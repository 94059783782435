import { CODES } from './codes';
import { hasOrientation, hasPixelMeasurements, hasPosition } from './isDisplaySetReconstructable';

export const checkMultiFrame = (multiFrameInstance) => {
	let frameSetIssues = {};

	if (!hasPixelMeasurements(multiFrameInstance)) {
		frameSetIssues = { ...frameSetIssues, [CODES.MULTIFRAME_NO_PIXEL_MEASUREMENTS]: true };
	}

	if (!hasOrientation(multiFrameInstance)) {
		frameSetIssues = { ...frameSetIssues, [CODES.MULTIFRAME_NO_ORIENTATION]: true };
	}

	if (!hasPosition(multiFrameInstance)) {
		frameSetIssues = { ...frameSetIssues, [CODES.MULTIFRAME_NO_POSITION_INFORMATION]: true };
	}

	return frameSetIssues;
};
