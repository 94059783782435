import React from 'react';

const PlusTab = ({ fill }) => (
	<svg fill="none" height={38} width={87} xmlns="http://www.w3.org/2000/svg">
		<mask
			height={38}
			id="a"
			maskUnits="userSpaceOnUse"
			style={{
				maskType: 'alpha',
			}}
			width={87}
			x={0}
			y={0}
		>
			<path d="M0 10C0 4.477 4.477 0 10 0h77v38H0V10Z" fill="#D9D9D9" />
		</mask>
		<g mask="url(#a)">
			<g filter="url(#b)">
				<path
					d="M-142 10c0-5.523 4.477-10 10-10H51.204a10 10 0 0 1 7.798 3.74L86.5 38H-142V10Z"
					fill={fill || '#2F2F2F'}
				/>
			</g>
			<path
				d="M40 19.429h-5.571V25H32.57v-5.571H27V17.57h5.571V12h1.858v5.571H40v1.858Z"
				fill="#fff"
				opacity={fill ? 1 : 0.6}
			/>
		</g>
	</svg>
);

export default PlusTab;
