import { createTheme } from '@mui/material/styles';

//import commonTheme from './common-theme';

// Creating a palette theme allows us to reference the palettes in our final light theme
const lightThemePalette = createTheme({
	//...commonTheme,
	palette: {
		mode: 'light',
		primary: {
			light: '#4f4f4f',
			main: '#E5E5E5',
			dark: '#000000',
			contrastText: '#fff',
		},
		rsPrimary: {
			main: '#42A5F5',
			button: '#4096DC',
			hover: '#28679A',
			contrastText: '#FFFFFF',
		},
		rsSecondary: {
			main: '#42A5F5',
			medium: '#FFFFFF',
			dark: '#E5E5E5',
			hover: '#28679A',
			button: '#F3F3F3',
		},
		rsError: {
			main: '#CF6679',
		},
		text: {
			primary: '#12121299',
			main: '#393939',
			light: '#FFFFFF',
			dark: '#000000',
			border: '#848484',
			header: '#121212',
		},
		icon: {
			primary: '#ffffff99',
			main: '#000000',
			background: '#E5E5E5',
		},
		iconFill: {
			main: '#F3F3F3',
		},
		rsTab: {
			main: '#848484',
		},
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				body: {
					backgroundColor: '#E5E5E5',
				},
			},
		},
	},
});

export const lightTheme = createTheme({
	...lightThemePalette,
	components: {
		//...commonTheme.components,
		MuiInputLabel: {
			styleOverrides: {
				root: {
					'&.Mui-focused': {
						color: lightThemePalette.palette.rsPrimary.main,
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: lightThemePalette.palette.rsPrimary.main,
					},
					'.MuiSvgIcon-root': {
						opacity: 0.6,
					},
				},
			},
		},
		MuiListItemIcon: {
			variants: [
				{
					props: { variant: 'checklist' },
					style: {
						color: lightThemePalette.palette.rsPrimary.main,
					},
				},
			],
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					opacity: 0.87,
				},
			},
		},
		MuiSpeedDial: {
			styleOverrides: {
				fab: {
					backgroundColor: lightThemePalette.palette.rsSecondary.main,
					'&.MuiFab-sizeLarge': {
						height: '70px',
						width: '70px',
					},
					'&:hover': {
						backgroundColor: lightThemePalette.palette.rsSecondary.hover,
					},
				},
			},
		},
		MuiSpeedDialAction: {
			styleOverrides: {
				fab: {
					backgroundColor: lightThemePalette.palette.rsSecondary.medium,
					'&.MuiFab-sizeSmall': {
						height: '50px',
						width: '50px',
					},
				},
				staticTooltipLabel: {
					whiteSpace: 'nowrap',
					maxWidth: '300px',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					lineHeight: '2',
					backgroundColor: lightThemePalette.palette.rsSecondary.dark,
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					background: lightThemePalette.palette.rsSecondary.medium,
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					'&.Mui-checked': {
						color: '#4096DC',
					},
				},
			},
		},
	},
	zIndex: {
		tooltip: 9999,
	},
});

export default lightTheme;
