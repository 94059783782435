import React, { useState, useMemo, useEffect } from 'react';
import { useCrmContext } from '../../../../../context/CRMContext';
import { Box, Collapse, TextField, Select, MenuItem, IconButton } from '@mui/material';
import {
	Row,
	Col,
	Collapsible,
	TabPanel,
	Wrapper,
	DottedWrapper,
	StyledPhoneInput,
} from '../../../Cases/casePopOverStyles';
import organizationTypes from '@worklist-2/ui/src/views/OrganizationDetailView/organizationDetail/organizationTypes';
import timezones from '@worklist-2/ui/src/views/OrganizationDetailView/organizationDetail/timezones';
import FormFieldVariants from '@worklist-2/ui/src/components/FormNew/formPartials/FormFieldVariants/FormFieldVariants';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import 'react-phone-input-2/lib/material.css';
import { generateRandomString, useConfig, fhirExtensionUrls } from '@worklist-2/core/src';
import axios from 'axios';

import LastPageIcon from '@mui/icons-material/LastPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BusinessIcon from '@mui/icons-material/Business';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FaxIcon from '@mui/icons-material/Fax';
import EmailIcon from '@mui/icons-material/Email';
import CheckIcon from '@mui/icons-material/Check';

const validationSchema = yup.object().shape({
	name: yup.string().required('Organization Name is required'),
	website: yup.string().matches(/^[a-z0-9]+([\-\.][a-z0-9]+)*\.[a-z]{2,6}$/, {
		message: 'Invalid website format. Please do not include "http://" prefix.',
		excludeEmptyString: true,
	}),
});

const OrganizationDetail = ({
	accountData,
	data,
	setStep,
	setNewOrgData,
	fetchAccountData,
	isSimpleSidebar,
	handleUpdateOrgTree,
}) => {
	const { toggleDetails } = useCrmContext();
	const [collapsedId, setCollapsed] = useState(null);
	const [contacts, setContacts] = useState([]);
	const [practiceType, setPracticeType] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formFieldErrors, setFormFieldErrors] = useState(null);
	const __config = useConfig();

	const organizationForm = {
		requiredFields: ['name'],
		selectFields: ['type', 'timezone'],
		multipleSelectFields: ['practiceType'],
		textFields: ['name', 'partOf', 'npi', 'website'],
		readonlyFields: ['partOf'],
	};

	const { requiredFields } = organizationForm;
	const { textFields } = organizationForm;
	const { selectFields } = organizationForm;
	const { multipleSelectFields } = organizationForm;
	const { readonlyFields } = organizationForm;
	const isReadOnly = !!data?.id;

	const orgData = data;
	const isMasterOrganization = !orgData?.partOf?.id;
	const hasOrganizationId = !!orgData?.id;
	const form = useForm({
		resolver: yupResolver(validationSchema),
		shouldFocusError: true,
		mode: 'all',
	});

	const shippingAddressForm = useForm({
		resolver: yupResolver(validationSchema),
		shouldFocusError: true,
		mode: 'all',
	});

	const detailsData = useMemo(() => {
		const timezone = orgData?.extension?.find(
			item => item.url === fhirExtensionUrls.organization.timezone
		)?.valueString;
		const isTelecomArr = Array.isArray(orgData?.telecom);
		let website = '';
		let contactList = [];

		if (isTelecomArr) {
			website = orgData?.telecom?.find(item => item.system === 'url')?.value;
			contactList = orgData?.telecom?.filter(item => item.system !== 'url');
		}
		const shippingAddressLine1 = orgData?.extension?.find(
			({ url }) => url === fhirExtensionUrls.organization.shippingAddress
		)?.valueString;
		const shippingCity = orgData?.extension?.find(
			({ url }) => url === fhirExtensionUrls.organization.shippingCity
		)?.valueString;
		const shippingProvince = orgData?.extension?.find(
			({ url }) => url === fhirExtensionUrls.organization.shippingProvince
		)?.valueString;
		const shippingPostalCode = orgData?.extension?.find(
			({ url }) => url === fhirExtensionUrls.organization.shippingPostalCode
		)?.valueString;
		const shippingCountry = orgData?.extension?.find(
			({ url }) => url === fhirExtensionUrls.organization.shippingCountry
		)?.valueString;
		const practiceTypeValue =
			orgData?.extension
				?.find(({ url }) => url === fhirExtensionUrls.organization.practiceType2)
				?.extension?.map(item => item?.valueString) || [];

		setContacts(contactList ?? []);
		setPracticeType(practiceTypeValue);
		form.setValue('address', orgData?.address?.[0]?.line?.[0]);
		form.setValue('city', orgData?.address?.[0]?.city);
		form.setValue('province', orgData?.address?.[0]?.state);
		form.setValue('postalCode', orgData?.address?.[0]?.postalCode);
		form.setValue('country', orgData?.address?.[0]?.country);

		shippingAddressForm.setValue('address', shippingAddressLine1);
		shippingAddressForm.setValue('city', shippingCity);
		shippingAddressForm.setValue('province', shippingProvince);
		shippingAddressForm.setValue('postalCode', shippingPostalCode);
		shippingAddressForm.setValue('country', shippingCountry);

		return {
			general: {
				id: 'general',
				label: 'General',
				contents: [
					{
						name: 'name',
						label: 'Organization Name',
						value: orgData?.name,
					},
					{
						name: 'partOf',
						label: 'Parent Organization',
						value: orgData?.partOf?.display,
					},
					{
						name: 'type',
						label: 'Organization Type',
						value: orgData?.type?.[0]?.coding?.[0]?.code,
					},
					{
						name: 'npi',
						label: 'NPI/Organization ID',
						value: orgData?.identifier?.[0]?.value,
					},
					{
						name: 'timezone',
						label: 'TimeZone',
						value: timezone,
					},
					{
						name: 'website',
						label: 'Corporate Website',
						value: website,
					},
					{
						name: 'practiceType',
						label: 'Practice Type',
					},
				],
			},
			address: {
				id: 'address',
				label: 'Billing Address',
				contents: [
					{
						name: 'country',
						label: 'Country',
						value: orgData?.address?.[0]?.country,
					},
					{
						name: 'province',
						label: 'State/Province',
						value: orgData?.address?.[0]?.state,
					},
					{
						name: 'postalCode',
						label: 'Zip/Postal Code',
						value: orgData?.address?.[0]?.postalCode,
					},
					{
						name: 'city',
						label: 'City',
						value: orgData?.address?.[0]?.city,
					},
					{
						name: 'address',
						label: 'Address Line 1',
						value: orgData?.address?.[0]?.line?.[0],
					},
				],
			},
			shippingAddress: {
				id: 'address',
				label: 'Shipping Address',
				contents: [
					{
						name: 'country',
						label: 'Country',
						value: shippingCountry,
					},
					{
						name: 'province',
						label: 'State/Province',
						value: shippingProvince,
					},
					{
						name: 'postalCode',
						label: 'Zip/Postal Code',
						value: shippingPostalCode,
					},
					{
						name: 'city',
						label: 'City',
						value: shippingCity,
					},
					{
						name: 'address',
						label: 'Address Line 1',
						value: shippingAddressLine1,
					},
				],
			},
			contact: {
				id: 'contact',
				label: 'Contact Information',
				contents: {},
			},
		};
	}, [orgData]);

	const handlePracticeTypeChange = event => {
		const {
			target: { value },
		} = event;
		setPracticeType(typeof value === 'string' ? value.split(' | ') : value);
	};

	const onCollapsed = id => {
		setCollapsed(collapsedId === id ? null : id);
	};

	const addNewContact = () => {
		setContacts([
			...contacts,
			{
				system: 'phone',
				value: '',
				rank: contacts.length + 1,
				id: generateRandomString(16),
			},
		]);
	};

	const generateFhirJson = submitData => {
		const fhirJson = {
			resourceType: 'Organization',
			extension: [
				{
					url: fhirExtensionUrls.organization.timezone,
					valueString: submitData.timezone,
				},
				{
					url: fhirExtensionUrls.organization.shippingAddress,
					valueString: submitData.shippingAddress.address,
				},
				{
					url: fhirExtensionUrls.organization.shippingCity,
					valueString: submitData.shippingAddress.city,
				},
				{
					url: fhirExtensionUrls.organization.shippingProvince,
					valueString: submitData.shippingAddress.province,
				},
				{
					url: fhirExtensionUrls.organization.shippingPostalCode,
					valueString: submitData.shippingAddress.postalCode,
				},
				{
					url: fhirExtensionUrls.organization.shippingCountry,
					valueString: submitData.shippingAddress.country,
				},
				{
					url: fhirExtensionUrls.organization.practiceType2,
					extension: practiceType.map(item => ({ url: 'text', valueString: item })),
				},
			],
			active: true,
			identifier: [
				{
					type: {
						coding: [
							{
								code: 'NPI',
								display: 'NPI',
							},
						],
					},
					value: submitData.npi,
				},
			],
			type: [
				{
					coding: [
						{
							code: submitData.type,
						},
					],
				},
			],
			name: submitData.name,
			address: [
				{
					line: [submitData.address],
					city: submitData.city,
					state: submitData.province,
					postalCode: submitData.postalCode,
					country: submitData.country,
				},
			],
			partOf: orgData?.partOf,
			telecom: [
				...contacts,
				{
					system: 'url',
					value: submitData.website,
					rank: contacts.length + 1,
					id: generateRandomString(16),
				},
			],
		};

		if (submitData.type === 'PROV') {
			fhirJson.extension.push({
				url: fhirExtensionUrls.organization.isManaging,
				valueBoolean: true,
			});
		}

		return fhirJson;
	};

	const onSubmit = async submitData => {
		setIsSubmitting(true);
		const fhirJson = generateFhirJson({ ...submitData, shippingAddress: shippingAddressForm.getValues() });
		try {
			await axios
				.post(
					`${__config.data_sources.breeze}/Account/${accountData.AccountId}/organization`,
					JSON.stringify(fhirJson),
					{
						headers: {
							Accept: '*/*',
							'Content-Type': 'application/json',
						},
					}
				)
				.then(result => {
					setIsSubmitting(false);
					if (result.status == 200) {
						const newOrg = result.data;
						if (newOrg?.id) {
							setNewOrgData(newOrg);
							setStep('license');
						} else {
							setStep(null);
						}
						fetchAccountData();
					}
				});
		} catch (e) {
			setIsSubmitting(false);
			console.error(e);
		}
	};

	const updateForm = async submitData => {
		const newData = { ...data };

		if (newData?.extension?.find(({ url }) => url === fhirExtensionUrls.organization.shippingAddress)) {
			newData?.extension?.map(item =>
				item.url === fhirExtensionUrls.organization.shippingAddress
					? (item.valueString = shippingAddressForm.getValues('address'))
					: ''
			);
		} else {
			newData?.extension?.push({
				url: fhirExtensionUrls.organization.shippingAddress,
				valueString: shippingAddressForm.getValues('address'),
			});
		}

		if (newData?.extension?.find(({ url }) => url === fhirExtensionUrls.organization.shippingCity)) {
			newData?.extension?.map(item =>
				item.url === fhirExtensionUrls.organization.shippingCity
					? (item.valueString = shippingAddressForm.getValues('city'))
					: ''
			);
		} else {
			newData?.extension?.push({
				url: fhirExtensionUrls.organization.shippingCity,
				valueString: shippingAddressForm.getValues('city'),
			});
		}

		if (newData?.extension?.find(({ url }) => url === fhirExtensionUrls.organization.shippingProvince)) {
			newData?.extension?.map(item =>
				item.url === fhirExtensionUrls.organization.shippingProvince
					? (item.valueString = shippingAddressForm.getValues('province'))
					: ''
			);
		} else {
			newData?.extension?.push({
				url: fhirExtensionUrls.organization.shippingProvince,
				valueString: shippingAddressForm.getValues('province'),
			});
		}

		if (newData?.extension?.find(({ url }) => url === fhirExtensionUrls.organization.shippingPostalCode)) {
			newData?.extension?.map(item =>
				item.url === fhirExtensionUrls.organization.shippingPostalCode
					? (item.valueString = shippingAddressForm.getValues('postalCode'))
					: ''
			);
		} else {
			newData?.extension?.push({
				url: fhirExtensionUrls.organization.shippingPostalCode,
				valueString: shippingAddressForm.getValues('postalCode'),
			});
		}

		if (newData?.extension?.find(({ url }) => url === fhirExtensionUrls.organization.shippingCountry)) {
			newData?.extension?.map(item =>
				item.url === fhirExtensionUrls.organization.shippingCountry
					? (item.valueString = shippingAddressForm.getValues('country'))
					: ''
			);
		} else {
			newData?.extension?.push({
				url: fhirExtensionUrls.organization.shippingCountry,
				valueString: shippingAddressForm.getValues('country'),
			});
		}

		const updatedData = {
			...newData,
			telecom: [...contacts],
			address: [
				{
					line: [submitData.address],
					city: submitData.city,
					state: submitData.province,
					postalCode: submitData.postalCode,
					country: submitData.country,
				},
			],
		};
		try {
			await axios
				.put(
					`${__config.data_sources.breeze}/OrganizationOmegaAI/${accountData.AccountId}/${data.id}`,
					updatedData,
					{
						headers: {
							Accept: '*/*',
							'Content-Type': 'application/json',
						},
					}
				)
				.then(result => {
					setIsSubmitting(false);
					if (result.status == 200) {
						const newOrg = result.data;
						setNewOrgData(newOrg);
						setStep('license');
						handleUpdateOrgTree(newOrg);
					}
				});
		} catch (e) {
			setIsSubmitting(false);
			console.error(e);
		}
	};

	const beforeSubmitForm = () => {
		if (Object.keys(formFieldErrors).length === 0) {
			hasOrganizationId ? form.handleSubmit(updateForm)() : form.handleSubmit(onSubmit)();
		}
	};

	const getTitle = () => {
		if (isMasterOrganization) {
			return 'Master Organization';
		}
		if (hasOrganizationId) {
			return 'Child Organization';
		}
		return 'New Organization';
	};

	useEffect(() => {
		setFormFieldErrors(form.formState.errors);
	}, [form.getValues()]);

	return (
		<Wrapper className="invisible-scrollbar">
			<div className="header" style={{ backgroundColor: '#FFF', padding: '5px 15px' }}>
				<div className="actions">
					<div className="left">
						{!isSimpleSidebar && (
							<IconButton
								onClick={() => {
									setStep(null);
									setNewOrgData(null);
								}}
							>
								<ArrowBackIcon />
							</IconButton>
						)}
					</div>
					<div className="right">
						<IconButton
							onClick={() => {
								setStep(null);
								setNewOrgData(null);
								toggleDetails();
							}}
						>
							<LastPageIcon />
						</IconButton>
					</div>
				</div>
			</div>
			<div className="body" style={{ position: 'relative' }}>
				<TabPanel className="invisible-scrollbar" style={{ margin: '0px 23px' }}>
					<div className="header">
						<div className="left">
							<div className="colored-icon info-icon">
								<BusinessIcon sx={{ fontSize: 20 }} />
							</div>
							<h2>{getTitle()}</h2>
						</div>
						<div className="right">
							<div className="colored-icon active-icon">
								<BusinessIcon sx={{ fontSize: 10 }} />
							</div>
							<span className="active-span" />
							<div className="colored-icon gray-icon">
								<CardTravelIcon sx={{ fontSize: 10 }} />
							</div>
							<span className="gray-span" />
							<div className="colored-icon gray-icon">
								<ToggleOnOutlinedIcon sx={{ fontSize: 10 }} />
							</div>
							<span className="gray-span" />
							<div className="colored-icon gray-icon">
								<DeviceHubIcon sx={{ fontSize: 10 }} />
							</div>
							<span className="gray-span" />
							<div className="colored-icon gray-icon">
								<FolderOutlinedIcon sx={{ fontSize: 10 }} />
							</div>
						</div>
					</div>
					<div className="body">
						<Collapsible>
							{_.map(detailsData, (category, i) => {
								const rows = _.chunk(category.contents, 2);

								let addressRows = _.chunk(detailsData.address.contents, 2);
								addressRows = [
									[{ name: 'addressSearch', label: 'Address Search', value: '' }],
									...addressRows,
								];

								return (
									<div key={i}>
										<CollapseHandle
											collapsedId={collapsedId}
											id={category.id}
											label={category.label}
											onCollapsed={onCollapsed}
										/>
										<Collapse in={collapsedId !== category.id}>
											<div className="content">
												{category.id == 'address' ? (
													addressRows.map((arr, j) => (
														<Row key={j} className="row">
															{arr.map((entry, k) => {
																const { name } = entry;
																return (
																	<Col
																		key={`${entry.name}-${k}`}
																		style={{
																			width: `${
																				arr.length == 1 ? '100%' : '50%'
																			}`,
																			marginBottom: 0,
																			marginRight: 0,
																		}}
																	>
																		<label className="detail">{entry.label}</label>
																		<div>
																			<div className="row">
																				<FormFieldVariants
																					field={{
																						name,
																						type: `${
																							entry.name ===
																							'addressSearch'
																								? 'googleAutocomplete'
																								: 'text'
																						}`,
																						props: {
																							InputProps: {
																								name,
																							},
																							id: `${category.label.replaceAll(
																								' ',
																								'_'
																							)}_${entry.label?.replaceAll(
																								' ',
																								'_'
																							)}`,
																							sx: {
																								marginRight: '17px',
																								'& .MuiInputBase-root':
																									{
																										borderRadius:
																											'10px',
																									},
																								'& .MuiInputBase-input':
																									{
																										padding:
																											'11px 10px',
																									},
																								'& .MuiOutlinedInput-input':
																									{
																										'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
																											{
																												'-webkit-appearance':
																													'none',
																											},
																										border: 'none',
																									},
																								'& .MuiOutlinedInput-notchedOutline':
																									{
																										span: {
																											marginRight:
																												'0px !important',
																										},
																									},
																							},
																						},
																					}}
																					formHook={
																						category.label ===
																						'Shipping Address'
																							? shippingAddressForm
																							: form
																					}
																				/>
																			</div>
																		</div>
																	</Col>
																);
															})}
														</Row>
													))
												) : category.id == 'contact' ? (
													<>
														<DottedWrapper>
															<div
																className="button"
																data-testid="add_contact"
																onClick={addNewContact}
															>
																<AddIcon />
															</div>
														</DottedWrapper>
														<Box
															sx={{
																margin: '10px',
																display: 'flex',
																flexDirection: 'column',
																alignItems: 'center',
																justifyContent: 'space-between',
															}}
														>
															{contacts.map((contact, j) => (
																<Box
																	key={j}
																	sx={{
																		width: '100%',
																		display: 'flex',
																		flexDirection: 'row',
																		border: '1px solid #C4C4C4',
																		borderRadius: '10px',
																		marginBottom: '10px',
																	}}
																>
																	<Select
																		sx={{
																			'& legend': { display: 'none' },
																			'& fieldset': {
																				top: 0,
																				border: 'none',
																			},
																			height: '47px',
																			fontSize: '12px',
																			fontStretch: 'normal',
																			fontStyle: 'normal',
																			flex: '1',
																			'.MuiSvgIcon-root': {
																				opacity: '1',
																				fontSize: '1rem',
																			},
																		}}
																		value={contact.system}
																		onChange={e => {
																			contact.system = e.target.value;
																			setContacts(prev => [
																				...prev.slice(0, j),
																				contact,
																				...prev.slice(j + 1),
																			]);
																		}}
																	>
																		<MenuItem value="phone">
																			<LocalPhoneIcon
																				sx={{
																					fontSize: 'large',
																					color: '#4D79EA',
																				}}
																			/>
																		</MenuItem>
																		<MenuItem value="fax">
																			<FaxIcon
																				sx={{
																					fontSize: 'large',
																					color: '#4D79EA',
																				}}
																			/>
																		</MenuItem>
																		<MenuItem value="email">
																			<EmailIcon
																				sx={{
																					fontSize: 'large',
																					color: '#4D79EA',
																				}}
																			/>
																		</MenuItem>
																	</Select>
																	<span
																		style={{
																			height: '30px',
																			width: '1px',
																			backgroundColor: '#CDCDCD',
																			marginTop: '8px',
																		}}
																	/>
																	{contact.system === 'email' ? (
																		<input
																			style={{
																				width: '100%',
																				border: 'none',
																				borderRadius: '10px',
																				padding: '0px 10px',
																				fontSize: '14px',
																				fontStyle: 'normal',
																				fontFamily: 'Roboto',
																				opacity: '0.5',
																				outline: '0',
																			}}
																			value={contact.value}
																			onChange={e => {
																				contact.value = e.target.value;
																				setContacts(prev => [
																					...prev.slice(0, j),
																					contact,
																					...prev.slice(j + 1),
																				]);
																			}}
																		/>
																	) : (
																		<StyledPhoneInput
																			country="us"
																			value={contact.value}
																			onChange={value => {
																				contact.value = `+${value}`;
																				setContacts(prev => [
																					...prev.slice(0, j),
																					contact,
																					...prev.slice(j + 1),
																				]);
																			}}
																		/>
																	)}
																</Box>
															))}
														</Box>
													</>
												) : (
													rows.map((arr, j) => (
														<Row key={j} className="row">
															{arr.map((entry, k) => {
																const fieldName = entry.name;
																return (
																	<Col
																		key={`${entry.key}-${k}`}
																		style={{
																			width: `${
																				arr.length == 1 ? '100%' : '50%'
																			}`,
																			marginBottom: 0,
																			marginRight: 0,
																		}}
																	>
																		{requiredFields.includes(fieldName) ? (
																			<label className="detail">
																				{entry.label}
																				<span style={{ color: 'red' }}>
																					&nbsp;*
																				</span>
																			</label>
																		) : (
																			<label className="detail">
																				{entry.label}
																			</label>
																		)}
																		<div className="form-control">
																			<div className="row">
																				{textFields.includes(fieldName) && (
																					<TextField
																						{...form.register(fieldName)}
																						hiddenLabel
																						InputProps={{
																							readOnly:
																								isReadOnly ||
																								readonlyFields.includes(
																									fieldName
																								),
																						}}
																						className="without-padding"
																						data-testid={fieldName}
																						defaultValue={entry.value}
																						error={
																							formFieldErrors &&
																							!!formFieldErrors[fieldName]
																						}
																						helperText={
																							formFieldErrors &&
																							formFieldErrors[fieldName]
																								?.message
																						}
																						id={fieldName}
																						name={fieldName}
																						size="small"
																						sx={{
																							'& legend': {
																								display: 'none',
																							},
																							'& fieldset': {
																								top: 0,
																							},
																							fontSize: '14px',
																							flex: '1',
																							maxWidth: '207px',
																							borderRadius: 10,
																						}}
																						value={entry.value}
																						variant="outlined"
																					/>
																				)}
																				{(selectFields.includes(fieldName) ||
																					multipleSelectFields.includes(
																						fieldName
																					)) && (
																					<div style={{ width: 200 }}>
																						<Select
																							{...form.register(
																								fieldName,
																								{
																									onChange:
																										fieldName ===
																										'practiceType'
																											? handlePracticeTypeChange
																											: () => {},
																								}
																							)}
																							class={fieldName}
																							data-testid={fieldName}
																							disabled={isReadOnly}
																							multiple={multipleSelectFields.includes(
																								fieldName
																							)}
																							name={fieldName}
																							renderValue={selected =>
																								multipleSelectFields.includes(
																									fieldName
																								)
																									? selected.join(
																											' | '
																									  )
																									: selected
																							}
																							style={{
																								border: 'none',
																								fontFamily: 'Inter',
																							}}
																							sx={{
																								'& legend': {
																									display: 'none',
																								},
																								'& fieldset': {
																									top: 0,
																								},
																								height: '40px',
																								width: '207px',
																								fontSize: '14px',
																								fontStretch: 'normal',
																								fontStyle: 'normal',
																								flex: '1',
																								borderRadius: 3,
																							}}
																							value={
																								fieldName ===
																								'practiceType'
																									? practiceType
																									: entry.value
																							}
																						>
																							{fieldName === 'type' &&
																								organizationTypes
																									.filter(type => {
																										if (
																											isMasterOrganization
																										) {
																											if (
																												type.code ==
																												'PROV'
																											) {
																												return type;
																											}
																										} else {
																											return type;
																										}
																									})
																									.map(
																										(
																											item,
																											index
																										) => (
																											<MenuItem
																												key={
																													index
																												}
																												value={
																													item.code
																												}
																											>
																												{
																													item.display
																												}
																											</MenuItem>
																										)
																									)}
																							{fieldName === 'timezone' &&
																								timezones.map(
																									(item, index) => (
																										<MenuItem
																											key={index}
																											value={
																												item.valueString
																											}
																										>
																											{
																												item.valueString
																											}
																										</MenuItem>
																									)
																								)}
																							{fieldName ===
																								'practiceType' &&
																								[
																									'Imaging',
																									'Reading',
																									'Transcription',
																								].map((item, index) => (
																									<MenuItem
																										key={index}
																										value={item}
																									>
																										<Box
																											sx={{
																												width: '24px',
																												height: '24px',
																												marginRight:
																													'8px',
																											}}
																										>
																											{practiceType.includes(
																												item
																											) && (
																												<CheckIcon />
																											)}
																										</Box>
																										{item}
																									</MenuItem>
																								))}
																						</Select>
																					</div>
																				)}
																			</div>
																		</div>
																	</Col>
																);
															})}
														</Row>
													))
												)}
											</div>
										</Collapse>
									</div>
								);
							})}
						</Collapsible>
						<div>&nbsp;</div>
					</div>
				</TabPanel>
				<div className="footer" style={{ position: 'absolute', width: '490px', marginLeft: '23px' }}>
					<button
						className="outline"
						data-testid="cancelButton"
						type="button"
						onClick={() => {
							setStep(null);
							setNewOrgData(null);

							if (isSimpleSidebar) {
								toggleDetails();
							}
						}}
					>
						CANCEL
					</button>
					<div className="right">
						<button
							className="fill"
							data-testid="saveButton"
							disabled={isSubmitting}
							onClick={beforeSubmitForm}
						>
							NEXT
						</button>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

export default OrganizationDetail;

const CollapseHandle = ({ id, collapsedId, label, onCollapsed }) => (
	<div className="handle" onClick={() => onCollapsed(id)}>
		<span>{label}</span>
		{collapsedId === id ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
	</div>
);
