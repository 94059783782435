import axios from 'axios';

const patchUser = async ({ __config, data }) => {
	const url = `${__config.data_sources.blume}User?version=3`;
	const response = await axios.patch(url, data, {
		headers: {
			Accept: '*/*',
			'Content-Type': 'application/json-patch+json',
		},
	});

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default patchUser;
