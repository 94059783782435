// React Libraries
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

// Material UI Libraries
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ShareIcon from '@mui/icons-material/Share';

// Custom Component Libraries
import Suggest from '@worklist-2/ui/src/components/Suggest/Suggest';

// Worklist Core Libraries
import _ from 'lodash';

function ShareSideContent({ setIsShare, onShareReport, userData, reportId }) {
	const [isAddNewShare, setIsAddNewShare] = useState(false);
	const [selectedUser, setSelectedUser] = useState({});
	const [selectedPrivilege, setSelectedPrivilege] = useState('view-only');
	useState('');
	const [userSearchScope] = useState({
		resource: 'userApi',
		label: 'name',
		searchKey: 'UserName',
	});
	const [isCopied, setIsCopied] = useState(false);

	const onSelectedUser = async (valueField, searchValue, optionId, record) => {
		if (searchValue && record) {
			setSelectedUser({
				id: record.id,
				display: searchValue,
			});
		} else {
			setSelectedUser({});
		}
	};

	const onSelectPrivilege = (item, value) => {
		setSelectedPrivilege(value.props.value);
	};

	const onChangeUserPrivilege = (item, e) => {
		const { extension } = item;
		const privilegeIndex = _.findIndex(extension, { url: 'privilege' });

		extension[privilegeIndex].valueString = e.target.value;
		onShareReport(item);
	};

	const onShareClick = () => {
		// only save if User and Privilege are selected
		if (selectedUser && selectedPrivilege) {
			selectedUser.extension = [
				{
					url: 'privilege',
					valueString: selectedPrivilege,
				},
				{
					url: 'favorite',
					valueBoolean: false,
				},
			];

			onShareReport(selectedUser);
			setIsAddNewShare(false);
		}
	};

	const onCopyClick = useCallback(async () => {
		if (location.href.endsWith('reports')) {
			const url = `${location.href.replace('reports', 'report')}/${reportId}`;
			await navigator.clipboard.writeText(url);
		} else {
			await navigator.clipboard.writeText(location.href);
		}
		setIsCopied(true);
	}, [location.href]);

	return (
		<Box
			sx={{
				height: '100vh',
				width: '400px',
				position: 'fixed',
				right: 0,
				top: 0,
				bottom: 0,
				zIndex: 10,
				background: '#fff',
				boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.25)',
				borderRadius: '10px',
				transition: '0.2s',
			}}
		>
			{!isAddNewShare ? (
				<>
					<Stack
						alignItems="center"
						direction="row"
						justifyContent="space-between"
						sx={{
							padding: '18px 20px',
							color: 'rgba(0, 0, 0, 0.9)',
							borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
							background: '#F9FAFC',
						}}
					>
						<Stack alignItems="center" direction="row">
							<ShareIcon
								sx={{
									marginRight: 3,
									color: '#4D79EA',
								}}
							/>
							<Typography
								fontSize={24}
								sx={{
									fontWeight: 500,
								}}
							>
								Share
							</Typography>
						</Stack>
						<IconButton aria-label="clear" onClick={() => setIsShare(false)}>
							<ClearIcon />
						</IconButton>
					</Stack>

					<Box
						sx={{
							padding: '0 20px',
							height: 'calc(100vh - 80px)',
							overflowY: 'auto',
							overflowX: 'hidden',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								marginTop: 3,
							}}
						>
							<Button
								data-testid="btn-copy-link"
								sx={{
									minWidth: 40,
									height: 40,
									padding: 0,
									background: 'rgba(75, 170, 246, 0.1)',
									borderRadius: '100px',
									color: '#4D79EA',
								}}
								onClick={onCopyClick}
							>
								<InsertLinkIcon />
							</Button>

							<Typography
								sx={{
									textTransform: 'uppercase',
									color: '#4D79EA',
									marginLeft: '16px',
								}}
							>
								{!isCopied ? 'Copy the link' : 'Copied!'}
							</Typography>
						</Box>

						<Typography
							fontSize={16}
							sx={{
								color: 'rgba(0,0,0,0.8)',
								marginTop: '30px',
							}}
						>
							My Shared List
						</Typography>

						<Box
							data-testid="add-new-share"
							sx={{
								width: '100%',
								height: '54px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								border: '1px dashed #C4C4C4',
								marginTop: '16px',
								color: '#C4C4C4',
								borderRadius: '5px',
								'&:hover': {
									color: '#818181',
									border: '1px dashed #818181',
									cursor: 'pointer',
								},
							}}
							onClick={() => setIsAddNewShare(true)}
						>
							<AddIcon />
						</Box>

						<List
							sx={{
								paddingTop: '10px',
							}}
						>
							{userData &&
								userData.map((elm, index) => (
									<ListItem
										key={index}
										sx={{
											padding: '8px 0',
										}}
									>
										<ListItemButton
											sx={{
												padding: '16px',
												background: '#F9FAFF',
												borderRadius: '10px',
												border: '1px solid transparent',
												justifyContent: 'space-between',
												'&:hover': {
													borderColor: 'rgba(66, 165, 245, 0.6)',
												},
											}}
										>
											<Box
												sx={{
													minWidth: '50px',
													height: '50px',
													background: 'rgba(77,121,234,0.2)',
													borderRadius: '10px',
													color: '#4D79EA',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Typography>{elm.icon}</Typography>
											</Box>
											<Box
												sx={{
													width: '100%',
													marginLeft: '16px',
												}}
											>
												<Typography
													sx={{
														color: '#4D79EA',
														fontWeight: 500,
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
														width: '150px',
													}}
												>
													{elm?.display}
												</Typography>
												<Typography
													fontSize={12}
													sx={{
														color: 'rgba(0,0,0,0.6)',
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
														width: '150px',
													}}
												>
													Referring Physician - {elm?.organization?.display}
												</Typography>
											</Box>

											<FormControl sx={{ m: 1, minWidth: 80 }}>
												<Select
													disableUnderline
													data-testid="report-share-privilege"
													defaultValue="view-only"
													id="privilege"
													sx={{
														fontSize: '12px',
														'&:selected': {
															background: '#FFF',
														},
														color: 'rgba(0,0,0,0.9)',
													}}
													value={
														elm.extension[
															_.findIndex(elm.extension, {
																url: 'privilege',
															})
														]?.valueString
													}
													variant="standard"
													onChange={e => {
														onChangeUserPrivilege(elm, e);
													}}
												>
													<MenuItem
														sx={{
															color: 'rgba(0,0,0,0.9)',
														}}
														value="view-only"
													>
														{/*<Check />*/}
														View Only
													</MenuItem>
													<Divider />
													<MenuItem
														sx={{
															color: 'rgba(0,0,0,0.9)',
														}}
														value="admin"
													>
														Admin
													</MenuItem>
													<Divider />
													<MenuItem
														sx={{
															color: 'rgba(0,0,0,0.9)',
														}}
														value="remove"
													>
														Remove
													</MenuItem>
												</Select>
											</FormControl>
										</ListItemButton>
									</ListItem>
								))}
						</List>
					</Box>
				</>
			) : (
				<Box
					data-testid="new-share-container"
					sx={{
						padding: '0 20px',
					}}
				>
					<Stack
						alignItems="center"
						direction="row"
						justifyContent="space-between"
						sx={{
							padding: '18px 0',
							color: 'rgba(0,0,0,0.9)',
							borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
						}}
					>
						<Typography fontSize={24} sx={{ fontWeight: 500 }}>
							Add New
						</Typography>
						<IconButton aria-label="clear" onClick={() => setIsAddNewShare(false)}>
							<ClearIcon />
						</IconButton>
					</Stack>
					<Stack
						spacing={3}
						sx={{
							padding: '28px 0',
						}}
					>
						<Suggest
							isCRM
							listSearchScope={userSearchScope}
							optionId="practitionerName"
							testid="UserName"
							text="User Name"
							width={360}
							onSelect={onSelectedUser}
						/>

						<FormControl fullWidth>
							<InputLabel id="select-privilege">Privilege</InputLabel>
							<Select
								displayEmpty
								data-testid="report-share-privilege-new"
								id="privilege"
								label="Privilege"
								labelId="select-privilege"
								startAdornment={
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								}
								value={selectedPrivilege}
								variant="outlined"
								onChange={onSelectPrivilege}
							>
								<MenuItem
									sx={{
										color: 'rgba(0,0,0,0.9)',
									}}
									value="view-only"
								>
									View Only
								</MenuItem>
								<Divider />
								<MenuItem
									sx={{
										color: 'rgba(0,0,0,0.9)',
									}}
									value="admin"
								>
									Admin
								</MenuItem>
							</Select>
						</FormControl>
					</Stack>
					<Stack
						direction="row"
						justifyContent="space-between"
						sx={{
							position: 'absolute',
							bottom: '16px',
							padding: '0 20px',
							left: '0',
							right: '0',
						}}
					>
						<Button
							color="success"
							sx={{
								color: '#4D79EA',
								border: '1px solid #4D79EA',
								'&:hover': {
									color: '#fff',
									background: 'rgba(77,121,234,0.8)',
									border: '1px solid rgba(77,121,234,0.8)',
								},
							}}
							variant="outlined"
							onClick={() => setIsAddNewShare(false)}
						>
							Cancel
						</Button>
						<Button
							color="secondary"
							sx={{
								color: '#FFF',
								background: '#4D79EA',
								'&:hover': {
									background: 'rgba(77,121,234,0.8)',
								},
							}}
							variant="contained"
							onClick={onShareClick}
						>
							Share
						</Button>
					</Stack>
				</Box>
			)}
		</Box>
	);
}

ShareSideContent.propTypes = {
	/**
	 * setIsShare is func to toggle share side bar
	 */
	setIsShare: PropTypes.func.isRequired,
};

export default ShareSideContent;
