import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PauseIcon from '@mui/icons-material/Pause';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import DialpadIcon from '@mui/icons-material/Dialpad';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';

function DialPadTab({ activeCall, setCurrentTab, setPhoneNumber, makeCall, hangupCall, mute, setMute, hold, setHold }) {
	const dialPadNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

	const muteCall = () => {
		if (activeCall) {
			activeCall.mute(true);
		}
	};

	useEffect(() => {}, [activeCall?.status()]);

	const unMuteCall = () => {
		if (activeCall) {
			activeCall.mute(false);
		}
	};

	return (
		<div
			style={{
				width: '100%',
				// height:'636px',
				height: '59vh',
				border: '1px solid #C4C4C466',
				borderRadius: '50px 50px 0px 0px',
				borderBottom: 'none',
			}}
		>
			<div
				style={{
					display: 'flex',
					width: '329px',
					margin: !activeCall ? '5.6vh auto 2.2vh' : '5.6vh auto 8vh',
					flexWrap: 'wrap',
					justifyContent: 'space-around',
				}}
			>
				{!activeCall ? (
					dialPadNumbers.map(number => (
						<Button
							sx={{
								width: '93px',
								// height: '70px',
								height: '6.4vh',
								borderRadius: '10px',
								margin: '5px 0',
								color: '#00000066',
								fontSize: '30px',
								border: '1px solid #00000066',
								'&:hover': {
									background: '#80808050',
									border: '1px solid #00000066',
								},
							}}
							variant="outlined"
							onClick={() => {
								setPhoneNumber(phoneNumber => phoneNumber + number);
							}}
						>
							{number}
						</Button>
					))
				) : (
					<>
						<Button
							sx={{
								display: 'flex',
								flexDirection: 'column',
								width: '93px',
								// height: '70px',
								height: '6.4vh',
								borderRadius: '10px',
								margin: '5px 0',
								color: '#00000066',
								fontSize: '30px',
								border: '1px solid #00000066',
								'&:hover': {
									background: '#80808050',
									border: '1px solid #00000066',
								},
							}}
							variant="outlined"
						>
							<AccountCircleOutlinedIcon />
							<Typography
								sx={{
									fontSize: '12px',
									fontFamily: 'Inter',
									textTransform: 'none',
									color: '#000000',
								}}
							>
								Profile
							</Typography>
						</Button>
						<Button
							sx={{
								width: '93px',
								display: 'flex',
								flexDirection: 'column',
								// height: '70px',
								height: '6.4vh',
								borderRadius: '10px',
								margin: '5px 0',
								color: '#00000066',
								fontSize: '30px',
								backgroundColor: hold ? '#4D79EA22' : '',
								border: '1px solid #00000066',
								'&:hover': {
									background: '#4D79EA22',
									border: '1px solid #00000066',
								},
							}}
							variant="outlined"
							onClick={() => {
								setHold(!hold);
							}}
						>
							<PauseIcon />
							<Typography
								sx={{
									fontSize: '12px',
									fontFamily: 'Inter',
									textTransform: 'none',
									color: '#000000',
								}}
							>
								Hold
							</Typography>
						</Button>
						<Button
							sx={{
								width: '93px',
								display: 'flex',
								flexDirection: 'column',
								// height: '70px',
								height: '6.4vh',
								borderRadius: '10px',
								margin: '5px 0',
								color: '#00000066',
								fontSize: '30px',
								border: '1px solid #00000066',
								'&:hover': {
									background: '#80808050',
									border: '1px solid #00000066',
								},
							}}
							variant="outlined"
							onClick={() => setCurrentTab('Available Agents')}
						>
							<SupportAgentIcon />
							<Typography
								sx={{
									fontSize: '12px',
									textTransform: 'none',
									fontFamily: 'Inter',
									color: '#000000',
								}}
							>
								Transfer
							</Typography>
						</Button>
						<Button
							sx={{
								width: '93px',
								display: 'flex',
								flexDirection: 'column',
								// height: '70px',
								height: '6.4vh',
								borderRadius: '10px',
								margin: '5px 0',
								color: mute ? '#4D79EA' : '#00000066',
								fontSize: '30px',
								border: '1px solid #00000066',
								backgroundColor: mute ? '#4D79EA22' : '',
								'&:hover': {
									background: '#80808050',
									border: '1px solid #00000066',
								},
							}}
							variant="outlined"
							onClick={() => {
								if (mute) {
									unMuteCall();
								} else {
									muteCall();
								}
								setMute(!mute);
							}}
						>
							{!mute ? <MicIcon /> : <MicOffIcon />}
							<Typography
								sx={{
									fontSize: '12px',
									fontFamily: 'Inter',
									textTransform: 'none',
									color: mute ? '#4D79EA' : '#000000',
								}}
							>
								Mute
							</Typography>
						</Button>
						<Button
							sx={{
								width: '93px',
								display: 'flex',
								flexDirection: 'column',
								// height: '70px',
								height: '6.4vh',
								borderRadius: '10px',
								margin: '5px 0',
								color: '#00000066',
								fontSize: '30px',
								border: '1px solid #00000066',
								'&:hover': {
									background: '#80808050',
									border: '1px solid #00000066',
								},
							}}
							variant="outlined"
						>
							<DialpadIcon />
							<Typography
								sx={{
									fontSize: '12px',
									fontFamily: 'Inter',
									textTransform: 'none',
									color: '#000000',
								}}
							>
								Keypad
							</Typography>
						</Button>
						<Button
							sx={{
								width: '93px',
								display: 'flex',
								flexDirection: 'column',
								// height: '70px',
								height: '6.4vh',
								borderRadius: '10px',
								margin: '5px 0',
								color: '#00000077',
								fontSize: '30px',
								border: '1px solid #00000066',
								'&:hover': {
									background: '#80808050',
									border: '1px solid #00000066',
								},
							}}
							variant="outlined"
						>
							<CoPresentOutlinedIcon />
							<Typography
								sx={{
									fontSize: '12px',
									fontFamily: 'Inter',
									textTransform: 'none',
									color: '#000000',
								}}
							>
								Share
							</Typography>
						</Button>
					</>
				)}
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-around',
				}}
			>
				<Button
					sx={{
						width: '93px',
						// height: '70px',
						height: '6.4vh',
						borderRadius: '10px',
						background: !activeCall ? '#17B443' : '#EF5D5D',
						margin: 'auto',
						'&:hover': {
							background: !activeCall ? '#17B453' : '#EF5D5D',
						},
					}}
					variant="contained"
					onClick={() => {
						if (!activeCall) {
							makeCall();
						} else {
							hangupCall();
						}
						setMute(false);
					}}
				>
					<CallIcon
						sx={{
							transform: !activeCall ? 'rotate(-45deg)' : 'rotate(135deg)',
						}}
					/>
				</Button>
			</div>
		</div>
	);
}

export default DialPadTab;
