export default class Fingerprint {
	nativeForEach;
	nativeMap;
	constructor() {
		this.nativeForEach = Array.prototype.forEach;
		this.nativeMap = Array.prototype.map;
	}
	get = () => {
		let browserFingerPrint = localStorage.getItem("browserFingerPrint"); 
		
		if(!browserFingerPrint)
		{
			let keys = [];
			keys.push(navigator.userAgent);
			keys.push(navigator.language);
			keys.push(screen.colorDepth);
			keys.push(this.getScreenResolution().join('x'));
			keys.push(new Date().getTimezoneOffset());
			keys.push(this.hasSessionStorage());
			keys.push(this.hasLocalStorage());
			keys.push(this.hasIndexDb());
			//body might not be defined at this point or removed programmatically
			if (document.body) {
				keys.push(typeof document.body.addBehavior);
			} else {
				keys.push(typeof undefined);
			}
			keys.push(typeof window.openDatabase);
			keys.push(navigator.cpuClass);
			keys.push(navigator.platform);
			keys.push(navigator.doNotTrack);
			keys.push(this.getPluginsString());
			
			localStorage.setItem("browserFingerPrint", `${this.murmurhash3_32_gc(keys.join('###'), 31)};${Date.parse(new Date)}`);
			browserFingerPrint = localStorage.getItem("browserFingerPrint");
		}
		return browserFingerPrint.split(';')[0];;
	};

	each = (obj, iterator, context) => {
		if (obj === null) {
			return;
		}
		if (this.nativeForEach && obj.forEach === this.nativeForEach) {
			obj.forEach(iterator, context);
		} else if (obj.length === +obj.length) {
			for (let i = 0, l = obj.length; i < l; i++) {
				if (iterator.call(context, obj[i], i, obj) === {}) return;
			}
		} else {
			for (let key in obj) {
				if (obj.hasOwnProperty(key)) {
					if (iterator.call(context, obj[key], key, obj) === {})
						return;
				}
			}
		}
	};

	map = (obj, iterator, context) => {
		let results = [];
		if (obj == null) return results;
		if (this.nativeMap && obj.map === this.nativeMap)
			return obj.map(iterator, context);
		this.each(obj, function (value, index, list) {
			results[results.length] = iterator.call(
				context,
				value,
				index,
				list
			);
		});
		return results;
	};

	/**
	 * If catch SecurityError, it means it exists
	 * @returns {boolean} has local storage
	 */
	hasLocalStorage = () => {
		try {
			return !!window.localStorage;
		} catch (e) {
			return true;
		}
	};

	/**
	 * If catch SecurityError, it means it exists
	 * @returns {boolean} has session storage
	 */
	hasSessionStorage = () => {
		try {
			return !!window.sessionStorage;
		} catch (e) {
			return true;
		}
	};

	/**
	 * If catch SecurityError, it means it exists
	 * @returns {boolean} has IndexDb
	 */
	hasIndexDb = () => {
		try {
			return !!window.indexedDB;
		} catch (e) {
			return true;
		}
	};

	isCanvasSupported = () => {
		let elem = document.createElement('canvas');
		return !!(elem.getContext && elem.getContext('2d'));
	};

	isIE = () => {
		if (navigator.appName === 'Microsoft Internet Explorer') {
			return true;
		} else if (
			navigator.appName === 'Netscape' &&
			/Trident/.test(navigator.userAgent)
		) {
			return true;
		}
		return false;
	};

	getPluginsString = () => {
		if (this.isIE()) {
			return this.getIEPluginsString();
		} else {
			return this.getRegularPluginsString();
		}
	};

	getRegularPluginsString = () => {
		return this.map(
			navigator.plugins,
			function (p) {
				let mimeTypes = this.map(p, function (mt) {
					return [mt.type, mt.suffixes].join('~');
				}).join(',');
				return [p.name, p.description, mimeTypes].join('::');
			},
			this
		).join(';');
	};

	/**
	 * Fetch plugins specific to IE
	 */
	getIEPluginsString = () => {
		if (window.ActiveXObject) {
			let names = [
				'ShockwaveFlash.ShockwaveFlash',
				'AcroPDF.PDF',
				'PDF.PdfCtrl',
				'QuickTime.QuickTime',
				'rmocx.RealPlayer G2 Control',
				'rmocx.RealPlayer G2 Control.1',
				'RealPlayer.RealPlayer(tm) ActiveX Control (32-bit)',
				'RealVideo.RealVideo(tm) ActiveX Control (32-bit)',
				'RealPlayer',
				'SWCtl.SWCtl',
				'WMPlayer.OCX',
				'AgControl.AgControl', // Silverlight
				'Skype.Detection',
			];

			return this.map(names, function (name) {
				try {
					new ActiveXObject(name);
					return name;
				} catch (e) {
					return null;
				}
			}).join(';');
		} else {
			return '';
		}
	};

	getScreenResolution = () => {
		return screen.height > screen.width
			? [screen.height, screen.width]
			: [screen.width, screen.height];
	};

	getCanvasFingerprint = () => {
		let canvas = document.createElement('canvas');
		let ctx = canvas.getContext('2d');
		// https://www.browserleaks.com/canvas#how-does-it-work
		let txt = 'CANVAS_FINGERPRINT';
		ctx.textBaseline = 'top';
		ctx.font = "14px 'Arial'";
		ctx.textBaseline = 'alphabetic';
		ctx.fillStyle = '#f60';
		ctx.fillRect(125, 1, 62, 20);
		ctx.fillStyle = '#069';
		ctx.fillText(txt, 2, 15);
		ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
		ctx.fillText(txt, 4, 17);
		return canvas.toDataURL();
	};

	murmurhash3_32_gc = (key, seed) => {
		let remainder, bytes, h1, h1b, c1, c2, k1, i;

		remainder = key.length & 3; // key.length % 4
		bytes = key.length - remainder;
		h1 = seed;
		c1 = 0xcc9e2d51;
		c2 = 0x1b873593;
		i = 0;

		while (i < bytes) {
			k1 =
				(key.charCodeAt(i) & 0xff) |
				((key.charCodeAt(++i) & 0xff) << 8) |
				((key.charCodeAt(++i) & 0xff) << 16) |
				((key.charCodeAt(++i) & 0xff) << 24);
			++i;

			k1 =
				((k1 & 0xffff) * c1 + ((((k1 >>> 16) * c1) & 0xffff) << 16)) &
				0xffffffff;
			k1 = (k1 << 15) | (k1 >>> 17);
			k1 =
				((k1 & 0xffff) * c2 + ((((k1 >>> 16) * c2) & 0xffff) << 16)) &
				0xffffffff;

			h1 ^= k1;
			h1 = (h1 << 13) | (h1 >>> 19);
			h1b =
				((h1 & 0xffff) * 5 + ((((h1 >>> 16) * 5) & 0xffff) << 16)) &
				0xffffffff;
			h1 =
				(h1b & 0xffff) +
				0x6b64 +
				((((h1b >>> 16) + 0xe654) & 0xffff) << 16);
		}

		k1 = 0;

		switch (remainder) {
			case 3:
				k1 ^= (key.charCodeAt(i + 2) & 0xff) << 16;
			case 2:
				k1 ^= (key.charCodeAt(i + 1) & 0xff) << 8;
			case 1:
				k1 ^= key.charCodeAt(i) & 0xff;

				k1 =
					((k1 & 0xffff) * c1 +
						((((k1 >>> 16) * c1) & 0xffff) << 16)) &
					0xffffffff;
				k1 = (k1 << 15) | (k1 >>> 17);
				k1 =
					((k1 & 0xffff) * c2 +
						((((k1 >>> 16) * c2) & 0xffff) << 16)) &
					0xffffffff;
				h1 ^= k1;
		}

		h1 ^= key.length;

		h1 ^= h1 >>> 16;
		h1 =
			((h1 & 0xffff) * 0x85ebca6b +
				((((h1 >>> 16) * 0x85ebca6b) & 0xffff) << 16)) &
			0xffffffff;
		h1 ^= h1 >>> 13;
		h1 =
			((h1 & 0xffff) * 0xc2b2ae35 +
				((((h1 >>> 16) * 0xc2b2ae35) & 0xffff) << 16)) &
			0xffffffff;
		h1 ^= h1 >>> 16;

		return h1 >>> 0;
	};
}
