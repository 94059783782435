import React, { useState, memo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledAccordion = styled(Accordion)(({ small, transparent }) => ({
	boxShadow: 'none',
	borderBottom: transparent ? '1px solid rgba(217, 217, 217, 0.2)' : 'none',
	'&::before': {
		height: 0,
	},
	'&.Mui-expanded': {
		margin: 0,
	},
	'.MuiAccordionDetails-root': {
		padding: 0,
		margin: 0,
	},
	'.MuiAccordionSummary-content': {
		padding: 0,
		margin: 0,
		'&.Mui-expanded': {
			margin: 0,
		},
	},
	'.MuiAccordionSummary-root': {
		padding: 0,
		margin: '0 0 2px 0',
		minHeight: small ? 'unset' : '40px',
		backgroundColor: transparent ? 'transparent' : 'rgba(77, 121, 234, 0.06)',
		'&.Mui-expanded': {
			minHeight: 'unset',
		},
	},
	'.MuiAccordionSummary-expandIconWrapper': {
		padding: '6px',
		color: 'rgba(0, 0, 0, 0.6)',
	},
}));

const CustomAccordion = memo(({ title, small, transparent, isDraggable, setDraggingItem, selected, children }) => {
	const [isDragging, setIsDragging] = useState(false);

	const handleDragStart = () => {
		if (isDraggable && !selected) {
			setIsDragging(true);
			setDraggingItem({ value: title });
		}
	};

	const handleDragEnd = () => {
		if (isDraggable) {
			setIsDragging(false);
			setDraggingItem(null);
		}
	};

	return (
		<StyledAccordion small={small} transparent={transparent}>
			<AccordionSummary
				draggable={isDraggable && !selected}
				expandIcon={isDragging ? null : <ExpandMoreIcon />}
				sx={{
					'&.MuiAccordionSummary-root': {
						padding: isDragging ? '8px 0' : 'none',
						border: isDragging ? '1px solid #4D79EA' : 'none',
						opacity: selected ? '0.5' : '1',
						borderRadius: isDragging ? '10px' : '0',
					},
				}}
				onDragEnd={handleDragEnd}
				onDragStart={handleDragStart}
			>
				<Box sx={{ marginLeft: transparent ? '26px' : '15px', display: 'flex', alignItems: 'center' }}>
					{small && (
						<Box
							sx={{
								marginRight: '10px',
								width: '4px',
								height: '20px',

								background: '#E4E8FF',
								borderRadius: '10px',
							}}
						/>
					)}
					<Typography
						sx={{
							fontFamily: 'Inter',
							fontSize: '14px',
							fontWeight: 500,
							lineHeight: '17px',
							color: 'rgba(0, 0, 0, 0.6)',
						}}
					>
						{title}
					</Typography>
				</Box>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</StyledAccordion>
	);
});

export default CustomAccordion;
