import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const GridRow = ({ classes, options, rowSelected, onClick, className, isRowSelectable, ...rest }) => {
	const methods = {};
	if (onClick) {
		methods.onClick = onClick;
	}

	return (
		<TableRow
			hover={!!options.rowHover}
			{...methods}
			className={classnames(
				{
					[classes.root]: true,
					[classes.hover]: options.rowHover,
					[classes.hoverCursor]:
						(options.selectableRowsOnClick && isRowSelectable) || options.expandableRowsOnClick,
					[classes.responsiveSimple]: options.responsive === 'simple',
					[classes.responsiveStacked]:
						options.responsive === 'vertical' ||
						options.responsive === 'stacked' ||
						options.responsive === 'stackedFullWidth',
					'mui-row-selected': rowSelected,
				},
				className
			)}
			selected={rowSelected}
			{...rest}
		>
			{this.props.children}
		</TableRow>
	);
};

export default GridRow;
