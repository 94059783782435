import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import React from 'react';

const SwitchComponent = ({ model, formField, disabled, onChange, className }) => {
	const getDefaultValue = () => formField.mapping.split('.').reduce((p, c) => (p && p[c]) || null, model);

	return (
		<FormControl>
			<FormControlLabel
				control={
					<Switch
						key={`${formField.mapping}-field`}
						fullWidth
						checked={getDefaultValue()}
						className={className}
						color="rsPrimary"
						data-cy="form-field"
						data-testid="form-field"
						disabled={disabled || formField.disabled}
						id={formField.id ? formField.id : `form-field-${formField.name}`}
						onChange={(event, value) =>
							onChange(
								{ target: { value } },
								value,
								formField.mapping ? formField.mapping : formField.name
							)
						}
					/>
				}
				label={formField.name}
			/>
		</FormControl>
	);
};

const SwitchComponentMemo = React.memo(SwitchComponent);

export default SwitchComponentMemo;
