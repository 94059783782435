// @flow

// core
import React, { type Node } from 'react';
// components
import { LoaderSkeleton } from './LoaderSkeleton';
import { LoaderConsultingPhysician } from './LoaderConsultingPhysician';
import { LoaderNotes } from './LoaderNotes';
import { LoaderPatientAlerts } from './LoaderPatientAlerts';
import { LoaderPatientDocuments } from './LoaderPatientDocuments';
import { LoaderStudyListV2 } from './LoaderStudyListV2';
import { PatientCard } from './PatientCard';
import { LoaderPatientAllergies } from './LoaderPatientAllergies';
import { LinkCards } from './LinkCards';
import { MergeCards } from './MergeCards';
import { NewWorklist } from './NewWorklist';
import { PatientBirthDate } from './PatientBirthDate';
import { SmokingAlcoholObservations } from './SmokingAlcoholObservations';
import { OutstandingBalance } from './OutstandingBalance';
import { PatientGender } from './PatientGender';
import { PatientPanelSection } from './PatientPanelSection';
import { Visit } from './Visit';
import { VisitList } from './VisitList';
import { Study } from './Study';
import { Orders } from './Orders';

export class Loader extends React.Component<any> {
	static Skeleton: any = LoaderSkeleton;

	static ConsultingPhysician: any = LoaderConsultingPhysician;

	static Notes: any = LoaderNotes;

	static PatientAlerts: any = LoaderPatientAlerts;

	static PatientAllergies: any = LoaderPatientAllergies;

	static PatientDocuments: any = LoaderPatientDocuments;

	static StudyListV2: any = LoaderStudyListV2;

	static PatientCard: any = PatientCard;

	static LinkCards: any = LinkCards;

	static MergeCards: any = MergeCards;

	static NewWorklist: any = NewWorklist;

	static PatientBirthDate: any = PatientBirthDate;

	static SmokingAlcoholObservations: any = SmokingAlcoholObservations;

	static OutstandingBalance: any = OutstandingBalance;

	static PatientGender: any = PatientGender;

	static PatientPanelSection: any = PatientPanelSection;

	static Visit: any = Visit;

	static Orders: any = Orders;

	static VisitList: any = VisitList;

	static Study: any = Study;

	render(): Node {
		return <LoaderSkeleton {...this.props} />;
	}
}
