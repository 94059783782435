import { vec3, vec2 } from 'gl-matrix';
import { getEnabledElement } from '@cornerstonejs/core';
import { state } from '@cornerstonejs/tools/dist/esm/store';
import { Events } from '@cornerstonejs/tools/dist/esm/enums';
import { resetElementCursor, hideElementCursor } from '@cornerstonejs/tools/dist/esm/cursors/elementCursor';
import { polyline } from '@cornerstonejs/tools/dist/esm/utilities/math';
import { ContourWindingDirection } from '@cornerstonejs/tools/dist/esm/types/ContourAnnotation';
import {
	getInterpolatedPoints,
	shouldSmooth,
} from '@cornerstonejs/tools/dist/esm/utilities/planarFreehandROITool/smoothPoints';
import triggerAnnotationRenderForViewportIds from '@cornerstonejs/tools/dist/esm/utilities/triggerAnnotationRenderForViewportIds';
import updateContourPolyline from '@cornerstonejs/tools/dist/esm/utilities/contours/updateContourPolyline';
import { triggerAnnotationModified } from '@cornerstonejs/tools/dist/esm/stateManagement/annotation/helpers/state';

const { getSubPixelSpacingAndXYDirections, addCanvasPointsToArray, getArea } = polyline;

function activateClosedContourEdit(evt, annotation, viewportIdsToRender) {
	this.isEditingClosed = true;

	const eventDetail = evt.detail;
	const { currentPoints, element } = eventDetail;
	const canvasPos = currentPoints.canvas;
	const enabledElement = getEnabledElement(element);
	if (!enabledElement) {
		// Occurs on shutdown
		return;
	}
	const { viewport } = enabledElement;

	const prevCanvasPoints = annotation.data.contour.polyline.map(viewport.worldToCanvas);

	const { spacing, xDir, yDir } = getSubPixelSpacingAndXYDirections(viewport, this.configuration.subPixelResolution);

	this.editData = {
		prevCanvasPoints,
		editCanvasPoints: [canvasPos],
		startCrossingIndex: undefined,
		editIndex: 0,
	};

	this.commonData = {
		annotation,
		viewportIdsToRender,
		spacing,
		xDir,
		yDir,
	};

	state.isInteractingWithTool = true;

	element.addEventListener(Events.MOUSE_UP, this.mouseUpClosedContourEditCallback);
	element.addEventListener(Events.MOUSE_DRAG, this.mouseDragClosedContourEditCallback);
	element.addEventListener(Events.MOUSE_CLICK, this.mouseUpClosedContourEditCallback);

	element.addEventListener(Events.TOUCH_END, this.mouseUpClosedContourEditCallback);
	element.addEventListener(Events.TOUCH_DRAG, this.mouseDragClosedContourEditCallback);
	element.addEventListener(Events.TOUCH_TAP, this.mouseUpClosedContourEditCallback);

	hideElementCursor(element);
}

function deactivateClosedContourEdit(element) {
	state.isInteractingWithTool = false;

	element.removeEventListener(Events.MOUSE_UP, this.mouseUpClosedContourEditCallback);
	element.removeEventListener(Events.MOUSE_DRAG, this.mouseDragClosedContourEditCallback);
	element.removeEventListener(Events.MOUSE_CLICK, this.mouseUpClosedContourEditCallback);

	element.removeEventListener(Events.TOUCH_END, this.mouseUpClosedContourEditCallback);
	element.removeEventListener(Events.TOUCH_DRAG, this.mouseDragClosedContourEditCallback);
	element.removeEventListener(Events.TOUCH_TAP, this.mouseUpClosedContourEditCallback);

	resetElementCursor(element);
}

function mouseDragClosedContourEditCallback(evt) {
	const eventDetail = evt.detail;
	const { currentPoints, element } = eventDetail;
	const worldPos = currentPoints.world;
	const canvasPos = currentPoints.canvas;
	const enabledElement = getEnabledElement(element);
	const { renderingEngine, viewport } = enabledElement;

	const { viewportIdsToRender, xDir, yDir, spacing } = this.commonData;
	const { editIndex, editCanvasPoints, startCrossingIndex } = this.editData;

	const lastCanvasPoint = editCanvasPoints[editCanvasPoints.length - 1];
	const lastWorldPoint = viewport.canvasToWorld(lastCanvasPoint);

	const worldPosDiff = vec3.create();

	vec3.subtract(worldPosDiff, worldPos, lastWorldPoint);

	const xDist = Math.abs(vec3.dot(worldPosDiff, xDir));
	const yDist = Math.abs(vec3.dot(worldPosDiff, yDir));

	// Check that we have moved at least one voxel in each direction.
	if (xDist <= spacing[0] && yDist <= spacing[1]) {
		// Haven't changed world point enough, don't render
		return;
	}

	if (startCrossingIndex !== undefined) {
		// Edge case: If the edit line itself crosses, remove part of that edit line so we don't
		// Get isolated regions.
		this.checkAndRemoveCrossesOnEditLine(evt);
	}

	const numPointsAdded = addCanvasPointsToArray(element, editCanvasPoints, canvasPos, this.commonData);

	const currentEditIndex = editIndex + numPointsAdded;

	this.editData.editIndex = currentEditIndex;

	if (startCrossingIndex === undefined && editCanvasPoints.length > 1) {
		// If we haven't found the index of the first crossing yet,
		// see if we can find it.
		this.checkForFirstCrossing(evt, true);
	}

	this.editData.snapIndex = this.findSnapIndex();

	if (this.editData.snapIndex === -1) {
		// No point on the prevCanvasPoints for the editCanvasPoints line to
		// snap to. Apply edit, and start a new edit as we've gone back on ourselves.
		this.finishEditAndStartNewEdit(evt);
		return;
	}

	this.editData.fusedCanvasPoints = this.fuseEditPointsWithClosedContour(evt);

	if (startCrossingIndex !== undefined && this.checkForSecondCrossing(evt, true)) {
		// Crossed a second time, apply edit, and start a new edit from the crossing.
		this.removePointsAfterSecondCrossing(true);
		this.finishEditAndStartNewEdit(evt);
	}

	triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
}

function finishEditAndStartNewEdit(evt) {
	const eventDetail = evt.detail;
	const { element } = eventDetail;
	const enabledElement = getEnabledElement(element);
	const { viewport, renderingEngine } = enabledElement;

	const { annotation, viewportIdsToRender } = this.commonData;
	const { fusedCanvasPoints, editCanvasPoints } = this.editData;

	updateContourPolyline(
		annotation,
		{
			points: fusedCanvasPoints,
			closed: true,
			targetWindingDirection: ContourWindingDirection.Clockwise,
		},
		viewport
	);

	// If any manual update, triggered on an annotation, then it will be treated as non-autogenerated.
	if (annotation.autoGenerated) {
		annotation.autoGenerated = false;
	}

	triggerAnnotationModified(annotation, element);

	const lastEditCanvasPoint = editCanvasPoints.pop();

	this.editData = {
		prevCanvasPoints: fusedCanvasPoints,
		editCanvasPoints: [lastEditCanvasPoint],
		startCrossingIndex: undefined,
		editIndex: 0,
		snapIndex: undefined,
	};

	triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
}

function fuseEditPointsWithClosedContour(evt) {
	const { prevCanvasPoints, editCanvasPoints, startCrossingIndex, snapIndex } = this.editData;

	if (startCrossingIndex === undefined || snapIndex === undefined) {
		return;
	}

	const eventDetail = evt.detail;
	const { element } = eventDetail;

	// Augment the editCanvasPoints array, between the end of edit and the snap index.
	const augmentedEditCanvasPoints = [...editCanvasPoints];

	addCanvasPointsToArray(element, augmentedEditCanvasPoints, prevCanvasPoints[snapIndex], this.commonData);

	if (augmentedEditCanvasPoints.length > editCanvasPoints.length) {
		// If any points added, remove the last point, which will be a clone of the snapIndex
		augmentedEditCanvasPoints.pop();
	}

	// Calculate the distances between the first and last edit points and the origin of the
	// Contour with the snap point. These will be used to see which way around the edit array should be
	// Placed within the preview.
	let lowIndex;
	let highIndex;

	if (startCrossingIndex > snapIndex) {
		lowIndex = snapIndex;
		highIndex = startCrossingIndex;
	} else {
		lowIndex = startCrossingIndex;
		highIndex = snapIndex;
	}

	const distanceBetweenLowAndFirstPoint = vec2.distance(prevCanvasPoints[lowIndex], augmentedEditCanvasPoints[0]);

	const distanceBetweenLowAndLastPoint = vec2.distance(
		prevCanvasPoints[lowIndex],
		augmentedEditCanvasPoints[augmentedEditCanvasPoints.length - 1]
	);

	const distanceBetweenHighAndFirstPoint = vec2.distance(prevCanvasPoints[highIndex], augmentedEditCanvasPoints[0]);

	const distanceBetweenHighAndLastPoint = vec2.distance(
		prevCanvasPoints[highIndex],
		augmentedEditCanvasPoints[augmentedEditCanvasPoints.length - 1]
	);

	// Generate two possible contours that could be intepreted from the edit:
	//
	// pointSet1 => 0 -> low -> edit -> high - max.
	// pointSet2 => low -> high -> edit
	//
	// Depending on the placement of the edit and the origin, either of these could be the intended edit.
	// We'll choose the one with the largest area, as edits are considered to be changes to the original area with
	// A relative change of much less than unity.

	// Point Set 1
	const pointSet1 = [];

	// Add points from the orignal contour origin up to the low index.
	for (let i = 0; i < lowIndex; i++) {
		const canvasPoint = prevCanvasPoints[i];

		pointSet1.push([canvasPoint[0], canvasPoint[1]]);
	}

	// Check which orientation of the edit line minimizes the distance between the
	// origial contour low/high points and the start/end nodes of the edit line.

	let inPlaceDistance = distanceBetweenLowAndFirstPoint + distanceBetweenHighAndLastPoint;

	let reverseDistance = distanceBetweenLowAndLastPoint + distanceBetweenHighAndFirstPoint;

	if (inPlaceDistance < reverseDistance) {
		for (let i = 0; i < augmentedEditCanvasPoints.length; i++) {
			const canvasPoint = augmentedEditCanvasPoints[i];

			pointSet1.push([canvasPoint[0], canvasPoint[1]]);
		}
	} else {
		for (let i = augmentedEditCanvasPoints.length - 1; i >= 0; i--) {
			const canvasPoint = augmentedEditCanvasPoints[i];

			pointSet1.push([canvasPoint[0], canvasPoint[1]]);
		}
	}

	// Add points from the orignal contour's high index up to to its end point.
	for (let i = highIndex; i < prevCanvasPoints.length; i++) {
		const canvasPoint = prevCanvasPoints[i];

		pointSet1.push([canvasPoint[0], canvasPoint[1]]);
	}

	// Point Set 2
	const pointSet2 = [];

	for (let i = lowIndex; i < highIndex; i++) {
		const canvasPoint = prevCanvasPoints[i];

		pointSet2.push([canvasPoint[0], canvasPoint[1]]);
	}

	inPlaceDistance = distanceBetweenHighAndFirstPoint + distanceBetweenLowAndLastPoint;

	reverseDistance = distanceBetweenHighAndLastPoint + distanceBetweenLowAndFirstPoint;

	if (inPlaceDistance < reverseDistance) {
		for (let i = 0; i < augmentedEditCanvasPoints.length; i++) {
			const canvasPoint = augmentedEditCanvasPoints[i];

			pointSet2.push([canvasPoint[0], canvasPoint[1]]);
		}
	} else {
		for (let i = augmentedEditCanvasPoints.length - 1; i >= 0; i--) {
			const canvasPoint = augmentedEditCanvasPoints[i];

			pointSet2.push([canvasPoint[0], canvasPoint[1]]);
		}
	}

	const areaPointSet1 = getArea(pointSet1);
	const areaPointSet2 = getArea(pointSet2);

	const pointsToRender = areaPointSet1 > areaPointSet2 ? pointSet1 : pointSet2;

	return pointsToRender;
}

function mouseUpClosedContourEditCallback(evt) {
	const eventDetail = evt.detail;
	const { element } = eventDetail;

	this.completeClosedContourEdit(element);
}

function completeClosedContourEdit(element) {
	const enabledElement = getEnabledElement(element);
	const { viewport, renderingEngine } = enabledElement;

	const { annotation, viewportIdsToRender } = this.commonData;
	const { fusedCanvasPoints, prevCanvasPoints } = this.editData;

	if (fusedCanvasPoints) {
		const updatedPoints = shouldSmooth(this.configuration, annotation)
			? getInterpolatedPoints(this.configuration, fusedCanvasPoints, prevCanvasPoints)
			: fusedCanvasPoints;

		updateContourPolyline(
			annotation,
			{
				points: updatedPoints,
				closed: true,
				targetWindingDirection: ContourWindingDirection.Clockwise,
			},
			viewport
		);

		// If any manual update, triggered on an annotation, then it will be treated as non-autogenerated.
		if (annotation.autoGenerated) {
			annotation.autoGenerated = false;
		}

		triggerAnnotationModified(annotation, element);
	}

	this.isEditingClosed = false;
	this.editData = undefined;
	this.commonData = undefined;

	triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

	this.deactivateClosedContourEdit(element);
}

function cancelClosedContourEdit(element) {
	this.completeClosedContourEdit(element);
}

function registerClosedContourEditLoop(toolInstance) {
	toolInstance.activateClosedContourEdit = activateClosedContourEdit.bind(toolInstance);
	toolInstance.deactivateClosedContourEdit = deactivateClosedContourEdit.bind(toolInstance);
	toolInstance.mouseDragClosedContourEditCallback = mouseDragClosedContourEditCallback.bind(toolInstance);
	toolInstance.mouseUpClosedContourEditCallback = mouseUpClosedContourEditCallback.bind(toolInstance);
	toolInstance.finishEditAndStartNewEdit = finishEditAndStartNewEdit.bind(toolInstance);
	toolInstance.fuseEditPointsWithClosedContour = fuseEditPointsWithClosedContour.bind(toolInstance);
	toolInstance.cancelClosedContourEdit = cancelClosedContourEdit.bind(toolInstance);
	toolInstance.completeClosedContourEdit = completeClosedContourEdit.bind(toolInstance);
}

export default registerClosedContourEditLoop;
