import {
	PERMISSIONS_MODULES,
	QC_MODULE_PERMISSIONS,
} from '@worklist-2/worklist/src/DocumentViewerV3/consts/imageViewerPermissionTags';

export const VIEWPORT = 'viewport';
export const DOCUMENT_VIEWER = 'document_viewer';
export const REPARENTING = 'reparenting';
export const KEY_IMAGE = 'key_image';
export const IMPORT = 'import';
export const SERIES = 'series';
export const SERIES_FRAME = 'series_frame';

export const DRAG_N_DROP_DISABLING_RULES = {
	[VIEWPORT]: count => count > 1,
	[REPARENTING]: (_, isViewOnly, permissions) =>
		Boolean(isViewOnly || !permissions?.[PERMISSIONS_MODULES.QC_MODULE]?.[QC_MODULE_PERMISSIONS.MOVE_SERIES]?.read),
	[KEY_IMAGE]: targetType => targetType === REPARENTING,
	[SERIES]: targetType => targetType === DOCUMENT_VIEWER,
	[SERIES_FRAME]: targetType => targetType !== DOCUMENT_VIEWER,
	[DOCUMENT_VIEWER]: () => false,
};

export const SNAPSHOT_CONFIG = {
	imageSize: 600,
	mimeType: 'image/jpeg',
};

export const ORIGINAL_THUMBNAIL_SIZE = 108;
