// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aFjxKl3WuZA9LDLZc174{margin:0 70px}.aFjxKl3WuZA9LDLZc174 img{width:100%}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/common/MainArea/Draft/AttachmentBlocks/ImageAttachment.module.scss"],"names":[],"mappings":"AAAA,sBACC,aAAA,CAEA,0BACC,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageBlock": "aFjxKl3WuZA9LDLZc174"
};
export default ___CSS_LOADER_EXPORT___;
