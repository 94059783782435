const handleSSNChange = (event, onChange) => {
	const inputValue = event.target.value;
	const digitsOnly = inputValue.replace(/\D/g, '');
	let formattedValue = '';
	if (digitsOnly.length >= 5) {
		formattedValue = `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3, 5)}-${digitsOnly.slice(5, 9)}`;
	} else if (digitsOnly.length >= 3) {
		formattedValue = `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3)}`;
	} else {
		formattedValue = digitsOnly;
	}

	onChange(formattedValue);

	// Handle backspace key
	if (event.nativeEvent.inputType === 'deleteContentBackward') {
		if (inputValue.length === 3) {
			onChange(inputValue.slice(0, 2));
		}

		if (inputValue.length === 6) {
			onChange(inputValue.slice(0, 5));
		}
	}
};

export default handleSSNChange;
