import React, { useState } from 'react';
import useLongPress from './useLongpress';
import { CircularProgress, IconButton, Tooltip, Box } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useTranslation } from 'react-i18next';

export const ProgressDeleteButton = ({
	tooltipLabel,
	circleColor = 'rsPrimary.main',
	onDelete,
	shouldPreventDefault = true,
	delay = 1000,
	onMouseOver,
	onMouseOut,
	...props
}) => {
	const { t } = useTranslation('imageViewer');
	const [value, setValue] = useState(0);

	const onLongPress = () => {
		setValue(0);
		onDelete();
	};

	const onClick = () => {
		setValue(0);
	};

	const onStart = e => {
		e.preventDefault();
		e.stopPropagation();
		setValue(100);
	};
	const longPressEvent = useLongPress(
		onLongPress,
		onClick,
		{
			shouldPreventDefault,
			delay,
		},
		onStart
	);

	return (
		<Tooltip title={tooltipLabel || t('series.Hold to Delete')} {...props.tooltip}>
			<Box
				{...props.wrapper}
				{...longPressEvent}
				{...(onMouseOver ? { onMouseOver } : null)}
				{...(onMouseOver ? { onMouseOut } : null)}
				data-testid="remove-icon"
			>
				<IconButton
					aria-label="delete"
					data-testid="progress-delete-button"
					size="small"
					sx={{
						background: '#48464A',
						color: '#CF6679',
					}}
					{...props.iconButton}
				>
					<Box
						sx={{
							position: 'relative',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<DeleteOutlinedIcon fontSize="inherit" {...props.icon} />
						<Box
							sx={{
								position: 'absolute',
								left: '50%',
								top: '50%',
								transform: 'translate3d(-50%, -50%, 0)',

								display: 'flex',
							}}
						>
							<CircularProgress
								size={28}
								sx={{
									color: `${circleColor}`,
									'& .MuiCircularProgress-circle': {
										transition: value
											? 'stroke-dashoffset 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
											: 'none',
									},
								}}
								thickness={4}
								value={value}
								variant="determinate"
								{...props.circularProgress}
							/>
						</Box>
					</Box>
				</IconButton>
				{props.children}
			</Box>
		</Tooltip>
	);
};
