import { Popover, Divider, Box, Stack, Typography } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CheckIcon from '@worklist-2/ui/src/assets/icons/checkmark.svg';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { getSeriesModality } from '../../utils/utils';
import { PRESETS } from './constants';
import { PresetMenuItem } from './PresetMenuItem';
import { useImageViewerWindowLevelContext } from '../../contexts/ImageViewerWindowLevelContext';
import { ApplyAutoWindowLevelToViewport } from '../CustomToolbar/components/Tools/WindowLevel/AutoWindowLevel';
import { SigmoidItem } from './Sigmoid';
import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined';
import { HotKeyText } from './HotKeyInputItem';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import useAddHotkeys from '../hotkeys/useAddHotkeys';
import hotkeyCodes from '../hotkeys/hotkeyCodes';
import { useImageViewerLayoutContext } from '../../contexts/ImageViewerLayoutContext';
import getDefaultRenderingEngine from '@rs-ui/views/ImageViewerView3D/cornerstone/getDefaultRenderingEngine';
import { utilities, getEnabledElement } from '@cornerstonejs/core';

function getWindowCenter(series) {
	const value = series?.metadata?.[0]?.['00281050']?.Value?.[0];
	if (typeof value === 'string') {
		return +value;
	}
	return value;
}

function getWindowWidth(series) {
	const value = series?.metadata?.[0]?.['00281051']?.Value?.[0];
	if (typeof value === 'string') {
		return +value;
	}
	return value;
}

export const WindowLevelPresetMenu = ({
	viewportId,
	series,
	position,
	isOpen,
	onClose,
	setIsShownWindowLevelPresetMenu,
}) => {
	const wonIvCustomwl = useBooleanFlagValue('WON-IV-CUSTOMWL');
	const wonIvWindowPresetModality = useBooleanFlagValue('won-iv-window-preset-modality-binding');
	const wonIvAutoWindowLevel = useBooleanFlagValue('won-iv-auto-window-level');
	const wonIvSigmoid = useBooleanFlagValue('won-iv-sigmoid');
	const ivWonWlHotkeyPreNext = useBooleanFlagValue('IV-WON-WL-HOTKEY-PRE-NEXT');
	const wonIvWlHotkey = useBooleanFlagValue('WON-IV-WL-HOTKEY');
	const { setIsShowWindowLevelConfigDrawer, currWindowLevelPresets, onWindowLevelPresetChange } =
		useImageViewerWindowLevelContext();

	const { setActiveViewportModality } = useImageViewerLayoutContext();
	const { defaultWindowLevelRef } = useImageViewerWindowLevelContext();
	const [currentPreset, setCurrentWindowLevelPreset] = useState(null);

	const renderingEngine = getDefaultRenderingEngine();
	const viewport = renderingEngine?.getViewport(viewportId);
	const element = viewport?.element;

	const modality = getSeriesModality(series);
	const windowCenter = getWindowCenter(series) || defaultWindowLevelRef?.current?.windowCenter;
	const windowWidth = getWindowWidth(series) || defaultWindowLevelRef?.current?.windowWidth;

	const presets = useMemo(() => {
		let result = [];
		if (wonIvCustomwl) {
			if (currWindowLevelPresets) {
				result = currWindowLevelPresets.filter(
					item =>
						item?.menuLabel !== 'Auto' &&
						item?.menuLabel !== 'Sigmoid' &&
						// eslint-disable-next-line no-nested-ternary
						(wonIvWindowPresetModality
							? item?.modality
								? item.modality === modality
								: modality === 'CT'
							: true)
				);
			} else {
				result = PRESETS.filter(item =>
					// eslint-disable-next-line no-nested-ternary
					wonIvWindowPresetModality ? (item?.modality ? item.modality === modality : modality === 'CT') : true
				);
			}
		} else {
			result = modality === 'CT' ? [...PRESETS] : [];
		}

		return result;
	}, [
		windowWidth,
		windowCenter,
		currentPreset,
		wonIvCustomwl,
		currWindowLevelPresets,
		wonIvWindowPresetModality,
		modality,
	]);

	const currentPresetItem = useMemo(() => {
		const item = presets.find(
			i => currentPreset?.windowWidth === i.windowWidth && currentPreset?.windowCenter === i.windowCenter
		);
		return item || null;
	}, [presets, currentPreset]);

	const handlePreviousPresetClick = useCallback(() => {
		if (!currentPresetItem) {
			const lastPreset = presets[presets.length - 1];
			handlePresetChange(lastPreset);
		} else {
			const currentIndex = presets.indexOf(currentPresetItem);
			const previousItem = currentIndex === 0 ? presets[presets.length - 1] : presets[currentIndex - 1];
			handlePresetChange(previousItem);
		}
	}, [currentPresetItem, presets]);

	const handleNextPresetClick = useCallback(() => {
		if (!currentPresetItem) {
			const firstPreset = presets?.[0];
			handlePresetChange(firstPreset);
		} else {
			const currentIndex = presets.indexOf(currentPresetItem);
			const nextItem = currentIndex === presets.length - 1 ? presets[0] : presets[currentIndex + 1];
			handlePresetChange(nextItem);
		}
	}, [currentPresetItem, presets]);

	const handlePresetChange = preset => {
		if (preset) {
			setCurrentWindowLevelPreset(preset);
			setIsShownWindowLevelPresetMenu(false);
			onWindowLevelPresetChange(preset, viewportId);
		}
	};

	useAddHotkeys([
		{ code: hotkeyCodes.End, callback: handleNextPresetClick },
		{ code: hotkeyCodes.Home, callback: handlePreviousPresetClick },
	]);

	const autoWindowLevelHotkey = currWindowLevelPresets?.find(item => item?.menuLabel === 'Auto')?.hotkey;
	const sigmoidHotkey = currWindowLevelPresets?.find(item => item?.menuLabel === 'Sigmoid')?.hotkey;

	const resetItem =
		windowWidth !== undefined && windowCenter !== undefined
			? { menuLabel: 'Reset to Default', windowCenter, windowWidth }
			: null;

	const ifShowReset = useCallback(() => {
		if (element) {
			const enabledViewport = getEnabledElement(element);
			if (enabledViewport?.viewport?.voiRange?.lower && enabledViewport?.viewport?.voiRange?.upper) {
				const { lower, upper } = utilities.windowLevel.toLowHighRange(windowWidth, windowCenter);
				return (
					Math.round(lower) !== Math.round(enabledViewport?.viewport?.voiRange?.lower) ||
					Math.round(upper) !== Math.round(enabledViewport?.viewport?.voiRange?.upper)
				);
			}
		}

		return false;
	}, [element, windowWidth, windowCenter]);

	const itemStyle = {
		alignItems: 'center',
		as: 'button',
		bgcolor: 'transparent',
		border: 'none',
		'data-testid': 'preset-button',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		sx: { cursor: 'pointer' },
		variant: 'text',
		onClick: e => {
			e.stopPropagation();
		},
		onMouseDown: e => e.stopPropagation(),
		onMouseUp: e => e.stopPropagation(),
	};

	return (
		<Popover
			PaperProps={{
				'data-testid': 'window-preset-menu-paper',
				sx: {
					margin: '10px',
					width: 'auto',
					minWidth: '180px',
					padding: '14px 16px',
					paddingLeft: '5px',
					background: '#393939',
					border: '1px solid rgba(255, 255, 255, 0.1)',
					borderRadius: '6px',
					boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
					display: 'flex',
					flexDirection: 'column',
					gap: '12px',
					zIndex: 1000,
				},
			}}
			anchorPosition={{ top: position.y, left: position.x }}
			anchorReference="anchorPosition"
			open={isOpen}
			onClose={onClose}
			onMouseDown={e => e.stopPropagation()}
			onMouseUp={e => e.stopPropagation()}
		>
			{wonIvAutoWindowLevel && (
				<Box
					{...itemStyle}
					onClick={e => {
						e.stopPropagation();
						onClose();
						ApplyAutoWindowLevelToViewport(viewportId);
					}}
				>
					<Stack alignItems="center" direction="row" gap="10px" sx={{ width: '100%' }}>
						<CheckIcon style={{ visibility: 'hidden', minWidth: '18px' }} />
						<Stack
							direction="row"
							justifyContent="space-between"
							sx={{ width: '100%', alignItems: 'center', gap: '10px' }}
						>
							<Stack direction="row" sx={{ width: '100%', alignItems: 'center', gap: '10px' }}>
								<Typography
									color="rgba(255, 255, 255, 0.87)"
									fontFamily="Roboto"
									fontSize={16}
									sx={{
										whiteSpace: 'nowrap',
										maxWidth: '115px',
										textOverflow: 'ellipsis',
										overflow: 'hidden',
									}}
								>
									Auto
								</Typography>
							</Stack>
						</Stack>
						{wonIvWlHotkey && autoWindowLevelHotkey && (
							<Stack
								color="rgba(255, 255, 255, 0.6)"
								direction="row"
								sx={{
									alignItems: 'center',
									width: '100%',
									marginLeft: '10px',
									justifyContent: 'flex-end',
								}}
							>
								<KeyboardOutlinedIcon
									sx={{
										fontSize: '24px',
									}}
								/>
								<Typography fontFamily="Roboto" fontSize={16}>
									<HotKeyText item={autoWindowLevelHotkey} />
								</Typography>
							</Stack>
						)}
					</Stack>
				</Box>
			)}

			<SigmoidItem
				hotkeyItem={sigmoidHotkey}
				modality={modality}
				viewportId={viewportId}
				wonIvSigmoid={wonIvSigmoid}
				wonIvWlHotkey={wonIvWlHotkey}
				onClose={onClose}
			/>

			{ivWonWlHotkeyPreNext && presets?.length > 0 && (
				<>
					<Box
						{...itemStyle}
						onClick={e => {
							e.stopPropagation();
							handlePreviousPresetClick();
						}}
					>
						<Stack alignItems="center" direction="row" gap="10px" sx={{ width: '100%' }}>
							<CheckIcon style={{ visibility: 'hidden', minWidth: '18px' }} />
							<Stack
								direction="row"
								justifyContent="space-between"
								sx={{ width: '100%', alignItems: 'center', gap: '10px' }}
							>
								<Stack direction="row" sx={{ width: '100%', alignItems: 'center', gap: '10px' }}>
									<Typography
										color="rgba(255, 255, 255, 0.87)"
										fontFamily="Roboto"
										fontSize={16}
										sx={{
											whiteSpace: 'nowrap',
											maxWidth: '115px',
											textOverflow: 'ellipsis',
											overflow: 'hidden',
										}}
									>
										Previous Preset
									</Typography>
								</Stack>
							</Stack>
							<Stack
								color="rgba(255, 255, 255, 0.6)"
								direction="row"
								sx={{
									alignItems: 'center',
									width: '100%',
									marginLeft: '10px',
									justifyContent: 'flex-end',
								}}
							>
								<KeyboardOutlinedIcon
									sx={{
										fontSize: '24px',
									}}
								/>
								<Typography fontFamily="Roboto" fontSize={16}>
									Home
								</Typography>
							</Stack>
						</Stack>
					</Box>

					<Box
						{...itemStyle}
						onClick={e => {
							e.stopPropagation();
							handleNextPresetClick();
						}}
					>
						<Stack alignItems="center" direction="row" gap="10px" sx={{ width: '100%' }}>
							<CheckIcon style={{ visibility: 'hidden', minWidth: '18px' }} />
							<Stack
								direction="row"
								justifyContent="space-between"
								sx={{ width: '100%', alignItems: 'center', gap: '10px' }}
							>
								<Stack direction="row" sx={{ width: '100%', alignItems: 'center', gap: '10px' }}>
									<Typography
										color="rgba(255, 255, 255, 0.87)"
										fontFamily="Roboto"
										fontSize={16}
										sx={{
											whiteSpace: 'nowrap',
											maxWidth: '115px',
											textOverflow: 'ellipsis',
											overflow: 'hidden',
										}}
									>
										Next Preset
									</Typography>
								</Stack>
							</Stack>
							<Stack
								color="rgba(255, 255, 255, 0.6)"
								direction="row"
								sx={{
									alignItems: 'center',
									width: '100%',
									marginLeft: '10px',
									justifyContent: 'flex-end',
								}}
							>
								<KeyboardOutlinedIcon
									sx={{
										fontSize: '24px',
									}}
								/>
								<Typography fontFamily="Roboto" fontSize={16}>
									End
								</Typography>
							</Stack>
						</Stack>
					</Box>
				</>
			)}

			{presets.map(item => {
				const isCurrentPreset = currentPresetItem?.menuLabel === item?.menuLabel;

				return (
					<PresetMenuItem
						key={item.menuLabel}
						isCurrent={isCurrentPreset}
						item={item}
						onChange={() => handlePresetChange(item)}
					/>
				);
			})}

			{resetItem && ifShowReset() && (
				<PresetMenuItem
					key={resetItem.menuLabel}
					isCurrent={false}
					item={resetItem}
					onChange={() => handlePresetChange(resetItem)}
				/>
			)}

			{wonIvCustomwl && (
				<>
					<Divider />
					<Box
						{...itemStyle}
						onClick={e => {
							e.stopPropagation();
							onClose();
							setActiveViewportModality(modality);
							setIsShowWindowLevelConfigDrawer(true);
						}}
					>
						<Stack alignItems="center" direction="row" gap="10px">
							<SettingsOutlinedIcon
								sx={{
									fontSize: 24,
									color: 'rgba(255, 255, 255, 0.6)',
								}}
							/>
							<Typography color="rgba(255, 255, 255, 0.87)" fontFamily="Roboto" fontSize={16}>
								Configure Presets
							</Typography>
						</Stack>
					</Box>
				</>
			)}
		</Popover>
	);
};

WindowLevelPresetMenu.propTypes = {
	series: PropTypes.object,
	currentPreset: PropTypes.shape({
		windowWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		windowCenter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	}),
	position: PropTypes.shape({
		x: PropTypes.number,
		y: PropTypes.number,
	}),
	onPresetChange: PropTypes.func,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
};
