// Core

import React from 'react';

// MUI

import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

// Component

const FormToolTextField = styled(props => (
	<TextField fullWidth {...props} sx={{ '& .MuiOutlinedInput-root': { padding: '10px 16px' } }} />
))({
	'& .MuiOutlinedInput-root': {
		color: 'rgba(0,0,0,1)',
		'& fieldset': {
			borderColor: 'var(--element-content-field-border-color)',
		},
		'& .MuiOutlinedInput-input.Mui-disabled': {
			WebkitTextFillColor: 'rgba(0, 0, 0, 0.6) !important',
		},
		'& ::-webkit-scrollbar': {
			width: '8px',
			height: '8px',
		},
		'& ::-webkit-scrollbar-thumb': {
			backgroundColor: '#C4C4C4',
			borderRadius: '10px',
		},
		'& ::-webkit-scrollbar-track': {
			borderRadius: '10px',
			backgroundColor: '#c4c4c433',
		},
		'.MuiInputBase-input.Mui-disabled': {
			color: 'rgba(0,0,0,0.26)',
		},
	},
});

export default FormToolTextField;
