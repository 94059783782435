import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import _ from 'lodash';

import { Box, IconButton, CircularProgress, Typography, Icon, Select, MenuItem, TextField } from '@mui/material';
import {
	HomeOutlined,
	AccessTimeOutlined,
	Visibility,
	VisibilityOutlined,
	EditOutlined,
	CheckCircle,
	Close,
	Work,
} from '@mui/icons-material';

import { caseMapping, crmSearchScopes, useCRMDataLoader, useCRMSearchScope, useAuth } from '@worklist-2/core/src';

import Comments from './DetailTabs/Comments';
import History from './DetailTabs/History';
import Knowledge from './DetailTabs/Knowledge';
import Overview from './DetailTabs/Overview';
import Contacts from './DetailTabs/Contacts';

import ArrowUpIcon from '@worklist-2/ui/src/assets/img/arrowUp.svg';
import ArrowDownIcon from '@worklist-2/ui/src/assets/img/arrowDown.svg';
import Avatar from '@worklist-2/ui/src/assets/img/crmUserImg.png';

import { Row, Col } from '../casePopOverStyles';

import {
	Card,
	CustomSwitch,
	DivImage,
	DPad,
	Header,
	HeaderPanel,
	Knob,
	MainContainer,
	Timer,
	Title,
} from '../../../../views/CrmView/styles';

import { PRIORITIES, CaseRecordTypes } from '../utils';

const DetailsFullscreen = () => {
	const navigate = useNavigate();
	const params = useParams();
	const replaceOp = 'replace';
	const { loggedInUser, authorized } = useAuth();
	const [id, setId] = useState('');
	const [sendPrivate, setSendPrivate] = useState(false);
	const { validateCaseForm, handleOverViewTabChange, caseGlobalList } = useCRMSearchScope();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);
	const [progress, setProgress] = useState(0);
	const [counter, setCounter] = useState();

	const caseDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.caseApi,
	});

	const commentDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.commentApi,
	});

	useEffect(() => {
		const getPrettyValue = value => {
			if (String(value).length === 1) {
				return `0${value}`;
			}
			return value;
		};

		function deltaTime() {
			if (data?.CreatedDateTime) {
				const dataOfCreation = new Date(data.CreatedDateTime).getTime();
				const currentTime = new Date();
				const delta = (currentTime - dataOfCreation) / 1000;

				const deltaDay = Math.floor(delta / 86400);
				const deltaHour = Math.floor((delta / 3600) % 24);
				const deltaMinute = Math.floor((delta / 60) % 60);
				const deltaSecond = Math.floor(delta % 60);

				setCounter(
					`${getPrettyValue(deltaDay)}:${getPrettyValue(deltaHour)}:${getPrettyValue(
						deltaMinute
					)}:${getPrettyValue(deltaSecond)}`
				);
			}
		}

		const timerID = setInterval(deltaTime, 1000);

		return () => clearInterval(timerID);
	}, [data?.CreatedDateTime]);

	const [overViewData, setOverViewData] = useState({});
	const [contactData, setContactData] = useState([]);
	const [commentData, setCommentData] = useState(null);
	const [attachments, setAttachments] = useState();
	const [errMsgs, setErrMsgs] = useState({});
	const [isValidForm, setIsValidForm] = useState(true);
	const [editableIndex, setEditableIndex] = useState(null);
	const [watcherData, setWatcherData] = useState([]);
	const [watching, setWatching] = useState(false);
	const [nextCase, setNextCase] = useState();
	const [previousCase, setPreviousCase] = useState();
	const [hoveredField, setHoveredField] = useState(null);
	const [commonData, setCommonData] = useState({});

	useEffect(() => {
		if (data) {
			setContactData(
				_.map(data?.RelatedContact, contact => ({
					name: contact.FullName,
					jobTitle: contact.JobTitle,
					phone: contact.CellPhone,
					email: contact.Email,
				}))
			);
		}
	}, [data]);

	useEffect(() => {
		if (authorized && params.id !== id) {
			fetchData().catch(console.error);
		}

		getNextPreviousCase();
	}, [authorized, params.id]);

	const getNextPreviousCase = () => {
		let nextCase = '';
		let previousCase = '';
		const index = _.findIndex(caseGlobalList, { CaseId: params.id });

		if (index !== -1) {
			if (index < caseGlobalList.length - 1) {
				nextCase = caseGlobalList[index + 1].CaseId;
			}

			if (index > 0) {
				previousCase = caseGlobalList[index - 1].CaseId;
			}
		}

		setNextCase(nextCase);
		setPreviousCase(previousCase);
	};

	const fetchData = async (loader, value) => {
		const fnName = 'load';
		const bodyData = undefined;
		setLoading(true);

		try {
			await caseDataLoader[fnName]({ id: params.id }, undefined, bodyData).then(result => {
				const newData = result;
				setData(newData);
				setWatcherData(newData?.Watcher ?? []);

				if (result) {
					setId(params.id);
				}
			});
		} catch (e) {
			console.error(e);
		}

		setLoading(false);
	};

	const onChangeData = async (e, value, tab, payload) => {
		const targetId = e.target.id ? e.target.id : '';
		const targetParentId = e.target.parentElement?.id ? e.target.parentElement.id : '';
		let result = '';

		if (targetId.split('_')[0] === 'closeEditButton' || targetParentId.split('_')[0] === 'closeEditButton') {
			const caseData = overViewData;
			delete caseData[caseMapping[value]];
			if (value === 'account_name') {
				delete caseData[caseMapping.account_id];
			}
			setOverViewData(caseData);
			setEditableIndex(null);
			setErrMsgs({});
		} else if (targetId.split('_')[0] === 'submitButton' || targetParentId.split('_')[0] === 'submitButton') {
			if (validateCaseForm(overViewData, setErrMsgs, setIsValidForm, true)) {
				const caseJson = buildCaseJson(data);
				setEditableIndex(null);
				const fieldName = [caseMapping[value]];
				const fieldValue = [caseJson[caseMapping[value]]];
				if (value === 'account_name') {
					fieldName.push(caseMapping.account_id);
					fieldValue.push(caseJson[caseMapping.account_id]);
				}
				if (value === 'duplicate_case_of') {
					fieldName.push(caseMapping.duplicate_case_of_id);
					fieldValue.push(caseJson[caseMapping.duplicate_case_of_id]);
				}
				if (value === 'parent_case') {
					fieldName.push(caseMapping.parent_case_id);
					fieldValue.push(caseJson[caseMapping.parent_case_id]);
				}
				if (value === 'project_name') {
					if (payload) {
						fieldName.push(caseMapping.project_name_id);
						fieldValue[0] = payload;
						fieldValue[1] = '';
					} else {
						fieldName.push(caseMapping.project_name_id);
						fieldValue.push(caseJson[caseMapping.project_name_id]);
					}
					setData(prev => ({ ...prev, ProjectName: fieldValue[0] }));
				}
				result = await caseDataLoader.patch(data.CaseId, fieldName, fieldValue, replaceOp);
				setData(result);
			}
		} else if (targetId.split('_')[0] === 'submitComment' || targetParentId.split('_')[0] === 'submitComment') {
			if (commentData) {
				const commentJson = buildCommentJson(commentData, attachments, data, value);
				result = await commentDataLoader.save(null, commentJson, null, setProgress);
				setCommentData(null);
				setAttachments(null);
				setProgress(0);
			}
		} else if (targetId.split('_')[0] === 'submitWatcher' || targetParentId.split('_')[0] === 'submitWatcher') {
			if (loggedInUser.permission.Case.Edit) {
				const tempData = [...watcherData];
				if (isWatching()) {
					_.remove(tempData, watcher => watcher.UserId === loggedInUser.id);
				} else {
					tempData.push({
						UserName: loggedInUser.display,
						UserId: loggedInUser.id,
					});
				}

				setWatcherData(tempData);
				const caseJson = buildCaseJson(data, tempData);
				const fieldName = ['Watcher'];
				const fieldValue = [caseJson.Watcher];
				result = await caseDataLoader.patch(data.CaseId, fieldName, fieldValue, replaceOp);
			}
		} else if (tab === 'overview') {
			handleOverViewTabChange(e.target.name, e.target.value, overViewData, setOverViewData);
		} else if (tab === 'contacts') {
			handleContactTabChange(value);
		} else if (tab === 'comments') {
			handleCommentsTabChange(e.target.value);
		}

		return result;
	};

	const handleContactTabChange = value => {
		let tempData = contactData;
		if (value.Index == undefined) {
			tempData.push(value);
		} else if (value.Deleted) {
			tempData = _.remove(tempData, currentObject => !currentObject.Deleted);
		}
		setContactData(tempData);
	};

	const handleCommentsTabChange = value => {
		setCommentData(value);
	};

	const handleSubmit = event => {
		event.preventDefault();
		if (validateCaseForm(overViewData, setErrMsgs, setIsValidForm, true) && !!loggedInUser.permission.Case.Edit) {
			const caseJson = buildCaseJson(data);
			setEditableIndex(null);
			const fieldName = [];
			const fieldValue = [];
			if (
				document.activeElement.value === 'contactSubmit' ||
				document.activeElement.value === 'submitNewContact'
			) {
				fieldName.push('RelatedContact');
				fieldValue.push(caseJson.RelatedContact);
				caseDataLoader.patch(data.CaseId, fieldName, fieldValue, replaceOp);
			} else if (document.activeElement.value === 'assignedSubmit') {
				fieldName.push('AssignedTo', 'AssignedToId');
				fieldValue.push(caseJson.AssignedTo, caseJson.AssignedToId);
				caseDataLoader.patch(data.CaseId, fieldName, fieldValue, replaceOp);
			} else {
				caseDataLoader.update(data.CaseId, caseJson);
			}
		}
	};

	const buildCommentJson = (inputComment, inputAttachments, caseData, parentCommentId) => ({
		CaseId: caseData.CaseId,
		CaseNumber: caseData.CaseNumber,
		Description: inputComment,
		ResourceType: 'Comment',
		ParentCommentId: parentCommentId,
		IsPrivate: sendPrivate,
		Attachments: inputAttachments,
	});

	const buildCaseJson = (caseData, watchers) => {
		const caseJson = caseData;

		caseJson.Priority = commonData.Priority ?? caseData.Priority;
		caseJson.CaseRecordType = commonData.CaseRecordType ?? caseData.CaseRecordType;
		caseJson.CaseOrigin = commonData.CaseOrigin ?? caseData.CaseOrigin;

		for (const item in overViewData) {
			switch (item) {
				case 'ImpactPatientCase':
					caseJson.ImpactPatientCase = overViewData.ImpactPatientCase.toLowerCase() === 'yes';
					break;
				case 'ClosedDateTime':
					if (overViewData.ClosedDateTime) {
						caseJson.ClosedDateTime = new Date(overViewData.ClosedDateTime).toISOString();
					}
					break;
				default:
					caseJson[item] = overViewData[item];
			}
		}

		caseJson.RelatedContact = _.map(contactData, contact => ({
			FullName: contact.name,
			JobTitle: contact.jobTitle,
			CellPhone: contact.phone,
			Email: contact.email,
		}));

		caseJson.Watcher = watchers ?? watcherData;

		return caseJson;
	};

	useEffect(() => {
		setWatching(isWatching());
	}, [watcherData]);

	const isWatching = () => _.some(watcherData, watcher => watcher.UserId === loggedInUser.id);

	return !loading ? (
		data ? (
			<form onSubmit={handleSubmit}>
				<Header>
					<Title>
						<Work sx={{ fontSize: 29, mr: '12px' }} />
						<span>Cases</span>
					</Title>
					<CustomSwitch>
						<Knob style={{ zIndex: 1 }} onClick={() => navigate('/')}>
							<HomeOutlined />
						</Knob>
						<Knob style={{ marginLeft: '-16px' }} onClick={() => navigate('/case')}>
							<span>Case</span>
						</Knob>
						<label>{data.CaseName}</label>
					</CustomSwitch>
				</Header>
				<Box
					sx={{
						overflow: 'auto',
						position: 'relative',
						zIndex: 0,
						height: 'calc(100vh - 144px)',
						maxHeight: '100vh',
						mb: 10,
						pt: 5,
						flex: 1,
						width: '100%',
						flexDirection: 'row',
						paddingTop: 0,
						'&::-webkit-scrollbar-thumb': {
							background: '#C4C4C4 !important',
							borderRadius: '3px',
						},
						'&::-webkit-scrollbar-track': {
							background: '#FFF !important',
						},
					}}
				>
					<HeaderPanel className="invisible-scrollbar" style={{ justifyContent: 'space-between' }}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div className="left">
								{editableIndex === 'priority' ? (
									<div style={{ display: 'flex' }}>
										<select
											name="priority"
											style={{
												border: 'none',
												fontFamily: 'Inter',
												fontSize: '25px',
												fontWeight: 'bold',
												fontStretch: 'normal',
												fontStyle: 'normal',
												color: '#4d79ea',
											}}
											onChange={e => {
												commonData.Priority = e.target.value;
												setCommonData(commonData);
											}}
										>
											{PRIORITIES.map(item => (
												<option selected={data.Priority == item.value ? 'selected' : ''}>
													{item.value}
												</option>
											))}
										</select>
										<IconButton
											data-testid="submitButton_priority"
											id="submitButton_priority"
											sx={{ padding: '8px 0px 8px 0px' }}
											type="button"
											onClick={e => {
												onChangeData(e, 'priority');
											}}
										>
											<CheckCircle
												sx={{
													pointerEvents: 'none',
													color: '#0047ff',
													fontSize: '22px',
													marginLeft: '3px',
												}}
											/>
										</IconButton>
										<IconButton
											id="closeEditButton_priority"
											sx={{ padding: '7px 2px 5px 2px' }}
											type="button"
										>
											<Close
												id="closeEditButton_priority"
												sx={{ color: '#0047ff' }}
												onClick={e => {
													setEditableIndex(null);
													commonData.Priority = '';
													setCommonData(commonData);
												}}
											/>
										</IconButton>
									</div>
								) : (
									<div style={{ display: 'flex' }}>
										<h2
											className="priority"
											style={{
												cursor: loggedInUser.permission.Case.Edit ? 'pointer' : '',
											}}
											onClick={() => {
												if (loggedInUser.permission.Case.Edit) {
													setEditableIndex('priority');
												}
											}}
											onMouseEnter={() => setHoveredField('priority')}
										>
											{data.Priority}
										</h2>
										{hoveredField === 'priority' && !!loggedInUser.permission.Case.Edit && (
											<IconButton
												id={'closeEditButton_Edit_' + 'priority'}
												sx={{ p: 0, marginLeft: '5px' }}
												type="button"
												onClick={e => {
													setEditableIndex('priority');
												}}
											>
												<EditOutlined
													sx={{
														color: 'rgba(0, 0, 0, 0.6)',
														fontSize: '24px',
														marginLeft: 'auto',
													}}
												/>
											</IconButton>
										)}
									</div>
								)}
								<h5>{data.CaseName}</h5>
								<DPad>
									<Icon
										component={ArrowUpIcon}
										fill={previousCase ? '#4D79EA' : 'rgba(0, 0, 0, 0.26)'}
										sx={{
											width: '12px',
											height: '14px',
											cursor: previousCase ? 'pointer' : 'context-menu',
										}}
										onClick={() => {
											if (previousCase) {
												navigate(`/case/${previousCase}`);
											}
										}}
									/>
									<Icon
										component={ArrowDownIcon}
										fill={nextCase ? '#4D79EA' : 'rgba(0, 0, 0, 0.26)'}
										sx={{
											width: '12px',
											height: '14px',
											cursor: nextCase ? 'pointer' : 'context-menu',
										}}
										onClick={() => {
											if (nextCase) {
												navigate(`/case/${nextCase}`);
											}
										}}
									/>
								</DPad>
							</div>
							<div className="center">
								<Row>
									<Col
										style={{
											flex: 'initial',
											minWidth: '150px',
										}}
									>
										<label>Case number</label>
										<span>{data.CaseNumber}</span>
									</Col>
									<Col
										style={{
											flex: 'initial',
											minWidth: '150px',
										}}
									>
										<label>Case Record Type</label>
										{editableIndex === 'caseRecordType' ? (
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													marginTop: '2px',
													fontSize: '14px',
												}}
											>
												<Select
													defaultValue={data.CaseRecordType}
													id="caseRecordType"
													name="caseRecordType"
													style={{
														border: 'none',
														fontFamily: 'Inter',
													}}
													sx={{
														'& legend': {
															display: 'none',
														},
														'& fieldset': {
															top: 0,
														},
														height: '40px',
														width: '120px',
														fontSize: '12px',
														fontStretch: 'normal',
														fontStyle: 'normal',
														flex: '1',
														borderRadius: 3,
													}}
													onChange={e => {
														commonData.CaseRecordType = e.target.value;
														setCommonData(commonData);
													}}
												>
													{CaseRecordTypes.map(selectItem => (
														<MenuItem key={selectItem.value} value={selectItem.value}>
															{selectItem.name}
														</MenuItem>
													))}
												</Select>
												<IconButton
													data-testid="submitButton_case_record_type"
													id="submitButton_case_record_type"
													sx={{
														paddingRight: 0,
													}}
													type="button"
													value="commonSubmit"
													onClick={e => {
														onChangeData(e, 'case_record_type');
													}}
												>
													<CheckCircle
														sx={{
															pointerEvents: 'none',
															color: '#0047ff',
															fontSize: '22px',
															marginLeft: 'auto',
														}}
													/>
												</IconButton>
												<IconButton
													id="closeEditButton_case_record_type"
													sx={{
														padding: '5px 1px 1px 1px',
														marginLeft: '1px',
													}}
													type="button"
												>
													<Close
														id="closeEditButton_case_record_type"
														sx={{
															fontSize: '22px',
															color: '#0047ff',
															marginBottom: '5px',
														}}
														onClick={e => {
															setEditableIndex(null);
															commonData.CaseRecordType = data.CaseRecordType;
															setCommonData(commonData);
														}}
													/>
												</IconButton>
											</Box>
										) : (
											<div
												style={{ display: 'flex', marginTop: '10px' }}
												onMouseEnter={() => setHoveredField('caseRecordType')}
												onMouseLeave={() => setHoveredField(null)}
											>
												<span
													style={{ margin: 0 }}
													onClick={() => {
														setEditableIndex('caseRecordType');
													}}
													onMouseEnter={() => setHoveredField('caseRecordType')}
												>
													{data.CaseRecordType || '-'}
												</span>
												{hoveredField === 'caseRecordType' &&
													!!loggedInUser.permission.Case.Edit && (
														<IconButton
															id="closeEditButton_Edit_caseRecordType"
															sx={{ p: 0, marginLeft: '5px' }}
															type="button"
															onClick={() => {
																setEditableIndex('caseRecordType');
															}}
														>
															<EditOutlined
																sx={{
																	color: 'rgba(0, 0, 0, 0.6)',
																	fontSize: '16px',
																	marginLeft: 'auto',
																}}
															/>
														</IconButton>
													)}
											</div>
										)}
									</Col>
									<Col
										style={{
											flex: 'initial',
											minWidth: '150px',
										}}
									>
										<label>Date/Time Opened</label>
										<span>
											{data.CreatedDateTime
												? `${new Date(data.CreatedDateTime).toLocaleDateString()} ${new Date(
														data.CreatedDateTime
												  ).toLocaleTimeString(navigator.language, {
														hour: '2-digit',
														minute: '2-digit',
												  })}`
												: '-'}
										</span>
									</Col>
									<Col
										style={{
											flex: 'initial',
											minWidth: '100px',
										}}
									>
										<label>Case Origin</label>
										{editableIndex === 'caseOrigin' ? (
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													marginTop: '2px',
													fontSize: '14px',
												}}
											>
												<TextField
													InputProps={{
														endAdornment: (
															<IconButton
																data-testid="submitButton_caseOrigin"
																id="submitButton_caseOrigin"
																sx={{
																	paddingRight: 0,
																}}
																type="button"
																value="commonSubmit"
																onClick={e => {
																	onChangeData(e, 'case_origin');
																}}
															>
																<CheckCircle
																	sx={{
																		pointerEvents: 'none',
																		color: '#0047ff',
																		fontSize: '22px',
																		marginLeft: 'auto',
																	}}
																/>
															</IconButton>
														),
													}}
													autoFocus={editableIndex === 'caseOrigin'}
													data-testid="caseOrigin"
													defaultValue={data.CaseOrigin}
													name="caseOrigin"
													size="small"
													sx={{
														'.MuiFormHelperText-root': {
															fontSize: '10px',
														},
														'.MuiOutlinedInput-root': {
															paddingRight: '5px',
															height: '35px',
															borderRadius: 2,
														},
														height: '30px',
														'& legend': {
															display: 'none',
														},
														'& fieldset': {
															top: 0,
														},
														flex: '1',
														width: '70px',
													}}
													variant="outlined"
													onChange={e => {
														commonData.CaseOrigin = e.target.value;
														setCommonData(commonData);
													}}
												/>
												<IconButton
													id="closeEditButton_caseOrigin"
													sx={{
														padding: '5px 1px 1px 1px',
														marginLeft: '1px',
													}}
													type="button"
												>
													<Close
														id="closeEditButton_caseOrigin"
														sx={{
															fontSize: '22px',
															color: '#0047ff',
														}}
														onClick={e => {
															setEditableIndex(null);
															commonData.CaseOrigin = data.CaseOrigin;
															setCommonData(commonData);
														}}
													/>
												</IconButton>
											</Box>
										) : (
											<div
												style={{ display: 'flex', marginTop: '10px' }}
												onMouseEnter={() => setHoveredField('caseOrigin')}
												onMouseLeave={() => setHoveredField(null)}
											>
												<span
													style={{ margin: 0 }}
													onClick={() => {
														setEditableIndex('caseOrigin');
													}}
													onMouseEnter={() => setHoveredField('caseOrigin')}
												>
													{data.CaseOrigin || '-'}
												</span>
												{hoveredField === 'caseOrigin' &&
													!!loggedInUser.permission.Case.Edit && (
														<IconButton
															id="closeEditButton_Edit_case_origin"
															sx={{ p: 0, marginLeft: '5px' }}
															type="button"
															onClick={() => {
																setEditableIndex('caseOrigin');
															}}
														>
															<EditOutlined
																sx={{
																	color: 'rgba(0, 0, 0, 0.6)',
																	fontSize: '16px',
																	marginLeft: 'auto',
																}}
															/>
														</IconButton>
													)}
											</div>
										)}
									</Col>
								</Row>
							</div>
						</div>
						<div
							className="right"
							style={{
								padding: '0 39px',
								borderLeft: '1px solid rgba(196, 196, 196, 0.4)',
							}}
						>
							<Row>
								<Col style={{ flex: 'none' }}>
									<label>Case Owner</label>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<DivImage
											style={{
												marginLeft: 0,
												marginRight: '10px',
												background: `url(${Avatar})`,
											}}
										/>
										<span style={{ margin: 0 }}>{data.CaseOwner}</span>
									</div>
								</Col>
								<Col>
									<Timer>
										<AccessTimeOutlined
											sx={{
												marginRight: '5px',
												color: '#4D79EA',
												fontSize: '25px',
											}}
										/>
										{counter && (
											<Box>
												<Typography component="span">{counter}</Typography>
											</Box>
										)}
									</Timer>
								</Col>
								<IconButton sx={{ fontSize: 20, color: '#4D79EA' }} type="button">
									{watching ? (
										<Visibility
											id="submitWatcher"
											onClick={e => {
												onChangeData(e);
											}}
										/>
									) : (
										<VisibilityOutlined
											id="submitWatcher"
											onClick={e => {
												onChangeData(e);
											}}
										/>
									)}
								</IconButton>
							</Row>
						</div>
					</HeaderPanel>
					<MainContainer>
						<Row
							style={{
								flex: 1,
								margin: 0,
								alignItems: 'flex-start',
							}}
						>
							{/* left */}
							<Col style={{ flex: 3, margin: 0 }}>
								<Card>
									<Overview
										expanded
										data={data}
										editableIndex={editableIndex}
										errorMsgs={errMsgs}
										hoveredField={hoveredField}
										isValidForm={isValidForm}
										setEditableIndex={setEditableIndex}
										setHoveredField={setHoveredField}
										onChangeData={onChangeData}
									/>
								</Card>
								{!!loggedInUser?.permission?.Case?.Read &&
									!!loggedInUser?.permission?.Comment?.Read && (
										<Card style={{ marginTop: '20px' }}>
											<Comments
												expanded
												attachments={attachments}
												caseData={data}
												commentData={commentData}
												progress={progress}
												sendPrivate={sendPrivate}
												setAttachments={setAttachments}
												setCommentData={setCommentData}
												setProgress={setProgress}
												setSendPrivate={setSendPrivate}
												onChangeData={onChangeData}
											/>
										</Card>
									)}
								{!!loggedInUser?.permission.History.Read && (
									<Card style={{ marginTop: '20px' }}>
										<History expanded caseData={data} />
									</Card>
								)}
							</Col>
							{/* right */}
							<Col
								style={{
									flex: 1,
									minWidth: '622px',
									margin: 0,
								}}
							>
								<Card>
									<Contacts
										canEdit={!!loggedInUser.permission.Case.Edit}
										data={contactData}
										handleSubmit={handleSubmit}
										onChangeData={onChangeData}
									/>
								</Card>
								<Card>
									<Knowledge />
								</Card>
							</Col>
						</Row>
					</MainContainer>
				</Box>
			</form>
		) : (
			<Box
				sx={{
					height: '100%',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					paddingBottom: '100px',
					boxSizing: 'border-box',
				}}
			>
				<Typography
					sx={{
						textAlign: 'center',
						fontSie: '16px',
						marginBottom: '8px',
						color: 'black',
					}}
				>
					Not Found
				</Typography>
			</Box>
		)
	) : (
		<Box
			sx={{
				height: '100%',
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				paddingBottom: '100px',
				boxSizing: 'border-box',
			}}
		>
			<Typography
				sx={{
					textAlign: 'center',
					fontSie: '16px',
					marginBottom: '8px',
					color: 'black',
				}}
			>
				<CircularProgress
					className="progressBar"
					size={22}
					sx={{
						color: '#03dac5',
						marginRight: '16px',
					}}
				/>
				Loading...
			</Typography>
		</Box>
	);
};

export default DetailsFullscreen;
