import { FhirDomainResource } from '../base';
import priorAuthorizationSchema from './schemata/priorAuthorizationSchema';

export default class PriorAuthorizationResource extends FhirDomainResource {
	id;
	resourceType;
	status;
	payerName;
	orderId;
	studyId;
	patientId;
	coverageId;
	caseNumber;
	submissionDate;
	evaluationDate;
	decisionDate;
	authorizationDate;
	managingorganizationid;

	constructor(resourceString) {
		super(resourceString, priorAuthorizationSchema);
		this.resourceType = 'PriorAuthorization';
		this.populateFields();
	}
}
