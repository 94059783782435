import React from 'react';

const ItalicIcon = () => (
	<svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_5211_59769)">
			<path
				d="M6.66667 2.6665V4.6665H8.14L5.86 9.99984H4V11.9998H9.33333V9.99984H7.86L10.14 4.6665H12V2.6665H6.66667Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_5211_59769">
				<rect fill="white" height="16" width="16" />
			</clipPath>
		</defs>
	</svg>
);

export default ItalicIcon;
