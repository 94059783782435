import axios from 'axios';

const shareStudy = async ({ __config, data, studyId, patientId }) => {
	const response = await axios.post(
		`${__config.data_sources.blume}Study/share/${studyId}?patientId=${patientId}`,
		data
	);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default shareStudy;
