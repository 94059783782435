/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */

// @flow

// core
import React, { useCallback, useMemo, useState, type Node } from 'react';
// components
import Svg from '../Svg/Svg';
// libs
import CloseIcon from '@mui/icons-material/Close';
import Zoom from '@mui/material/Zoom';
import { useTheme } from '@mui/system';
import styled from 'styled-components';

interface IWheelItem {
	icon: string;
	label: string;
	visible?: boolean;
	onHover: () => void;
}

interface IWorklistWheelProps {
	/**
	 * List of clickable items
	 * @default []
	 */
	items: IWheelItem[];
	/**
	 * Whether the wheel is opened or closed
	 * @default false
	 */
	isOpen?: boolean;
	/**
	 * Position of the wheel
	 * @default 'absolute'
	 */
	position?: 'absolute' | 'relative' | 'sticky' | 'unset';
	/**
	 * X Position of the component
	 * @default 0
	 */
	x: number;
	/**
	 * Y Position of the component
	 * @default 0
	 */
	y: number;

	/** Event called when an item is clicked */
	onClick: (item: IWheelItem) => void;
	/** Event called when the wheel is closed */
	onClose: () => void;
}

export const WorklistWheel = ({ items = [], isOpen, onClick, onClose, ...otherProps }: IWorklistWheelProps): Node => {
	// ==================== State ====================
	const [label, setLabel] = useState('');

	// ==================== Hooks ====================
	const theme = useTheme();

	// #region ==================== Events ====================
	const onHoverStart = useCallback(
		(label: string) => (e: Event) => {
			e.preventDefault();
			e.stopPropagation();

			setLabel(label);
		},
		[]
	);

	const onHoverEnd = useCallback(() => {
		setLabel('');
	}, []);

	const onSelect = useCallback(
		(item: IWheelItem) => {
			onClick(item);
			onClose();
		},
		[onClick, onClose]
	);
	// #endregion

	const buttons = useMemo(
		() =>
			items.map((item, index) => {
				const angle = (360 / items.length) * index;
				const radius = 68;
				const radian = (Math.PI * (180 + angle)) / 180;
				const top = Math.sin(radian) * radius - 20 + 93;
				const left = Math.cos(radian) * radius - 20 + 93;

				return (
					<Zoom key={item.label} in={isOpen} timeout={{ enter: 150 * (index + 1), exit: 0 }}>
						<div
							style={{ top, left, ...SX.WheelItem }}
							onClick={() => onSelect(item)}
							onMouseLeave={onHoverEnd}
							onMouseOver={onHoverStart(item.label)}
						>
							{label === item.label && <Overlay />}

							<Icon data-testid={`${item.icon}-icon`} name={item.icon} viewBox="0 0 40 40" />
						</div>
					</Zoom>
				);
			}),
		[items, label, isOpen, onHoverEnd, onHoverStart, onSelect]
	);

	return (
		<Zoom in={isOpen}>
			<WheelWrapper {...otherProps}>
				<Hole data-testid="hovered-icon-label" theme={theme}>
					{label || <CloseIcon data-cy="close-wheeloption" sx={SX.CloseIcon} onClick={onClose} />}
				</Hole>

				{buttons}
			</WheelWrapper>
		</Zoom>
	);
};

const SX = {
	CloseIcon: {
		color: 'icon.primary',
		cursor: 'pointer',
		'&:hover': { color: 'primary.contrastText' },
	},

	WheelItem: {
		position: 'absolute',
		cursor: 'pointer',
		borderRadius: '50%',
		overflow: 'hidden',
	},
};

const Overlay = styled.div`
	height: 40px;
	width: 40px;
	border-radius: 50%;
	background-color: #121212;
	opacity: 0.3;
	position: absolute;
	z-index: 2;
	top: 0px;
	left: 0px;
`;

const WheelWrapper = styled.div`
	width: 186px;
	height: 186px;
	left: ${props => `${props.x - 93}px`};
	top: ${props => `${props.y - 93}px`};
	border-radius: 50%;
	position: ${props => `${props.position || 'absolute'}`};
	z-index: 100;
`;

const Hole = styled.div`
	position: absolute;
	left: 50px;
	right: 50px;
	top: 50px;
	bottom: 50px;
	width: 86px;
	height: 86px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	text-align: center;
	border-radius: 50%;
	box-shadow: 0 0 0 50px rgba(69, 69, 69, 0.95);
	background: rgba(57, 57, 57, 0.8);
	color: #fff;
	font-size: 12px;
	font-family: ${({ theme }) => `${theme.typography.fontFamily}`};
`;

const Icon = styled(Svg)`
	width: 40px;
	height: 40px;
`;
