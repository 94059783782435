import axios from 'axios';
import { isUnixSystem } from '@worklist-2/core/src';

/**
 * Triggers download for DiscImageBurner
 * @param {string} organizationId Internal organization ID
 * @param {string} studyUID study UID
 * @param {string} authUrl URL of Auth API
 * @param {callback} onError On error Callback
 */
const downloadDiscImageBurner = (organizationId, studyUID, authUrl, accessionNumber, onError = null) => {
	downloadMultipleStudiesDiscImageBurner(
		{
			studyIds: [studyUID],
			internalManagingOrganizationIds: [organizationId],
		},
		authUrl,
		accessionNumber,
		onError
	);
};

/**
 * Triggers download for DiscImageBurner
 * @param {string} body Request raw body
 * @param {string} authUrl URL of Auth API
 * @param {callback} onError On error Callback
 */
const downloadMultipleStudiesDiscImageBurner = (body, authUrl, accessionNumber, onError = null) => {
	axios
		.post(`${authUrl}/Portal/DiscImageBurner`, body, { responseType: 'blob', headers: { 'unix-os': isUnixSystem() } })
		.then((response) => {
			if (response.status >= 200 && response.status < 300) {
				const objectUrl = window.URL.createObjectURL(
					new Blob([response.data], {
						type: response.headers['content-type'],
					})
				);
				const disposition = response.headers['content-disposition'];
				const attachment = disposition && disposition.slice(disposition.indexOf('=') + 1);
				let fileName = attachment && attachment.slice(0, attachment.indexOf(';'));

				// All disc image burner will have the Ramsoft. prefix
				if(!fileName?.toLowerCase()?.startsWith("ramsoft.")){
					fileName = `RamSoft.${fileName}`;
				}

				// Add accession number to file name
				if (accessionNumber) {
					const name = fileName.slice(0, fileName.lastIndexOf('.'));
					const extension = fileName.slice(fileName.lastIndexOf('.'));

					fileName = name + '-' + accessionNumber + extension;
				}

				let link = document.createElement('a');
				link.href = objectUrl;
				link.download = fileName;
				link.click();
				link.remove();

				setTimeout(function () {
					window.URL.revokeObjectURL(objectUrl);
				}, 15000);
			} else {
				throw Error(response.message);
			}
		})
		.catch((e) => {
			if (typeof onError === 'function') {
				onError(e);
			}
		});
};

export { downloadDiscImageBurner, downloadMultipleStudiesDiscImageBurner };
