import { fhirExtensionUrls } from '@worklist-2/core/src';
import { findExtensionIndex } from '../utils';

class Coverage {
	constructor(data) {
		this.data = data;
	}

	data = {};

	init = () => {
		if (!this.data.extension) {
			this.data.extension = [];
		}
		this.initializeFinancialClass();
		this.initializeFinancialType();
		this.initializeNetworkInOut();
		this.initializeCoverageStatus();
		this.initializeCopayAmount();
		this.initializePeriod();
		this.initializeInsuranceCard();
		this.initializePatientRelationship();
	};

	// Initialize any missing fields
	initializeFinancialType = () => {
		if (findExtensionIndex(this.data.extension, fhirExtensionUrls.coverage.financialType) < 0) {
			this.data.extension.push({
				url: fhirExtensionUrls.coverage.financialType,
				valueReference: null,
			});
		}
	};

	initializeNetworkInOut = () => {
		if (findExtensionIndex(this.data.extension, fhirExtensionUrls.coverage.networkInOut) < 0) {
			this.data.extension.push({
				url: fhirExtensionUrls.coverage.networkInOut,
				valueString: null,
			});
		}
	};

	initializeFinancialClass = () => {
		if (findExtensionIndex(this.data.extension, fhirExtensionUrls.coverage.financialClass) < 0) {
			this.data.extension.push({
				url: fhirExtensionUrls.coverage.financialClass,
				valueCoding: {},
			});
		}
	};

	initializeCoverageStatus = () => {
		if (findExtensionIndex(this.data.extension, fhirExtensionUrls.coverage.insuranceStatus) < 0) {
			this.data.extension.push({
				url: fhirExtensionUrls.coverage.insuranceStatus,
				valueCoding: {
					code: 'UNK',
					display: 'Unknown',
				},
			});
		}
	};

	initializeCopayAmount = () => {
		if (findExtensionIndex(this.data.extension, fhirExtensionUrls.coverage.copayAmount) < 0) {
			this.data.extension.push({
				url: fhirExtensionUrls.coverage.copayAmount,
				valueDecimal: null,
			});
		}
	};

	// Start/end times
	initializePeriod = () => {
		if (!this.data.period) {
			this.data.period = {};
		}
	};

	initializePatientRelationship = () => {
		if (!this.data.relationship || this.data.relationship?.length === 0 || this.data.relationship?.[0]?.coding) {
			this.data.relationship = {
				coding: [],
			};
		}
	};

	initializeInsuranceCard = () => {
		if (findExtensionIndex(this.data.extension, fhirExtensionUrls.coverage.insuranceCardBack) < 0) {
			this.data.extension.push({
				url: fhirExtensionUrls.coverage.insuranceCardBack,
				valueAttachment: null,
			});
		}

		if (findExtensionIndex(this.data.extension, fhirExtensionUrls.coverage.insuranceCardFront) < 0) {
			this.data.extension.push({
				url: fhirExtensionUrls.coverage.insuranceCardFront,
				valueAttachment: null,
			});
		}
	};
}

export default Coverage;
