import React from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useTranslation } from 'react-i18next';

const DataGridSort = ({ column, sortOrder, setSortOrder, isSingleSort, sortDirection = '', setSortDirection }) => {
	const { t } = useTranslation('gridFilterPanel');

	const handleSortClick = columnName => {
		let columnSet = sortOrder;
		let valueUpdated = false;

		if (isSingleSort) {
			if (columnSet.includes(`-${columnName}`)) {
				columnSet = [columnName];
				valueUpdated = true;
			} else if (columnSet.includes(columnName)) {
				columnSet = [`-${columnName}`];
				valueUpdated = true;
			}
		} else if (columnSet.length > 0) {
			columnSet.forEach((elem, index) => {
				if (elem === `-${columnName}`) {
					columnSet[index] = columnName;
					valueUpdated = true;
				} else if (elem === columnName) {
					columnSet[index] = `-${columnName}`;
					valueUpdated = true;
				}
			});
		}

		if (!valueUpdated) {
			isSingleSort && columnSet.pop();
			columnSet.push(`-${columnName}`);
			setSortDirection([...columnSet]);
			setSortOrder([...columnSet]);
		} else {
			setSortDirection([...columnSet]);
			setSortOrder([...columnSet]);
		}
	};

	const sortDirectionOrDefaultValue = sortDirection || 'asc';

	const tooltipText = { asc: t('Ascending'), desc: t('Descending') }[sortDirectionOrDefaultValue];

	return (
		<Tooltip title={tooltipText}>
			<Button
				data-testid={`headcol-${column}`}
				sx={{
					textTransform: 'none',
					marginLeft: '0px !important',
					minWidth: 0,
					paddingLeft: '0px',
					paddingRight: '0px',
					width: '16px',
				}}
				variant=""
				onClick={() => handleSortClick(column)}
			>
				<Box sx={{ display: 'flex', cursor: 'pointer' }}>
					<TableSortLabel active direction={sortDirectionOrDefaultValue} sx={{ height: '20px' }} />
				</Box>
			</Button>
		</Tooltip>
	);
};

export default DataGridSort;
