import React from 'react';

function ToggleSwitch({ isActive, onClick, disabled }) {
	return (
		<div
			data-testid="switchClick"
			style={{
				position: 'relative',
				maxWidth: '52px',
				width: '100%',
				height: '20px',
				display: 'flex',
				justifyContent: isActive ? 'flex-start' : 'flex-end',
				alignItems: 'center',
				borderRadius: '5px',
				backgroundColor: isActive ? '#4D79EA' : '#C4C4C4',
				cursor: disabled ? 'default' : 'pointer',
			}}
			onClick={e => {
				e.stopPropagation();
				if (!disabled) {
					onClick();
				}
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: '-2px',
					left: !isActive && '0',
					right: isActive && '0',
					width: '22px',
					height: '24px',
					borderRadius: '5px',
					backgroundColor: '#FFFFFF',
					boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
				}}
			/>
			<span
				style={{
					margin: '0 6px',
					fontSize: '14px',
					fontWeight: '500',
					lineHeight: '12px',
					color: '#FFFFFF',
				}}
			>
				{isActive ? 'On' : 'Off'}
			</span>
		</div>
	);
}

export default ToggleSwitch;
