import React from 'react';
import { Box, Button, IconButton, Typography, Icon } from '@mui/material';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import OmegaAIIcon from '@worklist-2/ui/src/assets/icons/Chat/omega-ai.svg';
import BlumeIcon from '@worklist-2/ui/src/assets/icons/Chat/blume.svg';
import { useLiveChatContext } from './LiveChatContext';
import UserAvatar from './UserAvatar';

function UserChatHeader() {
	const { setIsChatOpen, setSelectedConversation, selectedConversation, isProfileOpen, setIsProfileOpen } =
		useLiveChatContext();

	const handleChatClose = () => {
		setSelectedConversation(null);
		setIsProfileOpen(false);
		setIsChatOpen(false);
	};

	const handleConversationClose = () => {
		setSelectedConversation(null);
		setIsProfileOpen(false);
	};

	return (
		<div>
			<div
				style={{
					paddingBottom: '21px',
					backgroundColor: '#F9FAFC',
					boxSizing: 'border-box',
				}}
			>
				<div
					style={{
						padding: '7px 15px 0 15px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<IconButton data-testid="buttonConversationClose" type="button" onClick={handleConversationClose}>
						<ArrowBackIcon
							sx={{
								color: 'rgba(0, 0, 0, 0.6)',
								padding: '0',
							}}
						/>
					</IconButton>
					<div>
						<IconButton type="button">
							<OpenInNewOutlinedIcon
								sx={{
									color: 'rgba(0, 0, 0, 0.6)',
									padding: '0',
								}}
							/>
						</IconButton>
						<IconButton
							data-testid="buttonChatClose"
							sx={{ marginLeft: '2px' }}
							type="button"
							onClick={handleChatClose}
						>
							<LastPageOutlinedIcon
								sx={{
									color: 'rgba(0, 0, 0, 0.6)',
									padding: '0',
								}}
							/>
						</IconButton>
					</div>
				</div>

				<div>
					<div
						style={{
							marginTop: '25px',
							paddingLeft: '36px',
							paddingRight: '15px',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<UserAvatar
								isOnline={selectedConversation?.participantOnline}
								size={45}
								userName={selectedConversation?.name}
							/>
							<Box
								sx={{
									marginLeft: '13px',
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Typography
										data-testid="participantName"
										style={{
											fontFamily: 'Inter',
											fontStyle: 'normal',
											fontSize: '24px',
											fontWeight: '500',
											lineHeight: '29px',
											color: 'rgba(0, 0, 0, 0.9)',
										}}
									>
										{selectedConversation?.name}
									</Typography>
									<Icon
										component={
											selectedConversation?.senderFrom == 'omegaai'
												? OmegaAIIcon
												: selectedConversation?.senderFrom == 'blume'
												? BlumeIcon
												: null
										}
										style={{
											height: '22px',
											width: '22x',
											marginLeft: '10px',
										}}
									/>
								</Box>
								<Typography
									style={{
										fontFamily: 'Inter',
										fontStyle: 'normal',
										fontWeight: '500',
										fontSize: '14px',
										lineHeight: '17px',
										color: 'rgba(0, 0, 0, 0.6)',
										margin: '0',
									}}
								>
									{selectedConversation?.participantOnline ? 'Online' : 'Offine'}
								</Typography>
							</Box>
						</div>

						<Button
							data-testid="viewProfileDetailsOpen"
							sx={{
								padding: '8px',
								textTransform: 'unset',
								border: isProfileOpen ? '1px solid rgba(77, 121, 234, 0.5)' : '1px solid transparent',
								backgroundColor: isProfileOpen ? 'rgba(77, 121, 234, 0.05)' : 'transparent',
							}}
							onClick={() => setIsProfileOpen(true)}
						>
							<AccountCircleOutlinedIcon
								sx={{
									color: '#4D79EA',
									height: '15px',
									width: '15px',
								}}
							/>
							<Typography
								style={{
									color: '#4D79EA',
									fontFamily: 'Inter',
									fontSize: '12px',
									lineHeight: '15px',
									fontWeight: '600',
									marginLeft: '6.5px',
								}}
							>
								View Profile
							</Typography>
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UserChatHeader;
