// Core
import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

//MUI
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

// Components
import TeachingFolderGrid from '@worklist-2/ui/src/components/TeachingFolder/TeachingFolderGrid/TeachingFolderGrid';
import TeachingFolderDrawer from '@rs-ui/components/Drawers/TeachingFolderDrawer/TeachingFolderDrawer';
import fhirEndpoints from '@worklist-2/core/src/fhir/fhirEndpoints';

// Libraries
import { useAuth } from '@worklist-2/core/src/context/UserAuthContext';
import getColumnMapping from '@worklist-2/core/src/fhir/resource/columnMapping/getColumnMapping';
import { getColumnsForSettings } from '@worklist-2/ui/src/components/utils/gridUtils';
import _ from 'lodash';

// TeachingFolderContainer is a container component that wraps the TeachingFolderGrid component.
const TeachingFolderContainer = ({
	className,
	name,
	children,
	columns,
	setColumns,
	filters,
	setFilters,
	sort,
	setSort,
	removeStudies,
	isAddingStudies,
	readyForData,
	setReadyForData,
	folderId,
}) => {
	// Translation
	const { t } = useTranslation(['teaching', 'all']);

	// States
	// openDial: state to control the visibility of the drawer
	const [openDial, setOpenDial] = useState(false);

	// isSyncStorageComplete: state to control the visibility of the grid
	const [isSyncStorageComplete, setIsSyncStorageComplete] = useState(false);

	// settingsColumns: state to store the columns for the drawer
	const [settingsColumns, setSettingsColumns] = useState([]);

	// Users
	const { loggedInUser } = useAuth();

	// Column Mapping
	const columnMapping = useMemo(() => getColumnMapping(fhirEndpoints.imagingStudyWorklist), []);

	/**
	 * Get the column list
	 */
	const columnList = useMemo(() => {
		if (columnMapping) {
			const columnMappingArray = _.keys(columnMapping);
			let index = 0;
			const columnsToMap = columns;
			const array = _.map(columnsToMap, item => {
				index += 1;
				return _.includes(columnMappingArray, item)
					? {
							id: `${item}-${index}`, // used for dragging
							label: t(columnMapping[item].label, { ns: 'all' }),
							name: item,
							filterType: columnMapping[item].filterType,
							options: columnMapping[item].options,
							valueSet: columnMapping[item].searchValueSet,
							filter: _.find(filters, element => element.label === columnMapping[item].label)?.values,
							searchParameter: columnMapping[item].searchParameter,
							toolTip: columnMapping[item].toolTip,
					  }
					: null;
			});
			return _.filter(array, x => x !== null);
		}
		return [];
	}, [columnMapping, columns, filters]);

	/**
	 * Handle store the settings data to local storage
	 * @param {*} settingsData
	 */
	const handleDataSubmit = settingsData => {
		setOpenDial(false);
		// Only save the data if the columns is not empty
		if (!_.isEmpty(settingsData.columns)) {
			const newColumnArray = settingsData.columns.map(x => x.name);
			if (newColumnArray && !newColumnArray.includes('flagColTF')) {
				newColumnArray.unshift('flagColTF');
			}
			setFilters([]);
			setFilters(settingsData.filters);
			setSort(settingsData.sort);
			setColumns(newColumnArray);

			// Store to local storage by user id
			const gridLayoutFromLocal = localStorage.getItem(`TeachingFolderLayout ${loggedInUser?.id}`);
			let gridObject = { gridLayout: settingsData };
			if (gridLayoutFromLocal) {
				gridObject = JSON.parse(gridLayoutFromLocal);
				if (gridObject) {
					gridObject.gridLayout = settingsData;
				}
			}

			localStorage.setItem(`TeachingFolderLayout ${loggedInUser?.id}`, JSON.stringify(gridObject));
		}
	};

	/**
	 * Load the grid layout from local storage
	 */
	useEffect(() => {
		const gridLayoutFromLocal = localStorage.getItem(`TeachingFolderLayout ${loggedInUser?.id}`);
		let sortArr;

		if (gridLayoutFromLocal) {
			const gridObject = JSON.parse(gridLayoutFromLocal);

			if (gridObject && gridObject.gridLayout) {
				const gridLayputObject = gridObject.gridLayout;

				// Column
				if (gridLayputObject.columns && gridLayputObject.columns !== 0) {
					const userSavedColumnList = gridLayputObject.columns.map(x => x.name);
					if (userSavedColumnList && !userSavedColumnList.includes('flagColTF')) {
						userSavedColumnList.unshift('flagColTF');
					}
					setColumns(userSavedColumnList);
				}
				// Filter
				if (gridLayputObject.filters && gridLayputObject.filters.length !== 0) {
					const filterDate = gridLayputObject.filters.map(elm => {
						if (elm.filterType === 'date-range' && !isNaN(Date.parse(elm.displayValue[0]))) {
							return {
								...elm,
								displayValue: [new Date(elm.displayValue[0]), new Date(elm.displayValue[1])],
							};
						}
						return elm;
					});

					setFilters(filterDate);
				}
				// Sort
				if (gridLayputObject.sort && gridLayputObject.sort.length !== 0) {
					sortArr = gridLayputObject.sort;
				}
			}
		}

		if (sortArr) {
			setSort(sortArr);
		}
		setIsSyncStorageComplete(true);
	}, []);

	return (
		<div>
			<SettingsOutlinedIcon
				sx={{
					position: 'absolute',
					right: '50px',
					bottom: '40px',
					cursor: 'pointer',
					width: '24px',
					height: '24px',
					padding: '23px',
					borderRadius: '50%',
					backgroundColor: 'rgba(53, 136, 203, 1)',
					color: '#FFFFFF',
					zIndex: 1050,
					'&:hover': {
						backgroundColor: 'rgba(53, 136, 203, 0.8)',
					},
				}}
				onClick={() => {
					setSettingsColumns(getColumnsForSettings(columnList, filters, sort));
					setOpenDial(true);
				}}
			/>
			<TeachingFolderDrawer
				columns={settingsColumns}
				drawerOpen={openDial}
				filters={filters}
				isAddingStudies={isAddingStudies}
				setDrawerOpen={setOpenDial}
				sort={sort}
				title="Teaching Folder Settings"
				width={490}
				onDataSubmit={handleDataSubmit}
			/>
			{children}
			{isSyncStorageComplete && (
				<TeachingFolderGrid
					className={className}
					columns={columns}
					filters={filters}
					folderId={folderId}
					name={name}
					readyForData={readyForData}
					removeStudies={removeStudies}
					setFilters={setFilters}
					setReadyForData={setReadyForData}
					setSort={setSort}
					sort={sort}
				/>
			)}
		</div>
	);
};

export default TeachingFolderContainer;
