const createSpeechRecognitionSlice = (set, get) => ({
	augnitoWSConnectionRef: null,
	setAugnitoWSConnectionRef: augnitoWSConnectionRef => set({ augnitoWSConnectionRef }),

	augnitoSocketConnected: false,
	setAugnitoSocketConnected: isConnected => set({ isConnected }),

	receivedTranscription: { transcript: null, isFinal: true },
	setReceivedTranscription: (transcript, isFinal = get().receivedTranscription.isFinal) =>
		set({
			receivedTranscription: {
				transcript,
				isFinal,
			},
		}),

	command: null,
	setCommand: command => set({ command }),

	commands: [],
	setCommands: commands => set({ commands }),

	language: '',
	setLanguage: language => set({ language }),

	active: true,
	setActive: active => set({ active }),

	vrViaDvToolbar: false,
	setVrViaDvToolbar: value => set({ vrViaDvToolbar: value }),

	requestDevice: false,
	toggleRequestDevice: () => set(state => ({ requestDevice: !state.requestDevice })),

	getDevice: false,
	toggleGetDevice: () => set(state => ({ getDevice: !state.getDevice })),

	activeDevice: { id: 0, name: 'Default System Microphone' },
	setActiveDevice: device => set({ activeDevice: device }),

	activeFootControlDevice: { id: 0, name: 'Select Foot Control' },
	setActiveFootControlDevice: device => set({ activeFootControlDevice: device }),

	allDevices: [{ id: 0, name: 'Default System Microphone' }],
	setAllDevices: devices => set({ allDevices: devices }),

	buttonEventListeners: [],
	addButtonEventListener: listener =>
		set(state => ({ buttonEventListeners: [...state.buttonEventListeners, listener] })),
	removeButtonEventListener: listener =>
		set(state => ({ buttonEventListeners: state.buttonEventListeners.filter(l => l !== listener) })),

	footPedalButtonAction: null,
	setFootPedalButtonAction: action => set({ footPedalButtonAction: action }),

	executeRepeatedCommand: true,
	setExecuteRepeatedCommand: value => set({ executeRepeatedCommand: value }),

	finalTranscript: '',
	setFinalTranscript: transcript => set({ finalTranscript: transcript }),

	transcript: '',
	setTranscript: transcript => set({ transcript }),

	closeIsHovered: false,
	setCloseIsHovered: value => set({ closeIsHovered: value }),

	welcomeTranscription: null,
	setWelcomeTranscription: value => set({ welcomeTranscription: value }),

	microphoneEnabled: null,
	setMicrophoneEnabled: value => set({ microphoneEnabled: value }),

	augnitoLmid: '213803201', // default language model for US Spelling and American English Accent
	setAugnitoLmid: value => set({ augnitoLmid: value }),

	collapseSearchBarForVR: true,
	setCollapseSearchBarForVR: value => set({ collapseSearchBarForVR: value }),

	connectionError: null,
	setConnectionError: error => set({ connectionError: error }),

	speechMicEventForRadPair: { eventType: '' },
	setSpeechMicEventForRadPair: event => set({ speechMicEventForRadPair: event }),

	speechMikeButtonEvent: { device: null, bitMask: null },
	setSpeechMikeButtonEvent: event => set({ speechMikeButtonEvent: event }),

	triggerButtonEvent: (device, bitMask) => {
		const listeners = get().buttonEventListeners;
		listeners.forEach(listener => listener(device, bitMask));
	},
});

export default createSpeechRecognitionSlice;
