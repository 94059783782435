import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useAuth, useConfig, mediaMobile, useIsMobile } from '@worklist-2/core/src';
import CHAT_SECTIONS from '@worklist-2/ui/src/components/Chat/CHAT_SECTIONS';
import { useChatGlobalContext } from '@worklist-2/ui/src/components/Chat/ChatGlobalContext';

import { useShareDrawerStore } from '../../../stores';
import { useToastMessageContext } from '../../../context/ToastMessageContext';
import { getPatientId } from '../../../utils/getPatientId';
import addContact from '../../../api/addContact';
import AddContactForm from '../../AddContactForm/AddContactForm';
import MobileDrawerTitle from '../../MobileBottomDrawer/MobileDrawer/MobileDrawerTitle';
import MobileFixedButtons from '../../MobileBottomDrawer/MobileDrawer/MobileFixedButtons';
import addContactValidationSchema from './addContactvalidationSchema';
import { ADD_CONTACT } from '../../../analytics/eventTypes';
import sendAnalyticsEvent from '../../../analytics';

const CancelSaveButtons = ({ closeDrawer, onSave, t, chatSection, primaryTitle }) => {
	const { setSection } = useChatGlobalContext();

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				paddingTop: '20px',
			}}
		>
			<Box>
				<Button
					color="rsPrimary"
					data-cy="CancelSave"
					sx={{
						width: '100px',
						height: '42px',
						borderRadius: '8px',
						border: '2px solid #42A5F5',
					}}
					variant="outlined"
					onClick={
						chatSection
							? () => {
									setSection({ name: CHAT_SECTIONS.NEW });
							  }
							: closeDrawer
					}
				>
					<Typography
						style={{
							fontSize: '16px',
							fontStyle: 'medium',
							fontWeight: '500',
							lineHeight: '18.75px',
							letterSpacing: '0.15em',
						}}
					>
						{t('CANCEL')}
					</Typography>
				</Button>
			</Box>
			<Box>
				<Button
					color="rsPrimary"
					data-cy="ADD"
					sx={{
						width: '100px',
						height: '42px',
						borderRadius: '8px',
					}}
					variant="contained"
					onClick={onSave}
				>
					<Typography
						style={{
							fontSize: '16px',
							fontStyle: 'medium',
							fontWeight: '500',
							lineHeight: '18.75px',
							letterSpacing: '0.15em',
						}}
					>
						{primaryTitle ? t(primaryTitle) : t('ADD')}
					</Typography>
				</Button>
			</Box>
		</Box>
	);
};

const ShareContactNew = ({ isOpen, closeDrawer, chatSection }) => {
	const studyId = useShareDrawerStore(state => state.studyId);
	const userData = useShareDrawerStore(state => state.user);
	const shareStudy = useShareDrawerStore(state => state.shareStudy);
	const validationSchema = addContactValidationSchema();
	const { setSection } = useChatGlobalContext();
	const { setToastMsg } = useToastMessageContext();
	const __config = useConfig();
	const { contacts, setContacts, profiles, patientMapping } = useAuth();
	const [message, setMessage] = useState(false);

	const { t } = useTranslation('drawer');
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
		clearErrors,
	} = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: { name: '', email: '' },
	});
	const isMobile = useIsMobile();

	useEffect(() => {
		if (!isOpen) {
			reset();
			!!errors && clearErrors();
		}
	}, [isOpen]);

	const onShareStudy = useCallback(
		async ({ email, name }) => {
			try {
				await shareStudy({ users: [{ email, name }] });

				setToastMsg(`${t('Shared with')} 1 user ${t('successfully')}`);
			} catch (err) {
				setToastMsg(t('Share failed, try again'));
			}
		},
		[patientMapping, profiles, setToastMsg, shareStudy, t, userData?.profileId]
	);

	const onSubmit = async data => {
		let contactAlreadyPresent = false;
		setMessage(false);
		const submitData = {
			name: data.name,
			email: data.email,
			phone: null,
			isMyContact: true,
			sharedStudiesByMe: [],
			sharedDocumentsByMe: [],
		};

		contacts.find(element => {
			if (element.email === data.email && element.isMyContact === true) {
				setMessage(true);
				contactAlreadyPresent = true;
			}
		});

		if (contactAlreadyPresent === false) {
			await addContact({ __config, data: submitData });
			sendAnalyticsEvent(ADD_CONTACT, { data: submitData });
			setContacts([submitData, ...contacts]);

			if (chatSection) {
				setSection({ name: CHAT_SECTIONS.NEW });
			} else if (!studyId) closeDrawer();
			reset();

			// Share study with new contact
			if (studyId) {
				await onShareStudy(data);
				if (contactAlreadyPresent === false) closeDrawer();
			}
		}
	};

	return (
		<Box
			sx={{
				margin: chatSection ? '17px 30px 0px 30px' : '17px 50px 0px 51px',

				[mediaMobile]: { margin: 0, position: 'relative' },
			}}
		>
			{isMobile && <MobileDrawerTitle title={t('Add new contact')} onGoBack={closeDrawer} />}
			<AddContactForm
				control={control}
				errors={errors}
				handleSubmit={handleSubmit}
				message={message}
				onSubmit={onSubmit}
			/>

			{isMobile ? (
				<MobileFixedButtons onAction={handleSubmit(onSubmit)} onCancel={closeDrawer} />
			) : (
				<CancelSaveButtons
					chatSection={chatSection}
					closeDrawer={closeDrawer}
					primaryTitle="SHARE"
					t={t}
					onSave={handleSubmit(onSubmit)}
				/>
			)}
		</Box>
	);
};

export { CancelSaveButtons };
export default ShareContactNew;
