import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { Icon } from '@mui/material';
import MobileScheduledMoreList from '../../MobileScheduledMoreList';
import MobileBottomDrawer from '../../MobileDrawer/MobileBottomDrawer';
import { useIsMobile, mediaMobile } from '@worklist-2/core/src';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';

const CardLabelText = ({ label, text }) => (
	<Box
		sx={{
			maxWidth: '33%',
		}}
	>
		<Typography
			sx={{
				fontWeight: '400',
				fontSize: '12px',
				lineHeight: '14px',
				letterSpacing: '0.15em',
				color: 'rgba(18, 18, 18, 0.87)',
				marginBottom: '5px',
			}}
		>
			{label}
		</Typography>

		<Typography
			sx={{
				fontWeight: '400',
				fontSize: '16px',
				lineHeight: '19px',
				letterSpacing: '0.15em',
				color: 'rgba(18, 18, 18, 0.87)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			}}
		>
			{text}
		</Typography>
	</Box>
);

const MobileMapSection = ({ study, appointmentDate }) => {
	const [isShownListDrawer, setIsShownListDrawer] = useState(false);
	const [scheduledMoreEl, setScheduledMoreEl] = React.useState(null);
	const mapRef = useRef();
	const popperContainerRef = useRef();
	const isMobile = useIsMobile();

	useEffect(() => {
		new window.google.maps.Map(mapRef.current, {
			center: { lat: -34.397, lng: 150.644 },
			zoom: 8,
			disableDefaultUI: true,
		});
	}, []);

	const handleScheduledMoreClick = event => {
		if (event) {
			setScheduledMoreEl(scheduledMoreEl ? null : event.currentTarget);
		} else {
			setScheduledMoreEl(null);
		}
	};

	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					gap: '15px',
				}}
			>
				<Box
					sx={{
						width: '50%',
						border: '1px solid rgba(18, 18, 18, 0.1)',
						borderRadius: '15px',
						height: '229px',
						[mediaMobile]: {
							display: 'none',
						},
					}}
				>
					<Box
						sx={{
							height: '51px',
							display: 'flex',
							alignItems: 'center',
							paddingLeft: '18px',
						}}
					>
						<StarIcon
							sx={{
								color: '#C480DC',
								marginRight: '11px',
							}}
						/>

						<Typography
							sx={{
								fontWeight: '500',
								fontSize: '14px',
								lineHeight: '16px',
								letterSpacing: '0.15em',
								fontVariant: 'small-caps',
								textTransform: 'uppercase',
							}}
						>
							Scheduled
						</Typography>
					</Box>

					<Box
						sx={{
							height: '130px',
							background: 'rgba(112, 112, 112, 0.05)',
							padding: '14px 23px',
							boxSizing: 'border-box',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
						}}
					>
						<Typography
							sx={{
								fontWeight: '400',
								fontSize: '18px',
								lineHeight: '22px',
								letterSpacing: '0.1em',
								fontVariant: 'small-caps',
								color: 'rgba(18, 18, 18, 0.87)',
								textTransform: 'uppercase',
							}}
						>
							{moment(appointmentDate || study.scanDate).format('dddd')}
							<br />
							{moment(appointmentDate || study.scanDate).format('MMMM MM HH:mm A')}
						</Typography>

						<Box
							sx={{
								display: 'flex',
								gap: '27px',
							}}
						>
							<CardLabelText label="Modality" text={study.modality} />

							<CardLabelText label="Laterality" text={study.laterality} />

							<CardLabelText label="Body Part" text={study.bodyPart} />
						</Box>
					</Box>

					<Box sx={{ height: '48px' }} />
				</Box>

				<Box
					sx={{
						border: '1px solid rgba(18, 18, 18, 0.1)',
						borderRadius: '15px',
						marginBottom: '50px',
						width: '50%',
						height: '229px',
						[mediaMobile]: {
							width: '100%',
							height: 'unset',
						},
					}}
				>
					<Box
						ref={mapRef}
						sx={{
							minHeight: '181px',
							borderRadius: '15px',
						}}
					/>

					<Box
						ref={popperContainerRef}
						sx={{
							display: 'flex',
							justifyContent: 'end',
						}}
					>
						<IconButton
							onClick={e => {
								isMobile ? setIsShownListDrawer(true) : handleScheduledMoreClick(e);
							}}
						>
							<Icon component={MoreVertIcon} />
						</IconButton>
					</Box>
				</Box>
			</Box>

			<Popper
				anchorEl={scheduledMoreEl}
				container={popperContainerRef.current}
				open={Boolean(scheduledMoreEl)}
				placement="bottom-start"
			>
				<ClickAwayListener onClickAway={() => setTimeout(() => handleScheduledMoreClick(), 0)}>
					<Box
						sx={{
							background: '#fff',
							boxShadow: '4px 4px 15px rgba(18, 18, 18, 0.1)',
							borderRadius: '8px',
							width: '180px',
							'& .MuiListItem-root': {
								background: '#fff',
								border: 'none',
								marginBottom: '0',
							},
							'& .MuiTypography-root': {
								width: 'unset',
							},
						}}
					>
						<MobileScheduledMoreList />
					</Box>
				</ClickAwayListener>
			</Popper>

			<MobileBottomDrawer open={isShownListDrawer} title="Scheduled" onClose={() => setIsShownListDrawer(false)}>
				<MobileScheduledMoreList />
			</MobileBottomDrawer>
		</Box>
	);
};

export default MobileMapSection;
