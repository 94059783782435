import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { OutlineWrapper, RoleBadge } from '../../../../views/CrmView/styles';
import NewRoleField from './NewRoleField';
import { useAuth } from '@worklist-2/core/src';

const UserAssignedRolesCard = forwardRef((props, ref) => {
	const [editing, toggleEditing] = useState(false);
	const [initialData] = useState(props.user);
	const [user, setUser] = useState(props.user);
	const [payload, setPayload] = useState({});
	const { loggedInUser } = useAuth();

	useImperativeHandle(ref, () => ({
		isEditing: () => editing,
		cancelEditing: () => {
			setUser(initialData);
			toggleEditing(false);
		},
		save: () => {
			toggleEditing(false);
			return payload;
		},
	}));

	const onChange = data => {
		setUser(old => ({ ...old, ...data }));
		setPayload({ ...payload, ...data });
	};

	return (
		<OutlineWrapper style={{ flex: 0 }}>
			<div className="header">
				<div className="left">
					<div className="colored-icon primary-icon">
						<LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />
					</div>
					<h2>Assigned Role</h2>
				</div>
				{!editing && !!loggedInUser.permission.User.Edit && (
					<IconButton
						data-testid="edit-icon"
						id="closeEditButton_Edit_userAssignedRoles"
						onClick={() => toggleEditing(!editing)}
					>
						<EditOutlinedIcon
							id="closeEditButton_Edit_userAssignedRoles"
							sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
						/>
					</IconButton>
				)}
			</div>
			<Box sx={{ padding: '15px 19px' }}>
				<Box className="row body" style={{ justifyContent: 'normal', flexWrap: 'wrap' }}>
					{editing ? (
						<NewRoleField
							dismiss={() => toggleEditing()}
							roles={props.roles}
							user={user}
							onChange={onChange}
						/>
					) : (
						user?.RoleName && <RoleBadge>{user.RoleName}</RoleBadge>
					)}
				</Box>
			</Box>
		</OutlineWrapper>
	);
});

UserAssignedRolesCard.propTypes = {
	user: PropTypes.object.isRequired,
};

export default UserAssignedRolesCard;
