import { CometChat } from '@cometchat-pro/chat';

import * as enums from './enums.js';

export class SharedManager {
	textMessageListenerId = new Date().getTime();
	textMessageRequest = null;

	constructor(item, messagetype) {
		this.textMessageRequest = new CometChat.MessagesRequestBuilder()
			.setUID(item.uid)
			.setLimit(10)
			.setCategory('message')
			.setType(messagetype)
			.build();
	}

	fetchPreviousMessages() {
		return this.textMessageRequest.fetchPrevious();
	}

	attachListeners(callback) {
		CometChat.addMessageListener(
			this.msgListenerId,
			new CometChat.MessageListener({
				onTextMessageReceived: textMessage => {
					callback(enums.TEXT_MESSAGE_RECEIVED, textMessage);
				},
				onMessageDeleted: deletedMessage => {
					callback(enums.MESSAGE_DELETED, deletedMessage);
				},
			})
		);
	}

	removeListeners() {
		CometChat.removeMessageListener(this.textMessageListenerId);
	}
}
