import React from 'react';

const EmptyBlume = ({ dataTestId }) => (
	<svg data-testid={dataTestId} fill="none" height={230} width={227} xmlns="http://www.w3.org/2000/svg">
		<path
			d="M113.5 227c62.684 0 113.5-50.816 113.5-113.5S176.184 0 113.5 0 0 50.816 0 113.5 50.816 227 113.5 227Z"
			fill="url(#a)"
		/>
		<g filter="url(#b)">
			<mask
				height={227}
				id="d"
				maskUnits="userSpaceOnUse"
				style={{
					maskType: 'alpha',
				}}
				width={227}
				x={0}
				y={0}
			>
				<path
					d="M113.5 227c62.684 0 113.5-50.816 113.5-113.5S176.184 0 113.5 0 0 50.816 0 113.5 50.816 227 113.5 227Z"
					fill="url(#c)"
				/>
			</mask>
			<g mask="url(#d)">
				<path
					d="M178.573 65.074H48.426a7.567 7.567 0 0 0-7.566 7.567v158.9a7.567 7.567 0 0 0 7.566 7.567h130.147a7.567 7.567 0 0 0 7.567-7.567v-158.9a7.567 7.567 0 0 0-7.567-7.567Z"
					fill="#fff"
				/>
			</g>
		</g>
		<path
			d="M163.439 102.902h-99.88a6.054 6.054 0 0 0-6.054 6.054v15.133a6.053 6.053 0 0 0 6.054 6.053h99.88a6.053 6.053 0 0 0 6.053-6.053v-15.133a6.054 6.054 0 0 0-6.053-6.054Z"
			stroke="#1485FD"
			strokeWidth={2}
		/>
		<path d="M145 114h-44a3 3 0 1 0 0 6h44a3 3 0 1 0 0-6Z" fill="#B4DAFF" />
		<path
			d="M163.447 138.512h-99.88a6.053 6.053 0 0 0-6.054 6.053v15.133a6.053 6.053 0 0 0 6.053 6.054h99.881a6.053 6.053 0 0 0 6.053-6.054v-15.133a6.053 6.053 0 0 0-6.053-6.053Z"
			stroke="#1485FD"
			strokeWidth={2}
		/>
		<path d="M145.008 149.609h-44a3 3 0 0 0 0 6h44a3 3 0 1 0 0-6Z" fill="#B4DAFF" />
		<rect fill="#1485FD" fillOpacity={0.17} height={29} rx={7} width={29} x={58} y={138} />
		<path
			d="M163.454 174.121h-99.88a6.053 6.053 0 0 0-6.053 6.053v15.134a6.053 6.053 0 0 0 6.053 6.053h99.88a6.054 6.054 0 0 0 6.054-6.053v-15.134a6.054 6.054 0 0 0-6.054-6.053Z"
			stroke="#1485FD"
			strokeWidth={2}
		/>
		<path d="M145.016 185.219h-44a3 3 0 1 0 0 6h44a3 3 0 0 0 0-6Z" fill="#B4DAFF" />
		<rect fill="#1485FD" fillOpacity={0.17} height={29} rx={7} width={29} x={58} y={173} />
		<path d="M80.206 48.427a6.053 6.053 0 1 0 0-12.107 6.053 6.053 0 0 0 0 12.107Z" fill="#fff" />
		<path d="M113.499 48.427a6.053 6.053 0 1 0 0-12.106 6.053 6.053 0 0 0 0 12.106Z" fill="#1485FD" />
		<path d="M146.792 48.427a6.053 6.053 0 1 0 0-12.106 6.053 6.053 0 0 0 0 12.106Z" fill="#fff" />
		<path d="M130.146 133.172a7.566 7.566 0 1 0-7.566-7.566 7.565 7.565 0 0 0 7.566 7.566Z" fill="#DFEAFB" />
		<path
			d="M136.059 157.945c-1.211 0-2.341 0-3.37-.065a6.908 6.908 0 0 1-5.878-4.161l-6.385-12.618a2.722 2.722 0 0 1 .276-3.827 2.467 2.467 0 0 1 1.566-.543c.419.012.83.115 1.206.301.375.187.706.453.968.779l2.9 3.958.044.051v-15.035a2.812 2.812 0 1 1 5.625 0v9.837c-.033-.362.01-.727.127-1.071a2.607 2.607 0 0 1 1.423-1.559 2.632 2.632 0 0 1 2.111 0 2.622 2.622 0 0 1 1.551 2.63v2.05a2.613 2.613 0 0 1 2.606-2.851 2.607 2.607 0 0 1 1.931.851 2.63 2.63 0 0 1 .675 2v1.58a2.613 2.613 0 0 1 2.606-2.851 2.607 2.607 0 0 1 1.931.851 2.618 2.618 0 0 1 .675 2v9.59c-.052 2.95-1.385 7.923-6.075 7.923-.34.015-3.225.181-6.507.181l-.006-.001Z"
			fill="#1485FD"
			stroke="#fff"
		/>
		<defs>
			<linearGradient gradientUnits="userSpaceOnUse" id="a" x1={113.5} x2={113.5} y1={0} y2={227}>
				<stop stopColor="#E3ECFA" />
				<stop offset={1} stopColor="#DAE7FF" />
			</linearGradient>
			<linearGradient gradientUnits="userSpaceOnUse" id="c" x1={113.5} x2={113.5} y1={0} y2={227}>
				<stop stopColor="#E3ECFA" />
				<stop offset={1} stopColor="#DAE7FF" />
			</linearGradient>
			<filter
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
				height={173.926}
				id="b"
				width={157.28}
				x={34.86}
				y={56.074}
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy={-3} />
				<feGaussianBlur stdDeviation={3} />
				<feColorMatrix values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
				<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2852_46322" />
				<feBlend in="SourceGraphic" in2="effect1_dropShadow_2852_46322" result="shape" />
			</filter>
		</defs>
	</svg>
);

export default EmptyBlume;
