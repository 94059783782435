/**
 * Get the last quarter time range from a date
 * @param  {Date} date - the input Date object.
 * @return {Object}
 */
const getQuarterTimeRange = (date) => {
    // Calculate the current quarter's start and end months
    const currentQuarterStartMonth = Math.floor(date.getMonth() / 3) * 3;
    const currentQuarterEndMonth = currentQuarterStartMonth + 2;

    // Calculate the previous quarter's start and end months
    let lastQuarterStartMonth = currentQuarterStartMonth - 3,
        lastQuarterEndMonth = currentQuarterEndMonth - 3;

    // Determine the year changes
    let lastQuarterYear = date.getFullYear();
    if (lastQuarterStartMonth < 0) {
        lastQuarterYear--;
        lastQuarterStartMonth += 12;
        lastQuarterEndMonth += 12;
    }

    // Calculate the start and end dates of the last quarter
    const lastQuarterStartDate = new Date(lastQuarterYear, lastQuarterStartMonth, 1);
    const lastDayOfLastQuarter = new Date(lastQuarterYear, lastQuarterEndMonth + 1, 0); // Last day of the next month
    const lastQuarterEndDate = new Date(lastQuarterYear, lastQuarterEndMonth, lastDayOfLastQuarter.getDate());

    return {
        start: lastQuarterStartDate,
        end: lastQuarterEndDate,
    };
};

export default getQuarterTimeRange;
