import { getEnabledElement, triggerEvent, eventTarget } from '@cornerstonejs/core';
import { Enums, ProbeTool, utilities as toolsUtilites, annotation, cursors } from '@cornerstonejs/tools';

const { Events } = Enums;
const { triggerAnnotationRenderForViewportIds } = toolsUtilites;
const { removeAnnotation } = annotation.state;
const { resetElementCursor } = cursors.elementCursor;

class ProbeToolUpdated extends ProbeTool {
	_endCallback = (evt) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const { annotation, viewportIdsToRender } = this.editData;

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		const { viewportId } = enabledElement;
		this.eventDispatchDetail = {
			viewportId,
			renderingEngineId: renderingEngine.id,
		};

		this._deactivateModify(element);

		resetElementCursor(element);

		this.editData = null;
		this.isDrawing = false;

		if (this.isHandleOutsideImage && this.configuration.preventHandleOutsideImage) {
			removeAnnotation(annotation.annotationUID);
		}

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		const eventType = Events.ANNOTATION_COMPLETED;

		triggerEvent(eventTarget, eventType, { annotation });
	};
}

export default ProbeToolUpdated;
