import styled from 'styled-components';
import { mediaTablet } from '@worklist-2/core/src';

export const ContentBioLeft = styled.div`
	padding: 20px 20px 20px 0px;
	align-items: center;
	display: flex;
	flex-direction: column;
`;

export const MainBioContent = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const SideComponent = styled.div`
	min-width: 30em;
	overflow-x: hidden;
	height: inherit;

	${mediaTablet} {
		width: 90vw;
	}
`;

export const ContentWrapper = styled.div`
	margin: 10px 10px 10px 35px;
	padding: 15px 20px 15px 20px;
	border-radius: 6px;
	background-color: #393939;
	display: flex;
	flex-direction: row;
	color: rgba(255, 255, 255, 0.87);
	font-family: Roboto;
	height: inherit;
	border: 1px solid #393939;

	&.column {
		flex-direction: column;
	}

	&.clickable:hover {
		background: #27272729;
		cursor: pointer;
	}

	a {
		font-size: 12px;
	}

	label {
		font-size: 14px;
		line-height: 1.43;
		letter-spacing: 0.25px;
		text-align: left;
		color: rgba(255, 255, 255, 0.6);
	}

	.body {
		margin: 15px 0px;
		display: flex;
		flex-direction: row;
		align-items: center;

		.flex-column {
			flex: 1;
			display: flex;
			flex-direction: column;
		}
	}

	span {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: 0.25px;
		text-align: left;
		color: rgba(255, 255, 255, 0.87);
	}

	.content h2 {
		font-size: 20px;
		font-weight: 500;
		color: #fff;
	}
`;

export const InfoIcon = styled.div`
	width: 39.6px;
	height: 40px;
	background-color: #568af3;
	margin: 16px 16px 16px 0px;
	border-radius: 50%;

	&.success {
		background-color: #46a2b1;
	}

	&.danger {
		background-color: #a66ba3;
	}
`;

export const AvatarWrapper = styled.div`
	width: 90px;
	height: 89px;
	border-radius: 50%;
	flex-grow: 0;
	margin: 0 1px 24px;
	background-color: #d7d7d7;
	margin-bottom: 27px;
	font-size: 50px;
	font-weight: normal;
	color: #555;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-family: Roboto;
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	border-bottom: ${props => (props.bottomBorder ? '1px solid rgba(0, 0, 0, 0.1)' : '0px')};
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 4px 23px 4px 2px;

	&.mx-0 {
		margin-left: 0px;
		margin-right: 0px;
	}

	&.w-50 % {
		width: 50%;
	}

	span {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: 0.25px;
		text-align: left;
		color: rgba(255, 255, 255, 0.87);
	}

	label {
		font-size: 14px;
		line-height: 1.43;
		letter-spacing: 0.25px;
		text-align: left;
		color: rgba(255, 255, 255, 0.6);
	}

	label.detail {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		color: rgba(0, 0, 0, 0.6);
	}

	input,
	select {
		background: rgba(196, 196, 196, 0.15);
		border: 1px solid rgba(0, 0, 0, 0.05);
		border-radius: 10px;
		height: 40px !important;
		font-size: 14px;
		color: rgba(0, 0, 0, 0.9);
		margin-top: 10px !important;
		padding: 0px 12px;
		max-width: 225px;
	}

	.row {
		display: flex;
		flex-direction: row;
		align-items: center;

		span {
			margin-right: 20px;
		}
	}

	.form-control {
		min-height: 40px;
		overflow-y: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;

		input,
		select {
			flex: 1 !important;
		}
	}
`;

export const HeaderButton = styled.button`
	display: flex;
	flex-direction: column;
	background: transparent;
	font-size: 12px;
	align-items: center;
	justify-content: center;
	border: 0px;
	padding: 5px 10px;
	margin: 3px;
	color: rgba(255, 255, 255, 0.6);

	&.left {
		border-left: 1px solid rgba(255, 255, 255, 0.3);
	}

	&:hover {
		background: #27272729;
		cursor: pointer;
	}

	svg,
	span {
		margin-top: -5px;
		color: rgba(255, 255, 255, 0.6);
	}

	span {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: 0.4px;
		text-align: center;
		color: rgba(255, 255, 255, 0.6);
	}
`;

export const VerticalLine = styled.div`
	border: 1px solid rgba(255, 255, 255, 0.1);
	height: 20px;
`;

export const UndoDialog = styled.div`
	padding: 8px 16px 9px;
	border-radius: 6px;
	border: solid 1px #4d4d4d;
	background-color: #393939;
	min-width: 379px;
	position: fixed;
	z-index: 10;
	top: 197px;
	margin: auto;
	margin-left: 10%;
	display: flex;
	flex-direction: row;
	align-items: center;

	span {
		flex: 1;
		font-family: Roboto;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: 0.25px;
		text-align: left;
		color: #cf6679;
	}

	a {
		margin: 5px;
		font-family: Roboto;
		font-size: 14px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.14;
		letter-spacing: 1.25px;
		text-align: left;
		color: #42a5f5;
		cursor: hover;
	}
`;
