import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { Box, Stack, FormControl, Checkbox } from '@mui/material';
import FormToolSubheader from '@worklist-2/ui/src/components/FormTool/FormToolSubheader';
import FormToolLabel from '@worklist-2/ui/src/components/FormTool/FormToolLabel';

const FormToolCheckboxTableRow = ({
	data,
	questionId,
	options = [],
	answer,
	isReadOnly,
	index,
	handleInputData,
	isCompact,
}) => {
	const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

	useEffect(() => {
		if (answer?.length > 0) {
			setSelectedCheckboxes(answer);
		}
	}, [answer]);

	const handleChange = event => {
		const checkBoxName = event.target.name;
		const isChecked = event.target.checked;
		if (isChecked) {
			setSelectedCheckboxes([...selectedCheckboxes, checkBoxName]);
			handleInputData(index, [...selectedCheckboxes, checkBoxName]);
		} else {
			setSelectedCheckboxes(selectedCheckboxes.filter(name => name !== checkBoxName));
			handleInputData(
				index,
				selectedCheckboxes.filter(name => name !== checkBoxName)
			);
		}
	};

	return (
		<>
			<Stack justifyContent="center">
				<FormToolLabel required={data.required}>{data.description}</FormToolLabel>
			</Stack>

			{options.map((optionItem, optionIndex) => (
				<Stack
					key={`${questionId}-${optionIndex}-${optionItem}`}
					sx={{
						alignItems: 'center',
						justifyContent: 'center',
						'.MuiCheckbox-root.Mui-disabled': {
							color: 'rgba(0, 0, 0, 0.26)',
						},
					}}
				>
					<Checkbox
						checked={answer?.includes(optionItem.description)}
						color="rsPrimary"
						disabled={isReadOnly}
						name={optionItem.description}
						sx={{
							marginTop: '-9px',
							marginBottom: '-9px',
						}}
						onClick={handleChange}
					/>
				</Stack>
			))}
		</>
	);
};

const FormToolCheckboxGrid = ({
	id = uuid(),
	title,
	description,
	options = [],
	questions = [],
	answer = [],
	required,
	onChangeData,
	isReadOnly,
	isCompact,
}) => {
	const titleId = `${id}-survey-table-title`;
	const [selections, setSelections] = useState(Array(options.length).fill([]));
	useEffect(() => {
		if (answer.length > 0) {
			setSelections(answer[answer.length - 1].value);
		}
	}, [answer]);

	const handleInputData = (index, data) => {
		const updatedData = [...selections];
		updatedData[index] = data;
		setSelections(updatedData);
		onChangeData(updatedData);
	};

	return (
		<FormControl>
			<FormToolSubheader
				description={description}
				isCompact={isCompact}
				required={required}
				title={title}
				titleId={titleId}
			/>

			<Box
				sx={{
					'--element-content-label-font-size': '14px',
					display: 'grid',
					gridTemplateColumns: `1fr repeat(${options.length}, max-content)`,
					gap: isCompact ? '5px' : 'var(--element-spacing) 10px',
					'.MuiCheckbox-root': {
						color: 'rgba(0, 0, 0, 0.2)',
					},
					'.Mui-checked': {
						color: '#4096DC',
					},
				}}
			>
				<div />

				{options.map((item, index) => (
					<FormToolLabel
						key={`${index}-${item}`}
						sx={{
							textAlign: 'center',
						}}
					>
						{item.description}
					</FormToolLabel>
				))}

				{questions.map((item, index) => (
					<FormToolCheckboxTableRow
						key={`${index}-${item.description}`}
						answer={selections[index]}
						data={item}
						handleInputData={handleInputData}
						index={index}
						isCompact={isCompact}
						isReadOnly={isReadOnly}
						options={options}
						questionId={`${id}-survey-table-question-${index}`}
					/>
				))}
			</Box>
		</FormControl>
	);
};

export default FormToolCheckboxGrid;
