import axios from 'axios';

const unshareDocument = async ({ __config, documentId, email, isFacility }) => {
	const response = await axios.patch(
		`${__config.data_sources.blume}Document/${documentId}/${isFacility ? 'facility' : ''}unshare${
			isFacility ? `?facilityId=${email}` : ''
		}`,
		email,
		{
			headers: {
				Accept: '*/*',
				'Content-Type': 'application/json',
			},
		}
	);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default unshareDocument;
