import React, { useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDrag, useDrop } from 'react-dnd';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import { useNumberFlagValue, useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const reorderColumn = (draggedColumnId, targetColumnId, draggableColumnOrder) => {
	draggableColumnOrder.splice(
		draggableColumnOrder.indexOf(targetColumnId),
		0,
		draggableColumnOrder.splice(draggableColumnOrder.indexOf(draggedColumnId), 1)[0]
	);
	return [...draggableColumnOrder];
};

const DataGridColumnHeaderV2 = ({
	header,
	index,
	draggableTable,
	enableColumnDnd = false,
	flexRender,
	canResizeColumns,
	columnResizeMode,
	setCurrWorklistColumns,
	lastColWidth,
	onMouseOver,
	onMouseOut,
	cellSx,
	onColumnWidthChange,
	rows,
}) => {
	const { getState, setColumnOrder } = draggableTable;
	const { columnOrder } = getState();
	const { column } = header;
	const { minSize, maxSize } = column.columnDef;
	const ref = useRef(null);
	const [width, setWidth] = useState(0);
	const [isResizing, setIsResizing] = useState(false);
	const mavenWorklistVerticalSpacing = useNumberFlagValue('maven-worklist-vertical-spacing');
	const mavenWorklistHorizontalSpacing = useNumberFlagValue('maven-worklist-horizontal-spacing');
	const mavenWorklistUsabilityImprovements = useBooleanFlagValue('maven-worklist-usability-improvements');

	useEffect(() => {
		setWidth(header.getSize());
	}, [header]);

	const [, dropRef] = useDrop({
		accept: 'COLUMN',
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		hover: (item, monitor) => {
			if (!ref.current || !enableColumnDnd) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			// Not allowed to move to same position
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Determine pixels to the left
			const hoverClientX = clientOffset.x - hoverBoundingRect.left;

			// Only move when cursor has crossed half (50%) of column width
			if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
				return;
			}

			if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
				return;
			}

			// Move the column
			const newColumnOrder = reorderColumn(item.id, column.id, columnOrder);
			setColumnOrder(newColumnOrder);

			// Set Worklist Columns to show save button
			setCurrWorklistColumns?.(newColumnOrder);

			// Assigning index to avoid expensive searches
			item.index = hoverIndex;
		},
	});

	const [, dragRef] = useDrag({
		canDrag: enableColumnDnd,
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
		item: { id: column.id, index },
		type: 'COLUMN',
	});

	const onChangeWidth = useCallback(
		newWidth => {
			onColumnWidthChange(column.id, newWidth);
		},
		[onColumnWidthChange, column.id]
	);

	return (
		<TableCell
			key={column.id}
			colSpan={header.colSpan}
			id={`columnheader-${column.id}`}
			sx={{
				width: `${width}px`,
				minWidth: minSize,
				maxWidth: maxSize,
				whiteSpace: 'nowrap',
				'&:first-of-type': {
					width: '24px !important',
					'.resizer': {
						display: 'none',
					},
				},
				'&:last-of-type': {
					width: lastColWidth,
					'.resizer': {
						display: 'none',
					},
				},
				...cellSx,
				padding: `${mavenWorklistVerticalSpacing}px ${mavenWorklistHorizontalSpacing}px`,
			}}
			onMouseOut={!isResizing ? onMouseOut : null}
			onMouseOver={!isResizing ? onMouseOver : null}
		>
			<Box>
				<div ref={!isResizing && enableColumnDnd ? dragRef(dropRef(ref)) : ref}>
					{header.isPlaceholder
						? null
						: flexRender(header.column.columnDef?.header, { rows, ...header.getContext() })}
				</div>

				{canResizeColumns && (
					<div
						className={`${mavenWorklistUsabilityImprovements ? 'resizer' : 'resizerold'} ${
							isResizing ? 'isResizing' : ''
						}`}
						data-testid={`${column.id}-resizer`}
						style={{
							cursor: 'col-resize',
							position: 'absolute',
							userSelect: 'none',
							touchAction: 'none',
							transform:
								columnResizeMode === 'onEnd' && isResizing
									? `translateX(${draggableTable.getState()?.columnSizingInfo?.deltaOffset}px)`
									: '',
						}}
						onMouseDown={e => {
							e.stopPropagation();
							// This prevents drag action from happening
							const startX = e.clientX;
							let newUpdateWidth;

							setIsResizing(true);

							// Mouse move event
							const handleMouseMove = event => {
								// Get the current width of the column
								const currentWidth = width || document.getElementById(column.id).offsetWidth;
								// Calculate the new width
								let newWidth = currentWidth + (event.clientX - startX);

								// Ensure the new width is within the min and max bounds
								if (newWidth < minSize) {
									newWidth = minSize;
								} else if (newWidth > maxSize) {
									newWidth = maxSize;
								}

								// Set the new width
								newUpdateWidth = newWidth;
								setWidth(newWidth);
							};

							// Mouse up event
							const handleMouseUp = () => {
								// Remove the event listeners
								document.removeEventListener('mousemove', handleMouseMove);
								document.removeEventListener('mouseup', handleMouseUp);

								setIsResizing(false);

								// Call the function to update the column width
								onChangeWidth(newUpdateWidth);
							};

							// Add the event listeners
							document.addEventListener('mousemove', handleMouseMove);
							document.addEventListener('mouseup', handleMouseUp);
						}}
						onTouchStart={header.getResizeHandler()}
					/>
				)}
			</Box>
		</TableCell>
	);
};

export const MemoizedDataGridColumnHeaderV2 = React.memo(DataGridColumnHeaderV2);

DataGridColumnHeaderV2.propTypes = {
	/**
	 * Column header object
	 */
	header: PropTypes.shape({
		column: PropTypes.shape({
			id: PropTypes.string.isRequired,
			columnDef: PropTypes.shape({
				header: PropTypes.node,
			}),
		}).isRequired,
		getResizeHandler: PropTypes.func.isRequired,
		isPlaceholder: PropTypes.bool.isRequired,
		getContext: PropTypes.func.isRequired,
		getSize: PropTypes.func.isRequired,
		colSpan: PropTypes.number.isRequired,
	}),
	/**
	 * Index of the column
	 */
	index: PropTypes.number.isRequired,
	/**
	 * Draggable table object
	 */
	draggableTable: PropTypes.shape({
		getState: PropTypes.func.isRequired,
		setColumnOrder: PropTypes.func.isRequired,
	}),
	/**
	 * Enable column drag and drop
	 */
	enableColumnDnd: PropTypes.bool,
	/**
	 * Function to render the column header
	 */
	flexRender: PropTypes.func,
	/**
	 * Whether columns can be resized
	 */
	canResizeColumns: PropTypes.bool,
	/**
	 * Column resize mode
	 */
	columnResizeMode: PropTypes.string,
	/**
	 * Function to set the current worklist columns
	 */
	setCurrWorklistColumns: PropTypes.func,
	/**
	 * Width of the last column
	 */
	lastColWidth: PropTypes.string,
	/**
	 * Function to handle mouse over event
	 */
	onMouseOver: PropTypes.func,
	/**
	 * Function to handle mouse out event
	 */
	onMouseOut: PropTypes.func,
	/**
	 * Styles for the cell
	 */
	cellSx: PropTypes.shape({}),
	/**
	 * Function to handle column width change
	 */
	onColumnWidthChange: PropTypes.func,
};

DataGridColumnHeaderV2.defaultProps = {
	header: {},
	draggableTable: {},
	enableColumnDnd: false,
	flexRender: () => {},
	canResizeColumns: false,
	columnResizeMode: 'onChange',
	setCurrWorklistColumns: () => {},
	lastColWidth: 0,
	onMouseOver: () => {},
	onMouseOut: () => {},
	cellSx: {},
	onColumnWidthChange: () => {},
};

export default DataGridColumnHeaderV2;
