const crmEndpoints = {
	// CRM
	commentApi: 'Comment',
	caseApi: 'Case',
	accountApi: 'Account',
	notificationApi: 'Notification',
	userApi: 'User',
	teamApi: 'Team',
	reportApi: 'Report',
	roleApi: 'Role',
	globalSearchApi: 'GlobalSearch',
	okrApi: 'Okr',
	contactApi: 'Contact',
	conversationApi: 'Conversation',
	permissionApi: 'permissionApi',
	organizationLicenseApi: 'OrganizationLicenseOmegaAI',
	organizationApi: 'OrganizationOmegaAI',
	practitionerApi: 'PractitionerOmegaAI',
	practitionerRoleApi: 'PractitionerRoleOmegaAI',
	roleOmegaAIApi: 'RoleOmegaAI',
	deviceApi: 'DeviceOmegaAI',
	taskApi: 'TaskOmegaAI',
	subscriptionApi: 'SubscriptionOmegaAI',
};

export const crmScopeMapping = {
	// CRM
	all: crmEndpoints.caseApi,
	commentApi: crmEndpoints.commentApi,
	caseApi: crmEndpoints.caseApi,
	accountApi: crmEndpoints.accountApi,
	notificationApi: crmEndpoints.notificationApi,
	userApi: crmEndpoints.userApi,
	teamApi: crmEndpoints.teamApi,
	globalSearchApi: crmEndpoints.globalSearchApi,
	reportApi: crmEndpoints.reportApi,
	roleApi: crmEndpoints.roleApi,
	okrApi: crmEndpoints.okrApi,
	contactApi: crmEndpoints.contactApi,
	conversationApi: crmEndpoints.conversationApi,
	permissionApi: crmEndpoints.permissionApi,
	organizationLicenseApi: crmEndpoints.organizationLicenseApi,
	organizationApi: crmEndpoints.organizationApi,
	practitionerApi: crmEndpoints.practitionerApi,
	practitionerRoleApi: crmEndpoints.practitionerRoleApi,
	roleOmegaAIApi: crmEndpoints.roleOmegaAIApi,
	deviceApi: crmEndpoints.deviceApi,
	taskApi: crmEndpoints.taskApi,
	subscriptionApi: crmEndpoints.subscriptionApi,
};

export default crmEndpoints;
