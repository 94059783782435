import React, { createContext, useContext, useRef, useState } from 'react';
import { StackScrollTool, utilities } from '@cornerstonejs/tools';
import saveWheelConfig from '../api/saveWheelConfig';
import { useConfig } from '@worklist-2/core/src';
import { tools } from '../features/Wheel/tools';
import getDefaultRenderingEngine from '../cornerstone/getDefaultRenderingEngine';
import { useSearchParams } from 'react-router-dom';
import { useCustomToolBarStoreSelector } from '../features/CustomToolbar/context/zustand/CustomToolBarZustandProvider';
import { useImageViewerLayoutStoreSelector } from '../features/CustomToolbar/context/zustand/ImageViewerLayoutZustandProvider';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const ImageViewerWheelContext = createContext({});

const ImageViewerWheelContextProvider = ({ children }) => {
	const __config = useConfig();
	const wonIvCustomtoolbar = useBooleanFlagValue('WON-IV-CUSTOMTOOLBAR');
	const [searchParams] = useSearchParams();
	const isSharedStudy = searchParams.get('SharedStudy');
	const setHotkeyConfig = useCustomToolBarStoreSelector(state => state.setHotkeyConfig);
	const addActiveCine = useImageViewerLayoutStoreSelector(state => state.addActiveCine);
	const playCineZustand = useImageViewerLayoutStoreSelector(state => state.playCine);
	const stopCineZustand = useImageViewerLayoutStoreSelector(state => state.stopCine);
	const removeActiveCine = useImageViewerLayoutStoreSelector(state => state.removeActiveCine);

	const [wheelSettings, setWheelSettings] = useState({
		wheelTools: tools,
		wheelAdjustmentToolsCount: 8,
		wheelMarkupToolsCount: 8,
	});
	const [lastAdjustmentTool, setLastAdjustmentTool] = useState();
	const [activeTool, setActiveTool] = useState(StackScrollTool.toolName);
	const [isShownWheelCustomizationDrawer, setIsShownWheelCustomizationDrawer] = useState(false);
	const [activeCine, setActiveCine] = useState([]);
	const activeToolRef = useRef(activeTool);
	activeToolRef.current = activeTool;

	const submitWheelConfig = wheelConfig => {
		if (isSharedStudy) {
			return;
		}
		wonIvCustomtoolbar && setHotkeyConfig(wheelConfig.wheelTools);
		return saveWheelConfig({ __config, wheelConfig });
	};

	const createCine = ({ element }) =>
		new Promise(resolve => {
			const renderingEngine = getDefaultRenderingEngine();

			if (!renderingEngine) {
				return;
			}

			const viewport = renderingEngine.getViewport(element.id);

			if (!viewport) {
				return;
			}

			const imageIds = viewport.getImageIds();

			viewport
				.loadImages(imageIds || [])
				.then(() => {
					utilities.cine.playClip(element, {});
				})
				.finally(() => resolve());

			wonIvCustomtoolbar &&
				addActiveCine({
					id: element.id,
					isActive: true,
				});
			setActiveCine(prevState => [
				...prevState,
				{
					id: element.id,
					isActive: true,
				},
			]);
		});

	const removeCine = ({ element }) => {
		utilities.cine.stopClip(element);
		wonIvCustomtoolbar && removeActiveCine(element);
		setActiveCine(prevState => prevState.filter(item => item.id !== element.id));
	};

	const playCine = ({ element, options = {} }) => {
		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) {
			return;
		}

		const viewport = renderingEngine.getViewport(element.id);

		if (!viewport) {
			return;
		}

		const imageIds = viewport.getImageIds();

		viewport.loadImages(imageIds || []).then(() => {
			utilities.cine.playClip(element, options);
		});
		wonIvCustomtoolbar && playCineZustand(element);
		setActiveCine(prevState =>
			prevState.map(item => {
				if (item.id === element.id) {
					return {
						...item,
						isActive: true,
					};
				}

				return item;
			})
		);
	};

	const stopCine = ({ element }) => {
		utilities.cine.stopClip(element);
		wonIvCustomtoolbar && stopCineZustand(element);
		setActiveCine(prevState =>
			prevState.map(item => {
				if (item.id === element.id) {
					return {
						...item,
						isActive: false,
					};
				}

				return item;
			})
		);
	};

	const toggleCine = ({ element }) => {
		const cineItem = activeCine.find(item => item.id === element.id);

		if (!cineItem) {
			return createCine({ element });
		}

		if (cineItem.isActive) {
			stopCine({ element });
		} else {
			playCine({ element });
		}
	};

	return (
		<ImageViewerWheelContext.Provider
			value={{
				wheelSettings,
				setWheelSettings,
				submitWheelConfig,
				activeTool,
				setActiveTool,
				activeToolRef,
				isShownWheelCustomizationDrawer,
				setIsShownWheelCustomizationDrawer,
				removeCine,
				activeCine,
				setActiveCine,
				createCine,
				playCine,
				stopCine,
				toggleCine,
				lastAdjustmentTool,
				setLastAdjustmentTool,
			}}
		>
			{children}
		</ImageViewerWheelContext.Provider>
	);
};

const useImageViewerWheelContext = () => useContext(ImageViewerWheelContext);

export { ImageViewerWheelContext, useImageViewerWheelContext, ImageViewerWheelContextProvider };
