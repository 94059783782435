import * as React from 'react';
import PropTypes from 'prop-types';
import SelectUnstyled, { selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import { styled } from '@mui/system';
import { PopperUnstyled } from '@mui/base';
import { mediaMobile } from '@worklist-2/core/src';

const blue = {
	100: '#DAECFF',
	900: '#003A75',
};

const grey = {
	100: '#E7EBF0',
	400: '#B2BAC2',
	300: '#393939',
	900: '#1A2027',
};

const StyledButton = styled('button')(
	() => `
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  box-sizing: border-box;
  width: inherit;
  background: none;
  border: none;
  text-align: center;
  line-height: 1.5;
  color: ${grey[900]};
  letter-spacing: 0.4px;
  display: inline-flex;

  ${mediaMobile} {
		font-size: 14px;
		line-height: 16px;
	}

  &:hover {
    border-color: ${grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    width: 24%;
    text-align: right;
  }

  & .language {
    width: 100%;
    margin-left: 15%;
  }

  & .flag {
    width: 25%;
    text-align: right;
  }

  & .country {
    width: 50%;
    text-align: center;
  }
  `
);

const StyledListbox = styled('ul')(
	() => `
  font-family: Roboto;
  font-size: 20px;
  box-sizing: border-box;
  padding: 5px;
  width: 500px;
  max-width: 100%;
  max-height: 200px;
  background: #fff;
  border-radius: 5px;
  color: ${grey[900]};
  letter-spacing: 0.4px;
  overflow: auto;
  text-align: center;

  ${mediaMobile} {
		width: 328px;
		margin-left: -4px;
	}

  & li {
    display: flex
  }

  & .language {
    width: 100%;
  }

  & .flag {
    width: 42%;
  }

  & .country {
    width: 50%;
    text-align: left;
    margin-left: 10px;
  }

  &::-webkit-scrollbar {
		width: 7px;
		height: 7px;
	}
	&::-webkit-scrollbar-button {
		width: 0px;
		height: 0px;
	}
	&::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border: 0px none #c4c4c4;
		border-radius: 50px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #a39f9f;
	}
	&::-webkit-scrollbar-thumb:active {
		background: #a39f9f;
	}
	&::-webkit-scrollbar-track {
		background: rgb(196 196 196 / 17%);
		border: 0px none #ffffff;
		border-radius: 50px;
	}
	&::-webkit-scrollbar-track:hover {
		background: rgb(196 196 196 / 17%);
	}
	&::-webkit-scrollbar-track:active {
		background: rgb(196 196 196 / 17%);
	}
	&::-webkit-scrollbar-corner {
		background: transparent;
	}
  `
);

export const StyledOption = styled(OptionUnstyled)(
	() => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;
  margin-top: 5px;

  ${mediaMobile} {
		font-weight: 400;
		font-size: 14px;
		line-height: 14px;
		color: #000000 !important;
	}

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${blue[100]};
    color: ${blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${grey[100]};
    color: ${grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${blue[100]};
    color: ${blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${grey[100]};
    color: ${grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
	z-index: 1;
`;

export const Select = React.forwardRef((props, ref) => {
	const components = {
		Root: StyledButton,
		Listbox: StyledListbox,
		Popper: StyledPopper,
		...props.components,
	};

	return <SelectUnstyled {...props} ref={ref} components={components} />;
});

Select.propTypes = {
	/**
	 * The components used for each slot inside the Select.
	 * Either a string to use a HTML element or a component.
	 * @default {}
	 */
	components: PropTypes.shape({
		Listbox: PropTypes.elementType,
		Popper: PropTypes.elementType,
		Root: PropTypes.elementType,
	}),
};
