const hotkeyCodes = {
	Escape: 'Escape',
	Tab: 'Tab',
	ShiftLeft: 'ShiftLeft',
	ShiftRight: 'ShiftRight',
	ControlLeft: 'ControlLeft',
	ControlRight: 'ControlRight',
	AltLeft: 'AltLeft',
	AltRight: 'AltRight',
	MetaLeft: 'MetaLeft',
	MetaRight: 'MetaRight',
	Space: 'Space',
	ArrowLeft: 'ArrowLeft',
	ArrowUp: 'ArrowUp',
	ArrowRight: 'ArrowRight',
	ArrowDown: 'ArrowDown',
	Backspace: 'Backspace',
	Delete: 'Delete',
	PageDown: 'PageDown',
	PageUp: 'PageUp',
	Home: 'Home',
	End: 'End',
	KeyA: 'KeyA',
	KeyB: 'KeyB',
	KeyC: 'KeyC',
	KeyD: 'KeyD',
	KeyE: 'KeyE',
	KeyF: 'KeyF',
	KeyG: 'KeyG',
	KeyH: 'KeyH',
	KeyI: 'KeyI',
	KeyJ: 'KeyJ',
	KeyK: 'KeyK',
	KeyL: 'KeyL',
	KeyM: 'KeyM',
	KeyN: 'KeyN',
	KeyO: 'KeyO',
	KeyP: 'KeyP',
	KeyQ: 'KeyQ',
	KeyR: 'KeyR',
	KeyS: 'KeyS',
	KeyT: 'KeyT',
	KeyU: 'KeyU',
	KeyV: 'KeyV',
	KeyW: 'KeyW',
	KeyX: 'KeyX',
	KeyY: 'KeyY',
	KeyZ: 'KeyZ',
	Digit0: 'Digit0',
	Numpad0: 'Numpad0',
	Digit1: 'Digit1',
	Numpad1: 'Numpad1',
	Digit2: 'Digit2',
	Numpad2: 'Numpad2',
	Digit3: 'Digit3',
	Numpad3: 'Numpad3',
	Digit4: 'Digit4',
	Numpad4: 'Numpad4',
	Digit5: 'Digit5',
	Numpad5: 'Numpad5',
	Digit6: 'Digit6',
	Numpad6: 'Numpad6',
	Digit7: 'Digit7',
	Numpad7: 'Numpad7',
	Digit8: 'Digit8',
	Numpad8: 'Numpad8',
	Digit9: 'Digit9',
	Numpad9: 'Numpad9',
	NumpadAdd: 'NumpadAdd',
	NumpadSubtract: 'NumpadSubtract',
	NumpadDivide: 'NumpadDivide',
	Slash: 'Slash',
	Semicolon: 'Semicolon',
	Comma: 'Comma',
	Period: 'Period',
	NumpadDecimal: 'NumpadDecimal',
	Backquote: 'Backquote',
	BracketLeft: 'BracketLeft',
	BracketRight: 'BracketRight',
	IntlBackslash: 'IntlBackslash',
	Backslash: 'Backslash',
	F1: 'F1',
	F2: 'F2',
	F3: 'F3',
	F4: 'F4',
	F5: 'F5',
	F6: 'F6',
	F7: 'F7',
	F8: 'F8',
	F9: 'F9',
	F10: 'F10',
	F11: 'F11',
	F12: 'F12',
};

export default hotkeyCodes;
