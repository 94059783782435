import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { styled } from '@mui/material/styles';
import { TabPanel } from '../../../Cases/casePopOverStyles';
import FilterableColumn from '../../../Cases/FilterableColumn';

//temp
import { DivImage } from '../../../../../views/CrmView/styles';
import Avatar from '@worklist-2/ui/src/assets/img/unsplash_6W4F62sN_yI.png';

const Users = ({ data }) => {
	const [users, setUsers] = useState([]);
	const [field, setField] = useState(null);
	const [order, setOrder] = useState('asc');
	const [searchText, setSearchText] = useState('');

	useEffect(() => {
		setUsers(dataFormat(data));
	}, [data]);

	useEffect(() => {
		setUsers(dataFormat(data));
		setSearchText('');
	}, [field]);

	useEffect(() => {
		if (field) {
			setUsers(
				dataFormat(data).filter(user =>
					user[field].split(' ').join('').toLowerCase().includes(searchText.toLowerCase())
				)
			);
		}
	}, [searchText]);

	const handleInputText = e => {
		e.preventDefault();
		setSearchText(e.target.value);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = field === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		dataSort(order);
	};

	const dataFormat = rawData =>
		rawData.map(user => ({
			UserName: user.resource.practitioner?.display || '-',
			Status: user.resource.active ? 'Active' : 'Inactive',
			Email:
				user.resource.extension?.find(item => item.url.includes('/StructureDefinition/loginName'))
					?.valueString || '-',
			AssignedRoles:
				user.resource.extension?.find(item => item.url.includes('/StructureDefinition/role'))?.valueReference
					?.display || '-',
		}));

	const dataSort = orderParam => {
		const isAsc = orderParam === 'asc';
		users.sort((a, b) => (isAsc ? b[field]?.localeCompare(a[field]) : a[field]?.localeCompare(b[field])));
	};

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'&': {
			color: theme.palette.common.black,
		},
		'&:hover td': {
			backgroundColor: '#C8D6FF',
		},
		'& td': {
			height: '38px',
			padding: '0 2px',
			alignItems: 'center',
			border: 'none',
		},
		'&:nth-of-type(odd)': {
			backgroundColor: '#F9FBFF',
		},
		'&:nth-of-type(even)': {
			backgroundColor: theme.palette.common.white,
		},
	}));

	return (
		<TabPanel>
			<div className="header">
				<div className="left">
					<div className="colored-icon primary-icon">
						<PersonOutlineOutlinedIcon sx={{ fontSize: 20 }} />
					</div>
					<h2>Users</h2>
				</div>
			</div>

			<TableContainer
				style={{
					height: '300px',
					border: 0,
					borderRadius: 0,
					borderTopRightRadius: '20px',
					borderTopLeftRadius: '20px',
					boxShadow: 'none',
				}}
				sx={{
					'&::-webkit-scrollbar-thumb': {
						background: '#C4C4C4 !important',
						borderRadius: '3px',
					},

					'&::-webkit-scrollbar-track': {
						background: '#FFF !important',
					},
				}}
			>
				<Table stickyHeader aria-label="customized table">
					<TableHead>
						<TableRow sx={{ height: '60px' }}>
							<TableCell sx={{ display: 'none', border: 'none' }} />
							<FilterableColumn
								colSpan="2"
								field={field}
								fieldName="Status"
								fieldValue="Status"
								handleInputText={handleInputText}
								handleRequestSort={handleRequestSort}
								legendWidth="45px"
								order={order}
								setField={setField}
								showSearchIcon={false}
								textAlign="right"
								type="text"
								value={searchText}
							/>
							<FilterableColumn
								field={field}
								fieldName="User Name"
								fieldValue="UserName"
								handleInputText={handleInputText}
								handleRequestSort={handleRequestSort}
								legendWidth="65px"
								order={order}
								setField={setField}
								type="text"
								value={searchText}
							/>
							<FilterableColumn
								field={field}
								fieldName="Email"
								fieldValue="Email"
								handleInputText={handleInputText}
								handleRequestSort={handleRequestSort}
								legendWidth="40px"
								order={order}
								setField={setField}
								type="text"
								value={searchText}
							/>
							<FilterableColumn
								field={field}
								fieldName="Assigned Roles"
								fieldValue="AssignedRoles"
								handleInputText={handleInputText}
								handleRequestSort={handleRequestSort}
								order={order}
								setField={setField}
								type="text"
								value={searchText}
							/>
						</TableRow>
					</TableHead>

					<TableBody>
						{users && users.length > 0 ? (
							users.map((user, i) => (
								<StyledTableRow key={i}>
									<TableCell align="center">
										<DivImage style={{ background: `url(${Avatar})` }} />
									</TableCell>
									<TableCell
										align="center"
										sx={{ color: '#4D79EA', fontStyle: 'italic', minWidth: 58 }}
									>
										{user.Status}
									</TableCell>
									<TableCell
										align="center"
										sx={{
											maxWidth: '156px',
											overflowWrap: 'break-word',
											color: 'rgba(0, 0, 0, 0.9)',
										}}
									>
										{user.UserName}
									</TableCell>
									<TableCell
										align="center"
										sx={{
											maxWidth: '156px',
											overflowWrap: 'break-word',
											color: 'rgba(0, 0, 0, 0.9)',
										}}
									>
										{user.Email}
									</TableCell>
									<TableCell align="center">
										<label
											style={{
												padding: '6px 10px',
												backgroundColor: '#F3F3F3',
												borderRadius: '5px',
												color: 'rgba(0, 0, 0, 0.8)',
											}}
										>
											{user.AssignedRoles}
										</label>
									</TableCell>
								</StyledTableRow>
							))
						) : (
							<StyledTableRow>
								<TableCell colSpan={5}>
									<Box
										sx={{
											height: '100%',
											width: '100%',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Typography
											sx={{
												textAlign: 'center',
												fontSie: '16px',
												margin: '8px 0',
												color: 'black',
											}}
										>
											No Result
										</Typography>
									</Box>
								</TableCell>
							</StyledTableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</TabPanel>
	);
};

export default Users;
