import React from 'react';

import { makeStyles } from 'tss-react/mui';

const KanbanColumnWrapper = ({ children }) => {
	const useStyles = makeStyles()({
		root: {
			paddingRight: '50px',
			paddingLeft: '20px',
			display: 'flex',
			gap: '13px',
			marginTop: '16px',
			overflowX: 'auto',
			height: '100%',
			overflowY: 'hidden',
		},
	});

	const { classes } = useStyles();

	return <div className={`${classes.root} invisible-scrollbar`}>{children}</div>;
};

export default KanbanColumnWrapper;
