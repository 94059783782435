// @flow

// core
import React, { type Node } from 'react';
// components
import { type ISkeletonProps, LoaderSkeleton } from './LoaderSkeleton';
import { Box } from '@mui/material';

export const OutstandingBalance = ({ children, isLoading }: ISkeletonProps): Node =>
	isLoading ? (
		<Box data-testid="outstanding-balance-loader" sx={{ width: '100%' }}>
			<LoaderSkeleton sx={{ width: '100%', height: '91px', borderRadius: '10px' }} />
		</Box>
	) : (
		children
	);
