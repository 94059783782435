import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Icon } from '@mui/material';

const MobileListFloatingIcon = () => (
	<Icon
		component={CheckCircleIcon}
		sx={{
			color: '#42A5F5',
			fontSize: '16px',
			position: 'absolute',
			top: '4px',
			right: '4px',
		}}
	/>
);

export default MobileListFloatingIcon;
