import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Typography, Box, List, ListItemText, Tooltip, ListItem } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DraggableListItem from '@worklist-2/ui/src/components/DraggableListItem/DraggableListItem';
import { createTheme } from '@mui/material/styles';

function VisualColumnBuilder({ fieldList, removeField }) {
	const theme = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1200,
				xl: 1536,
			},
		},
	});

	return (
		<Box
			data-testid="multiValue_drop"
			sx={{
				width: 450,
				minHeight: 270,
				background: 'rgb(47 47 47)',
				boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.1)',
				borderRadius: '5px',
				border: '2px dashed rgba(255, 255, 255, 0.2)',
				marginLeft: 'auto',
				padding: '15px 0px 5px 0px',
				marginTop: '-18px',
				zIndex: 10,
				[theme.breakpoints.between('lg', 'xl')]: {
					marginTop: '-38px',
					transform: 'scale(0.9)',
				},
			}}
		>
			{fieldList.length > 0 ? (
				<>
					<Box>
						<Typography
							fontSize={16}
							sx={{
								fontWeight: 500,
								textAlign: 'center',
								height: 35,
							}}
						>
							DRAG AND DROP
						</Typography>
					</Box>
					<Droppable droppableId="multiValue_drop">
						{(provided, snapshot) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
								style={{
									height: 220,
									overflowY: 'auto',
									background:
										snapshot.isDraggingOver && fieldList.length > 0 ? '#323232' : 'rgb(47 47 47)',
								}}
							>
								<List
									sx={{
										borderTop: '1px solid rgba(255, 255, 255, 0.2)',
										paddingTop: 0,
									}}
								>
									{fieldList.map((field, index) => (
										<Box
											sx={{
												borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
												'& .MuiListItem-root': {
													height: '50px',
												},
											}}
										>
											<DraggableListItem
												key={field.id}
												dragId={field.id}
												iconFontSize={25}
												index={index}
												item={field}
											>
												<ListItem height={50}>
													<Typography
														sx={{
															width: '30px',
															textAlign: 'center',
														}}
													>
														{`${index + 1}. `}
													</Typography>
													<ListItemText
														sx={{
															width: '100%',
														}}
													>
														<Tooltip
															title={field.title?.replace(`${field.fieldName} `, '')}
														>
															<Typography
																sx={{
																	whiteSpace: 'nowrap',
																	overflow: 'hidden',
																	textOverflow: 'ellipsis',
																	textAlign: 'left',
																}}
															>
																{field.title?.replace(`${field.fieldName} `, '')}
															</Typography>
														</Tooltip>
													</ListItemText>
													<CancelIcon fontSize="small" onClick={() => removeField(index)} />
												</ListItem>
											</DraggableListItem>
										</Box>
									))}
								</List>
							</div>
						)}
					</Droppable>
				</>
			) : (
				<Droppable droppableId="multiValue_drop">
					{(provided, snapshot) => (
						<Box
							{...provided.droppableProps}
							ref={provided.innerRef}
							sx={{
								color: 'rgba(255, 255, 255, 0.6)',
								border: '1px dashed rgba(255, 255, 255, 0.2)',
								borderRadius: '5px',
								height: 40,
								width: 200,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								background: snapshot.isDraggingOver ? '#323232' : '#393939',
								margin: 'auto',
								position: 'absolute',
								left: '50%',
								top: '45%',
								transform: 'translate(-50%, -50%)',
							}}
						>
							<Typography
								fontSize={14}
								sx={{
									textTransform: 'uppercase',
								}}
							>
								Value
							</Typography>
						</Box>
					)}
				</Droppable>
			)}
		</Box>
	);
}

export default VisualColumnBuilder;
