import React from 'react';

import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';

const HeaderCustomTypography = styled(props => <Typography variant="h3" {...props} />)(({ theme }) => ({
	color: theme.palette.text.header,
	fontSize: '16px',
	fontWeight: 500,
	lineHeight: '19px',
	letterSpacing: '0.15em',
	textAlign: 'left',
	textTransform: 'Uppercase',
}));

const DetailSectionHeader = ({ header, icon, iconSx, count, sx }) => (
	<Box
		sx={{
			alignItems: 'center',
			display: 'flex',
			height: '28px',
			...sx,
		}}
	>
		{icon ? (
			<Icon
				component={icon}
				sx={{
					marginRight: '5px',
					...iconSx,
				}}
			/>
		) : null}

		<HeaderCustomTypography>{header}</HeaderCustomTypography>

		{count >= 0 && (
			<Chip
				label={count}
				sx={{
					borderRadius: '5px',
					fontSize: '16px',
					fontWeight: '500',
					marginLeft: '10px',
					letterSpacing: '1.25px',
					width: '30px',
					height: '28px',
					color: '#121212DE',
					verticalAlign: 'baseline',
					'& .MuiChip-label': {
						overflow: 'unset',
					},
				}}
			/>
		)}
	</Box>
);

DetailSectionHeader.defaultProps = {
	count: -1,
};

export default DetailSectionHeader;
