export const DocumentReferenceJson = {
	resourceType: 'DocumentReference',
	masterIdentifier: {
		value: '',
	},
	status: 'current',
	type: {
		coding: [
			{
				code: '56444-3',
				display: 'Healthcare communication Document',
			},
		],
	},
	category: [
		{
			coding: [
				{
					code: '56444-3',
					display: 'Healthcare communication Document',
				},
			],
		},
	],
	subject: {
		id: '',
		reference: 'Patient/',
	},
	content: [
		{
			attachment: {
				contentType: 'application/pdf',
				data: '',
			},
		},
	],
	context: {
		related: [
			{
				id: '',
				reference: 'ImagingStudy/',
			},
			{
				id: '',
				reference: 'Appointment/',
			},
		],
	},
};

export const removeErrorAttribute = formDataObj => {
	const tempFormObj = { ...formDataObj };
	const allQuestions = tempFormObj?.formTemplate?.content.flat(1);

	allQuestions.forEach(question => {
		delete question?.error;
	});

	return tempFormObj;
};

export const getCompletedFormData = result => ({
	id: result.data.id,
	patient: result.data?.patient,
	completed: result.data?.completed,
	organizationId: result.data.organizationId,
	status: result.data.status.charAt(0).toUpperCase() + result.data.status.slice(1),
	title: result.data.name,
	type: result.data.type,
	description: result.data.description,
	questionData: result.data.formTemplate?.content,
	resource: result.data,
});

export const validateForm = formDataObj => {
	const tempFormObj = { ...formDataObj };
	const questionData = tempFormObj?.formTemplate?.content;

	questionData.forEach((page, pageIndex) => {
		page.forEach((question, questionIndex) => {
			if (question?.required) {
				const updateAnswer = questionData[pageIndex][questionIndex];
				// check if answer present for required fields
				if (question?.answer) {
					if (
						question?.type === 'multipleChoiceGrid' ||
						question?.type === 'checkboxGrid' ||
						question?.type === 'shortAnswer'
					) {
						question?.options?.forEach((eachQ, index) => {
							if (!question?.answer[question?.answer?.length - 1]?.value[index]?.length > 0) {
								updateAnswer.error = true;
							} else {
								delete updateAnswer?.error;
							}
						});
					} else if (question?.type === 'checkbox') {
						if (!question?.answer[question?.answer?.length - 1]?.value?.length > 0) {
							updateAnswer.error = true;
						} else {
							delete updateAnswer?.error;
						}
					} else if (!question?.answer[question?.answer?.length - 1]?.value) {
						updateAnswer.error = true;
					} else {
						delete updateAnswer?.error;
					}
				} else {
					updateAnswer.error = true;
				}
			}
		});
	});

	return tempFormObj;
};

export const answerJson = ({ patient, value, ...addnValue }) => ({
	submitter: patient?.display,
	submitterType: 'patient',
	submitterId: patient?.id,
	submitterDate: new Date().toISOString(),
	value,
	...addnValue,
});

export const answerList = ({ answerObj, patient, value, index, ...addnValue }) => {
	if (answerObj?.answer) {
		const updatedList = answerObj.answer.map(answer => {
			if (answer.submitterId === patient?.id) {
				if (['multipleChoiceGrid', 'shortAnswer'].includes(answerObj.type)) {
					const valueList = answer.value;
					valueList[index] = value;
					return {
						...answer,
						value: valueList,
						submitterDate: new Date().toISOString(),
						...addnValue,
					};
				}
				return {
					...answer,
					value,
					submitterDate: new Date().toISOString(),
					...addnValue,
				};
			}
			return answer;
		});

		const foundItem = updatedList.find(answer => answer.submitterId === patient?.id);

		if (foundItem) {
			return updatedList;
		}

		if (['multipleChoiceGrid', 'shortAnswer'].includes(answerObj.type)) {
			const valueObj = Array(answerObj.options?.length).fill(null);

			valueObj[index] = value;

			return [...updatedList, answerJson({ patient, value: valueObj, ...addnValue })];
		}

		return [...updatedList, answerJson({ patient, value, ...addnValue })];
	}

	return [answerJson({ patient, value, ...addnValue })];
};

export const handleFormData = async ({
	key,
	index,
	value,
	lastSyncedForm,
	autoSave,
	formData,
	setFormData,
	validateFormData,
	autoSaveDebounce,
	data,
}) => {
	try {
		if (!lastSyncedForm && autoSave) {
			autoSaveDebounce(formData);
		}

		const tempFormObj = { ...formData };
		const questionData = tempFormObj?.formTemplate?.content;
		let pageIndex = -1;
		let questionIndex = -1;

		for (let row_idx = 0; row_idx < questionData?.length; row_idx++) {
			const row = questionData[row_idx];
			const col_idx = row.findIndex(obj => obj.unique_id === key);

			if (col_idx !== -1) {
				pageIndex = row_idx;
				questionIndex = col_idx;
				break;
			}
		}

		const updateAnswer = questionData[pageIndex][questionIndex];
		if (['multipleChoice', 'checkbox'].includes(updateAnswer.type)) {
			updateAnswer.answer = answerList({
				answerObj: updateAnswer,
				patient: data.patient,
				...value,
			});
		}

		if (['signature', 'dropdown', 'date', 'paragraph', 'checkboxGrid'].includes(updateAnswer.type)) {
			updateAnswer.answer = answerList({
				answerObj: updateAnswer,
				patient: data.patient,
				value,
			});
		}

		if (['multipleChoiceGrid', 'shortAnswer'].includes(updateAnswer.type)) {
			const valueObj = Array(updateAnswer.options?.length).fill(null);
			valueObj[index] = value;
			updateAnswer.answer = answerList({
				answerObj: updateAnswer,
				patient: data.patient,
				value: updateAnswer?.answer ? value : valueObj,
				index,
			});
		}

		let validatedFormDataObj = tempFormObj;

		if (validateFormData) {
			validatedFormDataObj = await validateForm(tempFormObj);
		}

		setFormData(validatedFormDataObj);
	} catch (err) {
		console.error(err);
	}
};
