import React from 'react';

import styles from './VideoAttachment.module.scss';

const VideoAttachment = ({ src, setDisplayMode }) => (
	<div className={styles.videoBlock} onMouseOver={() => setDisplayMode({ type: 'hover' })}>
		<video controls src={src} />
	</div>
);

export default VideoAttachment;
