import React, { useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import HailIcon from '@mui/icons-material/Hail';
import { useAuth } from '@worklist-2/core/src';
import { useLiveChatContext } from './LiveChatContext';
import ProfileModal from './ProfileModal';
import UserAvatar from './UserAvatar';

function SupportChatHeader({ title }) {
	const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

	const { setIsChatOpen, selectedStatus, requestedConvCnt, setIsRequestQueue, chatHeader } = useLiveChatContext();
	const { loggedInUser } = useAuth();

	return (
		<div>
			<div
				style={{
					position: 'relative',
					padding: '7px 15px 39px 18px',
					backgroundColor: '#F9FAFC',
					boxSizing: 'border-box',
					overflow: 'hidden',
				}}
			>
				<QueueLabel count={requestedConvCnt} onClick={() => setIsRequestQueue(true)} />
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<UserAvatar
						size={30}
						status={selectedStatus}
						sx={{ cursor: 'pointer' }}
						userName={loggedInUser?.fullName}
						onClick={() => setIsProfileModalOpen(true)}
					/>
					<div>
						<IconButton type="button">
							<OpenInNewOutlinedIcon
								sx={{
									color: 'rgba(0, 0, 0, 0.6)',
								}}
							/>
						</IconButton>
						<IconButton
							data-testid="onCloseBtn"
							sx={{ marginLeft: '2px' }}
							type="button"
							onClick={() => setIsChatOpen(false)}
						>
							<LastPageOutlinedIcon
								sx={{
									color: 'rgba(0, 0, 0, 0.6)',
								}}
							/>
						</IconButton>
					</div>
				</div>
				<div
					style={{
						marginTop: '20px',
						marginLeft: '18px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Typography
						style={{
							padding: '2px 0',
							paddingRight: title ? '15px' : '0',
							fontFamily: 'Inter',
							fontStyle: 'normal',
							fontSize: '24px',
							fontWeight: '500',
							lineHeight: '29px',
							color: 'rgba(0, 0, 0, 0.9)',
							borderRight: title ? '1px solid rgba(0, 0, 0, 0.1)' : 'none',
						}}
					>
						{chatHeader}
					</Typography>
					{title && (
						<Typography
							style={{
								marginLeft: '15px',
								fontFamily: 'Inter',
								fontStyle: 'normal',
								fontSize: '20px',
								fontWeight: '500',
								lineHeight: '24px',
								color: 'rgba(0, 0, 0, 0.6)',
							}}
						>
							{title}
						</Typography>
					)}
				</div>
			</div>
			<ProfileModal isOpen={isProfileModalOpen} onClose={() => setIsProfileModalOpen(false)} />
		</div>
	);
}

function QueueLabel({ count, onClick }) {
	return (
		<div
			style={{
				boxSizing: 'border-box',
				position: 'absolute',
				top: '-90px',
				left: '50%',
				transform: 'translateX(-50%)',
				width: '180px',
				height: '180px',
				paddingBottom: '20px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-end',
				alignItems: 'center',
				borderRadius: '50%',
				backgroundColor: '#4D79EA',
				color: '#FFFFFF',
				cursor: 'pointer',
				opacity: count > 0 ? '1' : '0',
				visibility: count > 0 ? 'visible' : 'hidden',
				transition: 'opacity .2s linear',
			}}
			onClick={onClick}
		>
			<HailIcon />
			<Typography
				sx={{
					margin: '6px 0 4px 0',
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontSize: '28px',
					fontWeight: '700',
					lineHeight: '22px',
				}}
			>
				{count}
			</Typography>
			<Typography
				sx={{
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontSize: '14px',
					lineHeight: '9px',
				}}
			>
				In queue
			</Typography>
		</div>
	);
}

export default SupportChatHeader;
