import React, { createContext, useEffect, useRef } from 'react';
import { createStore as createZustandStore, useStore } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { getZustandStoreBroadcastChannelName } from '../../../../contexts/utils/zustand/getZustandStoreBroadcastChannelName';
import useZustandStoreSelector from '../../../../contexts/utils/zustand/useZustandStoreSelector';
import useZustandStore from '../../../../contexts/utils/zustand/useZustandStore';
import { zustandShare } from '../../../../contexts/utils/zustand/useZustandShare';
import { EVENTS } from '@cornerstonejs/core';
import getDefaultRenderingEngine from '@rs-ui/views/ImageViewerView3D/cornerstone/getDefaultRenderingEngine';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const createStore = () =>
	createZustandStore(
		subscribeWithSelector((set, get) => ({
			activeViewportId: null,
			setActiveViewportId: activeViewportId => set({ activeViewportId }),
			activeViewport: null,
			setActiveViewport: activeViewport => set({ activeViewport }),
			onRenderedImageEvent: null,
			setOnRenderedImageEvent: onRenderedImageEvent =>
				set({ onRenderedImageEvent: { event: onRenderedImageEvent, timeStamp: Date.now() } }),
			activeCine: [],
			addActiveCine: cine => {
				const currentCine = get().activeCine;
				set({ activeCine: [...currentCine, cine] });
			},
			playCine: cine => {
				const currentCine = get().activeCine;
				set({
					activeCine: [
						...currentCine.map(item =>
							item.id === cine.id
								? {
										...item,
										isActive: true,
								  }
								: item
						),
					],
				});
			},
			stopCine: cine => {
				const currentCine = get().activeCine;
				set({
					activeCine: [
						...currentCine.map(item =>
							item.id === cine.id
								? {
										...item,
										isActive: false,
								  }
								: item
						),
					],
				});
			},
			removeActiveCine: cine => {
				const currentCine = get().activeCine;
				set({ activeCine: [...currentCine.filter(item => item.id !== cine.id)] });
			},
			cineLoading: [],
			addLoadingCine: cineLoading => {
				const currentLoadingCine = get().activeCine;
				set({ cineLoading: [...currentLoadingCine, cineLoading] });
			},
			removeLoadingCine: cineLoading => {
				const currentLoadingCine = get().activeCine;
				set({ cineLoading: currentLoadingCine.filter(i => i !== cineLoading) });
			},
		}))
	);

const useStoreSync = (storeRef, wonIvBroadcastMsgCompress) => {
	useEffect(() => {
		const storeName = 'imageViewerLayoutStore';
		const storeBroadcastChannelName = getZustandStoreBroadcastChannelName(storeName);
		const options = {
			initialize: true,
			ref: storeBroadcastChannelName,
			wonIvBroadcastMsgCompress,
		};

		const [, unsubscribe] = zustandShare('activeViewportId', storeRef.current, options);
		const [, unsubscribeActiveViewport] = zustandShare('activeViewport', storeRef.current, options);
		const [, unsubscribeOnRenderedImageEvent] = zustandShare('onRenderedImageEvent', storeRef.current, options);
		const [, unsubscribeActiveCine] = zustandShare('activeCine', storeRef.current, options);
		const [, unsubscribeCineLoading] = zustandShare('cineLoading', storeRef.current, options);

		return () => {
			unsubscribe();
			unsubscribeActiveViewport();
			unsubscribeOnRenderedImageEvent();
			unsubscribeActiveCine();
			unsubscribeCineLoading();
		};
	}, [storeRef, wonIvBroadcastMsgCompress]);
};

const ImageViewerLayoutContext = createContext(null);

const ImageViewerLayoutZustandProvider = ({ children }) => {
	const wonIvBroadcastMsgCompress = useBooleanFlagValue('won-iv-broadcast-msg-compress');
	const storeRef = useRef();
	const cornerstoneElRef = useRef(null);

	if (!storeRef.current) {
		storeRef.current = createStore();
	}

	useStoreSync(storeRef, wonIvBroadcastMsgCompress);

	const { activeViewportId, activeCine, setOnRenderedImageEvent } = useStore(storeRef.current);

	const onImageRendered = () => {
		try {
			setOnRenderedImageEvent({
				type: cornerstoneElRef.current.type,
				sliceIndex: cornerstoneElRef.current.getSliceIndex(),
				id: cornerstoneElRef.current.id,
				orientation: cornerstoneElRef.current.options?.orientation,
				imageIds: cornerstoneElRef.current.getImageIds(),
				currentImageIdIndex: cornerstoneElRef.current.getCurrentImageIdIndex(),
				currentImageId: cornerstoneElRef.current.getCurrentImageId(),
			});
			cornerstoneElRef.current.element.removeEventListener(EVENTS.IMAGE_RENDERED, onImageRendered);
		} catch (error) {
			console.log('ERROR', error);
		}
	};

	const onStackNewImage = () => {
		cornerstoneElRef.current?.element?.addEventListener(EVENTS.IMAGE_RENDERED, onImageRendered);
	};

	useEffect(() => {
		if (activeViewportId && !activeCine.find(item => item.id === activeViewportId)?.isActive) {
			const renderingEngine = getDefaultRenderingEngine();

			if (!renderingEngine) {
				return;
			}

			const viewport = renderingEngine.getViewport(activeViewportId);

			if (viewport) {
				cornerstoneElRef.current = viewport;
				onImageRendered();
				cornerstoneElRef.current?.element?.addEventListener(EVENTS.STACK_NEW_IMAGE, onStackNewImage);
			}
		}

		return () => {
			if (cornerstoneElRef.current) {
				cornerstoneElRef.current = null;
				cornerstoneElRef.current?.element?.removeEventListener(EVENTS.STACK_NEW_IMAGE, onStackNewImage);
				cornerstoneElRef.current?.element?.removeEventListener(EVENTS.IMAGE_RENDERED, onImageRendered);
			}
		};
	}, [activeViewportId, activeCine]);

	return <ImageViewerLayoutContext.Provider value={storeRef.current}>{children}</ImageViewerLayoutContext.Provider>;
};

const useImageViewerLayoutStore = () => useZustandStore(ImageViewerLayoutContext);

const useImageViewerLayoutStoreSelector = selector => useZustandStoreSelector(selector, ImageViewerLayoutContext);

export {
	ImageViewerLayoutContext,
	ImageViewerLayoutZustandProvider,
	useImageViewerLayoutStore,
	useImageViewerLayoutStoreSelector,
};
