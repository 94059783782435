// Core
import React, { useCallback, useEffect } from 'react';

// Components
import { StyledTree } from './Tree.style';
import TreeFolder from './TreeFolder';
import TreePlaceholderInput from './TreePlaceholderInput';
import { useTreeContext } from '@rs-ui/context/TreeContext';
// Libs
import { v4 } from 'uuid';

// Tree view component
const Tree = ({ data, sx, handleSubmit, handleDeleteFolder, handleEditName }) => {
	const { state, dispatch } = useTreeContext();
	// Set data
	useEffect(() => {
		dispatch({ type: 'SET_DATA', payload: data });
	}, [data, dispatch]);

	const makeComponents = useCallback(
		(componentData, parentId) =>
			componentData?.length > 0 &&
			componentData.map(item => {
				if (item.isShow === false) return null;
				// Generate a unique id for each item
				if (!item.id) {
					item.id = v4();
				}
				return item.isNewFolder ? (
					<TreePlaceholderInput
						folderLevel={item.level || 1}
						handleSubmit={newFolderName => {
							handleSubmit(item.id, newFolderName, parentId, item.level || 1);
						}}
						id={item.id}
						parentId={parentId}
						type="folder"
					/>
				) : (
					<TreeFolder
						key={item.id}
						canEdit={item.canEdit}
						folderOpen={item.isOpen || false}
						handleDeleteFolder={handleDeleteFolder}
						handleEditName={handleEditName}
						id={item.id}
						isLastChild={!item.files || item.files.length === 0}
						name={item.name}
						parentId={parentId}
						studies={item.studies}
					>
						{item.files && item.files.map(file => makeComponents([file], item.id))}
					</TreeFolder>
				);
			}),
		[handleSubmit, handleDeleteFolder]
	);

	return (
		<StyledTree
			sx={{
				...sx,
			}}
		>
			{makeComponents(state)}
		</StyledTree>
	);
};

// Tree default props
Tree.defaultProps = {
	data: [],
	sx: {},
	handleSubmit: () => {},
	handleDeleteFolder: () => {},
	handleEditName: () => {},
};
export default Tree;
