/* eslint-disable react/no-unstable-nested-components */
// core
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// libraries
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';

// mui
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import TextField from '@mui/material/TextField';

// compoonents
import { fhirEndpoints, searchScopes, useAuth, useFhirDataLoader, useLoading, useToast } from '@worklist-2/core/src';
import getColumnMapping from '@worklist-2/core/src/fhir/resource/columnMapping/getColumnMapping';
import _ from 'lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import DrawerTabSettings from '../DrawerTabs/DrawerTabSettings';
import GenericDrawer from '../GenericDrawer';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import UserSelector from '../../UserSelector';
import SuperSingleSelect from '../../SuperSingleSelect';
import SaveAsNewDrawer from '../SaveAsNewDrawer/SaveAsNewDrawer';
import CopyWorklistDrawer from '../CopyWorklistDrawer/CopyWorklistDrawer';
import Toast from '../../Toast';
import FormAPIAutocompleteVariant from '../../FormNew/formPartials/FormFieldVariants/variants/FormAPIAutocompleteVariant';
import FormSelectVariant from '../../FormNew/formPartials/FormFieldVariants/variants/FormSelectVariant';
import searchIconAdornment from '../../FormNew/formUtils/formMappingUtils';
import { DrawerTabSettingsV2 } from '../DrawerTabs';
import { Loader } from '@rs-ui/components/Loader/Loader';
import { PAYMENT_ORGANIZATION_TYPES_PARAM } from '@rs-ui/views/utils/paymentOrganizationTypes';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { useWorklist } from '@rs-ui/components/Worklist/Worklist/contexts/WorklistContext';

const validationSchema = yup.object().shape({
	name: yup
		.string()
		.required('Worklist name is required')
		.test('name', 'Please enter a valid worklist name.', val => val?.trim()?.length > 0),
	practitioner: yup.object().required('User name is required').nullable(''),
});

const rolesValidationSchema = yup.object().shape({
	organization: yup.object().required('Organization is required').nullable(''),
	role: yup.object().required('Role is required').nullable(''),
	roleWorklistName: yup.string().required('Worklist name is required').nullable(''),
});

/**
 *
 * @param anchor - String: "right", "left", "up", "down"
 */
// Should include arg to pass in options for button and drawer contents?
// How to override default options
const WorklistDrawer = ({
	currWorklistId,
	isNew,
	onUpdateWorklist,
	onSubmitWorklist,
	onDeleteWorklist,
	onModifyWorklist,
	reloadWorklistData,
	anchor,
	drawerOpen,
	setDrawerOpen,
	enableUserWorklistSettings,
	permissions,
	currentWorklist,
	defaultColumns,
	setIsNewWorklist,
	createRoleworklistFromSelector,
	onCloseCallback,
	...otherProps
}) => {
	const {
		filters,
		sort,
		currWorklistColumns,
		columnArray,
		setColumnArray,
		selectedWorklist,
		setSelectedWorklist,
		selectedRolesWorklist,
		setSelectedRolesWorklist,
		columnSizing,
		selectedWorklistPractitioner,
	} = useWorklist();

	const { authorized, loggedInUser, hasPermissionByOrganization, hasPermissionByOrganizationAsync } = useAuth();
	const { setToastUtility, toastOpen, toastMessage, handleToastClose } = useToast();
	const { t } = useTranslation('workList');
	const { t: worklistColumnsTranslateFn, ready: worklistColumnsReady } = useTranslation('imagingWorkListColumns');
	const { loading, setLoading } = useLoading();
	const fhirDataLoaderWorklist = useFhirDataLoader({
		scope: searchScopes.worklistLayout,
	});
	const rolesOptionsLoader = useFhirDataLoader({
		endpoint: 'Role',
	});
	const proactEnableOrganizationProvFilter = useBooleanFlagValue('proact-enable-organization-prov-filter');
	const crossGetPrivilegeOnDemand = useBooleanFlagValue('cross-get-privilege-on-demand');
	const maven2434WorklistSelector = useBooleanFlagValue('maven-2434-worklist-selector');

	const [userSelectorData, setUserSelectorData] = useState();
	const [rolesColumnArray, setRolesColumnArray] = useState([]);
	const [worklists, setWorklists] = useState([]);
	const [defaultWorklist, setDefaultWorklist] = useState(null);
	const [initialData, setInitialData] = useState({});
	const drawerWidth = 487;
	const [worklistLoading, setWorklistLoading] = useState(false);
	const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);
	const [open, setOpen] = useState(false);
	const [copyDrawerOpen, setCopyDrawerOpen] = useState(false);
	const [rolesMode, setRolesMode] = useState(createRoleworklistFromSelector);
	const [rolesOptions, setRolesOptions] = useState([]);
	const [rolesOptionsLoading, setRolesOptionsLoading] = useState(false);
	const [rolesWorklistOptions, setRolesWorklistOptions] = useState([]);
	const [rolesWorklistOptionsLoading, setRolesWorklistOptionsLoading] = useState(false);
	const [componentColumnList, setComponentColumnList] = useState([]);
	const [organizationOptions, setOrganizationOptions] = useState([]);
	const [isFormChanged, setFormChanged] = useState(false);
	const [isNewFromSettings, setIsNewFromSettings] = useState(createRoleworklistFromSelector);
	const [textWorklistName, setTextWorklistName] = useState('');
	const [textRolesWorklistName, setTextRolesWorklistName] = useState('');
	const [isFirstAccess, setIsFirstAccess] = useState(true); // State to track first access

	const hasFetchedOrganizationOptionsData = useRef(false);

	const newTitle = rolesMode ? t('workListDrawer.newRoleWorklist') : t('workListDrawer.newWorklist');
	const editTitle = rolesMode ? t('workListDrawer.roleWorklistSettings') : t('workListDrawer.worklistSettings');
	const title = isNew ? newTitle : editTitle;
	const requiredTxt = t('workListDrawer.required');

	const submitButtonDisabled =
		(componentColumnList.length !== 0 ? componentColumnList[0]?.name === '' : true) ||
		(isNew && defaultColumns?.length && !isFormChanged);

	// use refs for state management to prevent drawer rerender
	const rolesColumnsRef = useRef({ columns: [] });
	const columnsRef = useRef({ columns: [] });
	/**
	 *
	 * @param {Function} closeDrawer - Callback function to close the worklist drawer.
	 */
	const CancelSaveButtons = ({ closeDrawer }) => (
		<Box
			sx={{
				position: 'fixed',
				bottom: 0,
				flexDirection: 'row',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				width: 455,
				p: 2,
				backgroundColor: '#272727',
				zIndex: 1,
			}}
		>
			<SecondaryButton label={t('cancel')} testId="CancelSettings" onClick={closeDrawer} />
			{isNew ? (
				<PrimaryButton disabled={submitButtonDisabled} label={t('save')} onClick={handleSubmit(onSaveClick)} />
			) : (
				<PrimaryButton
					disabled={submitButtonDisabled}
					label={t('save')}
					options={
						rolesMode
							? _.isEmpty(rolesColumnsRef.current?.columns)
								? []
								: [t('newWorkListDrawer.duplicateWorklist')]
							: _.isEmpty(columnsRef.current?.columns)
							? []
							: [t('newWorkListDrawer.saveAsNewWorklist')]
					}
					placement="top-left"
					onClick={handleSubmit(onSaveClick)}
					onMenuSelected={onMenuSelected}
				/>
			)}
		</Box>
	);

	const form = useForm({
		resolver: yupResolver(rolesMode ? rolesValidationSchema : validationSchema),
		defaultValues: {
			isDefault: false,
			resourceType: 'WorkListLayout',
			role: {
				id: '',
				display: '',
			},
			organization: {
				display: '',
				id: '',
			},
		},
	});

	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
		clearErrors,
		getValues,
		setValue,
		watch,
	} = form;

	const watchRole = watch('role');

	const organizationLoader = useFhirDataLoader({
		scope: searchScopes.organization,
	});

	const loadOrganizations = async (search, roleOrganizationId) => {
		if (authorized) {
			const organizationTypeParam = proactEnableOrganizationProvFilter
				? { organizationType: 'PROV' }
				: { 'organizationType:not': PAYMENT_ORGANIZATION_TYPES_PARAM };

			if (crossGetPrivilegeOnDemand) {
				const result = await organizationLoader.load({
					...(search && { name: search }),
					...(roleOrganizationId && { _id: roleOrganizationId }),
					...organizationTypeParam,
					summary: true,
					isReferring: false,
					sort: 'name',
				});

				const orgsWithPermissions = await Promise.all(
					result.map(async org => ({
						id: org.id,
						display: org.name,
						reference: `Organization/${org.id}`,
						enabled: await hasPermissionByOrganizationAsync(
							org.id,
							'Home',
							'worklist.role worklist',
							'update'
						),
					}))
				);
				return orgsWithPermissions;
			}

			return organizationLoader
				.load({
					...(search && { name: search }),
					...(roleOrganizationId && { _id: roleOrganizationId }),
					...organizationTypeParam,
					summary: true,
					isReferring: false,
					sort: 'name',
				})
				.then(
					result =>
						result?.map(org => ({
							id: org.id,
							display: org.name,
							reference: `Organization/${org.id}`,
							enabled: hasPermissionByOrganization(org.id, 'Home', 'worklist.role worklist', 'update'),
						})) ?? []
				);
		}
		return [];
	};

	const metaSearchOrganizations = () => async query => {
		// When creating a new role worklist is better not to refetch the organizations, that way the user can access every initial option
		const formObject = getValues();
		if (query === formObject.organization?.display) return Promise.resolve(organizationOptions);

		return loadOrganizations(query);
	};

	const fetchWorklistData = useCallback(
		async (page, user) => {
			try {
				if (authorized && !loading && fhirDataLoaderWorklist) {
					setLoading(true);
					return fhirDataLoaderWorklist
						.load({
							page,
							user: user || userSelectorData,
						})
						.then(result => {
							if (result && result.length > 0) {
								const fetchedWorklists = _.compact(
									_.flatten(
										_.reduce(
											result,
											(a, b) =>
												_.concat(a, [
													{
														id: b.id,
														name: b.name,
														userFullName: b.userFullName,
														columns: b.rawData.columns,
														label: b.name,
														isCurrent: b.isCurrent,
														isDefault: b.isDefault,
														practitioner: b.practitioner,
														resourceType: b.resourceType,
														role: b.role,
														active: b.active,
													},
												]),
											[]
										)
									)
								).filter(w => !w.role);
								setWorklists(fetchedWorklists);
								setWorklistLoading(false);
								return fetchedWorklists;
							}
							if (result?.length <= 0) {
								setWorklists([]);
								setSelectedWorklist(null);
								setWorklistLoading(false);
								setColumnArray([]);
							}
						})
						.catch(console.error);
				}
			} finally {
				setLoading(false);
			}
		},
		[fhirDataLoaderWorklist, setWorklists, authorized, loading, currWorklistId]
	);

	const menuItemsRaw = worklists.map(worklist => ({
		id: worklist.id,
		value: worklist.id,
		name: worklist.name.toUpperCase(),
		isDefault: worklist.isDefault,
		edit: false,
	}));

	const menuItems = useMemo(() => menuItemsRaw, [menuItemsRaw]);
	const columnMapping = useMemo(() => getColumnMapping(fhirEndpoints.imagingStudyWorklist), []);

	const columnList = useMemo(
		() =>
			Object.keys(columnMapping).map(column => ({
				columnId: column,
				name: columnMapping[column].label,
				filterType: columnMapping[column].filterType,
				customFilterOptions: columnMapping[column].customFilterOptions,
			})),
		[]
	);

	const onMenuSelected = option => {
		const tabData = rolesMode ? rolesColumnsRef.current : columnsRef.current;

		const formObject = getValues();
		if (_.isEmpty(tabData?.columns)) {
			setToastUtility(true, t('newWorkListDrawer.AddAtleastOneColumn'));
			return;
		}
		formObject.columns = tabData?.columns;
		setInitialData(formObject);
		if (option === t('newWorkListDrawer.saveAsNewWorklist')) {
			setOpen(true);
		} else if (option === t('newWorkListDrawer.duplicateWorklist')) {
			setCopyDrawerOpen(true);
		}
	};

	const setColumns = (columns, isRoleMode) => {
		const columnsArray = _.map(columns, column => {
			const unique_id = uuid();
			const itemid = unique_id.slice(0, 4);
			let filteredLabel = '';
			filteredLabel = _.filter(columnList, columnListValue => column.name === columnListValue.columnId);

			let tempVal;
			let tempDisplayVal;
			try {
				tempVal = column.filter ? JSON.parse(column.filter) : '';
				tempDisplayVal = column.displayValue ? JSON.parse(column.displayValue) : '';
			} catch {
				tempVal = column.filter ?? '';
				tempDisplayVal = column.displayValue ?? '';
			}
			const values = _.isArray(tempVal) ? _.flatten(tempVal).filter(String) : tempVal.toString(); // Convert the value to string if its a number to fix an error on filter panel
			const displayValue = _.isArray(tempDisplayVal)
				? _.flatten(tempDisplayVal).filter(String)
				: tempDisplayVal.toString();
			const obj = {
				id: itemid,
				name: filteredLabel.length > 0 ? filteredLabel[0].columnId : 'undefined',
				...(column.size ? { size: column.size } : null),
				sort: column.sort,
				sortOrder: column.sortOrder,
				filter: values,
				displayValue,
				statusIndicators: column.statusIndicators,
				options: columnMapping[column.name]?.options,
				customFilterOptions: columnMapping[column.name]?.customFilterOptions,
			};
			return obj;
		});
		if (isRoleMode) {
			setRolesColumnArray([...columnsArray]);
		} else {
			setColumnArray([...columnsArray]);
		}
	};

	const loadColumns = (worklistId, isRoleMode = rolesMode) => {
		const worklistObj = _.find(
			isRoleMode ? rolesWorklistOptions : worklists,
			item => worklistId && item?.id === worklistId
		);
		if (worklistObj) {
			setColumns(worklistObj?.columns, isRoleMode);
		}
	};

	/**
	 * Handler for when the Save button is clicked in this form.
	 * @param {object} data
	 * @param {Event}} event
	 */
	const onSaveClick = json => {
		const data = _.cloneDeep(json);
		const targetDrawerTabSettingsData = rolesMode ? rolesColumnsRef.current : columnsRef.current;
		const targetSelectedWorklist = rolesMode ? selectedRolesWorklist : selectedWorklist;

		if (isNew) {
			data.name = rolesMode ? data.roleWorklistName : data.name;
		} else {
			data.id = targetSelectedWorklist.id;
			data.name = targetSelectedWorklist.name;
		}

		if (!data.name || _.isEmpty(targetDrawerTabSettingsData.columns)) {
			setToastUtility(true, t('Worklist name does not exist or column list is empty.'));
			return;
		}

		data.columns = targetDrawerTabSettingsData.columns;
		data.isDefault = targetSelectedWorklist?.isDefault ?? false;

		// If the user has not changed the column size, then use the default column size
		if (!_.isEmpty(columnSizing)) {
			data.columns = data.columns.map(column => {
				const columnWidth = columnSizing[column.name];
				if (columnWidth) {
					column.size = columnWidth;
				}
				return column;
			});
		}

		if (!rolesMode && !data.practitioner) {
			data.practitioner = loggedInUser;
		}

		if (!rolesMode) {
			delete data.role;
		} else {
			delete data.practitioner;
		}

		delete data.rolesColumns;
		delete data.roleWorklistName;

		if (rolesMode) {
			data.role = {
				id: data.role?.id,
				reference: `${data.role?.resourceType}/${data.role?.id}`,
				display: data.role?.display,
			};
			data.extension = [
				{
					url: 'flagSave',
					valueString: '2',
				},
			];
		}

		if (isNew) {
			setToastUtility(true, `${t('Creating worklist')} ${data.name} ....`);
			onSubmitWorklist(data).then(res => {
				setToastUtility(
					true,
					res
						? `${t('Worklist')} ${data.name} ${t('is created.')}`
						: `${t('Worklist')} ${data.name} ${t('could not be created. Please try after some time.')}`
				);
			});
		} else {
			setToastUtility(true, `${t('Saving Worklist')} ${data.name} ....`);
			onUpdateWorklist(targetSelectedWorklist.id || targetSelectedWorklist.value, data).then(res => {
				setToastUtility(
					true,
					res
						? `${t('Worklist')} ${data.name} ${t('is saved.')}`
						: `${t('Worklist')} ${data.name} ${t('could not be saved.')}`
				);
			});
		}

		reset();
	};

	const handleOnEditWorklistSubmit = useCallback(
		(id, name) => {
			worklists.forEach(worklist => {
				if (worklist.id === id) {
					worklist.name = name;
					worklist.resourceType = 'WorkListLayout';
					worklist.role = {
						id: '',
						display: '',
					};
					worklist.organization = {
						display: '',
						id: '',
					};
					setSelectedWorklist(worklist);
					return onModifyWorklist(id, worklist);
				}
			});
			loadColumns(id);
		},
		[worklists, onModifyWorklist, loadColumns, rolesMode]
	);

	const handleRolesOnEditWorklistSubmit = useCallback(
		(id, name) => {
			let targetInd;
			let targetWl = rolesWorklistOptions.find((worklist, ind) => {
				targetInd = ind;
				return worklist.id === id;
			});
			targetWl = _.cloneDeep(targetWl);
			targetWl.name = name.toUpperCase();
			targetWl.extension = [
				{
					url: 'flagSave',
					valueString: '2',
				},
			];

			if (selectedRolesWorklist?.id === id) {
				setSelectedRolesWorklist(targetWl);
			}

			setRolesWorklistOptions(prev => {
				prev[targetInd] = targetWl;
				return [...prev];
			});

			onModifyWorklist(id, targetWl);
		},
		[rolesWorklistOptions, setRolesWorklistOptions, selectedRolesWorklist, onModifyWorklist]
	);

	const handleOnDefaultSubmit = useCallback(
		id => {
			setDefaultWorklist(id);
			worklists.forEach(worklist => {
				if (worklist.id === id) {
					worklist.resourceType = 'WorkListLayout';
					worklist.isDefault = 'true';
					worklist.role = {
						id: '',
						display: '',
					};
					worklist.organization = {
						display: '',
						id: '',
					};
					setSelectedWorklist(worklist);

					return onModifyWorklist(id, worklist);
				}
			});

			loadColumns(id);
		},
		[worklists, onModifyWorklist, loadColumns, rolesMode]
	);

	const handleOnDeleteSubmit = useCallback(
		id => {
			onDeleteWorklist(id);
		},
		[worklists, onDeleteWorklist]
	);

	const handleRolesOnDeleteSubmit = useCallback(
		id => {
			if (id === selectedRolesWorklist?.id) {
				setSelectedRolesWorklist(null);
				setRolesColumnArray([]);
			}
			setRolesWorklistOptions(prev => prev.filter(p => p?.id !== id));
			onDeleteWorklist(id);
		},
		[
			rolesWorklistOptions,
			selectedRolesWorklist,
			setSelectedRolesWorklist,
			setRolesWorklistOptions,
			onDeleteWorklist,
		]
	);

	/**
	 * Handler for when the Save As New form submits data. Close the current form and call parent handler to save data.
	 * @param {Event} event
	 */
	const handleOnDrawerSubmit = data => {
		setToastUtility(true, `${t('Creating worklist')} ${data.name} ....`);
		if (!data.practitioner) {
			data.practitioner = userSelectorData;
		}
		onSubmitWorklist(data).then(res => {
			if (res) {
				setToastUtility(true, `${t('Worklist')} ${data.name} ${t('is created.')}`);
				setOpen(false);
			} else {
				setOpen(open);
				setToastUtility(true, t('Something went wrong while creating worklist. Please try after some time.'));
			}
		});
		reset();
	};

	const handleWorklistSelected = useCallback(
		selectedItem => {
			const worklist = {
				name: selectedItem.name,
				label: selectedItem.name,
				id: selectedItem.value,
				isDefault: selectedItem.isDefault,
				columns: _.find(worklists, item => item?.id === selectedItem.value)?.columns,
			};
			loadColumns(selectedItem.value);
			columnsRef.current = { columns: worklist.columns };
			setSelectedWorklist(worklist);
			if (!selectedItem) {
				setColumnArray([]);
			}
			setValue('name', worklist?.name);
			return selectedItem;
		},
		[worklists, loadColumns, rolesMode]
	);

	const handleRolesWorklistSelected = useCallback(
		selectedItem => {
			const worklist = {
				name: selectedItem.name,
				label: selectedItem.name,
				id: selectedItem.value,
				isDefault: selectedItem.isDefault,
				columns: _.find(rolesWorklistOptions, item => item?.id === selectedItem.value)?.columns,
			};
			loadColumns(selectedItem.value);
			setSelectedRolesWorklist(worklist);
			rolesColumnsRef.current = { columns: worklist.columns };
			if (!selectedItem) {
				setRolesColumnArray([]);
			}
			setValue('roleWorklistName', worklist?.name);
			return selectedItem;
		},
		[rolesWorklistOptions, loadColumns, rolesMode, setSelectedRolesWorklist, setRolesColumnArray]
	);

	const handleWorklistOnAdd = textFieldValue => {
		setIsLoadingPermissions(true);
		setIsNewWorklist(true);
		setIsNewFromSettings(true);
		onChangeTextWorklistName(textFieldValue);
		onChangeTextRoleWorklistName(textFieldValue);
	};

	const onChangeTextWorklistName = textFieldValue => {
		setTextWorklistName(textFieldValue);
		setValue('name', textFieldValue);
	};

	const onChangeTextRoleWorklistName = textFieldValue => {
		setTextRolesWorklistName(textFieldValue);
		setValue('roleWorklistName', textFieldValue);
	};

	const userSelectorChanged = useCallback(
		async userData => {
			if (authorized) {
				setWorklistLoading(true);
				setUserSelectorData(userData);
				if (userData?.id) {
					const fetchedWorklists = await fetchWorklistData(1, userData.id);
					if (fetchedWorklists?.length > 0) {
						setupUserWorklists(fetchedWorklists, fetchedWorklists[0]);
					}
				} else {
					setWorklistLoading(false);
				}
			}
			return userData;
		},
		[menuItems, authorized]
	);

	const loadRolesWorklistsByRoleId = roleId => {
		setRolesWorklistOptions([]);
		if (roleId) {
			setRolesWorklistOptionsLoading(true);
			return fhirDataLoaderWorklist
				.load(
					{
						internalRoleId: roleId,
					},
					true
				)
				.then(response => {
					if (Array.isArray(response?.entry)) {
						if (response.entry.length > 0) {
							const fetchedWorklists = _.compact(
								_.flatten(
									_.reduce(
										response?.entry?.map(ent => ent?.resource),
										(a, b) =>
											_.concat(a, [
												{
													id: b.id,
													name: b.name,
													columns: b.columns,
													label: b.name,
													isCurrent: b.isCurrent,
													isDefault: b.isDefault,
													practitioner: b.practitioner,
													role: b.role,
													organization: b.organization,
													resourceType: b.resourceType,
													active: b.active,
													value: b.id,
												},
											]),
										[]
									)
								)
							);
							setRolesWorklistOptions(fetchedWorklists);
							return fetchedWorklists;
						}

						if (response?.entry?.length <= 0) {
							setRolesWorklistOptions([]);
							setSelectedRolesWorklist(null);
							setRolesColumnArray([]);
							return [];
						}
					}
				})
				.finally(() => {
					setRolesWorklistOptionsLoading(false);
				});
		}
	};

	const loadOrganizationRolesById = orgId => {
		setValue('role', null);
		setRolesOptions([]);

		if (orgId) {
			setRolesOptionsLoading(true);
			rolesOptionsLoader
				.load(
					{
						organization: orgId,
						active: 'true',
						_sort: 'name',
					},
					true
				)
				.then(response => {
					const res = response?.entry?.map(ent => ent?.resource) || [];
					if (Array.isArray(res) && res.length > 0) {
						const currentWorklistRole = res.find(r => r?.id === currentWorklist?.role?.id);
						if (currentWorklistRole) {
							setValue('role', currentWorklistRole);
							if (rolesWorklistOptions.length === 0) {
								loadRolesWorklistsByRoleId(currentWorklistRole?.id);
							}
						} else {
							setValue('role', res[0]);
							handleRoleChange(res[0]);
						}
					} else {
						setValue('role', null);
					}
					setRolesOptions(res);
					return res;
				})
				.finally(() => {
					setRolesOptionsLoading(false);
				});
		}
	};

	const getDefaultOrganization = defaultOrganizationOptions => defaultOrganizationOptions.find(o => o.enabled);

	const setupInitialOrganization = async defaultOrganizationOptions => {
		if (currentWorklist?.organization?.id) {
			setValue(
				'organization',
				defaultOrganizationOptions.find(org => org?.id === currentWorklist.organization.id)
			);
		} else {
			const defaultOrganization = getDefaultOrganization(defaultOrganizationOptions);
			setValue('organization', defaultOrganization);
			loadOrganizationRolesById(defaultOrganization?.id);
		}
	};

	const loadRoleWorklists = async () => {
		await loadOrganizationRolesById(currentWorklist?.organization?.id);
		return loadRolesWorklistsByRoleId(currentWorklist?.role?.id);
	};

	const setupUserWorklists = async (fetchedWorklists, targetWorklist) => {
		const auxSelectedWorklist = fetchedWorklists?.find(w => w.id === targetWorklist.id);
		setSelectedWorklist(auxSelectedWorklist);
		setColumns(auxSelectedWorklist?.columns, false);
		columnsRef.current = { columns: auxSelectedWorklist?.columns };
		setValue('name', auxSelectedWorklist?.name);
	};

	const setupRoleWorklists = async (fetchedRoleWorklists, targetWorklist) => {
		const auxSelectedRoleWorklist = fetchedRoleWorklists?.find(w => w.id === targetWorklist.id);
		setSelectedRolesWorklist(auxSelectedRoleWorklist);
		setColumns(auxSelectedRoleWorklist?.columns, true);
		rolesColumnsRef.current = { columns: auxSelectedRoleWorklist?.columns };
		setValue('roleWorklistName', auxSelectedRoleWorklist?.name);
	};

	const initialWorklistSetup = async () => {
		if (authorized && loggedInUser && currentWorklist && !isNew) {
			setUserSelectorData(selectedWorklistPractitioner || loggedInUser);
			const fetchedWorklists = await fetchWorklistData(1, currentWorklist.practitioner?.id || loggedInUser.id);
			if (currentWorklist?.role) {
				setRolesMode(true);
				const fetchedRoleWorklists = await loadRoleWorklists();
				setupRoleWorklists(fetchedRoleWorklists, currentWorklist);
				setSelectedWorklist(fetchedWorklists[0]);
				setValue('name', fetchedWorklists[0]?.name);
			} else {
				setRolesMode(false);
				setupUserWorklists(fetchedWorklists, currentWorklist);
			}
		}
	};

	useEffect(() => {
		if (maven2434WorklistSelector) setUserSelectorData(selectedWorklistPractitioner || loggedInUser);
	}, [selectedWorklistPractitioner]);

	useEffect(() => {
		if (createRoleworklistFromSelector) {
			setIsNewFromSettings(true);
			setRolesMode(true);
		}
	}, [createRoleworklistFromSelector]);

	// Setup user data and fetch worklists
	useEffect(() => {
		async function fetchData() {
			initialWorklistSetup();
		}

		// Only fetch data if worklist layout is loaded
		if (currentWorklist) {
			if (isFirstAccess) {
				// If the user has selected a different worklist, then fetch the data
				// This condition to avoid fetching data when the user is just go back to the worklist drawer
				if (selectedRolesWorklist?.id !== currentWorklist?.id && selectedWorklist?.id !== currentWorklist?.id) {
					fetchData();
				}
			} else {
				fetchData();
			}
			setIsFirstAccess(false);
		}
	}, [authorized, loggedInUser, currentWorklist, isNew]);

	// Sync latest filters with the column array
	useEffect(() => {
		const curentColumns = rolesMode ? rolesColumnArray : columnArray;
		curentColumns?.forEach(col => {
			const latestFilter = filters?.find(f => f.columnIdentity === col.name);
			if (latestFilter) {
				col.filter = latestFilter.values;
				col.displayValue = latestFilter.displayValue;
			} else {
				col.filter = '';
				col.displayValue = '';
			}
		});
		if (rolesMode) {
			rolesColumnsRef.current = { columns: curentColumns };
			setRolesColumnArray(curentColumns);
		} else {
			columnsRef.current = { columns: curentColumns };
			setColumnArray(curentColumns);
		}
	}, [filters]);

	// Sync latest sort with the column array
	useEffect(() => {
		const curentColumns = rolesMode ? rolesColumnArray : columnArray;
		curentColumns?.forEach(col => {
			const latestSort = sort?.find(s => (_.startsWith(s, '-') ? s.substring(1) === col.name : s === col.name));
			if (latestSort) {
				col.sort = _.startsWith(latestSort, '-') ? 'desc' : 'asc';
				col.sortOrder = sort.indexOf(latestSort);
			} else {
				col.sort = '';
				col.sortOrder = '';
			}
		});
		if (rolesMode) {
			rolesColumnsRef.current = { columns: curentColumns };
			setRolesColumnArray(curentColumns);
		} else {
			columnsRef.current = { columns: curentColumns };
			setColumnArray(curentColumns);
		}
	}, [sort]);

	// Sync latest columns order with the column array
	useEffect(() => {
		const curentColumns = rolesMode ? rolesColumnArray : columnArray;
		if (curentColumns && currWorklistColumns) {
			curentColumns.sort((a, b) => currWorklistColumns.indexOf(a.name) - currWorklistColumns.indexOf(b.name));
		}
		if (rolesMode) {
			rolesColumnsRef.current = { columns: curentColumns };
			setRolesColumnArray(curentColumns);
		} else {
			columnsRef.current = { columns: curentColumns };
			setColumnArray(curentColumns);
		}
	}, [currWorklistColumns]);

	// Handles use cases where the user has unselected the current worklist, in that way we can reload the current worklist
	useEffect(() => {
		async function fetchData() {
			const hasRenderedCurrentWorklist =
				selectedRolesWorklist?.id === currentWorklist?.id || selectedWorklist?.id === currentWorklist?.id;
			if (!drawerOpen && !hasRenderedCurrentWorklist) {
				initialWorklistSetup();
			} else if (!createRoleworklistFromSelector) {
				setRolesMode(!!currentWorklist?.role);
			}

			if (!drawerOpen) {
				clearErrors();
			}
		}

		fetchData();
	}, [drawerOpen, createRoleworklistFromSelector]);

	// Load organization options when permissions object is loaded
	// With new logic we need no wait till the permissiosn object is loaded, as we get it on demand
	useEffect(() => {
		async function fetchData() {
			const keys = Object.keys(permissions ?? {});
			const hasPermission = crossGetPrivilegeOnDemand ? true : keys.some(key => key !== 'basicPermission');
			if (hasPermission && !hasFetchedOrganizationOptionsData.current && drawerOpen) {
				const preLoadId = !isNew && currentWorklist?.organization?.id;
				const defaultOrganizationOptions = await loadOrganizations(undefined, preLoadId);
				setOrganizationOptions(defaultOrganizationOptions);
				setupInitialOrganization(defaultOrganizationOptions);
				hasFetchedOrganizationOptionsData.current = true;
			} else if (hasPermission && hasFetchedOrganizationOptionsData.current && drawerOpen) {
				setupInitialOrganization(organizationOptions);
			}

			setIsLoadingPermissions(!hasPermission);
		}

		fetchData();
	}, [permissions, drawerOpen, crossGetPrivilegeOnDemand]);

	const setupNewWorklist = () => {
		const columnsToSet = defaultColumns?.length ? defaultColumns : [];
		hasFetchedOrganizationOptionsData.current = false;

		if (!isNewFromSettings) {
			setRolesMode(false);
			reset();
			setTextWorklistName('');
			setTextRolesWorklistName('');
			setRolesOptions([]);
			setRolesOptionsLoading(false);
			setRolesWorklistOptions([]);
			setRolesWorklistOptionsLoading(false);
			setValue('organization', getDefaultOrganization(organizationOptions));
		}

		clearErrors();
		setValue('columns', []);
		rolesColumnsRef.current = [];
		columnsRef.current = [];
		setValue('rolesColumns', []);
		setColumns(columnsToSet, false);
		setRolesColumnArray([]);
		setSelectedWorklist(null);
		setSelectedRolesWorklist(null);
		setFormChanged(false);
		setIsNewFromSettings(false);
		setIsLoadingPermissions(false);
	};

	useEffect(() => {
		if (isNew) {
			setupNewWorklist();
		}
	}, [drawerOpen, isNew, defaultColumns?.length]);

	const handleOrganizationChange = org => {
		loadOrganizationRolesById(org?.id);
	};

	const handleRoleChange = role => {
		if (!isNew) {
			setValue('roleWorklistName', null);
			setSelectedRolesWorklist(null);
			setRolesColumnArray([]);
			loadRolesWorklistsByRoleId(role?.id);
		}
	};

	// Error persists and due to that both one p tag(for error) and dropdown menu appear.
	// It creates a gap between SuperSingleSearchField and Dropdown menu.
	// By removing errors, the gap disappears.
	/**
	 * @param {Boolean} clearError - If true, then clear error for the given key
	 * @param {Boolean} key - key present in the errors field similar to form data
	 */
	const onClickInput = ({ clearError, key }) => {
		if (clearError && errors && !!errors[key]) {
			clearErrors(key);
		}
	};

	const handleRolesModeSet = isRoleMode => {
		setRolesMode(isRoleMode);

		if (isRoleMode && rolesColumnArray.length === 0) {
			loadColumns(selectedRolesWorklist?.id, isRoleMode);
		}

		if (!isRoleMode && columnArray.length === 0) {
			loadColumns(selectedWorklist?.id, isRoleMode);
		}
	};

	const renderButtons = () => (
		<Box
			sx={{
				position: 'relative', // This is so that absolute position can be used in child
				borderRadius: '21px',
				overflow: 'hidden',
				height: '42px',

				minWidth: '42px',
				width: '42px',
				transition: 'width 0.5s, min-width 0.5s',
				'& .MuiToggleButtonGroup-root': {
					transition: 'left 0.5s',
					position: 'absolute',
					left: rolesMode ? '-37px' : '0px',
				},
				'&:hover': {
					'& .MuiToggleButtonGroup-root': {
						position: 'absolute',
						left: '0px',
					},
					minWidth: '84px',
					width: '84px',
				},
			}}
		>
			<ToggleButtonGroup
				exclusive
				data-testid="rolesModeToggleButton"
				size="small"
				sx={{
					borderRadius: '21px',
					backgroundColor: 'background.dark-1',
					'& .MuiToggleButtonGroup-grouped': {
						width: '34px', // force buttons to all be 34px width
						margin: '4px',
						border: '0px',
						'&.Mui-disabled': {
							border: '0px',
						},
						// All button radius' set at 50%
						'&:not(:first-of-type)': {
							borderRadius: '50%',
						},
						'&:first-of-type': {
							borderRadius: '50%',
						},
					},
				}}
				value={rolesMode}
				onChange={(e, val) => handleRolesModeSet(val)}
			>
				<Tooltip title={t('workListDrawer.worklistSettings2')}>
					<ToggleButton value={false}>
						<AccountCircleIcon fontSize="small" />
					</ToggleButton>
				</Tooltip>
				<Tooltip title={t('workListDrawer.roleWorklistSettings2')}>
					<ToggleButton value>
						<MedicalServicesIcon fontSize="small" />
					</ToggleButton>
				</Tooltip>
			</ToggleButtonGroup>
		</Box>
	);

	return (
		<>
			<Toast message={toastMessage} open={toastOpen} onClose={handleToastClose} />
			<GenericDrawer
				anchor={anchor}
				drawerOpen={drawerOpen}
				setDrawerOpen={setDrawerOpen}
				showHeader={false}
				title={title}
				width={drawerWidth}
				onClose={() => {
					setDrawerOpen(false);
					onCloseCallback();
				}}
				{...otherProps}
			>
				<Stack
					alignItems="center"
					className="headerSection"
					direction="row"
					justifyContent="space-between"
					sx={{
						height: 54,
						minHeight: 54,
						pl: '27px',
						pr: '20px',
						pt: '15px',
					}}
				>
					{Array.isArray(organizationOptions) && organizationOptions.length > 0 && enableUserWorklistSettings
						? renderButtons()
						: null}
					<Typography
						sx={{
							width: '100%',
							fontSize: '16px',
							fontWeight: 500,
							lineHeight: '19px',
							letterSpacing: '0.1em',
							textAlign: 'left',
							paddingLeft: '9px',
						}}
					>
						{title}
					</Typography>
					<IconButton
						data-cy={`${title}_close`}
						onClick={() => {
							setDrawerOpen(false);
							onCloseCallback();
						}}
					>
						<CloseIcon />
					</IconButton>
				</Stack>
				<Loader.NewWorklist isLoading={isLoadingPermissions}>
					<form onSubmit={handleSubmit(onSaveClick)}>
						<Stack
							spacing={4}
							sx={{
								py: 4,
								width: 370,
								mx: 'auto',
							}}
						>
							{rolesMode ? (
								<>
									<FormAPIAutocompleteVariant
										TextFieldProps={{
											label: t('workListDrawer.organization'),
											InputProps: {
												...searchIconAdornment,
											},
										}}
										formHook={form}
										name="organization"
										options={organizationOptions}
										props={{
											isOptionEqualToValue: (option, value) => !value || option?.id === value?.id,
											getOptionLabel: option => option?.display || '',
											getOptionDisabled: option => !option?.enabled,
										}}
										onChange={handleOrganizationChange}
										onSearch={metaSearchOrganizations()}
									/>
									<FormSelectVariant
										customOnChange={e => handleRoleChange(e.target.value)}
										formHook={form}
										menuPopoverAdjustments={{
											adjustHorizontal: rolesOptionsLoading ? 321 : 356,
										}}
										name="role"
										options={rolesOptions}
										props={{
											label: t('workListDrawer.role'),
											SelectProps: {
												renderValue: option => t(option?.name) || '',
											},
											InputProps: {
												...searchIconAdornment,
												endAdornment: rolesOptionsLoading && (
													<CircularProgress color="inherit" size={20} sx={{ mr: '20px' }} />
												),
											},
										}}
									/>

									{isNew ? (
										<Controller
											control={control}
											name="roleWorklistName"
											render={({ field }) => (
												<TextField
													{...field}
													fullWidth
													autoComplete="off"
													error={!!errors.roleWorklistName}
													helperText={errors.roleWorklistName?.message}
													label="Role Worklist Name"
													testid="roleWorklistNameField"
													value={textRolesWorklistName}
													onChange={e => {
														onChangeTextRoleWorklistName(e.target.value);
													}}
												/>
											)}
										/>
									) : (
										<Controller
											control={control}
											defaultValue={selectedRolesWorklist || ''}
											name="roleWorklistName"
											render={({ field }) => (
												<SuperSingleSelect
													{...field}
													disableSettingTextOnWorklistDelete
													disableSettingTextOnWorklistNameChange
													data={rolesWorklistOptions}
													error={!!errors.roleWorklistName}
													formValue={getValues()}
													helperText={errors.roleWorklistName?.message}
													label={t('workListDrawer.worklistName')}
													loading={rolesWorklistOptionsLoading}
													selected={selectedRolesWorklist}
													testId="SettingRoleWLName"
													width={370}
													onAdd={handleWorklistOnAdd}
													onChange={e => {
														handleRolesWorklistSelected(e);
													}}
													onClickInput={onClickInput}
													onDelete={handleRolesOnDeleteSubmit}
													onEdit={handleRolesOnEditWorklistSubmit}
												/>
											)}
										/>
									)}
								</>
							) : (
								<>
									<Controller
										control={control}
										defaultValue={userSelectorData}
										name="practitioner"
										render={({ field: { onChange } }) => (
											<UserSelector
												fullWidth
												defaultValue={userSelectorData}
												error={!!errors.practitioner}
												helperText={errors.practitioner?.message}
												testId="WLSettingsUserSelector"
												onChange={e => {
													userSelectorChanged(e).then(res => {
														onChange(res);
													});
												}}
											/>
										)}
									/>
									{isNew ? (
										<Controller
											required
											control={control}
											name="name"
											render={({ field }) => (
												<TextField
													{...field}
													fullWidth
													autoComplete="off"
													error={!!errors.name}
													helperText={requiredTxt}
													label={t('workListDrawer.worklistName')}
													testid="worklistNameField"
													value={textWorklistName}
													onChange={e => {
														onChangeTextWorklistName(e.target.value);
													}}
												/>
											)}
										/>
									) : (
										<Controller
											control={control}
											defaultValue={selectedWorklist?.name || ''}
											name="name"
											render={({ field }) => (
												<SuperSingleSelect
													{...field}
													data={menuItems}
													defaultWorklist={defaultWorklist}
													error={!!errors.name}
													formValue={getValues()}
													helperText={errors.name?.message}
													label={t('workListDrawer.worklistName')}
													loading={worklistLoading}
													selected={selectedWorklist}
													testId="SettingWLName"
													width={370}
													onAdd={handleWorklistOnAdd}
													onChange={e => {
														handleWorklistSelected(e);
													}}
													onClickInput={onClickInput}
													onDefault={handleOnDefaultSubmit}
													onDelete={handleOnDeleteSubmit}
													onEdit={handleOnEditWorklistSubmit}
												/>
											)}
										/>
									)}
								</>
							)}
						</Stack>
						{rolesMode && (
							<Controller
								control={control}
								name="rolesColumns"
								render={({ field }) => (
									<DrawerTabSettings
										{...field}
										isWorklist
										columnBuilderHeight="calc(100vh - 580px)"
										componentColumnList={componentColumnList}
										disableAddButton={!isNew && !selectedRolesWorklist?.name}
										filterHeight="calc(100vh - 518px)"
										filters={filters}
										setComponentColumnList={setComponentColumnList}
										sortHeight="calc(100vh - 580px)"
										userSavedColumnList={rolesColumnArray}
										onChange={data => {
											rolesColumnsRef.current = { columns: data };
										}}
									/>
								)}
							/>
						)}
						{!rolesMode && (
							<Controller
								control={control}
								name="columns"
								render={({ field }) =>
									worklistColumnsReady ? (
										<DrawerTabSettingsV2
											{...field}
											isWorklist
											componentColumnList={componentColumnList}
											disableAddButton={!isNew && !selectedWorklist?.name}
											filters={filters}
											setComponentColumnList={setComponentColumnList}
											userSavedColumnList={columnArray}
											worklistColumnsTranslateFn={worklistColumnsTranslateFn}
											onChange={data => {
												columnsRef.current = { columns: data };
												setFormChanged(true);
											}}
										/>
									) : (
										<DrawerTabSettings
											{...field}
											isWorklist
											componentColumnList={componentColumnList}
											disableAddButton={!isNew && !selectedWorklist?.name}
											filters={filters}
											setComponentColumnList={setComponentColumnList}
											userSavedColumnList={columnArray}
											onChange={data => {
												columnsRef.current = { columns: data };
												setFormChanged(true);
											}}
										/>
									)
								}
							/>
						)}
					</form>
					<CancelSaveButtons
						closeDrawer={() => {
							columnsRef.current = { columns: columnArray };
							rolesColumnsRef.current = { columns: rolesColumnArray };
							setDrawerOpen(false);
							onCloseCallback();
						}}
					/>
				</Loader.NewWorklist>
			</GenericDrawer>
			<SaveAsNewDrawer
				defaultSelectedUser={loggedInUser}
				drawerData={initialData}
				drawerOpen={open}
				setDrawerOpen={setOpen}
				onDrawerSubmit={handleOnDrawerSubmit}
			/>
			<CopyWorklistDrawer
				columns={rolesMode ? rolesColumnsRef.current?.columns : columnsRef.current?.columns}
				currentOrganization={selectedRolesWorklist?.organization}
				drawerOpen={copyDrawerOpen}
				metaSearchOrganizations={metaSearchOrganizations}
				organizationOptions={organizationOptions}
				setDrawerOpen={setCopyDrawerOpen}
				onCopy={() => {
					loadRolesWorklistsByRoleId(watchRole?.id);
					reloadWorklistData();
				}}
			/>
		</>
	);
};
WorklistDrawer.propTypes = {
	/**
	 * Current worklist id on worklist grid.
	 */
	currWorklistId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	/**
	 * Current worklist column name on worklist grid.
	 */
	currWorklistColumns: PropTypes.array,

	/**
	 * Callback function to update worklist form data.
	 */
	onUpdateWorklist: PropTypes.func,

	/**
	 * Callback function to submit worklist form data.
	 */
	onSubmitWorklist: PropTypes.func,

	/**
	 * Callback function to delete worklist.
	 */
	onDeleteWorklist: PropTypes.func,

	/**
	 * Callback function to update worklist name and to set worklist to default.
	 */
	onModifyWorklist: PropTypes.func,

	/**
	 * The side of the window from where worklist drawer opens.
	 */
	anchor: PropTypes.oneOf(['right', 'left', 'up', 'down']),
};
export default WorklistDrawer;
