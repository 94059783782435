import { toNumber } from '../utils';
import { _getPerpendicularDistance, _getSpacingIssue, reconstructionIssues } from './isDisplaySetReconstructable';
import { CODES } from './codes';

/**
 * Checks if series has spacing issues
 * @param {*} instances
 * @param {*} warnings
 */
export default function areAllImageSpacingEqual(instances) {
	let imageSpacingIssues = {};

	if (!instances?.length) {
		return;
	}
	const firstImagePositionPatient = toNumber(instances[0].ImagePositionPatient);
	if (!firstImagePositionPatient) {
		return;
	}
	const lastIpp = toNumber(instances[instances.length - 1].ImagePositionPatient);
	if (!lastIpp) {
		return;
	}
	const averageSpacingBetweenFrames =
		_getPerpendicularDistance(firstImagePositionPatient, lastIpp) / (instances.length - 1);

	let previousImagePositionPatient = firstImagePositionPatient;

	const issuesFound = [];
	for (let i = 1; i < instances.length; i++) {
		const instance = instances[i];
		const imagePositionPatient = toNumber(instance.ImagePositionPatient);
		if (!imagePositionPatient) return;

		const spacingBetweenFrames = _getPerpendicularDistance(imagePositionPatient, previousImagePositionPatient);

		const spacingIssue = _getSpacingIssue(spacingBetweenFrames, averageSpacingBetweenFrames);

		if (spacingIssue) {
			const { issue } = spacingIssue;

			// avoid multiple warning of the same thing
			if (!issuesFound.includes(issue)) {
				issuesFound.push(issue);
				if (issue === reconstructionIssues.MISSING_FRAMES) {
					imageSpacingIssues = { ...imageSpacingIssues, [CODES.MISSING_FRAMES]: true };
				} else if (issue === reconstructionIssues.IRREGULAR_SPACING) {
					imageSpacingIssues = { ...imageSpacingIssues, [CODES.IRREGULAR_SPACING]: true };
				}
			}
		}
		previousImagePositionPatient = imagePositionPatient;
	}
	return imageSpacingIssues;
}
