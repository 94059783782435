import React from 'react';

const CodeIcon = ({ fill }) => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11 14.1702L8.83 12.0002L11 9.83016L9.59 8.41016L6 12.0002L9.59 15.5902L11 14.1702Z"
			fill={fill || 'white'}
			fillOpacity={fill ? 1 : 0.6}
		/>
		<path
			d="M14.41 15.5902L18 12.0002L14.41 8.41016L13 9.83016L15.17 12.0002L13 14.1702L14.41 15.5902Z"
			fill={fill || 'white'}
			fillOpacity={fill ? 1 : 0.6}
		/>
		<path
			d="M19 3.0002H14.82C14.6152 2.41564 14.2339 1.90916 13.7287 1.55082C13.2235 1.19248 12.6194 1 12 1C11.3806 1 10.7765 1.19248 10.2713 1.55082C9.76612 1.90916 9.38476 2.41564 9.18 3.0002H5C4.86557 2.99814 4.73136 3.01157 4.6 3.0402C4.28194 3.10806 3.98495 3.25167 3.73426 3.45884C3.48356 3.66601 3.28657 3.93062 3.16 4.2302C3.05427 4.47313 2.99981 4.73526 3 5.0002V19.0002C3.00189 19.2682 3.05625 19.5331 3.16 19.7802C3.26299 20.018 3.40873 20.235 3.59 20.4202C3.86584 20.6981 4.21691 20.8893 4.6 20.9702C4.73236 20.9905 4.86609 21.0005 5 21.0002H19C19.5299 20.9986 20.0377 20.7874 20.4125 20.4127C20.7872 20.0379 20.9984 19.5301 21 19.0002V5.0002C20.9984 4.47026 20.7872 3.96247 20.4125 3.58774C20.0377 3.21301 19.5299 3.00179 19 3.0002ZM12 2.7502C12.1483 2.7502 12.2933 2.79419 12.4167 2.8766C12.54 2.95901 12.6361 3.07615 12.6929 3.21319C12.7497 3.35023 12.7645 3.50103 12.7356 3.64652C12.7067 3.79201 12.6352 3.92564 12.5303 4.03053C12.4254 4.13542 12.2918 4.20685 12.1463 4.23579C12.0008 4.26473 11.85 4.24988 11.713 4.19311C11.5759 4.13635 11.4588 4.04022 11.3764 3.91688C11.294 3.79354 11.25 3.64854 11.25 3.5002C11.2513 3.30169 11.3307 3.11169 11.4711 2.97132C11.6115 2.83095 11.8015 2.75151 12 2.7502ZM19 15.0002V19.0002H5V5.0002H19V15.0002Z"
			fill={fill || 'white'}
			fillOpacity={fill ? 1 : 0.6}
		/>
	</svg>
);

export default CodeIcon;
