import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';

import { Box, CircularProgress, Typography } from '@mui/material';

import { useConfig } from '@worklist-2/core/src';
import { CustomSwitch, Header, Knob, Title } from '../../../views/CrmView/styles';

import {
	Assignment,
	HomeOutlined,
	TipsAndUpdatesOutlined,
	StickyNote2Outlined,
	CampaignOutlined,
} from '@mui/icons-material';

import { DocumentListItem, NewArticle, SelectedPopover } from './LandingPage/index';

const PATH = {
	'Recently Accessed': 'documentdraft',
	Drafts: 'documentdraft',
	Deleted: 'documentdraft?isactive=false',
};

const Documentation = () => {
	const __config = useConfig();
	const navigate = useNavigate();
	const [documents, setDocuments] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [sectionName, setSectionName] = useState('Recently Accessed');

	const handleSelectedFilesChange = fileId => {
		if (sectionName !== 'Drafts') {
			return;
		}

		if (selectedFiles.includes(fileId)) {
			setSelectedFiles(prev => prev.filter(id => fileId !== id));
		} else {
			setSelectedFiles([...selectedFiles, fileId]);
		}
	};

	const handleDelete = async () => {
		let newState = documents;

		const deletedFiles = selectedFiles.map(documentId =>
			axios.delete(`${__config.data_sources.breeze}/documentdraft/${documentId}`).then(response => {
				if (response.status === 200) {
					newState = newState.filter(item => item.id !== documentId);
				}
			})
		);
		await Promise.allSettled(deletedFiles);

		setDocuments(newState);
		setSelectedFiles([]);
	};

	const addRecentlyAccessed = id => {
		if (sectionName !== 'Drafts') {
			return;
		}
		const recentlyAccessedDocuments = JSON.parse(localStorage.getItem('recentlyAccessedDocuments'));

		if (!recentlyAccessedDocuments) {
			localStorage.setItem('recentlyAccessedDocuments', JSON.stringify([id]));
			return;
		}

		if (!recentlyAccessedDocuments.includes(id)) {
			localStorage.setItem('recentlyAccessedDocuments', JSON.stringify([...recentlyAccessedDocuments, id]));
		}
	};

	const findRecentlyAccessedDocuments = data => {
		let newState;
		const recentlyAccessedDocuments = JSON.parse(localStorage.getItem('recentlyAccessedDocuments'));

		if (!recentlyAccessedDocuments) {
			return [];
		}

		newState = recentlyAccessedDocuments.reduce((acc, recentlyAccessedDocument) => {
			const document = data.find(item => item.DocumentDraftId === recentlyAccessedDocument);

			return document ? [...acc, document] : acc;
		}, []);

		localStorage.setItem(
			'recentlyAccessedDocuments',
			JSON.stringify(newState.map(document => document.DocumentDraftId))
		);

		return newState;
	};

	const getData = async () => {
		let newState = [];
		setDocuments([]);
		setSelectedFiles([]);
		setIsLoading(true);

		await axios
			.get(`${__config.data_sources.breeze}/${PATH[sectionName]}`)
			.then(response => {
				if (response.status === 200 && response.data) {
					newState = response.data.entry.map(item => item.resource);
				}
			})
			.catch(console.error);

		if (sectionName === 'Recently Accessed') {
			newState = findRecentlyAccessedDocuments(newState);
		}

		setDocuments(newState);
		setIsLoading(false);
	};

	useEffect(() => {
		getData();
	}, [sectionName]);

	return (
		<Box sx={{ height: 'calc(100% - 86px)' }}>
			<Header sx={{ left: '22px', marginBottom: '20px' }}>
				<Title>
					<Assignment sx={{ fontSize: 35, mr: '12px' }} />
					<span>Documentation</span>
				</Title>
				<CustomSwitch>
					<Knob onClick={() => navigate('/')}>
						<HomeOutlined />
					</Knob>
					<label>Documentation</label>
				</CustomSwitch>
			</Header>

			<Box
				sx={{
					boxSizing: 'border-box',
					height: 'calc(100% - 78px)',
					padding: '18px 86px 20px 70px',
					overflowY: 'auto',
				}}
			>
				<Typography
					sx={{
						fontSize: '18px',
						fontWeight: 500,
						color: '#4D79EA',
					}}
				>
					Create New
				</Typography>
				<Box
					sx={{
						marginTop: '14px',
						marginBottom: '44px',
						display: 'flex',
						gap: '20px',
					}}
				>
					<NewArticle title="Knowledge Article" icon={<TipsAndUpdatesOutlined />} />
					<NewArticle title="Technical Article" icon={<StickyNote2Outlined />} withSwitch />
					<NewArticle title="Product News" icon={<CampaignOutlined />} withSwitch />
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Box sx={{ display: 'flex' }}>
						<Typography
							sx={{
								fontSize: '18px',
								fontWeight: 500,
								color: '#4D79EA',
							}}
						>
							{sectionName}
						</Typography>
						<Typography
							sx={{
								marginLeft: '12px',
								padding: '2px 4px',
								fontSize: '18px',
								fontWeight: 500,
								lineHeight: '23px',
								color: 'rgba(0, 0, 0, 0.60)',
								borderRadius: '6px',
								background: 'rgba(196, 196, 196, 0.35)',
							}}
						>
							{documents.length}
						</Typography>
					</Box>
					<Box sx={{ display: 'flex' }}>
						<Typography
							data-testid="drafts-section"
							sx={{
								fontSize: '14px',
								fontWeight: 500,
								color: sectionName === 'Drafts' ? '#4D79EA' : '#C4C4C4',
								cursor: 'pointer',
							}}
							onClick={() => setSectionName('Drafts')}
						>
							Drafts
						</Typography>
						<Typography
							sx={{
								marginLeft: '20px',
								fontSize: '14px',
								fontWeight: 500,
								color: sectionName === 'Deleted' ? '#4D79EA' : '#C4C4C4',
								cursor: 'pointer',
							}}
							onClick={() => setSectionName('Deleted')}
						>
							Deleted
						</Typography>
					</Box>
				</Box>
				{isLoading ? (
					<Box
						sx={{
							height: '100%',
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Typography
							sx={{
								textAlign: 'center',
								fontSie: '16px',
								marginBottom: '8px',
								color: 'black',
							}}
						>
							<CircularProgress
								sx={{
									color: '#03dac5',
									marginRight: '16px',
								}}
								size={22}
								className="progressBar"
							/>
							Loading...
						</Typography>
					</Box>
				) : (
					<Box
						sx={{
							marginTop: '14px',
							display: 'grid',
							gridTemplateColumns: 'repeat(4, 1fr)',
							gridColumnGap: '20px',
							gridRowGap: '40px',
						}}
					>
						{documents.map(item => (
							<DocumentListItem
								key={item.id}
								data={item}
								title={item.DocumentName}
								subData={item.PathName}
								isSelected={selectedFiles.includes(item.id)}
								addRecentlyAccessed={addRecentlyAccessed}
								onSelected={handleSelectedFilesChange}
							/>
						))}
					</Box>
				)}
			</Box>
			<SelectedPopover count={selectedFiles.length} onDelete={handleDelete} />
		</Box>
	);
};

export default Documentation;
