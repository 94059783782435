/* Generated automagically by FhirStarter - DO NOT EDIT */
const practitionerSchema = {
	resourceType: 'Practitioner',
	active: '__boolean__',
	name: ['__HumanName__'],
	gender: '__code__',
	id: '__code__',
	extension: ['__Extension__'],
	meta: ['__Meta__'],
	identifier: ['__Identifier__'],
	telecom: ['__ContactPoint__'],
	address: ['__Address__'],
};
export default practitionerSchema;
