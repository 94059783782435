import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { useConfig, useCRMDataLoader, useAuth } from '@worklist-2/core/src';
import { useChatGlobalContext } from '@worklist-2/ui/src/components/Chat/ChatGlobalContext';
import { Toast } from '@worklist-2/ui/src/components/index.js';
import { Device } from '@twilio/voice-sdk';

const LiveChatContext = React.createContext({});

const useLiveChatContext = () => React.useContext(LiveChatContext);

const LiveChatContextProvider = ({ children }) => {
	const [isChatOpen, setIsChatOpen] = useState(false);
	const [voiceChatTab, setVoiceChatTab] = useState('Dialpad');
	const [isRequestQueue, setIsRequestQueue] = useState(false);
	const [isTextAvailable, setIsTextAvailable] = useState(null);
	const [isVoiceAvailable, setIsVoiceAvailable] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [isProfileOpen, setIsProfileOpen] = useState(false);
	const [requestedConvs, setRequestedConvs] = useState([]);
	const [requestedConvCnt, setRequestedConvCnt] = useState([]);
	const [unreadConvs, setUnreadConvs] = useState([]);
	const [unreadConvCnt, setUnreadConvCnt] = useState(0);
	const [chatLogs, setChatLogs] = useState([]);
	const [selectedConversation, setSelectedConversation] = useState(null);
	const [selectedRequestConvSid, setSelectedRequestConvSid] = useState(null);
	const [toastMsg, setToastMsg] = useState(false);
	const [chatHeader, setChatHeader] = useState('Support Chat');
	const [activeCall, setActiveCall] = useState(null);
	const [isTextChatQueue, setIsTextChatQueue] = useState(true);
	const [incomingCall, setIncomingCall] = useState(null); //Reservation
	const [callerDetail, setCallerDetail] = useState(null);
	const [device, setDevice] = useState(null);
	const [worker, setWorker] = useState(null);

	const { state } = useChatGlobalContext();

	const STATUSES = [
		{ value: 'Available', color: '#3BE775' },
		{ value: 'Busy', color: '#EF5D5D' },
		{ value: 'Away', color: '#F5B538' },
		{ value: 'Be right back', color: '#F5B538' },
		{ value: 'Appear offline', color: '#C4C4C4' },
	];
	const __config = useConfig();
	const { authorized, loggedInUser, requestQueueReceived } = useAuth();
	const dataLoader = useCRMDataLoader({
		endpoint: 'Conversation/request',
	});
	const userUrl = `${__config.data_sources.breeze}/${loggedInUser?.reference}`;
	const userAvailabilityHeaders = {
		headers: {
			Accept: '*/*',
			'Content-Type': 'application/json-patch+json',
		},
	};

	useEffect(() => {
		const convs = _.filter(state, conv => conv.unread);

		setUnreadConvs(convs);
		if (selectedConversation && !_.some(state, conv => conv.sid === selectedConversation.sid)) {
			setSelectedConversation(null);
		}
	}, [state]);

	useEffect(() => {
		const convCnt = Object.keys(state).length == 0 ? 0 : _.filter(state, conv => conv.unread).length;

		setUnreadConvCnt(convCnt);
	}, [unreadConvs]);

	useEffect(() => {
		if (state && selectedRequestConvSid) {
			const conv = state[selectedRequestConvSid];

			setSelectedConversation(conv);
			setSelectedRequestConvSid(null);
		}
	}, [state]);

	useEffect(() => {
		const getUserAvailability = async () => {
			let user;

			await axios.get(userUrl).then(result => {
				if (result.status === 200) {
					user = result.data;
				}
			});

			if (
				!(
					user.hasOwnProperty('AgentStatus') &&
					user.hasOwnProperty('TextChatAvailability') &&
					user.hasOwnProperty('VoiceChatAvailability')
				)
			) {
				await axios
					.patch(
						userUrl,
						[
							{
								op: 'replace',
								path: '/AgentStatus',
								value: 'Available',
							},
							{
								op: 'replace',
								path: '/TextChatAvailability',
								value: true,
							},
							{
								op: 'replace',
								path: '/VoiceChatAvailability',
								value: true,
							},
						],
						userAvailabilityHeaders
					)
					.then(result => {
						if (result.status === 200) {
							user = result.data;
						}
					});
			}

			setSelectedStatus(user.AgentStatus);
			setIsTextAvailable(user.TextChatAvailability);
			setIsVoiceAvailable(user.VoiceChatAvailability);
		};

		const initializeTaskRoute = async () => {
			try {
				const token = await getRefreshToken(`${__config.data_sources.breeze}/Conversation/worker/token`);
				const twWorker = new Twilio.TaskRouter.Worker(
					token,
					true,
					'WA29ee5162604b774394e6dcf93304fe67' /** Online */,
					'WA7e132a31ec08354adf3922f38ff0d134' /** Offline */
				);
				setWorker(twWorker);

				//Register events
				twWorker.on('reservation.created', async reservation => {
					const phoneNumber = reservation?.task?.attributes?.caller ?? reservation?.task?.attributes?.from;
					await getUserByPhoneNumber(phoneNumber);
					setIncomingCall(reservation);
				});

				twWorker.on('reservation.canceled', () => {
					hangupCall();
				});

				twWorker.on('reservation.timeout', () => {
					setIncomingCall(null);
					setToastMsg({
						message: 'You missed an incoming call. Please reactivate to be able to receive call',
						duration: null,
						onUndo: () => {
							(worker ?? twWorker)?.update('ActivitySid', 'WA29ee5162604b774394e6dcf93304fe67');
							setToastMsg(null);
						},
					});
				});

				twWorker.on('task.wrapup', task => {
					hangupCall();
					if (task && task.assignmentStatus == 'wrapping') {
						task.complete();
					}
				});

				twWorker.on('token.expired', async () => {
					const refreshToken = await getRefreshToken(
						`${__config.data_sources.breeze}/Conversation/worker/token`
					);
					(worker ?? twWorker)?.updateToken(refreshToken);
				});
			} catch (error) {
				console.error('Error initializing Twilio Voice:', error);
			}
		};

		const initializeTwilioVoice = async () => {
			try {
				const token = await getRefreshToken(
					`${__config.data_sources.breeze}/Conversation/voice/token?identity=${loggedInUser?.userName}`
				);
				const voiceDevice = new Device(token);
				setDevice(voiceDevice);
				voiceDevice.register();

				voiceDevice.on('incoming', call => {
					//auto accept when have incoming call
					//accepting has been done by TaskRoute's worker on twWorker.on("reservation.created")
					call.accept();
					setActiveCall(call);
				});

				voiceDevice.on('tokenWillExpire', async () => {
					const refreshToken = await getRefreshToken(
						`${__config.data_sources.breeze}/Conversation/voice/token?identity=${loggedInUser?.userName}`
					);
					(device ?? voiceDevice)?.updateToken(refreshToken);
				});
			} catch (error) {
				console.error('Error initializing Twilio Voice:', error);
			}
		};

		getUserAvailability().catch(console.error);
		initializeTaskRoute().catch(console.error);
		initializeTwilioVoice().catch(console.error);

		return () => {
			if (device) {
				device.destroy();
			}
		};
	}, []);

	useEffect(() => {
		async function asynchronousEffect() {
			if (authorized) {
				try {
					await dataLoader
						.load({
							active: true,
							taken: false,
							resolved: false,
						})
						.then(async result => {
							const requestConvs = result;
							let convs = [];
							for (const element of requestConvs) {
								convs = [
									...convs,
									{
										supportRequestId: element.SupportRequestId,
										conversationSid: element.ConversationSid,
										name: element.SenderName,
										email: element.SenderEmail,
										lastUpdated: element.LastUpdated,
										text: element.Message,
										datetime: element.CreatedDateTime,
										unread: true,
										participantCount: 1,
										senderFrom: element.From,
										senderPhone: element.SenderPhone,
										senderOrganization: element.SenderOrganization,
										senderWebsite: element.SenderWebsite,
									},
								];
							}
							setRequestedConvs(convs);
							setRequestedConvCnt(convs.length);
						});
				} catch (e) {
					console.error(e);
				}
			}
		}

		asynchronousEffect();
	}, [requestQueueReceived]);

	useEffect(() => {
		if (selectedConversation) {
			const getChatLogs = async () => {
				const senderEmail = selectedConversation.email || selectedConversation.messages[0]?.state?.author;
				const url = `${__config.data_sources.breeze}/Conversation/request/`;

				await axios
					.get(url, {
						params: {
							'senderemail:exact': senderEmail,
						},
					})
					.then(result => {
						if (result.status == 200) {
							const data = result.data.entry.map(item => ({
								message: item.resource.Message,
								date: item.resource.CreatedDateTime,
								platform: item.resource.From,
							}));

							const sortedChatLogs = _.orderBy(data, 'date', 'desc');

							setChatLogs(sortedChatLogs);
						}
					});
			};

			getChatLogs().catch(console.error);
		}
	}, [selectedConversation]);

	const conversationPick = async item => {
		const url = `${__config.data_sources.breeze}/Conversation/request/${item.supportRequestId}/pick`;
		await axios
			.put(url)
			.catch(() => {
				throw new Error('An error happend on your request, please try again.');
			})
			.then(result => {
				if (!result) {
					//The Conversation no longer exists
					const otherConversations = requestedConvs.filter(
						conv => conv.supportRequestId !== item.supportRequestId
					);
					setRequestedConvs(otherConversations);
					setRequestedConvCnt(otherConversations.length);
					throw new Error('Conversation no longer exists.');
				}
				if (result.status == 200) {
					const convs = requestedConvs.filter(conv => conv.supportRequestId !== item.supportRequestId);
					setRequestedConvs(convs);
					setRequestedConvCnt(convs.length);
					setSelectedRequestConvSid(item.conversationSid);
					setIsRequestQueue(false);
				} else if (result.status == 422) {
					const convs = requestedConvs.filter(conv => conv.supportRequestId !== item.supportRequestId);
					setRequestedConvs(convs);
					setRequestedConvCnt(convs.length);
					//Show warning that Conversation has been taken by someone else
				}
			});
	};

	const handleStatusChange = async e => {
		await axios
			.patch(
				userUrl,
				[
					{
						op: 'replace',
						path: '/AgentStatus',
						value: e.target.value,
					},
				],
				userAvailabilityHeaders
			)
			.then(result => {
				if (result.status === 200) {
					setSelectedStatus(e.target.value);
				}
			});
	};

	const handleAvailabilityChange = async type => {
		await axios
			.patch(
				userUrl,
				[
					{
						op: 'replace',
						path: `/${type}ChatAvailability`,
						value: type === 'Text' ? !isTextAvailable : !isVoiceAvailable,
					},
				],
				userAvailabilityHeaders
			)
			.then(result => {
				if (result.status === 200) {
					type === 'Text' ? setIsTextAvailable(!isTextAvailable) : setIsVoiceAvailable(!isVoiceAvailable);
				}
			});
	};

	const getRefreshToken = async url => {
		const result = await axios.get(url);
		return result?.data;
	};

	const hangupCall = () => {
		if (device) {
			device.disconnectAll();
		}
		setIncomingCall(null);
		setActiveCall(null);
		setCallerDetail(null);
	};

	const getUserByPhoneNumber = async phone => {
		await axios
			.get(`${__config.data_sources.breeze}/PractitionerOmegaAI/%20?phone=${phone}`)
			.then(result => {
				if (result?.status === 200 && result?.data?.total > 0) {
					setCallerDetail(result.data.entry[0].resource);
				}
			})
			.catch(e => {
				console.error(e);
			});
	};

	return (
		<LiveChatContext.Provider
			value={{
				isChatOpen,
				setIsChatOpen,
				requestedConvs,
				unreadConvCnt,
				unreadConvs,
				selectedConversation,
				setSelectedConversation,
				conversationPick,
				voiceChatTab,
				setVoiceChatTab,
				isProfileOpen,
				setIsProfileOpen,
				STATUSES,
				handleStatusChange,
				handleAvailabilityChange,
				selectedStatus,
				isTextAvailable,
				isVoiceAvailable,
				chatLogs,
				requestedConvCnt,
				isRequestQueue,
				setIsRequestQueue,
				setUnreadConvs,
				setToastMsg,
				toastMsg,
				chatHeader,
				setChatHeader,
				activeCall,
				setActiveCall,
				isTextChatQueue,
				setIsTextChatQueue,
				incomingCall,
				setIncomingCall,
				device,
				setDevice,
				getUserByPhoneNumber,
				callerDetail,
				setCallerDetail,
			}}
		>
			<>
				<Toast
					customStyle={{ justifyContent: 'flex-end', marginBottom: 7, width: '90vw' }}
					duration={toastMsg?.duration}
					message={toastMsg?.message}
					open={!!toastMsg}
					onClose={() => setToastMsg(null)}
					onUndo={toastMsg?.onUndo}
				/>
				{children}
			</>
		</LiveChatContext.Provider>
	);
};

export default LiveChatContextProvider;
export { useLiveChatContext };
