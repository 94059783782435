import React from 'react';

const ArticleIcon = () => (
	<svg fill="none" height="64" viewBox="0 0 85 64" width="85" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_46073)">
			<path
				d="M77.0304 38.5397C77.0305 39.9053 76.8797 41.2667 76.5807 42.5991C73.9228 54.5126 59.7553 63.6047 42.681 63.6047C35.6367 63.6047 29.0882 62.0568 23.6389 59.4032C19.8785 57.5721 16.6406 55.2145 14.1248 52.4732C13.0551 51.4581 12.1137 50.3158 11.3216 49.0718C4.09684 37.8603 6.806 17.8546 19.0293 18.0212C20.4873 18.05 21.9456 17.9754 23.3932 17.798C26.8277 17.3546 28.9108 16.3163 30.1725 15.1903C30.4822 14.9148 30.7644 14.61 31.0153 14.2801C32.7712 11.9462 35.36 10.3799 38.2423 9.90765C63.7378 5.82362 73.0222 18.534 75.9002 29.2435C76.6821 32.1312 77.0593 35.1135 77.0208 38.1049C77.0283 38.2491 77.0304 38.3944 77.0304 38.5397Z"
				fill="#4BAAF6"
				opacity="0.18"
			/>
			<path
				d="M25.71 54.8017L12.1065 50.2081C12.1065 50.2081 7.10057 43.9982 7.37939 33.5547L27.6233 41.8413C27.6233 41.8413 36.4259 39.3116 41.6701 39.0585C46.9143 38.8053 70.1632 36.3643 70.1632 36.3643C70.1632 36.3643 74.65 41.508 72.6545 43.0816C70.6589 44.6552 56.2959 44.9201 56.2959 44.9201L46.3075 39.0659L37.9119 45.5568L31.5022 51.5969L25.71 54.8017Z"
				fill="#000405"
				opacity="0.05"
			/>
			<path
				d="M48.0115 31.7422L47.7979 32.2849C47.7979 32.2849 48.4388 32.8521 48.0115 33.0957C48.0115 33.0957 50.2357 32.9579 48.0115 31.7422Z"
				fill="#3F3D56"
			/>
			<path
				d="M22.5906 49.3164L22.332 50.0492L24.4515 51.0417C24.4515 51.0417 26.4214 51.2895 27.0271 50.7703C27.3946 50.4465 27.6631 50.0255 27.8016 49.5557L22.5906 49.3164Z"
				fill="#333333"
			/>
			<path
				d="M28.1486 42.4417C28.1486 42.4417 30.6463 42.3979 31.377 43.3049C32.1077 44.2119 29.4819 46.8975 29.4819 46.8975L23.4258 47.3249L28.1486 42.4417Z"
				fill="#4BAAF6"
			/>
			<path
				d="M28.1486 42.4417C28.1486 42.4417 30.6463 42.3979 31.377 43.3049C32.1077 44.2119 29.4819 46.8975 29.4819 46.8975L23.4258 47.3249L28.1486 42.4417Z"
				fill="url(#paint0_linear_2852_46073)"
			/>
			<path
				d="M28.0594 46.3325C28.0594 46.3325 33.3068 46.8058 34.2191 47.1423C35.9433 47.7907 35.4306 50.2136 34.6464 50.4721C33.9959 50.6911 29.0476 52.5018 27.4655 53.0819C27.3343 53.1293 27.19 53.1246 27.0622 53.0687C26.9344 53.0129 26.8329 52.9101 26.7786 52.7817L26.2359 51.488C26.2061 51.4172 26.1916 51.3409 26.1932 51.2641C26.1948 51.1873 26.2126 51.1116 26.2453 51.0421C26.2781 50.9726 26.325 50.9108 26.3832 50.8606C26.4414 50.8104 26.5095 50.7731 26.5831 50.7509L29.696 49.8247C29.696 49.8247 25.0747 50.2905 24.141 50.0832C22.0739 49.6303 20.0356 46.9852 21.121 44.6756C22.2063 42.366 28.0594 46.3325 28.0594 46.3325Z"
				fill="#4BAAF6"
			/>
			<path
				d="M28.0594 46.3325C28.0594 46.3325 33.3068 46.8058 34.2191 47.1423C35.9433 47.7907 35.4306 50.2136 34.6464 50.4721C33.9959 50.6911 29.0476 52.5018 27.4655 53.0819C27.3343 53.1293 27.19 53.1246 27.0622 53.0687C26.9344 53.0129 26.8329 52.9101 26.7786 52.7817L26.2359 51.488C26.2061 51.4172 26.1916 51.3409 26.1932 51.2641C26.1948 51.1873 26.2126 51.1116 26.2453 51.0421C26.2781 50.9726 26.325 50.9108 26.3832 50.8606C26.4414 50.8104 26.5095 50.7731 26.5831 50.7509L29.696 49.8247C29.696 49.8247 25.0747 50.2905 24.141 50.0832C22.0739 49.6303 20.0356 46.9852 21.121 44.6756C22.2063 42.366 28.0594 46.3325 28.0594 46.3325Z"
				fill="url(#paint1_linear_2852_46073)"
			/>
			<path
				d="M41.7669 33.2773L41.5244 34.226L42.7679 35.3274C42.7679 35.3274 42.4122 35.3274 42.1269 35.8829L42.0137 37.0975C42.0137 37.0975 42.8491 38.905 45.1544 37.3251L48.8934 40.6303L57.1865 37.7096L57.3638 36.5794L41.7669 33.2773Z"
				fill="#3F3D56"
			/>
			<path
				d="M64.2505 34.1835L60.1248 32.563C59.8702 32.4203 59.5849 32.3414 59.2933 32.3328C59.0016 32.3243 58.7122 32.3864 58.4497 32.5138C58.2173 32.6237 58.0193 32.7951 57.8771 33.0094C57.735 33.2236 57.6541 33.4726 57.6431 33.7295C57.5011 35.2892 58.3407 42.468 58.3407 42.468C58.6504 42.5879 58.9731 42.6707 59.3022 42.7148C59.5908 42.7723 59.8886 42.7658 60.1745 42.6959C60.4603 42.6259 60.7274 42.4942 60.9569 42.3099L61.0371 36.9098C61.0371 36.9098 65.6873 39.7824 67.3463 39.5431C69.203 39.276 72.1899 37.7527 70.1719 34.2199C68.1539 30.6871 64.2505 34.1835 64.2505 34.1835Z"
				fill="#4BAAF6"
			/>
			<path
				d="M64.2505 34.1835L60.1248 32.563C59.8702 32.4203 59.5849 32.3414 59.2933 32.3328C59.0016 32.3243 58.7122 32.3864 58.4497 32.5138C58.2173 32.6237 58.0193 32.7951 57.8771 33.0094C57.735 33.2236 57.6541 33.4726 57.6431 33.7295C57.5011 35.2892 58.3407 42.468 58.3407 42.468C58.6504 42.5879 58.9731 42.6707 59.3022 42.7148C59.5908 42.7723 59.8886 42.7658 60.1745 42.6959C60.4603 42.6259 60.7274 42.4942 60.9569 42.3099L61.0371 36.9098C61.0371 36.9098 65.6873 39.7824 67.3463 39.5431C69.203 39.276 72.1899 37.7527 70.1719 34.2199C68.1539 30.6871 64.2505 34.1835 64.2505 34.1835Z"
				fill="url(#paint2_linear_2852_46073)"
			/>
			<path
				d="M60.8316 24.6836C60.8316 24.6836 59.6202 28.5401 59.2933 29.3669C58.9664 30.1938 53.2383 30.8818 53.2383 30.8818L53.4519 33.357L59.9749 32.5472L61.9362 29.7109L60.8316 24.6836Z"
				fill="white"
			/>
			<path
				d="M60.8316 24.6836C60.8316 24.6836 59.6202 28.5401 59.2933 29.3669C58.9664 30.1938 53.2383 30.8818 53.2383 30.8818L53.4519 33.357L59.9749 32.5472L61.9362 29.7109L60.8316 24.6836Z"
				fill="url(#paint3_linear_2852_46073)"
				opacity="0.61"
			/>
			<path
				d="M64.0938 23.4066L65.4216 24.1438C67.8883 25.2451 68.7846 27.6968 70.1733 34.239C70.1733 34.239 69.0068 36.8477 64.7123 35.0936C64.7123 35.0936 61.798 30.8355 61.3173 29.2394C60.8366 27.6434 59.6262 24.8969 61.8514 23.8468C64.0767 22.7967 64.0938 23.4066 64.0938 23.4066Z"
				fill="white"
			/>
			<path
				d="M66.9411 29.0234L68.5585 35.1265C68.5585 35.1265 66.908 36.1328 64.702 35.0902C62.496 34.0476 63.2769 33.0103 62.5654 31.7433L64.7266 30.5682L66.9411 29.0234Z"
				fill="#000405"
				opacity="0.05"
			/>
			<path
				d="M63.9075 23.1406C63.8245 23.3204 63.7068 23.482 63.5613 23.6162C63.4158 23.7504 63.2452 23.8546 63.0594 23.9229C62.8736 23.9912 62.6761 24.0221 62.4783 24.014C62.2805 24.0059 62.0862 23.9589 61.9066 23.8756L61.8446 23.8457C61.8446 23.8457 60.5969 26.0218 62.3788 25.7622C63.3231 25.6244 64.15 24.2185 64.3081 23.506L63.9075 23.1406Z"
				fill="white"
			/>
			<path
				d="M63.987 21.3945C63.987 21.4191 63.9304 22.4927 63.8866 23.2662C63.8546 23.8646 63.5978 24.4289 63.1676 24.8461C62.9183 25.1051 62.6188 25.3105 62.2874 25.4497C62.2074 25.4795 62.1208 25.4865 62.0371 25.4701C61.9534 25.4537 61.8759 25.4145 61.8131 25.3568C61.75 25.2987 61.7042 25.2245 61.6804 25.1422C61.6567 25.0599 61.656 24.9726 61.6785 24.8899C61.7212 24.7286 61.7639 24.5428 61.7981 24.3483C61.8686 23.9445 61.9049 23.5076 61.7906 23.1989C61.7874 23.1872 61.7834 23.1758 61.7789 23.1647C61.7148 23.0055 61.7789 22.8314 61.9295 22.6562C62.1368 22.4105 62.5 22.1616 62.8632 21.9511C63.2272 21.745 63.6024 21.5592 63.987 21.3945Z"
				fill="#FFBBAB"
			/>
			<path
				d="M62.0364 24.2679C61.9623 24.3054 61.8836 24.333 61.8024 24.3501C61.8729 23.9463 61.9092 23.5094 61.7949 23.2006L61.9338 22.658L62.0406 22.2542L62.8717 21.9529L63.7125 21.6484C63.5031 23.1419 62.7051 23.9549 62.0364 24.2679Z"
				fill="#F09F8D"
			/>
			<path
				d="M61.8245 17.1424C61.1926 17.1801 60.6015 17.467 60.1809 17.94C59.7604 18.4131 59.5446 19.0337 59.5811 19.6657C59.5811 19.7265 59.5907 19.7874 59.5993 19.8483C59.674 20.8179 59.923 21.766 60.3343 22.6472C61.113 23.9762 63.0787 22.8822 63.3895 22.3353C63.7004 21.7883 64.8755 19.3131 64.383 18.6091C64.0572 18.1305 63.1 17.0922 61.8245 17.1424Z"
				fill="#FFBBAB"
			/>
			<path
				d="M59.7497 16.2024L60.1535 16.4096C59.9125 16.404 59.6737 16.4558 59.4568 16.5608C59.2398 16.6657 59.0509 16.8208 58.9057 17.0132C58.5369 17.3821 58.3269 17.8804 58.3203 18.402L59.0072 18.0815C59.0072 18.0815 58.9549 18.9275 60.2539 18.9927C61.5529 19.0579 62.9299 19.1102 62.9299 19.1102L62.4919 18.4992C62.4919 18.4992 63.2985 18.8196 63.4406 19.123C63.5679 19.602 63.6594 20.0898 63.7141 20.5823C63.7141 20.5823 64.0345 19.7469 64.1702 19.9296C64.3059 20.1123 64.0292 20.8697 63.8593 21.0353C63.6895 21.2008 63.7183 21.6997 63.7023 21.7403C63.6863 21.7809 64.5099 21.0994 64.5462 20.8654C64.5826 20.6315 65.4009 18.2609 64.8667 17.7674L65.1872 17.7439C64.9953 17.6133 64.776 17.5285 64.5462 17.496C64.5462 17.496 62.4214 14.6875 59.7497 16.2024Z"
				fill="#333333"
			/>
			<path
				d="M53.2312 31.191C52.82 31.119 52.4158 31.0118 52.023 30.8705C51.3935 30.7071 50.7494 30.6065 50.1001 30.5703C50.1001 30.5703 49.9751 30.9976 50.3137 31.0852C50.6524 31.1728 51.0402 31.316 51.0402 31.316L49.6279 31.4581L48.3203 31.6365C48.3203 31.6365 48.4453 32.0638 48.7968 32.0638C48.8984 32.2478 49.0352 32.4101 49.1995 32.5413C49.4481 32.7662 49.6789 33.0102 49.8896 33.2709C50.067 33.411 50.2561 33.5358 50.4547 33.6438C50.5274 33.6438 53.2803 33.3233 53.4459 33.0605L53.2312 31.191Z"
				fill="#FFBBAB"
			/>
			<path
				d="M64.7151 35.0904C64.7151 35.0904 65.1424 37.2173 65.7759 37.7525C65.7759 37.7525 61.6225 40.9937 61.4291 43.3386C61.1588 46.5797 71.3791 41.5535 71.3791 41.5535L70.6718 39.763L66.2406 41.7276C66.2406 41.7276 68.937 39.9051 70.2809 38.0816C71.3342 36.6351 70.7616 35.304 70.174 34.2699C70.1633 34.2358 67.6198 36.3381 64.7151 35.0904Z"
				fill="#4BAAF6"
			/>
			<path
				d="M64.7151 35.0904C64.7151 35.0904 65.1424 37.2173 65.7759 37.7525C65.7759 37.7525 61.6225 40.9937 61.4291 43.3386C61.1588 46.5797 71.3791 41.5535 71.3791 41.5535L70.6718 39.763L66.2406 41.7276C66.2406 41.7276 68.937 39.9051 70.2809 38.0816C71.3342 36.6351 70.7616 35.304 70.174 34.2699C70.1633 34.2358 67.6198 36.3381 64.7151 35.0904Z"
				fill="url(#paint4_linear_2852_46073)"
			/>
			<path
				d="M70.6602 39.7639L71.4272 39.5L71.8064 40.5053L70.9593 41.0843L70.6602 39.7639Z"
				fill="url(#paint5_linear_2852_46073)"
			/>
			<path
				d="M59.3842 43.1593L59.3115 42.7138C59.6004 42.7596 59.8954 42.747 60.1793 42.6767C60.4632 42.6064 60.73 42.4799 60.9642 42.3047L61.009 43.0343C60.7685 43.1851 60.495 43.2752 60.212 43.297C59.929 43.3187 59.6449 43.2715 59.3842 43.1593Z"
				fill="url(#paint6_linear_2852_46073)"
			/>
			<path
				d="M59.3657 43.0195L57.5496 43.6755C57.5496 43.6755 56.5401 43.4287 56.3382 43.9959C56.2058 44.287 56.1928 44.6184 56.3019 44.9189C56.3019 44.9189 56.7783 45.3462 57.9652 45.0973C59.152 44.8484 61.0931 44.2427 61.0931 44.2427L61.0205 43.0559C60.7597 43.1747 60.4754 43.2331 60.1889 43.2268C59.9024 43.2205 59.621 43.1497 59.3657 43.0195Z"
				fill="#333333"
			/>
			<path
				d="M71.5721 39.2669C71.5721 39.2669 71.1159 39.4539 71.1682 39.5746C71.2206 39.6953 71.3819 39.4293 71.79 40.5072L71.5283 40.6984L72.0966 41.9131C71.9472 42.0258 71.8287 42.1744 71.752 42.345C71.6752 42.5157 71.6427 42.7029 71.6575 42.8895C71.7141 43.1854 72.2387 43.226 72.6585 43.0807C73.0783 42.9354 73.2599 42.0028 73.2599 42.0028C73.2599 42.0028 72.9854 39.7669 72.4203 39.3941C71.8552 39.0212 71.5721 39.2669 71.5721 39.2669Z"
				fill="#333333"
			/>
			<path
				d="M57.8772 36.9951L49.3032 39.6968L45.3719 36.4322C43.7877 38.0517 41.8808 36.837 42.1425 35.8916C42.4042 34.9462 44.1616 35.288 44.1616 35.288L41.7793 33.2872L46.2362 32.1655C46.2639 32.3225 46.4498 33.0842 47.532 33.1451C48.7423 33.2145 49.0169 32.2296 48.0116 31.7478L50.8383 31.0117L57.8772 36.9951Z"
				fill="#51E08A"
			/>
			<path
				d="M55.586 35.9343C55.5273 35.95 55.472 35.976 55.4225 36.0112C55.3768 36.0551 55.336 36.1038 55.3007 36.1565C55.1426 36.353 54.8852 36.431 54.6512 36.524C54.3868 36.6284 54.1358 36.764 53.9034 36.9278C53.8685 36.9476 53.8411 36.9784 53.8254 37.0154C53.7902 37.1297 53.9675 37.1863 54.0273 37.2899C54.045 37.3289 54.0509 37.3721 54.0445 37.4144C54.038 37.4567 54.0195 37.4962 53.991 37.5281C53.9335 37.5912 53.8651 37.6433 53.7891 37.682C53.3822 37.9304 53.0268 38.2546 52.7422 38.637C54.0346 37.9647 55.382 37.4036 56.7696 36.9598C56.8776 36.9329 56.9791 36.8841 57.0677 36.8167C57.1115 36.7822 57.1436 36.7352 57.1599 36.6819C57.1761 36.6286 57.1756 36.5716 57.1585 36.5186C57.135 36.4663 57.2172 36.4524 57.1745 36.4118C56.9662 36.2259 56.6158 35.915 56.337 35.6875C56.3412 35.6896 55.8049 35.8616 55.586 35.9343Z"
				fill="#000405"
				opacity="0.05"
			/>
			<path
				d="M66.9788 25.2966C66.8265 25.1173 66.629 24.9821 66.4068 24.9049C66.1847 24.8278 65.9459 24.8115 65.7153 24.8578C65.4847 24.9042 65.2707 25.0114 65.0956 25.1684C64.9204 25.3254 64.7905 25.5264 64.7193 25.7506C64.6424 25.9974 64.5612 26.2933 64.4811 26.6576C64.0538 28.6264 62.1191 32.9081 62.1191 32.9081L56.5 35.9431L58.0383 37.9119L64.6189 34.6707C64.6189 34.6707 67.5332 28.2333 67.4808 27.0058C67.498 26.3974 67.3223 25.7991 66.9788 25.2966Z"
				fill="white"
			/>
			<path
				d="M66.9788 25.2966C66.8265 25.1173 66.629 24.9821 66.4068 24.9049C66.1847 24.8278 65.9459 24.8115 65.7153 24.8578C65.4847 24.9042 65.2707 25.0114 65.0956 25.1684C64.9204 25.3254 64.7905 25.5264 64.7193 25.7506C64.6424 25.9974 64.5612 26.2933 64.4811 26.6576C64.0538 28.6264 62.1191 32.9081 62.1191 32.9081L56.5 35.9431L58.0383 37.9119L64.6189 34.6707C64.6189 34.6707 67.5332 28.2333 67.4808 27.0058C67.498 26.3974 67.3223 25.7991 66.9788 25.2966Z"
				fill="url(#paint7_linear_2852_46073)"
				opacity="0.61"
			/>
			<path
				d="M56.9806 36.2188C56.5746 36.328 56.16 36.4023 55.7414 36.441C55.1012 36.5571 54.4739 36.736 53.8687 36.9751C53.8687 36.9751 53.9328 37.4131 54.2725 37.3522C54.6122 37.2913 55.0267 37.2635 55.0267 37.2635L53.8164 37.9889L52.7021 38.7014C52.7021 38.7014 52.9927 39.0379 53.3111 38.8873C53.482 39.0113 53.6754 39.1009 53.8805 39.1512C54.1988 39.2503 54.5092 39.3735 54.8088 39.5197C55.0288 39.5727 55.2528 39.6066 55.4786 39.6212C55.5438 39.5924 57.9292 38.1342 57.9453 37.8308L56.9806 36.2188Z"
				fill="#FFBBAB"
			/>
			<path
				d="M38.2066 35.4375L40.2577 36.2473V37.463C40.2577 37.463 37.8006 37.5153 37.8006 38.5804C37.8006 39.6455 40.1177 40.4808 41.671 39.059L45.207 41.0887L45.0788 42.1292L34.3277 46.0188L28.168 38.5035V37.2888L38.2066 35.4375Z"
				fill="#3F3D56"
			/>
			<path
				d="M38.2066 34.2383L40.262 36.2434C40.262 36.2434 37.8049 36.2969 37.8049 37.3619C37.8049 38.427 40.122 39.2624 41.6753 37.8395L45.2113 41.0806L34.4409 44.8773L28.168 37.3096L32.3941 36.017C32.3941 36.017 31.0502 35.1132 32.3417 34.5096C32.5803 34.3924 32.8393 34.3224 33.1045 34.3034C34.1183 34.2629 34.2796 34.7852 34.4131 35.3568V35.4016L38.2066 34.2383Z"
				fill="#51E08A"
			/>
			<path
				d="M29.8766 36.7852C29.8766 36.7852 31.5858 40.5006 32.3988 41.117C32.8517 41.461 35.9829 42.4823 38.6418 42.6265C38.8907 42.6404 39.6684 41.9193 40.0359 42.0144C40.5701 42.1543 39.9387 42.634 40.0626 42.6404C41.6651 42.7162 42.5528 42.0144 42.5528 42.0144L34.1134 45.0611L28.459 36.9529L29.8766 36.7852Z"
				fill="#000405"
				opacity="0.05"
			/>
			<path
				d="M38.1103 43.4375C38.3966 43.063 38.7242 42.7218 39.0867 42.4205C39.3833 42.2435 39.7024 42.1073 40.0354 42.0156C40.0354 42.0156 40.1849 42.4696 39.9286 42.5551C39.6722 42.6406 39.5247 43.0892 39.5247 43.0892C39.5247 43.0892 41.39 43.4461 41.6154 43.336C41.8408 43.226 41.8621 43.5753 41.422 43.6405C40.9819 43.7056 40.9947 44.337 40.6956 44.3883C40.3965 44.4395 39.9531 44.7248 39.513 44.8305C39.0029 44.8744 38.4892 44.8507 37.9854 44.76L38.1103 43.4375Z"
				fill="#FFBBAB"
			/>
			<path
				d="M29.552 38.044C29.7939 36.7286 29.6332 35.3708 29.091 34.1481C28.5487 32.9254 27.6502 31.8948 26.5128 31.191C26.1078 30.9136 25.6436 30.7347 25.1571 30.6686C23.2033 30.5554 21.1201 44.6738 21.1201 44.6738C21.1201 44.6738 22.0613 47.4086 24.9104 47.6714C27.5426 47.9149 28.0554 46.3307 28.0554 46.3307L29.552 38.044Z"
				fill="white"
			/>
			<path
				d="M29.552 38.044C29.7939 36.7286 29.6332 35.3708 29.091 34.1481C28.5487 32.9254 27.6502 31.8948 26.5128 31.191C26.1078 30.9136 25.6436 30.7347 25.1571 30.6686C23.2033 30.5554 21.1201 44.6738 21.1201 44.6738C21.1201 44.6738 22.0613 47.4086 24.9104 47.6714C27.5426 47.9149 28.0554 46.3307 28.0554 46.3307L29.552 38.044Z"
				fill="url(#paint8_linear_2852_46073)"
				opacity="0.61"
			/>
			<path
				d="M25.1572 30.6687C25.1572 30.6687 25.4435 28.5386 25.561 27.153C25.6785 25.7675 26.1336 23.9119 28.4593 24.1544C30.7849 24.3969 30.7839 27.9147 30.1375 29.3889C29.4912 30.8632 28.6323 30.8151 28.6323 30.8151C28.6323 30.8151 27.4743 30.5598 27.5139 31.9539L25.1572 30.6687Z"
				fill="#FFBBAB"
			/>
			<path
				d="M26.0371 23.855C25.6983 24.1798 25.4529 24.5897 25.3267 25.0418C25.2219 25.4085 25.1504 25.784 25.113 26.1635L25.3512 25.905C25.3377 26.5554 25.2878 27.2045 25.2017 27.8493C25.1138 28.4504 25.0781 29.058 25.0948 29.6653L25.5222 29.645L26.0563 30.3138L26.6577 29.9078L27.1096 29.6696L27.5134 28.6761C27.4151 28.604 27.343 28.5017 27.3081 28.3849C27.2732 28.268 27.2774 28.143 27.3201 28.0287C27.4696 27.6238 27.836 27.5298 27.9215 27.7691C27.987 28.0672 28.0228 28.371 28.0283 28.6761L28.6693 26.86C28.6693 26.86 29.0731 26.296 29.4246 26.6859C29.635 26.9211 29.9014 27.0993 30.1991 27.204L29.9854 26.5962L30.5666 26.7457L30.5217 26.0535L30.949 26.3569C30.949 26.3569 31.1424 25.2352 30.7749 24.7363C30.4074 24.2374 28.9684 22.2547 26.0371 23.855Z"
				fill="#333333"
			/>
			<path
				d="M38.1703 43.0771L33.4122 42.5622C32.7692 42.4386 32.1811 42.1165 31.7306 41.6413C31.2801 41.166 30.9898 40.5616 30.9007 39.9129L29.1412 34.5448C29.0348 34.208 28.8362 33.9077 28.5679 33.6779C28.2996 33.4481 27.9724 33.2981 27.6232 33.2447C27.3477 33.2035 27.0663 33.2328 26.8052 33.33C26.5442 33.4272 26.3121 33.5891 26.1306 33.8004C25.9492 34.0117 25.8243 34.2657 25.7677 34.5384C25.7111 34.8112 25.7247 35.0938 25.8071 35.3599L27.6232 41.8411C27.7087 42.5528 28.036 43.2135 28.5503 43.7127C29.0647 44.2118 29.7349 44.5192 30.4488 44.5834L37.9118 45.5566L38.1703 43.0771Z"
				fill="white"
			/>
			<path
				d="M38.1703 43.0771L33.4122 42.5622C32.7692 42.4386 32.1811 42.1165 31.7306 41.6413C31.2801 41.166 30.9898 40.5616 30.9007 39.9129L29.1412 34.5448C29.0348 34.208 28.8362 33.9077 28.5679 33.6779C28.2996 33.4481 27.9724 33.2981 27.6232 33.2447C27.3477 33.2035 27.0663 33.2328 26.8052 33.33C26.5442 33.4272 26.3121 33.5891 26.1306 33.8004C25.9492 34.0117 25.8243 34.2657 25.7677 34.5384C25.7111 34.8112 25.7247 35.0938 25.8071 35.3599L27.6232 41.8411C27.7087 42.5528 28.036 43.2135 28.5503 43.7127C29.0647 44.2118 29.7349 44.5192 30.4488 44.5834L37.9118 45.5566L38.1703 43.0771Z"
				fill="url(#paint9_linear_2852_46073)"
				opacity="0.61"
			/>
			<path
				d="M26.4249 50.957L25.0244 51.2775L25.5853 52.8981L27.0509 52.5595C27.0509 52.5595 27.443 51.3064 26.4249 50.957Z"
				fill="url(#paint10_linear_2852_46073)"
			/>
			<path
				d="M24.4512 51.0403C24.4512 51.0403 25.3677 50.8459 25.5194 51.1578C25.6711 51.4698 25.184 51.4495 25.2812 51.8917C25.3627 52.2281 25.4636 52.5595 25.5835 52.8842L26.0354 52.7827L26.1284 53.3168C26.1483 53.4087 26.1995 53.4907 26.2731 53.5492C26.3467 53.6076 26.4382 53.6387 26.5322 53.6373C27.5898 53.6982 27.4488 54.6575 27.077 54.6938L25.7085 54.8007L24.9853 54.1918C24.8987 54.1316 24.8304 54.0488 24.7877 53.9525C24.5238 53.4343 23.713 51.5061 24.4512 51.0403Z"
				fill="#333333"
			/>
			<path
				d="M46.7158 18.0051C46.6458 18.1063 46.585 18.2136 46.5342 18.3256C46.2276 18.9815 45.1903 21.3275 45.0386 23.3604C45.0085 23.8551 44.7978 24.3215 44.4464 24.6711C44.0951 25.0206 43.6276 25.229 43.1328 25.2566L41.2804 25.3634L40.6747 25.3955C40.1881 25.4242 39.7076 25.276 39.3217 24.9782C38.9359 24.6803 38.6709 24.2529 38.5755 23.7749C38.1963 21.9033 37.3887 19.0435 35.8194 17.7797C35.8194 17.7797 28.9001 11.4438 33.514 4.37816C33.8242 3.90077 34.171 3.44823 34.5513 3.02465C39.3938 -2.33598 47.3867 0.106105 49.3075 5.08536C51.2282 10.0646 47.4796 16.9315 46.7158 18.0051Z"
				fill="#FBCA1B"
				opacity="0.4"
			/>
			<path
				d="M46.7158 18.0051C46.6458 18.1063 46.585 18.2136 46.5342 18.3256C46.2276 18.9815 45.1903 21.3275 45.0386 23.3604C45.0085 23.8551 44.7978 24.3215 44.4464 24.6711C44.0951 25.0206 43.6276 25.229 43.1328 25.2566L41.2804 25.3634L40.6747 25.3955C40.1881 25.4242 39.7076 25.276 39.3217 24.9782C38.9359 24.6803 38.6709 24.2529 38.5755 23.7749C38.1963 21.9033 37.3887 19.0435 35.8194 17.7797C35.8194 17.7797 28.9001 11.4438 33.514 4.37816C33.8242 3.90077 34.171 3.44823 34.5513 3.02465C39.3938 -2.33598 47.3867 0.106105 49.3075 5.08536C51.2282 10.0646 47.4796 16.9315 46.7158 18.0051Z"
				fill="#FBCA1B"
				opacity="0.4"
			/>
			<path
				d="M46.7158 18.0051C46.6458 18.1063 46.585 18.2136 46.5342 18.3256C46.2276 18.9815 45.1903 21.3275 45.0386 23.3604C45.0085 23.8551 44.7978 24.3215 44.4464 24.6711C44.0951 25.0206 43.6276 25.229 43.1328 25.2566L41.2804 25.3634L40.6747 25.3955C40.1881 25.4242 39.7076 25.276 39.3217 24.9782C38.9359 24.6803 38.6709 24.2529 38.5755 23.7749C38.1963 21.9033 37.3887 19.0435 35.8194 17.7797C35.8194 17.7797 28.9001 11.4438 33.514 4.37816C33.8242 3.90077 34.171 3.44823 34.5513 3.02465C39.3938 -2.33598 47.3867 0.106105 49.3075 5.08536C51.2282 10.0646 47.4796 16.9315 46.7158 18.0051Z"
				fill="#FBCA1B"
				opacity="0.4"
			/>
			<path
				d="M45.303 26.1326C45.1417 26.5374 44.9559 26.7319 45.1172 27C45.2785 27.2681 45.7432 27.9892 43.3855 28.3097C42.8971 28.3771 42.4052 28.4164 41.9123 28.4272H41.141L40.6891 28.4069C40.1732 28.3749 39.7565 28.3225 39.4788 28.2777C39.3278 28.2555 39.1832 28.2019 39.0544 28.1201C38.9255 28.0384 38.8153 27.9305 38.731 27.8033C38.6897 27.7078 38.6758 27.6026 38.6909 27.4996C38.706 27.3966 38.7495 27.2998 38.8165 27.2201C38.8465 27.1922 38.8708 27.1586 38.8878 27.1213C38.9049 27.084 38.9145 27.0438 38.9159 27.0028C38.9174 26.9618 38.9108 26.9209 38.8965 26.8825C38.8822 26.8441 38.8604 26.8089 38.8325 26.7789C38.8006 26.7455 38.762 26.7193 38.7192 26.7019C38.5918 26.5821 38.5162 26.4171 38.5086 26.2423C38.501 26.0675 38.562 25.8966 38.6786 25.7661C38.7364 25.7064 38.7843 25.6379 38.8207 25.5632C38.8712 25.4476 38.8892 25.3206 38.8728 25.1956C38.8564 25.0706 38.8063 24.9524 38.7278 24.8538L38.6626 24.7737C38.5726 24.6521 38.507 24.5143 38.4693 24.3678C38.43 24.2671 38.4183 24.1578 38.4354 24.0511C38.4525 23.9445 38.4977 23.8443 38.5665 23.761C38.6618 24.2387 38.9268 24.6658 39.3124 24.9635C39.698 25.2611 40.1783 25.4092 40.6646 25.3805L41.2703 25.3442C41.2876 25.3207 41.3012 25.2948 41.3109 25.2672C41.4177 25.0087 41.1774 24.7085 41.2991 24.3058L41.7542 24.2855C42.8732 24.2186 43.9782 24.003 45.0403 23.6445C45.1563 23.7405 45.2356 23.8736 45.265 24.0213C45.2944 24.169 45.272 24.3223 45.2016 24.4554C45.1597 24.5451 45.1312 24.6404 45.1172 24.7384C45.0895 24.9438 45.1245 25.1527 45.2176 25.3378C45.2282 25.3603 45.2404 25.382 45.2539 25.4029C45.3187 25.5122 45.3569 25.6351 45.3654 25.7619C45.3739 25.8886 45.3526 26.0156 45.303 26.1326Z"
				fill="#4BAAF6"
			/>
			<path
				d="M38.5713 23.7592C40.6845 23.2116 42.8973 23.1713 45.029 23.6417C45.029 23.6417 41.5294 25.0754 38.5713 23.7592Z"
				fill="#333333"
			/>
			<path
				d="M41.6698 28.3874C41.494 28.4218 41.3145 28.434 41.1356 28.4237L40.6827 28.4034C40.1667 28.3713 39.7511 28.319 39.4723 28.2741C39.3214 28.252 39.1767 28.1983 39.0479 28.1166C38.9191 28.0349 38.8089 27.927 38.7245 27.7998C38.683 27.7044 38.6688 27.5992 38.6837 27.4962C38.6987 27.3931 38.742 27.2963 38.8089 27.2165C38.8469 27.1802 38.8753 27.135 38.8917 27.0851C38.9081 27.0352 38.9119 26.982 38.9029 26.9302C38.8932 26.8786 38.8706 26.8302 38.8372 26.7897C38.8037 26.7491 38.7606 26.7177 38.7117 26.6984C38.5841 26.5785 38.5086 26.4135 38.5012 26.2386C38.4938 26.0637 38.5552 25.8928 38.6722 25.7626C38.7912 25.6434 38.862 25.4844 38.8708 25.3162C38.8797 25.148 38.8261 24.9824 38.7203 24.8514C38.6017 24.7112 38.5163 24.5462 38.4703 24.3685C38.4311 24.2679 38.4193 24.1587 38.4362 24.0521C38.4531 23.9455 38.498 23.8453 38.5664 23.7617C39.579 24.1495 40.6602 24.3258 41.7435 24.2798L41.7958 24.3044C41.7958 24.3044 41.392 24.4582 41.4967 24.8108C41.6014 25.1633 41.7552 25.7989 41.408 26.2124C41.0609 26.6258 41.7285 26.739 41.6025 27.2048V27.2497C41.5511 27.3885 41.5118 27.5314 41.485 27.677C41.4123 28.0434 41.4465 28.3553 41.6698 28.3874Z"
				fill="url(#paint11_linear_2852_46073)"
			/>
			<path
				d="M41.7516 24.2776C40.6672 24.3246 39.5847 24.1482 38.5713 23.7595C38.1953 21.8996 37.3877 19.0398 35.8184 17.7771C35.8184 17.7771 28.8991 11.4411 33.513 4.37555C33.8232 3.89816 34.17 3.44562 34.5503 3.02204L35.1721 2.96542C35.1721 2.96542 39.3106 0.785059 41.0658 3.1716C42.821 5.55813 38.9901 8.25554 38.7893 12.6141C38.5884 16.9727 43.5741 17.7792 41.7516 24.2776Z"
				fill="white"
				opacity="0.49"
			/>
			<path
				d="M44.7583 27.2523C44.4592 27.4467 43.3856 27.812 41.4723 27.6796C40.5755 27.6082 39.6876 27.4508 38.8208 27.2095C38.8509 27.1816 38.8751 27.1481 38.8922 27.1108C38.9093 27.0735 38.9188 27.0332 38.9203 26.9922C38.9218 26.9513 38.9152 26.9104 38.9009 26.872C38.8866 26.8336 38.8648 26.7983 38.8369 26.7683C38.805 26.735 38.7664 26.7088 38.7236 26.6914C39.6528 27.0097 40.626 27.1806 41.608 27.1978C42.7104 27.2286 43.8112 27.0921 44.8726 26.7929C44.8726 26.7929 45.206 26.9606 44.7583 27.2523Z"
				fill="url(#paint12_linear_2852_46073)"
			/>
			<path
				d="M45.2148 25.3415C44.8559 25.6417 43.4992 26.1961 41.5378 26.006C40.6168 25.9726 39.7038 25.8215 38.8212 25.5562C38.8717 25.4409 38.8897 25.314 38.8733 25.1892C38.8569 25.0644 38.8068 24.9464 38.7283 24.848L38.6631 24.7668C38.7923 24.8159 39.0103 24.8843 39.3041 24.9612C39.966 25.1287 40.6418 25.236 41.3231 25.2817H41.6372C42.8234 25.3294 44.0073 25.1421 45.1208 24.7305C45.0884 24.939 45.1213 25.1524 45.2148 25.3415Z"
				fill="url(#paint13_linear_2852_46073)"
			/>
			<path
				d="M31.7812 35.125L31.8058 36.1933L32.3987 36.0106C32.3987 36.0106 31.7812 35.5822 31.7812 35.125Z"
				fill="#3F3D56"
			/>
			<path
				d="M44.7764 13.4728L44.6472 13.3852C44.842 12.7961 44.9107 12.1727 44.8491 11.5553C44.6953 10.2477 43.7723 9.4497 42.2532 9.31189C39.9243 9.09076 39.0024 10.2926 38.6605 11.0276C37.6735 13.0819 38.5665 16.4085 39.9895 17.9009C41.7436 19.7319 41.0663 23.1846 40.8185 24.2037C40.81 24.2286 40.8046 24.2544 40.8024 24.2806V24.349L41.1603 24.4708L41.186 24.3704C41.3983 23.5546 41.5215 22.7182 41.5534 21.8759C41.6165 20.0599 41.1817 18.591 40.2918 17.6295C38.9778 16.2525 38.1243 13.0733 39.0312 11.1921C39.5814 10.065 40.6913 9.54585 42.2436 9.69006C43.9325 9.85031 44.3993 10.8192 44.4934 11.6001C44.5423 12.1351 44.4838 12.6745 44.3214 13.1865C43.9443 12.9961 43.5113 12.9475 43.1014 13.0498C42.3012 13.2699 41.7959 14.0989 41.5129 14.7526C41.4421 14.9152 41.4202 15.0948 41.45 15.2696C41.4798 15.4443 41.5599 15.6066 41.6806 15.7365C41.7984 15.8636 41.9503 15.9541 42.1181 15.9971C42.286 16.0401 42.4627 16.0338 42.6271 15.979C43.3482 15.7237 44.1002 14.8392 44.5233 13.7602L44.5649 13.7891C45.74 14.6159 44.9014 15.8904 43.9368 17.3678C43.2499 18.403 42.3974 19.6913 42.3055 20.9604C42.1987 22.0971 42.2478 22.9955 42.3247 24.1396L42.3301 24.2304H42.7147L42.7093 24.1236C42.6345 22.9816 42.5897 22.0939 42.6901 20.9893C42.7724 19.827 43.5575 18.6316 44.2498 17.5708L44.2808 17.5227C45.2828 15.9812 46.2304 14.5176 44.7764 13.4728ZM42.4861 15.6094C42.3931 15.6412 42.2929 15.645 42.1979 15.6201C42.1028 15.5952 42.0173 15.5428 41.9519 15.4695C41.8814 15.3947 41.8347 15.3007 41.8176 15.1993C41.8005 15.098 41.8138 14.9939 41.8558 14.9001C42.0587 14.429 42.5032 13.6074 43.1943 13.4152C43.2932 13.3884 43.3952 13.3747 43.4977 13.3746C43.7336 13.3826 43.9648 13.4424 44.175 13.5498C43.7723 14.5678 43.0875 15.4086 42.4861 15.6115V15.6094ZM44.4282 13.459L44.4453 13.4707L44.4282 13.459Z"
				fill="white"
			/>
		</g>
		<defs>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint0_linear_2852_46073"
				x1="23.4258"
				x2="31.5052"
				y1="44.8828"
				y2="44.8828"
			>
				<stop stopOpacity="0" />
				<stop offset="0.91" stopOpacity="0.8" />
				<stop offset="1" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint1_linear_2852_46073"
				x1="34.6764"
				x2="25.1612"
				y1="39.8384"
				y2="50.7124"
			>
				<stop stopOpacity="0" />
				<stop offset="0.91" stopOpacity="0.8" />
				<stop offset="1" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint2_linear_2852_46073"
				x1="51.7804"
				x2="67.122"
				y1="34.9463"
				y2="38.4834"
			>
				<stop stopOpacity="0" />
				<stop offset="0.91" stopOpacity="0.8" />
				<stop offset="1" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint3_linear_2852_46073"
				x1="64.201"
				x2="57.7539"
				y1="33.1294"
				y2="29.3765"
			>
				<stop offset="0.36" stopColor="#00272E" />
				<stop offset="1" stopColor="#69B9EB" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint4_linear_2852_46073"
				x1="61.5669"
				x2="71.3588"
				y1="37.1319"
				y2="41.8099"
			>
				<stop stopOpacity="0" />
				<stop offset="0.91" stopOpacity="0.8" />
				<stop offset="1" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint5_linear_2852_46073"
				x1="70.6602"
				x2="71.8064"
				y1="40.2927"
				y2="40.2927"
			>
				<stop stopColor="#ECC4D7" />
				<stop offset="0.42" stopColor="#EFD4D1" />
				<stop offset="1" stopColor="#F2EAC9" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint6_linear_2852_46073"
				x1="59.3094"
				x2="61.009"
				y1="42.8068"
				y2="42.8068"
			>
				<stop stopColor="#ECC4D7" />
				<stop offset="0.42" stopColor="#EFD4D1" />
				<stop offset="1" stopColor="#F2EAC9" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint7_linear_2852_46073"
				x1="71.8608"
				x2="60.0285"
				y1="36.3853"
				y2="30.2652"
			>
				<stop offset="0.36" stopColor="#00272E" />
				<stop offset="1" stopColor="#69B9EB" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint8_linear_2852_46073"
				x1="18.3095"
				x2="27.1228"
				y1="38.3613"
				y2="39.4221"
			>
				<stop offset="0.36" stopColor="#00272E" />
				<stop offset="1" stopColor="#69B9EB" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint9_linear_2852_46073"
				x1="22.2091"
				x2="33.253"
				y1="46.5095"
				y2="38.7848"
			>
				<stop offset="0.36" stopColor="#00272E" />
				<stop offset="1" stopColor="#69B9EB" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint10_linear_2852_46073"
				x1="25.0244"
				x2="27.1236"
				y1="51.9281"
				y2="51.9281"
			>
				<stop stopColor="#ECC4D7" />
				<stop offset="0.42" stopColor="#EFD4D1" />
				<stop offset="1" stopColor="#F2EAC9" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint11_linear_2852_46073"
				x1="42.3171"
				x2="35.8605"
				y1="26.1824"
				y2="25.9474"
			>
				<stop stopOpacity="0" />
				<stop offset="0.91" stopOpacity="0.8" />
				<stop offset="1" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint12_linear_2852_46073"
				x1="41.6144"
				x2="42.3398"
				y1="23.2494"
				y2="33.913"
			>
				<stop stopColor="#68E1FD" stopOpacity="0" />
				<stop offset="0.02" stopColor="#66DAF6" stopOpacity="0.04" />
				<stop offset="0.18" stopColor="#58A1BC" stopOpacity="0.39" />
				<stop offset="0.35" stopColor="#4D758F" stopOpacity="0.66" />
				<stop offset="0.53" stopColor="#45566F" stopOpacity="0.85" />
				<stop offset="0.73" stopColor="#40435C" stopOpacity="0.96" />
				<stop offset="0.99" stopColor="#3F3D56" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint13_linear_2852_46073"
				x1="41.8156"
				x2="42.5409"
				y1="23.2381"
				y2="33.9006"
			>
				<stop stopColor="#68E1FD" stopOpacity="0" />
				<stop offset="0.02" stopColor="#66DAF6" stopOpacity="0.04" />
				<stop offset="0.18" stopColor="#58A1BC" stopOpacity="0.39" />
				<stop offset="0.35" stopColor="#4D758F" stopOpacity="0.66" />
				<stop offset="0.53" stopColor="#45566F" stopOpacity="0.85" />
				<stop offset="0.73" stopColor="#40435C" stopOpacity="0.96" />
				<stop offset="0.99" stopColor="#3F3D56" />
			</linearGradient>
			<clipPath id="clip0_2852_46073">
				<rect fill="white" height="63.6043" width="84.39" />
			</clipPath>
		</defs>
	</svg>
);

export default ArticleIcon;
