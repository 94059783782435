import React, { useCallback } from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const Dragger = styled(Box)(({ theme }) => ({
	height: '6%',
	width: '4px',
	borderRadius: '2px',
	backgroundColor: theme?.palette?.icon?.primary,
	cursor: 'ew-resize',
	top: 0,
	left: 0,
	bottom: 0,
	zIndex: 100,
	alignSelf: 'center',
}));

const WidthResizeTool = ({ setWidth, minWidth, maxWidth, cursorAdjustment, sx, isRightSide = false }) => {
	// FF
	const maven2061TeachingFolder = useBooleanFlagValue('maven-2061-teaching-folder');

	const handleMouseDown = () => {
		document.addEventListener('mouseup', handleMouseUp, true);
		document.addEventListener('mousemove', handleMouseMove, true);
	};

	const handleMouseUp = () => {
		document.removeEventListener('mouseup', handleMouseUp, true);
		document.removeEventListener('mousemove', handleMouseMove, true);
	};

	const handleMouseMove = useCallback(
		e => {
			e.preventDefault();
			if (maven2061TeachingFolder) {
				handleSetWidthV2(e);
			} else {
				handleSetWidthV1(e);
			}
		},
		[cursorAdjustment]
	);

	const handleSetWidthV1 = e => {
		const newWidth = document.body.offsetLeft + document.body.offsetWidth - e.clientX + cursorAdjustment;
		if (newWidth > minWidth && newWidth < maxWidth) {
			setWidth(newWidth);
		}
	};

	const handleSetWidthV2 = e => {
		const newWidth = isRightSide
			? e.clientX + cursorAdjustment
			: document.body.offsetLeft + document.body.offsetWidth - e.clientX + cursorAdjustment;
		if (newWidth > maxWidth) {
			setWidth(maxWidth);
		} else if (newWidth < minWidth) {
			setWidth(minWidth);
		} else {
			setWidth(newWidth);
		}
	};
	return <Dragger data-testid="resize-tool" sx={sx} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} />;
};

WidthResizeTool.defaultProps = {
	minWidth: 200,
	maxWidth: 1000,
	cursorAdjustment: 0,
};

export default WidthResizeTool;
