import React from 'react';

import { Box, Button } from '@mui/material';

import createStyles from './styles';

const Footer = ({ type, onBack, isCreate, onCreate, onSave, saveDisabled, onNext, currentStation }) => {
	const classes = createStyles();

	const rightBlock = () => {
		if (type === 'NEXT') {
			return (
				<Box className="right">
					{isCreate ? (
						<Button className="fill" data-testid="createButton" onClick={onCreate}>
							CREATE
						</Button>
					) : (
						''
					)}
					<Button
						className="fill"
						data-testid="nextButton"
						disabled={!currentStation?.id}
						style={!currentStation?.id ? classes.disabledButton : {}}
						onClick={onNext}
					>
						NEXT
					</Button>
				</Box>
			);
		}

		if (type === 'SAVE') {
			return (
				<Box className="right">
					<Button
						className="fill"
						data-testid="saveButton"
						disabled={saveDisabled}
						style={saveDisabled ? classes.disabledButton : {}}
						onClick={onSave}
					>
						SAVE
					</Button>
				</Box>
			);
		}
	};

	return (
		<Box className="footer" style={classes.footer}>
			<Button className="outline" data-testid="cancelButton" onClick={onBack}>
				GO BACK
			</Button>
			{rightBlock()}
		</Box>
	);
};

export default Footer;
