import React from 'react';
import moment from 'moment';
import { Box, Button, Slide, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import UserAvatar from './UserAvatar';

function ProfileDetails({ isOpen, onClose, conversation, chatLogs }) {
	return (
		<Slide mountOnEnter unmountOnExit direction="up" in={isOpen}>
			<div
				data-testid="IsOpen"
				style={{
					boxSizing: 'border-box',
					zIndex: '1',
					position: 'absolute',
					bottom: '0',
					width: '100%',
					height: 'calc(100vh - 195px)',
					padding: '42px 0 75px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					backgroundColor: '#FFFFFF',
					borderRadius: '20px 20px 0px 0px',
					boxShadow: '0px -4px 20px rgba(33, 88, 119, 0.1)',
				}}
			>
				<div
					style={{
						position: 'absolute',
						top: '0',
						transform: 'translate(0, -50%)',
						width: '70px',
						height: '70px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: '#FFFFFF',
						borderRadius: '50%',
					}}
				>
					<UserAvatar size={60} userName={conversation?.name} />
				</div>
				<Typography
					data-testid="authorName"
					style={{
						paddingBottom: '10px',
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontSize: '24px',
						fontWeight: '600',
						lineHeight: '29px',
						textAlign: 'center',
						color: '#4D79EA',
					}}
				>
					{conversation?.name}
				</Typography>
				<Box
					sx={{
						width: '100%',
						padding: '0 22px',
						overflow: 'auto',
						boxSizing: 'border-box',
					}}
				>
					<DetailsCategory
						bgColor="rgba(211, 73, 164, 0.2)"
						color="#D349A4"
						icon={<InfoOutlinedIcon />}
						title="Details"
					>
						<div
							style={{
								padding: '15px 19px 19px',
							}}
						>
							<div
								style={{
									marginBottom: '20px',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'flex-start',
								}}
							>
								<DetailsCategoryItem title="Email Address" value={conversation?.email} />
								<div
									style={{
										width: '1px',
										height: '31px',
										marginRight: '55px',
										backgroundColor: 'rgba(196, 196, 196, 0.4)',
									}}
								/>
								<DetailsCategoryItem title="Phone Number" value={conversation?.senderPhone} />
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<DetailsCategoryItem title="Organization" value={conversation?.senderOrganization} />
								<div
									style={{
										width: '1px',
										height: '31px',
										marginRight: '55px',
										backgroundColor: 'rgba(196, 196, 196, 0.4)',
									}}
								/>
								<DetailsCategoryItem title="Website" value={conversation?.senderWebsite} />
							</div>
						</div>
					</DetailsCategory>
					<DetailsCategory
						bgColor="rgba(77, 121, 234, 0.2)"
						color="#4D79EA"
						icon={<ToggleOnOutlinedIcon />}
						title="Permissions"
					>
						<div
							style={{
								padding: '15px 19px 19px',
							}}
						>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'flex-start',
								}}
							>
								<DetailsCategoryItem
									title="Platforms"
									value={
										conversation?.senderFrom == 'omegaai'
											? 'Omega AI'
											: conversation?.senderFrom == 'blume'
											? 'Blume'
											: ''
									}
								/>
								<div
									style={{
										width: '1px',
										height: '31px',
										marginRight: '55px',
										backgroundColor: 'rgba(196, 196, 196, 0.4)',
									}}
								/>
								<DetailsCategoryItem title="Permission Level" value={conversation?.permission || ''} />
							</div>
						</div>
					</DetailsCategory>
					<DetailsCategory
						bgColor="rgba(62, 185, 103, 0.2)"
						color="#3EB967"
						empty={chatLogs?.length === 0}
						icon={<SummarizeOutlinedIcon />}
						title="Chat Logs"
					>
						{chatLogs?.map((item, i, arr) => (
							<DetailsCategoryItem
								key={item?.date}
								date={moment(item?.date).format('DD/MM/YYYY')}
								lastItem={i === arr.length - 1}
								title={
									item?.platform == 'omegaai' ? 'Omega AI' : item.platform == 'blume' ? 'Blume' : ''
								}
								value={item?.message}
							/>
						))}
					</DetailsCategory>
				</Box>
				<Button
					data-testid="onCloseBtn"
					sx={{
						position: 'absolute',
						bottom: '30px',
						left: '22px',
						width: '82px',
						border: '1px solid #4D79EA',
						fontWeight: '400',
						letterSpacing: '1.5px',
						color: '#4D79EA',
					}}
					onClick={onClose}
				>
					Close
				</Button>
			</div>
		</Slide>
	);
}

function DetailsCategory({ icon, color, bgColor, title, children, empty }) {
	return (
		<div
			style={{
				width: '100%',
				margin: '10px 0',
				borderRadius: '10px',
				border: '1px solid rgba(196, 196, 196, 0.5)',
				boxSizing: 'border-box',
			}}
		>
			<div
				style={{
					boxSizing: 'border-box',
					width: '100%',
					padding: '10px 19px',
					display: 'flex',
					alignItems: 'center',
					borderBottom: empty ? 'none' : '1px solid rgba(196, 196, 196, 0.4)',
				}}
			>
				<div
					style={{
						width: '30px',
						height: '30px',
						marginRight: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: `${bgColor}`,
						borderRadius: '50%',
						color: `${color}`,
					}}
				>
					{icon}
				</div>
				<Typography
					style={{
						fontSize: '18px',
						fontWeight: '500',
						lineHeight: '22px',
						color: 'rgba(0, 0, 0, 0.9)',
					}}
				>
					{title}
				</Typography>
			</div>
			{children}
		</div>
	);
}

function DetailsCategoryItem({ title, value, date, lastItem }) {
	return (
		<div
			style={{
				boxSizing: 'border-box',
				width: '100%',
				padding: date && '12px 15px 15px 18px',
				borderBottom: date && !lastItem && '1px solid rgba(196, 196, 196, 0.4)',
			}}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: date ? 'space-between' : 'flex-start',
					alignItem: 'center',
				}}
			>
				{date && (
					<Typography
						style={{
							marginBottom: '2px',
							fontSize: '12px',
							lineHeight: '15px',
							color: 'rgba(0, 0, 0, 0.6)',
						}}
					>
						{new Date(date.split('/').reverse().join('-')).toLocaleDateString('en-US', {
							year: 'numeric',
							month: '2-digit',
							day: '2-digit',
						})}
					</Typography>
				)}
				<Typography
					style={{
						marginBottom: '2px',
						fontSize: '12px',
						lineHeight: '15px',
						color: 'rgba(0, 0, 0, 0.6)',
					}}
				>
					{title}
				</Typography>
			</div>
			<Typography
				style={{
					fontSize: '14px',
					fontWeight: '400',
					lineHeight: '17px',
					color: 'rgba(0, 0, 0, 0.9)',
				}}
			>
				{value}
			</Typography>
		</div>
	);
}

export default ProfileDetails;
