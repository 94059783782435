import { create } from 'zustand';

import { commonMiddlewares } from './middlewares';
import getAssetsAPI from '../api/getAssets';

export const useLicenseStore = create(
	commonMiddlewares((set, get) => ({
		// State
		__config: null,
		isLoading: false,
		assets: {}, // mapped with orgId

		// Actions
		setSharedStates: ({ __config }) => set(() => ({ __config }), false, 'license/setSharedStates'),
		getAssets: async ({ orgId, forceFetch }) => {
			try {
				const { __config, assets } = get();

				if (!forceFetch && assets[orgId]) return assets[orgId];

				set(() => ({ isLoading: true }), false, 'license/getAssets');

				const res = await getAssetsAPI({ __config, orgId });

				if (!res) throw new Error('No assets found for this org!');

				const transformedAssets = res.map(({ Active, Asset, Feature }) => ({
					active: Active?.toLowerCase() === 'true',
					name: Asset,
					features: Feature,
				}));

				set(
					() => ({ isLoading: false, assets: { ...assets, [orgId]: transformedAssets } }),
					false,
					'license/getAssets'
				);

				return transformedAssets;
			} catch (err) {
				console.error(err);
				set(() => ({ isLoading: false }), false, 'license/getAssets');
			}
		},
		/**
		 * Checks if the user has access to one or more assets for a given organization.
		 *
		 * @param {Object} params - The parameters for the function.
		 * @param {string} params.orgId - The ID of the organization.
		 * @param {string|string[]} params.assetNames - The name or names of the assets to check access for.
		 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating if the user has access to any of the specified assets.
		 */
		hasAccess: async ({ orgId, assetNames }) => {
			const { getAssets } = get();
			const orgAssets = await getAssets({ orgId });

			// Ensure assetNames is an array
			const assetNamesArray = Array.isArray(assetNames) ? assetNames : [assetNames];

			return assetNamesArray.some(assetName =>
				orgAssets.some(asset => asset.name?.toLowerCase() === assetName?.toLowerCase() && asset.active)
			);
		},
	}))
);
