import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function calculateOptionBackgroundColor(hexInput) {
	// return transparent in case of none/invalid color
	if (!hexInput || ![4, 7].includes(hexInput?.length) || !hexInput.includes('#')) {
		return '#00000000';
	}

	// trim leading # sign (if any)
	let hex = hexInput.replace(/^\s*#|\s*$/g, '');

	// make sure it's 6 chars long
	if (hex.length === 3) {
		hex = hex.replace(/(.)/g, '$1$1');
	}

	const colorOpacity = 0.09;

	return `#${new Array(3)
		.fill(undefined)
		.map((__, i) =>
			`0${Math.round(
				Math.min(
					255,
					Math.max(0, parseInt(hex.substr(i * 2, 2), 16) * colorOpacity + 0x39 * (1 - colorOpacity))
				)
			).toString(16)}`.slice(-2)
		)
		.join('')}`.toUpperCase();
}

const ColorPreview = ({ color, width }) => (
	<Box
		sx={{
			backgroundColor: calculateOptionBackgroundColor(color),
			width: `${width}px`,
			height: '22px',
			borderRadius: '2px',
			py: '5px',
			px: '9px',
			boxSizing: 'border-box',
		}}
	>
		<Box
			data-testid={`preview-${color}`}
			sx={{
				backgroundColor: color,
				width: `${width - 18}px`,
				height: '12px',
				borderRadius: '2px',
			}}
		/>
	</Box>
);

ColorPreview.propTypes = {
	/**
	 * Represents the code of the color
	 */
	color: PropTypes.string,
	/**
	 * Represents width of the item
	 */
	width: PropTypes.number,
};

export default ColorPreview;
