import { Enums, CobbAngleTool, utilities, annotation, cursors } from '@cornerstonejs/tools';
import { getEnabledElement, triggerEvent, eventTarget } from '@cornerstonejs/core';

const { Events } = Enums;
const { triggerAnnotationRenderForViewportIds } = utilities;
const { removeAnnotation } = annotation.state;
const { resetElementCursor } = cursors.elementCursor;

class CobbAngleToolUpdated extends CobbAngleTool {
	_mouseUpCallback = (evt) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const { annotation: _annotation, viewportIdsToRender, newAnnotation, hasMoved } = this.editData;

		const { data } = _annotation;
		if (newAnnotation && !hasMoved) {
			// when user starts the drawing by click, and moving the mouse, instead
			// of click and drag
			return;
		}

		// If preventing new measurement means we are in the middle of an existing measurement
		// we shouldn't deactivate modify or draw
		if (this.angleStartedNotYetCompleted && data.handles.points.length < 4) {
			resetElementCursor(element);

			// adds the first point of the second line
			this.editData.handleIndex = data.handles.points.length;
			return;
		}

		this.angleStartedNotYetCompleted = false;
		data.handles.activeHandleIndex = null;

		this._deactivateModify(element);
		this._deactivateDraw(element);
		resetElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		if (this.isHandleOutsideImage && this.configuration.preventHandleOutsideImage) {
			removeAnnotation(_annotation.annotationUID);
		}

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		const eventType = Events.ANNOTATION_COMPLETED;

		triggerEvent(eventTarget, eventType, { annotation: _annotation });

		this.editData = null;
		this.isDrawing = false;
	};
}

export default CobbAngleToolUpdated;
