import axios from 'axios';

const getAssets = async ({ __config, orgId }) => {
	const response = await axios.get(`${__config.data_sources.fhir}/Organization/${orgId}/Asset`);

	if (!response?.status?.toString()?.startsWith(2)) {
		return [];
	}

	return response.data;
};

export default getAssets;
