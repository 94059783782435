import React from 'react';
import { ListSubheader } from '@mui/material';

const MobileListSubheader = ({ subheader }) => (
	<ListSubheader
		sx={{
			fontWeight: '400',
			fontSize: '14px',
			paddingLeft: '0',
			letterSpacing: '0.25px',
			height: '14px',
			lineHeight: '20px',
			paddingBottom: '30px',
			top: '-4px',
		}}
	>
		{subheader}
	</ListSubheader>
);

export default MobileListSubheader;
