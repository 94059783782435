export const extraHotkeys = [
	{
		menuLabel: 'Auto',
		hotkey: {
			altKey: false,
			ctrlKey: false,
			hotkey: 'F5',
			metaKey: false,
			shiftKey: false,
		},
	},
	{
		menuLabel: 'Sigmoid',
	},
];

export const PRESETS = [
	{
		menuLabel: 'Brain',
		windowWidth: 70,
		windowCenter: 30,
	},
	{
		menuLabel: 'Bone',
		windowWidth: 2000,
		windowCenter: 500,
	},
	{
		menuLabel: 'Abdomen',
		windowWidth: 400,
		windowCenter: 40,
	},
	{
		menuLabel: 'Soft Tissue',
		windowWidth: 350,
		windowCenter: 50,
	},
	{
		menuLabel: 'Liver',
		windowWidth: 160,
		windowCenter: 60,
	},
	{
		menuLabel: 'Lung',
		windowWidth: 1500,
		windowCenter: -600,
	},
	{
		menuLabel: 'Mediastinum',
		windowWidth: 500,
		windowCenter: 50,
	},
	{
		menuLabel: 'Stroke',
		windowWidth: 30,
		windowCenter: 30,
	},
	{
		menuLabel: 'CTA',
		windowWidth: 600,
		windowCenter: 170,
	},
	{
		menuLabel: 'Seeds',
		windowWidth: 400,
		windowCenter: 800,
	},
];
