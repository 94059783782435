import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ModalityPill from '@rs-ui/components/ModalityPill/ModalityPill';

const ModalityPlainVariant = ({ formHook, name }) => {
	const { t } = useTranslation('organization');

	return (
		<Controller
			control={formHook.control}
			name={name}
			render={({ field }) => (
				<Box
					sx={{
						width: '100%',
						height: '54px',
						border: '1px solid #595959',
						borderRadius: '4px',
						position: 'relative',
					}}
				>
					<Typography
						sx={{
							position: 'absolute',
							top: '-9px',
							left: '13px',
							fontSize: '12px',
							color: 'rgba(255, 255, 255, 0.7)',
							backgroundColor: '#272727',
							padding: '0 5px',
						}}
					>
						{t('Modality')}
					</Typography>
					{field.value && field.value.length > 0 && (
						<Box
							sx={{
								display: 'flex',
								columnGap: '4px',
								height: '100%',
								alignItems: 'center',
								padding: '0 16px',
							}}
						>
							{field.value.map((modality, index) => {
								if (modality) {
									return <ModalityPill key={index} value={modality.code} />;
								}
							})}
						</Box>
					)}

					<Typography
						sx={{
							position: 'absolute',
							bottom: '-20px',
							fontSize: '12px',
							color: 'rgba(255, 255, 255, 0.36)',
							backgroundColor: '#272727',
						}}
					>
						{t('Read-only')}
					</Typography>
				</Box>
			)}
		/>
	);
};

export default ModalityPlainVariant;
