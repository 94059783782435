import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import LastPageIcon from '@mui/icons-material/LastPage';

const Header = ({ onClose, isOmega }) => (
	<Box
		sx={{
			position: 'relative',
			padding: '26px 20px 20px',
			display: 'flex',
			alignItems: 'center',
			backgroundColor: isOmega ? '#272727' : '#F9FAFC',
			borderBottom: isOmega ? '1px solid rgba(196, 196, 196, 0.40)' : 'none',
		}}
	>
		<RateReviewOutlinedIcon sx={{ fontSize: '30px', color: '#4D79EA' }} />
		<Typography
			sx={{
				marginLeft: '20px',
				fontFamily: 'Inter',
				fontSize: '24px',
				fontWeight: 500,
				lineHeight: '24px',
				color: isOmega ? '#FFF' : 'rgba(0, 0, 0, 0.90)',
			}}
		>
			Review
		</Typography>
		<IconButton
			data-testid="header_closeButton"
			sx={{ position: 'absolute', top: '6px', right: '2px' }}
			onClick={onClose}
		>
			<LastPageIcon sx={{ color: isOmega ? 'rgba(255, 255, 255, 0.60)' : 'rgba(0, 0, 0, 0.60)' }} />
		</IconButton>
	</Box>
);

export default Header;
