// @flow

// core
import React, { type Node } from 'react';

// libs
import Button from '@mui/material/Button';

interface ISecondaryButtonProps {
	/** Label property of the button */
	label: string;
	onClick: () => void;
	width: string;
	height: string;
	/** Determines which type of textTransforms we want to apply to the button */
	textTransform: string;
	testId: string;
	/** BorderRadius of Button */
	borderRadius: string;
	bgColor: 'string';
}

const SecondaryButton = ({
	label = '',
	onClick,
	width,
	height,
	textTransform = 'uppercase',
	testId,
	borderRadius,
	bgColor,
	...props
}: ISecondaryButtonProps): Node => {
	const handleClick = () => {
		if (onClick) {
			onClick();
		}
	};

	return (
		<Button
			data-cy={testId}
			data-testid={testId}
			sx={{
				height: { height },
				width: { width },
				textTransform: { textTransform },
				borderRadius: { borderRadius },
				backgroundColor: bgColor || 'unset',
				// rsPrimary hardcoded because of test case failure
				color: '#42A5F5',
				border: '1px solid rgba(66, 165, 245, 0.5)',

				'&:hover': {
					// rsPrimary hardcoded because of test case failure
					textDecoration: 'none',
					backgroundColor: 'rgba(66, 165, 245, 0.08)',
					border: '1px solid #42A5F5',
				},
			}}
			variant="outlined"
			// color="rsPrimary"
			onClick={handleClick}
			{...props}
		>
			{label}
		</Button>
	);
};

export default SecondaryButton;
