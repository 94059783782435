import React, { createContext, createRef, useContext, useEffect, useMemo, useState } from 'react';
import Slide from '@mui/material/Slide';
import AccountDetailPopOver from '../components/CRM/Accounts/AccountDetailPopOver';
import NewAccountPopOver from '../components/CRM/Accounts/AccountDetailPopOver/NewAccountPopOver';
import RoleEditor from '../components/CRM/AdminSettings/RoleEditor';
import RolePreview from '../components/CRM/AdminSettings/RolePreview';
import UserPreview from '../components/CRM/AdminSettings/UserPreview';
import AddUser from '../components/CRM/AdminSettings/UserPreview/AddUser';
import CaseDetailPopOver from '../components/CRM/Cases/CaseDetailPopOver';
import NewCaseDetailPopOver from '../components/CRM/Cases/CaseDetailPopOver/NewCaseDetailPopOver';
import Box from '@mui/material/Box';
import TeamEditor from '../components/CRM/AdminSettings/TeamEditor';
import OrganizationSteps from '../components/CRM/Accounts/AccountDetailPopOver/OrganizationSteps/index';

export const CRMContext = createContext({});

const CRMContextProvider = ({ children }) => {
	const [showDetails, _toggleDetails] = useState(false);
	const [showNewDetails, toggleNewDetails] = useState(null);
	const [selectedOKR, setSelectedOKR] = useState();
	const [selectedObjective, setSelectedObjective] = useState();
	const [objectiveText, setObjectiveText] = useState();
	const editorRef = createRef();
	const rolePreviewRef = createRef();
	const userPreviewRef = createRef();
	const addUserRef = createRef();
	const teamEditorRef = createRef();
	const currentDate = new Date();
	const [quarterGB, setQuarterGB] = useState(
		`Q${Math.floor((currentDate.getMonth() + 3) / 3)} - ${currentDate.getFullYear()}`
	);
	const [widgetData, setWidgetData] = useState(null);
	const [tempRoles, setRoles] = useState([]);
	const [isChatOpen, setIsChatOpen] = useState(false);
	const [isRefresh, setIsRefresh] = useState(false);
	const [isRoleGridLoading, toggleRoleGridLoading] = useState(false);
	const [isReloadUserGrid, toggleReloadUserGrid] = useState(false);
	const [isCloseOkr, setCloseOkr] = useState(false);
	const [closeKeyResult, setCloseKeyResult] = useState({});
	const [selectedFeature, setSelectedFeature] = useState(null);
	const [currentFeatures, setCurrentFeatures] = useState(null);
	const [cacheKey, setCacheKey] = useState(null);

	const toggleDetails = e => {
		setIsChatOpen(false);
		_toggleDetails(e);
	};

	const permissionsDefinitions = [
		[
			{ name: 'Permission', disabled: false },
			{ name: 'Permission', disabled: false },
			{ name: 'Permission', disabled: true },
		],
		[
			{ name: 'Permission', disabled: false },
			{ name: 'Permission', disabled: false },
			{ name: 'Permission', disabled: true },
		],
		[
			{ name: 'Permission', disabled: false },
			{ name: 'Permission', disabled: false },
			{ name: 'Permission', disabled: true },
		],
		[
			{ name: 'Permission', disabled: false },
			{ name: 'Permission', disabled: false },
			{ name: 'Permission', disabled: true },
		],
		[
			{ name: 'Permission', disabled: false },
			{ name: 'Permission', disabled: false },
			{ name: 'Permission', disabled: true },
		],
		[
			{ name: 'Permission', disabled: false },
			{ name: 'Permission', disabled: false },
			{ name: 'Permission', disabled: true },
		],
		[
			{ name: 'Permission', disabled: false },
			{ name: 'Permission', disabled: false },
			{ name: 'Permission', disabled: true },
		],
		[
			{ name: 'Permission', disabled: false },
			{ name: 'Permission', disabled: false },
			{ name: 'Permission', disabled: true },
		],
	];
	const toggleWidget = (tag, data) => {
		try {
			let ref;
			switch (tag) {
				case 'editRole':
					ref = editorRef.current;
					break;
				case 'editTeam':
					ref = teamEditorRef.current;
					break;
				case 'newRole':
					ref = rolePreviewRef.current;
					break;
				case 'userPreview':
					ref = userPreviewRef.current;
					break;
				case 'addUser':
					ref = addUserRef.current;
					break;

				default:
					break;
			}
			setWidgetData({ tag, data });
			ref[data ? 'open' : 'close'] && ref[data ? 'open' : 'close'](data);
		} catch (error) {
			console.error(error);
		}
	};

	const roles = useMemo(() => {
		// for each role, add extra permissions if not available
		if (!tempRoles) return [];
		const extraPermissionCategories = ['OKR', 'Billing Report'];
		const newRoles = [];
		for (const role of tempRoles) {
			// add other permission categories if not available
			if (role?.Permission) {
				const xtraPerm = extraPermissionCategories.map(cat => ({
					Read: false,
					Add: false,
					Delete: false,
					Category: cat,
					Edit: false,
				}));
				const Permission = _.uniqBy(_.concat(role.Permission, xtraPerm), 'Category');
				newRoles.push({
					...role,
					Permission,
				});
			}
		}
		return newRoles;
	}, [tempRoles]);

	const value = {
		showDetails,
		toggleDetails,
		showNewDetails,
		toggleNewDetails,
		toggleWidget,
		selectedOKR,
		setSelectedOKR,
		objectiveText,
		setObjectiveText,
		quarterGB,
		setQuarterGB,
		selectedObjective,
		setSelectedObjective,
		widgetData,
		setWidgetData,
		permissionsDefinitions,
		roles,
		setRoles,
		isChatOpen,
		setIsChatOpen,
		isRefresh,
		setIsRefresh,
		isRoleGridLoading,
		toggleRoleGridLoading,
		isReloadUserGrid,
		toggleReloadUserGrid,
		isCloseOkr,
		setCloseOkr,
		closeKeyResult,
		setCloseKeyResult,
		selectedFeature,
		setSelectedFeature,
		currentFeatures,
		setCurrentFeatures,
		cacheKey,
		setCacheKey,
	};

	const PopoverContent = useMemo(() => {
		try {
			let component;
			if (showNewDetails) {
				const paths = showNewDetails?.path.split('/');
				if (paths.includes('account')) {
					component = <NewAccountPopOver />;
				} else if (paths.includes('case')) {
					component = <NewCaseDetailPopOver />;
				}
			} else {
				const { data, tag, fullData, setFullData } = showDetails;
				switch (tag) {
					case 'cases':
						component = (
							<CaseDetailPopOver fullData={fullData} setFullData={setFullData} summaryData={data} />
						);
						break;

					case 'accounts':
						component = (
							<AccountDetailPopOver fullData={fullData} setFullData={setFullData} summaryData={data} />
						);
						break;

					case 'addOrganization':
						component = (
							<OrganizationSteps
								isSimpleSidebar
								accountData={data.accountData}
								fetchAccountData={data.fetchAccountData}
								organizationData={data.organizationData}
								setOrganizationData={data.setOrganizationData}
								setStep={data.setStep}
								step={data.step}
							/>
						);
						break;

					case 'setDeviceStationModal':
						component = (
							<OrganizationSteps
								isSimpleSidebar
								accountData={data.accountData}
								organizationData={data.organizationData}
								setOrganizationData={data.setOrganizationData}
								setStep={data.setStep}
								sidebarMode={data.sidebarMode}
								step={data.step}
							/>
						);
						break;

					default:
						break;
				}
			}
			return component;
		} catch (error) {
			return null;
		}
	}, [showDetails, showNewDetails]);

	useEffect(() => {
		if (showDetails || showNewDetails || isRefresh) {
			document.body.scrollIntoView({ behavior: 'smooth' }, 500);
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [showDetails, showNewDetails, isRefresh]);

	return (
		<CRMContext.Provider value={value}>
			<Box
				sx={{
					display: 'flex',
					gap: '10px',
					overflow: 'hidden',
					height: '100vh',
				}}
			>
				<Box
					sx={{
						background: '#F9FAFC',
						flex: 3,
					}}
				>
					{children}
				</Box>
				<Slide mountOnEnter unmountOnExit direction="left" in={showDetails || showNewDetails || isRefresh}>
					<Box sx={{ flex: '0 0 538px' }}>{PopoverContent}</Box>
				</Slide>
				{widgetData?.data && <Box sx={{ width: '574px' }} />}
			</Box>
			<TeamEditor ref={teamEditorRef} />
			<RoleEditor ref={editorRef} />
			<RolePreview ref={rolePreviewRef} />
			<UserPreview ref={userPreviewRef} />
			<AddUser ref={addUserRef} />
		</CRMContext.Provider>
	);
};

export const useCrmContext = () => {
	const crmContext = useContext(CRMContext);
	const {
		showDetails,
		toggleDetails,
		showNewDetails,
		toggleNewDetails,
		toggleWidget,
		selectedOKR,
		setSelectedOKR,
		objectiveText,
		setObjectiveText,
		quarterGB,
		setQuarterGB,
		selectedObjective,
		setSelectedObjective,
		widgetData,
		setWidgetData,
		permissionsDefinitions,
		roles,
		setRoles,
		isChatOpen,
		setIsChatOpen,
		isRefresh,
		setIsRefresh,
		isRoleGridLoading,
		toggleRoleGridLoading,
		isReloadUserGrid,
		toggleReloadUserGrid,
		isCloseOkr,
		setCloseOkr,
		closeKeyResult,
		setCloseKeyResult,
		selectedFeature,
		setSelectedFeature,
		currentFeatures,
		setCurrentFeatures,
		cacheKey,
		setCacheKey,
	} = crmContext;

	return {
		showDetails,
		toggleDetails,
		showNewDetails,
		toggleNewDetails,
		toggleWidget,
		selectedOKR,
		setSelectedOKR,
		objectiveText,
		setObjectiveText,
		quarterGB,
		setQuarterGB,
		selectedObjective,
		setSelectedObjective,
		widgetData,
		setWidgetData,
		permissionsDefinitions,
		roles,
		setRoles,
		isChatOpen,
		setIsChatOpen,
		isRefresh,
		setIsRefresh,
		isRoleGridLoading,
		toggleRoleGridLoading,
		isReloadUserGrid,
		toggleReloadUserGrid,
		isCloseOkr,
		setCloseOkr,
		closeKeyResult,
		setCloseKeyResult,
		selectedFeature,
		setSelectedFeature,
		currentFeatures,
		setCurrentFeatures,
		cacheKey,
		setCacheKey,
	};
};

export default CRMContextProvider;
