import React from 'react';

import { Box, Switch, Typography } from '@mui/material';

import { Controller } from 'react-hook-form';

import createStyles from './form-styles';

const FormSwitch = ({ label, control, name, stylesBox }) => {
	const classes = createStyles();

	return (
		<Box style={stylesBox} sx={classes.switchBox}>
			<Controller
				control={control}
				name={name}
				render={({ field }) => (
					<Switch {...field} checked={field.value} color="rsPrimary" sx={classes.switch} />
				)}
			/>
			<Typography sx={classes.switchLabel}>{label}</Typography>
		</Box>
	);
};

export default FormSwitch;
