import getSeriesOrientationPlane from './getSeriesOrientationPlane';
import { sortSeriesFrames } from './sortByDistance/sortFrames';

/**
 * Sort Image Slices for CT, PT. MR Modalities
 * Returns an array of imageIds for instances in the Series.
 */

export default function SortImageSlices(instances, wonIvEnhancedcurvedsort, wonIvRefactorsorting) {
	const NumberOfFramesTag = '00280008';
	const ModalityTag = '00080060';
	const ImagePositionPatientTag = '00200032';
	const ImageInstanceNumberTag = '00200013';

	const imagePlaneType = {
		ptUnknown: 0,
		ptOblique: 1,
		ptSagittal: 2,
		ptCoronal: 3,
		ptAxial: 4,
		ptMixed: 5,
	};

	let seriesPlaneType;
	let _instances = [];

	const getOrderOfSorting = instance => {
		const PatientPositionTag = '00185100';
		let sortOrder = 'ascending';

		const PatientPosition = instance[PatientPositionTag]?.Value?.[0];
		if (PatientPosition) {
			switch (PatientPosition) {
				case 'HFS':
				case 'HFDR':
				case 'FFDR':
				case 'LFS':
				case 'RFS':
				case 'HFP':
				case 'AFDR':
				case 'AFDL':
					break;

				case 'FFS':
				case 'FFP':
				case 'HFDL':
				case 'FFDL':
				case 'LFP':
				case 'RFP':
				case 'PFDR':
				case 'PFDL':
					sortOrder = 'descending';
					break;

				default:
					break;
			}
		}
		return sortOrder;
	};

	const sortInstancesByPlan = (instanceList, imagePlanType) => {
		let sortedInstances;

		let checkNaNFirstInstance;
		let checkNaNSecondInstance;
		let invalidImageObject = false;

		const sortOrder = getOrderOfSorting(instanceList[0]);

		switch (imagePlanType) {
			case imagePlaneType.ptAxial:
				if (wonIvEnhancedcurvedsort) {
					sortedInstances = sortSeriesFrames(instanceList, 'Axial');
				} else {
					sortedInstances = instanceList.sort((a, b) => {
						const firstInstanceAxial = parseFloat(a[ImagePositionPatientTag]?.Value?.[2]);
						checkNaNFirstInstance = isNaN(firstInstanceAxial);

						const secondInstanceAxial = parseFloat(b[ImagePositionPatientTag]?.Value?.[2]);

						checkNaNSecondInstance = isNaN(secondInstanceAxial);

						if (!checkNaNFirstInstance && !checkNaNSecondInstance) {
							// Perfrm Sorting
							if (sortOrder === 'ascending') {
								return firstInstanceAxial - secondInstanceAxial;
							}
							return secondInstanceAxial - firstInstanceAxial;
						} // Invalid ImageOrioentationPatient

						invalidImageObject = true;
					});

					// Sort by Image Number ImageOrioentationPatient
					if (invalidImageObject) {
						sortedInstances = instanceList.sort((a, b) => {
							const firstInstanceAxial = parseInt(a[ImageInstanceNumberTag]?.Value?.[0]);
							checkNaNFirstInstance = isNaN(firstInstanceAxial);

							const secondInstanceAxial = parseInt(b[ImageInstanceNumberTag]?.Value?.[0]);
							checkNaNSecondInstance = isNaN(secondInstanceAxial);

							if (!checkNaNFirstInstance && !checkNaNSecondInstance) {
								// Perfrm Sorting
								if (sortOrder === 'ascending') {
									return firstInstanceAxial - secondInstanceAxial;
								}
								return secondInstanceAxial - firstInstanceAxial;
							}
							// Else we are not perfoming sorting.
						});
					}
				}

				break;

			// Ascending Order
			case imagePlaneType.ptSagittal:
				if (wonIvRefactorsorting) {
					sortedInstances = sortSeriesFrames(instanceList, 'Sagittal');
				} else {
					sortedInstances = instanceList.sort((a, b) => {
						const firstInstanceSagittal = parseFloat(a[ImagePositionPatientTag]?.Value?.[0]);

						const secondInstanceSagttal = parseFloat(b[ImagePositionPatientTag]?.Value?.[0]);

						checkNaNFirstInstance = isNaN(firstInstanceSagittal);
						checkNaNSecondInstance = isNaN(secondInstanceSagttal);

						if (!checkNaNFirstInstance && !checkNaNSecondInstance) {
							// Perfrm Sorting
							return firstInstanceSagittal - secondInstanceSagttal;
						}
						invalidImageObject = true;
					});

					// Sort by Image Number due ImageOrioentationPatient
					if (invalidImageObject) {
						sortedInstances = instanceList.sort((a, b) => {
							const firstInstanceSagittal = parseInt(a[ImageInstanceNumberTag]?.Value?.[0]);
							checkNaNFirstInstance = isNaN(firstInstanceSagittal);

							const secondInstanceSagttal = parseInt(b[ImageInstanceNumberTag]?.Value?.[0]);
							checkNaNSecondInstance = isNaN(secondInstanceSagttal);

							if (!checkNaNFirstInstance && !checkNaNSecondInstance) {
								// Perfrm Sorting
								return firstInstanceSagittal - secondInstanceSagttal;
							}
							// Else we are not perfoming sorting.
						});
					}
				}

				break;

			case imagePlaneType.ptCoronal:
				if (wonIvRefactorsorting) {
					sortedInstances = sortSeriesFrames(instanceList, 'Coronal');
				} else {
					sortedInstances = instanceList.sort((a, b) => {
						const firstInstanceCoronal = parseFloat(a[ImagePositionPatientTag]?.Value?.[1]);
						const secondInstanceCoronal = parseFloat(b[ImagePositionPatientTag]?.Value?.[1]);

						checkNaNFirstInstance = isNaN(firstInstanceCoronal);
						checkNaNSecondInstance = isNaN(secondInstanceCoronal);

						if (!checkNaNFirstInstance && !checkNaNSecondInstance) {
							// Perfrm Sorting
							if (sortOrder === 'ascending') {
								return firstInstanceCoronal - secondInstanceCoronal;
							}
							return secondInstanceCoronal - firstInstanceCoronal;
						}
						invalidImageObject = true;
					});
					// Sort by Image Number due ImageOrioentationPatient
					if (invalidImageObject) {
						sortedInstances = instanceList.sort((a, b) => {
							const firstInstancecCoronal = parseInt(a[ImageInstanceNumberTag]?.Value?.[0]);
							checkNaNFirstInstance = isNaN(firstInstancecCoronal);

							const secondInstanceCoronal = parseInt(b[ImageInstanceNumberTag]?.Value?.[0]);
							checkNaNSecondInstance = isNaN(secondInstanceCoronal);

							if (!checkNaNFirstInstance && !checkNaNSecondInstance) {
								// Perfrm Sorting
								if (sortOrder === 'ascending') {
									return firstInstancecCoronal - secondInstanceCoronal;
								}
								return secondInstanceCoronal - firstInstancecCoronal;
							}
							// Else we are not perfoming sorting.
						});
					}
				}

				break;

			case imagePlaneType.ptMixed:
			case imagePlaneType.ptOblique:
				sortedInstances = instanceList.sort((a, b) => {
					const firstInstance = parseInt(a[ImageInstanceNumberTag]?.Value?.[0]);

					const secondInstance = parseInt(b[ImageInstanceNumberTag]?.Value?.[0]);

					checkNaNFirstInstance = isNaN(firstInstance);

					checkNaNSecondInstance = isNaN(secondInstance);

					if (!checkNaNFirstInstance && !checkNaNSecondInstance) {
						return firstInstance - secondInstance;
					}
				});
				break;

			default:
				sortedInstances = instanceList;
				break;
		}

		return sortedInstances;
	};

	const getCapturedDateTime = instance => {
		const AcquisitionDateTime = instance['0008002a']?.Value?.at(0);
		const AcquisitionDate = instance['00080022']?.Value?.at(0);
		const AcquisitionTime = instance['00080032']?.Value?.at(0);
		const ContentDate = instance['00080023']?.Value?.at(0);
		const ContentTime = instance['00080033']?.Value?.at(0);
		const InstanceNumber = instance[ImageInstanceNumberTag]?.Value?.at(0);

		return {
			AcquisitionDateTime,
			AcquisitionDate,
			AcquisitionTime,
			ContentDate,
			ContentTime,
			InstanceNumber,
		};
	};

	const SortByInstanceNumberAscending = instances => {
		const sortedInstances = instances.sort((a, b) => {
			const firstInstancecNumber = parseInt(a[ImageInstanceNumberTag]?.Value?.[0]);
			const checkNaNFirstInstanceNumber = isNaN(firstInstancecNumber);

			const secondInstanceNumber = parseInt(b[ImageInstanceNumberTag]?.Value?.[0]);
			const checkNaNSecondInstanceNumber = isNaN(secondInstanceNumber);

			if (!checkNaNFirstInstanceNumber && !checkNaNSecondInstanceNumber) {
				return firstInstancecNumber - secondInstanceNumber;
			}
		});

		return sortedInstances;
	};

	_instances = SortByInstanceNumberAscending(instances);

	const Modality = instances?.[0]?.[ModalityTag]?.Value?.[0];

	if (Modality === 'CT' || Modality === 'PT' || Modality === 'MR' || Modality === 'MG') {
		// MultiFrame Images has own sorting
		const isMultiframe = parseInt(instances[0][NumberOfFramesTag]?.Value[0]) > 1;
		if (isMultiframe) {
			_instances = instances;
		} else {
			let instancePlan;
			seriesPlaneType = getSeriesOrientationPlane(instances[0]);
			if (seriesPlaneType !== imagePlaneType.ptOblique) {
				for (const element of instances) {
					instancePlan = getSeriesOrientationPlane(element);
					if (instancePlan !== seriesPlaneType) {
						seriesPlaneType = imagePlaneType.ptMixed;
						break;
					}
				}
			}

			_instances = sortInstancesByPlan(instances, seriesPlaneType);
		}
	} else if (Modality === 'US' || Modality === 'IVUS' || Modality === 'SC') {
		const sortedInstances = instances.sort((a, b) => {
			const firstDateTime = getCapturedDateTime(a);
			const secondDateTime = getCapturedDateTime(b);

			// Sort order if equal or no value
			const propertiesToCompare = [
				'AcquisitionDate',
				'AcquisitionTime',
				'AcquisitionDateTime',
				'ContentDate',
				'ContentTime',
				'InstanceNumber',
			];

			for (const prop of propertiesToCompare) {
				const firstValue = firstDateTime[prop];
				const secondValue = secondDateTime[prop];

				if (!isNaN(firstValue) && !isNaN(secondValue)) {
					const diff = firstValue - secondValue;
					if (diff !== 0) {
						return diff;
					}
				}
			}

			return 0;
		});

		_instances = sortedInstances;
	}

	return _instances;
}
