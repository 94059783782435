import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import GlobalImportOverview from '@worklist-2/ui/src/views/ImportView/GlobalImportOverview';

import { useAppModeContext } from '@worklist-2/core/src';

const ImportTabContext = createContext({
	searchData: [],
	searchTab: 'All',
});

export const ImportTabProvider = ({ children }) => {
	const [importTabs, setImportTabs] = useState([]);
	const [selectedTab, setSelectedTab] = useState(null);
	const [sideBarIsOpen, toggleSideBarIsOpen] = useState(false);
	const [showImportWidget, setShowImportWidget] = useState(false);
	const [uploadSuccess, setUploadSuccess] = useState();
	const location = useLocation();
	const [filesUploading, setFilesUploading] = useState([]);
	const [filesDownloading, setFilesDownloading] = useState([]);
	const [showFileUploadWindow, setShowFileUploadWindow] = useState(false);
	const [showFileDownloadWindow, setShowFileDownloadWindow] = useState(false);
	const [reloadDocumentSection, setReloadDocumentSection] = useState(false);
	const [recentUploadedQuantity, setRecentUploadedQuantity] = useState(0);
	const [documentPayload, setDocumentPayload] = useState(null);
	const { appMode } = useAppModeContext();
	const [source, setSource] = useState('');
	const acceptExtFiles = [
		'89504e47' /*png*/,
		'25504446' /*pdf*/,
		'66747970' /*mp4*/,
		'ffd8ffe0' /*jpg*/,
		'ffd8ffe1' /*jpg*/,
		'ffd8ffe2' /*jpg*/,
		'ffd8ffdb' /*jpg*/,
		'ffd8ffee' /*jpg*/,
		'424dc6fa' /*bmp*/,
		'4449434d' /*dicom meta-header*/,
		'80' /*dicom little endian without meta-header*/,
		'08' /*dicom big endian without meta-header*/,
	];

	useEffect(() => {
		if (appMode !== 'patientPortal') {
			setShowImportWidget(!location.pathname.includes('import') && importTabs.length > 0);
		}
	}, [location, importTabs]);

	const updateImportTabByIndex = (tab, index) => {
		setImportTabs(prevState => {
			const val = prevState;
			val[index] = tab;
			return [...val];
		});
	};

	const value = useMemo(
		() => ({
			importTabs,
			selectedTab,
			updateImportTabs: setImportTabs,
			updateImportSelectedTab: setSelectedTab,
			toggleSideBarIsOpen,
			setShowImportWidget,
			filesUploading,
			setFilesUploading,
			filesDownloading,
			setFilesDownloading,
			showFileUploadWindow,
			setShowFileUploadWindow,
			showFileDownloadWindow,
			setShowFileDownloadWindow,
			sideBarIsOpen,
			reloadDocumentSection,
			setReloadDocumentSection,
			acceptExtFiles,
			uploadSuccess,
			setUploadSuccess,
			documentPayload,
			setDocumentPayload,
			updateImportTabByIndex,
			source,
			setSource,
			recentUploadedQuantity,
			setRecentUploadedQuantity,
		}),
		[
			importTabs,
			selectedTab,
			filesUploading,
			showFileUploadWindow,
			sideBarIsOpen,
			reloadDocumentSection,
			acceptExtFiles,
			uploadSuccess,
			documentPayload,
			source,
		]
	);

	return (
		<ImportTabContext.Provider value={value}>
			{children}
			{showImportWidget && <GlobalImportOverview margin={sideBarIsOpen ? 300 : 90} tabs={importTabs} />}
		</ImportTabContext.Provider>
	);
};

export const useImportTabContext = () => useContext(ImportTabContext);
export default ImportTabProvider;
