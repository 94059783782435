import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { Box, Typography } from '@mui/material';

import KanbanListItem from './KanbanListItem';

import { Badge } from '../../../../views/CrmView/styles';

import createStyles from './styles';

const KanbanColumn = ({ status, cases, assignedTeams, draggableCase }) => {
	const classes = createStyles(Boolean(draggableCase?.draggableId));

	return (
		<Droppable droppableId={status}>
			{provided => (
				<Box ref={provided.innerRef} sx={classes.wrapper} {...provided.droppableProps}>
					<Box sx={classes.title}>
						<Typography sx={classes.statusText}>{status}</Typography>
						<Badge>
							<span>{cases?.length}</span>
						</Badge>
					</Box>
					<Box className="invisible-scrollbar" sx={classes.columnBody}>
						{!draggableCase?.draggableId || status === draggableCase?.status ? (
							cases?.map((caseItem, index) => (
								<KanbanListItem
									key={caseItem.CaseId}
									assignedTeams={assignedTeams}
									caseItem={caseItem}
									index={index}
								/>
							))
						) : (
							<Box sx={classes.columnDrag}>
								<Typography sx={classes.dragText}>Drop Here to Change Status</Typography>
							</Box>
						)}
						{provided.placeholder}
					</Box>
				</Box>
			)}
		</Droppable>
	);
};

export default KanbanColumn;
