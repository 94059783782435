export default () => {
	const selected = {
		'&:hover': {
			border: '1px solid rgba(0, 0, 0, 0.2)',
			outline: 'none',
		},
		'&.Mui-focused': {
			border: '1px solid #42A5F5',
		},
		'.MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
	};

	const fontStyle = {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '14px',
		lineHeight: '17px',

		color: 'rgba(0, 0, 0, 0.6)',
	};

	const inputSelected = {
		...selected,
		...fontStyle,
	};

	return {
		switchBox: {
			flex: '0 1 50%',
			display: 'flex',
			alignItems: 'center',
		},
		switch: {
			left: '-10px',
		},
		switchLabel: {
			position: 'relative',
			left: '-10px',

			fontFamily: 'Inter',
			fontStyle: 'normal',
			fontWeight: '500',
			fontSize: '12px',
			lineHeight: '15px',
			color: 'rgba(0, 0, 0, 0.6)',
		},
		singleBox: {
			marginTop: '8px',
		},
		labelBox: {
			display: 'flex',
		},
		label: {
			fontFamily: 'Inter',
			fontStyle: 'normal',
			fontWeight: '500',
			fontSize: '12px',
			lineHeight: '15px',
			color: 'rgba(0, 0, 0, 0.6)',
			marginBottom: '5px',
		},
		inputShort: {
			...inputSelected,
			width: '219px',
			height: '40px',
			padding: '5px 10px',
			borderRadius: '10px',
			border: '1px solid rgba(0, 0, 0, 0.05)',
			background: 'rgba(196, 196, 196, 0.15)',
		},
		inputLong: {
			...inputSelected,
			width: '100%',
			height: '40px',
			padding: '5px 10px',
			borderRadius: '10px',
			border: '1px solid rgba(0, 0, 0, 0.05)',
			background: 'rgba(196, 196, 196, 0.15)',
		},
		textFieldBox: {
			padding: '5px 10px',
		},
		textField: {
			...inputSelected,
			background: 'rgba(196, 196, 196, 0.15)',
			border: '1px solid rgba(0, 0, 0, 0.05)',
			borderRadius: '10px',
		},
	};
};
