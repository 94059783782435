import React from 'react';
import { Box } from '@mui/system';
import { IconWrapper, ResultContent, resultsStyle } from './helpStyles';
import LiveSupportButton from './LiveSupportButton';
import { OctagonCheck } from '../../assets/icons/octagon_check.svg';

const SubmitQuestionResult = ({ setView }) => (
	<>
		<Box
			sx={{
				...resultsStyle,
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<ResultContent className="flex-col-sm" style={{ flex: 1 }}>
				<IconWrapper>
					<OctagonCheck color="#42a5f5" />
				</IconWrapper>
				<h3>Your question has been submitted.</h3>
				<p className="hint">Our support team will get back to you as soon as possible.</p>
			</ResultContent>
		</Box>
		<LiveSupportButton onClick={() => setView('support')} />
	</>
);

export default SubmitQuestionResult;
