import React, { useEffect, useState } from 'react';

const CurrentOrganizationContext = React.createContext({});

const useCurrentOrganizationContext = () => React.useContext(CurrentOrganizationContext);

const CurrentOrganizationContextProvider = ({ children, selectOrganization }) => {
	const [currentOrganizationID, setOrganizationID] = useState('');
	const [hoveredOrganizationID, setHoveredOrganizationID] = useState('');

	useEffect(() => {
		selectOrganization(currentOrganizationID);
	}, [currentOrganizationID]);

	return (
		<CurrentOrganizationContext.Provider
			value={{
				currentOrganizationID,
				setOrganizationID,
				hoveredOrganizationID,
				setHoveredOrganizationID,
			}}
		>
			{children}
		</CurrentOrganizationContext.Provider>
	);
};

export default CurrentOrganizationContextProvider;
export { useCurrentOrganizationContext };
