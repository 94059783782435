import { useCallback } from 'react';

const useDelayedRun = () =>
	useCallback(
		(fn, timeout) => () => {
			setTimeout(() => {
				if (fn && typeof fn === 'function') fn();
			}, timeout);
		},
		[]
	);
export default useDelayedRun;
