export const marketplaceCategoryOptions = [
	{
		hash: '#all',
		fullCategory: 'All',
	},
	{
		hash: '#visualization',
		fullCategory: 'Advanced Visualization',
	},
	{
		hash: '#reporting',
		fullCategory: 'Reporting',
	},
	{
		hash: '#workflow',
		fullCategory: 'Workflow Optimization',
	},
	{
		hash: '#ai',
		fullCategory: 'Clinical Support (AI)',
	},
];

export const getSearchAllBody = searchText => ({
	resourceType: 'Bundle',
	type: 'batch',
	entry: [
		{
			fullUrl: 'urn:uuid:61ebe359-bfdc-4613-8bf2-c5e300945f0a',
			request: {
				method: 'GET',
				url: `ElkImagingStudyWorklist?_content=${searchText}&_count=50`,
			},
		},
		{
			fullUrl: 'urn:uuid:61ebe359-bfdc-4613-8bf2-c5e300945f0b',
			request: {
				method: 'GET',
				url: `ElkPatient?_content=${searchText}&_count=50`,
			},
		},
		{
			fullUrl: 'urn:uuid:61ebe359-bfdc-4613-8bf2-c5e300945f0c',
			request: {
				method: 'GET',
				url: `ElkServiceRequest?_content=${searchText}&_count=50`,
			},
		},
		{
			fullUrl: 'urn:uuid:61ebe359-bfdc-4613-8bf2-c5e300945f0d',
			request: {
				method: 'GET',
				url: `ElkEncounter?_content=${searchText}&_count=50`,
			},
		},
		{
			fullUrl: 'urn:uuid:61ebe359-bfdc-4613-8bf2-c5e300945f0e',
			request: {
				method: 'GET',
				url: `ElkOrganization?_content=${searchText}&_count=50`,
			},
		},
		{
			fullUrl: 'urn:uuid:61ebe359-bfdc-4613-8bf2-c5e300945f0f',
			request: {
				method: 'GET',
				url: `ElkPractitioner?_content=${searchText}&_count=50`,
			},
		},
		{
			fullUrl: 'urn:uuid:61ebe359-bfdc-4613-8bf2-c5e300945f0g',
			request: {
				method: 'GET',
				url: `ElkHelpCenter?_content=${searchText}&_count=15&appname=oai`,
			},
		},
	],
});

export const getPatientPortalSearchAllBody = searchText => ({
	resourceType: 'Bundle',
	type: 'batch',
	entry: [
		{
			request: {
				method: 'GET',
				url: `ElkStudy?Content=${searchText}`,
			},
		},
		{
			request: {
				method: 'GET',
				url: `ElkStudy?IsShareWithMe=true&Content=${searchText}`,
			},
		},
		{
			request: {
				method: 'GET',
				url: `ElkHelpCenter?Content=${searchText}`,
			},
		},
	],
});
