import { useEffect } from 'react';
import { useImageViewerView3DContext } from '../../contexts/ImageViewerView3DContext';

const useAddHotkeys = (hotkeys) => {
	const { hotkeysManagerRef } = useImageViewerView3DContext();

	useEffect(() => {
		hotkeys.forEach(({ ctrlKey, altKey, metaKey, shiftKey, code, callback }) => {
			if (ctrlKey && shiftKey) {
				hotkeysManagerRef.current.hotkeys.ctrlShiftKey[code] = callback;

				return;
			}

			if (ctrlKey) {
				hotkeysManagerRef.current.hotkeys.ctrlKey[code] = callback;

				return;
			}

			if (altKey) {
				hotkeysManagerRef.current.hotkeys.altKey[code] = callback;

				return;
			}

			if (metaKey) {
				hotkeysManagerRef.current.hotkeys.metaKey[code] = callback;

				return;
			}

			if (shiftKey) {
				hotkeysManagerRef.current.hotkeys.shiftKey[code] = callback;

				return;
			}

			hotkeysManagerRef.current.hotkeys[code] = callback;
		});

		return () => {
			hotkeys.forEach(({ ctrlKey, altKey, metaKey, shiftKey, code }) => {
				if (ctrlKey && shiftKey) {
					hotkeysManagerRef.current.hotkeys.ctrlShiftKey[code] = undefined;
	
					return;
				}

				if (ctrlKey) {
					hotkeysManagerRef.current.hotkeys.ctrlKey[code] = undefined;

					return;
				}

				if (altKey) {
					hotkeysManagerRef.current.hotkeys.altKey[code] = undefined;

					return;
				}

				if (metaKey) {
					hotkeysManagerRef.current.hotkeys.metaKey[code] = undefined;

					return;
				}

				if (shiftKey) {
					hotkeysManagerRef.current.hotkeys.shiftKey[code] = undefined;

					return;
				}

				hotkeysManagerRef.current.hotkeys[code] = undefined;
			});
		};
	}, [hotkeys]);
};

export default useAddHotkeys;
