// @flow

// core
import React, { type Node } from 'react';

// libs
import { Stack } from '@mui/material';
import { type ISkeletonProps, LoaderSkeleton } from './LoaderSkeleton';

export const VisitList = ({ children, isLoading }: ISkeletonProps): Node =>
	isLoading ? (
		<Stack spacing="8px">
			<LoaderSkeleton sx={SX.headerSkeleton} />
			<LoaderSkeleton sx={SX.loaderSkeleton} />
			<LoaderSkeleton sx={SX.loaderSkeleton} />
			<LoaderSkeleton sx={SX.loaderSkeleton} />
		</Stack>
	) : (
		children
	);

const SX = {
	loaderSkeleton: {
		height: '81px',
		borderRadius: '10px',
	},
	headerSkeleton: {
		width: '60px',
		height: '20px',
		borderRadius: '6px',
		margin: '10px 0 0 15px',
	},
};
