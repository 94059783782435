import React from 'react';
import { ToggleButton } from '@mui/lab';
import { Stack } from '@mui/material';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';

export const StyledButton = props => {
	return (
		<ToggleButton
			{...props}
			sx={{
				display: 'flex',
				justifyContent: 'flex-start',
				fontSize: '14px',
				textTransform: 'Capitalize',
				fontWeight: 400,
				height: '36px',
				width: '208px',
				color: 'rgba(0, 0, 0, 0.9)',
				'&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
					borderColor: 'transparent',
					borderRadius: '5px',
				},
				'&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
					borderColor: 'transparent',
					borderRadius: '5px',
				},
				'&.MuiToggleButton-root:hover': {
					background: 'transparent',
					color: '#4D79EA',
				},
				'&.MuiToggleButton-root.Mui-selected': {
					background: '#4D79EA',
					color: '#FFF',
					'&:hover': {
						color: '#FFF',
					},
				},
			}}
		/>
	);
};

export const SearchResultsPageContainer = props => {
	return <Box {...props} position={'relative'} top={'22px'} left={'55px'} maxWidth={'1500px'} />;
};

export const CaseStatus = props => {
	return (
		<Stack {...props} justifyContent={'center'} alignItems={'center'} direction={'row'} mr={'-20px !important'} />
	);
};

export const General = props => {
	return (
		<Typography
			{...props}
			sx={{
				fontSize: '12px',
				fontWeight: '500',
				textTransform: 'Uppercase',
				color: 'rgba(0, 0, 0, 0.4)',
				letterSpacing: '1px',
				mt: '2rem',
				mb: '8px',
				ml: '10px',
			}}
		/>
	);
};

export const ResultsContainer = props => {
	return <Box {...props} position={'absolute'} top={'58px'} left={'500px'} width={'800px'} maxWidth={'800px'} />;
};

export const SectionsContainer = props => {
	return (
		<Box
			{...props}
			maxHeight={'700px'}
			sx={{
				overflowY: 'scroll',
				scrollbarWidth: '4px',
				scrollbarColor: 'rgba(196, 196, 196, 1)',
				'::-webkit-scrollbar': {
					width: '4px',
				},
				'::-webkit-scrollbar-thumb': {
					background: 'rgba(196, 196, 196, 1)',
					borderRadius: '10px',
				},
				'::-webkit-scrollbar-track': {
					background: 'transparent',
				},
			}}
		/>
	);
};

export const SectionTitle = props => {
	return (
		<Typography
			{...props}
			sx={{
				fontSize: '18px',
				fontWeight: 500,
				letterSpacing: '1px',
				color: 'rgba(0,0,0,0.9)',
				textTransform: 'Uppercase',
			}}
		/>
	);
};

export const SectionResultCount = props => {
	return (
		<Typography
			{...props}
			sx={{
				width: '38px',
				height: '27px',
				borderRadius: '6px',
				background: 'rgba(196, 196, 196, 0.35)',
				fontSize: '18px',
				fontWeight: 500,
				color: 'rgba(0, 0, 0, 0.6)',
				ml: '8px',
				textAlign: 'center',
			}}
		/>
	);
};

export const IconContainer = props => {
	return (
		<Stack
			{...props}
			justifyContent={'center'}
			alignItems={'center'}
			sx={{
				width: '54px',
				height: '54px',
				borderRadius: '50px',
				background: '#F9FAFC',
				mr: '-24px',
				ml: '-10px',
				zIndex: 1,
			}}
		/>
	);
};

export const IconContainerOKR = props => {
	return (
		<Stack
			{...props}
			justifyContent={'center'}
			alignItems={'center'}
			sx={{
				width: '38px',
				height: '38px',
				borderRadius: '50px',
				background: '#F9FAFC',
				mr: '-24px',
				ml: '-10px',
				zIndex: 1,
			}}
		/>
	);
};

export const IconBackground = props => {
	return (
		<Stack
			{...props}
			justifyContent={'center'}
			alignItems={'center'}
			sx={{
				width: '38px',
				height: '38px',
				borderRadius: '50px',
				background: 'rgba(77, 121, 234, 0.2)',
			}}
		/>
	);
};
export const IconBackgroundWhite = props => {
	return (
		<Stack
			{...props}
			justifyContent="center"
			alignItems="center"
			sx={{
				width: '30px',
				height: '30px',
				borderRadius: '50px',
				background: '#FFFFFF',
				boxShadow: '0px 0px 20px rgba(33, 88, 119, 0.12)',
			}}
		/>
	);
};

export const ResultBody = props => {
	return <Stack {...props} position={'relative'} justifyContent={'center'} />;
};
export const GeneralResultBody = props => {
	return (
		<Stack
			{...props}
			position={'relative'}
			display={'flex'}
			flexDirection={'row'}
			alignItems={'center'}
			justifyContent={'space-between'}
		/>
	);
};

export const CommentDescription = props => {
	return (
		<Typography
			{...props}
			ml={'3rem'}
			sx={{
				maxWidth: '500px',
				fontSize: '14px',
				fontWeight: 500,
				fontStyle: 'italic',
				color: 'rgba(0, 0, 0, 0.9)',
				pr: '2px',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				display: '-webkit-box',
				WebkitLineClamp: '1',
				WebkitBoxOrient: 'vertical',
			}}
		/>
	);
};
