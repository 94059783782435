// @flow

// core
import React, { type Node } from 'react';
// components
import Grid from '@mui/material/Grid';
import { LoaderSkeleton, type ISkeletonProps } from './LoaderSkeleton';

/** Skeleton loader for `OrderNotes.jsx` or `PatientNotes.jsx` */
export const LoaderNotes = ({ children, isLoading }: ISkeletonProps): Node =>
	isLoading ? (
		<Grid container spacing="20px">
			{[1, 2, 3].map(i => (
				<Grid key={`note_skeleton_${i}`} item sx={{ minWidth: '346px' }} xs={4}>
					<LoaderSkeleton sx={{ height: '180px' }} />
				</Grid>
			))}
		</Grid>
	) : (
		children
	);
