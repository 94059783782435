import React, { useState, useLayoutEffect } from 'react';
import { Box, IconButton, Chip } from '@mui/material';

import Tooltip from '@mui/material/Tooltip';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const styles = {
	root: {
		display: 'flex',
		justifyContent: 'left',
		flexWrap: 'nowrap',
		listStyle: 'none',
		padding: '2px',
		margin: 0,
		overflow: 'hidden',
		maxWidth: '230px',
	},
	tab: {
		opacity: 1,
		minWidth: '0px',
		padding: 0,
	},
	chip: {
		margin: '4px',
	},
};

const CustomChipsWithArrowButtons = ({
	tagValue,
	getTagProps,
	setExpand,
	expand,
	renderChipLabel,
	customLabelSx = {},
}) => {
	const [selectedTab, setSelectedTab] = useState(0);

	const slideAhead = () => {
		if (selectedTab + 1 <= tagValue.length) {
			setSelectedTab(selectedTab + 1);
			const element = document.querySelector(`[data-tag-index="${selectedTab + 1}"]`);
			element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const slideBack = () => {
		if (selectedTab >= 1) {
			setSelectedTab(selectedTab - 1);
			const element = document.querySelector(`[data-tag-index="${selectedTab - 1}"]`);
			element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	useLayoutEffect(() => {
		if (expand && tagValue?.length > 1) {
			const element = document.querySelector(`[data-tag-index="${tagValue?.length - 1}"]`);
			setSelectedTab(tagValue.length - 1);
			element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [tagValue, expand]);

	return (
		<Box sx={{ ...styles.root, ...customLabelSx }}>
			{tagValue?.length > 0 && expand && (
				<IconButton aria-label="back" sx={{ p: 0 }} onClick={() => slideBack()}>
					<NavigateBeforeIcon
						sx={{
							color: 'rgba(255, 255, 255, 0.6)',
						}}
					/>
				</IconButton>
			)}
			<Box sx={styles.root}>
				{tagValue?.map((option, index) => (
					<Tooltip key={index} title={renderChipLabel(option)}>
						<Chip
							label={renderChipLabel(option)}
							sx={styles.chip}
							{...getTagProps({ index })}
							data-testid={index}
						/>
					</Tooltip>
				))}
			</Box>
			<Box sx={{ pt: '8px' }}>
				{tagValue?.length > 1 && !expand ? (
					<Chip
						label={tagValue.length > 1 ? `+${tagValue.length - 1}` : ''}
						size="small"
						onClick={() => setExpand(!expand)}
					/>
				) : null}
			</Box>
			{tagValue?.length > 0 && expand && (
				<IconButton aria-label="forward" sx={{ p: 0 }} onClick={() => slideAhead()}>
					<NavigateNextIcon
						sx={{
							color: 'rgba(255, 255, 255, 0.6)',
						}}
					/>
				</IconButton>
			)}
		</Box>
	);
};

export default CustomChipsWithArrowButtons;
