import { FhirDomainResource } from '../base';
import reportSchema from './schemata/reportSchema';

export default class ReportResource extends FhirDomainResource {
	script;
	forRead;
	accessControl;

	constructor(resourceString) {
		super(resourceString, reportSchema);
		this.resourceType = 'Report';
		this.populateFields();
	}
}
