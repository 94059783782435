/* Generated automagically by FhirStarter - DO NOT EDIT */
const imagingStudySchema = {
	resourceType: 'ImagingStudy',
	identifier: ['__Identifier__'],
	status: '__code__',
	modality: ['__Coding__'],
	subject: '__Reference(Patient|Device|Group)__',
	encounter: '__Reference(Encounter)__',
	started: '__dateTime__',
	basedOn: ['__Reference(CarePlan|ServiceRequest|Appointment|AppointmentResponse|Task)__'],
	referrer: '__Reference(Practitioner|PractitionerRole)__',
	interpreter: ['__Reference(Practitioner|PractitionerRole)__'],
	endpoint: ['__Reference(Endpoint)__'],
	numberOfSeries: '__unsignedInt__',
	numberOfInstances: '__unsignedInt__',
	procedureReference: '__Reference(Procedure)__',
	procedureCode: ['__CodeableConcept__'],
	location: '__Reference(Location)__',
	reasonCode: ['__CodeableConcept__'],
	reasonReference: ['__Reference(Condition|Observation|Media|DiagnosticReport|DocumentReference)__'],
	note: ['__Annotation__'],
	description: '__string__',
	series: [
		{
			uid: '__id__',
			number: '__unsignedInt__',
			modality: '__Coding__',
			description: '__string__',
			numberOfInstances: '__unsignedInt__',
			endpoint: ['__Reference(Endpoint)__'],
			bodySite: '__Coding__',
			laterality: '__Coding__',
			specimen: ['__Reference(Specimen)__'],
			started: '__dateTime__',
			performer: [
				{
					function: '__CodeableConcept__',
					actor: '__Reference(Practitioner|PractitionerRole|Organization|CareTeam|Patient|Device|RelatedPerson)__',
				},
			],
			instance: [
				{
					uid: '__id__',
					sopClass: '__Coding__',
					number: '__unsignedInt__',
					title: '__string__',
				},
			],
		},
	],
};
export default imagingStudySchema;
