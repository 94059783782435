import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import UserSelector from '../../UserSelector';
import { GenericDrawer } from '..';

const validationSchema = yup.object().shape({
	name: yup
		.string()
		.required('Worklist name is required')
		.test('name', 'Please enter a valid worklist name.', val => val?.trim().length >= 0),
	practitioner: yup.object().required('user name is required').nullable(''),
});
/**
 *
 * @param anchor - String: "right", "left", "up", "down"
 */
// Should include arg to pass in options for button and drawer contents?
// How to override default options
const SaveAsNewDrawer = ({
	anchor,
	onDrawerSubmit,
	drawerData,
	defaultSelectedUser,
	drawerOpen,
	setDrawerOpen,
	...otherProps
}) => {
	const drawerWidth = 487;
	const { t } = useTranslation('workList');

	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
		clearErrors,
	} = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			name: '',
			managingOrganization: '',
		},
	});
	useEffect(() => {
		if (!drawerOpen) {
			reset();
			!!errors && clearErrors();
		}
	}, [drawerOpen]);
	const onSubmit = data => {
		drawerData.name = data.name;
		drawerData.practitioner = data.practitioner;
		onDrawerSubmit(drawerData);
		reset();
	};
	const CancelSaveButtons = ({ closeDrawer }) => (
		<Box
			sx={{
				position: 'fixed',
				bottom: 0,
				flexDirection: 'row',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				width: 391,
				pb: '20px',
				px: '48px',
			}}
		>
			<SecondaryButton label={t('cancel')} testId="SaveAsCancel" onClick={closeDrawer} />
			<PrimaryButton className="saveAs-button" label={t('save')} onClick={handleSubmit(onSubmit)} />
		</Box>
	);

	return (
		<GenericDrawer
			showBackBtn
			anchor={anchor}
			drawerOpen={drawerOpen}
			setDrawerOpen={setDrawerOpen}
			title={t('newWorkListDrawer.saveForNewWorklist')}
			width={drawerWidth}
			{...otherProps}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container alignItems="center" direction="column" justify="center">
					<Grid item width={390} xs={1}>
						<Controller
							control={control}
							name="name"
							render={({ field }) => (
								<TextField
									{...field}
									fullWidth
									error={!!errors.name}
									helperText={errors.name?.message}
									label={t('newWorkListDrawer.typeWorklistName')}
									sx={{ my: 3 }}
								/>
							)}
						/>
						<Controller
							control={control}
							defaultValue={defaultSelectedUser}
							name="practitioner"
							render={({ field: { onChange } }) => (
								<UserSelector
									fullWidth
									defaultValue={defaultSelectedUser}
									error={!!errors.practitioner}
									helperText={errors.practitioner?.message}
									testId="SaveAsWLUserSelector"
									onChange={onChange}
								/>
							)}
						/>
					</Grid>
				</Grid>
			</form>
			<CancelSaveButtons />
		</GenericDrawer>
	);
};
UserSelector.propTypes = {
	/**
	 * The side(right, left, up, down) of the window where drawer opens
	 */
	anchor: PropTypes.string,

	/**
	 * Callback function to submit Drawer form data.
	 */
	onDrawerSubmit: PropTypes.func,

	/**
	 * Drawer form data which come from Worklist settings.
	 */
	drawerData: PropTypes.object,

	/**
	 * Selected user from Worklist settings.
	 */
	defaultSelectedUser: PropTypes.object,

	/**
	 * If true, worklist drawer opens, else closes
	 */
	drawerOpen: PropTypes.bool,

	/**
	 * State hook variable to handle(open/close) worklist drawer
	 */
	setDrawerOpen: PropTypes.func,
};
export default SaveAsNewDrawer;
