const blumeFormSchema = {
	resourceType: '__string__',
	id: '__string__',
	name: '__string__',
	status: '__string__',
	meta: ['__Meta__'],
	owner: '__Reference(Practitioner)__',
	organization: '__Reference(Organization)__',
	organizationId: '__string__',
	organizationName: '__string__',
	created: '__dateTime__',
	completed: '__boolean__',
	description: '__string__',
	type: '__string__',
	version: '__string__',
	patient: '__Reference(Patient)__',
};
export default blumeFormSchema;
