// core
import React from 'react';

// libraries
import { Controller } from 'react-hook-form';
// components
import PhoneInput from 'react-phone-input-2';
import { styled } from '@mui/material/styles';

// types
import FormFieldVariantType from '../../../formTypes/FormFieldVariantType';
import { mediaTablet } from '@worklist-2/core/src/utils/responsiveUtils';

const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
	'&.react-tel-input': {
		display: 'flex',
		flexFlow: 'column nowrap',
	},
	'&.react-tel-input .form-control.invalid-number': {
		borderColor: '#d32f2f',
	},
	'&.react-tel-input .selected-flag': {
		padding: '0px 0px 0px 6px',
		width: '34px',
	},
	'&.react-tel-input .flag-dropdown': {
		height: '40px',
		bottom: 'auto',
	},
	'&.react-tel-input .flag-dropdown.open': {
		width: 'inherit',
	},
	'&.react-tel-input .country-list': {
		width: 'inherit',
		minWidth: '172px',
		'.country': {
			padding: '6px 9px 6px 46px',
		},
		'.flag': {
			top: 0,
		},
		'.search-box': {
			width: '95%',
			marginLeft: '0px',
			[mediaTablet]: {
				marginLeft: '8px',
			},
			borderRadius: '15px',
			lineHeight: '22px',
		},
	},
	'&.react-tel-input .invalid-number-message': {
		background: 'transparent',
		color: '#d32f2f',
		fontSize: '0.75rem',
		lineHeight: 1.66,
		padding: 0,
		position: 'static',
		margin: '3px 14px 0 14px',
		order: 100,
	},
}));

const FormPhoneVariant = ({ formHook, name, style, phoneProps }) => {
	const { control } = formHook;
	const {
		formState: { errors },
	} = formHook;

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, value } }) => (
				<StyledPhoneInput
					enableSearch
					containerStyle={style || { marginRight: phoneProps?.viewSource === 'Mobile' ? 0 : '5px' }}
					country="us"
					defaultErrorMessage="Phone is Required"
					inputStyle={{
						width: '100%',
						fontSize: '16px',
						...phoneProps,
					}}
					isValid={() => !errors.phone}
					placeholder="Phone Number"
					specialLabel=""
					value={value}
					onChange={onChange}
				/>
			)}
		/>
	);
};

FormPhoneVariant.propTypes = FormFieldVariantType();

export default FormPhoneVariant;
