/* Generated automagically by FhirStarter - DO NOT EDIT */
import { getSchema } from '../datatypes';
import { validateArgs } from './validateArgs';

const buildNarrativeFunc = args => {
	const { id, extension, status, div } = args;
	const schema = getSchema('Narrative');
	let valid = validateArgs(schema, args, Object.keys(args));

	return {
		id: id,
		extension: extension,
		status: status,
		div: div,
		__valid: valid,
	};
};

export default buildNarrativeFunc;
