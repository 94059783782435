import React, { useEffect, useRef, useState } from 'react';

import CheckList from './CheckList/CheckList';
import TableConfigurator from './Table/TableConfigurator/TableConfigurator';

import { handleKeyUp } from '../utils';

import { CalloutBlockIcon } from '../../../../assets/icons';

import styles from './InsertTextBlock.module.scss';

const InsertTextBlock = ({ theme, currentTextBlock, setCurrentTextBlock, setDraft, activePanel }) => {
	const [currentValue, setCurrentValue] = useState('');

	const insertTextRef = useRef();

	useEffect(() => {
		if (currentTextBlock.text) {
			setCurrentValue(currentTextBlock.text);
		}
	}, [currentTextBlock]);

	const onDraftChange = () => {
		if (currentValue !== '') {
			setCurrentTextBlock(prev => ({ ...prev, text: currentValue }));

			setDraft(prev =>
				prev.map(item => {
					if (item.DocumentDraftId === activePanel.id) {
						const newDraft = [...(item.DocumentJson || [])];
						newDraft.push({
							type: currentTextBlock.type,
							text: currentValue,
							id: `textBlock-${currentTextBlock.type}-${Date.now()}`,
						});

						return {
							...item,
							DocumentJson: newDraft,
						};
					}
					return item;
				})
			);

			setCurrentValue('');
			setCurrentTextBlock({});
		}

		if (
			currentTextBlock.type === 'Check List' &&
			currentTextBlock?.checkList?.length > 0 &&
			currentTextBlock.checkList[0].value !== ''
		) {
			setDraft(prev =>
				prev.map(item => {
					if (item.DocumentDraftId === activePanel.id) {
						const newDraft = [...item.DocumentJson];
						let content = '';

						currentTextBlock.checkList.forEach(item => {
							content = `${content + item.value}; `;
						});

						newDraft.push({
							...currentTextBlock,
							type: currentTextBlock.type,
							text: content,
							id: `textBlock-${currentTextBlock.type}-${Date.now()}`,
						});

						return {
							...item,
							DocumentJson: newDraft,
						};
					}
					return item;
				})
			);

			setCurrentValue('');
			setCurrentTextBlock({});
		}

		if (currentTextBlock.type === 'Table' && currentTextBlock?.tableText && currentTextBlock.tableText !== '') {
			setDraft(prev =>
				prev.map(item => {
					if (item.DocumentDraftId === activePanel.id) {
						const newDraft = [...item.DocumentJson];

						newDraft.push({
							type: currentTextBlock.type,
							html: currentTextBlock.tableHtml,
							text: currentTextBlock.tableText,
							id: `textBlock-${currentTextBlock.type}-${Date.now()}`,
						});

						return {
							...item,
							DocumentJson: newDraft,
						};
					}
					return item;
				})
			);

			setCurrentValue('');
			setCurrentTextBlock({});
		}
	};

	const handleOutSideClick = event => {
		const path = event.path || (event.composedPath && event.composedPath());
		if (!path.includes(insertTextRef.current)) {
			onDraftChange();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleOutSideClick);

		return () => {
			document.removeEventListener('mousedown', handleOutSideClick);
		};
	}, [currentValue, currentTextBlock]);

	const onChange = e => {
		setCurrentValue(e.target.value);
	};

	const handleKeyDown = event => {
		if (event.key === 'Enter' && event.ctrlKey) {
			onDraftChange();
		}
	};

	const getInputStyle = () => {
		let resultStyle = '';

		if (theme === 'OAI') {
			resultStyle = styles.inputOAI;
		} else {
			resultStyle = styles.inputBlume;
		}

		switch (currentTextBlock.type) {
			case 'Header':
				resultStyle += ` ${styles.inputHeader}`;
				break;
			case 'Paragraph':
				resultStyle += ` ${styles.inputParagraph}`;
				break;
			case 'Quote':
				resultStyle += ` ${styles.inputQuote}`;
				break;
			case 'Callout':
				resultStyle += ` ${styles.inputCallout}`;
				break;
			case 'Bullet List':
				resultStyle += ` ${styles.inputBulletList}`;
				break;
			case 'Number List':
				resultStyle += ` ${styles.inputNumberList}`;
				break;
			case 'Check List':
				resultStyle += ` ${styles.inputCheckList}`;
				break;
			default:
				resultStyle += '';
		}

		return resultStyle;
	};

	return (
		<div
			ref={insertTextRef}
			className={`${styles.insertTextBlock} ${
				currentTextBlock.type === 'Callout' ? styles.insertTextBlockCallout : ''
			} ${currentTextBlock.type === 'Table' ? styles.insertTextBlockTable : ''}`}
		>
			{currentTextBlock.type === 'Check List' ? (
				<CheckList handleKeyDown={handleKeyDown} setCurrentTextBlock={setCurrentTextBlock} theme={theme} />
			) : currentTextBlock.type === 'Table' ? (
				<TableConfigurator setCurrentTextBlock={setCurrentTextBlock} theme={theme} />
			) : (
				<>
					{currentTextBlock.type === 'Callout' ? (
						<div className={styles.calloutIcon}>
							<CalloutBlockIcon />
						</div>
					) : (
						''
					)}
					<textarea
						className={getInputStyle()}
						data-testid="textarea"
						id={`textarea ${getInputStyle()}`}
						placeholder={
							currentTextBlock.type === 'Callout'
								? 'Start typing to create the callout'
								: currentTextBlock.type === 'Bullet List'
								? 'Start typing to create first list item'
								: ''
						}
						rows={(currentValue.match(/\n/g) || []).length + 1 || 1}
						value={currentValue}
						onChange={onChange}
						onKeyDown={e => handleKeyDown(e)}
						onKeyUp={e => handleKeyUp(e, currentTextBlock.type)}
					/>
				</>
			)}
		</div>
	);
};

export default InsertTextBlock;
