import { vec3 } from 'gl-matrix';

export const sortInstancesByPosition = (instances, plane) => {
	// Return if only one instance e.g., multiframe
	if (instances.length <= 1) {
		return instances;
	}

	const { ImagePositionPatient: referenceImagePositionPatient, ImageOrientationPatient } =
		instances[Math.floor(instances.length / 2)]; // this prevents getting scout image as test image

	if (!referenceImagePositionPatient || !ImageOrientationPatient) {
		return instances;
	}

	const rowCosineVec = vec3.fromValues(
		ImageOrientationPatient[0],
		ImageOrientationPatient[1],
		ImageOrientationPatient[2]
	);

	const colCosineVec = vec3.fromValues(
		ImageOrientationPatient[3],
		ImageOrientationPatient[4],
		ImageOrientationPatient[5]
	);

	const scanAxisNormal = vec3.cross(vec3.create(), rowCosineVec, colCosineVec);

	const refIppVec = vec3.set(
		vec3.create(),
		referenceImagePositionPatient[0],
		referenceImagePositionPatient[1],
		referenceImagePositionPatient[2]
	);

	const distanceInstancePairs = instances.map(instance => {
		const imagePositionPatient = instance.ImagePositionPatient;

		if (!imagePositionPatient || !instance.ImageOrientationPatient) {
			return {
				distance: Number.NEGATIVE_INFINITY,
				instance,
			};
		}

		const positionVector = vec3.create();

		vec3.sub(positionVector, referenceImagePositionPatient, imagePositionPatient);

		const distance = vec3.dot(positionVector, scanAxisNormal);

		return {
			distance,
			instance,
		};
	});

	let sortedDistanceInstancePairs = distanceInstancePairs.sort((a, b) => b.distance - a.distance);
	sortedDistanceInstancePairs = sortByPlane({
		array: sortedDistanceInstancePairs,
		plane,
	});
	return sortedDistanceInstancePairs.map(a => a.instance);
};

export const sortByPlane = ({ array, plane }) => {
	const { firstFrameImagePositionPatient, lastFrameImagePositionPatient } =
		findFirstAndLastImagePositionPatient(array);
	if (!firstFrameImagePositionPatient || !lastFrameImagePositionPatient) return array;

	const firstFrame = vec3.set(
		vec3.create(),
		firstFrameImagePositionPatient[0],
		firstFrameImagePositionPatient[1],
		firstFrameImagePositionPatient[2]
	);

	const lastFrame = vec3.set(
		vec3.create(),
		lastFrameImagePositionPatient[0],
		lastFrameImagePositionPatient[1],
		lastFrameImagePositionPatient[2]
	);

	const direction = vec3.sub(vec3.create(), lastFrame, firstFrame);
	let sortedArray = [...array];

	switch (plane) {
		case 'Axial': ///main axis Z
			if (direction[2] > 0) {
				sortedArray = [...sortedArray.reverse()];
			}
			break;
		case 'Coronal': ///main axis Y
			if (direction[1] > 0) {
				sortedArray = [...sortedArray.reverse()];
			}
			break;
		case 'Sagittal': ///main axis X
			if (direction[0] < 0) {
				sortedArray = [...sortedArray.reverse()];
			}
			break;
		default:
			break;
	}
	return sortedArray;
};

const findFirstAndLastImagePositionPatient = array => {
	let start = 0;
	let end = array.length - 1;

	while (start < end) {
		if (!array[start].instance.ImagePositionPatient) {
			start++;
			continue;
		}
		if (!array[end].instance.ImagePositionPatient) {
			end--;
			continue;
		}
		return {
			firstFrameImagePositionPatient: array[start].instance.ImagePositionPatient,
			lastFrameImagePositionPatient: array[end].instance.ImagePositionPatient,
		};
	}
	return { firstFrameImagePositionPatient: null, lastFrameImagePositionPatient: null };
};
