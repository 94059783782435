import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import _ from 'lodash';

import {
	DeleteButton,
	Description,
	CommentDescription,
	DetailsBody,
	IconCage,
	IconContainer,
	NoNotifications,
	NotificationBody,
	OptionBody,
	OptionText,
	SectionNotifCount,
	SectionsContainer,
	SectionTitle,
	ShowSpinner,
	Step,
	StepTrack,
	Type,
} from './NotificationsDetailsViewStyle';

import { crmSearchScopes, useAuth, useCRMDataLoader, useCRMSearchScope } from '@worklist-2/core/src';

import Box from '@mui/system/Box';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const NotificationsDetailsView = () => {
	const [notifData, setNotifData] = useState([]);
	const [data, setData] = useState([]);
	const [showSpinner, setShowSpinner] = useState(true);
	const moment = new MomentUtils();
	const navigate = useNavigate();
	const { crmLoader, setCaseGlobalList, setAccountGlobalList } = useCRMSearchScope();
	const { authorized, setNotificationReceived, loggedInUser } = useAuth();
	const metaBreezeAccount = useBooleanFlagValue('meta-breeze-account');
	const metaBreezeCase = useBooleanFlagValue('meta-breeze-case');

	const caseDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.notificationApi,
	});

	const ALLOW_NOTIFY = useMemo(() => {
		const searchTab = [
			{
				TabName: 'Account',
				TabValue: 'Account',
			},
			{
				TabName: 'Case',
				TabValue: 'Case',
			},
			{
				TabName: 'Contact',
				TabValue: 'Contact',
			},
			{
				TabName: 'File',
				TabValue: 'Attachment',
			},
			{
				TabName: 'User',
				TabValue: 'User',
			},
			{
				TabName: 'Knowledge',
				TabValue: 'helpcenter',
			},
			{
				TabName: 'Comment',
				TabValue: 'Comment',
			},
			{
				TabName: 'DocumentReview',
				TabValue: 'DocumentReview',
			},
		];

		if (!metaBreezeAccount) {
			_.remove(searchTab, item => item.TabValue === 'Account');
		}
		if (!metaBreezeCase) {
			const removeTab = ['Case', 'Attachment', 'Comment'];
			_.remove(searchTab, item => removeTab.includes(item.TabValue));
		}

		if (!loggedInUser?.permission.Account.Read) {
			_.remove(searchTab, item => item.TabValue === 'Account');
		}
		if (!loggedInUser?.permission.Case.Read) {
			_.remove(searchTab, item => item.TabValue === 'Case');
		}
		if (!loggedInUser?.permission.Contact.Read) {
			_.remove(searchTab, item => item.TabValue === 'Contact');
		}
		if (!loggedInUser?.permission.Case.Read || !loggedInUser?.permission.Comment.Read) {
			const removeTab = ['Attachment', 'Comment'];
			_.remove(searchTab, item => removeTab.includes(item.TabValue));
		}
		if (!loggedInUser?.permission.User.Read) {
			_.remove(searchTab, item => item.TabValue === 'User');
		}

		return searchTab;
	}, [loggedInUser, metaBreezeAccount, metaBreezeCase]);

	useEffect(() => {
		if (crmLoader && authorized) {
			fetchData(caseDataLoader).catch(console.error);
		}
	}, [crmLoader, authorized]);

	const getUnreadNotifCount = notif => notif?.filter(item => item.IsReaded === false).length;

	const handleDelete = id => {
		deleteNotification(caseDataLoader, id).catch(console.error);
	};

	const handleClearAll = () => {
		if (notifData && notifData.length > 0) {
			deleteAllNotification(caseDataLoader).catch(console.error);
		}
	};

	const detailedView = notification => {
		const resourceType = _.lowerCase(notification.ResourceChangeType);
		const resourceId = notification.ResourceChangeId;

		setCaseGlobalList([]);
		setAccountGlobalList([]);
		navigate(`/${resourceType}/${resourceId}`);
	};

	const handleUpdate = notification => {
		if (!notification.IsReaded) {
			updateNotification(caseDataLoader, notification).catch(console.error);
		}
	};

	const updateNotification = async (loader, notification) => {
		const fnName = 'update';
		if (!loader) {
			loader = caseDataLoader;
		}

		if (authorized) {
			try {
				await loader[fnName](notification.NotificationId, {
					...notification,
					IsReaded: true,
				}).then(result => {
					fetchData(caseDataLoader).catch(console.error);
				});
			} catch (e) {
				console.error(e);
			}
		}
	};

	const deleteNotification = async (loader, id) => {
		const fnName = 'delete';
		if (!loader) {
			loader = caseDataLoader;
		}

		if (authorized) {
			const notifications = data.filter(item => item.NotificationId !== id);
			setData(notifications);

			try {
				await loader[fnName]({ id }).then(result => {
					setTimeout(() => {
						{
							fetchData(caseDataLoader).catch(console.error);
						}
					}, 1000);
				});
			} catch (e) {
				console.error(e);
			}
		}
	};

	const deleteAllNotification = async loader => {
		const fnName = 'delete';
		if (!loader) {
			loader = caseDataLoader;
		}

		if (authorized) {
			setData([]);

			try {
				await loader[fnName]().then(result => {
					setNotificationReceived(false);
				});
			} catch (e) {
				console.error(e);
			}
		}
	};

	const fetchData = async (loader, value) => {
		const fnName = 'load';
		const bodyData = undefined;
		if (!loader) {
			loader = caseDataLoader;
		}

		if (authorized) {
			try {
				setShowSpinner(true);
				await loader[fnName](value, !!bodyData || undefined, bodyData).then(result => {
					const newData = result;
					if (getUnreadNotifCount(newData) > 0) {
						setNotificationReceived(true);
					} else {
						setNotificationReceived(false);
					}

					const sortedData = newData.sort(
						(currentValue, nextValue) =>
							new Date(nextValue.CreatedDateTime) - new Date(currentValue.CreatedDateTime)
					);

					setData(sortedData);
					setTimeout(() => {
						setShowSpinner(false);
					}, 1000);
				});
			} catch (e) {
				console.error(e);
				setShowSpinner(false);
			}
		}
	};

	useEffect(() => {
		const filterData = [];

		for (const resultItem of data) {
			if (_.find(ALLOW_NOTIFY, ['TabValue', resultItem.ResourceChangeType])) {
				filterData.push(resultItem);
			}
		}

		const sortedData = filterData.sort(
			(currentValue, nextValue) => new Date(nextValue.CreatedDateTime) - new Date(currentValue.CreatedDateTime)
		);

		const formattedData = [];

		for (const item of sortedData) {
			if (item.CreatedDateTime) {
				const existingData = formattedData.find(
					el => el.NotificationDate === getTimeDiff(item.CreatedDateTime)
				);
				if (existingData) {
					existingData.Notifications = [...existingData.Notifications, item];
				} else {
					const newData = {
						NotificationDate: getTimeDiff(item.CreatedDateTime),
						Notifications: [item],
					};
					formattedData.push(newData);
				}
			}
		}

		setNotifData(formattedData);
	}, [data]);

	const getTimeDiff = date => {
		const now = moment.moment.utc();
		const end = moment.moment(date);
		const days = now.diff(end, 'days');

		let timeAgo;
		if (days <= 1) {
			timeAgo = 'Last 24 Hours';
		} else {
			timeAgo = moment.moment(date).format('MMM D');
		}

		return timeAgo;
	};

	const getNotificationIcon = type => {
		if (type === 'Account') {
			return (
				<IconCage bg="rgba(77, 121, 234, 0.2)">
					<ViewListOutlinedIcon sx={{ color: '#4D79EA' }} />
				</IconCage>
			);
		}
		if (type === 'Comment') {
			return (
				<IconCage bg="rgba(202, 235, 251, 1)">
					<ChatOutlinedIcon sx={{ color: '#1DBBFF' }} />
				</IconCage>
			);
		}
		if (type === 'Note') {
			return (
				<IconCage bg="rgba(252, 247, 204, 1)">
					<DescriptionOutlinedIcon sx={{ color: '#EED602' }} />
				</IconCage>
			);
		}
		if (type === 'Asset') {
			return (
				<IconCage bg="rgba(75, 73, 207, 0.2)">
					<FolderOutlinedIcon sx={{ color: '#4B49CF' }} />
				</IconCage>
			);
		}
		if (type === 'Case') {
			return (
				<IconCage bg="rgba(77, 121, 234, 0.2)">
					<WorkOutlineOutlinedIcon sx={{ color: '#4D79EA' }} />
				</IconCage>
			);
		}
		if (type === 'DocumentReview') {
			return (
				<IconCage bg="rgba(77, 121, 234, 0.2)">
					<StickyNote2OutlinedIcon sx={{ color: '#4D79EA' }} />
				</IconCage>
			);
		}
	};

	const getDocumentReviewHeader = path => {
		let header = '';
		if (path) {
			const myArray = path.split('|');

			if (myArray.length > 1) {
				header = `${myArray[myArray.length - 2]} • ${myArray[myArray.length - 1]}`;
			} else if (myArray.length > 0) {
				header = myArray[0];
			}
		}
		return header;
	};

	return (
		!!loggedInUser?.permission.Notification.Read && (
			<Box left="120px" position="relative" top="100px" width="1500px">
				<Stack direction="row" spacing={2}>
					<NotificationsIcon sx={{ color: '#4D79EA', fontSize: '35px' }} />
					<Typography sx={{ color: '#4D79EA', fontSize: '24px', fontWeight: 600 }}>Notifications</Typography>
				</Stack>
				<Box ml="2.5rem" mt="4rem">
					<OptionBody>
						<SettingsIcon
							sx={{
								color: '#4D79EA',
								fontSize: '18px',
								cursor: 'pointer',
							}}
						/>
						<OptionText
							sx={{
								color: '#4D79EA',
								fontSize: '14px',
								fontWeight: 400,
								cursor: 'pointer',
							}}
						>
							Configure Notification Settings
						</OptionText>
					</OptionBody>
					{!!loggedInUser?.permission.Notification.Delete && (
						<OptionBody onClick={handleClearAll}>
							<DeleteOutlinedIcon
								sx={{
									color: notifData && notifData.length > 0 ? '#4D79EA' : 'rgba(14, 14, 14, 0.4)',
									cursor: notifData && notifData.length > 0 ? 'pointer' : 'context-menu',
									fontSize: '18px',
								}}
							/>
							<OptionText
								sx={{
									color: notifData && notifData.length > 0 ? '#4D79EA' : 'rgba(14, 14, 14, 0.4)',
									cursor: notifData && notifData.length > 0 ? 'pointer' : 'context-menu',
									fontSize: '14px',
									fontWeight: 400,
								}}
							>
								Clear All
							</OptionText>
						</OptionBody>
					)}
				</Box>
				<Box left="450px" position="absolute" top="58px" width="824px">
					<SectionsContainer>
						{notifData && notifData.length > 0 ? (
							notifData.map(
								entry =>
									entry.Notifications.length > 0 && (
										<Stack mb="3rem">
											<Stack direction="row" mb="8px">
												<ScheduleIcon
													sx={{
														color: '#4D79EA',
														fontSize: '25px',
														mr: '1rem',
													}}
												/>
												<SectionTitle>{entry.NotificationDate}</SectionTitle>
												<SectionNotifCount>{`${entry.Notifications.length}`}</SectionNotifCount>
											</Stack>
											<Stack direction="row" position="relative" spacing="1.5rem">
												<Stack>
													<StepTrack />
												</Stack>
												<Stack spacing="8px">
													{entry.Notifications.map(notif => (
														<Stack alignItems="center" direction="row">
															<Step
																notification={notif}
																title={`${moment
																	.moment(notif.CreatedDateTime)
																	.format('LT')}`}
															/>
															<IconContainer>
																{getNotificationIcon(notif.ResourceChangeType)}
															</IconContainer>
															<NotificationBody>
																<DetailsBody
																	onClick={() => {
																		detailedView(notif);
																		handleUpdate(notif);
																	}}
																>
																	<Box width="auto">
																		<Stack direction="row" ml="3rem" spacing={2}>
																			<Typography
																				sx={{
																					fontSize: '14px',
																					fontWeight: 500,
																					color: '#4D79EA',
																				}}
																			>
																				{notif.ResourceChangeType ===
																				'DocumentReview'
																					? getDocumentReviewHeader(
																							notif.PathName
																					  )
																					: `${notif.ResourceChangeType} ${notif.ResourceChangeUid}`}
																			</Typography>
																			<Typography
																				sx={{
																					fontSize: '14px',
																					fontWeight: 400,
																					color: 'rgba(0, 0, 0, 0.4)',
																				}}
																			>
																				{notif.ResourceChangeType ===
																				'DocumentReview'
																					? ''
																					: `${notif.Title}`}
																			</Typography>
																		</Stack>
																		{notif.ResourceChangeSubType === 'Comment' ? (
																			<CommentDescription
																				commentData={notif.Description}
																			/>
																		) : (
																			<Description>
																				{notif.ResourceChangeType ===
																				'DocumentReview'
																					? `${notif.Title}`
																					: `${notif.Description}`}
																			</Description>
																		)}
																	</Box>
																	<Type>
																		{notif.ResourceChangeType === 'DocumentReview'
																			? `Review`
																			: `${notif.ResourceChangeType}`}
																	</Type>
																</DetailsBody>
																{!!loggedInUser?.permission.Notification.Delete && (
																	<DeleteButton
																		className="delete-hover-button"
																		onClick={() =>
																			handleDelete(notif.NotificationId)
																		}
																	>
																		<DeleteOutlinedIcon
																			className="delete-icon"
																			sx={{
																				'&.delete-icon': {
																					fontSize: '0',
																					color: '#FFF',
																					transition: '300ms ease-in-out',
																				},
																			}}
																		/>
																	</DeleteButton>
																)}
															</NotificationBody>
														</Stack>
													))}
												</Stack>
											</Stack>
										</Stack>
									)
							)
						) : (
							<Box height="440px" position="relative" width="700px">
								{showSpinner ? <ShowSpinner /> : <NoNotifications />}
							</Box>
						)}
					</SectionsContainer>
				</Box>
			</Box>
		)
	);
};

export default NotificationsDetailsView;
