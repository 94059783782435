import SignalRManager from '../../../../../../../utils/signalR';
import { fhirExtensionUrls } from '@worklist-2/core/src';
import _ from 'lodash';

const saveData = async (loader, props, payload) => {
	let saveResult = {};
	try {
		saveResult = await loader.save(props, payload).then(result => {
			return result.data;
		});
	} catch (e) {
		console.error(e);
		throw e;
	}
	return saveResult;
};

const testConnection = async ({
	device,
	config,
	subscriptionDataLoader,
	taskDataLoader,
	responseDataHandler,
	signalRUrl,
	customSubscriptionId,
}) => {
	const manager = new SignalRManager(config, responseDataHandler);

	// Create SignalR subscription
	const subscriptionId = customSubscriptionId ?? JSON.parse(localStorage.getItem('sessionId'));
	const owner = _.find(device.rawData?.extension ?? device.station?.extension, [
		'url',
		fhirExtensionUrls.device.associatedDevice,
	]);
	const subscriptionProps = {
		managingorganizationid: device.organizationId ?? device.orgData?.id,
		resourcetype: 'task',
		type: 'websocket',
		subtype: 'signalr',
	};
	const subscriptionPayload = {
		criteria: `Task?owner=${owner.valueReference.display}&status=rejected,cancelled,failed,completed,entered-in-error`,
		id: '-1',
		resourceType: 'Subscription',
		status: 'requested',
		reason: 'Worklist GUI Subscription',
		channelType: 'websocket',
		extension: [
			{
				url: 'http://www.ramsoft.com/fhir/extension/SubscriptionUID',
				valueString: `${subscriptionId}`,
			},
		],
		channel: {
			type: 'websocket',
			extension: [
				{
					url: 'http://www.ramsoft.com/fhir/StructureDefinition/SubType',
					valueString: 'SignalRWithData',
				},
			],
		},
	};
	await saveData(subscriptionDataLoader, subscriptionProps, subscriptionPayload);

	// Create FHIR Task payload
	let targetAeTitle = _.find(device.rawData?.extension ?? device.station?.extension, [
		'url',
		fhirExtensionUrls.device.aeTitle,
	])?.valueString;
	let targetPort = _.find(device.rawData?.extension ?? device.station?.extension, [
		'url',
		fhirExtensionUrls.device.dicomServerPort,
	])?.valueString;
	let targetHostName = _.find(device.rawData?.extension ?? device.station?.extension, [
		'url',
		fhirExtensionUrls.device.dicomServerHostName,
	])?.valueString;
	let managingOrg = _.find(device.rawData?.extension ?? device.station?.extension, [
		'url',
		fhirExtensionUrls.device.managingOrganization,
	]);
	const taskPayload = {
		id: '-1',
		resourceType: 'Task',
		status: 'requested',
		intent: 'plan',
		code: {
			coding: [
				{
					code: 'approve',
					display: 'Activate/approve the focal resource  ',
				},
			],
		},
		reasonCode: {
			text: 'DicomEcho',
		},
		for: {
			id: device.id ?? device.station?.id,
			extension: [
				{
					url: 'http://www.ramsoft.com/fhir/StructureDefinition/ForAETitle',
					valueString: targetAeTitle,
				},
				{
					url: 'http://www.ramsoft.com/fhir/StructureDefinition/PeerPort',
					valueString: targetPort,
				},
				{
					url: 'http://www.ramsoft.com/fhir/StructureDefinition/PeerHost',
					valueString: targetHostName,
				},
				{
					url: 'http://www.ramsoft.com/fhir/StructureDefinition/UseTLS',
					valueBoolean: false,
				},
				{
					url: 'http://www.ramsoft.com/fhir/extension/DeviceType',
					valueString: 'dicom-entity',
				},
			],
		},
		owner: {
			id: owner.valueReference.id,
			display: owner.valueReference.display,
		},
		extension: [
			{
				url: fhirExtensionUrls.common.managingOrganization,
				valueReference: {
					id: managingOrg.valueReference.id,
					reference: `Organization/${managingOrg.valueReference.id}`,
					display: managingOrg.valueReference.display,
				},
			},
			{
				url: fhirExtensionUrls.task.signalRSubscription,
				valueString: `${subscriptionId}`,
			},
		],
	};

	// Create SignalR Connection and then post FHIR Task
	await manager.connectAPISocket(subscriptionId, signalRUrl);
	await saveData(taskDataLoader, {}, taskPayload);
};

export default testConnection;
