import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { TabPanel, DottedWrapper } from '../../../Cases/casePopOverStyles';
import BusinessIcon from '@mui/icons-material/Business';
import AddIcon from '@mui/icons-material/Add';
import OrganizationSteps from '../OrganizationSteps/index';
import BreezeOrganizationLoadingImg from '@worklist-2/ui/src/assets/img/breezeOrganizationLoading.png';
import BreezeSettingIcon from '@worklist-2/ui/src/assets/img/breezeSettingIcon.gif';
import { useConfig } from '@worklist-2/core/src';
import axios from 'axios';
import OrganizationCard from '../FullscreenCards/OrganizationCard';
import { ShowSpinner } from '@worklist-2/breeze/src/views/CrmView/SearchPanelStyle';

const Organization = ({
	accountData,
	fetchAccountData,
	setClickedOrganization,
	setStepCustom,
	setCurrentOrganizationID,
	listMaxHeight,
	initialStep,
}) => {
	const __config = useConfig();
	const [step, setStep] = useState(null);
	const [selectedOrganizationID, setSelectedOrganizationID] = useState();
	const [dbClickOrganization, setDbClickOrganization] = useState(null);
	const [organizationData, setOrganizationData] = useState(accountData?.Organization);
	const [isOrganizationMatched] = useState(!!accountData?.Organization?.id);
	const [isBuildQueuing] = useState(!!accountData?.Organization?.buildId);
	const [isLoading, setIsLoading] = useState(true);

	const replaceOrgById = (org, newData) => {
		if (org.id === newData.id) {
			return org.childOrganizations ? { ...newData, childOrganizations: org.childOrganizations } : newData;
		}

		if (org.childOrganizations && Array.isArray(org.childOrganizations)) {
			org.childOrganizations = org.childOrganizations.map(child => replaceOrgById(child, newData));
		}

		return org;
	};

	const handleUpdateOrgTree = newData => {
		setOrganizationData(prevState => replaceOrgById({ ...prevState }, newData));
	};

	useEffect(() => {
		if (initialStep) {
			setStep(initialStep);
		}
	}, [initialStep]);

	const matchingChildOrganizations = (entryArr, parentId) => {
		const childOrgs = [];
		entryArr.forEach(entry => {
			const { id } = entry.resource;
			if (entry.resource.partOf?.id === parentId) {
				if (entryArr.some(x => id == x.resource.partOf?.id)) {
					entry.resource.childOrganizations = matchingChildOrganizations(entryArr, id);
				}

				childOrgs.push(entry.resource);
			}
		});
		return childOrgs;
	};

	useEffect(() => {
		async function asynchronousEffect() {
			if (accountData?.Organization?.id) {
				setIsLoading(true);
				try {
					await axios
						.get(
							`${__config.data_sources.breeze}/OrganizationOmegaAI/${accountData?.AccountId}/${accountData.Organization.id}/_descendant`
						)
						.then(result => {
							setIsLoading(false);
							if (result.status == 200 && result.data.total > 0) {
								const org = result.data.entry.find(
									x => x.resource.id == accountData.Organization.id
								)?.resource;
								org.childOrganizations = matchingChildOrganizations(result.data.entry, org?.id);
								setOrganizationData(org);
							}
						});
				} catch (e) {
					console.error(e);
				}
			}
		}

		asynchronousEffect();
	}, [accountData]);

	useEffect(() => {
		if (!!dbClickOrganization && !step) {
			setStep('organization');
		}
	}, [dbClickOrganization]);

	useEffect(() => {
		if (isBuildQueuing) {
			const interval = setInterval(() => {
				fetchAccountData();
			}, 15000);
			return () => {
				clearInterval(interval);
			};
		}
	}, [isBuildQueuing]);

	return (
		<>
			<TabPanel maxHeight="500px">
				<div className="header">
					<div className="left">
						<div className="colored-icon info-icon">
							<BusinessIcon sx={{ fontSize: 20 }} />
						</div>
						<h2>Organization</h2>
					</div>
				</div>

				<div
					className="body"
					style={{ overflow: listMaxHeight ? 'hidden' : 'auto', position: isLoading ? 'relative' : 'static' }}
				>
					{isBuildQueuing ? (
						<Box sx={{ margin: 'auto' }}>
							<div
								style={{
									height: '150px',
									width: '185px',
									backgroundImage: `url(${BreezeOrganizationLoadingImg})`,
									backgroundRepeat: 'round',
									margin: 'auto',
								}}
							>
								<img
									src={BreezeSettingIcon}
									style={{ width: '75px', height: '90px', margin: '35px 0px 0px 65px' }}
								/>
							</div>
							<div
								style={{
									textAlign: 'center',
									marginTop: '10px',
									fontFamily: 'Inter',
								}}
							>
								<span style={{ fontSize: '18px' }}>Thank for your patience</span>
								<br />
								<span style={{ fontSize: '14px', opacity: '0.5' }}>We are working on it</span>
							</div>
						</Box>
					) : isOrganizationMatched ? (
						isLoading ? (
							<Box sx={{ position: 'absolute', top: '50%', left: '40%' }}>
								<ShowSpinner />
							</Box>
						) : (
							<OrganizationCard
								data={organizationData}
								listMaxHeight={listMaxHeight}
								setDbClickOrganization={setClickedOrganization ?? setDbClickOrganization}
								setOrganizationID={setCurrentOrganizationID ?? setSelectedOrganizationID}
								showHeader={false}
							/>
						)
					) : (
						<DottedWrapper>
							<div
								className="card"
								onClick={() => {
									if (setStepCustom) {
										setStepCustom('organization');
									} else {
										setStep('organization');
									}
								}}
							>
								<AddIcon />
								<span>Add Organization</span>
							</div>
						</DottedWrapper>
					)}
				</div>
			</TabPanel>
			{!!step && (
				<OrganizationSteps
					accountData={accountData}
					fetchAccountData={fetchAccountData}
					handleUpdateOrgTree={handleUpdateOrgTree}
					organizationData={dbClickOrganization}
					setOrganizationData={setClickedOrganization ?? setDbClickOrganization}
					setStep={setStep}
					step={step}
				/>
			)}
		</>
	);
};

export default Organization;
