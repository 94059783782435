import React from 'react';

import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import CheckIcon from '@mui/icons-material/Check';

const StatusIndicatorStackStyles = checked => ({
	border: '1px solid rgba(255, 255, 255, 0.1)',
	borderRadius: '25px',
	height: '35px',
	background: checked ? '#42A5F5' : null,
	objectFit: 'cover',
	overflow: 'hidden',
});

const StatusIndicatorTypographyStyles = {
	font: 'Roboto',
	fontWeight: 400,
	fontSize: '12px',
	color: '#FFFFFF',
	lineHeight: '20px',
	marginRight: '1px',
};

const StatusIndicator = ({ indicator, handleClick, translateFn }) => (
	<Button
		startIcon={indicator.checked ? <CheckIcon sx={{ color: '#FFFFFF', fontSize: 12 }} /> : <>{indicator.icon}</>}
		sx={{ ...StatusIndicatorStackStyles(indicator.checked) }}
		variant={indicator.checked ? 'outlined' : 'contained'}
		onClick={handleClick}
	>
		<Typography
			sx={{
				...StatusIndicatorTypographyStyles,
			}}
		>
			{translateFn(indicator.name)}
		</Typography>
	</Button>
);

StatusIndicator.propTypes = {
	/**
	 * Indicator data and status to be displayed in the component.
	 */
	indicator: PropTypes.object,

	/**
	 * Callback function when an item is clicked
	 */
	handleClick: PropTypes.func,

	/**
	 * Translation function
	 */
	translateFn: PropTypes.func,
};

export default StatusIndicator;
