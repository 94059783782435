import React, { useEffect, useState, useMemo } from 'react';
import MomentUtils from '@date-io/moment';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { Avatar, Stack, Box, AvatarGroup } from '@mui/material';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import BlumeLogo from '@worklist-2/ui/src/assets/icons/blume_logo.svg';
import OmegaLogo from '@worklist-2/ui/src/assets/icons/Chat/omega-ai.svg';

import { crmSearchScopes, useAuth, useCRMDataLoader, useCRMSearchScope } from '@worklist-2/core/src';
import {
	DeleteButton,
	Description,
	CommentDescription,
	NoNotifications,
	NotifClearButton,
	NotifOutlinedButton,
	NotifContainedButton,
	NotifContainer,
	NotifCount,
	Notification,
	NotificationBody,
	NotifPanelContainer,
	NotifPanelFooter,
	NotifPanelHeader,
	NotifPanelTitle,
	PassedTime,
	ResourceChange,
	ShowSpinner,
	Title,
} from './NotificationViewStyle';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const NotificationView = ({ setNotifOpen }) => {
	const navigate = useNavigate();
	const { crmLoader, setCaseGlobalList, setAccountGlobalList } = useCRMSearchScope();
	const [data, setData] = useState([]);
	const [showSpinner, setShowSpinner] = useState(true);
	const { authorized, loggedInUser, setNotificationReceived } = useAuth();
	const moment = new MomentUtils();
	const metaBreezeAccount = useBooleanFlagValue('meta-breeze-account');
	const metaBreezeCase = useBooleanFlagValue('meta-breeze-case');

	const ALLOW_NOTIFY = useMemo(() => {
		const searchTab = [
			{
				TabName: 'Account',
				TabValue: 'Account',
			},
			{
				TabName: 'Case',
				TabValue: 'Case',
			},
			{
				TabName: 'Contact',
				TabValue: 'Contact',
			},
			{
				TabName: 'File',
				TabValue: 'Attachment',
			},
			{
				TabName: 'User',
				TabValue: 'User',
			},
			{
				TabName: 'Knowledge',
				TabValue: 'helpcenter',
			},
			{
				TabName: 'Comment',
				TabValue: 'Comment',
			},
			{
				TabName: 'DocumentReview',
				TabValue: 'DocumentReview',
			},
		];

		if (!metaBreezeAccount) {
			_.remove(searchTab, item => item.TabValue === 'Account');
		}
		if (!metaBreezeCase) {
			const removeTab = ['Case', 'Attachment', 'Comment'];
			_.remove(searchTab, item => removeTab.includes(item.TabValue));
		}

		if (!loggedInUser?.permission.Account.Read) {
			_.remove(searchTab, item => item.TabValue === 'Account');
		}
		if (!loggedInUser?.permission.Case.Read) {
			_.remove(searchTab, item => item.TabValue === 'Case');
		}
		if (!loggedInUser?.permission.Contact.Read) {
			_.remove(searchTab, item => item.TabValue === 'Contact');
		}
		if (!loggedInUser?.permission.Case.Read || !loggedInUser?.permission.Comment.Read) {
			const removeTab = ['Attachment', 'Comment'];
			_.remove(searchTab, item => removeTab.includes(item.TabValue));
		}
		if (!loggedInUser?.permission.User.Read) {
			_.remove(searchTab, item => item.TabValue === 'User');
		}

		return searchTab;
	}, [loggedInUser, metaBreezeAccount, metaBreezeCase]);

	const getNotifAge = createDate => moment.moment(createDate).fromNow();

	const getUnreadNotifCount = notif => notif?.filter(item => item.IsReaded === false).length;
	const caseDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.notificationApi,
	});

	useEffect(() => {
		if (crmLoader && authorized) {
			fetchData(caseDataLoader).catch(console.error);
		}
	}, [crmLoader, authorized]);

	const handleOpenNotification = notif => {
		setNotifOpen(false);
		handleUpdateNotification(notif);
		detailedView(notif);
	};

	const handleNotificationClick = notif => {
		if (notif.ResourceChangeType === 'DocumentReview') {
			return;
		}

		handleOpenNotification(notif);
	};

	const handleDeleteNotification = id => {
		deleteNotification(caseDataLoader, id).catch(console.error);
	};

	const handleDeleteAllNotification = () => {
		deleteAllNotification(caseDataLoader).catch(console.error);
	};

	const handleUpdateNotification = notification => {
		if (!notification.IsReaded) {
			updateNotification(caseDataLoader, notification).catch(console.error);
		}
	};

	const updateNotification = async (loader, notification) => {
		const fnName = 'update';
		if (!loader) {
			loader = caseDataLoader;
		}

		if (authorized) {
			try {
				await loader[fnName](notification.NotificationId, {
					...notification,
					IsReaded: true,
				}).then(result => {
					fetchData(caseDataLoader).catch(console.error);
				});
			} catch (e) {
				console.error(e);
			}
		}
	};

	const deleteNotification = async (loader, id) => {
		const fnName = 'delete';
		if (!loader) {
			loader = caseDataLoader;
		}

		if (authorized) {
			const notifications = data.filter(item => item.NotificationId !== id);
			setData(notifications);

			try {
				await loader[fnName]({ id }).then(result => {
					setTimeout(() => {
						{
							fetchData(caseDataLoader).catch(console.error);
						}
					}, 1000); //wait elk data re-indexing
				});
			} catch (e) {
				console.error(e);
			}
		}
	};

	const deleteAllNotification = async loader => {
		const fnName = 'delete';
		if (!loader) {
			loader = caseDataLoader;
		}

		if (authorized) {
			setData([]);

			try {
				await loader[fnName]().then(result => {
					setNotificationReceived(false);
				});
			} catch (e) {
				console.error(e);
			}
		}
	};

	const fetchData = async (loader, value) => {
		const fnName = 'load';
		const bodyData = undefined;
		if (!loader) {
			loader = caseDataLoader;
		}

		if (authorized) {
			try {
				await loader[fnName](value, !!bodyData || undefined, bodyData).then(result => {
					const newData = result;
					if (getUnreadNotifCount(newData) > 0) {
						setNotificationReceived(true);
					} else {
						setNotificationReceived(false);
					}

					const sortedData = newData.sort(
						(currentValue, nextValue) =>
							new Date(nextValue.CreatedDateTime) - new Date(currentValue.CreatedDateTime)
					);
					const filterData = [];
					for (const resultItem of sortedData) {
						if (_.find(ALLOW_NOTIFY, ['TabValue', resultItem.ResourceChangeType])) {
							filterData.push(resultItem);
						}
					}

					setData(filterData);
					setShowSpinner(false);
				});
			} catch (e) {
				console.error(e);
			}
		}
	};

	const detailedView = notification => {
		const resourceType = _.lowerCase(notification.ResourceChangeType);
		const resourceId = notification.ResourceChangeId;

		setCaseGlobalList([]);
		setAccountGlobalList([]);
		if (notification.ResourceChangeType === 'DocumentReview') {
			const PathNames = notification.PathName.split('|');
			navigate(`/documentation/help-center/${PathNames[0]}`, {
				state: {
					fileData: {
						Path: notification?.Path,
						DocumentDraftId: notification.ResourceChangeId,
						DocumentName: PathNames[PathNames.length - 1],
					},
					isReviewMode: true,
				},
			});
		} else {
			navigate(`/${resourceType}/${resourceId}`);
		}
	};

	const ICON_AVATARS = {
		Comment: (
			<Avatar
				sx={{
					bgcolor: 'rgba(29, 187, 255, 0.2)',
					width: '27px',
					height: '26px',
				}}
			>
				<ChatOutlinedIcon sx={{ fontSize: 17, color: '#1DBBFF' }} />
			</Avatar>
		),
		Asset: (
			<Avatar
				sx={{
					bgcolor: 'rgba(75, 73, 207, 0.2)',
					width: '27px',
					height: '26px',
				}}
			>
				<FolderOutlinedIcon sx={{ fontSize: 17, color: '#4B49CF' }} />
			</Avatar>
		),
		Case: (
			<Avatar
				sx={{
					bgcolor: 'rgba(77, 121, 234, 0.2)',
					width: '27px',
					height: '26px',
				}}
			>
				<WorkOutlineOutlinedIcon sx={{ fontSize: 17, color: '#4D79EA' }} />
			</Avatar>
		),
		Account: (
			<Avatar
				sx={{
					bgcolor: 'rgba(77, 121, 234, 0.2)',
					width: '27px',
					height: '26px',
				}}
			>
				<ViewListOutlinedIcon sx={{ fontSize: 17, color: '#4D79EA' }} />
			</Avatar>
		),
		Note: (
			<Avatar
				sx={{
					bgcolor: 'rgba(29, 187, 255, 0.2)',
					width: '27px',
					height: '26px',
				}}
			>
				<DescriptionOutlinedIcon sx={{ fontSize: 17, color: '#EED602' }} />
			</Avatar>
		),
		DocumentReview: (
			<Avatar
				sx={{
					zIndex: '1',
					bgcolor: '#D6EBFC',
					width: '27px',
					height: '26px',
				}}
			>
				<StickyNote2OutlinedIcon sx={{ fontSize: 17, color: '#4BAAF6' }} />
			</Avatar>
		),
		omegaai: (
			<Avatar
				sx={{
					bgcolor: '#005675',
					width: '27px',
					height: '26px',
				}}
			>
				<OmegaLogo />
			</Avatar>
		),
		blume: (
			<Avatar
				sx={{
					bgcolor: '#FFFFFF',
					width: '27px',
					height: '26px',
				}}
			>
				<BlumeLogo style={{ scale: '0.6' }} />
			</Avatar>
		),
	};

	const getDocumentReviewHeader = path => {
		let header = '';
		if (path) {
			const myArray = path.split('|');

			if (myArray.length > 1) {
				header = `${myArray[myArray.length - 2]} • ${myArray[myArray.length - 1]}`;
			} else if (myArray.length > 0) {
				header = myArray[0];
			}
		}
		return header;
	};

	return (
		<NotifPanelContainer>
			<NotifPanelHeader>
				<NotifPanelTitle>Notifications</NotifPanelTitle>
				<NotifCount>{getUnreadNotifCount(data)}</NotifCount>
				{!!loggedInUser.permission.Notification.Delete && (
					<NotifClearButton
						style={{
							color: data && data.length > 0 ? '' : 'rgba(14, 14, 14, 0.4)',
							cursor: data && data.length > 0 ? 'pointer' : 'context-menu',
						}}
						onClick={() => {
							if (data && data.length > 0) {
								handleDeleteAllNotification();
							}
						}}
					>
						Clear All
					</NotifClearButton>
				)}
			</NotifPanelHeader>
			<NotifContainer>
				{data && data.length > 0 ? (
					data.slice(0, 5).map(notif => (
						<Notification
							cursor={notif.ResourceChangeType === 'DocumentReview' ? 'default' : 'pointer'}
							height={notif.ResourceChangeType === 'DocumentReview' ? '100px' : '87px'}
						>
							<Box
								sx={{
									minWidth: '3px',
									height: '100%',
									background: notif.IsReaded ? 'transparent' : '#4D79EA',
								}}
							/>
							{notif.ResourceChangeType === 'DocumentReview' ? (
								<AvatarGroup sx={{ marginLeft: '13px', '.MuiAvatar-root': { border: 'none' } }}>
									{ICON_AVATARS[notif.PathName.split('|')[0]]}
									{ICON_AVATARS[notif.ResourceChangeType]}
								</AvatarGroup>
							) : (
								<Box sx={{ marginLeft: '25px' }}>
									{notif.ResourceChangeSubType
										? ICON_AVATARS[notif.ResourceChangeSubType]
										: ICON_AVATARS[notif.ResourceChangeType]}
								</Box>
							)}
							<NotificationBody
								marginLeft={notif.ResourceChangeType === 'DocumentReview' ? '8px' : '15px'}
								onClick={() => handleNotificationClick(notif)}
							>
								<Stack direction="row">
									<ResourceChange>
										{notif.ResourceChangeType === 'DocumentReview'
											? getDocumentReviewHeader(notif.PathName)
											: `${notif.ResourceChangeType} ${notif.ResourceChangeUid}`}
									</ResourceChange>
									<PassedTime>{getNotifAge(notif.CreatedDateTime)}</PassedTime>
								</Stack>
								<Title>{notif.Title}</Title>
								{notif.ResourceChangeType !== 'DocumentReview' &&
									(notif.ResourceChangeSubType === 'Comment' ? (
										<CommentDescription commentData={notif.Description} />
									) : (
										<Description>{notif.Description}</Description>
									))}
								{notif.ResourceChangeType === 'DocumentReview' && (
									<Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
										<NotifOutlinedButton maxHeight="34px">Remind Me</NotifOutlinedButton>
										<NotifContainedButton
											marginLeft="10px"
											maxHeight="34px"
											onClick={() => handleOpenNotification(notif)}
										>
											Review Now
										</NotifContainedButton>
									</Box>
								)}
							</NotificationBody>
							{!!loggedInUser.permission.Notification.Delete &&
								notif.ResourceChangeType !== 'DocumentReview' && (
									<DeleteButton
										className="delete-hover-button"
										onClick={() => handleDeleteNotification(notif.NotificationId)}
									>
										<DeleteOutlinedIcon
											className="delete-icon"
											sx={{
												'&.delete-icon': {
													fontSize: '0',
													color: '#FFF',
													transition: '300ms ease-in-out',
												},
											}}
										/>
									</DeleteButton>
								)}
						</Notification>
					))
				) : (
					<Box height="200px" left="34%" position="absolute" top="44%" width="200px">
						{showSpinner ? <ShowSpinner /> : <NoNotifications />}
					</Box>
				)}
			</NotifContainer>
			<NotifPanelFooter
				style={{
					color: data && data.length > 0 ? '' : 'rgba(14, 14, 14, 0.4)',
					cursor: data && data.length > 0 ? 'pointer' : 'context-menu',
				}}
				onClick={() => {
					if (data && data.length > 0) {
						setNotifOpen(false);
						navigate('/NotificationsDetails');
					}
				}}
			>
				View All
			</NotifPanelFooter>
		</NotifPanelContainer>
	);
};

export default NotificationView;
