// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bcPC_eiS6bZabSNKbu2t{width:192px}.bcPC_eiS6bZabSNKbu2t .NIwjAFp_s94a20GE0zlL .WJskfxGUVIUt_FI7ZGQA{display:inline-block;width:40%;margin:5% 0;background-color:rgba(196,196,196,.1490196078);border-radius:5px}.bcPC_eiS6bZabSNKbu2t .NIwjAFp_s94a20GE0zlL .WJskfxGUVIUt_FI7ZGQA input{height:31px;text-align:center;margin:0;color:#fff}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/Layout/PreviewOptions/PreviewOptions.module.scss"],"names":[],"mappings":"AAAA,sBACC,WAAA,CAGC,kEACC,oBAAA,CACA,SAAA,CACA,WAAA,CACA,8CAAA,CACA,iBAAA,CAEA,wEACC,WAAA,CACA,iBAAA,CACA,QAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PreviewOptions": "bcPC_eiS6bZabSNKbu2t",
	"inputContainer": "NIwjAFp_s94a20GE0zlL",
	"input": "WJskfxGUVIUt_FI7ZGQA"
};
export default ___CSS_LOADER_EXPORT___;
