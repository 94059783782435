import React from 'react';

const NumberListIcon = ({ fill }) => (
	<svg fill="none" height="23" viewBox="0 0 23 23" width="23" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_31956)">
			<path
				d="M2.32666 15.8857H4.1955V16.3529H3.26108V17.2874H4.1955V17.7546H2.32666V18.689H5.12992V14.9513H2.32666V15.8857ZM3.26108 7.47596H4.1955V3.73828H2.32666V4.6727H3.26108V7.47596ZM2.32666 10.2792H4.00861L2.32666 12.2415V13.0825H5.12992V12.148H3.44796L5.12992 10.1858V9.34479H2.32666V10.2792ZM6.99875 4.6727V6.54154H20.0806V4.6727H6.99875ZM6.99875 17.7546H20.0806V15.8857H6.99875V17.7546ZM6.99875 12.148H20.0806V10.2792H6.99875V12.148Z"
				fill={fill}
			/>
		</g>
		<defs>
			<clipPath id="clip0_2852_31956">
				<rect fill={fill} height="22.426" transform="translate(0.458008)" width="22.426" />
			</clipPath>
		</defs>
	</svg>
);

export default NumberListIcon;
