// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".O0bsK1ziyP2hBlkDPdNb{margin:20px 70px}.xx7H8tlnfs0XVPQVhMdg{margin:10px 50px;padding:10px;border-radius:10px;border:1px solid #42a5f5;background:rgba(66,165,245,.05)}.O0bsK1ziyP2hBlkDPdNb p{font-family:Roboto,serif;font-size:16px;font-style:normal;font-weight:400;line-height:24px;white-space:pre-wrap;word-wrap:break-word}.O0bsK1ziyP2hBlkDPdNb .HPpK26z_tGpcb4pDTp5c{color:#fff}.O0bsK1ziyP2hBlkDPdNb .nVtIN4mftJd5MBcCWIP6{color:rgba(18,18,18,.87)}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/common/MainArea/Draft/TextBlocks/ParagraphBlock/ParagraphBlock.module.scss"],"names":[],"mappings":"AAAA,sBACC,gBAAA,CAEA,sBACC,gBAAA,CACA,YAAA,CAEA,kBAAA,CACA,wBAAA,CACA,+BAAA,CAGD,wBACC,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEA,oBAAA,CACA,oBAAA,CAGD,4CACC,UAAA,CAGD,4CACC,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paragraphBlock": "O0bsK1ziyP2hBlkDPdNb",
	"paragraphBlockSelected": "xx7H8tlnfs0XVPQVhMdg",
	"paragraphOAI": "HPpK26z_tGpcb4pDTp5c",
	"paragraphBlume": "nVtIN4mftJd5MBcCWIP6"
};
export default ___CSS_LOADER_EXPORT___;
