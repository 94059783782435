// core
import React, { useRef } from 'react';

// utils
import { getTextFieldProps } from '../../../formUtils/formUtils';
import { Controller } from 'react-hook-form';

// MUI
import { TextField, Box, Typography, InputAdornment, FormControl, InputLabel } from '@mui/material';

// libraries
import { useTranslation } from 'react-i18next';

const FormSplitFieldDefault = React.forwardRef(({ formHook, name, props }, ref) => {
	const { t } = useTranslation('vitals');
	const {
		formState: { errors },
	} = formHook;
	const hasError = Boolean(errors?.[name]?.message);
	const fieldRefs = useRef(props?.individualFieldProps?.map(() => React.createRef()));

	const handleFieldChange = (e, maxLength, index) => {
		const { value } = e.target;
		if (value.length === maxLength) {
			fieldRefs.current[index + 1]?.current.focus();
		}
	};

	return (
		<FormControl
			error={hasError}
			sx={{
				...SX.formControl,
				borderColor: hasError ? 'red' : '#ccc',
			}}
			variant="outlined"
		>
			{props?.label && (
				<InputLabel shrink sx={{ ...SX.inputLabel, color: hasError ? 'red' : '#ccc' }}>
					{props.label}
				</InputLabel>
			)}
			<Box
				sx={{
					...SX.boxContainer,
					borderColor: hasError ? 'red' : '#ccc',
				}}
			>
				{props?.InputProps?.startAdornment && (
					<InputAdornment position="start" sx={{ width: '20px' }}>
						{props?.InputProps?.startAdornment}
					</InputAdornment>
				)}

				{/* Rendering individual text fields with splitters */}
				{props?.individualFieldProps?.map((fieldProps, index) => (
					<React.Fragment key={fieldProps?.name}>
						<Controller
							control={formHook?.control}
							name={fieldProps?.name}
							render={({ field }) => (
								<TextField
									{...field}
									{...getTextFieldProps(name)}
									InputProps={{ disableUnderline: true }}
									data-cy={`split-form-field-${fieldProps?.name}`}
									data-testid={`split-form-field-${fieldProps?.name}`}
									id={props?.id || `form-field-${fieldProps?.name}`}
									inputProps={fieldProps?.inputProps}
									inputRef={fieldRefs.current[index]}
									placeholder={fieldProps?.placeholder ? t(fieldProps?.placeholder) : null}
									required={false}
									sx={{
										width: '58px',
										marginRight: index < props?.individualFieldProps?.length - 1 ? '4px' : 0,
									}}
									value={props.value?.[index] ?? ''}
									variant="standard"
									onChange={e => {
										field.onChange(e.target.value);
										handleFieldChange(e, fieldProps?.inputProps?.maxLength, index);

										const currentValues = props?.individualFieldProps?.map(
											(_, i) => fieldRefs.current[i].current.value || ''
										);

										props.onChange(currentValues);
									}}
								/>
							)}
						/>
						{/* Splitter between fields */}
						{index < props?.individualFieldProps?.length - 1 && (
							<Typography sx={{ mx: 0.5, color: '#ffffff' }}>{props?.splitter || '/'}</Typography>
						)}
					</React.Fragment>
				))}

				{props?.InputProps?.endAdornment && (
					<InputAdornment position="end">
						<Typography>{props?.InputProps?.endAdornment}</Typography>
					</InputAdornment>
				)}
			</Box>

			{/* Display error message below the box */}
			{hasError && (
				<Typography color="error" sx={{ mt: 0.5, fontSize: '0.75rem' }}>
					{errors[name]?.message}
				</Typography>
			)}
		</FormControl>
	);
});

FormSplitFieldDefault.displayName = 'FormSplitFieldDefault';

export default FormSplitFieldDefault;

const SX = {
	formControl: {
		width: '100%',
	},
	inputLabel: {
		background: '#414141',
		paddingLeft: '6px',
		paddingRight: '6px',
	},
	boxContainer: {
		display: 'flex',
		alignItems: 'center',
		width: 'auto',
		height: '38px',
		border: '1px solid',
		padding: '8px',
		borderRadius: '4px',
		position: 'relative',
	},
};
