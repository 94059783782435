import axios from 'axios';
import { useConfig, useAuth } from '@rs-core';

const useBreezeAPI = () => {
	const __config = useConfig();
	const { accessToken, sessionId, authorized } = useAuth();
	const breezeApiBaseURL = __config.data_sources.breeze;
	const OAIOrganizationAPIUrl = `${breezeApiBaseURL}/OrganizationOmegaAI/`;

	/* Organization Omega AI API */

	const fetchDocumentsUploaded = async (accountId, organizationId) => {
		try {
			if (authorized) {
				const response = await axios.get(`${OAIOrganizationAPIUrl}${accountId}/${organizationId}/upload`, {
					responseType: 'json',
				});
				return response.data;
			}
		} catch (error) {
			console.error('Error while fetching uploaded documents:', error);
			return [];
		}
	};

	const deleteDocument = async (accountId, organizationId, type, documentId) => {
		try {
			if (authorized) {
				const response = await axios.delete(
					`${OAIOrganizationAPIUrl}${accountId}/${organizationId}/upload/${type}/${documentId}`,
					{ responseType: 'json' }
				);
				return response.data;
			}
		} catch (error) {
			console.error('Error while deleting document:', error);
			return [];
		}
	};

	const downloadDocument = async (accountId, organizationId, type, file) => {
		try {
			if (authorized) {
				const response = await axios.get(
					`${OAIOrganizationAPIUrl}${accountId}/${organizationId}/upload/${type}/${file.id}/download`,
					{ responseType: file.ContentType }
				);
				return response.data;
			}
		} catch (error) {
			console.error('Error while deleting document:', error);
			return [];
		}
	};

	const headers = {
		Accept: '*/*',
		Authorization: accessToken,
		SessionID: sessionId,
	};

	return {
		// Organization Omega AI API
		fetchDocumentsUploaded,
		deleteDocument,
		downloadDocument,

		// MISC
		headers,
	};
};

export default useBreezeAPI;
