const reportSchema = {
	resourceType: '__string__',
	id: '__string__',
	name: '__string__',
	active: '__boolean__',
	meta: ['__Meta__'],
	owner: '__Reference(Practitioner)__',
	organization: '__Reference(Organization)__',
	created: '__dateTime__',
};
export default reportSchema;
