import _ from 'lodash';

/**
 * Checks if there defined error for specified field
 *
 * eg. name="telecom.0.value" errors="{telecom: [{ value: { message: 'Value is required.' } }]}" returns="true"
 *
 * default value is false
 *
 * @param name
 * @param errors
 * @returns {boolean}
 */
const hasError = (name, errors) => Boolean(_.reduce(_.split(name, '.'), (p, c) => (p && p[c]) || false, errors));

/**
 * Checks if there defined error message for specified field
 *
 * eg. name="telecom.0.value" errors="{telecom: [{ value: { message: 'Value is required.' } }]}" returns="Value is required."
 *
 * default value is undefined
 *
 * @param name
 * @param errors
 * @returns {*}
 */
const getErrorMessage = (name, errors) =>
	_.reduce(_.split(name, '.'), (p, c) => (p && p[c]) || undefined, errors)?.message;

/**
 * Default props for TextField component
 *
 * @param name
 * @param errors
 * @returns {{fullWidth: boolean, InputLabelProps: {shrink: boolean}, placeholder: string, error: boolean, helperText: *}}
 */
const getTextFieldProps = (name, errors) => ({
	fullWidth: true,
	InputLabelProps: { shrink: true },
	placeholder: 'N/A',
	error: hasError(name, errors),
	helperText: getErrorMessage(name, errors),
});

export { hasError, getErrorMessage, getTextFieldProps };
