import { getInstanceSyntax, getInstanceViewPosition, getSeriesDescription, getSeriesViewPosition } from './utils';
import CheckVideoImagesTransferSyntax from './CheckVideoImagesTransferSyntax';
import { viewCodesModifiers } from '../../HangingProtocolView/features/ViewCodes/ViewCodes';
import getMiddleFrameIndex from './getMiddleFrameIndex';
import getSeriesOrientationPlane, { DICOM_IMAGE_PLANE_ANNOTATIONS } from './getSeriesOrientationPlane';
import {
	getImageLaterality,
	getLateralityViewCodeFromDescription,
} from '../components/ImageViewerViewport/utils/getImageLaterality';
import { MG_VIEW_CODES } from '@rs-ui/views/ImageViewerView3D/contexts/consts/consts';

const seriesMatchViewCodeCondition = ({ viewCode, modifier, series }) => {
	const middleFrameIndex = getMiddleFrameIndex(series?.metadata);
	const middleFrame = series?.metadata?.[middleFrameIndex] || series?.metadata?.[0];

	const seriesDescription = getSeriesDescription(series);
	const viewPosition = getSeriesViewPosition(series) || getInstanceViewPosition(middleFrame);
	const plane = DICOM_IMAGE_PLANE_ANNOTATIONS[getSeriesOrientationPlane(middleFrame)];
	const protocolName = middleFrame?.['00181030']?.Value?.[0];
	const imageComments = middleFrame?.['00204000']?.Value?.[0];
	const laterality = getImageLaterality(middleFrame, series);
	const isRightLaterality = laterality?.toUpperCase()?.includes('R');
	const isLeftLaterality = laterality?.toUpperCase()?.includes('L');

	const snomedRtId = middleFrame?.['00540220']?.Value?.[0]?.['00080100']?.Value?.[0];
	const isCC = snomedRtId?.toUpperCase()?.includes('R-10242');
	const isFB = snomedRtId?.toUpperCase()?.includes('R-10244');
	const isISO = snomedRtId?.toUpperCase()?.includes('R-40AAA');
	const isLM = snomedRtId?.toUpperCase()?.includes('R-10228');
	const isLMO = snomedRtId?.toUpperCase()?.includes('R-10230');
	const isML = snomedRtId?.toUpperCase()?.includes('R-10224');
	const isMLO = snomedRtId?.toUpperCase()?.includes('R-10226');
	const isSIO = snomedRtId?.toUpperCase()?.includes('R-102D0');
	const isXCC = snomedRtId?.toUpperCase()?.includes('R-102CF');
	const isXCCL = snomedRtId?.toUpperCase()?.includes('R-1024A') || snomedRtId?.toUpperCase()?.includes('Y-X1770');
	const isXCCM = snomedRtId?.toUpperCase()?.includes('R-1024B') || snomedRtId?.toUpperCase()?.includes('Y-X1771');
	const MGViewCode =
		getLateralityViewCodeFromDescription(seriesDescription).viewCode ||
		getLateralityViewCodeFromDescription(protocolName).viewCode ||
		getLateralityViewCodeFromDescription(imageComments).viewCode;

	const mammographyModifier =
		middleFrame?.['00540220']?.Value?.[0]?.['00540222']?.Value?.[0]?.['00080100']?.Value?.[0]?.toUpperCase();

	if (modifier) {
		const modifierValue = viewCodesModifiers.find(item => modifier === item.codeString)?.value;

		const imageTypeAttribute = middleFrame?.['00080008']?.Value;

		if (modifier === 'Tomo (3D)') {
			return imageTypeAttribute?.includes('TOMOSYNTHESIS') || imageTypeAttribute?.includes('VOLUME');
		}

		if (modifier === 'C-View') {
			return (
				(imageTypeAttribute?.includes('TOMOSYNTHESIS') || imageTypeAttribute?.includes('VOLUME')) &&
				imageTypeAttribute?.includes('GENERATED_2D')
			);
		}

		return mammographyModifier?.includes(modifierValue);
	}

	if (mammographyModifier) {
		let containsModifier = false;

		viewCodesModifiers.forEach(viewCodesModifier => {
			if (mammographyModifier?.includes(viewCodesModifier.value)) {
				containsModifier = true;
			}
		});

		if (containsModifier && !laterality) {
			return false;
		}
	}

	if (viewCode === 'AP') {
		return ['AP'].includes(viewPosition);
	}

	if (viewCode === 'LAT') {
		return ['LL', 'RL', 'LAT', 'LATERAL'].includes(viewPosition);
	}

	if (viewCode === 'PA') {
		return ['PA'].includes(viewPosition);
	}

	if (viewCode === 'OBLIQUE') {
		return ['OBLIQUE', 'RLO', 'LLO'].includes(viewPosition);
	}

	if (viewCode === 'RLD') {
		return ['RLD'].includes(viewPosition);
	}

	if (viewCode === 'LLD') {
		return ['LLD'].includes(viewPosition);
	}

	if (['CT: Axial', 'MR: Axial', 'PET: Axial'].includes(viewCode)) {
		return plane === 'Axial';
	}

	if (['CT: Coronal', 'MR: Coronal', 'PET: Coronal'].includes(viewCode)) {
		return plane === 'Coronal';
	}

	if (['CT: Sagittal', 'MR: Sagittal', 'PET: Sagittal'].includes(viewCode)) {
		return plane === 'Sagittal';
	}

	if (['CT: Scout', 'MR: Scout'].includes(viewCode)) {
		const scoutTags = [
			seriesDescription?.toUpperCase(),
			middleFrame?.['0008103E']?.Value?.[0]?.toUpperCase(),
			middleFrame?.['00080008']?.Value?.[0]?.toUpperCase(),
			middleFrame?.['00181030']?.Value?.[0]?.toUpperCase(),
		];

		return Boolean(scoutTags.find(scoutTag => scoutTag?.includes('LOCALIZER') || scoutTag?.includes('SCOUT')));
	}

	if (viewCode === 'MG: RCC') {
		return isRightLaterality && isCC;
	}

	if (viewCode === 'MG: RFB') {
		return isRightLaterality && isFB;
	}

	if (viewCode === 'MG: RISO') {
		return isRightLaterality && isISO;
	}

	if (viewCode === 'MG: RLM') {
		return isRightLaterality && isLM;
	}

	if (viewCode === 'MG: RLMO') {
		return isRightLaterality && isLMO;
	}

	if (viewCode === 'MG: RML') {
		return isRightLaterality && isML;
	}

	if (viewCode === 'MG: RMLO') {
		return isRightLaterality && isMLO;
	}

	if (viewCode === 'MG: RSIO') {
		return isRightLaterality && isSIO;
	}

	if (viewCode === 'MG: RXCC') {
		return isRightLaterality && isXCC;
	}

	if (viewCode === 'MG: RXCCL') {
		return isRightLaterality && isXCCL;
	}

	if (viewCode === 'MG: RXCCM') {
		return isRightLaterality && isXCCM;
	}

	if (viewCode === 'MG: LCC') {
		return isLeftLaterality && isCC;
	}

	if (viewCode === 'MG: LFB') {
		return isLeftLaterality && isFB;
	}

	if (viewCode === 'MG: LISO') {
		return isLeftLaterality && isISO;
	}

	if (viewCode === 'MG: LLM') {
		return isLeftLaterality && isLM;
	}

	if (viewCode === 'MG: LLMO') {
		return isLeftLaterality && isLMO;
	}

	if (viewCode === 'MG: LML') {
		return isLeftLaterality && isML;
	}

	if (viewCode === 'MG: LMLO') {
		return isLeftLaterality && isMLO;
	}

	if (viewCode === 'MG: LSIO') {
		return isLeftLaterality && isSIO;
	}

	if (viewCode === 'MG: LXCC') {
		return isLeftLaterality && isXCC;
	}

	if (viewCode === 'MG: LXCCL') {
		return isLeftLaterality && isXCCL;
	}

	if (viewCode === 'MG: LXCCM') {
		return isLeftLaterality && isXCCM;
	}

	if (viewCode === 'MG: RCC') {
		return MGViewCode === MG_VIEW_CODES.MG_RCC;
	}

	if (viewCode === 'MG: RFB') {
		return MGViewCode === MG_VIEW_CODES.MG_RFB;
	}

	if (viewCode === 'MG: RISO') {
		return MGViewCode === MG_VIEW_CODES.MG_RISO;
	}

	if (viewCode === 'MG: RLM') {
		return MGViewCode === MG_VIEW_CODES.MG_RLM;
	}

	if (viewCode === 'MG: RLMO') {
		return MGViewCode === MG_VIEW_CODES.MG_RLMO;
	}

	if (viewCode === 'MG: RML') {
		return MGViewCode === MG_VIEW_CODES.MG_RML;
	}

	if (viewCode === 'MG: RMLO') {
		return MGViewCode === MG_VIEW_CODES.MG_RMLO;
	}

	if (viewCode === 'MG: RSIO') {
		return MGViewCode === MG_VIEW_CODES.MG_RSIO;
	}

	if (viewCode === 'MG: RXCC') {
		return MGViewCode === MG_VIEW_CODES.MG_RXCC;
	}

	if (viewCode === 'MG: RXCCL') {
		return MGViewCode === MG_VIEW_CODES.MG_RXCCL;
	}

	if (viewCode === 'MG: RXCCM') {
		return MGViewCode === MG_VIEW_CODES.MG_RXCCM;
	}

	if (viewCode === 'MG: LCC') {
		return MGViewCode === MG_VIEW_CODES.MG_LCC;
	}

	if (viewCode === 'MG: LFB') {
		return MGViewCode === MG_VIEW_CODES.MG_LFB;
	}

	if (viewCode === 'MG: LISO') {
		return MGViewCode === MG_VIEW_CODES.MG_LISO;
	}

	if (viewCode === 'MG: LLM') {
		return MGViewCode === MG_VIEW_CODES.MG_LLM;
	}

	if (viewCode === 'MG: LLMO') {
		return MGViewCode === MG_VIEW_CODES.MG_LLMO;
	}

	if (viewCode === 'MG: LML') {
		return MGViewCode === MG_VIEW_CODES.MG_LML;
	}

	if (viewCode === 'MG: LMLO') {
		return MGViewCode === MG_VIEW_CODES.MG_LMLO;
	}

	if (viewCode === 'MG: LSIO') {
		return MGViewCode === MG_VIEW_CODES.MG_LSIO;
	}

	if (viewCode === 'MG: LXCC') {
		return MGViewCode === MG_VIEW_CODES.MG_LXCC;
	}

	if (viewCode === 'MG: LXCCL') {
		return MGViewCode === MG_VIEW_CODES.MG_LXCCL;
	}

	if (viewCode === 'MG: LXCCM') {
		return MGViewCode === MG_VIEW_CODES.MG_LXCCM;
	}

	if (viewCode === 'MG: Any right') {
		return isRightLaterality;
	}

	if (viewCode === 'MG: Any left') {
		return isLeftLaterality;
	}

	if (viewCode === 'Video') {
		return CheckVideoImagesTransferSyntax(getInstanceSyntax(series.metadata?.[0]));
	}

	return true;
};

export default seriesMatchViewCodeCondition;
