import React, { useRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';

import documentIcon from '../../../assets/icons/PatientInfoCards/documents-icon';
import registrationIcon from '../../../assets/icons/PatientInfoCards/registration-icon';
import { PATIENT_DOCUMENT_FILTERS } from '@worklist-2/worklist/src/DocumentViewerV3/consts/consts';

import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

export const FilterButton = ({
	children,
	value,
	icon: Icon,
	color,
	onClick,
	selectedFilter = PATIENT_DOCUMENT_FILTERS.ALL,
}) => {
	const isActive = value === selectedFilter;

	return (
		<Button
			sx={{
				border: '1px solid rgba(255, 255, 255, 0.1)',
				borderRadius: '25px',
				color: '#fff',
				textTransform: 'unset',
				fontSize: '12px',
				letterSpacing: '0.25px',
				gap: '8px',
				fontWeight: '400',
				paddingLeft: '10px',
				paddingRight: '10px',
				whiteSpace: 'nowrap',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: isActive ? 'rgba(66, 165, 245, 0.72)' : 'transparent',
				minWidth: value !== PATIENT_DOCUMENT_FILTERS.ALL ? 'auto' : undefined,
				'&:hover': {
					backgroundColor: isActive ? 'rgba(66, 165, 245, 1)' : '#494848',
				},
			}}
			onClick={onClick}
		>
			{Icon && (
				<Icon
					style={{
						width: '9.5px',
						height: 'auto', // Ensures aspect ratio is maintained
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						color: isActive ? 'white' : color || 'white',
					}}
				/>
			)}

			{children}
		</Button>
	);
};

const Filters = ({ selectedFilter, setSelectedFilter }) => {
	const { t } = useTranslation('reports');

	const scrollContainerRef = useRef(null);
	const [isOverflowing, setIsOverflowing] = useState(false);
	const [isScrolledLeft, setIsScrolledLeft] = useState(false);
	const [isScrolledRight, setIsScrolledRight] = useState(false);
	const galaxyDvPatientChartRefactor = useBooleanFlagValue('galaxy-dv-patient-chart-refactor');
	const handleScrollOnResize = () => {
		if (scrollContainerRef.current) {
			const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
			setIsScrolledLeft(scrollLeft > 0);
			setIsScrolledRight(scrollLeft + clientWidth < scrollWidth);
			setIsOverflowing(scrollWidth > clientWidth);
		}
	};

	useEffect(() => {
		if (scrollContainerRef.current) {
			handleScrollOnResize(); // Initial check
			scrollContainerRef.current.addEventListener('scroll', handleScrollOnResize);
		}

		window.addEventListener('resize', handleScrollOnResize);

		return () => {
			if (scrollContainerRef.current) {
				scrollContainerRef.current.removeEventListener('scroll', handleScrollOnResize);
			}
			window.removeEventListener('resize', handleScrollOnResize);
		};
	}, []);

	useEffect(() => {
		if (galaxyDvPatientChartRefactor) handleScrollOnResize();
	}, [scrollContainerRef.current?.clientWidth, galaxyDvPatientChartRefactor]);

	const handleScroll = direction => {
		if (scrollContainerRef.current) {
			const { scrollLeft, clientWidth } = scrollContainerRef.current;
			const scrollAmount = clientWidth / 2;
			scrollContainerRef.current.scrollTo({
				left: direction === 'left' ? scrollLeft - scrollAmount : scrollLeft + scrollAmount,
				behavior: 'smooth',
			});
		}
	};

	const TABS = [
		{ title: 'All', value: PATIENT_DOCUMENT_FILTERS.ALL },
		{
			title: 'Uploaded Document',
			value: PATIENT_DOCUMENT_FILTERS.UPLOADED_DOCUMENT,
			icon: documentIcon,
			color: '#7D7AFF',
		},
		{
			title: 'Registration Form',
			value: PATIENT_DOCUMENT_FILTERS.REGISTRATION_FORM,
			icon: registrationIcon,
			color: '#EB459E',
		},
	];

	return (
		<Box
			sx={{
				...STYLES.SCROLL_CONT,
				...(galaxyDvPatientChartRefactor && { minHeight: '66px', maxHeight: '66px' }),
			}}
		>
			{isOverflowing && isScrolledLeft && (
				<IconButton
					className="arrow"
					sx={{
						...STYLES.ARROW,
						left: 0,
						justifyContent: 'flex-start',
						background: 'linear-gradient(90deg, rgba(57,57,57,1) 60%, rgba(57,57,57,0) 100%);',
						'&:hover': {
							background: 'linear-gradient(90deg, rgba(57,57,57,1) 50%, rgba(57,57,57,0) 100%);',
						},
					}}
					onClick={() => handleScroll('left')}
				>
					<ArrowBackIosIcon />
				</IconButton>
			)}

			<Box ref={scrollContainerRef} sx={STYLES.FILTER_CONT}>
				{TABS.map(({ title, value, icon, color }) => (
					<FilterButton
						key={title}
						color={color}
						icon={icon}
						selectedFilter={selectedFilter}
						value={value}
						onClick={() => setSelectedFilter(value)}
					>
						{t(title)}
					</FilterButton>
				))}
			</Box>

			{isOverflowing && isScrolledRight && (
				<IconButton
					className="arrow"
					sx={{
						...STYLES.ARROW,
						right: 0,
						justifyContent: 'flex-end',
						background: 'linear-gradient(90deg, rgba(57,57,57,0) 0%, rgba(57,57,57,1) 60%);',
						'&:hover': {
							background: 'linear-gradient(90deg, rgba(57,57,57,0) 0%, rgba(57,57,57,1) 50%);',
						},
					}}
					onClick={() => handleScroll('right')}
				>
					<ArrowForwardIosIcon />
				</IconButton>
			)}
		</Box>
	);
};

export default Filters;

const STYLES = {
	SCROLL_CONT: {
		display: 'flex',
		alignItems: 'center',
		overflow: 'hidden',
		position: 'relative',
		'&:hover .arrow': {
			opacity: 1,
		},
	},
	ARROW: {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		zIndex: 2,
		opacity: 1,
		transition: 'opacity 0.3s',
		height: '100%',
		width: '55px',
		borderRadius: 0,
	},
	FILTER_CONT: {
		padding: '15px',
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		borderTop: '1px solid #FFFFFF1A',
		backgroundColor: '#363636',
		overflow: 'hidden',
		scrollBehavior: 'smooth',
	},
};
