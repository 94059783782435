import React from 'react';

import { InsertTextBlockIcon } from '../../../../assets/icons';

import styles from './InsertTextBlockPlaceholder.module.scss';

const InsertTextBlockPlaceholder = ({ theme }) => (
	<div className={styles.insertTextBlockPlaceholder}>
		<InsertTextBlockIcon />
		<span
			className={`${
				theme === 'OAI' ? styles.insertTextBlockPlaceholderOAI : styles.insertTextBlockPlaceholderBlume
			}`}
			data-testid="insertTextBlockPlaceholder"
		>
			Insert Text Block
		</span>
	</div>
);

export default InsertTextBlockPlaceholder;
