import { useConfig } from '@worklist-2/core/src/context/ConfigContext';
import { useAuth } from '@worklist-2/core/src/context/UserAuthContext';
import axios from 'axios';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const useFhirAPI = () => {
	const __config = useConfig();
	const enableInheritOrderSetAndProcedureCode = useBooleanFlagValue('enable-inherit-order-set-and-procedure-code');
	const { accessToken, sessionId, authorized } = useAuth();
	const fhirApiBaseURL = __config.data_sources.fhir;
	const faxAPIUrl = `${fhirApiBaseURL}/fax/`;
	const patientAPIUrl = `${fhirApiBaseURL}/Patient/`;
	const studyAPIUrl = `${fhirApiBaseURL}/ImagingStudyWorklist/`;
	const emergencyStudyAPIUrl = `${fhirApiBaseURL}/imagingstudy/`;
	const studySetAPIUrl = `${fhirApiBaseURL}/StudyType`;
	const organizationAPIUrl = `${fhirApiBaseURL}/organization`;
	const practitionerAPIUrl = `${fhirApiBaseURL}/Practitioner`;

	/* FAX API */

	const downloadFax = async messageId => {
		try {
			if (authorized) {
				const response = await axios.get(`${faxAPIUrl}${messageId}/download`, { responseType: 'blob' });
				return response.data;
			}
		} catch (error) {
			console.error('Error while downloading fax:', error);
			return [];
		}
	};

	const deleteFax = async data => {
		try {
			if (authorized) {
				const response = await axios.put(`${faxAPIUrl}${data.id}`, { ...data, isActive: false });
				return response;
			}
		} catch (error) {
			console.error('Error while deleting fax:', error);
			return [];
		}
	};

	const fetchFaxToOrderList = async organizationId => {
		try {
			if (authorized) {
				const response = await axios.get(
					`${faxAPIUrl}?status:exact=to%20new%20order${
						organizationId ? `&organization=${organizationId}` : ''
					}&_sort=lastUpdated`,
					{ responseType: 'json' }
				);
				return response.data.total > 0 ? response.data.entry.map(row => row.resource) : [];
			}
		} catch (error) {
			console.error('Error while fetching fax to order list:', error);
			return [];
		}
	};

	const updateFaxEntry = async (id, data) => {
		try {
			if (authorized) {
				const response = await axios.put(`${faxAPIUrl}${id}/`, { ...data });
				return response.data;
			}
		} catch (error) {
			console.error('Error while updating fax entry', error);
			return [];
		}
	};

	/* PATIENT API */

	const fetchPatientsFromOrganization = async (patientNameQuery, organizationId, patientCount, query) => {
		try {
			if (authorized) {
				const url = `elk?_count=${patientCount}&_start=0&organization=${organizationId}`;
				const patientFullName = patientNameQuery ? `&name=${patientNameQuery}` : '';
				const patientFirstName = query?.patientFirstName ? `&firstname=${query.patientFirstName}` : '';
				const patientLastName = query?.patientLastName ? `&lastname=${query.patientLastName}` : '';
				const ssnFilter = query?.ssn ? `&ssn=${query.ssn}` : '';
				const birthDateFilter = query?.birthDate ? `&birthdate=${new Date(query.birthDate).getTime()}` : '';
				const response = await axios.get(
					`${patientAPIUrl}${url}${patientFullName}${ssnFilter}${birthDateFilter}${patientFirstName}${patientLastName}`
				);
				return response.data;
			}
		} catch (error) {
			console.error('Error while fetching patients', error);
			return [];
		}
	};

	const fetchPatientNameForEmergencySearch = async (firstName, lastName, birthDate, phoneNumber, emailAddress) => {
		try {
			if (!firstName || !lastName || ![birthDate, phoneNumber, emailAddress].some(Boolean)) {
				throw new Error('Insufficient data to perform patient emergency search');
			}
			const url = `elk?isEmergencySearch=true`;
			const queryFirstName = firstName ? `&firstname:exact=${firstName}` : '';
			const queryLastName = lastName ? `&lastname:exact=${lastName}` : '';
			const queryBirthDate = birthDate ? `&birthdate:exact=${birthDate}` : '';
			const queryPhoneNumber = phoneNumber ? `&allphone:exact=${phoneNumber}` : '';
			const queryEmailAddress = emailAddress ? `&email:exact=${emailAddress}` : '';

			const response = await axios.get(
				`${patientAPIUrl}${url}${queryFirstName}${queryLastName}${queryBirthDate}${queryPhoneNumber}${queryEmailAddress}`
			);
			return response.data;
		} catch (error) {
			console.error('Error while performing patient emergency search', error);
			return [];
		}
	};

	const grantPatientViewAccess = async (patientId, physicianId) => {
		try {
			if (authorized) {
				const payload = {
					patient: {
						id: patientId,
						reference: `patient/${patientId}`,
					},
					physician: {
						id: physicianId,
						reference: `Practitioner/${physicianId}`,
					},
					attestation: 'testing',
				};
				const response = await axios.post(`${patientAPIUrl}attestation`, payload);
				return response.data;
			}
		} catch (error) {
			console.error('Error while granting access to view patient', error);
			return [];
		}
	};

	/* STUDY API */

	const fetchStudiesFromOrganization = async (studyNameQuery, organizationId, selectedPatient, studyCount) => {
		try {
			if (authorized) {
				const url = `elk?_content=${studyNameQuery}&internalmanagingorganizationid=${organizationId}&_count=${studyCount}&_start=0`;
				const patientFilter = selectedPatient ? `&internalpatientid=${selectedPatient.id}` : '';
				const response = await axios.get(`${studyAPIUrl}${url}${patientFilter}`);
				return response.data;
			}
		} catch (error) {
			console.error('Error while fetching studies', error);
			return [];
		}
	};

	const fetchStudiesSet = async (studyType, organizationId) => {
		try {
			if (authorized) {
				let url;
				if (enableInheritOrderSetAndProcedureCode) {
					url = `?&inheritorganization=${organizationId}&studytype=${studyType}`;
				} else {
					url = `?&owner=${organizationId}&studytype=${studyType}`;
				}
				const response = await axios.get(`${studySetAPIUrl}${url}`);
				return response.data;
			}
		} catch (error) {
			console.error('Error while fetching studies set', error);
			return [];
		}
	};

	const grantStudyAccess = async studyId => {
		try {
			if (authorized) {
				const response = await axios.post(`${emergencyStudyAPIUrl}/emergencyaccess/${studyId}`);
				return response.data;
			}
		} catch (error) {
			console.error('Error while granting study access', error);
			return [];
		}
	};
	/* ORGANIZATION API */

	const fetchInsurancePayerFromOrganization = async insuranceName => {
		try {
			if (authorized) {
				const url = `?count=50&_summary=true&name=${insuranceName}&organizationtype=PAY,INS`;
				const response = await axios.get(`${organizationAPIUrl}${url}`);
				return response.data;
			}
		} catch (error) {
			console.error('Error while fetching insurance payer', error);
			return [];
		}
	};

	/* PRACTITIONER API */

	const fetchReferringPhysician = async (referringPhysicianName, npi, organizationId) => {
		try {
			if (authorized) {
				const url = `?ispractitionerroleactive=true&managingorganization=${organizationId}&name=${referringPhysicianName}&referringphysician=true`;
				const npiFilter = npi ? `&npi=${npi}` : '';
				const response = await axios.get(`${practitionerAPIUrl}${url}${npiFilter}`);
				return response.data;
			}
		} catch (error) {
			console.error('Error while fetching referring practitioner ', error);
			return [];
		}
	};

	const headers = {
		Accept: '*/*',
		Authorization: accessToken,
		SessionID: sessionId,
	};

	return {
		// FAX
		downloadFax,
		deleteFax,
		fetchFaxToOrderList,
		updateFaxEntry,
		// PATIENT
		fetchPatientsFromOrganization,
		fetchPatientNameForEmergencySearch,
		grantPatientViewAccess,
		// STUDY
		fetchStudiesFromOrganization,
		fetchStudiesSet,
		grantStudyAccess,
		// ORGANIZATION
		fetchInsurancePayerFromOrganization,
		// PRACTITIONER
		fetchReferringPhysician,
		// MISC
		headers,
	};
};

export default useFhirAPI;
