import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';

const DeviceMapping = {
	id: {
		getDisplay: resource => {
			return resource.id;
		},
	},

	status: {
		getDisplay: resource => {
			return resource.status;
		},
	},

	deviceName: {
		label: 'Device Name',
		searchParameter: 'name',
		getDisplay: resource => {
			return resource.distinctIdentifier;
		},
	},

	aeTitle: {
		label: 'AETitle',
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.aeTitle).valueString;
		},
	},

	port: {
		label: 'Port',
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomServerPort).valueString;
		},
	},

	role: {
		label: 'Role',
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.role).valueReference;
		},
	},
	roleId: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.role).valueReference.id;
		},
	},

	managingOrganization: {
		label: 'Managing Organization',
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.managingOrganization)
				.valueReference;
		},
	},

	managingOrganizationId: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.managingOrganization)
				.valueReference.id;
		},
	},

	imagingOrganization: {
		label: 'Imaging Organization',
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.imagingOrganization)
				.valueReference;
		},
	},
	receiveImagingOrganizationAs: {
		label: 'Receive Imaging Organization As',
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.receiveImagingOrganizationAs)
				.valueReference;
		},
	},

	imagingOrganizationId: {
		label: 'Imaging Organization',
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.imagingOrganization)
				.valueReference.id;
		},
	},

	study: {
		label: 'Imaging Organization',
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.receiveStudyAs).valueReference;
		},
	},
	studyId: {
		label: 'Imaging Organization',
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.receiveStudyAs).valueReference
				.id;
		},
	},

	forceoverridestatus: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.forceOverrideStatus)
				.valueBoolean;
		},
	},

	dicomStationStudyStatus: {
		label: 'Dicom Station Study Status',
		getDisplay: resource => {
			return resource.extension
				.find(elem => elem.url === fhirExtensionUrls.device.dicomweb.dicomStationStudyStatus)
				.extension.map(t => t.valueReference);
		},
	},
	alertUsers: {
		label: 'Alert Users',
		getDisplay: resource => {
			return resource.extension
				.find(elem => elem.url === fhirExtensionUrls.device.alert.users)
				.extension.map(t => t.valueReference);
		},
	},
	alertNotificationSentDatetimeUtc: {
		label: 'Alert Notification Sent Datetime Utc',
		getDisplay: resource => {
			return resource.extension.find(
				elem => elem.url === fhirExtensionUrls.device.alert.notificationsentdatetimeutc
			).valueDateTime;
		},
	},
	alertNotificationIntervalMinutes: {
		label: 'alert Notification Interval Minutes',
		getDisplay: resource => {
			return resource.extension.find(
				elem => elem.url === fhirExtensionUrls.device.alert.notificationintervalminutes
			).valueString;
		},
	},
	alertReason: {
		label: 'Alert Reason',
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.alert.reason).valueString;
		},
	},
	dicomStationIssuerOfPatientId: {
		label: 'issuerOfPatientId',
		getDisplay: resource => {
			return resource.extension.find(
				elem => elem.url === fhirExtensionUrls.device.dicomweb.dicomStationIssuerOfPatientId
			).valueReference;
		},
	},
	type: {
		label: 'Device Type',
		getDisplay: resource => {
			return resource.rawData.type.coding[0].code;
		},
	},

	associateddeviceId: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.associatedDevice)
				.valueReference.id;
		},
	},

	associateddevice: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.associatedDevice)
				.valueReference;
		},
	},

	clientSecret: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomweb.dicomclientsecret)
				.valueString;
		},
	},

	privateKey: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomweb.dicomprivatekey)
				.valueString;
		},
	},

	password: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomweb.dicompassword)
				.valueString;
		},
	},

	features: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.features).extension;
		},
	},

	dicomStoreFeatures: {
		getDisplay: resource => {
			let features = resource.extension.find(elem => elem.url === fhirExtensionUrls.device.features).extension;

			if (features) {
				return features.findIndex(element => element.valueCoding.code == 'DicomStore') != -1;
			}

			return false;
		},
	},

	queryRetrieveFeatures: {
		getDisplay: resource => {
			let features = resource.extension.find(elem => elem.url === fhirExtensionUrls.device.features).extension;

			if (features) {
				return features.findIndex(element => element.valueCoding.code == 'QueryRetrieve') != -1;
			}

			return false;
		},
	},

	searchuri: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomweb.searchuri)
				.valueString;
		},
	},

	retrieveuri: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomweb.retrieveuri)
				.valueString;
		},
	},

	storeuri: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomweb.storeuri).valueString;
		},
	},

	username: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomweb.username).valueString;
		},
	},

	tokenuri: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomweb.tokenuri).valueString;
		},
	},

	claimsissuerdcweb: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomweb.claimsissuer)
				.valueString;
		},
	},

	jwtsigningalgorithm: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomweb.jwtsigningalgorithm)
				.valueString;
		},
	},

	authorizationuri: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomweb.authorizationuri)
				.valueString;
		},
	},

	clientiddcweb: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomweb.clientid).valueString;
		},
	},

	authenticationtype: {
		getDisplay: resource => {
			let value = resource.extension.find(
				elem => elem.url === fhirExtensionUrls.device.dicomweb.authenticationtype
			).valueInteger;

			return value == 0 ? 'basic' : value == 1 ? 'jwt' : value == 2 ? 'dicom' : null;
		},
	},

	scopes: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomweb.scopes).valueString;
		},
	},

	hideRejectedInstances: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.hideRejectedInstances)
				.valueBoolean;
		},
	},

	tlsEnable: {
		getDisplay: resource => {
			let tlsConnection = resource.extension.find(
				elem => elem.url === fhirExtensionUrls.device.tlsConnection
			)?.valueString;

			if (tlsConnection) {
				return tlsConnection != '0';
			}

			return false;
		},
	},

	isArchiveServer: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.archiveServer).valueBoolean;
		},
	},

	needIOCMNotification: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.needIOCMNotification)
				.valueBoolean;
		},
	},

	enableHeartbeat: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.enableHeartbeat).valueBoolean;
		},
	},

	coerceInfo: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomweb.coerceinfo)
				.valueBoolean;
		},
	},

	description: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.description).valueString;
		},
	},

	healthcareService: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.healthCareService)
				.valueReference;
		},
	},

	healthcareServiceId: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.healthCareService)
				.valueReference.id;
		},
	},

	ipAddress: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomServerHostName)
				.valueString;
		},
	},

	isActive: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.isActive).valueBoolean;
		},
	},

	characterSet: {
		getDisplay: resource => {
			const charList = [
				{ display: 'Latin 1 ISO_IR 100', id: 'ISO_IR 100' },
				{ display: 'Utf-8 ISO_IR 192', id: 'ISO_IR 192' },
				{ display: 'Thai ISO_IR 166', id: 'ISO_IR 166' },
				{ display: 'Latin-2 ISO_IR 101', id: 'ISO_IR 192' },
				{ display: 'Latin-3 ISO_IR 109', id: 'ISO_IR 192' },
				{ display: 'Latin-4 ISO_IR 110', id: 'ISO_IR 192' },
				{ display: 'Latin-5 ISO_IR 148', id: 'ISO_IR 148' },
				{ display: 'Latin-9 ISO_IR 203', id: 'ISO_IR 203' },
				{ display: 'Cyrillic ISO_IR 144', id: 'ISO_IR 144' },
				{ display: 'Arabic 127', id: 'ISO_IR 127' },
				{ display: 'Greek ISO_IR 126', id: 'ISO_IR 126' },
				{ display: 'Japanese ISO 2022 IR 13', id: 'ISO 2022 IR 13' },
				{ display: 'Chinese GB18030', id: 'GB18030' },
				{ display: 'Hebrew ISO_IR 138', id: 'ISO_IR 138' },
			];

			let valueSet = resource.extension.find(
				elem => elem.url === fhirExtensionUrls.device.characterSet
			).valueString;

			var valueObjectSet = charList.find(elem => elem.id === valueSet);
			if (valueObjectSet) {
				return valueObjectSet;
			} else {
				return { display: 'Latin 1 ISO_IR 100', id: 'ISO_IR 100' };
			}
		},
	},

	dicomtimeout: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomTimeout).valueString;
		},
	},

	dicommaxpdusize: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.dicomMaxPDUSize).valueString;
		},
	},

	compression1: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.compression1).valueString;
		},
	},

	compression2: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.compression2).valueString;
		},
	},

	compression3: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.compression3).valueString;
		},
	},

	jpegquality: {
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.device.jpegquality).valueString;
		},
	},

	deviceStatus: {
		getDisplay: resource => {
			return (
				resource.extension.find(elem => elem.url === fhirExtensionUrls.device.deviceStatus)?.valueString ||
				'Offline'
			);
		},
	},

	statusReasonDisplay: {
		getDisplay: resource => {
			let statusReason = resource.rawData.statusReason;

			if (statusReason) {
				if (statusReason[0].coding[0].code === 'off') {
					return 'Unknown';
				} else {
					return statusReason[0].coding[0].display;
				}
			} else {
				return 'Unknown';
			}
		},
	},
	issuer: {
		label: 'Receive Assign Authority as',
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.organization.issuer).valueReference;
		},
	},
};

export default DeviceMapping;
