import React, { useState } from 'react';
import OrganizationDetail from './OrganizationDetail';
import License from './License';
import Features from './Features';
import SubFeatures from './SubFeatures';
import { Box } from '@mui/material';
import Device from './Device/Device';
import UploadFiles from './UploadFiles';
import AddStationStep from './AddStationStep';

const OrganizationSteps = ({
	accountData,
	organizationData,
	setOrganizationData,
	step,
	setStep,
	fetchAccountData,
	isSimpleSidebar,
	sidebarMode,
	handleUpdateOrgTree,
}) => {
	const [deviceID, setDeviceID] = useState(null);

	return (
		<Box sx={{ position: 'absolute', top: '0px', right: '0px' }}>
			{step === 'organization' && (
				<OrganizationDetail
					accountData={accountData}
					data={organizationData}
					setStep={setStep}
					setNewOrgData={setOrganizationData}
					isSimpleSidebar={isSimpleSidebar}
					fetchAccountData={fetchAccountData}
					handleUpdateOrgTree={handleUpdateOrgTree}
				/>
			)}
			{step === 'license' && (
				<License
					accountData={accountData}
					orgData={organizationData}
					setOrganizationData={setOrganizationData}
					setStep={setStep}
					isSimpleSidebar={isSimpleSidebar}
				/>
			)}
			{step === 'features' && (
				<Features
					accountData={accountData}
					orgData={organizationData}
					setOrganizationData={setOrganizationData}
					setStep={setStep}
					isSimpleSidebar={isSimpleSidebar}
				/>
			)}
			{step === 'subfeatures' && (
				<SubFeatures
					accountData={accountData}
					orgData={organizationData}
					setOrganizationData={setOrganizationData}
					setStep={setStep}
					isSimpleSidebar={isSimpleSidebar}
				/>
			)}
			{(step === 'upload' || step === 'upload-from-account-fullscreen') && (
				<UploadFiles
					accountData={accountData}
					setStep={setStep}
					organizationID={organizationData.id}
					setOrganizationData={setOrganizationData}
					isOnboardingStep={step === 'upload'}
				/>
			)}
			{step === 'device' && (
				<Device
					accountData={accountData}
					orgData={organizationData}
					setStep={setStep}
					setOrganizationData={setOrganizationData}
					isSimpleSidebar={isSimpleSidebar}
					sidebarMode={sidebarMode}
					setDeviceID={setDeviceID}
				/>
			)}
			{step === 'station' && (
				<AddStationStep
					accountData={accountData}
					setSidebarStep={setStep}
					deviceID={deviceID}
					setDeviceID={setDeviceID}
					setOrganizationData={setOrganizationData}
				/>
			)}
		</Box>
	);
};

export default OrganizationSteps;
