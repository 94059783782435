// React Libraries
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Material UI Libraries
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';

function ReportFieldSearchBar({ setFieldSearchValue }) {
	const onFieldSearch = _.debounce(event => {
		setFieldSearchValue(event.target.value);
	}, 500);

	return (
		<Box
			sx={{
				marginLeft: '10px',
				marginRight: '10px',
				marginTop: '10px',
				marginBottom: '10px',
				display: 'flex',
				border: '1px solid rgba(255, 255, 255, 0.2)',
				boxShadow: 'inset 0px -4px 4px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.05)',
				borderRadius: '77px',
				background: '#F9FAFF',
				color: '#000',
				alignItems: 'center',
			}}
		>
			<InputBase
				required
				style={{
					width: '100%',
					height: 34,
					paddingRight: '10px',
					paddingLeft: '10px',
				}}
				type="text"
				onChange={onFieldSearch}
			/>
			<SearchIcon
				sx={{
					color: '#4D79EA',
					fontSize: 25,
					marginLeft: '-8px',
					marginRight: '8px',
				}}
			/>
		</Box>
	);
}

ReportFieldSearchBar.propTypes = {
	/**
	 * setFieldSearchValue is callback function whenever input changes
	 */
	setFieldSearchValue: PropTypes.func.isRequired,
};

export default ReportFieldSearchBar;
