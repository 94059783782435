import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import styled from 'styled-components';
import React from 'react';
import { mediaDesktopL, mediaTablet, mediaTabletOnly } from '@worklist-2/core/src';

export const Wrapper = props => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				padding: '5px 20px 0px',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
				flex: 1,
				'& .main': {
					zIndex: '0',
					overflowY: 'auto',
				},

				[theme.breakpoints.between('sm', 'md')]: {
					'& .main': {
						marginTop: '10px',
					},
				},

				[theme.breakpoints.up('lg')]: {
					overflowY: 'auto',
					'& .main': {
						overflowY: 'auto',
					},
				},
				[mediaDesktopL]: {
					padding: '5px 10px 20px',
					height: 'unset !important',
					'& .main': {
						maxWidth: 'unset !important',
					},
				},
			}}
			{...props}
		>
			{props.children}
		</Box>
	);
};

export const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

export const GridRow = styled.div`
	display: grid;
	grid-template-columns: 7fr 2fr;
	justify-content: center;
	align-items: center;
	padding: 16px 0;
	grid-gap: 16px;
`;

export const Flex = styled.div`
	flex: ${({ flex }) => flex || 1};
	display: flex;
	flex-direction: ${({ direction }) => direction || 'row'};

	&.title {
		justify-content: center;
		display: flex;
		flex-direction: column !important;

		h1 {
			margin: 0 0 4px;
			font-family: Roboto;
			font-size: 50px;
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: -0.5px;
			text-align: left;
			color: #121212;
		}

		h4 {
			font-family: Roboto;
			font-size: 24px;
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			color: rgba(18, 18, 18, 0.87);
			margin: 15px 0px 0px 0px;
		}
	}

	&.invisible-scrollbar::-webkit-scrollbar,
	&.invisible-scrollbar .invisible::-webkit-scrollbar {
		width: 0px !important;
	}

	&.invisible-scrollbar::-webkit-scrollbar-track,
	&.invisible-scrollbar .invisible::-webkit-scrollbar-track {
		background: transparent !important;
	}

	&.invisible-scrollbar::-webkit-scrollbar-thumb,
	&.invisible-scrollbar .invisible::-webkit-scrollbar-thumb {
		background-color: #f2b1b1 !important;
		border-radius: 0px !important;
		border: 0px solid transparent !important;
	}

	.chat-box {
		margin-bottom: 0px !important;
	}
`;

export const OuterWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 0;
	/**
   * For human readability, the ratio is expressed as
   * width / height, so we need to invert it.
   */
	padding-bottom: ${(1 / 1.16) * 100}%;
`;
export const InnerWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 25px;
	bottom: 0;
	left: 13px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	margin-top: ${({ marginTop }) => marginTop || 0}px;
	margin-bottom: 8px;
	background-image: ${({ src }) => `url(${src})`};
`;

export const HeaderSquare = ({ src, marginTop }) => (
	<OuterWrapper>
		<InnerWrapper src={src} marginTop={marginTop} />
	</OuterWrapper>
);

export const ImageSquare = styled.div`
	width: ${({ width }) => width || 160}px;
	height: ${({ height }) => height || 122}px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	margin: auto;
	background-image: ${({ src }) => `url(${src})`};
`;

export const SectionHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 20px 0px;

	h6 {
		font-family: Roboto;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: 0.15px;
		text-align: left;
		color: rgba(18, 18, 18, 0.6);
		margin: 36px 0px 14px 0px;
		text-transform: uppercase;
		margin: 0px;

		small {
			margin-left: 30px;
		}
	}

	button {
		width: 48px;
		height: 48px;
		padding: 13px 12px 11px;
		box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
		background-color: #f3f3f3;
		margin-right: 25px;
	}
`;

export const HelpListItem = styled.div`
	display: flex;
	flex-direction: row;
	max-height: 44px;
	margin: 10px;
	padding: 12px 40px 12px 12px;
	border-radius: 10px;
	background-color: #f8f8f8;
	justify-content: center;
	align-items: center;
	color: rgba(18, 18, 18, 0.87);
	cursor: pointer;

	& > div {
		flex: 1;
		margin: 0px 20px;
		* {
			font-family: Roboto;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
		}
	}
`;

export const HelpCard = styled.div`
	margin: 15px;
	padding: 9px 0 0;
	border-radius: 25px;
	min-height: 87px;
	border: ${({ selected }) => (selected ? `2px solid #42a5f5` : `1px solid rgba(18, 18, 18, 0.1)`)};
	flex: 1;
	overflow-x: hidden;
	cursor: pointer;
	text-decoration: none !important;

	.mask {
		flex: 1;
		background-color: red;
		z-index: 2;
		position: absolute;
	}

	.footer.ellipsis {
		p {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			${mediaTabletOnly} {
				-webkit-line-clamp: 5;
			}
		}
	}

	.footer {
		font-family: Roboto;
		padding: 6px 31px 3px 36px;
		background-color: ${({ selected }) => (selected ? 'rgba(200, 228, 243, 0.72)' : 'rgba(255, 253, 253, 0.62)')};

		h2,
		h3 {
			font-family: Roboto;
			font-size: 18px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: 3.3px;
			text-align: left;
			color: rgba(18, 18, 18, 0.87);
			text-transform: uppercase;
			text-decoration: none !important;
		}

		p {
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: justify;
			color: rgba(18, 18, 18, 0.87);
			text-decoration: none !important;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&.result-item {
			padding: 11px 20px 3px 43px !important;
			background-color: transparent !important;
			p {
				max-width: 100% !important;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		&.result-item.related-article {
			padding: 10px 20px 3px 21px !important;
			display: inline-flex;

			#StickyNote {
				order: 1;
				margin: 1em 10px 0px 0px !important;
				font-size: 1.25rem !important;
			}
			#indicator {
				order: 3;
			}
			#content {
				order: 2;
				width: 100% !important;
			}

			${mediaTablet} {
				display: grid;
				width: unset;
				#StickyNote {
					order: 1;
					margin: -3px 10px 0px 0px !important;
				}
				#indicator {
					order: 2;
					margin-top: -20px;
				}
				#content {
					order: 3;
				}
			}
			width: 95%;
			h2 {
				font-size: 18px !important;
				letter-spacing: 0px !important;
				margin-bottom: 5.8px !important;
			}

			h3 {
				letter-spacing: 0px !important;
			}

			p {
				display: block;
			}
		}

		.indicator {
			display: flex;
			flex-direction: row-reverse;
			height: 20px;
		}

		.content {
			margin-top: -30px;
		}
	}
`;

export const AgentFAB = styled.button`
	width: 83px;
	height: 83px;
	position: fixed;
	z-index: 3;
	bottom: 58px;
	right: 83px;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
	background-color: #42a5f5;
	border: 0px;
	border-radius: 50%;
	cursor: pointer;

	${mediaTablet} {
		width: 50px;
		height: 50px;
		bottom: 4px;
		right: 16px;
	}
`;

export const OnlineIndicator = styled.div`
	width: 14px;
	height: 14px;
	border: solid 2px #f3f3f3;
	background-color: ${({ agentOnline }) => (agentOnline ? '#51e08a' : '#f44336')};
	position: absolute;
	right: 0px;
	border-radius: 50%;

	${mediaTablet} {
		width: 9px;
		height: 9px;
		right: 1px;
		bottom: 1px;
	}
`;

export const SearchResultTitle = styled.h6`
	font-family: Roboto;
	font-size: 28px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 0.86;
	letter-spacing: 0.15px;
	text-align: center;
	color: #393939;
	text-align: center;
	width: 100%;
	margin: 0px;
	margin-bottom: 20px;

	span {
		text-transform: capitalize;
	}
`;

export const HelpBadge = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0px 20px;
	border-radius: 16px;
	height: 32px;
	background-color: rgba(66, 165, 245, 0.1);
	width: max-content;

	span {
		font-size: 14px;
		color: #42a5f5;
		font-family: Roboto;
		text-transform: capitalize;
		margin-top: 8px;
	}
`;
export const ArticleWrapper = styled.div`
	border-radius: 25px;
	padding: 20px;
	border: solid 1px rgba(18, 18, 18, 0.1);
	font-family: Roboto;
	overflow: hidden;
	height: 80vh;
	display: flex;
	flex-direction: column;

	.header {
		padding: 0px !important;
		background: transparent;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
	}

	.content {
		flex: 1;
		overflow-y: auto;
		margin: 0px 42px 0px 42px;

		p {
			margin: 4px 0px !important;
		}

		&.invisible-scrollbar::-webkit-scrollbar {
			width: 0px !important;
		}

		&.invisible-scrollbar::-webkit-scrollbar-track {
			background: transparent !important;
		}

		&.invisible-scrollbar::-webkit-scrollbar-thumb {
			background-color: #f2b1b1 !important;
			border-radius: 0px !important;
			border: 0px solid transparent !important;
		}
	}

	h2,
	h3 {
		font-family: Roboto;
		font-size: 28px;
		font-weight: 400;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: normal;
		color: rgba(57, 57, 57, 0.87);
		margin: 0px 0px 22px 0px;
	}

	p {
		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: 0.15px;
		text-align: left;
		color: rgba(18, 18, 18, 0.87);
	}
`;
export const SearchWrapper = styled.div`
	z-index: 10;
	position: relative;

	#container {
		width: fit-content;
		margin: auto;
	}
`;

export const SuggestionsWrapper = styled.div`
	width: 560px;
	height: ${props => (props.isPip ? '100%' : '339px')};
	overflow-y: auto;
	margin: 6px auto;
	background: #ffffff;
	box-shadow: ${props => (props.isPip ? 'none' : '0px 4px 20px rgba(0, 0, 0, 0.1)')};
	border-radius: 15px;
	padding: 0px 20px 20px 20px;

	&.hidden {
		display: none !important;
	}
`;

export const RecentSection = styled.div`
	h4 {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.25px;
		text-transform: uppercase;
		color: #393939;
	}

	ul {
		padding: 0px;
		margin: 0px 0px 25px 0px;

		li {
			list-style: none;
			padding: 5px;
			margin: 0px;
			color: #393939;
			display: flex;
			flex-direction: row;
			align-items: center;
			cursor: pointer;

			&.pip-item {
				max-height: 44px;
				margin: 10px 10px 10px -20px;
				padding: 12px 40px 12px 12px;
				border-radius: 10px;
				background-color: #f8f8f8;
				justify-content: center;
				align-items: center;
				color: rgba(18, 18, 18, 0.87);
			}

			span {
				flex: 1;
				font-family: 'Roboto';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
				letter-spacing: 0.25px;
				margin: 0px 14px;
			}
		}
	}
`;

export const SearchInfoPeek = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px 12px 12px 0px;

	strong,
	span {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.25px;
		color: #393939;
	}

	strong {
		font-weight: 700;
		margin: 0px 9px 0px 16px;
		max-width: 300px;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;
