import { getRenderingEngine } from '@cornerstonejs/core';
import { renderingEngineId } from '../contexts/ImageViewerCornerstoneContext';

const getDefaultRenderingEngine = customId => {
	const defaultRenderingEngine = getRenderingEngine(customId || renderingEngineId);

	if (!defaultRenderingEngine) {
		return;
	}

	if (defaultRenderingEngine.hasBeenDestroyed) {
		return;
	}

	return defaultRenderingEngine;
};

export default getDefaultRenderingEngine;
