const getFilteredTabData = (data, tab, tabResourceType) =>
	data?.filter(
		item =>
			tab === 'All' ||
			(item.resourceType ?? item.ResourceType) === tabResourceType ||
			(item.resourceType ?? item.ResourceType) === 'DICOMResult'
	);

const getTabResourceType = tab =>
	({
		Study: 'ImagingStudyWorklist',
		Order: 'ServiceRequest',
		Visit: 'Encounter',
		User: 'Practitioner',
		Studies: 'BlumeStudy',
		Shared: 'StudySharedWithMe',
		Help: 'helpcenter',
		'Help Center': 'helpcenter',
	})[tab] || tab;

module.exports = {
	getFilteredTabData,
	getTabResourceType,
};
