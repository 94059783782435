import { Box } from '@mui/system';
import React from 'react';

function ProgressBarHeader({ months }) {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-around',
			}}
		>
			{months.map((month, index) => (
				<h3
					key={index}
					style={{
						fontSize: '14px',
						fontWeight: '500',
					}}
				>
					{month}
				</h3>
			))}
		</Box>
	);
}

export default ProgressBarHeader;
