import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

// MUI
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// DatePicker
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ClickAwayListener from '@mui/material/ClickAwayListener';

// components
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';

// Calendar localization
import { useTranslation } from 'react-i18next';
import { getDatePickerLocale, datePickerPaperProps } from '@worklist-2/ui/src/components/utils/calendarLocalization';

const DatePicker = ({ label, defaultValue, width, fullWidth, onChange, shouldShowResetButton, popperProps }) => {
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [selectedDate, setSelectedDate] = useState(defaultValue ?? null);

	const { i18n } = useTranslation();
	const { t } = useTranslation('workList');

	const handleCloseDatePicker = () => {
		setOpenDatePicker(false);
	};

	const handleOpenDatePicker = () => {
		setOpenDatePicker(true);
	};

	const resetToDefaultValue = () => {
		setSelectedDate(defaultValue ?? null);
	};

	const resetSelectedDate = () => {
		const date = defaultValue ?? null;
		onChange(date, date?.toLocaleDateString());
		handleCloseDatePicker();
	};

	const updateSelectedDate = () => {
		onChange(selectedDate, selectedDate?.toLocaleDateString());
		handleCloseDatePicker();
	};

	const DEBOUNCE_TYPING_DELAY = 2000;

	const debounceSetSelectedDate = useCallback(
		_.debounce(dateValue => {
			const date = moment(dateValue, 'MM/DD/yyyy').toDate();
			setSelectedDate(date);
			onChange(date, date?.toLocaleDateString());
		}, DEBOUNCE_TYPING_DELAY),
		[]
	);

	return (
		<ClickAwayListener onClickAway={handleCloseDatePicker}>
			<Box>
				<LocalizationProvider adapterLocale={getDatePickerLocale(i18n)} dateAdapter={AdapterDateFns}>
					<DesktopDatePicker
						PaperProps={datePickerPaperProps}
						PopperProps={popperProps}
						closeOnSelect={false}
						components={{
							ActionBar: () => (
								<Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
									<SecondaryButton label={t('cancelUpper')} onClick={handleCloseDatePicker} />
									{shouldShowResetButton && (
										<SecondaryButton label={t('reset')} onClick={resetSelectedDate} />
									)}
									<PrimaryButton
										disabled={!selectedDate}
										label={t('select')}
										onClick={updateSelectedDate}
									/>
								</Box>
							),
						}}
						inputFormat="MM/dd/yyyy"
						label={label}
						open={openDatePicker}
						renderInput={params => (
							<>
								<TextField
									InputLabelProps={{ shrink: true }}
									InputProps={{
										endAdornment: params.InputProps.endAdornment,
										startAdornment: undefined,
									}}
									fullWidth={fullWidth}
									id={`${label}_filter`}
									label={label}
									sx={{
										width,
									}}
									onChange={event => {
										debounceSetSelectedDate(event.target.value);
									}}
									{...params}
									error={false}
									inputProps={{ ...params.inputProps, placeholder: t('mm/dd/yyyy') }}
								/>
								{/* Added button for reset to default value */}
								<Button
									data-cy={`${label}_filter_reset`}
									data-testid={`${label}_filter_reset`}
									sx={{ display: 'none' }}
									onClick={resetToDefaultValue}
								/>
							</>
						)}
						value={selectedDate}
						views={['year', 'month', 'day']}
						onChange={val => {
							val && setSelectedDate(val);
						}}
						onOpen={handleOpenDatePicker}
					/>
				</LocalizationProvider>
			</Box>
		</ClickAwayListener>
	);
};

DatePicker.defaultProps = {
	width: 300,
	fullWidth: false,
};

DatePicker.propTypes = {
	/**
	 * Use fullWidth
	 */
	fullWidth: PropTypes.bool,
};

export default DatePicker;
