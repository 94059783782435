import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import _ from 'lodash';
import React, { useState } from 'react';
import { TabPanel } from '../../../casePopOverStyles';
import NewContacts from './NewContacts';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const Contacts = ({ editable, data, onChangeData, canEdit = true, handleSubmit }) => {
	const [editMode, setEditMode] = useState(false);
	return (
		<TabPanel>
			<div className="header">
				<div className="left">
					<div className="colored-icon primary-icon">
						<PersonOutlinedIcon sx={{ fontSize: 20 }} />
					</div>
					<h2>Related Contacts</h2>
					{editMode ? (
						<RemoveIcon
							sx={{ color: '#4D79EA', marginLeft: 0.5, cursor: 'pointer' }}
							onClick={() => setEditMode(prevVal => !prevVal)}
						/>
					) : (
						<AddIcon
							sx={{ color: '#4D79EA', marginLeft: 0.5, cursor: 'pointer' }}
							onClick={() => setEditMode(prevVal => !prevVal)}
						/>
					)}
				</div>
			</div>
			<div className="body">
				{canEdit ? (
					<NewContacts
						data={data}
						editMode={editMode}
						handleSubmit={handleSubmit}
						onChangeData={onChangeData}
					/>
				) : (
					<>
						{_.map(data, (contact, i) => (
							<ContactListItem>
								<ContactAvatar src={ContactImg} />
								<div className="main">
									<div style={{ flex: 1, marginRight: '10px' }}>
										<h3>{contact.name}</h3>
										<label>{contact.jobTitle}</label>
									</div>
									<Stack spacing="12px" sx={{ width: '200px' }}>
										<ContactText style={{ marginBottom: '10px' }}>
											<PhoneIcon
												sx={{
													color: '#4D79EA',
													fontSize: '18px',
													marginRight: '12px',
												}}
											/>
											<span>{contact.phone}</span>
										</ContactText>
										<ContactText>
											<EmailIcon
												sx={{
													color: '#4D79EA',
													fontSize: '18px',
													marginRight: '12px',
												}}
											/>
											<span>{contact.email}</span>
										</ContactText>
									</Stack>
								</div>
							</ContactListItem>
						))}
					</>
				)}
			</div>
		</TabPanel>
	);
};

Contacts.propType = {
	editable: PropTypes.bool,
};

export default Contacts;
