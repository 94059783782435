// React Libraries
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GridLayout from 'react-grid-layout';
import axios from 'axios';

// Material UI Libraries
import ListItemButton from '@mui/material/ListItemButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InputBase from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tooltip from '@mui/material/Tooltip';
import { useMediaQuery } from '@mui/material';

// Custom Component Libraries
import ChartRender from '../BreezeReportDetailsView/components/ChartRender';
import ChartBox from '../BreezeReportDetailsView/components/ChartBox';
import Avatar from '@worklist-2/ui/src/components/Avatar';
import { Svg } from '@worklist-2/ui/src/components/Svg';
import FilterSideContent from './components/FilterSideContent';
import NewReport from './components/NewReport';
import ReportOptions from './components/ReportOptions';
import _ from 'lodash';

// Worklist Core Libraries
import { crmSearchScopes, useAuth, useConfig, useCRMDataLoader } from '@worklist-2/core/src';

// Custom Chart Images
import BarChart from '../../assets/Bar_Chart.png';
import LineChart from '@worklist-2/ui/src/assets/img/line_chart.png';
import PieChart from '@worklist-2/ui/src/assets/img/pie_chart.png';
import EmptyCard from '@worklist-2/ui/src/assets/img/empty_cards.png';

function ReportBlankPageView() {
	const [isFilter, setIsFilter] = useState(false);
	const [isExpand, setIsExpand] = useState(false);
	const [isReport, setIsReport] = useState(false);
	const [open, setOpen] = useState(false);
	const [reportName, setReportName] = useState('New Report');
	const [scripts, setScripts] = useState([]);
	const [reportJson, setReportJson] = useState({});
	const [chartIndex, setChartIndex] = useState();
	const [chartType, setChartType] = useState(null);
	const [chartImage, setChartImage] = useState(null);
	const navigate = useNavigate();
	const __config = useConfig();
	const [cubeToken, setCubeToken] = useState();
	const isSmallScreen = useMediaQuery('(max-width:1100px)');

	const fhirDataLoaderReport = useCRMDataLoader({
		scope: crmSearchScopes.reportApi,
	});

	const [dataSaverReport] = useState(fhirDataLoaderReport);
	const { loggedInUser, authorized } = useAuth();

	const [layout, setLayout] = useState([
		{
			w: 4,
			h: 4,
			x: 0,
			y: 0,
			i: '1',
			minW: 3,
			minH: 4,
			maxH: 6,
			moved: false,
			static: false,
		},
	]);

	const handleLayout = (_scripts, isAddNew = false) => {
		layoutChange(layout, _scripts, isAddNew);
	};

	const layoutChange = (_layout, _scripts, isAddNew) => {
		const lastLayout = _layout[_layout.length - 1];

		if (isAddNew) {
			const lastScript = _scripts[_scripts.length - 1];
			// The size of the card should be 1/4 of the regular visual to start
			if (lastScript.chartType == 'number') {
				lastLayout.w = 2;
				lastLayout.h = 2;
				lastLayout.minW = 2;
				lastLayout.minH = 2;
			}
		}

		const newLayout = {
			w: 4,
			h: 4,
			x: lastLayout ? (lastLayout.x + 4 > 8 ? 0 : lastLayout.x + 4) : 0,
			y: lastLayout ? (lastLayout.x >= 8 ? lastLayout.y + 4 : lastLayout.y) : 0,
			i: lastLayout ? String.fromCharCode(lastLayout.i.charCodeAt(0) + 1) : '1',
			minW: 3,
			minH: 4,
			maxH: 6,
			moved: false,
			static: false,
		};
		setLayout([..._layout, newLayout]);
	};

	const [filterCharts, setFilterChart] = useState([{ name: 'Add Card' }]);

	const handleFilterChart = () => {
		const newFilterChart = [...filterCharts];
		newFilterChart.unshift({ name: 'Filter Name' });
		setFilterChart(newFilterChart);
	};

	const debounceSetReportName = useCallback(
		_.debounce(nextValue => {
			if (nextValue !== reportName) {
				setReportName(nextValue);

				if (scripts.length > 0) {
					const reportPayload = {
						...reportJson,
						name: nextValue,
					};

					saveData(dataSaverReport, { id: reportPayload?.id }, reportPayload);
				}
			}
		}, 1000),
		[reportJson, scripts, reportName]
	);

	useEffect(() => {
		if (chartType === 'GRID_CHART') {
			setIsExpand(false);
			setScripts([...scripts, { name: 'GRID_CHART' }]);
			/*
				please aware that only add new chart need to add new layout,
				editing existing chart don't need to do so.
				please fix it when work on this feature.
			*/
			handleLayout();
		}
	}, [chartType]);

	const saveData = useCallback(
		async (loader, props, payload) => {
			let result = {};
			if (authorized) {
				try {
					result = await loader.save(props, payload).then(res => res.data);
				} catch (e) {
					console.error(e);
				}
			}
			return result;
		},
		[authorized]
	);

	const onSaveReport = useCallback(
		async script => {
			if (chartType === 'GRID_CHART') {
				setIsExpand(false);
				setIsReport(false);
				setScripts([...scripts, { name: chartType }]);
				/*
					please aware that only add new chart need to add new layout,
					editing existing chart don't need to do so.
					please fix it when work on this feature.
				*/
				handleLayout();
				return;
			}

			let reportPayload = {};
			let modifiedScripts;
			if (chartIndex === scripts.length) {
				modifiedScripts = [...scripts, script];
				handleLayout(modifiedScripts, true);
			} else {
				scripts[chartIndex] = script;
				modifiedScripts = scripts;
			}
			if (Object.keys(reportJson).length === 0) {
				reportPayload = {
					id: `-1`,
					resourceType: `Report`,
					created: `${new Date().toISOString()}`,
					name: `${reportName || 'New Report'}`,
					owner: {
						id: `${loggedInUser.id}`,
						display: `${loggedInUser.fullName}`,
					},
					forRead: [
						{
							id: `${loggedInUser.id}`,
							display: `${loggedInUser.fullName}`,

							extension: [
								{
									url: 'privilege',
									valueString: 'admin',
								},
								{
									url: 'favorite',
									valueBoolean: false,
								},
							],
						},
					],
					script: modifiedScripts.map(s => `${JSON.stringify(s)}`),
				};
			} else {
				reportPayload = {
					...reportJson,
					script: modifiedScripts.map(s => `${JSON.stringify(s)}`),
					name: `${reportName || 'New Report'}`,
				};
			}

			reportPayload = {
				...reportPayload,
				layout,
			};

			setScripts(modifiedScripts);

			const reportProps = reportJson ? { id: reportJson.id } : {};

			const res = await saveData(dataSaverReport, reportProps, reportPayload);

			if (res && res.resourceType === 'Report') {
				setReportJson(res);
			}

			setIsExpand(false);
			setIsReport(false);
		},
		[reportJson, reportName, chartIndex, layout, loggedInUser, chartType]
	);

	const onLayoutChange = async layouts => {
		if (JSON.stringify(layout) !== JSON.stringify(layouts)) {
			let reportPayload = {};
			if (Object.keys(reportJson).length === 0) {
				reportPayload = {
					id: `-1`,
					resourceType: `Report`,
					created: `${new Date().toISOString()}`,
					name: `${reportName || 'New Report'}`,
					owner: {
						id: `${loggedInUser.id}`,
						display: `${loggedInUser.fullName}`,
					},
					forRead: [
						{
							id: `${loggedInUser.id}`,
							display: `${loggedInUser.fullName}`,
							extension: [
								{
									url: 'privilege',
									valueString: 'admin',
								},
								{
									url: 'favorite',
									valueBoolean: false,
								},
							],
						},
					],
				};
			} else {
				reportPayload = {
					...reportJson,
				};
			}

			reportPayload = {
				...reportPayload,
				layout: layouts,
			};
			setLayout(layouts);

			const reportProps = reportJson ? { id: reportJson.id } : {};

			const res = await saveData(dataSaverReport, reportProps, reportPayload);

			if (res && res.resourceType === 'Report') {
				setReportJson(res);
			}
		}
	};

	const convertScriptJson = script => {
		const returnScript = [];

		if (!_.isEmpty(script)) {
			_.forEach(script, item => {
				try {
					returnScript.push(JSON.parse(item));
				} catch (error) {}
			});
		}

		return returnScript;
	};

	const fetchCubeToken = () => {
		axios
			.get(`${__config.data_sources.breeze}/report/cube/token`, {
				headers: {
					Accept: '*/*',
				},
			})
			.catch(error => {
				console.error('error fetching Cube token.', error);
			})
			.then(resp => {
				if (resp?.status === 200) {
					setCubeToken(resp.data);
				}
			});
	};

	const onDeleteChart = useCallback(
		index => {
			const modifiedScripts = convertScriptJson(reportJson.script);
			const layouts = convertScriptJson(reportJson.layout);

			modifiedScripts.splice(index, 1);
			layouts.splice(index, 1);

			const reportPayload = {
				...reportJson,
				layout: layouts,
				script: modifiedScripts.map(s => `${JSON.stringify(s)}`),
			};

			layoutChange(layouts);
			setScripts(modifiedScripts);
			const reportProps = reportJson ? { id: reportJson.id } : {};
			saveData(dataSaverReport, reportProps, reportPayload).then(res => {
				if (res && res.resourceType === 'Report') {
					setReportJson(res);
				}
			});
		},
		[reportJson, layout]
	);

	useEffect(() => {
		const header = document.getElementById('page-header');
		if (header) {
			header.style.filter = `${isReport && !!cubeToken ? 'blur(1.5px)' : 'blur(0px)'}`;
			header.style.opacity = `${isReport && !!cubeToken ? '0.2' : '1'}`;
		}
	}, [isReport, cubeToken]);

	useEffect(() => {
		if (authorized) {
			fetchCubeToken();
		}
	}, [authorized]);

	useEffect(() => {
		if (scripts) {
			if (scripts[chartIndex]?.chartType == 'bar') {
				setChartType('BAR_CHART');
				setChartImage(BarChart);
			} else if (scripts[chartIndex]?.chartType == 'line') {
				setChartType('LINE_CHART');
				setChartImage(LineChart);
			} else if (scripts[chartIndex]?.chartType == 'pie') {
				setChartType('PIE_CHART');
				setChartImage(PieChart);
			} else if (scripts[chartIndex]?.chartType == 'number') {
				setChartType('CROP_CHART');
				setChartImage(EmptyCard);
			} else if (scripts[chartIndex]?.chartType == 'table') {
				setChartType('TABLE_CHART');
			}
		}
	}, [chartIndex]);

	return (
		<>
			{/*
			<Button
				sx={{
					position: 'fixed',
					right: '45px',
					bottom: '45px',
					width: '70px',
					height: '70px',
					borderRadius: '100px',
					background: '#42A5F5',
					color: '#FFFFFF',
					zIndex: 1,
					'&:hover': {
						background: '#28679A',
					},
				}}
			>
				<BusinessCenterIcon />
			</Button>
			Disabled for now. we can re-enable it if this featur is implemented */}

			<Box
				sx={{
					margin: '20px 80px',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						position: 'fixed',
						left: isSmallScreen ? '100px' : '130px',
						top: '120px',
						right: '75px',
						opacity: `${isReport && !!cubeToken ? '0.2' : '1'}`,
						filter: `${isReport && !!cubeToken ? 'blur(1px)' : 'blur(0px)'}`,
					}}
				>
					<Stack alignItems="center" direction="row" justifyContent="center">
						<Button
							sx={{
								minWidth: 54,
								height: 54,
								marginRight: '10px',
								background: '#FFF',
								borderRadius: '6px',
								boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
							}}
							onClick={() => navigate('/stats')}
						>
							<Svg name="rootlogo" />
						</Button>

						<Box
							sx={{
								background: '#FFF',
								border: '1px solid rgba(255, 255, 255, 0.1)',
								boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.11)',
								borderRadius: '6px',
								color: '#FFFFFF',
								display: 'flex',
								padding: '0 16px',
								height: 54,
								alignItems: 'center',
								justifyContent: 'flex-start',
								width: '230px',
							}}
						>
							<Box
								sx={{
									width: 34,
									height: 34,
									background: '#4D79EA',
									borderRadius: '100px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									marginRight: '10px',
								}}
							>
								<Tooltip title={reportName}>
									<div
										style={{
											minWidth: '28px',
											minHeight: '28px',
										}}
									>
										<Avatar
											transparent
											color="#FFF"
											fontSize={14}
											height={28}
											label={reportName}
											showEllipsis={false}
											width={28}
										/>
									</div>
								</Tooltip>
							</Box>
							<Box>
								<InputBase
									defaultValue={reportName}
									sx={{
										maxWidth: '180px',
										color: '#000',
										fontSize: '20px',
										fontWeight: 500,
									}}
									onChange={e => debounceSetReportName(e.target.value)}
								/>
							</Box>
						</Box>
					</Stack>

					<Box
						sx={{
							position: 'relative',
						}}
					>
						{/*
						<Stack
							onMouseEnter={() => setOpen(true)}
							alignItems={'center'}
							justifyContent={'center'}
							sx={{
								border: '1px solid rgba(255, 255, 255, 0.1)',
								borderRadius: '20px',
								background: 'rgba(57, 57, 57, 0.5)',
								minWidth: 54,
								height: 54,
								color: 'rgba(255, 255, 255, 0.6)',
								'&:hover': {
									border: '1px solid rgb(66 165 245)',
								},
							}}
						>
							<MoreHorizOutlinedIcon />
						</Stack>
						Disabled for now. we can re-enable it if this featur is implemented
						*/}
						{open && (
							<ClickAwayListener onClickAway={() => setOpen(false)}>
								<List
									sx={{
										position: 'absolute',
										background: '#393939',
										borderRadius: '15px',
										width: '150px',
										color: '#FFFFFFDE',
										right: 0,
										marginTop: 2,
										zIndex: 10,
										transition: '0.2s',
									}}
									onClick={() => setOpen(false)}
									onMouseEnter={() => setOpen(true)}
									onMouseLeave={() => setOpen(false)}
								>
									<ListItem disablePadding>
										<ListItemButton>
											<ListItemIcon sx={{ minWidth: '40px' }}>
												<GetAppOutlinedIcon />
											</ListItemIcon>
											<ListItemText primary="Download" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton>
											<ListItemIcon sx={{ minWidth: '40px' }}>
												<DeleteOutlineOutlinedIcon />
											</ListItemIcon>
											<ListItemText primary="Delete" />
										</ListItemButton>
									</ListItem>
								</List>
							</ClickAwayListener>
						)}
					</Box>
				</Box>

				{/*!isReport && (
					<Stack sx={{ color: '#FFFFFF99', marginTop: '30px' }}>
						<IconButton
							aria-label="clear"
							sx={{ marginLeft: 'auto' }}
							onClick={() => setIsFilter(true)}
						>
							<FilterAltIcon />
						</IconButton>
					</Stack>
				)}

				<Stack direction={"row"} alignItems={"center"} spacing={2}>
					<ReportFilter items={DEMO_FILTER} name={"Issuer"}/>
					<ReportFilter items={DEMO_FILTER} name={"Fcility"}/>
					<ReportFilter items={DEMO_FILTER} name={"Modality"}/>
				</Stack>

				)
				Disabled for now. we can re-enable it if this featur is implemented
				*/}
				<Box
					sx={{
						marginTop: '184px',
						marginLeft: '-50px',
						position: 'relative',
					}}
					onClick={() => isExpand && setIsExpand(false)}
				>
					<GridLayout
						cols={12}
						layout={layout}
						rowHeight={100}
						width={window.innerWidth - 250}
						onLayoutChange={onLayoutChange}
					>
						{layout.map((el, index) => (
							<Box
								key={el.i}
								sx={{
									height: '100%',
									width: '100%',
									background: 'rgba(66, 165, 245, 0.05)',
									borderRadius: '20px',
									cursor: 'pointer',
									'.react-resizable-handle::after, .react-resizable-handle.react-resizable-handle-se':
										{
											right: 8,
											bottom: 8,
										},
								}}
							>
								{index === scripts.length ? (
									<Stack
										sx={{
											alignItems: 'center',
											justifyContent: 'center',
											height: '100%',
											textAlign: 'center',
											border: '1px solid rgba(255, 255, 255, 0.2)',
											borderRadius: '20px',
											'&:hover': {
												border: '1px dashed #42A5F5',
												'& .MuiBox-root': {
													color: '#FFFFFF',
												},
											},
										}}
									>
										<Box
											sx={{
												color: 'rgba(255, 255, 255, 0.6)',
												'&:hover': {
													color: '#FFFFFF',
												},
											}}
											onClick={() => {
												setIsExpand(true);
												setChartIndex(index);
											}}
										>
											{isExpand ? (
												<ReportOptions
													setChartImage={setChartImage}
													setChartType={setChartType}
													setIsReport={setIsReport}
												/>
											) : (
												<Box
													sx={{
														textAlign: 'center',
														color: 'rgba(255, 255, 255, 0.6)',
													}}
												>
													<AddCircleIcon
														sx={{
															color: '#4D79EA',
															fontSize: 38,
														}}
													/>
													<Typography
														fontSize={20}
														marginTop={1}
														sx={{
															color: '#4D79EA',
														}}
													>
														Click here to add new
													</Typography>
												</Box>
											)}
										</Box>
									</Stack>
								) : (
									<Box
										sx={{
											height: '100%',
											width: '100%',
										}}
									>
										{scripts[index] && (
											<ChartBox
												chartTitle={scripts[index]?.chartTitle}
												chartType={scripts[index]?.chartType}
												index={index}
												isHideToolBar={!!scripts[index]}
												setChartIndex={setChartIndex}
												setIsReport={setIsReport}
												onDeleteChart={onDeleteChart}
											>
												<Box
													sx={{
														height: '87%',
														width: '100%',
														paddingBottom: '15px',
													}}
												>
													{!!scripts[index] && !!cubeToken && (
														<ChartRender
															chartType={scripts[index].chartType}
															columnOrder={scripts[index].columnOrder}
															cubeToken={cubeToken}
															library={scripts[index].library}
															query={scripts[index].query}
														/>
													)}
												</Box>
											</ChartBox>
										)}
									</Box>
								)}
							</Box>
						))}
					</GridLayout>

					{isReport && !!cubeToken && (
						<NewReport
							chartImage={chartImage}
							chartType={chartType}
							cubeToken={cubeToken}
							script={scripts[chartIndex] ? scripts[chartIndex] : null}
							setChartImage={setChartImage}
							setChartType={setChartType}
							setIsReport={setIsReport}
							onSaveReport={onSaveReport}
						/>
					)}
				</Box>
			</Box>

			{isFilter && !isReport && <FilterSideContent setIsFilter={setIsFilter} />}
		</>
	);
}

export default ReportBlankPageView;
