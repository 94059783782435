import axios from 'axios';

const getOrganizationLogo = async ({ __config, orgId, filePath }) => {
	if (!filePath) return null;
	const response = await axios.get(`${__config.data_sources.blume}Organization/logo/${orgId}?filePath=${filePath}`, {
		headers: {
			'X-API-KEY': window.btoa(__config.patient_portal.authentication_key),
		},
		responseType: 'blob',
	});
	if (response?.status === 200) {
		return response?.data;
	}
};

export default getOrganizationLogo;
