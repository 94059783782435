// core
import React from 'react';
// libraries
import PropTypes from 'prop-types';
// mui
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export const LoadingWrapper = ({ children, error, isLoading, onCloseDicomSearch }) =>
	error || isLoading ? (
		<div>
			<div>
				{error ? (
					<div
						style={{
							color: 'red',
							marginTop: '50%',
							marginBottom: '50%',
							position: 'relative',
						}}
					>
						{error}
					</div>
				) : (
					<CircularProgress
						size={36}
						style={{
							marginTop: '50%',
							marginLeft: '40%',
							marginBottom: '50%',
							position: 'relative',
						}}
					/>
				)}
				<Button
					color="primary"
					style={{
						display: 'block',
						marginLeft: 'auto',
					}}
					variant="outlined"
					onClick={onCloseDicomSearch}
				>
					CANCEL
				</Button>
			</div>
		</div>
	) : (
		children
	);

LoadingWrapper.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element.isRequired]),
	error: PropTypes.string,
	isLoading: PropTypes.bool,
	onCloseDicomSearch: PropTypes.func,
};
