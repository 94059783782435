import React, { createContext, useContext, useState, useEffect } from 'react';

const ModalContext = createContext({});

export const ModalProvider = ({ children }) => {
	const [searchTab, setSearchTabs] = useState('All');
	const [isVisible, setTabVisible] = useState(false);

	const updateSearchTabs = tab => {
		setSearchTabs(tab);
	};

	const updateTabVisibility = visible => {
		setTabVisible(visible);
	};

	return (
		<ModalContext.Provider value={{ searchTab, isVisible, updateSearchTabs, updateTabVisibility }}>
			{children}
		</ModalContext.Provider>
	);
};

export const useModalContext = () => useContext(ModalContext);

export const useModal = () => {
	const modalContext = useModalContext();
	const [modal, setModal] = useState(null);

	return {
		modal,
		modalContext,
		openModal: setModal,
		closeModal: () => {
			setModal(null);
			//reverting transparent background change for terms of service and privacy policy
			const element = document.getElementsByTagName('body')[0];
			element.style.backgroundColor = '';
		},
	};
};

export default ModalProvider;
