import React, { useState } from 'react';
import AuditLogGrid from '../AuditLogGrid';
import PropTypes from 'prop-types';

const AuditLog = ({ className, name, columns, onColumnOrderChange, filters, setFilters, sort, setSort, children }) => (
	<div>
		{children}
		<AuditLogGrid
			className={className}
			columns={columns}
			filters={filters}
			name={name}
			setFilters={setFilters}
			setSort={setSort}
			sort={sort}
			onColumnOrderChange={onColumnOrderChange}
		/>
	</div>
);

AuditLog.propTypes = {
	/**
	 * A list of column names to be displayed in the grid: ['column1', 'column2', 'column2']
	 */
	columns: PropTypes.arrayOf(PropTypes.string),
	/**
	 * Callback function when column order has been changed by dragging
	 */
	onColumnOrderChange: PropTypes.func,
	/**
	 * Array of filter objects
	 */
	filters: PropTypes.arrayOf(PropTypes.object),
	/**
	 * Setter function for filters
	 */
	setFilters: PropTypes.func,
};

export default AuditLog;
