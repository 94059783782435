import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Divider from '@mui/material/Divider';
import CircleIcon from '@mui/icons-material/Circle';
import { Stack, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CircularProgress from '@mui/material/CircularProgress';
import TextEditor from '../../../components/CRM/Cases/CaseDetailPopOver/DetailTabs/Comments/TextEditor/TextEditor';

const useStyles = makeStyles()(() => ({
	tooltip: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#4D79EA',
		borderRadius: '5px',
		width: '90px',
		height: '30px',
		fontSize: '14px',
		fontWeight: 500,
		textTransform: 'Uppercase',
	},

	arrow: {
		color: '#4D79EA',
		fontSize: '16px',
	},
}));

export const OptionBody = props => {
	return <Stack {...props} direction={'row'} spacing={1} alignItems={'center'} mb={'1rem'} />;
};
export const OptionText = props => {
	return <Typography {...props} />;
};

export const SectionsContainer = props => {
	return (
		<Box
			{...props}
			maxHeight={'700px'}
			sx={{
				overflowY: 'scroll',
				scrollbarWidth: '4px',
				scrollbarColor: 'rgba(196, 196, 196, 1)',
				'::-webkit-scrollbar': {
					width: '4px',
				},
				'::-webkit-scrollbar-thumb': {
					background: 'rgba(196, 196, 196, 1)',
					borderRadius: '10px',
				},
				'::-webkit-scrollbar-track': {
					background: 'transparent',
				},
			}}
		/>
	);
};

export const SectionTitle = props => {
	return (
		<Typography
			{...props}
			sx={{
				fontSize: '18px',
				fontWeight: 500,
				letterSpacing: '1px',
				color: 'rgba(0,0,0,0.9)',
				textTransform: 'Uppercase',
			}}
		/>
	);
};

export const SectionNotifCount = props => {
	return (
		<Typography
			{...props}
			sx={{
				width: '38px',
				height: '27px',
				borderRadius: '6px',
				background: 'rgba(196, 196, 196, 0.35)',
				fontSize: '18px',
				fontWeight: 500,
				color: 'rgba(0, 0, 0, 0.6)',
				ml: '8px',
				textAlign: 'center',
			}}
		/>
	);
};

export const IconContainer = props => {
	return (
		<Stack
			{...props}
			justifyContent={'center'}
			alignItems={'center'}
			sx={{
				width: '54px',
				height: '54px',
				borderRadius: '50px',
				background: '#F9FAFC',
				mr: '-24px',
				ml: '-10px',
				zIndex: 1,
			}}
		/>
	);
};

export const Description = props => {
	return (
		<Typography
			{...props}
			ml={'3rem'}
			sx={{
				maxHeight: '45px',
				width: '550px',
				fontSize: '14px',
				fontWeight: 400,
				color: 'rgba(0, 0, 0, 0.9)',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				display: '-webkit-box',
				WebkitLineClamp: '2',
				WebkitBoxOrient: 'vertical',
			}}
		/>
	);
};

export const CommentDescription = props => {
	return (
		<TextEditor
			{...props}
			readOnly={true}
			sx={{
				maxHeight: '45px',
				width: '550px',
				fontSize: '14px',
				fontWeight: 400,
				marginLeft: '3rem',
				color: 'rgba(0, 0, 0, 0.9)',
				lineHeight: '14.52px',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				display: '-webkit-box',
				WebkitLineClamp: '2',
				WebkitBoxOrient: 'vertical',
			}}
		/>
	);
};

export const NotificationBody = props => {
	return (
		<Stack
			className={'body'}
			{...props}
			justifyContent={'center'}
			alignItems={'center'}
			direction={'row'}
			sx={{
				width: '739px',
				height: '92px',
				background: '#FFF',
				boxShadow: '0px 0px 20px rgba(33, 88, 119, 0.1)',
				borderRadius: '10px',
				cursor: 'pointer',
				'&:hover .delete-hover-button': {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '60px',
					height: '100%',
					background: '#EF5D5D',
					borderRadius: '0 10px 10px 0',
					transition: '300ms ease-in-out',
				},
				'&:hover .delete-icon': {
					display: 'box',
					color: '#FFF',
					fontSize: '24px',
				},
			}}
		/>
	);
};

export const DeleteButton = props => {
	return (
		<Box
			{...props}
			ml={'auto'}
			sx={{
				'&.delete-hover-button': {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: 0,
					height: '100%',
					background: 'transparent',
					borderRadius: '10px',
					transition: '300ms ease-in-out',
				},
			}}
		/>
	);
};

export const Type = props => {
	return (
		<Typography
			{...props}
			mr={'5%'}
			sx={{
				width: 'auto',
				fontSize: '14px',
				fontWeight: 400,
				fontStyle: 'Italic',
				color: 'rgba(0, 0, 0, 0.6)',
			}}
		/>
	);
};

export const StepTrack = props => {
	return (
		<Divider
			{...props}
			flexItem
			orientation={'vertical'}
			sx={{
				borderStyle: 'dashed',
				borderColor: '#D9D9D9',
				height: '100%',
				marginLeft: '12px',
			}}
		/>
	);
};

export const Step = props => {
	const { classes } = useStyles();
	return (
		<Stack
			position={'absolute'}
			height={'60px'}
			left={'12px'}
			alignItems={'center'}
			justifyContent={'center'}
			sx={{
				'&:hover .vl-hover': {
					borderColor: '#4D79EA',
					height: '60px',
					transition: '300ms ease-in-out',
				},
			}}
		>
			<Tooltip
				title={props.title}
				arrow
				placement={'left'}
				classes={{
					tooltip: classes.tooltip,
					arrow: classes.arrow,
				}}
				enterDelay={150}
				leaveDelay={150}
			>
				<CircleIcon
					sx={{
						position: 'absolute',
						fontSize: '10px',
						color: props.notification.IsReaded ? '#D9D9D9' : '#4D79EA',
						transition: '300ms ease-in-out',
						zIndex: 1,
						'&:hover': {
							color: '#4D79EA',
							transition: '300ms ease-in-out',
						},
					}}
				/>
			</Tooltip>
			<Divider
				className={'vl-hover'}
				flexItem
				orientation={'vertical'}
				sx={{
					'&.vl-hover': {
						borderStyle: 'dashed',
						borderColor: props.notification.IsReaded ? '#D9D9D9' : '#4D79EA',
						height: props.notification.IsReaded ? 0 : '60px',
						transition: '300ms ease-in-out',
					},
				}}
			/>
		</Stack>
	);
};

export const DetailsBody = props => {
	return <Stack {...props} direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} />;
};

export const IconCage = props => {
	return (
		<Stack
			{...props}
			justifyContent={'center'}
			alignItems={'center'}
			sx={{
				width: '38px',
				height: '38px',
				borderRadius: '50px',
				background: props.bg,
			}}
		/>
	);
};

export const NoNotifications = props => {
	return (
		<Typography {...props} position={'absolute'} left={'50%'} top={'50%'}>
			No Notifications
		</Typography>
	);
};

export const ShowSpinner = props => {
	return (
		<Stack
			{...props}
			direction={'row'}
			position={'absolute'}
			left={'50%'}
			top={'50%'}
			alignItems={'center'}
			spacing={2}
		>
			<CircularProgress
				sx={{
					color: '#4D79EA',
					width: '24px !important',
					height: '24px !important',
				}}
			/>
			<Typography>Loading</Typography>
		</Stack>
	);
};
