import generateRandomString from '@rs-core/utils/generateRandomString';

const extractPhysiciansFromProfile = profile => {
	const myPhysiciansArray = (profile?.myPhysician || []).map(physician => ({
		id: generateRandomString(16),
		name: physician.name || '',
		specialisation: physician.specialisation || '',
		phone: physician.phone || '',
		email: physician.email || '',
	}));

	return myPhysiciansArray;
};

export { extractPhysiciansFromProfile };
