import React from 'react';

const MobileIcon = ({ fill }) => (
	<svg fill="none" height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_4186_16094)">
			<path
				d="M19.375 1.25H9.375C7.65 1.25 6.25 2.65 6.25 4.375V25.625C6.25 27.35 7.65 28.75 9.375 28.75H19.375C21.1 28.75 22.5 27.35 22.5 25.625V4.375C22.5 2.65 21.1 1.25 19.375 1.25ZM14.375 27.5C13.3375 27.5 12.5 26.6625 12.5 25.625C12.5 24.5875 13.3375 23.75 14.375 23.75C15.4125 23.75 16.25 24.5875 16.25 25.625C16.25 26.6625 15.4125 27.5 14.375 27.5ZM20 22.5H8.75V5H20V22.5Z"
				fill={fill || '#C4C4C4'}
			/>
		</g>
		<defs>
			<clipPath id="clip0_4186_16094">
				<rect fill="white" height="30" width="30" />
			</clipPath>
		</defs>
	</svg>
);

export default MobileIcon;
