import * as React from 'react';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/system';
import { mediaTablet } from '@worklist-2/core/src';

const blue = {
	100: '#DAECFF',
	200: '#80BFFF',
	400: '#3399FF',
	600: '#0072E5',
};

const grey = {
	50: '#F3F6F9',
	100: '#E7EBF0',
	200: '#E0E3E7',
	300: '#CDD2D7',
	400: '#B2BAC2',
	500: '#A0AAB4',
	600: '#6F7E8C',
	700: '#3E5060',
	800: '#2D3843',
	900: '#1A2027',
};

const StyledInputElement = styled('input')(
	({ theme }) => `
  width: 95%;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 1;
  color: ${grey[900]};
  background: #FFF;
  border: 0px;
  border-radius: 0px;
  margin: 10px 10px;
  background: transparent;

  &:hover {
    border: 0px;
  }

  &:focus {
    outline: 0px;
  }

  ${mediaTablet} {
	  width: 80%;
	  margin: 0 10px;
	  padding: 7px 12px;
  }
`
);

const CustomInput = React.forwardRef((props, ref) => (
	<InputBase components={{ Input: StyledInputElement }} {...props} ref={ref} />
));

export default function CustomTextInput(props) {
	return <CustomInput {...props} />;
}
