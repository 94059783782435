import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axios from 'axios';
import _ from 'lodash';
import { useConfig } from '@worklist-2/core/src';
import MobileGallery from '../MobileGallery/MobileGallery';
import MobileSharedWithSection from './MobileStudyDetailSections/MobileSharedWithSection';
import MobileReportsSection from './MobileStudyDetailSections/MobileReportsSection';
import MobileStudyInformationSection from './MobileStudyDetailSections/MobileStudyInformationSection';
import { REPORT_DATA } from '../../../__mocks__/mockData';
import FormsView from '../../../views/StudyInfoView/StudyDetailedView/FormsView';
import { useTranslation } from 'react-i18next';

const MobileStudyDetailCompleted = ({ study, articles, fullScreenMode, studyAdditionalInfo, onShareClick }) => {
	const [reportDetail, setReportDetails] = useState([]);
	const [showMobileGallery, setShowMobileGallery] = useState(false);
	const __config = useConfig();
	const { t } = useTranslation('studyDetail');

	const getReports = () => {
		if (_.has(REPORT_DATA, study.id)) {
			setReportDetails(REPORT_DATA[study.id]);
			return;
		}
		let reportList = [];
		axios.get(`${__config.data_sources.blume}Study/${study.id}/report`).then(result => {
			_.map(result?.data?.entry, (elem, _index) => {
				const reportDict = {
					id: _index,
					selected: false,
					reportName: elem?.resource?.presentedForm[0]?.title || '',
					doctorName: elem?.resource?.resultsInterpreter?.[0]?.display || '',
					reportPath: `${__config.data_sources.blume}Study/report/${elem?.resource?.id}/content`,
					reportId: elem?.resource?.id || '',
				};
				reportList = [...reportList, reportDict];
			});
			setReportDetails(reportList);
		});
	};

	useEffect(() => {
		getReports();
	}, []);

	return (
		<Box data-testid="completedDetailMobile">
			{showMobileGallery && <MobileGallery closeGallery={() => setShowMobileGallery(false)} study={study} />}

			<Box sx={{ padding: '16px' }}>
				<MobileStudyInformationSection study={study} />

				{study?.studyThumbnail?.seriesUID && (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: '22px 12px',
							backgroundColor: 'rgba(250, 250, 250, 1)',
							marginBottom: '24px',
						}}
						onClick={() => setShowMobileGallery(true)}
					>
						<Typography
							sx={{
								color: 'rgba(66, 165, 245, 1)',
								fontFamily: 'Roboto',
								fontSize: '16px',
								fontWeight: '500',
							}}
						>
							{t('View images')}
						</Typography>
						<KeyboardArrowRightIcon sx={{ fill: 'rgba(66, 165, 245, 1)' }} />
					</Box>
				)}

				<MobileReportsSection fullScreenMode={fullScreenMode === 'reportViewer'} reports={reportDetail} />
				{study?.patientId && <FormsView patientId={study.patientId} studyId={study.id} />}
				<MobileSharedWithSection
					study={study}
					studyAdditionalInfo={studyAdditionalInfo}
					sx={{ marginBottom: '50px' }}
					onShareClick={onShareClick}
				/>

				{/* <MobileArticlesSection articles={articles} /> */}
			</Box>
		</Box>
	);
};

export default MobileStudyDetailCompleted;
