import { useContext } from 'react';
import { useStore } from 'zustand';

const useZustandStoreSelector = (selector, Context) => {
	const store = useContext(Context);

	if (!store) {
		throw new Error('Missing Context.Provider in the tree');
	}

	return useStore(store, selector);
};

export default useZustandStoreSelector;
