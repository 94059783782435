import _ from 'lodash';

const permissionsMapper = (permissionsItem, topLevelPermissionsFlag = true) => {
	// we now only have read/update permissions in the new GUI designs
	const topLevelPermissions = topLevelPermissionsFlag
		? {
				read: permissionsItem?.action?.includes('read') || false,
				update: permissionsItem?.action?.includes('update') || false,
				create: permissionsItem?.action?.includes('update') || false,
		  }
		: null;
	let mappedPermissions = { ...topLevelPermissions };

	if (Array.isArray(permissionsItem?.permissions)) {
		permissionsItem.permissions.forEach(perm => {
			mappedPermissions[_.lowerCase(perm.resource)] = permissionsMapper(perm);
		});
	}

	return mappedPermissions;
};

export default permissionsMapper;
