import React, { useEffect } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';

import StarterKit from '@tiptap/starter-kit';
import Strike from '@tiptap/extension-strike';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Underline from '@tiptap/extension-underline';
import Color from '@tiptap/extension-color';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import BackgroundColor from '@worklist-2/worklist/src/DocumentViewerV3/features/DocumentViewer/Viewports/ViewportTiptap/ViewportTipTapEditor/plugins/BackgroundColor';

import styles from './TableBlock.module.scss';

const TableBlock = ({ theme, block, setDisplayMode, type, isSelected }) => {
	const editor = useEditor({
		editable: false,
		extensions: [
			BackgroundColor,
			Color,
			StarterKit,
			Strike,
			Table.configure({
				resizable: false,
			}),
			TableRow,
			TableHeader,
			TableCell,
			TextAlign.configure({
				alignments: ['left', 'center', 'right'],
				types: ['paragraph'],
			}),
			TextStyle,
			Underline,
		],
		content: block.html,
	});

	const onMouseOver = () => {
		if (!type && setDisplayMode) {
			setDisplayMode({ type: 'hover' });
		}
	};

	useEffect(() => {
		if (editor) {
			editor.commands.setContent(block.html);
		}
	}, [editor, block]);

	return (
		<div
			className={`${styles.table} ${type === 'hover' ? styles.tableHover : ''} ${
				isSelected ? styles.tableSelected : ''
			}`}
			data-testid="table"
			onMouseOver={onMouseOver}
		>
			<EditorContent editor={editor} />
		</div>
	);
};

export default TableBlock;
