// core
import React, { useEffect, useState } from 'react';
// libraries
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// mui
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

// contexts
import { mediaTablet, useAppModeContext, useIsTablet, useIsMobile, useAuth, useTabContext } from '@worklist-2/core/src';

// partials
import { styled } from '@mui/material/styles';

import SearchResultItem, { getItemPageUrl, checkAccess } from '../SearchResults/SearchResultItem';
import SendDrawer from '@rs-ui/components/Worklist/WorklistGrid/SendDrawer';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const SearchDialog = ({
	closeSearchDialog,
	isLoadingUserId,
	loggedInUserId,
	scope,
	onGoToUser,
	setIsRecentSearch,
	setSearchText,
	setStudyInDetailedView,
	setIsSharedType,
}) => {
	const navigate = useNavigate();

	const [searchedItems, setSearchedItems] = useState([]);
	const [recentlyViewed, setRecentlyViewed] = useState([]);
	const {
		getItemCountRecentViewed,
		getItemCountRecentSearch,
		isPatientPortalMode,
		isWorklistMode,
		isMarketplaceMode,
		isHelpCenterMode,
	} = useAppModeContext();
	const { validOrganizations, internalPatients } = useTabContext();
	const appModeIsWorklist = isWorklistMode();
	const appModeIsHelpCenter = isHelpCenterMode();

	const isTablet = useIsTablet();
	const isMobile = useIsMobile();
	const itemCountRecentSearch = getItemCountRecentSearch();
	const itemCountRecentViewed = getItemCountRecentViewed();
	const { t } = useTranslation('Search');
	const { selectedLanguage, loggedInUser } = useAuth();
	const metaEmergencySearch = useBooleanFlagValue('meta-emergency-search');
	const [searchParams] = useSearchParams();

	useEffect(() => {
		if (scope) {
			const raw = {
				searchItems: localStorage.getItem('recentSearch'),
				viewItems: localStorage.getItem('recentViewed'),
			};

			const parsed = {
				searchItems: [],
				viewItems: [],
			};

			try {
				parsed.searchItems = JSON.parse(raw.searchItems);
				parsed.viewItems = JSON.parse(raw.viewItems);
			} catch (error) {
				throw new TypeError(
					'Recently searched and viewed items could not be parsed from `localStorage`. Check their content to see whether they contain a valid JSON object!'
				);
			}

			if (raw.searchItems) {
				setSearchedItems(parsed.searchItems.filter(({ type }) => type === scope));
			}

			if (raw.viewItems) {
				setRecentlyViewed(parsed.viewItems.filter(({ viewType }) => viewType === scope));
			}
		}
	}, [scope]);

	const getClickHandler = item => {
		const url = getItemPageUrl(item, loggedInUserId, appModeIsWorklist || appModeIsHelpCenter, selectedLanguage);

		if ((isMobile || isTablet) && item.ResourceType === 'BlumeStudy')
			return () => {
				setStudyInDetailedView({
					study: {
						id: item.id,
					},
				});
			};
		if ((isMobile || isTablet) && item.ResourceType === 'StudySharedWithMe')
			return () => {
				setIsSharedType('withMe');
				setStudyInDetailedView({
					study: {
						id: item.id,
					},
				});
			};
		if (url || item.resourceType === 'Practitioner')
			return async () => {
				if (url) {
					if (item?.ResourceType.toLowerCase() === 'helpcenter') {
						navigate(`${url}?${searchParams.get('from') ? `from=${searchParams.get('from')}` : ''}`);
					} else {
						navigate(url);
					}
				} else {
					await onGoToUser(item);
				}
				closeSearchDialog();
			};
		return undefined;
	};

	const SearchDialogdiv = styled('div')(({ theme }) => ({
		width: '100%',
		top: '44px',
		position: 'absolute',

		zIndex: '100',
		backgroundColor: theme.palette.rsSecondary.medium,
		color: theme.palette.text.main,
		border: ' 1px solid rgba(255, 255, 255, 0.2)',
		borderRadius: '6px',
		//padding: 0 0.5rem;

		'&.dialog-header': {
			display: 'flex',
			padding: '1rem',
			gap: '2rem',
			justifyContent: 'flex-start',
			alignItems: ' center',
		},

		'&.MuiSvgIcon-root': {
			opacity: '1',
		},

		'&.disabled': {
			opacity: '0.6',
		},

		'&.dialog-footer ': {
			borderTop: '1px solid rgba(255, 255, 255, 0.2)',
			textAlign: 'center',
			padding: ' 1rem',
			color: '#42a5f5',
			'.h6': {
				display: 'inline-block',
				cursor: 'pointer',
			},
		},
	}));

	return (
		<>
			{(recentlyViewed.length > 0 || searchedItems.length > 0) && (
				<SearchDialogdiv>
					{recentlyViewed.length > 0 && (
						<>
							{isWorklistMode || isMarketplaceMode || isHelpCenterMode ? (
								<MenuItem
									disabled
									sx={{
										marginTop: isPatientPortalMode ? '10px' : undefined,
									}}
								>
									{t('RECENTLY VIEWED')}
								</MenuItem>
							) : (
								<MenuItem
									sx={{
										color: '#393939',
										paddingTop: '6px',
										[mediaTablet]: {
											fontSize: '14px',
											lineHeight: '20px',
											minHeight: 'unset',
										},
									}}
								>
									{t('RECENTLY VIEWED')}
								</MenuItem>
							)}
							<List>
								{recentlyViewed.slice(0, itemCountRecentViewed).map(item => {
									const isAccess = checkAccess(
										item,
										validOrganizations,
										internalPatients,
										loggedInUser?.id,
										metaEmergencySearch
									);
									const cursorType = isAccess ? 'pointer' : 'default';
									const onClick = getClickHandler(item);

									return (
										<ListItemButton
											key={`${item.id}_${item.resourceType}`}
											className="searchDialogItem"
											data-cy="recent-viewed-items"
											sx={{
												cursor: cursorType,
												padding: isPatientPortalMode() ? '3px 16px 3px 16px' : '',
											}}
											onClick={isAccess ? onClick : () => {}}
										>
											<SearchResultItem
												showButton
												SendDrawer={SendDrawer}
												isAccess={isAccess}
												item={item}
												showProgress={
													isLoadingUserId &&
													item.resourceType === 'Practitioner' &&
													item.id === isLoadingUserId
												}
												type="search"
												onGoTo={onClick}
											/>
										</ListItemButton>
									);
								})}
							</List>
						</>
					)}

					{searchedItems.length > 0 && (
						<>
							{isWorklistMode || isMarketplaceMode || isHelpCenterMode ? (
								<MenuItem disabled>{t('RECENTLY SEARCHED')}</MenuItem>
							) : (
								<MenuItem
									sx={{
										color: '#393939',
										paddingTop: '6px',
										[mediaTablet]: {
											fontSize: '14px',
											lineHeight: '20px',
											minHeight: 'unset',
										},
									}}
								>
									{t('RECENTLY SEARCHED')}
								</MenuItem>
							)}
							<List>
								{searchedItems.slice(0, itemCountRecentSearch).map(item => (
									<ListItemButton
										key={item.content}
										data-cy="recent-searched-items"
										onClick={() => {
											setSearchText(item.content);
											setIsRecentSearch(true);
										}}
									>
										<ListItemAvatar>
											<Avatar
												sx={{
													width: isPatientPortalMode ? '35px' : undefined,
													height: isPatientPortalMode ? '35px' : undefined,
												}}
											>
												<SearchIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={item.content}
											sx={{
												[mediaTablet]: {
													'& .MuiListItemText-primary': {
														fontSize: '16px',
														lineHeight: '20px',
														letterSpacing: '0.25px',
													},
												},
											}}
										/>
									</ListItemButton>
								))}
							</List>
						</>
					)}
				</SearchDialogdiv>
			)}
		</>
	);
};

SearchDialog.propTypes = {
	/**
	 * closeSearchDialog is function to handle close the dialog
	 */
	closeSearchDialog: PropTypes.func,
	/**
	 * setSearchText is function use when we change search text
	 */
	setSearchText: PropTypes.func,
	/**
	 * scope is use for global search such as [all, search patient, search study...]
	 */
	scope: PropTypes.string,
	/**
	 * setIsRecentSearch is function use when we click on recent search test
	 */
	setIsRecentSearch: PropTypes.func,
};

export default SearchDialog;
