export const devices = {
	tablet: ['Responsive', 'Ipad Air', 'Ipad Pro', 'Samsung Tablet'],
	mobile: ['Iphone 12 Pro', 'Iphone XR', 'Samsung Galaxy 8+', 'Samsung Galaxy S20', 'Google Pixel'],
};

export const deviceDimensions = {
	'Ipad Air': [820, 1180],
	'Ipad Pro': [1024, 1366],
	'Samsung Tablet': [768, 1024],
	'Iphone 12 Pro': [390, 844],
	'Iphone XR': [414, 896],
	'Samsung Galaxy 8+': [360, 740],
	'Samsung Galaxy S20': [412, 915],
	'Google Pixel': [393, 851],
};
