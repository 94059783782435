import React, { useEffect, useState, useMemo } from 'react';
import MomentUtils from '@date-io/moment';
import { useNavigate } from 'react-router';
import _ from 'lodash';

import { Box, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import FaceIcon from '@mui/icons-material/Face';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CustomTextInput from '@worklist-2/ui/src/components/Help/CustomTextInput';
import { crmSearchScopes, useAuth, useCRMDataLoader, useCRMSearchScope } from '@worklist-2/core/src';
import {
	NoResults,
	PanelHeader,
	RecentBody,
	RecentEntry,
	RecentSearchContainer,
	SearchBody,
	SearchPanelContainer,
	SearchPanelFooter,
	SearchResult,
	SearchResultComment,
	SearchResultContainer,
	SearchResultDescription,
	SearchResultTitle,
	SearchTab,
	SearchTabs,
	SectionTitle,
	ShowSpinner,
	TabsContainer,
} from './SearchPanelStyle';
import Highlighter from 'react-highlight-words';
import { GlobalSearch_NoLinkResource } from '../../components/CRM/Cases/utils';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const COLORS = {
	grey: 'rgba(0, 0, 0, 0.6)',
	dark: 'rgba(0, 0, 0, 0.9)',
	blue: 'rgba(77, 121, 234, 1)',
	white: 'rgba(255, 255, 255, 1)',
};

const SearchPanel = ({
	searchValue,
	setSearchValue,
	searchCategories,
	setSearchCategories,
	data,
	setData,
	setSearchOpen,
}) => {
	const [showRecent, setShowRecent] = useState(true);
	const [recentSearch, setRecentSearch] = useState('');
	const [searchHistory, setSearchHistory] = useState([]);
	const [showSpinner, setShowSpinner] = useState(true);
	const [isFirstTime, setIsFirstTime] = useState(true);
	const [recentLimit] = useState(4);
	const { setFetchData, setCaseGlobalList, setAccountGlobalList } = useCRMSearchScope();
	const { authorized } = useAuth();
	const moment = new MomentUtils();
	const navigate = useNavigate();
	const metaBreezeAccount = useBooleanFlagValue('meta-breeze-account');
	const metaBreezeCase = useBooleanFlagValue('meta-breeze-case');

	const caseDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.globalSearchApi,
	});

	const handleTabChange = newValue => {
		if (!searchCategories.includes(newValue)) {
			setSearchCategories(prev => [...prev, newValue]);
		} else {
			setSearchCategories(prev => prev.filter(category => category !== newValue));
		}
	};

	const SearchChangeHandler = event => {
		setSearchValue(event.target.value);
	};

	useEffect(() => {
		searchValue !== '' ? setShowRecent(false) : setShowRecent(true);
	}, [searchValue]);

	useEffect(() => {
		if (searchValue !== '') {
			const delayDebounce = setTimeout(() => {
				setRecentSearch(searchValue);
			}, 500);

			return () => clearTimeout(delayDebounce);
		}
	}, [searchValue]);

	useEffect(() => {
		const retrievedRecentString = localStorage.getItem('recentSearches');
		retrievedRecentString === null ? setSearchHistory([]) : setSearchHistory(JSON.parse(retrievedRecentString));

		if (recentSearch !== '' && !searchHistory.includes(recentSearch)) {
			const newRecentDataSet = searchHistory.filter(entry => entry.trim().length > 0).slice(0, recentLimit);
			newRecentDataSet.unshift(recentSearch);
			setSearchHistory(newRecentDataSet);
			localStorage.setItem('recentSearches', JSON.stringify(newRecentDataSet));
		}
	}, [recentSearch]);

	useEffect(() => {
		setIsFirstTime(false);
	}, []);

	useEffect(() => {
		if (searchValue !== '' && !isFirstTime) {
			setData(null);
			setShowSpinner(true);
			const delayDebounce = setTimeout(() => {
				fetchData(caseDataLoader, {
					content: searchValue,
				}).catch(console.error);
			}, 500);
			return () => clearTimeout(delayDebounce);
		}
	}, [searchValue]);

	const getTabbedDataArray = entry => {
		if (searchCategories.length === 1) return entry;
		return entry.slice(0, 2);
	};

	const fetchData = async (loader, value) => {
		const fnName = 'load';
		const bodyData = undefined;
		if (!loader) {
			loader = caseDataLoader;
		}

		if (authorized) {
			setData(null);

			try {
				await loader[fnName](value, !!bodyData || undefined, bodyData).then(result => {
					const newData = [];
					for (const resultItem of result) {
						const resourceType =
							resultItem.entry?.length > 0 ? resultItem.entry[0].resource.ResourceType : '';
						if (resourceType && !!_.find(SEARCH_TABS, ['TabValue', resourceType])) {
							const results = resultItem.entry.map(item => item.resource);
							newData.push({
								ResultCategory: resourceType,
								SearchResults: results,
							});
						}
					}

					setData(newData);
					setFetchData(false);
					setShowSpinner(false);
				});
			} catch (e) {
				console.error(e);
			}
		}
	};

	const SEARCH_TABS = useMemo(() => {
		const { loggedInUser } = useAuth();
		const searchTab = [
			{
				TabName: 'Account',
				TabValue: 'Account',
				TabIcon: props => <ViewListOutlinedIcon {...props} />,
			},
			{
				TabName: 'Case',
				TabValue: 'Case',
				TabIcon: props => <WorkOutlineOutlinedIcon {...props} />,
			},
			{
				TabName: 'Contact',
				TabValue: 'Contact',
				TabIcon: props => <PermIdentityOutlinedIcon {...props} />,
			},
			{
				TabName: 'File',
				TabValue: 'Attachment',
				TabIcon: props => <FolderOutlinedIcon {...props} />,
			},
			{
				TabName: 'User',
				TabValue: 'User',
				TabIcon: props => <FaceIcon {...props} />,
			},
			{
				TabName: 'Knowledge',
				TabValue: 'helpcenter',
				TabIcon: props => <SchoolOutlinedIcon {...props} />,
			},
			{
				TabName: 'Comment',
				TabValue: 'Comment',
				TabIcon: props => <ChatOutlinedIcon {...props} />,
			},
		];

		if (!metaBreezeAccount) {
			_.remove(searchTab, item => item.TabValue === 'Account');
		}
		if (!metaBreezeCase) {
			const removeTab = ['Case', 'Attachment', 'Comment'];
			_.remove(searchTab, item => removeTab.includes(item.TabValue));
		}

		if (!loggedInUser.permission.Account.Read) {
			_.remove(searchTab, item => item.TabValue === 'Account');
		}
		if (!loggedInUser.permission.Case.Read) {
			_.remove(searchTab, item => item.TabValue === 'Case');
		}
		if (!loggedInUser.permission.Contact.Read) {
			_.remove(searchTab, item => item.TabValue === 'Contact');
		}
		if (!loggedInUser.permission.Case.Read || !loggedInUser.permission.Comment.Read) {
			const removeTab = ['Attachment', 'Comment'];
			_.remove(searchTab, item => removeTab.includes(item.TabValue));
		}
		if (!loggedInUser.permission.User.Read) {
			_.remove(searchTab, item => item.TabValue === 'User');
		}

		return searchTab;
	}, []);

	const getSearchResultTitle = (category, result) => {
		if (category === 'Account') {
			return `${result.AccountName} • ${result.ClientId}`;
		}
		if (category === 'Case') {
			return `${result.CaseName} • ${result.CaseNumber}`;
		}
		if (category === 'Contact') {
			return `${result.FullName} • ${result.Email}`;
		}
		if (category === 'Attachment') {
			return result.FileName ?? result.Title;
		}
		if (category === 'User') {
			return result.UserName;
		}
		if (category === 'helpcenter') {
			return result.Article;
		}
		if (category === 'Comment') {
			return `${result.LastUpdatedUserName} in Case ${result.CaseNumber}`;
		}
	};

	const getSearchResultDescription = (category, result) => {
		if (category === 'Attachment') {
			return `${moment.moment(result.LastUpdated).format('L')} • ${formatBytes(result.Size)}`;
		}
		if (category === 'User') {
			return result.LoginName;
		}
	};

	const getCategoryIcon = category => {
		if (category === 'Account') {
			return (
				<ViewListOutlinedIcon
					sx={{
						marginTop: '4px',
						fontSize: 17,
						color: `${COLORS.grey}`,
					}}
				/>
			);
		}
		if (category === 'Case') {
			return (
				<WorkOutlineOutlinedIcon
					sx={{
						marginTop: '4px',
						fontSize: 17,
						color: `${COLORS.grey}`,
					}}
				/>
			);
		}
		if (category === 'Contact') {
			return (
				<PermIdentityOutlinedIcon
					sx={{
						marginTop: '4px',
						fontSize: 17,
						color: `${COLORS.grey}`,
					}}
				/>
			);
		}
		if (category === 'Attachment') {
			return (
				<FolderOutlinedIcon
					sx={{
						marginTop: '4px',
						fontSize: 17,
						color: `${COLORS.grey}`,
					}}
				/>
			);
		}
		if (category === 'User') {
			return (
				<FaceIcon
					sx={{
						marginTop: '4px',
						fontSize: 17,
						color: `${COLORS.grey}`,
					}}
				/>
			);
		}
		if (category === 'helpcenter') {
			return (
				<SchoolOutlinedIcon
					sx={{
						marginTop: '4px',
						fontSize: 17,
						color: `${COLORS.grey}`,
					}}
				/>
			);
		}
		if (category === 'Comment') {
			return (
				<ChatOutlinedIcon
					sx={{
						marginTop: '4px',
						fontSize: 17,
						color: `${COLORS.grey}`,
					}}
				/>
			);
		}
	};

	const formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
	};

	const detailedView = (category, entry) => {
		let resourceType;
		let resourceId;
		let caseGlobalList = [];
		let accountGlobalList = [];

		if (category === 'Account') {
			accountGlobalList = _.find(data, ['ResultCategory', 'Account'])?.SearchResults;
			resourceType = _.lowerCase(entry.ResourceType);
			resourceId = entry.AccountId;
		} else if (category === 'Case') {
			caseGlobalList = _.find(data, ['ResultCategory', 'Case'])?.SearchResults;
			resourceType = _.lowerCase(entry.ResourceType);
			resourceId = entry.CaseId;
		} else if (category === 'Contact') {
			//Will update later
			return;
		} else if (category === 'Attachment') {
			resourceType = 'case';
			resourceId = entry.CaseId;
		} else if (category === 'helpcenter') {
			//Change according to previous categories if backend data provides any id and path.
			return;
		} else if (category === 'Comment') {
			resourceType = 'case';
			//There is no CaseId inside the data of Comment category
			//This will navigate to case/undefined
			resourceId = entry.CaseId;
		} else if (category === 'User') {
			//Will update later
			return;
		}

		setAccountGlobalList(accountGlobalList);
		setCaseGlobalList(caseGlobalList);
		navigate(`/${resourceType}/${resourceId}`);
	};

	const handleKeyDown = event => {
		if (event.key === 'Enter' && !showRecent && data && data.length > 0) {
			setSearchOpen(false);
			navigate('/GlobalSearchResults', {
				state: { searchData: data, keyword: searchValue, searchCategories },
			});
		}
	};

	const getCommentText = textString => {
		let textJson = '';
		let content = '';
		try {
			textJson = textString ? JSON.parse(textString) : {};
			const textBlocks = textJson.blocks ? textJson.blocks : [];
			if (textBlocks.length > 0) {
				content = textBlocks
					.map(x => x.text)
					.filter(Boolean)
					.join(' ');
			} else {
				content = textString;
			}
		} catch {
			content = textString;
		}

		return content;
	};

	return (
		<SearchPanelContainer>
			<PanelHeader>
				<SearchIcon
					sx={{
						m: '10px',
						color: `${COLORS.blue}`,
						marginLeft: '20px',
					}}
				/>
				<CustomTextInput
					autoFocus
					inputProps={{ 'aria-label': 'Search' }}
					placeholder="Enter Your Search"
					style={{ flex: 1, padding: '1px 2px' }}
					value={searchValue}
					onChange={SearchChangeHandler}
					onKeyDown={handleKeyDown}
				/>
				<CloseIcon
					sx={{
						marginRight: '15px',
						color: `${COLORS.grey}`,
						cursor: 'Pointer',
					}}
					onClick={() => setSearchValue('')}
				/>
			</PanelHeader>
			<SearchBody>
				<SearchTabs>
					<SectionTitle>Refine Your Search</SectionTitle>
					<TabsContainer>
						{SEARCH_TABS.map(item => (
							<SearchTab
								icon={<item.TabIcon style={{ fontSize: 14 }} />}
								included={searchCategories.includes(item.TabValue)}
								label={item.TabName}
								value={item.TabValue}
								onClick={() => handleTabChange(item.TabValue)}
							/>
						))}
					</TabsContainer>
				</SearchTabs>
				{showRecent ? (
					<RecentBody>
						<SectionTitle>Recent Searches</SectionTitle>
						{searchHistory.map(item => (
							<RecentSearchContainer onClick={() => setSearchValue(item)}>
								<SearchOutlinedIcon
									sx={{
										fontSize: 17,
										color: `${COLORS.grey}`,
									}}
								/>
								<RecentEntry>{item}</RecentEntry>
							</RecentSearchContainer>
						))}
					</RecentBody>
				) : (
					<SearchResultContainer>
						{data && data.length > 0 ? (
							data.map(
								entry =>
									entry.SearchResults.length > 0 &&
									(searchCategories.includes(entry.ResultCategory) ||
										searchCategories.length === 0) &&
									!!_.find(SEARCH_TABS, ['TabValue', entry.ResultCategory]) && (
										<Box>
											<SectionTitle>
												{_.find(SEARCH_TABS, ['TabValue', entry.ResultCategory]).TabName}
											</SectionTitle>
											{getTabbedDataArray(entry.SearchResults).map(result => (
												<SearchResult
													sx={{
														cursor: !GlobalSearch_NoLinkResource.includes(
															entry.ResultCategory
														)
															? 'pointer'
															: 'context-menu',
													}}
													onClick={() => {
														if (
															!GlobalSearch_NoLinkResource.includes(entry.ResultCategory)
														) {
															setSearchOpen(false);
															detailedView(entry.ResultCategory, result);
														}
													}}
												>
													{getCategoryIcon(entry.ResultCategory)}
													<Stack>
														<SearchResultTitle>
															<Highlighter
																autoEscape
																highlightClassName="myCustomHighlighter"
																searchWords={[searchValue]}
																textToHighlight={getSearchResultTitle(
																	entry.ResultCategory,
																	result
																)}
															/>
														</SearchResultTitle>
														{getSearchResultDescription(entry.ResultCategory, result) && (
															<SearchResultDescription>
																<Highlighter
																	autoEscape
																	highlightClassName="myCustomHighlighter"
																	searchWords={[searchValue]}
																	textToHighlight={getSearchResultDescription(
																		entry.ResultCategory,
																		result
																	)}
																/>
															</SearchResultDescription>
														)}
														{entry.ResultCategory === 'Comment' && (
															<SearchResultComment>
																<Highlighter
																	autoEscape
																	highlightClassName="myCustomHighlighter"
																	searchWords={[searchValue]}
																	textToHighlight={getCommentText(result.Description)}
																/>
															</SearchResultComment>
														)}
													</Stack>
												</SearchResult>
											))}
										</Box>
									)
							)
						) : (
							<Stack alignItems="center">{showSpinner ? <ShowSpinner /> : <NoResults />}</Stack>
						)}
					</SearchResultContainer>
				)}
			</SearchBody>
			{!showRecent && data && data.length > 0 && (
				<SearchPanelFooter
					onClick={() => {
						setSearchOpen(false);
						navigate('/GlobalSearchResults', {
							state: { searchData: data, keyword: searchValue, searchCategories },
						});
					}}
				>
					View All
				</SearchPanelFooter>
			)}
		</SearchPanelContainer>
	);
};

export default SearchPanel;
