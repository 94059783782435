/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
import practitionerSchema from './schemata/practitionerSchema';
/************************************************************************************************************************
Resource: Practitioner
Reference: https://www.hl7.org/fhir/Patient.html


This Resource covers data about patients and animals involved in a wide range of health-related activities, including: The
data in the Resource covers the "who" information about the patient: its attributes are focused on the demographic information
necessary to support the administrative, financial and logistic procedures. A Patient record is generally created and maintained
by each organization providing care for a patient. A patient or animal receiving care at multiple organizations may therefore
have its information present in multiple Patient Resources. Not all concepts are included within the base resource (such
as race, ethnicity, organ donor status, nationality, etc.), but may be found in profiles defined for specific jurisdictions
(e.g., US Meaningful Use Program) or standard extensions. Such fields vary widely between jurisdictions and often have different
names and valuesets for the similar concepts, but they are not similar enough to be able to map and exchange.
 ************************************************************************************************************************/

export default class PractitionerResource extends FhirDomainResource {
	active;
	name = [];
	extension = [];
	gender;
	id;
	meta = [];
	identifier = [];
	telecom = [];
	address = [];

	constructor(resourceString) {
		super(resourceString, practitionerSchema);
		this.resourceType = 'Practitioner';
		this.populateFields();
	}
}
