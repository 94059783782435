// core
import { createContext } from 'react';

const FormContext = createContext({
	readOnly: false,
	hoveredField: '',
	setHoveredField: () => {},
});

export default FormContext;
