import React from 'react';

import IdeaProgressContainer from './IdeaProgressContainer';
import KeyResultProgressBar from './KeyResultProgressBar';

function KeyResultProgressContainer({
	objective,
	keyResult,
	keyResultIndex,
	onSetObjectiveVariables,
	onSetKeyResultVariable,
	onSetIdeaVariable,
}) {
	return (
		<div>
			<div
				style={{
					height: '53px',
					paddingTop: '20px',
					boxSizing: 'border-box',
					marginBottom: '15px',
				}}
			>
				<KeyResultProgressBar keyResult={keyResult} />
			</div>
			{keyResult?.expanded && keyResult?.hovered && (
				<div
					style={{
						height: '37px',
					}}
				/>
			)}
			{keyResult?.expanded && keyResult?.Ideas?.length > 0 && (
				<div
					style={{
						padding: '8px 0',
						marginBottom: '26px',
					}}
				>
					{(keyResult?.Ideas || []).map((idea, index) => (
						<IdeaProgressContainer
							key={index}
							idea={idea}
							ideaIndex={index}
							keyResultIndex={keyResultIndex}
							objective={objective}
							onSetIdeaVariable={onSetIdeaVariable}
							onSetKeyResultVariable={onSetKeyResultVariable}
							onSetObjectiveVariables={onSetObjectiveVariables}
						/>
					))}
				</div>
			)}
		</div>
	);
}

export default KeyResultProgressContainer;
