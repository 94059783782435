import React, { createContext, useContext, useEffect, useState } from 'react';

export function findDuplicatedPatients(array) {
	const duplicates = [];
	const seenIds = new Map();

	for (const patient of array) {
		const patientID = patient.subject?.id;

		if (seenIds.has(patientID)) {
			seenIds.get(patientID).push(patient);
		} else {
			seenIds.set(patientID, [patient]);
		}
	}

	for (const items of seenIds.values()) {
		if (items.length > 1) {
			duplicates.push(...items);
		}
	}

	return duplicates;
}

export const SelectionContext = createContext({
	selectedStudies: [],
	selectedPatients: [],
	selectedResources: [],
	selectedResourcesHelper: [], //remove after API update
	selectedDuplicates: [],
	resetStudiesSelection: () => {},
	toggleStudyPresence: ({ id, data }) => {},
	toggleResourceSelection: data => {},
	handleResourceSelect: data => {},
	handleResourceDelete: data => {},
	handleResourceReset: () => {},
	updateResources: data => {},
	updateResourcesHelper: data => {},
	modifySelectedMergeStudy: data => {},
});

const SelectionProvider = ({ children }) => {
	const [selectedStudies, setSelectedStudies] = useState([]);
	const [selectedPatients, setSelectedPatients] = useState([]);
	const [preAuthStudy, setPreAuthStudy] = useState(null);
	const [selectedResources, setSelectedResources] = useState([]);
	const [selectedResourcesHelper, setSelectedResourcesHelper] = useState([]);
	const [selectedDuplicates, setSelectedDuplicates] = useState([]);
	const [selectedMergeStudy, setSelectedMergeStudy] = useState(null);
	const [selectedMergeStudyModified, setSelectedMergeStudyModified] = useState(null);
	const [selectedMergePatient, setSelectedMergePatient] = useState(null);
	const [selectedMergePatientModified, setSelectedMergePatientModified] = useState(null);
	const [replacedFields, setReplacedFields] = useState([]);

	const updateResources = data => {
		setSelectedResources(data);
	};

	const updateResourcesHelper = data => {
		setSelectedResourcesHelper(data);
	};

	const modifySelectedMergeStudy = data => {
		setSelectedMergeStudyModified(data);
	};

	const handleResourceSelect = data => {
		setSelectedResources(prev => [...prev, data]);
		if (selectedStudies.length) resetStudiesSelection();
	};

	const handleResourceDelete = data => {
		const _selectedResources = [...selectedResources];
		const _filtered = _selectedResources.filter(i => i.studyID !== data.studyID);
		setSelectedResources(_filtered);
	};

	const handleResourceReset = () => {
		setSelectedResources([]);
		setSelectedMergeStudy(null);
	};

	const resetStudiesSelection = () => {
		setSelectedStudies([]);
	};

	useEffect(() => {
		if (selectedResources.length) {
			const duplicates = findDuplicatedPatients(selectedResources);

			if (duplicates.length) {
				setSelectedDuplicates(duplicates);
			} else {
				setSelectedDuplicates([]);
			}
		}
	}, [selectedResources]);

	const toggleStudyPresence = ({ id, data }) => {
		if (selectedStudies.map(s => s.id).includes(id)) {
			setSelectedStudies(list => list.filter(s => s.id !== id));
		} else {
			setSelectedStudies(list => [
				...list,
				{
					...data,
				},
			]);
		}
		handleResourceReset();
	};

	const selectAllStudies = data => {
		setSelectedStudies(data);
		handleResourceReset();
	};

	const toggleResourceSelection = data => {
		if (selectedResources.map(res => res.id).includes(data.id)) {
			setSelectedResources(list => list.filter(resource => resource.id !== data.id));
		} else {
			setSelectedResources(list => [...list, data]);
		}
	};

	return (
		<SelectionContext.Provider
			value={{
				selectedStudies,
				resetStudiesSelection,
				toggleStudyPresence,
				toggleResourceSelection,
				selectedResources,
				handleResourceSelect,
				handleResourceDelete,
				handleResourceReset,
				selectedDuplicates,
				selectedMergeStudy,
				setSelectedMergeStudy,
				modifySelectedMergeStudy,
				selectedPatients,
				setSelectedPatients,
				selectedMergeStudyModified,
				selectedMergePatient,
				selectedMergePatientModified,
				setSelectedMergePatient,
				setSelectedMergePatientModified,
				preAuthStudy,
				setPreAuthStudy,
				updateResources,
				selectedResourcesHelper,
				updateResourcesHelper,
				replacedFields,
				setReplacedFields,
				selectAllStudies,
			}}
		>
			{children}
		</SelectionContext.Provider>
	);
};

export const useSelection = () => useContext(SelectionContext);
export default SelectionProvider;
