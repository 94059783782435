// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uHLIw_t6fWzyX3kTjl_F{display:flex;margin:30px 70px;border:1px solid rgba(112,112,112,.1);border-left:4px solid #c480dc;border-radius:5px}.gdjsSM4k8TX0cQ7iJWyd{margin:10px 90px;padding:10px;border-radius:10px;border:1px solid #42a5f5;background:rgba(66,165,245,.05)}.uHLIw_t6fWzyX3kTjl_F .J8vzBFLaNgDrF7a4XGSW{position:relative;left:7px;top:5px}.uHLIw_t6fWzyX3kTjl_F .BeCAEZ6BHSczQo3mdo2q{width:100%;padding:15px 75px 15px 50px}.uHLIw_t6fWzyX3kTjl_F span{font-family:Roboto,serif;font-size:16px;font-style:normal;font-weight:400;line-height:normal;letter-spacing:.15px;word-wrap:break-word}.uHLIw_t6fWzyX3kTjl_F .dCXManaNZJLF2jpyf0Fg{color:#fff}.uHLIw_t6fWzyX3kTjl_F .OyplBX06hfimBVpUB6TI{color:rgba(18,18,18,.87)}.D84D5yQUQ9TcmE1NkgJA{background:#4a4a4a}.JwVhloV1RqQx6Oe1MhmS{background:rgba(112,112,112,.05)}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/common/MainArea/Draft/TextBlocks/CalloutBlock/CalloutBlock.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CACA,gBAAA,CACA,qCAAA,CACA,6BAAA,CACA,iBAAA,CAEA,sBACC,gBAAA,CACA,YAAA,CAEA,kBAAA,CACA,wBAAA,CACA,+BAAA,CAGD,4CACC,iBAAA,CACA,QAAA,CACA,OAAA,CAGD,4CACC,UAAA,CACA,2BAAA,CAGD,2BACC,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,oBAAA,CACA,oBAAA,CAGD,4CACC,UAAA,CAGD,4CACC,wBAAA,CAIF,sBACC,kBAAA,CAED,sBACC,gCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calloutBlock": "uHLIw_t6fWzyX3kTjl_F",
	"calloutBlockSelected": "gdjsSM4k8TX0cQ7iJWyd",
	"calloutIcon": "J8vzBFLaNgDrF7a4XGSW",
	"calloutText": "BeCAEZ6BHSczQo3mdo2q",
	"calloutOAI": "dCXManaNZJLF2jpyf0Fg",
	"calloutBlume": "OyplBX06hfimBVpUB6TI",
	"calloutBlockOAI": "D84D5yQUQ9TcmE1NkgJA",
	"calloutBlockBlume": "JwVhloV1RqQx6Oe1MhmS"
};
export default ___CSS_LOADER_EXPORT___;
