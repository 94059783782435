import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';

import _ from 'lodash';
import Card from '../../components/Card';
import Row from './Row';
import Code from './Code';

import { useHangingProtocolContext } from '../../contexts/HangingProtocolContext';
import { useTranslation } from 'react-i18next';

const MGViewCodes = [
	'MG: RCC',
	'MG: RFB',
	'MG: RISO',
	'MG: RLM',
	'MG: RLMO',
	'MG: RML',
	'MG: RMLO',
	'MG: RSIO',
	'MG: RXCC',
	'MG: RXCCL',
	'MG: RXCCM',
	'MG: LCC',
	'MG: LFB',
	'MG: LISO',
	'MG: LLM',
	'MG: LLMO',
	'MG: LML',
	'MG: LMLO',
	'MG: LSIO',
	'MG: LXCC',
	'MG: LXCCL',
	'MG: LXCCM',
	'MG: Any right',
	'MG: Any left',
	'MG: Any',
];

const viewCodesData = [
	'AP',
	'LAT',
	'PA',
	'OBLIQUE',
	'RLD',
	'LLD',
	'Any X-ray',

	'CT: Axial',
	'CT: Coronal',
	'CT: Sagittal',
	'CT: Scout',
	'CT: Any',

	'MR: Axial',
	'MR: Coronal',
	'MR: Sagittal',
	'MR: Scout',
	'MR: Any',

	'PET: Axial',
	'PET: Coronal',
	'PET: Sagittal',
	'PET: Any',

	...MGViewCodes,

	'NM',
	'XA',
	'ES',
	'OT',
	'OP',
	'IO',
	'Video',
	'US',
	'Any',
];

const viewCodesModifiers = [
	{
		codeString: 'ID',
		label: 'Implant Displaced',
		value: 'R-102D5',
	},
	{
		codeString: 'RI',
		label: 'Rolled Inferior',
		value: 'R-102CA',
	},
	{
		codeString: 'RL',
		label: 'Rolled Lateral',
		value: 'R-102D3',
	},
	{
		codeString: 'RM',
		label: 'Rolled Medial',
		value: 'R-102D4',
	},
	{
		codeString: 'RS',
		label: 'Rolled Superior',
		value: 'R-102C9',
	},
	{
		codeString: 'NP',
		label: 'Nipple in profile',
		value: 'R-40AB3',
	},
	{
		codeString: 'AC',
		label: 'Anterior Compression',
		value: 'P2-00161',
	},
	{
		codeString: 'IMF',
		label: 'Intra Mammary Fold',
		value: 'R-40ABE',
	},
	{
		codeString: 'AX',
		label: 'Axillary Tissue',
		value: 'R-40AB2',
	},
	{
		codeString: 'M',
		label: 'Magnification',
		value: 'R-102D6',
	},
	{
		codeString: 'ID',
		label: 'Implant Displaced',
		value: 'R-102D5',
	},
	{
		codeString: 'S',
		label: 'Spot Compression',
		value: 'R-102D7',
	},
	{
		codeString: 'CV',
		label: 'Cleavage',
		value: 'R-102D2',
	},
	{
		codeString: 'TAN',
		label: 'Tangential',
		value: 'R-102C2',
	},
	{
		codeString: 'Tomo (3D)',
		label: '3D Tomosynthesis',
	},
	{
		codeString: 'C-View',
		label: 'C-View',
	},
];

const viewCodesChunk = _.chunk(viewCodesData, 3);
const viewCodesModifiersChunk = _.chunk(viewCodesModifiers, 3);

const ViewCodes = () => {
	const { setDraggableCode } = useHangingProtocolContext();
	const [viewCodesChunkState, setViewCodesChunkState] = useState(viewCodesChunk);
	const [viewCodesModifiersState, setViewCodesModifiersState] = useState(viewCodesModifiersChunk);
	const [searchValue, setSearchValue] = useState('');
	const { t } = useTranslation('imageViewer');

	const onChangeSearch = event => {
		event.stopPropagation();
		setSearchValue(event.target.value);
	};

	useEffect(() => {
		if (!searchValue) {
			setViewCodesChunkState(viewCodesChunk);
			setViewCodesModifiersState(viewCodesModifiersChunk);
		} else {
			const filteredViewCodesChunk = viewCodesChunk.map(item =>
				item.filter(code => code.toLowerCase().includes(searchValue.toLowerCase()))
			);

			const filteredViewCodesModifiersChunk = viewCodesModifiersChunk.map(items =>
				items.filter(item => item.codeString.toLowerCase().includes(searchValue.toLowerCase()))
			);

			setViewCodesChunkState(_.chunk(_.concat(...filteredViewCodesChunk), 3));

			setViewCodesModifiersState(_.chunk(_.concat(...filteredViewCodesModifiersChunk), 3));
		}
	}, [searchValue]);

	return (
		<Card
			sx={{
				flex: '0.3',
				paddingRight: '0',
				overflow: 'hidden',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: '14px',
					paddingRight: '33px',
				}}
			>
				<Typography
					sx={{
						color: '#fff',
						fontSize: '16px',
						lineHeight: '16px',
						letterSpacing: '1.25px',
						fontVariant: 'small-caps',
						textTransform: 'lowercase',
					}}
				>
					{t('hp.VIEW CODES')}
				</Typography>
				<Box
					sx={{
						width: 'min-content',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						'& fieldset': {},
					}}
				>
					<Tooltip title="Viewcode Search">
						<TextField
							InputProps={{
								startAdornment: (
									<IconButton
										aria-label="search-icon"
										className="searchiconcustom"
										sx={{
											cursor: 'pointer',
											color: 'rgba(255, 255, 255, 0.6)',
											fontSize: '20px',
											padding: '0!important',
											paddingLeft: '0',
											margin: '0',
										}}
										tooltip="Viewcode Search"
									>
										<SearchIcon />
									</IconButton>
								),
								disableUnderline: true,
							}}
							autoComplete="off"
							className="textfield search-input"
							data-testid="input-search-code"
							id="search-codes"
							placeholder=""
							sx={{
								width: '32px',
								'& fieldset': {
									border: 'none',
								},
								'&:focus-within': {
									width: '292px',
									height: '38px',
									boxSizing: 'border-box',
									border: '1px solid rgba(255, 255, 255, 0.2)',
									boxShadow:
										'inset 0px -4px 4px rgba(0, 0, 0, 0.1), inset 0px 4px 4px rgba(0, 0, 0, 0.1)',
									borderRadius: '10px',
									background: '#353535',
								},
								'> div': {
									padding: '0',
									cursor: 'pointer',
								},
								input: {
									padding: '7.5px 0 7.5px 8.5px',
								},
								transition: 'width 1s',
							}}
							value={searchValue}
							onChange={onChangeSearch}
							onKeyDown={event => {
								event.stopPropagation();
							}}
						/>
					</Tooltip>
				</Box>
			</Box>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					overflowY: 'auto',
					margin: '0 7px 0 0',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '5px',
						padding: '0 22px 0 0',
					}}
				>
					{viewCodesChunkState.map((chunk, index) => (
						<Row key={index}>
							{chunk.map((item, chunkIndex) => (
								<Code
									key={chunkIndex}
									code={item}
									onDragEnd={() => {
										setDraggableCode(null);
									}}
									onDragStart={() => {
										setDraggableCode(item);
									}}
								/>
							))}
						</Row>
					))}
				</Box>

				<Typography
					sx={{
						color: '#fff',
						fontSize: '16px',
						lineHeight: '16px',
						letterSpacing: '1.25px',
						fontVariant: 'small-caps',
						textTransform: 'lowercase',
						margin: '20px 0 20px 12px',
					}}
				>
					{t('hp.MG MODIFIERS')}
				</Typography>

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '5px',
						padding: '0 22px 0 0',
					}}
				>
					{viewCodesModifiersState.map((chunk, index) => (
						<Row key={index}>
							{chunk.map((item, chunkIndex) => (
								<Code
									key={chunkIndex}
									code={item.codeString}
									tooltip={item.label}
									onDragEnd={() => {
										setDraggableCode(null);
									}}
									onDragStart={() => {
										setDraggableCode(item);
									}}
								/>
							))}
						</Row>
					))}
				</Box>
			</Box>
		</Card>
	);
};

export default ViewCodes;

export { viewCodesData, viewCodesModifiers, MGViewCodes };
