import React from 'react';
import PropTypes from 'prop-types';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { styled } from '@mui/material/styles';

const StyledFiberManualRecordIcon = styled(FiberManualRecordIcon)(props => ({
	color: props.active ? '#4EE567' : '#9C9C9C',
	fontSize: 18,
	paddingLeft: '6px',
}));

const ActiveIcon = ({ active }) => <StyledFiberManualRecordIcon active={active} />;

ActiveIcon.propTypes = {
	/**
	 * Control the ActiveIcon is active or not.
	 */
	active: PropTypes.bool,
};

export default ActiveIcon;
