import React from 'react';
import { SvgIcon } from '@mui/material';

const MoreIcon = ({ sx }) => (
	<SvgIcon sx={sx}>
		<svg fill="none" height="19" viewBox="0 0 3 19" width="3" xmlns="http://www.w3.org/2000/svg">
			<g id="Group 3109">
				<circle cx="1.5" cy="1.5" fill="currentColor" id="Ellipse 206" r="1.5" />
				<circle cx="1.5" cy="9.5" fill="currentColor" id="Ellipse 208" r="1.5" />
				<circle cx="1.5" cy="17.5" fill="currentColor" id="Ellipse 209" r="1.5" />
			</g>
		</svg>
	</SvgIcon>
);

export default MoreIcon;
