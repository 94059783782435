import { SnackbarProvider } from 'notistack';
import { useImportTabContext } from '@worklist-2/core/src';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

// Custom styles for snackbar
const useStyles = makeStyles()((theme, { sideBarIsOpen }) => ({
	root: {
		minWidth: '100px',
		color: 'rgba(255, 255, 255, 0.87)',
		fontFamily: theme.typography.fontFamily,

		'&.notistack-MuiContent': {
			color: theme.palette.text.primary,
		},
	},
	containerAnchorOriginBottomLeft: {
		left: sideBarIsOpen ? '284px' : '68px',
		bottom: '12px',
	},
	containerAnchorOriginTopLeft: {
		left: sideBarIsOpen ? '284px' : '68px',
		bottom: '12px',
	},
}));

const ToastProvider = ({ children }) => {
	const { sideBarIsOpen } = useImportTabContext();
	const { classes } = useStyles({ sideBarIsOpen });

	return (
		<SnackbarProvider classes={classes} maxSnack={3}>
			{children}
		</SnackbarProvider>
	);
};
export default ToastProvider;
