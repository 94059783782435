import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import ContactImg from '@worklist-2/ui/src/assets/img/ContactAvatar.png';
import { crmSearchScopes, useCRMDataLoader, fhirExtensionUrls, generateRandomString } from '@worklist-2/core/src';
import {
	DottedWrapper,
	ContactAvatar,
	ContactListItem,
	ContactText,
	NewContactFormWrapper,
	TabPanel,
} from '../../../Cases/casePopOverStyles';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PhoneIcon from '@mui/icons-material/Phone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton, Input, Stack, TextField } from '@mui/material';
import Box from '@mui/system/Box';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const RoleBox = ({ children }) => (
	<Box
		sx={{
			height: '32px',
			lineHeight: '32px',
			textAlign: 'center',
			background: '#F3F3F3',
			borderRadius: '5px',

			span: {
				color: 'rgba(0, 0, 0, 0.6)',
				fontFamily: 'Inter',
				fontStyle: 'normal',
				fontWeight: '500',
				fontSize: '14px',
			},
		}}
	>
		{children}
	</Box>
);

const Users = ({ accountData, masterOrganization }) => {
	const [editableIndex, setEditableIndex] = useState(null);
	const [showUpload, toggleUpload] = useState(false);
	const [editUser, setEditUser] = useState(null);
	const [defaultRole, setDefaultRole] = useState({});
	const [users, setUsers] = useState([]);
	const [isSubmit, setIsSubmit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const practitionerDataLoader = useCRMDataLoader({
		endpoint: `PractitionerOmegaAI/${accountData?.AccountId}`,
	});
	const practitionerRoleDataLoader = useCRMDataLoader({
		endpoint: `PractitionerRoleOmegaAI/${accountData?.AccountId}`,
	});
	const roleDataLoader = useCRMDataLoader({
		endpoint: `RoleOmegaAI/${accountData?.AccountId}`,
	});

	useEffect(() => {
		if (masterOrganization?.id) {
			fetchRole(masterOrganization?.id);
		}
	}, [masterOrganization]);

	useEffect(() => {
		if (defaultRole?.id) {
			fetchUsers(masterOrganization?.id, defaultRole?.id);
		}
	}, [defaultRole]);

	useEffect(() => {
		if (!!defaultRole?.id && isSubmit) {
			fetchUsers(masterOrganization?.id, defaultRole?.id);
			setIsSubmit(false);
		}
	}, [isSubmit]);

	const fetchRole = async organizationId => {
		try {
			setIsLoading(true);
			await roleDataLoader.load({ organization: organizationId }).then(result => {
				if (result && result.length > 0) {
					setDefaultRole(result[0]);
				}
			});
		} catch (e) {
			console.error(e);
		}
	};

	const fetchUsers = async (organizationId, roleId) => {
		try {
			setIsLoading(true);

			const param = { organization: organizationId };
			if (roleId) {
				param.roleid = roleId;
			}

			await practitionerRoleDataLoader.load(param).then(result => {
				if (result && result.length > 0) {
					const usersMapping = _.map(result, prr => {
						const email = prr.extension?.find(
							item => item.url === fhirExtensionUrls.practitionerRole.loginName
						)?.valueString;
						const contactPoints = prr.practitioner?.extension?.find(
							item => item.url === fhirExtensionUrls.practitionerRole.telecom
						)?.valueExtension?.extension;
						const phone = contactPoints?.find(item => item.url === 'phone')?.valueContactPoint?.value;
						const role = prr.extension?.find(item => item.url === fhirExtensionUrls.practitionerRole.role)
							?.valueReference?.display;
						return {
							id: prr.practitioner?.id,
							name: prr.practitioner?.display,
							role,
							phone,
							email,
						};
					});
					setUsers(usersMapping);
					setIsLoading(false);
				}
			});
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		if (!!editUser?.id && !editUser.fhirJson) {
			practitionerDataLoader.load({ id: editUser.id }).then(result => {
				if (result) {
					setEditUser(prev => ({ ...prev, fhirJson: result }));
				}
			});
		}
	}, [editUser]);

	const onChangeUser = e => {
		const temp = editUser;
		temp[e.target.name] = e.target.value;
		setEditUser(temp);
	};

	const submitUserChange = () => {
		if (editUser?.fhirJson) {
			const userFhirJson = { ...editUser.fhirJson };
			userFhirJson.name[0].given = [editUser.name];
			userFhirJson.name[0].text = editUser.name;

			const telecom = userFhirJson.telecom ?? [];
			let phoneObj = telecom.find(item => item.system === 'phone');
			if (phoneObj) {
				phoneObj.value = editUser.phone;
			} else {
				phoneObj = {
					system: 'phone',
					value: editUser.phone,
					rank: telecom.length + 1,
					id: generateRandomString(16),
				};
				telecom.push(phoneObj);
			}
			userFhirJson.telecom = telecom;

			practitionerDataLoader.update(userFhirJson.id, userFhirJson).then(result => {
				setIsSubmit(true);
				setEditableIndex(null);
				setEditUser(null);
			});
		}
	};

	return (
		<TabPanel maxHeight="500px">
			<div className="header">
				<div className="left">
					<div className="colored-icon primary-icon">
						<PersonOutlinedIcon sx={{ fontSize: 20 }} />
					</div>
					<h2>Users</h2>
				</div>
			</div>
			<div className="body">
				<div>
					<NewUsers
						accountData={accountData}
						defaultRole={defaultRole}
						masterOrganization={masterOrganization}
						setIsSubmit={setIsSubmit}
					/>
					{!isLoading ? (
						_.map(users, (user, i) =>
							editableIndex === i ? (
								<NewContactFormWrapper>
									{showUpload ? (
										<div className="upload action-btn" onMouseLeave={() => toggleUpload(false)}>
											<FileUploadIcon />
											<span>Upload</span>
										</div>
									) : (
										<div className="avatar action-btn" onMouseEnter={() => toggleUpload(true)}>
											<PersonIcon />
										</div>
									)}
									<div className="row">
										<div className="col">
											<Input
												className="large"
												defaultValue={user.name}
												name="name"
												placeholder="Full Name"
												onChange={e => onChangeUser(e)}
											/>
											<RoleBox>
												<span>{user.role}</span>
											</RoleBox>
										</div>
										<Stack width="150px">
											<Stack direction="row">
												<PhoneIcon
													sx={{
														color: '#4D79EA',
														fontSize: '18px',
														marginRight: '12px',
														paddingTop: '6px',
													}}
												/>
												<Input
													defaultValue={user.phone}
													name="phone"
													placeholder="Phone Number"
													onChange={e => onChangeUser(e)}
												/>
											</Stack>
											<Stack direction="row">
												<EmailIcon
													sx={{
														color: '#4D79EA',
														fontSize: '18px',
														marginRight: '12px',
														paddingTop: '6px',
													}}
												/>
												<Input
													readOnly
													defaultValue={user.email}
													name="email"
													placeholder="Email Address"
													onChange={e => onChangeUser(e)}
												/>
											</Stack>
										</Stack>
									</div>
									<Stack direction="row" gap="1px" position="absolute" right={0} top="-4px">
										<IconButton
											sx={{
												paddingRight: 0,
												'&.MuiIconButton-root:hover': {
													backgroundColor: 'transparent',
												},
											}}
											onClick={submitUserChange}
										>
											<CheckCircleIcon
												sx={{
													color: '#4d79ea',
													fontSize: '20px',
													marginLeft: 'auto',
												}}
											/>
										</IconButton>
										<IconButton
											sx={{
												paddingLeft: 0,
												'&.MuiIconButton-root:hover': {
													backgroundColor: 'transparent',
												},
											}}
											onClick={e => {
												setEditableIndex(null);
												setEditUser(null);
											}}
										>
											<CloseIcon
												sx={{
													color: '#0047ff',
													fontSize: '20px',
													marginLeft: 'auto',
												}}
											/>
										</IconButton>
									</Stack>
								</NewContactFormWrapper>
							) : (
								<ContactListItem key={i}>
									<ContactAvatar src={ContactImg} />
									<div className="main">
										<div style={{ flex: 1, marginRight: '10px', width: '100px' }}>
											<h3 title={user.name}>{user.name}</h3>
											<RoleBox>
												<span>{user.role}</span>
											</RoleBox>
										</div>
										<Stack
											spacing="12px"
											sx={{
												width: '200px',
											}}
										>
											<ContactText style={{ marginBottom: '10px' }}>
												<LocalPhoneIcon
													sx={{
														color: '#4D79EA',
														fontSize: '18px',
														marginRight: '12px',
													}}
												/>
												<span title={user.phone}>{user.phone}</span>
											</ContactText>
											<ContactText>
												<EmailIcon
													sx={{
														color: '#4D79EA',
														fontSize: '18px',
														marginRight: '12px',
													}}
												/>
												<span title={user.email}>{user.email}</span>
											</ContactText>
										</Stack>
										<Box sx={{ position: 'absolute', top: 0, right: 0 }}>
											<IconButton
												onClick={() => {
													setEditableIndex(i);
													setEditUser(user);
												}}
											>
												<EditOutlinedIcon
													sx={{
														color: 'rgba(0, 0, 0, 0.6)',
														fontSize: '18px',
													}}
												/>
											</IconButton>
										</Box>
									</div>
								</ContactListItem>
							)
						)
					) : (
						<Box
							sx={{
								height: '100%',
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column',
								paddingBottom: '100px',
								boxSizing: 'border-box',
							}}
						>
							<Typography
								sx={{
									textAlign: 'center',
									fontSie: '16px',
									marginBottom: '8px',
									color: 'black',
								}}
							>
								<CircularProgress
									className="progressBar"
									size={22}
									sx={{
										color: '#03dac5',
										marginRight: '16px',
									}}
								/>
								Loading...
							</Typography>
						</Box>
					)}
				</div>
			</div>
		</TabPanel>
	);
};

export default Users;

const NewUsers = ({ accountData, masterOrganization, defaultRole, setIsSubmit }) => {
	const [newUser, toggleNewUser] = useState(false);

	return (
		<>
			<DottedWrapper>
				<div
					className={`card ${newUser && 'active'}`}
					onClick={() => {
						toggleNewUser(true);
					}}
				>
					<PersonAddAltIcon />
					<span>Add New</span>
				</div>
			</DottedWrapper>
			{newUser && (
				<NewUserForm
					accountData={accountData}
					defaultRole={defaultRole}
					masterOrganization={masterOrganization}
					setIsSubmit={setIsSubmit}
					toggleNewUser={toggleNewUser}
				/>
			)}
		</>
	);
};

const NewUserForm = ({ accountData, toggleNewUser, defaultRole, masterOrganization, setIsSubmit }) => {
	const [showUpload, toggleUpload] = useState(false);
	const practitionerDataLoader = useCRMDataLoader({
		endpoint: `PractitionerOmegaAI/${accountData?.AccountId}`,
	});
	const practitionerRoleDataLoader = useCRMDataLoader({
		endpoint: `PractitionerRoleOmegaAI/${accountData?.AccountId}`,
	});

	const form = useForm({
		resolver: yupResolver(
			yup.object().shape({
				name: yup.string().required('Name is required'),
				email: yup.string().required('Email is required').email('Format is incorrect'),
			})
		),
	});

	const submitNewUser = async data => {
		const practitionerJson = generatePractitionerJson(data);
		await practitionerDataLoader.save(null, practitionerJson).then(async result => {
			if (result?.status == 200) {
				const practitionerRoleJson = generatePractitionerRoleJson(result.data);
				await practitionerRoleDataLoader.save(null, practitionerRoleJson).then(() => {
					setIsSubmit(true);
					toggleNewUser(false);
				});
			} else {
				form.setError('email', { type: 'custom', message: 'Email already exists' });
			}
		});
	};

	const generatePractitionerJson = submitData => {
		const practitionerJson = {
			resourceType: 'Practitioner',
			active: true,
			extension: [
				{
					url: fhirExtensionUrls.practitionerRole.loginName,
					valueString: submitData.email,
				},
				{
					url: fhirExtensionUrls.practitioner.isUserActive,
					valueBoolean: true,
				},
			],
			name: [
				{
					given: [submitData.name],
					family: '',
					text: submitData.name,
				},
			],
			telecom: [
				{
					system: 'email',
					value: submitData.email,
					rank: 1,
					id: generateRandomString(16),
				},
			],
		};

		if (submitData.phone && submitData.phone != '') {
			practitionerJson.telecom.push({
				system: 'phone',
				value: submitData.phone,
				rank: 2,
				id: generateRandomString(16),
			});
		}

		return practitionerJson;
	};

	const generatePractitionerRoleJson = inputData => ({
		resourceType: 'PractitionerRole',
		active: true,
		extension: [
			{
				url: fhirExtensionUrls.practitionerRole.role,
				valueReference: {
					id: defaultRole?.id,
					reference: `Role/${defaultRole?.id}`,
					display: defaultRole?.name,
				},
			},
		],
		organization: {
			display: masterOrganization?.name,
			id: masterOrganization?.id,
			reference: `Organization/${masterOrganization?.id}`,
		},
		practitioner: {
			display: inputData?.name?.[0]?.text,
			id: inputData?.id,
			reference: `Practitioner/${inputData?.id}`,
		},
	});

	return (
		<NewContactFormWrapper>
			{showUpload ? (
				<div className="upload action-btn" onMouseLeave={() => toggleUpload(false)}>
					<FileUploadIcon />
					<span>Upload</span>
				</div>
			) : (
				<div className="avatar action-btn" onMouseEnter={() => toggleUpload(true)}>
					<PersonIcon />
				</div>
			)}
			<div className="row">
				<div className="col">
					<TextField
						{...form.register('name')}
						data-testid="new-name"
						error={!!form.formState.errors.name}
						helperText={form.formState.errors.name?.message}
						placeholder="Full Name"
						size="small"
						variant="standard"
					/>
					<RoleBox>
						<span>{defaultRole?.name ?? 'Admin'}</span>
					</RoleBox>
				</div>
				<div style={{ width: '150px' }}>
					<Stack direction="row">
						<PhoneIcon
							sx={{
								color: '#4D79EA',
								fontSize: '18px',
								marginRight: '12px',
								paddingTop: '6px',
							}}
						/>
						<TextField
							{...form.register('phone')}
							data-testid="new-phone"
							placeholder="Phone Number"
							size="small"
							variant="standard"
						/>
					</Stack>
					<Stack direction="row">
						<EmailIcon
							sx={{
								color: '#4D79EA',
								fontSize: '18px',
								marginRight: '12px',
								paddingTop: '6px',
							}}
						/>
						<TextField
							{...form.register('email')}
							data-testid="new-email"
							error={!!form.formState.errors.email}
							helperText={form.formState.errors.email?.message}
							placeholder="Email Address"
							size="small"
							variant="standard"
						/>
					</Stack>
				</div>
			</div>
			<Stack direction="row" position="absolute" right={0} spacing="1px" top="-4px">
				<IconButton
					sx={{
						paddingRight: 0,
						'&.MuiIconButton-root:hover': {
							backgroundColor: 'transparent',
						},
					}}
					type="button"
					onClick={form.handleSubmit(submitNewUser)}
				>
					<CheckCircleIcon
						sx={{
							color: '#4d79ea',
							fontSize: '18px',
							marginLeft: 'auto',
						}}
					/>
				</IconButton>
				<IconButton
					sx={{
						paddingLeft: 0,
						'&.MuiIconButton-root:hover': {
							backgroundColor: 'transparent',
						},
					}}
					onClick={() => {
						toggleNewUser(false);
					}}
				>
					<CloseIcon
						sx={{
							color: '#0047ff',
							fontSize: '20px',
							marginLeft: 'auto',
						}}
					/>
				</IconButton>
			</Stack>
		</NewContactFormWrapper>
	);
};
