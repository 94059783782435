import React from 'react';
import CheckIcon from '@worklist-2/ui/src/assets/icons/checkmark.svg';
import Box from '@mui/material/Box';
import { Popover, Typography, Icon } from '@mui/material';
import RectangleShutterIcon from '../../../../assets/icons/imageViewer/tools/shutter.svg';
import RectangleShutterCircleIcon from '../../../../assets/icons/imageViewer/tools/shutter-circle.svg';
import ShutterRectangleTool from '../../cornerstoneTools/ShutterTools/ShutterRectangleTool';
import { useImageViewerCornerstoneContext } from '../../contexts/ImageViewerCornerstoneContext';
import ShutterCircleTool from '../../cornerstoneTools/ShutterTools/ShutterCircleTool';
import ShutterFreehandTool from '../../cornerstoneTools/ShutterTools/ShutterFreehandTool';
import { ToolGroupManager } from '@cornerstonejs/tools';

export const ShutterToolsNames = [
	ShutterRectangleTool.toolName,
	ShutterCircleTool.toolName,
	ShutterFreehandTool.toolName,
];

export const ShutterToolPresetMenu = ({ position, isOpen, onClose, activeViewportId }) => {
	const { changeActiveTool } = useImageViewerCornerstoneContext();
	const toolGroups = ToolGroupManager.getAllToolGroups();

	const clearActiveTools = () => {
		toolGroups?.forEach(toolGroup => {
			const instanceRect = toolGroup.getToolInstance(ShutterRectangleTool.toolName);
			const instanceScis = toolGroup.getToolInstance(ShutterCircleTool.toolName);

			const element = document.getElementById(activeViewportId);

			instanceRect?._deactivateDraw(element);
			instanceScis?._deactivateDraw(element);
		});
	};

	let activeMenuItem = '';

	if (toolGroups?.[0]?.toolOptions) {
		for (const [key] of Object.entries(toolGroups?.[0]?.toolOptions)) {
			if (ShutterToolsNames.includes(key)) {
				if (toolGroups[0].toolOptions[key]?.mode === 'Active') {
					activeMenuItem = key;
				}
			}
		}
	}

	const PRESETS = [
		{
			menuLabel: 'Rectangle Shutter',
			toolName: ShutterRectangleTool.toolName,
			icon: RectangleShutterIcon,
			setTool: () => {
				clearActiveTools();
				changeActiveTool({ activeTool: ShutterRectangleTool.toolName });
			},
		},
		{
			menuLabel: 'Circle Shutter',
			toolName: ShutterCircleTool.toolName,
			icon: RectangleShutterCircleIcon,
			setTool: () => {
				clearActiveTools();
				changeActiveTool({ activeTool: ShutterCircleTool.toolName });
			},
		},
		{
			menuLabel: 'Freehand Shutter',
			toolName: ShutterFreehandTool.toolName,
			icon: RectangleShutterCircleIcon,
			setTool: () => {
				clearActiveTools();
				changeActiveTool({ activeTool: ShutterFreehandTool.toolName });
			},
		},
	];

	return (
		<Popover
			PaperProps={{
				sx: {
					margin: '10px',
					width: 'auto',
					minWidth: '180px',
					padding: '14px 16px',
					background: '#393939',
					border: '1px solid rgba(255, 255, 255, 0.1)',
					borderRadius: '6px',
					boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
					display: 'flex',
					flexDirection: 'column',
					gap: '12px',
					zIndex: 1000,
				},
			}}
			anchorPosition={{ top: position.y, left: position.x }}
			anchorReference="anchorPosition"
			open={isOpen}
			onClose={onClose}
			onMouseDown={e => e.stopPropagation()}
			onMouseUp={e => e.stopPropagation()}
		>
			{PRESETS.map(item => (
				<Box
					key={item.menuLabel}
					as="button"
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						background: 'transparent',
						border: 'none',
						cursor: 'pointer',
					}}
					variant="text"
					onClick={e => {
						e.stopPropagation();
						item.setTool(e);
						onClose();
					}}
					onMouseDown={e => e.stopPropagation()}
					onMouseUp={e => e.stopPropagation()}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '10px',
						}}
					>
						<Icon
							className="WheelItemIcon"
							component={item.icon}
							sx={{
								color: 'rgba(255, 255, 255, 0.6)',
							}}
						/>
						<Typography
							sx={{
								fontFamily: 'Roboto',
								fontSize: '16px',
								opacity: '87%',
								color: 'rgba(255, 255, 255, 0.87)',
								paddingRight: '18px',
							}}
						>
							{item.menuLabel}
						</Typography>
					</Box>
					{activeMenuItem === item.toolName && <CheckIcon data-testid="checkmark" />}
				</Box>
			))}
		</Popover>
	);
};
