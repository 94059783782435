import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import moment from 'moment';
import { debounce } from 'lodash';

import {
	Box,
	Typography,
	CircularProgress,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import { useCrmContext } from '../../../context/CRMContext';
import { useCRMSearchScope } from '@worklist-2/core/src';

import FilterableColumn from './FilterableColumn';

import {
	AirportShuttleOutlined,
	PanToolOutlined,
	ReportOutlined,
	CheckCircleOutlineOutlined,
} from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.white,
		borderWidth: 0,
		color: '#666666',
		fontWeight: '400',
		cursor: 'pointer',

		'& small': {
			fontSize: '14px !important',
		},
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		borderWidth: 0,
		color: theme.palette.common.black,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&': {
		color: theme.palette.common.black,
	},
	'&:hover td': {
		backgroundColor: '#C8D6FF',
		cursor: 'pointer',
	},
	'& td': {
		padding: 10,
	},
	'&:nth-of-type(odd)': {
		backgroundColor: '#F9FBFF',
	},
	'&:nth-of-type(even)': {
		backgroundColor: theme.palette.common.white,
	},
	'&.transparent:nth-of-type(odd)': {
		backgroundColor: theme.palette.common.white,
	},
}));

const CasesTable = ({ data, setSearchText, searchText, setField, field, setData }) => {
	const [newData, setNewData] = useState([]);
	const [isScrollVisible, setIsScrollVisible] = useState(false);
	const { toggleDetails } = useCrmContext();
	const { loading } = useCRMSearchScope();
	const [order, setOrder] = useState('asc');
	const navigate = useNavigate();

	const hiddenScroll = useMemo(
		() =>
			debounce(() => {
				setIsScrollVisible(false);
			}, 300),
		[]
	);

	const handleScroll = () => {
		setIsScrollVisible(true);
		hiddenScroll();
	};

	const handleInputText = e => {
		let tempValue = e?.target?.value;

		if (field === 'CreatedDateTime' && !!e) {
			tempValue = moment(e).format();
		}

		if (Array.isArray(tempValue)) {
			tempValue = e.target.value.join(',');
		}

		setSearchText(tempValue);
	};

	useEffect(() => {
		setSearchText('');
	}, [field]);

	const handleRequestSort = (event, property) => {
		const isAsc = field === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');

		dataSort(order);
	};

	const dataSort = order => {
		const tempData = newData ? [...newData] : [];
		tempData.sort((a, b) => {
			if (!a[field]) {
				return order === 'asc' ? -1 : 1;
			}

			if (!b[field]) {
				return order === 'asc' ? 1 : -1;
			}

			return order === 'asc' ? b[field]?.localeCompare(a[field]) : a[field]?.localeCompare(b[field]);
		});
		setNewData(tempData);
	};

	useEffect(() => {
		setNewData(data);
	}, [data]);

	const STATUS_ICONS = {
		'In Progress': <AirportShuttleOutlined sx={{ fontSize: 18, color: '#3EB967', marginRight: '15px' }} />,
		Waiting: <PanToolOutlined sx={{ fontSize: 18, color: '#EEBA02', marginRight: '15px' }} />,
		'On Hold': <ReportOutlined sx={{ fontSize: 18, color: '#EF5D5D', marginRight: '15px' }} />,
		Closed: <CheckCircleOutlineOutlined sx={{ fontSize: 18, color: '#4D79EA', marginRight: '15px' }} />,
	};

	return (
		<TableContainer
			id="caseTableContainer"
			style={{
				border: 0,
				borderRadius: 0,
				borderTopRightRadius: '20px',
				borderTopLeftRadius: '20px',
				boxShadow: 'none',
				maxHeight: '100%',
				width: '100%',
			}}
			sx={{
				'&::-webkit-scrollbar-thumb': {
					background: isScrollVisible ? '#C4C4C4 !important' : '#FFF !important',
					borderRadius: '3px',
				},

				'&::-webkit-scrollbar-track': {
					background: '#FFF !important',
				},
			}}
			onMouseLeave={() => setIsScrollVisible(false)}
			onMouseOver={e => setIsScrollVisible(e.target.id === 'caseTableContainer')}
			onScroll={handleScroll}
		>
			<Table stickyHeader aria-label="customized table" sx={{ width: '100%' }}>
				<TableHead>
					<TableRow sx={{ height: '66px' }}>
						<FilterableColumn
							field={field}
							fieldName="Case Number"
							fieldValue="CaseNumber"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="text"
							value={searchText}
						/>
						<FilterableColumn
							field={field}
							fieldName="Subject"
							fieldValue="Subject"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="text"
							value={searchText}
						/>
						<FilterableColumn
							field={field}
							fieldName="Type"
							fieldValue="Type"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="dropdown"
							value={searchText}
						/>
						<FilterableColumn
							field={field}
							fieldName="Account Name"
							fieldValue="AccountName"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="text"
							value={searchText}
						/>
						<FilterableColumn
							field={field}
							fieldName="Date/Time Opened"
							fieldValue="CreatedDateTime"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="dateTimePicker"
							value={searchText}
						/>
						<FilterableColumn
							field={field}
							fieldName="Priority"
							fieldValue="Priority"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="dropdown"
							value={searchText}
						/>
						<FilterableColumn
							field={field}
							fieldName="Status"
							fieldValue="Status"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="dropdown"
							value={searchText}
						/>
					</TableRow>
				</TableHead>
				{!loading ? (
					<TableBody>
						{newData && newData.length > 0 ? (
							newData.map((row, i) => (
								<StyledTableRow
									key={i}
									onClick={() =>
										toggleDetails({
											data: row,
											tag: 'cases',
											fullData: newData,
											setFullData: setData,
										})
									}
									onDoubleClick={() => {
										navigate(`/case/${row.CaseId}`);
										toggleDetails();
									}}
								>
									<StyledTableCell align="center">{row.CaseNumber}</StyledTableCell>
									<StyledTableCell align="center">{row.Subject}</StyledTableCell>
									<StyledTableCell align="center">
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											{row.Type}
										</div>
									</StyledTableCell>
									<StyledTableCell align="center">{row.AccountName}</StyledTableCell>
									<StyledTableCell align="center">
										{row.CreatedDateTime
											? `${new Date(row.CreatedDateTime).toLocaleDateString()} ${new Date(
													row.CreatedDateTime
											  ).toLocaleTimeString(navigator.language, {
													hour: '2-digit',
													minute: '2-digit',
											  })}`
											: ''}
									</StyledTableCell>
									<StyledTableCell align="center">{row.Priority}</StyledTableCell>
									<StyledTableCell>
										<div
											style={{
												textTransform: 'capitalize',
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											{STATUS_ICONS[row.Status]}
											{row.Status?.replace('_', ' ')}
										</div>
									</StyledTableCell>
								</StyledTableRow>
							))
						) : (
							<StyledTableRow>
								<StyledTableCell colSpan={8}>
									<Box
										sx={{
											height: '100%',
											width: '100%',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Typography
											sx={{
												textAlign: 'center',
												fontSie: '16px',
												marginBottom: '8px',
												color: 'black',
											}}
										>
											No Result
										</Typography>
									</Box>
								</StyledTableCell>
							</StyledTableRow>
						)}
					</TableBody>
				) : (
					<TableBody>
						<StyledTableRow>
							<StyledTableCell colSpan={8}>
								<Box
									sx={{
										height: '100%',
										width: '100%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<Typography
										sx={{
											textAlign: 'center',
											fontSie: '16px',
											marginBottom: '8px',
											color: 'black',
										}}
									>
										<CircularProgress
											className="progressBar"
											size={22}
											sx={{
												color: '#03dac5',
												marginRight: '16px',
											}}
										/>
										Loading...
									</Typography>
								</Box>
							</StyledTableCell>
						</StyledTableRow>
					</TableBody>
				)}
			</Table>
		</TableContainer>
	);
};

export default CasesTable;
