import { useState } from 'react';
import { useSnackbar } from 'notistack';

const AUTO_HIDE_DURATION = 5000;

export const useToast = () => {
	// If true, open the toast, else close the toast.
	const [toastOpen, setToastOpen] = useState(false);
	// Message shown on toast.
	const [toastMessage, setToastMessage] = useState('');
	// duration after which toaster needs to be auto hidden
	const [autoHideDuration, setAutoHideDuration] = useState(AUTO_HIDE_DURATION);
	/**
	 * @param {Boolean} isToastOpen - If true, open the toast else close the toast
	 * @param {string} message - Message shown on toast.
	 */
	const [anchorOrigin, setAnchorOrigin] = useState('');

	const { enqueueSnackbar } = useSnackbar();

	const [actions, setActions] = useState({});
	/**
	 * @param {string} severity - Message shown on toast.
	 */
	const [severity, setSeverity] = useState('');

	const setToastUtility = (isToastOpen, message, toastAnchorOrigin, severityType, onUndo) => {
		setToastOpen(isToastOpen);
		setToastMessage(message);
		setAnchorOrigin(toastAnchorOrigin);
		setSeverity(severityType);
		// Has to be done this way because React doesn't like storing functions in the state
		setActions({
			onUndo,
		});
	};

	// Handle to close toast manually.
	const handleToastClose = () => {
		setToastUtility(false);
	};

	/**
	 *
	 * @param {number} duration - duration of auto hide
	 * @param {Boolean} disableAutoHide - set true and invoke to disable auto hide
	 * @param {Boolean} enableDefaultAutoHide - set true and invoke to restore default auto hide
	 *
	 */
	const setAutoHide = ({ duration, disableAutoHide, enableDefaultAutoHide }) => {
		if (duration) {
			setAutoHideDuration(duration);
		}
		if (disableAutoHide) {
			setAutoHideDuration(null);
		}
		if (enableDefaultAutoHide) {
			setAutoHideDuration(AUTO_HIDE_DURATION);
		}
	};

	/**
	 * This function is designed to work with the notistack library.
	 */
	const showToast = message => {
		enqueueSnackbar(message, {
			anchorOrigin,
			autoHideDuration: AUTO_HIDE_DURATION,
			style: {
				backgroundColor: '#393939', // theme.palette.rsSecondary.medium
				border: '1px solid rgba(255, 255, 255, 0.2)',
				borderRadius: '6px',
				minWidth: 'auto',
				width: 'max-content',
			},
		});
	};

	const showToastWithAction = (message, options = {}) => {
		enqueueSnackbar(message, {
			variant: 'backdrop',
			anchorOrigin,
			autoHideDuration: AUTO_HIDE_DURATION,
			persist: options?.persist,
			saveProps: options?.saveProps,
			discardProps: options?.discardProps,
		});
	};

	return {
		setToastUtility,
		showToast,
		toastOpen,
		toastMessage,
		handleToastClose,
		anchorOrigin,
		actions,
		severity,
		setAutoHide,
		autoHideDuration,
		showToastWithAction,
	};
};

export default useToast;
