import React from 'react';
import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';

const TimeComponent = ({ model, formField, disabled, variant, onChange, className }) => (
	<LocalizationProvider dateAdapter={DateAdapter}>
		<TimePicker
			InputLabelProps={{ shrink: true, color: 'primary' }}
			className={className}
			data-testid="form-field"
			defaultValue={
				formField.multiple
					? formField.getLabel(formField.mapping.split('.').reduce((p, c) => (p && p[c]) || null, model))
					: formField.mapping.split('.').reduce((p, c) => (p && p[c]) || null, model)
			}
			disabled={disabled || formField.disabled}
			id={formField.id ? formField.id : `form-field-${formField.name}`}
			label={formField.name}
			placeholder={disabled ? null : formField.placeholder}
			renderInput={params => <TextField {...params} />}
			required={formField.required}
			variant={variant || 'outlined'}
			onChange={(event, value) => onChange(event, value, formField.mapping ? formField.mapping : formField.name)}
		/>
	</LocalizationProvider>
);

const Time = React.memo(TimeComponent);

export default Time;
