import React from 'react';
import { Popover, ListItem, ListItemButton, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import getDefaultRenderingEngine from '../../cornerstone/getDefaultRenderingEngine';
import { triggerEvent } from '@cornerstonejs/core';
import { ROTATE_MODIFIED } from '../../contexts/consts/consts';

export const ROTATE_PRESETS = [
	{
		label: 'Reset',
		value: 'reset',
	},
	{
		label: 'Rotate +90°',
		value: '+90',
	},
	{
		label: 'Rotate -90°',
		value: '-90',
	},
	{
		label: 'Rotate 180°',
		value: '180',
	},
];

export const RotatePresets = ({ viewportId, position, isOpen, onClose }) => {
	const renderingEngine = getDefaultRenderingEngine();

	if (!renderingEngine) {
		return null;
	}

	const renderingEngineViewport = renderingEngine.getViewport(viewportId);

	if (!renderingEngineViewport) {
		return null;
	}

	const onPresetChange = preset => {
		onClose();

		const viewport = renderingEngine.getViewport(viewportId);

		if (!viewport) {
			return;
		}

		const rotation = viewport.getRotation();

		if (preset === 'reset') {
			viewport.setProperties({
				rotation: 0,
			});
		}

		if (preset === '+90') {
			viewport.setProperties({
				rotation: rotation + 90,
			});
		}

		if (preset === '-90') {
			viewport.setProperties({
				rotation: rotation - 90,
			});
		}

		if (preset === '180') {
			viewport.setProperties({
				rotation: rotation + 180,
			});
		}

		if (!viewport.suppressEvents) {
			triggerEvent(viewport.element, ROTATE_MODIFIED, {
				viewportId: viewport.id,
			});
		}

		viewport.render();
	};

	return (
		<Popover
			PaperProps={{
				sx: {
					padding: 0,
					background: '#393939',
					border: '1px solid rgba(255, 255, 255, 0.1)',
					borderRadius: '6px',
					zIndex: 1000,
				},
			}}
			anchorPosition={{ top: position.y, left: position.x }}
			anchorReference="anchorPosition"
			open={isOpen}
			onClose={onClose}
			onMouseDown={e => e.stopPropagation()}
			onMouseUp={e => e.stopPropagation()}
		>
			{ROTATE_PRESETS.map(item => (
				<ListItem key={item.value} sx={{ p: 0 }}>
					<ListItemButton sx={{ gap: 2 }} onClick={() => onPresetChange(item.value)}>
						<ListItemText
							primary={item.label}
							sx={{
								color: '#fff',
							}}
						/>
						<CheckIcon
							sx={{
								color: '#42A5F5',
								opacity: 0,
							}}
						/>
					</ListItemButton>
				</ListItem>
			))}
		</Popover>
	);
};
