import React, { useCallback, useMemo, useState } from 'react';
import MuiSpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

export const StyledSpeedDialAction = styled(SpeedDialAction)(({ selected, theme }) => ({
	...(selected && {
		backgroundColor: theme.palette?.rsPrimary?.button,
		'&:hover': {
			backgroundColor: theme.palette?.rsPrimary?.hover,
		},
	}),
}));

const SpeedDial = ({ children, actionItems, position, x, y, icon, selected, onItemSelected, setBackdropOpen }) => {
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		// toggle open and close based on current state
		setOpen(prev => {
			setBackdropOpen && setBackdropOpen(!prev);
			return !prev;
		});
	};

	const handleItemClick = useCallback(
		(event, action) => {
			if (onItemSelected) {
				onItemSelected(event.target, action);
			}
		},
		[onItemSelected]
	);

	const handleClose = (event, reason) => {
		// we only want the menu to close when pressing escape or they click somewhere else
		if (['blur', 'escapeKeyDown'].includes(reason)) {
			setOpen(false);
			setBackdropOpen && setBackdropOpen(false);
		}
	};

	const sx = useMemo(
		() => ({
			position,
			right: x,
			bottom: y,
		}),
		[position, x, y]
	);

	return (
		<MuiSpeedDial
			ariaLabel="SpeedDial Menu"
			icon={icon || <SpeedDialIcon />}
			open={open}
			sx={sx}
			onClick={handleClick}
			onClose={handleClose}
		>
			{actionItems?.map((action, idx) => (
				<StyledSpeedDialAction
					key={action.key || idx}
					tooltipOpen
					data-cy={action.name}
					icon={action.icon}
					selected={selected == action.key}
					tooltipTitle={action.tooltipTitle ? action.tooltipTitle : action.name}
					onClick={event => {
						handleItemClick(event, action);
					}}
				/>
			))}
			{children}
		</MuiSpeedDial>
	);
};

SpeedDial.propTypes = {
	/**
	 * Array of items to be added to the speed dial
	 */
	actionItems: PropTypes.array,

	/**
	 * Positioning of the speed dial - relative or absolute
	 * Relative positioning is required if we want a parent element to handle the positioning
	 */
	position: PropTypes.oneOf(['absolute', 'relative']),

	/**
	 * x-coordinate of the speed dial relative to the bottom right hand corner
	 */
	x: PropTypes.number,

	/**
	 * y-coordinate of the speed dial relative to the bottom right hand corner
	 */
	y: PropTypes.number,

	/**
	 * id of the selected item - this will map to "key" value in action item
	 */
	selected: PropTypes.string,

	/**
	 * Either text or ReactJs element to display
	 */
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

	/**
	 * Callback handler for when certain item of speed dial is selected
	 */
	onItemSelected: PropTypes.func,
};

SpeedDial.defaultProps = {
	actionItems: [],
	position: 'absolute',
	x: 0,
	y: 0,
	selected: '',
};

export default SpeedDial;
