import axios from 'axios';
import handleApiResponse from './utils';
import { logError } from '@rs-core/index';

const getLinkedPatientsStudies = async ({ __config, patientData }) => {
	try {
		const baseUrl = `${__config.data_sources.fhir}/ImagingStudyWorklist/elk`;
		const linkedPatientsArray = patientData?.link?.map(patient => patient.other.id) || [];

		if (linkedPatientsArray.length === 0) return []; // Early return if no linked patients

		const queryParams = `linkedpatient=${linkedPatientsArray.join(',')}`;
		const response = await axios.get(`${baseUrl}?${queryParams}`);

		const handledResponse = handleApiResponse(response);
		return handledResponse?.data?.entry?.map(item => item.resource) || []; // Directly map or return an empty array
	} catch (error) {
		logError('Error fetching linked patients studies:', error);
		throw error;
	}
};

export default getLinkedPatientsStudies;
