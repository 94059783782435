import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Box, Button, Typography, IconButton, Collapse } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useConfig, useAuth } from '@worklist-2/core/src';
import { useCrmContext } from '../../../../../context/CRMContext';
import LastPageIcon from '@mui/icons-material/LastPage';
import BusinessIcon from '@mui/icons-material/Business';

import { Collapsible, TabPanel, Wrapper, FeaturesBox, FeatureItemBox } from '../../../Cases/casePopOverStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import _ from 'lodash';
import { defaultFeatures } from '../../../Cases/utils';

const SubFeatures = ({ accountData, orgData, setOrganizationData, setStep }) => {
	const __config = useConfig();
	const { authorized } = useAuth();
	const { toggleDetails, selectedFeature, setSelectedFeature, currentFeatures, setCurrentFeatures } = useCrmContext();
	const [collapsedId, setCollapsed] = useState(null);
	const [features, setFeatures] = useState([]);
	const [loading, setLoading] = useState(false);

	const getOrganizationFeatures = async () => {
		await axios
			.get(`${__config.data_sources.breeze}/OrganizationOmegaAI/${accountData?.AccountId}/${orgData.id}/features`)
			.then(resp => {
				if (resp?.status === 200) {
					if (resp.data) {
						setFeatures(JSON.parse(resp.data)?.FeatureExtJson);
					} else {
						setFeatures(defaultFeatures);
					}
				}
			});
	};

	useEffect(() => {
		async function asynchronousEffect() {
			if (authorized) {
				await getOrganizationFeatures().catch(console.error);
			}
		}

		asynchronousEffect();
	}, [authorized, orgData.id]);

	const toggleAll = useMemo(
		() => _.findIndex(selectedFeature.permissions, { action: ['read'] }) <= -1,
		[selectedFeature]
	);

	const handleToggleAllPermission = async () => {
		setLoading(true);
		const tempSubFeatures = _.cloneDeep(selectedFeature);
		const tempPermissions = tempSubFeatures.permissions.map(subFeature => {
			if (toggleAll) {
				if (!subFeature.action.includes('read')) {
					subFeature.action.push('read');
				}
			} else {
				subFeature.action = subFeature.action.filter(action => action !== 'read');
			}

			return subFeature;
		});

		tempSubFeatures.permission = tempPermissions;

		setSelectedFeature(tempSubFeatures);

		await new Promise(resolve => {
			setTimeout(resolve, 300);
		});
		setLoading(false);
	};

	const handleTogglePermission = currentFeature => {
		const tempSubFeatures = _.cloneDeep(selectedFeature);
		const tempPermissions = tempSubFeatures.permissions.map(subFeature => {
			if (subFeature.resource !== currentFeature) {
				return subFeature;
			}

			if (subFeature.action.includes('read')) {
				subFeature.action = subFeature.action.filter(action => action !== 'read');
			} else {
				subFeature.action.push('read');
			}

			return subFeature;
		});

		tempSubFeatures.permission = tempPermissions;

		setSelectedFeature(tempSubFeatures);
		return true;
	};

	const onCollapsed = id => {
		setCollapsed(collapsedId === id ? null : id);
	};

	const saveOrganizationFeatures = async () => {
		let IsSuccess = false;
		const payload = {
			FeatureExtJson: features.map(feature => {
				if (feature.resource !== selectedFeature.resource) {
					return feature;
				}
				return selectedFeature;
			}),
		};

		await axios
			.put(
				`${__config.data_sources.breeze}/OrganizationOmegaAI/${accountData?.AccountId}/${orgData.id}/features`,
				payload
			)
			.then(resp => {
				if (resp?.status === 200) {
					IsSuccess = true;
				}
			});

		setCurrentFeatures(
			currentFeatures.map(feature => {
				if (feature.resource !== selectedFeature.resource) {
					return feature;
				}
				return selectedFeature;
			})
		);
		setStep('features');

		return IsSuccess;
	};

	return (
		<form>
			<Wrapper className="invisible-scrollbar">
				<div className="header" style={{ backgroundColor: '#FFF', padding: '5px 15px' }}>
					<div className="actions">
						<div className="left">
							<IconButton
								onClick={() => {
									setStep('features');
								}}
							>
								<ArrowBackIcon />
							</IconButton>
						</div>
						<div className="right">
							<IconButton
								onClick={() => {
									setStep(null);
									setOrganizationData(null);
									toggleDetails();
								}}
							>
								<LastPageIcon />
							</IconButton>
						</div>
					</div>
				</div>
				<div className="body" style={{ position: 'relative' }}>
					<TabPanel className="invisible-scrollbar" style={{ margin: '0px 23px' }}>
						<div className="header">
							<div className="left">
								<div className="colored-icon info-icon">
									<BusinessIcon sx={{ fontSize: 20 }} />
								</div>
								<h2>Select Sub-Features</h2>
							</div>
						</div>
						<div className="body">
							<Collapsible>
								<CollapseHandle
									collapsedId={collapsedId}
									id="subfeatures"
									label="Sub-Features"
									onCollapsed={onCollapsed}
								/>
								<Collapse in={collapsedId !== 'subfeatures'}>
									<div className="colored-icon gray-icon">
										<Box
											sx={{
												marginTop: '10px',
												paddingRight: '6px',
												width: '100%',
												overflowY: 'auto',
											}}
										>
											{selectedFeature && (
												<FeaturesBox>
													<Button
														sx={{
															marginLeft: '18px',
															marginBottom: '15px',
															padding: '2px 10px',
															border: '1px solid #4D79EA',
															fontSize: '12px',
															letterSpacing: '0.5px',
															color: toggleAll ? '#4D79EA' : '#C4C4C4',
														}}
														onClick={handleToggleAllPermission}
													>
														View All
													</Button>
													{!loading &&
														selectedFeature.permissions.map(item => (
															<FeatureItemBox>
																<VisibilitySwitch
																	data={item}
																	onToggle={handleTogglePermission}
																/>
																<Typography
																	sx={{
																		marginLeft: '20px',
																		fontWeight: 500,
																		color: 'rgba(0, 0, 0, 0.87)',
																	}}
																>
																	{item.display}
																</Typography>
															</FeatureItemBox>
														))}
												</FeaturesBox>
											)}
										</Box>
									</div>
								</Collapse>
								<div>&nbsp;</div>
							</Collapsible>
						</div>
					</TabPanel>
					<div className="footer" style={{ position: 'absolute', width: '490px', marginLeft: '23px' }}>
						<button
							className="outline"
							data-testid="cancelButton"
							type="button"
							onClick={() => {
								setStep('features');
							}}
						>
							GO BACK
						</button>
						<div className="right">
							<button
								className="fill"
								data-testid="saveButton"
								type="button"
								value="saveSubFeatures"
								onClick={saveOrganizationFeatures}
							>
								SAVE
							</button>
						</div>
					</div>
				</div>
			</Wrapper>
		</form>
	);
};

const CollapseHandle = ({ id, collapsedId, label, onCollapsed }) => (
	<div className="handle" onClick={() => onCollapsed(id)}>
		<span>{label}</span>
		{collapsedId === id ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
	</div>
);

const VisibilitySwitch = ({ data, onToggle }) => {
	const [isActive, setIsActive] = useState(data.action.includes('read'));
	const handleClick = permission => {
		const IsSuccess = onToggle(permission);

		if (IsSuccess) {
			setIsActive(prev => !prev);
		}
	};

	return isActive ? (
		<VisibilityIcon sx={{ color: '#4D79EA', cursor: 'pointer' }} onClick={() => handleClick(data.resource)} />
	) : (
		<VisibilityOffIcon sx={{ color: '#C4C4C4', cursor: 'pointer' }} onClick={() => handleClick(data.resource)} />
	);
};

export default SubFeatures;
