import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NotAuthorized = ({ t }) => (
	<Box
		sx={{
			height: 'calc(100vh - 230px)',
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
			paddingBottom: '100px',
			boxSizing: 'border-box',
		}}
	>
		<Typography
			data-testid="not_authorized"
			sx={{
				textAlign: 'center',
				fontWeight: '500',
				fontSie: '16px',
				color: 'text.primary',
				marginBottom: '8px',
			}}
		>
			{t('notAuthorized:error')}
		</Typography>
	</Box>
);

NotAuthorized.propTypes = {
	/**
	 * Translation function
	 */
	t: PropTypes.func,
};

export default NotAuthorized;
