import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import MobileTitle from '../../MobileTitle';
import AddNewButton from '@worklist-2/ui/src/components/AddNewButton';
import MobileUsersList from '../MobileUsersList';
import MobileBottomDrawer from '../../MobileDrawer/MobileBottomDrawer';
import { useConfig, useIsMobile, mediaMobile } from '@worklist-2/core/src';

import { useShareDrawerStore } from '@worklist-2/patientPortal/src/stores';

import SharedUsers from '../../../SharedHistory/SharedUsers';
import ShareContactList from '../../../ShareContact/ShareContactList';

const MobileSharedWithSection = ({ study, studyAdditionalInfo, sx }) => {
	const initStudyDrawer = useShareDrawerStore(state => state.initStudyDrawer);
	const isStudyShareSuccess = useShareDrawerStore(state => state.isStudyShareSuccess);
	const setStudyShareSuccess = useShareDrawerStore(state => state.setStudyShareSuccess);
	const [users, setUsers] = useState(study.sharedInformation ?? []);
	const [isOpenSharedDrawer, setIsOpenSharedDrawer] = useState(false);

	const isMobile = useIsMobile();
	const __config = useConfig();

	const showAddButton =
		studyAdditionalInfo.isShared !== 'withMe' ||
		(studyAdditionalInfo.isShared === 'withMe' && studyAdditionalInfo.permission === 'Shareable');

	useEffect(() => {
		if (study.id && isStudyShareSuccess) {
			axios.get(`${__config.data_sources.blume}Study?id=${study.id}`).then(result => {
				setStudyShareSuccess(false);
				setUsers(result.data.sharedInformation ?? []);
			});
		}
	}, [isStudyShareSuccess]);

	const getShareContactList = () => <ShareContactList isShared={studyAdditionalInfo.isShared} />;

	return (
		<Box sx={sx}>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: '5px',
				}}
			>
				<MobileTitle title="Shared With" />
				<Box
					sx={{
						marginBottom: '30px',
						width: '19px',
						height: '19px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						fontWeight: '500',
						fontSize: '16px',
						lineHeight: '19px',
						color: 'rgba(18, 18, 18, 0.8)',
						radius: '5px',
						backgroundColor: 'rgba(243, 243, 243, 1)',
					}}
				>
					{users.length}
				</Box>
			</Box>
			<Box
				sx={{
					display: 'flex',
					[mediaMobile]: {
						display: 'block',
					},
				}}
			>
				{showAddButton && (
					<AddNewButton
						fullWidth
						sx={{
							color: '#BFBFBF',
							border: '1px dashed #BFBFBF',
							marginBottom: '6px',
							marginRight: '5px',
							borderRadius: '10px',
							width: '98px',
							height: '98px',

							[mediaMobile]: {
								width: '100%',
								height: '54px',
							},
						}}
						onClick={() => {
							if (study) initStudyDrawer({ study });
						}}
					/>
				)}
				{isMobile ? (
					<MobileUsersList users={users} onClick={() => setIsOpenSharedDrawer(true)} />
				) : (
					<SharedUsers
						getShareContactList={getShareContactList}
						isOpenSharedDrawer={isOpenSharedDrawer}
						setIsOpenSharedDrawer={setIsOpenSharedDrawer}
						users={users}
					/>
				)}
			</Box>

			{isMobile && (
				<MobileBottomDrawer
					background="#ffffff"
					open={isOpenSharedDrawer}
					textAlign="left"
					onClose={() => setIsOpenSharedDrawer(false)}
				>
					{getShareContactList()}
				</MobileBottomDrawer>
			)}
		</Box>
	);
};

export default MobileSharedWithSection;
