import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { Collapse, IconButton, Input, Stack, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
	ContactAvatar,
	ContactGroupWrapper,
	ContactListItem,
	ContactText,
	DottedWrapper,
	ExistingContactsWrapper,
	NewContactFormWrapper,
} from '../../../casePopOverStyles';
import { crmSearchScopes, useCRMDataLoader } from '@worklist-2/core/src';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import ContactImg from '@worklist-2/ui/src/assets/img/ContactAvatar.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';

const NewContacts = ({ onChangeData, data, handleSubmit, editMode }) => {
	const [method, setMethod] = useState(null);
	const tab = 'contacts';
	return (
		<>
			{editMode && (
				<DottedWrapper>
					<div
						className={`card ${method === 'new' && 'active'}`}
						onClick={() => {
							setMethod('new');
						}}
					>
						<PersonAddAltOutlinedIcon />
						<span>Add New</span>
					</div>
					<div
						className={`card ${method === 'existing' && 'active'}`}
						onClick={() => {
							setMethod('existing');
						}}
					>
						<CollectionsBookmarkOutlinedIcon />
						<span>Add From Existing</span>
					</div>
				</DottedWrapper>
			)}
			{editMode && method === 'new' ? (
				<NewContactForm handleSubmit={handleSubmit} toggleNewContact={setMethod} onChangeData={onChangeData} />
			) : (
				editMode &&
				method === 'existing' && (
					<ExistingContacts
						handleSubmit={handleSubmit}
						onSelect={(e, v) => {
							onChangeData(e, v, tab);
							setMethod('');
						}}
					/>
				)
			)}
			<SelectedContact contacts={data} handleSubmit={handleSubmit} onChangeData={onChangeData} />
		</>
	);
};

export default NewContacts;

const SelectedContact = ({ contacts, onChangeData, handleSubmit, editMode }) => {
	const [editableIndex, setEditableIndex] = useState(null);
	const [showUpload, toggleUpload] = useState(false);
	const [newContact, setNewContact] = useState({});
	const OnChangeNewContact = (e, i) => {
		const temp = contacts[i];
		temp[e.target.name] = e.target.value;
		temp.Index = i;
		setNewContact(temp);
	};
	const tab = 'contacts';
	return _.map(contacts, (contact, i) => {
		contact.Index = i;
		return editableIndex === i ? (
			<NewContactFormWrapper>
				{showUpload ? (
					<div className="upload action-btn" onMouseLeave={() => toggleUpload(false)}>
						<FileUploadIcon />
						<span>Upload</span>
					</div>
				) : (
					<div className="avatar action-btn" onMouseEnter={() => toggleUpload(true)}>
						<PersonIcon />
					</div>
				)}
				<div className="row">
					<div className="col">
						<Input
							className="large"
							defaultValue={contact.name}
							name="name"
							placeholder="Full Name"
							onChange={e => OnChangeNewContact(e, i)}
						/>
						<Input
							defaultValue={contact.jobTitle}
							name="jobTitle"
							placeholder="Job Title"
							onChange={e => OnChangeNewContact(e, i)}
						/>
					</div>
					<Stack width="150px">
						<Stack direction="row">
							<PhoneIcon
								sx={{
									color: '#4D79EA',
									fontSize: '18px',
									marginRight: '12px',
									paddingTop: '6px',
								}}
							/>
							<Input
								defaultValue={contact.phone}
								name="phone"
								placeholder="Phone Number"
								onChange={e => OnChangeNewContact(e, i)}
							/>
						</Stack>
						<Stack direction="row">
							<EmailIcon
								sx={{
									color: '#4D79EA',
									fontSize: '18px',
									marginRight: '12px',
									paddingTop: '6px',
								}}
							/>
							<Input
								defaultValue={contact.email}
								name="email"
								placeholder="Email Address"
								onChange={e => OnChangeNewContact(e, i)}
							/>
						</Stack>
					</Stack>
				</div>
				<Stack direction="row" gap="1px" position="absolute" right={0} top="-4px">
					<IconButton
						data-testid={`submitNewContact_${i}`}
						id={`submitNewContact_${i}`}
						sx={{
							paddingRight: 0,
							'&.MuiIconButton-root:hover': {
								backgroundColor: 'transparent',
							},
						}}
						value="contactSubmit"
						onClick={e => {
							onChangeData(e, newContact, tab);
							setEditableIndex(null);
							handleSubmit(e);
						}}
					>
						<CheckCircleIcon
							id={`submitNewContact_${i}`}
							sx={{
								color: '#4d79ea',
								fontSize: '20px',
								marginLeft: 'auto',
							}}
						/>
					</IconButton>
					<IconButton
						data-testid={`closeNewContact_${i}`}
						id={`closeNewContact_${i}`}
						sx={{
							paddingLeft: 0,
							'&.MuiIconButton-root:hover': {
								backgroundColor: 'transparent',
							},
						}}
						value="contactSubmit"
						onClick={e => {
							newContact.Deleted = true;
							onChangeData(e, newContact, tab);
							setEditableIndex(null);
							handleSubmit(e);
						}}
					>
						<CloseIcon
							id={`closeNewContact_${i}`}
							sx={{
								color: '#0047ff',
								fontSize: '20px',
								marginLeft: 'auto',
							}}
						/>
					</IconButton>
				</Stack>
			</NewContactFormWrapper>
		) : (
			<ContactListItem key={i}>
				<ContactAvatar src={ContactImg} />
				<div className="main">
					<div style={{ flex: 1, marginRight: '10px' }}>
						<h3>{contact.name}</h3>
						<label>{contact.jobTitle}</label>
					</div>
					<Stack
						spacing="12px"
						sx={{
							width: '200px',
						}}
					>
						<ContactText style={{ marginBottom: '10px' }}>
							<LocalPhoneIcon
								sx={{
									color: '#4D79EA',
									fontSize: '18px',
									marginRight: '12px',
								}}
							/>
							<span>{contact.phone}</span>
						</ContactText>
						<ContactText>
							<EmailIcon
								sx={{
									color: '#4D79EA',
									fontSize: '18px',
									marginRight: '12px',
								}}
							/>
							<span>{contact.email}</span>
						</ContactText>
					</Stack>
					<Box sx={{ position: 'absolute', top: 0, right: 0 }}>
						<IconButton
							data-testid={`contactEditButton_${i}`}
							id={`contactEditButton_${i}`}
							onClick={() => {
								setEditableIndex(i);
								setNewContact(contact);
							}}
						>
							<EditOutlinedIcon
								id={`contactEditButton_${i}`}
								sx={{
									color: 'rgba(0, 0, 0, 0.6)',
									fontSize: '18px',
								}}
							/>
						</IconButton>
					</Box>
				</div>
			</ContactListItem>
		);
	});
};

const NewContactForm = ({ onChangeData, toggleNewContact, handleSubmit }) => {
	const [showUpload, toggleUpload] = useState(false);
	const [contact, setContact] = useState({});
	const OnChangeNewContact = e => {
		const temp = contact;
		temp[e.target.name] = e.target.value.trim();
		setContact(temp);
	};
	const tab = 'contacts';

	return (
		<NewContactFormWrapper>
			{showUpload ? (
				<div className="upload action-btn" onMouseLeave={() => toggleUpload(false)}>
					<FileUploadIcon />
					<span>Upload</span>
				</div>
			) : (
				<div className="avatar action-btn" onMouseEnter={() => toggleUpload(true)}>
					<PersonIcon />
				</div>
			)}
			<div className="row">
				<div className="col">
					<Input className="large" name="name" placeholder="Full Name" onChange={OnChangeNewContact} />
					<Input name="jobTitle" placeholder="Job Title" onChange={OnChangeNewContact} />
				</div>
				<div className="col">
					<Stack direction="row">
						<PhoneIcon
							sx={{
								color: '#4D79EA',
								fontSize: '18px',
								marginRight: '12px',
								paddingTop: '6px',
							}}
						/>
						<Input name="phone" placeholder="Phone Number" onChange={OnChangeNewContact} />
					</Stack>
					<Stack direction="row">
						<EmailIcon
							sx={{
								color: '#4D79EA',
								fontSize: '18px',
								marginRight: '12px',
								paddingTop: '6px',
							}}
						/>
						<Input name="email" placeholder="Email Address" onChange={OnChangeNewContact} />
					</Stack>
				</div>
			</div>
			<Stack direction="row" position="absolute" right={0} spacing="1px" top="-4px">
				<IconButton
					data-testid="submitNewContact"
					id="submitNewContact"
					sx={{
						paddingRight: 0,
						'&.MuiIconButton-root:hover': {
							backgroundColor: 'transparent',
						},
					}}
					type="button"
					value="submitNewContact"
					onClick={e => {
						onChangeData(e, contact, tab);
						toggleNewContact('none');
						handleSubmit(e);
					}}
				>
					<CheckCircleIcon
						id="submitNewContact"
						sx={{
							color: '#4d79ea',
							fontSize: '18px',
							marginLeft: 'auto',
						}}
					/>
				</IconButton>
				<IconButton
					data-testid="closeNewContact"
					id="closeNewContact"
					sx={{
						paddingLeft: 0,
						'&.MuiIconButton-root:hover': {
							backgroundColor: 'transparent',
						},
					}}
					value="closeNewContact"
					onClick={e => {
						toggleNewContact('none');
					}}
				>
					<CloseIcon
						sx={{
							color: '#0047ff',
							fontSize: '20px',
							marginLeft: 'auto',
						}}
					/>
				</IconButton>
			</Stack>
		</NewContactFormWrapper>
	);
};

const ExistingContacts = ({ onSelect, handleSubmit }) => {
	const alphabets = 'abcdefghijklmnopqrstuvwxyz'.split('');
	const [collapsed, toggleCollapsed] = useState(false);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [activeGroups, setActiveGroups] = useState('');
	const [existContact, setExistContact] = useState(false);
	const [focusGroup, setFocusGroup] = useState('');
	const contactDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.contactApi,
	});

	useEffect(() => {
		fetchData().catch(console.error);
	}, []);

	const fetchData = async (loader, value) => {
		const fnName = 'load';
		setLoading(true);
		setData(null);

		try {
			await contactDataLoader[fnName](value, undefined, undefined).then(result => {
				const newData = result;
				setData(newData);
				setLoading(false);
			});
		} catch (e) {
			console.error(e);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (data) {
			setExistContact(
				_.map(data, contact => ({
					id: contact.ContactId,
					name: contact.FullName,
					jobTitle: contact.JobTitle,
					phone: contact.CellPhone,
					email: contact.Email,
				}))
			);
		}
	}, [data]);

	const handleDeleteContact = async (e, value) => {
		const fnName = 'delete';
		const props = { id: value.id };

		try {
			await contactDataLoader[fnName](props, undefined, undefined).then(() => {
				const newData = data.filter(item => item.ContactId !== value.id);
				setData(newData);
			});
		} catch (ex) {
			console.error(ex);
		}
	};

	const groupedContacts = useMemo(() => {
		const groupedContacts = _.groupBy(existContact, contact => {
			const names = contact?.name.toLowerCase().split(' ');
			return names[names.length - 1].substring(0, 1);
		});

		const sortResult = {};
		const tempActiveGroups = [];
		_.forEach(Object.keys(groupedContacts).sort(), key => {
			sortResult[key] = groupedContacts[key];
			tempActiveGroups.push(key);
		});
		setActiveGroups(tempActiveGroups);
		return sortResult;
	}, [existContact]);

	const handleSearchContact = e => {
		setFocusGroup('');
		let searchData = [...data];
		if (e.target.value) {
			searchData =
				_.filter(data, contact => _.includes(contact.FullName?.toLowerCase(), e.target.value.toLowerCase())) ||
				[];
		}

		setExistContact(
			_.map(searchData, contact => ({
				id: contact.ContactId,
				name: contact.FullName,
				jobTitle: contact.JobTitle,
				phone: contact.CellPhone,
				email: contact.Email,
			}))
		);
	};

	return !loading ? (
		<ExistingContactsWrapper>
			<div className="list-wrapper">
				<TextField
					hiddenLabel
					InputProps={{
						endAdornment: (
							<SearchIcon
								sx={{
									width: 22,
									height: 22,
									paddingRight: 0,
									color: '#4D79EA',
								}}
							/>
						),
					}}
					data-testid="searchContact"
					size="small"
					sx={{
						'& .MuiOutlinedInput-root': {
							borderRadius: 10,
						},
						'& legend': {
							display: 'none',
						},
						'& fieldset': {
							top: 0,
						},
						fontSize: '14px',
						flex: '1',
						width: '147px',
						height: '34px',
						alignSelf: 'flex-end',
					}}
					variant="outlined"
					onChange={e => handleSearchContact(e)}
				/>
				<div className="contact-list">
					{_.map(groupedContacts, (group, i) => (
						<ContactGroup
							key={group.id || i}
							collapsed={collapsed}
							contacts={group}
							focusGroup={focusGroup}
							handleSubmit={handleSubmit}
							label={group.id || i}
							toggleCollapsed={toggleCollapsed}
							onDelete={handleDeleteContact}
							onSelect={onSelect}
						/>
					))}
				</div>
			</div>
			<div className="alphabets">
				{alphabets.map(a => {
					if (activeGroups.includes(a)) {
						return (
							<span
								onClick={() => {
									setFocusGroup(a);
								}}
							>
								{a.toUpperCase()}
							</span>
						);
					}
				})}
			</div>
		</ExistingContactsWrapper>
	) : (
		<ExistingContactsWrapper>
			<div className="list-wrapper">
				<ShowSpinner />
			</div>
		</ExistingContactsWrapper>
	);
};

const ContactGroup = ({
	contacts,
	label,
	onSelect,
	onDelete,
	focusGroup,
	toggleCollapsed,
	collapsed,
	handleSubmit,
}) => (
	<ContactGroupWrapper>
		<div className="label">
			<IconButton
				ref={input => input && focusGroup === label && input.focus()}
				disableRipple
				sx={{
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: '500',
					fontSize: '16px',
					lineHeight: '19px',
					textTransform: 'uppercase',
					color: '#4D79EA',
					marginLeft: '15px',
					cursor: 'context-menu',
				}}
			>
				{label.toUpperCase()}
			</IconButton>
		</div>
		{_.map(contacts, (contact, i) => {
			const index = `${label}_${i}`;
			const initials = `${contact.name}`
				.split(' ')
				.slice(0, 2)
				.reduce((a, b) => a.substring(0, 1) + b.substring(0, 1), '');

			return (
				<ContactListItem key={index} style={{ padding: '6px 0px 6px 16px' }}>
					<div className="container">
						<div className="f-row" style={{ width: '100%' }}>
							<div
								className="f-row"
								style={{
									cursor: 'pointer',
								}}
								onClick={() => toggleCollapsed(collapsed === index ? null : index)}
							>
								<div className="initials-avatar">
									<span>{initials}</span>
								</div>
								<h3>{contact.name}</h3>
								<h5>{contact.jobTitle}</h5>
							</div>
							<div className="row">
								{collapsed === index ? (
									<Stack direction="row">
										<button
											className="add-btn"
											id="add-contact"
											type="button"
											value="submitNewContact"
											onClick={e => {
												onSelect(e, contact);
												handleSubmit(e);
											}}
										>
											ADD
										</button>
										<button
											className="add-btn"
											id="delete-contact"
											type="button"
											onClick={e => {
												onDelete(e, contact);
											}}
										>
											Delete
										</button>
									</Stack>
								) : (
									<>
										<IconButton onClick={() => toggleCollapsed(collapsed === index ? null : index)}>
											<PhoneIcon />
										</IconButton>
										<IconButton onClick={() => toggleCollapsed(collapsed === index ? null : index)}>
											<EmailIcon />
										</IconButton>
									</>
								)}
							</div>
						</div>
						<Collapse in={collapsed === index}>
							<div className="f-row bottom">
								<div className="f-row info" style={{ margin: '0px 23px 0px 52px' }}>
									<PhoneIcon />
									<span>{contact.phone}</span>
								</div>
								<div className="f-row info">
									<EmailIcon />
									<span>{contact.email}</span>
								</div>
							</div>
						</Collapse>
					</div>
				</ContactListItem>
			);
		})}
	</ContactGroupWrapper>
);

const ShowSpinner = props => (
	<Stack {...props} alignContent="center" alignItems="center" direction="row" justifyContent="center" spacing={2}>
		<CircularProgress
			sx={{
				color: '#4D79EA',
				width: '24px !important',
				height: '24px !important',
			}}
		/>
		<Typography>Loading</Typography>
	</Stack>
);
