/**
 * Get the quarter time range from a date
 * @param  {Date} date - the input Date object.
 * @return {Object}
 */
const getQuarterTimeRange = (date) => {

    // Get the current year and month from the input date
    const year = date.getFullYear();
    const month = date.getMonth();

    // Determine the start and end months of the quarter
		let quarterStartMonth, quarterEndMonth;

    if (month >= 0 && month <= 2) {
        quarterStartMonth = 0; // January (0-based)
        quarterEndMonth = 2;   // March
    } else if (month >= 3 && month <= 5) {
        quarterStartMonth = 3; // April
        quarterEndMonth = 5;   // June
    } else if (month >= 6 && month <= 8) {
        quarterStartMonth = 6; // July
        quarterEndMonth = 8;   // September
    } else {
        quarterStartMonth = 9; // October
        quarterEndMonth = 11;  // December
    }
    
    // Calculate the start and end dates of the quarter
    const quarterStartDate = new Date(year, quarterStartMonth, 1);
    const lastDayOfQuarter = new Date(year, quarterEndMonth + 1, 0); // Last day of the next month
    const quarterEndDate = new Date(year, quarterEndMonth, lastDayOfQuarter.getDate());

    return {
        start: quarterStartDate,
        end: quarterEndDate,
    };
};

export default getQuarterTimeRange;
