import { FhirDomainResource } from '../base';
import practitionerRoleSchema from './schemata/practitionerRoleSchema';

export default class PractitionerRoleResource extends FhirDomainResource {
	id;
	resourceType;
	active;
	meta;
	organization;
	practitioner;

	constructor(resourceString) {
		super(resourceString, practitionerRoleSchema);
		this.resourceType = 'PractitionerRole';
		this.populateFields();
	}
}
