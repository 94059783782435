export const stringToColor = string => {
	let hash = 0;
	let i;
	let color = '#';

	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}

	return color;
};

export const getTextWidth = (text, font) => {
	if (!text) {
		return 0;
	}

	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');
	context.font = font;
	const metrics = context.measureText(text);

	return metrics.width;
};
