// @flow

// core
import React, { type Node } from 'react';

// components
import CakeIcon from '@worklist-2/ui/src/assets/icons/cake.svg';
import { Loader } from '@rs-ui/components/Loader/Loader';

// libs
import { Box, Typography, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';

// utils
import { calculatePatientAge } from '@worklist-2/ui/src/views/PatientInformationView/utils/calculatePatientAge';
import { type IBirthDateProps } from '@worklist-2/ui/src/views/PatientInformationView/types/types';

export const BirthDate = ({ birthDate, isLoading }: IBirthDateProps): Node => {
	const { t } = useTranslation('patientInfo');

	return (
		<Tooltip title="Birth Date">
			<Box data-testid="birthdate-wrapper" sx={SX.wrapper}>
				<Loader.PatientBirthDate isLoading={isLoading}>
					<CakeIcon />
					<Typography data-testid="birthDate-text" sx={SX.text}>
						{birthDate
							? `${moment(birthDate).format('MM/DD/YYYY')} (${t('Age')} ${calculatePatientAge(
									birthDate
							  )})`
							: 'N/A'}
					</Typography>
				</Loader.PatientBirthDate>
			</Box>
		</Tooltip>
	);
};

const SX = {
	wrapper: {
		display: 'flex',
		columnGap: '12px',
		alignItems: 'center',
	},

	text: {
		fontSize: '14px',
		fontWeight: '400',
		color: '#FFFFFF99',
	},
};
