import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Box, Typography, Button } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { fhirExtensionUrls, useConfig, crmSearchScopes, useCRMDataLoader, useAuth } from '@worklist-2/core/src';
import { Collapsible } from '../../../../Cases/casePopOverStyles';
import { FormSwitch, FormSelect, FormInput, FormTextField } from './Form/index';
import testConnection from '@worklist-2/ui/src/views/OrganizationDetailView/organizationDetailSections/tools/testConnection';
import createStyles from './styles';

const validationSchema = yup.object().shape({
	active: yup.boolean(),
	stationType: yup.string().required('Station Type is required'),
	dicomStations: yup.string().required('DICOM Stations is required'),
	AETitle: yup.string().required('AE Title is required'),
	IPAddress: yup.string().required('AE Title is required'),
	port: yup.string().required('Port is required'),
	enableTLS: yup.boolean(),
	isArchiveServer: yup.boolean(),
	needIOCMNotification: yup.boolean(),
	shareStorage: yup.boolean(),
	stationDescription: yup.string(),
});

const AddEditStationTab = forwardRef(({ accountData, orgData, stationOpen, setSaveStationActive }, ref) => {
	const [checked, setChecked] = useState(false);
	const [isTesting, setIsTesting] = useState(false);
	const [toastObject, setToastObject] = useState(null);
	const __config = useConfig();
	const { loggedInUser } = useAuth();
	const taskDataLoader = useCRMDataLoader({
		endpoint: `TaskOmegaAI/${accountData?.AccountId}`,
	});
	const subscriptionDataLoader = useCRMDataLoader({
		endpoint: `SubscriptionOmegaAI/${accountData?.AccountId}`,
	});

	const classes = createStyles();

	const orgDataId = stationOpen?.orgData?.id ? stationOpen.orgData.id : orgData.id;
	const orgDataName = stationOpen?.orgData?.display ? stationOpen.orgData.display : orgData.name;

	const defaultValues = useMemo(() => {
		if (stationOpen?.station) {
			return {
				active: stationOpen.station.status === 'active',
				stationType: 'DICOM Station',
				dicomStations: stationOpen?.station.distinctIdentifier,
				AETitle: stationOpen.station.extension.find(i => i.url === fhirExtensionUrls.device.aeTitle)
					.valueString,
				IPAddress: stationOpen.station.extension.find(
					i => i.url === fhirExtensionUrls.device.dicomServerHostName
				).valueString,
				port: stationOpen.station.extension.find(i => i.url === fhirExtensionUrls.device.dicomServerPort)
					.valueString,
				enableTLS:
					stationOpen.station.extension.find(i => i.url === fhirExtensionUrls.device.tlsConnection)
						.valueString === '1',
				isArchiveServer: stationOpen.station.extension.find(
					i => i.url === fhirExtensionUrls.device.archiveServer
				).valueBoolean,
				needIOCMNotification: stationOpen.station.extension.find(
					i => i.url === fhirExtensionUrls.device.needIOCMNotification
				).valueBoolean,
				shareStorage: stationOpen.station.extension.find(i => i.url === fhirExtensionUrls.device.shareStorage)
					?.valueBoolean,
				stationDescription: stationOpen.station.extension.find(
					i => i.url === fhirExtensionUrls.device.description
				)?.valueString,
			};
		}
		return {
			active: true,
			stationType: 'DICOM Station',
			enableTLS: true,
			isArchiveServer: true,
			needIOCMNotification: true,
			shareStorage: true,
		};
	}, [stationOpen.station]);

	const { control, watch, getValues } = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues,
	});

	const watchDicomStations = watch('dicomStations');
	const watchAETitle = watch('AETitle');
	const watchIPAddress = watch('IPAddress');
	const watchPort = watch('port');

	useEffect(() => {
		if (watchDicomStations && watchAETitle && watchIPAddress && watchPort) {
			setSaveStationActive(true);
		} else {
			setSaveStationActive(false);
		}
	}, [watchDicomStations, watchAETitle, watchIPAddress, watchPort]);

	useImperativeHandle(ref, () => ({
		async onSubmit() {
			const valuesData = getValues();

			const payload = {
				resourceType: 'Device',
				distinctIdentifier: valuesData.dicomStations, // Station name
				type: {
					coding: [
						{
							code: 'dicom-entity', // Station type
						},
					],
				},

				status: valuesData.active ? 'active' : 'inActive', // Station status

				extension: [
					{
						url: fhirExtensionUrls.device.aeTitle, // AETitle
						valueString: valuesData.AETitle,
					},
					{
						url: fhirExtensionUrls.device.dicomServerHostName, // IP Address
						valueString: valuesData.IPAddress,
					},
					{
						url: fhirExtensionUrls.device.dicomServerPort, // Port
						valueString: valuesData.port,
					},
					{
						url: fhirExtensionUrls.device.managingOrganization, // Managing Organization
						valueReference: {
							id: orgDataId,
							reference: `Organization/${orgDataId}`,
							display: orgDataName,
						},
					},
					{
						url: fhirExtensionUrls.device.associatedDevice, // Associated Device
						valueReference: {
							id: stationOpen.associatedDevice.id,
							reference: `Device/${stationOpen.associatedDevice.id}`,
							display: stationOpen.associatedDevice.distinctIdentifier,
						},
					},
					{
						url: fhirExtensionUrls.device.tlsConnection, // Enable TLS
						valueString: valuesData.enableTLS ? '1' : '0',
					},
					{
						url: fhirExtensionUrls.device.archiveServer, // isArchiveServer
						valueBoolean: valuesData.isArchiveServer,
					},
					{
						url: fhirExtensionUrls.device.needIOCMNotification, // needIOCMNotification
						valueBoolean: valuesData.needIOCMNotification,
					},
					{
						url: fhirExtensionUrls.device.shareStorage, // shareStorage
						valueBoolean: valuesData.shareStorage,
					},
					{
						url: fhirExtensionUrls.device.description, // Description
						valueString: valuesData.stationDescription,
					},
					{
						url: fhirExtensionUrls.device.characterSet, // characterSet
						valueString: valuesData.characterSet,
					},
					{
						url: 'isActive',
						valueBoolean: true, // Active
					},
				],
			};

			try {
				if (stationOpen?.mode === 'edit') {
					payload.id = stationOpen.station.id;
					const finalExtension = [...stationOpen.station.extension];

					finalExtension.forEach((item, index) => {
						const currentElem = payload.extension.find(elem => elem.url === item.url);

						if (currentElem) {
							finalExtension[index] = currentElem;
						}
					});

					const finalPayload = {
						...stationOpen.station,
						...payload,
						extension: finalExtension,
					};

					await axios.put(
						`${__config.data_sources.breeze}/DeviceOmegaAI/${accountData?.AccountId}/${stationOpen.station.id}`,
						finalPayload
					);
				} else {
					await axios.post(
						`${__config.data_sources.breeze}/DeviceOmegaAI/${accountData?.AccountId}`,
						payload
					);
				}
			} catch (e) {
				console.error(e);
			}
		},
	}));

	const handleChange = () => {
		setChecked(prev => !prev);
	};

	const stationTypeData = [{ id: 1, option: 'DICOM Station' }];

	const successtoastObject = useMemo(
		() => ({
			text: 'Connection Succeeded.',
			severity: 'success',
		}),
		[]
	);

	const failuretoastObject = useMemo(
		() => ({
			text: 'Connection Failed.',
			severity: 'error',
		}),
		[]
	);

	const handleEchoResponse = useCallback(data => {
		if (data?.reasonCode?.text === 'DicomEcho') {
			if (data.status === 'completed') {
				setToastObject(successtoastObject);
			} else {
				setToastObject(failuretoastObject);
			}
		} else {
			setToastObject(failuretoastObject);
		}

		setIsTesting(false);
	}, []);

	return (
		<Collapsible>
			<Box className="handle" onClick={handleChange}>
				<Typography>Station Information</Typography>
				{checked ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
			</Box>
			{!checked ? (
				<Box data-testid="add-station-form">
					<Box component="form" style={classes.main}>
						<FormSwitch control={control} label="Active" name="active" />
						<FormSelect
							isRequired
							isSingle
							control={control}
							data={stationTypeData}
							label="Station Type"
							name="stationType"
							selectDisabled={false}
						/>

						<Box sx={classes.separator} />

						<Box sx={classes.statusBox}>
							<Box sx={classes.statusLabelBox}>
								<svg
									fill="none"
									height="5"
									viewBox="0 0 5 5"
									width="5"
									xmlns="http://www.w3.org/2000/svg"
								>
									<circle cx="2.5" cy="2.5" fill="black" fillOpacity="0.6" r="2.5" />
								</svg>
								<Typography sx={classes.statusLabel}>Unknown</Typography>
							</Box>
							<Button
								sx={classes.testConnectionButton}
								onClick={async () => {
									setIsTesting(true);
									setToastObject(null);
									try {
										await testConnection({
											device: stationOpen,
											config: __config,
											subscriptionDataLoader,
											taskDataLoader,
											responseDataHandler: handleEchoResponse,
											signalRUrl: `${__config.data_sources.breeze}/User/SignalRConnect?hub=task&user=${loggedInUser?.id}`,
											customSubscriptionId: `${loggedInUser?.id}`,
										});
									} catch (e) {
										setIsTesting(false);
										setToastObject(failuretoastObject);
									}
								}}
							>
								<Typography sx={classes.testConnection}>
									{isTesting ? 'TESTING...' : 'TEST CONNECTION'}
								</Typography>
							</Button>
							<Typography
								style={{
									color: toastObject?.severity == 'success' ? '#96ea31' : '#ff0000',
									display: toastObject ? 'block' : 'none',
								}}
								sx={classes.testConnectionSucceed}
							>
								{toastObject?.text}
							</Typography>
						</Box>

						<Box sx={classes.row}>
							<FormInput
								isRequired
								control={control}
								disabledInput={false}
								label="DICOM Stations"
								name="dicomStations"
							/>
							<FormInput
								isRequired
								control={control}
								disabledInput={false}
								label="AE Title"
								name="AETitle"
							/>
						</Box>
						<Box sx={classes.row}>
							<FormInput
								isRequired
								control={control}
								disabledInput={false}
								label="IP Address / Hostnames"
								name="IPAddress"
							/>
							<FormInput isRequired control={control} disabledInput={false} label="Port" name="port" />
						</Box>
						<Box sx={classes.additionalSwitches}>
							<Box sx={classes.additionalSwitchesRow}>
								<FormSwitch control={control} label="Enable TLS" name="enableTLS" />
								<FormSwitch control={control} label="Is Archive Server" name="isArchiveServer" />
							</Box>
							<Box sx={classes.additionalSwitchesRow}>
								<FormSwitch
									control={control}
									label="Need IOCM Notification"
									name="needIOCMNotification"
								/>
								<FormSwitch control={control} label="Share Storage" name="shareStorage" />
							</Box>
						</Box>
						<FormTextField control={control} label="DICOM Station Description" name="stationDescription" />
					</Box>
				</Box>
			) : (
				''
			)}
		</Collapsible>
	);
});

export default AddEditStationTab;
