import { isEmpty } from 'lodash';
/**
 * Compare the Date value on the grid to sorting
 * @param  {String} order - Sort ASC or DESC.
 * @return {boolean}
 */

const sortCompareDate = order => {
	return (a, b) => {
		const aData = isEmpty(a.data) || isEmpty(a.data.props) ? '' : new Date(a.data.props.text);
		const bData = isEmpty(b.data) || isEmpty(b.data.props) ? '' : new Date(b.data.props.text);
		return (aData.getTime() - bData.getTime()) * (order === 'asc' ? 1 : -1);
	};
};

const sortCompareNumber = order => {
	return (obj1, obj2) => {
		let val1 = Number(obj1.data?.props.text || 0);
		let val2 = Number(obj2.data?.props.text || 0);
		return (val1 - val2) * (order === 'asc' ? 1 : -1);
	};
};

export { sortCompareNumber, sortCompareDate };
