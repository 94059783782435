// @flow

// core
import React, { type Node } from 'react';
// components
import { type ISkeletonProps, LoaderSkeleton } from './LoaderSkeleton';
import { Box } from '@mui/material';

export const PatientPanelSection = ({ children, isLoading }: ISkeletonProps): Node =>
	isLoading ? (
		<Box data-testid="patient-panel-section-loader">
			<LoaderSkeleton sx={{ ...SX, borderTop: '2px solid #42F59F' }} />
			<LoaderSkeleton sx={{ ...SX, borderTop: '2px solid #EB459E' }} />
		</Box>
	) : (
		children
	);

const SX = {
	borderTopRightRadius: '10px',
	borderTopLeftRadius: '10px',
	width: '100%',
	height: '38px',
	marginBottom: '-4px',
	borderBottomLeftRadius: '0px',
	borderBottomRightRadius: '0px',
};
