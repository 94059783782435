import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export default function TimeRangePicker({ list, index, setListOfNotifications, defaultStartTime, defaultEndTime}) {
  const currentDate = new Date().toISOString().split('T')[0];
  function convertTo24Hour(time12) {
    // Parse the 12-hour time using Day.js
    const parsed = dayjs(`${currentDate} ${time12}`, 'YYYY-MM-DD hh:mm A');
    // Format to 24-hour
    return parsed;
}
  const [from, setFrom] = React.useState(convertTo24Hour(defaultStartTime));
  const [to, setTo] = React.useState(convertTo24Hour(defaultEndTime));
  React.useEffect(()=>{
    const newList = list;
    newList[index].StartTime = dayjs(from).format('hh:mm:ss A');
    newList[index].EndTime = dayjs(to).format('hh:mm:ss A');
    setListOfNotifications(newList);
  },[from,to]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{flexDirection:'row', display:'flex', width:'100%', gap:'10px'}}>
        <TimePicker
          label="Start Time"
          renderInput={(params) => <TextField sx={{width:'100%', margin:'0px'}} {...params} />}
          value={from}
          onChange={setFrom}
        />
        <TimePicker
          label="End Time"
          renderInput={(params) => <TextField sx={{width:'100%', margin:'0px'}} {...params} />}
          value={to}
          onChange={setTo}
        />
      </Box>
    </LocalizationProvider>
  );
}