import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '@worklist-2/core/src';

import { Layout, Header, AttachmentBar, BlockBar, ChatBlock, AddSubsections } from '../Layout';
import { CategoryBar, MainArea } from '../common';
import ReviewDrawer from '../ReviewDrawer';
import HelpCenterContextProvider from './HelpCenterContext';

import styles from './HelpCenter.module.scss';

const HelpCenter = ({ theme }) => {
	const [initDraft, setInitDraft] = useState(null);
	const [currentDocId, setCurrentDocId] = useState(null);
	const [activeCategory, setActiveCategory] = useState({ category: {}, subCategory: {} });
	const [unreadCommentsCount, setUnreadCommentsCount] = useState(0);
	const [activeButton, setActiveButton] = useState('');
	const [currentTextBlock, setCurrentTextBlock] = useState({});
	const [attachType, setAttachType] = useState(null);
	const [selectedAttachment, setSelectedAttachment] = useState(null);
	const [isReviewOpen, setIsReviewOpen] = useState(false);
	const [isSectionsOpen, setIsSectionsOpen] = useState(false);
	const [comments, setComments] = useState([]);

	const { loggedInUser } = useAuth();

	const location = useLocation();

	const getSidebar = () => {
		if (!activeButton) {
			return null;
		}
		if (activeButton === 'text') {
			return (
				<BlockBar currentTextBlock={currentTextBlock} setCurrentTextBlock={setCurrentTextBlock} theme={theme} />
			);
		}
		if (activeButton === 'attach') {
			return (
				<AttachmentBar
					activeCategory={activeCategory}
					attachType={attachType}
					setAttachType={setAttachType}
					setSelectedAttachment={setSelectedAttachment}
					theme={theme}
				/>
			);
		}
	};

	useEffect(() => {
		if (!location?.state?.fileData?.Path) {
			return;
		}

		setActiveCategory({
			category: {
				DocumentCategoryId: location.state.fileData.Path.split('|')[1],
			},
			subCategory:
				location.state.fileData.Path.split('|').length > 2
					? { SubCategoryId: location.state.fileData.Path.split('|')[2] }
					: {},
		});
		setInitDraft({ id: location.state.fileData.DocumentDraftId, name: location.state.fileData.DocumentName });
	}, []);

	return (
		<HelpCenterContextProvider>
			<Layout theme={theme}>
				{loggedInUser ? (
					<>
						<Header
							currentDocId={currentDocId}
							isReviewOpen={isReviewOpen}
							isSectionsOpen={isSectionsOpen}
							setActiveButton={setActiveButton}
							setIsSectionsOpen={setIsSectionsOpen}
							theme={theme}
							unreadCommentsCount={unreadCommentsCount}
							onOpenReview={() => setIsReviewOpen(true)}
						/>
						<div className={styles.helpCenter}>
							{isSectionsOpen ? (
								<AddSubsections setIsSectionsOpen={setIsSectionsOpen} theme={theme} />
							) : (
								''
							)}
							{getSidebar()}
							<div
								className={`${styles.right} ${
									activeButton && activeButton !== 'insertPage' ? styles.activeButton : ''
								} `}
							>
								{theme === 'OAI' ? (
									<h1 className={styles.rightTitle}>Help Center</h1>
								) : (
									<h1 className={styles.titleBlume}>Choose Help category</h1>
								)}
								<div>
									<div className={styles.main}>
										<div>
											<CategoryBar
												activeButton={activeButton}
												activeCategory={activeCategory}
												isSectionsOpen={isSectionsOpen}
												setActiveButton={setActiveButton}
												setActiveCategory={setActiveCategory}
												setIsSectionsOpen={setIsSectionsOpen}
												theme={theme}
											/>
											{theme === 'OAI' ? <ChatBlock /> : ' '}
										</div>
										<MainArea
											activeButton={activeButton}
											activeCategory={activeCategory}
											attachType={attachType}
											currentTextBlock={currentTextBlock}
											documentType={location.state?.Type}
											initDraft={initDraft}
											selectedAttachment={selectedAttachment}
											setActiveButton={setActiveButton}
											setCurrentDocId={setCurrentDocId}
											setCurrentTextBlock={setCurrentTextBlock}
											setInitDraft={setInitDraft}
											setIsSectionsOpen={setIsSectionsOpen}
											setSelectedAttachment={setSelectedAttachment}
											theme={theme}
										/>
									</div>
								</div>
							</div>
							<ReviewDrawer
								comments={comments}
								documentId={currentDocId}
								isOmega={theme === 'OAI'}
								isOpen={isReviewOpen}
								setComments={setComments}
								setUnreadCommentsCount={setUnreadCommentsCount}
								onClose={() => setIsReviewOpen(false)}
							/>
						</div>
					</>
				) : (
					''
				)}
			</Layout>
		</HelpCenterContextProvider>
	);
};

export default HelpCenter;
