import React from 'react';

const ImportIcon = ({ fill }) => (
	<svg fill="none" height="17" viewBox="0 0 14 17" width="14" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8 2V8H9.17L7 10.17L4.83 8H6V2H8ZM10 0H4V6H0L7 13L14 6H10V0ZM14 15H0V17H14V15Z"
			fill={fill || 'white'}
			fillOpacity={fill ? 1 : 0.6}
		/>
	</svg>
);

export default ImportIcon;
