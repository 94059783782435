import React from 'react';

import styles from './HeaderBlock.module.scss';

const HeaderBlock = ({ theme, block, setDisplayMode, isSelected }) => (
	<div
		className={`${styles.headerBlock} ${isSelected ? styles.headerBlockSelected : ''}`}
		data-testid="headerBlock"
		onMouseOver={() => setDisplayMode && setDisplayMode({ type: 'hover' })}
	>
		<h1 className={theme === 'OAI' ? styles.headerOAI : styles.headerBlume} data-testid="header">
			{block.text}
		</h1>
	</div>
);

export default HeaderBlock;
