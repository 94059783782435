import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';

const ContainerWrapper = styled(Box)(({ theme }) => ({
	background: '#FFFFFF',
	boxShadow: '0px -4px 20px rgba(33, 88, 119, 0.1)',
	borderRadius: '20px 20px 0px 0px',
	marginTop: '30px',
	marginRight: '80px',
	padding: '20px 20px',
	display: 'flex',
	justifyContent: 'space-between',
	height: 'auto',
	overflowY: 'auto',
	flexDirection: 'column',

	[theme.breakpoints.up('md')]: {
		flexDirection: 'row',
	},
}));

function DevelopmentContainer({ children }) {
	return <ContainerWrapper>{children}</ContainerWrapper>;
}

export default DevelopmentContainer;
