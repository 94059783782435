import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Collapse, TextField, Select, MenuItem, FormHelperText, IconButton } from '@mui/material';
import _ from 'lodash';
import React, { useMemo, useState, useEffect } from 'react';
import { Row, Col, Collapsible, TabPanel } from '../../../Cases/casePopOverStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MapField from './MapField';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import Suggest from '@worklist-2/ui/src/components/Suggest/Suggest';
import { useForm } from 'react-hook-form';
import FormFieldVariants from '@worklist-2/ui/src/components/FormNew/formPartials/FormFieldVariants/FormFieldVariants';
import { accountForm, crmSearchScopes, useCRMDataLoader, useAuth } from '@worklist-2/core/src';
import {
	AccountRecordTypeOptions,
	IndustryOptions,
	HostingModelOptions,
	IntegrationValidationRequiredOptions,
	AccountStateOptions,
	WhereToOnboardCustomers,
} from '../../../Cases/utils';
import timezones from '@worklist-2/ui/src/views/OrganizationDetailView/organizationDetail/timezones';

const Overview = ({
	expanded,
	editable,
	data,
	onChangeData,
	editableIndex,
	setEditableIndex,
	errorMsgs,
	hoveredField,
	setHoveredField,
}) => {
	const [collapsedId, setCollapsed] = useState(null);
	const [editMode] = useState(editable);
	const [enableShippingAddressSearch, setEnableShippingAddressSearch] = useState(false);
	const [enableBillingAddressSearch, setEnableBillingAddressSearch] = useState(false);
	const { loggedInUser } = useAuth();
	const numericRegex = /\d/;
	const tab = 'overview';

	const accountDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.accountApi,
	});

	const userDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.userApi,
	});

	const onCollapsed = id => {
		setCollapsed(collapsedId === id ? null : id);
	};

	const { numericFields } = accountForm;
	const { requiredFields } = accountForm;
	const { textFields } = accountForm;
	const { selectFields } = accountForm;
	const { searchableFields } = accountForm;
	const enableSubmitIconFields = [...selectFields, ...searchableFields];
	const enableCancelIconFields = [...selectFields, ...searchableFields, ...textFields];
	const listAvoid = [data?.AccountName];
	const billingForm = useForm({
		shouldFocusError: true,
		mode: 'all',
	});
	const shippingForm = useForm({
		shouldFocusError: true,
		mode: 'all',
	});
	useEffect(() => {
		const subscription = billingForm.watch(value => {
			onChangeData({ target: { name: 'billing_address_address_line_1', value: value.address } });
			onChangeData({ target: { name: 'billing_address_address_line_2', value: value.addressLine2 } });
			onChangeData({ target: { name: 'billing_address_country', value: value.country } });
			onChangeData({ target: { name: 'billing_address_province', value: value.province } });
			onChangeData({ target: { name: 'billing_address_city', value: value.city } });
			onChangeData({ target: { name: 'billing_address_postal_code', value: value.postalCode } });
		});
		return () => subscription.unsubscribe();
	}, [billingForm.watch]);

	useEffect(() => {
		const subscription = shippingForm.watch(value => {
			onChangeData({ target: { name: 'shipping_address_address_line_1', value: value.address } });
			onChangeData({ target: { name: 'shipping_address_address_line_2', value: value.addressLine2 } });
			onChangeData({ target: { name: 'shipping_address_country', value: value.country } });
			onChangeData({ target: { name: 'shipping_address_province', value: value.province } });
			onChangeData({ target: { name: 'shipping_address_city', value: value.city } });
			onChangeData({ target: { name: 'shipping_address_postal_code', value: value.postalCode } });
		});
		return () => subscription.unsubscribe();
	}, [shippingForm.watch]);

	const setFormValue = (form, type) => {
		if (type === 'billing') {
			form.setValue('address', data?.BillingAddressLine1);
			form.setValue('addressLine2', data?.BillingAddressLine2);
			form.setValue('city', data?.BillingCity);
			form.setValue('country', data?.BillingCountry);
			form.setValue('postalCode', data?.BillingPostalCode);
			form.setValue('province', data?.BillingState);
		} else {
			form.setValue('address', data?.ShippingAddressLine1);
			form.setValue('addressLine2', data?.ShippingAddressLine2);
			form.setValue('city', data?.ShippingCity);
			form.setValue('country', data?.ShippingCountry);
			form.setValue('postalCode', data?.ShippingPostalCode);
			form.setValue('province', data?.ShippingState);
		}
	};

	useEffect(() => {
		setFormValue(billingForm, 'billing');
		setFormValue(shippingForm, 'shipping');
	}, []);

	const detailsData = useMemo(
		() =>
			expanded
				? {
						general: {
							id: 'general',
							label: 'General',
							contents: {
								'Account State': data?.AccountState,
								'Account Record Type': data?.AccountRecordType,
								'Account Owner': data?.AccountOwner,
								'Local Time': data?.LocalTime,
								'Account Status': data?.AccountStatus,
							},
						},
				  }
				: {
						general: {
							id: 'general',
							label: 'General',
							contents: {
								'Account State': data?.AccountState,
								'Account Record Type': data?.AccountRecordType,
								'Account Owner': data?.AccountOwner,
								'Local Time': data?.LocalTime,
								'Account Status': data?.AccountStatus,
								'Important Account Info': data?.ImportantAccountInfo,
								'Downtime (Hrs)': data?.DownTime,
								Fax: data?.Fax,
								'Parent Account': data?.ParentAccount,
								'Customer Approved Remote (Hrs)': data?.CustomerApprovedRemote,
								'Legacy Client ID': data?.LegacyClientId,
								'Assigned Entity': data?.AssignedEntity,
								'Total Downtime (Hrs)': data?.TotalDownTime,
								'Project Training Remaining (Hrs)': data?.ProjectTrainingRemaining,
								'Contract Billable (Hrs)': data?.ContractBillale,
								'Days Overdue (Hrs)': data?.DaysOverdue,
								'Previous Name': data?.PreviousName,
								'Client URL': data?.ClientUrl,
								'Workspace URL': data?.WorkspaceUrl,
								'Where to onboard customers': data?.WhereToOnboardCustomers,
							},
						},
						addressInformation: {
							id: 'addInfo',
							label: 'Address Information',
							contents: {
								billingAddress: {
									'Billing Address Address Line 1': data?.BillingAddressLine1,
									'Billing Address Address Line 2': data?.BillingAddressLine2,
									'Billing Address City': data?.BillingCity,
									'Billing Address Province': data?.BillingState,
									'Billing Address Country': data?.BillingCountry,
									'Billing Address Postal Code': data?.BillingPostalCode,
								},
								shippingAddress: {
									'Shipping Address Address Line 1': data?.ShippingAddressLine1,
									'Shipping Address Address Line 2': data?.ShippingAddressLine2,
									'Shipping Address City': data?.ShippingCity,
									'Shipping Address Province': data?.ShippingState,
									'Shipping Address Country': data?.ShippingCountry,
									'Shipping Address Postal Code': data?.ShippingPostalCode,
								},
								'Account Currency': data?.AccountCurrency,
							},
						},
						pricingInformation: {
							id: 'priceInfo',
							label: 'Pricing Information',
							contents: {
								'Billable Hours custom pricing': data?.BillableHoursPricing,
								'VPN Monthly Custom Pricing': data?.VpnMonthlyPricing,
								'Training Hours custom pricing': data?.TrainingHoursPricing,
								'VPN Setup Custom Pricing': data?.VpnSetupPricing,
							},
						},
						companyOverview: {
							id: 'compOver',
							label: 'Company Overview',
							contents: {
								Industry: data?.Industry,
								'SIC Description': data?.SicDescription,
								'SIC Code': data?.SicCode,
								Employees: data?.Employees,
								'Annual Revenue': data?.AnnualRevenue,
								Ownership: data?.Ownership,
							},
						},
						additionalInformation: {
							id: 'additionalInfo',
							label: 'Additional Information',
							contents: {
								'# of Radiologists': data?.NumberOfRadiologist,
								'# of Gateways': data?.NumberOfGateway,
								'# of Studies/yr': data?.NumberOfStudyPerYear,
								'# of PowerCaches': data?.NumberOfPowerCache,
								'# of Facilities': data?.NumberOfFacility,
								'# of Beds': data?.NumberOfBed,
								'Other Location': data?.OtherLocation,
								Description: data?.Description,
								'Hosting Model': data?.HostingModel,
								'integration Validation Required': data?.IntegrationValidationRequired,
							},
						},
				  },
		[data, editableIndex, errorMsgs]
	);

	const genFieldName = key => {
		const noSpecialCharacters = key?.replace(/[^a-zA-Z0-9 ]/g, '');
		return noSpecialCharacters.replaceAll(' ', '_').toLowerCase();
	};

	const handleAccountSelect = (value, searchValue, optionId, record) => {
		const eventName = {};
		const eventId = {};

		if (record) {
			eventName.target = {
				name: 'parent_account',
				value: record.AccountName,
			};

			eventId.target = {
				name: 'parent_account_id',
				value: record.AccountId || record.id,
			};
		} else {
			eventName.target = {
				name: 'parent_account',
				value: '',
			};

			eventId.target = {
				name: 'parent_account_id',
				value: '',
			};
		}

		onChangeData(eventName, '', tab);
		onChangeData(eventId, '', tab);
	};

	const handleUserSelect = (value, searchValue, optionId, record) => {
		const eventName = {};
		const eventId = {};

		if (record) {
			eventName.target = {
				name: 'ownership',
				value: record.UserName,
			};

			eventId.target = {
				name: 'ownership_id',
				value: record.UserId || record.id,
			};
		} else {
			eventName.target = {
				name: 'ownership',
				value: '',
			};

			eventId.target = {
				name: 'ownership_id',
				value: '',
			};
		}

		onChangeData(eventName, '', tab);
		onChangeData(eventId, '', tab);
	};

	const getHandleSelect = fieldName => {
		if (fieldName === 'parent_account') {
			return handleAccountSelect;
		}
		if (fieldName === 'ownership') {
			return handleUserSelect;
		}
	};

	const getDataLoaderSelect = fieldName => {
		if (fieldName === 'parent_account') {
			return accountDataLoader;
		}
		if (fieldName === 'ownership') {
			return userDataLoader;
		}
	};

	const getPermissionSelect = fieldName => {
		if (fieldName === 'parent_account') {
			return !loggedInUser.permission?.Account.Read;
		}
		if (fieldName === 'ownership') {
			return !loggedInUser.permission?.User.Read;
		}
	};

	return (
		<TabPanel className="invisible-scrollbar" expanded={expanded}>
			<div className="header">
				<div className="left">
					<div className="colored-icon error-icon">
						<BadgeOutlinedIcon sx={{ fontSize: 20 }} />
					</div>
					<h2>Details</h2>
				</div>
			</div>
			<div className="body">
				<Collapsible>
					{_.map(detailsData, (category, i) => {
						const rows = _.chunk(
							_.map(category.contents, (value, key) => ({ key, value })),
							expanded ? 3 : 2
						);

						const billingAddressArray = expanded
							? []
							: _.map(detailsData.addressInformation.contents.billingAddress, (value, key) => ({
									key,
									value,
							  }));
						const shippingAddressArray = expanded
							? []
							: _.map(detailsData.addressInformation.contents.shippingAddress, (value, key) => ({
									key,
									value,
							  }));
						const billingAddressRows = _.chunk(billingAddressArray, expanded ? 3 : 2);
						const shippingAddressRows = _.chunk(shippingAddressArray, expanded ? 3 : 2);

						const billingAddressValue = billingAddressArray
							.map(x => x.value)
							.filter(Boolean)
							.join(', ');
						const shippingAddressValue = shippingAddressArray
							.map(x => x.value)
							.filter(Boolean)
							.join(', ');
						const accountCurrencyValue = expanded
							? ''
							: detailsData.addressInformation.contents['Account Currency'];
						const billingAddressField = genFieldName('Billing Address');
						const shippingAddressField = genFieldName('Shipping Address');
						const accountCurrencyField = genFieldName('Account Currency');
						return (
							<div key={i}>
								<CollapseHandle
									collapsedId={collapsedId}
									id={category.id}
									label={category.label}
									onCollapsed={onCollapsed}
								/>
								<Collapse in={collapsedId !== category.id}>
									<div className="content">
										{category.id == 'addInfo' ? (
											<div>
												{(editMode || editableIndex === billingAddressField) && (
													<fieldset
														style={{
															border: 'none',
															fontFamily: 'Inter',
															fontStyle: 'normal',
															fontWeight: '500',
															fontSize: '12px',
															color: 'rgba(0,0,0,0.6)',
															padding: '0 0 0 0',
														}}
													>
														<legend>Billing Address</legend>
														{billingAddressRows.map((billingArr, e) => (
															<Row
																key={e}
																bottomBorder={expanded && e < rows.length - 1}
																className="row"
															>
																{billingArr.map((billingEntry, f) => {
																	const billingFieldName = genFieldName(
																		billingEntry.key
																	);
																	return (
																		<Col
																			key={`${billingEntry.key}-${f}`}
																			className={
																				!expanded && f % 2 == 0 && !editMode
																			}
																			style={{
																				width: '100%',
																				marginBottom: 0,
																				marginRight: 0,
																			}}
																		>
																			<label className="detail">
																				{billingEntry.key.replace(
																					'Billing Address',
																					''
																				)}
																				{requiredFields.includes(
																					billingFieldName
																				) && (
																					<span style={{ color: 'red' }}>
																						&nbsp;*
																					</span>
																				)}
																			</label>

																			<div
																				onClick={() => {
																					if (
																						billingEntry.key.includes(
																							'Address Line 1'
																						)
																					) {
																						setEnableBillingAddressSearch(
																							true
																						);
																						billingForm.setValue(
																							'address',
																							''
																						);
																					}
																				}}
																			>
																				<FormFieldVariants
																					field={{
																						name: `${
																							billingEntry.key.includes(
																								'Address Line 1'
																							)
																								? 'address'
																								: billingEntry.key
																										.replace(
																											'Billing Address',
																											''
																										)
																										.replaceAll(
																											' ',
																											''
																										)
																										.charAt(0)
																										.toLowerCase() +
																								  billingEntry.key
																										.replace(
																											'Billing Address',
																											''
																										)
																										.replaceAll(
																											' ',
																											''
																										)
																										.slice(1)
																						}`,
																						// type: `${
																						// 	billingEntry.key.includes(
																						// 		'Address Line 1'
																						// 	) &&
																						// 	enableBillingAddressSearch
																						// 		? 'googleAutocomplete'
																						// 		: 'text'
																						// }`,
																						type: 'text',
																						props: {
																							InputProps: {
																								autoFocus:
																									billingEntry.key.includes(
																										'Address Line 1'
																									) &&
																									enableBillingAddressSearch,
																							},
																							id: `${billingEntry.key
																								.replaceAll(' ', '_')
																								.toLowerCase()}`,
																							error: !!errorMsgs[
																								billingFieldName
																							],
																							helperText:
																								errorMsgs[
																									billingFieldName
																								],
																							sx: {
																								marginRight: '17px',
																								'& .MuiInputBase-root':
																									{
																										borderRadius:
																											'10px',
																									},
																								'& .MuiInputBase-input':
																									{
																										padding:
																											'11px 10px',
																									},
																								'& .MuiOutlinedInput-input':
																									{
																										'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
																											{
																												'-webkit-appearance':
																													'none',
																											},
																										border: 'none',
																									},
																								'& .MuiOutlinedInput-notchedOutline':
																									{
																										span: {
																											marginRight:
																												'0px !important',
																										},
																									},
																							},
																						},
																					}}
																					formHook={billingForm}
																				/>
																			</div>
																		</Col>
																	);
																})}
															</Row>
														))}
														{editableIndex === billingAddressField && (
															<div style={{ float: 'right' }}>
																<IconButton
																	data-testid={`submitButton_${billingAddressField}`}
																	id={`submitButton_${billingAddressField}`}
																	sx={{
																		paddingRight: 0,
																	}}
																	type="submit"
																	value="overviewSubmit"
																	onClick={() => {
																		setEnableBillingAddressSearch(false);
																	}}
																>
																	<CheckCircleIcon
																		sx={{
																			pointerEvents: 'none',
																			color: '#0047ff',
																			fontSize: '22px',
																			marginLeft: 'auto',
																		}}
																	/>
																</IconButton>
																<IconButton
																	data-testid={`closeEditButton_${billingAddressField}`}
																	id={`closeEditButton_${billingAddressField}`}
																	sx={{ padding: '7px 2px 5px 2px' }}
																	type="button"
																	onClick={e => {
																		onChangeData(e, editableIndex, tab);
																		setEnableBillingAddressSearch(false);
																		setFormValue(billingForm, 'billing');
																	}}
																>
																	<CloseIcon
																		id={`closeEditButton_${billingAddressField}`}
																		sx={{
																			color: '#0047ff',
																		}}
																	/>
																</IconButton>
															</div>
														)}
													</fieldset>
												)}

												<Row className="row">
													{!(editMode || editableIndex === billingAddressField) && (
														<Col
															key={`${billingAddressField}-${1}`}
															className={
																!expanded && 2 % 2 == 0 && !editMode && 'right-bordered'
															}
															style={{ width: '50%', marginBottom: 0, marginRight: 0 }}
														>
															<label className="detail">
																Billing Address
																<span style={{ color: 'red' }}>&nbsp;*</span>
															</label>
															<div className="form-control">
																<Box>
																	<div className="row">
																		<span
																			style={{
																				maxWidth: billingAddressField
																					.toLowerCase()
																					.split('_')
																					.includes('address')
																					? '128px'
																					: '80%',
																				cursor: loggedInUser.permission?.Account
																					.Edit
																					? 'pointer'
																					: '',
																			}}
																			onClick={() => {
																				if (
																					loggedInUser.permission?.Account
																						.Edit
																				) {
																					setEditableIndex(
																						billingAddressField
																					);
																				}
																			}}
																			onMouseEnter={() =>
																				setHoveredField(billingAddressField)
																			}
																		>
																			{billingAddressValue || '-'}
																		</span>
																		{hoveredField === billingAddressField &&
																			!!loggedInUser.permission?.Account.Edit && (
																				<IconButton
																					data-testid={`closeEditButton_Edit_${billingAddressField}`}
																					id={`closeEditButton_Edit_${billingAddressField}`}
																					sx={{ p: 0 }}
																					onClick={e => {
																						onChangeData(
																							e,
																							editableIndex,
																							tab
																						);
																						setEditableIndex(
																							billingAddressField
																						);
																					}}
																				>
																					<EditOutlinedIcon
																						sx={{
																							color: 'rgba(0, 0, 0, 0.6)',
																							fontSize: '18px',
																							marginLeft: 'auto',
																						}}
																					/>
																				</IconButton>
																			)}
																	</div>
																	{!editMode && billingAddressValue && (
																		<MapField
																			address={billingAddressValue}
																			expanded={expanded}
																		/>
																	)}
																</Box>
															</div>
														</Col>
													)}
													{!(editMode || editableIndex === shippingAddressField) && (
														<Col
															key={`${shippingAddressField}-${1}`}
															className={
																!expanded && 1 % 2 == 0 && !editMode && 'right-bordered'
															}
															style={{ width: '50%', marginBottom: 0, marginRight: 0 }}
														>
															<label className="detail">Shipping Address</label>
															<div className="form-control">
																<Box>
																	<div className="row">
																		<span
																			style={{
																				maxWidth: shippingAddressField
																					.toLowerCase()
																					.split('_')
																					.includes('address')
																					? '128px'
																					: '80%',
																				cursor: loggedInUser.permission?.Account
																					.Edit
																					? 'pointer'
																					: '',
																			}}
																			onClick={() => {
																				if (
																					loggedInUser.permission?.Account
																						.Edit
																				) {
																					setEditableIndex(
																						shippingAddressField
																					);
																				}
																			}}
																			onMouseEnter={() =>
																				setHoveredField(shippingAddressField)
																			}
																		>
																			{shippingAddressValue || '-'}
																		</span>
																		{hoveredField === shippingAddressField &&
																			!!loggedInUser.permission?.Account.Edit && (
																				<IconButton
																					data-testid={`closeEditButton_Edit_${shippingAddressField}`}
																					id={`closeEditButton_Edit_${shippingAddressField}`}
																					sx={{ p: 0 }}
																					onClick={e => {
																						onChangeData(
																							e,
																							editableIndex,
																							tab
																						);
																						setEditableIndex(
																							shippingAddressField
																						);
																					}}
																				>
																					<EditOutlinedIcon
																						sx={{
																							color: 'rgba(0, 0, 0, 0.6)',
																							fontSize: '18px',
																							marginLeft: 'auto',
																						}}
																					/>
																				</IconButton>
																			)}
																	</div>
																	{!editMode && shippingAddressValue && (
																		<MapField
																			address={shippingAddressValue}
																			expanded={expanded}
																		/>
																	)}
																</Box>
															</div>
														</Col>
													)}
													{expanded && (
														<Col
															key={`${accountCurrencyField}-${1}`}
															className={!expanded && 1 % 2 == 0 && !editMode}
															style={{ width: '50%', marginBottom: 0, marginRight: 0 }}
														>
															<label className="detail">Account Currency</label>
															<div className="form-control">
																{editMode || editableIndex === accountCurrencyField ? (
																	<div className="row">
																		<TextField
																			hiddenLabel
																			InputProps={{
																				endAdornment: editableIndex ===
																					accountCurrencyField && (
																					<IconButton
																						data-testid={`submitButton_${accountCurrencyField}`}
																						id={`submitButton_${accountCurrencyField}`}
																						sx={{
																							paddingRight: 0,
																						}}
																						type="submit"
																						value="overviewSubmit"
																					>
																						<CheckCircleIcon
																							sx={{
																								pointerEvents: 'none',
																								color: '#0047ff',
																								fontSize: '22px',
																								marginLeft: 'auto',
																							}}
																						/>
																					</IconButton>
																				),
																			}}
																			autoFocus={
																				editableIndex === accountCurrencyField
																			}
																			className="without-padding"
																			data-testid={accountCurrencyField}
																			defaultValue={accountCurrencyValue}
																			error={!!errorMsgs[accountCurrencyField]}
																			helperText={errorMsgs[accountCurrencyField]}
																			id={accountCurrencyField}
																			name={accountCurrencyField}
																			size="small"
																			sx={{
																				height: '60px',
																				'& legend': { display: 'none' },
																				'& fieldset': { top: 0 },
																				fontSize: '14px',
																				flex: '1',
																				maxWidth: '207px',
																				borderRadius: 10,
																			}}
																			variant="outlined"
																			onChange={e => {
																				onChangeData(e, '', tab);
																			}}
																			onKeyPress={e => {
																				if (
																					numericFields.includes(
																						accountCurrencyField
																					) &&
																					!numericRegex.test(e.key)
																				) {
																					e.preventDefault();
																				}
																			}}
																		/>
																		{editableIndex === accountCurrencyField && (
																			<IconButton
																				data-testid={`closeEditButton_${accountCurrencyField}`}
																				id={`closeEditButton_${accountCurrencyField}`}
																				sx={{ padding: '7px 2px 5px 2px' }}
																				type="button"
																			>
																				<CloseIcon
																					id={`closeEditButton_${accountCurrencyField}`}
																					sx={{
																						color: '#0047ff',
																					}}
																					onClick={e => {
																						onChangeData(
																							e,
																							accountCurrencyField,
																							tab
																						);
																					}}
																				/>
																			</IconButton>
																		)}
																	</div>
																) : (
																	<Box>
																		<div className="row">
																			<span
																				style={{
																					maxWidth: '80%',
																					cursor: loggedInUser.permission
																						?.Account.Edit
																						? 'pointer'
																						: '',
																				}}
																				onClick={() => {
																					if (
																						loggedInUser.permission?.Account
																							.Edit
																					) {
																						setEditableIndex(
																							accountCurrencyField
																						);
																					}
																				}}
																				onMouseEnter={() =>
																					setHoveredField(
																						accountCurrencyField
																					)
																				}
																			>
																				{accountCurrencyValue || '-'}
																			</span>
																			{hoveredField === accountCurrencyField &&
																				!!loggedInUser.permission?.Account
																					.Edit && (
																					<IconButton
																						data-testid={`closeEditButton_Edit_${accountCurrencyField}`}
																						id={`closeEditButton_Edit_${accountCurrencyField}`}
																						sx={{ p: 0 }}
																						onClick={e => {
																							onChangeData(
																								e,
																								editableIndex,
																								tab
																							);
																							setEditableIndex(
																								accountCurrencyField
																							);
																						}}
																					>
																						<EditOutlinedIcon
																							sx={{
																								color: 'rgba(0, 0, 0, 0.6)',
																								fontSize: '18px',
																								marginLeft: 'auto',
																							}}
																						/>
																					</IconButton>
																				)}
																		</div>
																	</Box>
																)}
															</div>
														</Col>
													)}
												</Row>

												{(editMode || editableIndex === shippingAddressField) && (
													<fieldset
														style={{
															border: 'none',
															fontFamily: 'Inter',
															fontStyle: 'normal',
															fontWeight: '500',
															fontSize: '12px',
															color: 'rgba(0,0,0,0.6)',
															padding: '0 0 0 0',
															marginTop: '10px',
														}}
													>
														<legend>Shipping Address</legend>
														{shippingAddressRows.map((shippingArr, e) => (
															<Row
																key={e}
																bottomBorder={expanded && e < rows.length - 1}
																className="row"
															>
																{shippingArr.map((shippingEntry, f) => (
																	<Col
																		key={`${shippingEntry.key}-${f}`}
																		className={!expanded && f % 2 == 0 && !editMode}
																		style={{
																			width: '100%',
																			marginBottom: 0,
																			marginRight: 0,
																		}}
																	>
																		<label className="detail">
																			{shippingEntry.key.replace(
																				'Shipping Address',
																				''
																			)}
																		</label>
																		<div
																			onClick={() => {
																				if (
																					shippingEntry.key.includes(
																						'Address Line 1'
																					)
																				) {
																					setEnableShippingAddressSearch(
																						true
																					);
																					shippingForm.setValue(
																						'address',
																						''
																					);
																				}
																			}}
																		>
																			<FormFieldVariants
																				field={{
																					name: shippingEntry.key.includes(
																						'Address Line 1'
																					)
																						? 'address'
																						: shippingEntry.key
																								.replace(
																									'Shipping Address',
																									''
																								)
																								.replaceAll(' ', '')
																								.charAt(0)
																								.toLowerCase() +
																						  shippingEntry.key
																								.replace(
																									'Shipping Address',
																									''
																								)
																								.replaceAll(' ', '')
																								.slice(1),
																					// type: `${
																					// 	shippingEntry.key.includes(
																					// 		'Address Line 1'
																					// 	) && enableShippingAddressSearch
																					// 		? 'googleAutocomplete'
																					// 		: 'text'
																					// }`,
																					type: 'text',
																					props: {
																						InputProps: {
																							autoFocus:
																								shippingEntry.key.includes(
																									'Address Line 1'
																								) &&
																								enableShippingAddressSearch,
																						},
																						id: `${shippingEntry.key
																							.replaceAll(' ', '_')
																							.toLowerCase()}`,
																						sx: {
																							marginRight: '17px',
																							'& .MuiInputBase-root': {
																								borderRadius: '10px',
																							},
																							'& .MuiInputBase-input': {
																								padding: '11px 10px',
																							},
																							'& .MuiOutlinedInput-input':
																								{
																									'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
																										{
																											'-webkit-appearance':
																												'none',
																										},
																									border: 'none',
																								},
																							'& .MuiOutlinedInput-notchedOutline':
																								{
																									span: {
																										marginRight:
																											'0px !important',
																									},
																								},
																						},
																					},
																				}}
																				formHook={shippingForm}
																			/>
																		</div>
																	</Col>
																))}
															</Row>
														))}
														{editableIndex === shippingAddressField && (
															<div style={{ float: 'right' }}>
																<IconButton
																	data-testid={`submitButton_${shippingAddressField}`}
																	id={`submitButton_${shippingAddressField}`}
																	sx={{
																		paddingRight: 0,
																	}}
																	type="submit"
																	value="overviewSubmit"
																	onClick={() => {
																		setEnableShippingAddressSearch(false);
																	}}
																>
																	<CheckCircleIcon
																		sx={{
																			pointerEvents: 'none',
																			color: '#0047ff',
																			fontSize: '22px',
																			marginLeft: 'auto',
																		}}
																	/>
																</IconButton>
																<IconButton
																	data-testid={`closeEditButton_${shippingAddressField}`}
																	id={`closeEditButton_${shippingAddressField}`}
																	sx={{ padding: '7px 2px 5px 2px' }}
																	type="button"
																	onClick={e => {
																		onChangeData(e, editableIndex, tab);
																		setEnableShippingAddressSearch(false);
																		setFormValue(shippingForm, 'shipping');
																	}}
																>
																	<CloseIcon
																		id={`closeEditButton_${shippingAddressField}`}
																		sx={{
																			color: '#0047ff',
																		}}
																	/>
																</IconButton>
															</div>
														)}
													</fieldset>
												)}
												{!expanded && (
													<Row className="row">
														<Col
															key={`${accountCurrencyField}-${1}`}
															className={!expanded && 1 % 2 == 0 && !editMode}
															style={{ width: '50%', marginBottom: 0, marginRight: 0 }}
														>
															<label className="detail">Account Currency</label>
															<div className="form-control">
																{editMode || editableIndex === accountCurrencyField ? (
																	<div className="row">
																		<TextField
																			hiddenLabel
																			InputProps={{
																				endAdornment: editableIndex ===
																					accountCurrencyField && (
																					<IconButton
																						data-testid={`submitButton_${accountCurrencyField}`}
																						id={`submitButton_${accountCurrencyField}`}
																						sx={{
																							paddingRight: 0,
																						}}
																						type="submit"
																						value="overviewSubmit"
																					>
																						<CheckCircleIcon
																							sx={{
																								pointerEvents: 'none',
																								color: '#0047ff',
																								fontSize: '22px',
																								marginLeft: 'auto',
																							}}
																						/>
																					</IconButton>
																				),
																			}}
																			autoFocus={
																				editableIndex === accountCurrencyField
																			}
																			className="without-padding"
																			data-testid={accountCurrencyField}
																			defaultValue={accountCurrencyValue}
																			error={!!errorMsgs[accountCurrencyField]}
																			helperText={errorMsgs[accountCurrencyField]}
																			id={accountCurrencyField}
																			name={accountCurrencyField}
																			size="small"
																			sx={{
																				height: '60px',
																				'& legend': { display: 'none' },
																				'& fieldset': { top: 0 },
																				fontSize: '14px',
																				flex: '1',
																				maxWidth: '207px',
																				borderRadius: 10,
																			}}
																			variant="outlined"
																			onChange={e => {
																				onChangeData(e, '', tab);
																			}}
																			onKeyPress={e => {
																				if (
																					numericFields.includes(
																						accountCurrencyField
																					) &&
																					!numericRegex.test(e.key)
																				) {
																					e.preventDefault();
																				}
																			}}
																		/>
																		{editableIndex === accountCurrencyField && (
																			<IconButton
																				data-testid={`closeEditButton_${accountCurrencyField}`}
																				id={`closeEditButton_${accountCurrencyField}`}
																				sx={{ padding: '7px 2px 5px 2px' }}
																				type="button"
																			>
																				<CloseIcon
																					id={`closeEditButton_${accountCurrencyField}`}
																					sx={{
																						color: '#0047ff',
																					}}
																					onClick={e => {
																						onChangeData(
																							e,
																							editableIndex,
																							tab
																						);
																					}}
																				/>
																			</IconButton>
																		)}
																	</div>
																) : (
																	<Box>
																		<div className="row">
																			<span
																				style={{
																					maxWidth: '80%',
																					cursor: loggedInUser.permission
																						?.Account.Edit
																						? 'pointer'
																						: '',
																				}}
																				onClick={() => {
																					if (
																						loggedInUser.permission?.Account
																							.Edit
																					) {
																						setEditableIndex(
																							accountCurrencyField
																						);
																					}
																				}}
																				onMouseEnter={() =>
																					setHoveredField(
																						accountCurrencyField
																					)
																				}
																			>
																				{accountCurrencyValue || '-'}
																			</span>
																			{hoveredField === accountCurrencyField &&
																				!!loggedInUser.permission?.Account
																					.Edit && (
																					<IconButton
																						data-testid={`closeEditButton_Edit_${accountCurrencyField}`}
																						id={`closeEditButton_Edit_${accountCurrencyField}`}
																						sx={{ p: 0 }}
																						onClick={e => {
																							onChangeData(
																								e,
																								editableIndex,
																								tab
																							);
																							setEditableIndex(
																								accountCurrencyField
																							);
																						}}
																					>
																						<EditOutlinedIcon
																							sx={{
																								color: 'rgba(0, 0, 0, 0.6)',
																								fontSize: '18px',
																								marginLeft: 'auto',
																							}}
																						/>
																					</IconButton>
																				)}
																		</div>
																	</Box>
																)}
															</div>
														</Col>
													</Row>
												)}
											</div>
										) : (
											rows.map((arr, j) => {
												const addCol = expanded && arr.length < 3;
												return (
													<Row
														key={j}
														bottomBorder={expanded && j < rows.length - 1}
														className="row"
													>
														{arr.map((entry, k) => {
															const fieldName = genFieldName(entry.key);
															return (
																<Col
																	key={`${entry.key}-${k}`}
																	className={
																		!expanded &&
																		k % 2 == 0 &&
																		!editMode &&
																		'right-bordered'
																	}
																	style={{
																		width: '50%',
																		marginBottom: 0,
																		marginRight: 0,
																	}}
																>
																	{requiredFields.includes(fieldName) ? (
																		<label className="detail">
																			{entry.key}
																			<span style={{ color: 'red' }}>
																				&nbsp;*
																			</span>
																		</label>
																	) : (
																		<label className="detail">{entry.key}</label>
																	)}
																	<div className="form-control">
																		{editMode || editableIndex === fieldName ? (
																			<div className="row">
																				{searchableFields.includes(
																					fieldName
																				) && (
																					<Suggest
																						disableClearable
																						hideIcon
																						summary
																						className="auto-completed"
																						customDataLoader={getDataLoaderSelect(
																							fieldName
																						)}
																						disableSearch={getPermissionSelect(
																							fieldName
																						)}
																						disabled={false}
																						listAvoid={listAvoid}
																						listSearchScope={{
																							label:
																								fieldName ===
																								'parent_account'
																									? 'AccountName'
																									: 'UserName',
																							searchKey:
																								fieldName ===
																								'parent_account'
																									? 'accountname'
																									: 'username',
																						}}
																						optionId={
																							fieldName ===
																							'parent_account'
																								? 'AccountName'
																								: 'UserName'
																						}
																						optionStyle={{
																							ml: 1,
																							fontSize: '14px',
																						}}
																						size="small"
																						style={{
																							'& legend': {
																								display: 'none',
																							},
																							'& fieldset': { top: 0 },
																							fontSize: '14px',
																							flex: '1',
																							maxWidth: '207px',
																						}}
																						testid={fieldName}
																						value={entry.value}
																						onSelect={getHandleSelect(
																							fieldName
																						)}
																					/>
																				)}
																				{textFields.includes(fieldName) && (
																					<TextField
																						hiddenLabel
																						InputProps={{
																							endAdornment:
																								editableIndex ===
																									fieldName && (
																									<IconButton
																										data-testid={`submitButton_${fieldName}`}
																										id={`submitButton_${fieldName}`}
																										sx={{
																											paddingRight: 0,
																										}}
																										type="submit"
																										value="overviewSubmit"
																									>
																										<CheckCircleIcon
																											sx={{
																												pointerEvents:
																													'none',
																												color: '#0047ff',
																												fontSize:
																													'22px',
																												marginLeft:
																													'auto',
																											}}
																										/>
																									</IconButton>
																								),
																						}}
																						autoFocus={
																							editableIndex === fieldName
																						}
																						className="without-padding"
																						data-testid={fieldName}
																						defaultValue={entry.value}
																						error={!!errorMsgs[fieldName]}
																						helperText={
																							errorMsgs[fieldName]
																						}
																						id={fieldName}
																						name={fieldName}
																						size="small"
																						sx={{
																							height: '60px',
																							'& legend': {
																								display: 'none',
																							},
																							'& fieldset': { top: 0 },
																							fontSize: '14px',
																							flex: '1',
																							maxWidth: '207px',
																							borderRadius: 10,
																						}}
																						variant="outlined"
																						onChange={e => {
																							onChangeData(e, '', tab);
																						}}
																						onKeyPress={e => {
																							if (
																								numericFields.includes(
																									fieldName
																								) &&
																								!numericRegex.test(
																									e.key
																								)
																							) {
																								e.preventDefault();
																							}
																						}}
																					/>
																				)}
																				{selectFields.includes(fieldName) && (
																					<div style={{ width: 200 }}>
																						<Select
																							class={fieldName}
																							data-testid={fieldName}
																							defaultValue={entry.value}
																							error={
																								!!errorMsgs[fieldName]
																							}
																							name={fieldName}
																							style={{
																								border: 'none',
																								fontFamily: 'Inter',
																							}}
																							sx={{
																								'& legend': {
																									display: 'none',
																								},
																								'& fieldset': {
																									top: 0,
																								},
																								height: '40px',
																								width: editMode
																									? '207px'
																									: '156px',
																								fontSize: '12px',
																								fontStretch: 'normal',
																								fontStyle: 'normal',
																								flex: '1',
																								borderRadius: 3,
																							}}
																							onChange={e => {
																								onChangeData(
																									e,
																									'',
																									tab
																								);
																							}}
																						>
																							{fieldName ===
																								'account_state' &&
																								AccountStateOptions.map(
																									item => (
																										<MenuItem
																											value={
																												item.value
																											}
																										>
																											{item.name}
																										</MenuItem>
																									)
																								)}
																							{fieldName ===
																								'account_record_type' &&
																								AccountRecordTypeOptions.map(
																									(item, index) => (
																										<MenuItem
																											value={
																												item.value
																											}
																										>
																											{item.name}
																										</MenuItem>
																									)
																								)}
																							{fieldName === 'industry' &&
																								IndustryOptions.map(
																									item => (
																										<MenuItem
																											value={
																												item.value
																											}
																										>
																											{item.name}
																										</MenuItem>
																									)
																								)}
																							{fieldName ===
																								'hosting_model' &&
																								HostingModelOptions.map(
																									item => (
																										<MenuItem
																											value={
																												item.value
																											}
																										>
																											{item.name}
																										</MenuItem>
																									)
																								)}
																							{fieldName ===
																								'integration_validation_required' &&
																								IntegrationValidationRequiredOptions.map(
																									item => (
																										<MenuItem
																											value={
																												item.value
																											}
																										>
																											{item.name}
																										</MenuItem>
																									)
																								)}
																							{fieldName ===
																								'local_time' &&
																								timezones.map(item => (
																									<MenuItem
																										value={
																											item.valueString
																										}
																									>
																										{
																											item.valueString
																										}
																									</MenuItem>
																								))}
																							{fieldName ===
																								'where_to_onboard_customers' &&
																								Object.keys(
																									WhereToOnboardCustomers
																								).map(item => (
																									<MenuItem
																										value={item}
																									>
																										{
																											WhereToOnboardCustomers[
																												item
																											]
																										}
																									</MenuItem>
																								))}
																						</Select>
																						<FormHelperText
																							className="Mui-error"
																							sx={{ marginLeft: '14px' }}
																						>
																							{errorMsgs[fieldName]}
																						</FormHelperText>
																					</div>
																				)}
																				{editableIndex === fieldName &&
																					enableSubmitIconFields.includes(
																						fieldName
																					) && (
																						<IconButton
																							data-testid={`submitButton_${fieldName}`}
																							id={`submitButton_${fieldName}`}
																							sx={{
																								padding:
																									'8px 0px 8px 0px',
																							}}
																							type="submit"
																							value="overviewSubmit"
																						>
																							<CheckCircleIcon
																								sx={{
																									pointerEvents:
																										'none',
																									color: '#0047ff',
																									fontSize: '22px',
																									marginLeft: '3px',
																								}}
																							/>
																						</IconButton>
																					)}
																				{editableIndex === fieldName &&
																					enableCancelIconFields.includes(
																						fieldName
																					) && (
																						<IconButton
																							data-testid={`closeEditButton_${fieldName}`}
																							id={`closeEditButton_${fieldName}`}
																							sx={{
																								padding:
																									'7px 2px 5px 2px',
																							}}
																							type="button"
																						>
																							<CloseIcon
																								id={`closeEditButton_${fieldName}`}
																								sx={{
																									color: '#0047ff',
																								}}
																								onClick={e => {
																									onChangeData(
																										e,
																										fieldName,
																										tab
																									);
																								}}
																							/>
																						</IconButton>
																					)}
																			</div>
																		) : (
																			<Box>
																				<div className="row">
																					<span
																						style={{
																							maxWidth: entry.key
																								.toLowerCase()
																								.split(' ')
																								.includes('address')
																								? '128px'
																								: '80%',
																							cursor: loggedInUser
																								.permission?.Account
																								.Edit
																								? 'pointer'
																								: '',
																						}}
																						onClick={() => {
																							if (
																								loggedInUser.permission
																									?.Account.Edit
																							) {
																								setEditableIndex(
																									fieldName
																								);
																							}
																						}}
																						onMouseEnter={() =>
																							setHoveredField(fieldName)
																						}
																					>
																						{(fieldName ===
																						'where_to_onboard_customers'
																							? WhereToOnboardCustomers[
																									entry.value
																							  ]
																							: entry.value) || '-'}
																					</span>
																					{hoveredField === fieldName &&
																						!!loggedInUser.permission
																							?.Account.Edit && (
																							<IconButton
																								data-testid={`closeEditButton_Edit_${fieldName}`}
																								id={`closeEditButton_Edit_${fieldName}`}
																								sx={{ p: 0 }}
																								onClick={e => {
																									onChangeData(
																										e,
																										editableIndex,
																										tab
																									);
																									setEditableIndex(
																										fieldName
																									);
																								}}
																							>
																								<EditOutlinedIcon
																									sx={{
																										color: 'rgba(0, 0, 0, 0.6)',
																										fontSize:
																											'18px',
																										marginLeft:
																											'auto',
																									}}
																								/>
																							</IconButton>
																						)}
																				</div>
																			</Box>
																		)}
																	</div>
																</Col>
															);
														})}
														{addCol && (
															<Col
																style={{
																	width: '50%',
																	marginBottom: 0,
																	marginRight: 0,
																}}
															/>
														)}
													</Row>
												);
											})
										)}
									</div>
								</Collapse>
							</div>
						);
					})}
				</Collapsible>
				<div>&nbsp;</div>
			</div>
		</TabPanel>
	);
};

Overview.propType = {
	editable: PropTypes.bool,
	expandable: PropTypes.bool,
};

export default Overview;

const CollapseHandle = ({ id, collapsedId, label, onCollapsed }) => (
	<div className="handle" onClick={() => onCollapsed(id)}>
		<span>{label}</span>
		{collapsedId === id ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
	</div>
);
