import React from 'react';
import { Typography, Icon } from '@mui/material';
import OmegaAIIcon from '@worklist-2/ui/src/assets/icons/Chat/omega-ai.svg';
import BlumeIcon from '@worklist-2/ui/src/assets/icons/Chat/blume.svg';
import { FormatChatDateTime } from '@worklist-2/ui/src/components/Chat/ChatDateHelper';
import UserAvatar from './UserAvatar';

function TextChatItem({ data, onClick }) {
	return (
		<div
			style={{
				position: 'relative',
				width: '100%',
				height: '75px',
				padding: '0 40px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				fontFamily: 'Inter',
				borderBottom: '1px solid rgba(196, 196, 196, 0.5)',
				boxSizing: 'border-box',
				cursor: 'pointer',
			}}
			onClick={onClick}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						position: 'absolute',
						top: '0',
						left: '0',
						width: data?.unread ? '3px' : '0px',
						height: '100%',
						backgroundColor: '#4D79EA',
					}}
				/>
				<UserAvatar
					isOnline={data?.participantOnline}
					size={45}
					sx={{ cursor: 'pointer' }}
					userName={data?.name}
				/>
				<div style={{ maxWidth: '300px', marginLeft: '15px' }}>
					<Typography
						data-testid="authorName"
						sx={{
							fontSize: '16px',
							fontWeight: '500',
							lineHeight: '19px',
							color: 'rgba(0, 0, 0, 0.9)',
						}}
					>
						{data?.name}
					</Typography>
					<Typography
						data-testid="authorMessage"
						mt="3px"
						sx={{
							maxWidth: '350px',
							width: '100%',
							fontSize: '12px',
							fontWeight: '400',
							lineHeight: '15px',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{data?.text}
					</Typography>
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Icon
					component={
						data?.senderFrom == 'omegaai' ? OmegaAIIcon : data?.senderFrom == 'blume' ? BlumeIcon : null
					}
					style={{
						height: '22px',
						width: '22x',
						marginRight: '15px',
					}}
				/>
				<span
					data-testid="authorTimeSend"
					style={{
						width: '75px',
						textAlign: 'right',
						fontStyle: 'italic',
						fontWeight: '400',
						fontSize: '12px',
						lineHeight: '15px',
						color: 'rgba(0, 0, 0, 0.6)',
					}}
				>
					{FormatChatDateTime(data?.datetime)}
				</span>
			</div>
		</div>
	);
}

export default TextChatItem;
