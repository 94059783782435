// core
import React from 'react';

// libraries
import { Controller } from 'react-hook-form';

// components
import FormSplitFieldDefault from './FormSplitFieldDefault';

// types
import FormFieldVariantType from '../../../formTypes/FormFieldVariantType';

const FormSplitTextFieldVariant = ({ formHook, name, type, props, splitter = '/' }) => {
	const { control } = formHook;

	return (
		<Controller
			control={control}
			name={name}
			render={({ field }) => (
				<FormSplitFieldDefault
					formHook={formHook}
					name={name}
					props={{
						...field,
						...props,
						onChange: fieldValues => {
							field.onChange(fieldValues);
						},
					}}
					splitter={splitter}
					type={type}
				/>
			)}
		/>
	);
};

FormSplitTextFieldVariant.propTypes = FormFieldVariantType();

export default FormSplitTextFieldVariant;
