import { FhirDomainResource } from '../base';
import blumeFormSchema from './schemata/blumeFormSchema';

export default class BlumeFormResource extends FhirDomainResource {
	id;
	resourceType;
	referenceTemplate;
	name;
	organizationId;
	organizationName;
	version;
	type;
	status;
	description;
	patient = {};
	eligibility = {};
	formTemplate = {};

	constructor(resourceString) {
		super(resourceString, blumeFormSchema);
		this.resourceType = 'BlumeForm';
		this.populateFields();
	}
}
