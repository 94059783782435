import React from 'react';

import { Box, Input, Typography } from '@mui/material';

import { Controller } from 'react-hook-form';

import createStyles from './form-styles';

const FormInput = ({ label, control, name, isRequired, disabledInput }) => {
	const classes = createStyles();

	return (
		<Box>
			{isRequired ? (
				<Box sx={classes.labelBox}>
					<Typography sx={classes.label}>{label}</Typography>
					<Box color="red" display="inline">
						&nbsp;*
					</Box>
				</Box>
			) : (
				<Typography sx={classes.label}>{label}</Typography>
			)}
			<Controller
				control={control}
				name={name}
				render={({ field }) => (
					<Input
						disableUnderline
						disabled={disabledInput}
						required={isRequired}
						sx={classes.inputShort}
						{...field}
					/>
				)}
				rules={{ required: true }}
			/>
		</Box>
	);
};

export default FormInput;
