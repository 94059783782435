import React from 'react';

const ParagraphIcon = ({ fill }) => (
	<svg fill="none" height="16" viewBox="0 0 13 16" width="13" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_31984)">
			<path
				clipRule="evenodd"
				d="M11.2962 2.37203V14.7441C11.2962 14.9866 11.099 15.1851 10.8561 15.1851H9.53727C9.29562 15.1851 9.09724 14.9866 9.09724 14.7441V2.37203H7.59082V14.7429C7.59082 14.9854 7.39365 15.1839 7.15079 15.1839H5.83311C5.59146 15.1839 5.39309 14.9854 5.39309 14.7429V9.80997H5.3462C2.71927 9.80997 0.588867 7.63935 0.588867 4.96404C0.588867 1.86245 3.04748 0.132812 5.84874 0.132812H12.1907C12.4323 0.132812 12.6307 0.333705 12.6307 0.581147V1.92492C12.6307 2.17114 12.4323 2.37326 12.1907 2.37326H11.2962V2.37203Z"
				fill={fill}
				fillRule="evenodd"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2852_31984">
				<rect fill={fill} height="15.0523" transform="translate(0.588867 0.132812)" width="12.0418" />
			</clipPath>
		</defs>
	</svg>
);

export default ParagraphIcon;
