import React, { useState } from 'react';
import { Grid, IconButton, Typography, Menu, MenuItem, ListItemText, ListItemIcon, Slider } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CropFreeSharpIcon from '@mui/icons-material/CropFreeSharp';
import PictureInPictureAltRoundedIcon from '@mui/icons-material/PictureInPictureAltRounded';
import SlowMotionVideoSharpIcon from '@mui/icons-material/SlowMotionVideoSharp';
import { PauseSharp } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

const PLAY_OPTIONS = [
	{
		label: 0.25,
		value: 0.25,
	},

	{
		label: 0.5,
		value: 0.5,
	},
	{
		label: 0.75,
		value: 0.75,
	},
	{
		label: 'Normal',
		value: 1,
	},
	{
		label: 1.25,
		value: 1.25,
	},
	{
		label: 1.5,
		value: 1.5,
	},
	{
		label: 1.75,
		value: 1.75,
	},
	{
		label: 2,
		value: 2,
	},
];

export const PlayerControls = ({
	playedTime,
	playandpause,
	playing,
	played,
	playRate,
	playerbackRate,
	fullScreenMode,
	isFullScreen,
	pipMode,
	enablePip,
	onSeek,
	onSeekMouseUp,
	onSeekMouseDown,
}) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handlePopOver = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Grid
				container
				direction="column"
				justifyContent="flex-start"
				sx={{ width: '100%', alignSelf: 'flex-end' }}
				wrap="nowrap"
			>
				<Grid container direction="row" justifyContent="space-between" ml={-1} wrap="nowrap">
					<Grid container alignItems="center" direction="row" wrap="nowrap">
						<Grid item>
							<IconButton onClick={playandpause}>
								{playing ? (
									<PauseSharp fontSize="small" sx={{ color: '#fff' }} />
								) : (
									<PlayArrowIcon fontSize="small" sx={{ color: '#fff' }} />
								)}
							</IconButton>
						</Grid>
						<Grid item mt="2px">
							<Typography sx={{ color: '#fff' }}>{playedTime}</Typography>
						</Grid>
					</Grid>
					<Grid container alignItems="center" direction="row" justifyContent="flex-end" mr={-2} wrap="nowrap">
						<Grid item>
							<IconButton color={pipMode ? 'rsPrimary' : 'default'} onClick={enablePip}>
								<PictureInPictureAltRoundedIcon fontSize="small" sx={{ color: '#fff' }} />
							</IconButton>
						</Grid>
						<Grid item>
							<IconButton color={anchorEl ? 'rsPrimary' : 'default'} onClick={handlePopOver}>
								<SlowMotionVideoSharpIcon fontSize="small" sx={{ color: '#fff' }} />
							</IconButton>
						</Grid>
						<Grid item>
							<IconButton onClick={fullScreenMode}>
								{isFullScreen ? (
									<FullscreenExitIcon fontSize="small" sx={{ color: '#fff' }} />
								) : (
									<CropFreeSharpIcon fontSize="small" sx={{ color: '#fff' }} />
								)}
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Slider
						color="rsPrimary"
						data-testid="timeline"
						max={100}
						min={0}
						sx={{
							height: isFullScreen ? 10 : 5,
							pb: isFullScreen ? 3 : 2,
							'& .MuiSlider-thumb': {
								width: isFullScreen ? 12 : 7,
								height: isFullScreen ? 12 : 7,
								transition: '0.4s cubic-bezier(.47,1.64,.41,.8)',
								'&:before': {
									boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
								},
								'&:hover, &.Mui-focusVisible': {
									boxShadow: `0px 0px 0px 8px rgb(255 255 255 / 16%)`,
								},
								'&.Mui-active': {
									width: isFullScreen ? 25 : 20,
									height: isFullScreen ? 25 : 20,
								},
							},
							'& .MuiSlider-rail': {
								opacity: 0.28,
							},
						}}
						value={played * 100}
						valueLabelDisplay="off"
						onChange={onSeek}
						onChangeCommitted={onSeekMouseUp}
						onMouseDown={onSeekMouseDown}
					/>
				</Grid>
			</Grid>
			<Menu
				disablePortal
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={Boolean(anchorEl)}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				onClose={handleClose}
			>
				<MenuItem sx={{ pointerEvents: 'none' }}>
					<ListItemIcon>
						<SlowMotionVideoSharpIcon />
					</ListItemIcon>
					<ListItemText>
						<Typography>Playback Speed</Typography>
					</ListItemText>
				</MenuItem>
				{PLAY_OPTIONS.map(option => (
					<MenuItem
						key={option.value}
						selected={playerbackRate === option.value}
						sx={{
							'&.Mui-selected': {
								backgroundColor: 'transparent',
							},
						}}
						onClick={() => {
							playRate(option.value);
							handleClose();
						}}
					>
						{playerbackRate === option.value && (
							<ListItemIcon>
								<CheckIcon color="rsPrimary" />
							</ListItemIcon>
						)}
						<ListItemText inset={playerbackRate !== option.value}>
							<Typography>{option.label}</Typography>
						</ListItemText>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
