function getPTImageIdInstanceMetadata(metaData) {
	const patientWeight = metaData['00101030']?.Value?.[0];
	const radiopharmaceuticalInfo = metaData['00540016']?.Value?.[0];
	const frameReferenceTime = metaData['00541300']?.Value?.[0];
	const actualFrameDuration = metaData['00181242']?.Value?.[0];

	let PatientWeight;
	let RadiopharmaceuticalInformationSequence;
	let FrameReferenceTime;
	let ActualFrameDuration;

	if (patientWeight) {
		PatientWeight = parseFloat(patientWeight);
	}

	if (radiopharmaceuticalInfo) {
		const radionuclideHalfLife = radiopharmaceuticalInfo['00181075']?.Value?.[0];
		const radionuclideTotalDose = radiopharmaceuticalInfo['00181074']?.Value?.[0];

		let RadionuclideHalfLife;
		let RadionuclideTotalDose;

		if (radionuclideHalfLife) {
			RadionuclideHalfLife = parseFloat(radionuclideHalfLife);
			RadionuclideTotalDose = parseFloat(radionuclideTotalDose);
		}

		RadiopharmaceuticalInformationSequence = [
			{
				RadionuclideHalfLife,
				RadionuclideTotalDose,
				RadiopharmaceuticalStartDateTime: radiopharmaceuticalInfo['00181078']?.Value?.[0],
				RadiopharmaceuticalStartTime: radiopharmaceuticalInfo['00181072']?.Value?.[0],
			},
		];
	}

	if (frameReferenceTime) {
		FrameReferenceTime = parseFloat(frameReferenceTime);
	}

	if (actualFrameDuration) {
		ActualFrameDuration = parseFloat(actualFrameDuration);
	}

	const dicomMetaData = {
		SeriesDate: metaData['00080021']?.Value?.[0],
		SeriesTime: metaData['00080031']?.Value?.[0],
		PatientWeight,
		CorrectedImage: metaData['00280051']?.Value,
		Units: metaData['00541001']?.Value?.[0],
		RadiopharmaceuticalInformationSequence,
		DecayCorrection: metaData['00541102']?.Value?.[0],
		AcquisitionDate: metaData['00080022']?.Value?.[0],
		AcquisitionTime: metaData['00080032']?.Value?.[0],
		FrameReferenceTime,
		ActualFrameDuration,
		PatientSex: metaData['00100040']?.Value?.[0],
		PatientSize: metaData['00101020']?.Value?.[0],
		'70531000': metaData['70531000']?.Value?.[0],
		'70531009': metaData['70531009']?.Value?.[0],
		'0009100d': metaData['0009100d']?.Value?.[0],
	};

	if (
		dicomMetaData.SeriesDate === undefined ||
		dicomMetaData.SeriesTime === undefined ||
		dicomMetaData.PatientWeight === undefined ||
		dicomMetaData.CorrectedImage === undefined ||
		dicomMetaData.Units === undefined ||
		!dicomMetaData.RadiopharmaceuticalInformationSequence ||
		dicomMetaData.RadiopharmaceuticalInformationSequence[0].RadionuclideHalfLife === undefined ||
		dicomMetaData.RadiopharmaceuticalInformationSequence[0].RadionuclideTotalDose === undefined ||
		dicomMetaData.DecayCorrection === undefined ||
		dicomMetaData.AcquisitionDate === undefined ||
		dicomMetaData.AcquisitionTime === undefined ||
		(dicomMetaData.RadiopharmaceuticalInformationSequence[0].RadiopharmaceuticalStartDateTime === undefined &&
			dicomMetaData.RadiopharmaceuticalInformationSequence[0].RadiopharmaceuticalStartTime === undefined)
	) {
		console.info('required metadata are missing');

		return;
	}

	const instanceMetadata = {
		CorrectedImage: dicomMetaData.CorrectedImage,
		Units: dicomMetaData.Units,
		RadionuclideHalfLife: dicomMetaData.RadiopharmaceuticalInformationSequence[0].RadionuclideHalfLife,
		RadionuclideTotalDose: dicomMetaData.RadiopharmaceuticalInformationSequence[0].RadionuclideTotalDose,
		RadiopharmaceuticalStartDateTime:
			dicomMetaData.RadiopharmaceuticalInformationSequence[0].RadiopharmaceuticalStartDateTime,
		RadiopharmaceuticalStartTime:
			dicomMetaData.RadiopharmaceuticalInformationSequence[0].RadiopharmaceuticalStartTime,
		DecayCorrection: dicomMetaData.DecayCorrection,
		PatientWeight: dicomMetaData.PatientWeight,
		SeriesDate: dicomMetaData.SeriesDate,
		SeriesTime: dicomMetaData.SeriesTime,
		AcquisitionDate: dicomMetaData.AcquisitionDate,
		AcquisitionTime: dicomMetaData.AcquisitionTime,
	};

	if (
		dicomMetaData['70531000'] ||
		dicomMetaData['70531000'] !== undefined ||
		dicomMetaData['70531009'] ||
		dicomMetaData['70531009'] !== undefined
	) {
		const philipsPETPrivateGroup = {
			SUVScaleFactor: dicomMetaData['70531000'],
			ActivityConcentrationScaleFactor: dicomMetaData['70531009'],
		};
		instanceMetadata.PhilipsPETPrivateGroup = philipsPETPrivateGroup;
	}

	if (dicomMetaData['0009100d'] && dicomMetaData['0009100d'] !== undefined) {
		instanceMetadata.GEPrivatePostInjectionDateTime = dicomMetaData['0009100d'];
	}

	if (dicomMetaData.FrameReferenceTime && dicomMetaData.FrameReferenceTime !== undefined) {
		instanceMetadata.FrameReferenceTime = dicomMetaData.FrameReferenceTime;
	}

	if (dicomMetaData.ActualFrameDuration && dicomMetaData.ActualFrameDuration !== undefined) {
		instanceMetadata.ActualFrameDuration = dicomMetaData.ActualFrameDuration;
	}

	if (dicomMetaData.PatientSex && dicomMetaData.PatientSex !== undefined) {
		instanceMetadata.PatientSex = dicomMetaData.PatientSex;
	}

	if (dicomMetaData.PatientSize && dicomMetaData.PatientSize !== undefined) {
		instanceMetadata.PatientSize = dicomMetaData.PatientSize;
	}

	return instanceMetadata;
}

export default getPTImageIdInstanceMetadata;
