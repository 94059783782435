// core
import React from 'react';
// libs
import { Typography } from '@mui/material';
// utils
import { dateTimeUtil as dateUtils } from '@worklist-2/core/src';

export const NotesFieldDateTime = ({ time, sx }) => {
	const formattedDate = dateUtils.parseLocaleDate(time, 'date');
	const formattedTime = dateUtils.parseLocaleDate(time, 'time');

	return <Typography sx={{ ...SX, ...sx }}>{`${formattedDate} ${formattedTime}`}</Typography>;
};

const SX = {
	fontSize: '12px',
	color: 'rgba(255, 255, 255, 0.6)',
};
