// @flow

// core
import React, { type Node } from 'react';
// components
import { type ISkeletonProps, LoaderSkeleton } from './LoaderSkeleton';
import { Box } from '@mui/material';

export const SmokingAlcoholObservations = ({ children, isLoading }: ISkeletonProps): Node =>
	isLoading ? (
		<Box
			data-testid="smoking-alcohol-observations-loader"
			sx={{
				display: 'flex',
				columnGap: '8px',
				alignItems: 'center',
			}}
		>
			<LoaderSkeleton
				sx={{
					minHeight: '24px',
					width: '70px',
					borderRadius: '41px',
				}}
			/>
			<LoaderSkeleton
				sx={{
					width: '70px',
					minHeight: '24px',
					borderRadius: '41px',
				}}
			/>
		</Box>
	) : (
		children
	);
