import React, { forwardRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import DatePicker from 'react-datepicker';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

const QuarterPicker = ({ quarterSelect, setQuarterGB, deparment }) => {
	const [startDate, setStartDate] = useState();
	const navigate = useNavigate();
	const getQuarter = d => {
		d = d || new Date();
		let m = Math.floor(d.getMonth() / 3) + 1;
		m -= m > 4 ? 4 : 0;
		const y = d.getFullYear();
		return `Q${m} - ${y}`;
	};

	const getDateByQuarter = quarter => {
		const quarterEle = quarter?.split(' - ');
		const quarterNum = quarterEle[0].replace('Q', '') - 1;
		const year = quarterEle[1];
		return new Date(year, quarterNum * 3, 1);
	};

	const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
		<TextField
			ref={ref}
			InputProps={{
				endAdornment: (
					<IconButton
						edge="end"
						sx={{
							padding: '0 0 0 0',
						}}
					>
						<ArrowDropDownIcon />
					</IconButton>
				),
			}}
			className="example-custom-input"
			data-testid="quarterPicker"
			sx={{
				'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
					border: 'none',
				},
				'& .MuiOutlinedInput-notchedOutline': {
					border: 'none',
				},
				'& .MuiOutlinedInput-input': {
					cursor: 'pointer',
				},
				'& .MuiFormControl-root ': {
					width: '120px',
				},
			}}
			value={value || quarterSelect}
			onChange={e => {
				setQuarterGB(e.target.value);
			}}
			onClick={onClick}
		/>
	));

	return (
		<DatePicker
			showQuarterYearPicker
			customInput={<ExampleCustomInput value={quarterSelect} />}
			dateFormat="QQQ - yyyy"
			selected={getDateByQuarter(quarterSelect)}
			onChange={date => {
				setStartDate(date);
				setQuarterGB(getQuarter(date));
				if (deparment) {
					navigate(`/okr/${deparment}/${getQuarter(date)}`);
				}
			}}
		/>
	);
};

export default QuarterPicker;
