/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
import serviceRequestSchema from './schemata/serviceRequestSchema';
/************************************************************************************************************************
Resource: ServiceRequest
Reference: https://www.hl7.org/fhir/ServiceRequest.html


This resource is a request resource from a FHIR workflow perspective - see Workflow.

ServiceRequest is a record of a request for a procedure or diagnostic or other service to be planned, proposed, or performed,
as distinguished by the ServiceRequest.intent field value, with or on a patient. The procedure will lead to either a Procedure
or DiagnosticReport, which in turn may reference one or more Observations, which summarize the performance of the procedures
and associated documentation such as observations, images, findings that are relevant to the treatment/management of the
subject. This resource may be used to share relevant information required to support a referral or a transfer of care request
from one practitioner or organization to another when a patient is required to be referred to another provider for a consultation
/second opinion and/or for short term or longer term management of one or more health issues or problems. Examples include:

Procedures may be performed by a healthcare professional, a friend or relative or in some cases by the patient themselves.
The principal intention of ServiceRequest is to support ordering procedures for one patient (which includes non-human patients
in veterinary medicine). However, in many contexts, healthcare related processes include performing diagnostic investigations
on groups of subjects, devices involved in the provision of healthcare, and even environmental locations such as ducts, bodies
of water, etc. ServiceRequest supports all these usages.

The service request may represent an order that is entered by a practitioner in a CPOE system as well as a proposal made
by a clinical decision support (CDS) system based on a patient's clinical record and context of care. Planned procedures
referenced by a CarePlan may also be represented by this resource. The general work flow that this resource facilitates is
that a clinical system creates a service request. The service request is then accessed by or exchanged with a system, perhaps
via intermediaries, that represents an organization (e.g., diagnostic or imaging service, surgical team, physical therapy
department) that can perform the procedure. The organization receiving the service request will, after it accepts the request,
update the request as the work is performed, and then finally issue a report that references the requests that it fulfilled.The
ServiceRequest resource allows requesting only a single procedure. If a workflow requires requesting multiple procedures
simultaneously, this is done using multiple instances of this resource. These instances can be linked in different ways,
depending on the needs of the workflow. For guidance, refer to the Request pattern


************************************************************************************************************************/

export default class ServiceRequestResource extends FhirDomainResource {
	identifier = [];
	instantiatesCanonical = [];
	instantiatesUri = [];
	basedOn = [];
	replaces = [];
	requisition;
	status;
	intent;
	category = [];
	priority;
	doNotPerform;
	code;
	orderDetail = [];
	quantityQuantity;
	quantityRatio;
	quantityRange;
	subject;
	encounter;
	occurrenceDateTime;
	occurrencePeriod;
	occurrenceTiming;
	asNeededBoolean;
	asNeededCodeableConcept;
	authoredOn;
	requester;
	performerType;
	performer = [];
	locationCode = [];
	locationReference = [];
	reasonCode = [];
	reasonReference = [];
	insurance = [];
	supportingInfo = [];
	specimen = [];
	bodySite = [];
	note = [];
	patientInstruction;
	relevantHistory = [];

	constructor(resourceString) {
		super(resourceString, serviceRequestSchema);
		this.resourceType = 'ServiceRequest';
		this.populateFields();
	}
}
