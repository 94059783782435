import React from 'react';

const CalloutIcon = ({ fill }) => (
	<svg fill="none" height="25" viewBox="0 0 25 25" width="25" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_31996)">
			<path
				d="M3.56006 17.3227H21.7812V19.3472H3.56006V17.3227ZM19.7566 12.2612V13.2735H5.58463V12.2612H19.7566ZM21.7812 10.2366H3.56006V15.2981H21.7812V10.2366ZM3.56006 6.1875H21.7812V8.21207H3.56006V6.1875Z"
				fill={fill}
			/>
		</g>
		<defs>
			<clipPath id="clip0_2852_31996">
				<rect fill={fill} height="24.2949" transform="translate(0.523438 0.113281)" width="24.2949" />
			</clipPath>
		</defs>
	</svg>
);

export default CalloutIcon;
