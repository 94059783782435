import useMediaQuery from '@mui/material/useMediaQuery';

const breakpoints = {
	desktopXL: 1920,
	desktopL: 1919,
	tablet: 1024,
	mobile: 599,
};

const mediaDesktopXL = `@media (min-width:${breakpoints.desktopXL}px)`;
const mediaDesktopL = `@media (min-width: ${breakpoints.tablet + 1}px) and (max-width: ${breakpoints.desktopL}px)`;
const mediaTablet = `@media (max-width:${breakpoints.tablet}px)`;
const mediaTabletOnly = `@media (min-width: ${breakpoints.mobile + 1}px) and (max-width: ${breakpoints.tablet}px)`;
const mediaMobile = `@media (max-width:${breakpoints.mobile}px)`;

const mediaLandscapeTablet = `@media (orientation: landscape) and (max-width:700px)`;
const mediaLandscapeTabletOnly = `@media (orientation: landscape) and (min-width: 501px) and (max-width: 700px)`;
const mediaLandscapeMobile = `@media (orientation: landscape) and (max-width:500px)`;

const useIsDesktopXL = () => useMediaQuery(mediaDesktopXL);
const useIsDesktopL = () => useMediaQuery(mediaDesktopL);
const useIsTablet = () => useMediaQuery(mediaTablet);
const useIsTabletOnly = () => useMediaQuery(mediaTabletOnly);
const useIsMobile = () => useMediaQuery(mediaMobile);

export {
	breakpoints,
	mediaDesktopXL,
	mediaDesktopL,
	mediaTablet,
	mediaTabletOnly,
	mediaMobile,
	mediaLandscapeMobile,
	mediaLandscapeTabletOnly,
	mediaLandscapeTablet,
	useIsDesktopXL,
	useIsDesktopL,
	useIsTablet,
	useIsMobile,
	useIsTabletOnly,
};
