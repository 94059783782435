import React from 'react';

import { Box, Typography, CircularProgress } from '@mui/material';

import createStyles from './styles';

const Loading = () => {
	const classes = createStyles();

	return (
		<Box data-testid="loading-spinner" sx={classes.loadingBox}>
			<CircularProgress className="progressBar" size={22} sx={classes.loadingCircle} />
			<Typography sx={classes.loadingLabel}>Loading...</Typography>
		</Box>
	);
};

export default Loading;
