import SettingsIcon from '@mui/icons-material/Settings';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useCrmContext } from '../../../context/CRMContext';
import { CardOutline, DivImage, DropBoxWrapper } from '../../../views/CrmView/styles';
import { crmSearchScopes, useCRMDataLoader } from '@worklist-2/core/src';
import _ from 'lodash';
import PropType from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const TeamCard = ({ data, droppable, onClick, localRoles }) => {
	const { widgetData } = useCrmContext();
	const [team, setTeam] = useState(data);
	const [draggingOver, setDraggingOver] = useState(false);
	const teamDataLoader = useCRMDataLoader({ scope: crmSearchScopes.teamApi });
	let timer;

	const onDropped = useCallback(
		async e => {
			e.preventDefault();
			setDraggingOver(false);
			const droppedData = e.dataTransfer?.getData('roleData');
			const roles = JSON.parse(droppedData);
			const newRoles = _.uniqBy(_.concat(roles, team.Role), r => r.RoleId && r.RoleName);
			const payload = Object.assign(team, { Role: newRoles });
			await teamDataLoader.update(team.TeamId, payload);
		},
		[team]
	);

	const onDragOver = useCallback(e => {
		e.preventDefault();
		setDraggingOver(true);
		if (timer) {
			clearTimeout(timer);
		}
	}, []);

	const onDragLeave = useCallback(e => {
		e.preventDefault();
		timer = setTimeout(() => setDraggingOver(false), 500);
	}, []);

	useEffect(() => {
		if (viewing) {
			setTeam(widgetData.data);
		}
	}, [widgetData, viewing]);

	const viewing = useMemo(
		() => widgetData?.tag === 'editTeam' && widgetData?.data?.TeamId === team?.TeamId,
		[widgetData, team]
	);

	return (
		<DropBoxWrapper>
			<CardOutline
				active={viewing}
				className="team-card"
				data-testid="team-card"
				disabled={!team?.IsActive}
				draggingOver={draggingOver}
				droppable={droppable}
				sx={{
					animationFillMode: 'forwards',
					animation: draggingOver ? 'fadeIn 1.5s ease-in-out' : 'none',

					'&:hover': {
						cursor: 'pointer',
						background: '#EDF2FF',
						border: `${draggingOver ? '2px dashed' : '2px solid'} #4D79EA`,
					},
				}}
				onClick={() => onClick(team, localRoles)}
				onDragLeave={onDragLeave}
				onDragOver={onDragOver}
				onDrop={onDropped}
			>
				{draggingOver ? (
					<Box
						sx={{
							width: '100%',
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Typography sx={{ color: '#4D79EA' }}>Drop Here to Group</Typography>
					</Box>
				) : (
					<>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Box sx={{ flex: 1 }}>
								<h1>{team?.TeamName}</h1>
								<span>{`${_.size(team?.Role)} role${_.size(team?.Role) == 1 ? '' : 's'} in team`}</span>
							</Box>
							<IconButton sx={{ width: '20px', height: '20px' }}>
								<SettingsIcon sx={{ width: '14px', height: '14px', color: '#4D79EA' }} />
							</IconButton>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'flex-start',
								mx: '16px',
							}}
						>
							<TemMembersAvatars members={team?.User} />
						</Box>
					</>
				)}
			</CardOutline>
		</DropBoxWrapper>
	);
};

TeamCard.propTypes = {
	data: PropType.object,
	droppable: PropType.bool,
	onClick: PropType.func,
};

export default TeamCard;

export const TemMembersAvatars = props => {
	const initialsUser = member =>
		member?.UserName?.split(' ')
			.map(n => n.charAt(0).toUpperCase())
			.join('');
	const members = _.map(props.members, member => {
		member.initials = initialsUser(member);
		return { ...member };
	});

	useEffect(() => {
		if (!members) {
			props = { ...props, members: [] };
		}
	}, []);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ...props.sx }} {...props}>
			{_.slice(props.members, 0, 3)?.map((member, i) => (
				<MemberAvatar key={i} member={member} />
			))}
			<DivImage
				style={{
					background: '#E8E8E8',
					marginLeft: '-15px',
					border: '2px solid #FFF',
					boxShadow: '-1px 0px 1px rgba(0, 0, 0, 0.21)',
				}}
			>
				<h1 data-testid="members-count">{_.size(props.members)}</h1>
			</DivImage>
		</Box>
	);
};

export const MemberAvatar = ({ member, sx }) => {
	const initialsUser = member =>
		member?.UserName?.split(/\s/g)
			.map(n => n.charAt(0).toUpperCase())
			.join('');
	member.initials = initialsUser(member);

	return (
		<DivImage
			className="avatar"
			style={{
				background: '#E8E8E8',
				backgroundImage: `url(${member.Avatar})`,
				marginLeft: '-15px',
				border: '2px solid #FFF',
				boxShadow: '-1px 0px 1px rgba(0, 0, 0, 0.21)',
				...sx,
			}}
		>
			{!member.Avatar && <h1>{member?.initials}</h1>}
		</DivImage>
	);
};

TemMembersAvatars.propTypes = {
	members: PropType.array.isRequired,
};
