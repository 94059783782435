/* Generated automagically by FhirStarter - DO NOT EDIT */

const studySetSchema = {
	active: '__boolean__',
	description: '__string__',
	duration: '__unsignedInt__',
	id: '__string__',
	physicianRequired: '__boolean__',
	procedureCode: ['__CodeableConcept__'],
	studyType: '__string__',
};
export default studySetSchema;
