import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Controller } from 'react-hook-form';
import { getDatePickerLocale, datePickerPaperProps } from '../../../utils/calendarLocalization';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const FormDateTimePicker = ({ formHook, name, props }) => {
	const { i18n } = useTranslation();

	return (
		<Controller
			control={formHook.control}
			name={name}
			render={({ field }) => (
				// eslint-disable-next-line react-hooks/rules-of-hooks
				<LocalizationProvider adapterLocale={getDatePickerLocale(i18n)} dateAdapter={AdapterDateFns}>
					<DateTimePicker
						PaperProps={datePickerPaperProps}
						disabled={props?.disabled}
						renderInput={textFieldProps => (
							<TextField
								{...textFieldProps}
								fullWidth
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									endAdornment: undefined,
									startAdornment: textFieldProps.InputProps.endAdornment,
									sx: {
										paddingLeft: 0,
										'& > div': {
											height: '100%',
											width: '40px',

											'& > button': {
												padding: 0,
											},
										},
									},
									...props?.InputProps,
								}}
								// eslint-disable-next-line react/jsx-no-duplicate-props
								inputProps={{
									...textFieldProps.inputProps,
									value: field.value
										? props?.format
											? field.value
											: textFieldProps.inputProps.value
										: '',
								}}
								label={props.name}
								variant={props?.variant || 'outlined'}
							/>
						)}
						value={field.value}
						onChange={val => {
							const dateToSave = moment(val).format(props?.format || 'YYYY-MM-DDTHH:mm:ss');
							field.onChange(dateToSave);
						}}
					/>
				</LocalizationProvider>
			)}
		/>
	);
};

export default FormDateTimePicker;
