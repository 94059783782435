import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useAuth } from '@rs-core/context/UserAuthContext';
import { useAppModeContext } from '@rs-core/context/AppModeContext';
import { useTabContext } from '@rs-core/context/TabContext';

import { NoResultsCallout } from '@rs-ui/components/SearchResults/NoResultsCallout';
import SearchResultItem, { checkAccess } from '@rs-ui/components/SearchResults/SearchResultItem';
import { MoreResultsButton } from '@rs-ui/components/SearchResults/SearchDataSet';
import SendDrawer from '@rs-ui/components/Worklist/WorklistGrid/SendDrawer';
import { HIDDEN_HELP_CENTER_CATEGORIES } from '@worklist-2/patientPortal/src/consts';

import styles from './SearchDataSetV2.module.scss';

import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const SearchDataSetV2 = ({
	data,
	isAutoSuggest = false,
	isLoadingUserId,
	getOnClick,
	onClick: _onClick,
	select,
	handleTabChange,
	onCloseDialog,
	setIsClickAwayDisabled,
}) => {
	const { searchTab, validOrganizations, internalPatients } = useTabContext();

	const [dataSet, setDataSet] = useState([]);
	const { isWorklistMode, isHelpCenterMode, isMarketplaceMode, isPatientPortalMode } = useAppModeContext();
	const appModeIsWorklist = isWorklistMode();
	const appModeIsHelpCenter = isHelpCenterMode();
	const appModeIsMarketplace = isMarketplaceMode();
	const isBlumeMode = isPatientPortalMode();
	const { t } = useTranslation('Search');
	const metaEmergencySearch = useBooleanFlagValue('meta-emergency-search');
	const { loggedInUser } = useAuth();

	const itemCountPerSection = select?.length === 1 ? 6 : isAutoSuggest ? 2 : 4;

	const resourceTypes = [
		{ key: 'Patient' },
		{
			key: 'ImagingStudyWorklist',
			label: 'Study',
		},
		{ key: 'Organization' },
		{ key: 'Practitioner', label: 'User' },
		{
			key: 'BlumeStudy',
			labelPatientPortal: t('My Studies'),
			tabName: 'Studies',
		},
		{
			key: 'StudySharedWithMe',
			labelPatientPortal: t('Shared With Me'),
			tabName: 'Shared',
		},
		{
			key: 'helpcenter',
			label: 'Help Center',
			labelPatientPortal: t('Help Center'),
			tabName: 'Help Center',
		},
		{
			key: 'MarketplaceApp',
			label: 'Marketplace App',
		},
	];

	useEffect(() => {
		if (data?.length > 0) {
			const newDataSet = [];

			for (const field of resourceTypes) {
				const filteredData = _.filter(data, item => (item.resourceType ?? item.ResourceType) == field.key);

				if (filteredData?.length > 0) {
					if (appModeIsWorklist || appModeIsHelpCenter || appModeIsMarketplace) {
						newDataSet.push({
							data: filteredData,
							field: field.label || field.key,
							tab: field.label || field.key,
						});
					} else if (filteredData?.length > 0) {
						// Filter unwanted categories for Blume
						if (isPatientPortalMode() && field?.key === 'helpcenter') {
							newDataSet.push({
								data: filteredData.filter(
									article => !HIDDEN_HELP_CENTER_CATEGORIES.includes(article?.Category)
								),
								field: field.labelPatientPortal,
								tab: field.tabName,
							});
						} else {
							newDataSet.push({
								data: filteredData,
								field: field.labelPatientPortal,
								tab: field.tabName,
							});
						}
					}
				}
			}

			if (select?.length > 0) {
				const newDataSetFiltered = [];
				select.forEach(item => {
					newDataSet.forEach(elem => {
						if (elem.field === item.key) {
							newDataSetFiltered.push(elem);
						}
					});
				});

				setDataSet(newDataSetFiltered);
			} else {
				setDataSet(newDataSet);
			}
		}
	}, [data, searchTab, isAutoSuggest, select]);

	return (
		<div className={styles.dataSet}>
			{dataSet?.length ? (
				dataSet.map((ds, index) => (
					<div key={ds.field}>
						<div className={`${styles.titleBox} ${index === 0 ? styles.titleBoxFirst : ''}`}>
							<p className={styles.title} style={{ color: isBlumeMode ? '#000' : 'unset' }}>
								{t(_.upperCase(ds.field))}
							</p>
						</div>

						<div className={styles.searchList}>
							{ds.data.slice(0, itemCountPerSection).map(item => {
								const isAccess = checkAccess(
									item,
									validOrganizations,
									internalPatients,
									loggedInUser?.id,
									metaEmergencySearch
								);
								const onClick = _onClick || getOnClick(item);
								const cursorType = isAccess ? 'pointer' : 'default';

								return (
									<div
										key={item.id}
										className={styles.searchItem}
										data-cy={`dataset-${ds.field}`}
										style={{ cursor: cursorType }}
										onClick={isAccess ? onClick : () => {}}
									>
										<SearchResultItem
											showButton
											SendDrawer={SendDrawer}
											isAccess={isAccess}
											item={item}
											setIsClickAwayDisabled={setIsClickAwayDisabled}
											showProgress={
												isLoadingUserId &&
												item.id === isLoadingUserId &&
												item.resourceType === 'Practitioner'
											}
											studyBtnClassName={styles.studyBtn}
											type="search"
											onCloseDialog={onCloseDialog}
											onGoTo={onClick}
										/>
									</div>
								);
							})}
						</div>

						<div className={styles.moreResults}>
							<MoreResultsButton
								appModeIsWorklist={appModeIsWorklist}
								isVisible={ds.data.length > 4 && !isAutoSuggest}
								tabLabel={ds.field}
								tabName={ds.tab}
								onClick={handleTabChange}
							/>
						</div>
					</div>
				))
			) : !isAutoSuggest ? (
				<NoResultsCallout />
			) : null}
		</div>
	);
};

SearchDataSetV2.propTypes = {
	data: PropTypes.array,
	isAutoSuggest: PropTypes.bool,
	getOnClick: PropTypes.func,
	handleTabChange: PropTypes.func,
	handleRelatedTabData: PropTypes.func,
};

export default SearchDataSetV2;
