import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
	Box,
	Typography,
	CircularProgress,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import { useCrmContext } from '../../../context/CRMContext';
import { crmSearchScopes, useCRMSearchScope, useCRMDataLoader, useAuth } from '@worklist-2/core/src';

import FilterableColumn from '../Cases/FilterableColumn';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.white,
		borderWidth: 0,
		color: '#666666',
		fontWeight: '400',
		cursor: 'pointer',

		'& small': {
			fontSize: '14px !important',
		},
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		borderWidth: 0,
		color: theme.palette.common.black,
	},
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&': {
		color: theme.palette.common.black,
	},
	'&:hover td': {
		backgroundColor: '#C8D6FF',
		cursor: 'pointer',
	},
	'& td': {
		padding: 10,
	},
	'&:nth-of-type(odd)': {
		backgroundColor: '#F9FBFF',
	},
	'&:nth-of-type(even)': {
		backgroundColor: theme.palette.common.white,
	},
	'&.transparent:nth-of-type(odd)': {
		backgroundColor: theme.palette.common.white,
	},
	'&.transparent:hover td': {
		backgroundColor: `${theme.palette.common.white} !important`,
		cursor: 'pointer',
	},
}));

const AccountsTable = ({ setField, field, setSearchText, searchText, control, filters, setValue }) => {
	const { toggleDetails } = useCrmContext();
	const [data, setData] = useState([]);
	const { authorized } = useAuth();
	const [countLoading, setCountLoading] = useState(0);
	const [order, setOrder] = useState('asc');
	const [newData, setNewData] = useState([]);
	const { updateScope, loading, setLoading, crmLoader, isFetchData, setFetchData, setAccountGlobalList } =
		useCRMSearchScope();
	const accountDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.accountApi,
	});

	useEffect(() => {
		updateScope(crmSearchScopes.accountApi);
	}, []);

	useEffect(() => {
		if (isFetchData || (crmLoader && authorized)) {
			fetchData(accountDataLoader, { summary: true, sort: '-lastUpdated' }).catch(console.error);
		}
	}, [crmLoader, authorized, isFetchData]);

	const fetchData = async (loader, value) => {
		const fnName = 'load';
		const bodyData = undefined;
		if (!loader) {
			loader = accountDataLoader;
		}

		if (authorized) {
			setCountLoading(p => p + 1);
			setData(null);
			setAccountGlobalList([]);

			try {
				await loader[fnName](value, !!bodyData || undefined, bodyData).then(result => {
					const returnData = result;

					setTimeout(() => {
						{
							setData(returnData);
							setNewData(returnData);
							setFetchData(false);
							setAccountGlobalList(returnData);
						}
						setTimeout(() => {
							setCountLoading(p => Math.max(p - 1, 0));
						}, 0);
					}, 0);
				});
			} catch (e) {
				setCountLoading(p => Math.max(p - 1, 0));
				console.error(e);
			}
		}
	};

	useEffect(() => {
		if (filters?.length > 0) {
			const filtersObject = filters.reduce((acc, { type, value = '' }) => {
				acc[type] = removeSpecialCharacter(value);
				return acc;
			}, {});

			setTimeout(() => {
				fetchData(accountDataLoader, {
					...filtersObject,
					summary: true,
				}).catch(console.error);
			}, 500);
		} else {
			setTimeout(() => {
				fetchData(accountDataLoader, { summary: true, sort: '-lastUpdated' }).catch(console.error);
			}, 500);
		}
	}, [searchText, filters?.length]);

	const removeSpecialCharacter = text => {
		text = text.replace('&', '%26');
		return text;
	};

	useEffect(() => {
		setLoading(!!countLoading);
	}, [countLoading]);

	const handleInputText = (e, fieldValue) => {
		let tempValue = e?.target?.value;

		if (field === 'CreatedDateTime' && !!e) {
			tempValue = moment(e).format();
		}

		if (Array.isArray(tempValue)) {
			tempValue = e.target.value.join(',');
		}

		setSearchText(tempValue);
		setValue(fieldValue, tempValue);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = field === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');

		dataSort(order);
	};

	const dataSort = ord => {
		const tempData = newData ? [...newData] : [];
		tempData.sort((a, b) => {
			if (!a[field]) {
				return ord === 'asc' ? -1 : 1;
			}

			if (!b[field]) {
				return ord === 'asc' ? 1 : -1;
			}

			return ord === 'asc' ? b[field]?.localeCompare(a[field]) : a[field]?.localeCompare(b[field]);
		});
		setNewData(tempData);
	};

	useEffect(() => {
		setNewData(data);
	}, [data]);

	return (
		<TableContainer
			style={{
				border: 0,
				borderRadius: 0,
				borderTopRightRadius: '20px',
				borderTopLeftRadius: '20px',
				boxShadow: 'none',
				maxHeight: '100%',
			}}
			sx={{
				'&::-webkit-scrollbar-thumb': {
					background: '#C4C4C4 !important',
					borderRadius: '3px',
				},

				'&::-webkit-scrollbar-track': {
					background: '#FFF !important',
				},
			}}
		>
			<Table stickyHeader aria-label="customized table" sx={{ minWidth: 700 }}>
				<TableHead>
					<TableRow sx={{ height: '66px' }}>
						<FilterableColumn
							control={control}
							field={field}
							fieldName="Account Name"
							fieldValue="AccountName"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="text"
							value={searchText}
						/>
						<FilterableColumn
							control={control}
							field={field}
							fieldName="Client ID"
							fieldValue="ClientId"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="text"
							value={searchText}
						/>
						<FilterableColumn
							control={control}
							field={field}
							fieldName="Billing Country"
							fieldValue="BillingCountry"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="text"
							value={searchText}
						/>
						<FilterableColumn
							control={control}
							field={field}
							fieldName="Phone"
							fieldValue="Phone"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="text"
							value={searchText}
						/>
						<FilterableColumn
							control={control}
							field={field}
							fieldName="Billing State/Province"
							fieldValue="BillingState"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="text"
							value={searchText}
						/>
						<FilterableColumn
							control={control}
							field={field}
							fieldName="Billing City"
							fieldValue="BillingCity"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="text"
							value={searchText}
						/>
						<FilterableColumn
							control={control}
							field={field}
							fieldName="Account Owner"
							fieldValue="AccountOwner"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="text"
							value={searchText}
						/>
						<FilterableColumn
							control={control}
							field={field}
							fieldName="Type"
							fieldValue="AccountType"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="text"
							value={searchText}
						/>
					</TableRow>
				</TableHead>
				{!loading ? (
					<TableBody style={{ flex: '1' }}>
						{newData && newData.length > 0 ? (
							newData.map((row, i) => (
								<StyledTableRow
									key={i}
									onClick={() => {
										toggleDetails({
											data: row,
											tag: 'accounts',
											fullData: data,
											setFullData: setData,
										});
									}}
								>
									<StyledTableCell align="center">{row.AccountName}</StyledTableCell>
									<StyledTableCell align="center">{row.ClientId}</StyledTableCell>
									<StyledTableCell align="center">{row.BillingCountry}</StyledTableCell>
									<StyledTableCell align="center">{row.Phone}</StyledTableCell>
									<StyledTableCell align="center">{row.BillingState}</StyledTableCell>
									<StyledTableCell align="center">{row.BillingCity}</StyledTableCell>
									<StyledTableCell align="center">{row.AccountOwner}</StyledTableCell>
									<StyledTableCell>
										<div
											style={{
												textTransform: 'capitalize',
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											{row.AccountType}
										</div>
									</StyledTableCell>
								</StyledTableRow>
							))
						) : (
							<StyledTableRow>
								<StyledTableCell colSpan={8}>
									<Box
										sx={{
											height: '100%',
											width: '100%',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Typography
											sx={{
												textAlign: 'center',
												fontSie: '16px',
												marginBottom: '8px',
												color: 'black',
											}}
										>
											No Result
										</Typography>
									</Box>
								</StyledTableCell>
							</StyledTableRow>
						)}
					</TableBody>
				) : (
					<TableBody>
						<StyledTableRow>
							<StyledTableCell colSpan={8}>
								<Box
									sx={{
										height: '100%',
										width: '100%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<Typography
										sx={{
											textAlign: 'center',
											fontSie: '16px',
											marginBottom: '8px',
											color: 'black',
										}}
									>
										<CircularProgress
											className="progressBar"
											size={22}
											sx={{
												color: '#03dac5',
												marginRight: '16px',
											}}
										/>
										Loading...
									</Typography>
								</Box>
							</StyledTableCell>
						</StyledTableRow>
					</TableBody>
				)}
			</Table>
		</TableContainer>
	);
};

export default AccountsTable;
