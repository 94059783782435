import React, { useCallback, useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import ToggleSwitch from '../../../LiveChat/ToggleSwitch';

const BlumeOAIAsset = ({ name, features, active, onToggle }) => {
	const [expanded, setExpanded] = useState(false);
	const [isActive, setIsActive] = useState(active?.toLowerCase() === 'true');

	const hasFeatures = features.length;

	const handleChange = useCallback((_, newExpanded) => {
		setExpanded(newExpanded);
	}, []);

	useEffect(() => {
		setIsActive(active?.toLowerCase() === 'true');
	}, [active]);

	const handleSwitchToggle = useCallback(async () => {
		try {
			setIsActive(bool => !bool);

			await onToggle({
				Asset: name,
				Active: !isActive ? 'True' : 'False',
			});
		} catch (err) {
			setIsActive(bool => !bool);
			console.error('Error updating asset:', err);
		}
	}, [isActive, name, onToggle]);

	return (
		<Box sx={{ fontFamily: 'Inter' }}>
			<Accordion
				disableGutters
				expanded={expanded}
				sx={{
					width: '100%',
					boxShadow: 'none',
					backgroundColor: '#F9FAFF',

					'.MuiAccordionSummary-expandIconWrapper': {
						transform: 'none',
					},
				}}
				onChange={handleChange}
			>
				<AccordionSummary
					aria-controls="panel1-content"
					expandIcon={
						hasFeatures ? (
							<Typography
								sx={{
									textTransform: 'uppercase',
									color: '#4D79EA',
									fontWeight: 500,
									fontSize: '12px',
									lineHeight: '15px',
									letterSpacing: '0.5px',
								}}
							>
								{expanded ? 'Hide' : 'View features'}
							</Typography>
						) : null
					}
					id="panel1-header"
					sx={{
						color: '#000000',
						opacity: 0.87,
						fontWeight: 500,
						lineHeight: '24px',
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
						<Box sx={{ width: '55px' }}>
							<ToggleSwitch isActive={isActive} onClick={handleSwitchToggle} />
						</Box>
						<Typography sx={{ flexShrink: 0 }}>{name}</Typography>
					</Box>
				</AccordionSummary>
				{hasFeatures ? (
					<AccordionDetails>
						<Typography sx={{ fontSize: '14px', lineHeight: '17px', fontWeight: 500, color: '#697586' }}>
							Features included
						</Typography>
						<Divider sx={{ width: '100%', color: '#EAECF0', margin: '10px 0' }} />
						<Box
							component="ul"
							sx={{
								color: '#364152',

								'& li::marker': { color: '#9AA4B2' },
							}}
						>
							{features.map(feature => (
								<li key={feature}>{feature}</li>
							))}
						</Box>
					</AccordionDetails>
				) : null}
			</Accordion>
		</Box>
	);
};

export default BlumeOAIAsset;
