import React from 'react';

import styles from './Layout.module.scss';

const Layout = ({ theme, children }) => (
	<div className={`${styles.layout} ${theme === 'OAI' ? styles.OAITheme : styles.BlumeTheme}`} data-testid="layout">
		{children}
	</div>
);

export default Layout;
