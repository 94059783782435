import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

const AppModeContext = createContext({});
const worklistMode = 'worklist';
const patientPortalMode = 'patientPortal';
const marketplaceMode = 'marketplace';
const breezeMode = 'breeze';
const helpCenterMode = 'helpCenter';

export const AppModeProvider = ({ children }) => {
	const [isHiddenGlobalSearch, setIsHiddenGlobalSearch] = useState(false);
	const [isHiddenSidebar, setIsHiddenSidebar] = useState(false);

	const [isHiddenRightSidebar, setIsHiddenRightSidebar] = useState(false);
	const [isEmergencySearch, setIsEmergencySearch] = useState(false);
	const [calendarIsOpen, setCalendarIsOpen] = useState(false);
	const [appMode, setAppMode, removeAppMode] = useLocalStorage(
		'applicationMode',
		localStorage.getItem('applicationMode') || 'worklist'
	);

	const updateAppMode = mode => {
		if (mode) return setAppMode(mode);
		removeAppMode();
	};

	const isPatientPortalMode = useCallback(() => appMode === patientPortalMode, [appMode]);

	const isWorklistMode = useCallback(() => appMode === worklistMode, [appMode]);

	const isMarketplaceMode = useCallback(() => appMode === marketplaceMode, [appMode]);

	const isBreezeMode = useCallback(() => appMode === breezeMode, [appMode]);

	const isHelpCenterMode = useCallback(() => appMode === helpCenterMode, [appMode]);

	const getItemCountRecentViewed = useCallback(() => {
		const obj = {
			worklist: 3,
			patientPortal: 4,
			marketplace: 3,
			helpCenter: 3,
		};

		return obj[`${appMode}`] ?? 0;
	}, [appMode]);

	const getItemCountRecentSearch = useCallback(() => {
		const obj = {
			worklist: 3,
			patientPortal: 8,
			marketplace: 3,
			helpCenter: 3,
		};

		return obj[`${appMode}`] ?? 0;
	}, [appMode]);

	// keep track of paid/void studies - this is temporary as we are mocking insurance/billing
	// TODO: I don't why these values are defined when they aren't even being used.
	const paidStudies = [];
	const voidedStudies = [];

	const values = useMemo(
		() => ({
			appMode,
			isHiddenGlobalSearch,
			setIsHiddenGlobalSearch,
			isHiddenSidebar,
			setIsHiddenSidebar,
			isHiddenRightSidebar,
			setIsHiddenRightSidebar,
			isEmergencySearch,
			setIsEmergencySearch,
			calendarIsOpen,
			setCalendarIsOpen,
			updateAppMode,
			isPatientPortalMode,
			isWorklistMode,
			isMarketplaceMode,
			isBreezeMode,
			isHelpCenterMode,
			getItemCountRecentViewed,
			getItemCountRecentSearch,
			paidStudies,
			voidedStudies,
		}),
		[appMode, isHiddenGlobalSearch, isHiddenSidebar, isHiddenRightSidebar, isEmergencySearch, calendarIsOpen]
	);

	return <AppModeContext.Provider value={values}>{children}</AppModeContext.Provider>;
};
export const useAppModeContext = () => useContext(AppModeContext);
export default AppModeProvider;
