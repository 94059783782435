import React from 'react';

const OmegaDialIcon = ({ fill }) => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_31899)">
			<path
				d="M12.0002 1.69922C6.3002 1.69922 1.7002 6.29922 1.7002 11.9992C1.7002 17.6992 6.3002 22.2992 12.0002 22.2992C17.7002 22.2992 22.3002 17.6992 22.3002 11.9992C22.3002 6.29922 17.7002 1.69922 12.0002 1.69922ZM18.9002 5.99922C20.0002 5.99922 20.9002 6.89922 20.9002 7.99922C20.9002 9.09922 20.0002 9.99922 18.9002 9.99922C17.8002 9.99922 16.9002 9.09922 16.9002 7.99922C16.9002 6.89922 17.8002 5.99922 18.9002 5.99922ZM12.0002 1.99922C13.1002 1.99922 14.0002 2.89922 14.0002 3.99922C14.0002 5.09922 13.1002 5.99922 12.0002 5.99922C10.9002 5.99922 10.0002 5.09922 10.0002 3.99922C10.0002 2.89922 10.9002 1.99922 12.0002 1.99922ZM5.1002 5.99922C6.2002 5.99922 7.10019 6.89922 7.10019 7.99922C7.10019 9.09922 6.2002 9.99922 5.1002 9.99922C4.0002 9.99922 3.1002 9.09922 3.1002 7.99922C3.1002 6.89922 4.0002 5.99922 5.1002 5.99922ZM5.1002 17.9992C4.0002 17.9992 3.1002 17.0992 3.1002 15.9992C3.1002 14.8992 4.0002 13.9992 5.1002 13.9992C6.2002 13.9992 7.10019 14.8992 7.10019 15.9992C7.10019 17.0992 6.2002 17.9992 5.1002 17.9992ZM12.0002 21.9992C10.9002 21.9992 10.0002 21.0992 10.0002 19.9992C10.0002 18.8992 10.9002 17.9992 12.0002 17.9992C13.1002 17.9992 14.0002 18.8992 14.0002 19.9992C14.0002 21.0992 13.1002 21.9992 12.0002 21.9992ZM12.0002 17.2992C9.1002 17.2992 6.7002 14.8992 6.7002 11.9992C6.7002 9.09922 9.1002 6.69922 12.0002 6.69922C14.9002 6.69922 17.3002 9.09922 17.3002 11.9992C17.3002 14.8992 14.9002 17.2992 12.0002 17.2992ZM18.9002 17.9992C17.8002 17.9992 16.9002 17.0992 16.9002 15.9992C16.9002 14.8992 17.8002 13.9992 18.9002 13.9992C20.0002 13.9992 20.9002 14.8992 20.9002 15.9992C20.9002 17.0992 20.0002 17.9992 18.9002 17.9992Z"
				fill={fill || 'white'}
				fillOpacity={fill ? 1 : 0.6}
			/>
		</g>
		<defs>
			<clipPath id="clip0_2852_31899">
				<rect fill={fill || 'white'} height="24" width="24" />
			</clipPath>
		</defs>
	</svg>
);

export default OmegaDialIcon;
