// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EyJ4m28KOHnVgevxn6_G{position:relative;top:100px;right:50px;padding:10px;z-index:999;border-radius:10px;border:1px solid #2b2b2b;background:#4f4f4f;box-shadow:0 4px 4px 0 rgba(0,0,0,.25)}.tHOp6YO5pVmoK5fmjFXw{top:117px}.VlVxl9vhBzw2Vr6LyK96{top:134px}.EyJ4m28KOHnVgevxn6_G .Z2SBOUJ8su_rncbuglZe{display:grid;grid-template-columns:repeat(1, 1fr);gap:4px}.EyJ4m28KOHnVgevxn6_G .Z2SBOUJ8su_rncbuglZe .I08fzN887EzUTfHQOXMd{display:grid;gap:4px}.EyJ4m28KOHnVgevxn6_G .Z2SBOUJ8su_rncbuglZe .I08fzN887EzUTfHQOXMd .pszve1V1uLOIzyqUtGNd{width:30px;height:30px;border-radius:2px;background:#ebebeb;cursor:pointer}.EyJ4m28KOHnVgevxn6_G .Z2SBOUJ8su_rncbuglZe .I08fzN887EzUTfHQOXMd .b8ffGgaWLhSq6OfTfrwg{background:#4d79ea}.EyJ4m28KOHnVgevxn6_G .Z2SBOUJ8su_rncbuglZe .r7z5x9hnWuPNfW6D6tjK{display:flex;justify-content:center;align-items:center;margin-top:5px}.EyJ4m28KOHnVgevxn6_G .Z2SBOUJ8su_rncbuglZe .r7z5x9hnWuPNfW6D6tjK span{color:#fff;text-align:center;font-feature-settings:\"clig\" off,\"liga\" off;font-family:Roboto,serif;font-size:14px;font-style:normal;font-weight:400;line-height:20px;letter-spacing:1px}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/common/MainArea/Draft/InsertTextBlock/Table/TableSizeChanger.module.scss"],"names":[],"mappings":"AAAA,sBACC,iBAAA,CACA,SAAA,CACA,UAAA,CACA,YAAA,CACA,WAAA,CAEA,kBAAA,CACA,wBAAA,CACA,kBAAA,CACA,sCAAA,CAEA,sBACC,SAAA,CAGD,sBACC,SAAA,CAGD,4CACC,YAAA,CACA,oCAAA,CACA,OAAA,CAEA,kEACC,YAAA,CACA,OAAA,CAEA,wFACC,UAAA,CACA,WAAA,CACA,iBAAA,CACA,kBAAA,CACA,cAAA,CAEA,wFACC,kBAAA,CAKH,kEACC,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CAEA,uEACC,UAAA,CACA,iBAAA,CACA,2CAAA,CACA,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableSizeChanger": "EyJ4m28KOHnVgevxn6_G",
	"tableSizeChangerPos4": "tHOp6YO5pVmoK5fmjFXw",
	"tableSizeChangerPos5": "VlVxl9vhBzw2Vr6LyK96",
	"boxGrid": "Z2SBOUJ8su_rncbuglZe",
	"table": "I08fzN887EzUTfHQOXMd",
	"square": "pszve1V1uLOIzyqUtGNd",
	"squareActive": "b8ffGgaWLhSq6OfTfrwg",
	"size": "r7z5x9hnWuPNfW6D6tjK"
};
export default ___CSS_LOADER_EXPORT___;
