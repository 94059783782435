import React from 'react';

const NoDocument = () => (
	<svg fill="none" height="171" viewBox="0 0 214 171" width="214" xmlns="http://www.w3.org/2000/svg">
		<g opacity="0.6">
			<rect fill="white" height="171" width="214" />
			<path
				clipRule="evenodd"
				d="M177.194 55.5781C180.504 55.5781 183.186 58.2577 183.186 61.5631C183.186 64.8685 180.504 67.5481 177.194 67.5481H142.954C146.264 67.5481 148.946 70.2277 148.946 73.5331C148.946 76.8386 146.264 79.5181 142.954 79.5181H161.786C165.096 79.5181 167.778 82.1977 167.778 85.5031C167.778 88.8085 165.096 91.4881 161.786 91.4881H153.078C148.905 91.4881 145.522 94.1677 145.522 97.4731C145.522 99.6767 147.234 101.672 150.658 103.458C153.968 103.458 156.65 106.138 156.65 109.443C156.65 112.749 153.968 115.428 150.658 115.428H79.6104C76.3011 115.428 73.6184 112.749 73.6184 109.443C73.6184 106.138 76.3011 103.458 79.6104 103.458H46.2264C42.9171 103.458 40.2344 100.779 40.2344 97.4731C40.2344 94.1677 42.9171 91.4881 46.2264 91.4881H80.4664C83.7757 91.4881 86.4584 88.8085 86.4584 85.5031C86.4584 82.1977 83.7757 79.5181 80.4664 79.5181H59.0664C55.7571 79.5181 53.0744 76.8386 53.0744 73.5331C53.0744 70.2277 55.7571 67.5481 59.0664 67.5481H93.3064C89.9971 67.5481 87.3144 64.8685 87.3144 61.5631C87.3144 58.2577 89.9971 55.5781 93.3064 55.5781H177.194ZM177.194 79.5181C180.504 79.5181 183.186 82.1977 183.186 85.5031C183.186 88.8085 180.504 91.4881 177.194 91.4881C173.885 91.4881 171.202 88.8085 171.202 85.5031C171.202 82.1977 173.885 79.5181 177.194 79.5181Z"
				fill="#F3F7FF"
				fillRule="evenodd"
			/>
			<path
				clipRule="evenodd"
				d="M131.532 54.7176L139.501 112.729L140.217 118.549C140.448 120.424 139.114 122.131 137.237 122.361L87.1166 128.502C85.24 128.732 83.5316 127.398 83.301 125.524L75.5758 62.7363C75.4605 61.7989 76.1276 60.9457 77.066 60.8307C77.0719 60.83 77.0778 60.8293 77.0838 60.8286L81.2419 60.3632M84.6057 59.9927L88.5321 59.5531L84.6057 59.9927Z"
				fill="white"
				fillRule="evenodd"
			/>
			<path
				d="M132.77 54.5478C132.676 53.8639 132.046 53.3855 131.362 53.4792C130.678 53.573 130.199 54.2034 130.293 54.8874L132.77 54.5478ZM139.501 112.729L140.742 112.577C140.741 112.571 140.74 112.565 140.74 112.56L139.501 112.729ZM140.217 118.549L141.458 118.397L140.217 118.549ZM137.237 122.361L137.389 123.601L137.237 122.361ZM87.1166 128.502L87.269 129.743L87.1166 128.502ZM83.301 125.524L84.5417 125.371L83.301 125.524ZM75.5758 62.7363L74.3351 62.8886L75.5758 62.7363ZM77.0838 60.8286L77.2232 62.0708L77.0838 60.8286ZM81.3813 61.6054C82.0674 61.5286 82.5612 60.9102 82.4842 60.2242C82.4072 59.5381 81.7886 59.0442 81.1025 59.121L81.3813 61.6054ZM84.4662 58.7505C83.7802 58.8273 83.2864 59.4457 83.3634 60.1317C83.4404 60.8178 84.059 61.3117 84.7451 61.2349L84.4662 58.7505ZM88.6715 60.7953C89.3576 60.7185 89.8513 60.1001 89.7743 59.414C89.6973 58.728 89.0787 58.2341 88.3926 58.3109L88.6715 60.7953ZM130.293 54.8874L138.263 112.899L140.74 112.56L132.77 54.5478L130.293 54.8874ZM138.26 112.882L138.977 118.702L141.458 118.397L140.742 112.577L138.26 112.882ZM138.977 118.702C139.123 119.89 138.277 120.974 137.085 121.12L137.389 123.601C139.95 123.288 141.773 120.958 141.458 118.397L138.977 118.702ZM137.085 121.12L86.9643 127.261L87.269 129.743L137.389 123.601L137.085 121.12ZM86.9643 127.261C85.7717 127.407 84.6879 126.56 84.5417 125.371L82.0603 125.676C82.3754 128.237 84.7082 130.056 87.269 129.743L86.9643 127.261ZM84.5417 125.371L76.8165 62.584L74.3351 62.8886L82.0603 125.676L84.5417 125.371ZM76.8165 62.584C76.7856 62.3329 76.9644 62.1025 77.2184 62.0714L76.9136 59.59C75.2909 59.7889 74.1354 61.2649 74.3351 62.8886L76.8165 62.584ZM77.2184 62.0714C77.22 62.0712 77.2216 62.071 77.2232 62.0708L76.9444 59.5864C76.9341 59.5876 76.9238 59.5888 76.9136 59.59L77.2184 62.0714ZM77.2232 62.0708L81.3813 61.6054L81.1025 59.121L76.9444 59.5864L77.2232 62.0708ZM84.7451 61.2349L88.6715 60.7953L88.3926 58.3109L84.4662 58.7505L84.7451 61.2349Z"
				fill="#4D79EA"
			/>
			<path
				clipRule="evenodd"
				d="M129.377 58.3705L136.584 110.939L137.233 116.213C137.441 117.912 136.248 119.456 134.567 119.662L89.6769 125.168C87.9961 125.374 86.4642 124.164 86.2554 122.465L79.3143 66.0007C79.1796 64.9044 79.9591 63.9066 81.0554 63.7722L86.3229 63.1262"
				fill="#E8F0FE"
				fillRule="evenodd"
			/>
			<path
				d="M92.5625 46.75C92.5625 45.2312 93.7937 44 95.3125 44H133.496C134.224 44 134.923 44.2891 135.439 44.8037L146.607 55.9523C147.124 56.4682 147.415 57.1684 147.415 57.8986V110.57C147.415 112.089 146.183 113.32 144.665 113.32H95.3125C93.7937 113.32 92.5625 112.089 92.5625 110.57V46.75Z"
				fill="white"
				stroke="#4D79EA"
				strokeWidth="2.5"
			/>
			<path
				d="M134.109 44.8047V54.7203C134.109 56.1369 135.259 57.2853 136.677 57.2853H143.469"
				stroke="#4D79EA"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2.5"
			/>
			<path
				d="M101.008 100.886H123.264M101.008 57.2812H123.264H101.008ZM101.008 67.5413H137.816H101.008ZM101.008 78.6563H137.816H101.008ZM101.008 89.7713H137.816H101.008Z"
				stroke="#75A4FE"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2.5"
			/>
		</g>
	</svg>
);

export default NoDocument;
