import ArrowAnnotationTool from './ArrowAnnotationTool';

class ArrowAnnotationToolModyfied extends ArrowAnnotationTool {
	constructor(
		toolProps = {},
		defaultToolProps = {
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				shadow: true,
				getTextCallback,
				changeTextCallback,
				preventHandleOutsideImage: false,
				arrowFirst: true,
			},
		}
	) {
		super(toolProps, defaultToolProps);
	}
}

const drawInput = (x, y, callback) => {
	const inputStyle = `
		position: fixed;
		z-index: 100000; 
		top: ${y}px; left: ${x}px;
		width: 140px;
		height: 80px;
		border: 1px solid #395f7f;
		background: transparent; 
		pointer-events: none;
		font-family: Roboto,-apple-system,BlinkMacSystemFont,
		"Segoe UI","Helvetica Neue",Arial,sans-serif,
		"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
		font-weight: 400;
		padding: 6px 8px 8px;
		font-size: 0.8rem;
		line-height: 1.4375em;
		color: rgba(255, 255, 255, 0.87);
		outline: none !important;
		resize: none;
	`;

	const input = document.createElement('TEXTAREA');
	input.style.cssText = inputStyle;
	document.body.appendChild(input);
	input.focus();

	const callCallback = () => {
		if (input.value) {

			callback(input.value);
		} else {
			callback(false);
		}
		removeInput();
	};

	const keyDown = (event) => {
		event.stopPropagation();
		if (event.key === 'Enter' || event.key == 'Escape' || event.key == 'Esc') {
			callCallback();
		}
	};

	const keyUp = (event) => {
		event.stopPropagation();
	};

	const onBlur = () => {
		callCallback();
	};

	const removeInput = () => {
		input.removeEventListener('blur', onBlur);
		input.removeEventListener('keydown', keyDown);
		input.removeEventListener('keyup', keyUp);
		input.remove();
	};

	input.addEventListener('blur', onBlur);
	input.addEventListener('keyup', keyUp);
	input.addEventListener('keydown', keyDown);
};

function addFormAndEventOnDocument(callback) {
	document.body.addEventListener(
		'mousemove',
		(event) => {
			drawInput(event.clientX, event.clientY, callback);
		},
		{ once: true }
	);
}

function getTextCallback(doneChangingTextCallback) {
	return addFormAndEventOnDocument(doneChangingTextCallback);
}

function changeTextCallback(data, eventData, doneChangingTextCallback) {
	return addFormAndEventOnDocument(doneChangingTextCallback);
}

export default ArrowAnnotationToolModyfied;
