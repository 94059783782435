import React, { useState, useEffect } from 'react';

import { Avatar, AvatarGroup, Button, Divider } from '@mui/material';
import ShareIcon from '@worklist-2/ui/src/assets/icons/share2.svg';
import { useTreeContext } from '@rs-ui/context/TreeContext';
import { generateAvatarName } from '@worklist-2/core/src/fhir/resource/columnMapping/utils';

const ShareAvatarGroup = () => {
	const { selectedFolderData, setOpenShareDrawer, folderTab } = useTreeContext();
	const [accessControl, setAccessControl] = useState();

	// Set access control data
	useEffect(() => {
		setAccessControl(selectedFolderData?.accessControl);
	}, [selectedFolderData.accessControl, setAccessControl]);

	return (
		<>
			<AvatarGroup
				data-testid="ShareAvatarGroup"
				max={3}
				sx={{
					'.MuiAvatar-root': {
						background: '#A09BD5',
						color: '#fff',
						width: '33px',
						height: '33px',
						fontSize: '14px',
					},
				}}
			>
				{folderTab != 'myfolder' && selectedFolderData?.owner?.id && (
					<Avatar key={selectedFolderData?.owner?.id}>
						{generateAvatarName(selectedFolderData?.owner?.display?.replace('^', ' '))}
					</Avatar>
				)}
				{accessControl?.user?.map(us => (
					<Avatar key={us.id}>{generateAvatarName(us.display?.replace('^', ' '))}</Avatar>
				))}
				{accessControl?.organization?.map(org => (
					<Avatar key={org.id}>{generateAvatarName(org.display?.replace('^', ' '))}</Avatar>
				))}
			</AvatarGroup>
			<Button
				data-testid="ShareIcon"
				sx={{
					marginLeft: '4px',
					minWidth: '35px',
					color: '#C4C4C4',
					padding: 0,
					height: '35px',
				}}
				onClick={() => setOpenShareDrawer(true)}
			>
				<ShareIcon />
			</Button>
			<Divider flexItem orientation="vertical" sx={{ height: '16px', alignSelf: 'center', marginLeft: '20px' }} />
		</>
	);
};

export default ShareAvatarGroup;
