// Core
import React from 'react';
import PropTypes from 'prop-types';

// MUI components
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Components
import TreeEditingFolderIcon from '../TreeIcons/TreeEditingFolderIcon/TreeEditingFolderIcon';

// Styles
import { StyledName } from '../Tree.style';
import { Button } from '@mui/material';

// Context
import { useTreeContext } from '@rs-ui/context/TreeContext';
import OverflowTooltip from '@rs-ui/components/OverflowTooltip';

// FolderName component
const TreeFolderName = ({
	isOpen,
	name,
	handleClick,
	isLastChild,
	isSelectedFolder,
	isEditing,
	handleSubmit,
	level,
	disabledButton,
}) => {
	// Context
	const { treeWidth } = useTreeContext();

	// Folder icon
	let folderIcon;
	if (isLastChild) {
		folderIcon = <Box sx={{ width: '24px' }} />;
	} else if (isOpen) {
		folderIcon = (
			<ArrowDropDownIcon
				sx={{ color: isSelectedFolder ? 'rgba(66, 165, 245, 1)' : 'rgba(255, 255, 255, 0.87)' }}
				onClick={event => {
					handleClick(event, true);
				}}
			/>
		);
	} else {
		folderIcon = (
			<ArrowRightIcon
				sx={{ color: isSelectedFolder ? 'rgba(66, 165, 245, 1)' : 'rgba(255, 255, 255, 0.87)' }}
				onClick={event => {
					handleClick(event, true);
				}}
			/>
		);
	}

	return (
		<StyledName isSelectedFolder={isSelectedFolder}>
			{folderIcon}
			<Box
				data-testid="folder-detail-area"
				sx={{
					display: 'flex',
				}}
				onClick={event => handleClick(event, false)}
			>
				{isEditing ? (
					<TreeEditingFolderIcon />
				) : (
					<FolderOutlinedIcon
						sx={{
							color: isSelectedFolder ? 'rgba(66, 165, 245, 1)' : 'rgba(255, 255, 255, 0.87)',
						}}
					/>
				)}
				{!isEditing && (
					<OverflowTooltip
						style={{
							color: isSelectedFolder ? 'rgba(66, 165, 245, 1)' : 'rgba(255, 255, 255, 0.87)',
							marginLeft: '5px',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							fontSize: '0.875rem',
							overflow: 'hidden',
							width: `${treeWidth - (level - 1) * 20 - (isEditing ? 93 : 60)}px`,
						}}
						text={name}
					/>
				)}
			</Box>
			{isEditing && (
				<Typography
					sx={{
						color: isSelectedFolder ? 'rgba(66, 165, 245, 1)' : 'rgba(255, 255, 255, 0.87)',
						marginLeft: '5px',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						fontSize: '0.875rem',
						overflow: 'hidden',
						width: `${treeWidth - (level - 1) * 20 - (isEditing ? 93 : 60)}px`,
					}}
				>
					{name}
				</Typography>
			)}
			{isEditing && (
				<Button
					data-cy="submit-folder-name-btn"
					disabled={disabledButton}
					sx={{
						padding: '0',
						maxWidth: '24px',
						minWidth: '24px',
						'&:hover': {
							backgroundColor: 'transparent',
							border: 0,
						},
					}}
					onClick={handleSubmit}
				>
					<CheckCircleOutlinedIcon
						sx={{
							color: `${disabledButton ? 'rgba(255, 255, 255, 0.3)' : '#42A5F5'} !important`,
							marginLeft: '5px',
						}}
					/>
				</Button>
			)}
		</StyledName>
	);
};

// FolderName PropTypes
TreeFolderName.propTypes = {
	/**
	 * Folder name
	 */
	name: PropTypes.string,

	/**
	 * Callback function when folder is clicked
	 */
	handleClick: PropTypes.func,

	/**
	 * Whether this folder is the last child of parent
	 */
	isLastChild: PropTypes.bool,

	/**
	 * Whether this folder is selected
	 */
	isSelectedFolder: PropTypes.bool,

	/**
	 * Whether this folder is open
	 */
	isOpen: PropTypes.bool,

	/**
	 * Whether this folder is being edited
	 */
	isEditing: PropTypes.bool,

	/**
	 * Callback function when folder name is submitted
	 */
	handleSubmit: PropTypes.func,

	/**
	 * Folder level
	 */
	level: PropTypes.number,

	/**
	 * Button disabled
	 */
	disabledButton: PropTypes.bool,
};

// FolderName default props
TreeFolderName.defaultProps = {
	name: '',
	handleClick: () => {},
	isLastChild: false,
	isSelectedFolder: false,
	isOpen: false,
	isEditing: false,
	handleSubmit: () => {},
	level: 0,
	disabledButton: false,
};

export default TreeFolderName;
