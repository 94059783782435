import descriptions from './descriptions';
import modalities from './modalities';

let MODALITIES = [];

const buildDescriptions = () => {
	let descrips = {};
	for (let modality of modalities) {
		descrips[modality] = descriptions.filter(elem => elem.modality === modality).map(elem => elem.display);
	}
	let keys = Object.keys(descrips);
	let list = keys
		.filter(key => descrips[key].length > 0)
		.map(
			key => `"${key}": [${descrips[key].map(elem => `"${elem}"`)}]
    `
		);
	return JSON.parse(`{${list.join(',')}}`);
};

let DESCRIPTIONS = {};

const getRandomModality = () => {
	return MODALITIES[Math.floor(Math.random() * MODALITIES.length)];
};

const getRandomDescription = modality => {
	return DESCRIPTIONS[modality][Math.floor(Math.random() * DESCRIPTIONS[modality].length)];
};

const getRandomModalityAndDescription = () => {
	let modality = getRandomModality(),
		description = getRandomDescription(modality);
	return { modality: modality, description: description };
};

const setup = () => {
	DESCRIPTIONS = buildDescriptions();
	MODALITIES = Object.keys(DESCRIPTIONS);
};

setup();

export default getRandomModalityAndDescription;
