import React from 'react';
import Box from '@mui/material/Box';
import { RegionModalContent } from '../../RegionSelect/RegionModal';

const MobileRegionDrawer = () => (
	<Box>
		<RegionModalContent />
	</Box>
);

export default MobileRegionDrawer;
