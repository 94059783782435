import React from 'react';

const InsertImageIcon = () => (
	<svg
		data-testid="insert-image-icon"
		fill="none"
		height="33"
		viewBox="0 0 33 33"
		width="33"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_5211_13939)">
			<path
				d="M26.125 6.875V26.125H6.875V6.875H26.125ZM26.125 4.125H6.875C5.3625 4.125 4.125 5.3625 4.125 6.875V26.125C4.125 27.6375 5.3625 28.875 6.875 28.875H26.125C27.6375 28.875 28.875 27.6375 28.875 26.125V6.875C28.875 5.3625 27.6375 4.125 26.125 4.125ZM19.4425 16.3075L15.3175 21.6287L12.375 18.0675L8.25 23.375H24.75L19.4425 16.3075Z"
				fill="#4D79EA"
			/>
		</g>
		<defs>
			<clipPath id="clip0_5211_13939">
				<rect fill="white" height="33" width="33" />
			</clipPath>
		</defs>
	</svg>
);

export default InsertImageIcon;
