import React from 'react';

const CrossOutIcon = () => (
	<svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_5211_59775)">
			<path
				d="M4.82667 6.83333C4.65333 6.51333 4.56667 6.14667 4.56667 5.72C4.56667 5.31333 4.65333 4.94667 4.83333 4.60667C5.00667 4.27333 5.25333 3.98667 5.57333 3.74667C5.89333 3.51333 6.27333 3.32667 6.70667 3.19333C7.14667 3.06667 7.63333 3 8.16 3C8.7 3 9.18667 3.07333 9.63333 3.22667C10.0733 3.37333 10.4533 3.58667 10.76 3.85333C11.0733 4.12 11.3133 4.44 11.48 4.80667C11.6467 5.17333 11.7333 5.57333 11.7333 6.01333H9.72667C9.72667 5.80667 9.69333 5.62 9.62667 5.44667C9.56667 5.26667 9.46667 5.12 9.33333 4.99333C9.2 4.86667 9.03333 4.77333 8.83333 4.7C8.63333 4.63333 8.39333 4.59333 8.12667 4.59333C7.86667 4.59333 7.63333 4.62 7.44 4.68C7.24667 4.74 7.08667 4.82 6.96 4.92C6.83333 5.02667 6.73333 5.14667 6.66667 5.28667C6.6 5.42667 6.56667 5.57333 6.56667 5.72667C6.56667 6.04667 6.73333 6.31333 7.06 6.53333C7.31333 6.7 7.57333 6.85333 8 7H4.92667C4.89333 6.94667 4.85333 6.88667 4.82667 6.83333ZM14 9V7.66667H2V9H8.41333C8.53333 9.04667 8.68 9.09333 8.78 9.13333C9.02667 9.24667 9.22 9.36 9.36 9.47333C9.5 9.58667 9.59333 9.71333 9.64667 9.85333C9.69333 9.98667 9.72 10.14 9.72 10.3133C9.72 10.4667 9.68667 10.6133 9.62667 10.7533C9.56667 10.8867 9.47333 11.0067 9.34667 11.1067C9.22 11.2067 9.06667 11.28 8.87333 11.34C8.68 11.3933 8.45333 11.4267 8.2 11.4267C7.91333 11.4267 7.64667 11.4 7.41333 11.34C7.18 11.28 6.97333 11.1867 6.80667 11.06C6.64 10.9333 6.50667 10.7667 6.41333 10.56C6.32 10.3533 6.24667 10.0533 6.24667 9.75333H4.26667C4.26667 10.12 4.32 10.5067 4.42667 10.8067C4.53333 11.1067 4.67333 11.3733 4.86 11.6133C5.04667 11.8467 5.26 12.0533 5.51333 12.2267C5.76 12.4 6.03333 12.5467 6.32667 12.66C6.62 12.7733 6.92667 12.86 7.24667 12.92C7.56667 12.9733 7.88667 13.0067 8.20667 13.0067C8.74 13.0067 9.22667 12.9467 9.66 12.82C10.0933 12.6933 10.4667 12.52 10.7733 12.2933C11.08 12.0667 11.32 11.78 11.4867 11.4467C11.6533 11.1133 11.74 10.7333 11.74 10.3067C11.74 9.90667 11.6733 9.54667 11.5333 9.23333C11.5 9.16 11.46 9.08 11.42 9.01333H14V9Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_5211_59775">
				<rect fill="white" height="16" width="16" />
			</clipPath>
		</defs>
	</svg>
);

export default CrossOutIcon;
