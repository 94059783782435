import areAllImageDimensionsEqual from './areAllImageDimensionsEqual';
import areAllImageComponentsEqual from './areAllImageComponentsEqual';
import areAllImageOrientationsEqual from './areAllImageOrientationsEqual';
import areAllImagePositionsEqual from './areAllImagePositionsEqual';
import areAllImageSpacingEqual from './areAllImageSpacingEqual';
import { CODES } from './codes';

export const checkSingleFrames = instances => {
	let frameSetIssues = {};

	if (instances.length > 2) {
		if (!areAllImageDimensionsEqual(instances)) {
			frameSetIssues = { ...frameSetIssues, [CODES.INCONSISTENT_DIMENSIONS]: true };
		}

		if (!areAllImageComponentsEqual(instances)) {
			frameSetIssues = { ...frameSetIssues, [CODES.INCONSISTENT_COMPONENTS]: true };
		}

		if (!areAllImageOrientationsEqual(instances)) {
			frameSetIssues = { ...frameSetIssues, [CODES.INCONSISTENT_ORIENTATIONS]: true };
		}

		if (!areAllImagePositionsEqual(instances)) {
			frameSetIssues = { ...frameSetIssues, [CODES.INCONSISTENT_POSITION_INFORMATION]: true };
		}

		frameSetIssues = { ...frameSetIssues, ...areAllImageSpacingEqual(instances) };
	}

	return frameSetIssues;
};
