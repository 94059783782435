import { Button, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import React from 'react';
import styled from 'styled-components';

export const StyledContainer = ({ sx = {}, children }) => {
	return (
		<Box
			sx={{
				background: '#F9FAFC',
				width: 'calc(100% - 90px)',
				marginLeft: 'auto',
				height: '100vh',
				display: 'flex',
				flexDirection: 'column',
				color: 'rgba(0, 0, 0, 0.9) !important',
				...sx,

				'*': {
					fontFamily: 'Inter !important;',
				},
			}}
		>
			{children}
		</Box>
	);
};
export const SideNav = ({ children }) => {
	return (
		<Box
			sx={{
				width: '61px',
				height: '100%',
				position: 'fixed',
				left: 0,
				zIndex: 13,
				boxShadow: '-6px -1px 10px 2px #b6bac9',
				borderRadius: '0 30px 30px 0',
				backgroundColor: '#FBFBFB',
			}}
		>
			{children}
		</Box>
	);
};

export const StyledTableContainer = props => {
	return (
		<Box
			{...props}
			sx={{
				width: 'calc(100% - 24px)',
				height: '80vh',
				maxHeight: '80vh',
				overflow: 'auto',
				position: 'relative',
				boxShadow: '0 0 20px 0 rgba(33, 88, 119, 0.1)',
				background: 'white',
				borderRadius: '20px',
				left: '24px',
				top: '16px',
				...props.sx,
			}}
		>
			{props.children}
		</Box>
	);
};
export const SearchBarWrapper = ({ sx = {}, children }) => {
	return (
		<Box
			sx={{
				width: '100%',
				position: 'absolute',
				zIndex: 10,
				background: '#f9fafc',
				height: '76px',
				alignItems: 'flex-end',
				top: '0px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				paddingBottom: '10px',
				...sx,

				'.right-section': {
					position: 'absolute',
					right: '43px',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				},

				'.right-button': {
					color: '#4D79EA',

					'.dot': {
						width: '8px',
						height: '8px',
						background: '#EF5D5D',
						borderRadius: '50%',
						position: 'absolute',
						right: '10px',
						top: '10px',
					},
				},
			}}
		>
			{children}
		</Box>
	);
};

export const SearchBar = ({ children }) => {
	return (
		<Box
			sx={{
				width: '521px',
				height: '43px',
				boxShadow: '0px 0px 10px rgba(33, 88, 119, 0.21)',
				background: '#F9FAFC',
				borderRadius: '21.5px',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',

				input: {
					padding: '1px 2px !important',
					background: 'transparent !important;',
				},
			}}
		>
			{children}
		</Box>
	);
};

export const StyledButton = props => {
	return (
		<Button
			{...props}
			sx={{
				width: '63px',
				height: '62px',
				borderRadius: '50%',
				background: '#4D79EA',
				position: 'fixed',
				top: '45vh',
				left: '34px',
				border: '10px solid #F9FAFC',
				cursor: 'pointer',

				'*': {
					color: '#FFF',
				},

				'&:hover': {
					background: '#4D79EA',
				},
			}}
		>
			{props.children}
		</Button>
	);
};

export const Header = ({ sx = {}, children, dataTestId }) => {
	return (
		<Box
			data-testid={dataTestId}
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				position: 'relative',
				marginTop: '12px',
				zIndex: 3,
				background: '#f9fafc',
				padding: '10px 0px 0px -0',
				left: '50px',
				...sx,
			}}
		>
			{children}
		</Box>
	);
};

export const Title = props => {
	return (
		<Typography
			component={'h1'}
			sx={{
				margin: 0,
				fontFamily: 'Inter',
				fontStyle: 'normal',
				fontWeight: 600,
				fontSize: '24px',
				lineHeight: '29px',
				color: '#4D79EA',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
			}}
			{...props}
		>
			{props.children}
		</Typography>
	);
};

export const CustomSwitch = ({ children }) => {
	return (
		<Box
			sx={{
				minWidth: '96px',
				height: '35px',
				background: '#4D79EA',
				boxShadow: '2px 0px 2px rgba(0, 0, 0, 0.05)',
				borderRadius: '10px',
				marginLeft: '44px',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',

				label: {
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: 500,
					fontSize: '12px',
					lineHeight: '15px',
					color: '#FFFFFF',
					margin: '0px 12px',
				},
			}}
		>
			{children}
		</Box>
	);
};

export const Knob = props => {
	return (
		<Box
			{...props}
			sx={{
				minWidth: '39px',
				height: '35px',
				background: '#FFFFFF',
				boxShadow: '2px 0px 2px rgba(0, 0, 0, 0.05)',
				borderRadius: '10px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				cursor: 'pointer',

				'*': {
					fontSize: '24px',
					color: '#4D79EA !important',
				},

				span: {
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: 500,
					fontSize: '12px !important',
					lineHeight: '15px',
					color: '#4D79EA',
					margin: '0px 10px 0px 25px',
				},
			}}
		>
			{props.children}
		</Box>
	);
};

export const SideNavLinkWrapper = ({ children }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				paddingTop: '20px',
				width: '100%',
				height: '50%',
			}}
		>
			{children}
		</Box>
	);
};

export const SideNavLink = props => {
	return (
		<Box
			{...props}
			sx={{
				width: '36px',
				height: '38px',
				padding: '5px 10px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				cursor: 'pointer',
				borderRight: `${props.active ? '4px solid #4D79EA' : '0px'}`,
				marginBottom: '20px',

				'*': {
					fontSize: '24px',
					color: '#4D79EA',
				},
			}}
		>
			{props.children}
		</Box>
	);
};

export const Sublink = props => {
	return (
		<Box
			sx={{
				background: 'transparent',
				border: '1px solid #FFF',
				borderRadius: '5px',
				margin: '2px',
				height: '28px',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				color: '#4D79EA',
				padding: '3px',

				span: {
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: 600,
					fontSize: '12px',
					lineHeight: '15px',
					margin: '0px 10px',
				},

				'&:hover': {
					background: 'rgba(77, 121, 234, 0.05)',
					border: '1px solid rgba(77, 121, 234, 0.5)',
					cursor: 'pointer',
				},
			}}
			{...props}
		>
			{props.children}
		</Box>
	);
};

export const DivImage = props => {
	return (
		<Box
			sx={{
				borderRadius: '50%',
				width: '30px',
				height: '30px',
				backgroundSize: 'cover !important',
				marginLeft: '6px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				h1: {
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: 500,
					fontSize: '12px',
					lineHeight: '15px',
					color: 'rgba(0, 0, 0, 0.6)',
					margin: 0,
					textAlign: 'center',
				},
				...props.sx,
			}}
			{...props}
		>
			{props.children}
		</Box>
	);
};

export const HeaderPanel = props => {
	return (
		<Box
			{...props}
			sx={{
				overflowX: 'auto',
				overflowY: 'hidden',
				width: props.showDetails ? '69vw' : '90vw',
				height: '100px',
				position: 'absolute',
				boxShadow: '0 0 20px 0 rgba(33, 88, 119, 0.1)',
				background: 'white',
				borderRadius: '20px',
				left: '22px',
				top: '0px',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				padding: '0px',

				'.left': {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					margin: '0px 44px',

					h2: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: 700,
						fontSize: '25px',
						lineHeight: '30px',
						margin: '0px',
						color: '#4D79EA',
					},

					h5: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: 500,
						fontSize: '20px',
						lineHeight: '24px',
						color: 'rgba(0, 0, 0, 0.9)',
						marginLeft: '20px',
					},
				},

				'.divider': {
					width: '1px',
					height: '60%',
					background: 'rgba(196, 196, 196, 0.4)',
				},

				'.right': {
					label: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: 500,
						fontSize: '14px',
						lineHeight: '17px',
						color: 'rgba(0, 0, 0, 0.6)',
					},

					span: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: 400,
						fontSize: '14px',
						lineHeight: '17px',
						color: 'rgba(0, 0, 0, 0.9)',
						marginTop: '10px',
					},
				},

				'.center': {
					label: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: 500,
						fontSize: '14px',
						lineHeight: '17px',
						color: 'rgba(0, 0, 0, 0.6)',
					},

					span: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: 400,
						fontSize: '14px',
						lineHeight: '17px',
						color: 'rgba(0, 0, 0, 0.9)',
						marginTop: '10px',
					},
				},
			}}
		>
			{props.children}
		</Box>
	);
};

export const MainContainer = props => {
	return (
		<Box
			sx={{
				width: props.showDetails ? '69vw' : '90vw',
				position: 'absolute',
				left: '22px',
				top: '110px',
				display: 'flex',
				flexDirection: 'row',
			}}
		>
			{props.children}
		</Box>
	);
};

export const Card = props => {
	return (
		<Box
			{...props}
			sx={{
				boxShadow: '0 0 20px 0 rgba(33, 88, 119, 0.1)',
				padding: '20px',
				borderRadius: '20px',
				margin: '0px 10px 10px 10px',
				background: '#FFFFFF',
				display: 'flex',
				flexDirection: 'column',

				'& > div:first-child': {
					margin: '0px !important',

					'.header': {
						border: '0px !important',
					},

					'.body': {
						border: '0px !important',
					},
				},

				'& > a': {
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: 400,
					fontSize: '14px',
					lineHeight: '17px',
					textAlign: 'center',
					color: '#4D79EA',
					margin: '20px auto 0px auto',
					cursor: 'pointer',
					textDecoration: 'none',
				},
				...props.sx,
			}}
		>
			{props.children}
		</Box>
	);
};

export const DPad = ({ children }) => {
	return (
		<Box
			sx={{
				width: '20px',
				height: '44.55px',
				background: '#FFFFFF',
				padding: '4px 3px',
				border: '1px solid rgba(77, 121, 234, 0.2)',
				boxShadow: '0px 4px 5px rgba(167, 215, 225, 0.33)',
				borderRadius: '8.5px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				alignItems: 'center',
				marginLeft: '33px',
			}}
		>
			{children}
		</Box>
	);
};

export const Timer = ({ children }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
				width: '155px',
				height: '46px',
				padding: '10px',
				boxSizing: 'border-box',
				background: 'rgba(77, 121, 234, 0.05)',
				borderRadius: '10px',

				span: {
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: 500,
					fontSize: '18px !important',
					lineHeight: '22px !important',
					letterSpacing: '0.02em',
					color: 'rgba(0, 0, 0, 0.70) !important',
				},
			}}
		>
			{children}
		</Box>
	);
};

export const StatusCircle = props => {
	return (
		<Box
			sx={{
				width: '13px',
				height: '13px',
				borderRadius: '50%',
				background: '#3BE775',
			}}
			{...props}
		/>
	);
};

export const WrapperStack = props => {
	return (
		<Stack
			{...props}
			sx={{
				position: 'absolute',
				top: '40px',
				right: 0,
				width: '249px',
				height: '166px',
				background: '#FFFFFF',
				filter: 'drop-shadow(0px 0px 20px rgba(33, 88, 119, 0.21))',
				borderRadius: '15px',
				zIndex: 60,
			}}
		/>
	);
};

export const UpperStack = props => {
	return (
		<Stack
			{...props}
			direction={'row'}
			alignItems={'center'}
			justifyContent={'space-between'}
			sx={{
				width: '209px',
				height: '47px',
				padding: '0 20px',
				background: '#FFFFFF',
				boxShadow: '0px 0px 20px rgba(33, 88, 119, 0.1)',
				borderRadius: '15px 15px 0 0',
			}}
		/>
	);
};

export const UserName = props => {
	return (
		<Typography
			{...props}
			sx={{
				fontWeight: '500',
				fontSize: '16px',
				lineHeight: '19px',
				color: 'rgba(0, 0, 0, 0.9)',
			}}
		/>
	);
};

export const LogoutContainer = props => {
	return (
		<Stack
			{...props}
			direction={'row'}
			alignItems={'center'}
			spacing={0.5}
			sx={{
				'&:hover': {
					cursor: 'pointer',
				},
			}}
		/>
	);
};

export const LogoutText = props => {
	return (
		<Typography
			{...props}
			sx={{
				fontWeight: '500',
				fontSize: '12px',
				lineHeight: '15px',
				color: 'rgba(0, 0, 0, 0.6)',
			}}
		/>
	);
};

export const LowerStack = props => {
	return (
		<Stack
			{...props}
			spacing={2}
			sx={{
				margin: 2,
			}}
		/>
	);
};

export const ItemsContainer = props => {
	return (
		<Stack
			{...props}
			direction={'row'}
			alignItems={'center'}
			spacing={0.5}
			sx={{
				'&:hover': {
					cursor: 'pointer',
				},
			}}
		/>
	);
};

export const MenuItemName = props => {
	return (
		<Typography
			{...props}
			sx={{
				fontWeight: '500',
				fontSize: '14px',
				lineHeight: '17px',
				color: 'rgba(0, 0, 0, 0.6)',
			}}
		/>
	);
};

export const SubTitle = props => {
	return (
		<Typography
			component={'h5'}
			sx={{
				fontFamily: 'Inter',
				fontSize: '18px',
				fontWeight: '500',
				fontStretch: 'normal',
				fontStyle: 'normal',
				lineHeight: 'normal',
				letterSpacing: 'normal',
				textAlign: 'left',
				color: '#4d79ea',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',

				'& > span': {
					marginRight: '11px',
				},
			}}
			{...props}
		>
			{props.children}
		</Typography>
	);
};

export const Badge = props => {
	return (
		<Box
			{...props}
			sx={{
				minWidth: '24px',
				height: '22px',
				flexGrow: 0,
				fontFamily: 'Inter',
				fontSize: '18px',
				fontWeight: 500,
				fontStretch: 'normal',
				fontStyle: 'normal',
				lineHeight: 'normal',
				letterSpacing: 'normal',
				textAlign: 'center',
				backgroundColor: 'rgba(0, 0, 0, 0.06)',
				borderRadius: '5px',
				padding: '2px',
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'row',
				justifyContent: 'center',

				span: {
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: 500,
					fontSize: '18px',
					lineHeight: '12px',
					letterSspacing: '0.5px',
					textTransform: 'uppercase',
					color: 'rgba(0, 0, 0, 0.6)',
					margin: 'auto',
				},
			}}
		>
			{props.children}
		</Box>
	);
};

export const LinkButton = props => {
	return (
		<Button
			variant="text"
			sx={{
				fontFamily: 'Inter',
				fontSize: '14px',
				fontWeight: '500',
				fontStretch: 'normal',
				fontStyle: 'normal',
				lineHeight: 'normal',
				letterSpacing: '0.5px',
				textAlign: 'center',
				color: '#4d79ea',
				marginBottom: '5px',
				padding: '8px 10px',
			}}
			{...props}
		>
			{props.children}
		</Button>
	);
};

export const CardOutline = ({ disabled, active, draggingOver, sx, ...props }) => {
	return (
		<Box
			sx={{
				width: '262px',
				height: '114px',
				margin: '13px 29px 0 -9px',
				padding: '8px 7.7px 12px 16px',
				borderRadius: '10px',
				border: `2px ${draggingOver ? 'dashed' : 'solid'} ${
					(active && !disabled) || draggingOver ? '#4D79EA' : 'rgba(196, 196, 196, 0.5)'
				}`,
				cursor: disabled ? 'not-allowed' : 'pointer',
				opacity: disabled ? '0.5' : '1',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				backgroundColor: draggingOver || (active && !disabled) ? '#EDF2FF' : '#FFF',

				h1: {
					fontFamily: 'Inter',
					fontSize: '16px',
					fontWeight: '500',
					fontStretch: 'normal',
					fontStyle: 'normal',
					lineHeight: '0.75',
					letterSpacing: 'normal',
					textAlign: 'left',
					color: 'rgba(0, 0, 0, 0.87)',
					marginBottom: 0,
				},

				span: {
					fontFamily: 'Inter',
					fontSize: '12px',
					fontWeight: 'normal',
					fontStretch: 'normal',
					fontStyle: 'italic',
					lineHeight: '1',
					letterSpacing: 'normal',
					textAlign: 'left',
					color: 'rgba(0, 0, 0, 0.6)',
				},
				...sx,
			}}
			{...props}
		>
			{props.children}
		</Box>
	);
};

export const AddRoleCard = props => {
	return (
		<Box
			{...props}
			sx={{
				background: '#FFFFFF',
				border: '1px dashed rgba(196, 196, 196, 0.5)',
				borderRadius: '10px',
				height: '44px',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
				margin: '5px 37px',

				'&:hover': {
					cursor: 'pointer',
					border: '1px dashed #4D79EA !important',

					'& > svg': {
						color: '#4D79EA !important',
					},
				},
			}}
		>
			{props.children}
		</Box>
	);
};

export const RoleActionWrapper = props => {
	return (
		<Box
			sx={{
				margin: 'auto',
				width: '200px',
				height: '56px',
				bottom: '10px',
				left: '18%',
				bottom: '2%',
				background: '#4D79EA',
				boxShadow: '0px 30px 50px rgba(0, 0, 0, 0.21)',
				borderRadius: '31px',
				position: 'absolute',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-evenly',
				'-webkit-animation': 'fadeSlideLeft 2s both',
				'-moz-animation': 'fadeSlideLeft 2s both',
				'-o-animation': 'fadeSlideLeft 2s both',
				animation: 'fadeSlideLeft 2s both',

				span: {
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: 600,
					fontSize: '14px',
					lineHeight: '22px',
					color: '#FFFFFF',
					textTransform: 'uppercase',
					alignSelf: 'center',
				},

				'.right': {
					height: '23px',
					padding: '0px 10px',
					borderLeft: '1px solid #94B9FF',
					display: 'flex',
					flexDirection: 'row',
				},
			}}
			{...props}
		>
			{props.children}
		</Box>
	);
};

export const Tag = props => {
	return (
		<Box
			sx={{
				margin: '0px 4px 0px 0px',
				height: '31px',
				maxHeight: '31px',
				border: '1px solid #4D79EA',
				borderRadius: '16px',
				background: 'rgba(77, 121, 234, 0.05)',
				padding: '0px 8px',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',

				'& > span': {
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: 500,
					fontSize: '12px',
					lineHeight: '15px',
					color: '#4D79EA',
					marginRight: '9px',
				},
			}}
		>
			{props.children}
		</Box>
	);
};

export const RoleSideWidget = props => {
	return (
		<Box
			sx={{
				height: '100vh',
				width: '583px',
				overflow: 'hidden',
				background: '#FFF',
				position: 'fixed',
				top: '0px',
				right: '0px',
				zIndex: 10,
				display: 'flex',
				flexDirection: 'column',
				boxShadow: '0 0 20px 0 rgba(33, 88, 119, 0.1)',
				transform: `translateX(${props.isOpen ? '0' : '100%'})`,
				transition: 'transform 0.3s ease-in-out',

				'.row': {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',

					'.col': {
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						flex: 1,
					},
				},

				'& > .wrapper-footer': {
					height: '46px',
					padding: '10px 18px',
					justifyContent: 'normal',
					background: '#FFF',
					zIndex: 2,
				},

				'& > .header': {
					background: '#F9FAFC',
					height: '139px',
					padding: '12px 24px 0px 24px',
					display: 'flex',
					flexDirection: 'column',

					'.left': {
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',

						span: {
							fontFamily: 'Inter',
							fontSize: '14px',
							fontWeight: '500',
							fontStretch: 'normal',
							fontStyle: 'normal',
							lineHeight: 'normal',
							letterSpacing: 'normal',
							textAlign: 'left',
							color: 'rgba(0, 0, 0, 0.6)',
							marginLeft: '10px',
						},
					},

					'.main': {
						flex: 1,
						display: 'flex',
						alignItems: 'center',

						span: {
							fontFamily: 'Inter',
							fontSize: '12px',
							fontWeight: 'normal',
							fontStretch: 'normal',
							fontStyle: 'italic',
							lineHeight: '1',
							letterSpacing: 'normal',
							textAlign: 'left',
							color: 'rgba(0, 0, 0, 0.6)',
						},

						'.rounded-shadow': {
							width: '105px',
							height: '105px',
							background: '#FFF',
							borderRadius: '50%',
							position: 'absolute',
							margin: '115px auto 0px 215px',
							zIndex: 0,
							boxShadow: '0 -4px 20px 0 rgba(33, 88, 119, 0.1)',
						},
					},
				},
			}}
			{...props}
		>
			{props.children}
		</Box>
	);
};

export const SmallCircle = props => {
	return (
		<Box
			sx={{
				width: '30px',
				height: '30px',
				borderRadius: '50%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				background: 'rgba(77, 121, 234, 0.2)',
				marginRight: '11px',
			}}
			{...props}
		>
			{props.children}
		</Box>
	);
};

export const OutlineWrapper = props => {
	return (
		<Box
			sx={{
				border: '1px solid #E5E5E5',
				borderRadius: '10px',
				flex: 1,
				margin: '24px',
				display: 'flex',
				flexDirection: 'column',
				maxHeight: 'calc(100vh - 200px)',

				'& > .wrapper-header': {
					height: '46px',
					padding: '0px 18px',
					justifyContent: 'normal',
					borderBottom: '1px solid #E5E5E5',
				},
				'& > .wrapper-footer': {
					height: '46px',
					padding: '10px 18px',
					justifyContent: 'normal',
					borderTop: '1px solid #E5E5E5',
				},

				'.header': {
					display: 'flex',
					alignItems: 'center',
					padding: '10px 18px',
					backgroundColor: '#FFF',
					borderBottom: 'solid 1px rgba(196, 196, 196, 0.4)',
					borderTopLeftRadius: '10px',
					borderTopRightRadius: '10px',
					flexDirection: 'row !important',

					'.left': {
						flex: 1,
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',

						'.colored-icon': {
							width: '30px',
							height: '30px',
							flexGrow: 0,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: '100%',
						},

						'.error-icon': {
							backgroundColor: 'rgba(211, 73, 164, 0.2)',
							'*': {
								color: '#d349a4',
							},
						},

						'.info-icon': {
							backgroundColor: 'rgba(29, 187, 255, 0.2)',
							'*': {
								color: '#1DBBFF',
							},
						},

						'.teal-icon': {
							backgroundColor: 'rgba(47, 214, 173, 0.2)',
							'*': {
								color: '#2FD6AD',
							},
						},

						'.success-icon': {
							backgroundColor: 'rgba(23, 180, 67, 0.2)',
							'*': {
								color: '#17B443',
							},
						},

						'.primary-icon': {
							backgroundColor: 'rgba(61, 85, 215, 0.2)',
							'*': {
								color: '#3D55D7',
							},
						},

						'.pink-icon': {
							backgroundColor: 'rgba(169, 106, 207, 0.2)',
							'*': {
								color: '#A96ACF',
							},
						},

						'.warning-icon': {
							backgroundColor: 'rgba(245, 181, 56, 0.2)',
							'*': {
								color: '#F5B538',
							},
						},
					},

					h2: {
						margin: '0px 0px 0px 10px !important',
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '18px',
						lineHeight: '22px',
						color: 'rgba(0, 0, 0, 0.9)',
					},
				},
				'.body': {
					label: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '12px',
						lineHeight: '15px',
						marginBottom: '10px',
						color: 'rgba(0, 0, 0, 0.6)',
					},
					span: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '400',
						fontSize: '14px',
						lineHeight: '17px',
						color: 'rgba(0, 0, 0, 0.9)',
					},
				},
			}}
			{...props}
		>
			{props.children}
		</Box>
	);
};

export const VerticalDivider = props => {
	return (
		<Box
			sx={{
				height: '23px',
				width: '1px',
				backgroundColor: '#C4C4C4',
			}}
			{...props}
		/>
	);
};

export const RoleItem = props => {
	return (
		<Box
			sx={{
				flex: 1,
				display: 'flex',
				padding: '10px 15px 11px 16px',
				borderRadius: '10px',
				border: `solid 1px ${props.active ? '#4D79EA' : 'rgba(196, 196, 196, 0.5)'}`,
				background: `${props.active ? '#EDF2FF' : 'inherit'}`,
				cursor: 'grab',
				marginLeft: props.noMargin ? '0px' : '37px',

				'& .item-body': {
					flex: 1,
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				},

				'&:hover': {
					cursor: props.cursor || 'pointer',
					background: '#EDF2FF',
				},
			}}
		>
			{props.children}
		</Box>
	);
};

export const RoleBadge = props => {
	return (
		<Typography
			sx={{
				padding: '7px 9px 8px',
				borderRadius: '5px',
				backgroundColor: props.editMode ? '#4D79EA' : '#f3f3f3',
				fontFamily: 'Inter',
				fontStyle: 'normal',
				fontWeight: 500,
				fontSize: '14px',
				lineHeight: '17px',
				textAlign: 'center',
				color: props.editMode ? '#FFF' : 'rgba(0, 0, 0, 0.6)',
				marginRight: props.mr ? '10px' : '0px',
				marginBottom: '5px',
			}}
			{...props}
		>
			{props.children}
		</Typography>
	);
};

export const OKRCard = props => {
	return (
		<Box
			sx={{
				width: '267px',
				height: '138px',
				borderRadius: 5,
				position: 'relative',
				background: 'linear-gradient(to right, #8eddc9, #eee)',
				marginTop: '20px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',

				'& > .body': {
					background: '#FFF',
					width: 'calc(100% - 1.5px)',
					minHeight: 'calc(100% - 1px)',
					borderRadius: 5,
					display: 'flex',
					flexDirection: 'column',
				},
			}}
		>
			<Box className="body">
				<Box
					sx={{
						padding: '10px 15px',
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					{props.children}
				</Box>
			</Box>
		</Box>
	);
};

export const DropBoxWrapper = styled.div`
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

export const OKRSpan = styled.span`
	mb: 15px;
	font-family: Inter;
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;
	overflow: hidden !important;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`;

export const KanbanWrapper = props => {
	return (
		<Box
			sx={{
				width: 'calc(100% - 24px)',
				height: '80vh',
				maxHeight: '80vh',
				overflowY: 'hidden',
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				overflowX: 'auto',
				borderRadius: '20px',
				left: '24px',
				top: '16px',
				...props.sx,
			}}
			{...props}
		>
			{props.children}
		</Box>
	);
};

export const ColoredChip = props => {
	return (
		<Box
			sx={{
				width: 'fit-content',
				background: 'rgba(33, 173, 218, 0.05)',
				borderRadius: '15px',
				padding: '6px 10px',
			}}
		>
			{props.labels.map(label => (
				<Typography
					key={label}
					sx={{
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '600',
						fontSize: '12px',
						lineHeight: '15px',
						textAlign: 'start',
						letterSpacing: '1px',
						textTransform: 'uppercase',
						color: '#21ADDA',
					}}
				>
					{label}
				</Typography>
			))}
		</Box>
	);
};
