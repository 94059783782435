import React, { useState } from 'react';

import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

// icons
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FaxIcon from '@mui/icons-material/Fax';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

import _ from 'lodash';

// component
import Svg from '@worklist-2/ui/src/components/Svg/Svg';
import StatusIndicator from './StatusIndicator/StatusIndicator';
import { SnapStack } from '../../views/MarketplaceAppDetailView/partials/SnapStack';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const StatusIndicatorsStyles = {
	boxStyle: {
		padding: '0px 11px 11px 11px',
	},
	dividerStyle: {
		font: 'Roboto',
		fontWeight: 400,
		fontSize: '10px',
		color: 'rgba(255, 255, 255, 0.6)',
	},
	iconStyle: {
		color: 'rsPrimary.main',
		fontSize: 15,
	},
};

export const getDefaultIndicators = translateFn => {
	const maven2186ChargePosting = useBooleanFlagValue('maven-2186-charge-posting');

	return [
		{
			name: translateFn('Access'),
			icon: (
				<Svg
					data-testid="AccessIcon"
					name="access"
					sx={{
						fontSize: 15,
					}}
				/>
			),
			checked: true,
			hide: true,
		},
		{
			name: translateFn('Pre-auth'),
			icon: (
				<FingerprintIcon
					sx={{
						...StatusIndicatorsStyles.iconStyle,
					}}
				/>
			),
			checked: true,
			hide: false,
		},
		{
			name: translateFn('Eligibility'),
			icon: (
				<AccountBalanceOutlinedIcon
					sx={{
						...StatusIndicatorsStyles.iconStyle,
					}}
				/>
			),
			checked: true,
			hide: false,
		},
		{
			name: translateFn('Payment'),
			icon: (
				<CreditCardIcon
					sx={{
						...StatusIndicatorsStyles.iconStyle,
					}}
				/>
			),
			checked: true,
			hide: true,
		},
		{
			name: translateFn('Fax'),
			icon: (
				<FaxIcon
					sx={{
						...StatusIndicatorsStyles.iconStyle,
					}}
				/>
			),
			checked: true,
			hide: true,
		},
		{
			name: translateFn('Teaching'),
			icon: (
				<SchoolOutlinedIcon
					sx={{
						...StatusIndicatorsStyles.iconStyle,
					}}
				/>
			),
			checked: true,
			hide: true,
		},
		{
			name: 'Charge-posting',
			icon: (
				<Svg
					data-testid="chargePostPending"
					name="chargePostPending"
					sx={{
						fontSize: 20,
					}}
				/>
			),
			checked: false,
			hide: !maven2186ChargePosting,
		},
	];
};

const StatusIndicators = ({ columnId, onChange, t }) => {
	const defaultIndicators = getDefaultIndicators(t);

	const generateIndicatorsList = () => {
		if (columnId?.statusIndicators) {
			_.forEach(columnId.statusIndicators, i => {
				_.forEach(defaultIndicators, item => {
					if (item.name === i.name) {
						item.checked = i.checked;
					}
				});
			});
		}

		return defaultIndicators.filter(i => i.hide !== true);
	};

	const [indicators, setIndicators] = useState(generateIndicatorsList);

	const indicatorOnClick = index => {
		const indicatorList = [...indicators];
		indicatorList[index].checked = !indicatorList[index].checked;

		if (onChange) {
			const list = _.map(indicatorList, item => ({
				name: item.name,
				checked: item.checked,
			}));
			onChange(list);
		}
		setIndicators(indicatorList);
	};

	return (
		<Box sx={{ ...StatusIndicatorsStyles.boxStyle }}>
			<Divider
				sx={{
					...StatusIndicatorsStyles.dividerStyle,
				}}
			>
				{t('statusIndicators')}
			</Divider>
			<SnapStack direction="row" gap={1.5} mt={0.5}>
				{indicators?.map((indicator, index) => (
					<StatusIndicator
						key={indicator.name}
						handleClick={() => {
							indicatorOnClick(index);
						}}
						indicator={indicator}
						translateFn={t}
					/>
				))}
			</SnapStack>
		</Box>
	);
};

StatusIndicators.defaultProps = {};

StatusIndicators.propTypes = {};

export default StatusIndicators;
