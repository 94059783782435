// @flow

// core
import React from 'react';
// components
import Grid from '@mui/material/Grid';
import { LoaderSkeleton, type ISkeletonProps } from './LoaderSkeleton';

/** Skeleton loader used in `PatientDocuments.jsx` */
export const LoaderPatientDocuments = ({ children, isLoading }: ISkeletonProps): any =>
	isLoading ? (
		<Grid container spacing="20px">
			{[1, 2, 3, 4].map(i => (
				<Grid key={`patient_document_skeleton_${i}`} item md={3} sm={6} sx={{ minWidth: '248px' }} xs={3}>
					<LoaderSkeleton sx={{ height: '164px' }} />
				</Grid>
			))}
		</Grid>
	) : (
		children
	);
