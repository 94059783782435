import { PanTool, StackScrollTool, ToolGroupManager, WindowLevelTool, ZoomTool } from '@cornerstonejs/tools';
import StackScrollMouseWheelTool from '../cornerstoneTools/StackScrollToolMouseWheelTool';
import { MouseBindings } from '@cornerstonejs/tools/dist/esm/enums';

const toolGroupId = 'imageViewerExternal';

const createToolGroup = () => {
	const toolGroup = ToolGroupManager.createToolGroup(toolGroupId);

	toolGroup.addTool(StackScrollMouseWheelTool.toolName);
	toolGroup.addTool(PanTool.toolName);
	toolGroup.addTool(WindowLevelTool.toolName);
	toolGroup.addTool(ZoomTool.toolName);

	
	return toolGroup;
};

const createToolGroupWithoutRemap = ({ isMobile }) => {
	const toolGroup = createToolGroup();
	
	toolGroup.addTool(StackScrollTool.toolName);

	toolGroup.setToolActive(StackScrollMouseWheelTool.toolName);
	if (!isMobile) {
		toolGroup.setToolActive(WindowLevelTool.toolName, {
			bindings: [{ mouseButton: MouseBindings.Primary }],
		});
	}
	toolGroup.setToolActive(ZoomTool.toolName, {
		bindings: [{ mouseButton: MouseBindings.Secondary, numTouchPoints: 2 }],
	});
	toolGroup.setToolActive(PanTool.toolName, {
		bindings: [{ mouseButton: MouseBindings.Auxiliary }],
	});
	toolGroup.setToolActive(StackScrollTool.toolName, {
		bindings: [{ numTouchPoints: 1 }],
	});
};

const createToolGroupWithRemap = () => {
	const toolGroup = createToolGroup();

	toolGroup.addTool(StackScrollTool.toolName);
	
	toolGroup.setToolActive(WindowLevelTool.toolName, {
		bindings: [{ mouseButton: MouseBindings.Secondary }],
	});
	toolGroup.setToolActive(ZoomTool.toolName, {
		bindings: [{ mouseButton: MouseBindings.Auxiliary, numTouchPoints: 2 }],
	});
	toolGroup.setToolActive(PanTool.toolName, {
		bindings: [{ mouseButton: MouseBindings.Primary_And_Secondary }],
	});
	toolGroup.setToolActive(StackScrollTool.toolName, {
		bindings: [{ mouseButton: MouseBindings.Primary, numTouchPoints: 1 }],
	});
};

export { toolGroupId, createToolGroupWithoutRemap as createToolGroup, createToolGroupWithoutRemap, createToolGroupWithRemap };