import axios from 'axios';

const getOrgOrderSets = async ({ __config, orgId, pageNumber = 1, count = 50, searchString }) => {
	const response = await axios.get(
		`${
			__config.data_sources.blume
		}Organization/orderSets?InternalOrganizationId=${orgId}&PageNumber=${pageNumber}&Count=${count}${
			searchString ? `&SearchString=${searchString}` : ``
		}`,
		{
			headers: { 'X-API-KEY': window.btoa(__config.patient_portal.authentication_key) },
		}
	);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default getOrgOrderSets;
