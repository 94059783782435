import React from 'react';

const HomeIcon = ({ fill }) => (
	<svg fill="none" height="17" viewBox="0 0 20 17" width="20" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10 2.69L15 7.19V15H13V9H7V15H5V7.19L10 2.69ZM10 0L0 9H3V17H9V11H11V17H17V9H20L10 0Z"
			fill={fill || 'white'}
			fillOpacity={fill ? 1 : 0.6}
		/>
	</svg>
);

export default HomeIcon;
