import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const GridCount = ({ count, className }) => <div className={classnames(className, 'pagination')}>{count || '0'}</div>;

GridCount.propTypes = {
	/**
	 * Total number of table rows
	 */
	count: PropTypes.number,
};

export default GridCount;
