import React from 'react';

const AddIcon = () => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<circle cx="12" cy="12" fill="#4D79EA" r="12" />
		<path
			d="M18.8549 12.9774H12.9774V18.8549H11.0182V12.9774H5.14062V11.0182H11.0182V5.14062H12.9774V11.0182H18.8549V12.9774Z"
			fill="white"
		/>
	</svg>
);

export default AddIcon;
