import axios from 'axios';

const addContact = async ({ __config, data }) => {
	const response = await axios.put(`${__config.data_sources.blume}/User/contact`, data);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default addContact;
