import React, { useEffect, useState, createRef } from 'react';
import { useNavigate } from 'react-router';
import _ from 'lodash';

import { Box, Tab, Tabs, CircularProgress, Typography, IconButton, TextField, Select, MenuItem } from '@mui/material';

import CropFreeIcon from '@mui/icons-material/CropFree';
import LastPageIcon from '@mui/icons-material/LastPage';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

import { styled } from '@mui/material/styles';

import { DivImage } from '../../../../views/CrmView/styles';
import { InfoRow, Title, Wrapper } from '../casePopOverStyles';

import Overview from './DetailTabs/Overview';
import Comments from './DetailTabs/Comments';
import Contacts from './DetailTabs/Contacts';
import History from './DetailTabs/History';

import { useCrmContext } from '../../../../context/CRMContext';

import { caseMapping, crmSearchScopes, useCRMDataLoader, useCRMSearchScope, useAuth } from '@worklist-2/core/src';

import Suggest from '@worklist-2/ui/src/components/Suggest/Suggest';

import { PRIORITIES, CaseRecordTypes } from '../utils';

import Avatar from '@worklist-2/ui/src/assets/img/crmUserImg.png';

const ref = createRef();

export const DetailTabs = styled(Tabs)({
	'& .MuiTabs-indicator': {
		backgroundColor: '#4D79EA',
	},
});

export const DetailTab = styled(props => <Tab disableRipple {...props} />)(({ theme }) => ({
	textTransform: 'none',
	minWidth: 0,
	padding: '16px 12px 0px 16px',
	color: '#c4c4c4',
	[theme.breakpoints.up('sm')]: {
		minWidth: 0,
	},
	fontWeight: theme.typography.fontWeightRegular,
	marginRight: theme.spacing(1),
	fontFamily: [
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(','),
	'&:hover': {
		color: '#4D79EA',
		opacity: 1,
	},
	'&.Mui-selected': {
		color: '#4D79EA',
		fontWeight: theme.typography.fontWeightMedium,
	},
	'&.Mui-focusVisible': {
		backgroundColor: '#d1eaff',
	},
}));

const CaseDetailPopOver = ({ summaryData, fullData, setFullData }) => {
	const caseDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.caseApi,
	});

	const commentDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.commentApi,
	});

	const userDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.userApi,
	});

	const contactDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.contactApi,
	});

	const replaceOp = 'replace';
	const { loggedInUser } = useAuth();
	const [tabIndex, setTabIndex] = useState(0);
	const { validateCaseForm, handleOverViewTabChange } = useCRMSearchScope();
	const [sendPrivate, setSendPrivate] = useState(false);
	const { toggleDetails, setIsRefresh } = useCrmContext();
	const navigate = useNavigate();
	const [errMsgs, setErrMsgs] = useState({});
	const [isValidForm, setIsValidForm] = useState(true);
	const [hoveredField, setHoveredField] = useState(null);
	const [editableIndex, setEditableIndex] = useState(null);
	const [assignTo, setAssignTo] = useState(null);
	const [overViewData, setOverViewData] = useState({});
	const [commentData, setCommentData] = useState(null);
	const [attachments, setAttachments] = useState();
	const [watcherData, setWatcherData] = useState([]);
	const [watching, setWatching] = useState(false);
	const [progress, setProgress] = useState(0);
	const [loadingRow, setLoadingRow] = useState(false);
	const [data, setData] = useState({});
	const [contactData, setContactData] = useState([]);
	const [commonData, setCommonData] = useState({});

	const getAccessTabs = () => {
		const accessTabs = ['Overview', 'Contacts'];

		if (loggedInUser.permission.History.Read) {
			accessTabs.push('History');
		}

		if (!!loggedInUser.permission.Case.Read && !!loggedInUser.permission.Comment.Read) {
			accessTabs.push('Comments');
		}

		return accessTabs;
	};

	const tabs = getAccessTabs();

	useEffect(() => {
		fetchData().catch(console.error);
	}, [summaryData]);

	useEffect(() => {
		setContactData(
			_.map(data?.RelatedContact, contact => ({
				name: contact.FullName ? contact.FullName : contact.AccountName,
				jobTitle: contact.JobTitle,
				phone: contact.CellPhone,
				email: contact.Email,
			}))
		);

		setWatcherData(data?.Watcher ?? []);
	}, [data]);

	const fetchData = async loader => {
		const fnName = 'load';
		const bodyData = undefined;
		if (!loader) {
			loader = caseDataLoader;
		}

		setLoadingRow(true);
		setData(null);

		try {
			await loader[fnName]({ id: summaryData.CaseId }, !!bodyData || undefined, bodyData).then(result => {
				const newData = result;
				setData(newData);
				setLoadingRow(false);
			});
		} catch (e) {
			console.error(e);
			setLoadingRow(false);
		}
	};

	const handleTabChange = (event, newTabIndex) => {
		setTabIndex(newTabIndex);
	};

	const onChangeData = async (e, value, tab, payload) => {
		const targetId = e.target.id ? e.target.id : '';
		const targetParentId = e.target.parentElement?.id ? e.target.parentElement.id : '';
		let result = '';

		if (targetId.split('_')[0] === 'closeEditButton' || targetParentId.split('_')[0] === 'closeEditButton') {
			const caseData = overViewData;
			delete caseData[caseMapping[value]];
			if (value === 'account_name') {
				delete caseData[caseMapping.account_id];
			}
			setOverViewData(caseData);
			setEditableIndex(null);
			setErrMsgs({});
		} else if (targetId.split('_')[0] === 'submitButton' || targetParentId.split('_')[0] === 'submitButton') {
			if (
				validateCaseForm(overViewData, setErrMsgs, setIsValidForm, true) &&
				!!loggedInUser.permission.Case.Edit
			) {
				setEditableIndex(null);
				const caseJson = buildCaseJson(data, null, summaryData);
				const fieldName = [caseMapping[value]];
				const fieldValue = [caseJson[caseMapping[value]]];

				if (value === 'account_name') {
					fieldName.push(caseMapping.account_id);
					fieldValue.push(caseJson[caseMapping.account_id]);
				}

				if (value === 'duplicate_case_of') {
					fieldName.push(caseMapping.duplicate_case_of_id);
					fieldValue.push(caseJson[caseMapping.duplicate_case_of_id]);
				}

				if (value === 'parent_case') {
					fieldName.push(caseMapping.parent_case_id);
					fieldValue.push(caseJson[caseMapping.parent_case_id]);
				}

				if (value === 'project_name') {
					if (payload) {
						fieldName.push(caseMapping.project_name_id);
						fieldValue[0] = payload;
						fieldValue[1] = '';
					} else {
						fieldName.push(caseMapping.project_name_id);
						fieldValue.push(caseJson[caseMapping.project_name_id]);
					}
					setData(prev => ({ ...prev, ProjectName: fieldValue[0] }));
				}

				try {
					setIsRefresh(true);
					const updatedIndex = _.findIndex(fullData, { CaseId: caseJson.CaseId });
					fullData.splice(updatedIndex, 1, caseJson);
					setFullData(fullData);
					await new Promise(r => setTimeout(r, 500));
					setIsRefresh(false);
				} catch (error) {
					console.error(error);
				}

				result = await caseDataLoader.patch(data.CaseId, fieldName, fieldValue, replaceOp);
				setData(result);
			}
		} else if (targetId.split('_')[0] === 'submitComment' || targetParentId.split('_')[0] === 'submitComment') {
			if (commentData) {
				const commentJson = buildCommentJson(commentData, attachments, data, value);

				result = await commentDataLoader.save(null, commentJson, null, setProgress);
				setCommentData(null);
				setAttachments(null);
				setProgress(0);
			}
		} else if (targetId.split('_')[0] === 'submitWatcher' || targetParentId.split('_')[0] === 'submitWatcher') {
			if (loggedInUser.permission.Case.Edit) {
				const tempData = [...watcherData];
				if (isWatching()) {
					_.remove(tempData, watcher => watcher.UserId === loggedInUser.id);
				} else {
					tempData.push({
						UserName: loggedInUser.display,
						UserId: loggedInUser.id,
					});
				}

				setWatcherData(tempData);
				const caseJson = buildCaseJson(data, tempData, summaryData);
				const fieldName = ['Watcher'];
				const fieldValue = [caseJson.Watcher];
				result = await caseDataLoader.patch(data.CaseId, fieldName, fieldValue, replaceOp);
				setData(result);
			}
		} else if (tabs[tabIndex].toLowerCase().includes('overview')) {
			handleOverViewTabChange(e.target.name, e.target.value, overViewData, setOverViewData);
		} else if (tabs[tabIndex].toLowerCase().includes('contacts')) {
			handleContactTabChange(value);
		} else if (tabs[tabIndex].toLowerCase().includes('comments')) {
			handleCommentsTabChange(e.target.value);
		}

		return result;
	};

	const handleContactTabChange = async value => {
		let tempData = contactData;
		if (value.Index == undefined) {
			tempData.push(value);
			const contactJson = buildContactJson(value);
			if (value.id === undefined && !!value.name) {
				await contactDataLoader.save(null, contactJson);
			}
		} else if (value.Deleted) {
			tempData = _.filter(tempData, contact => !contact.Deleted);
		}
		setContactData(tempData);
	};

	const handleCommentsTabChange = value => {
		setCommentData(value);
	};

	const handleSubmit = async event => {
		event.preventDefault();
		if (validateCaseForm(overViewData, setErrMsgs, setIsValidForm, true) && !!loggedInUser.permission.Case.Edit) {
			const caseJson = buildCaseJson(data, null, summaryData);
			setEditableIndex(null);
			const fieldName = [];
			const fieldValue = [];
			if (
				document.activeElement.value === 'contactSubmit' ||
				document.activeElement.value === 'submitNewContact'
			) {
				fieldName.push('RelatedContact');
				fieldValue.push(caseJson.RelatedContact);
				caseDataLoader.patch(data.CaseId, fieldName, fieldValue, replaceOp);
			} else if (document.activeElement.value === 'assignedSubmit' || document.activeElement.id === 'assigner') {
				fieldName.push('AssignedTo', 'AssignedToId');
				fieldValue.push(caseJson.AssignedTo, caseJson.AssignedToId);
				caseDataLoader.patch(data.CaseId, fieldName, fieldValue, replaceOp);
			} else {
				const submitField = document.activeElement.id;

				fieldName.push(caseMapping[submitField]);
				fieldValue.push(caseJson[caseMapping[submitField]]);

				if (submitField === 'account_name') {
					fieldName.push(caseMapping.account_id);
					fieldValue.push(caseJson[caseMapping.account_id]);
				}

				if (submitField === 'duplicate_case_of') {
					fieldName.push(caseMapping.duplicate_case_of_id);
					fieldValue.push(caseJson[caseMapping.duplicate_case_of_id]);
				}

				if (submitField === 'parent_case') {
					fieldName.push(caseMapping.parent_case_id);
					fieldValue.push(caseJson[caseMapping.parent_case_id]);
				}

				if (submitField === 'project_name') {
					fieldName.push(caseMapping.project_name_id);
					fieldValue.push(caseJson[caseMapping.project_name_id]);
				}

				try {
					setIsRefresh(true);
					const updatedIndex = _.findIndex(fullData, { CaseId: caseJson.CaseId });
					fullData.splice(updatedIndex, 1, caseJson);
					setFullData(fullData);
					await new Promise(r => setTimeout(r, 500));
					setIsRefresh(false);
				} catch (error) {
					console.error(error);
				}

				caseDataLoader.patch(caseJson.CaseId, fieldName, fieldValue, replaceOp);
			}
		}
	};

	const buildCommentJson = (inputComment, inputAttachments, caseData, parentCommentId) => ({
		CaseId: caseData.CaseId,
		CaseNumber: caseData.CaseNumber,
		Description: inputComment,
		ResourceType: 'Comment',
		ParentCommentId: parentCommentId,
		IsPrivate: sendPrivate,
		Attachments: inputAttachments,
	});

	const buildCaseJson = (caseData, watchers, summaryCase) => {
		const caseJson = caseData;

		caseJson.AssignedTo = assignTo ? assignTo.AssignedTo : caseData.AssignedTo;
		caseJson.AssignedToId = assignTo ? assignTo.AssignedToId : caseData.AssignedToId;

		caseJson.Priority = commonData.Priority ?? caseData.Priority;
		summaryCase.Priority = caseJson.Priority;

		caseJson.CaseRecordType = commonData.CaseRecordType ?? caseData.CaseRecordType;
		summaryCase.CaseRecordType = caseJson.CaseRecordType;

		caseJson.CaseOrigin = commonData.CaseOrigin ?? caseData.CaseOrigin;
		summaryCase.CaseOrigin = caseJson.CaseOrigin;

		for (const item in overViewData) {
			switch (item) {
				case 'ImpactPatientCase':
					caseJson.ImpactPatientCase = overViewData.ImpactPatientCase?.toLowerCase() === 'yes';
					break;
				case 'ClosedDateTime':
					if (overViewData.ClosedDateTime) {
						caseJson.ClosedDateTime = new Date(overViewData.ClosedDateTime).toISOString();
					}
					break;
				default:
					caseJson[item] = overViewData[item];
					summaryCase[item] = overViewData[item];
			}
		}

		caseJson.RelatedContact = _.map(
			_.filter(contactData, contact => {
				if (!contact.Deleted) {
					return {
						FullName: contact.name,
						JobTitle: contact.jobTitle,
						CellPhone: contact.phone,
						Email: contact.email,
					};
				}
			}),
			contact => ({
				FullName: contact.name,
				JobTitle: contact.jobTitle,
				CellPhone: contact.phone,
				Email: contact.email,
			})
		);

		caseJson.Watcher = watchers ?? watcherData;
		return caseJson;
	};

	const buildContactJson = contactItem => ({
		FullName: contactItem.name,
		JobTitle: contactItem.jobTitle,
		CellPhone: contactItem.phone,
		Email: contactItem.email,
	});

	useEffect(() => {
		if (!loadingRow && data) {
			const ignoreClickOnMeElement = ref.current;

			const listener = event => {
				const isClickInsideElement = ignoreClickOnMeElement?.contains(event.target);

				const customButton = [
					'hoverEditButton',
					'closeEditButton',
					'contactEditButton',
					'submitNewContact',
					'closeNewContact',
				];

				if (
					!isClickInsideElement &&
					!event.target.id &&
					!customButton.includes(event.target.id.split('_')[0]) &&
					!customButton.includes(event.target.parentElement?.id.split('_')[0]) &&
					!event.target?.getAttribute('class')?.split(' ')?.includes('MuiBackdrop-invisible') &&
					!document.activeElement.className?.split(' ')?.includes('MuiMenuItem-root')
				) {
					toggleDetails();
				}
			};
			document.addEventListener('click', listener, true);

			return () => {
				document.removeEventListener('click', listener, true);
			};
		}
	}, []);

	const isEditField = field => editableIndex === field;

	const handleUserSelect = (value, searchValue, optionId, record) => {
		if (record) {
			setAssignTo({
				AssignedTo: record.UserName,
				AssignedToId: record.UserId,
			});
		} else {
			setAssignTo({});
		}
	};

	useEffect(() => {
		setWatching(isWatching());
	}, [watcherData]);

	const isWatching = () => _.some(watcherData, watcher => watcher.UserId === loggedInUser.id);

	return (
		<form onSubmit={handleSubmit}>
			<Wrapper ref={ref} className="invisible-scrollbar" id="popover-wrapper">
				{!loadingRow && data ? (
					<Wrapper className="invisible-scrollbar" id="popover-wrapper">
						<div className="header">
							<div className="actions">
								{isEditField('assignedToName') ? (
									<div className="left">
										{data?.AssignedTo ? (
											<DivImage
												style={{
													marginLeft: 0,
													background: `url(${Avatar})`,
												}}
											/>
										) : (
											<AccountCircleOutlinedIcon
												sx={{
													color: 'rgba(0, 0, 0, 0.4)',
													fontSize: '36px',
												}}
											/>
										)}
										<Suggest
											disableClearable
											hideIcon
											showAvatar
											summary
											customDataLoader={userDataLoader}
											disableSearch={!loggedInUser.permission?.User.Read}
											disabled={false}
											listSearchScope={{
												label: 'UserName',
												searchKey: 'name',
											}}
											optionId="UserName"
											size="small"
											style={{
												marginLeft: '5px',
											}}
											testid="assigner"
											value={data?.AssignedTo}
											variant="standard"
											width={209}
											onSelect={handleUserSelect}
										/>
										<IconButton
											sx={{
												padding: '7px 2px 5px 2px',
											}}
											type="submit"
											value="assignedSubmit"
										>
											<CheckCircleIcon
												sx={{
													color: '#0047ff',
													fontSize: '22px',
													marginLeft: 'auto',
												}}
											/>
										</IconButton>
										<IconButton
											sx={{
												marginTop: '5px',
												padding: '7px 2px 5px 2px',
											}}
											onClick={() => {
												setEditableIndex(null);
												setAssignTo(null);
											}}
										>
											<CloseIcon
												sx={{
													color: '#0047ff',
												}}
											/>
										</IconButton>
									</div>
								) : (
									<div className="left" onMouseLeave={() => setHoveredField(null)}>
										{data?.AssignedTo ? (
											<>
												<DivImage
													style={{
														marginLeft: 0,
														background: `url(${Avatar})`,
														cursor: loggedInUser.permission.Case.Edit ? 'pointer' : '',
													}}
													onClick={() => {
														if (loggedInUser.permission.Case.Edit) {
															setEditableIndex('assignedToName');
														}
													}}
													onMouseEnter={() => setHoveredField('assignedToName')}
												/>
												<span
													style={{
														cursor: loggedInUser.permission.Case.Edit ? 'pointer' : '',
													}}
													onClick={() => {
														if (loggedInUser.permission.Case.Edit) {
															setEditableIndex('assignedToName');
														}
													}}
													onMouseEnter={() => setHoveredField('assignedToName')}
												>
													{data?.AssignedTo}
												</span>
											</>
										) : (
											<>
												<AccountCircleOutlinedIcon
													sx={{
														color: 'rgba(0, 0, 0, 0.4)',
														fontSize: '36px',
														cursor: loggedInUser.permission.Case.Edit ? 'pointer' : '',
													}}
													onClick={() => {
														if (loggedInUser.permission.Case.Edit) {
															setEditableIndex('assignedToName');
														}
													}}
													onMouseEnter={() => setHoveredField('assignedToName')}
												/>
												<span
													style={{
														cursor: loggedInUser.permission.Case.Edit ? 'pointer' : '',
													}}
													onClick={() => {
														if (loggedInUser.permission.Case.Edit) {
															setEditableIndex('assignedToName');
														}
													}}
													onMouseEnter={() => setHoveredField('assignedToName')}
												>
													Unassigned
												</span>
											</>
										)}
										{hoveredField === 'assignedToName' && !!loggedInUser.permission.Case.Edit && (
											<IconButton
												sx={{
													p: 0,
													marginLeft: '5px',
												}}
												type="button"
												onClick={() => setEditableIndex('assignedToName')}
											>
												<EditOutlinedIcon
													id="hoverEditButton_assignedToName"
													sx={{
														color: 'rgba(0, 0, 0, 0.6)',
														fontSize: '18px',
														marginLeft: 'auto',
													}}
												/>
											</IconButton>
										)}
									</div>
								)}
								<div className="right">
									<IconButton sx={{ color: 'rgba(0, 0, 0, 0.6)' }} type="button">
										{watching ? (
											<VisibilityIcon
												id="submitWatcher"
												onClick={e => {
													onChangeData(e);
												}}
											/>
										) : (
											<VisibilityOutlinedIcon
												id="submitWatcher"
												onClick={e => {
													onChangeData(e);
												}}
											/>
										)}
									</IconButton>
									<IconButton
										sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
										onClick={() => {
											navigate(`/case/${data.CaseId}`);
											toggleDetails();
										}}
									>
										<CropFreeIcon />
									</IconButton>
									<IconButton sx={{ color: 'rgba(0, 0, 0, 0.6)' }} onClick={() => toggleDetails()}>
										<LastPageIcon />
									</IconButton>
								</div>
							</div>
							<Title>
								{editableIndex === 'priority' ? (
									<div style={{ display: 'flex' }}>
										<select
											name="priority"
											style={{
												border: 'none',
												fontFamily: 'Inter',
												fontSize: '25px',
												fontWeight: 'bold',
												fontStretch: 'normal',
												fontStyle: 'normal',
												color: '#4d79ea',
											}}
											onChange={e => {
												commonData.Priority = e.target.value;
												setCommonData(commonData);
											}}
										>
											{PRIORITIES.map(item => (
												<option selected={data.Priority == item.value ? 'selected' : ''}>
													{item.value}
												</option>
											))}
										</select>
										<IconButton
											data-testid="submitButton_priority"
											id="submitButton_priority"
											sx={{ padding: '8px 0px 8px 0px' }}
											type="button"
											onClick={e => {
												onChangeData(e, 'priority');
											}}
										>
											<CheckCircleIcon
												sx={{
													pointerEvents: 'none',
													color: '#0047ff',
													fontSize: '22px',
													marginLeft: '3px',
												}}
											/>
										</IconButton>
										<IconButton
											id="closeEditButton_priority"
											sx={{ padding: '7px 2px 5px 2px' }}
											type="button"
										>
											<CloseIcon
												id="closeEditButton_priority"
												sx={{ color: '#0047ff' }}
												onClick={e => {
													setEditableIndex(null);
													commonData.Priority = data.Priority;
													setCommonData(commonData);
												}}
											/>
										</IconButton>
									</div>
								) : (
									<div
										style={{ display: 'flex' }}
										onMouseEnter={() => setHoveredField('priority')}
										onMouseLeave={() => setHoveredField(null)}
									>
										<span
											className="priority"
											style={{
												cursor: loggedInUser.permission.Case.Edit ? 'pointer' : '',
											}}
											onClick={() => {
												if (loggedInUser.permission.Case.Edit) {
													setEditableIndex('priority');
												}
											}}
										>
											{data.Priority || '-'}
										</span>
										{hoveredField === 'priority' && !!loggedInUser.permission.Case.Edit && (
											<IconButton
												id={'closeEditButton_Edit_' + 'priority'}
												sx={{ p: 0, marginLeft: '5px' }}
												type="button"
												onClick={e => {
													setEditableIndex('priority');
												}}
											>
												<EditOutlinedIcon
													sx={{
														color: 'rgba(0, 0, 0, 0.6)',
														fontSize: '24px',
														marginLeft: 'auto',
													}}
												/>
											</IconButton>
										)}
									</div>
								)}
								<span onMouseEnter={() => setHoveredField('caseName')}>{data.CaseName}</span>
							</Title>
							<InfoRow>
								<div className="col">
									<label style={{ height: '34px' }}>Case Number</label>
									<span style={{ margin: 0 }} onMouseEnter={() => setHoveredField('caseNumber')}>
										{data.CaseNumber}
									</span>
								</div>
								<div className="col">
									<label style={{ height: '34px', width: '100px' }}>Case Record Type</label>
									{editableIndex === 'caseRecordType' ? (
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'row',
												marginTop: '2px',
												fontSize: '14px',
											}}
										>
											<Select
												defaultValue={data.CaseRecordType}
												id="caseRecordType"
												name="caseRecordType"
												style={{
													border: 'none',
													fontFamily: 'Inter',
												}}
												sx={{
													'& legend': {
														display: 'none',
													},
													'& fieldset': {
														top: 0,
													},
													height: '40px',
													width: '120px',
													fontSize: '12px',
													fontStretch: 'normal',
													fontStyle: 'normal',
													flex: '1',
													borderRadius: 3,
												}}
												onChange={e => {
													commonData.CaseRecordType = e.target.value;
													setCommonData(commonData);
												}}
											>
												{CaseRecordTypes.map(selectItem => (
													<MenuItem key={selectItem.value} value={selectItem.value}>
														{selectItem.name}
													</MenuItem>
												))}
											</Select>
											<IconButton
												data-testid="submitButton_case_record_type"
												id="submitButton_case_record_type"
												sx={{
													paddingRight: 0,
												}}
												type="button"
												value="commonSubmit"
												onClick={e => {
													onChangeData(e, 'case_record_type');
												}}
											>
												<CheckCircleIcon
													sx={{
														pointerEvents: 'none',
														color: '#0047ff',
														fontSize: '22px',
														marginLeft: 'auto',
													}}
												/>
											</IconButton>
											<IconButton
												id="closeEditButton_case_record_type"
												sx={{
													padding: '5px 1px 1px 1px',
													marginLeft: '1px',
												}}
												type="button"
											>
												<CloseIcon
													id="closeEditButton_case_record_type"
													sx={{
														fontSize: '22px',
														color: '#0047ff',
														marginBottom: '5px',
													}}
													onClick={e => {
														setEditableIndex(null);
														commonData.CaseRecordType = data.CaseRecordType;
														setCommonData(commonData);
													}}
												/>
											</IconButton>
										</Box>
									) : (
										<div
											style={{ display: 'flex' }}
											onMouseEnter={() => setHoveredField('caseRecordType')}
											onMouseLeave={() => setHoveredField(null)}
										>
											<span
												style={{ margin: 0 }}
												onClick={() => {
													setEditableIndex('caseRecordType');
												}}
												onMouseEnter={() => setHoveredField('caseRecordType')}
											>
												{data.CaseRecordType || '-'}
											</span>
											{hoveredField === 'caseRecordType' &&
												!!loggedInUser.permission.Case.Edit && (
													<IconButton
														id="closeEditButton_Edit_caseRecordType"
														sx={{ p: 0, marginLeft: '5px' }}
														type="button"
														onClick={() => {
															setEditableIndex('caseRecordType');
														}}
													>
														<EditOutlinedIcon
															sx={{
																color: 'rgba(0, 0, 0, 0.6)',
																fontSize: '16px',
																marginLeft: 'auto',
															}}
														/>
													</IconButton>
												)}
										</div>
									)}
								</div>
								<div className="col">
									<label style={{ height: '34px' }}>Date/Time Opened</label>
									<span
										style={{ margin: 0, width: '140px' }}
										onMouseEnter={() => setHoveredField('dateOpened')}
									>
										{data.CreatedDateTime
											? `${new Date(data.CreatedDateTime).toLocaleDateString()} ${new Date(
													data.CreatedDateTime
											  ).toLocaleTimeString(navigator.language, {
													hour: '2-digit',
													minute: '2-digit',
											  })}`
											: '-'}
									</span>
								</div>
								<div className="col">
									<label style={{ height: '34px' }}>Case Origin</label>
									{editableIndex === 'caseOrigin' ? (
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'row',
												marginTop: '2px',
												fontSize: '14px',
											}}
										>
											<TextField
												InputProps={{
													endAdornment: (
														<IconButton
															data-testid="submitButton_caseOrigin"
															id="submitButton_caseOrigin"
															sx={{
																paddingRight: 0,
															}}
															type="button"
															value="commonSubmit"
															onClick={e => {
																onChangeData(e, 'case_origin');
															}}
														>
															<CheckCircleIcon
																sx={{
																	pointerEvents: 'none',
																	color: '#0047ff',
																	fontSize: '22px',
																	marginLeft: 'auto',
																}}
															/>
														</IconButton>
													),
												}}
												autoFocus={editableIndex === 'caseOrigin'}
												data-testid="caseOrigin"
												defaultValue={data.CaseOrigin}
												name="caseOrigin"
												size="small"
												sx={{
													'.MuiFormHelperText-root': {
														fontSize: '10px',
													},
													'.MuiOutlinedInput-root': {
														paddingRight: '5px',
														height: '35px',
														borderRadius: 2,
													},
													height: '30px',
													'& legend': {
														display: 'none',
													},
													'& fieldset': {
														top: 0,
													},
													flex: '1',
													width: '70px',
												}}
												variant="outlined"
												onChange={e => {
													commonData.CaseOrigin = e.target.value;
													setCommonData(commonData);
												}}
											/>
											<IconButton
												id="closeEditButton_caseOrigin"
												sx={{
													padding: '5px 1px 1px 1px',
													marginLeft: '1px',
												}}
												type="button"
											>
												<CloseIcon
													id="closeEditButton_caseOrigin"
													sx={{
														fontSize: '22px',
														color: '#0047ff',
													}}
													onClick={e => {
														setEditableIndex(null);
														commonData.CaseOrigin = data.CaseOrigin;
														setCommonData(commonData);
													}}
												/>
											</IconButton>
										</Box>
									) : (
										<div
											style={{ display: 'flex' }}
											onMouseEnter={() => setHoveredField('caseOrigin')}
											onMouseLeave={() => setHoveredField(null)}
										>
											<span
												style={{ margin: 0 }}
												onClick={() => {
													setEditableIndex('caseOrigin');
												}}
												onMouseEnter={() => setHoveredField('caseOrigin')}
											>
												{data.CaseOrigin || '-'}
											</span>
											{hoveredField === 'caseOrigin' && !!loggedInUser.permission.Case.Edit && (
												<IconButton
													id="closeEditButton_Edit_case_origin"
													sx={{ p: 0, marginLeft: '5px' }}
													type="button"
													onClick={() => {
														setEditableIndex('caseOrigin');
													}}
												>
													<EditOutlinedIcon
														sx={{
															color: 'rgba(0, 0, 0, 0.6)',
															fontSize: '16px',
															marginLeft: 'auto',
														}}
													/>
												</IconButton>
											)}
										</div>
									)}
								</div>
							</InfoRow>
						</div>
						<div className="body">
							<Box sx={{ margin: '0px 36px' }}>
								<DetailTabs
									scrollButtons={false}
									value={tabIndex}
									variant="scrollable"
									onChange={handleTabChange}
								>
									{_.map(tabs, (tab, i) => (
										<DetailTab key={i} label={tab} />
									))}
								</DetailTabs>
							</Box>
							<Box style={{ flex: 1, paddingBottom: 20 }}>
								{tabs[tabIndex].toLowerCase().includes('overview') && (
									<Overview
										data={data}
										editableIndex={editableIndex}
										errorMsgs={errMsgs}
										hoveredField={hoveredField}
										isValidForm={isValidForm}
										setEditableIndex={setEditableIndex}
										setHoveredField={setHoveredField}
										onChangeData={onChangeData}
									/>
								)}
								{tabs[tabIndex].toLowerCase().includes('comments') &&
									!!loggedInUser?.permission?.Case?.Read &&
									!!loggedInUser?.permission?.Comment?.Read && (
										<Comments
											attachments={attachments}
											caseData={data}
											commentData={commentData}
											progress={progress}
											sendPrivate={sendPrivate}
											setAttachments={setAttachments}
											setCommentData={setCommentData}
											setProgress={setProgress}
											setSendPrivate={setSendPrivate}
											onChangeData={onChangeData}
										/>
									)}
								{tabs[tabIndex].toLowerCase().includes('contacts') && (
									<Contacts
										canEdit={!!loggedInUser.permission.Case.Edit}
										data={contactData}
										handleSubmit={handleSubmit}
										onChangeData={onChangeData}
									/>
								)}
								{tabs[tabIndex].toLowerCase().includes('history') &&
									!!loggedInUser.permission.History.Read && <History caseData={data} />}
							</Box>
						</div>
					</Wrapper>
				) : (
					<Box
						sx={{
							height: '100%',
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							paddingBottom: '100px',
							boxSizing: 'border-box',
						}}
					>
						<Typography
							sx={{
								textAlign: 'center',
								fontSie: '16px',
								marginBottom: '8px',
								color: 'black',
							}}
						>
							<CircularProgress
								className="progressBar"
								size={22}
								sx={{
									color: '#03dac5',
									marginRight: '16px',
								}}
							/>
							Loading...
						</Typography>
					</Box>
				)}
			</Wrapper>
		</form>
	);
};

export default CaseDetailPopOver;
