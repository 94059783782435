import { getSchema, isPrimitive } from '../datatypes';
import { validateArgs, validatePrimitive } from './validateArgs';
import { buildDataType } from '../utils';

const buildExtensionFunc = args => {
	const {
		url,
		id,
		extension,
		valueBase64Binary,
		valueBoolean,
		valueCanonical,
		valueCode,
		valueDate,
		valueDateTime,
		valueDecimal,
		valueId,
		valueInstant,
		valueInteger,
		valueMarkdown,
		valueOid,
		valuePositiveInt,
		valueString,
		valueTime,
		valueUnsignedInt,
		valueUri,
		valueUrl,
		valueUuid,
		valueAddress,
		valueAge,
		valueAnnotation,
		valueAttachment,
		valueCodeableConcept,
		valueCoding,
		valueContactPoint,
		valueCount,
		valueDistance,
		valueDuration,
		valueHumanName,
		valueIdentifier,
		valueMoney,
		valuePeriod,
		valueQuantity,
		valueRange,
		valueRatio,
		valueReference,
		valueSampledData,
		valueSignature,
		valueTiming,
		valueContactDetail,
		valueContributor,
		valueDataRequirement,
		valueExpression,
		valueParameterDefinition,
		valueRelatedArtifact,
		valueTriggerDefinition,
		valueUsageContext,
		valueDosage,
		valueMeta,
	} = args;
	// we can only include one, so just include the first one
	let argsKey = Object.keys(args)[1],
		valid = validatePrimitive('url', url),
		schema = argsKey.slice('value'.length),
		isPrimitiveValue = isPrimitive(schema.charAt(0).toLowerCase() + schema.slice(1)),
		value;

	if (isPrimitiveValue) {
		valid = valid && validatePrimitive(schema.charAt(0).toLowerCase() + schema.slice(1), args[argsKey]);
		value = `"${args[argsKey]}"`;
	} else {
		valid = valid && validateArgs(getSchema(schema), args[argsKey], Object.keys(args[argsKey]));
		value = buildDataType(schema, args[argsKey]);
	}
	return JSON.parse(`{"url":"${url}", "${argsKey}": ${JSON.stringify(value)}, "__valid": ${valid}}`);
};

export default buildExtensionFunc;
