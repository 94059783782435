import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, Typography } from '@mui/material';

import { accessTimeOutlinedIconAdornment } from '../FormNew/formUtils/formMappingUtils';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import { useTranslation } from 'react-i18next';

import {
	TimeSelectorButtonsWrap,
	TimeSelectorInputsWrap,
	TimeInputsDivider,
	inputProps,
	menuItemStyles,
	menuItemHeaderStyles,
} from './styles';

const MIN_MINUTE_VALUE = 1;
const MAX_MINUTE_VALUE = 59;

const convertToMinutes = (hours, minutes) => {
	const hoursInMinutes = (parseInt(hours, 10) || 0) * 60;
	return hoursInMinutes + (parseInt(minutes, 10) || 0);
};

const TimeSelectorInput = ({ value, helperText, onChange }) => (
	<TextField
		data-testid={`${helperText}-input`}
		helperText={helperText}
		inputProps={inputProps}
		type="number"
		value={value}
		variant="outlined"
		onChange={onChange}
		onClick={e => e.stopPropagation()}
	/>
);

const TimeSelector = ({
	label,
	onDone,
	valueHours = 0,
	valueMinutes = 0,
	disabled = false,
	textPostfix = true,
	inputHeight,
	isEditing = true,
	sx,
}) => {
	const { t } = useTranslation(['form', 'datetimerangepicker']);
	const [tmpHours, setHours] = useState(valueHours);
	const [tmpMinutes, setMinutes] = useState(valueMinutes);
	const [outputValue, setOutputValue] = useState('');
	const [hoursAndMinutes, setHoursAndMinutes] = useState({ hours: valueHours, minutes: valueMinutes });

	const [open, setOpen] = useState(false);

	const handleClose = () => {
		defineOutputValue(hoursAndMinutes?.hours, hoursAndMinutes?.minutes);
		setOpen(false);
	};

	const defineOutputValue = (hours, minutes) => {
		if (!hours && !minutes) {
			setOutputValue('');
		} else {
			const _outputValue = getOutputValue(hours, minutes);
			setOutputValue(_outputValue);
		}
		const newHoursAndMinutes = { hours, minutes };
		setHoursAndMinutes(newHoursAndMinutes);
		setHours(hours);
		setMinutes(minutes);
	};

	const getOutputValue = (hours, minutes) =>
		textPostfix
			? `${hours || 0} ${t('datetimerangepicker:hours')} ${minutes || 0} ${t('datetimerangepicker:minutes')}`
			: `${hours.toString().padStart(2, '0') || 0}:${minutes.toString().padStart(2, '0') || 0}`;

	const handleSubmit = e => {
		e.stopPropagation();
		const total = convertToMinutes(tmpHours, tmpMinutes);
		onDone(total);
		defineOutputValue(tmpHours, tmpMinutes);
		setOpen(false);
	};

	useEffect(() => {
		setHours(valueHours);
		setMinutes(valueMinutes);
		defineOutputValue(valueHours, valueMinutes);
	}, [valueHours, valueMinutes]);

	const onMinuteInputChange = e => {
		let value = parseInt(e.target.value, 10);

		if (value > MAX_MINUTE_VALUE) value = MAX_MINUTE_VALUE;
		if (value < MIN_MINUTE_VALUE && value !== 0) value = MIN_MINUTE_VALUE;

		setMinutes(value || '');
	};

	const onHoursInputChange = e => {
		let value = parseInt(e.target.value, 10);

		if (value < MIN_MINUTE_VALUE && value !== 0) value = MIN_MINUTE_VALUE;

		setHours(value || '');
	};

	const onOpen = () => {
		if (!isEditing) return;
		setOpen(true);
	};

	return (
		<TextField
			select
			SelectProps={{
				renderValue: val => val,
				onClose: handleClose,
				open,
				onOpen,
				...accessTimeOutlinedIconAdornment,
			}}
			data-testid="time-selector"
			disabled={isEditing ? disabled : true}
			helperText={disabled ? t('Readonly') : ''}
			label={label}
			sx={
				inputHeight
					? {
							width: '100%',
							'& .MuiOutlinedInput-root': {
								height: inputHeight,
							},
							'& .MuiFormHelperText-root': {
								color: 'rgba(255, 255, 255, 0.36) !important',
							},
							...sx,
					  }
					: {
							width: '100%',
							'& .MuiFormHelperText-root': {
								color: 'rgba(255, 255, 255, 0.36) !important',
								marginLeft: 0,
							},
							...sx,
					  }
			}
			value={outputValue}
		>
			<MenuItem data-testid="time-selector-menu-item" sx={menuItemStyles}>
				<Typography sx={menuItemHeaderStyles}>{t('datetimerangepicker:enterTime')}</Typography>
				<TimeSelectorInputsWrap>
					<TimeSelectorInput
						helperText={t('datetimerangepicker:Hour')}
						value={tmpHours}
						onChange={onHoursInputChange}
					/>
					<TimeInputsDivider>:</TimeInputsDivider>
					<TimeSelectorInput
						helperText={t('datetimerangepicker:Minute')}
						value={tmpMinutes}
						onChange={onMinuteInputChange}
					/>
				</TimeSelectorInputsWrap>
				<TimeSelectorButtonsWrap>
					<SecondaryButton label={t('datetimerangepicker:Cancel')} onClick={handleClose} />
					<PrimaryButton label={t('datetimerangepicker:Done')} onClick={handleSubmit} />
				</TimeSelectorButtonsWrap>
			</MenuItem>
		</TextField>
	);
};

export default TimeSelector;
