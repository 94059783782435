import axios from 'axios';

const unshareDICOM = async ({ __config, internalManagingOrgId, id, patientId }) => {
	const response = await axios.patch(
		`${__config.data_sources.blume}Study/dicomUnshare?internalManagingOrgId=${internalManagingOrgId}&InternalStudyId=${id}&internalPatientID=${patientId}`
	);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default unshareDICOM;
