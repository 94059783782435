import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const ConfigContext = createContext({});

export const ConfigProvider = ({ children }) => {
	const wonEnableHeap = useBooleanFlagValue('won-enable-heap');
	const __config = useMemo(() => JSON.parse(process.env.NODE_CONFIG), []);

	useEffect(() => {
		// inject window.location.origin (ie: https://www.omegaai.com)
		for (const property in __config.data_sources) {
			__config.data_sources[property] = __config.data_sources[property].replace(
				'<BASE_URL>',
				window.location.origin
			);
		}

		const heapScript = document.createElement('script');

		const googleMapsApiScript = document.createElement('script');
		googleMapsApiScript.src = `https://maps.googleapis.com/maps/api/js?key=${__config.google_map_api_key}&libraries=places`;
		document.body.appendChild(googleMapsApiScript);

		if (wonEnableHeap && __config.heap_app_id) {
			// heap
			heapScript.src = `https://cdn.heapanalytics.com/js/heap-${__config.heap_app_id}.js`;
			document.body.appendChild(heapScript);
			window.heap = window.heap || [];
		}

		return () => {
			document.body.removeChild(googleMapsApiScript);
			if (wonEnableHeap && __config.heap_app_id) {
				document.body.removeChild(heapScript);
			}
		};
	}, [__config, wonEnableHeap]);

	useEffect(() => {
		function createMethod(eventName) {
			return function () {
				heap.push([eventName].concat(Array.prototype.slice.call(arguments, 0)));
			};
		}
		if (window.heap && __config.heap_app_id) {
			window.heap.appId = __config.heap_app_id;
			window.heap.config = {};

			const methods = [
				'addEventProperties',
				'addUserProperties',
				'clearEventProperties',
				'identify',
				'resetIdentity',
				'removeEventProperty',
				'setEventProperties',
				'track',
				'unsetEventProperty',
			];

			for (let i = 0; i < methods.length; i++) {
				heap[methods[i]] = createMethod(methods[i]);
			}
		}
	}, [window.heap, __config.heap_app_id]);

	return <ConfigContext.Provider value={__config}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => useContext(ConfigContext);
export default ConfigProvider;
