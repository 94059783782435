import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, IconButton, Collapse } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import { useConfig, useAuth } from '@worklist-2/core/src';
import { useCrmContext } from '../../../../../context/CRMContext';
import ToggleSwitch from '../../../LiveChat/ToggleSwitch';
import LastPageIcon from '@mui/icons-material/LastPage';
import BusinessIcon from '@mui/icons-material/Business';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

import { Collapsible, TabPanel, Wrapper, FeatureItemBox, SubTitle } from '../../../Cases/casePopOverStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CircularProgress from '@mui/material/CircularProgress';
import { defaultFeatures } from '../../../Cases/utils';

const Features = ({ accountData, orgData, setOrganizationData, setStep }) => {
	const __config = useConfig();
	const { toggleDetails, setSelectedFeature, currentFeatures, setCurrentFeatures } = useCrmContext();
	const [collapsedId, setCollapsed] = useState(null);
	const [features, setFeatures] = useState(currentFeatures || []);
	const { authorized } = useAuth();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function asynchronousEffect() {
			if (authorized && !currentFeatures) {
				await getOrganizationFeatures().catch(console.error);
			}
		}

		asynchronousEffect();
	}, [authorized, orgData.id]);

	const getOrganizationFeatures = async () => {
		setLoading(true);
		await axios
			.get(`${__config.data_sources.breeze}/OrganizationOmegaAI/${accountData?.AccountId}/${orgData.id}/features`)
			.then(resp => {
				if (resp?.status === 200) {
					if (
						resp.data &&
						JSON.parse(resp.data).FeatureExtJson != null &&
						JSON.parse(resp.data).FeatureExtJson.length > 0
					) {
						setFeatures(JSON.parse(resp.data)?.FeatureExtJson);
					} else {
						setFeatures(defaultFeatures);
					}
				}
			});
		setLoading(false);
	};

	const handleTogglePermission = currentFeature => {
		const payload = {
			FeatureExtJson: features.map(feature => {
				if (feature.resource !== currentFeature) {
					return feature;
				}

				if (feature.action.includes('read')) {
					feature.action = feature.action.filter(action => action !== 'read');
				} else {
					feature.action.push('read');
				}

				return feature;
			}),
		};

		setFeatures(payload.FeatureExtJson);
		return true;
	};

	const onCollapsed = id => {
		setCollapsed(collapsedId === id ? null : id);
	};

	const saveOrganizationFeatures = async () => {
		let IsSuccess = false;
		const payload = {
			FeatureExtJson: features,
		};

		await axios
			.put(
				`${__config.data_sources.breeze}/OrganizationOmegaAI/${accountData?.AccountId}/${orgData.id}/features`,
				payload
			)
			.then(resp => {
				if (resp?.status === 200) {
					IsSuccess = true;
				}
			});

		return IsSuccess;
	};

	return (
		<form>
			<Wrapper className="invisible-scrollbar">
				<div className="header" style={{ backgroundColor: '#FFF', padding: '5px 15px' }}>
					<div className="actions">
						<div className="left">
							<IconButton
								onClick={() => {
									setStep('license');
								}}
							>
								<ArrowBackIcon />
							</IconButton>
						</div>
						<div className="right">
							<IconButton
								onClick={() => {
									setStep(null);
									setOrganizationData(null);
									toggleDetails();
								}}
							>
								<LastPageIcon />
							</IconButton>
						</div>
					</div>
				</div>
				<div className="body" style={{ position: 'relative' }}>
					<TabPanel className="invisible-scrollbar" style={{ margin: '0px 23px' }}>
						<div className="header">
							<div className="left">
								<div className="colored-icon info-icon">
									<BusinessIcon sx={{ fontSize: 20 }} />
								</div>
								<h2>Select Features</h2>
							</div>
							<div className="right">
								<div className="colored-icon active-icon">
									<BusinessIcon sx={{ fontSize: 10 }} />
								</div>
								<span className="active-span" />
								<div className="colored-icon active-icon">
									<CardTravelIcon sx={{ fontSize: 10 }} />
								</div>
								<span className="active-span" />
								<div className="colored-icon active-icon">
									<ToggleOnOutlinedIcon sx={{ fontSize: 10 }} />
								</div>
								<span className="gray-span" />
								<div className="colored-icon gray-icon">
									<DeviceHubIcon sx={{ fontSize: 10 }} />
								</div>
								<span className="gray-span" />
								<div className="colored-icon gray-icon">
									<FolderOutlinedIcon sx={{ fontSize: 10 }} />
								</div>
							</div>
						</div>
						<div className="body">
							<Collapsible>
								<CollapseHandle
									collapsedId={collapsedId}
									id="features"
									label="Features"
									onCollapsed={onCollapsed}
								/>
								<Collapse in={collapsedId !== 'features'}>
									<div className="colored-icon gray-icon">
										<Box
											sx={{
												marginTop: '10px',
												paddingRight: '6px',
												width: '100%',
												overflowY: 'auto',
											}}
										>
											{!loading ? (
												features.map(item => (
													<FeatureItem
														key={item.display}
														data={item}
														onClick={() => {
															setCurrentFeatures(features);
															setSelectedFeature(item);
															setStep('subfeatures');
														}}
														onToggle={handleTogglePermission}
													/>
												))
											) : (
												<Box
													sx={{
														height: '100%',
														width: '100%',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														flexDirection: 'column',
														paddingBottom: '100px',
														boxSizing: 'border-box',
													}}
												>
													<Typography
														sx={{
															textAlign: 'center',
															fontSie: '16px',
															marginBottom: '8px',
															color: 'black',
														}}
													>
														<CircularProgress
															className="progressBar"
															size={22}
															sx={{
																color: '#03dac5',
																marginRight: '16px',
															}}
														/>
														Loading...
													</Typography>
												</Box>
											)}
										</Box>
									</div>
								</Collapse>
								<div>&nbsp;</div>
							</Collapsible>
						</div>
					</TabPanel>
					<div className="footer" style={{ position: 'absolute', width: '490px', marginLeft: '23px' }}>
						<button
							className="outline"
							data-testid="cancelButton"
							type="button"
							onClick={() => {
								setStep('license');
							}}
						>
							GO BACK
						</button>
						<div className="right">
							<button
								className="fill"
								data-testid="saveButton"
								type="button"
								value="saveFeatures"
								onClick={() => {
									saveOrganizationFeatures();
									setStep('upload'); //device
								}}
							>
								SAVE
							</button>
						</div>
					</div>
				</div>
			</Wrapper>
		</form>
	);
};

const CollapseHandle = ({ id, collapsedId, label, onCollapsed }) => (
	<div className="handle" onClick={() => onCollapsed(id)}>
		<span>{label}</span>
		{collapsedId === id ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
	</div>
);

const FeatureItem = ({ data, onClick, onToggle }) => {
	const [isActive, setIsActive] = useState(data.action.includes('read'));
	const [isFetch, setIsFetch] = useState(false);

	const handleSwitchClick = permission => {
		setIsFetch(true);
		const IsSuccess = onToggle(permission);

		if (IsSuccess) {
			setIsActive(prev => !prev);
		}
		setIsFetch(false);
	};

	return (
		<FeatureItemBox>
			<Box
				className="item-body"
				sx={{
					opacity: 1,
				}}
			>
				<ToggleSwitch disabled={isFetch} isActive={isActive} onClick={() => handleSwitchClick(data.resource)} />
				<Box
					sx={{
						flex: 1,
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-end',

						span: {
							fontFamily: 'Inter',
							fontSize: '12px',
							fontWeight: 'normal',
							fontStretch: 'normal',
							fontStyle: 'italic',
							lineHeight: '1',
							letterSpacing: 'normal',
							textAlign: 'left',
							color: 'rgba(0, 0, 0, 0.6)',
							marginRight: '20px',
						},
					}}
				>
					<SubTitle sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '16px', flex: 1, marginLeft: '10px' }}>
						{data.display}
					</SubTitle>
					<span>{`${
						data.permissions.filter(item => item.action?.length > 0)?.length
					} permissions defined`}</span>
					<SettingsIcon
						sx={{
							fontSize: '18px',
							color: '#4D79EA',
							marginRight: '10px',
							cursor: 'pointer',
						}}
						onClick={onClick}
					/>
				</Box>
			</Box>
		</FeatureItemBox>
	);
};

export default Features;
