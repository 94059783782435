import React from 'react';

import styles from './BulletListBlock.module.scss';

const BulletListBlock = ({ theme, block, setDisplayMode, isSelected }) => (
	<div
		className={`${styles.bulletListBlock} ${isSelected ? styles.bulletListBlockSelected : ''}`}
		data-testid="bulletListBlock"
		onMouseOver={() => setDisplayMode && setDisplayMode({ type: 'hover' })}
	>
		<span className={theme === 'OAI' ? styles.bulletListOAI : styles.bulletListBlume} data-testid="bulletList">
			{block.text}
		</span>
	</div>
);

export default BulletListBlock;
