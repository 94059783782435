import React, { useEffect, useMemo, useState } from 'react';
import { Box, Icon, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import hotkeyIcons from '../hotkeys/hotkeyIcons';
import hotkeyCodes from '../hotkeys/hotkeyCodes';

const HotKeyIcon = ({ hotkey }) => {
	const key = hotkeyIcons[hotkey];

	if (!key) {
		return null;
	}

	return (
		<Icon
			component={key.icon}
			sx={{
				color: 'rgba(255, 255, 255, 0.6)',
				width: 'auto',
				height: 'auto',
				maxHeight: '20px',
				...key.sx,
			}}
		/>
	);
};

export const HotKeyText = ({ item }) => {
	const { hotkey } = item;
	const key = hotkeyIcons[hotkey];

	let res = '';

	if (item.ctrlKey) res += 'Ctrl + ';

	if (item.altKey) res += 'Alt + ';

	if (item.metaKey) res += 'Meta + ';

	if (item.shiftKey) res += 'Shift + ';

	if (key) {
		return res + key.text;
	}

	return 'None Assigned';
};

export const HotKeyInputItem = ({ isDuplicated, isRestricted, item = {}, onKeyBind }) => {
	const [active, setActive] = useState(false);

	const assigned = useMemo(() => item?.hotkey !== undefined, [item?.hotkey]);

	const getBorder = () => {
		if (isDuplicated || isRestricted) {
			return '1px solid #CF6679';
		}

		if (active) {
			return '1px solid #42A5F5';
		}

		if (assigned) {
			return '1px solid rgba(255, 255, 255, 0.23)';
		}

		return '1px solid #3c3c3c';
	};

	useEffect(() => {
		const onKeydown = e => e.preventDefault();

		function handler(e) {
			setActive(false);
			onKeyBind(e);
		}

		if (active) {
			window.addEventListener('keyup', handler);
			window.addEventListener('keydown', onKeydown);
		} else {
			window.removeEventListener('keyup', handler);
			window.removeEventListener('keydown', onKeydown);
		}

		return () => {
			window.removeEventListener('keyup', handler);
			window.removeEventListener('keydown', onKeydown);
		};
	}, [active]);

	return (
		<Box
			sx={{
				border: getBorder(),
				borderRadius: '5px',
				display: 'flex',
				alignItems: 'center',
				marginRight: '14px',
				width: '227px',
				height: '52px',
				boxSizing: 'border-box',
				position: 'relative',
				cursor: 'pointer',
			}}
			onClick={() => setActive(true)}
		>
			{active && (
				<Box
					sx={{
						borderRadius: '6px',
						background: '#393939',
						border: '1px solid rgba(255, 255, 255, 0.1)',
						boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
						padding: '12px',
						position: 'absolute',
						top: 'calc(100% + 4px)',
						left: '-1px',
						zIndex: '1',
						width: 'calc(100% + 2px)',
						boxSizing: 'border-box',
					}}
				>
					<Typography
						sx={{
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.25px',
							color: (isDuplicated || isRestricted) && active ? '#CF6679' : 'rgba(255, 255, 255, 0.87)',
							display: 'flex',
						}}
					>
						{(isDuplicated || isRestricted) && active && (
							<ErrorOutlineIcon
								sx={{
									color: '#CF6679',
									marginRight: '6px',
								}}
							/>
						)}

						{(isDuplicated || isRestricted) && active
							? `${isDuplicated ? 'Duplicated' : 'Restricted'} key assigned, please choose a new key`
							: 'Click any mouse or keyboard button to assign a new key'}
					</Typography>
				</Box>
			)}
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '56px',
					height: '100%',
					background: active
						? isDuplicated || isRestricted
							? 'rgba(207, 102, 121, 0.21)'
							: 'rgba(66, 165, 245, 0.21)'
						: 'transparent',
				}}
			>
				{item.ctrlKey && <HotKeyIcon hotkey={hotkeyCodes.ControlLeft} />}

				{item.altKey && <HotKeyIcon hotkey={hotkeyCodes.AltLeft} />}

				{item.metaKey && <HotKeyIcon hotkey={hotkeyCodes.MetaLeft} />}

				{item.shiftKey && <HotKeyIcon hotkey={hotkeyCodes.ShiftLeft} />}

				<HotKeyIcon hotkey={item.hotkey} />
			</Box>
			<Box
				sx={{
					width: '1px',
					height: '25px',
					background: active ? 'transparent' : assigned ? '#4D4D4D' : '#3c3c3c',
				}}
			/>
			<Typography
				sx={{
					padding: '0 26px',
					fontSize: '16px',
					lineHeight: '24px',
					letterSpacing: '0.15px',
					color:
						(isDuplicated || isRestricted) && active
							? '#CF6679'
							: !assigned
							? 'rgba(255, 255, 255, 0.3)'
							: item.hotkey
							? 'rgba(255, 255, 255, 0.87)'
							: 'rgba(255, 255, 255, 0.6)',
					textAlign: item.hotkey ? 'center' : 'left',
					flex: '1',
				}}
			>
				<HotKeyText item={item} />
			</Typography>
		</Box>
	);
};
