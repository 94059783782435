import generateRandomString from '@rs-core/utils/generateRandomString';

export const getAllergyDataFromProfile = allergyMapping => {
	const allergiesMapping = allergyMapping || [];
	return allergiesMapping.map(allergy => ({
		id: generateRandomString(16),
		Allergen: allergy.allergen,
		Severity: allergy.severity,
	}));
};
