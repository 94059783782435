// @flow

// libs
import { create } from 'zustand';
// utils
import Patient from '../models/Patient';
import Observations from '../models/Observations';
import {
	type IDocument,
	type IImagingStudy,
	type IPatientAlert,
	type IPatientLink,
} from '@rs-ui/views/PatientInfoView/utils';
import CoverageModel from '@rs-ui/views/PatientInformationView/models/Coverage';

export type TPatientStore = {
	alerts: IPatientAlert[],
	setAlerts: (alerts: IPatientAlert[]) => void,

	documents: IDocument[],
	setDocuments: (documents: IDocument[]) => void,

	coverages: CoverageModel[],
	setCoverages: (coverages: CoverageModel[]) => void,

	linkedPatients: IPatientLink[],
	setLinkedPatients: (linkedPatients: IPatientLink[]) => void,

	/** Whether the patient data is currently being fetched */
	isLoading: boolean, // #TODO: implemented with react-query
	setIsLoadingPatient: (isLoading: boolean) => void,

	isLoaded: boolean, // #TODO: implemented with react-query
	setIsLoadedPatient: (isLoading: boolean) => void,

	patient: Patient | null,
	setPatient: (patient: Patient) => void,

	permissions: any | null,
	setPermissions: (permissions: any) => void,

	studies: IImagingStudy[],
	setStudies: (studies: IImagingStudy) => void,

	resetPatientStore: () => void,

	guarantor: Object,
	setGuarantor: (guarantor: Object) => void,

	observations: Observations | null,
	setPatientObservations: (observations: Observations) => void,

	studyShareQRLink: string | null,
	setStudyShareQRLink: (observations: Observations) => void,

	updateCoverage: (res: CoverageModel) => void,
	deleteCoverage: (coverageId: string) => void,
};

export const usePatientStore: () => TPatientStore = create(set => ({
	// Store variables w/ default values
	alerts: [],
	documents: [],
	coverages: [],
	isLoading: false,
	isLoaded: false,
	linkedPatients: [],
	// order: null,		// #TODO
	patient: null,
	permissions: null,
	studies: [],
	guarantor: null,
	observations: null,
	studyShareQRLink: null,

	// ==================== Setters ====================
	// #NOTE: use setters only with actual value, to reset store variables, use re-setters down below

	setAlerts: (alerts: IPatientAlert[]) => set({ alerts }),

	setDocuments: (documents: IDocument[]) => {
		set(state => {
			// Update `setDocuments` to accept a functional update or a direct value
			const newDocuments = typeof documents === 'function' ? documents(state.documents) : documents;
			return { documents: newDocuments };
		});
	},

	setCoverages: (coverages: CoverageModel[]) => set({ coverages }),

	setIsLoadingPatient: (isLoading: boolean) => set({ isLoading }),

	setIsLoadedPatient: (isLoaded: boolean) => set({ isLoaded }),

	setLinkedPatients: (linkedPatients: IPatientLink[]) => set({ linkedPatients }),

	setPatient: (patientData: Patient) =>
		set(prevState => ({
			patient: {
				...prevState.patient,
				...patientData,
			},
		})),

	setPatientObservations: (observations: Observations) =>
		set(prevState => ({
			observations: {
				...prevState.observations,
				...observations,
			},
		})),

	setPermissions: (permissions: any) => set({ permissions }),

	setStudies: (studies: IImagingStudy[]) => set({ studies }),

	setGuarantor: (guarantor: Object) => set({ guarantor }),
	//
	// ==================== Re-setters ====================
	resetPatientStore: () =>
		set({
			// #NOTE: Be sure to include all the store's variables !
			alerts: [],
			documents: [],
			insuranceCoverage: [],
			patient: null,
			isLoading: false,
			isLoaded: false,
			permissions: null,
			studies: [],
			linkedPatients: [],
			guarantor: null,
			studyShareQRLink: null,
		}),

	/** Resets the patient's `alerts` back to `[]` */
	resetAlerts: () => set({ alerts: [] }),

	/** Resets the patient's `documents` back to `[]` */
	resetDocuments: () => set({ documents: [] }),

	/** Resets the patient's `insuranceCoverage` back to `[]` */
	resetInsuranceCoverage: () => set({ insuranceCoverage: [] }),

	/** Resets the patient back to `null` */
	resetPatient: () => set({ patient: null }),

	/** Resets the patient's `studies` back to `[]` */
	resetStudies: () => set({ studies: [] }),

	/** Resets the patient's `links` back to `[]` */
	resetLinkedPatients: () => set({ linkedPatients: [] }),

	setStudyShareQRLink: (studyShareQRLink: String) => set({ studyShareQRLink }),

	updateCoverage: res => {
		set(state => {
			const newCoverages = [...state.coverages];

			const newUpdatedCoverageIndex = newCoverages.findIndex(item => item.data.id === res?.id);

			if (newUpdatedCoverageIndex !== -1) {
				const coverageModel = new CoverageModel(res);
				coverageModel.init();
				newCoverages[newUpdatedCoverageIndex] = { ...coverageModel };

				return { coverages: newCoverages };
			}

			return state;
		});
	},

	deleteCoverage: (coverageId: string) => {
		set(state => {
			const updatedCoverages = state.coverages.filter(coverage => coverage.data.id !== coverageId);
			return { coverages: updatedCoverages };
		});
	},

	// ! DON'T USE !
	// ==================== Danger zone  ====================
	// __deleteStore: () => set({}, true), // clears the entire store, actions included
}));
