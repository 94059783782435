import React from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import AddNewButton from '../../AddNewButton';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';

const AddMoreComponent = ({ options, onChange, formField, disabled, variant, className, onAddMore }) => {
	const shouldRender = option => {
		if (formField.customFilter) {
			return formField.customFilter(option);
		}

		return true;
	};

	const getKey = item =>
		formField.optionIdMapping ? `${item[formField.optionIdMapping]}` : `${item[formField.optionValueMapping]}`;

	return !disabled ? (
		<Grid container alignItems="center" spacing={1}>
			<Grid item>{formField.icon}</Grid>

			<Grid item>{formField.name}</Grid>

			<Grid item xs={12}>
				<AddNewButton fullWidth onClick={onAddMore} />
			</Grid>

			{options &&
				options.map((item, index) =>
					shouldRender(item) ? (
						<Grid key={getKey(item)} item xs={12}>
							<TextField
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												edge="end"
												onClick={() => {
													onChange(null, null, `${formField.mapping}.${index}`, true);
												}}
											>
												<CloseIcon data-cy={`form-field-${formField.name}-clear`} />
											</IconButton>
										</InputAdornment>
									),
									'data-testid': 'email-text-field',
								}}
								className={className}
								data-testid="form-field"
								defaultValue={item[formField.optionValueMapping]}
								id={formField.id ? formField.id : `form-field-${formField.name}`}
								required={formField.required}
								variant={variant || 'outlined'}
								onChange={event => {
									const value = item;

									value[formField.optionValueMapping] = event.target.value;

									onChange(event, value, `${formField.mapping}.${index}`);
								}}
							/>
						</Grid>
					) : null
				)}
		</Grid>
	) : (
		<Grid container alignItems="center" spacing={1}>
			<Grid item xs={12}>
				<Grid container alignItems="center" flexWrap="nowrap" spacing={1}>
					<Grid item>{formField.icon}</Grid>

					<Grid item>{formField.name}</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				{options &&
					options.map((item, index) =>
						shouldRender(item) ? (
							<TextField
								key={getKey(item)}
								disabled
								fullWidth
								className={className}
								defaultValue={item[formField.optionValueMapping]}
								id={formField.id ? `${formField.id}-${index}` : `form-field-${formField.name}-${index}`}
								variant={variant || 'outlined'}
								onChange={() => {}}
							/>
						) : null
					)}
			</Grid>
		</Grid>
	);
};

const AddMore = React.memo(AddMoreComponent);

export default AddMore;
