// @flow

// core
import React, { type Node } from 'react';
// components
import Stack from '@mui/material/Stack';
import { LoaderSkeleton, type ISkeletonProps } from './LoaderSkeleton';

export const LoaderStudyListV2 = ({ children, isLoading }: ISkeletonProps): Node =>
	isLoading ? (
		<Stack spacing="8px">
			<LoaderSkeleton sx={{ height: '216px' }} />
			<LoaderSkeleton sx={{ height: '216px' }} />
		</Stack>
	) : (
		children
	);
