import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import getCurrentSeries from '../utils/getCurrentSeries';
import { BROADCAST_EVENTS } from './consts/consts';
import { useImageViewerMultiMonitorContext } from './ImageViewerMultiMonitorContext';
import useStateRef from '../hooks/useStateRef';
import { getSeriesModality } from '../utils/utils';
import { useImageViewerStudiesZustandStoreSelector } from './ImageViewerStudiesZustandContext';
import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';
import { useImageViewerView3DContext } from './ImageViewerView3DContext';

const ImageViewerStudiesContext = createContext({});

const ImageViewerStudiesContextProvider = ({ children }) => {
	const { postMessage } = useImageViewerMultiMonitorContext();
	const { loadingManagerRef } = useImageViewerView3DContext();

	// Zustand context ==============
	const zustandStudies = useImageViewerStudiesZustandStoreSelector(state => state.studies);
	const zustandSetStudies = useImageViewerStudiesZustandStoreSelector(state => state.setStudies);

	const zustandSeries = useImageViewerStudiesZustandStoreSelector(state => state.series);
	const zustandSetSeries = useImageViewerStudiesZustandStoreSelector(state => state.setSeries);
	const zustandAddNewSeries = useImageViewerStudiesZustandStoreSelector(state => state.addSeries);

	const zustandCurrentStudy = useImageViewerStudiesZustandStoreSelector(state => state.currentStudy);
	const zustandSetCurrentStudy = useImageViewerStudiesZustandStoreSelector(state => state.setCurrentStudy);

	const zustandStudiesRef = useRef(zustandStudies);
	zustandStudiesRef.current = zustandStudies;

	const zustandSeriesRef = useRef(zustandSeries);
	zustandSeriesRef.current = zustandSeries;

	const zustandCurrentStudyRef = useRef(zustandCurrentStudy);
	zustandCurrentStudyRef.current = zustandCurrentStudy;
	// Zustand context ==============

	const [internalStudyId, setInternalStudyId] = useState(null);
	const [imagingStudy, setImagingStudy] = useState();
	const [priorMatchingModels, setPriorMatchingModels, priorMatchingModelsRef] = useStateRef([]);
	const [keyImages, setKeyImages] = useState([]);
	const [isInitialized, setIsInitialized] = useState(false);
	const [studySize, setStudySize] = useState(0);

	const currentSeries = useMemo(
		() =>
			getCurrentSeries({
				series: zustandSeries,
				currentStudy: zustandCurrentStudy,
			}),
		[zustandSeries, zustandCurrentStudy]
	);

	useEffect(() => {
		const effectSeries = zustandSeries;
		if (effectSeries.length > 0) {
			effectSeries.forEach(item => {
				const { imageIds, metadata, studyInstanceUID } = item;
				imageIds.forEach((imageId, index) => {
					if (!cornerstoneWADOImageLoader.wadors.metaDataManager.get(imageId)) {
						cornerstoneWADOImageLoader.wadors.metaDataManager.add(imageId, metadata[index]);
					}
				});

				loadingManagerRef.current.studySeries[studyInstanceUID] = true;
			});
		}
	}, [zustandSeries]);

	const isMGStudyOnly = currentSeries?.every(item => {
		const modality = getSeriesModality(item)?.toUpperCase();

		return modality === 'MG';
	});

	const onSetKeyImages = value => {
		let result = value;

		if (value instanceof Function) {
			setKeyImages(prevState => {
				const newValue = value(prevState);

				result = newValue;

				return newValue;
			});
		} else {
			setKeyImages(value);
		}

		postMessage({
			event: BROADCAST_EVENTS.KEY_IMAGES_UPDATE,
			value: result,
		});
	};

	return (
		<ImageViewerStudiesContext.Provider
			value={{
				imagingStudy,
				setImagingStudy,
				studies: zustandStudies,
				setStudies: zustandSetStudies,
				studiesRef: zustandStudiesRef,
				series: zustandSeries,
				setSeries: zustandSetSeries,
				addNewSeries: zustandAddNewSeries,
				seriesRef: zustandSeriesRef,
				currentStudy: zustandCurrentStudy,
				setCurrentStudy: zustandSetCurrentStudy,
				currentStudyRef: zustandCurrentStudyRef,
				currentSeries,
				keyImages,
				setKeyImages,
				onSetKeyImages,
				priorMatchingModels,
				setPriorMatchingModels,
				priorMatchingModelsRef,
				isInitialized,
				setIsInitialized,
				isMGStudyOnly,
				internalStudyId,
				setInternalStudyId,
				studySize,
				setStudySize,
			}}
		>
			{children}
		</ImageViewerStudiesContext.Provider>
	);
};

const useImageViewerStudiesContext = () => useContext(ImageViewerStudiesContext);

export { useImageViewerStudiesContext, ImageViewerStudiesContextProvider, ImageViewerStudiesContext };
