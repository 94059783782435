/* Generated automagically by FhirStarter - but please feel free to edit this file */
import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';
import React from 'react';
import getUserFullName from '@rs-core/fhir/resource/columnMapping/utils/getUserFullName';
import { sortCompareDate } from '@rs-core/fhir/resource/columnMapping/utils/sortCompare';
import TaskLogActionButton from '@rs-ui/components/TaskLogActionButton/TaskLogActionButton';
import TextWithReasonWindow from '@rs-ui/components/TextWithReasonWindow/TextWithReasonWindow';
import _ from 'lodash';
import getExtensionValueString from '@rs-core/fhir/resource/columnMapping/utils/getExtensionValueString';

const priorityMapping = [
	{
		id: 'routine',
		display: 'Low',
	},
	{
		id: 'urgent',
		display: 'Medium',
	},
	{
		id: 'asap',
		display: 'High',
	},
	{
		id: 'stat',
		display: 'Highest',
	},
];

const TaskMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
			excludeFromSave: true,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.id;
		},
	},
	actions: {
		label: 'Action',
		filterType: 'none',
		getDisplay: resource => {
			return resource;
		},
		options: {
			filter: false,
			sort: false,
			draggable: false,
			customBodyRender: value => {
				return (
					<TaskLogActionButton
						resource={value.props?.text}
						logStatus={value.props.text?.status}
						reasonCode={value.props.text?.reasonCode?.text}
					/>
				);
			},
		},
		cell: props => {
			return (
				<TaskLogActionButton
					resource={props.row.original}
					logStatus={props.row.original.status}
					reasonCode={props.row.original.reasonCode?.text}
				/>
			);
		},
	},
	status: {
		label: 'Status',
		options: {
			filter: true,
			sort: false,
			setCellProps: () => ({
				style: { minWidth: '170px' },
			}),
			customBodyRender: value => {
				let statusStr = value.props?.text?.status ? _.replace(value.props?.text?.status, '-', ' ') : '';
				if (statusStr === '') {
					statusStr = value.props?.text ? _.replace(value.props?.text, '-', ' ') : '';
				}

				let statusStr1 = _.join(
					_.map(_.split(statusStr, ' '), s => _.capitalize(s)),
					' '
				);
				let statusReasonStr;
				let dateTimeStr;

				if (statusStr === 'failed' || statusStr === 'rejected') {
					statusReasonStr = value?.props?.text?.statusReason ? value?.props?.text?.statusReason?.text : '';

					dateTimeStr = value.props?.text?.lastModified;

					if (dateTimeStr != null) {
						let dateTime = new Date(dateTimeStr);

						dateTimeStr = dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
					}
				}
				return (
					<TextWithReasonWindow text={statusStr1} windowText={statusReasonStr} windowHeader={dateTimeStr} />
				);
			},
		},
		cell: props => {
			let data = props.row?.original;
			let statusStr = data?.status ? _.replace(data?.status, '-', ' ') : '';

			let statusStr1 = _.join(
				_.map(_.split(statusStr, ' '), s => _.capitalize(s)),
				' '
			);
			let statusReasonStr;
			let dateTimeStr;

			if (statusStr === 'failed' || statusStr === 'rejected') {
				statusReasonStr = data?.statusReason ? data?.statusReason?.text : '';

				dateTimeStr = data?.lastModified;

				if (dateTimeStr != null) {
					let dateTime = new Date(dateTimeStr);

					dateTimeStr = dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
				}
			}
			return <TextWithReasonWindow text={statusStr1} windowText={statusReasonStr} windowHeader={dateTimeStr} />;
		},
		searchParameter: 'status',
		searchValueSet: 'taskStatus',
		filterType: 'multi-select',
		getDisplay: resource => {
			return resource.status;
		},
	},
	identifier: {
		label: 'Identifier',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'identifier',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.identifier;
		},
	},
	instantiatesCanonical: {
		label: 'Instantiates Canonical',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'instantiatesCanonical',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.instantiatesCanonical;
		},
	},
	instantiatesUri: {
		label: 'Instantiates Uri',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'instantiatesUri',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.instantiatesUri;
		},
	},
	basedOn: {
		label: 'Based On',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'basedOn',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.basedOn;
		},
	},
	groupIdentifier: {
		label: 'Group Identifier',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'groupIdentifier',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.groupIdentifier;
		},
	},
	partOf: {
		label: 'Part Of',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'partOf',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.partOf;
		},
	},
	statusReason: {
		label: 'Status Reason',
		options: { filter: true, sort: false },
		searchParameter: 'statusreason',
		valueSet: {},
		filterType: 'text-search',
		getDisplay: resource => {
			return resource.reasonCode?.text;
		},
		sortingFn: 'text',
	},
	deviceType: {
		label: 'Device Type',
		options: { filter: false, sort: false },
		filterType: 'none',
		searchParameter: 'reasonCode',
		valueSet: {},
		getDisplay: resource => {
			return resource.owner?.type
				? resource.owner.type
				: getExtensionValueString(resource.for, fhirExtensionUrls.task.deviceType);
		},
	},
	businessStatus: {
		label: 'Business Status',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'businessStatus',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.businessStatus;
		},
	},
	intent: {
		label: 'Intent',
		options: { filter: false, sort: false },
		searchParameter: 'intent',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.intent;
		},
	},
	transmissionPriority: {
		label: 'Transmission Priority',
		options: { filter: true, sort: false },
		searchParameter: 'priority',
		searchValueSet: 'transmissionPriority',
		findById: true,
		filterType: 'multi-select',
		customFilterOptions: priorityMapping,
		getDisplay: data => {
			return priorityMapping.find(mapping => mapping.id === data.priority)?.display;
		},
	},
	code: {
		label: 'Code',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'code',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.code;
		},
	},
	description: {
		label: 'Description',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'description',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.description;
		},
	},
	focus: {
		label: 'Focus',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'focus',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.focus;
		},
	},
	forAeTitle: {
		label: 'AE Title',
		options: { filter: false, sort: false },
		searchParameter: 'for',
		valueSet: {},
		filterType: 'text-search',
		getDisplay: resource => {
			return _.find(resource.for?.extension, extension => extension.url === fhirExtensionUrls.task.forAeTitle)
				?.valueString;
		},
	},
	for: {
		label: 'DICOM Station',
		options: { filter: false, sort: false },
		searchParameter: 'stationName',
		valueSet: {},
		filterType: 'text-search',
		getDisplay: resource => {
			return resource.for?.display
				? resource.for.display
				: _.find(resource.for?.extension, extension => extension.url === fhirExtensionUrls.task.forAeTitle)
						?.valueString;
		},
	},
	encounter: {
		label: 'Encounter',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'encounter',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.encounter;
		},
	},
	executionPeriod: {
		label: 'Execution Period',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'executionPeriod',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.executionPeriod;
		},
	},
	taskStart: {
		label: 'Task Start',
		options: { filter: true, sort: false },
		searchParameter: 'starttask',
		valueSet: {},
		filterType: 'date-range',
		getDisplay: resource => {
			let value = resource?.executionPeriod?.start;
			if (value != null) {
				let dateTime = new Date(value);
				if (dateTime.getFullYear() > 1900 && dateTime.getFullYear() < 9999) {
					return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
				}
			}
		},
		toolTip: true,
	},
	taskEnd: {
		label: 'Task End',
		options: { filter: true, sort: false },
		searchParameter: 'endtask',
		valueSet: {},
		filterType: 'date-range',
		getDisplay: resource => {
			let value = resource?.executionPeriod?.end;
			if (value != null) {
				let dateTime = new Date(value);
				if (dateTime.getFullYear() > 1900 && dateTime.getFullYear() < 9999) {
					return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
				}
			}
		},
		toolTip: true,
	},
	authoredOn: {
		label: 'Creation Date Time',
		options: { filter: false, sort: false, sortCompare: sortCompareDate },
		searchParameter: 'authoredOn',
		valueSet: {},
		filterType: 'date-range',
		getDisplay: resource => {
			let value = resource?.authoredOn;
			if (value != null) {
				let dateTime = new Date(value);
				return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
			}
		},
		toolTip: true,
	},
	lastModified: {
		label: 'Last Modified',
		options: { filter: true, sort: false },
		searchParameter: 'lastModified',
		valueSet: {},
		filterType: 'date-range',
		getDisplay: resource => {
			let value = resource.lastModified;
			if (value != null) {
				let dateTime = new Date(value);
				return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
			}
		},
		toolTip: true,
	},
	requester: {
		label: 'Requester',
		options: { filter: false, sort: false },
		searchParameter: 'requester',
		valueSet: {},
		filterType: 'text-search',
		getDisplay: resource => {
			return resource.requester?.display;
		},
	},
	performerType: {
		label: 'Performer Type',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'performerType',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.performerType;
		},
	},
	owner: {
		label: 'Owner',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'owner',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.owner;
		},
	},
	location: {
		label: 'Location',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'location',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.location;
		},
	},
	reasonCode: {
		label: 'Reason Code',
		options: { filter: false, sort: false },
		searchParameter: 'reasonCode',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.reasonCode?.text;
		},
	},
	reasonReference: {
		label: 'Reason Reference',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'reasonReference',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.reasonReference;
		},
	},
	insurance: {
		label: 'Insurance',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'insurance',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.insurance;
		},
	},
	note: {
		label: 'Note',
		options: { filter: false, sort: false },
		searchParameter: 'note',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.note[0]?.text;
		},
	},
	relevantHistory: {
		label: 'Relevant History',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'relevantHistory',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.relevantHistory;
		},
	},
	restriction_repetitions: {
		label: 'Restriction_repetitions',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'restriction_repetitions',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.restriction_repetitions;
		},
	},
	restriction_period: {
		label: 'Restriction_period',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'restriction_period',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.restriction_period;
		},
	},
	restriction_recipient: {
		label: 'Restriction_recipient',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'restriction_recipient',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.restriction_recipient;
		},
	},
	input: {
		label: 'Input',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'input',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.input;
		},
	},
	output: {
		label: 'Output',
		options: { filter: false, sort: false, viewColumns: false },
		searchParameter: 'output',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.output;
		},
	},
	numOfObjects: {
		label: '# of Objects',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.NumofObjects;
		},
	},
	numOfRemaining: {
		label: '# Remaining',
		options: { filter: false, sort: false, viewColumns: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.NumofRemaining;
		},
	},
	numFailed: {
		label: '# Failed',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.NumFailures;
		},
	},
	duration: {
		label: 'Duration',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: data => {
			if (data.executionPeriod) {
				if (data.executionPeriod.start && data.executionPeriod.end) {
					let startDate = new Date(data.executionPeriod.start),
						endDate = new Date(data.executionPeriod.end),
						result = '';

					// Prevents calculation when executionPeriod.end or executionPeriod.start is null.
					// FHIR API returns startDate as "0001-01-01T00:00:00.000000+00:00" when it is null
					// FHIR API returns endDate as "9999-12-31T23:59:59.999999+00:00" when it is null
					if (startDate.getFullYear() > 1900 && endDate.getFullYear() < 9999) {
						let distance = endDate - startDate;

						if (!distance.isNaN && distance > 0) {
							const hours = Math.floor(distance / 3600000);
							distance -= hours * 3600000;
							if (hours > 0) {
								result += `${hours}h `;
							}

							const minutes = Math.floor(distance / 60000);
							distance -= minutes * 60000;
							result += `${minutes}m `;

							const seconds = Math.floor(distance / 1000);
							result += `${seconds}s`;
						} else {
							result += `0s`;
						}
					}

					return result;
				}
			} else {
				return '';
			}
		},
	},
	patientID: {
		label: 'Patient ID',
		options: { filter: false, sort: false },
		filterType: 'text-search',
		searchParameter: 'patientId',
		getDisplay: resource => {
			return resource.PatientID;
		},
		sortingFn: 'text',
	},
	patientName: {
		label: 'Patient Name',
		options: { filter: false, sort: true },
		filterType: 'text-search',
		searchParameter: 'patientName',
		getDisplay: resource => {
			return getUserFullName(resource.Patient, true);
		},
		renderValue: value => {
			return getUserFullName(value, true);
		},
		sortingFn: 'text',
	},
	organizationName: {
		label: 'Managing Organization',
		options: { filter: true, sort: true },
		filterType: 'suggest',
		searchParameter: 'managingOrganization',
		suggestScope: {
			resource: 'organization',
			label: 'name',
			extraParam: {
				ismanaging: true,
				active: true,
				_summary: 'text',
				_elements: 'name',
			},
		},
		getDisplay: resource => {
			return resource.organization;
		},
		sortingFn: 'text',
	},
	serviceName: {
		label: 'Device',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'serviceName',
		getDisplay: resource => {
			return resource.owner?.display;
		},
		sortingFn: 'text',
	},
	accessionNumber: {
		label: 'Accession Number',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'accessionNumber',
		getDisplay: resource => {
			return resource.AccessionNumber;
		},
		sortingFn: 'text',
	},
	issuer: {
		label: 'Issuer',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'issuer',
		getDisplay: resource => {
			return resource.issuer;
		},
	},
	peerHost: {
		label: 'Peer Host',
		filterType: 'text-search',
		options: { filter: false, sort: false },
		searchParameter: 'peerHost',
		getDisplay: resource => {
			return resource.peerHost
				? resource.peerHost
				: getExtensionValueString(resource.for, fhirExtensionUrls.task.peerHost);
		},
	},
	studyDateTime: {
		label: 'Study Date Time',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			let value = resource.StudyDateTime;

			if (value != null) {
				let dateTime = new Date(value);
				return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
			}
		},
	},
	studyUID: {
		label: 'Study Instance UID',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'studyInstanceUID',
		getDisplay: resource => {
			return resource.input
				? _.find(resource.input, input => input?.type?.text === fhirExtensionUrls.task.studyUID)?.valueString
				: '';
		},
		cell: props => {
			let data = props.row?.original,
				uid = data?.input
					? _.find(data.input, input => input?.type?.text === fhirExtensionUrls.task.studyUID)?.valueString
					: '';

			return uid ? uid : '';
		},
		sortingFn: 'text',
	},
	studyDescription: {
		label: 'Study Description',
		filterType: 'text-search',
		searchParameter: 'studyDescription',
		getDisplay: resource => {
			return resource.StudyDescription;
		},
	},
	visitNumber: {
		label: 'Visit #',
		filterType: 'text-search',
		searchParameter: 'visitNumber',
		getDisplay: resource => {
			return resource.VisitNumber;
		},
	},
	sender: {
		label: 'Sender',
		options: { filter: false, sort: false },
		searchParameter: 'senderUserName',
		valueSet: {},
		filterType: 'text-search',
		getDisplay: resource => {
			return getExtensionValueString(resource, fhirExtensionUrls.task.senderUserName);
		},
	},
	receiver: {
		label: 'Receiver',
		filterType: 'text-search',
		options: { filter: false, sort: false },
		searchParameter: 'receiverUserNames',
		getDisplay: resource => {
			return getExtensionValueString(resource, fhirExtensionUrls.task.receiverUserNames);
		},
	},
	trigger: {
		label: 'Trigger',
		filterType: 'text-search',
		options: { filter: false, sort: false },
		searchParameter: 'trigger',
		getDisplay: resource => {
			return getExtensionValueString(resource, fhirExtensionUrls.task.trigger);
		},
	},
	notificationType: {
		label: 'Notification Type',
		filterType: 'text-search',
		options: { filter: false, sort: false },
		searchParameter: 'notificationType',
		getDisplay: resource => {
			return resource.reasonCode?.text;
		},
	},
	failureReason: {
		label: 'Failure Reason',
		filterType: 'text-search',
		options: { filter: false, sort: false },
		searchParameter: 'failureReason',
		getDisplay: resource => {
			return getExtensionValueString(resource, fhirExtensionUrls.task.failureReason);
		},
	},
	message: {
		label: 'Message',
		filterType: 'text-search',
		options: { filter: false, sort: false },
		searchParameter: 'message',
		getDisplay: resource => {
			return getExtensionValueString(resource, fhirExtensionUrls.task.message);
		},
	},
	executionTime: {
		label: 'Execution Time',
		options: { filter: true, sort: false },
		searchParameter: 'starttask',
		valueSet: {},
		filterType: 'date-range',
		getDisplay: resource => {
			let value = resource?.executionPeriod?.start;
			if (value != null) {
				let dateTime = new Date(value);
				if (dateTime.getFullYear() > 1900 && dateTime.getFullYear() < 9999) {
					return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
				}
			}
		},
		toolTip: true,
	},
	deliveryTime: {
		label: 'Delivery Time',
		options: { filter: true, sort: false },
		searchParameter: 'endtask',
		valueSet: {},
		filterType: 'date-range',
		getDisplay: resource => {
			let value = resource?.executionPeriod?.end;
			if (value != null) {
				let dateTime = new Date(value);
				if (dateTime.getFullYear() > 1900 && dateTime.getFullYear() < 9999) {
					return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
				}
			}
		},
		toolTip: true,
	},
};
export default TaskMapping;
