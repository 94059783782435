import React from 'react';

const TableBorderIcon = ({ isHover }) => (
	<svg fill="none" height={18} width={18} xmlns="http://www.w3.org/2000/svg">
		<mask
			height={18}
			id="a"
			maskUnits="userSpaceOnUse"
			style={{
				maskType: 'alpha',
			}}
			width={18}
			x={0}
			y={0}
		>
			<path d="M0 0h18v18H0z" fill="#C4C4C4" />
		</mask>
		<g mask="url(#a)">
			<path
				d="M2.25 15.75v-1.5h1.5v1.5h-1.5Zm0-3v-1.5h1.5v1.5h-1.5Zm0-3v-1.5h1.5v1.5h-1.5Zm0-3v-1.5h1.5v1.5h-1.5Zm0-3v-1.5h1.5v1.5h-1.5Zm3 12v-1.5h1.5v1.5h-1.5Zm0-6v-1.5h1.5v1.5h-1.5Zm0-6v-1.5h1.5v1.5h-1.5Zm3 12v-1.5h1.5v1.5h-1.5Zm0-3v-1.5h1.5v1.5h-1.5Zm0-3v-1.5h1.5v1.5h-1.5Zm0-3v-1.5h1.5v1.5h-1.5Zm0-3v-1.5h1.5v1.5h-1.5Zm3 12v-1.5h1.5v1.5h-1.5Zm0-6v-1.5h1.5v1.5h-1.5Zm0-6v-1.5h1.5v1.5h-1.5Zm3 12v-1.5h1.5v1.5h-1.5Zm0-3v-1.5h1.5v1.5h-1.5Zm0-3v-1.5h1.5v1.5h-1.5Zm0-3v-1.5h1.5v1.5h-1.5Zm0-3v-1.5h1.5v1.5h-1.5Z"
				fill={isHover ? '#fff' : '#C4C4C4'}
			/>
		</g>
	</svg>
);

export default TableBorderIcon;
