import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useIsTablet } from '@worklist-2/core/src';
import Tooltip from '@mui/material/Tooltip';

const StyledIconButton = styled(IconButton)(() => ({
	height: 48,
	width: 48,
	position: 'absolute',
	borderRadius: '50%',
	background: '#f3f3f3',
	boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
	zIndex: 1,
	opacity: 1,
	'&.Mui-disabled': {
		display: 'none',
	},
	'&:hover': {
		backgroundColor: '#f3f3f3',
	},
}));

const DesktopScrollableView = ({ children, sx }) => {
	const [scrollStart, setScrollStart] = useState({ top: 0, left: 0 });
	const [scrollEnd, setScrollEnd] = useState({ bottom: false, right: false });
	const contentWrapper = useRef(null);

	const { t } = useTranslation('studyDetail');

	const onScrollCheck = useCallback(() => {
		const element = contentWrapper.current;
		if (element) {
			setScrollStart({
				top: element.scrollTop,
				left: element.scrollLeft,
			});
			setScrollEnd({
				bottom: Math.floor(element.scrollHeight - element.scrollTop) <= element.offsetHeight,
				right: Math.floor(element.scrollWidth - element.scrollLeft) <= element.offsetWidth,
			});
		}
	}, [children]);

	const sideScroll = useCallback((element, speed, distance, stepX, stepY) => {
		let scrollAmount = 0;
		const slideTimer = setInterval(() => {
			element.scrollTop += stepY;
			element.scrollLeft += stepX;
			scrollAmount += Math.sqrt(stepX ** 2 + stepY ** 2);
			if (scrollAmount >= distance) {
				clearInterval(slideTimer);
			}
			setScrollStart({
				top: element.scrollTop,
				left: element.scrollLeft,
			});
			setScrollEnd({
				bottom: Math.floor(element.scrollHeight - element.scrollTop) <= element.offsetHeight,
				right: Math.floor(element.scrollWidth - element.scrollLeft) <= element.offsetWidth,
			});
		}, speed);
	}, []);

	useEffect(() => {
		if (contentWrapper.current) {
			onScrollCheck();
		}
	}, [children, onScrollCheck]);

	return (
		<>
			{scrollStart.top !== 0 && (
				<StyledIconButton
					sx={{ top: '135px' }}
					onClick={() => {
						sideScroll(contentWrapper.current, 20, contentWrapper.current.offsetHeight, 0, -30);
					}}
				>
					<KeyboardArrowUpIcon />
				</StyledIconButton>
			)}
			{scrollStart.left !== 0 && (
				<Tooltip title={t('Prev')}>
					<StyledIconButton
						sx={{ left: '-22px' }}
						onClick={() => {
							sideScroll(contentWrapper.current, 20, contentWrapper.current.offsetWidth, -30, 0);
						}}
					>
						<KeyboardArrowLeftIcon />
					</StyledIconButton>
				</Tooltip>
			)}
			<Box
				ref={contentWrapper}
				data-testid="scrollBox"
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					overflow: 'hidden',
					...sx,
				}}
				onScroll={onScrollCheck}
			>
				{children}
			</Box>
			{!scrollEnd.bottom && (
				<StyledIconButton
					sx={{ bottom: '17px' }}
					onClick={() => {
						sideScroll(contentWrapper.current, 20, contentWrapper.current.offsetHeight, 0, 30);
					}}
				>
					<KeyboardArrowDownIcon />
				</StyledIconButton>
			)}
			{!scrollEnd.right && (
				<Tooltip title={t('Next')}>
					<StyledIconButton
						sx={{ right: '-22px' }}
						onClick={() => {
							sideScroll(contentWrapper.current, 20, contentWrapper.current.offsetWidth, 30, 0);
						}}
					>
						<KeyboardArrowRightIcon />
					</StyledIconButton>
				</Tooltip>
			)}
		</>
	);
};

const MobileScrollableView = ({ children, sx }) => (
	<Box
		data-testid="scrollBox"
		sx={{
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			overflow: 'auto',
			scrollbarWidth: 'none',
			msOverflowStyle: 'none',
			'&::-webkit-scrollbar': {
				display: 'none',
			},
			...sx,
		}}
	>
		{children}
	</Box>
);

const ScrollableView = ({ children, sx }) => {
	const isTablet = useIsTablet();
	return isTablet ? (
		<MobileScrollableView sx={sx}>{children}</MobileScrollableView>
	) : (
		<DesktopScrollableView sx={sx}>{children}</DesktopScrollableView>
	);
};

export default ScrollableView;
