// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NGgbbX3wA07fM77ZwiFl{display:flex;justify-content:center;width:100%;overflow:hidden}.KfSVwaOcMITlJTLhgwbi{position:relative;width:100%;border-radius:10px;background:#393939;overflow:auto}.KfSVwaOcMITlJTLhgwbi .Pymz6JwFAqbT5Hqk7kqq{height:calc(100% - 38px);overflow:auto}.Gi0hXQqoANyw4kYAQPtd{position:relative;width:100%;border-radius:25px;box-sizing:content-box;border:1px solid var(--neutral-color-dark-10, rgba(18, 18, 18, 0.1));background:#f3f3f3;overflow:auto}.Gi0hXQqoANyw4kYAQPtd .P1KbDC3_lVOKlwwg9frl{height:100%;overflow:auto}.iAYTHXnezgmJCP3J9yno{position:absolute;display:flex;justify-content:center;align-items:center;background-color:rgba(245,245,245,.341)}.afXmFxJx26Ip7MZz1VGw{height:100%;right:-5px;cursor:ew-resize}.afXmFxJx26Ip7MZz1VGw .bwnGh0PAMKMks07ixjuu{width:6px;height:50%;border-left:2px solid #000;border-right:2px solid #000;margin:5px}.ORIJG7BdlIg4TFPApfOT{width:100%;bottom:-5px;cursor:ns-resize}.ORIJG7BdlIg4TFPApfOT .bwnGh0PAMKMks07ixjuu{width:50%;height:6px;border-top:2px solid #000;border-bottom:2px solid #000;margin:5px}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/common/MainArea/MainArea.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CACA,sBAAA,CACA,UAAA,CACA,eAAA,CAGD,sBACC,iBAAA,CACA,UAAA,CACA,kBAAA,CACA,kBAAA,CACA,aAAA,CAEA,4CACC,wBAAA,CACA,aAAA,CAIF,sBACC,iBAAA,CACA,UAAA,CACA,kBAAA,CACA,sBAAA,CACA,oEAAA,CACA,kBAAA,CACA,aAAA,CAEA,4CACC,WAAA,CACA,aAAA,CAIF,sBACC,iBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,uCAAA,CAGD,sBACC,WAAA,CACA,UAAA,CACA,gBAAA,CAEA,4CACC,SAAA,CACA,UAAA,CACA,0BAAA,CACA,2BAAA,CACA,UAAA,CAIF,sBACC,UAAA,CACA,WAAA,CACA,gBAAA,CAEA,4CACC,SAAA,CACA,UAAA,CACA,yBAAA,CACA,4BAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainAreaContainer": "NGgbbX3wA07fM77ZwiFl",
	"mainAreaOAI": "KfSVwaOcMITlJTLhgwbi",
	"canvasOAI": "Pymz6JwFAqbT5Hqk7kqq",
	"mainAreaBlume": "Gi0hXQqoANyw4kYAQPtd",
	"canvasBlume": "P1KbDC3_lVOKlwwg9frl",
	"resizeHandle": "iAYTHXnezgmJCP3J9yno",
	"resizeHandleWidth": "afXmFxJx26Ip7MZz1VGw",
	"bar": "bwnGh0PAMKMks07ixjuu",
	"resizeHandleHeight": "ORIJG7BdlIg4TFPApfOT"
};
export default ___CSS_LOADER_EXPORT___;
