import React from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '@mui/material/ButtonGroup';
import StopIcon from '@mui/icons-material/Stop';
import ReplayIcon from '@mui/icons-material/Replay';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { searchScopes } from '@worklist-2/core/src/context/consts/searchScopes';
import useFhirDataLoader from '@worklist-2/core/src/hooks/useFhirDataLoader';
import { fhirExtensionUrls } from '@worklist-2/core/src/fhir/extension/fhirExtensionUrls';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const StyledButton = styled(Button)(props => ({
	height: '22px',
	width: props.icontype === 'STOP' ? '68px' : 'fit-content',
	fontSize: '10px',
	fontWeight: 500,
	fontFamily: 'Roboto',
	fontStyle: 'normal',
	fontVariant: 'small-caps',
	color: 'rgba(0, 0, 0, 0.6)',
	boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
	borderRadius: '6px',
	lineHeight: '12px',
	letterSpacing: '0.06em',
	alignItems: 'center',
	textAlign: 'center',
	textTransform: 'uppercase',
	backgroundColor: '#FFFFFF',
	'&:hover': {
		color: '#42A5F5 ',
		backgroundColor: 'rgba(66, 165, 245, 0.06)',
		border: '1px solid rgba(66, 165, 245, 0.6)',
	},
}));

const StyledButtonGroup = styled(ButtonGroup)({
	height: '22px',
	width: '72px',
	color: 'rgba(0, 0, 0, 0.6)',
	boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
	borderRadius: '6px',
	backgroundColor: '#FFFFFF',
});

const IconSize = 'medium';
const IconColor = 'rgba(0, 0, 0, 0.6)';

const StyledPlayArrowIcon = styled(PlayArrowIcon)({
	fontSize: IconSize,
	color: IconColor,
});

const StyledCloseIcon = styled(CloseIcon)({
	fontSize: IconSize,
	color: IconColor,
	marginLeft: '5px',
});

const StyledReplayIcon = styled(ReplayIcon)({
	fontSize: IconSize,
	color: IconColor,
	marginRight: '5px',
});

const StyledStopIcon = styled(StopIcon)({
	fontSize: IconSize,
});

const ButtonLienarProgress = ({ progress, btnText, btnIcon, onClickHandler }) => (
	<div
		style={{
			position: 'relative',
			width: '100%',
			height: '22px',
			overflow: 'hidden',
			borderRadius: '6px',
			cursor: 'pointer',
			fontSize: '10px',
			fontWeight: 'bold',
			letterSpacing: '0.07em',
		}}
		onClick={onClickHandler}
	>
		<div
			style={{
				position: 'absolute',
				top: 0,
				height: '100%',
				overflow: 'hidden',
				background: '#26A455',
				left: 0,
				width: `${progress}%`,
				borderRadius: '6px',
			}}
		>
			<span
				style={{
					position: 'absolute',
					width: '72px',
					height: '100%',
					left: 0,
					color: 'white',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-around',
				}}
			>
				{btnIcon}
				<span style={{ paddingTop: '3px' }}>{btnText}</span>
			</span>
		</div>
		<div
			style={{
				position: 'absolute',
				top: 0,
				height: '100%',
				overflow: 'hidden',
				background: '#fff',
				right: 0,
				width: `${100 - Number(progress)}%`,
			}}
		>
			<span
				style={{
					position: 'absolute',
					width: '72px',
					height: '100%',
					textAlign: 'center',
					right: 0,
					color: '#26A455',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-around',
				}}
			>
				{btnIcon}
				<span style={{ paddingTop: '3px' }}>{btnText}</span>
			</span>
		</div>
	</div>
);

const ButtonIconText = ({ icon, text, onClickHandler }) => (
	<div
		style={{
			width: '100%',
			height: '22px',
			overflow: 'hidden',
			borderRadius: '6px',
			cursor: 'pointer',
			fontSize: '10px',
			fontWeight: 'bold',
			letterSpacing: '0.07em',
			color: 'darkgrey',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around',
		}}
		onClick={onClickHandler}
	>
		{icon} <span style={{ paddingTop: '3px', paddingRight: '5px' }}>{text}</span>
	</div>
);

const TaskLogActionButton = ({ resource, logStatus, reasonCode }) => {
	const showAction = ['dicomstore', 'dicomstorepush'];
	const { t } = useTranslation('task');

	const fhirTaskDataLoader = useFhirDataLoader({
		scope: searchScopes.task,
	});

	const updateTaskStatus = async newStatus => {
		resource.status = newStatus;
		resource.rawData.status = newStatus;
		await fhirTaskDataLoader.update(resource.id, JSON.stringify(resource.rawData)).then(result => result);
	};

	const actions = {
		'on-hold': {
			key: 'onhold',
			buttons: [
				{
					icon: <StyledPlayArrowIcon />,
					onClick: () => updateTaskStatus('ready'),
				},
				{
					icon: <StyledCloseIcon />,
					onClick: () => updateTaskStatus('cancelled'),
				},
			],
		},
		requested: {
			key: 'requested',
			buttons: [
				{
					icon: <StyledCloseIcon />,
					text: t('CANCEL'),
					onClick: () => updateTaskStatus('cancelled'),
				},
			],
		},
		'in-progress': {
			key: 'inProgress',
			buttons: [
				{
					icon: <StyledStopIcon />,
					text: t('STOP'),
					onClick: () => updateTaskStatus('on-hold'),
				},
			],
		},
		failed: {
			key: 'failed',
			buttons: [
				{
					icon: <StyledReplayIcon />,
					onClick: () => updateTaskStatus('requested'),
				},
				{
					icon: <StyledCloseIcon />,
					onClick: () => updateTaskStatus('cancelled'),
				},
			],
		},
		rejected: {
			key: 'rejected',
			buttons: [
				{
					icon: <StyledReplayIcon />,
					onClick: () => updateTaskStatus('requested'),
				},
				{
					icon: <StyledCloseIcon />,
					onClick: () => updateTaskStatus('cancelled'),
				},
			],
		},
	};

	const progress = () => {
		const numRemaining = _.find(
			resource.rawData.extension,
			ext => ext.url === fhirExtensionUrls.task.numofRemaining
		)?.valueInteger;
		const numObj = _.find(
			resource.rawData.extension,
			ext => ext.url === fhirExtensionUrls.task.numofObjects
		)?.valueInteger;
		const remainingObjects = numRemaining ? parseInt(numRemaining) ?? 0 : 0;
		const totalObjects = numObj ? parseInt(numObj) ?? 0 : 0;
		return totalObjects === 0 ? 0 : ((totalObjects - remainingObjects) / totalObjects) * 100;
	};

	const buttonView =
		actions[logStatus]?.buttons.map((button, index) =>
			button.text ? (
				renderButtons(button, index, progress())
			) : (
				<StyledButton
					key={index}
					icontype={button.text}
					sx={{
						'&.MuiButtonBase-root': {
							color: 'darkgrey',
							outline: 'none',
						},
						'&.MuiButtonGroup-grouped': { minWidth: '32px' },
					}}
					onClick={button.onClick}
				>
					<span>{button.icon}</span>
				</StyledButton>
			)
		) || [];

	return (
		<>
			{!!actions[logStatus] && logStatus !== 'completed' && _.includes(showAction, reasonCode.toLowerCase()) && (
				<StyledButtonGroup
					aria-label="split button"
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '6px',
					}}
					variant="contained"
				>
					{buttonView}
				</StyledButtonGroup>
			)}
		</>
	);
};

TaskLogActionButton.propTypes = {
	/**
	 * The status of the task log
	 * @type {string}
	 */
	logStatus: PropTypes.string,

	/**
	 * The progress of the task log
	 * @type {string}
	 */
};

function renderButtons(button, index, progress) {
	return button.text === 'STOP' ? (
		<div key={`${index}_div`} style={{ position: 'relative', width: '72px' }}>
			<ButtonLienarProgress
				btnIcon={button.icon}
				btnText={button.text}
				progress={progress}
				onClickHandler={button.onClick}
			/>
		</div>
	) : (
		<ButtonIconText icon={button.icon} text={button.text} onClickHandler={button.onClick} />
	);
}

export default TaskLogActionButton;
