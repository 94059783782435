import React from 'react';

const CheckListIcon = ({ fill }) => (
	<svg fill="none" height="25" viewBox="0 0 26 25" width="26" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_31944)">
			<path
				d="M23.2536 7.26397H14.143V9.28854H23.2536V7.26397ZM23.2536 15.3623H14.143V17.3868H23.2536V15.3623ZM6.59131 11.3131L3.00781 7.72962L4.43514 6.3023L6.58119 8.44835L10.8733 4.15625L12.3006 5.58357L6.59131 11.3131ZM6.59131 19.4114L3.00781 15.8279L4.43514 14.4006L6.58119 16.5466L10.8733 12.2545L12.3006 13.6819L6.59131 19.4114Z"
				fill={fill}
			/>
		</g>
		<defs>
			<clipPath id="clip0_2852_31944">
				<rect fill={fill} height="24.2949" transform="translate(0.983398 0.179688)" width="24.2949" />
			</clipPath>
		</defs>
	</svg>
);

export default CheckListIcon;
