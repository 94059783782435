import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';

const KeyResultProgress = styled('div')({
	width: '100%',
	height: '5px',
	display: 'flex',
	alignItems: 'center',
	overflow: 'hidden',
	background: 'rgba(77, 121, 234, 0.1);',
	borderRadius: '20px',
	// marginBottom: '50px',
});

function KeyResultProgressBar({ keyResult }) {
	const progresses = (keyResult?.Ideas || []).map(idea => idea.Progress);
	const averagePercentage = progresses.reduce((a, b) => a + b, 0) / progresses.length;

	return (
		<KeyResultProgress>
			<div
				style={{
					width: `${averagePercentage}%`,
					background: '#2FD6AD',
					height: '100%',
					borderRadius: '20px',
				}}
			/>
		</KeyResultProgress>
	);
}

export default KeyResultProgressBar;
