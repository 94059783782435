import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React, { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import CanadaFlag from '@worklist-2/ui/src/assets/img/Canada.svg';
import AvatarImage from '@worklist-2/ui/src/assets/img/unsplash_6W4F62sN_yI_large.png';
import { LinkButton, RoleSideWidget, StatusCircle, SubTitle, Title } from '../../../../views/CrmView/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import Avatar from '@mui/material/Avatar';
import Icon from '@mui/material/Icon';
import SwitchButton from '../SwitchButton';
import UserAssignedRolesCard from './UserAssignedRolesCard';
import UserAssignedTeamsCard from './UserAssignedTeamsCard';
import UserDetailsCard from './UserDetailsCard';
import { crmSearchScopes, useCRMDataLoader, useAuth } from '@worklist-2/core/src';
import { useCrmContext } from '../../../../context/CRMContext';
import _ from 'lodash';

const UserPreview = forwardRef((props, ref) => {
	const [visibility, toggleVisibility] = useState(false);
	const [user, setUser] = useState({});
	const [roles, setRoles] = useState([]);
	const [teams, setTeams] = useState([]);
	const [updateUser, setUpdateUser] = useState();
	const { toggleWidget } = useCrmContext();

	useImperativeHandle(ref, () => ({
		open: data => {
			toggleVisibility(true);
			setUser(data?.user);
			setRoles(data?.roles);
			setTeams(data?.teams);
			setUpdateUser(() => data?.updateUser);
		},
		close: () => {
			toggleVisibility(false);
		},
	}));

	const onClose = () => {
		toggleVisibility(false);
		toggleWidget('userPreview', null);
	};

	return (
		visibility && (
			<UserPreviewWidget roles={roles} teams={teams} updateUser={updateUser} user={user} onClose={onClose} />
		)
	);
});

const UserPreviewWidget = props => {
	const { onClose, updateUser } = props;
	const detailsCardRef = createRef();
	const rolesCardRef = createRef();
	const teamsCardRef = createRef();
	const [user, setUser] = useState(props.user);
	const [isOpen, setIsOpen] = useState(!_.isNull(user));
	const { loggedInUser } = useAuth();
	const [active, setActive] = useState(props.user.IsActive);
	const [isUpdating, setIsUpdating] = useState(false);

	const userDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.userApi,
	});

	useEffect(() => {
		const ignoreClickOnMeElement = document.getElementById('popover-wrapper');

		const customButton = ['userName', 'closeEditButton', 'editButton', 'menu-', 'LI'];

		const listener = event => {
			const isClickInsideElement = ignoreClickOnMeElement?.contains(event.target);
			if (
				!isClickInsideElement &&
				!customButton.includes(event.target.id.split('_')[0]) &&
				!customButton.includes(event.target.parentElement?.id.split('_')[0]) &&
				!customButton.includes(event.target.lastChild?.id?.split('_')[0]) &&
				!customButton.includes(event.target.tagName)
			) {
				close();
			}
		};
		document.addEventListener('click', listener, true);

		return () => {
			document.removeEventListener('click', listener, true);
		};
	}, []);

	const close = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		if (!isOpen) {
			onClose();
		}
	}, [isOpen]);

	const onCancel = withClose => {
		detailsCardRef.current?.cancelEditing();
		rolesCardRef.current?.cancelEditing();
		teamsCardRef.current?.cancelEditing();
		if (withClose) {
			close();
		}
	};

	const onSave = async () => {
		try {
			setIsUpdating(true);
			const newPayload = {
				...user,
				...detailsCardRef.current?.save(),
				...rolesCardRef.current?.save(),
				...teamsCardRef.current?.save(),
			};
			newPayload.IsActive = active;
			const response = await userDataLoader.update(user?.UserId, newPayload);
			setIsUpdating(false);
			if (response === null) {
				onCancel(false);
			} else {
				updateUser(newPayload);
				setUser(newPayload);
				onClose();
			}
		} catch (error) {
			setIsUpdating(false);
			console.error(error.message);
		}
	};

	return (
		<RoleSideWidget id="popover-wrapper" isOpen={isOpen}>
			<Box className="header" sx={{ height: '120px' }}>
				<Box className="row">
					<SwitchButton
						checked={active}
						onChange={() => !!loggedInUser?.permission?.Role?.Edit && setActive(!active)}
					/>
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<IconButton onClick={close}>
							<LastPageIcon />
						</IconButton>
					</Box>
				</Box>
				<Box className="main">
					<Box className="rounded-shadow" />
				</Box>
			</Box>
			<Box
				sx={{
					flex: 1,
					background: '#FFF',
					boxShadow: '0 -4px 20px 0 rgba(33, 88, 119, 0.1)',
					borderTopLeftRadius: '20px',
					borderTopRightRadius: '20px',
					zIndex: 1,
				}}
			>
				<Avatar
					src={AvatarImage}
					style={{
						margin: '-48px auto 0px auto',
						width: '90px',
						height: '90px',
						borderRadius: '50%',
					}}
				/>
				<StatusCircle
					style={{
						marginLeft: '310px',
						marginTop: '-14px',
						position: 'absolute',
						border: '2px solid #FFF',
					}}
				/>
				<Box
					sx={{
						marginTop: '15px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Title>{user.UserName}</Title>
					<Box className="row" sx={{ justifyContent: 'center', alignItems: 'center' }}>
						<Icon component={CanadaFlag} />
						<SubTitle
							style={{
								fontFamily: 'Inter',
								fontStyle: 'normal',
								fontWeight: 400,
								fontSize: '12px',
								lineHeight: '15px',
								color: 'rgba(0, 0, 0, 0.9)',
								marginBottom: '10px',
							}}
						>
							{user.organization}
						</SubTitle>
					</Box>
				</Box>
				<UserDetailsCard ref={detailsCardRef} user={user} />
				<UserAssignedRolesCard ref={rolesCardRef} roles={props.roles} user={user} />
				<UserAssignedTeamsCard ref={teamsCardRef} teams={props.teams} user={user} />
			</Box>
			<Box className="row wrapper-footer">
				<LinkButton
					disabled={isUpdating || !loggedInUser?.permission?.User?.Edit}
					id="editButton_Edit_Cancel"
					style={{ border: '1px solid #4D79EA', fontSize: '12px' }}
					onClick={() => onCancel(true)}
				>
					Cancel
				</LinkButton>

				<LinkButton
					disabled={isUpdating || !loggedInUser?.permission?.User?.Edit}
					id="editButton_Edit_Save"
					style={
						loggedInUser?.permission?.User?.Edit
							? {
									margin: '0px 0px 0px auto',
									background: '#4D79EA',
									color: '#FFF',
									width: '82px',
							  }
							: {
									margin: '0px 0px 0px auto',
									border: '1px solid #4D79EA',
									fontSize: '12px',
									width: '82px',
							  }
					}
					onClick={onSave}
				>
					Save
				</LinkButton>
			</Box>
		</RoleSideWidget>
	);
};

export default UserPreview;
