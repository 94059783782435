// React Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Material UI Libraries
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

function ChartBox({
	children,
	index,
	setIsReport,
	setChartIndex,
	onDeleteChart,
	isHideToolBar = true,
	chartTitle,
	isEditMode = true,
	chartType,
}) {
	return (
		<Stack
			spacing={2}
			sx={{
				height: '100%',
				width: '100%',
				position: 'relative',
				// border: chartType == 'number' ? '1px solid rgba(255, 255, 255, 0.2)' : '1px solid #272727',
				border: '1px dashed transparent',
				borderRadius: '20px',
				background: 'rgba(189, 211, 231, 0.17)',
				'&:hover': {
					'.MuiSvgIcon-root': {
						display: 'inline',
					},
					'.MuiButton-root': {
						display: 'inline-flex',
					},
					border: isEditMode && '1px dashed #42A5F5',
				},
			}}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				sx={{
					top: '12px',
					width: '100%',
					margin: '12px 0px',
				}}
			>
				<Stack direction="row" sx={{ marginLeft: '8px', width: '90%' }}>
					<Box sx={{ width: '26px', height: '24px' }}>
						{isEditMode && (
							<DragIndicatorIcon
								sx={{
									// display: 'none',
									color: '#4D79EA',
									fontSize: '24px',
								}}
							/>
						)}
					</Box>
					<Tooltip title={chartTitle}>
						<Typography
							fontSize={24}
							sx={{
								fontWeight: 500,
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								fontFamily: 'Roboto',
								lineHeight: '24px',
								color: 'rgba(0, 0, 0, 0.9)',
								maxWidth: '90%',
							}}
						>
							{chartTitle}
						</Typography>
					</Tooltip>
				</Stack>
				{isHideToolBar && (
					<Stack
						direction="row"
						justifyContent="end"
						spacing={1}
						sx={{
							margin: '0px 16px 0px 16px',
							height: '24px',
							width: '20%',
						}}
					>
						{!!onDeleteChart && (
							<Button
								aria-label="Root chart delete"
								data-testid="deletebutton"
								sx={{
									minWidth: 30,
									height: 30,
									borderRadius: 100,
									background: 'rgba(255, 255, 255, 0.1)',
									color: 'rgba(255, 255, 255, 0.6)',
									'&:hover': {
										background: '#FFF',
									},
									marginRight: '40px',
									padding: 'unset',
									// display: 'none',
								}}
								onClick={() => {
									onDeleteChart && onDeleteChart(index);
								}}
							>
								<DeleteOutlinedIcon
									sx={{
										color: '#4D79EA',
										fontSize: '24px',
									}}
								/>
							</Button>
						)}
						<Button
							aria-label="Root chart edit"
							data-testid="editbutton"
							sx={{
								minWidth: 30,
								height: 30,
								borderRadius: 100,
								background: 'rgba(255, 255, 255, 0.1)',
								color: 'rgba(255, 255, 255, 0.6)',
								'&:hover': {
									background: '#FFF',
								},
								position: 'absolute',
								padding: 'unset',
								// display: 'none',
							}}
							onClick={() => {
								setIsReport && setIsReport(true);
								setChartIndex && setChartIndex(index);
							}}
						>
							<EditOutlinedIcon
								sx={{
									color: '#4D79EA',
									fontSize: '24px',
								}}
							/>
						</Button>
					</Stack>
				)}
			</Stack>
			{children}
		</Stack>
	);
}

ChartBox.propTypes = {
	/**
	 * index is chart box render image index
	 */
	index: PropTypes.number,
	/**
	 * Control to display the Tool Bar
	 */
	isHideToolBar: PropTypes.bool,
	/**
	 * setIsReport is func bool value to toggle new report popup
	 */
	setIsReport: PropTypes.func,
	/**
	 * setChartIndex is func bool value to set index of the chart
	 */
	setChartIndex: PropTypes.func,
	/**
	 * onDeleteChart is func bool value to remove existing chart of the report
	 */
	onDeleteChart: PropTypes.func,
	/**
	 * Control to Reading/Editing display
	 */
	isEditMode: PropTypes.bool,
};

export default ChartBox;
