// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dcDO2cwuqq0TXW_XBi4B{margin:20px 70px;border:none;background:rgba(0,0,0,0)}.dcDO2cwuqq0TXW_XBi4B table{width:100%;gap:5px;border:none;table-layout:fixed}.dcDO2cwuqq0TXW_XBi4B table tr{width:100%}.dcDO2cwuqq0TXW_XBi4B table tr td{height:30px;padding:5px 10px;border-radius:3px;background:rgba(196,196,196,.05)}.dcDO2cwuqq0TXW_XBi4B table tr td:hover{border:1px solid #4d79ea}.dcDO2cwuqq0TXW_XBi4B table tr td p{margin:0;color:#fff;font-family:Roboto,serif;font-size:14px;font-style:normal;font-weight:400;line-height:normal}.LF38c9aFvu_WYgL2wuW8{margin:0}._Fb0PQOoxIuwamNsMl9S{margin:10px 90px;padding:10px;border-radius:10px;border:1px solid #42a5f5;background:rgba(66,165,245,.05)}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/common/MainArea/Draft/TextBlocks/TableBlock/TableBlock.module.scss"],"names":[],"mappings":"AAAA,sBACC,gBAAA,CAEA,WAAA,CACA,wBAAA,CAEA,4BACC,UAAA,CACA,OAAA,CACA,WAAA,CACA,kBAAA,CAEA,+BACC,UAAA,CAEA,kCACC,WAAA,CACA,gBAAA,CAEA,iBAAA,CACA,gCAAA,CAEA,wCACC,wBAAA,CAGD,oCACC,QAAA,CAEA,UAAA,CACA,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CAOL,sBACC,QAAA,CAGD,sBACC,gBAAA,CACA,YAAA,CAEA,kBAAA,CACA,wBAAA,CACA,+BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "dcDO2cwuqq0TXW_XBi4B",
	"tableHover": "LF38c9aFvu_WYgL2wuW8",
	"tableSelected": "_Fb0PQOoxIuwamNsMl9S"
};
export default ___CSS_LOADER_EXPORT___;
