import React from 'react';
import { Controller } from 'react-hook-form';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';

const PlainDateTime = ({ formHook, name, props }) => (
	<Controller
		control={formHook.control}
		name={name}
		render={({ field }) => {
			const date = new Date(field.value);
			return (
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						rowGap: '2px',
						fontFamily: 'Roboto, sans-serif',
					}}
				>
					<Typography
						sx={{
							color: 'rgba(255, 255, 255, 0.5)',
							fontSize: '12px',
							fontWeight: '400',
						}}
					>
						{props.name}
					</Typography>
					<Typography sx={{ color: 'rgba(255, 255, 255, 0.87)' }}>
						{isNaN(date.getDate()) ? '' : date.toLocaleString()}
					</Typography>
				</Box>
			);
		}}
	/>
);

export default PlainDateTime;
