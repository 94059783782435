import { ZOOM_TYPES, DISTANCE_COMPENSATOR } from '../consts/consts';

export const original = (viewport, flag) => {
	const { imageRatio, viewportRatio, viewportWidth, viewportHeight, imageWidth, imageHeight } =
		calculateVars(viewport);
	let zoomValue;
	if (imageRatio > viewportRatio) {
		zoomValue = (imageWidth * DISTANCE_COMPENSATOR(flag)) / viewportWidth;
	} else {
		zoomValue = (imageHeight * DISTANCE_COMPENSATOR(flag)) / viewportHeight;
	}
	return zoomValue;
};

export const cover = (viewport, flag) => {
	const { imageRatio, viewportRatio, viewportWidth, viewportHeight, imageWidth, imageHeight } =
		calculateVars(viewport);
	let zoomValue;

	if (imageRatio > viewportRatio) {
		const imageOrginalScale = (imageWidth * DISTANCE_COMPENSATOR(flag)) / viewportWidth;
		zoomValue = (viewportHeight * imageOrginalScale) / imageHeight;
	} else {
		const imageOrginalScale = (imageHeight * DISTANCE_COMPENSATOR(flag)) / viewportHeight;
		zoomValue = (viewportWidth * imageOrginalScale) / imageWidth;
	}
	return zoomValue;
};

export const contain = (_, flag) => DISTANCE_COMPENSATOR(flag);
export const sizeType = {
	[ZOOM_TYPES.original]: original,
	[ZOOM_TYPES.contain]: contain,
	[ZOOM_TYPES.cover]: cover,
};

export const calculateVars = viewport => {
	const viewportHeight = viewport.sHeight;
	const viewportWidth = viewport.sWidth;
	const imageHeight = viewport.csImage.height;
	const imageWidth = viewport.csImage.width;
	const viewportRatio = viewportWidth / viewportHeight;
	const imageRatio = imageWidth / imageHeight;
	return { imageRatio, viewportRatio, viewportWidth, viewportHeight, imageWidth, imageHeight };
};

export const scaleDisplayArea = ({ displayArea, scale }) => ({ ...displayArea, imageArea: [1 / scale, 1 / scale] });

export const alignDisplayArea = ({ displayArea, align }) => {
	let canvasX = 0.5;
	let canvasY = 0.5;
	let imageX = 0.5;
	let imageY = 0.5;

	switch (align) {
		case 'top':
			canvasY = 0;
			imageY = 0;
			break;
		case 'right':
			canvasX = 1;
			imageX = 1;
			break;
		case 'bottom':
			canvasY = 1;
			imageY = 1;
			break;
		case 'left':
			imageX = 0;
			canvasX = 0;
			break;
		default:
			break;
	}

	return {
		...displayArea,
		imageCanvasPoint: {
			imagePoint: [imageX, imageY],
			canvasPoint: [canvasX, canvasY],
		},
	};
};

export const assignRule = ({ viewport, rule }) => {
	if (viewport.defaultOptions?.defaultRules) {
		const index = viewport.defaultOptions.defaultRules.findIndex(r => r.type === rule.type);

		viewport.defaultOptions.defaultRules.splice(
			index > -1 ? index : viewport.defaultOptions.defaultRules.length,
			index > -1 ? 1 : 0,
			rule
		);
	} else {
		viewport.defaultOptions = {
			...viewport.defaultOptions,
			defaultRules: [rule],
		};
	}
};
