import axios from 'axios';

const shareDocumentWithFacilityAPI = async ({ __config, hash, documentId, data }) => {
	const response = await axios.post(
		`${__config.data_sources.blume}Document/${documentId}/facilityShare/v2?hash=${hash}`,
		data
	);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default shareDocumentWithFacilityAPI;
