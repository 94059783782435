import React, { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CustomTextInput from './CustomTextInput';
import { useHelpCenter, mediaTablet } from '@worklist-2/core/src';
import { searchBarStyle } from '@worklist-2/ui/src/components/Help/helpStyles';

const HelpInput = () => {
	const { setView, setSearchKey } = useHelpCenter();
	const [keyword, setKeyword] = useState(localStorage.getItem('keyword') || '');
	useEffect(() => {
		localStorage.setItem('keyword', keyword);
	}, [keyword]);

	const handleSubmit = e => {
		e.preventDefault();
		setView(keyword.trim().length > 0 ? 'searchResult' : 'FAQs');
		setSearchKey(keyword);
	};

	return (
		<Box component="form" sx={searchBarStyle} onSubmit={handleSubmit}>
			<SearchIcon sx={{ m: '10px', color: '#121212', marginLeft: '19px' }} />
			<CustomTextInput
				inputProps={{ 'aria-label': 'Search' }}
				placeholder="Search"
				sx={{ ml: 1, flex: 1 }}
				value={keyword}
				onChange={e => setKeyword(e.target.value)}
			/>
			<IconButton
				aria-label="directions"
				color="primary"
				sx={{
					p: '10px',
					m: 'auto',
					mr: '10px',
					background: '#42a5f5',
					borderRadius: 1,
					color: '#FFF',
					[mediaTablet]: {
						width: '41px',
						height: '41px',
						marginTop: '2px',
					},
				}}
				type="submit"
			>
				<CheckIcon />
			</IconButton>
		</Box>
	);
};

export default HelpInput;
