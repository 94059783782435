import React from 'react';
import { Box, styled } from '@mui/material';

export const TimeSelectorButtonsWrap = styled(props => <Box {...props} />)({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	mt: 2,
	width: '100%',
});

export const TimeSelectorInputsWrap = styled(props => <Box {...props} />)({
	display: 'flex',
	width: '100%',
	flex: '1 1 auto',
	alignItems: 'center',
	justifyContent: 'center',
});

export const TimeInputsDivider = styled(props => <Box {...props} />)({
	margin: '0 12px',
	paddingBottom: '25px',
	fontSize: '50px',
});

export const inputProps = {
	sx: {
		maxWidth: '96px',
		fontSize: '50px',
		textAlign: 'center',
		padding: '2px',
		'&::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
		'&::-webkit-inner-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
	},
};

export const menuItemStyles = {
	height: '255px',
	width: '100%',
	maxWidth: '100%',
	display: 'flex',
	flexDirection: 'column',
};

export const menuItemHeaderStyles = {
	fontWeight: 400,
	fontSize: '12px',
	lineHeight: '24px',
	letterSpacing: '1.5px',
	opacity: 0.6,
	width: '100%',
	height: '40px',
	display: 'flex',
	alignItems: 'center',
};
