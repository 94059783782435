import _ from 'lodash';
import GetCalculatedSliceLocationString, { CalculateSliceLocation } from './GetCalculatedSliceLocationString';

const roundToTwoDecimals = num => {
	return Math.round(num * 100) / 100;
};

const overlayDictSource = {
	['(0032,1066)']: '(0032,1066)',
	StudyComments: 'Study Comments',
	['(0008,1030)']: '(0008,1030)',
	StudyDescription: 'Study Description',
	['(0008,103E)']: '(0008,103E)',
	SeriesDescription: 'Series Description',
	['0008,2111']: '0008,2111',
	DerivationDescription: 'Derivation Description',
	['0008,1070']: '0008,1070',
	TechName: "Tech's name",
	['0008,1010']: '0008,1010',
	StationName: 'Station Name',
	['0008,0080']: '0008,0080',
	InstitutionName: 'Institution Name',
	['0010,1010']: '0010,1010',
	Age: 'Age',
	['00200013']: '00200013',
	InstanceNumber: 'Instance Number',
	['0020,1041']: '0020,1041',
	SliceLocation: 'Slice Location',
	['00080020']: '00080020',
	StudyDate: 'Study Date',
	['0028,2110']: '0028,2110',
	ImageCompression: 'Image Compression',
	['00200037']: '00200037',
	ViewcodeTag: 'Viewcode Tag',
	['00281050']: '00281050',
	WindowCenterWidth: 'Window Center & Width',
	['(0008,0016a)']: '(0008,0016a)',
	SharpnessUnsharpMaskDegree: 'Sharpness & Unsharp Mask Degree',

	['(0018,1210)']: '(0018,1210)',
	ConvolutionKernel: 'Convolution Kernel',

	['0018,1310']: '0018,1310',
	AcquisitionMatrix: 'Acquisition Matrix',

	['0018,0083']: '0018,0083',
	NumberOfAverages: 'Number of averages',

	['0018,0091']: '0018,0091',
	EchoTrainLength: 'Echo Train Length',

	['00180023']: '00180023',
	MRAcquisitionType: 'MR Acquisition Type',

	['00180024']: '00180023',
	SequenceName: 'Sequence Name',

	['00180021']: '00180021',
	SequenceVariant: 'Sequence Variant',

	['(0010,0010)']: '(0010,0010)',
	PatientName: 'Patient Name',

	['(0010,0020)']: '(0010,0020)',
	PatientID: 'Patient ID',

	['(0010,0030)']: '(0010,0030)',
	DateOfBirth: 'Date of Birth',

	['(0020,0010)']: '(0020,0010)',
	StudyID: 'Study ID',

	['(0020,0011)']: '(0020,0011)',
	SeriesNumber: 'Series Number',

	['0010,0040']: '0010,0040',
	Sex: 'Sex',

	['(0018,0050)']: '(0018,0050)',
	SliceThickness: 'Slice Thickness',

	['(0018,0088)']: '(0018,0088)',
	SpacingBetweenSlices: 'Spacing Between Slices',

	['(0018,0010)']: '(0018,0010)',
	ContrastAgent: 'Contrast Agent',

	['0018,0060']: '0018,0060',
	KVP: 'KVP',

	['0018,1152']: '0018,1152',
	Exposure: 'Exposure',

	['0018,1150']: '0018,1150',
	ExposureTime: 'Exposure Time',

	['0018,11a2']: '0018,11a2',
	CompressionForce: 'Compression Force',

	['0018,1100']: '0018,1100',
	ReconstructionDiameter: 'Reconstruction Diameter',

	['0040,0316']: '0040,0316',
	OrganDose: 'Organ Dose',

	['0018,1510']: '0018,1510',
	PositionerPrimaryAngle: 'Positioner Primary Angle',

	['0018,11a0']: '0018,11a0',
	BodyPartThickness: 'Body Part Thickness',

	['0018,0080']: '0018,0080',
	RepetitionTime: 'Repetition Time',

	['0018,0081']: '0018,0081',
	EchoTime: 'Echo Time',

	['0018,0082']: '0018,0082',
	InversionTime: 'Inversion Time',

	['0018,1060']: '0018,1060',
	TriggerTime: 'Trigger Time',

	['0018,1314']: '0018,1314',
	FlipAngle: 'Flip Angle',

	['00180091']: '00180091',
	ETLength: 'Echo Train Length',

	['00180095']: '00180095',
	PixelBandwidth: 'Pixel Bandwidth',
	FrameNumber: 'Frame Number',
	FrameNumberTotalFrames: 'Frame Number/Total Frames',
	CurrentOrPriorIndicator: 'Current or Prior indicator',
	Acquisition: 'Acquisition',
	SmartImageCompression: 'Smart Image Compression',
};

const overlayDict = {
	StudyComments: 'Study Comments (0032,1066)',
	StudyDescription: 'Study Description (0008,1030)',
	SeriesDescription: 'Series Description (0008,103E)',
	DerivationDescription: 'Derivation Description (0008,2111)',
	TechName: "Tech's name (0008,1070)",
	StationName: 'Station Name (0008,1010)',
	InstitutionName: 'Institution Name (0008,0080)',
	Age: 'Age (0010,1010)',
	InstanceNumber: 'Instance Number (0020,0013)',
	SliceLocation: 'Slice Location (0020,1041)',
	CurrentOrPriorIndicator: 'Current or Prior indicator',
	Acquisition: 'Acquisition',
	StudyDate: 'Study Date (0008,0020)',
	ImageCompression: 'Image Compression (0028,2110)',
	ViewcodeTag: 'Viewcode Tag (0020,0037)',
	WindowCenterWidth: 'Window Center & Width (0028,1050)',
	SharpnessUnsharpMaskDegree: 'Sharpness & Unsharp Mask Degree (0008,0016a)',
	ConvolutionKernel: 'Convolution Kernel (0018,1210)',
	AcquisitionMatrix: 'Acquisition Matrix (0018,1310)',
	NumberOfAverages: 'Number of averages (0018,0083)',
	EchoTrainLength: 'Echo Train Length (0018,0091)',
	MRAcquisitionType: 'MR Acquisition Type (0018,0023)',
	SequenceName: 'Sequence Name (0018,0024)',
	SequenceVariant: 'Sequence Variant (00180021)',
	PatientName: 'Patient Name (0010,0010)',
	PatientID: 'Patient ID (0010,0020)',
	DateOfBirth: 'Date of Birth (0010,0030)',
	StudyID: 'Study ID (0020,0010)',
	SeriesNumber: 'Series Number (0020,0011)',
	Sex: 'Sex (0010,0040)',
	SliceThickness: 'Slice Thickness (0018,0050)',
	SpacingBetweenSlices: 'Spacing Between Slices (0018,0088)',
	ContrastAgent: 'Contrast Agent (0018,0010)',
	KVP: 'KVP (0018,0060)',
	Current: 'Current (0018,1151)',
	Exposure: 'Exposure (0018,1152)',
	ExposureTime: 'Exposure Time (0018,1150)',
	CompressionForce: 'Compression Force (0018,11a2)',
	ReconstructionDiameter: 'Reconstruction Diameter (0018,1100)',
	OrganDose: 'Organ Dose (0040,0316)',
	PositionerPrimaryAngle: 'Positioner Primary Angle (0018,1510)',
	BodyPartThickness: 'Body Part Thickness (0018,11a0)',
	RepetitionTime: 'Repetition Time (0018,0080)',
	EchoTime: 'Echo Time (0018,0081)',
	InversionTime: 'Inversion Time (0018,0082)',
	TriggerTime: 'Trigger Time (0018,1060)',
	FlipAngle: 'Flip Angle (0018,1314)',
	ETLength: 'Echo Train Length (0018,0091)',
	PixelBandwidth: 'Pixel Bandwidth (0018,0095)',
	FrameNumber: 'Frame Number',
	FrameNumberTotalFrames: 'Frame Number/Total Frames',
	SmartImageCompression: 'Smart Image Compression',
};

const getValueWithFallback = (instance, tagA, tagB) => {
	let firstTag = instance?.[tagA]?.Value?.at(0);
	if (typeof firstTag !== 'string' || firstTag === null || firstTag.trim() === '') {
		return instance?.[tagB]?.Value?.at(0);
	}
	return firstTag;
};

const getOverlayDisplay = (activeInstance, isCurrentStudy) => [
	// Unchanged Tags
	{
		name: overlayDictSource.FrameNumber,
		value:
			activeInstance?.series?.imageIds?.length > 1
				? activeInstance?.activeScroll?.toString()
					? (activeInstance?.activeScroll + 1).toString()
					: ''
				: '',
	},
	{
		name: overlayDictSource.FrameNumberTotalFrames,
		value:
			activeInstance?.series?.imageIds?.length > 1
				? activeInstance?.activeScroll?.toString()
					? `${(activeInstance?.activeScroll + 1).toString()}/${activeInstance?.series?.imageIds?.length}`
					: ''
				: '',
	},
	{ name: overlayDictSource['(0032,1066)'], value: getValueWithFallback(activeInstance, '00321066', '00324000') },
	{ name: overlayDictSource.StudyComments, value: getValueWithFallback(activeInstance, '00321066', '00324000') },
	{ name: overlayDictSource['(0008,1030)'], value: activeInstance?.['00081030']?.Value?.at(0) },
	{ name: overlayDictSource.StudyDescription, value: activeInstance?.['00081030']?.Value?.at(0) },
	{ name: overlayDictSource['(0008,103E)'], value: activeInstance?.['0008103e']?.Value?.at(0) },
	{ name: overlayDictSource.SeriesDescription, value: activeInstance?.['0008103e']?.Value?.at(0) },
	{ name: overlayDictSource['0008,2111'], value: activeInstance?.['00082111']?.Value?.at(0) },
	{ name: overlayDictSource.DerivationDescription, value: activeInstance?.['00082111']?.Value?.at(0) },
	{ name: overlayDictSource['0008,1070'], value: activeInstance?.['00081070']?.Value?.at(0)?.Alphabetic },
	{ name: overlayDictSource.TechName, value: activeInstance?.['00081070']?.Value?.at(0)?.Alphabetic },
	{ name: overlayDictSource['0008,1010'], value: activeInstance?.['00081010']?.Value?.at(0) },
	{ name: overlayDictSource.StationName, value: activeInstance?.['00081010']?.Value?.at(0) },
	{ name: overlayDictSource['0008,0080'], value: activeInstance?.['00080080']?.Value?.at(0) },
	{ name: overlayDictSource.InstitutionName, value: activeInstance?.['00080080']?.Value?.at(0) },
	// Conditional Tags
	{ name: overlayDictSource['0010,1010'], value: getAgeDisplay(activeInstance) },
	{ name: overlayDictSource.Age, value: getAgeDisplay(activeInstance) },

	{
		name: overlayDictSource['00200013'],
		value: activeInstance?.['00280008']?.Value?.at(0)
			? `${activeInstance?.['00200013']?.Value?.at(0)}/${activeInstance?.['00280008']?.Value?.at(0)}`
			: `${activeInstance?.['00200013']?.Value?.at(0)}/1`,
	},
	{
		name: overlayDictSource.InstanceNumber,
		value: activeInstance?.['00280008']?.Value?.at(0)
			? `${activeInstance?.['00200013']?.Value?.at(0)}/${activeInstance?.['00280008']?.Value?.at(0)}`
			: `${activeInstance?.['00200013']?.Value?.at(0)}/1`,
	},

	{ name: overlayDictSource['0020,1041'], value: getSliceLocationDisplay(activeInstance) },
	{ name: overlayDictSource.SliceLocation, value: getSliceLocationDisplay(activeInstance) },
	{ name: overlayDictSource.CurrentOrPriorIndicator, value: isCurrentStudy ? 'Current' : 'Prior' },
	// Date Time Tags
	{ name: overlayDictSource.Acquisition, value: getAcquisitionDisplay(activeInstance) },

	{ name: overlayDictSource['00080020'], value: getStudyDateDisplay(activeInstance) },
	{ name: overlayDictSource.StudyDate, value: getStudyDateDisplay(activeInstance) },
	// Image Compression Tag
	{
		name: overlayDictSource['0028,2110'],
		value: getImageCompressionDisplay(activeInstance),
		format: getImageCompressionFormat(activeInstance),
	},
	{
		name: overlayDictSource.ImageCompression,
		value: getImageCompressionDisplay(activeInstance),
		format: getImageCompressionFormat(activeInstance),
	},
	// Viewcode Tag
	{ name: overlayDictSource['00200037'], value: getViewcodeTagDisplay(activeInstance) },
	{ name: overlayDictSource.ViewcodeTag, value: getViewcodeTagDisplay(activeInstance) },
	// Formatted Tags
	{
		name: overlayDictSource['00281050'],
		value: `C:${Math.round(activeInstance?.['00281050']?.Value?.at(0))} - W: ${Math.round(
			activeInstance?.['00281051']?.Value?.at(0)
		)}`,
	},
	{
		name: overlayDictSource.WindowCenterWidth,
		value: `C:${Math.round(activeInstance?.['00281050']?.Value?.at(0))} - W: ${Math.round(
			activeInstance?.['00281051']?.Value?.at(0)
		)}`,
	},
	{
		name: overlayDictSource['(0008,0016a)'],
		value: `S:${activeInstance?.['0016004a']?.Value?.at(0)} - U:${activeInstance?.['310d1020']?.Value?.at(0)}`,
	},
	{
		name: overlayDictSource['(0008,0016a)'],
		value: `S:${activeInstance?.['0016004a']?.Value?.at(0)} - U:${activeInstance?.['310d1020']?.Value?.at(0)}`,
	},
	{
		name: overlayDictSource.SharpnessUnsharpMaskDegree,
		value: `S:${activeInstance?.['0016004a']?.Value?.at(0)} - U:${activeInstance?.['310d1020']?.Value?.at(0)}`,
	},

	{ name: overlayDictSource['(0018,1210)'], value: `CK: ${activeInstance?.['00181210']?.Value?.at(0)}` },
	{ name: overlayDictSource.ConvolutionKernel, value: `CK: ${activeInstance?.['00181210']?.Value?.at(0)}` },

	{ name: overlayDictSource['0018,1310'], value: `ACQ Matrix: ${activeInstance?.['00181310']?.Value?.at(0)}` },
	{ name: overlayDictSource.AcquisitionMatrix, value: `ACQ Matrix: ${activeInstance?.['00181310']?.Value?.at(0)}` },

	{ name: overlayDictSource['0018,0083'], value: `NEX: ${activeInstance?.['00180083']?.Value?.at(0)}` },
	{ name: overlayDictSource.NumberOfAverages, value: `NEX: ${activeInstance?.['00180083']?.Value?.at(0)}` },

	{ name: overlayDictSource['0018,0091'], value: `ETL: ${activeInstance?.['00180091']?.Value?.at(0)}` },
	{ name: overlayDictSource.EchoTrainLength, value: `ETL: ${activeInstance?.['00180091']?.Value?.at(0)}` },

	{ name: overlayDictSource['00180023'], value: `MAT: ${activeInstance?.['00180023']?.Value?.at(0)}` },
	{ name: overlayDictSource.MRAcquisitionType, value: `MAT: ${activeInstance?.['00180023']?.Value?.at(0)}` },

	{ name: overlayDictSource['00180023'], value: `SEQ: ${activeInstance?.['00180024']?.Value?.at(0)}` },
	{ name: overlayDictSource.SequenceName, value: `SEQ: ${activeInstance?.['00180024']?.Value?.at(0)}` },

	{ name: overlayDictSource['00180021'], value: `SV: ${activeInstance?.['00180021']?.Value?.at(0)}` },
	{ name: overlayDictSource.SequenceVariant, value: `SV: ${activeInstance?.['00180021']?.Value?.at(0)}` },

	{ name: overlayDictSource['(0010,0010)'], value: `PN: ${activeInstance?.['00100010']?.Value?.at(0)?.Alphabetic}` },
	{ name: overlayDictSource.PatientName, value: `PN: ${activeInstance?.['00100010']?.Value?.at(0)?.Alphabetic}` },

	{ name: overlayDictSource['(0010,0020)'], value: `PID: ${activeInstance?.['00100020']?.Value?.at(0)}` },
	{ name: overlayDictSource.PatientID, value: `PID: ${activeInstance?.['00100020']?.Value?.at(0)}` },

	{ name: overlayDictSource['(0010,0030)'], value: `DOB: ${activeInstance?.['00100030']?.Value?.at(0)}` },
	{ name: overlayDictSource.DateOfBirth, value: `DOB: ${activeInstance?.['00100030']?.Value?.at(0)}` },

	{ name: overlayDictSource['(0020,0010)'], value: `SID: ${activeInstance?.['00200010']?.Value?.at(0)}` },
	{ name: overlayDictSource.StudyID, value: `SID: ${activeInstance?.['00200010']?.Value?.at(0)}` },

	{ name: overlayDictSource['(0020,0011)'], value: `S#: ${activeInstance?.['00200011']?.Value?.at(0)}` },
	{ name: overlayDictSource.SeriesNumber, value: `S#: ${activeInstance?.['00200011']?.Value?.at(0)}` },

	{ name: overlayDictSource['0010,0040'], value: `Sex: ${activeInstance?.['00100040']?.Value?.at(0)}` },
	{ name: overlayDictSource.Sex, value: `Sex: ${activeInstance?.['00100040']?.Value?.at(0)}` },

	{
		name: overlayDictSource['(0018,0050)'],
		value: `ST: ${roundToTwoDecimals(activeInstance?.['00180050']?.Value?.at(0))}mm`,
	},
	{
		name: overlayDictSource.SliceThickness,
		value: `ST: ${roundToTwoDecimals(activeInstance?.['00180050']?.Value?.at(0))}mm`,
	},

	{
		name: overlayDictSource['(0018,0088)'],
		value: `SBS: ${roundToTwoDecimals(activeInstance?.['00180088']?.Value?.at(0))}mm`,
	},
	{
		name: overlayDictSource.SpacingBetweenSlices,
		value: `SBS: ${roundToTwoDecimals(activeInstance?.['00180088']?.Value?.at(0))}mm`,
	},

	{ name: overlayDictSource['(0018,0010)'], value: `${activeInstance?.['00180010']?.Value?.at(0)?.substr(0, 30)}` },
	{ name: overlayDictSource.ContrastAgent, value: `${activeInstance?.['00180010']?.Value?.at(0)?.substr(0, 30)}` },

	{
		name: overlayDictSource['0018,0060'],
		value: `KVP: ${roundToTwoDecimals(activeInstance?.['00180060']?.Value?.at(0))}`,
	},
	{ name: overlayDictSource.KVP, value: `KVP: ${roundToTwoDecimals(activeInstance?.['00180060']?.Value?.at(0))}` },

	{
		name: overlayDictSource['0018,1152'],
		value: `Exp: ${roundToTwoDecimals(activeInstance?.['00181152']?.Value?.at(0))}mA`,
	},
	{
		name: overlayDictSource.Exposure,
		value: `Exp: ${roundToTwoDecimals(activeInstance?.['00181152']?.Value?.at(0))}mA`,
	},

	{
		name: overlayDictSource['0018,1150'],
		value: `ET: ${roundToTwoDecimals(activeInstance?.['00181150']?.Value?.at(0))}ms`,
	},
	{
		name: overlayDictSource.ExposureTime,
		value: `ET: ${roundToTwoDecimals(activeInstance?.['00181150']?.Value?.at(0))}ms`,
	},

	{
		name: overlayDictSource['0018,11a2'],
		value: `CF: ${roundToTwoDecimals(activeInstance?.['001811a2']?.Value?.at(0))}N`,
	},
	{
		name: overlayDictSource.CompressionForce,
		value: `CF: ${roundToTwoDecimals(activeInstance?.['001811a2']?.Value?.at(0))}N`,
	},

	{
		name: overlayDictSource['0018,1100'],
		value: `RD: ${roundToTwoDecimals(activeInstance?.['00181100']?.Value?.at(0))}mm`,
	},
	{
		name: overlayDictSource.ReconstructionDiameter,
		value: `RD: ${roundToTwoDecimals(activeInstance?.['00181100']?.Value?.at(0))}mm`,
	},

	{
		name: overlayDictSource['0040,0316'],
		value: `OD: ${roundToTwoDecimals(activeInstance?.['00400316']?.Value?.at(0))}dGy`,
	},
	{
		name: overlayDictSource.OrganDose,
		value: `OD: ${roundToTwoDecimals(activeInstance?.['00400316']?.Value?.at(0))}dGy`,
	},

	{
		name: overlayDictSource['0018,1510'],
		value: `PPA: ${roundToTwoDecimals(activeInstance?.['00181510']?.Value?.at(0))}°`,
	},
	{
		name: overlayDictSource.PositionerPrimaryAngle,
		value: `PPA: ${roundToTwoDecimals(activeInstance?.['00181510']?.Value?.at(0))}°`,
	},

	{
		name: overlayDictSource['0018,11a0'],
		value: `Thk: ${roundToTwoDecimals(activeInstance?.['001811a0']?.Value?.at(0))}mm`,
	},
	{
		name: overlayDictSource.BodyPartThickness,
		value: `Thk: ${roundToTwoDecimals(activeInstance?.['001811a0']?.Value?.at(0))}mm`,
	},

	{
		name: overlayDictSource['0018,0080'],
		value: `TR: ${roundToTwoDecimals(activeInstance?.['00180080']?.Value?.at(0))}ms`,
	},
	{
		name: overlayDictSource.RepetitionTime,
		value: `TR: ${roundToTwoDecimals(activeInstance?.['00180080']?.Value?.at(0))}ms`,
	},

	{
		name: overlayDictSource['0018,0081'],
		value: `TE: ${roundToTwoDecimals(activeInstance?.['00180081']?.Value?.at(0))}ms`,
	},
	{
		name: overlayDictSource.EchoTime,
		value: `TE: ${roundToTwoDecimals(activeInstance?.['00180081']?.Value?.at(0))}ms`,
	},

	{
		name: overlayDictSource['0018,0082'],
		value: `TI: ${roundToTwoDecimals(activeInstance?.['00180082']?.Value?.at(0))}ms`,
	},
	{
		name: overlayDictSource.InversionTime,
		value: `TI: ${roundToTwoDecimals(activeInstance?.['00180082']?.Value?.at(0))}ms`,
	},

	{
		name: overlayDictSource['0018,1060'],
		value: `TD: ${roundToTwoDecimals(activeInstance?.['00181060']?.Value?.at(0))}ms`,
	},
	{
		name: overlayDictSource.TriggerTime,
		value: `TD: ${roundToTwoDecimals(activeInstance?.['00181060']?.Value?.at(0))}ms`,
	},

	{
		name: overlayDictSource['0018,1314'],
		value: `Flip: ${roundToTwoDecimals(activeInstance?.['00181314']?.Value?.at(0))}°`,
	},
	{
		name: overlayDictSource.FlipAngle,
		value: `Flip: ${roundToTwoDecimals(activeInstance?.['00181314']?.Value?.at(0))}°`,
	},

	{ name: overlayDictSource['0018,1310'], value: `Matrix: ${activeInstance?.['00181310']?.Value?.at(0)}` },
	{ name: overlayDictSource.AcquisitionMatrix, value: `Matrix: ${activeInstance?.['00181310']?.Value?.at(0)}` },

	{ name: overlayDictSource['0018,0083'], value: `Excite: ${activeInstance?.['00180083']?.Value?.at(0)}` },
	{ name: overlayDictSource.NumberOfAverages, value: `Excite: ${activeInstance?.['00180083']?.Value?.at(0)}` },

	{
		name: overlayDictSource['0018,0091'],
		value: `ETL: ${roundToTwoDecimals(activeInstance?.['00180091']?.Value?.at(0))}`,
	},
	{
		name: overlayDictSource.EchoTrainLength,
		value: `ETL: ${roundToTwoDecimals(activeInstance?.['00180091']?.Value?.at(0))}`,
	},

	{
		name: overlayDictSource['00180095'],
		value: `BW: ${roundToTwoDecimals(activeInstance?.['00180095']?.Value?.at(0))}Hz`,
	},
	{
		name: overlayDictSource.PixelBandwidth,
		value: `BW: ${roundToTwoDecimals(activeInstance?.['00180095']?.Value?.at(0))}Hz`,
	},
	{
		// New code
		name: overlayDictSource.SmartImageCompression,
		value: (instance => {
			const LossyImageCompression = instance?.['00282110']?.Value?.at(0);
			const LossyImageCompressionRatio = instance?.['00282112']?.Value?.at(0);
			const LossyImageCompressionMethod = instance?.['00282114']?.Value?.at(0);
			const TransferSyntaxUID = instance?.['00020010']?.Value?.at(0);

			if (
				// lossy conditions
				TransferSyntaxUID == '1.2.840.10008.1.2.4.50' ||
				TransferSyntaxUID == '1.2.840.10008.1.2.4.51' ||
				TransferSyntaxUID == '1.2.840.10008.1.2.4.52' ||
				TransferSyntaxUID == '1.2.840.10008.1.2.4.81' ||
				TransferSyntaxUID == '1.2.840.10008.1.2.4.91' ||
				TransferSyntaxUID == '1.2.840.10008.1.2.4.93' ||
				LossyImageCompression == '01' ||
				LossyImageCompressionRatio ||
				LossyImageCompressionMethod
			) {
				if (LossyImageCompressionRatio) {
					return 'Lossy ' + Math.round(LossyImageCompressionRatio) + ':1';
				}

				return 'Lossy';
			}

			return undefined;
		})(activeInstance),
	},
];

export const parseDate = date => {
	let dateDisplay;
	if (date && date.length === 8) {
		const year = date.substr(0, 4);
		const month = date.substr(4, 2);
		const day = date.substr(6, 2);

		dateDisplay = month + '-' + day + '-' + year;
	}
	return dateDisplay;
};

export const parseTime = time => {
	let timeDisplay;
	if (time && time.length === 6) {
		const hour = time.substr(0, 2);
		const min = time.substr(2, 2);
		const sec = time.substr(4, 2);

		timeDisplay = hour + ':' + min + ':' + sec;
	}
	return timeDisplay;
};

const getAgeDisplay = activeInstance => {
	const age = activeInstance?.['00101010']?.Value?.at(0);
	const dateOfBirth = activeInstance?.['00100030']?.Value?.at(0);

	if (age) return age;

	if (!age && dateOfBirth) {
		const yearOfBirth = parseInt(dateOfBirth.substring(0, 4));
		const monthOfBirth = parseInt(dateOfBirth.substring(4, 6));
		const dayOfBirth = parseInt(dateOfBirth.substring(6, 8));

		const birthDate = new Date(`${yearOfBirth}/${monthOfBirth}/${dayOfBirth}`);
		const today = new Date();
		const diffTime = today.getTime() - birthDate.getTime();
		const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

		const years = Math.floor(diffDays / 365);
		const months = Math.floor((diffDays % 365) / 30);
		const days = diffDays - years * 365 - months * 30;

		// Format age
		if (years >= 2) return `${years}Y ${months}M ${days}D`;

		if (years === 1) return `${years}Y, ${months}M`;

		if (months >= 1) return `${months}M`;
		else return `${days}D`;
	}

	return undefined;
};

const getSliceLocationDisplay = instance => {
	const sliceLocation = roundToTwoDecimals(instance?.['00201041']?.Value?.at(0));
	const tablePosition = roundToTwoDecimals(instance?.['00189327']?.Value?.at(0));
	const currentSliceIndex = parseInt(instance?.activeScroll);
	const imagePositionPatient =
		instance?.['52009230']?.Value[currentSliceIndex]?.['00209113']?.Value?.[0]?.['00200032']?.Value ||
		instance?.['00200032']?.Value;
	const imageOrientationPatient =
		instance?.['52009230']?.Value?.[currentSliceIndex]?.['00209116']?.Value?.[0]?.['00200037']?.Value ||
		instance?.['00200037']?.Value;
	const numberOfFrames = parseInt(instance?.['00280008']?.Value[0]);

	if (sliceLocation) {
		return `SL: ${sliceLocation}mm`;
	}
	if (tablePosition) {
		return `SL: ${tablePosition}mm`;
	}

	try {
		if (numberOfFrames > 1) {
			const calculatedSliceLoacationStr = GetCalculatedSliceLocationString(instance);
			if (calculatedSliceLoacationStr) {
				return `SL: ${calculatedSliceLoacationStr}mm`;
			}
		}

		if (imagePositionPatient && imageOrientationPatient) {
			const calculatedSliceLoacation = roundToTwoDecimals(
				CalculateSliceLocation(imagePositionPatient, imageOrientationPatient)
			);
			if (calculatedSliceLoacation != null) {
				return `SL: ${calculatedSliceLoacation}mm`;
			}
		}
	} catch (error) {
		console.error('An error occurred during CalculateSliceLocation:', error);
	}
};

const getAcquisitionDisplay = instance => {
	const AcquisitionDateTime = instance?.['0008002a']?.Value?.at(0);
	const AcquisitionDate = instance?.['00080022']?.Value?.at(0);
	const AcquisitionTime = instance?.['00080032']?.Value?.at(0);
	const ContentDate = instance?.['00080023']?.Value?.at(0);
	const ContentTime = instance?.['00080033']?.Value?.at(0);

	if (AcquisitionDateTime) {
		let AcquisitionDateTimeDisplay = AcquisitionDateTime;
		if (AcquisitionDateTime.length >= 14) {
			AcquisitionDateTimeDisplay =
				parseDate(AcquisitionDateTime.substr(0, 8)) + ' ' + parseTime(AcquisitionDateTime.substr(8, 6));
		}
		return AcquisitionDateTimeDisplay;
	}

	if (AcquisitionDate && AcquisitionTime)
		return parseDate(AcquisitionDate) + ' ' + parseTime(AcquisitionTime.substr(0, 6));

	if (ContentDate && ContentTime) return parseDate(ContentDate) + ' ' + parseTime(ContentTime.substr(0, 6));
};

const getStudyDateDisplay = instance => {
	const StudyDate = instance?.['00080020']?.Value?.at(0);
	const StudyTime = instance?.['00080030']?.Value?.at(0);
	const SeriesDate = instance?.['00080021']?.Value?.at(0);
	const SeriesTime = instance?.['00080031']?.Value?.at(0);

	if (StudyDate && StudyTime) return parseDate(StudyDate) + ' ' + parseTime(StudyTime);

	if (SeriesDate && SeriesTime) return parseDate(SeriesDate) + ' ' + parseTime(SeriesTime);
};

const getImageCompressionDisplay = instance => {
	const LossyImageCompression = instance?.['00282110']?.Value?.at(0);
	const LossyImageCompressionRatio = instance?.['00282112']?.Value?.at(0);
	const LossyImageCompressionMethod = instance?.['00282114']?.Value?.at(0);
	const TransferSyntaxUID = instance?.['00020010']?.Value?.at(0);

	if (LossyImageCompression == '00') {
		return 'Lossless';
	}
	if (LossyImageCompression == '01' && LossyImageCompressionRatio) {
		return 'Lossy ' + roundToTwoDecimals(LossyImageCompressionRatio) + ':1 ' + LossyImageCompressionMethod;
	}
	if (!LossyImageCompression && TransferSyntaxUID) {
		if (
			TransferSyntaxUID == '1.2.840.10008.1.2' ||
			TransferSyntaxUID == '1.2.840.10008.1.2.1' ||
			TransferSyntaxUID == '1.2.840.10008.1.2.1.99' ||
			TransferSyntaxUID == '1.2.840.10008.1.2.2'
		) {
			return 'Uncompressed';
		}
		if (
			TransferSyntaxUID == '1.2.840.10008.1.2.4.50' ||
			TransferSyntaxUID == '1.2.840.10008.1.2.4.51' ||
			TransferSyntaxUID == '1.2.840.10008.1.2.4.52'
		) {
			return 'JPEG Lossy';
		}
		if (TransferSyntaxUID == '1.2.840.10008.1.2.4.57' || TransferSyntaxUID == '1.2.840.10008.1.2.4.70') {
			return 'JPEG Lossless';
		}
		if (TransferSyntaxUID == '1.2.840.10008.1.2.4.80') {
			return 'JPEG-LS (Lossless)';
		}
		if (TransferSyntaxUID == '1.2.840.10008.1.2.4.81') {
			return 'JPEG-LS Lossy';
		}
		if (TransferSyntaxUID == '1.2.840.10008.1.2.4.90' || TransferSyntaxUID == '1.2.840.10008.1.2.4.92') {
			return 'JPEG 2K (Lossless)';
		}
		if (TransferSyntaxUID == '1.2.840.10008.1.2.4.91' || TransferSyntaxUID == '1.2.840.10008.1.2.4.93') {
			return 'JPEG 2K Lossy';
		}
		if (TransferSyntaxUID == '1.2.840.10008.1.2.5') {
			return 'RLE Lossless';
		}
		if (TransferSyntaxUID == '1.2.840.10008.1.2.4.100' || TransferSyntaxUID == '1.2.840.10008.1.2.4.101') {
			return 'MPEG-2';
		}
		if (TransferSyntaxUID == '1.2.840.10008.1.2.4.102' || TransferSyntaxUID == '1.2.840.10008.1.2.4.103') {
			return ' MPEG-4';
		}
	}
};

const getImageCompressionFormat = instance => {
	// Return css format
	const LossyImageCompression = instance?.['00282110']?.Value?.at(0);
	const LossyImageCompressionRatio = instance?.['00282112']?.Value?.at(0);
	const TransferSyntaxUID = instance?.['00020010']?.Value?.at(0);

	const redTextCSS = 'color: red;';

	if (LossyImageCompression == '01' && LossyImageCompressionRatio) {
		return redTextCSS;
	}

	if (!LossyImageCompression && TransferSyntaxUID) {
		if (
			TransferSyntaxUID == '1.2.840.10008.1.2.4.50' ||
			TransferSyntaxUID == '1.2.840.10008.1.2.4.51' ||
			TransferSyntaxUID == '1.2.840.10008.1.2.4.52' ||
			TransferSyntaxUID == '1.2.840.10008.1.2.4.81' ||
			TransferSyntaxUID == '1.2.840.10008.1.2.4.91'
		) {
			return redTextCSS;
		}
	}

	return undefined;
};

const getViewcodeTagDisplay = instance => {
	const ViewIndicator = instance?.['00200037']?.Value;
	const modalityCode = instance?.['00080060']?.Value?.at(0);
	const laterality = instance?.['00200062']?.Value?.at(0);
	const majorCode = instance['00540222']?.Value?.[0]?.['00080100']?.Value?.at(0);
	const modifierCode = instance['00540222']?.Value?.[0]?.['00080102']?.Value?.at(0);
	const imageType = instance['00080008']?.Value;
	let viewCodeDisplay = '';

	if (['MR', 'CT', 'PET'].includes(modalityCode)) {
		if (ViewIndicator && _.isEqual(ViewIndicator, ['1', '0', '0', '0', '0', '-1'])) {
			return 'View: Coronal';
		}

		if (ViewIndicator && _.isEqual(ViewIndicator, ['0', '1', '0', '0', '0', '-1'])) {
			return 'View: Sagittal';
		}

		if (ViewIndicator && _.isEqual(ViewIndicator, ['1', '0', '0', '0', '1', '0'])) {
			return 'View: Axial';
		}
	}

	if (modalityCode == 'MG') {
		const majorCodeMapping = {
			'R-10242': 'CC',
			'R-10244': 'FB',
			'R-40AAA': 'ISO',
			'R-10228': 'LM',
			'R-10230': 'LMO',
			'R-10224': 'ML',
			'R-10226': 'MLO',
			'R-102D0': 'SIO',
			'R-102CF': 'XCC',
			'R-1024A': 'XCCL',
			'Y-X1770': 'XCCL',
			'R-1024B': 'XCCM',
			'Y-X1771': 'XCCM',
		};

		const modifiersMapping = {
			'R-102D5': 'ID',
			'R-102CA': 'RI',
			'R-102D3': 'RL',
			'R-102D4': 'RM',
			'R-102C9': 'RS',
			'R-40AB3': 'NP',
			'P2-00161': 'AC',
			'R-40ABE': 'IMF',
			'R-40AB2': 'AX',
			'R-102D6': 'M',
			'R-102D7': 'S',
			'R-102D2': 'CV',
			'R-102C2': 'TAN',
		};

		let appendStr = '';
		if (imageType?.includes('TOMOSYNTHESIS') || imageType?.includes('VOLUME')) {
			if (imageType?.includes('GENERATED_2D')) {
				appendStr = 'C-View';
			} else {
				appendStr = '3D';
			}
		}

		if (laterality) {
			viewCodeDisplay += laterality;
		}
		if (majorCodeMapping[majorCode]) {
			viewCodeDisplay += majorCodeMapping[majorCode];
		}
		if (modifiersMapping[modifierCode]) {
			viewCodeDisplay += modifiersMapping[modifierCode];
		}
		if (appendStr) {
			viewCodeDisplay += ' ' + appendStr;
		}
		return viewCodeDisplay;
	}
};

export default getOverlayDisplay;
export {
	overlayDict,
	getAgeDisplay,
	getSliceLocationDisplay,
	getAcquisitionDisplay,
	getStudyDateDisplay,
	getImageCompressionDisplay,
	getImageCompressionFormat,
	getViewcodeTagDisplay,
};
