import React, { useState } from 'react';
import { Typography, Select, MenuItem } from '@mui/material';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ToggleSwitch from './ToggleSwitch';
import { useAuth } from '@worklist-2/core/src';
import { useLiveChatContext } from './LiveChatContext';
import UserAvatar from './UserAvatar';

function ProfileModal({ isOpen, onClose }) {
	const [isStatusDisabled, setIsStatusDisabled] = useState(false);
	const [isTextDisabled, setIsTextDisabled] = useState(false);
	const [isVoiceDisabled, setIsVoiceDisabled] = useState(false);
	const { loggedInUser } = useAuth();
	const {
		STATUSES,
		handleStatusChange,
		selectedStatus,
		isTextAvailable,
		isVoiceAvailable,
		handleAvailabilityChange,
	} = useLiveChatContext();

	const handleStatusSelect = async e => {
		setIsStatusDisabled(true);
		await handleStatusChange(e);
		setIsStatusDisabled(false);
	};

	const handleAvailabilityToggle = async (type, setDisabled) => {
		setDisabled(true);
		await handleAvailabilityChange(type);
		setDisabled(false);
	};

	return (
		<div
			style={{
				zIndex: '1',
				position: 'absolute',
				top: '0',
				visibility: isOpen ? 'visible' : 'hidden',
				maxWidth: '456px',
				width: '100%',
				margin: '8px 0 0 8px',
				padding: '26px 23px 32px',
				backgroundColor: '#FFFFFF',
				borderRadius: '20px',
				boxShadow: '0px 0px 20px rgba(33, 88, 119, 0.1)',
				boxSizing: 'border-box',
				opacity: isOpen ? '1' : '0',
				transition: 'visibility 0s, opacity 0.2s linear',
			}}
			onMouseLeave={onClose}
		>
			<div
				style={{
					marginBottom: '28px',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
				}}
			>
				<UserAvatar size={54} status={selectedStatus} userName={loggedInUser?.fullName} />
				<div style={{ marginLeft: '25px' }}>
					<Typography
						data-testid="authorName"
						style={{
							fontSize: '24px',
							fontWeight: '500',
							lineHeight: '29px',
							color: 'rgba(0, 0, 0, 0.9)',
						}}
					>
						{loggedInUser?.fullName}
					</Typography>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							color: 'rgba(0, 0, 0, 0.6)',
						}}
					>
						<Select
							IconComponent={ExpandMoreOutlinedIcon}
							MenuProps={{
								PaperProps: {
									sx: {
										borderRadius: '8px',
										justifyContent: 'center',
										border: '1px solid #4D79EA',
									},
								},
							}}
							class="notchedOutline"
							data-testid="select"
							disabled={isStatusDisabled}
							name="status"
							renderValue={selected => selected}
							sx={{
								'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
									border: 'none',
								},
								'& .MuiOutlinedInput-notchedOutline': {
									border: 'none',
								},
								'& .MuiSelect-outlined': {
									padding: '0',
								},
								'& .MuiOutlinedInput-input': {
									display: 'flex',
									alignItems: 'center',
								},
								fontSize: '14px',
								lineHeight: '17px',
							}}
							value={selectedStatus}
							onChange={event => handleStatusSelect(event)}
						>
							{STATUSES.map(status => (
								<MenuItem
									key={status.value}
									sx={{
										padding: '2.5px 20px 2.5px 10px',
										fontSize: '14px',
										lineHeight: '17px',
									}}
									value={status.value}
								>
									<div
										style={{
											width: '14px',
											height: '14px',
											marginRight: '12px',
											border: '2px solid #F9FAFC',
											borderRadius: '50%',
											backgroundColor: `${status.color}`,
										}}
									/>
									{status.value}
								</MenuItem>
							))}
						</Select>
					</div>
				</div>
			</div>
			<div>
				<AvailabilityItem
					isFirst
					circleTestId="TextAvailableCircle"
					disabled={isTextDisabled}
					isActive={isTextAvailable}
					onToggle={() => handleAvailabilityToggle('Text', setIsTextDisabled)}
				/>
				<AvailabilityItem
					circleTestId="VoiceAvailableCircle"
					disabled={isVoiceDisabled}
					isActive={isVoiceAvailable}
					onToggle={() => handleAvailabilityToggle('Voice', setIsVoiceDisabled)}
				/>
			</div>
		</div>
	);
}

function AvailabilityItem({ isFirst, isActive, onToggle, disabled, circleTestId }) {
	return (
		<div
			data-testid="availabilityItem"
			style={{
				boxSizing: 'border-box',
				width: '100%',
				padding: isFirst ? '12px 16px 10px 25px' : '9px 16px 12px 25px',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				border: '1px solid rgba(196, 196, 196, 0.5)',
				borderBottom: isFirst ? 'none' : '1px solid rgba(196, 196, 196, 0.5)',
				borderRadius: isFirst ? '10px 10px 0 0 ' : '0 0 10px 10px',
			}}
		>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div
					style={{
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
						color: '#4D79EA',
					}}
				>
					{isFirst ? <TextsmsOutlinedIcon /> : <CallOutlinedIcon />}
					<div
						data-testid={circleTestId}
						style={{
							position: 'absolute',
							right: '0',
							bottom: '0',
							width: '10px',
							height: '10px',
							color: '#FFFFFF',
							backgroundColor: isActive ? '#3BE775' : '#EF5D5D',
							borderRadius: '50%',
							outline: '2px solid #FFFFFF',
						}}
					>
						{isActive ? (
							<DoneOutlinedIcon
								style={{
									position: 'absolute',
									width: '10px',
									height: '10px',
								}}
							/>
						) : (
							<CloseOutlinedIcon
								style={{
									position: 'absolute',
									width: '10px',
									height: '10px',
								}}
							/>
						)}
					</div>
				</div>
				<Typography
					style={{
						marginLeft: '11px',
						fontSize: '14px',
						lineHeight: '17px',
						color: 'rgba(0, 0, 0, 0.6)',
					}}
				>
					{isFirst ? 'Text' : 'Voice'} Chat Availability
				</Typography>
			</div>
			<ToggleSwitch disabled={disabled} isActive={isActive} onClick={onToggle} />
		</div>
	);
}

export default ProfileModal;
