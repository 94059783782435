import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { IconButton, Tooltip, Typography, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
import CloseIcon from '@mui/icons-material/Close';
import TextEditor from '../Cases/CaseDetailPopOver/DetailTabs/Comments/TextEditor/TextEditor';
import UserAvatar from './UserAvatar';
import InputCode from './InputCode';
import './UserChatStyles.css';

import { useAuth } from '@worklist-2/core/src';
import { useChatGlobalContext } from '@worklist-2/ui/src/components/Chat/ChatGlobalContext';
import { useLiveChatContext } from './LiveChatContext';

const useStyles = makeStyles()(() => ({
	tooltip: {
		padding: '8px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#4D79EA',
		borderRadius: '5px',
	},

	arrow: {
		color: '#4D79EA',
	},
}));

function UserChat() {
	const { selectedConversation: conversation } = useLiveChatContext();
	const { loggedInUser } = useAuth();
	const { state, dispatch } = useChatGlobalContext();
	const textEditorRef = useRef();
	const messagesRef = React.useRef(null);

	const { classes } = useStyles();
	const [isEnteringCode, setIsEnteringCode] = useState(false);
	const [msgs, setMsgs] = useState();

	const renderItems = arr => {
		const items = arr.map(data => (
			<div>
				{data.date ? (
					<p
						style={{
							fontFamily: 'Inter',
							fontWeight: '500',
							fontSize: '12px',
							lineHeight: '15px',
							textAlign: 'center',
							color: 'rgba(0, 0, 0, 0.6)',
							margin: '15px 0 9px 0',
						}}
					>
						{data.date}
					</p>
				) : (
					<div
						key={data.id}
						style={{
							margin: '10px 0px',
							display: 'flex',
							justifyContent: data.isAuthor ? 'flex-end' : 'flex-start',
						}}
					>
						{data.isAuthor ? null : (
							<UserAvatar isOnline={conversation?.participantOnline} size={35} userName={data?.author} />
						)}
						<Box
							data-testid="wrapper"
							sx={{
								color: 'black',
								backgroundColor: data.isAuthor ? '#FFFFFF' : '#F7F9FE',
								maxWidth: '424px',
								border: '1px solid #C4C4C480',
								borderRadius: '10px',
								padding: '15px 19px 15px 21px',
								marginLeft: '5px',
								boxSizing: 'border-box',
							}}
						>
							<p
								style={{
									padding: '0',
									margin: '0',
								}}
							>
								<span
									data-testid="authorName"
									style={{
										fontFamily: 'Inter',
										fontWeight: '600',
										fontSize: '12px',
										lineHeight: '15px',
										marginBottom: '4px',
									}}
								>
									{data.author}
								</span>
								<span
									data-testid="authorTimeSend"
									style={{
										fontFamily: 'Inter',
										fontWeight: '400',
										fontSize: '12px',
										lineHeight: '15px',
										marginLeft: '6px',
									}}
								>
									{data.time}
								</span>
							</p>
							<div
								data-testid="authorMessage"
								style={{
									marginTop: '2px',
									fontFamily: 'Inter',
									fontWeight: '400',
									fontSize: '14px',
									lineHeight: '17px',
								}}
							>
								{data.message}
							</div>
						</Box>
					</div>
				)}
			</div>
		));

		setMsgs(items);
	};

	useEffect(() => {
		messagesRef.current?.scrollIntoView({ block: 'end' });
	}, [msgs]);

	const getNameOfParticipant = (participants, email) => {
		const node = participants.filter(participant => participant.email === email);
		return node != null ? node[0]?.name : null;
	};

	const onMessageSubmit = async e => {
		e?.preventDefault();

		if (textEditorRef.current?.getPlainText()) {
			// Send Blume/OmegaAI conversation message
			const msg = textEditorRef.current?.getPlainText();
			textEditorRef.current?.clearEditorData();

			const messageIndex = await conversation?.conversation?.sendMessage(msg);
			await conversation?.conversation?.updateLastReadMessageIndex(messageIndex);

			dispatch({
				msgtype: 'MESSAGE_READ',
				payload: conversation?.conversation,
			});
		}
	};

	useEffect(() => {
		async function asynchronousEffect() {
			if (conversation?.conversation != null && conversation.unread) {
				await conversation.conversation.setAllMessagesRead();
				dispatch({
					msgtype: 'MESSAGE_READ',
					payload: conversation.conversation,
				});
			}
		}

		asynchronousEffect();
	}, [state[conversation?.conversation?.sid]?.messages]);

	useEffect(() => {
		if (state && conversation) {
			const dataGroup = [];
			const convParticipants = conversation?.conversation?.attributes?.participants;
			const loggedEmail = loggedInUser?.userName?.toLowerCase();
			const messages = state[conversation?.conversation?.sid]?.messages;

			const groupByDate =
				messages?.reduce((group, message) => {
					const { dateUpdated } = message;
					const date = moment(dateUpdated).format('Do MMMM, YYYY', 'en');
					group[date] = group[date] ?? [];
					group[date].push(message);
					return group;
				}, {}) ?? {};

			const groupArrays = Object.keys(groupByDate).map(date => ({
				date,
				messages: groupByDate[date],
			}));

			groupArrays.forEach(group => {
				dataGroup.push({
					date: group.date,
				});
				group.messages.forEach((message, index) => {
					if (index == 0 && index == group.messages.length - 1) {
						dataGroup.push({
							author:
								loggedEmail !== message.author
									? getNameOfParticipant(convParticipants, message.author) ?? message.author
									: `${loggedInUser?.fullName} (me)`,
							time: moment(message.dateUpdated).format('hh:mm a'),
							message: message.body,
							isAuthor: loggedEmail === message.author,
							first: true,
							last: true,
						});
					} else if (index == 0) {
						dataGroup.push({
							author:
								loggedEmail !== message.author
									? getNameOfParticipant(convParticipants, message.author) ?? message.author
									: `${loggedInUser?.fullName} (me)`,
							time: moment(message.dateUpdated).format('hh:mm a'),
							message: message.body,
							isAuthor: loggedEmail === message.author,
							first: true,
						});
					} else if (index == group.messages.length - 1) {
						dataGroup.push({
							author:
								loggedEmail !== message.author
									? getNameOfParticipant(convParticipants, message.author) ?? message.author
									: `${loggedInUser?.fullName} (me)`,
							time: moment(message.dateUpdated).format('hh:mm a'),
							message: message.body,
							isAuthor: loggedEmail === message.author,
							last: true,
						});
					} else {
						dataGroup.push({
							author:
								loggedEmail !== message.author
									? getNameOfParticipant(convParticipants, message.author) ?? message.author
									: `${loggedInUser?.fullName} (me)`,
							message: message.body,
							isAuthor: loggedEmail === message.author,
							time: moment(message.dateUpdated).format('hh:mm a'),
						});
					}
				});
			});

			renderItems(dataGroup);
		}
	}, [state, conversation]);

	return (
		<Box
			sx={{
				position: 'relative',
				height: '100%',
				display: 'grid',
				gridTemplateRows: '1fr min-content',
				overflow: 'hidden',
			}}
		>
			<Box
				sx={{
					padding: '0 23px',
					overflowY: 'auto',
				}}
			>
				{msgs}
				<div ref={messagesRef} />
			</Box>

			{isEnteringCode ? (
				<Box
					sx={{
						position: 'relative',
						padding: '10px 30px 18px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						border: '1px solid rgba(196, 196, 196, 0.5)',
					}}
				>
					<Box
						sx={{
							alignSelf: 'flex-start',
							marginBottom: '10px',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<CoPresentOutlinedIcon sx={{ color: '#4D79EA' }} />
						<Typography
							sx={{
								marginLeft: '10px',
								fontFamily: 'Inter',
								fontSize: '14px',
								color: 'rgba(0, 0, 0, 0.9)',
							}}
						>
							Enter screen share code
						</Typography>
					</Box>
					<InputCode className="user-chat-input-code" length={6} onClose={() => setIsEnteringCode(false)} />
					<IconButton
						sx={{
							position: 'absolute',
							top: '5px',
							right: '10px',
							padding: 0,
						}}
						onClick={() => setIsEnteringCode(false)}
					>
						<CloseIcon />
					</IconButton>
				</Box>
			) : (
				<Box
					className="user-chat-input"
					style={{
						boxSizing: 'border-box',
						width: '100%',
						padding: '0 22px 30px',
						display: 'flex',
						alignItems: 'flex-end',
					}}
				>
					<Tooltip
						arrow
						classes={{
							tooltip: classes.tooltip,
							arrow: classes.arrow,
						}}
						placement="top-start"
						sx={{
							'.MuiSvgIcon-root': {
								opacity: '0.6',
								'&:hover': {
									opacity: '1',
									color: '#4D79EA',
								},
							},
						}}
						title="Invite to Share Screen"
					>
						<IconButton onClick={() => setIsEnteringCode(true)}>
							<CoPresentOutlinedIcon />
						</IconButton>
					</Tooltip>
					<TextEditor ref={textEditorRef} isChat submitComment={onMessageSubmit} sx={{ width: '100%' }} />
				</Box>
			)}
		</Box>
	);
}

export default UserChat;
