export const DOC_TYPES = ['txt', 'rtf', 'pdf'];
export const IMG_TYPES = ['jpeg', 'jpg', 'png', 'bmp', 'image/jpeg', 'image/png', 'image/bmp'];
export const MEDIA_TYPES = ['mp4', 'video/mp4'];
export const ACCEPT_FILES_EXT = [...DOC_TYPES, ...IMG_TYPES, ...MEDIA_TYPES, 'dcm'];

export const FILES_EXT_GROUP = {
	pdf: ['25504446'],
	mp4: ['66747970'],
	jpg: ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffdb', 'ffd8ffee'],
	dcm: ['4449434d', '80', '08'],
	txt: ['efbbbf', 'fffe', 'feff', 'fffe0000', '0000feff', '0efeff', '7465'],
	rtf: ['7b5c7274', '7b5c'],
};

export const IMPORT_STATIC_VALUES = {
	RS_IMPLEMENTATION_VERSION_NAME: 'RS 7.01',
	RS_IMPLEMENTATION_VERSION_NAME2: 'RS_7_01',
	RS_RELEASEDATE: '2017-09-24',
	RS_UID_ROOT: '1.2.124.113540',
	RS_ULTRAPRO_VERSION: '7.0',
	RS_ULTRAPRO_PREV_VERSION: '6',
	RS_ULTRAPRO_VERSION_NUMBER: 600,
	RS_IMPLEMENTATION_CLASS_UID: '1.2.124.113540.1.7.0',
	RS_PREV_IMPL_CLASS_UID: '1.2.124.113540.1.6',
	RS_PRIVATE_DATA_CODE: 'RS Inc. - Private Data',
	RS_INSTITUTION_NAME: 'RAMSOFT',
	RS_MANUFACTURER: 'RamSoft Inc.',
};
export const RS_FILE_KIND_IDENTIFIER = 'RamSoft File Kind Identifier';
export const RS_RACE_IDENTIFIER = 'RamSoft Race Identifier';

export const WORKER_MESSAGES = {
	sessionId: 'sessionId',
	number_of_workers: 'number_of_workers',
	import_in_progress: 'import_in_progress',
	retry_upload: 'retry_upload',
	post_error: 'post_error',
	access_token: 'access_token',
	start_import: 'start_import',
	upload_progress: 'upload_progress',
	post_success: 'post_success',
	stop_import: 'stop_import',
	stop_import_files: 'stop_import_files',
	stop_import_all: 'stop_import_all',
	import_completed: 'import_completed',
};

export const COORDINATOR_WORKER_URL = '/scripts/importcoordinatorworkerV2.js';
