// Core

import React from 'react';

// Custom

import CHAT_CONVERSATION_TYPES, { DEFAULT_CALL_DATA } from './CHAT_CONVERSATION_TYPES';
import { CHAT_SECTIONS_default } from './CHAT_SECTIONS';

// Context
import { useAppModeContext } from '@worklist-2/core/src/context/AppModeContext';

const ChatGlobalContext = React.createContext({
	section: null,
	setSection: null,
	conversationType: null,
	setConversationType: null,
	callData: null,
	setCallData: null,
	callParticipants: null,
	setCallParticipants: null,
	callRoom: null,
	setCallRoom: null,
	expanded: null,
	setExpanded: null,
	state: null,
	dispatch: null,
	screenShare: null,
	setScreenShare: null,
	smallView: null,
	setSmallView: null,
});

// Hook

const useChatGlobalContext = () => React.useContext(ChatGlobalContext);

// State reducer

function reducer(state, action) {
	let newState = {};

	switch (action.msgtype) {
		case 'CONVERSATION_LEFT':
		case 'CONVERSATION_REMOVED':
			newState = { ...state };
			delete newState[action.payload.sid];
			break;

		case 'CONVERSATION_ADDED':
			newState = { ...state };
			newState[action.payload.conversation.sid] = action.payload;
			break;

		case 'CONVERSATION_UPDATED':
			newState = { ...state };
			if (newState.hasOwnProperty(action.payload.conversation.sid)) {
				newState[action.payload.conversation.sid] = action.payload;
			}
			break;

		case 'MESSAGE_ADDED':
			newState = { ...state };
			if (newState.hasOwnProperty(action.payload.conversation.sid)) {
				let msgArray = state[action.payload.conversation.sid].messages;
				// Fix PRO-2013: skip updating state if the message is duplicated in front-end side
				const duplicatedMsg = msgArray.filter(message => message.sid === action.payload.sid);
				if (duplicatedMsg?.length > 0) {
					break;
				}
				msgArray = [...msgArray, action.payload];
				newState[action.payload.conversation.sid].messages = msgArray;
				newState[action.payload.conversation.sid].datetime = action.payload.dateUpdated;
				newState[action.payload.conversation.sid].text = action.payload.body;
				newState[action.payload.conversation.sid].unread = true;
			}
			break;

		case 'INIT':
			newState = { ...state, ...action.payload };
			break;

		case 'MESSAGE_READ':
			newState = { ...state };
			if (newState[action.payload?.sid] != null ?? false) {
				newState[action.payload.sid].unread = false;
			}
			break;

		case 'MESSAGE_UNREAD':
			newState = { ...state };
			if (newState[action.payload?.sid] != null ?? false) {
				newState[action.payload.sid].unread = true;
			}
			break;

		case 'USER_UPDATED':
			newState = { ...state };
			// When a participant deletes a conversation and the the other participant refreshes the page,
			// skip checking online status for the deleted coversation
			if (newState.hasOwnProperty(action.payload.conversationSid)) {
				newState[action.payload.conversationSid].participantOnline = action.payload.isOnline;
				newState[action.payload.conversationSid].conversation.participantOnline =
					newState[action.payload.conversationSid].participantOnline;
			}
			break;

		case 'USER_TYPING':
			newState = { ...state };
			newState[action.payload.conversation.sid].conversation = action.payload.conversation;
			break;

		default:
			break;
	}

	return newState;
}

// Component

const ChatGlobalContextProvider = ({ children }) => {
	const [section, setSection] = React.useState(CHAT_SECTIONS_default);
	const [callData, setCallData] = React.useState(JSON.parse(JSON.stringify(DEFAULT_CALL_DATA)));
	const [callParticipants, setCallParticipants] = React.useState([]); // callParticipants array stores the list of remote participants
	const [callRoom, setCallRoom] = React.useState(null);
	const [conversationType, setConversationType] = React.useState(CHAT_CONVERSATION_TYPES.MESSAGING);
	const [screenShare, setScreenShare] = React.useState({});
	const [expanded, setExpanded] = React.useState(false);
	const [smallView, setSmallView] = React.useState(false);
	const [state, dispatch] = React.useReducer(reducer, {});

	const { isPatientPortalMode } = useAppModeContext();
	const appBlumeMode = isPatientPortalMode();
	const nonUsEntities = ['po-ca01', 'po-in01'];

	const safeSetConversationType = React.useCallback(
		value => {
			let newValue = value;

			if (value === CHAT_CONVERSATION_TYPES.CALL && !callData?.isCall) {
				newValue = CHAT_CONVERSATION_TYPES.MESSAGING;
			}

			setConversationType(newValue);
		},
		[callData, setConversationType]
	);

	return (
		<ChatGlobalContext.Provider
			value={{
				section,
				setSection,
				conversationType,
				setConversationType: safeSetConversationType,
				callData,
				setCallData,
				callParticipants,
				setCallParticipants,
				callRoom,
				setCallRoom,
				expanded,
				setExpanded,
				state,
				dispatch,
				screenShare,
				setScreenShare,
				smallView,
				setSmallView,
				appBlumeMode,
				nonUsEntities,
			}}
		>
			{children}
		</ChatGlobalContext.Provider>
	);
};

export default ChatGlobalContextProvider;
export { useChatGlobalContext };
