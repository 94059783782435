// Core

import React from 'react';

import PropTypes from 'prop-types';

import { useSwipeable, LEFT, RIGHT } from 'react-swipeable';

// MUI

import { Icon, Stack } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

// Defaults

const MIN_WIDTH = 100;
const TRANSITION_DURATION = 100;
const TRANSITION_DURATION_S = TRANSITION_DURATION / 1000;

// Item component

const HorizontallySwipeable = ({ children, sx, onDelete }) => {
	const contentRef = React.useRef(null);

	const widthRef = React.useRef(MIN_WIDTH);

	const deleteDefined = typeof onDelete === 'function';
	const deleteRef = React.useRef(null);

	const clearStyles = React.useCallback(
		element => {
			if (element) {
				element.style.left = '';
			}

			if (contentRef.current) {
				contentRef.current.style.width = '';
			}

			if (deleteRef.current) {
				deleteRef.current.style.opacity = '';
				deleteRef.current.style.width = '';
			}
		},
		[deleteDefined]
	);

	const _swipingHandler = React.useCallback(
		event => {
			const { dir, absX, first } = event;
			const currentTarget = event.event?.currentTarget;

			const left = dir === LEFT;
			const right = dir === RIGHT;

			if ((!left && !right) || !contentRef.current) {
				return;
			}

			if (first) {
				const { width } = contentRef.current.getBoundingClientRect();

				contentRef.current.style.width = `${width}px`;

				widthRef.current = Math.max(width, MIN_WIDTH);
			}

			if (currentTarget) {
				currentTarget.style.left = left ? `-${absX}px` : '';
			}

			if (deleteRef.current) {
				deleteRef.current.style.opacity = left ? 1 : '';
				deleteRef.current.style.width = left ? `${absX}px` : '';
			}
		},
		[deleteDefined]
	);

	const _swipedLeftHandler = React.useCallback(
		event => {
			const { absX } = event;
			const currentTarget = event.event?.currentTarget;

			if (absX >= widthRef.current / 2) {
				if (currentTarget) {
					currentTarget.style.transition = `left ${TRANSITION_DURATION_S}s ease-in-out`;
					currentTarget.style.left = `-${widthRef.current}px`;
				}

				if (deleteRef.current) {
					deleteRef.current.style.transition = `width ${TRANSITION_DURATION_S}s ease-in-out`;
					deleteRef.current.style.width = `${widthRef.current}px`;
				}

				setTimeout(() => {
					if (currentTarget) {
						currentTarget.style.transition = '';
					}

					if (deleteRef.current) {
						deleteRef.current.style.transition = '';
					}
				}, TRANSITION_DURATION);

				if (deleteDefined) {
					onDelete();
				}
			} else {
				clearStyles(currentTarget);
			}
		},
		[clearStyles, deleteDefined, onDelete]
	);

	const _swipedRightHandler = React.useCallback(
		event => {
			const currentTarget = event.event?.currentTarget;

			clearStyles(currentTarget);
		},
		[clearStyles]
	);

	const swipeHandlers = useSwipeable({
		onSwiping: _swipingHandler,
		onSwipedLeft: _swipedLeftHandler,
		onSwipedRight: _swipedRightHandler,
	});

	return (
		<Stack
			alignItems="stretch"
			direction="row"
			sx={{
				'--element-content-offset': '0px',
				position: 'relative',
				...sx,
			}}
			{...swipeHandlers}
		>
			<Stack
				ref={contentRef}
				flex="1 0 auto"
				sx={{
					position: 'relative',
					zIndex: 1,
				}}
			>
				{children}
			</Stack>

			{deleteDefined ? (
				<Stack
					ref={deleteRef}
					alignItems="center"
					flex="none"
					justifyContent="center"
					sx={{
						backgroundColor: '#cf6679',
						boxSizing: 'content-box',
						marginLeft: 'calc(0px - var(--element-content-offset))',
						paddingLeft: 'var(--element-content-offset)',
						overflow: 'hidden',
						opacity: 0,
						width: '0px',
					}}
				>
					<Icon component={DeleteOutlinedIcon} sx={{ color: '#ffffff' }} />
				</Stack>
			) : null}
		</Stack>
	);
};

HorizontallySwipeable.propTypes = {
	children: PropTypes.node.isRequired,
	sx: PropTypes.object,
	onDelete: PropTypes.func,
};

export default HorizontallySwipeable;
