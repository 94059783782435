import axios from 'axios';

const saveForm = async ({ __config, data }) => {
	const response = await axios.post(`${__config.data_sources.fhir}/Form`, data);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default saveForm;
