import _ from 'lodash';

const getExtensionValueCodingArray = (resource, fhirUrl) => {
	let valueArray = [];

	const firstLevelExtension = _.find(resource?.extension, elem => elem.url === fhirUrl);

	if (firstLevelExtension) {
		valueArray = _.map(firstLevelExtension.extension, item => item.valueCoding);
	}

	return valueArray;
};

export default getExtensionValueCodingArray;
