// React Libraries
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';

// Material UI Libraries
import ClearIcon from '@mui/icons-material/Clear';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveIcon from '@mui/icons-material/Save';
import StackedBarChartOutlinedIcon from '@mui/icons-material/StackedBarChartOutlined';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { IconButton, Typography, Box, Button, Grid, InputBase, Tooltip } from '@mui/material';

// Custom Component Libraries
import ReportFields from './ReportFields';
import FilterList from './FilterList';
import ReportOptions from './ReportOptions';
import FieldCharts from './FieldCharts';
import ReportFieldSearchBar from './ReportFieldSearchBar';

// Cube Libraries
import { QueryBuilder } from '@cubejs-client/react';
import { createTheme } from '@mui/material/styles';

//
import axios from 'axios';
import { useConfig } from '@worklist-2/core/src';

function NewReport({
	setIsReport,
	onSaveReport,
	script,
	chartType,
	chartImage,
	setChartImage,
	setChartType,
	cubeToken,
}) {
	const __config = useConfig();
	const cubejsApi = `${__config.crm?.cube}/meta`;
	const [metaData, setMetaData] = useState();
	const [isReportOptions, setReportOptions] = useState(false);
	const [filterItems, setFilterItems] = useState([]);
	const [measureOrder, setMeasureOrder] = useState();
	const [measuresToOrder, setMeasuresToOrder] = useState([]);
	const [order, setOrder] = useState();
	const [legendItem, setLegendItem] = useState(null);
	const [yAxisItem, setYAxisItem] = useState();
	const [xAxisItem, setXAxisItem] = useState();
	const [valueItem, setValueItem] = useState();
	const [multiItem, setMultiItem] = useState([]);
	const [dragDimension, setDragDimension] = useState('x');
	const [fieldSearchValue, setFieldSearchValue] = useState('');
	const [chartTitle, setChartTitle] = useState(script?.chartTitle ? script.chartTitle : 'Draft');
	const [editModeTitle, setEditModeTitle] = useState(false);
	const [multiMeasureItem, setMultiMeasureItem] = useState([]);

	const debounceSetChartTitle = useCallback(
		_.debounce(nextValue => {
			if (nextValue !== chartTitle) {
				setChartTitle(nextValue);
			}
		}, 1000),
		[chartTitle]
	);

	useEffect(
		() =>
			axios
				.get(cubejsApi, {
					transformRequest: [
						(data, headers) => {
							delete headers.common.sessionId;
							headers.common.Authorization = cubeToken;
							return data;
						},
					],
				})
				.then(resp => {
					if (resp.status === 200) {
						const data = _.filter(resp.data.cubes, e => e.name.indexOf('MV') === -1);
						setMetaData({ cubes: data });
					}
				}),
		[cubeToken]
	);

	useEffect(() => {
		if (!!script && !!metaData) {
			// get measures item
			const measureStr = script.query?.measures?.[0];
			if (measureStr && script.chartType != 'table') {
				const measureName = measureStr.substring(0, measureStr.indexOf('.'));
				const sourceMeasureIndex = metaData.cubes.findIndex(m => m.name === measureName);

				if (sourceMeasureIndex >= 0) {
					const measureItems = metaData?.cubes[sourceMeasureIndex];
					const measureFieldName = measureItems.name;

					if (script.chartType == 'number') {
						// the Number Chart will use ValueItem
						setValueItem({
							...measureItems.measures.find(d => d.name === measureStr),
							fieldName: measureFieldName,
						});
					} else if (script.chartType != 'line' && script.chartType != 'bar') {
						// will not set this field if Multi Measure is open.
						setYAxisItem({
							...measureItems.measures.find(d => d.name === measureStr),
							fieldName: measureFieldName,
						});
					}
				}
			}

			// get dimensions item
			const dimensionStr = script.query?.dimensions[0];
			if (dimensionStr && script.chartType != 'table') {
				const dimensionName = dimensionStr.substring(0, dimensionStr.indexOf('.'));
				const sourceDimensionStrIndex = metaData.cubes.findIndex(m => m.name === dimensionName);

				if (sourceDimensionStrIndex >= 0) {
					const dimensionItems = metaData?.cubes[sourceDimensionStrIndex];
					const dimensionFieldName = dimensionItems.name;

					setXAxisItem({
						...dimensionItems.dimensions.find(d => d.name === dimensionStr),
						fieldName: dimensionFieldName,
					});
				}
			}

			// Get data for Multiple values.
			if (script.chartType == 'table') {
				const { columnOrder } = script;
				const multiItemArray = [];

				if (columnOrder) {
					columnOrder.forEach(column => {
						const tableName = column.key.substring(0, column.key.indexOf('.'));
						const item = metaData.cubes.find(m => m.name === tableName);
						const itemSource = column.itemSource == 'measure' ? item.measures : item.dimensions;
						const fieldMatching = itemSource.find(el => el.name === column.key);

						if (fieldMatching !== undefined) {
							multiItemArray.push({
								...fieldMatching,
								fieldName: tableName,
								itemSource: column.itemSource,
								id: generateDragId(),
							});
						}
					});
				}

				setMultiItem(multiItemArray);
			}

			// Get data for Multi Mesures
			if (script.chartType == 'bar' || script.chartType == 'line') {
				const mesureFields = script.query?.measures;
				const mutiMesures = [];

				if (mesureFields) {
					mesureFields.forEach(fieldStr => {
						const measureName = fieldStr.substring(0, fieldStr.indexOf('.'));
						const sourceMeasureIndex = metaData.cubes.findIndex(m => m.name === measureName);

						if (sourceMeasureIndex >= 0) {
							const measureItems = metaData?.cubes[sourceMeasureIndex];
							const measureFieldName = measureItems.name;

							mutiMesures.push({
								...measureItems.measures.find(d => d.name === fieldStr),
								fieldName: measureFieldName,
							});
						}
					});
				}

				setMultiMeasureItem(mutiMesures);
			}

			const filterArray = [];
			// get filter
			script.query?.filters.forEach(filter => {
				const fieldNameIndex = filter.member.indexOf('.');
				const filterName = filter.member.substring(0, fieldNameIndex);
				const item = metaData.cubes.find(m => m.name === filterName);
				let matching = item?.dimensions.find(d => d.name === filter.member);

				if (matching === undefined) {
					matching = item?.measures.find(d => d.name === filter.member);
				}

				if (matching !== undefined) {
					filterArray.push({
						...matching,
						value: filter.values,
						operator: filter.operator,
						fieldName: filterName,
					});
				}
			});

			setFilterItems(filterArray);
		}
	}, [script, metaData]);

	useEffect(() => {
		if (measuresToOrder?.length) {
			const newOrder = measuresToOrder.map(value => [value, measureOrder?.length ? measureOrder[0] : null]);
			setOrder(newOrder);
		}
	}, [measureOrder?.length, measuresToOrder?.length]);

	const onSave = useCallback(
		onSavechartType => {
			let type = 'line';
			let multiMeasures = [];
			let multiDimensions = null;
			let columnOrder = null;

			if (onSavechartType === 'BAR_CHART') {
				type = 'bar';

				multiMeasures = multiMeasureItem.map(m => m.name);
			} else if (onSavechartType === 'LINE_CHART') {
				type = 'line';

				multiMeasures = multiMeasureItem.map(m => m.name);
			} else if (onSavechartType === 'PIE_CHART') {
				type = 'pie';
			} else if (onSavechartType === 'CROP_CHART') {
				type = 'number';
			} else if (onSavechartType === 'TABLE_CHART') {
				type = 'table';

				multiMeasures = multiItem
					.filter(m => {
						let isMeasure = false;
						if (m.itemSource == 'measure') {
							isMeasure = true;
						}
						return isMeasure;
					})
					.map(m => m.name);

				multiDimensions = multiItem
					.filter(d => {
						let isDimension = false;
						if (d.itemSource == 'dimension') {
							isDimension = true;
						}
						return isDimension;
					})
					.map(d => d.name);

				// Save the column order
				columnOrder = multiItem.map(value => ({
					key: value.name,
					itemSource: value.itemSource,
				}));
			}

			const script = {
				chartTitle,
				chartType: `${type}`,
				library: `ChartsJS`,
				query: {
					measures:
						multiMeasures?.length > 0
							? multiMeasures
							: yAxisItem?.name
							? [`${yAxisItem.name}`]
							: valueItem?.name
							? [`${valueItem.name}`]
							: [],
					dimensions:
						multiDimensions?.length > 0 ? multiDimensions : xAxisItem?.name ? [`${xAxisItem.name}`] : [],
					filters: filterItems
						.map(s => ({
							member: s.name,
							operator: s.operator,
							values: s.value,
						}))
						.filter(
							item =>
								(!_.isEmpty(item.values) && !_.isEmpty(item.operator)) ||
								item.operator === 'set' ||
								item.operator === 'notSet'
						),
					order: order ? Object.fromEntries(order) : [],
				},
				columnOrder,
			};

			onSaveReport(script);
		},
		[onSaveReport, yAxisItem, xAxisItem, filterItems, valueItem, chartTitle, multiItem, multiMeasureItem]
	);

	const onUpdateFilters = (updatedFilter, newValue, newOperator) => {
		let newFilterObj = null;

		for (let index = 0; index < filterItems.length; index++) {
			const item = filterItems[index];
			if (item.name === updatedFilter.name) {
				newFilterObj = {
					...item,
					value: newValue === null ? item.value : newValue,
					operator: newOperator === null ? item.operator : newOperator,
				};

				filterItems[index] = newFilterObj;
				break;
			}
		}

		setFilterItems(filterItems);
	};

	const onRemoveFilters = filter => {
		let indexToRemove = -1;
		const filterTemp = JSON.parse(JSON.stringify(filterItems));
		for (let index = 0; index < filterTemp.length; index++) {
			const item = filterTemp[index];
			if (item.name === filter) {
				indexToRemove = index;
				break;
			}
		}

		if (indexToRemove != -1) {
			filterTemp.splice(indexToRemove, 1);
			setFilterItems(filterTemp);
		}
	};

	const onRemoveMultiValue = index => {
		const temp = [...multiItem];

		if (index != -1) {
			temp.splice(index, 1);
			setMultiItem(temp);
		}
	};

	const onDragStart = result => {
		const { source } = result;
		const dragAxis = source.droppableId.includes('-dimension') ? 'x' : 'y';

		setDragDimension(dragAxis);
	};

	const onDragEnd = (result, meta) => {
		if (!result.destination) return;
		const { source, destination } = result;

		if (source.droppableId !== destination.droppableId) {
			let dropid = source.droppableId.replace('-dimension', '');
			dropid = dropid.replace('-measure', '');

			const sourceMeasureIndex = meta?.cubes?.findIndex(m => m.name === dropid);

			const sourceItems = meta?.cubes[sourceMeasureIndex];
			const fieldName = sourceItems.name;
			let itemSource = '';
			let draggedItem = sourceItems.dimensions.find(d => d.name === result.draggableId);

			if (draggedItem == null) {
				draggedItem = sourceItems.measures.find(d => d.name === result.draggableId);
				itemSource = 'measure';
			} else {
				itemSource = 'dimension';
			}

			if (destination.droppableId === 'filter_drop') {
				let addNewFilter = true;
				for (const f of filterItems) {
					if (f.name === draggedItem.name) {
						addNewFilter = false;
						break;
					}
				}

				if (addNewFilter) {
					const destItems = [
						...filterItems,
						{
							...draggedItem,
							fieldName,
							itemSource,
						},
					];
					setFilterItems(destItems);
				}
			} else if (destination.droppableId === 'legend_drop') {
				setLegendItem({ ...draggedItem, fieldName });
			} else if (destination.droppableId === 'y_axis_drop') {
				setYAxisItem({ ...draggedItem, fieldName });
			} else if (destination.droppableId === 'x_axis_drop') {
				setXAxisItem({ ...draggedItem, fieldName });
			} else if (destination.droppableId === 'value_drop' && draggedItem.aggType == 'count') {
				// the VALUE ITEM for the card limited to what is under the MEASURES (i.e. COUNT)
				setValueItem({ ...draggedItem, fieldName });
			} else if (destination.droppableId === 'multiValue_drop') {
				let addNewIteam = true;
				for (const f of multiItem) {
					if (f.name === draggedItem.name) {
						addNewIteam = false;
						break;
					}
				}

				if (addNewIteam) {
					const destItems = [
						...multiItem,
						{
							...draggedItem,
							fieldName,
							itemSource,
							id: generateDragId(),
						},
					];
					setMultiItem(destItems);
				}
			} else if (destination.droppableId == 'multimeasure_drop' && multiMeasureItem.length < 4) {
				// Number of measures will be limited to 4
				const addedField = multiMeasureItem.filter(m => m.name == draggedItem.name);

				// Add new Field
				if (addedField.length == 0) {
					const newlist = [
						...multiMeasureItem,
						{
							...draggedItem,
							fieldName,
						},
					];
					setMultiMeasureItem(newlist);
				}
			}
		} else if (destination.droppableId == 'multiValue_drop') {
			// Handle the case of changing the order
			const rearrangedItemList = ReArrange(multiItem, source.index, destination.index);

			setMultiItem(rearrangedItemList);
		}
	};

	const ReArrange = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);

		result.splice(endIndex, 0, removed);
		return result;
	};

	const generateDragId = () => {
		const unique_id = uuid();
		const itemid = unique_id.slice(0, 8);

		return itemid;
	};

	const theme = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1200,
				xl: 1536,
			},
		},
	});

	// Check and enable Save button for different charts
	const enableSaveButton = useMemo(() => {
		if (chartType == 'BAR_CHART' || chartType == 'LINE_CHART') {
			// If Multiple Measures enable, will use multiMeasureItem to input
			return multiMeasureItem?.length > 0 && xAxisItem;
		}
		if (chartType == 'TABLE_CHART') {
			// Table Chart
			return multiItem.length > 0;
		}
		if (chartType == 'PIE_CHART' || chartType == 'STACK_CHART') {
			// Pie chart and Stack chart
			return yAxisItem && xAxisItem;
		}
		if (chartType == 'CROP_CHART') {
			// Visual Card
			return valueItem;
		}

		return false;
	}, [yAxisItem, multiMeasureItem, xAxisItem, valueItem, multiItem, chartType]);

	return (
		<QueryBuilder
			render={() => (
				//setMetaData(meta);
				<Box
					data-testid="new-report-window"
					sx={{
						position: 'fixed',
						top: '145px',
						left: '150px',
						width: 1160,
						height: '80vh',
					}}
				>
					<Box
						sx={{
							zIndex: 2,
						}}
					>
						<Button
							sx={{
								position: 'absolute',
								top: -23,
								right: 15,
								minWidth: 58,
								height: 40,
								background: '#42A5F5',
								borderRadius: '10px',
								paddingTop: 0,
								color: '#fff',

								'&:hover': {
									background: '#42A5F5',
								},
								'& .MuiSvgIcon-root': {
									marginTop: '-5px',
									fontSize: '1rem',
								},
							}}
							value="clear"
							onClick={() => setIsReport(false)}
						>
							<ClearIcon />
						</Button>
					</Box>
					<Box
						sx={{
							position: 'fixed',
							top: '150px',
							left: '150px',
							width: 1100,
							height: '80vh',
							background: '#EFF3F9',
							border: '2px solid #42A5F5',
							boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
							borderRadius: '20px',
							color: '#fff',
							padding: '8px 20px',
							cursor: 'auto',
							fontFamily: 'Roboto',
							zIndex: 1,
						}}
					>
						<Box
							sx={{
								position: 'absolute',
								bottom: 55,
								right: -40,
								display: 'flex',
								flexDirection: 'column',
								gap: '16px',
							}}
						>
							<Button
								sx={{
									position: 'relative',
									width: 85,
									height: 72,
									background: '#FFFFFF',
									borderRadius: '20px',
									boxShadow: '30px 30px 60px rgba(0, 0, 0, 0.25)',
									color: '#fff',
									'&:hover': {
										background: '#FFF',
									},
								}}
								onClick={() => setReportOptions(true)}
							>
								<StackedBarChartOutlinedIcon sx={{ color: '#42A5F5' }} />

								{isReportOptions && (
									<ClickAwayListener onClickAway={() => setReportOptions(false)}>
										<Box
											sx={{
												position: 'absolute',
												width: 300,
												left: '100px',
												top: '-35px',
												bottom: 0,
											}}
										>
											<ReportOptions setChartImage={setChartImage} setChartType={setChartType} />
										</Box>
									</ClickAwayListener>
								)}
							</Button>

							{enableSaveButton && (
								<Button
									sx={{
										width: 85,
										height: 72,
										background: '#42A5F5',
										borderRadius: '20px',
										boxShadow: '30px 30px 60px rgba(0, 0, 0, 0.25)',
										color: '#fff',
										'&:hover': {
											background: '#1d97fa',
										},
									}}
									onClick={() => onSave(chartType)}
								>
									<SaveIcon data-testid="SaveIcon" sx={{ color: '#FFF' }} />
								</Button>
							)}
						</Box>

						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								color: 'rgba(255, 255, 255, 0.86)',
								height: '40px',
							}}
						>
							{editModeTitle ? (
								<InputBase
									autoFocus
									defaultValue={chartTitle}
									fontSize={24}
									sx={{
										marginRight: '20px',
										fontSize: 20,
										width: '35%',
									}}
									onBlur={() => {
										setEditModeTitle(false);
									}}
									onChange={event => debounceSetChartTitle(event.target.value)}
									onFocus={e => {
										e.target.select();
									}}
									onKeyUp={event => {
										if (event.key == 'Enter') {
											setEditModeTitle(false);
										}
									}}
								/>
							) : (
								<Box
									sx={{
										display: 'inherit',
										alignItems: 'center',
										width: '35%',
										'&:hover': {
											'.MuiSvgIcon-root': {
												display: 'inline-block',
											},
										},
									}}
								>
									<Tooltip title={chartTitle}>
										<Typography
											sx={{
												color: '#000',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis',
												fontSize: 24,
												fontWeight: 700,
												marginRight: '7px',
											}}
										>
											{chartTitle}
										</Typography>
									</Tooltip>
									<IconButton sx={{ width: '40px' }} onClick={() => setEditModeTitle(true)}>
										<EditOutlinedIcon
										// sx={{ display: 'none' }}
										/>
									</IconButton>
								</Box>
							)}
						</Box>

						<DragDropContext
							onDragEnd={result => onDragEnd(result, metaData)}
							onDragStart={source => onDragStart(source)}
						>
							<Grid
								container
								data-testid="drag-drop-context"
								sx={{
									margin: 0,
								}}
							>
								<Grid item xs={4}>
									<Box
										sx={{
											width: '100%',
											height: '69vh',
											background: '#F9FAFF',
											boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.1)',
											borderRadius: '15px',
											padding: '16px 8px',
											marginTop: 3,
											[theme.breakpoints.between('lg', 'xl')]: {
												marginTop: '6px',
												transform: 'scale(0.9)',
											},
											'::-webkit-scrollbar': {
												width: '4px  !important',
											},
											'::-webkit-scrollbar-thumb': {
												background: '#C4C4C4 !important',
												borderRadius: '2px !important',
											},
											'::-webkit-scrollbar-track': {
												background: 'transparent !important',
											},
										}}
									>
										<Typography
											fontSize={16}
											sx={{
												fontWeight: 500,
												marginLeft: 2,
												color: '#000',
											}}
										>
											FIELDS
										</Typography>

										<ReportFieldSearchBar setFieldSearchValue={setFieldSearchValue} />

										<ReportFields
											data-testid="field_drop"
											fieldSearchValue={fieldSearchValue}
											measures={metaData}
										/>
									</Box>
								</Grid>
								<Grid
									item
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										flexDirection: 'column',
									}}
									xs={8}
								>
									<FilterList
										filterItems={filterItems}
										removeFilter={onRemoveFilters}
										updateFilter={onUpdateFilters}
									/>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'start',
											flexDirection: 'column',
										}}
									>
										<FieldCharts
											chartImage={chartImage}
											chartType={chartType}
											dimensionDisabled={dragDimension !== 'x'}
											legend={legendItem}
											measureDisabled={dragDimension === 'x'}
											measureOrder={measureOrder}
											measuresToOrder={measuresToOrder}
											multiItem={multiItem}
											multiMeasureItem={multiMeasureItem}
											setMeasureOrder={setMeasureOrder}
											setMeasuresToOrder={setMeasuresToOrder}
											setMultiMeasureItem={setMultiMeasureItem}
											valueItem={valueItem}
											xAxis={xAxisItem}
											yAxis={yAxisItem}
											onRemoveMultiValue={onRemoveMultiValue}
										/>
									</Box>
								</Grid>
							</Grid>
						</DragDropContext>
					</Box>
				</Box>
			)}
		/>
	);
}

NewReport.propTypes = {
	/**
	 * setIsReport is func value to toggle new report popup
	 */
	setIsReport: PropTypes.func.isRequired,
	/**
	 * existing script to be edited
	 */
	script: PropTypes.object,
	/**
	 * setChartImage is func to handle chart image based on option clicked
	 */
	setChartImage: PropTypes.func,
	/**
	 * setChartType is func to handle chart type based on option clicked
	 */
	setChartType: PropTypes.func,
	/**
	 * chartType is string that define chart image.
	 */
	chartType: PropTypes.string,
	/**
	 * chartImage is string that define chart image.
	 */
	chartImage: PropTypes.string,
	/**
	 * API token used for Cube
	 * @type {string}
	 */
	cubeToken: PropTypes.string,
};

export default NewReport;
