import { useMemo } from 'react';
import { useImageViewerLayoutContext } from '../contexts/ImageViewerLayoutContext';
import CheckVideoImagesTransferSyntax from '../utils/CheckVideoImagesTransferSyntax';
import { getInstanceSyntax } from '../utils/utils';

const useWheel = () => {
	const { activeViewportId, layoutItems } = useImageViewerLayoutContext();

	const canShowWheel = useMemo(() => {
		if (!layoutItems || !activeViewportId) return true;
		let isVideoViewport = false;
		try {
			const activeLayoutItem = layoutItems?.find(i =>
				(i.viewports || []).some(({ id }) => id === activeViewportId)
			);
			if (!activeLayoutItem) return true; //hightlighted viewport
			if (!activeLayoutItem?.series) return false; //empty viewport

			const instances = activeLayoutItem?.series?.metadata;
			isVideoViewport = CheckVideoImagesTransferSyntax(getInstanceSyntax(instances?.[0]));
		} catch (err) {
			console.error(err);
		}

		return !isVideoViewport;
	}, [activeViewportId, layoutItems]);

	return { canShowWheel };
};

export default useWheel;
