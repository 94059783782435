import { init, setUseCPURendering, metaData, utilities } from '@cornerstonejs/core';
import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';

const htj2kMixedOptions = {
	stages: [
		{
			id: 'lossySequential',
			retrieveType: 'singleFast',
		},
		{
			id: 'lossySequentialFailure',
			retrieveType: 'singleFastFailure',
		},
		{
			id: 'lossyMiddle',
			retrieveType: 'singleMiddle',
		},
		{
			id: 'lossyMiddleFailure',
			retrieveType: 'singleMiddleFailure',
		},
		{
			id: 'finalSequential',
			retrieveType: 'singleFinal',
		},
	],
	retrieveOptions: {
		singleFast: {
			decodeLevel: 2,
			chunkSize: 128 * 1024,
			rangeIndex: 0,
		},
		// This is a fallback phase if decodeLevel 2 fails, then try at 3
		singleFastFailure: {
			decodeLevel: 3,
			rangeIndex: 0,
		},
		// Note how the rangeIndex increases significantly to get much more data
		singleMiddle: {
			decodeLevel: 0,
			rangeIndex: 10,
		},
		singleMiddleFailure: {
			decodeLevel: 1,
			rangeIndex: 10,
		},
		singleFinal: {
			// Just do the final rangeIndex retrieve
			rangeIndex: -1,
		},
	},
};

const initCornerstone = ({ wonIvForceGpuRenderingRef }) => {
	metaData.addProvider((type, imageId) => {
		let _imageId = imageId;

		if (Array.isArray(imageId) && imageId[0]) {
			_imageId = imageId[0];
		}

		const _metaData = cornerstoneWADOImageLoader.wadors.metaDataManager.get(_imageId);

		if (!_metaData) {
			return;
		}

		if (type === 'scalingModule') {
			return _metaData.scalingModule;
		}
	});

	metaData.addProvider(
		utilities.calibratedPixelSpacingMetadataProvider.get.bind(utilities.calibratedPixelSpacingMetadataProvider)
	);

	if (wonIvForceGpuRenderingRef.current) {
		return setUseCPURendering(false);
	}

	return init();
};

export { htj2kMixedOptions };
export default initCornerstone;
