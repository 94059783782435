import axios from 'axios';

const getStudies = async ({ __config, status }) => {
	const response = await axios.get(
		`${__config.data_sources.blume}Study/userstudies?isUploaded=false&studyStatus=${status}`
	);

	if (!response?.status?.toString()?.startsWith(2)) {
		return [];
	}

	return response.data;
};

export default getStudies;
