import React from 'react';
import LoginBreezeView from '../../views/CrmView/LoginBreezeView';

import { useAuth } from '@worklist-2/core/src';
import { Navigate } from 'react-router-dom';

function BreezeLogin() {
	const { loggedIn } = useAuth();

	return <>{loggedIn ? <Navigate replace to="/Breeze" /> : <LoginBreezeView />}</>;
}

export default BreezeLogin;
