import React from 'react';
import Box from '@mui/material/Box';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import IconButton from '@mui/material/IconButton';
import { Icon } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { useAuth, useIsMobile } from '@worklist-2/core/src';
import PdfViewer from '@rs-ui/components/PdfViewer';

const MobileReportDetail = ({ report, onGoBack }) => {
	const isMobile = useIsMobile();
	const __userAuthContext = useAuth();

	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<IconButton
					sx={{
						background: '#F3F3F3',
						boxShadow: '2px 3px 7px rgba(18, 18, 18, 0.15)',
						marginRight: '17px',
					}}
					onClick={onGoBack}
				>
					<Icon component={KeyboardArrowLeftIcon} sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />
				</IconButton>

				<Typography
					sx={{
						fontSize: '14px',
						color: 'rgba(0, 0, 0, 0.6)',
						marginRight: '24px',
					}}
				>
					{report.reportName}
				</Typography>
			</Box>

			<Divider sx={{ margin: '16px -16px' }} />

			<Box sx={{ '& .rpv-core__inner-page': { boxSizing: 'border-box' } }}>
				<PdfViewer
					showToolbar
					canvasHeight="80vh"
					defaultZoomLevel={isMobile ? 2 : 1}
					fileUrl={report.reportPath}
					headers={{
						Accept: '*/*',
						Authorization: __userAuthContext.accessToken,
						SessionID: __userAuthContext.sessionId,
					}}
					isLoading={false}
				/>
			</Box>
		</Box>
	);
};

export default MobileReportDetail;
