// React Libraries
import React from 'react';

// Material UI Libraries
import Box from '@mui/material/Box';
import SortIcon from '@mui/icons-material/Sort';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { FormControlLabel, ListSubheader, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
	iconOpen: {
		transform: 'rotate(0deg)',
	},
}));

function FieldSort({ multiMeasureItem, measureOrder, setMeasureOrder, measuresToOrder, setMeasuresToOrder }) {
	const { classes } = useStyles();

	const renderSelectOptions = options =>
		options.map(field => (
			<MenuItem value={field.name}>
				{measuresToOrder.includes(field.name) ? (
					<RadioButtonCheckedIcon sx={{ margin: '0 16px 0 0', color: '#29b6f6' }} />
				) : (
					<PanoramaFishEyeIcon sx={{ margin: '0 16px 0 0' }} />
				)}
				{field.shortTitle}
			</MenuItem>
		));

	const renderSelectSubheader = value => (
		<ListSubheader
			sx={{
				color: 'rgba(255, 255, 255, 0.6)',
				backgroundColor: 'rgba(255, 255, 255, 0.03)',
				padding: '0px 80px 0px 15px',
			}}
		>
			{value}
		</ListSubheader>
	);

	const handleChangeSelect = event => {
		const {
			target: { value },
		} = event;
		setMeasuresToOrder(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		);
	};

	const handleChangeRadio = event => {
		const {
			target: { value },
		} = event;
		setMeasureOrder(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		);
	};

	return (
		<Box sx={{ position: 'absolute', bottom: 0, left: '55px' }}>
			<Select
				displayEmpty
				multiple
				IconComponent={SortIcon}
				MenuProps={{
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right',
					},
					transformOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					getContentAnchorEl: null,
					sx: { ul: { paddingTop: 0 } },
				}}
				classes={{
					iconOpen: classes.iconOpen,
				}}
				renderValue={() => null}
				sx={{ width: '38px', height: '38px' }}
				value={measuresToOrder}
				onChange={handleChangeSelect}
			>
				{renderSelectSubheader('Select field to sort')}
				{renderSelectOptions(multiMeasureItem)}
				{renderSelectSubheader('Sort')}
				<RadioGroup
					multiple
					aria-labelledby="demo-radio-buttons-group-label"
					defaultValue="female"
					name="radio-buttons-group"
					sx={{ marginLeft: '18px' }}
					value={measureOrder}
					onChange={handleChangeRadio}
				>
					<FormControlLabel control={<Radio color="info" />} label="Ascending" value="asc" />
					<FormControlLabel control={<Radio color="info" />} label="Descending" value="desc" />
				</RadioGroup>
			</Select>
		</Box>
	);
}

FieldSort.propTypes = {};

export default FieldSort;
