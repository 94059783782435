import React from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

import DeleteProgress from '@worklist-2/ui/src/components/DeleteProgress';
import { useIsTablet, useIsMobile, useConfig, useAuth } from '@worklist-2/core/src';
import { useShareDrawerStore } from '@worklist-2/patientPortal/src/stores';

import { getPatientId } from '../../../utils/getPatientId';
import { NOTIFICATION_TYPES } from '../../../consts';
import MobileListSubheader from './MobileListSubheader';
import MobileListFloatingIcon from './MobileListFloatingIcon';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const MobileList = ({
	items,
	color = 'rgba(18, 18, 18, 0.6)',
	background = '#FAFAFA',
	border = '1px solid rgba(18, 18, 18, 0.1)',
	sx,
	sxListItem,
	subheader,
	isSwipeable = false,
	sxListItemText = {},
	isFacilityList,
	showSelectCircle,
	isShared,
}) => {
	const __config = useConfig();
	const { profiles, patientMapping } = useAuth();
	const studyId = useShareDrawerStore(state => state.studyId);
	const user = useShareDrawerStore(state => state.user);
	const unshareStudyWithFacility = useShareDrawerStore(state => state.unshareStudyWithFacility);

	const { t } = useTranslation('profile');
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const phoenixBlumeRestrictHomeScreenFunctionality = useBooleanFlagValue(
		'phoenix-blume-restrict-home-screen-functionality'
	);

	const getListItem = item => (
		<ListItem
			// ID for facility and email for user
			key={item?.isFacility ? item.id : item.secondary}
			disablePadding
			data-testid="mobile-list-item"
			secondaryAction={
				<div className="secondaryActionSection">
					{item.secondaryAction ||
						(item.selected && !item.selectedFloating && (
							<Icon component={CheckCircleIcon} sx={{ color: '#42A5F5', fontSize: '16px' }} />
						))}
					{item?.isFacility && isShared && phoenixBlumeRestrictHomeScreenFunctionality && (
						<Button
							size="small"
							sx={{
								position: 'absolute',
								fontSize: '12px',
								right: '-16px',
								top: '50%',
								transform: 'translateY(-50%)',
							}}
							variant="primary"
							onClick={() =>
								unshareStudyWithFacility({
									__config,
									internalManagingOrgId: item.id,
									studyId,
									patientId: getPatientId({
										patientMapping,
										managingOrgId: item.id,
										profiles,
										profileId: user.profileId,
									}),
								})
							}
						>
							{t('Unshare')}
						</Button>
					)}
					{!item.selected && !isMobile && !isTablet && !isFacilityList && !isShared && (
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<IconButton height="17px" sx={{ visibility: 'hidden', padding: 0 }} width="14px">
								<DeleteProgress backgroundColor="white" color="#6C6C6C" onDelete={item.onDelete} />
							</IconButton>
							{showSelectCircle && (
								<Box
									className="ExpandableSectionButton"
									sx={{
										backgroundColor: '#FFF9F9',
										color: '#FFF9F9',
										border: '2px dotted #42A5F5',
										borderRadius: '50%',
										boxSizing: 'border-box',
										width: '15px',
										height: '15px',
										visibility: 'hidden',
										marginRight: '-10px',
										marginTop: '-10px',
										cursor: 'pointer',
									}}
									onClick={item.onClick}
								/>
							)}
						</Box>
					)}
				</div>
			}
			selected={item.selected}
			sx={{
				border,
				borderRadius: '6px',
				background,
				marginBottom: '6px',
				position: 'relative',

				'&.Mui-selected': {
					border: '1px solid #4BAAF6',

					'&:hover .ExpandableSectionButton': { visibility: 'hidden' },
				},

				'&:hover .ExpandableSectionButton': {
					visibility: typeof item.onDelete !== 'function' ? 'hidden' : 'visible',
				},

				...sxListItem,
				...item.sx,
			}}
		>
			{item.selected && item.selectedFloating && <MobileListFloatingIcon />}
			<ListItemButton
				component={item.href ? 'a' : undefined}
				disabled={item.primary === 'Remove'}
				href={item.href}
				sx={{ width: '100%' }}
				target={item?.target}
				onClick={item.onClick}
			>
				{item.avatar && (
					<ListItemAvatar>
						<Avatar {...item.avatar} />
					</ListItemAvatar>
				)}
				{isFacilityList && (
					<Box
						sx={{
							height: '40px',
							width: '40px',
							backgroundColor: '#7566CF',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: '4px',
							marginRight: '15px',
						}}
					>
						<Icon
							component={CorporateFareIcon}
							sx={{
								color: 'white',
								fontSize: '26px',
							}}
						/>
					</Box>
				)}
				{item.icon && (
					<ListItemIcon
						sx={{
							minWidth: '44px',
						}}
					>
						<Icon
							component={item.icon}
							sx={{
								color,
								fontSize: '26px',
							}}
						/>
					</ListItemIcon>
				)}
				<ListItemText
					primary={
						<Tooltip title={t(item.primary)}>
							<Typography
								sx={{
									fontSize: '14px',
									overflow: 'hidden',
									fontWeight: '700',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									width: '95%',
									...sxListItemText,
								}}
							>
								{t(item.primary)}
							</Typography>
						</Tooltip>
					}
					secondary={
						item.secondary && (
							<Tooltip title={item.secondary}>
								<Typography
									sx={{
										fontSize: '12px',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										width: '13rem',
									}}
								>
									{item.secondary}
								</Typography>
							</Tooltip>
						)
					}
					sx={{ width: '75%', overflow: 'hidden' }}
				/>
			</ListItemButton>
		</ListItem>
	);

	const shareItem = item =>
		isSwipeable ? (
			<SwipeableViews
				// ID for facility and email for user
				key={item?.isFacility ? item.id : item.secondary}
				resistance
				disabled={typeof item.onDelete !== 'function'}
				index={0}
				slideStyle={{ overflow: 'hidden' }}
				style={{ overflow: 'hidden', marginRight: '5px' }}
				onChangeIndex={item.onDelete}
			>
				{getListItem(item)}
				<Box
					sx={{ height: '67px', width: '100%', display: 'flex', alignItems: 'center', background: '#CF6679' }}
				>
					<Icon component={DeleteOutlineOutlinedIcon} sx={{ color: '#fff', paddingLeft: '10px' }} />
				</Box>
			</SwipeableViews>
		) : (
			getListItem(item)
		);

	return (
		<List disablePadding subheader={subheader && <MobileListSubheader subheader={subheader} />} sx={sx}>
			{items.map(item => {
				const isSupportedNotificationType =
					item.notificationType === NOTIFICATION_TYPES.OAI_PATIENT_SYNC ||
					item?.notificationType === NOTIFICATION_TYPES.OAI_PATIENT_DATA_SYNC;

				return isSupportedNotificationType ? item.component : shareItem(item);
			})}
		</List>
	);
};

export default MobileList;
