import React from 'react';

import styles from './NumberListBlock.module.scss';

const NumberListBlock = ({ theme, block, setDisplayMode, isSelected }) => (
	<div
		className={`${styles.numberListBlock} ${isSelected ? styles.numberListBlockSelected : ''}`}
		data-testid="numberListBlock"
		onMouseOver={() => setDisplayMode && setDisplayMode({ type: 'hover' })}
	>
		<span className={theme === 'OAI' ? styles.numberListOAI : styles.numberListBlume} data-testid="numberList">
			{block.text}
		</span>
	</div>
);

export default NumberListBlock;
