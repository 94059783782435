import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import { mediaTablet, useAuth, useConfig } from '@worklist-2/core/src';

import FormCard from '../../../components/FormCard';
import FormDrawer from '../../../components/FormDrawer';

import DetailSectionHeader from './DetailSectionHeader';

import ScrollableView from '../../../components/SharedHistory/SharedUsers/ScrollableView';
import { useFormStore } from '../../../stores/forms';
import { useAppointmentContext } from '../../../components/Appointment/AppointmentContext/AppointmentContext';

// Component

const FormsView = ({ patientId, studyId }) => {
	const { loggedInUser } = useAuth();
	const __config = useConfig();
	const [blumForms, setBlumForms] = useState([]);
	const [openFormDrawer, setOpenFormDrawer] = useState(false);
	const [selectedForm, setSelectedForm] = useState({});
	const { appointmentFormUpdated, setStudyFormUpdated } = useAppointmentContext();
	const getFormsByPatientId = useFormStore(state => state.getFormsByPatientId);

	const fetchData = useCallback(async () => {
		if (loggedInUser && patientId) {
			const result = await getFormsByPatientId({ __config, patientId });
			const filteredForms = result?.filter(
				item =>
					item.resource?.type === 'registration' ||
					(item.resource?.type === 'clinic' && item?.resource?.study?.id === studyId)
			);
			const transformedForms = filteredForms.map(item => ({
				id: item.resource.id,
				patient: item.resource?.patient,
				completed: item.resource?.completed,
				organizationId: item.resource.organizationId,
				status: item.resource.status.charAt(0).toUpperCase() + item.resource.status.slice(1),
				title: item.resource.name,
				type: item.resource.type,
				description: item.resource.description,
				questionData: item.resource.formTemplate?.content,
				lastModified: item.resource.lastModified,
				resource: item.resource,
				pdfFileName: item.resource?.pdfFileName,
				referenceTemplate: item.resource?.referenceTemplate,
			}));
			setBlumForms(transformedForms);
		}
	}, [loggedInUser]);

	const updateFormData = updatedResult => {
		setStudyFormUpdated(updatedResult);
		const updatedForm = blumForms.map(form => (form.id === updatedResult.id ? updatedResult : form));
		setBlumForms(updatedForm);
	};

	useEffect(() => {
		updateFormData(appointmentFormUpdated);
	}, [appointmentFormUpdated]);

	const handleOpenForm = formData => {
		setSelectedForm(formData);
		setOpenFormDrawer(true);
	};

	useEffect(() => {
		if (loggedInUser && loggedInUser.id) {
			fetchData();
		}
	}, [loggedInUser]);

	const data = blumForms;
	const dataLength = data.length;

	const required = data.some(obj => !obj.completed);

	const { t } = useTranslation('studyDetail');

	return blumForms.length > 0 ? (
		<Box
			component="section"
			sx={{
				'--element-offset': '10px',
				'--element-content-gap': '12px',
				'--element-card-width': 'calc(50% - (var(--element-content-gap) / 2))',
				'--element-card-height': '76px',
				marginBottom: '32px',
				[mediaTablet]: {
					'--element-card-width': '247px',
				},
			}}
		>
			<DetailSectionHeader
				count={required ? -1 : dataLength}
				header={t(required ? 'Required' : 'forms')}
				icon={required ? PriorityHighIcon : undefined}
				iconSx={required ? { color: '#cf6679' } : undefined}
				sx={{
					marginBottom: '24px',

					...(required
						? {
								backgroundColor: '#ECD7DB',
								borderRadius: 'var(--border-radius--small, var(--border-radius-small))',
						  }
						: {}),
				}}
			/>
			<Box
				data-testid="formCardSection"
				sx={{
					position: 'relative',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<ScrollableView
					sx={{
						flexDirection: 'row',
						gap: 'var(--element-content-gap)',
						width: '100%',
						maxWidth: '770px',
						alignItems: 'flex-start',
						height: 'calc(var(--element-card-height) + 4px)',
					}}
				>
					{data.map((item, index) => (
						<FormCard
							key={`${index}-${item}`}
							data={item}
							type="studyDetail"
							onClick={() => {
								handleOpenForm(item);
							}}
						/>
					))}
				</ScrollableView>
			</Box>

			{openFormDrawer && (
				<FormDrawer
					data={selectedForm}
					open={openFormDrawer}
					setOpen={setOpenFormDrawer}
					updateForm={updateFormData}
				/>
			)}
		</Box>
	) : null;
};

export default FormsView;
