import React from 'react';

const SchedulingIcon = () => (
	<svg fill="none" height="64" viewBox="0 0 85 64" width="85" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_45913)">
			<path
				d="M72.3495 53.388C71.1179 56.2217 68.5521 58.1232 65.7159 59.4969C64.7873 59.9429 63.8306 60.3369 62.8842 60.6863C60.146 61.6973 57.295 62.5135 54.3741 62.6309C51.2735 62.7528 48.1966 62.0897 45.0945 61.8786C40.3685 61.5649 35.6381 62.3157 30.9032 62.2518C26.8398 62.1938 22.5833 61.4222 19.2776 59.1446C18.7288 58.772 18.2115 58.3548 17.731 57.8972C14.2872 54.6265 13.0051 49.7203 12.1225 45.0595C11.4748 41.6133 10.9548 38.1062 11.2311 34.6109C11.5075 31.1157 12.6336 27.5981 14.9454 24.9562C16.7178 22.9328 19.0726 21.5368 21.261 19.9668C25.0112 17.2838 28.3393 14.0546 31.1349 10.3865C33.2149 7.65093 35.0705 4.61062 37.9067 2.65559C40.8945 0.608396 44.6533 0.00925313 48.2798 0.00033288C50.6837 -0.0100741 53.1276 0.220366 55.3517 1.13915C57.2059 1.90927 58.8401 3.12837 60.4447 4.33558C62.2711 5.70037 64.0935 7.06963 65.912 8.44335C67.0307 9.27888 68.61 10.1323 69.3573 11.3662C71.3497 14.6563 68.0128 18.0638 67.6191 21.2409C67.3011 23.8442 68.827 26.2645 69.916 28.6507C72.2426 33.7531 72.6793 39.4888 73.082 45.0877C73.287 47.8842 73.4697 50.8145 72.3495 53.388Z"
				fill="#4BAAF6"
				opacity="0.24"
			/>
			<path
				d="M69.878 43.9567C69.6418 46.2254 68.5127 48.2831 67.703 50.415C66.4921 53.5965 66.6927 56.784 65.7166 59.4988C64.788 59.9448 63.8312 60.3388 62.8849 60.6881C60.1467 61.6991 57.2957 62.5153 54.3748 62.6328C51.2742 62.7547 48.1973 62.0916 45.0952 61.8805C40.3692 61.5668 35.6388 62.3176 30.9039 62.2537C26.8405 62.1957 22.584 61.4241 19.2783 59.1464C19.6171 56.4555 21.7862 54.2789 24.239 53.1104C26.6919 51.9418 29.4702 51.5791 32.1593 51.087C34.8484 50.5949 37.6118 49.914 39.766 48.2251C41.17 47.1249 42.2397 45.656 43.192 44.1441C47.8185 36.7626 50.1005 27.8007 56.22 21.5981C58.4679 19.325 61.5685 17.4205 64.6959 18.0583C65.7701 18.2739 66.8591 18.8834 67.2216 19.9137C67.4667 20.602 67.3434 21.3588 67.2454 22.0843C66.638 26.5867 66.9658 31.1661 68.2081 35.536C68.9985 38.3102 70.1722 41.0889 69.878 43.9567Z"
				fill="#4BAAF6"
				opacity="0.44"
			/>
			<path
				d="M25.0593 63.0347C25.064 63.1897 25.1158 63.3396 25.2079 63.4644C25.3397 63.5782 25.5091 63.6391 25.6833 63.6353C26.7604 63.7186 27.8256 63.338 28.7616 62.7968C29.0023 62.6765 29.2122 62.5025 29.3752 62.2884C29.4542 62.1793 29.4987 62.049 29.5029 61.9144C29.5072 61.7798 29.471 61.647 29.399 61.5331C29.2973 61.4171 29.1673 61.3296 29.0216 61.2789C28.6678 61.1407 28.3036 61.0303 27.9326 60.9488C27.221 60.7734 26.4633 60.5459 25.7249 60.543C25.0727 60.543 24.9419 61.0321 24.9404 61.6089C24.9488 62.0863 24.9885 62.5626 25.0593 63.0347Z"
				fill="#4BAAF6"
			/>
			<path
				d="M19.8709 60.7636C19.722 60.9214 19.6299 61.1244 19.6095 61.3405C19.5103 61.9031 19.5392 62.4809 19.6941 63.0308C19.7164 63.1282 19.7567 63.2205 19.813 63.3029C19.9616 63.5021 20.2438 63.5423 20.492 63.5304C21.1353 63.4992 21.7399 63.2241 22.3015 62.9119C22.627 62.7496 22.922 62.5323 23.1736 62.2696C23.2991 62.138 23.3954 61.9814 23.4563 61.81C23.5172 61.6387 23.5414 61.4564 23.5272 61.275C23.5181 61.1695 23.4778 61.0691 23.4113 60.9866C23.2773 60.8725 23.106 60.8116 22.93 60.8156C22.3357 60.7606 21.7414 60.716 21.1471 60.6595C20.7401 60.6283 20.2201 60.4529 19.8709 60.7636Z"
				fill="#4BAAF6"
			/>
			<path
				d="M17.5947 35.0394C17.6823 36.8487 18.0136 38.667 18.7832 40.3068C18.9897 40.0095 19.0313 39.6408 19.0521 39.284C19.1086 38.2983 19.0417 37.3096 19.0209 36.3224C18.9986 35.2431 19.4265 34.3065 19.5127 33.2301C19.5692 32.5269 19.792 30.9078 19.6167 30.224C19.4993 29.769 18.6584 29.9266 18.2112 29.7066C17.617 29.4241 17.7314 30.5079 17.7046 30.8722C17.5833 32.2577 17.5466 33.6494 17.5947 35.0394Z"
				fill="#FFBDAD"
			/>
			<path
				d="M62.6769 3.69922H42.4328C41.4872 3.69922 40.5802 4.07514 39.9115 4.74429C39.2429 5.41344 38.8672 6.321 38.8672 7.26732V50.4621C38.8672 51.4085 39.2429 52.316 39.9115 52.9852C40.5802 53.6543 41.4872 54.0302 42.4328 54.0302H62.6769C63.6226 54.0302 64.5295 53.6543 65.1982 52.9852C65.8669 52.316 66.2426 51.4085 66.2426 50.4621V7.26583C66.2422 6.31977 65.8663 5.41259 65.1977 4.74377C64.529 4.07494 63.6223 3.69922 62.6769 3.69922ZM63.5683 34.874V48.522H40.0647V7.52898C40.0647 7.33374 40.1031 7.14042 40.1777 6.96004C40.2524 6.77966 40.3618 6.61577 40.4998 6.47772C40.6378 6.33966 40.8015 6.23015 40.9818 6.15544C41.1621 6.08073 41.3552 6.04227 41.5503 6.04227H44.6703V7.8962H58.6848V6.04822H62.1019C62.496 6.04822 62.8738 6.20485 63.1525 6.48366C63.4311 6.76248 63.5876 7.14063 63.5876 7.53493V32.8387C63.6324 33.5167 63.6254 34.1971 63.5668 34.874H63.5683Z"
				fill="#4BAAF6"
			/>
			<path
				d="M62.6661 3.69922H42.4221C41.4764 3.69922 40.5695 4.07514 39.9008 4.74429C39.2321 5.41344 38.8564 6.321 38.8564 7.26732V50.4621C38.8564 51.4085 39.2321 52.316 39.9008 52.9852C40.5695 53.6543 41.4764 54.0302 42.4221 54.0302H62.6661C63.6118 54.0302 64.5188 53.6543 65.1874 52.9852C65.8561 52.316 66.2318 51.4085 66.2318 50.4621V7.26583C66.2314 6.31977 65.8556 5.41259 65.1869 4.74377C64.5183 4.07494 63.6116 3.69922 62.6661 3.69922ZM63.5576 34.874V48.522H40.0524V7.52898C40.0524 7.13468 40.209 6.75653 40.4876 6.47772C40.7662 6.19891 41.1441 6.04227 41.5381 6.04227H44.6581V7.8962H58.6711V6.04822H62.0882C62.4822 6.04822 62.8601 6.20485 63.1387 6.48366C63.4174 6.76248 63.5739 7.14063 63.5739 7.53493V32.8387C63.6192 33.5166 63.6127 34.197 63.5546 34.874H63.5576Z"
				fill="url(#paint0_linear_2852_45913)"
			/>
			<path
				d="M28.4073 52.1537C28.0205 54.4337 27.4795 56.6848 26.7879 58.8915C26.7455 59.0641 26.6687 59.2265 26.5621 59.3687C26.2649 59.721 25.7093 59.6779 25.2517 59.5991C25.1534 59.5956 25.0597 59.5571 24.9872 59.4906C24.9331 59.4095 24.907 59.3129 24.913 59.2156C24.8 56.599 25.0615 53.9764 25.0169 51.3538C24.9724 48.7313 25.3141 47.2089 24.0453 44.9105C23.3916 50.2002 23.4659 54.5651 21.6548 59.5739C21.6424 59.6533 21.614 59.7293 21.5714 59.7974C21.5287 59.8655 21.4728 59.9243 21.4068 59.9701C21.3408 60.016 21.2663 60.048 21.1876 60.0642C21.109 60.0804 21.0278 60.0805 20.9491 60.0645L19.9285 60.0793C19.8427 60.0869 19.7567 60.0682 19.6818 60.0258C19.5689 59.9411 19.5793 59.7746 19.5986 59.6378C20.1038 56.0578 19.9418 52.4228 19.7754 48.8146C19.7309 47.8125 19.6878 46.8149 19.6387 45.8188C19.5645 44.1924 19.4902 42.5629 19.2109 40.9618C19.098 40.2898 18.942 39.6118 19.0148 38.9339C19.0876 38.2559 19.4426 37.5587 20.0651 37.2881C20.3722 37.1721 20.6982 37.1141 21.0264 37.1171C23.0142 37.019 25.0897 37.0919 27.0479 37.4992C28.4415 37.7891 28.8976 38.4671 29.0164 39.8571C29.3567 43.9471 29.0877 48.1307 28.4073 52.1537Z"
				fill="#606161"
			/>
			<path
				d="M61.8356 3.69922H41.593C40.6473 3.69922 39.7404 4.07514 39.0717 4.74429C38.403 5.41344 38.0273 6.321 38.0273 7.26732V50.4621C38.0273 51.4085 38.403 52.316 39.0717 52.9852C39.7404 53.6543 40.6473 54.0302 41.593 54.0302H61.8356C62.7799 54.0283 63.685 53.6515 64.3521 52.9825C65.0191 52.3136 65.3938 51.4072 65.3938 50.4621V7.26583C65.3934 6.32106 65.0186 5.415 64.3515 4.74639C63.6845 4.07778 62.7797 3.70119 61.8356 3.69922ZM62.727 34.874V48.522H39.2233V7.52898C39.2233 7.13468 39.3799 6.75653 39.6585 6.47772C39.9371 6.19891 40.315 6.04227 40.709 6.04227H43.829V7.8962H57.842V6.04822H61.2591C61.6531 6.04822 62.031 6.20485 62.3097 6.48366C62.5883 6.76248 62.7448 7.14063 62.7448 7.53493V32.8387C62.7906 33.5166 62.7846 34.197 62.727 34.874Z"
				fill="#4BAAF6"
			/>
			<path
				d="M62.7273 7.62343V48.6179H39.2266V7.62343C39.2254 7.42861 39.2627 7.23549 39.3364 7.05516C39.4101 6.87484 39.5187 6.71087 39.656 6.5727C39.7932 6.43453 39.9564 6.32488 40.1362 6.25006C40.3159 6.17523 40.5087 6.13672 40.7034 6.13672H43.8233V7.99956H57.8393V6.13969H61.2564C61.6474 6.1436 62.021 6.30156 62.2963 6.5793C62.5717 6.85705 62.7265 7.23221 62.7273 7.62343Z"
				fill="white"
			/>
			<path
				d="M59.4037 34.503C59.2599 36.0951 58.9517 37.668 58.484 39.1965C58.3865 39.5454 58.2664 39.8876 58.1245 40.2209C58.0081 40.4983 57.852 40.7572 57.661 40.9895C56.8527 41.9707 55.3878 42.1997 54.1844 41.7775C54.0849 41.7403 53.9868 41.7002 53.8873 41.6556C52.6438 41.0802 51.7672 39.8938 51.2428 38.6212C51.0259 38.0949 50.8565 37.5374 50.5326 37.0705C49.8492 36.0685 48.6146 35.6359 47.5003 35.1646C47.4379 35.1378 47.3785 35.1111 47.3205 35.0828C46.2063 34.5848 45.7026 34.0421 45.5154 32.7457C45.3282 31.4493 45.5377 30.1291 45.2183 28.858C44.9033 27.6255 44.0996 26.561 43.8128 25.3241C43.5261 24.0871 43.7891 22.7268 44.4234 21.6073C45.0578 20.4878 46.0176 19.5794 47.0977 18.8881C47.6718 18.5014 48.3044 18.2098 48.9711 18.0243C50.341 17.6779 51.8237 17.9797 53.085 18.619C54.7743 19.4739 56.1619 20.9754 56.7146 22.7818C57.1053 24.0589 57.083 25.4385 57.505 26.7067C57.8021 27.5987 58.2968 28.3867 58.6593 29.246C59.0778 30.2483 59.3288 31.3126 59.4022 32.3963C59.4583 33.0974 59.4588 33.8018 59.4037 34.503Z"
				fill="#E08142"
			/>
			<path
				d="M55.5359 27.0267C55.5359 27.6496 55.417 28.2725 55.4824 28.891C55.5597 29.6344 55.9028 30.3361 56.1272 31.0586C56.3515 31.7812 56.4436 32.6107 56.0484 33.2545C55.8279 33.58 55.5402 33.8543 55.2046 34.0588C53.9239 34.927 52.257 35.4236 50.8188 34.8557C50.382 34.6817 49.9853 34.4186 49.553 34.2298C49.1207 34.041 48.675 33.9324 48.2857 33.6827C47.5592 33.2218 47.1759 32.3714 46.9709 31.5359C46.7658 30.7003 46.702 29.8291 46.4137 29.0189C46.0676 28.048 45.399 27.1129 45.5476 26.093C45.6679 25.2619 46.338 24.587 47.1105 24.2584C47.8831 23.9299 48.7448 23.8942 49.5857 23.9284C51.0551 23.9818 52.5023 24.3043 53.8556 24.8799C54.9461 25.3422 55.5344 25.6767 55.5359 27.0267Z"
				fill="url(#paint1_linear_2852_45913)"
			/>
			<path
				d="M45.7207 26.9355C45.6587 26.9774 45.6083 27.0342 45.574 27.1007C45.5397 27.1672 45.5227 27.2413 45.5245 27.3161C45.531 27.4657 45.5679 27.6124 45.633 27.7472C45.7322 28.0386 45.923 28.2899 46.1768 28.4638C46.2207 28.4967 46.2736 28.5152 46.3284 28.5168C46.3832 28.5184 46.4371 28.503 46.4828 28.4727C46.5074 28.4422 46.5252 28.4068 46.5352 28.3689C46.5452 28.331 46.5471 28.2914 46.5408 28.2527C46.5244 27.9554 46.1961 26.6649 45.7207 26.9355Z"
				fill="#FFD6CC"
			/>
			<path
				d="M55.4278 26.2855C55.4771 26.5563 55.4618 26.8349 55.3832 27.0987C55.3022 27.3638 55.126 27.5894 54.8885 27.732C54.8435 27.7596 54.7925 27.7759 54.7399 27.7796C54.6967 27.7747 54.6556 27.7584 54.6209 27.7322C54.5861 27.7061 54.559 27.6711 54.5423 27.631C54.5083 27.5521 54.4911 27.4671 54.4918 27.3812C54.4755 27.0467 54.4443 26.5293 54.6404 26.229C54.8677 25.8603 55.3431 25.7503 55.4278 26.2855Z"
				fill="#FFD6CC"
			/>
			<path
				d="M48.9751 33.3344C50.3134 32.7385 51.7655 32.4422 53.2301 32.4662C53.7007 32.4661 54.1695 32.5225 54.6267 32.6342C55.3785 32.831 56.0616 33.2312 56.6011 33.7908C56.9365 34.1525 57.2291 34.5516 57.4733 34.9802C57.7274 35.3896 57.9366 35.8252 58.0972 36.2796C58.2458 36.7345 58.4464 37.2118 58.378 37.6845C57.4733 43.841 58.2295 47.0508 58.02 48.7397C54.1082 48.591 49.8487 48.765 45.9339 48.591C45.8025 48.6019 45.672 48.5626 45.5684 48.481C45.4793 48.3903 45.5877 47.473 45.5862 47.3452C45.5684 45.8362 46.0319 44.3718 46.2191 42.8717C46.4346 41.1947 46.3172 39.4864 46.5371 37.8169C46.757 36.1473 47.4315 34.0168 48.9751 33.3344Z"
				fill="#4BAAF6"
			/>
			<path
				d="M50.2922 33.1069C50.2141 33.3355 50.1754 33.5758 50.1778 33.8175C50.1934 33.9538 50.2433 34.084 50.3228 34.1958C50.4023 34.3076 50.5089 34.3974 50.6325 34.4568C50.8688 34.5515 51.1294 34.5671 51.3753 34.5014C51.5234 34.4744 51.6663 34.4242 51.7987 34.3527C51.9913 34.2316 52.1638 34.0811 52.3098 33.9067C52.5016 33.7171 52.6656 33.5014 52.7971 33.2659C52.8926 33.0618 52.9562 32.8442 52.9858 32.6207C53.0666 32.1291 53.0761 31.6283 53.014 31.134C53.0142 31.0591 52.9981 30.985 52.9668 30.917C52.9355 30.849 52.8898 30.7886 52.8328 30.74C52.7224 30.6866 52.5968 30.6734 52.4777 30.7028C51.8938 30.7802 51.2891 30.905 50.8434 31.2975C50.7068 31.418 50.5656 31.5399 50.4334 31.6633C50.391 31.7009 50.3602 31.7498 50.3447 31.8043C50.3291 31.8589 50.3295 31.9167 50.3457 31.971C50.3932 32.3499 50.3751 32.7341 50.2922 33.1069Z"
				fill="#FFD6CC"
			/>
			<path
				d="M53.0152 31.1211C53.0152 31.1211 52.0659 33.3824 50.6485 33.0642L50.3291 32.9037L50.3826 31.8392L53.0152 31.1211Z"
				fill="url(#paint2_linear_2852_45913)"
				opacity="0.5"
			/>
			<path
				d="M32.0175 26.8179C31.9053 27.4907 31.7327 28.1519 31.502 28.7937C31.3639 29.1847 31.0849 29.5099 30.7196 29.7057C30.3544 29.9015 29.9292 29.9538 29.5275 29.8523C28.8545 29.6858 28.1785 29.5252 27.8694 29.4628C27.5723 29.4628 27.8992 34.1622 28.018 34.9636C28.1904 36.2065 29.0877 38.1853 28.7609 38.4901C28.0433 39.1472 27.2395 39.7761 26.3466 40.115C25.9281 40.285 25.4819 40.3767 25.0303 40.3856C23.7749 40.4049 22.6071 39.7315 21.5166 39.1873C20.9223 38.89 20.3192 38.5778 19.7338 38.2388C19.5507 38.1485 19.3964 38.009 19.2881 37.8359C19.2032 37.6445 19.1596 37.4373 19.1603 37.2278C19.0816 35.814 19.0623 34.4031 19.092 32.9848C19.1068 32.3172 19.2495 31.5754 19.1796 30.9168C19.1217 30.3771 18.4635 30.4455 18.0564 30.1124C17.9435 30.0232 17.5261 29.9786 17.4726 29.8523C17.4494 29.7924 17.4363 29.7291 17.4339 29.6649C17.3938 28.9974 17.89 27.9508 17.997 27.2862C18.1352 26.4269 18.2377 25.5022 18.6656 24.7083C19.1306 23.843 19.9656 23.2216 20.8941 22.8707C21.8227 22.5198 24.6633 22.7666 25.2026 23.0818C28.7683 25.1632 31.8882 26.0686 31.9789 26.2307C32.0558 26.4168 32.0694 26.6232 32.0175 26.8179Z"
				fill="white"
			/>
			<path
				d="M29.5173 27.7734C29.5173 27.7734 29.287 29.1561 29.4475 29.4549C29.6079 29.7537 30.3463 29.8013 30.691 29.5471C31.0357 29.2929 29.5173 27.7734 29.5173 27.7734Z"
				fill="url(#paint3_linear_2852_45913)"
				opacity="0.5"
			/>
			<path
				d="M30.9678 25.9607C30.7048 26.4067 29.9085 26.4498 29.7138 26.9241C29.5483 27.3748 29.4653 27.8518 29.4687 28.332C29.4607 28.5488 29.5045 28.7644 29.5965 28.9609C29.7554 29.2716 30.1269 29.4069 30.4641 29.4872C30.9731 29.6355 31.5038 29.6949 32.033 29.6626C32.567 29.6276 33.0665 29.3867 33.4266 28.9906C33.8426 28.4911 33.9154 27.8012 33.9391 27.1471C33.9953 25.5848 33.8667 24.0213 33.5558 22.4892C33.4192 21.8128 33.2468 21.1378 33.2334 20.448C33.2156 19.5559 33.4682 18.6639 33.5306 17.7719C33.593 16.8799 33.431 15.8853 32.7536 15.2965C32.4636 15.0676 32.1411 14.8832 31.7968 14.7494C31.5769 14.6513 31.2976 14.5606 31.1163 14.7182C31.0444 14.7865 30.993 14.8735 30.9678 14.9694C30.7494 15.6563 31.0019 16.4086 30.8831 17.1192C30.8796 17.1948 30.8576 17.2684 30.819 17.3335C30.7804 17.3986 30.7264 17.4532 30.6617 17.4924C30.5678 17.5136 30.4725 17.528 30.3765 17.5355C30.2738 17.5736 30.1877 17.6465 30.1334 17.7417C30.0791 17.8368 30.0599 17.948 30.0793 18.0559C30.1238 18.2712 30.2231 18.4715 30.3675 18.6372C30.6855 19.0698 31.2055 19.4876 31.3243 20.0049C31.464 20.6145 31.4105 21.3638 31.4016 21.9867C31.3956 23.2549 31.6244 24.865 30.9678 25.9607Z"
				fill="#FFBDAD"
			/>
			<path
				d="M25.1537 59.4855C25.1433 59.878 25.1448 60.272 25.1537 60.6644C25.1482 60.7175 25.1534 60.7712 25.169 60.8223C25.1846 60.8734 25.2102 60.9208 25.2444 60.9618C25.3082 61.0092 25.3848 61.0361 25.4642 61.0391C25.7302 61.0763 25.9961 61.103 26.2635 61.1209C26.3078 61.1269 26.3528 61.1239 26.3958 61.1119C26.423 61.1004 26.4481 61.0843 26.4701 61.0644C26.6188 60.9302 26.7147 60.7472 26.7405 60.5485C26.7644 60.351 26.7644 60.1513 26.7405 59.9538C26.7316 59.6409 26.6817 59.3304 26.5919 59.0306C26.5841 58.995 26.5648 58.9631 26.5369 58.9399C26.5106 58.9234 26.4803 58.9147 26.4493 58.9146C26.1194 58.8786 25.786 58.8911 25.4598 58.9518C25.1731 59.0097 25.1627 59.1837 25.1537 59.4855Z"
				fill="#606161"
			/>
			<path
				d="M19.7906 60.7185C19.7728 60.7811 19.7717 60.8472 19.7873 60.9103C19.803 60.9734 19.8348 61.0313 19.8797 61.0783C19.9495 61.1182 20.0301 61.1349 20.11 61.1259L20.8751 61.1155C20.9351 61.1238 20.9962 61.118 21.0535 61.0983C21.1108 61.0787 21.1627 61.0459 21.2049 61.0025C21.2391 60.9393 21.2546 60.8676 21.2495 60.7958C21.2599 60.3959 21.3728 59.8458 21.2822 59.4578C21.2242 59.211 21.2421 59.2408 20.9657 59.2125C20.792 59.1857 20.6149 59.1903 20.4428 59.2259C19.899 59.3805 19.853 60.2532 19.7906 60.7185Z"
				fill="#606161"
			/>
			<path
				d="M25.4365 23.0977C25.4661 23.18 25.4748 23.2684 25.4617 23.3549C25.4337 23.4215 25.3903 23.4805 25.335 23.527C25.2797 23.5736 25.2142 23.6063 25.1438 23.6225C24.7622 23.7682 24.3461 23.7977 23.9478 23.7072C23.5526 23.6106 22.8707 23.1126 22.6553 22.7691C22.679 22.459 22.6844 22.1477 22.6716 21.837C22.65 21.5399 22.6535 21.2415 22.682 20.9449C22.6874 20.7156 22.7774 20.4964 22.9346 20.3294C23.2317 20.0722 23.6774 20.2046 24.0414 20.3503C24.3757 20.4841 24.7367 20.6476 24.8987 20.9643C25.0472 21.2527 25.2448 21.5708 25.3057 21.8682C25.3889 22.2711 25.3325 22.6874 25.4365 23.0977Z"
				fill="#FFBDAD"
			/>
			<path
				d="M23.7822 20.9727C23.7822 20.9727 24.8876 22.7017 25.3259 22.8162L25.3481 21.664L23.7822 20.9727Z"
				fill="url(#paint4_linear_2852_45913)"
				opacity="0.5"
			/>
			<path
				d="M28.0911 17.2289C28.1367 17.5655 28.1422 17.9064 28.1075 18.2443C28.0896 18.861 27.9937 19.4729 27.8222 20.0655C27.648 20.6627 27.2713 21.1805 26.757 21.5299C26.4279 21.7202 26.0571 21.8265 25.6772 21.8394C25.2974 21.8524 24.9202 21.7716 24.5789 21.6043C23.9862 21.2912 23.5039 20.8034 23.1975 20.2069C22.8911 19.6104 22.7753 18.9341 22.8659 18.2696C22.9595 17.7136 23.2255 17.2051 23.5063 16.716C23.894 16.041 24.3368 15.3646 24.992 14.9483C25.5862 14.5751 26.6262 14.3759 27.1655 14.9483C27.6424 15.4315 27.9767 16.5881 28.0911 17.2289Z"
				fill="#FFBDAD"
			/>
			<path
				d="M26.4209 11.744C26.7931 11.8366 27.1387 12.0147 27.4301 12.2643C27.7215 12.5138 27.9508 12.8279 28.0997 13.1816C28.239 13.5034 28.2853 13.8577 28.2334 14.2045C28.1694 14.4934 28.0601 14.7704 27.9096 15.0252C27.7149 15.3894 25.7167 15.602 25.5206 17.5957C25.5206 17.6462 25.1729 18.6572 25.1417 18.6364C24.9441 18.4877 25.115 17.838 24.9575 17.6462C24.8516 17.5359 24.7088 17.4686 24.5564 17.457C24.404 17.4455 24.2527 17.4906 24.1315 17.5838C24.0142 17.6819 23.923 17.8074 23.866 17.9493C23.809 18.0912 23.7879 18.245 23.8046 18.397C23.8284 18.6943 24.433 19.3098 24.2087 19.6503C24.078 19.8465 23.7036 20.7683 23.4911 20.8694C23.194 21.0106 22.8255 20.9006 22.5447 20.7207C22.2583 20.5269 22.0071 20.2856 21.8019 20.0071C21.0457 19.0318 20.6252 17.777 20.7619 16.549C20.8169 16.0852 21.0174 15.5589 21.4691 15.4325C21.5069 15.4256 21.5428 15.4109 21.5746 15.3894C21.6163 15.3409 21.6371 15.2778 21.6325 15.214C21.6753 14.658 21.8516 14.1205 22.1465 13.6474C22.4413 13.1742 22.8461 12.7793 23.3262 12.4963C24.1597 11.9878 25.4597 11.5121 26.4209 11.744Z"
				fill="#606161"
			/>
			<path
				d="M46.5048 28.6222C46.6177 29.052 46.7881 29.4646 47.0114 29.8487C47.2779 30.2331 47.6122 30.5656 47.9979 30.8299C48.9711 31.545 50.1745 32.0654 51.3645 31.8706C52.6095 31.664 53.6272 30.6812 54.1621 29.5439C54.6969 28.4066 54.8143 27.1057 54.8113 25.8435C54.8295 25.1457 54.7536 24.4485 54.5855 23.771C54.1561 22.2219 52.9423 20.9686 51.5116 20.2401C50.8301 19.8761 50.078 19.664 49.3068 19.6187C48.529 19.5749 47.7596 19.7995 47.1273 20.255C44.4976 22.2546 45.6951 26.0145 46.5048 28.6222Z"
				fill="#FFD6CC"
			/>
			<path
				d="M46.2925 22.1481C46.4672 22.113 46.6484 22.1333 46.811 22.206C47.236 22.377 47.5539 22.7308 47.8659 23.0654C48.6385 23.8994 49.5091 24.7186 50.604 25.0189C51.8267 25.3549 53.1475 24.9936 54.3851 25.2627C54.5571 25.2925 54.7219 25.3546 54.8709 25.4456C55.0492 25.5645 55.1785 25.7429 55.3508 25.8737C55.5231 26.0046 55.7802 26.0655 55.9451 25.9258C56.0044 25.8606 56.0485 25.783 56.0742 25.6987C56.0999 25.6144 56.1066 25.5254 56.0937 25.4381C56.061 24.6502 55.7594 23.9009 55.4533 23.1739C55.015 22.1332 54.5366 21.0791 53.7269 20.2971C52.788 19.3932 51.5757 18.5547 50.2772 18.5547L47.6208 19.0617C46.7605 19.5077 46.1692 20.3477 45.4635 21.0152C45.2062 21.2309 44.9749 21.4759 44.7742 21.7452C44.5476 22.1355 44.4234 22.5769 44.4131 23.0282C44.3938 23.2869 44.3701 23.7225 44.6375 23.8771C44.8529 24.002 45.1099 23.8548 45.254 23.6868C45.6314 23.2408 45.624 22.3175 46.2925 22.1481Z"
				fill="#E08142"
			/>
			<path
				d="M47.5048 35.6194C47.3202 36.403 47.0574 37.166 46.7204 37.897C46.3817 38.5705 45.4531 40.5984 44.7816 40.9329C44.0595 41.2853 41.3214 41.9825 40.7227 42.0911C40.2177 42.1726 39.7001 42.1134 39.2266 41.9201V30.4724L39.5059 29.0883C39.5749 28.8104 39.6029 28.5239 39.5891 28.2379C39.5558 27.9896 39.495 27.7458 39.4078 27.5109C39.3296 27.2638 39.269 27.0114 39.2266 26.7557V24.2401C39.2607 24.0647 39.2994 23.8878 39.3484 23.7109C39.4866 24.0498 39.6292 24.3888 39.7659 24.7322C39.7711 23.9536 39.902 23.181 40.1536 22.4442C40.3627 22.9462 40.5094 23.472 40.5904 24.0097C40.682 23.492 40.8315 22.9864 41.0361 22.5022C41.0852 22.3803 41.1743 22.242 41.3021 22.2569C41.4298 22.2717 41.4922 22.4338 41.513 22.5706C41.5918 23.0523 41.675 23.534 41.7537 24.0157C41.8607 23.4938 41.9691 22.975 42.0717 22.4531C42.091 22.3654 42.1266 22.2569 42.2202 22.2331C42.3569 22.1885 42.4461 22.3818 42.4654 22.5304C42.6147 23.6912 42.6147 24.8664 42.4654 26.0272C43.122 25.9974 43.2305 24.8229 43.8827 24.7887C43.9124 26.0896 43.2706 27.5347 42.2247 28.3152C41.8711 29.9684 41.5244 31.6212 41.1847 33.2734C40.9009 34.659 40.6112 36.0981 40.8757 37.4823C40.9 37.6787 40.9822 37.8635 41.1119 38.013C41.409 38.3104 41.9112 38.1498 42.3005 37.9729C44.0328 37.1899 45.7676 36.4054 47.5048 35.6194Z"
				fill="#FFD6CC"
			/>
			<path
				d="M44.654 35.8933C44.1323 36.3207 43.6427 36.7858 43.1891 37.2849C43.007 37.4556 42.8562 37.6568 42.7434 37.8796C42.5651 38.2869 42.6572 38.7582 42.7746 39.1864C42.9931 39.9812 43.2882 40.753 43.6556 41.4908C43.705 41.6134 43.7847 41.7213 43.8874 41.8045C44.1117 41.9532 44.4148 41.8506 44.6629 41.7376L46.3313 40.9853C46.569 40.8865 46.7981 40.7681 47.0162 40.6315C47.4816 40.2974 47.8593 39.8557 48.1171 39.344C48.8729 37.9562 49.1099 36.3444 48.7857 34.7976C48.7248 34.5003 48.5495 33.8313 48.1721 33.7941C47.875 33.7644 47.2495 34.3293 46.9836 34.4795C46.1976 34.9255 45.376 35.3328 44.654 35.8933Z"
				fill="#4BAAF6"
			/>
			<path
				d="M61.9629 40.3921C62.019 40.5938 62.1153 40.7821 62.2461 40.9457C62.3768 41.1092 62.5392 41.2445 62.7236 41.3436V22.8281C61.2275 23.4228 58.2665 31.3901 57.8877 32.9556C57.3528 35.1856 59.1238 33.522 59.5977 35.6525C60.1816 38.2765 61.3375 37.774 61.9629 40.3921Z"
				fill="#FFD6CC"
			/>
			<path
				d="M57.4393 30.5015C57.8657 29.9678 58.3456 29.3359 59.0052 29.5099C58.5913 31.2522 58.7476 33.0815 59.451 34.7282C59.812 35.5667 60.3097 36.5494 59.8253 37.324C59.6486 37.6065 59.0275 37.5738 58.7497 37.7596C58.304 38.057 58.1287 38.6026 57.5909 38.5238C57.053 38.445 56.64 37.9752 56.2924 37.5411C55.4351 36.4692 52.9629 33.973 53.2289 32.4655C53.2304 32.4655 55.9195 32.4015 57.4393 30.5015Z"
				fill="#4BAAF6"
			/>
			<path d="M46.4539 10.0703H40.2363V18.4003H46.4539V10.0703Z" fill="#4BAAF6" opacity="0.24" />
			<path
				d="M40.3484 16.8399C40.3484 16.8399 40.2801 17.9267 40.3143 18.3177C42.3363 18.2731 44.3256 18.3415 46.3491 18.2984L46.3358 17.3974C46.3478 17.3283 46.3367 17.2572 46.3042 17.1951C46.2717 17.133 46.2196 17.0834 46.156 17.054C45.6806 16.7448 45.1962 16.4311 44.654 16.2675C44.2203 16.1543 43.7752 16.0905 43.3272 16.0772C42.8444 16.0445 42.2873 15.9285 41.8148 16.0386C41.3424 16.1486 40.7496 16.5455 40.3484 16.8399Z"
				fill="white"
			/>
			<path
				d="M42.0229 15.8049C41.9902 16.0042 41.9055 16.1974 41.9234 16.3996C41.9434 16.5282 41.9923 16.6505 42.0664 16.7574C42.1405 16.8642 42.2379 16.9529 42.3512 17.0166C42.5935 17.1568 42.8678 17.2321 43.1476 17.2352C43.2185 17.2423 43.2902 17.2331 43.3571 17.2084C43.4102 17.1787 43.4529 17.1334 43.4795 17.0786C43.506 17.0239 43.5152 16.9623 43.5056 16.9021C43.4952 16.7832 43.4432 16.6672 43.4209 16.5468C43.3481 16.1513 43.6022 15.7366 43.4091 15.3679C43.2889 15.1733 43.1019 15.0295 42.8831 14.9635C42.6586 14.8951 42.4255 14.8591 42.1908 14.8564C41.8937 14.843 41.8625 14.9709 41.9471 15.1865C42.0269 15.3822 42.053 15.5957 42.0229 15.8049Z"
				fill="#FFBDAD"
			/>
			<path
				d="M42.0332 15.457C42.0332 15.457 42.1966 16.0279 42.6379 16.2524C42.8712 16.3842 43.1393 16.4413 43.406 16.416C43.406 16.416 43.4402 15.9328 43.5189 15.8213C43.5976 15.7098 42.0332 15.457 42.0332 15.457Z"
				fill="url(#paint5_linear_2852_45913)"
				opacity="0.5"
			/>
			<path
				d="M44.6805 13.5351C44.7004 13.53 44.7213 13.53 44.7413 13.5349C44.7612 13.5398 44.7796 13.5496 44.7949 13.5634C44.8138 13.5849 44.8278 13.6103 44.836 13.6377C44.8442 13.6652 44.8464 13.6941 44.8425 13.7224C44.8315 13.8504 44.7962 13.975 44.7385 14.0897C44.7102 14.1551 44.6716 14.2264 44.6018 14.2383C44.5921 14.2424 44.5818 14.2445 44.5713 14.2445C44.5608 14.2445 44.5505 14.2424 44.5408 14.2383C44.4948 14.2146 44.4933 14.1506 44.4978 14.0986L44.5215 13.7819C44.5244 13.7305 44.5406 13.6808 44.5685 13.6375C44.5963 13.5943 44.6349 13.559 44.6805 13.5351Z"
				fill="#FFBDAD"
			/>
			<path
				d="M41.5533 14.1226C41.5058 14.0616 41.4492 14.0085 41.3854 13.965C41.353 13.9432 41.3161 13.9293 41.2774 13.9241C41.2388 13.9189 41.1995 13.9228 41.1625 13.9352C41.1145 13.9601 41.0762 14.0006 41.0541 14.05C41.032 14.0994 41.0273 14.1549 41.0407 14.2073C41.0697 14.3118 41.1273 14.406 41.2071 14.4794C41.2547 14.5353 41.3141 14.58 41.3809 14.6102C41.4144 14.6258 41.4516 14.6318 41.4883 14.6276C41.525 14.6234 41.5598 14.6092 41.5889 14.5864C41.6198 14.5501 41.6391 14.5053 41.6444 14.4579C41.6497 14.4105 41.6408 14.3626 41.6186 14.3203C41.5739 14.2364 41.5376 14.1482 41.5102 14.0572"
				fill="#FFBDAD"
			/>
			<path
				d="M42.2245 15.6662C42.4325 15.816 42.672 15.9161 42.9246 15.9589C43.1773 16.0017 43.4363 15.986 43.682 15.913C44.5021 15.6543 44.7116 13.3856 44.6403 12.8965C44.5689 12.4073 44.4709 11.9524 44.046 11.7175C43.5646 11.4529 42.9703 11.5688 42.437 11.7056C42.0135 11.8112 41.5456 11.9494 41.3153 12.3167C41.0181 12.7627 41.1385 13.4867 41.3153 13.9728C41.504 14.5482 41.7105 15.2901 42.2245 15.6662Z"
				fill="#FFBDAD"
			/>
			<path
				d="M40.6778 13.0781C40.6321 12.8966 40.6062 12.7107 40.6006 12.5236C40.5905 12.4423 40.6066 12.36 40.6466 12.2887C40.7254 12.1727 40.8918 12.1667 41.0329 12.1727C41.0248 11.9555 41.0612 11.739 41.1399 11.5364C41.2885 11.1811 41.6629 10.9714 42.0031 10.8287C42.3715 10.6807 42.7636 10.6006 43.1604 10.5923C43.5215 10.5804 43.9033 10.6057 44.2093 10.7945C44.3289 10.8626 44.424 10.9666 44.4812 11.0919C44.5121 11.1918 44.534 11.2943 44.5466 11.3981C44.6001 11.6568 44.6684 11.8798 44.716 12.1415C44.8259 12.754 44.6521 13.8021 44.612 13.8244C44.5347 13.8646 44.5154 12.6722 44.3148 12.2887C44.2628 12.1905 44.1915 12.0835 44.0816 12.0686C43.9805 12.0721 43.8835 12.1098 43.8067 12.1757C43.361 12.4537 42.4428 12.4358 41.9228 12.366C41.6792 12.3333 41.3627 12.3422 41.2766 12.5726C41.2542 12.6619 41.2542 12.7554 41.2766 12.8447L41.4088 13.7813C41.4216 13.93 41.4166 14.0798 41.3939 14.2273C41.3605 14.1485 41.3192 14.0733 41.2706 14.0028C41.2469 13.9731 41.232 13.9478 41.1963 13.9404C41.1607 13.933 41.0864 13.9805 41.0805 13.9701C40.8922 13.7003 40.7557 13.3978 40.6778 13.0781Z"
				fill="#606161"
			/>
			<path
				d="M45.5672 14.1594C45.4744 14.0956 45.4 14.0084 45.3517 13.9067C45.3146 13.8011 45.3666 13.6569 45.478 13.6435C45.5894 13.6301 45.6756 13.7312 45.7752 13.7C45.8747 13.6688 45.8777 13.4889 45.8376 13.37L45.4379 12.2148C45.3874 12.0661 45.3503 11.8847 45.4706 11.7926C45.5909 11.7004 45.744 11.7762 45.8673 11.8476C46.0797 11.971 46.313 12.1122 46.3977 12.3426C46.4385 12.4708 46.4552 12.6054 46.4467 12.7396C46.4467 13.7045 46.5953 14.8002 46.4303 15.7487C46.3569 15.1278 46.0479 14.5588 45.5672 14.1594Z"
				fill="#FFBDAD"
			/>
			<path
				d="M45.5825 6.75443C45.9468 6.75443 46.2421 6.43427 46.2421 6.03932C46.2421 5.64438 45.9468 5.32422 45.5825 5.32422C45.2182 5.32422 44.9229 5.64438 44.9229 6.03932C44.9229 6.43427 45.2182 6.75443 45.5825 6.75443Z"
				fill="white"
			/>
			<path d="M62.7941 48.6172H39.2266V49.3739H62.7941V48.6172Z" fill="url(#paint6_linear_2852_45913)" />
			<path
				d="M54.6406 51.2731C54.641 51.3732 54.6216 51.4723 54.5835 51.5649C54.5454 51.6574 54.4894 51.7415 54.4187 51.8122C54.3479 51.883 54.2639 51.939 54.1715 51.9772C54.079 52.0153 53.9799 52.0347 53.8799 52.0343H46.6267C46.5243 52.0388 46.422 52.0226 46.3261 51.9865C46.2301 51.9504 46.1424 51.8952 46.0684 51.8243C45.9943 51.7534 45.9353 51.6681 45.895 51.5738C45.8547 51.4795 45.834 51.3779 45.834 51.2753C45.834 51.1727 45.8547 51.0712 45.895 50.9769C45.9353 50.8825 45.9943 50.7973 46.0684 50.7264C46.1424 50.6555 46.2301 50.6003 46.3261 50.5642C46.422 50.5281 46.5243 50.5118 46.6267 50.5164H53.8799C53.9795 50.516 54.0783 50.5353 54.1704 50.5731C54.2626 50.6109 54.3464 50.6666 54.4171 50.7369C54.4877 50.8072 54.5438 50.8907 54.5822 50.9827C54.6205 51.0747 54.6404 51.1734 54.6406 51.2731Z"
				fill="white"
				opacity="0.24"
			/>
			<path
				d="M54.6407 51.2731C54.6411 51.3732 54.6217 51.4723 54.5836 51.5649C54.5455 51.6574 54.4895 51.7415 54.4188 51.8122C54.3481 51.883 54.2641 51.939 54.1716 51.9772C54.0791 52.0153 53.98 52.0347 53.88 52.0343H53.4521C53.5546 52.0388 53.6569 52.0226 53.7528 51.9865C53.8488 51.9504 53.9365 51.8952 54.0105 51.8243C54.0846 51.7534 54.1436 51.6681 54.1839 51.5738C54.2241 51.4795 54.2449 51.3779 54.2449 51.2753C54.2449 51.1727 54.2241 51.0712 54.1839 50.9769C54.1436 50.8825 54.0846 50.7973 54.0105 50.7264C53.9365 50.6555 53.8488 50.6003 53.7528 50.5642C53.6569 50.5281 53.5546 50.5118 53.4521 50.5164H53.88C53.9797 50.516 54.0784 50.5353 54.1706 50.5731C54.2627 50.6109 54.3465 50.6666 54.4172 50.7369C54.4879 50.8072 54.544 50.8907 54.5823 50.9827C54.6207 51.0747 54.6405 51.1734 54.6407 51.2731Z"
				fill="url(#paint7_linear_2852_45913)"
			/>
			<path
				d="M24.6678 18.273C24.6845 18.1919 24.6685 18.1075 24.6232 18.0381C24.6059 18.0199 24.585 18.0054 24.5619 17.9955C24.5388 17.9856 24.5139 17.9805 24.4888 17.9805C24.4636 17.9805 24.4387 17.9856 24.4156 17.9955C24.3925 18.0054 24.3717 18.0199 24.3543 18.0381C24.3296 18.0677 24.3114 18.1021 24.3008 18.1392C24.276 18.2362 24.268 18.3368 24.277 18.4366C24.2759 18.5632 24.3055 18.6881 24.3632 18.8008C24.3928 18.857 24.438 18.9033 24.4934 18.9342C24.5488 18.9651 24.612 18.9792 24.6752 18.9747C24.7071 18.9732 24.7379 18.9634 24.7649 18.9463C24.7918 18.9292 24.8138 18.9054 24.8288 18.8772C24.8437 18.849 24.8512 18.8175 24.8503 18.7856C24.8494 18.7537 24.8403 18.7225 24.8238 18.6952C24.7926 18.6387 24.7109 18.6209 24.6752 18.557C24.6444 18.4652 24.6418 18.3663 24.6678 18.273Z"
				fill="url(#paint8_linear_2852_45913)"
				opacity="0.5"
			/>
			<path
				d="M19.1744 30.9114L19.5696 25.1133C19.5696 25.1133 18.9754 25.2976 18.6485 26.1733C18.3217 27.049 17.46 29.841 17.46 29.841L19.1744 30.9114Z"
				fill="url(#paint9_linear_2852_45913)"
				opacity="0.5"
			/>
			<path
				d="M28.7489 38.4847C28.0313 39.1418 27.2275 39.7707 26.3346 40.1097L25.6646 33.8179C25.5618 32.8454 25.5757 31.8642 25.7062 30.895L26.039 28.4375L27.8649 29.4633C27.5678 29.4633 27.8946 34.1628 28.0135 34.9642C28.1799 36.2011 29.0772 38.1769 28.7489 38.4847Z"
				fill="url(#paint10_linear_2852_45913)"
				opacity="0.5"
			/>
			<path
				d="M24.0386 44.9144C23.3849 50.2041 23.4591 54.569 21.6481 59.5778L21.4074 59.9658L20.9424 60.0684C20.9424 60.0684 21.6184 54.9318 21.6852 54.0323C21.7521 53.1329 22.6851 43.6328 22.6851 43.6328L24.0386 44.9144Z"
				fill="url(#paint11_linear_2852_45913)"
			/>
			<path
				d="M44.5077 47.0362C45.7541 47.0362 46.7645 46.0251 46.7645 44.7778C46.7645 43.5306 45.7541 42.5195 44.5077 42.5195C43.2614 42.5195 42.251 43.5306 42.251 44.7778C42.251 46.0251 43.2614 47.0362 44.5077 47.0362Z"
				fill="#51E08A"
			/>
			<path
				d="M44.5263 47.0362C45.7727 47.0362 46.7831 46.0251 46.7831 44.7778C46.7831 43.5306 45.7727 42.5195 44.5263 42.5195C43.2799 42.5195 42.2695 43.5306 42.2695 44.7778C42.2695 46.0251 43.2799 47.0362 44.5263 47.0362Z"
				fill="url(#paint12_linear_2852_45913)"
			/>
			<path
				d="M55.5615 47.0362C56.8078 47.0362 57.8182 46.0251 57.8182 44.7778C57.8182 43.5306 56.8078 42.5195 55.5615 42.5195C54.3151 42.5195 53.3047 43.5306 53.3047 44.7778C53.3047 46.0251 54.3151 47.0362 55.5615 47.0362Z"
				fill="#4BAAF6"
			/>
			<path
				d="M55.6474 47.0362C56.8938 47.0362 57.9042 46.0251 57.9042 44.7778C57.9042 43.5306 56.8938 42.5195 55.6474 42.5195C54.401 42.5195 53.3906 43.5306 53.3906 44.7778C53.3906 46.0251 54.401 47.0362 55.6474 47.0362Z"
				fill="url(#paint13_linear_2852_45913)"
			/>
			<path
				d="M48.0525 45.7334L47.8936 45.546L48.5324 45.0064L47.9753 44.4102L48.155 44.2422L48.889 45.0272L48.0525 45.7334Z"
				fill="white"
			/>
			<path
				d="M49.6653 45.7334L49.5078 45.546L50.1452 45.0064L49.588 44.4102L49.7678 44.2422L50.5003 45.0272L49.6653 45.7334Z"
				fill="white"
			/>
			<path
				d="M51.2766 45.7334L51.1191 45.546L51.7565 45.0064L51.1994 44.4102L51.3791 44.2422L52.1116 45.0272L51.2766 45.7334Z"
				fill="white"
			/>
			<path
				d="M44.6123 46.0006C44.4496 46.0024 44.2895 45.9603 44.1487 45.8787C43.7283 45.6349 43.5544 45.0818 43.4519 44.6893C43.4081 44.5531 43.3931 44.4093 43.4079 44.2669C43.4227 44.1246 43.4671 43.9869 43.5381 43.8627C43.65 43.6897 43.8257 43.5679 44.0269 43.5238C44.0544 43.5182 44.0828 43.5181 44.1104 43.5237C44.1379 43.5292 44.1641 43.5402 44.1873 43.5559C44.2106 43.5717 44.2304 43.592 44.2457 43.6156C44.2611 43.6392 44.2715 43.6656 44.2765 43.6932C44.2819 43.7207 44.2818 43.7489 44.2763 43.7763C44.2708 43.8037 44.2599 43.8298 44.2443 43.853C44.2287 43.8762 44.2087 43.8961 44.1854 43.9115C44.1621 43.927 44.136 43.9377 44.1086 43.943C44.0207 43.9672 43.9446 44.0227 43.8947 44.0991C43.8584 44.1746 43.8374 44.2566 43.8328 44.3403C43.8282 44.4239 43.8402 44.5077 43.8679 44.5867C43.9912 45.0863 44.1532 45.3881 44.3627 45.51C44.4354 45.5473 44.515 45.5692 44.5965 45.5743C44.6781 45.5794 44.7598 45.5676 44.8366 45.5397C44.8636 45.532 44.8919 45.5297 44.9198 45.5329C44.9477 45.5362 44.9747 45.5448 44.9993 45.5585C45.0238 45.5722 45.0455 45.5906 45.0629 45.6126C45.0804 45.6346 45.0933 45.6599 45.1011 45.6869C45.1088 45.714 45.1111 45.7422 45.1078 45.7702C45.1046 45.7981 45.0959 45.8251 45.0823 45.8497C45.0686 45.8743 45.0503 45.8959 45.0282 45.9134C45.0062 45.9309 44.981 45.9438 44.954 45.9515C44.8429 45.9838 44.7279 46.0003 44.6123 46.0006Z"
				fill="white"
			/>
			<path
				d="M44.2342 44.3215H44.2163C44.1598 44.3169 44.1074 44.2901 44.0706 44.2469C44.0338 44.2037 44.0157 44.1476 44.0202 44.0911L44.0782 43.3804C44.0831 43.324 44.11 43.2718 44.1531 43.235C44.1962 43.1983 44.252 43.18 44.3084 43.1842C44.3365 43.1862 44.3639 43.1938 44.389 43.2066C44.4141 43.2193 44.4364 43.2369 44.4546 43.2584C44.4729 43.2798 44.4867 43.3046 44.4953 43.3315C44.5038 43.3583 44.507 43.3865 44.5046 43.4146L44.4526 44.1267C44.4473 44.1807 44.4219 44.2308 44.3815 44.2669C44.341 44.303 44.2884 44.3225 44.2342 44.3215Z"
				fill="white"
			/>
			<path
				d="M45.3307 46.0172C45.2755 46.018 45.2222 45.9972 45.1822 45.9592L44.6592 45.4745C44.6386 45.455 44.6221 45.4316 44.6105 45.4057C44.599 45.3798 44.5926 45.3518 44.5919 45.3235C44.5911 45.2951 44.5959 45.2668 44.6061 45.2403C44.6162 45.2138 44.6315 45.1896 44.651 45.169C44.6705 45.1484 44.6939 45.1319 44.7198 45.1203C44.7457 45.1087 44.7736 45.1024 44.802 45.1016C44.8303 45.1009 44.8586 45.1057 44.885 45.1159C44.9115 45.126 44.9357 45.1413 44.9563 45.1608L45.4793 45.6455C45.5192 45.684 45.5426 45.7366 45.5445 45.7921C45.5465 45.8475 45.5268 45.9016 45.4897 45.9428C45.4701 45.9659 45.4457 45.9845 45.4183 45.9973C45.3909 46.0102 45.361 46.0169 45.3307 46.0172Z"
				fill="white"
			/>
		</g>
		<defs>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint0_linear_2852_45913"
				x1="58.7603"
				x2="112.095"
				y1="28.8959"
				y2="29.1721"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint1_linear_2852_45913"
				x1="49.4906"
				x2="65.9542"
				y1="25.2961"
				y2="60.6593"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint2_linear_2852_45913"
				x1="51.2681"
				x2="52.7587"
				y1="33.4523"
				y2="26.6421"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint3_linear_2852_45913"
				x1="29.651"
				x2="30.1181"
				y1="29.9827"
				y2="28.6523"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint4_linear_2852_45913"
				x1="24.687"
				x2="24.3181"
				y1="22.7641"
				y2="18.3635"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint5_linear_2852_45913"
				x1="42.8488"
				x2="42.6525"
				y1="16.6271"
				y2="13.9079"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint6_linear_2852_45913"
				x1="51.0393"
				x2="50.7477"
				y1="47.8634"
				y2="58.7877"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint7_linear_2852_45913"
				x1="51.1419"
				x2="71.7529"
				y1="51.3177"
				y2="50.9718"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint8_linear_2852_45913"
				x1="24.3632"
				x2="25.6885"
				y1="18.4767"
				y2="18.4411"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint9_linear_2852_45913"
				x1="18.2637"
				x2="25.9314"
				y1="27.9648"
				y2="28.3107"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint10_linear_2852_45913"
				x1="24.0571"
				x2="43.8862"
				y1="34.704"
				y2="30.6996"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint11_linear_2852_45913"
				x1="20.9424"
				x2="24.0386"
				y1="51.8558"
				y2="51.8558"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint12_linear_2852_45913"
				x1="44.556"
				x2="44.3715"
				y1="39.8122"
				y2="69.8631"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint13_linear_2852_45913"
				x1="55.6786"
				x2="55.4941"
				y1="39.8122"
				y2="69.8631"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<clipPath id="clip0_2852_45913">
				<rect fill="white" height="63.6475" width="84.3896" />
			</clipPath>
		</defs>
	</svg>
);

export default SchedulingIcon;
