import React from 'react';

const FileIcon = () => (
	<svg fill="none" height="20" viewBox="0 0 16 16" width="20" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10.5 0.5H2.16667C1.25 0.5 0.508333 1.25 0.508333 2.16667L0.5 13.8333C0.5 14.75 1.24167 15.5 2.15833 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V5.5L10.5 0.5ZM2.16667 13.8333V2.16667H9.66667V6.33333H13.8333V13.8333H2.16667ZM5.5 4.66667C5.5 5.125 5.125 5.5 4.66667 5.5C4.20833 5.5 3.83333 5.125 3.83333 4.66667C3.83333 4.20833 4.20833 3.83333 4.66667 3.83333C5.125 3.83333 5.5 4.20833 5.5 4.66667ZM5.5 8C5.5 8.45833 5.125 8.83333 4.66667 8.83333C4.20833 8.83333 3.83333 8.45833 3.83333 8C3.83333 7.54167 4.20833 7.16667 4.66667 7.16667C5.125 7.16667 5.5 7.54167 5.5 8ZM5.5 11.3333C5.5 11.7917 5.125 12.1667 4.66667 12.1667C4.20833 12.1667 3.83333 11.7917 3.83333 11.3333C3.83333 10.875 4.20833 10.5 4.66667 10.5C5.125 10.5 5.5 10.875 5.5 11.3333Z"
			fill="#4D79EA"
		/>
	</svg>
);

export default FileIcon;
