export const PRIORITIES = [
	{
		name: 'P1',
		value: 'P1',
	},
	{
		name: 'P2',
		value: 'P2',
	},
	{
		name: 'P3',
		value: 'P3',
	},
	{
		name: 'P4',
		value: 'P4',
	},
	{
		name: 'P5',
		value: 'P5',
	},
];

export const STATUSES = [
	{
		name: 'New',
		value: 'New',
	},
	{
		name: 'In Progress',
		value: 'In Progress',
	},
	{
		name: 'Waiting on Customer',
		value: 'Waiting on Customer',
	},
	{
		name: 'Closed with No Response',
		value: 'Closed with No Response',
	},
	{
		name: 'Resolved',
		value: 'Resolved',
	},
	{
		name: 'Bug submitted',
		value: 'Bug submitted',
	},
	{
		name: 'Feedback Submitted',
		value: 'Feedback Submitted',
	},
];

export const TYPES = [
	{
		name: 'HL7/FHIR Standard',
		value: 'HL7/FHIR Standard',
	},
	{
		name: 'IT/Security',
		value: 'IT/Security',
	},
	{
		name: 'OAI Document Viewer',
		value: 'OAI Document Viewer',
	},
	{
		name: 'OAI Image Viewer',
		value: 'OAI Image Viewer',
	},
	{
		name: 'OAI Link',
		value: 'OAI Link',
	},
	{
		name: 'OAI Storage',
		value: 'OAI Storage',
	},
	{
		name: 'OAI Worklist',
		value: 'OAI Worklist',
	},
	{
		name: 'Onboarding',
		value: 'Onboarding',
	},
	{
		name: 'Root',
		value: 'Root',
	},
	{
		name: 'User Access',
		value: 'User Access',
	},
	{
		name: 'VR - MModal',
		value: 'VR - MModal',
	},
	{
		name: 'VR - Native',
		value: 'VR - Native',
	},
];

export const ReportableIncidentCaseOptions = [
	{
		name: 'Yes',
		value: 'Yes',
	},
	{
		name: 'No',
		value: 'No',
	},
];

export const ImpactPatientCaseOptions = [
	{
		name: 'Yes',
		value: 'Yes',
	},
	{
		name: 'No',
		value: 'No',
	},
];

export const RootCauseOptions = [
	{
		name: 'Bug',
		value: 'Bug',
	},
	{
		name: 'Configuration',
		value: 'Configuration',
	},
	{
		name: 'Customer IT',
		value: 'Customer IT',
	},
	{
		name: 'Customer Training',
		value: 'Customer Training',
	},
	{
		name: 'Documentation',
		value: 'Documentation',
	},
	{
		name: 'Duplicate Case',
		value: 'Duplicate Case',
	},
	{
		name: 'Feedback/Feature Request',
		value: 'Feedback/Feature Request',
	},
	{
		name: 'No Response from Customer',
		value: 'No Response from Customer',
	},
	{
		name: 'Onboarding',
		value: 'Onboarding',
	},
	{
		name: 'Password Reset',
		value: 'Password Reset',
	},
	{
		name: 'Ramsoft\\OAI Platform',
		value: 'Ramsoft\\OAI Platform',
	},
	{
		name: 'User Access\\Management',
		value: 'User Access\\Management',
	},
];

export const SWVersionOptions = [
	{
		name: 'PowerServer 6.0',
		value: 'PowerServer 6.0',
	},
	{
		name: 'OmegaAI 1.0',
		value: 'OmegaAI 1.0',
	},
	{
		name: 'Blume 1.0',
		value: 'Blume 1.0',
	},
	{
		name: 'Root 1.0',
		value: 'Root 1.0',
	},
];

export const AccountRecordTypeOptions = [
	{
		name: 'Prospect',
		value: 'Prospect',
	},
	{
		name: 'Client',
		value: 'Client',
	},
	{
		name: 'Affiliate',
		value: 'Affiliate',
	},
];

export const IndustryOptions = [
	{
		name: 'Health',
		value: 'Health',
	},
	{
		name: 'Legal',
		value: 'Legal',
	},
	{
		name: 'Technology',
		value: 'Technology',
	},
];

export const HostingModelOptions = [
	{
		name: 'Cloud',
		value: 'Cloud',
	},
	{
		name: 'On-premises',
		value: 'On-premises',
	},
	{
		name: 'Managed',
		value: 'Managed',
	},
];

export const IntegrationValidationRequiredOptions = [
	{
		name: 'Yes',
		value: 'Yes',
	},
	{
		name: 'No',
		value: 'No',
	},
];

export const AccountStateOptions = [
	{
		name: 'Active',
		value: 'Active',
	},
	{
		name: 'Inactive',
		value: 'Inactive',
	},
	{
		name: 'Disabled',
		value: 'Disabled',
	},
	{
		name: 'Suspended',
		value: 'Suspended',
	},
];

export const WhereToOnboardCustomers = {
	'po-us01': 'Prod US',
	'po-sg01': 'Prod SG',
	'po-in01': 'Prod IN',
	'po-ca01': 'Prod CA',
};

export const ACTIVE_STATUSES = [
	{
		name: 'Active',
		value: true,
	},
	{
		name: 'Inactive',
		value: false,
	},
];

export const GlobalSearch_NoLinkResource = ['Contact', 'User'];

export const DefaultDepartment = [
	{
		Department: 'Support',
		DepartmentAlias: 'Support',
	},
	{
		Department: 'Product',
		DepartmentAlias: 'Product',
	},
	{
		Department: 'Development',
		DepartmentAlias: 'Development',
	},
	{
		Department: 'IT',
		DepartmentAlias: 'IT',
	},
	{
		Department: 'Sales - Marketing',
		DepartmentAlias: 'Sales & Marketing',
	},
	{
		Department: 'Finance',
		DepartmentAlias: 'Finance',
	},
	{
		Department: 'Operations',
		DepartmentAlias: 'Operations',
	},
	{
		Department: 'Onboarding',
		DepartmentAlias: 'Onboarding',
	},
];

export const CompanyDepartment = {
	Department: 'Company',
	DepartmentAlias: 'Company',
};

export const QuarterMonth = {
	Q1: ['JAN', 'FEB', 'MAR'],
	Q2: ['APR', 'MAY', 'JUN'],
	Q3: ['JUL', 'AUG', 'SEP'],
	Q4: ['OCT', 'NOV', 'DEC'],
};

export const defaultFeatures = [
	{
		resource: 'Home',
		display: 'Home',
		action: ['read'],
		permissions: [
			{
				resource: 'Patient',
				display: 'Patient',
				action: ['read'],
			},
			{
				resource: 'Visit',
				display: 'Visit',
				action: ['read'],
			},
			{
				resource: 'Order',
				display: 'Order',
				action: ['read'],
			},
			{
				resource: 'Study',
				display: 'Study',
				action: ['read'],
			},
			{
				resource: 'ImageViewer',
				display: 'ImageViewer',
				action: ['read'],
			},
			{
				resource: 'DocumentViewer',
				display: 'DocumentViewer',
				action: ['read'],
			},
			{
				resource: 'SignStudy',
				display: 'SignStudy',
				action: ['read'],
			},
			{
				resource: 'Send',
				display: 'Send',
				action: ['read'],
			},
			{
				resource: 'StudyHistory',
				display: 'StudyHistory',
				action: ['read'],
			},
			{
				resource: 'UserWorklist',
				display: 'UserWorklist',
				action: ['read'],
			},
			{
				resource: 'RoleWorklist',
				display: 'RoleWorklist',
				action: ['read'],
			},
			{
				resource: 'Worklist',
				display: 'Worklist',
				action: ['read'],
			},
			{
				resource: 'VoiceRecognition',
				display: 'VoiceRecognition',
				action: ['read'],
			},
		],
	},
	{
		resource: 'Organizations',
		display: 'Organizations',
		action: ['read'],
		permissions: [
			{
				resource: 'OrganizationMapView',
				display: 'OrganizationMapView',
				action: ['read'],
			},
			{
				resource: 'OrganizationDirectory',
				display: 'OrganizationDirectory',
				action: ['read'],
			},
			{
				resource: 'General',
				display: 'General',
				action: ['read'],
			},
			{
				resource: 'IdentityProvider',
				display: 'IdentityProvider(IdP)',
				action: ['read'],
			},
			{
				resource: 'Users',
				display: 'Users',
				action: ['read'],
			},
			{
				resource: 'BlumeForm',
				display: 'BlumeForm',
				action: ['read'],
			},
			{
				resource: 'Roles',
				display: 'Roles',
				action: ['read'],
			},
			{
				resource: 'HealthcareServices',
				display: 'HealthcareServices',
				action: ['read'],
			},
			{
				resource: 'StudyStatus',
				display: 'StudyStatuses',
				action: ['read'],
			},
			{
				resource: 'WorkflowSteps',
				display: 'WorkflowSteps',
				action: ['read'],
			},
			{
				resource: 'WorkflowConfiguration',
				display: 'WorkflowConfiguration',
				action: ['read'],
			},
			{
				resource: 'ImportScripts',
				display: 'ImportScripts',
				action: ['read'],
			},
			{
				resource: 'Device',
				display: 'Device',
				action: ['read'],
			},
			{
				resource: 'RIS',
				display: 'RIS',
				action: ['read'],
			},
		],
	},
	{
		resource: 'Codes',
		display: 'Codes',
		action: ['read'],
		permissions: [
			{
				resource: 'ModalityCodes',
				display: 'ModalityCodes',
				action: ['read'],
			},
			{
				resource: 'IssuerCodes',
				display: 'IssuerCodes',
				action: ['read'],
			},
			{
				resource: 'ConditionCodes',
				display: 'ConditionCodes',
				action: ['read'],
			},
			{
				resource: 'DiagnosisCodes',
				display: 'DiagnosisCodes',
				action: ['read'],
			},
			{
				resource: 'ProcedureCodes',
				display: 'ProcedureCodes',
				action: ['read'],
			},
		],
	},
	{
		resource: 'Logs',
		display: 'Logs',
		action: ['read'],
		permissions: [
			{
				resource: 'ActivityHistory',
				display: 'ActivityHistory',
				action: ['read'],
			},
			{
				resource: 'AuditLog',
				display: 'AuditLog',
				action: ['read'],
			},
			{
				resource: 'TaskLog',
				display: 'TaskLog',
				action: ['read'],
			},
			{
				resource: 'Settings',
				display: 'Settings',
				action: ['read'],
			},
		],
	},
	{
		resource: 'Search',
		display: 'Search',
		action: ['read'],
		permissions: [
			{
				resource: 'Study',
				display: 'Study',
				action: ['read'],
			},
			{
				resource: 'Patient',
				display: 'Patient',
				action: ['read'],
			},
			{
				resource: 'DICOMQuery',
				display: 'DICOMQuery',
				action: ['read'],
			},
			{
				resource: 'Organization',
				display: 'Organization',
				action: ['read'],
			},
			{
				resource: 'User',
				display: 'User',
				action: ['read'],
			},
			{
				resource: 'Marketplace',
				display: 'Marketplace',
				action: ['read'],
			},
			{
				resource: 'Help',
				display: 'Help',
				action: ['read'],
			},
		],
	},
	{
		resource: 'Marketplace',
		display: 'Marketplace',
		action: ['read'],
		permissions: [
			{
				resource: 'Like',
				display: 'Like',
				action: ['read'],
			},
			{
				resource: 'Favorites',
				display: 'Favorites',
				action: ['read'],
			},
			{
				resource: 'Downloads',
				display: 'Downloads',
				action: ['read'],
			},
			{
				resource: 'WriteaReview',
				display: 'WriteaReview',
				action: ['read'],
			},
			{
				resource: 'DownloadApp',
				display: 'DownloadApp',
				action: ['read'],
			},
		],
	},
	{
		resource: 'AddNew',
		display: 'AddNew',
		action: ['read'],
		permissions: [
			{
				resource: 'Order',
				display: 'Order',
				action: ['read'],
			},
			{
				resource: 'Import',
				display: 'Import',
				action: ['read'],
			},
		],
	},
	{
		resource: 'DICOM',
		display: 'DICOM',
		action: ['read'],
		permissions: [
			{
				resource: 'DICOMSend',
				display: 'DICOMSend',
				action: ['read'],
			},
			{
				resource: 'DICOMRetrieve',
				display: 'DICOMRetrieve',
				action: ['read'],
			},
			{
				resource: 'DICOMWebStorage',
				display: 'DICOMWebStorage',
				action: ['read'],
			},
			{
				resource: 'DICOMWebRetrieve',
				display: 'DICOMWebRetrieve',
				action: ['read'],
			},
			{
				resource: 'DICOMWebSearch',
				display: 'DICOMWebSearch',
				action: ['read'],
			},
		],
	},
	{
		resource: 'Root',
		display: 'Root',
		action: ['read'],
		permissions: [
			{
				resource: 'ViewRegularReports',
				display: 'ViewRegularReports',
				action: ['read'],
			},
			{
				resource: 'CreateEditReports',
				display: 'Create/EditReports',
				action: ['read'],
			},
			{
				resource: 'ViewPremiumReports',
				display: 'ViewPremiumReports',
				action: ['read'],
			},
		],
	},
];

export const TargetEntities = {
	'pre-us01': [
		{
			name: 'Pre US01',
			value: 'pre-us01',
		},
	],
	'po-us01': [
		{
			name: 'Prod US - United States',
			value: 'po-us01',
		},
		{
			name: 'Prod CA - Canada',
			value: 'po-ca01',
		},
		{
			name: 'Prod IN - India',
			value: 'po-in01',
		},
		{
			name: 'Prod  SG - Singapore',
			value: 'po-sg01',
		},
	],
};

export const CaseRecordTypes = [
	{
		name: 'Support Issue',
		value: 'Support Issue',
	},
	{
		name: 'Error Issue',
		value: 'Error Issue',
	},
	{
		name: 'Claim Issue',
		value: 'Claim Issue',
	},
];
