import React from 'react';

import { Box, TextField, Typography } from '@mui/material';

import { Controller } from 'react-hook-form';

import createStyles from './form-styles';

const FormTextField = ({ label, control, name }) => {
	const classes = createStyles();

	return (
		<Box>
			<Typography sx={classes.label}>{label}</Typography>
			<Controller
				control={control}
				name={name}
				render={({ field }) => <TextField {...field} fullWidth multiline rows={5} sx={classes.textField} />}
				sx={classes.textFieldBox}
			/>
		</Box>
	);
};

export default FormTextField;
