export default function getSeriesOrientationPlane(instance) {
	const ImageOrientationPatientTag = '00200037';
	const ImageTypeTag = '00080008';

	const imagePlaneType = {
		ptUnknown: 0,
		ptOblique: 1,
		ptSagittal: 2,
		ptCoronal: 3,
		ptAxial: 4,
		ptMixed: 5,
	};

	if (!instance) {
		return imagePlaneType.ptUnknown;
	}

	let planeType;

	let row_dircos_x = parseFloat(instance[ImageOrientationPatientTag]?.Value?.[0]);
	let row_dircos_y = parseFloat(instance[ImageOrientationPatientTag]?.Value?.[1]);
	let row_dircos_z = parseFloat(instance[ImageOrientationPatientTag]?.Value?.[2]);

	let col_dircos_x = parseFloat(instance[ImageOrientationPatientTag]?.Value?.[3]);
	let col_dircos_y = parseFloat(instance[ImageOrientationPatientTag]?.Value?.[4]);
	let col_dircos_z = parseFloat(instance[ImageOrientationPatientTag]?.Value?.[5]);

	// compute normal to row and col (cross product of row and col)
	// This is common approach
	// We are using this approach in DicomImagePlane.pas  in version 6.X;

	let nrm_dircox_x = row_dircos_y * col_dircos_z - col_dircos_y * row_dircos_z;
	nrm_dircox_x = Math.round(nrm_dircox_x);

	let nrm_dircox_y = row_dircos_x * col_dircos_z - col_dircos_x * row_dircos_z;
	nrm_dircox_y = Math.round(nrm_dircox_y);

	let nrm_dircox_z = row_dircos_x * col_dircos_y - col_dircos_x * row_dircos_y;
	nrm_dircox_z = Math.round(nrm_dircox_z);

	if (Math.ceil(Math.abs(nrm_dircox_x)) >= 1) {
		planeType = imagePlaneType.ptSagittal;
	} else if (Math.ceil(Math.abs(nrm_dircox_y)) >= 1) {
		planeType = imagePlaneType.ptCoronal;
	} else if (Math.ceil(Math.abs(nrm_dircox_z)) >= 1) {
		planeType = imagePlaneType.ptAxial;
	} else {
		planeType = imagePlaneType.ptOblique;
	}

	if (planeType === imagePlaneType.ptOblique) {
		let imageType = instance[ImageTypeTag]?.Value?.at[2];
		if (imageType) {
			if (imageType === 'AXIAL') {
				planeType = imagePlaneType.ptAxial;
			} else if (imageType === 'SAGITTAL') {
				planeType = imagePlaneType.ptSagittal;
			} else if (imageType === 'CORONAL' || imageType === 'LOCALIZER') {
				planeType = imagePlaneType.ptCoronal;
			}
		}
	}

	return planeType;
}

export const DICOM_IMAGE_PLANE_ANNOTATIONS = ['Unknown', 'Oblique', 'Sagittal', 'Coronal', 'Axial', 'Mixed'];
