// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wbgI52Vlg0LcO_5tzxMA{margin:20px 100px}.wrcqfSZ2ZkzwuzAv_wqO{margin:10px 90px;padding:10px;border-radius:10px;border:1px solid #42a5f5;background:rgba(66,165,245,.05)}.wbgI52Vlg0LcO_5tzxMA span{font-feature-settings:\"clig\" off,\"liga\" off;font-family:Roboto,serif;font-size:14px;font-style:normal;font-weight:400;line-height:20px;white-space:pre-wrap;word-wrap:break-word}.CH37o4xC7LufnokFhpDH{color:#fff}.VMleqo2Ey00Bhvf8l6Wl{color:rgba(18,18,18,.87)}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/common/MainArea/Draft/TextBlocks/BulletListBlock/BulletListBlock.module.scss"],"names":[],"mappings":"AAAA,sBACC,iBAAA,CAEA,sBACC,gBAAA,CACA,YAAA,CAEA,kBAAA,CACA,wBAAA,CACA,+BAAA,CAGD,2BACC,2CAAA,CACA,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEA,oBAAA,CACA,oBAAA,CAIF,sBACC,UAAA,CAGD,sBACC,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bulletListBlock": "wbgI52Vlg0LcO_5tzxMA",
	"bulletListBlockSelected": "wrcqfSZ2ZkzwuzAv_wqO",
	"bulletListOAI": "CH37o4xC7LufnokFhpDH",
	"bulletListBlume": "VMleqo2Ey00Bhvf8l6Wl"
};
export default ___CSS_LOADER_EXPORT___;
