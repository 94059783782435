export function cloneStateWithoutFunctions(state) {
  // If the state is a primitive or null, return it as is
  if (typeof state !== 'object' || state === null) {
    return state;
  }

  // Create a new object or array to clone into
  const clonedState = Array.isArray(state) ? [] : {};

  // Iterate over each key in the state
  for (const key in state) {
    // Check if the current value is a function
    if (typeof state[key] === 'function') {
      // If it's a function, replace it with undefined
      clonedState[key] = undefined;
    } else {
      // If it's not a function, recursively clone it
      clonedState[key] = cloneStateWithoutFunctions(state[key]);
    }
  }

  return clonedState;
}
