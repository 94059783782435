import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useCrmContext } from '../../../context/CRMContext';
import { useLiveChatContext } from '../LiveChat/LiveChatContext';
import { crmSearchScopes, useCRMSearchScope, useCRMDataLoader, useAuth } from '@worklist-2/core/src';

import CasesTable from './CasesTable';
import { CustomSwitch, Header, Knob, StyledTableContainer, Title } from '../../../views/CrmView/styles';

import { Work, HomeOutlined } from '@mui/icons-material';

const Cases = () => {
	const navigate = useNavigate();
	const { updateScope, setLoading, crmLoader, isFetchData, setFetchData, setCaseGlobalList } = useCRMSearchScope();
	const [countLoading, setCountLoading] = useState(0);
	const [data, setData] = useState([]);
	const { showDetails, toggleDetails, isChatOpen: isCRMChatOpen } = useCrmContext();
	const { isChatOpen: isLiveChatOpen } = useLiveChatContext();
	const { authorized } = useAuth();
	const [field, setField] = useState(null);
	const [searchText, setSearchText] = useState(null);

	const caseDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.caseApi,
	});

	useEffect(() => {
		updateScope(crmSearchScopes.caseApi);
		return () => {
			toggleDetails();
		};
	}, []);

	useEffect(() => {
		if (isFetchData || (crmLoader && authorized)) {
			fetchData().catch(console.error);
		}
	}, [crmLoader, authorized, isFetchData]);

	useEffect(() => {
		if (searchText) {
			setTimeout(() => {
				fetchData(caseDataLoader, {
					[field]: removeSpecialCharacter(searchText),
					summary: true,
					sort: '-lastUpdated',
				}).catch(console.error);
			}, 500);
		} else {
			setField(null);
			setTimeout(() => {
				fetchData(caseDataLoader, { summary: true, sort: '-lastUpdated' }).catch(console.error);
			}, 500);
		}
	}, [searchText]);

	const removeSpecialCharacter = text => {
		text = text.replace('&', '%26');
		return text;
	};

	const fetchData = async (loader, value) => {
		const fnName = 'load';
		const bodyData = undefined;
		if (!loader) {
			loader = caseDataLoader;
		}

		if (authorized) {
			setCountLoading(p => p + 1);
			setData(null);
			setCaseGlobalList([]);

			try {
				await loader[fnName](value, !!bodyData || undefined, bodyData).then(result => {
					const newData = result;

					setTimeout(() => {
						{
							setData(newData);
							setFetchData(false);
							setCaseGlobalList(newData);
						}
						setTimeout(() => {
							setCountLoading(p => Math.max(p - 1, 0));
						}, 0);
					}, 0);
				});
			} catch (e) {
				setCountLoading(p => Math.max(p - 1, 0));
				console.error(e);
			}
		}
	};

	useEffect(() => {
		setLoading(!!countLoading);
	}, [countLoading, searchText]);

	return (
		<>
			<Header>
				<Title>
					<Work sx={{ fontSize: 29, mr: '12px' }} />
					<span>Cases</span>
				</Title>
				<CustomSwitch>
					<Knob onClick={() => navigate('/')}>
						<HomeOutlined />
					</Knob>
					<label>Cases</label>
				</CustomSwitch>
			</Header>
			<StyledTableContainer
				className="invisible-scrollbar"
				sx={{
					width: isLiveChatOpen
						? 'calc(100% - 620px)'
						: showDetails || isCRMChatOpen
						? 'calc(100% - 24px)'
						: 'calc(100% - 51px)',
				}}
			>
				<CasesTable
					data={data}
					field={field}
					searchText={searchText}
					setData={setData}
					setField={setField}
					setSearchText={setSearchText}
				/>
			</StyledTableContainer>
		</>
	);
};

export default Cases;
