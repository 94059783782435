// @flow

// libs
import _difference from 'lodash/difference';
import _isArray from 'lodash/isArray';
import _isPlainObject from 'lodash/isPlainObject';
import { create } from 'zustand';
// utils
import { searchScopes, useFhirDataLoader } from '@worklist-2/core/src';

/** Object type for valueSets where key is the valueSet `name` and value is an array of that valueSet `values` */
export type TValueSet = Partial<Record<TValueSetNames, any[]>>;

/**
 * Collection of all valueSet `names`, BE treats them as case-insensitive
 *
 * Keep them in alphabetical order and in PascalCase !
 */
type TValueSetNames =
	// A
	| 'AccessType'
	| 'AccidentType'
	| 'AccountStatus'
	| 'AccountType'
	| 'ActPriority'
	| 'ActReason'
	| 'AdministrativeGender'
	| 'alcohol-type'
	| 'AllergyClinicalStatus'
	| 'AllergyCode'
	| 'AllergyCriticality'
	| 'AllergyIntoleranceCategory'
	| 'AllergyIntoleranceSeverity'
	| 'AllergyStatus'
	| 'AllergyType'
	| 'AllergyVertificationStatus'
	| 'AmbulatoryStatus'
	| 'AnatomicFocus'
	| 'AnatomicRegion'
	| 'AppointmentCancellationReason'
	| 'AppointmentStatus'
	| 'AppointmentType'
	| 'Appropriateness'
	| 'AuditEventAction'
	| 'AuditEventEntityType'
	| 'AuditEventNetworkType'
	| 'AuditEventOutcome'
	| 'AuditEventSourceType'
	| 'AuditEventSubType'
	| 'AuditEventType'
	| 'AuthorizationEligibility'
	// B
	| 'BeneficiaryRelationship'
	| 'BodyPart'
	| 'BodyRegion'
	// C
	| 'CallType'
	| 'CancellationReason'
	| 'ChargeItemStatus'
	| 'ChargeStatus'
	| 'CodeSystemStatus'
	| 'CommunicationRequestStatus'
	| 'CommunicationStatus'
	| 'CompositionStatus'
	| 'ConditionCategory'
	| 'ConditionClinical'
	| 'Confidentiality'
	| 'ContactMethod'
	| 'ContactPointSystem'
	| 'ContactPointUse'
	| 'Country'
	| 'CoverageLevel'
	| 'CoverageType'
	| 'Currency'
	// D
	| 'DefaultGuiRole'
	| 'DeviceNameType'
	| 'DeviceType'
	| 'Diagnosis'
	| 'DiagnosisCodingMethod'
	| 'DiagnosisRole'
	| 'DiagnosticReportCategory'
	| 'DiagnosticReportCode'
	| 'DiagnosticReportCodedDiagnosis'
	| 'DiagnosticReportPerformerRole'
	| 'DiagnosticReportStatus'
	| 'DicomReason'
	| 'DistributionMethod'
	| 'DocumentClass'
	| 'DocumentReferenceStatus'
	| 'DocumentReferenceType'
	| 'DocumentRelationshipType'
	| 'DocumentType'
	// E
	| 'EmploymentStatus'
	| 'EncounterClass'
	| 'EncounterReasonCodes'
	| 'EncounterState'
	| 'EntityRole'
	| 'Ethnicity'
	| 'EventStatus'
	| 'EncounterType'
	// F
	| 'FamilyHistoryAbsentReason'
	| 'FamilyHistoryStatus'
	| 'FamilyMember'
	| 'FaxDirection'
	| 'FaxStatus'
	| 'FHIRDeviceFeature'
	| 'FHIRDeviceStatus'
	| 'FHIRDeviceStatusReason'
	| 'FHIRDeviceTypes'
	| 'FinancialClass'
	| 'FlagCategory'
	| 'FlagStatus'
	// H
	| 'Hl7BeneficiaryRelationship'
	| 'Hl7EncounterClass'
	| 'Hl7MaritalStatus'
	| 'Hl7ObservationStatus'
	| 'HL7Priority'
	| 'HL7RequestStatus'
	| 'Hl7Severity'
	| 'HL7StudyStatus'
	// I
	| 'Image'
	| 'ImagingStudyStatus'
	| 'InstanceAvailability'
	| 'InsuranceStatus'
	| 'IssuerIDType'
	// L
	| 'Language'
	| 'Laterality'
	| 'LifeCycle'
	| 'LinkageType'
	| 'LinkType'
	| 'LoincCode'
	// M
	| 'MaritalStatus'
	| 'MedicationCategory'
	| 'MedicationStatus'
	| 'MedicationTaken'
	| 'MessageStatus'
	| 'Modality'
	| 'ModalityModifier'
	| 'Modifier'
	// N
	| 'NamePrefix'
	| 'NameSuffix'
	| 'NoteColor'
	| 'NoteDay'
	| 'NoteDuration'
	| 'NoteMonth'
	| 'NoteOrdinalDate'
	| 'NoteReason'
	// O
	| 'ObservationCategory'
	| 'ObservationCode'
	| 'ObservationDataAbsentReason'
	| 'ObservationInterpretation'
	| 'ObservationMethod'
	| 'ObservationStatus'
	| 'ObservationUnit'
	| 'OrderEligibility'
	| 'OrganizationAffiliationRole'
	| 'OrganizationType'
	// P
	| 'ParticipantRequired'
	| 'ParticipantRoles'
	| 'ParticipantStatus'
	| 'ParticipantType'
	| 'PatientActivityType'
	| 'PatientContactRelationship'
	| 'PatientPortalStatus'
	| 'PatientRelationshipType'
	| 'PayerType'
	| 'Pharmaceutical'
	| 'PhysicianSpecialty'
	| 'Population'
	| 'PracticeSettingCodeValueSet'
	| 'PracticeType'
	| 'Priority'
	| 'PriorityDicomSend'
	| 'ProblemCode'
	| 'ProcedureCode'
	| 'ProcedureModifier'
	| 'ProcedureReasonCodes'
	| 'ProcedureStatus'
	| 'Program'
	// R
	| 'Race'
	| 'ReactionEventSeverity'
	| 'ReasonForExam'
	| 'ReferringPhysicianGuiRole'
	| 'RejectionReason'
	| 'Relationship'
	| 'RequestIntent'
	| 'RequestStatus'
	| 'ResourceDefinitions'
	| 'RoleAccessResource'
	| 'RoleAction'
	| 'RoleDefaults'
	| 'RoleResource'
	| 'RoleUserType'
	// S
	| 'ScheduledModality'
	| 'SecurityLabels'
	| 'SecurityRoleType'
	| 'ServiceCategory'
	| 'ServiceDeliveryLocationRoleType'
	| 'ServiceRequestCategoryCodes'
	| 'ServiceRequestOrderDetailsCodes'
	| 'ServiceType'
	| 'Severity'
	| 'Sex'
	| 'SlotStatus'
	| 'SmokingStatus'
	| 'SNOMEDCTBodyStructures'
	| 'SNOMEDCTClinicalFindings'
	| 'SOPClass'
	| 'SpecialArrangement'
	| 'SpecialCourtesy'
	| 'State'
	| 'Status'
	| 'StudyPlayerType'
	| 'StudySharePrivilege'
	| 'SubscriptionChannelType'
	| 'SubscriptionStatus'
	// T
	| 'TaskCode'
	| 'TaskIntent'
	| 'TaskStatus'
	| 'Technique'
	| 'TemplateBookmark'
	| 'TimeZone'
	| 'TimeZoneType'
	| 'TransferSyntax'
	| 'Transport'
	// U
	| 'UcumVitalsCommon'
	| 'UDIEntryType'
	| 'UsCoreObservationCcdasmokingstatus'
	// V
	| 'ValueSetStatus'
	| 'VerificationStatus'
	| 'View'
	| 'VRMacro'
	| 'VRMacro-fr-FR'
	// W
	| 'WorkflowStatus';

//

interface IValueSetStoreCore {
	/**
	 * Whether the valueSets are currently being loaded/fetched.
	 * Can be used to show a loading indicator when entering edit mode of a form.
	 *
	 * @default false
	 */
	isLoading: boolean;
	/**
	 * All currently fetched valueSets, globally accessible
	 *
	 * @default {}
	 */
	valueSets: TValueSet;
}

interface IValueSetStoreExtra extends IValueSetStoreCore {
	resetValueSets: () => void;
	setIsLoading: (isLoading: boolean) => void;
	setValueSets: (valueSet: TValueSet) => void;
}

interface IValueSetStoreHook extends IValueSetStoreCore {
	/**
	 * Fetches the valueSets based on provided names and stores them in the valueSet store
	 * @param {array | object} valueSetList
	 * @param {boolean} batchFetch whether to fetch the valueSets in batch (together) or 1 by 1
	 * @returns void
	 */
	fetchValueSets: (valueSetList: Object | TValueSetNames[], batchFetch?: boolean) => Promise<void>;

	fetchAccidentValueSets: () => Promise<void>;

	fetchPayerValueSets: () => Promise<void>;

	fetchInsuranceValueSets: () => Promise<void>;

	fetchCoverageValueSets: () => Promise<void>;

	fetchOrderAppropriatenessValueSets: () => Promise<void>;
	fetchOrderValueSets: () => Promise<void>;

	fetchPatientDrawerValueSets: () => Promise<void>;
	fetchPatientInformationPanelValueSets: () => Promise<void>;
	fetchPatientValueSets: () => Promise<void>;
	fetchPatientV2ValueSets: () => Promise<void>;

	fetchRelatedPersonValueSets: () => Promise<void>;

	fetchStudyValueSets: () => Promise<void>;

	fetchVisitInfoValueSets: () => Promise<void>;
	fetchVisitValueSets: () => Promise<void>;
	fetchDataForBMT: () => Promise<void>;
	fetchPhysicianSpecialty: () => Promise<void>;
	//added this as it was missing from previous implementation
	fetchDataForTM: () => Promise<void>;
}

/**
 * Simple, global store for storing all valueSets in 1 place
 *
 * Don't export or use this directly, use the wrapper hook `useValueSets` below !
 */
const useValueSetStore: () => IValueSetStoreExtra = create(
	(set, get) =>
		({
			// ==================== Variables (w/ defaults) ====================
			isLoading: false,
			valueSets: {},

			// ==================== Setters ====================
			setIsLoading: (isLoading: boolean) => set({ isLoading }),

			// By default Zustands replaces a state with new one, therefore sprading is needed to prevents this as we want to merge the previous state w/ new one
			setValueSets: (valueSets: TValueSet) => set({ valueSets: { ...get().valueSets, ...valueSets } }),

			// ==================== Re-setters ====================
			resetValueSets: () => set({ valueSets: {} }),
		}: IValueSetStoreExtra)
);

/**
 * Universal hook for managing `valueSets` built on top of a Zustand store
 *
 * @example
 * // 1. import
 * import { useValueSets } from '@rs-ui/stores/valueSetStore';
 *
 * // 2. declare
 * const { isLoading, valueSets, fetchValueSets } = useValueSets();
 *
 * // 3. use
 * valueSets.MaritalStatus.map( ... )
 */
export const useValueSets = (): IValueSetStoreHook => {
	// ==================== Store ====================
	const { isLoading, valueSets, setIsLoading, setValueSets } = useValueSetStore();

	// ==================== Hooks ====================
	const valueSetloader = useFhirDataLoader({ scope: searchScopes.valueSet });

	// ==================== Events ====================
	/**
	 * Fetches valueSets based on provided valueSet names and parses it into a object w/ keys and values
	 * @param {object | string[]} valueSetList a collection of valueSets to fetch
	 * @returns Object of valueSets where key is a valueSet name and value is array of values
	 */
	const fetchValueSets = async (
		valueSetList: Object | TValueSetNames[],
		batchFetch?: boolean = false
	): Promise<void> => {
		const newValueSets: TValueSet = {};
		let valueSetNames: TValueSetNames[] = [];

		setIsLoading(true);

		// The collection of valueSet names could be an array...
		if (_isArray(valueSetList)) {
			valueSetNames = [...valueSetList];
		}

		// ...or an object with different keys & values
		if (_isPlainObject(valueSetList)) {
			// $FlowIgnore
			valueSetNames = Object.keys(valueSetList).map(key => valueSetList[key]);
		}

		// Exclude already fetched valueSets, terminate the fetch if result is empty
		valueSetNames = _difference(valueSetNames, Object.keys(valueSets));
		if (!valueSetNames.length) {
			setIsLoading(false);
			return valueSets;
		}

		// Fetches all provided valueSets together in 1 requests
		if (batchFetch) {
			const result = await valueSetloader?.load(
				{
					value: [{ label: 'name', values: valueSetNames }],
					count: 1000, // temporary implementation - will be removed once valueSets are loaded as meta-search
				},
				true // return raw data - needed for parsing below
			);

			// Parses the result into { `valueSetName`: values[], ... }
			result?.entry?.forEach(i => {
				newValueSets[i.resource.name] = i.resource?.compose?.include?.[0]?.concept || [];
			});
		}

		// Takes a list of value set names, fetches each one by one and returns an object: `{ valueSetName: valueSetValues[] }`
		else {
			await Promise.all(
				valueSetNames.map(name =>
					valueSetloader
						?.load({
							value: [{ label: 'name', values: name }],
							count: 1000, // temporary implementation - will be removed once valueSets are loaded as meta-search
						})
						.then(res => {
							newValueSets[name] = res;
						})
				)
			);
		}

		setValueSets(newValueSets);
		setIsLoading(false);
		return newValueSets;
	};

	/** Fetch only valueSets for `PatientNotes` / `Accident` component */
	const fetchAccidentValueSets = () => fetchValueSets(['State']);

	/** Fetch valueSets for `InsurancePayerDetailForm` component */
	const fetchPayerValueSets = () => fetchValueSets(['PayerType']);

	/** Fetch only valueSets for `InsuranceInformation` page */
	const fetchInsuranceValueSets = () =>
		fetchValueSets(['EmploymentStatus', 'FinancialClass', 'BeneficiaryRelationship']);

	/** Fetch only valueSets for `CoverageInformation` page */
	const fetchCoverageValueSets = () => fetchValueSets(['BeneficiaryRelationship']);

	/** Fetch only valueSets for `OrderAppropriateness` component */
	const fetchOrderAppropriatenessValueSets = () => fetchValueSets(['Appropriateness']);
	/** Fetch only valueSets for `OrderInfo` page */
	const fetchOrderValueSets = () =>
		fetchValueSets(['Appropriateness', 'AppointmentCancellationReason', 'Priority', 'RequestStatus']);

	/** Fetch only valueSets for `PatientDrawer` component */
	const fetchPatientDrawerValueSets = () =>
		fetchValueSets(['BeneficiaryRelationship', 'EmploymentStatus', 'Relationship', 'Sex', 'SpecialCourtesy']);

	/** Fetches only valueSets for `InformationPanelEditing` component */
	const fetchPatientInformationPanelValueSets = () => fetchValueSets(['SpecialCourtesy'], true);

	/** Fetch only valueSets for `PatientInfo` page */
	const fetchPatientValueSets = () =>
		fetchValueSets([
			'Confidentiality',
			'EmploymentStatus',
			'Ethnicity',
			'Language',
			'LinkType',
			'MaritalStatus',
			'Race',
			'Relationship',
			'Sex',
			'SpecialArrangement',
			'SpecialCourtesy',
			'State',
		]);

	const fetchPatientV2ValueSets = () =>
		fetchValueSets(['Confidentiality', 'Ethnicity', 'Language', 'MaritalStatus', 'Race', 'Sex']);

	/** Fetch only valueSets for `RelatedPerson` model */
	const fetchRelatedPersonValueSets = () => fetchValueSets(['Language', 'Sex']);

	/** Fetch only valueSets for `StudyInfoV2` page */
	const fetchStudyValueSets = () =>
		fetchValueSets([
			'BodyPart',
			'EncounterClass',
			'Laterality',
			'LoincCode',
			'Modality',
			'ModalityModifier',
			'Pharmaceutical',
			'Technique',
			'View',
			'AnatomicFocus',
		]);

	/** Fetch only valueSets for `VisitInformation` page */
	const fetchVisitInfoValueSets = () =>
		fetchValueSets([
			'AccidentType',
			'EncounterClass',
			'EncounterState',
			'FinancialClass',
			'SpecialArrangement',
			'SpecialCourtesy',
		]);

	/** Fetch only valueSets for `OrderInformation` page */
	const fetchVisitValueSets = () => fetchValueSets(['EncounterClass', 'SpecialCourtesy', 'EncounterState']);

	/** Fetch only StudyPlayerTypes - note certain types should be filtered */
	const fetchStudyPlayerTypes = () => fetchValueSets(['StudyPlayerType']);

	/** Fetch only Physician Specialty */
	const fetchPhysicianSpecialty = () => fetchValueSets(['PhysicianSpecialty']);

	const fetchDataForBMT = () => fetchValueSets(['Laterality', 'Bodypart', 'Status']);

	const fetchDataForTM = () => fetchValueSets(['Laterality', 'Bodypart', 'Status', 'Sex', 'Modality']);

	return {
		isLoading,
		valueSets,
		fetchValueSets,

		fetchAccidentValueSets,

		fetchPayerValueSets,

		fetchInsuranceValueSets,

		fetchCoverageValueSets,

		fetchOrderAppropriatenessValueSets,
		fetchOrderValueSets,

		fetchPatientDrawerValueSets,
		fetchPatientInformationPanelValueSets,
		fetchPatientValueSets,
		fetchPatientV2ValueSets,

		fetchRelatedPersonValueSets,

		fetchStudyValueSets,

		fetchVisitInfoValueSets,
		fetchVisitValueSets,

		fetchStudyPlayerTypes,
		fetchDataForBMT,
		fetchPhysicianSpecialty,
		fetchDataForTM,
	};
};
