import React from 'react';

import styles from './CheckListBlock.module.scss';

const CheckListBlock = ({ theme, block, setDisplayMode, isSelected }) => (
	<div
		className={`${styles.checkListBlock} ${isSelected ? styles.checkListBlockSelected : ''}`}
		data-testid="checkListBlock"
		onMouseOver={() => setDisplayMode && setDisplayMode({ type: 'hover' })}
	>
		{block.checkList.map(item => (
			<label key={item.id} className={styles.item}>
				<input
					readOnly
					checked={item.checked}
					className={`${styles.itemCheckbox} ${
						theme === 'OAI' ? styles.itemCheckboxOAI : styles.itemCheckboxBlume
					}`}
					data-testid="checkListBlockCheckbox"
					type="checkbox"
				/>
				<span
					className={`${styles.itemText} ${theme === 'OAI' ? styles.textOAI : styles.textBlume}`}
					data-testid="checkListBlockText"
				>
					{item.value}
				</span>
			</label>
		))}
	</div>
);

export default CheckListBlock;
