import React, { useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import _ from 'lodash';
import OverflowTooltip from '@worklist-2/ui/src/components/OverflowTooltip/OverflowTooltip';
import convertStringToLocalDatetime from '@worklist-2/ui/src/views/utils/convertStringToLocalDatetime';

function ReportDataGrid({ tableColumns, dataSource, columnOrder }) {
	const [selectedRowId, setSelectedRowId] = useState(-1);

	const onSelectColumn = function (row, event) {
		if (row.id != selectedRowId) {
			setSelectedRowId(row.id);
			// add Selected row
			event.currentTarget.className = 'MuiDataGrid-row Mui-selected';
		} else {
			setSelectedRowId(-1);
			// Deselect row
			event.currentTarget.className = 'MuiDataGrid-row';
		}
	};

	const columns = useMemo(
		() =>
			_.map(columnOrder, column => ({
				field: column.key,
				headerName: tableColumns.find(e => e.key == column.key)?.shortTitle,
				headerAlign: 'left',
				flex: 1,
				renderCell: cell => (
					// convertStringToLocalDatetime method will check and convert if the value is Date String
					<OverflowTooltip text={cell.value ? convertStringToLocalDatetime(cell.value) : ''} />
				),
			})),
		[tableColumns, columnOrder]
	);

	const rows = useMemo(
		() =>
			_.map(dataSource, (row, index) => ({
				id: index,
				...row,
			})),
		[dataSource]
	);

	return (
		<DataGrid
			disableColumnMenu
			hideFooter
			hideFooterPagination
			columns={columns}
			getRowHeight={() => 40}
			rows={rows}
			sx={{
				border: 'none',
				'& .MuiDataGrid-columnSeparator': {
					display: 'none',
				},
				'& .MuiDataGrid-row:nth-child(odd)': {
					backgroundColor: selectedRowId == -1 ? 'rgba(140, 220, 254, 0.05)' : 'none',
				},
				'& .MuiDataGrid-row:hover': {
					backgroundColor: 'rgba(140, 220, 254, 0.3)',
					borderLeft: '5px solid rgba(66, 165, 245, 1)',
					color: '#000000',
					paddingLeft: 0,
				},
				'& .MuiDataGrid-row.Mui-selected': {
					backgroundColor: 'rgba(140, 220, 254, 0.3)',
					borderLeft: '5px solid rgba(66, 165, 245, 1)',
					color: '#000000',
					paddingLeft: 0,
				},
				'& .MuiDataGrid-row.Mui-selected:hover': {
					backgroundColor: 'rgba(140, 220, 254, 0.3)',
					borderLeft: '5px solid #42A5F5',
				},
				'& .MuiDataGrid-cell:focus': {
					outline: 'none',
				},
				'& .MuiDataGrid-cell:focus-within': {
					outline: 'none',
				},
				'& .MuiDataGrid-row': {
					color: selectedRowId == -1 ? '#000' : 'rgba(0, 0, 0, 0.6)',
					paddingLeft: '5px',
				},
				'& .MuiDataGrid-columnHeaderTitle': {
					color: 'rgba(0, 0, 0, 0.6)',
				},
			}}
			onRowClick={(row, event) => onSelectColumn(row, event)}
		/>
	);
}

export default ReportDataGrid;
