import axios from 'axios';

const bookSelfSchedulingAppointment = async ({ __config, data }) => {
	const response = await axios.post(`${__config.data_sources.blume}Appointment/createPatientAndSchedule`, data, {
		headers: {
			'X-API-KEY': window.btoa(__config.patient_portal.authentication_key),
		},
	});

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default bookSelfSchedulingAppointment;
