// core
import React from 'react';

// libraries
import { Controller } from 'react-hook-form';

// components
import FormFieldDefault from './FormFieldDefault';

// types
import FormFieldVariantType from '../../../formTypes/FormFieldVariantType';
import handleSSNChange from './handleSSNChange';

const FormTextFieldVariant = ({ formHook, name, type, props }) => {
	const { control } = formHook;

	return (
		<Controller
			control={control}
			name={name}
			render={({ field }) => (
				<FormFieldDefault
					formHook={formHook}
					name={name}
					props={{
						...field,
						...props,
						onChange: value => {
							if (props?.beforeOnChange) {
								props.beforeOnChange();
							}

							name === 'ssn' ? handleSSNChange(value, field.onChange) : field.onChange(value);
						},
					}}
					type={type}
				/>
			)}
		/>
	);
};

FormTextFieldVariant.propTypes = FormFieldVariantType();

export default FormTextFieldVariant;
