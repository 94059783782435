import getActiveTextStyle from './getActiveTextStyle';

const getActiveBackgroundColor = editor => {
	const attribute = 'backgroundColor';
	const defaultValue = '#ffffff';

	return getActiveTextStyle({ editor, attribute, defaultValue });
};

export default getActiveBackgroundColor;
