import React, { useEffect, useState } from 'react';
import DescriptionBar from './DescriptionBar';
import DevelopmentContainer from './DevelopmentContainer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

function OKRDevelopmentContainer() {
	const [states, setStates] = useState({});

	const getState = state => {
		setStates({ ...states, ...state });
	};

	return (
		<DevelopmentContainer>
			<DndProvider backend={HTML5Backend}>
				<DescriptionBar objectState={states} setObjectState={state => getState(state)} />
			</DndProvider>
		</DevelopmentContainer>
	);
}

export default OKRDevelopmentContainer;
