import React, { useState } from 'react';
import Box from '@mui/material/Box';
import MobileTitle from '../../MobileTitle';
import Grid from '@mui/material/Grid';
import MobileLabelText from '../../MobileLabelText';
import { Icon } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import MobilePhysicianInformationList from '../MobilePhysicianInformationList';
import MobileBottomDrawer from '../../MobileDrawer/MobileBottomDrawer';
import { useIsMobile } from '@worklist-2/core/src';
import { useTranslation } from 'react-i18next';

const MobilePhysicianInformationSection = ({ study }) => {
	const [isShownPhysicianInformationDrawer, setIsShownPhysicianInformationDrawer] = useState(false);

	const isMobile = useIsMobile();
	const { t } = useTranslation('studyDetail');

	return (
		<Box>
			<MobileTitle title="Physician Information" />

			<Grid container>
				<Grid item xs={isMobile ? 12 : 4}>
					<MobileLabelText label={t('Referring Physician')} text={study.referringPhysician} />
				</Grid>

				<Grid item xs={isMobile ? 12 : 4}>
					<MobileLabelText
						label={t('Imaging Organization')}
						text={
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								{study.imagingOrganization ? (
									<>
										{study.imagingOrganization?.phone && (
											<Icon
												component={PhoneIcon}
												sx={{
													marginRight: '10px',
													fontSize: '16px',
												}}
												onClick={() =>
													study.imagingOrganization &&
													setIsShownPhysicianInformationDrawer(true)
												}
											/>
										)}

										{study.imagingOrganization.name}
									</>
								) : (
									'-'
								)}
							</Box>
						}
					/>
				</Grid>
			</Grid>

			<MobileBottomDrawer
				background="#ffffff"
				open={isShownPhysicianInformationDrawer}
				title="Imaging Organization"
				onClose={() => setIsShownPhysicianInformationDrawer(false)}
			>
				<MobilePhysicianInformationList imagingOrganizationPhone={study.imagingOrganization?.phone} />
			</MobileBottomDrawer>
		</Box>
	);
};

export default MobilePhysicianInformationSection;
