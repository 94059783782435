const modalities = [
	'AR',
	'BD',
	'BI',
	'CADSR',
	'CR',
	'DG',
	'ECG',
	'EPS',
	'HD',
	'IO',
	'IOL',
	'KER',
	'KO',
	'LEN',
	'LR',
	'LS',
	'MG',
	'MR',
	'OAM',
	'OPR',
	'PR',
	'CT',
	'PT',
	'REG',
	'RT',
	'RTDOSE',
	'RTPLAN',
	'RTRECORD',
	'SC',
	'SR',
	'SEG',
	'SM',
	'SMR',
	'SPL',
	'SRF',
	'ST',
	'TG',
	'UR',
	'VA',
	'XC',
	'XR',
	'DOC',
	'NM',
	'US',
	'RF',
	'XA',
	'PT/CT',
	'XR/RF',
	'BDUS',
	'BMD',
	'DX',
	'EEG',
	'EMG',
	'EOG',
	'ES',
	'GM',
	'IVOCT',
	'IVUS',
	'OCT',
	'OP',
	'OT',
	'OPM',
	'OPT',
	'OPTBSV',
	'OPTENF',
	'OPV',
	'OSS',
	'POS',
	'PX',
	'RESP',
	'RG',
	'RTIMAGE',
	'ASMT',
	'AU',
	'CTPROTOCOL',
	'FID',
	'HC',
	'M3D',
	'PLAN',
	'RTSTRUCT',
	'RWV',
	'STAIN',
	'TEXTUREMAP',
];

export default modalities;
