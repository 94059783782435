import React, { useEffect, useState, useCallback, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import FullScreenIcon from '../FullScreenIcon';
import map from 'lodash/map';
import fill from 'lodash/fill';
import { useTranslation } from 'react-i18next';
import Toolbar from './Toolbar';

const PdfViewer = ({
	fileUrl,
	headers,
	isLoading,
	showFullScreenIcon,
	sx,
	workerSrc,
	fillBox = false,
	blumeAttachments = false,
	canvasHeight,
	showToolbar = false,
	defaultZoomLevel = 1,
	handleOnDelete,
}) => {
	const { t } = useTranslation('common');

	const [pageCount, setPageCount] = useState(0);
	const [isLoadingFile, setIsLoadingFile] = useState(true);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const [zoomLevel, setZoomLevel] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [error, setError] = useState('');

	useEffect(() => {
		if (workerSrc) {
			pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
		}
	}, []);

	useEffect(() => {
		setZoomLevel(defaultZoomLevel);
	}, [defaultZoomLevel]);

	const file = useMemo(
		() => ({
			url: fileUrl,
			httpHeaders: headers,
		}),
		[fileUrl, headers]
	);

	const handlePageChange = useCallback(({ pageNumber }) => {
		setCurrentPage(pageNumber);
	}, []);

	const onLoadError = useCallback(() => {
		setError(t('pdfViewer.loadingError'));
		setIsLoadingFile(false);
	}, []);

	const onLoadSuccess = useCallback(({ numPages }) => {
		setPageCount(numPages);
		setIsLoadingFile(false);
	}, []);

	const isLoadingPdf = isLoadingFile || isLoading;

	return (
		<Box
			sx={{
				top: 0,
				left: 0,
				bgcolor: 'rsSecondary.medium',
				zIndex: 999,
				position: 'relative',
				...sx,
				...(isFullscreen
					? {
							position: 'absolute',
							width: 'calc(100% - 60px)',
							margin: '30px',
							display: 'flex',
							flexDirection: 'column',
					  }
					: {}),
			}}
		>
			<Box
				sx={{
					position: 'absolute',
					height: '50px',
					boxSizing: 'border-box',
					paddingTop: '6px',
					visibility: isLoadingPdf || !showFullScreenIcon ? 'hidden' : undefined,
				}}
			>
				<FullScreenIcon isFullscreen={isFullscreen} onToggleFullscreen={setIsFullscreen} />
			</Box>
			<Box
				mb={isFullscreen ? 9 : 0.75}
				mt={fillBox ? 0 : 3.75}
				mx={fillBox ? 0 : 2.25}
				px={fillBox ? 0 : 0.75}
				sx={{
					boxSizing: 'border-box',
					position: 'relative',
					alignSelf: isFullscreen ? 'center' : undefined,
					height:
						canvasHeight ||
						`calc(100vh - ${isFullscreen ? 210 : fillBox ? (blumeAttachments ? 256 : 70) : 256}px)`,
					overflowY: isLoadingPdf ? 'hidden' : 'scroll',
					overflowX: zoomLevel !== defaultZoomLevel ? 'auto' : 'hidden',
					visibility: isLoadingPdf ? 'hidden' : undefined,

					'& > div, .react-pdf__Document': {
						minWidth: 'fit-content',
					},

					'.react-pdf__Page': {
						'&:not(:last-of-type)': {
							marginBottom: '8px',
						},
						'.annotationLayer': {
							display: 'none',
						},
					},
					'& > div, .react-pdf__Page__canvas': {
						borderRadius: fillBox ? '0px' : '10px',
					},
				}}
			>
				<AutoSizer
					style={{
						visibility: isLoadingPdf ? 'hidden' : 'visible',
					}}
				>
					{({ width, height }) => (
						<Document
							error={error ?? null}
							file={file}
							options={{
								cMapUrl: '/cmaps',
								cMapPacked: true,
							}}
							onLoadError={onLoadError}
							onLoadSuccess={onLoadSuccess}
						>
							{map(fill(new Array(pageCount), null), (__, i) => (
								<Page
									key={i}
									height={isFullscreen ? height : undefined}
									pageNumber={i + 1}
									scale={zoomLevel}
									width={isFullscreen ? undefined : width - 4}
									onRenderSuccess={() => handlePageChange({ pageNumber: i + 1 })}
								/>
							))}
						</Document>
					)}
				</AutoSizer>
			</Box>

			{isLoadingPdf && <Skeleton sx={styles.skeleton} variant="rectangular" />}
			<Toolbar
				currentPage={currentPage}
				fileUrl={fileUrl}
				handleOnDelete={handleOnDelete}
				headers={headers}
				loading={isLoadingPdf}
				pageCount={pageCount}
				setZoomLevel={setZoomLevel}
				show={showToolbar}
				zoomLevel={zoomLevel}
			/>
		</Box>
	);
};

PdfViewer.propTypes = {
	/**
	 * URL of the PDF file
	 */
	fileUrl: PropTypes.string,
	/**
	 * Access headers for the PDF file
	 */
	headers: PropTypes.shape({
		Accept: PropTypes.string,
		Authorization: PropTypes.string,
		SessionID: PropTypes.string,
	}),
	/**
	 * Whether the URL is being resolved
	 */
	isLoading: PropTypes.bool,
	/**
	 * Whether to show full screen icon or not
	 */
	showFullScreenIcon: PropTypes.bool,
	sx: PropTypes.object,
	workerSrc: PropTypes.string,
};

export default PdfViewer;

const styles = {
	skeleton: {
		position: 'absolute',
		top: 0,
		left: 0,
		margin: '14px',
		borderRadius: '6px',
		width: 'calc(100% - 28px)',
		height: 'calc(100% - 28px)',
	},
};
