import { vec2, vec3 } from 'gl-matrix';
import { getEnabledElement, triggerEvent, eventTarget } from '@cornerstonejs/core';
import { Enums, BidirectionalTool, utilities, annotation, cursors } from '@cornerstonejs/tools';

const { Events } = Enums;
const { triggerAnnotationRenderForViewportIds } = utilities;
const { removeAnnotation } = annotation.state;
const { resetElementCursor } = cursors.elementCursor;

class BidirectionalToolUpdated extends BidirectionalTool {
	_endCallback = (evt) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const { annotation, viewportIdsToRender, newAnnotation, hasMoved } = this.editData;
		const { data } = annotation;

		if (newAnnotation && !hasMoved) {
			return;
		}

		data.handles.activeHandleIndex = null;

		this._deactivateModify(element);
		this._deactivateDraw(element);

		resetElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		if (this.editData.handleIndex !== undefined) {
			const { points } = data.handles;
			const firstLineSegmentLength = vec3.distance(points[0], points[1]);
			const secondLineSegmentLength = vec3.distance(points[2], points[3]);

			if (secondLineSegmentLength > firstLineSegmentLength) {
				// Switch points so [0,1] is the long axis and [2,3] is the short axis.

				const longAxis = [[...points[2]], [...points[3]]];

				const shortAxisPoint0 = [...points[0]];
				const shortAxisPoint1 = [...points[1]];

				// shortAxis[0->1] should be perpendicular (counter-clockwise) to longAxis[0->1]
				const longAxisVector = vec2.create();

				vec2.set(longAxisVector, longAxis[1][0] - longAxis[0][0], longAxis[1][1] - longAxis[1][0]);

				const counterClockWisePerpendicularToLongAxis = vec2.create();

				vec2.set(counterClockWisePerpendicularToLongAxis, -longAxisVector[1], longAxisVector[0]);

				const currentShortAxisVector = vec2.create();

				vec2.set(
					currentShortAxisVector,
					shortAxisPoint1[0] - shortAxisPoint0[0],
					shortAxisPoint1[1] - shortAxisPoint0[0],
				);

				let shortAxis;

				if (vec2.dot(currentShortAxisVector, counterClockWisePerpendicularToLongAxis) > 0) {
					shortAxis = [shortAxisPoint0, shortAxisPoint1];
				} else {
					shortAxis = [shortAxisPoint1, shortAxisPoint0];
				}

				data.handles.points = [longAxis[0], longAxis[1], shortAxis[0], shortAxis[1]];
			}
		}

		if (this.isHandleOutsideImage && this.configuration.preventHandleOutsideImage) {
			removeAnnotation(annotation.annotationUID);
		}

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		const eventType = Events.ANNOTATION_COMPLETED;

		triggerEvent(eventTarget, eventType, { annotation });

		this.editData = null;
		this.isDrawing = false;
	};
}

export default BidirectionalToolUpdated;
