import {
	addTool,
	MagnifyTool,
	MIPJumpToClickTool,
	StackScrollTool,
	VolumeRotateMouseWheelTool,
	DragProbeTool,
} from '@cornerstonejs/tools';
import CrosshairPointerTool from './CrosshairPointerTool';
import ShutterRectangleTool from './ShutterTools/ShutterRectangleTool';
import ShutterCircleTool from './ShutterTools/ShutterCircleTool';
import ShutterFreehandTool from './ShutterTools/ShutterFreehandTool';
import LengthTool from './LengthTool';
import AngleTool from './AngleTool';
import ZoomTool from './ZoomTool';
import PanTool from './PanTool';
import ArrowAnnotationToolModyfied from './ArrowAnnotationTool/ArrowAnnotationToolModyfied';
import ProbeTool from './ProbeTool';
import RectangleROITool from './RectangleROITool';
import EllipticalROITool from './EllipticalROITool';
import BidirectionalTool from './BidirectionalTool';
import StackScrollMouseWheelTool from './StackScrollToolMouseWheelTool';
import QuadZoomTool from './QuadZoomTool';
import CADtool from './CADtool';
import CobbAngleTool from './CobbAngleTool';
import CardiothoracicTool from './CardiothoracicTool';
import PlumbLineTool from './PlumbLineTool';
import PlanarFreehandROITool from './PlanarFreehandROITool';
import WindowLevelTool from './WindowLevelTool';
import SpineLabelingTool from './SpineLabelingTool';
import ReferenceLinesTool from './ReferenceLinesTool';
import ScaleOverlayTool from './ScaleOverlayTool';
import PlanarRotateTool from './PlanarRotateTool';
import CrosshairsTool from './CrosshairsTool';
import CalibrationTool from './CalibrationTool';

const addCornerstoneTools = () => {
	CrosshairsTool.isAnnotation = false;
	ReferenceLinesTool.isAnnotation = false;

	addTool(QuadZoomTool);
	addTool(CADtool);
	addTool(StackScrollMouseWheelTool);
	addTool(ReferenceLinesTool);
	addTool(PanTool);
	addTool(WindowLevelTool);
	addTool(StackScrollTool);
	addTool(ZoomTool);
	addTool(MagnifyTool);
	addTool(CrosshairPointerTool);
	addTool(ShutterRectangleTool);
	addTool(ShutterCircleTool);
	addTool(ShutterFreehandTool);
	addTool(CrosshairsTool);
	addTool(ArrowAnnotationToolModyfied);
	addTool(AngleTool);
	addTool(ProbeTool);
	addTool(DragProbeTool);
	addTool(RectangleROITool);
	addTool(LengthTool);
	addTool(BidirectionalTool);
	addTool(EllipticalROITool);
	addTool(PlanarFreehandROITool);
	addTool(MIPJumpToClickTool);
	addTool(VolumeRotateMouseWheelTool);
	addTool(CobbAngleTool);
	addTool(CardiothoracicTool);
	addTool(PlumbLineTool);
	addTool(PlanarRotateTool);
	addTool(SpineLabelingTool);
	addTool(ScaleOverlayTool);
	addTool(CalibrationTool);
};

export default addCornerstoneTools;
