import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { Header, Title } from '../../../views/CrmView/styles';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import OKRDevelopmentContainer from './component/OKRDevelopmentContainer';
import { useCrmContext } from '../../../context/CRMContext';
import { Select, MenuItem } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { DefaultDepartment, CompanyDepartment } from '../Cases/utils';
import QuarterPicker from './component/QuarterPicker/QuarterPicker';
import _ from 'lodash';

function Department() {
	const { setQuarterGB } = useCrmContext();
	const params = useParams();
	const navigate = useNavigate();
	const targetDeparment =
		_.find(DefaultDepartment, item => item.Department === params.department) || CompanyDepartment;

	return (
		<Box
			sx={{
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
				overflowY: 'hidden',
			}}
		>
			<Header>
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Title>
							<CorporateFareIcon
								sx={{
									fontSize: 29,
									mr: '12px',
								}}
							/>
							<span>{targetDeparment.DepartmentAlias}</span>
						</Title>
						<Box
							sx={{
								display: 'flex',
								marginLeft: '50px',
								alignItems: 'center',
								fontSize: '16px',
								color: '#FFFFFF',
								background: '#4D79EA',
								borderRadius: '10px',
								justifyContent: 'space-between',
								paddingRight: '10px',
							}}
						>
							<HomeOutlinedIcon
								sx={{
									fontSize: 29,
									mr: '12px',
									color: '#4D79EA',
									background: '#FFFFFF',
									borderRadius: '10px',
									padding: '5px 7px',
									cursor: 'pointer',
								}}
								onClick={() => navigate('/')}
							/>
							<span>{targetDeparment.DepartmentAlias}</span>
						</Box>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							marginRight: '80px',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								color: 'rgba(0, 0, 0, 0.6)',
								fontSize: '14px',
								fontWeight: '600',
							}}
						>
							<HistoryToggleOffIcon sx={{ fontSize: 24, mr: '5px', color: '#4D79EA' }} />
							<QuarterPicker
								deparment={params.department}
								quarterSelect={params.quarter}
								setQuarterGB={setQuarterGB}
							/>
						</Box>
					</Box>
				</Box>
			</Header>
			<OKRDevelopmentContainer />
		</Box>
	);
}

export default Department;
