import React from 'react';

const TreeEditingFolderIcon = () => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<mask height="24" id="mask0_1225_5990" maskUnits="userSpaceOnUse" width="24" x="0" y="0">
			<rect fill="#D9D9D9" height="24" width="24" />
		</mask>
		<g mask="url(#mask0_1225_5990)">
			<path
				d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H10L12 6H20C20.55 6 21.0208 6.19583 21.4125 6.5875C21.8042 6.97917 22 7.45 22 8V11.3C21.6833 11.1667 21.3542 11.0792 21.0125 11.0375C20.6708 10.9958 20.3333 11 20 11.05V8H11.175L9.175 6H4V18H12.1L12 18.1V20H4ZM14 22V18.925L19.525 13.425C19.675 13.275 19.8417 13.1667 20.025 13.1C20.2083 13.0333 20.3917 13 20.575 13C20.775 13 20.9667 13.0375 21.15 13.1125C21.3333 13.1875 21.5 13.3 21.65 13.45L22.575 14.375C22.7083 14.525 22.8125 14.6917 22.8875 14.875C22.9625 15.0583 23 15.2417 23 15.425C23 15.6083 22.9667 15.7958 22.9 15.9875C22.8333 16.1792 22.725 16.35 22.575 16.5L17.075 22H14ZM15.5 20.5H16.45L19.475 17.45L19.025 16.975L18.55 16.525L15.5 19.55V20.5ZM19.025 16.975L18.55 16.525L19.475 17.45L19.025 16.975Z"
				fill="white"
				fillOpacity="1"
			/>
		</g>
	</svg>
);

export default TreeEditingFolderIcon;
