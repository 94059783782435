import { FhirDomainResource } from '../base';
import invoiceSchema from './schemata/invoiceSchema';

export default class InvoiceResource extends FhirDomainResource {
	id;
	resourceType;
	identifier = [];
	status;
	cancelledReason;
	type;
	subject;
	recipient;
	date;
	participant = [];
	issuer;
	account;
	lineItem = [];
	totalPriceComponent = [];
	totalNet;
	totalGross;
	paymentTerms;
	note;

	constructor(resourceString) {
		super(resourceString, invoiceSchema);
		this.resourceType = 'Invoice';
		this.populateFields();
	}
}
