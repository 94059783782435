import React from 'react';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeIcon from '@mui/icons-material/Home';

import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';

import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import WorkIcon from '@mui/icons-material/Work';

import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import ViewListIcon from '@mui/icons-material/ViewList';

import LoginBreezeView from './LoginBreezeView';

import { BreezeNewReports, BreezeReportBlankPage } from '../../routes/BreezeNewReports';
import BreezeReports from '../../routes/BreezeReports';
import BreezeReportDetails from '../../routes/BreezeReportDetails';
import BreezeReportEdit from '../../routes/BreezeReportEdit';
import NotificationsDetails from '../../routes/NotificationsDetails';
import GlobalSearchResults from '../../routes/GlobalSearchResults';

import CRMAccounts from '../../components/CRM/Accounts';
import AdminSettings from '../../components/CRM/AdminSettings';
import Department from '../../components/CRM/OKR/Department';

import Cases from '../../components/CRM/Cases/Cases';
import OKR from '../../components/CRM/OKR';
import AccountFullscreen from '../../components/CRM/Accounts/AccountDetailPopOver/AccountFullscreen';
import DetailsFullscreen from '../../components/CRM/Cases/CaseDetailPopOver/DetailsFullscreen';
import CasesKanban from '../../components/CRM/Cases/Kanban/CasesKanban';
import Documentation from '../../components/CRM/Documentation';

import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

export const crmNavLinks = [
	{
		text: 'CRM',
		path: '',
		element: <OKR />,
		iconComponent: <HomeOutlinedIcon />,
		activeIconComponent: <HomeIcon />,
	},
	{
		text: 'Development',
		path: '/okr/:department/:quarter',
		element: <Department />,
	},
	{
		text: 'CRMLogin',
		path: 'login',
		element: <LoginBreezeView />,
	},
	{
		text: 'Cases',
		path: 'case',
		element: <Cases />,
		iconComponent: <WorkOutlineOutlinedIcon />,
		activeIconComponent: <WorkIcon />,
		subNavLink: [
			{
				text: 'List View',
				path: 'case/list',
				iconComponent: <ViewDayIcon />,
				element: <Cases />,
				navLink: true,
			},
			{
				text: 'Kanban',
				path: 'case/kanban',
				iconComponent: <DnsOutlinedIcon />,
				element: <CasesKanban />,
				navLink: true,
			},
			{
				text: 'Cases Detail',
				path: 'case/:id',
				element: <DetailsFullscreen />,
			},
		],
	},
	{
		text: 'Accounts',
		path: 'account',
		element: <CRMAccounts />,
		iconComponent: <ViewListOutlinedIcon />,
		activeIconComponent: <ViewListIcon />,
		subNavLink: [
			{
				text: 'Account Detail',
				path: 'account/:id',
				element: <AccountFullscreen />,
			},
		],
	},
	// {
	// 	text: "",
	// 	path: "",
	// 	element: null,
	// 	iconComponent: <LightbulbOutlinedIcon />,
	// 	activeIconComponent: <LightbulbIcon />,
	// },
	{
		text: 'Statistics',
		path: 'stats',
		element: <BreezeReports />,
		iconComponent: <InsertChartOutlinedIcon />,
		activeIconComponent: <InsertChartIcon />,
		subNavLink: [
			{
				text: 'Report',
				path: 'stats/report',
				element: <BreezeNewReports />,
				iconComponent: null,
				activeIconComponent: null,
			},
			{
				text: 'Report',
				path: 'stats/report/:id/*',
				element: <BreezeReportDetails />,
				iconComponent: null,
				activeIconComponent: null,
			},
			{
				text: 'NewBlank',
				path: 'stats/report/new',
				element: <BreezeReportBlankPage />,
				iconComponent: null,
				activeIconComponent: null,
			},
			{
				text: 'NewBlank',
				path: 'stats/report/edit',
				element: <BreezeReportEdit />,
				iconComponent: null,
				activeIconComponent: null,
			},
		],
	},
	{
		text: 'Documentation',
		path: 'documentation',
		element: <Documentation />,
		iconComponent: <AssignmentOutlinedIcon />,
		activeIconComponent: <AssignmentIcon />,
	},
	{
		text: 'Admin Settings',
		path: 'adminSettings',
		element: <AdminSettings />,
		iconComponent: <ManageAccountsOutlinedIcon />,
		activeIconComponent: <ManageAccountsIcon />,
	},
	{
		text: 'GlobalSearchResults',
		path: 'globalSearchResults',
		element: <GlobalSearchResults />,
		iconComponent: null,
		activeIconComponent: null,
	},
	{
		text: 'NotificationsDetails',
		path: 'notificationsDetails',
		element: <NotificationsDetails />,
		iconComponent: null,
		activeIconComponent: null,
	},
];
