import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import ShowMoreButton from '../ShowMoreButton';

// Checks to see if element is overflowing
const isOverflown = elemId => {
	const element = document.getElementById(elemId);
	if (element) {
		return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
	}
	return false;
};

const ExpandableDescription = ({ id, descriptionText, numberOfLines, height, opacity, showMoreText, showLessText }) => {
	const [expanded, setExpanded] = useState(false);
	const [hideShowMoreButton, setHideShowMoreButton] = useState(true);
	const webkitLineClampLines = expanded ? 'none' : numberOfLines;
	const descriptionElementId = `description-text-${id}`;

	useEffect(() => {
		// This check can only be done if the expanded state is false, otherwise
		// the elements will be full height and there will be no overflow
		if (!isOverflown(descriptionElementId)) {
			// if not overflowing, hide the show more button
			setHideShowMoreButton(true);
		} else {
			// if overflowing, show the show more button
			setHideShowMoreButton(false);
		}
	}, [descriptionText]);

	const onShowMoreClick = () => {
		setExpanded(exp => !exp);
	};

	return (
		<>
			<Typography
				data-testid="app-description"
				id={descriptionElementId}
				sx={{
					color: 'text.primary',
					display: '-webkit-box',
					fontSize: '14px',
					lineHeight: '20px',
					opacity,
					overflow: 'hidden',
					WebkitBoxOrient: 'vertical',
					WebkitLineClamp: webkitLineClampLines, // Limit the number of visible lines
					minHeight: height,
					// These do the same thing, but both should be kept
					overflowWrap: 'break-word',
					wordWrap: 'break-word',
				}}
				variant="body1"
			>
				{descriptionText}
			</Typography>
			{!hideShowMoreButton && (
				<ShowMoreButton btnText={expanded ? showLessText : showMoreText} onShowMoreClick={onShowMoreClick} />
			)}
		</>
	);
};

ExpandableDescription.propTypes = {
	/**
	 * Id of item being described
	 */
	id: PropTypes.string.isRequired,
	/**
	 * Description text
	 */
	descriptionText: PropTypes.string.isRequired,
	/**
	 * Number of lines that should be visible in the details section
	 */
	numberOfLines: PropTypes.number,
	/**
	 * Custom height
	 */
	height: PropTypes.string,
	/**
	 * Text opacity
	 */
	opacity: PropTypes.string,
	/**
	 * Text for Show More button
	 */
	showMoreText: PropTypes.string,
	/**
	 * Text for Show Less button
	 */
	showLessText: PropTypes.string,
};

ExpandableDescription.defaultProps = {
	height: '57px',
	numberOfLines: 2,
	opacity: '1',
	showMoreText: 'SHOW MORE',
	showLessText: 'SHOW LESS',
};

export default ExpandableDescription;
