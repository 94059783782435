import React, { useState, useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import DeleteProgress from '../../../../DeleteProgress';
import FormFieldVariants from '../FormFieldVariants';

const expandButtonWidth = '24px';

const ExpandableRow = ({
	formHook,
	isEditing,
	onClick,
	uniqueKey,
	index,
	children,
	isExpandable,
	getGridMapping,
	expandableRowColSpan,
	expandableRowFields,
	expandableRowCustomContent,
	sx,
}) => {
	const [open, setOpen] = useState(false);

	const onRowClick = e => {
		onClick(e);
		if (!isEditing) {
			setOpen(v => !v);
		}
	};

	useEffect(() => {
		if (isEditing) {
			setOpen(true);
		}
	}, [isEditing]);

	const expandedRowContent = expandableRowFields
		? expandableRowFields.map(item => (
				<FormFieldVariants
					key={item.uniqueKey}
					field={{
						...item,
						name: getGridMapping(item, index),
					}}
					formHook={formHook}
					{...item}
					isEditing={isEditing} // this is required
				/>
		  ))
		: expandableRowCustomContent;

	return (
		<React.Fragment key={`expanded-row-frag-${uniqueKey}`}>
			<TableRow
				key="parent-row"
				sx={{
					...sx,
				}}
				onClick={onRowClick}
			>
				{isExpandable ? (
					<TableCell key="expand-button" sx={{ width: expandButtonWidth }}>
						{open ? (
							<KeyboardArrowUpIcon
								aria-label="expand row"
								sx={{
									cursor: 'pointer',
									color: 'icon.primary',

									'&:hover': {
										color: 'rsPrimary.main',
									},
								}}
								onClick={isEditing ? () => setOpen(false) : undefined}
							/>
						) : (
							<KeyboardArrowDownIcon
								aria-label="collapse row"
								sx={{
									cursor: 'pointer',
									color: 'icon.primary',

									'&:hover': {
										color: 'rsPrimary.main',
									},
								}}
								onClick={isEditing ? () => setOpen(true) : undefined}
							/>
						)}
					</TableCell>
				) : null}
				{children}
			</TableRow>
			<TableRow key="expanded-row">
				<TableCell
					key="expanded-row-content"
					colSpan={expandableRowColSpan}
					sx={{ py: '0px', pr: '0px', pl: '45px' }}
				>
					<Collapse in={open}>{expandedRowContent}</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
};

const FormGridVariant = ({
	isEditing,
	onDelete,
	onAdd,
	addNewItemText,
	name,
	formHook,
	fields,
	onRowClick,
	getSelectedRow,
	idMapping,
	expandableRowsFlag,
	expandableRowFields,
	expandableRowCustomContent,
	disableGridHeader,
	maxNumOfAddableRows,
	disableInputPadding,
}) => {
	const getGridMapping = (item, index) => {
		let mapping = `${name}.${index}`;

		if (item.nameFn) {
			mapping += `.${item.nameFn(index, formHook)}`;
		} else if (item.name) {
			mapping += `.${item.name}`;
		}

		return mapping;
	};

	const getIdMapping = index => formHook.getValues(`${name}.${index}.${idMapping}`);

	const rows = formHook.getValues(name) || [];

	useWatch({
		control: formHook.control,
		name,
	});

	return (
		<TableContainer
			sx={{
				border: disableGridHeader ? undefined : '1px solid rgba(255, 255, 255, 0.06)',
				boxSizing: 'border-box',
				borderRadius: disableGridHeader ? '0px' : '6px',
				'& .MuiTableCell-root': {
					borderBottom: 'none',
				},
			}}
		>
			<Table>
				{disableGridHeader ? null : (
					<TableHead>
						<TableRow
							sx={{
								'& .MuiTableCell-root': {
									color: 'rgba(255, 255, 255, 0.6)',
								},
							}}
						>
							{expandableRowsFlag ? (
								<TableCell key="Expand Button" sx={{ width: expandButtonWidth }} />
							) : null}
							{fields
								.filter(item => !item.hidden)
								.map(item => (
									<TableCell
										key={item.header}
										sx={{
											color: '#FFFFFF99',
										}}
									>
										{item.header}
									</TableCell>
								))}

							{isEditing && onDelete && (
								<TableCell
									key="delete"
									sx={{
										color: '#FFFFFF99',
									}}
								/>
							)}
						</TableRow>
					</TableHead>
				)}

				<TableBody>
					{rows.map((row, index) => (
						<ExpandableRow
							key={getIdMapping(index)}
							expandableRowColSpan={1000}
							expandableRowCustomContent={expandableRowCustomContent}
							expandableRowFields={expandableRowFields}
							formHook={formHook}
							getGridMapping={getGridMapping}
							index={index}
							isEditing={isEditing}
							isExpandable={expandableRowsFlag}
							sx={{
								'&:hover': {
									background: 'rgba(255, 255, 255, 0.04)',
								},
								'&': {
									background: getSelectedRow?.({
										row,
										index,
										formHook,
									})
										? 'rgba(255, 255, 255, 0.04)'
										: undefined,
								},
								'&:hover .onDelete': {
									visibility: 'visible',
								},
								'& .MuiTableCell-root': {
									padding: '6px 16px',
								},
								'& .MuiInputBase-input':
									disableGridHeader || disableInputPadding
										? undefined
										: {
												padding: '6.5px 14px !important',
										  },
								'& .MuiAutocomplete-inputRoot': {
									padding: '0 !important',
								},
								'& .MuiTextField-root': {
									marginBottom: '0',
								},
							}}
							uniqueKey={getIdMapping(index)}
							onClick={() => onRowClick?.({ row, index, formHook })}
						>
							{fields
								.filter(item => !item.hidden)
								.map(item => (
									<TableCell key={item.header} sx={item.cellSx}>
										{item.customContent || (
											<FormFieldVariants
												field={{
													...item,
													name: getGridMapping(item, index),
												}}
												formHook={formHook}
												{...item}
											/>
										)}
									</TableCell>
								))}

							{isEditing && onDelete && (
								<TableCell key="delete" sx={{ textAlign: 'right', width: '74px' }}>
									<Box
										className="onDelete"
										sx={{
											visibility: getSelectedRow?.({
												row,
												index,
												formHook,
											})
												? 'visible'
												: 'hidden',
										}}
									>
										<DeleteProgress
											onDelete={() =>
												onDelete({
													row,
													index,
													formHook,
													mapping: name,
												})
											}
										/>
									</Box>
								</TableCell>
							)}
						</ExpandableRow>
					))}
					{rows.length < maxNumOfAddableRows && onAdd && isEditing && (
						<TableRow key="add-new-row">
							<TableCell colSpan={1000} sx={{ width: '200px', padding: '6px 16px' }}>
								<IconButton
									sx={{
										marginLeft: '20px',
										color: '#FFFFFF99',
									}}
									onClick={e => {
										e.preventDefault();
										e.stopPropagation();
										onAdd({ formHook, mapping: name });
									}}
								>
									<AddIcon />
								</IconButton>
								{addNewItemText}
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

FormGridVariant.defaultProps = {
	isEditing: false,
};

export default FormGridVariant;
