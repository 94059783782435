// core
import React, { useState } from 'react';

// mui
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

// components
import DraggableListItem from '../DraggableListItem';
import AddNewButton from '@worklist-2/ui/src/components/AddNewButton';
import SearchableSingleSelectColumnPicker from '@worklist-2/ui/src/components/SearchableSingleSelectColumnPicker';
import { getStatusIndicatorOptions } from '@worklist-2/ui/src/components/utils/gridUtils';

// libraries
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

const ReArrange = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

const statusIndicatorStyles = {
	width: true,
	style: {
		padding: '16px 11px 11px 11px',
	},
};

const ColumnBuilder = ({
	width,
	columnList,
	preConfiguredColumnList,
	onChange,
	onChangeDelete,
	setComponentColumnList,
	componentColumnList,
	disableAddButton,
	height,
	isLarge,
	isWorklist = false,
}) => {
	const [componentList, updateComponentList] = useState(componentColumnList);
	const { t } = useTranslation('workList');
	const sortedColumnList = columnList.sort((a, b) =>
		a.label ? a.label.localeCompare(b.name) : a.name.localeCompare(b.name)
	);
	const handleAddNewColumn = event => {
		const list = componentColumnList;
		const unique_id = uuid();
		const itemid = unique_id.slice(0, 8);
		list.unshift({ name: '', label: '', id: itemid });

		if (onChange) {
			onChange(list);
			setComponentColumnList([...list]);
		}
	};
	const handleOnDragEnd = results => {
		if (!results.destination) {
			return;
		}

		const rearrangedItemList = ReArrange(componentList, results.source.index, results.destination.index);
		setComponentColumnList([...rearrangedItemList]);
		onChange(rearrangedItemList, { dragItem: true });
	};

	const handleColumnValueSelect = (columnName, column, reason, indicatorStatus = false) => {
		const list = componentColumnList?.map(item => {
			if (item.id === column.id) {
				if (indicatorStatus) {
					const customFilterOptions = getStatusIndicatorOptions(columnName);
					const selectedValues = item.filter
						? getStatusIndicatorOptions(
								columnName,
								item.filter.map(f => ({ id: f }))
						  )?.map(f => f.id)
						: [];
					return {
						...item,
						filter: selectedValues,
						displayValue: selectedValues,
						customFilterOptions,
						name: column ? column.name : '',
						label: column ? column.label : '',
						statusIndicators: columnName || '',
						options: columnName?.options ? columnName.options : '',
					};
				}
				const returnObj = {
					...item,
					name: columnName ? columnName.name : '',
					options: columnName?.options ? columnName.options : '',
				};
				if (!isWorklist) {
					returnObj.label = columnName ? columnName.label : '';
				}
				return returnObj;
			}
			return item;
		});

		if (onChange) {
			onChange(list, { updateColumns: true });
		}
		setComponentColumnList([...list]);
	};

	const handleDeleteColumn = item => {
		const list = componentColumnList?.filter(itemid => itemid.id !== item.id);
		setComponentColumnList([...list]);
		if (onChangeDelete) {
			onChangeDelete(list);
		}
		onChange(list, { updateColumns: true });
	};

	return (
		<DragDropContext onDragEnd={handleOnDragEnd}>
			<Stack container alignItems="center">
				<AddNewButton
					disabled={disableAddButton}
					id="addNewButton"
					tooltipTitle={t('workListDrawer.add')}
					width={width}
					onClick={handleAddNewColumn}
				/>

				<Droppable droppableId="droppable-1">
					{provided => (
						<List
							ref={provided.innerRef}
							{...provided.droppableProps}
							sx={{
								paddingLeft: '0px',
								height: height || `calc(100vh - ${isLarge ? 260 : 500}px)`,
								overflow: 'auto',
							}}
						>
							{componentList?.map((item, index) => (
								<DraggableListItem key={item.id} dragId={item.id} index={index} item={item}>
									<ListItem
										id={item.id}
										sx={{
											color: 'icon.primary',
											paddingLeft: '0px',
											paddingRight: '0px',
										}}
									>
										<Typography
											sx={{
												width: '30px',
												textAlign: 'center',
											}}
										>
											{index + 1}
										</Typography>
										{item.name === 'statusIndicator' ? (
											<Box
												sx={{
													width,
													background: '#272727',
													border: '1px solid rgba(255, 255, 255, 0.23)',
													borderRadius: '5px',
												}}
											>
												<SearchableSingleSelectColumnPicker
													columnId={item}
													columnStyles={statusIndicatorStyles}
													data={sortedColumnList}
													isWorklist={isWorklist}
													label={t('workListDrawer.columnName')}
													selectedItemArray={preConfiguredColumnList}
													testId={`SearchableSingleSelect${index}`}
													onChange={handleColumnValueSelect}
												/>
											</Box>
										) : (
											<SearchableSingleSelectColumnPicker
												columnId={item}
												data={sortedColumnList}
												isWorklist={isWorklist}
												label={t('workListDrawer.columnName')}
												selectedItemArray={preConfiguredColumnList}
												testId={`SearchableSingleSelect${index}`}
												width={width}
												onChange={handleColumnValueSelect}
											/>
										)}
										<Tooltip title={t('remove')}>
											<IconButton
												sx={{
													opacity: 0.6,
													marginLeft: '4px',
													visibility: 'hidden',
												}}
												onClick={event => {
													handleDeleteColumn(item);
												}}
											>
												<DeleteIcon data-cy={`delete${index}`} />
											</IconButton>
										</Tooltip>
									</ListItem>
								</DraggableListItem>
							))}
							{provided.placeholder}
						</List>
					)}
				</Droppable>
			</Stack>
		</DragDropContext>
	);
};

ColumnBuilder.propTypes = {
	/**
	 * Width of the Add new button and column builder
	 */
	width: PropTypes.number,

	/**
	 * Height of the column builder grid
	 */
	height: PropTypes.number,

	/**
	 * List of columns for worklist
	 */
	columnList: PropTypes.array,

	/**
	 * User saved columns specific to worklist.
	 */
	preConfiguredColumnList: PropTypes.array,

	/**
	 * Handler called when columns have changed
	 */
	onChange: PropTypes.func,

	/**
	 * Handler to update Sort tab columns when column in ColumnBuilder is deleted
	 */
	onChangeDelete: PropTypes.func,
};

ColumnBuilder.defaultProps = {
	width: 200,
	columnList: [],
	preConfiguredColumnList: [],
	// hasWorklist: true,
};

export default ColumnBuilder;
