import React from 'react';

const RightArrow = () => (
	<svg fill="none" height={20} width={20} xmlns="http://www.w3.org/2000/svg">
		<mask
			height={20}
			id="a"
			maskUnits="userSpaceOnUse"
			style={{
				maskType: 'alpha',
			}}
			width={20}
			x={0}
			y={0}
		>
			<path d="M0 0h20v20H0z" fill="#D9D9D9" />
		</mask>
		<g mask="url(#a)">
			<path
				d="m6.688 18.334-1.479-1.48L12.063 10 5.21 3.146l1.48-1.479L15.02 10l-8.333 8.334Z"
				fill="#fff"
				fillOpacity={0.6}
			/>
		</g>
	</svg>
);

export default RightArrow;
