import React from 'react';
import FeedIcon from '@mui/icons-material/Feed';
import IconButton from '@mui/material/IconButton';

import { Svg } from '@rs-ui/components/Svg';

import MobileList from '../MobileList/MobileList';

const MobileReportsList = ({ reports, onClick, onDownloadClick, showCount }) => {
	const items = reports.map(report => ({
		primary: report.reportName,
		icon: FeedIcon,
		secondaryAction: (
			<IconButton
				data-testid="download-btn"
				sx={{
					'svg, path': { fill: 'rgba(111, 111, 111, 1)' },
				}}
				onClick={() => onDownloadClick(report)}
			>
				<Svg name="download" />
			</IconButton>
		),
		sx: {
			padding: '2px 0',
		},
		onClick: () => onClick(report),
	}));

	return (
		<MobileList
			background="#FAFAFA"
			border="none"
			items={items}
			showCount={showCount}
			sx={{
				marginBottom: '50px',
			}}
		/>
	);
};

export default MobileReportsList;
