import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import Icon from '@mui/material/Icon';

import HoldToDelete from '../HoldToDelete/HoldToDelete';
import Avatar from '../Avatar';
import { generateAvatarColour } from '../Avatar/generateAvatarColour';
import { NotesFieldDateTime } from './NotesFieldDateTime';
import BlumeLogo from '@worklist-2/ui/src/assets/icons/blume_logo.svg';
import { useTranslation } from 'react-i18next';

const StyledNotesField = styled(props => <TextField InputProps={{ disableUnderline: true }} {...props} />)(
	({ theme }) => ({
		'& .MuiFilledInput-root': {
			fontSize: '12px',
			overflow: 'hidden',
			paddingTop: '40px',
			paddingBottom: '50px',
			transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
			backgroundColor: '#414141',
			background: `linear-gradient(#414141, #414141) padding-box,
			linear-gradient(102.58deg, rgba(66, 165, 245, 0.6) 0%, rgba(6, 173, 163, 0.3) 109.54%) border-box`,
			border: '1px solid transparent',
			borderRadius: 20,
			'&:hover, &.Mui-focused': {
				backgroundColor: 'transparent',
				borderColor: '#42A5F5',
			},
			'&:before, &:hover:before': {
				borderBottom: '1px solid transparent !important',
			},
			'& .Mui-disabled': {
				cursor: 'pointer',
				WebkitTextFillColor: '#fff !important',
			},
		},
	})
);

const SimpleTextField = ({ fieldArray, readOnly, style, props, type = '' }) => {
	const { t } = useTranslation('order');

	return (
		<StyledNotesField
			{...fieldArray}
			{...props}
			multiline
			InputLabelProps={{ shrink: true }}
			InputProps={{
				style: { paddingLeft: '20px' },
				readOnly,
				startAdornment: props?.defaultValue && props?.time && (
					<InputAdornment position="start">
						<Box
							sx={{
								position: 'absolute',
								bottom: '9px',
								left: '16px',
								display: 'flex',
								alignItems: 'center',
								color: 'rgba(255, 255, 255, 0.6)',
							}}
						>
							<NotesFieldDateTime time={props?.time} />
						</Box>
					</InputAdornment>
				),
			}}
			label={
				<Box
					sx={{
						display: 'inline-flex',
						alignItems: 'center',
						mt: '10px',
						ml: '10px',
						pb: '10px',
						width: '100%',
						color: '#fff',
					}}
				>
					<SummarizeOutlinedIcon sx={{ color: '#42A5F5' }} />
					<Typography sx={{ ml: '10px', mr: '10px' }}>{t('note')}</Typography>
					{type === 'blumeNotes' && (
						<Icon
							component={BlumeLogo}
							style={{
								height: '32px',
								width: '32x',
							}}
						/>
					)}
				</Box>
			}
			placeholder={readOnly ? '' : t('addNotesHere')}
			rows={5}
			style={{ marginTop: 11, marginBottom: 50, ...style }}
			variant="filled"
		/>
	);
};

const NotesField = ({
	formHook,
	name,
	initialValue,
	disabled = true,
	readOnly,
	isEditing,
	setEditing,
	onChange,
	noteMapping,
	studyInformation = false,
	loggedInUser,
	simpleTextFieldStyle = {},
}) => {
	const { control } = formHook || '';
	const [showNotesField, setShowNotesField] = useState(false);
	const [showDelete, setShowDelete] = useState(null);
	const [cloneDeepValue, setCloneDeepValue] = useState(null);
	const [totalNotesFields, setTotalNotesFields] = useState(
		initialValue?.length || formHook.getValues?.(name)?.length
	);
	const [noteDisplayOrder, setNoteDisplayOrder] = useState([{ zindex: '10', width: '100%' }]);
	const [uniqueTestingVal, setUniqueTestingVal] = useState([]);

	useEffect(() => {
		setCloneDeepValue(_.cloneDeep(initialValue) || _.cloneDeep(formHook?.getValues(name)));

		setUniqueTestingVal(createUniqueKeys());
	}, []);

	useEffect(() => {
		// Set initial Display order for notes
		const displayOrder = [];
		let count = 0;

		while (count < totalNotesFields) {
			count++;

			displayOrder.push({
				zindex: count,
				width: 101 - count * 5,
			});
		}

		setNoteDisplayOrder([...displayOrder]);
	}, [totalNotesFields]);

	// Creating Unique Keys
	const createUniqueKeys = () => {
		const uuidArr = [];

		let i = 0;
		while (i < 5) {
			uuidArr.push(uuid());
			i++;
		}
		return uuidArr;
	};

	useEffect(() => {
		setUniqueTestingVal(createUniqueKeys());
	}, [isEditing]);

	// Move the clicked note to the front
	const onChangeActiveNote = (clickedNoteIndex, clickedNoteWidth) => {
		const activeChanged = noteDisplayOrder?.map((element, index) => {
			if (index === clickedNoteIndex) {
				element.zindex = totalNotesFields;
				element.width = 101 - totalNotesFields * 5;
			} else if (clickedNoteWidth > element.width) {
				element.zindex -= 1;
				element.width += 5;
			}
			return element;
		});

		setNoteDisplayOrder([...activeChanged]);
	};

	const onDelete = deletedNoteIndex => {
		setEditing(true);
		const newUniqueValue = uniqueTestingVal;
		newUniqueValue?.splice(deletedNoteIndex, 1);
		newUniqueValue?.push(uuid());

		setUniqueTestingVal(newUniqueValue);

		if (totalNotesFields === 1) {
			setShowNotesField(false);
		}

		setTotalNotesFields(prev => prev - 1);
		onChange(deletedNoteIndex, null, noteMapping, true);
	};

	const onAddStudyNote = (studyFormHook, studyMappingName) => {
		let array = studyFormHook.getValues(studyMappingName);
		const date = moment(new Date()).toISOString();

		if (!Array.isArray(array)) {
			array = [];
		}

		const newNote = {
			uniqueKey: uuid(),
			authorReference: {
				reference: `practitioner/${loggedInUser.id}`,
				display: loggedInUser.fullName,
			},
			authorString: loggedInUser.fullName,
			time: date,
			text: '',
		};

		studyFormHook.setValue(studyMappingName, [...array, newNote]);
	};

	const onDeleteStudyNote = studyNoteIndexKey => {
		const array = formHook.getValues(name);

		// Removing empty value
		array.splice(studyNoteIndexKey, 1);

		formHook.setValue(name, array);

		setTotalNotesFields(array.length);

		array.length === 0 && setShowNotesField(false);
	};

	const isDisabledTextField = (textValue, createdTime) => {
		let deepCount = 0;
		let disabledTextField = false;

		if (!isEditing && studyInformation) {
			setCloneDeepValue(formHook?.getValues(name));
		}

		while (deepCount < cloneDeepValue?.length) {
			if (cloneDeepValue?.[deepCount]?.time === createdTime && cloneDeepValue?.[deepCount]?.text === textValue) {
				disabledTextField = true;
			}

			deepCount++;
		}
		return disabledTextField;
	};

	// Removing empty notes when not editing on order
	if (!isEditing && !studyInformation) {
		totalNotesFields !== cloneDeepValue?.length && setTotalNotesFields(cloneDeepValue?.length);
	}

	// Removing empty notes when not editing on study
	useEffect(() => {
		if (!isEditing && studyInformation) {
			const array = formHook.getValues(name);
			if (array) {
				let counter = 0;
				while (counter < formHook.getValues(name).length) {
					if (formHook.getValues(name)?.[counter].text === '') {
						array.splice(counter, 1);
					}

					counter++;
				}

				formHook.setValue(name, array);

				array.length === 0 && setShowNotesField(false);

				setTotalNotesFields(array.length);
				setCloneDeepValue(_.cloneDeep(array));
			}
		}
	}, [formHook?.getValues?.(name)?.length]);

	// Explicitly checking for when notes are empty ([{text: ''}])
	if (showNotesField && !initialValue?.[0]?.text && !isEditing) {
		!studyInformation && setShowNotesField(false);
	}

	return control ? (
		<Controller
			control={control}
			name={name}
			render={({ field }) =>
				!studyInformation ? (
					<SimpleTextField
						fieldArray={field}
						formHookVal={formHook}
						readOnly={readOnly}
						style={simpleTextFieldStyle}
					/>
				) : (
					<>
						<Stack
							alignItems="center"
							direction="row"
							justifyContent="space-between"
							sx={{
								position: 'relative',
								mt: '20px',
								display: totalNotesFields > 0 ? 'flex' : 'none',
								width: '100%',
							}}
						>
							{Array.from(Array(totalNotesFields))?.map((eachNote, index) => (
								<StyledNotesField
									key={field.value?.[index]?.uniqueKey || `${uniqueTestingVal[index]}_${index}`}
									multiline
									InputLabelProps={{ shrink: true }}
									InputProps={{
										id: field.value?.[index]?.uniqueKey || `${uniqueTestingVal[index]}_${index}`,
										startAdornment: (
											<InputAdornment position="start">
												<Box
													sx={{
														position: 'absolute',
														right: '20px',
														top: '18px',
														color: 'rgba(255, 255, 255, 0.6)',
													}}
												>
													{showDelete === index ? (
														<HoldToDelete onDelete={() => onDeleteStudyNote(index)} />
													) : (
														moment(cloneDeepValue?.[index]?.time).format('MM/DD/YYYY')
													)}
												</Box>
												<Box
													sx={{
														position: 'absolute',
														bottom: '9px',
														left: '16px',
														display: !cloneDeepValue?.[index]?.authorReference
															? 'none'
															: 'flex',
														alignItems: 'center',
														color: 'rgba(255, 255, 255, 0.6)',
													}}
												>
													<Avatar
														backgroundColor={generateAvatarColour(
															cloneDeepValue?.[index]?.authorReference?.display ||
																loggedInUser?.fullName
														)}
														fontSize={14}
														height="24px"
														label={
															cloneDeepValue?.[index]?.authorReference?.display ||
															loggedInUser?.fullName
														}
														testId={`note_${index}_avatar`}
														transparent={false}
														width="24px"
													/>
													<Typography
														sx={{
															fontSize: '12px',
															fontWeight: 400,
															ml: '10px',
														}}
													>
														Created by&nbsp;
														<strong>
															{cloneDeepValue?.[index]?.authorReference?.display ||
																loggedInUser?.fullName}
														</strong>
														&nbsp;| From&nbsp;
														<strong>Study</strong>
													</Typography>
													{cloneDeepValue?.[index]?.time && cloneDeepValue?.[index]?.text && (
														<NotesFieldDateTime
															sx={{ ml: '10px' }}
															time={cloneDeepValue?.[index]?.time}
														/>
													)}
												</Box>
											</InputAdornment>
										),
									}}
									defaultValue={field?.value?.[index]?.text || ''}
									disabled={
										field.value?.[index]?.text
											? isDisabledTextField(
													field.value?.[index]?.text,
													field.value?.[index]?.time
											  )
											: false
									}
									label={
										<Box
											sx={{
												display: 'inline-flex',
												alignItems: 'center',
												mt: '10px',
												ml: '10px',
												pb: '10px',
												width: '100%',
												color: '#fff',
											}}
										>
											<SummarizeOutlinedIcon sx={{ color: '#42A5F5' }} />
											<Typography sx={{ ml: '10px' }}>Note</Typography>
										</Box>
									}
									name={`${name}.${index}.text`}
									placeholder="Add notes here"
									rows={5}
									style={{
										position: 'absolute',
										width: `${noteDisplayOrder?.[index]?.width}%`,
										zIndex: noteDisplayOrder?.[index]?.zindex,
										height: '100%',
										marginBottom: '0',
									}}
									variant="filled"
									onChange={e => formHook.setValue(`${name}.${index}.text`, e.target.value)}
									onClick={e => {
										e.preventDefault();
										e.stopPropagation();
										onChangeActiveNote(index, noteDisplayOrder?.[index]?.width);
									}}
									onMouseEnter={() => {
										isEditing && setShowDelete(() => index);
									}}
									onMouseLeave={() => {
										isEditing && setShowDelete(() => null);
									}}
								/>
							))}

							<Button
								disabled={totalNotesFields === 5}
								sx={{
									position: 'relative',
									left: '96%',
									color: '#FFFFFF66',
									fontSize: '24px',
									background: 'transparent',
									width: '5%',
									minHeight: '176px',
									minWidth: '50px',
									height: '100%',
									border: '1px dashed #FFFFFF66',

									borderTopRightRadius: '20px',
									borderBottomRightRadius: '20px',
									borderLeft: 'none',
									ml: '-20px',
									pl: '30px',
									'&:hover': {
										backgroundColor: 'transparent',
										border: '1px dashed #FFFFFF',
									},
								}}
								variant="outlined"
								onClick={e => {
									e.preventDefault();
									e.stopPropagation();
									setEditing(true);
									setTotalNotesFields(prev => prev + 1);
									onAddStudyNote(formHook, name);
								}}
							>
								+
							</Button>
						</Stack>
						<Button
							disableElevation
							sx={{
								color: '#FFFFFF',
								fontSize: '14px',
								fontStyle: 'normal',
								fontWeight: 400,
								lineHeight: '20px',
								letterSpacing: '0.25px',
								border: '1px dashed #FFFFFF66',
								borderRadius: '24px',
								mb: '100px !important',
								display: !showNotesField && totalNotesFields === 0 ? 'flex' : 'none',
								width: '100%',
								height: '168px',
								'&:hover': {
									backgroundColor: 'transparent',
									borderColor: '#42A5F5',
								},
							}}
							variant="outlined"
							onClick={() => {
								setShowNotesField(!showNotesField);
								setEditing(true);
								totalNotesFields === 0 && setTotalNotesFields(prev => prev + 1);
								onAddStudyNote(formHook, name);
							}}
						>
							+<br />
							Add a Note
						</Button>
					</>
				)
			}
		/>
	) : (
		<>
			<Stack
				alignItems="center"
				direction="row"
				justifyContent="space-between"
				sx={{
					position: 'relative',
					mt: '20px',
					display: totalNotesFields > 0 && (showNotesField || initialValue?.[0]?.text) ? 'flex' : 'none',
					width: '100%',
				}}
			>
				{Array.from(Array(totalNotesFields))?.map((eachNote, index) => (
					<StyledNotesField
						key={uniqueTestingVal[index]}
						multiline
						InputLabelProps={{ shrink: true }}
						InputProps={{
							id: `${uniqueTestingVal[index]}_${index}`,
							startAdornment: (
								<InputAdornment position="start">
									<Box
										sx={{
											position: 'absolute',
											right: '20px',
											top: '18px',
											color: 'rgba(255, 255, 255, 0.6)',
										}}
									>
										{showDelete === index ? (
											<HoldToDelete
												onDelete={() => {
													onDelete(index);
												}}
											/>
										) : (
											moment(cloneDeepValue?.[index]?.time).format('MM/DD/YYYY')
										)}
									</Box>
									<Box
										sx={{
											position: 'absolute',
											bottom: '9px',
											left: '16px',
											display: !cloneDeepValue?.[index]?.authorReference ? 'none' : 'flex',
											alignItems: 'center',
											color: 'rgba(255, 255, 255, 0.6)',
										}}
									>
										<Avatar
											backgroundColor={generateAvatarColour(
												cloneDeepValue?.[index]?.authorReference?.display ||
													loggedInUser?.fullName
											)}
											fontSize={14}
											height="24px"
											label={
												cloneDeepValue?.[index]?.authorReference?.display ||
												loggedInUser?.fullName
											}
											testId={`note_${index}_avatar`}
											transparent={false}
											width="24px"
										/>
										<Typography
											sx={{
												fontSize: '12px',
												fontWeight: 400,
												ml: '10px',
											}}
										>
											Created by&nbsp;
											<strong>
												{cloneDeepValue?.[index]?.authorReference?.display ||
													loggedInUser?.fullName}
											</strong>
											&nbsp;| From&nbsp;
											<strong>Order</strong>
										</Typography>
									</Box>
								</InputAdornment>
							),
						}}
						data-note-id={uniqueTestingVal[index]}
						defaultValue={initialValue?.[index]?.text}
						disabled={
							initialValue?.[index]?.text
								? isDisabledTextField(initialValue?.[index]?.text, initialValue?.[index]?.time)
								: false
						}
						label={
							<Box
								sx={{
									display: 'inline-flex',
									alignItems: 'center',
									mt: '10px',
									ml: '10px',
									pb: '10px',
									width: '100%',
									color: '#fff',
								}}
							>
								<SummarizeOutlinedIcon sx={{ color: '#42A5F5' }} />
								<Typography sx={{ ml: '10px' }}>Note</Typography>
							</Box>
						}
						placeholder="Add notes here"
						rows={5}
						style={{
							position: 'absolute',
							width: `${noteDisplayOrder?.[index]?.width}%`,
							zIndex: noteDisplayOrder?.[index]?.zindex,
							height: '100%',
							marginBottom: '0',
						}}
						variant="filled"
						onChange={event => onChange(event, event.target.value, noteMapping)}
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							onChangeActiveNote(index, noteDisplayOrder?.[index]?.width);
						}}
						onMouseEnter={() => {
							isEditing && setShowDelete(() => index);
						}}
						onMouseLeave={() => {
							isEditing && setShowDelete(() => null);
						}}
					/>
				))}
				<Button
					disabled={totalNotesFields === 5}
					sx={{
						position: 'relative',
						left: '96%',
						color: '#FFFFFF66',
						fontSize: '24px',
						fontWeight: '400 !important',
						background: 'transparent',
						width: '5%',
						minHeight: '176px',
						minWidth: '50px',
						height: '100%',
						border: '1px dashed #FFFFFF66',

						borderTopRightRadius: '20px',
						borderBottomRightRadius: '20px',
						borderLeft: 'none',
						ml: '-20px',
						pl: '30px',
						'&:hover': {
							backgroundColor: 'transparent',
							border: '1px dashed #FFFFFF',
						},
					}}
					variant="outlined"
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						setEditing(true);
						setTotalNotesFields(prev => prev + 1);
					}}
				>
					+
				</Button>
			</Stack>
			<Button
				disableElevation
				sx={{
					color: '#FFFFFF',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '20px',
					letterSpacing: '0.25px',
					border: '1px dashed #FFFFFF66',
					borderRadius: '24px',
					mb: '100px !important',
					mt: '20px',
					display: !initialValue?.[0]?.text && !showNotesField ? 'flex' : 'none',
					width: '100%',
					height: '168px',
					'&:hover': {
						backgroundColor: 'transparent',
						borderColor: '#42A5F5',
					},
				}}
				variant="outlined"
				onClick={() => {
					setShowNotesField(!showNotesField);
					setEditing(true);
					totalNotesFields === 0 && setTotalNotesFields(prev => prev + 1);
				}}
			>
				+<br />
				Add a Note
			</Button>
		</>
	);
};

export { SimpleTextField, NotesField };
