// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qEl_rTYW1jSqBGYBqBJo{position:relative;display:flex}.qEl_rTYW1jSqBGYBqBJo .bq2adpHTsSA9dAMjn8AM{display:flex;flex-direction:column;width:100%;padding:35px}.qEl_rTYW1jSqBGYBqBJo .bq2adpHTsSA9dAMjn8AM.YCkC9ikoqzPDLNqJpTM9{width:calc(100% - 300px)}.qEl_rTYW1jSqBGYBqBJo .bq2adpHTsSA9dAMjn8AM .Wx2fsFHA3IXtwpUmZHQ8{color:var(--neutral-color-dark-60, rgba(18, 18, 18, 0.6));font-feature-settings:\"clig\" off,\"liga\" off;font-family:Roboto,serif;font-size:20px;font-style:normal;font-weight:400;line-height:24px;letter-spacing:.15px;text-transform:uppercase}.qEl_rTYW1jSqBGYBqBJo .zfC2L44GoBDbucdkaF6a{color:#fff;font-family:Roboto,serif;font-size:28px;font-style:normal;font-weight:400;line-height:normal}.qEl_rTYW1jSqBGYBqBJo .bq2adpHTsSA9dAMjn8AM .FW2qCvJVXiKpkX4uKrqF{display:flex;width:100%}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/HelpCenter/HelpCenter.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CAEA,4CACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,YAAA,CAEA,iEACE,wBAAA,CAEF,kEACE,yDAAA,CACA,2CAAA,CACA,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,oBAAA,CACA,wBAAA,CAGF,4CACE,UAAA,CACA,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CAGF,kEACE,YAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpCenter": "qEl_rTYW1jSqBGYBqBJo",
	"right": "bq2adpHTsSA9dAMjn8AM",
	"activeButton": "YCkC9ikoqzPDLNqJpTM9",
	"titleBlume": "Wx2fsFHA3IXtwpUmZHQ8",
	"rightTitle": "zfC2L44GoBDbucdkaF6a",
	"main": "FW2qCvJVXiKpkX4uKrqF"
};
export default ___CSS_LOADER_EXPORT___;
