import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { mediaMobile } from '@worklist-2/core/src';

import MobileTitle from '../../MobileTitle';

const MobileProcessingReportsSection = () => {
	const { t } = useTranslation('studyDetail');

	return (
		<Box>
			<MobileTitle title={t('Reports')} />

			<Box
				sx={{
					border: '1px solid rgba(0, 0, 0, 0.1)',
					borderRadius: '20px',
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					padding: '30px',
					marginBottom: '50px',
				}}
			>
				<Typography
					sx={{
						fontSize: '24px',
						lineHeight: '36px',
						[mediaMobile]: {
							fontSize: '16px',
							lineHeight: '20px',
							color: 'rgba(57, 57, 57, 0.87)',
							marginBottom: '10px',
						},
					}}
				>
					{t('Not available yet...')}
				</Typography>

				<Typography
					sx={{
						fontSize: '14px',
						lineHeight: '20px',
						color: 'rgba(57, 57, 57, 0.6)',
					}}
				>
					{t('Your report will be ready soon...')}
				</Typography>
			</Box>
		</Box>
	);
};

export default MobileProcessingReportsSection;
