import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import Check from '@mui/icons-material/Check';

import { getAutocompleteClearAndPopupTooltips } from '@rs-ui/helpers/tooltipUtils';
import { useTranslation } from 'react-i18next';
import StatusIndicators from '../SettingStatusIndicators';

const SearchableSingleSelectColumnPicker = ({
	data,
	size,
	label,
	initValue,
	fullWidth,
	width,
	columnId,
	selectedItemArray,
	disabled,
	onChange,
	testId,
	columnStyles = null,
	isWorklist = false,
}) => {
	const { t } = useTranslation(['workList', 'formapiautocompletevariant']);
	const { t: worklistColumnsTranslateFn } = useTranslation('imagingWorkListColumns');
	return (
		<>
			<Autocomplete
				disablePortal
				data-testid="searchable-user-selector-autocomplete"
				disabled={disabled}
				fullWidth={fullWidth}
				getOptionDisabled={option =>
					selectedItemArray && !!selectedItemArray.find(element => element.name === option.name)
				}
				getOptionLabel={option => (isWorklist ? worklistColumnsTranslateFn(option.name) : option.label)}
				id="searchable-user-selectory"
				options={data}
				renderInput={params => (
					<TextField
						{...params}
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
						data-cy={testId}
						data-testid="searchable-user-selector-textfield"
						label={label}
						variant="outlined"
					/>
				)}
				renderOption={(props, option) => (
					<MenuItem key={option.id} sx={{ p: 1.5 }} value={option.name} {...props}>
						<ListItemIcon variant="checklist">
							{columnId ? (
								columnId.name === option.name || option.name === initValue ? (
									<Check />
								) : (
									''
								)
							) : (
								''
							)}
						</ListItemIcon>
						{isWorklist ? (
							<ListItemText data-testid="worklist-translated">
								{worklistColumnsTranslateFn(option.name)}
							</ListItemText>
						) : (
							<ListItemText data-testid="searchable-list-item" primary={option.label} />
						)}
					</MenuItem>
				)}
				// isOptionEqualToValue={(option, value) => option.name === value.name}
				renderValue={value => option.find(element => element.name === columnId.name)[value.name]}
				size={size || 'medium'}
				sx={{
					...(fullWidth || columnStyles?.width ? '' : { width }),
					...columnStyles?.style,
					...getAutocompleteClearAndPopupTooltips({ clearText: t('clearText'), openText: t('openText') }),
				}}
				value={
					columnId
						? {
								name: columnId.name,
								label: isWorklist ? worklistColumnsTranslateFn(columnId.name) : columnId.label,
						  }
						: null
				}
				onChange={(event, value, reason) => {
					onChange(value, columnId, reason);
				}}
			/>
			{columnId.name === 'statusIndicator' && (
				<StatusIndicators
					columnId={columnId}
					t={t}
					onChange={indicators => onChange(indicators, columnId, '', true)}
				/>
			)}
		</>
	);
};

SearchableSingleSelectColumnPicker.defaultProps = {
	size: 'medium',
	data: [],
	width: 200,
	fullWidth: false,
	columnId: 0,
	disabled: false,
};

SearchableSingleSelectColumnPicker.propTypes = {
	/**
	 * Array of data for search and select.
	 */
	data: PropTypes.array,

	/**
	 * Size of the element
	 */
	size: PropTypes.oneOf(['small', 'medium']),

	/**
	 * Label of the element
	 */
	label: PropTypes.string,

	/**
	 * The initial field value. Used when a filter is pre-set
	 */
	initValue: PropTypes.string,

	/**
	 * Width of the element
	 */
	width: PropTypes.number,

	/**
	 * Determines if the component should take up the full width of the container.
	 */
	fullWidth: PropTypes.bool,

	/**
	 * Callback function when an item is selected
	 */
	onChange: PropTypes.func,
	/**
	 * Array of data for disable.
	 */
	selectedItemArray: PropTypes.array,
	/**
	 * enable and disable the component
	 */
	disabled: PropTypes.bool,
};

export default SearchableSingleSelectColumnPicker;
