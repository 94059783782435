import GppGoodIcon from '@mui/icons-material/GppGood';
import GppBadIcon from '@mui/icons-material/GppBad';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

// healthcareService, studyStatus: configurable on GUI by users
// procedureCode: around 8912 rows, medical terms
export const SKIP_TRANSLATION_LIST = [
	'healthcareService',
	'procedureCode',
	'status',
	'auditEventEntityType',
	'insurancePayer',
	'eligibility',
	'laterality',
	'financialClass',
	'workflowStatus',
	'taskStatus',
	'faxStatus',
	'faxDirection',
	'transmissionPriority',
];
export const CUSTOM_FILTER_OPTIONS_LIST = ['statusIndicator', 'transmissionPriority'];
// exclude columns that have customized valueset loading
export const WORKLIST_GRID_COLUMN_VALUESET_EXCLUDE_LIST = ['Procedure Code'];
export const PREFIX_LIST = ['DR', 'MR', 'MRS', 'MS.'];
export const SUFFIX_LIST = [
	'JR',
	'SR',
	'I',
	'II',
	'III',
	'IV',
	'NP',
	'RPA',
	'PA',
	'RT',
	'RBMS',
	'PHD',
	'RN',
	'DO',
	'MD',
	'D.C',
	'D.P.M',
];

export const GUARANTOR_RELATIONSHIP_TYPE = [
	{ code: 'SEL', display: 'SELF' },
	{ code: 'PAR', display: 'PARENT' },
	{ code: 'CHD', display: 'CHILD' },
	{ code: 'SPO', display: 'SPOUSE' },
	{ code: 'OTH', display: 'OTHER' },
];

export const COVERAGE_STATUS_CODES = [
	{
		value: { code: 'ACT', display: 'ACTIVE' },
		label: 'Active',
		Icon: GppGoodIcon,
		sx: {
			width: '24px',
			height: '24px',
			'&.MuiSvgIcon-root': {
				fill: '#72D9B4',
			},
		},
	},
	{
		value: { code: 'IAC', display: 'INACTIVE' },
		label: 'Inactive',
		Icon: GppBadIcon,
		sx: {
			width: '24px',
			height: '24px',
			'&.MuiSvgIcon-root': {
				fill: '#CF6679',
			},
		},
	},
	{
		value: { code: 'UNK', display: 'UNKNOWN' },
		label: 'Unknown',
		Icon: GppMaybeIcon,
		sx: {
			width: '24px',
			height: '24px',
			'&.MuiSvgIcon-root': {
				fill: '#EDB900',
			},
		},
	},
];

export const PAYER_STATUS = [
	{
		value: true,
		label: 'Active',
		Icon: VerifiedUserIcon,
		sx: {
			width: '21px',
			height: '21px',
			'&.MuiSvgIcon-root': {
				fill: '#72D9B4',
			},
		},
	},
	{
		value: false,
		label: 'Inactive',
		Icon: GppBadIcon,
		sx: {
			width: '24px',
			height: '24px',
			'&.MuiSvgIcon-root': {
				fill: '#CF6679',
			},
		},
	},
];
