import BusinessIcon from '@mui/icons-material/Business';
import CropFreeIcon from '@mui/icons-material/CropFree';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useState, useEffect } from 'react';
import { Header, Title } from '../../../views/CrmView/styles';
import OKRListItem from './OKRListItem';
import { useNavigate } from 'react-router';
import { crmSearchScopes, useCRMDataLoader, useAuth } from '@worklist-2/core/src';
import { useCrmContext } from '../../../context/CRMContext';
import NoDocument from './component/NoDocument';
import { Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { DefaultDepartment, CompanyDepartment } from '../Cases/utils';
import QuarterPicker from './component/QuarterPicker/QuarterPicker';
import _ from 'lodash';

const OKR = () => {
	const defaultData = DefaultDepartment;
	const [isFullScreen, setIsFullScreen] = useState(false);
	const [response, setResponse] = useState();
	const [companies, setCompanies] = useState([]);
	const [filterDeparment, setFilterDeparment] = useState('');
	const { authorized } = useAuth();
	const { quarterGB, setQuarterGB } = useCrmContext();
	const okrDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.okrApi,
	});

	let navigate = useNavigate();
	const { loggedInUser } = useAuth();

	const getData = async () => {
		try {
			let departments = [...defaultData];
			let params = {
				quarter: quarterGB,
				sort: 'ordernum',
				summary: true,
			};
			if (filterDeparment) {
				params['department'] = `${CompanyDepartment.Department},${filterDeparment}`;
				_.remove(departments, item => !filterDeparment.split(',').includes(item.Department));
			}

			const data = await okrDataLoader.load(params);

			const companyOKR = data.filter(d => d.Department === CompanyDepartment.Department);
			setCompanies({
				Department: CompanyDepartment.Department,
				DepartmentAlias: CompanyDepartment.DepartmentAlias,
				Objective: companyOKR,
				Quarter: quarterGB,
			});

			let groupedDepartments = _.groupBy(
				[...departments, ...data.filter(d => d.Department !== CompanyDepartment.Department)],
				'Department'
			);

			setResponse(
				Object.values(groupedDepartments).map(dept => {
					let objectiveArray = [];
					dept.map(d => {
						if (d.Objective) {
							objectiveArray = objectiveArray.concat(d);
						}
					});
					return {
						Department: dept[0]?.Department,
						DepartmentAlias: dept[0]?.DepartmentAlias,
						Objective: objectiveArray.filter(o => o !== undefined),
						Quarter: quarterGB,
					};
				})
			);
		} catch (error) {
			return error;
		}
	};

	const handleNavigation = obj => {
		if (obj && obj.Department) {
			navigate(`/okr/${obj?.Department}/${quarterGB}`);
		}
	};

	const getFilterDepartmentDisplay = selectedDepartment => {
		let display = selectedDepartment;
		if (!selectedDepartment.includes('All')) {
			display = _.map(selectedDepartment, item => {
				return _.find(DefaultDepartment, dep => dep.Department === item).DepartmentAlias;
			});
		}

		return display;
	};

	useEffect(() => {
		getData();
	}, [quarterGB, authorized, filterDeparment]);

	return (
		<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>
			<Header>
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Title>
						<EmojiEventsIcon sx={{ fontSize: 29, mr: '12px' }} />
						<span>OKR Management</span>
					</Title>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							marginRight: '80px',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								color: 'rgba(0, 0, 0, 0.6)',
								fontSize: '14px',
								fontWeight: '600',
							}}
						>
							<HistoryToggleOffIcon sx={{ fontSize: 24, mr: '5px', color: '#4D79EA' }} />
							<QuarterPicker quarterSelect={quarterGB} setQuarterGB={setQuarterGB} />
						</Box>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								color: 'rgba(0, 0, 0, 0.6)',
								fontSize: '14px',
								fontWeight: '600',
								marginLeft: '20px',
							}}
						>
							<CorporateFareIcon sx={{ fontSize: 24, mr: '5px', color: '#4D79EA' }} />
							<Select
								name="deparment"
								class="notchedOutline"
								sx={{
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										border: 'none',
									},
									'& .MuiOutlinedInput-notchedOutline': {
										border: 'none',
									},
								}}
								MenuProps={{
									PaperProps: {
										sx: {
											borderRadius: '20px',
											width: '210px',
										},
									},
								}}
								multiple
								value={filterDeparment ? filterDeparment.split(',') : ['All']}
								onChange={e => {
									let tempArr = e.target.value;
									if (e.target.value.length > 1 && e.target.value.includes('All')) {
										_.remove(tempArr, item => item == 'All');
									}

									setFilterDeparment(tempArr.join(','));
								}}
								renderValue={selected => getFilterDepartmentDisplay(selected).join(',')}
							>
								{defaultData.map(item => (
									<MenuItem key={item.Department} value={item.Department}>
										<Checkbox
											checked={
												filterDeparment
													? filterDeparment.split(',').indexOf(item.Department) > -1
													: false
											}
										/>
										<ListItemText primary={item.DepartmentAlias} />
									</MenuItem>
								))}
							</Select>
						</Box>
					</Box>
				</Box>
			</Header>
			<Box
				sx={{
					mb: 10,
					pt: 5,
					flex: 1,
					width: 'calc(100vw - 85px)',
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				{/* SIDE BAR */}
				<Box
					sx={{
						width: '266px',
						height: 'calc(100vh - 250px)',
						marginRight: '30px',
						background: '#FFFFFF',
						boxShadow: '0px 0px 20px rgba(77, 121, 234, 0.2)',
						borderRadius: '20px',
						padding: '25px 28px',
						display: 'flex',
						flexDirection: 'column',
						overflowY: 'hidden',
					}}
				>
					{/* SIDE BAR HEADER */}
					<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<BusinessIcon sx={{ fontSize: 24, color: '#4D79EA', mr: '8px' }} />
						<Box sx={{ flex: 1 }}>
							<Typography
								component={'h3'}
								sx={{
									fontFamily: 'Inter',
									fontWeight: 500,
									fontSize: '18px',
									color: '#4D79EA',
								}}
							>
								{companies.DepartmentAlias} ({companies.Objective?.length})
							</Typography>
						</Box>
						<IconButton onClick={() => handleNavigation(companies)}>
							<CropFreeIcon sx={{ fontSize: 24, color: '#4D79EA' }} />
						</IconButton>
					</Box>
					{/* SIDE BAR CONTENT */}
					<Box className="invisible-scrollbar" sx={{ flex: 1, overflowY: 'auto' }}>
						{companies.Objective?.length > 0 ? (
							(companies.Objective || []).map((company, i) => (
								<OKRListItem key={i} data={company} owner={company?.Owner} />
							))
						) : (
							<Box
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									width: '100%',
								}}
							>
								<Box
									onClick={() => handleNavigation(companies)}
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										cursor: 'pointer',
									}}
								>
									<NoDocument />
									{!!loggedInUser?.permission?.OKR?.Add && (
										<Typography
											component={'h3'}
											sx={{
												fontFamily: 'Inter',
												fontWeight: 500,
												fontSize: '18px',
												color: '#4D79EA',
												textAlign: 'center',
												marginTop: '-20px',
											}}
										>
											+ New OKR
										</Typography>
									)}
								</Box>
							</Box>
						)}
					</Box>
				</Box>

				{/* MAIN CONTENT */}
				<Box
					className="invisible-scrollbar"
					sx={{
						flex: 1,
						marginRight: '30px',
						height: 'calc(100vh - 200px)',
						overflow: 'auto',
					}}
				>
					{(response || [])
						.filter(res => res.Department !== undefined)
						.map((dept, i) => (
							<Box
								key={i}
								sx={{
									background: '#FFFFFF',
									boxShadow: ' 0px 0px 20px rgba(33, 88, 119, 0.1)',
									borderRadius: '20px',
									padding: '25px 28px 0px',
									display: 'flex',
									flexDirection: 'column',
									overflowY: 'hidden',
									mb: '30px',
								}}
							>
								<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<CorporateFareIcon sx={{ fontSize: 24, color: '#4D79EA', mr: '8px' }} />
									<Box sx={{ flex: 1 }}>
										<Typography
											component={'h3'}
											sx={{
												fontFamily: 'Inter',
												fontWeight: 500,
												fontSize: '18px',
												color: '#4D79EA',
											}}
										>
											{dept.DepartmentAlias} ({dept.Objective?.length})
										</Typography>
									</Box>
									<IconButton onClick={() => handleNavigation(dept)}>
										<CropFreeIcon sx={{ fontSize: 24, color: '#4D79EA' }} />
									</IconButton>
								</Box>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										paddingBottom: '25px',
										overflowX: 'auto',
										'&::-webkit-scrollbar-thumb': {
											background: '#C4C4C4 !important',
											borderRadius: '4px',
											height: '4px',
										},

										'&::-webkit-scrollbar-track': {
											background: '#FFF !important',
										},
									}}
								>
									{dept.Objective?.length > 0 ? (
										(dept.Objective || []).map((entry, i) => (
											<Box key={i} sx={{ mr: '20px' }}>
												<OKRListItem data={entry} owner={entry?.Owner} />
											</Box>
										))
									) : (
										<Box
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'center',
												width: '100%',
											}}
										>
											<Box
												onClick={() => handleNavigation(dept)}
												style={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													cursor: 'pointer',
												}}
											>
												<NoDocument />
												{!!loggedInUser?.permission?.OKR?.Add && (
													<Typography
														component={'h3'}
														sx={{
															fontFamily: 'Inter',
															fontWeight: 500,
															fontSize: '18px',
															color: '#4D79EA',
															textAlign: 'center',
															marginTop: '-20px',
														}}
													>
														+ New OKR
													</Typography>
												)}
											</Box>
										</Box>
									)}
								</Box>
							</Box>
						))}
				</Box>
			</Box>
		</Box>
	);
};

export default OKR;
