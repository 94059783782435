import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

export const TitleIcon = ({ count = 0, type }) => {
	if (type === 'PATIENT_INFO') {
		return (
			<Box sx={STYLES.USER_BATCH}>
				<PersonIcon />
			</Box>
		);
	}
	if (count > 0) {
		return <Box sx={STYLES.COUNT_BATCH}>{count}</Box>;
	}
	return null;
};

const PatientCard = ({ cardData, isSelected, isHover, rootHeight, resetHeight, handleUploadDocument }) => {
	const { Component, title, type, count, color, sx } = cardData;
	const [isShowMain, setShowMain] = useState(true);
	const galaxyDvGlobalNotes = useBooleanFlagValue('galaxy-dv-global-notes');
	const galaxyDvPatientChartRefactor = useBooleanFlagValue('galaxy-dv-patient-chart-refactor');

	useEffect(() => {
		if (((galaxyDvGlobalNotes && rootHeight === 90) || rootHeight === 60) && isSelected) {
			//to preserve collapse state on applyn template lyout
			//height 90 indicates that card is collapsed
			setShowMain(false);
		} else {
			// to reset accordion back to original state on collapse
			setShowMain(true);
		}
	}, [isSelected, rootHeight]);

	const handleCollapse = status => {
		setShowMain(status);
		if (!status) {
			// to collapse the accoridion
			galaxyDvGlobalNotes ? resetHeight(90) : resetHeight(60);
		}
	};

	const handleExpand = () => {
		setShowMain(true);
		resetHeight();
	};

	return (
		<Box
			sx={
				!galaxyDvPatientChartRefactor && {
					...STYLES.CONTAINER({ color, isSelected, isHover }),
					...sx,
				}
			}
		>
			{(!isSelected || !isShowMain) && (
				<Box data-testid="patient-card-title" sx={STYLES.TITLE} onClick={() => handleExpand()}>
					{title} <TitleIcon count={count} type={type} />
				</Box>
			)}
			{(galaxyDvPatientChartRefactor || (isShowMain && (isSelected || isHover))) && (
				<Box
					className="patient-card-main"
					data-testid="patient-card-main"
					sx={
						galaxyDvPatientChartRefactor
							? {
									'--main-height': `calc(var(--main-content-height) - 62px)`,
									transition: 'height 0.3s ease-in-out',
									width: '100%',
									height: `max(var(--main-height), 0px)`,
									maxHeight: `max(var(--main-height), 0px)`,
									...(isSelected && isShowMain ? {} : { display: 'none' }),
							  }
							: STYLES.MAIN_CONT({
									isSelected,
									isHover,
									rootHeight: galaxyDvGlobalNotes ? rootHeight : rootHeight + 30,
							  })
					}
				>
					{Component && (
						<Component
							handleUploadDocument={handleUploadDocument}
							isExpandMain={isShowMain}
							isHover={isHover}
							isSelected={isSelected}
							rootHeight={rootHeight}
							setCollapsed={handleCollapse}
						/>
					)}
				</Box>
			)}
		</Box>
	);
};

export default PatientCard;

const TITLE_ICON_STYLE = {
	height: '18px',
	borderRadius: '50%',
	fontFamily: 'ROBOTO',
	fontSize: '11px',
	lineHeight: 1.5,
	color: '#fff',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	background: '#272727',
	marginLeft: '10px',
};

const STYLES = {
	CONTAINER: ({ color, isSelected, isHover }) => ({
		borderRadius: isSelected || isHover ? '10px' : '10px 10px 0 0',
		background: '#393939',
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
		borderTop: `2px solid ${color}`,
	}),
	TITLE: {
		height: '30px',
		fontFamily: 'ROBOTO',
		color: '#fff',
		textAlign: 'center',
		fontWeight: '500',
		fontSize: '12px',
		lineHeight: 1.5,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
	},
	COUNT_BATCH: {
		...TITLE_ICON_STYLE,
		minWidth: '18px',
	},
	USER_BATCH: {
		...TITLE_ICON_STYLE,
		width: '18px',
		'& svg': {
			fontSize: '14px',
		},
	},
	MAIN_CONT: ({ isSelected, isHover, rootHeight }) => ({
		// height: !isSelected && isHover ? `${rootHeight}px` : isSelected ? `298px` : '268px',
		height: (() => {
			let heightValue;
			if (!isSelected && isHover) {
				heightValue = rootHeight <= 360 ? 360 : rootHeight; // if all cards are collapsed set min height on hover
			} else if (isSelected) {
				heightValue = rootHeight - 62;
			} else {
				heightValue = rootHeight - 92;
			}
			return `${Math.max(heightValue, 0)}px`;
		})(),
		transition: 'height 0.3s ease-in-out',
		...(!isSelected ? { overflow: 'hidden' } : {}),
	}),
};
