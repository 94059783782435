import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import ImageViewerViewportScrollbar from './components/ImageViewerViewport/ImageViewerViewportScrollbar';
import { getInstanceFPS } from './utils/utils';
import ImageViewerViewport from './components/ImageViewerViewport/ImageViewerViewport';
import ImageViewerEmptyViewport from './ImageViewerEmptyViewport';
import { useImageViewerWheelContext } from './contexts/ImageViewerWheelContext';
import { useImageViewerLayoutContext } from './contexts/ImageViewerLayoutContext';
import ImageViewerCineControl from './features/StackViewports/ImageViewerCineControl';
import { WindowLevelPresetMenu } from './features/WindowLevel/WindowLevelPresetMenu';
import { ShutterToolPresetMenu, ShutterToolsNames } from './components/ShutterToolPresetMenu/ShutterToolPresetMenu';
import { ZoomPanObserver } from './components/ZoomPanObserver/ZoomPanObserver';
import { Droppable } from './components/DragDropComponents/Droppable';
import { VIEWPORT } from './consts/dragDropRules';
import getDefaultRenderingEngine from './cornerstone/getDefaultRenderingEngine';
import PropTypes from 'prop-types';
import { PanPresets } from './components/ZoomPanObserver/PanPresets';
import { RotatePresets } from './components/ZoomPanObserver/RotatePresets';
import useWheel from './hooks/useWheel';
import { getImageIds } from './features/StackViewports/useAddOnViewportEnabled';
import { useImageViewerLayoutStoreSelector } from './features/CustomToolbar/context/zustand/ImageViewerLayoutZustandProvider';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const DOUBLE_CLICK_DURATION = 200;

export const getFPS = metadata => {
	const defaultFPS = 3;

	if (!metadata) {
		return defaultFPS;
	}

	const frameTime = Number(metadata?.['00181063']?.Value?.[0]);

	if (!frameTime || Number.isNaN(frameTime) || frameTime <= 0) {
		const instanceFPS = Number(getInstanceFPS(metadata));

		if (!instanceFPS || Number.isNaN(instanceFPS)) {
			return defaultFPS;
		}

		return instanceFPS;
	}

	return Math.floor(1000 / frameTime);
};

export const isEmptyViewport = ({ wonIvBlankvptile, layoutItem, viewportIndex }) => {
	if (wonIvBlankvptile) {
		if (!layoutItem?.series?.imageIds?.length) {
			return true;
		}

		if (!layoutItem?.viewports?.length) {
			return true;
		}

		const imageIds = getImageIds({
			siblingViewports: layoutItem.viewports,
			viewportIndex,
			series: layoutItem.series,
			isKeyImage: layoutItem.isKeyImage,
			keyImageIndex: layoutItem.keyImageIndex,
		});

		return !imageIds?.length;
	}
	return !layoutItem?.series;
};

const ImageViewerLayoutItem = ({ layoutItem, CornerstoneViewport, disabledViewportDoubleClick }) => {
	const wonIvSeamlessviewports = useBooleanFlagValue('WON-IV-SEAMLESSVIEWPORTS');
	const wonIvBlankvptile = useBooleanFlagValue('WON-IV-BLANKVPTILE');
	const wonIvCustomtoolbar = useBooleanFlagValue('WON-IV-CUSTOMTOOLBAR');
	const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
	const [isShownWindowLevelPresetMenu, setIsShownWindowLevelPresetMenu] = useState(false);
	const [isShownZoomPresetMenu, setIsShownZoomPresetMenu] = useState(false);
	const [isShownPanPresetMenu, setIsShownPanPresetMenu] = useState(false);
	const [isShownPlanarRotatePresetMenu, setIsShownPlanarRotatePresetMenu] = useState(false);
	const [isShutterToolPresetMenu, setIsShutterToolPresetMenu] = useState(false);
	const { activeViewportId, isMPRView, isFusionView } = useImageViewerLayoutContext();
	const { activeCine, activeTool } = useImageViewerWheelContext();

	const activeCineZustand = useImageViewerLayoutStoreSelector(state => state.activeCine);

	const doubleClickedRef = useRef(false);
	const timeoutRef = useRef();
	const listenerRef = useRef(null);

	const { canShowWheel } = useWheel();

	const cineItem = [...(wonIvCustomtoolbar ? activeCineZustand : activeCine)].find(
		item => item.id === layoutItem.viewports?.[0]?.id
	);

	const activeToolsList = ['WindowLevel', 'Zoom', 'Pan', 'PlanarRotate', ...ShutterToolsNames];

	const renderingEngine = getDefaultRenderingEngine();

	if (!renderingEngine) {
		return;
	}

	const handleMouseDown = e => {
		if (activeViewportId !== layoutItem.viewports[0].id || !canShowWheel) {
			return;
		}

		if (activeToolsList.includes(activeTool) && e.button !== 2) {
			setCursorPosition({
				x: e.clientX,
				y: e.clientY,
			});
		}
	};

	const handleMouseUp = e => {
		if (e.button === 2) {
			return;
		}

		clearTimeout(timeoutRef.current);

		timeoutRef.current = setTimeout(() => {
			if (doubleClickedRef.current) {
				doubleClickedRef.current = false;
				return;
			}

			if (activeTool === 'WindowLevel') {
				setIsShownWindowLevelPresetMenu(e.clientX === cursorPosition.x && e.clientY === cursorPosition.y);
			} else if (activeTool === 'Zoom') {
				setIsShownZoomPresetMenu(e.clientX === cursorPosition.x && e.clientY === cursorPosition.y);
			} else if (activeTool === 'Pan') {
				setIsShownPanPresetMenu(e.clientX === cursorPosition.x && e.clientY === cursorPosition.y);
			} else if (activeTool === 'PlanarRotate') {
				setIsShownPlanarRotatePresetMenu(e.clientX === cursorPosition.x && e.clientY === cursorPosition.y);
			} else if (ShutterToolsNames.includes(activeTool)) {
				setIsShutterToolPresetMenu(e.clientX === cursorPosition.x && e.clientY === cursorPosition.y);
			}
		}, DOUBLE_CLICK_DURATION);
	};

	const handleDoubleClick = () => {
		doubleClickedRef.current = true;
	};

	return (
		<Box
			ref={listenerRef}
			data-listener="ImageViewerLayoutItem"
			data-testid="ImageViewerLayoutItem"
			display="flex"
			flex={1}
			flexWrap="wrap"
			gap={wonIvSeamlessviewports ? '0' : '10px'}
			height="100%"
			position="relative"
			sx={{
				display: 'grid',
				gridTemplateRows: `repeat(${layoutItem.columns || 1}, 1fr)`,
				gridTemplateColumns: `repeat(${layoutItem.rows || 1}, 1fr)`,
			}}
			onDoubleClick={handleDoubleClick}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
		>
			{layoutItem.viewports.map((viewport, index) => (
				<Box
					key={viewport.id}
					borderRadius="10px"
					position="relative"
					sx={{
						height: '100%',
						width: '100%',
					}}
				>
					<Droppable
						data={{ type: VIEWPORT, layoutItem, layoutItemId: layoutItem.id }}
						style={{ width: 'unset', height: '100%' }}
					>
						{isEmptyViewport({ wonIvBlankvptile, layoutItem, viewportIndex: index }) ? (
							<ImageViewerEmptyViewport viewport={viewport} />
						) : (
							<ImageViewerViewport
								CornerstoneViewport={CornerstoneViewport}
								disabledViewportDoubleClick={disabledViewportDoubleClick}
								index={index}
								layoutItem={layoutItem}
								viewport={viewport}
							/>
						)}
					</Droppable>
				</Box>
			))}

			{!layoutItem.hideScrollbar && layoutItem.series?.imageIds?.length && (
				<ImageViewerViewportScrollbar
					key={layoutItem.id}
					imageIds={layoutItem.series.imageIds}
					initialInstanceIndex={layoutItem.initialInstanceIndex + 1}
					viewportId={layoutItem.viewports[0].id}
					viewportsCount={layoutItem.viewports.length}
				/>
			)}

			{cineItem && layoutItem.series?.imageIds?.length && (
				<ImageViewerCineControl
					cineItem={cineItem}
					imageIds={layoutItem.series.imageIds}
					initialFPS={getFPS(layoutItem?.series?.metadata?.[0])}
					initialInstanceIndex={layoutItem.initialInstanceIndex + 1}
					viewportId={layoutItem.viewports[0].id}
				/>
			)}

			<WindowLevelPresetMenu
				isOpen={activeTool === 'WindowLevel' && isShownWindowLevelPresetMenu}
				position={{ x: cursorPosition.x, y: cursorPosition.y }}
				series={layoutItem?.series}
				setIsShownWindowLevelPresetMenu={setIsShownWindowLevelPresetMenu}
				viewportId={
					(isMPRView || isFusionView) && layoutItem.viewports?.[0]?.id
						? layoutItem?.viewports?.[0].id
						: activeViewportId
				}
				onClose={() => setIsShownWindowLevelPresetMenu(false)}
			/>

			<ZoomPanObserver
				isOpen={activeTool === 'Zoom' && isShownZoomPresetMenu}
				position={{
					x: cursorPosition.x,
					y: cursorPosition.y,
				}}
				viewportId={activeViewportId}
				onClose={() => setIsShownZoomPresetMenu(false)}
			/>

			<PanPresets
				isOpen={activeTool === 'Pan' && isShownPanPresetMenu}
				position={{
					x: cursorPosition.x,
					y: cursorPosition.y,
				}}
				viewportId={activeViewportId}
				onClose={() => setIsShownPanPresetMenu(false)}
			/>

			<RotatePresets
				isOpen={activeTool === 'PlanarRotate' && isShownPlanarRotatePresetMenu}
				position={{
					x: cursorPosition.x,
					y: cursorPosition.y,
				}}
				viewportId={activeViewportId}
				onClose={() => setIsShownPlanarRotatePresetMenu(false)}
			/>

			<ShutterToolPresetMenu
				activeViewportId={activeViewportId}
				isOpen={isShutterToolPresetMenu}
				position={{
					x: cursorPosition.x,
					y: cursorPosition.y,
				}}
				onClose={() => setIsShutterToolPresetMenu(false)}
			/>
		</Box>
	);
};

export default ImageViewerLayoutItem;

ImageViewerLayoutItem.propTypes = {
	layoutItem: PropTypes.shape({
		id: PropTypes.string.isRequired,
		hideScrollbar: PropTypes.bool,
		initialInstanceIndex: PropTypes.number,
		rows: PropTypes.number,
		columns: PropTypes.number,
		series: PropTypes.object,
		viewports: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
			})
		).isRequired,
	}).isRequired,
};
