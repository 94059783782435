// @flow
// core
import React, { useCallback, useEffect, useMemo, useState, type Node } from 'react';
// components
import HelpModal, { HelpModalContent } from '../Help/HelpModal';
import HyperlinkButton from '../HyperlinkButton/HyperlinkButton';
import LanguageModal from '../LanguageSelect/LanguageModal';
import PrivacyModal from '../PrivacyPolicy/PrivacyPolicyModal';
import TermsModal from '../PrivacyPolicy/TermsModal';
import RegionModal from '../RegionSelect/RegionModal';
import MobileBottomDrawer from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import MobileTermsDrawer from './components/MobileTermsDrawer';
import MobilePrivacyDrawer from './components/MobilePrivacyDrawer';
import MobileLanguageDrawer from './components/MobileLanguageDrawer';
import MobileRegionDrawer from './components/MobileRegionDrawer';
// libs
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Backdrop, Box, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
// uils
import { useModal, useSelector, breakpoints, useIsMobile, useIsTablet } from '@worklist-2/core/src';
import { useBooleanFlagValue, useStringFlagValue } from '@rs-core/hooks/useFlags';

interface IMainFooterProps {
	style: Record<string, any>;
	linkStyle: Record<string, any>;
	loginMode: boolean;
}

export const MainFooter = ({ style, linkStyle, loginMode }: IMainFooterProps): Node => {
	// ==================== State ====================
	const [isMenuItemsContainerOpen, setIsMenuItemsContainerOpen] = useState(false);
	const [mobileDrawer, setMobileDrawer] = useState();
	const [mobileDrawerProps, setMobileDrawerProps] = useState({});
	const [selectedItem, setSelectedItem] = useState(0);

	// ==================== Hooks ====================
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const { t } = useTranslation('login');
	const { modal, openModal, closeModal } = useModal();
	const { region, regions, language, languages } = useSelector();
	const sprinterOaibreezeblumeFooterHelp = useBooleanFlagValue('sprinter-oaibreezeblume-footer-help');
	const sprinterOaibreezeblumeFooterGeography = useBooleanFlagValue('sprinter-oaibreezeblume-footer-geography');
	const sprinterAllDuallaunchpageTheme = useStringFlagValue('sprinter-all-duallaunchpage-theme');
	const isSmall = useMediaQuery(theme => theme.breakpoints.down(breakpoints.tablet + 1));

	const containerHeight = isSmall && isMenuItemsContainerOpen ? 127 : 0;
	const selectedCountry = regions.find(({ code }) => code == region)?.label.toUpperCase();
	const selectedLanguage = languages.find(({ code }) => code == language)?.name.toUpperCase();

	const menuItems = useMemo(() => {
		const footerMenu = [
			{ id: 'terms', text: 'TERMS OF SERVICE' },
			{ id: 'privacy', text: 'PRIVACY POLICY' },
		];

		if (sprinterOaibreezeblumeFooterHelp) {
			footerMenu.push({ id: 'help', text: 'HELP' });
		}

		footerMenu.push({
			id: 'language',
			text: selectedLanguage || 'LANGUAGE',
		});

		if (sprinterOaibreezeblumeFooterGeography) {
			footerMenu.push({ id: 'region', text: selectedCountry || 'REGION' });
		}

		return footerMenu;
	}, [selectedCountry, selectedLanguage]);

	// ==================== Effects ====================
	useEffect(() => {
		setIsMenuItemsContainerOpen(false);
	}, [isSmall, !!modal]);

	useEffect(() => {
		if (!mobileDrawer) {
			setMobileDrawerProps({});
		}
	}, [mobileDrawer]);

	useEffect(() => {
		if (modal === null) {
			let item = null;
			if (window.location.hash.includes('modal=terms')) {
				item = 'terms';
			} else if (window.location.hash.includes('modal=privacy')) {
				item = 'privacy';
			}

			if (isMobile) {
				const DrawerComponent = {
					terms: MobileTermsDrawer,
					privacy: MobilePrivacyDrawer,
				}[item];

				if (DrawerComponent) {
					setMobileDrawer(<DrawerComponent setMobileDrawerProps={setMobileDrawerProps} />);
				}
			} else {
				const ModalComponent = {
					terms: TermsModal,
					privacy: PrivacyModal,
				}[item];
				if (ModalComponent) {
					openModal(<ModalComponent open onClose={closeModal} />);
				}
			}
		}
	}, [window.location.hash, isMobile]);

	// ==================== Events ====================
	const onLinkClick = useCallback(e => {
		e.preventDefault();

		setSelectedItem(menuItems.findIndex(elem => elem.id === e.target.id));

		if (isMobile) {
			const DrawerComponent = {
				terms: MobileTermsDrawer,
				privacy: MobilePrivacyDrawer,
				help: HelpModalContent,
				language: MobileLanguageDrawer,
				region: MobileRegionDrawer,
			}[e.target.id];

			if (DrawerComponent) {
				setMobileDrawer(
					<DrawerComponent setMobileDrawer={setMobileDrawer} setMobileDrawerProps={setMobileDrawerProps} />
				);
			}

			if (isMenuItemsContainerOpen) {
				onToggleIsMenuItemsContainerOpen();
			}

			return;
		}

		if (['terms', 'privacy'].includes(e.target.id)) {
			window.location.hash = `introduction?blur=true&modal=${e.target.id}`;
		}

		const ModalComponent = {
			terms: TermsModal,
			privacy: PrivacyModal,
			help: HelpModal,
			language: LanguageModal,
			region: RegionModal,
		}[e.target.id];

		if (ModalComponent) {
			openModal(<ModalComponent open onClose={closeModal} />);
		}
	});

	const onToggleIsMenuItemsContainerOpen = useCallback(() => {
		setIsMenuItemsContainerOpen(prev => !prev);
	}, []);

	const getHyperLinkList = items => {
		items.splice(selectedItem, 1);

		return items;
	};

	const getBackGroundColor = () => {
		let color = 'rgba(255,255,255,.6)';

		if (!loginMode) {
			switch (sprinterAllDuallaunchpageTheme) {
				case '2022RSNAeBlast':
				case '2022RSNA':
				case '2022RadiologicTechnologyWeek':
				case '2022RemembranceDay':
				case '2022ChristmasBoxing':
				case '2023NewYear':
					color = 'rgba(0,0,0,.6)';
					break;

				case '2023IRIA':
					if (isMobile || isTablet) {
						color = 'rgba(137, 134, 134, 0.6)';
						break;
					}
					color = 'rgba(255,255,255,.6)';
					break;

				case '2023LunarNewYear':
					color = 'rgba(123, 17, 17)';
					break;

				default:
					color = 'rgba(255,255,255,.6)';
					break;
			}
		}

		return color;
	};

	const renderHyperLinkButton = props => (
		<HyperlinkButton
			key={props.id}
			id={props.id}
			link={props.id}
			style={linkStyle}
			text={t(props.text)}
			onClick={onLinkClick}
		/>
	);

	return (
		<>
			{isMobile ? (
				<MobileBottomDrawer
					ModalProps={{
						BackdropProps: {
							invisible: true,
						},
					}}
					background="rgba(255, 255, 255, 0.6)"
					open={Boolean(mobileDrawer)}
					sx={sx.mobileBottomDrawer}
					onClose={() => setMobileDrawer(undefined)}
					{...mobileDrawerProps}
				>
					<Box sx={sx.mobileDrawer}>{mobileDrawer}</Box>
				</MobileBottomDrawer>
			) : (
				modal
			)}

			<Backdrop open={isMenuItemsContainerOpen} sx={sx.backdrop} onClick={onToggleIsMenuItemsContainerOpen} />

			<footer style={{ ...sx.footer(isSmall), ...style }}>
				<Box sx={sx.footerBox}>
					{isSmall && (
						<Box
							sx={{
								...sx.linkListBox,
								top: `-${containerHeight ? 32 * (menuItems.length - 1) : 0}px`,
								height: `${containerHeight ? 32 * (menuItems.length - 1) : 0}px`,
								background: getBackGroundColor(),
							}}
						>
							{getHyperLinkList(menuItems.slice(0, menuItems.length)).map(renderHyperLinkButton)}
						</Box>
					)}

					<Box sx={sx.bottomBox}>
						{isSmall ? (
							<Box sx={sx.renderLinkBox}>
								{renderHyperLinkButton(menuItems[selectedItem])}

								{isSmall && (
									<Box
										sx={{ ...sx.KeyboardArrowDownBox, ...linkStyle }}
										onClick={onToggleIsMenuItemsContainerOpen}
									>
										<KeyboardArrowDown sx={sx.KeyboardArrowDown(isMenuItemsContainerOpen)} />
									</Box>
								)}
							</Box>
						) : (
							menuItems.map(renderHyperLinkButton)
						)}
					</Box>
				</Box>
			</footer>
		</>
	);
};

const sx = {
	mobileBottomDrawer: {
		backdropFilter: 'blur(10px)',
		boxShadow: 'unset',
		minHeight: '450px',
		marginBottom: '50px',
		'& .MobileBottomDrawerHandleWrapper': {
			background: 'unset',
		},
	},
	mobileDrawer: { marginBottom: '30px' },

	backdrop: { zIndex: 9998, background: 'transparent' },

	footer: (isSmall: boolean) => ({
		height: isSmall ? '30px' : '50px',
		width: '100%',
		position: 'fixed',
		bottom: '0',
		backgroundColor: 'rgba(255,255,255,.6)',
		zIndex: 9999,

		':after': {
			opacity: '0.6',
		},
	}),
	footerBox: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		position: 'relative',
	},
	linkListBox: {
		display: 'flex',
		flexDirection: 'column',
		position: 'absolute',
		width: '100%',
		alignItems: 'center',
		backdropFilter: '4px',
		transition: 'height .3s ease, top .3s ease',
		overflow: 'hidden',

		'& > *': {
			margin: '7px !important',
		},
		'& > *:first-of-type': {
			marginTop: '14px !important',
		},
	},

	bottomBox: {
		'& > .hyperlink:not(:first-of-type)': {
			ml: '30px',
		},
	},
	renderLinkBox: {
		paddingLeft: '60px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},

	KeyboardArrowDownBox: {
		width: '60px',
		display: 'inline-flex',
		height: '30px',
		paddingBottom: '2px',
		cursor: 'pointer',
		boxSizing: 'border-box',
		paddingRight: '16px',
	},
	KeyboardArrowDown: (isMenuItemsContainerOpen: boolean) => ({
		fontSize: '20px',
		margin: 'auto',
		transition: 'transform .3s ease',
		transform: `rotate(${isMenuItemsContainerOpen ? 180 : 0}deg)`,
	}),
};
