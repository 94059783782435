import React from 'react';

const SeparateBlocksIcon = () => (
	<svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_34906)">
			<path
				d="M7.335 8.37L6.27 9.435C5.76 8.9175 5.265 8.25 4.9275 7.23L6.3825 6.8625C6.6225 7.53 6.96 7.9875 7.335 8.37ZM8.25 4.5L5.25 1.5L2.25 4.5H4.515C4.53 5.1075 4.575 5.655 4.6575 6.1275L6.1125 5.76C6.06 5.4 6.0225 4.9725 6.015 4.5H8.25ZM15.75 4.5L12.75 1.5L9.75 4.5H11.9925C11.9175 7.26 11.0325 8.0625 10.0875 8.91C9.7125 9.24 9.33 9.6 9 10.0725C8.745 9.705 8.4525 9.4125 8.1525 9.1425L7.095 10.2C7.7925 10.8375 8.25 11.355 8.25 12.75V16.5H9.75V12.75C9.75 11.235 10.2825 10.755 11.0925 10.0275C12.1275 9.0975 13.4025 7.9425 13.4925 4.5H15.75Z"
				fill="#C4C4C4"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2852_34906">
				<rect fill="white" height="18" width="18" />
			</clipPath>
		</defs>
	</svg>
);

export default SeparateBlocksIcon;
