import React from 'react';

import ColorPicker from './ColorPicker';

import circleColors from './circleColors';

const ColorDrawer = ({
	editor,
	activeColor,
	activeBackgroundColor,
	isShownColorPicker,
	isShownBackgroundColorPicker,
}) => {
	const handleChangeColor = ({ hex }) => {
		editor.chain().focus().setColor(hex).run();
	};

	const handleChangeBackgroundColor = ({ hex }) => {
		editor.chain().focus().setBackgroundColor(hex).run();
	};

	return (
		<div>
			{isShownColorPicker && (
				<ColorPicker
					activeColor={activeColor?.value}
					circleColors={circleColors}
					onChangeComplete={handleChangeColor}
				/>
			)}

			{isShownBackgroundColorPicker && (
				<ColorPicker
					activeColor={activeBackgroundColor?.value}
					circleColors={circleColors}
					onChangeComplete={handleChangeBackgroundColor}
				/>
			)}
		</div>
	);
};

export default ColorDrawer;
