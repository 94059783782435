export const CODES = {
	NO_VALID_INSTANCES: 'NO_VALID_INSTANCES', 
	NO_POSITION_INFORMATION: 'NO_POSITION_INFORMATION',
	RECONSTRUCTABLE: 'RECONSTRUCTABLE',
	NOT_RECONSTRUCTABLE: 'NOT_RECONSTRUCTABLE',
	MULTIFRAME_NO_PIXEL_MEASUREMENTS: 'MULTIFRAME_NO_PIXEL_MEASUREMENTS',
	MULTIFRAME_NO_ORIENTATION: 'MULTIFRAME_NO_ORIENTATION',
	MULTIFRAME_NO_POSITION_INFORMATION: 'MULTIFRAME_NO_POSITION_INFORMATION',
	MISSING_FRAMES: 'MISSING_FRAMES', // reconstructionIssues
	IRREGULAR_SPACING: 'IRREGULAR_SPACING', // reconstructionIssues
	INCONSISTENT_DIMENSIONS: 'INCONSISTENT_DIMENSIONS',
	INCONSISTENT_COMPONENTS: 'INCONSISTENT_COMPONENTS',
	INCONSISTENT_ORIENTATIONS: 'INCONSISTENT_ORIENTATIONS',
	INCONSISTENT_POSITION_INFORMATION: 'INCONSISTENT_POSITION_INFORMATION',
	UNSUPPORTED_DISPLAYSET: 'UNSUPPORTED_DISPLAYSET',
};
