import extensionUrls from '../../extension/fhirExtensionUrls';
import getUserFullName from '@rs-core/fhir/resource/columnMapping/utils/getUserFullName';
import getCurrentTime from '@rs-core/fhir/resource/columnMapping/utils/getCurrentTime';
import getCurrentDate from '@rs-core/fhir/resource/columnMapping/utils/getCurrentDate';

const DocumentReferenceMapping = {
	id: {
		getDisplay: resource => {
			return resource?.id;
		},
	},
	reportName: {
		getDisplay: resource => {
			return resource?.content?.[0]?.attachment?.title;
		},
	},
	reportUrl: {
		getDisplay: resource => {
			return resource?.content?.[0]?.attachment?.url;
		},
	},
	resourceType: {
		getDisplay: resource => {
			return resource?.resourceType;
		},
	},
	date: {
		getDisplay: (resource, timeZone = '') => {
			let value = resource?.content?.[0]?.attachment?.creation;
			if (value != null) {
				return getCurrentDate(value, timeZone) + ' ' + getCurrentTime(value, timeZone);
			}
		},
	},
	reportType: {
		getDisplay: resource => {
			return resource?.context?.encounter?.[0]?.id ? 'visit' : 'patient';
		},
	},
	reportTypeLabel: {
		getDisplay: resource => {
			return resource?.context?.encounter?.[0]?.id ? 'Visit Document' : 'Patient Document';
		},
	},
	status: {
		getDisplay: resource => {
			return resource?.status;
		},
	},
	docStatus: {
		getDisplay: resource => {
			return resource?.docStatus;
		},
	},
	isCritical: {
		getDisplay: resource => {
			const resObj = resource?.extension?.find(item => item.url === extensionUrls.diagnosisReport.isCritical);
			return resObj?.valueBoolean || false;
		},
	},
	dateTimeUpdated: {
		getDisplay: (resource, timeZone = '') => {
			let value = resource.meta?.lastUpdated;
			if (value != null) {
				return getCurrentDate(value, timeZone) + ' ' + getCurrentTime(value, timeZone);
			}
		},
	},
	lastUpdatedUser: {
		getDisplay: resource => {
			const resObj = resource?.meta?.extension?.find(item => item.url === extensionUrls.common.lastUpdateUser);
			return getUserFullName(resObj?.valueReference?.display);
		},
	},
};

export default DocumentReferenceMapping;
