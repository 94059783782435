// React Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI Libraries
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack } from '@mui/material';

// Custom Component Libraries
import GroupReportsCard from './components/GroupReportsCard';
import ReportsCard from './components/ReportsCard';
import ReportFilter from './components/ReportFilter';
import ReportLayout from './components/ReportLayout';

function AllReports({
	fetchDeletableReport,
	reports,
	checkedReports,
	setCheckedReports,
	reportOnClick,
	onSaveFavorite,
	setDeleteModal,
	sortMethod,
	onOpenShare,
}) {
	const isSmallScreen = useMediaQuery('(max-width:1100px)');
	const isHdMonitor = useMediaQuery('(max-width:1920px)');
	const [isOrgMenuOpen, setIsOrgMenuOpen] = useState(false);
	const [isLayoutOpen, setIsLayoutOpen] = useState(false);

	return (
		<Box
			sx={{
				padding: isSmallScreen ? '32px 60px' : isHdMonitor ? '32px 85px' : '32px 107px',
				width: '1500px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					mb: '20px',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						color: '#FFFFFF',
						gap: '8px',
					}}
				>
					<DescriptionIcon sx={{ fontSize: '18px', color: '#4D79EA' }} />
					<Typography
						sx={{
							font: 'normal normal normal 24px/32px Roboto',
							color: '#4D79EA',
							fontSize: '18px',
							fontWeight: 500,
						}}
					>
						All Reports
					</Typography>
					<Box
						sx={{
							padding: '4px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: 'rgba(0, 0, 0, 0.6)',
							fontSize: '18px',
							fontWeight: 500,
							width: '27px',
							height: '27px',
							background: 'rgba(196, 196, 196, 0.35)',
							borderRadius: '6px',
						}}
					>
						{reports.length}
					</Box>
				</Box>
			</Box>

			<Box display="grid" gap="22px" gridTemplateColumns="repeat( auto-fit, 350px)">
				{reports.map((report, index) => (
					<Box key={report.id}>
						{report.isGroup ? (
							<GroupReportsCard
								checkedReports={checkedReports}
								report={report}
								setCheckedReports={setCheckedReports}
							/>
						) : (
							<ReportsCard
								checkedReports={checkedReports}
								fetchDeletableReport={fetchDeletableReport}
								report={report}
								setCheckedReports={setCheckedReports}
								setDeleteModal={setDeleteModal}
								onOpenShare={onOpenShare}
								onSaveFavorite={onSaveFavorite}
							/>
						)}
					</Box>
				))}
			</Box>
		</Box>
	);
}

AllReports.propTypes = {
	/**
	 * reports is array of all reports
	 */
	reports: PropTypes.array,
	/**
	 * checkedReports is array of index to get the checked reports
	 */
	checkedReports: PropTypes.array,
	/**
	 * setCheckedReports is function to set checked reports array
	 */
	setCheckedReports: PropTypes.func,
	/**
	 * onDeleteReport is function to delete report
	 */
	onDeleteReport: PropTypes.func,
	/**
	 * onSaveFavorite is function to set favorite report
	 */
	onSaveFavorite: PropTypes.func,
	/**
	 * sortMethod is function to sort the report
	 */
	sortMethod: PropTypes.func,
	/**
	 * onOpenShare is function to share the report
	 */
	onOpenShare: PropTypes.func,
};

export default AllReports;
