import React, { useState, useEffect } from 'react';
import { useCrmContext } from '../../../../../context/CRMContext';
import { Box, TextField, IconButton, Select, MenuItem } from '@mui/material';
import { TabPanel, Wrapper } from '../../../Cases/casePopOverStyles';
import { useAuth, useConfig } from '@worklist-2/core/src';
import _ from 'lodash';
import 'react-phone-input-2/lib/material.css';
import LastPageIcon from '@mui/icons-material/LastPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BusinessIcon from '@mui/icons-material/Business';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { licenseApplicationNameValues } from './Device/utils';

export const LicenseBox = ({ children }) => (
	<Box
		sx={{
			'.handle': {
				backgroundColor: 'rgba(77, 121, 234, 0.03)',
				padding: '8px 18px',
				display: 'flex',
				flexDirection: 'row',

				span: {
					fontFamily: 'Inter',
					fontSize: '14px',
					fontWeight: '500',
					fontStretch: 'normal',
					fontStyle: 'normal',
					lineHeight: 'normal',
					letterSpacing: 'normal',
					textAlign: 'left',
					color: 'rgba(0, 0, 0, 0.6)',
				},
			},
		}}
	>
		{children}
	</Box>
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.white,
		borderWidth: 0,
		color: '#666666',
		fontWeight: '500',
		fontSize: 12,
		padding: '2px 2px 2px 2px',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		borderWidth: 0,
		color: theme.palette.common.black,
		padding: '2px 2px 2px 2px',
	},
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&': {
		color: theme.palette.common.black,
	},
	'&:hover td': {
		backgroundColor: '#C8D6FF',
	},
	'& td': {
		padding: 10,
	},
	'&:nth-of-type(odd)': {
		backgroundColor: '#F9FBFF',
	},
	'&:nth-of-type(even)': {
		backgroundColor: theme.palette.common.white,
	},
	'&.transparent:nth-of-type(odd)': {
		backgroundColor: theme.palette.common.white,
	},
	'&.transparent:hover td': {
		backgroundColor: `${theme.palette.common.white} !important`,
	},
}));

const License = ({
	accountData,
	orgData,
	setOrganizationData,
	setStep,
	inAccountFullscreen,
	setShowLicenseDrawer,
	setLicensesAccountFullScreen,
	isSimpleSidebar,
}) => {
	const [licenses, setLicenses] = useState([]);
	const [deleteLicenses, setDeleteLicenses] = useState([]);
	const { toggleDetails, setCurrentFeatures } = useCrmContext();
	const [editingIndex, setEditingIndex] = useState(null);
	const [indexLicense, setIndexLicense] = useState(0);
	const [indexHover, setIndexHover] = useState(null);
	const [loading, setLoading] = useState(false);
	const numericRegex = /\d/;
	const { authorized } = useAuth();
	const __config = useConfig();

	const organizationLicenseForm = {
		textFields: [
			{
				name: 'applicationName',
				widthTextField: '90px',
				width: '90px',
			},
			{
				name: 'licenseType',
				widthTextField: '80px',
				width: '80px',
			},
			{
				name: 'numberOfLicense',
				widthTextField: '70px',
				width: '70px',
			},
			{
				name: 'assignedLicense',
				widthTextField: '70px',
				width: '70px',
			},
			{
				name: 'avaiableLicense',
				widthTextField: '60px',
				width: '80px',
			},
		],
		numericFields: ['numberOfLicense', 'assignedLicense', 'avaiableLicense'],
		disabledApplication: ['pVerify Eligibility', 'Prior Auth'],
	};

	useEffect(() => {
		async function asynchronousEffect() {
			if (authorized) {
				await getOrganizationLicense().catch(console.error);
			}
		}

		asynchronousEffect();
	}, [authorized, orgData.id]);

	const getOrganizationLicense = async loader => {
		setLoading(true);

		const { data } = await axios.get(
			`${__config.data_sources.breeze}/OrganizationLicenseOmegaAI/${accountData?.AccountId}?organization=${orgData.id}`
		);

		if (data.entry && data.entry.length > 0) {
			const processedValues = _.map(data.entry, elem => elem.resource);
			setLicenses(processedValues ?? []);
		}

		setLoading(false);
	};

	const inputChangeHandler = async (e, i) => {
		let result = licenses;

		if (
			e.target.name === 'applicationName' &&
			organizationLicenseForm.disabledApplication.includes(e.target.value)
		) {
			setLoading(true);
			result = result?.map(license =>
				license.id === i
					? {
							...license,
							[e.target.name]: e.target.value,
							licenseType: '',
							numberOfLicense: '',
							assignedLicense: '',
							avaiableLicense: '',
					  }
					: { ...license }
			);
			setLicenses(result);
			await new Promise(resolve => {
				setTimeout(resolve, 200);
			});
			setLoading(false);
		} else {
			result = licenses?.map(license =>
				license.id === i ? { ...license, [e.target.name]: e.target.value } : { ...license }
			);
			setLicenses(result);
		}
	};

	const addNewLicenseHandler = () => {
		if (editingIndex) {
			return;
		}

		const newData = {
			id: indexLicense,
			organization: {
				id: orgData.id,
				reference: `Organization/${orgData.id}`,
				display: orgData.name,
			},
			applicationName: licenseApplicationNameValues[0],
			licenseType: '',
			numberOfLicense: '',
			assignedLicense: '',
			avaiableLicense: '',
		};
		licenses?.unshift(newData);
		setIndexLicense(indexLicense - 1);
		setEditingIndex(newData.id);
		setLicenses(licenses);
	};

	const saveOrganizationLicense = () => {
		deleteLicenses.forEach(async (deleteId, i) => {
			await axios
				.delete(
					`${__config.data_sources.breeze}/OrganizationLicenseOmegaAI/${accountData?.AccountId}/${deleteId}`
				)
				.then(res => {
					setLicensesAccountFullScreen(prevState =>
						prevState.filter(license => license.resource.id !== deleteId)
					);
				});
		});

		licenses?.forEach(async (license, i) => {
			if (license.id < 1) {
				await axios
					.post(
						`${__config.data_sources.breeze}/OrganizationLicenseOmegaAI/${accountData?.AccountId}`,
						license
					)
					.then(res => {
						setLicensesAccountFullScreen(prevState => [...prevState, { resource: res.data }]);
					});
			}
		});

		setCurrentFeatures(null);
		inAccountFullscreen ? setShowLicenseDrawer(false) : setStep('features');
	};

	const deleteOrganizationLicense = i => {
		if (i > 0) {
			deleteLicenses.push(i);
			setDeleteLicenses(deleteLicenses);
		}
		_.remove(licenses, license => license.id === i);
		setLicenses(licenses);
	};

	const updateOrganizationLicense = i => {
		if (!licenses?.[0].applicationName) {
			_.remove(licenses, license => license.id === i);
			setLicenses(licenses);
		}

		setEditingIndex(null);
	};

	return (
		<form>
			<Wrapper className="invisible-scrollbar">
				{!inAccountFullscreen && (
					<div className="header" style={{ backgroundColor: '#FFF', padding: '5px 15px' }}>
						<div className="actions">
							<div className="left">
								<IconButton
									onClick={() => {
										setStep('organization');
									}}
								>
									<ArrowBackIcon />
								</IconButton>
							</div>
							<div className="right">
								<IconButton
									onClick={() => {
										setStep(null);
										setOrganizationData(null);
										toggleDetails();
									}}
								>
									<LastPageIcon />
								</IconButton>
							</div>
						</div>
					</div>
				)}
				<div className="body" style={{ position: 'relative' }}>
					<TabPanel
						className="invisible-scrollbar"
						style={{ margin: `${inAccountFullscreen ? '10px' : '0px'} 23px` }}
					>
						<div className="header">
							<div className="left">
								<div className="colored-icon info-icon">
									<BusinessIcon sx={{ fontSize: 20 }} />
								</div>
								<h2>Add License</h2>
							</div>
							{!inAccountFullscreen && (
								<div className="right">
									<div className="colored-icon active-icon">
										<BusinessIcon sx={{ fontSize: 10 }} />
									</div>
									<span className="active-span" />
									<div className="colored-icon active-icon">
										<CardTravelIcon sx={{ fontSize: 10 }} />
									</div>
									<span className="gray-span" />
									<div className="colored-icon gray-icon">
										<ToggleOnOutlinedIcon sx={{ fontSize: 10 }} />
									</div>
									<span className="gray-span" />
									<div className="colored-icon gray-icon">
										<DeviceHubIcon sx={{ fontSize: 10 }} />
									</div>
									<span className="gray-span" />
									<div className="colored-icon gray-icon">
										<FolderOutlinedIcon sx={{ fontSize: 10 }} />
									</div>
								</div>
							)}
						</div>
						<div className="body">
							<LicenseBox>
								<div className="handle">
									<span>License</span>
									<IconButton
										sx={{ padding: '0 0 0 0', marginLeft: '10px' }}
										onClick={addNewLicenseHandler}
									>
										<AddIcon sx={{ fontSize: 20, color: '#4D79EA' }} />
									</IconButton>
								</div>
							</LicenseBox>
							<TableContainer
								className="invisible-scrollbar"
								style={{
									border: 0,
									borderRadius: 0,
									borderTopRightRadius: '20px',
									borderTopLeftRadius: '20px',
									boxShadow: 'none',
									minHeight: '400px',
								}}
							>
								<Table stickyHeader aria-label="customized table">
									<TableHead>
										<TableRow>
											<StyledTableCell align="center" width="90px">
												Application
											</StyledTableCell>
											<StyledTableCell align="center" width="80px">
												License Type
											</StyledTableCell>
											<StyledTableCell align="center" width="70px">
												Number of License
											</StyledTableCell>
											<StyledTableCell align="center" width="70px">
												Assigned
											</StyledTableCell>
											<StyledTableCell align="center" width="90px">
												Avaiable License
											</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody style={{ flex: '1' }}>
										{!loading ? (
											licenses?.length > 0 &&
											licenses?.map((license, i) => (
												<StyledTableRow
													onMouseLeave={() => {
														setIndexHover(null);
													}}
													onMouseOver={() => {
														setIndexHover(license.id);
													}}
												>
													{organizationLicenseForm.textFields.map((item, j) =>
														editingIndex === license?.id ? (
															item.name === 'applicationName' ? (
																<Select
																	name={item.name}
																	sx={{
																		'& legend': { display: 'none' },
																		'& fieldset': {
																			top: 0,
																			border: 'none',
																		},
																		height: '47px',
																		fontSize: '12px',
																		fontStretch: 'normal',
																		fontStyle: 'normal',
																		flex: '1',
																		'.MuiSvgIcon-root': {
																			opacity: '1',
																			fontSize: '1rem',
																		},
																		'.MuiSelect-select': {
																			width: '50px',
																		},
																	}}
																	value={license.applicationName}
																	onChange={async e =>
																		await inputChangeHandler(e, license?.id)
																	}
																>
																	{licenseApplicationNameValues?.map(menuItem => (
																		<MenuItem value={menuItem}>{menuItem}</MenuItem>
																	))}
																</Select>
															) : (
																<StyledTableCell align="center">
																	<TextField
																		hiddenLabel
																		InputProps={{
																			sx: {
																				borderRadius: '8px',
																				height: '25px',
																				fontWeight: '500',
																				fontSize: 12,
																			},
																			disabled:
																				organizationLicenseForm.disabledApplication.includes(
																					license.applicationName
																				) ||
																				item.name === 'avaiableLicense' ||
																				item.name === 'assignedLicense',
																		}}
																		className="without-padding"
																		defaultValue={license[item.name]}
																		id={item.name}
																		inputProps={{ style: { textAlign: 'center' } }}
																		name={item.name}
																		size="small"
																		sx={{
																			'& legend': { display: 'none' },
																			'& fieldset': { top: 0 },
																			fontSize: '14px',
																			flex: '1',
																			width: item.widthTextField,
																		}}
																		variant="outlined"
																		onChange={async e =>
																			await inputChangeHandler(e, license?.id)
																		}
																		onKeyPress={e => {
																			if (
																				organizationLicenseForm.numericFields.includes(
																					item.name
																				) &&
																				!numericRegex.test(e.key)
																			) {
																				e.preventDefault();
																			}
																		}}
																		onPaste={e => {
																			if (
																				organizationLicenseForm.numericFields.includes(
																					item.name
																				)
																			) {
																				e.preventDefault();
																				return false;
																			}
																		}}
																	/>
																	{item.name === 'avaiableLicense' && (
																		<IconButton
																			sx={{
																				padding: '0 0 0 0',
																				display: 'inline-flex',
																				marginLeft: '10px',
																			}}
																			onClick={() =>
																				updateOrganizationLicense(license?.id)
																			}
																		>
																			<CheckIcon
																				sx={{ fontSize: 20, color: '#4D79EA' }}
																			/>
																		</IconButton>
																	)}
																</StyledTableCell>
															)
														) : indexHover === license?.id ? (
															<StyledTableCell
																align={
																	item.name === 'avaiableLicense' ? 'right' : 'center'
																}
																width={item.width}
															>
																{organizationLicenseForm.disabledApplication.includes(
																	license.applicationName
																)
																	? license[item.name] || '-'
																	: license[item.name]}
																{item.name === 'avaiableLicense' && (
																	<IconButton
																		sx={{
																			padding: '0 0 0 0',
																			display: 'inline-flex',
																			marginLeft: '15px',
																		}}
																		onClick={() =>
																			deleteOrganizationLicense(license?.id)
																		}
																	>
																		<DeleteIcon
																			sx={{ fontSize: 20, color: '#4D79EA' }}
																		/>
																	</IconButton>
																)}
															</StyledTableCell>
														) : (
															<StyledTableCell align="center" width={item.width}>
																{organizationLicenseForm.disabledApplication.includes(
																	license.applicationName
																)
																	? license[item.name] || '-'
																	: license[item.name]}
															</StyledTableCell>
														)
													)}
												</StyledTableRow>
											))
										) : (
											<StyledTableRow>
												<StyledTableCell colSpan={5}>
													<Box
														sx={{
															marginTop: '10px',
															height: '100%',
															width: '100%',
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
														}}
													>
														<Typography
															sx={{
																textAlign: 'center',
																fontSie: '16px',
																marginBottom: '8px',
																color: 'black',
															}}
														>
															<CircularProgress
																className="progressBar"
																size={22}
																sx={{
																	color: '#03dac5',
																	marginRight: '16px',
																}}
															/>
															Loading...
														</Typography>
													</Box>
												</StyledTableCell>
											</StyledTableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>
							<div>&nbsp;</div>
						</div>
					</TabPanel>
					<div className="footer" style={{ position: 'absolute', width: '490px', marginLeft: '23px' }}>
						<button
							className="outline"
							data-testid="cancelButton"
							type="button"
							onClick={() => {
								inAccountFullscreen ? setShowLicenseDrawer(false) : setStep('organization');
							}}
						>
							{inAccountFullscreen ? 'CANCEL' : 'GO BACK'}
						</button>
						<div className="right">
							<button
								className="fill"
								data-testid="saveButton"
								type="button"
								value="saveLicense"
								onClick={() => {
									saveOrganizationLicense();
								}}
							>
								SAVE
							</button>
						</div>
					</div>
				</div>
			</Wrapper>
		</form>
	);
};

export default License;
