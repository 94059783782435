import React from 'react';

import ClearFilterIcon from './assets/ClearFilterIcon';
import ClearAllFilterIcon from './assets/ClearAllFilterIcon';

import styles from './AccountsFilterPanel.module.scss';

const AccountsFilterPanel = ({ filters, onClear, onClearAllFilter }) => (
	<div className={styles.casesFilterPanel}>
		{filters.map(
			filter =>
				filter.value && (
					<div className={styles.filter}>
						<span>
							{filter.type}: {filter.value}
						</span>
						<div
							className={styles.filterIcon}
							data-testid={`btn-clear-${filter.type}-filter`}
							onClick={() => onClear(filter.type)}
						>
							<ClearFilterIcon />
						</div>
					</div>
				)
		)}
		<div className={styles.clearAllFilter} data-testid="btn-clear-all-filter" onClick={onClearAllFilter}>
			<ClearAllFilterIcon />
		</div>
	</div>
);

export default AccountsFilterPanel;
