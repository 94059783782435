// https://www.hl7.org/fhir/resourcelist.html
// we (RamSoft) may not support all of these

const fhirEndpoints = {
	// normative
	binary: 'Binary',
	bundle: '',
	capabilityStatement: 'CapabilityStatement',
	codeSystem: 'CodeSystem',
	domainResource: 'DomainResource',
	observation: 'Observation',
	operationDefinition: 'OperationDefinition',
	operationOutcome: 'OperationOutcome',
	parameters: 'Parameters',
	patient: 'Patient',
	resource: 'Resource',
	structureDefinition: 'StructureDefinition',
	studyType: 'StudyType',
	valueSet: 'ValueSet',

	// trial use
	account: 'Account',
	activityDefinition: 'ActivityDefinition',
	adverseEvent: 'AdverseEvent',
	allergyIntolerance: 'AllergyIntolerance',
	appointment: 'Appointment',
	appointmentResponse: 'AppointmentResponse',
	appointmentReserve: 'AppointmentReserve',
	availableTimeForAppointment: 'Appointment/findavailabletime',
	availableTimeForAppointmentByService: 'Appointment/findavailabletime/healthcareservice',
	auditEvent: 'AuditEvent',
	activityHistory: 'ActivityHistory',
	basic: 'Basic',
	biologicallyDerivedProduct: 'BiologicallyDerivedProduct',
	blumeForm: 'Form',
	bodyStructure: 'BodyStructure',
	carePlan: 'CarePlan',
	careTeam: 'CareTeam',
	catalogEntry: 'CatalogEntry',
	chargeItem: 'ChargeItem',
	chargeItemDefinition: 'ChargeItemDefinition',
	claim: 'Claim',
	claimResponse: 'ClaimResponse',
	clinicalImpression: 'ClinicalImpression',
	communication: 'Communication',
	communicationRequest: 'CommunicationRequest',
	compartmentDefinition: 'CompartmentDefinition',
	composition: 'Composition',
	conceptMap: 'ConceptMap',
	condition: 'Condition',
	consent: 'Consent',
	contract: 'Contract',
	coverage: 'Coverage',
	coverageEligibilityRequest: 'CoverageEligibilityRequest',
	coverageEligibilityResponse: 'CoverageEligibilityResponse',
	detectedIssue: 'DetectedIssue',
	device: 'device',
	deviceDefinition: 'DeviceDefinition',
	deviceMetric: 'DeviceMetric',
	deviceRequest: 'DeviceRequest',
	deviceUseStatement: 'DeviceUseStatement',
	diagnosticReport: 'DiagnosticReport',
	documentManifest: 'DocumentManifest',
	documentReference: 'DocumentReference',
	effectEvidenceSynthesis: 'EffectEvidenceSynthesis',
	encounter: 'Encounter',
	endpoint: 'Endpoint',
	enrollmentRequest: 'EnrollmentRequest',
	enrollmentResponse: 'EnrollmentResponse',
	episodeOfCare: 'EpisodeOfCare',
	eventDefinition: 'EventDefinition',
	evidence: 'Evidence',
	evidenceVariable: 'EvidenceVariable',
	exampleScenario: 'ExampleScenario',
	explanationOfBenefit: 'ExplanationOfBenefit',
	familyMemberHistory: 'FamilyMemberHistory',
	flag: 'Flag',
	goal: 'Goal',
	graphDefinition: 'GraphDefinition',
	group: 'Group',
	guidanceResponse: 'GuidanceResponse',
	healthcareService: 'HealthcareService',
	imagingStudy: 'ImagingStudy',
	immunization: 'Immunization',
	immunizationEvaluation: 'ImmunizationEvaluation',
	immunizationRecommendation: 'ImmunizationRecommendation',
	implementationGuide: 'ImplementationGuide',
	insurancePlan: 'InsurancePlan',
	invoice: 'Invoice',
	library: 'Library',
	linkage: 'Linkage',
	list: 'List',
	location: 'Location',
	measure: 'Measure',
	measurement: 'Measurement',
	measureReport: 'MeasureReport',
	media: 'Media',
	medication: 'Medication',
	medicationAdministration: 'MedicationAdministration',
	medicationDispense: 'MedicationDispense',
	medicationKnowledge: 'MedicationKnowledge',
	medicationRequest: 'MedicationRequest',
	medicationStatement: 'MedicationStatement',
	medicinalProduct: 'MedicinalProduct',
	medicinalProductAuthorization: 'MedicinalProductAuthorization',
	medicinalProductContraindication: 'MedicinalProductContraindication',
	medicinalProductIndication: 'MedicinalProductIndication',
	medicinalProductIngredient: 'MedicinalProductIngredient',
	medicinalProductInteraction: 'MedicinalProductInteraction',
	medicinalProductManufactured: 'MedicinalProductManufactured',
	medicinalProductPackaged: 'MedicinalProductPackaged',
	medicinalProductPharmaceutical: 'MedicinalProductPharmaceutical',
	medicinalProductUndesirableEffect: 'MedicinalProductUndesirableEffect',
	messageDefinition: 'MessageDefinition',
	messageHeader: 'MessageHeader',
	molecularSequence: 'MolecularSequence',
	namingSystem: 'NamingSystem',
	nutritionOrder: 'NutritionOrder',
	observationDefinition: 'ObservationDefinition',
	organization: 'organization',
	organizationAffiliation: 'OrganizationAffiliation',
	organizationLicense: 'OrganizationLicense',
	paymentNotice: 'PaymentNotice',
	paymentReconciliation: 'PaymentReconciliation',
	person: 'Person',
	planDefinition: 'PlanDefinition',
	practitioner: 'Practitioner',
	practitionerRole: 'PractitionerRole',
	role: 'Role',
	guiRole: 'Role/GuiRole',
	procedure: 'Procedure',
	provenance: 'Provenance',
	questionnaire: 'Questionnaire',
	questionnaireResponse: 'QuestionnaireResponse',
	relatedPerson: 'RelatedPerson',
	requestGroup: 'RequestGroup',
	researchDefinition: 'ResearchDefinition',
	researchElementDefinition: 'ResearchElementDefinition',
	researchStudy: 'ResearchStudy',
	researchSubject: 'ResearchSubject',
	riskAssessment: 'RiskAssessment',
	riskEvidenceSynthesis: 'RiskEvidenceSynthesis',
	schedule: 'Schedule',
	searchParameter: 'SearchParameter',
	serviceRequest: 'ServiceRequest',
	slot: 'Slot',
	specimen: 'Specimen',
	specimenDefinition: 'SpecimenDefinition',
	structureMap: 'StructureMap',
	subscription: 'Subscription',
	substance: 'Substance',
	substanceNucleicAcid: 'SubstanceNucleicAcid',
	substancePolymer: 'SubstancePolymer',
	substanceProtein: 'SubstanceProtein',
	substanceReferenceInformation: 'SubstanceReferenceInformation',
	substanceSourceMaterial: 'SubstanceSourceMaterial',
	substanceSpecification: 'SubstanceSpecification',
	supplyDelivery: 'SupplyDelivery',
	supplyRequest: 'SupplyRequest',
	task: 'task',
	fax: 'fax',
	terminologyCapabilities: 'TerminologyCapabilities',
	testReport: 'TestReport',
	testScript: 'TestScript',
	verificationResult: 'VerificationResult',
	visionPrescription: 'VisionPrescription',
	report: 'Report',

	// ramsoft-specific
	imagingStudyWorklist: 'ImagingStudyWorklist/elk',
	imagingStudyWorklistElasticsearch: 'ImagingStudyWorklist/elk',
	patientElasticsearch: 'Patient/elk',
	practitionerElasticsearch: 'Practitioner/elk',
	organizationElasticsearch: 'Organization/elk',
	helpElasticsearch: 'HelpCenter',
	worklistLayout: 'WorklistLayout',
	signalRConnectionInfo: 'auth/token/SignalRConnectionInfo',
	practitionerLoggedUser: 'Practitioner/loggedUser',
	issuer: 'AssigningAuthority',
	workflowStep: 'WorkflowStep',
	studyStatus: 'StudyStatus',
	patientAlert: 'PatientAlert',
	patientActivity: 'PatientActivity',
	feeSchedule: 'FeeSchedule',
	procedureCode: 'ProcedureCode',
	workflowAutomation: 'WorkflowAutomation',
	insurancePayer: 'InsurancePayer',
	priorAuthorization: 'PriorAuthorization',
	financialType: 'financialType',
	patientLedger: 'PatientLedger',
	auditEventElasticsearch: 'AuditEvent/elk',
	priority: 'Priority',
	// patient portal
	patientPortalAll: '/',
	teachingFolder: 'TeachingFolder',
};

export const scopeMapping = {
	searchBundle: fhirEndpoints.bundle,
	searchDicom: fhirEndpoints.imagingStudyWorklist,
	searchEmergency: fhirEndpoints.imagingStudyWorklistElasticsearch,
	searchOrganization: fhirEndpoints.organization,
	searchOrganizationElk: fhirEndpoints.organizationElasticsearch,
	searchOrganizationLicense: fhirEndpoints.organizationLicense,
	searchHelpElk: fhirEndpoints.helpElasticsearch,
	searchPatient: fhirEndpoints.patient,
	searchPatientElk: fhirEndpoints.patientElasticsearch,
	searchPatientById: fhirEndpoints.patient,
	searchStudy: fhirEndpoints.imagingStudyWorklistElasticsearch,
	searchStudyRegular: fhirEndpoints.imagingStudy,
	searchOrder: fhirEndpoints.serviceRequest,
	searchVisit: fhirEndpoints.encounter,
	searchLog: fhirEndpoints.auditEvent,
	searchLogElk: fhirEndpoints.auditEventElasticsearch,
	searchTask: fhirEndpoints.task,
	searchFax: fhirEndpoints.fax,
	searchCode: fhirEndpoints.codeSystem,
	searchIssuer: fhirEndpoints.issuer,
	searchAll: fhirEndpoints.imagingStudyWorklistElasticsearch,
	searchWorklistLayout: fhirEndpoints.worklistLayout,
	searchValueSet: fhirEndpoints.valueSet,
	searchPractitioner: fhirEndpoints.practitioner,
	searchPractitionerElk: fhirEndpoints.practitionerElasticsearch,
	practitionerDetails: fhirEndpoints.practitioner,
	subscription: fhirEndpoints.subscription,
	searchPractitionerLoggedUser: fhirEndpoints.practitionerLoggedUser,
	searchPractitionerRole: fhirEndpoints.practitionerRole,
	searchRole: fhirEndpoints.role,
	searchGuiRole: fhirEndpoints.guiRole,
	searchActivityHistory: fhirEndpoints.activityHistory,
	searchDevice: fhirEndpoints.device,
	searchWorkflowStep: fhirEndpoints.workflowStep,
	searchStudyStatus: fhirEndpoints.studyStatus,
	searchPatientActivity: fhirEndpoints.patientActivity,
	searchPatientAlert: fhirEndpoints.patientAlert,
	searchPatientPortalAll: fhirEndpoints.patientPortalAll,
	searchPatientPortalHelp: fhirEndpoints.patientPortalAll,
	searchReport: fhirEndpoints.report,
	searchAppointment: fhirEndpoints.appointment,
	searchAppointmentReserve: fhirEndpoints.appointmentReserve,
	searchAvailableTimeForAppointment: fhirEndpoints.availableTimeForAppointment,
	searchAvailableTimeForAppointmentByService: fhirEndpoints.availableTimeForAppointmentByService,
	searchHealthcareService: fhirEndpoints.healthcareService,
	searchCoverage: fhirEndpoints.coverage,
	searchFeeSchedule: fhirEndpoints.feeSchedule,
	searchProcedureCode: fhirEndpoints.procedureCode,
	searchInsurancePayer: fhirEndpoints.insurancePayer,
	searchPriorAuthorization: fhirEndpoints.priorAuthorization,
	searchFinancialType: fhirEndpoints.financialType,
	searchStudyType: fhirEndpoints.studyType,
	measurement: fhirEndpoints.measurement,
	searchInvoice: fhirEndpoints.invoice,
	searchForm: fhirEndpoints.blumeForm,
	searchWorkflowAutomation: fhirEndpoints.workflowAutomation,
	searchChargeItem: fhirEndpoints.chargeItem,
	searchRelatedPerson: fhirEndpoints.relatedPerson,
	patientLedger: fhirEndpoints.patientLedger,
	searchAllergyIntolerance: fhirEndpoints.allergyIntolerance,
	searchPriority: fhirEndpoints.priority,
	searchCarePlan: fhirEndpoints.carePlan,
	// todo: delete once Elastic search is fully functional
	searchAllStudyOld: fhirEndpoints.imagingStudyWorklist,
	searchDiagnosticReport: fhirEndpoints.diagnosticReport,
	searchDocumentReference: fhirEndpoints.documentReference,
	searchTeachingFolder: fhirEndpoints.teachingFolder,
};

export default fhirEndpoints;
