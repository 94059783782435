import { useEffect, useState } from 'react';
import { measureHeight, useWasRenderedOnClientAtLeastOnce } from './Mobile100vhUtils';

const use100vh = () => {
	const [height, setHeight] = useState(measureHeight);
	const wasRenderedOnClientAtLeastOnce = useWasRenderedOnClientAtLeastOnce();

	useEffect(() => {
		if (!wasRenderedOnClientAtLeastOnce) return;

		const setMeasuredHeight = () => {
			const measuredHeight = measureHeight();

			setHeight(measuredHeight);
		};

		window.addEventListener('resize', setMeasuredHeight);

		return () => window.removeEventListener('resize', setMeasuredHeight);
	}, [wasRenderedOnClientAtLeastOnce]);

	return wasRenderedOnClientAtLeastOnce ? height : null;
};

export default use100vh;
