import axios from 'axios';
import handleApiResponse from './utils';

const getDocumentReferences = async ({ __config, patientId, studyId }) => {
	let response;
	let documentReferences = [];
	let page = 1;
	do {
		response = await axios.get(
			`${__config.data_sources.fhir}/DocumentReference?_count=50&page=${page}&subject=${patientId}${
				studyId ? `&imagingstudy=${studyId}` : ''
			}&_dc=${new Date().getTime()}`
		);
		response = handleApiResponse(response);
		page += 1;
		const responseData = response?.data?.entry ? response.data.entry.map(item => item.resource) : [];
		documentReferences = [...documentReferences, ...responseData];
	} while (response?.data?.entry?.length === 50);

	return documentReferences;
};

export default getDocumentReferences;
