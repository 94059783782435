import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';

import {
	Collapse,
	Select,
	MenuItem,
	FormHelperText,
	IconButton,
	TextField,
	Autocomplete,
	Box,
	CircularProgress,
	InputAdornment,
} from '@mui/material';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import SearchIcon from '@mui/icons-material/Search';

import { crmSearchScopes, useCRMDataLoader, useAuth, useConfig, useLoading } from '@worklist-2/core/src';
import { Row, Col, Collapsible, TabPanel } from '../../casePopOverStyles';

import Suggest from '@worklist-2/ui/src/components/Suggest/Suggest';

import {
	STATUSES,
	TYPES,
	ImpactPatientCaseOptions,
	RootCauseOptions,
	SWVersionOptions,
	ReportableIncidentCaseOptions,
} from '../../utils';

const get = require('lodash.get');

const Overview = ({
	expanded,
	editable,
	data,
	onChangeData,
	errorMsgs,
	editableIndex,
	setEditableIndex,
	hoveredField,
	setHoveredField,
}) => {
	const accountDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.accountApi,
	});
	const caseDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.caseApi,
	});
	const { loggedInUser } = useAuth();
	const [collapsedId, setCollapsed] = useState(null);
	const [editMode] = useState(editable);
	const tab = 'overview';
	const unEditableFields = [
		'age_in_business_days',
		'date_opened',
		'last_modified_by',
		'created_by',
		'closed_by',
		'datetime_closed',
	];

	const suggestFields = ['account_name', 'duplicate_case_of', 'parent_case'];

	const requiredFields = ['type', 'status', 'subject', 'description'];

	const numericFields = ['downtime_hrs', 'billable_hrs'];

	const selectFields = [
		'type',
		'sw_version',
		'status',
		'is_this_issue_impacting_parent_care',
		'root_cause',
		'reportable_incident',
	];

	const textFields = [
		'bug_number',
		'frequency',
		'downtime_hrs',
		'billable_hrs',
		'subject',
		'description',
		'case_resolution',
	];

	const onCollapsed = id => {
		setCollapsed(collapsedId === id ? null : id);
	};

	const getBusinessDatesCount = startDateStr => {
		const startDate = parseDate(startDateStr);
		const endDate = new Date();

		// Validate input
		if (endDate <= startDate) {
			return 0;
		}

		// Calculate days between dates
		const millisecondsPerDay = 86400 * 1000; // Day in milliseconds
		startDate.setHours(0, 0, 0, 1); // Start just after midnight
		endDate.setHours(23, 59, 59, 999); // End just before midnight
		const diff = endDate - startDate; // Milliseconds between datetime objects
		let days = Math.ceil(diff / millisecondsPerDay);

		// Subtract two weekend days for every week in between
		const weeks = Math.floor(days / 7);
		days -= weeks * 2;

		// Handle special cases
		const startDay = startDate.getDay();
		const endDay = endDate.getDay();

		// Remove weekend not previously removed.
		if (startDay - endDay > 1) {
			days -= 2;
		}
		// Remove start day if span starts on Sunday but ends before Saturday
		if (startDay === 0 && endDay !== 6) {
			days--;
		}
		// Remove end day if span ends on Saturday but starts after Sunday
		if (endDay === 6 && startDay !== 0) {
			days--;
		}
		return days;
	};

	function parseDate(input) {
		// Transform date from text to date
		const parts = input?.match(/(\d+)/g);
		// new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
		return new Date(parts[0], parts[1] - 1, parts[2]);
	}

	const handleUserSelect = (value, searchValue, optionId, record) => {
		const accountNameEvent = {};
		const accountIdEvent = {};
		if (record) {
			accountNameEvent.target = {
				name: 'account_name',
				value: record.AccountName,
			};

			accountIdEvent.target = {
				name: 'account_id',
				value: record.AccountId || record.id,
			};
		} else {
			accountNameEvent.target = {
				name: 'account_name',
				value: '',
			};

			accountIdEvent.target = {
				name: 'account_id',
				value: '',
			};
		}

		onChangeData(accountNameEvent, '', tab);
		onChangeData(accountIdEvent, '', tab);
	};

	const handleDuplicateCase = (value, searchValue, optionId, record) => {
		const duplicateCase = {};
		const duplicateCaseId = {};

		if (record) {
			duplicateCase.target = {
				name: 'duplicate_case_of',
				value: record.Subject,
			};

			duplicateCaseId.target = {
				name: 'duplicate_case_of_id',
				value: record.CaseId || record.id,
			};
		} else {
			duplicateCase.target = {
				name: 'duplicate_case_of',
				value: '',
			};

			duplicateCaseId.target = {
				name: 'duplicate_case_of_id',
				value: '',
			};
		}

		onChangeData(duplicateCase, '', tab);
		onChangeData(duplicateCaseId, '', tab);
	};

	const handleParentCase = (value, searchValue, optionId, record) => {
		const parentCase = {};
		const parentCaseId = {};

		if (record) {
			parentCase.target = {
				name: 'parent_case',
				value: record.Subject,
			};

			parentCaseId.target = {
				name: 'parent_case_id',
				value: record.CaseId || record.id,
			};
		} else {
			parentCase.target = {
				name: 'parent_case',
				value: '',
			};

			parentCaseId.target = {
				name: 'parent_case_id',
				value: '',
			};
		}

		onChangeData(parentCase, '', tab);
		onChangeData(parentCaseId, '', tab);
	};
	const handleProjectNameSelect = async (value, searchValue, optionId, record) => {
		const projectName = {};
		const projectId = {};

		if (record?.Code) {
			projectName.target = {
				name: 'project_name',
				value: record.Code,
			};

			projectId.target = {
				name: 'project_name_id',
				value: record.ValueSetId,
			};
		} else if (value !== '') {
			projectName.target = {
				name: 'project_name',
				value,
			};
			projectId.target = {
				name: 'project_name_id',
				value: '',
			};
		} else {
			projectName.target = {
				name: 'project_name',
				value: '',
			};

			projectId.target = {
				name: 'project_name_id',
				value: '',
			};
		}

		onChangeData(projectName, '', tab);
		onChangeData(projectId, '', tab);
	};

	const detailsData = useMemo(() => {
		const caseJson = {
			caseInformation: {
				id: 'info',
				label: 'Case Information',
				contents: {
					'Account Name': data?.AccountName,
					Type: data?.Type,
					'SW Version': data?.SWVersion,
					Status: data?.Status,
					'Bug Number': data?.BugNumber,
					Frequency: data?.Frequency,
					'Reportable Incident': data?.ReportableIncident,
					'Last Modified By': `${data?.LastUpdatedUserName ? data?.LastUpdatedUserName : ''} ${
						data?.LastUpdated
							? `${new Date(data.LastUpdated).toLocaleDateString()} ${new Date(
									data.LastUpdated
							  ).toLocaleTimeString(navigator.language, {
									hour: '2-digit',
									minute: '2-digit',
							  })}`
							: ''
					}`,
					'Downtime (Hrs)': data?.Downtime?.toString(),
					'Billable (Hrs)': data?.Billable?.toString(),
					'Parent Case': data?.ParentCaseNumber,
					'Is this issue impacting parent care?': data?.ImpactPatientCase ? 'Yes' : 'No',
					'Project Name': data?.ProjectName,
					'Age In Business Days': data?.CreatedDateTime ? getBusinessDatesCount(data?.CreatedDateTime) : '',
				},
			},
			caseDescription: {
				id: 'desc',
				label: 'Case Description',
				contents: {
					Subject: data?.Subject,
					Description: data?.Description,
					'Duplicate Case of': data?.DuplicateCaseNumber,
					'Root Cause': data?.RootCause,
					'Case Resolution': data?.CaseResolution,
					'Created By': `${data?.CreatedUserName ? data?.CreatedUserName : ''} ${
						data?.CreatedDateTime
							? `${new Date(data.CreatedDateTime).toLocaleDateString()} ${new Date(
									data.CreatedDateTime
							  ).toLocaleTimeString(navigator.language, {
									hour: '2-digit',
									minute: '2-digit',
							  })}`
							: ''
					}`,
					'Closed By': data?.ClosedUserName ? data?.ClosedUserName : '',
					'Date/Time Closed': data?.ClosedDateTime
						? `${new Date(data.ClosedDateTime).toLocaleDateString()} ${new Date(
								data.ClosedDateTime
						  ).toLocaleTimeString(navigator.language, {
								hour: '2-digit',
								minute: '2-digit',
						  })}`
						: '',
				},
			},
		};

		if (editMode) {
			delete caseJson.caseInformation.contents['Last Modified By'];
			delete caseJson.caseInformation.contents['Age In Business Days'];
			delete caseJson.caseDescription.contents['Created By'];
			delete caseJson.caseDescription.contents['Closed By'];
			delete caseJson.caseDescription.contents['Date/Time Closed'];
		}

		return caseJson;
	}, [data, editableIndex, errorMsgs]);

	const genFieldName = key => {
		const noSpecialCharacters = key.replace(/[^a-zA-Z0-9 ]/g, '');
		return noSpecialCharacters.replaceAll(' ', '_').toLowerCase();
	};

	return (
		<TabPanel className="invisible-scrollbar" expanded={expanded}>
			<div className="header">
				<div className="left">
					<div className="colored-icon error-icon">
						<BadgeOutlinedIcon sx={{ fontSize: 20 }} />
					</div>
					<h2>Details</h2>
				</div>
			</div>
			<div className="body">
				<Collapsible>
					{_.map(detailsData, (category, i) => {
						const rows = _.chunk(
							_.map(category.contents, (value, key) => ({ key, value })),
							expanded ? 3 : 2
						);
						return (
							<div key={i}>
								<CollapseHandle
									collapsedId={collapsedId}
									id={category.id}
									label={category.label}
									onCollapsed={onCollapsed}
								/>
								<Collapse in={collapsedId !== category.id}>
									<div className="content" style={{ paddingTop: '3px' }}>
										{rows.map((arr, j) => (
											<Row key={j} bottomBorder={expanded && j < rows.length - 1} className="row">
												{arr.map((entry, k) => {
													const fieldName = genFieldName(entry.key);
													return (
														<Col
															key={`${entry.key}-${k}`}
															className={
																!expanded &&
																k % 2 === 0 &&
																!editMode &&
																'right-bordered'
															}
															style={{
																width: '50%',
																marginBottom: 0,
																marginRight: k % 2 === 0 ? '20px' : '0px',
															}}
														>
															{requiredFields.includes(fieldName) ? (
																<label className="detail">
																	{entry.key}
																	<span style={{ color: 'red' }}>&nbsp;*</span>
																</label>
															) : (
																<label className="detail">{entry.key}</label>
															)}
															<div className="form-control">
																{editMode || editableIndex === fieldName ? (
																	<div className="row">
																		{fieldName === 'account_name' && (
																			<Suggest
																				disableClearable
																				hideIcon
																				className="auto-completed"
																				customDataLoader={accountDataLoader}
																				disabled={false}
																				listSearchScope={{
																					label: 'AccountName',
																					searchKey: 'accountname',
																				}}
																				optionId="AccountName"
																				optionStyle={{
																					ml: 1,
																					fontSize: '14px',
																				}}
																				size="small"
																				style={{
																					'& legend': { display: 'none' },
																					'& fieldset': { top: 0 },
																					fontSize: '14px',
																					flex: '1',
																					maxWidth: '207px',
																				}}
																				testid={fieldName}
																				value={entry.value}
																				onSelect={handleUserSelect}
																			/>
																		)}
																		{fieldName === 'duplicate_case_of' && (
																			<Suggest
																				disableClearable
																				hideIcon
																				className="auto-completed"
																				customDataLoader={caseDataLoader}
																				disabled={false}
																				listSearchScope={{
																					label: 'Subject',
																					searchKey: 'subject',
																				}}
																				optionId="Subject"
																				optionStyle={{
																					ml: 1,
																					fontSize: '14px',
																				}}
																				size="small"
																				style={{
																					'& legend': { display: 'none' },
																					'& fieldset': { top: 0 },
																					fontSize: '14px',
																					flex: '1',
																					maxWidth: '207px',
																				}}
																				testid={fieldName}
																				value={entry.value}
																				onSelect={handleDuplicateCase}
																			/>
																		)}
																		{fieldName === 'parent_case' && (
																			<Suggest
																				disableClearable
																				hideIcon
																				className="auto-completed"
																				customDataLoader={caseDataLoader}
																				disabled={false}
																				listSearchScope={{
																					label: 'Subject',
																					searchKey: 'subject',
																				}}
																				optionId="Subject"
																				optionStyle={{
																					ml: 1,
																					fontSize: '14px',
																				}}
																				size="small"
																				style={{
																					'& legend': { display: 'none' },
																					'& fieldset': { top: 0 },
																					fontSize: '14px',
																					flex: '1',
																					maxWidth: '207px',
																				}}
																				testid={fieldName}
																				value={entry.value}
																				onSelect={handleParentCase}
																			/>
																		)}
																		{fieldName === 'project_name' && (
																			<ProjectNameField
																				disableClearable
																				hideIcon
																				className="auto-completed"
																				editable={editable}
																				listSearchScope={{
																					label: 'Code',
																					searchKey: 'code',
																				}}
																				optionId="Code"
																				optionStyle={{
																					ml: 1,
																					fontSize: '14px',
																				}}
																				size="small"
																				style={{
																					'& legend': { display: 'none' },
																					'& fieldset': { top: 0 },
																					fontSize: '14px',
																					flex: '1',
																					maxWidth: '207px',
																				}}
																				testId={fieldName}
																				value={entry.value}
																				onChangeData={onChangeData}
																				onSelect={handleProjectNameSelect}
																			/>
																		)}
																		{selectFields.includes(fieldName) && (
																			<div style={{ width: '152px' }}>
																				<Select
																					class={fieldName}
																					data-testid={fieldName}
																					defaultValue={entry.value}
																					error={!!errorMsgs[fieldName]}
																					name={fieldName}
																					style={{
																						border: 'none',
																						fontFamily: 'Inter',
																					}}
																					sx={{
																						'& legend': {
																							display: 'none',
																						},
																						'& fieldset': { top: 0 },
																						height: '40px',
																						width: editMode
																							? '200px'
																							: '156px',
																						fontSize: '12px',
																						fontStretch: 'normal',
																						fontStyle: 'normal',
																						flex: '1',
																						borderRadius: 3,
																					}}
																					onChange={e => {
																						onChangeData(e, '', tab);
																					}}
																				>
																					{fieldName === 'sw_version' &&
																						SWVersionOptions.map(item => (
																							<MenuItem
																								value={item.value}
																							>
																								{item.name}
																							</MenuItem>
																						))}
																					{fieldName === 'status' &&
																						STATUSES.map(item => (
																							<MenuItem
																								value={item.value}
																							>
																								{item.name}
																							</MenuItem>
																						))}
																					{fieldName ===
																						'is_this_issue_impacting_parent_care' &&
																						ImpactPatientCaseOptions.map(
																							item => (
																								<MenuItem
																									value={item.value}
																								>
																									{item.name}
																								</MenuItem>
																							)
																						)}
																					{fieldName === 'root_cause' &&
																						RootCauseOptions.map(item => (
																							<MenuItem
																								value={item.value}
																							>
																								{item.name}
																							</MenuItem>
																						))}
																					{fieldName === 'type' &&
																						TYPES.map(item => (
																							<MenuItem
																								value={item.value}
																							>
																								{item.name}
																							</MenuItem>
																						))}
																					{fieldName ===
																						'reportable_incident' &&
																						ReportableIncidentCaseOptions.map(
																							item => (
																								<MenuItem
																									value={item.value}
																								>
																									{item.name}
																								</MenuItem>
																							)
																						)}
																				</Select>
																				<FormHelperText
																					className="Mui-error"
																					sx={{ marginLeft: '14px' }}
																				>
																					{errorMsgs[fieldName]}
																				</FormHelperText>
																			</div>
																		)}
																		{textFields.includes(fieldName) && (
																			<TextField
																				hiddenLabel
																				InputProps={{
																					endAdornment: editableIndex ===
																						fieldName && (
																						<IconButton
																							id={`submitButton_${fieldName}`}
																							sx={{
																								paddingRight: 0,
																							}}
																							type="button"
																							onClick={e => {
																								onChangeData(
																									e,
																									fieldName,
																									tab
																								);
																							}}
																						>
																							<CheckCircleIcon
																								sx={{
																									pointerEvents:
																										'none',
																									color: '#0047ff',
																									fontSize: '22px',
																									marginLeft: 'auto',
																								}}
																							/>
																						</IconButton>
																					),
																				}}
																				className="without-padding"
																				data-testid={fieldName}
																				defaultValue={entry.value}
																				error={!!errorMsgs[fieldName]}
																				helperText={errorMsgs[fieldName]}
																				id={fieldName}
																				name={fieldName}
																				size="small"
																				sx={{
																					height: '60px',
																					'& legend': {
																						display: 'none',
																					},
																					'& fieldset': {
																						top: 0,
																					},
																					fontSize: '14px',
																					flex: '1',
																					maxWidth: '207px',
																				}}
																				variant="outlined"
																				onChange={e => {
																					onChangeData(e, '', tab);
																				}}
																				onKeyPress={e => {
																					if (
																						numericFields.includes(
																							fieldName
																						) &&
																						!/[0-9]/.test(e.key)
																					) {
																						e.preventDefault();
																					}
																				}}
																			/>
																		)}
																		{editableIndex === fieldName &&
																			(selectFields.includes(fieldName) ||
																				suggestFields.includes(fieldName)) && (
																				<IconButton
																					data-testid={`submitButton_${fieldName}`}
																					id={`submitButton_${fieldName}`}
																					sx={{
																						padding: '8px 0px 8px 0px',
																					}}
																					type="button"
																					onClick={e => {
																						onChangeData(e, fieldName, tab);
																					}}
																				>
																					<CheckCircleIcon
																						sx={{
																							pointerEvents: 'none',
																							color: '#0047ff',
																							fontSize: '22px',
																							marginLeft: '5px',
																						}}
																					/>
																				</IconButton>
																			)}
																		{editableIndex === fieldName && (
																			<IconButton
																				id={`closeEditButton_${fieldName}`}
																				sx={{ padding: '7px 2px 5px 2px' }}
																				type="button"
																			>
																				<CloseIcon
																					id={`closeEditButton_${fieldName}`}
																					sx={{
																						color: '#0047ff',
																					}}
																					onClick={e => {
																						onChangeData(e, fieldName, tab);
																					}}
																				/>
																			</IconButton>
																		)}
																	</div>
																) : (
																	<div
																		className="row"
																		onMouseEnter={() => setHoveredField(fieldName)}
																		onMouseLeave={() => setHoveredField(null)}
																	>
																		<span
																			name={fieldName}
																			style={{
																				cursor:
																					!unEditableFields.includes(
																						fieldName
																					) &&
																					!!loggedInUser.permission?.Case.Edit
																						? 'pointer'
																						: '',
																			}}
																			onClick={() => {
																				if (
																					!unEditableFields.includes(
																						fieldName
																					) &&
																					!!loggedInUser.permission?.Case.Edit
																				) {
																					setEditableIndex(fieldName);
																				}
																			}}
																		>
																			{entry.value || '-'}
																		</span>
																		{hoveredField === fieldName &&
																			!unEditableFields.includes(hoveredField) &&
																			!!loggedInUser.permission?.Case.Edit && (
																				<IconButton
																					sx={{
																						p: 0,
																					}}
																					onClick={() =>
																						setEditableIndex(fieldName)
																					}
																				>
																					<EditOutlinedIcon
																						id={`hoverEditButton_${fieldName}`}
																						sx={{
																							color: 'rgba(0, 0, 0, 0.6)',
																							fontSize: '18px',
																							marginLeft: 'auto',
																						}}
																					/>
																				</IconButton>
																			)}
																	</div>
																)}
															</div>
														</Col>
													);
												})}
											</Row>
										))}
									</div>
								</Collapse>
							</div>
						);
					})}
				</Collapsible>
				<div>&nbsp;</div>
			</div>
		</TabPanel>
	);
};

Overview.propType = {
	editable: PropTypes.bool,
	expandable: PropTypes.bool,
};

export default Overview;

const CollapseHandle = ({ id, collapsedId, label, onCollapsed }) => (
	<div className="handle" onClick={() => onCollapsed(id)}>
		<span>{label}</span>
		{collapsedId === id ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
	</div>
);

const ProjectNameField = ({
	hideIcon,
	disableClearable,
	className,
	size,
	style,
	optionStyle,
	optionId,
	listSearchScope,
	onSelect,
	value,
	testId,
	onChangeData,
	editable,
}) => {
	const [open, setOpen] = useState(false);
	const [optionsData, setOptionsData] = useState([]);
	const [suggestionValue, setSuggestionValue] = useState(value || '');
	const [selected, setSelected] = useState('');
	const [searchLoading, setSearchLoading] = useState(false);

	const { loading, setLoading } = useLoading();

	const __config = useConfig();

	useEffect(() => {
		const getData = async () => {
			setLoading(true);

			await axios
				.get(`${__config.data_sources.breeze}/valueset?category=ProjectName&code=${suggestionValue}`)
				.then(({ data }) => {
					if (data.entry.length > 0) {
						const loadData = data.entry.map(item => ({
							...item.resource,
						}));
						setSelected('');
						setOptionsData(
							loadData.map(elem => ({
								[optionId]: elem[listSearchScope.label],
								record: elem,
							}))
						);
					}
				})
				.catch(console.error)
				.finally(() => {
					setLoading(false);
					setSearchLoading(false);
				});
		};
		getData();
	}, [suggestionValue]);

	const debounceSetSuggestionValue = useCallback(
		_.debounce(nextValue => {
			setSuggestionValue(null);
			setSuggestionValue(nextValue);
		}, 500),
		[]
	);

	const onCreateClick = e => {
		suggestionValue
			? onChangeData(e, 'project_name', 'overview', suggestionValue)
			: onChangeData(e, 'project_name', 'overview');
	};

	return (
		<div style={{ display: 'flex' }}>
			<Autocomplete
				autoHighlight
				className={className}
				clearOnBlur={false}
				data-testid={testId}
				defaultValue={value}
				disableClearable={disableClearable}
				forcePopupIcon={false}
				getOptionLabel={option => get(option, optionId) || (_.isString(option) ? option : '')}
				id={testId}
				isOptionEqualToValue={(option, val) => get(option, optionId) === get(val, optionId)}
				loading={loading}
				open={open}
				options={selected ? [] : optionsData} // don't show list of options if one has already been selected
				renderInput={params => (
					<TextField
						{...params}
						InputProps={{
							...params.InputProps,
							startAdornment: !hideIcon && (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
							endAdornment: (
								<>
									{searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</>
							),
							placeholder: 'Search',
						}}
						data-cy={testId}
						data-testid={`${testId}_ComboBox`}
					/>
				)}
				renderOption={(props, option) =>
					option.Code !== '' && (
						<li
							style={{
								backgroundColor: 'transparent',
							}}
							{...props}
						>
							<Box
								flexGrow={1}
								id={`${props.id}-box`}
								sx={optionStyle || {}}
								onClick={() => setSuggestionValue(get(option, optionId))}
							>
								{get(option, optionId)}
							</Box>
						</li>
					)
				}
				size={size}
				sx={{ minWidth: editable ? '207px' : '150px', ...style }}
				value={suggestionValue}
				onBlur={() => {
					setOpen(false);
				}}
				onChange={(event, val) => {
					const newVal = val ? val[optionId] : '';
					const record = val?.record;

					if (onSelect && newVal) {
						setSelected(newVal);
						onSelect(newVal, newVal, optionId, record, null);
						setOptionsData([]);
					}

					setOpen(false);
				}}
				onClose={() => {
					setOpen(false);
				}}
				onFocus={() => {
					setOpen(true);
				}}
				onInputChange={(event, val, reason) => {
					if (editable) {
						onChangeData(event, 'project_name', 'overview', val);
					}

					// Fires when typing or clearing field
					if (reason !== 'reset') {
						if (!open) setOpen(true);
						// input means user is typing
						if (reason === 'input' && !!val) {
							setSearchLoading(true);
							debounceSetSuggestionValue(val);
						} else {
							setOptionsData([]);
							onSelect(val ? val[optionId] : '');
							setSearchLoading(false);
						}
					}
				}}
				onKeyDown={event => {
					const regex = /[%&#*]/;
					if (regex.test(event.key)) {
						event.preventDefault();
					} else if (event.key === 'Enter') {
						if (suggestionValue) {
							onSelect(suggestionValue, '', '', {});
						}
					}
				}}
				onOpen={() => {
					setOpen(true);
				}}
			/>
			{!editable ? (
				<IconButton
					data-testid="submitButton_project_name"
					id="submitButton_project_name"
					sx={{
						padding: '8px 0px 8px 0px',
					}}
					type="button"
					onClick={onCreateClick}
				>
					<CheckCircleIcon
						sx={{
							pointerEvents: 'none',
							color: '#0047ff',
							fontSize: '22px',
							marginLeft: '5px',
						}}
					/>
				</IconButton>
			) : (
				''
			)}
		</div>
	);
};
