import React from 'react';
import { Controller } from 'react-hook-form';
import DateTimeRangePicker from '../../../../DateTimeRangePicker/DateTimeRangePicker';

const FormDateTimeRangePickerVariant = ({ formHook, name, type, ...props }) => {
	const { control, setValue } = formHook;

	return (
		<Controller
			control={control}
			name={name}
			render={({ field }) => (
				<DateTimeRangePicker
					{...props}
					defaultValue={field.value}
					onBlur={() => {
						field.onBlur();
						setValue(name, field.value, { shouldDirty: true, shouldValidate: true });
					}}
					onChange={value => {
						setValue(name, value, { shouldDirty: true, shouldValidate: true });
					}}
				/>
			)}
		/>
	);
};

export default FormDateTimeRangePickerVariant;
