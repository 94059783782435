import React from 'react';

import {
	BlumeArticleIcon,
	BlumeMyProfileIcon,
	BlumeNotificationsIcon,
	BlumeSchedulingIcon,
	BlumeSearchIcon,
	BlumeSettingsIcon,
	BlumeShareIcon,
	BlumeStudiesIcon,
	OAICodeIcon,
	OAIHomeIcon,
	OAIImportIcon,
	OAILogsIcon,
	OAIMarketplaceIcon,
	OAIMyAppsIcon,
	OAIOmegaDialIcon,
	OAIOrganizationIcon,
	OAIOverviewIcon,
	OAISearchIcon,
	OAIUserProfileIcon,
	KeyboardArrowUpIcon,
} from '../../../assets/icons';

import styles from './CategoryItem.module.scss';

const CategoryItem = ({ theme, category, activeCategory, setActiveCategory, setActiveButton }) => {
	const onCategory = () => {
		if (activeCategory.category.DocumentCategoryId === category.DocumentCategoryId) {
			setActiveCategory({ category: {}, subCategory: {} });
			setActiveButton('');
		} else {
			setActiveCategory({ category: { ...category }, subCategory: {} });
			setActiveButton('text');
		}
	};

	const onSubCategory = subCategory => {
		if (activeCategory.subCategory.SubCategoryId === subCategory.SubCategoryId) {
			setActiveCategory(activeCategoryObject => ({ ...activeCategoryObject, subCategory: {} }));
			setActiveButton('');
		} else {
			setActiveCategory(activeCategoryObject => ({ ...activeCategoryObject, subCategory: { ...subCategory } }));
			setActiveButton('text');
		}
	};

	const getCategoryIcon = (icon, fill) => {
		switch (icon) {
			case 'OAIOverviewIcon':
				return <OAIOverviewIcon fill={fill} />;
			case 'OAIImportIcon':
				return <OAIImportIcon fill={fill} />;
			case 'OAIHomeIcon':
				return <OAIHomeIcon fill={fill} />;
			case 'OAIOmegaDialIcon':
				return <OAIOmegaDialIcon fill={fill} />;
			case 'OAISearchIcon':
				return <OAISearchIcon fill={fill} />;
			case 'OAIOrganizationIcon':
				return <OAIOrganizationIcon fill={fill} />;
			case 'OAICodeIcon':
				return <OAICodeIcon fill={fill} />;
			case 'OAILogsIcon':
				return <OAILogsIcon fill={fill} />;
			case 'OAIMyAppsIcon':
				return <OAIMyAppsIcon fill={fill} />;
			case 'OAIMarketplaceIcon':
				return <OAIMarketplaceIcon fill={fill} />;
			case 'OAIUserProfileIcon':
				return <OAIUserProfileIcon fill={fill} />;
			case 'BlumeSchedulingIcon':
				return <BlumeSchedulingIcon fill={fill} />;
			case 'BlumeStudiesIcon':
				return <BlumeStudiesIcon fill={fill} />;
			case 'BlumeSearchIcon':
				return <BlumeSearchIcon fill={fill} />;
			case 'BlumeNotificationsIcon':
				return <BlumeNotificationsIcon fill={fill} />;
			case 'BlumeArticleIcon':
				return <BlumeArticleIcon fill={fill} />;
			case 'BlumeShareIcon':
				return <BlumeShareIcon fill={fill} />;
			case 'BlumeMyProfileIcon':
				return <BlumeMyProfileIcon fill={fill} />;
			case 'BlumeSettingsIcon':
				return <BlumeSettingsIcon fill={fill} />;
			default:
		}
	};

	return (
		<>
			<div
				className={`${theme === 'OAI' ? styles.categoryItemOAI : styles.categoryItemBlume} ${
					activeCategory.category.DocumentCategoryId === category.DocumentCategoryId && theme === 'OAI'
						? styles.categoryActiveOAI
						: activeCategory.category.DocumentCategoryId === category.DocumentCategoryId &&
						  theme === 'Blume'
						? styles.categoryActiveBlume
						: ''
				}`}
				onClick={onCategory}
			>
				<div className={styles.categoryBox}>
					<div className={theme === 'OAI' ? styles.categoryItemOAIIcon : styles.categoryItemBlumeIcon}>
						{activeCategory.category.DocumentCategoryId === category.DocumentCategoryId
							? getCategoryIcon(category.CategoryIcon, '#4BAAF6')
							: getCategoryIcon(category.CategoryIcon)}
					</div>
					<span>{category.CategoryName}</span>
				</div>
				<KeyboardArrowUpIcon />
			</div>
			{activeCategory.category.DocumentCategoryId === category.DocumentCategoryId &&
			theme === 'OAI' &&
			category.SubCategory
				? category.SubCategory.map(subCategory => (
						<div
							key={subCategory.SubCategoryId}
							className={`${theme === 'OAI' ? styles.subCategoryItemOAI : styles.categoryItemBlume} ${
								activeCategory.subCategory?.SubCategoryId === subCategory.SubCategoryId &&
								theme === 'OAI'
									? styles.categoryActiveOAI
									: activeCategory.subCategory.SubCategoryId === subCategory.SubCategoryId &&
									  theme === 'Blume'
									? styles.categoryActiveBlume
									: ''
							}`}
							onClick={() => onSubCategory(subCategory)}
						>
							<span>{subCategory.SubCategoryName}</span>
						</div>
				  ))
				: ''}
		</>
	);
};

export default CategoryItem;
