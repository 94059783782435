import React, { useState } from 'react';

import { CustomPicker } from 'react-color';
import { Hue, Saturation } from 'react-color/lib/components/common';

import tinyColor from 'tinycolor2';

import styles from './ColorPicker.module.scss';

const ColorPointer = () => <div className={styles.colorPointer} />;
const HueColorPointer = () => <div className={styles.hueColorPointer} />;

const getColor = value => {
	const color = tinyColor(value);

	return {
		hsv: color.toHsv(),
		hsl: color.toHsl(),
		hex: color.toHex(),
	};
};

const ColorPicker = CustomPicker(props => {
	const [color, setColor] = useState(getColor(props.activeColor));

	const activeHex = `#${color.hex}`;

	const handleColorChange = value => {
		setColor(getColor(value));
		props.onChange(value);
	};

	return (
		<div
			className={styles.colorDrawer}
			data-testid="color-picker"
			onMouseDown={e => {
				e.preventDefault();
				e.stopPropagation();
			}}
		>
			<div className={styles.colorPickerSaturation}>
				<Saturation hsl={color.hsl} hsv={color.hsv} pointer={ColorPointer} onChange={handleColorChange} />
			</div>

			<div className={styles.colorPickerHue}>
				<Hue hsl={color.hsl} pointer={HueColorPointer} onChange={handleColorChange} />
			</div>

			<div
				className={styles.colorPicker}
				style={{
					background: activeHex,
				}}
			/>

			<div
				className={styles.colorPickerGrid}
				style={{
					gridTemplateColumns: `repeat(${props.circleColors.length / 2}, 1fr)`,
				}}
			>
				{props.circleColors.map((circleColor, index) => (
					<div
						key={circleColor}
						className={styles.colorPickerGridButton}
						data-testid={`color-button-${index}`}
						style={{
							background: circleColor,
							border:
								circleColor.toLowerCase() === '#ffffff' || circleColor.toLowerCase() === 'transparent'
									? '1px solid #D9D9D9'
									: undefined,
						}}
						onClick={() => handleColorChange(circleColor)}
					>
						{circleColor.toLowerCase() === activeHex.toLowerCase() && (
							<div
								className={styles.colorPickerGridButtonItem}
								style={{
									top: circleColor.toLowerCase() === '#ffffff' ? '-3px' : '-2px',
									left: circleColor.toLowerCase() === '#ffffff' ? '-3px' : '-2px',
								}}
							/>
						)}

						{circleColor.toLowerCase() === 'transparent' && (
							<div className={styles.colorPickerGridButtonItemTransparent} />
						)}
					</div>
				))}
			</div>
		</div>
	);
});

export default ColorPicker;
