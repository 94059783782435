import { getEnabledElement, triggerEvent, eventTarget } from '@cornerstonejs/core';
import { Enums, AnnotationTool, utilities, annotation, drawing, state, cursors } from '@cornerstonejs/tools';
import checkLineIntersection from './utils/checkLineIntersection';
import distanceToPoint from './utils/distanceToPoint';

const { Events } = Enums;
const { lineSegment } = utilities.math;
const { getViewportIdsWithToolToRender } = utilities.viewportFilters;
const { triggerAnnotationRenderForViewportIds } = utilities;
const { addAnnotation, getAnnotations, removeAnnotation } = annotation.state;
const { isAnnotationVisible } = annotation.visibility;
const { drawArrow: drawArrowSvg } = drawing;
const { resetElementCursor, hideElementCursor } = cursors.elementCursor;

class PlumbLineTool extends AnnotationTool {
	toolName;
	touchDragCallback;
	mouseDragCallback;
	editData;
	isDrawing;
	isHandleOutsideImage;

	constructor(
		toolProps = {},
		defaultToolProps = {
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				preventHandleOutsideImage: false,
			},
		}
	) {
		super(toolProps, defaultToolProps);
	}

	addNewAnnotation = (evt) => {
		const eventDetail = evt.detail;
		const { currentPoints } = eventDetail;
		const worldPos = currentPoints.world;

		const { element } = eventDetail;
		const enabledElement = getEnabledElement(element);
		const { viewport, renderingEngine } = enabledElement;

		const camera = viewport.getCamera();
		const { viewPlaneNormal, viewUp } = camera;

		const referencedImageId = this.getReferencedImageId(viewport, worldPos, viewPlaneNormal, viewUp);

		const FrameOfReferenceUID = viewport.getFrameOfReferenceUID();

		const { rotation } = viewport.getProperties();

		const canvasPos = viewport.worldToCanvas(worldPos);

		const point1 = worldPos;
		const point2 = viewport.canvasToWorld([canvasPos[0], canvasPos[1] + 100000]);

		const _annotation = {
			highlighted: true,
			invalidated: true,
			metadata: {
				toolName: this.getToolName(),
				viewPlaneNormal: [...viewPlaneNormal],
				viewUp: [...viewUp],
				FrameOfReferenceUID,
				referencedImageId,
			},
			data: {
				handles: {
					points: [point1, point2],
					rotation,
					activeHandleIndex: null,
					textBox: {
						hasMoved: false,
						worldPosition: [0, 0, 0],
						worldBoundingBox: {
							topLeft: [0, 0, 0],
							topRight: [0, 0, 0],
							bottomLeft: [0, 0, 0],
							bottomRight: [0, 0, 0],
						},
					},
				},
				label: '',
				cachedStats: {},
			},
		};

		addAnnotation(_annotation, element);

		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.editData = {
			annotation: _annotation,
			viewportIdsToRender,
			handleIndex: 1,
			movingTextBox: false,
			newAnnotation: false,
			hasMoved: true,
		};

		evt.preventDefault();

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		const eventType = Events.ANNOTATION_COMPLETED;
		triggerEvent(eventTarget, eventType, {
			annotation: _annotation,
		});

		return _annotation;
	};

	/**
	 * It returns if the canvas point is near the provided length annotation in the provided
	 * element or not. A proximity is passed to the function to determine the
	 * proximity of the point to the annotation in number of pixels.
	 *
	 * @param element - HTML Element
	 * @param _annotation - Annotation
	 * @param canvasCoords - Canvas coordinates
	 * @param proximity - Proximity to tool to consider
	 * @returns Boolean, whether the canvas point is near tool
	 */
	isPointNearTool = (element, _annotation, canvasCoords, proximity) => {
		const enabledElement = getEnabledElement(element);
		const { viewport } = enabledElement;
		const { data } = _annotation;
		const [point1, point2] = data.handles.points;
		const canvasPoint1 = viewport.worldToCanvas(point1);
		const canvasPoint2 = viewport.worldToCanvas(point2);

		const line1 = {
			start: {
				x: canvasPoint1[0],
				y: canvasPoint1[1],
			},
			end: {
				x: canvasPoint2[0],
				y: canvasPoint2[1],
			},
		};

		const _distanceToPoint = lineSegment.distanceToPoint(
			[line1.start.x, line1.start.y],
			[line1.end.x, line1.end.y],
			[canvasCoords[0], canvasCoords[1]]
		);

		return _distanceToPoint <= proximity;
	};

	toolSelectedCallback = (evt, _annotation) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		_annotation.highlighted = true;

		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.editData = {
			annotation: _annotation,
			viewportIdsToRender,
			movingTextBox: false,
		};

		this._activateModify(element);

		hideElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		evt.preventDefault();
	};

	handleSelectedCallback(evt, _annotation, handle) {
		const eventDetail = evt.detail;
		const { element } = eventDetail;
		const { data } = _annotation;

		_annotation.highlighted = true;

		let movingTextBox = false;
		let handleIndex;

		if (handle.worldPosition) {
			movingTextBox = true;
		} else {
			handleIndex = data.handles.points.findIndex((p) => p === handle);
		}

		// Find viewports to render on drag.
		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.editData = {
			annotation: _annotation,
			viewportIdsToRender,
			handleIndex,
			movingTextBox,
		};
		this._activateModify(element);

		hideElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		evt.preventDefault();
	}

	_endCallback = (evt) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const { annotation: _annotation, viewportIdsToRender } = this.editData;
		const { data } = _annotation;

		data.handles.activeHandleIndex = null;

		this._deactivateModify(element);
		this._deactivateDraw(element);
		resetElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		if (this.isHandleOutsideImage && this.configuration.preventHandleOutsideImage) {
			removeAnnotation(_annotation.annotationUID);
		}

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		const eventType = Events.ANNOTATION_COMPLETED;
		triggerEvent(eventTarget, eventType, {
			annotation: _annotation,
		});

		this.editData = null;
		this.isDrawing = false;
	};

	_dragCallback = (evt) => {
		this.isDrawing = true;
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const { annotation: _annotation, viewportIdsToRender, handleIndex, movingTextBox } = this.editData;
		const { data } = _annotation;

		if (movingTextBox) {
			// Drag mode - moving text box
			const { deltaPoints } = eventDetail;
			const worldPosDelta = deltaPoints.world;

			const { textBox } = data.handles;
			const { worldPosition } = textBox;

			worldPosition[0] += worldPosDelta[0];
			worldPosition[1] += worldPosDelta[1];
			worldPosition[2] += worldPosDelta[2];

			textBox.hasMoved = true;
		} else if (handleIndex === undefined) {
			// Drag mode - moving handle
			const { deltaPoints } = eventDetail;
			const worldPosDelta = deltaPoints.world;

			const points = data.handles.points;

			points.forEach((point) => {
				point[0] += worldPosDelta[0];
				point[1] += worldPosDelta[1];
				point[2] += worldPosDelta[2];
			});
			_annotation.invalidated = true;
		} else {
			// Move mode - after double click, and mouse move to draw
			const { currentPoints } = eventDetail;
			const worldPos = currentPoints.world;
			const handlePos = data.handles.points[handleIndex];

			data.handles.points[handleIndex] = [worldPos[0], handlePos[1], worldPos[2]];
			_annotation.invalidated = true;
		}

		this.editData.hasMoved = true;

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
	};

	cancel = (element) => {
		// If it is mid-draw or mid-modify
		if (this.isDrawing) {
			this.isDrawing = false;
			this._deactivateDraw(element);
			this._deactivateModify(element);
			resetElementCursor(element);

			const { annotation: _annotation, viewportIdsToRender, newAnnotation } = this.editData;
			const { data } = _annotation;

			_annotation.highlighted = false;
			data.handles.activeHandleIndex = null;

			const enabledElement = getEnabledElement(element);
			const { renderingEngine } = enabledElement;

			triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

			if (newAnnotation) {
				const eventType = Events.ANNOTATION_COMPLETED;

				const eventDetail = {
					annotation: _annotation,
				};

				triggerEvent(eventTarget, eventType, eventDetail);
			}

			this.editData = null;
			return _annotation.annotationUID;
		}
	};

	_activateModify = (element) => {
		state.isInteractingWithTool = true;

		element.addEventListener(Events.MOUSE_UP, this._endCallback);
		element.addEventListener(Events.MOUSE_DRAG, this._dragCallback);
		element.addEventListener(Events.MOUSE_CLICK, this._endCallback);
	};

	_deactivateModify = (element) => {
		state.isInteractingWithTool = false;

		element.removeEventListener(Events.MOUSE_UP, this._endCallback);
		element.removeEventListener(Events.MOUSE_DRAG, this._dragCallback);
		element.removeEventListener(Events.MOUSE_CLICK, this._endCallback);
	};

	_activateDraw = (element) => {
		state.isInteractingWithTool = true;

		element.addEventListener(Events.MOUSE_UP, this._endCallback);
		element.addEventListener(Events.MOUSE_DRAG, this._dragCallback);
		element.addEventListener(Events.MOUSE_MOVE, this._dragCallback);
		element.addEventListener(Events.MOUSE_CLICK, this._endCallback);
	};

	_deactivateDraw = (element) => {
		state.isInteractingWithTool = false;

		element.removeEventListener(Events.MOUSE_UP, this._endCallback);
		element.removeEventListener(Events.MOUSE_DRAG, this._dragCallback);
		element.removeEventListener(Events.MOUSE_MOVE, this._dragCallback);
		element.removeEventListener(Events.MOUSE_CLICK, this._endCallback);
	};

	/**
	 * it is used to draw the length annotation in each
	 * request animation frame. It calculates the updated cached statistics if
	 * data is invalidated and cache it.
	 *
	 * @param enabledElement - The Cornerstone's enabledElement.
	 * @param svgDrawingHelper - The svgDrawingHelper providing the context for drawing.
	 */
	renderAnnotation = (enabledElement, svgDrawingHelper) => {
		let renderStatus = false;
		const { viewport } = enabledElement;
		const { element, sWidth, sHeight } = viewport;

		let annotations = getAnnotations(this.getToolName(), element);

		if (!annotations?.length) {
			return renderStatus;
		}

		annotations = this.filterInteractableAnnotationsForElement(element, annotations);

		if (!annotations?.length) {
			return renderStatus;
		}

		const styleSpecifier = {
			toolGroupId: this.toolGroupId,
			toolName: this.getToolName(),
			viewportId: enabledElement.viewport.id,
		};

		// Draw SVG
		for (let i = 0; i < annotations.length; i++) {
			const _annotation = annotations[i];
			const { annotationUID, data } = _annotation;
			const { points } = data.handles;

			styleSpecifier.annotationUID = annotationUID;

			const lineWidth = this.getStyle('lineWidth', styleSpecifier, _annotation);
			const lineDash = this.getStyle('lineDash', styleSpecifier, _annotation);
			const color = this.getStyle('color', styleSpecifier, _annotation);
			const shadow = this.getStyle('shadow', styleSpecifier, _annotation);

			const canvasCoordinates = points.map((p) => viewport.worldToCanvas(p));
			const canvasStartCoordinates = viewport.worldToCanvas(points[0]);
			const canvasEndCoordinates = [
				checkLineIntersection(
					canvasCoordinates[0][0],
					canvasCoordinates[0][1],
					canvasCoordinates[1][0],
					canvasCoordinates[1][1],
					0,
					0,
					sWidth,
					0
				),
				checkLineIntersection(
					canvasCoordinates[0][0],
					canvasCoordinates[0][1],
					canvasCoordinates[1][0],
					canvasCoordinates[1][1],
					sWidth,
					0,
					sWidth,
					sHeight
				),
				checkLineIntersection(
					canvasCoordinates[0][0],
					canvasCoordinates[0][1],
					canvasCoordinates[1][0],
					canvasCoordinates[1][1],
					sWidth,
					sHeight,
					0,
					sHeight
				),
				checkLineIntersection(
					canvasCoordinates[0][0],
					canvasCoordinates[0][1],
					canvasCoordinates[1][0],
					canvasCoordinates[1][1],
					0,
					sHeight,
					0,
					0
				),
			]
				.filter((p) => p)
				.sort(
					(a, b) => distanceToPoint(canvasStartCoordinates, b) - distanceToPoint(canvasStartCoordinates, a)
				);

			if (!canvasEndCoordinates.length) {
				return;
			}

			if (!isAnnotationVisible(annotationUID)) {
				continue;
			}

			const dataId = `${annotationUID}-line`;
			let arrowUID = '1';
			drawArrowSvg(
				svgDrawingHelper,
				annotationUID,
				arrowUID,
				canvasStartCoordinates,
				canvasEndCoordinates[0],
				{
					color,
					width: lineWidth,
					lineDash,
					shadow,
				},
				dataId
			);

			renderStatus = true;

			if (!viewport.getRenderingEngine()) {
				console.warn('Rendering Engine has been destroyed');
				return renderStatus;
			}
		}

		return renderStatus;
	};
}

PlumbLineTool.toolName = 'PlumbLine';
export default PlumbLineTool;
