import ImportQueue from './ImportQueue';
import { fhirExtensionUrls } from '@worklist-2/core/src';
import _ from 'lodash';
import { IMG_TYPES, MEDIA_TYPES } from './consts.jsx';

class ImportDispatcher {
	coordinatorWorker = null;
	importQueue = null;
	parent = null;
	debugEnable = false;
	NUM_WORKERS = window.navigator.hardwareConcurrency > 8 ? 8 : window.navigator.hardwareConcurrency;
	importing = false;
	COORDINATOR_WORKER_URL = '/scripts/importcoordinatorworker.js';
	importProgressBarsMap = new Map();
	skipped = ['Study locked', 'Object already exists in the system'];
	accessToken = null;
	runner = null;
	task = null;
	studyDataLoader = null;
	patientDataLoader = null;
	importedFiles = 0;
	totalFileSize = 0;
	uploadSize = 0;
	progress = 0;
	errorMessage = [];
	uploadStatus = [];
	uploadProgress = [];
	instance = null;
	__config = null;
	updateAccessToken = null;

	RS_FILE_KIND_IDENTIFIER = 'RamSoft File Kind Identifier';
	RS_RACE_IDENTIFIER = 'RamSoft Race Identifier';
	implementationValues = {
		RS_IMPLEMENTATION_VERSION_NAME: 'RS 7.01',
		RS_IMPLEMENTATION_VERSION_NAME2: 'RS_7_01',
		RS_RELEASEDATE: '2017-09-24',
		RS_UID_ROOT: '1.2.124.113540',
		RS_ULTRAPRO_VERSION: '7.0',
		RS_ULTRAPRO_PREV_VERSION: '6',
		RS_ULTRAPRO_VERSION_NUMBER: 600,
		RS_IMPLEMENTATION_CLASS_UID: '1.2.124.113540.1.7.0',
		RS_PREV_IMPL_CLASS_UID: '1.2.124.113540.1.6',
		RS_PRIVATE_DATA_CODE: 'RS Inc. - Private Data',
		RS_INSTITUTION_NAME: 'RAMSOFT',
		RS_MANUFACTURER: 'RamSoft Inc.',
	};

	constructor(
		studyDataLoader,
		patientDataLoader,
		accessToken,
		sessionId,
		coordinatorWorkerURL,
		instance,
		__config,
		updateAccessToken
	) {
		console.log('constructor');
		this.instance = instance;
		this.__config = __config;
		this.updateAccessToken = updateAccessToken;
		this.studyDataLoader = studyDataLoader;
		this.patientDataLoader = patientDataLoader;
		this.accessToken = accessToken;
		this.sessionId = sessionId;
		if (coordinatorWorkerURL) {
			this.COORDINATOR_WORKER_URL = coordinatorWorkerURL;
		}
		this.initialSetup(this.COORDINATOR_WORKER_URL);
	}

	initialSetup = script => {
		let dispatcher = this;
		console.log('creating import coordinator worker');
		this.coordinatorWorker = new Worker(script);

		this.importQueue = this.importQueue ? this.importQueue : new ImportQueue(this);

		this.coordinatorWorker.onmessage = function (e) {
			console.log('received message from coordinator worker');
			if (e.data && e.data.length > 2 && (e.data[0] === 'postSuccess' || e.data[0] === 'postError')) {
				dispatcher.onMessage(e.data[1], e.data[2]);
				console.log('coordinatorWorker.onmessage', e.data[0]);
			} else if (e.data?.[0] === 'getMoreTasks') {
				console.log('require more tasks');
				dispatcher.postMoreTasks();
			} else if (e.data?.[0] === 'areAllWorkerFree') {
				if (e.data[1] === true && e.data[2] === false) {
					// log Finished importing.
					dispatcher.importQueue.resetPriorityQueue();
					dispatcher.importing = false;

					//todo: Importing is done, we need to update progress bar to 100% and show user that importing is finished.
					console.log('finished importing');
				} else if (e.data[1] === true && e.data[2] === true) {
					console.log('importing stopped');
				}
			} else if (e.data?.[0] === 'updateProgress') {
				dispatcher.updateProgressBar(e.data[1], e.data[2], e.data[3], e.data[4], e.data[5], e.data[6]);
			} else if (e.data?.[0] === 'postAborted') {
			} else if (e.data?.[0] === 'fileUploadProgress') {
				dispatcher.updateFileProgress(e.data[1], e.data[2], e.data[5]);
			}
		};

		if (sessionStorage.getItem('rsff_import_debugEnable') == 'true') {
			this.debugEnable = sessionStorage.getItem('rsff_import_debugEnable');
		}
		this.coordinatorWorker.postMessage(['numberOfWorkers', this.NUM_WORKERS]);
		this.coordinatorWorker.postMessage(['debugEnable', this.debugEnable]);

		this.coordinatorWorker.postMessage(['accessToken', this.accessToken]);
		this.coordinatorWorker.postMessage(['sessionId', this.sessionId]);
	};

	onMessage = (queueData, messageEvent) => {
		// from importworker.js
		const SUCCESS_MESSAGE = 'Import Successful';
		let me = this,
			fileName = queueData.fullPath ? queueData.fullPath : queueData.item.name,
			uploadItem;

		if (messageEvent.operationSuccess) {
			if (messageEvent.message && messageEvent.message.text) {
				if (messageEvent.message.text === SUCCESS_MESSAGE) {
					me.importedCount();
				} else {
					let skipped = me.skipped.includes(messageEvent.message.text),
						warningMsg = me.composeMessage(
							fileName,
							messageEvent.message.code,
							messageEvent.message.text,
							false,
							skipped
						);
					me.importedCount();
				}
			} else {
				// log the success
				me.importedCount();
			}

			uploadItem = {
				fileName: fileName,
				isUploaded: true,
				messageEvent,
			};
			this.uploadStatus.push(uploadItem);

			// Import exit conditions
			// 1. importQueue is empty
			// 2. fileQueue has no records
			// 3. worker are all available
			if (me.importQueue.isEmpty()) {
				// import finished
				me.coordinatorWorker.postMessage(['areAllWorkersFree']);
			}
		} else {
			let error = me.parseError(messageEvent),
				errorMessage,
				errorItem;

			if (error.code && error.codeMeaning) {
				errorMessage = me.composeMessage(fileName, error.code, error.codeMeaning, true);
			} else {
				errorMessage = me.composeMessage(fileName, error.status, error.statusText, true);

				errorItem = {
					fileName: fileName,
					status: error.status,
					statusText: error.statusText,
				};

				console.log(errorMessage);
			}

			if (!!errorItem) {
				this.errorMessage.push(errorItem);
			}
			console.log(errorMessage);

			uploadItem = {
				fileName: fileName,
				isUploaded: true,
				messageEvent,
			};
			this.uploadStatus.push(uploadItem);

			if (me.importQueue.isEmpty()) {
				// import finished
				me.coordinatorWorker.postMessage(['areAllWorkersFree']);
			}
		}
	};

	postMoreTasks = () => {
		console.log('ask to get more tasks');
		if (this.importing) {
			this.importQueue.getMoreTasks();
		}
	};

	generateDicomJsons = async (nodeList, url) => {
		let studyObject = nodeList.studyObject;
		let studyId, studyUID, studyStatus, managingOrganizationId, issuer;

		if (studyObject) {
			const studyUIdIdentifier = _.find(studyObject.identifier, item => item.system === 'urn:dicom:uid');

			studyId = studyObject.id;
			studyUID = studyUIdIdentifier?.value.replace('urn:oid:', '');
		}

		issuer = _.find(
			nodeList.managingOrganization.extension,
			elem =>
				elem.url === fhirExtensionUrls.organization.issuer ||
				elem.url === fhirExtensionUrls.organization.defaultIssuer
		)?.valueReference.display;

		managingOrganizationId = nodeList.managingOrganization.id;
		studyStatus = nodeList.studyStatus ? nodeList.studyStatus.status : null;

		let postStudyUrl = url;

		//prefer studyId over studyUID
		if (studyId) {
			postStudyUrl =
				postStudyUrl +
				'?internalstudyid=' +
				studyId +
				'&internalmanagingorganizationid=' +
				managingOrganizationId;
		} else if (studyUID) {
			postStudyUrl =
				postStudyUrl + '?studyuid=' + studyUID + '&internalmanagingorganizationid=' + managingOrganizationId;
		} else {
			postStudyUrl = postStudyUrl + '?internalmanagingorganizationid=' + managingOrganizationId;
		}

		if (studyStatus) {
			postStudyUrl = postStudyUrl + '&studyStatus=' + studyStatus;
		}

		for (let item of nodeList.files) {
			let dicomJson = null;
			item.errorMessage = null;

			if (item.type === '') {
				// if there is no file type, we have to assume that it is DICOM
				// it could be something else, but we can't reliably determine the type
				// so we make the assumption and skip creating the dicomJson
				// item.type we cannot set, so use newtype
				item.newtype = 'application/dicom';
				dicomJson = '{}';
			} else if ((!studyId || studyId <= 0) && item.newtype != 'application/dicom') {
				dicomJson = '{}';
			} else {
				dicomJson = await this.generateDicomForImport(item, studyObject, issuer);
			}

			if (dicomJson) {
				this.importQueue.add({
					item,
					dicomJson,
					url: postStudyUrl,
				});
			}
		}
	};

	generateJsons = async (nodeList, url, payload) => {
		for (let item of nodeList) {
			const dicomJson = 'NOT_REQUIRED';
			this.importQueue.add({
				item,
				dicomJson,
				url: url,
				payload,
			});
		}
	};

	toDicomDateFormat = date => {
		let month = (date.getMonth() < 10 ? '0' : '') + (date.getMonth() + 1),
			day = (date.getDate() < 10 ? '0' : '') + date.getDate();

		return `${date.getFullYear()}${month}${day}`;
	};

	toDicomTimeFormat = date => {
		let hours = (date.getHours() < 10 ? '0' : '') + date.getHours(),
			minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes(),
			seconds = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();

		return `${hours}${minutes}${seconds}`;
	};

	getDicomTimeZoneOffset = date => {
		let timeZoneOffset = date.getTimezoneOffset();
		let timeZoneOffsetAbs = Math.abs(timeZoneOffset);
		let hours = Math.floor(timeZoneOffsetAbs / 60),
			minutes = timeZoneOffsetAbs % 60,
			hoursPadded = (hours < 10 ? '0' : '') + hours,
			minutesPadded = (minutes < 10 ? '0' : '') + minutes;
		return (timeZoneOffset >= 0 ? '-' : '+') + hoursPadded + minutesPadded;
	};

	generateDicomForImport = async (node, studyObject, issuer) => {
		let dicomJson = '{}';

		let patientName,
			patientAddress,
			patientModelLoaded,
			issuerOfPatientID,
			isPdf = node.type == 'application/pdf',
			isMedia = MEDIA_TYPES.includes(node.type) || IMG_TYPES.includes(node.type),
			fileName = node.name,
			encapsulatedPdf = !isPdf
				? ''
				: `"00420011": {
					"vr": "OB",
					"BulkDataURI": ["${fileName}"]
				},`,
			pixelData = isPdf
				? ''
				: `,
				"7fe00010": {
					"vr": "OB",
					"BulkDataURI": ["${fileName}"]
				}`;

		let dateTimeNow = new Date(Date.now()),
			dateNow = this.toDicomDateFormat(dateTimeNow),
			timeNow = this.toDicomTimeFormat(dateTimeNow);

		const studyUIdIdentifier = _.find(studyObject.identifier, item => item.system === 'urn:dicom:uid');

		let studyUID = studyUIdIdentifier?.value.replace('urn:oid:', '');

		let modalityListCode = [];
		if (studyObject.modality) {
			modalityListCode = _.map(studyObject.modality, item => item.code);
		}

		// the document viewer is where we would create an SR
		// for the study, so here we can assume a PDF is an HC
		// also we use HC if the modality list is not populated in the study model
		let modalityList = modalityListCode,
			modality = modalityList && modalityList.length > 0 ? modalityList[0] : 'HC';

		if (isPdf) {
			// PDF should be DOC - #4148
			modality = 'DOC'; // Reference from David Clunie - https://groups.google.com/g/comp.protocols.dicom/c/CPZuY3x5o0Y
		} else if (isMedia) {
			// image/video should be SC
			modality = 'SC';
		}

		const studyAccIdentifier = _.find(
			studyObject.identifier,
			item => item.system === fhirExtensionUrls.imagingStudy.accession
		);

		let accessionNumber = studyAccIdentifier?.value;

		const priorityExt = _.find(studyObject.extension, item => item.url === fhirExtensionUrls.common.priority);

		let studyPriority = priorityExt?.valueString;

		patientModelLoaded = await this.patientDataLoader.load({
			id: studyObject.subject?.id,
		});

		const patientIdentifier = _.find(
			patientModelLoaded.identifier,
			item => item.system === 'urn:oid:1.2.36.146.595.217.0.1'
		);

		let patientId = patientIdentifier?.value;

		patientName = patientModelLoaded.name ? patientModelLoaded.name[0] : '';
		patientAddress = patientModelLoaded.address ? patientModelLoaded.address : '';
		issuerOfPatientID = issuer;

		dicomJson = `{
			"00080000": {
				"vr": "UL"
			},
			"00080005": {
				"vr": "CS"
			},
			"00080008": {
				"vr": "CS"
			},
			"00080012": {
				"vr": "DA",
				"Value":["${dateNow}"]
			},
			"00080013": {
				"vr": "TM",
				"Value":["${timeNow}"]
			},
			"00080014": {
				"vr": "UI",
				"Value":["${this.implementationValues?.RS_IMPLEMENTATION_CLASS_UID}"]
			},
			"00080016": {
				"vr": "UI"${
					isPdf
						? `,
				"Value": ["1.2.840.10008.5.1.4.1.1.104.1"]`
						: `,
				"Value": ["1.2.840.10008.5.1.4.1.1.7"]`
				}
			},
			"00080018": {
				"vr": "UI"
			},
			"00080020": {
				"vr": "DA"
			},
			"00080023": {
				"vr": "DA",
				"Value":["${dateNow}"]
			},
			"00080030": {
				"vr": "TM"
			},
			"00080033": {
				"vr": "TM",
				"Value":["${timeNow}"]
			},
			"00080050": {
				"vr": "SH",
				"Value":["${accessionNumber}"]
			},
			"00080060": {
				"vr": "CS",
				"Value":["${modality}"]
			},
			"00080070": {
				"vr": "LO",
				"Value":["${this.implementationValues?.RS_MANUFACTURER}"]
			},
			"00080080": {
				"vr": "LO",
				"Value":["${this.implementationValues?.RS_INSTITUTION_NAME}"]
			},
			"00080090": {
				"vr": "PN"${
					(typeof studyObject.get === 'function' ? studyObject.get('referringPhysianDisplay') : '')
						? `,
				"Value": [{
					"Alphabetic": "${studyObject.referringPhysianDisplay}"
				}
			]`
						: ''
				}
			},
			"00080201": {
				"vr": "SH",
				"Value":["${this.getDicomTimeZoneOffset(dateTimeNow)}"]
			},
			"00081030": {
				"vr": "LO",
				"Value":["${studyObject.description}"]
			},
			"0008103e": {
				"vr": "LO"
			},
			"00081048": {
				"vr": "PN"
			},
			"00081050": {
				"vr": "PN"
			},
			"00081060": {
				"vr": "PN"${
					studyObject.readingPhysianDisplay
						? `,
				"Value": [{
					"Alphabetic": "${studyObject.readingPhysianDisplay}"
				}
			]`
						: ''
				}
			},
			"00081070": {
				"vr": "PN"
			},
			"00081110": {
				"vr": "SQ",
				"Value": [{
						"00081150": {
							"vr": "UI"
						},
						"00081155": {
							"vr": "UI"
						}
					}
				]
			},
			"00081111": {
				"vr": "SQ",
				"Value": [{
						"00081150": {
							"vr": "UI"
						},
						"00081155": {
							"vr": "UI"
						}
					}
				]
			},
			"00100010": {
				"vr": "PN",
				"Value": [{
						"Alphabetic": "${patientName.text}"
					}
				]
			},
			"00100020": {
				"vr": "LO",
				"Value":["${patientId}"]
			},
			"00100021": {
				"vr": "LO",
				"Value":["${issuerOfPatientID}"]
			},
			"00100030": {
				"vr": "DA"
			},
			"00100040": {
				"vr": "CS"
			},
			"00100050": {
				"vr": "SQ",
				"Value": [{
						"00080100": {
							"vr": "SH"
						}
					}
				]
			},
			"00101000": {
				"vr": "LO"
			},
			"00101040": {
				"vr": "LO"
			},
			"00102150": {
				"vr": "LO",
				"Value":["${patientAddress.country}"]
			},
			"00102154": {
				"vr": "SH"
			},
			"00102160": {
				"vr": "SH",
				"Value":["${patientModelLoaded.ethnicityDisplay?.join(' ')}"]
			},
			"001021a0": {
				"vr": "CS",
				"Value":["${patientModelLoaded.smokingStatusCodingDisplay}"]
			},
			"00180015": {
				"vr": "CS"
			},
			"0020000d": {
				"vr": "UI",
				"Value":["${studyUID}"]
			},
			"0020000e": {
				"vr": "UI"
			},
			"00200010": {
				"vr": "SH",
				"Value":["${studyObject.id}"]
			},
			"00200011": {
				"vr": "IS"
			},
			"00200060": {
				"vr": "CS"
			},
			"00321030": {
				"vr": "LO"
			},
			"00324000": {
				"vr": "LT"
			},
			"00380010": {
				"vr": "LO"
			},
			"00380016": {
				"vr": "LO"
			},
			"00380020": {
				"vr": "DA"
			},
			"00380021": {
				"vr": "TM"
			},
			"00380300": {
				"vr": "LO"
			},
			"00384000": {
				"vr": "LT"
			},
			"00400007": {
				"vr": "LO"
			},
			"00400008": {
				"vr": "SQ",
				"Value": [{
					"00080100": {
						"vr": "SH"
					},
					"00080102": {
						"vr": "SH"
					}
				}]
			},
			"00400009": {
				"vr": "SH"
			},
			"00400241": {
				"vr": "AE"
			},
			"00400242": {
				"vr": "SH"
			},
			"00400244": {
				"vr": "DA"
			},
			"00400245": {
				"vr": "TM"
			},
			"00400253": {
				"vr": "SH"
			},
			"00400254": {
				"vr": "LO"
			},
			"00401001": {
				"vr": "SH"
			},
			"00401003": {
				"vr": "SH",
				"Value":["${studyPriority}"]
			},
			"00402001": {
				"vr": "LO"
			},
			${encapsulatedPdf}
			"20500020": {
				"vr": "CS"
			},
			"31070010": {
				"vr": "LO"
			},
			"31071010": {
				"vr": "DS"
			},
			"31110010": {
				"vr": "LO",
				"Value":["${this.RS_FILE_KIND_IDENTIFIER}"]
			},
			"31111010": {
				"vr": "CS"
			},
			"311b0010":{
                "vr": "LO",
				"Value":["RamSoft Document Type Identifier"]
			},
			"311b1010":{
                "vr": "LO",
				"Value":["DIAGNOSTIC REPORT"]
			},
			"31290010": {
				"vr": "LO",
				"Value":["${this.RS_RACE_IDENTIFIER}"]
			},
			"31291010": {
				"vr": "LO",
				"Value":["${patientModelLoaded.race !== '' ? patientModelLoaded.race : 'UNK'}"]
			},
			"4008010a": {
				"vr": "PN"
			},
			"7fe00000": {
				"vr": "UL"
			}${pixelData}
		}`;

		return dicomJson;
	};

	importFiles = async (nodeList, patientDataLoader, studyDataLoader, url) => {
		console.log('importing files ....');
		this.clearQueue();
		await this.generateDicomJsons(nodeList, url);
		this.startImport();
	};

	importNonDicomFiles = async (nodeList, url, payload) => {
		console.log('importing non dicom files ....');
		this.clearQueue();
		await this.generateJsons(nodeList, url, payload);
		this.startImport();
	};

	importBlumeDicomFiles = async (nodeList, url, payload) => {
		console.log('import dicom files ...');
		this.clearQueue();
		const dicomJson = 'BLUME_DICOM';
		for (let item of nodeList) {
			await this.importQueue.add({
				item,
				dicomJson,
				url: url,
				payload,
			});
		}
		this.startImport();
	};

	clearQueue = () => {
		//clear queue and data before importing
		this.uploadSize = 0;
		this.totalFileSize = 0;
		this.progress = 0;
		this.errorMessage = [];
		this.uploadStatus = [];
		this.uploadProgress = [];
	};

	startImport = () => {
		this.coordinatorStartImport('importing'); // Starting a new import
	};

	stopImport = () => {
		this.importQueue.resetPriorityQueue();
		this.importQueue.fileQueue = [];
		this.coordinatorWorker.postMessage(['stopImport', null]);
		this.importing = false;
	};
	// post message to coordinator worker 3x number of work files no directories should be sent to coordinator worker
	// coordinator worker should start right away after receiving the message
	coordinatorStartImport = state => {
		console.log('populating files');
		console.log('NUM_WORKERS' + this.NUM_WORKERS * 3);
		this.importing = true;
		this.importQueue.populateFiles(this.NUM_WORKERS * 3, state);
	};

	sendFiles = async records => {
		console.log('import tasks are ready');
		await this.getOrRenewWorkerAccessToken();
		console.log('startImport', records);
		this.coordinatorWorker.postMessage(['startImport', records]);
	};

	//Msal will auto handle token.
	//if it's expired or close to expiration, acquireTokenSilent will renew token. if not token will be get from cache
	getOrRenewWorkerAccessToken = async () => {
		try {
			let data = await this.instance?.acquireTokenSilent({
				scopes: this.__config.auth?.omegaai.scopes,
				account: this.instance.getActiveAccount(),
			});

			if (!!data && `${data.tokenType} ${data.accessToken}` !== this.accessToken) {
				let newToken = `${data.tokenType} ${data.accessToken}`;
				this.coordinatorWorker.postMessage(['accessToken', newToken]);
				this.updateAccessToken(newToken);
			}
			console.log('getOrRenewWorkerAccessToken success!');
		} catch (errors) {
			console.log('getOrRenewWorkerAccessToken' + errors);
		}
	};

	pauseFileUpload = fileName => {
		this.coordinatorWorker.postMessage(['pauseImport', fileName]);
	};

	resumeFileUpload = fileName => {
		this.importing = true;
		this.coordinatorWorker.postMessage(['resumeFileUpload', fileName]);
	};

	cancelFileUpload = fileName => {
		this.coordinatorWorker.postMessage(['cancelFileUpload', fileName]);
	};

	restartFailedUpload = fileName => {
		this.coordinatorWorker.postMessage(['restartFailedUpload', fileName]);
	};

	updateProgressBar = (worker, byteSent, progress, fileName, queueDataToPost, status) => {
		if (this.importing) {
			this.uploadSize = this.uploadSize + byteSent;
			this.updateTotalProgressBar();
			this.updateFileProgress(queueDataToPost, progress, status);
		}
	};

	updateFileProgress = (queueData, progress, status) => {
		let fileName = queueData.fullPath ? queueData.fullPath : queueData.item.name;
		const fileIndex = this.uploadProgress.findIndex(_element => _element.fileName === fileName);
		let fileUploadItem = {
			fileName: fileName,
			progress: progress,
			status: status,
		};
		if (fileIndex > -1) this.uploadProgress[fileIndex] = fileUploadItem;
		else this.uploadProgress.push(fileUploadItem);
	};

	updateTotalProgressBar = () => {
		if (this.importing) {
			let progress = this.totalFileSize > 0 ? (this.uploadSize / this.totalFileSize) * 100 : 0;

			this.progress = Math.min(Math.max(_.round(progress), 0), 100);
			console.log('progress', progress);
		}
	};

	parseError = messageEvent => {
		const errorCodes = {
			'-1': 'Unknown error',
			272: 'Processing failure',
			281: 'SOPInstanceUID Conflict',
			290: 'Referenced SOP Class not supported',
			400: '400 - Bad request',
			401: '401 - Unauthorized',
			404: '404 - Not found',
			406: '406 - Not acceptable',
			409: '409 - Conflict',
			410: '410 - Gone',
			413: '413 - Payload too large',
			422: '422 - Unprocessable entity',
			500: '500 - Internal server error',
			501: '501 - Not implemented',
			49442: 'Referenced Transfer Syntax not supported',
			42752: 'Refused out of Resources',
			43519: 'Data Set does not match SOP Class',
			49152: 'Bulk data could not be found',
			49153: 'Cannot understand',
		};

		return {
			status: messageEvent.response.status,
			statusText: errorCodes[messageEvent.response.status],
		};
	};

	importedCount = count => {
		if (count === 'dec') {
			this.importedFiles = this.importedFiles - 1;
		} else {
			this.importedFiles = this.importedFiles + 1;
		}
	};

	composeMessage = (fileName, codeStr, messageStr, isError, skipped) => {
		return `${skipped}, ${isError}, ${fileName}, ${codeStr}, ${messageStr}`;
	};
}

export default ImportDispatcher;
