import React from 'react';
import { Box, Stack, Typography, Tabs, tabsClasses, Tab } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export const SearchPanelContainer = props => {
	return (
		<Stack
			{...props}
			sx={{
				width: '521px',
				maxHeight: '650px',
				borderRadius: '30px',
				background: '#FFFFFF',
				filter: 'drop-shadow(0px 0px 20px rgba(88, 119, 54, 0.129))',
				zIndex: 60,
			}}
		/>
	);
};

export const PanelHeader = props => {
	return (
		<Stack
			{...props}
			direction={'row'}
			sx={{
				width: '521px',
				height: '43px',
				borderRadius: '30px 30px 0 0',
				boxShadow: '0px 0px 10px rgba(33, 88, 119, 0.1)',
				alignItems: 'center',

				input: {
					padding: '1px 2px !important',
					background: 'transparent !important',
					caretColor: '#4D79EA',
				},
			}}
		/>
	);
};

export const SearchBody = props => {
	return (
		<Stack
			{...props}
			spacing={3}
			sx={{
				width: '480px',
				mt: '20px',
				mx: '22px',
			}}
		/>
	);
};

export const SearchTabs = props => {
	return (
		<Stack
			{...props}
			spacing={1}
			alignItems={'flex-start'}
			justifyContent={'center'}
			sx={{
				maxWidth: '480px',
			}}
		/>
	);
};

export const SectionTitle = props => {
	return (
		<Typography
			{...props}
			sx={{
				fontSize: '12px',
				fontWeight: 500,
				letterSpacing: '1px',
				color: 'rgba(0, 0, 0, 0.4)',
				textTransform: 'Uppercase',
			}}
		/>
	);
};

export const TabsContainer = props => {
	return (
		<Tabs
			{...props}
			variant="scrollable"
			scrollButtons
			disableRipple
			TabIndicatorProps={{
				style: {
					display: 'none',
				},
			}}
			TabScrollButtonProps={{
				style: {
					color: 'rgba(0, 0, 0, 0.9)',
					background: 'transparent',
					width: '20px',
					borderRadius: '50px',
				},
			}}
			sx={{
				minHeight: 0,
				maxWidth: '480px',
				[`& .${tabsClasses.scrollButtons}`]: {
					'&.Mui-disabled': { opacity: 0.3 },
				},
			}}
		/>
	);
};

export const SearchTab = props => {
	return (
		<Tab
			{...props}
			iconPosition="start"
			disableRipple
			sx={{
				height: '32px',
				width: '110px',
				minHeight: '32px',
				fontSize: '14px',
				fontWeight: 500,
				textTransform: 'Capitalize',
				color: props?.included ? 'rgba(77, 121, 234, 1)' : 'rgba(0, 0, 0, 0.6)',
				background: props?.included ? 'rgba(77, 121, 234, 0.05)' : 'rgba(225, 225, 225, 0.21)',
				border: props?.included ? '1px solid #4D79EA' : 'none',
				borderRadius: '16px',
				marginRight: '5px',
				'&.Mui-selected': {
					color: 'rgba(77, 121, 234, 1)',
					background: 'rgba(77, 121, 234, 0.05)',
					border: '1px solid #4D79EA',
				},
			}}
		/>
	);
};

export const RecentBody = props => {
	return (
		<Box
			{...props}
			sx={{
				maxHeight: '417px',
				marginBottom: '24px !important',
				overflowY: 'scroll',
				scrollbarWidth: 'none',
				'::-webkit-scrollbar': {
					width: 0,
					height: 0,
				},
			}}
		/>
	);
};

export const RecentSearchContainer = props => {
	return (
		<Stack
			{...props}
			direction={'row'}
			alignItems={'center'}
			spacing={'18px'}
			mt={'10px'}
			sx={{
				cursor: 'pointer',
			}}
		/>
	);
};

export const RecentEntry = props => {
	return (
		<Typography
			{...props}
			sx={{
				fontSize: '16px',
				fontWeight: 500,
				color: 'rgba(0, 0, 0, 0.9)',
			}}
		/>
	);
};

export const SearchResultContainer = props => {
	return (
		<Stack
			{...props}
			spacing={'18px'}
			sx={{
				maxHeight: '428px',
				overflowY: 'scroll',
				scrollbarWidth: 'none',
				'::-webkit-scrollbar': {
					width: 0,
					height: 0,
				},
			}}
		/>
	);
};

export const SearchResult = props => {
	return <Stack {...props} direction={'row'} mt={1} spacing={1} />;
};

export const SearchResultTitle = props => {
	return (
		<Typography
			{...props}
			sx={{
				fontSize: '16px',
				fontWeight: 500,
				color: 'rgba(0, 0, 0, 0.9)',
			}}
		/>
	);
};

export const SearchResultDescription = props => {
	return (
		<Typography
			{...props}
			sx={{
				fontSize: '14px',
				fontWeight: 400,
				color: 'rgba(0, 0, 0, 0.9)',
			}}
		/>
	);
};

export const SearchResultComment = props => {
	return (
		<Typography
			{...props}
			sx={{
				fontSize: '14px',
				fontWeight: 500,
				fontStyle: 'italic',
				color: 'rgba(0, 0, 0, 0.9)',
				pr: '2px',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				display: '-webkit-box',
				WebkitLineClamp: '1',
				WebkitBoxOrient: 'vertical',
			}}
		/>
	);
};

export const SearchPanelFooter = props => {
	return (
		<Stack alignItems={'center'} my={'1rem'}>
			<Typography
				{...props}
				sx={{
					fontSize: '14px',
					fontWeight: 400,
					color: 'rgba(77, 121, 234, 1)',
					cursor: 'pointer',
				}}
			/>
		</Stack>
	);
};

export const NoResults = props => {
	return (
		<Typography {...props} mb={'24px'}>
			No Results
		</Typography>
	);
};

export const ShowSpinner = props => {
	return (
		<Stack {...props} direction={'row'} alignItems={'center'} spacing={2} mb={'24px'}>
			<CircularProgress
				sx={{
					color: '#4D79EA',
					width: '24px !important',
					height: '24px !important',
				}}
			/>
			<Typography>{props.text || 'Loading'}</Typography>
		</Stack>
	);
};
