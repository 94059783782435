import {
	AnnotationTool,
	annotation,
	ToolGroupManager,
	state,
	Enums as ENUMS,
	utilities,
	cursors,
	drawing,
} from '@cornerstonejs/tools';
import {
	getEnabledElementByIds,
	Enums,
	getEnabledElement,
	utilities as csUtils,
	eventTarget,
} from '@cornerstonejs/core';
import { vec2 } from 'gl-matrix';

const { Events } = ENUMS;
const { addAnnotation, removeAnnotation, getAnnotations } = annotation.state;

function drawAnnotation(svgDrawingHelper, annotationUID, crosshairCenterCanvas) {
	const RADIUS = 10;
	const LINE_INNER_WIDTH = 1.37;
	const LINE_OUTER_WIDTH = 2.37;
	const COLOR_1 = 'rgb(3, 218, 197)';
	const COLOR_2 = 'rgb(247, 247, 247)';
	const dShort = RADIUS / 2;
	const dLong = RADIUS + RADIUS / 2;

	drawing.drawCircle(svgDrawingHelper, annotationUID, `circleOuter`, vec2.clone(crosshairCenterCanvas), RADIUS, {
		color: COLOR_2,
		lineWidth: LINE_INNER_WIDTH,
	});
	drawing.drawCircle(svgDrawingHelper, annotationUID, `circleInner`, vec2.clone(crosshairCenterCanvas), RADIUS, {
		color: COLOR_1,
		lineWidth: LINE_INNER_WIDTH,
		lineDash: '5 5',
	});

	const top1out = vec2.create();
	const top2out = vec2.create();
	vec2.set(top1out, crosshairCenterCanvas[0], crosshairCenterCanvas[1] - dShort + 1);
	vec2.set(top2out, crosshairCenterCanvas[0], crosshairCenterCanvas[1] - dLong - 1);
	drawing.drawLine(svgDrawingHelper, annotationUID, `topLineOut`, top1out, top2out, {
		color: COLOR_2,
		lineWidth: LINE_OUTER_WIDTH,
	});
	const top1 = vec2.create();
	const top2 = vec2.create();
	vec2.set(top1, crosshairCenterCanvas[0], crosshairCenterCanvas[1] - dShort);
	vec2.set(top2, crosshairCenterCanvas[0], crosshairCenterCanvas[1] - dLong);
	drawing.drawLine(svgDrawingHelper, annotationUID, `topLine`, top1, top2, {
		color: COLOR_1,
		lineWidth: LINE_INNER_WIDTH,
	});

	const bottom1out = vec2.create();
	const bottom2out = vec2.create();
	vec2.set(bottom1out, crosshairCenterCanvas[0], crosshairCenterCanvas[1] + dShort - 1);
	vec2.set(bottom2out, crosshairCenterCanvas[0], crosshairCenterCanvas[1] + dLong + 1);
	drawing.drawLine(svgDrawingHelper, annotationUID, `bottomLineOut`, bottom1out, bottom2out, {
		color: COLOR_2,
		lineWidth: LINE_OUTER_WIDTH,
	});
	const bottom1 = vec2.create();
	const bottom2 = vec2.create();
	vec2.set(bottom1, crosshairCenterCanvas[0], crosshairCenterCanvas[1] + dShort);
	vec2.set(bottom2, crosshairCenterCanvas[0], crosshairCenterCanvas[1] + dLong);
	drawing.drawLine(svgDrawingHelper, annotationUID, `bottomLine`, bottom1, bottom2, {
		color: COLOR_1,
		lineWidth: LINE_INNER_WIDTH,
	});

	const left1out = vec2.create();
	const left2out = vec2.create();
	vec2.set(left1out, crosshairCenterCanvas[0] - dShort + 1, crosshairCenterCanvas[1]);
	vec2.set(left2out, crosshairCenterCanvas[0] - dLong - 1, crosshairCenterCanvas[1]);
	drawing.drawLine(svgDrawingHelper, annotationUID, `leftLineOut`, left1out, left2out, {
		color: COLOR_2,
		lineWidth: LINE_OUTER_WIDTH,
	});
	const left1 = vec2.create();
	const left2 = vec2.create();
	vec2.set(left1, crosshairCenterCanvas[0] - dShort, crosshairCenterCanvas[1]);
	vec2.set(left2, crosshairCenterCanvas[0] - dLong, crosshairCenterCanvas[1]);
	drawing.drawLine(svgDrawingHelper, annotationUID, `leftLine`, left1, left2, {
		color: COLOR_1,
		lineWidth: LINE_INNER_WIDTH,
	});

	const right1out = vec2.create();
	const right2out = vec2.create();
	vec2.set(right1out, crosshairCenterCanvas[0] + dShort - 1, crosshairCenterCanvas[1]);
	vec2.set(right2out, crosshairCenterCanvas[0] + dLong + 1, crosshairCenterCanvas[1]);
	drawing.drawLine(svgDrawingHelper, annotationUID, `rightLineOut`, right1out, right2out, {
		color: COLOR_2,
		lineWidth: LINE_OUTER_WIDTH,
	});
	const right1 = vec2.create();
	const right2 = vec2.create();
	vec2.set(right1, crosshairCenterCanvas[0] + dShort, crosshairCenterCanvas[1]);
	vec2.set(right2, crosshairCenterCanvas[0] + dLong, crosshairCenterCanvas[1]);
	drawing.drawLine(svgDrawingHelper, annotationUID, `rightLine`, right1, right2, {
		color: COLOR_1,
		lineWidth: LINE_INNER_WIDTH,
	});
}

function isValidModalityForTool(modality) {
	return ['CT', 'PT', 'MR'].includes(modality);
}

class CrosshairPointerTool extends AnnotationTool {
	static toolName;

	constructor(
		toolProps = {},
		defaultToolProps = {
			supportedInteractionTypes: ['Mouse'],
			configuration: {
				mobile: {
					enabled: false,
				},
			},
		}
	) {
		super(toolProps, defaultToolProps);
	}

	initializeViewport = ({ renderingEngineId, viewportId }) => {
		const enabledElement = getEnabledElementByIds(viewportId, renderingEngineId);
		const { FrameOfReferenceUID, viewport } = enabledElement;
		const { element, modality } = viewport;
		const { position, focalPoint } = viewport.getCamera();
		const { seriesId } = viewport.options;

		let annotations = this._getAnnotations(enabledElement);
		annotations = this.filterInteractableAnnotationsForElement(element, annotations);

		if (annotations.length) {
			// If found, it will override it by removing the annotation and adding it later
			removeAnnotation(annotations[0].annotationUID);
		}

		const annotation = {
			highlighted: false,
			metadata: {
				cameraPosition: [...position],
				cameraFocalPoint: [...focalPoint],
				FrameOfReferenceUID,
				toolName: this.getToolName(),
			},
			data: {
				handles: {},
				viewportId,
				seriesId,
				modality,
			},
		};

		addAnnotation(annotation, element);
	};

	_getViewportsInfo = () => {
		const viewportsInfo = [];

		const toolGroups = ToolGroupManager.getAllToolGroups();

		toolGroups?.forEach(toolGroup => {
			if (!toolGroup) {
				return;
			}

			if (!toolGroup._toolInstances[CrosshairPointerTool.toolName]) {
				return;
			}

			if (toolGroup.viewportsInfo?.length) {
				viewportsInfo.push(...toolGroup.viewportsInfo);
			}
		});

		return viewportsInfo;
	};

	_getAnnotations = enabledElement => {
		const { viewport } = enabledElement;
		const annotations = getAnnotations(this.getToolName(), viewport.element) || [];
		const viewportIds = this._getViewportsInfo().map(({ viewportId }) => viewportId);

		const toolGroupAnnotations = annotations.filter(annotation => {
			const { data } = annotation;
			return viewportIds.includes(data.viewportId);
		});

		return toolGroupAnnotations;
	};

	onSetToolActive() {
		this._unsubscribeToViewportNewStackSet();
		this._subscribeToViewportNewStackSet();

		const viewportsInfo = this._getViewportsInfo();
		this.initializeViewports(viewportsInfo);
	}

	_unsubscribeToViewportNewStackSet() {
		eventTarget.removeEventListener(Enums.Events.STACK_VIEWPORT_NEW_STACK, this._onNewStack);
	}

	_subscribeToViewportNewStackSet() {
		eventTarget.addEventListener(Enums.Events.STACK_VIEWPORT_NEW_STACK, this._onNewStack);
	}

	_onNewStack = () => {
		const viewportsInfo = this._getViewportsInfo();
		this.initializeViewports(viewportsInfo);
	};

	onSetToolEnabled() {
		const viewportsInfo = this._getViewportsInfo();
		this.initializeViewports(viewportsInfo);
	}

	onSetToolPassive() {
		this.clickCenter = null;
		const viewportsInfo = this._getViewportsInfo();

		viewportsInfo.forEach(({ renderingEngineId, viewportId }) => {
			const enabledElement = getEnabledElementByIds(viewportId, renderingEngineId);

			if (!enabledElement) {
				return;
			}

			const annotations = this._getAnnotations(enabledElement);

			if (annotations?.length) {
				annotations.forEach(annotation => {
					removeAnnotation(annotation.annotationUID);
				});
			}
		});
	}

	onSetToolDisabled() {
		const viewportsInfo = this._getViewportsInfo();

		this._unsubscribeToViewportNewStackSet();
		viewportsInfo.forEach(({ renderingEngineId, viewportId }) => {
			const enabledElement = getEnabledElementByIds(viewportId, renderingEngineId);

			if (!enabledElement) {
				return;
			}

			const annotations = this._getAnnotations(enabledElement);

			if (annotations?.length) {
				annotations.forEach(annotation => {
					removeAnnotation(annotation.annotationUID);
				});
			}
		});
	}

	initializeViewports = viewportsInfo => {
		viewportsInfo.forEach(viewportInfo => this.initializeViewport(viewportInfo));
	};

	addNewAnnotation = evt => {
		const { currentPoints, element } = evt.detail;
		const enabledElement = getEnabledElement(element);
		const { viewport } = enabledElement;

		const annotations = this._getAnnotations(enabledElement);
		const filteredAnnotations = this.filterInteractableAnnotationsForElement(viewport.element, annotations);
		const [filteredAnnotation] = filteredAnnotations;

		if (isValidModalityForTool(filteredAnnotation.data.modality)) {
			const toolGroups = ToolGroupManager.getAllToolGroups();

			toolGroups?.forEach(toolGroup => {
				if (!toolGroup) {
					return;
				}

				if (!toolGroup._toolInstances[CrosshairPointerTool.toolName]) {
					return;
				}

				toolGroup._toolInstances[CrosshairPointerTool.toolName].clickCenter = currentPoints.world;
			});

			this._jump(enabledElement);
			cursors.elementCursor.hideElementCursor(element);
			this._activateModify(element);
		}

		evt.preventDefault();

		return filteredAnnotation;
	};

	mouseMoveCallback = () => {
		return false;
	};

	renderAnnotation = (enabledElement, svgDrawingHelper) => {
		let renderStatus = false;
		const { viewport } = enabledElement;
		const { element } = viewport;
		const annotations = this._getAnnotations(enabledElement);
		const filteredToolAnnotations = this.filterInteractableAnnotationsForElement(element, annotations);

		const viewportAnnotation = filteredToolAnnotations[0];
		if (
			!annotations?.length ||
			!viewportAnnotation?.data ||
			!isValidModalityForTool(viewportAnnotation.data.modality)
		) {
			// No annotations yet, and didn't just create it as we likely don't have a FrameOfReference/any data loaded yet.
			return renderStatus;
		}

		const { annotationUID } = viewportAnnotation;
		if (this.clickCenter) {
			const crosshairCenterCanvas = viewport.worldToCanvas(this.clickCenter);
			drawAnnotation(svgDrawingHelper, annotationUID, crosshairCenterCanvas);
			renderStatus = true;
		}

		return renderStatus;
	};

	getHandleNearImagePoint() {
		return undefined;
	}

	isPointNearTool = () => {
		return false;
	};

	_jump = enabledElement => {
		state.isInteractingWithTool = true;
		const { renderingEngine } = enabledElement;

		const annotations = this._getAnnotations(enabledElement);

		const otherViewportAnnotations = this._getAnnotationsForViewportsWithDifferentCameras(
			enabledElement,
			annotations
		);

		this._applyDeltaShiftToSelectedViewportCameras(renderingEngine, otherViewportAnnotations);

		state.isInteractingWithTool = false;

		return true;
	};

	_getAnnotationsForViewportsWithDifferentCameras = (enabledElement, annotations) => {
		const { viewportId, renderingEngine, viewport } = enabledElement;

		const otherViewportAnnotations = annotations.filter(annotation => annotation.data.viewportId !== viewportId);

		if (!otherViewportAnnotations || !otherViewportAnnotations.length) {
			return [];
		}

		const camera = viewport.getCamera();
		const { viewPlaneNormal, position } = camera;

		const viewportsWithDifferentCameras = otherViewportAnnotations.filter(annotation => {
			const { viewportId } = annotation.data;
			const targetViewport = renderingEngine.getViewport(viewportId);
			const cameraOfTarget = targetViewport.getCamera();

			return !(
				csUtils.isEqual(cameraOfTarget.viewPlaneNormal, viewPlaneNormal, 1e-2) &&
				csUtils.isEqual(cameraOfTarget.position, position, 1)
			);
		});

		return viewportsWithDifferentCameras;
	};

	filterInteractableAnnotationsForElement = (element, annotations) => {
		if (!annotations || !annotations.length) {
			return [];
		}

		const enabledElement = getEnabledElement(element);
		const { viewportId } = enabledElement;

		const viewportUIDSpecificCrosshairs = annotations.filter(
			annotation => annotation.data.viewportId === viewportId
		);

		return viewportUIDSpecificCrosshairs;
	};

	_activateModify = element => {
		state.isInteractingWithTool = !this.configuration.mobile?.enabled;

		element.addEventListener(Events.MOUSE_UP, this._endCallback);
		element.addEventListener(Events.MOUSE_DRAG, this._dragCallback);
		element.addEventListener(Events.MOUSE_CLICK, this._endCallback);

		element.addEventListener(Events.TOUCH_END, this._endCallback);
		element.addEventListener(Events.TOUCH_DRAG, this._dragCallback);
		element.addEventListener(Events.TOUCH_TAP, this._endCallback);
	};

	_deactivateModify = element => {
		state.isInteractingWithTool = false;

		element.removeEventListener(Events.MOUSE_UP, this._endCallback);
		element.removeEventListener(Events.MOUSE_DRAG, this._dragCallback);
		element.removeEventListener(Events.MOUSE_CLICK, this._endCallback);

		element.removeEventListener(Events.TOUCH_END, this._endCallback);
		element.removeEventListener(Events.TOUCH_DRAG, this._dragCallback);
		element.removeEventListener(Events.TOUCH_TAP, this._endCallback);
	};

	_applyDeltaShiftToSelectedViewportCameras(renderingEngine, viewportsAnnotationsToUpdate) {
		viewportsAnnotationsToUpdate.forEach(annotation => {
			this._applyDeltaShiftToViewportCamera(renderingEngine, annotation);
		});
	}

	_applyDeltaShiftToViewportCamera(renderingEngine, annotation) {
		const { data } = annotation;
		const viewport = renderingEngine.getViewport(data.viewportId);
		const index = csUtils.getClosestStackImageIndexForPoint(this.clickCenter, viewport);
		viewport.setImageIdIndex(index);
	}

	_dragCallback = evt => {
		const eventDetail = evt.detail;
		const delta = eventDetail.deltaPoints.world;

		if (Math.abs(delta[0]) < 1e-3 && Math.abs(delta[1]) < 1e-3 && Math.abs(delta[2]) < 1e-3) {
			return;
		}

		const { element } = eventDetail;
		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;
		const annotations = this._getAnnotations(enabledElement);
		const filteredToolAnnotations = this.filterInteractableAnnotationsForElement(element, annotations);

		const viewportAnnotation = filteredToolAnnotations[0];
		if (!viewportAnnotation) {
			return;
		}

		const otherViewportAnnotations = this._getAnnotationsForViewportsWithDifferentCameras(
			enabledElement,
			annotations
		);

		const toolGroups = ToolGroupManager.getAllToolGroups();

		toolGroups?.forEach(toolGroup => {
			if (!toolGroup) {
				return;
			}

			if (!toolGroup._toolInstances[CrosshairPointerTool.toolName]) {
				return;
			}

			toolGroup._toolInstances[CrosshairPointerTool.toolName].clickCenter = eventDetail.currentPoints.world;
		});

		utilities.triggerAnnotationRenderForViewportIds(renderingEngine, [enabledElement.viewport.id]);
		this._applyDeltaShiftToSelectedViewportCameras(renderingEngine, otherViewportAnnotations);
	};

	_endCallback = evt => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		this._deactivateModify(element);

		cursors.elementCursor.resetElementCursor(element);

		this.editData = null;

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		const requireSameOrientation = false;
		const viewportIdsToRender = utilities.viewportFilters.getViewportIdsWithToolToRender(
			element,
			this.getToolName(),
			requireSameOrientation
		);

		utilities.triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
	};
}

CrosshairPointerTool.toolName = 'CrosshairPointer';

cursors.registerCursor(
	CrosshairPointerTool.toolName,
	cursors.CursorSVG.Crosshairs.iconContent,
	cursors.CursorSVG.Crosshairs.viewBox
);

export default CrosshairPointerTool;
