import React, { createContext, useState, useContext } from 'react';

const ToastMessageContext = createContext({});

export const ToastMessageProvider = ({ children }) => {
	const [toastMsg, setToastMsg] = useState();
	const [type, setType] = useState();

	return (
		<ToastMessageContext.Provider value={{ toastMsg, setToastMsg, type, setType }}>
			{children}
		</ToastMessageContext.Provider>
	);
};

export const useToastMessageContext = () => useContext(ToastMessageContext);
export default ToastMessageProvider;
