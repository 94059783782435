import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Typography, IconButton, Box, Button, MenuItem, Menu, Divider, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { styled } from '@mui/material/styles';

const MenuTextField = styled(Typography)({
	marginRight: 'auto',
	maxWidth: '130px',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	fontStyle: 'normal',
	letterSpacing: '1.25px',
});

function VisualMeasureBuilder({ measureDisabled, multiMeasureItem, setMultiMeasureItem }) {
	const [anchorEl, setAnchorEl] = useState(null);
	// this flag is used to avoid closing the menu when removing the first item
	const [isRemoveFirstItem, setIsRemoveFirstItem] = useState(false);

	function handleClick(event) {
		if (anchorEl !== event.currentTarget) {
			setAnchorEl(event.currentTarget);
		}
	}

	function handleClose() {
		if (!isRemoveFirstItem) {
			setAnchorEl(null);
		}
		setIsRemoveFirstItem(false);
	}

	function onRemoveField(index) {
		const temp = [...multiMeasureItem];

		setIsRemoveFirstItem(temp.length == 4 && (index == 0 || index == 1));
		temp.splice(index, 1);
		setMultiMeasureItem(temp);
		if (temp.length == 0) {
			handleClose();
		}
	}

	return (
		<Box data-testid="multimeasure_drop">
			<Droppable
				droppableId="multimeasure_drop"
				isDropDisabled={measureDisabled || multiMeasureItem.length == 4} // should disable Drop once 4 fileds are added
			>
				{provided => (
					<Box
						{...provided.droppableProps}
						ref={provided.innerRef}
						sx={{
							color: 'rgba(255, 255, 255, 0.6)',
							height: 40,
							width: 150,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginRight: '-30px',
							transform: 'rotate(270deg)',
							fontSize: '1rem',
						}}
					>
						{multiMeasureItem && multiMeasureItem.length > 0 ? (
							<>
								<Box
									sx={{
										display: 'inherit',
										background: '#393939',
										borderRadius: '5px',
										padding: '7px 7px 7px 7px',
										marginRight: '5px',
										'&:hover': {
											border: '1px solid #42A5F5',
										},
									}}
								>
									<Tooltip title={multiMeasureItem[multiMeasureItem.length - 1].shortTitle}>
										<Typography
											sx={{
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis',
												width: '110px',
												lineHeight: '1.8',
												overflow: 'hidden',
											}}
										>
											{multiMeasureItem[multiMeasureItem.length - 1].shortTitle}
										</Typography>
									</Tooltip>
									<IconButton
										sx={{
											borderRight: '1px solid rgba(255, 255, 255, 0.2)',
											padding: '0px 5px 0px 0px',
											whiteSpace: 'nowrap',
											lineHeight: '1.3',
											minWidth: 'unset',
											color: 'rgba(255, 255, 255, 0.6)',
											borderRadius: 'unset',
											background: 'unset',
											'&:hover': {
												backgroundColor: '#393939',
												color: '#fff',
											},
										}}
										onClick={() => {
											onRemoveField(multiMeasureItem.length - 1);
										}}
									>
										<CancelIcon />
									</IconButton>
									<Button
										aria-haspopup="true"
										aria-owns={anchorEl ? 'simple-menu' : undefined}
										sx={{
											whiteSpace: 'nowrap',
											lineHeight: '1.3',
											minWidth: 'unset',
											color: 'rgba(255, 255, 255, 0.6)',
											padding: 'unset',
											marginLeft: '5px',
											'&:hover': {
												color: '#fff',
											},
										}}
										onMouseOver={handleClick}
									>
										{`(${multiMeasureItem.length})`}
									</Button>
									<Menu
										MenuListProps={{
											onMouseLeave: handleClose,
										}}
										anchorEl={anchorEl}
										anchorOrigin={{
											vertical: -6,
											horizontal: 35,
										}}
										id="measures-menu"
										open={Boolean(anchorEl)}
										sx={{
											'& .MuiList-root': {
												border: '1px solid rgba(255, 255, 255, 0.1)',
												borderRadius: '6px',
												boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',
												padding: 'unset',
											},
											'& .MuiDivider-root': {
												margin: 'unset !important',
											},
										}}
										onClose={handleClose}
									>
										{multiMeasureItem.map((field, index) => (
											<>
												<MenuItem
													key={index}
													sx={{
														justifyContent: 'flex-end',
														padding: '8px 12px 8px 12px',
														display: 'flex',
														width: '210px',
														color: 'rgba(255, 255, 255, 0.6)',
													}}
												>
													<MenuTextField
														sx={{
															marginRight: '10px',
														}}
													>
														{index + 1}
													</MenuTextField>
													<Tooltip title={field.shortTitle}>
														<MenuTextField>{field.shortTitle}</MenuTextField>
													</Tooltip>
													<CancelIcon
														sx={{
															'&:hover': {
																color: '#fff',
															},
															right: 0,
														}}
														onClick={() => {
															onRemoveField(index);
														}}
													/>
													<Divider
														sx={{
															margin: 'unset',
														}}
													/>
												</MenuItem>
												{multiMeasureItem.length != 1 &&
													multiMeasureItem.length - 1 != index && (
														<Divider
															sx={{
																margin: 'unset',
															}}
														/>
													)}
											</>
										))}
									</Menu>
								</Box>
								{multiMeasureItem.length < 4 && ( // The “Add Field” should be hidden once 4 fileds are added
									<Box
										sx={{
											display: 'inherit',
											background: '#393939',
											border: '1px dashed rgba(255, 255, 255, 0.2)',
											borderRadius: '5px',
											padding: '7px 7px 7px 5px',
											'&:hover': {
												border: '1px solid #42A5F5',
											},
										}}
									>
										<AddCircleOutlineIcon />
										<Typography
											fontSize={14}
											sx={{
												textTransform: 'uppercase',
												whiteSpace: 'nowrap',
												marginLeft: '5px',
												lineHeight: '1.8',
											}}
										>
											add measure
										</Typography>
									</Box>
								)}
							</>
						) : (
							<Box
								sx={{
									display: 'flex',
									background: '#393939',
									border: '1px dashed rgba(255, 255, 255, 0.2)',
									borderRadius: '5px',
									padding: '7px 7px 7px 5px',
									'&:hover': {
										border: '1px solid #42A5F5',
									},
									width: '200px',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Typography
									fontSize={14}
									sx={{
										textTransform: 'uppercase',
									}}
								>
									measures
								</Typography>
							</Box>
						)}
					</Box>
				)}
			</Droppable>
			<Box />
		</Box>
	);
}

export default VisualMeasureBuilder;
