import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { DICOM_NATURALIZED_DICT } from '../../../consts/consts';

const useStyles = makeStyles()((_, props) => ({
	StudyDate: props?.isCurrentStudy ? {} : { filter: 'invert(1)', background: '#7B591E' },
	Acquisition: props?.isCurrentStudy ? {} : { filter: 'invert(1)', background: '#7B591E' },
	ViewcodeTag: props?.modality === 'MG' ? { fontSize: '28px', fontWeight: '600' } : {},
	row: {
		margin: 0,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		fontWeight: '500',
		fontSize: '14px',
		lineHeight: 1.1,
		letterSpacing: '0.25px',
		padding: '0 2px',
		'& *:not(:last-child)': {
			marginRight: '8px',
		},
	},
}));

export const Tags = ({ viewport, column, metadata, isCurrentStudy }) => {
	const { classes, cx } = useStyles({ modality: viewport?.modality, isCurrentStudy });
	const { id } = viewport;

	return column.map((row, index) => (
		<p key={`${id}-${index}`} className={cx(classes.row)}>
			{row.map(field => {
				if (field.tag) {
					const tag = DICOM_NATURALIZED_DICT[field.tag];
					if (tag) {
						if (tag.component) {
							return tag.component(id);
						}
						if (tag.value) {
							const tagValue = tag.value(metadata, viewport, isCurrentStudy);
							return tagValue ? (
								<span key={field.tag} className={cx(classes[field.tag])}>
									{tagValue}
								</span>
							) : null;
						}
						return null;
					}
					return null;
				}
				return <span key={field.label.replace(' ', '-')}>{field.label}</span>;
			})}
		</p>
	));
};
