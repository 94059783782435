import React, { useEffect } from 'react';
import axios from 'axios';

import { useConfig } from '@worklist-2/core/src';

import CategoryItem from '../CategoryItem/CategoryItem';
import { ActionButtons } from '../../index';
import { useHelpCenterContext } from '../../../HelpCenter/HelpCenterContext';

import styles from './CategoryBar.module.scss';

const CategoryBar = ({
	theme,
	activeCategory,
	setActiveCategory,
	activeButton,
	setActiveButton,
	setIsSectionsOpen,
	isSectionsOpen,
}) => {
	const { categories, setCategories } = useHelpCenterContext();

	const __config = useConfig();

	useEffect(() => {
		const getCategories = async () => {
			try {
				const { data } = await axios.get(`${__config.data_sources.breeze}/documentcategory`);
				const payload =
					theme === 'OAI'
						? data.entry.filter(i => i.resource.App === 'omegaai')
						: data.entry.filter(i => i.resource.App === 'blume');

				const result = [];
				payload.forEach(elem => {
					if (elem.resource.DocumentCategoryId) {
						result.push(elem.resource);
					}
				});

				const sortByUpdatedAt = items => [...items].sort((itemA, itemB) => itemA.Order - itemB.Order);
				const resultSorted = sortByUpdatedAt(result);
				setCategories(resultSorted);
			} catch (e) {
				console.error(e);
			}
		};

		getCategories();
	}, []);

	return (
		<div className={theme === 'OAI' ? styles.categoryOAI : styles.categoryBarBlume}>
			{categories.map(category => (
				<CategoryItem
					key={category.id}
					activeCategory={activeCategory}
					category={category}
					setActiveButton={setActiveButton}
					setActiveCategory={setActiveCategory}
					theme={theme}
				/>
			))}
			{activeCategory.category?.DocumentCategoryId && (
				<ActionButtons
					activeButton={activeButton}
					isSectionsOpen={isSectionsOpen}
					setActiveButton={setActiveButton}
					setIsSectionsOpen={setIsSectionsOpen}
					theme={theme}
				/>
			)}
		</div>
	);
};

export default CategoryBar;
