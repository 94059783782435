// @flow

// core
import React from 'react';
// components
import Grid from '@mui/material/Grid';
import { LoaderSkeleton, type ISkeletonProps } from './LoaderSkeleton';

/** Skeleton loader used in `PatientAllergies.jsx` (the Allergy tab in patient info page) */
export const LoaderPatientAllergies = ({ children, isLoading }: ISkeletonProps): any =>
	isLoading ? (
		<Grid container spacing="20px">
			{[1, 2, 3].map(i => (
				<Grid key={`patient_alert_skeleton_${i}`} item sx={{ minWidth: '300px' }} xs={4}>
					<LoaderSkeleton sx={{ height: '260px' }} />
				</Grid>
			))}
		</Grid>
	) : (
		children
	);
