import React, { useState, useRef } from 'react';
import { IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const InputCode = ({ length, className, onClose }) => {
	const [code, setCode] = useState([...Array(length)].map(() => ''));
	const inputs = useRef([]);

	const processInput = (e, slot) => {
		const num = e.target.value;
		if (/[^0-9]/.test(num)) return;
		const newCode = [...code];
		newCode[slot] = num;
		setCode(newCode);
		if (slot !== length - 1) {
			inputs.current[slot + 1].focus();
		}
	};

	const onKeyUp = (e, slot) => {
		if (e.keyCode === 8 && !code[slot] && slot !== 0) {
			const newCode = [...code];
			newCode[slot - 1] = '';
			setCode(newCode);
			inputs.current[slot - 1].focus();
		}
	};

	return (
		<div
			className={className}
			data-testid="classNametest"
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{code.map((num, idx) => (
				<input
					key={idx}
					ref={ref => inputs.current.push(ref)}
					autoFocus={!code[0].length && idx === 0}
					inputMode="numeric"
					maxLength={1}
					style={{
						margin: '0 7px',
						width: '54px',
						height: '57px',
						backgroundColor: 'rgba(77, 121, 234, 0.05)',
						borderRadius: '10px',
						fontFamily: 'Inter',
						fontSize: '30px',
						fontWeight: '500',
						lineHeight: '36px',
						color: '#4D79EA',
					}}
					type="text"
					value={num}
					onChange={e => processInput(e, idx)}
					onKeyUp={e => onKeyUp(e, idx)}
				/>
			))}
			<IconButton
				data-testid="closeBtn"
				disabled={code.some(num => num === '')}
				sx={{
					marginLeft: '10px',
					padding: 0,
					backgroundColor: '#4D79EA',
					opacity: '1',
					color: '#FFFFFF',
					'&.Mui-disabled': {
						backgroundColor: '#4D79EA',
						color: '#FFFFFF',
						opacity: '0.4',
					},
				}}
				onClick={onClose}
			>
				<CheckIcon sx={{ fontSize: '20px' }} />
			</IconButton>
		</div>
	);
};

export default InputCode;
