import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SaveIcon from '@mui/icons-material/Save';
import PropTypes from 'prop-types';

const PrimaryButton = ({
	label,
	options,
	placement,
	width,
	onClick,
	onMenuSelected,
	disabled = false,
	isLoading,
	testId,
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	let anchorOriginVertical;
	let anchorOriginHorizontal;
	let transformOriginVertical;
	let transformOriginHorizontal;

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleMenuSelected = (event, option, index) => {
		if (onMenuSelected) {
			onMenuSelected(option, index);
		}
	};

	switch (placement) {
		case 'top-left':
			anchorOriginVertical = 'top';
			anchorOriginHorizontal = 'right';
			transformOriginVertical = 'bottom';
			transformOriginHorizontal = 'right';
			break;
		case 'top-right':
			anchorOriginVertical = 'top';
			anchorOriginHorizontal = 'right';
			transformOriginVertical = 'bottom';
			transformOriginHorizontal = 'left';
			break;
		case 'bottom-left':
			anchorOriginVertical = 'bottom';
			anchorOriginHorizontal = 'right';
			transformOriginVertical = 'top';
			transformOriginHorizontal = 'right';
			break;
		case 'bottom-right':
			anchorOriginVertical = 'bottom';
			anchorOriginHorizontal = 'right';
			transformOriginVertical = 'top';
			transformOriginHorizontal = 'left';
			break;
	}

	return (
		<ButtonGroup
			aria-label="split button"
			color="rsPrimary"
			data-testid={testId}
			sx={{
				'.MuiButton-root.MuiButtonGroup-grouped:not(:last-of-type)': {
					borderRight: 'none',
					'&::after': {
						content: "''",
						position: 'absolute',
						opacity: 0.1,
						right: 0,
						top: '30%',
						bottom: '30%',
						borderRight: '1px solid #ffffff',
					},
				},
			}}
			variant="contained"
		>
			{isLoading ? (
				<LoadingButton
					loading
					data-cy={`${label}_${options}`}
					data-testid={`${label}_${options}`}
					loadingPosition="start"
					startIcon={<SaveIcon />}
					sx={{ width: { width } }}
					variant="outlined"
				>
					{label}
				</LoadingButton>
			) : (
				<Button
					data-cy={`${label}_${options}`}
					data-testid={`${label}_${options}`}
					disabled={disabled}
					sx={{ width: { width } }}
					onClick={onClick}
				>
					{label}
				</Button>
			)}
			{options.length > 0 && (
				<Button
					aria-expanded={open ? 'true' : 'false'}
					aria-haspopup="menu"
					aria-label="Primary Button Menu"
					data-cy="PrimaryExpand"
					data-testid="PrimaryExpand"
					size="small"
					onClick={handleClick}
				>
					<ArrowDropDownIcon data-cy="ArrowDropDownIcon" />
				</Button>
			)}
			{options.length > 0 && (
				<Menu
					MenuListProps={{
						'aria-labelledby': 'Primary Button Menu',
					}}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: anchorOriginVertical,
						horizontal: anchorOriginHorizontal,
					}}
					id="basic-menu"
					open={open}
					transformOrigin={{
						vertical: transformOriginVertical,
						horizontal: transformOriginHorizontal,
					}}
					onClick={handleClose}
				>
					{options.map((option, index) => (
						<MenuItem key={option} onClick={event => handleMenuSelected(event, option, index)}>
							{option}
						</MenuItem>
					))}
				</Menu>
			)}
		</ButtonGroup>
	);
};

PrimaryButton.propTypes = {
	/**
	 * Button text
	 */
	label: PropTypes.string,

	/**
	 * List of menu items
	 */
	options: PropTypes.array,

	/**
	 * Position of the menu relative to the button.
	 * Can be: bottom-right, bottom-left, top-right, top-left
	 */
	placement: PropTypes.oneOf(['bottom-left', 'bottom-right', 'top-left', 'top-right']),

	/**
	 * Handler for when the button is clicked
	 */
	onClick: PropTypes.func,

	/**
	 * Handler for when a menu is selected
	 */
	onMenuSelected: PropTypes.func,
};

PrimaryButton.defaultProps = {
	label: '',
	options: [],
	placement: 'bottom-left',
};

/**
 * Primary button with menu support
 */
export default PrimaryButton;
