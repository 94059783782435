// Section names

const CHAT_SECTIONS = {
	MAIN: 'main',
	CONVERSATION: 'conversation',
	NEW: 'new',
	NEW_GROUP: 'new-group',
	ADD_CONTACT: 'add_contact',
	NEW_GROUP_ADD_PARTICIPANTS: 'new-group-add-participants',
	NEW_GROUP_CREATE: 'new-group-create',
	SUPPORT: 'support',
	UNREAD: 'unread',
	GROUP_ADD_PARTICIPANTS: 'group-add-participants',
	GROUP_REMOVE_PARTICIPANTS: 'group-remove-participants',
};

const CHAT_SECTIONS_default = {
	name: CHAT_SECTIONS.MAIN,
};

const CHAT_SECTIONS_titles = {
	[CHAT_SECTIONS.MAIN]: 'Conversations',
	[CHAT_SECTIONS.CONVERSATION]: 'Chat',
	[CHAT_SECTIONS.NEW]: 'New Chat',
	[CHAT_SECTIONS.NEW_GROUP]: 'New Group Chat',
	[CHAT_SECTIONS.SUPPORT]: 'Chat With Support',
	[CHAT_SECTIONS.ADD_CONTACT]: 'Add New Contact',
	[CHAT_SECTIONS.NEW_GROUP_ADD_PARTICIPANTS]: 'Add Group Participants',
	[CHAT_SECTIONS.NEW_GROUP_CREATE]: 'New Group',
	[CHAT_SECTIONS.GROUP_ADD_PARTICIPANTS]: 'Add Group Participants',
	[CHAT_SECTIONS.GROUP_REMOVE_PARTICIPANTS]: 'All Participants',
	addUsers: 'Add Participants',
	group: 'Group Chat',
	[CHAT_SECTIONS.UNREAD]: 'Filtered by Unread',
};

const DEFAULT_SUPPORTER = {
	email: 'support@ramsoft.com',
	name: 'Support',
	isOnline: false,
	text: 'On queue',
};

export default CHAT_SECTIONS;
export { CHAT_SECTIONS_default, CHAT_SECTIONS_titles, DEFAULT_SUPPORTER };
