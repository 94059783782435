import styled from 'styled-components';

export const boxStyle = {
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	overflow: 'none',
};

export const Title = styled.h1`
	font-family: Roboto;
	font-size: 28px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #fff;
	margin-bottom: 36px;
`;

export const Wrapper = styled.div`
	height: calc(100vh - 90px);
	padding: 5px 0px 20px 30px;
	overflow-y: hidden;

	@media (max-width: 1440px) {
		height: calc(100vh - 80px);
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: -16px;
	}
`;

export const NavLinkItem = styled.div`
	font-family: Roboto;
	margin-bottom: 21px;
`;

export const ArticlesHeader = styled.h2`
	font-family: Roboto;
	font-size: 24px;
	font-weight: 400;
	line-height: 28px;
	letter-spacing: normal;
`;

export const Articles = styled.div`
	a {
		font-weight: 400;
		font-size: 24px;
		line-height: 28px;

		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1; /* number of lines to show */
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	a:hover {
		cursor: pointer;
	}

	.articleEntry {
		.articles-primary {
			color: #42a5f5;
			cursor: pointer;

			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 1; /* number of lines to show */
			line-clamp: 1;
			-webkit-box-orient: vertical;
		}
		display: grid;
		margin-top: 35px;
	}

	.navigation {
		font-weight: 700;
		font-size: 12px;
		line-height: 24px;
		color: #ffffff;
		opacity: 0.6;
		text-transform: capitalize;
	}

	.content {
		font-weight: 400;
		font-size: 18px;
		line-height: 24px;
		color: #ffffff;

		margin-top: 10px;

		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* number of lines to show */
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}
`;

export const H3 = styled.h3`
	font-family: Roboto;
	font-size: 18px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #fff;
	margin-bottom: 14px;
	margin-top: 8px;
`;

export const Hint = styled.span`
	opacity: 0.6;
	font-family: Roboto;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #fff;

	a {
		color: #fff !important;
	}
`;

export const PrimaryButton = styled.button`
	width: 262px;
	height: 49px;
	min-height: 40px !important;
	margin: 14px 0 0;
	padding: 16px 55px 10px 56px;
	border-radius: 5px;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
	background-color: #42a5f5;
	border-width: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px;
	cursor: pointer;

	span {
		font-family: Roboto;
		font-size: 14px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 1.25px;
		text-align: center;
		color: #fff;
	}
`;

export const Scrollable = styled.div`
	flex: 1;
	overflow-y: auto;

	* {
		font-family: Roboto;
	}
`;

export const AgentFAB = styled.button`
	width: 60px;
	height: 60px;
	position: relative;
	z-index: 3;
	bottom: 14%;
	left: 92%;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
	background-color: #42a5f5;
	border: 0px;
	border-radius: 50%;
	cursor: pointer;
`;

export const OnlineIndicator = styled.div`
	width: 14px;
	height: 14px;
	border: solid 2px #f3f3f3;
	background-color: ${({ agentOnline }) =>
		agentOnline ? '#51e08a' : '#f44336'};
	position: absolute;
	right: 0px;
	border-radius: 50%;
`;
