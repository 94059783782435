// types
import PropTypes from 'prop-types';
import FormFieldVariantType from './FormFieldVariantType';

const FormFieldVariantsType = {
	/**
	 * FormFieldVariant props
	 */
	field: PropTypes.shape(FormFieldVariantType(true)).isRequired,

	/**
	 * react-hook-form - useForm() instance
	 */
	formHook: PropTypes.object.isRequired,
};

export default FormFieldVariantsType;
