import React, { createContext, useState, useContext, useEffect } from 'react';

export const SelectorContext = createContext();

const SelectorContextProvider = ({ children }) => {
	const [state, setState] = useState({
		region: localStorage.getItem('region') || 'CA',
		language: localStorage.getItem('language') || 'en',
	});

	const updateValue = (key, value) => {
		setState(prev => ({ ...prev, [key]: value }));
		localStorage.setItem(key, value);
	};
	useEffect(() => {
		if (state.language === 'null') {
			updateValue('language', 'en');
		}
	}, [state]);

	return <SelectorContext.Provider value={{ ...state, updateValue }}>{children}</SelectorContext.Provider>;
};

export const useSelector = () => {
	const selectorContext = useContext(SelectorContext);
	const { region, language, updateValue } = selectorContext;
	const regions = [
		{ code: 'BR', label: 'Brazil' },
		{ code: 'CA', label: 'Canada' },
		{ code: 'IN', label: 'India' },
		{ code: 'SG', label: 'Singapore' },
		{ code: 'US', label: 'United States' },
	];
	const languages = [
		{
			code: 'en',
			name: 'English',
		},
		{
			code: 'es',
			name: 'Español',
		},
		{
			code: 'hi',
			name: 'हिन्दी',
		},
		{
			code: 'fr',
			name: 'Français',
		},
	];

	return {
		region,
		regions,
		language,
		languages,
		updateValue,
	};
};

export default SelectorContextProvider;
