import React from 'react';

import { InsertImageIcon, InsertAttachmentIcon } from '../../../../assets/icons';

import styles from './InsertAttachmentBlockPlaceholder.module.scss';

const InsertAttachmentBlockPlaceholder = ({ theme, attachType }) => (
	<div className={styles.insertAttachmentBlockPlaceholder}>
		{attachType === 'Image' ? <InsertImageIcon /> : <InsertAttachmentIcon />}
		<span
			className={`${
				theme === 'OAI'
					? styles.insertAttachmentBlockPlaceholderOAI
					: styles.insertAttachmentBlockPlaceholderBlume
			}`}
		>
			{attachType === 'Image' ? 'Insert Image' : 'Insert Attachment'}
		</span>
	</div>
);

export default InsertAttachmentBlockPlaceholder;
