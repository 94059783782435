import { create } from 'zustand';
import { commonMiddlewares } from './middlewares';

export const useStudyStore = create(
	commonMiddlewares((set, get) => ({
		studyFilters: {},

		setStudyFilters: studyFilters =>
			set(() => ({
				studyFilters,
			})),
	}))
);
