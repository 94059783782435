import React from 'react';

const TableSizeIcon = ({ isHover }) => (
	<svg fill="none" height={19} width={19} xmlns="http://www.w3.org/2000/svg">
		<mask
			height={19}
			id="a"
			maskUnits="userSpaceOnUse"
			style={{
				maskType: 'alpha',
			}}
			width={19}
			x={0}
			y={0}
		>
			<path d="M.405.945h18v18h-18z" fill="#D9D9D9" />
		</mask>
		<g mask="url(#a)">
			<path
				d="M3.405 15.945c-.412 0-.765-.147-1.06-.44a1.445 1.445 0 0 1-.44-1.06v-9c0-.412.147-.765.44-1.06.295-.293.648-.44 1.06-.44h12c.413 0 .766.147 1.06.44.293.295.44.648.44 1.06v9c0 .413-.147.766-.44 1.06-.294.293-.647.44-1.06.44h-12Zm0-6.75h3v-3.75h-3v3.75Zm4.5 0h3v-3.75h-3v3.75Zm4.5 0h3v-3.75h-3v3.75Zm-6 5.25v-3.75h-3v3.75h3Zm1.5 0h3v-3.75h-3v3.75Zm4.5 0h3v-3.75h-3v3.75Z"
				fill={isHover ? '#fff' : '#C4C4C4'}
			/>
		</g>
	</svg>
);

export default TableSizeIcon;
