import React from 'react';
import { Box } from '@mui/material';
import Loader from './Loader';
import CommentItem from './CommentItem';

const CommentsTab = ({ comments, isLoading, isOmega }) =>
	isLoading ? (
		<Loader isOmega={isOmega} />
	) : (
		<Box className="invisible-scrollbar" sx={{ height: '100%', marginTop: '14px', overflow: 'auto' }}>
			{comments.map(item => (
				<CommentItem
					key={item?.DocumentCommentId}
					comment={item?.Description}
					date={item?.CreatedDateTime}
					isOmega={isOmega}
					isUnread={!item?.IsSeen}
					name={item?.LastUpdatedUserName}
				/>
			))}
		</Box>
	);

export default CommentsTab;
