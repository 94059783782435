import { BROADCAST_EVENTS } from '@rs-ui/views/ImageViewerView3D/contexts/consts/consts';
import { useImageViewerLayoutStoreSelector } from '@rs-ui/views/ImageViewerView3D/features/CustomToolbar/context/zustand/ImageViewerLayoutZustandProvider';
import { useImageViewerMultiMonitorContext } from '@rs-ui/views/ImageViewerView3D/contexts/ImageViewerMultiMonitorContext';
import { useImageViewerLayoutContext } from '@rs-ui/views/ImageViewerView3D/contexts/ImageViewerLayoutContext';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { logDebug } from '@worklist-2/core/src';

const useOnSetActiveViewportId = () => {
	const wonIvCustomtoolbar = useBooleanFlagValue('WON-IV-CUSTOMTOOLBAR');
	const setActiveViewportZustand = useImageViewerLayoutStoreSelector(state => state.setActiveViewport);
	const setActiveViewportIdZustand = useImageViewerLayoutStoreSelector(state => state.setActiveViewportId);

	const { postMessage } = useImageViewerMultiMonitorContext();
	const { layoutItems, setActiveViewportId, isMPRViewRef, isFusionViewRef } = useImageViewerLayoutContext();

	const onSetActiveViewportId = async viewportId => {
		logDebug('OnSetActiveViewportId', 'Viewport', viewportId);
		if (wonIvCustomtoolbar) {
			setActiveViewportZustand(layoutItems.find(i => (i.viewports || []).some(({ id }) => id === viewportId)));
			setActiveViewportIdZustand(viewportId);
		} else {
			await setActiveViewportId(prevState => {
				if (prevState === viewportId) {
					return prevState;
				}

				if (!isMPRViewRef.current && !isFusionViewRef.current) {
					setActiveViewportIdZustand(viewportId);
					postMessage({
						event: BROADCAST_EVENTS.ACTIVE_VIEWPORT_ID_UPDATE,
						value: viewportId,
					});
				}

				return viewportId;
			});
		}
	};

	return {
		onSetActiveViewportId,
	};
};

export { useOnSetActiveViewportId };
