import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import { LAYOUT_TYPE } from './consts/consts';
import useStateRef from '../hooks/useStateRef';
import { useMultiscreen } from '@rs-core/hooks';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { useSearchParams } from 'react-router-dom';

const ImageViewerView3DContext = createContext({});

const ImageViewerView3DContextProvider = ({ children }) => {
	const wonIvMmPreventUntilImageLoaded = useBooleanFlagValue('won-iv-mm-prevent-until-image-loaded');
	const [searchParams] = useSearchParams();

	const [isSplitView, setIsSplitView] = useState(searchParams.get('opendv') === 'true');
	const [resizeDiff, setResizeDiff] = useState(0);
	const [focusedSection, setFocusedSection] = useState('imageViewer');
	const [isShownBackdrop, setIsShownBackdrop] = useState(false);
	const [isFullScreen, setIsFullScreen, isFullScreenRef] = useStateRef(false);
	const { ivLayoutType, setIvLayoutType } = useMultiscreen();
	const [imageLoaded, setImageLoaded] = useState(!wonIvMmPreventUntilImageLoaded); // Used to prevent loading some data until the image is loaded

	useEffect(() => {
		// set imageLoaded to true if no image is loaded in 15 seconds
		const timer = setTimeout(() => {
			if (!imageLoaded) {
				setImageLoaded(true);
			}
		}, 15000);

		return () => clearTimeout(timer);
	}, []);

	const hotkeysManagerRef = useRef({
		enabled: false,
		hotkeys: {
			ctrlKey: {},
			altKey: {},
			metaKey: {},
			shiftKey: {},
			ctrlShiftKey: {},
		},
	});

	const loadingManagerRef = useRef({
		priorStudiesLoaded: false,
		studySeries: {},
		studyMeasurements: {},
		studyKeyImages: {},
	});

	return (
		<ImageViewerView3DContext.Provider
			value={{
				layoutType: ivLayoutType,
				setLayoutType: setIvLayoutType,
				isSplitView,
				setIsSplitView,
				resizeDiff,
				setResizeDiff,
				focusedSection,
				setFocusedSection,
				hotkeysManagerRef,
				isShownBackdrop,
				setIsShownBackdrop,
				isFullScreen,
				setIsFullScreen,
				isFullScreenRef,
				loadingManagerRef,
				imageLoaded,
				setImageLoaded,
			}}
		>
			{children}
		</ImageViewerView3DContext.Provider>
	);
};

const useImageViewerView3DContext = () => useContext(ImageViewerView3DContext);

export { useImageViewerView3DContext, ImageViewerView3DContextProvider, ImageViewerView3DContext };
