import axios from 'axios';
import _ from 'lodash';
import { sleep, crmSearchScopes } from '../..';
import moment from 'moment';

let __config;
const MAX_RETRIES = 5;

class CRMDataLoader {
	results = null;

	context = {
		name: 'all',
		bearerToken: '',
		sessionId: '',
		endpoint: '',
	};

	httpOptions;

	searchScope;
	retries = 0;

	abortController;

	constructor({ config, scopeContext, httpOptions, abortController }) {
		__config = this.config = config;
		this.httpOptions = {
			...{
				timeout: __config?.crm?.http_timeout || 30000,
				headers: { ...__config?.crm?.request_headers } | {},
				transformResponse: [data => data],
			},
			...httpOptions,
		};
		this.searchScope = scopeContext || {};
		this.abortController = abortController;
	}

	__testValue = 106;

	__op__updateScope = newScope => {
		this.searchScope = newScope;
	};

	__op__load = async (props, returnRawData, bodyData) => {
		let processResults = this.processResults;
		props = props || {};
		let needCancel = false;
		let needCancelScopes = [
			crmSearchScopes.all,
			crmSearchScopes.commentApi,
			crmSearchScopes.caseApi,
			crmSearchScopes.accountApi,
			crmSearchScopes.notificationApi,
			crmSearchScopes.userApi,
			crmSearchScopes.teamApi,
			crmSearchScopes.contactApi,
			crmSearchScopes.globalSearchApi,
			crmSearchScopes.reportApi,
			crmSearchScopes.okrApi,
			crmSearchScopes.permissionApi,
			crmSearchScopes.organizationLicenseApi,
			crmSearchScopes.organizationApi,
		];

		if (needCancelScopes.includes(this.searchScope.scope)) {
			needCancel = true;
		}

		let uri = this.buildUrl(props);

		try {
			let results;

			if (needCancel) {
				if (this.cancel) {
					this.cancel.cancel();
				}

				// Get a new token
				this.cancel = axios.CancelToken.source();
			}

			let arr;
			try {
				do {
					arr = await axios[bodyData ? 'post' : 'get'](
						__config.data_sources.breeze + (uri?.startsWith('?') ? '' : '/') + (uri || ''),
						!bodyData
							? {
									headers: {
										Accept: '*/*',
									},
									cancelToken: needCancel ? this.cancel.token : null,
							  }
							: bodyData,
						{
							cancelToken: needCancel ? this.cancel.token : null,
						}
					);

					if (arr.status !== 403) {
						this.retries = 0;
						break;
					} else {
						this.retries++;
						sleep(500);
					}
				} while (this.retries <= MAX_RETRIES);
				if (this.retries > MAX_RETRIES) {
					console.warn(
						`Could not load data from ${this.searchScope.endpoint}; maximum retries (${MAX_RETRIES}) exceeded.`
					);
					results = [];
				} else {
					results = processResults(arr.data);
				}
			} catch (thrown) {
				if (axios.isCancel(thrown)) {
					console.error('First request canceled', thrown.message);
				} else {
					console.error('Not canceled');
				}
			}

			return results;
		} catch (e) {
			console.error(e);
			throw e;
		}
	};

	__op__save = async (props, payload, enforcePost, setProgress) => {
		props = props || {};
		let uri = this.buildUrl(props);
		try {
			return await axios[props['id'] && !enforcePost ? 'put' : 'post'](
				__config.data_sources.breeze + '/' + uri,
				payload,
				{
					headers: {
						Accept: '*/*',
						'Content-Type': 'application/json',
						...(props.headers ? props.headers : {}),
					},
					onUploadProgress: progressEvent => {
						const { loaded, total } = progressEvent;
						let precentage = Math.floor((loaded / total) * 100);
						if (!!setProgress) {
							setProgress(precentage);
						}
					},
				}
			);
		} catch (e) {
			console.error(e);
			throw e;
		}
	};

	__op__delete = async props => {
		props = props || {};
		let uri = this.buildUrl(props);
		try {
			let resp = await axios.delete(
				__config.data_sources.breeze + (uri?.startsWith('?') ? '' : '/') + (uri || ''),
				{
					headers: {
						Accept: '*/*',
						'Content-Type': 'application/json',
					},
				}
			);
			return !!resp && resp?.status >= 200 && resp?.status < 300;
		} catch (e) {
			console.error(e);
			throw e;
		}
	};

	__op__update = async (id, payload, setProgress) => {
		let uri = `${this.searchScope.endpoint}/${id}`;
		try {
			let resp = await axios.put(`${__config.data_sources.breeze}/${uri}`, payload, {
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json',
				},
				onUploadProgress: progressEvent => {
					const { loaded, total } = progressEvent;
					let precentage = Math.floor((loaded / total) * 100);
					if (!!setProgress) {
						setProgress(precentage);
					}
				},
			});
			return !!resp && resp.status >= 200 && resp.status < 300 ? resp.data : null;
		} catch (e) {
			console.error(e);
			throw e;
		}
	};

	__op__patch = async (id, fieldName, value, op = 'add') => {
		let uri = `${this.searchScope.endpoint}/${id}`;
		let payload = [];
		if (Array.isArray(fieldName)) {
			payload = _.map(fieldName, (item, index) => {
				return {
					op: op,
					path: '/' + item,
					value: value[index],
				};
			});
		} else {
			payload = [
				{
					op: op,
					path: '/' + fieldName,
					value: value,
				},
			];
		}

		try {
			let resp = await axios.patch(`${__config.data_sources.breeze}/${uri}`, payload, {
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json',
				},
			});
			return !!resp && resp.status >= 200 && resp.status < 300 ? resp.data : resp;
		} catch (e) {
			console.error(e);
			throw e;
		}
	};

	processResults = result => {
		let processedValues;
		if (
			result.entry &&
			result.entry.length > 0 &&
			result['resourceType'] &&
			result['resourceType'].toLowerCase() === 'bundle'
		) {
			//default get bundle
			processedValues = _.map(result.entry, elem => {
				let item = {};
				item = elem.resource;
				return item;
			});
		} else if (
			result.entry &&
			result.entry.length === 0 &&
			result['resourceType'] &&
			result['resourceType'].toLowerCase() === 'bundle'
		) {
			processedValues = [];
		} else if (
			result['resourceType'] &&
			result['resourceType'].toLowerCase() === 'bundle' &&
			result['total'] === 0
		) {
			processedValues = [];
		} else {
			return result;
		}
		return processedValues || [];
	};

	buildUrl = args => {
		let cacheBuster = `?_dc=${new Date().getTime()}`,
			returnUrl = `${this.searchScope.endpoint || ''}${!!args['id'] ? '/' + args['id'] : cacheBuster}`;

		if (!!args.id) {
			let summaryParam = args.summary ? `&_summary=${args.summary}` : '';

			let url = `${this.searchScope.endpoint}/${args.id}`;

			if (args.history) {
				url = `${url}/_history`;
			}

			return `${url}${cacheBuster}${summaryParam}`;
		}

		if (!_.find(args, (value, key, collection) => key === 'page')) {
			args = { ...args, page: 1 };
		} else if (args['page'] <= 0) {
			args['page'] = 1;
		}

		if (!_.find(args, (value, key, collection) => key === 'count')) {
			args = { ...args, count: __config.crm.results_per_search || 50 };
		} else if (args['count'] <= 0) {
			args['count'] = __config.crm.results_per_search || 50;
		}

		let params = _.map(args, (value, key, collection) => {
			switch (key) {
				case 'count':
					return [`_count=${value}`];
				case 'sort':
					return [`_sort=${value}`];
				case 'summary':
					return [`_summary=${value}`];
				case 'content':
					return [`_content=${value}`];
				case 'CaseNumber':
					return [`CaseNumber=${value}`];
				case 'Subject':
					return [`Subject=${value}`];
				case 'Type':
					return [`Type=${value}`];
				case 'AccountName':
					return [`AccountName=${value}`];
				case 'OpenedDateTime':
					return [`OpenedDateTime=${moment().format()}&OpenedDateTime=${value}`];
				case 'CaseOwnerAlias':
					return [`CaseOwnerAlias=${value}`];
				case 'Priority':
					return [`Priority=${value}`];
				case 'Status':
					return [`Status=${value}`];
				case 'userid':
					return [`userid=${value}`];
				case 'page':
					if (_.endsWith(this.searchScope.endpoint, 'elk')) {
						return [`_start=${(value - 1) * collection['count']}`];
					} else {
						return [`page=${value}`];
					}
				case 'id':
					return [''];
				default:
					return [`${_.toLower(key)}=${value}`];
			}
		});

		return `${returnUrl}${
			!args['id']
				? '&' + _.join(_.sortBy(_.filter(_.flatten(params), elem => !_.isNil(elem) && !_.isEmpty(elem))), '&')
				: ''
		}`;
	};
}

export default CRMDataLoader;
