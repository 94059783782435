import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React from 'react';
import CheckIcon from '@worklist-2/ui/src/assets/icons/checkmark.svg';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined';
import { HotKeyText } from './HotKeyInputItem';

export const PresetMenuItem = ({ item, onChange, isCurrent }) => (
	<Box
		alignItems="center"
		as="button"
		bgcolor="transparent"
		border="none"
		data-testid="preset-button"
		display="flex"
		flexDirection="row"
		justifyContent="space-between"
		sx={{ cursor: 'pointer' }}
		variant="text"
		onClick={e => {
			e.stopPropagation();
			onChange(item);
		}}
		onMouseDown={e => e.stopPropagation()}
		onMouseUp={e => e.stopPropagation()}
	>
		<Stack alignItems="center" direction="row" gap="10px" sx={{ width: '100%' }}>
			{isCurrent ? <CheckIcon data-testid="checkmark" /> : <CheckIcon style={{ visibility: 'hidden' }} />}
			<Stack
				direction="row"
				justifyContent="space-between"
				sx={{ width: '100%', alignItems: 'center', gap: '10px' }}
			>
				<Stack direction="row" sx={{ width: '100%', alignItems: 'center', gap: '10px' }}>
					<Typography
						color="rgba(255, 255, 255, 0.87)"
						fontFamily="Roboto"
						fontSize={16}
						sx={{
							whiteSpace: 'nowrap',
							maxWidth: '115px',
							textOverflow: 'ellipsis',
							overflow: 'hidden',
						}}
					>
						{item.menuLabel}
					</Typography>
					<Stack color="rgba(255, 255, 255, 0.6)" direction="row">
						<Typography fontFamily="Roboto" fontSize={14}>
							{item.windowCenter}/
						</Typography>
						<Typography fontFamily="Roboto" fontSize={14}>
							{item.windowWidth}
						</Typography>
					</Stack>
				</Stack>
				{item?.hotkey && (
					<Stack
						color="rgba(255, 255, 255, 0.6)"
						direction="row"
						sx={{
							alignItems: 'center',
							width: '100%',
							marginLeft: '10px',
							justifyContent: 'flex-end',
						}}
					>
						<KeyboardOutlinedIcon
							sx={{
								fontSize: '24px',
							}}
						/>
						<Typography fontFamily="Roboto" fontSize={16}>
							<HotKeyText item={item.hotkey} />
						</Typography>
					</Stack>
				)}
			</Stack>
		</Stack>
	</Box>
);

PresetMenuItem.propTypes = {
	item: PropTypes.shape({
		menuLabel: PropTypes.string,
		windowCenter: PropTypes.number,
		windowWidth: PropTypes.number,
	}).isRequired,
	onChange: PropTypes.func.isRequired,
	isCurrent: PropTypes.bool,
};
