// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XFQv9opYrNTEXJXvCmxA{margin:20px 100px}.nwelSzb8yQ1pK6V4HTTT{margin:10px 90px;padding:10px;border-radius:10px;border:1px solid #42a5f5;background:rgba(66,165,245,.05)}.XFQv9opYrNTEXJXvCmxA span{font-feature-settings:\"clig\" off,\"liga\" off;font-family:Roboto,serif;font-size:14px;font-style:normal;font-weight:400;line-height:20px;white-space:pre-wrap;word-wrap:break-word}.ZxlP_sggp_T87nf4PiMe{color:#fff}.htlVMAeqIJqtY2C29HC2{color:rgba(18,18,18,.87)}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/common/MainArea/Draft/TextBlocks/NumberListBlock/NumberListBlock.module.scss"],"names":[],"mappings":"AAAA,sBACC,iBAAA,CAEA,sBACC,gBAAA,CACA,YAAA,CAEA,kBAAA,CACA,wBAAA,CACA,+BAAA,CAGD,2BACC,2CAAA,CACA,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEA,oBAAA,CACA,oBAAA,CAIF,sBACC,UAAA,CAED,sBACC,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"numberListBlock": "XFQv9opYrNTEXJXvCmxA",
	"numberListBlockSelected": "nwelSzb8yQ1pK6V4HTTT",
	"numberListOAI": "ZxlP_sggp_T87nf4PiMe",
	"numberListBlume": "htlVMAeqIJqtY2C29HC2"
};
export default ___CSS_LOADER_EXPORT___;
