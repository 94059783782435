import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { Svg } from '@rs-ui/components/Svg';

const PriorityIcon = ({ optionName, sx }) => {
	const type = optionName?.toLowerCase();
	const svgSx = {
		width: type === 'routine' ? '8px' : '16px',
		height: type === 'routine' ? '4px' : '16px',
		marginTop: ['stat', 'asap'].includes(type) ? '2px' : '0px',
		fill: type === 'routine' ? '' : '#CF6679',
		fillOpacity: type === 'routine' ? '' : '1',
		stroke: type === 'routine' ? '#42A5F5' : '',
		strokeOpacity: type === 'routine' ? '1' : '',
	};

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				mr: sx?.marginRight ?? '8px',
				width: sx?.width ?? '16px',
				height: sx?.height ?? '16px',
				borderRadius: '50px !important',
				backgroundColor:
					type === 'routine' ? 'rgba(66, 165, 245, 0.1) !important' : 'rgba(207, 102, 121, 0.1) !important',
				border:
					type === 'routine'
						? '1px solid rgba(66, 165, 245, 0.2) !important'
						: '1px solid rgba(207, 102, 121, 0.2) !important',
			}}
		>
			{type === 'stat' && <Svg name="statIcon" sx={{ ...svgSx }} />}
			{type === 'asap' && <Svg name="asapIcon" sx={{ ...svgSx }} />}
			{type === 'urgent' && <Svg name="urgentIcon" sx={{ ...svgSx }} />}
			{type === 'routine' && <Svg name="routineIcon" sx={{ ...svgSx }} />}
		</Box>
	);
};

PriorityIcon.propTypes = {
	/**
	 *  The selected opption name
	 */
	optionName: PropTypes.string,
	/**
	 *  A styling object
	 */
	sx: PropTypes.object,
};

export default PriorityIcon;
