/* Generated automagically by FhirStarter - DO NOT EDIT */
import * as datatypes from '../datatypes';
import * as validateArgs from './validateArgs';

const buildReferenceFunc = args => {
	const { id, extension, reference, type, identifier, display } = args;
	const schema = datatypes.getSchema('Reference');
	let valid = validateArgs.validateArgs(schema, args, Object.keys(args));

	return {
		id: id,
		extension: extension,
		reference: reference,
		type: type,
		identifier: identifier,
		display: display,
		__valid: valid,
	};
};

export default buildReferenceFunc;
