const getMarkRanges = editor => {
	const markRanges = [];
	let selectionRange = 0;

	const { ranges } = editor.state.selection;

	ranges.forEach(({ $from, $to }) => {
		const from = $from.pos;
		const to = $to.pos;

		editor.state.doc.nodesBetween(from, to, (node, pos) => {
			if (!node.isText && !node.marks.length) {
				return;
			}

			const relativeFrom = Math.max(from, pos);
			const relativeTo = Math.min(to, pos + node.nodeSize);
			const range = relativeTo - relativeFrom;

			selectionRange += range;

			markRanges.push(
				...node.marks.map(mark => ({
					mark,
					from: relativeFrom,
					to: relativeTo,
				}))
			);
		});
	});

	return [markRanges, selectionRange];
};

export default getMarkRanges;
