const ViewCode = [
	// Old ViewCode, SNOMED-RT ID
	{ code: 'R-102CF', description: 'XCC' },
	{ code: 'R-10226A', description: 'XMLO' },
	{ code: 'Y-X1770', description: 'XCCL' },
	{ code: 'Y-X1771', description: 'XCCM' },
	// New ViewCode, SNOMED-RT ID
	{ code: 'R-10224', description: 'ML' },
	{ code: 'R-10226', description: 'MLO' },
	{ code: 'R-10228', description: 'LM' },
	{ code: 'R-10230', description: 'LMO' },
	{ code: 'R-10242', description: 'CC' },
	{ code: 'R-10244', description: 'FB' },
	{ code: 'R-102D0', description: 'SIO' },
	{ code: 'R-40AAA', description: 'ISO' },
	{ code: 'R-1024A', description: 'XCCL' },
	{ code: 'R-1024B', description: 'XCCM' },
	{ code: 'G-8310', description: '' },
	// ViewCode, SCT
	{ code: '399260004', description: 'ML' },
	{ code: '399368009', description: 'MLO' },
	{ code: '399352003', description: 'LM' },
	{ code: '399099002', description: 'LMO' },
	{ code: '399162004', description: 'CC' },
	{ code: '399196006', description: 'FB' },
	{ code: '399188001', description: 'SIO' },
	{ code: '441555000', description: 'ISO' },
	{ code: '399192008', description: 'XCCL' },
	{ code: '399101009', description: 'XCCM' },
	{ code: '127457009', description: '' },
];

export default ViewCode;
