import React from 'react';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

const StyledChip = styled(Chip, {
	shouldForwardProp: prop => prop !== 'iconColor',
})(({ selected, iconColor, theme }) => ({
	height: 40,
	fontSize: 12,
	color: theme.palette.text.primary,
	fontFamily: theme.palette.fontFamily,
	fontWeight: '400',
	textTransform: 'none',
	border: '1px solid',
	borderRadius: '25px',
	paddingLeft: '8px',
	paddingRight: '8px',
	backgroundColor: selected ? theme.palette.rsPrimary.button : 'transparent',
	borderColor: theme.palette.primary.light,
	'.MuiSvgIcon-root': {
		color: iconColor ?? theme.palette.text.primary,
		...(selected && {
			color: theme.palette.text.primary,
		}),
	},
}));

const StorefrontChip = ({ label, icon, iconColor, stylingProps, selected, onClick }) => (
	<StyledChip
		clickable
		icon={icon}
		iconColor={iconColor}
		label={label}
		selected={selected}
		sx={{
			...stylingProps,
		}}
		variant="outlined"
		onClick={onClick}
	/>
);

export default StorefrontChip;

StorefrontChip.propTypes = {
	/**
	 * The text on the chip
	 */
	label: PropTypes.string.isRequired,
	/**
	 * Icon to display on chip
	 */
	icon: PropTypes.element,
	/**
	 * Color for icon, if different from text
	 */
	iconColor: PropTypes.string,
	/**
	 * Whether or not the chip is selected
	 */
	selected: PropTypes.bool,
	/**
	 * Additional styling to be passed to sx prop
	 */
	stylingProps: PropTypes.object,
	/**
	 * handler for onclick
	 */
	onClick: PropTypes.func,
};

StorefrontChip.defaultProp = {
	selected: false,
	stylingProps: null,
};
