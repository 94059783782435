import React, { createContext, useState, useMemo, useCallback, useEffect, useContext, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import pako from 'pako';
import {
	get as getIndexedDbValueByKey,
	set as setIndexedDBValueByKey,
	del as deleteIndexedDBValueByKey,
} from 'idb-keyval';

import {
	fhirExtensionUrls,
	useAppModeContext,
	useRouter,
	eventPublish,
	getNewResource,
	TWILIO_SYNC_RESOURCE_TYPE,
	TWILIO_SYNC_EVENT_TYPE,
	TWILIO_SYNC_NOTIFICATION_DATA,
	logError,
	logWarn,
	logDebug,
	logInfo,
} from '@worklist-2/core/src';
import { useChatGlobalContext } from '@worklist-2/ui/src/components/Chat/ChatGlobalContext';
import CHAT_CONVERSATION_TYPES from '@worklist-2/ui/src/components/Chat/CHAT_CONVERSATION_TYPES';
import {
	PublicClientApplication,
	LogLevel,
	EventType,
	ClientAuthErrorMessage,
	ClientConfigurationErrorMessage,
	InteractionRequiredAuthError,
	InteractionType,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { useConfig } from './ConfigContext';
import axios from 'axios';
import { useLocalStorage } from 'react-use';
import _ from 'lodash';
import fhirEndpoints from '../fhir/fhirEndpoints';
import permissionsMapper from './utils/permissionsMapper';
import defaultRamSoftPermission from './defaultRamSoftPermission';
import basicPermission from './basicPermission';
import affiliatedPermission from './affiliatedPermission';
import getUserFullName, { parseFhirName } from '../fhir/resource/columnMapping/utils/getUserFullName';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { OpenFeature } from '@openfeature/react-sdk';
import { useBooleanFlagValue, useStringFlagValue } from '@rs-core/hooks/useFlags';
import { setCrossFormatPersonNameGV } from '@worklist-2/core/src/fhir/resource/columnMapping/utils/globalVariablesForFormatName';

import { addCacheBuster } from '@worklist-2/core/src/utils/url';

// Twilio SDK
import { Client } from '@twilio/conversations';
import { SyncClient } from 'twilio-sync';

import { datadogRum } from '@datadog/browser-rum';
import { useTranslation } from 'react-i18next';
import { clearRadPairStudyInfo } from '@worklist-2/worklist/src/DocumentViewerV3/features/DocumentViewer/Viewports/ViewportTiptap/ViewportTipTapEditor/utils/studyInfoForRadpair';

const LanguageMapping = [
	{
		code: 'en',
		name: 'English',
	},
	{
		code: 'es',
		name: 'Español',
	},
	{
		code: 'hi',
		name: 'हिन्दी',
	},
	{
		code: 'fr',
		name: 'Français',
	},
];
export const UserAuthContext = createContext({
	handleSyncMessage: event => {},
});
const MS_PER_MONTH = 2592000000;

const processedSyncMessages = new Set();
const MAX_SET_SIZE = 1000;

const getUserImageViewerUrl = userData =>
	userData.extension?.find(item => item.url === fhirExtensionUrls.practitioner.imageViewerUrl)?.valueString;

const getUserDocumentViewerUrl = user =>
	user.extension?.find(item => item.url === fhirExtensionUrls.practitioner.documentViewerUrl)?.valueString;

const getMonitorSetup = user => {
	let settingJson = {};
	const setting = user.extension?.find(
		item => item.url === fhirExtensionUrls.practitioner.monitorSetupUrl
	)?.valueString;

	if (setting) {
		settingJson = JSON.parse(setting);
	}

	return settingJson;
};

const getLanguage = user => user.extension?.find(item => item.url === fhirExtensionUrls.common.language)?.valueString;

const decompressedSyncPayload = payload => {
	let result = null;
	try {
		const strData = window.atob(payload);
		const charData = strData.split('').map(x => x.charCodeAt(0));
		const binData = new Uint8Array(charData);
		result = pako.inflate(binData, { to: 'string' });
	} catch (err) {
		console.error(`failed to parse the sync payload`);
	}
	return result;
};

const createAxiosInstance = baseURL =>
	axios.create({
		baseURL,
		validateStatus: status => status < 500, // we can handle 400 errors ourself, we don't need to throw them
	});

export const UserAuthProvider = ({ children }) => {
	// LD feature flag
	const crossFormatPersonName = useBooleanFlagValue('cross-format-person-name');
	const sprinterBlumeStageingpolicy = useBooleanFlagValue('sprinter-blume-stageingpolicy');
	const metaDisableBreezeSupport = useBooleanFlagValue('meta-disable-breeze-support');
	const phoenixBlumeDisableChat = useBooleanFlagValue('phoenix-blume-disable-chat');
	const wonEnableSpanish = useBooleanFlagValue('WON-ENABLE-SPANISH');
	const won3715MmLogoutCloseother = useBooleanFlagValue('WON-3715-MM-LOGOUT-CLOSEOTHER');
	const proactHideMarketplace = useBooleanFlagValue('proact-hide-marketplace');
	const sprinterOaiAccessTokenExpiry = useBooleanFlagValue('sprinter-oai-access-tokenExpiry');
	const sprinterMsalLoglevel = useStringFlagValue('sprinter-msal-loglevel');
	const sprinterOaiStageingpolicy = useBooleanFlagValue('sprinter-oai-stageingpolicy');
	const proactDisableTimeZoneFromAppointments = useBooleanFlagValue('proact-disable-time-zone-from-appointments');
	const wonIvServiceworker = useBooleanFlagValue('won-iv-serviceworker');
	const wonIvWebWorkerCache = useBooleanFlagValue('won-iv-web-worker-cache');
	const crossGetPrivilegeOnDemand = useBooleanFlagValue('cross-get-privilege-on-demand');
	const sprinterOaiIncludeReforgInGetuserrole = useBooleanFlagValue('sprinter-oai-include-reforg-in-getuserrole');
	const galaxyDvUacEnableNewPermissions = useBooleanFlagValue('galaxy-dv-uac-enable-new-permissions');
	const wonPreCacheImagesByWebworker = useBooleanFlagValue('won-pre-cache-images-by-webworker');
	const wonCacheSeriesMetadataInClient = useBooleanFlagValue('won-cache-series-metadata-in-client');
	const proactChatImpersonationChanges = useBooleanFlagValue('proact-chat-impersonation-changes');
	const crossMoveDevCycleSetContext = useBooleanFlagValue('cross-move-dev-cycle-set-context');

	// these are mostly MSAL related
	const { goTo } = useRouter();
	const [loggingIn, setLoggingIn] = useState(false);
	const [loggedIn, setLoggedIn] = useState(false);
	const [loggingOut, setLoggingOut] = useState(false);
	const [currentLoggedInUser, setCurrentLoggedInUser] = useState(null);
	const [isEmailVerified, setIsEmailVerified] = useState(null);
	const [isSentOTP, setIsSentOTP] = useState(null);
	const [isPhoneVerified, setIsPhoneVerified] = useState(null);
	const [isRecordFound, setIsRecordFound] = useState(null);
	const [patientMapping, setPatientMapping] = useState([]);
	const [displaySettings, setDisplaySettings] = useState({});
	const [contacts, setContacts] = useState([]);
	const { appMode, isBreezeMode, isPatientPortalMode, isWorklistMode } = useAppModeContext();
	const appWorklistMode = isWorklistMode();
	const appBreezeMode = isBreezeMode();
	const appBlumeMode = isPatientPortalMode();
	const [userInOmegaAI, setUserInOmegaAI] = useState(null);
	const [userIdP, setUserIdP] = useState(null);
	const [omegaAIAffiliateRights, setOmegaAIAffiliateRights] = useState(false);
	const [notificationReceived, setNotificationReceived] = useState(false);
	const [shouldUpdateNotification, setShouldUpdateNotification] = useState();
	const [blumeUserSettings, setBlumeUserSettings] = useState({});
	const location = useLocation();
	const [searchParams] = useSearchParams();
	let isSharedStudy =
		location.pathname === '/iv' ||
		location.pathname === '/imageviewerexternal' ||
		location.pathname.startsWith('/externalImageViewer') ||
		location.pathname.startsWith('/external-form'); //For the View mode  for External users
	isSharedStudy =
		isSharedStudy ||
		(location.pathname.startsWith('/imageviewer3d') && searchParams.get('SharedStudy') === 'true') ||
		location.pathname.startsWith('/external-form');
	const [sendLoginMessage, setSendLoginMessage] = useState(false);

	const [appointmentVisibleHours, setAppointmentVisibleHours] = useState(8);
	const [lastOrderOrganization, setLastOrderOrganization] = useState();

	// Twilio
	const [client, setClient] = useState(null);
	const [isClientInitialized, setIsClientInitialized] = useState(false);
	const [supportClient, setSupportClient] = useState(null);
	const [isSupportClientInitialized, setIsSupportClientInitialized] = useState(false);
	const [blumeClient, setBlumeClient] = useState(null);
	const [isBlumeClientInitialized, setIsBlumeClientInitialized] = useState(false);
	const [requestQueueReceived, setRequestQueueReceived] = useState(null);
	const [chatMsgReceived, setChatMsgReceived] = useState(false);
	const [signatureData, setSignatureData] = useState('');
	const { setCallData, setConversationType, conversationType, nonUsEntities } = useChatGlobalContext();

	// Twilio Sync
	const [twilioConnectionStatus, setTwilioConnectionStatus] = useState('Connecting...');
	const [twilioErrorMessage, setTwilioErrorMessage] = useState('');
	const twilioClientRef = useRef();

	const [userPractitionerRolesPerOrganization, setUserPractitionerRolesPerOrganization] = useState([]);

	const __config = useConfig();

	const tokenRef = useRef();
	const loggingInRef = useRef(false);
	const loggedInRef = useRef(false);
	const loggingOutRef = useRef(false);
	const userInfoRef = useRef(null);
	const displaySettingsRef = useRef({});

	// For IdP test
	const testIdpUserAuthenticatedRef = useRef(false);
	const [testIdpUserAuthenticated, setTestIdpUserAuthenticated] = useState(false);

	// For languages
	const { i18n } = useTranslation();
	const [languages, setLanguages] = useState(LanguageMapping);
	const [selectedLanguage, setSelectedLanguage] = useState('en');

	// we abort any requests when we're logging out
	const abortControllerRef = useRef(new AbortController());

	// interceptors
	const [requestInterceptor, setRequestInterceptor] = useState(null);
	const [responseInterceptor, setResponseInterceptor] = useState(null);

	// auth values
	const [accessToken, setAccessToken] = useState(null);
	const [oid, setOid] = useState(null);
	const [sessionId, setSessionId] = useState(null);

	// signalR SYNC_RELOAD Blume
	const [syncReloadBlume, setSyncReloadBlume] = useState(false);

	// user roles
	const [userRoleForOrganizations, setUserRoleForOrganizations] = useState([]);
	const [hasLoadedUserRoleForOrganizationPermissions, setHasLoadedUserRoleForOrganizationPermissions] =
		useState(false);

	// new user roles - on demand
	const userPrivilegesForOrganizations = useRef([]);
	const [userAssociatedWithOnlyOneOrg, setUserAssociatedWithOnlyOneOrg] = useState(false);

	// global permissions
	const [globalPermissionsForLoggedUser, setGlobalPermissionsForLoggedUser] = useState({});
	const [practitionerRole, setPractitionerRole] = useState([]);

	// Marketplace User Id
	const [marketplaceUserId, setMarketplaceUserId] = useState(null);

	// ABHA
	const [ABHAHealthID, setABHAHealthID] = useState(null);

	// The user profiles
	const [profiles, setProfiles] = useState(null);

	// Dev Cycle Context Update
	const [devCyleContextUpdated, setDevCycleContextUpdated] = useState(false);

	/**
	 * These states are used to track the progression through the authentication process:
	 * 1. Log in and get access token (!!accessToken, basically)
	 * 2. Authenticate the user's OID (!!accessToken && !!oid -> setUserAuthorized(true))
	 * 3. Get a valid session ID (!!accessToken && !!oid && !!sessionId -> setFullyAuthorized(true))
	 *
	 * fullyAuthorized is then used to set the correct interceptors, and tell the app it's cool to start
	 * making API calls
	 */
	const [userAuthorized, setUserAuthorized] = useState(false);
	const [sessionIdValid, setSessionIdValid] = useState(false);
	const [fullyAuthorized, setFullyAuthorized] = useState(false);

	// not really used right now, but I have big plans for these bad boys later
	const [userInfo, setUserInfo] = useState(null);
	const [lastError, setLastError] = useState(null);

	// set reference values to current state values so they're always up to date on each render
	tokenRef.current = accessToken;
	loggedInRef.current = loggedIn;
	loggingInRef.current = loggingIn;
	loggingOutRef.current = loggingOut;
	userInfoRef.current = userInfo;
	testIdpUserAuthenticatedRef.current = testIdpUserAuthenticated;

	// we want to store the session ID in localStorage in case of a crash, a user can retain the session
	const [localSessionId, setLocalSessionId, removeLocalSessionID] = useLocalStorage('sessionId', '');

	const { internalHttp, blumeHttp, breezeHttp } = useMemo(
		() => ({
			internalHttp: createAxiosInstance(__config.auth.omegaai.auth_api),
			blumeHttp: createAxiosInstance(__config.data_sources.blume),
			breezeHttp: createAxiosInstance(__config.data_sources.breeze),
		}),
		[__config]
	);

	const getNotificationStatus = id => {
		if (appBlumeMode) {
			axios
				.get(`${__config.data_sources.blume}Notification`)
				.then(result => {
					if (result.status === 200) {
						_.map(result.data, notification => {
							if (notification.isSeen === false) {
								setNotificationReceived(true);
							}
						});
					}
				})
				.catch(e => {
					console.error('Error on getting notification status ', e);
				});
		} else if (appBreezeMode) {
			breezeHttp
				.get(`Notification?isreaded=false`, {
					headers: {
						Authorization: accessToken || tokenRef.current,
					},
				})
				.then(result => {
					if (result?.status === 200) {
						if (result.data?.total > 0) {
							setNotificationReceived(true);
						}
					}
				})
				.catch(error => console.error(`Error while authorizing user: ${error.message}`));
		} else if (appWorklistMode) {
			const currentDateTime = new Date();
			axios
				.get(`${__config.data_sources.fhir}/Notification`)

				.then(result => {
					if (result.status === 200) {
						_.map(
							result.data.filter(
								item =>
									Math.abs(currentDateTime.getTime() - new Date(item.createdDateTime).getTime()) <
									MS_PER_MONTH
							),
							notification => {
								if (notification.isReaded === false) {
									setNotificationReceived(true);
								}
							}
						);
					}
				})
				.catch(e => {
					console.error('Error on getting notification status ', e);
				});
		}
	};

	const connectAPISocket = url => {
		axios
			.get(url)
			.then(result => {
				const connection = createSignalRConnection(result.data.url, {
					accessTokenFactory: () => result.data.accessToken,
				});
				connection.on('HandleNotification', processResponseData);
				connection.on('RequestSupport', processRequestSupport);
				connection.on('StudyAutoReload', processBlumeSync);
				connection.on('StudyInfoUpdate', processStudyUpdate);
				// signalr is being used for blume call will be removed once call signal shifted twilio
				if (appBlumeMode) connection.on('CallNotification', processCallNotification);
				startConnection(connection);
			})
			.catch(e => {
				logError('UserAuthContext::Error', 'Error on Connecting to signalR ', e);
			});
	};

	const connectTwilio = loggedEmail => {
		let url = `${__config.data_sources.fhir}/Conversation/token`;

		if (proactChatImpersonationChanges) {
			url = `${__config.data_sources.fhir}/Conversation/token?identity=${loggedEmail}`;
		}

		url = appBlumeMode ? `${__config.data_sources.blume}User/Conversation/token` : url;
		url = appBreezeMode ? `${__config.data_sources.breeze}/Conversation/token?identity=${loggedEmail}` : url;

		createClientAndDevice(url, loggedEmail);
	};

	const connectSupportTwilio = loggedEmail => {
		let twilioUrl = __config.data_sources.breeze;
		let identity = loggedEmail;
		if (nonUsEntities.includes(__config.resource_group)) {
			twilioUrl = __config.data_sources.pous01_breeze_twilio;
			identity = `${__config.resource_group}-${loggedEmail}`;
		}
		const url = `${twilioUrl}/Conversation/token?identity=${identity}`;
		createClientAndDevice(url, identity, 'support');
	};

	const connectBlumeTwilio = loggedEmail => {
		let url = `${__config.data_sources.fhir}/Conversation/blume/token`;
		if (proactChatImpersonationChanges) {
			url = `${__config.data_sources.fhir}/Conversation/blume/token?identity=${loggedEmail}`;
		}
		createClientAndDevice(url, loggedEmail, 'blume');
	};

	const createClientAndDevice = (url, loggedEmail, clientMode) => {
		const localAccessToken = accessToken || tokenRef.current;
		const customConfig = {
			headers: { Authorization: clientMode === 'support' ? '' : localAccessToken },
		};

		axios.get(addCacheBuster(url), customConfig).then(async result => {
			// Only create new client when result.data is a string which is a token (when getting token fails, result.data is an object)
			const data = result?.data;

			if (typeof data === 'string' && data.length) {
				// Chat
				const twilioClient = new Client(data);

				twilioClient.on('initialized', async () => {
					switch (clientMode) {
						case 'support':
							setSupportClient(twilioClient);
							setIsSupportClientInitialized(true);
							if (nonUsEntities.includes(__config.resource_group)) {
								loggedEmail = `${__config.resource_group}-${loggedEmail}`;
							}
							break;
						case 'blume':
							setBlumeClient(twilioClient);
							setIsBlumeClientInitialized(true);
							break;
						default:
							setClient(twilioClient);
							setIsClientInitialized(true);
					}

					let subscribedConversations = await twilioClient.getSubscribedConversations();
					let conversations = subscribedConversations.items;
					while (subscribedConversations.hasNextPage) {
						subscribedConversations = await subscribedConversations.nextPage();
						conversations = [...conversations, ...subscribedConversations.items];
					}

					conversations.forEach(async conv => {
						conv.on('messageAdded', message => {
							if (!chatMsgReceived && message.author !== loggedEmail?.toLowerCase()) {
								setChatMsgReceived(true);
							}
						});

						if (!chatMsgReceived && (await conv.getUnreadMessagesCount()) > 0) {
							setChatMsgReceived(true);
						}
					});

					twilioClient.on('conversationAdded', conversation => {
						conversation.on('messageAdded', message => {
							if (!chatMsgReceived && message.author !== loggedEmail?.toLowerCase()) {
								setChatMsgReceived(true);
							}
						});
					});
				});
			}
		});
	};

	const getConversationContacts = loggedUser => {
		const emailObj = _.find(loggedUser.extension, ['url', fhirExtensionUrls.practitioner.loginName]);
		const loggedEmail = emailObj?.valueString ?? '';

		const url = `${__config.data_sources.fhir}/Practitioner?_sort=userName&_count=1000`;

		const blumeUserurl = addCacheBuster(`${__config.data_sources.fhir}/Conversation/blume/alluser`);

		const promiseArray = [
			axios
				.get(url)
				.then(result => {
					const oaiContacts = [];
					if (result.status === 200) {
						_.forEach(result.data.entry, practitioner => {
							const userName =
								`${practitioner.resource.name?.[0]?.given?.[0] ?? ''} ${
									practitioner.resource.name?.[0]?.family ?? ''
								}` || practitioner.resource.name?.[0]?.text;
							const loginName = practitioner.resource.extension?.find(
								elem => elem.url === fhirExtensionUrls.practitioner.loginName
							)?.valueString;

							if (loginName?.toLowerCase() !== loggedEmail) {
								const role = practitioner.resource.extension?.find(
									elem => elem.url === fhirExtensionUrls.practitioner.role
								)?.valueReference?.display;

								oaiContacts.push({
									email: loginName?.toLowerCase(),
									name: userName,
									internalId: practitioner.resource.id,
									role,
									isBlumeUser: false,
								});
							}
						});
					}
					return oaiContacts;
				})
				.catch(e => {
					console.error('Error on getting Pratitioners', e);
					return [];
				}),
		];
		if (!phoenixBlumeDisableChat) {
			promiseArray.push(
				axios
					.get(blumeUserurl)
					.then(result => {
						const blumeContacts = [];
						if (result.status === 200) {
							_.forEach(result?.data, user => {
								if (user?.email !== loggedEmail) {
									blumeContacts.push({
										email: user.email,
										name: user.userName,
										internalId: user.id,
										isBlumeUser: true,
									});
								}
							});
						}
						return blumeContacts;
					})
					.catch(e => {
						console.error('Error on getting Pratitioners', e);
						return [];
					})
			);
		}
		Promise.all(promiseArray).then(([oaiContactList, blumeContactList]) => {
			const oaiContacts = oaiContactList ?? [];
			const blumeContacts = blumeContactList ?? [];

			if (oaiContacts?.length > 0 || blumeContacts?.length > 0) {
				setContacts([...oaiContacts, ...blumeContacts]);
			}
		});
	};

	const handleSyncMessage = event => {
		const { sid, data } = event?.message || {};
		const { Resource, Payload, Active, roomName, ResourceId, resources } = data;

		// Check if the message has already been processed
		if (processedSyncMessages.has(sid)) {
			return;
		}

		processedSyncMessages.add(sid);

		if (processedSyncMessages.size >= MAX_SET_SIZE) {
			processedSyncMessages.clear();
		}

		// Logout the Inactive User
		if (!event.isLocal && data?.Resource === -1) {
			resetAndLogOut();
		}

		if (!event.isLocal) {
			const isActive = Active === 'True' || Active === 'true';
			if (Payload) {
				const result = decompressedSyncPayload(Payload);
				if (result) {
					switch (Resource) {
						case TWILIO_SYNC_RESOURCE_TYPE.IMAGING_STUDY:
							eventPublish(TWILIO_SYNC_EVENT_TYPE.IMAGING_STUDY, {
								syncObjects: [
									{
										data: isActive
											? getNewResource('ImagingStudyWorklist', result)
											: { id: ResourceId },
										id: ResourceId,
										isActive,
									},
								],
							});
							break;
						case TWILIO_SYNC_RESOURCE_TYPE.APPOINTMENT:
							try {
								eventPublish(TWILIO_SYNC_EVENT_TYPE.APPOINTMENT, {
									syncObjects: [
										{
											data: isActive ? JSON.parse(result) : { id: ResourceId },
											id: ResourceId,
											isActive,
										},
									],
								});
							} catch (e) {
								// don't let any parsing error crash the app as it's not critical
								logError('UserAuthContext::error:', 'handleSyncMessage', e);
							}
							break;
						default:
							break;
					}
				}

				// Payload not present, reason: the 30s batch updates on BE exceeds 4kb limit of twilio sync
			} else if (resources?.length > 0) {
				proccessTwilioSyncEvents(
					resources.map(res => ({
						resourceId: res[TWILIO_SYNC_NOTIFICATION_DATA.ResourceId],
						resourceType: res[TWILIO_SYNC_NOTIFICATION_DATA.ResourceType],
						isActive:
							res[TWILIO_SYNC_NOTIFICATION_DATA.isActive] === 'True' ||
							res[TWILIO_SYNC_NOTIFICATION_DATA.isActive] === 'true',
					}))
				);
			} else {
				proccessTwilioSyncEvents([{ resourceId: ResourceId, resourceType: Resource, isActive }]);
			}
		}

		// Proceed incoming call notification
		if (roomName?.length > 0) {
			processCallNotification({ ...data });
		}
	};

	const urlMapping = {
		[TWILIO_SYNC_RESOURCE_TYPE.IMAGING_STUDY]: id =>
			`${__config.data_sources.fhir}/ImagingStudyWorklist/elk?internalstudyid=${id}`,
		[TWILIO_SYNC_RESOURCE_TYPE.APPOINTMENT]: id =>
			proactDisableTimeZoneFromAppointments
				? `${__config.data_sources.fhir}/Appointment?iswallclock=true&id=${id}`
				: `${__config.data_sources.fhir}/Appointment?id=${id}`,
	};

	const fetchFhirData = async (resourceType, resourceIDs) => {
		const URL = urlMapping[resourceType](resourceIDs);
		const response = await axios.get(URL);
		if (response?.status === 200 && response?.data && response?.data?.entry) {
			return response.data.entry.map(entry => ({
				...entry,
				isActive: true,
			}));
		}

		logWarn('UserAuthContext::warn:', `proccessTwilioSyncEvents - No data found - URL ${URL}, Result ${response}`);

		return [];
	};

	const proccessTwilioSyncEvents = async resources => {
		const eventTypeMapping = {
			[TWILIO_SYNC_RESOURCE_TYPE.IMAGING_STUDY]: TWILIO_SYNC_EVENT_TYPE.IMAGING_STUDY,
			[TWILIO_SYNC_RESOURCE_TYPE.APPOINTMENT]: TWILIO_SYNC_EVENT_TYPE.APPOINTMENT,
		};
		try {
			const studyIDsToUpdate =
				resources
					?.filter(res => res.resourceType === TWILIO_SYNC_RESOURCE_TYPE.IMAGING_STUDY && res.isActive)
					?.map(res => res.resourceId) || [];
			const studyIDsToDelete =
				resources
					?.filter(res => res.resourceType === TWILIO_SYNC_RESOURCE_TYPE.IMAGING_STUDY && !res.isActive)
					?.map(res => res.resourceId) || [];
			const appointmentIDsToUpdate =
				resources
					?.filter(res => res.resourceType === TWILIO_SYNC_RESOURCE_TYPE.APPOINTMENT && res.isActive)
					?.map(res => res.resourceId) || [];
			const appointmentIDsToDelete =
				resources
					?.filter(res => res.resourceType === TWILIO_SYNC_RESOURCE_TYPE.APPOINTMENT && !res.isActive)
					?.map(res => res.resourceId) || [];

			const processResourceData = async (resourceType, resourceIDs) => {
				const fhirResources = await fetchFhirData(resourceType, resourceIDs.join(','));
				if (fhirResources) {
					eventPublish(eventTypeMapping[resourceType], {
						syncObjects: fhirResources.map(entry => ({
							id: entry.resource.id,
							isActive: true,
							data:
								resourceType === TWILIO_SYNC_RESOURCE_TYPE.IMAGING_STUDY
									? getNewResource('ImagingStudyWorklist', JSON.stringify(entry.resource))
									: JSON.parse(JSON.stringify(entry.resource)),
						})),
					});
				}
			};

			const deleteResourceData = async (resourceType, resourceIDs) => {
				if (resourceIDs && resourceIDs.length) {
					eventPublish(eventTypeMapping[resourceType], {
						syncObjects: resourceIDs.map(id => ({
							id,
							isActive: false,
							data: { id },
						})),
					});
				}
			};

			if (studyIDsToUpdate && studyIDsToUpdate.length) {
				await processResourceData(TWILIO_SYNC_RESOURCE_TYPE.IMAGING_STUDY, studyIDsToUpdate);
			}

			if (studyIDsToDelete && studyIDsToDelete.length) {
				deleteResourceData(TWILIO_SYNC_RESOURCE_TYPE.IMAGING_STUDY, studyIDsToDelete);
			}

			if (appointmentIDsToUpdate && appointmentIDsToUpdate.length) {
				await processResourceData(TWILIO_SYNC_RESOURCE_TYPE.APPOINTMENT, appointmentIDsToUpdate);
			}

			if (appointmentIDsToDelete && appointmentIDsToDelete.length) {
				deleteResourceData(TWILIO_SYNC_RESOURCE_TYPE.APPOINTMENT, appointmentIDsToDelete);
			}
		} catch (error) {
			// don't let any parsing error crash the app as it's not critical
			logError('UserAuthContext::error:', 'proccessTwilioSyncEvents', error);
		}
	};

	const createSyncStreamRequest = async token => {
		const customConfig = {
			headers: { Authorization: token },
		};

		return appBlumeMode
			? blumeHttp.get(addCacheBuster('/User/SyncStreamConnectionInfo'), customConfig)
			: internalHttp.get(addCacheBuster('/token/SyncStreamConnectionInfo'), customConfig);
	};

	const retrieveTwilioSyncToken = async () => {
		const msToken = await getOrRenewToken();
		if (!msToken || msToken === 'undefined') {
			console.error(`TWILIO SYNC: MS token is undefined: ${msToken}`);
			shutDownTwilioClient();
			return;
		}
		const result = await createSyncStreamRequest(msToken);
		const { streamName, accessToken: resToken } = result?.data ?? {};

		if (resToken != null) {
			if (twilioClientRef.current) {
				console.log('TWILIO SYNC: Updating the sync client with a new access token');
				twilioClientRef.current.updateToken(resToken); // update the sync client with a new access token
			} else {
				console.log('TWILIO SYNC: Creating a new sync client');
				createSyncClient(resToken, streamName); // create a new sync client
			}
		} else {
			setTwilioConnectionStatus('error');
			setTwilioErrorMessage('No access token found in result');
			console.error('TWILIO SYNC: No access token found in result');
		}
	};

	const createSyncClient = (token, streamName) => {
		const newClient = new SyncClient(token, { logLevel: 'info' });

		newClient.on('connectionStateChanged', state => {
			if (state === 'connected') {
				twilioClientRef.current = newClient;
				setTwilioConnectionStatus('connected');
				setTwilioErrorMessage('');
				newClient.stream(streamName).then(stream => {
					stream.on('messagePublished', handleSyncMessage);
				});
			} else {
				setTwilioConnectionStatus('error');
				setTwilioErrorMessage(`Error: expected connected status but got ${state}`);
			}
		});

		newClient.on('tokenAboutToExpire', retrieveTwilioSyncToken);
		newClient.on('tokenExpired', retrieveTwilioSyncToken);
	};

	const shutDownTwilioClient = async () => {
		if (twilioClientRef.current) {
			try {
				await twilioClientRef.current.shutdown();
				console.log('TWILIO SYNC: Client shutdown successfully');
				twilioClientRef.current = null;
				setTwilioConnectionStatus('Disconnected');
				setTwilioErrorMessage('Not authorized');
			} catch (error) {
				console.error('TWILIO SYNC: Error during Sync client shutdown:', error);
			}
		}
	};

	const processResponseData = data => {
		if (data) {
			setNotificationReceived(true);
			setShouldUpdateNotification(data);
		}
	};

	const processRequestSupport = data => {
		if (data) {
			setRequestQueueReceived(data);
		}
	};

	const processCallNotification = data => {
		if (data) {
			setCallData(prev => {
				prev.isCall = true;
				prev.status.incoming = true;
				prev.callNotification = { ...data };

				return { ...prev };
			});

			if (conversationType === CHAT_CONVERSATION_TYPES.MESSAGING) {
				setConversationType(CHAT_CONVERSATION_TYPES.CALL);
			}
		}
	};

	const processBlumeSync = data => {
		if (data) {
			logInfo('AuthContext::Info', 'Processing blume sync now!');
			setSyncReloadBlume(true);
			fetchAndUpdateLoggedInUserBlume();
		}
	};

	const processStudyUpdate = () => {
		setSyncReloadBlume(true);
	};

	const createSignalRConnection = (signalRUrl, options) =>
		new HubConnectionBuilder().withUrl(signalRUrl, options).build();

	const startConnection = connection => {
		connection
			.start()
			.then(() => {
				logInfo('UserAuthContext::Info', 'Connected to SignalR');
			})
			.catch(err => {
				console.error(err);
				setTimeout(() => {
					startConnection(connection);
				}, 2000);
			});
	};

	const validateUser = () => {
		const email = sessionStorage.getItem('login_hint');

		if (email) {
			axios
				.get(
					`${
						__config.auth.omegaai.auth_api
					}/Token/ValidateUserID/?username=${email}&_dc=${new Date().getTime()}`
				)
				.then(result => {
					if (result.status === 200) {
						setUserInOmegaAI(true);
					}
				})
				.catch(() => {
					setUserInOmegaAI(false);
				});
		}
	};

	const getUserRole = () => {
		if (crossGetPrivilegeOnDemand) {
			/*
			In the new logic, we clear UAC from index db only on logout. This logic needs further improvement
			as we would like to change privilege for a user and have that take effect immediately. Until the further
			imporovements are planned and implemented, we are going deleting the IndexedDB cache on reload.
			This should be removed once we have a better cache ejecting logic based on UAC change from BE
			*/
			deleteIndexedDBValueByKey('userPrivilegesForOrganizations');
			axios.get(`${__config.data_sources.fhir}/Role/GUIrole/loggeduser?onDemand=true`).then(value => {
				if (value?.data?.GuiPermission?.length > 0) {
					updateUserPrivileges({
						organizationId: value.data.GuiPermission[0]?.InternalOrganizationID,
						guiPermission: value.data.GuiPermission[0]?.GuiRoleExtJson?.GuiExtJson,
						roleUserType: value.data.GuiPermission[0]?.GuiRoleExtJson?.userType,
						roleId: value.data.GuiPermission[0]?.InternalRoleID,
					});
					setUserAssociatedWithOnlyOneOrg(true);
				}
				setHasLoadedUserRoleForOrganizationPermissions(true);
			});
		}
		// adding a check based galaxyDvUacEnableNewPermissions as in useCheckAccess component, old UAC methods are used if this
		// flag is disabled.
		else if (!crossGetPrivilegeOnDemand || !galaxyDvUacEnableNewPermissions) {
			axios
				.get(`${__config.data_sources.fhir}/Role/GUIrole/loggeduser`, {
					headers: {
						Accept: '*/*',
						// Authorization: __userAuthContext.accessToken,
						// SessionID: __userAuthContext.sessionId,
					},
				})
				.then(value => {
					setUserRoleForOrganizations(
						_.map(value?.data?.GuiPermission || [], guiPermission => ({
							organizationId: guiPermission.InternalOrganizationID,
							guiPermission: guiPermission?.GuiRoleExtJson?.GuiExtJson,
							roleUserType: guiPermission?.GuiRoleExtJson?.userType,
							roleId: guiPermission.InternalRoleID,
						}))
					);
					setHasLoadedUserRoleForOrganizationPermissions(true);
				});
		}
	};

	const getGlobalPermissionByLoggedUser = () => {
		axios
			.get(`${__config.data_sources.fhir}/Role/GUIrole/global`, {
				headers: {
					Accept: '*/*',
					// Authorization: __userAuthContext.accessToken,
					// SessionID: __userAuthContext.sessionId,
				},
			})
			.then(value => {
				setGlobalPermissionsForLoggedUser(value?.data);
			});
	};

	const getPractitionerRoles = () => {
		axios
			.get(`${__config.data_sources.fhir}/practitioner/AssociatedOrganization`, {
				headers: {
					Accept: '*/*',
					// Authorization: __userAuthContext.accessToken,
					// SessionID: __userAuthContext.sessionId,
				},
			})
			.then(value => {
				setPractitionerRole(value?.data.entry);
			});
	};

	const getParsedJson = json => {
		try {
			return JSON.parse(json);
		} catch (e) {
			return json;
		}
	};

	// new methods to get UAC on demand

	const getUserPrivilegebyOrgId = async organizationId => {
		if (organizationId) {
			const response = await axios.get(`${__config.data_sources.fhir}/Role/GUIrole/loggeduser/${organizationId}`);

			if (response.data?.Organizations?.length > 0) {
				const userPrivilegeForThisOrg = {
					organizationId,
					guiPermission: response.data.Organizations[0]?.GuiRoleExtJson?.GuiExtJson,
					roleUserType: response.data.Organizations[0]?.GuiRoleExtJson?.userType || '',
					roleId: response.data.Organizations[0]?.InternalRoleID || '',
					guiRoleType: response.data.Organizations[0]?.GuiRoleType,
				};
				updateUserPrivileges(userPrivilegeForThisOrg);
				return userPrivilegeForThisOrg;
			}
		}
	};

	const getUserPrivilegebyOrgIdOrReferringOrg = async (organizationId, referringOrganization) => {
		if (organizationId) {
			const response = await axios.get(
				`${__config.data_sources.fhir}/Role/GUIrole/loggeduser/${organizationId}/referringOrganization/${referringOrganization}`
			);

			if (response.data?.Organizations?.length > 0) {
				const userPrivilegeForThisOrg = {
					organizationId,
					guiPermission: response.data.Organizations[0]?.GuiRoleExtJson?.GuiExtJson,
					roleUserType: response.data.Organizations[0]?.GuiRoleExtJson?.userType || '',
					roleId: response.data.Organizations[0]?.InternalRoleID || '',
					guiRoleType: response.data.Organizations[0]?.GuiRoleType,
				};
				updateUserPrivileges(userPrivilegeForThisOrg);
				return userPrivilegeForThisOrg;
			}
		}
	};

	// set the value from indexed db to state on initial load
	useEffect(() => {
		async function loadDataFromDB() {
			try {
				const privilegesFromIndexedDB = await getIndexedDbValueByKey('userPrivilegesForOrganizations');
				if (privilegesFromIndexedDB?.length > 0) {
					userPrivilegesForOrganizations.current = privilegesFromIndexedDB;
				}
			} catch (error) {
				console.error('Error loading data from IndexedDB:', error);
			}
		}
		if (crossGetPrivilegeOnDemand) {
			loadDataFromDB();
		}
	}, [crossGetPrivilegeOnDemand]);

	const updateUserPrivileges = newPrivilege => {
		const finalPrivileges = [...userPrivilegesForOrganizations.current, newPrivilege];
		// Remove duplicates based on organizationId and roleId
		const uniquePrivileges = finalPrivileges
			.reverse()
			.filter(
				(privilege, index, self) =>
					index ===
					self.findIndex(p => p.organizationId === privilege.organizationId && p.roleId === privilege.roleId)
			);
		setIndexedDBValueByKey('userPrivilegesForOrganizations', uniquePrivileges);
		userPrivilegesForOrganizations.current = uniquePrivileges;
	};

	const getUserTypeByOrganization = organizationId => {
		let currentOrganizationRole = _.filter(userPrivilegesForOrganizations.current, organizationRole => {
			if (organizationRole.organizationId === organizationId) {
				return organizationRole;
			}
		})?.[0];
		// if currentOrganizationRole not present in userRoleForOrganization get it using getUserRolebyOrgId
		if (crossGetPrivilegeOnDemand && !currentOrganizationRole) {
			currentOrganizationRole = getUserPrivilegebyOrgId(organizationId);
		}
		return currentOrganizationRole?.roleUserType || '';
	};

	const getGuiPrivilegeByOrganizationAndResourceAsync = async (organizationId, resource, referringFacilityId) => {
		/*	UAC Optimization: Skip /Role/GUIrole/loggeduser/${organizationId} API call for Ramsoft Users.
			When we call the endpoint /Role/GUIrole/loggeduser/${organizationId} for the first time and in
			response if we receieve "GuiRoleType" = "DefaultAdminGuiRole", this means the backend would
			serve UAC from code pool instead. So, we can skip subsequent api calls as we would get the same
			response for any organizations
		*/
		// check if any existing UAC from userPrivilegesForOrganizations has DefaultAdminGuiRole as GuiRoleType
		let currentOrganizationRole;
		const defaultAdminGuiRole = userPrivilegesForOrganizations.current.find(
			obj => obj.guiRoleType === 'DefaultAdminGuiRole'
		);
		if (defaultAdminGuiRole) {
			currentOrganizationRole = defaultAdminGuiRole;
		} else if (sprinterOaiIncludeReforgInGetuserrole) {
			if (referringFacilityId) {
				currentOrganizationRole = _.filter(userPrivilegesForOrganizations.current, organizationRole => {
					if (
						(organizationRole.organizationId === organizationId &&
							organizationRole.guiRoleType !== 'DefaultReferringPhysicianGuiRole') ||
						organizationRole.organizationId === referringFacilityId
					) {
						return organizationRole;
					}
				})?.[0];
			} else {
				currentOrganizationRole = _.filter(userPrivilegesForOrganizations.current, organizationRole => {
					if (organizationRole.organizationId === organizationId) {
						return organizationRole;
					}
				})?.[0];
			}
		} else {
			currentOrganizationRole = _.filter(userPrivilegesForOrganizations.current, organizationRole => {
				if (
					organizationRole.organizationId === organizationId ||
					organizationRole.organizationId === referringFacilityId
				) {
					return organizationRole;
				}
			})?.[0];
		}

		if (crossGetPrivilegeOnDemand && !currentOrganizationRole) {
			if (sprinterOaiIncludeReforgInGetuserrole && referringFacilityId) {
				currentOrganizationRole = await getUserPrivilegebyOrgIdOrReferringOrg(
					organizationId,
					referringFacilityId
				);
			} else {
				currentOrganizationRole = await getUserPrivilegebyOrgId(organizationId || referringFacilityId);
			}
		}
		let permission = {};

		if (currentOrganizationRole && currentOrganizationRole.guiPermission) {
			const orgPermission = _.find(
				getParsedJson(currentOrganizationRole.guiPermission),
				item => item.resource === resource
			);
			permission = permissionsMapper(orgPermission, true);
		}
		return permission;
	};

	const hasPermissionByOrganizationAsync = async (organizationId, resource, permissionPath, action = 'read') => {
		const [secondaryResource, tertiaryResource, quaternaryResource] = permissionPath.split('.');
		const primaryPermission = await getGuiPrivilegeByOrganizationAndResourceAsync(organizationId, resource);
		if (secondaryResource) {
			const secondaryPermission = primaryPermission?.[secondaryResource];

			if (tertiaryResource) {
				const tertiaryPermission = secondaryPermission?.[tertiaryResource];

				if (quaternaryResource) {
					return tertiaryPermission?.[quaternaryResource]?.[action] || false;
				}

				return tertiaryPermission?.[action] || false;
			}

			return secondaryPermission?.[action] || false;
		}

		return primaryPermission?.[action] || false;
	};

	// old UAC methods
	const getGuiRoleByOrganizationAndResource = (organizationId, resource, referringFacilityId, isAffiliatedOrg) => {
		let currentOrganizationRole = _.filter(userRoleForOrganizations, organizationRole => {
			if (
				organizationRole.organizationId == organizationId ||
				organizationRole.organizationId == referringFacilityId
			) {
				return organizationRole;
			}
		})?.[0];
		let permission = {};

		// TODO: Organization affiliation has not been implemented yet. As such, we need to temporarily
		// provide access to RamSoft users given the fact that we will be affiliated with all managing organizations
		// in Omega AI.
		if (!currentOrganizationRole) {
			if (userInfo.domain?.toLowerCase() == 'ramsoft.com') {
				currentOrganizationRole = defaultRamSoftPermission;
			} else if (userRoleForOrganizations.length < 1) {
				currentOrganizationRole = basicPermission;
			} else if (isAffiliatedOrg) {
				currentOrganizationRole = affiliatedPermission;
			} else {
				// trying to handle the scenario where a referring physician is trying to open their study that is driectly assigned to them, but not
				// assigned to a referring facility. I think in this case it may be fine to give them baisc permissions.
				currentOrganizationRole = basicPermission;
			}
		}

		if (currentOrganizationRole && currentOrganizationRole.guiPermission) {
			const orgPermission = _.find(
				getParsedJson(currentOrganizationRole.guiPermission),
				item => item.resource === resource
			);
			permission = permissionsMapper(orgPermission, true);
		}
		return permission;
	};

	const getGuiRole = resource => {
		const guiRole = {};
		const newRoleArr = userRoleForOrganizations.length > 0 ? userRoleForOrganizations : [basicPermission];
		newRoleArr.forEach(organizationRole => {
			let permission = {};

			if (!!organizationRole && !!organizationRole.guiPermission) {
				const orgPermission = _.find(
					getParsedJson(organizationRole.guiPermission),
					item => item.resource === resource
				);

				permission = permissionsMapper(orgPermission, false);

				permission[_.lowerCase(resource)] = {
					read: orgPermission?.action?.includes('read') || false,
				};
			}

			guiRole[organizationRole.organizationId] = permission;
		});

		return guiRole;
	};

	// Given a resource and an organization, check to see if that organization has the specified permission
	const hasPermissionByOrganization = (organizationId, resource, permissionPath, action = 'read') => {
		if (currentLoggedInUser && currentLoggedInUser?.ramsoftUserRole == 'ADMINISTRATOR') {
			return true;
		}

		const [secondaryResource, tertiaryResource, quaternaryResource] = permissionPath.split('.');
		const primaryPermission = getGuiRoleByOrganizationAndResource(organizationId, resource);
		if (secondaryResource) {
			const secondaryPermission = primaryPermission?.[secondaryResource];

			if (tertiaryResource) {
				const tertiaryPermission = secondaryPermission?.[tertiaryResource];

				if (quaternaryResource) {
					return tertiaryPermission?.[quaternaryResource]?.[action] || false;
				}

				return tertiaryPermission?.[action] || false;
			}

			return secondaryPermission?.[action] || false;
		}

		return primaryPermission?.[action] || false;
	};

	// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
	const parseJwt = token => {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
				.join('')
		);

		return JSON.parse(jsonPayload);
	};

	const updateAccessToken = token => {
		const decodedToken = token && parseJwt(token);
		const kmsi = localStorage.getItem('kmsi');

		if (decodedToken) {
			if (decodedToken.kmsi && decodedToken.kmsi.toUpperCase() == 'TRUE') {
				// KMSI is on for logged user
				localStorage.setItem('kmsi', decodedToken.email || decodedToken.phone);
			} else if (
				kmsi &&
				decodedToken.email.toUpperCase() !== kmsi.toUpperCase() &&
				decodedToken.phone.toUpperCase() !== kmsi.toUpperCase()
			) {
				localStorage.removeItem('kmsi');
			}

			if (decodedToken.deviceId) {
				localStorage.setItem(
					`${decodedToken.email?.toLocaleLowerCase() || decodedToken.phone?.toLocaleLowerCase()}_deviceId`,
					encodeURIComponent(decodedToken.deviceId)
				);
			}

			/**
			 * We are using UserIdP for
			 *  Omega AI and Blume: for switching application between applications
			 * 	Blume: For PIN reset logic enable for local users only.
			 */
			if (decodedToken.idp) {
				setUserIdP(decodedToken.idp);
			} else if (decodedToken.idp_type) {
				// IdP not exists for B2C local users
				setUserIdP(decodedToken.idp_type);
			}

			if (
				decodedToken.idp === 'ramsoft.com' &&
				decodedToken.idpGroupMemberships &&
				Array.isArray(decodedToken.idpGroupMemberships)
			) {
				setOmegaAIAffiliateRights(
					decodedToken.idpGroupMemberships.includes('Omega AI Affiliation') ||
						decodedToken.idpGroupMemberships.includes('Prod Omega AI Affiliation')
				);
			}
		} else {
			// Logging out
			localStorage.removeItem('kmsi');
		}

		navigator?.serviceWorker?.controller?.postMessage({
			type: 'token',
			message: token,
			authapiurl: __config.auth.omegaai.auth_api,
			dicomapiurl: __config.data_sources.dicom_web,
			username: decodedToken?.email?.toLowerCase() || decodedToken?.phone?.toLowerCase(),
			wonIvServiceworker,
			wonIvWebWorkerCache,
			wonPreCacheImagesByWebworker,
			wonCacheSeriesMetadataInClient,
		});

		setAccessToken(token);
	};

	// Get the Marketplace User ID that corresponds with the oid
	const getMarketplaceUserId = () => {
		if (!proactHideMarketplace) {
			axios
				.get(`${__config.data_sources.marketplace}users/getID`)
				.then(res => {
					if (res?.status === 200) {
						setMarketplaceUserId(res.data);
					}
				})
				.catch(error => console.error(`Error while getting current Marketplace User ID: ${error.message}`));
		}
	};

	const getLogLevel = () => {
		const level = sprinterMsalLoglevel?.toString().toLowerCase() || 'error';
		switch (level) {
			case 'error':
				return LogLevel.Error;
			case 'info':
				return LogLevel.Info;
			case 'verbose':
				return LogLevel.Verbose;
			case 'warning':
				return LogLevel.Warning;
			default:
				return LogLevel.Error;
		}
	};

	// here, we create the public client application that we will need throughout
	const msalInstance = useMemo(() => {
		//get  authority
		let authority = sprinterOaiStageingpolicy
			? __config.auth.omegaai.msal.stage_authority
			: __config.auth.omegaai.msal.authority;
		if (appBlumeMode) {
			authority = sprinterBlumeStageingpolicy
				? __config.auth.blume.msal.stage_authority
				: __config.auth.blume.msal.authority;
		}
		const msalConfig = {
			auth: {
				clientId: __config.auth.omegaai.msal.client_id,
				authority, // __config.auth.omegaai.msal.authority,
				// authority,
				// most likely going to be /home
				redirectUri: __config.auth.omegaai.msal.redirect_uri,
				knownAuthorities: __config.auth.omegaai.msal.known_authorities,
			},
			cache: {
				cacheLocation: __config.auth.omegaai.msal.cache.location,
				storeAuthStateInCookie:
					// this should almost always be false...I'm almost positive setting this to true breaks everything
					__config.auth.omegaai.msal.cache.store_auth_state_in_cookie,
			},
			system: {
				//https://learn.microsoft.com/en-us/entra/identity-platform/msal-logging-js#logging-levels
				loggerOptions: {
					logLevel: getLogLevel(),
					loggerCallback: (level, message, containsPii) => {
						if (containsPii) {
							return;
						}
						switch (level) {
							case LogLevel.Error:
								console.error(message);
								return;
							case LogLevel.Info:
								console.info(message);
								return;
							case LogLevel.Verbose:
								console.debug(message);
								return;
							case LogLevel.Warning:
								console.warn(message);
						}
					},
					piiLoggingEnabled: false,
				},
				allowRedirectInIframe: true,
			},
		};

		const pca = new PublicClientApplication(msalConfig);

		// this is where we handle all the different auth events; this is the meat of our MSAL handling
		pca.addEventCallback(event => {
			let account;
			switch (event.eventType) {
				case EventType.ACQUIRE_TOKEN_NETWORK_START:
				case EventType.INITIALIZE_START:
					checkAccessTokenExpiry();
					break;
				case EventType.LOGIN_START:
					loggingInRef.current = true;
					setLoggingIn(true);
					break;

				case EventType.LOGIN_SUCCESS: {
					logInfo('MSAL:addEventCallback::Info', `Login Success: ${new Date().toUTCString()}`);
					loggedInRef.current = true;
					if (crossMoveDevCycleSetContext) {
						const emailId = sessionStorage.getItem('login_hint');
						const devCycleContext = {
							user_id: emailId.toUpperCase(),
							name: emailId,
							email: emailId?.toUpperCase(),
							customData: {
								entity: `${__config.resource_group}`.trim().toUpperCase(),
							},
						};
						OpenFeature.setContext(devCycleContext);
					}
					setLoggedIn(true);
					loggingInRef.current = false;
					setLoggingIn(false);
					setSendLoginMessage(true);
					break;
				}
				case EventType.ACQUIRE_TOKEN_SUCCESS:
					// we don't want to trigger this every time we call an API, so we only handle redirects
					if (event.interactionType === InteractionType.Redirect) {
						const newToken = `${event.payload.tokenType} ${event.payload.accessToken}`;
						tokenRef.current = newToken;
						updateAccessToken(newToken);
						setOid(
							event.payload.idToken
								? event.payload.idTokenClaims.oid
								: event.payload.account.localAccountId
						);

						loggedInRef.current = true;
						setLoggedIn(true);
						loggingInRef.current = false;
						setLoggingIn(false);
					}

					setUserInfoFunc();
					break;
				case EventType.LOGIN_FAILURE:
					loggedInRef.current = false;
					setLoggedIn(false);
					loggingInRef.current = false;
					setLoggingIn(false);
					break;
				case EventType.ACQUIRE_TOKEN_FAILURE:
					resetAndLogOut();
					break;
				case EventType.LOGOUT_START:
					loggedInRef.current = false;
					setLoggedIn(false);
					loggingOutRef.current = true;
					setLoggingOut(true);
					break;
				case EventType.LOGOUT_SUCCESS:
				case EventType.LOGOUT_END:
				case EventType.LOGOUT_FAILURE:
					loggingOutRef.current = false;
					setLoggingOut(false);
					break;

				case EventType.ACCOUNT_ADDED:
				case EventType.ACCOUNT_REMOVED:
					setUserInfoFunc(true);
					break;
				case EventType.HANDLE_REDIRECT_END:
					// if the page is refreshed, this event is emitted
					if (location.pathname.includes('idptest')) {
						const emailId = sessionStorage.getItem('idp_test_user');
						account = msalInstance.getAccountByUsername(emailId);
						if (account) {
							msalInstance
								.acquireTokenSilent({
									scopes: __config.auth.omegaai.scopes,
									account,
								})
								.then(data => {
									if (!testIdpUserAuthenticatedRef.current) {
										testIdpUserAuthenticatedRef.current = true;
										setTestIdpUserAuthenticated(testIdpUserAuthenticatedRef.current);
									}
								});
						}
					} else {
						const emailId = sessionStorage.getItem('login_hint');
						if (emailId) {
							account = msalInstance.getAccountByUsername(emailId);
						} else {
							account = msalInstance.getAllAccounts()[0];
						}
						if (account) {
							sessionStorage.setItem('login_hint', account.username);
							msalInstance.setActiveAccount(account);
							loggedInRef.current = true;
							setLoggedIn(true);
							if (!accessToken) {
								// covers a page refresh
								msalInstance
									.acquireTokenSilent({
										scopes: __config.auth.omegaai.scopes,
										account: msalInstance.getActiveAccount(),
									})
									.then(data => {
										if (!oid) {
											setOid(data.idToken ? data.idTokenClaims.oid : data.account.localAccountId);
										}

										const newToken = `${data.tokenType} ${data.accessToken}`;
										tokenRef.current = newToken;
										updateAccessToken(newToken);
									})
									.catch(async error => {
										await handleAuthError(error);
									});
							}
						}
					}

					break;
				// these are all the rest of the possible event types...they're here for reference and
				// in case we need to add handling logic for these in the future
				case EventType.ACQUIRE_TOKEN_START:
				case EventType.HANDLE_REDIRECT_START:
				case EventType.SSO_SILENT_START:
				case EventType.SSO_SILENT_SUCCESS:
				case EventType.SSO_SILENT_FAILURE:
				case EventType.ACQUIRE_TOKEN_BY_CODE_START:
				case EventType.ACQUIRE_TOKEN_BY_CODE_SUCCESS:
				case EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE:
				case EventType.POPUP_OPENED: {
					break;
				}
				default:
					break;
			}

			function setUserInfoFunc(forceUpdate = false) {
				account = msalInstance.getAccountByUsername(sessionStorage.getItem('login_hint'));
				if (!!account && (userInfoRef.current?.localAccountId !== account.localAccountId || forceUpdate)) {
					msalInstance.setActiveAccount(account);
					const userInfoObj = {
						localAccountId: account.localAccountId,
						username: account.idTokenClaims.preferred_username,
						name: account.idTokenClaims.name,
						firstName: account.idTokenClaims.given_name,
						lastName: account.idTokenClaims.family_name,
						domain: account.idTokenClaims.extension_idp_domain,
					};

					userInfoRef.current = userInfoObj;
					setUserInfo(userInfoRef.current);
				}
			}
		});
		return pca;
	}, [appMode, sprinterBlumeStageingpolicy]);

	useEffect(() => {
		if (wonEnableSpanish) {
			setLanguages(prev => [...prev, { code: 'es', name: 'Español' }]);
		}
	}, [wonEnableSpanish]);

	// when the OID changes, we need to make a call to the API to authenticate
	useEffect(() => {
		// authenticate OID here
		if (!!oid && loggedIn && !userAuthorized && (!!tokenRef.current || !!accessToken)) {
			if (appBlumeMode) {
				blumeHttp
					// Using the version 3 API.
					.get(`User/loggeduser?version=3&platform=web`, {
						headers: {
							Authorization: accessToken || tokenRef.current,
							sessionId: localSessionId,
						},
					})
					.then(res => {
						setUserAuthorized(true);
						setSessionIdValid(true);

						if (res?.status === 200) {
							setProfiles(res?.data?.aggregatedetails);
							let firstName = '';
							let lastName = '';
							// Find the primary user and the aggregate details for the primary user
							const primaryUser = Object?.values(res?.data?.aggregatedetails)?.find(
								details => details?.isPrimary === true
							);

							if (primaryUser?.firstName?.length > 0) {
								firstName = primaryUser?.firstName;
							}
							if (primaryUser?.lastName?.length > 0) {
								lastName = primaryUser?.lastName;
							}
							setCurrentLoggedInUser({
								id: res.data.id,
								email: res.data.email || res.data.phone,
								display: primaryUser?.firstName,
								reference: `User/${res.data.id}`,
								fullName: `${firstName} ${lastName}`.trim(),
								firstName: _.join(primaryUser?.firstName, ''),
								imageViewerUrl: getUserImageViewerUrl(res.data),
								documentViewerUrl: getUserDocumentViewerUrl(res.data),
								isBlumeUser: true,
								isBlumeSynced: Boolean(res?.data?.isSynced),
							});
							setContacts(res.data.contacts);
							setBlumeUserSettings(res.data.userSettings);
							setIsSentOTP(!!res.data?.verificationOTP);
							setPatientMapping(res.data.patientMapping);
							if (!res.data.isSynced) {
								blumeHttp
									.get(`User/getuser`, {
										headers: {
											Authorization: accessToken || tokenRef.current,
											sessionId: localSessionId,
										},
									})
									.then(res => {
										setIsEmailVerified(true);
									})
									.catch(err => {
										console.log('error verifying the email', err);
										setIsEmailVerified(false);
									});
							} else {
								setIsEmailVerified(res.data?.isEmailVerified);
							}
							setIsPhoneVerified(res.data?.isPhoneVerified);
							setIsRecordFound(res.data?.recordFound);

							const socketURL = `${__config.data_sources.blume}User/SignalRConnect`;
							connectAPISocket(socketURL);
							getNotificationStatus(res.data.id);

							const loginHint = sessionStorage.getItem('login_hint');
							if (
								loginHint &&
								res.data.email &&
								res.data.email.toLowerCase() !== loginHint.toLowerCase()
							) {
								datadogRum?.setUser({
									id: `${loginHint}_impersonated_${res.data.email}`,
									name: `Impersonated Session For ${firstName} ${lastName}`.trim(),
									email: loginHint,
								});
							} else {
								datadogRum?.setUser({
									id: res.data.id,
									name: `${firstName} ${lastName}`.trim(),
									email: res.data.email || res.data.phone,
								});
							}

							const devCycleContext = {
								user_id: res.data.email?.toUpperCase() || res.data.phone?.toUpperCase(),
								name: `${firstName} ${lastName}`.trim(),
								email: res.data.email?.toUpperCase() || res.data.phone,
								customData: {
									entity: `${__config.resource_group}`.trim().toUpperCase(),
								},
							};

							OpenFeature.setContext(devCycleContext);

							if (!phoenixBlumeDisableChat) {
								retrieveTwilioSyncToken();
								connectTwilio(res.data.email);
							}
						}
					})
					.catch(error => console.error(`Error while authorizing user: ${error.message}`));
				if (userInOmegaAI === null) {
					validateUser();
				}
			} else if (appBreezeMode) {
				breezeHttp
					.get(`User/loggeduser`, {
						headers: {
							Authorization: accessToken || tokenRef.current,
							sessionId: localSessionId,
						},
					})
					.then(res => {
						if (res?.status === 200) {
							setUserAuthorized(true);
							setSessionIdValid(true);
							const permissionJson = {};
							_.forEach(res.data.permission, item => {
								const permissionName = item.Category;
								const permissionDetail = {
									Read: item.Read,
									Add: item.Add,
									Edit: item.Edit,
									Delete: item.Delete,
								};

								permissionJson[permissionName] = permissionDetail;
							});
							setCurrentLoggedInUser({
								id: res.data.internalUserID,
								display: res.data.name,
								reference: `User/${res.data.internalUserID}`,
								fullName: getUserFullName(res.data.name),
								firstName: res.data.name,
								userName: res.data.userName,
								imageViewerUrl: getUserImageViewerUrl(res.data),
								documentViewerUrl: getUserDocumentViewerUrl(res.data),
								permission: permissionJson,
							});

							const socketURL = `${__config.data_sources.breeze}/User/SignalRConnect`;
							connectAPISocket(socketURL);
							if (permissionJson.Notification?.Read) {
								getNotificationStatus(res.data.internalUserID);
							}
							connectTwilio(res.data.userName);

							const devCycleContext = {
								user_id: res.data.userName?.toUpperCase(),
								name: res.data.name,
								email: res.data.userName?.toUpperCase(),
								customData: {
									entity: `${__config.resource_group}`.trim().toUpperCase(),
								},
							};
							OpenFeature.setContext(devCycleContext);
						}
					})
					.catch(error => console.error(`Error while authorizing user: ${error.message}`));
			} else {
				internalHttp
					.get(`authorize/azureADUser/${oid}`, {
						headers: {
							Authorization: accessToken || tokenRef.current,
							sessionId: localSessionId,
						},
					})
					.then(() => setUserAuthorized(true))
					.catch(error => console.error(`Error while authorizing user: ${error.message}`));
			}
		} else if (!userAuthorized && isSharedStudy) {
			setUserAuthorized(true);
			setSessionIdValid(true);
		}
	}, [oid, loggedIn, userAuthorized]);

	useEffect(() => {
		if (currentLoggedInUser && !appBlumeMode && !appBreezeMode) {
			axios
				.get(
					`${__config.data_sources.fhir}/${fhirEndpoints.practitionerRole}?practitioner=${currentLoggedInUser.id}`
				)
				.then(res => {
					if (res?.data?.entry) {
						setUserPractitionerRolesPerOrganization(res.data.entry.map(({ resource }) => resource));
					}
				});
		}
	}, [currentLoggedInUser]);

	const updateProfiles = () => {
		if (!!tokenRef.current || !!accessToken) {
			if (appBlumeMode) {
				blumeHttp
					.get(`User/loggeduser?version=3&platform=web`, {
						headers: {
							Authorization: accessToken || tokenRef.current,
							sessionId: localSessionId,
						},
					})
					.then(res => {
						setUserAuthorized(true);
						setSessionIdValid(true);

						if (res?.status === 200) {
							setProfiles(res?.data?.aggregatedetails);
						}
					});
			}
		}
	};

	const fetchAndUpdateLoggedInUserBlume = () => {
		if (appBlumeMode) {
			blumeHttp
				.get(`User/loggeduser?version=3&platform=web`, {
					headers: {
						Authorization: accessToken || tokenRef.current,
						sessionId: localSessionId,
					},
				})
				.then(res => {
					if (res?.status === 200) {
						let firstName = '';
						let lastName = '';
						// Find the primary user and the aggregate details for the primary user
						const primaryUser = Object?.values(res?.data?.aggregatedetails)?.find(
							details => details?.isPrimary === true
						);

						if (primaryUser?.firstName?.length > 0) {
							firstName = primaryUser?.firstName;
						}
						if (primaryUser?.lastName?.length > 0) {
							lastName = primaryUser?.lastName;
						}
						setCurrentLoggedInUser({
							id: res.data.id,
							email: res.data.email || res.data.phone,
							display: primaryUser?.firstName,
							reference: `User/${res.data.id}`,
							fullName: `${firstName} ${lastName}`.trim(),
							firstName: _.join(primaryUser?.firstName, ''),
							imageViewerUrl: getUserImageViewerUrl(res.data),
							documentViewerUrl: getUserDocumentViewerUrl(res.data),
							isBlumeUser: true,
							isBlumeSynced: Boolean(res?.data?.isSynced),
						});
						setContacts(res.data.contacts);
						setBlumeUserSettings(res.data.userSettings);
						setIsSentOTP(!!res.data?.verificationOTP);
						setPatientMapping(res.data.patientMapping);
						setIsEmailVerified(res.data?.isEmailVerified);
						setIsPhoneVerified(res.data?.isPhoneVerified);
						setIsRecordFound(res.data?.recordFound);
					}
				});
		}
	};

	const updateLoggedInUser = userObj => {
		setCurrentLoggedInUser({
			email: userObj?.email ?? currentLoggedInUser?.email,
			id: userObj?.id ?? currentLoggedInUser?.id,
			display: userObj?.fullName ?? currentLoggedInUser?.display,
			reference: userObj?.reference ?? currentLoggedInUser?.reference,
			fullName: userObj?.fullName ?? currentLoggedInUser?.fullName,
			firstName: userObj?.firstName ?? currentLoggedInUser?.firstName,
			imageViewerUrl: userObj?.imageViewerUrl ?? currentLoggedInUser?.imageViewerUrl,
			documentViewerUrl: userObj?.documentViewerUrl ?? currentLoggedInUser?.documentViewerUrl,
			organization: userObj?.organization ?? currentLoggedInUser?.organization,
			phone: userObj?.phone ?? currentLoggedInUser?.phone,
			ramsoftUserRole: userObj?.ramsoftUserRole ?? currentLoggedInUser?.ramsoftUserRole,
		});
	};

	// Get current loggedIn user when fully authorized.
	useEffect(() => {
		if (fullyAuthorized && !appBlumeMode && !appBreezeMode && !isSharedStudy) {
			internalHttp
				.get(`${__config.data_sources.fhir}/${fhirEndpoints.practitionerLoggedUser}?_elements=telecom`, {
					headers: {
						Authorization: accessToken || tokenRef.current,
						sessionId: localSessionId,
					},
				})
				.then(res => {
					if (res?.status === 200) {
						const emailObj = _.find(res.data.extension, ['url', fhirExtensionUrls.practitioner.loginName]);
						const email = emailObj.valueString;
						const userRole = res.data.extension?.find(
							elem => elem.url === fhirExtensionUrls.practitioner.role
						)?.valueReference;
						const ramsoftUserRole = res.data.extension?.find(
							elem => elem.url === fhirExtensionUrls.common.ramSoftUserRole
						)?.valueString;
						setAppointmentVisibleHours(
							res.data.extension?.find(elem => elem.url === fhirExtensionUrls.practitioner.visibleHours)
								?.valueString || 8
						);
						setLastOrderOrganization(
							res.data.extension?.find(
								elem => elem.url === fhirExtensionUrls.practitioner.lastOrderOrganization
							)?.valueReference
						);
						setCurrentLoggedInUser({
							email,
							id: res.data.id,
							display: res.data.name?.[0].text,
							reference: `Practitioner/${res.data.id}`,
							fullName: crossFormatPersonName
								? parseFhirName(res.data.name?.[0])
								: getUserFullName(res.data.name?.[0]),
							firstName: _.join(res.data.name?.[0].given, ' '),
							lastName: res.data.name?.[0].family,
							imageViewerUrl: getUserImageViewerUrl(res.data),
							documentViewerUrl: getUserDocumentViewerUrl(res.data),
							role: userRole?.display,
							roleId: userRole?.id,
							isBlumeUser: false,
							organization: res.data.extension?.find(
								elem => elem.url === fhirExtensionUrls.common.organization
							)?.valueReference,
							phone: res.data.telecom?.find(elem => elem.system === 'phone')?.value,
							rawData: res.data,
							ramsoftUserRole,
						});

						const userLanguage = getLanguage(res.data) || 'en';
						if (userLanguage !== selectedLanguage) {
							setSelectedLanguage(userLanguage);
						}

						const monitorSetup = getMonitorSetup(res.data);
						setDisplaySettings(monitorSetup);
						displaySettingsRef.current = monitorSetup;

						datadogRum?.setUser({
							id: res.data.id,
							name: getUserFullName(res.data.name[0]),
							email,
						});
						if (crossMoveDevCycleSetContext) {
							getUserRole();
							setCrossFormatPersonNameGV(crossFormatPersonName);
						} else {
							const devCycleContext = {
								user_id: email?.toUpperCase(),
								name: getUserFullName(res.data.name[0]),
								email: email?.toUpperCase(),
								customData: {
									entity: `${__config.resource_group}`.trim().toUpperCase(),
								},
							};

							OpenFeature.setContext(devCycleContext);
							setDevCycleContextUpdated(true);
						}

						retrieveTwilioSyncToken(); // Init Twilio sync client

						const socketURL = `${__config.auth.omegaai.auth_api}/token/SignalRConnectionInfo?hub=notification&user=${res.data.id}`;
						connectAPISocket(socketURL);
						getNotificationStatus(res.data.id);
						connectTwilio(email);
						if (!metaDisableBreezeSupport) {
							connectSupportTwilio(email);
						}
						connectBlumeTwilio(email);
						getConversationContacts(res.data);
					}
				})
				.catch(error => console.error(`Error while getting current loggedIn user: ${error.message}`));
			getMarketplaceUserId();
		}
	}, [fullyAuthorized]);

	useEffect(() => {
		// Adding this useEffect to handle methods which needs feature flags after the dev cycle context is updated with user info
		if (devCyleContextUpdated) {
			getUserRole();
			setCrossFormatPersonNameGV(crossFormatPersonName);
		}
	}, [devCyleContextUpdated, crossFormatPersonName]);

	useEffect(() => {
		if (appWorklistMode && currentLoggedInUser && !!sendLoginMessage) {
			internalHttp
				.get(`/portal/login/${currentLoggedInUser?.id}?name=${currentLoggedInUser?.email}`, {
					headers: {
						Authorization: accessToken,
					},
				})
				.catch(error => console.error(`Error while get portal login ${error.message}`));

			setSendLoginMessage(false);
		}
	}, [currentLoggedInUser, sendLoginMessage]);

	// last step: get the session ID
	useEffect(() => {
		// We dont want to call FHIR Auth service for Blume
		let isAbleToGetSessionId = !!oid && loggedIn && userAuthorized && !appBlumeMode && !appBreezeMode;
		isAbleToGetSessionId = isAbleToGetSessionId && !isSharedStudy;

		if (isAbleToGetSessionId) {
			internalHttp
				.get(`/portal/values`, {
					headers: {
						Authorization: accessToken,
					},
				})
				.then(response => {
					const idx = response.data.findIndex(elem => elem.item === 'SessionID');
					if (idx !== -1) {
						setSessionId(response.data[idx].value);
						setLocalSessionId(response.data[idx].value);
						setSessionIdValid(true);
					}
				})
				.catch(error => console.error(`Error while getting session ID ${error.message}`));
		}
	}, [oid, loggedIn, userAuthorized]);

	// we're fully authorized only if all of the conditions are met: access token, oid, and session ID
	useEffect(() => {
		if (!!accessToken && isSharedStudy) {
			setFullyAuthorized(true);
			setLoggedIn(true);
			registerServiceWorker();
		} else if (
			!!accessToken &&
			!!oid &&
			userAuthorized &&
			((!!sessionId && sessionIdValid) || appBlumeMode || appBreezeMode)
		) {
			setFullyAuthorized(true);
			addResponseInterceptors();
			addRequestInterceptors();
			if (!appBlumeMode && !appBreezeMode) {
				getGlobalPermissionByLoggedUser();
				getPractitionerRoles();
			}
		} else {
			setFullyAuthorized(false);
		}
	}, [accessToken, sessionId, oid, userAuthorized, sessionIdValid]);

	// Token should be not be expired to mentioned minutes
	const checkAccessTokenExpiry = async () => {
		if (sprinterOaiAccessTokenExpiry) {
			const msalAccount =
				sessionStorage.getItem('login_hint') !== null
					? msalInstance.getAccountByUsername(sessionStorage.getItem('login_hint'))
					: msalInstance.getAllAccounts().length > 0
					? msalInstance.getAllAccounts()[0]
					: null;
			const idTokenExpiry = msalAccount?.idTokenClaims?.exp ?? 0;
			if (Math.round(Date.now() / 1000) > idTokenExpiry + __config.auth.idealTimeToLogout * 60) {
				logInfo(
					'checkAccessTokenExpiry::Info:',
					`Access Token has been expired before ${__config.auth.idealTimeToLogout} minutes, idTokenExpiry expired on ${idTokenExpiry}, Logout user using resetAndLogOut.`
				);
				await resetAndLogOut();
			} else {
				logDebug(
					'checkAccessTokenExpiry::Debug:',
					`Access token is good to continue with application. Token expired on ${idTokenExpiry}`
				);
			}
		} else {
			logDebug('checkAccessTokenExpiry::Debug:', `checkAccessTokenExpiry FF is OFF`);
		}
	};

	// if we're logged in, we're not logging in
	useEffect(() => {
		if (loggedIn) {
			setLoggingIn(false);
			loggingInRef.current = false;
		}
	}, [loggedIn]);

	const registerServiceWorker = () => {
		// Register service worker
		if ('serviceWorker' in navigator) {
			// When a replacement service worker is activated, we need to send it a token.
			// Note that accessToken includes "Bearer"
			navigator.serviceWorker.addEventListener('controllerchange', () => {
				if (navigator.serviceWorker.controller) {
					navigator.serviceWorker.controller.postMessage({
						type: 'token',
						message: accessToken,
						authapiurl: __config.auth.omegaai.auth_api,
						dicomapiurl: __config.data_sources.dicom_web,
						username: currentLoggedInUser?.email || currentLoggedInUser?.phone,
						wonIvServiceworker,
						wonIvWebWorkerCache,
						wonPreCacheImagesByWebworker,
						wonCacheSeriesMetadataInClient,
					});
				}
			});

			navigator.serviceWorker.register('serviceworker.js').then(
				() => {
					console.log('Registered ServiceWorker');
				},
				err => {
					// registration failed :(
					console.log('ServiceWorker registration failed: ', err);
				}
			);
		}
	};

	// if we need to log out, we want to reset all the states; this is because the UserAuthContext exists
	// on top of everything, including what we see when we're not authenticated...so if we don't reset all
	// this stuff before we head over to the login page, we end up in a big ol' loop
	const resetAndLogOut = useCallback(
		async logoutUrl => {
			if (!loggingOut.current) {
				logInfo('resetAndLogOut::Info:', `Logging out.`);

				if (won3715MmLogoutCloseother) {
					//close all other screens
					const multiscreenBroadcastChannel = new BroadcastChannel('MULTISCREEN');
					multiscreenBroadcastChannel.postMessage({
						event: 'USER_LOGOUT',
					});
					multiscreenBroadcastChannel.close();
				}

				clearRadPairStudyInfo();

				shutDownTwilioClient();
				// do this first so that API calls stop
				setFullyAuthorized(false);
				loggingOutRef.current = true;

				setLoggingOut(true);
				if (requestInterceptor) {
					axios.interceptors.request.eject(requestInterceptor);
					setRequestInterceptor(null);
				}

				if (responseInterceptor) {
					axios.interceptors.response.eject(responseInterceptor);
					setResponseInterceptor(null);
				}

				loggedInRef.current = false;
				loggingInRef.current = false;

				tokenRef.current = null;
				setLoggingIn(false);

				setLoggingOut(true);

				setLoggedIn(false);

				if (appWorklistMode && currentLoggedInUser) {
					internalHttp
						.get(`/portal/logout/${currentLoggedInUser?.id}`, {
							headers: {
								Authorization: accessToken,
							},
						})
						.catch(error => console.error(`Error while get portal logout ${error.message}`));
				}

				updateAccessToken(null);
				setOid(null);
				setSessionId(null);
				setSessionIdValid(false);

				setUserInfo(null);
				const msalAccounts = msalInstance.getAllAccounts();
				if (msalAccounts.length > 0) {
					msalInstance
						.logoutRedirect({
							account:
								sessionStorage.getItem('login_hint') !== null
									? msalInstance.getAccountByUsername(sessionStorage.getItem('login_hint'))
									: msalAccounts[0],
							onRedirectNavigate: () =>
								// Return false to stop navigation after local logout
								false,
							postLogoutRedirectUri: logoutUrl || __config.auth.omegaai.msal.logout_redirect_uri,
						})
						.then(() => {
							postLogOutMessage();
							removeLocalSessionID();
							sessionStorage.removeItem('login_hint');
							// We need to navigate to the root as we want msal to stop navigation after local logout
							goTo.defaultRoot();
						});
				}
				// removed UserRoleForOrganizations from indexed DB
				if (crossGetPrivilegeOnDemand) {
					deleteIndexedDBValueByKey('userPrivilegesForOrganizations');
				}
			}
		},
		[requestInterceptor, responseInterceptor, loggingOut, loggingIn, currentLoggedInUser, crossGetPrivilegeOnDemand]
	);

	// for now, error handling isn't fully online, so we need to handle our authentication errors here
	const handleAuthError = useCallback(
		async error => {
			if (error.errorCode) {
				let errorToHandle =
					_.find(ClientAuthErrorMessage, elem => elem.code === error.errorCode) ||
					_.find(ClientConfigurationErrorMessage, elem => elem.code === error.errorCode);

				if (!errorToHandle) {
					errorToHandle = {
						code: error.errorCode,
						desc: error.errorCode,
					};
				}
				setLastError(errorToHandle);

				const interactionError = InteractionRequiredAuthError.isInteractionRequiredError(errorToHandle.code);
				const authError = _.includes(ClientAuthErrorMessage, errorToHandle);
				const configError = _.includes(ClientConfigurationErrorMessage, errorToHandle);

				if (interactionError || authError) {
					console.error(
						`${interactionError ? 'Interaction' : 'Authentication'} error: ${errorToHandle.desc}`
					);
					await resetAndLogOut();
				} else if (configError) {
					console.error(`Configuration error: ${errorToHandle.desc}`);
				}
			}
		},
		[resetAndLogOut]
	);

	// should be used only as part of the interceptor; handles silently getting a token for each API call
	const getOrRenewToken = useCallback(async () => {
		try {
			const data = await msalInstance.acquireTokenSilent({
				scopes: [__config.auth.omegaai.scopes[0], `offline_access`, `openid`, `email`, `profile`],
				account: msalInstance.getActiveAccount(),
			});

			if (data) {
				const newToken = `${data.tokenType} ${data.accessToken}`;

				if (tokenRef.current !== newToken) {
					logInfo('getOrRenewToken::Info:', `Slilent token acquired successfully.`);
					// just in case we need the token before re-render
					tokenRef.current = newToken;
					updateAccessToken(newToken);
				}
				return newToken;
			}
		} catch (error) {
			logError('getOrRenewToken::Info:', `Slilent token returns error.`);
			await handleAuthError(error);
		}
	}, [msalInstance, handleAuthError, accessToken]);

	/**
	 * Request and response interceptors for axios
	 */
	const requestHandler = useCallback(
		config =>
			getOrRenewToken()
				.then(token => {
					const headerSessionId = _.endsWith(config.url, '/portal/values') ? {} : { sessionId };

					return Promise.resolve({
						...config,
						// always return true; we don't want axios to treat 400/500 codes as errors
						// because we want to handle them ourselves.
						validateStatus: () => true,
						headers: {
							...config.headers,
							common: {
								...config.headers.common,
								Accept: '*/*',
								Authorization: token,
								...headerSessionId,
							},
						},
						signal: abortControllerRef.current.signal,
					});
				})
				.catch(() => {
					abortControllerRef.current.abort();
				}),
		[getOrRenewToken, sessionId]
	);

	// we don't want to throw when we get a 400 or a 500; we just handle them...the reason is that
	// we do not want the whole app to crash if there's a server error or the client sends a bad
	// request; we can log any unhandled error for now until better error handling is implemented
	// ...in the request config, we can actually specify which status codes will resolve and which will
	// reject, so in our case we just return true always, meaning nothing throws an error. This is
	// how we get around the way axios treats 400/500 errors as exception-worthy
	const responseHandler = useCallback(
		async response => {
			{
				let breezeTwilioUrl = __config.data_sources.breeze;
				if (nonUsEntities?.includes(__config.resource_group)) {
					breezeTwilioUrl = __config.data_sources.pous01_breeze_twilio;
				}

				if (_.inRange(response?.status, 100, 400)) {
					return response;
				}
				if (
					_.includes([401], response?.status) &&
					!response?.request.responseURL.includes(`${breezeTwilioUrl}/Conversation`) &&
					!response?.request.responseURL.includes(`${__config.data_sources.blume}User/Conversation`)
				) {
					// Logout in case of 401 except the 401 result from Breeze and Blume Chat API
					logWarn(
						'responseHandler::Warn:',
						`Response from ${response?.request.responseURL} is ${response?.status}, logging out the user.`
					);
					await resetAndLogOut();
				} else if (_.inRange(response?.status, 400, 500)) {
					console.warn(`Client error: ${response?.data}`);
					return response;
				} else if (response?.status > 500) {
					console.error(`Server error: ${response?.data}`);
					return response;
				}
			}
		},
		[resetAndLogOut]
	);

	const addRequestInterceptors = useCallback(() => {
		if (requestInterceptor) {
			axios.interceptors.request.eject(requestInterceptor);
		}
		setRequestInterceptor(axios.interceptors.request.use(requestHandler));
	}, [requestHandler]);

	const addResponseInterceptors = useCallback(() => {
		if (responseInterceptor) {
			axios.interceptors.response.eject(responseInterceptor);
		}
		setResponseInterceptor(axios.interceptors.response.use(responseHandler));
	}, [responseHandler]);
	/**
	 * End request and response interceptors for axios
	 */

	/**
	 * Logout From all tabs
	 */
	const channel = useMemo(() => {
		try {
			return new BroadcastChannel('azureLogout');
		} catch (error) {
			return null;
		}
	}, []);

	useEffect(() => {
		channel?.addEventListener('message', event => {
			if (event.data.origin !== window.location.origin) {
				return;
			}

			// need to make sure single sign out from the same clientID
			if (event.data.clientID !== __config.auth.omegaai.msal.client_id) {
				return;
			}

			if (event.data.message === 'logoutAzure') {
				logWarn('UserAuthProvider::Warn:', 'Received logout message from Azure AD, logging out the user.');
				resetAndLogOut();
			}
		});
		return () => {
			channel?.close();
		};
	}, []);

	// Shut down connection when browser is refreshed or closed
	useEffect(() => {
		const handleBeforeUnload = () => {
			shutDownTwilioClient();
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [twilioClientRef.current]);

	const postLogOutMessage = () => {
		try {
			channel?.postMessage({
				message: 'logoutAzure',
				origin: window.location.origin,
				clientID: __config.auth.omegaai.msal.client_id,
			});
			channel?.close();
		} catch (error) {
			console.error(error);
		}
	};

	/**
	 * End of logout From all tabs
	 */

	const onLanguageChanged = data => {
		i18n.changeLanguage(data);
		if (data !== selectedLanguage) {
			setSelectedLanguage(data);
		}
	};

	const fetchPractitionerInfo = id => {
		const fileUrl = `${__config.data_sources.fhir}/Practitioner/Signature/${id}`;
		axios.get(fileUrl, { responseType: 'arraybuffer' }).then(response => {
			if (response && response.status != 404) {
				const { Buffer } = require('buffer');
				setSignatureData(`data:image/png;base64,${Buffer.from(response.data).toString('base64')}`);
			}
		});
	};

	return (
		<MsalProvider instance={msalInstance}>
			<UserAuthContext.Provider
				value={{
					loggedInUser: currentLoggedInUser,
					sessionId,
					accessToken,
					authorized: !!fullyAuthorized,
					loggedIn,
					loggingIn,
					userInfo,
					lastError,
					userRoleForOrganizations,
					setUserRoleForOrganizations,
					globalPermissionsForLoggedUser,
					setGlobalPermissionsForLoggedUser,
					hasLoadedUserRoleForOrganizationPermissions,
					setLoggingIn: value => {
						loggingInRef.current = !!value;
						setLoggingIn(!!value);
					},
					getGuiRoleByOrganizationAndResource,
					getGuiRole,
					hasPermissionByOrganization,
					isEmailVerified,
					isPhoneVerified,
					setIsEmailVerified,
					setIsPhoneVerified,
					isRecordFound,
					setIsRecordFound,
					isSentOTP,
					setIsSentOTP,
					userIdP,
					patientMapping,
					setPatientMapping,
					contacts,
					testIdpUserAuthenticated,
					resetAndLogOut,
					updateLoggedInUser,
					getUserFullName,
					setContacts,
					marketplaceUserId,
					userInOmegaAI,
					setNotificationReceived,
					notificationReceived,
					shouldUpdateNotification,
					blumeUserSettings,
					setBlumeUserSettings,
					displaySettingsRef,
					displaySettings,
					setDisplaySettings,
					client, //  Twilio Client
					isClientInitialized,
					supportClient, //  Twilio Client
					isSupportClientInitialized,
					blumeClient, //  Twilio Client
					isBlumeClientInitialized,
					chatMsgReceived,
					setChatMsgReceived,
					requestQueueReceived,
					updateAccessToken,
					languages,
					selectedLanguage,
					onLanguageChanged,
					ABHAHealthID,
					setABHAHealthID,
					profiles,
					setProfiles,
					signature: signatureData,
					updateSignature: fetchPractitionerInfo,
					appointmentVisibleHours,
					setAppointmentVisibleHours,
					lastOrderOrganization,
					setLastOrderOrganization,
					updateProfiles,
					syncReloadBlume,
					setSyncReloadBlume,
					twilioConnectionStatus,
					twilioErrorMessage,
					twilioSyncClient: twilioClientRef.current,
					userPractitionerRolesPerOrganization,
					setCurrentLoggedInUser,
					setUserInfo,
					handleSyncMessage,
					practitionerRole,
					setPractitionerRole,
					userAssociatedWithOnlyOneOrg,
					getUserTypeByOrganization,
					hasPermissionByOrganizationAsync,
					getGuiPrivilegeByOrganizationAndResourceAsync,
					omegaAIAffiliateRights,
					userPrivilegesForOrganizations,
					updateUserPrivileges,
				}}
			>
				{children}
			</UserAuthContext.Provider>
		</MsalProvider>
	);
};

export const useAuth = () => useContext(UserAuthContext);
export default UserAuthProvider;
