import React, { createContext, useContext, useEffect, useState } from 'react';
import { annotation, ToolGroupManager } from '@cornerstonejs/tools';
import SpineLabelingTool from '../cornerstoneTools/SpineLabelingTool';
import { spineLabelingItems } from '../components/SpineLabelingMenu/SpineLabelingMenuData';

const { getAnnotationManager } = annotation.state;

const ImageViewerSpineLabelingContext = createContext({});

const ImageViewerSpineLabelingContextProvider = ({ children }) => {
	const [isShownSpineLabelingMenu, setIsShownSpineLabelingMenu] = useState(false);

	const [spineLabelingSettings, setSpineLabelingSettings] = useState({
		currentMarker: spineLabelingItems[0],
		ascending: true,
		loop: false,
	});

	const setLatestMarker = imageId => {
		if (!imageId) {
			return;
		}

		const spineLabelingMeasurements = [];

		const annotationManager = getAnnotationManager();

		if (annotationManager.annotations) {
			const keys = Object.keys(annotationManager.annotations);

			keys.forEach(key => {
				const spineLabelingAnnotations = annotationManager.annotations[key]?.[
					SpineLabelingTool.toolName
				]?.filter(a => a.metadata.referencedImageId.split('/instances')[0] === imageId.split('/instances')[0]);

				if (spineLabelingAnnotations?.length) {
					spineLabelingMeasurements.push(...spineLabelingAnnotations);
				}
			});
		}

		let currentMarker = spineLabelingItems[0];

		spineLabelingItems.forEach((spineLabelingItem, index) => {
			const spineLabelingMarker = spineLabelingMeasurements.find(
				spineLabelingMeasurement => spineLabelingMeasurement.data.label === spineLabelingItem
			);

			if (spineLabelingMarker?.data?.handles?.points?.length === 1) {
				currentMarker = spineLabelingItems[index];
			}

			if (spineLabelingMarker?.data?.handles?.points?.length > 1) {
				currentMarker = spineLabelingItems[index + 1] || spineLabelingItems[0];
			}
		});

		setSpineLabelingSettings(prevState => ({
			...prevState,
			currentMarker,
		}));
	};

	useEffect(() => {
		const toolGroups = ToolGroupManager.getAllToolGroups();

		toolGroups?.forEach(toolGroup => {
			if (!toolGroup) {
				return;
			}

			const spineLabelingTool = toolGroup?._toolInstances?.[SpineLabelingTool.toolName];

			if (!spineLabelingTool) {
				return;
			}

			spineLabelingTool.setConfiguration(spineLabelingSettings);
		});
	}, [spineLabelingSettings]);

	return (
		<ImageViewerSpineLabelingContext.Provider
			value={{
				isShownSpineLabelingMenu,
				setIsShownSpineLabelingMenu,
				spineLabelingSettings,
				setSpineLabelingSettings,
				setLatestMarker,
			}}
		>
			{children}
		</ImageViewerSpineLabelingContext.Provider>
	);
};

const useImageViewerSpineLabelingContext = () => useContext(ImageViewerSpineLabelingContext);

export { useImageViewerSpineLabelingContext, ImageViewerSpineLabelingContextProvider, ImageViewerSpineLabelingContext };
