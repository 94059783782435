import { Box } from '@mui/system';
import React, { useState, useEffect, useRef } from 'react';
import DvrOutlinedIcon from '@mui/icons-material/DvrOutlined';
import { MenuItem, Select, Typography } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { useCrmContext } from '../../../../../context/CRMContext';
import { crmSearchScopes, useCRMDataLoader, useAuth } from '@worklist-2/core/src';

function IdeaProgressBar({
	objective,
	keyResultIndex,
	ideaIndex,
	percentage,
	status,
	color,
	team,
	setObjective,
	onSetIdeaVariable,
	key,
}) {
	const [percentages, setPercentages] = useState([]);
	const [projectCategories] = useState([
		'Aurora',
		'Design',
		'Fabulous',
		'Galaxy',
		'Lego',
		'Maven',
		'Meta',
		'Phoenix',
		'Platform',
		'Proact',
		'Sprinter',
		'Won',
	]);
	const [projectStatusList] = useState(['In Dev', 'Research', 'Planning', 'Design', 'Released', 'Delayed']);
	const [projectCategory, setProjectCategory] = useState(team || 'Team');
	const [projectStatus, setProjectStatus] = useState(status || 'Status');
	const { setSelectedObjective } = useCrmContext();
	const okrDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.okrApi,
	});
	const { loggedInUser } = useAuth();

	const progressBarRef = useRef();
	const userRef = useRef();
	const updateData = async (obj, objectiveId) => {
		const refinedObjective = {
			...obj,
			KeyResults: obj.KeyResults.map(keyResult => ({
				...keyResult,
				hovered: undefined,
				expanded: undefined,
			})),
			expanded: undefined,
			hovered: undefined,
		};

		await okrDataLoader.update(objectiveId, refinedObjective);
	};

	const handleTeamChange = async e => {
		e.stopPropagation();
		setProjectCategory(e.target.value);

		objective.KeyResults[keyResultIndex].Ideas[ideaIndex].Team = e.target.value;

		setObjective(objective);

		setSelectedObjective(objective);

		updateData(objective, objective.id);
	};

	const handleStatusChange = async e => {
		e.stopPropagation();
		setProjectStatus(e.target.value);

		objective.KeyResults[keyResultIndex].Ideas[ideaIndex].ProgressStatus = e.target.value;

		setObjective(objective);

		setSelectedObjective(objective);

		updateData(objective, objective.id);
	};

	const handlePercentChange = async e => {
		e.stopPropagation();
		objective.KeyResults[keyResultIndex].Ideas[ideaIndex].Progress = e.target.value;
		setObjective(objective);
		setSelectedObjective(objective);

		onSetIdeaVariable(objective, keyResultIndex, ideaIndex, 'Progress', e.target.value);
		updateData(objective, objective.id);
	};

	useEffect(() => {
		const arr = [];
		for (let i = 0; i <= 10; i++) {
			arr.push(i * 10);
		}
		setPercentages(arr);
	}, []);

	const [statusLeftPosition, setStatusLeftPosition] = useState(210);

	useEffect(() => {
		setProjectStatus(status);
		setProjectCategory(team);
	}, [status, team]);

	useEffect(() => {
		if (progressBarRef?.current && userRef?.current) {
			const progressBarWidth = progressBarRef?.current?.clientWidth;
			const userWidth = userRef?.current?.clientWidth;
			const userPercent = (userWidth / progressBarWidth) * 100;
			if (percentage < userPercent) {
				setStatusLeftPosition(userPercent);
			} else {
				setStatusLeftPosition(percentage);
			}
		}
	}, [progressBarRef?.current, userRef?.current, percentage]);

	return (
		<Box
			key={key}
			ref={progressBarRef}
			sx={{
				width: '100%',
				height: '32px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				overflow: 'hidden',
				background: 'rgba(77, 121, 234, 0.1);',
				borderRadius: '20px',
				marginBottom: '12px',
				position: 'relative',
			}}
		>
			<div
				style={{
					width: `${percentage}%`,
					transition: '3s',
					height: '100%',
					background: color,
					display: 'flex',
					alignItems: 'center',
					color: '#fff',
					justifyContent: 'space-between',
				}}
			>
				<Select
					ref={userRef}
					IconComponent=""
					class="notchedOutline"
					disabled={!loggedInUser?.permission?.OKR?.Edit}
					name="quarter"
					renderValue={value => (
						<div
							style={{
								color: percentage === 0 ? color : '#fff',
								fontSize: '12px',
								padding: '5px',
								borderRadius: '20px',
								background: 'transparent',
								display: 'flex',
								marginTop: '5px',
								marginBottom: '5px',
								height: '26px',
							}}
						>
							<GroupIcon />
							<Typography
								component="h3"
								sx={{
									fontFamily: 'Inter',
									fontWeight: 600,
									fontSize: '14px',
									color: percentage === 0 ? color : '#fff',
									pl: 1,
								}}
							>
								{value || 'Team'}
							</Typography>
						</div>
					)}
					sx={{
						'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
							border: 'none',
						},
						'& .MuiOutlinedInput-notchedOutline': {
							border: 'none',
						},
						'& .MuiInputBase-input.Mui-disabled': {
							WebkitTextFillColor: '#fff !important',
						},
						padding: '0px',
						position: 'relative',
						background: 'transparent',
						color: '#fff !important', // percentage > 90 ? "#fff" : color
					}}
					value={projectCategory}
					onChange={handleTeamChange}
				>
					{projectCategories.map((projectCat, index) => (
						<MenuItem key={index} value={projectCat}>
							<ProjectCategory color={color} text={projectCat} />
						</MenuItem>
					))}
				</Select>
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					position: 'absolute',
					left: `${statusLeftPosition}%`,
					transition: '3s',
				}}
			>
				<Select
					IconComponent=""
					class="notchedOutline"
					disabled={!loggedInUser?.permission?.OKR?.Edit}
					name="quarter"
					renderValue={value => (
						<div
							style={{
								color: percentage >= 90 ? '#fff' : color,
								fontWeight: 'bold',
								transition: '3s',
								fontSize: '12px',
								padding: '5px',
								borderRadius: '20px',
								background: 'transparent',
								marginTop: '5px',
								marginBottom: '5px',
								// background: percentage >= 90 ? '#204CBD' : '#CDDAF9',
								display: 'flex',
								height: '26px',
							}}
						>
							<ProgressStatus
								status={projectStatusList.includes(value) ? value : 'Status'}
								text={projectStatusList.includes(value) ? value : 'Status'}
							/>
						</div>
					)}
					sx={{
						'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
							border: 'none',
						},
						'& .MuiOutlinedInput-notchedOutline': {
							border: 'none',
						},
						'& .MuiInputBase-input.Mui-disabled': {
							WebkitTextFillColor: percentage >= 90 ? '#fff' : color,
						},
						padding: '0px',
						position: 'relative',
						transition: '3s',
						right: percentage >= 90 ? '200px' : '0px',
						color: percentage >= 90 ? '#fff' : color,
					}}
					value={projectStatus}
					onChange={handleStatusChange}
				>
					{projectStatusList.map((item, index) => (
						<MenuItem key={index} value={item}>
							<ProgressStatus status={item} text={item} />
						</MenuItem>
					))}
				</Select>
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					width: `${percentage}%)`,
					position: 'relative',
				}}
			>
				<Select
					IconComponent=""
					class="notchedOutline"
					disabled={!loggedInUser?.permission?.OKR?.Edit}
					name="quarter"
					renderValue={value => (
						<div
							style={{
								color: percentage >= 100 ? '#fff' : color,
								fontWeight: 'bold',
								transition: '3s',
								fontSize: '12px',
								padding: '5px',
								marginRight: '-22px',
								borderRadius: '20px',
								background: 'transparent',
								// background: percentage >= 100 ? '#204CBD' : '#CDDAF9',
								display: 'flex',
							}}
						>
							{percentage ? `${value}%` : 'Progress'}
						</div>
					)}
					sx={{
						'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
							border: 'none',
						},
						'& .MuiOutlinedInput-notchedOutline': {
							border: 'none',
						},
						'& .MuiInputBase-input.Mui-disabled': {
							WebkitTextFillColor: percentage >= 100 ? '#fff' : color,
						},
						padding: '0px',
						position: 'absolute',
						right: 0,
					}}
					value={percentage || '0'}
					onChange={handlePercentChange}
				>
					{percentages.map((item, index) => (
						<MenuItem key={index} value={item}>
							<span
								style={{
									fontSize: '14px',
									color,
								}}
							>
								{item}%
							</span>
						</MenuItem>
					))}
				</Select>
			</div>
		</Box>
	);
}

const ProgressStatus = ({ text, status, color }) => (
	<div
		style={{
			display: 'flex',
			alignItems: 'center',
			height: '26px',
		}}
	>
		{status === 'indev' ? (
			<DvrOutlinedIcon
				sx={{
					color,
					fontSize: '24px',
					marginRight: '5px',
				}}
			/>
		) : status === 'research' ? (
			<PsychologyIcon
				sx={{
					color,
					fontSize: '24px',
					marginRight: '5px',
				}}
			/>
		) : (
			<ColorLensIcon
				sx={{
					color,
					fontSize: '24px',
					marginRight: '5px',
				}}
			/>
		)}
		<Typography
			component="h3"
			sx={{
				height: '26px',
				fontFamily: 'Inter',
				fontWeight: 600,
				fontSize: '14px',
				color,
			}}
		>
			{text}
		</Typography>
	</div>
);

const ProjectCategory = ({ text, color }) => (
	<div
		style={{
			display: 'flex',
			alignItems: 'flex-start',
			overflow: 'hidden',
		}}
	>
		<GroupIcon
			sx={{
				color: '#FFFFFF',
				fontSize: '24px',
				marginRight: '1px',
			}}
		/>
		<Typography
			component="h3"
			sx={{
				fontFamily: 'Inter',
				fontWeight: 600,
				fontSize: '14px',
				color,
			}}
		>
			{text}
		</Typography>
	</div>
);
export default IdeaProgressBar;
