import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import mapBoxImage from '@worklist-2/ui/src/assets/img/mapBox.png';

const MapField = ({ address, expanded }) => (
	<Box
		sx={{
			'& > img': {
				width: expanded ? '231px' : '200px',
				height: '148px',
				borderRadius: '10px',
				marginTop: '26px',
			},
		}}
	>
		<img alt={address} src={mapBoxImage} />
	</Box>
);

MapField.propTypes = {
	address: PropTypes.string,
};

export default MapField;
