import axios from 'axios';

const getForm = async ({ __config, patientId }) => {
	const response = await axios.get(`${__config.data_sources.blume}Form?PatientId=${patientId}`);

	if (!response?.status?.toString()?.startsWith(2)) {
		return [];
	}

	return response.data;
};

export default getForm;
