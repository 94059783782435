// @flow

// core
import React, { type Node } from 'react';

// libs
import { Box } from '@mui/material';
import { type ISkeletonProps, LoaderSkeleton } from './LoaderSkeleton';

export const Orders = ({ children, isLoading }: ISkeletonProps): Node =>
	isLoading ? (
		<>
			<LoaderSkeleton sx={SX.orderHeaderTitleSkeleton} />
			<Box sx={SX.skeletonWrapper}>
				<Box sx={SX.orderSkeletonWrapper}>
					<LoaderSkeleton sx={SX.orderHeaderSkeleton} />
					<LoaderSkeleton sx={SX.orderSkeleton} />
				</Box>

				<Box sx={SX.orderSkeletonWrapper}>
					<LoaderSkeleton sx={SX.orderHeaderSkeleton} />
					<LoaderSkeleton sx={SX.orderSkeleton} />
				</Box>
			</Box>
		</>
	) : (
		children
	);

const SX = {
	skeletonWrapper: {
		width: 'calc(100% - 20px)',
		rowGap: '20px',
		display: 'grid',
		flexDirection: 'column',
		marginLeft: '20px',
	},
	orderSkeletonWrapper: {
		display: 'grid',
	},
	orderHeaderSkeleton: {
		borderRadius: '10px 10px 0 0',
		width: '100%',
		height: '46px',
		marginBottom: '5px',
	},
	orderSkeleton: {
		borderRadius: '0 0 10px 10px',
		width: '100%',
		height: '287px',
		paddingBottom: '15px',
	},
	orderHeaderTitleSkeleton: {
		borderRadius: '10px',
		width: '101px',
		height: '24px',
	},
};
