import React from 'react';
import Avatar from '@mui/material/Avatar';

const ICONS = {};

/**
 * Return the matching SVG Icon as a React Component.
 * Results in an inlined SVG Element. If there's no match,
 * return `null`
 */
export default function getIcon(key, props) {
	let { alt, height, width, url } = props;
	alt = alt || 'User';
	if (height && !width) {
		width = height;
	} else if (width && !height) {
		height = width;
	}

	// avatar will default to use alt-test or generic profile icon
	return <Avatar alt={alt} src={ICONS[key] || url || ''} width={width} height={height} />;
}

export { getIcon, ICONS };
