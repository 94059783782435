import { MenuItem, Box, TextField, Stack, Autocomplete } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircleOutlined';
import Avatar from '../../../../../../ui/src/assets/img/crmUserImg.png';
import styled from '@emotion/styled';
import React from 'react';

export const MenuItemOnTrack = styled(MenuItem)({
	justifyContent: 'center',
	fontFamily: 'Inter',
	fontStyle: 'normal',
	fontWeight: '500',
	fontSize: '12px',
	lineHeight: '15px',
	background: 'rgba(47, 214, 173, 0.1)',
	borderRadius: '17px',
	color: 'rgba(0, 154, 116, 0.6)',
	marginLeft: '10px',
	width: '82px',
	'&.Mui-selected': {
		backgroundColor: 'rgba(47, 214, 173, 0.1) !important',
	},
	'&:hover': {
		backgroundColor: 'rgba(196, 196, 196, 0.2) !important',
	},
});

export const MenuItemOffTrack = styled(MenuItem)({
	justifyContent: 'center',
	fontFamily: 'Inter',
	fontStyle: 'normal',
	fontWeight: '500',
	fontSize: '12px',
	lineHeight: '15px',
	background: 'rgba(239, 93, 93, 0.2)',
	borderRadius: '17px',
	color: '#EF5D5D',
	marginTop: '10px',
	marginLeft: '10px',
	width: '82px',
	'&.Mui-selected': {
		backgroundColor: 'rgba(239, 93, 93, 0.2) !important',
	},
	'&:hover': {
		backgroundColor: 'rgba(196, 196, 196, 0.2) !important',
	},
});

export const MenuItemDelayed = styled(MenuItem)({
	justifyContent: 'center',
	fontFamily: 'Inter',
	fontStyle: 'normal',
	fontWeight: '500',
	fontSize: '12px',
	lineHeight: '15px',
	background: 'rgba(238, 186, 2, 0.2)',
	borderRadius: '17px',
	color: '#EEBA02',
	marginTop: '10px',
	marginLeft: '10px',
	width: '82px',
	'&.Mui-selected': {
		backgroundColor: 'rgba(238, 186, 2, 0.2) !important',
	},
	'&:hover': {
		backgroundColor: 'rgba(196, 196, 196, 0.2) !important',
	},
});

export const MenuItemAcrhived = styled(MenuItem)({
	justifyContent: 'center',
	fontFamily: 'Inter',
	fontStyle: 'normal',
	fontWeight: '500',
	fontSize: '12px',
	lineHeight: '15px',
	background: 'rgba(77, 121, 234, 0.2)',
	borderRadius: '17px',
	color: '#4D79EA',
	marginTop: '10px',
	marginLeft: '10px',
	width: '82px',
	'&.Mui-selected': {
		backgroundColor: 'rgba(77, 121, 234, 0.2) !important',
	},
	'&:hover': {
		backgroundColor: 'rgba(196, 196, 196, 0.2) !important',
	},
});

export const BoxObjectiveOKR = ({ children }) => {
	return (
		<Box
			sx={{
				'&:hover': {
					'.menu-btn': {
						display: 'block',
					},
				},
				'.menu-btn': {
					position: 'absolute',
					top: '-17px',
					left: '40%',
					display: 'none',

					'.icon-btn': {
						background: '#FFFFFF',
						border: '1px solid #FFFFFF',
						boxShadow: '-2px 4px 5px  rgba(0, 0, 0, 0.05)',
						padding: '6px',
					},
				},
				minWidth: '80%',
			}}
		>
			{children}
		</Box>
	);
};

export const TextFieldObjectiveOKR = props => {
	return (
		<TextField
			{...props}
			sx={{
				'& .MuiOutlinedInput-root': {
					'& fieldset': {
						borderRadius: '8px',
						border: '1px solid rgba(196, 196, 196, 0.15)',
						background: 'rgba(196, 196, 196, 0.15)',
					},
					'&:hover fieldset': {
						border: '1px solid rgba(196, 196, 196, 0.15)',
					},
					'&.Mui-focused fieldset': {
						border: '1px solid rgba(196, 196, 196, 0.15)',
					},
					marginLeft: '10px',
				},
				'& .MuiOutlinedInput-input': {
					padding: '10px 9px 9px',
					fontSize: '14px',
				},
				minWidth: '90%',
			}}
		/>
	);
};

export const WrapperStack = props => {
	return (
		<Stack
			{...props}
			direction="column"
			sx={{
				position: 'absolute',
				width: '208px',
				background: '#FFFFFF',
				filter: 'drop-shadow(0px 0px 20px rgba(33, 88, 119, 0.21))',
				borderRadius: '3px',
				border: 'none',
				zIndex: 60,
				alignContent: 'center',
				top: '6px',
				right: '-131px',
			}}
		/>
	);
};

export const AutocompleteAssigner = props => {
	return (
		<Autocomplete
			{...props}
			sx={{
				'& .MuiOutlinedInput-root': {
					border: 'none',
					fontSize: '14px',
				},
				'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
					border: '1px solid #4D79EA',
					borderRadius: '10px',
				},
				'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
					border: '1px solid #4D79EA !important',
					borderRadius: '10px',
				},
			}}
			ListboxProps={{
				style: {
					paddingTop: '5px',
					background: '#FFFFFF',
					maxHeight: '200px',
					width: '206px',
					position: 'absolute',
					top: '-10px',
					fontSize: '14px',
					border: '1px solid #4D79EA',
					borderTopStyle: 'none',
					borderBottomLeftRadius: '10px',
					borderBottomRightRadius: '10px',
				},
			}}
			renderInput={params => (
				<TextField
					autoFocus
					{...params}
					placeholder="Unassigned"
					variant="outlined"
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<>
								<AccountCircle fontSize="large" sx={{ marginLeft: '8px' }} />
								{params.InputProps.startAdornment}
							</>
						),
					}}
				/>
			)}
			renderOption={(properties, option) => {
				return (
					<li {...properties}>
						<>
							<Box
								sx={{
									borderRadius: '50%',
									width: '30px',
									height: '30px',
									backgroundSize: 'cover !important',
									background: `url(${Avatar})`,
								}}
							/>
							<Box id={properties.id + '-box'} sx={{ ml: 1 }}>
								{option}
							</Box>
						</>
					</li>
				);
			}}
		/>
	);
};
