const WorkflowAutomationSchema = {
	resourceType: '__string__',
	id: '__string__',
	name: '__string__',
	workflow: '__string__',
	displaysetting: '__string__',
	lastUpdateUser: '__string__',
	guiid: '__string__',
	active: '__boolean__',
	enable: '__boolean__',
	managingOrganization: '__Reference(Organization)__',
	extension: ['__Extension__'],
};
export default WorkflowAutomationSchema;
