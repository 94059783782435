/* Generated automagically by FhirStarter - DO NOT EDIT */
const medicationSchema = {
	resourceType: 'Medication',
	identifier: ['__Identifier__'],
	code: '__CodeableConcept__',
	status: '__code__',
	manufacturer: '__Reference(Organization)__',
	form: '__CodeableConcept__',
	amount: '__Ratio__',
	ingredient: [
		{
			itemCodeableConcept: '__CodeableConcept__',
			itemReference: '__Reference(Substance|Medication)__',
			isActive: '__boolean__',
			strength: '__Ratio__',
		},
	],
	batch: {
		lotNumber: '__string__',
		expirationDate: '__dateTime__',
	},
};
export default medicationSchema;
