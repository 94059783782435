import axios from 'axios';

const shareDICOM = async ({ __config, internalManagingOrgId, internalStudyId, internalPatientID, data }) => {
	const response = await axios.post(
		`${__config.data_sources.blume}Study/dicomshare?internalManagingOrgId=${internalManagingOrgId}&InternalStudyId=${internalStudyId}&internalPatientID=${internalPatientID}`,
		data
	);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default shareDICOM;
