import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
	DOCUMENT_VIEWER,
	DRAG_N_DROP_DISABLING_RULES,
	IMPORT,
	REPARENTING,
	VIEWPORT,
} from '../../consts/dragDropRules';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import Fade from '@mui/material/Fade';
import { useImageViewerSeriesDragDropContext } from '../../contexts/ImageViewerSeriesDragDropContext';
import { useImageViewerLayoutContext } from '../../contexts/ImageViewerLayoutContext';
import { getFiles } from '../../features/Import/utils/importUtils';
import { useImageViewerPermissionsContext } from '../../contexts/ImageViewerPermissionsContext';

const useStyles = makeStyles()(() => ({
	droppable: {
		position: 'relative',
		height: 'inherit',
		width: 'inherit',
		'&:after': {
			content: '""',
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			opacity: 0,
			transition: '.2s opacity',
			boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
			borderRadius: '10px',
			zIndex: 1,
			pointerEvents: 'none',
		},
	},
	disabled: {
		'&:after': {
			background: 'rgba(57,57,57, 0.7)',
			border: '1px dashed rgba(255, 255, 255, 0.6)',
			opacity: 1,
		},
	},
	dragOver: {
		'&:after': {
			opacity: 1,
		},
	},
	default: {
		'&:after': {
			border: '1px dashed #42A5F5',
			background: 'linear-gradient(0deg, rgba(66, 165, 245, 0.1), rgba(66, 165, 245, 0.1)), transparent',
		},
	},
	parent: {
		'&:after': {
			background: 'rgba(57,57,57, 0.7)',
			border: '1px dashed rgba(255, 255, 255, 0.6)',
		},
	},
}));

export const Droppable = ({ data, children, style }) => {
	const { classes, cx } = useStyles();
	const { draggingElementData } = useImageViewerLayoutContext();
	const { onDragComplete } = useImageViewerSeriesDragDropContext();
	const { isViewOnly, imageViewerPermission } = useImageViewerPermissionsContext();
	const [disabled, setDisabled] = useState(false);
	const [parent, setParent] = useState(false);
	const [isOver, setIsOver] = useState(false);
	const enterTarget = useRef(null);
	const disablingRulesFromDroppable = DRAG_N_DROP_DISABLING_RULES[data?.type];
	const disablingRulesFromDragging = DRAG_N_DROP_DISABLING_RULES[draggingElementData?.type];

	useEffect(() => {
		if (draggingElementData) {
			setDisabled(
				(data?.type === REPARENTING && !data?.studyUid) ||
					(disablingRulesFromDroppable &&
						disablingRulesFromDroppable(
							draggingElementData?.selectedItems?.length,
							isViewOnly,
							imageViewerPermission
						)) ||
					(disablingRulesFromDragging && disablingRulesFromDragging(data?.type))
			);
		} else {
			setIsOver(false);
			setDisabled(false);
			setParent(false);
		}
	}, [draggingElementData]);

	const dragOver = e => {
		if (data.type === DOCUMENT_VIEWER) return;
		e.stopPropagation();
		e.preventDefault();
	};

	const dragLeave = e => {
		if (e.target === enterTarget.current) {
			setIsOver(false);
		}
		if (data.type === DOCUMENT_VIEWER) return;
		e.preventDefault();
	};

	const dragEnter = e => {
		e.preventDefault();
		enterTarget.current = e.target;
		setIsOver(true);
		setParent(data.type !== DOCUMENT_VIEWER && draggingElementData?.studyUid === data?.studyUid);
	};

	const drop = async e => {
		setIsOver(false);
		setDisabled(false);
		setParent(false);

		if (data.type === DOCUMENT_VIEWER) return;

		e.preventDefault();
		e.stopPropagation();

		const dt = e.dataTransfer;
		const items = dt?.items;
		const files = dt?.files;

		if (items?.length > 0 && files?.length > 0 && data.type !== VIEWPORT) {
			const filesOnly = await getFiles(items);
			const droppedFiles = filesOnly.flat(Infinity);
			onDragComplete({ ...data, type: IMPORT }, droppedFiles);
		} else {
			if (disabled || parent) return;
			try {
				const dtData = dt?.getData('text');
				const draggableData = JSON.parse(dtData);
				onDragComplete(data, draggableData);
			} catch (error) {}
		}
	};

	return (
		<>
			<input
				data-testid="input-file-upload"
				id="input-file-upload"
				multiple={false}
				style={{ display: 'none' }}
				type="file"
				onChange={drop}
			/>
			<div
				className={cx(
					classes.droppable,
					isOver && classes.dragOver,
					disabled ? classes.disabled : isOver && (parent ? classes.parent : classes.default)
				)}
				data-testid="droppable-area"
				style={style}
				onDragEnter={dragEnter}
				onDragLeave={dragLeave}
				onDragOver={dragOver}
				onDrop={drop}
			>
				<Fade in={disabled && !parent}>
					<NotInterestedIcon
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate3d(-50%, -50%, 0)',
							fontSize: 60,
							color: 'rgba(255,255,255, 0.2)',
							zIndex: 2,
						}}
					/>
				</Fade>
				{children}
			</div>
		</>
	);
};
