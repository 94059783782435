// core
import React, { useMemo, useRef } from 'react';
// mui
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
// libraries
import PropTypes from 'prop-types';
import { Controlled as CodeMirror } from 'react-codemirror2';
import AutoSizer from 'react-virtualized-auto-sizer';
import { JSHINT } from 'jshint';
// partials
import HistoryPopover from './HistoryPopover';
import Tools, { PropTypesToolsList } from './Tools';
import { useTranslation } from 'react-i18next';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/javascript-hint';

// styles & misc
const EDITOR_THEME_NAME = 'material-darker';
require('codemirror/lib/codemirror.css');

require(`codemirror/theme/${EDITOR_THEME_NAME}.css`);
require('codemirror/mode/javascript/javascript.js');

window.JSHINT = JSHINT;

const ScriptEditor = ({
	code,
	historyList,
	isEditModeOn = false,
	isHistoryLoading = false,
	isLoading = false,
	subHeader,
	title,
	toolsList,
	onCodeChange,
	onHistoryItemClick,
}) => {
	const editorRef = useRef(null);
	const { t } = useTranslation('organization');

	subHeader = subHeader
		? subHeader.includes('Draft')
			? subHeader.replace('Draft', t('Draft'))
			: t(subHeader)
		: subHeader;

	const editorOptions = useMemo(
		() => ({
			lineNumbers: true,
			mode: 'javascript',
			theme: EDITOR_THEME_NAME,
			readOnly: !isEditModeOn,
			indentUnit: 4, // Use 4 spaces per indent
			indentWithTabs: false,

			// Chakara Core (the engine that powers the script editor) uses ES5.1
			lint: { ecmaVersion: 5.1 },
			gutters: ['CodeMirror-lint-markers'],
		}),
		[isEditModeOn]
	);

	return (
		<Layout>
			<Column
				endRender={
					<>
						<LoadingIndicator isLoading={isLoading} />
						<HistoryPopover
							data={historyList}
							isLoading={isHistoryLoading}
							onHistoryItemClick={onHistoryItemClick}
						/>
					</>
				}
				isEditModeOn={isEditModeOn}
				subtitle={subHeader}
				title={t(title)}
			>
				<AutoSizer style={{ height: '100%' }}>
					{({ height, width }) => (
						<div className="horizontal-scrollbar-fix" style={{ height, width, overflow: 'auto' }}>
							<CodeMirror
								ref={editorRef}
								options={editorOptions}
								value={code}
								onBeforeChange={onCodeChange}
							/>
						</div>
					)}
				</AutoSizer>
			</Column>

			<Column isToolsWrapper isEditModeOn={isEditModeOn} title={t('Tools')}>
				{isEditModeOn && <Tools editor={editorRef.current?.editor} toolsList={toolsList} />}
			</Column>
		</Layout>
	);
};

export default ScriptEditor;

//																			   //
// ============================ Partial components =========================== //
//																			   //

const Column = ({ children, endRender, isEditModeOn, isToolsWrapper = false, subtitle, title }) => (
	<InnerColumnWrapper isEditModeOn={isEditModeOn} isToolsWrapper={isToolsWrapper}>
		<ColumnHeader>
			<ColumnTitle variant="h3">{title}</ColumnTitle>
			{subtitle && <ColumnSubtitle variant="caption">{subtitle}</ColumnSubtitle>}
			{Boolean(endRender) && <div style={{ display: 'flex', marginLeft: 'auto' }}>{endRender}</div>}
		</ColumnHeader>
		<Box
			sx={{
				marginTop: '49px',
			}}
		>
			{children}
		</Box>
	</InnerColumnWrapper>
);

const LoadingIndicator = ({ isLoading }) =>
	isLoading ? (
		<CircularProgress
			size={18}
			sx={{
				color: 'inherit',
				padding: '3px',
				marginRight: '10px',
			}}
		/>
	) : null;

//																			   //
// ============================ Component proptypes ========================== //
//																			   //

Column.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.node]),
	endRender: PropTypes.element,
	isEditModeOn: PropTypes.bool,
	isToolsWrapper: PropTypes.bool,
	subtitle: PropTypes.string,
	title: PropTypes.string,
};

Column.defaultProps = {
	isToolsWrapper: false,
};

LoadingIndicator.propTypes = {
	isLoading: PropTypes.bool,
};

ScriptEditor.propTypes = {
	/**
	 * Code in the editor
	 */
	code: PropTypes.string,

	/**
	 * List of history
	 */
	historyList: PropTypes.array,

	/**
	 * Whether the editor is in edit mode
	 */
	isEditModeOn: PropTypes.bool,

	/**
	 * Indicates that the history is loading
	 */
	isHistoryLoading: PropTypes.bool,

	/**
	 * Indicates that code is loading or saving
	 */
	isLoading: PropTypes.bool,

	/**
	 * Displays text in the subheader
	 */
	subHeader: PropTypes.string,

	/**
	 * Title displayed in the code editor header
	 */
	title: PropTypes.string,

	/**
	 * Tools list
	 */
	toolsList: PropTypesToolsList,

	onCodeChange: PropTypes.func,
	onHistoryItemClick: PropTypes.func,
};

ScriptEditor.defaultProps = {
	isEditModeOn: false,
	isHistoryLoading: false,
	isLoading: false,
	title: 'Script Editor',
};

//																			   //
// ============================= Styled components =========================== //
//																			   //

const ColumnHeader = styled(Box)(({ theme }) => ({
	borderRadius: '10px',
	height: 50,
	display: 'flex',
	alignItems: 'center',
	border: '1px solid #4d4d4d',
	backgroundColor: theme.palette.rsSecondary.medium,
	position: 'absolute',
	top: '-1px',
	left: '-1px',
	width: 'calc(100% + 2px)',
	boxSizing: 'border-box',
	zIndex: 1,
	paddingLeft: theme.spacing(3),
	paddingRight: theme.spacing(3),
}));

const ColumnSubtitle = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	color: theme.palette.icon.primary,
	fontStyle: 'italic',
	margin: '0 40px 0 30px',
	fontWeight: '300',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
}));

const ColumnTitle = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	color: theme.palette.primary.contrastText,
	whiteSpace: 'nowrap',
}));

const InnerColumnWrapper = styled(Box)(({ isEditModeOn, isToolsWrapper, theme }) => ({
	backgroundColor: theme.palette.primary.main,
	borderRadius: '10px',
	border: '1px solid #4d4d4d',
	position: 'relative',
	...(isToolsWrapper
		? {
				width: 'min(380px, 35%)',
				maxWidth: !isEditModeOn ? 0 : '380px',
				borderWidth: !isEditModeOn ? 0 : 1,
				transition: 'max-width .3s ease, border-width .3s ease',
				overflow: 'hidden',

				'& > div:nth-of-type(2)': {
					overflow: 'hidden',
					padding: '10px',
					height: '100%',
					maxHeight: 'calc(100% - 69px)',
					display: 'flex',
					flexDirection: 'column',
				},
		  }
		: {
				flexGrow: 1,
				padding: '0 0 5px',
				marginRight: isEditModeOn ? '5px' : 0,

				'& > div:last-child': {
					height: 'calc(100% - 44px)',
				},
		  }),
}));

const Layout = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexGrow: 1,
	height: 'calc(100vh - 300px)',
	overflow: 'hidden',
	padding: theme.spacing(2.5),
}));
