import React, { useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import Button from '@mui/material/Button';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Skeleton from '@mui/material/Skeleton';

import { useAuth, useConfig } from '@worklist-2/core/src';

const buttonStyling = {
	height: '34px',
	borderRadius: '6px ',
	zIndex: 1,

	'& p': {
		fontWeight: '400',
		fontSize: '12px',
		lineHeight: '20px',
		letterSpacing: '0.25px',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textAlign: 'left',
		display: 'block',
	},

	'&:hover': {
		color: 'rsPrimary.hover',
	},
};

const AppVendorFavoriteButton = ({
	isLoading,
	vendorInfo,
	favoriteBtnLabel = 'Favorite',
	unfavoriteBtnLabel = 'Unfavorite',
}) => {
	const [isFavorite, setIsFavorite] = useState(vendorInfo?.isFavorite);
	const __config = useConfig();

	const { marketplaceUserId } = useAuth();

	return isLoading ? (
		<Skeleton data-testid="skeleton" height="34px" variant="rectangular" width="120px" />
	) : (
		<Button
			color="rsPrimary"
			startIcon={isFavorite ? <FavoriteBorder /> : <Favorite />}
			sx={buttonStyling}
			variant="contained"
			onClick={() => {
				const newState = !isFavorite;
				const url = `${__config.data_sources.marketplace}favorites/${marketplaceUserId}/vendors/${vendorInfo?.id}`;

				setIsFavorite(newState);

				if (newState) {
					axios.post(url);
				} else {
					axios.delete(url);
				}
			}}
		>
			<p>{isFavorite ? unfavoriteBtnLabel : favoriteBtnLabel}</p>
		</Button>
	);
};

AppVendorFavoriteButton.propTypes = {
	/**
	 * Vendor Info
	 */
	vendorInfo: PropTypes.object.isRequired,
};

export default AppVendorFavoriteButton;
