import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const AddContactForm = ({ handleSubmit, onSubmit, control, errors, message }) => {
	const { t } = useTranslation('drawer');
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box sx={{ height: '90px' }}>
				<Controller
					control={control}
					name="name"
					render={({ field }) => (
						<TextField
							{...field}
							fullWidth
							InputLabelProps={{ shrink: true }}
							error={!!errors.name}
							helperText={errors.name?.message || t('Required')}
							label={t('Name')}
							sx={{
								height: 56,
								'& .MuiFormHelperText-root': {
									marginLeft: '0px',
								},
							}}
							type="text"
						/>
					)}
				/>
			</Box>
			<Box sx={{ height: '90px' }}>
				<Controller
					control={control}
					name="email"
					render={({ field }) => (
						<TextField
							{...field}
							fullWidth
							InputLabelProps={{ shrink: true }}
							error={!!errors.email}
							helperText={errors.email?.message || t('Required')}
							inputProps={{ style: { textTransform: 'lowercase' } }}
							label={t('Email')}
							sx={{
								height: 56,
								'& .MuiFormHelperText-root': {
									marginLeft: '0px',
								},
							}}
							type="email"
						/>
					)}
				/>

				<div
					style={{
						paddingTop: '20px',
						paddingLeft: '10px',
						color: 'red',
						paddingBottom: '10px',
						visibility: message === true ? 'visible' : 'hidden',
					}}
				>
					{t('Contact with same Email Id already exists')}
				</div>
			</Box>
		</form>
	);
};

export default AddContactForm;
