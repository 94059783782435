import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import ColorPreview from '../ColorPreview/ColorPreview';
import { Box, Select, MenuItem, InputLabel, FormControl } from '@mui/material';

const selectStyles = {
	'& > .MuiSvgIcon-root': {
		right: '-1px',
		transform: 'scaleX(0.6)',
	},
	'& > .MuiSelect-select': {
		paddingLeft: '12px !important',
		paddingRight: '23px !important',
	},
};

const menuItemStyles = {
	opacity: 1,
	display: 'flex',
	alignItems: 'center',
	padding: '5px',
	pl: '4px',
};

const ColorPicker = ({ label, value, onChange, colors = [] }) => {
	const changeHandler = e => {
		onChange(e.target.value);
	};

	return (
		<FormControl>
			<InputLabel sx={{ background: '#272727', padding: '0 5px' }}>{label}</InputLabel>
			<Select
				MenuProps={{
					PaperProps: {
						sx: {
							border: '1px solid rgba(255,255,255,0.1)',
							mt: '5px',
						},
					},
				}}
				data-testid="color-picker-select"
				labelId="test-select-label"
				renderValue={val => <ColorPreview color={val} width={87} />}
				sx={selectStyles}
				value={value ?? '#ffffff'}
				variant="outlined"
				onChange={changeHandler}
			>
				{colors.map(c => (
					<MenuItem key={c} data-testid={`item-${c}`} sx={menuItemStyles} value={c}>
						{value === c ? (
							<CheckIcon sx={{ width: '17px', height: '14px', color: 'rsPrimary.main' }} />
						) : (
							<Box mr="17px" />
						)}
						<ColorPreview color={c} width={94} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

ColorPicker.propTypes = {
	/**
	 * Represents the label of input
	 */
	label: PropTypes.string,
	/**
	 * Represents the current color code
	 */
	value: PropTypes.string,
	/**
	 * Change value handler
	 */
	onChange: PropTypes.func,
	/**
	 * An array of colors for select
	 */
	colors: PropTypes.array,
};

export default ColorPicker;
