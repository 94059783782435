import LiveSupport from '@worklist-2/ui/src/components/Help/LiveSupport';
import HelpQuestions from '@worklist-2/ui/src/components/Help/Questions.json';
import SearchResult from '@worklist-2/ui/src/components/Help/SearchResult';
import SubmitQuestion from '@worklist-2/ui/src/components/Help/SubmitQuestion';
import SubmitQuestionResult from '@worklist-2/ui/src/components/Help/SubmitQuestionResult';
import HelpContent from '@worklist-2/ui/src/views/HelpView/HelpCenter/HelpPopOver/HelpContent';
import HelpPopOverMenu from '@worklist-2/ui/src/views/HelpView/HelpCenter/HelpPopOver/HelpPopOverMenu';
import ScreenShare from '@worklist-2/ui/src/views/HelpView/HelpCenter/ScreenShare';
import VideoPreview from '@worklist-2/ui/src/views/HelpView/HelpCenter/VideoPlayer/VideoPreview';
import { PictureModeVideoPlayer } from '@worklist-2/ui/src/views/HelpView/HelpCenter/WhatsNew/whatsNewStyles';
import HelpPopOverSearchResult from '@worklist-2/ui/src/views/HelpView/HelpCenter/HelpPopOver/HelpPopOverSearchResult';
import _ from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, Suspense } from 'react';
import { loadOmegaMarkdown } from '../../../../../markdown/omega-ai/help-center/categories/markdownLoader';
import { useAuth } from '@worklist-2/core/src';
import LiveChatContextProvider from '../LiveChatContext';

import * as Muicon from '@mui/icons-material';

export const HelpCenterContext = createContext();

const HelpCenterContextProvider = ({ PopOverComponent, children }) => {
	const [view, setView] = useState('faq');
	const [showScreenShare, toggleScreenShare] = useState(false);
	const [showPopover, togglePopover] = useState(false);
	const [showTip, toggleShowTip] = useState(false);
	const [popOverContent, setPopOverContent] = useState('helpContent');
	const [nowPlaying, setNowPlaying] = useState(null);
	const [searchQuestion, handleQuestionChange] = useState('');
	const [searchKey, setSearchKey] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	const [currentSideTab, setCurrentSideTab] = useState(null);
	const [currentPiPNav, setCurrentPiPNav] = useState(null);
	const [popOverNavHistory, setPopOverNavHistory] = useState([]);
	const [currentPipTabs, setCurrentPiPTabs] = useState([]);

	const [omegaAIHelpLinks, setOmegaAIHelpLinks] = useState([]);

	const { selectedLanguage } = useAuth();

	const HelpComponent = useMemo(() => {
		const abc = {
			support: () => LiveSupport,
			searchResult: () => SearchResult,
			questionInput: () => SubmitQuestion,
			questionSubmit: () => SubmitQuestionResult,
		};

		return abc[`${view}`];
	}, [view]);

	useEffect(() => {
		const omegaAIHelpLinksClone = _.cloneDeep(omegaAIHelp);
		if (omegaAIHelpLinksClone && omegaAIHelpLinksClone instanceof Array && omegaAIHelpLinksClone.length > 0) {
			for (const cat of omegaAIHelpLinksClone) {
				if (cat.path) {
					cat.path = `${selectedLanguage}/${cat.path}`;
				}
				if (cat.tabs && cat.tabs instanceof Array && cat.tabs.length > 0) {
					for (const tab of cat.tabs) {
						tab.path = `${selectedLanguage}/${tab.path}`;
					}
				}
				if (cat.subNavLink && cat.subNavLink instanceof Array && cat.subNavLink.length > 0) {
					for (const subNavLink of cat.subNavLink) {
						subNavLink.path = `${selectedLanguage}/${subNavLink.path}`;

						if (subNavLink.tabs && subNavLink.tabs instanceof Array && subNavLink.tabs.length > 0) {
							for (const tab of subNavLink.tabs) {
								tab.path = `${selectedLanguage}/${tab.path}`;
							}
						}
					}
				}
			}
		}
		setOmegaAIHelpLinks(omegaAIHelpLinksClone);
	}, [selectedLanguage]);

	const omegaAIHelp = useMemo(() => {
		try {
			const helpConfig = require('../../../../../markdown/omega-ai/help-center/categories/index.json');
			return helpConfig?.items || [];
		} catch (error) {
			console.error(error);
			return [];
		}
	}, []);

	const getOmegaAIHelpIcon = (variation, svg, props = {}) => {
		if (variation) {
			const IconName = Muicon[variation];
			return props => <IconName {...props} />;
		}
		if (svg) {
			const svgDir = require.context('!@svgr/webpack!../../../../ui/src/assets/icons/helpCenter');
			const SvgIcon = svgDir(`./${svg}.svg`).default;
			return props => <SvgIcon {...props} />;
		}
		return null;
	};

	// TODO: Complexity n^4
	const searchObject = useMemo(() => {
		const links = [];
		for (const cat in omegaAIHelpLinks) {
			if (omegaAIHelpLinks[cat].tabs) {
				for (const tab in omegaAIHelpLinks[cat].tabs) {
					const paths = omegaAIHelpLinks[cat].tabs[tab].path.split('/');
					links.push({
						cat: `${paths[0]}/${paths[1]}`,
						art: paths[2],
					});
				}
			}

			if (omegaAIHelpLinks[cat].subNavLink) {
				for (const subNav in omegaAIHelpLinks[cat].subNavLink) {
					for (const tab in omegaAIHelpLinks[cat].subNavLink[subNav].tabs) {
						const paths = omegaAIHelpLinks[cat].subNavLink[subNav].tabs[tab].path.split('/');
						links.push({
							cat: `${paths[0]}/${paths[1]}`,
							art: paths[2],
						});
					}
				}
			}
		}

		const indexes = links.reduce((group, link) => {
			const { cat } = link;
			group[cat] = group[cat] ?? [];
			group[cat].push(link.art);
			return group;
		}, {});

		const helpArticles = _.map(indexes, (subCategories, key) =>
			// dynamically load contents
			_.map(subCategories, cat => {
				try {
					const markdown = loadOmegaMarkdown(`${key}/${cat}`);
					return {
						title: markdown.textContent[0],
						articlePath: markdown.path,
						content: markdown.content,
						path: `Help/${markdown.path}`
							.replaceAll('/', ' > ')
							.split(/(?=[A-Z])/)
							.join(' ')
							.toLowerCase(),
						textContent: _.filter(markdown.textContent, content => typeof content === 'string').join(' | '),
					};
				} catch (error) {
					return {};
				}
			})
		);

		return _.flatMap(helpArticles);
	}, [omegaAIHelpLinks]);

	const PopOverContent = useMemo(() => {
		try {
			let temp = { component: null };
			// update history
			if (
				!popOverNavHistory.includes(popOverContent) &&
				popOverContent !== 'menu' &&
				popOverContent !== 'helpContent'
			) {
				setPopOverNavHistory([...popOverNavHistory, popOverContent]);
			}
			switch (popOverContent) {
				case 'menu':
					temp.component = HelpPopOverMenu;
					setCurrentPiPTabs([]);
					break;
				case 'searchResults':
					setCurrentPiPTabs([]);
					temp.component = HelpPopOverSearchResult;
					break;

				default:
					const markdown = _.find(searchObject, entry => entry.articlePath === popOverContent);
					temp = markdown ? { content: markdown?.content } : { component: HelpContent };
					break;
			}
			return temp?.component || temp;
		} catch (error) {
			console.error(error.message);
		}
	}, [popOverContent, showPopover, omegaAIHelpLinks]);

	const showingMenu = useMemo(() => popOverContent === 'menu', [popOverContent]);

	// handle showing help tip
	useEffect(() => {
		const shown = localStorage.getItem('help-popover-shown');
		if (showPopover) {
			toggleShowTip(!shown);
			setPopOverContent('helpContent');
			setCurrentPiPNav(null);
		}
		localStorage.setItem('help-popover-shown', 'toggled');
	}, [showPopover]);

	// handle rendering search results
	useEffect(() => {
		if (searchQuestion?.trim().length > 0) {
			setPopOverContent('searchResults');
		}
	}, [searchQuestion, searchResults]);

	const popOverHistoryBack = useCallback(() => {
		try {
			const history = popOverNavHistory;
			if (history.length > 1) {
				history.pop();
				const v = history[history.length - 1];
				if (!_.isNull(v)) {
					setPopOverContent(v);
					setPopOverNavHistory(history);
				}
			}
		} catch (error) {
			console.error(error);
		}
	}, [popOverNavHistory]);

	const value = {
		searchQuestion,
		handleQuestionChange,
		searchResults,
		setSearchResults,
		HelpComponent,
		setView,
		toggleScreenShare,
		togglePopover,
		showScreenShare,
		showTip,
		openMenu: v => setPopOverContent(v),
		dismissTip: () => toggleShowTip(false),
		PopOverContent,
		nowPlaying,
		setNowPlaying,
		Questions: HelpQuestions,
		searchKey,
		setSearchKey,
		showPopover,
		currentSideTab,
		setCurrentSideTab,
		popOverHistoryBack,
		currentPiPNav,
		setCurrentPiPNav,
		searchObject,
		currentPipTabs,
		setCurrentPiPTabs,
		popOverContent,
		showingMenu,
		omegaAIHelpLinks,
		getOmegaAIHelpIcon,
	};

	return (
		<HelpCenterContext.Provider value={value}>
			<LiveChatContextProvider>
				{children}
				{/* screenshare drawer */}
				<Suspense fallback={null}>
					<ScreenShare dismiss={() => toggleScreenShare(false)} show={showScreenShare} />
				</Suspense>
				{/* help center popover widget */}
				{showPopover && (
					<PopOverComponent
						Content={PopOverContent}
						dismiss={() => togglePopover(false)}
						dismissTip={() => toggleShowTip(false)}
						openMenu={v => setPopOverContent(v)}
						showTip={showTip}
						showingMenu={showingMenu}
					/>
				)}
				{/* picture mode video player */}
				{nowPlaying?.isPictureMode && (
					<PictureModeVideoPlayer>
						<VideoPreview ignorePicMode />
					</PictureModeVideoPlayer>
				)}
			</LiveChatContextProvider>
		</HelpCenterContext.Provider>
	);
};

export const useHelpCenter = () => {
	const helpCenterContext = useContext(HelpCenterContext);
	const {
		searchQuestion,
		handleQuestionChange,
		searchResults,
		setSearchResults,
		HelpComponent,
		setView,
		keyword,
		setKeyword,
		toggleScreenShare,
		showScreenShare,
		togglePopover,
		showTip,
		dismissTip,
		nowPlaying,
		setNowPlaying,
		Questions,
		searchKey,
		setSearchKey,
		showPopover,
		currentSideTab,
		setCurrentSideTab,
		popOverHistoryBack,
		searchObject,
		currentPipTabs,
		setCurrentPiPTabs,
		openMenu,
		popOverContent,
		showingMenu,
		setCurrentPiPNav,
		omegaAIHelpLinks,
		getOmegaAIHelpIcon,
	} = helpCenterContext;

	return {
		searchQuestion,
		handleQuestionChange,
		searchResults,
		setSearchResults,
		HelpComponent,
		setView,
		keyword,
		setKeyword,
		toggleScreenShare,
		showScreenShare,
		togglePopover,
		showTip,
		dismissTip,
		nowPlaying,
		setNowPlaying,
		Questions,
		searchKey,
		setSearchKey,
		showPopover,
		currentSideTab,
		setCurrentSideTab,
		popOverHistoryBack,
		searchObject,
		currentPipTabs,
		setCurrentPiPTabs,
		openMenu,
		popOverContent,
		showingMenu,
		setCurrentPiPNav,
		omegaAIHelpLinks,
		getOmegaAIHelpIcon,
	};
};

export default HelpCenterContextProvider;
