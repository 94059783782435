import React, { useState } from 'react';
import { Box, FormControlLabel, InputBase, IconButton, MenuItem, Select, Switch, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BusinessIcon from '@mui/icons-material/Business';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import MapDataBackground from '@worklist-2/ui/src/assets/icons/MapDataBackground.svg';
import MapDataIcon from '@worklist-2/ui/src/assets/icons/MapDataIcon.svg';
import { TabPanel } from '../../../Cases/casePopOverStyles';
import Section from './common/Section';
import CustomButton from './common/CustomButton';
import { MESSAGE_TYPES } from './AddStationStep';

const StyledSwitch = styled(Switch)(({ theme }) => ({
	'& .MuiSwitch-switchBase.Mui-checked': {
		color: '#4D79EA',
		'&:hover': {
			backgroundColor: 'none',
		},
	},
	'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
		backgroundColor: '#4D79EA',
	},
}));

const StyledSelect = styled(Select)(({ theme }) => ({
	width: '220px',
	background: 'rgba(196, 196, 196, 0.15)',
	borderRadius: ' 10px',
	border: ' 1px solid rgba(0, 0, 0, 0.05)',
	'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		border: 'none',
	},
	'& .MuiOutlinedInput-notchedOutline': {
		border: 'none',
	},
	'& .MuiOutlinedInput-input': {
		padding: '8px 14px',
		fontFamily: 'Inter',
		fontWeight: 400,
		fontSize: '14px',
		color: 'rgba(0, 0, 0, 0.9)',
	},
}));

const StyledInput = styled(InputBase)(() => ({
	padding: '8px 14px',
	width: '220px',
	backgroundColor: 'rgba(196, 196, 196, 0.15)',
	border: '1px solid rgba(0, 0, 0, 0.05)',
	borderRadius: '10px',
	input: {
		height: '22px',
		padding: '0',
		fontSize: '14px',
		color: 'rgba(0, 0, 0, 0.9)',
		'&::placeholder': {
			color: 'rgba(0, 0, 0, 0.9)',
		},
	},
}));

const AddStation = ({
	mappedTypes,
	isActive,
	setIsActive,
	stationType,
	setStationType,
	messageType,
	setMessageType,
	IP,
	setIP,
	inboundPort,
	setInboundPort,
	outboundPort,
	setOutboundPort,
	changePrevStep,
	changeNextStep,
	onClose,
	setSelectHL7MessageType,
	isEmpty,
	handleNextButtonClick,
}) => {
	const isReadyMapping = isActive && stationType && messageType;

	const handleStationTypeChange = event => {
		setStationType(event.target.value);
	};

	const handleMessageTypeChange = event => {
		setMessageType(event.target.value);
	};

	const handleInputChange = (event, setValue) => {
		setValue(event.target.value);
	};

	const handleActiveChange = event => {
		setIsActive(event.target.checked);
	};

	const handleMapDataSelect = name => {
		setSelectHL7MessageType(name);
		changeNextStep();
	};

	return (
		<Box
			sx={{
				boxSizing: 'border-box',
				position: 'relative',
				zIndex: '20',
				width: '538px',
				height: '100vh',
				padding: '14px 14px 0px',
				background: '#FFF',
				boxShadow: '-4px 0 20px 0 rgba(33,88,119,0.1)',
			}}
		>
			<Box sx={{ marginBottom: '24px', display: 'flex', justifyContent: 'space-between' }}>
				<IconButton sx={{ padding: 0 }} onClick={changePrevStep}>
					<ArrowBackIcon />
				</IconButton>
				<IconButton sx={{ padding: 0 }} onClick={onClose}>
					<LastPageIcon />
				</IconButton>
			</Box>
			<TabPanel style={{ margin: 0, minHeight: 'unset', maxHeight: 'unset', height: 'calc(100%  - 48px)' }}>
				<div className="header" style={{ borderBottom: 'none' }}>
					<div className="left">
						<div className="colored-icon info-icon">
							<BusinessIcon sx={{ fontSize: 20 }} />
						</div>
						<h2>Add Station</h2>
					</div>
					<div className="right">
						<div className="colored-icon active-icon">
							<BusinessIcon sx={{ fontSize: 10 }} />
						</div>
						<span className="active-span" />
						<div className="colored-icon active-icon">
							<CardTravelIcon sx={{ fontSize: 10 }} />
						</div>
						<span className="active-span" />
						<div className="colored-icon active-icon">
							<ToggleOnOutlinedIcon sx={{ fontSize: 10 }} />
						</div>
						<span className="active-span" />
						<div className="colored-icon active-icon">
							<DeviceHubIcon sx={{ fontSize: 10 }} />
						</div>
						<span className="gray-span" />
						<div className="colored-icon gray-icon">
							<FolderOutlinedIcon sx={{ fontSize: 10 }} />
						</div>
					</div>
				</div>
				<div className="body">
					<Box
						sx={{
							boxSizing: 'border-box',
							width: '100%',
							padding: '8px 18px',
							background: 'rgba(77, 121, 234, 0.03)',
						}}
					>
						<Typography
							sx={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}
						>
							Station Information
						</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							flex: 1,
							boxSizing: 'border-box',
							width: '100%',
							padding: '2px 20px 15px',
						}}
					>
						<FormControlLabel
							control={<StyledSwitch checked={isActive} onChange={handleActiveChange} />}
							label={
								<Typography
									sx={{
										fontFamily: 'Inter',
										fontWeight: 500,
										fontSize: '12px',
										color: 'rgba(0, 0, 0, 0.6)',
									}}
								>
									{isActive ? 'Active' : 'Inactive'}
								</Typography>
							}
						/>

						<Box
							sx={{
								marginTop: '8px',
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<Box>
								<Typography
									sx={{
										marginBottom: '8px',
										fontFamily: 'Inter',
										fontWeight: 500,
										fontSize: '12px',
										color: 'rgba(0, 0, 0, 0.6)',
									}}
								>
									Station Type
								</Typography>
								<StyledSelect
									IconComponent={KeyboardArrowDownIcon}
									disabled={!isActive}
									value={stationType}
									onChange={handleStationTypeChange}
								>
									<MenuItem value="HL7 Messaging">HL7 Messaging</MenuItem>
								</StyledSelect>
							</Box>
							<Box>
								<Typography
									sx={{
										marginBottom: '8px',
										fontFamily: 'Inter',
										fontWeight: 500,
										fontSize: '12px',
										color: 'rgba(0, 0, 0, 0.6)',
									}}
								>
									Message Type
								</Typography>
								<StyledSelect
									IconComponent={KeyboardArrowDownIcon}
									disabled={!isActive}
									value={messageType}
									onChange={handleMessageTypeChange}
								>
									<MenuItem value="Inbound">Inbound</MenuItem>
									<MenuItem value="Outbound">Outbound</MenuItem>
								</StyledSelect>
							</Box>
						</Box>
						{messageType && (
							<Box
								sx={{
									marginTop: '8px',
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								{messageType === 'Outbound' && (
									<Box>
										<Typography
											sx={{
												marginBottom: '8px',
												fontFamily: 'Inter',
												fontWeight: 500,
												fontSize: '12px',
												color: 'rgba(0, 0, 0, 0.6)',
											}}
										>
											IP
										</Typography>
										<StyledInput
											placeholder="-"
											value={IP}
											onChange={e => handleInputChange(e, setIP)}
										/>
									</Box>
								)}
								<Box>
									<Typography
										sx={{
											marginBottom: '8px',
											fontFamily: 'Inter',
											fontWeight: 500,
											fontSize: '12px',
											color: 'rgba(0, 0, 0, 0.6)',
										}}
									>
										Port
									</Typography>
									<StyledInput
										placeholder="-"
										value={messageType === 'Outbound' ? outboundPort : inboundPort}
										onChange={e =>
											handleInputChange(
												e,
												messageType === 'Outbound' ? setOutboundPort : setInboundPort
											)
										}
									/>
								</Box>
							</Box>
						)}
						<Section
							smallHeader
							color="#A6A4FF"
							icon={<CompareArrowsIcon sx={{ fontSize: '14px', color: '#FFF' }} />}
							secondColor="rgba(115, 113, 255, 0.19)"
							sx={{ flex: 1, marginTop: '10px' }}
							title="Map Data"
						>
							<MapDataSection
								handleMapDataSelect={handleMapDataSelect}
								isReadyMapping={isReadyMapping}
								mappedTypes={mappedTypes}
							/>
						</Section>
					</Box>
				</div>
				<Box
					sx={{
						padding: '10px 18px 30px',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<CustomButton outlined onClick={changePrevStep}>
						go back
					</CustomButton>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<CustomButton>create</CustomButton>
						<CustomButton
							disabled={isActive && isEmpty}
							sx={{ marginLeft: '8px' }}
							onClick={handleNextButtonClick}
						>
							next
						</CustomButton>
					</Box>
				</Box>
			</TabPanel>
		</Box>
	);
};

const MapDataSection = ({ isReadyMapping, handleMapDataSelect, mappedTypes }) => (
	<Box
		sx={{
			boxSizing: 'border-box',
			padding: '10px 14px',
			display: 'flex',
			justifyContent: 'space-between',
			flexWrap: 'wrap',
		}}
	>
		{MESSAGE_TYPES.map(key => (
			<MapDataItem
				key={key}
				handleMapDataSelect={handleMapDataSelect}
				isMapped={mappedTypes.includes(key)}
				isReadyMapping={isReadyMapping}
				name={key}
			/>
		))}
	</Box>
);

const MapDataItem = ({ name, isReadyMapping, handleMapDataSelect, isMapped }) => {
	const [isHover, setIsHover] = useState(false);

	return (
		<Box
			sx={{
				boxSizing: 'border-box',
				position: 'relative',
				marginBottom: '12px',
				padding: '10px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				borderRadius: '10px',
				border: '1px solid #D9D9D9',
			}}
			onMouseLeave={() => setIsHover(false)}
			onMouseOver={() => setIsHover(true)}
		>
			<MapDataBackground />
			<Box
				sx={{
					marginTop: '-22px',
					boxSizing: 'border-box',
					width: '45px',
					height: '45px',
					paddingLeft: '5px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: '#FFFFFF',
					borderRadius: '50%',
				}}
			>
				<MapDataIcon />
			</Box>
			<Typography
				sx={{
					fontFamily: 'Roboto',
					fontWeight: 400,
					fontSize: '14px',
					lineHeight: '20px',
					letterSpacing: '0.25px',
					color: '#272727',
				}}
			>
				{name}
			</Typography>
			{isReadyMapping && isHover && (
				<>
					<Box
						sx={{
							zIndex: 1,
							position: 'absolute',
							top: 0,
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(77, 121, 234, 0.2)',
							borderRadius: '10px',
							border: '1px solid #4D79EA',
							cursor: 'pointer',
						}}
						onClick={() => handleMapDataSelect(name)}
					/>
					<Typography
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							padding: '5px 7px',
							fontFamily: 'Inter',
							fontWeight: 500,
							fontSize: '12px',
							lineHeight: '15px',
							letterSpacing: '0.5px;',
							color: '#FFFFFF',
							backgroundColor: '#4D79EA',
							borderRadius: '3px',
							textTransform: 'uppercase',
							transform: 'translate(-50%, -50%)',
						}}
					>
						{isMapped ? 'update data' : 'map data'}
					</Typography>
				</>
			)}
		</Box>
	);
};

export default AddStation;
