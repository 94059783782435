// @flow

// core
import React from 'react';
// components
import Grid from '@mui/material/Grid';
import { LoaderSkeleton, type ISkeletonProps } from './LoaderSkeleton';

/** Skeleton loader used in `PatientNeeds.jsx` (the patient alerts tab in patient info page) */
export const LoaderPatientAlerts = ({ children, isLoading }: ISkeletonProps): any =>
	isLoading ? (
		<Grid container spacing="20px">
			{[1, 2, 3].map(i => (
				<Grid key={`patient_alert_skeleton_${i}`} item sx={{ minWidth: '250px' }} xs={4}>
					<LoaderSkeleton sx={{ height: '130px' }} />
				</Grid>
			))}
		</Grid>
	) : (
		children
	);
