import { getEnabledElement, triggerEvent, eventTarget, utilities as csUtils } from '@cornerstonejs/core';
import { Enums, AnnotationTool, utilities, annotation, drawing, state, cursors } from '@cornerstonejs/tools';
import midPoint2 from './utils/midPoint2';
import rotate from './utils/rotate';

const { transformWorldToIndex } = csUtils;
const { Events } = Enums;
const { throttle } = utilities;
const { lineSegment } = utilities.math;
const { getTextBoxCoordsCanvas } = utilities.drawing;
const { getViewportIdsWithToolToRender } = utilities.viewportFilters;
const { triggerAnnotationRenderForViewportIds } = utilities;
const { addAnnotation, getAnnotations, removeAnnotation } = annotation.state;
const { isAnnotationLocked } = annotation.locking;
const { isAnnotationVisible } = annotation.visibility;
const { drawHandles: drawHandlesSvg, drawLine: drawLineSvg, drawLinkedTextBox: drawLinkedTextBoxSvg } = drawing;
const { resetElementCursor, hideElementCursor } = cursors.elementCursor;

const angleBetweenPoints = (p1, p2) => (Math.atan2(p2[1] - p1[1], p2[0] - p1[0]) * 180) / Math.PI;

const getWorldPos = (viewport, worldPos, x = 0, y = 0) => {
	const { rotation } = viewport.getProperties();
	viewport.setProperties({ rotation: 0 });

	let ratioX;
	let ratioY;
	let result;

	const canvasPos = viewport.worldToCanvas(worldPos);
	const pos0 = viewport.worldToCanvas([worldPos[0] + 1, worldPos[1], worldPos[2]]);
	const pos1 = viewport.worldToCanvas([worldPos[0], worldPos[1] + 1, worldPos[2]]);
	const pos2 = viewport.worldToCanvas([worldPos[0], worldPos[1], worldPos[2] + 1]);

	if (pos0[0] !== canvasPos[0]) {
		ratioX = pos0[0] - canvasPos[0];
	}

	if (pos0[1] !== canvasPos[1]) {
		ratioY = pos0[1] - canvasPos[1];
	}

	if (pos1[0] !== canvasPos[0]) {
		ratioX = pos1[0] - canvasPos[0];
	}

	if (pos1[1] !== canvasPos[1]) {
		ratioY = pos1[1] - canvasPos[1];
	}

	if (pos2[0] !== canvasPos[0]) {
		ratioX = pos2[0] - canvasPos[0];
	}

	if (pos2[1] !== canvasPos[1]) {
		ratioY = pos2[1] - canvasPos[1];
	}

	ratioX = Math.abs(ratioX);
	ratioY = Math.abs(ratioY);

	result = viewport.canvasToWorld(
		rotate(canvasPos[0], canvasPos[1], canvasPos[0] + x * ratioX, canvasPos[1] + y * ratioY, rotation)
	);

	viewport.setProperties({ rotation });

	return result;
};

class CardiothoracicTool extends AnnotationTool {
	toolName;
	touchDragCallback;
	mouseDragCallback;
	_throttledCalculateCachedStats;
	editData;
	isDrawing;
	isHandleOutsideImage;

	constructor(
		toolProps = {},
		defaultToolProps = {
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				preventHandleOutsideImage: false,
			},
		}
	) {
		super(toolProps, defaultToolProps);

		this._throttledCalculateCachedStats = throttle(this._calculateCachedStats, 100, { trailing: true });
	}

	addNewAnnotation = (evt) => {
		const eventDetail = evt.detail;
		const { currentPoints } = eventDetail;
		const worldPos = currentPoints.world;

		const { element } = eventDetail;
		const enabledElement = getEnabledElement(element);
		const { viewport, renderingEngine } = enabledElement;

		const camera = viewport.getCamera();
		const { viewPlaneNormal, viewUp } = camera;

		const referencedImageId = this.getReferencedImageId(viewport, worldPos, viewPlaneNormal, viewUp);

		const FrameOfReferenceUID = viewport.getFrameOfReferenceUID();

		let point1 = getWorldPos(viewport, worldPos, -160);
		let point2 = getWorldPos(viewport, worldPos, 160);
		let point3 = getWorldPos(viewport, worldPos, -80, -40);
		let point4 = getWorldPos(viewport, worldPos, 80, -40);

		const _annotation = {
			highlighted: true,
			invalidated: true,
			metadata: {
				toolName: this.getToolName(),
				viewPlaneNormal: [...viewPlaneNormal],
				viewUp: [...viewUp],
				FrameOfReferenceUID,
				referencedImageId,
			},
			data: {
				handles: {
					points: [point1, point2, point3, point4],
					activeHandleIndex: null,
					textBox: {
						hasMoved: false,
						worldPosition: [0, 0, 0],
						worldBoundingBox: {
							topLeft: [0, 0, 0],
							topRight: [0, 0, 0],
							bottomLeft: [0, 0, 0],
							bottomRight: [0, 0, 0],
						},
					},
				},
				label: '',
				cachedStats: {},
			},
		};

		addAnnotation(_annotation, element);

		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.editData = {
			annotation: _annotation,
			viewportIdsToRender,
			handleIndex: 1,
			movingTextBox: false,
			newAnnotation: false,
			hasMoved: true,
		};

		evt.preventDefault();

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		const eventType = Events.ANNOTATION_COMPLETED;
		triggerEvent(eventTarget, eventType, {
			annotation: _annotation,
		});

		return _annotation;
	};

	activeLine;

	/**
	 * It returns if the canvas point is near the provided length annotation in the provided
	 * element or not. A proximity is passed to the function to determine the
	 * proximity of the point to the annotation in number of pixels.
	 *
	 * @param element - HTML Element
	 * @param _annotation - Annotation
	 * @param canvasCoords - Canvas coordinates
	 * @param proximity - Proximity to tool to consider
	 * @returns Boolean, whether the canvas point is near tool
	 */
	isPointNearTool = (element, _annotation, canvasCoords, proximity) => {
		const enabledElement = getEnabledElement(element);
		const { viewport } = enabledElement;
		const { data } = _annotation;
		const [point1, point2, point3, point4] = data.handles.points;
		const canvasPoint1 = viewport.worldToCanvas(point1);
		const canvasPoint2 = viewport.worldToCanvas(point2);
		const canvasPoint3 = viewport.worldToCanvas(point3);
		const canvasPoint4 = viewport.worldToCanvas(point4);

		const line1 = {
			start: {
				x: canvasPoint1[0],
				y: canvasPoint1[1],
			},
			end: {
				x: canvasPoint2[0],
				y: canvasPoint2[1],
			},
		};

		const line2 = {
			start: {
				x: canvasPoint3[0],
				y: canvasPoint3[1],
			},
			end: {
				x: canvasPoint4[0],
				y: canvasPoint4[1],
			},
		};

		const distanceToPoint = lineSegment.distanceToPoint(
			[line1.start.x, line1.start.y],
			[line1.end.x, line1.end.y],
			[canvasCoords[0], canvasCoords[1]]
		);

		const distanceToPoint2 = lineSegment.distanceToPoint(
			[line2.start.x, line2.start.y],
			[line2.end.x, line2.end.y],
			[canvasCoords[0], canvasCoords[1]]
		);

		if (distanceToPoint <= proximity) {
			this.activeLine = 1;

			return true;
		}

		if (distanceToPoint2 <= proximity) {
			this.activeLine = 2;

			return true;
		}

		return false;
	};

	toolSelectedCallback = (evt, _annotation) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		_annotation.highlighted = true;

		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.editData = {
			annotation: _annotation,
			viewportIdsToRender,
			movingTextBox: false,
		};

		this._activateModify(element);

		hideElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		evt.preventDefault();
	};

	handleSelectedCallback(evt, _annotation, handle) {
		const eventDetail = evt.detail;
		const { element } = eventDetail;
		const { data } = _annotation;

		_annotation.highlighted = true;

		let movingTextBox = false;
		let handleIndex;

		if (handle.worldPosition) {
			movingTextBox = true;
		} else {
			handleIndex = data.handles.points.findIndex((p) => p === handle);
		}

		// Find viewports to render on drag.
		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.editData = {
			annotation: _annotation,
			viewportIdsToRender,
			handleIndex,
			movingTextBox,
		};
		this._activateModify(element);

		hideElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		evt.preventDefault();
	}

	_endCallback = (evt) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const { annotation: _annotation, viewportIdsToRender } = this.editData;
		const { data } = _annotation;

		data.handles.activeHandleIndex = null;

		this._deactivateModify(element);
		this._deactivateDraw(element);
		resetElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		if (this.isHandleOutsideImage && this.configuration.preventHandleOutsideImage) {
			removeAnnotation(_annotation.annotationUID);
		}

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		const eventType = Events.ANNOTATION_COMPLETED;
		triggerEvent(eventTarget, eventType, {
			annotation: _annotation,
		});

		this.editData = null;
		this.isDrawing = false;
	};

	_dragCallback = (evt) => {
		this.isDrawing = true;
		const eventDetail = evt.detail;
		const { element } = eventDetail;
		const enabledElement = getEnabledElement(element);
		const { viewport, renderingEngine } = enabledElement;

		const { annotation: _annotation, viewportIdsToRender, handleIndex, movingTextBox } = this.editData;
		const { data } = _annotation;

		if (movingTextBox) {
			// Drag mode - moving text box
			const { deltaPoints } = eventDetail;
			const worldPosDelta = deltaPoints.world;

			const { textBox } = data.handles;
			const { worldPosition } = textBox;

			worldPosition[0] += worldPosDelta[0];
			worldPosition[1] += worldPosDelta[1];
			worldPosition[2] += worldPosDelta[2];

			textBox.hasMoved = true;
		} else if (handleIndex === undefined) {
			// Drag mode - moving handle
			const { deltaPoints } = eventDetail;
			const worldPosDelta = deltaPoints.world;

			const points = data.handles.points;

			points.forEach((point, index) => {
				if (this.activeLine === 1 && (index === 0 || index === 1)) {
					point[0] += worldPosDelta[0];
					point[1] += worldPosDelta[1];
					point[2] += worldPosDelta[2];
				}

				if (this.activeLine === 2 && (index === 2 || index === 3)) {
					point[0] += worldPosDelta[0];
					point[1] += worldPosDelta[1];
					point[2] += worldPosDelta[2];
				}
			});
			_annotation.invalidated = true;
		} else {
			// Move mode - after double click, and mouse move to draw
			const { currentPoints } = eventDetail;
			const worldPos = currentPoints.world;

			data.handles.points[handleIndex] = [...worldPos];

			const isLine1 = handleIndex === 0 || handleIndex === 1;
			const isLine2 = handleIndex === 2 || handleIndex === 3;

			if (isLine1) {
				const p1 = viewport.worldToCanvas(data.handles.points[0]);
				const p2 = viewport.worldToCanvas(data.handles.points[1]);
				const angle = angleBetweenPoints(p1, p2);

				const p3 = viewport.worldToCanvas(data.handles.points[2]);
				const p4 = viewport.worldToCanvas(data.handles.points[3]);
				const lineAngle = angleBetweenPoints(p3, p4);

				if (handleIndex === 0) {
					data.handles.points[2] = viewport.canvasToWorld(
						rotate(p4[0], p4[1], p3[0], p3[1], lineAngle - angle)
					);
				}

				if (handleIndex === 1) {
					data.handles.points[3] = viewport.canvasToWorld(
						rotate(p3[0], p3[1], p4[0], p4[1], lineAngle - angle)
					);
				}
			}

			if (isLine2) {
				const p1 = viewport.worldToCanvas(data.handles.points[2]);
				const p2 = viewport.worldToCanvas(data.handles.points[3]);
				const angle = angleBetweenPoints(p1, p2);

				const p3 = viewport.worldToCanvas(data.handles.points[0]);
				const p4 = viewport.worldToCanvas(data.handles.points[1]);
				const lineAngle = angleBetweenPoints(p3, p4);

				if (handleIndex === 2) {
					data.handles.points[0] = viewport.canvasToWorld(
						rotate(p4[0], p4[1], p3[0], p3[1], lineAngle - angle)
					);
				}

				if (handleIndex === 3) {
					data.handles.points[1] = viewport.canvasToWorld(
						rotate(p3[0], p3[1], p4[0], p4[1], lineAngle - angle)
					);
				}
			}

			_annotation.invalidated = true;
		}

		this.editData.hasMoved = true;

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
	};

	cancel = (element) => {
		// If it is mid-draw or mid-modify
		if (this.isDrawing) {
			this.isDrawing = false;
			this._deactivateDraw(element);
			this._deactivateModify(element);
			resetElementCursor(element);

			const { annotation: _annotation, viewportIdsToRender, newAnnotation } = this.editData;
			const { data } = _annotation;

			_annotation.highlighted = false;
			data.handles.activeHandleIndex = null;

			const enabledElement = getEnabledElement(element);
			const { renderingEngine } = enabledElement;

			triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

			if (newAnnotation) {
				const eventType = Events.ANNOTATION_COMPLETED;

				const eventDetail = {
					annotation: _annotation,
				};

				triggerEvent(eventTarget, eventType, eventDetail);
			}

			this.editData = null;
			return _annotation.annotationUID;
		}
	};

	_activateModify = (element) => {
		state.isInteractingWithTool = true;

		element.addEventListener(Events.MOUSE_UP, this._endCallback);
		element.addEventListener(Events.MOUSE_DRAG, this._dragCallback);
		element.addEventListener(Events.MOUSE_CLICK, this._endCallback);
	};

	_deactivateModify = (element) => {
		state.isInteractingWithTool = false;

		element.removeEventListener(Events.MOUSE_UP, this._endCallback);
		element.removeEventListener(Events.MOUSE_DRAG, this._dragCallback);
		element.removeEventListener(Events.MOUSE_CLICK, this._endCallback);
	};

	_activateDraw = (element) => {
		state.isInteractingWithTool = true;

		element.addEventListener(Events.MOUSE_UP, this._endCallback);
		element.addEventListener(Events.MOUSE_DRAG, this._dragCallback);
		element.addEventListener(Events.MOUSE_MOVE, this._dragCallback);
		element.addEventListener(Events.MOUSE_CLICK, this._endCallback);
	};

	_deactivateDraw = (element) => {
		state.isInteractingWithTool = false;

		element.removeEventListener(Events.MOUSE_UP, this._endCallback);
		element.removeEventListener(Events.MOUSE_DRAG, this._dragCallback);
		element.removeEventListener(Events.MOUSE_MOVE, this._dragCallback);
		element.removeEventListener(Events.MOUSE_CLICK, this._endCallback);
	};

	/**
	 * it is used to draw the length annotation in each
	 * request animation frame. It calculates the updated cached statistics if
	 * data is invalidated and cache it.
	 *
	 * @param enabledElement - The Cornerstone's enabledElement.
	 * @param svgDrawingHelper - The svgDrawingHelper providing the context for drawing.
	 */
	renderAnnotation = (enabledElement, svgDrawingHelper) => {
		let renderStatus = false;
		const { viewport } = enabledElement;
		const { element } = viewport;

		let annotations = getAnnotations(this.getToolName(), element);

		if (!annotations?.length) {
			return renderStatus;
		}

		annotations = this.filterInteractableAnnotationsForElement(element, annotations);

		if (!annotations?.length) {
			return renderStatus;
		}

		const targetId = this.getTargetId(viewport);
		const renderingEngine = viewport.getRenderingEngine();

		const styleSpecifier = {
			toolGroupId: this.toolGroupId,
			toolName: this.getToolName(),
			viewportId: enabledElement.viewport.id,
		};

		// Draw SVG
		for (let i = 0; i < annotations.length; i++) {
			const _annotation = annotations[i];
			const { annotationUID, data } = _annotation;
			const { points, activeHandleIndex } = data.handles;

			styleSpecifier.annotationUID = annotationUID;

			const lineWidth = this.getStyle('lineWidth', styleSpecifier, _annotation);
			const lineDash = this.getStyle('lineDash', styleSpecifier, _annotation);
			const color = this.getStyle('color', styleSpecifier, _annotation);
			const shadow = this.getStyle('shadow', styleSpecifier, _annotation);

			const canvasCoordinates = points.map((p) => viewport.worldToCanvas(p));

			let activeHandleCanvasCoords;

			// If cachedStats does not exist, or the unit is missing (as part of import/hydration etc.),
			// force to recalculate the stats from the points
			if (!data.cachedStats[targetId] || data.cachedStats[targetId].unit === undefined) {
				data.cachedStats[targetId] = {
					length: null,
					unit: null,
				};

				this._calculateCachedStats(_annotation, renderingEngine, enabledElement);
			} else if (_annotation.invalidated) {
				this._throttledCalculateCachedStats(_annotation, renderingEngine, enabledElement);
			}

			if (!isAnnotationVisible(annotationUID)) {
				continue;
			}

			if (!isAnnotationLocked(_annotation) && !this.editData && activeHandleIndex !== null) {
				// Not locked or creating and hovering over handle, so render handle.
				activeHandleCanvasCoords = [canvasCoordinates[activeHandleIndex]];
			}

			if (activeHandleCanvasCoords) {
				const handleGroupUID = '0';

				drawHandlesSvg(svgDrawingHelper, annotationUID, handleGroupUID, canvasCoordinates, {
					color,
					lineDash,
					lineWidth,
				});
			}

			const dataId = `${annotationUID}-line`;
			let lineUID = '1';
			drawLineSvg(
				svgDrawingHelper,
				annotationUID,
				lineUID,
				canvasCoordinates[0],
				canvasCoordinates[1],
				{
					color,
					width: lineWidth,
					lineDash,
					shadow,
				},
				dataId
			);

			lineUID = '2';
			drawLineSvg(
				svgDrawingHelper,
				annotationUID,
				lineUID,
				canvasCoordinates[2],
				canvasCoordinates[3],
				{
					color,
					width: lineWidth,
					lineDash,
					shadow,
				},
				dataId
			);

			lineUID = '3';
			const mid1 = midPoint2(canvasCoordinates[0], canvasCoordinates[1]);
			const mid2 = midPoint2(canvasCoordinates[2], canvasCoordinates[3]);
			drawLineSvg(svgDrawingHelper, annotationUID, lineUID, mid1, mid2, {
				color,
				lineWidth: '1',
				lineDash: '1,4',
			});

			renderStatus = true;

			// If rendering engine has been destroyed while rendering
			if (!viewport.getRenderingEngine()) {
				console.warn('Rendering Engine has been destroyed');
				return renderStatus;
			}

			const textLines = this._getTextLines(data, targetId);

			// Need to update to sync w/ annotation while unlinked/not moved
			if (!data.handles.textBox.hasMoved) {
				const canvasTextBoxCoords = getTextBoxCoordsCanvas(canvasCoordinates);

				data.handles.textBox.worldPosition = viewport.canvasToWorld(canvasTextBoxCoords);
			}

			const textBoxPosition = viewport.worldToCanvas(data.handles.textBox.worldPosition);

			const textBoxUID = '1';
			const boundingBox = drawLinkedTextBoxSvg(
				svgDrawingHelper,
				annotationUID,
				textBoxUID,
				textLines,
				textBoxPosition,
				canvasCoordinates,
				{},
				this.getLinkedTextBoxStyle(styleSpecifier, _annotation)
			);

			const { x: left, y: top, width, height } = boundingBox;

			data.handles.textBox.worldBoundingBox = {
				topLeft: viewport.canvasToWorld([left, top]),
				topRight: viewport.canvasToWorld([left + width, top]),
				bottomLeft: viewport.canvasToWorld([left, top + height]),
				bottomRight: viewport.canvasToWorld([left + width, top + height]),
			};
		}

		return renderStatus;
	};

	// text line for the current active length annotation
	_getTextLines(data, targetId) {
		const cachedVolumeStats = data.cachedStats[targetId];
		const { length, length2 } = cachedVolumeStats;

		// Can be null on load
		if (length === undefined || length === null || length2 === undefined || length2 === null || isNaN(length)) {
			return;
		}

		const value = (length2 / length) * 100;

		return [`${value?.toFixed(2)} %`];
	}

	_calculateLength(pos1, pos2) {
		const dx = pos1[0] - pos2[0];
		const dy = pos1[1] - pos2[1];
		const dz = pos1[2] - pos2[2];

		return Math.sqrt(dx * dx + dy * dy + dz * dz);
	}

	_calculateCachedStats(_annotation, renderingEngine, enabledElement) {
		const data = _annotation.data;
		const { viewportId, renderingEngineId } = enabledElement;

		const worldPos1 = data.handles.points[0];
		const worldPos2 = data.handles.points[1];
		const worldPos3 = data.handles.points[2];
		const worldPos4 = data.handles.points[3];
		const { cachedStats } = data;
		const targetIds = Object.keys(cachedStats);

		for (let i = 0; i < targetIds.length; i++) {
			const targetId = targetIds[i];

			const image = this.getTargetIdImage(targetId, renderingEngine);

			// If image does not exists for the targetId, skip. This can be due
			// to various reasons such as if the target was a volumeViewport, and
			// the volumeViewport has been decached in the meantime.
			if (!image) {
				continue;
			}

			const { imageData, dimensions, hasPixelSpacing } = image;

			const length = this._calculateLength(worldPos1, worldPos2);
			const length2 = this._calculateLength(worldPos3, worldPos4);

			const index1 = transformWorldToIndex(imageData, worldPos1);
			const index2 = transformWorldToIndex(imageData, worldPos2);

			this._isInsideVolume(index1, index2, dimensions)
				? (this.isHandleOutsideImage = false)
				: (this.isHandleOutsideImage = true);

			cachedStats[targetId] = {
				length,
				length2,
				unit: hasPixelSpacing ? 'mm' : 'px',
			};
		}

		_annotation.invalidated = false;

		// Dispatching annotation modified
		const eventType = Events.ANNOTATION_MODIFIED;

		const eventDetail = {
			annotation: _annotation,
			viewportId,
			renderingEngineId,
		};
		triggerEvent(eventTarget, eventType, eventDetail);

		return cachedStats;
	}

	_isInsideVolume(index1, index2, dimensions) {
		return csUtils.indexWithinDimensions(index1, dimensions) && csUtils.indexWithinDimensions(index2, dimensions);
	}
}

CardiothoracicTool.toolName = 'Cardiothoracic';
export default CardiothoracicTool;
