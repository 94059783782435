import React, { createContext, useCallback, useContext, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useConfig, useAuth } from '@worklist-2/core/src';
import { useSearchParams } from 'react-router-dom';
import { PRESETS as defaultPresets, extraHotkeys } from '../features/WindowLevel/constants';
import { v4 as uuid } from 'uuid';
import { useImageViewerCornerstoneContext } from './ImageViewerCornerstoneContext';
import { ApplyAutoWindowLevelToViewport } from '../features/CustomToolbar/components/Tools/WindowLevel/AutoWindowLevel';
import { toggleSigmoid } from '../features/WindowLevel/Sigmoid';
import { useImageViewerView3DContext } from './ImageViewerView3DContext';

const ImageViewerWindowLevelContext = createContext({});

const ImageViewerWindowLevelContextProvider = ({ children }) => {
	const __config = useConfig();
	const { viewportCustomTool } = useImageViewerCornerstoneContext();
	const { sessionId, authorized } = useAuth();
	const { imageLoaded } = useImageViewerView3DContext();
	const [currWindowLevelPresets, setCurrWindowLevelPresets] = useState([]);
	const [searchParams] = useSearchParams();

	const [selectedWindowLevel, setSelectedWindowLevel] = useState(null);
	const [isShowWindowLevelConfigDrawer, setIsShowWindowLevelConfigDrawer] = useState(false);
	const [isShowAddNewWindowLevel, setIsShowAddNewWindowLevel] = useState(false);

	const currWindowLevelPresetsPayloadRef = useRef(null);
	const defaultWindowLevelRef = useRef(null);

	useEffect(() => {
		if (sessionId && authorized && imageLoaded) {
			loadWindowLevelPresets();
		}
	}, [sessionId, authorized, imageLoaded]);

	const loadWindowLevelPresets = async () => {
		try {
			const res = await axios.get(`${__config.data_sources.dicom_web}/ImageViewerPreset`, {
				params: {
					JsonQuery: JSON.stringify({ PresetType: 'WindowLevel', PresetLevel: 'USER' }),
					InternalManagingOrgId: searchParams.get('internalManagingOrganizationID'),
				},
			});

			const data = res?.data
				?.replaceAll('---------', '')
				?.replaceAll('Content-Type: application/json', '')
				?.split('-------')
				?.filter(val => !!val)
				?.map(el => {
					try {
						return JSON.parse(el);
					} catch (error) {
						return null;
					}
				});

			if (data?.[0]) {
				currWindowLevelPresetsPayloadRef.current = data?.[0];
			}

			if (data?.[0]?.WindowLevelPresets) {
				setCurrWindowLevelPresets(data?.[0]?.WindowLevelPresets);
			} else {
				setCurrWindowLevelPresets([...extraHotkeys, ...defaultPresets]);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const updateWindowLevelPresets = useCallback(async windowLevelPresets => {
		const currPresetID = currWindowLevelPresetsPayloadRef.current?.PresetID;
		const _ = {
			PresetID: currWindowLevelPresetsPayloadRef.current?.PresetID ?? uuid(),
			PresetName: 'WindowLevelPreset',
			PresetType: 'WindowLevel',
			PresetLevel: 'USER',
		};

		const payload = {
			..._,
			WindowLevelPresets: windowLevelPresets,
		};

		try {
			if (currPresetID) {
				await axios.put(`${__config.data_sources.dicom_web}/ImageViewerPreset`, payload, {
					params: {
						InternalManagingOrgId: searchParams.get('internalManagingOrganizationID'),
						presetId: currWindowLevelPresetsPayloadRef.current.PresetID,
					},
				});
			} else {
				// no presetID, create a new one
				await axios.post(`${__config.data_sources.dicom_web}/ImageViewerPreset`, payload, {
					params: {
						InternalManagingOrgId: searchParams.get('internalManagingOrganizationID'),
						presetId: payload.PresetID,
					},
				});
			}
		} catch (err) {
			console.error(err);
		}
	}, []);

	const onWindowLevelPresetChange = (preset, viewportId) => {
		if (preset?.menuLabel == 'Auto') {
			ApplyAutoWindowLevelToViewport(viewportId);
		} else if (preset?.menuLabel == 'Sigmoid') {
			toggleSigmoid(viewportId);
		} else {
			viewportCustomTool({
				viewportId,
				toolName: 'WindowLeveling',
				options: preset,
			});
		}
	};

	return (
		<ImageViewerWindowLevelContext.Provider
			value={{
				selectedWindowLevel,
				setSelectedWindowLevel,
				isShowWindowLevelConfigDrawer,
				setIsShowWindowLevelConfigDrawer,
				isShowAddNewWindowLevel,
				setIsShowAddNewWindowLevel,
				currWindowLevelPresets,
				setCurrWindowLevelPresets,
				updateWindowLevelPresets,
				onWindowLevelPresetChange,
				defaultWindowLevelRef,
			}}
		>
			{children}
		</ImageViewerWindowLevelContext.Provider>
	);
};

const useImageViewerWindowLevelContext = () => useContext(ImageViewerWindowLevelContext);

export { ImageViewerWindowLevelContext, useImageViewerWindowLevelContext, ImageViewerWindowLevelContextProvider };
