import { Stack, Typography, IconButton, Select, MenuItem, ClickAwayListener } from '@mui/material';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
	GeneralResultBody,
	IconBackgroundWhite,
	IconContainerOKR,
} from '../../../../views/CrmView/GlobalSearchResultsView/GlobalSearchResultsStyle';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import AccordionContainer from './AccordionContainer';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useCrmContext } from '../../../../context/CRMContext';
import {
	MenuItemOnTrack,
	MenuItemOffTrack,
	MenuItemDelayed,
	MenuItemAcrhived,
	BoxObjectiveOKR,
	TextFieldObjectiveOKR,
	WrapperStack,
	AutocompleteAssigner,
} from './OKRstyles';
import AccountCircle from '@mui/icons-material/AccountCircleOutlined';
import QuarterCardSwitch from './QuarterCardSwitch';
import { useDrag, useDrop } from 'react-dnd';
import { crmSearchScopes, useCRMDataLoader, useAuth } from '@worklist-2/core/src';

function QuarterCard({
	objectives,
	setObjectives,
	index,
	setObjectState,
	priority,
	ObjectiveIndex,
	objective,
	description,
	cardData,
	status,
	owner,
	handleResultAdd,
	onAddIdea,
	onEditIdea,
	onEditKeyResult,
	onEditObjective,
	createData,
	updateData,
	objectiveData,
	onMouseOver,
	onMouseLeave,
	isActive,
	isObjectiveActive,
	deleteObjective,
	onEditActive,
	onEditStatus,
	onEditKeyStatus,
	onEditIdeaStatus,
	onEditIdeaOwner,
	users,
	onEditObjectiveUser,
	onEditKeyResultUser,
	onSetObjectiveVariable,
	onSetKeyResultVariable,
}) {
	const [isCollapse, setIsCollapse] = useState(false);
	const [isInput, toggleInput] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [hoveredIndex, setHoveredIndex] = useState(-1);
	const [selectedStatus, setSelectedStatus] = useState(status);
	const [statusBgColor, setStatusBgColor] = useState('');
	const [keyResObj, setKeyResObj] = useState();
	const [value, setValue] = useState(description || objective || '');
	const [statusfontColor, setStatusFontColor] = useState('');
	const { quarterGB } = useCrmContext();
	const { setSelectedObjective, isCloseOkr, setCloseOkr } = useCrmContext();
	const [obj, setObj] = useState();
	const [isAssignerMenuOpen, setIsAssignerMenuOpen] = useState(false);
	const displayDelete = -1;
	const displayAdd = [-1, -2];
	const okrDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.okrApi,
	});
	const { loggedInUser } = useAuth();

	useEffect(() => {
		if (isCloseOkr) {
			setIsCollapse(false);
			setCloseOkr(false);
		}
	}, [isCloseOkr]);

	// useDrag - the list item is draggable
	const [{ isDragging }, dragSource, dragPreview] = useDrag({
		type: 'objective',
		item: { index },
		end: () => {
			objectives.forEach(async objective => {
				const payload = _.cloneDeep(objective);
				const refinedObjective = {
					...objective,
					KeyResults: payload.KeyResults?.map(keyResult => ({
						...keyResult,
						hovered: undefined,
						expanded: undefined,
					})),
					hovered: undefined,
					expanded: undefined,
				};
				onSetObjectiveVariable(refinedObjective, 'expanded', false);
				await okrDataLoader.update(objective.id, refinedObjective);
			});
			setCloseOkr(true);
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});
	// useDrop - the list item is also a drop area
	const [spec, dropRef] = useDrop({
		accept: 'objective',
		hover: (item, monitor) => {
			const dragIndex = item.index;
			const hoverIndex = index;
			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;
			// if dragging down, continue only when hover is smaller than middle Y
			if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
			// if dragging up, continue only when hover is bigger than middle Y
			if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;
			moveObjectivesListItem(dragIndex, hoverIndex);
			item.index = hoverIndex;
		},
	});
	// Join the 2 refs together into one (both draggable and can be dropped on)
	const ref = useRef(null);
	const dragDropRef = dragPreview(dropRef(ref));

	const moveObjectivesListItem = useCallback(
		async (dragIndex, hoverIndex) => {
			if (dragIndex !== hoverIndex) {
				const dragItem = {
					...objectives[dragIndex],
					OrderNum: hoverIndex + 1,
				};
				const hoverItem = {
					...objectives[hoverIndex],
					OrderNum: dragIndex + 1,
				};
				// Swap places of dragItem and hoverItem in the objectives array

				const updatedObjectives = [...objectives];
				updatedObjectives[dragIndex] = { ...hoverItem };
				updatedObjectives[hoverIndex] = { ...dragItem };

				setObjectives(updatedObjectives);
			}
		},
		[objectives]
	);

	const moveKeyListItem = useCallback(
		async (dragIndex, hoverIndex, ObjectiveIndex) => {
			if (dragIndex !== hoverIndex) {
				const dragItem = objectives[ObjectiveIndex].KeyResults[dragIndex];
				const hoverItem = objectives[ObjectiveIndex].KeyResults[hoverIndex];
				// Swap places of dragItem and hoverItem in the objectives array
				objectives[ObjectiveIndex].KeyResults[dragIndex] = hoverItem;
				objectives[ObjectiveIndex].KeyResults[hoverIndex] = dragItem;
				const updatedObjectives = [...objectives];
				updatedObjectives[ObjectiveIndex].KeyResults[dragIndex] = hoverItem;
				updatedObjectives[ObjectiveIndex].KeyResults[hoverIndex] = dragItem;
				setObjectives(updatedObjectives);
			}
		},
		[objectiveData]
	);
	const moveIdeaListItem = useCallback(
		async (dragIndex, hoverIndex, ObjectiveIndex, keyIndex) => {
			// return
			if (dragIndex !== hoverIndex) {
				const dragItem = objectives[ObjectiveIndex].KeyResults[keyIndex].Ideas[dragIndex];
				const hoverItem = objectives[ObjectiveIndex].KeyResults[keyIndex].Ideas[hoverIndex];
				// Swap places of dragItem and hoverItem in the objectives array
				objectives[ObjectiveIndex].KeyResults[keyIndex].Ideas[dragIndex] = hoverItem;
				objectives[ObjectiveIndex].KeyResults[keyIndex].Ideas[hoverIndex] = dragItem;

				const updatedObjectives = [...objectives];
				updatedObjectives[ObjectiveIndex].KeyResults[keyIndex].Ideas[dragIndex] = hoverItem;
				updatedObjectives[ObjectiveIndex].KeyResults[keyIndex].Ideas[hoverIndex] = dragItem;

				setObjectives(updatedObjectives);
			}
		},
		[objectiveData]
	);

	const handleEmptyContainerClick = () => {
		if (loggedInUser?.permission?.OKR?.Edit) {
			setValue(description || objective || '');
			toggleInput(true);
		}
	};

	useEffect(() => {
		if (objective?.length === 0) {
			handleEmptyContainerClick();
		} else {
			toggleInput(false);
		}
		const statusStyle = getStyleByStatus(objectiveData.Status);
		setStatusBgColor(statusStyle.bgColor);
		setStatusFontColor(statusStyle.fontColor);
	}, [objective, selectedStatus, objectiveData.Status]);

	const handleAddIdea = (data, indexKeyResult) => {
		onAddIdea(indexKeyResult, data, objectiveData);
	};

	const handleEditObjective = value => {
		onEditObjective(value, objectiveData);
	};

	const handleEditKeyResult = (keyResultIndex, value, isRemove = false) => {
		onEditKeyResult(keyResultIndex, value, objectiveData, isRemove);
	};

	const handleEditKeyResultsUser = (keyResultIndex, value) => {
		onEditKeyResultUser(keyResultIndex, value, objectiveData);
	};

	const handleEditIdea = (keyResultIndex, ideaIndex, value, isRemove = false) => {
		onEditIdea(keyResultIndex, ideaIndex, value, objectiveData, isRemove);
	};

	const handleEditStatusIdea = (keyResultIndex, ideaIndex, value) => {
		onEditIdeaStatus(keyResultIndex, ideaIndex, value, objectiveData);
	};

	const handleEditIdeaOwner = (keyResultIndex, ideaIndex, value) => {
		onEditIdeaOwner(keyResultIndex, ideaIndex, value, objectiveData);
	};

	const handleSetActive = () => {
		setSelectedObjective(objectiveData);
	};

	const handleStatusChange = e => {
		const _status = e.target.value;
		setSelectedStatus(_status);

		onEditStatus(_status, objectiveData);
	};

	const handleOwnerChange = e => {
		const _owner = e;
		onEditObjectiveUser(_owner, objectiveData);

		if (e) {
			setIsAssignerMenuOpen(false);
		}
	};

	useEffect(() => {
		setIsCollapse(false);
	}, [quarterGB]);

	const getStyleByStatus = status => {
		let bgColor = '';
		let fontColor = '';
		switch (status?.replace(/ /g, '').toLowerCase()) {
			case 'ontrack':
				bgColor = 'rgba(47, 214, 173, 0.1)';
				fontColor = 'rgba(0, 154, 116, 0.6)';
				break;
			case 'offtrack':
				bgColor = 'rgba(239, 93, 93, 0.2)';
				fontColor = '#EF5D5D';
				break;
			case 'delayed':
				bgColor = 'rgba(238, 186, 2, 0.2)';
				fontColor = '#EEBA02';
				break;
			case 'archived':
				bgColor = 'rgba(77, 121, 234, 0.2)';
				fontColor = '#4D79EA';
				break;
			default:
				bgColor = 'rgba(196, 196, 196, 0.2)';
				fontColor = '#C4C4C4';
				break;
		}

		return {
			bgColor,
			fontColor,
		};
	};

	const submitRef = useRef();

	const handleObjectiveSubmit = e => {
		e.preventDefault();

		const objectiveVal = value.trim();
		toggleInput(false);

		if (objectiveVal) {
			if (objectiveData && (objectiveData.OKRId || objectiveData.id)) {
				handleEditObjective(objectiveVal);
				updateData(objectiveData, objectiveData.OKRId || objectiveData.id);
			} else {
				createData(objectiveVal, ObjectiveIndex);
			}
		} else if (objectiveData.OKRId || objectiveData.id) {
			handleEditObjective(objectiveData.Objective);
		} else {
			deleteObjective();
		}
	};

	const handleSubmitOnBlur = () => {
		const objectiveVal = value.trim();
		toggleInput(false);

		if (!(objectiveData.OKRId || objectiveData.id)) {
			if (objectiveVal) {
				createData(objectiveVal, ObjectiveIndex);
			} else {
				deleteObjective();
			}
		}
	};

	return (
		<div
			ref={dragDropRef}
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				opacity: isObjectiveActive ? '1' : '0.6',
			}}
			onMouseLeave={() => setIsEdit(false)}
		>
			<div
				id="divKeyResult"
				style={{
					width: isEdit ? '130%' : '100%',
				}}
				onMouseLeave={onMouseLeave}
				onMouseOver={onMouseOver}
			>
				<Stack
					alignItems="center"
					direction="row"
					mb="15px"
					mr="20px"
					position="relative"
					onClick={handleSetActive}
				>
					{loggedInUser?.permission?.OKR?.Edit && (
						<span
							ref={dragSource}
							style={{
								position: 'absolute',
								left: '30px',
								top: '50%',
								transform: 'translateY(-50%)',
								zIndex: '1',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<DragIndicatorIcon
								sx={{
									width: '15px',
									height: '20px',
									color: 'grey',
									opacity: '0.5',
									'&:hover': {
										opacity: '0.8',
									},
								}}
							/>
						</span>
					)}
					<IconContainerOKR>
						<IconBackgroundWhite
							onClick={() => {
								setIsCollapse(!isCollapse);
								setObjectState({});
								setObj();
								onSetObjectiveVariable(objectiveData, 'expanded', !isCollapse);
							}}
						>
							{isCollapse ? (
								<RemoveIcon
									sx={{
										color: '#4D79EA',
										cursor: 'pointer',
										transition: 'all 0.5s ease',
									}}
								/>
							) : (
								<AddIcon
									sx={{
										color: '#4D79EA',
										cursor: 'pointer',
										transition: 'all 0.5s ease',
									}}
								/>
							)}
						</IconBackgroundWhite>
					</IconContainerOKR>
					<GeneralResultBody
						sx={{
							width: '100%',
							height: {
								md: '53px',
								sm: 'auto',
								'@media (width: 1920px)': {
									width: '646px',
								},
							},
							background: '#FFF',
							boxShadow: '0px 0px 20px rgba(33, 88, 119, 0.1)',
							borderRadius: '10px',
							display: 'flex',
							flexDirection: { md: 'row', sm: 'column' },
							marginLeft: '15px',
						}}
					>
						<div
							style={{
								width: isEdit ? '45%' : '80%',
								display: 'flex',
								alignItems: 'center',
								overflow: 'hidden',
								color: '#4D79EA',
								height: 'inherit',
							}}
						>
							<Typography
								ml="30px"
								mt={isInput ? '8px' : '-1px'}
								sx={{
									fontSize: '14px',
									fontWeight: 600,
									fontStyle: 'Inter',
								}}
							>
								{priority}
							</Typography>
							{isInput ? (
								<form onBlur={handleSubmitOnBlur} onSubmit={handleObjectiveSubmit}>
									<BoxObjectiveOKR ml="5px">
										<TextFieldObjectiveOKR
											autoFocus
											data-testid={`objInput${priority || ''}`}
											id="objective"
											name="objective"
											value={value}
											onChange={e => setValue(e.target.value)}
											onKeyDown={e => {
												if (e.key === 'Enter' && !e.target.value) {
													e.preventDefault();
												}
											}}
										/>
										<button
											ref={submitRef}
											data-testid={`objSubmit${priority || ''}`}
											style={{ display: 'none' }}
											type="submit"
										/>
										{(objectiveData.OKRId || objectiveData.id) && (
											<div className="menu-btn">
												<IconButton
													className="icon-btn"
													sx={{
														padding: '8px 0px 8px 0px',
													}}
													onMouseDown={handleObjectiveSubmit}
												>
													<CheckIcon
														className="icon"
														fontSize="25px"
														sx={{
															color: '#4D79EA',
														}}
													/>
												</IconButton>
												<IconButton
													className="icon-btn"
													sx={{
														padding: '7px 2px 5px 2px',
													}}
													type="button"
												>
													<CloseIcon
														className="icon"
														fontSize="25px"
														sx={{
															color: 'rgba(0, 0, 0, 0.4)',
														}}
														onClick={e => {
															toggleInput(false);
															if (objectiveData.OKRId || objectiveData.id) {
																handleEditObjective(objectiveData.Objective);
															} else {
																deleteObjective();
															}
														}}
													/>
												</IconButton>
											</div>
										)}
									</BoxObjectiveOKR>
								</form>
							) : (
								<Typography
									data-testid="objectiveActive"
									ml="10px"
									sx={{
										width: '80%',
										height: 'inherit',
										overflow: 'auto',
										'&::-webkit-scrollbar-thumb': {
											background: '#C4C4C4 !important',
											borderRadius: '3px',
										},
										'&::-webkit-scrollbar-track': {
											background: '#FFF !important',
										},
										display: 'flex',
										alignItems: 'center',
									}}
									onClick={handleEmptyContainerClick}
								>
									<Typography
										sx={{
											fontSize: '14px',
											fontWeight: 600,
											fontStyle: 'Inter',
											margin: 'auto 0',
										}}
									>
										{description || objective}
									</Typography>
								</Typography>
							)}
							{!isInput && !!loggedInUser?.permission?.OKR?.Edit && (
								<IconButton sx={{ width: '30px' }} type="button" onClick={handleEmptyContainerClick}>
									{isActive && hoveredIndex === displayDelete && <CreateOutlinedIcon />}
								</IconButton>
							)}
						</div>

						<div
							style={{
								background: statusBgColor,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								borderRadius: '17px',
								height: '27px',
								minWidth: '90px',
								marginLeft: '5px',
								marginRight: '15px',
							}}
						>
							<Select
								IconComponent=""
								MenuProps={{
									PaperProps: {
										sx: {
											borderRadius: '8px',
											width: '102px',
											justifyContent: 'center',
											border: '1px solid #4D79EA',
										},
									},
								}}
								class="notchedOutline"
								data-testid={`objSts${priority || ''}`}
								disabled={!loggedInUser?.permission?.OKR?.Edit}
								name="status"
								sx={{
									'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
										border: 'none',
									},
									'& .MuiOutlinedInput-notchedOutline': {
										border: 'none',
									},
									'& .MuiSelect-outlined': {
										paddingRight: '16px !important',
									},
									'& .MuiSelect-icon.MuiSelect-iconOutlined.css-3qbkez-MuiSelect-icon': {
										display: 'none',
									},
									'& .MuiInputBase-input.Mui-disabled': {
										WebkitTextFillColor: statusfontColor,
									},
									justifyContent: 'center',
									fontWeight: '500',
									fontSize: '12px',
									color: statusfontColor,
								}}
								value={objectiveData.Status || ''}
								onChange={handleStatusChange}
							>
								<MenuItemOnTrack value="ON TRACK">ON TRACK</MenuItemOnTrack>
								<MenuItemOffTrack value="OFF TRACK">OFF TRACK</MenuItemOffTrack>
								<MenuItemDelayed value="DELAYED">DELAYED</MenuItemDelayed>
								<MenuItemAcrhived value="ARCHIVED">ARCHIVED</MenuItemAcrhived>
							</Select>
						</div>
						<div
							style={{
								width: '28px',
								height: '22px',
								background: objectiveData.Owner ? '#FFDBF3' : '',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								padding: objectiveData.Owner ? '5px' : '2px',
								borderRadius: '50%',
								cursor: loggedInUser?.permission?.OKR?.Edit ? 'pointer' : '',
							}}
							onClick={() => {
								if (loggedInUser?.permission?.OKR?.Edit) {
									setIsAssignerMenuOpen(!isAssignerMenuOpen);
								}
							}}
						>
							{objectiveData.Owner ? (
								<div
									style={{
										color: '#D349A4',
										fontWeight: 'bold',
										fontSize: '12px',
									}}
								>
									{objectiveData.Owner.split(' ')
										.map(n => n.charAt(0).toUpperCase())
										.join('')}
								</div>
							) : (
								<AccountCircle fontSize="large" sx={{ color: 'rgba(0, 0, 0, 0.4)' }} />
							)}
						</div>
						{isAssignerMenuOpen && (
							<ClickAwayListener onClickAway={() => setIsAssignerMenuOpen(false)}>
								<div>
									<WrapperStack>
										<AutocompleteAssigner
											freeSolo
											id="assigner"
											options={_.map(users, user => user.username)}
											size="small"
											value={objectiveData.Owner}
											onChange={(e, val) => handleOwnerChange(val)}
										/>
									</WrapperStack>
								</div>
							</ClickAwayListener>
						)}
						{!isEdit && (
							<IconButton
								disabled={
									!loggedInUser?.permission?.OKR?.Edit && !loggedInUser?.permission?.OKR?.Delete
								}
								sx={{
									padding: '7px 5px 5px 5px',
									width: '30px',
								}}
								type="button"
								onClick={() => setIsEdit(true)}
							>
								{isActive &&
									hoveredIndex === displayDelete &&
									(!!loggedInUser?.permission?.OKR?.Edit ||
										!!loggedInUser?.permission?.OKR?.Delete) && <MoreVertIcon />}
							</IconButton>
						)}
						{isEdit && (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								{!!loggedInUser?.permission?.OKR?.Edit && (
									<QuarterCardSwitch
										isActive={isObjectiveActive}
										testid={`activeButton${priority || ''}`}
										onClick={() => onEditActive(objectiveData)}
									/>
								)}
								<IconButton
									disabled={!loggedInUser?.permission?.OKR?.Delete}
									sx={{
										padding: '7px 5px 5px 5px',
										width: '30px',
									}}
									type="button"
									onClick={() => {
										setSelectedObjective(null);
										deleteObjective();
										setIsCollapse(false);
									}}
								>
									{!!loggedInUser?.permission?.OKR?.Delete && <DeleteOutlineIcon />}
								</IconButton>
							</div>
						)}
					</GeneralResultBody>
				</Stack>
				{isActive &&
					isCollapse &&
					displayAdd.includes(hoveredIndex) &&
					!!loggedInUser?.permission?.OKR?.Edit && (
						<Stack
							alignItems="normal"
							direction="column"
							mb="20px"
							ml="35px"
							onMouseLeave={() => setHoveredIndex(-1)}
							onMouseOver={() => setHoveredIndex(-2)}
						>
							<div
								style={{
									alignItems: 'center',
									color: 'rgba(0, 0, 0, 0.4)',
									fontWeight: '400',
								}}
							>
								<div
									style={{
										cursor: 'pointer',
										display: 'flex',
									}}
									onClick={() => handleResultAdd(objectiveData)}
								>
									<AddIcon fontSize="12px" sx={{ marginRight: '5px' }} />
									<span style={{ fontSize: '14px' }}>New Key Result</span>
								</div>
							</div>
						</Stack>
					)}
			</div>
			{isCollapse && (
				<Stack alignItems="normal" direction="column" ml="10px" mr="20px">
					{cardData?.map((data, i) => (
						<SubQuarterCard
							ObjectiveIndex={ObjectiveIndex}
							data={data}
							getStyleByStatus={getStyleByStatus}
							isActive={i === hoveredIndex}
							keyResultIndex={i}
							moveIdeaListItem={moveIdeaListItem}
							moveKeyListItem={moveKeyListItem}
							objectState={obj}
							objective={objectiveData}
							setObjectState={object => {
								setObj(object);
								setObjectState(object);
							}}
							updateData={updateData}
							users={users}
							onAddIdea={handleAddIdea}
							onEditIdea={handleEditIdea}
							onEditIdeaOwner={handleEditIdeaOwner}
							onEditIdeaStatus={handleEditStatusIdea}
							onEditKeyResult={handleEditKeyResult}
							onEditKeyResultsUser={handleEditKeyResultsUser}
							onEditKeyStatus={onEditKeyStatus}
							onMouseLeave={() => {
								setHoveredIndex(-1);
								onSetKeyResultVariable(objectiveData, i, 'hovered', false);
							}}
							onMouseOver={() => {
								setHoveredIndex(i);
								onSetObjectiveVariable(objectiveData, 'hovered', false);
								onSetKeyResultVariable(objectiveData, i, 'hovered', true);
							}}
							onSetKeyResultVariable={onSetKeyResultVariable}
						/>
					))}
				</Stack>
			)}
		</div>
	);
}

function SubQuarterCard({
	setObjectState,
	objectState,
	data,
	ObjectiveIndex,
	keyResultIndex,
	objective,
	onAddIdea,
	onEditIdea,
	onEditIdeaOwner,
	onEditKeyResult,
	onEditKeyResultsUser,
	updateData,
	onMouseOver,
	onMouseLeave,
	isActive,
	onEditKeyStatus,
	onEditIdeaStatus,
	getStyleByStatus,
	users,
	onSetKeyResultVariable,
	moveKeyListItem,
	moveIdeaListItem,
}) {
	const { closeKeyResult, setCloseKeyResult } = useCrmContext();
	const [isCollapse, setIsCollapse] = useState(false);
	const [isInput, toggleInput] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState(data?.Status);
	const [selectedOwners, setSelectedOwners] = useState(users);
	const [value, setValue] = useState(data?.KeyResult || '');
	const [statusBgColor, setStatusBgColor] = useState('');
	const [statusfontColor, setStatusFontColor] = useState('');
	const [hoveredIndex, setHoveredIndex] = useState(-1);
	const [top, setTop] = useState(0);
	const [isAssignerMenuOpen, setIsAssignerMenuOpen] = useState(false);
	const displayDelete = -1;
	const displayAdd = [-1, -2];
	const myRef = useRef();
	const okrDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.okrApi,
	});
	const { loggedInUser } = useAuth();

	const handleEmptyContainerClick = () => {
		if (loggedInUser?.permission?.OKR?.Edit) {
			setValue(data?.KeyResult || '');
			toggleInput(true);
		}
	};

	const handleStatusChange = e => {
		setSelectedStatus(e.target.value);

		onEditKeyStatus(keyResultIndex, e.target.value, objective);
	};

	useEffect(() => {
		if (objective?.KeyResults[keyResultIndex]?.KeyResult?.length === 0) {
			handleEmptyContainerClick();
		} else {
			toggleInput(false);
		}
		const statusStyle = getStyleByStatus(data.Status);
		setStatusBgColor(statusStyle.bgColor);
		setStatusFontColor(statusStyle.fontColor);
	}, [objective, selectedStatus, data]);

	const handleKeyResultsUserChange = e => {
		const _owner = e;
		onEditKeyResultsUser(keyResultIndex, _owner);

		if (e) {
			setIsAssignerMenuOpen(false);
		}
	};

	const handleOnChangeText = (e, isRemove = false) => {
		if (isRemove) {
			onEditKeyResult(keyResultIndex, e.target.value, isRemove);
		} else {
			setValue(e.target.value);
		}
	};

	const getComponentTop = () => {
		const position = myRef.current.getBoundingClientRect();
		setObjectState({
			...objectState,
			[keyResultIndex]: !isCollapse,
			[`top${keyResultIndex}`]: position.top,
		});
	};

	useEffect(() => {
		const position = myRef?.current?.getBoundingClientRect();
		setObjectState({
			...objectState,
			[`top${keyResultIndex}`]: position.top,
		});
	}, [myRef?.current?.getBoundingClientRect()?.top, isActive]);

	const handleEditIdeaOwner = (ideaIndex, owner) => {
		onEditIdeaOwner(keyResultIndex, ideaIndex, owner);
	};

	const handleAddIdea = data => {
		onAddIdea(
			{
				...data,
				Ideas: [
					...(data.Ideas || []),
					{
						Idea: '',
						Status: '',
						Progress: null,
						ProgressStatus: '',
						Owner: '',
						Team: '',
					},
				],
			},
			keyResultIndex
		);
	};

	const submitRef = useRef();

	const handleKeySubmit = e => {
		e.preventDefault();

		const keyResultVal = value.trim();
		toggleInput(false);
		if (keyResultVal) {
			onEditKeyResult(keyResultIndex, keyResultVal);
			updateData(objective, objective.OKRId || objective.id);
		} else if (data?.KeyResult) {
			setValue(data?.KeyResult);
		} else {
			handleOnChangeText(e, true);
		}
	};

	const handleSubmitOnBlur = e => {
		const keyResultVal = value.trim();
		toggleInput(false);

		if (!data?.KeyResult) {
			if (keyResultVal) {
				handleKeySubmit(e);
			} else {
				onEditKeyResult(keyResultIndex, keyResultVal, true);
			}
		}
	};

	useEffect(() => {
		if (closeKeyResult[ObjectiveIndex]) {
			setIsCollapse(false);
			const tempState = _.cloneDeep(objective);
			tempState[ObjectiveIndex] = false;
			setCloseKeyResult(tempState);
		}
	}, [closeKeyResult]);

	// useDrag - the list item is draggable
	const [{ isDragging }, dragSource, dragPreview] = useDrag({
		type: `key${ObjectiveIndex}`,
		item: { keyResultIndex },
		end: async () => {
			const payload = _.cloneDeep(objective);
			const refinedObjective = {
				...objective,
				KeyResults: payload.KeyResults?.map(keyResult => ({
					...keyResult,
					hovered: undefined,
					expanded: undefined,
				})),
				hovered: undefined,
				expanded: undefined,
			};
			_.map(payload.KeyResults, (keyResult, index) => {
				onSetKeyResultVariable(objective, index, 'expanded', false);
				return keyResult;
			});
			const tempState = _.cloneDeep(objective);
			tempState[ObjectiveIndex] = true;
			setCloseKeyResult(tempState);
			await okrDataLoader.update(objective.id, refinedObjective);
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});
	// useDrop - the list item is also a drop area
	const [spec, dropRef] = useDrop({
		accept: `key${ObjectiveIndex}`,
		hover: (item, monitor) => {
			const dragIndex = item.keyResultIndex;
			const hoverIndex = keyResultIndex;
			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;
			// if dragging down, continue only when hover is smaller than middle Y
			if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
			// if dragging up, continue only when hover is bigger than middle Y
			if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;
			moveKeyListItem(dragIndex, hoverIndex, ObjectiveIndex);
			item.keyResultIndex = hoverIndex;
		},
	});
	// Join the 2 refs together into one (both draggable and can be dropped on)
	const ref = useRef(null);
	const keyDragDropRef = dragPreview(dropRef(ref));

	return (
		<div ref={keyDragDropRef}>
			<div
				ref={myRef}
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div onMouseLeave={onMouseLeave} onMouseOver={onMouseOver}>
					<Stack alignItems="center" direction="row" mb="15px" position="relative">
						{!!loggedInUser?.permission?.OKR?.Edit && (
							<span
								ref={dragSource}
								style={{
									position: 'absolute',
									left: '25px',
									top: '50%',
									transform: 'translateY(-50%)',
									zIndex: '1',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<DragIndicatorIcon
									sx={{
										width: '15px',
										height: '20px',
										color: 'grey',
										opacity: '0.5',
										'&:hover': {
											opacity: '0.8',
										},
									}}
								/>
							</span>
						)}
						<IconContainerOKR>
							<IconBackgroundWhite
								onClick={() => {
									setIsCollapse(!isCollapse);
									getComponentTop();
									onSetKeyResultVariable(objective, keyResultIndex, 'expanded', !isCollapse);
								}}
							>
								{isCollapse ? (
									<RemoveIcon
										sx={{
											color: '#4D79EA',
											cursor: 'pointer',
											transition: 'all 0.5s ease',
										}}
									/>
								) : (
									<AddIcon
										sx={{
											color: '#4D79EA',
											cursor: 'pointer',
											transition: 'all 0.5s ease',
										}}
									/>
								)}
							</IconBackgroundWhite>
						</IconContainerOKR>
						<GeneralResultBody
							sx={{
								width: '100%',
								height: { md: '53px', sm: 'auto' },
								background: '#FFF',
								boxShadow: '0px 0px 20px rgba(33, 88, 119, 0.1)',
								borderRadius: '10px',
								display: 'flex',
								flexDirection: { md: 'row', sm: 'column' },
								marginLeft: '10px',
							}}
						>
							<div
								style={{
									width: '80%',
									display: 'flex',
									alignItems: 'center',
									overflow: 'hidden',
									height: 'inherit',
								}}
							>
								<Typography
									ml="25px"
									mt="-1px"
									sx={{
										fontSize: '14px',
										fontWeight: 600,
										fontStyle: 'Inter',
										color: 'rgba(0, 0, 0, 0.4)',
									}}
								>
									{data?.KeyResultNumber}
								</Typography>
								{isInput ? (
									<form onBlur={handleSubmitOnBlur} onSubmit={handleKeySubmit}>
										<BoxObjectiveOKR ml="5px">
											<TextFieldObjectiveOKR
												autoFocus
												data-testid={`krInput${
													data?.KeyResultNumber ? data.KeyResultNumber : ''
												}`}
												id="keyResult"
												value={value}
												onChange={e => handleOnChangeText(e)}
												onKeyDown={e => {
													if (e.key === 'Enter' && !e.target.value) {
														e.preventDefault();
													}
												}}
											/>
											<button ref={submitRef} style={{ display: 'none' }} type="submit" />
											{data?.KeyResult && (
												<div className="menu-btn">
													<IconButton
														className="icon-btn"
														sx={{
															padding: '8px 0px 8px 0px',
														}}
														onMouseDown={handleKeySubmit}
													>
														<CheckIcon
															className="icon"
															fontSize="25px"
															sx={{
																color: '#4D79EA',
															}}
														/>
													</IconButton>
													<IconButton
														className="icon-btn"
														sx={{
															padding: '7px 2px 5px 2px',
														}}
														type="button"
													>
														<CloseIcon
															className="icon"
															fontSize="25px"
															sx={{
																color: 'rgba(0, 0, 0, 0.4)',
															}}
															onMouseDown={e => {
																toggleInput(false);
																if (data?.KeyResult) {
																	setValue(data?.KeyResult);
																} else {
																	handleOnChangeText(e, true);
																}
															}}
														/>
													</IconButton>
												</div>
											)}
										</BoxObjectiveOKR>
									</form>
								) : (
									<Typography
										ml="10px"
										sx={{
											width: '100%',
											display: 'flex',
											alignItems: 'center',
											height: 'inherit',
											overflow: 'auto',
											'&::-webkit-scrollbar-thumb': {
												background: '#C4C4C4 !important',
												borderRadius: '3px',
											},
											'&::-webkit-scrollbar-track': {
												background: '#FFF !important',
											},
										}}
										onClick={handleEmptyContainerClick}
									>
										<Typography
											sx={{
												fontSize: '14px',
												fontWeight: 500,
												fontStyle: 'Inter',
												margin: 'auto 0',
											}}
										>
											{data?.KeyResult}
										</Typography>
									</Typography>
								)}
								{!isInput && !!loggedInUser?.permission?.OKR?.Edit && (
									<IconButton
										sx={{ width: '30px' }}
										type="button"
										onClick={handleEmptyContainerClick}
									>
										{isActive && hoveredIndex === displayDelete && <CreateOutlinedIcon />}
									</IconButton>
								)}
							</div>

							<div
								style={{
									background: statusBgColor,
									display: 'flex',
									justifyContent: 'center',
									borderRadius: '17px',
									height: '27px',
									minWidth: '90px',
									marginLeft: '5px',
									marginRight: '15px',
								}}
							>
								<Select
									IconComponent=""
									MenuProps={{
										PaperProps: {
											sx: {
												borderRadius: '8px',
												width: '102px',
												justifyContent: 'center',
												border: '1px solid #4D79EA',
											},
										},
									}}
									class="notchedOutline"
									disabled={!loggedInUser?.permission?.OKR?.Edit}
									name="status"
									sx={{
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											border: 'none',
										},
										'& .MuiOutlinedInput-notchedOutline': {
											border: 'none',
										},
										'& .MuiSelect-outlined': {
											paddingRight: '16px !important',
										},
										'& .Mui-selected': {
											backgroundColor: 'transparent',
										},
										'& .MuiSelect-icon.MuiSelect-iconOutlined.css-3qbkez-MuiSelect-icon': {
											display: 'none',
										},
										'& .MuiInputBase-input.Mui-disabled': {
											WebkitTextFillColor: statusfontColor,
										},
										justifyContent: 'center',
										fontWeight: '500',
										fontSize: '12px',
										color: statusfontColor,
									}}
									value={data?.Status || ''}
									onChange={handleStatusChange}
								>
									<MenuItemOnTrack value="ON TRACK">ON TRACK</MenuItemOnTrack>
									<MenuItemOffTrack value="OFF TRACK">OFF TRACK</MenuItemOffTrack>
									<MenuItemDelayed value="DELAYED">DELAYED</MenuItemDelayed>
									<MenuItemAcrhived value="ARCHIVED">ARCHIVED</MenuItemAcrhived>
								</Select>
							</div>

							<div
								style={{
									width: '28px',
									height: '22px',
									background: data.Owner ? '#FFDBF3' : '',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									padding: data.Owner ? '5px' : '2px',
									borderRadius: '50%',
									cursor: loggedInUser?.permission?.OKR?.Edit ? 'pointer' : '',
								}}
								onClick={() => {
									if (loggedInUser?.permission?.OKR?.Edit) {
										setIsAssignerMenuOpen(!isAssignerMenuOpen);
									}
								}}
							>
								{data.Owner ? (
									<div
										style={{
											color: '#D349A4',
											fontWeight: 'bold',
											fontSize: '12px',
										}}
									>
										{data.Owner.split(' ')
											.map(n => n.charAt(0).toUpperCase())
											.join('')}
									</div>
								) : (
									<AccountCircle fontSize="large" sx={{ color: 'rgba(0, 0, 0, 0.4)' }} />
								)}
							</div>
							{isAssignerMenuOpen && (
								<ClickAwayListener onClickAway={() => setIsAssignerMenuOpen(false)}>
									<div>
										<WrapperStack>
											<AutocompleteAssigner
												freeSolo
												id="assigner"
												options={_.map(users, user => user.username)}
												size="small"
												value={data.Owner}
												onChange={(e, val) => handleKeyResultsUserChange(val)}
											/>
										</WrapperStack>
									</div>
								</ClickAwayListener>
							)}
							<IconButton
								data-testid="subQuarterCardDeleteItem"
								disabled={!loggedInUser?.permission?.OKR?.Delete}
								sx={{
									padding: '7px 5px 5px 5px',
									width: '30px',
								}}
								type="button"
								onClick={e => {
									handleOnChangeText(e, true);
									setIsCollapse(false);
									onMouseLeave();
								}}
							>
								{isActive &&
									hoveredIndex === displayDelete &&
									!!loggedInUser?.permission?.OKR?.Delete && <DeleteOutlineIcon />}
							</IconButton>
						</GeneralResultBody>
					</Stack>
					{isCollapse &&
						isActive &&
						displayAdd.includes(hoveredIndex) &&
						!!loggedInUser?.permission?.OKR?.Edit && (
							<Stack
								alignItems="normal"
								direction="column"
								mb="20px"
								ml="35px"
								onMouseLeave={() => setHoveredIndex(-1)}
								onMouseOver={() => setHoveredIndex(-2)}
							>
								<div
									style={{
										alignItems: 'center',
										color: 'rgba(0, 0, 0, 0.4)',
										fontWeight: '400',
									}}
								>
									<div
										style={{
											cursor: 'pointer',
											display: 'flex',
										}}
										onClick={() => handleAddIdea(data)}
									>
										<AddIcon fontSize="12px" sx={{ marginRight: '5px' }} />
										<span style={{ fontSize: '14px' }}>New Idea</span>
									</div>
								</div>
							</Stack>
						)}
				</div>
				{isCollapse && (
					<Stack alignItems="normal" direction="column" ml="0px">
						<AccordionContainer
							ObjectiveIndex={ObjectiveIndex}
							dataSet={data?.Ideas || []}
							getStyleByStatus={getStyleByStatus}
							hoveredIndex={hoveredIndex}
							keyResultIndex={keyResultIndex}
							moveIdeaListItem={moveIdeaListItem}
							objective={objective}
							selectedOwners={selectedOwners}
							setHoveredIndex={setHoveredIndex}
							updateData={updateData}
							onEditIdea={(ideaIndex, ideaValue, isRemove) =>
								onEditIdea(keyResultIndex, ideaIndex, ideaValue, isRemove)
							}
							onEditIdeaOwner={handleEditIdeaOwner}
							onEditIdeaStatus={(ideaIndex, ideaStsValue) =>
								onEditIdeaStatus(keyResultIndex, ideaIndex, ideaStsValue)
							}
							onSetKeyResultVariable={onSetKeyResultVariable}
						/>
					</Stack>
				)}
			</div>
		</div>
	);
}

export default QuarterCard;
