import TextField from '@mui/material/TextField';
import React from 'react';

const GoogleAutocomplete = ({ model, formField, disabled, variant, onChange, className, customModelPath }) => {
	const getDefaultValue = () => {
		const value = formField.mapping.split('.').reduce((p, c) => (p && p[c]) || null, model);

		if (formField.getLabel) {
			return formField.getLabel(value);
		}

		return value;
	};

	let autocomplete;

	const fillInAddress = () => {
		const place = autocomplete.getPlace();
		let address1 = '';
		let postcode = '';
		let locality = '';
		let state = '';
		let country = '';

		for (const component of place.address_components) {
			const componentType = component.types[0];

			switch (componentType) {
				case 'street_number': {
					address1 = `${component.long_name} ${address1}`;
					break;
				}

				case 'route': {
					address1 += component.short_name;
					break;
				}

				case 'postal_code': {
					postcode = `${component.long_name}${postcode}`;
					break;
				}

				case 'postal_code_suffix': {
					postcode = `${postcode}-${component.long_name}`;
					break;
				}

				case 'locality':
					locality = component.long_name;
					break;

				case 'administrative_area_level_1': {
					state = component.short_name;
					break;
				}

				case 'country':
					country = component.long_name;
					break;
			}
		}

		const address = {
			line: [address1],
			city: locality,
			state,
			postalCode: postcode,
			country,
		};

		return onChange(address, address, customModelPath || 'address.0');
	};

	const id = formField.id ? formField.id : `form-field-${formField.name}`;

	return (
		<TextField
			key={`${formField.mapping}-field`}
			ref={ref => {
				if (!autocomplete) {
					autocomplete = new window.google.maps.places.Autocomplete(ref.childNodes[1].firstChild, {
						fields: ['address_components', 'geometry'],
						types: ['address'],
					});

					autocomplete.addListener('place_changed', fillInAddress);
				}
			}}
			fullWidth
			InputLabelProps={{ shrink: true }}
			className={className}
			data-testid="form-field"
			defaultValue={getDefaultValue()}
			disabled={disabled || formField.disabled}
			id={id}
			label={formField.name}
			placeholder={disabled ? null : formField.placeholder}
			required={formField.required}
			variant={variant || 'outlined'}
		/>
	);
};

export default GoogleAutocomplete;
