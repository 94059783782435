/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
import organizationSchema from './schemata/organizationSchema';
/************************************************************************************************************************
Resource: Organization
Reference: https://www.hl7.org/fhir/Organization.html


This resource may be used in a shared registry of contact and other information for various organizations or it can be used
merely as a support for other resources that need to reference organizations, perhaps as a document, message or as a contained
resource.

If using a registry approach, it's entirely possible for multiple registries to exist, each dealing with different types
or levels of organization. 
************************************************************************************************************************/

export default class OrganizationResource extends FhirDomainResource {
	identifier = [];
	active;
	type = [];
	name;
	alias = [];
	telecom = [];
	address = [];
	partOf;
	contact = [{}];
	endpoint = [];

	constructor(resourceString) {
		super(resourceString, organizationSchema);
		this.resourceType = 'Organization';
		this.populateFields();
	}
}
