import React from 'react';
import { Select, MenuItem, Icon, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((_, { icon }) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		position: 'relative',
		'& .MuiOutlinedInput-root': {
			backgroundColor: '#444444',
			fontSize: '14px',
			height: 42,
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderWidth: '1px !important',
			borderColor: 'rgba(255, 255, 255, 0.1) !important',
		},
	},
	lightContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		position: 'relative',
		'& .MuiOutlinedInput-root': {
			fontSize: '14px',
			height: 42,
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderWidth: '1px !important',
			borderColor: 'rgba(255, 255, 255, 0.1) !important',
		},
	},
	menu: {
		transform: 'translateY(-10px) !important',
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		backgroundColor: '#444444',
		boxShadow: 'none',
		border: '1px solid rgba(255, 255, 255, 0.1)',
		borderTop: 'none',
		boxSizing: 'border-box',
		maxHeight: '250px',
	},
	lightMenu: {
		transform: 'translateY(-10px) !important',
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		boxShadow: 'none',
		border: '1px solid rgba(255, 255, 255, 0.1)',
		borderTop: 'none',
		boxSizing: 'border-box',
		maxHeight: '250px',
	},
	additionalItemValue: {
		marginLeft: 10,
		opacity: '0.6',
	},
	selectItem: {
		fontSize: '14px',
		padding: '6px 8px',
	},
	label: {
		fontSize: '14px',
		color: 'rgba(255, 255, 255, 0.8)',
		marginRight: 10,
	},
	select: {
		padding: '8px',
		paddingRight: '23px',
		paddingLeft: icon ? '25px' : '8px',
	},
	selectIcon: {
		right: 0,
	},
}));

const Dropdown = ({
	value,
	options,
	onChange,
	placeholder,
	label,
	className = '',
	classKey,
	menuKey,
	dropdownStyle = {},
	menuClassName = '',
	selectClassName = '',
	selectIconClassName = '',
	icon,
	iconStyles = {},
}) => {
	const { classes } = useStyles({ icon: !!icon });

	return (
		<div className={[classes[classKey || 'container'], className].join(' ')}>
			{label && <Typography className={classes.label}>{label}</Typography>}
			{icon && (
				<Icon
					component={icon}
					sx={{
						width: 'auto',
						height: 'auto',
						position: 'absolute',
						left: '8px',
						top: '50%',
						transform: 'translateY(-50%)',
						zIndex: 10,
						...iconStyles,
					}}
				/>
			)}
			<Select
				displayEmpty
				MenuProps={{
					classes: {
						paper: [classes[menuKey || 'menu'], menuClassName].join(' '),
					},
					sx: dropdownStyle,
				}}
				classes={{
					select: [classes.select, selectClassName].join(' '),
					icon: [classes.selectIcon, selectIconClassName].join(' '),
				}}
				data-testid="dropdown-select"
				renderValue={() => (value ? options.find(i => i.value === value)?.label : placeholder)}
				size="small"
				sx={dropdownStyle}
				value={value}
				onChange={e => onChange(e.target.value)}
			>
				{options.map((option, i) => (
					<MenuItem
						key={option.value}
						className={classes.selectItem}
						data-testid={`option-${i}`}
						value={option.value}
					>
						{option.label}
						{option.additionalValue && (
							<span className={classes.additionalItemValue}>{option.additionalValue}</span>
						)}
					</MenuItem>
				))}
			</Select>
		</div>
	);
};

export default Dropdown;
