import _getHash from '@cornerstonejs/tools/dist/esm/drawingSvg/_getHash';
import setNewAttributesIfValid from '@cornerstonejs/tools/dist/esm/drawingSvg/setNewAttributesIfValid';

const drawCircle = (svgDrawingHelper, annotationUID, circleUID, center, radius, canvas, dataId = '', done) => {
	const svgns = 'http://www.w3.org/2000/svg';
	const svgNodeHash = _getHash(annotationUID, 'circle', circleUID);
	const existingCircleElement = svgDrawingHelper.getSvgNode(svgNodeHash);

	const circlePath = `
	M -1 -1
	h ${canvas.offsetWidth + 2}
	v ${canvas.offsetHeight + 2}
	h -${canvas.offsetWidth + 2}
	z
	M ${center[0] + radius} ${center[1]}
	A ${radius} ${radius} 0 1 0 ${center[0] + radius} ${center[1] + 1}
	`;

	const attributes = {
		d: circlePath,
		fill: done ? 'black' : 'transparent',
		'fill-rule': 'evenodd',
		stroke: '#fff',
	};

	if (existingCircleElement) {
		setNewAttributesIfValid(attributes, existingCircleElement);

		svgDrawingHelper.setNodeTouched(svgNodeHash);
	} else {
		const newCircleElement = document.createElementNS(svgns, 'path');

		if (dataId !== '') {
			newCircleElement.setAttribute('data-id', dataId);
		}

		setNewAttributesIfValid(attributes, newCircleElement);

		svgDrawingHelper.prependNode(newCircleElement, svgNodeHash);
	}
};

export { drawCircle };
