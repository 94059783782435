const FAX_STATUSES = {
	success: 'success',
	toNewOrder: 'to new order',
	processing: 'processing',
	transmitting: 'transmitting',
	failed: 'failed',
	attachedWithPatient: 'attached with patient',
	attachedWithStudy: 'attached with study',
};

const FAX_DIRECTIONS = {
	inbound: 'inbound',
	outbound: 'outbound',
};

const FAXLOG_READONLY_STATUSES = Object.values(FAX_STATUSES).filter(status => status !== FAX_STATUSES.success);

const faxIsValidToDisplayPDF = fax => {
	const hasValidStatus = fax.status !== FAX_STATUSES.processing;
	const hasDownloadUrl = fax.extension.find(ex => ex.url === 'http://www.ramsoft.com/fhir/extension/downloadUrl');
	return Boolean(hasValidStatus && hasDownloadUrl);
};

const faxIsReadOnly = fax => {
	const hasReadOnlyStatus = FAXLOG_READONLY_STATUSES.includes(fax.status);
	const isOutboundFax = fax.faxDirection === FAX_DIRECTIONS.outbound;
	return Boolean(hasReadOnlyStatus || isOutboundFax);
};

module.exports = {
	faxIsValidToDisplayPDF,
	faxIsReadOnly,
	FAX_STATUSES,
	FAX_DIRECTIONS,
	FAXLOG_READONLY_STATUSES,
};
