import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import MenuItems from './MenuItems';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ListItemText from '@mui/material/ListItemText';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';

const SuperSingleSelectPopper = styled(Popper, {
	name: 'SuperSingleSelect',
	slot: 'Popper',
})(({ theme }) => ({
	zIndex: theme.zIndex.modal,
}));

const SuperSingleSelect = ({
	data,
	size,
	label,
	width,
	disabled,
	selected,
	defaultWorklist,
	onChange,
	onDelete,
	onEdit,
	onDefault,
	onAdd,
	testId,
	loading,
	error,
	helperText,
	onClickInput,
	formValue,
	customDropDown,
	onClickAway,
	forceCloseMenu,
	disableSettingTextOnWorklistNameChange,
	disableSettingTextOnWorklistDelete,
}) => {
	const { t } = useTranslation('workList');

	const [textFieldValue, setTextFieldValue] = useState('');
	const [filterData, setFilterData] = useState([]);
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const prevOpen = useRef(open);
	const selectedItemId = selected?.id;
	const defaultWorklistName = defaultWorklist;
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
		setOpen(prevOpenItem => !prevOpenItem);
	};

	const handleOnAdd = () => {
		onAdd(textFieldValue);
	};

	const handleOnClick = event => {
		if (loading) {
			return;
		}
		setFilterData(data);
		setAnchorEl(event.currentTarget);
		setOpen(true);
		if (onClickInput) {
			onClickInput({
				clearError: true,
				key: data?.length > 0 && data[0].hasOwnProperty('role') ? 'roleWorklistName' : 'name',
			});
		}
	};

	const handleSelect = selectedItem => {
		setTextFieldValue(selectedItem.name);
		setOpen(false);
		if (onChange) {
			onChange(selectedItem);
		}
	};

	const handleClose = () => {
		if (onClickAway) {
			onClickAway();
		}
		setOpen(false);
	};
	const handleFilter = event => {
		const searchValue = event.target.value;
		// Collect all items which fully or partially match with search item.
		// Collected items will be visible in dropdown menu.
		const newfilter = data.filter(value => value.name.toLowerCase().includes(searchValue.toLowerCase()));
		// find the item which exactly matches with search item.
		// if matched, then call onChange with the matched item else call onChange with empty string
		// Before calling onChange, check if the form data already stores the matched item for the given key
		// if already stored, then don't call onChange, else call onChange.
		const validSearchValue = _.find(newfilter, item => item?.name?.toLowerCase() === searchValue?.toLowerCase());
		if (searchValue?.trim() === '') {
			setFilterData([]);
			setTextFieldValue('');
			setOpen(false);
			onChange && formValue?.name !== '' && onChange('');
		} else {
			handleClick(event);
			setOpen(true);
			setFilterData(newfilter);
			setTextFieldValue(searchValue);
			onChange && !!validSearchValue && formValue?.name !== validSearchValue?.name
				? onChange(validSearchValue)
				: onChange && formValue?.name !== '' && onChange('');
		}
	};

	const inputRef = useRef();
	useEffect(() => {
		inputRef.current.value = textFieldValue;
		if (prevOpen.current === true) {
			inputRef.current.focus();
		}
		prevOpen.current = open;
		// When menu closes, replace input(if there is any) by text value.
		const editTrueIndex = _.findIndex(data, item => !!item.edit);
		// If state changes, update the data.
		if (editTrueIndex >= 0) {
			data[editTrueIndex].edit = false;
			setFilterData(data);
		}
	}, [open]);
	useEffect(() => {
		if (selected && selected?.name) {
			setTextFieldValue(selected?.name);
		}
	}, [selected, loading]);
	useEffect(() => {
		if (!selected && !selected?.name) {
			onChange('');
		}
	}, [data]);
	useEffect(() => {
		if (forceCloseMenu) {
			setOpen(false);
		}
	}, [forceCloseMenu]);

	const handleDelete = id => {
		onDelete(id);
		const newList = filterData.filter(item => item.value !== id);
		setFilterData(newList);
		if (!disableSettingTextOnWorklistDelete) {
			setTextFieldValue(data[0].name);
		}
	};
	const setWorklistName = (id, name) => {
		if (!disableSettingTextOnWorklistNameChange || selected?.id === id) {
			setTextFieldValue(name.toUpperCase());
		}
		// filter worklists
		return filterData.map(item => {
			if (item.value === id) {
				item.edit = false;
				item.name = name.toUpperCase();
			}
			return item;
		});
	};
	const handleDefault = (id, name) => {
		const newFilterWorklist = setWorklistName(id, name);
		setFilterData(newFilterWorklist);
		onDefault(id, name);
	};
	const handleEditClick = (evt, option) => {
		const newfilterData = filterData.map(item => {
			if (item.value === option.value) {
				item.edit = true;
			} else {
				item.edit = false;
			}
			return item;
		});
		setFilterData(newfilterData);
	};

	const handleUpdate = (event, id, name) => {
		const newFilterWorklist = setWorklistName(id, name);
		setFilterData(newFilterWorklist);
		onEdit(id, name);
	};
	return (
		<div className="SuperSearch">
			<TextField
				ref={inputRef}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
					endAdornment: loading ? (
						<CircularProgress color="inherit" size={20} />
					) : (
						<IconButton edge="end">
							<ArrowDropDownIcon data-cy={`${testId}_expand`} />
						</IconButton>
					),
				}}
				data-cy={testId}
				defaultValue={selected ? textFieldValue : ''}
				disabled={disabled}
				error={error}
				helperText={helperText}
				label={label}
				placeholder={
					(!!selected && selected?.name) || (!!data && data?.length > 0) ? t('search') : t('noOptions')
				}
				role="presentation"
				sx={{
					width,
				}}
				type="text"
				value={selected ? textFieldValue : ''}
				onChange={handleFilter}
				onClick={handleOnClick}
			/>

			<SuperSingleSelectPopper
				disablePortal
				transition
				anchorEl={inputRef.current}
				open={open}
				placement="bottom-start"
				role={undefined}
				style={{
					width,
					height: 200,
					overflow: 'auto',
				}}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								{customDropDown ? (
									customDropDown()
								) : (
									<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
										{onAdd ? (
											<>
												<Button
													startIcon={<AddIcon sx={{ color: '#42A5F5', ml: '14px' }} />}
													sx={{
														'& .MuiButton-startIcon': {},
														justifyContent: 'left',
														height: '48px',
														width: '100%',
													}}
													onMouseDown={handleOnAdd}
												>
													<Typography
														sx={{
															lineHeight: '18px',
															fontSize: '16px',
															color: '#42A5F5',
															ml: '8px',
														}}
													>
														{t('workListDrawer.addNew')}
													</Typography>
												</Button>
												<Divider />
											</>
										) : null}
										<MenuList
											dense
											disablePadding
											aria-labelledby="composition-button"
											autoFocus={false}
											autoFocusItem={false}
											data-testid="worklist-dropdown-menu"
											id="composition-menu"
										>
											{loading && <MenuItem>Loading...</MenuItem>}
											{!loading && filterData.length > 0 && (
												<MenuItems
													isDefault={defaultWorklistName}
													optionList={filterData}
													selectedItemId={selectedItemId}
													t={t}
													onAdd={handleOnAdd}
													onDefault={onDefault ? handleDefault : null}
													onDelete={handleDelete}
													onEdit={handleEditClick}
													onSelect={handleSelect}
													onUpdate={handleUpdate}
												/>
											)}
											{!loading && filterData.length <= 0 && (
												<MenuItem>
													<ListItemText
														primary={t('noOptions')}
														sx={{
															py: '6px',
															opacity: 0.6,
														}}
													/>
												</MenuItem>
											)}
										</MenuList>
									</Box>
								)}
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</SuperSingleSelectPopper>
		</div>
	);
};

SuperSingleSelect.defaultProps = {
	size: 'medium',
	data: [],
	width: 200,
	fullWidth: false,
	columnId: 0,
	disabled: false,
	loading: false,
};

SuperSingleSelect.propTypes = {
	/**
	 * Array of data for search and select.
	 */
	data: PropTypes.array,

	/**
	 * Size of the element
	 */
	size: PropTypes.oneOf(['small', 'medium']),

	/**
	 * Label of the element
	 */
	label: PropTypes.string,

	/**
	 * Width of the element
	 */
	width: PropTypes.number,

	/**
	 * Callback function when an item is selected
	 */
	onChange: PropTypes.func,
	/**
	 * enable and disable the component
	 */
	disabled: PropTypes.bool,
	/**
	 * Callback function to delete the worklist
	 */
	onDelete: PropTypes.func,

	/**
	 * Callback function to Edit worklist name
	 */
	onEdit: PropTypes.func,
	/**
	 * When false, then all data are loaded in dropdown menu, else data are still loading
	 */
	loading: PropTypes.bool,

	/**
	 * Form validation error for SuperSingleSelect.
	 */
	error: PropTypes.bool,

	/**
	 * Form validation error message for SuperSingleSelect.
	 */
	helperText: PropTypes.string,

	/**
	 * Callback function for onClick event on Textfield.
	 */
	onClickInput: PropTypes.func,

	/**
	 * Form data from useForm hook.
	 */
	formValue: PropTypes.object,
};

export default SuperSingleSelect;
