const priorAuthorizationSchema = {
	id: '__string__',
	resourceType: '__string__',
	status: '__string__',
	payerName: '__string__',
	orderId: '__string__',
	studyId: '__string__',
	patientId: '__string__',
	coverageId: '__string__',
	caseNumber: '__string__',
	submissionDate: '__string__',
	evaluationDate: '__string__',
	decisionDate: '__string__',
	authorizationDate: '__string__',
	managingorganizationid: '__string__',
};

export default priorAuthorizationSchema;
