/* Generated automagically by FhirStarter - DO NOT EDIT */
const auditEventSchema = {
	resourceType: 'AuditEvent',
	type: '__Coding__',
	subtype: ['__Coding__'],
	action: '__code__',
	period: '__Period__',
	recorded: '__instant__',
	outcome: '__code__',
	outcomeDesc: '__string__',
	purposeOfEvent: ['__CodeableConcept__'],
	agent: [
		{
			type: '__CodeableConcept__',
			role: ['__CodeableConcept__'],
			who: '__Reference(PractitionerRole|Practitioner|Organization|Device|Patient|RelatedPerson)__',
			altId: '__string__',
			name: '__string__',
			requestor: '__boolean__',
			location: '__Reference(Location)__',
			policy: ['__uri__'],
			media: '__Coding__',
			network: {
				address: '__string__',
				type: '__code__',
			},
			purposeOfUse: ['__CodeableConcept__'],
		},
	],
	source: {
		site: '__string__',
		observer: '__Reference(PractitionerRole|Practitioner|Organization|Device|Patient|RelatedPerson)__',
		type: ['__Coding__'],
	},
	entity: [
		{
			what: '__Reference(Any)__',
			type: '__Coding__',
			role: '__Coding__',
			lifecycle: '__Coding__',
			securityLabel: ['__Coding__'],
			name: '__string__',
			description: '__string__',
			query: '__base64Binary__',
			detail: [
				{
					type: '__string__',
					valueString: '__string__',
					valueBase64Binary: '__base64Binary__',
				},
			],
		},
	],
};
export default auditEventSchema;
