/**
 * Mapping data from API to tree data
 * @param {*} datas
 * @returns
 */
export const createTreeDataMapping = (datas, userId, searchText, selectedFolderId, stateToSync) => {
	// Sample data - will repalce with actual data
	let treeDatas = [];
	if (datas && datas.length > 0) {
		datas.map(data => {
			// Parent Id
			const partOfId = data.partOfId;
			data.isOpen = false;

			// Open for parent of selected folder
			if (data.id === selectedFolderId) {
				data.isOpen = true;
				if (data.path) {
					const parentIds = data.path.split('/');
					parentIds.forEach(pId => {
						const parentNode = searchDFS({
							data: treeDatas,
							cond: item => item.id === pId,
						});
						if (parentNode.item) {
							parentNode.item.isOpen = true;
						}
					});
				}
			}

			// Create nodes
			if (partOfId) {
				// Child folder
				const node = searchDFS({
					data: treeDatas,
					cond: item => item.id === partOfId,
				});
				if (node && node.item) {
					if (!node.item.files) {
						node.item.files = [];
					}
					node.item.files.push(
						createFolder({
							id: data.id,
							name: data.folderName,
							studies: data.studies,
							level: data.level,
							isOpen: data.isOpen,
							canEdit: userId ? hasEditPrivilege(userId, data) : false,
						})
					);
				} else {
					// Root folder
					treeDatas.push(
						createFolder({
							id: data.id,
							name: data.folderName,
							studies: data.studies,
							isOpen: data.isOpen,
							canEdit: userId ? hasEditPrivilege(userId, data) : false,
						})
					);
				}
			} else {
				// Root folder
				treeDatas.push(
					createFolder({
						id: data.id,
						name: data.folderName,
						studies: data.studies,
						isOpen: data.isOpen,
						canEdit: userId ? hasEditPrivilege(userId, data) : false,
					})
				);
			}
		});
	}

	// Search text
	if (searchText && searchText !== '') {
		treeDatas = searchFolderBySearchText(treeDatas, searchText);
	}

	// Sync state
	if (stateToSync && stateToSync.length > 0) {
		treeDatas = syncFolderStates(treeDatas, stateToSync);
	}
	return treeDatas;
};

/**
 * Search node
 * @param {*} param0
 * @returns
 */
export const searchDFS = ({ data, cond, childPathKey = 'files' }) => {
	let final = null;
	let parentPath = [];
	let parent = null;
	let next = null;
	let prev = null;

	const recursiveFind = tree => {
		tree?.forEach((item, index) => {
			if (cond(item, index)) {
				final = item;

				if (parentPath) {
					parentPath.forEach(p => {
						// check if parent has the `current item`
						if (p && p[childPathKey].includes(item)) {
							parent = p;
							// set next & previous indexes
							next = p[childPathKey][index + 1];
							prev = p[childPathKey][index - 1];
						} else {
							// if parent is null then check the root of the tree
							next = tree[index + 1];
							prev = tree[index - 1];
						}
					});
				}
				return;
			}
			if (item[childPathKey]) {
				// push parent stack
				parentPath.push(item);
				recursiveFind(item[childPathKey]);
			}
		});
	};

	recursiveFind(data);
	return {
		parent,
		item: final,
		nextSibling: next,
		previousSibling: prev,
	};
};

/**
 * Validation before save folder
 * @param {*} treeDatas
 * @param {*} folderId
 * @param {*} folderName
 * @returns
 */
export const validateFolderName = (treeDatas, folderId, folderName) => {
	if (!folderName || folderName.trim() === '' || !folderId || folderId === '') return 1;

	return 0;
};

/**
 * searchFolderBySearchText
 * @param {*} treeDatas
 * @param {*} searchText
 * @returns
 */
export const searchFolderBySearchText = (treeDatas, searchText) => {
	treeDatas?.map(folder => {
		if (folder.name.toLowerCase().includes(searchText.toLowerCase())) {
			folder.isShow = true;
		} else {
			folder.isShow = false;
		}
		if (folder.files) {
			let childTrees = searchFolderBySearchText(folder.files, searchText);
			let isChildShow = childTrees?.find(item => item.isShow === true);
			if (isChildShow) {
				folder.isShow = true;
			}
		}
	});
	return treeDatas;
};

/**
 * Sort folder by name
 * @param {*} treeDatas
 * @returns
 */
export const sortFolderByName = treeDatas => {
	if (!treeDatas || treeDatas.length === 0) return treeDatas;
	treeDatas.sort((a, b) => {
		const lowerA = a.name?.toLowerCase();
		const lowerB = b.name?.toLowerCase();

		if (lowerA === lowerB) {
			return a.name < b.name ? -1 : 1;
		} else {
			return lowerA < lowerB ? -1 : 1;
		}
	});
	treeDatas.forEach(folder => {
		if (folder.files) {
			folder.files = sortFolderByName(folder.files);
		}
	});
	return treeDatas;
};

export const createFolder = ({
	id,
	name,
	level = 1,
	studies = [],
	isNewFolder = false,
	canEdit = false,
	isShow = true,
	isOpen = false,
}) => ({
	id,
	name,
	type: 'folder',
	files: [],
	level,
	studies,
	isNewFolder,
	canEdit,
	isShow,
	isOpen,
});

/**
 * Check if user can edit privilege
 * @param {*} userId
 * @param {*} folderData
 * @returns
 */
export const hasEditPrivilege = (userId, folderData) => {
	// check if user is owner
	if (folderData && folderData.owner && folderData.owner.id === userId) {
		return true;
	}

	// check if user is admin
	if (folderData && folderData.accessControl?.user?.length > 0) {
		const user = folderData.accessControl.user.find(item => item.id === userId);
		if (user && user.privilege === 'admin') {
			return true;
		}
	}
	return false;
};

/**
 * syncFolderStates
 * @param {*} treeDatas
 * @param {*} treeState
 * @returns
 */
export const syncFolderStates = (treeDatas, treeState) => {
	if (!treeState || treeState.length === 0) {
		return treeDatas;
	}
	treeDatas?.map(folder => {
		const findFolder = treeState.find(item => item.id === folder.id);
		if (findFolder) {
			folder.isOpen = findFolder.isOpen;
		}
		if (folder.files && findFolder) {
			syncFolderStates(folder.files, findFolder.files);
		}
	});
	return treeDatas;
};
