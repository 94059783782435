import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import CallIcon from '@mui/icons-material/Call';
import { useLiveChatContext } from './LiveChatContext';
import { useCrmContext } from '../../../context/CRMContext';
import Notification from './Notification';
import { useIsMount } from '../../../hooks/useIsMount';

function LiveChatButton() {
	const [isDraggable, setIsDraggable] = useState(false);
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [prevPosition, setPrevPosition] = useState({ x: 0, y: 0 });
	const { unreadConvs, requestedConvs, isChatOpen, setIsChatOpen, incomingCall } = useLiveChatContext();
	const { showDetails, toggleDetails } = useCrmContext();
	const [intervalEvent, setIntervalEvent] = useState();
	const isMount = useIsMount();

	const windowWidth = window.innerWidth;
	const windowHeight = window.innerHeight;
	const defaultTitle = 'Breeze';
	const newMessage = 'You got new messages';

	useEffect(() => {
		let defaultPosition;
		if (unreadConvs.length || requestedConvs.length || incomingCall) {
			defaultPosition = { x: windowWidth - 455, y: windowHeight - 265 };
		} else {
			defaultPosition = { x: windowWidth - 150, y: windowHeight - 150 };
		}
		setPosition(defaultPosition);
		return () => clearAllInterval();
	}, []);

	useEffect(() => {
		if (!isMount && !isDraggable) {
			if (showDetails) {
				const newPosition = position.x - 628;
				setPrevPosition(position);
				setPosition(prev => ({ ...prev, x: newPosition > 0 ? newPosition : 0 }));
			} else {
				setPosition(prevPosition);
			}
		}
	}, [showDetails]);

	useEffect(() => {
		if (unreadConvs.length || requestedConvs.length || incomingCall) {
			setPosition(prev => ({
				x: prev.x > windowWidth - 455 ? windowWidth - 455 : prev.x,
				y: prev.y > windowHeight - 265 ? windowHeight - 265 : prev.y,
			}));
		}

		if (requestedConvs.length + unreadConvs.length > 0) {
			if (!intervalEvent) {
				const interval = setInterval(() => {
					document.title = document.title === newMessage ? defaultTitle : newMessage;
				}, 1000);
				setIntervalEvent(interval);
			}
		} else {
			clearAllInterval();
		}
	}, [unreadConvs, requestedConvs, incomingCall]);

	const clearAllInterval = () => {
		window.clearInterval(intervalEvent);
		setIntervalEvent(null);
		document.title = defaultTitle;
	};

	const handleDrag = (event, position) => {
		const { x, y } = position;

		toggleDetails();
		setIsDraggable(true);
		setPosition({ x, y });
	};

	const handleStop = () => {
		setTimeout(() => setIsDraggable(false), 100);
	};

	const handleClick = () => {
		!isDraggable && setIsChatOpen(true);
	};

	return (
		<Draggable position={position} onDrag={handleDrag} onStop={handleStop}>
			<div
				style={{
					position: 'fixed',
					zIndex: '25',
					height: 'fit-content',
					display: isChatOpen ? 'none' : 'flex',
					flexDirection: position.y < windowHeight / 2 ? 'column-reverse' : 'column',
					alignItems: position.x < windowWidth / 2 ? 'flex-start' : 'flex-end',
				}}
			>
				{
					// send details in props like name, call details
					incomingCall && <Notification isCall />
				}
				{unreadConvs.map(conv => (
					<Notification key={conv.name} conversation={conv} />
				))}
				{requestedConvs.map(conv => (
					<Notification key={conv.name} conversation={conv} />
				))}
				<button
					data-testid="phoneBtn"
					style={{
						width: '70px',
						height: '70px',
						margin: '10px 0',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						color: '#FFFFFF',
						backgroundColor: '#4D79EA',
						border: 'none',
						outline:
							(unreadConvs.length > 0 || requestedConvs.length > 0) &&
							'12px solid rgba(148, 185, 255, 0.33)',
						borderRadius: '50%',
						boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
						transform:
							(unreadConvs.length > 0 || requestedConvs.length > 0 || incomingCall) && 'rotate(-45deg)',
						transition: 'transform 0.2s ease-in-out',
						cursor: 'pointer',
					}}
					onClick={handleClick}
				>
					<CallIcon style={{ width: '36px', height: '36px' }} />
				</button>
			</div>
		</Draggable>
	);
}

export default LiveChatButton;
