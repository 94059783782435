/* Generated automagically by FhirStarter - but please feel free to edit this file */
import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';
import getExtensionValueString from '@rs-core/fhir/resource/columnMapping/utils/getExtensionValueString';
import convertArrayToString from '@rs-core/fhir/resource/columnMapping/utils/convertArrayToString';
import getExtensionValueCodingArrayConcat from '@rs-core/fhir/resource/columnMapping/utils/getExtensionValueCodingArrayConcat';
import getExtensionValueReference from '@rs-core/fhir/resource/columnMapping/utils/getExtensionValueAttachment';
import getExtensionValueCoding from '@rs-core/fhir/resource/columnMapping/utils/getExtensionValueCoding';

const ImagingStudyMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		searchParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.id;
		},
	},
	identifier: {
		label: 'Identifier',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.identifier;
		},
	},
	modality: {
		label: 'Modality',
		filterType: 'multi-select',
		searchParameter: 'modality',
		getDisplay: resource => {
			return convertArrayToString(resource?.modality, 'code');
		},
	},
	laterality: {
		label: 'Laterality',
		filterType: 'multi-select',
		getDisplay: resource => {
			return getExtensionValueCodingArrayConcat(resource, fhirExtensionUrls.common.laterality);
		},
	},
	bodyPart: {
		label: 'Body Part',
		options: { filter: false, sort: false },
		filterType: 'multi-select',
		searchParameter: 'bodyPart',
		getDisplay: resource => {
			return getExtensionValueCodingArrayConcat(resource, fhirExtensionUrls.common.bodyPart);
		},
	},
	duration: {
		label: 'duration',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return getExtensionValueString(resource, fhirExtensionUrls.common.appointmentDuration);
		},
	},
	anatomicFocus: {
		label: 'Anatomic Focus',
		options: { filter: false, sort: false },
		filterType: 'multi-select',
		searchParameter: 'anatomicFocus',
		getDisplay: resource => getExtensionValueCodingArrayConcat(resource, fhirExtensionUrls.common.anatomicFocus),
	},
	techniqueObject: {
		label: 'Technique Object',
		options: { filter: false, sort: false },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => {
			return resource?.techniqueObject;
		},
	},
	view: {
		label: 'View',
		options: { filter: false, sort: false },
		filterType: 'multi-select',
		searchParameter: 'view',
		getDisplay: resource => getExtensionValueCodingArrayConcat(resource, fhirExtensionUrls.common.view),
	},
	reasonForExam: {
		label: 'Reason For Exam',
		options: { filter: false, sort: false },
		filterType: 'multi-select',
		searchParameter: 'reasonForExam',
		getDisplay: resource => getExtensionValueCodingArrayConcat(resource, fhirExtensionUrls.common.reasonForExam),
	},
	performingTechnologist: {
		label: 'Performing Technologist',
		options: { filter: false, sort: false },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => {
			return resource?.performingTechnologist;
		},
	},
	performingPhysician: {
		label: 'Performing Physician',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'performingPhysician',
		getDisplay: resource => {
			return resource?.performingPhysician;
		},
	},
	readingFacility: {
		label: 'Reading Facility',
		options: { filter: false, sort: false },
		filterType: 'suggest',
		searchParameter: 'readingFacility',
		suggestScope: {
			resource: 'organization',
			label: 'name',
		},
		getDisplay: resource => {
			return resource?.readingFacility;
		},
	},
	readingPhysician: {
		label: 'Reading Physician',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'readingPhysician',
		getDisplay: resource => {
			return resource?.readingPhysician;
		},
	},
	transcriptionist: {
		label: 'Transcriptionist',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'transcriptionist',
		getDisplay: resource => {
			return resource?.transcriptionist;
		},
	},
	transcriptionistFacility: {
		label: 'Transcriptionist Facility',
		options: { filter: false, sort: false },
		filterType: 'suggest',
		searchParameter: 'transcriptionistFacility',
		suggestScope: {
			resource: 'organization',
			label: 'name',
		},
		getDisplay: resource => {
			return resource?.transcriptionistFacility;
		},
	},
	notes: {
		label: 'Notes',
		options: { filter: false, sort: false },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => {
			return resource?.notes;
		},
	},
	clinicalNotes: {
		label: 'Clinical Notes',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'clinical',
		getDisplay: resource => {
			return getExtensionValueString(resource, fhirExtensionUrls.imagingStudy.notes.clinical);
		},
	},
	pharmaceutical: {
		label: 'Pharmaceutical',
		options: { filter: false, sort: false },
		filterType: 'multi-select',
		searchParameter: 'pharmaceutical',
		getDisplay: resource => {
			return getExtensionValueCodingArrayConcat(resource, fhirExtensionUrls.common.pharmaceutical);
		},
	},
	encounter: {
		label: 'Encounter',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.encounter;
		},
	},
	studyDateTime: {
		label: 'Study Date/Time',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.started;
		},
	},
	basedOn: {
		label: 'Based On',
		options: { filter: false, sort: false },
		filterType: 'none',
		searchParameter: 'placerordernumber',
		getDisplay: resource => {
			return resource?.basedOn;
		},
	},
	requestedProcedureID: {
		label: 'Requested Procedure ID',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return getExtensionValueString(resource, fhirExtensionUrls.common.requestedProcedureID);
		},
	},
	reasonCode: {
		label: 'Reason Code',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return getExtensionValueCoding(resource, fhirExtensionUrls.common.reasonCode);
		},
	},
	description: {
		label: 'Description',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.description;
		},
	},
	accessionNumber: {
		label: 'Accession #',
		getDisplay: resource => {
			return resource?.identifier.find(
				elem => elem.type && elem.type.coding && elem.type.coding.some(item => item.code === 'ACSN')
			).value;
		},
	},
	patientName: {
		label: 'Patient Name',
		getDisplay: resource => {
			return resource?.subject;
		},
	},
	patientId: {
		label: 'Patient Name',
		getDisplay: resource => {
			return resource?.subject.id;
		},
	},
	managingOrganization: {
		label: 'Managing Organization',
		options: { filter: true, sort: true },
		filterType: 'suggest',
		suggestScope: {
			resource: 'organization',
			label: 'name',
		},
		getDisplay: resource => {
			return getExtensionValueReference(resource, fhirExtensionUrls.common.managingOrganization);
		},
	},
	imagingOrganization: {
		label: 'Imaging Organization',
		options: { filter: true, sort: true },
		filterType: 'suggest',
		suggestScope: {
			resource: 'organization',
			label: 'name',
		},
		getDisplay: resource => {
			return getExtensionValueReference(resource, fhirExtensionUrls.common.imagingOrganization).display;
		},
	},
	referrer: {
		label: 'Referring Physician',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'referrer',
		getDisplay: resource => {
			return resource?.referrer?.display;
		},
	},

	department: {
		label: 'Department',
		options: { filter: false, sort: false },
		filterType: 'suggest',
		searchParameter: 'department',
		suggestScope: {
			resource: 'organization',
			label: 'name',
		},
		getDisplay: resource => {
			return getExtensionValueReference(resource, fhirExtensionUrls.common.department);
		},
	},
	studyStatus: {
		label: 'Study Status',
		getDisplay: resource => {
			return getExtensionValueString(resource, fhirExtensionUrls.common.status);
		},
	},
	studyType: {
		label: 'Study Type',
		getDisplay: resource => {
			return getExtensionValueCoding(resource, fhirExtensionUrls.common.studyType);
		},
	},
	loincCode: {
		label: 'LOINC Code',
		getDisplay: resource => {
			return getExtensionValueCoding(resource, fhirExtensionUrls.common.loincCode);
		},
	},
	healthcareService: {
		label: 'Healthcare Service',
		options: { filter: true, sort: true },
		filterType: 'suggest',
		suggestScope: {
			resource: 'organization',
			label: 'name',
		},
		getDisplay: resource => {
			return getExtensionValueReference(resource, fhirExtensionUrls.common.healthcareService);
		},
	},
	studyID: {
		label: 'Study ID',
		getDisplay: resource => {
			return getExtensionValueString(resource, fhirExtensionUrls.imagingStudy.studyID);
		},
	},
};
export default ImagingStudyMapping;
