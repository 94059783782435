import { Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import styledComponent from 'styled-components';
import React from 'react';
import { navLinks } from './HelpCenter';

const StyledTabs = styled((props) => <Tabs {...props} TabIndicatorProps={{ children: null }} />)({
	'& .MuiTabs-flexContainer': {
		overflowX: 'auto',
	},
	'& .MuiTabs-indicator': {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
	},
	'& .MuiTabs-indicatorSpan': {
		maxWidth: 40,
		width: '100%',
		backgroundColor: '#635ee7',
	},
});

const StyledTabWrapper = styledComponent.div`
  box-shadow: -9px -5px 13px 1px #00000045;
  font-family: Roboto;
  .button-wrap {
    padding: 0px;
  }

  &:not(:first-child){
    margin-left: 0px;
  }

  .button-default {
    color: white;
    background-color: #393939;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 40px 10px 20px;
    display: inline-block;
    border-radius: 0px 3px 0px 0px;
    height: 28px;
  }

  .button-slanted {
    -ms-transform: skewX(43deg);
    -webkit-transform: skewX(43deg);
    transform: skewX(43deg);
    background-color: #393939;
    position: relative;
    box-shadow: 4px -4px 11px 2px #00000045;
  }

  .button-slanted--left {
    background-color: #333;
  }

  .button-slanted--right {
    margin-left: -4px;
  }

  .button-slanted--left:after, .button-slanted--right:after {
    content: "";
    position: absolute;
    width: 70px;
    height: 100%;
    background-color: #393939;
    position: absolute;
    top: 0;
    -ms-transform: skewX(-43deg);
    -webkit-transform: skewX(-43deg);
    transform: skewX(-43deg);
  }

  .button-slanted--left:after {
    border-radius: 5px 0 0 5px;
    left: -31px;
  }

  .button-slanted--right:after {
    border-radius: 0 30px 30px 0;
    right: -23px;
  }

  .button-slanted-content {
    -ms-transform: skewX(-43deg);
    -webkit-transform: skewX(-43deg);
    transform: skewX(-43deg);
    display: inline-block;
    z-index: 3;
    position: relative;
    margin-left: 0px;
    font-size: 0.9375rem;
    text-transform: capitalize;
  }

  .button-default.button-slanted.button-slanted--left {
    background-color: #393939;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 143px;
  }
`;

const StyledClickableTab = styledComponent.div`
  box-shadow: -9px -5px 13px 1px #00000045;
  font-family: Roboto;
  cursor: pointer;
  .button-wrap {
    padding: 0px;
  }

  &:not(:first-child){
    margin-left: 0px;
  }

  .button-default {
    color: white;
    background-color: #2f2f2f;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 40px 10px 20px;
    display: inline-block;
    border-radius: 0px 3px 0px 0px;
    height: 28px;
  }

  .button-slanted {
    -ms-transform: skewX(43deg);
    -webkit-transform: skewX(43deg);
    transform: skewX(43deg);
    background-color: #2f2f2f;
    position: relative;
    box-shadow: 4px -4px 11px 2px #00000045;
  }

  .button-slanted--left {
    background-color: #333;
  }

  .button-slanted--right {
    margin-left: -4px;
  }

  .button-slanted--left:after, .button-slanted--right:after {
    content: "";
    position: absolute;
    width: 70px;
    height: 100%;
    background-color: #2f2f2f;
    position: absolute;
    top: 0;
    -ms-transform: skewX(-43deg);
    -webkit-transform: skewX(-43deg);
    transform: skewX(-43deg);
  }

  .button-slanted--left:after {
    border-radius: 5px 0 0 5px;
    left: -31px;
    z-index: 10;
    box-shadow: -5px 2px 5px 2px #262525;
  }

  .button-slanted--right:after {
    border-radius: 0 30px 30px 0;
    right: -23px;
  }

  .button-slanted-content {
    -ms-transform: skewX(-43deg);
    -webkit-transform: skewX(-43deg);
    transform: skewX(-43deg);
    display: inline-block;
    z-index: 3;
    position: relative;
    margin-left: 0px;
    font-size: 0.9375rem;
    text-transform: capitalize;
  }

  .button-default.button-slanted.button-slanted--left {
    background-color: #2f2f2f;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 220px;
  }
`;

const StyledTabDisabled = ({ label, style }) => (
	<StyledTabWrapper style={style}>
		<div className="button-wrap">
			<div className="button-default button-slanted button-slanted--left">
				<span data-testid={'helpSubNav'} className="button-slanted-content">
					{label}
				</span>
			</div>
		</div>
	</StyledTabWrapper>
);

const StyledTab = ({ label, onClick, style }) => (
	<StyledClickableTab onClick={onClick} style={style}>
		<div className="button-wrap">
			<div className="button-default button-slanted button-slanted--left">
				<span data-testid={'helpSubNav'} className="button-slanted-content">
					{label}
				</span>
			</div>
		</div>
	</StyledClickableTab>
);

const updateRoute = (pathName, index) => {
	const { subNavLink } = navLinks.find((v) => v.path === pathName);
	let path = pathName;
	if (index > 0) {
		const route = subNavLink[index - 1];
		path = route.path;
	}
	return path;
};

export { StyledTab, StyledTabDisabled, StyledTabs, updateRoute };
