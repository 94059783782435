import _ from 'lodash';

export const getPatientId = ({ patientMapping, managingOrgId, profiles, profileId }) => {
	const profile = profiles[profileId];

	const { patientId } =
		_.find(patientMapping, ({ internalManagingOrganizationId, firstName, lastName }) => {
			const nameMatch =
				_.isEqual(_.toLower(firstName), _.toLower(profile?.firstName)) &&
				_.isEqual(_.toLower(lastName), _.toLower(profile?.lastName));

			if (managingOrgId) {
				return _.isEqual(internalManagingOrganizationId, managingOrgId) && nameMatch;
			}

			return nameMatch;
		}) || {};

	return patientId;
};

export default getPatientId;
