import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import React, { useEffect, useState } from 'react';
import { TabPanel } from '../../casePopOverStyles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { crmSearchScopes, useCRMDataLoader } from '@worklist-2/core/src';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.white,
		borderWidth: 0,
		color: '#666666',
		fontWeight: '400',
		wordWrap: 'break-word',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		borderWidth: 0,
		color: theme.palette.common.black,
		wordWrap: 'break-word',
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&': {
		color: theme.palette.common.black,
	},
	'&:hover td': {
		backgroundColor: '#C8D6FF',
	},
	'& td': {
		padding: 10,
		alignItems: 'center',
	},
	'&:nth-of-type(odd)': {
		backgroundColor: '#F9FBFF',
	},
	'&:nth-of-type(even)': {
		backgroundColor: theme.palette.common.white,
	},
}));

const History = ({ caseData }) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const caseDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.caseApi,
	});

	useEffect(() => {
		fetchData().catch(console.error);
	}, [caseData]);

	const notDisplayHistoryFields = ['Watcher', 'RelatedContact', 'AccountId'];

	const fetchData = async (loader, value) => {
		const fnName = 'load';
		const bodyData = undefined;
		if (!loader) {
			loader = caseDataLoader;
		}

		setLoading(true);
		setData(null);

		try {
			await loader[fnName]({ id: caseData.CaseId, history: true }, !!bodyData || undefined, bodyData).then(
				result => {
					const newData = result;
					setData(newData);
					setLoading(false);
				}
			);
		} catch (e) {
			console.error(e);
			setLoading(false);
		}
	};

	return !loading && caseData ? (
		<TabPanel>
			<div className="header">
				<div className="left">
					<div className="colored-icon success-icon">
						<ChatOutlinedIcon sx={{ fontSize: 20 }} />
					</div>
					<h2>History</h2>
				</div>
			</div>
			<div className="body">
				<Table aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell align="center">Updated Date/Time</StyledTableCell>
							<StyledTableCell align="center">Updated By</StyledTableCell>
							<StyledTableCell align="center">Field</StyledTableCell>
							<StyledTableCell align="center">Old Value</StyledTableCell>
							<StyledTableCell align="center">New Value</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody style={{ flex: '1' }}>
						{data && data.length > 0 ? (
							data.map((row, i) => {
								if (!notDisplayHistoryFields.includes(row.FieldName)) {
									return (
										<StyledTableRow key={i}>
											<StyledTableCell align="center">
												{row.LastUpdated
													? `${new Date(row.LastUpdated).toLocaleDateString()} ${new Date(
															row.LastUpdated
													  ).toLocaleTimeString(navigator.language, {
															hour: '2-digit',
															minute: '2-digit',
													  })}`
													: '-'}
											</StyledTableCell>
											<StyledTableCell align="center">{row.LastUpdatedUserName}</StyledTableCell>
											<StyledTableCell align="center" style={{ wordBreak: 'break-all' }}>
												{row.FieldName}
											</StyledTableCell>
											<StyledTableCell align="center" style={{ wordBreak: 'break-all' }}>
												{row.OldValue || '-'}
											</StyledTableCell>
											<StyledTableCell align="center" style={{ wordBreak: 'break-all' }}>
												{row.NewValue || '-'}
											</StyledTableCell>
										</StyledTableRow>
									);
								}
							})
						) : (
							<StyledTableRow>
								<StyledTableCell colSpan={5}>
									<Box
										sx={{
											height: '100%',
											width: '100%',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Typography
											sx={{
												textAlign: 'center',
												fontSie: '16px',
												marginBottom: '8px',
												color: 'black',
											}}
										>
											No Result
										</Typography>
									</Box>
								</StyledTableCell>
							</StyledTableRow>
						)}
					</TableBody>
				</Table>
			</div>
		</TabPanel>
	) : (
		<Box
			sx={{
				height: '100%',
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				paddingBottom: '100px',
				boxSizing: 'border-box',
			}}
		>
			<Typography
				sx={{
					textAlign: 'center',
					fontSie: '16px',
					marginBottom: '8px',
					color: 'black',
				}}
			>
				<CircularProgress
					className="progressBar"
					size={22}
					sx={{
						color: '#03dac5',
						marginRight: '16px',
					}}
				/>
				Loading...
			</Typography>
		</Box>
	);
};

export default History;
