/* Generated automagically by FhirStarter - DO NOT EDIT */

const procedureCodeSchema = {
	resourceType: 'ProcedureCode',
	identifier: ['__Identifier__'],
	critical: '__boolean__',
	display: '__string__',
	code: '__string__',
};
export default procedureCodeSchema;
