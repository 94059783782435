import React from 'react';

const ExpandMoreIcon = () => (
	<svg fill="none" height="11" viewBox="0 0 11 11" width="11" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_4400_16100)">
			<path
				d="M7.60375 3.9375L5.5 6.03667L3.39625 3.9375L2.75 4.58375L5.5 7.33375L8.25 4.58375L7.60375 3.9375Z"
				fill="#4D79EA"
			/>
		</g>
		<defs>
			<clipPath id="clip0_4400_16100">
				<rect fill="white" height="11" width="11" />
			</clipPath>
		</defs>
	</svg>
);

export default ExpandMoreIcon;
