import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { BroadcastChannel } from 'broadcast-channel';
import { useConfig } from '@worklist-2/core/src/context/ConfigContext';
import { fhirExtensionUrls } from '@worklist-2/core/src/fhir/extension/fhirExtensionUrls';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';

import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const Edit = ({ organization, config, TestDomain, domainName }) => {
	const { t } = useTranslation('organization');
	const [selectedFile, setSelectedFile] = useState(null);
	const [loginHint, setLoginHint] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const inputRef = useRef(null);

	const downloadTemplate = () => {
		const url = `${config?.auth?.omegaai?.auth_api}/Portal/IdentityProvider/Template?Type=OpenID`;

		axios
			.get(url)
			.then(response => {
				if (response?.status == 200) {
					const blob = new Blob([response.data], {
						type: 'text/xml',
					});
					const winURL = window.URL || window.webkitURL;
					const blobUrl = winURL.createObjectURL(blob);
					const anchorElem = document.createElement('a');

					anchorElem.href = blobUrl;
					anchorElem.download = 'OpenID.xml';
					anchorElem.click();
					anchorElem.remove();
				}
			})
			.catch(e => {
				console.error(e);
			});
	};

	const onFileChange = event => {
		setSelectedFile(event.target.files[0]);
	};

	const handleUploadClick = () => {
		if (domainName) {
			inputRef.current?.click();
		}
	};

	const onLoginHintChange = event => {
		setLoginHint(event.target.value);
	};

	const uploadFile = () => {
		const url = `${config.auth.omegaai.auth_api}/Portal/IdentityProvider/${organization?.id}?forceUpdate=true`;
		// Create an object of formData
		const formData = new FormData();

		// Update the formData object
		formData.append('myFile', selectedFile, selectedFile.name);

		// Request made to the backend api
		// Send formData object
		axios
			.put(url, formData)
			.then(response => {
				if (response?.status == 200) {
					TestDomain?.(domainName, loginHint);
				}
			})
			.catch(e => {
				console.error(e);
			});
	};

	const handleButtonClick = () => {
		setShowModal(prev => !prev);
	};

	const DragDropIdP = styled(TextField)({
		'& input::file-selector-button': {
			display: 'none',
		},
	});

	return (
		<>
			<Grid container spacing={2} sx={{ marginTop: '25px', marginBottom: '10px' }}>
				<Grid item sx={{ display: 'flex', justifyContent: 'end' }} xs={4}>
					<input
						ref={inputRef}
						accept="text/xml"
						id="add-files"
						style={{ display: 'none' }}
						type="file"
						onChange={onFileChange}
					/>
					<DragDropIdP
						fullWidth
						InputLabelProps={{ shrink: true }}
						className="addFiles"
						disabled={!domainName}
						for="add-files"
						helperText={
							<Typography
								component="div"
								fontSize="12px"
								fontWeight="500"
								marginBottom="32px"
								sx={{ textDecoration: 'underline', ml: '-14px' }}
								textTransform="uppercase"
							>
								<Link color="rsPrimary.main" sx={{ cursor: 'pointer' }} onClick={downloadTemplate}>
									{t('Download “open id” xml template')}
								</Link>
							</Typography>
						}
						label={t('Import Identity Provider *')}
						placeholder={t('No file chosen')}
						sx={{ flex: 1 }}
						title={t('Click to browse or drag and drop a XML file')}
						value={selectedFile?.name ?? null}
						onClick={handleUploadClick}
					/>
					<IconButton
						fullWidth
						color="rsPrimary"
						disabled={!(domainName && selectedFile)}
						sx={{
							p: '10px',
							mt: '5px',
							mr: '8px',
							background: '#42a5f5',
							borderRadius: 1,
							color: '#FFF',
							position: 'absolute',
						}}
						onClick={uploadFile}
					>
						<PublishOutlinedIcon />
					</IconButton>
				</Grid>
				<Grid item xs={4}>
					<TextField
						disabled
						fullWidth
						InputLabelProps={{ shrink: true }}
						helperText={<Typography sx={{ fontSize: '12px', ml: '-14px' }}>{t('Read-only')}</Typography>}
						label={t('Domain')}
						value={domainName}
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						fullWidth
						InputLabelProps={{ shrink: true }}
						disabled={false}
						helperText={
							<Typography sx={{ fontSize: '12px', ml: '-14px', color: 'rgba(255, 255, 255, 0.36)' }}>
								{t('required')}
							</Typography>
						}
						label={t('Email')}
						value={loginHint}
						onChange={onLoginHintChange}
					/>
				</Grid>
				{/* Display logic to show login screen inside a modal (Not used for now)  */}
				{organization && config && (
					<Modal open={showModal} onClose={handleButtonClick}>
						<Box
							sx={{
								position: 'absolute',
								top: '60%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								width: '560px',
								height: '400px',
								bgcolor: '#414141',
								border: '2px solid #000',
								boxShadow: 24,
								pt: 2,
								px: 4,
								pb: 3,
							}}
						>
							<IconButton
								sx={{
									position: 'relative',
									top: 0,
									right: '-95%',
								}}
								onClick={() => handleButtonClick()}
							>
								<CloseIcon sx={{ color: '#fff' }} />
							</IconButton>
							<embed
								height="350px"
								src={`${config.auth.omegaai.msal.redirect_uri}idptest?domainName=${domainName}&organization=${organization.id}&loginHint=${loginHint}`}
								width="100%"
							/>
						</Box>
					</Modal>
				)}
			</Grid>

			<Grid container alignItems="center" sx={{ marginBottom: '30px', width: '184px' }}>
				<Button
					fullWidth
					color="rsPrimary"
					disabled={!(domainName && loginHint)}
					variant="contained"
					onClick={() => {
						// To open the model use handleButtonClick
						TestDomain?.(domainName, loginHint);
					}}
				>
					{t('Test Connection'.toLowerCase())}
				</Button>
			</Grid>
		</>
	);
};

const Show = ({ domainName }) => (
	<Grid container alignItems="center" sx={{ marginTop: '25px', marginBottom: '45px' }}>
		<Grid item sx={{ marginRight: '70px' }}>
			<Stack>
				<Typography fontSize="14px" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
					Domain
				</Typography>

				<Typography fontSize="16px">{domainName}</Typography>
			</Stack>
		</Grid>
	</Grid>
);

const DragDropIDP = ({ organization, isEditing, setBadgeStatus, setToastMessage }) => {
	const sprinterOaiOrganizationGeneralHideDownloadopenidxmltemplate = useBooleanFlagValue(
		'sprinter-oai-organization-general-hide-downloadopenidxmltemplate'
	);
	const __config = useConfig();
	const successToastObject = useMemo(
		() => ({
			open: true,
			text: 'Test connection Succeeded.',
			severity: 'success',
			color: 'success',
		}),
		[]
	);

	const failureToastObject = useMemo(
		() => ({
			open: true,
			text: 'Test connection Failed.',
			severity: 'error',
			color: 'error',
		}),
		[]
	);

	const getToastChildren = useCallback(
		toast => (
			<Alert
				color={toast.color}
				severity={toast.severity}
				sx={{
					p: 0,
					borderRadius: '6px',
					backgroundColor: 'rsSecondary.medium',
					// boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
				}}
			>
				{toast.text}
			</Alert>
		),
		[]
	);

	const domainName = organization
		? _.find(organization.extension, elem => elem.url === fhirExtensionUrls.organization.DomainName)?.valueString ||
		  ''
		: '';
	const [idPLastSuccessfulConnection, setIdPLastSuccessfulConnection] = useState(
		organization
			? _.find(
					organization.extension,
					elem => elem.url === fhirExtensionUrls.organization.IdPLastSuccessfulConnection
			  )?.valueString || ''
			: ''
	);

	// Setting the Badge Status for header
	useEffect(() => {
		domainName ? setBadgeStatus(idPLastSuccessfulConnection ? 'Online' : 'Offline') : setBadgeStatus('Unknown');
	}, [idPLastSuccessfulConnection]);

	const channel = useMemo(() => new BroadcastChannel('IDPDomainTest'), []);

	useEffect(() => {
		channel.addEventListener('message', e => {
			if (e.isSuccess) {
				SaveState('Online', e.domainHint, e.organization);
			} else {
				SaveState('Offline', e.domainHint, e.organization);
			}
		});
		return () => {
			channel.close();
		};
	}, []);

	const TestDomain = (domainHint, loginHint) => {
		if (organization && __config) {
			// Call the function to open a new window
			const testUrl = `${__config.auth.omegaai.msal.redirect_uri}idptest?domainName=${domainHint}&organization=${organization.id}&loginHint=${loginHint}`;
			const testWindow = window.open(testUrl, 'Id Provider Test', ' width= 560, height=640');

			// Show alarm message if cannot open pop-up
			if (!testWindow) {
				SaveState('Unknown', domainHint, organization.id);
				return;
			}

			testWindow.focus();
		}
	};

	const SaveState = (statusValue, domainHint, org) => {
		const url = `${__config.auth.omegaai.auth_api}/Portal/IdentityProvider/ConnectionStatus/${org}`;
		const requestJson = {
			domain: domainHint,
			status: statusValue,
		};
		// Request made to the backend api
		// Send formData object
		axios
			.put(url, requestJson)
			.then(response => {
				if (response?.status == 200 && statusValue === 'Online') {
					setToastMessage(true, getToastChildren(successToastObject));
					setIdPLastSuccessfulConnection(response.data.LastCheckedTime);
				} else if (statusValue !== 'Online') {
					setToastMessage(true, getToastChildren(failureToastObject));
					setIdPLastSuccessfulConnection('');
				} else {
					setToastMessage(true, getToastChildren(failureToastObject));
				}
			})
			.catch(e => {
				setToastMessage(true, getToastChildren(failureToastObject));
				setIdPLastSuccessfulConnection('');
				console.error(e);
			});
	};

	return sprinterOaiOrganizationGeneralHideDownloadopenidxmltemplate ? (
		<></>
	) : isEditing ? (
		<Edit TestDomain={TestDomain} config={__config} domainName={domainName} organization={organization} />
	) : (
		<Show domainName={domainName} />
	);
};

export default DragDropIDP;
