// React Libraries
import React, { useCallback, useEffect, useRef, useState } from 'react';
import GridLayout from 'react-grid-layout';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import _ from 'lodash';
import { renderToStaticMarkup } from 'react-dom/server';

// Material UI Libraries
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import { Avatar as Avatar2, Svg } from '@worklist-2/ui/src/components';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import AvatarGroup from '@mui/material/AvatarGroup';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

// Custom Component Libraries
import ShareSideContent from './components/ShareSideContent';
import ChartRender from './components/ChartRender';
import ChartBox from './components/ChartBox';
import ReportOptions from '../BreezeNewReportsView/components/ReportOptions';
import NewReport from '../BreezeNewReportsView/components/NewReport';

// Custom Chart Images
import BarChart from '@worklist-2/ui/src/assets/img/bar_chart.png';
import LineChart from '@worklist-2/ui/src/assets/img/line_chart.png';
import PieChart from '@worklist-2/ui/src/assets/img/pie_chart.png';
import EmptyCard from '@worklist-2/ui/src/assets/img/empty_cards.png';
import { ReactElement as BorderDashedLine } from '@worklist-2/ui/src/assets/branding/svg/report-border-dashed-line.svg';

// Worklist Core Libraries
import { crmSearchScopes, useAuth, useConfig, useCRMDataLoader, useImportTabContext } from '@worklist-2/core/src';

import AddCircleIcon from '@mui/icons-material/AddCircle';

const ReportDetailsView = () => {
	const { id } = useParams();
	const [isShare, setIsShare] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [allowEdit, setAllowEdit] = useState(false);
	const [isExpand, setIsExpand] = useState(false);
	const [isReport, setIsReport] = useState(false);
	const [chartIndex, setChartIndex] = useState();
	const [reportJson, setReportJson] = useState({});
	const [chartType, setChartType] = useState(null);
	const [chartImage, setChartImage] = useState(null);
	const __config = useConfig();
	const [cubeToken, setCubeToken] = useState();
	const navigate = useNavigate();
	const [report, setReport] = useState(null);
	const isSmallScreen = useMediaQuery('(max-width:1100px)');
	const [userData, setUserData] = useState(null);
	const [isSwitchingMode, setIsSwitchingMode] = useState(false);
	const [editModeReportName, setEditModeReportName] = useState(false);
	const reportNameRef = useRef(null);
	const [nameWidth, setNameWidth] = useState(100);
	const [filter, setFilter] = useState('reading');
	const is4k = useMediaQuery('(min-width:3600px)');

	const { sidebarIsOpen } = useImportTabContext();

	const dashedLineSvgString = encodeURIComponent(renderToStaticMarkup(<BorderDashedLine />));

	const [layout, setLayout] = useState([
		{
			i: 'a',
			x: 0,
			y: 0,
			w: 4,
			h: 4,
			minW: 3,
			maxH: is4k ? 16 : 8,
			minH: 5,
		},
		{
			i: 'b',
			x: 4,
			y: 0,
			w: 4,
			h: 4,
			minW: 3,
			maxH: is4k ? 16 : 8,
			minH: 5,
		},
		{
			i: 'c',
			x: 8,
			y: 0,
			w: 4,
			h: 4,
			minW: 3,
			maxH: is4k ? 16 : 8,
			minH: 5,
		},
		{
			i: 'd',
			x: 0,
			y: 0,
			w: 4,
			h: 4,
			minW: 3,
			maxH: is4k ? 16 : 8,
			minH: 5,
		},
		{
			i: 'e',
			x: 4,
			y: 0,
			w: 4,
			h: 4,
			minW: 3,
			maxH: is4k ? 16 : 8,
			minH: 5,
		},
		{
			i: 'f',
			x: 8,
			y: 0,
			w: 4,
			h: 4,
			minW: 3,
			maxH: is4k ? 16 : 8,
			minH: 5,
		},
	]);

	useEffect(() => {
		const newLayout = [];
		layout.forEach(item => {
			newLayout.push({
				...item,
				maxW: sidebarIsOpen ? 11 : 12,
				w: item.w >= 11 ? 11 : item.w,
			});
		});
		setLayout(newLayout);
	}, [sidebarIsOpen]);

	const fhirDataLoaderReport = useCRMDataLoader({
		scope: crmSearchScopes.reportApi,
	});

	const [dataSaverReport] = useState(fhirDataLoaderReport);
	const { loggedInUser, authorized } = useAuth();

	useEffect(() => {
		const header = document.getElementById('page-header');
		if (header) {
			header.style.filter = `${isReport && !!cubeToken ? 'blur(1.5px)' : 'blur(0px)'}`;
			header.style.opacity = `${isReport && !!cubeToken ? '0.2' : '1'}`;
		}
	}, [isReport, cubeToken]);

	const handleLayout = (isAddNew = false) => {
		layoutChange(layout, report.script, isAddNew);
	};

	const layoutChange = (_layout, script, isAddNew) => {
		if (_layout.length > script.length) {
			_layout.pop();
		}
		const lastLayout = _layout[_layout.length - 1];

		if (isAddNew) {
			const lastScript = script[script.length - 1];
			// The size of the card should be 1/4 of the regular visual to start
			if (lastScript.chartType == 'number') {
				lastLayout.w = 2;
				lastLayout.h = 2;
				lastLayout.minW = 2;
				lastLayout.minH = 2;
			}
		}

		const newLayout = {
			w: 4,
			h: 4,
			x: lastLayout ? (lastLayout.x + 4 > 8 ? 0 : lastLayout.x + 4) : 0,
			y: lastLayout ? (lastLayout.x >= 8 ? lastLayout.y + 4 : lastLayout.y) : 0,
			i: lastLayout ? String.fromCharCode(lastLayout.i.charCodeAt(0) + 1) : '1',
			minW: 3,
			minH: 5,
			maxH: is4k ? 16 : 8,
			moved: false,
			static: false,
		};

		setLayout([..._layout, newLayout]);
	};

	const generateAvatarName = label => {
		let initials = '';

		if (label) {
			const items = label.trim().split(' ');

			items.slice(0, 2).forEach(item => {
				initials += item[0];
			});

			if (items.length > 2) {
				initials += '...';
			}
		}

		return initials.toUpperCase();
	};

	const checkPrivilege = inputs => {
		const userIndex = _.findIndex(inputs, { id: loggedInUser.id });
		const { extension } = inputs[userIndex];
		const privilegeIndex = _.findIndex(extension, { url: 'privilege' });

		setAllowEdit(extension[privilegeIndex]?.valueString === 'admin');
	};

	const updateUserData = inputs => {
		const data = _.map(inputs, elem => ({
			id: elem.id,
			display: elem.display,
			icon: generateAvatarName(elem.display.replace('^', ' ')),
			extension: elem.extension,
		}));
		setUserData(data);
	};

	const saveData = useCallback(
		async (loader, props, payload) => {
			let result = {};
			if (authorized) {
				try {
					result = await loader.save(props, payload, false).then(res => res.data);
				} catch (e) {
					console.error(e);
				}
			}
			return result;
		},
		[authorized]
	);

	const onShareReport = useCallback(
		async userinfo => {
			const { forRead } = report;
			const reportPayload = deepClone(report);
			const userIndex = _.findIndex(forRead, { id: userinfo.id });
			const { extension } = userinfo;
			const privilegeIndex = _.findIndex(extension, {
				url: 'privilege',
			});
			const privilegeValue = extension[privilegeIndex].valueString;

			if (userIndex > -1) {
				if (privilegeValue === 'remove') {
					forRead.splice(userIndex, 1);
				} else {
					forRead[userIndex] = userinfo;
				}
			} else {
				forRead.push(userinfo);
			}

			reportPayload.script = report.script.map(s => `${JSON.stringify(s)}`);
			reportPayload.resourceType = 'Report';
			reportPayload.forRead = forRead;
			report.forRead = forRead;
			setReport(report);

			const res = await saveData(dataSaverReport, { id: reportPayload.id }, reportPayload);

			if (res && res.resourceType === 'Report') {
				setReportJson(res);
				updateUserData(forRead);
			}
		},
		[report]
	);

	const onSaveReport = useCallback(
		async script => {
			if (!report?.script || chartIndex === report?.script.length) {
				report.script = [...report.script, script];
				handleLayout(true);
			} else {
				report.script[chartIndex] = script;
			}

			setReport(report);
			const scripts = report.script.map(s => `${JSON.stringify(s)}`);
			const reportPayload = deepClone(report);
			reportPayload.script = scripts;
			reportPayload.resourceType = 'Report';

			const res = await saveData(dataSaverReport, { id: reportPayload.id }, reportPayload);

			if (res && res.resourceType === 'Report') {
				setReportJson(res);
			}

			setIsExpand(false);
			setIsReport(false);
		},
		[report, reportJson, chartIndex, loggedInUser]
	);

	const onAutoSaveReport = useCallback(
		async report => {
			setReport(report);
			const scripts = report.script.map(s => `${JSON.stringify(s)}`);
			const reportPayload = deepClone(report);
			reportPayload.script = scripts;
			reportPayload.resourceType = 'Report';

			const res = await saveData(dataSaverReport, { id: reportPayload.id }, reportPayload);

			if (res && res.resourceType === 'Report') {
				setReportJson(res);
			}
		},
		[report, layout, reportJson, loggedInUser]
	);

	const onLayoutChange = layout => {
		if (!!report && JSON.stringify(report.layout) !== JSON.stringify(layout)) {
			setLayout(layout);
			report.layout = layout;

			!isSwitchingMode && onAutoSaveReport(report);
		}

		// finished switch mode
		isSwitchingMode && setIsSwitchingMode(false);
	};

	function deepClone(obj) {
		const _out = new obj.constructor();

		const getType = function (n) {
			return Object.prototype.toString.call(n).slice(8, -1);
		};

		for (const _key in obj) {
			if (obj.hasOwnProperty(_key)) {
				_out[_key] =
					getType(obj[_key]) === 'Object' || getType(obj[_key]) === 'Array'
						? deepClone(obj[_key])
						: obj[_key];
			}
		}
		return _out;
	}

	const fetchCubeToken = () => {
		axios
			.get(`${__config.data_sources.breeze}/report/cube/token`, {
				headers: {
					Accept: '*/*',
				},
			})
			.catch(error => {
				console.error('error fetching Cube token.', error);
			})
			.then(resp => {
				if (resp?.status === 200) {
					setCubeToken(resp.data);
				}
			});
	};

	const fetchReportData = useCallback(
		async reportId => {
			if (authorized && fhirDataLoaderReport) {
				return fhirDataLoaderReport
					.load({
						id: reportId,
						summary: false,
					})
					.then(result => {
						if (!_.isEmpty(result)) {
							const item = {
								id: result.id,
								name: result.name,
								forRead: result.forRead,
								script: convertScriptJson(result.script),
								layout: result.layout,
								thumbnail: result.thumbnail,
								owner: result.owner,
							};

							setLayout(result.layout);
							setReport(item);
							setNameWidth(result.name.length * 9);
						}
					});
			}
		},
		[id]
	);

	const convertScriptJson = script => {
		const returnScript = [];

		if (!_.isEmpty(script)) {
			_.forEach(script, item => {
				try {
					returnScript.push(JSON.parse(item));
				} catch (error) {}
			});
		}

		return returnScript;
	};

	const onDeleteChart = useCallback(
		index => {
			const scripts = report.script;
			const layouts = report.layout;
			const reportPayload = deepClone(report);

			reportPayload.resourceType = 'Report';

			scripts.splice(index, 1);
			reportPayload.script = scripts;
			report.script = scripts;

			layouts.splice(index, 1);
			setLayout(layouts);
			reportPayload.layout = layouts;
			report.layout = layouts;
			setReport(report);

			layoutChange(layouts, scripts);

			saveData(dataSaverReport, { id: reportPayload.id }, reportPayload).then(res => {
				if (res && res.resourceType === 'Report') {
					setReportJson(res);
				}
			});
		},
		[report]
	);

	const onChangeReportName = event => {
		const modifiedReportName = event.target.value;

		if (modifiedReportName !== report.name) {
			report.name = modifiedReportName;
			onAutoSaveReport(report);
			setNameWidth(event.target.value.length * 9);
		}
		setEditModeReportName(false);
	};

	useEffect(() => {
		if (authorized) {
			fetchReportData(id);
			fetchCubeToken();
		}
	}, [authorized]);

	useEffect(() => {
		if (report) {
			if (report.script[chartIndex]?.chartType == 'bar') {
				setChartType('BAR_CHART');
				setChartImage(BarChart);
			} else if (report.script[chartIndex]?.chartType == 'line') {
				setChartType('LINE_CHART');
				setChartImage(LineChart);
			} else if (report.script[chartIndex]?.chartType == 'pie') {
				setChartType('PIE_CHART');
				setChartImage(PieChart);
			} else if (report.script[chartIndex]?.chartType == 'number') {
				setChartType('CROP_CHART');
				setChartImage(EmptyCard);
			} else if (report.script[chartIndex]?.chartType == 'table') {
				setChartType('TABLE_CHART');
			}
		}
	}, [chartIndex]);

	useEffect(() => {
		if (isEdit) {
			handleLayout();
		}
	}, [isEdit]);

	useEffect(() => {
		if (!!report && !!loggedInUser) {
			updateUserData(report.forRead);
			checkPrivilege(report.forRead);
		}
	}, [report, loggedInUser]);

	return (
		<>
			{!!report && (
				<Box
					sx={{
						position: 'absolute',
						top: '15px',
						left: isSmallScreen ? '75px' : '140px',
						right: '75px',
						with: '100%',
					}}
					onClick={() => isExpand && setIsExpand(false)}
				>
					<Stack
						alignItems="center"
						direction="row"
						justifyContent="space-between"
						mt="5rem"
						sx={{
							opacity: `${isReport && !!cubeToken ? '0.2' : '1'}`,
							filter: `${isReport && !!cubeToken ? 'blur(1px)' : 'blur(0px)'}`,
						}}
					>
						<Stack alignItems="center" direction="row" spacing={1} sx={{ width: '200px' }}>
							<Button
								sx={{
									minWidth: 54,
									height: 54,
									marginRight: '10px',
									background: '#FFF',
									borderRadius: '6px',
									boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
								}}
								onClick={() => {
									setTimeout(() => {
										navigate('/stats');
									}, 200);
								}}
							>
								<Svg name="rootlogo" />
							</Button>

							<Box
								sx={{
									background: '#FFF',
									border: '1px solid rgba(255, 255, 255, 0.1)',
									boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.11)',
									borderRadius: '6px',
									color: '#FFFFFF',
									display: 'flex',
									padding: '0 16px',
									height: 54,
									alignItems: 'center',
									justifyContent: 'flex-start',
									width: '230px',
									'&:hover': {
										'.MuiSvgIcon-root': {
											display: 'inline-block',
										},
									},
								}}
							>
								<Tooltip title={report?.name}>
									<Box
										sx={{
											background: '#4D79EA',
											borderRadius: '100px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											marginRight: '10px',
											width: 34,
											minWidth: 34,
											height: 34,
										}}
									>
										<Avatar2
											transparent
											color="#FFF"
											fontSize={14}
											height={28}
											label={report.name}
											showEllipsis={false}
											width={28}
										/>
									</Box>
								</Tooltip>
								{editModeReportName ? (
									<InputBase
										autoFocus
										aria-labelledby="Root Details Editing Report Name"
										defaultValue={report?.name ? report?.name : ''}
										inputRef={reportNameRef}
										sx={{
											width: `${nameWidth}px`,
											color: '#000',
											minWidth: '275px',
											maxWidth: '375px',
											marginRight: '5px',
										}}
										onBlur={e => {
											onChangeReportName(e);
										}}
										onFocus={e => {
											e.target.select();
										}}
										onKeyUp={event => {
											if (event.key == 'Enter') {
												onChangeReportName(event);
											}
										}}
									/>
								) : (
									<>
										<Tooltip title={report?.name}>
											<Typography
												sx={{
													color: '#000',
													overflow: 'hidden',
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis',
													maxWidth: '350px',
													marginRight: '12px',
												}}
											>
												{report?.name}
											</Typography>
										</Tooltip>
										{isEdit && (
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													margin: 'auto',
													width: 30,
													height: 30,
												}}
											>
												<Button
													aria-label="Root chart edit"
													sx={{
														minWidth: 30,
														height: 30,
														color: '#C4C4C4',
														position: 'absolute',
														padding: 'unset',
														display: 'flex',
														borderRadius: 100,
													}}
												>
													<EditOutlinedIcon
														sx={{
															fontSize: '24px',
															// display: 'none',
														}}
														onClick={() => {
															setEditModeReportName(true);
														}}
													/>
												</Button>
											</Box>
										)}
									</>
								)}
							</Box>
						</Stack>

						{allowEdit && (
							<Box
								sx={{
									background: '#FFF',
									borderRadius: '30px',
									height: '40px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									gap: '8px',
								}}
							>
								<Button
									data-testid="reading-button"
									sx={{
										marginLeft: '3px',
										width: '115px',
										height: '34px',
										borderRadius: '30px',
										background: !isEdit ? '#4D79EA' : '#FFF',
										boxShadow: !isEdit ? '4px 0px 4px rgba(0, 0, 0, 0.15)' : 'none',
										fontSize: '12px',
										fontWeight: 500,
										textTransform: 'uppercase',
										letterSpacing: '1.25px',
										color: isEdit ? 'rgba(0, 0, 0, 0.6)' : '#fff',
										display: 'flex',
										alignItems: 'center',
										'&:hover': {
											background: !isEdit ? '#4D79EA' : '#FFF',
										},
									}}
									onClick={() => {
										setIsEdit(false);
										setIsSwitchingMode(true);
									}}
								>
									<VisibilityIcon
										sx={{
											fontSize: '20px',
											marginRight: '10px',
											display: isEdit ? 'none' : 'block',
										}}
									/>
									Reading
								</Button>

								<Button
									data-testid="edit-button"
									sx={{
										marginRight: '3px',
										width: '115px',
										height: '34px',
										borderRadius: '30px',
										background: isEdit ? '#4D79EA' : '#FFF',
										fontSize: '12px',
										fontWeight: 500,
										boxShadow: isEdit ? '-4px 0px 4px rgba(0, 0, 0, 0.15)' : 'none',
										textTransform: 'uppercase',
										letterSpacing: '1.25px',
										color: isEdit ? '#fff' : 'rgba(0, 0, 0, 0.6)',
										display: 'flex',
										alignItems: 'center',
										'&:hover': {
											background: isEdit ? '#4D79EA' : '#FFF',
										},
									}}
									onClick={() => {
										setIsEdit(true);
										setIsSwitchingMode(true);
									}}
								>
									<EditIcon
										sx={{
											fontSize: '20px',
											marginRight: '10px',
											display: isEdit ? 'block' : 'none',
										}}
									/>
									Edit
								</Button>
							</Box>
						)}

						<Stack alignItems="end" direction="column-reverse" justifyContent="end" spacing={10}>
							{allowEdit && (
								<Stack alignItems="center" direction="row">
									<AvatarGroup
										max={3}
										sx={{
											'.MuiAvatar-root': {
												background: '#A09BD5',
												color: '#fff',
												width: '35px',
												height: '35px',
												fontSize: '14px',
											},
										}}
									>
										{userData &&
											userData.map((el, index) => <Avatar key={index}>{el.icon}</Avatar>)}
									</AvatarGroup>
									<Button
										data-testid="btn-share"
										sx={{
											marginLeft: '4px',
											minWidth: '35px',
											color: '#C4C4C4',
											border: '1px dashed #C4C4C4',
											borderRadius: '100px',
											padding: 0,
											height: 35,
										}}
										onClick={() => setIsShare(true)}
									>
										<AddIcon />
									</Button>
								</Stack>
							)}
						</Stack>
					</Stack>

					<Box
						sx={{
							marginTop: '10px',
							marginLeft: '-10px',
							position: 'relative',
						}}
					>
						<GridLayout
							autoSize
							cols={12}
							isDraggable={isEdit}
							isResizable={isEdit}
							layout={layout}
							rowHeight={100}
							width={window.innerWidth - 190}
							onLayoutChange={onLayoutChange}
						>
							{layout.map((el, index) =>
								index === report?.script.length && !isEdit ? null : (
									<Box
										key={el.i}
										sx={{
											height: '100%',
											width: '100%',
											background: 'rgba(128, 128, 128, 0.05)',
											borderRadius: '20px',
											boxSizing: 'border-box',
											cursor: 'pointer',
											'.react-resizable-handle::after, .react-resizable-handle.react-resizable-handle-se':
												{
													right: 8,
													bottom: 8,
												},
											'&.resizing': {
												backgroundImage: `url("data:image/svg+xml,${dashedLineSvgString}")`,
											},
										}}
									>
										{index === report?.script.length && isEdit ? (
											<Stack
												sx={{
													alignItems: 'center',
													justifyContent: 'center',
													height: '100%',
													width: '100%',
													textAlign: 'center',
													border: '1px solid rgba(255, 255, 255, 0.2)',
													borderRadius: '20px',
													'&:hover': {
														// backgroundImage: `url("data:image/svg+xml,${dashedLineSvgString}")`,
														border: '1px dashed #42A5F5',
														'& .MuiBox-root': {
															color: '#FFFFFF',
														},
													},
												}}
											>
												<Box
													sx={{
														color: 'rgba(255, 255, 255, 0.6)',
														'&:hover': {
															color: '#FFFFFF',
														},
													}}
													onClick={() => {
														setIsExpand(true);
														setChartIndex(index);
													}}
												>
													{isExpand ? (
														<ReportOptions
															setChartImage={setChartImage}
															setChartType={setChartType}
															setIsReport={setIsReport}
														/>
													) : (
														<Box
															sx={{
																textAlign: 'center',
																color: 'rgba(255, 255, 255, 0.6)',
															}}
														>
															<AddCircleIcon
																sx={{
																	color: '#4D79EA',
																	fontSize: 38,
																}}
															/>
															<Typography
																fontSize={20}
																marginTop={1}
																sx={{
																	color: '#4D79EA',
																}}
															>
																Click here to add new
															</Typography>
														</Box>
													)}
												</Box>
											</Stack>
										) : (
											<ChartBox
												chartTitle={report.script[index]?.chartTitle}
												chartType={report.script[index]?.chartType}
												index={index}
												isEditMode={isEdit}
												isHideToolBar={isEdit && !!report?.script && !!report?.script[index]}
												setChartIndex={setChartIndex}
												setIsReport={setIsReport}
												onDeleteChart={onDeleteChart}
											>
												<Box
													sx={{
														height: '88%',
														width: '100%',
														paddingBottom: '15px',
													}}
												>
													{!!report?.script && !!report?.script[index] && !!cubeToken && (
														<ChartRender
															chartType={report.script[index].chartType}
															columnOrder={report.script[index].columnOrder}
															cubeToken={cubeToken}
															library={report.script[index].library}
															query={report.script[index].query}
														/>
													)}
												</Box>
											</ChartBox>
										)}
									</Box>
								)
							)}
						</GridLayout>
						{isReport && !!cubeToken && (
							<NewReport
								chartImage={chartImage}
								chartType={chartType}
								cubeToken={cubeToken}
								script={report?.script[chartIndex] ? report?.script[chartIndex] : null}
								setChartImage={setChartImage}
								setChartType={setChartType}
								setIsReport={setIsReport}
								onSaveReport={onSaveReport}
							/>
						)}
					</Box>
				</Box>
			)}
			{isShare && <ShareSideContent setIsShare={setIsShare} userData={userData} onShareReport={onShareReport} />}
		</>
	);
};

export default ReportDetailsView;
