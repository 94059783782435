// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VfaTCVokZ8ATVFcB8eeD{display:flex;flex-direction:column;width:100%;height:100%;padding:10px 20px}.uL3yLnY2YgAd0_GHGcUW{display:flex;justify-content:center;align-items:center}.uL3yLnY2YgAd0_GHGcUW input[type=checkbox]:checked{-webkit-appearance:checkbox;width:12px;height:12px;border-radius:2px;border:none;outline:none;box-shadow:0 0 0 1px #4d79ea;background-color:#4d79ea}.UpecOKgv8evUI7PIq_dU{-moz-appearance:none;-webkit-appearance:none;-o-appearance:none;width:12px;height:12px;margin:0;border-radius:2px;background-color:rgba(0,0,0,0)}.wm23XoFAKyiXICd6Nzzc{box-shadow:0 0 0 1px rgba(255,255,255,.5)}.NT_aVcy713a75dGeBk_2{box-shadow:0 0 0 1px #000}.jri6SHtKWESfKs85kbRy{width:100%;height:100%;padding-left:10px;background:rgba(0,0,0,0);font-feature-settings:\"clig\" off,\"liga\" off;font-family:Roboto,serif;font-size:14px;font-style:normal;font-weight:400;line-height:20px}.Szbzg1XKjk7uqEdJdRK9{color:#fff}.unswXrhAmb2dxb27J42b{color:rgba(57,57,57,.87)}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/common/MainArea/Draft/InsertTextBlock/CheckList/CheckList.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CACA,qBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CAEA,sBACC,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,mDACC,2BAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,WAAA,CACA,YAAA,CACA,4BAAA,CACA,wBAAA,CAGD,sBACC,oBAAA,CACA,uBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,QAAA,CACA,iBAAA,CACA,8BAAA,CAEA,sBACC,yCAAA,CAED,sBACC,yBAAA,CAIF,sBACC,UAAA,CACA,WAAA,CACA,iBAAA,CACA,wBAAA,CAEA,2CAAA,CACA,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAKH,sBACC,UAAA,CAGD,sBACC,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkList": "VfaTCVokZ8ATVFcB8eeD",
	"checkListItem": "uL3yLnY2YgAd0_GHGcUW",
	"checkListItemCheckbox": "UpecOKgv8evUI7PIq_dU",
	"checkListItemCheckboxOAI": "wm23XoFAKyiXICd6Nzzc",
	"checkListItemCheckboxBlume": "NT_aVcy713a75dGeBk_2",
	"checkListItemInput": "jri6SHtKWESfKs85kbRy",
	"inputOAI": "Szbzg1XKjk7uqEdJdRK9",
	"inputBlume": "unswXrhAmb2dxb27J42b"
};
export default ___CSS_LOADER_EXPORT___;
