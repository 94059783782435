import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CustomTextField from '../CustomTextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { mediaMobile } from '@worklist-2/core/src';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { lightTheme } from '@worklist-2/ui/src/themes/light-theme';
import { ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const LoginField = ({
	mode,
	width,
	isDisabled,
	visible,
	onChange,
	onKeyPress,
	placeholder = 'Enter your email address here',
}) => {
	const [dateValue, setDateValue] = useState(null);
	const { t } = useTranslation('login');

	// Date adapter
	const dateAdapter = AdapterDateFns;

	// Get user's locale
	moment.locale(navigator.language || 'en');

	// Date format based on browser language with standard format
	const dateFormat = moment.localeData().longDateFormat('L').toLowerCase().replaceAll('m', 'M');

	const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	// Get client locale function
	const clientLocale = {
		...moment.localeData(),
		formatLong: {
			date: () => dateFormat,
			time: () => moment.localeData().longDateFormat('LT'),
			dateTime: () => `${moment.localeData().longDateFormat('L')} ${moment.localeData().longDateFormat('LT')}`,
		},
		localize: {
			day: n => t(days[n]),
			month: n => t(months[n]),
		},
		match: {
			day: string => moment.weekdays().findIndex(day => day.toLowerCase() === string.toLowerCase()),
			month: string => moment.months().findIndex(month => month.toLowerCase() === string.toLowerCase()),
		},
	};

	return (
		<Box
			sx={{
				width,
				display: 'flex',
				flexDirection: 'column',
				[mediaMobile]: {
					width: '100% !important',
				},
			}}
		>
			{mode == 'sharedStudy' ? (
				<ThemeProvider theme={lightTheme}>
					<LocalizationProvider adapterLocale={clientLocale} dateAdapter={dateAdapter}>
						<DesktopDatePicker
							InputAdornmentProps={{
								position: 'start',
							}}
							inputFormat={dateFormat}
							inputProps={{
								placeholder: dateFormat?.toLowerCase(),
							}}
							label={t('Patient Birth Date')}
							renderInput={params => (
								<CustomTextField
									data-testid="birthdayVerify"
									disabled={isDisabled}
									id="emailLogin"
									name="emailLogin"
									onChange={onChange}
									onKeyPress={event => {
										onKeyPress(event);
									}}
									{...params}
									sx={{
										'.MuiInputLabel-root': {
											color: '#737374',
										},
										'.MuiButtonBase-root': {
											color: 'rgba(0, 0, 0, 0.6)',
										},
										'& input': {
											paddingLeft: 'unset',
										},
										'.Mui-focused': {
											border: 'unset',
										},
									}}
								/>
							)}
							value={dateValue}
							onChange={date => {
								setDateValue(date);
								onChange(date);
							}}
						/>
					</LocalizationProvider>
				</ThemeProvider>
			) : (
				<CustomTextField
					fullWidth
					autoComplete="email"
					disabled={isDisabled}
					id="emailLogin"
					name="emailLogin"
					placeholder={t(placeholder)}
					sx={{
						width,
						height: 56,
						display: visible ? 'block' : 'none',
						[mediaMobile]: {
							width: '100% !important',
						},
					}}
					type="email"
					onChange={onChange}
					onKeyPress={event => onKeyPress(event)}
				/>
			)}
		</Box>
	);
};
LoginField.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isDisabled: PropTypes.bool,
};

LoginField.defaultProps = {
	width: 415,
	isDisabled: false,
};

export default LoginField;
