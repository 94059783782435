// React Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Material UI Libraries
import Typography from '@mui/material/Typography';
import ShareIcon from '@mui/icons-material/Share';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';

function ReportsSelectView({ checkedReports, onDeleteReport }) {
	return (
		<Box
			sx={{
				background: '#2C7EC0',
				boxShadow: '30px 30px 60px rgba(0, 0, 0, 0.25)',
				borderRadius: '80px',
				position: 'fixed',
				bottom: '4px',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				color: '#FFFFFF',
				padding: '20px 50px',
			}}
		>
			<Box
				sx={{
					borderRight: '1px solid rgba(255, 255, 255, 0.5)',
					paddingRight: '30px',
				}}
			>
				<Typography fontSize={14}>{checkedReports.length} ITEMS</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					gap: '32px',
					alignItems: 'center',
					justifyContent: 'space-evenly',
					svg: {
						cursor: 'pointer',
					},
				}}
			>
				<ShareIcon
					sx={{
						paddingLeft: '30px',
					}}
				/>
				<DeleteOutlineIcon onClick={onDeleteReport} />
			</Box>
		</Box>
	);
}

ReportsSelectView.propTypes = {
	/**
	 * checkedReports is array of index to get the checked reports
	 */
	checkedReports: PropTypes.array,
};

export default ReportsSelectView;
