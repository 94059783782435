import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { featureCRMNavLinks } from './featureCMRNavLinks';
import { SideNavLink, SideNavLinkWrapper, Sublink } from './styles';
import { crmSearchScopes, useCRMSearchScope } from '@worklist-2/core/src';

const CrmSideNav = ({ setIsStats }) => {
	const { updateScope } = useCRMSearchScope();
	const navigate = useNavigate();
	const params = useParams();
	const [anchorEl, setAnchorEl] = useState(null);
	const [hoveringItem, setHoveringItem] = useState(false);
	const crmLink = featureCRMNavLinks();

	const handleLinkHover = (e, link) => {
		const target = e.currentTarget;
		setAnchorEl(!_.isEmpty(link.subNavLink?.filter(entry => entry.navLink)) ? { target, link } : null);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setHoveringItem('');
	};

	const handleNavigate = item => {
		if (item?.path === 'case') {
			updateScope(crmSearchScopes.caseApi);
		}
		setIsStats(item?.path === 'stats');
		navigate(`/${item?.path}`);
	};

	const open = !_.isEmpty(anchorEl);

	const currentLink = useMemo(
		() => _.find(crmLink, link => link.path.includes(params['*']?.split('/')[0])),
		[params, crmLink]
	);

	return (
		<Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
			<SideNavLinkWrapper>
				{featureCRMNavLinks()
					.filter(e => e.iconComponent && e.path !== 'adminSettings')
					.map((item, i) => (
						<NavLinkItem
							key={i}
							currentLink={currentLink}
							handleClose={handleClose}
							handleLinkHover={e => handleLinkHover(e, item)}
							handleNavigate={handleNavigate}
							hoveringItem={hoveringItem}
							item={item}
							setHoveringItem={setHoveringItem}
						/>
					))}

				<Popover
					anchorEl={anchorEl?.target}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={open}
					onClose={handleClose}
				>
					<Box
						sx={{
							padding: '5px',
							color: '#000',
							minWidth: 100,
							background: '#FBFBFB',
							boxShadow: '0px 4px 20px rgba(33, 88, 119, 0.21)',
							borderRadius: '0px 5px 5px 0px',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						{_.map(
							anchorEl?.link?.subNavLink,
							(link, i) =>
								link.navLink && (
									<Sublink
										key={i}
										onClick={() => {
											handleNavigate(link);
											handleClose();
										}}
									>
										{link.iconComponent}
										<span>{link.text}</span>
									</Sublink>
								)
						)}
					</Box>
				</Popover>
			</SideNavLinkWrapper>
			{featureCRMNavLinks()
				.filter(e => e.path === 'adminSettings')
				.map((item, i) => (
					<NavLinkItem
						key={i}
						currentLink={currentLink}
						handleClose={handleClose}
						handleLinkHover={e => handleLinkHover(e, item)}
						handleNavigate={handleNavigate}
						hoveringItem={hoveringItem}
						item={item}
						setHoveringItem={setHoveringItem}
					/>
				))}
		</Box>
	);
};

export default CrmSideNav;

const NavLinkItem = ({
	item,
	currentLink,
	hoveringItem,
	handleLinkHover,
	handleClose,
	handleNavigate,
	setHoveringItem,
}) => {
	const active = useMemo(() => currentLink?.path === item?.path, [currentLink, item]);

	return (
		<SideNavLink
			active={active}
			onClick={e => {
				handleClose();
				handleNavigate(item);
			}}
			onMouseEnter={e => {
				handleLinkHover(e);
				setHoveringItem(item.text);
			}}
			onMouseLeave={() => (item.text === 'Cases' ? '' : setHoveringItem(''))}
		>
			{active || hoveringItem === item.text ? item.activeIconComponent : item.iconComponent}
		</SideNavLink>
	);
};
