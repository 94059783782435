import React from 'react';
import { Typography } from '@mui/material';

export const ModalityPill = ({ value }) => (
	<Typography
		data-testid="modality-pill"
		sx={{
			minWidth: '42px',
			height: '24px',
			background: '#3d3d3d',
			borderRadius: '41px',
			color: 'rgba(255, 255, 255, 0.8)',
			fontWeight: 400,
			fontSize: '14px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			fontStyle: 'italic',
		}}
	>
		{value}
	</Typography>
);

export default ModalityPill;
