// React Libraries
import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import _ from 'lodash';

// Material UI Libraries
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';

// Custom Component Libraries
import { Svg } from '@worklist-2/ui/src/components/Svg';

// Worklist Core Libraries
import { useAuth } from '@worklist-2/core/src';
import { getExtensionValueString } from '@worklist-2/core/src/fhir/resource/columnMapping/utils';

const StyleIcon = styled(Button)({
	minWidth: '40px',
	background: '#42A5F5',
	color: '#fff',
	height: '40px',
	borderRadius: '100px',
	transition: 'all 0.1s',

	'&:hover': {
		background: '#29a0f8',
	},
});

const GreyStyledIcon = styled(StyleIcon)({
	color: '#C4C4C4',
	background: '#FFF',
	minWidth: '40px',
	height: '40px',

	'&:hover': {
		color: '#FFF',
		background: '#29a0f8',
	},
});

function ReportsCard({
	fetchDeletableReport,
	report,
	checkedReports,
	setCheckedReports,
	setDeleteModal,
	onSaveFavorite,
	onOpenShare,
}) {
	const navigate = useNavigate();
	const [openMenu, setOpenMenu] = useState(false);
	const [isFavorite, setIsFavorite] = useState(report.favorite);
	const { loggedInUser } = useAuth();

	const onToggleReportIsChecked = () => {
		const existingReport = checkedReports.find(el => el === report.id);

		if (!existingReport) {
			setCheckedReports(prev => [...prev, report.id]);
		} else {
			setCheckedReports(prev => prev.filter(id => id !== report.id));
		}
	};

	const userPrivilege = useMemo(() => {
		if (!report.premium) {
			const shareUserInfo = _.find(report.forRead, { id: loggedInUser.id });
			if (shareUserInfo) {
				return getExtensionValueString(shareUserInfo, 'privilege');
			}
		}
	}, [report]);

	const isChecked = checkedReports.find(el => el === report.id);

	const onClickDeleteItem = () => {
		setOpenMenu(false);
		fetchDeletableReport(report);
		setDeleteModal(true);
	};

	const onClickFavorite = newFavorite => {
		setTimeout(() => {
			setIsFavorite(newFavorite);
			report.favorite = newFavorite;
			onSaveFavorite(report, newFavorite);
		}, 400);
	};

	const getReportName = name => (
		<Typography
			data-testid="ReportCardName"
			sx={{
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				marginRight: '15px',
			}}
		>
			{name}
		</Typography>
	);

	const onClickShare = () => {
		onOpenShare(report);
	};

	const onGoToDetailsPage = useCallback(() => {
		if (report.premium) {
			navigate(`/stats/report/${report.premium_url}`);
		} else if (report.id) {
			navigate(`/stats/report/${report.id}`);
		}
	}, [report]);

	const generateInitials = label => {
		let initials = '';

		if (!label) return initials;

		const MAX_LENGTH = 2;

		const items = label.trim().split(' ');

		items.slice(0, MAX_LENGTH).forEach(item => {
			initials += item[0];
		});

		return initials.toUpperCase();
	};

	return (
		<Box
			data-testid="ReportCard Detail Box"
			sx={{
				position: 'relative',
				width: '350px',
				height: '226px',
				borderRadius: '10px',
				background: 'rgba(0, 0, 0, 0.2)',
				backdropFilter: 'blur(2px)',
				border: '1px solid rgba(255, 255, 255, 0.3)',
				cursor: 'pointer',
				transition: '0.2s',

				'&:hover .cardHoverContent': {
					display: 'block',
				},
			}}
			onClick={onGoToDetailsPage}
		>
			<img
				alt="report-image"
				src={report.image}
				style={{
					width: '100%',
					height: undefined,
					borderRadius: 'inherit',
				}}
			/>
			<Box
				sx={{
					background: 'rgba(255, 255, 255, 0.9)',
					backdropFilter: 'blur(50px)',
					position: 'absolute',
					bottom: '10px',
					left: '10px',
					right: '10px',
					display: 'flex',
					alignItems: 'center',
					padding: '16px 16px 20px 34px',
					color: 'rgba(0, 0, 0, 0.6)',
					borderRadius: '30px 30px 10px 10px',
					boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
				}}
			>
				{report.name.length > 25 ? (
					<Tooltip arrow placement="bottom" title={report.name}>
						{getReportName(report.name)}
					</Tooltip>
				) : (
					getReportName(report.name)
				)}

				<Box
					sx={{
						background: '#4D79EA',
						minWidth: '35px',
						height: '35px',
						borderRadius: '100px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginLeft: 'auto',
					}}
				>
					<Avatar sx={{ bgcolor: '#4D79EA' }}>{generateInitials(report.name)}</Avatar>
				</Box>
			</Box>
			{report.premium ? (
				<Box
					data-testid="ReportCardCrownIcon"
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
					}}
				>
					<Box
						sx={{
							display: 'flex',
							padding: '10px',
							alignItems: 'start',
							justifyContent: 'space-between',
						}}
					>
						<Svg name="crown" />
					</Box>
				</Box>
			) : (
				<>
					{/*isChecked && (
				<Box
					onClick={(e) => {
						e.stopPropagation();
						onToggleReportIsChecked();
					}}
					sx={{
						width: '24px',
						height: '24px',
						background: '#42A5F5',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						color: '#FFFFFF',
						borderRadius: ' 100px',
						position: 'absolute',
						top: 0,
						left: 0,
						margin: '10px',
						zIndex: 1,

						'.MuiSvgIcon-root': {
							fontSize: '1rem',
						},
					}}
				>
					<CheckIcon />
				</Box>
			)}
			Disabled for now. we can re-enable it if this featur is implemented
			*/}

					<Box
						className="cardHoverContent"
						sx={{
							display: 'none',
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
						}}
					>
						<Box
							sx={{
								display: 'flex',
								padding: '10px',
								alignItems: 'start',
								justifyContent: 'space-between',
							}}
						>
							{!isChecked ? (
								<Box
									// Disabled for now. we can re-enable it if this featur is implemented
									//sx={{
									//	width: '24px',
									//	height: '24px',
									//	background: 'rgba(18, 18, 18, 0.6)',
									//	border: '1px solid rgba(255, 255, 255, 0.6)',
									//	borderRadius: '100px',
									//}}
									onClick={e => {
										e.stopPropagation();
										onToggleReportIsChecked();
									}}
								/>
							) : (
								<div />
							)}
							{userPrivilege == 'admin' ? (
								<StyleIcon
									onClick={e => {
										e.stopPropagation();
										onClickShare();
										setDeleteModal(false);
									}}
								>
									<ShareIcon />
								</StyleIcon>
							) : (
								<div style={{ height: '40px' }} />
							)}
						</Box>
						<Box
							sx={{
								marginTop: '35px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								gap: '10px',
							}}
						>
							{isFavorite ? (
								<StyleIcon>
									<StarBorderIcon
										onClick={e => {
											e.stopPropagation();
											onClickFavorite(false);
											setDeleteModal(false);
										}}
									/>
								</StyleIcon>
							) : (
								<GreyStyledIcon>
									<StarBorderIcon
										onClick={e => {
											e.stopPropagation();
											onClickFavorite(true);
											setDeleteModal(false);
										}}
									/>
								</GreyStyledIcon>
							)}
							{userPrivilege == 'admin' && (
								<GreyStyledIcon>
									<MoreVertIcon
										onClick={e => {
											e.stopPropagation();
											setOpenMenu(true);
											setDeleteModal(false);
										}}
									/>
									{openMenu && (
										<ClickAwayListener onClickAway={() => setOpenMenu(false)}>
											<List
												sx={{
													position: 'absolute',
													background: '#FFF',
													borderRadius: '15px',
													width: '130px',
													color: '#FFFFFFDE',
													left: 15,
													marginTop: 15,
													zIndex: 10,
													transition: '0.2s',
													filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.11))',
													'.MuiSvgIcon-root': {
														fontSize: '1rem',
													},
												}}
											>
												<ListItem disablePadding>
													<ListItemButton
														sx={{
															borderRadius: '15px',
														}}
														onClick={e => {
															e.stopPropagation();
															onClickDeleteItem();
														}}
													>
														{/*
												<ListItemIcon
													sx={{ minWidth: '30px' }}
												>
													<DeleteOutlineOutlinedIcon />												
												</ListItemIcon>
												Disabled for now. we can re-enable it if this feature is implemented
											*/}
														<ListItemText secondary="Delete" />
													</ListItemButton>
												</ListItem>
											</List>
										</ClickAwayListener>
									)}
								</GreyStyledIcon>
							)}
						</Box>
					</Box>
				</>
			)}
		</Box>
	);
}

ReportsCard.propTypes = {
	/**
	 * report is object of a report
	 */
	report: PropTypes.object,
	/**
	 * checkedReports is array of index to get the checked reports
	 */
	checkedReports: PropTypes.array,
	/**
	 * setCheckedReports is function to set checked reports array
	 */
	setCheckedReports: PropTypes.func,
	/**
	 * onDeleteReport is function to delete report
	 */
	onDeleteReport: PropTypes.func,
	/**
	 * onSaveFavorite is function to set favorite report
	 */
	onSaveFavorite: PropTypes.func,
	/**
	 * onOpenShare is function to share the report
	 */
	onOpenShare: PropTypes.func,
};

export default ReportsCard;
