import React from 'react';

const MyProfileIcon = () => (
	<svg fill="none" height="64" viewBox="0 0 85 64" width="85" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_46181)">
			<path
				d="M29.8693 1.82904C25.733 4.05463 22.6983 8.13139 21.368 12.645C20.5782 15.3073 20.3287 18.1361 19.4142 20.7569C16.8368 28.2448 9.39552 33.1328 6.21532 40.3919C4.44855 44.4271 4.13676 49.1487 5.63333 53.3086C7.12989 57.4686 10.4556 60.9838 14.5919 62.4398C17.1486 63.3341 19.8923 63.4797 22.5736 63.5629C27.6453 63.7293 32.7378 63.7501 37.7055 62.8349C40.0751 62.3982 42.4239 61.7742 44.8142 61.5038C49.4494 60.963 54.1677 61.7742 58.7821 61.0462C61.9831 60.5262 65.0178 59.2574 67.9278 57.8014C70.1103 56.699 72.2512 55.4926 74.0388 53.8286C78.7987 49.4607 80.5447 42.3887 79.2767 36.0656C78.0088 29.7424 73.9764 24.1889 68.9463 20.1537C66.7222 18.3857 64.3111 16.8881 61.9831 15.2865C58.99 13.2065 56.1424 10.8978 53.461 8.42259C47.059 2.49464 38.7864 -2.95492 29.8693 1.82904Z"
				fill="#4BAAF6"
				opacity="0.18"
			/>
			<path d="M31.6152 54.0797H49.1583L46.0612 42.9102H34.2342L31.6152 54.0797Z" fill="#4BAAF6" />
			<path
				d="M31.6152 54.0797H49.1583L46.0612 42.9102H34.2342L31.6152 54.0797Z"
				fill="url(#paint0_linear_2852_46181)"
			/>
			<path
				d="M66.6595 13.0626V41.8704C66.6595 43.6176 65.2461 45.0112 63.5209 45.0112H18.0419C16.296 45.0112 14.9033 43.5968 14.9033 41.8704V13.0626C14.9033 11.3155 16.3167 9.92188 18.0419 9.92188H63.5209C65.2668 9.92188 66.6595 11.3363 66.6595 13.0626Z"
				fill="#4BAAF6"
			/>
			<path
				d="M66.6595 13.0639V38.3149H14.9033V13.0639C14.9033 11.3167 16.3167 9.90234 18.0627 9.90234H63.5416C65.2668 9.92314 66.6595 11.3375 66.6595 13.0639Z"
				fill="#473F47"
			/>
			<path
				d="M65.8899 13.6646V37.4805H15.6719V13.6646C15.6719 11.9799 17.0437 10.6279 18.7274 10.6487H62.8344C64.518 10.6279 65.8899 11.9799 65.8899 13.6646Z"
				fill="white"
			/>
			<path
				d="M65.8899 13.6646V37.4805H15.6719V13.6646C15.6719 11.9799 17.0437 10.6279 18.7274 10.6487H62.8344C64.518 10.6279 65.8899 11.9799 65.8899 13.6646Z"
				fill="#4BAAF6"
				opacity="0.18"
			/>
			<path d="M65.4742 25.9805H15.6719V37.6492H65.4742V25.9805Z" fill="white" />
			<path d="M63.6874 22.2578H49.8857V36.2353H63.6874V22.2578Z" fill="url(#paint1_linear_2852_46181)" />
			<path
				d="M65.8899 13.6646V18.0534H15.6719V13.6646C15.6719 11.9799 17.0437 10.6279 18.7274 10.6487H62.8344C64.518 10.6279 65.8899 11.9799 65.8899 13.6646Z"
				fill="#4BAAF6"
			/>
			<path
				d="M65.8899 13.6646V18.0534H15.6719V13.6646C15.6719 11.9799 17.0437 10.6279 18.7274 10.6487H62.8344C64.518 10.6279 65.8899 11.9799 65.8899 13.6646Z"
				fill="url(#paint2_linear_2852_46181)"
			/>
			<path d="M64.6229 21.4219H50.8213V35.3994H64.6229V21.4219Z" fill="white" />
			<path d="M63.9153 22.4844H51.5479V34.5483H63.9153V22.4844Z" fill="#4BAAF6" opacity="0.18" />
			<path
				d="M61.7123 25.9183C62.3322 25.9183 62.8347 25.4154 62.8347 24.7951C62.8347 24.1747 62.3322 23.6719 61.7123 23.6719C61.0924 23.6719 60.5898 24.1747 60.5898 24.7951C60.5898 25.4154 61.0924 25.9183 61.7123 25.9183Z"
				fill="#51E08A"
			/>
			<path
				d="M51.5479 29.2458L54.5618 27.0203L58.4071 29.2458H59.2177L56.4325 27.6027L58.7189 25.8555L64.2063 29.0794V34.5498H51.5479V29.2458Z"
				fill="url(#paint3_linear_2852_46181)"
			/>
			<path
				d="M66.7428 32.0957C67.1169 32.2621 67.4079 32.5533 67.595 32.9069C67.6989 33.1149 67.6781 33.3645 67.5534 33.5517C67.4287 33.6765 67.2624 33.7389 67.0961 33.7181C66.8467 33.6973 66.5973 33.6349 66.3686 33.5101C66.1608 33.4269 65.9737 33.3229 65.7658 33.2189C65.6411 33.1773 65.5372 33.0941 65.4333 32.9901C65.3086 32.8237 65.267 32.5949 65.3086 32.4077C65.3293 32.1997 65.3917 31.7005 65.6619 31.6797C65.849 31.7213 66.0153 31.7629 66.1815 31.8461C66.3686 31.9293 66.5765 32.0125 66.7428 32.0957Z"
				fill="#FFBAAA"
			/>
			<path
				d="M65.8486 31.6382C65.8902 32.2414 65.8694 32.8654 65.7655 33.4686C65.2666 33.3438 64.7885 33.115 64.3936 32.7822C64.2065 32.6574 64.0195 32.5118 63.874 32.3662C63.6869 32.1374 63.5622 31.8462 63.4998 31.555C63.4167 31.0974 63.001 29.9742 63.583 30.2446C64.1442 30.4942 65.2666 31.3262 65.8486 31.6382Z"
				fill="#FBCA1B"
			/>
			<path
				d="M61.5873 46.4236C61.9615 47.0268 62.398 47.5884 62.876 48.1292C63.6243 48.8988 64.3934 49.6267 65.2248 50.3131C65.6405 50.6667 66.0355 51.0203 66.4304 51.3947C66.763 51.7067 67.1787 51.9147 67.241 52.3931C67.2826 52.6843 67.241 53.0171 67.4489 53.2043C67.6152 53.3291 67.8438 53.3499 68.0101 53.2459C68.1764 53.1419 68.3219 52.9963 68.4466 52.8299C68.4882 52.7675 68.5297 52.7051 68.5505 52.6219C68.5713 52.5387 68.5713 52.4555 68.5505 52.3723C68.4674 51.9771 68.1556 51.6859 67.8854 51.3739C67.6567 51.1035 67.4281 50.8123 67.241 50.5003C67.0747 50.2715 66.8877 50.0427 66.6798 49.8347L65.1209 48.0668C64.5597 47.4428 63.9777 46.7772 63.749 45.966C63.6867 45.654 63.562 45.342 63.4165 45.0508C63.0215 44.4684 62.0862 44.3852 61.4626 44.6556C60.7143 44.9884 61.2755 45.9452 61.5873 46.4236Z"
				fill="#FFBAAA"
			/>
			<path
				d="M64.8307 45.2595C64.8307 45.2595 65.2464 48.7955 64.8307 50.7091C64.5812 51.8947 64.4773 53.1011 64.5189 54.2867C64.6228 54.4947 64.5397 54.7651 64.3318 54.8691C64.1239 54.9731 63.8953 55.0355 63.6667 55.0147L62.7521 55.0563C62.6482 55.0771 62.5235 55.0563 62.4403 54.9731C62.3156 54.8483 62.4403 54.6195 62.6066 54.5363C62.7729 54.4531 62.96 54.4531 63.1055 54.3491C63.3964 54.1827 63.438 53.7667 63.438 53.4339L63.4588 51.9571C63.5004 49.8147 63.5212 47.6723 63.2094 45.5715C63.1886 45.4675 63.1886 45.3843 63.2094 45.2803C63.251 45.1139 63.4172 44.9891 63.5835 44.9059C63.7498 44.8019 63.9369 44.7187 64.1447 44.6771C64.3526 44.6355 64.5604 44.7395 64.6644 44.9059"
				fill="#FFBAAA"
			/>
			<path
				d="M59.7791 32.2202C59.7583 32.1994 59.7168 32.1786 59.6752 32.1578C59.4673 32.0954 59.0516 32.3866 58.8645 32.4698C58.2618 32.7402 57.6174 32.865 56.9523 32.8442C56.3495 32.7818 55.7675 32.6362 55.2063 32.449C54.4788 32.241 53.7305 32.1162 53.003 31.8666C52.2755 31.6586 51.6104 31.2635 51.0699 30.7227C50.9452 30.8059 51.0284 31.0347 50.9037 31.1179C50.7789 31.2011 50.675 31.0347 50.5295 31.0347C50.0514 30.9931 50.3424 32.241 50.4256 32.449C50.4464 32.4906 50.4672 32.5322 50.4879 32.5738C50.5295 32.5946 50.5711 32.6154 50.6334 32.6362C52.3794 33.0106 53.8968 34.0922 55.6428 34.4458C56.5158 34.6122 57.4095 34.6122 58.2618 34.425C59.0932 34.2794 59.883 33.905 60.5482 33.3642C60.6105 33.3226 60.6729 33.2394 60.6313 33.177C60.6105 33.1562 60.5897 33.1354 60.5482 33.1146C60.3403 33.0314 60.1533 32.9066 60.0078 32.7402C59.9454 32.5946 59.9038 32.345 59.7791 32.2202Z"
				fill="#FFBAAA"
			/>
			<path
				d="M61.1095 28.7057C61.2134 28.8929 61.4005 29.0177 61.6084 29.0385C61.6707 29.0385 61.7123 29.0385 61.7539 29.0801C61.7954 29.1217 61.7954 29.1841 61.7954 29.2465L61.7331 29.9329C61.7331 29.9953 61.7331 30.0577 61.7539 30.0993C61.7954 30.1409 61.8578 30.1825 61.9202 30.2033C62.1696 30.2657 62.4814 30.3281 62.6684 30.1409C62.7516 30.0369 62.8139 29.8913 62.8139 29.7457C62.9594 28.9553 63.0842 28.1233 62.9594 27.3329C62.8763 26.8545 62.6892 26.3345 62.2735 26.0849C61.7954 25.7937 60.5483 25.7105 60.6107 26.5217C60.673 26.8545 60.6938 27.1665 60.7146 27.4993C60.6938 27.9153 60.8393 28.3521 61.1095 28.7057Z"
				fill="#FFBAAA"
			/>
			<path
				d="M60.5688 25.8558C60.548 25.9806 60.5272 26.1054 60.4856 26.2302L60.4648 26.3966C60.4648 26.4174 60.4648 26.4382 60.4648 26.4382C60.4856 26.459 60.4856 26.459 60.5064 26.4798L60.9429 26.6254C60.9637 26.6462 61.0053 26.6462 61.0468 26.6254C61.0676 26.6254 61.0884 26.6046 61.1092 26.5838L61.2963 26.3758L61.3171 26.5838C61.3171 26.6046 61.3171 26.6254 61.3378 26.6254C61.3378 26.6254 61.3586 26.6462 61.3794 26.6462L62.1901 26.8334L62.2316 26.7086C62.3563 27.499 62.3563 28.3102 62.2108 29.1006C62.876 29.2878 63.5619 29.2878 64.227 29.0798C64.3102 29.059 64.3933 29.0174 64.4765 28.955C64.5596 28.8718 64.622 28.7678 64.6428 28.643C64.9545 27.6446 64.8298 26.5422 64.2894 25.6478C64.04 25.1694 63.5619 24.8574 63.0215 24.8158C62.5018 24.7326 61.9614 24.7742 61.4418 24.9198C61.1923 25.003 60.9845 25.1486 60.8182 25.3358C60.6727 25.4814 60.6103 25.6686 60.5688 25.8558Z"
				fill="#FB682D"
			/>
			<path
				d="M61.3594 37.3365C61.4218 39.0629 60.7774 40.6853 60.3201 42.3493C60.1123 43.0773 59.8836 43.8261 59.9668 44.5541C59.9668 44.5749 59.9668 44.5957 59.9668 44.6165C62.2532 45.2821 64.7474 45.5941 66.9507 44.7205C66.7221 43.5557 66.5974 42.3701 66.5766 41.1845C66.5974 40.7061 66.5558 40.2485 66.4726 39.7909C66.2856 39.0005 65.7867 38.3349 65.2463 37.7109C65.0384 37.5029 64.8722 37.2533 64.7474 36.9621C64.6851 36.6917 64.6643 36.4213 64.7267 36.1509C64.8306 35.2565 64.8514 34.3621 64.789 33.4678C64.7267 32.6566 64.5188 31.8662 64.1655 31.1174C64.02 30.7846 63.8121 30.4726 63.5627 30.2022C63.3132 29.9318 62.9599 29.7862 62.5857 29.7446C62.2116 29.703 61.8582 29.8694 61.6296 30.1814C61.0684 31.0342 60.1538 31.2214 59.4679 31.991C59.4263 32.0326 59.4056 32.0742 59.3848 32.1366C59.3848 32.199 59.4055 32.2406 59.4471 32.303L60.1538 33.4262C60.2162 33.551 60.2993 33.655 60.4033 33.759C60.4864 33.8422 60.5903 33.9254 60.6735 34.0085C60.8813 34.2789 61.0268 34.6117 61.0684 34.9653C61.2555 35.7557 61.3386 36.5461 61.3594 37.3365Z"
				fill="#FBCA1B"
			/>
			<path
				d="M62.0662 54.7671L62.2949 54.6839C62.4819 54.6215 62.669 54.5799 62.8561 54.5591C62.8977 54.5591 62.9184 54.5591 62.96 54.5799C63.0016 54.6007 63.0224 54.6423 63.0432 54.6839C63.0639 54.7463 63.1263 54.8087 63.1887 54.8503C63.3134 54.9127 63.4589 54.9127 63.5836 54.8503C63.7083 54.8087 63.8538 54.7671 63.9785 54.7047C64.1032 54.6423 64.2072 54.5383 64.3319 54.4759C64.3942 54.4343 64.4774 54.4343 64.5605 54.4551C64.6021 54.4759 64.6229 54.4967 64.6437 54.5383C64.6852 54.6007 64.7268 54.6631 64.7476 54.7255C64.7684 54.7879 64.7684 54.8711 64.7476 54.9335C64.7476 54.9959 64.7268 55.0583 64.6852 55.1207C64.6021 55.2039 64.4981 55.2455 64.3942 55.2247L63.2718 55.2871C62.9184 55.3079 62.5651 55.3287 62.2117 55.3287C62.0247 55.3287 61.8168 55.3079 61.6297 55.2663C61.609 55.2663 61.5674 55.2455 61.5674 55.2247C61.5674 55.2039 61.5674 55.1831 61.5674 55.1623C61.6921 54.9335 61.9207 54.7671 62.1702 54.7047"
				fill="#4BAAF6"
			/>
			<path
				d="M68.343 51.9183C68.3222 52.0223 68.343 52.1471 68.3222 52.2719C68.2806 52.3551 68.239 52.4383 68.1767 52.5007L67.9065 52.8335C67.8857 52.8751 67.8441 52.9167 67.8026 52.9375C67.6778 52.9791 67.5531 52.9791 67.4284 52.9167C67.3868 52.8959 67.3661 52.8959 67.3245 52.9167C67.3037 52.9375 67.3037 52.9583 67.2829 52.9791L67.1166 53.5199C67.0543 53.7487 67.0127 53.9775 67.0127 54.2063C67.0335 54.2271 67.0543 54.2479 67.0958 54.2687C67.3453 54.3935 67.7818 53.8319 67.9896 53.6447C68.1975 53.4575 68.343 53.2079 68.4469 52.9375C68.5093 52.7919 68.5716 52.6671 68.6132 52.5215C68.6755 52.3967 68.7171 52.2511 68.7379 52.1055C68.7379 52.0223 68.6963 51.9599 68.634 51.9183C68.5716 51.9391 68.3638 51.7935 68.343 51.9183Z"
				fill="#4BAAF6"
			/>
			<path
				d="M59.946 44.5721C60.2786 44.6969 60.6527 44.6553 60.9437 44.4681C61.4633 44.1353 61.6712 43.4905 61.7751 42.8873C61.983 41.5561 61.8583 40.1002 62.565 38.9562C62.7728 38.6026 63.0846 38.249 63.0223 37.833C62.9599 37.417 62.5858 37.105 62.3571 36.7514C62.1285 36.3354 62.0246 35.8778 62.0038 35.3994C61.9206 34.5258 61.8375 33.6314 61.7751 32.7578C61.7751 32.9866 60.7982 33.4442 60.4448 33.7978C60.528 33.881 60.6319 33.9642 60.7151 34.0474C60.9229 34.3178 61.0684 34.6506 61.11 35.0042C61.2555 35.7738 61.3386 36.5642 61.3594 37.3546C61.4218 39.081 60.7774 40.7033 60.3201 42.3673C60.1123 43.0745 59.8836 43.8233 59.946 44.5721Z"
				fill="url(#paint4_linear_2852_46181)"
			/>
			<path
				d="M25.8778 18.094C25.8778 19.862 24.4436 21.2972 22.6768 21.2972C20.9101 21.2972 19.4551 19.862 19.4551 18.094C19.4551 16.326 20.8893 14.8908 22.6561 14.8908C24.4436 14.87 25.8778 16.3052 25.8778 18.094C25.8778 18.094 25.8778 18.0732 25.8778 18.094Z"
				fill="white"
			/>
			<path
				d="M24.9843 20.3203C23.8826 21.4643 22.1159 21.6515 20.8271 20.7363L20.9934 20.1747C21.0974 19.7587 21.4715 19.4883 21.8872 19.4883H22.2406L23.5916 19.5091C23.9866 19.5091 24.3815 19.6755 24.6725 19.9667L24.9843 20.3203Z"
				fill="#FBCA1B"
			/>
			<path
				d="M21.3467 18.9469L23.7786 18.8429C23.7786 18.8429 24.0072 16.7837 23.1134 16.3677C22.282 15.9725 21.6169 16.3261 21.4298 17.0333C21.222 17.7405 21.3467 18.9469 21.3467 18.9469Z"
				fill="#FB682D"
			/>
			<path
				d="M22.2402 18.867V19.491C22.2402 19.5534 22.2402 19.6158 22.2402 19.6782C22.3026 19.8238 22.4065 19.9486 22.552 20.011C22.6144 20.0526 22.6767 20.0734 22.7391 20.0734C22.8014 20.0734 22.8638 20.0526 22.9054 20.011C22.9262 19.9694 22.9469 19.907 22.9677 19.8654C23.0093 19.7406 23.0301 19.6158 23.0093 19.491C22.9677 19.387 22.9469 19.283 22.9262 19.1582L22.8222 18.7422L22.2402 18.867Z"
				fill="#FFBAAA"
			/>
			<path
				d="M22.2617 19.095C22.2617 19.095 22.4904 19.5734 22.7606 19.5318C23.0308 19.4694 22.9269 19.0742 22.9269 19.0742L22.2617 19.095Z"
				fill="url(#paint5_linear_2852_46181)"
			/>
			<path
				d="M23.301 18.4282C23.2594 18.6154 23.1971 18.7818 23.0931 18.9274C22.9892 19.073 22.8229 19.177 22.6359 19.1978C22.5111 19.1978 22.3864 19.1562 22.2617 19.073C21.846 18.8026 21.6797 18.2826 21.6589 17.8042C21.5966 17.409 21.7421 17.0138 21.9915 16.7434C22.4072 16.4314 22.9684 16.4938 23.301 16.9098C23.5712 17.305 23.4049 18.0122 23.301 18.4282Z"
				fill="#FFBAAA"
			/>
			<path
				d="M21.659 17.0754L21.5967 17.7618H21.8253L21.9085 17.325L21.95 17.7618H22.3865L22.4281 17.325L22.4905 17.7202H22.927V17.3042L23.0517 17.741H23.4674C23.4674 17.741 23.7168 16.9298 23.1556 16.6386C22.5944 16.3474 21.8253 16.4722 21.659 17.0754Z"
				fill="#FB682D"
			/>
			<path
				d="M18.7074 22.8594H28.0817C28.2065 22.8594 28.3312 22.9634 28.3312 23.109V23.1506C28.3312 23.2754 28.2272 23.4002 28.0817 23.4002H18.7074C18.5827 23.4002 18.458 23.2962 18.458 23.1506V23.109C18.458 22.9842 18.5827 22.8594 18.7074 22.8594Z"
				fill="#4BAAF6"
			/>
			<path
				d="M18.6659 24.1289H24.9847C25.0886 24.1289 25.1925 24.2121 25.1925 24.3369V24.4617C25.1925 24.5657 25.1094 24.6697 24.9847 24.6697H18.6659C18.5619 24.6697 18.458 24.5865 18.458 24.4617V24.3369C18.458 24.2121 18.5619 24.1289 18.6659 24.1289Z"
				fill="#4BAAF6"
			/>
			<path
				d="M26.5435 24.1289H32.6753C32.7792 24.1289 32.8623 24.2121 32.8623 24.3161V24.4409C32.8623 24.5449 32.7792 24.6281 32.6753 24.6281H26.5435C26.4396 24.6281 26.3564 24.5449 26.3564 24.4409V24.3161C26.3564 24.2121 26.4396 24.1289 26.5435 24.1289Z"
				fill="#4BAAF6"
			/>
			<path
				d="M33.6727 24.1289H39.8044C39.9084 24.1289 39.9915 24.2121 39.9915 24.3161V24.4409C39.9915 24.5449 39.9084 24.6281 39.8044 24.6281H33.6727C33.5687 24.6281 33.4856 24.5449 33.4856 24.4409V24.3161C33.4648 24.2121 33.548 24.1289 33.6727 24.1289Z"
				fill="#4BAAF6"
			/>
			<path d="M43.8573 28.4141H27.0625V30.806H43.8573V28.4141Z" fill="#4BAAF6" opacity="0.18" />
			<path d="M43.8573 32.5742H27.0625V34.9662H43.8573V32.5742Z" fill="#4BAAF6" opacity="0.18" />
		</g>
		<defs>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint0_linear_2852_46181"
				x1="41.4006"
				x2="39.5115"
				y1="40.4451"
				y2="65.8902"
			>
				<stop offset="0.01" />
				<stop offset="0.08" stopOpacity="0.69" />
				<stop offset="0.21" stopOpacity="0.32" />
				<stop offset="1" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint1_linear_2852_46181"
				x1="100.684"
				x2="4.93087"
				y1="7.37669"
				y2="55.0249"
			>
				<stop offset="0.01" />
				<stop offset="0.13" stopOpacity="0.69" />
				<stop offset="0.25" stopOpacity="0.32" />
				<stop offset="1" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint2_linear_2852_46181"
				x1="41.3518"
				x2="39.405"
				y1="4.58485"
				y2="43.2655"
			>
				<stop offset="0.01" />
				<stop offset="0.13" stopOpacity="0.69" />
				<stop offset="0.25" stopOpacity="0.32" />
				<stop offset="1" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint3_linear_2852_46181"
				x1="53.9078"
				x2="64.3834"
				y1="45.8727"
				y2="9.01198"
			>
				<stop offset="0.01" />
				<stop offset="0.13" stopOpacity="0.69" />
				<stop offset="0.25" stopOpacity="0.32" />
				<stop offset="1" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint4_linear_2852_46181"
				x1="74.2254"
				x2="41.8919"
				y1="35.7939"
				y2="42.9325"
			>
				<stop offset="0.01" />
				<stop offset="0.13" stopOpacity="0.69" />
				<stop offset="0.25" stopOpacity="0.32" />
				<stop offset="1" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint5_linear_2852_46181"
				x1="22.1887"
				x2="23.4963"
				y1="18.004"
				y2="21.4449"
			>
				<stop offset="0.01" />
				<stop offset="0.13" stopOpacity="0.69" />
				<stop offset="0.25" stopOpacity="0.32" />
				<stop offset="1" stopOpacity="0" />
			</linearGradient>
			<clipPath id="clip0_2852_46181">
				<rect fill="white" height="63.6475" width="84.3896" />
			</clipPath>
		</defs>
	</svg>
);

export default MyProfileIcon;
