// core
import React, { useCallback, useRef, useState } from 'react';
import { fhirExtensionUrls } from '@worklist-2/core/src';
// libraries
import PropTypes from 'prop-types';
import { FileDrop } from 'react-file-drop';
import HoldToDelete from '../../components/HoldToDelete';
// mui
import FormLabel from '@mui/material/FormLabel';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Stack from '@mui/material/Stack';
import { isValidBase64ImageData } from '../utils/signatureUtils';

export const SignatureDropZone = ({ disabled, handleUpdateSignature, signature, showToast }) => {
	// ref
	const inputRef = useRef(null);
	const [signatureData, setSignatureData] = useState(signature);
	const maxAllowedSize = 1 * 1024 * 1024;
	const isSignatureValid = isValidBase64ImageData(signatureData);

	// triggers when file is dropped
	const onFilesDrop = useCallback(files => {
		if (files[0].size > maxAllowedSize) {
			showToast('Maximum allowed size ~1 Mb');
			return;
		}
		createSignatureData(files);
	}, []);

	// triggers when file is selected with click
	const handleChange = e => {
		e.preventDefault();
		if (e.target.files && e.target.files[0]) {
			onFilesDrop(e.target.files);
		}
	};
	const getBase64 = file =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			if (reader !== undefined && file !== undefined) {
				reader.readAsDataURL(file);
			}
			reader.onload = function () {
				{
					resolve(reader.result);
				}
			};
			reader.onerror = reject;
		});

	const createSignatureData = async dropFiles => {
		const signatureDict = {
			url: fhirExtensionUrls.practitioner.reportSignature,
			valueAttachment: '',
		};
		const promise = getBase64(dropFiles[0]);
		const valueAttachment = {
			contentType: dropFiles[0].type,
			data: await promise,
			size: dropFiles[0].size,
			title: dropFiles[0].name,
		};
		signatureDict.valueAttachment = valueAttachment;
		setSignatureData(valueAttachment.data);
		handleUpdateSignature(valueAttachment);
	};

	return !disabled ? (
		<FormLabel
			id="label-file-upload"
			sx={{
				height: 'inherit',
				width: '100%',
				position: 'absolute',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				border: '4px dashed #FFFFFF',
				opacity: '0.6',
				borderRadius: '8px',
			}}
		>
			<FileDrop onDrop={onFilesDrop}>
				<input
					ref={inputRef}
					hidden
					accept="image/jpeg, image/jpg, image/png"
					data-testid="input-file-upload"
					id="input-file-upload"
					type="file"
					onChange={handleChange}
				/>
				{isSignatureValid && (
					<>
						<HoldToDelete
							sx={{
								position: 'absolute',
								top: '5px',
								right: '10px',
							}}
							tooltipText="Hold to Delete"
							onDelete={() => {
								setSignatureData({});
								handleUpdateSignature({});
							}}
						/>
						{isSignatureValid && (
							<img
								alt="File preview"
								src={signatureData}
								style={{
									padding: '15px',
								}}
							/>
						)}
					</>
				)}
				<Stack alignItems="center" direction="column" justifyContent="center" spacing={0.3}>
					<FileUploadIcon
						sx={{
							alignItems: 'center',
							paddingLeft: '15px',
							paddingTop: isSignatureValid ? '12px' : '40px',
						}}
					/>
					<p style={{ fontSize: '14px' }}>Drag and drop or</p>
					<p
						style={{
							fontSize: '14px',
							textDecorationLine: 'underline',
						}}
					>
						click to browse files
					</p>
				</Stack>
			</FileDrop>
		</FormLabel>
	) : (
		<img alt="File preview" src={signatureData} />
	);
};

SignatureDropZone.propTypes = {
	/**
	 * disable  or enable the dropArea
	 */
	disabled: PropTypes.bool,
};
