/**
 * Convert Array to string with delimiter ' | ' or a custom delimiter
 * @param  {Array} value - The array to be converted.
 * @param  {String} propertyGetter - Which property needs to be retrieved from the object. By default, "display" will be retrieved if possible.
 * @param  {Boolean} filterFlag - Designates whether the returned value should be formatted to be useable in a filter (sorted and no duplicates) or not.
 * @param  {Boolean} convertToArray - Designates whether the returned value is an array or not.
 * @param  {String} customDelimiter - A custom delimiter for seperating the items in the returned string.
 * @return {String}
 */
const convertArrayToString = (
	value,
	propertyGetter,
	filterFlag = false,
	convertToArray = false,
	customDelimiter = null
) => {
	// Return the value if it's not array type. Otherwise, continue
	if (!Array.isArray(value)) {
		return value;
	}

	const delimiter = customDelimiter ? customDelimiter : ' | ';

	let propertyGetterName = propertyGetter || 'display',
		valueDisplay = [],
		item;

	for (item of value) {
		if (item[propertyGetterName]) {
			valueDisplay.push(item[propertyGetterName]);
		} else if (item.data) {
			valueDisplay.push(item.data.value);
		} else if (item.coding) {
			valueDisplay.push(item.coding[0]?.code);
		} else if (item) {
			valueDisplay.push(item);
		}
	}

	if (filterFlag) {
		//remove duplicated items
		valueDisplay = valueDisplay.sort().reduce((accumulator, current) => {
			const length = accumulator.length;
			if (length === 0 || accumulator[length - 1] !== current) {
				accumulator.push(current);
			}
			return accumulator;
		}, []);
	}

	return convertToArray ? valueDisplay : valueDisplay.join(delimiter);
};

export default convertArrayToString;
