import React, { useState } from 'react';

const HelpCenterContext = React.createContext({});

const useHelpCenterContext = () => React.useContext(HelpCenterContext);

const HelpCenterContextProvider = ({ children }) => {
	const [activeDeviceType, setActiveDeviceType] = useState('desktop');
	const [width, setWidth] = useState(1920);
	const [height, setHeight] = useState(1080);
	const [responsive, setResponsive] = useState(false);
	const [resolution, setResolution] = useState({ width: 1920, height: 1080 });
	const [categories, setCategories] = useState([]);

	const inputWidth = width => {
		if (activeDeviceType === 'desktop' && width >= 1024) {
			setResolution(res => ({ ...res, width }));
		} else if (activeDeviceType === 'tablet' && width >= 600) {
			setResolution(res => ({ ...res, width }));
		} else if (activeDeviceType === 'mobile' && width >= 320) {
			setResolution(res => ({ ...res, width }));
		}
		setWidth(width);
	};

	const inputHeight = height => {
		if (activeDeviceType === 'desktop' && height >= 576) {
			setResolution(res => ({ ...res, height }));
		} else if (activeDeviceType === 'tablet' && height >= 600) {
			setResolution(res => ({ ...res, height }));
		} else if (activeDeviceType === 'mobile' && height >= 360) {
			setResolution(res => ({ ...res, height }));
		}
		setHeight(height);
	};

	return (
		<HelpCenterContext.Provider
			value={{
				activeDeviceType,
				setActiveDeviceType,
				width,
				resolution,
				setResolution,
				inputWidth,
				inputHeight,
				height,
				responsive,
				setResponsive,
				setWidth,
				categories,
				setCategories,
			}}
		>
			{children}
		</HelpCenterContext.Provider>
	);
};

export default HelpCenterContextProvider;
export { useHelpCenterContext };
