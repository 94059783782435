/* Generated automagically by FhirStarter - DO NOT EDIT */

import FhirDomainResource from '../base/FhirDomainResource';
import auditEventSchema from './schemata/auditEventSchema';
/************************************************************************************************************************
Resource: AuditEvent
Reference: https://www.hl7.org/fhir/AuditEvent.html


The audit event is based on the IHE-ATNA Audit record definitions, originally from RFC 3881 , and now managed by DICOM (see
DICOM Part 15 Annex A5 ).

This resource is managed collaboratively between HL7, DICOM, and IHE.

The primary purpose of this resource is the maintenance of security audit log information.

However, it can also be used for any audit logging needs and simple event-based notification. 
************************************************************************************************************************/

export default class AuditEventResource extends FhirDomainResource {
	type;
	subtype = [];
	action;
	period;
	recorded;
	outcome;
	outcomeDesc;
	purposeOfEvent = [];
	agent = [{}];
	source_site;
	source_observer;
	source_type = [];
	entity = [{}];

	constructor(resourceString) {
		super(resourceString, auditEventSchema);
		this.resourceType = 'AuditEvent';
		this.populateFields();
	}
}
