import React from 'react';

// MUI
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

// worklist core
import { Svg } from '@rs-ui/components/Svg';
import { useTranslation } from 'react-i18next';

import getExtensionValueString from '@rs-core/fhir/resource/columnMapping/utils/getExtensionValueString';
import { fhirExtensionUrls } from '@rs-core/fhir/extension/fhirExtensionUrls';
import { useSelection } from '@worklist-2/core/src/context/SelectionContext';

const preAuthStatusIcons = {
	Approved: {
		name: 'Approved',
		iconId: 'preAuthAllCleared',
	},
	Denied: {
		name: 'Denied',
		iconId: 'preAuthDenied',
	},
	Expired: {
		name: 'Expired',
		iconId: 'preAuthDenied',
	},
	Withdrawn: {
		name: 'Denied',
		iconId: 'preAuthDenied',
	},
	PreAuthPartiallyClearedIcon: {
		name: 'Some not cleared',
		iconId: 'preAuthPartiallyCleared',
	},
	Pending: {
		name: 'Pending',
		iconId: 'preAuthPending',
	},
	Unknown: {
		name: 'Pending',
		iconId: 'preAuthPending',
	},
	'Auth Required': {
		name: 'Authorization Required',
		iconId: 'preAuthPartiallyCleared',
	},
	'No Auth Required': {
		name: 'No Auth Required',
		iconId: 'preAuthAllCleared',
	},
	'In Review': {
		name: 'Pending',
		iconId: 'preAuthPending',
	},
};

const eligibilityStatusIcons = {
	Active: {
		name: 'Eligible',
		iconId: 'eligible',
	},
	Mixed: {
		name: 'Some not cleared',
		iconId: 'eligibleMixed',
	},
	Inactive: {
		name: 'Denied',
		iconId: 'eligibleDenied',
	},
	Unknown: {
		name: 'Pending',
		iconId: 'eligiblePending',
	},
};

// Charge status list
const chargeStatusList = {
	'Charge Posted': {
		iconId: 'chargePosted',
		name: 'Charge Posted',
	},
	'Charge Post Failed': {
		iconId: 'chargePostFailed',
		name: 'Charge Post Failed',
	},
	'Charge Post Pending': {
		iconId: 'chargePostPending',
		name: 'Charge Post Pending',
	},
};

export const GridStatusIndicators = ({ data, studyData }) => {
	const { t } = useTranslation('imagingWorkListColumns');
	const { setPreAuthStudy } = useSelection();

	const handleClick = studyRes => {
		setPreAuthStudy(studyRes);
	};

	if (!data.indicators || data.indicators.length === 0) {
		return null;
	}

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				width: 'fit-content',
				'& svg': {
					m: 1,
				},
				'& hr': {
					mx: 0.5,
				},
				gap: '3px',
			}}
		>
			{data?.indicators?.map(indicator =>
				indicator.iconId.includes('eligible') || indicator.iconId.includes('preAuth') ? (
					<Tooltip key={indicator.iconId} title={t(indicator.name)}>
						<div style={{ cursor: 'pointer' }}>
							<IconBox
								handleClick={e => {
									e.stopPropagation();
									handleClick(studyData);
								}}
							>
								<Svg
									data-testid={indicator.iconId}
									name={indicator.iconId}
									sx={{
										fontSize: '21px',
									}}
								/>
							</IconBox>
						</div>
					</Tooltip>
				) : (
					<Tooltip key={indicator.iconId} title={t(indicator.name)}>
						<div style={{ cursor: 'pointer' }}>
							<IconBox>
								<Svg
									data-testid={indicator.iconId}
									name={indicator.iconId}
									sx={{
										fontSize: '21px',
									}}
								/>
							</IconBox>
						</div>
					</Tooltip>
				)
			)}
		</Box>
	);
};

const IconBox = ({ handleClick, children }) => (
	<Box
		sx={{
			height: '21px',
			width: '21px',
			borderRadius: '6px',
			background: '#353535',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}
		onClick={handleClick}
	>
		{children}
	</Box>
);

export const GetStatusIndicatorDisplay = (column, row) => {
	// determine if users have enabled the pre-auth and eligibility status indicators
	const preAuthConfig = column.columnDef.options.statusIndicators?.find(x => x.name === 'Pre-auth');
	const eligibilityConfig = column.columnDef.options.statusIndicators?.find(x => x.name === 'Eligibility');
	const chargeConfig = column.columnDef.options.statusIndicators?.find(x => x.name === 'Charge-posting');

	const statusIndicator = { access: '', indicators: [] };

	const preAuthStatus = preAuthStatusIcons[row?.priorStatus];
	const includePreAuthStatus = preAuthConfig ? preAuthStatus && preAuthConfig.checked : preAuthStatus;

	if (includePreAuthStatus) {
		statusIndicator.indicators = [...statusIndicator.indicators, preAuthStatus];
	}

	const eligibilityStatus = eligibilityStatusIcons[row?.eligibilityStatus];
	const includeEligibilityStatus = eligibilityConfig
		? eligibilityStatus && eligibilityConfig.checked
		: eligibilityStatus;

	if (includeEligibilityStatus) {
		statusIndicator.indicators = [...statusIndicator.indicators, eligibilityStatus];
	}

	// Charge posting status
	if (chargeConfig?.checked) {
		const currentStatus = getExtensionValueString(row, fhirExtensionUrls.imagingStudy.chargePostingState);
		const isPosted = getExtensionValueString(row, fhirExtensionUrls.imagingStudy.chargePosted) === 'true';
		const statusObj = chargeStatusList[currentStatus]
			? chargeStatusList[currentStatus]
			: isPosted // will show pending if charge has been sent to Mirth but not posted
			? chargeStatusList['Charge Post Pending']
			: null;

		statusObj && statusIndicator.indicators.push(statusObj);
	}

	return statusIndicator;
};

const StatusIndicatorCell = ({ column, row }) => (
	<GridStatusIndicators
		data={GetStatusIndicatorDisplay(column, row)}
		studyData={{
			id: row.id,
			internalManagingOrganizationId: row.internalManagingOrganizationID,
			patientId: row?.subject?.id,
			orderId: row?.basedOn?.[0]?.id,
		}}
	/>
);
export default StatusIndicatorCell;
