export const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

export const reorderCaseMap = ({ caseMap, source, destination }) => {
	const current = [...caseMap[source.droppableId]];
	const next = [...caseMap[destination.droppableId]];
	const target = current[source.index];

	// moving to same list
	if (source.droppableId === destination.droppableId) {
		const reordered = reorder(current, source.index, destination.index);
		return {
			...caseMap,
			[source.droppableId]: reordered,
		};
	}

	// moving to different list

	// remove from original
	current.splice(source.index, 1);
	// insert into next
	next.splice(destination.index, 0, target);

	return {
		...caseMap,
		[source.droppableId]: current,
		[destination.droppableId]: next,
	};
};
