import {
	CONSTANTS,
	getEnabledElement,
	VolumeViewport,
	utilities as csUtils,
	getEnabledElementByIds,
} from '@cornerstonejs/core';
import { vec3 } from 'gl-matrix';
import {
	removeAnnotation,
	getAnnotationManager,
} from '@cornerstonejs/tools/dist/esm/stateManagement/annotation/annotationState';
import { polyline } from '@cornerstonejs/tools/dist/esm/utilities/math';
import { filterAnnotationsForDisplay } from '@cornerstonejs/tools/dist/esm/utilities/planar';
import { getViewportIdsWithToolToRender } from '@cornerstonejs/tools/dist/esm/utilities/viewportFilters';
import triggerAnnotationRenderForViewportIds from '@cornerstonejs/tools/dist/esm/utilities/triggerAnnotationRenderForViewportIds';
import registerDrawLoop from './ShutterFreehandTool/drawLoop';
import registerEditLoopCommon from './ShutterFreehandTool/editLoopCommon';
import registerClosedContourEditLoop from './ShutterFreehandTool/closedContourEditLoop';
import registerOpenContourEditLoop from './ShutterFreehandTool/openContourEditLoop';
import registerOpenContourEndEditLoop from './ShutterFreehandTool/openContourEndEditLoop';
import registerRenderMethods from './ShutterFreehandTool/renderMethods';
import { ContourSegmentationBaseTool } from './ShutterFreehandTool/ContourSegmentationBaseTool';
import { KeyboardBindings } from '@cornerstonejs/tools/dist/esm/enums';
import { renderingEngineId } from '../../contexts/ImageViewerCornerstoneContext';

const { pointCanProjectOnLine } = polyline;
const { EPSILON } = CONSTANTS;

const PARALLEL_THRESHOLD = 1 - EPSILON;

class ShutterFreehandTool extends ContourSegmentationBaseTool {
	toolName;

	commonData;

	isDrawing = false;

	isEditingClosed = false;

	isEditingOpen = false;

	constructor(
		toolProps = {},
		defaultToolProps = {
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				shadow: true,
				preventHandleOutsideImage: false,
				/**
				 * Specify which modifier key is used to add a hole to a contour. The
				 * modifier must be pressed when the first point of a new contour is added.
				 */
				contourHoleAdditionModifierKey: KeyboardBindings.Shift,
				alwaysRenderOpenContourHandles: {
					// When true, always render end points when you have an open contour, rather
					// than just rendering a line.
					enabled: true,
					// When enabled, use this radius to draw the endpoints whilst not hovering.
					radius: 2,
				},
				allowOpenContours: false,
				// Proximity in canvas coordinates used to join contours.
				closeContourProximity: 10,
				// The proximity at which we fallback to the simplest grabbing logic for
				// determining what index of the contour to start editing.
				checkCanvasEditFallbackProximity: 6,
				// For closed contours, make them clockwise
				// This can be useful if contours are compared between slices, eg for
				// interpolation, and does not cause problems otherwise so defaulting to true.
				makeClockWise: true,
				// The relative distance that points should be dropped along the polyline
				// in units of the image pixel spacing. A value of 1 means that nodes must
				// be placed no closed than the image spacing apart. A value of 4 means that 4
				// nodes should be placed within the space of one image pixel size. A higher
				// value gives more finesse to the tool/smoother lines, but the value cannot
				// be infinite as the lines become very computationally expensive to draw.
				subPixelResolution: 4,
				/**
				 * Smoothing is used to remove jagged irregularities in the polyline,
				 * as opposed to interpolation, which is used to create new polylines
				 * between existing polylines.
				 */
				smoothing: {
					smoothOnAdd: false,
					smoothOnEdit: false, // used for edit only
					knotsRatioPercentageOnAdd: 40,
					knotsRatioPercentageOnEdit: 40,
				},
				/**
				 * Interpolation is the creation of new segmentations in between the
				 * existing segmentations/indices.  Note that this does not apply to
				 * ROI values, since those annotations are individual annotations, not
				 * connected in any way to each other, whereas segmentations are intended
				 * to be connected 2d + 1 dimension (time or space or other) volumes.
				 */
				interpolation: {
					enabled: false,
					// Callback to update the annotation or perform other action when the
					// interpolation is complete.
					onInterpolationComplete: null,
				},
				/**
				 * The polyline may get processed in order to reduce the number of points
				 * for better performance and storage.
				 */
				decimate: {
					enabled: true,
					/** A maximum given distance 'epsilon' to decide if a point should or
					 * shouldn't be added the resulting polyline which will have a lower
					 * number of points for higher `epsilon` values.
					 */
					epsilon: 0.1,
				},
			},
		}
	) {
		super(toolProps, defaultToolProps);

		// Register event loops and rendering logic, which are stored in different
		// Files due to their complexity/size.
		registerDrawLoop(this);
		registerEditLoopCommon(this);
		registerClosedContourEditLoop(this);
		registerOpenContourEditLoop(this);
		registerOpenContourEndEditLoop(this);
		registerRenderMethods(this);
	}

	/**
	 * Based on the current position of the mouse and the current image, creates
	 * a `PlanarFreehandROIAnnotation` and stores it in the annotationManager.
	 *
	 * @param evt - `EventTypes.NormalizedMouseEventType`
	 * @returns The `PlanarFreehandROIAnnotation` object.
	 */
	addNewAnnotation = evt => {
		const eventDetail = evt.detail;
		const { element, viewportId } = eventDetail;
		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		const annotation = this.createAnnotation(evt);

		const enabledViewport = getEnabledElementByIds(viewportId, renderingEngineId);

		if (enabledViewport?.viewport) {
			const imageId = enabledViewport.viewport?.getCurrentImageId();
			const annotationManager = getAnnotationManager();
			const annotations = annotationManager.getAllAnnotations();

			annotations?.forEach(_annotation => {
				if (
					_annotation.metadata?.toolName === ShutterFreehandTool.toolName &&
					_annotation.metadata?.referencedImageId === imageId
				) {
					removeAnnotation(_annotation.annotationUID);
				}
			});
		}

		this.addAnnotation(annotation, element);

		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.activateDraw(evt, annotation, viewportIdsToRender);
		evt.preventDefault();
		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		return annotation;
	};

	handleSelectedCallback = (evt, annotation, handle) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.activateOpenContourEndEdit(evt, annotation, viewportIdsToRender, handle);
	};

	toolSelectedCallback = (evt, annotation) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		if (annotation.data.contour.closed) {
			this.activateClosedContourEdit(evt, annotation, viewportIdsToRender);
		} else {
			this.activateOpenContourEdit(evt, annotation, viewportIdsToRender);
		}
	};

	isPointNearTool = (element, annotation, canvasCoords, proximity) => {
		const enabledElement = getEnabledElement(element);
		const { viewport } = enabledElement;

		const { polyline: points } = annotation.data.contour;

		// NOTE: It is implemented this way so that we do not double calculate
		// points when number crunching adjacent line segments.
		let previousPoint = viewport.worldToCanvas(points[0]);

		for (let i = 1; i < points.length; i++) {
			const p1 = previousPoint;
			const p2 = viewport.worldToCanvas(points[i]);
			const canProject = pointCanProjectOnLine(canvasCoords, p1, p2, proximity);

			if (canProject) {
				return true;
			}

			previousPoint = p2;
		}

		if (!annotation.data.contour.closed) {
			// Contour is open, don't check last point to first point.
			return false;
		}

		// check last point to first point
		const pStart = viewport.worldToCanvas(points[0]);
		const pEnd = viewport.worldToCanvas(points[points.length - 1]);

		return pointCanProjectOnLine(canvasCoords, pStart, pEnd, proximity);
	};

	cancel = (element: HTMLDivElement): void => {
		const isDrawing = this.isDrawing;
		const isEditingOpen = this.isEditingOpen;
		const isEditingClosed = this.isEditingClosed;

		if (isDrawing) {
			this.cancelDrawing(element);
		} else if (isEditingOpen) {
			this.cancelOpenContourEdit(element);
		} else if (isEditingClosed) {
			this.cancelClosedContourEdit(element);
		}
	};

	filterInteractableAnnotationsForElement(element, annotations) {
		if (!annotations || !annotations.length) {
			return;
		}

		const enabledElement = getEnabledElement(element);
		const { viewport } = enabledElement;

		let annotationsToDisplay;

		if (viewport instanceof VolumeViewport) {
			const camera = viewport.getCamera();

			const { spacingInNormalDirection } = csUtils.getTargetVolumeAndSpacingInNormalDir(viewport, camera);

			// Get data with same normal and within the same slice
			annotationsToDisplay = this.filterAnnotationsWithinSlice(annotations, camera, spacingInNormalDirection);
		} else {
			// Use the default `filterAnnotationsForDisplay` utility, as the stack
			// path doesn't require handles.
			annotationsToDisplay = filterAnnotationsForDisplay(viewport, annotations);
		}

		return annotationsToDisplay;
	}

	filterAnnotationsWithinSlice(annotations, camera, spacingInNormalDirection) {
		const { viewPlaneNormal } = camera;

		const annotationsWithParallelNormals = annotations.filter(td => {
			const annotationViewPlaneNormal = td.metadata.viewPlaneNormal;

			const isParallel = Math.abs(vec3.dot(viewPlaneNormal, annotationViewPlaneNormal)) > PARALLEL_THRESHOLD;

			return annotationViewPlaneNormal && isParallel;
		});

		// No in plane annotations.
		if (!annotationsWithParallelNormals.length) {
			return [];
		}

		// Annotation should be within the slice, which means that it should be between
		// camera's focalPoint +/- spacingInNormalDirection.

		const halfSpacingInNormalDirection = spacingInNormalDirection / 2;
		const { focalPoint } = camera;

		const annotationsWithinSlice = [];

		for (const annotation of annotationsWithParallelNormals) {
			const data = annotation.data;
			const point = data.contour.polyline[0];

			if (!annotation.isVisible) {
				continue;
			}

			// A = point
			// B = focal point
			// P = normal

			// B-A dot P  => Distance in the view direction.
			// this should be less than half the slice distance.

			const dir = vec3.create();

			vec3.sub(dir, focalPoint, point);

			const dot = vec3.dot(dir, viewPlaneNormal);

			if (Math.abs(dot) < halfSpacingInNormalDirection) {
				annotationsWithinSlice.push(annotation);
			}
		}

		return annotationsWithinSlice;
	}

	isContourSegmentationTool() {
		// Disable contour segmentation behavior because it shall be activated only
		// for PlanarFreehandContourSegmentationTool
		return false;
	}

	createAnnotation(evt) {
		const worldPos = evt.detail.currentPoints.world;
		const contourAnnotation = super.createAnnotation(evt);

		const onInterpolationComplete = annotation => {
			// Clear out the handles because they aren't used for straight freeform
			annotation.data.handles.points.length = 0;
		};

		return csUtils.deepMerge(contourAnnotation, {
			data: {
				contour: {
					polyline: [[...worldPos]],
				},
				label: '',
			},
			onInterpolationComplete,
		});
	}

	getAnnotationStyle(context) {
		// This method exists only because `super` cannot be called from
		// _getRenderingOptions() which is in an external file.
		return super.getAnnotationStyle(context);
	}

	renderAnnotationInstance(renderContext) {
		const { enabledElement, svgDrawingHelper } = renderContext;
		const annotation = renderContext.annotation;

		let renderStatus = false;

		const isDrawing = this.isDrawing;
		const isEditingOpen = this.isEditingOpen;
		const isEditingClosed = this.isEditingClosed;

		if (!(isDrawing || isEditingOpen || isEditingClosed)) {
			// No annotations are currently being modified, so we can just use the
			// render contour method to render all of them
			this.renderContour(enabledElement, svgDrawingHelper, annotation);
		} else {
			// The active annotation will need special rendering treatment. Render all
			// other annotations not being interacted with using the standard renderContour
			// rendering path.
			const activeAnnotationUID = this.commonData.annotation.annotationUID;

			if (annotation.annotationUID === activeAnnotationUID) {
				if (isDrawing) {
					this.renderContourBeingDrawn(enabledElement, svgDrawingHelper, annotation);
				} else if (isEditingClosed) {
					this.renderClosedContourBeingEdited(enabledElement, svgDrawingHelper, annotation);
				} else if (isEditingOpen) {
					this.renderOpenContourBeingEdited(enabledElement, svgDrawingHelper, annotation);
				} else {
					throw new Error(`Unknown ${this.getToolName()} annotation rendering state`);
				}
			} else {
				this.renderContour(enabledElement, svgDrawingHelper, annotation);
			}

			// Todo: return boolean flag for each rendering route in the planar tool.
			renderStatus = true;
		}

		return renderStatus;
	}
}

ShutterFreehandTool.toolName = 'ShutterFreehandTool';
export default ShutterFreehandTool;
