import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const StatusBadge = ({ type, sx }) => {
	const { t } = useTranslation('organization');
	const title = {
		Online: t('Online'),
		Offline: t('Offline'),
		Unknown: t('Unknown'),
		Active: t('Active'),
		Inactive: t('Inactive'),
	};

	const color = {
		Online: '#6CBD6F',
		Color: '#E70B0B',
		Offline: '#E70B0B',
		Unknown: '#FFFFFF99',
		Active: '#6CBD6F',
		Inactive: '#FFFFFF80',
	};

	return (
		<Box
			sx={{
				padding: '6px 10px',
				display: 'inline-flex',
				alignItems: 'center',
				background: '#2F2F2F',
				borderRadius: '6px',
				...sx,
			}}
		>
			<Box
				sx={{
					width: '10px',
					height: '10px',
					borderRadius: '50%',
					background: color[type],
					marginRight: '10px',
				}}
			/>

			<Typography fontSize="14px">{title[type]}</Typography>
		</Box>
	);
};

StatusBadge.propTypes = {
	/**
	 * Status Badge type to display
	 */
	type: PropTypes.string,
};

export default StatusBadge;
