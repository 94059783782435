//React Library
import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import _ from 'lodash';

//Custom Component Library
import {
	CaseStatus,
	CommentDescription,
	General,
	IconBackground,
	IconContainer,
	ResultBody,
	ResultsContainer,
	SearchResultsPageContainer,
	SectionResultCount,
	SectionsContainer,
	SectionTitle,
	StyledButton,
} from './GlobalSearchResultsStyle';
import Highlighter from 'react-highlight-words';
import MomentUtils from '@date-io/moment';

//MUI Library
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import Box from '@mui/system/Box';
import SearchIcon from '@mui/icons-material/Search';
import { ToggleButtonGroup } from '@mui/lab';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FaceIcon from '@mui/icons-material/Face';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { GlobalSearch_NoLinkResource } from '../../../components/CRM/Cases/utils';
import { useCRMSearchScope, useAuth } from '@worklist-2/core';
import CircularProgress from '@mui/material/CircularProgress';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const GlobalSearchResultsView = () => {
	const { state } = useLocation();
	const { searchData, keyword, searchCategories } = state;
	const [toggleValue, setToggleValue] = useState('ViewAll');
	const moment = new MomentUtils();
	const navigate = useNavigate();
	const { setCaseGlobalList, setAccountGlobalList } = useCRMSearchScope();
	const { loggedInUser } = useAuth();
	const metaBreezeAccount = useBooleanFlagValue('meta-breeze-account');
	const metaBreezeCase = useBooleanFlagValue('meta-breeze-case');

	const TOGGLE_BUTTONS = useMemo(() => {
		const searchTab = [
			{
				name: 'Account',
				value: 'Account',
			},
			{
				name: 'Case',
				value: 'Case',
			},
			{
				name: 'Contact',
				value: 'Contact',
			},
			{
				name: 'File',
				value: 'Attachment',
			},
			{
				name: 'Knowledge',
				value: 'helpcenter',
			},
			{
				name: 'Comment',
				value: 'Comment',
			},
			{
				name: 'User',
				value: 'User',
			},
		];

		if (!metaBreezeAccount) {
			_.remove(searchTab, item => item.value === 'Account');
		}
		if (!metaBreezeCase) {
			const removeTab = ['Case', 'Attachment', 'Comment'];
			_.remove(searchTab, item => removeTab.includes(item.value));
		}
		if (!loggedInUser?.permission.Account.Read) {
			_.remove(searchTab, item => item.value === 'Account');
		}
		if (!loggedInUser?.permission.Case.Read) {
			_.remove(searchTab, item => item.value === 'Case');
		}
		if (!loggedInUser?.permission.Contact.Read) {
			_.remove(searchTab, item => item.value === 'Contact');
		}
		if (!loggedInUser?.permission.Case.Read || !loggedInUser?.permission.Comment.Read) {
			const removeTab = ['Attachment', 'Comment'];
			_.remove(searchTab, item => removeTab.includes(item.value));
		}
		if (!loggedInUser?.permission.User.Read) {
			_.remove(searchTab, item => item.value === 'User');
		}

		return searchTab;
	}, [loggedInUser, metaBreezeAccount, metaBreezeCase]);

	useEffect(() => {
		if (searchCategories.length == 1) {
			setToggleValue(searchCategories[0]);
		} else {
			setToggleValue('ViewAll');
		}
	}, [state]);

	const getCategoryIcon = category => {
		if (category === 'Account') {
			return <ViewListOutlinedIcon sx={{ color: '#4D79EA' }} />;
		}
		if (category === 'Case') {
			return <WorkOutlineOutlinedIcon sx={{ color: '#4D79EA' }} />;
		}
		if (category === 'Contact') {
			return <PermIdentityOutlinedIcon sx={{ color: '#4D79EA' }} />;
		}
		if (category === 'Attachment') {
			return <FolderOutlinedIcon sx={{ color: '#4D79EA' }} />;
		}
		if (category === 'User') {
			return <FaceIcon sx={{ color: '#4D79EA' }} />;
		}
		if (category === 'helpcenter') {
			return <SchoolOutlinedIcon sx={{ color: '#4D79EA' }} />;
		}
		if (category === 'Comment') {
			return <ChatOutlinedIcon sx={{ color: '#4D79EA' }} />;
		}
	};

	const getSearchResultTitle = (category, result) => {
		if (category === 'Account') {
			return `${result.AccountName} • ${result.ClientId}`;
		}
		if (category === 'Case') {
			return `${result.CaseName} • ${result.CaseNumber}`;
		}
		if (category === 'Contact') {
			return `${result.FullName} • ${result.Email}`;
		}
		if (category === 'Attachment') {
			return result.FileName;
		}
		if (category === 'User') {
			return result.UserName;
		}
		if (category === 'helpcenter') {
			return result.Article;
		}
		if (category === 'Comment') {
			return `${result.LastUpdatedUserName} in Case ${result.CaseNumber}`;
		}
	};

	const getSearchResultStatusLength = (category, result) => {
		if (category === 'Account') {
			return result.Type?.length;
		}
		if (category === 'Case') {
			return result.Status?.length;
		}
	};

	const getSearchResultDescription = (category, result) => {
		if (category === 'Attachment') {
			return `${moment.moment(result.LastUpdated).format('L')} • ${formatBytes(result.Size)}`;
		}
		if (category === 'User') {
			return result.LoginName;
		}
		if (category === 'Account') {
			return `Account ${result.AccountState}`;
		}
	};

	const getCaseStatusIcon = status => {
		//TODO: Add more Icons in respect to Case Status here. Once done, remove the else block

		if (status === 'In Progress') {
			return <AirportShuttleOutlinedIcon sx={{ fontSize: '18px', color: '#3EB967', mt: '2px' }} />;
		}
		if (status === 'Waiting') {
			return <PanToolOutlinedIcon sx={{ fontSize: '18px', color: '#EEBA02', mt: '2px' }} />;
		}
		if (status === 'On Hold') {
			return <ReportOutlinedIcon sx={{ fontSize: '18px', color: '#EF5D5D', mt: '2px' }} />;
		}
		if (status === 'Closed') {
			return <CheckCircleOutlineOutlinedIcon sx={{ fontSize: '18px', color: '#4D79EA', mt: '2px' }} />;
		}
		return <AirportShuttleOutlinedIcon sx={{ fontSize: '18px', color: '#3EB967', mt: '2px' }} />;
	};

	const getSearchResultStatus = (category, result) => {
		if (category === 'Account') {
			//This needs to be changed once the backend data is updated
			return (
				<Typography
					sx={{
						fontSize: '14px',
						fontWeight: 400,
						fontStyle: 'Italic',
						color: 'rgba(0, 0, 0, 0.6)',
					}}
				>
					{result.AccountType}
				</Typography>
			);
		}
		if (category === 'Case') {
			return (
				<CaseStatus>
					{getCaseStatusIcon(result.Status)}
					<Typography sx={{ marginLeft: '8px', fontSize: '14px' }}>{result.Status}</Typography>
				</CaseStatus>
			);
		}
	};

	const getTotalResultCount = () =>
		searchData.reduce(
			(count, item) =>
				searchCategories.length === 0 || searchCategories.includes(item.ResultCategory)
					? count + item.SearchResults.length
					: count,
			0
		);

	const getSearchCategories = categories =>
		searchCategories.length === 0
			? categories
			: categories.filter(category => searchCategories.includes(category.value));

	const handleToggleChange = (event, value) => {
		if (value !== null) setToggleValue(value);
	};

	const getCategoryResultCount = category => {
		let catCount = 0;
		searchData.forEach(item => {
			if (item.ResultCategory === null) catCount = 0;
			else if (item.ResultCategory === category) catCount = item.SearchResults.length;
		});
		return catCount;
	};

	const getToggleDataArray = entry => {
		if (toggleValue !== 'ViewAll' || searchCategories.length === 1) return entry;
		return entry.slice(0, 4);
	};

	const detailedView = (category, entry) => {
		let resourceType;
		let resourceId;
		let caseGlobalList = [];
		let accountGlobalList = [];

		if (category === 'Account') {
			accountGlobalList = _.find(searchData, ['ResultCategory', 'Account'])?.SearchResults;
			resourceType = _.lowerCase(entry.ResourceType);
			resourceId = entry.AccountId;
		} else if (category === 'Case') {
			caseGlobalList = _.find(searchData, ['ResultCategory', 'Case'])?.SearchResults;
			resourceType = _.lowerCase(entry.ResourceType);
			resourceId = entry.CaseId;
		} else if (category === 'Contact') {
			//Will update later
			return;
		} else if (category === 'Attachment') {
			resourceType = 'case';
			resourceId = entry.CaseId;
		} else if (category === 'helpcenter') {
			//Change according to previous categories if backend data provides any id and path.
			return;
		} else if (category === 'Comment') {
			resourceType = 'case';
			//There is no CaseId inside the data of Comment category
			//This will navigate to case/undefined
			resourceId = entry.CaseId;
		} else if (category === 'User') {
			//Will update later
			return;
		}

		setAccountGlobalList(accountGlobalList);
		setCaseGlobalList(caseGlobalList);
		navigate(`/${resourceType}/${resourceId}`);
	};

	const formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
	};

	const getCommentText = textString => {
		let textJson = '';
		let content = '';
		try {
			textJson = textString ? JSON.parse(textString) : {};
			const textBlocks = textJson.blocks ? textJson.blocks : [];
			if (textBlocks.length > 0) {
				content = textBlocks
					.map(x => x.text)
					.filter(Boolean)
					.join(' ');
			} else {
				content = textString;
			}
		} catch {
			content = textString;
		}

		return content;
	};

	return loggedInUser ? (
		<SearchResultsPageContainer>
			<Stack direction="row" spacing={2}>
				<SearchIcon sx={{ color: '#4D79EA', fontSize: '35px' }} />
				<Box>
					<Typography
						sx={{
							fontSize: '24px',
							fontWeight: 600,
							color: '#4D79EA',
							mb: '1rem',
						}}
					>
						Search Results
					</Typography>
					<ToggleButtonGroup
						exclusive
						orientation="vertical"
						value={toggleValue}
						onChange={handleToggleChange}
					>
						{searchCategories.length !== 1 && (
							<StyledButton value="ViewAll">{`View All (${getTotalResultCount()})`}</StyledButton>
						)}
						<General>General</General>
						{getSearchCategories(TOGGLE_BUTTONS).map(item => (
							<StyledButton value={item.value}>
								{`${item.name} (${getCategoryResultCount(item.value)})`}
							</StyledButton>
						))}
					</ToggleButtonGroup>
				</Box>
			</Stack>
			<ResultsContainer>
				{toggleValue === 'ViewAll' && (
					<Typography
						marginBottom="1.5rem"
						sx={{ fontSize: '16px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.6)' }}
					>
						<Highlighter
							autoEscape
							highlightClassName="myCustomHighlighter"
							searchWords={[`${getTotalResultCount()}`]}
							textToHighlight={`Search returned ${getTotalResultCount()} results`}
						/>
					</Typography>
				)}
				<SectionsContainer>
					{searchData && searchData.length > 0 ? (
						searchData
							.filter(
								entry =>
									searchCategories.length === 0 || searchCategories.includes(entry.ResultCategory)
							)
							.map(
								entry =>
									entry.SearchResults.length > 0 &&
									(entry.ResultCategory === toggleValue || toggleValue === 'ViewAll') && (
										<Stack marginBottom="1rem">
											<Stack direction="row" mb="8px">
												<SectionTitle>
													{`${_.find(TOGGLE_BUTTONS, ['value', entry.ResultCategory])?.name}`}
												</SectionTitle>
												<SectionResultCount>
													{`${getCategoryResultCount(entry.ResultCategory)}`}
												</SectionResultCount>
											</Stack>
											{getToggleDataArray(entry.SearchResults).map(result => (
												<Stack alignItems="center" direction="row" ml="5px" my="6px">
													<IconContainer>
														<IconBackground>
															{getCategoryIcon(entry.ResultCategory)}
														</IconBackground>
													</IconContainer>
													<ResultBody
														sx={{
															width: '739px',
															height: '82px',
															background: '#FFF',
															boxShadow: '0px 0px 20px rgba(33, 88, 119, 0.1)',
															borderRadius: '10px',
															cursor: !GlobalSearch_NoLinkResource.includes(
																entry.ResultCategory
															)
																? 'pointer'
																: 'context-menu',
														}}
														onClick={() => {
															if (
																!GlobalSearch_NoLinkResource.includes(
																	entry.ResultCategory
																)
															) {
																detailedView(entry.ResultCategory, result);
															}
														}}
													>
														<Typography
															ml="3rem"
															sx={{
																fontSize: '18px',
																fontWeight: 500,
																color: 'rgba(0, 0, 0, 0.9)',
																inlineSize: `calc(739px - ${
																	getSearchResultStatusLength(
																		entry.ResultCategory,
																		result
																	) *
																		11 +
																	100
																}px)`,
																overflowWrap: 'break-word',
																overflow: 'hidden',
															}}
														>
															<Highlighter
																autoEscape
																highlightClassName="myCustomHighlighter"
																searchWords={[keyword]}
																textToHighlight={getSearchResultTitle(
																	entry.ResultCategory,
																	result
																)}
															/>
														</Typography>
														{getSearchResultDescription(entry.ResultCategory, result) && (
															<Typography ml="3rem">
																<Highlighter
																	autoEscape
																	highlightClassName="myCustomHighlighter"
																	searchWords={[keyword]}
																	textToHighlight={getSearchResultDescription(
																		entry.ResultCategory,
																		result
																	)}
																/>
															</Typography>
														)}
														{entry.ResultCategory === 'Comment' && (
															<CommentDescription>
																<Highlighter
																	autoEscape
																	highlightClassName="myCustomHighlighter"
																	searchWords={[keyword]}
																	textToHighlight={getCommentText(result.Description)}
																/>
															</CommentDescription>
														)}
														{getSearchResultStatus(entry.ResultCategory, result) && (
															<Box position="absolute" right="54px">
																{getSearchResultStatus(entry.ResultCategory, result)}
															</Box>
														)}
													</ResultBody>
												</Stack>
											))}
											{entry.SearchResults.length > 4 &&
												toggleValue === 'ViewAll' &&
												searchCategories.length !== 1 && (
													<Box
														sx={{ cursor: 'pointer', ml: 'auto', mr: '40px' }}
														onClick={() => setToggleValue(entry.ResultCategory)}
													>
														<Typography sx={{ color: '#4D79EA', fontSize: '14px' }}>
															{`More ${entry.ResultCategory} Results`}
														</Typography>
													</Box>
												)}
										</Stack>
									)
							)
					) : (
						<Box>
							<Typography>No Results Found</Typography>
							<Typography>
								{`You may want to try using different keywords, checking 
									for typos, or adjusting your filters.`}
							</Typography>
						</Box>
					)}
				</SectionsContainer>
			</ResultsContainer>
		</SearchResultsPageContainer>
	) : (
		<Box
			sx={{
				height: '100%',
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				paddingBottom: '100px',
				boxSizing: 'border-box',
			}}
		>
			<Typography
				sx={{
					textAlign: 'center',
					fontSie: '16px',
					marginBottom: '8px',
					color: 'black',
				}}
			>
				<CircularProgress
					className="progressBar"
					size={22}
					sx={{
						color: '#03dac5',
						marginRight: '16px',
					}}
				/>
				Loading...
			</Typography>
		</Box>
	);
};

export default GlobalSearchResultsView;
