import { useEffect } from 'react';

const DEFAULT_HELP_PAGE = 'https://help.omegaai.com/';

/**
 * Custom hook to handle opening help pages based on the current URL path or hash when F1 is pressed.
 *
 * @param {Object} helpPageMapping - A mapping of URL segments or hashes to help page URLs.
 * @param {boolean} enabled - A flag to enable or disable the help page functionality.
 */
const useHelpPage = (helpPageMapping, enabled) => {
	/**
	 * Event handler for keydown events.
	 *
	 * @param {KeyboardEvent} event - The keyboard event.
	 */
	const handleKeyDown = event => {
		if (event.key === 'F1') {
			event.preventDefault(); // Prevent the default F1 help action
			const currentPath = window.location.pathname;
			const pathSegments = currentPath.split('/');

			// Check the hash first
			const hash = window.location.hash.substring(1); // Remove the leading '#'
			if (hash && helpPageMapping[hash]) {
				window.open(helpPageMapping[hash], '_blank');
				return;
			}

			// Check each segment of the path from right to left
			for (let i = pathSegments.length - 1; i >= 0; i -= 1) {
				const segment = pathSegments[i];
				const helpPageUrl = helpPageMapping[segment];

				if (helpPageUrl) {
					window.open(helpPageUrl, '_blank');
					return;
				}
			}

			// If no help page is found, go to the home page
			window.open(DEFAULT_HELP_PAGE, '_blank');
		}
	};

	useEffect(() => {
		if (!enabled) {
			return;
		}

		// Add event listener for keydown events
		window.addEventListener('keydown', handleKeyDown);

		// Cleanup eventf listener on component unmount
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [helpPageMapping, enabled]);
};

/**
 * Gives an element the ability to open a help page when F1 is pressed.
 *
 */
const useHelpPageComponent = (component, url, enabled) => {
	const handleKeyDown = event => {
		if (event.key === 'F1') {
			event.preventDefault();
			event.stopPropagation(); // Prevent the event from bubbling up
			window.open(url, '_blank');
		}
	};

	useEffect(() => {
		if (!enabled) {
			return;
		}

		// add an event listener to listen for focus, if focused add the mouse down handler
		component?.addEventListener('keydown', handleKeyDown);

		return () => {
			component?.removeEventListener('keydown', handleKeyDown);
		};
	}, [enabled, component]);
};

export { useHelpPageComponent };

export default useHelpPage;
