// @flow

// core
import React, { type Node } from 'react';
// components
import CardiologyIcon from '@worklist-2/ui/src/assets/icons/cardiology.svg';
import RulerIcon from '@worklist-2/ui/src/assets/icons/ruler.svg';
import PacemakerIcon from '@worklist-2/ui/src/assets/icons/pacemaker.svg';
import WeightIcon from '@worklist-2/ui/src/assets/icons/weight.svg';
import AccessibilityIcon from '@worklist-2/ui/src/assets/icons/accessibility.svg';

// libs
import { Stack, SvgIcon, Tooltip, Typography, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IObservationInfo {
	bmi?: string;
	bmiValue?: string;
	diastolicBlood?: number;
	heartRate?: number;
	height?: number;
	systolicBlood?: number;
	weight?: number;
}
interface IVitalsProps {
	observationInfo: IObservationInfo;
	isLoading: boolean;
}

interface IVitalSignProps {
	icon: any;
	tooltipTitle: string;
	testId: string;
	textValue: string | number;
	sxProps: any;
	isLoading: boolean;
}

const VitalSign = ({ icon, tooltipTitle, testId, textValue, sxProps, isLoading }: IVitalSignProps) => (
	<Tooltip title={tooltipTitle}>
		<Stack alignItems="center" justifyContent="center">
			<SvgIcon component={icon} sx={sxProps} />
			{isLoading ? (
				<Skeleton height="20px" width="30px" />
			) : (
				<Typography noWrap data-testid={testId}>
					{textValue}
				</Typography>
			)}
		</Stack>
	</Tooltip>
);

const getVitalNodes = (t, observationInfo = {}) => {
	const {
		bmi = 0,
		bmiValue = '0',
		diastolicBlood = 0,
		heartRate = 0,
		bodyHeight = 0,
		systolicBlood = 0,
		bodyWeight = 0,
	} = observationInfo;

	return [
		{
			icon: AccessibilityIcon,
			tooltipTitle: `${t(bmi)} ${t('bmi')}`,
			testId: 'bmiValue-text',
			textValue: t(bmiValue),
			sxProps: { path: { color: 'red', transform: 'translate(4px, 0)' } },
		},
		{
			icon: RulerIcon,
			tooltipTitle: t('height'),
			testId: 'height-text',
			textValue: bodyHeight,
			sxProps: { path: { transform: 'translate(3px, 3px)' } },
		},
		{
			icon: WeightIcon,
			tooltipTitle: t('weight'),
			testId: 'weight-text',
			textValue: bodyWeight,
			sxProps: { path: { transform: 'translate(4px, 2px)' } },
		},
		{
			icon: CardiologyIcon,
			tooltipTitle: t('heartRate'),
			testId: 'heartRate-text',
			textValue: heartRate,
			sxProps: { path: { transform: 'translate(2px, 0px)' } },
		},
		{
			icon: PacemakerIcon,
			tooltipTitle: t('bloodPressure'),
			testId: 'systolicBlood-diastolicBlood-text',
			textValue: `${systolicBlood}/${diastolicBlood}`,
			sxProps: { path: { transform: 'translate(2px, 0px)' } },
		},
	];
};

export const Vitals = ({ observationInfo, isLoading }: IVitalsProps): Node => {
	const { t } = useTranslation('vitals');

	const vitalNodes = getVitalNodes(t, observationInfo);

	return (
		<Stack data-testid="patient-vitals" direction="row" sx={styleProps}>
			{vitalNodes.map(({ icon, tooltipTitle, testId, textValue, sxProps }) => (
				<VitalSign
					key={testId}
					icon={icon}
					isLoading={isLoading}
					sxProps={sxProps}
					testId={testId}
					textValue={textValue}
					tooltipTitle={tooltipTitle}
				/>
			))}
		</Stack>
	);
};

const styleProps = {
	width: '90%',
	boxSizing: 'border-box',
	justifyItems: 'space-between',
	alignItems: 'center',

	'> div': { minWidth: '20%', maxWidth: '20%' },
	'.MuiTypography-noWrap': {
		maxWidth: '95%',
		fontSize: '14px',
		color: 'rgba(255, 255, 255, 0.87)',
	},
};
