// Core

import React from 'react';

// MUI

import { Box, Typography } from '@mui/material';

// Custom

import FormToolLabel from './FormToolLabel';

// Component

const FormToolSubheader = ({ required, title, titleId, description, isCompact }) =>
	title !== undefined || description ? (
		<Box
			sx={{
				marginBottom: isCompact ? 'unset' : 'var(--element-spacing)',
			}}
		>
			{title !== undefined ? (
				<FormToolLabel id={titleId} required={required}>
					{title}
				</FormToolLabel>
			) : null}

			{description ? (
				<Typography
					sx={{
						color: '#999999',
						fontSize: '14px',
						marginTop: title ? '10px' : undefined,
						whiteSpace: 'pre-line',
					}}
				>
					{description}
				</Typography>
			) : null}
		</Box>
	) : null;

export default FormToolSubheader;
