// core
import React, { useContext } from 'react';

// contexts
import FormContext from '../../../formContexts/FormContext';

// utils
import { getTextFieldProps, hasError } from '../../../formUtils/formUtils';

// MUI
import TextField from '@mui/material/TextField';

// types
import FormFieldVariantType from '../../../formTypes/FormFieldVariantType';
import { useTranslation } from 'react-i18next';

const FormFieldDefault = React.forwardRef(({ formHook, name, type, props, uncontrolled }, ref) => {
	const { readOnly: isReadOnly } = useContext(FormContext);
	const { t } = useTranslation('insurancepayer');

	const readOnly = props?.hoveredField ? isReadOnly && !props.hoveredField : isReadOnly;

	const {
		formState: { errors },
	} = formHook;

	const getReadOnlyProps = () => {
		if (!readOnly && props.disabled) {
			return {
				helperText: t('Read-only'),
			};
		}

		return readOnly
			? {
					disabled: true,
					variant: 'standard',
					sx: {
						'& .Mui-disabled': {
							'&:before': { display: 'none' },
						},
						'& .MuiSelect-icon': {
							display: 'none',
						},
						'& .MuiInput-input.Mui-disabled': {
							WebkitTextFillColor: 'rgba(255, 255, 255, 0.87) !important',
						},
						'& .MuiInput-input': {
							WebkitTextFillColor: 'rgba(255, 255, 255, 0.87) !important',
						},
						...props.sx,
					},
					InputLabelProps: { shrink: true },
					InputProps: {
						startAdornment: undefined,
						endAdornment: undefined,
					},
			  }
			: {};
	};

	const getRequiredProps = () => {
		if (!readOnly && !props.disabled && props.required && !hasError(name, errors)) {
			return {
				helperText: t('Required'),
				sx: {
					'& .MuiFormHelperText-root': {
						color: 'rgba(255, 255, 255, 0.36) !important',
						marginLeft: 0,
					},
				},
			};
		}
	};

	const getValue = () => {
		if (uncontrolled) {
			return props.value;
		}

		return props.value || '';
	};

	return (
		<>
			{!props.hidden && (
				<TextField
					inputRef={ref}
					rows={props.multiline && !readOnly ? 3 : undefined}
					sx={
						hasError(name, errors)
							? {
									'& .MuiInputLabel-root.Mui-error': {
										color: '#f44336 !important',
									},
							  }
							: {}
					}
					{...getTextFieldProps(name, errors)}
					{...props}
					type={type}
					value={getValue()}
					{...getReadOnlyProps()}
					{...getRequiredProps()}
					data-cy="form-field"
					data-testid="form-field"
					id={props.id || `form-field-${props.label}`}
					placeholder={props.placeholder ? t(props.placeholder) : null}
					required={false}
				/>
			)}
		</>
	);
});

FormFieldDefault.propTypes = FormFieldVariantType();

export default FormFieldDefault;
