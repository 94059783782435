import CalibrationTool from './CalibrationTool';

const cursors = {
	[CalibrationTool.toolName]: {
		icon: `
<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="8" width="1" height="1" fill="#7FFD8C"/>
<rect x="2" y="6" width="1" height="1" fill="#7FFD8C"/>
<rect x="4" y="4" width="1" height="1" fill="#7FFD8C"/>
<rect x="6" y="2" width="1" height="1" fill="#7FFD8C"/>
<rect x="8" width="1" height="1" fill="#7FFD8C"/>
</svg>`,
		viewBox: { x: 9, y: 9 },
	},
};

export { cursors };
