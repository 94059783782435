import moment from 'moment-timezone';

import * as _ from 'lodash';

export const getDateAndTime = dateString => {
	const date = new Date(dateString);

	return {
		date: date.toLocaleDateString('en-US'),
		time: date.toLocaleTimeString('en-US'),
	};
};

export const isDateValid = (date, format) => {
	if (!date) return false;
	if (format) {
		return moment(date, format, true).isValid();
	}
	const _date = new Date(date);

	return (
		!isNaN(_date) &&
		_date instanceof Date &&
		_date?.getFullYear()?.toString()?.length == 4 &&
		_date !== 'Invalid Date'
	);
};

/**
 * Selected date is all day
 * @param {*} firstTimeValue
 * @param {*} secondTimeValue
 * @returns
 */
export const isWholeDay = dateRanges => {
	// Return true if dateRanges is not enough
	if (dateRanges?.length !== 2) return false;

	// Return false if dateRanges is not date
	if (isNaN(dateRanges[0]) || isNaN(dateRanges[1])) {
		return false;
	}
	const timeFormat = 'hh:mm a';
	const firstTimeValue = `${moment(dateRanges[0]).format(timeFormat)}`;
	const secondTimeValue = `${moment(dateRanges[1]).format(timeFormat)}`;
	return firstTimeValue === '12:00 am' && secondTimeValue === '11:59 pm';
};

/**
 * Translate date
 * @param {*} t
 * @param {*} date
 * @param {*} format
 * @returns
 */
export const translateDate = (t, date, isDateTime = false) => {
	if (!date) return '';
	const dayLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	const dateTimeString = isDateTime
		? `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
		: date.toLocaleDateString();
	return `${t(dayLabels[date.getDay()])} ${dateTimeString}`;
};

/**
 * getDateHintText
 * @param {*} t
 * @param {*} dateValue
 * @returns
 */
export const getDateHintText = (t, dateValue) => {
	let hinText = '';
	let isDateTimeFormat = true;
	// Selected date from pillpicker
	if (dateValue?.length === 1) {
		return t(dateValue[0]);
	}

	// Check display format by all day time
	if (dateValue[0] && dateValue[1] && isWholeDay([dateValue[0], dateValue[1]])) {
		isDateTimeFormat = false;
	}
	// translate date
	if (isDateValid(dateValue[0])) {
		hinText = `${translateDate(t, new Date(dateValue[0]), isDateTimeFormat)}`;
	}
	if (isDateValid(dateValue[1])) {
		hinText += ` - ${translateDate(t, new Date(dateValue[1]), isDateTimeFormat)}`;
	}
	return hinText;
};

export const getFormattedDateRange = (defaultValue, dateTimeFormat) => {
	if (!defaultValue || !dateTimeFormat) {
		return ''; // Handle cases where either input is missing
	}

	if (_.isArray(defaultValue) && defaultValue.length === 2) {
		const [startDateStr, endDateStr] = defaultValue;
		const startDate = moment(startDateStr);
		const endDate = moment(endDateStr);

		if (startDate.isValid() && endDate.isValid()) {
			if (startDate.isSame(endDate, 'day')) {
				return `${startDate.format(dateTimeFormat)} - NA`;
			}

			return `${startDate.format(dateTimeFormat)} - ${endDate.format(dateTimeFormat)}`;
		}
	}

	return ''; // Return empty string for invalid input or single date
};

export const getTimezoneOffset = timeZone => moment.tz(timeZone).utcOffset() / 60;

export const getTimezoneWithOffset = timeZone => {
	const offset = getTimezoneOffset(timeZone);
	const hours = Math.floor(Math.abs(offset));
	const minutes = Math.round((Math.abs(offset) * 60) % 60);

	const formattedHours = hours.toString().padStart(2, '0');
	const formattedMinutes = minutes.toString().padStart(2, '0');

	const sign = offset >= 0 ? '+' : '-';

	return timeZone ? `${timeZone} (UTC${sign}${formattedHours}:${formattedMinutes})` : '';
};

export const isValidDateMapping = dateTime => {
	return !isNaN(dateTime.getTime()) && dateTime.getYear() >= 0;
};
