// Types

const CHAT_CONVERSATION_TYPES = {
	MESSAGING: 'messaging',
	CALL: 'call',
};

const DEFAULT_CALL_DATA = {
	isCall: false,
	caller: null,
	callNotification: null,
	conversation: null,
	status: {
		incoming: false,
		onCall: false,
		calling: false,
	},
	callStartTime: 0,
	audioIsMute: false,
};

export default CHAT_CONVERSATION_TYPES;
export { DEFAULT_CALL_DATA };
