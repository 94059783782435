import { useEffect, useState } from 'react';
import { EVENTS, utilities as csUtils, getEnabledElementByIds } from '@cornerstonejs/core';
import { annotation } from '@cornerstonejs/tools';
import getDefaultRenderingEngine from '../../cornerstone/getDefaultRenderingEngine';
import { renderingEngineId, shadowActiveViewportId } from '../../contexts/ImageViewerCornerstoneContext';
import { vec3 } from 'gl-matrix';
import SpineLabelingTool from '../../cornerstoneTools/SpineLabelingTool';
import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';
import getSeriesOrientationPlane, { DICOM_IMAGE_PLANE_ANNOTATIONS } from '../../utils/getSeriesOrientationPlane';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const { getAnnotationManager } = annotation.state;
const EPSILON = 1e-3;

const isParallel = (vec1, vec2) => {
	return Math.abs(vec3.dot(vec1, vec2)) > 1 - EPSILON;
};

const isPerpendicular = (vec1, vec2) => {
	const dot = vec3.dot(vec1, vec2);
	return Math.abs(dot) < EPSILON;
};

const lineIntersects = (p0_x, p0_y, p1_x, p1_y, p2_x, p2_y, p3_x, p3_y) => {
	let s1_x, s1_y, s2_x, s2_y;
	s1_x = p1_x - p0_x;
	s1_y = p1_y - p0_y;
	s2_x = p3_x - p2_x;
	s2_y = p3_y - p2_y;

	let s, t;
	s = (-s1_y * (p0_x - p2_x) + s1_x * (p0_y - p2_y)) / (-s2_x * s1_y + s1_x * s2_y);
	t = (s2_x * (p0_y - p2_y) - s2_y * (p0_x - p2_x)) / (-s2_x * s1_y + s1_x * s2_y);

	return s >= 0 && s <= 1 && t >= 0 && t <= 1;
};

const intersectInfiniteLines = (line1Start, line1End, line2Start, line2End) => {
	const [x1, y1] = line1Start;
	const [x2, y2] = line1End;
	const [x3, y3] = line2Start;
	const [x4, y4] = line2End;

	const a1 = y2 - y1;
	const b1 = x1 - x2;
	const c1 = x2 * y1 - x1 * y2;

	const a2 = y4 - y3;
	const b2 = x3 - x4;
	const c2 = x4 * y3 - x3 * y4;

	if (Math.abs(a1 * b2 - a2 * b1) < EPSILON) {
		return;
	}

	const x = (b1 * c2 - b2 * c1) / (a1 * b2 - a2 * b1);
	const y = (a2 * c1 - a1 * c2) / (a1 * b2 - a2 * b1);

	return [x, y];
};

const isInBound = (point, dimensions) => {
	return point[0] >= 0 && point[0] <= dimensions[0] && point[1] >= 0 && point[1] <= dimensions[1];
};

const getTargetId = viewport => {
	return `imageId:${viewport.getCurrentImageId()}`;
};

const getTargetIdImage = (targetId, renderingEngine) => {
	if (targetId.startsWith('imageId:')) {
		const imageId = targetId.split('imageId:')[1];
		const imageURI = csUtils.imageIdToURI(imageId);
		let viewports = csUtils.getViewportsWithImageURI(imageURI, renderingEngine.id);

		if (!viewports || !viewports.length) {
			return;
		}

		viewports = viewports.filter(viewport => {
			return viewport.getCurrentImageId() === imageId;
		});

		if (!viewports || !viewports.length) {
			return;
		}

		return viewports[0].getImageData();
	} else if (targetId.startsWith('volumeId:')) {
		const volumeId = targetId.split('volumeId:')[1];
		const viewports = csUtils.getViewportsWithVolumeId(volumeId, renderingEngine.id);

		if (!viewports || !viewports.length) {
			return;
		}

		return viewports[0].getImageData();
	} else {
		throw new Error('getTargetIdImage: targetId must start with "imageId:" or "volumeId:"');
	}
};

const getReferencedImageId = viewport => {
	const targetId = getTargetId(viewport);

	return targetId.split('imageId:')[1];
};

const getCanvasCoords = (targetViewport, lineStartWorld, viewPlaneNormal, viewUp, lineEndWorld, canvasCoordinates) => {
	const renderingEngine = targetViewport.getRenderingEngine();
	const targetId = getTargetId(targetViewport);
	const targetImage = getTargetIdImage(targetId, renderingEngine);
	const referencedImageId = getReferencedImageId(targetViewport);

	if (referencedImageId && targetImage) {
		try {
			const { imageData, dimensions } = targetImage;

			const [topLeftImageCoord, topRightImageCoord, bottomRightImageCoord, bottomLeftImageCoord] = [
				imageData.indexToWorld([0, 0, 0]),
				imageData.indexToWorld([dimensions[0] - 1, 0, 0]),
				imageData.indexToWorld([dimensions[0] - 1, dimensions[1] - 1, 0]),
				imageData.indexToWorld([0, dimensions[1] - 1, 0]),
			].map(world => csUtils.worldToImageCoords(referencedImageId, world));

			const [lineStartImageCoord, lineEndImageCoord] = [lineStartWorld, lineEndWorld].map(world =>
				csUtils.worldToImageCoords(referencedImageId, world)
			);

			canvasCoordinates = [
				[topLeftImageCoord, topRightImageCoord],
				[topRightImageCoord, bottomRightImageCoord],
				[bottomLeftImageCoord, bottomRightImageCoord],
				[topLeftImageCoord, bottomLeftImageCoord],
			]
				.map(([start, end]) => intersectInfiniteLines(start, end, lineStartImageCoord, lineEndImageCoord))
				.filter(point => point && isInBound(point, dimensions))
				.map(point => {
					const world = csUtils.imageToWorldCoords(referencedImageId, point);
					return targetViewport.worldToCanvas(world);
				});
		} catch (err) {
			console.log(err);
		}
	}
	return canvasCoordinates;
};

const useGetSpineLabels = ({ viewportId }) => {
	const wonIvSpinelabeling = useBooleanFlagValue('WON-IV-SPINELABELING');

	const [spineLabels, setSpineLabels] = useState();

	useEffect(() => {
		const element = document.getElementById(viewportId);

		if (!wonIvSpinelabeling) {
			return;
		}

		const onStackNewImage = () => {
			const renderingEngine = getDefaultRenderingEngine();

			if (!renderingEngine) {
				return;
			}

			const sourceViewport = renderingEngine.getViewport(viewportId);

			if (!sourceViewport) {
				return;
			}

			const imageId = sourceViewport.getCurrentImageId();

			if (!imageId) {
				setSpineLabels(undefined);

				return;
			}

			const instanceMetadata = cornerstoneWADOImageLoader.wadors.metaDataManager.get(imageId);

			const imageOrientationPatient = DICOM_IMAGE_PLANE_ANNOTATIONS[getSeriesOrientationPlane(instanceMetadata)];

			if (imageOrientationPatient !== 'Axial') {
				setSpineLabels(undefined);

				return;
			}

			const stackViewports = renderingEngine.getStackViewports();

			if (!stackViewports?.length) {
				setSpineLabels(undefined);

				return;
			}

			const viewports = stackViewports.filter(viewport => {
				if (viewport.id === viewportId) {
					return false;
				}

				if (viewport.id === shadowActiveViewportId) {
					return false;
				}

				const targetImageId = viewport.getCurrentImageId();

				if (!targetImageId) {
					return false;
				}

				const targetInstanceMetadata = cornerstoneWADOImageLoader.wadors.metaDataManager.get(targetImageId);

				const targetImageOrientationPatient =
					DICOM_IMAGE_PLANE_ANNOTATIONS[getSeriesOrientationPlane(targetInstanceMetadata)];

				return targetImageOrientationPatient === 'Sagittal';
			});

			const annotations = [];

			const annotationManager = getAnnotationManager();

			if (annotationManager.annotations) {
				const keys = Object.keys(annotationManager.annotations);

				keys.forEach(key => {
					const spineLabelingAnnotations = annotationManager.annotations[key]?.[
						SpineLabelingTool.toolName
					]?.filter(a => a.metadata.referencedImageId.split('/series')[0] === imageId.split('/series')[0]);

					if (spineLabelingAnnotations?.length) {
						annotations.push(...spineLabelingAnnotations);
					}
				});
			}

			if (!annotations.length) {
				setSpineLabels(undefined);

				return;
			}

			if (!getEnabledElementByIds(viewportId, renderingEngineId) || !sourceViewport) {
				return;
			}

			if (!sourceViewport.getImageData?.()) {
				return;
			}

			const sourceViewportCanvasCornersInWorld = csUtils.getViewportImageCornersInWorld(sourceViewport);

			if (!sourceViewportCanvasCornersInWorld?.length) {
				setSpineLabels(undefined);

				return;
			}

			const topLeft = sourceViewportCanvasCornersInWorld[0];
			const topRight = sourceViewportCanvasCornersInWorld[1];
			const bottomLeft = sourceViewportCanvasCornersInWorld[2];
			const bottomRight = sourceViewportCanvasCornersInWorld[3];

			const markersToDisplay = [];

			annotations.forEach(a => {
				const marker = a.data.label;

				const annotationSecondPoint = a.data.handles.points[1];

				if (!annotationSecondPoint) {
					return;
				}

				const annotationImageId = a.metadata.referencedImageId;

				if (!annotationImageId) {
					return;
				}

				viewports.forEach(targetViewport => {
					const { focalPoint, viewPlaneNormal, viewUp } = targetViewport.getCamera();
					const { viewPlaneNormal: sourceViewPlaneNormal } = sourceViewport.getCamera();

					if (isParallel(viewPlaneNormal, sourceViewPlaneNormal)) {
						return;
					}

					const targetViewportPlane = csUtils.planar.planeEquation(viewPlaneNormal, focalPoint);

					const pointSet1 = [topLeft, bottomLeft, topRight, bottomRight];
					const pointSet2 = [topLeft, topRight, bottomLeft, bottomRight];

					let pointSetToUse = pointSet1;

					let topBottomVec = vec3.subtract(vec3.create(), pointSet1[0], pointSet1[1]);
					topBottomVec = vec3.normalize(vec3.create(), topBottomVec);

					let topRightVec = vec3.subtract(vec3.create(), pointSet1[2], pointSet1[0]);
					topRightVec = vec3.normalize(vec3.create(), topRightVec);

					const newNormal = vec3.cross(vec3.create(), topBottomVec, topRightVec);

					if (isParallel(newNormal, viewPlaneNormal)) {
						return;
					}

					if (isPerpendicular(topBottomVec, viewPlaneNormal)) {
						pointSetToUse = pointSet2;
					}

					const lineStartWorld = csUtils.planar.linePlaneIntersection(
						pointSetToUse[0],
						pointSetToUse[1],
						targetViewportPlane
					);

					const lineEndWorld = csUtils.planar.linePlaneIntersection(
						pointSetToUse[2],
						pointSetToUse[3],
						targetViewportPlane
					);

					const canvasLineStartWorld = targetViewport.worldToCanvas(lineStartWorld);
					const canvasLineEndWorld = targetViewport.worldToCanvas(lineEndWorld);

					const canvasCoordinates = getCanvasCoords(
						targetViewport,
						lineStartWorld,
						viewPlaneNormal,
						viewUp,
						lineEndWorld,
						[canvasLineStartWorld, canvasLineEndWorld]
					);

					const annotationCanvasCoords = targetViewport.worldToCanvas(a.data.handles.points[0]);

					const secondAnnotationCanvasCoordinates =
						annotationSecondPoint && targetViewport.worldToCanvas(annotationSecondPoint);

					const line = {
						x1: annotationCanvasCoords[0],
						y1: annotationCanvasCoords[1],
						x2: secondAnnotationCanvasCoordinates[0],
						y2: secondAnnotationCanvasCoordinates[1],
					};

					const lineLength = Math.hypot(line.x2 - line.x1, line.y2 - line.y1);

					const extendLineBy = lineLength * 0.3;

					const extendedLine = {
						x1: line.x1 + ((line.x1 - line.x2) / lineLength) * extendLineBy,
						y1: line.y1 + ((line.y1 - line.y2) / lineLength) * extendLineBy,
						x2: line.x2 + ((line.x2 - line.x1) / lineLength) * extendLineBy,
						y2: line.y2 + ((line.y2 - line.y1) / lineLength) * extendLineBy,
					};

					const linesIntersecting = lineIntersects(
						extendedLine.x1,
						extendedLine.y1,
						extendedLine.x2,
						extendedLine.y2,
						canvasCoordinates[0][0],
						canvasCoordinates[0][1],
						canvasCoordinates[1][0],
						canvasCoordinates[1][1]
					);

					if (linesIntersecting && !markersToDisplay.includes(marker)) {
						markersToDisplay.push(marker);
					}
				});
			});

			setSpineLabels(markersToDisplay.join('-'));
		};

		element?.addEventListener(EVENTS.IMAGE_RENDERED, onStackNewImage);

		return () => {
			element?.removeEventListener(EVENTS.IMAGE_RENDERED, onStackNewImage);
		};
	}, [wonIvSpinelabeling]);

	return {
		spineLabels,
	};
};

export default useGetSpineLabels;
