// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".U05mCff7mi34rxL3tbXB{display:flex;align-items:center;margin-left:20px}.U05mCff7mi34rxL3tbXB .Yj5bYuepI3XRTPRQp9LS{display:flex;justify-content:center;align-items:center;height:32px;padding:0 7px;margin-right:10px;border-radius:16px;border:1px solid rgba(77,121,234,.5);background:rgba(77,121,234,.05)}.U05mCff7mi34rxL3tbXB .VQn3ZFzzfvSGV_Efp1SH{display:flex;justify-content:center;align-items:center;margin-left:5px;cursor:pointer}.U05mCff7mi34rxL3tbXB .Yj5bYuepI3XRTPRQp9LS span{color:#4d79ea;font-family:Inter,serif;font-size:12px;font-style:normal;font-weight:500;line-height:normal}.U05mCff7mi34rxL3tbXB .PF3cgTkUHfCd1XCqJIBt{display:flex;justify-content:center;align-items:center;border-left:1px solid #4d79ea}.U05mCff7mi34rxL3tbXB .PF3cgTkUHfCd1XCqJIBt svg{width:20px;height:20px;margin-left:10px;fill:#4d79ea;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/CRM/Accounts/AccountsFilterPanel/AccountsFilterPanel.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAEA,gBAAA,CAEA,4CACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,aAAA,CACA,iBAAA,CACA,kBAAA,CACA,oCAAA,CAEA,+BAAA,CAEA,4CACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CAEA,cAAA,CAKF,iDACE,aAAA,CAEA,uBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CAIJ,4CACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,6BAAA,CAGA,gDACE,UAAA,CACA,WAAA,CACA,gBAAA,CAEA,YAAA,CAEA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casesFilterPanel": "U05mCff7mi34rxL3tbXB",
	"filter": "Yj5bYuepI3XRTPRQp9LS",
	"filterIcon": "VQn3ZFzzfvSGV_Efp1SH",
	"clearAllFilter": "PF3cgTkUHfCd1XCqJIBt"
};
export default ___CSS_LOADER_EXPORT___;
