import PropTypes from 'prop-types';
import getSvg from './getSvg';

const Svg = (name, { ...otherProps }) => getSvg(name, { ...otherProps });

Svg.propTypes = {
	name: PropTypes.string.isRequired,
};

export default Svg;
