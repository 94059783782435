import React from 'react';
import { Icon, Snackbar } from '@mui/material';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';

const MobileSnackbar = ({ message, open, onClose }) => (
	<Snackbar
		disableWindowBlurListener
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
		autoHideDuration={3000}
		open={open}
		sx={{
			bottom: '44px',
		}}
		onClose={onClose}
	>
		<Box
			sx={{
				background: '#FFFFFF',
				boxShadow: '-4px -4px 10px rgba(18, 18, 18, 0.1), 4px 4px 4px rgba(18, 18, 18, 0.25)',
				borderRadius: '20px',
				padding: '19px 28px',
				display: 'flex',
				alignItems: 'center',
			}}
			onClick={onClose}
		>
			<Icon component={CheckIcon} sx={{ marginRight: '20px', color: 'rsPrimary.main' }} />

			<Typography sx={{ fontSize: '14px', fontWeight: '400' }}>{message}</Typography>
		</Box>
	</Snackbar>
);

export default MobileSnackbar;
