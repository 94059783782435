import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React, { forwardRef, useEffect, useImperativeHandle, useState, useMemo } from 'react';
import {
	AddRoleCard,
	LinkButton,
	OutlineWrapper,
	RoleSideWidget,
	SmallCircle,
	SubTitle,
	Title,
	RoleItem,
} from '../../../views/CrmView/styles';
import { TemMembersAvatars } from './TeamCard';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LastPageIcon from '@mui/icons-material/LastPage';
import SwitchButton from './SwitchButton';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import _ from 'lodash';
import { useCrmContext } from '../../../context/CRMContext';
import { crmSearchScopes, useCRMDataLoader, useAuth } from '@worklist-2/core/src';
import { Select, MenuItem } from '@mui/material';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FormHelperText from '@mui/material/FormHelperText';

const TeamEditor = forwardRef((props, ref) => {
	const [visibility, toggleVisibility] = useState(false);
	const [team, setTeam] = useState({});
	const [localRoles, setLocalRoles] = useState({});
	const { toggleWidget } = useCrmContext();

	useImperativeHandle(ref, () => ({
		open: data => {
			setTeam(data?.team);
			setLocalRoles(data?.localRoles);
			toggleVisibility(true);
		},
		close: () => {
			toggleVisibility(false);
		},
	}));

	const onClose = () => {
		toggleVisibility(false);
		toggleWidget('newRole', null);
	};

	return visibility && <TeamEditorWidget localRoles={localRoles} team={team} onClose={onClose} />;
});

const TeamEditorWidget = props => {
	const { onClose, localRoles } = props;
	const [team, setTeam] = useState(props.team);
	const [active, setActive] = useState(false);
	const { widgetData, setWidgetData } = useCrmContext();
	const [teamRoles, setTeamRoles] = useState([]);
	const [hoverIndex, setHoverIndex] = useState(null);
	const [editIndex, setEditIndex] = useState([]);
	const [isOpen, setIsOpen] = useState(!_.isNull(team));
	const { loggedInUser } = useAuth();
	const [isUpdating, setIsUpdating] = useState(false);
	const [errRoleMsgs, setErrRoleMsgs] = useState({});

	const teamDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.teamApi,
	});

	useEffect(() => {
		if (team) {
			setTeamRoles(_.cloneDeep(team.Role || []));
			setActive(team.IsActive);
		} else {
			setTeamRoles([]);
			setActive(false);
		}
	}, [team]);

	useEffect(() => {
		const ignoreClickOnMeElement = document.getElementById('popover-wrapper');
		const customButton = ['roleSelect', 'closeEditButton', 'menu', 'LI'];
		const listener = event => {
			const isClickInsideElement = ignoreClickOnMeElement?.contains(event.target);
			if (
				!isClickInsideElement &&
				!customButton.includes(event.target.id.split('_')[0]) &&
				!customButton.includes(event.target.parentElement?.id.split('_')[0]) &&
				!customButton.includes(event.target.lastChild?.id?.split('-')[0]) &&
				!customButton.includes(event.target.tagName)
			) {
				close();
			}
		};
		document.addEventListener('click', listener, true);

		return () => {
			document.removeEventListener('click', listener, true);
		};
	}, []);

	const close = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		if (!isOpen) {
			onClose();
		}
	}, [isOpen]);

	const save = async () => {
		if (_.find(errRoleMsgs, item => item !== '')) {
			return;
		}

		setIsUpdating(true);
		try {
			const tempTeamRoles = _.filter(teamRoles, item => {
				if (item.RoleId !== '-1') {
					return item;
				}
			});

			const payload = _.cloneDeep(team);
			payload.Role = tempTeamRoles;
			payload.IsActive = active;
			setTeam(payload);
			setTeamRoles(tempTeamRoles);
			setWidgetData({ ...widgetData, data: payload });
			await teamDataLoader.update(team.TeamId, payload);
		} catch (error) {
			console.error(error);
		}
		setIsUpdating(false);
		setEditIndex([]);
		setHoverIndex(null);
		onClose();
	};

	const addRole = () => {
		const tempErr = {};
		const tempRole = _.cloneDeep(teamRoles);

		if (_.find(teamRoles, item => item.RoleId == '-1')) {
			return;
		}

		tempRole.unshift({
			RoleId: '-1',
			RoleName: '',
		});
		const tempEditIndex = _.map(editIndex, item => `${item.split('_')[0]}_${Number(item.split('_')[1]) + 1}`);
		tempEditIndex.unshift('roleSelect_0');

		Object.entries(errRoleMsgs).forEach(entry => {
			const [key, value] = entry;
			const newKey = `${key.split('_')[0]}_${Number(key.split('_')[1]) + 1}`;
			tempErr[newKey] = value;
		});

		setErrRoleMsgs({});
		setTeamRoles([]);
		setEditIndex([]);

		setTimeout(() => {
			setErrRoleMsgs(tempErr);
			setTeamRoles(tempRole);
			setEditIndex(tempEditIndex);
		}, 200);
	};

	return (
		<RoleSideWidget data-testid="team-editor-widget" id="popover-wrapper" isOpen={isOpen}>
			<div className="header">
				<div className="row">
					<TemMembersAvatars members={team?.User} style={{ marginLeft: '12px' }} />
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<IconButton sx={{ display: 'none' }}>
							<ContentCopyIcon />
						</IconButton>
						<IconButton onClick={() => close()}>
							<LastPageIcon />
						</IconButton>
					</Box>
				</div>
				<div className="main">
					<div className="row" style={{ justifyContent: 'normal', alignItems: 'center' }}>
						<SwitchButton
							checked={active}
							data-testid="switch-button"
							onChange={() => !!loggedInUser?.permission?.Team?.Edit && setActive(!active)}
						/>
						<Box sx={{ marginLeft: '17px' }}>
							<Title
								data-testid="team-name"
								style={{
									color: '#000',
									fontSize: '24px',
									fontWeight: '500',
								}}
							>
								{team.TeamName}
							</Title>
							<span>{_.size(team?.User)} users in team</span>
						</Box>
					</div>
				</div>
			</div>

			<OutlineWrapper>
				<div className="row wrapper-header">
					<SmallCircle>
						<ToggleOnOutlinedIcon sx={{ fontSize: '18px', color: '#4D79EA' }} />
					</SmallCircle>
					<SubTitle style={{ color: '#000' }}>Roles</SubTitle>
				</div>

				<AddRoleCard style={{ margin: '5px' }} onClick={() => addRole()}>
					<AddIcon sx={{ width: '18px', height: '18px', color: '#C4C4C4' }} />
				</AddRoleCard>

				<Box
					className="invisible-scrollbar"
					sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', overflowY: 'auto' }}
				>
					{teamRoles?.map((role, i) => (
						<TeamRoleListItem
							data={role}
							editIndex={editIndex}
							errMsgs={errRoleMsgs}
							hoverIndex={hoverIndex}
							index={i}
							localRoles={localRoles}
							setEditIndex={setEditIndex}
							setErrMsgs={setErrRoleMsgs}
							setHoverIndex={setHoverIndex}
							setTeamRoles={setTeamRoles}
							teamRoles={teamRoles}
						/>
					))}
				</Box>
				<div className="row wrapper-footer">
					<LinkButton
						data-testid="close-button"
						disabled={isUpdating || !loggedInUser?.permission?.Team?.Edit}
						style={{ border: '1px solid #4D79EA', fontSize: '12px' }}
						onClick={() => close()}
					>
						Cancel
					</LinkButton>

					<LinkButton
						data-testid="save-button"
						disabled={isUpdating || !loggedInUser?.permission?.Team?.Edit}
						style={
							loggedInUser?.permission?.Team?.Edit
								? { margin: '0px 0px 0px auto', background: '#4D79EA', color: '#FFF', width: '82px' }
								: {
										margin: '0px 0px 0px auto',
										border: '1px solid #4D79EA',
										fontSize: '12px',
										width: '82px',
								  }
						}
						onClick={save}
					>
						Save
					</LinkButton>
				</div>
			</OutlineWrapper>
		</RoleSideWidget>
	);
};

export default TeamEditor;

export const TeamRoleListItem = ({
	index,
	data,
	draggable,
	onDragStart,
	onDragEnd,
	onChecked,
	teamRoles,
	setTeamRoles,
	hoverIndex,
	setHoverIndex,
	editIndex,
	setEditIndex,
	errMsgs,
	setErrMsgs,
	localRoles,
}) => {
	const [role, setRole] = useState({});
	const [selected, setSelected] = useState(false);
	const [hover, setHover] = useState(false);
	const { widgetData } = useCrmContext();
	const { loggedInUser } = useAuth();

	useEffect(() => {
		setRole(data);
	}, [data]);

	const viewing = useMemo(
		() => widgetData?.tag === 'editRole' && widgetData?.data?.RoleId === role?.RoleId,
		[widgetData, role]
	);

	const deleteRole = roleId => {
		try {
			const tempErr = {};
			const tempRole = _.cloneDeep(teamRoles);
			tempRole.splice(index, 1);

			let tempEditIndex = _.filter(editIndex, item => item !== `roleSelect_${index}`);

			tempEditIndex = _.map(tempEditIndex, item => {
				if (Number(item.split('_')[1]) > Number(index)) {
					return `${item.split('_')[0]}_${Number(item.split('_')[1]) - 1}`;
				}
				return item;
			});

			Object.entries(errMsgs).forEach(entry => {
				const [key, value] = entry;
				let newKey = key;
				let newValue = value;
				if (Number(key.split('_')[1]) > Number(index)) {
					newKey = `${key.split('_')[0]}_${Number(key.split('_')[1]) - 1}`;
				}
				if (_.filter(tempRole, item => item.RoleId == roleId).length == 1 && !!newValue) {
					newValue = '';
				}

				tempErr[newKey] = newValue;
			});

			setErrMsgs({});
			setEditIndex([]);
			setTeamRoles([]);
			setHoverIndex(null);
			setTimeout(() => {
				setErrMsgs(tempErr);
				setTeamRoles(tempRole);
				setEditIndex(tempEditIndex);
			}, 200);
		} catch (error) {
			return error;
		}
	};

	const onChangeRole = (e, roleIndex) => {
		const temp = _.cloneDeep(errMsgs);
		const roleId = e.target.value.split('_')[0];

		if (_.find(teamRoles, item => item.RoleId == roleId)) {
			temp[roleIndex] = 'Role already exist';
		} else {
			temp[roleIndex] = '';
		}
		setErrMsgs(temp);

		teamRoles[index] = {
			RoleId: e.target.value.split('_')[0],
			RoleName: e.target.value.split('_')[1],
		};

		setTeamRoles(teamRoles);
	};

	const noMargin = useMemo(() => hover || selected || !onChecked, [hover, selected, onChecked]);

	return (
		<Box
			draggable={draggable}
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				marginTop: '10px',
				marginLeft: !onChecked ? '10px' : 'inherit',
				marginRight: noMargin ? '10px' : '37px',
			}}
			onDragEnd={onDragEnd}
			onDragStart={draggable && onDragStart}
			onMouseLeave={() => setHover(false)}
			onMouseOver={() => setHover(true)}
		>
			{onChecked && (hover || selected) && (
				<Box
					sx={{
						cursor: 'default',
						margin: '0px 11px',
					}}
					onClick={e => {
						if (onChecked) onChecked(e, role, !selected);
						setSelected(!selected);
					}}
				>
					{selected ? (
						<RadioButtonCheckedOutlinedIcon style={{ width: '15px', height: '15px', color: '#4D79EA' }} />
					) : (
						<RadioButtonUncheckedOutlinedIcon style={{ width: '15px', height: '15px', color: '#C4C4C4' }} />
					)}
				</Box>
			)}
			<RoleItem noMargin active={selected || viewing} cursor="default">
				<Box className="item-body">
					<Box
						sx={{
							flex: 1,
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'flex-end',

							span: {
								fontFamily: 'Inter',
								fontSize: '12px',
								fontWeight: 'normal',
								fontStretch: 'normal',
								fontStyle: 'italic',
								lineHeight: '1',
								letterSpacing: 'normal',
								textAlign: 'left',
								color: 'rgba(0, 0, 0, 0.6)',
								marginRight: '20px',
							},
						}}
					>
						{editIndex.includes(`roleSelect_${index}`) ? (
							<SubTitle sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '16px', flex: 1 }}>
								<Select
									class="roleName"
									data-testid={`roleSelect_${index}`}
									defaultValue={role.RoleId == '-1' ? '' : `${role.RoleId}_${role.RoleName}`}
									error={!!errMsgs[`roleSelect_${index}`]}
									id={`roleSelect_${index}`}
									name="roleName"
									style={{
										border: 'none',
										fontFamily: 'Inter',
									}}
									sx={{
										'& legend': { display: 'none' },
										'& fieldset': { top: 0 },
										height: '40px',
										width: '150px',
										fontSize: '12px',
										fontStretch: 'normal',
										fontStyle: 'normal',
										flex: '1',
										borderRadius: 3,
									}}
									onChange={e => {
										onChangeRole(e, `roleSelect_${index}`);
									}}
								>
									{localRoles.map(item => (
										<MenuItem value={`${item.RoleId}_${item.RoleName}`}>{item.RoleName}</MenuItem>
									))}
								</Select>
								<FormHelperText className="Mui-error" sx={{ marginLeft: '14px' }}>
									{errMsgs[`roleSelect_${index}`]}
								</FormHelperText>
							</SubTitle>
						) : (
							<SubTitle
								sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '16px', flex: 1, cursor: 'pointer' }}
								onClick={e => {
									if (loggedInUser.permission?.Team.Edit) {
										const temp = _.cloneDeep(editIndex);
										temp.push(`roleSelect_${index}`);
										setEditIndex(temp);
									}
								}}
								onMouseEnter={() => setHoverIndex(`roleSelect_${index}`)}
								onMouseLeave={() => setHoverIndex(null)}
							>
								{role.RoleName}
								{hoverIndex === `roleSelect_${index}` && !!loggedInUser.permission?.Team.Edit && (
									<IconButton
										data-testid={`closeEditButton_Edit_${index}`}
										id={`closeEditButton_Edit_${index}`}
										sx={{
											p: 0,
											width: '14px',
											height: '14px',
											paddingLeft: '5px',
											paddingBottom: '3px',
										}}
										onClick={e => {
											const temp = _.cloneDeep(editIndex);
											temp.push(`roleSelect_${index}`);
											setEditIndex(temp);
										}}
									>
										<EditOutlinedIcon
											sx={{
												color: 'rgba(0, 0, 0, 0.6)',
												width: '14px',
												height: '14px',
												fontSize: '14px',
												marginLeft: 'auto',
											}}
										/>
									</IconButton>
								)}
							</SubTitle>
						)}
						<IconButton sx={{ width: '20px', height: '20px' }} onClick={() => deleteRole(role.RoleId)}>
							<DeleteOutlineIcon sx={{ width: '20px', height: '20px', color: '#4D79EA' }} />
						</IconButton>
					</Box>
				</Box>
			</RoleItem>
		</Box>
	);
};
