import React from 'react';

const RecentlySearchedIcon = () => (
	<svg fill="none" height="36" viewBox="0 0 35 36" width="35" xmlns="http://www.w3.org/2000/svg">
		<circle cx="17.5" cy="18" fill="white" fillOpacity="0.06" r="17.5" />
		<path
			d="M20.5624 19.7499H19.8711L19.6261 19.5136C20.5856 18.3957 21.0747 16.9495 20.9906 15.4787C20.9066 14.0079 20.256 12.6268 19.1754 11.6255C18.0948 10.6242 16.6683 10.0804 15.1953 10.1085C13.7223 10.1365 12.3175 10.7341 11.2758 11.7758C10.2341 12.8175 9.63648 14.2223 9.60845 15.6953C9.58043 17.1683 10.1242 18.5948 11.1255 19.6754C12.1268 20.756 13.5079 21.4066 14.9787 21.4906C16.4495 21.5747 17.8957 21.0856 19.0136 20.1261L19.2499 20.3711V21.0624L23.6249 25.4286L24.9286 24.1249L20.5624 19.7499ZM15.3124 19.7499C14.5336 19.7499 13.7723 19.5189 13.1248 19.0863C12.4773 18.6536 11.9726 18.0387 11.6746 17.3192C11.3766 16.5997 11.2986 15.808 11.4505 15.0442C11.6025 14.2804 11.9775 13.5788 12.5281 13.0281C13.0788 12.4775 13.7804 12.1025 14.5442 11.9505C15.308 11.7986 16.0997 11.8766 16.8192 12.1746C17.5387 12.4726 18.1536 12.9773 18.5863 13.6248C19.0189 14.2723 19.2499 15.0336 19.2499 15.8124C19.2506 16.3296 19.1492 16.842 18.9516 17.32C18.7539 17.798 18.4639 18.2324 18.0981 18.5981C17.7324 18.9639 17.298 19.2539 16.82 19.4516C16.342 19.6492 15.8296 19.7506 15.3124 19.7499Z"
			fill="white"
			fillOpacity="0.87"
		/>
	</svg>
);

export default RecentlySearchedIcon;
