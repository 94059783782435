const assigningAuthoritySchema = {
	resourceType: '__string__',
	id: '__string__',
	identifier: ['__Identifier__'],
	name: '__string__',
	active: '__boolean__',
	external: '__boolean__',
	managingOrganization: '__Reference(Organization)__',
	prefix: {
		visitNumber: '__string__',
		accessionNumber: '__string__',
		patientId: '__string__',
	},
};
export default assigningAuthoritySchema;
