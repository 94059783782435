import React, { useContext, useState, useEffect } from 'react';
//mui
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';
// ramsoft library
import { SelectionContext } from '@worklist-2/core/src/context/SelectionContext';
import fhirExtensionUrls from '@worklist-2/core/src/fhir/extension/fhirExtensionUrls';

const CheckboxSelectAll = ({ rows = [] }) => {
	const { selectedStudies, selectAllStudies } = useContext(SelectionContext);
	const [isSelected, setIsSelected] = useState(false);

	useEffect(() => {
		setIsSelected(selectedStudies?.length === rows.length && selectedStudies?.length !== 0);
	}, [selectedStudies]);

	const handleSelectAllRows = () => {
		if (selectedStudies?.length === rows.length) {
			selectAllStudies([]);
		} else {
			selectAllStudies(
				rows.map(r => ({
					id: r.original.id,
					isCritical: !!r.original.extension?.find(
						elem => elem.url === fhirExtensionUrls.imagingStudy.isCritical
					)?.valueBoolean,
					dicomUid: r.original.identifier
						?.find(id => id.system === 'urn:dicom:uid')
						?.value?.split('urn:oid:')?.[1],
					internalManagingOrganizationId: r.original.internalManagingOrganizationID,
					referringPhysician: r.original.referringPhysician,
					referringPhysicianId: r.original.referringPhysicianId,
					accessionNumber: r.original.identifier?.find(elem =>
						elem.type?.coding?.some(item => item.code === 'ACSN')
					)?.value,
					basedOn: r.original.basedOn,
					patientID: r.original.patientID,
					patientEmail: r.original.patientEmail,
					subject: r.original.subject,
					rawData: r.original.rawData,
				}))
			);
		}
	};

	return (
		<Box
			sx={{
				height: '20px',
				width: '20px',
				'&:hover': {
					'.checkbox': { display: 'flex' },
					'.flag': { display: 'none' },
				},
			}}
		>
			<Box
				className="checkbox"
				sx={{
					display: isSelected ? 'flex' : 'none',
				}}
			>
				<Checkbox
					checked={isSelected}
					checkedIcon={
						<Box
							sx={{
								height: '20px',
								width: '20px',
								borderRadius: '3px',
								background: '#42A5F5',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<CheckIcon
								fontSize="large"
								sx={{
									color: '#272727',
									width: '14px',
									height: '14px',
								}}
							/>
						</Box>
					}
					data-testid="cbx-select-all"
					icon={
						<Box
							sx={{
								height: '18px',
								width: '18px',
								borderRadius: '3px',
								border: '2px solid rgba(255,255,255,.6)',
							}}
						/>
					}
					sx={{
						padding: '0 !important',
					}}
					onClick={() => {
						handleSelectAllRows();
					}}
				/>
			</Box>
		</Box>
	);
};

export default CheckboxSelectAll;
