import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
export const INDENT = 20;

export const StyledTree = styled(props => <Box {...props}></Box>)(() => ({
	lineHeight: 1.75,
	zIndex: 1,
	'& .tree__input': {
		width: '100%',
		backgroundColor: 'transparent',
		border: 'none',
		borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
		color: 'rgba(255, 255, 255, 0.87)',
		fontSize: '0.875rem',
		fontWeight: '400',
		'&:focus': {
			outline: 'none',
		},
	},
	height: '100%',
}));

export const StyledFolder = styled(props => <Box {...props}></Box>)(props => ({
	display: 'flex',
	flexDirection: 'column',
}));

export const StyledFolderInput = styled(props => <Box {...props}></Box>)(props => ({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: `${(props.indent - 1) * INDENT}px`,
	width: 'fit-content',
}));

export const ActionsWrapper = styled(props => <Box {...props}></Box>)(props => ({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'nowrap',
	justifyContent: 'space-between',
	marginLeft: `${(props.indent - 1) * INDENT}px`,
	position: 'relative',
	'& .actions': {
		padding: '3px 5px',
		gap: '5px',
		position: 'absolute',
		alignItems: 'center',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		display: 'none',
		boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
		backgroundColor: 'rgba(26, 26, 26, 1)',
		borderRadius: '20px',
		pointerEvents: 'none',
		'& >svg': {
			cursor: 'pointer',
			marginLeft: '10px',
		},
		left: props.treePosition ? `${props.treePosition}px` : `${300 - (props.indent - 1) * INDENT - 22}px`,
	},

	'&:hover .actions': {
		display: 'flex',
		pointerEvents: 'all',
		transition: '0.2s',
	},
}));

export const StyledName = styled(props => <Box {...props}></Box>)(props => ({
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	backgroundColor: 'transparent',
	height: '36px',
	'&:hover ': {
		'& p': {
			color: props.isSelectedFolder ? 'rgba(66, 165, 245, 1)' : 'rgba(66, 165, 245, 0.87)',
		},
		'& svg': {
			color: props.isSelectedFolder ? 'rgba(66, 165, 245, 1)' : 'rgba(66, 165, 245, 0.87)',
		},
		'& div': {
			color: props.isSelectedFolder ? 'rgba(66, 165, 245, 1)!important' : 'rgba(66, 165, 245, 0.87)!important',
		},
	},
}));

export const Collapse = styled(props => <Box {...props}></Box>)(props => ({
	height: 'max-content',
	width: '100%',
	maxHeight: `${props?.isOpen ? 'max-content' : '0px'}`,
	overflow: 'hidden',
	transition: '0.3s ease-in-out',
	display: `${props?.isOpen ? 'block' : 'none'}`,
	marginLeft: `${props.index * INDENT}px`,
}));
