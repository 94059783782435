import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const MobileDrawerTitle = ({ title, sx, sxWrapper, onGoBack, icon, goBack }) => {
	const { t } = useTranslation('profile');

	return (
		<Box
			data-testid="mobile-drawer-title"
			sx={{
				display: 'flex',
				alignItems: 'center',
				marginBottom: '25px',
				...sxWrapper,
			}}
			onClick={onGoBack}
		>
			{onGoBack && (
				<IconButton data-testid="back-icon" sx={{ marginLeft: '-15px', ...goBack?.sx }} onClick={onGoBack}>
					<Icon component={goBack?.icon ? goBack?.icon : ChevronLeftIcon} />
				</IconButton>
			)}

			{icon && (
				<IconButton>
					<Icon
						component={icon.component}
						data-testid="custom-icon"
						sx={{
							color: icon.color || 'currentColor',

							'path[fill]': {
								fill: 'currentColor',
							},

							'path[stroke]': {
								stroke: 'currentColor',
							},

							...icon.sx,
						}}
					/>
				</IconButton>
			)}

			<Typography
				sx={{
					color: 'rgba(18, 18, 18, 0.8)',
					fontSize: '14px',
					lineHeight: '16px',
					letterSpacing: '0.15px',
					fontWeight: '400',
					textTransform: 'uppercase',
					flex: '1',
					...sx,
				}}
			>
				{t(title.toUpperCase())}
			</Typography>
		</Box>
	);
};

export default MobileDrawerTitle;
