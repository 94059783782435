import axios from 'axios';

const patchAppointment = async ({ __config, appointmentId, cancellationReason, patchList }) => {
	const response = await axios.patch(
		`${__config.data_sources.blume}Appointment/${appointmentId}?cancellationReason=${cancellationReason}`,
		patchList
	);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default patchAppointment;
