/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
import imagingStudyWorklistSchema from './schemata/imagingStudyWorklistSchema';
/** **********************************************************************************************************************
Resource: ImagingStudyWorklist
Reference: N/A
Proprietary RamSoft resource
*********************************************************************************************************************** */

export default class ImagingStudyWorklistResource extends FhirDomainResource {
	accountNumber;

	accountStatus;

	age;

	allergies = [];

	authorizationNumbers;

	authorizationPeriod;

	basedOn = [];

	birthDate;

	appointmentCancellationReason;

	cellPhone;

	chargeStatus;

	communicationStatusName;

	conditions = [];

	dateTimeAddendum;

	dateTimeOrdered;

	dateTimeRead;

	dateTimeReceived;

	dateTimeSigned;

	dateTimeTranscribed;

	dateTimeVerified;

	dateTimeScheduled;

	appointmentDateTime;

	department;

	description;

	encounter;

	ethnicity = [];

	examDuration;

	examRoom;

	fillerOrderNumber;

	financialClass;

	gender;

	healthcareService;

	homePhone;

	identifier = [];

	imagingOrganization;

	imagingOrganizationId;

	insuranceCopay;

	insuranceExpiry;

	insurancePayer;

	insuranceStatus;

	internalDiagnosticRequestID;

	internalManagingOrganizationID;

	internalMasterOrganizationID;

	internalOrganizationId;

	internalOrganizationValue;

	isLinked;

	languages = [];

	lastUpdated;

	managingOrganization;

	modality = [];

	modalityModifier;

	numberOfInstances;

	numFrames;

	numImages;

	numReports;

	objectType;

	orderDateTime;

	orderStatus;

	patientAddress;

	patientAge;

	patientBalance;

	patientContactMethod;

	patientEmail;

	patientID;

	patientLocation;

	performingPhysician;

	performingPhysicianNPI;

	performingTechnologist;

	placerOrderNumber;

	procedureCode = [];

	race = [];

	readingFacility;

	readingPhysician;

	readingPhysicianNPI;

	reasonCode = [];

	referringFacility;

	referringPhysician;

	referringPhysicianNPI;

	requestedAppointmentDateTime;

	smokingStatus = [];

	specialArrangement;

	ssn;

	state;

	studyDateTime;

	studyID;

	studyUID;

	subject;

	timeAtStatus;

	timeZone;

	transcriptionist;

	transcriptionistFacility;

	uid;

	visitClass;

	visitNumber;

	orderNotes;

	started;

	eTag;

	priorStatus;

	eligibilityStatus;

	createdDateTimeUTC;

	primaryPriorAuthorizationNumber;

	workflowStep;

	consultingPhysician = [];

	numDocuments;
	constructor(resourceString) {
		super(resourceString, imagingStudyWorklistSchema);
		this.resourceType = 'ImagingStudyWorklist';
		this.populateFields();
	}
}
