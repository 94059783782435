import React from 'react';

const CrossIcon = () => (
	<svg fill="none" height={20} width={20} xmlns="http://www.w3.org/2000/svg">
		<mask
			height={20}
			id="a"
			maskUnits="userSpaceOnUse"
			style={{
				maskType: 'alpha',
			}}
			width={20}
			x={0}
			y={0}
		>
			<path d="M0 0h20v20H0z" fill="#D9D9D9" />
		</mask>
		<g mask="url(#a)">
			<path
				d="m5.334 15.834-1.167-1.167L8.834 10 4.167 5.334l1.167-1.167L10 8.834l4.667-4.667 1.167 1.167L11.167 10l4.667 4.667-1.167 1.167L10 11.167l-4.666 4.667Z"
				fill="#fff"
				fillOpacity={0.6}
			/>
		</g>
	</svg>
);

export default CrossIcon;
