// @flow

// core
import React, { createContext, useContext, useState, type Node, useEffect } from 'react';
// libs
import { useLocation } from 'react-router';
// utils
import { searchScopes, useFhirDataLoader } from '@worklist-2/core/src';
import { AlertsAndNeeds } from '@rs-ui/views/PatientInfoView/model/AlertsAndNeeds';
import { type IPatientAlert } from '@rs-ui/views/PatientInfoView/utils';

interface IPatient {
	alerts: IPatientAlert[];
	birthDate: string;
	id: string;
	name: string;
}

interface IPatientAlertsContext {
	/** Whether the patient alerts are currently being fetched */
	isLoading: boolean;
	/** Holds simple patient data (id and alerts) */
	patient: IPatient | null;
	/** Fetches patient alerts */
	loadPatientAlerts: () => void;
	/** Sets the patient data - this is called from each experience (page) separately due to how they're set up */
	setPatient: (patient: IPatient) => void;
}

interface IPatientAlertsProviderProps {
	children: Node;
}

// Context
export const PatientAlertContext = createContext<IPatientAlertsContext>({});

// Hook
export const usePatientAlertContext = (): IPatientAlertsContext => useContext(PatientAlertContext);

// Provider
export const PatientAlertsProvider = ({ children }: IPatientAlertsProviderProps): Node => {
	// ==================== State ====================
	const [patient, setPatient] = useState<IPatient | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const location = useLocation();

	const patientAlertsLoader = useFhirDataLoader({
		scope: searchScopes.patientAlert,
	});

	// ==================== Events ====================
	const loadPatientAlerts = () => {
		setIsLoading(true);

		if (!patient?.id) return;

		patientAlertsLoader
			.load({ patientId: patient.id, roleBased: true }, true)
			.then(res => {
				const alerts =
					(res?.entry || []).map(i => ({
						...new AlertsAndNeeds(i.resource).data,
					})) || [];

				setPatient(prev => ({ ...prev, alerts }));
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (!patient?.id) return;

		loadPatientAlerts();
	}, [patient?.id]);

	// Reset the patient when user goes back to the worklist
	useEffect(() => {
		if (
			location.pathname.includes('home') ||
			location.pathname.includes('patient') || // #TODO: check for patient detail page specifically, 'patient' could be elsewhere
			location.pathname.includes('organization')
		) {
			// study history is under patientinfo, but still seperate from the rest of the experience
			if (location.pathname.includes('study-history')) return;

			setPatient(null);
		}
	}, [location.pathname, isLoading]);

	return (
		<PatientAlertContext.Provider value={{ isLoading, patient, loadPatientAlerts, setPatient }}>
			{children}
		</PatientAlertContext.Provider>
	);
};
