import React, { useState } from 'react';
import InboxIcon from '@mui/icons-material/Inbox';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import MobileList from './MobileList/MobileList';
import Box from '@mui/material/Box';
import LocationCard from '../LocationCard';
import MobileBottomDrawer from './MobileDrawer/MobileBottomDrawer';

const MobileScheduledMoreList = () => {
	const [isShownDirectionDrawer, setIsShownDirectionDrawer] = useState(false);
	const items = [
		{
			primary: 'Reschedule',
			icon: InboxIcon,
			sx: {
				'& .MuiTypography-root': {
					color: '#42A5F5',
					fontSize: '12px',
					fontWeight: '400',
				},
			},
		},
		{
			primary: 'Get Directions',
			icon: LocationOnIcon,
			onClick: () => setIsShownDirectionDrawer(true),
			sx: {
				'& .MuiTypography-root': {
					color: '#42A5F5',
					fontSize: '12px',
					fontWeight: '400',
				},
			},
		},
		{
			primary: 'Cancel',
			icon: CloseIcon,
			sx: {
				'& .MuiTypography-root': {
					color: '#B96877',
					fontSize: '12px',
					fontWeight: '400',
				},
				'& .MuiSvgIcon-root': {
					color: '#B96877',
				},
			},
		},
	];

	return (
		<Box>
			<MobileList color="#42A5F5" items={items} />

			<MobileBottomDrawer open={isShownDirectionDrawer} onClose={() => setIsShownDirectionDrawer(false)}>
				<LocationCard
					sx={{
						position: 'relative',
						width: '100%',
					}}
				/>
			</MobileBottomDrawer>
		</Box>
	);
};

export default MobileScheduledMoreList;
