import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';

const CommentItem = ({ isUnread, name, date, comment, isOmega }) => (
	<Box
		sx={{
			position: 'relative',
			width: '100%',
			padding: '14px 22px',
			borderBottom: '1px solid rgba(196, 196, 196, 0.40)',
		}}
	>
		{isUnread && (
			<Box
				data-testid="commentItem_unread"
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '2px',
					height: '100%',
					backgroundColor: '#4D79EA',
				}}
			/>
		)}
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Avatar
				sx={{
					width: '30px',
					height: '30px',
					fontFamily: 'Inter',
					fontSize: '14px',
					fontWeight: 500,
					color: isOmega ? '#FFF' : '#4D79EA',
					backgroundColor: isOmega ? 'rgba(77, 121, 234, 0.60)' : 'rgba(77, 121, 234, 0.20)',
				}}
			>
				{`${name.split(' ')[0][0]}${name.split(' ')[1][0]}`}
			</Avatar>
			<Typography
				sx={{
					marginLeft: '6px',
					fontFamily: 'Inter',
					fontSize: '12px',
					fontWeight: 500,
					color: isOmega ? '#FFF' : '#4D79EA',
				}}
			>
				{name}
			</Typography>
			<Typography
				sx={{
					marginLeft: '10px',
					fontFamily: 'Inter',
					fontSize: '12px',
					fontWeight: 500,
					color: isOmega ? 'rgba(255, 255, 255, 0.60)' : 'rgba(0, 0, 0, 0.6)',
				}}
			>
				{`${new Date(date).toLocaleDateString()} ${new Date(date).toLocaleTimeString(navigator.language, {
					hour: '2-digit',
					minute: '2-digit',
				})}`}
			</Typography>
		</Box>
		<Typography
			sx={{
				height: '24px',
				marginLeft: '36px',
				fontFamily: 'Inter',
				fontSize: '12px',
				lineHeight: '12px',
				display: '-webkit-box',
				'-webkit-line-clamp': '2',
				'-webkit-box-orient': 'vertical',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				color: isOmega ? '#FFF' : '#000',
			}}
		>
			{comment}
		</Typography>
	</Box>
);

export default CommentItem;
