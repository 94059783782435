import React from 'react';

const ClearAllFilterIcon = () => (
	<svg data-name="Layer 1" id="Layer_1" viewBox="0 0 15.95 16.57" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17,17.11l2.5,2.5a.37.37,0,0,1,0,.53l-.63.62a.36.36,0,0,1-.52,0l-2.51-2.5a.36.36,0,0,0-.52,0l-2.63,2.63a.36.36,0,0,1-.52,0l-.61-.61a.36.36,0,0,1,0-.52l2.63-2.63a.37.37,0,0,0,0-.53L11.54,14a.37.37,0,0,1,0-.53l.63-.63a.37.37,0,0,1,.52,0l2.63,2.63a.37.37,0,0,0,.53,0L18.33,13a.36.36,0,0,1,.52,0l.61.61a.36.36,0,0,1,0,.52L17,16.58a.37.37,0,0,0,0,.53Zm-5.1-1.86,0,2.58-1.1,2a.69.69,0,0,1-.24.05.54.54,0,0,1-.27-.07.52.52,0,0,1-.24-.43V12.6L3.78,5.26a.51.51,0,0,1,.39-.83H19.1a.52.52,0,0,1,.46.28.48.48,0,0,1-.06.54L13.1,12.3l-.47-.47a.42.42,0,0,0-.6,0l-1.39,1.39a.42.42,0,0,0,0,.59l1.25,1.44Z"
			transform="translate(-3.66 -4.43)"
		/>
	</svg>
);

export default ClearAllFilterIcon;
