// Cores
import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// MUI components
import Typography from '@mui/material/Typography';

// Components
import { StyledFolderInput } from '../Tree.style';
import TreeFolderName from '../TreeFolderName';

// Context
import { useTreeContext } from '@rs-ui/context/TreeContext';
import { Box } from '@mui/material';

const TreePlaceholderInput = ({ id, folderLevel, handleSubmit, defaultValue, isEdit, parentId }) => {
	// Translation
	const { t } = useTranslation('teaching');

	// TreePlaccerholderInput ref
	const treeInputRef = useRef(null);

	// Input reference
	const inputRef = useRef();

	// Context error code
	const { errorCode, setErrorCode, dispatch, isUpdating } = useTreeContext();

	useEffect(() => {
		setErrorCode(defaultValue ? 0 : 3);
	}, [setErrorCode, defaultValue]);

	const handleDetectOutSideClick = useCallback(
		event => {
			if (isUpdating) return;
			// Exclude in case clicked add subfolder of parent that contains current input element
			const btnElementId = event.target?.closest('button')?.id;
			if (btnElementId && parentId && btnElementId.includes(parentId)) {
				inputRef.current?.focus();
				return;
			}
			const outsideClick = !treeInputRef.current?.contains(event.target);

			if (outsideClick) {
				if (!isEdit) {
					setErrorCode(0);
					dispatch({ type: 'DELETE_FOLDER', payload: { id } });
				} else {
					handleSubmit(null, folderLevel);
				}
			}
		},
		[treeInputRef, setErrorCode, dispatch, id, isUpdating, parentId]
	);

	useEffect(() => {
		inputRef.current?.focus();
		document.addEventListener('click', handleDetectOutSideClick, { capture: true });
		return () => {
			document.removeEventListener('click', handleDetectOutSideClick, { capture: true });
		};
	}, [handleDetectOutSideClick]);

	/**
	 * Create folder on submit
	 * @returns {void}
	 */
	const handleCreateFolder = () => {
		if (!inputRef.current) return;
		handleSubmit(inputRef.current.value, folderLevel);
	};

	return (
		<Box
			ref={treeInputRef}
			sx={{
				width: '100%',
			}}
		>
			<StyledFolderInput id={id} indent={folderLevel}>
				<TreeFolderName
					isEditing
					isLastChild
					isOpen
					disabledButton={errorCode !== 0}
					handleSubmit={handleCreateFolder}
					level={folderLevel}
					name={
						<input
							ref={inputRef}
							className="tree__input"
							data-testid="folder-input"
							defaultValue={defaultValue}
							placeholder={!isEdit ? t('New Folder') : t('Folder name')}
							onChange={e => {
								if (errorCode !== 0) setErrorCode(0);

								if (!e.target?.value) {
									setErrorCode(3);
								} else {
									setErrorCode(0);
								}
							}}
							onKeyDown={event => {
								if (event.key === 'Enter') {
									handleCreateFolder();
								}
								if (event.key === 'Escape') {
									if (!isEdit) {
										setErrorCode(0);
										dispatch({ type: 'DELETE_FOLDER', payload: { id } });
									} else {
										handleSubmit(null, folderLevel);
									}
								}
							}}
						/>
					}
				/>
				{errorCode !== 0 && errorCode < 3 && (
					<Typography
						data-testid="folder-error-message"
						sx={{
							color: 'rgba(255, 255, 255, 0.87)',
							whiteSpace: 'wrap',
							marginLeft: '48px',
							fontSize: '0.875rem',
							fontWeight: '400',
						}}
					>
						{t(`Errors.FolderCreation-${errorCode}`)}
					</Typography>
				)}
			</StyledFolderInput>
		</Box>
	);
};

TreePlaceholderInput.propTypes = {
	folderLevel: PropTypes.number.isRequired,
	handleSubmit: PropTypes.func,
	defaultValue: PropTypes.string,
	id: PropTypes.string.isRequired,
	parentId: PropTypes.string,
	isEdit: PropTypes.bool,
};

TreePlaceholderInput.defaultProps = {
	handleSubmit: () => {},
	defaultValue: '',
	isEdit: false,
	parentId: null,
};

export default TreePlaceholderInput;
