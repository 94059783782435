import React from 'react';
import { Box, Typography } from '@mui/material';

const Section = ({ title, icon, color, secondColor, sx, smallHeader, children }) => (
	<Box
		sx={{
			boxSizing: 'border-box',
			width: '100%',
			border: '1px solid rgba(196, 196, 196, 0.5)',
			borderRadius: '10px',
			...sx,
		}}
	>
		<Box
			sx={{
				padding: smallHeader ? '7px 12px' : '16px 12px 16px 20px',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				borderBottom: '1px solid rgba(196, 196, 196, 0.5)',
			}}
		>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box
					sx={{
						width: '16px',
						height: '16px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: color,
						borderRadius: '50%',
						backgroundClip: 'padding-box',
						border: `2px solid ${secondColor}`,
					}}
				>
					{icon}
				</Box>
				<Typography
					sx={{
						marginLeft: smallHeader ? '2px' : '12px',
						fontFamily: 'Inter',
						fontWeight: 500,
						fontSize: '14px',
						color: 'rgba(0, 0, 0, 0.6)',
					}}
				>
					{title}
				</Typography>
			</Box>
		</Box>
		{children}
	</Box>
);

export default Section;
