import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { get, set, del } from 'idb-keyval';
import createCurrentStudyInfoSlice from './slices/currentStudyInfoSlice';
import createSpeechRecognitionSlice from './slices/speechRecognitionSlice';
import commonMiddlewares from './middlewares';
import { logError, logInfo } from '@rs-core/utils/logger';

// Custom storage implementation for idb-keyval
const customStorage = createJSONStorage(() => ({
	getItem: async name => {
		try {
			const result = await get(name);
			logInfo('globalStore', `Retrieved item ${name} from IndexedDB:`);
			return result;
		} catch (error) {
			logError('globalStore', `Error getting item ${name} from IndexedDB`, error);
			return null;
		}
	},
	setItem: async (name, value) => {
		try {
			logInfo('globalStore', `Setting item ${name} in IndexedDB`);
			await set(name, value);
		} catch (error) {
			logError('globalStore', `Error setting item ${name} in IndexedDB`, error);
		}
	},
	removeItem: async name => {
		try {
			logInfo('globalStore', `Removing item ${name} from IndexedDB`);
			await del(name);
		} catch (error) {
			logError('globalStore', `Error removing item ${name} from IndexedDB`, error);
		}
	},
}));

// Zustand store
export const useGlobalStore = create(
	persist(
		commonMiddlewares((set, get) => ({
			...createCurrentStudyInfoSlice(set, get),
			...createSpeechRecognitionSlice(set, get), // Non-persistent state
		})),
		{
			name: 'global-store',
			storage: customStorage,
			// Only persist the currentStudyInfo slice
			partialize: state => ({ currentStudyInfo: state.currentStudyInfo }),
		}
	)
);

export default useGlobalStore;
