import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState, useCallback } from 'react';
import { LinkButton, OutlineWrapper, RoleSideWidget, SmallCircle, SubTitle } from '../../../views/CrmView/styles';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LastPageIcon from '@mui/icons-material/LastPage';
import SwitchButton from './SwitchButton';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import PermissionListItem from './PermissionListItem';
import { AssignCaseMenu, StyledMenu } from '../Accounts/AccountDetailPopOver/NewAccountPopOver';
import { Input } from '@mui/material';
import { TemMembersAvatars } from './TeamCard';
import { crmSearchScopes, useCRMDataLoader, useAuth } from '@worklist-2/core/src';
import _ from 'lodash';
import { useCrmContext } from '../../../context/CRMContext';

const RolePreview = forwardRef((props, ref) => {
	const [visibility, toggleVisibility] = useState(false);
	const [role, setRole] = useState({});
	const { toggleWidget, roles, setRoles, toggleRoleGridLoading, toggleReloadUserGrid } = useCrmContext();
	const [caseHandlers, setCaseHandlers] = useState([]);
	const [assignedUsers, setAssignedUsers] = useState([]);
	const { loggedInUser } = useAuth();

	const dataLoader = useCRMDataLoader({ scope: crmSearchScopes.roleApi });
	const userDataLoader = useCRMDataLoader({ scope: crmSearchScopes.userApi });

	useEffect(() => {
		if (visibility) fetchPermissions();
	}, [visibility]);

	useImperativeHandle(ref, () => ({
		open: () => {
			toggleVisibility(true);
		},
		close: () => {
			toggleVisibility(false);
		},
	}));

	const fetchPermissions = async () => {
		try {
			const tempRoles = _.cloneDeep(roles);
			const permissions = _.compact(_.uniqBy(_.flatMap(tempRoles?.map(entry => entry?.Permission)), 'Category'));
			permissions.forEach(obj => {
				for (const key in obj) {
					if (typeof obj[key] === 'boolean') {
						obj[key] = false;
					}
				}
			});
			setRole({ Permission: permissions });
			if (loggedInUser.permission?.User?.Read) {
				fetchUsers();
			}
		} catch (error) {
			console.error(error);
		}
	};

	const onClose = () => {
		setRole({});
		setAssignedUsers([]);
		toggleVisibility(false);
		toggleWidget('newRole', null);
	};

	const save = useCallback(async () => {
		try {
			toggleRoleGridLoading(true);
			const payload = { ...role };
			// create new role
			if (!payload.RoleName) throw Error('Please a name for this role');
			const { data } = await dataLoader.save({}, payload);
			setRoles(_.compact(_.concat(data, roles)));
			toggleReloadUserGrid(true);
			toggleRoleGridLoading(false);
			onClose();
		} catch (error) {
			console.error(error);
		}
	}, [role]);

	const fetchUsers = async () => {
		try {
			const usersData = await userDataLoader.load();
			setCaseHandlers(usersData);
		} catch (error) {
			console.error(error.message);
		}
	};

	return (
		visibility && (
			<RolePreviewWidget
				{...props}
				assignedUsers={assignedUsers}
				caseHandlers={caseHandlers}
				role={role}
				save={save}
				setAssignedUsers={setAssignedUsers}
				setRole={setRole}
				onClose={onClose}
			/>
		)
	);
});

const RolePreviewWidget = ({ onClose, role, setRole, save, caseHandlers, assignedUsers, setAssignedUsers }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [isOpen, setIsOpen] = useState(true);
	const open = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const close = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		if (!isOpen) {
			onClose();
		}
	}, [isOpen]);

	const groupedPermissions = useMemo(() => {
		try {
			return _.groupBy(role.Permission, permission => permission?.Category);
		} catch (error) {
			return null;
		}
	}, [role]);

	useEffect(() => {
		const ignoreClickOnMeElement = document.getElementById('popover-wrapper-addrole');
		const listener = event => {
			const isClickInsideElement = ignoreClickOnMeElement?.contains(event.target);
			if (
				!isClickInsideElement &&
				!event.target?.getAttribute('class')?.split(' ')?.includes('MuiBackdrop-invisible') &&
				!event.target?.id.includes('assignedmenu') &&
				!document.activeElement.className?.split(' ')?.includes('MuiList-root')
			) {
				close();
			}
		};
		document.addEventListener('click', listener, true);

		return () => {
			document.removeEventListener('click', listener, true);
		};
	}, []);

	useEffect(() => {
		const tempUser = _.map(assignedUsers, item => ({
			UserId: item.UserId,
			UserName: item.UserName,
		}));

		setRole(old => ({
			...old,
			User: tempUser,
		}));
	}, [assignedUsers]);

	const togglePermission = useCallback(
		({ category, data }) => {
			const temp = _.cloneDeep(role);
			const index = temp?.Permission?.findIndex(item => item.Category === category);
			temp.Permission[index] = { ...temp.Permission[index], ...data };
			setRole(temp);
		},
		[role]
	);

	const handleButtonAll = mode => {
		const Permission = Object.keys(groupedPermissions).map(key => {
			const obj = {};
			Object.keys(groupedPermissions[key][0]).forEach(index => {
				if ((index == 'Read' && mode == 'viewAll') || mode == 'modifyAll') {
					obj[index] = true;
				} else {
					obj[index] = false;
				}
			});
			obj.Category = key;
			return obj;
		});
		setRole({ ...role, Permission });
	};

	return (
		<RoleSideWidget data-testid="role-preview-widget" id="popover-wrapper-addrole" isOpen={isOpen}>
			<div className="header">
				<div className="row">
					<div className="left">
						{assignedUsers.length === 0 ? (
							<>
								<AccountCircleOutlinedIcon
									sx={{ color: 'rgba(0, 0, 0, 0.4)', fontSize: '36px' }}
									onClick={handleClick}
								/>
								<span>Unassigned</span>
							</>
						) : (
							<div style={{ marginLeft: '10px' }} onClick={handleClick}>
								<TemMembersAvatars
									members={assignedUsers}
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										'&:hover': { cursor: 'pointer' },
									}}
								/>
							</div>
						)}
						<StyledMenu
							MenuListProps={{
								'aria-labelledby': 'demo-customized-button',
							}}
							anchorEl={anchorEl}
							id="demo-customized-menu"
							open={open}
							onClose={handleClose}
						>
							<AssignCaseMenu
								assignedUsers={assignedUsers}
								caseHandlers={caseHandlers}
								onClose={handleClose}
								onSelect={user => {
									setAssignedUsers(old => {
										const found = old.find(entry => entry.UserId === user.UserId);
										return found
											? old.filter(entry => entry.UserId !== user.UserId)
											: [...old, user];
									});
									handleClose();
								}}
							/>
						</StyledMenu>
					</div>
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<IconButton>
							<ContentCopyIcon />
						</IconButton>
						<IconButton onClick={() => close()}>
							<LastPageIcon />
						</IconButton>
					</Box>
				</div>
				<div className="main">
					<div className="row" style={{ justifyContent: 'normal', alignItems: 'center', flex: 1 }}>
						<SwitchButton
							checked={role?.IsActive || false}
							onChange={() => setRole({ ...role, IsActive: !role?.IsActive })}
						/>
						<Input
							defaultValue={role?.RoleName}
							placeholder="Enter a name for this Role"
							sx={{
								border: 0,
								margin: '0px 24px 0px 10px',
								fontSize: '24px',
								color: '#000',
								width: '100%',
							}}
							onChange={e => setRole({ ...role, RoleName: e.target.value })}
						/>
					</div>
				</div>
			</div>

			<OutlineWrapper>
				<div className="row wrapper-header">
					<SmallCircle>
						<ToggleOnOutlinedIcon sx={{ fontSize: '18px', color: '#4D79EA' }} />
					</SmallCircle>
					<SubTitle style={{ color: '#000' }}>Permissions</SubTitle>
					<LinkButton style={{ margin: '0px 0px 0px auto' }} onClick={() => handleButtonAll('reset')}>
						<CachedOutlinedIcon sx={{ mr: '2px' }} />
						Reset All
					</LinkButton>
				</div>
				<Box
					className="invisible-scrollbar"
					sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', overflowY: 'auto' }}
				>
					<div className="row" style={{ padding: '12px 15px 8px 18px' }}>
						<LinkButton
							style={{ border: '1px solid #4D79EA', height: '25px', fontSize: '12px' }}
							onClick={() => handleButtonAll('viewAll')}
						>
							View All
						</LinkButton>
						<LinkButton
							style={{ border: '1px solid #4D79EA', height: '25px', fontSize: '12px' }}
							onClick={() => handleButtonAll('modifyAll')}
						>
							Modify All
						</LinkButton>
					</div>
					{_.map(groupedPermissions, (permissions, i) => (
						<PermissionListItem
							key={i}
							category={i}
							permissions={permissions}
							togglePermission={togglePermission}
						/>
					))}
				</Box>
				<div className="row wrapper-footer">
					<LinkButton
						data-testid="close-button"
						style={{ border: '1px solid #4D79EA', fontSize: '12px' }}
						onClick={close}
					>
						Cancel
					</LinkButton>

					<LinkButton
						style={{ margin: '0px 0px 0px auto', background: '#4D79EA', color: '#FFF', width: '82px' }}
						onClick={save}
					>
						Save
					</LinkButton>
				</div>
			</OutlineWrapper>
		</RoleSideWidget>
	);
};

export default RolePreview;
