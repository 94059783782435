// React Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Material UI Libraries
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// SVG
import GroupIcon from '@worklist-2/ui/src/assets/icons/group.svg';

function GroupReportsCard({ report }) {
	return (
		<Box
			sx={{
				position: 'relative',
				width: '350px',
				height: '226px',
				borderRadius: '15px',
				background: 'rgba(255, 255, 255, 0.2)',
				backdropFilter: 'blur(73px)',
				border: '1px solid rgba(255, 255, 255, 0.1)',
				cursor: 'pointer',
				transition: '0.2s',
			}}
		>
			<Box
				sx={{
					display: 'grid',
					margin: '15px',
					gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
					gridAutoRows: 'minmax(min-content, max-content)',
					rowGap: '8px',
					columnGap: '8px',
					maxHeight: '80%',
					maxWidth: '100%',
				}}
			>
				{report.group.items.map((item, index) => (
					<img
						key={index}
						alt="report-image"
						src={item.image}
						style={{
							width: '100%',
							height: '100%',
							background: 'rgba(57, 57, 57, 0.1)',
							border: '1px solid rgba(255, 255, 255, 0.2)',
							backdropFilter: 'blur(1px)',
							borderRadius: '5px',
						}}
					/>
				))}
			</Box>

			<Box
				sx={{
					background: 'rgba(57, 57, 57, 0.8)',
					backdropFilter: 'blur(50px)',
					position: 'absolute',
					bottom: '10px',
					left: '10px',
					right: '10px',
					display: 'flex',
					alignItems: 'center',
					padding: '16px 16px 20px 34px',
					color: '#FFFFFF',
					borderRadius: '30px 30px 10px 10px',
					justifyContent: 'space-between',
				}}
			>
				<Typography>{report.group.name}</Typography>
				<GroupIcon />
			</Box>
		</Box>
	);
}

GroupReportsCard.propTypes = {
	/**
	 * report is object of a report
	 */
	report: PropTypes.object,
};

export default GroupReportsCard;
