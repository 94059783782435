// core
import React, { useRef, useState, useMemo, useLayoutEffect, useEffect } from 'react';

// libraries
import { Controller } from 'react-hook-form';

// MUI
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Fade from '@mui/material/Fade';
import ListItemIcon from '@mui/material/ListItemIcon';
// MUI icons
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Check from '@mui/icons-material/Check';
// components
import FormFieldDefault from './FormFieldDefault';
import { DropdownButtonItem } from '../DropdownButtonItem';

// types
import FormFieldVariantType from '../../../formTypes/FormFieldVariantType';
import { useTranslation } from 'react-i18next';

const FormSelectVariant = ({
	formHook,
	name,
	options,
	modifySelectedValue,
	disableOnBlur,
	menuPopoverAdjustments,
	customOnChange,
	buttonIcon = '',
	buttonLabel = '',
	onButtonClick,
	props,
}) => {
	const { control, getValues, watch } = formHook;
	const ref = useRef();
	const [autoWidth, setAutoWidth] = useState(0);
	const { MenuProps, ...otherProps } = props;
	const { t } = useTranslation('orderset');
	const { renderValue } = props.SelectProps;
	const fieldWatcher = watch(name);

	const renderMenuItem = props?.SelectProps?.customRenderMenuItem
		? props.SelectProps.customRenderMenuItem
		: item => {
				const selectedValues =
					props.SelectProps.multiple && getValues(name)?.find(v => renderValue(v) === renderValue(item));

				if (props?.SelectProps?.ignoredOptions?.includes(item?.id)) return null;

				return name === 'facility' ? (
					<MenuItem
						key={renderValue(item)}
						sx={{
							...MenuProps,
						}}
						value={item}
					>
						<ListItemAvatar>
							<Avatar sx={{ bgcolor: '#5F92DE' }}>
								<CorporateFareIcon />
							</Avatar>
						</ListItemAvatar>

						<ListItemText
							primary={
								<Typography
									sx={{
										color: '#121221DE',
										fontSize: '16px',
										fontWeight: '400',
									}}
								>
									{item.organizationName}
								</Typography>
							}
							secondary={
								<>
									<Typography
										display="inline"
										sx={{
											color: '#12121299',
											fontSize: '14px',
											fontWeight: '400',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
									>
										{item?.address}
									</Typography>
									<Typography
										display="inline"
										sx={{
											color: '#12121299',
											fontSize: '14px',
											fontWeight: '400',
										}}
									>
										{' '}
										- {item?.distance?.toString().split('.')[0]} Km
									</Typography>
								</>
							}
						/>
					</MenuItem>
				) : (
					<MenuItem
						key={renderValue(item)}
						sx={{
							...MenuProps,
						}}
						value={item}
					>
						{props.SelectProps.multiple && (
							<ListItemIcon>
								{props.SelectProps.checkbox ? (
									<Checkbox checked={Boolean(selectedValues)} />
								) : (
									selectedValues && <Check />
								)}
							</ListItemIcon>
						)}

						{t(renderValue(item))}
					</MenuItem>
				);
		  };

	const getValue = () => {
		if (name === 'scanType' && options.length === 1) {
			return options[0];
		}

		if (props.SelectProps.multiple) {
			return options?.filter(option =>
				getValues(name)?.find(item => {
					if (props?.SelectProps?.isOptionEqualToValue) {
						return props.SelectProps.isOptionEqualToValue(option, item);
					}
					return renderValue(option) === renderValue(item);
				})
			);
		}

		return (
			options?.find(item => renderValue(item) === renderValue(getValues(name))) ||
			(props?.SelectProps.freeSolo ? renderValue(getValues(name)) : null)
		);
	};

	const menuProps = useMemo(
		() => ({
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: props.InputProps?.startAdornment || props.InputProps?.endAdornment ? 'right' : 'center',
			},
			transformOrigin: {
				vertical: 'top',
				horizontal:
					props.InputProps?.startAdornment || props.InputProps?.endAdornment
						? menuPopoverAdjustments?.adjustHorizontal || 'right'
						: 'center',
			},
			TransitionComponent: Fade,
			transitionDuration: 200,
			PaperProps: {
				variant: 'outlined',
				elevation: 0,
				sx: {
					maxHeight: 300,
					...(props.InputProps?.startAdornment || props.InputProps?.endAdornment
						? {
								// -2 to account for border
								width:
									autoWidth -
									2 +
									(menuPopoverAdjustments?.adjustWidth ? menuPopoverAdjustments.adjustWidth : 0),
						  }
						: undefined),
					...(onButtonClick ? { '& > ul': { paddingTop: 0 } } : undefined),
					...(menuPopoverAdjustments?.adjustVisibility
						? { visibility: menuPopoverAdjustments?.adjustVisibility }
						: undefined),
				},
			},
		}),
		[autoWidth, props.InputProps?.startAdornment, menuPopoverAdjustments]
	);

	const selectProps = {
		MenuProps: menuProps,
		// If SelectProps with MenuProps has been passed in, they will override
		// current MenuProps
		...props.SelectProps,
	};

	const optionsToRender = useMemo(() => {
		const noOptionsItem = <MenuItem>{t('No options')}</MenuItem>;
		const menuItems =
			!options?.length && onButtonClick ? [noOptionsItem] : options?.map(item => renderMenuItem(item));

		const dropdownBtn = (
			<DropdownButtonItem key="dropdown-btn-item" icon={buttonIcon} onButtonClick={onButtonClick}>
				{buttonLabel}
			</DropdownButtonItem>
		);

		return onButtonClick ? [dropdownBtn, ...menuItems] : menuItems;
	}, [options, onButtonClick, fieldWatcher]);

	useLayoutEffect(() => {
		setAutoWidth(ref.current?.node?.offsetWidth);
	}, [ref, ref.current, ref.current?.node, ref.current?.node?.offsetWidth]);

	useEffect(() => {
		setAutoWidth(ref.current?.node?.offsetWidth);
	}, [ref, ref.current, ref.current?.node, ref.current?.node?.offsetWidth]);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field }) => {
				const [oldValue, setOldValue] = useState(null);

				useEffect(() => {
					setOldValue(field.value);
				}, [field.value]);

				return (
					<FormFieldDefault
						ref={ref}
						formHook={formHook}
						name={name}
						props={{
							select: true,
							...field,
							onBlur: disableOnBlur ? () => {} : field.onBlur,
							onChange: event => {
								let modifiedValue = null;

								if (modifySelectedValue) {
									modifiedValue = modifySelectedValue(event.target.value, oldValue);
								}

								const value = modifiedValue || event;

								if (customOnChange) {
									customOnChange(value);
								}
								field.onChange(value);
							},
							value: getValue(),
							children: optionsToRender,
							...otherProps,
							SelectProps: { ...selectProps }, // This goes after props
						}}
					/>
				);
			}}
		/>
	);
};

FormSelectVariant.propTypes = FormFieldVariantType();

export default FormSelectVariant;
