export const defaultOptions = [
	{
		id: 'last24',
		display: 'Last 24 hrs',
	},
	{
		id: 'T',
		display: 'Today',
	},
	{
		id: 'Tmr',
		display: 'Tomorrow',
	},
	{
		id: 'lastW',
		display: 'Last 7 days',
	},
	{
		id: 'lastM',
		display: 'Last 30 days',
	},
];
