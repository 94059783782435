import React from 'react';
import CRMContextProvider from '../../context/CRMContext';
import CaseView from '../../views/CrmView';
import LiveChatContextProvider from '../../components/CRM/LiveChat/LiveChatContext';
import ChatContextProvider from '@worklist-2/ui/src/components/Chat/ChatContext';
import { ChatGlobalContextProvider } from '@worklist-2/ui/src';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const Crm = () => {
	const metaBreezeChat = useBooleanFlagValue('meta-breeze-chat');
	return metaBreezeChat ? (
		<ChatGlobalContextProvider>
			<ChatContextProvider>
				<LiveChatContextProvider>
					<CRMContextProvider>
						<CaseView />
					</CRMContextProvider>
				</LiveChatContextProvider>
			</ChatContextProvider>
		</ChatGlobalContextProvider>
	) : (
		<CRMContextProvider>
			<CaseView />
		</CRMContextProvider>
	);
};

export default Crm;
