// core
import React, { useState } from 'react';

// MUI
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from 'react-i18next';

// Library
import { defaultOptions } from '@rs-ui/views/utils/defaultPillDateOptions';

const useStyles = makeStyles()(() => ({
	container: {
		width: 250,
		height: 376,
		backgroundColor: '#393939',
		borderRadius: 6,
	},
	presets: {
		width: 43,
		height: 16,
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 12,
		lineHeight: '16px',
		letterSpacing: 0.4,
		color: 'rgba(255, 255, 255, 0.38)',
		marginTop: 15,
		marginLeft: 15,
	},
	boxRow: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: 12,
		marginLeft: 15,
		marginRight: 15,
	},
	box: {
		width: 150,
		height: 48,
		backgroundColor: '#393939',
		border: '1px solid #797979',
		borderRadius: 4,
		marginBottom: 8,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	selectedBox: {
		width: 150,
		height: 48,
		backgroundColor: '#393E42',
		border: '1px solid #42A5F5',
		borderRadius: 4,
		marginBottom: 8,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	typography: {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 16,
		lineHeight: '16px',
		color: 'rgba(255, 255, 255, 0.6)',
	},
	selectedTypography: {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 16,
		lineHeight: '16px',
		color: '#42A5F5',
	},
}));

const DateTimePillPicker = ({ onPillClick, selectedPill, setSelectedPill, pillPickerOption }) => {
	const { classes } = useStyles();
	const { t } = useTranslation('datetimerangepicker');
	const pillOptions = pillPickerOption || defaultOptions;

	return (
		<Box classNmae={classes.container}>
			<Typography className={classes.presets}>{t('Presets')}</Typography>
			<Box className={classes.boxRow}>
				{pillOptions.map(option => (
					<Box
						key={option.id}
						className={option.id === selectedPill ? classes.selectedBox : classes.box}
						onClick={() => {
							setSelectedPill(option.id);
							onPillClick && onPillClick(option.display);
						}}
					>
						<Typography
							className={option.id === selectedPill ? classes.selectedTypography : classes.typography}
							sx={{
								'&:hover': {
									color: 'rsPrimary.main',
								},
							}}
						>
							{t(option.display)}
						</Typography>
					</Box>
				))}
			</Box>
		</Box>
	);
};

DateTimePillPicker.propTypes = {
	/**
	 * The function to be called when user selects the pill
	 */
	onPillClick: PropTypes.func,
};

export default DateTimePillPicker;
