import { ZoomTool } from '@cornerstonejs/tools';
import { getEnabledElement, triggerEvent } from '@cornerstonejs/core';
import { vec3 } from 'gl-matrix';
import { SCALE_MODIFIED } from '../contexts/consts/consts';

class ZoomToolUpdated extends ZoomTool {
	constructor(
		toolProps = {},
		defaultToolProps = {
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				// whether zoom to the center of the image OR zoom to the mouse position
				zoomToCenter: false,
				minZoomScale: 0.1,
				maxZoomScale: 30,
				pinchToZoom: true,
				pan: true,
				invert: false,
			},
		}
	) {
		super(toolProps, defaultToolProps);
		if (this.configuration.pinchToZoom) {
			this.touchDragCallback = this._pinchCallback.bind(this);
		} else {
			this.touchDragCallback = this._dragCallback.bind(this);
		}
		this.mouseDragCallback = this._dragCallback.bind(this);
	}

	_pinchCallback = (evt) => {
		const pointsList = evt.detail.currentPointsList;

		if (pointsList.length > 1) {
			const { element, currentPoints, viewportId } = evt.detail;
			const enabledElement = getEnabledElement(element);
			const { viewport } = enabledElement;

			const camera = viewport.getCamera();
			const worldPos = currentPoints.world;
			const { focalPoint } = camera;
			this.initialMousePosWorld = worldPos;
			// The direction vector from the clicked location to the focal point
			// which would act as the vector to translate the image (if zoomToCenter is false)
			let dirVec = vec3.fromValues(
				focalPoint[0] - worldPos[0],
				focalPoint[1] - worldPos[1],
				focalPoint[2] - worldPos[2]
			);

			dirVec = vec3.normalize(vec3.create(), dirVec);
			this.dirVec = dirVec;
			if (camera.parallelProjection) {
				this._dragParallelProjection(evt, viewport, camera, true);
			} else {
				this._dragPerspectiveProjection(evt, viewport, camera, true);
			}
			triggerEvent(element, SCALE_MODIFIED, {
				scaleType: 'custom',
				zoom: viewport.getZoom(),
				pan: viewport.getPan(),
				viewportId,
			});
			viewport.render();
		}

		if (this.configuration.pan) {
			this._panCallback(evt);
		}
	};
	_dragCallback = (evt) => {
		const { element, viewportId } = evt.detail;
		const enabledElement = getEnabledElement(element);
		const { viewport } = enabledElement;

		const camera = viewport.getCamera();

		if (camera.parallelProjection) {
			this._dragParallelProjection(evt, viewport, camera);
		} else {
			this._dragPerspectiveProjection(evt, viewport, camera);
		}

		triggerEvent(element, SCALE_MODIFIED, {
			scaleType: 'custom',
			zoom: viewport.getZoom(),
			pan: viewport.getPan(),
			viewportId,
		});

		viewport.render();

		const zoomPreset = viewport?.defaultOptions?.rules?.scaling?.fit;
		const verticalAlignment = viewport?.defaultOptions?.rules?.verticalAlign;
		const horizontalAlignment = viewport?.defaultOptions?.rules?.horizontalAlign;

		if (zoomPreset) {
			delete viewport.defaultOptions.rules.scaling.fit;
		}

		if (verticalAlignment) {
			delete viewport.defaultOptions.rules.verticalAlign;
		}

		if (horizontalAlignment) {
			delete viewport.defaultOptions.rules.horizontalAlign;
		}
	};
}
export default ZoomToolUpdated;
