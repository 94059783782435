import React from 'react';
import { Typography } from '@mui/material';
import { useFrames } from './useFrames';

const FrameNumber = ({ viewportId }) => {
	const { totalFrames, currentFrame, shouldRender } = useFrames({ viewportId, isInitialized: true });

	if (!shouldRender) {
		return null;
	}

	return (
		<Typography
			sx={{
				position: 'absolute',
				top: '20px',
				right: '20px',
				background: 'rgb(57, 57, 57)',
				width: '54px',
				height: '24px',
				borderRadius: '10px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				color: '#fff',
				fontSize: '12px',
			}}
		>
			{currentFrame}/{totalFrames}
		</Typography>
	);
};

export default FrameNumber;
