import React from 'react';

import { TableBlock } from '../../../index';

import { getInputStyle } from './utils';

import {
	CalloutBlockIcon,
	CopyBlockIcon,
	DeleteBlockIcon,
	DragBlockIcon,
	SeparateBlocksIcon,
} from '../../../../assets/icons';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';

import styles from './DraftItem.module.scss';

const DraftItemHover = ({
	theme,
	setDisplayMode,
	item,
	setDraft,
	setCurrentValue,
	activePanel,
	draggingItem,
	provided,
}) => {
	const handleBlockDbClick = () => {
		if (item.type.includes('Attachment') || item.type === 'MergedBlocks') {
			return;
		}

		setDisplayMode({ type: 'edit' });
		setCurrentValue(item.text);
	};

	const handleMergedBlockDbClick = block => {
		if (item.type === 'MergedBlocks') {
			setDisplayMode({ type: 'edit', block });
			setCurrentValue(block.text);
		}
	};

	const handleMouseLeave = () => {
		setDisplayMode({ type: 'default' });
		setCurrentValue('');
	};

	const onSeparate = () => {
		if (item.type === 'MergedBlocks') {
			setDraft(prev =>
				prev.map(elem => {
					if (elem.DocumentDraftId === activePanel.id) {
						const initBlock = elem.DocumentJson.find(JsonBlock => JsonBlock.id === item.id);
						const newDocumentJson = elem.DocumentJson.filter(JsonBlock => JsonBlock.id !== item.id);

						initBlock.mergedBlocks.forEach(mergedBlock => {
							newDocumentJson.push(mergedBlock);
						});

						return {
							...elem,
							DocumentJson: newDocumentJson,
						};
					}
					return elem;
				})
			);
		}
	};

	const onDelete = () => {
		setDraft(prev =>
			prev.map(elem => {
				if (elem.DocumentDraftId === activePanel.id) {
					return {
						...elem,
						DocumentJson: elem.DocumentJson.filter(block => block.id !== item.id),
					};
				}
				return elem;
			})
		);
	};

	const textBlockHoverStyles = () => {
		let resultStyles = styles.textBlockHoverBox;

		if (theme === 'OAI') {
			resultStyles += ` ${styles.textBlockHoverBoxOAI}`;
		} else {
			resultStyles += ` ${styles.textBlockHoverBoxBlume}`;
		}

		if (item?.type === 'Callout') {
			resultStyles += ` ${styles.textBlockHoverBoxCallout}`;

			if (theme === 'OAI') {
				resultStyles += ` ${styles.textBlockHoverBoxCalloutOAI}`;
			} else {
				resultStyles += ` ${styles.textBlockHoverBoxCalloutBlume}`;
			}
		}

		if (item?.type === 'Table') {
			resultStyles += ` ${styles.textBlockHoverBoxTable}`;
		}

		return resultStyles;
	};

	return (
		<div
			className={styles.textBlockHover}
			data-testid="draft-item-hover"
			onDoubleClick={handleBlockDbClick}
			onMouseLeave={handleMouseLeave}
		>
			<div className={styles.textBlockHoverDrag} {...provided.dragHandleProps}>
				<DragBlockIcon />
			</div>
			<div className={textBlockHoverStyles()} data-testid="textBlockHoverBox">
				{!draggingItem && (
					<div
						className={`${styles.textBlockHoverBoxButtons} ${
							item?.type === 'Table' ? styles.textBlockHoverBoxTableButtons : ''
						}`}
					>
						<div
							className={`${styles.textBlockHoverBoxButtonsItem} ${
								theme === 'OAI'
									? styles.textBlockHoverBoxButtonsItemOAI
									: styles.textBlockHoverBoxButtonsItemBlume
							}`}
							data-testid="separate-icon"
							onClick={onSeparate}
						>
							{item?.type?.includes('Attachment') ? (
								<ViewQuiltOutlinedIcon sx={{ fontSize: '18px', color: '#C4C4C4' }} />
							) : (
								<SeparateBlocksIcon />
							)}
						</div>
						<div
							className={`${styles.textBlockHoverBoxButtonsItem} ${
								theme === 'OAI'
									? styles.textBlockHoverBoxButtonsItemOAI
									: styles.textBlockHoverBoxButtonsItemBlume
							}`}
						>
							<CopyBlockIcon />
						</div>
						<div
							className={`${styles.textBlockHoverBoxButtonsItem} ${
								theme === 'OAI'
									? styles.textBlockHoverBoxButtonsItemOAI
									: styles.textBlockHoverBoxButtonsItemBlume
							}`}
							data-testid="delete-icon"
							onClick={onDelete}
						>
							<DeleteBlockIcon />
						</div>
					</div>
				)}
				<div
					className={`${item?.type === 'MergedBlocks' ? '' : styles.textBlockHoverBoxText} ${
						item?.type === 'Table' ? styles.textBlockHoverBoxTableText : ''
					} ${item?.type === 'Callout' ? styles.textBlockHoverBoxTextCallout : ''}`}
				>
					{item?.type === 'Callout' ? (
						<div className={styles.calloutHoverIcon}>
							<CalloutBlockIcon />
						</div>
					) : (
						''
					)}
					{item?.type === 'Check List' ? (
						<div className={styles.checkListBox}>
							{item?.checkList.map(checkListItem => (
								<label key={checkListItem.id} className={styles.checkListItem}>
									<input
										readOnly
										checked={checkListItem.checked}
										className={`${styles.checkListItemCheckbox} ${
											theme === 'OAI'
												? styles.checkListItemCheckboxOAI
												: styles.checkListItemCheckboxBlume
										}`}
										type="checkbox"
									/>
									<span className={`${getInputStyle(theme, item)} ${styles.checkListItemText}`}>
										{checkListItem.value}
									</span>
								</label>
							))}
						</div>
					) : item?.type === 'Table' ? (
						<TableBlock block={item} theme={theme} type="hover" />
					) : item?.type === 'MergedBlocks' ? (
						<div className={styles.mergedBlocks}>
							{item.mergedBlocks.map((block, index) => (
								<div
									data-testid={`merged-block-${index}`}
									onDoubleClick={() => handleMergedBlockDbClick(block)}
								>
									{block?.type === 'Callout' ? (
										<div className={styles.calloutHoverIcon}>
											<CalloutBlockIcon />
										</div>
									) : (
										''
									)}
									<div
										className={`${styles.textBlockHoverBoxText} ${
											block?.type === 'Table' ? styles.textBlockHoverBoxTableText : ''
										}`}
									>
										{block?.type === 'Check List' ? (
											<div className={styles.checkListBox}>
												{block?.checkList.map(checkItem => (
													<label key={checkItem.id} className={styles.checkListItem}>
														<input
															readOnly
															checked={checkItem.checked}
															className={`${styles.checkListItemCheckbox} ${
																theme === 'OAI'
																	? styles.checkListItemCheckboxOAI
																	: styles.checkListItemCheckboxBlume
															}`}
															type="checkbox"
														/>
														<span
															className={`${getInputStyle(theme, block)} ${
																styles.checkListItemText
															}`}
														>
															{checkItem.value}
														</span>
													</label>
												))}
											</div>
										) : block?.type === 'Table' ? (
											<TableBlock block={block} theme={theme} type="hover" />
										) : (
											<span className={getInputStyle(theme, block)}>{block?.text}</span>
										)}
									</div>
								</div>
							))}
						</div>
					) : (
						<div
							className={`${styles.draftItemHover} ${
								item?.type === 'Callout'
									? styles.calloutTextBlock
									: item?.type === 'Attachment Image'
									? styles.attachmentImage
									: ''
							}`}
						>
							<span className={getInputStyle(theme, item)}>{item?.text}</span>
							{item.type === 'Attachment Image' && <img src={item.path} />}
							{item.type === 'Attachment Video' && <video src={item.path} />}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default DraftItemHover;
