import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';

import _ from 'lodash';

const [hourDisplaySize, minuteDisplaySize, displayWeight] = [24, 12, 400];

const HourMarkerCircle = ({ sx }) => (
	<Box
		sx={{
			height: 10,
			width: 10,
			backgroundColor: '#B0B0B0',
			borderRadius: '50%',
			display: 'inline-block',
			ml: 4,
			top: '-5px',
			position: 'absolute',
			...sx,
		}}
	/>
);

const MinuteDivisions = ({ hour, isFirst, minutesPerDivision, rowHeight, rowsPerDivision }) => {
	const chunksCount = 60 / minutesPerDivision;

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				borderBottomColor: 'text.faint',
			}}
		>
			{_.fill(Array(chunksCount)).map((value, index) => {
				const minute = index * minutesPerDivision;
				const isLast = chunksCount - 1 === index;

				return (
					<Box
						key={`${hour}-${minute}`}
						sx={{
							ml: 0.25,
							height: isLast ? rowHeight * rowsPerDivision - 1 : rowHeight * rowsPerDivision,
						}}
					>
						{minute === 0 ? (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									position: 'relative',
									height: '100%',
								}}
							>
								<Typography
									sx={{
										borderBottom: 1,
										borderBottomColor: 'text.faint',
										width: 18,
										fontSize: minuteDisplaySize,
										fontWeight: displayWeight,
										color: 'text.faint',
										height: '100%',
										alignItems: 'center',
										display: 'inline-flex',
									}}
								>
									{hour < 12 ? 'am' : 'pm'}
								</Typography>
								<HourMarkerCircle index={`${hour}-${minute}`} sx={isFirst ? { top: 0 } : undefined} />
							</Box>
						) : (
							<Typography
								sx={{
									borderBottom: minute === 60 - minutesPerDivision ? 0 : 1,
									borderBottomColor: 'text.faint',
									width: 18,
									fontSize: minuteDisplaySize,
									fontWeight: displayWeight,
									color: 'text.faint',
									display: 'grid',
									justifyContent: 'center',
									height: '100%',
									alignItems: 'center',
								}}
							>
								{minute}
							</Typography>
						)}
					</Box>
				);
			})}
		</Box>
	);
};

const HourDivisions = ({ startTime, endTime, minutesPerDivision, rowHeight, rowsPerDivision }) =>
	_.fill(Array(endTime - startTime)).map((value, index) => {
		const hour = startTime + index;
		const hourDisplay = hour > 12 ? hour - 12 : hour || 12;

		return (
			<Box
				key={`hour-${hour}`}
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
					borderBottom: 1,
					borderBottomColor: 'text.faint',
					width: 48,
				}}
			>
				<Typography
					sx={{
						fontSize: hourDisplaySize,
						fontWeight: displayWeight,
						color: 'text.faint',
						width: 27,
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					{hourDisplay}
				</Typography>
				<MinuteDivisions
					hour={hour}
					isFirst={index === 0}
					minutesPerDivision={minutesPerDivision}
					rowHeight={rowHeight}
					rowsPerDivision={rowsPerDivision}
				/>
			</Box>
		);
	});

const Timeline = ({ startTime, endTime, minutesPerDivision, rowHeight, rowsPerDivision }) => (
	<Box
		sx={{
			width: 66,
			borderRight: 2,
			borderRightColor: 'text.faint',
		}}
	>
		<HourDivisions
			endTime={endTime}
			minutesPerDivision={minutesPerDivision}
			rowHeight={rowHeight}
			rowsPerDivision={rowsPerDivision}
			startTime={startTime}
		/>
		<Box sx={{ height: '0px', marginTop: '-10px' }}>
			<HourMarkerCircle sx={{ ml: 7.75, position: 'static' }} />
		</Box>
	</Box>
);

Timeline.propTypes = {
	/**
	 * The starting time (military/24-HR clock)
	 */
	startTime: PropTypes.number,

	/**
	 * The end time (military/24-HR clock)
	 */
	endTime: PropTypes.number,

	/**
	 * The number of minutes per division
	 */
	minutesPerDivision: PropTypes.number,

	/**
	 * The height of one division row
	 */
	rowHeight: PropTypes.number,
};

Timeline.defaultProps = {
	startTime: 8,
	endTime: 20,
	minutesPerDivision: 15,
	rowsPerDivision: 1,
};

export default Timeline;
