import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Tooltip } from '@mui/material';
import useInitCornerstoneLibraries from '../hooks/useInitCornerstoneLibraries';
import { ToolGroupManager } from '@cornerstonejs/tools';
import { createToolGroup, createToolGroupWithRemap, toolGroupId } from './toolGroup';
import { ImageViewerExternalContextProvider } from './ImageViewerExternalContext';
import LoadData from './LoadData';
import SeriesNavigator from './SeriesNavigator';
import ImageViewerExternalViewport from './ImageViewerExternalViewport';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { useIsMobile } from '@rs-core/utils';

const ImageViewerExternal = ({
	internalManagingOrganizationID,
	studyInstanceUID,
	onPopOut,
	disabledKeyImages,
	creatorUserId,
	isUploaded,
	isMobile: isMobileProp,
	sx,
	blumeToken = null,
}) => {
	const wonIvDefaultmouseaction = useBooleanFlagValue('WON-IV-DEFAULTMOUSEACTION');

	const { t } = useTranslation('imageViewer');

	const isMobile = isMobileProp || useIsMobile();

	const { cornerstoneInitialized } = useInitCornerstoneLibraries({
		internalManagingOrganizationID,
		studyInstanceUID,
		blumeToken,
	});

	useEffect(() => {
		const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);
		if (!toolGroup) {
			if (wonIvDefaultmouseaction) {
				createToolGroupWithRemap({ isMobile });
			} else {
				createToolGroup({ isMobile });
			}
		}
	}, [wonIvDefaultmouseaction]);

	if (!cornerstoneInitialized) {
		return null;
	}

	const handleDownloadImage = id => {
		if (!id) return;
		const width = 1080;
		const height = 1080;
		const element = document.getElementById(id);
		if (!element) return;
		const canvas = element.firstChild?.firstChild;
		if (!canvas) return;
		try {
			const img = canvas.toDataURL('image/jpeg');
			const image = new Image(width, height);
			image.src = img;
			const newCanvas = document.createElement('canvas');
			newCanvas.width = width;
			newCanvas.height = height;
			const newContext = newCanvas.getContext('2d');
			newCanvas.style.cssText = 'position: absolute; left: 0; top: -10000000px;';

			image.onload = function () {
				newContext.drawImage(image, 0, 0, width, height);
				const src = newCanvas.toDataURL('image/jpeg');
				const link = document.createElement('a');
				link.download = id;
				link.href = src;
				link.click();

				link.remove();
				newCanvas.remove();
				image.remove();
			};
		} catch (err) {
			console.error(err.message);
		}
	};

	return (
		<Box
			key={studyInstanceUID}
			sx={{
				borderRadius: '10px',
				background: '#000',
				width: '100%',
				height: '100%',
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				overflow: 'hidden',
				...sx,
			}}
		>
			<ImageViewerExternalContextProvider
				creatorUserId={creatorUserId}
				internalManagingOrganizationID={internalManagingOrganizationID}
				isUploaded={isUploaded}
				studyInstanceUID={studyInstanceUID}
			>
				{onPopOut && (
					<Tooltip title={t('topToolbar.Detach', { defaultValue: 'Detach' })}>
						<LaunchIcon
							sx={{
								cursor: 'pointer',
								position: 'absolute',
								color: 'rgba(255, 255, 255, 0.60)',
								top: '12px',
								left: '12px',
								zIndex: '1',
							}}
							onClick={onPopOut}
						/>
					</Tooltip>
				)}
				<LoadData disabledKeyImages={disabledKeyImages} />

				<ImageViewerExternalViewport isMobile={isMobile} onExternalImageDownload={handleDownloadImage} />

				<SeriesNavigator />
			</ImageViewerExternalContextProvider>
		</Box>
	);
};

export default ImageViewerExternal;
