// Core

import React, { useState, useEffect } from 'react';

// MUI

import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';

// Component

const FormToolSelectField = ({
	id,
	required = false,
	label,
	options = [],
	answer = [],
	onChangeData,
	isCompact,
	...props
}) => {
	const labelId = `${id}-select-label`;
	const [selection, setSelection] = useState('');

	useEffect(() => {
		if (answer.length > 0) {
			setSelection(answer[answer.length - 1].value);
		}
	}, [answer]);

	return (
		<FormControl required={required} sx={{ width: '100%' }}>
			<InputLabel
				id={labelId}
				sx={{
					fontSize: 'var(--element-content-label-font-size)',
				}}
			>
				{isCompact ? '' : label}
			</InputLabel>

			<Select
				displayEmpty
				id={id}
				label={isCompact ? '' : label}
				labelId={isCompact ? '' : labelId}
				renderValue={
					selection !== ''
						? undefined
						: () => <div style={{ '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.2)' }}>Select</div>
				}
				sx={{
					WebkitTextFillColor: 'rgba(0,0,0,1)',
					color: 'rgba(0,0,0,1)',
					'& .MuiInputBase-input.Mui-disabled': {
						WebkitTextFillColor: 'rgba(0,0,0,0.7)',
						color: 'rgba(0,0,0,0.7)',
					},
					...(isCompact && {
						'& .MuiSelect-select': {
							padding: isCompact && '12px 16px',
						},
					}),
					height: isCompact ? '45px' : '55px',
				}}
				value={selection}
				onChange={e => {
					setSelection(e.target.value);
					onChangeData(e, null);
				}}
				{...props}
			>
				{Array.isArray(options)
					? options.map((item, index) => (
							<MenuItem
								key={`${index}-${item.key}`}
								sx={{ color: 'rgba(0,0,0,1)', height: isCompact ? '20px' : '36px' }}
								value={item.key}
							>
								{item.description}
							</MenuItem>
					  ))
					: null}
			</Select>
		</FormControl>
	);
};

export default FormToolSelectField;
