import hotkeyCodes from './hotkeyCodes';
import Tab from '../../../../assets/icons/imageViewer/9.svg';
import Shift from '../../../../assets/icons/imageViewer/16.svg';
import Control from '../../../../assets/icons/imageViewer/17.svg';
import Alt from '../../../../assets/icons/imageViewer/18.svg';
import Space from '../../../../assets/icons/imageViewer/32.svg';
import ArrowLeft from '../../../../assets/icons/imageViewer/37.svg';
import key38 from '../../../../assets/icons/imageViewer/38.svg';
import key39 from '../../../../assets/icons/imageViewer/39.svg';
import key40 from '../../../../assets/icons/imageViewer/40.svg';
import key65 from '../../../../assets/icons/imageViewer/65.svg';
import key66 from '../../../../assets/icons/imageViewer/66.svg';
import key67 from '../../../../assets/icons/imageViewer/67.svg';
import key68 from '../../../../assets/icons/imageViewer/68.svg';
import key69 from '../../../../assets/icons/imageViewer/69.svg';
import key70 from '../../../../assets/icons/imageViewer/70.svg';
import key71 from '../../../../assets/icons/imageViewer/71.svg';
import key72 from '../../../../assets/icons/imageViewer/72.svg';
import key73 from '../../../../assets/icons/imageViewer/73.svg';
import key74 from '../../../../assets/icons/imageViewer/74.svg';
import key75 from '../../../../assets/icons/imageViewer/75.svg';
import key76 from '../../../../assets/icons/imageViewer/76.svg';
import key77 from '../../../../assets/icons/imageViewer/77.svg';
import key78 from '../../../../assets/icons/imageViewer/78.svg';
import key79 from '../../../../assets/icons/imageViewer/79.svg';
import key80 from '../../../../assets/icons/imageViewer/80.svg';
import key81 from '../../../../assets/icons/imageViewer/81.svg';
import key82 from '../../../../assets/icons/imageViewer/82.svg';
import key83 from '../../../../assets/icons/imageViewer/83.svg';
import key84 from '../../../../assets/icons/imageViewer/84.svg';
import key85 from '../../../../assets/icons/imageViewer/85.svg';
import key86 from '../../../../assets/icons/imageViewer/86.svg';
import key87 from '../../../../assets/icons/imageViewer/87.svg';
import key88 from '../../../../assets/icons/imageViewer/88.svg';
import key89 from '../../../../assets/icons/imageViewer/89.svg';
import key90 from '../../../../assets/icons/imageViewer/90.svg';
import key96 from '../../../../assets/icons/imageViewer/96.svg';
import key97 from '../../../../assets/icons/imageViewer/97.svg';
import key98 from '../../../../assets/icons/imageViewer/98.svg';
import key99 from '../../../../assets/icons/imageViewer/99.svg';
import key100 from '../../../../assets/icons/imageViewer/100.svg';
import key101 from '../../../../assets/icons/imageViewer/101.svg';
import key102 from '../../../../assets/icons/imageViewer/102.svg';
import key103 from '../../../../assets/icons/imageViewer/103.svg';
import key104 from '../../../../assets/icons/imageViewer/104.svg';
import key105 from '../../../../assets/icons/imageViewer/105.svg';
import key107 from '../../../../assets/icons/imageViewer/107.svg';
import key109 from '../../../../assets/icons/imageViewer/109.svg';
import key111 from '../../../../assets/icons/imageViewer/111.svg';
import key112 from '../../../../assets/icons/imageViewer/112.svg';
import key113 from '../../../../assets/icons/imageViewer/113.svg';
import key114 from '../../../../assets/icons/imageViewer/114.svg';
import key115 from '../../../../assets/icons/imageViewer/115.svg';
import key116 from '../../../../assets/icons/imageViewer/116.svg';
import key117 from '../../../../assets/icons/imageViewer/117.svg';
import key118 from '../../../../assets/icons/imageViewer/118.svg';
import key119 from '../../../../assets/icons/imageViewer/119.svg';
import key120 from '../../../../assets/icons/imageViewer/120.svg';
import key121 from '../../../../assets/icons/imageViewer/121.svg';
import key122 from '../../../../assets/icons/imageViewer/122.svg';
import key123 from '../../../../assets/icons/imageViewer/123.svg';
import key186 from '../../../../assets/icons/imageViewer/186.svg';
import key188 from '../../../../assets/icons/imageViewer/188.svg';
import key190 from '../../../../assets/icons/imageViewer/190.svg';
import key192 from '../../../../assets/icons/imageViewer/192.svg';
import key219 from '../../../../assets/icons/imageViewer/219.svg';
import key221 from '../../../../assets/icons/imageViewer/221.svg';
import key226 from '../../../../assets/icons/imageViewer/226.svg';

const hotkeyIcons = {
	[hotkeyCodes.Tab]: {
		icon: Tab,
		text: 'Tab',
		sx: {
			maxHeight: '16px',
		},
	},
	[hotkeyCodes.ShiftLeft]: {
		icon: Shift,
		text: 'Shift Left',
		sx: {
			maxHeight: '16px',
		},
	},
	[hotkeyCodes.ShiftRight]: {
		icon: Shift,
		text: 'Shift Right',
		sx: {
			maxHeight: '16px',
		},
	},
	[hotkeyCodes.ControlLeft]: {
		icon: Control,
		text: 'Ctrl Left',
		sx: {
			maxHeight: '16px',
		},
	},
	[hotkeyCodes.ControlRight]: {
		icon: Control,
		text: 'Ctrl Right',
		sx: {
			maxHeight: '16px',
		},
	},
	[hotkeyCodes.AltLeft]: {
		icon: Alt,
		text: 'Alt Left',
		sx: {
			maxHeight: '16px',
		},
	},
	[hotkeyCodes.AltRight]: {
		icon: Alt,
		text: 'Alt Right',
		sx: {
			maxHeight: '16px',
		},
	},
	[hotkeyCodes.Space]: {
		icon: Space,
		text: 'Space',
	},
	[hotkeyCodes.ArrowLeft]: {
		icon: ArrowLeft,
		text: 'Arrow Left',
	},
	[hotkeyCodes.ArrowUp]: {
		icon: key38,
		text: 'Arrow Up',
	},
	[hotkeyCodes.ArrowRight]: {
		icon: key39,
		text: 'Arrow Right',
	},
	[hotkeyCodes.ArrowDown]: {
		icon: key40,
		text: 'Arrow Down',
	},
	[hotkeyCodes.KeyA]: {
		icon: key65,
		text: 'A',
	},
	[hotkeyCodes.KeyB]: {
		icon: key66,
		text: 'B',
	},
	[hotkeyCodes.KeyC]: {
		icon: key67,
		text: 'C',
	},
	[hotkeyCodes.KeyD]: {
		icon: key68,
		text: 'D',
	},
	[hotkeyCodes.KeyE]: {
		icon: key69,
		text: 'E',
	},
	[hotkeyCodes.KeyF]: {
		icon: key70,
		text: 'F',
	},
	[hotkeyCodes.KeyG]: {
		icon: key71,
		text: 'G',
	},
	[hotkeyCodes.KeyH]: {
		icon: key72,
		text: 'H',
	},
	[hotkeyCodes.KeyI]: {
		icon: key73,
		text: 'I',
	},
	[hotkeyCodes.KeyJ]: {
		icon: key74,
		text: 'J',
	},
	[hotkeyCodes.KeyK]: {
		icon: key75,
		text: 'K',
	},
	[hotkeyCodes.KeyL]: {
		icon: key76,
		text: 'L',
	},
	[hotkeyCodes.KeyM]: {
		icon: key77,
		text: 'M',
	},
	[hotkeyCodes.KeyN]: {
		icon: key78,
		text: 'N',
	},
	[hotkeyCodes.KeyO]: {
		icon: key79,
		text: 'O',
	},
	[hotkeyCodes.KeyP]: {
		icon: key80,
		text: 'P',
	},
	[hotkeyCodes.KeyQ]: {
		icon: key81,
		text: 'Q',
	},
	[hotkeyCodes.KeyR]: {
		icon: key82,
		text: 'R',
	},
	[hotkeyCodes.KeyS]: {
		icon: key83,
		text: 'S',
	},
	[hotkeyCodes.KeyT]: {
		icon: key84,
		text: 'T',
	},
	[hotkeyCodes.KeyU]: {
		icon: key85,
		text: 'U',
	},
	[hotkeyCodes.KeyV]: {
		icon: key86,
		text: 'V',
	},
	[hotkeyCodes.KeyW]: {
		icon: key87,
		text: 'W',
	},
	[hotkeyCodes.KeyX]: {
		icon: key88,
		text: 'X',
	},
	[hotkeyCodes.KeyY]: {
		icon: key89,
		text: 'Y',
	},
	[hotkeyCodes.KeyZ]: {
		icon: key90,
		text: 'Z',
	},
	[hotkeyCodes.Digit0]: {
		icon: key96,
		text: '0',
	},
	[hotkeyCodes.Numpad0]: {
		icon: key96,
		text: 'Numpad 0',
	},
	[hotkeyCodes.Digit1]: {
		icon: key97,
		text: '1',
	},
	[hotkeyCodes.Numpad1]: {
		icon: key97,
		text: 'Numpad 1',
	},
	[hotkeyCodes.Digit2]: {
		icon: key98,
		text: '2',
	},
	[hotkeyCodes.Numpad2]: {
		icon: key98,
		text: 'Numpad 2',
	},
	[hotkeyCodes.Digit3]: {
		icon: key99,
		text: '3',
	},
	[hotkeyCodes.Numpad3]: {
		icon: key99,
		text: 'Numpad 3',
	},
	[hotkeyCodes.Digit4]: {
		icon: key100,
		text: '4',
	},
	[hotkeyCodes.Numpad4]: {
		icon: key100,
		text: 'Numpad 4',
	},
	[hotkeyCodes.Digit5]: {
		icon: key101,
		text: '5',
	},
	[hotkeyCodes.Numpad5]: {
		icon: key101,
		text: 'Numpad 5',
	},
	[hotkeyCodes.Digit6]: {
		icon: key102,
		text: '6',
	},
	[hotkeyCodes.Numpad6]: {
		icon: key102,
		text: 'Numpad 6',
	},
	[hotkeyCodes.Digit7]: {
		icon: key103,
		text: '7',
	},
	[hotkeyCodes.Numpad7]: {
		icon: key103,
		text: 'Numpad 7',
	},
	[hotkeyCodes.Digit8]: {
		icon: key104,
		text: '8',
	},
	[hotkeyCodes.Numpad8]: {
		icon: key104,
		text: 'Numpad 8',
	},
	[hotkeyCodes.Digit9]: {
		icon: key105,
		text: '9',
	},
	[hotkeyCodes.Numpad9]: {
		icon: key105,
		text: 'Numpad 9',
	},
	[hotkeyCodes.NumpadAdd]: {
		icon: key107,
		text: '+',
	},
	[hotkeyCodes.NumpadSubtract]: {
		icon: key109,
		text: '-',
	},
	[hotkeyCodes.NumpadDivide]: {
		icon: key111,
		text: 'Numpad /',
	},
	[hotkeyCodes.Slash]: {
		icon: key111,
		text: '/',
	},
	[hotkeyCodes.Semicolon]: {
		icon: key186,
		text: ';',
	},
	[hotkeyCodes.Comma]: {
		icon: key188,
		text: ',',
	},
	[hotkeyCodes.Period]: {
		icon: key190,
		text: '.',
	},
	[hotkeyCodes.NumpadDecimal]: {
		icon: key190,
		text: 'Numpad .',
	},
	[hotkeyCodes.Backquote]: {
		icon: key192,
		text: '`',
	},
	[hotkeyCodes.BracketLeft]: {
		icon: key219,
		text: '[',
	},
	[hotkeyCodes.BracketRight]: {
		code: 221,
		icon: key221,
		text: ']',
	},
	[hotkeyCodes.IntlBackslash]: {
		icon: key226,
		text: '\\',
	},
	[hotkeyCodes.Backslash]: {
		icon: key226,
		text: '\\',
	},
	[hotkeyCodes.F1]: {
		icon: key112,
		text: 'F1',
	},
	[hotkeyCodes.F2]: {
		icon: key113,
		text: 'F2',
	},
	[hotkeyCodes.F3]: {
		icon: key114,
		text: 'F3',
	},
	[hotkeyCodes.F4]: {
		icon: key115,
		text: 'F4',
	},
	[hotkeyCodes.F5]: {
		icon: key116,
		text: 'F5',
	},
	[hotkeyCodes.F6]: {
		icon: key117,
		text: 'F6',
	},
	[hotkeyCodes.F7]: {
		icon: key118,
		text: 'F7',
	},
	[hotkeyCodes.F8]: {
		icon: key119,
		text: 'F8',
	},
	[hotkeyCodes.F9]: {
		icon: key120,
		text: 'F9',
	},
	[hotkeyCodes.F10]: {
		icon: key121,
		text: 'F10',
	},
	[hotkeyCodes.F11]: {
		icon: key122,
		text: 'F11',
	},
	[hotkeyCodes.F12]: {
		icon: key123,
		text: 'F12',
	},
};

export default hotkeyIcons;
