import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const DrawerTabPanel = props => {
	const { children, value, index, tabId, tabPanelId, tabPanelSx, ...other } = props;

	return (
		<Box
			aria-labelledby={`${tabId}-${index}`}
			hidden={value !== index}
			id={`${tabPanelId}-${index}`}
			role="drawertabpanel"
			sx={{ pt: 3, ...tabPanelSx }}
			{...other}
		>
			{value === index && children}
		</Box>
	);
};

DrawerTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

export default DrawerTabPanel;
