import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { StyledTableCell, StyledTableRow } from '../Accounts/AccountsTable';
import Avatar from '@worklist-2/ui/src/assets/img/unsplash_6W4F62sN_yI.png';
import { useCrmContext } from '../../../context/CRMContext';
import { DivImage } from '../../../views/CrmView/styles';
import FilterableColumn from '../Cases/FilterableColumn';
import Loader from '../Loader';

const RoleAccountsTable = ({ users, teams, roles, filterAccount, field, setField, updateUser, countLoading }) => {
	const { toggleWidget } = useCrmContext();
	const [order, setOrder] = useState('asc');
	const [searchText, setSearchText] = useState('');
	const [isLoading, toggleLoading] = useState(false);

	useEffect(() => {
		setSearchText('');
	}, [field]);

	useEffect(() => {
		const filter = async () => {
			await onFilterAccount(searchText);
		};

		filter();
	}, [searchText]);

	useEffect(() => {
		toggleLoading(!!countLoading);
	}, [countLoading, searchText]);

	const handleInputText = e => {
		e.preventDefault();
		setSearchText(e.target.value);
	};

	const onFilterAccount = async function (v) {
		try {
			await filterAccount(v);
		} catch (error) {
			console.error(error);
		}
	};

	const handleRequestSort = (event, property) => {
		const isAsc = field === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		dataSort(order);
	};

	const dataSort = orderParam => {
		const isAsc = orderParam === 'asc';
		if (field !== 'IsActive') {
			users.sort((a, b) => (isAsc ? b[field]?.localeCompare(a[field]) : a[field]?.localeCompare(b[field])));
		} else {
			users.sort((a, b) => (isAsc ? a[field] - b[field] : b[field] - a[field]));
		}
	};

	return (
		<TableContainer
			className="invisible-scrollbar"
			style={{
				border: 0,
				borderRadius: 0,
				borderTopRightRadius: '20px',
				borderTopLeftRadius: '20px',
				boxShadow: 'none',
				minHeight: '400px',
			}}
		>
			<Table stickyHeader aria-label="customized table">
				<TableHead>
					<TableRow sx={{ height: '66px' }}>
						<FilterableColumn
							field={field}
							fieldName="User Name"
							fieldValue="UserName"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="text"
							value={searchText}
						/>
						<FilterableColumn
							field={field}
							fieldName="Email Address"
							fieldValue="LoginName"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="text"
							value={searchText}
						/>
						<FilterableColumn
							field={field}
							fieldName="Status"
							fieldValue="IsActive"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							order={order}
							setField={setField}
							type="dropdown"
							value={searchText}
						/>
						<FilterableColumn
							field={field}
							fieldName="Assigned Role"
							fieldValue="RoleName"
							handleInputText={handleInputText}
							handleRequestSort={handleRequestSort}
							options={roles?.map((r, index) => ({ index, name: r.RoleName, value: r.RoleId || r.id }))}
							order={order}
							setField={setField}
							type="dropdown"
							value={searchText}
						/>
					</TableRow>
				</TableHead>
				{!isLoading && (
					<TableBody style={{ flex: '1' }}>
						{users?.map((user, i) => (
							<StyledTableRow
								key={i}
								onClick={() =>
									toggleWidget('userPreview', { user: { ...user, Avatar }, roles, teams, updateUser })
								}
							>
								<StyledTableCell
									align="left"
									sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
								>
									<DivImage
										style={{
											background: `url(${Avatar})`,
											marginRight: '10px',
										}}
									/>
									<span>{user?.UserName}</span>
								</StyledTableCell>
								<StyledTableCell align="left">{user?.LoginName}</StyledTableCell>
								<StyledTableCell
									align="center"
									sx={{
										span: {
											fontFamily: 'Inter',
											fontSize: '14px',
											fontWeight: 500,
											fontStretch: 'normal',
											fontStyle: 'italic',
											lineHeight: 'normal',
											letterSpacing: 'normal',
											textAlign: 'center',
											color: '#4d79ea',
										},
									}}
								>
									<span>{user?.IsActive ? 'Active' : 'Inactive'}</span>
								</StyledTableCell>
								<StyledTableCell
									align="center"
									sx={{
										label: {
											padding: '7px 9px 8px',
											borderRadius: '5px',
											backgroundColor: '#f3f3f3',
											fontFamily: 'Inter',
											fontStyle: 'normal',
											fontWeight: 500,
											fontSize: '14px',
											lineHeight: '17px',
											textAlign: 'center',
											color: 'rgba(0, 0, 0, 0.6)',
											margin: 'auto 5px',
										},
									}}
								>
									{user.RoleName && <label>{user.RoleName}</label>}
								</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				)}
			</Table>

			{isLoading && <Loader style={{ height: '100%' }} />}
		</TableContainer>
	);
};

export default RoleAccountsTable;
