// Core

import React from 'react';

// MUI

import { Stack } from '@mui/material';

// Component

const FormToolBody = ({ sx, ...props }) => (
	<Stack
		sx={{
			'--element-content-border-radius': 'var(--border-radius--base, var(--border-radius-base))',
			'--element-content-field-border-color': '#c4c4c4',
			'--element-content-label-font-size': '16px',
			'--element-content-label-foreground-color': '#000000',
			'--element-spacing': '20px',

			alignItems: 'stretch',
			alignSelf: 'center',
			gap: props.isCompact ? '5px' : 'var(--element-spacing)',
			maxWidth: '700px',
			padding: '24px 0',

			...sx,
		}}
		{...props}
	/>
);

export default FormToolBody;
