import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Popper from '@mui/material/Popper';
import { searchScopes, useFhirDataLoader } from '@worklist-2/core/src';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { loadValueSet } from '@worklist-2/core/src/fhir/resource/columnMapping/utils';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Svg } from '@rs-ui/components/Svg';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SKIP_TRANSLATION_LIST } from '@rs-ui/helpers/constants.js';

const DropdownPaper = props => <Paper {...props} sx={{ minWidth: 200 }} />;

const SearchableCheckboxMultiSelectColumnFilter = ({
	valueSetType,
	onSelectFilter, // for column filters
	onSelectForm, // for form fields
	capitalizeOptions = false,
	fieldRef,
	preSelectedValues,
	displayValue = 'display',
	labelAlwaysShrunk = false,
	label = 'Search',
	placeholder = 'Search',
	testId,
	style,
	width,
	fullWidth = false,
	hideIcon = true,
	hideTags = true,
	options,
	customFilterOptions,
	popperProps,
	onFocus,
	onBlur,
	columnWidth,
	valueSetExtraParam,
}) => {
	const { t } = useTranslation('codePool');
	const notTranslate = SKIP_TRANSLATION_LIST.includes(valueSetType) || !valueSetType;

	const [optionsList, setOptionsList] = useState([]);
	const [preSelectedOptions, setPreSelectedOptions] = useState([]);

	const newSelectedOptionsRef = useRef([]);
	const newSelectedValuesRef = useRef([]);

	const fhirDataLoader = useFhirDataLoader({
		scope: searchScopes.valueSet,
	});
	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;

	// For Grid columns, get valueSet from API when component loads
	useEffect(() => {
		if (!_.isEmpty(customFilterOptions)) {
			setOptionsList(customFilterOptions);
		} else if (!_.isEmpty(options)) {
			setOptionsList(options);
		} else if (valueSetType) {
			const getValueSet = async () =>
				loadValueSet(
					fhirDataLoader,
					valueSetType,
					displayValue,
					capitalizeOptions,
					['display'],
					valueSetExtraParam
				);
			getValueSet()
				.then(output => {
					setOptionsList(output);
					if (preSelectedValues) {
						parsePreSelectOptions(output);
					}
				})
				.catch(console.error);
		}
	}, [options, customFilterOptions]);

	const handleSelectFilter = newSelectedOptions => {
		onSelectFilter(
			{
				selectedOptions: newSelectedOptions ? newSelectedOptionsRef.current : preSelectedOptions,
				fullOptionsList: optionsList,
			},
			newSelectedOptions ? newSelectedOptionsRef.current : preSelectedOptions
		);
	};

	const findOption = element => {
		// If the option has an "id" property, then we should select the ID instead of the text content.
		const searchFor = element?.id || (element[displayValue] ?? element);
		return optionsList.find(option => option.id === searchFor || option.display === searchFor)?.id ?? searchFor;
	};

	const parsePreSelectOptions = allOptions => {
		if (preSelectedValues) {
			setPreSelectedOptions(preSelectedValues.length > 0 ? _.split(preSelectedValues, '|') : []);
		}
	};

	const getTranslatedValue = item => {
		if (!item) {
			return item;
		}
		if (notTranslate) {
			return item[displayValue] || item;
		}
		return item[displayValue] ? t(`${valueSetType}.${item[displayValue]}`) : t(`${valueSetType}.${item}`);
	};

	useEffect(() => {
		parsePreSelectOptions(optionsList);
	}, [preSelectedValues]);

	const handleOnChange = (event, selectedValues) => {
		if (onFocus) {
			onFocus();
		}
		if (onSelectFilter) {
			const selectedOptionText =
				event.target.textContent || event.target?.parentElement?.nextSibling?.textContent;

			// If it's a newly selected option, the item will be an object, else it's a string
			const isSelected = selectedValues?.some(
				item => item && typeof item !== 'object' && getTranslatedValue(item) === selectedOptionText
			);

			if (!_.isEmpty(selectedValues)) {
				newSelectedOptionsRef.current = isSelected
					? selectedValues
							?.filter(item => getTranslatedValue(item) !== selectedOptionText)
							.map(elem => findOption(elem))
					: _.map(selectedValues, elem => findOption(elem));
			} else {
				// clear filters
				newSelectedOptionsRef.current = [];
			}
			setPreSelectedOptions(newSelectedOptionsRef.current);
			handleSelectFilter(newSelectedOptionsRef.current);
		} else if (onSelectForm) {
			newSelectedValuesRef.current = selectedValues;
		}
		if (onBlur) {
			setTimeout(() => {
				onBlur();
			}, 10000);
		}
	};

	return (
		<Autocomplete
			disableCloseOnSelect
			multiple
			PaperComponent={DropdownPaper}
			PopperComponent={({ ...props }) => <Popper {...props} {...popperProps} />}
			fullWidth={fullWidth}
			getOptionLabel={option => getTranslatedValue(option)}
			noOptionsText={t('noOptionsText')}
			options={optionsList}
			renderInput={params => (
				<TextField
					{...params}
					InputLabelProps={labelAlwaysShrunk ? { shrink: true } : undefined}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<>
								{hideIcon ? null : <SearchIcon sx={{ pr: '2px' }} />}
								{params.InputProps.startAdornment}
							</>
						),
						placeholder,
					}}
					data-cy={testId}
					inputRef={fieldRef}
					label={label}
					onBlur={() => (onBlur ? onBlur() : '')}
					onFocus={() => (onFocus ? onFocus() : '')}
				/>
			)}
			renderOption={(props, option, { selected }) => (
				<li id={option.id} {...props}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							marginRight: '15px',
						}}
					>
						<Checkbox
							checked={
								selected ||
								_.findIndex(
									preSelectedOptions,
									elem => getTranslatedValue(elem) === getTranslatedValue(option[displayValue])
								) > -1
							}
							checkedIcon={checkedIcon}
							icon={icon}
						/>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							{option.icon && (
								<Svg
									data-testid={option.icon}
									name={option.icon}
									sx={{
										width: '15px',
										height: '15px',
										flexShrink: 0,
										marginRight: '12px',
									}}
								/>
							)}
							{notTranslate ? option[displayValue] : t(`${valueSetType}.${option[displayValue]}`)}
						</Box>
					</Box>
				</li>
			)}
			renderTags={(values, getTagProps) =>
				// fieldWidth will be a number or NaN regardless width is a percentage in string or number
				hideTags ? (
					<Typography
						sx={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							maxWidth: `calc(${columnWidth} - 145px)`, // The max width of the selected options needs to be the width of the column minus the input element (50px), the end addorment (52px) and the right-left paddings
						}}
					>
						{values?.map(value => t(`${valueSetType}.${value}`))?.join(' | ')}
					</Typography>
				) : (
					values.map((elem, ind) => <Chip {...getTagProps({ ind })} label={t(elem)} />)
				)
			}
			sx={{
				...(fullWidth ? '' : { width }),
				...style,
				'& .MuiAutocomplete-inputRoot': { display: 'flex', flexWrap: 'nowrap' },
			}}
			value={preSelectedOptions}
			onBlur={() => {
				if (onSelectFilter) {
					if (newSelectedOptionsRef.current.length == 0 && preSelectedOptions.length !== 0) {
						return;
					}
					handleSelectFilter();
				}

				if (onSelectForm) {
					if (newSelectedValuesRef.current.length === 0 && preSelectedOptions.length !== 0) {
						/* empty */
					} else {
						onSelectForm(newSelectedValuesRef.current);
					}
				}
			}}
			onChange={handleOnChange}
		/>
	);
};

export default SearchableCheckboxMultiSelectColumnFilter;

SearchableCheckboxMultiSelectColumnFilter.propTypes = {
	/**
	 * Whether the input label should be explicitly in shrink mode
	 */
	labelAlwaysShrunk: PropTypes.bool,
	/**
	 * The type of valueSet that will be used to populate the list of options
	 */
	valueSetType: PropTypes.string,
	/**
	 * Handler for when a menu item is selected, as part of a column filter
	 */
	onSelectFilter: PropTypes.func,
	/**
	 * Handler for when a menu item is selected, as part of a form field
	 */
	onSelectForm: PropTypes.func,
	/**
	 * The values that should already be selected when the component loads
	 */
	preSelectedValues: PropTypes.string,
	/**
	 * The valueSet property that will be displayed
	 */
	displayValue: PropTypes.string,
	/**
	 * Whether or not the options should be capitalized
	 */
	capitalizeOptions: PropTypes.bool,

	/**
	 * Use this option if you want to pass custom filter options instead of a search set.
	 */
	customFilterOptions: PropTypes.array,
};
