/* Generated automagically by FhirStarter - DO NOT EDIT */
const patientSchema = {
	resourceType: 'Patient',
	identifier: ['__Identifier__'],
	active: '__boolean__',
	name: ['__HumanName__'],
	telecom: ['__ContactPoint__'],
	gender: '__code__',
	birthDate: '__date__',
	deceasedBoolean: '__boolean__',
	deceasedDateTime: '__dateTime__',
	address: ['__Address__'],
	maritalStatus: '__CodeableConcept__',
	multipleBirthBoolean: '__boolean__',
	multipleBirthInteger: '__integer__',
	photo: ['__Attachment__'],
	contact: [
		{
			relationship: ['__CodeableConcept__'],
			name: '__HumanName__',
			telecom: ['__ContactPoint__'],
			address: '__Address__',
			gender: '__code__',
			organization: '__Reference(Organization)__',
			period: '__Period__',
		},
	],
	communication: [
		{
			language: '__CodeableConcept__',
			preferred: '__boolean__',
		},
	],
	generalPractitioner: ['__Reference(Organization|Practitioner|PractitionerRole)__'],
	managingOrganization: '__Reference(Organization)__',
	link: [
		{
			other: '__Reference(Patient|RelatedPerson)__',
			type: '__code__',
		},
	],
};
export default patientSchema;
