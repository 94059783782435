const STUDY_FILTERS = {
	ALL: 'ALL',
	CURRENT_STUDY: 'CURRENT_STUDY',
	PRIOR_STUDY: 'PRIOR_STUDY',
};

const REPORT_RESOURCE_TYPE = {
	DIAGNOSTIC: 'DiagnosticReport',
	DOCUMENT: 'DocumentReference',
	FORM: 'BlumeForm',
};

const DIAGNOSTIC_REPORT_STATUS = {
	PRELIMINARY: 'preliminary',
	FINAL: 'final',
	AMENDED: 'amended',
	PARTIAL: 'partial',
	UNKNOWN: 'unknown',
};

const VIEWPORT_TYPES = {
	EMPTY: 'EMPTY',
	PDF: 'PDF',
	TIPTAP: 'TIPTAP',
	PREVIEW: 'PREVIEW',
	TEMPLATE_TIPTAP: 'TEMPLATE_TIPTAP',
	HISTORY_DIFFERENCE: 'HISTORY_DIFFERENCE',
	AMENDMENT: 'AMENDMENT',
	CONTENT_LOADER: 'CONTENT_LOADER',
	FORM: 'FORM',
	ORG_HEADER_FOOTER_EDITOR: 'ORG_HEADER_FOOTER_EDITOR',
	DOCUMENTS: 'DOCUMENTS',
};

const VALUESET_LIST = ['Modality', 'Bodypart', 'Laterality', 'Status', 'Sex', 'ProcedureCode'];

const PAPER_SIZES = {
	A3: {
		label: 'A3 (29.7cm X 42cm)',
		format: 'A3',
		landscape: false,
		width: 29.7,
		height: 42,
		margin: {
			top: 2.54,
			right: 2.54,
			bottom: 2.54,
			left: 2.54,
		},
	},
	A4: {
		label: 'A4 (21cm X 29.7cm)',
		format: 'A4',
		landscape: false,
		width: 21,
		height: 29.7,
		margin: {
			top: 2.54,
			right: 2.54,
			bottom: 2.54,
			left: 2.54,
		},
	},
	A5: {
		label: 'A5 (14.85cm X 21cm)',
		format: 'A5',
		landscape: false,
		width: 14.85,
		height: 21,
		margin: {
			top: 2.54,
			right: 2.54,
			bottom: 2.54,
			left: 2.54,
		},
	},
	Letter: {
		label: 'Letter (21.6cm X 27cm)',
		format: 'Letter',
		landscape: false,
		width: 21.6,
		height: 27,
		margin: {
			top: 2.54,
			right: 2.54,
			bottom: 2.54,
			left: 2.54,
		},
	},
	Tabloid: {
		label: 'Tabloid (27cm X 43.2cm)',
		format: 'Tabloid',
		landscape: false,
		width: 27,
		height: 43.2,
		margin: {
			top: 2.54,
			right: 2.54,
			bottom: 2.54,
			left: 2.54,
		},
	},
	Legal: {
		label: 'Legal (21.6cm X 35.6cm)',
		format: 'Legal',
		landscape: false,
		width: 21.6,
		height: 35.6,
		margin: {
			top: 2.54,
			right: 2.54,
			bottom: 2.54,
			left: 2.54,
		},
	},
};

// Allowed Template Formats
const TEMPLATE_MIME_TYPES = [
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
	'application/pdf',
	'application/rtf',
];

// Allowed Document/Reports Formats
const DOCUMENT_MIME_TYPES = [
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
	'application/pdf',
	'application/rtf',
];

const BLANK_REPORT_ID = 0;

const AUTOSAVE_DELAY = 2000;

const AUTSAVE_DELAY_WITH_ORG_LOAD = 5000;

const SIGNREPORT_DELAY = 2000;

const AI_DISCLAIMER_LINK = 'https://www.ramsoft.com/AI-disclaimer';

const PATIENT_DOCUMENT_FILTERS = {
	ALL: 'ALL',
	UPLOADED_DOCUMENT: 'UPLOADED_DOCUMENT',
	REGISTRATION_FORM: 'REGISTRATION_FORM',
};

export {
	STUDY_FILTERS,
	DIAGNOSTIC_REPORT_STATUS,
	VIEWPORT_TYPES,
	VALUESET_LIST,
	REPORT_RESOURCE_TYPE,
	PAPER_SIZES,
	TEMPLATE_MIME_TYPES,
	DOCUMENT_MIME_TYPES,
	BLANK_REPORT_ID,
	AUTOSAVE_DELAY,
	AUTSAVE_DELAY_WITH_ORG_LOAD,
	SIGNREPORT_DELAY,
	AI_DISCLAIMER_LINK,
	PATIENT_DOCUMENT_FILTERS,
};
