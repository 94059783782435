import _ from 'lodash';

export const getInfoPanelPatientName = (patientMapping, gridData) => {
	let patientName = '';
	_.find(patientMapping, patient => {
		if (patient.patientId === gridData?.patientId) {
			const fName = patient.firstName !== null ? patient.firstName : '';
			const lName = patient.lastName !== null ? patient.lastName : '';
			patientName = `${fName} ${lName}`;
		}
	});
	return patientName;
};

export default getInfoPanelPatientName;
