export default (isOpen, isHover, isCurrentStation) => {
	const firstLastChild = {
		'&:first-of-type': {
			marginTop: 0,
		},
		'&:last-child': {
			marginBottom: 0,
		},
	};

	return {
		header: {
			backgroundColor: '#FFF',
			padding: '5px 15px',
		},
		tabPanel: {
			margin: '0px 23px',
		},
		businessIcon20: {
			fontSize: 20,
		},
		businessIcon10: {
			fontSize: 10,
		},
		footer: {
			position: 'absolute',
			width: '490px',
			marginLeft: '23px',
		},
		top: {
			backgroundColor: 'rgba(77, 121, 234, 0.03)',
			padding: '8px 18px',
		},
		deviceTabBox: {
			'::-webkit-scrollbar-thumb': {
				backgroundColor: '#C4C4C4',
			},
			'::-webkit-scrollbar-track': {
				background: 'transparent',
			},
			height: 'calc(100vh - 200px)',
			overflowY: 'auto',
		},
		main: {
			display: 'flex',
			flexDirection: 'column',
			padding: '10px 20px',
		},
		row: {
			display: 'flex',
			justifyContent: 'space-between',
			gap: '10px',
			marginTop: '8px',
		},
		rowFirst: {
			display: 'flex',
		},
		separator: {
			marginTop: '20px',
			marginBottom: '12px',
			border: '1px solid rgba(0, 0, 0, 0.1)',
		},
		dicomLinkButtonsBox: {
			marginTop: '10px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
		},
		label: {
			fontFamily: 'Inter',
			fontStyle: 'normal',
			fontWeight: '500',
			fontSize: '12px',
			lineHeight: '15px',
			color: 'rgba(0, 0, 0, 0.6)',
			marginBottom: '5px',
		},
		dicomLinkButton: {
			height: '33px',
			marginRight: '10px',
			background: '#4D79EA',
			borderRadius: '5px',
			'&:hover': {
				background: '#4D79EA',
			},
		},
		dicomLinkButtonLabel: {
			marginLeft: '5px',
			fontSize: '14px',
			lineHeight: '17px',
			textAlign: 'center',
			letterSpacing: '1.5px',
			textTransform: 'uppercase',
			color: '#FFFFFF',
		},
		disabledButton: {
			opacity: '0.6',
		},
		devicesListBox: {
			'::-webkit-scrollbar-thumb': {
				backgroundColor: '#C4C4C4',
			},
			'::-webkit-scrollbar-track': {
				background: 'transparent',
			},
			height: 'calc(100vh - 250px)',
			margin: '10px 0',
			overflowY: 'auto',
		},
		deviceItemBox: {
			...firstLastChild,
			margin: '10px 15px',
			display: 'flex',
			backgroundColor: '#F9FAFF',
			borderRadius: '10px',
			border: isOpen ? '1px solid #4D79EA' : isHover ? '1px solid #4D79EA' : '',
			'&:hover': {
				border: '1px solid #4D79EA',
			},
		},
		deviceItemLeft: {
			flex: '0 1 90%',
			display: 'flex',
			padding: '20px 0px 20px 24px',
		},
		deviceItemRight: {
			flex: '0 1 10%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: isHover ? 'space-between' : 'flex-end',
			padding: '8px 15px 13px 0px',
		},
		deviceItemArrow: {
			width: '100%',
			display: 'flex',
			justifyContent: 'flex-end',
			cursor: 'pointer',
			'& svg': {
				padding: '5px',
			},
			'& svg:hover': {
				padding: '5px',
				backgroundColor: '#E5E5E5',
				borderRadius: '50%',
			},
		},
		accountTreeIcon: {
			fontSize: '16px',
			color: '#4D79EA',
		},
		expandMore: {
			fontSize: '18px',
			color: 'rgba(0, 0, 0, 0.6)',
			transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
			transition: 'all 0.3s ease',
		},
		deviceHubBox: {
			padding: '24px 28px',
			backgroundColor: 'rgba(77, 121, 234, 0.2)',
			borderRadius: '5px',
		},
		deviceHub: { fontSize: 20, color: '#4D79EA' },
		deviceHubBoxSmall: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '10px 11px',
			backgroundColor: 'rgba(77, 121, 234, 0.2)',
			borderRadius: '5px',
		},
		deviceItemTitleBox: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			marginLeft: '18px',
		},
		deviceItemTitle: {
			fontSize: '20px',
			fontWeight: '500',
			lineHeight: '24px',
			color: 'rgba(0, 0, 0, 0.9)',
			width: '100%',
			marginBottom: '6px',
		},
		deviceItemStatusBox: {
			display: 'flex',
			alignItems: 'center',
		},
		deviceItemStatusIndicator: {
			width: '6px',
			height: '6px',
			borderRadius: '50%',
			backgroundColor: '#3BE775',
		},
		deviceItemStatusLabel: {
			fontSize: '16px',
			fontWeight: '400',
			lineHeight: '20px',
			color: 'rgba(0, 0, 0, 0.4)',
			marginLeft: '8px',
		},
		deviceItemTree: {
			display: 'flex',
			alignItems: 'center',
			padding: '2px 4px',
			backgroundColor: 'rgba(77, 121, 234, 0.2)',
			borderRadius: '5px',
			cursor: 'pointer',
		},
		loadingBox: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: '100px',
		},
		loadingLabel: {
			fontSize: '16px',
			color: 'black',
		},
		loadingCircle: {
			color: '#03dac5',
			marginRight: '10px',
		},
		addStationButtonBox: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: '100%',
			marginBottom: '15px',
			cursor: 'pointer',
		},
		addStationButtonIconBox: {
			height: '14px',
			borderLeft: '0.5px dashed rgba(77, 121, 234, 0.6)',
		},
		addStationButtonIconButton: {
			padding: '4px',
			backgroundColor: '#4D79EA',
			outline: '2px solid rgba(77, 121, 234, 0.2)',
			'&:hover': {
				backgroundColor: 'rgba(77, 121, 234, 0.6)',
			},
		},
		addStationButtonIcon: {
			fontSize: '16px',
			color: '#FFFFFF',
		},
		addStationButtonLabelBox: {
			position: 'relative',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: '18px',
			padding: '8px 30px',
			backgroundColor: '#4D79EA',
			borderRadius: '5px',
			'&::before': {
				content: '""',
				position: 'absolute',
				top: '-6px',
				left: '50%',
				display: 'block',
				width: '14px',
				height: '14px',
				backgroundColor: '#4D79EA',
				transform: 'translate(-50%, 0) rotate(45deg)',
				borderRadius: '1px',
			},
		},
		addStationButtonLabel: {
			fontSize: '14px',
			color: '#FFFFFF',
		},
		statusBox: {
			display: 'flex',
			margin: '5px 0 15px 0',
		},
		statusLabelBox: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '28px',
			padding: '0 7px',
			marginRight: '10px',
			background: '#FFFFFF',
			border: '1px solid rgba(77, 121, 234, 0.2)',
			borderRadius: '5px',
		},
		statusLabel: {
			marginLeft: '5px',
			textTransform: 'none',
			fontWeight: '500',
			fontSize: '12px',
			lineHeight: '15px',
			letterSpacing: '0.02em',
			color: 'rgba(0, 0, 0, 0.6)',
		},
		testConnectionButton: {
			height: '30px',
			background: '#4D79EA',
			border: '1px solid rgba(77, 121, 234, 0.2)',
			borderRadius: '5px',
			'&:hover': {
				background: '#4D79EA',
			},
		},
		testConnection: {
			fontWeight: '500',
			fontSize: '12px',
			lineHeight: '15px',
			letterSpacing: '0.02em',
			color: '#FFFFFF',
		},
		testConnectionSucceed: {
			fontWeight: '500',
			fontSize: '12px',
			lineHeight: '30px',
			letterSpacing: '0.02em',
			marginLeft: '10px',
		},
		additionalSwitches: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			margin: '10px 0',
		},
		additionalSwitchesRow: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
		},
		stationListBox: {
			display: 'flex',
			margin: '10px 15px',
		},
		stationList: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
		},
		stationListSeparator: {
			width: '4px',
			marginRight: '10px',
			background: '#EEF1FF',
			borderRadius: '10px',
		},
		stationItemBox: {
			...firstLastChild,
			display: 'flex',
			marginBottom: '10px',
			background: '#F9FAFF',
			borderRadius: '10px',
			border: isCurrentStation ? '1px solid #4D79EA' : '',
			'&:hover': {
				border: '1px solid #4D79EA',
			},
		},
		stationItemBoxLeft: {
			flex: '0 1 90%',
			display: 'flex',
			padding: '20px 0px 20px 24px',
		},
		stationItemBoxRight: {
			flex: '0 1 10%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			padding: '8px 15px 13px 0px',
		},
		stationItemTitleBox: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			marginLeft: '10px',
		},
		stationItemTitle: {
			fontSize: '16px',
			fontWeight: '500',
			lineHeight: '12px',
			color: 'rgba(0, 0, 0, 0.87)',
			width: '260px',
			margin: '3px 0',
		},
	};
};
