import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
	Autocomplete,
	Box,
	Button,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Slide,
	TextField,
	Typography,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import LastPageIcon from '@mui/icons-material/LastPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ThemeProvider } from '@mui/material/styles';
import { useConfig, crmSearchScopes, useCRMDataLoader } from '@worklist-2/core/src';

import { lightTheme, darkTheme } from '@worklist-2/ui/src/themes';

const AddNewReview = ({ isOpen, onClose, onCloseSidebar, isOmega, documentId, setReviews }) => (
	<Slide mountOnEnter unmountOnExit direction="left" in={isOpen}>
		<Box
			sx={{
				position: 'absolute',
				top: 0,
				right: 0,
				width: '390px',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: isOmega ? '#272727' : '#FFF',
				boxShadow: '-4px 0px 20px 0px rgba(0, 0, 0, 0.20)',
			}}
		>
			<AddNewReviewContent
				documentId={documentId}
				isOmega={isOmega}
				setReviews={setReviews}
				onClose={onClose}
				onCloseSidebar={onCloseSidebar}
			/>
		</Box>
	</Slide>
);

const AddNewReviewContent = ({ onClose, onCloseSidebar, isOmega, documentId, setReviews }) => {
	const __config = useConfig();
	const [selectedUser, setSelectedUser] = useState(null);
	const [date, setDate] = useState(new Date());
	const [priority, setPriority] = useState('default');
	const [users, setUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const disabledSend = !selectedUser || priority === 'default' || isLoading;
	const userDataLoader = useCRMDataLoader({ scope: crmSearchScopes.userApi });

	useEffect(() => {
		const getUsers = async () => {
			const allUsers = await userDataLoader.load({ count: 5000 });
			setUsers(allUsers.map(user => ({ ...user, label: user.UserName })));
		};

		getUsers();
	}, []);

	const handleReviewAdd = async () => {
		try {
			setIsLoading(true);
			const payload = {
				DocumentId: documentId,
				ReviewerId: selectedUser?.UserId,
				ReviewerName: selectedUser?.UserName,
				ReviewerRole: selectedUser?.RoleName,
				IsActive: true,
				Priority: priority,
				Status: 'open',
				Deadline: date,
			};
			await axios
				.post(`${__config.data_sources.breeze}/DocumentReview`, JSON.stringify(payload), {
					headers: {
						Accept: '*/*',
						'Content-Type': 'application/json',
					},
				})
				.then(res => {
					if (res.status === 201) {
						setReviews(prev => [...prev, res.data]);
					}
				});
		} catch (e) {
			console.error(e);
		} finally {
			setIsLoading(false);
			onClose();
		}
	};

	return (
		<>
			<Box
				sx={{
					position: 'relative',
					padding: '26px 70px',
					display: 'flex',
					alignItems: 'center',
					backgroundColor: isOmega ? '#272727' : '#F9FAFC',
					borderBottom: isOmega ? '1px solid rgba(196, 196, 196, 0.40)' : 'none',
				}}
			>
				<IconButton sx={{ position: 'absolute', top: '6px', left: '2px' }} onClick={onClose}>
					<ArrowBackIcon
						data-testid="closeAddSectionButton"
						sx={{ color: isOmega ? 'rgba(255, 255, 255, 0.60)' : 'rgba(0, 0, 0, 0.60)' }}
					/>
				</IconButton>
				<Typography
					sx={{
						fontFamily: 'Inter',
						fontSize: '24px',
						fontWeight: 500,
						lineHeight: '24px',
						color: isOmega ? '#FFF' : 'rgba(0, 0, 0, 0.90)',
					}}
				>
					Add New
				</Typography>
				<IconButton sx={{ position: 'absolute', top: '6px', right: '2px' }} onClick={onCloseSidebar}>
					<LastPageIcon
						data-testid="closeSidebarButton"
						sx={{ color: isOmega ? 'rgba(255, 255, 255, 0.60)' : 'rgba(0, 0, 0, 0.60)' }}
					/>
				</IconButton>
			</Box>
			<Box
				sx={{
					width: '100%',
					height: '100%',
					padding: '22px 10px 10px',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<UserNameFiled isOmega={isOmega} options={users} setValue={setSelectedUser} value={selectedUser} />
				<DateField isOmega={isOmega} setValue={setDate} value={date} />
				<PriorityFiled isOmega={isOmega} setValue={setPriority} value={priority} />
				<Button
					data-testid="addReviewButton"
					disabled={disabledSend}
					style={disabledSend ? null : { border: '1px solid #4D79EA' }}
					sx={{
						marginTop: 'auto',
						padding: '4px 0px',
						alignSelf: 'flex-end',
						border: '1px solid #4D79EA',
						color: '#4D79EA',
						'&:disabled': {
							border: isOmega ? '1px solid rgba(255, 255, 255, 0.26)' : '1px solid rgba(0, 0, 0, 0.26)',
							color: isOmega ? 'rgba(255, 255, 255, 0.26)' : 'rgba(0, 0, 0, 0.26)',
						},
					}}
					onClick={handleReviewAdd}
				>
					add
				</Button>
			</Box>
		</>
	);
};

const UserNameFiled = ({ value, setValue, options, isOmega }) => {
	const textColor = isOmega ? '#FFF' : 'rgba(0, 0, 0, 0.90)';
	const bgColor = isOmega ? 'rgba(196, 196, 196, 0.15)' : 'rgba(196, 196, 196, 0.03)';
	const iconColor = isOmega ? '#4D79EA' : 'rgba(0, 0, 0, 0.60)';
	const labelColor = isOmega ? '#FFF' : 'rgba(0, 0, 0, 0.60)';
	const labelBgColor = isOmega ? '#272727' : '#FFF';
	const buttonColor = isOmega ? '#FFF' : '#000';
	const listItemsBgColor = isOmega ? 'rgb(63,63,63)' : '#FFF';

	return (
		<Box sx={{ marginBottom: '20px' }}>
			<Autocomplete
				disablePortal
				freeSolo
				ListboxProps={{
					sx: {
						opacity: 1,
						color: textColor,
						backgroundColor: listItemsBgColor,
					},
				}}
				options={options}
				renderInput={params => (
					<TextField
						{...params}
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<InputAdornment
									position="start"
									sx={{
										color: iconColor,
										'& .MuiSvgIcon-root': {
											opacity: 1,
										},
									}}
								>
									<AccountCircleIcon />
								</InputAdornment>
							),
						}}
						label="User Name"
					/>
				)}
				sx={{
					marginBottom: '2px',
					'& .MuiOutlinedInput-root': {
						color: textColor,
						backgroundColor: bgColor,
						'& button': {
							color: buttonColor,
						},
					},
					'& fieldset': {
						border: '1px solid rgba(0, 0, 0, 0.10)',
					},
					'& label': {
						padding: '0 4px',
						letterSpacing: '0.4px',
						color: labelColor,
						backgroundColor: labelBgColor,
						borderRadius: '10px',
					},
				}}
				value={value}
				onChange={(event, newValue) => {
					setValue(newValue);
				}}
			/>
			<Typography
				sx={{
					fontFamily: 'Inter',
					fontSize: '12px',
					color: labelColor,
				}}
			>
				Required
			</Typography>
		</Box>
	);
};

const DateField = ({ value, setValue, isOmega }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isAccept, setIsAccept] = useState(false);
	const textColor = isOmega ? '#FFF' : 'rgba(0, 0, 0, 0.90)';
	const bgColor = isOmega ? 'rgba(196, 196, 196, 0.15)' : 'rgba(196, 196, 196, 0.03)';
	const labelColor = isOmega ? '#FFF' : 'rgba(0, 0, 0, 0.60)';
	const labelBgColor = isOmega ? '#272727' : '#FFF';
	const iconColor = isOmega ? '#4D79EA' : 'rgba(0, 0, 0, 0.60)';
	const FieldSX = {
		width: '100%',
		'& .MuiOutlinedInput-root': {
			marginBottom: '22px',
			color: textColor,
			backgroundColor: bgColor,
		},
		'& input': {
			textTransform: 'uppercase',
		},
		'& fieldset': {
			border: '1px solid rgba(0, 0, 0, 0.10)',
		},
		'& label': {
			padding: '0 4px',
			letterSpacing: '0.4px',
			color: labelColor,
			backgroundColor: labelBgColor,
			borderRadius: '10px',
		},
	};

	const handleAccept = () => {
		setIsOpen(false);
		setIsAccept(true);
	};

	const handleFocus = () => {
		if (isAccept) {
			setIsOpen(false);
			setIsAccept(false);
		} else {
			setIsOpen(true);
		}
	};

	return (
		<ThemeProvider theme={isOmega ? darkTheme : lightTheme}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DateTimePicker
					disableOpenPicker
					PaperProps={{
						sx: {
							'& .MuiButtonBase-root': {
								bottom: '8px',
							},
						},
					}}
					PopperProps={{
						disableRestoreFocus: true,
					}}
					components={{
						LeftArrowButton: Boolean,
						RightArrowButton: Boolean,
					}}
					label="Deadline"
					open={isOpen}
					renderInput={params => (
						<TextField
							{...params}
							InputProps={{
								...params.InputProps,
								startAdornment: (
									<InputAdornment
										position="start"
										sx={{
											color: iconColor,
											'& .MuiSvgIcon-root': {
												opacity: 1,
											},
										}}
									>
										<CalendarMonthIcon />
									</InputAdornment>
								),
							}}
							sx={FieldSX}
							onFocus={handleFocus}
						/>
					)}
					value={value}
					onAccept={handleAccept}
					onChange={newValue => setValue(newValue)}
				/>
			</LocalizationProvider>
		</ThemeProvider>
	);
};

const PriorityFiled = ({ value, setValue, isOmega }) => {
	const ITEMS = ['High', 'Medium', 'Low'];
	const textColor = isOmega ? '#FFF' : 'rgba(0, 0, 0, 0.90)';
	const bgColor = isOmega ? 'rgba(196, 196, 196, 0.15)' : 'rgba(196, 196, 196, 0.03)';
	const labelColor = isOmega ? '#FFF' : 'rgba(0, 0, 0, 0.60)';
	const labelBgColor = isOmega ? '#272727' : '#FFF';
	const buttonColor = isOmega ? '#FFF' : 'rgba(0, 0, 0, 0.60)';
	const listItemsBgColor = isOmega ? 'rgb(63,63,63)' : '#FFF';

	return (
		<FormControl fullWidth>
			<InputLabel
				sx={{
					padding: '0 4px',
					letterSpacing: '0.4px',
					color: labelColor,
					backgroundColor: labelBgColor,
					borderRadius: '10px',
				}}
			>
				Priority
			</InputLabel>
			<Select
				MenuProps={{
					PaperProps: {
						sx: {
							marginLeft: '6px',
							backgroundColor: listItemsBgColor,
							'& ul': {
								padding: 0,
							},
							'& .MuiMenuItem-root': {
								color: textColor,
							},
						},
					},
				}}
				label="Priority"
				sx={{
					color: textColor,
					backgroundColor: bgColor,
					'& fieldset': {
						border: '1px solid rgba(0, 0, 0, 0.10)',
					},
					'.MuiSvgIcon-root ': {
						opacity: 1,
						fill: buttonColor,
					},
				}}
				value={value}
				onChange={event => {
					setValue(event.target.value);
				}}
			>
				<MenuItem disabled value="default">
					Set Priority
				</MenuItem>
				{ITEMS.map(priority => (
					<MenuItem key={priority} value={priority}>
						{priority}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default AddNewReview;
