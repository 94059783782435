export const categoriesList = [
	{
		label: 'Study',
		placeholder: 'Study',
		context: 'searchStudy',
		key: 'Study',
		resourceInGuiRole: 'Study',
		permissionLabel: 'SearchStudy',
	},
	{
		label: 'Patient',
		placeholder: 'Patient',
		context: 'searchPatientElk',
		key: 'Patient',
		resourceInGuiRole: 'Patient',
		permissionLabel: 'SearchPatient',
	},
	{
		label: 'User',
		placeholder: 'Practitioner',
		context: 'searchPractitionerElk',
		key: 'User',
		resourceInGuiRole: 'User',
		permissionLabel: 'SearchUser',
	},
	{
		label: 'Organization',
		placeholder: 'Organization',
		context: 'searchOrganizationElk',
		key: 'Organization',
		resourceInGuiRole: 'Organization',
		permissionLabel: 'SearchOrganization',
	},
	{
		label: 'Dicom',
		placeholder: 'Dicom',
		context: 'searchDicom',
		key: 'DICOM Query',
		resourceInGuiRole: 'DICOMQuery',
		permissionLabel: 'SearchDicomQuery',
	},
	{
		label: 'Help',
		placeholder: 'Articles',
		context: 'searchHelpElk',
		key: 'Help Center',
		resourceInGuiRole: 'Help',
		permissionLabel: 'SearchHelp',
	},
	{
		label: 'Marketplace',
		placeholder: 'Apps',
		context: 'searchMarketplace',
		key: 'Marketplace App',
		resourceInGuiRole: 'Marketplace',
		permissionLabel: 'SearchMarketplace',
	},
];
