import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, TextareaAutosize, styled } from '@mui/material';
import moment from 'moment';
import SummaryNoteIcon from '../../../assets/icons/PatientInfoCards/summarize-note-icon.svg';
import PersonIcon from '@mui/icons-material/Person';
import NotesCardAction from './NotesCardAction';
// import SpeechToTextAction from './SpeechToTextAction';
import NavigatorTooltip from '@worklist-2/worklist/src/DocumentViewerV3/components/Navigator/NavigatorTooltip';

const NoteTextarea = styled(TextareaAutosize)({
	width: '100%',
	fontFamily: 'Roboto',
	fontSize: '14px',
	fontWeight: 400,
	letterSpacing: '0.25px',
	color: 'rgba(255,255,255,0.87)',
	lineHeight: 1.5,
	backgroundColor: 'transparent',
	border: 'none',
	outline: 'none',
	caretColor: '#42A5F5',
	'&:focus-visible': {
		outline: 'none',
	},
});

const CreatedBy = ({ name }) => (
	<Box sx={STYLES.CREATED_BY}>
		<Box sx={STYLES.CB_AVATAR}>
			<PersonIcon
				sx={{
					color: '#979797',
					fontSize: '15px',
				}}
			/>
		</Box>
		<Typography sx={STYLES.CB_LABEL}>
			Created by&nbsp;
			<NavigatorTooltip placement="bottom" title={name}>
				<b>{name}</b>
			</NavigatorTooltip>
		</Typography>
	</Box>
);

const NotesCard = ({
	note,
	CreationDateTime: date,
	CreatorDisplayName: creatorName,
	CreatorId,
	ResourceID,
	isEditMode,
	editStatus,
	selectedId,
	onDelete,
	onEdit,
	setNoteChage,
	loggedInUser,
}) => {
	const textareaRef = useRef(null);
	const isEditAccess = +loggedInUser?.id === CreatorId; // Edit access only for the creator of the note
	const isEnableEditor = ResourceID === '' || (selectedId === ResourceID && isEditAccess && isEditMode); // access only for add and edit selected
	const isShowEditControls = isEditAccess && !isEditMode;
	const [noteContent, setNoteContent] = useState(note);

	const dataLabel = moment(date).format('YYYY-MM-DD / hh:mm A'); // expected format 2024-01-19 / 07:54 AM

	useEffect(() => {
		// reset to previous state if editing is canceled
		if (editStatus === 'CANCEL') {
			setNoteContent(note);
		}
	}, [editStatus]);

	useEffect(() => {
		if (textareaRef.current && isEnableEditor) {
			const textarea = textareaRef.current;
			textarea.focus();
			// Set the cursor to the end of the text
			textarea.setSelectionRange(textarea.value.length, textarea.value.length);
		}
	}, [isEnableEditor]);

	const handleNoteChage = e => {
		setNoteContent(e.target.value);
		setNoteChage(e.target.value);
	};

	return (
		<Box sx={STYLES.OUTER_CONTAINER}>
			<Box sx={STYLES.GRADIENT_BORDER(isEnableEditor)} />
			<Box sx={STYLES.CONTAINER}>
				<Typography sx={STYLES.TITLE}>
					<SummaryNoteIcon />
					Study Note
				</Typography>
				<Box sx={STYLES.NOTE_CONTENT}>
					{isEnableEditor ? (
						<NoteTextarea
							ref={textareaRef}
							minRows={3}
							value={noteContent}
							onChange={e => handleNoteChage(e)}
						/>
					) : (
						noteContent
					)}
				</Box>

				{!isEnableEditor ? (
					<>
						{/* Card Footer */}
						<Box data-testid="note-footer" sx={STYLES.NOTE_FOOTER(creatorName)}>
							{creatorName && <CreatedBy name={creatorName} />}
							<Typography sx={STYLES.TIME_STAMP}>{dataLabel}</Typography>
						</Box>
					</>
				) : null}
				{/* Card Control */}
				{isShowEditControls && (
					<NotesCardAction onDelete={onDelete} onEdit={() => onEdit({ ResourceID, note: noteContent })} />
				)}
			</Box>
		</Box>
	);
};

export default NotesCard;

const STYLES = {
	OUTER_CONTAINER: {
		position: 'relative',
		borderRadius: '12px',
		padding: '1px', // Create space for the gradient border
	},
	CONTAINER: {
		padding: '14px 12px',
		borderRadius: '12px',
		backgroundColor: '#414141',
		position: 'relative',
		zIndex: 1,

		'&:hover': {
			'& .actions': {
				visibility: 'visible',
			},
		},
	},
	GRADIENT_BORDER: isEditable => ({
		content: '""',
		position: 'absolute',
		top: '0',
		left: '0',
		right: '0',
		bottom: '0',
		zIndex: 0,
		background: isEditable
			? 'rgb(66, 165, 245)'
			: 'linear-gradient(102.58deg, rgba(66, 165, 245, 0.6) 0%, rgba(6, 173, 163, 0.3) 109.54%)',
		borderRadius: 'inherit', // Inherit the border-radius from the card
		padding: '1px', // Adjust the thickness of the border
		boxSizing: 'border-box',
	}),
	TITLE: {
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontWeight: 400,
		letterSpacing: '0.25px',
		color: 'rgba(255,255,255,0.87)',
		lineHeight: 1.428,
		display: 'flex',
		alignItems: 'center',
		'& svg': {
			marginRight: '6px',
		},
	},
	NOTE_CONTENT: {
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontWeight: 400,
		letterSpacing: '0.25px',
		color: 'rgba(255,255,255,0.87)',
		lineHeight: 1.428,
		display: 'flex',
		alignItems: 'center',
		marginTop: '20px',
	},
	NOTE_FOOTER: isNameAvailable => ({
		display: 'flex',
		justifyContent: isNameAvailable ? 'space-between' : 'end',
		marginTop: '20px',
		alignItems: 'center',
		gap: '8px',
		textWrap: 'nowrap',
	}),
	CREATED_BY: {
		display: 'inline-flex',
		overflow: 'hidden',
		alignItems: 'center',
	},
	CB_AVATAR: {
		width: '24px',
		minWidth: '24px',
		height: '24px',
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		backgroundColor: '#303030',
		marginRight: '6px',
	},
	CB_LABEL: {
		fontFamily: 'Roboto',
		fontSize: '11px',
		fontWeight: 400,
		letterSpacing: '0.25px',
		color: 'rgba(255,255,255,0.60)',
		width: 'calc(100% - 24px)',
		display: 'inline-flex',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'& b': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
	TIME_STAMP: {
		fontFamily: 'Roboto',
		fontSize: '12px',
		fontWeight: 400,
		letterSpacing: '0.25px',
		color: 'rgba(255,255,255,0.60)',
		lineHeight: 1.16,
	},
};
