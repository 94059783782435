// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IYK9mzhHRJbYG0HeANkO{display:flex;justify-content:center;align-items:center;width:100%;margin-top:50px}.IYK9mzhHRJbYG0HeANkO .rtpQ8bCwthzX4L70zwiY{display:flex;justify-content:center;align-items:center;width:275px;height:56px;padding:0 25px;border-radius:50px;background:#4d79ea}.IYK9mzhHRJbYG0HeANkO .rtpQ8bCwthzX4L70zwiY .quRfdF9hiuDsdqyPmzxf{flex:0 1 58%;display:flex;justify-content:center;align-items:center;margin-right:10px}.IYK9mzhHRJbYG0HeANkO .rtpQ8bCwthzX4L70zwiY .quRfdF9hiuDsdqyPmzxf span{color:#fff;font-family:Inter,serif;font-size:14px;font-style:normal;font-weight:600;line-height:normal;letter-spacing:1px;text-transform:uppercase}.IYK9mzhHRJbYG0HeANkO .rtpQ8bCwthzX4L70zwiY .CDDy6NtQVXCDBq5eBm3w{flex:0 1 42%;display:flex;align-items:center;margin-left:10px}.IYK9mzhHRJbYG0HeANkO .rtpQ8bCwthzX4L70zwiY .CDDy6NtQVXCDBq5eBm3w .N0PCfhp9HE9qVnACRVmQ{display:flex;justify-content:center;align-items:center;padding:10px 12px;background:rgba(0,0,0,0);cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/common/MainArea/SelectedPopup/SelectedPopup.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,eAAA,CAEA,4CACC,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,WAAA,CACA,cAAA,CAEA,kBAAA,CACA,kBAAA,CAEA,kEACC,YAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CAEA,uEACC,UAAA,CACA,uBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,kBAAA,CACA,wBAAA,CAIF,kEACC,YAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA,CAEA,wFACC,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CACA,wBAAA,CAEA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedPopupWrapper": "IYK9mzhHRJbYG0HeANkO",
	"selectedPopup": "rtpQ8bCwthzX4L70zwiY",
	"selectedText": "quRfdF9hiuDsdqyPmzxf",
	"selectedIcons": "CDDy6NtQVXCDBq5eBm3w",
	"icon": "N0PCfhp9HE9qVnACRVmQ"
};
export default ___CSS_LOADER_EXPORT___;
