export const tooltipStyle = {
	//default mui tooltip style
	position: 'absolute',
	backgroundColor: 'rgba(97, 97, 97, 0.92)',
	borderRadius: '4px',
	color: 'rgb(255, 255, 255)',
	padding: '4px 8px',
	fontSize: '0.6875rem',
	maxWidth: '300px',
	margin: '2px',
	overflowWrap: 'break-word',
	fontWeight: '500',
	transform: 'translateY(-40px)', // Adjust the vertical position
};

export function getInputStartAdornmentTooltip(tooltipText) {
	return {
		'& .MuiInputAdornment-positionStart:hover': {
			'&::before': {
				content: `"${tooltipText}"`,
				...tooltipStyle,
			},
		},
	};
}

export function getAutocompleteClearAndPopupTooltips({ clearText, openText }) {
	return {
		'& .MuiAutocomplete-clearIndicator:hover': {
			'&::before': {
				content: `"${clearText}"`,
				...tooltipStyle,
			},
		},
		'& .MuiAutocomplete-popupIndicator:hover': openText
			? {
					'&::before': {
						content: `"${openText}"`,
						...tooltipStyle,
					},
			  }
			: {},
	};
}
