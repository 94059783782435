import React from 'react';
import { Popover, ListItem, ListItemButton, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import getDefaultRenderingEngine from '../../cornerstone/getDefaultRenderingEngine';
import { ZOOM_TYPES } from '../../contexts/consts/consts';
import { renderingEngineId } from '../../contexts/ImageViewerCornerstoneContext';
import { ramsoft } from '@cornerstonejs/core';

export const ZOOM_PRESETS = [
	{
		label: 'Actual Pixels (100%)',
		value: ZOOM_TYPES.original,
	},
	{
		label: 'Fit to Viewport',
		value: ZOOM_TYPES.contain,
	},
	{
		label: 'Fill the viewport',
		value: ZOOM_TYPES.cover,
	},
];

export const ZoomPanObserver = ({ viewportId, position, isOpen, onClose }) => {
	const renderingEngine = getDefaultRenderingEngine();

	if (!renderingEngine) {
		return null;
	}

	const renderingEngineViewport = renderingEngine.getViewport(viewportId);

	if (!renderingEngineViewport) {
		return null;
	}

	const rule = renderingEngineViewport?.defaultOptions?.rules?.scaling?.fit;

	const onPresetChange = preset => {
		onClose();

		if (preset === ZOOM_TYPES.contain) {
			ramsoft.fitViewport?.({ renderingEngineId, viewportId });
		}

		if (preset === ZOOM_TYPES.cover) {
			ramsoft.fillViewport?.({ renderingEngineId, viewportId });
		}

		if (preset === ZOOM_TYPES.original) {
			ramsoft.pixels?.({ renderingEngineId, viewportId });
		}

		const viewport = renderingEngine.getViewport(viewportId);

		if (!viewport) {
			return;
		}

		const verticalAlignment = viewport?.defaultOptions?.rules?.verticalAlign;
		const horizontalAlignment = viewport?.defaultOptions?.rules?.horizontalAlign;

		if (!verticalAlignment && !horizontalAlignment) {
			viewport.setPan([0, 0]);

			return;
		}

		if (verticalAlignment) {
			if (verticalAlignment === 'top') {
				ramsoft.alignTop({ renderingEngineId, viewportId });
			} else {
				ramsoft.alignBottom({ renderingEngineId, viewportId });
			}
		}

		if (horizontalAlignment) {
			if (horizontalAlignment === 'left') {
				ramsoft.alignLeft({ renderingEngineId, viewportId });
			} else {
				ramsoft.alignRight({ renderingEngineId, viewportId });
			}
		}
	};

	return (
		<Popover
			PaperProps={{
				sx: {
					padding: 0,
					background: '#393939',
					border: '1px solid rgba(255, 255, 255, 0.1)',
					borderRadius: '6px',
					zIndex: 1000,
				},
			}}
			anchorPosition={{ top: position.y, left: position.x }}
			anchorReference="anchorPosition"
			open={isOpen}
			onClose={onClose}
			onMouseDown={e => e.stopPropagation()}
			onMouseUp={e => e.stopPropagation()}
		>
			{ZOOM_PRESETS.map(item => (
				<ListItem key={item.value} sx={{ p: 0 }}>
					<ListItemButton sx={{ gap: 2 }} onClick={() => onPresetChange(item.value)}>
						<ListItemText
							primary={item.label}
							sx={{
								color: '#fff',
							}}
						/>
						<CheckIcon
							sx={{
								color: '#42A5F5',
								opacity: item.value === rule ? 1 : 0,
							}}
						/>
					</ListItemButton>
				</ListItem>
			))}
		</Popover>
	);
};
