import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Box, ClickAwayListener } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import React, { Fragment, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import CustomTextInput from '@worklist-2/ui/src/components/Help/CustomTextInput';
import { useCrmContext } from '../../context/CRMContext';
import { featureCRMNavLinks } from './featureCMRNavLinks';
import CrmSideNav from './CrmSideNav';
import SearchPanel from './SearchPanel';
import NotificationView from './NotificationView';
import LiveChatButton from '../../components/CRM/LiveChat/LiveChatButton';
import LiveChat from '../../components/CRM/LiveChat/LiveChat';

import {
	DivImage,
	ItemsContainer,
	LogoutContainer,
	LogoutText,
	LowerStack,
	MenuItemName,
	SearchBar,
	SearchBarWrapper,
	SideNav,
	StyledButton,
	StyledContainer,
	UpperStack,
	UserName,
	WrapperStack,
} from './styles';
import './styles-override.css';
import { useAuth } from '@worklist-2/core/src';
import Avatar from '@mui/material/Avatar';
import _ from 'lodash';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const CaseView = () => {
	const [searchValue, setSearchValue] = useState('');
	const [searchCategories, setSearchCategories] = useState([]);
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
	const [searchOpen, setSearchOpen] = useState(false);
	const { resetAndLogOut, notificationReceived, loggedInUser } = useAuth();
	const [notifOpen, setNotifOpen] = useState(false);
	const metaBreezeAdminsetting = useBooleanFlagValue('meta-breeze-adminsetting');
	const metaBreezeChat = useBooleanFlagValue('meta-breeze-chat');

	const initialsUser = () =>
		loggedInUser?.display
			?.split(' ')
			.map(n => n.charAt(0).toUpperCase())
			.join('');

	const handleNavigate = path => {
		navigate(`/${path}`);
		setIsUserMenuOpen(false);
	};

	const onLogoutClick = () => {
		resetAndLogOut();
	};

	return (
		<>
			<CRMSideNav />
			<Box sx={{ display: 'flex' }}>
				<Box sx={{ flex: 3 }}>
					<SearchBarWrapper sx={{ position: 'relative' }}>
						{!searchOpen ? (
							<SearchBar>
								<SearchIcon
									sx={{
										m: '10px',
										color: '#4D79EA',
										marginLeft: '19px',
									}}
								/>
								<CustomTextInput
									inputProps={{ 'aria-label': 'Search' }}
									placeholder={searchValue === '' ? 'Enter Your Search' : searchValue}
									style={{ flex: 1, padding: '1px 2px' }}
									onClick={() => setSearchOpen(true)}
								/>
							</SearchBar>
						) : (
							<ClickAwayListener onClickAway={() => setSearchOpen(false)}>
								<div
									style={{
										position: 'absolute',
										top: '32px',
									}}
								>
									<SearchPanel
										data={data}
										searchCategories={searchCategories}
										searchValue={searchValue}
										setData={setData}
										setSearchCategories={setSearchCategories}
										setSearchOpen={setSearchOpen}
										setSearchValue={setSearchValue}
									/>
								</div>
							</ClickAwayListener>
						)}
						<div className="right-section">
							{!!loggedInUser?.permission?.Notification?.Read && (
								<IconButton className="right-button" onClick={() => setNotifOpen(!notifOpen)}>
									{notificationReceived ? <div className="dot" /> : null}
									<NotificationsIcon />
								</IconButton>
							)}

							{notifOpen && (
								<ClickAwayListener onClickAway={() => setNotifOpen(false)}>
									<div>
										<NotificationView setNotifOpen={setNotifOpen} />
									</div>
								</ClickAwayListener>
							)}

							<DivImage
								style={{
									cursor: 'pointer',
								}}
								onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
							>
								<Avatar
									sx={{
										width: '30px',
										height: '30px',
										fontSize: '14px',
										background: '#FFDBF3',
										color: '#D349A4',
										fontWeight: 'bold',
									}}
								>
									{initialsUser()}
								</Avatar>
							</DivImage>

							{loggedInUser && isUserMenuOpen && (
								<ClickAwayListener onClickAway={() => setIsUserMenuOpen(false)}>
									<div>
										<WrapperStack>
											<UpperStack>
												<UserName>{loggedInUser?.display}</UserName>
												<LogoutContainer onClick={() => onLogoutClick()}>
													<LogoutIcon
														sx={{
															fontSize: '14px',
															color: '#4D79EA',
														}}
													/>
													<LogoutText>Logout</LogoutText>
												</LogoutContainer>
											</UpperStack>
											<LowerStack>
												{metaBreezeAdminsetting && !!loggedInUser.permission.User?.Read && (
													<ItemsContainer>
														<SettingsIcon
															sx={{
																fontSize: '17px',
																color: '#4D79EA',
															}}
														/>
														<MenuItemName>My Settings</MenuItemName>
													</ItemsContainer>
												)}
												{metaBreezeAdminsetting &&
													(!!loggedInUser.permission.Team?.Read ||
														!!loggedInUser.permission.Role?.Read ||
														!!loggedInUser.permission.User?.Read) && (
														<ItemsContainer
															onClick={e => {
																handleNavigate('adminSettings');
															}}
														>
															<ManageAccountsIcon
																sx={{
																	fontSize: '17px',
																	color: '#4D79EA',
																}}
															/>
															<MenuItemName>Admin Settings</MenuItemName>
														</ItemsContainer>
													)}
												<ItemsContainer>
													<NotificationsIcon
														sx={{
															fontSize: '17px',
															color: '#4D79EA',
														}}
													/>
													<MenuItemName>Notification Settings</MenuItemName>
												</ItemsContainer>
											</LowerStack>
										</WrapperStack>
									</div>
								</ClickAwayListener>
							)}
						</div>
					</SearchBarWrapper>
					<StyledContainer sx={{ flex: 3 }}>
						<Routes>
							{featureCRMNavLinks().map((item, i) => (
								<Fragment key={i}>
									<Route exact element={item.element} path={item.path} />
									{!!item.subNavLink &&
										item.subNavLink.map((link, j) => (
											<Route key={j} exact element={link?.element} path={link?.path} />
										))}
								</Fragment>
							))}
						</Routes>
					</StyledContainer>
				</Box>
				{metaBreezeChat && (
					<>
						<LiveChatButton />
						<LiveChat />
					</>
				)}
			</Box>
		</>
	);
};

export default CaseView;

const CRMSideNav = () => {
	const { toggleNewDetails } = useCrmContext();
	const params = useParams();
	const navigate = useNavigate();
	const [isStats, setIsStats] = useState(false);
	const [displayAddIcon, setDisplayAddIcon] = useState(false);
	const crmLink = featureCRMNavLinks();
	const location = useLocation();
	const { loggedInUser } = useAuth();
	const pathNotDisplayAddIcon = ['/okr', '/GlobalSearchResults', '/NotificationsDetails'];

	useEffect(() => {
		if (location.pathname.includes('/stats')) {
			setIsStats(true);
		}

		if (location.pathname === '/' || _.some(pathNotDisplayAddIcon, item => location.pathname.includes(item))) {
			setDisplayAddIcon(false);
		} else if (loggedInUser) {
			if (location.pathname === '/account' && !!loggedInUser?.permission?.Account?.Add) {
				setDisplayAddIcon(true);
			} else if (
				(location.pathname === '/case' ||
					location.pathname === '/case/list' ||
					location.pathname === '/case/kanban') &&
				!!loggedInUser?.permission?.Case?.Add
			) {
				setDisplayAddIcon(true);
			} else if (location.pathname === '/stats') {
				setDisplayAddIcon(true);
			} else if (location.pathname === '/documentation') {
				setDisplayAddIcon(true);
			} else {
				setDisplayAddIcon(false);
			}
		} else {
			setDisplayAddIcon(false);
		}
	}, [location.pathname, loggedInUser]);

	const handleNewRecord = () => {
		if (isStats) {
			navigate('/stats/report/new');
			return;
		}

		const currentLink = _.find(crmLink, link => link.path.includes(params['*']?.split('/')[0]));
		toggleNewDetails(currentLink);
	};

	return (
		<SideNav>
			<CrmSideNav setIsStats={setIsStats} />
			{displayAddIcon && (
				<StyledButton onClick={handleNewRecord}>
					<AddIcon />
				</StyledButton>
			)}
		</SideNav>
	);
};
