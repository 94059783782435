// @flow

// core
import React, { type Node } from 'react';
// components
import Stack from '@mui/material/Stack';
import { type ISkeletonProps, LoaderSkeleton } from './LoaderSkeleton';
import { Box } from '@mui/material';

export const NewWorklist = ({ children, isLoading }: ISkeletonProps): Node =>
	isLoading ? (
		<Stack
			data-testid="merge-cards-loader"
			direction="column"
			spacing="20px"
			sx={{ paddingTop: '20px', width: '100%', justifyContent: 'center', alignItems: 'center' }}
		>
			<LoaderSkeleton sx={{ height: '56px', width: '370px' }} />
			<LoaderSkeleton sx={{ height: '56px', width: '375px' }} />

			<Box sx={{ display: 'flex', width: '370px', justifyContent: 'space-between', marginTop: '20px' }}>
				<LoaderSkeleton sx={{ height: '48px', width: '100px' }} />
				<Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
					<LoaderSkeleton sx={{ height: '48px', width: '100px' }} />
					<LoaderSkeleton sx={{ height: '48px', width: '100px' }} />
				</Box>
			</Box>
			<LoaderSkeleton sx={{ height: '56px', width: '375px' }} />
		</Stack>
	) : (
		children
	);
