export const searchScopes = {
	bundle: 'searchBundle',
	dicom: 'searchDicom',
	emergency: 'searchEmergency',
	organization: 'searchOrganization',
	organizationElk: 'searchOrganizationElk',
	organizationLicense: 'searchOrganizationLicense',
	helpElk: 'searchHelpElk',
	patient: 'searchPatient',
	patientElk: 'searchPatientElk',
	patientById: 'searchPatientById',
	order: 'searchOrder',
	study: 'searchStudy',
	studyRegular: 'searchStudyRegular',
	visit: 'searchVisit',
	encounter: 'searchVisit',
	logs: 'searchLog',
	logsElk: 'searchLogElk',
	task: 'searchTask',
	fax: 'searchFax',
	codes: 'searchCode',
	issuer: 'searchIssuer',
	all: 'searchAll',
	device: 'searchDevice',
	worklistLayout: 'searchWorklistLayout',
	valueSet: 'searchValueSet',
	practitioner: 'searchPractitioner',
	practitionerElk: 'searchPractitionerElk',
	practitionerDetails: 'practitionerDetails',
	practitionerLoggedUser: 'searchPractitionerLoggedUser',
	practitionerRole: 'searchPractitionerRole',
	role: 'searchRole',
	guiRole: 'searchGuiRole',
	activityHistory: 'searchActivityHistory',
	help: 'help',
	workflowStep: 'searchWorkflowStep',
	studyStatus: 'searchStudyStatus',
	studyType: 'searchStudyType',
	patientActivity: 'searchPatientActivity',
	patientAlert: 'searchPatientAlert',
	diagnosticReport: 'searchDiagnosticReport',
	patientPortalAll: 'searchPatientPortalAll',
	patientPortalHelp: 'searchPatientPortalHelp',
	report: 'searchReport',
	documentReference: 'searchDocumentReference',
	appointment: 'searchAppointment',
	appointmentReserve: 'searchAppointmentReserve',
	availableTimeForAppointment: 'searchAvailableTimeForAppointment',
	availableTimeForAppointmentByService: 'searchAvailableTimeForAppointmentByService',
	healthcareService: 'searchHealthcareService',
	coverage: 'searchCoverage',
	feeSchedule: 'searchFeeSchedule',
	procedureCode: 'searchProcedureCode',
	measurement: 'measurement',
	invoice: 'searchInvoice',
	marketplace: 'searchMarketplace',
	blumeForm: 'searchForm',
	workflowAutomation: 'searchWorkflowAutomation',
	insurancePayer: 'searchInsurancePayer',
	financialType: 'searchFinancialType',
	priorAuthorization: 'searchPriorAuthorization',
	chargeItem: 'searchChargeItem',
	relatedPerson: 'searchRelatedPerson',
	patientLedger: 'patientLedger',
	allergyIntolerance: 'searchAllergyIntolerance',
	carePlan: 'searchCarePlan',
	// TODO: delete once Elastic search is fully functional
	worklist: 'searchAllStudyOld',
	priority: 'searchPriority',
	teachingFolder: 'searchTeachingFolder',
};
