import { PanTool } from '@cornerstonejs/tools';
import { getEnabledElement, triggerEvent } from '@cornerstonejs/core';
import { PAN_MODIFIED } from '../contexts/consts/consts';

class PanToolUpdated extends PanTool {
	_dragCallback(evt) {
		const { element, deltaPoints, viewportId } = evt.detail;
		const enabledElement = getEnabledElement(element);
		const deltaPointsWorld = deltaPoints.world;
		const camera = enabledElement.viewport.getCamera();
		const { focalPoint, position } = camera;

		const updatedPosition = [
			position[0] - deltaPointsWorld[0],
			position[1] - deltaPointsWorld[1],
			position[2] - deltaPointsWorld[2],
		];

		const updatedFocalPoint = [
			focalPoint[0] - deltaPointsWorld[0],
			focalPoint[1] - deltaPointsWorld[1],
			focalPoint[2] - deltaPointsWorld[2],
		];

		enabledElement.viewport.setCamera({
			focalPoint: updatedFocalPoint,
			position: updatedPosition,
		});
		triggerEvent(element, PAN_MODIFIED, { pan: enabledElement.viewport.getPan(), viewportId });
		enabledElement.viewport.render();

		const verticalAlignment = enabledElement.viewport?.defaultOptions?.rules?.verticalAlign;
		const horizontalAlignment = enabledElement.viewport?.defaultOptions?.rules?.horizontalAlign;

		if (verticalAlignment) {
			delete enabledElement.viewport.defaultOptions.rules.verticalAlign;
		}

		if (horizontalAlignment) {
			delete enabledElement.viewport.defaultOptions.rules.horizontalAlign;
		}
	}
}
export default PanToolUpdated;
