import getActiveTextStyle from './getActiveTextStyle';

const getActiveColor = editor => {
	const attribute = 'color';
	const defaultValue = '#000000';

	return getActiveTextStyle({ editor, attribute, defaultValue });
};

export default getActiveColor;
