import React from 'react';

import styles from './ParagraphBlock.module.scss';

const ParagraphBlock = ({ theme, block, setDisplayMode, isSelected }) => (
	<div
		className={`${styles.paragraphBlock} ${isSelected ? styles.paragraphBlockSelected : ''}`}
		onMouseOver={() => setDisplayMode && setDisplayMode({ type: 'hover' })}
	>
		<p className={theme === 'OAI' ? styles.paragraphOAI : styles.paragraphBlume} data-testid="paragraph">
			{block.text}
		</p>
	</div>
);

export default ParagraphBlock;
