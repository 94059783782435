import React, { useState } from 'react';

import { AttachButtonIcon, InsertPageButtonIcon, TextButtonIcon } from '../../assets/icons';

import styles from './ActionButtons.module.scss';

const ActionButtons = ({ theme, activeButton, setActiveButton, setIsSectionsOpen, isSectionsOpen }) => {
	const [hover, setHover] = useState('');

	const getCurrentFill = button => {
		if (theme === 'Blume') {
			if (activeButton === button) {
				return '#fff';
			}
			return hover === button ? '#4D79EA' : '#000000';
		}
		if (activeButton === button) {
			return '#fff';
		}
		return hover === button ? '#4D79EA' : '#fff';
	};

	const onActionButton = actionButton => {
		if (activeButton === actionButton) {
			setActiveButton('');
		} else {
			setActiveButton(actionButton);
			setIsSectionsOpen(false);
		}
	};

	const getActiveStyle = () => {
		if (activeButton !== 'insertPage' && (activeButton || isSectionsOpen)) {
			if (theme === 'OAI') return styles.actionButtonsOAIActive;
			return styles.actionButtonsBlumeActive;
		}
	};

	return (
		<div className={`${theme === 'OAI' ? styles.actionButtonsOAI : styles.actionButtonsBlume} ${getActiveStyle()}`}>
			<div
				className={`${theme === 'OAI' ? styles.actionButtonsOAIItem : styles.actionButtonsBlumeItem}  ${
					activeButton === 'text' ? styles.actionButtonsItemActive : ''
				}`}
				data-testid="text-action-button"
				onClick={() => onActionButton('text')}
				onMouseLeave={() => setHover('')}
				onMouseOver={() => setHover('text')}
			>
				<TextButtonIcon
					fill={getCurrentFill('text')}
					opacity={activeButton !== 'text' && hover !== 'text' && theme === 'Blume' ? 0.4 : ''}
				/>
			</div>
			<div
				className={`${theme === 'OAI' ? styles.actionButtonsOAIItem : styles.actionButtonsBlumeItem}  ${
					activeButton === 'attach' ? styles.actionButtonsItemActive : ''
				}`}
				data-testid="attach-action-button"
				onClick={() => onActionButton('attach')}
				onMouseLeave={() => setHover('')}
				onMouseOver={() => setHover('attach')}
			>
				<AttachButtonIcon
					fill={getCurrentFill('attach')}
					opacity={activeButton !== 'attach' && hover !== 'attach' && theme === 'Blume' ? 0.4 : ''}
				/>
			</div>
			<div
				className={`${theme === 'OAI' ? styles.actionButtonsOAIItem : styles.actionButtonsBlumeItem}  ${
					activeButton === 'insertPage' ? styles.actionButtonsItemActive : ''
				}`}
				data-testid="insert-action-button"
				onClick={() => onActionButton('insertPage')}
				onMouseLeave={() => setHover('')}
				onMouseOver={() => setHover('insertPage')}
			>
				<InsertPageButtonIcon
					fill={getCurrentFill('insertPage')}
					opacity={activeButton !== 'insertPage' && hover !== 'insertPage' && theme === 'Blume' ? 0.4 : ''}
				/>
			</div>
		</div>
	);
};

export default ActionButtons;
