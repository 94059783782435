const getCurrentDate = (date, timezone) => {
	const dateTimeObj = new Date(date);
	try {
		return dateTimeObj.toLocaleDateString('en-US', { timeZone: timezone });
	} catch (error) {
		console.error(`timezone: ${timezone} Error:${error}`);
		return dateTimeObj.toLocaleDateString(); // Fallback to default locale
	}
};

export default getCurrentDate;
