import React, { useState } from 'react';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import BarChartIcon from '@mui/icons-material/BarChart';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import GridLayout from 'react-grid-layout';
import ShareSideContent from '../BreezeReportDetailsView/components/ShareSideContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router';

const ReportEditView = () => {
	const [isShare, setIsShare] = useState(false);

	const isSmallScreen = useMediaQuery('(max-width:1100px)');

	const layout = [
		{ i: 'a', x: 0, y: 0, w: 4, h: 4, minW: 3, maxH: 8 },
		{ i: 'b', x: 4, y: 0, w: 4, h: 4, minW: 3, maxH: 8 },
		{ i: 'c', x: 8, y: 0, w: 4, h: 4, minW: 3, maxH: 8 },
		{ i: 'd', x: 0, y: 0, w: 4, h: 4, minW: 3, maxH: 8 },
		{ i: 'e', x: 4, y: 0, w: 4, h: 4, minW: 3, maxH: 8 },
		{ i: 'f', x: 8, y: 0, w: 4, h: 4, minW: 3, maxH: 8 },
	];
	const navigate = useNavigate();

	return (
		<>
			<Button
				sx={{
					position: 'fixed',
					right: '45px',
					bottom: '45px',
					width: '146px',
					height: '70px',
					borderRadius: '38px',
					padding: 0,
					boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
					background: '#42A5F5',
					color: '#FFFFFF',
					zIndex: 1,
					transition: '0.2s',
					'&:hover': {
						background: '#28679A',
					},
				}}
			>
				<FilterAltIcon />
				<Typography fontSize={16} sx={{ textTransform: 'uppercase' }}>
					Filter
				</Typography>
			</Button>
			<Box
				sx={{
					padding: isSmallScreen ? '28px 60px' : '28px 100px',
				}}
			>
				<Stack alignItems="center" direction="row" justifyContent="space-between">
					<Stack alignItems="center" direction="row" spacing={1}>
						<Button
							sx={{
								color: '#42A5F5',
								border: '1px solid rgba(66, 165, 245, 0.4)',
								borderRadius: '6px',
								minWidth: 54,
								height: 54,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								cursor: 'pointer',
							}}
							onClick={() => {
								setTimeout(() => {
									navigate('/home');
								}, 200);
							}}
						>
							<BarChartIcon />
						</Button>

						<Box
							sx={{
								border: '1px solid rgba(255, 255, 255, 0.1)',
								borderRadius: '6px',
								height: 54,
								background: 'rgba(57, 57, 57, 0.5)',
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								padding: '0 16px',
								gap: '20px',
							}}
						>
							<Avatar
								sx={{
									background: '#A09BD5',
									color: '#fff',
									width: '34px',
									height: '34px',
									fontSize: '14px',
								}}
							>
								RS
							</Avatar>
							<Typography
								sx={{
									color: '#FFFFFF',
								}}
							>
								New Report
							</Typography>
							<IconButton aria-label="edit">
								<EditOutlinedIcon />
							</IconButton>
						</Box>
					</Stack>

					<Stack
						alignItems="center"
						direction="row"
						sx={{
							marginLeft: 'auto',
						}}
					>
						<Button
							sx={{
								marginLeft: '4px',
								minWidth: '35px',
								color: '#C4C4C4',
								border: '1px dashed #C4C4C4',
								borderRadius: '100px',
								padding: 0,
								height: 35,
							}}
						>
							<AddIcon />
						</Button>
						<Button
							sx={{
								marginLeft: '16px',
								minWidth: '54px',
								height: '54px',
								background: 'rgba(57, 57, 57, 0.5)',
								border: '1px solid rgba(255, 255, 255, 0.1)',
								borderRadius: '20px',
								color: '#FFFFFF99',
							}}
							onClick={() => setIsShare(true)}
						>
							<MoreHorizIcon />
						</Button>
					</Stack>
				</Stack>

				<Box
					sx={{
						marginTop: '50px',
						marginLeft: '-10px',
					}}
				>
					<GridLayout cols={12} layout={layout} rowHeight={100} width={window.innerWidth - 250}>
						{layout.map((el, index) => (
							<Box
								key={el.i}
								sx={{
									width: '100%',
									height: '100%',
									background: 'rgba(128, 128, 128, 0.05)',
									borderRadius: '20px',
									border: '1px dashed rgba(66, 165, 245, 0.5)',
									cursor: 'pointer',
									'.react-resizable-handle::after, .react-resizable-handle.react-resizable-handle-se':
										{
											right: 8,
											bottom: 8,
										},
									'&.resizing': {
										border: '2px dashed #42A5F5',
										borderRadius: '15px',
										background: '#393939',
									},
								}}
							/>
						))}
					</GridLayout>
				</Box>
			</Box>

			{isShare && <ShareSideContent setIsShare={setIsShare} />}
		</>
	);
};

export default ReportEditView;
