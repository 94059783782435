const getInitials = name =>
	name
		?.match(/(\b\S)?/g)
		.join('')
		.match(/(^\S|\S$)?/g)
		.join('')
		.toUpperCase();

const getUserInitials = name => {
	const firstName = name?.split(' ')[0];
	const lastName = name?.split(' ')[1];
	if (firstName && lastName) return `${firstName[0]}${lastName[0]}`?.toUpperCase();
	if (firstName && !lastName) return `${firstName[0]}${firstName[1] ? firstName[1] : ''}`?.toUpperCase();
	if (!firstName && lastName) return `${lastName[0]}${lastName[1] ? lastName[1] : ''}`?.toUpperCase();
};

export { getInitials, getUserInitials };
