import React, { createContext, useContext, useRef, useState } from 'react';

const ImageViewerExternalContext = createContext({});

const ImageViewerExternalContextProvider = ({ internalManagingOrganizationID, studyInstanceUID, creatorUserId, isUploaded, children }) => {
	const [series, setSeries] = useState([]);
	const [keyImages, setKeyImages] = useState([]);
	const [measurements, setMeasurements] = useState([]);
	const [viewport, setViewport] = useState();
	const [imagesLoading, setImagesLoading] = useState(true);

	const keyImagesDataURLRef = useRef({});

	return (
		<ImageViewerExternalContext.Provider
			value={{
				internalManagingOrganizationID,
				studyInstanceUID,
				series,
				setSeries,
				keyImages,
				setKeyImages,
				viewport,
				setViewport,
				keyImagesDataURLRef,
				creatorUserId,
				isUploaded,
				measurements,
				setMeasurements,
				imagesLoading,
				setImagesLoading
			}}
		>
			{children}
		</ImageViewerExternalContext.Provider>
	);
};

const useImageViewerExternalContext = () => useContext(ImageViewerExternalContext);

export { ImageViewerExternalContext, ImageViewerExternalContextProvider, useImageViewerExternalContext };
