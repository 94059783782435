import React from 'react';

const TableIcon = ({ fill }) => (
	<svg fill="none" height="25" viewBox="0 0 26 25" width="26" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_31964)">
			<path
				d="M21.0498 3.26953H5.99983C4.89616 3.26953 3.99316 4.17253 3.99316 5.2762V19.3228C3.99316 20.4265 4.89616 21.3295 5.99983 21.3295H21.0498C22.1535 21.3295 23.0565 20.4265 23.0565 19.3228V5.2762C23.0565 4.17253 22.1535 3.26953 21.0498 3.26953ZM21.0498 5.2762V8.28619H5.99983V5.2762H21.0498ZM16.0331 19.3228H11.0165V10.2929H16.0331V19.3228ZM5.99983 10.2929H9.00982V19.3228H5.99983V10.2929ZM18.0398 19.3228V10.2929H21.0498V19.3228H18.0398Z"
				fill={fill}
			/>
		</g>
		<defs>
			<clipPath id="clip0_2852_31964">
				<rect fill={fill} height="24.08" transform="translate(0.983398 0.257812)" width="24.08" />
			</clipPath>
		</defs>
	</svg>
);

export default TableIcon;
