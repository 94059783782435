import React, { useRef, useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import ImageViewerViewportScrollbarItem from './ImageViewerViewportScrollbarItem';
import ImageViewerViewportScrollbarIndicator from './ImageViewerViewportScrollbarIndicator';
import getDefaultRenderingEngine from '../../cornerstone/getDefaultRenderingEngine';
import { useOnSetActiveViewportId } from '@rs-ui/views/ImageViewerView3D/hooks/useOnSetActiveViewportId';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const setViewportImageIdIndex = ({ viewportId, imageIdIndex }) => {
	const renderingEngine = getDefaultRenderingEngine();

	if (!renderingEngine) {
		return;
	}

	const renderingEngineViewport = renderingEngine.getViewport(viewportId);

	if (!renderingEngineViewport) {
		return;
	}

	renderingEngineViewport.setImageIdIndex(imageIdIndex);
	renderingEngineViewport.targetImageIdIndex = imageIdIndex;
};

const ImageViewerViewportScrollbar = ({ viewportId, imageIds, initialInstanceIndex, viewportsCount }) => {
	const { onSetActiveViewportId } = useOnSetActiveViewportId();

	const [activeScroll, setActiveScroll] = useState();
	const [lastLoadedImage, setLastLoadedImage] = useState(initialInstanceIndex);
	const [scrollItems, setScrollItems] = useState([]);
	const wonIvAutohidescroll = useBooleanFlagValue('WON-IV-AUTOHIDESCROLL');

	const parentRef = useRef();

	const renderImage = imageIdIndex => {
		setActiveScroll(imageIdIndex + 1);

		setViewportImageIdIndex({
			viewportId,
			imageIdIndex,
		});
	};

	const setScroll = () => {
		if (!parentRef.current) {
			return;
		}

		const maxScrollCount = Math.floor(parentRef.current.offsetHeight / 27);

		if (imageIds.length <= maxScrollCount) {
			return setScrollItems(Array.from(Array(imageIds.length).keys()));
		}

		const itemsPerScroll = (imageIds.length - 2) / (maxScrollCount - 2);

		const newScrollItems = [0];

		for (let i = 1; i < maxScrollCount - 2; i++) {
			newScrollItems.push(Math.floor(itemsPerScroll * i));
		}

		newScrollItems.push(imageIds.length - 1);

		setScrollItems(newScrollItems);
	};

	const ON_CORNERSTONE_STACK_VIEWPORT_SCROLL = e => {
		setActiveScroll(e.detail.newImageIdIndex + 1);
	};

	const ON_CORNERSTONE_STACK_NEW_IMAGE = e => {
		setActiveScroll(e.detail.imageIdIndex + 1);
		setLastLoadedImage(e.detail.imageIdIndex + 1);
	};

	useEffect(() => {
		setActiveScroll(initialInstanceIndex);
		const myObserver = new ResizeObserver(setScroll);

		myObserver.observe(parentRef.current);

		const element = document.getElementById(viewportId);

		element?.addEventListener('CORNERSTONE_STACK_VIEWPORT_SCROLL', ON_CORNERSTONE_STACK_VIEWPORT_SCROLL);

		element?.addEventListener('CORNERSTONE_STACK_NEW_IMAGE', ON_CORNERSTONE_STACK_NEW_IMAGE);

		return () => {
			myObserver.disconnect();

			element?.removeEventListener('CORNERSTONE_STACK_VIEWPORT_SCROLL', ON_CORNERSTONE_STACK_VIEWPORT_SCROLL);

			element?.removeEventListener('CORNERSTONE_STACK_NEW_IMAGE', ON_CORNERSTONE_STACK_NEW_IMAGE);
		};
	}, [imageIds]);

	const renderScrollItems = useMemo(() => {
		if (wonIvAutohidescroll) {
			const opacityCoefficient =
				(scrollItems.length - 1) / (scrollItems[scrollItems.length - 1] - scrollItems[0]);

			return scrollItems.map(item => {
				const opacityOffset = 0.2 * Math.abs(item + 1 - activeScroll) * opacityCoefficient;
				const opacity = 0.9 - opacityOffset > 0.1 ? 0.9 - opacityOffset : 0.1;

				return (
					<ImageViewerViewportScrollbarItem
						key={imageIds?.[item]}
						isLoaded
						item={item}
						opacity={opacity}
						renderImage={() => {}}
					/>
				);
			});
		}
		return scrollItems.map(item => (
			<ImageViewerViewportScrollbarItem key={imageIds?.[item]} isLoaded item={item} renderImage={() => {}} />
		));
	}, [scrollItems, activeScroll]);

	return (
		<Box
			ref={parentRef}
			data-testid="ImageViewerViewportScrollbar"
			id={`ImageViewerViewportScrollbar_${viewportId}`}
			sx={{
				width: '34px',
				position: 'absolute',
				right: '0',
				top: '20px',
				height: 'calc(100% - 20px)',
				zIndex: '1',
				display: 'flex',
				flexDirection: 'column',
				...(wonIvAutohidescroll
					? {
							'.ScrollItem.hovered, &:hover .ScrollItem': {
								opacity: '1',
								transition: 'opacity 0.1s ease',
							},
					  }
					: {}),
			}}
			onMouseDown={e => {
				e.stopPropagation();
				onSetActiveViewportId?.(viewportId);
			}}
			onMouseUp={e => e.stopPropagation()}
		>
			<Box
				component="input"
				max={imageIds.length - 1 - (viewportsCount - 1)}
				min={0}
				step={1}
				sx={{
					position: 'absolute',
					top: '0',
					left: '17px',
					rotate: '90deg',
					transformOrigin: 'top left',
					width: `${scrollItems.length * 27 - 10}px`,
					height: '19px',
					zIndex: '1',
					opacity: '0',
					cursor: 'pointer',
					WebkitAppearance: 'none',
					background: 'transparent',
				}}
				type="range"
				value={Number.isNaN(+activeScroll) ? '' : activeScroll - 1}
				onChange={e => {
					const value = parseInt(e.target.value, 10);

					renderImage(value);
				}}
				onWheel={e => {
					const value = parseInt(e.currentTarget.value, 10);

					if (e.deltaY > 0 && value < imageIds.length - 1 - (viewportsCount - 1)) {
						renderImage(value + 1);
					}
					if (e.deltaY < 0 && value > 0) {
						renderImage(value - 1);
					}
				}}
			/>

			<ImageViewerViewportScrollbarIndicator
				activeScroll={activeScroll}
				initialInstanceIndex={initialInstanceIndex}
				lastLoadedImage={lastLoadedImage}
				scrollItems={scrollItems}
				wonIvAutohidescroll={wonIvAutohidescroll}
			/>

			<Box
				className="ScrollItem"
				sx={{
					...(wonIvAutohidescroll
						? {
								opacity: '0',
								transition: 'opacity 0.5s ease',
								'transition-delay': '3s',
						  }
						: {}),
				}}
			>
				{scrollItems.length && <>{renderScrollItems}</>}
			</Box>
		</Box>
	);
};

export default ImageViewerViewportScrollbar;
