import axios from 'axios';

const getHealthcareServiceInfo = async ({ __config, healthCareServiceIds, multiple = true }) => {
	const response = await axios.get(
		`${__config.data_sources.blume}Appointment/healthcareService?id=${healthCareServiceIds}&multipleIds=${multiple}`
	);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default getHealthcareServiceInfo;
