import styled from 'styled-components';
import { mediaTablet, mediaMobile } from '@worklist-2/core/src';

export const mainBox = {
	width: '100%',
	height: 'calc(100% - 170px)',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'center',
	[mediaTablet]: {
		height: 'calc(100% - 120px)',
	},
};

export const boxStyle = {
	flexDirection: 'column',
	display: 'flex',
	alignItems: 'center',
	minWidth: 299,
	width: '100%',
	maxWidth: '900px',
	height: '100%',
	margin: 'auto',
	marginBottom: '100px',
	marginLeft: '100px',
	padding: '10px',
	[mediaTablet]: {
		marginLeft: '20px',
		marginBottom: '0',
	},
};

export const header = {
	width: '485px',
	height: '56px',
	opacity: 0.87,
	fontFamily: 'Roboto',
	fontSize: '40px',
	fontWeight: 'normal',
	fontStretch: 'normal',
	fontStyle: 'normal',
	lineHeight: 1.4,
	letterSpacing: 'normal',
	textAlign: 'center',
	color: '#000',
	margin: '36px',
	marginTop: '25px',
	[mediaTablet]: {
		fontSize: '24px',
		lineHeight: '28px',
		margin: '15px 0 30px 0',
	},
};

export const BackDrop = styled.div`
	width: 100%;
	height: 100%;
	background-color: rgb(227 227 230 / 48%);
	backdrop-filter: blur(6px);
	display: flex;
	flex-direction: column;
`;

export const sideStyle = {
	overflowY: 'auto',
	width: 'fit-content',
	marginTop: '200px',
	borderRight: '2px solid rgba(196, 196, 196, 0.3)',
};

export const NoScrollbar = styled.div`
	::-webkit-scrollbar {
		width: 0px;
		height: 0px;
	}
	::-webkit-scrollbar-button {
		width: 0px;
		height: 0px;
	}
	::-webkit-scrollbar-thumb {
		background: #e1e1e1;
		border: 0px none #ffffff;
		border-radius: 50px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #ffffff;
	}
	::-webkit-scrollbar-thumb:active {
		background: #000000;
	}
	::-webkit-scrollbar-track {
		background: #666666;
		border: 0px none #ffffff;
		border-radius: 50px;
	}
	::-webkit-scrollbar-track:hover {
		background: #666666;
	}
	::-webkit-scrollbar-track:active {
		background: #333333;
	}
	::-webkit-scrollbar-corner {
		background: transparent;
	}
`;

export const PolicyWrapper = styled.div`
	text-align: left;
	width: 100%;
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom: 20px;
	max-height: 80vh;
	overflow-y: auto;
	padding-bottom: 100px;

	@media (max-width: 1280px) {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 0;
		padding-bottom: 0;
		max-height: unset;
	}

	p,
	li,
	i {
		font-family: Roboto !important;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 20px;
		letter-spacing: 0.25px;
		text-align: left;
		color: #000;
	}

	li {
		margin: 5px;
	}

	a {
		color: #42a5f5 !important;
	}

	i {
		font-weight: 500;
		font-style: italic;
	}

	#pleaseread {
		text-align: center !important;
		font-weight: bold !important;
		text-decoration: underline;
	}

	#california-consumer-privacy-act,
	#california-online-privacy-protection-act,
	#european-economic-area,
	#pipeda {
		color: darkred;
		font-size: 26px;
	}

	${mediaTablet} {
		h4 {
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 0.15px;
			color: #121212;
			opacity: 0.87;
		}

		p,
		li,
		i {
			font-family: Roboto !important;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: #121212;
			opacity: 0.87;
		}
	}

	${mediaMobile} {
		p,
		li,
		i {
			font-size: 12px;
		}
	}

	::-webkit-scrollbar {
		width: 7px;
		height: 7px;
	}
	::-webkit-scrollbar-button {
		width: 0px;
		height: 0px;
	}
	::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border: 0px none #c4c4c4;
		border-radius: 50px;
		height: 156px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #a39f9f;
	}
	::-webkit-scrollbar-thumb:active {
		background: #a39f9f;
	}
	::-webkit-scrollbar-track {
		background: rgb(196 196 196 / 17%);
		border: 0px none #ffffff;
		border-radius: 50px;
	}
	::-webkit-scrollbar-track:hover {
		background: rgb(196 196 196 / 17%);
	}
	::-webkit-scrollbar-track:active {
		background: rgb(196 196 196 / 17%);
	}
	::-webkit-scrollbar-corner {
		background: transparent;
	}
`;

export const DateText = styled.small`
	font-family: Roboto;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: 0.4px;
	text-align: left;
	color: #000;
`;

export const StrongText = styled.h4`
	font-family: Roboto;
	font-size: 20px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: 0.15px;
	text-align: left;
	color: #121212;
`;

export const SideBarWrapper = styled.div`
	@media only screen and (min-width: 1281px) {
		margin-left: 80px;
	}
	padding: 20px;
	width: 380px;
	height: calc(100vh - 200px);

	ul {
		margin: 0px;
	}

	li {
		// margin-bottom: 15px;
		list-style-type: none;
		color: #000;

		a {
			text-transform: capitalize;
		}

		&.blur {
			filter: blur(2px);
		}
	}

	a.active {
		color: #42a5f5 !important;
	}

	a:hover {
		color: #42a5f5 !important;
	}

	a.false {
		color: #000;
	}

	a {
		font-family: Roboto;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		text-decoration: none;
		line-height: 33px;
	}

	${mediaTablet} {
		width: calc(100% - 40px);

		li {
			margin-bottom: 28px;
		}

		a {
			line-height: 20px;
		}
	}

	${mediaMobile} {
		li {
			margin-bottom: unset;
		}

		a {
			line-height: 48px;
		}
	}
`;
