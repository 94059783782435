import { getInstanceModality } from '../../utils/utils';
import getSeriesOrientationPlane, { DICOM_IMAGE_PLANE_ANNOTATIONS } from '../../utils/getSeriesOrientationPlane';

const isAvailableMPR = instance => {
	const plane = DICOM_IMAGE_PLANE_ANNOTATIONS[getSeriesOrientationPlane(instance)];
	const modality = getInstanceModality(instance);

	if (modality !== 'CT' && modality !== 'MRI' && modality !== 'PT') {
		return false;
	}

	return !(plane !== 'Axial' && plane !== 'Coronal' && plane !== 'Sagittal');
};

export default isAvailableMPR;
