import { getEnabledElement, StackViewport, VolumeViewport, eventTarget } from '@cornerstonejs/core';
import { BaseTool } from '@cornerstonejs/tools';
import { scrollVolume } from '@cornerstonejs/tools/dist/cjs/utilities/scroll';
import { CORNERSTONE_EVENTS } from '../contexts/consts/consts';
import { getVolumeId } from './WindowLevelTool';

/**
 * The StackScrollMouseWheelTool is a tool that allows the user to scroll through a
 * stack of images using the mouse wheel
 */
class StackScrollMouseWheelTool extends BaseTool {
	static toolName;

	_configuration;

	constructor(
		toolProps = {},
		defaultToolProps = {
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				invert: false,
				debounceIfNotLoaded: true,
				loop: false,
			},
		}
	) {
		super(toolProps, defaultToolProps);
	}

	mouseWheelCallback(evt) {
		const { wheel, element } = evt.detail;
		const { direction } = wheel;
		const { invert } = this.configuration;
		const { viewport } = getEnabledElement(element);
		const delta = direction * (invert ? -1 : 1);

		if (viewport instanceof StackViewport) {
			const currentImageIdIndex = viewport.getCurrentImageIdIndex();
			const imageIds = viewport.getImageIds();

			if (imageIds?.length > 1) {
				viewport.scroll(delta, this.configuration.debounceIfNotLoaded, this.configuration.loop);
			}

			if (
				(direction === -1 && currentImageIdIndex === 0) ||
				(direction === 1 && currentImageIdIndex === imageIds.length - 1)
			) {
				eventTarget.dispatchEvent({
					type: CORNERSTONE_EVENTS.CORNERSTONE_SCROLL_BETWEEN_SERIES,
					detail: {
						direction,
						viewportId: viewport.id,
					},
				});
			}
		}

		if (viewport instanceof VolumeViewport) {
			const targetId = this.getTargetId(viewport);
			const volumeId = getVolumeId(targetId);
			scrollVolume(viewport, volumeId, delta);
		}
	}
}

StackScrollMouseWheelTool.toolName = 'StackScrollMouseWheel';
export default StackScrollMouseWheelTool;
