// @flow
//core
import React, { type Node } from 'react';
//libs
import { Box, IconButton } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface INumericInputArrows {
	inputValue?: string;
	onChange: (value: number) => void;
}

const styles = {
	iconButtonStyles: {
		maxHeight: '24px',
		maxWidth: '24px',
		cursor: 'pointer',
		padding: '4px',
	},
	wrapperStyles: {
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '100%',
		position: 'absolute',
		right: '10px',
	},
};

export const NumericInputArrows = ({ inputValue, onChange }: INumericInputArrows): Node => {
	const handleIncrement = () => {
		if (inputValue) {
			onChange(`${+inputValue + 1}`);
		} else {
			onChange('1');
		}
	};

	const handleDecrement = () => {
		if (inputValue) {
			onChange(`${+inputValue - 1}`);
		} else {
			onChange('-1');
		}
	};

	return (
		<Box sx={styles.wrapperStyles}>
			<IconButton data-testid="increment-button" sx={styles.iconButtonStyles} onClick={handleIncrement}>
				<ArrowDropUpIcon />
			</IconButton>
			<IconButton data-testid="decrement-button" sx={styles.iconButtonStyles} onClick={handleDecrement}>
				<ArrowDropDownIcon />
			</IconButton>
		</Box>
	);
};
