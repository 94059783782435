/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
import encounterSchema from './schemata/encounterSchema';
/************************************************************************************************************************
Resource: Encounter
Reference: https://www.hl7.org/fhir/Encounter.html
 A patient encounter is further characterized by the setting in which it takes place. Amongst them are ambulatory,

emergency, home health, inpatient and virtual encounters. An Encounter encompasses the lifecycle from pre-admission,

the actual encounter (for ambulatory encounters), and admission, stay and discharge (for inpatient encounters). During the
encounter the patient may move from practitioner to practitioner and location to location. Because of the broad scope of
Encounter, not all elements will be relevant in all settings. For this reason, admission/discharge related information is
kept in a separate Hospitalization component within Encounter. The class element is used to distinguish between these settings,
which will guide further validation and application of business rules. There is also substantial variance from organization
to organization (and between jurisdictions and countries) on which business events translate to the start of a new Encounter,
or what level of aggregation is used for Encounter. For example, each single visit of a practitioner during a hospitalization
may lead to a new instance of Encounter, but depending on local practice and the systems involved, it may well be that this
is aggregated to a single instance for a whole hospitalization. Even more aggregation may occur where jurisdictions introduce
groups of Encounters for financial or other reasons. Encounters can be aggregated or grouped under other Encounters using
the partOf element. See below for examples. Encounter instances may exist before the actual encounter takes place to convey
pre-admission information, including using Encounters elements to reflect the planned start date or planned encounter locations.
In this case the status element is set to 'planned'. The Hospitalization component is intended to store the extended information
relating to a hospitalization event. It is always expected to be the same period as the encounter itself. Where the period
is different, another encounter instance should be used to capture this information as a partOf this encounter instance.
The Procedure and encounter have references to each other, and these should be to different procedures; one for the procedure
that was performed during the encounter (stored in Procedure.encounter), and another for cases where an encounter is a result
of another procedure (stored in Encounter.indication) such as a follow-up encounter to resolve complications from an earlier
procedure. During the life-cycle of an encounter it will pass through many statuses. Typically these are in order or the
organization's workflow: planned, in-progress, finished/cancelled. This status information is often used for other things,
and often an analysis of the status history is required. This could be done by scanning through all the versions of the encounter,
checking the period of each, and then doing some form of post processing. To ease the burden of this (or where a system doesn't
support resource histories) a status history component is included. There is no direct indication purely by the status field
as to whether an encounter is considered "admitted". The context of the encounter and business practices/policies/workflows/types
can influence this definition. (e.g., acute care facility, aged care center, outpatient clinic, emergency department, community-based
clinic). Statuses of "arrived", "triaged" or "in progress" could be considered the start of the admission, and also have
the presence of the hospitalization sub-component entered. The "on leave" status might or might not be a part of the admission,
for example if the patient was permitted to go home for a weekend or some other form of external event. The location is also
likely to be filled in with a location status of "present". For other examples such as an outpatient visit (day procedure
- colonoscopy), the patient could also be considered to be admitted, hence the encounter doesn't have a fixed definition
of admitted. At a minimum, we do believe that a patient IS admitted when the status is in-progress. 
************************************************************************************************************************/

export default class EncounterResource extends FhirDomainResource {
	identifier = [];
	status;
	statusHistory = [{}];
	class;
	classHistory = [{}];
	type = [];
	serviceType;
	priority;
	subject;
	episodeOfCare = [];
	basedOn = [];
	participant = [{}];
	appointment = [];
	period;
	length;
	reasonCode = [];
	reasonReference = [];
	diagnosis = [{}];
	account = [];
	hospitalization_preAdmissionIdentifier;
	hospitalization_origin;
	hospitalization_admitSource;
	hospitalization_reAdmission;
	hospitalization_dietPreference = [];
	hospitalization_specialCourtesy = [];
	hospitalization_specialArrangement = [];
	hospitalization_destination;
	hospitalization_dischargeDisposition;
	location = [{}];
	serviceProvider;
	partOf;

	constructor(resourceString) {
		super(resourceString, encounterSchema);
		this.resourceType = 'Encounter';
		this.populateFields();
	}
}
