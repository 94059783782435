import { create } from 'zustand';
import { PATIENT_DOCUMENT_FILTERS } from '@worklist-2/worklist/src/DocumentViewerV3/consts/consts';

const minCardHeight = 378;
const minCardHeightWithNotes = 408;

export const usePatientInfoCardStore = create(set => ({
	// Store variables with default values
	selectedCard: {
		idx: 2, // Default selected card
	},
	cardOrder: [0, 1, 2], // Mandatory prop to preserve the selected card order
	isCardCollapsed: false,
	selectedDocFilter: PATIENT_DOCUMENT_FILTERS.ALL,
	refHeight: minCardHeight,
	minHeight: minCardHeight,
	selectedPatientDocument: null,
	initConfig: {
		enablePatientNavigation: true,
		enableOverflowMenu: false,
		enableCollapse: true,
	},
	isDocumentViewer: false,
	// ==================== Setters ====================
	setSelectedCard: card => set({ selectedCard: card }),
	setCardOrder: order => set({ cardOrder: order }),
	setIsCardCollapsed: status => set({ isCardCollapsed: status }),
	setSelectedDocFilter: filter => set({ selectedDocFilter: filter }),
	setRefHeight: height => set({ refHeight: height }), // Root height of the cards
	setMinHeight: height => set({ minHeight: height }), // Minimum height to set for the cards on resize
	setSelectedPatientDocument: id => set({ selectedPatientDocument: id }),
	setIsDocumentViewer: status => set({ isDocumentViewer: status }),
	// Initialize the card in collapsed state; 90 indicates that the height of each card is 30px in the collapsed state (3 cards x 30px = 90px)
	initAsCollapsed: (galaxyDvGlobalNotes, newInitConfig) => {
		const collapsedState = galaxyDvGlobalNotes
			? { refHeight: 90, minHeight: 90, cardOrder: [0, 1, 2], selectedCard: { idx: 2 } }
			: { refHeight: 60, minHeight: 60, cardOrder: [0, 1], selectedCard: { idx: 1 } };

		set(collapsedState);
		// Update initConfig
		if (newInitConfig) {
			set(state => {
				return { initConfig: { ...state.initConfig, ...newInitConfig } };
			});
		}
	},

	//
	// ==================== Re-setters ====================
	resetPatientInfoCardStore: galaxyDvGlobalNotes => {
		const defaultState = {
			selectedCard: { idx: galaxyDvGlobalNotes ? 2 : 1 }, // default selected card
			cardOrder: galaxyDvGlobalNotes ? [0, 1, 2] : [0, 1],
			isCardCollapsed: false,
			selectedDocFilter: PATIENT_DOCUMENT_FILTERS.ALL,
			refHeight: galaxyDvGlobalNotes ? minCardHeight : minCardHeightWithNotes,
			minHeight: galaxyDvGlobalNotes ? minCardHeight : minCardHeightWithNotes,
			initConfig: {
				enablePatientNavigation: true,
				enableOverflowMenu: false,
				enableCollapse: true,
			},
		};
		set(defaultState);
	},
	resetPatientDoc: () =>
		set({
			selectedDocFilter: PATIENT_DOCUMENT_FILTERS.ALL,
		}),
}));
