import React, { createContext, useEffect, useState, useContext, useMemo, useCallback } from 'react';
import { crmScopeMapping } from '../crm';
import { useConfig } from './ConfigContext';
import CRMDataLoader from '../services/CRMDataLoader';
import _ from 'lodash';

export const crmSearchScopes = {
	// CRM
	all: 'all',
	commentApi: 'commentApi',
	caseApi: 'caseApi',
	accountApi: 'accountApi',
	notificationApi: 'notificationApi',
	userApi: 'userApi',
	teamApi: 'teamApi',
	roleApi: 'roleApi',
	contactApi: 'contactApi',
	globalSearchApi: 'globalSearchApi',
	reportApi: 'reportApi',
	okrApi: 'okrApi',
	conversationApi: 'conversationApi',
	permissionApi: 'permissionApi',
	organizationLicenseApi: 'organizationLicenseApi',
	organizationApi: 'organizationApi',
	practitionerApi: 'practitionerApi',
	practitionerRoleApi: 'practitionerRoleApi',
	roleOmegaAIApi: 'roleOmegaAIApi',
	deviceApi: 'deviceApi',
	taskApi: 'taskApi',
	subscriptionApi: 'subscriptionApi',
};

export const caseMapping = {
	priority: 'Priority',
	type: 'Type',
	sw_version: 'SWVersion',
	status: 'Status',
	bug_number: 'BugNumber',
	frequency: 'Frequency',
	case_owner: 'CaseOwner',
	reportable_incident: 'ReportableIncident',
	last_modified_by: 'LastUpdatedUserName',
	downtime_hrs: 'Downtime',
	billable_hrs: 'Billable',
	parent_case: 'ParentCaseNumber',
	parent_case_id: 'ParentCaseId',
	is_this_issue_impacting_parent_care: 'ImpactPatientCase',
	project_name: 'ProjectName',
	project_name_id: 'ProjectNameId',
	age_in_business_days: 'AgeInBusinessDays',
	subject: 'Subject',
	description: 'Description',
	duplicate_case_of: 'DuplicateCaseNumber',
	duplicate_case_of_id: 'DuplicateCaseId',
	root_cause: 'RootCause',
	case_resolution: 'CaseResolution',
	created_by: 'CreatedUserName',
	closed_by: 'ClosedUserName',
	datetime_closed: 'ClosedDateTime',
	account_name: 'AccountName',
	account_id: 'AccountId',
	case_record_type: 'CaseRecordType',
	case_origin: 'CaseOrigin',
};

export const accountMapping = {
	account_state: 'AccountState',
	account_record_type: 'AccountRecordType',
	account_owner: 'AccountOwner',
	local_time: 'LocalTime',
	account_status: 'AccountStatus',
	important_account_info: 'ImportantAccountInfo',
	downtime_hrs: 'DownTime',
	fax: 'Fax',
	parent_account: 'ParentAccount',
	parent_account_id: 'ParentAccountId',
	customer_approved_remote_hrs: 'CustomerApprovedRemote',
	legacy_client_id: 'LegacyClientId',
	client_id: 'ClientId',
	assigned_entity: 'AssignedEntity',
	total_downtime_hrs: 'TotalDownTime',
	project_training_remaining_hrs: 'ProjectTrainingRemaining',
	contract_billable_hrs: 'ContractBillale',
	days_overdue_hrs: 'DaysOverdue',
	previous_name: 'PreviousName',
	client_url: 'ClientUrl',
	workspace_url: 'WorkspaceUrl',
	where_to_onboard_customers: 'WhereToOnboardCustomers',
	billing_address: 'BillingAddress',
	billing_address_address_line_1: 'BillingAddressLine1',
	billing_address_address_line_2: 'BillingAddressLine2',
	billing_address_city: 'BillingCity',
	billing_address_province: 'BillingState',
	billing_address_country: 'BillingCountry',
	billing_address_postal_code: 'BillingPostalCode',
	shipping_address: 'ShippingAddress',
	shipping_address_address_line_1: 'ShippingAddressLine1',
	shipping_address_address_line_2: 'ShippingAddressLine2',
	shipping_address_city: 'ShippingCity',
	shipping_address_province: 'ShippingState',
	shipping_address_country: 'ShippingCountry',
	shipping_address_postal_code: 'ShippingPostalCode',
	account_currency: 'AccountCurrency',
	billable_hours_custom_pricing: 'BillableHoursPricing',
	vpn_monthly_custom_pricing: 'VpnMonthlyPricing',
	training_hours_custom_pricing: 'TrainingHoursPricing',
	vpn_setup_custom_pricing: 'VpnSetupPricing',
	industry: 'Industry',
	sic_description: 'SicDescription',
	sic_code: 'SicCode',
	employees: 'Employees',
	annual_revenue: 'AnnualRevenue',
	ownership: 'Ownership',
	ownership_id: 'OwnershipId',
	_of_radiologists: 'NumberOfRadiologist',
	_of_gateways: 'NumberOfGateway',
	_of_studiesyr: 'NumberOfStudyPerYear',
	_of_powercaches: 'NumberOfPowerCache',
	_of_facilities: 'NumberOfFacility',
	_of_beds: 'NumberOfBed',
	other_location: 'OtherLocation',
	description: 'Description',
	hosting_model: 'HostingModel',
	integration_validation_required: 'IntegrationValidationRequired',
	accountName: 'AccountName',
	phone: 'Phone',
	referenceStatus: 'ReferenceStatus',
	accountType: 'AccountType',
	watcher: 'Watcher',
	clientId: 'ClientId',
	targetEntity: 'TargetEntity',
};

export const accountForm = {
	numericFields: [
		'downtime_hrs',
		'total_downtime_hrs',
		'customer_approved_remote_hrs',
		'project_training_remaining_hrs',
		'contract_billable_hrs',
		'days_overdue_hrs',
		'_of_radiologists',
		'_of_gateways',
		'_of_studiesyr',
		'_of_powercaches',
		'_of_facilities',
		'_of_beds',
		'fax',
		'employees',
		'annual_revenue',
		'billable_hours_custom_pricing',
		'vpn_monthly_custom_pricing',
		'training_hours_custom_pricing',
		'vpn_setup_custom_pricing',
	],
	requiredFields: [
		'account_state',
		'account_owner',
		'account_record_type',
		'local_time',
		'account_status',
		'billing_address_address_line_1',
		'billing_address_city',
		'billing_address_province',
		'billing_address_country',
	],
	selectFields: [
		'account_state',
		'account_record_type',
		'industry',
		'hosting_model',
		'integration_validation_required',
		'local_time',
		'where_to_onboard_customers',
	],
	textFields: [
		'account_owner',
		'account_status',
		'important_account_info',
		'downtime_hrs',
		'fax',
		'customer_approved_remote_hrs',
		'legacy_client_id',
		'assigned_entity',
		'total_downtime_hrs',
		'project_training_remaining_hrs',
		'contract_billable_hrs',
		'days_overdue_hrs',
		'previous_name',
		'client_url',
		'workspace_url',
		'account_currency',
		'billable_hours_custom_pricing',
		'vpn_monthly_custom_pricing',
		'training_hours_custom_pricing',
		'vpn_setup_custom_pricing',
		'sic_description',
		'sic_code',
		'employees',
		'annual_revenue',
		'_of_radiologists',
		'_of_gateways',
		'_of_studiesyr',
		'_of_powercaches',
		'_of_facilities',
		'_of_beds',
		'other_location',
		'description',
	],
	searchableFields: ['parent_account', 'ownership'],
	commonTextFields: [
		{
			labelName: 'Client ID',
			fieldName: 'clientId',
			valueName: 'ClientId',
		},
		{
			labelName: 'Phone',
			fieldName: 'phone',
			valueName: 'Phone',
		},
		{
			labelName: 'Reference Status',
			fieldName: 'referenceStatus',
			valueName: 'ReferenceStatus',
		},
		{
			labelName: 'Type',
			fieldName: 'accountType',
			valueName: 'AccountType',
		},
		{
			labelName: 'Target Entity',
			fieldName: 'targetEntity',
			valueName: 'TargetEntity',
		},
	],
};

export const getCRMSearchScopeList = () => {
	// temporary - this needs to be built out, but for now we just return a static set of routes
	return [
		{
			label: 'All',
			context: crmSearchScopes.all,
		},
	];
};

const CRMSearchScopeContext = createContext({
	loading: false,
	scope: crmSearchScopes.all,
	endpoint: crmScopeMapping[crmSearchScopes.all],
	updateScope: () => {
		console.log('updateScope');
	},
	updateEndpoint: () => {
		console.log('updateEndpoint');
	},
	setLoading: () => {
		console.log('setLoading');
	},
	columnMapping: {},
	crmLoader: {},
});

export const infoMessage = {
	required: 'Required',
	numeric: 'Only Allow numeric',
	clientIdFormat: 'Must have 6 numbers and 1 letter',
};

export const CRMSearchScopeProvider = ({ children }) => {
	const [loading, setLoading] = useState(false);
	const __config = useConfig();
	const [caseGlobalList, setCaseGlobalList] = useState([]);
	const [accountGlobalList, setAccountGlobalList] = useState([]);
	const [isFetchData, setFetchData] = useState(false);
	const [scope, setScope] = useState(crmSearchScopes.all);
	const [endpoint, setEndpoint] = useState(crmScopeMapping[crmSearchScopes.all]);

	const defaultLoader = useMemo(
		() =>
			new CRMDataLoader({
				config: __config,
				scopeContext: {
					scope: scope,
					endpoint: endpoint,
				},
			}),
		[]
	);
	const [scopeLoader, setScopeLoader] = useState(defaultLoader);

	const updateEndpointByScope = searchScope => {
		if (crmScopeMapping[searchScope]) {
			setEndpoint(crmScopeMapping[searchScope]);
		} else {
			setScope(crmSearchScopes.all);
			setEndpoint(crmScopeMapping[crmSearchScopes.all]);
		}
	};

	const updateScopeByEndpoint = useCallback(newEndpoint => {
		let endpointToScope = _.zipObject(_.values(crmScopeMapping), _.keys(crmScopeMapping));
		if (endpointToScope[newEndpoint]) {
			setScope(endpointToScope[newEndpoint]);
		} else {
			setEndpoint(searchScopes.all);
			setScope(endpointToScope[crmSearchScopes.all]);
		}
	}, []);

	useEffect(() => {
		if (!!scope && !!endpoint) {
			setScopeLoader(
				scope === crmSearchScopes.all
					? new CRMDataLoader({
							config: __config,
							scopeContext: {
								scope: scope,
								endpoint: '/',
							},
					  })
					: new CRMDataLoader({
							config: __config,
							scopeContext: {
								scope: scope,
								endpoint: endpoint,
							},
					  })
			);
		}
	}, [endpoint, scope]);

	const updateScope = useCallback(key => {
		if (!!key) {
			setScope(key);
		}
	});

	const updateEndpoint = useCallback(newEndpoint => {
		if (!!newEndpoint) {
			updateScopeByEndpoint(newEndpoint);
		}
	});

	useEffect(() => {
		if (!!scope) {
			updateEndpointByScope(scope);
		}
	}, [scope]);

	const validateCaseForm = (overViewData, setErrMsgs, setIsValidForm, editMode) => {
		let tempError = {};
		let isValid = true;
		if ((!editMode && !overViewData['Type']) || (editMode && overViewData['Type'] === '')) {
			tempError['type'] = infoMessage.required;
			isValid = false;
		}
		if ((!editMode && !overViewData['Status']) || (editMode && overViewData['Status'] === '')) {
			tempError['status'] = infoMessage.required;
			isValid = false;
		}
		if ((!editMode && !overViewData['Subject']) || (editMode && overViewData['Subject'] === '')) {
			tempError['subject'] = infoMessage.required;
			isValid = false;
		}
		if ((!editMode && !overViewData['Description']) || (editMode && overViewData['Description'] === '')) {
			tempError['description'] = infoMessage.required;
			isValid = false;
		}
		setErrMsgs(tempError);
		setIsValidForm(isValid);

		return isValid;
	};

	const validateAccountForm = (commonData, overViewData, setErrMsgs, setIsValidForm, editMode) => {
		let tempError = {};
		let isValid = true;
		const numericRegex = /^\d+$/;
		const clientIdRegex = /^\d{6}[a-zA-Z]$/;

		//Check required fields
		if ((!editMode && !commonData.accountName?.value) || (editMode && commonData.accountName?.value === '')) {
			tempError['accountName'] = infoMessage.required;
			isValid = false;
		}

		if ((!editMode && !commonData.clientId?.value) || (editMode && commonData.clientId?.value === '')) {
			tempError['clientId'] = infoMessage.required;
			isValid = false;
		}

		accountForm.requiredFields.forEach(value => {
			if (
				(!editMode && !overViewData[accountMapping[value]]) ||
				(editMode && overViewData[accountMapping[value]] === '')
			) {
				tempError[value] = infoMessage.required;
				isValid = false;
			}
		});

		//Check numeric fields
		if (!!commonData.phone?.value && !numericRegex.test(commonData.phone.value)) {
			tempError['phone'] = infoMessage.numeric;
			isValid = false;
		}

		accountForm.numericFields.forEach(value => {
			if (overViewData[accountMapping[value]] && !numericRegex.test(overViewData[accountMapping[value]])) {
				tempError[value] = infoMessage.numeric;
				isValid = false;
			}
		});

		//Check format field
		if (commonData.clientId?.value && !clientIdRegex.test(commonData.clientId?.value)) {
			tempError['clientId'] = infoMessage.clientIdFormat;
			isValid = false;
		}

		setErrMsgs(tempError);
		setIsValidForm(isValid);

		return isValid;
	};

	const handleOverViewTabChange = (name, value, overViewData, setOverViewData) => {
		let data = overViewData;
		data[caseMapping[name]] = value.trim();
		setOverViewData(data);
	};

	return (
		<CRMSearchScopeContext.Provider
			value={{
				loading,
				scope: scope,
				endpoint: endpoint,
				updateScope: updateScope,
				updateEndpoint: updateEndpoint,
				setLoading,
				crmLoader: scopeLoader,
				isFetchData,
				setFetchData,
				validateCaseForm,
				handleOverViewTabChange,
				validateAccountForm,
				caseGlobalList,
				setCaseGlobalList,
				accountGlobalList,
				setAccountGlobalList,
			}}
		>
			{children}
		</CRMSearchScopeContext.Provider>
	);
};

export const useCRMSearchScope = () => useContext(CRMSearchScopeContext);
export default CRMSearchScopeProvider;
