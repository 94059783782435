import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import FhirDataLoader from '../services/FhirDataLoader';
import { useConfig, useSearchScope, scopeMapping, useAuth } from '..';
import _ from 'lodash';

const DEFAULT_HTTP_OPTIONS = {
	timeout: 30000,
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
		'Access-Control-Allow-Origin': '*',
	},
	transformResponse: [data => data],
};

export const useFhirDataLoader = ({ scope, httpOptions, endpoint, isGlobal } = {}) => {
	const authRef = useRef();
	const __config = useConfig();
	const { authorized } = useAuth();
	const __searchScopeContext = useSearchScope();
	const [searchScope, setSearchScope] = useState(scope);
	authRef.current = authorized;

	const [loader, setLoader] = useState(() => {
		if (!!endpoint) {
			return new FhirDataLoader({
				config: __config,
				scopeContext: {
					endpoint: endpoint,
				},
				httpOptions: httpOptions,
				isGlobal: isGlobal,
			});
		} else if (!!scope) {
			return new FhirDataLoader({
				config: __config,
				scopeContext: {
					scope: scope,
					endpoint: scopeMapping[scope],
				},
				httpOptions: httpOptions,
				isGlobal: isGlobal,
			});
		} else {
			return new FhirDataLoader({
				config: __config,
				scopeContext: {
					scope: __searchScopeContext.scope,
					endpoint: __searchScopeContext.endpoint,
				},
				httpOptions: httpOptions,
				isGlobal: isGlobal,
			});
		}
	});

	const SUPPORTED_OPS = useMemo(
		() => _.filter(Object.getOwnPropertyNames(loader), elem => _.startsWith(elem, '__op__')),
		[loader]
	);
	const getNewLoader = useCallback(
		newScope => {
			if (!!newScope && !!scopeMapping[scope]) {
				return new FhirDataLoader({
					config: __config,
					scopeContext: {
						scope: newScope,
						endpoint: scopeMapping[scope],
					},
					httpOptions: httpOptions,
					isGlobal: isGlobal,
				});
			} else {
				return new FhirDataLoader({
					config: __config,
					scopeContext: __searchScopeContext,
					httpOptions: httpOptions,
					isGlobal: isGlobal,
				});
			}
		},
		[scope, httpOptions]
	);

	const loaderObject = useMemo(
		() =>
			_.fromPairs(
				_.map(SUPPORTED_OPS, op => {
					let newFunc = _.wrap(loader[op], async (func, ...args) => {
						if (authorized) {
							return func(...args);
						}
					});
					return [_.replace(op, /__op__/gi, ''), newFunc];
				})
			),
		[authorized, loader]
	);

	useEffect(() => {
		if (!_.isEmpty(scope) && !_.isEmpty(searchScope) && scope !== searchScope) {
			setSearchScope(scope);
		}
	}, [scope]);

	useEffect(() => {
		if (!!httpOptions) {
			// merge ---------> this way
			return _.merge(DEFAULT_HTTP_OPTIONS, __config.fhir, httpOptions);
		}
	}, [httpOptions]);

	useEffect(() => {
		if (authorized && !!searchScope && !!scopeMapping[searchScope]) {
			setLoader(getNewLoader(searchScope));
		}
	}, [searchScope, __searchScopeContext, __config]);

	return loaderObject;
};

export default useFhirDataLoader;
