// core
import React, { useRef, useState, useMemo, useEffect } from 'react';

// libraries
import { Controller } from 'react-hook-form';
import _ from 'lodash';

// MUI
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

const FormMultiSelectVariant = ({ formHook, name, options, props }) => {
	const { control } = formHook;
	const ref = useRef();
	const [autoWidth, setAutoWidth] = useState(0);

	const menuProps = useMemo(
		() => ({
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'right',
			},
			transformOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
			TransitionComponent: Fade,
			transitionDuration: 200,
			PaperProps: {
				variant: 'outlined',
				elevation: 0,
				...props?.MenuProps?.PaperProps,
				...(props?.InputProps?.startAdornment
					? {
							sx: {
								// -2 to account for border
								width: autoWidth - 2,
								...props?.MenuProps?.PaperProps.sx,
							},
					  }
					: undefined),
			},
			...props?.MenuProps,
		}),
		[autoWidth]
	);
	const dataCy = props.SelectProps?.hasOwnProperty('data-cy') ? props.SelectProps['data-cy'] : null;

	useEffect(() => {
		const width = ref.current?.node?.offsetWidth ?? ref.current?.offsetWidth;
		setAutoWidth(width);
	}, [ref, ref.current, ref.current?.node, ref.current?.node?.offsetWidth]);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field }) => {
				const [itemName, setItemName] = useState(
					field?.value && field.value.length > 0
						? props?.SelectProps?.parseValueFromOptions && options
							? options.filter(option => field?.value?.includes(option?.display))
							: field.value
						: []
				);

				const menuItemList = useMemo(
					() =>
						_.map(options, item =>
							props?.SelectProps?.customRenderMenuItem ? (
								props?.SelectProps?.customRenderMenuItem(item, itemName)
							) : (
								<MenuItem
									key={_.camelCase(item.id)}
									name={_.camelCase(item.display)}
									sx={{ px: '6px' }}
									value={item}
								>
									<Checkbox
										checked={_.findIndex(itemName, elem => elem?.id === item.id) > -1}
										sx={{ py: '6px' }}
									/>
									<ListItemText primary={item.display} />
								</MenuItem>
							)
						),
					[itemName, options]
				);

				return (
					<FormControl sx={{ ...props?.sx }}>
						<InputLabel shrink id="multiple-name-label">
							{props?.label}
						</InputLabel>
						<Select
							ref={ref}
							multiple
							notched
							MenuProps={menuProps}
							data-cy={dataCy}
							error={props?.error}
							id="multiple-checkbox"
							label={props?.label}
							labelId="multiple-name-label"
							renderValue={selectedValues =>
								props?.SelectProps?.customRenderValue ? (
									props?.SelectProps?.customRenderValue(selectedValues, setItemName, field.onChange)
								) : selectedValues && selectedValues.length ? (
									_.join(
										_.map(selectedValues, value => value?.display || value),
										' | '
									)
								) : (
									<label style={{ opacity: 0.5 }}>{props?.label}</label>
								)
							}
							size="medium"
							value={itemName}
							onChange={event => {
								const changedValues = event.target.value;
								const duplicateIds = changedValues
									.filter((item, index, arr) => arr.findIndex(t => t.id === item.id) !== index)
									.map(item => item.id);
								const uniqueItems = changedValues.filter(item => !duplicateIds.includes(item.id));

								setItemName(uniqueItems);

								field.onChange(uniqueItems);
							}}
							onMouseDown={event => {
								event.stopPropagation();
							}}
							{...props?.InputProps}
						>
							{menuItemList}
						</Select>
						{props?.helperText && (
							<FormHelperText error={props?.helperText}>{props?.helperText}</FormHelperText>
						)}
					</FormControl>
				);
			}}
		/>
	);
};

export default FormMultiSelectVariant;
