import React from 'react';

import { CalloutBlockIcon } from '../../../../../assets/icons';

import styles from './CalloutBlock.module.scss';

const CalloutBlock = ({ theme, block, setDisplayMode, isSelected }) => (
	<div
		className={`${styles.calloutBlock} ${theme === 'OAI' ? styles.calloutBlockOAI : styles.calloutBlockBlume} ${
			isSelected ? styles.calloutBlockSelected : ''
		}`}
		data-testid="calloutBlock"
		onMouseOver={() => setDisplayMode && setDisplayMode({ type: 'hover' })}
	>
		<div className={styles.calloutIcon}>
			<CalloutBlockIcon />
		</div>
		<div className={styles.calloutText}>
			<span className={theme === 'OAI' ? styles.calloutOAI : styles.calloutBlume} data-testid="callout">
				{block.text}
			</span>
		</div>
	</div>
);

export default CalloutBlock;
