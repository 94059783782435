import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import React from 'react';
import { OKRCard, OKRSpan } from '../../../views/CrmView/styles';
import target from '../../../../../ui/src/assets/icons/target.svg';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

const OKRListItem = ({ data, owner }) => {
	const initials = () => {
		return owner
			?.split(' ')
			.map(n => n.charAt(0).toUpperCase())
			.join('');
	};

	return (
		<OKRCard>
			<Box sx={{ flex: 1, height: '50px' }}>
				<Box sx={{ mb: '13px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<Icon component={target} sx={{ mr: '5px' }} />
						<Typography
							sx={{ color: '#4D79EA', fontSize: '16px', fontWeight: '600', fontFamily: 'Inter' }}
							component={'h3'}
						>
							{'P' + data.OrderNum}
						</Typography>
					</Box>
					<Typography
						component={'span'}
						sx={{
							color: '#4D79EA',
							fontFamily: 'Inter',
							fontStyle: 'italic',
							fontSize: '12px',
							fontWeight: '600',
						}}
					>
						{data.Status}
					</Typography>
				</Box>
				<OKRSpan>{data.Objective}</OKRSpan>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'row' }}>
				<Avatar
					sx={{
						width: '28px',
						height: '28px',
						fontSize: '14px',
						background: 'rgba(77, 121, 234, 0.2)',
						color: '#4D79EA',
					}}
				>
					{initials()}
				</Avatar>
			</Box>
		</OKRCard>
	);
};

export default OKRListItem;
