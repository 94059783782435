import React from 'react';
import { Typography } from '@mui/material';
import UserAvatar from './UserAvatar';

function VoiceQueueTab() {
	return (
		<div
			style={{
				borderBottom: '1px solid #C4C4C466',
			}}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					width: '503px',
					margin: '0 auto',
					height: '67px',
				}}
			>
				<UserAvatar size={38} userName="Martha Stewart" />
				<div
					style={{
						width: '207px',
						paddingLeft: '17px',
					}}
				>
					<Typography
						style={{
							fontFamily: 'Inter',
							fontWeight: '500',
							fontSize: '18px',
							width: '100%',
							lineHeight: '21px',
							color: '#000',
						}}
					>
						Martha Stewart
					</Typography>
					<Typography
						style={{
							fontFamily: 'Inter',
							fontWeight: '500',
							fontSize: '16px',
							color: '#00000099',
							lineHeight: '19px',
						}}
					>
						555-098-2600
					</Typography>
				</div>
				<Typography
					sx={{
						width: '76px',
						backgroundColor: '#4D79EA22',
						borderRadius: '5px',
						color: '#4D79EA',
						fontFamily: 'Inter',
						fontWeight: '500',
						fontSize: '16px',
						textAlign: 'center',
						margin: '0 14px',
					}}
				>
					1:01:06
				</Typography>
				<Typography
					sx={{
						width: '126px',
						borderRadius: '5px',
						color: '#00000066',
						fontFamily: 'Inter',
						fontWeight: '500',
						fontSize: '16px',
						textAlign: 'right',
					}}
				>
					{'On hold < 1m'}
				</Typography>
			</div>
		</div>
	);
}

export default VoiceQueueTab;
