import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import prettyBytes from 'pretty-bytes';
import { useDropzone } from 'react-dropzone';

import { IconButton, Tooltip } from '@mui/material';

import { useConfig } from '@worklist-2/core/src';

import {
	PlayCircle,
	ImageOutlined,
	TheatersOutlined,
	LanguageOutlined,
	CalendarViewDayOutlined,
	ArrowBackOutlined,
	CreateNewFolderOutlined,
	FileUploadOutlined,
} from '@mui/icons-material';

import Folder from '../../assets/icons/AttachmentBar/Folder';

import styles from './AttachmentBar.module.scss';

const ATTACHMENTS = [
	{ title: 'Image', icon: <ImageOutlined /> },
	{ title: 'Video', icon: <TheatersOutlined /> },
	{ title: 'Link', icon: <LanguageOutlined /> },
	{ title: 'Cross Reference', icon: <CalendarViewDayOutlined /> },
];

const AttachmentBar = ({ theme, activeCategory, attachType, setAttachType, setSelectedAttachment }) => (
	<div className={`${styles.blockBar} ${theme === 'OAI' ? styles.blockBarOAI : styles.blockBarBlume}`}>
		{attachType === 'Image' || attachType === 'Video' ? (
			<UploadAttachment
				activeCategory={activeCategory}
				attachType={attachType}
				setSelectedAttachment={setSelectedAttachment}
				theme={theme}
				onClose={() => setAttachType(null)}
			/>
		) : (
			<div className={styles.blockList}>
				<h3>Attach</h3>
				<div className={styles.blockListGrid}>
					{ATTACHMENTS.map(item => (
						<div
							key={item.title}
							className={`${styles.block} ${theme === 'OAI' ? styles.blockOAI : styles.blockBlume}`}
							onClick={() => setAttachType(item.title)}
						>
							{item.icon}
							<span>{item.title}</span>
						</div>
					))}
				</div>
			</div>
		)}
	</div>
);

const UploadAttachment = ({ theme, attachType, onClose, activeCategory, setSelectedAttachment }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [path, setPath] = useState('');
	const [currentFolder, setCurrentFolder] = useState(null);
	const [folders, setFolders] = useState([]);
	const [attachments, setAttachments] = useState([]);
	const inputRef = useRef();
	const __config = useConfig();

	useEffect(() => {
		setPath(`${activeCategory.category.App}|${activeCategory.category.DocumentCategoryId}`);
		setFolders(activeCategory.category?.SubCategory || []);
		setCurrentFolder(null);
	}, [activeCategory]);

	useEffect(() => {
		getAttachments();
	}, [path, currentFolder]);

	const addFolder = name => {
		const payload = activeCategory.category;

		payload.SubCategory = payload.SubCategory
			? [...payload.SubCategory, { SubCategoryName: `${name} ${payload.SubCategory.length + 1}` }]
			: [{ SubCategoryName: `${name} 1` }];

		axios
			.put(
				`${__config.data_sources.breeze}/documentcategory/${activeCategory.category.DocumentCategoryId}`,
				payload
			)
			.then(resp => {
				if (resp.status === 200) {
					setFolders(resp.data.SubCategory);
				}
			})
			.catch(error => console.error(error));
	};

	const getAttachments = async () => {
		if (!path) {
			return;
		}

		setIsLoading(true);
		await axios
			.get(
				`${__config.data_sources.breeze}/documentattachment?documentpath:exact=${
					path + (currentFolder ? `|${currentFolder.SubCategoryId}` : '')
				}`
			)
			.then(resp => {
				if (resp.status === 200) {
					setAttachments(resp.data.entry.map(item => item.resource));
				}
			})
			.catch(error => console.error(error));
		setIsLoading(false);
	};

	const arrayBufferToBase64 = buffer => {
		const { Buffer } = require('buffer');
		return Buffer.from(buffer).toString('base64');
	};

	const sendFiles = async files => {
		if (files.length === 0) {
			return;
		}

		axios
			.post(`${__config.data_sources.breeze}/DocumentAttachment`, JSON.stringify(files[0]), {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then(resp => {
				if (resp.status === 201) {
					const newState = [...attachments];
					newState.push(resp.data);
					setAttachments(newState);
				}
			})
			.catch(error => console.error(error));
	};

	const handleFileChange = async e => {
		try {
			const files = e.target.files || [];
			const promises = [...files].map(async file => {
				const fileNameArr = file.name.split('.');
				const fileInfo = {
					Title: fileNameArr[0],
					Type: fileNameArr[fileNameArr.length - 1],
					FileName: file.name,
					ContentType: file.type,
					FileSize: prettyBytes(file.size),
					CreatedAt: new Date(file.lastModified).toLocaleDateString('en-US'),
					ResourceType: 'DocumentAttachment',
					Path: path + (currentFolder ? `|${currentFolder.SubCategoryId}` : ''),
				};

				const fileReader = new FileReader();

				fileReader.readAsArrayBuffer(file);

				fileInfo.Data = await file.arrayBuffer().then(res => arrayBufferToBase64(res));
				return fileInfo;
			});
			const fileImport = await Promise.all(promises);

			sendFiles(fileImport);
		} catch (error) {
			console.error(error);
		}
	};

	const handleUploadClick = () => {
		inputRef.current?.click();
	};

	const handleBackButtonClick = () => {
		if (currentFolder) {
			setCurrentFolder(null);
		} else {
			onClose();
		}
	};

	const attachmentsFilter = data =>
		data.reduce((acc, item) => {
			if (attachType === 'Image' && item.ContentType.includes('image/')) {
				return [...acc, item];
			}
			if (attachType !== 'Image' && !item.ContentType.includes('image/')) {
				return [...acc, item];
			}
			return acc;
		}, []);

	const onDrop = acceptedFiles => {
		const filteredFiles = acceptedFiles.filter(
			file =>
				(attachType === 'Image' && file.type.includes('image/')) ||
				(attachType === 'Video' && file.type.includes('video/'))
		);
		handleFileChange({ target: { files: filteredFiles } });
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true, noKeyboard: true });

	return (
		<div className={styles.uploadAttachment} {...getRootProps()}>
			<div className={styles.uploadAttachmentHeader}>
				<div className={`${styles.left} ${theme === 'OAI' ? styles.colorOAI : styles.lightColorBlume}`}>
					<IconButton sx={{ padding: 0, color: 'currentColor' }} onClick={handleBackButtonClick}>
						<ArrowBackOutlined />
					</IconButton>
					<h3>{currentFolder?.SubCategoryName || 'Uploads'}</h3>
				</div>
				<div className={styles.right}>
					{!currentFolder && (
						<IconButton
							className={styles.btn}
							data-testid="create-folder-btn"
							sx={{ marginRight: '10px', padding: 0, color: 'currentColor' }}
							onClick={() => addFolder(activeCategory.category.CategoryName)}
						>
							<CreateNewFolderOutlined />
						</IconButton>
					)}
					<Tooltip
						arrow
						componentsProps={{
							tooltip: {
								sx: {
									padding: '8px 16px',
									fontFamily: 'Inter',
									fontSize: '14px',
									bgcolor: '#4D79EA',
									'& .MuiTooltip-arrow': {
										color: '#4D79EA',
									},
								},
							},
						}}
						title="Upload"
					>
						<IconButton
							className={styles.btn}
							data-testid="upload-btn"
							sx={{ padding: 0, color: 'currentColor' }}
							onClick={() => handleUploadClick()}
						>
							<FileUploadOutlined />
						</IconButton>
					</Tooltip>
				</div>
			</div>
			{!isLoading && (
				<>
					<div className={styles.foldersList}>
						{!currentFolder &&
							folders.map(item => (
								<div
									key={item.SubCategoryId}
									className={styles.folder}
									onClick={() => setCurrentFolder(item)}
								>
									<div className={styles.folderWrapper}>
										<Folder />
										<span className={styles.folderCount} />
									</div>
									<span className={theme === 'OAI' ? styles.colorOAI : styles.colorBlume}>
										{item.SubCategoryName}
									</span>
								</div>
							))}
					</div>
					<div className={`${styles.filesList} invisible-scrollbar`}>
						{attachmentsFilter(attachments).map(item => (
							<div
								key={item.DocumentAttachmentId}
								className={`${styles.fileWrapper} ${
									theme === 'OAI' ? styles.fileWrapperOAI : styles.fileWrapperBlume
								}`}
								onClick={() =>
									setSelectedAttachment({ type: `Attachment ${attachType}`, path: item.Url })
								}
							>
								{attachType === 'Image' ? (
									<img className={styles.fileImg} src={item.Url} />
								) : (
									<PlayCircle sx={{ fontSize: 40, color: '#4D79EA' }} />
								)}
								<div className={styles.fileTitleWrapper}>
									<span
										className={`${styles.fileTitle} ${
											theme === 'OAI' ? styles.colorOAI : styles.colorBlume
										}`}
									>
										{item.Title}
									</span>
									<span
										className={`${styles.fileSubtitle} ${
											theme === 'OAI' ? styles.colorOAI : styles.lightColorBlume
										}`}
									>
										{`${item.CreatedAt} • ${item.FileSize} • ${item.Type}`}
									</span>
								</div>
							</div>
						))}
					</div>
				</>
			)}
			{isDragActive && (
				<div className={styles.uploadSectionContent}>
					<FileUploadOutlined sx={{ fontSize: '44px' }} />
					<span className={theme === 'OAI' ? styles.colorOAI : styles.colorBlume}>Drop Here to Upload</span>
				</div>
			)}

			<input {...getInputProps()} />
			<input
				ref={inputRef}
				accept={attachType === 'Image' ? 'image/*' : 'video/*'}
				data-testid="input-file-upload"
				style={{ display: 'none' }}
				type="file"
				onChange={handleFileChange}
			/>
		</div>
	);
};

export default AttachmentBar;
