import React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

import { useAppModeContext } from '@worklist-2/core/src/context/AppModeContext';

const Toast = ({
	open,
	message,
	children,
	duration,
	anchorOrigin,
	customStyle,
	onClose,
	onUndo,
	closeType,
	severity,
}) => {
	const appMode = useAppModeContext();
	let toastAnchorOrigin = appMode.isMarketplaceMode ? { vertical: 'bottom', horizontal: 'left' } : anchorOrigin;

	if (anchorOrigin) {
		toastAnchorOrigin = anchorOrigin;
	}
	const action = (
		<>
			{onUndo ? (
				<Button
					size="small"
					sx={{
						color: 'rsPrimary.main',
						marginTop: '2px',

						'&:hover': {
							backgroundColor: 'rgba(255, 255, 255, 0.08)',
						},
					}}
					variant="text"
					onClick={onUndo}
				>
					UNDO
				</Button>
			) : null}
			<IconButton
				aria-label="close"
				color="inherit"
				size="small"
				sx={{
					color: 'rsPrimary.main',
				}}
				onClick={onClose}
			>
				{closeType || <CloseIcon fontSize="small" />}
			</IconButton>
		</>
	);
	const snackbarStyle = {
		'.MuiSnackbarContent-message': {
			color: 'text.primary',
		},
		'.MuiSnackbarContent-root': {
			backgroundColor: 'rsSecondary.medium',
			border: '1px solid rgba(255, 255, 255, 0.2)',
			borderRadius: '6px',
			minWidth: '100px',
		},
	};
	const values = Object.values(toastAnchorOrigin);
	const snackBarBottomLeftStyle =
		values.includes('bottom') && values.includes('left')
			? {
					left: '96px !important',
					bottom: '0px !important',
			  }
			: {};

	return (
		<Snackbar
			TransitionComponent={Slide}
			action={action}
			anchorOrigin={toastAnchorOrigin}
			autoHideDuration={duration}
			message={message}
			open={open}
			style={customStyle}
			sx={{
				...snackbarStyle,
				...snackBarBottomLeftStyle,
			}}
			onClose={onClose}
		>
			{/* <Alert
				icon={
					severity === 'success' ? (
						<CheckIcon fontSize="inherit" />
					) : (
						false
					)
				}
				severity="info"
				sx={{
					borderRadius: '10px',
					background: 'rsSecondary.dark',
					boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
					color: 'text.faint',
				}}
			>
				<>
					{message}
					{children}
				</>
			</Alert> */}
		</Snackbar>
	);
};

Toast.propTypes = {
	/**
	 * Determines whether the toast should be open or not
	 */
	open: PropTypes.bool,

	/**
	 * The message to be displayed in the toast component
	 */
	message: PropTypes.string,

	/**
	 * Children to render inside the drawer
	 */
	children: PropTypes.node,
	/**
	 * The number of milliseconds to wait before automatically calling the `onClose` function
	 */
	duration: PropTypes.number,
	/**
	 * The anchor of the `Snackbar`
	 */
	anchorOrigin: PropTypes.object,
	/**
	 * The custom style for Snackbar
	 */
	customStyle: PropTypes.object,

	/**
	 * Callback handler for handling closing
	 */
	onClose: PropTypes.func,
	/**
	 * Custom callback for handling the UNDO feature (will be shown if this callback is defined)
	 */
	onUndo: PropTypes.func,

	/**
	 * The severity to display icon on tost message
	 * Possible values :  success, failed
	 */
	severity: PropTypes.string,
};

Toast.defaultProps = {
	open: false,
	message: '',
	duration: 5000,
	children: null,
	anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
	severity: '',
};

export default Toast;
