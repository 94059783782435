import extensionUrls from '../../extension/fhirExtensionUrls';
import getUrlLabel from '@worklist-2/worklist/src/DocumentViewerV3/utils/getUrlLabel';
const PractitionerMapping = {
	organization: {
		getDisplayByKey: (resource, key) => {
			const orgObj = resource?.extension?.find(item => {
				if (item.url === extensionUrls.common.organization && item.valueReference?.id === key) {
					return item;
				}
			});
			return orgObj?.valueReference?.display || '';
		},
		getDisplay: resource => {
			const orgObj = resource?.extension?.find(item => {
				if (item.url === extensionUrls.common.organization) {
					return item;
				}
			});
			return orgObj?.valueReference?.display || '';
		},
	},
	physicianTitle: {
		getDisplay: resource => {
			const practitionerAttributes = getUrlLabel()?.practitioner;
			const userTypeExtensionObj = resource?.extension?.find(
				item => item.url === extensionUrls.practitioner.userType
			);
			const userTypeObj = userTypeExtensionObj?.extension?.find(obj => obj.valueBoolean);
			return practitionerAttributes?.getLabel(userTypeObj?.url || '');
		},
	},
};
export default PractitionerMapping;
