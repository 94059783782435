import React from 'react';
import PropTypes from 'prop-types';
import MuiAvatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';

const StyledAvatar = styled(MuiAvatar, {
	shouldForwardProp: prop =>
		prop !== 'backgroundColor' && prop !== 'fontSize' && prop !== 'height' && prop !== 'width',
})(({ theme, fontSize, backgroundColor, height, width }) => ({
	backgroundColor,
	fontSize,
	color: theme.palette.text.primary,
	height,
	width,
}));

const MAX_LENGTH = 2;

const generateInitials = (label, showEllipsis) => {
	let initials = '';
	if (!label) return initials;

	const items = label
		.split(' ')
		.flatMap(substring => substring.split('-'))
		.filter(Boolean);

	items.slice(0, MAX_LENGTH).forEach(item => {
		initials += item[0];
	});

	if (items.length > MAX_LENGTH && showEllipsis) {
		initials += '...';
	}

	return initials.toUpperCase();
};

const Avatar = ({
	label,
	iconText,
	transparent,
	fontSize,
	backgroundColor,
	testId,
	height,
	width,
	showEllipsis,
	color,
	sxProps,
}) => {
	let bgColor;
	if (transparent) {
		bgColor = 'transparent';
	} else {
		bgColor = backgroundColor || 'rsSecondary.medium';
	}

	return (
		<StyledAvatar
			backgroundColor={bgColor}
			data-selected-wl={testId}
			data-testid={`Avatar ${label}`}
			fontSize={fontSize}
			height={height}
			sx={{
				color: `${color} !important`,
				...sxProps,
			}}
			width={width}
		>
			{iconText || generateInitials(label, showEllipsis)}
		</StyledAvatar>
	);
};

Avatar.propTypes = {
	/**
	 * Font size of the avatar
	 */
	fontSize: PropTypes.number,
	/**
	 * Label used to generate the avatar
	 */
	label: PropTypes.string,

	/**
	 * Character used to show the avatar
	 */
	iconText: PropTypes.string,

	/**
	 * Controls whether or not the avatar should be transparent. This option will override the backgroundColor props if provided.
	 */
	transparent: PropTypes.bool,

	/**
	 * Background color for the avatar
	 */
	backgroundColor: PropTypes.string,

	/**
	 * Height of the avatar.
	 */
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	/**
	 * Width of the avatar.
	 */
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	/**
	 * Will show the ellipsis if the words are longer than MAX_LENGTH.
	 */
	showEllipsis: PropTypes.bool,
};

Avatar.defaultProps = {
	fontSize: 14,
	label: '',
	iconText: null,
	transparent: true,
	backgroundColor: '',
	height: 100,
	width: 100,
	showEllipsis: true,
};

export { generateInitials };
export default Avatar;
