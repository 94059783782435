import React, { useState } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import ReviewProgressPopover from './ReviewProgressPopover';

const RequestItem = ({ isOmega, name, jobTitle, dates }) => {
	const [isShowPopover, setIsShowPopover] = useState(false);

	return (
		<Box
			sx={{
				position: 'relative',
				width: '100%',
				marginBottom: '10px',
				padding: '14px',
				display: 'flex',
				alignItems: 'center',
				backgroundColor: isOmega ? 'rgba(255, 255, 255, 0.10)' : '#F9FAFF',
				borderRadius: '10px',
			}}
		>
			<Avatar
				sx={{
					width: '50px',
					height: '50px',
					fontFamily: 'Inter',
					fontSize: '16px',
					fontWeight: 500,
					color: isOmega ? '#FFF' : '#4D79EA',
					backgroundColor: isOmega ? 'rgba(77, 121, 234, 0.60)' : 'rgba(77, 121, 234, 0.20)',
					borderRadius: '5px',
				}}
			>
				{`${name.split(' ')[0][0]}${name.split(' ')[1][0]}`}
			</Avatar>
			<Box sx={{ marginLeft: '14px' }}>
				<Typography
					sx={{
						fontFamily: 'Inter',
						fontSize: '14px',
						fontWeight: 500,
						color: '#4D79EA',
					}}
				>
					{name}
				</Typography>
				<Typography
					sx={{
						height: '18px',
						fontFamily: 'Inter',
						fontSize: '12px',
						color: isOmega ? '#FFF' : 'rgba(0, 0, 0, 0.60)',
					}}
				>
					{jobTitle}
				</Typography>
			</Box>
			<HistoryIcon
				data-testid="review_progress_icon"
				sx={{
					position: 'absolute',
					top: '4px',
					right: '4px',
					fontSize: '20px',
					color: '#4D79EA',
				}}
				onMouseLeave={() => setIsShowPopover(false)}
				onMouseOver={() => setIsShowPopover(true)}
			/>
			<ReviewProgressPopover dates={dates} isOmega={isOmega} isOpen={isShowPopover} />
		</Box>
	);
};

export default RequestItem;
