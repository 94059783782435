import { LOGIN_SUCCESS } from '@worklist-2/patientPortal/src/analytics/eventTypes';
import { logInfo } from '@rs-core/utils/logger';
import mixpanel from 'mixpanel-browser';

const config = JSON.parse(process.env.NODE_CONFIG || '{}');

let defaultParams = {};

function sendAnalyticsEvent(eventName, eventParams) {
	if (!config?.mixpanel) {
		logInfo('Analytics', 'Mixpanel config not found');
		return;
	}
	if (config.mixpanel.includes('MIXPANEL_TOKEN')) {
		logInfo('Mixpanel token not found', config.mixpanel);
		return;
	}
	if (!mixpanel?.identify || !mixpanel?.track) {
		return;
	}
	if (eventName === LOGIN_SUCCESS) {
		defaultParams = eventParams;
		mixpanel.people.set({
			$name: eventParams.name,
			$email: eventParams.email,
			USER_ID: eventParams.id,
			isBlumeSynced: eventParams.isBlumeSynced,
		});
	}
	const payload = { ...defaultParams, ...eventParams };
	if (
		payload?.email.includes('yopmail.com') ||
		payload?.email.includes('mailsac.com') ||
		payload?.email.includes('ramsoftlocal')
	) {
		return;
	}
	mixpanel.identify(payload.id);
	mixpanel.track(eventName, payload);
	logInfo('Analytics event sent:', eventName, payload);
}

export default sendAnalyticsEvent;
