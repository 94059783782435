import React from 'react';
import PropTypes from 'prop-types';
import getIcon from './getIcon';

const Icon = ({ name, ...otherProps }) => getIcon(name, { ...otherProps });

Icon.propTypes = {
	name: PropTypes.string.isRequired,
};

export default Icon;
