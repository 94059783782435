// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".raa7dMtWShD_6pxSyOAh{display:flex;flex-direction:column;justify-content:center;align-items:center;height:100%}.raa7dMtWShD_6pxSyOAh .zQjKiqB_EqJpd9ogmpNm{display:flex;flex-direction:column;justify-content:center;align-items:center;margin-top:40px}.raa7dMtWShD_6pxSyOAh .zQjKiqB_EqJpd9ogmpNm span{font-feature-settings:\"clig\" off,\"liga\" off;font-family:Roboto,serif;font-size:16px;font-style:normal;font-weight:500;line-height:24px;letter-spacing:.15px}.raa7dMtWShD_6pxSyOAh .zQjKiqB_EqJpd9ogmpNm p{text-align:center;font-feature-settings:\"clig\" off,\"liga\" off;font-family:Roboto,serif;font-size:14px;font-style:normal;font-weight:400;line-height:20px;letter-spacing:.25px}.KtvH3kCG4hMNr1lphkxY span{color:#fff}.KtvH3kCG4hMNr1lphkxY p{color:var(--neutral-color-white-87, rgba(255, 255, 255, 0.87))}.YUTPymQz2a3Ql13iCfSA span{color:rgba(18,18,18,.87)}.YUTPymQz2a3Ql13iCfSA p{color:rgba(18,18,18,.6)}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/Layout/EmptyArticle/EmptyArticle.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CAGA,4CACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CAEA,iDACE,2CAAA,CAEA,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,oBAAA,CAGF,8CACE,iBAAA,CACA,2CAAA,CAEA,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,oBAAA,CAMJ,2BACE,UAAA,CAEF,wBACE,8DAAA,CAKF,2BACE,wBAAA,CAEF,wBACE,uBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyArticle": "raa7dMtWShD_6pxSyOAh",
	"emptyArticleLabel": "zQjKiqB_EqJpd9ogmpNm",
	"OAITheme": "KtvH3kCG4hMNr1lphkxY",
	"BlumeTheme": "YUTPymQz2a3Ql13iCfSA"
};
export default ___CSS_LOADER_EXPORT___;
