import dcmjs from 'dcmjs';

const { DicomMetaDictionary } = dcmjs.data;

function toNumber(val) {
	if (Array.isArray(val)) {
		return val.map(v => (v !== undefined ? Number(v) : v));
	}
	return val !== undefined ? Number(val) : val;
}

function getPixelSpacingInformation(instance) {
	const projectionRadiographSOPClassUIDs = [
		'1.2.840.10008.5.1.4.1.1.1', //	CR Image Storage
		'1.2.840.10008.5.1.4.1.1.1.1', //	Digital X-Ray Image Storage – for Presentation
		'1.2.840.10008.5.1.4.1.1.1.1.1', //	Digital X-Ray Image Storage – for Processing
		'1.2.840.10008.5.1.4.1.1.1.2', //	Digital Mammography X-Ray Image Storage – for Presentation
		'1.2.840.10008.5.1.4.1.1.1.2.1', //	Digital Mammography X-Ray Image Storage – for Processing
		'1.2.840.10008.5.1.4.1.1.1.3', //	Digital Intra – oral X-Ray Image Storage – for Presentation
		'1.2.840.10008.5.1.4.1.1.1.3.1', //	Digital Intra – oral X-Ray Image Storage – for Processing
		'1.2.840.10008.5.1.4.1.1.12.1', //	X-Ray Angiographic Image Storage
		'1.2.840.10008.5.1.4.1.1.12.1.1', //	Enhanced XA Image Storage
		'1.2.840.10008.5.1.4.1.1.12.2', //	X-Ray Radiofluoroscopic Image Storage
		'1.2.840.10008.5.1.4.1.1.12.2.1', //	Enhanced XRF Image Storage
		'1.2.840.10008.5.1.4.1.1.12.3', // X-Ray Angiographic Bi-plane Image Storage	Retired
	];

	const {
		PixelSpacing,
		ImagerPixelSpacing,
		SOPClassUID,
		PixelSpacingCalibrationType,
		PixelSpacingCalibrationDescription,
		EstimatedRadiographicMagnificationFactor,
		SequenceOfUltrasoundRegions,
	} = instance;

	const isProjection = projectionRadiographSOPClassUIDs.includes(SOPClassUID);

	const TYPES = {
		NOT_APPLICABLE: 'NOT_APPLICABLE',
		UNKNOWN: 'UNKNOWN',
		CALIBRATED: 'CALIBRATED',
		DETECTOR: 'DETECTOR',
	};

	if (isProjection && !ImagerPixelSpacing) {
		// If only Pixel Spacing is present, and this is a projection radiograph,
		// PixelSpacing should be used, but the user should be informed that
		// what it means is unknown
		return {
			PixelSpacing,
			type: TYPES.UNKNOWN,
			isProjection,
		};
	}
	if (PixelSpacing && ImagerPixelSpacing && PixelSpacing === ImagerPixelSpacing) {
		// If Imager Pixel Spacing and Pixel Spacing are present and they have the same values,
		// then the user should be informed that the measurements are at the detector plane
		return {
			PixelSpacing,
			type: TYPES.DETECTOR,
			isProjection,
		};
	}
	if (PixelSpacing && ImagerPixelSpacing && PixelSpacing !== ImagerPixelSpacing) {
		// If Imager Pixel Spacing and Pixel Spacing are present and they have different values,
		// then the user should be informed that these are "calibrated"
		// (in some unknown manner if Pixel Spacing Calibration Type and/or
		// Pixel Spacing Calibration Description are absent)
		return {
			PixelSpacing,
			type: TYPES.CALIBRATED,
			isProjection,
			PixelSpacingCalibrationType,
			PixelSpacingCalibrationDescription,
		};
	}
	if (!PixelSpacing && ImagerPixelSpacing) {
		let CorrectedImagerPixelSpacing = ImagerPixelSpacing;
		if (EstimatedRadiographicMagnificationFactor) {
			// Note that in IHE Mammo profile compliant displays, the value of Imager Pixel Spacing is required to be corrected by
			// Estimated Radiographic Magnification Factor and the user informed of that.
			CorrectedImagerPixelSpacing = ImagerPixelSpacing.map(
				pixelSpacing => pixelSpacing / EstimatedRadiographicMagnificationFactor
			);
		} else {
			console.log(
				'EstimatedRadiographicMagnificationFactor was not present. Unable to correct ImagerPixelSpacing.'
			);
		}

		return {
			PixelSpacing: CorrectedImagerPixelSpacing,
			isProjection,
		};
	}
	if (SequenceOfUltrasoundRegions && typeof SequenceOfUltrasoundRegions === 'object') {
		const { PhysicalDeltaX, PhysicalDeltaY } = SequenceOfUltrasoundRegions;
		const USPixelSpacing = [PhysicalDeltaX * 10, PhysicalDeltaY * 10];

		return {
			PixelSpacing: USPixelSpacing,
		};
	}
	if (
		SequenceOfUltrasoundRegions &&
		Array.isArray(SequenceOfUltrasoundRegions) &&
		SequenceOfUltrasoundRegions.length > 1
	) {
		console.log(
			'Sequence of Ultrasound Regions > one entry. This is not yet implemented, all measurements will be shown in pixels.'
		);
	} else if (isProjection === false && !ImagerPixelSpacing) {
		// If only Pixel Spacing is present, and this is not a projection radiograph,
		// we can stop here
		return {
			PixelSpacing,
			type: TYPES.NOT_APPLICABLE,
			isProjection,
		};
	}

	console.log('Unknown combination of PixelSpacing and ImagerPixelSpacing identified. Unable to determine spacing.');
}

function omit(keys, obj) {
	if (!keys.length) return obj;
	const { [keys.pop()]: omitted, ...rest } = obj;
	return omit(keys, rest);
}

export default function CheckAndUpdateInstanceMetaData(instanceMetaData) {
	const instanceMetaDataClone = instanceMetaData;

	const SamplesPerPixelTag = '00280002';

	const PhotometricInterpretationTag = '00280004';

	const PlanarConfigurationTag = '00280006';

	const SmallestImagePixelValueTag = '00280106';

	const LargestImagePixelValueTag = '00280107';

	// Check PlanarConfiguration if SamplesPerPixels equal to 3
	const SamplesPerPixels = instanceMetaData[SamplesPerPixelTag]?.Value[0];

	if (SamplesPerPixels === '3') {
		const PlanarConfiguration = instanceMetaData[PlanarConfigurationTag]?.Value[0];
		const PhotometricInterpretatation = instanceMetaData[PhotometricInterpretationTag]?.Value[0];
		// Set Planar Configuration to Plane Per Pixel if PhotometricInterpretation is not 'RGB'
		if (PlanarConfiguration === '1' && PhotometricInterpretatation !== 'RGB') {
			instanceMetaDataClone[PlanarConfigurationTag].Value[0] = '0';
		}
	}

	// Check Smallest and Largest Image Pixel Values
	// Due the problems with those tags in Corner Stone Library
	// See Issue WON-474, we will ignore those not mandatory tags
	// if they are present
	let ignoreSmallestLargestPixelsValues = true;

	const smallestImagePixelValue = parseInt(instanceMetaData[SmallestImagePixelValueTag]?.Value?.[0]);
	const largestImagePixelValue = parseInt(instanceMetaData[LargestImagePixelValueTag]?.Value?.[0]);

	if (isNaN(smallestImagePixelValue) && isNaN(largestImagePixelValue)) {
		ignoreSmallestLargestPixelsValues = false;
	}

	if (ignoreSmallestLargestPixelsValues) {
		if (instanceMetaDataClone[SmallestImagePixelValueTag])
			instanceMetaDataClone[SmallestImagePixelValueTag].Value[0] = undefined;
		if (instanceMetaDataClone[LargestImagePixelValueTag])
			instanceMetaDataClone[LargestImagePixelValueTag].Value[0] = undefined;
	}

	const pixelSpacing = instanceMetaDataClone['00280030'];

	if (!pixelSpacing) {
		let naturalizedDataset = omit(['isMultiframe', 'scalingModule', 'cadsr'], instanceMetaDataClone);
		naturalizedDataset = DicomMetaDictionary.naturalizeDataset(naturalizedDataset);

		const { PixelSpacing } = getPixelSpacingInformation(naturalizedDataset);

		if (PixelSpacing?.length) {
			instanceMetaDataClone['00280030'] = {
				vr: 'DS',
				Value: PixelSpacing.map(item => Math.abs(toNumber(item || 1))),
			};
		}
	}

	return instanceMetaDataClone;
}
