// core and msal
import React, { useState, useEffect, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAuth, useConfig, useAppModeContext } from '@worklist-2/core/src';
import axios from 'axios';
import { InteractionStatus } from '@azure/msal-browser';
// assets
import { ReactComponent as BreezeLogin } from '@worklist-2/ui/src/assets/img/breezeLogin.svg';
// mui
import { Link, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import MuiGrid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import './styles-override.css';
// components
import { MainFooter } from '@worklist-2/ui/src/components/Footer/MainFooter';
import Fingerprint from '../../../../../utils/BrowserFingerPrint/fingerprint';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const LoginBreezeView = () => {
	const [errorMessage, setErrorMessage] = useState('');
	const __config = useConfig();
	const buttonRef = useRef(null);
	const { setLoggingIn } = useAuth();
	const { instance, inProgress } = useMsal();
	const { updateAppMode } = useAppModeContext();
	const metaBreezeSignup = useBooleanFlagValue('meta-breeze-signup');
	const sprinterOaibreezeblumeFooterHelp = useBooleanFlagValue('sprinter-oaibreezeblume-footer-help');
	const sprinterOaibreezeblumeFooterGeography = useBooleanFlagValue('sprinter-oaibreezeblume-footer-geography');

	// if we're here, we're logging in
	useEffect(() => setLoggingIn(true));

	const useHelperTextStyles = makeStyles()(() => ({
		root: {
			color: '#EF5D5D',
			marginLeft: '444px !important',
			position: 'fixed',
			width: '165px',
		},
	}));

	const onCodeChange = event => {
		setErrorMessage('');
	};

	/**
	 * Renders the navbar component with a sign-in button if a user is not authenticated
	 */
	const useQueryParams = () => {
		const params = new URLSearchParams(window ? window.location.search : {});

		return new Proxy(params, {
			get(target, prop) {
				return target.get(prop);
			},
		});
	};
	const { code, error, error_description } = useQueryParams();

	if (code !== null) {
		const tokenRequest = {
			code,
			redirectUri: 'urn:ietf:wg:oauth:2.0:oob',
			scopes: __config.auth.omegaai.scopes,
			codeVerifier: '1234567890123456789012345678901234567890123',
		};
		// acquire a token by exchanging the code
		instance
			.acquireTokenByCode(tokenRequest)
			.then(() => {
				window.location.search = '';
			})
			.catch(e => {
				console.error(e);
			});
	}

	const handleLogin = (event, msalInstance, email) => {
		setErrorMessage('');
		if (!email.value) {
			setErrorMessage('Please enter your email address.');
			event.preventDefault();
		} else if (!email.validity.valid) {
			setErrorMessage(email.validationMessage);
			event.preventDefault();
		} else {
			sessionStorage.setItem('login_hint', email.value);
			const url = `${__config.data_sources.breeze}/User/ValidateUserID?username=${
				email.value
			}&_dc=${new Date().getTime()}`;

			axios
				.get(url)
				.then(response => {
					if (response.data.domainhint) {
						const queryParameters = {
							login_hint: email.value,
							domain_hint: response.data.domainhint,
							deviceId: localStorage.getItem(`${email.value.toLocaleLowerCase()}_deviceId`),
							userAgent: new Fingerprint().get(),
						};

						if (
							__config.data_dog &&
							__config.data_dog.applicationId &&
							!__config.data_dog.applicationId.includes('DATADOG_APPLICATION_ID')
						) {
							queryParameters.ddappid = __config.data_dog.applicationId;
							queryParameters.ddt = __config.data_dog.clientToken;
							queryParameters.ddenv = __config.data_dog.env;
							queryParameters.ddsite = __config.data_dog.site;
						}

						const loginRequest = {
							scopes: __config.auth.omegaai.scopes,
							redirectUri: __config.auth.omegaai.msal.redirect_uri,
						};

						const kmsi = localStorage.getItem('kmsi');
						if (kmsi && kmsi.toUpperCase() == email.value.toUpperCase()) {
							// KMSI enabled for this user.
							queryParameters.iskmsi = true;
						} else {
							loginRequest.prompt = 'select_account';
						}

						loginRequest.extraQueryParameters = queryParameters;

						if (inProgress === InteractionStatus.None) {
							msalInstance.loginRedirect(loginRequest).catch(e => {
								console.error(e);
								setErrorMessage(e);
							});
						}
					} else {
						throw new Error('Invalid domainHint, please try again!');
					}
				})
				.catch(e => {
					console.error(e);
					setErrorMessage(`Breeze account does not exist. Please try again.`);
				});
		}
	};

	const handleSignup = msalInstance => {
		const queryParameters = {
			siteName: 'PP',
			login_uri: __config.auth.omegaai.msal.logout_redirect_uri,
		};
		const loginRequest = {
			scopes: __config.auth.omegaai.scopes,
			extraQueryParameters: queryParameters,
			redirectUri: __config.auth.omegaai.msal.redirect_uri,
			prompt: 'select_account',
		};
		if (inProgress === InteractionStatus.None) {
			msalInstance.loginRedirect(loginRequest).catch(e => {
				console.error(e);
				setErrorMessage(e);
			});
		}
	};

	const theme = useTheme();

	const { classes: helperTextStyles } = useHelperTextStyles();

	useEffect(() => {
		updateAppMode('breeze');
		document.getElementById('terms').style.color = '#4D79EA';
		document.getElementById('privacy').style.color = '#4D79EA';
		if (sprinterOaibreezeblumeFooterHelp) {
			document.getElementById('help').style.color = '#4D79EA';
		}
		if (sprinterOaibreezeblumeFooterGeography) {
			document.getElementById('language').style.color = '#4D79EA';
			document.getElementById('region').style.color = '#4D79EA';
		}
		if (error !== null) {
			setErrorMessage(
				error_description.startsWith('AADB2C99001')
					? 'The email address is already registred.'
					: error_description
			);
		}
	}, []);

	return (
		<Box
			sx={{
				background: 'linear-gradient(79.69deg, #FFFFFF 61.62%, #EBF0FF 104.93%)',
				height: '100vh',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<MuiGrid
				container
				columns={100}
				spacing={0}
				style={{
					backgroundColor: 'transparent',
					flexGrow: 1,
				}}
			>
				<MuiGrid
					item
					sx={{
						display: 'flex',
						[theme.breakpoints.down(1000)]: {
							flexBasis: '100%',
							maxWidth: '100%',
							width: '100%',
						},
					}}
					xs={50}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							ml: '300px',
							mr: '10px',

							[theme.breakpoints.down('lg')]: {
								marginLeft: '50px',
							},

							[theme.breakpoints.down(1000)]: {
								ml: undefined,
								mr: 'undefined',
								margin: 'auto',
								maxWidth: '500px',
								padding: '0 50px',
							},

							[theme.breakpoints.down(550)]: {
								padding: '0 28px',
							},
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								height: 'fit-content',
								margin: 'auto',
							}}
						>
							<Box
								sx={{
									letterSpacing: '0.25px',
									fontFamily: 'Inter',
									fontStyle: 'normal',
									lineHeight: '16.94px',
									justifyContent: 'flex-start',
								}}
							>
								<Link
									sx={{
										padding: '8px',
										fontSize: '14px',
										color: '#4D79EA',
										fontWeight: '700',
										width: '55px',
										textDecoration: 'none',
										cursor: 'pointer',
									}}
								>
									Login
								</Link>
								{metaBreezeSignup && (
									<Link
										className="LinkHover"
										sx={{
											padding: '8px',
											fontSize: '14px',
											color: 'rgba(0, 0, 0, 0.2)',
											fontWeight: '500',
											textDecoration: 'none',
											cursor: 'pointer',
										}}
										onClick={event => handleSignup(instance)}
									>
										Sign Up
									</Link>
								)}
								<Box>
									<Divider
										sx={{
											width: '48px',
											borderColor: '#4D79EA',
											marginLeft: '7px',
											marginTop: '6px',
											borderWidth: '2px',
											borderBottomWidth: 'thin',
										}}
									/>
								</Box>
							</Box>
							<FormLabel
								sx={{
									fontFamily: 'Inter',
									fontStyle: 'normal',
									fontWeight: '700',
									fontSize: '60px',
									lineHeight: '72.61px',
									color: '#4D79EA',
									mt: '20px',
								}}
							>
								Welcome Back
							</FormLabel>
							<FormLabel
								sx={{
									fontFamily: 'Inter',
									fontSize: '18px',
									lineHeight: '21.78px',
									color: 'rgba(0, 0, 0, 0.6)',
									fontWeight: '500',
									letterSpacing: '1px',
									marginLeft: '8px',
								}}
							>
								Login using your credentials
							</FormLabel>
							<InputLabel
								sx={{
									position: 'relative',
									fontFamily: 'Inter',
									fontStyle: 'normal',
									fontWeight: '500',
									fontSize: '16px',
									lineHeight: '19px',
									color: error ? '#EF5D5D' : 'rgba(0, 0, 0, 0.6)',
									marginTop: '96px',
									marginBottom: '7px',
								}}
							>
								Email Address
							</InputLabel>
							<TextField
								FormHelperTextProps={{
									classes: {
										root: helperTextStyles.root,
									},
								}}
								error={errorMessage}
								helperText={errorMessage}
								id="emailLogin"
								name="emailLogin"
								sx={{
									input: {
										color: '#000000',
										height: '43.69px',
										padding: '0px',
										marginLeft: '22px',
										caretColor: '#4D79EA',
										fontFamily: 'Inter',
										fontWeight: '400',
										'&:-webkit-autofill': {
											boxShadow: '0 0 0 100px #F6F6F6 inset !important',
											webkitTextFillColor: '#000000',
										},
									},
									boxSizing: 'border-box',
									position: 'relative',
									background: 'rgba(196, 196, 196, 0.15)',
									border: '1px solid rgba(0, 0, 0, 0.05)',
									borderRadius: '10px',
									width: '431px',
									height: '44.69px',
								}}
								type="email"
								onChange={onCodeChange}
							/>
							<Button
								ref={buttonRef}
								fullWidth
								color="rsPrimary"
								sx={{
									fontFamily: 'Inter',
									width: '100%',
									maxWidth: '432px',
									height: '47.92px',
									mt: '30px',
									borderRadius: '10px',
									fontSize: '24px',
									lineHeight: '29px',
									fontWeight: '700',
									letterSpacing: '1.5px',
									color: '#FFFFFF',
									backgroundColor: '#4D79EA',
								}}
								type="button"
								variant="contained"
								onClick={event => handleLogin(event, instance, emailLogin)}
							>
								Login
							</Button>
						</Box>
					</Box>
				</MuiGrid>
				<MuiGrid
					item
					sx={{
						display: 'flex',
						[theme.breakpoints.down(1000)]: {
							flexBasis: '100%',
							maxWidth: '100%',
							width: '100%',
						},
					}}
					xs={50}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<BreezeLogin
							id="breezeBackground"
							style={{
								width: '780px',
								height: '570px',
							}}
						/>
					</Box>
				</MuiGrid>
			</MuiGrid>
			{/* footer */}
			<MainFooter loginMode style={{ backgroundColor: 'inherit' }} />
		</Box>
	);
};

export default LoginBreezeView;
