import { BaseTool } from '@cornerstonejs/tools';
import drawCADgraphic from '../features/StackViewports/CAD/drawCADgraphic';

class CADtool extends BaseTool {
	constructor(
		toolProps = {},
		defaultToolProps = {
			supportedInteractionTypes: [],
			configuration: {
				strategies: {
				},
				defaultStrategy: '',
				activeStrategy: '',
			},
		}
	) {
		super(toolProps, defaultToolProps);
	}

	renderAnnotation = (enabledElement) => {
		const { viewport } = enabledElement;
		
		if(viewport?.cad?.showCAD) {
			drawCADgraphic(viewport, viewport.cad.cad)	
		} 

		viewport.render()
	}
}
CADtool.toolName = 'CADtool';
export default CADtool;