import React from 'react';

const InsertAttachmentIcon = () => (
	<svg
		data-testid="insert-attachment-icon"
		fill="none"
		height="33"
		viewBox="0 0 33 33"
		width="33"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_5211_13160)">
			<path
				d="M22.6875 8.25V24.0625C22.6875 27.1012 20.2262 29.5625 17.1875 29.5625C14.1487 29.5625 11.6875 27.1012 11.6875 24.0625V6.875C11.6875 4.9775 13.2275 3.4375 15.125 3.4375C17.0225 3.4375 18.5625 4.9775 18.5625 6.875V21.3125C18.5625 22.0688 17.9438 22.6875 17.1875 22.6875C16.4313 22.6875 15.8125 22.0688 15.8125 21.3125V8.25H13.75V21.3125C13.75 23.21 15.29 24.75 17.1875 24.75C19.085 24.75 20.625 23.21 20.625 21.3125V6.875C20.625 3.83625 18.1637 1.375 15.125 1.375C12.0862 1.375 9.625 3.83625 9.625 6.875V24.0625C9.625 28.2425 13.0075 31.625 17.1875 31.625C21.3675 31.625 24.75 28.2425 24.75 24.0625V8.25H22.6875Z"
				fill="#4D79EA"
			/>
		</g>
		<defs>
			<clipPath id="clip0_5211_13160">
				<rect fill="white" height="33" width="33" />
			</clipPath>
		</defs>
	</svg>
);

export default InsertAttachmentIcon;
