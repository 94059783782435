import React from 'react';
import Box from '@mui/material/Box';
import MobileReports from '../../MobileReport/MobileReports';

const MobileReportsSection = ({ reports, fullScreenMode }) => (
	<Box>
		<MobileReports fullScreenMode={fullScreenMode} reports={reports} />
	</Box>
);

export default MobileReportsSection;
