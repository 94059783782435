import en from 'date-fns/locale/en-CA';
import { es, fr } from 'date-fns/locale';
/**
 * Returns the localization object to use in a calendar component.
 */
export const getDatePickerLocale = i18n => {
	switch (i18n.language || 'en') {
		case 'fr':
			return fr;
		case 'es':
			return es;
		case 'en':
		default:
			return en;
	}
};

/**
 * This object overrides the PaperProps of a calendar component to force month names to be capitalized, even when
 * applying translations. This is necessary because these components do not offer a straightforward way of overriding these
 * styles. See this link for reference: https://github.com/mui/mui-x/issues/6218#issuecomment-1252130056
 */
export const datePickerPaperProps = {
	sx: {
		'.PrivatePickersFadeTransitionGroup-root': {
			textTransform: 'capitalize',
		},
	},
};
