import {
	PanTool,
	WindowLevelTool,
	StackScrollTool,
	ZoomTool,
	MagnifyTool,
	ArrowAnnotateTool,
	AngleTool,
	ProbeTool,
	DragProbeTool,
	RectangleROITool,
	LengthTool,
	BidirectionalTool,
	EllipticalROITool,
	PlanarFreehandROITool,
	PlanarRotateTool,
} from '@cornerstonejs/tools';
import AddIcon from '@mui/icons-material/Add';
import pan from '../../../../assets/icons/imageViewer/tools/pan.svg';
import quadTool from '../../../../assets/icons/imageViewer/tools/quad-tool.svg';
import contrast from '../../../../assets/icons/imageViewer/tools/contrast.svg';
import stackScroll from '../../../../assets/icons/imageViewer/tools/stackScrolll.svg';
import zoom from '../../../../assets/icons/imageViewer/tools/zoom.svg';
import rotateRight from '../../../../assets/icons/imageViewer/tools/rotateRight.svg';
import rotateLeft from '../../../../assets/icons/imageViewer/tools/rotateLeft.svg';
import magnify from '../../../../assets/icons/imageViewer/tools/magnify.svg';
import shutter from '../../../../assets/icons/imageViewer/tools/shutter.svg';
import annotation from '../../../../assets/icons/imageViewer/tools/annotation.svg';
import angle from '../../../../assets/icons/imageViewer/tools/angle.svg';
import probe from '../../../../assets/icons/imageViewer/tools/probe.svg';
import markROI from '../../../../assets/icons/imageViewer/tools/markROI.svg';
import measure from '../../../../assets/icons/imageViewer/tools/measure.svg';
import ellipticalRoi from '../../../../assets/icons/imageViewer/tools/ellipticalRoi.svg';
import freehand from '../../../../assets/icons/imageViewer/tools/freehand.svg';
import flipHorizontal from '../../../../assets/icons/imageViewer/tools/flipHorizontal.svg';
import flipVertical from '../../../../assets/icons/imageViewer/tools/flipVertical.svg';
import invert from '../../../../assets/icons/imageViewer/tools/invert.svg';
import dragProbe from '../../../../assets/icons/imageViewer/tools/dragProbe.svg';
import bidirectional from '../../../../assets/icons/imageViewer/tools/bidirectional.svg';
import cobbAngle from '../../../../assets/icons/imageViewer/tools/cobbAngle.svg';
import plumbLine from '../../../../assets/icons/imageViewer/tools/plumbLine.svg';
import QuadZoomTool from '../../cornerstoneTools/QuadZoomTool';
import CTR from '../../assets/img/toolIcons/CTR.svg';
import hotkeyCodes from '../hotkeys/hotkeyCodes';
import { TOOL_GROUPS } from '../../contexts/consts/consts';
import CobbAngleTool from '../../cornerstoneTools/CobbAngleTool';
import CardiothoracicTool from '../../cornerstoneTools/CardiothoracicTool';
import PlumbLineTool from '../../cornerstoneTools/PlumbLineTool';
import LoopIcon from '@mui/icons-material/Loop';
import spineLabelingIcon from '../../../../assets/icons/imageViewer/tools/spineLabeling.svg';
import ShutterRectangleTool from '../../cornerstoneTools/ShutterTools/ShutterRectangleTool';
import CrosshairPointerTool from '../../cornerstoneTools/CrosshairPointerTool';
import SpineLabelingTool from '../../cornerstoneTools/SpineLabelingTool';
import CrosshairsTool from '../../cornerstoneTools/CrosshairsTool';

const toolIcons = {
	[PanTool.toolName]: pan,
	[WindowLevelTool.toolName]: contrast,
	[StackScrollTool.toolName]: stackScroll,
	[ZoomTool.toolName]: zoom,
	[MagnifyTool.toolName]: magnify,
	RotateLeft: rotateLeft,
	RotateRight: rotateRight,
	FlipVertically: flipVertical,
	FlipHorizontally: flipHorizontal,
	Invert: invert,
	[CrosshairsTool.toolName]: AddIcon,
	[CrosshairPointerTool.toolName]: AddIcon,
	[QuadZoomTool.toolName]: quadTool,
	[ArrowAnnotateTool.toolName]: annotation,
	[AngleTool.toolName]: angle,
	[ProbeTool.toolName]: probe,
	[DragProbeTool.toolName]: dragProbe,
	[RectangleROITool.toolName]: markROI,
	[LengthTool.toolName]: measure,
	[BidirectionalTool.toolName]: bidirectional,
	[EllipticalROITool.toolName]: ellipticalRoi,
	[PlanarFreehandROITool.toolName]: freehand,
	[CobbAngleTool.toolName]: cobbAngle,
	[CardiothoracicTool.toolName]: CTR,
	[PlumbLineTool.toolName]: plumbLine,
	[PlanarRotateTool.toolName]: LoopIcon,
	[ShutterRectangleTool.toolName]: shutter,
	[SpineLabelingTool.toolName]: spineLabelingIcon,
};

const tools = [
	{
		name: 'Spine Labeling',
		toolName: SpineLabelingTool.toolName,
		group: TOOL_GROUPS.MARKUP,
	},
	{
		name: 'Pan',
		toolName: PanTool.toolName,
		group: TOOL_GROUPS.ADJUSTMENT,
		hotkey: hotkeyCodes.KeyT,
	},
	{
		name: 'Window Level',
		toolName: WindowLevelTool.toolName,
		group: TOOL_GROUPS.ADJUSTMENT,
		hotkey: hotkeyCodes.KeyW,
	},
	{
		name: 'Stack Scroll',
		toolName: StackScrollTool.toolName,
		group: TOOL_GROUPS.ADJUSTMENT,
		hotkey: hotkeyCodes.KeyS,
	},
	{
		name: 'Zoom',
		toolName: ZoomTool.toolName,
		group: TOOL_GROUPS.ADJUSTMENT,
		hotkey: hotkeyCodes.KeyZ,
	},

	{
		name: 'Shutter Rectangle',
		toolName: ShutterRectangleTool.toolName,
		group: TOOL_GROUPS.ADJUSTMENT,
		hotkey: hotkeyCodes.F6,
	},
	{
		name: 'Magnify',
		toolName: MagnifyTool.toolName,
		group: TOOL_GROUPS.ADJUSTMENT,
		hotkey: hotkeyCodes.KeyM,
	},
	{
		name: 'Free Rotate',
		toolName: PlanarRotateTool.toolName,
		group: TOOL_GROUPS.ADJUSTMENT,
	},
	{
		id: 'flipVertically',
		name: 'Flip Vertically',
		toolName: 'FlipVertically',
		group: TOOL_GROUPS.ADJUSTMENT,
		hotkey: hotkeyCodes.KeyF,
	},
	{
		id: 'flipHorizontally',
		name: 'Flip Horizontally',
		toolName: 'FlipHorizontally',
		group: TOOL_GROUPS.ADJUSTMENT,
		hotkey: hotkeyCodes.KeyH,
	},
	{
		id: 'invert',
		name: 'Invert',
		toolName: 'Invert',
		group: TOOL_GROUPS.ADJUSTMENT,
		hotkey: hotkeyCodes.Backslash,
	},
	{
		name: 'Crosshairs',
		toolName: CrosshairsTool.toolName,
		group: TOOL_GROUPS.ADJUSTMENT,
		hotkey: hotkeyCodes.KeyJ,
	},
	{
		name: 'Crosshair Pointer',
		toolName: CrosshairPointerTool.toolName,
		group: TOOL_GROUPS.ADJUSTMENT,
	},
	{
		name: 'Quad Tool',
		toolName: QuadZoomTool.toolName,
		group: TOOL_GROUPS.ADJUSTMENT,
		hotkey: hotkeyCodes.KeyK,
	},
	{
		name: 'Length',
		toolName: LengthTool.toolName,
		group: TOOL_GROUPS.MARKUP,
		hotkey: hotkeyCodes.KeyD,
	},
	{
		name: 'Cobb Angle',
		toolName: CobbAngleTool.toolName,
		group: TOOL_GROUPS.MARKUP,
		hotkey: hotkeyCodes.KeyA,
		ctrlKey: true,
	},
	{
		name: 'Cardiothoracic',
		toolName: CardiothoracicTool.toolName,
		group: TOOL_GROUPS.MARKUP,
		hotkey: hotkeyCodes.Semicolon,
	},
	{
		name: 'Plumb Line',
		toolName: PlumbLineTool.toolName,
		group: TOOL_GROUPS.MARKUP,
	},
	{
		name: 'Annotate',
		toolName: ArrowAnnotateTool.toolName,
		group: TOOL_GROUPS.MARKUP,
		hotkey: hotkeyCodes.NumpadDivide,
	},
	{
		name: 'Angle',
		toolName: AngleTool.toolName,
		group: TOOL_GROUPS.MARKUP,
		hotkey: hotkeyCodes.KeyA,
	},
	{
		name: 'Probe',
		toolName: ProbeTool.toolName,
		group: TOOL_GROUPS.MARKUP,
		hotkey: hotkeyCodes.KeyQ,
	},
	{
		name: 'Drag Probe',
		toolName: DragProbeTool.toolName,
		group: TOOL_GROUPS.MARKUP,
		hotkey: hotkeyCodes.KeyX,
	},
	{
		name: 'ROI',
		toolName: RectangleROITool.toolName,
		group: TOOL_GROUPS.MARKUP,
		hotkey: hotkeyCodes.KeyG,
	},
	{
		name: 'Bidirectional',
		toolName: BidirectionalTool.toolName,
		group: TOOL_GROUPS.MARKUP,
		hotkey: hotkeyCodes.KeyB,
	},
	{
		name: 'Elliptical Roi',
		toolName: EllipticalROITool.toolName,
		group: TOOL_GROUPS.MARKUP,
		hotkey: hotkeyCodes.KeyE,
	},
	{
		name: 'Freehand Roi',
		toolName: PlanarFreehandROITool.toolName,
		group: TOOL_GROUPS.MARKUP,
	},
];

export { tools, toolIcons };
