import { draw, drawLine, drawPolyline as drawPolylineSvg } from '@cornerstonejs/tools/dist/esm/drawingSvg';
import { v4 as uuid } from 'uuid';

const drawCADgraphic = (viewport, CADSR) => {
	const graphicList = CADSR?.data?.graphicObjectList;

	try {
		draw(viewport.element, (svgDrawingElement) => {
			graphicList.forEach((graphicObject) => {
				if (graphicObject['drawXMark'] == 'true') {
					drawXMark(svgDrawingElement, viewport);
				} else if (graphicObject['graphicType'] == 'POLYLINE') {
					drawPolyline(svgDrawingElement, viewport, graphicObject.graphicData);
				} else if (graphicObject['graphicType'] == 'POINT') {
					if (graphicObject?.graphicData?.length > 1) {
						drawTriangle(svgDrawingElement, viewport, graphicObject.graphicData);
					}
				}
			});
		});
	} catch (error) {
		console.log(error);
	}
};

const drawPolyline = (svgDrawingElement, viewport, graphicData) => {
	let graphicDataArr = [];
	let xWorldToIndex = 15.3;
	let yWorldToIndex = 15.3;

	const imageData = viewport?.getDefaultImageData?.();

	if (imageData) {
		const worldToIndex = imageData?.getWorldToIndex?.();

		if (worldToIndex?.[0] && worldToIndex?.[5]) {
			xWorldToIndex = worldToIndex?.[0];
			yWorldToIndex = worldToIndex?.[5];
		}
	}

	for (let i = 0; i < graphicData.length - 1; i += 2) {
		const x = parseInt(graphicData[i]),
			y = parseInt(graphicData[i + 1]);

		const worldCoord = viewport.worldToCanvas([x / xWorldToIndex, y / yWorldToIndex, 0]);
		graphicDataArr.push(worldCoord.slice(0, 2));
	}

	const options = {
		color: '#8ed127',
		lineWidth: 1.5,
		connectLastToFirst: true,
	};

	drawPolylineSvg(svgDrawingElement, null, uuid(), graphicDataArr, options);
};

const drawTriangle = (svgDrawingElement, viewport, graphicData) => {
	let graphicDataArr = [];

	if (graphicData.length > 1) {
		const _x = parseInt(graphicData[0]),
			_y = parseInt(graphicData[1]);

		const worldCoord = viewport.worldToCanvas([_x / 15.3, _y / 15.3, 0]).slice(0, 2);
		const x = worldCoord[0],
			y = worldCoord[1];

		graphicDataArr.push([x, y]);
		graphicDataArr.push([x - 3, y + 3]);
		graphicDataArr.push([x + 3, y + 3]);
		graphicDataArr.push([x, y]);
		graphicDataArr.push([x - 3, y + 3]);

		const options = {
			color: '#8ed127',
			lineWidth: 4,
		};

		drawPolylineSvg(svgDrawingElement, null, uuid(), graphicDataArr, options);
	}
};

const drawXMark = (svgDrawingElement, viewport) => {
	const options = {
		color: '#8ed127',
		lineWidth: 2,
	};

	drawLine(svgDrawingElement, null, 'XMark-1', [0, 0], [viewport.canvas.width, viewport.canvas.height], options);
	drawLine(svgDrawingElement, null, 'XMark-2', [viewport.canvas.width, 0], [0, viewport.canvas.height], options);
};

export default drawCADgraphic;
