// @flow

// core
import React, { type Node } from 'react';
// components
import Stack from '@mui/material/Stack';
import { type ISkeletonProps, LoaderSkeleton } from './LoaderSkeleton';

export const PatientCard = ({ children, isLoading }: ISkeletonProps): Node =>
	isLoading ? (
		<Stack data-testid="patient-results-loader" direction="column" spacing="5px">
			<Stack direction="column" spacing="3px" sx={{ padding: '14px 22px' }}>
				<LoaderSkeleton sx={{ height: '20px' }} />
				<LoaderSkeleton sx={{ height: '20px' }} />
			</Stack>
			<Stack direction="column" spacing="3px" sx={{ padding: '14px 22px' }}>
				<LoaderSkeleton sx={{ height: '20px' }} />
				<LoaderSkeleton sx={{ height: '20px' }} />
			</Stack>
			<Stack direction="column" spacing="3px" sx={{ padding: '14px 22px' }}>
				<LoaderSkeleton sx={{ height: '20px' }} />
				<LoaderSkeleton sx={{ height: '20px' }} />
			</Stack>
		</Stack>
	) : (
		children
	);
