import { getEnabledElement, triggerEvent, eventTarget } from '@cornerstonejs/core';
import { Enums, EllipticalROITool, utilities, annotation, cursors } from '@cornerstonejs/tools';

const { Events } = Enums;
const { triggerAnnotationRenderForViewportIds } = utilities;
const { removeAnnotation } = annotation.state;
const { resetElementCursor } = cursors.elementCursor;

class EllipticalROIToolUpdated extends EllipticalROITool {
	_endCallback = (evt) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const { annotation, viewportIdsToRender, newAnnotation, hasMoved } = this.editData;
		const { data } = annotation;

		if (newAnnotation && !hasMoved) {
			return;
		}

		// Elliptical ROI tool should reset its highlight to false on mouse up (as opposed
		// to other tools that keep it highlighted until the user moves. The reason
		// is that we use top-left and bottom-right handles to define the ellipse,
		// and they are by definition not in the ellipse on mouse up.
		annotation.highlighted = false;
		data.handles.activeHandleIndex = null;

		this._deactivateModify(element);
		this._deactivateDraw(element);

		resetElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		this.editData = null;
		this.isDrawing = false;

		if (this.isHandleOutsideImage && this.configuration.preventHandleOutsideImage) {
			removeAnnotation(annotation.annotationUID);
		}

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		const eventType = Events.ANNOTATION_COMPLETED;

		triggerEvent(eventTarget, eventType, { annotation });
	};
}

export default EllipticalROIToolUpdated;
