import React from 'react';
import { Popover, ListItem, ListItemButton, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import getDefaultRenderingEngine from '../../cornerstone/getDefaultRenderingEngine';
import { renderingEngineId } from '../../contexts/ImageViewerCornerstoneContext';
import { ramsoft } from '@cornerstonejs/core';

export const PAN_PRESETS = [
	{
		label: 'Reset',
		value: 'reset',
	},
	{
		label: 'Align Top',
		value: 'top',
	},
	{
		label: 'Align Right',
		value: 'right',
	},
	{
		label: 'Align Bottom',
		value: 'bottom',
	},
	{
		label: 'Align Left',
		value: 'left',
	},
	{
		label: 'Align Vertical Center',
		value: 'verticalCenter',
	},
	{
		label: 'Align Horizontal Center',
		value: 'horizontalCenter',
	},
];

export const PanPresets = ({ viewportId, position, isOpen, onClose }) => {
	const renderingEngine = getDefaultRenderingEngine();

	if (!renderingEngine) {
		return null;
	}

	const renderingEngineViewport = renderingEngine.getViewport(viewportId);

	if (!renderingEngineViewport) {
		return null;
	}

	const pan = renderingEngineViewport.getPan?.();

	let verticalAlignment = renderingEngineViewport?.defaultOptions?.rules?.verticalAlign;
	let horizontalAlignment = renderingEngineViewport?.defaultOptions?.rules?.horizontalAlign;

	if (!verticalAlignment && pan?.[1] > -1 && pan?.[1] < 1) {
		verticalAlignment = 'verticalCenter';
	}

	if (!horizontalAlignment && pan?.[0] > -1 && pan?.[0] < 1) {
		horizontalAlignment = 'horizontalCenter';
	}

	const alignments = [verticalAlignment, horizontalAlignment];

	const onPresetChange = preset => {
		onClose();

		if (preset === 'reset') {
			ramsoft.alignVerticalCenter({ renderingEngineId, viewportId });
			ramsoft.alignHorizontalCenter({ renderingEngineId, viewportId });
		}

		if (preset === 'top') {
			ramsoft.alignTop({ renderingEngineId, viewportId });
		}

		if (preset === 'right') {
			ramsoft.alignRight({ renderingEngineId, viewportId });
		}

		if (preset === 'bottom') {
			ramsoft.alignBottom({ renderingEngineId, viewportId });
		}

		if (preset === 'left') {
			ramsoft.alignLeft({ renderingEngineId, viewportId });
		}

		if (preset === 'verticalCenter') {
			ramsoft.alignVerticalCenter({ renderingEngineId, viewportId });
		}

		if (preset === 'horizontalCenter') {
			ramsoft.alignHorizontalCenter({ renderingEngineId, viewportId });
		}
	};

	return (
		<Popover
			PaperProps={{
				sx: {
					padding: 0,
					background: '#393939',
					border: '1px solid rgba(255, 255, 255, 0.1)',
					borderRadius: '6px',
					zIndex: 1000,
				},
			}}
			anchorPosition={{ top: position.y, left: position.x }}
			anchorReference="anchorPosition"
			open={isOpen}
			onClose={onClose}
			onMouseDown={e => e.stopPropagation()}
			onMouseUp={e => e.stopPropagation()}
		>
			{PAN_PRESETS.map(item => (
				<ListItem key={item.value} sx={{ p: 0 }}>
					<ListItemButton sx={{ gap: 2 }} onClick={() => onPresetChange(item.value)}>
						<ListItemText
							primary={item.label}
							sx={{
								color: '#fff',
							}}
						/>
						<CheckIcon
							sx={{
								color: '#42A5F5',
								opacity: alignments.includes(item.value) ? 1 : 0,
							}}
						/>
					</ListItemButton>
				</ListItem>
			))}
		</Popover>
	);
};
