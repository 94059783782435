// core
import React from 'react';

// components
import FormAPIAutocompleteVariant from './variants/FormAPIAutocompleteVariant';
import FormContactInformationVariant from './variants/FormContactInformationVariant';
import FormContactInformationVariantV2 from './variants/FormContactInformationVariantV2';
import FormGoogleAutocompleteVariant from './variants/FormGoogleAutocompleteVariant';
import FormTextFieldVariant from './variants/FormTextFieldVariant';
import FormNumberFieldVariant from './variants/FormNumberFieldVariant';
import FormSelectVariant from './variants/FormSelectVariant';
import FormMultiSelectVariant from './variants/FormMultiSelectVariant';
import FormModifierVariant from './variants/FormModifierVariant';
import FormDatePicker from './variants/FormDatePicker';
import { NotesField } from '../../../NotesField';
import DragDropIDP from '../../../DragDropIDP';
import FormDateTimeRangePickerVariant from './variants/FormDateTimeRangePickerVariant';
import FormSplitTextFieldVariant from './variants/FormSplitTextFieldVariant';

// types
import FormFieldVariantsType from '../../formTypes/FormFieldVariantsType';
import FormGridVariant from './variants/FormGridVariant';
import FormPhoneVariant from './variants/FormPhoneVariant';
import FormMUIPhoneVariant from './variants/FormMUIPhoneVariant';
import FormDateTimePicker from '@rs-ui/components/FormNew/formPartials/FormFieldVariants/FormDateTimePicker';
import FormSignDelayVariant from './variants/FormSignDelayVariant';
import PlainDateTime from '@rs-ui/components/FormNew/formPartials/FormFieldVariants/PlainDateTime';
import TimeSelectorVariant from '@rs-ui/components/FormNew/formPartials/FormFieldVariants/TimeSelectorVariant';
import ModalityPlainVariant from './ModalityPlainVariant';
import DateTimeRangePicker from '../../../DateTimeRangePicker/DateTimeRangePicker';
import FormGoogleLocationVariant from './variants/FormGoogleLocationVariant';
import { FormAPIAutocompleteMultipleVariant } from './variants/FormAPIAutocompleteMultipleVariant';

const FormFieldVariants = ({
	field,
	formHook,
	isEditing,
	setIsEditing,
	organization,
	setBadgeStatus,
	setToastMessage,
	loggedInUser,
}) => {
	const FieldComponent =
		{
			APIAutocomplete: FormAPIAutocompleteVariant,
			contactInformation: FormContactInformationVariant,
			contactInformationV2: FormContactInformationVariantV2,
			googleAutocomplete: FormGoogleAutocompleteVariant,
			googleAddressSearch: FormGoogleLocationVariant,
			grid: FormGridVariant,
			text: FormTextFieldVariant,
			number: FormNumberFieldVariant,
			select: FormSelectVariant,
			multiSelect: FormMultiSelectVariant,
			phone: FormPhoneVariant,
			muiPhone: FormMUIPhoneVariant,
			modifier: FormModifierVariant,
			notesField: NotesField,
			dragDropIdp: DragDropIDP,
			datePicker: FormDatePicker,
			dateTimePicker: FormDateTimePicker,
			plainDateTime: PlainDateTime,
			timeSelector: TimeSelectorVariant,
			modalityPlainField: ModalityPlainVariant,
			signDelay: FormSignDelayVariant,
			dateTimeRangePicker: DateTimeRangePicker,
			formDateTimeRangePicker: FormDateTimeRangePickerVariant,
			autocompleteMultipleVariant: FormAPIAutocompleteMultipleVariant,
			splitText: FormSplitTextFieldVariant,
		}[field.type] || FormTextFieldVariant;

	return (
		<FieldComponent
			formHook={formHook}
			{...field}
			studyInformation
			isEditing={isEditing}
			loggedInUser={loggedInUser}
			organization={organization}
			setBadgeStatus={setBadgeStatus}
			setEditing={setIsEditing}
			setToastMessage={setToastMessage}
		/>
	);
};

FormFieldVariants.propTypes = FormFieldVariantsType;

FormFieldVariants.defaultProps = {
	isEditing: false,
};

export default FormFieldVariants;
