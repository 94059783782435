/* Generated automagically by FhirStarter - DO NOT EDIT */
/* that FaxResource was not generated, written manually based on top of TaskResouce. */

import { FhirDomainResource } from '../base';
// import taskSchema from './schemata/taskSchema';
/************************************************************************************************************************
Resource: Task
Reference: none (custom resource)


A task resource describes an activity that can be performed and tracks the state of

completion of that activity. It is a representation that an activity should be or has been

initiated, and eventually, represents the successful or unsuccessful completion of that

activity.

Note that there are a variety of processes associated with making and processing orders.

Some orders may be handled immediately by automated systems but most require real world

actions by one or more humans. Some orders can only be processed when other real world actions

happen, such as a patient presenting themselves so that the action to be performed

can actually be performed. Often these real world dependencies are only implicit in the order

details. 
************************************************************************************************************************/

export default class FaxResource extends FhirDomainResource {
	id;
	// actions;
	status;
	resourceType;
	faxDatetime;
	faxDirection;
	faxFrom;
	faxNumber;
	pages;
	duration;
	patient;
	study;
	failedReason;

	constructor(resourceString) {
		super(resourceString, faxSchema);
		this.resourceType = 'Fax';
		this.populateFields();
	}
}

const faxSchema = {
	resourceType: 'Fax',
	// actions: ['__Identifier__'], // TODO: ask how to map actions
	status: '__code__',
	faxDatetime: '__dateTime__',
	faxDirection: '__string__',
	faxFrom: '__string__',
	faxNumber: '__string__',
	pages: '__string__',
	duration: '__positiveInt__',
	patient: {
		id: '__positiveInt__',
		reference: '__string__',
		display: '__string__',
	},
	study: {
		id: '__positiveInt__',
		reference: '__string__',
		display: '__string__',
	},
	failedReason: '__string__',
};
