import React, { useState } from 'react';
import axios from 'axios';

import { Typography } from '@mui/material';

import { useConfig } from '@worklist-2/core/src';
import CustomTextInput from '@worklist-2/ui/src/components/Help/CustomTextInput';

import {
	AddIcon,
	ArrowIcon,
	BookmarkIcon,
	DragBlockIcon,
	FileIcon,
	FolderAddIcon,
	FolderIcon,
	SubSectionIcon,
} from '../../assets/icons';

import { useHelpCenterContext } from '../../HelpCenter/HelpCenterContext';

import { Clear, CollectionsBookmarkOutlined, CreateOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';

import styles from './AddSubsections.module.scss';

const AddSubsections = ({ theme, setIsSectionsOpen }) => {
	const { categories } = useHelpCenterContext();
	const [sections, setSections] = useState([...categories]);

	const addNewCategory = async () => {
		setSections(category => [
			...category,
			{
				CategoryName: 'New Section',
				App: theme === 'OAI' ? 'omegaai' : 'blume',
				Status: 'draft',
				ResourceType: 'DocumentCategory',
				SubCategory: [],
				newSection: true,
				DocumentCategoryId: category.length,
			},
		]);
	};

	return (
		<div className={`${styles.sections} ${theme === 'OAI' ? styles.sectionsOAI : styles.sectionsBlume}`}>
			<div className={`${styles.sectionsHeading} ${theme === 'OAI' ? styles.OAI : styles.Blume}`}>
				<div className={styles.alignCenter}>
					<ArrowIcon
						color={`${theme === 'OAI' ? 'white' : 'black'}`}
						onClick={() => setIsSectionsOpen(false)}
					/>
					<h3>Sections</h3>
				</div>
				<div>
					<span style={{ marginRight: '10px' }}>
						<FolderAddIcon height="18" width="19" />
					</span>
					<span data-testid="add-category-button" onClick={addNewCategory}>
						<BookmarkIcon circleColor={theme === 'OAI' ? '#00000099' : 'white'} hoverColor="#4D79EA" />
					</span>
				</div>
			</div>
			<div className={`${styles.sectionsTitle} ${theme === 'OAI' ? styles.OAI : styles.Blume}`}>
				<FolderIcon color={theme === 'OAI' ? 'white' : 'black'} />
				<Typography>Omega AI: Documentation</Typography>
				<KeyboardArrowUpOutlined />
			</div>
			{sections.map(categoryItem => (
				<SubSections
					key={categoryItem.DocumentCategoryId}
					category={categoryItem}
					setSections={setSections}
					theme={theme}
				/>
			))}
		</div>
	);
};

const SubSections = ({ theme, category, setSections }) => {
	let { CategoryName, DocumentCategoryId, newSection, SubCategory } = category;
	const { setCategories } = useHelpCenterContext();

	const [inputValue, setInputValue] = useState({
		CategoryName,
		subCategoryName: '',
		subSection: '',
	});
	const [isSubsectionHover, setIsSubsectionHover] = useState(false);
	const [draggableIndex, setDraggableIndex] = useState(null);
	const [activeSubSectionInput, setActiveSubSectionInput] = useState(false);
	const [showEditCategory, setShowEditCategory] = useState(false);
	const [showEditSubCategory, setShowEditSubCategory] = useState('');
	const [editCategory, setEditCategory] = useState(false);
	const [editSubCategory, setEditSubCategory] = useState('');
	const __config = useConfig();

	const handleDragStart = (e, idx) => {
		e.stopPropagation();
		setDraggableIndex(idx);
	};

	const handleDragOver = (e, dragOverIndex) => {
		e.preventDefault();
		if (draggableIndex === null) return;

		const updatedItems = [...SubCategory];
		const draggedItem = updatedItems[draggableIndex];
		updatedItems.splice(draggableIndex, 1);
		updatedItems.splice(dragOverIndex, 0, draggedItem);

		setSections(items => {
			setDraggableIndex(dragOverIndex);
			setIsSubsectionHover(dragOverIndex);
			return items.map(item => {
				if (item.DocumentCategoryId === DocumentCategoryId) {
					return { ...item, SubCategory: [...updatedItems] };
				}
				return item;
			});
		});
	};

	const handleDragEnd = () => {
		setDraggableIndex(null);
		updateCategory(DocumentCategoryId, {
			...category,
			CategoryName,
			App: theme === 'OAI' ? 'omegaai' : 'blume',
			Status: 'draft',
			ResourceType: 'DocumentCategory',
			SubCategory: [...SubCategory],
		});
	};

	const updateCategory = async (id, data) => {
		let response;
		if (newSection) {
			response = await axios.post(`${__config.data_sources.breeze}/documentcategory`, data);
		} else {
			response = await axios.put(`${__config.data_sources.breeze}/documentcategory/${id}`, data);
		}
		if (response.status === 200 || response.status === 201) {
			setSections(categories =>
				categories.map(categoryItem => {
					if (DocumentCategoryId === categoryItem.DocumentCategoryId) {
						return response.data;
					}
					return categoryItem;
				})
			);

			if (response.status === 201) {
				setCategories(categories => [...categories, response.data]);
			} else {
				setCategories(categories =>
					categories.map(categoryItem => {
						if (DocumentCategoryId === categoryItem.DocumentCategoryId) {
							return response.data;
						}
						return categoryItem;
					})
				);
			}
		}

		return response.status;
	};

	const onMouseEnterSection = (idx, item) => {
		setIsSubsectionHover(idx);
		setShowEditSubCategory(item.SubCategoryId);
	};

	const onMouseLeaveSection = () => {
		setIsSubsectionHover(false);
		setShowEditSubCategory('');
	};

	return (
		<div>
			<div
				className={`${styles.subsectionsTitleContainer} ${theme === 'OAI' ? styles.OAI : styles.Blume}`}
				onMouseEnter={() => setShowEditCategory(true)}
				onMouseLeave={() => setShowEditCategory(false)}
			>
				<div className={styles.title}>
					<CollectionsBookmarkOutlined sx={{ fontSize: 15, marginRight: 1 }} />
					{editCategory ? (
						<CustomTextInput
							inputProps={{
								style: {
									color: '#FFFFFF',
									fontSize: '14px',
									margin: '5px 0px',
								},
							}}
							placeholder="Add Subsection"
							style={{ border: '0' }}
							value={inputValue.CategoryName}
							onChange={e => {
								setInputValue(prevVal => ({ ...prevVal, CategoryName: e.target.value }));
							}}
							onKeyDown={e => {
								if (e.code === 'Enter') {
									if (inputValue.CategoryName) {
										const response = updateCategory(DocumentCategoryId, {
											...category,
											CategoryName: inputValue.CategoryName,
											App: theme === 'OAI' ? 'omegaai' : 'blume',
											Status: 'draft',
											ResourceType: 'DocumentCategory',
											SubCategory: [...SubCategory],
										});
										if (response === 200 || response === 201) {
											CategoryName = inputValue.CategoryName;
										}
									}
								}
							}}
						/>
					) : (
						<Typography>{CategoryName}</Typography>
					)}
				</div>
				<div className={styles.title}>
					{showEditCategory && !editCategory && (
						<CreateOutlined
							sx={{ fontSize: 16, cursor: 'pointer' }}
							onClick={() => setEditCategory(true)}
						/>
					)}
					{editCategory && (
						<Clear sx={{ fontSize: 16, cursor: 'pointer' }} onClick={() => setEditCategory(false)} />
					)}
					<Typography className={styles.length}>
						<i>{`${SubCategory?.length || 0} Items`}</i>
					</Typography>
				</div>
			</div>
			<div
				className={`${styles.subsectionsContainer} ${theme === 'OAI' ? styles.OAI : styles.Blume}`}
				onMouseEnter={() => setActiveSubSectionInput(true)}
				onMouseLeave={() => setActiveSubSectionInput(false)}
			>
				{SubCategory?.map((item, idx) => (
					<div
						key={idx}
						draggable
						className={`${styles.subsectionsList} ${theme === 'OAI' ? styles.OAI : styles.Blume} ${
							draggableIndex === idx ? styles.drag : ''
						}`}
						onDragEnd={handleDragEnd}
						onDragEnter={e => handleDragOver(e, idx)}
						onDragStart={e => handleDragStart(e, idx)}
					>
						{isSubsectionHover !== idx && draggableIndex === null ? (
							<SubSectionIcon color="black" height="44" />
						) : (
							''
						)}
						<div
							className={`${styles.section} ${theme === 'OAI' ? styles.OAI : styles.Blume} ${
								draggableIndex === null ? '' : styles.sectionDrag
							} ${draggableIndex === idx ? styles.sectionDragActive : ''}`}
							onMouseEnter={() => onMouseEnterSection(idx, item)}
							onMouseLeave={() => onMouseLeaveSection()}
						>
							{isSubsectionHover === idx ? (
								<span className={styles.dragIcon}>
									<DragBlockIcon color={theme === 'OAI' ? '' : '#4D79EA'} />
								</span>
							) : (
								''
							)}
							<div className={styles.subCategory}>
								<FileIcon />
								{editSubCategory === item.SubCategoryId ? (
									<CustomTextInput
										inputProps={{
											style: {
												color: '#FFFFFF',
												fontSize: '14px',
												margin: '5px 7px',
											},
										}}
										placeholder="Add Subsection"
										style={{
											border: '0',
										}}
										value={
											editSubCategory === item.SubCategoryId
												? inputValue.subCategoryName
												: item.SubCategoryName
										}
										onChange={e => {
											setInputValue(prevVal => ({
												...prevVal,
												subCategoryName: e.target.value,
											}));
										}}
										onKeyDown={e => {
											if (e.code === 'Enter') {
												if (inputValue.subCategoryName) {
													const newSubCategories = SubCategory.map(subCategoryItem => {
														if (item.SubCategoryId === subCategoryItem.SubCategoryId) {
															return {
																...subCategoryItem,
																SubCategoryName: inputValue.subCategoryName,
															};
														}
														return subCategoryItem;
													});
													const status = updateCategory(DocumentCategoryId, {
														...category,
														CategoryName: inputValue.CategoryName,
														App: theme === 'OAI' ? 'omegaai' : 'blume',
														Status: 'draft',
														ResourceType: 'DocumentCategory',
														SubCategory: newSubCategories,
													});
													if (status === 200) {
														SubCategory = [...newSubCategories];
													}
												}
											}
										}}
									/>
								) : (
									<Typography sx={{ marginLeft: 1, fontSize: 14 }}>{item.SubCategoryName}</Typography>
								)}
							</div>
							{showEditSubCategory === item.SubCategoryId && editSubCategory !== item.SubCategoryId && (
								<CreateOutlined
									sx={{ fontSize: 16, cursor: 'pointer' }}
									onClick={() => {
										setEditSubCategory(item.SubCategoryId);
										setInputValue(prevVal => ({
											...prevVal,
											subCategoryName: item.SubCategoryName,
										}));
									}}
								/>
							)}
							{editSubCategory === item.SubCategoryId && (
								<Clear
									sx={{ fontSize: 16, cursor: 'pointer' }}
									onClick={() => setEditSubCategory('')}
								/>
							)}
						</div>
					</div>
				))}

				{activeSubSectionInput && draggableIndex === null && (
					<div className={styles.addSubsectionList}>
						<SubSectionIcon color="#4D79EA" height="44" />
						<div className={styles.addSubsection}>
							<span
								style={{
									transform: `${inputValue.subSection ? 'rotate(-135deg)' : ''}`,
									cursor: 'pointer',
								}}
								onClick={() => setInputValue(prevVal => ({ ...prevVal, subSection: '' }))}
							>
								<AddIcon />
							</span>
							<CustomTextInput
								className={styles.input}
								inputProps={{
									style: {
										color: theme === 'OAI' ? '#FFFFFF' : '#000000CC',
									},
								}}
								placeholder="Add Subsection"
								style={{
									border: '0',
								}}
								value={inputValue.subSection}
								onChange={e => setInputValue(prevVal => ({ ...prevVal, subSection: e.target.value }))}
								onKeyDown={async e => {
									if (e.code === 'Enter') {
										if (inputValue.subSection) {
											const status = await updateCategory(DocumentCategoryId, {
												...category,
												CategoryName,
												App: theme === 'OAI' ? 'omegaai' : 'blume',
												Status: 'draft',
												ResourceType: 'DocumentCategory',
												SubCategory: [
													...SubCategory,
													{
														SubCategoryName: inputValue.subSection,
														Status: 'draft',
													},
												],
											});
											if (status === 200 || status === 201) {
												setInputValue(prevVal => ({ ...prevVal, subSection: '' }));
											}
										}
									}
								}}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default AddSubsections;
