import React from 'react';

const UnderlineIcon = () => (
	<svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_5211_59772)">
			<path
				d="M7.99992 11.3333C10.2066 11.3333 11.9999 9.54 11.9999 7.33333V2H10.3333V7.33333C10.3333 8.62 9.28659 9.66667 7.99992 9.66667C6.71325 9.66667 5.66659 8.62 5.66659 7.33333V2H3.99992V7.33333C3.99992 9.54 5.79325 11.3333 7.99992 11.3333ZM3.33325 12.6667V14H12.6666V12.6667H3.33325Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_5211_59772">
				<rect fill="white" height="16" width="16" />
			</clipPath>
		</defs>
	</svg>
);

export default UnderlineIcon;
