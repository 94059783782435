import React from 'react';

const BreezeIcon = () => (
	<svg fill="none" height="30" viewBox="0 0 31 30" width="31" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_4186_16084)">
			<path
				d="M5.69092 28.0665C5.69092 28.0665 19.8838 32.742 27.928 27.5552C27.9311 27.5531 27.9331 27.5521 27.9361 27.55C28.0754 27.4789 31.8922 25.483 30.8046 20.8827C30.8046 20.8827 29.779 16.8732 23.295 13.032C23.295 13.032 17.6323 9.44377 10.9023 7.88281C10.9023 7.88281 23.046 12.5542 24.9335 20.3767C24.9335 20.3767 26.566 25.6346 19.5524 27.848C18.1822 28.2798 16.75 28.377 15.3209 28.4815C13.5095 28.6133 10.3514 28.7304 5.69092 28.0665Z"
				fill="#3465D1"
			/>
			<path
				d="M9.62897 6.80376C8.65317 13.7313 11.2767 14.0931 9.84955 15.8851C9.30777 16.5657 7.97926 16.502 7.284 15.4784C7.08579 13.9206 7.20268 12.3638 7.60215 10.8959C8.0006 9.42695 8.68163 8.04792 9.61169 6.8414C9.62796 6.82153 9.63304 6.79644 9.63101 6.77344V6.80376H9.62897Z"
				fill="url(#paint0_linear_4186_16084)"
			/>
			<path
				d="M0.673059 4.41458C2.18657 4.47627 4.85782 5.00739 9.5681 6.69067C9.60469 6.70321 9.62807 6.73667 9.6301 6.77222C9.63315 6.79522 9.62706 6.82031 9.61079 6.84018C8.68073 8.0467 7.9997 9.42573 7.60125 10.8947C7.20178 12.3626 7.08489 13.9194 7.2831 15.4772L0.245129 5.68488C0.165845 5.5751 0.101808 5.45905 0.0520016 5.33986C-0.132994 4.89134 0.199388 4.39576 0.673059 4.41458Z"
				fill="url(#paint1_linear_4186_16084)"
			/>
			<path
				d="M11.9154 0.0888882C11.9154 0.0888882 1.79243 3.15016 0.982311 3.4659C0.954866 3.47636 0.914208 3.49413 0.908109 3.49727C0.704817 3.59345 0.678389 3.92384 1.06058 3.94475C1.09006 3.94684 1.15511 3.95625 1.26794 3.96357C2.23561 4.03152 6.15304 4.84075 10.0786 6.24697C10.4008 5.87163 13.3791 2.82605 13.3791 2.82605C14.1912 2.12555 14.1323 0.782064 13.2215 0.224805C12.8566 1.94181e-05 12.3961 -0.0763032 11.9154 0.0888882Z"
				fill="url(#paint2_linear_4186_16084)"
			/>
		</g>
		<defs>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint0_linear_4186_16084"
				x1="11.2835"
				x2="5.81905"
				y1="14.834"
				y2="8.14943"
			>
				<stop stopColor="#2956A5" />
				<stop offset="0.16" stopColor="#25519C" />
				<stop offset="0.41" stopColor="#1B4486" />
				<stop offset="0.72" stopColor="#0C2E63" />
				<stop offset="0.84" stopColor="#062654" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint1_linear_4186_16084"
				x1="9.93271"
				x2="0.785999"
				y1="12.6294"
				y2="3.91743"
			>
				<stop stopColor="#2956A5" />
				<stop offset="0.11" stopColor="#315EB5" />
				<stop offset="0.29" stopColor="#3D6ACC" />
				<stop offset="0.49" stopColor="#4672DD" />
				<stop offset="0.71" stopColor="#4B77E6" />
				<stop offset="1" stopColor="#4D79EA" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint2_linear_4186_16084"
				x1="10.4529"
				x2="6.95271"
				y1="6.93275"
				y2="2.13898"
			>
				<stop stopColor="#2956A5" />
				<stop offset="0.38" stopColor="#3966C5" />
				<stop offset="0.77" stopColor="#4773E0" />
				<stop offset="1" stopColor="#4D79EA" />
			</linearGradient>
			<clipPath id="clip0_4186_16084">
				<rect fill="white" height="30" width="31" />
			</clipPath>
		</defs>
	</svg>
);

export default BreezeIcon;
