import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Avatar, Box, Typography } from '@mui/material';

import { useCrmContext } from '../../../../context/CRMContext';

import { TemMembersAvatars } from '../../AdminSettings/TeamCard';

import { ColoredChip } from '../../../../views/CrmView/styles';

import createStyles from './styles';

import { useNavigate } from 'react-router';

const KanbanListItem = ({ caseItem, index, assignedTeams }) => {
	const { toggleDetails } = useCrmContext();
	const navigate = useNavigate();

	const classes = createStyles();

	const getDate = date =>
		date
			? `${new Date(date).toLocaleDateString()} ${new Date(date).toLocaleTimeString(navigator.language, {
					hour: '2-digit',
					minute: '2-digit',
			  })}`
			: '-';

	return (
		<Draggable draggableId={caseItem.CaseId} index={index}>
			{provided => (
				<Box
					ref={provided.innerRef}
					sx={classes.listItemContainer}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					onClick={() =>
						toggleDetails({
							data: caseItem,
							tag: 'cases',
						})
					}
					onDoubleClick={() => {
						navigate(`/case/${caseItem.CaseId}`);
						toggleDetails();
					}}
				>
					<Box sx={classes.listItemTitle}>
						<Typography sx={classes.priority} variant="h1">
							{caseItem.Priority}
						</Typography>
						<Typography sx={classes.caseNumber}>{caseItem.CaseNumber}</Typography>
					</Box>
					<Typography sx={classes.date}>{getDate(caseItem.CreatedDateTime)}</Typography>
					<Typography sx={classes.subject}>{caseItem.Subject}</Typography>
					<Box sx={classes.right}>
						<Box sx={classes.rightBox}>
							{assignedTeams.length > 0 && <ColoredChip labels={assignedTeams} />}
							{caseItem.AssignedTo && (
								<Avatar sx={classes.avatar}>
									{caseItem.AssignedTo.split(' ')
										.map(n => n.charAt(0).toUpperCase())
										.join('')}
								</Avatar>
							)}
						</Box>
						<TemMembersAvatars
							members={
								caseItem?.RelatedContact?.map(member => ({
									...member,
									UserName: member.FullName,
								})) || []
							}
						/>
					</Box>
				</Box>
			)}
		</Draggable>
	);
};

export default KanbanListItem;
