import React from 'react';

const KeyboardArrowUpIcon = () => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_5211_15662)" opacity="0.6">
			<path
				d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_5211_15662">
				<rect fill="white" height="24" width="24" />
			</clipPath>
		</defs>
	</svg>
);

export default KeyboardArrowUpIcon;
