import React from 'react';

import KeyResultProgressContainer from './KeyResultProgressContainer';

function ObjectiveProgressContainer({ objective, onSetObjectiveVariables, onSetKeyResultVariable, onSetIdeaVariable }) {
	return (
		<div>
			<div
				style={{
					height: '53px',
					marginBottom: '15px',
				}}
			/>
			{objective.expanded && objective.hovered && (
				<div
					style={{
						height: '37px',
					}}
				/>
			)}
			{objective?.expanded && (
				<div>
					{(objective.KeyResults || []).map((keyResult, index) => (
						<KeyResultProgressContainer
							key={index}
							keyResult={keyResult}
							keyResultIndex={index}
							objective={objective}
							onSetIdeaVariable={onSetIdeaVariable}
							onSetKeyResultVariable={onSetKeyResultVariable}
							onSetObjectiveVariables={onSetObjectiveVariables}
						/>
					))}
				</div>
			)}
		</div>
	);
}

export default ObjectiveProgressContainer;
