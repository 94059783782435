import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import DrawerTabPanel from './DrawerTabPanel';

const allyProps = (index, tabId, tabPanelId) => ({
	id: `${tabId}-${index}`,
	'aria-controls': `${tabPanelId}-${index}`,
});

const PopulatedTabs = ({ tabNames, tabProps, value, handleChange, tabId, tabPanelId, tabSx, tabsOptions }) => (
	<Tabs
		aria-label={tabId}
		sx={{
			'& .MuiTabs-indicator': {
				backgroundColor: 'rsPrimary.main',
			},
		}}
		value={value}
		onChange={handleChange}
		{...tabsOptions}
	>
		{tabNames.map((tabName, ind) => (
			<Tab
				key={tabName}
				label={tabName}
				sx={{
					textTransform: 'none',
					minWidth: 0,
					'&:hover': {
						color: 'rsPrimary.main',
						opacity: 1,
					},
					'&.Mui-selected': {
						color: 'rsPrimary.main',
					},
					...tabSx,
				}}
				{...tabProps?.[ind]}
				{...allyProps(ind, tabId, tabPanelId)}
				data-cy="sendDrawerTabs"
			/>
		))}
	</Tabs>
);

const PopulatedTabPanels = ({ value, tabId, tabPanelId, tabPanelSx, children }) => (
	<>
		{React.Children.map(children, (child, ind) => {
			if (React.isValidElement(child)) {
				return (
					<DrawerTabPanel
						key={ind}
						index={ind}
						tabId={tabId}
						tabPanelId={tabPanelId}
						tabPanelSx={tabPanelSx}
						value={value}
					>
						{child}
					</DrawerTabPanel>
				);
			}
		})}
	</>
);

const GenericDrawerTabs = ({
	tabNames,
	tabProps,
	tabPanelContents,
	tabId,
	tabPanelId,
	tabSx,
	tabPanelSx,
	tabsOptions,
	currentTabIndex,
	setCurrentTabIndex,
	tabPanelBoxSx,
	tabsBoxSx,
}) => {
	const [value, setValue] = setCurrentTabIndex ? [currentTabIndex, setCurrentTabIndex] : useState(0);

	const handleChange = useCallback((event, newValue) => {
		setValue(newValue);
	}, []);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				width: '100%',
				...tabsBoxSx,
			}}
		>
			<PopulatedTabs
				handleChange={handleChange}
				tabId={tabId}
				tabNames={tabNames}
				tabPanelId={tabPanelId}
				tabProps={tabProps}
				tabSx={tabSx}
				tabsOptions={tabsOptions}
				value={value}
			/>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: '1',
					overflowY: 'auto',
					...tabPanelBoxSx,
				}}
			>
				<PopulatedTabPanels tabId={tabId} tabPanelId={tabPanelId} tabPanelSx={tabPanelSx} value={value}>
					{tabPanelContents}
				</PopulatedTabPanels>
			</Box>
		</Box>
	);
};

GenericDrawerTabs.propTypes = {
	/**
	 * A list of names for each tab
	 */
	tabNames: PropTypes.array.isRequired,
	/**
	 * A list of objects that contain props; each prop object passed to the
	 * corresponding Tab component at the same index
	 */
	tabProps: PropTypes.array,
	/**
	 * A list of React Components which are the contents of each tab
	 */
	tabPanelContents: PropTypes.array.isRequired,
	/**
	 * The id which will be used to label the tab. This id will have an index
	 * corresponding to the tab appended to it and used to link it with
	 * the corresponding tab panel
	 */
	tabId: PropTypes.string.isRequired,
	/**
	 * The id which will be used to label the tab panel. This id will have an index
	 * corresponding to the tab panel appended to it and used to link it
	 * with the corresponding tab
	 */
	tabPanelId: PropTypes.string.isRequired,
	/**
	 * sx prop which is passed to the tab
	 */
	tabSx: PropTypes.object,
	/**
	 * sx prop which is passed to the tab content box
	 */
	tabPanelBoxSx: PropTypes.object,
};

export default GenericDrawerTabs;
