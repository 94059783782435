// @flow

// core
import React, { type Node } from 'react';

// libs
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

interface IDropdownButtonItemProps {
	children: Node;
	/**
	 * @default ''
	 */
	icon: Node;
	sx: Record<any, any>;
	onButtonClick: () => void;
}

export const DropdownButtonItem = ({ children, sx, icon = '', onButtonClick }: IDropdownButtonItemProps) => {
	const handleBtnClick = e => {
		e.stopPropagation();
		onButtonClick();
	};

	return (
		<MenuItem sx={{ ...styles.container, ...sx }}>
			<Button startIcon={icon} sx={styles.button} onClick={handleBtnClick}>
				{children}
			</Button>
			<Divider sx={styles.divider} />
		</MenuItem>
	);
};

const styles = {
	container: { height: '36px', padding: 0, margin: 0 },
	button: { justifyContent: 'left', height: '100%', width: '100%' },
	divider: { width: '100%', bottom: 0, position: 'absolute' },
};
