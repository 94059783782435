import React from 'react';

import { Box, MenuItem, Select, Typography } from '@mui/material';

import { Controller } from 'react-hook-form';

import createStyles from './form-styles';

const FormSelect = ({ isSingle, label, control, name, isRequired, data, selectDisabled }) => {
	const classes = createStyles();

	return (
		<Box sx={isSingle ? classes.singleBox : {}}>
			{isRequired ? (
				<Box sx={classes.labelBox}>
					<Typography sx={classes.label}>{label}</Typography>
					<Box color="red" display="inline">
						&nbsp;*
					</Box>
				</Box>
			) : (
				<Typography sx={classes.label}>{label}</Typography>
			)}
			<Controller
				control={control}
				name={name}
				render={({ field }) => (
					<Select {...field} disabled={selectDisabled} style={{ padding: 0 }} sx={classes.inputShort}>
						{data.map(({ id, option }) => (
							<MenuItem key={id} value={option}>
								{option}
							</MenuItem>
						))}
					</Select>
				)}
				rules={{ required: isRequired }}
			/>
		</Box>
	);
};

export default FormSelect;
