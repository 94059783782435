import React, { createContext, useContext, useRef, useState } from 'react';
import { useAuth } from '@worklist-2/core/src';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const ImageViewerSeriesNavigatorContext = createContext({});

const ImageViewerSeriesNavigatorContextProvider = ({ children }) => {
	const wonIvAutoOpenStudyExplorer = useBooleanFlagValue('won-iv-auto-open-study-explorer');

	const { loggedInUser, accessToken } = useAuth();
	const isSharedStudy = accessToken && !loggedInUser;

	const [isExpandedLeftSection, setIsExpandedLeftSection] = useState(
		isSharedStudy || (wonIvAutoOpenStudyExplorer && localStorage.getItem('AutoOpenStudyExplorer'))
	);
	const [isExpandedRightSection, setIsExpandedRightSection] = useState(false);
	const [manager, setManager] = useState({
		isShown: false,
		data: '',
	});

	const cachedThumbnailsRef = useRef({});

	return (
		<ImageViewerSeriesNavigatorContext.Provider
			value={{
				isExpandedLeftSection,
				setIsExpandedLeftSection,
				isExpandedRightSection,
				setIsExpandedRightSection,
				manager,
				setManager,
				cachedThumbnailsRef,
			}}
		>
			{children}
		</ImageViewerSeriesNavigatorContext.Provider>
	);
};

const useImageViewerSeriesNavigatorContext = () => useContext(ImageViewerSeriesNavigatorContext);

export {
	useImageViewerSeriesNavigatorContext,
	ImageViewerSeriesNavigatorContext,
	ImageViewerSeriesNavigatorContextProvider,
};
