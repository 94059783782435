import React, { useMemo, useState } from 'react';
import MultipleSelectChip from '../MultipleSelectChip';
import _ from 'lodash';
import Dropdown from '@worklist-2/ui/src/views/HangingProtocolView/components/Dropdown';

const NewRoleField = ({ roles, user, onChange, dismiss, fieldName }) => {
	const [value, setValue] = useState(user.RoleId);
	const roleOptions = useMemo(
		() =>
			_.map(roles, role => ({
				label: role.RoleName,
				value: role.RoleId || role.id,
			})),
		[roles]
	);

	const handleChange = key => {
		const { label, value } = roleOptions.find(({ value }) => value === key);
		onChange({ RoleName: label, RoleId: value });
		setValue(key);
	};

	return (
		<div>
			<Dropdown
				classKey="lightContainer"
				fieldName={fieldName}
				menuKey="lightMenu"
				options={roleOptions}
				placeholder="Assign Role"
				styles={{
					inputContainer: {
						width: '480px',
						border: '0px',
						'&:hover': {
							cursor: 'pointer',
							border: '0px',
							background: 'none',
						},
					},
				}}
				value={value}
				onChange={handleChange}
				onEnterKeyPressed={dismiss}
			/>
		</div>
	);
};
export default NewRoleField;
