import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useCrmContext } from '../../../context/CRMContext';
import { useLiveChatContext } from '../LiveChat/LiveChatContext';

import {
	CustomSwitch,
	Header,
	Knob,
	StyledTableContainer,
	Title,
} from '../../../../../Breeze/src/views/CrmView/styles';
import AccountsTable from './AccountsTable';
import AccountsFilterPanel from './AccountsFilterPanel/AccountsFilterPanel';

import { ViewListOutlined, HomeOutlined } from '@mui/icons-material';

const schema = yup
	.object()
	.shape({
		AccountName: yup.string(),
		ClientId: yup.string(),
		BillingCountry: yup.string(),
		Phone: yup.string(),
		BillingState: yup.string(),
		BillingCity: yup.string(),
		AccountOwner: yup.string(),
		AccountType: yup.string(),
	})
	.required();

const CRMAccounts = () => {
	const [field, setField] = useState(null);
	const [searchText, setSearchText] = useState(null);

	const navigate = useNavigate();
	const { showDetails, toggleDetails, isChatOpen: isCRMChatOpen } = useCrmContext();
	const { isChatOpen: isLiveChatOpen } = useLiveChatContext();

	const { control, setValue, getValues, resetField, reset } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			AccountName: '',
			ClientId: '',
			BillingCountry: '',
			Phone: '',
			BillingState: '',
			BillingCity: '',
			AccountOwner: '',
			AccountType: '',
		},
	});

	const formData = getValues();
	const filters = useMemo(() => {
		return Object.keys(formData)
			.filter(key => formData[key] !== '')
			.map(key => ({
				type: key,
				value: formData[key],
			}));
	}, [formData]);

	useEffect(() => {
		return () => {
			toggleDetails();
		};
	}, []);

	const onClear = filterType => {
		resetField(filterType);
		setSearchText('');
	};

	const onClearAllFilter = () => {
		reset();
		setSearchText('');
	};

	return (
		<>
			<Header>
				<Title>
					<ViewListOutlined sx={{ fontSize: 29, mr: '12px' }} />
					<span>Accounts</span>
				</Title>
				<CustomSwitch>
					<Knob onClick={() => navigate('/')}>
						<HomeOutlined />
					</Knob>
					<label>Accounts</label>
				</CustomSwitch>
				{filters.length > 0 && (
					<AccountsFilterPanel filters={filters} onClear={onClear} onClearAllFilter={onClearAllFilter} />
				)}
			</Header>
			<StyledTableContainer
				sx={{
					width: isLiveChatOpen
						? 'calc(100% - 620px)'
						: showDetails || isCRMChatOpen
						? 'calc(100% - 24px)'
						: 'calc(100% - 51px)',
				}}
				className="invisible-scrollbar"
			>
				<AccountsTable
					field={field}
					setField={setField}
					searchText={searchText}
					setSearchText={setSearchText}
					control={control}
					filters={filters}
					setValue={setValue}
				/>
			</StyledTableContainer>
		</>
	);
};

export default CRMAccounts;
