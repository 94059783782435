export default () => {
	return {
		plusIconBox: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginLeft: '10px',
			cursor: 'pointer',
			'& svg': {
				padding: '2px',
			},
			'& svg:hover': {
				padding: '2px',
				backgroundColor: '#E5E5E5',
				borderRadius: '50%',
			},
		},
		deviceHubIcon: {
			fontSize: 20,
			color: '#4DA8EA',
		},
		devicesListBox: {
			'::-webkit-scrollbar-thumb': {
				backgroundColor: 'gray',
			},
			'::-webkit-scrollbar-track': {
				background: 'transparent',
			},
			height: 'calc(100vh - 210px)',
			margin: '10px 0',
			overflowY: 'auto',
		},
		noResultBox: {
			height: '100%',
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		noResultLabel: {
			textAlign: 'center',
			fontSie: '16px',
			margin: '8px 0',
			color: 'black',
		},
	};
};
