import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

const RejectionReason = ({ selectedReasons }) => {
	const { t } = useTranslation('worklist');

	return (
		<Box
			id="rejection-reason-text-box"
			sx={{ maxWidth: '206px', ml: '4px', display: 'flex', alignItems: 'center', gap: 1 }}
		>
			{selectedReasons?.length > 0 && (
				<>
					<Typography sx={{ padding: '0 0 12px 4px', fontSize: '24px' }}>.</Typography>
					<Typography
						data-testid="rejectionReasonText"
						id="rejection-reason-text"
						sx={{
							maxWidth: 192,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							'&:hover': {
								color: 'rsPrimary.main',
							},
						}}
					>
						{selectedReasons[0].display}
					</Typography>
				</>
			)}
			{selectedReasons?.length >= 2 && (
				<Tooltip
					componentsProps={{
						tooltip: {
							sx: {
								backgroundColor: '#393939',
								border: '1px solid rgba(196, 196, 196, 0.15)',
								borderRadius: '5px',
								boxSizing: 'border-box',
							},
						},
					}}
					data-testid="moreRejectionReasonToolTip"
					placement="bottom-start"
					title={
						<Box>
							<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
								<SummarizeOutlinedIcon
									sx={{
										color: 'rsPrimary.main',
									}}
								/>
								<Typography
									sx={{
										fontSize: '12px',
										color: 'rgba(255, 255, 255, 0.6)',
									}}
								>
									{selectedReasons?.length >= 3
										? t('rejectStudyStatus.rejectReasons')
										: t('rejectStudyStatus.rejectReason')}
								</Typography>
							</Box>

							{selectedReasons?.map((reason, i) => {
								if (i > 0) {
									return (
										<Typography
											key={reason.code}
											sx={{
												fontSize: '14px',
												lineHeight: '20px',
												letterSpacing: '0.25px',
												margin: '4px',
											}}
										>
											{reason.display}
										</Typography>
									);
								}
							})}
						</Box>
					}
				>
					<Button
						data-testid="showMoreReasons"
						style={{
							minWidth: 4,
							height: '22px',
							padding: 4,
							color: '#42A5F5',
							background: 'rgba(66, 165, 245, 0.1)',
							borderRadius: '4px',
						}}
					>
						{`+${selectedReasons?.length - 1}`}
					</Button>
				</Tooltip>
			)}
		</Box>
	);
};

RejectionReason.propTypes = {
	selectedReasons: PropTypes.array,
};

export default RejectionReason;
