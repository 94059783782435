// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CI5uhZVrzYvSvQqjc9Fl{margin:0 70px}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/common/MainArea/Draft/AttachmentBlocks/VideoAttachment.module.scss"],"names":[],"mappings":"AAAA,sBACC,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoBlock": "CI5uhZVrzYvSvQqjc9Fl"
};
export default ___CSS_LOADER_EXPORT___;
