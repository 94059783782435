/* Generated automagically by FhirStarter - DO NOT EDIT */
const organizationAffiliationSchema = {
	resourceType: 'OrganizationAffiliation',
	identifier: ['__Identifier__'],
	active: '__boolean__',
	period: '__Period__',
	organization: '__Reference(Organization)__',
	participatingOrganization: '__Reference(Organization)__',
	network: ['__Reference(Organization)__'],
	code: ['__CodeableConcept__'],
	specialty: ['__CodeableConcept__'],
	location: ['__Reference(Location)__'],
	healthcareService: ['__Reference(HealthcareService)__'],
	telecom: ['__ContactPoint__'],
	endpoint: ['__Reference(Endpoint)__'],
};
export default organizationAffiliationSchema;
