/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
import WorkflowAutomationSchema from './schemata/WorkflowAutomationSchema';
/************************************************************************************************************************
Resource: WorkflowAutomationResource
Reference: N/A
Proprietary RamSoft resource
 ************************************************************************************************************************/

export default class WorkflowAutomationResource extends FhirDomainResource {
	constructor(resourceString) {
		super(resourceString, WorkflowAutomationSchema);
		this.resourceType = 'WorkflowAutomation';
		this.populateFields();
	}
}
