export default function CheckVideoImagesTransferSyntax(InputTransferSyntax) {
	const VideoImagesTransferSyntax = {
		UID_MPEG2MainProfileAtMainLevelTransfer: '1.2.840.10008.1.2.4.100',
		UID_MPEG2MainProfileAtHighLevelTransfer: '1.2.840.10008.1.2.4.101',
		UID_MPEG4HighProfileLevel4_1Transfer: '1.2.840.10008.1.2.4.102',
		UID_MPEG4BDcompatibleHighProfileLevel4_1Transfer: '1.2.840.10008.1.2.4.103',
		UID_MPEG4HighProfileLevel4_2_For2DVideoTransfer: '1.2.840.10008.1.2.4.104',
		UID_MPEG4HighProfileLevel4_2_For3DVideoTransfer: '1.2.840.10008.1.2.4.105',
		UID_MPEG4StereoHighProfileLevel4_2Transfer: '1.2.840.10008.1.2.4.106',
	};

	return (
		InputTransferSyntax === VideoImagesTransferSyntax.UID_MPEG2MainProfileAtMainLevelTransfer ||
		InputTransferSyntax === VideoImagesTransferSyntax.UID_MPEG2MainProfileAtHighLevelTransfer ||
		InputTransferSyntax === VideoImagesTransferSyntax.UID_MPEG4HighProfileLevel4_1Transfer ||
		InputTransferSyntax === VideoImagesTransferSyntax.UID_MPEG4BDcompatibleHighProfileLevel4_1Transfer ||
		InputTransferSyntax === VideoImagesTransferSyntax.UID_MPEG4HighProfileLevel4_2_For2DVideoTransfer ||
		InputTransferSyntax === VideoImagesTransferSyntax.UID_MPEG4HighProfileLevel4_2_For3DVideoTransfer ||
		InputTransferSyntax === VideoImagesTransferSyntax.UID_MPEG4StereoHighProfileLevel4_2Transfer
	);
}
