// Avatar colour based on name
const generateAvatarColour = avatarUsersName => {
	let hash = 0;

	for (let i = 0; i < avatarUsersName?.length; i += 1) {
		hash = avatarUsersName.charCodeAt(i) + ((hash << 5) - hash);
	}

	let colour = '#';

	for (let j = 0; j < 3; j += 1) {
		const value = (hash >> (j * 8)) & 0xff;
		colour += `00${value.toString(16)}`.slice(-2);
	}
	return colour;
};

export { generateAvatarColour };
