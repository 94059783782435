import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

// libraries
import classnames from 'classnames';
import PropTypes from 'prop-types';

// mui
import Box from '@mui/material/Box';

import { getSearchScopeList, useAuth, searchScopes, useAppModeContext, useGlobalStore } from '@worklist-2/core/src';

import GlobalSearchV2 from '@rs-ui/components/GlobalSearch/GlobalSearchV2/GlobalSearchV2';

import { contextListContainValue } from '@rs-components/GlobalHeader/utils/containsObjectWithParam';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { isDvInMultiMonitor } from '@worklist-2/worklist/src/DocumentViewerV3/utils';
import { useRecognitionContext } from '@worklist-2/worklist/src/DocumentViewerV3/contexts/RecognitionContext';

const GlobalHeader = ({
	className,
	children,
	contextList = getSearchScopeList(),
	additionalSearchParams,
	setParentData,
	initialValue,
}) => {
	const sprinterGlobalsearchHelp = useBooleanFlagValue('sprinter-globalsearch-help');
	const galaxyDvEnableDvFullScreen = useBooleanFlagValue('galaxy-dv-enable-dv-full-screen');
	const galaxyDvEnableAugnitoSdk = useBooleanFlagValue('galaxy-dv-enable-augnito-sdk');
	const { isHiddenGlobalSearch } = useAppModeContext();
	const { globalPermissionsForLoggedUser } = useAuth();
	const [contextListWithPermission, setContextList] = useState(contextList);

	const { vrViaDvToolbar: vrViaDvToolbarFromContext } = useRecognitionContext();
	const vrViaDvToolbarFromStore = useGlobalStore(state => state.vrViaDvToolbar);

	const vrViaDvToolbar = galaxyDvEnableAugnitoSdk ? vrViaDvToolbarFromStore : vrViaDvToolbarFromContext;

	const [searchParams] = useSearchParams();
	const screenNumber = searchParams.get('screenNumber');

	useEffect(() => {
		const newContextList = [...contextListWithPermission];

		if (!contextListContainValue(newContextList, 'label', 'Study') && globalPermissionsForLoggedUser?.SearchStudy) {
			newContextList.push({
				label: 'Study',
				placeholder: 'Study',
				context: searchScopes.study,
			});
		}

		if (
			!contextListContainValue(newContextList, 'label', 'DICOM Query') &&
			globalPermissionsForLoggedUser?.SearchDicomQuery
		) {
			newContextList.push({
				label: 'DICOM Query',
				placeholder: 'Dicom',
				context: searchScopes.dicom,
			});
		}

		if (
			!contextListContainValue(newContextList, 'label', 'Patient') &&
			globalPermissionsForLoggedUser?.SearchPatient
		) {
			newContextList.push({
				label: 'Patient',
				placeholder: 'Patient',
				context: searchScopes.patientElk,
			});
		}

		if (
			!contextListContainValue(newContextList, 'label', 'Organization') &&
			globalPermissionsForLoggedUser?.SearchOrganization
		) {
			newContextList.push({
				label: 'Organization',
				placeholder: 'Organization',
				context: searchScopes.organizationElk,
			});
		}

		if (!contextListContainValue(newContextList, 'label', 'User') && globalPermissionsForLoggedUser?.SearchUser) {
			newContextList.push({
				label: 'User',
				placeholder: 'Practitioner',
				context: searchScopes.practitionerElk,
			});
		}

		if (
			!contextListContainValue(newContextList, 'label', 'Help') &&
			globalPermissionsForLoggedUser?.SearchHelp &&
			sprinterGlobalsearchHelp
		) {
			newContextList.push({
				label: 'Help',
				placeholder: 'Articles',
				context: searchScopes.helpElk,
			});
		}

		if (
			!contextListContainValue(newContextList, 'label', 'Marketplace') &&
			globalPermissionsForLoggedUser?.SearchMarketplace
		) {
			newContextList.push({
				label: 'Marketplace',
				placeholder: 'Apps',
				context: searchScopes.marketplace,
			});
		}

		setContextList(newContextList);
	}, [globalPermissionsForLoggedUser]);

	const shouldHideHeader = () => galaxyDvEnableDvFullScreen && isDvInMultiMonitor(screenNumber) && !vrViaDvToolbar;

	if (shouldHideHeader()) {
		return null;
	}

	return (
		<div
			className={classnames(className)}
			id="page-header"
			style={{ display: `${isHiddenGlobalSearch ? 'none' : 'flex'}` }}
		>
			{globalPermissionsForLoggedUser?.Search ? (
				<GlobalSearchV2
					additionalSearchParams={additionalSearchParams}
					contextList={contextListWithPermission}
					initialValue={initialValue}
					setParentData={setParentData}
				/>
			) : (
				<Box sx={{ height: '61px' }} />
			)}
			{/* only render VR Speech Icon after FF is loaded */}
			{children}
		</div>
	);
};

GlobalHeader.propTypes = {
	/**
	 * List of items available to be selected.
	 */
	contextList: PropTypes.array,
};

export default GlobalHeader;
