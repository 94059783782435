import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const customHeadLabelRenderer = (
	label,
	sortArrow,
	dragRef,
	children,
	forceShowRef,
	customWrapperStyles = {},
	hasFilterType = true
) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(forceShowRef.current);
	}, [forceShowRef]);

	return hasFilterType ? (
		<div
			ref={dragRef}
			className={classnames('table-head-cell', show && 'hover')}
			onMouseEnter={() => {
				forceShowRef.current = true;
			}}
			onMouseLeave={() => {
				forceShowRef.current = false;
			}}
		>
			<div className="table-head-cell-content-root">
				<div className="table-head-cell-content-wrapper" style={{ ...customWrapperStyles }}>
					{children}
				</div>

				<div className="sort-button-wrapper">{sortArrow}</div>
			</div>

			<div className="table-head-overlay table-head-overlay-filter">{label}</div>
			<div className="table-head-sizer">{label}</div>
		</div>
	) : (
		<div>
			<div>{label}</div>
		</div>
	);
};

customHeadLabelRenderer.propTypes = {
	/**
	 * name of column to be displayed
	 */
	label: PropTypes.string,
	/**
	 * Custom styling of label
	 */
	customWrapperStyles: PropTypes.object,
	/**
	 * indicate if columns has filter type or not
	 */
	hasFilterType: PropTypes.bool,
};

export default customHeadLabelRenderer;
