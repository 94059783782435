import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';
import _ from 'lodash';

export const VALUES = [
	{ display: 'Ramsoft 1', code: 'A', id: '1' },
	{ display: 'Ramsoft 2', code: 'B', id: '2' },
	{ display: 'Ramsoft 3', code: 'C', id: '3' },
];

export const FIELDS = [
	{
		id: 1,
		label: 'Study Date/Time',
		tag: '00080020',
		vr: 'DA',
		type: 'datePicker',
	},
	{
		id: 2,
		label: 'Patient Name',
		tag: '00100010',
		vr: 'PN',
		type: 'textField',
	},
	{
		id: 3,
		label: 'Patient ID',
		tag: '00100020',
		vr: 'DA',
		type: 'textField',
	},
	{
		id: 4,
		label: '# of Instances',
		tag: '00201208',
		vr: 'DA',
		type: 'textField',
	},
	{
		id: 5,
		label: 'Accession #',
		tag: '00080050',
		vr: 'DA',
		type: 'textField',
	},
	{
		id: 6,
		label: 'Age at Study',
		tag: '00101010',
		vr: 'DA',
		type: 'textField',
	},
	{
		id: 7,
		label: 'Anatomic Focus',
		tag: '00080063',
		vr: 'DA',
		type: 'select',
	},
	{
		id: 8,
		label: 'Birth Date',
		tag: '00100030',
		vr: 'DA',
		type: 'datePicker',
	},
	{
		id: 9,
		label: 'Gender',
		tag: '00100040',
		vr: 'DA',
		type: 'select',
	},
	{
		id: 10,
		label: 'Imaging Organization',
		tag: '00080080',
		vr: 'DA',
		type: 'textField',
	},
	{
		id: 11,
		label: 'Managing Organization',
		tag: '00100021',
		vr: 'DA',
		type: 'textField',
	},
	{
		id: 12,
		label: 'Modality',
		tag: '00080060',
		vr: 'CS',
		type: 'select',
	},
	{
		id: 13,
		label: 'Study Priority',
		tag: '0032000C',
		vr: 'CS',
		type: 'select',
	},
	{
		id: 14,
		label: 'Reading Physician',
		tag: '00081060',
		vr: 'PN',
		type: 'textField',
	},
	{
		id: 15,
		label: 'Referring Physician',
		tag: '00080090',
		vr: 'PN',
		type: 'textField',
	},
	{
		id: 16,
		label: 'SSN',
		tag: '00101000',
		vr: 'LO',
		type: 'textField',
	},
	{
		id: 17,
		label: 'Study Description',
		tag: '00081030',
		vr: 'LO',
		type: 'textField',
	},
	{
		id: 18,
		label: 'Study ID',
		tag: '00200010',
		vr: 'SH',
		type: 'textField',
	},
	{
		id: 19,
		label: 'Study Status',
		tag: '0032000A',
		vr: 'SH',
		type: 'select',
	},
	{
		id: 20,
		label: 'Study UID',
		tag: '0020000D',
		vr: 'UI',
		type: 'textField',
	},
	{
		id: 21,
		label: 'Assigning Authority',
		tag: '00100021',
		vr: 'LO',
		type: 'textField',
	},
];

export const getTaskPayload = (device, extension, subscriptionId) => {
	const getTargetVal = extensionName => _.find(device.extension, ['url', extensionName])?.valueString;

	const deviceType = device.type.coding[0]?.code;

	let authenticationType;
	let searchUri;
	let retrieveUri;
	let storeUri;
	let userName;

	if (deviceType === 'dicom-web') {
		authenticationType = _.find(device.extension, [
			'url',
			fhirExtensionUrls.device.dicomweb.authenticationtype,
		])?.valueInteger;

		searchUri = getTargetVal(fhirExtensionUrls.device.dicomweb.searchuri);
		retrieveUri = getTargetVal(fhirExtensionUrls.device.dicomweb.retrieveuri);
		storeUri = getTargetVal(fhirExtensionUrls.device.dicomweb.storeuri);
		userName = getTargetVal(fhirExtensionUrls.device.dicomweb.username);
	}

	const managingOrg = device.extension.find(ext => ext.url == fhirExtensionUrls.device.managingOrganization);

	return {
		id: '-1',
		resourceType: 'Task',
		status: 'requested',
		intent: 'plan',
		code: {
			coding: [
				{
					code: 'approve',
					display: 'Activate/approve the focal resource  ',
				},
			],
		},
		reasonCode: {
			text: 'DicomQuery',
		},
		for: {
			id: device.id,
			extension: [
				{
					url: fhirExtensionUrls.task.forAeTitle,
					valueString: getTargetVal(fhirExtensionUrls.device.aeTitle),
				},
				{
					url: fhirExtensionUrls.task.peerPort,
					valueString: getTargetVal(fhirExtensionUrls.device.dicomServerPort),
				},
				{
					url: fhirExtensionUrls.task.peerHost,
					valueString: getTargetVal(fhirExtensionUrls.device.dicomServerHostName),
				},
				{
					url: fhirExtensionUrls.task.useTls,
					valueBoolean: false,
				},
				{
					url: fhirExtensionUrls.task.deviceType,
					valueString: deviceType,
				},
				{
					url: fhirExtensionUrls.device.dicomweb.authenticationtype,
					valueInteger: authenticationType,
				},
				{
					url: fhirExtensionUrls.device.dicomweb.searchuri,
					valueString: searchUri,
				},
				{
					url: fhirExtensionUrls.device.dicomweb.retrieveuri,
					valueString: retrieveUri,
				},
				{
					url: fhirExtensionUrls.device.dicomweb.storeuri,
					valueString: storeUri,
				},
				{
					url: fhirExtensionUrls.device.dicomweb.username,
					valueString: userName,
				},
			],
		},
		owner: {
			id: extension.valueReference.id,
			display: extension.valueReference.display,
		},
		input: [
			{
				type: {
					text: 'QueryRetrieveInformationModelSOPClassUID',
				},
				valueString: '1.2.840.10008.5.1.4.1.2.2.1',
			},
			{
				type: {
					text: 'QueryRetrieveLevel',
				},
				valueString: 'STUDY',
			},
			{
				type: {
					text: 'DicomJson',
				},
				valueString: JSON.stringify(
					FIELDS.reduce(
						(existingObj, field) => ({
							...existingObj,
							[field.tag]: {
								vr: field.vr,
								Value: [field.value || ''],
							},
						}),
						{}
					)
				),
			},
		],
		extension: [
			{
				url: fhirExtensionUrls.common.organization,
				valueReference: {
					id: managingOrg.valueReference.id,
					reference: `Organization/${managingOrg.valueReference.id}`,
					display: managingOrg.valueReference.display,
				},
			},
			{
				url: 'http://www.ramsoft.com/fhir/extension/signalRSubscription',
				valueString: `${subscriptionId}`,
			},
		],
	};
};

export const getSubscriptionPayload = (extension, subscriptionId) => ({
	criteria: `Task?owner=${extension.valueReference.display}&status=rejected,cancelled,failed,completed,entered-in-error`,
	id: '-1',
	resourceType: 'Subscription',
	status: 'requested',
	reason: 'Worklist GUI Subscription',
	channelType: 'websocket',
	extension: [
		{
			url: 'http://www.ramsoft.com/fhir/extension/SubscriptionUID',
			valueString: `${subscriptionId}`,
		},
	],
	channel: {
		type: 'websocket',
		extension: [
			{
				url: 'http://www.ramsoft.com/fhir/StructureDefinition/SubType',
				valueString: 'SignalRWithData',
			},
		],
	},
});
