import styled from 'styled-components';

export const Content = styled.div`
	margin-top: 20px;

	p, span {
		font-family: Roboto;
		font-size: 18px; !important
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
	}

	h5 {
		margin: 39px 0px;
		font-family: Roboto;
		font-size: 24px !important;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
	}

	span,
	li {
		font-family: Roboto;
		font-size: 18px !important;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
	}
`;

export const Container = styled.div`
	padding: 20px 70px;
	display: flex;
	flex-direction: column;
	color: #FFF !important;
	font-family: Roboto !important;
	
	p {
		font-family: Roboto !important;
		font-size: 18px;
	}
	a {
		color: #42a5f5 !important;
		text-decoration: underline !important;
	}
	table.table-container {
		td, th {
			border: 1px solid #d7d7d7;
			padding: 10px;
		}
		tr>td:first-child {
			text-align: center;
		}
	}
`;

export const HeroBanner = styled.div`
	height: 262px;
	padding: 0 20px 0 20px;
	margin-top: 30px;
	border-radius: 5px;
	background-color: #42a5f5;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	img {
		width: 309px;
	}
`;

export const BannerBody = styled.div`
	flex: 1;

	small {
		font-family: Roboto;
		font-size: 24px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
	}
`;

export const BannerHeader = styled.p`
	font-family: Roboto;
	font-size: 48px !important;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #fff;
	margin: 0px;
	margin-bottom: 21px;
`;

export const VideoSlides = styled.div`
	display: flex;
	flex-direction: row;
	overflow-x: auto;
	overflow-x: hidden;
`;

export const VideoSlide = styled.div`
	display: flex;
	flex-direction: column;
	overflow-x: auto;
	margin: 0px 5px;

	.video-cover {
		cursor: pointer;
		width: 100%;
		height: 187px;
		flex-grow: 0;
		background-color: #d7d7d7;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	p {
		font-family: Roboto;
		font-size: 20px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
	}

	span {
		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
	}
`;

export const StrongText = styled.p`
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	margin: 0px;
`;

export const TrainingPreviewWrapper = styled.div`
	padding: 0px;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;

	@keyframes fade {
		0%,
		100% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
	}

	p {
		font-family: Roboto;
		font-size: 34px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
		margin-bottom: 13px;
	}

	span {
		font-family: Roboto;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
	}
`;
// display: flex;
// flex-direction: column;
export const VideoPlayerWrapper = styled.div`
	background-color: #d7d7d7;
	border-radius: 5px;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
	overflow: hidden;

	video {
		width: 100%;
		height: 100%;
		display: block;
	}
`;

export const ControlsMask = styled.div`
	background: rgba(0, 0, 0, 0.3);
	display: flex;
	flex-direction: row;
	z-index: 1;
	position: absolute;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
	height: 100%;
	margin: auto;
	animation: fade 4s linear;
`;

export const TimeControlsWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-evenly;

	.time_progressbarContainer {
		background-color: #c4c4c4;
		width: 100%;
		flex: 1;
		height: 4px;
		position: relative;
	}

	.time_progressBar {
		background-color: #2982d4;
		height: 100%;
	}

	.controlsTime {
		color: white;
	}
`;

export const PictureModeVideoPlayer = styled.div`
	width: 400px;
	min-height: 225px;
	background: #272727;
	z-index: 2;
	position: fixed;
	bottom: 30px;
	right: 30px;

	.video-details {
		margin: 20px 10px;
		padding: 10px 0px;

		p {
			margin: 0px;
			margin-bottom: 20px;
			font-family: Roboto;
			font-size: 20px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: #fff;
		}

		span {
			font-family: Roboto;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: #fff;
		}
	}
`;
