// core
import React, { useCallback, useState } from 'react';
// mui
import { styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MuiPopover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// mui icons
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RewindIconUnstyled from '@mui/icons-material/History';
import NotesIcon from '@mui/icons-material/Notes';
import SquareIcon from '@mui/icons-material/Square';
// libraries
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

const HistoryPopover = ({ data, isLoading, onHistoryItemClick }) => {
	const [historyPopoverAnchor, setHistoryPopoverAnchor] = useState(undefined);

	const { t } = useTranslation('organization');
	const onToggleShowHistory = useCallback(
		e => setHistoryPopoverAnchor(prev => (prev ? undefined : e.currentTarget)),
		[]
	);

	return (
		<>
			<RewindIcon isHighlighted={!!historyPopoverAnchor} onClick={onToggleShowHistory} />

			<Popover anchorEl={historyPopoverAnchor} onClose={onToggleShowHistory}>
				<header>
					<HistoryIcon />

					<Typography
						sx={{
							color: 'primary.contrastText',
							display: 'inline',
							fontSize: '12px',
						}}
					>
						{t('History')}
					</Typography>

					<LoadingIndicator isLoading={isLoading} />
				</header>

				<Stack
					sx={{
						maxHeight: 300,
						overflowY: 'scroll',
						padding: '8px 0 12px 0',
					}}
				>
					{_.map(data, (groupedHistory, heading) => (
						<HistoryListItem
							key={heading}
							data={groupedHistory}
							heading={heading}
							onHistoryItemClick={onHistoryItemClick}
						/>
					))}
				</Stack>
			</Popover>
		</>
	);
};

export default HistoryPopover;

//																			   //
// ============================ Partial components =========================== //
//																			   //

const HistoryIcon = () => (
	<span
		style={{
			position: 'relative',
			marginRight: '12px',
			marginLeft: '16px',
		}}
	>
		<NotesIcon
			sx={{
				fontSize: '12px',
				color: 'primary.contrastText',
			}}
		/>
		<EditIcon
			sx={{
				position: 'absolute',
				bottom: '2.5px',
				right: '-5px',
				fontSize: '8px',
				color: 'primary.contrastText',
			}}
		/>
	</span>
);

const HistoryListItem = ({ heading, data, onHistoryItemClick: _onHistoryItemClick }) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const onToggleIsExpanded = useCallback(() => setIsExpanded(prev => !prev), []);

	const onHistoryItemClick = record => e => {
		e.stopPropagation();
		_onHistoryItemClick?.(record);
	};

	return (
		<ListItem
			sx={{
				padding: '0 16px',
				flexDirection: 'column',
				alignItems: 'self-start',
				'&:not(:last-child)': { marginBottom: '2px' },
			}}
			onClick={onToggleIsExpanded}
		>
			<header
				style={{
					display: 'flex',
					alignItems: 'center',
					width: '100%',
					cursor: 'pointer',
				}}
			>
				<ListItemIcon sx={{ minWidth: '22px' }}>
					<RewindIconUnstyled sx={{ fontSize: '14px' }} />
				</ListItemIcon>

				<ListItemText
					sx={{
						flex: 1,
						cursor: 'pointer',
						whiteSpace: 'nowrap',
						'& *': { fontSize: '12px !important' },
					}}
				>
					{heading}
				</ListItemText>
				<ListItemIcon sx={{ minWidth: 14 }}>
					<ExpandMoreIcon
						sx={{
							fontSize: '14px',
							transition: 'transform .3s ease',
							transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
						}}
					/>
				</ListItemIcon>
			</header>

			<Collapse in={isExpanded} sx={{ width: '100%' }}>
				<List sx={{ padding: '2px 0' }}>
					{data.map(r => (
						<ListItemButton
							key={`${r.user}_${r.lastUpdated}`}
							sx={{
								display: 'flex',
								alignItems: 'center',
								padding: '0 4px 0 22px',
							}}
							onClick={onHistoryItemClick(r)}
						>
							<HistoryRecordBullet />
							<HistoryRecordInfo updatedAt={r.lastUpdated} userName={r.user} />
						</ListItemButton>
					))}
				</List>
			</Collapse>
		</ListItem>
	);
};

const HistoryRecordBullet = () => (
	<ListItemIcon sx={{ minWidth: '17px' }}>
		<SquareIcon
			sx={{
				color: 'icon.primary',
				fontSize: '8px',
			}}
		/>
	</ListItemIcon>
);

const HistoryRecordInfo = ({ userName, updatedAt }) => (
	<ListItemText
		sx={{
			color: 'icon.primary',
			'& *': { fontSize: '12px !important' },
			'& > *': {
				display: 'flex',
				justifyContent: 'space-between',
			},
		}}
	>
		<span>{userName}</span>
		<span>{updatedAt}</span>
	</ListItemText>
);

const LoadingIndicator = ({ isLoading }) =>
	isLoading ? (
		<CircularProgress
			size={18}
			sx={{
				right: 16,
				color: 'inherit',
				position: 'absolute',
			}}
		/>
	) : null;

const Popover = ({ children, anchorEl, onClose }) => (
	<MuiPopover
		PaperProps={{
			sx: {
				borderRadius: '10px',
				border: '1px solid',
				borderColor: 'text.border',
				padding: '20px 0 0 0',
				marginTop: '24px',
				width: 260,
				maxWidth: 260,
				backgroundColor: 'primary.main',
			},
		}}
		anchorEl={anchorEl}
		anchorOrigin={{
			horizontal: 'right',
			vertical: 'bottom',
		}}
		id="update-history-log"
		open={!!anchorEl}
		transformOrigin={{
			horizontal: 'right',
			vertical: 'top',
		}}
		onClose={onClose}
	>
		{children}
	</MuiPopover>
);

//																			   //
// ============================ Component proptypes ========================== //
//																			   //

HistoryPopover.propTypes = {
	/**
	 * Data
	 */
	data: PropTypes.array,
	/**
	 * Is loading?
	 */
	isLoading: PropTypes.bool,
	/**
	 * Item click callback (possibly to revert to a version)
	 */
	onHistoryItemClick: PropTypes.func,
};

HistoryListItem.propTypes = {
	/**
	 * Heading
	 */
	heading: PropTypes.string,
	/**
	 * Data for the section
	 */
	data: PropTypes.arrayOf(PropTypes.object),
	/**
	 * Item click callback (possibly to revert to a version)
	 */
	onHistoryItemClick: PropTypes.func,
};

HistoryRecordInfo.propTypes = {
	/**
	 * Author name
	 */
	userName: PropTypes.string,
	/**
	 * Last update date (formatted as string)
	 */
	updatedAt: PropTypes.string,
};

LoadingIndicator.propTypes = {
	/**
	 * Is loading?
	 */
	isLoading: PropTypes.bool,
};

Popover.propTypes = {
	/**
	 * Children
	 */
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.node]),
	/**
	 * Anchor element from which the Popover originates
	 */
	anchorEl: PropTypes.any,
	/**
	 * Close callback
	 */
	onClose: PropTypes.func,
};

//																			   //
// ============================= Styled components =========================== //
//																			   //

const RewindIcon = styled(({ isHighlighted: __, ...props }) => <RewindIconUnstyled {...props} />)(
	({ isHighlighted, theme }) => ({
		color: isHighlighted ? theme.palette.primary.contrastText : theme.palette.icon.primary,
		cursor: 'pointer',
		transition: 'color .3s ease',

		'&:hover': { color: theme.palette.primary.contrastText },
	})
);
