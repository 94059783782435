/* Generated automagically by FhirStarter - DO NOT EDIT */
const encounterSchema = {
	resourceType: 'Encounter',
	identifier: ['__Identifier__'],
	status: '__code__',
	statusHistory: [
		{
			status: '__code__',
			period: '__Period__',
		},
	],
	class: '__Coding__',
	classHistory: [
		{
			class: '__Coding__',
			period: '__Period__',
		},
	],
	type: ['__CodeableConcept__'],
	serviceType: '__CodeableConcept__',
	priority: '__CodeableConcept__',
	subject: '__Reference(Patient|Group)__',
	episodeOfCare: ['__Reference(EpisodeOfCare)__'],
	basedOn: ['__Reference(ServiceRequest)__'],
	participant: [
		{
			type: ['__CodeableConcept__'],
			period: '__Period__',
			individual: '__Reference(Practitioner|PractitionerRole|RelatedPerson)__',
		},
	],
	appointment: ['__Reference(Appointment)__'],
	period: '__Period__',
	length: '__Duration__',
	reasonCode: ['__CodeableConcept__'],
	reasonReference: ['__Reference(Condition|Procedure|Observation|ImmunizationRecommendation)__'],
	diagnosis: [
		{
			condition: '__Reference(Condition|Procedure)__',
			use: '__CodeableConcept__',
			rank: '__positiveInt__',
		},
	],
	account: ['__Reference(Account)__'],
	hospitalization: {
		preAdmissionIdentifier: '__Identifier__',
		origin: '__Reference(Location|Organization)__',
		admitSource: '__CodeableConcept__',
		reAdmission: '__CodeableConcept__',
		dietPreference: ['__CodeableConcept__'],
		specialCourtesy: ['__CodeableConcept__'],
		specialArrangement: ['__CodeableConcept__'],
		destination: '__Reference(Location|Organization)__',
		dischargeDisposition: '__CodeableConcept__',
	},
	location: [
		{
			location: '__Reference(Location)__',
			status: '__code__',
			physicalType: '__CodeableConcept__',
			period: '__Period__',
		},
	],
	serviceProvider: '__Reference(Organization)__',
	partOf: '__Reference(Encounter)__',
};
export default encounterSchema;
