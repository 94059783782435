/* Generated automagically by FhirStarter - but please feel free to edit this file */
import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';
const TaskLogLayoutMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.id;
		},
	},
	name: {
		label: 'Name',
		options: { filter: false, sort: false },
		queryParameter: 'name',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.name;
		},
	},
	active: {
		label: 'Active',
		options: { filter: false, sort: false },
		queryParameter: 'active',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.active;
		},
	},
};
export default TaskLogLayoutMapping;
