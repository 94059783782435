/**
 * Determines whether the client's browser is running on a Unix-like system.
 *
 * @returns {boolean} True if the client's browser is running on a Unix-like system, otherwise false.
 */
export const isUnixSystem = () => {
	// Regular expression to match common Unix-like system names
	var unixRegex =
		/(Linux|FreeBSD|OpenBSD|NetBSD|SunOS|Unix|IRIX|HP-UX|AIX|GNU|DragonFly|Darwin|Macintosh|Mac OS X|MacPPC|MacIntel|Mac68K)/i;
	return unixRegex.test(navigator.userAgent);
};
