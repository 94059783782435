import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { buildDicomURL, getSeriesUID } from './utils/utils';
import ReactPlayer from 'react-player';
import { useAuth, useConfig, useAppModeContext } from '@worklist-2/core/src';
import { useSearchParams } from 'react-router-dom';
import screenfull from 'screenfull';
import { PlayerControls } from './components/PlayerControls/PlayerControls';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
	player: {
		height: '100%',
		width: '100%',
		position: 'relative',
		alignSelf: 'center',

		'& video': {
			width: '100%',
			height: '100%',
			position: 'absolute',
			top: 0,
			right: 0,
			left: 0,
			bottom: 0,
		},
	},
}));

const format = seconds => {
	if (isNaN(seconds)) {
		return '00:00';
	}

	const date = new Date(seconds * 1000);
	const hh = date.getUTCHours();
	const mm = date.getUTCMinutes();
	const ss = date.getUTCSeconds().toString().padStart(2, '0');

	if (hh) {
		return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
	}
	return `${mm}:${ss}`;
};

const ImageViewerViewportVideo = ({ isThumbnail, InstanceSOPUID, layoutItem, autoPlay = false }) => {
	const { classes, cx } = useStyles();
	const playerRef = useRef(null);
	const playerDivRef = useRef(null);
	const { accessToken } = useAuth();
	const __config = useConfig();
	const seriesInstanceUID = getSeriesUID(layoutItem.series);
	const studyInstanceUID = layoutItem.series?.studyInstanceUID;
	const [searchParams] = useSearchParams();
	const { isPatientPortalMode } = useAppModeContext();
	const isBlumeApp = isPatientPortalMode();

	const internalmanagingorganizationid = searchParams.get('internalManagingOrganizationID');

	const [videoUrl, setVideoUrl] = useState();
	const [pipMode, setPipMode] = useState(false);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const [playerstate, setPlayerState] = useState({
		playing: autoPlay,
		muted: true,
		playerbackRate: 1.0,
		played: 0,
		seeking: false,
	});

	const { playing, playerbackRate, played } = playerstate;

	const currentPlayerTime = playerRef.current ? playerRef.current.getCurrentTime() : '00:00';
	const playedTime = format(currentPlayerTime);

	screenfull.on('change', () => {
		setIsFullscreen(screenfull.isFullscreen);
	});

	const loadVideo = async () => {
		try {
			const result = await fetch(
				buildDicomURL({
					isVideo: true,
					__config,
					studyInstanceUID,
					seriesInstanceUID,
					InstanceSOPUID,
					frameNumber: 1,
					isBlumeApp,
				}),
				{
					headers: {
						authorization: accessToken,
						internalmanagingorganizationid,
					},
				}
			);

			const blob = await result.blob();
			if (blob) {
				setVideoUrl(URL.createObjectURL(blob));
			}
		} catch (err) {}
	};

	useEffect(() => {
		loadVideo();
	}, []);

	const handlePlayAndPause = () => {
		setPlayerState({ ...playerstate, playing: !playerstate.playing });
	};

	const handlePlayerRate = rate => {
		setPlayerState({ ...playerstate, playerbackRate: rate });
	};

	const handleFullScreenMode = () => {
		screenfull.toggle(playerDivRef.current);
	};

	const handlePip = state => {
		setPipMode(state);
	};

	const handlePlayerProgress = state => {
		if (!playerstate.seeking) {
			setPlayerState({ ...playerstate, ...state });
		}
	};

	const handlePlayerSeek = (e, newValue) => {
		setPlayerState({ ...playerstate, playing: false, played: parseFloat(newValue / 100) });
		playerRef.current.seekTo(parseFloat(newValue / 100));
	};

	const handlePlayerMouseSeekDown = e => {
		setPlayerState({ ...playerstate, playing: false, seeking: true });
	};

	const handlePlayerMouseSeekUp = (e, newValue) => {
		setPlayerState({ ...playerstate, seeking: false });
		playerRef.current.seekTo(newValue / 100);
	};

	const handleVideoEnds = () => {
		setPlayerState({ ...playerstate, playing: false });
	};

	return (
        <Box
			ref={playerDivRef}
			sx={{
				width: '100%',
				height: '100%',
				px: !isThumbnail ? (isFullscreen ? 8 : 4) : 0,
				boxSizing: 'border-box',
				display: 'flex',
				'& video': {
					borderRadius: '10px',
				},
			}}
		>
			<div
				style={{
					width: '100%',
					height: '100%',
					margin: '0 auto',
					borderRadius: '10px',
					display: 'grid',
					gridAutoFlow: 'row',
					gridTemplateRows: '1fr auto',
				}}
			>
				<ReactPlayer
					ref={playerRef}
					className={cx(classes.player)}
					height="100%"
					pip={pipMode}
					playbackRate={playerbackRate}
					playing={playing}
					progressInterval={100}
					url={videoUrl}
					width="100%"
					onDisablePIP={() => handlePip(false)}
					onEnded={handleVideoEnds}
					onProgress={handlePlayerProgress}
				/>
				{!isThumbnail && (
					<PlayerControls
						enablePip={() => handlePip(true)}
						fullScreenMode={handleFullScreenMode}
						isFullScreen={isFullscreen}
						pipMode={pipMode}
						playRate={handlePlayerRate}
						playandpause={handlePlayAndPause}
						played={played}
						playedTime={playedTime}
						playerbackRate={playerbackRate}
						playing={playing}
						onSeek={handlePlayerSeek}
						onSeekMouseDown={handlePlayerMouseSeekDown}
						onSeekMouseUp={handlePlayerMouseSeekUp}
					/>
				)}
			</div>
		</Box>
    );
};

export default ImageViewerViewportVideo;
