import Box from '@mui/material/Box';
import React from 'react';
import { useCrmContext } from '../../../context/CRMContext';
import { Badge, Card, SubTitle } from '../../../views/CrmView/styles';
import TeamCard from './TeamCard';

const TeamsHeaderCard = ({ teams, roles }) => {
	const { toggleWidget } = useCrmContext();

	const handleClick = (team, localRoles) => {
		toggleWidget('editTeam', { team, localRoles });
	};

	return (
		<Card
			style={{
				padding: '19px 38px 24px 47px',
				minHeight: '185px',
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: '#FFF',
				boxShadow: '0px 0px 20px rgba(33, 88, 119, 0.1)',
				borderRadius: '20px',
				width: '100%',
			}}
		>
			<SubTitle style={{ marginLeft: '-9px' }}>
				<span>Teams</span>
				<Badge>
					<span>{teams?.length}</span>
				</Badge>
			</SubTitle>
			<Box
				className="invisible-scrollbar"
				sx={{
					paddingLeft: '10px',
					display: '-webkit-box',
					flexDirection: 'row',
					overflowX: 'auto',
					maxWidth: 'calc(100vw - 250px)',
				}}
			>
				{teams?.map((team, i) => (
					<TeamCard key={i} droppable data={team} localRoles={roles} onClick={(v, y) => handleClick(v, y)} />
				))}
			</Box>
		</Card>
	);
};

export default TeamsHeaderCard;
