import { useImageViewerExternalContext } from './ImageViewerExternalContext';
import CheckVideoImagesTransferSyntax from '../utils/CheckVideoImagesTransferSyntax';
import { getInstanceSOPUID, getInstanceSyntax } from '../utils/utils';
import Box from '@mui/material/Box';
import ImageViewerViewportVideo from '../ImageViewerViewportVideo';
import React from 'react';
import ImageViewerExternalViewportCornerstone from './ImageViewerExternalViewportCornerstone';
import { Download } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ImageViewerEmptyViewport from './ImageViewerEmptyViewport';
import { Loader } from '@rs-ui/components';

const ImageViewerExternalViewport = ({ onExternalImageDownload, isMobile }) => {
	const { series, viewport, imagesLoading } = useImageViewerExternalContext();

	const { t } = useTranslation('imageViewer');

	const handleExternalImageDownload = () => onExternalImageDownload(viewport?.id);

	const activeSeries = series?.find(s => s.uniqueId === viewport?.seriesUniqueId);

	const shouldRenderVideoViewport =
		activeSeries && CheckVideoImagesTransferSyntax(getInstanceSyntax(activeSeries.metadata?.[0]));

	return (
		<Box
			sx={{
				flex: '1',
				position: 'relative',
				width: '100%',
			}}
		>
			{imagesLoading && (
				<Loader
					sx={{
						width: '100%',
						height: '100%',
						zIndex: 100,
						backgroundColor: 'rgba(255, 255, 255, 0.15)',
					}}
				/>
			)}
			{!imagesLoading && !viewport && <ImageViewerEmptyViewport />}
			{viewport && !isMobile && !imagesLoading && (
				<Tooltip title={t('topToolbar.Download Image', { defaultValue: 'Download Image' })}>
					<IconButton
						sx={{ color: 'white', position: 'absolute', top: '13px', right: '80px', zIndex: 100 }}
						onClick={handleExternalImageDownload}
					>
						<Download />
					</IconButton>
				</Tooltip>
			)}
			{activeSeries && !imagesLoading && (
				<>
					{shouldRenderVideoViewport && (
						<ImageViewerViewportVideo
							autoPlay
							InstanceSOPUID={getInstanceSOPUID(activeSeries.metadata?.[0])}
							layoutItem={{
								series: activeSeries,
							}}
							viewport={viewport}
						/>
					)}

					{!shouldRenderVideoViewport && (
						<ImageViewerExternalViewportCornerstone
							key={viewport.id}
							isMobile={isMobile}
							series={activeSeries}
							viewport={viewport}
						/>
					)}
				</>
			)}
		</Box>
	);
};

export default ImageViewerExternalViewport;
