/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
import procedureCodeSchema from './schemata/procedureCodeSchema';

export default class ProcedureCodeResource extends FhirDomainResource {
	identifier = [];
	code;
	critical;
	display;
	duration;
	feeSchedules = [{}];
	internalProcedureCodeID;
	isActive;
	modality;
	modifiers = [];
	officeVisit;
	organization;
	resourceType;
	rvuProfessional;
	rvuTechnical;
	seenByPhysician;
	studySets = [{}];
	totalFeeSchedules;
	totalStudySets;
	waitTime;

	constructor(resourceString) {
		super(resourceString, procedureCodeSchema);
		this.resourceType = 'ProcedureCode';
		this.populateFields();
	}
}
