// Core
import React from 'react';

// MUI
import { Box, Typography } from '@mui/material';

const FormToolHeader = ({
	title,
	description,
	backgroundColor,
	logo,
	backgroundImageUrl,
	titleColor,
	descriptionColor,
	sx,
}) => (
	<Box
		sx={{
			background: backgroundColor,
			borderRadius: 'var(--element-content-border-radius)',
			boxSizing: 'border-box',
			...sx,
		}}
	>
		{!!backgroundImageUrl && (
			<Box
				sx={{
					height: '150px',
					filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
					position: 'relative',
					zIndex: 1,
				}}
			>
				<Box
					component="img"
					src={backgroundImageUrl}
					sx={{
						objectFit: 'contain',
						width: '700px',
						height: '150px',
						borderRadius: 'var(--element-content-border-radius)',
					}}
				/>
			</Box>
		)}
		<Box
			sx={{
				padding: '20px 30px',
				display: 'flex',
				justifyContent: 'start',
				gap: '10px',
			}}
		>
			{logo && (
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<Box
						component="img"
						src={logo}
						sx={{
							objectFit: 'contain',
							width: '46px',
							height: '46px',
							borderRadius: '10px',
							marginLeft: '-10px',
						}}
					/>
				</Box>
			)}
			<Box>
				<Typography
					component="h1"
					sx={{
						color: titleColor,
						fontSize: '20px',
						marginBottom: '5px',
						lineHeight: '24px',
						width: '400px',
					}}
				>
					{title}
				</Typography>

				<Typography
					sx={{
						color: descriptionColor,
						fontSize: '14px',
						lineHeight: '16px',
					}}
				>
					{description}
				</Typography>
			</Box>
		</Box>
	</Box>
);

export default FormToolHeader;
