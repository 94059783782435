import { useEffect, useState } from 'react';

const useRouterHash = () => {
	const [hash, setHash] = useState('');
	const [blurred, setBlurred] = useState(true);

	const listenToPopstate = () => {
		const [hash, blur] = window.location.hash.split('?');
		if (blur) {
			const [, v] = blur.split('=');
			setBlurred(v === 'true');
		}
	};

	useEffect(() => {
		window.onpopstate = () => {
			listenToPopstate();
		};
		listenToPopstate();
	}, []);

	const watchScroll = e => {
		try {
			// get the top bound of the scrolling container
			const container = e.target.getBoundingClientRect();
			// get all header texts to watch
			const headers = e.target.getElementsByClassName('watched');
			// for each watched element, check the current location on the viewport
			const positions = [];
			for (const header of headers) {
				const position = header.getBoundingClientRect();
				positions.push({
					id: header.id,
					isFocused: position.top >= container.top && position.top + position.height <= container.bottom,
					top: position.top,
					bottom: position.bottom,
					height: position.height,
					offsetTop: container.top - position.top,
					offsetBottom: container.bottom - position.bottom,
					containerTop: container.top,
					containerBottom: container.bottom,
					containerHeight: container.height,
				});
			}
			// find the entry with isFocused set to true
			const blur = handleBlurred(positions);
			const focused = positions.find(e => e.isFocused);
			if (focused) {
				window.location.hash = `${focused.id}?blur=${blur}`;
			}
		} catch (error) {
			console.error(error.message);
		}
	};

	const handleBlurred = headers => {
		// check if the last entry on the list is far enough from the bottom of the container
		const lastEntry = headers[headers.length - 1];
		return lastEntry.offsetBottom <= -1000;
	};

	return { hash, blurred, watchScroll, setHash };
};

export default useRouterHash;
