import { eventTarget, getEnabledElement, triggerEvent } from '@cornerstonejs/core';
import { annotation, AnnotationTool, cursors, drawing, Enums, state, utilities } from '@cornerstonejs/tools';
import { vec2 } from 'gl-matrix';
import { spineLabelingItems } from '../components/SpineLabelingMenu/SpineLabelingMenuData';
import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';
import getSeriesOrientationPlane, { DICOM_IMAGE_PLANE_ANNOTATIONS } from '../utils/getSeriesOrientationPlane';

const { Events } = Enums;
const { triggerAnnotationRenderForViewportIds } = utilities;
const { getViewportIdsWithToolToRender } = utilities.viewportFilters;
const { addAnnotation, getAnnotations, removeAnnotation, getAnnotationManager } = annotation.state;
const { drawLine: drawLineSvg, drawTextBox: drawTextBoxSvg } = drawing;
const { resetElementCursor, hideElementCursor } = cursors.elementCursor;

class SpineLabelingTool extends AnnotationTool {
	static toolName;

	selectedPoint: 0;

	constructor(
		toolProps = {},
		defaultToolProps = {
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				shadow: true,
				preventHandleOutsideImage: false,
				markers: spineLabelingItems,
				currentMarker: spineLabelingItems[0],
				ascending: true,
				loop: false,
			},
		}
	) {
		super(toolProps, defaultToolProps);
	}

	isPointNearTool() {
		return false;
	}

	toolSelectedCallback() {}

	addNewAnnotation = evt => {
		if (!this.configuration.currentMarker) {
			return false;
		}

		const eventDetail = evt.detail;
		const { currentPoints, element } = eventDetail;
		const worldPos = currentPoints.world;

		const enabledElement = getEnabledElement(element);
		const { viewport, renderingEngine } = enabledElement;

		const camera = viewport.getCamera();
		const { viewPlaneNormal, viewUp } = camera;

		const referencedImageId = this.getReferencedImageId(viewport, worldPos, viewPlaneNormal, viewUp);

		if (!referencedImageId) {
			return false;
		}

		const instanceMetadata = cornerstoneWADOImageLoader.wadors.metaDataManager.get(referencedImageId);

		if (!instanceMetadata) {
			return false;
		}

		const seriesPlane = DICOM_IMAGE_PLANE_ANNOTATIONS[getSeriesOrientationPlane(instanceMetadata)];

		if (['Axial', 'Coronal'].includes(seriesPlane)) {
			return false;
		}

		const FrameOfReferenceUID = viewport.getFrameOfReferenceUID();

		const annotations = [];

		const annotationManager = getAnnotationManager();

		if (annotationManager.annotations) {
			const keys = Object.keys(annotationManager.annotations);

			keys.forEach(key => {
				const spineLabelingAnnotations = annotationManager.annotations[key]?.[
					SpineLabelingTool.toolName
				]?.filter(
					a =>
						a.metadata.referencedImageId.split('/instances')[0] === referencedImageId.split('/instances')[0]
				);

				if (spineLabelingAnnotations?.length) {
					annotations.push(...spineLabelingAnnotations);
				}
			});
		}

		const annotationWithMarker = annotations.find(a => a.data.label === this.configuration.currentMarker);

		this.isDrawing = true;

		const _annotation = annotationWithMarker || {
			invalidated: true,
			highlighted: true,
			metadata: {
				toolName: this.getToolName(),
				viewPlaneNormal: [...viewPlaneNormal],
				viewUp: [...viewUp],
				FrameOfReferenceUID,
				referencedImageId,
			},
			data: {
				label: this.configuration.currentMarker,
				handles: {
					points: [[...worldPos]],
					textBox: {
						hasMoved: false,
						worldPosition: [0, 0, 0],
						worldBoundingBox: {
							topLeft: [0, 0, 0],
							topRight: [0, 0, 0],
							bottomLeft: [0, 0, 0],
							bottomRight: [0, 0, 0],
						},
					},
				},
				cachedStats: {},
			},
		};

		const shouldAddNewAnnotation = !annotationWithMarker;
		const shouldUpdateExistingAnnotation = annotationWithMarker?.data?.handles?.points?.length === 1;
		const shouldClearExistingAnnotation = annotationWithMarker?.data?.handles?.points?.length > 1;

		if (shouldAddNewAnnotation) {
			addAnnotation(_annotation, element);
		}

		if (shouldUpdateExistingAnnotation) {
			removeAnnotation(_annotation.annotationUID);

			_annotation.data.handles.points[1] = [...worldPos];

			const canvasCoordinates = viewport.worldToCanvas(_annotation.data.handles.points[0]);
			const secondAnnotationCanvasCoordinates = viewport.worldToCanvas(_annotation.data.handles.points[1]);
			const line = {
				x0: canvasCoordinates[0],
				y0: canvasCoordinates[1],
				x1: secondAnnotationCanvasCoordinates[0],
				y1: secondAnnotationCanvasCoordinates[1],
			};
			const textOffset = 12;
			const midX = (line.x0 + line.x1) / 2 - 8;
			const midY = (line.y0 + line.y1) / 2 - textOffset;

			if (!_annotation.data.handles.textBox) {
				_annotation.data.handles.textBox = {
					hasMoved: false,
					worldPosition: [0, 0, 0],
					worldBoundingBox: {
						topLeft: [0, 0, 0],
						topRight: [0, 0, 0],
						bottomLeft: [0, 0, 0],
						bottomRight: [0, 0, 0],
					},
				};
			}

			_annotation.data.handles.textBox.worldPosition = viewport.canvasToWorld([midX, midY]);

			addAnnotation(_annotation, element);
		}

		if (shouldClearExistingAnnotation) {
			removeAnnotation(_annotation.annotationUID);

			_annotation.data.handles.points = [[...worldPos]];

			addAnnotation(_annotation, element);
		}

		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.editData = {
			annotation: _annotation,
			newAnnotation: true,
			viewportIdsToRender,
		};
		this._activateModify(element);

		hideElementCursor(element);

		evt.preventDefault();

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		if (shouldUpdateExistingAnnotation) {
			const currentIndex = this.configuration.markers.indexOf(this.configuration.currentMarker);

			const nextIndex = this.configuration.ascending ? currentIndex + 1 : currentIndex - 1;

			this.configuration.currentMarker = this.configuration.markers[nextIndex];

			// loop
			// if (nextValue) {
			// 	this.configuration.currentMarker = nextValue;
			// } else {
			// 	if (this.configuration.ascending) {
			// 		this.configuration.currentMarker = this.configuration.markers[0];
			// 	} else {
			// 		this.configuration.currentMarker =
			// 			this.configuration.markers[this.configuration.markers.length - 1];
			// 	}
			// }
		}

		return _annotation;
	};

	getHandleNearImagePoint(element, _annotation, canvasCoords, proximity) {
		const enabledElement = getEnabledElement(element);
		const { viewport } = enabledElement;

		const { data } = _annotation;
		const point = data.handles.points[0];
		const annotationCanvasCoordinate = viewport.worldToCanvas(point);

		const near = vec2.distance(canvasCoords, annotationCanvasCoordinate) < proximity;

		if (near === true) {
			this.selectedPoint = 0;

			return point;
		}

		if (!data?.handles?.textBox?.worldPosition) {
			return false;
		}

		const textBoxPosition = data.handles.textBox.worldPosition;
		const textBoxCanvasCoordinate = viewport.worldToCanvas(textBoxPosition);

		const nearTextBox =
			vec2.distance(canvasCoords, [textBoxCanvasCoordinate[0] + 10, textBoxCanvasCoordinate[1] + 10]) < 14;

		if (nearTextBox === true) {
			this.selectedPoint = 2;

			return point;
		}

		const secondPoint = data.handles.points[1];

		if (!secondPoint) {
			return false;
		}

		const annotationCanvasCoordinate2 = viewport.worldToCanvas(secondPoint);

		const near2 = vec2.distance(canvasCoords, annotationCanvasCoordinate2) < proximity;

		if (near2 === true) {
			this.selectedPoint = 1;

			return secondPoint;
		}
	}

	handleSelectedCallback(evt, _annotation) {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		_annotation.highlighted = true;

		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		// Find viewports to render on drag.

		this.editData = {
			annotation: _annotation,
			viewportIdsToRender,
		};
		this._activateModify(element);

		hideElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		evt.preventDefault();
	}

	_endCallback = (evt): void => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const { annotation: _annotation, viewportIdsToRender } = this.editData;

		const { viewportId, renderingEngine } = getEnabledElement(element);
		this.eventDispatchDetail = {
			viewportId,
			renderingEngineId: renderingEngine.id,
		};

		this._deactivateModify(element);

		resetElementCursor(element);

		this.editData = null;
		this.isDrawing = false;

		if (this.isHandleOutsideImage && this.configuration.preventHandleOutsideImage) {
			removeAnnotation(_annotation.annotationUID);
		}

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		const eventType = Events.ANNOTATION_COMPLETED;
		triggerEvent(eventTarget, eventType, {
			annotation: _annotation,
		});
	};

	_dragCallback = evt => {
		this.isDrawing = true;
		const eventDetail = evt.detail;
		const { currentPoints, element } = eventDetail;
		const worldPos = currentPoints.world;

		const { annotation: _annotation, viewportIdsToRender } = this.editData;
		const { data } = _annotation;

		if (this.selectedPoint === 2) {
			// Drag mode - moving text box
			const { deltaPoints } = eventDetail;
			const worldPosDelta = deltaPoints.world;

			const { textBox } = data.handles;
			const { worldPosition } = textBox;

			worldPosition[0] += worldPosDelta[0];
			worldPosition[1] += worldPosDelta[1];
			worldPosition[2] += worldPosDelta[2];

			textBox.hasMoved = true;
		} else {
			data.handles.points[this.selectedPoint] = [...worldPos];
			_annotation.invalidated = true;
		}

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
	};

	cancel = element => {
		if (this.isDrawing) {
			this.isDrawing = false;
			this._deactivateModify(element);
			resetElementCursor(element);

			const { annotation: _annotation, viewportIdsToRender, newAnnotation } = this.editData;
			const { data } = _annotation;

			_annotation.highlighted = false;
			data.handles.activeHandleIndex = null;

			const { renderingEngine } = getEnabledElement(element);

			triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

			if (newAnnotation) {
				const eventType = Events.ANNOTATION_COMPLETED;

				const eventDetail = {
					annotation: _annotation,
				};

				triggerEvent(eventTarget, eventType, eventDetail);
			}

			this.editData = null;
			return _annotation.annotationUID;
		}
	};

	_activateModify = element => {
		state.isInteractingWithTool = true;

		element.addEventListener(Events.MOUSE_UP, this._endCallback);
		element.addEventListener(Events.MOUSE_DRAG, this._dragCallback);
		element.addEventListener(Events.MOUSE_CLICK, this._endCallback);

		element.addEventListener(Events.TOUCH_END, this._endCallback);
		element.addEventListener(Events.TOUCH_DRAG, this._dragCallback);
		element.addEventListener(Events.TOUCH_TAP, this._endCallback);
	};

	_deactivateModify = element => {
		state.isInteractingWithTool = false;

		element.removeEventListener(Events.MOUSE_UP, this._endCallback);
		element.removeEventListener(Events.MOUSE_DRAG, this._dragCallback);
		element.removeEventListener(Events.MOUSE_CLICK, this._endCallback);

		element.removeEventListener(Events.TOUCH_END, this._endCallback);
		element.removeEventListener(Events.TOUCH_DRAG, this._dragCallback);
		element.removeEventListener(Events.TOUCH_TAP, this._endCallback);
	};

	renderAnnotation = (enabledElement, svgDrawingHelper) => {
		let renderStatus = false;
		const { viewport } = enabledElement;
		const { element } = viewport;
		const imageId = viewport.getCurrentImageId();

		if (!imageId) {
			return renderStatus;
		}

		const instanceMetadata = cornerstoneWADOImageLoader.wadors.metaDataManager.get(imageId);

		if (!instanceMetadata) {
			return;
		}

		const seriesPlane = DICOM_IMAGE_PLANE_ANNOTATIONS[getSeriesOrientationPlane(instanceMetadata)];

		if (['Axial', 'Coronal'].includes(seriesPlane)) {
			return false;
		}

		let annotations = getAnnotations(this.getToolName(), element);

		if (!annotations?.length) {
			return renderStatus;
		}

		annotations = annotations.filter(
			a => a.metadata.referencedImageId.split('/instances')[0] === imageId.split('/instances')[0]
		);

		if (!annotations?.length) {
			return renderStatus;
		}

		const styleSpecifier = {
			toolGroupId: this.toolGroupId,
			toolName: this.getToolName(),
			viewportId: enabledElement.viewport.id,
		};

		for (let i = 0; i < annotations.length; i++) {
			const _annotation = annotations[i];
			const marker = _annotation.data.label;
			const annotationUID = _annotation.annotationUID;
			const data = _annotation.data;
			const point = data.handles.points[0];
			const canvasCoordinates = viewport.worldToCanvas(point);
			const secondAnnotation = data.handles.points[1];
			const secondAnnotationPoint = data.handles.points[1];
			const secondAnnotationCanvasCoordinates =
				secondAnnotationPoint && viewport.worldToCanvas(secondAnnotationPoint);

			if (!_annotation.isVisible) {
				continue;
			}

			let shouldRenderX = true;

			if (secondAnnotation && !_annotation.highlighted) {
				shouldRenderX = false;
			}

			styleSpecifier.annotationUID = annotationUID;

			const color = this.getStyle('color', styleSpecifier, _annotation);

			if (!viewport.getRenderingEngine()) {
				console.warn('Rendering Engine has been destroyed');
				return renderStatus;
			}

			const headLength = 5;

			const firstLine = {
				start: [canvasCoordinates[0] - headLength / 2, canvasCoordinates[1] - headLength / 2],
				end: [canvasCoordinates[0] + headLength / 2, canvasCoordinates[1] + headLength / 2],
			};

			const secondLine = {
				start: [canvasCoordinates[0] - headLength / 2, canvasCoordinates[1] + headLength / 2],
				end: [canvasCoordinates[0] + headLength / 2, canvasCoordinates[1] - headLength / 2],
			};

			if (shouldRenderX) {
				drawLineSvg(svgDrawingHelper, annotationUID, '2', firstLine.start, firstLine.end, {
					color,
					width: '1',
				});

				drawLineSvg(svgDrawingHelper, annotationUID, '3', secondLine.start, secondLine.end, {
					color,
					width: '1',
				});
			}

			if (secondAnnotation) {
				const thirdLine = {
					start: [
						secondAnnotationCanvasCoordinates[0] - headLength / 2,
						secondAnnotationCanvasCoordinates[1] - headLength / 2,
					],
					end: [
						secondAnnotationCanvasCoordinates[0] + headLength / 2,
						secondAnnotationCanvasCoordinates[1] + headLength / 2,
					],
				};

				const fourthLine = {
					start: [
						secondAnnotationCanvasCoordinates[0] - headLength / 2,
						secondAnnotationCanvasCoordinates[1] + headLength / 2,
					],
					end: [
						secondAnnotationCanvasCoordinates[0] + headLength / 2,
						secondAnnotationCanvasCoordinates[1] - headLength / 2,
					],
				};

				if (shouldRenderX) {
					drawLineSvg(svgDrawingHelper, annotationUID, '4', thirdLine.start, thirdLine.end, {
						color,
						width: '1',
					});

					drawLineSvg(svgDrawingHelper, annotationUID, '5', fourthLine.start, fourthLine.end, {
						color,
						width: '1',
					});
				}
			}

			renderStatus = true;

			const options = this.getLinkedTextBoxStyle(styleSpecifier, _annotation);

			if (!options.visibility) {
				continue;
			}

			const textLines = [data.label];

			if (textLines && secondAnnotation) {
				if (!data.handles.textBox) {
					const _canvasCoordinates = viewport.worldToCanvas(_annotation.data.handles.points[0]);
					const _secondAnnotationCanvasCoordinates = viewport.worldToCanvas(
						_annotation.data.handles.points[1]
					);
					const line = {
						x0: _canvasCoordinates[0],
						y0: _canvasCoordinates[1],
						x1: _secondAnnotationCanvasCoordinates[0],
						y1: _secondAnnotationCanvasCoordinates[1],
					};
					const textOffset = 12;
					const midX = (line.x0 + line.x1) / 2 - 8;
					const midY = (line.y0 + line.y1) / 2 - textOffset;

					data.handles = {
						...data.handles,
						textBox: {
							hasMoved: false,
							worldPosition: viewport.canvasToWorld([midX, midY]),
							worldBoundingBox: {
								topLeft: [0, 0, 0],
								topRight: [0, 0, 0],
								bottomLeft: [0, 0, 0],
								bottomRight: [0, 0, 0],
							},
						},
					};
				}

				const textUID = '0';
				const textBoxPosition = viewport.worldToCanvas(data.handles.textBox.worldPosition);

				const boundingBox = drawTextBoxSvg(
					svgDrawingHelper,
					annotationUID,
					textUID,
					textLines,
					textBoxPosition,
					{
						...options,
						padding: 0,
					}
				);

				const { x: left, y: top, width, height } = boundingBox;

				data.handles.textBox.worldBoundingBox = {
					topLeft: viewport.canvasToWorld([left, top]),
					topRight: viewport.canvasToWorld([left + width, top]),
					bottomLeft: viewport.canvasToWorld([left, top + height]),
					bottomRight: viewport.canvasToWorld([left + width, top + height]),
				};
			}
		}

		return renderStatus;
	};
}

SpineLabelingTool.toolName = 'SpineLabeling';
export default SpineLabelingTool;
