import axios from 'axios';
import { uniqBy } from 'lodash';

export const getKeyImages = async ({ __config, studyInstanceUID, internalManagingId }) => {
	const response = await axios.get(
		`${__config.data_sources.dicom_web}/StudyResource?resourceType=KeyObject&studyInstanceUID=${studyInstanceUID}&internalManagingOrganizationId=${internalManagingId}`
	);

	if (response?.data) {
		return response.data;
	}

	return [];
};

export const addKeyImage = async ({
	__config,
	internalManagingId,
	studyInstanceUID,
	seriesId,
	ReferenceImageSOPInstanceUID,
	ReferenceImageFrameNumber,
	currentImageId,
	description,
	onSetKeyImages,
}) => {
	try {
		await axios.post(
			`${__config.data_sources.dicom_web}/StudyResource?resourceType=KeyObject&studyInstanceUID=${studyInstanceUID}&internalManagingOrganizationId=${internalManagingId}`,
			{
				ReferenceImageSOPInstanceUID,
				ReferenceImageFrameNumber,
				currentImageId,
				SeriesID: seriesId,
				description: description || '',
			}
		);

		const response = await getKeyImages({
			__config,
			studyInstanceUID,
			internalManagingId,
		});

		onSetKeyImages((prev) => uniqBy([...prev, ...response], (i) => i.id));
	} catch (e) {
		console.error(e);
	}
};

export const renameKeyImage = async ({
	__config,
	internalManagingId,
	studyInstanceUID,
	keyImage,
	description,
	onSetKeyImages,
}) => {
	try {
		await axios.put(`${__config.data_sources.dicom_web}/StudyResource?resourceType=KeyObject&studyInstanceUID=${studyInstanceUID}&internalManagingOrganizationId=${internalManagingId}`, {
			...keyImage,
			description
		});


		onSetKeyImages((prev) => prev.map((i) => (i.id === keyImage.id ? { ...i, description } : i)));
	} catch (e) {
		console.error(e);
	}
};

export const removeKeyImage = async (__config, keyImage, onSetKeyImages) => {
	try {
		await axios.delete(
			`${__config.data_sources.dicom_web}/StudyResource?resourceType=KeyObject&studyInstanceUID=${keyImage.StudyInstanceUID}&internalManagingOrganizationId=${keyImage.Managingorganizationid}&ResourceID=${keyImage.ResourceID}`
		);
		onSetKeyImages((prev) => prev.filter((i) => i.id !== keyImage.id));
	} catch (e) {
		console.error(e);
	}
};
