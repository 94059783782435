import React, { useState, memo } from 'react';
import { Box, Typography } from '@mui/material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import LogoIcon from '@worklist-2/ui/src/assets/icons/omegaAI_logo.svg';
import Section from './common/Section';
import CustomAccordion from './common/CustomAccordion';
import { HL7MapData } from './HL7MapData';

const MapData = memo(({ isSplit, selectedItems, setDraggingItem, selectHL7MessageType, messageType }) => {
	const HL7ORM = HL7MapData[Object.keys(HL7MapData).find(key => key.includes(selectHL7MessageType.split('_')[0]))];

	return (
		<Box
			sx={{
				boxSizing: 'border-box',
				position: messageType === 'Inbound' ? 'fixed' : 'relative',
				zIndex: messageType === 'Inbound' ? '19' : '21',
				top: messageType === 'Inbound' ? 0 : 'unset',
				right: messageType === 'Inbound' ? '538px' : 'unset',
				width: '538px',
				height: '100vh',
				padding: '27px 20px 8px',
				background: '#FFF',
				overflowY: 'hidden',
				boxShadow: messageType === 'Inbound' ? '-4px 0 20px 0 rgba(33,88,119,0.1)' : 'none',
			}}
		>
			<Section
				color="#A6A4FF"
				icon={<CompareArrowsIcon sx={{ fontSize: '14px', color: '#FFF' }} />}
				secondColor="rgba(115, 113, 255, 0.19)"
				sx={{ height: isSplit ? '50%' : '100%', marginBottom: isSplit ? '6px' : 0 }}
				title={`HL7 ${selectHL7MessageType}`}
			>
				<Box sx={{ height: 'calc(100% - 54px)', overflowY: 'auto' }}>
					{Object.keys(HL7ORM).map(firstLevelItem => (
						<CustomAccordion key={firstLevelItem} title={firstLevelItem}>
							{Object.keys(HL7ORM[firstLevelItem]).map(secondLevelItem => (
								<CustomAccordion
									key={secondLevelItem}
									isDraggable
									small
									selected={selectedItems.some(
										selectedItem => selectedItem.value === secondLevelItem
									)}
									setDraggingItem={setDraggingItem}
									title={secondLevelItem}
								>
									{typeof HL7ORM[firstLevelItem][secondLevelItem] === 'object'
										? Object.keys(HL7ORM[firstLevelItem][secondLevelItem]).map(thirdLevelItem =>
												thirdLevelItem.includes('#') ? null : (
													<CustomAccordion
														key={thirdLevelItem}
														isDraggable
														small
														transparent
														selected={selectedItems.some(
															selectedItem => selectedItem.value === thirdLevelItem
														)}
														setDraggingItem={setDraggingItem}
														title={thirdLevelItem}
													>
														{typeof HL7ORM[firstLevelItem][secondLevelItem][
															thirdLevelItem
														] === 'object' ? (
															<Box sx={{ padding: '2px 28px 2px 42px' }}>
																{Object.keys(
																	HL7ORM[firstLevelItem][secondLevelItem][
																		thirdLevelItem
																	]
																).map(item => (
																	<MapDataItem
																		key={item}
																		draggable
																		selected={selectedItems.some(
																			selectedItem => selectedItem.value === item
																		)}
																		setDraggingItem={setDraggingItem}
																		value={item}
																	/>
																))}
															</Box>
														) : null}
													</CustomAccordion>
												)
										  )
										: null}
								</CustomAccordion>
							))}
						</CustomAccordion>
					))}
				</Box>
			</Section>
			{isSplit && (
				<Section
					color="#42A5F5"
					icon={<LogoIcon />}
					secondColor="rgba(66, 165, 245, 0.19)"
					sx={{ height: isSplit ? '50%' : '100%' }}
					title="OmegaAI"
				>
					<Box
						sx={{
							boxSizing: 'border-box',
							height: 'calc(100% - 54px)',
							padding: '12px 26px 12px 18px',
							overflowY: 'auto',
						}}
					>
						{selectedItems.map(item =>
							item.value ? <MapDataItem key={item.columnName} value={item.value} /> : null
						)}
					</Box>
				</Section>
			)}
		</Box>
	);
});

const MapDataItem = memo(({ value, selected, draggable, setDraggingItem }) => {
	const [isDragging, setIsDragging] = useState(false);

	const handleDragStart = () => {
		if (draggable && !selected) {
			setIsDragging(true);
			setDraggingItem({ value });
		}
	};

	const handleDragEnd = () => {
		if (draggable) {
			setIsDragging(false);
			setDraggingItem(null);
		}
	};

	return (
		<Box
			draggable={draggable && !selected}
			sx={{
				boxSizing: 'border-box',
				marginBottom: '2px',
				padding: '12px 8px',
				display: 'flex',
				alignItems: 'center',
				backgroundColor: isDragging ? 'rgba(77, 121, 234, 0.1)' : '#FFFFFF',
				border: isDragging ? '1px solid #4D79EA' : '1px solid rgba(196, 196, 196, 0.5)',
				borderRadius: '10px',
				opacity: selected ? '0.5' : '1',
				cursor: selected ? 'default' : 'pointer',
			}}
			onDragEnd={handleDragEnd}
			onDragStart={handleDragStart}
		>
			<DragIndicatorIcon sx={{ fontSize: '20px', color: 'rgba(0, 0, 0, 0.4)' }} />
			<Typography
				sx={{
					marginLeft: '12px',
					fontFamily: 'Inter',
					fontWeight: 500,
					fontSize: '16px',
					lineHeight: '12px',
					color: 'rgba(0, 0, 0, 0.6)',
				}}
			>
				{value}
			</Typography>
		</Box>
	);
});

export default MapData;
