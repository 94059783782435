// core
import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import _ from 'lodash';

// mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

// custom components
import useFhirDataLoader from '@rs-core/hooks/useFhirDataLoader';
import { useAuth } from '@rs-core/context/UserAuthContext';
import { eventPublish } from '@rs-core/services/customEventHandler';
import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';
import useToast from '@rs-core/hooks/useToast';
import { searchScopes } from '@rs-core/context/consts/searchScopes';

import { filterAllowedStatusBasedOnAllowedTransitionsByRole } from '@rs-ui/views/OrganizationDetailView/organizationDetailSections/OrganizationDetailStudyStatusV2/studyStatusV2Utils';
import Toast from '@rs-ui/components/Toast/Toast';
import RejectionReason from '@rs-ui/components/Worklist/StudyStatusCell/RejectionReason';
import { useWorklist } from '@rs-ui/components/Worklist/Worklist/contexts/WorklistContext';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const updateAppointmentDataEvent = 'updateAppointmentDataEvent';

const StudyStatusCellPlain = ({ studyId, status, orgId, onUpdate, rejectionReasonArr }) => {
	const [statuses, setStatuses] = useState([]);
	const [searchCardOpen, setSearchCardOpen] = useState(false);
	const [rejectionReasonCardOpen, setRejectionReasonCardOpen] = useState(false);
	const [rejectionReasons, setRejectionReasons] = useState([]);
	const { userPractitionerRolesPerOrganization } = useAuth();
	const { closeWorklistWheel } = useWorklist();

	const ref = useRef(null);
	const rejectWorkflowStepNameRef = useRef(null);
	const selectedStudyStatusRef = useRef(null);
	const rejectionReasonsRef = useRef(null);

	const proactEnableRejectionReason = useBooleanFlagValue('proact-enable-rejection-reason');
	const sprinterOaiStudystatusWorkflowstepsV2 = useBooleanFlagValue('sprinter-oai-studystatus-workflowsteps-v2');

	const { t } = useTranslation('worklist');

	const { setToastUtility, toastOpen, toastMessage, handleToastClose } = useToast();

	const studyLoader = useFhirDataLoader({
		scope: searchScopes.studyRegular,
	});

	const studyStatusLoader = useFhirDataLoader({
		scope: searchScopes.studyStatus,
	});

	const rejectionReasonLoader = useFhirDataLoader({
		scope: searchScopes.valueSet,
	});

	const workflowStepLoader = useFhirDataLoader({ scope: searchScopes.workflowStep });

	const studyStatus = status?.toUpperCase();

	const handleSearchCardClose = () => {
		setSearchCardOpen(false);
	};

	const loadStudyStatuses = () => {
		const filters = { organization: orgId, _sort: 'statusValue', active: true };
		if (sprinterOaiStudystatusWorkflowstepsV2) {
			filters.includetransitionsbyrole = true;
		}
		studyStatusLoader.load(filters, true).then(response => {
			setStatuses(response?.entry?.map(({ resource }) => resource) || []);
		});
	};

	const loadRejectionReasons = () =>
		rejectionReasonLoader.load({ 'name:exact': 'RejectionReason' }).then(response => {
			if (response?.length > 0) {
				const reasonArr = response?.map(item => ({ code: item.code, display: item.display }));
				const sortedList = _.orderBy(reasonArr, 'display');
				setRejectionReasons(sortedList);
				rejectionReasonsRef.current = sortedList;
			} else {
				return Promise.resolve();
			}
		});

	const getWorkflowStepName = () => {
		workflowStepLoader
			.load({
				organizationid: orgId,
				workflowStepName: 'REJECTED',
			})
			.then(response => {
				if (response?.length > 0) {
					rejectWorkflowStepNameRef.current = response[0].studyStatus.display.toUpperCase();
				} else {
					return Promise.resolve();
				}
			});
	};

	const handleClickOutside = async event => {
		if (ref?.current) {
			const isOptionClicked = event.target.classList.contains('MuiAutocomplete-option');
			const isRejectionReasonClicked =
				event.target?.id?.includes('rejection-reason') ||
				event.target?.parentElement?.id?.includes('rejection-reason') ||
				event.target?.parentElement?.className?.includes('MuiCheckbox-root');
			if (!ref?.current?.contains(event.target) && !isOptionClicked && !isRejectionReasonClicked) {
				selectedStudyStatusRef.current = null;
				handleSearchCardClose();
				setRejectionReasonCardOpen(false);
				resetRejectionReasons();
			}
		}
	};

	const updateStudy = async statusValue => {
		if (statusValue) {
			// Need full study JSON for PUT request
			await studyLoader
				.load({
					id: studyId,
					summary: false,
					hideSeriesById: true,
				})
				.then(res => {
					if (res) {
						const studyStatusExtension = res.extension.find(
							item => item.url === fhirExtensionUrls.common.status
						);

						// Update study status in json
						if (studyStatusExtension) {
							studyStatusExtension.valueString = statusValue;
						} else {
							res.extension.push({
								url: fhirExtensionUrls.common.status,
								valueString: statusValue,
							});
						}

						// Add/Delete rejection reason into/from json
						const rejectionReasonExt = res.extension.find(
							item => item.url === fhirExtensionUrls.imagingStudy.rejectionReason
						);
						// Delete the selected reasons when changing status from Rejected to something else
						if (statusValue !== rejectWorkflowStepNameRef.current && rejectionReasonExt?.extension) {
							rejectionReasonExt.extension = [];
						} else {
							// Add/update rejection reasons when the new status is REJECTED
							const selected = rejectionReasons?.filter(item => item.checked);
							const reasonArr = [];

							if (selected?.length > 0) {
								selected.forEach(reason => {
									delete reason.checked;
									reasonArr.push({
										url: 'text',
										valueCoding: reason,
									});
								});
							}

							if (rejectionReasonExt) {
								rejectionReasonExt.extension = reasonArr;
							} else {
								res.extension.push({
									url: fhirExtensionUrls.imagingStudy.rejectionReason,
									extension: reasonArr,
								});
							}
						}

						studyLoader.update(studyId, res).then(updateRes => {
							if (updateRes?.resourceType == 'ImagingStudy') {
								setToastUtility(true, t('Successfully updated Study Status.'));
								resetStates();
								onUpdate?.(statusValue);

								eventPublish(updateAppointmentDataEvent, {
									studyId,
									newStatus: statusValue,
								});
							} else {
								setToastUtility(true, t('Could not update Study Status. Please try again.'));
								resetStates();
							}
						});
					} else {
						setToastUtility(true, t('Could not update Study Status. Please try again.'));
						resetStates();
					}
				});
		} else {
			setRejectionReasonCardOpen(false);
		}
	};

	const resetStates = () => {
		selectedStudyStatusRef.current = null;
		setRejectionReasonCardOpen(false);
		handleSearchCardClose();
		updateRejectionReasons();
	};

	const changeStatusHandler = async (e, value) => {
		const val = value?.toUpperCase();
		if (val === rejectWorkflowStepNameRef.current && proactEnableRejectionReason) {
			// Close Study Status dropdown, open Rejection Reason dropdown, but not update study
			updateRejectionReasons();
			setRejectionReasonCardOpen(true);
			selectedStudyStatusRef.current = val;
		} else {
			// Update study, close Study Status dropdown, do not open Rejection Reason dropdown
			if (studyStatus === rejectWorkflowStepNameRef) {
				const selectedReasons = rejectionReasons?.filter(item => item.checked);
				if (selectedReasons?.length > 0) {
					setRejectionReasons(prev => {
						selectedReasons.forEach(reason => {
							const index = rejectionReasons?.findIndex(item => item.code === reason.code);
							if (index >= 0) {
								prev[index].checked = false;
							}
						});

						return [...prev];
					});
				}
			}

			await updateStudy(val);
		}
	};

	const updateRejectionReasons = async () => {
		// Update rejectionReasons everytime rejectionReasonArr changes - reload the cell
		if (rejectionReasonArr?.length > 0) {
			rejectionReasons?.length > 0 &&
				setRejectionReasons(prev => {
					rejectionReasonArr.forEach(reason => {
						const index = _.findIndex(rejectionReasons, item => item.code === reason.code);
						if (index >= 0) {
							prev[index].checked = true;
						}
					});

					return [...prev];
				});
		} else {
			resetRejectionReasons();
		}
	};

	const resetRejectionReasons = () => {
		// When this function is called from handleClickOutside event listener that is triggered in useEffect hook,
		// it cannot get the latest value of the selectedReasons state, useState does not work but useRef works in this case
		const reasonList = rejectionReasons?.length > 0 ? rejectionReasons : rejectionReasonsRef.current;
		if (reasonList?.length > 0) {
			const selectedReasons = reasonList?.filter(item => item.checked);
			if (selectedReasons?.length > 0) {
				setRejectionReasons(prev => {
					selectedReasons.forEach(reason => {
						const index = _.findIndex(reasonList, item => item.code === reason.code);
						if (index >= 0) {
							prev[index].checked = false;
						}
					});

					return [...prev];
				});
			}
		}
	};

	const handleRejectReasonSelection = event => {
		setRejectionReasons(prev => {
			const index = _.findIndex(rejectionReasons, item => item.code === event.target.id);
			if (index >= 0) {
				prev[index].checked = event.target.checked;
			}

			return [...prev];
		});
	};

	useEffect(() => {
		if (proactEnableRejectionReason && rejectionReasonArr?.length > 0) {
			rejectionReasons?.length == 0 && loadRejectionReasons();
			!rejectWorkflowStepNameRef.current && getWorkflowStepName();
			updateRejectionReasons();
		}
	}, [rejectionReasonArr]);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	useEffect(() => {
		if (searchCardOpen) {
			statuses?.length == 0 && loadStudyStatuses();
			proactEnableRejectionReason && !rejectWorkflowStepNameRef.current && getWorkflowStepName();
		}

		proactEnableRejectionReason &&
			rejectionReasonCardOpen &&
			rejectionReasons?.length == 0 &&
			loadRejectionReasons();
	}, [searchCardOpen, rejectionReasonCardOpen]);

	function getFilteredStudyStatusOptions() {
		if (!status) return statuses;
		const currentStatusNameLowerCase = status.toLowerCase();
		const currentStatus = statuses.find(s => s.status.toLowerCase() === currentStatusNameLowerCase);
		return filterAllowedStatusBasedOnAllowedTransitionsByRole(
			userPractitionerRolesPerOrganization,
			orgId,
			currentStatus,
			statuses,
			sprinterOaiStudystatusWorkflowstepsV2
		).map(s => s.status);
	}

	const filteredStatusOptions = getFilteredStudyStatusOptions();

	return (
		<>
			<Toast
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				message={toastMessage}
				open={toastOpen}
				onClose={handleToastClose}
			/>
			<Box
				sx={{
					minWidth: '10px',
					minHeight: '20px',
					display: 'flex',
					alignItems: 'center',
					position: 'absolute',
					top: '4px',
					bottom: '2px',
				}}
				onClick={e => {
					// Suppress Clickwheel
					e.stopPropagation();

					// Close the worklist wheel if it's visible
					closeWorklistWheel();
					const selectedText = e.target?.innerText || e.target?.innerHTML;
					// Open UserSearch when clicking on Study Status text
					if (e.target?.id?.includes('studyStatusText') || e.target?.id === 'studyStatusAutocomplete') {
						!searchCardOpen && setSearchCardOpen(true);
					} else if (e.target?.id?.includes('rejection-reason-text')) {
						// Close Study Status list and open Rejection Reason list when clicking on Rejection Reason text
						updateRejectionReasons();
						setSearchCardOpen(true);
						setRejectionReasonCardOpen(true);
					} else if (
						e.target?.id?.includes('studyStatusAutocomplete-option') &&
						selectedText === studyStatus
					) {
						if (selectedText !== rejectWorkflowStepNameRef.current) {
							// When reselecting the saved studyStatus that is not REJECTED, close the study status dropdown
							setSearchCardOpen(false);
						} else if (selectedText === rejectWorkflowStepNameRef.current) {
							// When reselecting the saved studyStatus that is REJECTED, show the rejection reason list
							setSearchCardOpen(false);
							updateRejectionReasons();
							setSearchCardOpen(true);
							setRejectionReasonCardOpen(true);
						}
					}
				}}
			>
				{searchCardOpen ? (
					proactEnableRejectionReason ? (
						<Tooltip
							PopperProps={{
								sx: {
									'& .MuiTooltip-tooltip': {
										mt: '1px !important',
										width: '200px',
										height: '248px',
									},
								},
							}}
							componentsProps={{
								tooltip: {
									sx: {
										backgroundColor: '#393939',
										border: '1px solid rgba(196, 196, 196, 0.15)',
										borderRadius: '5px',
										boxSizing: 'border-box',
									},
								},
							}}
							data-testid="rejectionReasonTooltip"
							id="rejection-reason-tooltip"
							open={rejectionReasonCardOpen}
							placement="bottom"
							title={
								<Box id="rejection-reason-box" sx={{ height: '216px' }}>
									<Box
										data-testid="rejectionReasonBackBox"
										id="rejection-reason-back-box"
										sx={{ width: '24px', height: '24px', mt: '4px' }}
										onClick={event => {
											setRejectionReasonCardOpen(false);
											setSearchCardOpen(true);
											selectedStudyStatusRef.current = null;
										}}
									>
										<ArrowBackOutlinedIcon
											id="rejection-reason-arrow-back-icon"
											sx={{
												color: 'rsPrimary.main',
												'&:hover': {
													cursor: 'pointer',
												},
											}}
										/>
									</Box>
									<Box
										id="rejection-reason-list-box"
										sx={{
											background: '#393939',
											borderRadius: '0px',
											border: '0px',
											height: '172px',
											overflow: 'hidden',
											overflowY: 'scroll',
											overflowX: 'scroll',
											whiteSpace: 'nowrap',
										}}
									>
										<FormControl component="fieldset" id="rejection-reason-form-control">
											<FormGroup id="rejection-reason-form-group">
												{rejectionReasons?.map(option => (
													<FormControlLabel
														key={option.code}
														control={
															<Checkbox
																checked={option.checked}
																id={option.code}
																name={option.display}
																onChange={handleRejectReasonSelection}
															/>
														}
														id="rejection-reason-form-control-label"
														label={option.display}
													/>
												))}
											</FormGroup>
										</FormControl>
									</Box>
									<Button
										data-testid="rejectionReasonSelectButton"
										disabled={rejectionReasons?.filter(item => item.checked)?.length == 0}
										sx={{
											float: 'right',
											mt: '2px',
											color: 'rsPrimary.main',
										}}
										variant="text"
										onClick={() => {
											// selectedStudyStatusRef.current: when change status to REJECTED and select reasons
											// studyStatus: when status is REJECTED and modify the selected reasons
											updateStudy(selectedStudyStatusRef.current ?? studyStatus);
										}}
									>
										{t('rejectStudyStatus.select')}
									</Button>
								</Box>
							}
						>
							<Autocomplete
								ref={ref}
								disableClearable
								openOnFocus
								ListboxProps={{
									style: {
										maxHeight: '165px',
									},
								}}
								id="studyStatusAutocomplete"
								open={searchCardOpen && !rejectionReasonCardOpen}
								options={filteredStatusOptions}
								renderInput={props => <StudyStatusCellInput props={props} />}
								sx={{
									width: '200px',
									height: '90%',
								}}
								value={selectedStudyStatusRef.current ?? studyStatus}
								onChange={changeStatusHandler}
							/>
						</Tooltip>
					) : (
						<Autocomplete
							ref={ref}
							disableClearable
							openOnFocus
							ListboxProps={{
								style: {
									maxHeight: '165px',
								},
							}}
							id="studyStatusAutocomplete"
							options={filteredStatusOptions}
							renderInput={props => <StudyStatusCellInput props={props} />}
							sx={{
								width: '200px',
								height: '90%',
							}}
							value={studyStatus}
							onChange={changeStatusHandler}
						/>
					)
				) : (
					<Typography
						data-testid="study-status-text"
						id="studyStatusText"
						sx={{
							fontSize: '14px',
							lineHeight: '1.43',
							color: searchCardOpen ? 'rsPrimary.main' : null,
							'&:hover': {
								color: 'rsPrimary.main',
							},
						}}
					>
						{rejectionReasonCardOpen && selectedStudyStatusRef.current
							? selectedStudyStatusRef.current
							: studyStatus}
					</Typography>
				)}
				{proactEnableRejectionReason &&
					!searchCardOpen &&
					!rejectionReasonCardOpen &&
					rejectionReasonArr?.length > 0 && <RejectionReason selectedReasons={rejectionReasonArr} />}
			</Box>
		</>
	);
};

const StudyStatusCellInput = ({ props }) => (
	<TextField
		label="Study Status"
		sx={{ height: '100%' }}
		{...props}
		InputLabelProps={{
			shrink: true,
			sx: { fontSize: '14px', lineHeight: '1.3em', ml: '3px', mt: '2px' },
		}}
		InputProps={{
			...props.InputProps,
			sx: {
				height: '100%',
				boxSizing: 'border-box',
				textAlign: 'center',
				alignItems: 'center',
				padding: '4px 65px 2px 8px !important',
			},

			inputProps: {
				...props.inputProps,
				sx: { fontSize: '13px', padding: '0 0 0 8px !important' },
			},
		}}
		size="small"
	/>
);

export default StudyStatusCellPlain;
