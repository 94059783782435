import React, { createContext, useContext } from 'react';
import { CometChat } from '@cometchat-pro/chat';
import { SharedManager } from './CometChatController';
import * as enums from './enums.js';

export const LiveChatContext = createContext();
class LiveChatContextProvider extends React.Component {
	static contextType = LiveChatContext;

	constructor(props) {
		super(props);
		this.appID = '210358fe421c1e78';
		this.region = 'us';
		this.authKey = 'c94da270ec845f01004c8b8bc8a7a70ac08f282c';
		this.state = {
			initializing: true,
			liveChatSender: null,
			messages: [],
			appUser: null,
			liveAgent: null,
		};
	}

	initChat = userInfo => {
		try {
			this.setState({ initializing: true });
			const appSetting = new CometChat.AppSettingsBuilder()
				.subscribePresenceForAllUsers()
				.setRegion(this.region)
				.build();
			CometChat.init(this.appID, appSetting).then(
				async () => {
					const { firstName, lastName, localAccountId } = userInfo;
					const uid = localAccountId;
					const name = `${firstName} ${lastName}`;
					const user = await this.loginOrRegister(uid, name);
					const agent = await this.getAgent();
					this.startMessageListener(agent);
					this.setState({
						appUser: userInfo,
						liveAgent: agent,
						liveChatSender: user,
						initializing: false,
					});
				},
				error => {
					console.error('Initialization failed with error:', error);
					setInitializing(false);
				}
			);
		} catch (error) {
			console.error('INIT CHAT ERROR =>', error);
		}
	};

	loginOrRegister = async (uid, name) =>
		new Promise((resolve, reject) => {
			try {
				const user = new CometChat.User(uid);
				user.setName(name);
				CometChat.createUser(user, this.authKey)
					.then(async account => {
						const res = await CometChat.login(uid, this.authKey);
						resolve(res);
					})
					.catch(async () => {
						const res = await CometChat.login(uid, this.authKey);
						resolve(res);
					});
			} catch (error) {
				reject(error);
			}
		});

	getAgent = () =>
		new Promise((resolve, reject) => {
			CometChat.getUser('omega-support')
				.then(user => resolve(user))
				.catch(error => reject(error));
		});

	startMessageListener = async agent => {
		try {
			const controller = new SharedManager(agent, 'text');
			const prev = await controller.fetchPreviousMessages();
			this.setState({ messages: prev });
			controller.attachListeners(this.handleMessageReceived);
		} catch (error) {
			console.error(error);
		}
	};

	handleMessageReceived = (eventType, data) => {
		switch (eventType) {
			case enums.TEXT_MESSAGE_RECEIVED:
				this.setState({ messages: [...this.state.messages, data] });

				import('./resources/audio/incomingmessage.wav').then(response => {
					const messageAudio = new Audio(response.default);
					messageAudio.currentTime = 0;
					messageAudio.play();
				});
				break;

			case enums.TYPING_STARTED:
				break;

			default:
				// handle other message events here
				break;
		}
	};

	sendTextMessage = ({ message }) =>
		new Promise(async (resolve, reject) => {
			try {
				const textMessage = new CometChat.TextMessage();
				if (!this.state.liveChatSender) {
					const { firstName, lastName, localAccountId } = this.state.appUser;
					const uid = localAccountId;
					const name = `${firstName} ${lastName}`;
					await this.loginOrRegister(uid, name);
				}
				textMessage.setSender(this.state.liveChatSender);
				textMessage.setReceiverId(this.state.liveAgent?.uid);
				textMessage.setReceiverType('user');
				textMessage.setText(`${message}`.trim());
				textMessage._composedAt = '';
				textMessage._id = `_${Math.random().toString(36).substring(2, 9)}`;
				const response = await CometChat.sendMessage(textMessage);
				this.setState(
					{
						messages: [...this.state.messages, response],
					},
					() => {
						import('./resources/audio/outgoingmessage.wav').then(file => {
							const messageAudio = new Audio(file.default);
							messageAudio.currentTime = 0;
							messageAudio.play();
						});
					}
				);
				resolve(response);
			} catch (error) {
				reject(error);
			}
		});

	render() {
		return (
			<LiveChatContext.Provider
				value={{
					...this.state,
					initChat: this.initChat,
					sendTextMessage: this.sendTextMessage,
				}}
			>
				{this.props.children}
			</LiveChatContext.Provider>
		);
	}
}

export const useLiveChat = () => {
	const liveChatContext = useContext(LiveChatContext);
	const { initializing, liveChatSender, sendTextMessage, messages, initChat, liveAgent } = liveChatContext;

	return { initializing, liveChatSender, sendTextMessage, messages, initChat, liveAgent };
};

export default LiveChatContextProvider;
