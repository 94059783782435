// core
import React, { useContext, useEffect } from 'react';
import { generateRandomString } from '@worklist-2/core/src/utils/generateRandomString';

// contexts
import FormContext from '../../../formContexts/FormContext';

// libraries
import { useFieldArray } from 'react-hook-form';
import _ from 'lodash';

// MUI
import MuiGrid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

// MUI icons
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import FaxIcon from '@mui/icons-material/Fax';
import PhoneIcon from '@mui/icons-material/Phone';

// components
import AddNewButton from '../../../../AddNewButton';
import FormTextFieldVariant from './FormTextFieldVariant';
import FormMUIPhoneVariant from './FormMUIPhoneVariant';
import { useTranslation } from 'react-i18next';

const FormContactInformationVariant = ({ formHook, name, props }) => {
	const { readOnly } = useContext(FormContext);

	const { control, getValues } = formHook;
	const { t } = useTranslation('organization');
	const { fields, prepend, remove } = useFieldArray({
		control,
		name,
	});

	const shouldRender = (index, system) => getValues(`${name}.${index}.system`) === system;

	useEffect(() => {
		if (
			(props.contactsRequired && fields.length === 1 && fields[0].system === 'url') ||
			(props.contactsRequired && fields.length === 0)
		) {
			prepend([
				{
					system: 'phone',
					value: '',
					rank: fields.length + 1,
					id: generateRandomString(16),
				},
				{
					system: 'email',
					value: '',
					rank: fields.length + 1,
					id: generateRandomString(16),
				},
			]);
		}
	}, [props.contactsRequired]);

	const endAdornment = (index, disabled) =>
		!props.disabled &&
		!disabled && (
			<InputAdornment position="end">
				<Tooltip title={t('icons.deleteContact')}>
					<IconButton edge="end" onClick={() => remove(index)}>
						<CloseIcon />
					</IconButton>
				</Tooltip>
			</InputAdornment>
		);

	const renderSection = (title, icon, system, disabled, tooltipTitle) => (
		<MuiGrid key={system} item data-cy="form-contact-information" xs={4}>
			<Typography
				component="div"
				sx={{
					display: 'flex',
					alignItems: 'center',
					fontSize: '15px',
					marginBottom: '15px',
				}}
			>
				<Icon component={icon} sx={{ marginRight: '15px' }} /> {t(title)}
			</Typography>

			{!readOnly && !props.disabled && !disabled && (
				<AddNewButton
					fullWidth
					sx={{ marginBottom: '10px' }}
					tooltipTitle={tooltipTitle}
					onClick={() =>
						prepend({
							system,
							value: '',
							rank: fields.length + 1,
							id: generateRandomString(16),
						})
					}
				/>
			)}

			{_.map(
				fields,
				(field, index) =>
					shouldRender(index, system) &&
					(field.system === 'phone' || field.system === 'fax' ? (
						<FormMUIPhoneVariant
							key={getValues(`${name}.${index}.id`)}
							formHook={formHook}
							name={`${name}.${index}.value`}
							props={{
								...props,
								id: `form-field-${title}`,
								sx: { marginBottom: '10px', width: '100%' },
								InputProps: {
									...props.InputProps,
									endAdornment: endAdornment(index, disabled || readOnly),
								},
								...(disabled ? { disabled } : undefined),
								readOnly,
							}}
						/>
					) : (
						<FormTextFieldVariant
							key={getValues(`${name}.${index}.id`)}
							formHook={formHook}
							name={`${name}.${index}.value`}
							props={{
								...props,
								id: `form-field-${title}`,
								sx: { marginBottom: '10px' },
								InputProps: {
									...props.InputProps,
									endAdornment: endAdornment(index, disabled),
								},
								...(disabled ? { disabled } : undefined),
							}}
						/>
					))
			)}
		</MuiGrid>
	);

	return (
		<MuiGrid container spacing={2}>
			{props.hideEmail
				? null
				: renderSection('Email', EmailIcon, 'email', props.disableEmail, t('icons.addEmail'))}

			{props.hidePhone
				? null
				: renderSection('Phone', PhoneIcon, 'phone', props.disablePhone, t('icons.addPhone'))}

			{props.hideFax ? null : renderSection('Fax', FaxIcon, 'fax', props.disableFax, t('icons.addFax'))}
		</MuiGrid>
	);
};

export default FormContactInformationVariant;
