// core
import React, { useContext, useEffect, useRef, useMemo } from 'react';
import { generateRandomString } from '@worklist-2/core/src';

// contexts
import FormContext from '../../../formContexts/FormContext';

// utils
import { hasError } from '../../../formUtils/formUtils';

// libraries
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// MUI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MuiGrid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

// MUI icons
import EmailIcon from '@mui/icons-material/Email';
import FaxIcon from '@mui/icons-material/Fax';
import PhoneIcon from '@mui/icons-material/Phone';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';

// components
import FormTextFieldVariant from './FormTextFieldVariant';
import FormMUIPhoneVariant from './FormMUIPhoneVariant';

const ActionButton = ({ addTooltipTitle, deleteTooltipTitle, onAddClick, onDeleteClick }) => (
	<>
		<Tooltip title={addTooltipTitle}>
			<Button sx={StyleProps.actionButton} onClick={onAddClick}>
				<AddIcon sx={{ color: ' #FFFFFF99' }} />
			</Button>
		</Tooltip>
		<Tooltip title={deleteTooltipTitle}>
			<Button sx={StyleProps.actionButton} onClick={onDeleteClick}>
				<DeleteOutlinedIcon sx={{ color: '#CF6679' }} />
			</Button>
		</Tooltip>
	</>
);

const FormContactInformationVariantV2 = ({ formHook, name, props }) => {
	const { readOnly, hoveredField, setHoveredField } = useContext(FormContext);
	const {
		control,
		getValues,
		formState: { errors },
	} = formHook;
	const { t } = useTranslation('organization');
	const contactRef = useRef(null);
	const { fields, append, remove } = useFieldArray({ control, name });

	const shouldRender = (index, system) => getValues(`${name}.${index}.system`) === system;

	useEffect(() => {
		const defaultSystems = ['phone', 'email', 'fax'];
		defaultSystems.forEach(system => {
			const systemExists = fields?.some(telecom => telecom.system === system);
			if (!systemExists) {
				append({
					system,
					value: '',
					rank: fields.length + 1,
					id: generateRandomString(16),
				});
			}
		});
	}, [fields, append]);

	const renderSection = (title, icon, system, disabled, tooltipTitle) => {
		const fieldData = fields.filter(field => field.system === system);
		const onAddButtonClick = () => {
			append(
				{
					system,
					value: '',
					rank: fields.length + 1,
					id: generateRandomString(16),
				},
				{
					focusName: `${name}.${fields.length + 1}.value`,
				}
			);
		};

		return (
			<MuiGrid
				key={system}
				item
				data-cy="form-contact-information"
				sx={{ minHeight: `${fieldData.length * 38 + 56}px` }}
				xs={4}
			>
				<Typography component="div" sx={StyleProps.headerText}>
					<Icon component={icon} sx={{ marginRight: '10px' }} /> {t(title)}
				</Typography>

				{fields.map(
					(field, index) =>
						shouldRender(index, system) && (
							<Box
								key={getValues(`${name}.${index}.id`)}
								sx={{ display: 'flex', columnGap: '5px' }}
								onMouseEnter={() =>
									!disabled && setHoveredField && setHoveredField(`${name}.${index}.value`)
								}
								onMouseLeave={() => !disabled && setHoveredField && setHoveredField('')}
							>
								{field.system === 'phone' || field.system === 'fax' ? (
									<FormMUIPhoneVariant
										formHook={formHook}
										name={`${name}.${index}.value`}
										props={{
											...props,
											id: `form-field-${title}`,
											sx: { marginBottom: '6px', width: '100%' },
											InputProps: {
												...props.InputProps,
											},
											...(disabled ? { disabled } : undefined),
											readOnly:
												readOnly &&
												hoveredField !== `${name}.${index}.value` &&
												!hasError(`${name}.${index}.value`, errors),
										}}
									/>
								) : (
									<FormTextFieldVariant
										formHook={formHook}
										name={`${name}.${index}.value`}
										props={{
											...props,
											placeholder: 'N/A',
											id: `form-field-${title}`,
											sx: { marginBottom: '6px' },
											InputProps: {
												...props.InputProps,
											},
											hoveredField:
												hoveredField === `${name}.${index}.value` ||
												hasError(`${name}.${index}.value`, errors),
											...(disabled ? { disabled } : undefined),
										}}
									/>
								)}
								{!(readOnly && hoveredField !== `${name}.${index}.value`) && (
									<ActionButton
										addTooltipTitle={tooltipTitle}
										deleteTooltipTitle={t('icons.deleteContact')}
										onAddClick={onAddButtonClick}
										onDeleteClick={() => remove(index)}
									/>
								)}
							</Box>
						)
				)}
			</MuiGrid>
		);
	};

	return (
		<MuiGrid ref={contactRef} container columnSpacing={2}>
			{!props.hideEmail && renderSection('Email', EmailIcon, 'email', props.disableEmail, t('icons.addEmail'))}
			{!props.hidePhone && renderSection('Phone', PhoneIcon, 'phone', props.disablePhone, t('icons.addPhone'))}
			{!props.hideFax && renderSection('Fax', FaxIcon, 'fax', props.disableFax, t('icons.addFax'))}
		</MuiGrid>
	);
};

export default FormContactInformationVariantV2;

const StyleProps = {
	headerText: {
		display: 'flex',
		alignItems: 'center',
		fontSize: '15px',
		marginBottom: '8px',
		color: '#FFFFFF99',
	},
	actionButton: {
		width: '34px',
		minWidth: '34px',
		maxHeight: '56px',
		borderRadius: '5px',
		border: '1px solid #C4C4C41A',
		mb: '6px',
		background: '#282828',
	},
};
