import React from 'react';
import axios from 'axios';

import { Box, IconButton } from '@mui/material';
import Add from '@mui/icons-material/Add';
import DownloadOutlined from '@mui/icons-material/DownloadOutlined';
import PrintOutlined from '@mui/icons-material/PrintOutlined';
import Remove from '@mui/icons-material/Remove';

import HoldToDelete from '@worklist-2/ui/src/components/HoldToDelete';

const maxZoomIn = 3.0;
const maxZoomOut = 0.5;

const Toolbar = ({
	show,
	setZoomLevel,
	headers = {},
	fileUrl,
	zoomLevel,
	currentPage,
	loading,
	pageCount,
	handleOnDelete,
}) => {
	if (!show) return null;

	const handleZoomIn = () => {
		setZoomLevel(prevZoomLevel => Math.min(prevZoomLevel + 0.2, maxZoomIn));
	};

	const handleZoomOut = () => {
		setZoomLevel(prevZoomLevel => Math.max(prevZoomLevel - 0.2, maxZoomOut));
	};

	const handleDownload = async () => {
		try {
			const response = await axios.get(fileUrl, { responseType: 'blob', headers: { ...headers } });

			if (response?.status === 200) {
				const blob = new Blob([response.data], { type: 'application/html' });
				const winURL = window.URL || window.webkitURL;
				const blobUrl = winURL.createObjectURL(blob);
				const anchorElem = document.createElement('a');
				anchorElem.href = blobUrl;
				anchorElem.download = `content.pdf`;
				anchorElem.click();
				anchorElem.remove();
			}
		} catch (err) {
			console.log('Error downloading the PDF file', err);
		}
	};

	const handlePrint = () => {
		fetch(fileUrl, {
			headers: {
				...headers,
			},
		})
			.then(response => response.blob())
			.then(blob => {
				const url = window.URL.createObjectURL(blob);
				const printWindow = window.open(url, '_blank');
				printWindow.onload = () => {
					printWindow.print();
				};
			})
			.catch(_err => {
				console.log('Error printing the PDF file', _err);
			});
	};

	return (
		<Box
			sx={{
				...styles.mainBox,
				visibility: loading ? 'hidden' : undefined,
			}}
		>
			<Box sx={styles.innerBox}>
				<IconButton
					data-testid="zoom-out"
					disabled={zoomLevel <= maxZoomOut}
					sx={styles.icon}
					onClick={handleZoomOut}
				>
					<Remove fontSize="small" />
				</IconButton>
				<IconButton
					data-testid="zoom-in"
					disabled={zoomLevel >= maxZoomIn}
					sx={styles.icon}
					onClick={handleZoomIn}
				>
					<Add fontSize="small" />
				</IconButton>
				<Box sx={styles.pageBox}>{currentPage}</Box>
				<p style={{ color: '#ffffff', margin: '0px 10px 0px 5px' }}>
					<span style={{ marginRight: '10px', color: '#cacaca' }}>/</span>
					{pageCount}
				</p>
				<IconButton data-testid="download" sx={styles.icon} onClick={handleDownload}>
					<DownloadOutlined fontSize="small" />
				</IconButton>
				<IconButton data-testid="print" sx={styles.icon} onClick={handlePrint}>
					<PrintOutlined fontSize="small" />
				</IconButton>
				{handleOnDelete && (
					<HoldToDelete
						backgroundColor="#dadada50"
						height="36px"
						iconColor="#cacaca"
						progressStyles={{ width: '30px', height: '30px' }}
						sx={{
							'&:hover': {
								backgroundColor: '#393939',
							},
						}}
						width="36px"
						onDelete={handleOnDelete}
					/>
				)}
			</Box>
		</Box>
	);
};

export default Toolbar;

const styles = {
	mainBox: {
		position: 'absolute',
		bottom: '80px',
		width: '100%',
		margin: '0 auto',
	},
	innerBox: {
		margin: '0 auto',
		display: 'flex',
		alignItems: 'center',
		borderRadius: '25px',
		gap: 1,
		height: '45px',
		boxSizing: 'border-box',
		background: '#000000',
		padding: '3px 8px',
		width: 'fit-content',
	},
	pageBox: {
		background: '#dadada50',
		color: '#ffffff',
		borderRadius: '5px',
		width: '35px',
		height: '25px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginLeft: '10px',
		fontSize: '13px',
	},
	icon: { background: '#dadada50', color: '#cacaca', borderRadius: '100%' },
};
