/* eslint-disable react/jsx-props-no-spreading */
// @flow
//core
import React, { useEffect, useState, useCallback, type Node } from 'react';
//libs
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import _ from 'lodash';
import { useIMask } from 'react-imask';
// components
import { NumericInputArrows } from '../NumericInputArrows/NumericInputArrows';

// why do we use types for javascript file?
interface IFieldRef {
	current: any;
}
interface ITextSearch {
	hideIcon: boolean; // Hides the magnifier icon
	fieldRef: IFieldRef; // A reference to the input field of the component; used for clearing the value when a filter is removed
	labelAlwaysShrunk: boolean; // Whether the label is always shrinked or not
	width: number; // Controls the width of the component. If `fullWidth` is true, this value does not take effect.
	fullWidth: boolean; // Determines if the component should take up the full width of the container.
	label: string; // Label of the textfield
	style: object;
	disable: boolean; // Controls if the textfield is disabled or not
	error: boolean; // Controls if the textfield should display an error or not
	onSearch: (value: string) => void; // Handler for handling the typing
	defaultValue: string;
	testId: string;
	type: string;
	otherProps: object;
	placeholder: string;
	clearText: string;
	mask: string;
	onFocus: () => void;
	onBlur: () => void;
}

const TextSearch = ({
	hideIcon,
	fieldRef,
	labelAlwaysShrunk,
	width = 300,
	fullWidth = false,
	label,
	style,
	disable = false,
	error = false,
	onSearch,
	defaultValue,
	testId,
	type,
	otherProps,
	placeholder = 'Search',
	clearText = 'Clear',
	mask = /(?:.*)/,
	onFocus,
	onBlur,
}: ITextSearch): Node => {
	const [searchValue, setSearchValue] = useState(defaultValue || '');
	// Temporary fix for onSearch being called after new column layout being
	// loaded in and overwriting the saved filters with ''
	const [notFirstRender, setNotFirstRender] = useState(false);
	const { ref, unmaskedValue, setValue } = useIMask({ mask });

	const handleClear = () => {
		if (fieldRef) fieldRef.current.value = '';
		if (ref) ref.current.value = '';
		setSearchValue('');
		setValue('');
	};

	const debounceSetSearchValue = useCallback(
		_.debounce(newValue => {
			// clear current value
			setSearchValue(null);

			// add new value
			setSearchValue(newValue);
		}, 500),
		[]
	);

	useEffect(() => {
		setNotFirstRender(true);
	}, []);

	useEffect(() => {
		if (!!(onSearch && !_.isNil(searchValue)) && notFirstRender) {
			onSearch(searchValue);
			if (onBlur) {
				setTimeout(() => {
					onBlur();
				}, 10000);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	// handler to change value on numeric input arrows click (Increase of decrease)
	const handleNumericInputChange = value => {
		debounceSetSearchValue(value);
		if (ref) {
			ref.current.value = value;
			setValue(value);
		}
	};

	return (
		<TextField
			InputLabelProps={{ shrink: labelAlwaysShrunk }}
			InputProps={{
				startAdornment: !hideIcon && (
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
				),
				endAdornment: (
					// eslint-disable-next-line react/jsx-no-useless-fragment
					<>
						{type === 'number' ? (
							<NumericInputArrows inputValue={searchValue} onChange={handleNumericInputChange} />
						) : (
							<InputAdornment position="end" sx={fieldRef?.current?.value ? null : { display: 'none' }}>
								<IconButton
									aria-label="Clear"
									data-cy={`${label}_clear`}
									size="small"
									sx={{ width: '28px', height: '28px' }}
									title={clearText}
									onClick={handleClear}
								>
									<CloseIcon />
								</IconButton>
							</InputAdornment>
						)}
					</>
				),
				placeholder,
			}}
			autoComplete="off"
			data-cy={testId}
			defaultValue={defaultValue}
			disabled={disable}
			error={error}
			fullWidth={fullWidth}
			id="outlined-basic"
			inputRef={node => {
				ref.current = node;
				if (fieldRef) {
					fieldRef.current = node;
				}
			}}
			label={label}
			type={type}
			// This component should be either controlled with (value, setValue) or uncontrolled with (ref, and defaultValue)
			// setting both creates mess and unpredicted behaviour
			// comment this line to use it as uncontrolled component
			// value={searchValue}
			sx={{ ...(fullWidth ? '' : { width }), ...style }}
			variant="outlined"
			onBlur={onBlur}
			onFocus={onFocus}
			onKeyUp={() => debounceSetSearchValue(unmaskedValue)}
			{...otherProps}
		/>
	);
};

export default TextSearch;
