import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab, { tabClasses } from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SearchResults from '@rs-components//SearchResults/SearchResults';
import {
	useSearchScope,
	useAppModeContext,
	useTabContext,
	mediaTablet,
	useIsTablet,
	useIsMobile,
} from '@worklist-2/core/src';
import _ from 'lodash';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';
import { HIDDEN_HELP_CENTER_CATEGORIES } from '@worklist-2/patientPortal/src/consts';
import { useDrawersStore } from '@worklist-2/patientPortal/src/stores';

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	const { appMode } = useAppModeContext();

	return (
		<div
			aria-labelledby={`simple-tab-${index}`}
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			role="tabpanel"
			{...other}
		>
			{value === index && (
				<Box
					sx={{
						p: appMode == 'worklist' || appMode == 'marketplace' ? 3 : '',
						pb: 0,
					}}
				>
					<Typography component="div">{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const SearchTabs = ({
	tab,
	data,
	showTabsHeader,
	setShowDialogHeader,
	selected = 0,
	closeSearchDialog,
	setStudyInDetailedView,
	setIsSharedType,
}) => {
	const [value, setValue] = useState(0);
	const [results, setResults] = useState([]);
	const [tabSelected, setTabSelected] = useState(selected);
	const [showTabs, setShowTabs] = useState(showTabsHeader);
	const { updatePlaceholder } = useTabContext();
	const isTablet = useIsTablet();
	const isMobile = useIsMobile();
	const searchTabMarginTop = isTablet || isMobile ? '12px' : '-15px';

	const __searchScopeContext = useSearchScope();
	const { appMode, isPatientPortalMode } = useAppModeContext();
	const { t } = useTranslation('Search');

	const { searchText, mobileSearchDrawerOpen, mobileSearchText } = useDrawersStore(state => ({
		searchText: state.searchText,
		mobileSearchDrawerOpen: state.mobileSearchDrawerOpen,
		mobileSearchText: state.mobileSearchText,
	}));

	const isBlumeHomePage = searchText?.length > 0 || mobileSearchDrawerOpen;
	const forceChatGptAssistant =
		searchText.toLowerCase().includes('what is') ||
		(isBlumeHomePage && mobileSearchText.toLowerCase().includes('what is'));

	const tabs =
		appMode === 'worklist' || appMode === 'marketplace'
			? ['All', 'Patient', 'Study', 'Order', 'Visit', 'Organization', 'User', 'Help', 'Marketplace']
			: ['All', 'Studies', 'Shared', 'Help Center'];

	const handleRelatedTabData = data => {
		setResults(data);
	};

	useEffect(() => {
		if (tab) {
			handleTabChange(tab);
		}
	}, [tab]);

	useEffect(() => {
		// Filter unwanted categories for Blume
		if (isPatientPortalMode()) {
			setResults(data?.filter(article => !HIDDEN_HELP_CENTER_CATEGORIES.includes(article?.Category)));
		} else {
			setResults(data);
		}
	}, [data]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		setTabSelected(newValue);
	};

	const handleTabChange = value => {
		if (isPatientPortalMode() && !showTabs) {
			setShowDialogHeader(false);
			setShowTabs(true);
			updatePlaceholder(value);
		}
		setValue(tabs.indexOf(value));
		setTabSelected(tabs.indexOf(value));
	};

	const checkTabDataExistance = tabLabel => {
		if (tabLabel === 'All') return results?.length > 0;

		const tabResourceType = getTabResourceType(tabLabel);

		return results.some(({ resourceType }) => resourceType === tabResourceType);
	};

	const TabsList = styled(TabsListUnstyled)`
		border-radius: 16px;
		margin-bottom: 16px;
		display: flex;
		align-items: left;
		justify-content: left;
		align-content: space-between;
		height: 32%;
		width: 130%;

		${mediaTablet} {
			justify-content: space-between;
		}
	`;

	const PatientPortalTab = styled(TabUnstyled)`
		font-family: Roboto;
		color: #42a5f5;
		cursor: pointer;
		font-size: 14px;
		font-weight: Regular;
		background-color: #f0f7ff;
		height: 100%;
		padding: 6px 12px;
		margin-right: 8px;
		border: none;
		border-radius: 25px;
		display: flex;
		justify-content: left;
		letter-spacing: 0.25px !important;
		&:last-child {
			margin-right: 0px;
		}

		${mediaTablet} {
			font-size: 14px !important;
			margin: 0;
			padding: 6px 12px;
			align-items: center;
			letter-spacing: 0.25px !important;

			&.${tabUnstyledClasses.selected} {
				height: 30px !important;
				padding-bottom: 5px !important;
			}
		}

		&:hover {
			background-color: #3399ff;
			border-radius: 25px;
			color: rgba(255, 255, 255, 0.87);
		}

		&:focus {
			color: #fff;
			border-radius: 25px;
		}

		&.${tabUnstyledClasses.selected} {
			background-color: rgba(66, 165, 245, 0.2);
			border-radius: 25px;
			border: 1px solid #42a5f5;
			height: 32px;
		}
	`;

	return (
		<Box
			className="searchTabs"
			sx={{
				marginTop: appMode === 'patientPortal' ? searchTabMarginTop : undefined,
			}}
		>
			<Box>
				{tab === 'All' &&
					(appMode === 'worklist' || appMode === 'marketplace') &&
					!__searchScopeContext.loading && (
						<Tabs centered value={value} onChange={handleChange}>
							{_.map(tabs, (tabItem, i) => {
								const tabIsHidden = !checkTabDataExistance(tabItem);

								return (
									<Tab
										key={tabItem}
										disabled={tabIsHidden}
										label={t(tabItem)}
										sx={{
											cursor: 'pointer',

											// cannot use `display: none;` because the `Tabs` component would throw an error that it couldn't find some of the tabs
											visibility: tabIsHidden ? 'hidden' : undefined,
											maxHeight: tabIsHidden ? 0 : undefined,
											overflow: tabIsHidden ? 'hidden' : undefined,
											pointerEvents: tabIsHidden ? 'none' : undefined,
											opacity: tabIsHidden ? 0 : undefined,

											[`&.${tabClasses.disabled}`]: {
												opacity: 0.5,
												cursor: 'not-allowed',
												pointerEvents: 'unset',
												display: 'none',
											},
										}}
										{...a11yProps(i)}
									/>
								);
							})}
						</Tabs>
					)}

				{results?.length > 0 &&
					appMode === 'patientPortal' &&
					!__searchScopeContext.loading &&
					!forceChatGptAssistant && (
						<TabsUnstyled
							style={{
								display: showTabs ? '' : 'None',
								overflowY: 'scroll',
							}}
							value={tabSelected}
							onChange={handleChange}
						>
							<TabsList>
								{_.map(tabs, (patientPortalTabItems, i) => (
									<PatientPortalTab
										style={{
											fontWeight: '400',
											lineHeight: '20px',
										}}
									>
										{tabSelected === i ? (
											<DoneIcon
												sx={{
													paddingRight: '10px',
													fontSize: 'large',
													[mediaTablet]: {
														paddingRight: '8px',
														fontSize: '16px',
													},
												}}
											/>
										) : (
											''
										)}
										{t(patientPortalTabItems)}
									</PatientPortalTab>
								))}
							</TabsList>
						</TabsUnstyled>
					)}
			</Box>

			{tabs.map((tabItem, i) => (
				<TabPanel key={tabItem} index={i} value={value}>
					<SearchResults
						closeSearchDialog={closeSearchDialog}
						data={results}
						handleRelatedTabData={handleRelatedTabData}
						setIsSharedType={setIsSharedType}
						setStudyInDetailedView={setStudyInDetailedView}
						tab={tabItem}
						onTabChange={handleTabChange}
					/>
				</TabPanel>
			))}
		</Box>
	);
};

SearchTabs.propTypes = {
	tab: PropTypes.string,
	data: PropTypes.array,
	closeSearchDialog: PropTypes.func,
};

export default SearchTabs;

export const getTabResourceType = tab =>
	({
		Study: 'ImagingStudyWorklist',
		Order: 'ServiceRequest',
		Visit: 'Encounter',
		User: 'Practitioner',
		Studies: 'BlumeStudy',
		Shared: 'StudySharedWithMe',
		'Help Center': 'helpcenter',
	})[tab] || tab;
