import * as React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
	color: theme.palette.text.border,
	height: '54px',
	background: 'transparent',
	border: `1px dashed ${theme.palette.text.border}`,

	'&:hover': {
		backgroundColor: 'transparent',
		border: `1px dashed ${theme.palette.text.primary}`,
		'.MuiSvgIcon-root': {
			color: theme.palette.text.primary,
		},
	},
}));

const AddNewButton = ({
	to,
	children = null,
	disabled,
	fullWidth,
	width,
	sx = {},
	onClick,
	tooltipTitle,
	testId = 'share-button',
}) => {
	const toDefined = typeof to !== 'undefined';

	return (
		<Tooltip data-testid="button-tooltip" title={tooltipTitle ?? ''}>
			<StyledButton
				className="toolbar-btn-cls"
				component={toDefined ? RouterLink : undefined}
				data-cy="add-new-btn"
				data-testid={testId}
				disabled={disabled}
				fullWidth={fullWidth}
				sx={{ width: { width }, ...sx }}
				to={toDefined ? to : undefined}
				onClick={onClick}
			>
				<AddIcon className="toolbar-btn-cls" />
				{children}
			</StyledButton>
		</Tooltip>
	);
};

AddNewButton.propTypes = {
	children: PropTypes.node,
	to: PropTypes.any,
	disabled: PropTypes.bool,
	fullWidth: PropTypes.bool,
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	sx: PropTypes.object,
	onClick: PropTypes.func,
	tooltipTitle: PropTypes.string,
	testId: PropTypes.string,
};

export default AddNewButton;
