import { ZOOM_TYPES } from '../consts/consts';
import { utilities, ramsoft } from '@cornerstonejs/core';
import { renderingEngineId } from '../ImageViewerCornerstoneContext';

export const useApplyHPRulesToViewport = () => {
	const setToViewPortZoomTypes = (fit, viewportId) => {
		switch (fit) {
			case ZOOM_TYPES.original:
				ramsoft.pixels?.({ renderingEngineId, viewportId });
				break;
			case ZOOM_TYPES.contain:
				ramsoft.fitViewport?.({ renderingEngineId, viewportId });
				break;
			case ZOOM_TYPES.cover:
				ramsoft.fillViewport?.({ renderingEngineId, viewportId });
				break;
			default:
				break;
		}
	};

	const applyHPRulesToViewport = ({ viewport }) => {
		const viewportId = viewport.id;

		viewport.resetCamera();

		ramsoft.setInitialCamera?.({ renderingEngineId, viewportId });

		viewport.defaultOptions?.defaultRules?.map(rule => {
			switch (rule.type) {
				case 'scaling':
					const { zoom, fit } = rule.values;

					let newZoom = zoom;

					if (newZoom) {
						newZoom = Number(newZoom) / 100;
					}

					if (fit) {
						setToViewPortZoomTypes(fit, viewportId);
					} else if (newZoom) {
						viewport.setZoom?.(newZoom);
					}
					break;
				case 'orientation':
					const { align, rotateL, rotateR, rotate180, flipH, flipV } = rule.values;

					if (rotateL) {
						viewport.setProperties?.({
							rotation: -90,
						});
					}

					if (rotateR) {
						viewport.setProperties?.({
							rotation: 90,
						});
					}

					if (rotate180) {
						viewport.setProperties?.({
							rotation: 180,
						});
					}

					if (flipH) {
						viewport.setCamera?.({
							...viewport.getCamera?.(),
							flipHorizontal: flipH,
						});
					}

					if (flipV) {
						viewport.setCamera?.({
							...viewport.getCamera?.(),
							flipVertical: flipV,
						});
					}

					if (align) {
						if (align === 'top') {
							ramsoft.alignTop?.({ renderingEngineId, viewportId });
						}

						if (align === 'right') {
							ramsoft.alignRight?.({ renderingEngineId, viewportId });
						}

						if (align === 'bottom') {
							ramsoft.alignBottom?.({ renderingEngineId, viewportId });
						}

						if (align === 'left') {
							ramsoft.alignLeft?.({ renderingEngineId, viewportId });
						}
					}

					break;
				case 'windowPresets':
					if (rule.values?.wl && rule.values?.ww) {
						const { lower, upper } = utilities.windowLevel.toLowHighRange?.(rule.values.ww, rule.values.wl);

						viewport.setProperties?.({
							voiRange: {
								upper,
								lower,
							},
						});
					}
					break;
				default:
					break;
			}
		});
	};

	return {
		applyHPRulesToViewport,
	};
};
