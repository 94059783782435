import React from 'react';
import { Button } from '@mui/material';

const CustomButton = ({ children, outlined, disabled, onClick, sx }) => (
	<Button
		disabled={disabled}
		sx={{
			minWidth: '82px',
			padding: '5px 9px',
			fontFamily: 'Inter',
			fontWeight: 500,
			fontSize: '14px',
			letterSpacing: '1.5px',
			color: outlined ? '#4D79EA' : '#FFFFFF',
			backgroundColor: outlined ? '#FFFFFF' : '#4D79EA',
			border: outlined ? '1px solid #4D79EA' : '1px solid #FFFFFF',
			opacity: '1',
			'&:hover': {
				backgroundColor: outlined ? '#FFFFFF' : '#4D79EA',
			},
			'&.Mui-disabled': {
				color: outlined ? '#4D79EA' : '#FFFFFF',
				opacity: 0.5,
			},
			...sx,
		}}
		onClick={onClick}
	>
		{children}
	</Button>
);

export default CustomButton;
