// @flow

// core
import React, { type Node } from 'react';
// components
import { type ISkeletonProps, LoaderSkeleton } from './LoaderSkeleton';
import { Box } from '@mui/material';

export const PatientBirthDate = ({ children, isLoading }: ISkeletonProps): Node =>
	isLoading ? (
		<Box data-testid="birth-date-loader">
			<LoaderSkeleton sx={{ height: '24px', width: '181px' }} />
		</Box>
	) : (
		children
	);
