import { create } from 'zustand';
import _ from 'lodash';

import { commonMiddlewares } from './middlewares';
import getUserFacilities from '../api/getUserFacilities';
import shareStudyAPI from '../api/shareStudy';
import shareStudyWithFacilityAPI from '../api/shareDICOM';
import shareDocumentWithFacilityAPI from '../api/shareDocumentWithFacility';
import unshareStudyAPI from '../api/unshareStudy';
import unshareDICOMAPI from '../api/unshareDICOM';
import unshareDocumentAPI from '../api/unshareDocument';

export const useShareDrawerStore = create(
	commonMiddlewares((set, get) => ({
		// State
		__config: null,
		isOpen: false,
		isLoading: false,
		studyId: null, //The ID of the study associated with the drawer. Should be null if document is present
		patientId: null,
		documentId: null, // The ID of the document associated with the drawer. Should be null if study is present
		contacts: [],
		user: null,
		users: [],
		sharedUsers: [],
		facilities: [],
		sharedFacilities: [],
		shareType: null, // [withMe, byMe] not setting this and no use as of now
		isStudyShareSuccess: false, // Used to update study outside of this store when share successful
		isDocumentShareSuccess: false, // Used to update document outside of this store when share successful

		// Actions

		/**
		 * Used for fetching/setting init study data for drawer
		 * @param {{
		 * study,
		 * shareType,
		 * forceFacilityFetch
		 * }} object
		 * @returns {state}
		 */
		initStudyDrawer: async ({ study, shareType, forceFacilityFetch }) => {
			// Open drawer directly
			set(() => ({ isOpen: true, isLoading: true, documentId: null }), false, 'share-drawer/initStudyDrawer');

			// Get values from state
			const { __config, contacts, user } = get();

			const { id: studyId, sharedInformation, isUploaded, patientId } = study;

			// Facility (Only for uploaded study)
			let facilities = [];
			let sharedFacilities = [];

			if (forceFacilityFetch || isUploaded) {
				const userFacilities = await getUserFacilities({ __config, familyMemberHash: user.profileId });
				facilities = _.differenceBy(
					userFacilities,
					sharedInformation?.filter(info => info.shareType === 'Facility'),
					facility => facility.resource?.id || facility.id
				);
				sharedFacilities = _.intersectionBy(
					userFacilities,
					sharedInformation?.filter(info => info.shareType === 'Facility'),
					facility => facility.resource?.id || facility.id
				);
			}

			// Users
			const sharedContactList = _.intersectionBy(
				contacts,
				sharedInformation?.filter(info => info.shareType === 'Contact'),
				'email'
			);

			const users = contacts
				.filter(
					// prettier-ignore
					({ email, isMyContact }) => isMyContact && !sharedContactList?.some((innerContact) => innerContact?.email === email)
				)
				.sort((a, b) => a?.name?.localeCompare(b?.name));

			const sharedUsers = contacts
				.map(contact => {
					const { email, isMyContact } = contact;
					const item = sharedInformation
						?.filter(info => info.shareType === 'Contact')
						?.find(innerContact => innerContact?.email === email);

					if (isMyContact && item)
						// Both contact details or shared information details
						return {
							...contact,
							...item,
						};

					return null;
				})
				.filter(Boolean);

			return set(
				() => ({
					studyId,
					users,
					sharedUsers,
					facilities,
					sharedFacilities,
					shareType,
					isLoading: false,
					patientId,
				}),
				false,
				'share-drawer/initStudyDrawer' // Action name that will come in redux dev tools
			);
		},

		/**
		 * Used for fetching/setting init study data for drawer
		 * @param {{
		 * document,
		 * shareType
		 * }} object
		 * @returns {state}
		 */
		initDocumentDrawer: async ({ document, shareType, forceFacilityFetch, isUploaded }) => {
			// Open drawer directly
			set(() => ({ isOpen: true, isLoading: true, studyId: null }), false, 'share-drawer/initDocumentDrawer');

			// Get values from state
			const { contacts, user, __config } = get();

			const { id: documentId, sharedInformation } = document;

			const users = _.differenceBy(contacts, sharedInformation, 'email').sort((a, b) =>
				a?.name?.localeCompare(b?.name)
			);

			let facilities = [];
			let sharedFacilities = [];

			if (forceFacilityFetch || isUploaded) {
				const userFacilities = await getUserFacilities({ __config, familyMemberHash: user.profileId });
				facilities = _.differenceBy(
					userFacilities,
					sharedInformation?.filter(info => info.shareType === 'Facility'),
					facility => facility.resource?.id || facility.id
				);
				sharedFacilities = _.intersectionBy(
					userFacilities,
					sharedInformation?.filter(info => info.shareType === 'Facility'),
					facility => facility.resource?.id || facility.id
				);
			}

			const sharedUsers = contacts
				.map(contact => {
					const { email, isMyContact } = contact;
					const item = sharedInformation
						?.filter(info => info.shareType === 'Contact')
						?.find(innerContact => innerContact?.email === email);

					if (isMyContact && item)
						// Both contact details or shared information details
						return {
							...contact,
							...item,
						};

					return null;
				})
				.filter(Boolean);

			return set(
				() => ({
					isLoading: false,
					documentId,
					users,
					sharedUsers,
					facilities,
					sharedFacilities,
					shareType,
				}),
				false,
				'share-drawer/initDocumentDrawer'
			);
		},
		/**
		 * set shared states (for ex: __config) object so that we don't have to pass it everytime
		 * @param {{__config}} object config object from context
		 * @returns {state}
		 */
		setSharedStates: ({ __config }) => set(() => ({ __config }), false, 'share-drawer/setSharedStates'),
		toggleDrawer: bool =>
			set(({ isOpen }) => ({ isOpen: bool !== undefined ? bool : !isOpen }), false, 'share-drawer/toggleDrawer'),
		setContacts: contacts => {
			const { sharedUsers } = get();

			const users = contacts.filter(
				// prettier-ignore
				({ email, isMyContact }) => isMyContact && !sharedUsers?.some((innerContact) => innerContact?.email === email)
			);

			return set(() => ({ contacts, users }), false, 'share-drawer/setContacts');
		},
		setUser: user => set(() => ({ user }), false, 'share-drawer/setUser'),
		setUsers: users => set(() => ({ users }), false, 'share-drawer/setUsers'),
		toggleUserSelect: ({ email }) =>
			set(
				({ users }) => ({
					users: users.map(user => {
						if (user.email === email) {
							return {
								...user,
								selected: !user.selected,
								shareOption: !user.selected ? 'View Only' : undefined,
							};
						}

						return user;
					}),
				}),
				false,
				'share-drawer/toggleUserSelect'
			),
		toggleFacilitySelect: ({ resource: { id: userId } }) =>
			set(
				({ facilities }) => ({
					facilities: facilities.map(facility => {
						if (facility?.resource?.id === userId) {
							return {
								...facility,
								selected: !facility.selected,
								shareOption: !facility.selected ? 'View Only' : undefined,
							};
						}
						return facility;
					}),
				}),
				false,
				'share-drawer/toggleFacilitySelect'
			),
		deselectAll: () => {
			set(
				({ users, facilities }) => ({
					users: users.map(user => ({ ...user, selected: false })),
					facilities: facilities.map(facility => ({ ...facility, selected: false })),
				}),
				false,
				'share-drawer/deselectAll'
			);
		},
		deleteContact: email => {
			set(({ users, contacts }) => ({
				users: users.filter(usr => usr.email !== email),
				contacts: contacts.filter(usr => usr.email !== email),
			}));
		},
		/**
		 * Reset study and document data to reinit drawer later
		 */
		resetData: () =>
			set(
				() => ({
					users: [],
					sharedUsers: [],
					facilities: [],
					sharedFacilities: [],
					documentId: null,
					studyId: null,
					patientId: null,
				}),
				false,
				'share-drawer/resetData'
			),
		setStudyShareSuccess: (bool = false) =>
			set({ isStudyShareSuccess: bool }, false, 'share-drawer/setStudyShareSuccess'),
		setDocumentShareSuccess: (bool = false) =>
			set({ isDocumentShareSuccess: bool }, false, 'share-drawer/setDocumentShareSuccess'),
		shareStudy: async ({ users: newlySharedUsers }) => {
			const { __config, users, user, sharedUsers, deselectAll, studyId, resetData, patientId } = get();

			try {
				const sharedUsersPayload = newlySharedUsers.map(({ email, name }) => ({
					email,
					name,
				}));

				const res = await shareStudyAPI({ __config, data: sharedUsersPayload, studyId, patientId });

				if (!res) throw new Error('No response from share API');

				const newlySharedContactsList = [];
				const filteredUserContacts = users.filter(contact => {
					const matchingContact = newlySharedUsers.find(sharedUser => sharedUser.email === contact.email);

					// Push data for updating newly shared users
					if (matchingContact) {
						const { name, email, phone } = contact;

						newlySharedContactsList.push({
							id: null,
							name,
							email,
							phone,
							permission: contact.shareOption,
							timeToLive: null,
							isSeen: null,
							initialShared: user.email,
							sharedBy: user.email,
							shareType: 'Contact',
						});
					}

					return !matchingContact;
				});

				deselectAll();
				resetData();

				return set(
					() => ({
						users: filteredUserContacts,
						sharedUsers: [...sharedUsers, ...newlySharedContactsList],
						isOpen: false,
						isStudyShareSuccess: true,
					}),
					false,
					'share-drawer/shareStudy'
				);
			} catch (err) {
				console.error(err);
				deselectAll();
				throw err;
			}
		},
		shareStudyWithFacility: async ({ facilities: newlySharedFacilities }) => {
			try {
				const { __config, facilities, deselectAll, studyId, resetData } = get();

				const shareRequests = newlySharedFacilities.map(facility => {
					const internalManagingOrgId = facility?.resource?.id;
					const internalStudyId = studyId;
					const internalPatientID = facility?.patientId[0];

					if (internalPatientID) {
						return shareStudyWithFacilityAPI({
							__config,
							internalManagingOrgId,
							internalStudyId,
							internalPatientID,
						});
					}

					return null;
				});
				const tempSharedFacilities = [];

				const sharedResponses = await Promise.all(shareRequests);

				const filteredUserFacilties = facilities.filter(facility => {
					const matchingFacility = newlySharedFacilities.find(
						sharedFacility => sharedFacility.resource.id === facility.resource.id
					);

					// Push data for updating newly shared facilities
					if (matchingFacility) {
						tempSharedFacilities.push(facility);
					}

					return !matchingFacility;
				});

				deselectAll();
				resetData();

				set(
					state => ({
						isOpen: false,
						facilities: filteredUserFacilties,
						sharedFacilities: [...state.sharedFacilities, ...tempSharedFacilities],
						isStudyShareSuccess: true,
					}),
					false,
					'share-drawer/shareStudyWithFacility'
				);

				return sharedResponses;
			} catch (err) {
				console.error(err);
				deselectAll();
				throw err;
			}
		},
		shareDocumentWithFacility: async ({ facilities: newlySharedFacilities, hash }) => {
			try {
				const { __config, facilities, deselectAll, documentId, resetData } = get();

				const payload = newlySharedFacilities.map(facility => ({
					id: facility?.resource?.id,
					shareType: 'Facility',
				}));

				const tempSharedFacilities = [];

				await shareDocumentWithFacilityAPI({
					__config,
					data: payload,
					documentId,
					hash,
				});

				const filteredUserFacilties = facilities.filter(facility => {
					const matchingFacility = newlySharedFacilities.find(
						sharedFacility => sharedFacility.resource.id === facility.resource.id
					);

					// Push data for updating newly shared facilities
					if (matchingFacility) {
						tempSharedFacilities.push(facility);
					}

					return !matchingFacility;
				});

				deselectAll();
				resetData();

				set(
					state => ({
						isOpen: false,
						facilities: filteredUserFacilties,
						sharedFacilities: [...state.sharedFacilities, ...tempSharedFacilities],
						isDocumentShareSuccess: true,
					}),
					false,
					'share-drawer/shareDocumentWithFacility'
				);

				return newlySharedFacilities;
			} catch (err) {
				console.error(err);
				deselectAll();
				throw err;
			}
		},
		unshareStudy: async ({ studyId, email }) => {
			try {
				const { __config } = get();

				await unshareStudyAPI({ __config, id: studyId, data: [email] });

				return set(
					() => ({
						isOpen: false,
						isStudyShareSuccess: true,
					}),
					false,
					'share-drawer/unshareStudy'
				);
			} catch (err) {
				console.error(err);
				throw err;
			}
		},
		unshareStudyWithFacility: async ({ internalManagingOrgId, studyId, patientId, data }) => {
			try {
				const { __config } = get();

				await unshareDICOMAPI({ __config, internalManagingOrgId, id: studyId, patientId, data });

				return set(
					() => ({
						isOpen: false,
						isStudyShareSuccess: true,
					}),
					false,
					'share-drawer/unshareStudyWithFacility'
				);
			} catch (err) {
				console.error(err);
				throw err;
			}
		},
		unshareDocument: async ({ documentId, email, isFacility }) => {
			try {
				const { __config } = get();

				await unshareDocumentAPI({ __config, documentId, email, isFacility });

				return set(() => ({
					isOpen: false,
					isDocumentShareSuccess: true,
				}));
			} catch (err) {
				console.error(err);
				throw err;
			}
		},
	}))
);
