import TableSortLabel from '@mui/material/TableSortLabel';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { ColumnFilter } from '../Grid/ColumnFilter';
import DataGridSort from './DataGridSort';
import Box from '@mui/system/Box';
import { useClickOutside } from '@rs-ui/components/AffiliatedOrganizations/useClickOutside';

const DataGridHeader = ({
	column,
	filterType,
	label,
	displayValue,
	searchValueSet,
	searchParameter,
	capitalizeOptions,
	suggestScope,
	options,
	preSelectedValues,
	itemList,
	setSortOrder,
	sortOrder,
	isSingleSort,
	func,
	type,
	customFilterOptions,
	mask,
	hideTags,
	sx,
	multiParam,
	valueField,
	showResetButtonOnDatePicker,
	columnWidth,
	valueSetExtraParam,
	searchComponentConfig,
	managingOrgId,
}) => {
	// Need to update the sort Direction
	const [sortDirection, setSortDirection] = useState(sortOrder);
	const filterRef = useRef(null);
	const [hoverClassnameActive, setHoverClassnameActive] = useState(false);
	const direction = useMemo(
		() => (sortOrder.includes(column) ? 'asc' : sortOrder.includes(`-${column}`) ? 'desc' : ''),
		[sortOrder, column, sortDirection]
	);

	const hasFilter = filterType !== 'none';

	const [isFilterFocused, setIsFilterFocused] = useState(false);
	useClickOutside(filterRef, () => {
		setIsFilterFocused(false);
	});
	const shouldFilterStayFocused = filterType =>
		filterType === 'single-select' ||
		filterType === 'single-select-checkbox' ||
		filterType === 'checkbox-multi-select' ||
		filterType === 'date-range' ||
		filterType === 'multi-select' ||
		filterType === 'infinite-scroll-multi-select-suggest';

	const SX = {
		wrapper: {
			maxWidth: options?.sort ? 'calc(100% - 30px)' : '100%',
			cursor: 'pointer',
			textAlign: 'left',
			display: 'flex',
			flex: 1,
			alignItems: 'center',
		},
	};

	return (
		<Box
			ref={filterRef}
			sx={sx}
			className={classnames(
				'table-head-cell',
				isFilterFocused && shouldFilterStayFocused(filterType) ? 'filterFocused' : '',
				hoverClassnameActive ? 'hover' : ''
				// ref[column].forceShowRef.current && 'hover'
			)}
			// onMouseEnter={() => {
			// 	ref[column].forceShowRef.current = true;
			// }}
			// onMouseLeave={() => {
			// 	ref[column].forceShowRef.current = false;
			// }}
		>
			<Box className="table-head-cell-content-root">
				<Box
					className="table-head-cell-content-wrapper"
					sx={SX.wrapper}
					onFocus={e => {
						e.preventDefault();
						setIsFilterFocused(true);
					}}
				>
					{hasFilter && (
						<ColumnFilter
							//fieldRef={ref[column].fieldRef}
							capitalizeOptions={capitalizeOptions}
							columnWidth={columnWidth}
							customFilterOptions={customFilterOptions}
							dateRangeRef={filterRef}
							displayValue={displayValue}
							filterType={filterType}
							hideTags={hideTags}
							itemList={itemList}
							label={label}
							managingOrgId={managingOrgId}
							mask={mask}
							multiParam={multiParam}
							preSelectedValues={preSelectedValues}
							searchComponentConfig={searchComponentConfig}
							searchId={searchValueSet ? searchValueSet : searchParameter}
							setHoverClassnameActive={setHoverClassnameActive}
							showResetButtonOnDatePicker={showResetButtonOnDatePicker}
							type={type}
							valueField={valueField}
							valueSetExtraParam={valueSetExtraParam}
							onSelected={(val, displayVal, optionId, record, searchParam) => {
								// Use the searchParam that is assigned from a child component for filter instead of the searchParam configured in
								// ImagingStudyWorklistMapping (Managing Organization column is an example)
								func(val, displayVal, column, searchParam);
							}}
							forceShowRef={{ current: false }}
							//forceShowRef={ref[column].forceShowRef}
							suggestScope={suggestScope}
						/>
					)}
				</Box>

				<div className={direction ? 'sort-button-wrapper-fixed' : 'sort-button-wrapper'}>
					{options?.sort && (
						<DataGridSort
							column={column}
							isSingleSort={isSingleSort}
							setSortDirection={setSortDirection}
							setSortOrder={setSortOrder}
							sortDirection={direction}
							sortOrder={sortOrder}
						/>
					)}
				</div>
			</Box>

			<div className={hasFilter ? 'table-head-overlay  table-head-overlay-filter' : 'table-head-overlay'}>
				<TableSortLabel active={false} direction={direction}>
					{label}
				</TableSortLabel>
			</div>
			<div className="table-head-sizer">{label} 3</div>
		</Box>
	);
};

DataGridHeader.defaultProps = {
	sortOrder: [],
};

DataGridHeader.propTypes = {
	column: PropTypes.string.isRequired,
	filterType: PropTypes.oneOf([
		'text-search',
		'single-select',
		'checkbox-single-select',
		'checkbox-multi-select',
		'multi-select',
		'suggest',
		'date-range',
		'date-time',
		'none',
	]),
	label: PropTypes.node.isRequired,
	displayValue: PropTypes.string,
	searchValueSet: PropTypes.any,
	searchParameter: PropTypes.any,
	capitalizeOptions: PropTypes.bool,
	suggestScope: PropTypes.any,
	options: PropTypes.object,
	preSelectedValues: PropTypes.any,
	itemList: PropTypes.arrayOf(PropTypes.string),
	setSortOrder: PropTypes.func,
	sortOrder: PropTypes.arrayOf(PropTypes.string),
	isSingleSort: PropTypes.bool,
	func: PropTypes.func.isRequired,
	customFilterOptions: PropTypes.array,
	mask: PropTypes.string,
	hideTags: PropTypes.bool,
};

export default DataGridHeader;
