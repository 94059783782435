import _getHash from '@cornerstonejs/tools/dist/esm/drawingSvg/_getHash';
import setNewAttributesIfValid from '@cornerstonejs/tools/dist/esm/drawingSvg/setNewAttributesIfValid';
import setAttributesIfNecessary from '@cornerstonejs/tools/dist/esm/drawingSvg/setAttributesIfNecessary';

const drawPath = (svgDrawingHelper, annotationUID, pathUID, points, options, canvas) => {
	// It may be a polyline with holes that will be an array with multiple
	// 'points' arrays
	const hasSubArrays = points.length && points[0].length && Array.isArray(points[0][0]);

	const pointsArrays = hasSubArrays ? points : [points];
	const { closePath = false } = options;

	const svgns = 'http://www.w3.org/2000/svg';
	const svgNodeHash = _getHash(annotationUID, 'path', pathUID);
	const existingNode = svgDrawingHelper.getSvgNode(svgNodeHash);
	let pointsAttribute = `
	M -1 -1
	h ${canvas.offsetWidth + 2}
	v ${canvas.offsetHeight + 2}
	h -${canvas.offsetWidth + 2}
	z
	`;

	for (let i = 0, numArrays = pointsArrays.length; i < numArrays; i++) {
		const points = pointsArrays[i];
		const numPoints = points.length;

		if (numPoints < 2) {
			continue;
		}

		for (let j = 0; j < numPoints; j++) {
			const point = points[j];
			const cmd = j ? 'L' : 'M';

			pointsAttribute += `${cmd} ${point[0].toFixed(1)}, ${point[1].toFixed(1)} `;
		}

		if (closePath) {
			pointsAttribute += 'Z ';
		}
	}

	if (!pointsAttribute) {
		return;
	}

	const attributes = {
		d: pointsAttribute,
		stroke: '#fff',
		fill: 'black',
		'fill-rule': 'evenodd',
	};

	if (existingNode) {
		// This is run to avoid re-rendering annotations that actually haven't changed
		setAttributesIfNecessary(attributes, existingNode);

		svgDrawingHelper.setNodeTouched(svgNodeHash);
	} else {
		const newNode = document.createElementNS(svgns, 'path');

		setNewAttributesIfValid(attributes, newNode);
		svgDrawingHelper.prependNode(newNode, svgNodeHash);
	}
};

export { drawPath };
