import React from 'react';

function QuarterCardSwitch({ testid, isActive, onClick }) {
	return (
		<div
			data-testid={testid}
			style={{
				position: 'relative',
				display: 'flex',
				justifyContent: isActive ? 'flex-start' : 'flex-end',
				alignItems: 'center',
				width: '86px',
				height: '27px',
				borderRadius: '16px',
				backgroundColor: isActive ? 'rgb(77,	121,	234)' : 'rgb(211, 211, 211)',
				cursor: 'pointer',
			}}
			onClick={onClick}
		>
			<div
				style={{
					width: '27px',
					height: '27px',
					boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
					borderRadius: '14px',
					backgroundColor: isActive ? 'rgb(256,	256,	256)' : 'rgb(148, 148, 148)',
				}}
			/>
			{isActive && (
				<span
					style={{
						display: 'block',
						paddingLeft: '6px',
						fontSize: '12px',
						fontWeight: '600',
						lineHeight: '15px',
						color: 'rgb(256, 256, 256)',
						textTransform: 'uppercase',
						cursor: 'pointer',
					}}
				>
					Active
				</span>
			)}
		</div>
	);
}

export default QuarterCardSwitch;
