// @flow
//core
import React, { useState, useEffect, useCallback, type Node } from 'react';
//components
//lib
import {
	CircularProgress,
	MenuItem,
	FormControl,
	InputLabel,
	Box,
	Chip,
	Select,
	Typography,
	Stack,
	InputAdornment,
	Autocomplete,
	TextField,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//utils
import { searchScopes } from '@rs-core/context/consts/searchScopes';
import useFhirDataLoader from '@rs-core/hooks/useFhirDataLoader';
import {
	renderPriorityIcon,
	defaultPriority,
	formatExtensionsPriority,
	styles,
	renderCustomPriority,
	PriorityCodes,
	getKeyByValue,
} from '@rs-ui/components/PrioritySelector/helperMethods';

interface IPrioritySelectorProps {
	/**
	 * @default true
	 */
	isCustomPriorityAvailable?: boolean;
	/**
	 * @default false
	 */
	fullWidth?: boolean;
	InputLabelProps?: Object;
	InputProps?: Object;
	/**
	 * @default true
	 */
	isEditing?: boolean;
	/**
	 * @default false
	 */
	isAutocompleteEnabled?: boolean;
	label: string;
	orgId: string | number;
	placeholder?: string;
	selectedPriority?: Object;
	selectedCustomPriority?: Object;
	sx?: Object;
	t?: any;
	/**
	 * @default 200
	 */
	width?: number;
	setSelectedPriority: (org: Object) => void;
	setSelectedCustomPriority: (org: Object) => void;
}

export const PrioritySelector = ({
	isCustomPriorityAvailable = true,
	fullWidth = false,
	InputLabelProps,
	InputProps,
	isEditing = true,
	isAutocompleteEnabled = false,
	label,
	orgId,
	placeholder,
	selectedPriority,
	sx,
	t,
	width = 200,
	setSelectedPriority,
	selectedCustomPriority,
	setSelectedCustomPriority,
}: IPrioritySelectorProps): Node => {
	const [priorities, setPriorities] = useState([]);
	const [customDefaultPriority, setCustomDefaultPriority] = useState();
	const [selectedValue, setSelectedValue] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const priorityLoader = useFhirDataLoader({ scope: searchScopes.priority });

	const loadPrioritiesByOrganizationId = useCallback(
		async orgId =>
			priorityLoader
				.load(
					{
						organization: orgId,
					},
					true
				)
				.then(res => res),
		[]
	);

	// this effect will be triggered when the isEditing is changed and will help in set the values when we have the priorities with formatting applied and rest cases based on the if condition
	useEffect(() => {
		let priority;
		if (selectedCustomPriority && selectedCustomPriority?.display && selectedCustomPriority?.id !== -1) {
			priority = renderCustomPriority({ priority: selectedPriority, selectedCustomPriority });
		} else if (customDefaultPriority) {
			priority = customDefaultPriority;
		} else {
			priority = renderCustomPriority({ priority: selectedPriority, selectedCustomPriority: null });
		}
		setSelectedValue(priority);
	}, [isEditing, priorities, customDefaultPriority, isAutocompleteEnabled]);

	// this effect will be triggered when the isCustomPriorityAvailable is changed (when new order is created or appointment is created or edited), this will set the default values in the setSelectedPriority and setSelectedValue so as to have the same format expected in the select
	useEffect(() => {
		if (
			!isAutocompleteEnabled &&
			customDefaultPriority?.display &&
			isEditing &&
			selectedCustomPriority?.id === -1
		) {
			setSelectedPriority?.(customDefaultPriority?.display);
			setSelectedValue?.(customDefaultPriority);
			setSelectedCustomPriority?.(customDefaultPriority);
		}
	}, [isCustomPriorityAvailable, customDefaultPriority]);

	// this will be triggered when the orgId changes and will fetch the new custom priorities
	useEffect(() => {
		if (orgId) {
			(async () => {
				setIsLoading(true);
				const res = await loadPrioritiesByOrganizationId(orgId);
				const prioritiesWithoutParentId = res?.entry
					?.map(item => item.resource)
					.filter(item => !item?.parentId);
				const formattedDefaultPriorities = prioritiesWithoutParentId?.map(item =>
					formatExtensionsPriority(item, true)
				);
				setCustomDefaultPriority(
					formattedDefaultPriorities?.find(
						item => item?.priorityCode === getKeyByValue(PriorityCodes, selectedPriority?.toUpperCase())
					) || formattedDefaultPriorities?.find(item => item?.display === 'ROUTINE')
				);
				setPriorities(res?.entry?.map(item => formatExtensionsPriority(item.resource, true)));
				setIsLoading(false);
			})();
		}
	}, [orgId]);

	const onChange = (event, newValue) => {
		const {
			target: { value },
		} = event;
		setSelectedValue(isAutocompleteEnabled ? newValue : value);
		setSelectedPriority?.(
			isAutocompleteEnabled ? PriorityCodes[newValue?.priorityCode] : PriorityCodes[value?.priorityCode]
		);
		setSelectedCustomPriority(isAutocompleteEnabled ? newValue : value);
	};

	const customRenderMenuItem = useCallback(
		option => (
			<Chip
				color="primary"
				label={
					<Stack direction="row" sx={styles.stackCustomStyle}>
						{renderPriorityIcon(option)}
						{option?.display}
					</Stack>
				}
				style={{
					backgroundColor: option?.color,
				}}
			/>
		),
		[priorities]
	);

	if (!isEditing) {
		return isLoading ? (
			<CircularProgress size={20} sx={{ color: '#fff' }} />
		) : (
			<Box sx={styles.flexWithCenter}>
				<Stack direction="row" sx={styles.stackCustomStyle}>
					<Box
						sx={styles.staticIconStyle({
							color: selectedValue?.color,
						})}
					>
						{renderPriorityIcon(selectedValue)}
					</Box>
					<Typography sx={{ fontSize: '16px' }}>{selectedValue?.display?.toUpperCase()}</Typography>
				</Stack>
			</Box>
		);
	}

	if (!isAutocompleteEnabled && (isEditing || priorities?.length || selectedCustomPriority)) {
		return (
			<FormControl fullWidth>
				<InputLabel data-testid={`${label}_test_id_label`}>{label}</InputLabel>
				<Select
					IconComponent={props =>
						isLoading ? (
							<CircularProgress {...props} size={20} />
						) : (
							<KeyboardArrowDownIcon {...props} size={20} />
						)
					}
					data-testid={`${label}_test_id_option`}
					label={label}
					renderValue={() =>
						isLoading ? (
							<Box sx={styles.chipStyle}>{customRenderMenuItem(defaultPriority)}</Box>
						) : (
							<Box sx={styles.chipStyle}>{customRenderMenuItem(selectedValue)}</Box>
						)
					}
					value={selectedValue || defaultPriority}
					onChange={onChange}
				>
					{isLoading ? (
						<MenuItem key={defaultPriority?.display} value={defaultPriority}>
							{customRenderMenuItem(defaultPriority)}
						</MenuItem>
					) : (
						priorities?.map(obj => (
							<MenuItem key={obj?.display} value={obj}>
								{customRenderMenuItem(obj)}
							</MenuItem>
						))
					)}
				</Select>
			</FormControl>
		);
	}
	if (isAutocompleteEnabled) {
		return (
			<Autocomplete
				autoHighlight
				disablePortal
				data-test-id="autoCompleteForWorklistPriority"
				defaultValue={selectedCustomPriority}
				getOptionLabel={option => option?.display}
				loading={isLoading}
				options={priorities}
				renderInput={params => (
					<TextField
						{...params}
						InputLabelProps={{
							...params.InputLabelProps,
							...InputLabelProps,
						}}
						InputProps={{
							...params.InputProps,
							startAdornment: selectedValue ? (
								<InputAdornment position="start" sx={styles.inputAdornmentStyles}>
									<Chip
										color="primary"
										label={renderPriorityIcon(selectedValue)}
										style={styles.inputAdornmentChipStyles(selectedValue?.color)}
									/>
								</InputAdornment>
							) : null,
							...InputProps,
							placeholder: t(placeholder),
						}}
						label={label}
						variant="outlined"
					/>
				)}
				renderOption={(props, option) => (
					<Box sx={styles.chipStyle} {...props}>
						{customRenderMenuItem(option)}
					</Box>
				)}
				sx={{
					...sx,
					...(fullWidth ? '' : { width }),
				}}
				value={selectedValue}
				onChange={onChange}
			/>
		);
	}
	return null;
};
