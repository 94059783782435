import React from 'react';

import { DeleteIcon, MergedIcon, SeparateIcon } from '../../../assets/icons';

import styles from './SelectedPopup.module.scss';

const SelectedPopup = ({ selectedItems, selectedPopupRef, onMerge, onDelete }) => (
	<div className={styles.selectedPopupWrapper}>
		<div ref={selectedPopupRef} className={styles.selectedPopup}>
			<div className={styles.selectedText}>
				<span>{selectedItems.length} SELECTED</span>
			</div>
			<SeparateIcon />
			<div className={styles.selectedIcons}>
				<button className={styles.icon} data-testid="merge-button" onClick={onMerge}>
					<MergedIcon />
				</button>
				<button className={styles.icon} data-testid="delete-button" onClick={onDelete}>
					<DeleteIcon />
				</button>
			</div>
		</div>
	</div>
);

export default SelectedPopup;
