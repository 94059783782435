import React from 'react';
import { Typography } from '@mui/material';
import { useLiveChatContext } from './LiveChatContext';

function UserAvatar({ size, sx, status, isOnline, userName = '', userImg, onClick }) {
	const { STATUSES } = useLiveChatContext();

	const colorSelect = currentStatus => STATUSES.find(item => item.value === currentStatus).color;

	let fontSize;

	if (size > 55) {
		fontSize = 22;
	} else if (size > 45) {
		fontSize = 20;
	} else if (size > 35) {
		fontSize = 18;
	} else if (size > 30) {
		fontSize = 16;
	} else {
		fontSize = 14;
	}

	return (
		<div
			style={{
				position: 'relative',
				width: `${size}px`,
				height: `${size}px`,
				flexShrink: 0,
				cursor: onClick ? 'pointer' : 'default',
				...sx,
			}}
			onClick={onClick}
		>
			{userImg ? (
				<img src={userImg} />
			) : (
				<Typography
					sx={{
						width: `${size}px`,
						height: `${size}px`,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'rgba(77, 121, 234, 0.2)',
						borderRadius: '50%',
						fontFamily: 'Inter',
						fontSize: `${fontSize}px`,
						fontWeight: '500',
						color: '#4D79EA',
						textTransform: 'uppercase',
					}}
				>
					{userName &&
						userName
							.split(' ')
							.reduce((result, value) => result + value[0], '')
							.slice(0, 2)}
				</Typography>
			)}
			{(typeof isOnline === 'boolean' || status) && (
				<div
					style={{
						position: 'absolute',
						right: '0',
						bottom: '0',
						width: size < 40 ? '8px' : '10px',
						height: size < 40 ? '8px' : '10px',
						border: size < 40 ? '1px solid #FFFFFF' : '2px solid #FFFFFF',
						borderRadius: '50%',
						backgroundColor: status ? colorSelect(status) : isOnline ? '#3BE775' : '#C4C4C4',
					}}
				/>
			)}
		</div>
	);
}

export default UserAvatar;
