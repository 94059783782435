import React from 'react';

const AddRowColumnIcon = () => (
	<svg fill="none" height={13} width={13} xmlns="http://www.w3.org/2000/svg">
		<mask
			height={13}
			id="a"
			maskUnits="userSpaceOnUse"
			style={{
				maskType: 'alpha',
			}}
			width={13}
			x={0}
			y={0}
		>
			<path d="M0 0h12.461v12.461H0z" fill="#D9D9D9" />
		</mask>
		<g mask="url(#a)">
			<path d="M5.711 6.752H2.596V5.713H5.71V2.598H6.75v3.115h3.115v1.038H6.75v3.116H5.71V6.75Z" fill="#fff" />
		</g>
	</svg>
);

export default AddRowColumnIcon;
