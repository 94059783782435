import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from '@mui/material/Icon';
import CheckIcon from '@mui/icons-material/Check';

const ListCheckIcon = ({ showCheckIcon }) => (
	<ListItemIcon>
		<Icon component={showCheckIcon ? CheckIcon : undefined} sx={{ color: '#42A5F5' }} />
	</ListItemIcon>
);

export default ListCheckIcon;
