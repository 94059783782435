import React, { forwardRef, useImperativeHandle, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { OutlineWrapper, RoleBadge } from '../../../../views/CrmView/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import _ from 'lodash';
import NewTeamField from './NewTeamField';

const UserAssignedTeamsCard = forwardRef((props, ref) => {
	const [editing, toggleEditing] = useState(false);
	const [initialData] = useState(props.user);
	const [user, setUser] = useState(props.user);
	const [payload, setPayload] = useState({});

	useImperativeHandle(ref, () => ({
		cancelEditing: () => {
			setUser(initialData);
			toggleEditing(false);
		},
		save: () => {
			toggleEditing(false);
			return payload;
		},
	}));

	const onChange = values => {
		setUser(old => ({ ...old, Teams: values }));
		setPayload({ ...payload, Teams: values });
	};

	return (
		<OutlineWrapper style={{ flex: 0 }}>
			<div className="header">
				<div className="left">
					<div className="colored-icon warning-icon">
						<GroupsOutlinedIcon sx={{ fontSize: 20 }} />
					</div>
					<h2>Assigned Teams</h2>
				</div>
				{/*!editing && (
					<IconButton onClick={() => toggleEditing(!editing)}>
						<EditOutlinedIcon
							sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
						/>
					</IconButton>
				)*/}
			</div>
			<Box sx={{ padding: '15px 19px' }}>
				<Box className="row body" style={{ justifyContent: 'normal', flexWrap: 'wrap' }}>
					{
						/*editing ? (
						<NewTeamField
							dismiss={() => toggleEditing()}
							user={user}
							onChange={onChange}
							teams={props.teams}
						/>
					) :*/ _.map(user?.Team, (team, i) => (
							<RoleBadge key={i} mr={i !== user.Team.length - 1}>
								{team.TeamName}
							</RoleBadge>
						))
					}
				</Box>
			</Box>
		</OutlineWrapper>
	);
});

UserAssignedTeamsCard.propTypes = {
	user: PropTypes.object.isRequired,
};

export default UserAssignedTeamsCard;
