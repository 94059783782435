import React, { useState, useMemo } from 'react';

import { Box, Typography, IconButton } from '@mui/material';
import { Add, AccountTreeOutlined, DeviceHubOutlined, ExpandMoreOutlined } from '@mui/icons-material';

import StationItem from './StationItem';

import { getDeviceType } from './utils';

import createStyles from './styles';

const DeviceItem = ({ device, stations, onDeviceOpen, onStationOpen, currentStation, setCurrentStation, location }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isHover, setIsHover] = useState(false);

	const classes = createStyles(isOpen, isHover);

	const deviceId = device.id;
	const deviceName = device.distinctIdentifier;
	const deviceStatus = device.status;

	const isHaveStations = useMemo(() => stations.hasOwnProperty(device.id), [stations, device.id]);

	const getStations = () => {
		if (isHaveStations) {
			return stations[deviceId].map(({ resource }) => (
				<StationItem
					key={resource.id}
					associatedDevice={device}
					currentStation={currentStation}
					location={location}
					setCurrentStation={setCurrentStation}
					stationData={resource}
					onStationOpen={onStationOpen}
				/>
			));
		}
	};

	const onExpand = () => {
		setIsOpen(!isOpen);
		setCurrentStation({});
	};

	const handleEditDevice = e => {
		if (location === 'accountFullScreen') {
			onDeviceOpen(e, 'edit', device);
		} else {
			onDeviceOpen({ device, open: true, mode: 'edit' });
		}
	};

	const handleAddStation = e => {
		if (location === 'accountFullScreen') {
			onStationOpen(e, 'add', {}, device);
		} else {
			onStationOpen({ open: true, associatedDevice: device, mode: 'add' });
		}
	};

	const isOmegaAILinkHovered = getDeviceType(device) === 'OmegaAI Link' && isHover;

	return (
		<Box onMouseLeave={() => setIsHover(false)}>
			<Box
				style={isOmegaAILinkHovered ? { marginBottom: '0px' } : { marginBottom: '10px' }}
				sx={classes.deviceItemBox}
				onMouseOver={ctx => {
					if (ctx.target?.id === 'expandMore') {
						setIsHover(false);
					} else if (ctx.relatedTarget?.id === 'expandMore') {
						setIsHover(false);
					} else {
						setIsHover(true);
					}
				}}
			>
				<Box sx={classes.deviceItemLeft}>
					<Box sx={classes.deviceHubBox}>
						<DeviceHubOutlined sx={classes.deviceHub} />
					</Box>
					<Box sx={classes.deviceItemTitleBox}>
						<Typography sx={classes.deviceItemTitle}>
							{getDeviceType(device)} - {deviceName}
						</Typography>
						<Box sx={classes.deviceItemStatusBox}>
							<Box sx={classes.deviceItemStatusIndicator} />
							<Typography sx={classes.deviceItemStatusLabel}>
								{deviceStatus.charAt(0).toUpperCase() + deviceStatus.slice(1)}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Box sx={classes.deviceItemRight}>
					{isHover ? (
						<Box data-testid="edit-arrow" sx={classes.deviceItemArrow} onClick={handleEditDevice}>
							<svg
								fill="none"
								height="12"
								viewBox="0 0 12 12"
								width="12"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M0.666585 6.66536L8.77992 6.66536L5.05325 10.392L5.99992 11.332L11.3333 5.9987L5.99992 0.665364L5.05992 1.60536L8.77992 5.33203L0.666585 5.33203L0.666585 6.66536Z"
									fill="#4D79EA"
								/>
							</svg>
						</Box>
					) : (
						''
					)}
					{isHaveStations ? (
						<Box
							data-testid="expand-more"
							id="expandMore"
							sx={classes.deviceItemTree}
							onClick={onExpand}
							onMouseOver={() => setIsHover(false)}
						>
							<AccountTreeOutlined id="expandMore" sx={classes.accountTreeIcon} />
							<ExpandMoreOutlined id="expandMore" sx={classes.expandMore} />
						</Box>
					) : (
						''
					)}
				</Box>
			</Box>
			{isHaveStations && isOpen ? (
				<Box style={isHover ? { marginBottom: '0px' } : { marginBottom: '10px' }} sx={classes.stationListBox}>
					<Box sx={classes.stationListSeparator} />
					<Box sx={classes.stationList}>{getStations()}</Box>
				</Box>
			) : (
				''
			)}
			{isOmegaAILinkHovered ? (
				<Box
					data-testid="add-station"
					sx={classes.addStationButtonBox}
					onClick={handleAddStation}
					onMouseOver={() => setIsHover(true)}
				>
					<Box sx={classes.addStationButtonIconBox} />
					<IconButton sx={classes.addStationButtonIconButton}>
						<Add sx={classes.addStationButtonIcon} />
					</IconButton>
					<Box sx={classes.addStationButtonLabelBox}>
						<Typography sx={classes.addStationButtonLabel}>Add station</Typography>
					</Box>
				</Box>
			) : (
				''
			)}
		</Box>
	);
};

export default DeviceItem;
