import axios from 'axios';

const getReferringPhysicians = async ({ __config, orgId, searchTerm, pageNum = 1, count = 50 }) => {
	const response = await axios.get(
		`${__config.data_sources.blume}Organization/referringPhysicians?InternalOrganizationId=${orgId}&PageNumber=${pageNum}&Count=${count}&SearchString=${searchTerm}`,
		{
			headers: {
				'X-API-KEY': window.btoa(__config.patient_portal.authentication_key),
			},
		}
	);

	if (!response?.status?.toString()?.startsWith(2)) {
		return [];
	}

	return response.data;
};

export default getReferringPhysicians;
