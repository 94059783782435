const practitionerRoleSchema = {
	id: '__string__',
	resourceType: '__string__',
	active: '__boolean__',
	extension: ['__Extension__'],
	meta: ['__Meta__'],
	organization: '__Reference(Organization)__',
	practitioner: '__Reference(Practitioner)__',
};

export default practitionerRoleSchema;
