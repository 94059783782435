import conditionIcon from '../../../assets/icons/hangingProtocol/rules/Condition.svg';
import scalingIcon from '../../../assets/icons/hangingProtocol/rules/Scaling.svg';
import orientationIcon from '../../../assets/icons/hangingProtocol/rules/Orientation.svg';
import windowPresetsIcon from '../../../assets/icons/hangingProtocol/rules/WindowPresets.svg';
import toggleIcon from '../../../assets/icons/hangingProtocol/rules/Toggle.svg';

export const WINDOW_PRESETS = 'windowPresets';
export const ORIENTATION = 'orientation';
export const TOGGLE = 'toggle';
export const CONDITION = 'condition';
export const SCALING = 'scaling';

export const COMPARISION_TYPES = {
	equal: 'equal',
	notEqual: 'notEqual',
	includes: 'includes',
	notInclude: 'notInclude',
};

export const RULES_SETTINGS = {
	[WINDOW_PRESETS]: {
		limit: 1,
		defaultValue: {
			wl: '',
			ww: '',
			preset: '',
		},
		icon: windowPresetsIcon,
		label: 'Window Presets',
		validate(rules) {
			const limitExceeded = rules.length >= this.limit;
			return !limitExceeded;
		},
	},
	[SCALING]: {
		limit: 1,
		defaultValue: {
			zoom: '',
			fit: '',
		},
		icon: scalingIcon,
		label: 'Scaling',
		validate(rules) {
			const limitExceeded = rules.length >= this.limit;
			return !limitExceeded;
		},
	},
	[TOGGLE]: {
		limit: 1,
		defaultValue: {
			options: {
				linking: true,
				scroll: false,
				annotations: true,
				overlay: true,
				referenceLines: true,
				scoutImageOverlay: true,
				cine: false,
			},
		},
		icon: toggleIcon,
		label: 'Toggles',
		validate(rules) {
			const limitExceeded = rules.length >= this.limit;
			return !limitExceeded;
		},
	},
	[ORIENTATION]: {
		limit: 1,
		defaultValue: {
			align: '',
			flipH: false,
			flipV: false,
			rotateR: false,
			rotateL: false,
		},
		icon: orientationIcon,
		label: 'Orientation',
		validate(rules) {
			const limitExceeded = rules.length >= this.limit;
			return !limitExceeded;
		},
	},
	[CONDITION]: {
		limit: 5,
		defaultValue: {
			tag: '',
			value: '',
			comparisionType: '',
		},
		icon: conditionIcon,
		label: 'Conditions',
		validate(rules) {
			const limitExceeded = rules.length >= this.limit;
			const isEmpty = rules.some((rule) => {
				return rule.values.tag === '' || rule.values.value === '' || rule.values.comparisionType === '';
			});
			return !isEmpty && !limitExceeded;
		},
	},
};

export default {
	WINDOW_PRESETS,
	ORIENTATION,
	TOGGLE,
	CONDITION,
	SCALING,
	RULES_SETTINGS,
};
