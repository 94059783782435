/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
import feeScheduleSchema from './schemata/feeScheduleSchema';

export default class FeeScheduleResource extends FhirDomainResource {
	active;
	endDate;
	externalId;
	feeScheduleName;
	id;
	insurancepayerids;
	isActive;
	isDefault;
	isSelfPay;
	meta;
	organization;
	startDate;

	constructor(resourceString) {
		super(resourceString, feeScheduleSchema);
		this.resourceType = 'FeeSchedule';
		this.populateFields();
	}
}
