import axios from 'axios';
import { fhirExtensionUrls } from '@rs-core';

const findFinalDocuments = async ({ __config, patientsInfo = [], refData = null, studyuid = null }) => {
	const reportPromises = patientsInfo.map(async patient => {
		let urlParams = `DiagnosticReport?_dc=${new Date().getTime()}&_count=200&page=1&subject=${patient}`;
		if (studyuid) {
			urlParams += `&studyuid=${studyuid}`;
		}
		const response = await axios.get(`${__config.data_sources.fhir}/${urlParams}`);
		return response?.data?.entry?.map(item => item.resource) ?? [];
	});

	const requestResult = (await Promise.all(reportPromises)).flat().filter(item => {
		const extensions = item.extension || [];
		const isFinalReport = item.status === 'amended' || item.status === 'final';
		const matchingExtension = extensions.find(
			extension => extension.url === fhirExtensionUrls.diagnosisReport.studyInstanceUID
		);
		if (matchingExtension) {
			item.dicomUid = matchingExtension.valueString;
		}
		return !!matchingExtension && isFinalReport;
	});

	if (!!refData && Array.isArray(refData)) {
		refData.forEach(study => {
			const finalReportsCurStudy = requestResult.filter(item => {
				const extensions = item.extension || [];
				return !!extensions.find(
					extension =>
						extension.url === fhirExtensionUrls.diagnosisReport.studyInstanceUID &&
						extension.valueString === study.dicomUid
				);
			});

			study.rawData.numFinalReports = finalReportsCurStudy.length;
		});
	}

	return requestResult;
};

export default findFinalDocuments;

export const findFinalDocumentsV2 = async ({ __config, patientsInfo = [], studyuid = null }) => {
	const reportPromises = patientsInfo.map(async patient => {
		let urlParams = `DiagnosticReport?_dc=${new Date().getTime()}&_count=200&page=1&subject=${patient}`;
		if (studyuid) {
			urlParams += `&studyuid=${studyuid}`;
		}
		const response = await axios.get(`${__config.data_sources.fhir}/${urlParams}`);
		return response?.data?.entry?.map(item => item.resource) ?? [];
	});

	return (await Promise.all(reportPromises)).flat().filter(item => {
		const extensions = item.extension || [];
		const isFinalReport = item.status === 'amended' || item.status === 'final';
		const matchingExtension = extensions.find(
			extension => extension.url === fhirExtensionUrls.diagnosisReport.studyInstanceUID
		);
		if (matchingExtension) {
			item.dicomUid = matchingExtension.valueString;
		}
		return !!matchingExtension && isFinalReport;
	});
};
