import React, { useState, useEffect } from 'react';
import axios from 'axios';

import MobileReportsList from './MobileReportsList';
import MobileBottomDrawer from '../MobileDrawer/MobileBottomDrawer';
import MobileReportDetail from './MobileReportDetail';
import MobileProcessingReportsSection from '../MobileStudyDetail/MobileStudyDetailSections/MobileProcessingReportsSection';
import MobileTitle from '../MobileTitle';
import Box from '@mui/material/Box';

import { useToastMessageContext } from '../../../context/ToastMessageContext';
import { useTranslation } from 'react-i18next';

const MobileReports = ({ reports, fullScreenMode }) => {
	const { t } = useTranslation('profile');
	const { setToastMsg } = useToastMessageContext();
	const [selectedReport, setSelectedReport] = useState(false);

	const onDownloadClick = reportData => {
		axios
			.get(reportData.reportPath, {
				responseType: 'blob',
			})
			.then(response => {
				if (response?.status === 200) {
					const blob = new Blob([response.data], {
						type: 'application/pdf',
					});
					const winURL = window.URL || window.webkitURL;
					const blobUrl = winURL.createObjectURL(blob);
					const anchorElem = document.createElement('a');
					anchorElem.href = blobUrl;
					anchorElem.download = `${reportData.reportName}.pdf`;
					anchorElem.click();
					anchorElem.remove();
				}
			})
			.catch(() => {
				setToastMsg(true, t('Failed to Download'));
			});
	};

	useEffect(() => {
		if (fullScreenMode) {
			setSelectedReport(reports[0]);
		}
	}, [reports]);

	return reports.length === 0 ? (
		<MobileProcessingReportsSection />
	) : (
		<>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: '5px',
				}}
			>
				<MobileTitle title="Reports" />
				<Box
					sx={{
						marginBottom: '30px',
						width: '19px',
						height: '19px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						fontWeight: '500',
						fontSize: '16px',
						lineHeight: '19px',
						color: 'rgba(18, 18, 18, 0.8)',
						radius: '5px',
						backgroundColor: 'rgba(243, 243, 243, 1)',
					}}
				>
					{reports.length}
				</Box>
			</Box>

			<MobileReportsList
				reports={reports}
				showCount={3}
				onClick={setSelectedReport}
				onDownloadClick={onDownloadClick}
			/>

			<MobileBottomDrawer
				fullHeight
				background="#ffffff"
				open={Boolean(selectedReport)}
				onClose={() => setSelectedReport(undefined)}
			>
				{selectedReport && (
					<MobileReportDetail report={selectedReport} onGoBack={() => setSelectedReport(undefined)} />
				)}
			</MobileBottomDrawer>
		</>
	);
};

export default MobileReports;
