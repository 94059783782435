import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import AddNewButton from '../../../../AddNewButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SearchableSingleSelectColumnPicker from '../../../../SearchableSingleSelectColumnPicker';
import { v4 as uuid } from 'uuid';

const Sort = ({
	isLarge,
	preConfiguredColumnList,
	width,
	height,
	componentSortList,
	setComponentSortList,
	onChange,
	label = 'Column Name',
	disableAddButton,
	translateFn,
	isWorklist = false,
}) => {
	const DESC = 'desc';
	const ASC = 'asc';
	const tempColumnList = [];

	/**
	 * Populate a new list and update key of the object - name as key for form column data
	 */
	preConfiguredColumnList.map(item => {
		if (item.name !== '' && !!item.name && item.options?.sort) {
			tempColumnList.push({ ...item });
		}
	});
	const [columnList, setColumnList] = useState(tempColumnList);

	/**
	 * Create a new column
	 */
	const handleAddNewColumn = () => {
		if (componentSortList.length < columnList.length) {
			const list = componentSortList;
			const unique_id = uuid();
			const itemid = unique_id.slice(0, 8);
			list.unshift({ name: '', id: itemid, sort: DESC, label: '' });
			setComponentSortList([...list]);
		}
	};

	/**
	 * @param {Object} item - Object representing column name, columnId, sort order type and column size
	 */
	const handleDeleteColumn = item => {
		const list = componentSortList.filter(sortItem => sortItem.id !== item.id);
		if (onChange) {
			onChange(list);
		}
		setComponentSortList(list);
	};

	/**
	 * @param {Number} columnId - ID of the column
	 * @param {Object} columnObj - Object representing column name, columnId, sort order type and column size
	 */
	const handleColumnValueSelect = (columnObj, columnId, reason) => {
		const objIndex = componentSortList.findIndex(item => item.id === columnId.id);
		const currentItem = componentSortList[objIndex];
		let updatedItem;

		// When selecting new;
		if (reason == 'selectOption') {
			updatedItem = columnObj;
			updatedItem.sort = currentItem.sort;
			updatedItem.sortOrder = currentItem.sortOrder;
		} else {
			// When remove the selected
			updatedItem = {
				name: '',
				id: uuid().slice(0, 8),
				sort: currentItem.sort,
				label: '',
				sortOrder: currentItem.sortOrder,
			};
		}
		componentSortList[objIndex] = updatedItem;

		if (onChange) {
			onChange(componentSortList);
		}
		setComponentSortList([...componentSortList]);
	};

	/**
	 * @param {Number} index - Index of the column
	 */
	const onClickArrowIcon = index => {
		const selectedItem = componentSortList[index];
		selectedItem.sort = selectedItem.sort === DESC ? ASC : DESC;
		componentSortList[index] = selectedItem;
		if (onChange) {
			onChange(componentSortList);
		}
		setComponentSortList(componentSortList);
	};
	return (
		<Stack container alignItems="center">
			<AddNewButton
				disabled={disableAddButton}
				id="addNewButton"
				tooltipTitle={translateFn('workListDrawer.add')}
				width={width}
				onClick={handleAddNewColumn}
			/>
			<List
				sx={{
					maxHeight: height || `calc(100vh - ${isLarge ? 260 : 500}px)`,
					height: height || `calc(100vh - ${isLarge ? 260 : 500}px)`,
					width: '100%',
					overflow: 'auto',
					paddingLeft: '0px',
				}}
			>
				{/** Render selected columns and automatically update columns if its corresponding
				 * column in ColumnBuilder is removed.
				 */}
				{componentSortList.map((item, index) => (
					<ListItem
						key={`list_${index}`}
						id={item.id}
						sx={{
							justifyContent: 'center',
							color: 'icon.primary',
							paddingLeft: '0px',
							paddingRight: '0px',
							'&:hover': {
								'.MuiListItemAvatar-root': {
									visibility: 'visible',
								},
								'.MuiButtonBase-root': {
									visibility: 'visible',
								},
							},
						}}
					>
						<Typography
							sx={{
								width: '20px',
							}}
						>
							{index + 1}
						</Typography>
						<SearchableSingleSelectColumnPicker
							columnId={item}
							data={columnList}
							isWorklist={isWorklist}
							label={label}
							selectedItemArray={componentSortList}
							width={width - 44}
							onChange={handleColumnValueSelect}
						/>
						<IconButton
							data-cy={`ArrowIcon_${index}`}
							sx={{
								opacity: 0.6,
								marginLeft: '4px',
								marginRight: '20px',
							}}
							onClick={() => onClickArrowIcon(index)}
						>
							{item.sort === ASC ? (
								<ArrowUpwardIcon data-cy="ArrowUpwardIcon" />
							) : (
								<ArrowDownwardIcon data-cy="ArrowDownwardIcon" />
							)}
						</IconButton>
						<IconButton
							sx={{
								opacity: 0.6,
								visibility: 'hidden',
								position: 'absolute',
								right: 0,
							}}
							onClick={() => {
								handleDeleteColumn(item);
							}}
						>
							<DeleteIcon data-cy={`delete_${index}`} />
						</IconButton>
					</ListItem>
				))}
			</List>
		</Stack>
	);
};
Sort.propTypes = {
	/**
	 * Width of the Add new button and column builder
	 */
	width: PropTypes.number,

	/**
	 * Height of the column builder grid
	 */
	height: PropTypes.number,

	/**
	 * Empty list state (useState hook) to hold selected columns (to which Sort is applied)
	 * Each object representing column name, sort order type, size, column id
	 */
	componentSortList: PropTypes.array,

	/**
	 * set function of useState hook to update selected columns (to which Sort is applied)
	 */
	setComponentSortList: PropTypes.func,

	/**
	 * User saved columns specific to worklist.
	 */
	preConfiguredColumnList: PropTypes.array,

	/** Function to update the worklist form data onChange */
	onChange: PropTypes.func,

	/**
	 * Translation function
	 */
	translateFn: PropTypes.func,
};
Sort.defaultProps = {
	width: 200,
	componentSortList: [],
	preConfiguredColumnList: [],
};
export default Sort;
