// React Libraries
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

// Material UI Libraries
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
			width: 250,
			overflowX: 'scroll',
		},
	},
};

function Filter({ isYAxis, isXAxis, isFilterList, member, updateFilters }) {
	const [checked, setChecked] = useState('');

	const filterOptions = [
		{
			display: 'equals',
			name: 'equals',
			type: ['string', 'number', 'time'],
			applyToMeasures: true,
			valueNumber: -1, // -1 means multiple
		},
		{
			display: 'does not equal',
			name: 'notEquals',
			type: ['string', 'number', 'time'],
			applyToMeasures: true,
			valueNumber: -1,
		},
		{
			display: 'contain',
			name: 'contains',
			type: ['string'],
			applyToMeasures: false,
			valueNumber: -1,
		},
		{
			display: 'not contain',
			name: 'notContains',
			type: ['string'],
			applyToMeasures: false,
			valueNumber: -1,
		},
		{
			display: 'starts with',
			name: 'startsWith',
			type: ['string'],
			applyToMeasures: false,
			valueNumber: -1,
		},
		{
			display: 'ends with',
			name: 'endsWith',
			type: ['string'],
			applyToMeasures: false,
			valueNumber: -1,
		},
		{
			display: 'greater than',
			name: 'gt',
			type: ['number'],
			applyToMeasures: true,
			valueNumber: 1,
		},
		{
			display: 'greater than or equal to',
			name: 'gte',
			type: ['number'],
			applyToMeasures: true,
			valueNumber: 1,
		},
		{
			display: 'less than',
			name: 'lt',
			type: ['number'],
			applyToMeasures: true,
			valueNumber: 1,
		},
		{
			display: 'less than or equal to',
			name: 'lte',
			type: ['number'],
			applyToMeasures: true,
			valueNumber: 1,
		},
		{
			display: 'is set',
			name: 'set',
			type: ['string', 'number', 'time'],
			applyToMeasures: true,
			valueNumber: 0,
		},
		{
			display: 'is not set',
			name: 'notSet',
			type: ['string', 'number', 'time'],
			applyToMeasures: true,
			valueNumber: 0,
		},
		{
			display: 'in date range of',
			name: 'inDateRange',
			type: ['time'],
			applyToMeasures: false,
			valueNumber: 2,
		},
		{
			display: 'not in date range of',
			name: 'notInDateRange',
			type: ['time'],
			applyToMeasures: false,
			valueNumber: 2,
		},
		{
			display: 'before date',
			name: 'beforeDate',
			type: ['time'],
			applyToMeasures: false,
			valueNumber: 1,
		},
		{
			display: 'after date',
			name: 'afterDate',
			type: ['time'],
			applyToMeasures: false,
			valueNumber: 1,
		},
	];

	useEffect(() => {
		let inputNumber = -1;
		let disableInput = false;
		const operatorName = member.operator;
		let checkOption = '';
		if (operatorName) {
			for (const f of filterOptions) {
				if (operatorName === f.name) {
					inputNumber = f.valueNumber;
					checkOption = f.display;
					break;
				}
			}

			if (operatorName === 'set' || operatorName === 'notSet' || member?.value?.length === inputNumber) {
				disableInput = true;
			}

			updateFilters(operatorName, false, disableInput, inputNumber, true);
		}
		setChecked(checkOption);
	}, [member]);

	const getFilterOperatorsFromType = useCallback(
		(type, source) => {
			const returnFilterlist = [];
			const applyToMeasure = source === 'measure';
			filterOptions.forEach(filter => {
				if (filter.type.includes(type) && (!applyToMeasure || filter.applyToMeasures)) {
					returnFilterlist.push(filter);
				}
			});

			return returnFilterlist;
		},
		[filterOptions]
	);

	const handleToggle = (event, node) => {
		let newChecked = '';
		let disableInput = false;
		let inputNumber = -1;
		const newValue = event.target.value;
		if (checked !== newValue) {
			newChecked = newValue;
		}

		const operatorName = node?.key?.replace('.$', '');
		// set and notset operators don't need value
		if (operatorName === 'set' || operatorName === 'notSet') {
			disableInput = true;
		}

		for (const f of filterOptions) {
			if (newValue === f.display) {
				inputNumber = f.valueNumber;
				break;
			}
		}

		updateFilters(operatorName, newChecked === '', disableInput, inputNumber);
		setChecked(newChecked);
	};

	return (
		<div>
			<FormControl sx={{ m: 1, width: 200 }} variant="standard">
				<Select
					disableUnderline
					MenuProps={MenuProps}
					data-testid="checkbox-select"
					id="demo-simple-select-standard"
					labelId="demo-simple-select-standard-label"
					renderValue={selected => selected}
					value={checked}
					onChange={handleToggle}
				>
					{getFilterOperatorsFromType(member.type, member.itemSource).map((value, index) => (
						<MenuItem key={value.name} value={value.display}>
							<Checkbox disableRipple checked={checked === value.display} edge="start" tabIndex={-1} />
							<ListItemText primary={value.display} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
}

Filter.propTypes = {
	/**
	 * isYAxis is boolean value to identify yAxis filter
	 */
	isYAxis: PropTypes.bool,
	/**
	 * isXAxis is boolean value to identify isXAxis filter
	 */
	isXAxis: PropTypes.bool,
	/**
	 * isFilterList is boolean value to identify is it filter list or chart axis
	 */
	isFilterList: PropTypes.bool,
};

export default Filter;
