import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

const MobileTitle = ({ title }) => {
	const { t } = useTranslation('studyDetail');
	return (
		<Typography
			component="div"
			sx={{
				fontWeight: '500',
				fontSize: '16px',
				lineHeight: '19px',
				color: 'rgba(18, 18, 18, 0.8)',
				marginBottom: '30px',
			}}
		>
			{t(title)}
		</Typography>
	);
};

export default MobileTitle;
