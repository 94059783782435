import {
	FILES_EXT_GROUP,
	IMPORT_STATIC_VALUES,
	RS_FILE_KIND_IDENTIFIER,
	RS_RACE_IDENTIFIER,
	DOC_TYPES,
	IMG_TYPES,
	MEDIA_TYPES,
} from '../consts/consts';
import dicomParser from 'dicom-parser';
import { getSeriesUID } from '@rs-ui/views/ImageViewerView3D/utils/utils';
import { isEmpty, some } from 'lodash';

export const checkFileHeader = arrayBuffer => {
	const header1 = getHeader(new Uint8Array(arrayBuffer).subarray(0, 4));
	const header2 = getHeader(new Uint8Array(arrayBuffer).subarray(4, 8));
	const header3 = getHeader(new Uint8Array(arrayBuffer).subarray(128, 132));
	const header4 = getHeader(new Uint8Array(arrayBuffer).subarray(0, 2));
	const headers = [header1, header2, header3, header4];
	return Object.keys(FILES_EXT_GROUP).find(keyType =>
		some(headers, header => FILES_EXT_GROUP[keyType].includes(header))
	);
};

const getHeader = arr => arr.reduce((agg, item) => (agg += item.toString(16)), '');

export const groupDicomFiles = (files, series, studyUid) =>
	files.reduce((agg, f) => {
		const seriesUid = parceDicomFiles(f.arrayBuffer, 'x0020000e');
		const index = agg.findIndex(i => i.studyUid === studyUid && i.seriesUid === seriesUid);
		if (index >= 0) {
			return agg.map((el, i) =>
				i === index ? { ...el, file: [...el.file, { id: f.id, name: f?.file?.name }] } : el
			);
		}
		return [
			...agg,
			{
				id: f.id,
				fileType: f.fileType,
				studyUid,
				seriesUid,
				isExisted: !!series.find(i => getSeriesUID(i) === seriesUid && i.studyInstanceUID === studyUid),
				file: [{ id: f.id, name: f?.file?.name }],
			},
		];
	}, []);

export const groupFilesByType = files =>
	files.reduce((acc, file) => {
		const ext = file.fileType;
		if (!acc[ext]) {
			acc[ext] = [];
		}
		acc[ext].push(file);
		return acc;
	}, {});

export const groupFilesByStudyUid = files =>
	files.reduce((acc, file) => {
		const { studyUid } = file;
		if (!acc[studyUid]) {
			acc[studyUid] = [];
		}
		acc[studyUid].push(file);
		return acc;
	}, {});

export const sortFiles = files => {
	const groupedFiles = groupFilesByType(files);

	return Object.keys(groupedFiles).reduce((agg, ext) => {
		const filesExt = groupedFiles[ext];
		let sorted = [];
		if (ext === 'dcm') {
			const sortedDcmBySeriesUid = filesExt.reduce((acc, f) => {
				const seriesUid = parceDicomFiles(f.arrayBuffer, 'x0020000e');

				if (!acc[seriesUid]) {
					acc[seriesUid] = [];
				}
				acc[seriesUid].push(f);
				return acc;
			}, {});

			sorted = Object.keys(sortedDcmBySeriesUid).reduce((acc, seriesUid) => {
				const filesSeriesUid = sortedDcmBySeriesUid[seriesUid];
				return [...acc, ...sortFilesByNameLastModified(filesSeriesUid)];
			}, []);
		} else {
			sorted = sortFilesByNameLastModified(filesExt);
		}

		return [...agg, ...sorted];
	}, []);
};

export const generateImportCompleteMessage = (importingInstances, importingDicomFiles, t) => {
	const uploaded = [];
	const failed = [];
	const aborted = [];

	importingInstances.forEach(i => {
		if (i.uploaded) {
			uploaded.push(...i?.file?.map(f => ({ ...f, name: f?.file?.name ?? '' })));
		} else if (i.aborted) {
			aborted.push(...i?.file?.map(f => ({ ...f, name: f?.file?.name ?? '' })));
		} else if (i.error) {
			failed.push(...i?.file?.map(f => ({ ...f, name: f?.file?.name ?? '' })));
		}
	});

	importingDicomFiles.forEach(i => {
		if (i?.file) {
			i.file.forEach(f => {
				if (f.uploaded) {
					uploaded.push(f);
				} else if (f.aborted) {
					aborted.push(f);
				} else if (f.error) {
					failed.push(f);
				}
			});
		}
	});

	if (aborted.length > 0 && failed.length > 0) {
		return t('MultipleFailedAndAborted', { countFailed: failed.length, countAborted: aborted.length });
	}

	if (aborted.length === 1) {
		return `${aborted[0].name} ${t('Upload Aborted')}`;
	}

	if (aborted.length > 1) {
		return t('MultipleAborted', { countAborted: aborted.length, countSuccessful: uploaded.length });
	}

	if (failed.length === 1) {
		return `${failed[0].name} ${t('Upload Failed')}`;
	}

	if (failed.length > 1) {
		return t('MultipleFailed', { countFailed: failed.length, countSuccessful: uploaded.length });
	}

	if (aborted.length === 0 && failed.length === 0) {
		return t('Upload Successful');
	}
};

const sortFilesByNameLastModified = files => {
	const [nonNumeric, numeric] = files.reduce(
		([p, f], i) => (i.file.name.match(/\d+/g) ? [p, [...f, i]] : [[...p, i], f]),
		[[], []]
	);
	const sortedNonNumericFiles = nonNumeric.sort((a, b) => {
		const aDate = a.file.lastModified;
		const bDate = b.file.lastModified;
		return aDate - bDate;
	});

	const sortedNumericFiles = numeric.sort((a, b) => {
		const aName = a.file.name.split('.')[0];
		const bName = b.file.name.split('.')[0];
		return aName.localeCompare(bName, undefined, { numeric: true, sensitivity: 'base' });
	});
	return [...sortedNumericFiles, ...sortedNonNumericFiles];
};

export const normalizeRegularFiles = files =>
	files.map(fileItem => {
		const { id, file, optimizedFilename, fileType, arrayBuffer, encapsulate } = fileItem;

		return { id, file: [{ file, optimizedFilename, arrayBuffer }], fileType, encapsulate };
	});
export const normalizeImageFiles = files => {
	const [single, grouped] = files.reduce(([p, f], i) => (i.isGrouped ? [p, [...f, i]] : [[...p, i], f]), [[], []]);
	const normalizedSingleFiles = normalizeRegularFiles(single);

	const normalizedGroupedFiles = grouped.reduce((agg, fileItem, index) => {
		const { id, file, optimizedFilename, fileType, arrayBuffer } = fileItem;

		if (index === 0) {
			return {
				id,
				fileType,
				file: [{ file, optimizedFilename, arrayBuffer }],
			};
		}
		return {
			...agg,
			file: [...agg.file, { file, optimizedFilename, arrayBuffer }],
		};
	}, {});
	return [...normalizedSingleFiles, ...(isEmpty(normalizedGroupedFiles) ? [] : [normalizedGroupedFiles])];
};

export const fileExtension = filename => filename.split('.').slice(-1)[0];

export const generateThumbnail = (file, boundBox) => {
	if (!boundBox || boundBox.length != 2) {
		throw 'You need to give the boundBox';
	}
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');
	if (!ctx) {
		throw new Error('Context not available');
	}

	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onerror = reject;
		img.onload = function () {
			const scaleRatio = Math.min(...boundBox) / Math.max(img.width, img.height);
			const w = img.width * scaleRatio;
			const h = img.height * scaleRatio;
			canvas.width = w;
			canvas.height = h;
			ctx.drawImage(img, 0, 0, w, h);
			return resolve(canvas.toDataURL(file.type));
		};
		img.src = window.URL.createObjectURL(file);
	});
};

export const getFiles = async items => {
	const fileHandlesPromises = [...items]
		.filter(item => item?.kind === 'file')
		.map(item => item.getAsFileSystemHandle());

	const files = [];

	for await (const handle of fileHandlesPromises) {
		if (handle?.kind === 'directory') {
			const path = [handle.name];
			const contents = [];

			await getFilesRecursively(handle, contents, path);

			contents.forEach(file => {
				file.path_components = file?.path_components?.join('/');
			});

			files.push(...contents);
		} else {
			const file = await handle.getFile();
			file.path_components = file.name;
			files.push(file);
		}
	}

	return files;
};

const getFilesRecursively = async (entry, files, path) => {
	if (entry?.kind === 'file') {
		const file = await entry.getFile();
		file.path_components = path;
		files.push(file);
	} else if (entry?.kind === 'directory') {
		for await (const handle of entry.values()) {
			path.push(handle.name);
			const newPath = path.map(p => p);
			await getFilesRecursively(handle, files, newPath);
			path.pop();
		}
	}
};

export const readFileAsync = file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			resolve(reader.result);
		};

		reader.onerror = reject;

		reader.readAsArrayBuffer(file);
	});

const toDicomDateFormat = date => {
	const month = (date.getMonth() < 10 ? '0' : '') + (date.getMonth() + 1);
	const day = (date.getDate() < 10 ? '0' : '') + date.getDate();

	return `${date.getFullYear()}${month}${day}`;
};

const toDicomTimeFormat = date => {
	const hours = (date.getHours() < 10 ? '0' : '') + date.getHours();
	const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
	const seconds = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();

	return `${hours}${minutes}${seconds}`;
};

const getDicomTimeZoneOffset = date => {
	const timeZoneOffset = date.getTimezoneOffset();
	const timeZoneOffsetAbs = Math.abs(timeZoneOffset);
	const hours = Math.floor(timeZoneOffsetAbs / 60);
	const minutes = timeZoneOffsetAbs % 60;
	const hoursPadded = (hours < 10 ? '0' : '') + hours;
	const minutesPadded = (minutes < 10 ? '0' : '') + minutes;
	return (timeZoneOffset >= 0 ? '-' : '+') + hoursPadded + minutesPadded;
};

const URLBuilder = (grouped, studyData, managingOrganizationId, url) => {
	const urlParams = new URLSearchParams();
	studyData.id
		? urlParams.append('internalstudyid', studyData.id)
		: studyData.uid && urlParams.append('studyuid', studyData.uid);
	urlParams.append('internalmanagingorganizationid', managingOrganizationId);
	studyData.status && urlParams.append('studyStatus', studyData.status);
	grouped && urlParams.append('mergebulkfiles', true);
	return `${url}/?${urlParams.toString()}`;
};

export const generateDicomJsons = (filesList, studyData, patientData, managingOrganizationId, importUrl) =>
	filesList.map(fileListItem => {
		const { file, customName, id, fileType, encapsulate } = fileListItem;

		const bulkData = file.length > 1 || (DOC_TYPES.includes(fileType) && !encapsulate);
		const url = URLBuilder(bulkData, studyData, managingOrganizationId, importUrl);
		let dicomJson = '{}';
		if (fileType !== 'dcm') {
			dicomJson = file.map(fileItem =>
				nonDicomFilesJsonHeaher({
					type: fileItem.file.type,
					optimizedFilename: fileItem.optimizedFilename,
					patientData,
					customName,
					studyData,
					encapsulate,
				})
			);

			dicomJson = file.length > 1 ? `[${dicomJson.join(',')}]` : dicomJson[0];
		}

		return { file, dicomJson, url, id, fileType };
	});

const nonDicomFilesJsonHeaher = ({ type, patientData, customName, studyData, encapsulate, optimizedFilename }) =>
	dicomJsonBuilder({
		file_type: type,
		encapsulated_file: encapsulate,
		file_name: optimizedFilename,
		series_description: customName,
		patient_race: patientData.race,
		patient_name: patientData.patientName,
		patient_id: patientData.patientId,
		patient_issuer: patientData.issuerOfPatientID,
		patient_country: patientData.country,
		patient_ethnicity: patientData.enthnici,
		patient_smoking: patientData.smoking,
		study_internalid: studyData.id,
		study_uid: studyData.uid,
		study_priority: studyData.priority,
		study_modality: studyData.modality,
		study_accessionNumber: studyData.accessionNumber,
		study_referringPhysian: studyData.referringPhysian,
		study_readingPhysianDisplay: studyData.readingPhysian,
		study_description: studyData.description,
	});

const parceDicomFiles = (arrayBuffer, dicomTag = 'x0020000d') => {
	const byteArray = new Uint8Array(arrayBuffer);

	try {
		// Allow raw files
		const options = { TransferSyntaxUID: '1.2.840.10008.1.2' };
		// Parse the byte array to get a DataSet object that has the parsed contents
		const dataSet = dicomParser.parseDicom(byteArray, options);

		// access a string element
		return dataSet.string(dicomTag);
	} catch (ex) {
		console.error('Error parsing byte stream', ex);
	}
};

const pixelData = fileName => ({
	'7fe00010': {
		vr: 'OB',
		BulkDataURI: [fileName],
	},
});

const encapsulatedPdf = fileName => ({
	'00420011': {
		vr: 'OB',
		BulkDataURI: [fileName],
	},
});

const dicomJsonBuilder = ({
	file_type,
	encapsulated_file,
	file_name = '',
	series_description = '',
	study_internalid = '',
	study_uid = '',
	study_priority = '',
	study_referringPhysian = '',
	study_readingPhysianDisplay = '',
	study_accessionNumber = '',
	study_modality = 'HC',
	study_description = '',
	patient_race = 'UNK',
	patient_name = '',
	patient_id = '',
	patient_issuer = '',
	patient_country = '',
	patient_ethnicity = '',
	patient_smoking = '',
}) => {
	const pdf = encapsulated_file && encapsulatedPdf(file_name);
	const filePixelData = !encapsulated_file && pixelData(file_name);
	const dateTimeNow = new Date();
	const dateNow = toDicomDateFormat(dateTimeNow);
	const timeNow = toDicomTimeFormat(dateTimeNow);
	const timeZoneOffset = getDicomTimeZoneOffset(dateTimeNow);
	let modality = file_type === 'application/pdf' ? (encapsulated_file ? 'DOC' : 'SC') : study_modality;

	if (IMG_TYPES.includes(file_type) || MEDIA_TYPES.includes(file_type)) modality = 'SC';

	const dicomData = {
		'00080000': {
			vr: 'UL',
		},
		'00080005': {
			vr: 'CS',
		},
		'00080008': {
			vr: 'CS',
		},
		'00080012': {
			vr: 'DA',
			Value: [dateNow],
		},
		'00080013': {
			vr: 'TM',
			Value: [timeNow],
		},
		'00080014': {
			vr: 'UI',
			Value: [IMPORT_STATIC_VALUES.RS_IMPLEMENTATION_CLASS_UID],
		},
		'00080016': {
			vr: 'UI',
			Value: [encapsulated_file ? '1.2.840.10008.5.1.4.1.1.104.1' : '1.2.840.10008.5.1.4.1.1.7'],
		},
		'00080018': {
			vr: 'UI',
		},
		'00080020': {
			vr: 'DA',
		},
		'00080023': {
			vr: 'DA',
			Value: [dateNow],
		},
		'00080030': {
			vr: 'TM',
		},
		'00080033': {
			vr: 'TM',
			Value: [timeNow],
		},
		'00080050': {
			vr: 'SH',
			Value: [study_accessionNumber],
		},
		'00080060': {
			vr: 'CS',
			Value: [modality],
		},
		'00080070': {
			vr: 'LO',
			Value: [IMPORT_STATIC_VALUES.RS_MANUFACTURER],
		},
		'00080080': {
			vr: 'LO',
			Value: [IMPORT_STATIC_VALUES.RS_INSTITUTION_NAME],
		},
		'00080090': {
			vr: 'PN',
			Value: [
				{
					Alphabetic: study_referringPhysian,
				},
			],
		},
		'00080201': {
			vr: 'SH',
			Value: [timeZoneOffset],
		},
		'00081030': {
			vr: 'LO',
			Value: [study_description],
		},
		'0008103e': {
			vr: 'LO',
			Value: [series_description],
		},
		'00081048': {
			vr: 'PN',
		},
		'00081050': {
			vr: 'PN',
		},
		'00081060': {
			vr: 'PN',
			Value: [
				{
					Alphabetic: study_readingPhysianDisplay,
				},
			],
		},
		'00081070': {
			vr: 'PN',
		},
		'00081110': {
			vr: 'SQ',
			Value: [
				{
					'00081150': {
						vr: 'UI',
					},
					'00081155': {
						vr: 'UI',
					},
				},
			],
		},
		'00081111': {
			vr: 'SQ',
			Value: [
				{
					'00081150': {
						vr: 'UI',
					},
					'00081155': {
						vr: 'UI',
					},
				},
			],
		},
		'00100010': {
			vr: 'PN',
			Value: [
				{
					Alphabetic: patient_name,
				},
			],
		},
		'00100020': {
			vr: 'LO',
			Value: [patient_id],
		},
		'00100021': {
			vr: 'LO',
			Value: [patient_issuer],
		},
		'00100030': {
			vr: 'DA',
		},
		'00100040': {
			vr: 'CS',
		},
		'00100050': {
			vr: 'SQ',
			Value: [
				{
					'00080100': {
						vr: 'SH',
					},
				},
			],
		},
		'00101000': {
			vr: 'LO',
		},
		'00101040': {
			vr: 'LO',
		},
		'00102150': {
			vr: 'LO',
			Value: [patient_country],
		},
		'00102154': {
			vr: 'SH',
		},
		'00102160': {
			vr: 'SH',
			Value: [patient_ethnicity],
		},
		'001021a0': {
			vr: 'CS',
			Value: [patient_smoking],
		},
		'00180015': {
			vr: 'CS',
		},
		'0020000d': {
			vr: 'UI',
			Value: [study_uid],
		},
		'0020000e': {
			vr: 'UI',
		},
		'00200010': {
			vr: 'SH',
			Value: [study_internalid],
		},
		'00200011': {
			vr: 'IS',
		},
		'00200060': {
			vr: 'CS',
		},
		'00321030': {
			vr: 'LO',
		},
		'00324000': {
			vr: 'LT',
		},
		'00380010': {
			vr: 'LO',
		},
		'00380016': {
			vr: 'LO',
		},
		'00380020': {
			vr: 'DA',
		},
		'00380021': {
			vr: 'TM',
		},
		'00380300': {
			vr: 'LO',
		},
		'00384000': {
			vr: 'LT',
		},
		'00400007': {
			vr: 'LO',
		},
		'00400008': {
			vr: 'SQ',
			Value: [
				{
					'00080100': {
						vr: 'SH',
					},
					'00080102': {
						vr: 'SH',
					},
				},
			],
		},
		'00400009': {
			vr: 'SH',
		},
		'00400241': {
			vr: 'AE',
		},
		'00400242': {
			vr: 'SH',
		},
		'00400244': {
			vr: 'DA',
		},
		'00400245': {
			vr: 'TM',
		},
		'00400253': {
			vr: 'SH',
		},
		'00400254': {
			vr: 'LO',
		},
		'00401001': {
			vr: 'SH',
		},
		'00401003': {
			vr: 'SH',
			Value: [study_priority],
		},
		'00402001': {
			vr: 'LO',
		},
		20500020: {
			vr: 'CS',
		},
		31070010: {
			vr: 'LO',
		},
		31071010: {
			vr: 'DS',
		},
		31110010: {
			vr: 'LO',
			Value: [RS_FILE_KIND_IDENTIFIER],
		},
		31111010: {
			vr: 'CS',
		},
		'311b0010': {
			vr: 'LO',
			Value: ['RamSoft Document Type Identifier'],
		},
		'311b1010': {
			vr: 'LO',
			Value: ['DIAGNOSTIC REPORT'],
		},
		31290010: {
			vr: 'LO',
			Value: [RS_RACE_IDENTIFIER],
		},
		31291010: {
			vr: 'LO',
			Value: [patient_race],
		},
		'4008010a': {
			vr: 'PN',
		},
		'7fe00000': {
			vr: 'UL',
		},
		...pdf,
		...filePixelData,
	};

	const allKeys = new Set();
	JSON.stringify(dicomData, (key, value) => (allKeys.add(key), value));
	return JSON.stringify(dicomData, Array.from(allKeys).sort());
};
