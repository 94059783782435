export const PERMISSIONS_MODULES = {
	QC_MODULE: 'qc module',
	DOCUMENT_REVIEW: 'document review',
	MEASUREMENT_PANEL: 'measurement panel',
	TOOLBAR: 'toolbar',
	HANGING_PROTOCOLS: 'hanging protocols',
	KEY_IMAGE: 'key image',
};

export const QC_MODULE_PERMISSIONS = {
	DELETE_STUDIES: 'delete studies',
	MERGE_STUDIES: 'merge studies',
	MOVE_SERIES: 'move series',
	DELETE_SERIES: 'delete series',
	RENAME_SERIES: 'rename series',
	IMPORT_SERIES: 'import series',
};

export const DOCUMENT_REVIEW_PERMISSIONS = {
	DOCUMENT_REPORTS: 'documents reports',
	VOICE_TEXT: 'voice text',
};

export const MEASUREMENT_PANEL_PERMISSIONS = {
	ANNOTATIONS_MEASUREMENTS: 'annotations measurements',
};

export const TOOLBAR_PERMISSIONS = {
	WORKFLOW_FINISHING_OPTIONS: 'workflow finishing options',
	DOWNLOAD_STUDY: 'download study',
	DOWNLOAD_PRINTIMAGES: 'download print images',
	SHARE: 'share',
	NOTES: 'notes',
	DELETE_NOTES_DICTATIONS: 'delete notes dictations',
	VOICE_DICTATIONS: 'voice dictations',
	CUSTOMIZE_OVERLAYS: 'customize overlays',
	CUSTOMIZE_WHEEL: 'customize wheel',
	CUSTOMIZE_TOOLBAR: 'customize toolbar',
};
