import axios from 'axios';

const getAvailableTimeslots = async ({ __config, orgId, modality, date }) => {
	const response = await axios.get(
		`${__config.data_sources.blume}Appointment/findavailabletime?internalOrganizationId=${orgId}&modality=${modality}&date=${date}`,
		{ headers: { 'X-API-KEY': window.btoa(__config.patient_portal.authentication_key) } }
	);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default getAvailableTimeslots;
