import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LastPageIcon from '@mui/icons-material/LastPage';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState, createRef } from 'react';
import { DetailTab, DetailTabs } from '.';
import { Row, Col, Title, Wrapper } from '../casePopOverStyles';
import Contacts from './DetailTabs/Contacts';
import Overview from './DetailTabs/Overview';
import _ from 'lodash';
import BlumeID from './DetailTabs/BlumeID';
import { useCrmContext } from '../../../../context/CRMContext';
import { crmSearchScopes, useCRMDataLoader, useCRMSearchScope, useAuth } from '@worklist-2/core/src';
import TextField from '@mui/material/TextField';
import Suggest from '@worklist-2/ui/src/components/Suggest/Suggest';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const ref = createRef();

const NewCaseDetailPopOver = ({ editable = true }) => {
	const { loggedInUser } = useAuth();
	const { setFetchData, validateCaseForm, handleOverViewTabChange } = useCRMSearchScope();
	const caseDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.caseApi,
	});
	const userDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.userApi,
	});
	const tabs = ['Overview', 'Blume ID', 'Contacts'];
	const [assignTo, setAssignTo] = useState(null);
	const [overViewData, setOverViewData] = useState({});
	const [contactData, setContactData] = useState([]);
	const [errMsgs, setErrMsgs] = useState({});
	const [isValidForm, setIsValidForm] = useState(true);
	const [tabIndex, setTabIndex] = useState(0);
	const { toggleNewDetails } = useCrmContext();
	const [watcherData, setWatcherData] = useState([]);
	const [watching, setWatching] = useState(false);

	const handleTabChange = (event, newTabIndex) => {
		setTabIndex(newTabIndex);
	};

	const onChangeData = (e, value, tab, payload) => {
		if (tabs[tabIndex].toLowerCase().includes('overview')) {
			if (payload) {
				handleOverViewTabChange(value, payload, overViewData, setOverViewData);
			} else {
				handleOverViewTabChange(e.target.name, e.target.value, overViewData, setOverViewData);
			}
		} else if (tabs[tabIndex].toLowerCase().includes('contacts')) {
			handleContactTabChange(value);
		}
	};

	const handleContactTabChange = value => {
		let tempData = contactData;
		if (value.Index == undefined) {
			tempData.push(value);
		} else if (value.Deleted) {
			tempData = _.remove(tempData, currentObject => !currentObject.Deleted);
		}
		setContactData(tempData);
	};

	useEffect(() => {
		const ignoreClickOnMeElement = ref.current;

		const listener = event => {
			const isClickInsideElement = ignoreClickOnMeElement.contains(event.target);

			const customButton = ['assignedmenu'];
			if (
				!isClickInsideElement &&
				!event.target.id &&
				!customButton.includes(event.target.id.split('-')[0]) &&
				!customButton.includes(event.target.parentElement.id.split('-')[0]) &&
				!event.target?.getAttribute('class')?.split(' ')?.includes('MuiBackdrop-invisible') &&
				!document.activeElement.className?.split(' ')?.includes('MuiMenuItem-root')
			) {
				toggleNewDetails();
			}
		};
		document.addEventListener('click', listener, true);

		return () => {
			document.removeEventListener('click', listener, true);
		};
	}, []);

	const handleSubmit = async event => {
		event.preventDefault();
		if (
			document.activeElement.value !== 'submitNewContact' &&
			validateCaseForm(overViewData, setErrMsgs, setIsValidForm)
		) {
			const caseJson = buildCaseJson(event.target);
			await caseDataLoader.save(null, caseJson);
			setFetchData(true);
			if (document.activeElement.value === 'saveNew') {
				event.target.reset();
				setAssignTo({});
			} else {
				toggleNewDetails();
			}
		}
	};

	const buildCaseJson = data => {
		const caseJson = {};
		const now = new Date();
		const caseNumber = [
			now.getUTCFullYear(),
			addZero(now.getUTCMonth() + 1, 2),
			addZero(now.getUTCDate(), 2),
			addZero(now.getUTCHours(), 2),
			addZero(now.getUTCMinutes(), 2),
			addZero(now.getUTCSeconds(), 2),
			addZero(now.getUTCMilliseconds(), 3),
		].join('');
		caseJson.ResourceType = 'Case';
		caseJson.AssignedTo = assignTo ? assignTo.AssignedTo : data.assigned?.value;
		caseJson.AssignedToId = assignTo ? assignTo.AssignedToId : data.assigned?.value;
		caseJson.Priority = data.priority?.value || 'P3';
		caseJson.CaseName = data.caseName?.value;
		caseJson.CaseNumber = caseNumber;
		caseJson.CaseOrigin = data.caseOrigin?.value;
		caseJson.CaseRecordType = data.caseRecordType?.value;
		caseJson.CreatedDateTime = new Date().toISOString();

		//Temp hard code here because we missing this field in design
		caseJson.CaseOwner = loggedInUser?.display;
		caseJson.CaseOwnerId = loggedInUser?.id;
		caseJson.ImpactPatientCase = false;

		//Data need handle before post
		for (const item in overViewData) {
			switch (item) {
				case 'ImpactPatientCase':
					caseJson.ImpactPatientCase = overViewData.ImpactPatientCase?.toLowerCase() === 'yes';
					break;
				case 'ClosedDateTime':
					if (overViewData.ClosedDateTime) {
						caseJson.ClosedDateTime = new Date(overViewData.ClosedDateTime).toISOString();
					}
					break;
				default:
					caseJson[item] = overViewData[item];
			}
		}

		caseJson.RelatedContact = _.map(contactData, contact => ({
			FullName: contact.name,
			JobTitle: contact.jobTitle,
			CellPhone: contact.phone,
			Email: contact.email,
		}));

		caseJson.Watcher = watcherData;

		return caseJson;
	};

	const addZero = (num, length) => {
		while (num.toString().length < length) {
			num = `0${num}`;
		}
		return num;
	};

	const handleUserSelect = (value, searchValue, optionId, record) => {
		if (record) {
			setAssignTo({
				AssignedTo: record.UserName,
				AssignedToId: record.UserId,
			});
		} else {
			setAssignTo({});
		}
	};

	const isWatching = () => _.some(watcherData, watcher => watcher.UserId === loggedInUser.id);

	const handleWatching = () => {
		if (isWatching()) {
			setWatcherData([]);
			setWatching(false);
		} else {
			setWatcherData([{ UserName: loggedInUser.display, UserId: loggedInUser.id }]);
			setWatching(true);
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<Wrapper ref={ref} className="invisible-scrollbar" id="popover-wrapper">
				<div className="header">
					<div className="actions">
						<div className="left">
							<AccountCircleOutlinedIcon
								sx={{
									color: 'rgba(0, 0, 0, 0.4)',
									fontSize: '36px',
								}}
							/>
							<Suggest
								disableClearable
								hideIcon
								showAvatar
								summary
								customDataLoader={userDataLoader}
								disableSearch={!loggedInUser.permission?.User.Read}
								disabled={false}
								listSearchScope={{
									label: 'UserName',
									searchKey: 'name',
								}}
								optionId="UserName"
								size="small"
								style={{
									'& legend': { display: 'none' },
									'& fieldset': { top: 0 },
								}}
								testid="assigner"
								variant="standard"
								width={209}
								onSelect={handleUserSelect}
							/>
						</div>
						<div className="right">
							<IconButton sx={{ color: 'rgba(0, 0, 0, 0.6)' }} onClick={handleWatching}>
								{watching ? <VisibilityIcon /> : <VisibilityOutlinedIcon />}
							</IconButton>
							<IconButton sx={{ color: 'rgba(0, 0, 0, 0.6)' }} onClick={() => toggleNewDetails()}>
								<LastPageIcon />
							</IconButton>
						</div>
					</div>
					<Title>
						<span className="priority">
							<select
								name="priority"
								style={{
									border: 'none',
									fontFamily: 'Inter',
									fontSize: '25px',
									fontWeight: 'bold',
									fontStretch: 'normal',
									fontStyle: 'normal',
									color: '#4d79ea',
								}}
							>
								<option>P1</option>
								<option>P2</option>
								<option selected="selected">P3</option>
								<option>P4</option>
								<option>P5</option>
							</select>
						</span>

						<input name="caseName" placeholder="Enter a name for this issue" />
					</Title>
					<Row>
						<Col>
							<label className="detail">Case Record Type</label>
							<select name="caseRecordType">
								<option>Support Issue</option>
								<option>Error Issue</option>
								<option>Claim Issue</option>
							</select>
						</Col>
						<Col>
							<label className="detail">Case Origin</label>
							<TextField
								hiddenLabel
								className="without-padding"
								id="caseOrigin"
								name="caseOrigin"
								size="small"
								sx={{
									height: '40px',
									'& legend': {
										display: 'none',
									},
									'& fieldset': {
										top: 0,
									},
									fontSize: '14px',
									flex: '1',
									maxWidth: '226px',
								}}
								variant="outlined"
							/>
						</Col>
					</Row>
				</div>
				<div className="body">
					<Box sx={{ margin: '0px 36px' }}>
						<DetailTabs
							scrollButtons={false}
							value={tabIndex}
							variant="scrollable"
							onChange={handleTabChange}
						>
							{_.map(tabs, (tab, i) => (
								<DetailTab key={i} label={tab} />
							))}
						</DetailTabs>
					</Box>
					<Box style={{ flex: 1, paddingBottom: 20 }}>
						{tabs[tabIndex].toLowerCase().includes('overview') && (
							<Overview
								data={overViewData}
								editable={editable}
								errorMsgs={errMsgs}
								isValidForm={isValidForm}
								onChangeData={onChangeData}
							/>
						)}
						{tabs[tabIndex].toLowerCase().includes('blume') && <BlumeID editable={editable} />}
						{tabs[tabIndex].toLowerCase().includes('contacts') && (
							<Contacts
								data={contactData}
								editable={editable}
								handleSubmit={handleSubmit}
								onChangeData={onChangeData}
							/>
						)}
					</Box>
					<div className="footer">
						<button
							className="outline"
							data-testid="cancelbutton"
							type="button"
							onClick={() => toggleNewDetails()}
						>
							CANCEL
						</button>
						<div className="right">
							<button className="outline" data-testid="saveNewbutton" type="submit" value="saveNew">
								SAVE &amp; New
							</button>
							<button className="fill" data-testid="savebutton" type="submit" value="save">
								SAVE
							</button>
						</div>
					</div>
				</div>
			</Wrapper>
		</form>
	);
};

export default NewCaseDetailPopOver;
