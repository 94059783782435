import React from 'react';

const MyAppsIcon = ({ fill }) => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_31890)" opacity={fill ? 1 : 0.6}>
			<path
				d="M4 8H8V4H4V8ZM10 20H14V16H10V20ZM4 20H8V16H4V20ZM4 14H8V10H4V14ZM10 14H14V10H10V14ZM16 4V8H20V4H16ZM10 8H14V4H10V8ZM16 14H20V10H16V14ZM16 20H20V16H16V20Z"
				fill={fill || 'white'}
			/>
		</g>
		<defs>
			<clipPath id="clip0_2852_31890">
				<rect fill={fill || 'white'} height="24" width="24" />
			</clipPath>
		</defs>
	</svg>
);

export default MyAppsIcon;
