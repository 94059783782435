import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';

const SelectComponent = ({ formField, disabled, onChange, option, variant, className }) => {
	const getDefaultValue = () => {
		if (formField.multiple) {
			return option;
		}

		return option || '';
	};

	const onSelectChange = event =>
		onChange(event, event.target.value, formField.mapping || formField.name, false, formField.customModelUpdate);

	const renderMenuItem = item => (
		<MenuItem
			key={formField.renderValue ? formField.renderValue(item) : `${formField.mapping}-menuItem`}
			value={item}
		>
			{formField.renderValue && formField.renderValue(item)}
		</MenuItem>
	);

	const showClearButton = formField?.isClearable && option;

	return (
		<TextField
			key={`${formField.mapping}-field`}
			fullWidth
			select
			InputLabelProps={{ shrink: true }}
			SelectProps={{
				multiple: formField.multiple,
				renderValue: formField.renderValue,
				MenuProps: {
					PaperProps: {
						sx: {
							maxHeight: '300px', //temporary fix to stop dropdown overflow
						},
					},
				},
				endAdornment: showClearButton && (
					<SelectClearButton
						onClick={() => {
							formField.onChange && formField.onChange('');
							onChange(
								event,
								'',
								formField.mapping ? formField.mapping : formField.name,
								false,
								formField.customModelUpdate
							);
						}}
					/>
				),
				...formField?.adornments,
			}}
			className={className}
			data-testid="form-field"
			disabled={disabled || formField.disabled}
			helperText={formField.helperText}
			id={formField.id ? formField.id : `form-field-${formField.name}`}
			label={formField.name}
			placeholder={disabled ? null : formField.placeholder}
			required={formField.required}
			value={getDefaultValue()}
			variant={variant || 'outlined'}
			onChange={event => {
				formField.onChange && formField.onChange(event.target.value);

				onSelectChange(event);
			}}
		>
			{formField.options.map(item => renderMenuItem(item))}
		</TextField>
	);
};

const SelectClearButton = ({ onClick }) => (
	<InputAdornment
		position="end"
		sx={{
			position: 'absolute',
			right: '35px',
			cursor: 'pointer',
			width: '28px',
			height: '28px',
			borderRadius: '50%',
			justifyContent: 'center',
			alignItems: 'center',

			'&:hover': {
				backgroundColor: 'rgba(255, 255, 255, 0.08)',
			},
		}}
		onClick={onClick}
	>
		<CloseIcon sx={{ width: '20px', height: '20px' }} />
	</InputAdornment>
);

const Select = React.memo(SelectComponent);

export default Select;
