import React from 'react';

const PollIcon = ({ fill }) => (
	<svg fill="none" height="25" viewBox="0 0 25 25" width="25" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_31950)">
			<path
				d="M19.7566 3.21484H5.58463C4.47112 3.21484 3.56006 4.1259 3.56006 5.23942V19.4114C3.56006 20.5249 4.47112 21.436 5.58463 21.436H19.7566C20.8702 21.436 21.7812 20.5249 21.7812 19.4114V5.23942C21.7812 4.1259 20.8702 3.21484 19.7566 3.21484ZM19.7566 19.4114H5.58463V5.23942H19.7566V19.4114ZM7.60921 10.3009H9.63378V17.3869H7.60921V10.3009ZM11.6584 7.26399H13.6829V17.3869H11.6584V7.26399ZM15.7075 13.3377H17.7321V17.3869H15.7075V13.3377Z"
				fill={fill}
			/>
		</g>
		<defs>
			<clipPath id="clip0_2852_31950">
				<rect fill={fill} height="24.2949" transform="translate(0.523438 0.179688)" width="24.2949" />
			</clipPath>
		</defs>
	</svg>
);

export default PollIcon;
