const defaultColumns = [
	'accessionNumber',
	'modality',
	'patientName',
	'managingOrganization',
	'studyStatus',
	'studyDescription',
];

const demographicColumns = [
	'birthDate',
	'birthSex',
	'ethnicity',
	'gender',
	'patientAddress',
	'cellPhone',
	'patientEmail',
	'patientID',
	'patientName',
	'state',
	'ssn',
	'languages',
	'race',
];

const administrativeColumns = [
	'accountNumber',
	'accountStatus',
	'patientBalance',
	'patientLocation',
	'managingOrganization',
	'homePhone',
	'specialArrangement',
	'insurancePayer',
];

const studyClinicalColumns = [
	'accessionNumber',
	'anatomicFocus',
	'bodyPart',
	'clinicalNotes',
	'imagingOrganization',
	'studyDescription',
	'studyStatus',
	'modality',
	'laterality',
];
const studyImageSpecsColumns = [
	'numFrames',
	'numberOfInstances',
	'numReports',
	'numImages',
	'patientAge',
	'studyID',
	'studyUID',
	'priority',
];
const orderVisitDetailsColumns = [
	'cdsId',
	'cancellationReason',
	'authorizationNumber',
	'procedureCode',
	'requestedProcedureID',
	'orderCustomMemo',
	'view',
	'visitNumber',
	'visitClass',
];
const dateTimeColumns = [
	'dateTimeAddendum',
	'lastUpdated',
	'dateTimeOrdered',
	'dateTimeRead',
	'dateTimeReceived',
	'dateTimeSigned',
	'dateTimeTranscribed',
	'dateTimeVerified',
	'studyDateTime',
	'requestedAppointmentDateTime',
	'orderDateTime',
	'timeZone',
];
const clinicalContributersColumns = [
	'performingPhysician',
	'performingPhysicianNPI',
	'performingTechnologist',
	'readingFacility',
	'readingPhysician',
	'readingPhysicianNPI',
	'reasonForExam',
	'referringFacility',
	'referringPhysician',
	'referringPhysicianNPI',
	'transcriptionistFacility',
	'transcriptionist',
];
const miscColumns = [
	'department',
	'eligibility', // No Data
	'fillerOrderNumber',
	'financialClass', // No Data
	'healthcareService', // No Data
	'orderOfAppropriateness',
	'objectType', // No Data
	'pharmaceutical',
	'placerOrderNumber',
	'requestedProcedureID',
	'timeAtStatus',
];

const columnGroupings = {
	default: defaultColumns,
	demographic: demographicColumns,
	administrative: administrativeColumns,
	studyClinical: studyClinicalColumns,
	studyImageSpecs: studyImageSpecsColumns,
	orderVisitDetails: orderVisitDetailsColumns,
	dateTime: dateTimeColumns,
	clinicalContributers: clinicalContributersColumns,
	misc: miscColumns,
};

export { columnGroupings };
