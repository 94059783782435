/* Generated automagically by FhirStarter - DO NOT EDIT */

const feeScheduleSchema = {
	active: '__boolean__',
	id: '__string__',
	endDate: '__string__',
	externalId: '__string__',
	feeScheduleName: '__string__',
	isActive: '__boolean__',
	isDefault: '__boolean__',
	isSelfPay: '__boolean__',
	startDate: '__string__',
};
export default feeScheduleSchema;
