// React Libraries
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Material UI Libraries
import StarIcon from '@mui/icons-material/Star';
import Typography from '@mui/material/Typography';
import Carousel from 'react-material-ui-carousel';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

// Custom Component Libraries
import ReportsCard from './components/ReportsCard';

const FavouriteReports = ({
	fetchDeletableReport,
	reports,
	checkedReports,
	setCheckedReports,
	setDeleteModal,
	reportOnClick,
	onSaveFavorite,
	onOpenShare,
}) => {
	const [items, setItems] = useState([]);
	const isSmallScreen = useMediaQuery('(max-width:1100px)');
	const isHdMonitor = useMediaQuery('(max-width:1920px)');

	useEffect(() => {
		const sliderLength = Math.floor(window.innerWidth / 400);

		const sliderItems = reports.length > sliderLength ? sliderLength : reports.length;
		const newItems = [];
		for (let i = 0; i < reports.length; i += sliderItems) {
			if (i % sliderItems === 0) {
				newItems.push(
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: 'repeat( auto-fit, 350px)',
							gridGap: '22px',
						}}
					>
						{reports.slice(i, i + sliderItems).map((report, index) => (
							<Box key={report.id}>
								<ReportsCard
									checkedReports={checkedReports}
									fetchDeletableReport={fetchDeletableReport}
									report={report}
									setCheckedReports={setCheckedReports}
									setDeleteModal={setDeleteModal}
									onOpenShare={onOpenShare}
									onSaveFavorite={onSaveFavorite}
								/>
							</Box>
						))}
					</Box>
				);
			}
		}
		setItems(newItems);
	}, [reports, isSmallScreen, isHdMonitor]);

	return (
		<Box
			sx={{
				padding: isSmallScreen ? '32px 60px' : isHdMonitor ? '32px 85px' : '32px 107px',
				background: '#FFF',
				borderRadius: '20px',
				boxShadow: '0px 4px 20px rgba(33, 88, 119, 0.1)',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					mb: '20px',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						color: '#FFFFFF',
						gap: '8px',
					}}
				>
					<StarIcon sx={{ fontSize: '18px', color: '#4D79EA' }} />
					<Typography
						sx={{
							font: 'normal normal normal 24px/32px Roboto',
							color: '#4D79EA',
							fontSize: '18px',
							fontWeight: 500,
						}}
					>
						Favourite
					</Typography>
					<Box
						sx={{
							padding: '4px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: 'rgba(0, 0, 0, 0.6)',
							fontSize: '18px',
							fontWeight: 500,
							width: '27px',
							height: '27px',
							background: 'rgba(196, 196, 196, 0.35)',
							borderRadius: '6px',
						}}
					>
						{reports.length}
					</Box>
				</Box>
			</Box>

			<Carousel
				navButtonsAlwaysVisible
				swipe
				autoPlay={false}
				cycleNavigation={false}
				navButtonsProps={{
					style: {
						color: '#4D79EA',
						background: '#FFF',
						boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
						opacity: 1.0,
					},
				}}
				navButtonsWrapperProps={{
					style: {
						top: '-11px',
					},
				}}
			>
				{items}
			</Carousel>
		</Box>
	);
};

FavouriteReports.propTypes = {
	/**
	 * reports is array of all reports
	 */
	reports: PropTypes.array,
	/**
	 * checkedReports is array of index to get the checked reports
	 */
	checkedReports: PropTypes.array,
	/**
	 * setCheckedReports is function to set checked reports array
	 */
	setCheckedReports: PropTypes.func,
	/**
	 * onDeleteReport is function to delete report
	 */
	onDeleteReport: PropTypes.func,
	/**
	 * onSaveFavorite is function to set favorite report
	 */
	onSaveFavorite: PropTypes.func,
	/**
	 * onOpenShare is function to share the report
	 */
	onOpenShare: PropTypes.func,
};

export default FavouriteReports;
