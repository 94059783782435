/* Generated automagically by FhirStarter - but please feel free to edit this file */
const PatientMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.id;
		},
	},
	patientId: {
		label: 'Patient ID',
		filterType: 'none',
		getDisplay: resource => {
			const patientId = resource?.identifier?.find(i => i.system == 'urn:oid:1.2.36.146.595.217.0.1');
			return patientId?.value || '';
		},
	},
	active: {
		label: 'Active',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.active;
		},
	},
	name: {
		label: 'Name',
		options: { filter: false, sort: false },
		filterType: 'none',
		searchParameter: 'name',
		getDisplay: resource => {
			let nameArray = resource?.name;
			if (!!nameArray && nameArray.length > 0) {
				return nameArray[0]?.text;
			} else {
				return '';
			}
		},
	},
	confidentiality: {
		label: 'Confidentiality',
		getDisplay: resource => {
			let securityArray = resource?.meta?.security;
			if (!!securityArray && securityArray.length > 0) {
				return securityArray[0]?.display;
			} else {
				return '';
			}
		},
	},
	phoneNumber: {
		label: 'Telecom',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.telecom?.find(elem => elem.system == 'phone')?.value;
		},
	},
	gender: {
		label: 'Gender',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.gender;
		},
	},
	birthDate: {
		label: 'Birth Date',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.birthDate;
		},
	},
	deceasedBoolean: {
		label: 'Deceased Boolean',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.deceasedBoolean;
		},
	},
	deceasedDateTime: {
		label: 'Deceased Date Time',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.deceasedDateTime;
		},
	},
	address: {
		label: 'Address',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.address;
		},
	},
	maritalStatus: {
		label: 'Marital Status',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.maritalStatus;
		},
	},
	multipleBirthBoolean: {
		label: 'Multiple Birth Boolean',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.multipleBirthBoolean;
		},
	},
	multipleBirthInteger: {
		label: 'Multiple Birth Integer',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.multipleBirthInteger;
		},
	},
	photo: {
		label: 'Photo',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.photo;
		},
	},
	contact: {
		label: 'Contact',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.contact;
		},
	},
	communication: {
		label: 'Communication',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.communication;
		},
	},
	generalPractitioner: {
		label: 'General Practitioner',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.generalPractitioner;
		},
	},
	managingOrganizationId: {
		label: 'Managing Organization ID',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.managingOrganization?.id;
		},
	},
	managingOrganization: {
		label: 'Managing Organization',
		options: { filter: true, sort: true },
		filterType: 'suggest',
		getDisplay: resource => {
			return resource?.managingOrganization?.display;
		},
	},
	link: {
		label: 'Link',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.link;
		},
	},
};
export default PatientMapping;
