/* Generated automagically by FhirStarter - DO NOT EDIT */
const serviceRequestSchema = {
	resourceType: 'ServiceRequest',
	identifier: ['__Identifier__'],
	instantiatesCanonical: ['__canonical(ActivityDefinition|PlanDefinition)__'],
	instantiatesUri: ['__uri__'],
	basedOn: ['__Reference(CarePlan|ServiceRequest|MedicationRequest)__'],
	replaces: ['__Reference(ServiceRequest)__'],
	requisition: '__Identifier__',
	status: '__code__',
	intent: '__code__',
	category: ['__CodeableConcept__'],
	priority: '__code__',
	doNotPerform: '__boolean__',
	code: '__CodeableConcept__',
	orderDetail: ['__CodeableConcept__'],
	quantityQuantity: '__Quantity__',
	quantityRatio: '__Ratio__',
	quantityRange: '__Range__',
	subject: '__Reference(Patient|Group|Location|Device)__',
	encounter: '__Reference(Encounter)__',
	occurrenceDateTime: '__dateTime__',
	occurrencePeriod: '__Period__',
	occurrenceTiming: '__Timing__',
	asNeededBoolean: '__boolean__',
	asNeededCodeableConcept: '__CodeableConcept__',
	authoredOn: '__dateTime__',
	requester: '__Reference(Practitioner|PractitionerRole|Organization|Patient|RelatedPerson|Device)__',
	performerType: '__CodeableConcept__',
	performer: [
		'__Reference(Practitioner|PractitionerRole|Organization|CareTeam|HealthcareService|Patient|Device|RelatedPerson)__',
	],
	locationCode: ['__CodeableConcept__'],
	locationReference: ['__Reference(Location)__'],
	reasonCode: ['__CodeableConcept__'],
	reasonReference: ['__Reference(Condition|Observation|DiagnosticReport|DocumentReference)__'],
	insurance: ['__Reference(Coverage|ClaimResponse)__'],
	supportingInfo: ['__Reference(Any)__'],
	specimen: ['__Reference(Specimen)__'],
	bodySite: ['__CodeableConcept__'],
	note: ['__Annotation__'],
	patientInstruction: '__string__',
	relevantHistory: ['__Reference(Provenance)__'],
};
export default serviceRequestSchema;
