import React from 'react';
import IconButton from '@mui/material/IconButton';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

/**
 * Default button for the Generic Drawer
 * @param {*} props
 * @returns
 */
const GenericDrawerButton = props => {
	const defaultButtonProps = {
		sx: {
			width: 50,
			height: 50,
		},
	};

	const defaultProps = { ...props, ...defaultButtonProps };

	return (
		<IconButton {...defaultProps}>
			<SettingsOutlinedIcon sx={{ fontSize: 25 }} />
		</IconButton>
	);
};

export default GenericDrawerButton;
