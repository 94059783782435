const extractAddressFromProfile = address => {
	if (Array?.isArray(address) && address?.length) {
		return {
			address: address[0]?.addressLine1 || '',
			addressLine2: address[0]?.addressLine2 || '',
			city: address[0]?.city || '',
			country: address[0]?.country || '',
			zip: address[0]?.zip || '',
			state: address[0]?.state || '',
		};
	}
	return {};
};

export { extractAddressFromProfile };
