import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import styledCom from 'styled-components';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ErrorIcon from '@mui/icons-material/Error';
import ReportIcon from '@mui/icons-material/Report';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const StyledErrorIcon = styled(ErrorIcon)({
	fontSize: 'medium',
	color: 'rgba(256, 256, 256, 0.6) !important',
	'&:hover': {
		color: '#42A5F5 ',
		backgroundColor: 'rgba(66, 165, 245, 0.06)',
	},
});

const StyledReportIconIcon = styled(ReportIcon)({
	fontSize: '20px',
	color: '#FFB800 !important',
});

const StyledTypography = styled(Typography)({
	fontFamily: 'Roboto',
	fontStyle: 'normal',
	fontWeight: 400,
	fontSize: '14px',
	lineHeight: '20px',
	letterspacing: '0.25px',
	color: '#FFFFFF',
	whiteSpace: 'normal',
});

const WindowWrapper = styledCom.div`
	width: 464px;
	height: 251px;
	border-radius: 6px;
	left: ${props => `${props.x - 20}px`};
	top: ${props => `${props.y + 20}px`};
	position: ${props => `${props.position || 'absolute'}`};
	z-index: 100;
	background: #393939;
	border: 1px solid #454545;
	white-space: unset ;
`;

const TextWithReasonWindow = ({ text, windowText, windowHeader }) => {
	const [windowXPos, setWindowXPos] = useState(-100);
	const [windowYPos, setWindowYPos] = useState(-100);
	const [windowOpen, setWindowOpen] = useState(false);

	// Constant for wrapper height based on style
	const wrapperHeight = 251;

	const handleMouseEnter = event => {
		event.preventDefault();

		// Calulate display position for wrapper
		// The root element heigh
		const rootHeight = root?.offsetHeight;

		// Position of wrapper
		const wrapperY = event.clientY;

		// Diff range from root to selected item
		let diff = 0;

		// Size of wrapper when opened
		const diffRootAndWrapperSize = event.clientY + wrapperHeight / 2;

		// Check wrapper opended is cut off
		if (diffRootAndWrapperSize > rootHeight) {
			// Set diff range to adjust the display of client Y
			diff = diffRootAndWrapperSize - rootHeight;
		}
		setWindowXPos(event.clientX + 36);
		setWindowYPos(wrapperY - wrapperHeight / 2 - diff);

		setWindowOpen(true);
	};

	return (
		<>
			<Typography
				color="primary.contrastText"
				sx={{
					size: '14px',
					color: '#FFFFFF',
				}}
				variant="subtitle2"
			>
				{text}
				{!!windowText && (
					<IconButton>
						<StyledErrorIcon
							data-testid="statusIcon"
							onMouseEnter={handleMouseEnter}
							onMouseLeave={() => {
								setWindowOpen(false);
							}}
						/>
					</IconButton>
				)}
			</Typography>
			{!!windowText && (
				<Collapse in={windowOpen}>
					<WindowWrapper position="fixed" x={windowXPos} y={windowYPos}>
						<Grid container spacing={2}>
							<Grid item xs={1}>
								<IconButton>
									<StyledReportIconIcon />
								</IconButton>
							</Grid>
							<Grid item xs={10}>
								<Box>
									<StyledTypography
										style={{
											paddingTop: '10px',
											paddingBottom: '10px',
										}}
									>
										{windowHeader}
									</StyledTypography>
									<StyledTypography>{windowText}</StyledTypography>
								</Box>
							</Grid>
						</Grid>
					</WindowWrapper>
				</Collapse>
			)}
		</>
	);
};

TextWithReasonWindow.propTypes = {
	/**
	 * Label for the component.
	 * @type {string}
	 */
	text: PropTypes.string,

	/**
	 * Label for the failure window.
	 * if it's empty, hide the failure window and display Text label only.
	 * @type {string}
	 */
	windowText: PropTypes.string,

	/**
	 * Header text for the failure window.
	 * @type {string}
	 */
	windowHeader: PropTypes.string,
};

export default TextWithReasonWindow;
