const SOPClass = [
	{
		name: 'SC_BASICTEXTSR',
		code: '1.2.840.10008.5.1.4.1.1.88.11',
		display: 'Basic Text SR',
	},
	{
		name: 'SC_ENHANCEDSR',
		code: '1.2.840.10008.5.1.4.1.1.88.22',
		display: 'Enhanced SR',
	},
	{
		name: 'SC_COMPREHENSIVESR',
		code: '1.2.840.10008.5.1.4.1.1.88.33',
		display: 'Comprehensive SR',
	},
	{
		name: 'SC_ENCAPSULATEDPDF',
		code: '1.2.840.10008.5.1.4.1.1.104.1',
		display: 'Encapsulated PDF Storage',
	},
	{
		name: 'SC_US',
		code: '1.2.840.10008.5.1.4.1.1.6.1',
		display: 'Ultrasound Image Storage',
	},
	{
		name: 'SC_USRETIRED',
		code: '1.2.840.10008.5.1.4.1.1.6',
		display: 'RETIRED Ultrasound Image Storage',
	},
	{
		name: 'SC_USMULTI',
		code: '1.2.840.10008.5.1.4.1.1.3.1',
		display: 'Ultrasound Multi-frame Image Storage',
	},
	{
		name: 'SC_USMULTIRETIRED',
		code: '1.2.840.10008.5.1.4.1.1.3',
		display: 'RETIRED Ultrasound Multi Frame Image Storage',
	},
	{
		name: 'SC_CR',
		code: '1.2.840.10008.5.1.4.1.1.1',
		display: 'Computed Radiography Image Storage',
	},
	{
		name: 'SC_DXPRES',
		code: '1.2.840.10008.5.1.4.1.1.1.1',
		display: 'Digital Xray Pres Image Storage',
	},
	{
		name: 'SC_DXPROC',
		code: '1.2.840.10008.5.1.4.1.1.1.1.1',
		display: 'Digital Xray Proc Image Storage',
	},
	{
		name: 'SC_MGPRES',
		code: '1.2.840.10008.5.1.4.1.1.1.2',
		display: 'Digital Mammography X-ray Image Storage for Presentation',
	},
	{
		name: 'SC_MGPROC',
		code: '1.2.840.10008.5.1.4.1.1.1.2.1',
		display: 'Digital Mammography X-ray Image Storage for Processing',
	},
	{
		name: 'SC_INTRAORALXRAYPRES',
		code: '1.2.840.10008.5.1.4.1.1.1.3',
		display: 'Digital Intra Oral X-ray Image Storage for Presentation',
	},
	{
		name: 'SC_INTRAORALXRAYPROC',
		code: '1.2.840.10008.5.1.4.1.1.1.3.1',
		display: 'Digital Intra Oral X-ray Image Storage for Processing',
	},
	{
		name: 'SC_MGTOMO',
		code: '1.2.840.10008.5.1.4.1.1.13.1.3',
		display: 'Breast Tomosynthesis Image Storage',
	},
	{
		name: 'SC_RF',
		code: '1.2.840.10008.5.1.4.1.1.12.2',
		display: 'X-ray Angiographic Image Storage',
	},
	{
		name: 'SC_CT',
		code: '1.2.840.10008.5.1.4.1.1.2',
		display: 'CT Image Storage',
	},
	{
		name: 'SC_MR',
		code: '1.2.840.10008.5.1.4.1.1.4',
		display: 'MR Image Storage',
	},
	{
		name: 'SC_PET',
		code: '1.2.840.10008.5.1.4.1.1.128',
		display: 'PET Image Storage',
	},
	{
		name: 'SC_MAMMOCADSR',
		code: '1.2.840.10008.5.1.4.1.1.88.50',
		display: 'Mammography CAD SR',
	},
	{
		name: 'SC_SPECTACLEPRESCRIPTIONREPORT',
		code: '1.2.840.10008.5.1.4.1.1.78.6',
		display: 'Spectacle Prescription Report',
	},
	{
		name: 'SC_MACULARGRIDTHICKNESSANDVOLUMEREPORT',
		code: '1.2.840.10008.5.1.4.1.1.79.1',
		display: 'Macular Grid Thickness And Volume Report',
	},
	{
		name: 'SC_COMPREHENSIVE3DSR',
		code: '1.2.840.10008.5.1.4.1.1.88.34',
		display: 'Comprehensive 3D SR',
	},
	{
		name: 'SC_PROCEDURELOG',
		code: '1.2.840.10008.5.1.4.1.1.88.40',
		display: 'Procedure Log',
	},
	{
		name: 'SC_KEYOBJECTSELECTIONDOCUMENT',
		code: '1.2.840.10008.5.1.4.1.1.88.59',
		display: 'Key Object Selection Document',
	},
	{
		name: 'SC_CHESTCADSR',
		code: '1.2.840.10008.5.1.4.1.1.88.65',
		display: 'Chest CAD SR',
	},
	{
		name: 'SC_XRAYRADIATIONDOSESR',
		code: '1.2.840.10008.5.1.4.1.1.88.67',
		display: 'XRay Radiation Dose SR',
	},
	{
		name: 'SC_RADIOPHARMACEUTICALRADIATIONDOSESR',
		code: '1.2.840.10008.5.1.4.1.1.88.68',
		display: 'Radiopharmaceutical Radiation Dose SR',
	},
	{
		name: 'SC_COLONCADSR',
		code: '1.2.840.10008.5.1.4.1.1.88.69',
		display: 'Colon CAD SR',
	},
	{
		name: 'SC_IMPLANTATIONPLANSRDOCUMENT',
		code: '1.2.840.10008.5.1.4.1.1.88.70',
		display: 'Implantation Plan SR Document',
	},
	{
		name: 'SC_ACQUISITIONCONTEXTSR',
		code: '1.2.840.10008.5.1.4.1.1.88.71',
		display: 'Acquisition Context SR',
	},
	{
		name: 'SC_SIMPLIFIEDADULTECHOSR',
		code: '1.2.840.10008.5.1.4.1.1.88.72',
		display: 'Simplified Adult Echo SR',
	},
	{
		name: 'SC_PATIENTRADIATIONDOSESR',
		code: '1.2.840.10008.5.1.4.1.1.88.73',
		display: 'Patient Radiation Dose SR',
	},
	{
		name: 'SC_PLANNEDIMAGINGAGENTADMINISTRATIONSR',
		code: '1.2.840.10008.5.1.4.1.1.88.74',
		display: 'Planned Imaging Agent Administration SR',
	},
	{
		name: 'SC_PERFORMEDIMAGINGAGENTADMINISTRATIONSR',
		code: '1.2.840.10008.5.1.4.1.1.88.75',
		display: 'Performed Imaging Agent Administration SR',
	},
	{
		name: 'SC_GSPS',
		code: '1.2.840.10008.5.1.4.1.1.11.1',
		display: 'Grayscale Softcopy Presentation State Storage',
	},
];

const IsXRay = (sopclass) => {
	return (
		sopclass ==
		SOPClass.find(
			(s) =>
				s.name == 'SC_CR' ||
				s.name == 'SC_DXPRES' ||
				s.name == 'SC_DXPROC' ||
				s.name == 'SC_MGPRES' ||
				s.name == 'SC_MGPROC' ||
				s.name == 'SC_INTRAORALXRAYPRES' ||
				s.name == 'SC_INTRAORALXRAYPROC' ||
				s.name == 'SC_MGTOMO' ||
				s.name == 'SC_RF'
		)
	);
};

const IsUltrasound = (sopclass) => {
	return (
		sopclass ==
		SOPClass.find(
			(s) =>
				s.name == 'SC_US' ||
				s.name == 'SC_USRETIRED' ||
				s.name == 'SC_USMULTIRETIRED' ||
				s.name == 'SC_USMULTI'
		)
	);
};

const IsSR = (sopclass) => {
	return (
		sopclass ==
		SOPClass.find(
			(s) =>
				s.name == 'SC_BASICTEXTSR' ||
				s.name == 'SC_COMPREHENSIVESR' ||
				s.name == 'SC_ENHANCEDSR' ||
				s.name == 'SC_SPECTACLEPRESCRIPTIONREPORT' ||
				s.name == 'SC_MACULARGRIDTHICKNESSANDVOLUMEREPORT' ||
				s.name == 'SC_COMPREHENSIVE3DSR' ||
				s.name == 'SC_PROCEDURELOG' ||
				s.name == 'SC_KEYOBJECTSELECTIONDOCUMENT' ||
				s.name == 'SC_CHESTCADSR' ||
				s.name == 'SC_XRAYRADIATIONDOSESR' ||
				s.name == 'SC_RADIOPHARMACEUTICALRADIATIONDOSESR' ||
				s.name == 'SC_COLONCADSR' ||
				s.name == 'SC_IMPLANTATIONPLANSRDOCUMENT' ||
				s.name == 'SC_ACQUISITIONCONTEXTSR' ||
				s.name == 'SC_SIMPLIFIEDADULTECHOSR' ||
				s.name == 'SC_PATIENTRADIATIONDOSESR' ||
				s.name == 'SC_PLANNEDIMAGINGAGENTADMINISTRATIONSR' ||
				s.name == 'SC_PERFORMEDIMAGINGAGENTADMINISTRATIONSR'
		)
	);
};

export { SOPClass, IsXRay, IsUltrasound, IsSR };
