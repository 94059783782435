import React from 'react';
import { AddNewBtn, HamburgerBtn } from '../SideBarButton';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useAppModeContext } from '@worklist-2/core/src/context/AppModeContext';

const SideBarHeader = ({ className, handleDrawerClick, onAddClick, hideAddNew }) => {
	const { appMode } = useAppModeContext();

	return (
		<div className={classnames('sidebar-header', className)}>
			<div>
				<HamburgerBtn onClick={handleDrawerClick} />
			</div>
			{(appMode === 'worklist' || appMode === 'marketplace' || appMode === 'helpCenter') && !hideAddNew ? (
				<div style={{ marginTop: '45px' }}>
					<AddNewBtn onClick={onAddClick} />
				</div>
			) : null}
		</div>
	);
};

SideBarHeader.propTypes = {
	handleDrawerClick: PropTypes.func,
};

export default SideBarHeader;
