import { useState, useEffect } from 'react';

export const useFirstLoadData = ({ setObjectives, objectives, okrDataLoader }) => {
	const [isFirstLoad, setIsFirstLoad] = useState(true);

	useEffect(() => {
		if (isFirstLoad && objectives?.length) {
			let copyObj;
			let newObjectives = [];
			objectives.map(async objective => {
				copyObj = {
					...objective,
					KeyResults: objective?.KeyResults?.map(item => ({
						...item,
						expanded: false,
					})),
				};

				newObjectives.push(copyObj);
			});

			setObjectives(newObjectives);
			setIsFirstLoad(false);
		}
	}, [isFirstLoad, objectives]);

	return !isFirstLoad;
};
