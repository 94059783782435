import React from 'react';
import ObjectiveProgressContainer from './ObjectiveProgressContainer';
import ProgressBarHeader from './ProgressBarHeader';
import { useCrmContext } from '../../../../../context/CRMContext';
import { QuarterMonth } from '../../../Cases/utils';

function ProgressContainer({ objectives, onSetObjectiveVariables, onSetKeyResultVariable, onSetIdeaVariable }) {
	const { quarterGB } = useCrmContext();
	const months = QuarterMonth[quarterGB.split(' - ')[0]];

	return (
		<div
			style={{
				flex: 1,
				borderLeft: '1px solid #c4c4c488',
				paddingLeft: '10px',
			}}
		>
			<div style={{ height: '80px' }}>
				<ProgressBarHeader months={months} />
			</div>
			{(objectives || []).map((objective, index) => (
				<ObjectiveProgressContainer
					key={index}
					objective={objective}
					onSetIdeaVariable={onSetIdeaVariable}
					onSetKeyResultVariable={onSetKeyResultVariable}
					onSetObjectiveVariables={onSetObjectiveVariables}
				/>
			))}
		</div>
	);
}

export default ProgressContainer;
