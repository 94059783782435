import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import QuarterCard from './QuarterCard';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/system';
import { crmSearchScopes, useCRMDataLoader, useAuth } from '@worklist-2/core/src';
import { useCrmContext } from '../../../../context/CRMContext';
import _ from 'lodash';
import ProgressContainer from './ProgressContainer';
import { DefaultDepartment, CompanyDepartment } from '../../Cases/utils';
import { useFirstLoadData } from '../useFirstLoadData.hook';

const DescriptionContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	width: '100%',
	flexDirection: 'column',
	[theme.breakpoints.up('md')]: {
		width: '35%',
	},
}));

function DescriptionBar({ setObjectState, objectState }) {
	const params = useParams();
	const targetDeparment =
		_.find(DefaultDepartment, item => item.Department === params.department) || CompanyDepartment;
	const [objectives, setObjectives] = useState([]);
	const [hoveredIndex, setHoveredIndex] = useState(-1);
	const { setQuarterGB, setSelectedObjective } = useCrmContext();
	const [users, setUsers] = useState([]);
	const okrDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.okrApi,
	});
	const userDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.userApi,
	});
	const quarterCardRef = useRef();
	const { loggedInUser } = useAuth();

	useEffect(() => {
		setObjectState({ objectives });
	}, [objectives]);

	const handleResultAdd = useCallback(
		data => {
			const findAndEdit = objectives.map(objective => {
				if (objective.id === data.id) {
					let priority = 'P1';
					if (objective.KeyResults && objective.KeyResults.length > 0) {
						let maxPriority = objective.KeyResults[objective.KeyResults.length - 1].KeyResultNumber;
						maxPriority = maxPriority.replace('P', '');
						priority = `P${parseInt(maxPriority) + 1}`;
					}
					objective.KeyResults = [
						...(objective.KeyResults || []),
						{
							KeyResult: '',
							Status: '',
							Owner: '',
							KeyResultNumber: priority,
						},
					];
				}
				return objective;
			});
			setObjectives(findAndEdit);
		},
		[objectives]
	);

	const handleAddIdea = (index, data, objectiveData) => {
		const findAndEdit = objectives.map(objective => {
			if (objective.id === objectiveData.id) {
				objective.KeyResults[index] = data;
			}

			return objective;
		});

		setObjectives(findAndEdit);
	};

	const handleEditObjective = (value, objectiveData) => {
		const findAndEdit = objectives.map(objective => {
			if (objective.id === objectiveData.id) {
				objective.Objective = value;
			}

			return objective;
		});

		setObjectives(findAndEdit);
	};

	const handleEditStatus = (value, objectiveData) => {
		const findAndEdit = objectives.map(objective => {
			if (objective.id === objectiveData.id) {
				objective.Status = value;
			}

			return objective;
		});

		updateData(objectiveData, objectiveData.id);

		setObjectives(findAndEdit);
	};

	const handleEditActive = objectiveData => {
		const findAndEdit = objectives.map(objective => {
			if (objective.id === objectiveData.id) {
				objective.IsActive = !objective.IsActive;
			}

			return objective;
		});

		updateData(objectiveData, objectiveData.id);

		setObjectives(findAndEdit);
	};

	const handleEditKeyStatus = (keyResultIndex, value, objectiveData) => {
		const findAndEdit = objectives.map(objective => {
			if (objective.id === objectiveData.id) {
				objective.KeyResults[keyResultIndex].Status = value;
			}

			return objective;
		});

		updateData(objectiveData, objectiveData.id);
		setObjectives(findAndEdit);
	};

	const handleEditKeyResultUser = (keyResultIndex, value, objectiveData) => {
		const findAndEdit = objectives.map(objective => {
			if (objective.id === objectiveData.id) {
				objective.KeyResults[keyResultIndex].Owner = value;
			}

			return objective;
		});

		updateData(objectiveData, objectiveData.id);
		setObjectives(findAndEdit);
	};

	const handleEditIdeaStatus = (keyResultIndex, ideaIndex, value, objectiveData) => {
		objectiveData.KeyResults[keyResultIndex].Ideas[ideaIndex].Status = value;

		updateData(objectiveData, objectiveData.id);
	};

	const handleEditIdea = (keyResultIndex, ideaIndex, value, objectiveData, isRemove = false) => {
		const findAndEdit = objectives.map(objective => {
			if (objective.id === objectiveData.id) {
				if (isRemove) {
					objective?.KeyResults[keyResultIndex]?.Ideas?.splice(ideaIndex, 1);
					updateData(objective, objective.id);
				} else {
					objective.KeyResults[keyResultIndex].Ideas[ideaIndex].Idea = value;
				}
			}

			return objective;
		});

		setObjectives(findAndEdit);
	};

	const handleEditIdeaOwner = (keyResultIndex, ideaIndex, value, objectiveData) => {
		const findAndEdit = objectives.map(objective => {
			if (objective.id === objectiveData.id) {
				objective.KeyResults[keyResultIndex].Ideas[ideaIndex].Owner = value;
			}

			return objective;
		});

		setObjectives(findAndEdit);
		updateData(objectiveData, objectiveData.id);
	};

	const handleEditKeyResult = (keyResultIndex, value, objectiveData, isRemove = false) => {
		const findAndEdit = objectives.map(objective => {
			if (objective.id === objectiveData.id) {
				if (isRemove) {
					objective?.KeyResults?.splice(keyResultIndex, 1);
					objective.KeyResults = _.map(objective.KeyResults, (item, index) => {
						const updateItem = item;
						updateItem.KeyResultNumber = `P${index + 1}`;
						return updateItem;
					});
					updateData(objective, objective.id);
				} else {
					objective.KeyResults[keyResultIndex].KeyResult = value;
				}
			}

			return objective;
		});

		setObjectives(findAndEdit);
	};

	const handleAddEmptyContainer = () => {
		setObjectives([...objectives, { Objective: '' }]);
	};

	const handleEditObjectiveUser = (value, objectiveData) => {
		const findAndEdit = objectives.map(objective => {
			if (objective.id === objectiveData.id) {
				objective.Owner = value;
			}

			return objective;
		});

		updateData(objectiveData, objectiveData.id);

		setObjectives(findAndEdit);
	};

	const promiseGetUser = () => {
		if (loggedInUser.permission?.User.Read) {
			return Promise.resolve(
				userDataLoader.load({ count: 1000, summary: true }).then(result =>
					_.filter(
						_.map(result, d => ({
							username: d.UserName,
							key: d.UserId,
						})),
						item => item.key && item.username && item.username !== 'unknown'
					)
				)
			);
		}
		return Promise.resolve([]);
	};

	const fetchData = async () => {
		setQuarterGB(params.quarter);

		Promise.resolve({});

		Promise.all([
			promiseGetUser(),
			okrDataLoader.load({
				Department: targetDeparment.Department,
				Quarter: params.quarter,
				sort: 'ordernum',
			}),
		]).then(values => {
			setUsers(values[0]);
			setObjectives(values[1]);
		});
	};

	useEffect(() => {
		async function asynchronousEffect() {
			await fetchData();
			setSelectedObjective(null);
		}

		asynchronousEffect();
	}, [params]);

	const createData = async (objective, index) => {
		const payload = buildObjectiveJson(objective, index);
		const res = await okrDataLoader.save(null, payload);

		if (res) {
			objectives.splice(index, 1, res.data);
			setObjectives(objectives);
		}
	};

	const updateData = async (objective, objectiveId) => {
		let payload = '';

		if (typeof objective === 'object') {
			payload = objective;
		}

		const refinedObjective = {
			...objective,
			KeyResults: payload.KeyResults?.map(keyResult => ({
				...keyResult,
				hovered: undefined,
				expanded: undefined,
			})),
			hovered: undefined,
			expanded: undefined,
		};

		const data = await okrDataLoader.update(objectiveId, refinedObjective);

		const augmentedObjective = {
			...data,
			...objective,
		};

		if (data) {
			objectives.splice(
				objectives.findIndex(obj => obj.id === data.id),
				1,
				augmentedObjective
			);
			setObjectives(objectives);
			setSelectedObjective(data);
		}
	};

	const buildObjectiveJson = (text, index) => {
		let orderNum = 1;
		const originData = _.filter(objectives, item => !!item.id);
		if (originData && originData.length > 0) {
			const maxOrderNum = originData[originData.length - 1].OrderNum;
			orderNum = maxOrderNum + 1;
		}

		return {
			ResourceType: 'OKR',
			Objective: text,
			Department: targetDeparment.Department,
			Status: objectives[index].Status,
			Owner: objectives[index].Owner,
			Quarter: params.quarter,
			OrderNum: orderNum,
		};
	};

	const deleteObjective = (data, index) => {
		objectives.splice(index, 1);

		if (data.id) {
			const tempObjective = _.map(objectives, (item, index) => {
				item.OrderNum = index + 1;
				return item;
			});

			setObjectives(tempObjective);
			_.map(tempObjective, item => {
				okrDataLoader.patch(item.id, 'OrderNum', item.OrderNum, 'replace');
			});
			okrDataLoader.delete({ id: data.id });
		}
	};

	const handleSetObjectiveVariable = (objectiveData, variable, value) => {
		const findAndEdit = objectives.map(objective => {
			if (objective.id === objectiveData.id) {
				objective[variable] = value;
				if (variable === 'expanded' && !objective[variable]) {
					objective.KeyResults = objective.KeyResults?.map(item => ({
						...item,
						expanded: value,
					}));
				}
			}

			return objective;
		});

		setObjectives(findAndEdit);
	};

	const handleSetKeyResultVariable = (objectiveData, keyResultIndex, variable, value) => {
		const findAndEdit = objectives.map(objective => {
			if (objective.id === objectiveData.id) {
				if (objective.KeyResults[keyResultIndex]) {
					objective.KeyResults[keyResultIndex][variable] = value;
				}
			}

			return objective;
		});

		setObjectives(findAndEdit);
	};

	const handleSetIdeaVariable = (objectiveData, keyResultIndex, ideaIndex, variable, value) => {
		const findAndEdit = objectives.map(objective => {
			if (objective.id === objectiveData.id) {
				objective.KeyResults[keyResultIndex].Ideas[ideaIndex][variable] = value;
			}

			return objective;
		});

		setObjectives(findAndEdit);
	};

	const isDataLoaded = useFirstLoadData({
		setObjectives,
		objectives,
		okrDataLoader,
	});
	const isProgressContainerVisible = isDataLoaded && objectives && objectives.length;

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
			}}
		>
			<DescriptionContainer>
				<h2
					style={{
						fontWeight: '500',
						fontSize: '14px',
						color: 'rgba(0, 0, 0, 0.6)',
						marginTop: '50px',
					}}
				>
					Quarters
				</h2>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
					}}
				>
					{!!isProgressContainerVisible &&
						objectives.map((data, index) => (
							<div key={index} ref={quarterCardRef} id={index}>
								<QuarterCard
									key={index}
									ObjectiveIndex={index}
									cardData={data.KeyResults}
									clearObject={() => setObjectState(null)}
									createData={createData}
									deleteObjective={() => deleteObjective(data, index)}
									handleResultAdd={objectiveData => handleResultAdd(objectiveData)}
									index={index}
									isActive={index === hoveredIndex}
									isObjectiveActive={data.IsActive}
									objectState={objectState}
									objective={data.Objective}
									objectiveData={data}
									objectives={objectives}
									owner={data.Owner?.split(' ')
										.map(n => n.charAt(0).toUpperCase())
										.join('')}
									priority={data.OrderNum ? `P${data.OrderNum}` : data.OrderNum}
									setObjectState={state =>
										setObjectState({
											[`object${index}`]: state,
										})
									}
									setObjectives={setObjectives}
									status={data.Status}
									updateData={updateData}
									users={users}
									onAddIdea={handleAddIdea}
									onEditActive={handleEditActive}
									onEditIdea={handleEditIdea}
									onEditIdeaOwner={handleEditIdeaOwner}
									onEditIdeaStatus={handleEditIdeaStatus}
									onEditKeyResult={handleEditKeyResult}
									onEditKeyResultUser={handleEditKeyResultUser}
									onEditKeyStatus={handleEditKeyStatus}
									onEditObjective={handleEditObjective}
									onEditObjectiveUser={handleEditObjectiveUser}
									onEditStatus={handleEditStatus}
									onMouseLeave={() => {
										setHoveredIndex(-1);
										handleSetObjectiveVariable(data, 'hovered', false);
									}}
									onMouseOver={() => {
										setHoveredIndex(index);
										handleSetObjectiveVariable(data, 'hovered', true);
									}}
									onSetKeyResultVariable={handleSetKeyResultVariable}
									onSetObjectiveVariable={handleSetObjectiveVariable}
								/>
							</div>
						))}
				</div>

				{!!loggedInUser?.permission?.OKR?.Add && (
					<div style={{ width: '100%' }}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								color: 'rgba(0, 0, 0, 0.4)',
								fontWeight: '400',
								cursor: 'pointer',
								marginBottom: '30px',
							}}
							onClick={handleAddEmptyContainer}
						>
							<AddIcon fontSize="12px" sx={{ marginRight: '5px' }} />
							<span style={{ fontSize: '14px' }}>New Objective</span>
						</div>
					</div>
				)}
			</DescriptionContainer>
			<ProgressContainer
				objectives={objectives}
				onSetIdeaVariable={handleSetIdeaVariable}
				onSetKeyResultVariable={handleSetKeyResultVariable}
				onSetObjectiveVariable={handleSetObjectiveVariable}
			/>
		</div>
	);
}

export default DescriptionBar;
