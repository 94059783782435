import React from 'react';

const NotificationsIcon = () => (
	<svg fill="none" height="64" viewBox="0 0 85 64" width="85" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_46031)">
			<path
				d="M77.2142 45.7243C77.0428 45.9614 76.8614 46.1957 76.6814 46.4242C76.6725 46.437 76.6619 46.4486 76.65 46.4585C74.9959 48.5497 73.0419 50.3494 70.9364 51.9792C70.3722 52.4149 69.8052 52.8362 69.2224 53.2547C68.3068 53.9147 67.3655 54.5489 66.4271 55.1659C65.4272 55.8258 64.4073 56.4686 63.3946 57.1057C61.242 58.4626 59.0809 59.8196 56.7398 60.7994C52.0047 62.7849 46.7711 63.1363 41.6518 63.4619C39.5264 63.6048 37.3781 63.7348 35.2784 63.3648C31.7532 62.7535 28.6107 60.7723 25.1812 59.751C21.2217 58.5683 16.7937 58.6297 13.0357 57.0971C12.1581 56.7475 11.3264 56.2923 10.5589 55.7416C8.70197 54.4089 7.34073 52.5063 6.20945 50.518C2.92132 44.7516 1.39867 37.8725 2.52994 31.322C3.66122 24.7714 7.57784 18.6423 13.3313 15.3856C17.4022 13.083 22.2316 12.2289 26.0339 9.49636C29.0535 7.32523 31.2404 4.18279 34.6171 2.50587C44.9771 -2.65629 57.5968 0.521856 65.5015 8.69933C66.8041 10.0506 68.0054 11.5204 69.5009 12.6517C71.6706 14.2886 74.3574 15.1299 76.5214 16.7711C80.2709 19.6107 81.9921 24.5186 82.2078 29.2408C82.4578 35.1423 80.6973 40.9538 77.2142 45.7243Z"
				fill="#4BAAF6"
				opacity="0.24"
			/>
			<path
				d="M66.4337 55.1656C65.4338 55.8255 64.4139 56.4683 63.4012 57.1054C61.2487 58.4623 59.0875 59.8193 56.7464 60.7992C52.0113 62.7846 46.7778 63.136 41.6584 63.4617C39.533 63.6045 37.3847 63.7345 35.285 63.3645C31.7598 62.7532 28.6173 60.772 25.1878 59.7507C21.2212 58.568 16.7932 58.6295 13.0352 57.0968C13.7875 56.7226 14.6245 56.5512 15.4634 56.5997C21.7797 56.9654 28.0117 56.1112 34.2794 55.4684C37.7075 55.1171 41.1599 54.8442 44.6095 54.9185C47.1277 54.9757 49.6417 55.2185 52.1542 55.347C56.905 55.587 61.6715 55.4027 66.4337 55.1656Z"
				fill="#4BAAF6"
			/>
			<path
				d="M30.9711 56.9217C30.7011 56.6246 30.4154 56.2533 30.5126 55.8633C30.634 55.3791 31.2396 55.2305 31.7367 55.1734C34.7429 54.8268 37.7573 54.5511 40.7797 54.3464C43.4494 54.165 46.2519 54.0607 48.6672 55.2134C47.9645 57.0889 46.6104 58.6415 45.2848 60.137L43.0751 62.6424C42.8807 62.8977 42.6331 63.1077 42.3495 63.258C42.0553 63.3866 41.6696 63.3609 41.4682 63.1152C41.3054 62.9124 41.304 62.6281 41.3111 62.3681C41.3378 61.3397 41.3654 60.3113 41.3939 59.2828C40.287 59.2928 39.6056 60.5255 39.4428 61.6211C39.3756 62.071 39.3428 62.5553 39.0671 62.9166C37.933 64.395 31.8709 57.913 30.9711 56.9217Z"
				fill="url(#paint0_linear_2852_46031)"
			/>
			<path
				d="M76.6535 26.375V46.458C74.5552 49.1091 71.9799 51.2873 69.2259 53.2542L68.2975 52.3386H14.8361C11.6094 52.3386 8.99121 49.7675 8.99121 46.5823V26.375C8.99121 23.1968 11.6094 20.6172 14.8361 20.6172H70.8129C74.0396 20.6129 76.6535 23.1926 76.6535 26.375Z"
				fill="url(#paint1_linear_2852_46031)"
			/>
			<path
				d="M77.2751 24.0181V44.2325C77.2763 44.5021 77.2572 44.7714 77.2179 45.0381V45.7294C77.0465 45.9666 76.8651 46.2008 76.6852 46.4294C76.6763 46.4422 76.6657 46.4537 76.6537 46.4636C74.9997 48.5548 73.0456 50.3545 70.9402 51.9843L68.9148 49.9846H15.452C12.2295 49.9846 9.61133 47.4049 9.61133 44.2268V24.0181C9.61133 20.8399 12.2295 18.2617 15.452 18.2617H71.4344C74.6569 18.2617 77.2751 20.8399 77.2751 24.0181Z"
				fill="#4BAAF6"
			/>
			<path
				d="M77.2751 24.0181V44.2325C77.2763 44.5021 77.2572 44.7714 77.2179 45.0381V45.7294C77.0465 45.9666 76.8651 46.2008 76.6852 46.4294C76.6763 46.4422 76.6657 46.4537 76.6537 46.4636C74.9997 48.5548 73.0456 50.3545 70.9402 51.9843L68.9148 49.9846H15.452C12.2295 49.9846 9.61133 47.4049 9.61133 44.2268V24.0181C9.61133 20.8399 12.2295 18.2617 15.452 18.2617H71.4344C74.6569 18.2617 77.2751 20.8399 77.2751 24.0181Z"
				fill="url(#paint2_linear_2852_46031)"
			/>
			<g opacity="0.24">
				<path
					d="M27.9346 23.9834C27.8632 24.222 27.6489 24.9547 27.5875 25.219L26.8733 27.8829C26.8404 28 26.8241 28.1212 26.8248 28.2429C26.8207 28.2842 26.8261 28.326 26.8405 28.365C26.8549 28.4041 26.8779 28.4393 26.9079 28.4682C26.9378 28.497 26.9739 28.5187 27.0135 28.5317C27.053 28.5446 27.0949 28.5484 27.1361 28.5428C27.4154 28.5221 27.6807 28.4124 27.8932 28.23C28.4802 27.7743 28.8645 26.8873 28.8645 25.9632C28.8772 25.4472 28.7569 24.9367 28.5153 24.4807C28.2736 24.0246 27.9187 23.6384 27.4847 23.3592C26.9447 23.035 26.1891 22.855 25.3421 22.855C22.811 22.855 21.0341 24.5348 21.0341 26.9102C21.0341 29.2142 22.631 30.7268 25.0421 30.7268C25.6639 30.7268 26.2825 30.6378 26.879 30.4626C27.354 30.3141 27.8059 30.1002 28.2217 29.8269L28.9545 30.8825C28.306 31.2667 28.0189 31.411 27.4547 31.5795C26.6304 31.8329 25.773 31.9619 24.9107 31.9624C23.2181 31.9624 21.8983 31.4824 20.9498 30.534C20.05 29.6455 19.5586 28.3728 19.5586 26.9816C19.5586 25.4932 20.0628 24.2091 21.0227 23.2364C22.1268 22.1337 23.5781 21.5938 25.4378 21.5938C28.2346 21.5938 30.2272 23.3935 30.2272 25.9017C30.2366 26.4352 30.1405 26.9652 29.9444 27.4613C29.7483 27.9575 29.456 28.41 29.0845 28.7928C28.4951 29.4034 27.6888 29.757 26.8405 29.7769C26.3734 29.7769 25.9834 29.597 25.8092 29.3099C25.7449 29.1955 25.7078 29.068 25.7006 28.937C25.245 29.4655 24.765 29.6941 24.0923 29.6941C22.9053 29.6941 22.1482 28.8056 22.1482 27.4372C22.1482 25.4089 23.4809 23.7235 25.0764 23.7235C25.7492 23.7235 26.0849 23.892 26.4091 24.3948L26.5291 23.9763L27.9346 23.9834ZM25.4635 27.5472C25.7517 26.9722 25.9125 26.3417 25.9349 25.6989C25.9349 25.2061 25.6578 24.8705 25.2378 24.8705C24.7822 24.8705 24.3622 25.1704 24.0623 25.7275C23.7682 26.2833 23.6041 26.8987 23.5823 27.5272C23.5823 28.1643 23.8223 28.5 24.278 28.5C24.7336 28.5 25.1464 28.1586 25.4592 27.5472H25.4635Z"
					fill="#4BAAF6"
				/>
			</g>
			<g opacity="0.24">
				<path
					d="M33.8758 29.5715C33.8122 29.2426 33.7165 28.9206 33.5902 28.6102L32.9917 26.8819C32.9917 26.8505 32.7903 26.2463 32.7717 26.1948C32.7003 25.9606 32.7003 25.9606 32.6674 25.8692C32.706 26.2791 32.7331 26.8105 32.7331 27.1547V28.5645C32.7265 28.8986 32.7437 29.2327 32.7846 29.5644H31.499C31.5391 29.2327 31.5563 28.8986 31.5504 28.5645V25.1807C31.5527 24.8424 31.5355 24.5043 31.499 24.168H33.2131C33.2656 24.4804 33.3484 24.7871 33.4602 25.0836L34.4343 27.8832L35.4028 25.0836C35.5147 24.7876 35.5952 24.4808 35.6428 24.168H37.3568C37.3166 24.504 37.2989 24.8423 37.304 25.1807V28.5645C37.2958 28.8987 37.3134 29.233 37.3568 29.5644H36.0713C36.1123 29.2327 36.13 28.8986 36.1241 28.5645V27.149C36.1241 26.9933 36.1427 26.5062 36.1555 26.3048C36.1755 25.9606 36.1755 25.9606 36.1827 25.8635C36.0398 26.3691 35.9741 26.6034 35.897 26.8362L35.2671 28.6159C35.1451 28.9258 35.0495 29.2455 34.9814 29.5715H33.8758Z"
					fill="#4BAAF6"
				/>
				<path
					d="M42.2637 29.6136C41.9079 29.5766 41.5501 29.5618 41.1924 29.5693H39.1456C38.6528 29.5693 38.4699 29.5693 38.1914 29.5879C38.2333 29.274 38.2505 28.9574 38.2428 28.6409V25.0913C38.2473 24.7744 38.2301 24.4575 38.1914 24.1429C38.4571 24.1558 38.6399 24.1629 39.1456 24.1629H41.2196C41.5425 24.1675 41.8655 24.1522 42.1866 24.1172V25.3313C41.8654 25.297 41.5425 25.2817 41.2196 25.2856H39.5298V26.2412H40.9339C41.2592 26.2461 41.5846 26.2309 41.9081 26.1955V27.3967C41.5846 27.3616 41.2592 27.3463 40.9339 27.351H39.5312V28.4423H41.2053C41.5587 28.4486 41.9122 28.4333 42.2637 28.3966V29.6136Z"
					fill="#4BAAF6"
				/>
				<path
					d="M46.0317 26.8233C46.2006 27.0727 46.3565 27.3306 46.4988 27.5961C46.4731 27.2975 46.4603 26.999 46.4603 26.6362V25.155C46.466 24.8252 46.4488 24.4954 46.4088 24.168H47.7901C47.7501 24.4954 47.7329 24.8252 47.7387 25.155V28.6031C47.7359 28.9266 47.7531 29.2501 47.7901 29.5715H46.3617C46.219 29.2852 46.0562 29.0093 45.8746 28.7459L44.6734 26.9347C44.5062 26.6778 44.3545 26.4112 44.2191 26.1363C44.2514 26.4576 44.2667 26.7804 44.2648 27.1033V28.5574C44.2581 28.8962 44.2753 29.2351 44.3163 29.5715H42.9336C42.9763 29.2333 42.9935 28.8924 42.985 28.5517V25.135C42.9893 24.8119 42.9721 24.4888 42.9336 24.168H44.342C44.4666 24.454 44.6212 24.726 44.8033 24.9793L46.0317 26.8233Z"
					fill="#4BAAF6"
				/>
				<path
					d="M51.378 28.5891C51.3733 28.917 51.3909 29.2448 51.4308 29.5704H49.9953C50.0345 29.2448 50.0517 28.9169 50.0467 28.5891V25.3166H49.2811C48.9534 25.3131 48.6258 25.3283 48.2998 25.3624V24.1211C48.6216 24.1538 48.9448 24.1691 49.2682 24.1668H52.1121C52.4503 24.1717 52.7885 24.1565 53.1249 24.1211V25.3624C52.7885 25.3265 52.4503 25.3112 52.1121 25.3166H51.378V28.5891Z"
					fill="#4BAAF6"
				/>
				<path
					d="M55.0563 24.168C55.0137 24.4865 54.9966 24.808 55.0049 25.1293V28.6102C54.9977 28.9315 55.0148 29.2529 55.0563 29.5715H53.6279C53.6696 29.2529 53.6868 28.9315 53.6794 28.6102V25.1293C53.6874 24.808 53.6702 24.4866 53.6279 24.168H55.0563Z"
					fill="#4BAAF6"
				/>
				<path
					d="M60.9409 26.8415C60.9409 28.5555 59.8953 29.6982 58.317 29.6982C56.7386 29.6982 55.7002 28.5884 55.7002 26.8672C55.7002 25.146 56.7258 24.0547 58.3227 24.0547C59.9196 24.0547 60.9409 25.1403 60.9409 26.8415ZM59.5897 26.8543C59.5897 25.803 59.1097 25.166 58.3227 25.166C57.5357 25.166 57.0443 25.8087 57.0443 26.8543C57.0443 27.8999 57.5314 28.5684 58.3227 28.5684C59.114 28.5684 59.5897 27.9199 59.5897 26.8543Z"
					fill="#4BAAF6"
				/>
				<path
					d="M64.6957 26.8233C64.8842 27.109 65.0071 27.3104 65.1642 27.5961C65.137 27.2975 65.1242 26.999 65.1242 26.6362V25.155C65.1299 24.8252 65.1127 24.4954 65.0728 24.168H66.4554C66.4154 24.4954 66.3983 24.8252 66.404 25.155V28.6031C66.402 28.9266 66.4192 29.25 66.4554 29.5715H65.0342C64.8903 29.2858 64.7276 29.01 64.5471 28.7459L63.3444 26.9347C63.1734 26.6778 63.0175 26.4112 62.8773 26.1363C62.9096 26.4576 62.9249 26.7804 62.923 27.1033V28.5574C62.9163 28.8962 62.9335 29.2351 62.9745 29.5715H61.5918C61.6345 29.2333 61.6517 28.8924 61.6432 28.5517V25.135C61.6475 24.8119 61.6303 24.4888 61.5918 24.168H63.0002C63.1248 24.454 63.2794 24.726 63.4615 24.9793L64.6957 26.8233Z"
					fill="#4BAAF6"
				/>
			</g>
			<path
				d="M66.8656 34.8672H22.0045C21.3197 34.8672 20.7646 35.422 20.7646 36.1063C20.7646 36.7907 21.3197 37.3454 22.0045 37.3454H66.8656C67.5503 37.3454 68.1054 36.7907 68.1054 36.1063C68.1054 35.422 67.5503 34.8672 66.8656 34.8672Z"
				fill="#4BAAF6"
				opacity="0.24"
			/>
			<path
				d="M66.8656 41.0625H22.0045C21.3197 41.0625 20.7646 41.6173 20.7646 42.3016C20.7646 42.986 21.3197 43.5407 22.0045 43.5407H66.8656C67.5503 43.5407 68.1054 42.986 68.1054 42.3016C68.1054 41.6173 67.5503 41.0625 66.8656 41.0625Z"
				fill="#4BAAF6"
				opacity="0.24"
			/>
			<path
				d="M46.9576 32.6117C46.9615 33.8522 46.8967 35.092 46.7634 36.3254C46.6491 37.5753 46.5391 38.8294 46.4248 40.0792C46.332 41.1148 46.2406 42.1489 45.9963 43.1559C45.7521 44.163 45.3936 45.1057 45.1607 46.1013C44.879 47.2839 44.7955 48.5051 44.9136 49.7151C44.9274 49.806 44.923 49.8987 44.9008 49.9879H38.5588C38.4159 47.1968 38.2859 44.3529 39.0244 41.6647C39.4529 40.0935 40.1828 38.5651 40.2442 36.9325C40.2928 35.6655 39.9357 34.3614 40.3185 33.1473C40.3599 32.9945 40.435 32.8529 40.5385 32.7331C40.6959 32.5912 40.8981 32.5088 41.1098 32.5002C41.4378 32.4792 41.7664 32.5307 42.0722 32.6509C42.3781 32.7711 42.6537 32.9572 42.8796 33.1959C43.3293 33.6791 43.6355 34.2781 43.7638 34.9256C43.8438 35.2784 43.9066 35.6741 44.1994 35.8898C44.7451 35.1756 44.7451 34.1957 44.8022 33.2973C44.893 31.9162 45.1816 30.5554 45.6592 29.2564C46.1135 28.028 46.5662 29.3092 46.6762 29.9077C46.8425 30.8 46.9366 31.7042 46.9576 32.6117Z"
				fill="url(#paint3_linear_2852_46031)"
			/>
			<path
				d="M66.8654 6.8281V13.083C66.8651 13.9292 66.5288 14.7406 65.9304 15.3389C65.3321 15.9373 64.5206 16.2736 63.6744 16.274H47.2838L44.727 20.9876L41.7274 16.274H26.1881C25.3419 16.2736 24.5305 15.9373 23.9321 15.3389C23.3338 14.7406 22.9974 13.9292 22.9971 13.083V6.8281C22.9967 5.98128 23.3326 5.16898 23.931 4.56979C24.5294 3.97059 25.3413 3.63357 26.1881 3.63281H63.6744C64.5213 3.63357 65.3331 3.97059 65.9315 4.56979C66.5299 5.16898 66.8658 5.98128 66.8654 6.8281Z"
				fill="#FBCA1B"
			/>
			<path
				d="M30.5958 14.6251C33.2882 14.6251 35.4708 12.4425 35.4708 9.75006C35.4708 7.05764 33.2882 4.875 30.5958 4.875C27.9033 4.875 25.7207 7.05764 25.7207 9.75006C25.7207 12.4425 27.9033 14.6251 30.5958 14.6251Z"
				fill="url(#paint4_linear_2852_46031)"
			/>
			<path
				d="M61.4841 6.52734H39.8599C39.0781 6.52734 38.4443 7.1611 38.4443 7.94287V8.08571C38.4443 8.86748 39.0781 9.50123 39.8599 9.50123H61.4841C62.2659 9.50123 62.8996 8.86748 62.8996 8.08571V7.94287C62.8996 7.1611 62.2659 6.52734 61.4841 6.52734Z"
				fill="url(#paint5_linear_2852_46031)"
			/>
			<path
				d="M61.5571 11.6289H39.4158C39.0734 11.6289 38.7959 11.9065 38.7959 12.2488C38.7959 12.5912 39.0734 12.8687 39.4158 12.8687H61.5571C61.8995 12.8687 62.177 12.5912 62.177 12.2488C62.177 11.9065 61.8995 11.6289 61.5571 11.6289Z"
				fill="url(#paint6_linear_2852_46031)"
			/>
			<path
				d="M38.2109 54.8711L38.2724 61.7345L39.1222 61.7944L39.7907 54.9925L38.2109 54.8711Z"
				fill="#FCBDBA"
			/>
			<path
				d="M41.6113 54.8711L41.6728 61.7345L42.5226 61.7944L43.1911 54.9925L41.6113 54.8711Z"
				fill="#FCBDBA"
			/>
			<path
				d="M44.1714 44.2544C44.0285 44.8872 43.8857 48.9552 43.0015 48.8666C42.1831 48.7824 39.2463 49.338 39.0021 48.998C38.9178 48.8881 38.3279 48.3867 37.7822 47.9025C37.4737 47.6254 37.1795 47.3568 37.0038 47.1683C36.5238 46.6569 36.4881 45.8599 36.6381 45.1686C36.7881 44.4772 37.0866 43.8259 37.1752 43.126C37.3551 41.6862 36.8666 39.4593 37.8351 38.1866C38.1338 37.7952 38.5529 37.5127 39.0278 37.3825C40.4633 36.9368 43.3586 37.7296 44.0771 39.068C44.9598 40.7277 44.5727 42.5161 44.1714 44.2544Z"
				fill="white"
			/>
			<path
				d="M37.9082 47.8281L43.7074 47.961C43.7074 47.961 43.9516 49.4608 43.8302 50.3435C43.7088 51.2262 43.6474 53.2602 43.6474 53.2602L43.3131 55.16L37.9082 55.2671C37.9082 55.2671 37.8467 49.3736 37.9082 47.8281Z"
				fill="#606161"
			/>
			<path
				d="M37.9082 47.8281L43.7074 47.961C43.7074 47.961 43.9516 49.4608 43.8302 50.3435C43.7088 51.2262 43.6474 53.2602 43.6474 53.2602L43.3131 55.16L37.9082 55.2671C37.9082 55.2671 37.8467 49.3736 37.9082 47.8281Z"
				fill="url(#paint7_linear_2852_46031)"
			/>
			<path
				d="M38.5157 61.299C38.4455 61.2957 38.3758 61.3121 38.3143 61.3462C38.2721 61.3821 38.2407 61.429 38.2233 61.4816C38.2059 61.5343 38.2033 61.5907 38.2158 61.6447C38.2329 61.7547 38.2786 61.859 38.2915 61.9689C38.3043 62.0789 38.2915 62.1761 38.3629 62.2432C38.4141 62.2826 38.4768 62.3042 38.5414 62.3046C38.6657 62.3175 38.7914 62.326 38.9171 62.3289C38.9659 62.3337 39.0151 62.3258 39.0599 62.306C39.1585 62.2532 39.1585 62.1161 39.1556 62.0061C39.1528 61.8961 39.2713 61.3919 39.2142 61.3147C39.1199 61.1919 38.6557 61.2919 38.5157 61.299Z"
				fill="#4BAAF6"
			/>
			<path
				d="M41.9172 61.299C41.847 61.2957 41.7773 61.3121 41.7158 61.3462C41.6736 61.3821 41.6422 61.429 41.6251 61.4817C41.6079 61.5344 41.6057 61.5908 41.6187 61.6447C41.6344 61.7547 41.6801 61.859 41.693 61.9689C41.7058 62.0789 41.693 62.1761 41.7644 62.2432C41.8156 62.2826 41.8783 62.3042 41.9429 62.3046C42.0672 62.3175 42.1929 62.326 42.3186 62.3289C42.3674 62.3337 42.4166 62.3258 42.4614 62.306C42.56 62.2532 42.56 62.1161 42.5571 62.0061C42.5543 61.8961 42.6728 61.3919 42.6157 61.3147C42.5214 61.1919 42.0572 61.2919 41.9172 61.299Z"
				fill="#4BAAF6"
			/>
			<path
				d="M38.6481 62.1694C38.5204 62.1353 38.3845 62.1528 38.2696 62.2179C38.2488 62.2362 38.2263 62.2524 38.2024 62.2665C38.172 62.2763 38.1402 62.2811 38.1082 62.2808C38.0406 62.2932 37.9779 62.3245 37.9274 62.371C37.8769 62.4175 37.8405 62.4773 37.8225 62.5436C37.7871 62.6788 37.7951 62.8217 37.8453 62.9521C37.8616 63.0119 37.8937 63.0662 37.9382 63.1092C37.9806 63.1406 38.0294 63.1626 38.081 63.1735C38.2967 63.2254 38.5166 63.2575 38.7381 63.2692C38.874 63.2946 39.0144 63.2686 39.1323 63.1964C39.1863 63.1394 39.228 63.072 39.2548 62.9983C39.2815 62.9246 39.2929 62.8461 39.288 62.7678C39.305 62.6471 39.3011 62.5244 39.2766 62.405C39.178 62.0408 38.9181 62.2051 38.6481 62.1694Z"
				fill="#606161"
			/>
			<path
				d="M42.0419 62.1692C41.9137 62.1354 41.7775 62.1528 41.6619 62.2178C41.6414 62.2363 41.6189 62.2526 41.5948 62.2663C41.5648 62.2762 41.5335 62.281 41.502 62.2806C41.4332 62.292 41.3691 62.3227 41.3172 62.3693C41.2653 62.4159 41.2278 62.4763 41.2091 62.5434C41.1735 62.6787 41.182 62.8218 41.2334 62.952C41.2497 63.0117 41.2817 63.066 41.3263 63.1091C41.3686 63.1407 41.4173 63.1627 41.4691 63.1734C41.6842 63.2253 41.9037 63.2573 42.1247 63.2691C42.2611 63.2945 42.402 63.2685 42.5204 63.1962C42.5744 63.1393 42.616 63.0719 42.6428 62.9982C42.6696 62.9244 42.6809 62.846 42.6761 62.7677C42.6916 62.6469 42.6878 62.5244 42.6647 62.4049C42.5718 62.0407 42.3133 62.2049 42.0419 62.1692Z"
				fill="#606161"
			/>
			<path
				d="M44.4141 28.9914H44.7983C44.9238 28.6884 44.9728 28.3593 44.9411 28.0329C44.9169 27.7858 44.8926 27.453 45.1283 27.373C45.2232 27.3495 45.3232 27.3565 45.4139 27.393C45.8339 27.5201 46.2624 27.7315 46.4709 28.1172C46.6795 28.5029 46.6138 29.0157 46.5452 29.4699C46.5301 29.6891 46.4473 29.8983 46.3081 30.0684C45.5139 30.8411 44.654 29.5813 44.4141 28.9914Z"
				fill="#FCBDBA"
			/>
			<path
				d="M44.1212 42.1698C44.976 40.8561 45.5863 39.3985 45.9223 37.8676C46.4308 35.545 46.2937 33.1282 46.6222 30.7728C46.666 30.5844 46.662 30.388 46.6108 30.2014C46.448 29.7615 45.8623 29.6987 45.3938 29.7087C45.2417 29.6997 45.0897 29.7281 44.951 29.7915C44.7196 29.9229 44.6454 30.22 44.5997 30.48C44.274 32.3583 44.2354 34.2923 43.7155 36.1278C43.5298 36.7877 43.2798 37.4419 43.2627 38.1275C43.2677 38.5908 43.3267 39.052 43.4384 39.5016C43.5812 40.203 43.7498 40.9428 44.244 41.4614"
				fill="white"
			/>
			<path
				d="M40.9903 32.4953C41.413 32.5706 41.8067 32.7607 42.1285 33.0449C42.4502 33.3291 42.6875 33.6964 42.8144 34.1065C43.0272 34.8307 42.8729 35.6063 42.9143 36.3591C42.9506 36.974 43.1182 37.5741 43.4057 38.1188C43.6011 38.4369 43.779 38.7655 43.9385 39.103C44.1216 39.5918 44.1414 40.1267 43.9952 40.6278C43.849 41.1288 43.5444 41.569 43.1272 41.8826C42.7187 42.1754 42.2287 42.3283 41.7788 42.554C41.1958 42.8474 40.682 43.2618 40.2718 43.7695C39.6077 43.7838 39.1291 43.1025 39.0149 42.4468C38.9006 41.7912 39.0406 41.1184 38.9692 40.4585C38.8435 39.3158 38.1036 38.1731 38.4949 37.0833C38.6649 36.6133 39.042 36.1962 39.0263 35.6949C38.9977 34.8307 38.1107 34.3236 38.6649 33.4095C39.0849 32.7296 40.2476 32.3768 40.9903 32.4953Z"
				fill="#606161"
			/>
			<path
				d="M40.9903 32.4953C41.413 32.5706 41.8067 32.7607 42.1285 33.0449C42.4502 33.3291 42.6875 33.6964 42.8144 34.1065C43.0272 34.8307 42.8729 35.6063 42.9143 36.3591C42.9506 36.974 43.1182 37.5741 43.4057 38.1188C43.6011 38.4369 43.779 38.7655 43.9385 39.103C44.1216 39.5918 44.1414 40.1267 43.9952 40.6278C43.849 41.1288 43.5444 41.569 43.1272 41.8826C42.7187 42.1754 42.2287 42.3283 41.7788 42.554C41.1958 42.8474 40.682 43.2618 40.2718 43.7695C39.6077 43.7838 39.1291 43.1025 39.0149 42.4468C38.9006 41.7912 39.0406 41.1184 38.9692 40.4585C38.8435 39.3158 38.1036 38.1731 38.4949 37.0833C38.6649 36.6133 39.042 36.1962 39.0263 35.6949C38.9977 34.8307 38.1107 34.3236 38.6649 33.4095C39.0849 32.7296 40.2476 32.3768 40.9903 32.4953Z"
				fill="url(#paint8_linear_2852_46031)"
			/>
			<path
				d="M38.2806 41.4742L37.9092 47.8291C37.9092 47.8291 37.8606 47.8591 37.7764 47.9033C37.4678 47.6262 37.1736 47.3577 36.9979 47.1691C36.518 46.6578 36.4823 45.8608 36.6322 45.1694C36.7822 44.4781 37.0807 43.8267 37.1693 43.1268C37.3493 41.687 36.8608 39.4602 37.8292 38.1875C38.0363 38.4232 38.3377 40.5843 38.2806 41.4742Z"
				fill="url(#paint9_linear_2852_46031)"
			/>
		</g>
		<defs>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint0_linear_2852_46031"
				x1="39.5813"
				x2="40.077"
				y1="55.7019"
				y2="104.447"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint1_linear_2852_46031"
				x1="43.178"
				x2="42.2296"
				y1="13.8367"
				y2="74.0329"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#4BAAF6" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint2_linear_2852_46031"
				x1="42.3569"
				x2="43.4096"
				y1="73.3343"
				y2="35.3608"
			>
				<stop stopColor="white" stopOpacity="0" />
				<stop offset="0.14" stopColor="white" stopOpacity="0.22" />
				<stop offset="0.3" stopColor="white" stopOpacity="0.45" />
				<stop offset="0.45" stopColor="white" stopOpacity="0.65" />
				<stop offset="0.6" stopColor="white" stopOpacity="0.8" />
				<stop offset="0.73" stopColor="white" stopOpacity="0.91" />
				<stop offset="0.85" stopColor="white" stopOpacity="0.98" />
				<stop offset="0.94" stopColor="white" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint3_linear_2852_46031"
				x1="42.831"
				x2="42.344"
				y1="58.0397"
				y2="-13.4821"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint4_linear_2852_46031"
				x1="30.2444"
				x2="31.4842"
				y1="-12.1127"
				y2="64.9712"
			>
				<stop stopColor="white" stopOpacity="0" />
				<stop offset="0.07" stopColor="white" stopOpacity="0.22" />
				<stop offset="0.16" stopColor="white" stopOpacity="0.45" />
				<stop offset="0.25" stopColor="white" stopOpacity="0.65" />
				<stop offset="0.33" stopColor="white" stopOpacity="0.8" />
				<stop offset="0.4" stopColor="white" stopOpacity="0.91" />
				<stop offset="0.46" stopColor="white" stopOpacity="0.98" />
				<stop offset="0.51" stopColor="white" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint5_linear_2852_46031"
				x1="50.3427"
				x2="51.5826"
				y1="-12.4372"
				y2="64.6452"
			>
				<stop stopColor="white" stopOpacity="0" />
				<stop offset="0.07" stopColor="white" stopOpacity="0.22" />
				<stop offset="0.16" stopColor="white" stopOpacity="0.45" />
				<stop offset="0.25" stopColor="white" stopOpacity="0.65" />
				<stop offset="0.33" stopColor="white" stopOpacity="0.8" />
				<stop offset="0.4" stopColor="white" stopOpacity="0.91" />
				<stop offset="0.46" stopColor="white" stopOpacity="0.98" />
				<stop offset="0.51" stopColor="white" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint6_linear_2852_46031"
				x1="50.0887"
				x2="51.3285"
				y1="-12.4321"
				y2="64.6503"
			>
				<stop stopColor="white" stopOpacity="0" />
				<stop offset="0.07" stopColor="white" stopOpacity="0.22" />
				<stop offset="0.16" stopColor="white" stopOpacity="0.45" />
				<stop offset="0.25" stopColor="white" stopOpacity="0.65" />
				<stop offset="0.33" stopColor="white" stopOpacity="0.8" />
				<stop offset="0.4" stopColor="white" stopOpacity="0.91" />
				<stop offset="0.46" stopColor="white" stopOpacity="0.98" />
				<stop offset="0.51" stopColor="white" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint7_linear_2852_46031"
				x1="38.8809"
				x2="46.5641"
				y1="53.0931"
				y2="45.9884"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint8_linear_2852_46031"
				x1="38.3821"
				x2="44.0956"
				y1="38.1203"
				y2="38.1203"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint9_linear_2852_46031"
				x1="36.8294"
				x2="46.6609"
				y1="42.7312"
				y2="45.9122"
			>
				<stop stopColor="#010101" stopOpacity="0" />
				<stop offset="0.95" stopColor="#010101" />
			</linearGradient>
			<clipPath id="clip0_2852_46031">
				<rect fill="white" height="63.6043" width="84.39" />
			</clipPath>
		</defs>
	</svg>
);

export default NotificationsIcon;
