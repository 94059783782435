import React from 'react';

const BulletListIcon = ({ fill }) => (
	<svg fill="none" height="25" viewBox="0 0 25 25" width="25" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_31936)">
			<path
				d="M3.16064 13.0443H5.16761V11.0374H3.16064V13.0443ZM3.16064 17.0583H5.16761V15.0513H3.16064V17.0583ZM3.16064 9.03041H5.16761V7.02344H3.16064V9.03041ZM7.17458 13.0443H21.2234V11.0374H7.17458V13.0443ZM7.17458 17.0583H21.2234V15.0513H7.17458V17.0583ZM7.17458 7.02344V9.03041H21.2234V7.02344H7.17458ZM3.16064 13.0443H5.16761V11.0374H3.16064V13.0443ZM3.16064 17.0583H5.16761V15.0513H3.16064V17.0583ZM3.16064 9.03041H5.16761V7.02344H3.16064V9.03041ZM7.17458 13.0443H21.2234V11.0374H7.17458V13.0443ZM7.17458 17.0583H21.2234V15.0513H7.17458V17.0583ZM7.17458 7.02344V9.03041H21.2234V7.02344H7.17458Z"
				fill={fill}
			/>
		</g>
		<defs>
			<clipPath id="clip0_2852_31936">
				<rect fill={fill} height="24.0836" transform="translate(0.150391)" width="24.0836" />
			</clipPath>
		</defs>
	</svg>
);

export default BulletListIcon;
