import { HubConnectionBuilder } from '@microsoft/signalr';
import axios from 'axios';

export default class SignalRManager {
	config;
	responseDataHandler;

	constructor(config, responseDataHandler) {
		this.config = config;
		this.responseDataHandler = responseDataHandler;
	}

	fetchAccessToken = async (id, customUrl) => {
		try {
			let url =
				customUrl ?? `${this.config.auth.omegaai.auth_api}/token/SignalRConnectionInfo?hub=task&user=${id}`;
			const res = await axios.get(url);
			return res.data;
		} catch (e) {
			console.error(e);
			throw e;
		}
	};

	createSignalRConnection = (signalRUrl, options) => {
		return new HubConnectionBuilder().withUrl(signalRUrl, options).build();
	};

	startConnection = (connection) => {
		console.log('connecting to signalr...');
		connection
			.start()
			.then(function () {
				console.log('signalr connected!');
			})
			.catch(function (err) {
				console.error(err);
				setTimeout(function () {
					this.startConnection(connection);
				}, 2000);
			});
	};

	connectAPISocket = async (subscriptionId, customUrl) => {
		try {
			// 2. get accession token from auth
			const tokenData = await this.fetchAccessToken(subscriptionId, customUrl);
			let url = tokenData.url;

			const options = {
				accessTokenFactory: function () {
					return tokenData.accessToken;
				},
			};

			// 3. Create signalR connection
			let connection = this.createSignalRConnection(url, options);
			let receivedResponse = false;

			connection.on('taskUpdated', (data) => {
				this.responseDataHandler(data);
				receivedResponse = true;
			});
			connection.onclose(() => {
				console.log('closing signalr connection');
			});

			this.startConnection(connection);

			setTimeout(() => {
				if (!receivedResponse) {
					this.responseDataHandler(null);
				}
			}, 5000);
		} catch (e) {
			console.error(e);
			throw e;
		}
	};
}
