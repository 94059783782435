// @flow

// core
import React, { type Node } from 'react';
// components
import Stack from '@mui/material/Stack';
import { type ISkeletonProps, LoaderSkeleton } from './LoaderSkeleton';

export const MergeCards = ({ children, isLoading }: ISkeletonProps): Node =>
	isLoading ? (
		<Stack
			data-testid="merge-cards-loader"
			direction="column"
			spacing="20px"
			sx={{ paddingTop: '20px', width: '100%', justifyContent: 'center', alignItems: 'center' }}
		>
			<LoaderSkeleton sx={{ height: '385px', width: '365px' }} />
			<LoaderSkeleton sx={{ height: '385px', width: '365px' }} />
		</Stack>
	) : (
		children
	);
