import React, { useEffect, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import {
	HeaderBlock,
	ParagraphBlock,
	CalloutBlock,
	BulletListBlock,
	NumberListBlock,
	CheckListBlock,
	TableBlock,
	MergedBlocks,
	DraftItemHover,
} from '../../../index';

import CheckList from '../InsertTextBlock/CheckList/CheckList';
import TableConfigurator from '../InsertTextBlock/Table/TableConfigurator/TableConfigurator';

import ImageAttachment from '../AttachmentBlocks/ImageAttachment';
import VideoAttachment from '../AttachmentBlocks/VideoAttachment';

import { getInputStyle, getPayload } from './utils';
import { handleKeyUp } from '../utils';

import styles from './DraftItem.module.scss';

const DraftItem = ({
	theme,
	item,
	index,
	setDraft,
	activePanel,
	setActiveButton,
	draggingItem,
	setIsSectionsOpen,
	isSelecting,
	isSelected,
	setIsSelectingActive,
}) => {
	const [currentValue, setCurrentValue] = useState('');
	const [checkListData, setCheckListData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [displayMode, setDisplayMode] = useState({ type: 'default' });

	const draftItemRef = useRef();

	useEffect(() => {
		if (item?.text || item?.type === 'MergedBlocks') {
			setDisplayMode({ type: 'default' });
		}
	}, [item]);

	useEffect(() => {
		if (displayMode.type !== 'default') {
			setIsSelectingActive(false);
		} else {
			setIsSelectingActive(true);
		}
	}, [displayMode.type]);

	const onDraftChange = () => {
		const payload = getPayload(item, displayMode, checkListData, tableData, currentValue);

		setDraft(prev =>
			prev.map(elem => {
				if (elem.DocumentDraftId !== activePanel.id) {
					return elem;
				}

				const newDraft = elem.DocumentJson.map(JsonBlock => {
					if (displayMode?.block?.id && JsonBlock.id === item.id) {
						const newJsonBlock = JsonBlock.mergedBlocks.map(mergedBlock =>
							mergedBlock.id === displayMode.block.id ? { ...mergedBlock, ...payload } : mergedBlock
						);

						return { ...JsonBlock, mergedBlocks: newJsonBlock };
					}

					return JsonBlock.id === payload.id ? { ...JsonBlock, ...payload } : JsonBlock;
				});

				return { ...elem, DocumentJson: newDraft };
			})
		);

		setDisplayMode({ type: 'hover' });
	};

	const handleOutSideClick = event => {
		const path = event.path || (event.composedPath && event.composedPath());
		if (!path.includes(draftItemRef.current)) {
			if (currentValue !== '') {
				onDraftChange();
			}
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleOutSideClick);

		return () => {
			document.removeEventListener('mousedown', handleOutSideClick);
		};
	}, [draftItemRef, currentValue, checkListData, tableData]);

	useEffect(() => {
		if (displayMode.type === 'edit' && item.type !== 'Check List' && item.type !== 'Table') {
			setActiveButton('text');
			setIsSectionsOpen(false);
			if (item.type === 'MergedBlocks' && displayMode?.block?.id) {
				handleKeyUp({}, displayMode?.block.type);
			} else {
				handleKeyUp({}, item.type);
			}
		}
	}, [displayMode]);

	const onChange = e => {
		setCurrentValue(e.target.value);
	};

	const handleKeyDown = event => {
		if (event.key === 'Enter' && event.ctrlKey && currentValue !== '') {
			onDraftChange();
		}
	};

	const getBlockType = (block, isMergedBlockEditing) => {
		const setDisplayModeFunc = isMergedBlockEditing ? null : !isSelected && !isSelecting ? setDisplayMode : null;

		switch (block.type) {
			case 'Header':
				return (
					<HeaderBlock
						block={block}
						isSelected={isSelected}
						setDisplayMode={setDisplayModeFunc}
						theme={theme}
					/>
				);
			case 'Paragraph':
				return (
					<ParagraphBlock
						block={block}
						isSelected={isSelected}
						setDisplayMode={setDisplayModeFunc}
						theme={theme}
					/>
				);
			case 'Callout':
				return (
					<CalloutBlock
						block={block}
						isSelected={isSelected}
						setDisplayMode={setDisplayModeFunc}
						theme={theme}
					/>
				);
			case 'Bullet List':
				return (
					<BulletListBlock
						block={block}
						isSelected={isSelected}
						setDisplayMode={setDisplayModeFunc}
						theme={theme}
					/>
				);
			case 'Number List':
				return (
					<NumberListBlock
						block={block}
						isSelected={isSelected}
						setDisplayMode={setDisplayModeFunc}
						theme={theme}
					/>
				);
			case 'Check List':
				return (
					<CheckListBlock
						block={block}
						isSelected={isSelected}
						setDisplayMode={setDisplayModeFunc}
						theme={theme}
					/>
				);
			case 'Table':
				return (
					<TableBlock
						block={block}
						isSelected={isSelected}
						setDisplayMode={setDisplayModeFunc}
						theme={theme}
					/>
				);
			case 'MergedBlocks':
				return (
					<MergedBlocks
						block={block}
						isSelected={isSelected}
						setDisplayMode={setDisplayModeFunc}
						theme={theme}
					/>
				);
			case 'Attachment Image':
				return <ImageAttachment setDisplayMode={setDisplayMode} src={block.path} />;
			case 'Attachment Video':
				return <VideoAttachment setDisplayMode={setDisplayMode} src={block.path} />;
			default:
		}
	};

	return (
		<Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={displayMode.type === 'edit'}>
			{provided => (
				<div ref={provided.innerRef} {...provided.draggableProps}>
					<div ref={draftItemRef}>
						{item?.type === 'MergedBlocks' && displayMode.type === 'edit' ? (
							<div>
								{item.mergedBlocks.map(block =>
									block?.id === displayMode?.block?.id ? (
										<div
											className={`${styles.insertTextBlock} ${
												block.type === 'Table' ? styles.insertTextTable : ''
											}`}
										>
											{block?.type === 'Check List' ? (
												<CheckList
													draftItem={block}
													handleKeyDown={handleKeyDown}
													setCheckListData={setCheckListData}
													theme={theme}
												/>
											) : block?.type === 'Table' ? (
												<TableConfigurator
													block={block}
													setCurrentTextBlock={setTableData}
													theme={theme}
												/>
											) : (
												<textarea
													className={getInputStyle(theme, block)}
													data-testid="textarea"
													id={`textarea ${getInputStyle(theme, block)}`}
													rows={(currentValue.match(/\n/g) || []).length + 1 || 1}
													value={currentValue}
													onChange={onChange}
													onKeyDown={handleKeyDown}
													onKeyUp={e => handleKeyUp(e, block.type)}
												/>
											)}
										</div>
									) : (
										getBlockType(block, true)
									)
								)}
							</div>
						) : item?.text && displayMode.type === 'edit' ? (
							<div
								className={`${styles.insertTextBlock} ${
									item.type === 'Table' ? styles.insertTextTable : ''
								}`}
							>
								{item?.type === 'Check List' ? (
									<CheckList
										draftItem={item}
										handleKeyDown={handleKeyDown}
										setCheckListData={setCheckListData}
										theme={theme}
									/>
								) : item?.type === 'Table' ? (
									<TableConfigurator item={item} setCurrentTextBlock={setTableData} theme={theme} />
								) : (
									<textarea
										className={getInputStyle(theme, item)}
										data-testid="textarea"
										id={`textarea ${getInputStyle(theme, item)}`}
										rows={(currentValue.match(/\n/g) || []).length + 1 || 1}
										value={currentValue}
										onChange={onChange}
										onKeyDown={handleKeyDown}
										onKeyUp={e => handleKeyUp(e, item.type)}
									/>
								)}
							</div>
						) : displayMode.type === 'hover' || draggingItem?.draggableId === item.id ? (
							<DraftItemHover
								activePanel={activePanel}
								draggingItem={draggingItem}
								item={item}
								provided={provided}
								setCurrentValue={setCurrentValue}
								setDisplayMode={setDisplayMode}
								setDraft={setDraft}
								theme={theme}
							/>
						) : (
							getBlockType(item)
						)}
					</div>
				</div>
			)}
		</Draggable>
	);
};

export default DraftItem;
