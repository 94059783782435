import axios from 'axios';
const arrayBufferToBase64 = buffer => {
	let binary = '';
	const bytes = new Uint8Array(buffer);

	for (let i = 0; i < bytes.byteLength; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return window.btoa(binary);
};

const readAsDataURL = async inputFile => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = async e => {
			try {
				// Resolve the promise with the response value
				resolve(e.target.result);
			} catch (err) {
				reject(err);
			}
		};
		reader.onerror = error => {
			reject(error);
		};
		reader.readAsDataURL(inputFile);
	});
};

const formatBytes = (bytes, decimals = 1) => {
	if (!+bytes) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const uploadFileToServer = (url, fileData, contentType = 'multipart/form-data') => {
	return new Promise(async (resolve, reject) => {
		axios
			.post(url, fileData, {
				headers: {
					'content-type': contentType,
				},
			})
			.then(resp => {
				resolve(resp.data);
			})
			.catch(error => {
				reject(error);
			});
	});
};

const loadFileFromServer = url => {
	return new Promise(async (resolve, reject) => {
		axios
			.get(url, {
				responseType: 'blob', // important
			})
			.then(resp => {
				resolve(resp.data);
			})
			.catch(error => {
				reject(error);
			});
	});
};
export { arrayBufferToBase64, readAsDataURL, formatBytes, uploadFileToServer, loadFileFromServer };
