import React from 'react';
import { Typography } from '@mui/material';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import PhoneCallbackOutlinedIcon from '@mui/icons-material/PhoneCallbackOutlined';
import CallIcon from '@mui/icons-material/Call';
import { FormatChatDateTime } from '@worklist-2/ui/src/components/Chat/ChatDateHelper';
import { useLiveChatContext } from './LiveChatContext.jsx';
import UserAvatar from './UserAvatar.jsx';

function Notification({ conversation, isCall }) {
	const {
		setSelectedConversation,
		setIsChatOpen,
		conversationPick,
		setToastMsg,
		setChatHeader,
		setIsTextChatQueue,
		setVoiceChatTab,
		incomingCall,
		callerDetail,
	} = useLiveChatContext();

	const handleNotificationClick = async () => {
		if (isCall) {
			return;
		}
		if (conversation.participantCount === 1) {
			try {
				await conversationPick(conversation);
				setIsChatOpen(true);
			} catch (error) {
				setToastMsg({
					message: error.message,
				});
			}
		} else {
			setSelectedConversation(conversation);
			setIsChatOpen(true);
		}
	};

	const handleCallAnswer = () => {
		if (incomingCall) {
			//accept call
			incomingCall.dequeue('+15757086400');
			setIsChatOpen(true);
			setChatHeader('Support Call');
			setIsTextChatQueue(false);
			setVoiceChatTab('Dialpad');
		}
	};

	return (
		<div
			data-testid="Notification"
			style={{
				margin: '5px 0',
				borderRadius: '10px',
				boxShadow: '0px 0px 20px rgba(33, 88, 119, 0.2)',
				overflow: 'hidden',
				cursor: 'pointer',
			}}
			onClick={handleNotificationClick}
		>
			<div
				style={{
					boxSizing: 'border-box',
					padding: '10px 18px',
					display: 'flex',
					alignItems: 'center',
					backgroundColor: '#FFFFFF',
					color: '#4D79EA',
				}}
			>
				{conversation ? (
					<TextsmsOutlinedIcon fontSize="small" />
				) : (
					<PhoneCallbackOutlinedIcon fontSize="small" />
				)}
				<Typography
					style={{
						marginLeft: '10px',
						fontSize: '14px',
						fontWeight: '500',
						lineHeight: '17px',
						color: 'rgba(0, 0, 0, 0.9)',
					}}
				>
					{isCall ? 'Incoming Call' : 'New Message'}
				</Typography>
			</div>
			<div
				style={{
					boxSizing: 'border-box',
					padding: ' 10px 19px',
					display: 'flex',
					alignItem: 'flex-start',
					backgroundColor: '#F9FBFE',
					alignItems: 'center',
				}}
			>
				<UserAvatar
					isOnline={!!callerDetail || conversation?.participantOnline}
					size={54}
					userName={callerDetail?.name?.[0]?.text ?? conversation?.name ?? 'Unknown'}
				/>
				{isCall ? (
					<div
						style={{ marginLeft: '18px', display: 'flex', width: '265px', justifyContent: 'space-between' }}
					>
						<div style={{ fontFamily: 'Inter', fontWeight: '500' }}>
							<Typography style={{ lineHeight: '21px', fontSize: '18px' }}>
								{callerDetail?.name?.[0]?.text ??
									incomingCall?.task?.attributes?.caller ??
									incomingCall?.task?.attributes?.from}
							</Typography>
							<Typography style={{ fontSize: '14px', lineHeight: '16px', color: '#00000066' }}>
								Mobile
							</Typography>
						</div>
						<button
							style={{
								background: '#3EB967',
								color: '#ffffff',
								fontSize: '18px',
								display: 'flex',
								alignItems: 'center',
								padding: '10px',
								height: '40px',
								borderRadius: '10px',
								border: 'none',
								cursor: 'pointer',
							}}
							onClick={handleCallAnswer}
						>
							<CallIcon style={{ transform: 'rotate(-45deg)' }} />
							<Typography style={{ marginLeft: '5px' }}>Answer</Typography>
						</button>
					</div>
				) : (
					<div style={{ marginLeft: '18px' }}>
						<div
							style={{
								marginBottom: '4px',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<Typography
								data-testid="authorName"
								style={{
									fontSize: '12px',
									fontWeight: '600',
									lineHeight: '15px',
									color: 'rgba(0, 0, 0, 0.9)',
								}}
							>
								{conversation?.name}
							</Typography>
							<Typography
								data-testid="messageTime"
								style={{
									marginLeft: '10px',
									fontSize: '12px',
									fontWeight: '400',
									lineHeight: '15px',
									color: '#000000',
								}}
							>
								{FormatChatDateTime(conversation?.datetime)}
							</Typography>
						</div>
						<Typography
							data-testid="authorMessage"
							style={{
								display: '-webkit-box',
								width: '265px',
								height: '30px',
								fontSize: '12px',
								fontWeight: '400',
								lineHeight: '15px',
								color: '#000000',
								textOverflow: 'ellipsis',
								WebkitBoxOrient: 'vertical',
								WebkitLineClamp: '2',
								overflow: 'hidden',
							}}
						>
							{conversation?.participantCount !== 1
								? conversation?.messages[conversation.messages.length - 1].state.body
								: conversation?.text}
						</Typography>
					</div>
				)}
			</div>
		</div>
	);
}

export default Notification;
