// core
import React, { useState, useEffect } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Box, CircularProgress } from '@mui/material';

// components
import TeachingFolderView from '@worklist-2/ui/src/views/TeachingFolderView/TeachingFolderView';
import { TreeContextProvider } from '@rs-ui/context/TreeContext';
import { SelectionProvider } from '@worklist-2/core/src';

// Teaching Folder main component
export default function TeachingFolder({ isAddingStudies = false, closeDrawer }) {
	// loading state
	const [isLoading, setIsLoading] = useState(true);

	// check if user is authenticated
	const isAuthenticated = useIsAuthenticated();

	useEffect(() => {
		if (isAuthenticated) {
			setIsLoading(false);
		}
	}, [isAuthenticated]);

	const renderTFComponent = () => (
		<TeachingFolderView closeDrawer={closeDrawer} isAddingStudies={isAddingStudies}>
			<Box sx={{ display: isLoading ? 'flex' : 'none' }}>
				<CircularProgress />
			</Box>
		</TeachingFolderView>
	);

	return (
		<TreeContextProvider>
			{!isAddingStudies ? (
				<SelectionProvider>{renderTFComponent()}</SelectionProvider>
			) : (
				<>{renderTFComponent()}</>
			)}
		</TreeContextProvider>
	);
}
