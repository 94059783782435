// core
import React, { useEffect, useContext } from 'react';

// contexts
import FormContext from '../../../formContexts/FormContext';

// components
import FormFieldDefault from './FormFieldDefault';

// types
import FormFieldVariantType from '../../../formTypes/FormFieldVariantType';
import { useAppModeContext } from '@worklist-2/core/src/context/AppModeContext';
import { Controller } from 'react-hook-form';

const FormGoogleAutocompleteVariant = ({ formHook, name, props }) => {
	let autocomplete;
	const { isWorklistMode } = useAppModeContext();
	const { readOnly } = useContext(FormContext);
	const { setValue, clearErrors } = formHook;

	const fillInAddress = () => {
		if (autocomplete) {
			const place = autocomplete.getPlace();

			let address1 = '';
			let postcode = '';
			let locality = '';
			let state = '';
			let country = '';
			const latitude = place.geometry.location.lat();
			const longitude = place.geometry.location.lng();

			for (const component of place.address_components) {
				const componentType = component.types[0];

				switch (componentType) {
					case 'street_number': {
						address1 = `${component.long_name} ${address1}`;
						break;
					}

					case 'route': {
						address1 += component.short_name;
						break;
					}

					case 'postal_code': {
						postcode = `${component.long_name}${postcode}`;
						break;
					}

					case 'postal_code_suffix': {
						postcode = `${postcode}-${component.long_name}`;
						break;
					}

					case 'locality':
						locality = component.long_name;
						break;

					case 'administrative_area_level_1': {
						state = component.short_name;
						break;
					}

					case 'country':
						country = component.long_name;
						break;
				}
			}
			if (isWorklistMode()) {
				setValue('address.0.line.0', address1);
				setValue('address.0.city', locality);
				setValue('address.0.state', state);
				setValue('address.0.postalCode', postcode);
				setValue('address.0.country', country);
				setValue('address.0.latitude', latitude);
				setValue('address.0.longitude', longitude);

				// only used for Tilled Billing address
				setValue('update', true);
				clearErrors([
					'address.0.line.0',
					'address.0.city',
					'address.0.state',
					'address.0.postalCode',
					'address.0.country',
					'address.0.latitude',
					'address.0.longitude',
				]);
			} else {
				setValue('address', address1);
				setValue('city', locality);
				setValue('province', state);
				setValue('postalCode', postcode);
				setValue('country', country);
				setValue('latitude', latitude);
				setValue('longitude', longitude);
			}

			return true;
		}
	};

	useEffect(() => {
		const element = document.getElementById(props.id || `form-field-${props.label}`);

		if (element) {
			autocomplete = new window.google.maps.places.Autocomplete(element, {
				fields: ['address_components', 'geometry'],
				types: ['address'],
			});

			const autocompleteLsr = autocomplete.addListener('place_changed', fillInAddress);

			return () => {
				window.google.maps.event.removeListener(autocompleteLsr);
				window.google.maps.event.clearInstanceListeners(autocomplete);
			};
		}
	}, [readOnly]);

	const { control } = formHook;

	if (props.controlled) {
		return (
			<Controller
				control={control}
				name={name}
				render={({ field }) => (
					<FormFieldDefault
						formHook={formHook}
						name={name}
						props={{
							...field,
							...props,
						}}
						type="text"
					/>
				)}
			/>
		);
	}

	return (
		<FormFieldDefault
			uncontrolled
			formHook={formHook}
			name={name}
			props={{
				...props,
			}}
		/>
	);
};

FormGoogleAutocompleteVariant.propTypes = FormFieldVariantType();

export default FormGoogleAutocompleteVariant;
