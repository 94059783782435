// @flow

import _ from 'lodash';
import { format } from 'date-fns';

export interface IPatientAlert {
	id: string;
	patientId: string;
	resourceType: 'PatientAlert';
	roleIds: string[];
	roleDetails: Record<string, string>[];
	description: string;
	note: string;
	startDate: Date;
	endDate: Date;
	createdBy: string;
	createdDate: Date;
	lastUpdatedDate: Date;
	lastUpdatedNoteDate: Date;
}

const defaultData: IPatientAlert = {
	id: '', // if blank <PatientAlertsAndNeedsDrawer /> is in adding mode, otherwise it's in editing mode
	patientId: '',
	resourceType: 'PatientAlert',
	roleIds: [],
	roleDetails: [],
	description: '',
	note: '',
	startDate: new Date(),
	endDate: new Date(),
	createdBy: '',
	createdDate: new Date(), // current time
	lastUpdatedDate: new Date(),
	lastUpdatedNoteDate: new Date(),
};

export class AlertsAndNeeds {
	constructor(data?: IPatientAlert) {
		this._data = _.isObject(data) ? _.cloneDeep({ ...defaultData, ...data }) : _.cloneDeep(defaultData);
	}

	static getDefaultData() {
		return _.cloneDeep(defaultData);
	}

	// Converts all necessarry class data to structure needed for POST & PUT requests
	toPayload(data?: Partial<IPatientAlert>) {
		return {
			id: data.id || this.id,
			patientId: data.patientId || this.patientId,
			resourceType: this.resourceType,
			roleIds: _.map(data.roles, 'id') || this.roleIds,
			description: data.description || this.description,
			note: data.note || this.note,
			startDate: format(new Date(data.startDate || this.startDate), 'yyyy-MM-dd'),
			endDate: format(new Date(data.endDate || this.startDate), 'yyyy-MM-dd'),
			createdBy: data.createdBy || this.createdBy,
			createdDate: this.createdDate,
			lastUpdatedNoteDate: this.lastUpdatedNoteDate,
		};
	}

	// =============== Getters ===============
	get data() {
		return this._data;
	}

	get id() {
		return this._data?.id;
	}

	get patientId() {
		return this._data?.patientId;
	}

	get resourceType() {
		return this._data?.resourceType;
	}

	get roleIds() {
		return this._data?.roleIds;
	}

	get roleDetails() {
		return this._data?.roleDetails;
	}

	get description() {
		return this._data?.description;
	}

	get note() {
		return this._data?.note;
	}

	get startDate() {
		return this._data?.startDate;
	}

	get endDate() {
		return this._data?.endDate;
	}

	get createdBy() {
		return this._data?.createdBy;
	}

	get createdDate() {
		return this._data?.createdDate;
	}

	get lastUpdatedDate() {
		return this._data?.lastUpdatedDate;
	}
	get lastUpdatedNoteDate() {
		return this._data?.lastUpdatedNoteDate;
	}

	// =============== Setters ===============
	set id(value) {
		this._data.id = value;
	}
	set patientId(value) {
		this._data.patientId = value;
	}

	set description(value) {
		this._data.description = value;
	}

	set note(value) {
		this._data.note = value;
	}

	set startDate(value) {
		this._data.startDate = value;
	}

	set endDate(value) {
		this._data.endDate = value;
	}

	set createdBy(value) {
		this._data.createdBy = value;
	}

	set lastUpdatedNoteDate(value) {
		this._data.lastUpdatedNoteDate = value;
	}

	set roleDetails(value) {
		this._data.roleDetails = value;
	}
}
