const eventSubscribe = (eventName, listener) => {
	document.addEventListener(eventName, listener);
};

const eventUnsubscribe = (eventName, listener) => {
	document.removeEventListener(eventName, listener);
};

const eventPublish = (eventName, data) => {
	const event = new CustomEvent(eventName, { detail: data });
	document.dispatchEvent(event);
};

export { eventSubscribe, eventUnsubscribe, eventPublish };

export const TWILIO_SYNC_RESOURCE_TYPE = {
	IMAGING_STUDY: 1,
	APPOINTMENT: 2,
};

export const TWILIO_SYNC_EVENT_TYPE = {
	IMAGING_STUDY: 'twilioSyncImagingStudy',
	APPOINTMENT: 'twilioSyncAppointment',
};

export const TWILIO_SYNC_NOTIFICATION_DATA = {
	ResourceType: 0,
	ResourceId: 1,
	isActive: 2,
};
