const crypto = window.crypto || window.msCrypto;

/**
 * randomColorByCrypto
 *  Random a color by crypto
 * @returns
 */
const randomColorByCrypto = () => {
	let colorArray = new Uint32Array(2);
	crypto.getRandomValues(colorArray);
	return colorArray[0] / (0xffffffff + 1);
};

/**
 * cryptoUtils
 *  Crypto
 */
const cryptoUtils = {
	randomByCryto: randomColorByCrypto,
	/**
	 *
	 * @returns Hex color
	 */
	randomHexColor: () => {
		let hexNumber = Math.floor(randomColorByCrypto() * 16777215).toString(16);
		if (hexNumber.length < 6) return cryptoUtils.randomHexColor();
		return '#' + hexNumber;
	},

	/**
	 *
	 * @returns RgbaColor
	 */
	randomRgbaColor: opacity => {
		var o = Math.round,
			s = 255;
		return (
			'rgba(' +
			o(randomColorByCrypto() * s) +
			',' +
			o(randomColorByCrypto() * s) +
			',' +
			o(randomColorByCrypto() * s) +
			',' +
			randomColorByCrypto().toFixed(opacity) +
			')'
		);
	},

	/**
	 * convertHexToRGBA
	 * @param {*} hexCode
	 * @param {*} opacity
	 * @returns
	 */
	convertHexToRGBA: (hexCode, opacity = 1) => {
		let hex = hexCode.replace('#', '');

		if (hex.length === 3) {
			hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
		}

		const r = parseInt(hex.substring(0, 2), 16);
		const g = parseInt(hex.substring(2, 4), 16);
		const b = parseInt(hex.substring(4, 6), 16);

		/* Backward compatibility for whole number based opacity values. */
		if (opacity > 1 && opacity <= 100) {
			opacity = opacity / 100;
		}

		return `rgba(${r},${g},${b},${opacity})`;
	},
};
export default cryptoUtils;
