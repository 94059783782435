import React, { useEffect, useMemo, useRef, useState } from 'react';

import axios from 'axios';

import { Box, IconButton, Typography } from '@mui/material';

import {
	Add,
	ArrowBack,
	Business,
	CardTravel,
	DeviceHub,
	FolderOutlined,
	KeyboardArrowDown,
	KeyboardArrowUp,
	LastPage,
	ToggleOnOutlined,
} from '@mui/icons-material';

import { useAuth, useConfig } from '@worklist-2/core/src';
import { useCrmContext } from '../../../../../../context/CRMContext';

import { Collapsible, DottedWrapper, TabPanel, Wrapper } from '../../../../Cases/casePopOverStyles';
import Loading from './Loading';
import DeviceItem from './DeviceItem';
import AddEditDeviceTab from './AddEditDeviceTab';
import AddEditStationTab from './AddEditStationTab';
import Footer from './Footer';

import { getDevicesWithStations } from './utils';

import createStyles from './styles';

const Device = ({ accountData, orgData, setOrganizationData, setStep, isSimpleSidebar, sidebarMode, setDeviceID }) => {
	const [devices, setDevices] = useState([]);
	const [checked, setChecked] = useState(false);
	const [deviceOpen, setDeviceOpen] = useState({ orgData: {}, device: {}, open: false, mode: 'add' });
	const [stationOpen, setStationOpen] = useState({
		orgData: {},
		associatedDevice: {},
		station: {},
		open: false,
		mode: 'add',
	});
	const [saveDeviceActive, setSaveDeviceActive] = useState(false);
	const [saveStationActive, setSaveStationActive] = useState(false);
	const [loading, setLoading] = useState(false);
	const [stationsData, setStationsData] = useState({});
	const [currentStation, setCurrentStation] = useState({});

	const __config = useConfig();
	const { authorized } = useAuth();

	const { toggleDetails, showDetails } = useCrmContext();

	const classes = createStyles();

	const deviceRef = useRef();
	const stationRef = useRef();

	useEffect(() => {
		async function makeAuth() {
			if (authorized) {
				await getDevice().catch(console.error);
			}
		}
		makeAuth();
	}, [authorized, orgData.id, deviceOpen, stationOpen]);

	useEffect(() => {
		if (sidebarMode?.entity === 'device') {
			if (sidebarMode?.mode === 'add') {
				setDeviceOpen({ orgData: showDetails.data.organizationData, open: true, mode: 'add' });
			} else if (sidebarMode?.mode === 'edit') {
				setDeviceOpen({
					orgData: showDetails.data.organizationData,
					device: sidebarMode?.device,
					open: true,
					mode: 'edit',
				});
			}
		}
		if (sidebarMode?.entity === 'station') {
			if (sidebarMode?.mode === 'add') {
				setStationOpen({
					orgData: showDetails.data.organizationData,
					associatedDevice: sidebarMode?.associatedDevice,
					open: true,
					mode: 'add',
				});
			} else if (sidebarMode?.mode === 'edit') {
				setStationOpen({
					orgData: showDetails.data.organizationData,
					associatedDevice: sidebarMode?.associatedDevice,
					station: sidebarMode?.station,
					open: true,
					mode: 'edit',
				});
			}
		}
	}, [showDetails, sidebarMode]);

	const getDevice = async () => {
		setLoading(true);
		const orgDataId = orgData?.id ? orgData?.id : showDetails.data.id;
		const { data } = await axios.get(
			`${__config.data_sources.breeze}/DeviceOmegaAI/${accountData?.AccountId}?managingorganization=${orgDataId}&type=OmegaAILink,dicom-web,dicom-entity`
		);

		if (data.entry) {
			const [entityMap, filteredDevices] = getDevicesWithStations(data.entry);
			setStationsData(entityMap);
			setDevices(filteredDevices);
		}

		setLoading(false);
	};

	const [newOmegaAIName, newDicomWEBName] = useMemo(() => {
		const orgName = orgData.name ? orgData.name : showDetails.data.organizationData.display;
		const strAI = `${orgName}-OMEGA AI LINK-`;
		const strWEB = `${orgName}-DICOM-WEB-`;
		let numberAI = 0;
		let numberWeb = 0;

		devices.forEach(elem => {
			const deviceName = elem.distinctIdentifier;

			if (deviceName.includes(strAI)) {
				const currentPoint = deviceName.substring(strAI.length);
				if (isNaN(currentPoint) === false) {
					if (+currentPoint > numberAI) {
						numberAI = +currentPoint;
					}
				}
			}

			if (deviceName.includes(strWEB)) {
				const currentPoint = deviceName.substring(strWEB.length);
				if (isNaN(currentPoint) === false) {
					if (+currentPoint > numberWeb) {
						numberWeb = +currentPoint;
					}
				}
			}
		});

		numberAI += 1;
		numberWeb += 1;

		return [strAI + numberAI, strWEB + numberWeb];
	}, [orgData.name, showDetails?.data?.organizationData?.display, devices]);

	const handleChange = () => {
		setChecked(prev => !prev);
	};

	const onAddDevice = () => {
		setDeviceOpen({ open: true, mode: 'add' });
	};

	const onClose = () => {
		setStep(null);
		setOrganizationData(null);
		toggleDetails();
	};

	const setPreviousStep = () => {
		if (isSimpleSidebar) {
			setStep(null);
			setOrganizationData(null);
			toggleDetails();
		} else {
			setStep('upload'); //features
		}
	};

	const onBackDevice = () => {
		if (isSimpleSidebar) {
			setStep(null);
			setOrganizationData(null);
			toggleDetails();
		} else {
			setDeviceOpen({ open: false, mode: 'add' });
		}
	};

	const onBackStation = () => {
		if (isSimpleSidebar) {
			setStep(null);
			setOrganizationData(null);
			toggleDetails();
		} else {
			setStationOpen({ open: false, mode: 'add' });
		}
	};

	const onNext = () => {
		setDeviceID(currentStation.id);
		setStep('station');
	};

	const onSaveDevice = () => {
		if (isSimpleSidebar) {
			deviceRef.current.onSubmit();
			setStep(null);
			setOrganizationData(null);
			toggleDetails();
		} else {
			deviceRef.current.onSubmit();
			setTimeout(() => setDeviceOpen({ open: false }), 1000);
		}
	};

	const onSaveStation = () => {
		if (isSimpleSidebar) {
			stationRef.current.onSubmit();
			setStep(null);
			setOrganizationData(null);
			toggleDetails();
		} else {
			stationRef.current.onSubmit();
			setTimeout(() => setStationOpen({ open: false }), 1000);
		}
	};

	const onCreate = () => {
		setDeviceOpen({ open: true, mode: 'add' });
	};

	const title = useMemo(() => {
		if (stationOpen.open) {
			return stationOpen.mode === 'add' ? 'Add Station' : 'Edit Station';
		}
		return deviceOpen.mode === 'add' ? 'Add Device' : 'Edit Device';
	}, [stationOpen.open, stationOpen.mode, deviceOpen.mode]);

	return (
		<Wrapper>
			<Box className="header" style={classes.header}>
				<Box className="actions">
					<Box className="left">
						<IconButton onClick={setPreviousStep}>
							<ArrowBack />
						</IconButton>
					</Box>
					<Box className="right">
						<IconButton onClick={onClose}>
							<LastPage />
						</IconButton>
					</Box>
				</Box>
			</Box>
			<Box className="body" style={classes.body}>
				<TabPanel className="invisible-scrollbar" style={classes.tabPanel}>
					<Box className="header">
						<Box className="left">
							<Box className="colored-icon info-icon">
								<Business sx={classes.businessIcon20} />
							</Box>
							<Typography variant="h2">{title}</Typography>
						</Box>
						{!(deviceOpen.open || stationOpen.open || isSimpleSidebar) && (
							<Box className="right">
								<Box className="colored-icon active-icon">
									<Business sx={classes.businessIcon10} />
								</Box>
								<Typography className="active-span" variant="span" />
								<Box className="colored-icon active-icon">
									<CardTravel sx={classes.businessIcon10} />
								</Box>
								<Typography className="active-span" variant="span" />
								<Box className="colored-icon active-icon">
									<ToggleOnOutlined sx={classes.businessIcon10} />
								</Box>
								<Typography className="active-span" variant="span" />
								<Box className="colored-icon active-icon">
									<DeviceHub sx={classes.businessIcon10} />
								</Box>
								<Typography className="gray-span" variant="span" />
								<Box className="colored-icon gray-icon">
									<FolderOutlined sx={classes.businessIcon10} />
								</Box>
							</Box>
						)}
					</Box>
					<Box className="body">
						{loading ? (
							<Loading />
						) : deviceOpen.open ? (
							<AddEditDeviceTab
								ref={deviceRef}
								accountData={accountData}
								deviceOpen={deviceOpen}
								newDicomWEBName={newDicomWEBName}
								newOmegaAIName={newOmegaAIName}
								orgData={orgData}
								setDeviceOpen={setDeviceOpen}
								setSaveDeviceActive={setSaveDeviceActive}
							/>
						) : stationOpen.open ? (
							<AddEditStationTab
								ref={stationRef}
								accountData={accountData}
								orgData={orgData}
								setSaveStationActive={setSaveStationActive}
								setStationOpen={setStationOpen}
								stationOpen={stationOpen}
							/>
						) : devices?.length > 0 ? (
							<Collapsible>
								<Box className="handle" onClick={handleChange}>
									<Typography>Dicom Device</Typography>
									{checked ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
								</Box>
								{checked ? (
									''
								) : (
									<Box sx={classes.devicesListBox}>
										{devices.map(device => (
											<DeviceItem
												key={device.id}
												currentStation={currentStation}
												device={device}
												setCurrentStation={setCurrentStation}
												stations={stationsData}
												onDeviceOpen={setDeviceOpen}
												onStationOpen={setStationOpen}
											/>
										))}
									</Box>
								)}
							</Collapsible>
						) : (
							<Collapsible>
								<Box className="handle" onClick={handleChange}>
									<Typography>Dicom Device</Typography>
									{checked ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
								</Box>
								{checked ? (
									''
								) : (
									<DottedWrapper>
										<Box className="card" onClick={onAddDevice}>
											<Add />
											<Typography>Add Device</Typography>
										</Box>
									</DottedWrapper>
								)}
							</Collapsible>
						)}
					</Box>
				</TabPanel>
			</Box>
			{deviceOpen.open ? (
				<Footer saveDisabled={!saveDeviceActive} type="SAVE" onBack={onBackDevice} onSave={onSaveDevice} />
			) : stationOpen.open ? (
				<Footer saveDisabled={!saveStationActive} type="SAVE" onBack={onBackStation} onSave={onSaveStation} />
			) : (
				<Footer
					currentStation={currentStation}
					isCreate={devices?.length > 0}
					type="NEXT"
					onBack={setPreviousStep}
					onCreate={onCreate}
					onNext={onNext}
				/>
			)}
		</Wrapper>
	);
};

export default Device;
