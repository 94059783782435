import { toast } from 'react-toastify';
const message = 'Error while performing operation!';

function handleApiResponse(response, showToast = false) {
	if (!response?.status?.toString()?.startsWith(2)) {
		if (showToast) {
			toast.error(response?.data?.message || message, {
				position: 'bottom-left',
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				closeButton: false,
				theme: 'colored',
			});
		}

		return null;
	} else {
		return response;
	}
}

export const handleRequestError = e => {
	toast.error(message, {
		position: 'bottom-left',
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: true,
		progress: undefined,
		closeButton: false,
		theme: 'colored',
	});
};

export default handleApiResponse;
