import { Icon } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import { mediaDesktopL, mediaDesktopXL } from '@worklist-2/core/src';

const MobileStudyStatus = ({ status }) => {
	switch (status) {
		case 'SCHEDULED':
		case 'Scheduled':
			return (
				<Icon
					component={StarIcon}
					sx={{
						color: '#C480DC',
						marginRight: '10px',
						[mediaDesktopXL]: {
							margin: '2px 10px -4.5px 1.9px',
						},
						[mediaDesktopL]: {
							margin: '2px 10px -4.5px 1.9px',
						},
						width: '20px',
						height: '20px',
						borderRadius: '50%',
					}}
				/>
			);
		case 'INPROCESS':
		case 'Processing':
			return (
				<Icon
					component={HourglassFullIcon}
					sx={{
						color: '#1E8FEA',
						marginRight: '10px',
						[mediaDesktopXL]: {
							margin: '2px 10px -4.5px 1.9px',
						},
						[mediaDesktopL]: {
							margin: '2px 10px -4.5px 1.9px',
						},
						width: '20px',
						height: '20px',
						borderRadius: '50%',
					}}
				/>
			);
		case 'Completed':
		case 'Ordered':
		default:
			return (
				<Icon
					component={CheckIcon}
					sx={{
						color: '#fff',
						backgroundColor: '#75B678',
						marginRight: '10px',
						[mediaDesktopXL]: {
							margin: '2px 10px -4.5px 1.9px',
						},
						[mediaDesktopL]: {
							margin: '2px 10px -4.5px 1.9px',
						},
						width: '20px',
						height: '20px',
						borderRadius: '50%',
					}}
				/>
			);
	}
};

export default MobileStudyStatus;
