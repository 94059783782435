import extensionUrls from '../../extension/fhirExtensionUrls';
import moment from 'moment';
import getUserFullName from '@rs-core/fhir/resource/columnMapping/utils/getUserFullName';
import getCurrentTime from '@rs-core/fhir/resource/columnMapping/utils/getCurrentTime';
import getCurrentDate from '@rs-core/fhir/resource/columnMapping/utils/getCurrentDate';

function formatDate(inputDate) {
	const momentDate = moment.utc(inputDate);
	const formattedDate = momentDate.format('M/D/YYYY h:mm:ss A');

	return formattedDate;
}

const DiagnosticReportMapping = {
	id: {
		getDisplay: resource => {
			return resource.id;
		},
	},

	reportName: {
		getDisplay: resource => {
			return resource?.presentedForm ? resource.presentedForm[0]?.title : '';
		},
	},
	doctorName: {
		getDisplay: resource => {
			return resource.resultsInterpreter ? resource.resultsInterpreter[0]?.display : '';
		},
	},
	creationDateTime: {
		getDisplay: resource => {
			return resource.presentedForm && resource.presentedForm.length > 0
				? resource.presentedForm[0]?.creation
				: '';
		},
	},
	reportUrl: {
		getDisplay: resource => {
			return resource.presentedForm && resource.presentedForm?.length > 0 ? resource.presentedForm[0]?.url : '';
		},
	},
	studyId: {
		getDisplay: resource => {
			return resource.imagingStudy?.length > 0 ? resource.imagingStudy[0].id : '';
		},
		//imagingStudy
	},
	resourceType: {
		getDisplay: resource => {
			return resource?.resourceType;
		},
	},
	status: {
		// reportType
		getDisplay: resource => {
			return resource?.status;
		},
	},
	reportTypeLabel: {
		getDisplay: resource => {
			if (resource?.status === 'final') {
				return 'Final Report';
			} else if (resource?.status === 'preliminary') {
				return 'Preliminary Report';
			}
			return resource?.status;
		},
	},
	managingOrganization: {
		getDisplay: resource => {
			const resObj = resource?.extension?.find(item => item.url === extensionUrls.common.managingOrganization);
			return resObj?.valueReference?.display || '';
		},
	},
	referrer: {
		getDisplay: resource => {
			const resObj = resource?.extension?.find(item => item.url === extensionUrls.common.referringPhysician);
			return resObj?.valueReference?.display || '';
		},
	},

	isCritical: {
		getDisplay: resource => {
			const resObj = resource?.extension?.find(item => item.url === extensionUrls.diagnosisReport.isCritical);
			return resObj?.valueBoolean || false;
		},
	},
	dateTimeUpdated: {
		getDisplay: (resource, timeZone = '') => {
			let value = resource.meta?.lastUpdated;
			if (value != null) {
				return getCurrentDate(value, timeZone) + ' ' + getCurrentTime(value, timeZone);
			}
		},
	},
	signedDateTime: {
		getDisplay: resource => {
			let value = resource?.effectiveDateTime;
			if (value != null) {
				return formatDate(value);
			}
		},
	},
	lastUpdatedUser: {
		getDisplay: resource => {
			const resObj = resource?.meta?.extension?.find(item => item.url === extensionUrls.common.lastUpdateUser);
			return getUserFullName(resObj?.valueReference?.display);
		},
	},
	signed: {
		getDisplay: resource => {
			const resObj = resource?.extension?.find(item => item.url === extensionUrls.imagingStudy.signer);
			return getUserFullName(resObj?.valueReference?.display);
		},
	},
	performer: {
		getDisplay: resource => {
			return resource.performer?.length > 0 ? resource.performer[0].display : '';
		},
	},
};
export default DiagnosticReportMapping;
