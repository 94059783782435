import React, { useEffect, useState } from 'react';
import { Button, Box } from '@mui/material';

const DayPicker = ({ list, index, setListOfNotifications, defaultDays=[]}) => {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const [selectedDays, setSelectedDays] = useState(defaultDays);

  useEffect(()=>{
    const newList = list;
    newList[index].Schedule = selectedDays?.join(' ');
    setListOfNotifications(newList);
  },[selectedDays, defaultDays]);

  const handleDayToggle = (day) => {
    setSelectedDays((prevDays) => {
      if (prevDays.includes(day)) {
        return prevDays.filter(d => d !== day);
      }
      return [...prevDays, day];
      
    });
  };

  const selectedDaysString = selectedDays.join(' ');

  return (
    <Box display="flex" justifyContent="flex-start" width="100%" gap='10px' margin='8px'>
      {daysOfWeek.map((day) => (
        <Button
          key={day}
          variant="outlined"
          onClick={() => handleDayToggle(day)}
          sx={{
            width: '32px',
            height: '32px',
            fontSize: '12px',
            borderRadius: '100%',
            minWidth: 'unset',
            color: selectedDays.includes(day) ? '#42A5F5' : 'gray',
            borderColor: selectedDays.includes(day) ? '#42A5F533' : 'gray',
            backgroundColor: selectedDays.includes(day) ? '#42A5F51A' : 'transparent',
            '&:hover': {
                color:'#42A5F5',
                borderColor:'#42A5F533',
                backgroundColor: '#42A5F51A',
            },
          }}
        >
          {day.charAt(0)}
        </Button>
      ))}
        <Box mt={2} textAlign="center" display="none">
        Selected Days: {selectedDaysString || 'None'}
      </Box>
    </Box>
  );
};

export default DayPicker;