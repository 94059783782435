import React, { forwardRef, useImperativeHandle, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { OutlineWrapper, VerticalDivider } from '../../../../views/CrmView/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useAuth } from '@worklist-2/core/src';

const UserDetailsCard = forwardRef((props, ref) => {
	const [editing, toggleEditing] = useState(false);
	const [initialData] = useState(props.user);
	const [user, setUser] = useState(props.user);
	const [payload, setPayload] = useState({});
	const { loggedInUser } = useAuth();

	useImperativeHandle(ref, () => ({
		cancelEditing: () => {
			setUser(initialData);
			toggleEditing(false);
		},
		save: () => {
			toggleEditing(false);
			return payload;
		},
	}));

	const handleChange = e => {
		if (e.key === 'Enter') {
			toggleEditing(false);
		} else {
			setUser({ ...user, [e.target.name]: e.target.value });
			setPayload({ ...payload, [e.target.name]: e.target.value });
		}
	};

	return (
		<OutlineWrapper style={{ flex: 0 }}>
			<div className="header">
				<div className="left">
					<div className="colored-icon error-icon">
						<ErrorOutlineIcon sx={{ fontSize: 20 }} />
					</div>
					<h2>Details</h2>
				</div>
				{!editing && !!loggedInUser.permission.User.Edit && (
					<IconButton
						data-testid="edit-user"
						id="closeEditButton_Edit_userDetail"
						onClick={() => toggleEditing(!editing)}
					>
						<EditOutlinedIcon id="closeEditButton_Edit_userDetail" sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />
					</IconButton>
				)}
			</div>
			<Box sx={{ padding: '15px 19px' }}>
				<Box className="row body">
					<div className="col">
						<label>Email Address</label>
						{editing ? (
							<TextField
								data-testid="LoginName-field"
								defaultValue={user?.LoginName}
								id="editButton_Edit_Email"
								name="LoginName"
								size="small"
								sx={{
									borderRadius: '5px',
									background: 'rgba(196, 196, 196, 0.15)',
								}}
								variant="outlined"
								onKeyUp={handleChange}
							/>
						) : (
							<span>{user?.LoginName}</span>
						)}
					</div>
					{!editing && <VerticalDivider style={{ margin: '0px 20px' }} />}
					<div className="col">
						<label>Phone Number</label>
						{editing ? (
							<TextField
								data-testid="Phone-field"
								defaultValue={user?.PhoneNumber}
								id="editButton_Edit_PhoneNumber"
								name="PhoneNumber"
								size="small"
								sx={{
									borderRadius: '5px',
									background: 'rgba(196, 196, 196, 0.15)',
								}}
								variant="outlined"
								onKeyUp={handleChange}
							/>
						) : (
							<span>{user?.PhoneNumber}</span>
						)}
					</div>
				</Box>
				<Box className="row body" style={{ margin: '20px 0px' }}>
					<div className="col">
						<label>Organization</label>
						{editing ? (
							<TextField
								data-testid="Organization-field"
								defaultValue={user.Organization}
								id="editButton_Edit_Organization"
								name="Organization"
								size="small"
								sx={{
									borderRadius: '5px',
									background: 'rgba(196, 196, 196, 0.15)',
								}}
								variant="outlined"
								onKeyUp={handleChange}
							/>
						) : (
							<span>{user.Organization}</span>
						)}
					</div>
					{!editing && <VerticalDivider style={{ margin: '0px 20px' }} />}
					<div className="col">
						<label>Website</label>
						{editing ? (
							<TextField
								data-testid="Website-field"
								defaultValue={user.WebSite}
								id="editButton_Edit_WebSite"
								name="WebSite"
								size="small"
								sx={{
									borderRadius: '5px',
									background: 'rgba(196, 196, 196, 0.15)',
								}}
								variant="outlined"
								onKeyUp={handleChange}
							/>
						) : (
							<span>{user.WebSite}</span>
						)}
					</div>
				</Box>
			</Box>
		</OutlineWrapper>
	);
});

UserDetailsCard.propTypes = {
	user: PropTypes.object.isRequired,
};

export default UserDetailsCard;
