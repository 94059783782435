import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import AddIcon from '@mui/icons-material/Add';
import RequestItem from './RequestItem';
import Loader from './Loader';

const RequestTab = ({ onAddClick, isOmega, isLoading, reviews }) => (
	<Box
		sx={{
			width: '100%',
			height: '100%',
			padding: '20px 22px 0',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden',
		}}
	>
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<IconButton sx={{ backgroundColor: 'rgba(77, 121, 234, 0.1)' }}>
				<LinkIcon sx={{ color: '#4D79EA' }} />
			</IconButton>
			<Typography
				sx={{
					marginLeft: '15px',
					fontFamily: 'Inter',
					fontSize: '14px',
					fontWeight: 500,
					letterSpacing: '1.5px',
					textTransform: 'uppercase',
					color: '#4D79EA',
				}}
			>
				Copy the link
			</Typography>
		</Box>
		<Typography
			sx={{
				marginTop: '20px',
				marginBottom: '12px',
				fontFamily: 'Inter',
				fontSize: '16px',
				fontWeight: 500,
				color: isOmega ? 'rgba(255, 255, 255, 0.60)' : 'rgba(0, 0, 0, 0.60)',
			}}
		>
			My Shared List
		</Typography>
		<IconButton
			style={{
				width: '100%',
				padding: '14px 0',
				border: '1px dashed #C4C4C4',
				borderRadius: '4px',
			}}
			onClick={onAddClick}
		>
			<AddIcon sx={{ color: '#C4C4C4' }} />
		</IconButton>
		{isLoading ? (
			<Loader isOmega={isOmega} />
		) : (
			<Box className="invisible-scrollbar" sx={{ height: '100%', marginTop: '10px', overflow: 'auto' }}>
				{reviews.map(item => (
					<RequestItem
						key={item?.DocumentReviewId}
						dates={{
							CreatedDateTime: item?.CreatedDateTime,
							SeenDateTime: item?.SeenDateTime,
							ReviewedDateTime: item?.ReviewedDateTime,
						}}
						isOmega={isOmega}
						jobTitle={item?.ReviewerRole}
						name={item?.ReviewerName}
					/>
				))}
			</Box>
		)}
	</Box>
);

export default RequestTab;
