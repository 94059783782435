// React Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Material UI Libraries
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import TableChartIcon from '@mui/icons-material/TableChart';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputLabel from '@mui/material/InputLabel';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import List from '@mui/material/List';
import Accordion from '@mui/material/Accordion';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';

// Custom Component Libraries
import UpArrow from '@worklist-2/ui/src/assets/icons/up-arrow.svg';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			aria-labelledby={`full-width-tab-${index}`}
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			role="tabpanel"
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

function FilterSideContent({ setIsFilter }) {
	const [value, setValue] = React.useState(0);
	const [open, setOpen] = React.useState(false);
	const [field, setField] = React.useState('');
	const [filterOpen, setFilterOpen] = React.useState(false);
	const [expanded, setExpanded] = React.useState(2);
	const [checkedFilterItems, setCheckedFilterItems] = React.useState([]);

	const handleAccordionChange = panel => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleFieldChange = e => {
		setField(e.target.value);
	};

	const handleFilterCheckedItems = index => {
		const hasChecked = checkedFilterItems.find(i => i === index);
		let newFilterArray = [];
		if (hasChecked) {
			newFilterArray = checkedFilterItems.filter(i => i !== index);
		} else {
			newFilterArray = [...checkedFilterItems, index];
		}
		setCheckedFilterItems(newFilterArray);
	};

	const ITEMS_ARRAY = {
		name: 'Measure',
		items: [
			{
				name: 'Name Name 1',
			},
			{
				name: 'Name Name 2',
			},
			{
				name: 'Name Name 3',
			},
			{
				name: 'Name Name 4',
			},
		],
	};

	const FILTER_ARRAY = [
		'Select All',
		'Option Name EWGFDdfg trergdfgd',
		'Option Name SHREOIsRsSLSDFSD#',
		'Option Name',
	];

	return (
		<Box
			sx={{
				height: '100%',
				width: '400px',
				position: 'absolute',
				right: 0,
				top: 0,
				bottom: 0,
				zIndex: 10,
				background: '#272727',
				boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.25)',
				borderRadius: '10px',
				transition: '0.2s',
			}}
		>
			<Box
				sx={{
					padding: '0 20px',
				}}
			>
				<Stack
					alignItems="center"
					direction="row"
					justifyContent="space-between"
					sx={{
						padding: '18px 0',
						color: '#fff',
						borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
					}}
				>
					<Typography fontSize={16}>Filter</Typography>
					<IconButton aria-label="clear" data-testid="clear-button" onClick={() => setIsFilter(false)}>
						<ClearIcon />
					</IconButton>
				</Stack>
			</Box>

			<Tabs
				aria-label="icon position tabs example"
				indicatorColor="secondary"
				sx={{
					color: '#FFFFFF99',
					'.Mui-selected': {
						color: '#42A5F5 !important',
					},
					'.MuiTabs-indicator': {
						backgroundColor: '#42A5F5',
					},
				}}
				value={value}
				variant="fullWidth"
				onChange={handleChange}
			>
				<Tab icon={<TableChartIcon />} iconPosition="start" label="FIELDS" {...a11yProps(0)} />
				<Tab icon={<FilterAltOutlinedIcon />} iconPosition="start" label="FILTER" {...a11yProps(1)} />
			</Tabs>
			<TabPanel index={0} value={value}>
				<>
					<Box
						sx={{
							margin: 'auto',
							width: '80%',
							height: '54px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							border: '2px dashed rgba(255, 255, 255, 0.2)',
							color: '#FFFFFF3B',
							borderRadius: '5px',
							'&:hover': {
								color: '#FFF',
								borderColor: 'rgb(255, 255, 255)',
								cursor: 'pointer',
							},
						}}
					>
						<AddIcon />
					</Box>

					<Stack
						alignItems="center"
						direction="row"
						gap={2}
						justifyContent="space-between"
						sx={{
							color: 'rgba(255, 255, 255, 0.6)',
							margin: '24px -20px 0 8px',
						}}
					>
						<Typography fontSize={16}>1</Typography>
						<Box sx={{ width: '100%' }}>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">Data Fields</InputLabel>
								<Select
									id="demo-simple-select"
									label="Data Fields"
									labelId="demo-simple-select-label"
									open={open}
									value={field}
									onClick={() => setOpen(true)}
								>
									<ClickAwayListener onClickAway={() => setOpen(false)}>
										<Box>
											{Array(5)
												.fill(ITEMS_ARRAY)
												.map((el, index) => (
													<Accordion
														expanded={expanded === index}
														sx={{
															'&.Mui-expanded': {
																margin: 0,
															},
														}}
														onChange={handleAccordionChange(index)}
													>
														<AccordionSummary
															aria-controls="panel1d-content"
															expandIcon={<UpArrow />}
															id="panel1d-header"
															sx={{
																color:
																	expanded === index
																		? '#fff'
																		: 'rgba(255, 255, 255, 0.6)',
																display: 'flex',
																alignItems: 'center',
																'&.Mui-expanded': {
																	minHeight: '48px',
																	'.MuiAccordionSummary-content': {
																		margin: 0,
																	},
																},
															}}
														>
															<TableChartIcon
																sx={{
																	marginRight: '16px',
																}}
															/>
															<Typography>{el.name}</Typography>
														</AccordionSummary>
														<AccordionDetails
															sx={{
																background: '#323232',
																borderRadius: '10px',
															}}
														>
															<List>
																{el.items.map((item, i) => (
																	<ListItemButton onClick={() => setField(item.name)}>
																		<ListItemIcon>
																			{field === item.name && (
																				<CheckIcon
																					sx={{
																						color: '#42A5F5',
																					}}
																				/>
																			)}
																		</ListItemIcon>
																		<ListItemText primary={item.name} />
																	</ListItemButton>
																))}
															</List>
														</AccordionDetails>
													</Accordion>
												))}
										</Box>
									</ClickAwayListener>
								</Select>
							</FormControl>
						</Box>
						<IconButton aria-label="clear">
							<DeleteOutlinedIcon sx={{ color: '#FFFFFF' }} />
						</IconButton>
					</Stack>
				</>
			</TabPanel>
			<TabPanel index={1} value={value}>
				<Stack
					alignItems="center"
					direction="row"
					gap={2}
					justifyContent="space-between"
					sx={{ color: 'rgba(255, 255, 255, 0.6)' }}
				>
					<Typography fontSize={16}>1</Typography>
					<Box sx={{ width: '100%' }}>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label2">[Data Fields Name]</InputLabel>
							<Select
								id="demo-simple-select2"
								label="[Data Fields Name]"
								labelId="demo-simple-select-label2"
								open={filterOpen}
								renderValue={() => (
									<Box
										sx={{
											display: 'flex',
											flexWrap: 'wrap',
											gap: 0.5,
										}}
									>
										{checkedFilterItems.map(value => (
											<Chip key={value} label={FILTER_ARRAY[value]} />
										))}
									</Box>
								)}
								onClick={() => setFilterOpen(true)}
							>
								<ClickAwayListener onClickAway={() => setFilterOpen(false)}>
									<Box
										sx={{
											height: '425px',
											background: '#393939',
											borderRadius: '6px',
											padding: '20px',
										}}
									>
										<TextField
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<SearchIcon />
													</InputAdornment>
												),
											}}
											id="outlined-start-adornment"
											placeholder="Search"
											size="small"
											sx={{
												width: '100%',
												'.MuiOutlinedInput-notchedOutline': {
													borderRadius: '100px',
												},
											}}
										/>

										<List sx={{ width: '100%' }}>
											{FILTER_ARRAY.map((value, index) => {
												const labelId = `checkbox-list-label-${value}`;

												return (
													<ListItem key={value} disablePadding>
														<ListItemButton
															dense
															role={undefined}
															onClick={() => handleFilterCheckedItems(index)}
														>
															<ListItemIcon>
																<Checkbox
																	disableRipple
																	edge="start"
																	inputProps={{
																		'aria-labelledby': labelId,
																	}}
																	tabIndex={-1}
																/>
															</ListItemIcon>
															<ListItemText id={labelId} primary={value} />
														</ListItemButton>
													</ListItem>
												);
											})}
										</List>
									</Box>
								</ClickAwayListener>
							</Select>
						</FormControl>
					</Box>
				</Stack>
			</TabPanel>
		</Box>
	);
}

FilterSideContent.propTypes = {
	/**
	 * setIsFilter is func to toggle share side bar
	 */
	setIsFilter: PropTypes.func.isRequired,
};

export default FilterSideContent;
