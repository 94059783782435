// core
import React from 'react';

const StudyPreviewImage = ({ imageUrl, style = {} }) => (
	<div
		data-testid="study-preview-image"
		role="img"
		style={{
			height: 'calc(100% - 32px)',
			margin: '16px 0',
			width: '100%',
			scrollSnapAlign: 'center',
			backgroundColor: 'black',
			backgroundImage: `url(${imageUrl})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain',
			...style,
		}}
	/>
);

export default StudyPreviewImage;
