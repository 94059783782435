import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Controlled as CodeMirror } from 'react-codemirror2';
import HeaderButtons from './HeaderButtons';
import './ScriptStyles.css';

const EDITOR_THEME_NAME = 'material-darker';
require('codemirror/lib/codemirror.css');

require(`codemirror/theme/${EDITOR_THEME_NAME}.css`);
require('codemirror/mode/javascript/javascript.js');

const ScriptSection = ({
	isCodeEditorOpen,
	setIsCodeEditorOpen,
	code,
	setCode,
	setIsTestRun,
	isTestRun,
	disabled,
	sendHL7Scripting,
	showSave,
}) => {
	const [localCode, setLocalCode] = useState(code);
	const [isChanged, setIsChanged] = useState(showSave || false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setLocalCode(code);
	}, [code]);

	const editorOptions = useMemo(
		() => ({
			lineNumbers: true,
			mode: 'javascript',
			theme: EDITOR_THEME_NAME,
			readOnly: isTestRun || disabled,
		}),
		[isTestRun, disabled]
	);

	const handleScriptSave = async () => {
		setIsLoading(true);
		const isSuccess = await sendHL7Scripting(localCode);

		if (isSuccess) {
			setIsChanged(false);
			setCode(localCode);
		}

		setIsLoading(false);
	};

	const onCodeChange = useCallback((__, ___, c) => {
		setLocalCode(c);
		setIsChanged(true);
	}, []);

	return (
		<Box
			sx={{
				paddingBottom: '10px',
				boxSizing: 'border-box',
				width: '100%',
				height: '100%',
				borderRadius: '10px',
				backgroundColor: '#212121',
			}}
		>
			<Box
				sx={{
					padding: isTestRun ? '8px 22px 8px 16px' : '8px 10px 8px 16px',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
					backgroundColor: '#272727',
					borderRadius: '10px',
				}}
			>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Typography
						sx={{
							fontFamily: 'Roboto',
							fontWeight: 500,
							fontSize: '14px',
							color: 'rgba(255, 255, 255, 0.6)',
						}}
					>
						{isTestRun ? 'Test' : 'OmegaAI Script'}
					</Typography>
					{isChanged && (
						<Button
							data-testid="save-script-btn"
							disabled={isLoading}
							sx={{
								padding: 0,
								marginLeft: '6px',
								backgroundColor: '#4D79EA',
								border: '1px solid #4D79EA',
								'&:hover': {
									border: '1px solid rgba(255, 255, 255, 0.6)',
								},
								'&:disabled': {
									color: 'rgba(255, 255, 255, 0.26)',
									border: '1px solid rgba(255, 255, 255, 0.26)',
									backgroundColor: 'transparent',
								},
							}}
							onClick={handleScriptSave}
						>
							save
						</Button>
					)}
				</Box>
				<HeaderButtons
					isCodeEditorOpen={isCodeEditorOpen}
					isTestRun={isTestRun}
					setIsCodeEditorOpen={setIsCodeEditorOpen}
					setIsTestRun={setIsTestRun}
				/>
			</Box>
			<CodeMirror
				className={isTestRun ? 'ScriptTestHeight' : 'ScriptHeight'}
				options={editorOptions}
				value={localCode}
				onBeforeChange={onCodeChange}
			/>
		</Box>
	);
};

export default ScriptSection;
