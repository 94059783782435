import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import MobileList from '../MobileList/MobileList';

const MobilePhysicianInformationList = ({ imagingOrganizationPhone }) => {
	const items = [
		{
			primary: imagingOrganizationPhone,
			icon: PhoneIcon,
			href: `tel:${imagingOrganizationPhone}`,
		},
	];

	return <MobileList color="#42A5F5" items={items} />;
};

export default MobilePhysicianInformationList;
