export function arrayBufferToBase64(buffer) {
	const Buffer = require('buffer').Buffer;
	return Buffer.from(buffer).toString('base64');
}

export function formatBytes(bytes, decimals = 1) {
	if (!+bytes) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
