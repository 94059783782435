import React, { useState } from 'react';
import { Tooltip, Popover } from '@mui/material';
import axios from 'axios';

import { useAuth, useConfig } from '@worklist-2/core/src';

import { useHelpCenterContext } from '../../HelpCenter/HelpCenterContext';

import PreviewOptions from '../PreviewOptions/PreviewOptions';

import { BreezeIcon, DesktopIcon, InventoryIcon, MobileIcon, TabletIcon, ExpandMoreIcon } from '../../assets/icons';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';

import styles from './Header.module.scss';

const Header = ({
	theme,
	unreadCommentsCount,
	isReviewOpen,
	onOpenReview,
	isSectionsOpen,
	setIsSectionsOpen,
	setActiveButton,
	currentDocId,
}) => {
	const [anchorEl, setAnchorEl] = useState(false);

	const { activeDeviceType, setActiveDeviceType, inputWidth, inputHeight, setResolution, setResponsive } =
		useHelpCenterContext();

	const { loggedInUser } = useAuth();
	const __config = useConfig();

	const getUserInitials = () =>
		loggedInUser?.display
			?.split(' ')
			.map(n => n.charAt(0).toUpperCase())
			.join('');

	const onClickDevice = type => {
		setActiveDeviceType(type);
	};

	const onInventoryClick = () => {
		setIsSectionsOpen(true);
		setActiveButton('');
	};

	const onPublish = async () => {
		if (currentDocId) {
			await axios.put(`${__config.data_sources.breeze}/documentdraft/publish/${currentDocId}`);
		}
	};

	return (
		<div
			className={`${styles.header} ${theme === 'OAI' ? styles.headerOAITheme : styles.headerBlumeTheme}`}
			data-testid="header"
		>
			<div className={styles.headerLeft} data-testid="left-icons">
				<BreezeIcon />
				<div
					className={`${styles.inventoryBox} ${isSectionsOpen ? styles.inventoryBoxActive : ''}`}
					data-testid="inventory-icon"
					onClick={onInventoryClick}
				>
					<InventoryIcon />
				</div>
			</div>
			<div className={styles.headerCenter}>
				<div style={{ position: 'relative' }}>
					<div
						className={styles.deviceBlock}
						onClick={e => {
							onClickDevice('desktop');
							setAnchorEl(e.currentTarget);
						}}
					>
						{activeDeviceType === 'desktop' ? (
							<div data-testid="desktop-active-icon" style={{ display: 'flex', alignItems: 'center' }}>
								<div className={styles.deviceBlockActiveIcon}>
									<DesktopIcon fill="#4D79EA" />
								</div>
								<div className={styles.expand} data-testid="expand-more-icon">
									<ExpandMoreIcon />
								</div>
							</div>
						) : (
							<div
								className={styles.deviceBlock}
								data-testid="desktop-icon"
								onClick={() => {
									onClickDevice('desktop');
									setResolution(res => ({ ...res, width: 1920 }));
									setResolution(res => ({ ...res, height: 1080 }));
									inputWidth(1920);
									inputHeight(1080);
									setResponsive(false);
								}}
							>
								<DesktopIcon />
								<div className={styles.expandPlaceholder} />
							</div>
						)}
					</div>
					<Popover
						PaperProps={{
							style:
								theme === 'OAI'
									? {
											backgroundColor: '#393939',
											border: '1px solid #ffffff1a',
									  }
									: {
											backgroundColor: '#ffffff',
											border: '1px solid #c4c4c466',
									  },
						}}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						open={activeDeviceType === 'desktop' && anchorEl}
						onClose={() => setAnchorEl(false)}
					>
						<PreviewOptions theme={theme} />
					</Popover>
				</div>
				<div>
					<div
						className={styles.deviceBlock}
						data-testid="tablet-block"
						onClick={e => {
							onClickDevice('tablet');
							setAnchorEl(e.currentTarget);
						}}
					>
						{activeDeviceType === 'tablet' ? (
							<div data-testid="tablet-active-icon" style={{ display: 'flex', alignItems: 'center' }}>
								<div className={styles.deviceBlockActiveIcon}>
									<TabletIcon fill="#4D79EA" />
								</div>
								<div className={styles.expand}>
									<ExpandMoreIcon />
								</div>
							</div>
						) : (
							<div
								className={styles.deviceBlock}
								data-testid="tablet-icon"
								onClick={() => {
									onClickDevice('tablet');
									setResolution(res => ({ ...res, width: 912 }));
									setResolution(res => ({ ...res, height: 1368 }));
									inputWidth(912);
									inputHeight(1368);
									setResponsive(false);
								}}
							>
								<TabletIcon />
								<div className={styles.expandPlaceholder} />
							</div>
						)}
					</div>
					<Popover
						PaperProps={{
							style:
								theme === 'OAI'
									? {
											backgroundColor: '#393939',
											border: '1px solid #ffffff1a',
									  }
									: {
											backgroundColor: '#ffffff',
											border: '1px solid #c4c4c466',
									  },
						}}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						open={activeDeviceType === 'tablet' && anchorEl}
						onClose={() => setAnchorEl(false)}
					>
						<PreviewOptions theme={theme} />
					</Popover>
				</div>
				{theme === 'Blume' ? (
					<div>
						<div
							className={styles.deviceBlock}
							data-testid="mobile-block"
							onClick={e => {
								if (theme === 'OAI') {
									return;
								}
								onClickDevice('mobile');
								setAnchorEl(e.currentTarget);
							}}
						>
							{activeDeviceType === 'mobile' ? (
								<div data-testid="mobile-active-icon" style={{ display: 'flex', alignItems: 'center' }}>
									<div className={styles.deviceBlockActiveIcon}>
										<MobileIcon fill="#4D79EA" />
									</div>
									<div className={styles.expand}>
										<ExpandMoreIcon />
									</div>
								</div>
							) : (
								<div
									className={styles.deviceBlock}
									data-testid="mobile-icon"
									onClick={() => {
										if (theme === 'OAI') {
											return;
										}
										onClickDevice('mobile');
										setResolution(res => ({ ...res, width: 360 }));
										setResolution(res => ({ ...res, height: 657 }));
										inputWidth(360);
										inputHeight(657);
										setResponsive(false);
									}}
								>
									<MobileIcon />
									<div className={styles.expandPlaceholder} />
								</div>
							)}
						</div>
						<Popover
							PaperProps={{
								style:
									theme === 'OAI'
										? {
												backgroundColor: '#393939',
												border: '1px solid #ffffff1a',
										  }
										: {
												backgroundColor: '#ffffff',
												border: '1px solid #c4c4c466',
										  },
							}}
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							open={activeDeviceType === 'mobile' && anchorEl}
							onClose={() => setAnchorEl(false)}
						>
							<PreviewOptions theme={theme} />
						</Popover>
					</div>
				) : (
					''
				)}
			</div>
			<div className={styles.headerRight}>
				<button className={styles.avatar}>{getUserInitials()}</button>
				<ReviewButton
					isReviewOpen={isReviewOpen}
					theme={theme}
					unreadCommentsCount={unreadCommentsCount}
					onOpenReview={onOpenReview}
				/>
				<button className={styles.publishBtn} onClick={onPublish}>
					<span>Publish</span>
				</button>
			</div>
		</div>
	);
};

const ReviewButton = ({ theme, unreadCommentsCount, isReviewOpen, onOpenReview }) => {
	const [isShowTooltip, setIsShowTooltip] = useState(false);

	const handleButtonClick = () => {
		setIsShowTooltip(false);
		onOpenReview();
	};

	const handleTooltipOver = () => {
		if (!isReviewOpen) {
			setIsShowTooltip(true);
		}
	};

	return (
		<Tooltip
			arrow
			componentsProps={{
				tooltip: {
					sx: {
						marginTop: '-6px !important',
						padding: '10px 18px',
						fontFamily: 'Inter',
						fontSize: '14px',
						bgcolor: '#4D79EA',
						'& .MuiTooltip-arrow': {
							color: '#4D79EA',
						},
					},
				},
			}}
			open={isShowTooltip}
			title="Review"
			onClose={() => setIsShowTooltip(false)}
			onOpen={handleTooltipOver}
		>
			<button
				className={`${styles.reviewBtn} ${isReviewOpen ? styles.reviewBtnActive : ''}`}
				data-testid="review-btn"
				onClick={handleButtonClick}
			>
				<RateReviewOutlinedIcon />
				{unreadCommentsCount > 0 && !isReviewOpen && (
					<div
						className={`${styles.reviewBtnLabel} ${
							theme === 'OAI' ? styles.reviewBtnLabelOAITheme : styles.reviewBtnLabelBlumeTheme
						}`}
						data-testid="review-count"
					>
						{unreadCommentsCount}
					</div>
				)}
			</button>
		</Tooltip>
	);
};

export default Header;
