import React from 'react';
import Box from '@mui/material/Box';
import Switch from 'react-switch';

const SwitchButton = props => (
	<Box
		sx={{
			marginRight: '10px',
			'& .react-switch-handle': {
				boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
			},
		}}
	>
		<Switch
			activeBoxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
			borderRadius={5}
			checked={props.checked}
			checkedIcon={
				<Box
					data-testid="active-indicator"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
						fontSize: 14,
						color: '#FFF',
					}}
				>
					On
				</Box>
			}
			className="react-switch"
			data-testid="switch-button"
			handleDiameter={21}
			height={21}
			id="small-radius-switch"
			offColor="#C4C4C4"
			uncheckedIcon={
				<Box
					data-testid="inactive-indicator"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
						fontSize: 14,
						color: '#FFF',
					}}
				>
					Off
				</Box>
			}
			width={50}
			onChange={props.onChange}
			onColor="#4D79EA"
		/>
	</Box>
);

export default SwitchButton;
