import { v4 as uuidv4 } from 'uuid';

const extractEmergencyContactsFromProfile = profile => {
	const emergencyContactsArray = profile?.emergencyContacts ? [...profile.emergencyContacts] : [];
	return emergencyContactsArray.filter(
		(contact, index, self) => self.findIndex(c => c.email === contact.email) === index
	);
};

// TODO rename function
const extractTempEmergencyContactsFromProfile = (profile, updated, newVal, toDelete) => {
	let emergencyContactsArray = profile?.emergencyContacts ? [...profile.emergencyContacts] : [];
	emergencyContactsArray = emergencyContactsArray
		.filter(d => d?.emergencyContactId !== toDelete)
		.map(contact => {
			if (updated && updated.emergencyContactId == contact.emergencyContactId)
				return {
					id: updated?.emergencyContactId,
					name: updated?.name || contact.name,
					relation: updated?.relation || updated?.relationship || contact.relation,
					phone: updated?.phone || contact.phone,
					email: updated?.email || contact.email,
					emergencyContactId: updated?.emergencyContactId,
				};
			return contact;
		});

	if (newVal) {
		newVal.id = newVal.emergencyContactId || uuidv4();
		emergencyContactsArray.push(newVal);
	}
	return emergencyContactsArray.filter(
		(contact, index, self) => self.findIndex(c => c.email === contact.email) === index
	);
};

export { extractEmergencyContactsFromProfile, extractTempEmergencyContactsFromProfile };
