import * as React from 'react';
import InputUnstyled from '@mui/base/InputUnstyled';
import { styled } from '@mui/system';

const StyledInputElement = styled('input')(
	() => `
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 1.5;
  color: #FFF;
  background: transparent;
  border: 0px;
  border-radius: 0px;
  padding: 12px 12px;
  flex: 1;
  width: 95%;

  &:hover {
    border: 0px;
  }

  &:focus {
    outline: 0px;
  }
`
);

const CustomInput = React.forwardRef(function CustomInput(props, ref) {
	return <InputUnstyled components={{ Input: StyledInputElement }} {...props} ref={ref} />;
});

export default function ChatBoxInput(props) {
	return <CustomInput {...props} />;
}
