import React, { useState } from 'react';
import axios from 'axios';

import { useConfig } from '@worklist-2/core/src';

import { EditTitleIcon, PlusMoreTab, PlusTab, SaveTitleIcon } from '../../../assets/icons';

import styles from './Panel.module.scss';

const Panel = ({ draft, setDraft, activePanel, setActivePanel, activeCategory, documentType }) => {
	const [titleValue, setTitleValue] = useState('');
	const [titleMode, setTitleMode] = useState('default');
	const [isPlusHover, setIsPlusHover] = useState(false);
	const [titleHover, setTitleHover] = useState({});
	const [isSaveTitleIconHover, setIsSaveTitleIconHover] = useState(false);
	const [isEditTitleIconHover, setIsEditTitleIconHover] = useState(false);

	const __config = useConfig();

	const onPlusMoreTab = e => {
		e.stopPropagation();
		if (titleMode !== 'edit') {
			setActivePanel({ id: '', name: '' });
			setIsPlusHover(false);
			setTitleMode('create');
		}
	};

	const onPlusTab = e => {
		e.stopPropagation();
		setIsPlusHover(false);
		setTitleMode('create');
	};

	const onCreateNewTab = async e => {
		e.stopPropagation();
		setIsSaveTitleIconHover(false);

		if (titleValue) {
			const payload = {
				DocumentName: titleValue,
				DocumentContent: '',
				DocumentJson: [],
				Type: documentType || 'tech',
				Path: `omegaai|${
					activeCategory.subCategory.SubCategoryId
						? `${activeCategory.category.DocumentCategoryId}|${activeCategory.subCategory.SubCategoryId}`
						: activeCategory.category.DocumentCategoryId
				}`,
			};

			const newTab = await axios.post(`${__config.data_sources.breeze}/documentdraft`, payload);

			if (newTab.data.DocumentDraftId) {
				setDraft(prev => [...prev, newTab.data]);
				setActivePanel({ id: newTab.data.DocumentDraftId, name: titleValue });
			}
		}

		setIsPlusHover(false);
		setTitleMode('default');
		setTitleValue('');
	};

	const onSwitchTab = item => {
		if (titleMode === 'default') {
			setActivePanel({ id: item.DocumentDraftId, name: item.DocumentName });
			setTitleMode('default');
		}
	};
	const onEditTitle = (e, item) => {
		e.stopPropagation();

		if (item.DocumentDraftId === activePanel.id) {
			setTitleValue(item.DocumentName);
			setTitleMode('edit');
			setTitleHover({});
		}
	};

	const onSaveTitle = (e, item) => {
		e.stopPropagation();

		setDraft(prev =>
			prev.map(elem => {
				if (elem.DocumentDraftId === item.DocumentDraftId) {
					return {
						...elem,
						DocumentName: titleValue,
					};
				}
				return elem;
			})
		);

		setActivePanel({ id: item.DocumentDraftId, name: titleValue });
		setTitleValue('');
		setTitleMode('default');
	};

	const createNewTab = () => (
		<>
			<div
				className={`${styles.panelItemBox} ${styles.panelItemBoxLast} ${
					isPlusHover ? styles.panelItemBoxHover : ''
				}`}
			>
				<div className={`${styles.panelItem} ${styles.panelItemActive} ${styles.panelItemLast}`}>
					<div className={styles.panelItemEdit}>
						<input
							autoFocus
							placeholder="Type Name"
							type="text"
							value={titleValue}
							onChange={e => setTitleValue(e.target.value)}
						/>
						<div
							data-testid="save-title-icon"
							onClick={onCreateNewTab}
							onMouseLeave={() => setIsSaveTitleIconHover(false)}
							onMouseOver={() => setIsSaveTitleIconHover(true)}
						>
							<SaveTitleIcon fill={isSaveTitleIconHover ? '#4BAAF6' : ''} />
						</div>
					</div>
				</div>
			</div>
			<div
				className={styles.panelPlusIcon}
				onMouseLeave={() => setIsPlusHover(false)}
				onMouseOver={() => setIsPlusHover(true)}
			>
				<PlusMoreTab fill={isPlusHover ? '#4BAAF6' : ''} />
			</div>
		</>
	);

	return (
		<div className={styles.panel}>
			{draft.length > 0 ? (
				<>
					{draft.map(item => (
						<div
							key={item.id}
							className={`${styles.panelItemBox} 
							${titleMode !== 'create' && draft.at(-1).DocumentDraftId === item.DocumentDraftId ? styles.panelItemBoxLast : ''} ${
								isPlusHover &&
								titleMode !== 'create' &&
								(draft.at(-1).DocumentDraftId === item.DocumentDraftId ? styles.panelItemBoxHover : '')
							}
							`}
							onClick={() => onSwitchTab(item)}
							onMouseLeave={() => setTitleHover({})}
							onMouseOver={() => setTitleHover(item)}
						>
							<div
								className={`${styles.panelItem} ${
									activePanel.id === item.DocumentDraftId ? styles.panelItemActive : ''
								} ${draft.at(-1).DocumentDraftId === item.DocumentDraftId ? styles.panelItemLast : ''}`}
								onDoubleClick={e => onEditTitle(e, item)}
							>
								{titleMode === 'edit' && activePanel.id === item.DocumentDraftId ? (
									<div className={styles.panelItemEdit}>
										<input
											placeholder="Type Name"
											type="text"
											value={titleValue}
											onChange={e => setTitleValue(e.target.value)}
										/>
										<div
											data-testid="save-title-icon"
											onClick={e => onSaveTitle(e, item)}
											onMouseLeave={() => setIsSaveTitleIconHover(false)}
											onMouseOver={() => setIsSaveTitleIconHover(true)}
										>
											<SaveTitleIcon fill={isSaveTitleIconHover ? '#4BAAF6' : ''} />
										</div>
									</div>
								) : activePanel.id === item.DocumentDraftId &&
								  titleHover?.DocumentDraftId === item.DocumentDraftId ? (
									<div className={styles.panelItemHover}>
										<span>{item.DocumentName}</span>
										<div
											className={styles.panelItemHoverEditIcon}
											onClick={e => onEditTitle(e, item)}
											onMouseLeave={() => setIsEditTitleIconHover(false)}
											onMouseOver={() => setIsEditTitleIconHover(true)}
										>
											<EditTitleIcon fill={isEditTitleIconHover ? '#4BAAF6' : ''} />
										</div>
									</div>
								) : (
									<span>{item.DocumentName}</span>
								)}
							</div>
						</div>
					))}
					{titleMode === 'create' ? (
						createNewTab()
					) : (
						<div
							className={styles.panelPlusIcon}
							data-testid="plus-more-tab"
							onClick={onPlusMoreTab}
							onMouseLeave={() => setIsPlusHover(false)}
							onMouseOver={() => setIsPlusHover(true)}
						>
							<PlusMoreTab fill={isPlusHover ? '#4BAAF6' : ''} />
						</div>
					)}
				</>
			) : titleMode === 'create' ? (
				createNewTab()
			) : (
				<button
					className={styles.panelPlusButtonIcon}
					disabled={!activeCategory.category.CategoryName}
					onClick={onPlusTab}
					onMouseLeave={() => activeCategory.category.CategoryName && setIsPlusHover(false)}
					onMouseOver={() => activeCategory.category.CategoryName && setIsPlusHover(true)}
				>
					<PlusTab fill={isPlusHover ? '#4BAAF6' : ''} />
				</button>
			)}
		</div>
	);
};

export default Panel;
