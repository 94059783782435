import React from 'react';
import Grid from '@mui/material/Grid';
import MobileLabelText from '../../MobileLabelText';
import Box from '@mui/material/Box';
import MobileTitle from '../../MobileTitle';

const MobileAppointmentLocationSection = ({ study }) => (
	<Box>
		<MobileTitle title="Study Information" />

		<Grid container>
			<Grid item xs={7}>
				<MobileLabelText label="Country" text={study.country} />
			</Grid>

			<Grid item xs={5}>
				<MobileLabelText label="State/Province" text={study.state} />
			</Grid>

			<Grid item xs={7}>
				<MobileLabelText label="City" text={study.city} />
			</Grid>

			<Grid item xs={5}>
				<MobileLabelText label="Zip/Postal Code" text={study.postalCode} />
			</Grid>

			<Grid item xs={7}>
				<MobileLabelText label="Address Line 1" text={study.addressLine1} />
			</Grid>

			<Grid item xs={5}>
				<MobileLabelText label="Address Line 2" text={study.addressLine2} />
			</Grid>
		</Grid>
	</Box>
);

export default MobileAppointmentLocationSection;
