// core
import React from 'react';
// libraries
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const ErrorResult = ({ error, resetErrorBoundary }) => {
	const { t } = useTranslation('errorResult');
	return (
		<Box
			sx={{
				height: 'calc(100vh - 230px)',
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				paddingBottom: '100px',
				boxSizing: 'border-box',
			}}
		>
			<Typography
				sx={{
					textAlign: 'center',
					fontWeight: '500',
					fontSie: '16px',
					color: 'text.primary',
					marginBottom: '8px',
				}}
			>
				{t('error1')}
			</Typography>
			<Typography
				sx={{
					fontSize: '14px',
					color: 'text.primary',
					maxWidth: '350px',
					textAlign: 'center',
				}}
			>
				{t('error2')}
			</Typography>
		</Box>
	);
};

export default ErrorResult;
