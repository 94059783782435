/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
import organizationAffiliationSchema from './schemata/organizationAffiliationSchema';
/************************************************************************************************************************
Resource: OrganizationAffiliation
Reference: https://www.hl7.org/fhir/OrganizationAffiliation.html


A relationship between 2 organizations over a period of time, where the entities are separate business entities. The relationship
can optionally include details of locations/services from the participating organization. The OrganizationAffiliation enables
defining

non-hierarchical relationships between organizations. For example: 
************************************************************************************************************************/

export default class OrganizationAffiliationResource extends FhirDomainResource {
	identifier = [];
	active;
	period;
	organization;
	participatingOrganization;
	network = [];
	code = [];
	specialty = [];
	location = [];
	healthcareService = [];
	telecom = [];
	endpoint = [];

	constructor(resourceString) {
		super(resourceString, organizationAffiliationSchema);
		this.resourceType = 'OrganizationAffiliation';
		this.populateFields();
	}
}
