// core
import React, { useEffect, useRef, useState } from 'react';
// libraries
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export const LoadingCallout = () => {
	const loadingWrapperRef = useRef(null);
	const [loadersCount, setLoadersCount] = useState(0);

	useEffect(() => {
		if (loadingWrapperRef.current) {
			const availableHeight = loadingWrapperRef.current.clientHeight;
			const maxItemsWithoutSpacing = Math.floor(availableHeight / 134);

			setLoadersCount(
				Math.max(
					0,
					maxItemsWithoutSpacing * 134 + (maxItemsWithoutSpacing - 1) * 15 <= availableHeight
						? maxItemsWithoutSpacing
						: maxItemsWithoutSpacing - 1
				)
			);
		}
	}, [!!loadingWrapperRef.current]);

	return (
		<div ref={loadingWrapperRef} data-testid="LoadingCallout" style={{ height: 'calc(100vh - 140px)' }}>
			{new Array(loadersCount).fill(null).map((_, index) => (
				<LoadingPlaceholder key={index} />
			))}
		</div>
	);
};

const LoadingPlaceholder = () => (
	<Box
		pb={2.5}
		pl={2.5}
		pt={2}
		sx={{
			height: '134px',
			minWidth: '100%',
			display: 'flex',
			flex: 1,
			backgroundColor: 'rsSecondary.medium',
			boxSizing: 'border-box',
			borderRadius: '6px',
			'&:not(:last-of-type)': {
				marginBottom: '15px',
			},
		}}
	>
		<Skeleton height={44} variant="circular" width={44} />
		<div style={{ margin: '0 24px', flexGrow: 1 }}>
			<Skeleton
				sx={{
					height: '23px',
					marginBottom: '8px',
					borderRadius: '6px',
				}}
				variant="rectangular"
			/>
			<Skeleton
				sx={{
					height: '23px',
					marginBottom: '8px',
					borderRadius: '6px',
				}}
				variant="rectangular"
			/>
			<Skeleton sx={{ height: '35px', borderRadius: '6px' }} variant="rectangular" />
		</div>
	</Box>
);
