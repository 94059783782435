import { Avatar, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import React from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	border-bottom: ${props => (props.bottomBorder ? '1px solid rgba(0, 0, 0, 0.1)' : '0px')};
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 12px 23px 13px 2px;

	&.mx-0 {
		margin-left: 0px;
		margin-right: 0px;
	}

	&.w-50% {
		width: 50%;
	}

	span {
		font-size: 14px;
		line-height: 1.25;
		letter-spacing: 0.25px;
		text-align: left;
	}

	label {
		font-size: 14px;
		line-height: 1.43;
		letter-spacing: 0.25px;
		text-align: left;
		color: rgba(255, 255, 255, 0.6);
	}

	label.detail {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		color: rgba(0, 0, 0, 0.6);
	}

	select {
		background: rgba(196, 196, 196, 0.15);
		border: 1px solid rgba(0, 0, 0, 0.05);
		border-radius: 10px;
		height: 40px !important;
		font-size: 14px;
		color: rgba(0, 0, 0, 0.9);
		padding: 0px 12px;
		max-width: 225px;
	}

	input {
		font-size: 14px;
	}

	.row {
		display: flex;
		flex-direction: row;
		align-items: center;

		span {
			margin-right: 20px;
		}
	}

	.without-padding input {
		padding-right: 1px;
		padding-left: 5px;
	}

	.without-padding div {
		padding-right: 5px;
		border-radius: 10px;
	}

	.auto-completed div {
		border-radius: 10px;
	}

	.form-control {
		padding-top: 6px;
		min-height: 40px;
		overflow-y: hidden;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		input,
		select {
			flex: 1 !important;
		}
	}
`;

export const Wrapper = styled(Box)`
	${({ theme }) => `
  width: 538px;
  height: 100vh;
  overflow-y: auto;
  margin: 0px;
  background: #FFF;
  padding: 0px;
  box-shadow: -4px 0 20px 0 rgba(33, 88, 119, 0.1);
  position: relative;
  top: 0px;
  right: 0px;
  z-index: 20;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.5s;
  opacity: 1;

  @keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
       opacity: 1;
    }
  }

  .body {
      flex: 1;
      display: flex;
      flex-direction: column;
  }

  & > .header {
      padding: 8px 0 18px 36px;
      background-color: #f9fafc;
      display: flex;
      flex-direction: column;

      .actions {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 25px;

          .right {
              display: flex;
              flex-direction: row;
              align-items: center;
          }

          .left {
              display: flex;
              flex-direction: row;
              align-items: center;
          }

          .left span {
              font-family: Inter;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: rgba(0, 0, 0, 0.6);
              margin-left: 10px;
          }
      }
  }

  .footer {
      height: 50px;
      width: 478px;
      margin: auto;
      background: #fff;
      position: fixed;
      bottom: 10px;
      margin-left: 36px;
      border: 1px solid #d7d7d7;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;

      button.outline {
          margin-left: 20px;
      }

      .right {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          margin-left: auto;
          margin-right: 20px;
      }

      button {
          margin: 0px;
          height: 33px;
          background: #4D79EA;
          border-radius: 5px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          letter-spacing: 1.5px;
          text-transform: uppercase;
          cursor: pointer;
      }

      button.outline {
          background: #FFF;
          border: 1px solid #4D79EA;
          color: #4D79EA;
      }

      button.fill {
          background: #4D79EA;
          border: 1px solid #4D79EA;
          color: #FFF;
          margin-left: 10px;
      }
  `}
`;

export const Title = ({ children }) => {
	return (
		<Typography
			component={'h1'}
			sx={{
				fontFamily: 'Inter',
				fontSize: '24px',
				fontWeight: '500',
				fontStretch: 'normal',
				fontStyle: 'normal',
				lineHeight: 'normal',
				letterSpacing: 'normal',
				textAlign: 'left',
				color: 'rgba(0, 0, 0, 0.9)',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				marginBottom: '38px',

				'span.priority': {
					fontFamily: 'Inter',
					fontSize: '25px',
					fontWeight: 'bold',
					fontStretch: 'normal',
					fontStyle: 'normal',
					lineHeight: 'normal',
					letterSpacing: 'normal',
					textAlign: 'left',
					color: '#4d79ea',
					marginRight: '17px',
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'row',
				},

				input: {
					color: '#000',
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: '500',
					fontSize: '24px',
					lineHeight: '29px',
					flex: 1,
					background: 'transparent',
					border: 'none',
					marginRight: '40px',
					boxShadow: 'none',
					outline: 'none',
				},
				'&:hover': {
					input: {
						borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
					},
				},
			}}
		>
			{children}
		</Typography>
	);
};

export const InfoRow = ({ children }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',

				'.col': {
					display: 'flex',
					flexDirection: 'column',
					marginRight: '10px',

					label: {
						fontFamily: 'Inter',
						fontSize: '14px',
						fontWeight: '500',
						fontStretch: 'normal',
						fontStyle: 'normal',
						lineHeight: 'normal',
						letterSpacing: 'normal',
						textAlign: 'left',
						color: 'rgba(0, 0, 0, 0.6)',
					},

					span: {
						marginTop: '14px',
						fontFamily: 'Inter',
						fontSize: '14px',
						fontWeight: 'normal',
						fontStretch: 'normal',
						fontStyle: 'normal',
						lineHeight: 'normal',
						letterSpacing: 'normal',
						textAlign: 'left',
						color: 'rgba(0, 0, 0, 0.9)',
					},
				},
			}}
		>
			{children}
		</Box>
	);
};

export const TabPanel = props => {
	return (
		<Box
			{...props}
			sx={{
				flex: 1,
				margin: ` ${props.expanded ? '10px' : '18px 22px 18px 36px'}`,
				borderRadius: `${props.expanded ? '20px' : '10px'}`,
				width: 'auto',
				minHeight: '98%',
				maxHeight: props.maxHeight ?? '98%',
				display: 'flex',
				overflowY: 'auto',
				flexDirection: 'column',

				'.header': {
					display: 'flex',
					alignItems: 'center',
					padding: '10px 18px',
					backgroundColor: '#FFF',
					border: 'solid 1px rgba(196, 196, 196, 0.4)',
					borderTopLeftRadius: '10px',
					borderTopRightRadius: '10px',
					flexDirection: 'row !important',

					'.left': {
						flex: 1,
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',

						'.colored-icon': {
							width: '30px',
							height: '30px',
							flexGrow: 0,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: '100%',
						},

						'.error-icon': {
							backgroundColor: 'rgba(211, 73, 164, 0.2)',
							'*': {
								color: '#d349a4',
							},
						},

						'.info-icon': {
							backgroundColor: 'rgba(29, 187, 255, 0.2)',
							'*': {
								color: '#1DBBFF',
							},
						},

						'.teal-icon': {
							backgroundColor: 'rgba(47, 214, 173, 0.2)',
							'*': {
								color: '#2FD6AD',
							},
						},

						'.success-icon': {
							backgroundColor: 'rgba(23, 180, 67, 0.2)',
							'*': {
								color: '#17B443',
							},
						},

						'.primary-icon': {
							backgroundColor: 'rgba(61, 85, 215, 0.2)',
							'*': {
								color: '#3D55D7',
							},
						},

						'.pink-icon': {
							backgroundColor: 'rgba(169, 106, 207, 0.2)',
							'*': {
								color: '#A96ACF',
							},
						},
					},

					'.right': {
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',

						'.colored-icon': {
							width: '24px',
							height: '24px',
							flexGrow: 0,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: '100%',
							backgroundColor: '#FFFFFF',
						},

						'.active-icon': {
							border: 'solid 1px #4D79EA',
							'*': {
								color: '#4D79EA',
							},
						},

						'.gray-icon': {
							border: 'solid 1px #CDCDCD',
							'*': {
								color: '#CDCDCD',
							},
						},

						span: {
							width: '15px',
							margin: '3px',
						},

						'.active-span': {
							border: 'solid 1px #4D79EA',
						},
						'.gray-span': {
							border: 'solid 1px #CDCDCD',
						},
					},

					h2: {
						margin: '0px 0px 0px 10px !important',
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '18px',
						lineHeight: '22px',
						color: 'rgba(0, 0, 0, 0.9)',
					},
				},

				'.body': {
					overflowY: 'auto',
					flex: 1,
					backgroundColor: '#FFF',
					border: 'solid 1px rgba(196, 196, 196, 0.4)',
					borderBottomLeftRadius: '10px',
					borderBottomRightRadius: '10px',
					borderTopWidth: '0px',

					'&::-webkit-scrollbar-thumb': {
						background: '#C4C4C4 !important',
						borderRadius: '3px',
					},

					'&::-webkit-scrollbar-track': {
						background: '#FFF !important',
					},
				},

				'&::-webkit-scrollbar-thumb': {
					background: '#C4C4C4 !important',
					borderRadius: '3px',
				},

				'&::-webkit-scrollbar-track': {
					background: '#FFF !important',
				},
			}}
		>
			{props.children}
		</Box>
	);
};

export const Collapsible = ({ children }) => {
	return (
		<Box
			sx={{
				'.handle': {
					backgroundColor: 'rgba(77, 121, 234, 0.03)',
					padding: '6px 18px',
					cursor: 'pointer',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',

					span: {
						fontFamily: 'Inter',
						fontSize: '14px',
						fontWeight: '500',
						fontStretch: 'normal',
						fontStyle: 'normal',
						lineHeight: 'normal',
						letterSpacing: 'normal',
						textAlign: 'left',
						color: 'rgba(0, 0, 0, 0.6)',
					},
				},

				'.content': {
					padding: '15px 19px',

					label: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '12px',
						lineHeight: '15px',
						color: 'rgba(0, 0, 0, 0.6)',
						marginBottom: '0px !important',
					},

					span: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '400',
						fontSize: '14px',
						lineHeight: '15px',
						color: 'rgba(0, 0, 0, 0.9)',
					},

					'.right-bordered': {
						borderRight: '1px solid rgba(196, 196, 196, 0.4)',
						marginRight: '20px !important',
						maxWidth: '47%',
					},

					'.row': {
						alignItems: 'flex-start !important',
					},
				},
			}}
		>
			{children}
		</Box>
	);
};

export const HeaderActionButton = props => {
	return (
		<Button
			{...props}
			sx={{
				background: `${props.active ? 'rgba(77, 121, 234, 0.1)' : 'transparent'}`,
				border: `${props.active ? '1px solid rgba(77, 121, 234, 0.2)' : '1px solid #FFF'}`,
				display: 'flex',
				flexDirection: 'column',
				borderRadius: '5px',
				color: `${props.active ? '#4D79EA' : '#C4C4C4'}`,
				fontSize: '12px',
				cursor: 'pointer',
			}}
		>
			{props.children}
		</Button>
	);
};

export const CommentsWrapper = ({ children }) => {
	return (
		<Box
			sx={{
				flex: 1,
				display: 'flex',
				flexDirection: 'column',

				'.comments': {
					flex: 1,
					overflow: 'auto',
				},

				'.comment-input-box': {
					minHeight: '50px',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					margin: '16px 17px',

					'.MuiInputBase-multiline': {
						flex: 1,
						padding: '0px !important',
						color: '#000 !important',
						border: 'none',
					},

					textarea: {
						background: 'rgba(196, 196, 196, 0.15)',
						border: '1px solid rgba(0, 0, 0, 0.05)',
						borderRadius: '10px',
					},
				},
			}}
		>
			{children}
		</Box>
	);
};

export const Comment = ({ children }) => {
	return (
		<Box
			sx={{
				margin: '15px 0px',

				'.wrapper': {
					position: 'relative',
					display: 'flex',
					flexDirection: 'row',
					margin: '0px 18px',

					'&:hover': {
						backgroundColor: 'rgba(196, 196, 196, 0.11)',

						'.menu-btn': {
							display: 'block',
						},
					},
				},

				'.avatar': {
					width: '30px',
					height: '30px',
					borderRadius: '50%',
					background: 'rgba(77, 121, 234, 0.2)',
					alignItems: 'center',
					justifyContent: 'center',
					display: 'flex',
					margin: '6px',

					span: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '14px',
						lineHeight: '17px',
						textAlign: 'center',
						color: '#4D79EA',
					},
				},

				'.main': {
					flex: 1,

					p: {
						margin: '5px 0px',
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '400',
						fontSize: '14px',
						lineHeight: '17px',
						color: '#000000',
					},

					'.info': {
						display: 'flex',
						flexDirection: 'row',
						marginTop: '10px',

						'span.name': {
							fontFamily: 'Inter',
							fontStyle: 'normal',
							fontWeight: '500',
							fontSize: '12px',
							lineHeight: '15px',
							color: '#4D79EA',
						},

						'span.datetime': {
							fontFamily: 'Inter',
							fontStyle: 'normal',
							fontWeight: '500',
							fontSize: '12px',
							lineHeight: '15px',
							textAlign: 'right',
							color: 'rgba(0, 0, 0, 0.6)',
							marginLeft: '17px',
						},

						'span.private': {
							fontFamily: 'Inter',
							fontStyle: 'normal',
							fontWeight: '500',
							fontSize: '12px',
							lineHeight: '15px',
							textAlign: 'right',
							color: '#4D79EA',
							marginLeft: '5px',
							alignContent: 'center',
						},
					},
				},

				'.menu-btn': {
					position: 'absolute',
					top: '-13px',
					right: '18px',
					display: 'none',

					'.icon-btn': {
						background: '#FBFBFB',
						border: '1px solid #FFFFFF',
						boxShadow: '-2px 4px 5px  rgba(0, 0, 0, 0.05)',
						marginLeft: '8px',
						padding: '6px',

						'.icon': {
							fontSize: 12,
						},

						'&:hover': {
							color: '#4D79EA',
						},
					},

					'& .tooltip': {
						backgroundColor: '#4D79EA',
					},
				},
			}}
		>
			{children}
		</Box>
	);
};

export const FilesWrapper = ({ children, sx }) => {
	return (
		<Box
			sx={{
				...sx,

				'.file-item': {
					background: '#F9FAFF',
					borderRadius: '10px',
					padding: '12px',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					width: '100px',
					position: 'relative',

					'&:hover': {
						'.menu': {
							display: 'block',
						},

						'.menu-btn': {
							display: 'block',
						},
					},

					'.menu': {
						position: 'absolute',
						top: '0px',
						right: '0px',
						display: 'none',

						'.icon-btn': {
							padding: '4px',

							'.icon': {
								fontSize: 14,

								'&:hover': {
									color: '#4D79EA',
								},
							},
						},
					},

					'.menu-btn': {
						position: 'absolute',
						top: '-13px',
						right: '15px',
						display: 'none',

						'.icon-btn': {
							background: '#FBFBFB',
							border: '1px solid #FFFFFF',
							boxShadow: '-2px 4px 5px  rgba(0, 0, 0, 0.05)',
							marginLeft: '8px',
							padding: '6px',

							'.icon': {
								fontSize: 12,

								'&:hover': {
									color: '#4D79EA',
								},
							},
						},

						'& .tooltip': {
							backgroundColor: '#4D79EA',
						},
					},

					'.main': {
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						justifyContents: 'center',
						marginLeft: '15px',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					},

					label: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '14px',
						lineHeight: '17px',
						color: '#4D79EA',
					},

					span: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '12px',
						lineHeight: '15px',
						color: 'rgba(0, 0, 0, 0.6)',
					},

					'.progress-bar': {
						borderRadius: '10px',
						position: 'absolute',
						bottom: '0px',
						left: '0px',
						height: '3px',
						background: '#4DE3AF',
					},
				},
			}}
		>
			{children}
		</Box>
	);
};
export const ContactListItem = ({ children }) => {
	return (
		<Box
			sx={{
				margin: '10px 10px 0px 10px',
				background: '#F9FAFF',
				borderRadius: '10px',
				padding: '19px 10px',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				position: 'relative',

				'.main': {
					flex: 1,
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',

					h3: {
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '16px',
						lineHeight: '24px',
						margin: '0px !important',
						color: 'rgba(0, 0, 0, 0.9)',
					},

					label: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '400',
						fontSize: '14px',
						lineHeight: '19px',
						color: 'rgba(0, 0, 0, 0.4)',
					},
				},

				'.initials-avatar': {
					background: 'rgba(77, 121, 234, 0.2)',
					width: '35px',
					height: '35px',
					borderRadius: '50%',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',

					span: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '16px',
						lineHeight: '19px',
						textAlign: 'center',
						color: '#4D79EA',
					},
				},

				'.f-row': {
					display: 'flex',
					margin: '0px !important',
					flexDirection: 'row',
					alignItems: 'center',
				},
			}}
		>
			{children}
		</Box>
	);
};

export const ContactAvatar = props => {
	return (
		<Avatar
			{...props}
			sx={{
				width: '76px',
				height: '73px',
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundImage: `url(${props.src})`,
				borderRadius: '5px',
				marginRight: '20px',
			}}
		/>
	);
};

export const ContactText = ({ children }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',

				'& > span': {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: '400',
					fontSize: '14px',
					lineHeight: '17px',
					color: 'rgba(0, 0, 0, 0.9) !important',
				},
			}}
		>
			{children}
		</Box>
	);
};

export const KnowledgeListItem = ({ children }) => {
	return (
		<Box
			sx={{
				margin: '10px 10px 0px 10px',
				background: '#FFF',
				borderRadius: '10px',
				padding: '19px 24px',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				border: '1px solid rgba(0, 0, 0, 0.05)',

				'.main': {
					flex: 1,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					marginLeft: '27px',

					h3: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '14px',
						lineHeight: '17px',
						margin: '0px !important',
						color: '#4D79EA',
					},

					label: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '400',
						fontSize: '12px',
						lineHeight: '15px',
						color: '#000',
					},

					small: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '12px',
						lineHeight: '20px',
						textAlign: 'right',
						letterSpacing: '0.015em',
						color: 'rgba(0, 0, 0, 0.6)',
						alignSelf: 'flex-end',
						marginTop: '8px',
					},
				},
			}}
		>
			{children}
		</Box>
	);
};

export const DottedWrapper = ({ children }) => {
	return (
		<Box
			sx={{
				borderRadius: '10px',
				border: '1px dashed #4D79EA',
				margin: '10px',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',

				'& > :first-of-type': {
					marginRight: '0px',

					svg: {
						transform: 'rotateY(180deg)',
					},
				},

				'.card': {
					flex: 1,
					height: '81px',
					margin: '15px 16px 15px 16px',
					border: '1px solid #4D79EA',
					borderRadius: '5px',
					cursor: 'pointer',
					color: '#4D79EA',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',

					svg: {
						fontSize: '24px',
					},

					span: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '600',
						fontSize: '14px',
						lineHeight: '17px',
						textAlign: 'center',
						color: '#4D79EA',
						marginTop: '5px',
					},

					'&:hover, &.active': {
						background: 'rgba(77, 121, 234, 0.2)',
					},
				},

				'.button': {
					flex: 1,
					height: '47px',
					cursor: 'pointer',
					color: '#4D79EA',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					borderRadius: '10px',

					svg: {
						fontSize: '24px',
					},

					span: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '600',
						fontSize: '14px',
						lineHeight: '17px',
						textAlign: 'center',
						color: '#4D79EA',
						marginTop: '5px',
					},

					'&:hover, &.active': {
						background: 'rgba(77, 121, 234, 0.2)',
					},
				},
			}}
		>
			{children}
		</Box>
	);
};

export const NewContactFormWrapper = ({ children }) => {
	return (
		<Box
			sx={{
				border: '1px solid #4D79EA',
				display: 'flex',
				flexDirection: 'row',
				width: 'auto',
				position: 'relative',
				alignItems: 'center',
				padding: '19px 28px',
				margin: '4px 10px 10px',
				borderRadius: '10px',

				'.action-btn': {
					width: '76px',
					height: '73px',
					left: '60px',
					top: '565px',
					background: 'rgba(77, 121, 234, 0.2)',
					borderRadius: '5px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					border: '1px solid rgba(77, 121, 234, 0.2)',

					svg: {
						fontSize: '25px',
						color: 'rgba(77, 121, 234, 0.75)',
					},
				},

				'.upload': {
					background: '#FFF !important',
					cursor: 'pointer',
					border: '1px solid #4D79EA',

					span: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '14px',
						lineHeight: '17px',
						color: '#4D79EA',
					},

					svg: {
						color: '#4D79EA',
					},
				},

				'.row': {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					margin: '0px 10px 10px 30px',
					gap: '10px',

					'.col': {
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						width: '120px',

						'.f-row': {
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',

							svg: {
								fontSize: '15px',
								color: '#4D79EA',
								marginRight: '13px',
							},
						},
					},
				},

				'input.large': {
					fontSize: '24px !important',
				},

				'input, input.large': {
					color: '#000',
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: '500',
					lineHeight: '24px',
					fontSize: '14px',
					border: 'none',
				},
			}}
		>
			{children}
		</Box>
	);
};

export const ExistingContactsWrapper = ({ children }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				margin: '0px 10px 30px 10px',

				'.list-wrapper': {
					flex: 1,
					display: 'flex',
					flexDirection: 'column',

					'.contact-list': {
						flex: 1,
					},

					button: {
						svg: {
							fontSize: '17px',
							color: '#4D79EA',
						},
					},
				},

				'.alphabets': {
					display: 'flex',
					flexDirection: 'column',
					width: '20px',
					marginTop: '15px',

					span: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '12px',
						lineHeight: '15px',
						textAlign: 'center',
						color: '#4D79EA',
						marginBottom: '3px',

						'&:hover': {
							fontSize: '16px',
							cursor: 'pointer',
						},
					},
				},
			}}
		>
			{children}
		</Box>
	);
};

export const ContactGroupWrapper = ({ children }) => {
	return (
		<Box
			sx={{
				marginBottom: '20px',
				'.container': {
					flex: 1,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
				},

				'.label': {
					borderBottom: '1px solid rgba(196, 196, 196, 0.15)',

					span: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '16px',
						lineHeight: '19px',
						textTransform: 'uppercase',
						color: '#4D79EA',
						marginBottom: '10px',
						marginLeft: '30px',
					},
				},

				h3: {
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: '500',
					fontSize: '16px',
					lineHeight: '19px',
					color: '#4D79EA',
					margin: '0px 0px 0px 17px',
				},

				h5: {
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: '500',
					fontSize: '12px',
					lineHeight: '15px',
					color: '#C4C4C4',
					margin: '0px 0px 0px 17px',
				},

				'.row': {
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
					alignItems: 'center',
					marginLeft: 'auto',
				},

				'.f-row': {
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
					alignItems: 'center',
				},

				'.f-row.bottom': {
					margin: '7px 0px',
					justifyContent: 'flex-start',
				},

				'.f-row.info': {
					svg: {
						fontSize: '12px',
						color: '#4D79EA',
						margin: '0px 5px 0px 5px',
					},

					span: {
						fontFamily: 'Inter',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '12px',
						lineHeight: '15px',
						color: 'rgba(0, 0, 0, 0.9)',
					},
				},

				'.add-btn': {
					height: '28px',
					background: '#4D79EA',
					borderRadius: '5px',
					border: '0px',
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '17px',
					textAlign: 'center',
					letterSpacing: '1.5px',
					textTransform: 'uppercase',
					color: '#FFFFFF',
					marginLeft: 'auto',
					marginRight: '10px',
					cursor: 'pointer',
				},
			}}
		>
			{children}
		</Box>
	);
};

export const CaseHandlerWrapper = ({ children }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				padding: '0px 5px',

				'& .search-bar': {
					height: '36px',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					cursor: 'pointer',
				},

				'ul.case-handlers': {
					height: '230px',
					overflowY: 'auto',
					overflowX: 'hidden',
					padding: '3px !important',

					'&::-webkit-scrollbar-thumb': {
						background: '#C4C4C4 !important',
						borderRadius: '3px',
					},

					'&::-webkit-scrollbar-track': {
						background: '#FFF !important',
					},

					li: {
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						cursor: 'pointer',
						marginBottom: '7px',

						span: {
							fontFamily: 'Inter',
							fontStyle: 'normal',
							fontWeight: '500',
							fontSize: '14px',
							lineHeight: '17px',
							marginLeft: '10px',
							color: 'rgba(0, 0, 0, 0.6)',
						},
					},
				},
			}}
		>
			{children}
		</Box>
	);
};

export const BlumeIDWrapper = ({ children }) => {
	return (
		<Box
			sx={{
				backgroundImage: 'linear-gradient(to bottom, #f9faff 70%, rgba(249, 250, 255, 0) 100%)',
				margin: '13.5px 15px 18.5px 11px',
				borderRadius: '10px',
				flex: 1,
				display: 'flex',
				flexDirection: 'column',

				'& .card': {
					margin: '0px auto',
					marginTop: '44px',
					width: '292px',
					borderRadius: '10px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					paddingBottom: '10px',
				},

				'& .card > img': {
					height: '50px',
					width: '50px',
					borderRadius: '50%',
				},

				'& .card > h5': {
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: '500',
					fontSize: '20px',
					lineHeight: '12px',
					color: 'rgba(0, 0, 0, 0.9)',
					margin: '12px 0px 9px 0px',
				},

				'& .card > .hint': {
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: '400',
					fontSize: '12px',
					lineHeight: '15px',
					letterSpacing: '-0.02em',
					color: 'rgba(18, 18, 18, 0.6)',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',

					span: {
						marginLeft: '5px',
					},
				},

				'& > h3': {
					marginTop: '30px',
					marginBottom: '5px',
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: '600',
					fontSize: '18px',
					lineHeight: '22px',
					textAlign: 'center',
					color: '#4D79EA',
				},

				'& > span': {
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: '400',
					fontSize: '12px',
					lineHeight: '15px',
					textAlign: 'center',
					color: '#C4C4C4',
				},

				'& > div.row': {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: '24px',
				},

				'& .row > button': {
					margin: '0px',
					height: '33px',
					background: '#4D79EA',
					borderRadius: '5px',
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '17px',
					textAlign: 'center',
					letterSpacing: '1.5px',
					textTransform: 'uppercase',
					cursor: 'pointer',
				},

				'& .row > button.outline': {
					background: '#FFF',
					border: '1px solid #4D79EA',
					color: '#4D79EA',
					marginLeft: '10px',
				},

				'& .row > button.fill': {
					background: '#4D79EA',
					border: '1px solid #4D79EA',
					color: '#FFF',
					marginLeft: '10px',
				},
			}}
		>
			{children}
		</Box>
	);
};

export const StyledDialPadInput = styled(PhoneInput)(({ theme }) => ({
	'&.react-tel-input .form-control': {
		height: '6.2vh',
		width: '100%',
		border: 'none',
		color: '#4D79EA',
		textAlign: 'center',
		fontSize: '30px',
		fontFamily: 'Inter',
	},
	'&.react-tel-input .selected-flag': {
		width: '70px',
		border: 'none',
		borderRadius: '10px 0px 0px 10px',
	},
	'&.react-tel-input .country-list': {
		width: '405px',
		'::-webkit-scrollbar-track': {
			background: 'none',
		},
	},
	'&.react-tel-input .flag-dropdown.open .selected-flag ': {
		background: '#E5E5E5',
	},

	'&.react-tel-input': {
		'.flag': { width: '25px', height: '20px' },
		'.special-label': { display: 'none' },
		'.form-control': {
			fontSize: '30px',
			borderRadius: '10px',
			paddingLeft: '75px',
			opacity: '0.5',
		},
		'.selected-flag .arrow': { left: '35px' },
		'.open': {
			background: '#FFFFFF !important',
		},
	},
	'&.react-tel-input .country-list ': {
		'.country.highlight': { backgroundColor: '#42A5F533' },
		'.country': { height: '32px' },
		'.country-name': {
			marginRight: '10px',
			marginLeft: '30px',
		},
		'.flag': { marginTop: '-1px' },
	},
}));

export const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
	'&.react-tel-input .form-control': {
		height: '47px',
		width: '100%',
		border: 'none',
	},
	'&.react-tel-input .selected-flag': {
		width: '70px',
		border: 'none',
		borderRadius: '10px 0px 0px 10px',
	},
	'&.react-tel-input .country-list': {
		width: '405px',
		'::-webkit-scrollbar-track': {
			background: 'none',
		},
	},
	'&.react-tel-input .flag-dropdown.open .selected-flag ': {
		background: '#E5E5E5',
	},

	'&.react-tel-input': {
		'.flag': { width: '25px', height: '20px' },
		'.special-label': { display: 'none' },
		'.form-control': {
			fontSize: '14px',
			borderRadius: '10px',
			paddingLeft: '75px',
			opacity: '0.5',
		},
		'.selected-flag .arrow': { left: '35px' },
		'.open': {
			background: '#FFFFFF !important',
		},
	},
	'&.react-tel-input .country-list ': {
		'.country.highlight': { backgroundColor: '#42A5F533' },
		'.country': { height: '32px' },
		'.country-name': {
			marginRight: '10px',
			marginLeft: '30px',
		},
		'.flag': { marginTop: '-1px' },
	},
}));

export const FeaturesBox = ({ children }) => {
	return (
		<Box
			sx={{
				'.handle': {
					backgroundColor: 'rgba(77, 121, 234, 0.03)',
					padding: '8px 18px',
					display: 'flex',
					flexDirection: 'row',

					span: {
						fontFamily: 'Inter',
						fontSize: '14px',
						fontWeight: '500',
						fontStretch: 'normal',
						fontStyle: 'normal',
						lineHeight: 'normal',
						letterSpacing: 'normal',
						textAlign: 'left',
						color: 'rgba(0, 0, 0, 0.6)',
					},
				},
			}}
		>
			{children}
		</Box>
	);
};

export const FeatureItemBox = props => {
	return (
		<Box
			sx={{
				boxSizing: 'border-box',
				width: '97%',
				marginBottom: '8px',
				marginLeft: '10px',
				padding: '16px 0 12px 14px',
				display: 'flex',
				backgroundColor: '#FFFFFF',
				borderRadius: '10px',
				border: '1px solid rgba(196, 196, 196, 0.5)',
				'&:hover': {
					backgroundColor: 'rgba(77, 121, 234, 0.06)',
					border: '1px solid #4D79EA;',
				},
				flex: 1,

				'& .item-body': {
					flex: 1,
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				},
			}}
		>
			{props.children}
		</Box>
	);
};

export const SubTitle = props => {
	return (
		<Typography
			component={'h5'}
			sx={{
				fontFamily: 'Inter',
				fontSize: '18px',
				fontWeight: '500',
				fontStretch: 'normal',
				fontStyle: 'normal',
				lineHeight: 'normal',
				letterSpacing: 'normal',
				textAlign: 'left',
				color: '#4d79ea',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',

				'& > span': {
					marginRight: '11px',
				},
			}}
			{...props}
		>
			{props.children}
		</Typography>
	);
};
