import styled from 'styled-components';
import { mediaTablet } from '@worklist-2/core/src';

export const mainBox = {
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'center',
	overflow: 'hidden',
	overflowY: 'auto',

	'@media (max-width: 600px)': {
		'& #close-btn': {
			background: 'rgba(18, 18, 18, 0.1)',
			width: '80px',
			height: '5px',
			borderRadius: '7px',
			right: 'inherit',
			padding: '0px',

			svg: {
				display: 'none',
			},
		},
	},
};

export const boxStyle = {
	flexDirection: 'column',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	minWidth: 299,
	minHeight: 451,
	maxWidth: '603px',
	margin: 'auto',
};

export const header = {
	width: '468px',
	height: '56px',
	opacity: 0.87,
	fontFamily: 'Roboto',
	fontSize: '40px',
	fontWeight: 'normal',
	fontStretch: 'normal',
	fontStyle: 'normal',
	lineHeight: 1.4,
	letterSpacing: 'normal',
	textAlign: 'center',
	color: '#000',
	margin: '36px',
	[mediaTablet]: {
		fontSize: '34px',
		lineHeight: '56px',
	},
	'@media (max-width: 600px)': {
		fontWeight: 400,
		fontSize: '24px',
		lineHeight: '28px',
		textAlign: 'center',
		color: '#121212',
		opacity: 0.87,
		margin: '31px 0px 15px 0px !important',
		height: 'inherit',
	},
};

export const resultsStyle = {
	minWidth: '520px',
	height: 'calc(100vh - 426px)',
	overflow: 'hidden',
	flexGrow: 0,
	borderRadius: '5px',
	backgroundColor: '#fff',
	padding: '5px 45px',
	maxWidth: '603px',
	[mediaTablet]: {
		height: 'unset',
		maxHeight: '440px',
	},
	'@media (max-width: 600px)': {
		maxWidth: '328px !important',
		minWidth: '328px !important',
		margin: 'auto',
		padding: '8px 5px !important',
	},
};

export const BackDrop = styled.div`
	width: 100%;
	height: 100%;
	background-color: rgb(227 227 230 / 48%);
	backdrop-filter: blur(6px);
	display: flex;
	flex-direction: column;
`;

export const searchBarStyle = {
	p: '2px 4px',
	display: 'flex',
	alignItems: 'center',
	width: 603,
	background: '#FFF',
	marginBottom: '19px',

	'@media (max-width: 600px)': {
		width: '328px',
		height: '36px',
		overflow: 'hidden',
		border: '1px solid rgba(112, 112, 112, 0.15)',
		borderRadius: '5px',
		paddingBottom: '5px !important',

		'& > button': {
			display: 'none',
		},

		'& > svg': {
			marginLeft: '14px',
		},

		'& > input': {
			margin: '0px 0px 0px -20px !important',
		},
	},
};

export const blueBox = {
	width: '-webkit-fill-available',
	height: '173px',
	padding: '13px 176px 21px 169px',
	borderRadius: '5px',
	backgroundColor: '#42a5f5',
	marginTop: '20px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	color: '#FFF',
	fontFamily: 'Roboto',
	textAlign: 'center',
	cursor: 'pointer',
	marginBottom: '70px',
	[mediaTablet]: {
		height: '127px',
		marginBottom: '16px !important',
	},
	'@media (max-width: 600px)': {
		padding: '10px',
		minHeight: '95px',
		width: '280px !important',
		maxWidth: '280px !important',
		overflow: 'hidden',
		marginBottom: '53px !important',

		'& > span': {
			marginTop: '3px !important',
		},
	},
};

export const supportInfo = {
	width: '-webkit-fill-available',
	padding: '13px 176px 21px 169px',
	borderRadius: '5px',
	backgroundColor: '#FFF',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	color: '#42a5f5',
	fontFamily: 'Roboto',
	textAlign: 'center',
};

export const Label = styled.span`
	opacity: 0.2;
	font-family: Roboto;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: 0.4px;
	text-align: center;
	color: #121212;
`;

export const SupportText = styled.span`
	font-family: Roboto;
	font-size: 22px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.09;
	letter-spacing: 0.4px;
	text-align: center;
	color: #42a5f5;

	@media (max-width: 600px) {
		font-weight: 400;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.4px;
	}
`;

export const ListItem = styled.div`
	margin-bottom: 29.5px;
	font-family: Roboto;
	margin: 0px;

	@media (max-width: 600px) {
		padding-left: 15px;
	}

	.category {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: 1.25px;
		text-align: left;
		color: #000;
		text-transform: uppercase;
	}

	p {
		font-size: 20px;
		margin-top: 5px;
		color: #000;
		font-weight: normal;
	}

	${mediaTablet} {
		p {
			font-size: 14px;
		}
	}
`;

export const ResultContent = styled.div`
	max-height: 80%;
	overflow-y: auto;
	padding-bottom: 10px;
	font-family: Roboto;
	font-weight: 300;
	font-stretch: normal;
	margin: 30px 0px;

	@media (max-width: 600px) {
		margin: 10px 0px;
		max-height: 96%;

		&.flex-col-sm {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		p.title {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.4px;
			text-transform: uppercase;
			color: #000000;
			margin-left: 13px;
			margin-bottom: 15px;
		}

		h3 {
			font-style: normal !important;
			font-weight: 400 !important;
			font-size: 14px !important;
			line-height: 16px !important;
			text-align: center !important;
			letter-spacing: 0.4px !important;
		}

		p.hint {
			font-weight: 400 !important;
			font-size: 12px !important;
			line-height: 18px !important;
			text-align: center !important;
			letter-spacing: 0.4px !important;
			color: #121212 !important;
			opacity: 0.4 !important;
			margin: 0px !important;
		}
	}

	.title {
		margin-bottom: 11px;
		font-family: Roboto;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1;
		letter-spacing: 0.4px;
		text-align: left;
		color: #000;
	}

	h3 {
		font-family: Roboto;
		font-size: 24px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 0.67;
		letter-spacing: 0.4px;
		text-align: center;
		color: #42a5f5;
		margin-top: -20px;
	}

	p.hint {
		opacity: 0.2;
		font-family: Roboto;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: 0.4px;
		text-align: center;
		color: #121212;
		max-width: 380px;
		margin: auto;
	}

	::-webkit-scrollbar {
		width: 7px;
		height: 7px;
	}
	::-webkit-scrollbar-button {
		width: 0px;
		height: 0px;
	}
	::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border: 0px none #c4c4c4;
		border-radius: 50px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #a39f9f;
	}
	::-webkit-scrollbar-thumb:active {
		background: #a39f9f;
	}
	::-webkit-scrollbar-track {
		background: rgb(196 196 196 / 17%);
		border: 0px none #ffffff;
		border-radius: 50px;
	}
	::-webkit-scrollbar-track:hover {
		background: rgb(196 196 196 / 17%);
	}
	::-webkit-scrollbar-track:active {
		background: rgb(196 196 196 / 17%);
	}
	::-webkit-scrollbar-corner {
		background: transparent;
	}
`;

export const QuestionSubmit = styled.div`
	min-width: 520px;
	border-radius: 5px;
	background-color: #fff;
	padding: 10px 45px;
	max-width: 603px;
	text-align: center;

	@media (max-width: 600px) {
		max-width: 328px !important;
		min-width: 328px !important;
		margin: auto;
		padding: 8px 5px !important;

		form > svg {
			font-size: 28px !important;
		}

		.text {
			display: grid;
			grid-template-rows: repeat(4, 70px) !important;
		}
	}

	p.hint {
		opacity: 0.2;
		font-family: Roboto;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: 0.4px;
		text-align: center;
		color: #121212;
		max-width: 380px;
		margin: auto;
		margin-bottom: revert;
	}

	.text {
		display: grid;
		grid-template-rows: repeat(3, 70px);
	}
`;

export const MoreLink = styled.a`
	height: 32px;
	margin-bottom: 20px;
	font-family: Roboto;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.67;
	letter-spacing: 0.4px;
	text-align: center;
	color: #42a5f5;
	cursor: pointer;

	@media (max-width: 600px) {
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		text-align: left;
		margin: 20px 29px 20px 25px;
	}
`;

export const IconWrapper = styled.div`
	width: 200px;
	height: 200px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;
	@media (max-width: 600px) {
		margin: 0px !important;
		& > svg {
			filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
		}
	}
`;

export const ChatWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;

	&.patient-chat {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow-y: auto;
		width: 95%;
		margin: auto;
		padding-top: 10px;

		.chat-start-wrapper {
			background: rgba(112, 112, 112, 0.05);
			border: 1px solid rgba(112, 112, 112, 0.1);
			border-radius: 5px;
			flex-direction: row !important;
			width: 300px !important;
			margin: auto;
			margin-bottom: 10px;
			padding: 10px;
			display: flex;
			align-items: center;
			justify-content: center;

			h3 {
				text-align: left !important;
				margin: 0px !important;
				font-weight: 400;
			}

			* {
				color: rgba(18, 18, 18, 0.6) !important;
			}
		}

		.chat-box {
			margin-bottom: 23px;

			.input-wrapper {
				box-shadow: none !important;
				align-items: center;

				input {
					background: rgba(196, 196, 196, 0.15);
					border: 1px solid rgba(0, 0, 0, 0.05);
					border-radius: 5px;
					color: #000;
				}

				button.send {
					margin-left: 15px;
				}

				button.share {
					margin-right: 15px;
				}
			}
		}
	}

	.chat-start-wrapper {
		text-align: center;
	}

	.chat-content-wrapper {
		flex-direction: column;
		display: flex;
		flex: 1;
		overflow-y: auto;
		overflow-x: hidden;
		max-height: 65vh;
	}

	.chat-box {
		padding: 10px 0px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
`;

export const ChatBubble = styled.div`
	display: flex;
	flex-direction: ${({ isUser }) => (isUser ? 'row-reverse' : 'row')};
	margin: 10px;
	width: 96%;

	.content {
		padding: 20px;
		border-radius: 5px;
		box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
		background-color: ${({ isUser }) => (isUser ? '#42a5f5' : '#e6e6e6')};
		color: ${({ isUser }) => (isUser ? '#FFF' : '#000')};
		max-width: 60%;
		font-family: Roboto;
		font-size: 14px;

		&.patient-chat-bubble {
			box-shadow: none;
			background-color: ${({ isUser }) => (isUser ? 'rgba(112, 112, 112, 0.05)' : '#445461')};
			color: ${({ isUser }) => (isUser ? '#000' : '#FFF')};
		}
	}
`;

export const InputWrapper = styled.div`
	flex: 1;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	box-shadow: inset 0 4px 10px 0 rgba(0, 0, 0, 0.25);
`;

export const DrawerHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.line {
		width: 95.5px;
		height: 1px;
		background-color: #fff;
		margin-left: -100px;
		cursor: pointer;
	}

	.line:hover,
	.line:focus {
		height: 5px;
		border-radius: 4px;
	}

	.left {
		display: flex;
		flex-direction: row;
		align-items: center;

		span.label {
			opacity: 0.6;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: #fff;
			margin-left: 10px;
		}
	}
`;

export const DrawerContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;

	.left {
		border-right: 1px solid #ffffff20;
	}

	.right {
		flex: 1;
	}

	.left,
	.right {
		padding: 20px 50px;

		.row {
			-ms-box-orient: horizontal;
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: -moz-flex;
			display: -webkit-flex;
			display: flex;
			max-width: 100%;
			overflow-x: auto;
			-webkit-flex-wrap: wrap;
			flex-wrap: wrap;
		}

		img {
			width: 282px;
			height: 141px;
			background: #d7d7d7;
			border: solid 1px #d7d7d7;

			&:hover {
				cursor: pointer;
				border: solid 1px #42a5f5;
			}
		}

		p,
		h5 {
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			color: #fff;
		}

		h5 {
			font-size: 16px;
			font-weight: bold;
			text-align: left;
		}
	}
`;

export const SearchResultInfoText = styled.h1`
	font-family: Roboto;
	font-size: 24px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #fff;
`;

export const SearchResultList = styled.div`
	max-height: 100vh;
	overflow-y: auto;
	border-top: 1px solid #363636;
	margin-bottom: 37px;
`;

export const SearchResultListItem = styled.div`
	cursor: pointer;
	h3 {
		font-family: Roboto;
		font-size: 24px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #42a5f5;
		margin-bottom: 0px;
	}

	span.path {
		opacity: 0.6;
		font-family: Roboto;
		font-size: 12px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 2;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
	}

	p {
		font-family: Roboto;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
	}
`;

export const EmptyResultWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 70vh;

	p {
		color: #fff;
		font-family: Roboto;
		text-align: center;
	}
`;
