import React from 'react';

import {
	BulletListBlock,
	CalloutBlock,
	CheckListBlock,
	HeaderBlock,
	NumberListBlock,
	ParagraphBlock,
	TableBlock,
} from '../../../../index';

const MergedBlocks = ({ theme, block, setDisplayMode, type }) => {
	const getBlockType = blockItem => {
		switch (blockItem.type) {
			case 'Header':
				return <HeaderBlock block={blockItem} theme={theme} />;
			case 'Paragraph':
				return <ParagraphBlock block={blockItem} theme={theme} />;
			case 'Quote':
				return <HeaderBlock block={blockItem} theme={theme} />;
			case 'Callout':
				return <CalloutBlock block={blockItem} theme={theme} />;
			case 'Bullet List':
				return <BulletListBlock block={blockItem} theme={theme} />;
			case 'Number List':
				return <NumberListBlock block={blockItem} theme={theme} />;
			case 'Check List':
				return <CheckListBlock block={blockItem} theme={theme} />;
			case 'Table':
				return <TableBlock block={blockItem} theme={theme} />;
			default:
		}
	};

	return (
		<div onMouseOver={() => !type && setDisplayMode && setDisplayMode({ type: 'hover' })}>
			{block.mergedBlocks.map(item => getBlockType(item))}
		</div>
	);
};

export default MergedBlocks;
