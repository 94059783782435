import React from 'react';
import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';
import { Svg } from '@rs-components/Svg';
import * as icons from '@mui/icons-material';
export const PriorityCodes = {
	'0': 'STAT',
	'10': 'URGENT',
	'5': 'ASAP',
	'20': 'ROUTINE',
};

export const AppointmentPriorityCodes = {
	'0': '6',
	'10': '1',
	'5': '3',
	'20': '9',
};

export function getKeyByValue(object, value) {
	return Object.keys(object).find(key => object[key] === value);
}

export const formatExtensionsPriority = (obj, isPartOfArray = false, priority) => {
	const colorValue = isPartOfArray
		? obj?.color
		: obj?.color ||
		  obj?.extension?.find(item => item.url.includes(fhirExtensionUrls.studyPriority.color)).valueString;
	const iconValue = isPartOfArray
		? obj?.icon
		: obj?.icon ||
		  obj?.extension?.find(item => item.url.includes(fhirExtensionUrls.studyPriority.icon)).valueString;
	const priorityCode = isPartOfArray
		? obj?.priorityCode
		: obj?.priorityCode || getKeyByValue(PriorityCodes, priority?.toUpperCase());
	return {
		id: obj?.id,
		display: obj?.display,
		color: colorValue,
		icon: iconValue,
		priorityCode: priorityCode,
	};
};
export const defaultPriority = {
	id: -1,
	display: 'ROUTINE',
	color: '#42a5f51a',
	icon: 'routineIcon',
};

export const defaultPriorities = [
	{ id: -1, display: 'STAT', color: '#cf66791a', icon: 'statIcon', priorityCode: 0 },
	{ id: -1, display: 'ASAP', color: '#cf66791a', icon: 'asapIcon', priorityCode: 5 },
	{ id: -1, display: 'URGENT', color: '#cf66791a', icon: 'urgentIcon', priorityCode: 10 },
	{ id: -1, display: 'ROUTINE', color: '#42a5f51a', icon: 'routineIcon', priorityCode: 20 },
];

export const addCustomPriorityExtension = (payload, customPriority, isPartOfAppointment) => {
	const extensions = [...(payload?.extension || [])];
	const indexNumber = extensions.findIndex(item =>
		item?.url?.includes(fhirExtensionUrls.studyPriority.customPriority)
	);

	const modifiedExtension = {
		url: fhirExtensionUrls.studyPriority.customPriority,
		valueReference: {
			id: customPriority?.id || extensions?.[indexNumber]?.valueReference?.id,
			reference: `CustomPriority/${customPriority?.id || extensions?.[indexNumber]?.valueReference?.id}`,
			display: customPriority?.display || extensions?.[indexNumber]?.valueReference?.display,
		},
	};
	if (indexNumber > -1) {
		extensions[indexNumber] = modifiedExtension;
	} else {
		extensions.push(modifiedExtension);
	}
	if (isPartOfAppointment) {
		const customPriorityExtension = extensions.find(item =>
			item?.url?.includes(fhirExtensionUrls.studyPriority.customPriority)
		);
		return { customPriorityExtension };
	}
	payload.extension = extensions;
	return payload;
};

export const renderPreDefinedIcons = icon => {
	switch (icon) {
		case 'statIcon':
			return <Svg name="statIcon" sx={styles.statIcon} />;

		case 'urgentIcon':
			return <Svg name="urgentIcon" sx={styles.urgentIcon} />;

		case 'asapIcon':
			return <Svg name="asapIcon" sx={styles.asapIcon} />;

		case 'routineIcon':
			return <Svg name="routineIcon" sx={styles.routineIcon} />;

		default:
	}
};

export const renderPriorityIcon = option => {
	let Icon;
	let preDefinedIcons;
	if (['statIcon', 'urgentIcon', 'asapIcon', 'routineIcon'].includes(option?.icon)) {
		preDefinedIcons = renderPreDefinedIcons(option?.icon);
	} else {
		Icon = icons && icons[option?.icon];
	}
	if (preDefinedIcons) {
		return preDefinedIcons;
	}
	return <Icon sx={{ width: '16px', height: '16px' }} />;
};

export const styles = {
	flexWithCenter: { display: 'flex', alignItems: 'center' },
	chipStyle: { display: 'flex', flexWrap: 'wrap', gap: 0.5 },
	stackCustomStyle: { alignItems: 'center', gap: 1 },
	statIcon: { width: '16px', height: '12px' },
	urgentIcon: { width: '16px', height: '16px' },
	asapIcon: { width: '16px', height: '14px' },
	routineIcon: { width: '10px', height: '4px' },
	staticIconStyle: ({ color }) => ({
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		mr: '8px',
		width: '24px',
		height: '24px',
		borderRadius: '50px !important',
		background: color,
		border: '1px solid rgba(207, 102, 121, 0.2) !important',
	}),
	inputAdornmentStyles: { ml: '2px', mr: '0px', paddingRight: '8px' },
	inputAdornmentChipStyles: color => ({
		backgroundColor: color,
		width: '24px',
		height: '24px',
		paddingRight: '16px',
		paddingTop: '4px',
	}),
};

export const renderCustomPriority = ({ priority, selectedCustomPriority, customPriorities }) => {
	if (selectedCustomPriority) {
		return selectedCustomPriority;
	} else {
		return defaultPriorities.find(item => item.display === (priority?.display || priority?.toUpperCase()));
	}
};
