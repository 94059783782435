import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { Controlled as CodeMirror } from 'react-codemirror2';
import LastPageIcon from '@mui/icons-material/LastPage';
import ScriptSection from './common/ScriptSection';
import './common/ScriptStyles.css';

const EDITOR_THEME_NAME = 'material-darker';
require('codemirror/lib/codemirror.css');

require(`codemirror/theme/${EDITOR_THEME_NAME}.css`);
require('codemirror/mode/javascript/javascript.js');

const editorOptions = {
	lineNumbers: true,
	mode: 'javascript',
	theme: EDITOR_THEME_NAME,
	readOnly: true,
};

const Testing = ({ code, setIsTestRun }) => (
	<>
		<Box sx={{ marginBottom: '17px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
			<Typography
				sx={{
					fontFamily: 'Inter',
					fontWeight: 500,
					fontSize: '18px',
					color: 'rgba(0, 0, 0, 0.9)',
				}}
			>
				Testing
			</Typography>
			<IconButton sx={{ padding: 0 }} onClick={() => setIsTestRun(false)}>
				<LastPageIcon />
			</IconButton>
		</Box>

		<ScriptSection isCodeEditorOpen isTestRun code={code} />

		<Box
			sx={{
				marginTop: '12px',
				paddingBottom: '10px',
				boxSizing: 'border-box',
				width: '100%',
				height: '254px',
				borderRadius: '10px',
				backgroundColor: '#212121',
			}}
		>
			<Box
				sx={{
					padding: '8px 16px',
					display: 'flex',
					alignItems: 'center',
					boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
					backgroundColor: '#272727',
					borderRadius: '10px',
				}}
			>
				<Typography
					sx={{
						fontFamily: 'Roboto',
						fontWeight: 500,
						fontSize: '12px',
						color: 'rgba(255, 255, 255, 0.6)',
					}}
				>
					Testing Result
				</Typography>
				<Typography
					sx={{
						marginLeft: '14px',
						padding: '0 6px',
						fontFamily: 'Roboto',
						fontWeight: 500,
						fontSize: '11px',
						lineHeight: '22px',
						letterSpacing: '0.03em',
						color: '#48C496',
						backgroundColor: 'rgba(72, 196, 150, 0.1)',
						border: '1px solid rgba(72, 196, 150, 0.35)',
						borderRadius: '8px',
					}}
				>
					SUCCESS
				</Typography>
			</Box>
			<CodeMirror className="TestingHeight" options={editorOptions} value="" />
		</Box>
	</>
);

export default Testing;
