import React, { useState } from 'react';
import Box from '@mui/material/Box';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Typography from '@mui/material/Typography';
import Templates from './components/Templates';
import OrganizationDialog from './components/OrganizationDialog';
import { useNavigate } from 'react-router';
import { CustomSwitch, Header, Knob, Title } from '../styles';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

function NewReportsView() {
	const [isOrganization, setIsOrganization] = useState(false);
	const navigate = useNavigate();

	return (
		<>
			<Header>
				<Title>
					<InsertChartOutlinedIcon sx={{ fontSize: 29, mr: '12px' }} />
					<span>Analytics</span>
				</Title>
				<CustomSwitch>
					<Knob data-testid="navigate-test" style={{ zIndex: 1 }} onClick={() => navigate('/')}>
						<HomeOutlinedIcon />
					</Knob>
					<Knob data-testid="navigate-all" style={{ marginLeft: '-16px' }} onClick={() => navigate('/stats')}>
						<span>Analytics</span>
					</Knob>
					<label>New Report</label>
				</CustomSwitch>
			</Header>

			<Box
				sx={{
					mx: '20px',
					height: 'calc(100vh - 124px)',
					color: 'rgba(255, 255, 255, 0.6)',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					gap: '40px',
				}}
			>
				<Box
					sx={{
						width: 470,
						height: 380,
						background: 'rgba(189, 211, 231, 0.17)',
						borderRadius: '15px',
						cursor: 'pointer',
						transition: 'all 0.2s',
						border: '1px solid transparent',
						'&:hover': {
							background: 'rgba(66, 165, 245, 0.05)',
							border: '1px dashed #42A5F5',
							color: '#FFFFFF',
						},
					}}
					onClick={() => setIsOrganization(true)}
				>
					{isOrganization ? (
						<OrganizationDialog setIsOrganization={setIsOrganization} />
					) : (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%',
							}}
						>
							<Box
								sx={{
									textAlign: 'center',
								}}
							>
								<AddCircleOutlineOutlinedIcon
									sx={{
										color: '#4D79EA',
										fontSize: 38,
									}}
								/>
								<Typography fontSize={20} marginTop={1} sx={{ color: '#4D79EA' }}>
									Blank Page
								</Typography>
							</Box>
						</Box>
					)}
				</Box>
				<Templates />
			</Box>
		</>
	);
}

export default NewReportsView;
