import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const MobileLabelText = ({ label, text, onClick }) => (
	<Box sx={{ marginBottom: '30px' }}>
		<Typography
			component="div"
			sx={{
				fontWeight: '400',
				fontSize: '14px',
				lineHeight: '16px',
				color: 'rgba(18, 18, 18, 0.6)',
				marginBottom: '7px',
			}}
		>
			{label}
		</Typography>

		<Typography
			component="div"
			sx={{
				fontWeight: '400',
				fontSize: '16px',
				lineHeight: '19px',
				color: 'rgba(0, 0, 0, 0.87)',
				marginBottom: '7px',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			}}
			onClick={onClick}
		>
			{text}
		</Typography>
	</Box>
);

export default MobileLabelText;
