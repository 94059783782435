import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { LinearProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FastForwardIcon from '@mui/icons-material/FastForward';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Typography from '@mui/material/Typography';
import Draggable from 'react-draggable';
import Slider from '@mui/material/Slider';
import { useImageViewerWheelContext } from '../../contexts/ImageViewerWheelContext';
import getDefaultRenderingEngine from '../../cornerstone/getDefaultRenderingEngine';

const ImageViewerCineControl = ({ cineItem, imageIds, viewportId, initialInstanceIndex, initialFPS }) => {
	const { playCine, stopCine } = useImageViewerWheelContext();

	const [activeImageIndex, setActiveImageIndex] = useState(initialInstanceIndex);
	const [FPS, setFPS] = useState(initialFPS || 3);
	const [value, setValue] = useState(initialFPS || 3);

	const element = document.getElementById(viewportId);

	const scrollToStart = () => {
		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) {
			return;
		}

		const viewport = renderingEngine.getViewport(viewportId);

		if (viewport) {
			viewport.setImageIdIndex(0);
			viewport.targetImageIdIndex = 0;
		}
	};

	const scrollToEnd = () => {
		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) {
			return;
		}

		const viewport = renderingEngine.getViewport(viewportId);

		if (viewport) {
			viewport.setImageIdIndex(imageIds.length - 1);
			viewport.targetImageIdIndex = imageIds.length - 1;
		}
	};

	const scrollNext = () => {
		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) {
			return;
		}

		const viewport = renderingEngine.getViewport(viewportId);

		if (viewport) {
			viewport.scroll(1);
		}
	};

	const scrollPrev = () => {
		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) {
			return;
		}

		const viewport = renderingEngine.getViewport(viewportId);

		if (viewport) {
			viewport.scroll(-1);
		}
	};

	const ON_CORNERSTONE_STACK_VIEWPORT_SCROLL = e => {
		setActiveImageIndex(e.detail.newImageIdIndex);
	};

	const ON_CORNERSTONE_STACK_NEW_IMAGE = e => {
		setActiveImageIndex(e.detail.imageIdIndex);
	};

	useEffect(() => {
		const viewportElement = document.getElementById(viewportId);

		viewportElement?.addEventListener('CORNERSTONE_STACK_VIEWPORT_SCROLL', ON_CORNERSTONE_STACK_VIEWPORT_SCROLL);

		viewportElement?.addEventListener('CORNERSTONE_STACK_NEW_IMAGE', ON_CORNERSTONE_STACK_NEW_IMAGE);

		return () => {
			viewportElement?.removeEventListener(
				'CORNERSTONE_STACK_VIEWPORT_SCROLL',
				ON_CORNERSTONE_STACK_VIEWPORT_SCROLL
			);

			viewportElement?.removeEventListener('CORNERSTONE_STACK_NEW_IMAGE', ON_CORNERSTONE_STACK_NEW_IMAGE);
		};
	}, []);

	useEffect(() => {
		const _element = document.getElementById(viewportId);

		if (!_element) {
			return;
		}

		playCine({ element: _element, options: { framesPerSecond: FPS } });
	}, [FPS]);

	return (
		<Draggable
			bounds="parent"
			handle=".cine"
			onDoubleClick={e => e.stopPropagation()}
			onMouseDown={e => e.stopPropagation()}
			onMouseUp={e => e.stopPropagation()}
		>
			<Box
				data-testid="ImageViewerCineControl"
				sx={{
					position: 'absolute',
					bottom: '20px',
					left: '20px',
					background: 'linear-gradient(96.4deg, #42A5F5 0%, rgba(66, 165, 245, 0) 55.04%)',
					padding: '1px',
					boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',
					borderRadius: '10px',
					filter: 'drop-shadow(0px 0px 20px rgba(66, 165, 245, 0.4))',
					width: '288px',
					boxSizing: 'border-box',
					zIndex: '10',
				}}
				onDoubleClick={e => e.stopPropagation()}
			>
				<Box
					sx={{
						background: '#0E0E0E',
						borderRadius: '10px',
						display: 'flex',
						flexDirection: 'column',
						padding: '7px 15px 11px 15px',
						boxSizing: 'border-box',
					}}
				>
					<LinearProgress
						sx={{
							backgroundColor: 'rgba(255, 255, 255, 0.31)',
							borderRadius: '20px',
							marginBottom: '11px',
							height: '2px',
							'& .MuiLinearProgress-bar': {
								backgroundColor: '#42A5F5',
								borderRadius: '20px',
								transition: 'unset',
							},
						}}
						value={((activeImageIndex + 1) / imageIds.length) * 100 || 0}
						variant="determinate"
					/>

					<Box
						className="cine"
						sx={{
							position: 'absolute',
							top: '0',
							left: '0',
							width: '100%',
							height: '100%',
							cursor: 'move',
						}}
					/>

					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							marginLeft: '9px',
							zIndex: '1',
							'& .MuiSvgIcon-root': {
								color: '#fff',
							},
						}}
						onClick={e => e.stopPropagation()}
					>
						<IconButton
							size="small"
							onClick={() => {
								stopCine({ element });
								scrollToStart();
							}}
						>
							<SkipPreviousIcon />
						</IconButton>

						<IconButton
							size="small"
							onClick={() => {
								stopCine({ element });
								scrollPrev();
							}}
						>
							<FastRewindIcon />
						</IconButton>

						<Box
							sx={{
								width: '40px',
								height: '40px',
								borderRadius: '50%',
								backgroundColor: '#42A5F5',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								margin: '0 8.75px',
							}}
						>
							<IconButton
								onClick={() => (cineItem?.isActive ? stopCine({ element }) : playCine({ element }))}
							>
								{cineItem?.isActive ? <StopIcon /> : <PlayArrowIcon />}
							</IconButton>
						</Box>

						<IconButton
							size="small"
							onClick={() => {
								stopCine({ element });
								scrollNext();
							}}
						>
							<FastForwardIcon />
						</IconButton>

						<IconButton
							size="small"
							sx={{
								marginRight: '13px',
							}}
							onClick={() => {
								stopCine({ element });
								scrollToEnd();
							}}
						>
							<SkipNextIcon />
						</IconButton>

						<Box
							sx={{
								width: '25px',
								height: '25px',
								borderRadius: '5px',
								backgroundColor: 'rgba(255, 255, 255, 0.15)',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								position: 'relative',
							}}
						>
							<Box
								sx={{
									position: 'absolute',
									width: '100%',
									height: '100%',
									overflow: 'hidden',
									zIndex: '1',
								}}
							>
								<Slider
									max={60}
									min={1}
									sx={{
										width: '200px',
										position: 'relative',
										top: '-3px',
										left: `${(30 - FPS) * 3.3333333 + -86}px`,
										cursor: 'ew-resize',
										color: 'transparent',
										'& .MuiSlider-thumbColorPrimary:before': {
											boxShadow: 'unset',
										},
									}}
									value={value}
									onChange={(event, newValue) => {
										setValue(newValue);
									}}
									onChangeCommitted={() => setFPS(value)}
								/>
							</Box>

							<Draggable
								axis="x"
								position={{
									x: (value - FPS) * 3.3333333,
									y: 0,
								}}
							>
								<Box
									sx={{
										position: 'absolute',
										width: '100%',
										height: '100%',
										cursor: 'ew-resize',
									}}
								/>
							</Draggable>

							<Typography
								sx={{
									fontWeight: '500',
									fontSize: '12px',
									lineHeight: '14px',
									letterSpacing: '0.25px',
									color: '#fff',
									userSelect: 'none',
								}}
							>
								{value}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</Draggable>
	);
};

export default ImageViewerCineControl;
