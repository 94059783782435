import React from 'react';

const TextButtonIcon = ({ fill, opacity }) => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_31825)" opacity={opacity || ''}>
			<path
				d="M5 17V19H19V17H5ZM9.5 12.8H14.5L15.4 15H17.5L12.75 4H11.25L6.5 15H8.6L9.5 12.8ZM12 5.98L13.87 11H10.13L12 5.98Z"
				fill={fill || 'white'}
			/>
		</g>
		<defs>
			<clipPath id="clip0_2852_31825">
				<rect fill={fill || 'white'} height="24" width="24" />
			</clipPath>
		</defs>
	</svg>
);

export default TextButtonIcon;
