import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import HelpIcon from '@mui/icons-material/Help';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import IconButton from '@mui/material/IconButton';
import _ from 'lodash';
import React, { useState } from 'react';
import { KnowledgeListItem, TabPanel } from '../../casePopOverStyles';

const Knowledge = () => {
	const [knowledges] = useState([
		{
			title: 'Frequently asked questions about billing',
			article:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Erat velit scelerisque in dictum non. Venenatis cras sed felis eget velit aliquet.',
			createdAt: '01/01/2022 11:30 AM',
		},
		{
			title: 'Frequently asked questions about billing',
			article:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Erat velit scelerisque in dictum non. Venenatis cras sed felis eget velit aliquet.',
			createdAt: '01/01/2022 11:30 AM',
		},
		{
			title: 'Frequently asked questions about billing',
			article:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Erat velit scelerisque in dictum non. Venenatis cras sed felis eget velit aliquet.',
			createdAt: '01/01/2022 11:30 AM',
		},
	]);

	return (
		<TabPanel>
			<div className="header">
				<div className="left">
					<div className="colored-icon teal-icon">
						<SchoolOutlinedIcon sx={{ fontSize: 20 }} />
					</div>
					<h2>Knowledge</h2>
				</div>
				<IconButton>
					<SearchOutlinedIcon sx={{ color: '#4D79EA' }} />
				</IconButton>
				<IconButton>
					<FilterListOutlinedIcon sx={{ color: '#4D79EA' }} />
				</IconButton>
			</div>
			<div className="body">
				{_.map(knowledges, (data, i) => (
					<KnowledgeListItem key={i}>
						<HelpIcon sx={{ color: '#C4C4C4', fontSize: '26px' }} />
						<div className="main">
							<div style={{ flex: 1, marginRight: '10px' }}>
								<h3>{data.title}</h3>
								<label>{data.article}</label>
							</div>
							<small>{data.createdAt}</small>
						</div>
					</KnowledgeListItem>
				))}
			</div>
		</TabPanel>
	);
};

export default Knowledge;
