import React from 'react';

import {
	BulletListIcon,
	CalloutIcon,
	ChartIcon,
	CheckListIcon,
	HeaderIcon,
	NumberListIcon,
	ParagraphIcon,
	PollIcon,
	QuoteIcon,
	TableIcon,
} from '../../assets/icons';

import styles from './BlockBar.module.scss';

const BlockBar = ({ theme, currentTextBlock, setCurrentTextBlock }) => {
	const onBlockClick = blockType => {
		if (currentTextBlock.type === blockType) {
			setCurrentTextBlock({});
		} else {
			setCurrentTextBlock({ type: blockType });
		}
	};

	const blockStyles = blockType => {
		let resultStyles = styles.block;

		if (theme === 'OAI') {
			if (currentTextBlock.type === blockType) {
				resultStyles = `${resultStyles} ${styles.blockOAI} ${styles.blockActive}`;
			} else {
				resultStyles = `${resultStyles} ${styles.blockOAI}`;
			}
		} else if (currentTextBlock.type === blockType) {
			resultStyles = `${resultStyles} ${styles.blockBlume} ${styles.blockActiveBlume}`;
		} else {
			resultStyles = `${resultStyles} ${styles.blockBlume}`;
		}

		return resultStyles;
	};

	return (
		<div
			className={`${styles.blockBar} ${theme === 'OAI' ? styles.blockBarOAI : styles.blockBarBlume}`}
			data-testid="blockBar"
		>
			<div className={styles.blockList}>
				<h3>Text Styling</h3>
				<div className={styles.blockListGrid}>
					<div className={blockStyles('Header')} onClick={() => onBlockClick('Header')}>
						<HeaderIcon fill={currentTextBlock.type === 'Header' ? '#fff' : '#4D79EA'} />
						<span>Header</span>
					</div>
					<div className={blockStyles('Paragraph')} onClick={() => onBlockClick('Paragraph')}>
						<ParagraphIcon fill={currentTextBlock.type === 'Paragraph' ? '#fff' : '#4D79EA'} />
						<span>Paragraph</span>
					</div>
					<div className={blockStyles('Quote')} onClick={() => onBlockClick('Quote')}>
						<QuoteIcon fill={currentTextBlock.type === 'Quote' ? '#fff' : '#4D79EA'} />
						<span>Quote</span>
					</div>
					<div className={blockStyles('Callout')} onClick={() => onBlockClick('Callout')}>
						<CalloutIcon fill={currentTextBlock.type === 'Callout' ? '#fff' : '#4D79EA'} />
						<span>Callout</span>
					</div>
				</div>
			</div>
			<div className={styles.blockList}>
				<h3>Lists</h3>
				<div className={styles.blockListGrid}>
					<div className={blockStyles('Bullet List')} onClick={() => onBlockClick('Bullet List')}>
						<BulletListIcon fill={currentTextBlock.type === 'Bullet List' ? '#fff' : '#4D79EA'} />
						<span>Bullet List</span>
					</div>
					<div className={blockStyles('Number List')} onClick={() => onBlockClick('Number List')}>
						<NumberListIcon fill={currentTextBlock.type === 'Number List' ? '#fff' : '#4D79EA'} />
						<span>Number List</span>
					</div>
					<div className={blockStyles('Check List')} onClick={() => onBlockClick('Check List')}>
						<CheckListIcon fill={currentTextBlock.type === 'Check List' ? '#fff' : '#4D79EA'} />
						<span>Check List</span>
					</div>
					<div className={blockStyles('Poll')} onClick={() => onBlockClick('Poll')}>
						<PollIcon fill={currentTextBlock.type === 'Poll' ? '#fff' : '#4D79EA'} />
						<span>Poll</span>
					</div>
				</div>
			</div>
			<div className={styles.blockList}>
				<h3>Tables</h3>
				<div className={styles.blockListGrid}>
					<div className={blockStyles('Table')} onClick={() => onBlockClick('Table')}>
						<TableIcon fill={currentTextBlock.type === 'Table' ? '#fff' : '#4D79EA'} />
						<span>Table</span>
					</div>
					<div className={blockStyles('Chart')} onClick={() => onBlockClick('Chart')}>
						<ChartIcon fill={currentTextBlock.type === 'Chart' ? '#fff' : '#4D79EA'} />
						<span>Chart</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlockBar;
