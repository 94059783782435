import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useConfig } from '@worklist-2/core/src';
import { useCrmContext } from '../../../../../context/CRMContext';
import AddStation from './AddStation';
import InboundMapping from './InboundMapping';

export const MESSAGE_TYPES = [
	'ADT_A08',
	'ADT_A23',
	'ADT_A40',
	'BAR_P01',
	'BAR_P06',
	'MFN_M02',
	'ORM_O01',
	'ORU_R01',
	'SIU_S12',
	'SIU_S15',
];

const getTypes = collection => collection.map(item => `${item.MessageType}_${item.MessageEvent}`);

const AddStationStep = ({ accountData, setSidebarStep, deviceID, setDeviceID, setOrganizationData }) => {
	const [step, setStep] = useState('station');
	const [state, setState] = useState({
		InboundMappings: [],
		OutboundMappings: [],
	});
	const [isActive, setIsActive] = useState(true);
	const [stationType, setStationType] = useState(null);
	const [messageType, setMessageType] = useState(null);
	const [IP, setIP] = useState('');
	const [inboundPort, setInboundPort] = useState('');
	const [outboundPort, setOutboundPort] = useState('');
	const [selectHL7MessageType, setSelectHL7MessageType] = useState('');

	const __config = useConfig();
	const { toggleDetails } = useCrmContext();

	useEffect(() => {
		getHL7().catch(console.error);
	}, []);

	const getHL7 = async () => {
		const { data } = await axios.get(
			`${__config.data_sources.breeze}/DeviceOmegaAI/${accountData?.AccountId}/${deviceID}/hl7mapping`
		);
		if (data) {
			const rawData = JSON.parse(data);
			setState({
				InboundMappings: rawData?.Hl7Server?.InboundMappings || [],
				OutboundMappings: rawData?.Hl7Server?.OutboundMappings || [],
			});
			setIP(rawData?.Hl7Server?.OutboundServerHostName || '');
			setInboundPort(rawData?.Hl7Server?.InboundServerPort || '');
			setOutboundPort(rawData?.Hl7Server?.OutboundServerPort || '');
		}
	};

	const sendHL7 = async () => {
		const payload = {
			Hl7Server: {
				...state,
				OutboundServerHostName: IP,
				InboundServerPort: inboundPort,
				OutboundServerPort: outboundPort,
			},
		};
		await axios
			.put(
				`${__config.data_sources.breeze}/DeviceOmegaAI/${accountData?.AccountId}/${deviceID}/hl7mapping`,
				payload
			)
			.catch(console.error);
	};

	const updateData = (newValue, included) => {
		const newState = { ...state };
		const type = messageType === 'Inbound' ? 'InboundMappings' : 'OutboundMappings';

		if (included) {
			newState[type] = newState[type].map(item =>
				item.MessageType === newValue.MessageType && item.MessageEvent === newValue.MessageEvent
					? newValue
					: item
			);
		} else {
			newState[type].push(newValue);
		}

		setState(newState);
	};

	const handleStepChange = newStep => {
		setStep(newStep);
	};

	const onClose = () => {
		setSidebarStep(null);
		setOrganizationData(null);
		toggleDetails();
	};

	const changePrevStep = () => {
		setDeviceID(null);
		setSidebarStep('device');
	};

	const handleNextButtonClick = async () => {
		await sendHL7();
	};

	const currentMappingData = state[messageType === 'Inbound' ? 'InboundMappings' : 'OutboundMappings'];

	return (
		<>
			{step === 'station' && (
				<AddStation
					IP={IP}
					changeNextStep={() => handleStepChange('mapping')}
					changePrevStep={changePrevStep}
					handleNextButtonClick={handleNextButtonClick}
					inboundPort={inboundPort}
					isActive={isActive}
					isEmpty={state.InboundMappings.length === 0 && state.OutboundMappings.length === 0}
					mappedTypes={currentMappingData ? getTypes(currentMappingData) : []}
					messageType={messageType}
					outboundPort={outboundPort}
					setIP={setIP}
					setInboundPort={setInboundPort}
					setIsActive={setIsActive}
					setMessageType={setMessageType}
					setOutboundPort={setOutboundPort}
					setSelectHL7MessageType={setSelectHL7MessageType}
					setStationType={setStationType}
					stationType={stationType}
					onClose={onClose}
				/>
			)}
			{step === 'mapping' && (
				<InboundMapping
					accountData={accountData}
					changePrevStep={() => handleStepChange('station')}
					deviceID={deviceID}
					initData={currentMappingData.find(
						item => `${item.MessageType}_${item.MessageEvent}` === selectHL7MessageType
					)}
					messageType={messageType}
					selectHL7MessageType={selectHL7MessageType}
					setSelectHL7MessageType={setSelectHL7MessageType}
					updateData={updateData}
					onClose={onClose}
				/>
			)}
		</>
	);
};

export default AddStationStep;
