import { useConfig } from '@rs-core/context/ConfigContext';
import {
	useStringFlagValue as useOpenStringValue,
	useBooleanFlagValue as useOpenBooleanFlagValue,
	useNumberFlagValue as useOpenNumberValue,
	useJsonFlagValue as useOpenJsonValue,
} from '@openfeature/react-sdk';

export const useBooleanFlagValue = (flagName, defaultValue) => {
	const config = useConfig();

	return useOpenBooleanFlagValue(flagName?.toLowerCase(), defaultValue ?? false, {
		updateOnConfigurationChanged: false,
	});
};

export const useStringFlagValue = (flagName, defaultValue) => {
	const config = useConfig();

	return useOpenStringValue(flagName?.toLowerCase(), defaultValue ?? '', {
		updateOnConfigurationChanged: false,
	});
};

export const useNumberFlagValue = (flagName, defaultValue) => {
	const config = useConfig();

	return useOpenNumberValue(flagName?.toLowerCase(), defaultValue ?? -1, {
		updateOnConfigurationChanged: false,
	});
};

export const useJsonFlagValue = (flagName, defaultValue) => {
	const config = useConfig();

	return useOpenJsonValue(flagName?.toLowerCase(), defaultValue ?? {}, {
		updateOnConfigurationChanged: false,
	});
};
