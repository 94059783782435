import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import BlumeLogo from '@worklist-2/ui/src/assets/icons/blume_logo.svg';
import OmegaLogo from '@worklist-2/ui/src/assets/icons/Chat/omega-ai.svg';

const NewArticle = ({ icon, title, withSwitch }) => {
	const [isHover, setIsHover] = useState(false);
	const [isButtonHover, setIsButtonHover] = useState(false);

	const handleMouseOver = () => {
		if (withSwitch) {
			setIsHover(true);
		}
	};

	return (
		<Box
			sx={{
				boxSizing: 'border-box',
				width: '262px',
				height: '114px',
				padding: isHover ? '10px' : '16px',
				background: 'rgba(189, 211, 231, 0.17)',
				borderRadius: '10px',
			}}
			onMouseLeave={() => setIsHover(false)}
			onMouseOver={handleMouseOver}
		>
			<Box
				sx={{
					marginBottom: isHover ? '18px' : '6px',
					display: 'flex',
					flexDirection: isHover ? 'row' : 'column',
					alignItems: isHover ? 'center' : 'flex-start',
				}}
			>
				<Box
					sx={{
						height: isHover ? '15px' : '20px',
						'*': {
							fontSize: isHover ? '15px' : '20px',
							color: '#4D79EA',
						},
					}}
				>
					{icon}
				</Box>
				<Typography
					sx={{
						marginTop: isHover ? '0' : '10px',
						marginLeft: isHover ? '6px' : '0',
						fontSize: isHover ? '12px' : '16px',
						fontWeight: 500,
						lineHeight: '12px',
						color: 'rgba(0, 0, 0, 0.87)',
					}}
				>
					{title}
				</Typography>
			</Box>
			{isHover ? (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						gap: isButtonHover ? '5px' : '40px',
						transition: 'all 0.3s ease',
					}}
				>
					<CircleButton isOmega setIsButtonHover={setIsButtonHover} title={title} />
					<CircleButton setIsButtonHover={setIsButtonHover} title={title} />
				</Box>
			) : (
				<Typography
					sx={{
						fontSize: '12px',
						fontStyle: 'italic',
						fontWeight: 400,
						lineHeight: '12px',
						color: 'rgba(0, 0, 0, 0.60)',
					}}
				>
					{withSwitch ? 'Write on OmegaAI or Blume' : 'Write on Breeze'}
				</Typography>
			)}
		</Box>
	);
};

const CircleButton = ({ title, isOmega, setIsButtonHover }) => {
	const [isHover, setIsHover] = useState(false);

	const navigate = useNavigate();

	const handleButtonHover = value => {
		setIsHover(value);
		setIsButtonHover(value);
	};

	const onClick = () => {
		isOmega
			? navigate('/documentation/help-center/omegaai', {
					state: {
						Type: title,
					},
			  })
			: navigate('/documentation/help-center/blume', {
					state: {
						Type: title,
					},
			  });
	};

	return (
		<Box
			data-testid="circleButton"
			sx={{
				position: 'relative',

				width: isHover ? '136px' : '52px',
				height: '52px',
				display: 'flex',
				cursor: 'pointer',
				transition: 'all 0.3s ease',
			}}
			onClick={onClick}
			onMouseLeave={() => handleButtonHover(false)}
			onMouseOver={() => handleButtonHover(true)}
		>
			<Box
				sx={{
					boxSizing: 'border-box',
					position: 'absolute',
					top: 0,
					left: 0,
					width: '52px',
					height: '52px',
					padding: '10px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: isOmega ? '#005675' : '#F7F9FF',
					border: '1px solid rgba(196, 196, 196, 0.40)',
					borderRadius: '50%',
				}}
			>
				{isOmega ? <OmegaLogo style={{ scale: '1.5' }} /> : <BlumeLogo />}
			</Box>
			<Box
				sx={{
					boxSizing: 'border-box',
					width: '100%',
					height: '100%',
					paddingLeft: '52px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: '#FFF',
					borderRadius: '30px',
				}}
			>
				{isHover && (
					<Typography
						sx={{
							fontFamily: 'Roboto',
							fontSize: '14px',
							fontWeight: 400,
							color: 'rgba(0, 0, 0, 0.87)',
							whiteSpace: 'nowrap',
						}}
					>
						{isOmega ? 'Omega AI' : 'Blume'}
					</Typography>
				)}
			</Box>
		</Box>
	);
};

export default NewArticle;
