import moment from 'moment';
import { useTranslation } from 'react-i18next';

const datetimeOffset = (datetime, unitOfwork = 'seconds') => {
	const dateTimeNow = moment();
	const dateTime = moment(datetime);
	const offset = dateTimeNow.diff(dateTime, unitOfwork, false);
	return offset;
};

export const FormatChatDateTime = datetime => {
	const { t } = useTranslation('chat');
	var offset = datetimeOffset(datetime, 'hours');
	let formattedOffset = '';
	if (offset < 1) formattedOffset = moment(datetime).format('hh:mm a');
	else if (offset >= 1 && offset < 24)
		formattedOffset = `${datetimeOffset(datetime, 'hours')} ${t('chatDateTime.hoursAgo')}`;
	else if (offset >= 24 && offset < 48) formattedOffset = t('chatDateTime.yesterday');
	else if (offset >= 48) formattedOffset = `${datetimeOffset(datetime, 'days')} ${t('chatDateTime.daysAgo')}`;
	else if (offset >= 24 * 30 && offset < 24 * 365)
		formattedOffset = `${datetimeOffset(datetime, 'months')} ${t('chatDateTime.monthsAgo')}`;
	else if (offset >= 365 * 24) formattedOffset = `${datetimeOffset(datetime, 'years')} ${t('chatDateTime.yearsAgo')}`;

	return formattedOffset;
};
