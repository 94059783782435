import React, { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import { CardOutline, SubTitle, VerticalDivider } from '../../../views/CrmView/styles';
import { StyledTableCell, StyledTableRow } from '../Accounts/AccountsTable';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useAuth } from '@worklist-2/core/src';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const PermissionListItem = ({ permissions, category, togglePermission }) => {
	const disabled = useMemo(() => {
		if (permissions.length === 0 || !permissions.find(p => p.Category == category)) return false;
		const { Add, Edit, Delete, Read } = permissions.find(p => p.Category == category);
		return !Add && !Edit && !Delete && !Read;
	}, [permissions]);

	return (
		<CardOutline disabled style={{ width: '89%', height: 'inherit', margin: '0px 19px 20px 17px', opacity: 1 }}>
			<Table stickyHeader aria-label="customized table">
				<TableHead sx={{ borderBottom: '1px solid #333' }}>
					<TableRow>
						<StyledTableCell align="left" sx={{ p: 0, pl: '5px', width: '120px' }}>
							{category}
						</StyledTableCell>
						<StyledTableCell align="center" sx={{ p: 0 }}>
							Read
						</StyledTableCell>
						<StyledTableCell align="center" sx={{ p: 0 }}>
							Add
						</StyledTableCell>
						<StyledTableCell align="center" sx={{ p: 0 }}>
							Edit
						</StyledTableCell>
						<StyledTableCell align="center" sx={{ p: 0 }}>
							Delete
						</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody style={{ flex: '1' }}>
					{_.map(permissions, (permission, i) => (
						<ListItem
							key={i}
							category={category}
							disabled={disabled}
							permission={permission}
							togglePermission={togglePermission}
						/>
					))}
				</TableBody>
			</Table>
		</CardOutline>
	);
};

const ListItem = ({ permission, togglePermission, category, disabled }) => {
	const { Privilege, Add, Edit, Delete, Read } = permission;
	const { loggedInUser } = useAuth();

	const toggleAll = async () => {
		Object.keys(permission).forEach(async key => {
			if (typeof permission[key] === 'boolean') {
				await new Promise(resolve => {
					setTimeout(resolve, 500);
				});
				if (disabled) {
					togglePermission({ category, data: { Add: false, Edit: false, Delete: false, Read: true } });
				} else {
					togglePermission({ category, data: { Add: false, Edit: false, Delete: false, Read: false } });
				}
			}
		});
	};

	return (
		<StyledTableRow className="transparent">
			<StyledTableCell
				align="left"
				sx={{
					display: 'flex',
					flex: 1,
					maxWidth: 120,
					flexDirection: 'row',
					alignItems: 'center',
					padding: '10px 0px !important',
				}}
			>
				<IconButton
					data-testid="toggle-button"
					disabled={!loggedInUser?.permission?.Role?.Edit}
					sx={{ m: 0 }}
					onClick={toggleAll}
				>
					{disabled ? (
						<VisibilityOffIcon sx={{ color: '#C4C4C4', width: '18px', height: '18px' }} />
					) : (
						<VisibilityIcon sx={{ color: '#4D79EA', width: '18px', height: '18px' }} />
					)}
				</IconButton>
				<SubTitle
					style={{
						fontSize: '16px',
						color: disabled ? '#C4C4C4' : 'rgba(0, 0, 0, 0.87)',
						marginLeft: '15px',
					}}
				>
					{Privilege}
				</SubTitle>
				<VerticalDivider style={{ marginLeft: 'auto' }} />
			</StyledTableCell>
			<StyledTableCell align="center" sx={{ padding: '0px !important' }}>
				<IconButton
					data-testid="Read-toggle-button"
					disabled={!loggedInUser?.permission?.Role?.Edit}
					sx={{ m: 0 }}
					onClick={() => togglePermission({ category, data: { Read: !Read } })}
				>
					<LibraryBooksOutlinedIcon
						sx={{ width: '18px', height: '18px', color: disabled || !Read ? '#C4C4C4' : '#4D79EA' }}
					/>
				</IconButton>
			</StyledTableCell>
			<StyledTableCell align="center" sx={{ padding: '0px !important' }}>
				<IconButton
					data-testid="Add-toggle-button"
					disabled={!loggedInUser?.permission?.Role?.Edit}
					sx={{ m: 0 }}
					onClick={() => togglePermission({ category, data: { Add: !Add } })}
				>
					<PlaylistAddIcon
						sx={{ width: '18px', height: '18px', color: disabled || !Add ? '#C4C4C4' : '#4D79EA' }}
					/>
				</IconButton>
			</StyledTableCell>
			<StyledTableCell align="center" sx={{ padding: '0px !important' }}>
				<IconButton
					data-testid="Edit-toggle-button"
					disabled={!loggedInUser?.permission?.Role?.Edit}
					sx={{ m: 0 }}
					onClick={() => togglePermission({ category, data: { Edit: !Edit } })}
				>
					<ModeEditOutlinedIcon
						sx={{ width: '18px', height: '18px', color: disabled || !Edit ? '#C4C4C4' : '#4D79EA' }}
					/>
				</IconButton>
			</StyledTableCell>
			<StyledTableCell align="center" sx={{ padding: '0px !important' }}>
				<IconButton
					data-testid="Delete-toggle-button"
					disabled={!loggedInUser?.permission?.Role?.Edit}
					sx={{ m: 0 }}
					onClick={() => togglePermission({ category, data: { Delete: !Delete } })}
				>
					<DeleteOutlineOutlinedIcon
						sx={{ width: '18px', height: '18px', color: disabled || !Delete ? '#C4C4C4' : '#4D79EA' }}
					/>
				</IconButton>
			</StyledTableCell>
		</StyledTableRow>
	);
};

PermissionListItem.propTypes = {
	permissions: PropTypes.array.isRequired,
};

export default PermissionListItem;
