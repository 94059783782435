import React, { useMemo } from 'react';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import VisualCards from '../VisualCards';
import ReportDataGrid from '../../../BreezeNewReportsView/components/ReportDataGrid';
import convertStringToLocalDatetime from '@worklist-2/ui/src/views/utils/convertStringToLocalDatetime';
import _ from 'lodash';

const COLORS_SERIES = [
	'#9188FF',
	'#FF87D7',
	'#FFC045',
	'#43BDF0',
	'#FF94C7',
	'#49E2AD',
	'#4DAFFF',
	'#FFA9EC',
	'#41C2DE',
	'#FCF173',
	'#D3F5CF',
];

const SECONDARY_COLORS_SERIES = [
	'#B9A9FF',
	'#FFAAE2',
	'#FFDA91',
	'#8CDCFE',
	'#FCAEC1',
	'#98F4D4',
	'#A7D7FF',
	'#FFE8FE',
	'#64F3FC',
	'#FFF9B4',
	'#C8F8FF',
];

const GRADIENT_COLORS_SERIES = ['#9188FF', '#FF87D7', '#8C8C8C', '#8CDCFE'];

const GRADIENT_COLORS_RGB_SERIES = [
	'rgba(145,136,255,0.90)',
	'rgba(255,135,215,0.90)',
	'rgba(140,140,140,0.90)',
	'rgba(140,220,254,0.90)',
];

const commonOptions = {
	maintainAspectRatio: false,
	interaction: {
		intersect: false,
	},
	plugins: {
		legend: {
			position: 'bottom',
		},
	},
	// scales: {
	//   x: {
	//     ticks: {
	//       autoSkip: true,
	//       maxRotation: 0,
	//       padding: 12,
	//       minRotation: 0,
	//     },
	//   },
	// },
};

// const useDrilldownCallback = ({
//   datasets,
//   labels,
//   onDrilldownRequested,
//   pivotConfig,
// }) => {
//   return React.useCallback(
//     (elements) => {
//       if (elements.length <= 0) return;
//       const { datasetIndex, index } = elements[0];
//       const { yValues } = datasets[datasetIndex];
//       const xValues = [labels[index]];

//       if (typeof onDrilldownRequested === "function") {
//         onDrilldownRequested(
//           {
//             xValues,
//             yValues,
//           },
//           pivotConfig
//         );
//       }
//     },
//     [datasets, labels, onDrilldownRequested]
//   );
// };

const BarChartRenderer = ({ resultSet, pivotConfig }) => {
	const datasets = resultSet.series().map((s, index) => ({
		label: convertStringToLocalDatetime(s.title),
		data: s.series.map(r => r.value),
		yValues: [s.key],
		backgroundColor: COLORS_SERIES[index],
		fill: false,
	}));

	const data = {
		labels: checkToConvertDateTimeValue(resultSet.categories().map(c => c.x)),
		datasets,
	};

	const options = {
		...commonOptions,
		// scales: {
		//   x: { ...commonOptions.scales.x, stacked },
		//   y: { ...commonOptions.scales.y, stacked },
		// },
	};

	return <Bar data={data} options={options} type="bar" />;
};

function getGradientArray(ctx, chartArea) {
	return COLORS_SERIES.map((color, index) => {
		const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
		gradient.addColorStop(0, SECONDARY_COLORS_SERIES[index]);
		gradient.addColorStop(1, color);
		return gradient;
	});
}

const PieChartRenderer = ({ resultSet }) => {
	const data = {
		labels: checkToConvertDateTimeValue(resultSet.categories().map(c => c.x)),
		datasets: resultSet.series().map((s, index) => ({
			label: s.title,
			data: s.series.map(r => r.value),
			yValues: [s.key],
			backgroundColor: context => {
				const { chart } = context;
				const { ctx, chartArea } = chart;
				if (!chartArea) {
					return null;
				}
				return getGradientArray(ctx, chartArea);
			},
		})),
	};

	return <Doughnut data={data} data-testid="chart-doughnut" options={commonOptions} type="doughnut" />;
};

const LineChartRenderer = ({ resultSet }) => {
	const datasets = resultSet.series().map((s, index) => ({
		label: s.title,
		data: s.series.map(r => r.value),
		yValues: [s.key],
		borderColor: GRADIENT_COLORS_SERIES[index],
		backgroundColor: context => {
			const { ctx } = context.chart;
			const gradient = ctx.createLinearGradient(0, 0, 0, 300);
			gradient.addColorStop(0, GRADIENT_COLORS_RGB_SERIES[index]);
			gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');
			return gradient;
		},
		pointRadius: 1,
		tension: 0.1,
		pointHoverRadius: 1,
		borderWidth: 2,
		tickWidth: 1,
		fill: true,
	}));

	const data = {
		labels: checkToConvertDateTimeValue(resultSet.categories().map(c => c.x)),
		datasets,
	};

	return <Line data={data} options={commonOptions} type="line" />;
};

const checkToConvertDateTimeValue = value => {
	if (value != null) {
		return value.map(el =>
			// convertStringToLocalDatetime method will check and convert if the value is Date String
			convertStringToLocalDatetime(el)
		);
	}
};

const CropChartRenderer = ({ resultSet }) => {
	const CARDS = new Array();
	const tempArray = new Array();

	resultSet.seriesNames().map(s => {
		const nameArray = s.key.replace('_', ' ').substring(0, s.key.indexOf('.')).split(' ');

		nameArray.map(item => {
			tempArray.push(_.capitalize(item));
		});

		const name = tempArray.join(' ');
		const title = s.title.replace(name, '');

		CARDS.push({ title, number: resultSet.totalRow()[s.key] });
	});

	return <VisualCards cards={CARDS} />;
};

const TableRenderer = ({ resultSet, pivotConfig, columnOrder }) => {
	const [tableColumns, dataSource] = useMemo(() => {
		const columns = resultSet.tableColumns(pivotConfig);
		return [columns, formatTableData(columns, resultSet.tablePivot(pivotConfig))];
	}, [resultSet, pivotConfig]);

	return <ReportDataGrid columnOrder={columnOrder} dataSource={dataSource} tableColumns={tableColumns} />;
};

const formatTableData = (tableColumns, data) => {
	function flatten(columns = []) {
		return columns.reduce((memo, column) => {
			if (column.children) {
				return [...memo, ...flatten(column.children)];
			}

			return [...memo, column];
		}, []);
	}

	const typeByIndex = flatten(tableColumns).reduce((memo, column) => ({ ...memo, [column.dataIndex]: column }), {});

	function formatValue(value, { type, format } = {}) {
		if (value == undefined) {
			return value;
		}

		if (type === 'boolean') {
			if (typeof value === 'boolean') {
				return value.toString();
			}
			if (typeof value === 'number') {
				return Boolean(value).toString();
			}

			return value;
		}

		if (type === 'number' && format === 'percent') {
			return [parseFloat(value).toFixed(2), '%'].join('');
		}

		return value.toString();
	}

	function formatFn(row) {
		return Object.fromEntries(
			Object.entries(row).map(([dataIndex, value]) => [dataIndex, formatValue(value, typeByIndex[dataIndex])])
		);
	}

	return data.map(formatFn);
};

const Charts = ({ resultSet, pivotConfig, chartType, columnOrder }) => (
	<>
		{chartType === 'bar' && <BarChartRenderer pivotConfig={pivotConfig} resultSet={resultSet} />}
		{chartType === 'pie' && <PieChartRenderer resultSet={resultSet} />}
		{chartType === 'line' && <LineChartRenderer resultSet={resultSet} />}
		{chartType === 'number' && <CropChartRenderer resultSet={resultSet} />}
		{chartType === 'table' && (
			<TableRenderer columnOrder={columnOrder} pivotConfig={pivotConfig} resultSet={resultSet} />
		)}
	</>
);

export default Charts;
