import { Enums, LengthTool, utilities, annotation, cursors } from '@cornerstonejs/tools';
import { getEnabledElement, triggerEvent, eventTarget } from '@cornerstonejs/core';

const { Events } = Enums;
const { triggerAnnotationRenderForViewportIds } = utilities;
const { removeAnnotation } = annotation.state;
const { resetElementCursor } = cursors.elementCursor;

class LengthToolUpdated extends LengthTool {
	_endCallback = (evt) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const { annotation, viewportIdsToRender, newAnnotation, hasMoved } = this.editData;
		const { data } = annotation;

		if (newAnnotation && !hasMoved) {
			// when user starts the drawing by click, and moving the mouse, instead
			// of click and drag
			return;
		}

		data.handles.activeHandleIndex = null;

		this._deactivateModify(element);
		this._deactivateDraw(element);
		resetElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		if (this.isHandleOutsideImage && this.configuration.preventHandleOutsideImage) {
			removeAnnotation(annotation.annotationUID);
		}

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		const eventType = Events.ANNOTATION_COMPLETED;

		triggerEvent(eventTarget, eventType, { annotation });

		this.editData = null;
		this.isDrawing = false;
	};
}

export default LengthToolUpdated;
