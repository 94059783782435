import React from 'react';

const AIIcon = () => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<rect fill="#F2F4F7" height="24" rx="12" width="24" />
		<path
			d="M9.94036 10.3191L10.209 11.0455L10.3981 11.5548L10.9074 11.7439L11.6338 12.0125L10.9074 12.2812L10.3981 12.4703L10.209 12.9796L9.94036 13.706L9.67173 12.9796L9.48262 12.4703L8.9733 12.2812L8.24692 12.0125L8.9733 11.7439L9.48262 11.5548L9.67173 11.0455L9.94036 10.3191ZM8.56283 10.6361L5.05561 11.9341C4.98146 11.961 4.98146 12.0663 5.05561 12.0931L8.56283 13.3911L9.86085 16.8984C9.88771 16.9725 9.99302 16.9725 10.0199 16.8984L11.3179 13.3911L14.8251 12.0931C14.8993 12.0663 14.8993 11.961 14.8251 11.9341L11.3179 10.6361L10.0199 7.12885C9.99302 7.05471 9.88771 7.05471 9.86085 7.12885L8.56283 10.6361Z"
			fill="url(#paint0_linear_1246_56844)"
		/>
		<path
			d="M15.9061 5.01451L16.5906 6.86375L18.4398 7.54822C18.4592 7.55574 18.4592 7.5826 18.4398 7.58905L16.5906 8.27352L15.9061 10.1228C15.8986 10.1421 15.8717 10.1421 15.8653 10.1228L15.1808 8.27352L13.3316 7.58905C13.3122 7.58153 13.3122 7.55467 13.3316 7.54822L15.1808 6.86375L15.8653 5.01451C15.8728 4.99516 15.8997 4.99516 15.9061 5.01451Z"
			fill="url(#paint1_linear_1246_56844)"
		/>
		<path
			d="M15.924 13.8299L16.6085 15.6792L18.4577 16.3636C18.4771 16.3712 18.4771 16.398 18.4577 16.4045L16.6085 17.0889L15.924 18.9382C15.9165 18.9575 15.8896 18.9575 15.8832 18.9382L15.1987 17.0889L13.3495 16.4045C13.3301 16.397 13.3301 16.3701 13.3495 16.3636L15.1987 15.6792L15.8832 13.8299C15.8907 13.8106 15.9176 13.8106 15.924 13.8299Z"
			fill="url(#paint2_linear_1246_56844)"
		/>
		<defs>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint0_linear_1246_56844"
				x1="3.56095"
				x2="15.6815"
				y1="12.0125"
				y2="12.0125"
			>
				<stop stopColor="#B768FF" />
				<stop offset="0.35" stopColor="#8D74FF" />
				<stop offset="1" stopColor="#67D7FF" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint1_linear_1246_56844"
				x1="5.35866"
				x2="18.9019"
				y1="7.56863"
				y2="7.56863"
			>
				<stop stopColor="#B768FF" />
				<stop offset="0.39" stopColor="#8D74FF" />
				<stop offset="1" stopColor="#67D7FF" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint2_linear_1246_56844"
				x1="5.21429"
				x2="17.1876"
				y1="16.3851"
				y2="16.3851"
			>
				<stop stopColor="#B768FF" />
				<stop offset="0.39" stopColor="#8D74FF" />
				<stop offset="1" stopColor="#67D7FF" />
			</linearGradient>
		</defs>
	</svg>
);

export default AIIcon;
