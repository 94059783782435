// React Libraries
import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

// Material UI Libraries
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import List from '@mui/material/List';
import { createTheme } from '@mui/material/styles';

// Custom Component Libraries
import DropListItem from './DropListItem';

function FilterList({ filterItems, updateFilter, removeFilter }) {
	const [editing, setEditing] = useState(true);

	const theme = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1200,
				xl: 1536,
			},
		},
	});

	return (
		<Box
			data-testid="filter_drop"
			sx={{
				width: editing && filterItems.length > 0 ? '92%' : 295,
				minHeight: 285,
				background: '#F9FAFF',
				boxShadow: '4px 4px 1px rgba(77, 121, 234, 0.14)',
				borderRadius: '15px',
				marginLeft: 'auto',
				padding: '26px 15px',
				marginTop: '-18px',
				zIndex: 10,
				[theme.breakpoints.between('lg', 'xl')]: {
					marginTop: '-38px',
					transform: 'scale(0.9)',
				},
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<Typography fontSize={16} sx={{ fontWeight: 500, marginLeft: '32px', color: '#000' }}>
					FILTER
				</Typography>
				{filterItems.length > 0 && editing && (
					<CloseFullscreenIcon
						fontSize="small"
						sx={{
							color: 'rgba(255, 255, 255, 0.6)',
							cursor: 'pointer',
						}}
						onClick={() => setEditing(!editing)}
					/>
				)}
			</Box>

			<Droppable droppableId="filter_drop">
				{(provided, snapshot) => (
					<div
						{...provided.droppableProps}
						ref={provided.innerRef}
						style={{
							height: 250,
							marginTop: '16px',
							borderRadius: '5px',
							overflowY: 'auto',
							background: '#F9FAFF',
						}}
					>
						{filterItems.length > 0 ? (
							<List>
								{filterItems.map((value, index) => (
									<DropListItem
										key={index}
										isFilterList
										edit={editing}
										item={value}
										removeFilter={removeFilter}
										setEdit={setEditing}
										updateFilters={updateFilter}
									/>
								))}
							</List>
						) : (
							<Box
								sx={{
									color: '#4D79EA',
									border: '1px dashed #4D79EA',
									borderRadius: '5px',
									height: '220px',
									marginTop: '20px',
									display: 'flex',
									background: '#F9FAFF',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Typography
									fontSize={14}
									sx={{
										textTransform: 'uppercase',
										letterSpacing: '1.5px',
									}}
								>
									Drag and drop
								</Typography>
							</Box>
						)}
					</div>
				)}
			</Droppable>
		</Box>
	);
}

FilterList.propTypes = {
	/**
	 * filterItems is array that holds all the filtered fields
	 */
	filterItems: PropTypes.array.isRequired,
	/**
	 * removeFilterItem is a func to remove array item
	 */
	removeFilterItem: PropTypes.func,
};

export default FilterList;
