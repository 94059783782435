import React from 'react';
import { Row, Col, FilesWrapper } from '../../../../casePopOverStyles';
import _ from 'lodash';
import Icon from '@mui/material/Icon';
import DocumentIcon from '@worklist-2/ui/src/assets/icons/breeze/DocumentIcon.svg';
import ImgIcon from '@worklist-2/ui/src/assets/icons/breeze/ImgIcon.svg';
import VideoIcon from '@worklist-2/ui/src/assets/icons/breeze/VideoIcon.svg';
import GifIcon from '@worklist-2/ui/src/assets/icons/breeze/GifIcon.svg';
import AiIcon from '@worklist-2/ui/src/assets/icons/breeze/AiIcon.svg';
import PsdIcon from '@worklist-2/ui/src/assets/icons/breeze/PsdIcon.svg';
import PdfIcon from '@worklist-2/ui/src/assets/icons/breeze/PdfIcon.svg';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Tooltip } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import axios from 'axios';
import { useConfig } from '@worklist-2/core/src';

const FILE_ICON = {
	// doc
	'application/msword': DocumentIcon,
	// docx
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': DocumentIcon,
	// pdf
	'application/pdf': PdfIcon,
	// json
	'application/json': DocumentIcon,
	// text
	'text/plain': DocumentIcon,
	'text/csv': DocumentIcon,
	// excel
	'application/vnd.ms-excel': DocumentIcon,
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': DocumentIcon,
	// xml
	'text/xml': DocumentIcon,
	// images (jpg, png, webp, bmp)
	'image/jpeg': ImgIcon,
	'image/png': ImgIcon,
	'image/webp': ImgIcon,
	'image/bmp': ImgIcon,
	'image/gif': GifIcon,
	// video
	'video/mp4': VideoIcon,
	'video/mpeg': VideoIcon,
	// zip
	'application/zip': DocumentIcon,
	'application/x-zip-compressed': DocumentIcon,
	// others
	'application/octet-stream': PsdIcon,
	'application/postscript': AiIcon,
	//dicom
	'application/dicom': DocumentIcon,
};

const useStyles = makeStyles()(() => ({
	tooltip: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#4D79EA',
		borderRadius: '5px',
	},

	arrow: {
		color: '#4D79EA',
	},
}));

const CommentFiles = ({
	filesInput,
	onRemoveAttachment,
	readOnly = true,
	isFileView = false,
	setEditComment,
	toggleFiles,
	progress = 0,
	sx,
}) => {
	const { classes } = useStyles();
	const __config = useConfig();

	const download = async attachment => {
		axios
			.get(
				`${__config.data_sources.breeze}/comment/${attachment.CommentId}/attachment/${attachment.AttachmentId}/content`,
				{
					headers: {
						Accept: '*/*',
					},
					responseType: 'blob',
				}
			)
			.catch(error => {
				console.error('error downloading file.', error);
			})
			.then(resp => {
				if (resp?.status === 200) {
					const link = document.createElement('a');
					link.href = URL.createObjectURL(resp.data);
					link.download = attachment.FileName ?? `${attachment.Title}.${attachment.Type}`;
					link.click();
				}
			});
	};

	return (
		<FilesWrapper
			sx={{
				...sx,
			}}
		>
			{_.chunk(filesInput, 2).map((arr, i) => (
				<Row className="row">
					{arr?.map((entry, j) => (
						<Col key={`${entry?.Title}-${j}`} className="file-item">
							{readOnly && (
								<div className="menu-btn">
									<Tooltip
										arrow
										classes={{
											tooltip: classes.tooltip,
											arrow: classes.arrow,
										}}
										title="Download"
									>
										<IconButton
											className="icon-btn"
											type="button"
											onClick={() => {
												download(entry);
											}}
										>
											<FileDownloadOutlinedIcon className="icon" color="action" />
										</IconButton>
									</Tooltip>
								</div>
							)}
							{!readOnly && (
								<div className="menu">
									<IconButton
										className="icon-btn"
										onClick={() => {
											onRemoveAttachment(i * 2 + j);
										}}
									>
										<ClearIcon className="icon" color="action" />
									</IconButton>
								</div>
							)}
							{isFileView && (
								<div className="menu-btn">
									<Tooltip
										arrow
										classes={{
											tooltip: classes.tooltip,
											arrow: classes.arrow,
										}}
										title="Go to comment"
									>
										<IconButton
											className="icon-btn"
											type="button"
											onClick={() => {
												setEditComment(`reply_${entry?.CommentId}`);
												toggleFiles(false);
											}}
										>
											<PersonOutlineOutlinedIcon className="icon" color="action" />
										</IconButton>
									</Tooltip>
									<Tooltip
										arrow
										classes={{
											tooltip: classes.tooltip,
											arrow: classes.arrow,
										}}
										title="Download"
									>
										<IconButton
											className="icon-btn"
											type="button"
											onClick={() => {
												download(entry);
											}}
										>
											<FileDownloadOutlinedIcon className="icon" color="action" />
										</IconButton>
									</Tooltip>
								</div>
							)}

							<Icon component={FILE_ICON[entry.ContentType] || DocumentIcon} />
							<div className="main">
								<label>{entry?.Title}</label>
								<span>
									{entry?.CreatedAt}
									&nbsp;&middot;&nbsp;
									{entry?.FileSize}
									&nbsp;&middot;&nbsp;
									{entry?.Type}
								</span>
							</div>
							{!!entry.Data && (
								<div
									className="progress-bar"
									style={{
										width: `${progress}%`,
									}}
								/>
							)}
						</Col>
					))}
				</Row>
			))}
		</FilesWrapper>
	);
};

export default CommentFiles;
