import axios from 'axios';
import handleApiResponse from './utils';

const getDiagnosticReports = async ({ __config, patientId, status, performer, studyId }) => {
	let response;
	let diagnosticReports = [];
	let page = 1;
	do {
		response = await axios.get(
			`${__config.data_sources.fhir}/DiagnosticReport?_count=50&page=${page}${
				patientId ? `&subject=${patientId}` : ''
			}${status ? `&status=${status}` : ''}${performer ? `&performer=Practitioner/${performer}` : ''}${
				studyId ? `&imagingstudy=${studyId}` : ''
			}&_dc=${new Date().getTime()}`
		);
		response = handleApiResponse(response);
		page += 1;
		const responseData = response?.data?.entry ? response.data.entry.map(item => item.resource) : [];
		diagnosticReports = [...diagnosticReports, ...responseData];
	} while (response?.data?.entry?.length === 50);

	return diagnosticReports;
};

export default getDiagnosticReports;
