import { create } from 'zustand';

export const useGlobalNotesStore = create(set => ({
	// Store variables w/ default values
	globalNotes: [],

	// ==================== Setters ====================
	setGlobalNotes: notesList =>
		set(state => ({
			globalNotes: typeof notesList === 'function' ? notesList(state.globalNotes) : notesList,
		})),

	// ==================== Re-setters ====================
	resetGlobalNotesStore: () =>
		set({
			// #NOTE: Be sure to include all the store's variables !
			globalNotes: [],
		}),
}));
