import React from 'react';
import { Controller } from 'react-hook-form';
import TimeSelector from '@rs-ui/components/TimeSelector/TimeSelector';
import Box from '@mui/system/Box';
import { convertMinutes } from '../../../utils/convertMinutes';

const TimeSelectorVariant = ({
	formHook,
	name,
	props,
	isEditing,
	textPostfix = true,
	inputHeight,
	fontSize,
	onChange,
	disabled,
	wrapStyles,
}) => (
	<Controller
		control={formHook.control}
		name={name}
		render={({ field }) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const convertedTime = convertMinutes(field.value);

			return (
				<Box
					className="TIME_BOX_CLASS"
					sx={{
						width: '100%',
						'& .MuiFormControl-root .MuiOutlinedInput-root .MuiInputAdornment-root': {
							display: !isEditing ? 'none' : 'flex',
						},

						'& .MuiOutlinedInput-root .MuiSvgIcon-root': {
							display: !isEditing ? 'none' : 'block',
						},

						'& .MuiFormControl-root .MuiOutlinedInput-root fieldset': {
							borderWidth: !isEditing ? '0' : '1px',
						},

						'& .MuiOutlinedInput-input.Mui-disabled': {
							textFillColor: '#fff',
						},

						'& .MuiOutlinedInput-root': {
							paddingLeft: !isEditing ? 0 : '13px',
							fontSize: fontSize || '16px',
						},

						'& .MuiInputLabel-root': {
							left: !isEditing ? '-13px' : 0,
						},

						...wrapStyles,
					}}
				>
					<TimeSelector
						label={props.label}
						textPostfix={textPostfix}
						{...props}
						disabled={disabled}
						inputHeight={inputHeight}
						isEditing={isEditing}
						valueHours={convertedTime.hours}
						valueMinutes={convertedTime.minutes}
						onDone={value => {
							if (onChange) {
								onChange(value);
							}
							field.onChange(value);
						}}
					/>
				</Box>
			);
		}}
	/>
);

export default TimeSelectorVariant;
