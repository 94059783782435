import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const ImageViewerViewportScrollbarIndicator = ({ activeScroll, scrollItems, lastLoadedImage, wonIvAutohidescroll }) => {
	const isLoading = activeScroll !== lastLoadedImage;

	let activeScrollIndex = scrollItems.findIndex(scrollItem => scrollItem + 1 === activeScroll);

	if (activeScrollIndex === -1) {
		scrollItems.forEach((_scrollItem, index) => {
			if (activeScroll > scrollItems[index]) {
				activeScrollIndex = index;
			}
		});
	}

	const getActiveScrollCoefficient = () => {
		if (activeScrollIndex === scrollItems.length - 1) {
			return 0;
		}
		return (
			27 *
			((scrollItems[activeScrollIndex] + 1 - scrollItems[activeScrollIndex]) /
				(scrollItems[activeScrollIndex + 1] + 1 - scrollItems[activeScrollIndex]))
		);
	};

	const top =
		activeScrollIndex * 27 +
		(activeScroll - 1 - scrollItems[activeScrollIndex]) * getActiveScrollCoefficient() -
		3.5;

	const frameNumberStyle = {
		fontWeight: '500',
		fontSize: '12px',
		lineHeight: '15px',
		color: '#FFFFFF',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
	};

	return (
		<Box
			sx={{
				width: '34px',
				height: '26px',
				position: 'absolute',
				right: '0',
				top: `${top}px`,
				display: 'flex',
				alignItems: 'center',
				pointerEvents: 'none',
			}}
		>
			<Box
				className="ScrollItem"
				sx={{
					width: '19px',
					height: '19px',
					background: '#42A5F5',
					border: '1px solid rgba(255, 255, 255, 0.36)',
					borderRadius: '2px',
					boxSizing: 'border-box',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					...(wonIvAutohidescroll
						? {
								'flex-direction': 'column',
								'row-gap': '10px',
								opacity: '0',
								transition: 'opacity 0.5s ease',
								'transition-delay': '3s',
						  }
						: {}),
				}}
			>
				<Typography component="div" sx={{ ...frameNumberStyle, opacity: '1' }}>
					{activeScroll}
				</Typography>

				{isLoading && (
					<CircularProgress
						size={19}
						sx={{
							color: '#fff',
							position: 'absolute',
						}}
					/>
				)}
			</Box>

			<Box
				className="ScrollItem"
				sx={{
					width: '12px',
					height: '1px',
					background: '#42A5F5',
					...(wonIvAutohidescroll
						? {
								opacity: '0',
								transition: 'opacity 0.5s ease',
								'transition-delay': '3s',
						  }
						: {}),
				}}
			/>

			<Box
				sx={{
					width: '3px',
					height: '26px',
					background: '#42A5F5',
					borderRadius: '4px',
				}}
			/>
		</Box>
	);
};

export default ImageViewerViewportScrollbarIndicator;
