import {
	getSeriesAcquisitionDateTime,
	getSeriesAcquisitionDate,
	getSeriesAcquisitionTime,
	getSeriesContentDate,
	getSeriesContentTime,
	getSeriesDate,
	getSeriesDescription,
	getSeriesInstanceCreationDate,
	getSeriesInstanceCreationTime,
	getSeriesInstanceNumber,
	getSeriesTime,
	getSeriesTimeZoneOffset,
	getInstanceSeriesNumber,
} from './utils';

const combineDateTime = (seriesDate, seriesTime) => {
	const values = [seriesDate, seriesTime];
	if (values.every(v => v)) {
		return values.join('');
	}
	return false;
};

const SERIES_TIME_FALLBACK_QUEUE = [
	metadata => getInstanceSeriesNumber(metadata),
	metadata =>
		convertDicomDate(
			combineDateTime(getSeriesDate(metadata), getSeriesTime(metadata)),
			getSeriesTimeZoneOffset(metadata)
		),
	metadata =>
		convertDicomDate(
			combineDateTime(getSeriesAcquisitionDate(metadata), getSeriesAcquisitionTime(metadata)),
			getSeriesTimeZoneOffset(metadata)
		),
	metadata => convertDicomDate(getSeriesAcquisitionDateTime(metadata), getSeriesTimeZoneOffset(metadata)),
	metadata =>
		convertDicomDate(
			combineDateTime(getSeriesInstanceCreationDate(metadata), getSeriesInstanceCreationTime(metadata)),
			getSeriesTimeZoneOffset(metadata)
		),
	metadata =>
		convertDicomDate(
			combineDateTime(getSeriesContentDate(metadata), getSeriesContentTime(metadata)),
			getSeriesTimeZoneOffset(metadata)
		),
	metadata => getSeriesInstanceNumber(metadata),
	metadata => getSeriesDescription(metadata),
];

const convertDicomDate = (dateString, timezoneOffset) => {
	if (dateString) {
		const year = dateString.slice(0, 4);
		const month = dateString.slice(4, 6);
		const day = dateString.slice(6, 8);
		const hour = dateString.slice(8, 10);
		const minute = dateString.slice(10, 12);
		const second = dateString.slice(12, 14);
		const millisecond = dateString.slice(15, 21) || '000000';
		const timezoneFromDate = dateString.slice(21, 26);
		const date = new Date(year, month - 1, day, hour, minute, second, millisecond);
		const timezone = timezoneFromDate || timezoneOffset;

		if (timezone) {
			const offsetSign = timezone[0] === '+' ? -1 : 1;
			const offsetHours = parseInt(timezone.slice(1, 3));
			const offsetMinutes = parseInt(timezone.slice(3, 5));
			date.setHours(date.getHours() + offsetSign * offsetHours);
			date.setMinutes(date.getMinutes() + offsetSign * offsetMinutes);
		}
		return date;
	}
	return false;
};

const hasDuplicates = array => new Set(array.map(a => a.compareValue.toString())).size !== array.length;

export const sortSeries = (series, i = 0) => {
	let fallbackConter = i;
	let dateTime = [];

	for (let index = 0; index < series.length; index++) {
		const element = series[index];
		const compareValue = SERIES_TIME_FALLBACK_QUEUE[fallbackConter](element.metadata[0]);
		if (compareValue) {
			dateTime = [...dateTime, { index, compareValue }];
			if (hasDuplicates(dateTime) && SERIES_TIME_FALLBACK_QUEUE.length - 1 > i) {
				fallbackConter++;
				break;
			}
		} else {
			if (SERIES_TIME_FALLBACK_QUEUE.length - 1 > i) fallbackConter++;
			break;
		}
	}

	if (fallbackConter !== i) {
		return sortSeries(series, fallbackConter);
	}
	dateTime.sort((a, b) =>
		fallbackConter === SERIES_TIME_FALLBACK_QUEUE.length - 1
			? a.compareValue.localeCompare(b.compareValue)
			: a.compareValue - b.compareValue
	);
	return dateTime.length > 0 && dateTime.length === series.length ? dateTime.map(v => series[v.index]) : series;
};
