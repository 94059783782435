import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TabPanel } from '../../../Cases/casePopOverStyles';
import Features from './Features';
import MapField from '../DetailTabs/MapField';
import organizationTypes from '@worklist-2/ui/src/views/OrganizationDetailView/organizationDetail/organizationTypes';
import FileList from './FileList';
import { useCrmContext } from '../../../../../context/CRMContext';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const Organization = ({
	accountData,
	informationData,
	featuresData,
	canEdit,
	setFeatures,
	currentOrganizationID,
	setStepCustom,
	setDbClickOrganization,
	fileListCacheKey,
}) => {
	const typeObj =
		typeof informationData?.type === 'string' ? JSON.parse(informationData?.type) : informationData?.type;
	const breezeEnableAccountFile = useBooleanFlagValue('breeze-enable-account-file');
	const { organizationData } = useCrmContext();
	const generalData = [
		[
			{ title: 'Organization Name', data: informationData?.name },
			{ title: 'Parent Organization', data: informationData?.partOf?.display },
			{
				title: 'Organization Type',
				data: organizationTypes.find(item => (item.code = typeObj?.[0]?.coding?.[0]?.code))?.display,
			},
		],
		[
			{ title: 'NPI/Organization ID', data: informationData?.identifier[0]?.value },
			{
				title: 'Time Zone',
				data: informationData?.extension?.find(item => item.url.includes('/StructureDefinition/timezone'))
					?.valueString,
			},
			{
				title: 'Corporate Website',
				data: informationData?.telecom?.find(item => item.system === 'url')?.value,
			},
		],
	];

	const contactData = [
		{ title: 'Phone number', data: informationData?.telecom?.find(item => item.system === 'phone')?.value },
		{ title: 'Fax Number', data: informationData?.telecom?.find(item => item.system === 'fax')?.value },
		{ title: 'Email', data: informationData?.telecom?.find(item => item.system === 'email')?.value },
	];
	const addressData = [
		{
			title: 'Billing Address',
			data: {
				line: informationData?.address?.[0]?.line?.[0] || '',
				city: informationData?.address?.[0]?.city || '',
				state: informationData?.address?.[0]?.state || '',
				country: informationData?.address?.[0]?.country || '',
				postalCode: informationData?.address?.[0]?.postalCode || '',
			},
		},
		{
			title: 'Shipping Address',
			data: {
				line:
					informationData?.extension?.find(item => item.url.includes('/shippingAddress'))?.valueString || '',
				city: informationData?.extension?.find(item => item.url.includes('/shippingCity'))?.valueString || '',
				state:
					informationData?.extension?.find(item => item.url.includes('/shippingProvince'))?.valueString || '',
				country:
					informationData?.extension?.find(item => item.url.includes('/shippingCountry'))?.valueString || '',
				postalCode:
					informationData?.extension?.find(item => item.url.includes('/shippingPostalCode'))?.valueString ||
					'',
			},
		},
		{ title: null },
	];

	return (
		<TabPanel className="invisible-scrollbar">
			<div className="header">
				<div className="left">
					<div className="colored-icon" style={{ backgroundColor: 'rgba(66, 165, 245, 0.2)' }}>
						<BusinessOutlinedIcon sx={{ fontSize: 20, color: '#42A5F5' }} />
					</div>
					<h2>Organization Information</h2>
				</div>
			</div>
			<Box sx={{ marginTop: '8px' }}>
				<InformationAccordion
					body={generalData.map((rowData, i) => (
						<InformationRow key={i} data={rowData} index={i} />
					))}
					title="General"
				/>
				<InformationAccordion
					body={<InformationRow isAddress data={addressData} index={0} />}
					title="Address"
				/>
				<InformationAccordion
					body={<InformationRow data={contactData} index={0} />}
					title="Contact Information"
				/>
				<InformationAccordion
					body={
						<Features
							accountData={accountData}
							canEdit={canEdit}
							currentOrganizationID={informationData?.id}
							features={featuresData}
							setFeatures={setFeatures}
						/>
					}
					title="Features"
				/>
				{breezeEnableAccountFile && (
					<InformationAccordion
						body={
							<FileList
								accountId={accountData?.id}
								cacheKey={fileListCacheKey}
								data={organizationData || accountData?.Organization}
								organizationId={currentOrganizationID || informationData?.id}
								setDbClickOrganization={setDbClickOrganization}
								setStepCustom={setStepCustom}
							/>
						}
						title="Files"
					/>
				)}
			</Box>
		</TabPanel>
	);
};

const CustomAccordion = styled(Accordion)(() => ({
	boxShadow: 'none',
	'&::before': {
		height: 0,
	},
	'&.Mui-expanded': {
		margin: 0,
	},
	'.MuiAccordionDetails-root': {
		padding: 0,
		margin: 0,
	},
	'.MuiAccordionSummary-content': {
		padding: 0,
		margin: 0,
		'&.Mui-expanded': {
			margin: 0,
		},
	},
	'.MuiAccordionSummary-root': {
		padding: 0,
		margin: 0,
		'&.Mui-expanded': {
			minHeight: 'unset',
		},
	},
	'.MuiAccordionSummary-expandIconWrapper': {
		padding: '12px',
		color: 'rgba(0, 0, 0, 0.6)',
	},
}));

const InformationAccordion = ({ title, body }) => (
	<CustomAccordion defaultExpanded>
		<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#F9FBFE' }}>
			<Typography sx={{ padding: '8px 18px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.6)' }}>{title}</Typography>
		</AccordionSummary>
		<AccordionDetails>{body}</AccordionDetails>
	</CustomAccordion>
);

const InformationRow = ({ data, index, isAddress }) => (
	<Box
		sx={{
			boxSizing: 'border-box',
			width: '100%',
			display: 'flex',
			padding: '22px 16px',
			borderTop: index === 0 ? 'unset' : '1px solid rgba(0, 0, 0, 0.1)',
		}}
	>
		{isAddress
			? data.map(item => <AddressItem key={item.title} data={item.data} title={item.title} />)
			: data.map(item => <InformationRowItem key={item.title} data={item.data} title={item.title} />)}
	</Box>
);

const InformationRowItem = ({ title, data }) => (
	<Box sx={{ width: '100%' }}>
		<Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.6)' }}>{title}</Typography>
		<Typography
			sx={{
				marginTop: '12px',
				fontSize: '14px',
				color: 'rgba(0, 0, 0, 0.9)',
			}}
		>
			{data || '-'}
		</Typography>
	</Box>
);

const AddressItem = ({ title, data }) => {
	const hasAddress = data && (data.line || data.city || data.state || data.country || data.postalCode);

	return (
		<Box sx={{ width: '100%' }}>
			{title && (
				<>
					<Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.6)' }}>
						{title}
					</Typography>
					<Typography
						sx={{
							height: hasAddress ? '64px' : 'unset',
							marginTop: '12px',
							fontSize: '14px',
							color: 'rgba(0, 0, 0, 0.9)',
						}}
					>
						{hasAddress ? (
							<>
								{data.line}
								<br />
								{data.city ? `${data.city}, ${data.state}` : data.state}
								<br />
								{data.country ? `${data.country} ${data.postalCode}` : data.postalCode}
							</>
						) : (
							'-'
						)}
					</Typography>
					{hasAddress && <MapField expanded />}
				</>
			)}
		</Box>
	);
};

export default Organization;
