import React, { useState, useEffect, useMemo, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { FileDrop } from 'react-file-drop';
import { EditorState, RichUtils, ContentState, Modifier, convertFromRaw, convertToRaw } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import 'draft-js/dist/Draft.css';
import 'draft-js-mention-plugin/lib/plugin.css';

import { Box } from '@mui/system';
import { Tooltip, IconButton, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SendIcon from '@mui/icons-material/Send';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import FileUploadIcon from '@worklist-2/ui/src/assets/icons/breeze/FileUpload.svg';

import { makeStyles } from 'tss-react/mui';
import styled from '@mui/system/styled';

import { formatBytes, arrayBufferToBase64 } from '../Attachments/fileUtils.js';
import CommentFiles from '../Attachments/CommentFiles.jsx';

import { sleep } from '@worklist-2/core/src';

const allowedMimeTypes = [
	// doc
	'application/msword',
	// docx
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	// pdf
	'application/pdf',
	// json
	'application/json',
	// text
	'text/plain',
	'text/csv',
	// excel
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	// xml
	'text/xml',
	// images (jpg, png, webp, bmp)
	'image/jpeg',
	'image/png',
	'image/webp',
	'image/bmp',
	'image/gif',
	// video
	'video/mp4',
	'video/mpeg',
	// zip
	'application/zip',
	'application/x-zip-compressed',
	// others
	'application/octet-stream',
	'application/postscript',
	// dicom
	'',
	'application/dicom',
	'.dcm',
	'.log',
];

const maxFileSize = 104857600;

const useStyles = makeStyles()(() => ({
	tooltip: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#4D79EA',
		borderRadius: '5px',
	},

	arrow: {
		color: '#4D79EA',
	},
}));

const FileDropContainer = styled(Box)({
	position: 'relative',
	width: '100%',
	minHeight: '100px',
	border: '1px dashed #4D79EA',
	borderRadius: '10px',

	'& .file-drop > .file-drop-target': {
		position: 'absolute',
		top: -1,
		left: -1,
		height: '102%',
		width: '100.5%',
		overflow: 'hidden',
	},
	'& .file-drop > .file-drop-target.file-drop-dragging-over-frame::after': {
		content: '""',
		display: 'flex',
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		background: 'rgba(77, 121, 234, 0.05)',
		border: '2px solid #4D79EA',
		borderRadius: '10px',
		boxSizing: 'border-box',
	},
});

const Entry = ({ mention, theme, searchValue, isFocused, ...parentProps }) => {
	const initialUser = name =>
		name
			?.split(' ')
			.map(n => n.charAt(0).toUpperCase())
			.join('');

	return (
		<div
			{...parentProps}
			style={{
				marginLeft: 1,
				backgroundColor: 'rsPrimary.contrastText',
				display: 'flex',
			}}
		>
			<Avatar
				sx={{
					width: '22px',
					height: '22px',
					fontSize: '10px',
					background: '#FFDBF3',
					color: '#D349A4',
					fontWeight: 'bold',
					display: 'inline-flex',
				}}
			>
				{initialUser(mention.name)}
			</Avatar>
			<Typography
				noWrap
				sx={{
					maxWidth: 120,
					fontSize: '13px',
					color: '#121212',
					paddingRight: 1,
					paddingLeft: 1,
					display: 'inline-flex',
				}}
			>
				{mention.name}
			</Typography>
		</div>
	);
};

const TextEditor = forwardRef(
	(
		{
			commentData,
			setCommentData,
			attachments,
			setAttachments,
			submitComment,
			sendPrivate,
			setSendPrivate,
			readOnly,
			isEditMode,
			isChat,
			sx,
			users,
			progress,
		},
		ref
	) => {
		const { classes } = useStyles();
		const maxLength = 20000;
		const [editorState, setEditorState] = useState(() => {
			if (commentData) {
				try {
					const blocks = JSON.parse(commentData);
					const templateData = convertFromRaw(blocks);
					return EditorState.createWithContent(templateData);
				} catch (e) {
					const contentState = ContentState.createFromText(commentData);
					return EditorState.createWithContent(contentState);
				}
			} else {
				return EditorState.createEmpty();
			}
		});
		const [suggestions, setSuggestions] = useState(users);
		const [isAttach, setIsAttach] = useState(false);
		const [isSubmited, setIsSubmited] = useState(false);
		const [isUploading, setIsUploading] = useState(false);

		const inputRef = useRef(null);

		const { MentionSuggestions, plugins } = useMemo(() => {
			const mentionPlugin = createMentionPlugin({
				entityMutability: 'IMMUTABLE',
				mentionPrefix: '@',
				supportWhitespace: false,
			});
			const { MentionSuggestions } = mentionPlugin;
			const plugins = [mentionPlugin];
			return { plugins, MentionSuggestions };
		}, []);

		useEffect(() => {
			if (setCommentData) {
				const contentState = editorState.getCurrentContent();
				setCommentData(JSON.stringify(convertToRaw(contentState)));
			}
		}, [editorState]);

		const onChange = editorStateChange => {
			let editorStateSts = editorStateChange;
			const contentState = editorStateChange.getCurrentContent();
			if (contentState.getPlainText().length > maxLength) {
				editorStateSts = EditorState.undo(editorState);
			}
			setEditorState(editorStateSts);
		};

		const handleBeforeInput = chars => {
			const totalLength = editorState.getCurrentContent().getPlainText().length + chars.length;
			if (totalLength > maxLength) {
				return 'handled';
			}
		};

		const applyStyle = style => {
			const editorStateFocused = EditorState.forceSelection(editorState, editorState.getSelection());

			setEditorState(RichUtils.toggleInlineStyle(editorStateFocused, style));
		};

		const toggleBulletPoints = () => {
			setEditorState(RichUtils.toggleBlockType(editorState, 'unordered-list-item'));
		};

		const orderedList = () => {
			setEditorState(RichUtils.toggleBlockType(editorState, 'ordered-list-item'));
		};

		const appendMention = () => {
			const contentState = editorState.getCurrentContent();
			const targetRange = editorState.getSelection();
			const textWithInsert = Modifier.insertText(contentState, targetRange, '@');

			const editorWithInsert = EditorState.push(editorState, textWithInsert, 'insert-characters');

			setEditorState(editorWithInsert);
		};

		const onSearchChange = ({ value }) => {
			setSuggestions(defaultSuggestionsFilter(value, users));
		};

		const onFilesDrop = useCallback(
			(files, e) => {
				e?.preventDefault();
				handleFileChange(files);
			},
			[attachments]
		);

		const onFileInputChange = e => {
			e.preventDefault();
			handleFileChange(e.target.files);
		};

		const handleFileChange = async files => {
			const promises = [...files]
				.filter(({ type }) => allowedMimeTypes.includes(type))
				.filter(({ size }) => size <= maxFileSize)
				.map(async file => {
					const fileNameArr = file.name.split('.');
					const fileInfo = {
						Title: fileNameArr[0],
						Type: fileNameArr[fileNameArr.length - 1],
						FileName: file.name,
						ContentType: file.type,
						FileSize: formatBytes(file.size),
						CreatedAt: new Date(file.lastModified).toLocaleDateString(),
					};

					const fileReader = new FileReader();
					fileReader.onloadend = async evt => {
						if (evt.target.readyState == FileReader.DONE && evt.target.result) {
							if (fileInfo.ContentType === '') {
								const array =
									evt.target.result.byteLength > 132 ? new Uint8Array(evt.target.result, 128, 4) : [];
								const DICM = String.fromCharCode.apply(null, array);

								if (DICM == 'DICM') {
									fileInfo.ContentType = 'application/dicom';
									fileInfo.Type = 'dcm';
								}

								if (fileInfo.Type == 'log') {
									fileInfo.ContentType = 'text/plain';
								}
							}
							setIsUploading(false);
						}
					};
					fileReader.readAsArrayBuffer(file);
					setIsUploading(true);
					while (isUploading) {
						await sleep(100);
					}
					fileInfo.Data = await file.arrayBuffer().then(res => arrayBufferToBase64(res));

					if (fileInfo.ContentType !== '') {
						return fileInfo;
					}
				});

			const fileImport = await Promise.all(promises);
			setAttachments([...(attachments ?? []), ...fileImport.filter(file => file !== undefined)]);
			setIsAttach(false);
		};

		const onRemoveAttachment = index => {
			const array = [...attachments];
			array.splice(index, 1);
			setAttachments(array);
		};

		useImperativeHandle(ref, () => ({
			clearEditorData: () => setEditorState(EditorState.createEmpty()),
			getPlainText: () => editorState.getCurrentContent().getPlainText(),
		}));

		return !readOnly ? (
			<Box
				sx={{
					...sx,
				}}
			>
				<Box
					sx={{
						'.MuiSvgIcon-root': {
							fontSize: '15px',
							'&:hover': {
								color: '#4D79EA',
							},
						},
					}}
				>
					<IconButton>
						<FormatBoldIcon color="action" onClick={() => applyStyle('BOLD')} />
					</IconButton>
					<IconButton>
						<FormatItalicIcon color="action" onClick={() => applyStyle('ITALIC')} />
					</IconButton>
					<IconButton>
						<FormatUnderlinedIcon color="action" onClick={() => applyStyle('UNDERLINE')} />
					</IconButton>
					<IconButton>
						<StrikethroughSIcon color="action" onClick={() => applyStyle('STRIKETHROUGH')} />
					</IconButton>

					<Box
						sx={{
							display: 'inline-flex',
							margin: '-6px 4px',
							width: '1px',
							height: '20px',
							background: 'rgba(196, 196, 196, 0.4)',
						}}
					/>
					<IconButton>
						<FormatListBulletedIcon color="action" onClick={toggleBulletPoints} />
					</IconButton>
					<IconButton>
						<FormatListNumberedIcon color="action" onClick={orderedList} />
					</IconButton>

					<Box
						sx={{
							display: 'inline-flex',
							margin: '-6px 4px',
							width: '1px',
							height: '20px',
							background: 'rgba(196, 196, 196, 0.4)',
						}}
					/>

					<IconButton>
						<AttachFileIcon color="action" onClick={() => setIsAttach(!isAttach)} />
					</IconButton>
					<IconButton>
						<AlternateEmailIcon color="action" onClick={appendMention} />
					</IconButton>
				</Box>
				{isAttach ? (
					<FileDropContainer>
						<FileDrop onDrop={onFilesDrop}>
							<input
								ref={inputRef}
								multiple
								accept={allowedMimeTypes}
								data-testid="input-file-upload"
								id="input-file-upload"
								style={{ display: 'none' }}
								type="file"
								onChange={onFileInputChange}
							/>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									marginTop: '15px',
								}}
							>
								<FileUploadIcon />
								<Typography
									sx={{
										fontFamily: 'Inter',
										fontStyle: 'normal',
										fontWeight: '500',
										fontSize: '14px',
										lineHeight: '20px',
										color: '#4D79EA',
									}}
								>
									Drag and drop files or
								</Typography>
								<Button
									sx={{
										height: '33px',
										width: '80px',
										marginLeft: '10px',
										backgroundColor: '#4D79EA',
										borderColor: '#4D79EA',
										boxShadow: 'none',
										'&:hover': {
											backgroundColor: '#4D79EA',
											borderColor: '#4D79EA',
											boxShadow: 'none',
										},
									}}
									variant="contained"
									onClick={() => inputRef.current.click()}
								>
									BROWSE
								</Button>
							</Box>
						</FileDrop>
					</FileDropContainer>
				) : (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Box
							sx={{
								display: 'flex',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									flex: '1',
									borderRadius: '10px',
									border: '1px solid rgba(0, 0, 0, 0.3)',
									'&:hover': {
										border: '1px solid #4D79EA',
									},
								}}
							>
								{isChat ? (
									<></>
								) : (
									!isEditMode && (
										<Tooltip
											arrow
											classes={{
												tooltip: classes.tooltip,
												arrow: classes.arrow,
											}}
											title={sendPrivate ? 'Private' : 'Public'}
										>
											<IconButton
												className="icon-btn"
												disabled={isSubmited}
												type="button"
												onClick={() => {
													setSendPrivate(!sendPrivate);
												}}
											>
												{sendPrivate ? (
													<LockIcon
														sx={{
															fontSize: '12px',
															marginLeft: 'auto',
														}}
													/>
												) : (
													<LockOpenIcon
														sx={{
															fontSize: '12px',
															marginLeft: 'auto',
														}}
													/>
												)}
											</IconButton>
										</Tooltip>
									)
								)}
								<Box
									sx={{
										flex: '1',
										overflow: 'auto',
										border: isChat ? 'none' : '1px solid rgba(0, 0, 0, 0.05)',
										borderRadius: '10px',
										background: isChat ? '#FFFFFF' : 'rgba(196, 196, 196, 0.15)',
										maxHeight: '135px',

										'& .public-DraftEditorPlaceholder-inner': {
											fontFamily: 'Roboto',
											fontWeight: '400',
											fontSize: '14px',
											lineHeight: '24px',
											letterSpacing: '0.15px',
										},
										'& .DraftEditor-root': {
											position: 'relative',
										},
										'& .public-DraftEditorPlaceholder-root': {
											'z-index': '-1',
											position: 'absolute',
											top: '0',
										},
										'& .DraftEditor-editorContainer': {
											margin: '10px 7px',
										},
										'& .public-DraftStyleDefault-block': {
											fontFamily: 'Roboto',
											fontWeight: '400',
											fontSize: '14px',
											lineHeight: '24px',
											letterSpacing: '0.15px',

											'& span': {
												color: '#000000',
											},
										},
										'& .public-DraftStyleDefault-orderedListItem': {
											fontFamily: 'Roboto',
											fontWeight: '400',
											fontSize: '14px',
											lineHeight: '24px',
											letterSpacing: '0.15px',
										},
										'& .public-DraftEditor-content': {
											overflowWrap: 'anywhere !important',
										},
									}}
								>
									<Editor
										ref={ref}
										editorState={editorState}
										handleBeforeInput={handleBeforeInput}
										plugins={plugins}
										readOnly={isSubmited}
										onChange={onChange}
									/>
									<MentionSuggestions
										entryComponent={Entry}
										popoverContainer={({ children }) => <div>{children}</div>}
										suggestions={suggestions}
										onSearchChange={onSearchChange}
									/>
								</Box>
							</Box>
							{!isEditMode && (
								<IconButton
									disabled={isSubmited}
									id="submitComment_AddNew"
									sx={{
										alignSelf: isChat ? 'flex-end' : 'auto',
									}}
									onClick={e => {
										if (!isChat) {
											setIsSubmited(true);
										}
										submitComment(e);
										setIsSubmited(false);
									}}
								>
									<SendIcon
										id="submitComment_AddNew"
										sx={{
											fontSize: 21,
											color: '#4D79EA',
										}}
									/>
								</IconButton>
							)}
						</Box>
						{attachments && (
							<CommentFiles
								filesInput={attachments}
								progress={progress}
								readOnly={readOnly || isSubmited}
								sx={{
									width: '97%',

									'.row > .file-item': {
										margin: '5px 23px 5px 0px',
									},
								}}
								onRemoveAttachment={onRemoveAttachment}
							/>
						)}
					</Box>
				)}
			</Box>
		) : (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Box
					sx={{
						...sx,
					}}
				>
					<Editor editorState={editorState} readOnly={readOnly} onChange={() => {}} />
				</Box>
				{attachments && (
					<CommentFiles
						filesInput={attachments}
						sx={{
							width: '100%',

							'.row > .file-item': {
								margin: '5px 23px 5px 0px',
							},
						}}
					/>
				)}
			</Box>
		);
	}
);

TextEditor.propTypes = {
	commentData: PropTypes.string,
	setCommentData: PropTypes.func,
	attachments: PropTypes.array,
	setAttachments: PropTypes.func,
	submitComment: PropTypes.func,
	sendPrivate: PropTypes.bool,
	setSendPrivate: PropTypes.func,
	readOnly: PropTypes.bool,
	isEditMode: PropTypes.bool,
	users: PropTypes.array,
};

TextEditor.defaultProps = {
	readOnly: false,
	isEditMode: false,
	users: [],
};

export default TextEditor;
