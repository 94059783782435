import React from 'react';

const SearchIcon = () => (
	<svg fill="none" height="64" viewBox="0 0 85 64" width="85" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_46271)">
			<path
				d="M37.465 0.0617267C37.0763 0.0482053 36.6897 0.0391912 36.3054 0.030177C30.1367 -0.0982758 23.8414 0.109052 18.0163 2.135C12.1912 4.16095 6.82267 8.26693 4.58259 13.991C3.42299 16.9634 3.14948 20.1905 2.71774 23.35C1.91076 29.2746 0.507036 35.1474 0.432442 41.1238C0.357848 47.1002 1.7887 53.3922 5.90494 57.746C13.0456 65.309 22.9508 60.8267 31.4478 58.7894C36.4905 57.5848 41.7362 57.4917 46.8187 58.5168C50.1732 59.1928 53.4124 60.3579 56.7646 61.0745C59.1765 61.5906 61.6855 61.8746 64.1426 61.5568C66.5025 61.2526 68.3222 60.1303 70.4718 59.2266C74.5632 57.4959 78.6998 55.2919 81.3083 51.5758C83.3075 48.6585 84.225 45.1381 83.9033 41.6196C83.1008 32.3439 74.4095 28.4498 69.9225 21.1595C67.3095 16.9228 65.5283 12.1183 62.167 8.44496C56.1362 1.86457 46.4751 0.347929 37.465 0.0617267Z"
				fill="#4BAAF6"
				opacity="0.18"
			/>
			<path
				d="M50.7858 9.40707V55.4135C50.7858 56.4791 50.3612 57.5011 49.6053 58.2547C48.8495 59.0082 47.8244 59.4315 46.7555 59.4315H26.7823C25.7134 59.4315 24.6883 59.0082 23.9324 58.2547C23.1766 57.5011 22.752 56.4791 22.752 55.4135V9.40707C22.7517 8.87921 22.8557 8.35647 23.0581 7.86872C23.2605 7.38096 23.5573 6.93774 23.9316 6.56439C24.3059 6.19103 24.7503 5.89486 25.2394 5.69279C25.7286 5.49072 26.2528 5.38672 26.7823 5.38672H46.7555C47.2849 5.38672 47.8092 5.49072 48.2983 5.69279C48.7875 5.89486 49.2319 6.19103 49.6061 6.56439C49.9804 6.93774 50.2772 7.38096 50.4797 7.86872C50.6821 8.35647 50.7861 8.87921 50.7858 9.40707Z"
				fill="#4BAAF6"
			/>
			<path
				d="M46.3352 6.07031H27.2007C25.0484 6.07031 23.3037 7.80975 23.3037 9.95545V54.781C23.3037 56.9267 25.0484 58.6661 27.2007 58.6661H46.3352C48.4875 58.6661 50.2322 56.9267 50.2322 54.781V9.95545C50.2322 7.80975 48.4875 6.07031 46.3352 6.07031Z"
				fill="#473F47"
			/>
			<path
				d="M49.499 10.3962V53.8019C49.4889 54.7859 49.0872 55.7257 48.3823 56.4145C47.6773 57.1033 46.7269 57.4848 45.7399 57.4752H27.8124C27.1575 57.4773 26.5133 57.3096 25.9431 56.9885C25.3712 56.6737 24.8941 56.2123 24.5611 55.6521C24.2281 55.0919 24.0512 54.4531 24.0488 53.8019V10.3962C24.0596 9.41155 24.4621 8.4715 25.1678 7.78268C25.8736 7.09385 26.8248 6.71264 27.8124 6.72286H30.8414V7.25695C30.8491 7.60735 30.9605 7.94767 31.1616 8.23515C31.3627 8.52263 31.6445 8.74446 31.9716 8.87275C32.1912 8.95906 32.4251 9.0034 32.6611 9.00346H40.9885C41.4654 9.00648 41.9242 8.82157 42.265 8.48898C42.6058 8.15639 42.801 7.70305 42.8081 7.22765V6.72286H45.7467C46.7109 6.72074 47.6396 7.08498 48.3439 7.74146C48.7088 8.08128 48.9997 8.49233 49.1984 8.94904C49.3971 9.40574 49.4994 9.89833 49.499 10.3962Z"
				fill="white"
			/>
			<path
				d="M37.9551 36.2461H27.5367C27.3557 36.2461 27.209 36.3924 27.209 36.5729V36.5751C27.209 36.7556 27.3557 36.9019 27.5367 36.9019H37.9551C38.1361 36.9019 38.2828 36.7556 38.2828 36.5751V36.5729C38.2828 36.3924 38.1361 36.2461 37.9551 36.2461Z"
				fill="#4BAAF6"
				opacity="0.31"
			/>
			<path
				d="M37.9551 37.8477H27.5367C27.3557 37.8477 27.209 37.994 27.209 38.1744V38.1767C27.209 38.3571 27.3557 38.5034 27.5367 38.5034H37.9551C38.1361 38.5034 38.2828 38.3571 38.2828 38.1767V38.1744C38.2828 37.994 38.1361 37.8477 37.9551 37.8477Z"
				fill="#4BAAF6"
				opacity="0.31"
			/>
			<path d="M50.7851 30.3438H22.749V32.0993H50.7851V30.3438Z" fill="#4BAAF6" opacity="0.18" />
			<path
				d="M18.5566 25.9141H52.7027C53.31 25.9141 53.8924 26.1546 54.3218 26.5827C54.7513 27.0108 54.9925 27.5915 54.9925 28.1969C54.9925 28.8024 54.7513 29.383 54.3218 29.8111C53.8924 30.2393 53.31 30.4798 52.7027 30.4798H18.5566V25.9141Z"
				fill="#4BAAF6"
			/>
			<path d="M46.1294 25.9141H18.5566V30.4775H46.1294V25.9141Z" fill="white" />
			<path
				d="M50.2225 29.0399C50.833 29.0399 51.3279 28.5465 51.3279 27.9379C51.3279 27.3293 50.833 26.8359 50.2225 26.8359C49.6121 26.8359 49.1172 27.3293 49.1172 27.9379C49.1172 28.5465 49.6121 29.0399 50.2225 29.0399Z"
				stroke="white"
				strokeMiterlimit="10"
				strokeWidth="1.57687"
			/>
			<path d="M51.2333 29.7312L50.7812 28.8906" stroke="white" strokeMiterlimit="10" strokeWidth="1.57687" />
			<path
				d="M44.5013 34.6211H27.7379C27.4458 34.6211 27.209 34.8572 27.209 35.1484C27.209 35.4397 27.4458 35.6758 27.7379 35.6758H44.5013C44.7934 35.6758 45.0302 35.4397 45.0302 35.1484C45.0302 34.8572 44.7934 34.6211 44.5013 34.6211Z"
				fill="#4BAAF6"
			/>
			<path
				d="M37.9551 41.7617H27.5367C27.3557 41.7617 27.209 41.908 27.209 42.0885V42.0907C27.209 42.2712 27.3557 42.4175 27.5367 42.4175H37.9551C38.1361 42.4175 38.2828 42.2712 38.2828 42.0907V42.0885C38.2828 41.908 38.1361 41.7617 37.9551 41.7617Z"
				fill="#4BAAF6"
				opacity="0.31"
			/>
			<path
				d="M37.9551 43.3633H27.5367C27.3557 43.3633 27.209 43.5096 27.209 43.69V43.6923C27.209 43.8728 27.3557 44.0191 27.5367 44.0191H37.9551C38.1361 44.0191 38.2828 43.8728 38.2828 43.6923V43.69C38.2828 43.5096 38.1361 43.3633 37.9551 43.3633Z"
				fill="#4BAAF6"
				opacity="0.31"
			/>
			<path
				d="M44.5013 40.1328H27.7379C27.4458 40.1328 27.209 40.3689 27.209 40.6601C27.209 40.9514 27.4458 41.1875 27.7379 41.1875H44.5013C44.7934 41.1875 45.0302 40.9514 45.0302 40.6601C45.0302 40.3689 44.7934 40.1328 44.5013 40.1328Z"
				fill="#4BAAF6"
			/>
			<path
				d="M37.9551 47.2734H27.5367C27.3557 47.2734 27.209 47.4197 27.209 47.6002V47.6025C27.209 47.7829 27.3557 47.9292 27.5367 47.9292H37.9551C38.1361 47.9292 38.2828 47.7829 38.2828 47.6025V47.6002C38.2828 47.4197 38.1361 47.2734 37.9551 47.2734Z"
				fill="#4BAAF6"
				opacity="0.31"
			/>
			<path
				d="M37.9551 48.8789H27.5367C27.3557 48.8789 27.209 49.0252 27.209 49.2057V49.2079C27.209 49.3884 27.3557 49.5347 27.5367 49.5347H37.9551C38.1361 49.5347 38.2828 49.3884 38.2828 49.2079V49.2057C38.2828 49.0252 38.1361 48.8789 37.9551 48.8789Z"
				fill="#4BAAF6"
				opacity="0.31"
			/>
			<path
				d="M44.5013 45.6484H27.7379C27.4458 45.6484 27.209 45.8845 27.209 46.1758C27.209 46.467 27.4458 46.7031 27.7379 46.7031H44.5013C44.7934 46.7031 45.0302 46.467 45.0302 46.1758C45.0302 45.8845 44.7934 45.6484 44.5013 45.6484Z"
				fill="#4BAAF6"
			/>
			<g opacity="0.41">
				<path
					d="M47.1892 19.2494C48.41 19.248 49.6235 19.4373 50.7855 19.8105V18.1632C48.8017 17.6104 46.7165 17.5227 44.6931 17.9071C42.6696 18.2915 40.7628 19.1375 39.1219 20.3789C37.481 21.6202 36.1505 23.2233 35.2345 25.0627C34.3185 26.9021 33.8418 28.9278 33.8418 30.9814C33.8418 33.035 34.3185 35.0607 35.2345 36.9001C36.1505 38.7395 37.481 40.3425 39.1219 41.5839C40.7628 42.8253 42.6696 43.6713 44.6931 44.0557C46.7165 44.4401 48.8017 44.3524 50.7855 43.7996V42.1523C49.1676 42.6699 47.4564 42.8321 45.7695 42.6277C44.0827 42.4232 42.4603 41.857 41.0139 40.9679C39.5675 40.0789 38.3313 38.8881 37.3904 37.4774C36.4496 36.0667 35.8263 34.4696 35.5635 32.7959C35.3007 31.1222 35.4046 29.4116 35.868 27.7818C36.3314 26.1519 37.1434 24.6415 38.2481 23.3542C39.3528 22.067 40.724 21.0336 42.2674 20.325C43.8109 19.6164 45.49 19.2494 47.1892 19.2494Z"
					fill="url(#paint0_linear_2852_46271)"
					opacity="0.41"
				/>
			</g>
			<path
				d="M47.9009 43.6464C45.2599 43.6464 42.6783 42.8654 40.4826 41.4024C38.2868 39.9393 36.5757 37.8598 35.5657 35.4271C34.5557 32.9943 34.2921 30.3175 34.8083 27.7353C35.3245 25.1532 36.5973 22.7817 38.4657 20.9208C40.3341 19.06 42.7142 17.7935 45.3047 17.2814C47.8953 16.7694 50.58 17.0349 53.0191 18.0444C55.4583 19.0538 57.5424 20.7618 59.0077 22.9523C60.473 25.1429 61.2537 27.7174 61.251 30.3504C61.2432 33.8767 59.8337 37.2561 57.3314 39.7483C54.8291 42.2405 51.4379 43.6422 47.9009 43.6464ZM47.9009 18.6048C45.574 18.6048 43.2994 19.2926 41.3644 20.5811C39.4295 21.8696 37.9212 23.7011 37.0302 25.8441C36.1391 27.9871 35.9052 30.3453 36.3582 32.6207C36.8112 34.8961 37.9306 36.9866 39.575 38.6279C41.2194 40.2692 43.315 41.3876 45.5968 41.8418C47.8786 42.296 50.2443 42.0656 52.3948 41.1797C54.5453 40.2938 56.3842 38.7922 57.6788 36.8647C58.9735 34.9371 59.666 32.6702 59.6687 30.3504C59.6675 27.2386 58.4275 24.2545 56.221 22.0535C54.0146 19.8525 51.0221 18.6146 47.9009 18.6116V18.6048Z"
				fill="#FBCA1B"
			/>
			<path
				d="M65.9829 50.0567C65.8028 50.0568 65.6246 50.0212 65.4585 49.952C65.2924 49.8827 65.1418 49.7812 65.0154 49.6533L56.2676 40.8035C56.142 40.6772 56.0427 40.5273 55.9754 40.3626C55.908 40.1979 55.8739 40.0215 55.875 39.8436C55.8761 39.6658 55.9125 39.4899 55.9819 39.326C56.0513 39.1621 56.1525 39.0135 56.2796 38.8887C56.4067 38.7639 56.5573 38.6653 56.7227 38.5986C56.8881 38.532 57.0651 38.4984 57.2435 38.5001C57.4219 38.5017 57.5983 38.5384 57.7624 38.608C57.9266 38.6777 58.0754 38.779 58.2002 38.9061L66.9481 47.7558C67.1356 47.9456 67.2627 48.1865 67.3134 48.4481C67.3641 48.7097 67.3361 48.9804 67.233 49.2262C67.1299 49.472 66.9562 49.682 66.7338 49.8297C66.5114 49.9775 66.2501 50.0564 65.9829 50.0567Z"
				fill="#FBCA1B"
			/>
			<path
				d="M70.1731 52.8719C70.0238 53.0191 69.8468 53.1356 69.6523 53.2145C69.4578 53.2934 69.2496 53.3333 69.0396 53.3318C68.8296 53.3303 68.622 53.2876 68.4286 53.2059C68.2352 53.1243 68.0599 53.0054 67.9127 52.8561L57.6141 42.4357C57.5566 42.3789 57.5044 42.3171 57.4582 42.2509C57.4311 42.2148 57.4062 42.1788 57.3836 42.1427C57.3102 42.0195 57.277 41.8767 57.2883 41.7338C57.2996 41.591 57.355 41.4552 57.4469 41.345C57.647 41.1075 57.8654 40.8859 58.1001 40.6824C58.3697 40.4437 58.6619 40.2316 58.9727 40.0492C59.0994 39.9733 59.247 39.9392 59.3943 39.9518C59.5416 39.9643 59.6812 40.0228 59.7932 40.119C59.8242 40.1434 59.8537 40.1698 59.8814 40.1979L70.198 50.6206C70.3447 50.77 70.4605 50.9468 70.5387 51.1408C70.6169 51.3348 70.6559 51.5423 70.6536 51.7514C70.6513 51.9605 70.6077 52.1671 70.5252 52.3593C70.4428 52.5516 70.3231 52.7258 70.1731 52.8719Z"
				fill="#473F47"
			/>
			<path
				d="M33.1386 16.6918V21.2801C33.1386 21.8816 32.8996 22.4586 32.4738 22.8847C32.048 23.3109 31.4702 23.5515 30.8669 23.5539H22.752V14.418H30.8669C31.4702 14.4204 32.048 14.661 32.4738 15.0871C32.8996 15.5133 33.1386 16.0903 33.1386 16.6918Z"
				fill="#4BAAF6"
				opacity="0.18"
			/>
			<path
				d="M30.4641 13.2109H15.3599C14.1002 13.2109 13.0791 14.229 13.0791 15.4848V20.073C13.0791 21.3288 14.1002 22.3469 15.3599 22.3469H30.4641C31.7237 22.3469 32.7448 21.3288 32.7448 20.073V15.4848C32.7448 14.229 31.7237 13.2109 30.4641 13.2109Z"
				fill="white"
			/>
			<path
				d="M32.0554 15.1243C32.963 15.1243 33.6988 14.3908 33.6988 13.486C33.6988 12.5812 32.963 11.8477 32.0554 11.8477C31.1479 11.8477 30.4121 12.5812 30.4121 13.486C30.4121 14.3908 31.1479 15.1243 32.0554 15.1243Z"
				fill="#FBCA1B"
			/>
			<path
				d="M28.0932 16.1328H17.415C17.1653 16.1328 16.9629 16.3346 16.9629 16.5835V16.5858C16.9629 16.8347 17.1653 17.0365 17.415 17.0365H28.0932C28.3429 17.0365 28.5453 16.8347 28.5453 16.5858V16.5835C28.5453 16.3346 28.3429 16.1328 28.0932 16.1328Z"
				fill="#4BAAF6"
			/>
			<path
				d="M28.0932 18.5352H17.415C17.1653 18.5352 16.9629 18.7369 16.9629 18.9859V18.9881C16.9629 19.237 17.1653 19.4388 17.415 19.4388H28.0932C28.3429 19.4388 28.5453 19.237 28.5453 18.9881V18.9859C28.5453 18.7369 28.3429 18.5352 28.0932 18.5352Z"
				fill="#4BAAF6"
			/>
			<path
				d="M69.1313 59.9705C69.228 60.0911 69.2861 60.2378 69.2982 60.3917C69.3103 60.5456 69.2758 60.6996 69.1991 60.8337C69.1186 60.9616 68.9996 61.0608 68.8591 61.1171C68.7186 61.1733 68.5638 61.1838 68.417 61.1469C68.3653 61.134 68.3174 61.1093 68.2768 61.0748C68.1932 60.9937 68.1796 60.8494 68.1005 60.7683C68.0182 60.7009 67.9189 60.6572 67.8135 60.6421C67.6266 60.5739 67.4507 60.479 67.2913 60.3604C67.2655 60.3458 67.2431 60.3258 67.2258 60.3018C67.2097 60.272 67.2011 60.2388 67.2009 60.2049C67.1623 60.0318 67.1888 59.8505 67.2755 59.6956C67.323 59.6376 67.3813 59.5894 67.4473 59.5536C67.5718 59.4767 67.7145 59.434 67.8609 59.4297C67.9751 59.4375 68.0872 59.4633 68.1932 59.5063C68.4937 59.5924 68.7757 59.733 69.025 59.921"
				fill="#4BAAF6"
			/>
			<path
				d="M68.5458 58.5898L69.0499 60.3206C69.0499 60.3206 68.9549 60.7555 68.4215 60.573C67.888 60.3904 67.4404 59.027 67.4404 59.027L68.5458 58.5898Z"
				fill="#FCBDBA"
			/>
			<path
				d="M63.2243 62.2061C63.087 62.1828 62.9477 62.173 62.8084 62.1768C62.7386 62.1798 62.6701 62.1969 62.6072 62.2272C62.5442 62.2574 62.4881 62.3 62.4422 62.3526C62.376 62.4588 62.3497 62.585 62.3679 62.7087C62.3861 62.8325 62.4477 62.9458 62.5417 63.0286C62.7313 63.1848 62.9636 63.2806 63.2085 63.3035C63.3573 63.3374 63.5118 63.3374 63.6606 63.3035C63.8482 63.2495 64.0268 63.094 64.2144 63.1526C64.2963 63.1866 64.3725 63.233 64.4404 63.29C64.5685 63.3825 64.7271 63.423 64.8841 63.4034C65.041 63.3837 65.1846 63.3053 65.2858 63.1841C65.3402 63.1032 65.3792 63.0131 65.4011 62.9182C65.4802 62.6185 65.444 62.2421 65.1954 62.0686C64.9015 61.859 64.6732 62.0033 64.3907 62.1002C64.0138 62.2173 63.6162 62.2534 63.2243 62.2061Z"
				fill="#4BAAF6"
			/>
			<path
				d="M63.4688 60.53L64.2056 62.3329C64.2056 62.3329 64.6284 62.5875 64.929 62.4749L65.2251 62.3532L64.9652 59.8359L63.4688 60.53Z"
				fill="#FCBDBA"
			/>
			<path
				d="M57.9916 42.2039C57.9419 42.1386 57.8764 42.0664 57.7972 42.0777C57.7181 42.089 57.7046 42.1205 57.6571 42.107C57.6275 42.0966 57.6021 42.0768 57.5848 42.0507C57.4723 41.9317 57.3472 41.8251 57.2118 41.7329C57.1292 41.6606 57.0297 41.6102 56.9225 41.5864C56.8442 41.5825 56.7666 41.6018 56.6994 41.642C56.6322 41.6821 56.5784 41.7412 56.545 41.8118C56.4776 41.9521 56.4608 42.1113 56.4975 42.2625C56.5374 42.4137 56.5982 42.5586 56.6783 42.6929C56.7269 42.7936 56.7884 42.8876 56.8614 42.9724C57.0875 43.218 57.4627 43.2586 57.7656 43.4096C57.7979 43.4292 57.8342 43.4415 57.8718 43.4456C57.904 43.4403 57.9345 43.4276 57.9608 43.4084C57.9871 43.3892 58.0085 43.3641 58.0233 43.3352C58.1404 43.1687 58.2113 42.9744 58.229 42.7718C58.2127 42.5621 58.1296 42.3631 57.9916 42.2039Z"
				fill="#FCBDBA"
			/>
			<path
				d="M60.9308 40.2476C60.8945 40.2376 60.8557 40.2416 60.8223 40.2589C60.8035 40.2739 60.7867 40.2913 60.7725 40.3107C60.7093 40.3783 60.6053 40.3806 60.5194 40.4189C60.4642 40.4466 60.4156 40.4856 60.3767 40.5334C60.3377 40.5812 60.3093 40.6366 60.2933 40.6961C60.2633 40.8146 60.2504 40.9367 60.2549 41.0589C60.2491 41.1234 60.256 41.1885 60.2753 41.2504C60.2854 41.2806 60.304 41.3072 60.3288 41.3272C60.3537 41.3471 60.3837 41.3596 60.4154 41.3631C60.4669 41.3538 60.5156 41.333 60.5578 41.3023C60.6439 41.2551 60.7438 41.2392 60.8404 41.2572C60.9643 41.2971 61.0828 41.3524 61.193 41.4217C61.2491 41.4548 61.3131 41.4723 61.3783 41.4723C61.4436 41.4723 61.5076 41.4548 61.5637 41.4217C61.6138 41.3768 61.6531 41.3214 61.679 41.2594C61.7807 41.0341 61.905 40.7839 61.679 40.6014C61.4668 40.4174 61.208 40.295 60.9308 40.2476Z"
				fill="#FCBDBA"
			/>
			<path
				d="M67.6481 48.5305C67.5952 49.4784 67.4888 50.4227 67.3294 51.3587C67.126 52.2872 67.0039 53.3418 67.0039 53.3418L68.8869 58.7504C68.8869 58.7504 67.7408 59.5098 67.0039 59.2146C66.267 58.9194 64.4022 54.7728 64.4022 54.7728L63.6924 50.0561L65.5708 47.9062L67.6481 48.5305Z"
				fill="#473F47"
			/>
			<path
				d="M62.4521 48.1523C62.4521 48.1523 61.1094 52.54 61.1094 53.6803C61.1094 54.8206 62.7188 60.441 62.7188 60.441C62.7188 60.441 64.4661 61.0314 64.8752 60.441L65.2844 59.8506L64.3011 54.1941C64.3011 54.1941 65.6935 51.242 65.9399 50.4825C66.089 50.0145 66.1956 49.534 66.2586 49.047L62.4521 48.1523Z"
				fill="#473F47"
			/>
			<path
				d="M63.0171 36.1377C62.8601 36.4505 62.7599 36.7885 62.721 37.1361C62.7033 37.308 62.7309 37.4816 62.8011 37.6397C62.8712 37.7978 62.9816 37.9349 63.1211 38.0375C63.2066 38.0781 63.2863 38.1296 63.3585 38.1907C63.6116 38.4476 63.3223 38.9141 63.467 39.2476C63.5253 39.3548 63.621 39.437 63.736 39.4785C63.8509 39.52 63.9772 39.5181 64.0909 39.473C64.2021 39.4207 64.3004 39.3445 64.3786 39.2498C64.4567 39.1551 64.5129 39.0443 64.5429 38.9254C64.6079 38.6838 64.6368 38.434 64.6288 38.184L64.6537 36.6944C64.6892 36.363 64.637 36.0281 64.5022 35.7231C64.4124 35.5656 64.28 35.4365 64.1201 35.3505C63.9603 35.2644 63.7794 35.2249 63.5981 35.2363C63.25 35.3107 63.1437 35.847 63.0171 36.1377Z"
				fill="#FCBDBA"
			/>
			<path
				d="M57.8045 43.5743C57.8045 43.5743 60.6707 46.1276 60.8742 46.1276C61.0776 46.1276 62.3502 44.523 62.3502 44.523L62.452 48.1535C62.8059 48.3618 63.1745 48.5441 63.555 48.6989C64.1744 48.9513 64.9588 49.1857 65.6437 49.1226C67.0361 48.9964 67.6487 48.5321 67.6487 48.5321L66.792 39.9821C66.792 39.9821 65.169 38.817 64.6152 38.9951C64.2182 39.0657 63.8119 39.0657 63.4149 38.9951C62.6147 38.8914 61.7964 39.9821 61.7964 39.9821L60.7318 43.4684L58.234 42.585C58.0709 42.6876 57.9426 42.837 57.866 43.0135C57.7893 43.19 57.7679 43.3855 57.8045 43.5743Z"
				fill="#51E08A"
			/>
			<path
				d="M63.2587 34.5214C63.5593 34.2488 64.2397 34.1361 64.572 34.1C64.8274 34.0638 65.0876 34.0824 65.3352 34.1547C65.5828 34.227 65.812 34.3513 66.0074 34.5192C66.5499 35.033 66.6064 35.8713 66.5996 36.642C66.5928 37.4128 66.5815 38.2398 67.0336 38.8257C67.4224 39.326 68.0576 39.5334 68.5526 39.9187C68.7971 40.0994 69.0001 40.3299 69.1482 40.5949C69.2963 40.8599 69.3861 41.1533 69.4116 41.4556C69.5269 43.7723 65.978 42.7785 64.8048 42.2624C64.5972 42.1826 64.4064 42.0649 64.242 41.9154C64.0685 41.7284 63.9408 41.504 63.869 41.2596C63.5936 40.4316 63.6404 39.5307 64.0001 38.7356C64.0544 38.6184 64.1131 38.4719 64.0386 38.3683C63.9978 38.3224 63.9467 38.2868 63.8894 38.2646C63.7394 38.2025 63.6104 38.0988 63.5178 37.9658C63.4253 37.8327 63.373 37.676 63.3672 37.5142C63.4158 37.2768 63.523 37.0553 63.6791 36.8696C63.7558 36.7767 63.8085 36.6667 63.833 36.5489C63.8574 36.4311 63.8527 36.3092 63.8193 36.1936C63.7379 36.011 63.5435 35.9209 63.3989 35.7879C63.1457 35.5626 62.9626 34.9564 63.1728 34.6319C63.1968 34.5917 63.2257 34.5546 63.2587 34.5214Z"
				fill="#F6A569"
			/>
		</g>
		<defs>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint0_linear_2852_46271"
				x1="31.0475"
				x2="68.918"
				y1="81.558"
				y2="-70.6204"
			>
				<stop offset="0.01" />
				<stop offset="0.08" stopOpacity="0.69" />
				<stop offset="0.21" stopOpacity="0.32" />
				<stop offset="1" stopOpacity="0" />
			</linearGradient>
			<clipPath id="clip0_2852_46271">
				<rect fill="white" height="63.4106" width="84.3896" />
			</clipPath>
		</defs>
	</svg>
);

export default SearchIcon;
