import React, { useState, useRef, useEffect, memo } from 'react';
import { VariableSizeList } from 'react-window';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import Crown from '@worklist-2/ui/src/assets/icons/breeze/Crown.svg';
import { TabPanel } from '../../../Cases/casePopOverStyles';
import CurrentOrganizationContextProvider, { useCurrentOrganizationContext } from './CurrentOrganizationContext';

const CustomAccordion = styled(Accordion)(() => ({
	boxShadow: 'none',
	overflow: 'hidden',
	'&::before': {
		height: 0,
	},
	'&.Mui-expanded': {
		margin: 0,
	},
	'.MuiAccordionDetails-root': {
		padding: 0,
		margin: '8px 0 0 0 ',
	},
	'.MuiAccordionSummary-content': {
		padding: 0,
		margin: 0,
	},
	'.MuiAccordionSummary-root': {
		padding: 0,
		margin: 0,
		'.Mui-expanded': {
			margin: 0,
		},
	},
}));

const OrganizationCard = memo(
	({ data, setOrganizationID, setDbClickOrganization, listMaxHeight, showHeader = true }) => {
		const [childOrgs, setChildOrgs] = useState([]);
		const [isOpen, setIsOpen] = useState(false);
		const listRef = useRef({});
		const rowHeights = useRef({});

		function filterByType(org) {
			if (!org.childOrganizations) {
				return org?.type?.[0]?.coding?.[0]?.code === 'PROV';
			}

			org.childOrganizations = org.childOrganizations.filter(filterByType);

			if (org.childOrganizations.length === 0) {
				delete org.childOrganizations;
			}

			return org?.type?.[0]?.coding?.[0]?.code === 'PROV';
		}

		useEffect(() => {
			if (data.childOrganizations) {
				setChildOrgs(data.childOrganizations.filter(filterByType));
			}
		}, [data]);

		const handleAccordionOpen = () => {
			setIsOpen(prev => !prev);
		};

		function getRowHeight(index) {
			return rowHeights.current[index] && rowHeights.current[index] >= 75 ? rowHeights.current[index] : 75;
		}

		function setRowHeight(index, size) {
			rowHeights.current = { ...rowHeights.current, [index]: size };
			listRef.current.resetAfterIndex(0);
		}

		const ListItem = ({ data, index, style }) => {
			const item = data[index];
			const rowRef = useRef({});

			const expandRowHandler = isHoverEvent => {
				if (isHoverEvent) {
					setRowHeight(index, rowRef.current.clientHeight);
				} else {
					setTimeout(() => {
						setRowHeight(index, rowRef.current.clientHeight);
					}, 300);
				}
			};

			useEffect(() => {
				if (rowRef.current) {
					setRowHeight(index, rowRef.current.clientHeight);
				}
			}, [rowRef]);

			return (
				<div style={style}>
					<Box ref={rowRef} sx={{ paddingBottom: '8px' }}>
						<ImagingOrganization
							key={index}
							data={item}
							expandRowHandler={expandRowHandler}
							setDbClickOrganization={setDbClickOrganization}
							setOrganizationData={10}
							setOrganizationID={setOrganizationID}
						/>
					</Box>
				</div>
			);
		};

		return (
			<CurrentOrganizationContextProvider selectOrganization={setOrganizationID}>
				<TabPanel expanded={!showHeader}>
					{showHeader && (
						<div className="header">
							<div className="left">
								<div className="colored-icon" style={{ backgroundColor: 'rgba(207, 106, 167, 0.2)' }}>
									<AccountTreeOutlinedIcon sx={{ fontSize: 20, color: '#F990D4' }} />
								</div>
								<h2>Organization Tree</h2>
							</div>
						</div>
					)}

					<CustomAccordion>
						<AccordionSummary>
							<OrganizationItem
								managingOrganization
								data={data}
								handleAccordionOpen={handleAccordionOpen}
								isOpen={isOpen}
								setDbClickOrganization={setDbClickOrganization}
								setOrganizationID={setOrganizationID}
							/>
						</AccordionSummary>
						<AccordionDetails>
							{childOrgs.length !== 0 ? (
								<Box sx={{ display: 'flex' }}>
									<Box
										sx={{
											width: '2px',
											height: 'auto',
											marginRight: '6px',
											backgroundColor: '#EEF1FF',
											borderRadius: '10px',
										}}
									/>
									<OrganizationListWrapper>
										<VariableSizeList
											ref={listRef}
											height={listMaxHeight || window.innerHeight - 500}
											itemCount={childOrgs.length}
											itemData={childOrgs}
											itemSize={getRowHeight}
										>
											{ListItem}
										</VariableSizeList>
									</OrganizationListWrapper>
								</Box>
							) : null}
						</AccordionDetails>
					</CustomAccordion>
				</TabPanel>
			</CurrentOrganizationContextProvider>
		);
	}
);

const OrganizationListWrapper = ({ children }) => {
	const { setHoveredOrganizationID } = useCurrentOrganizationContext();

	return (
		<Box sx={{ width: '100%' }} onMouseLeave={() => setHoveredOrganizationID('')}>
			{children}
		</Box>
	);
};

const OrganizationAccordion = ({
	data,
	managingOrganization,
	setOrganizationID,
	setDbClickOrganization,
	expandRowHandler,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleAccordionOpen = () => {
		setIsOpen(prev => !prev);
		expandRowHandler();
	};

	return (
		<CustomAccordion>
			<AccordionSummary>
				<OrganizationItem
					data={data}
					expandRowHandler={expandRowHandler}
					handleAccordionOpen={handleAccordionOpen}
					isOpen={isOpen}
					managingOrganization={managingOrganization}
					setDbClickOrganization={setDbClickOrganization}
					setOrganizationID={setOrganizationID}
				/>
			</AccordionSummary>
			<AccordionDetails>
				<Box sx={{ display: 'flex' }}>
					<Box
						sx={{
							width: '2px',
							height: 'auto',
							marginRight: '6px',
							backgroundColor: '#EEF1FF',
							borderRadius: '10px',
						}}
					/>
					<Box sx={{ width: '100%' }}>
						{data.childOrganizations?.map((organization, i) => (
							<ImagingOrganization
								key={i}
								data={organization}
								expandRowHandler={expandRowHandler}
								setDbClickOrganization={setDbClickOrganization}
								setOrganizationID={setOrganizationID}
							/>
						))}
					</Box>
				</Box>
			</AccordionDetails>
		</CustomAccordion>
	);
};

const ImagingOrganization = ({ data, setOrganizationID, setDbClickOrganization, expandRowHandler }) =>
	data.childOrganizations ? (
		<OrganizationAccordion
			data={data}
			expandRowHandler={expandRowHandler}
			setDbClickOrganization={setDbClickOrganization}
			setOrganizationID={setOrganizationID}
		/>
	) : (
		<OrganizationItem
			data={data}
			expandRowHandler={expandRowHandler}
			setDbClickOrganization={setDbClickOrganization}
			setOrganizationID={setOrganizationID}
		/>
	);

const OrganizationItem = ({
	data,
	managingOrganization,
	isOpen,
	handleAccordionOpen,
	setDbClickOrganization,
	expandRowHandler,
}) => {
	const { currentOrganizationID, setOrganizationID, hoveredOrganizationID, setHoveredOrganizationID } =
		useCurrentOrganizationContext();
	const isHover = hoveredOrganizationID === data.id;

	useEffect(() => {
		if (!managingOrganization) {
			expandRowHandler(true);
		}
	}, [isHover]);

	const handleMouseLeave = () => {
		if (managingOrganization) {
			setHoveredOrganizationID('');
		}
	};

	const handleOrganizationClick = () => {
		if (handleAccordionOpen) {
			handleAccordionOpen();
		}
		setOrganizationID(data.id);
	};

	const handleOrganizationDbClick = () => {
		setDbClickOrganization(data);
	};

	const addOrganization = event => {
		event.stopPropagation();
		const newChildOrg = {
			partOf: {
				id: data.id,
				display: data.name,
				reference: `/Organization/${data.id}`,
			},
		};

		setDbClickOrganization(newChildOrg);
	};

	return (
		<Box
			sx={{
				marginBottom: 0,
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
			}}
			onMouseLeave={handleMouseLeave}
		>
			<Box
				sx={{
					boxSizing: 'border-box',
					position: 'relative',
					width: managingOrganization || data.childOrganizations ? '100%' : 'unset',
					padding: managingOrganization ? '20px 26px' : '12px 16px',
					display: 'flex',
					alignItems: 'center',
					backgroundColor: '#F9FAFF',
					borderRadius: '10px',
					border: currentOrganizationID === data.id ? '1px solid #4D79EA' : 'unset',
					cursor: 'pointer',
					'&:hover': {
						backgroundColor: '#EBEEFE',
					},
				}}
				onClick={handleOrganizationClick}
				onDoubleClick={handleOrganizationDbClick}
				onMouseEnter={() => setHoveredOrganizationID(data.id)}
			>
				<IconButton
					sx={{ position: 'absolute', top: managingOrganization ? '8px' : '4px', right: '8px', padding: 0 }}
					onClick={e => {
						e.stopPropagation();
						handleOrganizationDbClick();
					}}
				>
					<ArrowForwardIcon sx={{ fontSize: '16px', color: '#4D79EA' }} />
				</IconButton>
				<Box
					sx={{
						position: 'relative',
						padding: managingOrganization ? '24px 28px' : '8px 10px',
						backgroundColor: 'rgba(77, 121, 234, 0.2)',
						borderRadius: '5px',
					}}
				>
					{managingOrganization && (
						<>
							<Box
								sx={{
									position: 'absolute',
									top: '-11px',
									left: '-11px',
									padding: '4px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									backgroundColor: '#F9FAFF',
									borderRadius: '3px',
								}}
							>
								<Crown />
							</Box>
							<Box
								sx={{
									position: 'absolute',
									top: '-2px',
									right: '-4px',
									padding: '2px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									backgroundColor: '#F9FAFF',
									borderRadius: '3px',
								}}
							>
								<DoneIcon sx={{ fontSize: 12, color: '#4D79EA' }} />
							</Box>
						</>
					)}
					<BusinessOutlinedIcon sx={{ fontSize: 20, color: '#4D79EA' }} />
				</Box>
				<Box sx={{ marginLeft: managingOrganization ? '18px' : '10px' }}>
					<Box
						sx={{
							marginBottom: managingOrganization ? '6px' : '3px',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Typography
							sx={{
								marginRight: managingOrganization ? '14px' : '10px',
								fontSize: managingOrganization ? '20px' : '16px',
								fontWeight: '500',
								lineHeight: managingOrganization ? '24px' : '18px',
								color: 'rgba(0, 0, 0, 0.9)',
							}}
						>
							{data.name}
						</Typography>
						<DeviceHubOutlinedIcon sx={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.5)' }} />
						<Typography
							sx={{
								marginLeft: '2px',
								fontSize: '12px',
								fontWeight: '400',
								lineHeight: '14px',
								color: 'rgba(0, 0, 0, 0.5)',
							}}
						>
							2
						</Typography>
						{managingOrganization && (
							<Typography
								sx={{
									marginLeft: '10px',
									fontSize: '10px',
									fontWeight: '400',
									lineHeight: '12px',
									color: '#FF6A6A',
									backgroundColor: 'rgba(255, 106, 106, 0.1)',
								}}
							>
								All connections down
							</Typography>
						)}
					</Box>
					<Typography
						sx={{
							fontSize: '16px',
							fontWeight: '400',
							lineHeight: '20px',
							color: 'rgba(0, 0, 0, 0.4)',
						}}
					>
						{managingOrganization ? 'Managing Organization' : 'Imaging Organization'}
					</Typography>
				</Box>
				{(managingOrganization || data.childOrganizations) && (
					<Box
						sx={{
							position: 'absolute',
							right: '8px',
							bottom: '12px',
							padding: '2px 4px',
							display: 'flex',
							alignItems: 'center',
							backgroundColor: 'rgba(77, 121, 234, 0.2)',
							borderRadius: '5px',
						}}
					>
						{managingOrganization ? (
							<AccountTreeOutlinedIcon sx={{ fontSize: '16px', color: '#4D79EA' }} />
						) : (
							<Typography sx={{ paddingLeft: '4px' }}>{data.childOrganizations.length}</Typography>
						)}
						<ExpandMoreOutlinedIcon
							sx={{
								fontSize: '18px',
								color: 'rgba(0, 0, 0, 0.6)',
								transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
								transition: 'all 0.3s ease',
							}}
						/>
					</Box>
				)}
			</Box>
			{isHover && (
				<Box
					sx={{
						cursor: 'pointer',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
					onClick={event => addOrganization(event)}
				>
					<Box sx={{ height: '14px', borderLeft: '0.5px dashed rgba(77, 121, 234, 0.6)' }} />
					<IconButton
						sx={{
							padding: '4px',
							backgroundColor: '#4D79EA',
							outline: '2px solid rgba(77, 121, 234, 0.2)',
							'&:hover': {
								backgroundColor: 'rgba(77, 121, 234, 0.6)',
							},
						}}
					>
						<AddIcon sx={{ fontSize: '16px', color: '#FFFFFF' }} />
					</IconButton>
					<Typography
						sx={{
							position: 'relative',
							marginTop: '18px',
							padding: '6px 10px',
							fontSize: '14px',
							color: '#FFFFFF',
							backgroundColor: '#4D79EA',
							borderRadius: '5px',
							'&::before': {
								content: '""',
								position: 'absolute',
								top: '-6px',
								left: '50%',
								display: 'block',
								width: '14px',
								height: '14px',
								backgroundColor: '#4D79EA',
								transform: 'translate(-50%, 0) rotate(45deg)',
								borderRadius: '1px',
							},
						}}
					>
						Create child organization
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default OrganizationCard;
