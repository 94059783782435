import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';

const CheckboxComponent = ({ model, formField, disabled, onChange, className }) => {
	const getDefaultValue = () => formField.mapping.split('.').reduce((p, c) => (p && p[c]) || null, model);

	return (
		<FormControl>
			<FormControlLabel
				control={
					<Checkbox
						key={`${formField.mapping}-field`}
						checked={getDefaultValue()}
						className={className}
						color="rsPrimary"
						data-cy="form-field"
						data-testid="form-field"
						disabled={disabled || formField.disabled}
						id={formField.id ? formField.id : `form-field-${formField.name}`}
						onChange={(event, value) =>
							onChange(
								{ target: { value } },
								value,
								formField.mapping ? formField.mapping : formField.name
							)
						}
					/>
				}
				label={formField.name}
			/>
		</FormControl>
	);
};

const CheckboxComponentMemo = React.memo(CheckboxComponent);

export default CheckboxComponentMemo;
