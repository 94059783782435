import React from 'react';

const BoldIcon = () => (
	<svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_5211_59766)">
			<path
				d="M10.4001 7.19317C11.0467 6.7465 11.5001 6.01317 11.5001 5.33317C11.5001 3.8265 10.3334 2.6665 8.83341 2.6665H4.66675V11.9998H9.36008C10.7534 11.9998 11.8334 10.8665 11.8334 9.47317C11.8334 8.45984 11.2601 7.59317 10.4001 7.19317ZM6.66675 4.33317H8.66675C9.22008 4.33317 9.66675 4.77984 9.66675 5.33317C9.66675 5.8865 9.22008 6.33317 8.66675 6.33317H6.66675V4.33317ZM9.00008 10.3332H6.66675V8.33317H9.00008C9.55341 8.33317 10.0001 8.77984 10.0001 9.33317C10.0001 9.8865 9.55341 10.3332 9.00008 10.3332Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_5211_59766">
				<rect fill="white" height="16" width="16" />
			</clipPath>
		</defs>
	</svg>
);

export default BoldIcon;
