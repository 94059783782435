// React Libraries
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

// Custom Component Libraries
import FavouriteReports from './FavouriteReports';
import AllReports from './AllReports';
import ReportsSelectView from './components/ReportsSelectView';
import { CustomSwitch, Header, Knob, Title } from '../styles';

// Material UI Libraries
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Stack from '@mui/material/Stack';

// Custom Images
import ThumbnailImage from '@worklist-2/ui/src/assets/img/report-demo-thumb.png';
import Toast from '@worklist-2/ui/src/components/Toast';
import BreezeLogo from '../../assets/Breeze_Logo.svg';
// Worklist Core Libraries
import { useAuth, useCRMDataLoader, crmSearchScopes } from '@worklist-2/core/src';
import { generateAvatarName, getExtensionValueString } from '@rs-core/fhir/resource/columnMapping/utils';
import ShareSideContent from '../BreezeReportDetailsView/components/ShareSideContent';

const ReportsView = () => {
	const [checkedReports, setCheckedReports] = useState([]);
	const { loggedInUser, authorized } = useAuth();

	const reportDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.reportApi,
	});

	const navigate = useNavigate();

	const [allReportData, setAllReportData] = useState([]);
	const [favoriteReportData, setFavoriteReportData] = useState([]);
	const [toastMsg, setToastMsg] = useState(false);
	const [isShare, setIsShare] = useState(false);
	const [shareUserData, setShareUserData] = useState([]);
	const [selectedShareReport, setSelectedShareReport] = useState(null);
	const [deleteModal, setDeleteModal] = useState(false);
	const [reportToDelete, setReportToDelete] = useState(null);

	const fetchReportData = useCallback(
		async extraValue => {
			if (authorized && reportDataLoader && loggedInUser) {
				return reportDataLoader
					.load({
						count: 75,
					})
					.then(result => {
						if (!_.isEmpty(result)) {
							return _.map(result, item => ({
								id: item.id,
								name: item.name,
								forRead: item.forRead,
								image: item.thumbnail
									? `data:${item.thumbnail.ContentType};base64,${item.thumbnail.data}`
									: ThumbnailImage,
								favorite: item.forRead ? isFavoriteReport(item.forRead) : false,
								script: convertScriptJson(item.script),
								layout: item.layout,
								thumbnail: item.thumbnail,
								owner: item.owner,
							}));
						}
					});
			}
		},
		[reportDataLoader, authorized, loggedInUser]
	);

	const deleteReport = async id => {
		if (authorized && reportDataLoader) {
			return reportDataLoader.delete({ id });
		}
	};

	const saveReport = async (props, payload, enforcePost) => {
		let result = {};

		if (authorized && reportDataLoader) {
			try {
				result = await reportDataLoader.save(props, payload, enforcePost).then(res => res.data);
			} catch (e) {
				console.error(e);
			}
		}

		return result;
	};

	const onSaveFavorite = useCallback(
		async (report, isFavorite) => {
			const userId = loggedInUser.id;
			const userIndex = _.findIndex(report.forRead, { id: userId });

			if (userIndex > -1) {
				const { extension } = report.forRead[userIndex];
				const favoriteIndex = _.findIndex(extension, { url: 'favorite' });

				extension[favoriteIndex].valueBoolean = isFavorite;
				report.resourceType = 'Report';

				// Update the Favorite report on List View.
				allReportData[_.findIndex(allReportData, { id: report.id })] = report;
				setAllReportData([]);
				setAllReportData(allReportData);
				setFavoriteReportData(_.filter(allReportData, elem => elem.favorite === true));

				report.script = convertJSONToText(report.script);

				// Shave the new favorite change.
				const res = await saveReport({ id: report?.id }, report, false);

				if (res && res.resourceType === 'Report') {
					setToastMsg(`Successfully save Favorite Report: ${report.name}`);
				}
			}
		},
		[reportDataLoader, authorized, loggedInUser, allReportData]
	);

	const onDeleteSingleReport = report => {
		if (report) {
			_.remove(allReportData, elem => elem.id === report.id);

			setAllReportData(allReportData);
			setFavoriteReportData(_.filter(allReportData, elem => elem.favorite === true));

			deleteReport(report.id).then(() => {
				setToastMsg(`Successfully delete Report: ${report.name}`);
			});
		}
	};

	const onDeleteMultiReport = async () => {
		if (checkedReports.length > 0) {
			await Promise.all(
				checkedReports.map(async id => {
					await deleteReport(id);
				})
			);
			setToastMsg('Successfully delete Reports');
			fetchAllReportData();
			setCheckedReports([]);
		}
	};

	const fetchDeletableReport = report => {
		setReportToDelete(report);
	};

	useEffect(() => {
		if (deleteModal === false) setReportToDelete(null);
	}, [deleteModal]);

	const convertScriptJson = script => {
		const returnScript = [];

		if (!_.isEmpty(script)) {
			_.forEach(script, item => {
				try {
					returnScript.push(JSON.parse(item));
				} catch (error) {}
			});
		}

		return returnScript;
	};

	const convertJSONToText = script => {
		const returnScript = [];

		if (!_.isEmpty(script)) {
			_.forEach(script, item => {
				try {
					returnScript.push(JSON.stringify(item));
				} catch (error) {}
			});
		}

		return returnScript;
	};

	const isFavoriteReport = forRead => {
		let favorite = false;
		const userId = loggedInUser?.id;
		const extension = forRead.find(elem => elem.id === userId)?.extension;

		if (extension) {
			favorite = extension.find(elem => elem.url === 'favorite')?.valueBoolean;
		}

		return favorite;
	};

	const fetchAllReportData = async () => {
		let result = [];

		result = await fetchReportData();

		if (result && result.length > 0) {
			setAllReportData(result);
			setFavoriteReportData(_.filter(result, elem => elem.favorite === true));
		}
	};

	const sortReport = sortValue => {
		switch (sortValue) {
			case 'a-z':
				setAllReportData([...allReportData].sort(customComparatorAsc));
				break;
			case 'z-a':
				setAllReportData([...allReportData].sort(customComparatorDesc));
				break;
			default:
				fetchAllReportData();
				break;
		}
	};

	const customComparatorAsc = (a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1);

	const customComparatorDesc = (a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? -1 : 1);

	const onShareReport = useCallback(
		async userinfo => {
			const { forRead } = selectedShareReport;
			const userIndex = _.findIndex(forRead, { id: userinfo.id });
			const privilegeValue = getExtensionValueString(userinfo, 'privilege');

			// If select remove option, remove this user
			// If the user had been shared, just update the privilege
			// If not, add new
			if (userIndex > -1) {
				if (privilegeValue === 'remove') {
					forRead.splice(userIndex, 1);
				} else {
					_.find(forRead[userIndex].extension, {
						url: 'privilege',
					}).valueString = privilegeValue;
				}
			} else {
				forRead.push(userinfo);
			}

			selectedShareReport.script = selectedShareReport.script.map(s => `${JSON.stringify(s)}`);
			selectedShareReport.resourceType = 'Report';
			selectedShareReport.forRead = forRead;

			// Update List View data
			updateUserData(forRead);
			allReportData[_.findIndex(allReportData, { id: selectedShareReport.id })] = selectedShareReport;
			setAllReportData([]);
			setAllReportData(allReportData);
			setFavoriteReportData(_.filter(allReportData, elem => elem.favorite === true));

			// Save the changes
			const res = await saveReport({ id: selectedShareReport.id }, selectedShareReport);

			if (res && res.resourceType === 'Report') {
				setToastMsg(`Successfully share Report: ${selectedShareReport.name}`);
			}
		},
		[reportDataLoader, authorized, loggedInUser, allReportData, selectedShareReport]
	);

	const onOpenShare = report => {
		updateUserData(report.forRead);
		setSelectedShareReport(report);
		setIsShare(true);
	};

	const updateUserData = inputs => {
		const data = _.map(inputs, elem => ({
			id: elem.id,
			display: elem.display,
			icon: generateAvatarName(elem.display.replace('^', ' ')),
			extension: elem.extension,
		}));
		setShareUserData(data);
	};

	useEffect(() => {
		if (authorized && loggedInUser) {
			fetchAllReportData();
		}
	}, [authorized, loggedInUser, reportDataLoader]);

	const handleToastClose = () => {
		setToastMsg(false);
	};

	return (
		<Box className="background" position="relative">
			<Header>
				<Title>
					<InsertChartOutlinedIcon sx={{ fontSize: 29, mr: '12px' }} />
					<span>Analytics</span>
				</Title>
				<CustomSwitch>
					<Knob onClick={() => navigate('/')}>
						<HomeOutlinedIcon />
					</Knob>
					<label>Analytics</label>
				</CustomSwitch>
			</Header>
			<Box
				sx={{
					marginTop: '32px',
					fontFamily: 'Roboto',
					position: 'absolute',
					top: '120px',
					left: '50px',
				}}
			>
				<Toast message={toastMsg} open={Boolean(toastMsg)} onClose={handleToastClose} />
				{favoriteReportData && favoriteReportData.length > 0 && (
					<FavouriteReports
						checkedReports={checkedReports}
						fetchDeletableReport={fetchDeletableReport}
						reports={favoriteReportData}
						setCheckedReports={setCheckedReports}
						setDeleteModal={setDeleteModal}
						onOpenShare={onOpenShare}
						onSaveFavorite={onSaveFavorite}
					/>
				)}

				<AllReports
					checkedReports={checkedReports}
					fetchDeletableReport={fetchDeletableReport}
					reports={allReportData}
					setCheckedReports={setCheckedReports}
					setDeleteModal={setDeleteModal}
					sortMethod={sortReport}
					onOpenShare={onOpenShare}
					onSaveFavorite={onSaveFavorite}
				/>

				{checkedReports.length > 0 && (
					<ReportsSelectView checkedReports={checkedReports} onDeleteReport={onDeleteMultiReport} />
				)}
			</Box>

			{isShare && (
				<ShareSideContent
					reportId={selectedShareReport.id}
					setIsShare={setIsShare}
					userData={shareUserData}
					onShareReport={onShareReport}
				/>
			)}

			{deleteModal && reportToDelete !== null && (
				<ClickAwayListener
					onClickAway={() => {
						setDeleteModal(false);
					}}
				>
					<Stack
						sx={{
							position: 'relative',
							width: '422px',
							height: '207px',
							left: '35%',
							top: '100%',
							background: '#FFF',
							boxShadow: '-5px -5px 30px rgba(33, 88, 119, 0.11), 5px 5px 30px rgba(33, 88, 119, 0.11)',
							borderRadius: '20px',
							zIndex: 10,
						}}
					>
						<Stack
							alignItems="center"
							direction="row"
							sx={{
								height: '47px',
								width: '100%',
								background: '#FFF',
								borderRadius: '20px 20px 0 0',
								boxShadow: '0px 4px 20px rgba(33, 88, 119, 0.1)',
							}}
						>
							<Stack alignItems="center" direction="row" ml="2rem" spacing={2}>
								<BreezeLogo />
								<Typography
									sx={{
										fontSize: '16px',
										fontWeight: 500,
										color: 'rgba(0, 0, 0, 0.9)',
									}}
								>
									Delete Report
								</Typography>
							</Stack>
						</Stack>
						<Box
							sx={{
								width: 'auto',
								color: 'rgba(0, 0, 0, 0.87)',
								ml: '4rem',
								mt: '1.5rem',
								mb: '1rem',
							}}
						>
							<Typography
								sx={{
									fontSize: '16px',
									fontWeight: 600,
									letterSpacing: '0.15px',
									mb: '1rem',
								}}
							>
								Are you sure you want to delete
							</Typography>
							<Typography
								sx={{
									fontSize: '16px',
									fontWeight: 400,
									letterSpacing: '0.15px',
								}}
							>
								{`"${reportToDelete.name}"?`}
							</Typography>
						</Box>
						<Stack direction="row" justifyContent="space-between" px="2rem" width="auto">
							<Stack
								alignItems="center"
								justifyContent="center"
								sx={{
									width: '93px',
									height: '37px',
									border: '1px solid #4D79EA',
									borderRadius: '6px',
									color: '#4D79EA',
									textTransform: 'Uppercase',
									letterSpacing: '1.5px',
									cursor: 'pointer',
								}}
								onClick={() => {
									setDeleteModal(false);
								}}
							>
								Cancel
							</Stack>
							<Stack
								alignItems="center"
								justifyContent="center"
								sx={{
									width: '93px',
									height: '37px',
									border: '1px solid rgba(255, 255, 255, 0.1)',
									background: '#EF5D5D',
									borderRadius: '6px',
									color: '#FFF',
									textTransform: 'Uppercase',
									letterSpacing: '1.5px',
									cursor: 'pointer',
								}}
								onClick={() => {
									setDeleteModal(false);
									onDeleteSingleReport(reportToDelete);
								}}
							>
								Delete
							</Stack>
						</Stack>
					</Stack>
				</ClickAwayListener>
			)}
		</Box>
	);
};

export default ReportsView;
