import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useLongPress = callback => {
	const [value, setValue] = useState(0);

	const [startLongPress, setStartLongPress] = useState(false);

	useEffect(() => {
		if (startLongPress) {
			setValue(100);
		} else {
			setValue(0);
		}

		let timerId;

		if (startLongPress) {
			timerId = setTimeout(callback, 1000);
		} else {
			clearTimeout(timerId);
		}

		return () => {
			clearTimeout(timerId);
		};
	}, [startLongPress]);

	const start = e => {
		e.stopPropagation();
		setStartLongPress(true);
	};

	const stop = e => {
		e.stopPropagation();
		setStartLongPress(false);
	};

	return {
		value,
		onMouseDown: start,
		onMouseUp: stop,
		onMouseLeave: stop,
		onTouchStart: start,
		onTouchEnd: stop,
	};
};

const SendInvitationProgress = ({ buttonTooltip, className, sx, onSend, ...props }) => {
	const { value, ...longPressProps } = useLongPress(onSend);
	const circularProgress = (
		<CircularProgress
			size={40}
			sx={{
				position: 'absolute',
				top: '0',
				left: '0',
				color: 'rsPrimary.main',
				'& .MuiCircularProgress-circle': {
					transition: 'stroke-dashoffset 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
				},
			}}
			thickness={4}
			value={value}
			variant="determinate"
		/>
	);

	const sendIcon = (
		<IconButton
			{...props}
			sx={{
				...sx,
				position: 'relative',
				'&, & *': {
					background: 'transparent !important',
					backgroundColor: 'transparent !important',
				},
				'&:hover': { color: '#42A5F5 !important' },
			}}
			{...longPressProps}
			onClick={e => e.stopPropagation()}
		>
			<ForwardToInboxOutlinedIcon
				data-testid="SendInvitation"
				id="SendInvitation"
				sx={{
					color: 'inherit',
					'&:hover': { color: 'inherit' },
				}}
			/>
			{circularProgress}
		</IconButton>
	);

	const tooltip = element => {
		const { t } = useTranslation('organization');
		return (
			<Tooltip disableFocusListener title={t(buttonTooltip)}>
				{element}
			</Tooltip>
		);
	};

	return <Box className={className}>{tooltip(sendIcon)}</Box>;
};

SendInvitationProgress.propTypes = {
	/**
	 * Tooltip override
	 */
	buttonTooltip: PropTypes.string,
	/**
	 * Custom className
	 */
	className: PropTypes.string,
	/**
	 * Additional sx to customize the component
	 */
	sx: PropTypes.object,
	/**
	 * Callback to be invoked when a delete is initiated
	 */
	onSend: PropTypes.func,
};

SendInvitationProgress.defaultProps = {};

export default SendInvitationProgress;
