import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Slide } from '@mui/material';
import { useConfig } from '@worklist-2/core/src';
import Header from './Header';
import RequestTab from './RequestTab';
import CommentsTab from './CommentsTab';
import AddNewReview from './AddNewReview';

const ReviewDrawer = ({ isOpen, onClose, isOmega, documentId, setUnreadCommentsCount, comments, setComments }) => {
	const __config = useConfig();
	const [isLoading, setIsLoading] = useState();
	const [reviews, setReviews] = useState([]);
	const [selectedTab, setSelectedTab] = useState('comments');

	const getData = async type => {
		try {
			setIsLoading(true);
			await axios
				.get(
					`${__config.data_sources.breeze}/${
						type === 'comments' ? 'DocumentComment' : 'DocumentReview'
					}?DocumentId=${documentId}`
				)
				.then(res => {
					if (res.status === 200) {
						if (type === 'comments') {
							setComments(res.data.entry.map(item => item.resource));
							setUnreadCommentsCount(
								res.data.entry.reduce((acc, item) => (item.resource.isSeen ? acc : acc + 1), 0)
							);
						} else {
							setReviews(res.data.entry.map(item => item.resource));
						}
					}
				});
		} catch (e) {
			console.error(e);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (documentId) {
			getData('comments');
		} else {
			setComments([]);
			setUnreadCommentsCount(0);
			setReviews([]);
		}
	}, [documentId]);

	useEffect(() => {
		if (documentId) {
			getData(selectedTab);
		} else {
			setComments([]);
			setUnreadCommentsCount(0);
			setReviews([]);
		}
	}, [selectedTab]);

	return (
		<Slide direction="left" in={isOpen} mountOnEnter unmountOnExit>
			<Box
				sx={{
					position: 'absolute',
					top: 0,
					right: 0,
					width: '390px',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					backgroundColor: isOmega ? '#272727' : '#FFF',
					boxShadow: '-4px 0px 20px 0px rgba(0, 0, 0, 0.20)',
					overflow: 'hidden',
				}}
			>
				<ReviewDrawerContent
					onClose={onClose}
					isOmega={isOmega}
					documentId={documentId}
					isLoading={isLoading}
					comments={comments}
					reviews={reviews}
					setReviews={setReviews}
					selectedTab={selectedTab}
					setSelectedTab={setSelectedTab}
				/>
			</Box>
		</Slide>
	);
};

const ReviewDrawerContent = ({
	onClose,
	isOmega,
	documentId,
	isLoading,
	comments,
	reviews,
	setReviews,
	selectedTab,
	setSelectedTab,
}) => {
	const [isAddNewReviewOpen, setIsAddNewReviewOpen] = useState(false);

	return (
		<>
			<Header onClose={onClose} isOmega={isOmega} />
			<Box sx={{ padding: '0 22px ', display: 'flex' }}>
				<TabButton
					title={'Comments'}
					setIsActive={() => setSelectedTab('comments')}
					isActive={selectedTab === 'comments'}
				/>
				<TabButton
					title={'Request'}
					setIsActive={() => setSelectedTab('request')}
					isActive={selectedTab === 'request'}
				/>
			</Box>
			{selectedTab === 'comments' ? (
				<CommentsTab isOmega={isOmega} isLoading={isLoading} comments={comments} />
			) : (
				<RequestTab
					isOmega={isOmega}
					onAddClick={() => setIsAddNewReviewOpen(true)}
					isLoading={isLoading}
					reviews={reviews}
				/>
			)}
			<AddNewReview
				isOpen={isAddNewReviewOpen}
				onClose={() => setIsAddNewReviewOpen(false)}
				onCloseSidebar={onClose}
				isOmega={isOmega}
				documentId={documentId}
				setReviews={setReviews}
			/>
		</>
	);
};

const TabButton = ({ title, isActive, setIsActive }) => {
	return (
		<Button
			style={{
				padding: '16px 14px 6px',
				fontFamily: 'Inter',
				lineHeight: '14px',
				textTransform: 'unset',
				color: isActive ? '#4D79EA' : '#C4C4C4',
				borderRadius: 'unset',
				borderBottom: isActive ? '2px solid #4D79EA' : '2px solid transparent',
			}}
			onClick={setIsActive}
		>
			{title}
		</Button>
	);
};

export default ReviewDrawer;
