// @flow

// libs
import { create } from "zustand";
// utils
import Patient from "./model/Patient";
import {
	type IDocument,
	type IImagingStudy,
	type IInsuranceCoverage,
	type IPatientAlert,
	type IPatientLink,
} from "@rs-ui/views/PatientInfoView/utils";

export type TPatientStore = {
	alerts: IPatientAlert[],
	setAlerts: (alerts: IPatientAlert[]) => void,

	documents: IDocument[],
	setDocuments: (documents: IDocument[]) => void,

	insuranceCoverage: IInsuranceCoverage[],
	setInsuranceCoverage: (insuranceCoverages: IInsuranceCoverage[]) => void,

	linkedPatients: IPatientLink[],
	setLinkedPatients: (linkedPatients: IPatientLink[]) => void,

	/** Whether the patient data is currently being fetched */
	isLoading: boolean; // #TODO: implemented with react-query
    setIsLoadingPatient: (isLoading: boolean) => void;

	patient: Patient | null,
	setPatient: (patient: Patient) => void,

	permissions: any | null,
	setPermissions: (permissions: any) => void,

	studies: IImagingStudy[],
	setStudies: (studies: IImagingStudy) => void,

	resetPatientStore: () => void,
};

export const usePatientStore: () => TPatientStore = create((set) => ({
	// Store variables w/ default values
	alerts: [],
	documents: [],
	insuranceCoverages: [],
    isLoading: false,
	linkedPatients: [],
	// order: null,		// #TODO
	patient: null,
	permissions: null,
	studies: [],


	// ==================== Setters ====================
	// #NOTE: use setters only with actual value, to reset store variables, use re-setters down below

	setAlerts: (alerts: IPatientAlert[]) => set({ alerts }),

	setDocuments: (documents: IDocument[]) => set({ documents }),

	setInsuranceCoverage: (insuranceCoverages: IInsuranceCoverage[]) => set({ insuranceCoverages }),

    setIsLoadingPatient: (isLoading: boolean) => set({ isLoading }),
    
    setLinkedPatients: (linkedPatients: IPatientLink[]) => set({ linkedPatients }),

	setPatient: (patientData: Patient) => set((prevState) => ({
		patient: {
			...prevState.patient,
			...patientData,
		},
	})),

	setPermissions: (permissions: any) => set({ permissions }),

	setStudies: (studies: IImagingStudy[]) => set({ studies }),


	//
	// ==================== Re-setters ====================
	resetPatientStore: () => set({
		// #NOTE: Be sure to include all the store's variables !
		alerts: [],
		documents: [],
		insuranceCoverage: [],
		patient: null,
		permissions: null,
		studies: [],
		linkedPatients: [],
	}),

	/** Resets the patient's `alerts` back to `[]` */
	resetAlerts: () => set({ alerts: [] }),

	/** Resets the patient's `documents` back to `[]` */
	resetDocuments: () => set({ documents: [] }),

	/** Resets the patient's `insuranceCoverage` back to `[]` */
	resetInsuranceCoverage: () => set({ insuranceCoverage: [] }),

	/** Resets the patient back to `null` */
	resetPatient: () => set({ patient: null }),

	/** Resets the patient's `studies` back to `[]` */
	resetStudies: () => set({ studies: [] }),

	/** Resets the patient's `links` back to `[]` */
	resetLinkedPatients: () => set({ linkedPatients: [] }),

	// ! DON'T USE !
	// ==================== Danger zone  ====================
	// __deleteStore: () => set({}, true), // clears the entire store, actions included
}));
