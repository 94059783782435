import React from 'react';
import _ from 'lodash';

import { Controller } from 'react-hook-form';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
	FormControl,
	MenuItem,
	Select,
	TableSortLabel,
	Checkbox,
	ListItemText,
	ClickAwayListener,
	TextField,
	InputAdornment,
	InputLabel,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { styled } from '@mui/material/styles';

import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import MultipleSelectChip from '../AdminSettings/MultipleSelectChip';

import { ACTIVE_STATUSES, PRIORITIES, STATUSES, TYPES } from './utils';

import { ArrowDownward, ArrowUpward, Search } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.white,
		borderWidth: 0,
		color: '#666666',
		fontWeight: '500',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		borderWidth: 0,
		color: theme.palette.common.black,
	},
}));

const useStyles = makeStyles()({
	root: {
		width: '120px',
		minHeight: '0 !important',
		height: '12px !important',
	},
});

const FilterableColumn = ({
	setField,
	fieldValue,
	order,
	field: fieldProp,
	fieldName,
	options,
	handleRequestSort,
	handleInputText,
	value,
	type,
	legendWidth,
	colSpan,
	showSearchIcon = true,
	textAlign = 'center',
	control,
}) => {
	const { classes } = useStyles();

	const DropdownIndicator = props =>
		order === 'asc' ? (
			<ArrowUpward
				sx={{
					color: 'rgba(77, 121, 234, 1)',
					height: '24px',
					width: '24px',
				}}
				onClick={e => handleRequestSort(e, fieldValue)}
			/>
		) : (
			<ArrowDownward
				sx={{
					color: 'rgba(77, 121, 234, 1)',
					height: '24px',
					width: '24px',
				}}
				onClick={e => handleRequestSort(e, fieldValue)}
			/>
		);

	const renderTextField = () => {
		if (type === 'text') {
			return control ? (
				<Controller
					control={control}
					name={fieldValue}
					render={({ field: { onChange, onBlur, value } }) => (
						<TextField
							autoFocus
							InputLabelProps={{
								style: {
									color: 'rgba(77, 121, 234, 1)',
								},
							}}
							InputProps={{
								style: {
									maxWidth: '150px',
									paddingLeft: showSearchIcon ? '14px' : '2px',
								},
								startAdornment: (
									<InputAdornment position="start">
										{showSearchIcon && <Search sx={{ color: 'rgba(77, 121, 234, 1)' }} />}
									</InputAdornment>
								),
							}}
							data-testid="filter-column"
							label={fieldName}
							placeholder="Search"
							sx={{
								'& .MuiOutlinedInput-root': {
									'& fieldset': {
										border: '1px solid rgba(77, 121, 234, 1)',
										borderRadius: '5px',
									},
									'&:hover fieldset': {
										border: '1px solid rgba(77, 121, 234, 1)',
										borderRadius: '5px',
									},
									'&.Mui-focused fieldset': {
										border: '1px solid rgba(77, 121, 234, 1)',
										borderRadius: '5px',
									},
								},
								input: {
									padding: '10px 14px',
									paddingLeft: 0,
									'&::placeholder': {
										color: 'rgba(77, 121, 234, 1)',
									},
								},
								'& legend': {
									width: legendWidth ? `${legendWidth}` : 'inherit',
								},
							}}
							value={value}
							variant="outlined"
							onBlur={onBlur}
							onChange={props => {
								onChange(props);
								handleInputText(props, fieldValue);
							}}
						/>
					)}
				/>
			) : (
				<TextField
					autoFocus
					InputLabelProps={{
						style: {
							color: 'rgba(77, 121, 234, 1)',
						},
					}}
					InputProps={{
						style: {
							maxWidth: '150px',
							paddingLeft: showSearchIcon ? '14px' : '2px',
						},
						startAdornment: (
							<InputAdornment position="start">
								{showSearchIcon && <Search sx={{ color: 'rgba(77, 121, 234, 1)' }} />}
							</InputAdornment>
						),
					}}
					data-testid="filter-column"
					label={fieldName}
					name={fieldValue}
					placeholder="Search"
					sx={{
						'& .MuiOutlinedInput-root': {
							'& fieldset': {
								border: '1px solid rgba(77, 121, 234, 1)',
								borderRadius: '5px',
							},
							'&:hover fieldset': {
								border: '1px solid rgba(77, 121, 234, 1)',
								borderRadius: '5px',
							},
							'&.Mui-focused fieldset': {
								border: '1px solid rgba(77, 121, 234, 1)',
								borderRadius: '5px',
							},
						},
						input: {
							padding: '10px 14px',
							paddingLeft: 0,
							'&::placeholder': {
								color: 'rgba(77, 121, 234, 1)',
							},
						},
						'& legend': {
							width: legendWidth ? `${legendWidth}` : 'inherit',
						},
					}}
					value={value}
					variant="outlined"
					onChange={props => handleInputText(props, fieldValue)}
				/>
			);
		}

		if (type === 'dropdown') {
			let array;
			if (fieldValue === 'Priority') {
				array = PRIORITIES;
			} else if (fieldValue === 'Status') {
				array = STATUSES;
			} else if (fieldValue === 'Type') {
				array = TYPES;
			} else if (fieldValue === 'IsActive') {
				array = ACTIVE_STATUSES;
			} else if (fieldValue === 'RoleName') {
				array = options;
			}

			return (
				<FormControl
					fullWidth
					sx={{
						'& .MuiOutlinedInput-root': {
							'& fieldset': {
								border: '1px solid rgba(77, 121, 234, 1)',
								borderRadius: '5px',
							},
							'&:hover fieldset': {
								border: '1px solid rgba(77, 121, 234, 1)',
								borderRadius: '5px',
							},
							'&.Mui-focused fieldset': {
								border: '1px solid rgba(77, 121, 234, 1)',
								borderRadius: '5px',
							},
						},
					}}
				>
					<InputLabel
						sx={{
							'&.MuiInputLabel-root.Mui-focused': {
								color: 'rgba(77, 121, 234, 1) !important',
							},
						}}
					>
						{fieldName}
					</InputLabel>
					<Select
						autoFocus
						multiple
						MenuProps={{ classes: { paper: classes.select } }}
						classes={{ select: classes.root }}
						data-testid="filter-column"
						label={fieldName}
						renderValue={selected => {
							let temp = [];
							if (selected.length > 0) {
								temp = _.map(selected, item => array.find(node => node.value == item)?.name);
							}

							return temp.join(',');
						}}
						value={typeof value === 'string' ? _.compact(value.split(',')) : value || []}
						onChange={props => handleInputText(props, fieldValue)}
					>
						{array.map(item => (
							<MenuItem key={item.value} value={item.value}>
								<Checkbox checked={value.indexOf(item.value) > -1} />
								<ListItemText primary={item.name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			);
		}

		if (type === 'dateTimePicker') {
			return (
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DateTimePicker
						label="Date&Time Picker"
						renderInput={fieldProp => (
							<TextField
								className="test-3"
								sx={{
									'& .MuiOutlinedInput-root': {
										'& fieldset': {
											border: '1px solid rgba(77, 121, 234, 1)',
											borderRadius: '5px',
										},
										'&:hover fieldset': {
											border: '1px solid rgba(77, 121, 234, 1)',
											borderRadius: '5px',
										},
										'&.Mui-focused fieldset': {
											border: '1px solid rgba(77, 121, 234, 1)',
											borderRadius: '5px',
										},
										input: {
											padding: '10px 14px',
											'&::placeholder': {
												color: 'rgba(77, 121, 234, 1)',
											},
										},
									},
								}}
								{...fieldProp}
							/>
						)}
						sx={{
							'&.MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root': {
								height: '45px',
							},
						}}
						value={value || new Date()}
						onChange={props => handleInputText(props, fieldValue)}
					/>
				</LocalizationProvider>
			);
		}

		if (type === 'multiselect') {
			return (
				<MultipleSelectChip
					data={options}
					data-testid="filter-column"
					fieldName={fieldName}
					id="multi-select-chip"
					startArdonment={<Search sx={{ color: 'rgba(77, 121, 234, 1)' }} />}
					onChange={props => handleInputText(props, fieldValue)}
				/>
			);
		}
	};

	const handleClickAway = () => {
		if (fieldProp === fieldValue && value === '') {
			setField(null);
		}
	};

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<StyledTableCell
				align="center"
				colSpan={colSpan}
				data-testid={`filter-column-${fieldName.replace(/\s/g, '')}`}
				sx={{
					padding: textAlign === 'right' ? '0 8px 0 0' : 0,
					cursor: 'pointer',
					width: '190px',
					textAlign,
				}}
				onClick={() => setField(fieldValue)}
			>
				{fieldProp === fieldValue ? (
					<TableSortLabel
						IconComponent={() => <DropdownIndicator />}
						active={fieldProp === fieldValue}
						data-testid="table-sort-label"
						direction={order}
					>
						{type ? renderTextField() : <span style={{ marginRight: '4px' }}>{fieldName}</span>}
					</TableSortLabel>
				) : (
					<span>{fieldName}</span>
				)}
			</StyledTableCell>
		</ClickAwayListener>
	);
};

export default FilterableColumn;
