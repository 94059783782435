// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qUBibprHYeEqpmisCU1g{display:flex;flex-direction:column;padding:20px 0;margin-right:10px;border-radius:10px;background:#393939}.tN7i3EEYZcU9Q3waJ3Ek{display:flex;flex-direction:column;margin-right:20px}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/common/Categories/CategoryBar/CategoryBar.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,cAAA,CACA,iBAAA,CAEA,kBAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoryOAI": "qUBibprHYeEqpmisCU1g",
	"categoryBarBlume": "tN7i3EEYZcU9Q3waJ3Ek"
};
export default ___CSS_LOADER_EXPORT___;
