// slices/currentStudyInfoSlice.js
const createCurrentStudyInfoSlice = set => ({
	currentStudyInfo: {},

	// ==================== Setters ====================
	setCurrentStudyInfo: studyInfo =>
		set(() => ({
			currentStudyInfo: studyInfo,
		})),

	// ==================== Re-setters ====================
	resetCurrentStudyInfo: () =>
		set(() => ({
			currentStudyInfo: {},
		})),
});

export default createCurrentStudyInfoSlice;
