import React, { useEffect, useState } from 'react';

const measureHeight = () => {
	if (!isClient()) return null;
	return window.innerHeight;
};

const useWasRenderedOnClientAtLeastOnce = () => {
	const [wasRenderedOnClientAtLeastOnce, setWasRenderedOnClientAtLeastOnce] = useState(false);

	useEffect(() => {
		if (isClient()) {
			setWasRenderedOnClientAtLeastOnce(true);
		}
	}, []);

	return wasRenderedOnClientAtLeastOnce;
};

const isClient = () => typeof window !== 'undefined' && typeof document !== 'undefined';

export { measureHeight, useWasRenderedOnClientAtLeastOnce };
