import React from 'react';

import styles from './ImageAttachment.module.scss';

const ImageAttachment = ({ src, setDisplayMode }) => (
	<div className={styles.imageBlock} onMouseOver={() => setDisplayMode({ type: 'hover' })}>
		<img src={src} />
	</div>
);

export default ImageAttachment;
