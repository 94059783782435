// React Libraries
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

// Material UI Libraries
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import VisualColumnBuilder from './VisualColumnBuilder';
import VisualMeasureBuilder from './VisualMeasureBuilder';
import Tooltip from '@mui/material/Tooltip';

// Custom Component Libraries
import DropListItem from './DropListItem';
import FieldSort from './FieldSort';

function FieldCharts({
	legend,
	yAxis,
	xAxis,
	chartImage,
	valueItem,
	multiItem,
	measureDisabled,
	dimensionDisabled,
	chartType,
	onRemoveMultiValue,
	multiMeasureItem,
	setMultiMeasureItem,
	measureOrder,
	setMeasureOrder,
	measuresToOrder,
	setMeasuresToOrder,
}) {
	const isYAxisShown = () =>
		chartType === 'BAR_CHART' ||
		chartType === 'LINE_CHART' ||
		chartType === 'STACK_CHART' ||
		chartType === 'PIE_CHART';

	const isXAxisShown = () =>
		chartType === 'BAR_CHART' ||
		chartType === 'LINE_CHART' ||
		chartType === 'PIE_CHART' ||
		chartType === 'STACK_CHART';

	const isValueShown = () => chartType === 'CROP_CHART';

	const isMultipleValue = () => chartType === 'TABLE_CHART';

	const isMultipleMeasure = () => chartType === 'BAR_CHART' || chartType === 'LINE_CHART';

	const isLegendShown = () => false; // chartType === 'BAR_CHART' || chartType === 'LINE_CHART'
	const theme = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1200,
				xl: 1536,
			},
		},
	});

	return (
		<Box
			sx={{
				margin: 'auto',
				position: 'relative',
				[theme.breakpoints.between('lg', 'xl')]: {
					marginTop: '-75px',
					transform: 'scale(0.8)',
				},
			}}
		>
			{isLegendShown() && (
				<Box sx={legendStyle}>
					<Typography
						fontSize={14}
						sx={{
							textTransform: 'uppercase',
						}}
					>
						Legend
					</Typography>
				</Box>
			)}
			{!isMultipleValue() && (
				<Box
					sx={
						chartType !== 'PIE_CHART'
							? {
									alignItems: 'center',
									justifyContent: 'start',
									display: 'flex',
							  }
							: {}
					}
				>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						{isYAxisShown() && !isMultipleMeasure() && (
							<Droppable droppableId="y_axis_drop" isDropDisabled={measureDisabled}>
								{provided => (
									<Box
										{...provided.droppableProps}
										ref={provided.innerRef}
										sx={chartType === 'PIE_CHART' ? legendStyle : yAxisStyle()}
									>
										{yAxis ? (
											<DropListItem isYAxis item={yAxis} />
										) : (
											<Typography
												fontSize={14}
												sx={{
													textTransform: 'uppercase',
												}}
											>
												Measures
											</Typography>
										)}
									</Box>
								)}
							</Droppable>
						)}
						{isMultipleMeasure() && (
							<VisualMeasureBuilder
								measureDisabled={measureDisabled}
								multiMeasureItem={multiMeasureItem}
								setMultiMeasureItem={setMultiMeasureItem}
							/>
						)}
						{multiMeasureItem?.length > 0 && (
							<FieldSort
								measureOrder={measureOrder}
								measuresToOrder={measuresToOrder}
								multiMeasureItem={multiMeasureItem}
								setMeasureOrder={setMeasureOrder}
								setMeasuresToOrder={setMeasuresToOrder}
							/>
						)}
					</Box>
					<Box
						className="chart_image"
						sx={
							chartType !== 'PIE_CHART'
								? {
										margin: 'auto',
										marginTop: '20px',
								  }
								: { marginLeft: '20px', marginTop: '20px' }
						}
					>
						<img alt="chart" src={chartImage} />
					</Box>
				</Box>
			)}

			{isXAxisShown() && (
				<Droppable droppableId="x_axis_drop" isDropDisabled={dimensionDisabled}>
					{provided => (
						<Box {...provided.droppableProps} ref={provided.innerRef} sx={xAxisStyle()}>
							{xAxis ? (
								<Tooltip title={xAxis.title?.replace(`${xAxis.fieldName} `, '')}>
									<Typography
										fontSize={14}
										sx={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											display: 'block',
											textAlign: 'center',
											color: '#4D79EA',
											fontSize: '1rem',
										}}
									>
										{xAxis.title?.replace(`${xAxis.fieldName} `, '')}
									</Typography>
								</Tooltip>
							) : (
								<Typography
									fontSize={14}
									sx={{
										textTransform: 'uppercase',
									}}
								>
									Dimensions
								</Typography>
							)}
						</Box>
					)}
				</Droppable>
			)}

			{isValueShown() && (
				<Droppable droppableId="value_drop">
					{provided => (
						<Box {...provided.droppableProps} ref={provided.innerRef} sx={valueStyle()}>
							{valueItem ? (
								<DropListItem isXAxis item={valueItem} />
							) : (
								<Typography
									fontSize={14}
									sx={{
										textTransform: 'uppercase',
									}}
								>
									Value
								</Typography>
							)}
						</Box>
					)}
				</Droppable>
			)}
			{isMultipleValue() && <VisualColumnBuilder fieldList={multiItem} removeField={onRemoveMultiValue} />}
		</Box>
	);
}

const xAxisStyle = () => ({
	color: '#4D79EA',
	border: '1px dashed #4D79EA',
	borderRadius: '5px',
	height: 40,
	width: 200,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	background: 'rgba(77, 121, 234, 0.05)',
	margin: 'auto',
	marginTop: 3,
});

const yAxisStyle = () => ({
	color: '#4D79EA',
	border: '1px dashed #4D79EA',
	borderRadius: '5px',
	height: 40,
	width: 200,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	background: 'rgba(77, 121, 234, 0.05)',
	marginRight: '-30px',
	transform: 'rotate(270deg)',
});

const legendStyle = {
	color: '#4D79EA',
	border: '1px dashed #4D79EA',
	borderRadius: '5px',
	height: 40,
	width: 260,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	background: 'rgba(77, 121, 234, 0.05)',
	margin: 'auto',
	marginTop: -3,
};

const valueStyle = () => ({
	color: '#4D79EA',
	border: '1px dashed #4D79EA',
	borderRadius: '5px',
	height: 40,
	width: 200,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	background: 'rgba(77, 121, 234, 0.05)',
	margin: 'auto',
	position: 'absolute',
	left: '50%',
	top: '55%',
	transform: 'translate(-50%, -50%)',
});

FieldCharts.propTypes = {
	/**
	 * legend is selected legend field item
	 */
	legend: PropTypes.object,
	/**
	 * yAxis is selected yAxis field item for chart
	 */
	yAxis: PropTypes.object,
	/**
	 * xAxis is selected xAxis field item for chart
	 */
	xAxis: PropTypes.object,
	/**
	 * chart image
	 */
	chartImage: PropTypes.any,
	/**
	 * chart type
	 */
	chartType: PropTypes.string,
};

export default FieldCharts;
