import React, { useState, useEffect } from 'react';
import { EVENTS, utilities, getEnabledElement } from '@cornerstonejs/core';
import getDefaultRenderingEngine from '@rs-ui/views/ImageViewerView3D/cornerstone/getDefaultRenderingEngine';

export const WindowLevelTag = ({ viewportId }) => {
	const [windowLevel, setWindowLevel] = useState(null);
	const renderingEngine = getDefaultRenderingEngine();
	const viewport = renderingEngine?.getViewport(viewportId);
	const element = viewport?.element;

	const calcWindowLevel = voiRange => {
		if (!voiRange) return;
		const { lower, upper } = voiRange;
		const { windowWidth, windowCenter } = utilities.windowLevel.toWindowLevel(lower, upper);

		if (viewport.modality == 'PT') {
			setWindowLevel(`L${Math.round(lower)} U${Math.round(upper)}`);
		} else {
			setWindowLevel(`W${Math.round(windowWidth)} C${Math.round(windowCenter)}`);
		}

		element?.removeEventListener(EVENTS.IMAGE_RENDERED, ON_CORNERSTONE_IMAGE_RENDERED);
	};

	const ON_CORNERSTONE_VOI_MODIFIED = e => {
		calcWindowLevel(e.detail.range);
	};

	const ON_CORNERSTONE_IMAGE_RENDERED = e => {
		const enabledViewport = getEnabledElement(e.detail.element);
		if (enabledViewport?.viewport) calcWindowLevel(enabledViewport.viewport.voiRange);
	};
	useEffect(() => {
		if (element) {
			const enabledViewport = getEnabledElement(element);
			if (enabledViewport?.viewport) {
				calcWindowLevel(enabledViewport.viewport.voiRange);
				element.addEventListener(EVENTS.IMAGE_RENDERED, ON_CORNERSTONE_IMAGE_RENDERED);
				element.addEventListener(EVENTS.VOI_MODIFIED, ON_CORNERSTONE_VOI_MODIFIED);
			}
		}

		return () => {
			element?.removeEventListener(EVENTS.IMAGE_RENDERED, ON_CORNERSTONE_IMAGE_RENDERED);
			element?.removeEventListener(EVENTS.VOI_MODIFIED, ON_CORNERSTONE_VOI_MODIFIED);
		};
	}, [element]);

	if (!renderingEngine || !viewport || !element) return null;

	return windowLevel ? <span>{windowLevel}</span> : null;
};
