import TextField from '@mui/material/TextField';
import React from 'react';

const TextComponent = ({ model, formField, disabled, variant, onChange, className, submitCount }) => {
	const getDefaultValue = () => {
		if (formField.multiple) {
			return formField.renderValue(formField.mapping.split('.').reduce((p, c) => (p && p[c]) || null, model));
		}

		const value = formField.mapping.split('.').reduce((p, c) => (p && p[c]) || null, model);

		if (formField.renderValue) {
			return formField.renderValue(value);
		}

		return formField.capitalizeOptions ? value?.toUpperCase() : value;
	};

	return (
		<TextField
			key={`${formField.mapping}-field`}
			fullWidth
			InputLabelProps={{ shrink: true }}
			className={className}
			data-cy={`form-field-${formField.name}`}
			data-testid="form-field"
			disabled={disabled || formField.disabled}
			error={formField.error || (formField.required ? submitCount && !getDefaultValue() : false)}
			helperText={formField.helperText}
			id={formField.id ? formField.id : `form-field-${formField.name}`}
			inputProps={{ maxLength: formField?.maxLength, ...formField?.props }}
			label={formField.name}
			multiline={formField.multiline}
			placeholder={disabled ? null : formField.placeholder}
			required={formField.required}
			rows={formField.multiline && !disabled ? 3 : undefined}
			value={getDefaultValue() || ''}
			variant={formField.variant || variant || 'outlined'}
			onChange={(event, value) => {
				formField.onChange && formField.onChange(event.target.value);

				onChange(
					event,
					value,
					formField.mapping ? formField.mapping : formField.name,
					false,
					formField.customModelUpdateFn
				);
			}}
		/>
	);
};

const Text = React.memo(TextComponent);

export default Text;
