// React Libraries
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

// Material UI Libraries
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import GridOnIcon from '@mui/icons-material/GridOn';
import ListItemText from '@mui/material/ListItemText';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const StyledListItem = styled(props => <ListItem {...props} />)(() => ({
	'&:hover': {
		cursor: 'pointer',
		background: '#C8D6FF',
		borderRadius: '10px',
		'.icon': {
			display: 'flex',
		},
		'.iconText': {
			marginLeft: '12px',
		},
	},
}));

function DragListItem({ value, index, name }) {
	return (
		<Draggable draggableId={value.name.toString()} index={index}>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					style={{
						userSelect: 'none',
						background: snapshot.isDragging && '#364E5F',
						color: snapshot.isDragging && '#42A5F5',
						borderRadius: snapshot.isDragging && '10px',
						...provided.draggableProps.style,
					}}
				>
					<StyledListItem>
						<GridOnIcon
							className="icon"
							sx={{
								display: snapshot.isDragging ? 'flex' : 'none',
								color: '#4D79EA',
							}}
						/>
						<ListItemText
							className="iconText"
							primary={value.title.replace(name, '')}
							sx={{
								marginLeft: snapshot.isDragging ? '16px' : '38px',
							}}
						/>
						<DragIndicatorIcon
							className="icon"
							sx={{
								display: 'none',
								color: '#4D79EA',
							}}
						/>
					</StyledListItem>
				</div>
			)}
		</Draggable>
	);
}

DragListItem.propTypes = {
	/**
	 * value is list item object that holds name and id
	 */
	value: PropTypes.object.isRequired,
	/**
	 * index is index number of item
	 */
	index: PropTypes.number.isRequired,
};

export default DragListItem;
