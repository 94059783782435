import React, { createContext, useEffect, useState, useContext, useMemo, useCallback } from 'react';
import { useAppModeContext } from '@worklist-2/core/src';
import { scopeMapping } from '../fhir/fhirEndpoints';
import { getColumnMapping } from '..';
import { useConfig } from './ConfigContext';
import FhirDataLoader from '../services/FhirDataLoader';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { searchScopes } from './consts/searchScopes';

// for backwards compatiblity - we should change all paths to import from searchScopes, not searchScopeContext
export { searchScopes };

export const getSearchScopeList = () => {
	const { t } = useTranslation('Search');

	// temporary - this needs to be built out, but for now we just return a static set of routes
	return [
		{
			label: t('All'),
			context: searchScopes.all,
		},
	];
};

const SearchScopeContext = createContext({
	loading: false,
	scope: searchScopes.all,
	endpoint: scopeMapping[searchScopes.all],
	updateScope: () => {
		console.log('updateScope');
	},
	updateEndpoint: () => {
		console.log('updateEndpoint');
	},
	setLoading: () => {
		console.log('setLoading');
	},
	columnMapping: {},
	fhirLoader: {},
});

export const SearchScopeProvider = ({ children }) => {
	const [loading, setLoading] = useState(false);
	const __config = useConfig();
	const [scope, setScope] = useState(searchScopes.all);
	const [endpoint, setEndpoint] = useState(scopeMapping[searchScopes.all]);
	const [columnMapping, setColumnMapping] = useState(getColumnMapping(scopeMapping[searchScopes.all]));
	const defaultLoader = useMemo(
		() =>
			new FhirDataLoader({
				config: __config,
				scopeContext: {
					scope,
					endpoint,
				},
				isGlobal: true,
			}),
		[]
	);
	const [scopeLoader, setScopeLoader] = useState(defaultLoader);
	const { getItemCountRecentViewed } = useAppModeContext();

	const updateEndpointByScope = searchScope => {
		// Do not use FHIR endpoints for Marketplace
		if (searchScope === searchScopes.marketplace) {
			setEndpoint(`${__config.data_sources.marketplace}apps`);
			setColumnMapping(null);
		} else if (scopeMapping[searchScope]) {
			setEndpoint(scopeMapping[searchScope]);
			setColumnMapping(getColumnMapping(scopeMapping[searchScope]));
		} else {
			setScope(searchScopes.all);
			setEndpoint(scopeMapping[searchScopes.all]);
			setColumnMapping(getColumnMapping(scopeMapping[searchScopes.all]));
		}
	};

	const updateScopeByEndpoint = useCallback(newEndpoint => {
		const endpointToScope = _.zipObject(_.values(scopeMapping), _.keys(scopeMapping));
		if (endpointToScope[newEndpoint]) {
			setScope(endpointToScope[newEndpoint]);
			setColumnMapping(getColumnMapping(newEndpoint));
		} else {
			setEndpoint(searchScopes.all);
			setScope(endpointToScope[searchScopes.all]);
			setColumnMapping(getColumnMapping(searchScopes.all));
		}
	}, []);

	useEffect(() => {
		if (!!scope && !!endpoint) {
			setScopeLoader(
				scope === searchScopes.all
					? new FhirDataLoader({
							config: __config,
							scopeContext: {
								scope,
								endpoint: '/',
							},
							isGlobal: true,
					  })
					: new FhirDataLoader({
							config: __config,
							scopeContext: {
								scope,
								endpoint,
							},
							isGlobal: true,
					  })
			);
		}
	}, [endpoint, scope]);

	useEffect(() => {
		if (scope) {
			updateEndpointByScope(scope);
		}
	}, [scope]);

	const updateScope = useCallback(key => {
		if (key) {
			setScope(key);
		}
	});

	const updateEndpoint = useCallback(newEndpoint => {
		if (newEndpoint) {
			updateScopeByEndpoint(newEndpoint);
		}
	});

	const handleSaveRecentlyViewed = item => {
		const recentViewed = localStorage.getItem('recentViewed');
		const itemDetails = { ...item, viewType: scope };
		itemDetails.eTag = undefined; //reset highlight value

		if (recentViewed) {
			let arrays = JSON.parse(recentViewed);
			let isContain;
			const arraysByType = _.filter(arrays, elem => elem.viewType === itemDetails.viewType) || [];

			if (arraysByType.length > 0) {
				isContain = _.find(
					arraysByType,
					elem =>
						(elem.id ?? elem.Content) === (item.id ?? item.Content) &&
						(elem.resourceType ?? elem.ResourceType) === (item.resourceType ?? item.ResourceType)
				);
			}

			if (!isContain) {
				if (arraysByType.length > getItemCountRecentViewed() - 1) {
					arraysByType.pop();
				}
				arraysByType.unshift(itemDetails);
				_.remove(arrays, elem => elem.viewType === itemDetails.viewType);
				arrays = _.union(arrays, arraysByType);
				localStorage.setItem('recentViewed', JSON.stringify(arrays));
			}
		} else {
			const arrays = [itemDetails];
			localStorage.setItem('recentViewed', JSON.stringify(arrays));
		}
	};

	return (
		<SearchScopeContext.Provider
			value={{
				loading,
				scope,
				endpoint,
				updateScope,
				updateEndpoint,
				setLoading,
				columnMapping,
				fhirLoader: scopeLoader,
				handleSaveRecentlyViewed,
			}}
		>
			{children}
		</SearchScopeContext.Provider>
	);
};

export const useSearchScope = () => useContext(SearchScopeContext);
export default SearchScopeProvider;
