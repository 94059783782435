import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';
import getNestedExtensionValue from '@rs-core/fhir/resource/columnMapping/utils/getNestedExtensionValue';
import _ from 'lodash';

/* Generated automagically by FhirStarter - but please feel free to edit this file */
const EncounterMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		searchParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.id;
		},
	},
	identifier: {
		label: 'Identifier',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.identifier;
		},
	},
	status: {
		label: 'Status',
		options: { filter: false, sort: false },
		searchParameter: 'status',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.status?.toUpperCase();
		},
	},
	visitDateTime: {
		label: 'Visit Date/Time',
		getDisplay: resource => {
			return resource?.period?.start;
		},
	},
	statusHistory: {
		label: 'Status History',
		options: { filter: false, sort: false },
		searchParameter: 'statusHistory',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.statusHistory;
		},
	},
	class: {
		label: 'Class',
		options: { filter: false, sort: false },
		searchParameter: 'class',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.class?.code;
		},
	},
	classHistory: {
		label: 'Class History',
		options: { filter: false, sort: false },
		searchParameter: 'classHistory',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.classHistory;
		},
	},
	type: {
		label: 'Type',
		options: { filter: false, sort: false },
		searchParameter: 'type',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.type;
		},
	},
	serviceType: {
		label: 'Service Type',
		options: { filter: false, sort: false },
		searchParameter: 'serviceType',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.serviceType;
		},
	},
	priority: {
		label: 'Priority',
		options: { filter: false, sort: false },
		searchParameter: 'priority',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.priority;
		},
	},
	subject: {
		label: 'Subject',
		options: { filter: false, sort: false },
		searchParameter: 'subject',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.subject;
		},
	},
	patientId: {
		label: 'Patient Id',
		options: { filter: false, sort: false },
		searchParameter: 'subject',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.subject.id;
		},
	},
	episodeOfCare: {
		label: 'Episode Of Care',
		options: { filter: false, sort: false },
		searchParameter: 'episodeOfCare',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.episodeOfCare;
		},
	},
	basedOn: {
		label: 'Based On',
		options: { filter: false, sort: false },
		searchParameter: 'basedOn',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.basedOn;
		},
	},
	participant: {
		label: 'Participant',
		options: { filter: false, sort: false },
		searchParameter: 'participant',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.participant;
		},
	},
	appointment: {
		label: 'Appointment',
		options: { filter: false, sort: false },
		searchParameter: 'appointment',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.appointment;
		},
	},
	period: {
		label: 'Period',
		options: { filter: false, sort: false },
		searchParameter: 'period',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.period;
		},
	},
	length: {
		label: 'Length',
		options: { filter: false, sort: false },
		searchParameter: 'length',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.length;
		},
	},
	reasonCode: {
		label: 'Reason Code',
		options: { filter: false, sort: false },
		searchParameter: 'reasonCode',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.reasonCode;
		},
	},
	reasonReference: {
		label: 'Reason Reference',
		options: { filter: false, sort: false },
		searchParameter: 'reasonReference',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.reasonReference;
		},
	},
	diagnosis: {
		label: 'Diagnosis',
		options: { filter: false, sort: false },
		searchParameter: 'diagnosis',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.diagnosis;
		},
	},
	account: {
		label: 'Account',
		options: { filter: false, sort: false },
		searchParameter: 'account',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.account;
		},
	},
	hospitalization_preAdmissionIdentifier: {
		label: 'Hospitalization_pre Admission Identifier',
		options: { filter: false, sort: false },
		searchParameter: 'hospitalization_preAdmissionIdentifier',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.hospitalization_preAdmissionIdentifier;
		},
	},
	hospitalization_origin: {
		label: 'Hospitalization_origin',
		options: { filter: false, sort: false },
		searchParameter: 'hospitalization_origin',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.hospitalization_origin;
		},
	},
	hospitalization_admitSource: {
		label: 'Hospitalization_admit Source',
		options: { filter: false, sort: false },
		searchParameter: 'hospitalization_admitSource',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.hospitalization_admitSource;
		},
	},
	hospitalization_reAdmission: {
		label: 'Hospitalization_re Admission',
		options: { filter: false, sort: false },
		searchParameter: 'hospitalization_reAdmission',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.hospitalization_reAdmission;
		},
	},
	hospitalization_dietPreference: {
		label: 'Hospitalization_diet Preference',
		options: { filter: false, sort: false },
		searchParameter: 'hospitalization_dietPreference',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.hospitalization_dietPreference;
		},
	},
	hospitalizationSpecialCourtesy: {
		label: 'Special Courtesy',
		options: { filter: false, sort: false },
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.hospitalization?.specialCourtesy[0]?.coding[0].display;
		},
	},
	hospitalizationSpecialArrangement: {
		label: 'Hospitalization Special Arrangement',
		options: { filter: false, sort: false },
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.hospitalization?.specialArrangement[0]?.coding[0].display;
		},
	},
	hospitalization_destination: {
		label: 'Hospitalization_destination',
		options: { filter: false, sort: false },
		searchParameter: 'hospitalization_destination',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.hospitalization_destination;
		},
	},
	hospitalization_dischargeDisposition: {
		label: 'Hospitalization_discharge Disposition',
		options: { filter: false, sort: false },
		searchParameter: 'hospitalization_dischargeDisposition',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.hospitalization_dischargeDisposition;
		},
	},
	patientLocation: {
		label: 'Patient Location',
		options: { filter: false, sort: false },
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.location?.[0]?.location?.display;
		},
	},
	serviceProvider: {
		label: 'Service Provider',
		options: { filter: false, sort: false },
		searchParameter: 'serviceProvider',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.serviceProvider?.display;
		},
	},
	serviceProviderId: {
		label: 'Service Provider ID',
		options: { filter: false, sort: false },
		searchParameter: 'serviceProvider',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.serviceProvider?.id;
		},
	},
	financialClass: {
		getDisplay: resource => {
			return _.map(
				_.filter(resource?.extension, item => {
					return item.url === fhirExtensionUrls.encounter.financialClass;
				}),
				item => item.valueCoding
			);
		},
	},
	partOf: {
		label: 'Part Of',
		options: { filter: false, sort: false },
		searchParameter: 'partOf',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.partOf;
		},
	},
	accidentIllnessDate: {
		getDisplay: resource => {
			return getNestedExtensionValue(resource, fhirExtensionUrls.encounter.accident, 'date', 'dateTime');
		},
	},
	accidentType: {
		getDisplay: resource => {
			return getNestedExtensionValue(resource, fhirExtensionUrls.encounter.accident, 'type', 'coding');
		},
	},
	accidentState: {
		getDisplay: resource => {
			return getNestedExtensionValue(resource, fhirExtensionUrls.encounter.accident, 'state', 'string');
		},
	},
	accidentCountry: {
		getDisplay: resource => {
			return getNestedExtensionValue(resource, fhirExtensionUrls.encounter.accident, 'country', 'string');
		},
	},
};
export default EncounterMapping;
