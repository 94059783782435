import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const CustomTextField = styled(TextField)({
	'& input': {
		color: '#000000',
		opacity: '0.6',
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		fontSize: '14px',
		fontWeight: '400',
		lineHeight: '24px',
		letterSpacing: '0.15px',
		paddingLeft: '18px',
		paddingRight: '18px',
		'&::placeholder': {
			fontSize: '12px',
		},
	},
	'& input:invalid + fieldset': {
		borderColor: '#B12028',
		borderWidth: 1,
	},
	'& input:invalid:focus + fieldset': {
		borderColor: '#B12028',
		borderWidth: 1,
	},
	'& input:invalid:hover + fieldset': {
		borderColor: '#B12028',
		borderWidth: 1,
	},
	'& input:-webkit-autofil': {
		WebkitBoxShadow: '0 0 0px 1000px #FFFFFF inset',
		WebkitTextFillColor: '#000000',
		opacity: '0.6',
		transition: 'background-color 5000s ease-in-out 0s',
	},
	'& input:-internal-autofill-selected': {
		WebkitBoxShadow: '0 0 0px 1000px #FFFFFF inset',
		WebkitTextFillColor: '#000000',
		opacity: '0.6',
		transition: 'background-color 5000s ease-in-out 0s',
	},
	'& .Mui-focused': {
		border: '0.5px solid #888888',
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#888888',
		},
		'&:hover fieldset': {
			borderColor: '#888888',
		},
	},
	'& .MuiInputBase-root': {
		border: 'none',
		borderRadius: '3.5px',
	},
});

export default CustomTextField;
