/* Generated automagically by FhirStarter - DO NOT EDIT */

import _ from 'lodash';

export const Address = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	use: '__code__',
	type: '__code__',
	text: '__string__',
	line: ['__string__'],
	city: '__string__',
	district: '__string__',
	state: '__string__',
	postalCode: '__string__',
	country: '__string__',
	period: '__Period__',
};

export const Annotation = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	authorReference: '__Reference(Practitioner|Patient|RelatedPerson|Organization)__',
	authorString: '__string__',
	time: '__dateTime__',
	text: '__markdown__',
};

export const Attachment = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	contentType: '__code__',
	language: '__code__',
	data: '__base64Binary__',
	url: '__url__',
	size: '__unsignedInt__',
	hash: '__base64Binary__',
	title: '__string__',
	creation: '__dateTime__',
	__rule: ({ data, contentType }) => !data || !!contentType,
};

export const CodeableConcept = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	coding: ['__Coding__'],
	text: '__string__',
};

export const Coding = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	system: '__uri__',
	version: '__string__',
	code: '__code__',
	display: '__string__',
	userSelected: '__boolean__',
};

export const ContactPoint = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	system: '__code__',
	value: '__string__',
	use: '__code__',
	rank: '__positiveInt__',
	period: '__Period__',
	__rule: ({ value, system }) => !value || !!system,
};

export const HumanName = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	use: '__code__',
	text: '__string__',
	family: '__string__',
	given: ['__string__'],
	prefix: ['__string__'],
	suffix: ['__string__'],
	period: '__Period__',
};

export const Identifier = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	use: '__code__',
	type: '__CodeableConcept__',
	system: '__uri__',
	value: '__string__',
	period: '__Period__',
	assigner: '__Reference(Organization)__',
};

export const Money = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	value: '__decimal__',
	currency: '__code__',
};

export const Period = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	start: '__dateTime__',
	end: '__dateTime__',
	__rule: ({ start, end }) => !start || start < end,
};

export const Quantity = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	value: '__decimal__',
	comparator: '__code__',
	unit: '__string__',
	system: '__uri__',
	code: '__code__',
	__rule: ({ code, system }) => !code || !!system,
};

export const Age = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	value: '__decimal__',
	comparator: '__code__',
	unit: '__string__',
	system: '__uri__',
	code: '__code__',
	__rule: ({ code, value, system }) =>
		(!!code || !value) && (!system || system.toUpperCase() === 'UCUM') && !!value && value > 0,
};

export const Count = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	value: '__decimal__',
	comparator: '__code__',
	unit: '__string__',
	system: '__uri__',
	code: '__code__',
	__rule: ({ code, value, system }) =>
		(!!code || !value) &&
		(!system || system.toUpperCase() === 'UCUM') &&
		(!code || code === 1) &&
		(!value || !value.toString().includes('.')),
};

export const Duration = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	value: '__decimal__',
	comparator: '__code__',
	unit: '__string__',
	system: '__uri__',
	code: '__code__',
	__rule: ({ code, value, system }) => (code ? system.toUpperCase() === 'UCUM' && !!value : true),
};

export const Distance = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	value: '__decimal__',
	comparator: '__code__',
	unit: '__string__',
	system: '__uri__',
	code: '__code__',
	__rule: ({ code, value, system }) => (code ? system.toUpperCase() === 'UCUM' && !!value : true),
};

export const SimpleQuantity = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	value: '__decimal__',
	comparator: '__code__',
	unit: '__string__',
	system: '__uri__',
	code: '__code__',
	__rule: ({ code, value, system }) => (code ? system.toUpperCase() === 'UCUM' && !!value : true),
};

export const Range = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	low: '__Quantity(SimpleQuantity)__',
	high: '__Quantity(SimpleQuantity)__',
	__rule: ({ low, high }) => !!low || !!high || low <= high,
};

export const Ratio = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	numerator: '__Quantity__',
	denominator: '__Quantity__',
	__rule: ({ numerator, denominator, extension }) =>
		((!numerator && !denominator) || (numerator && denominator)) && (!!numerator || !!extension),
};

export const SampledData = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	origin: '__Quantity(SimpleQuantity)__',
	period: '__decimal__',
	factor: '__decimal__',
	lowerLimit: '__decimal__',
	upperLimit: '__decimal__',
	dimensions: '__positiveInt__',
	data: '__string__',
};

export const Signature = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	type: ['__Coding__'],
	when: '__instant__',
	who: '__Reference(Practitioner|PractitionerRole|RelatedPerson|Patient|Device|Organization)__',
	onBehalfOf: '__Reference(Practitioner|PractitionerRole|RelatedPerson|Patient|Device|Organization)__',
	targetFormat: '__code__',
	sigFormat: '__code__',
	data: '__base64Binary__',
};

export const Timing = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	event: ['__dateTime__'],
	repeat: {
		boundsDuration: '__Duration__',
		boundsRange: '__Range__',
		boundsPeriod: '__Period__',
		count: '__positiveInt__',
		countMax: '__positiveInt__',
		duration: '__decimal__',
		durationMax: '__decimal__',
		durationUnit: '__code__',
		frequency: '__positiveInt__',
		frequencyMax: '__positiveInt__',
		period: '__decimal__',
		periodMax: '__decimal__',
		periodUnit: '__code__',
		dayOfWeek: ['__code__'],
		timeOfDay: ['__time__'],
		when: ['__code__'],
		offset: '__unsignedInt__',
	},
	code: '__CodeableConcept__',
	__rule: ({
		duration,
		durationUnit,
		period,
		periodUnit,
		periodMax,
		durationMax,
		count,
		countMax,
		offset,
		when,
		timeOfDay,
	}) =>
		(!duration || !!durationUnit) &&
		(!period || !!periodUnit) &&
		(!!duration ? duration >= 0 : true) &&
		(!periodMax || !!period) &&
		(!durationMax || !!duration) &&
		(!countMax || !!count) &&
		(!offset || (!!when && !['C', 'CM', 'CD', 'CV'].includes(when))) &&
		(!timeOfDay || !when),
};

export const ContactDetail = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	name: '__string__',
	telecom: ['__ContactPoint__'],
};

export const Contributor = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	type: '__code__',
	name: '__string__',
	contact: ['__ContactDetail__'],
};

export const DataRequirement = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	type: '__code__',
	profile: ['__canonical(StructureDefinition)__'],
	subjectCodeableConcept: '__CodeableConcept__',
	subjectReference: '__Reference(Group)__',
	mustSupport: ['__string__'],
	codeFilter: [
		{
			path: '__string__',
			searchParam: '__string__',
			valueSet: '__canonical(ValueSet)__',
			code: ['__Coding__'],
		},
	],
	dateFilter: [
		{
			path: '__string__',
			searchParam: '__string__',
			valueDateTime: '__dateTime__',
			valuePeriod: '__Period__',
			valueDuration: '__Duration__',
		},
	],
	limit: '__positiveInt__',
	sort: [{ path: '__string__', direction: '__code__' }],
};

export const Expression = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	description: '__string__',
	name: '__id__',
	language: '__code__',
	expression: '__string__',
	reference: '__uri__',
};

export const ParameterDefinition = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	name: '__code__',
	use: '__code__',
	min: '__integer__',
	max: '__string__',
	documentation: '__string__',
	type: '__code__',
	profile: '__canonical(StructureDefinition)__',
};

export const RelatedArtifact = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	type: '__code__',
	label: '__string__',
	display: '__string__',
	citation: '__markdown__',
	url: '__url__',
	document: '__Attachment__',
	resource: '__canonical(Any)__',
};

export const TriggerDefinition = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	type: '__code__',
	name: '__string__',
	timingTiming: '__Timing__',
	timingReference: '__Reference(Schedule)__',
	timingDate: '__date__',
	timingDateTime: '__dateTime__',
	data: ['__DataRequirement__'],
	condition: '__Expression__',
};

export const UsageContext = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	code: '__Coding__',
	valueCodeableConcept: '__CodeableConcept__',
	valueQuantity: '__Quantity__',
	valueRange: '__Range__',
	valueReference:
		'__Reference(PlanDefinition|ResearchStudy|InsurancePlan|HealthcareService|Group|Location|Organization)__',
};

export const Dosage = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	sequence: '__integer__',
	text: '__string__',
	additionalInstruction: ['__CodeableConcept__'],
	patientInstruction: '__string__',
	timing: '__Timing__',
	asNeededBoolean: '__boolean__',
	asNeededCodeableConcept: '__CodeableConcept__',
	site: '__CodeableConcept__',
	route: '__CodeableConcept__',
	method: '__CodeableConcept__',
	doseAndRate: [
		{
			type: '__CodeableConcept__',
			doseRange: '__Range__',
			doseQuantity: '__Quantity(SimpleQuantity)__',
			rateRatio: '__Ratio__',
			rateRange: '__Range__',
			rateQuantity: '__Quantity(SimpleQuantity)__',
		},
	],
	maxDosePerPeriod: '__Ratio__',
	maxDosePerAdministration: '__Quantity(SimpleQuantity)__',
	maxDosePerLifetime: '__Quantity(SimpleQuantity)__',
};

export const Meta = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	versionId: '__id__',
	lastUpdated: '__instant__',
	source: '__uri__',
	profile: ['__canonical(StructureDefinition)__'],
	security: ['__Coding__'],
	tag: ['__Coding__'],
};

export const Reference = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	reference: '__string__',
	type: '__uri__',
	identifier: '__Identifier__',
	display: '__string__',
};

export const Extension = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	url: '__uri__',
	valueBase64Binary: '__base64Binary__',
	valueBoolean: '__boolean__',
	valueCanonical: '__canonical__',
	valueCode: '__code__',
	valueDate: '__date__',
	valueDateTime: '__dateTime__',
	valueDecimal: '__decimal__',
	valueId: '__id__',
	valueInstant: '__instant__',
	valueInteger: '__integer__',
	valueMarkdown: '__markdown__',
	valueOid: '__oid__',
	valuePositiveInt: '__positiveInt__',
	valueString: '__string__',
	valueTime: '__time__',
	valueUnsignedInt: '__unsignedInt__',
	valueUri: '__uri__',
	valueUrl: '__url__',
	valueUuid: '__uuid__',
	valueAddress: '__Address__',
	valueAge: '__Age__',
	valueAnnotation: '__Annotation__',
	valueAttachment: '__Attachment__',
	valueCodeableConcept: '__CodeableConcept__',
	valueCoding: '__Coding__',
	valueContactPoint: '__ContactPoint__',
	valueCount: '__Count__',
	valueDistance: '__Distance__',
	valueDuration: '__Duration__',
	valueHumanName: '__HumanName__',
	valueIdentifier: '__Identifier__',
	valueMoney: '__Money__',
	valuePeriod: '__Period__',
	valueQuantity: '__Quantity__',
	valueRange: '__Range__',
	valueRatio: '__Ratio__',
	valueReference: '__Reference__',
	valueSampledData: '__SampledData__',
	valueSignature: '__Signature__',
	valueTiming: '__Timing__',
	valueContactDetail: '__ContactDetail__',
	valueContributor: '__Contributor__',
	valueDataRequirement: '__DataRequirement__',
	valueExpression: '__Expression__',
	valueParameterDefinition: '__ParameterDefinition__',
	valueRelatedArtifact: '__RelatedArtifact__',
	valueTriggerDefinition: '__TriggerDefinition__',
	valueUsageContext: '__UsageContext__',
	valueDosage: '__Dosage__',
	valueMeta: '__Meta__',
};

export const Narrative = {
	__valid: true,
	id: '__id__',
	extension: ['__Extension__'],
	status: '__code__',
	div: '__EscapedXHTML__',
};
const dict = {
	AddressConst: Address,
	AnnotationConst: Annotation,
	AttachmentConst: Attachment,
	CodeableConceptConst: CodeableConcept,
	CodingConst: Coding,
	ContactPointConst: ContactPoint,
	HumanNameConst: HumanName,
	IdentifierConst: Identifier,
	MoneyConst: Money,
	PeriodConst: Period,
	QuantityConst: Quantity,
	RangeConst: Range,
	RatioConst: Ratio,
	SampledDataConst: SampledData,
	SignatureConst: Signature,
	TimingConst: Timing,
	ContactDetailConst: ContactDetail,
	ContributorConst: Contributor,
	DataRequirementConst: DataRequirement,
	ExpressionConst: Expression,
	ParameterDefinitionConst: ParameterDefinition,
	RelatedArtifactConst: RelatedArtifact,
	TriggerDefinitionConst: TriggerDefinition,
	UsageContextConst: UsageContext,
	DosageConst: Dosage,
	MetaConst: Meta,
	ReferenceConst: Reference,
	ExtensionConst: Extension,
	NarrativeConst: Narrative,
	AgeConst: Age,
	CountConst: Count,
	DurationConst: Duration,
	DistanceConst: Distance,
	SimpleQuantityConst: SimpleQuantity,
};
export const getSchema = schema => {
	const REGEX = /_*(?<datatype>[^\(]*)\(?[^\)]*\)?_*/g;
	if (REGEX.test(_.replace(schema, /_/g, ''))) {
		schema = _.upperFirst(schema.replace(REGEX, '$<datatype>'));
	}
	return dict[`${schema}Const`];
};
