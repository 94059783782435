// core
import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// mui
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const Popup = ({
	children,
	popupComponent,
	disablePortal,
	hoverMode,
	delay,
	sxBox,
	isOpen,
	onPopup,
	placement,
	onClick,
	popupOpen,
	setPopupOpen,
	timeout = 300,
}) => {
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const openValue = setPopupOpen ? popupOpen : open;

	const enterTimer = useRef();
	const exitTimer = useRef();
	const boxRef = useRef();

	const handleEnter = e => {
		clearTimeout(enterTimer.current);
		clearTimeout(exitTimer.current);
		setAnchorEl(e.currentTarget);

		if (delay && !open) {
			enterTimer.current = setTimeout(() => {
				handleOpen();
			}, delay);
		} else {
			handleOpen();
		}
	};

	const handleLeave = () => {
		clearTimeout(enterTimer.current);
		exitTimer.current = setTimeout(() => {
			handleClose();
		}, 100);
	};

	const handleWheel = () => {
		handleClose();
	};

	const handlePopupEnter = () => {
		clearTimeout(exitTimer.current);
	};

	const handlePopupLeave = () => {
		exitTimer.current = setTimeout(() => {
			handleClose();
		}, 500);
	};

	const handleClick = e => {
		setAnchorEl(e.currentTarget);
		e.stopPropagation();
		handleOpen();
	};

	const handleClickAway = e => {
		if (e.target.localName === 'body') {
			// This is to prevent unwanted behaviour for mui Select component
			// Reference: https://github.com/mui/material-ui/issues/12034
			return;
		}
		if (!hoverMode) {
			handleClose();
		}
	};

	const handleClose = () => {
		if (setPopupOpen) {
			setPopupOpen(false);
		} else {
			setOpen(false);
		}
		onPopup?.(false);
	};

	const handleOpen = () => {
		if (!open) {
			if (setPopupOpen) {
				setPopupOpen(true);
			} else {
				setOpen(true);
			}
			onPopup?.(true);
		}
	};

	const popperModifiers = useMemo(
		() => [
			{
				name: 'offset',
				enabled: true,
				options: {
					offset: [0, 10],
				},
			},
			{
				name: 'preventOverflow',
				enabled: true,
				options: {
					mainAxis: true,
					altAxis: false,
					tether: true,
					altBoundary: false,
				},
			},
			{
				name: 'flip',
				enabled: true,
				options: {
					altBoundary: false,
				},
			},
			{
				name: 'eventListeners',
				enabled: true,
				options: {
					resize: false,
					scroll: true,
				},
			},
		],
		[]
	);

	useEffect(() => {
		if (!isOpen) {
			handleClose();
		}
	}, [isOpen]);

	useEffect(() => {
		if (openValue && !anchorEl) {
			// set the anchor element to be the child wrapper
			setAnchorEl(boxRef?.current);
		}
	}, [openValue, boxRef?.current]);

	return (
		<>
			<Popper
				transition
				anchorEl={anchorEl}
				disablePortal={disablePortal}
				modifiers={popperModifiers}
				open={openValue}
				placement={placement || 'bottom'}
				sx={{ zIndex: '999' }}
				onMouseDown={e => e.stopPropagation()}
				onMouseEnter={hoverMode ? handlePopupEnter : null}
				onMouseLeave={hoverMode ? handlePopupLeave : null}
			>
				{({ TransitionProps }) => (
					<GrowWrapper TransitionProps={TransitionProps} timeout={timeout}>
						{popupComponent ? (
							<ClickAwayListener onClickAway={handleClickAway}>
								<Box>
									{React.cloneElement(popupComponent, {
										onClose: handleClose,
									})}
								</Box>
							</ClickAwayListener>
						) : null}
					</GrowWrapper>
				)}
			</Popper>
			<Box
				ref={boxRef}
				className="popup-box"
				display="flex"
				flexDirection="row"
				justifyContent="flex-start"
				sx={sxBox}
				onClick={!hoverMode ? onClick || handleClick : null}
				onMouseLeave={hoverMode ? handleLeave : null}
				onMouseOver={hoverMode ? handleEnter : null}
				onWheel={handleWheel}
			>
				{children}
			</Box>
		</>
	);
};

const GrowWrapper = ({ children, TransitionProps, timeout }) => (
	<Grow orientation="vertical" {...TransitionProps} timeout={timeout}>
		<Box
			sx={{
				backgroundColor: 'transparent',
				borderRadius: '20px',
				boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
			}}
		>
			{children}
		</Box>
	</Grow>
);

Popup.propTypes = {
	/**
	 * Component to hover over which will then trigger the popup.
	 */
	children: PropTypes.node.isRequired,
	/**
	 * Component to display after hovering over the children component.
	 */
	popupComponent: PropTypes.node,
	/**
	 * Time in milliseconds before the popup will display. Only applies in 'hover' mode.
	 */
	delay: PropTypes.number,
	disablePortal: PropTypes.bool,
	/**
	 * Determines whether to show popup based on hover, or click
	 */
	hoverMode: PropTypes.bool,
	sxBox: PropTypes.object,
	/**
	 * Lifted State that determines if the popup is open or not; cannot be directly
	 * manipulated by popup internally
	 */
	isOpen: PropTypes.bool,
	/**
	 * Popper placement
	 */
	placement: PropTypes.string,
	/**
	 * Optional lifted State that determines if the popup is open
	 */
	popupOpen: PropTypes.bool,
	/**
	 * Optional Setter function for the open state
	 */
	setPopupOpen: PropTypes.func,
};

Popup.defaultProps = {
	delay: 1000,
	disablePortal: true,
	hoverMode: true,
};

/**
 * This is a component which can be used wrap around another component which gives it the ability
 * to popup when mouse over.
 */

export default Popup;
