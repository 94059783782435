import React from 'react';
import { styled } from '@mui/material/styles';

const StyledImg = styled('img')(() => ({
	objectFit: 'contain',
	backgroundColor: 'black',
	borderRadius: '8px',
	width: '96px',
	minWidth: '96px',
	height: '96px',
	'&:not(:last-of-type)': {
		marginRight: '4px',
	},
}));

const StudyThumbnailImage = ({ imageUrl, style }) => (
	<StyledImg
		data-testid="study-thumbnail-image"
		src={imageUrl}
		style={{
			...style,
		}}
	/>
);

export default StudyThumbnailImage;
