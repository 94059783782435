import React from 'react';

const LogsIcon = ({ fill }) => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13 3C10.6131 3 8.32387 3.94822 6.63604 5.63604C4.94821 7.32387 4 9.61306 4 12H1L4.89 15.89L4.96 16.03L9 12H6C5.99221 10.3712 6.54992 8.79021 7.57794 7.52681C8.60597 6.26341 10.0406 5.39595 11.6369 5.07246C13.2333 4.74898 14.8925 4.98953 16.3312 5.75305C17.77 6.51658 18.8992 7.75576 19.5261 9.25909C20.153 10.7624 20.2387 12.4367 19.7687 13.9962C19.2986 15.5557 18.3019 16.9038 16.9487 17.8103C15.5954 18.7168 13.9695 19.1256 12.3485 18.9669C10.7274 18.8082 9.21171 18.0918 8.06 16.94L6.64 18.36C7.68631 19.4076 8.97493 20.1809 10.3916 20.6113C11.8083 21.0417 13.3093 21.116 14.7616 20.8275C16.2138 20.539 17.5724 19.8967 18.717 18.9574C19.8616 18.0182 20.7568 16.8111 21.3233 15.4431C21.8897 14.0751 22.1099 12.5885 21.9643 11.115C21.8187 9.64156 21.3118 8.22678 20.4886 6.99609C19.6654 5.76541 18.5513 4.75684 17.245 4.05977C15.9387 3.36271 14.4806 2.9987 13 3ZM12 8V13L16.25 15.52L17.02 14.24L13.5 12.15V8H12Z"
			fill={fill || 'white'}
			fillOpacity={fill ? 1 : 0.6}
		/>
	</svg>
);

export default LogsIcon;
