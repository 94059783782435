export const handleKeyUp = (event, blockType) => {
	if (blockType === 'Bullet List' && event.target) {
		const { keyCode, target } = event;
		const { selectionStart, value } = target;

		const bullet = '\u2022';
		const bulletWithSpace = `${bullet} `;

		if (keyCode === 13) {
			target.value = [...value].map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c)).join('');

			target.selectionStart = selectionStart + bulletWithSpace.length;
			target.selectionEnd = selectionStart + bulletWithSpace.length;
		}

		if (value[0] !== bullet) {
			target.value = `${bulletWithSpace}${value}`;
		}
	}

	if (blockType === 'Number List' && event.target) {
		const { keyCode, target } = event;
		const { selectionStart, value } = target;

		let counter = target.value.split(/\r?\n|\r|\n/g).length;
		let counterWithSpace = `${counter}. `;

		if (keyCode === 13) {
			target.value = [...value].map((c, i) => (i === selectionStart - 1 ? `\n${counterWithSpace}` : c)).join('');

			target.selectionStart = selectionStart + counterWithSpace.length;
			target.selectionEnd = selectionStart + counterWithSpace.length;
		}

		if (value[0] !== '1') {
			target.value = `${counterWithSpace}${value}`;
		}
	}

	const textarea = document.querySelector('[id*="textarea"]');

	textarea.style.height = 'auto';
	textarea.style.height = textarea.scrollHeight + 'px';
};
