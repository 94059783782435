export default isDragging => {
	return {
		wrapper: {
			boxSizing: 'border-box',
			minWidth: '290px',
			width: '100%',
			height: '100%',
			borderRadius: '20px',
			backgroundColor: 'rgba(77, 121, 234, 0.05)',
			padding: '13px 21px 0',
			display: 'flex',
			flexDirection: 'column',
			border: isDragging ? '2px dashed #4D79EA' : '2px solid #f9fafc',
		},
		title: {
			display: 'flex',
			flexDirection: 'row',
			gap: '6px',
			alignItems: 'center',
			marginBottom: '16px',
		},
		statusText: {
			fontSize: '16px',
		},
		columnBody: {
			flex: 1,
			overflowY: 'auto',
			gap: '10px',
		},
		columnDrag: {
			width: '100%',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
		dragText: {
			fontSize: '20px',
			color: '#4D79EA',
			textAlign: 'center',
		},
		listItemContainer: {
			boxSizing: 'border-box',
			minHeight: '144px',
			width: '100%',
			borderRadius: '10px',
			backgroundColor: '#FFF',
			boxShadow: '0px 0px 10px rgba(33, 88, 119, 0.1)',
			marginBottom: '10px',
			padding: '12px 17px',
		},
		listItemTitle: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			fontFamily: 'Inter',
			fontStyle: 'normal',
			color: '#4D79EA',
		},
		priority: {
			fontSize: '22px',
			fontWeight: 700,
			lineHeight: '27px',
			margin: '0px',
		},
		caseNumber: {
			fontSize: '12px',
			fontWeight: 500,
		},
		date: {
			fontSize: '12px',
			color: 'rgba(0, 0, 0, 0.6)',
		},
		subject: {
			fontSize: '18px',
			color: 'rgba(0, 0, 0, 0.9)',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
		right: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'flex-end',
			justifyContent: 'space-between',
			marginTop: '15px',
			gap: '10px',
		},
		rightBox: {
			flex: 1,
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			gap: '5px',
		},

		avatar: {
			width: 27,
			height: 27,
			fontSize: '14px',
			backgroundColor: 'rgba(77, 121, 234, 0.2)',
			color: '#4D79EA',
		},
	};
};
