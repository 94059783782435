// core
import React from 'react';

// MUI
import InputAdornment from '@mui/material/InputAdornment';

// MUI icons
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StarIcon from '@mui/icons-material/Star';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTime';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import HealthAndSafetyOutlined from '@mui/icons-material/HealthAndSafetyOutlined';

const searchIconAdornment = {
	startAdornment: (
		<InputAdornment position="start">
			<SearchIcon />
		</InputAdornment>
	),
};

const avatarIconAdornment = {
	startAdornment: (
		<InputAdornment position="start">
			<AccountCircleIcon />
		</InputAdornment>
	),
};

const starIconAdornment = {
	startAdornment: (
		<InputAdornment position="start">
			<StarIcon />
		</InputAdornment>
	),
};

const calendarMonthOutlinedIconAdornment = {
	startAdornment: (
		<InputAdornment position="start">
			<CalendarMonthOutlinedIcon />
		</InputAdornment>
	),
};

const accessTimeOutlinedIconAdornment = {
	startAdornment: (
		<InputAdornment position="start">
			<AccessTimeOutlinedIcon />
		</InputAdornment>
	),
};

const eventRepeatOutlinedIconAdornment = {
	startAdornment: (
		<InputAdornment position="start">
			<EventRepeatOutlinedIcon />
		</InputAdornment>
	),
};

const healthSafetyIconAdornment = {
	startAdornment: (
		<InputAdornment position="start">
			<HealthAndSafetyOutlined />
		</InputAdornment>
	),
};

export {
	avatarIconAdornment,
	starIconAdornment,
	calendarMonthOutlinedIconAdornment,
	accessTimeOutlinedIconAdornment,
	eventRepeatOutlinedIconAdornment,
	healthSafetyIconAdornment,
};
export default searchIconAdornment;
