import React from 'react';

import { EmptyBlume } from '../../assets/icons';

import emptyOAI from '../../assets/images/emptyOAI.png';

import styles from './EmptyArticle.module.scss';

const EmptyArticle = ({ theme }) => (
	<div className={styles.emptyArticle}>
		<div>
			{theme === 'OAI' ? (
				<img alt="emptyOAI" data-testid="empty-OAI" height={227} src={emptyOAI} width={227} />
			) : (
				<EmptyBlume dataTestId="empty-Blume" />
			)}
		</div>
		<div className={`${styles.emptyArticleLabel} ${theme === 'OAI' ? styles.OAITheme : styles.BlumeTheme}`}>
			<span>Nothing Here Yet</span>
			<p>Choose a category for your article.</p>
		</div>
	</div>
);

export default EmptyArticle;
