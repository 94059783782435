import { v4 as uuid } from 'uuid';
import { useImageViewerLayoutContext } from '../contexts/ImageViewerLayoutContext';
import { useImageViewerCornerstoneContext } from '../contexts/ImageViewerCornerstoneContext';
import getDefaultRenderingEngine from '../cornerstone/getDefaultRenderingEngine';
import { useImageViewerLayoutStoreSelector } from '../features/CustomToolbar/context/zustand/ImageViewerLayoutZustandProvider';

const useOnViewportDoubleClick = () => {
	const { highlightedViewport, setHighlightedViewport, setLayoutItems } = useImageViewerLayoutContext();
	const { resizeViewports } = useImageViewerCornerstoneContext();
	const setActiveViewport = useImageViewerLayoutStoreSelector(state => state.setActiveViewport);
	const setActiveViewportId = useImageViewerLayoutStoreSelector(state => state.setActiveViewportId);

	const onViewportDoubleClick = layoutItem => {
		let cameraPreset;
		if (highlightedViewport) {
			setLayoutItems(prevState =>
				prevState.map(l => {
					if (l.id === layoutItem.id) {
						let { initialInstanceIndex } = l;

						if (layoutItem.viewports?.length) {
							const renderingEngine = getDefaultRenderingEngine();

							if (renderingEngine) {
								const cornerstoneViewport = renderingEngine.getViewport(layoutItem.viewports[0].id);

								if (cornerstoneViewport) {
									cameraPreset = {
										pan: cornerstoneViewport.getPan(),
										properties: cornerstoneViewport.getProperties(),
										zoom: cornerstoneViewport.getZoom(),
										camera: cornerstoneViewport.getCamera(),
									};
									initialInstanceIndex = cornerstoneViewport.getCurrentImageIdIndex() || 0;
								}
							}
						}

						return {
							...l,
							viewports: l.viewports.map(v => ({ ...v, cameraPreset })),
							initialInstanceIndex,
						};
					}

					return l;
				})
			);
			return setHighlightedViewport(undefined);
		}

		resizeViewports();

		const newItem = { ...layoutItem };
		if (newItem.viewports?.length) {
			const renderingEngine = getDefaultRenderingEngine();

			if (renderingEngine) {
				const cornerstoneViewport = renderingEngine.getViewport(newItem.viewports[0].id);

				if (cornerstoneViewport) {
					cameraPreset = {
						pan: cornerstoneViewport.getPan(),
						properties: cornerstoneViewport.getProperties(),
						zoom: cornerstoneViewport.getZoom(),
						camera: cornerstoneViewport.getCamera(),
					};
					newItem.initialInstanceIndex = cornerstoneViewport.getCurrentImageIdIndex() || 0;
				}
			}

			// To Preserve the lastViewportId for comparison with the new highlighted viewport
			const lastViewportId = newItem?.viewports[0]?.id;

			newItem.viewports = newItem.viewports.map(viewport => ({
				...viewport,
				cameraPreset,
				lastViewportId,
				id: uuid(),
			}));
		}

		setHighlightedViewport(newItem);
		setActiveViewportId(newItem.viewports[0].id);
		setActiveViewport(newItem);
	};

	return {
		onViewportDoubleClick,
	};
};

export default useOnViewportDoubleClick;
