import React from 'react';

const MergedIcon = () => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_5211_23491)">
			<path
				d="M16.9998 20.41L18.4098 19L14.9998 15.59L13.5898 17L16.9998 20.41ZM7.49984 8H10.9998V13.59L5.58984 19L6.99984 20.41L12.9998 14.41V8H16.4998L11.9998 3.5L7.49984 8Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_5211_23491">
				<rect fill="white" height="24" width="24" />
			</clipPath>
		</defs>
	</svg>
);

export default MergedIcon;
