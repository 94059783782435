import {
	Enums,
	RectangleROITool,
	utilities as toolsUtilites,
	annotation,
	cursors,
	drawing,
} from '@cornerstonejs/tools';
import { getEnabledElement, triggerEvent, eventTarget, VolumeViewport, utilities as csUtils } from '@cornerstonejs/core';

const {
	drawHandles: drawHandlesSvg,
	drawLine: drawLineSvg,
	drawRect: drawRectSvg,
	drawLinkedTextBox: drawLinkedTextBoxSvg,
} = drawing;
const { Events } = Enums;
const { triggerAnnotationRenderForViewportIds } = toolsUtilites;
const { removeAnnotation, getAnnotations } = annotation.state;
const { isAnnotationVisible } = annotation.visibility;
const { isAnnotationLocked } = annotation.locking;
const { resetElementCursor } = cursors.elementCursor;

class RectangleROIToolUpdated extends RectangleROITool {
	_endCallback = evt => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const { annotation, viewportIdsToRender, newAnnotation, hasMoved } = this.editData;
		const { data } = annotation;

		if (newAnnotation && !hasMoved) {
			return;
		}

		data.handles.activeHandleIndex = null;

		this._deactivateModify(element);
		this._deactivateDraw(element);

		resetElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		this.editData = null;
		this.isDrawing = false;

		if (this.isHandleOutsideImage && this.configuration.preventHandleOutsideImage) {
			removeAnnotation(annotation.annotationUID);
		}

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		const eventType = Events.ANNOTATION_COMPLETED;

		triggerEvent(eventTarget, eventType, { annotation });
	};
	renderAnnotation = (enabledElement, svgDrawingHelper) => {
		let renderStatus = false;
		const { viewport } = enabledElement;
		if (!viewport) {
			return renderStatus;
		}
		const { element } = viewport;
		if (!element) {
			return renderStatus;
		}

		let annotations = getAnnotations(this.getToolName(), element);

		if (!annotations?.length) {
			return renderStatus;
		}

		annotations = this.filterInteractableAnnotationsForElement(element, annotations);

		if (!annotations?.length) {
			return renderStatus;
		}

		const targetId = this.getTargetId(viewport);
		const renderingEngine = viewport.getRenderingEngine();

		const styleSpecifier = {
			toolGroupId: this.toolGroupId,
			toolName: this.getToolName(),
			viewportId: viewport.id,
		};

		for (let i = 0; i < annotations.length; i++) {
			const annotation = annotations[i];
			const { annotationUID, data } = annotation;
			if (!Array.isArray(data?.handles?.points)) {
				continue;
			}
			const { points, activeHandleIndex } = data.handles;
			const canvasCoordinates = points.map(p => viewport.worldToCanvas(p));

			styleSpecifier.annotationUID = annotationUID;

			const lineWidth = this.getStyle('lineWidth', styleSpecifier, annotation);
			const lineDash = this.getStyle('lineDash', styleSpecifier, annotation);
			const color = this.getStyle('color', styleSpecifier, annotation);

			const { viewPlaneNormal, viewUp } = viewport.getCamera();

			// If cachedStats does not exist, or the unit is missing (as part of import/hydration etc.),
			// force to recalculate the stats from the points
			if (!data?.cachedStats[targetId] || data?.cachedStats[targetId]?.areaUnit == null) {
				data.cachedStats[targetId] = {
					Modality: null,
					area: null,
					max: null,
					mean: null,
					stdDev: null,
					areaUnit: null,
				};

				this._calculateCachedStats(annotation, viewPlaneNormal, viewUp, renderingEngine, enabledElement);
			} else if (annotation.invalidated) {
				this._throttledCalculateCachedStats(
					annotation,
					viewPlaneNormal,
					viewUp,
					renderingEngine,
					enabledElement
				);

				// If the invalidated data is as a result of volumeViewport manipulation
				// of the tools, we need to invalidate the related stackViewports data if
				// they are not at the referencedImageId, so that
				// when scrolling to the related slice in which the tool were manipulated
				// we re-render the correct tool position. This is due to stackViewport
				// which doesn't have the full volume at each time, and we are only working
				// on one slice at a time.
				if (viewport instanceof VolumeViewport) {
					const { referencedImageId } = annotation.metadata;

					// invalidate all the relevant stackViewports if they are not
					// at the referencedImageId
					for (const targetId in data.cachedStats) {
						if (targetId.startsWith('imageId')) {
							const viewports = renderingEngine.getStackViewports();

							const invalidatedStack = viewports.find(vp => {
								// The stack viewport that contains the imageId but is not
								// showing it currently
								const referencedImageURI = csUtils.imageIdToURI(referencedImageId);
								const hasImageURI = vp.hasImageURI(referencedImageURI);
								const currentImageURI = csUtils.imageIdToURI(vp.getCurrentImageId());
								return hasImageURI && currentImageURI !== referencedImageURI;
							});

							if (invalidatedStack) {
								delete data.cachedStats[targetId];
							}
						}
					}
				}
			}

			// If rendering engine has been destroyed while rendering
			if (!viewport.getRenderingEngine()) {
				console.warn('Rendering Engine has been destroyed');
				return renderStatus;
			}

			let activeHandleCanvasCoords;

			if (!isAnnotationVisible(annotationUID)) {
				continue;
			}

			if (!isAnnotationLocked(annotation) && !this.editData && activeHandleIndex !== null) {
				// Not locked or creating and hovering over handle, so render handle.
				activeHandleCanvasCoords = [canvasCoordinates[activeHandleIndex]];
			}

			if (activeHandleCanvasCoords) {
				const handleGroupUID = '0';

				drawHandlesSvg(svgDrawingHelper, annotationUID, handleGroupUID, activeHandleCanvasCoords, {
					color,
				});
			}

			const dataId = `${annotationUID}-rect`;
			if (this.configuration.rotateRectangle) {
				drawLineSvg(
					svgDrawingHelper,
					annotationUID,
					'0',
					canvasCoordinates[0],
					canvasCoordinates[1],
					{ color, lineDash, lineWidth },
					`${annotationUID}-line-0`
				);
				drawLineSvg(
					svgDrawingHelper,
					annotationUID,
					'1',
					canvasCoordinates[1],
					canvasCoordinates[3],
					{ color, lineDash, lineWidth },
					`${annotationUID}-line-1`
				);
				drawLineSvg(
					svgDrawingHelper,
					annotationUID,
					'2',
					canvasCoordinates[3],
					canvasCoordinates[2],
					{ color, lineDash, lineWidth },
					`${annotationUID}-line-2`
				);
				drawLineSvg(
					svgDrawingHelper,
					annotationUID,
					'3',
					canvasCoordinates[2],
					canvasCoordinates[0],
					{ color, lineDash, lineWidth },
					`${annotationUID}-line-3`
				);
			} else {
				const rectangleUID = '0';
				drawRectSvg(
					svgDrawingHelper,
					annotationUID,
					rectangleUID,
					canvasCoordinates[0],
					canvasCoordinates[3],
					{
						color,
						lineDash,
						lineWidth,
					},
					dataId
				);
			}

			renderStatus = true;

			const options = this.getLinkedTextBoxStyle(styleSpecifier, annotation);
			if (!options?.visibility) {
				data.handles.textBox = {
					hasMoved: false,
					worldPosition: [0, 0, 0],
					worldBoundingBox: {
						topLeft: [0, 0, 0],
						topRight: [0, 0, 0],
						bottomLeft: [0, 0, 0],
						bottomRight: [0, 0, 0],
					},
				};
				continue;
			}

			const textLines = this.configuration.getTextLines(data, targetId);
			if (!textLines || textLines.length === 0) {
				continue;
			}

			if (!data?.handles?.textBox?.hasMoved) {
				const canvasTextBoxCoords = toolsUtilites.drawing.getTextBoxCoordsCanvas(canvasCoordinates);

				data.handles.textBox.worldPosition = viewport.canvasToWorld(canvasTextBoxCoords);
			}

			const textBoxPosition = viewport.worldToCanvas(data.handles.textBox.worldPosition);

			const textBoxUID = '1';
			const boundingBox = drawLinkedTextBoxSvg(
				svgDrawingHelper,
				annotationUID,
				textBoxUID,
				textLines,
				textBoxPosition,
				canvasCoordinates,
				{},
				options
			);

			const { x: left, y: top, width, height } = boundingBox;

			data.handles.textBox.worldBoundingBox = {
				topLeft: viewport.canvasToWorld([left, top]),
				topRight: viewport.canvasToWorld([left + width, top]),
				bottomLeft: viewport.canvasToWorld([left, top + height]),
				bottomRight: viewport.canvasToWorld([left + width, top + height]),
			};
		}

		return renderStatus;
	};
}

export default RectangleROIToolUpdated;
