import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import DialPadTab from './DialPadTab.jsx';
import AvailableAgents from './AvailableAgents.jsx';
import VoiceHistoryTab from './VoiceHistoryTab.jsx';
import VoiceQueueTab from './VoiceQueueTab.jsx';
import DialpadIcon from '@mui/icons-material/Dialpad';
import HailIcon from '@mui/icons-material/Hail';
import HistoryIcon from '@mui/icons-material/History';
import UserAvatar from './UserAvatar';
import { StyledDialPadInput } from '../Cases/casePopOverStyles.js';
import { useLiveChatContext } from './LiveChatContext';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

function VoiceChatTab() {
	const [name, setName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [mute, setMute] = useState(false);
	const metaBreezeDialCall = useBooleanFlagValue('meta-breeze-dial-call');
	const {
		voiceChatTab,
		setVoiceChatTab,
		activeCall,
		setActiveCall,
		device,
		incomingCall,
		getUserByPhoneNumber,
		callerDetail,
		setCallerDetail,
	} = useLiveChatContext();

	const makeCall = async () => {
		if (device) {
			try {
				await getUserByPhoneNumber(phoneNumber);
				const call = await device.connect({
					params: {
						To: phoneNumber,
					},
				});
				setActiveCall(call);
				call.on('disconnect', () => {
					hangupCall();
				});
			} catch (error) {
				console.error('Error making the call:', error);
			}
		}
	};

	const hangupCall = () => {
		if (device) {
			device.disconnectAll();
		}
		setActiveCall(null);
		setMute(false);
		setName('');
		setCallerDetail(null);
	};

	useEffect(() => {
		//Incoming call - phone number
		if (incomingCall) {
			setPhoneNumber(incomingCall?.task?.attributes?.caller ?? incomingCall?.task?.attributes?.from ?? '+1');
		}

		//Incoming call - User name
		if (callerDetail) {
			setName(callerDetail.name[0]?.text);
		} else {
			setName('Unknown');
		}
	}, []);

	useEffect(() => {
		if (callerDetail) {
			setName(callerDetail.name[0]?.text);
		} else {
			setName('Unknown');
		}
	}, [callerDetail]);

	return (
		metaBreezeDialCall && (
			<div>
				{voiceChatTab === 'Dialpad' ? (
					<div>
						{activeCall && (
							<div>
								<div
									style={{
										width: '70px',
										height: '70px',
										backgroundColor: '#FFFFFF',
										borderRadius: '50%',
										margin: '21px auto 0',
									}}
								>
									<UserAvatar size={65} userName={name} />
								</div>
								<Typography
									style={{
										fontFamily: 'Inter',
										fontStyle: 'normal',
										fontSize: '20px',
										fontWeight: '500',
										lineHeight: '24px',
										textAlign: 'center',
									}}
								>
									{name}
								</Typography>
								<Typography
									style={{
										paddingBottom: '10px',
										fontFamily: 'Inter',
										fontStyle: 'normal',
										fontSize: '16px',
										fontWeight: '500',
										lineHeight: '24px',
										textAlign: 'center',
									}}
								>
									Mobile
								</Typography>
							</div>
						)}
						<div
							style={{
								margin: '0 auto',
								width: '370px',
								marginTop: activeCall ? '0' : '11vh',
							}}
						>
							<StyledDialPadInput
								country="us"
								disabled={!!activeCall}
								style={{
									height: '6.2vh',
									borderRadius: '10px',
									backgroundColor: '#F9FAFC',
									fontFamily: 'Inter',
									marginBottom: '2.8vh',
									color: '#4D79EA',
								}}
								value={phoneNumber}
								onChange={value => {
									setPhoneNumber(`+${value}`);
								}}
							/>
						</div>
						<DialPadTab
							activeCall={activeCall}
							hangupCall={hangupCall}
							makeCall={makeCall}
							mute={mute}
							setCurrentTab={setVoiceChatTab}
							setMute={setMute}
							setPhoneNumber={setPhoneNumber}
						/>
					</div>
				) : voiceChatTab === 'History' ? (
					<VoiceHistoryTab />
				) : voiceChatTab === 'Call Queue' ? (
					<VoiceQueueTab />
				) : (
					<AvailableAgents setCurrentTab={setVoiceChatTab} />
				)}
				<div
					style={{
						position: 'absolute',
						left: '214px',
						bottom: '1.8vh',
					}}
				>
					{voiceChatTab !== 'Available Agents' && (
						<div
							style={{
								width: '156px',
								margin: '0 auto',
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<HailIcon
								sx={{
									padding: '0.74vh 9px',
									borderRadius: '5px',
									border: `1px solid ${voiceChatTab === 'Call Queue' ? '#4D79EA' : '#C4C4C466'}`,
									color: voiceChatTab === 'Call Queue' ? '#4D79EA' : '#C4C4C4',
									backgroundColor: voiceChatTab === 'Call Queue' ? '#4D79EA22' : '',
									cursor: 'pointer',
								}}
								onClick={() => {
									setVoiceChatTab('Call Queue');
								}}
							/>
							<HistoryIcon
								sx={{
									padding: '0.74vh 9px',
									borderRadius: '5px',
									border: `1px solid ${voiceChatTab === 'History' ? '#4D79EA' : '#C4C4C4'}`,
									color: voiceChatTab === 'History' ? '#4D79EA' : '#C4C4C4',
									backgroundColor: voiceChatTab === 'History' ? '#4D79EA22' : '',
									cursor: 'pointer',
								}}
								onClick={() => {
									setVoiceChatTab('History');
								}}
							/>

							<DialpadIcon
								sx={{
									padding: '0.74vh 9px',
									borderRadius: '5px',
									border: `1px solid ${voiceChatTab === 'Dialpad' ? '#4D79EA' : '#C4C4C4'}`,
									color: voiceChatTab === 'Dialpad' ? '#4D79EA' : '#C4C4C4',
									backgroundColor: voiceChatTab === 'Dialpad' ? '#4D79EA22' : '',
									cursor: 'pointer',
								}}
								onClick={() => {
									setVoiceChatTab('Dialpad');
								}}
							/>
						</div>
					)}
				</div>
			</div>
		)
	);
}

export default VoiceChatTab;
