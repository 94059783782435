import { init, cursors, DragProbeTool, PlanarRotateTool } from '@cornerstonejs/tools';
import { cursors as _cursors } from './cursors';
import CardiothoracicTool from './CardiothoracicTool';
import CalibrationTool from './CalibrationTool';

const initCornerstoneTools = () => {
	init();

	cursors.registerCursor(
		DragProbeTool.toolName,
		cursors.CursorSVG.Probe.iconContent,
		cursors.CursorSVG.Probe.viewBox
	);

	cursors.registerCursor(
		CardiothoracicTool.toolName,
		cursors.CursorSVG.Length.iconContent,
		cursors.CursorSVG.Length.viewBox
	);

	cursors.registerCursor(
		PlanarRotateTool.toolName,
		cursors.CursorSVG.Rotate.iconContent,
		cursors.CursorSVG.Rotate.viewBox
	);

	cursors.registerCursor(
		CalibrationTool.toolName,
		_cursors[CalibrationTool.toolName].icon,
		_cursors[CalibrationTool.toolName].viewBox
	);
};

export default initCornerstoneTools;
