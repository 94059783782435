import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SearchableMutiSelectCheckboxDropdown({label, placeholder, options, defaultValue, list, index, setListOfNotifications}) {
    const [selectedOption, setSelectedOption] = React.useState(defaultValue);
  return (
    <Autocomplete
      disableCloseOnSelect
      multiple
      getOptionLabel={(option) => option.title}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      options={options}
      renderInput={(params) => (
        <TextField {...params} 
        sx={{margin:'0px'}}
        label={label} 
        placeholder={placeholder} 
        />
      )}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.title}
          </li>
        );
      }}
      style={{ width: '100%' }}
      value = {selectedOption}
      onChange={(event, newValue) => {
        setSelectedOption(newValue);
        const newList = list;
        newList[index].NotificationType = newValue.map(item => item.title).join(',');
        setListOfNotifications(newList);
      }}
    />
  );
}
