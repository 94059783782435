import _ from 'lodash';
import moment from 'moment-timezone';

import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';

export const APPOINTMENT_GROUPS = {
	TODAY: 'TODAY',
	UPCOMING: 'UPCOMING',
	PAST: 'PAST',
};

export const getAppointmentBadgeStyles = status =>
	({
		scheduled: {
			background: '#ECFDF3',
			color: '#067647',
			borderColor: '#ABEFC6',
		},
		pending: {
			background: '#FEF3F2',
			color: '#B42318',
			borderColor: '#FECDCA',
		},
		requested: {
			background: '#FFFAEB',
			color: '#B54708',
			borderColor: '#FEDF89',
		},
		cancelled: {
			background: '#F8FAFC',
			color: '#B42318',
			borderColor: '#FECDCA',
		},
		past: {
			background: '#F8FAFC',
			color: '#364152',
			borderColor: '#E3E8EF',
		},
		ongoing: {
			background: '#FFFAEB',
			color: '#B54708',
			borderColor: '#FEDF89',
		},
	})[status];

/**
 * The mapping is done according to: https://ramsoftinc.atlassian.net/wiki/spaces/OA/pages/292421639/Study+Types+for+Blume+Scheduling
 */
export const scanTypes = [
	{ Name: 'X-RAY', DicomModalities: ['DX', 'CR', 'RG'] },
	{ Name: 'Ultrasound', DicomModalities: ['US', 'IVUS', 'CD', 'DD'] },
	{ Name: 'MRI', DicomModalities: ['MR'] },
	{ Name: 'CT Scan', DicomModalities: ['CT'] },
	{ Name: 'Breast Scan (Mammography)', DicomModalities: ['DG', 'MG'] },
	{ Name: 'Dental X-RAY', DicomModalities: ['IO', 'PX'] },
	{ Name: 'Fluroscopy', DicomModalities: ['RF'] },
	{ Name: 'Eye Imaging', DicomModalities: ['OP'] },
	{ Name: 'Nuclear Medicine', DicomModalities: ['NM'] },
	{ Name: 'Endoscopy', DicomModalities: ['ES'] },
	{ Name: 'Angiography', DicomModalities: ['XA'] },
	{ Name: 'PET/SPECT Scan', DicomModalities: ['PT', 'ST', 'PET', 'SPECT'] },
	{ Name: 'Biomagnetic Imaging', DicomModalities: ['BI'] },
	{ Name: 'Pathology', DicomModalities: ['GM', 'SM'] },
];

export const getPatientFriendlyModality = dicomModality => {
	const scanType = scanTypes.find(scan => scan.DicomModalities.includes(dicomModality));
	if (scanType) {
		return scanType.Name;
	}
	return '';
};

export const statusMapping = {
	proposed: 'requested', // Ordered
	pending: 'pending', // Scheduled
	waitlist: 'pending', // Waitlist*
	booked: 'scheduled', // Confirmed
	arrived: 'scheduled', // Arrived
	'checked-in': 'scheduled', // Ready for scan
	noshow: 'cancelled', // No Show
	cancelled: 'cancelled', // Cancelled
	fulfilled: 'completed', // Completed
};

/**
 * Creates an appointment object with healthcare information.
 * @param {Object} params - The parameters object.
 * @param {Object} params.resource - The resource object representing the appointment.
 * @param {Object} params.healthCareInfo - The healthcare information object.
 * @param {string} params.healthCareServiceId - The ID of the healthcare service which healthcare information is passed.
 * @returns {Object} The appointment object with healthcare information.
 */
// prettier-ignore
export const createAppointmentWithHealthcareInfo = ({ resource, healthCareInfo, healthCareServiceId }) => {
	try {
		const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		const pfmExtUrl = fhirExtensionUrls.appointment.patientFriendlyModality;
		const blumeAttachmentExtUrl = fhirExtensionUrls.appointment.blumeAttachment;
		const blumeNotesExtUrl = fhirExtensionUrls.appointment.blumeNotes;
		const parkIndexExtUrl = fhirExtensionUrls.appointment.parkingIndex;
		const isPublicBookingExtUrl = fhirExtensionUrls.appointment.isPublicBooking;
		const managingOrgExtUrl = fhirExtensionUrls.common.managingOrganization;
		const selfSchedulingSettingExtUrl = fhirExtensionUrls.appointment.selfScheduling;
		const { extension, participant } = resource;
		const organizationTimeZoneIANA = healthCareInfo?.timezoneIANA || null;
		
		let startTimeInOrgTimeZone = null;
		let endTimeInOrgTimeZone = null;
		
		// if not user time zone is same as org show timezone and convert to org timzoen
		if (organizationTimeZoneIANA && clientTimezone !== organizationTimeZoneIANA) {
			startTimeInOrgTimeZone = moment(resource.start).tz(organizationTimeZoneIANA).format('YYYY-MM-DDTHH:mm:ss');
			endTimeInOrgTimeZone = moment(resource.end).tz(organizationTimeZoneIANA).format('YYYY-MM-DDTHH:mm:ss');
		}
		
		const patientFriendlyModality = _.get(_.find(extension, { url: pfmExtUrl }), 'valueString', null);
		const modalityFromOAIAppointment = _.get(_.find(extension, item => item.url === 'modality'), 'extension[0].valueCoding.code', null);
		const attachments = _.get(_.find(extension, { url: blumeAttachmentExtUrl }), 'extension', null);
		const patientName = _.get(participant.find(({ actor }) => actor.reference.toLowerCase().includes('patient')), 'actor.display', null);
		const blumeNotes = _.get(_.find(extension, { url: blumeNotesExtUrl }), 'valueString', null);
		const isPublicBooking = _.get(_.find(extension, { url: isPublicBookingExtUrl }), 'valueBoolean', null);
		const isParked = !!_.get(_.find(extension, { url: parkIndexExtUrl }), 'valueString', null);
		const managingOrganization = _.get(_.find(extension, { url: managingOrgExtUrl }), 'valueString', null);
		const patientId = _.get(participant.find(({ actor }) => actor.reference.toLowerCase().includes('patient')), 'actor.reference', null)?.split('/')[1];
		const studyId = _.get(resource?.supportingInformation, '[0].id', null);
		const {
			[fhirExtensionUrls?.selfSchedulingSettings?.allowReschedule]: allowReschedule,
			[fhirExtensionUrls?.selfSchedulingSettings?.allowCancel]: allowCancel,
		} = _.mapValues(
			_.keyBy(_.get(_.find(extension?.reverse(), { url: selfSchedulingSettingExtUrl }), 'extension', []), 'url'),
			'valueBoolean'
		);

		const appointment = {
			attachments,
			dateEnd: endTimeInOrgTimeZone ? new Date(endTimeInOrgTimeZone) : new Date(resource.end),
			dateStart: startTimeInOrgTimeZone ? new Date(startTimeInOrgTimeZone) : new Date(resource.start),
			description: resource.description,
			id: resource.id,
			organizationTimeZone: healthCareInfo?.timezone || null,
			origin: patientFriendlyModality ? 'Blume' : 'OAI',
			status: statusMapping[resource.status],
			type: patientFriendlyModality || getPatientFriendlyModality(modalityFromOAIAppointment),
			organizationTimeZoneIANA,
			patient: { name: patientName },
			blumeNotes,
			isPublicBooking,
			isParked,
			patientId,
			studyId,
			managingOrganization,
			internalOrganizationID: null,
			organization: null,
			phone: null,
			address: null,
			allowReschedule,
			allowCancel,
			resource, // Passing full appointment resource just in case
		};

		if (!healthCareServiceId && !healthCareInfo) return appointment;

		if (!appointment.type) {
			appointment.type = healthCareInfo?.modality ? getPatientFriendlyModality(healthCareInfo.modality) : null;
		}

		// Copy values from healthcare object to appointment with same keys
		Object.assign(appointment, _.pick(healthCareInfo, ['organization', 'internalOrganizationID', 'phone', 'address']));

		return appointment;
	} catch (err) {
		console.error('Failed to create appointment. Please verify that the healthcare information or appointment object is correctly formatted in the createAppointmentWithHealthcareInfo function.');
		return false;
	}
};
