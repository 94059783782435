import React, { useState } from 'react';
import { BubbleMenu } from '@tiptap/react';

import getActiveColor from '@worklist-2/worklist/src/DocumentViewerV3/features/DocumentViewer/Viewports/ViewportTiptap/ViewportTipTapEditor/utils/getActiveColor';
import getActiveBackgroundColor from '@worklist-2/worklist/src/DocumentViewerV3/features/DocumentViewer/Viewports/ViewportTiptap/ViewportTipTapEditor/utils/getActiveBackgroundColor';

import ColorDrawer from '../ColorDrawer/ColorDrawer';

import {
	AlignCenterIcon,
	AlignLeftIcon,
	AlignRightIcon,
	ArrowBottomIcon,
	ArrowTopIcon,
	BoldIcon,
	CrossOutIcon,
	HighlightingIcon,
	ItalicIcon,
	SeparateLine,
	UnderlineIcon,
} from '../../../../../../assets/icons';

import styles from './TableActionPanel.module.scss';

const TableActionPanel = ({ editor }) => {
	const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
	const [isBackgroundColorPickerOpen, setIsBackgroundColorPickerOpen] = useState(false);
	const [isAlignByOpen, setIsAlignByOpen] = useState(false);
	const [currentAlignBy, setCurrentAlignBy] = useState('left');

	const activeColor = getActiveColor(editor);
	const activeBackgroundColor = getActiveBackgroundColor(editor);

	const onColorPicker = () => {
		setIsColorPickerOpen(!isColorPickerOpen);
	};

	const onHighlightPicker = () => {
		setIsBackgroundColorPickerOpen(!isBackgroundColorPickerOpen);
	};

	const onBold = () => {
		editor.chain().focus().toggleBold().run();
	};

	const onItalic = () => {
		editor.chain().focus().toggleItalic().run();
	};

	const onUnderline = () => {
		editor.chain().focus().toggleUnderline().run();
	};

	const onStrike = () => {
		editor.chain().focus().toggleStrike().run();
	};

	const onAlign = position => {
		editor.chain().focus().setTextAlign(position).run();
		setCurrentAlignBy(position);
		setIsAlignByOpen(false);
	};

	return (
		<BubbleMenu editor={editor} tippyOptions={{ duration: 200 }}>
			<div className={styles.actionPanel} data-testid="actionPanel">
				<div className={styles.colorPicker} onClick={onColorPicker}>
					<div className={styles.activeColor} />
					{isColorPickerOpen ? <ArrowTopIcon /> : <ArrowBottomIcon />}
				</div>
				<div className={styles.separateLine1} data-testid="separateLine">
					<SeparateLine />
				</div>
				<div className={styles.actionItemBold} data-testid="boldIcon" onClick={onBold}>
					<BoldIcon />
				</div>
				<div className={styles.actionItem} data-testid="italicIcon" onClick={onItalic}>
					<ItalicIcon />
				</div>
				<div className={styles.actionItem} data-testid="underlineIcon" onClick={onUnderline}>
					<UnderlineIcon />
				</div>
				<div className={styles.actionItem} data-testid="crossOutIcon" onClick={onStrike}>
					<CrossOutIcon />
				</div>
				<div className={styles.actionItem} data-testid="highlightingIcon" onClick={onHighlightPicker}>
					<HighlightingIcon />
				</div>
				<div className={styles.separateLine2}>
					<SeparateLine />
				</div>
				<div
					className={`${styles.actionItemAlignBox} ${isAlignByOpen ? styles.actionItemAlignBoxActive : ''}`}
					data-testid="alignBy"
					onClick={() => setIsAlignByOpen(!isAlignByOpen)}
				>
					<div className={styles.actionItemAlignIcon}>
						{currentAlignBy === 'left' ? (
							<AlignLeftIcon />
						) : currentAlignBy === 'right' ? (
							<AlignRightIcon />
						) : (
							<AlignCenterIcon />
						)}
					</div>
					{isAlignByOpen ? <ArrowTopIcon /> : <ArrowBottomIcon />}
					{isAlignByOpen && (
						<div className={styles.alignBy}>
							<div
								className={`${styles.alignByType} ${
									currentAlignBy === 'left' ? styles.alignByTypeActive : ''
								}`}
								onClick={() => onAlign('left')}
							>
								<AlignLeftIcon />
							</div>
							<div
								className={`${styles.alignByType} ${
									currentAlignBy === 'center' ? styles.alignByTypeActive : ''
								}`}
								onClick={() => onAlign('center')}
							>
								<AlignCenterIcon />
							</div>
							<div
								className={`${styles.alignByType} ${
									currentAlignBy === 'right' ? styles.alignByTypeActive : ''
								}`}
								onClick={() => onAlign('right')}
							>
								<AlignRightIcon />
							</div>
						</div>
					)}
				</div>

				<ColorDrawer
					activeBackgroundColor={activeBackgroundColor}
					activeColor={activeColor}
					editor={editor}
					isShownBackgroundColorPicker={isBackgroundColorPickerOpen}
					isShownColorPicker={isColorPickerOpen}
				/>
			</div>
		</BubbleMenu>
	);
};

export default TableActionPanel;
