import React, { useState, useEffect } from 'react';

import { Box, Typography } from '@mui/material';

import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';

import { useCrmContext } from '../../../../../../context/CRMContext';

import { TabPanel } from '../../../../Cases/casePopOverStyles';
import DeviceItem from '../../OrganizationSteps/Device/DeviceItem';

import { getDevicesWithStations } from '../../OrganizationSteps/Device/utils';

import createStyles from './styles';

const Device = ({ data, accountData, setDbClickOrganization, setStep }) => {
	const [devices, setDevices] = useState([]);
	const [stationsData, setStationsData] = useState({});

	const classes = createStyles();

	const { toggleDetails } = useCrmContext();

	useEffect(() => {
		if (data) {
			const [entityMap, filteredDevices] = getDevicesWithStations(data);
			setStationsData(entityMap);
			setDevices(filteredDevices);
		}
	}, [data]);

	const onDeviceOpen = (event, type, device) => {
		event.stopPropagation();
		const organizationData = {
			id: accountData.Organization.id,
			display: accountData.Organization.name,
			reference: `/Organization/${accountData.Organization.id}`,
		};

		toggleDetails({
			data: {
				accountData,
				organizationData,
				setOrganizationData: setDbClickOrganization,
				step: 'device',
				setStep,
				sidebarMode: {
					device,
					mode: type,
					entity: 'device',
				},
			},
			tag: 'setDeviceStationModal',
		});
	};

	const onStationOpen = (event, type, station, associatedDevice) => {
		event.stopPropagation();
		const organizationData = {
			id: accountData.Organization.id,
			display: accountData.Organization.name,
			reference: `/Organization/${accountData.Organization.id}`,
		};

		toggleDetails({
			data: {
				accountData,
				organizationData,
				setOrganizationData: setDbClickOrganization,
				step: 'device',
				setStep,
				sidebarMode: {
					associatedDevice,
					station,
					mode: type,
					entity: 'station',
				},
			},
			tag: 'setDeviceStationModal',
		});
	};

	return (
		<TabPanel>
			<Box className="header">
				<Box className="left">
					<Box className="colored-icon" style={{ backgroundColor: 'rgba(77, 168, 234, 0.2)' }}>
						<DeviceHubOutlinedIcon sx={classes.deviceHubIcon} />
					</Box>
					<Typography component="h2">Device</Typography>
					<Box sx={classes.plusIconBox} onClick={e => onDeviceOpen(e, 'add')}>
						<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
							<g clipPath="url(#clip0_1022_25919)">
								<path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#4D79EA" />
							</g>
							<defs>
								<clipPath id="clip0_1022_25919">
									<rect fill="white" height="24" width="24" />
								</clipPath>
							</defs>
						</svg>
					</Box>
				</Box>
			</Box>
			<Box className="body">
				{devices.length > 0 ? (
					devices.map(device => (
						<DeviceItem
							key={device.id}
							currentStation={{}}
							device={device}
							location="accountFullScreen"
							setCurrentStation={() => {}}
							stations={stationsData}
							onDeviceOpen={onDeviceOpen}
							onStationOpen={onStationOpen}
						/>
					))
				) : (
					<Box sx={classes.noResultBox}>
						<Typography sx={classes.noResultLabel}>No Result</Typography>
					</Box>
				)}
			</Box>
		</TabPanel>
	);
};

export default Device;
