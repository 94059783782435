const useGoogleApi = () => {
	const extractAddressComponents = ({ address_components, geometry }) => {
		const address = {
			address1: '',
			postcode: '',
			locality: '',
			state: '',
			country: '',
			latitude: geometry.location.lat(),
			longitude: geometry.location.lng(),
		};

		address_components.forEach(component => {
			const componentType = component.types[0];

			switch (componentType) {
				case 'street_number':
					address.address1 = `${component.long_name} ${address.address1}`;
					break;
				case 'route':
					address.address1 += component.short_name;
					break;
				case 'postal_code':
					address.postcode = `${component.long_name}${address.postcode}`;
					break;
				case 'postal_code_suffix':
					address.postcode = `${address.postcode}-${component.long_name}`;
					break;
				case 'locality':
					address.locality = component.long_name;
					break;
				case 'administrative_area_level_1':
					address.state = component.short_name;
					break;
				case 'country':
					address.country = component.long_name;
					break;
				default:
					break;
			}
		});

		return address;
	};

	const handleServiceResponse = (resolve, reject) => (result, status) => {
		if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
			reject(status);
		} else {
			resolve(result);
		}
	};

	const getPlacePredictions = query => {
		const service = new window.google.maps.places.AutocompleteService();
		return new Promise((resolve, reject) => {
			service.getPlacePredictions({ input: query, types: ['address'] }, handleServiceResponse(resolve, reject));
		});
	};

	const getPlaceDetails = async placeId => {
		const service = new window.google.maps.places.PlacesService(document.createElement('div'));
		return new Promise((resolve, reject) => {
			service.getDetails(
				{ placeId, fields: ['address_components', 'geometry'] },
				handleServiceResponse(resolve, reject)
			);
		});
	};

	const getDetailedPlaceInfo = async prediction => {
		try {
			const placeId = prediction.place_id;
			const details = await getPlaceDetails(placeId);
			return extractAddressComponents(details);
		} catch (error) {
			console.error('Error fetching place details:', error);
			return {};
		}
	};

	const getSearchPlaces = async query => {
		try {
			return await getPlacePredictions(query);
		} catch (error) {
			console.error('Error fetching place predictions:', error);
			return [];
		}
	};

	return { getSearchPlaces, getDetailedPlaceInfo };
};

export default useGoogleApi;
