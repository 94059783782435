import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';
import axios from 'axios';
import CheckVideoImagesTransferSyntax from '../utils/CheckVideoImagesTransferSyntax';
import CheckAndUpdateInstanceMetaData from '../utils/CheckAndUpdateInstanceMetaData';
import {
	buildDicomURL,
	buildCacheURL,
	getInstanceSOPUID,
	getSeriesModality,
	GetModalityLoadTime,
} from '../utils/utils';

export default async function createImageIdsAndCacheMetaData({
	studyInstanceUID,
	seriesInstanceUID,
	headers,
	__config,
	series,
	instances,
	cacheLinks,
	useCornerstone,
	isBlumeApp,
	creatorUserId,
	isUploaded,
}) {
	let _cacheLinks = cacheLinks ?? [];
	const _availableLinks = [];
	const NumberOfFramesTag = '00280008';

	const TransferSyntaxTag = '00020010';

	const NumberOfRowsTag = '00280010';

	const NumberOfColumnsTag = '00280011';

	const _instances = [];

	const _resolveFns = [];

	const checkAvailableLink = async (instanceMetaData, frameNumber = 1) => {
		if (_cacheLinks.length > 0) {
			const promises = _cacheLinks.map(
				link =>
					new Promise(async resolve => {
						_resolveFns.push(resolve);
						const localCacheUrl = buildCacheURL({
							cacheUrl: link,
							studyInstanceUID,
							seriesInstanceUID,
							InstanceSOPUID: getInstanceSOPUID(instanceMetaData),
							frameNumber,
							isChecking: true,
						});

						const TIMEOUT = GetModalityLoadTime(getSeriesModality(series));

						await axios
							.get(localCacheUrl, {
								headers,
								timeout: TIMEOUT,
							})
							.then(response => {
								if (response.status == 200) {
									_availableLinks.push(link);
									_resolveFns.forEach(element => {
										element();
									});
								} else {
									resolve();
								}
							})
							.catch(() => {
								console.error(`Link ${link} not available or too busy.`);
								resolve();
							});
					})
			);

			await Promise.all(promises);
			_cacheLinks = [];
		}
	};

	const getImageId = async (instanceMetaData, frameNumber = 1) => {
		let imageId;
		await checkAvailableLink(instanceMetaData, frameNumber);

		if (_availableLinks.length > 0) {
			imageId = buildCacheURL({
				cacheUrl: _availableLinks[0],
				studyInstanceUID,
				seriesInstanceUID,
				InstanceSOPUID: getInstanceSOPUID(instanceMetaData),
				frameNumber,
			});
		} else {
			imageId = buildDicomURL({
				__config,
				studyInstanceUID,
				seriesInstanceUID,
				InstanceSOPUID: getInstanceSOPUID(instanceMetaData),
				frameNumber,
				isBlumeApp,
			});
		}

		if (useCornerstone) {
			cornerstoneWADOImageLoader.wadors.metaDataManager.add(imageId, instanceMetaData);
		}

		return imageId;
	};

	function IsInstanceDicomDocument(instanceMetaData) {
		let isDocument = false;

		const NumberOfRows = instanceMetaData[NumberOfRowsTag]?.Value[0];
		const NumberOfColumns = instanceMetaData[NumberOfColumnsTag]?.Value[0];

		// Image has Columns ad Rows
		if (NumberOfRows === undefined && NumberOfColumns === undefined) {
			isDocument = true;
		}
		return isDocument;
	}

	for (let instanceMetaData of instances) {
		instanceMetaData = CheckAndUpdateInstanceMetaData(instanceMetaData);

		// Video DICOM Image has only one frame
		const checkVideo = CheckVideoImagesTransferSyntax(instanceMetaData[TransferSyntaxTag].Value[0]);
		if (!checkVideo && instanceMetaData[NumberOfFramesTag]) {
			let framesIndex = parseInt(instanceMetaData[NumberOfFramesTag].Value[0]);

			if (!framesIndex) {
				framesIndex = 1;
			}

			for (let i = 1; i <= framesIndex; i++) {
				if (i === 1) {
					// Check Only for First Frame
					const isDicomDocument = IsInstanceDicomDocument(instanceMetaData);
					if (!isDicomDocument) {
						_instances.push(await getImageId(instanceMetaData, i));
					}
				} else {
					_instances.push(await getImageId(instanceMetaData, i));
				}
			}
		} else {
			const isDicomDocument = IsInstanceDicomDocument(instanceMetaData);
			if (!isDicomDocument) {
				_instances.push(await getImageId(instanceMetaData));
			}
		}
	}

	return _instances;
}
