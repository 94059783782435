// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wVLOgrJSKflwlfo2MBBJ{display:flex;flex-direction:column;justify-content:center;align-items:center;height:75px;margin:40px 60px;border-radius:20px;border:1px dashed #4d79ea;background:rgba(77,121,234,.05)}.wVLOgrJSKflwlfo2MBBJ span{margin-top:5px;text-align:center;font-family:Inter,serif;font-size:14px;font-style:normal;font-weight:500;line-height:normal}.emHY71ut5grVmRX0_ugj{color:#fff}.eVF7yY_VnFMOkA2vU29d{color:rgba(0,0,0,.8)}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/common/MainArea/Draft/InsertTextBlock/InsertTextBlockPlaceholder.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,gBAAA,CAEA,kBAAA,CACA,yBAAA,CACA,+BAAA,CAEA,2BACE,cAAA,CACA,iBAAA,CACA,uBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CAIJ,sBACE,UAAA,CAGF,sBACE,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"insertTextBlockPlaceholder": "wVLOgrJSKflwlfo2MBBJ",
	"insertTextBlockPlaceholderOAI": "emHY71ut5grVmRX0_ugj",
	"insertTextBlockPlaceholderBlume": "eVF7yY_VnFMOkA2vU29d"
};
export default ___CSS_LOADER_EXPORT___;
