import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const SelectedResourcesButton = ({
	title,
	icon: Icon,
	onClick,
	params,
	minWidth = '20px',
	iconSize = '30px',
}) => {
	const handleOver = event => {
		event.currentTarget.classList.add('hovered');
	};

	const handleLeave = event => {
		event.currentTarget.classList.remove('hovered');
	};

	return (
		<Box
			data-testid="selected-resources-button"
			sx={{
				display: 'flex',
				alignItems: 'center',
				columnGap: '5px',
				borderRadius: '20px',
				padding: '2px 10px',
				transition: 'all .7s ease',
				overflow: 'hidden',
				maxWidth: minWidth,
				cursor: 'pointer',

				'&.hovered': {
					backgroundColor: '#2975B2',
					width: 'fit-content',
					maxWidth: '150px',
				},

				...params?.btnStyles,
			}}
			onClick={onClick}
			onMouseEnter={handleOver}
			onMouseLeave={handleLeave}
		>
			<Icon
				data-testid="example-icon"
				style={{ overflow: 'visible' }}
				sx={{
					color: '#fff',
					width: iconSize,
					height: iconSize,
					...params?.iconStyles,
				}}
				variant="outlined"
			/>

			<Typography
				sx={{
					color: '#fff',
					fontSize: '10px',
					whiteSpace: 'nowrap',
					padding: '0 2px',
					fontWeight: '700',
					...params?.titleStyles,
				}}
			>
				{params?.plainTitle ? title : title.toUpperCase()}
			</Typography>
		</Box>
	);
};
