import { isString } from 'lodash';
import { findMetadataTag } from '@rs-ui/views/ImageViewerView3D/utils/utils';
import { MG_VIEW_CODES } from '@rs-ui/views/ImageViewerView3D/contexts/consts/consts';

export const getLateralityViewCodeFromDescription = description => {
	const result = {
		laterality: null,
		viewCode: null,
	};

	if (!isString(description)) {
		return result;
	}

	const values = [
		{
			viewCode: MG_VIEW_CODES.MG_RCC,
			laterality: 'R',
			filters: ['RCC', 'R CC'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_RFB,
			laterality: 'R',
			filters: ['RFB', 'R FB'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_RISO,
			laterality: 'R',
			filters: ['RISO', 'R ISO'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_RLMO,
			laterality: 'R',
			filters: ['RLMO', 'R LMO'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_RLM,
			laterality: 'R',
			filters: ['RLM', 'R LM'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_RMLO,
			laterality: 'R',
			filters: ['RMLO', 'R MLO'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_RML,
			laterality: 'R',
			filters: ['RML', 'R ML'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_RSIO,
			laterality: 'R',
			filters: ['RSIO', 'R SIO'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_RXCCL,
			laterality: 'R',
			filters: ['RXCCL', 'R XCCL'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_RXCCM,
			laterality: 'R',
			filters: ['RXCCM', 'R XCCM'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_RXCC,
			laterality: 'R',
			filters: ['RXCC', 'R XCC'],
		},

		{
			viewCode: MG_VIEW_CODES.MG_LCC,
			laterality: 'L',
			filters: ['LCC', 'L CC'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_LFB,
			laterality: 'L',
			filters: ['LFB', 'L FB'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_LISO,
			laterality: 'L',
			filters: ['LISO', 'L ISO'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_LLMO,
			laterality: 'L',
			filters: ['LLMO', 'L LMO'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_LLM,
			laterality: 'L',
			filters: ['LLM', 'L LM'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_LMLO,
			laterality: 'L',
			filters: ['LMLO', 'L MLO'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_LML,
			laterality: 'L',
			filters: ['LML', 'L ML'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_LSIO,
			laterality: 'L',
			filters: ['LSIO', 'L SIO'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_LXCCL,
			laterality: 'L',
			filters: ['LXCCL', 'L XCCL'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_LXCCM,
			laterality: 'L',
			filters: ['LXCCM', 'L XCCM'],
		},
		{
			viewCode: MG_VIEW_CODES.MG_LXCC,
			laterality: 'L',
			filters: ['LXCC', 'L XCC'],
		},
	];

	const matchedValue = values.find(value => value.filters.find(filter => description.toUpperCase().includes(filter)));

	if (matchedValue) {
		result.laterality = matchedValue.laterality;
		result.viewCode = matchedValue.viewCode;
	}

	return result;
};

export const getImageLaterality = (metadata, seriesMetadata) => {
	const keys = [
		'0019108a',
		'00200062',
		'00200060',
		'52009229',
		'00180015',
		'0008103E',
		'00185101',
		'00181030',
		'00204000',
	];

	for (const key of keys) {
		let value;

		if (key === '0019108a') {
			value = findMetadataTag(metadata, key)?.Value?.[0]?.['00191087']?.Value?.[0]?.[0];
		} else if (key === '52009229') {
			value = metadata?.['52009229']?.Value?.[0]?.['00209071']?.Value?.[0]?.['00209072']?.Value?.[0];
		} else if (key === '00180015' || key === '00185101') {
			value = metadata?.[key]?.Value?.[0]?.[0];
		} else if (key === '0008103E') {
			value = getLateralityViewCodeFromDescription(seriesMetadata?.[key]?.Value?.[0]).laterality;
		} else if (key === '00181030' || key === '00204000') {
			value = getLateralityViewCodeFromDescription(seriesMetadata?.[key]?.Value?.[0]).laterality;
		} else {
			value = metadata?.[key]?.Value?.[0];
		}

		if (value && (value === 'L' || value === 'R')) {
			return value;
		}
	}
};
