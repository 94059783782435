// core
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTreeContext } from '@worklist-2/ui/src/context/TreeContext';

// mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DownloadIcon from '@mui/icons-material/DownloadOutlined';
import AlbumIcon from '@mui/icons-material/Album';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import SendIcon from '@mui/icons-material/SendOutlined';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

// components
import Toast from '../../../Toast';
import UserSelector from '../../../UserSelector';
import { SelectedResourcesButton } from '../SelectedResourcesTooltip/SelectedResourcesBtn';
import { AlertDialog } from '@rs-components/Dialog/AlertDialog';
// libraries
import { useTranslation } from 'react-i18next';
import extensionUrls from '@worklist-2/core/src/fhir/extension/fhirExtensionUrls';
import moment from 'moment';

import {
	searchScopes,
	useFhirDataLoader,
	useConfig,
	useToast,
	useSelection,
	formatBytes,
	isUnixSystem,
	useAuth,
} from '@worklist-2/core/src';
import { DownloadDiscImageBurner, DownloadMultipleStudies, DownloadSingleStudy } from '@rs-ui/views/utils/downloader';
import { downloadMultipleStudiesDiscImageBurner } from '@rs-ui/views/utils/downloadDiscImageBurner';
import findFinalDocuments from '@rs-ui/views/ImageViewerView3D/api/findFinalDocuments';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const VerticalDivider = () => (
	<Box
		sx={{
			borderRight: '1px solid',
			borderRightColor: 'icon.primary',
			height: '15px',
			margin: '0 10px',
		}}
	/>
);

const SelectedStudiesToolbar = ({ onSendClick, onAddToTFClick, onRemoveStudiesFromTF }) => {
	const [, setShowClearAll] = useState(false);
	const [open, setOpen] = useState(false);
	const wonIvIsoDownload = useBooleanFlagValue('WON-IV-ISO-DOWNLOAD');
	const wonIvDownloadmanager = useBooleanFlagValue('WON-IV-DOWNLOADMANAGER');
	const maven2061TeachingFolder = useBooleanFlagValue('maven-2061-teaching-folder');
	const crossGetPrivilegeOnDemand = useBooleanFlagValue('cross-get-privilege-on-demand');
	const { hasPermissionByOrganization, hasPermissionByOrganizationAsync } = useAuth();
	const { setToastUtility, toastOpen, toastMessage, handleToastClose } = useToast();
	// Download progress file info
	const [progressFileLoaded, setProgressFileLoaded] = useState({
		numOfFile: 0,
		fileLoaded: 0,
	});

	// Toast message display duration
	const [duration, setDuration] = useState(null);
	const { selectedStudies, resetStudiesSelection } = useSelection();

	const selectedCount = selectedStudies?.length;
	const { t } = useTranslation(['workList', 'downloader']);

	// Context values
	const { userCanEdit } = useTreeContext();

	useEffect(() => {
		setShowClearAll(selectedStudies?.length > 1);
	}, [selectedStudies]);

	/**
	 * Show real progress information
	 */
	useEffect(() => {
		if (toastOpen) {
			setToastUtility(
				true,
				`${t('Exporting')} ${progressFileLoaded.numOfFile} ${t('studies')} (downloaded : ${
					progressFileLoaded.fileLoaded
				})`
			);
		}
	}, [progressFileLoaded]);

	const clearAllStudies = () => {
		resetStudiesSelection();
	};

	const __config = useConfig();

	/**
	 * downloadSingleFile
	 * @param {*} data
	 */
	const downloadSingleFile = (data, anonymize) => {
		const url = `${__config.data_sources.dicom_web}/export/studies/${data
			.map(({ dicomUid }) => dicomUid)
			.join('%2C')}?internalmanagingorganizationid=${data
			.map(({ internalManagingOrganizationId }) => internalManagingOrganizationId)
			.join('%2C')}&FileType=application%2Fzip&anonymize=${anonymize}`;

		axios
			.get(url, {
				responseType: 'blob',
				headers: { 'unix-os': isUnixSystem() }, // Zip max compatibility
				onDownloadProgress: progressEvent => {
					setProgressFileLoaded({
						numOfFile: data.length,
						fileLoaded: formatBytes(progressEvent.loaded),
					});
				},
			})
			.then(resp => {
				if (resp.status == 200) {
					downloadBlob(resp.data, resp.data.type, `${data[0].accessionNumber}_${data[0].dicomUid}`);
				}
			})
			.catch(e => {
				setDuration(5000);
				showErrorDownloadMsg();
			});
	};

	/**
	 * downloadMultipleFile
	 * @param {*} data
	 */
	const downloadMultipleFile = (data, anonymize) => {
		const studyIds = data.map(({ dicomUid }) => dicomUid);
		const internalManagingOrganizationIds = data.map(
			({ internalManagingOrganizationId }) => internalManagingOrganizationId
		);
		const url = `${__config.data_sources.dicom_web}/export/studies?FileType=application%2Fzip&anonymize=${anonymize}`;
		// Using post for request export multiple files
		axios
			.post(
				url,
				{
					studyIds,
					internalManagingOrganizationIds,
				},
				{
					responseType: 'blob',
					headers: { 'unix-os': isUnixSystem() }, // Zip max compatibility
					onDownloadProgress: progressEvent => {
						setProgressFileLoaded({
							numOfFile: data.length,
							fileLoaded: formatBytes(progressEvent.loaded),
						});
					},
				}
			)
			.then(resp => {
				if (resp.status == 200) {
					downloadBlob(resp.data, resp.data.type, `${data.length} Studies - ${generateDateString()}`);
				}
			})
			.catch(e => {
				showErrorDownloadMsg();
			});
	};

	/**
	 * downloadBlob
	 * @param {*} resData
	 * @param {*} dataType
	 * @param {*} fileName
	 * @returns
	 */
	const downloadBlob = (resData, dataType, fileName) => {
		if (dataType !== 'application/zip') {
			showErrorDownloadMsg();
			return;
		}

		const href = window.URL.createObjectURL(resData);
		const anchorElement = document.createElement('a');
		anchorElement.href = href;
		anchorElement.download = `${fileName}.zip`;
		document.body.appendChild(anchorElement);
		anchorElement.click();
		document.body.removeChild(anchorElement);
		window.URL.revokeObjectURL(href);
	};

	/**
	 * showErrorDownloadMsg
	 */
	const showErrorDownloadMsg = label => {
		setDuration(5000);
		setToastUtility(true, `${t(label || 'No images available for download')}`);
	};

	/**
	 * generateDateString
	 * @returns dd-mm-yyyy hh:mm:ss
	 */
	const generateDateString = () => moment(new Date()).format('MM-DD-YYYY_HH-mm-ss-A');

	const onDownloadClick = async anonymize => {
		const data = selectedStudies;
		setDuration(null);

		const studyIds = data.map(({ dicomUid }) => dicomUid);
		const hasDuplicates = new Set(studyIds).size !== studyIds.length;
		if (hasDuplicates) {
			setToastUtility(true, t(`Cannot download same studyUID`, { ns: 'downloader' }));
			return false;
		}

		await findFinalDocuments({
			__config,
			patientsInfo: [...new Set(data?.map(({ subject }) => subject.id))],
			refData: data,
		});

		const atLeastOneImageOrFinalReport = data.every(
			item => item.rawData.numImages > 0 || item.rawData.numFinalReports > 0
		);
		if (!atLeastOneImageOrFinalReport) {
			showErrorDownloadMsg('workListDrawer.selectedEmptyOrder');
		} else if (!wonIvDownloadmanager) {
			setToastUtility(
				true,
				`${t('Exporting')} ${data.length} ${t(
					'studies. The download should start automatically within a few seconds.'
				)}`
			);

			if (data.length > 1) {
				downloadMultipleFile(data, anonymize);
			} else {
				downloadSingleFile(data, anonymize);
			}
		} else {
			setDuration(5000);
			if (data.length > 1) {
				const internalManagingOrganizationIds = data.map(
					({ internalManagingOrganizationId }) => internalManagingOrganizationId
				);
				const download = new DownloadMultipleStudies({
					uniqueId: studyIds.join(', '),
					prefixUrl: __config.data_sources.dicom_web,
					studyIds,
					internalManagingOrganizationIds,
					anonymize,
				}).start();

				if (download.enqueueStatus === 'ENQUEUED') {
					setToastUtility(
						true,
						`${t('Exporting', { ns: 'downloader' })} ${data.length} ${t(
							'studies. The download should start automatically within a few seconds.',
							{ ns: 'downloader' }
						)}`
					);
				} else if (download.enqueueStatus === 'ALREADY-ENQUEUED') {
					setToastUtility(true, t(`This is being downloaded already.`, { ns: 'downloader' }));
				}
			} else {
				const { dicomUid, internalManagingOrganizationId } = data[0] || {};
				const download = new DownloadSingleStudy({
					uniqueId: dicomUid,
					prefixUrl: __config.data_sources.dicom_web,
					dicomUid,
					internalManagingOrganizationId,
					anonymize,
				}).start({ displayName: dicomUid });

				if (download.enqueueStatus === 'ENQUEUED') {
					setToastUtility(
						true,
						`${t('Exporting', { ns: 'downloader' })} ${data.length} ${t(
							'studies. The download should start automatically within a few seconds.',
							{ ns: 'downloader' }
						)}`
					);
				} else if (download.enqueueStatus === 'ALREADY-ENQUEUED') {
					setToastUtility(true, t(`This is being downloaded already.`, { ns: 'downloader' }));
				}
			}
		}
		handleDownloadCardClose();
		resetStudiesSelection();
	};

	const onBurnToDiscClick = async () => {
		const data = selectedStudies;
		setDuration(null);

		const studyIds = data?.map(({ dicomUid }) => dicomUid);
		const hasDuplicates = new Set(studyIds).size !== studyIds.length;
		if (hasDuplicates) {
			setToastUtility(true, t(`Cannot download same studyUID`, { ns: 'downloader' }));
			return false;
		}

		const patientsInfo = [...new Set(data?.map(({ subject }) => subject.id))];
		const internalManagingOrganizationIds = data.map(
			({ internalManagingOrganizationId }) => internalManagingOrganizationId
		);
		const body = { studyIds, internalManagingOrganizationIds };

		await findFinalDocuments({ __config, patientsInfo, refData: data });

		const atLeastOneImageOrFinalReport = data.every(
			item => item.rawData.numImages > 0 || item.rawData.numFinalReports > 0
		);

		if (!atLeastOneImageOrFinalReport) {
			showErrorDownloadMsg('workListDrawer.selectedEmptyOrder');
		} else {
			const accessionNum = data
				.map(
					item =>
						item.rawData?.identifier?.find(elem => elem.system == extensionUrls.imagingStudy.accession)
							?.value
				)
				.filter((value, index, self) => self.indexOf(value) === index)
				.join('-')
				.substring(0, 200); // Windows max filename is 260, so let's save some space for the prefix and sulfix when available

			if (!wonIvDownloadmanager) {
				setToastUtility(true, `${t('workListDrawer.prepareToBurnDisc')}`);
				downloadMultipleStudiesDiscImageBurner(body, __config.auth.omegaai.auth_api, accessionNum, e => {
					showErrorDownloadMsg('CD Burner Error on Create');
				});
			} else {
				setDuration(5000);
				const download = new DownloadDiscImageBurner({
					uniqueId: studyIds.join(', '),
					authUrl: __config.auth.omegaai.auth_api,
					accessionNumber: accessionNum,
					studyIds,
					internalManagingOrganizationIds,
				}).start({
					errorHandler: e => {
						showErrorDownloadMsg('CD Burner Error on Create');
					},
				});

				if (download.enqueueStatus === 'ENQUEUED') {
					setToastUtility(true, t(`prepareToBurnDisc`, { ns: 'downloader' }));
				} else if (download.enqueueStatus === 'ALREADY-ENQUEUED') {
					setToastUtility(true, t(`This is being downloaded already.`, { ns: 'downloader' }));
				}
			}
		}
		resetStudiesSelection();
	};

	const onDeleteClick = () => {
		const data = selectedStudies;
		setToastUtility(true, `${t('Deleting')} ${data.length} ${t('studies')}...`);
		setOpen(false);
		Promise.resolve(data.map(s => studyLoader.delete(s.id))).then(() => {
			setToastUtility(true, t('Successfully deleted selected studies.'));
			resetStudiesSelection();
		});
	};

	// Assign to User
	const [searchCardAnchorEl, setSearchCardAnchorEl] = useState(null);
	const searchCardOpen = Boolean(searchCardAnchorEl);
	const handleSearchCardClose = () => {
		setSearchCardAnchorEl(null);
	};

	// Download menu
	const [downloadCardAnchorEl, setDownloadCardAnchorEl] = useState(null);
	const downloadCardOpen = Boolean(downloadCardAnchorEl);
	const handleDownloadCardClose = () => {
		setDownloadCardAnchorEl(null);
	};
	// delete study
	const [enableDeleteStudies, setEnableDeleteStudies] = useState(false);

	const studyLoader = useFhirDataLoader({
		scope: searchScopes.studyRegular,
	});

	const onUserSelect = useCallback(
		newUser => {
			if (newUser) {
				setToastUtility(true, t('Updating Assignee, please wait...'));

				let operationSuccess = true;
				// Need to first retrieve Order so that PUT can have entire Order JSON
				Promise.all(
					selectedStudies?.map(s =>
						studyLoader
							.patch(s.id, 'interpreter', [
								{
									id: newUser.id,
									reference: `Practitioner/${newUser.id}`,
								},
							])
							.then(res => {
								if (!res) {
									operationSuccess = false;
								}
							})
					)
				).finally(() => {
					if (operationSuccess) {
						resetStudiesSelection();

						setToastUtility(true, t('Successfully updated Assignee. Please refresh to see updates.'));
						handleSearchCardClose();
					} else {
						setToastUtility(true, t('Could not update Asignee. Please try again.'));
						handleSearchCardClose();
					}
				});
			}
		},
		[selectedStudies]
	);

	const enableAssignStudies = useMemo(() => {
		const studiesManagingOrgsArray = selectedStudies?.map(s => s.internalManagingOrganizationId);
		const studiesManagingOrgsSet = new Set(studiesManagingOrgsArray);

		if (studiesManagingOrgsSet.size > 1) return false;

		return true;
	}, [selectedStudies]);

	const readingPhysicianParams = {
		isreadingphysician: true,
		managingorganization: selectedStudies?.length > 0 ? selectedStudies[0]?.internalManagingOrganizationId : null,
		ispractitionerroleactive: true,
	};

	const isTFScreenOpen = useMemo(() => !!onRemoveStudiesFromTF, [onRemoveStudiesFromTF]);

	useEffect(() => {
		const checkPermissions = async () => {
			if (isTFScreenOpen) {
				setEnableDeleteStudies(false);
				return;
			}
			const studiesManagingOrgsArray = selectedStudies?.map(s => s.internalManagingOrganizationId);
			const studiesManagingOrgsSet = new Set(studiesManagingOrgsArray);
			const deleteStudyPermissionPath = 'worklist.delete study';
			if (crossGetPrivilegeOnDemand) {
				const hasAllPermissions = await Promise.all(
					Array.from(studiesManagingOrgsSet).map(async internalManagingOrganizationId =>
						hasPermissionByOrganizationAsync(
							internalManagingOrganizationId,
							'Home',
							deleteStudyPermissionPath
						)
					)
				);
				setEnableDeleteStudies(hasAllPermissions.every(Boolean));
			} else {
				const hasAllPermissions = Array.from(studiesManagingOrgsSet).every(internalManagingOrganizationId =>
					hasPermissionByOrganization(internalManagingOrganizationId, 'Home', deleteStudyPermissionPath)
				);
				setEnableDeleteStudies(hasAllPermissions);
			}
		};
		checkPermissions();
	}, [selectedStudies, isTFScreenOpen, crossGetPrivilegeOnDemand]);

	return (
		<>
			{selectedCount > 0 ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						position: 'absolute',
						bottom: '10px',
						gap: '105px',
						height: '150px',
						width: '100%',
						pointerEvents: 'none',
						left: 0,
					}}
				>
					<Box id="userselect-anchor" sx={{ width: '225px' }} />
					<Box
						sx={{
							backgroundColor: '#3588CB',
							height: '45px',
							boxSizing: 'border-box',
							borderRadius: '29px',
							zIndex: 10,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							padding: '0 16px',
							pointerEvents: 'all',
							boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
							transition: 'background-color .3s ease',

							'&:hover': {
								backgroundColor: '#28679A',
							},
						}}
					>
						<Typography
							sx={{
								fontSize: '10px',
								color: 'icon.primary',
								textTransform: 'uppercase',
								paddingRight: '10px',
								lineHeight: '13px',
								letterSpacing: '1.5px',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							{`${selectedCount}`} {selectedCount > 1 ? t('Studies') : t('Study')}
						</Typography>
						<CloseIcon
							data-testid="close-icon"
							sx={{
								color: '#fff',
								backgroundColor: 'rgba(66, 165, 245, 0.6);',
								borderRadius: '50%',
								width: '14px',
								height: '14px',
								marginLeft: '10px',
								padding: '3px',
								cursor: 'pointer',
							}}
							onClick={clearAllStudies}
						/>

						<VerticalDivider />

						{enableAssignStudies && (
							<Tooltip placement="top" title={t('workListDrawer.assignee')}>
								<IconButton
									aria-label="user-button"
									onClick={e => {
										if (searchCardAnchorEl) {
											setSearchCardAnchorEl(null);
										} else {
											setSearchCardAnchorEl(document.getElementById('userselect-anchor'));
										}
									}}
								>
									<FolderSharedIcon
										sx={{
											height: '24px',
											color: 'rsPrimary.contrastText',
										}}
									/>
								</IconButton>
							</Tooltip>
						)}

						{selectedCount > 0 && (
							<Tooltip placement="top" title={t('download')}>
								<IconButton
									aria-label="download-button"
									onClick={e => {
										if (downloadCardAnchorEl) {
											setDownloadCardAnchorEl(null);
										} else {
											setDownloadCardAnchorEl(document.getElementById('userselect-anchor'));
										}
									}}
								>
									<DownloadIcon
										sx={{
											height: '24px',
											color: 'rsPrimary.contrastText',
										}}
									/>
								</IconButton>
							</Tooltip>
						)}
						{wonIvIsoDownload && (
							<Tooltip placement="top" title={t('workListDrawer.burnToDisc')}>
								<IconButton
									aria-label="burn-to-disc-button"
									onClick={e => {
										onBurnToDiscClick();
									}}
								>
									<AlbumIcon sx={{ height: '24px', color: 'rsPrimary.contrastText' }} />
								</IconButton>
							</Tooltip>
						)}
						<Tooltip placement="top" title={t('send')}>
							<IconButton onClick={() => onSendClick(selectedStudies)}>
								<SendIcon
									sx={{
										height: '24px',
										color: 'rsPrimary.contrastText',
									}}
								/>
							</IconButton>
						</Tooltip>
						{maven2061TeachingFolder && !isTFScreenOpen && (
							<Tooltip placement="top" title={t('AddTeachingFolder')}>
								<IconButton data-cy="add-to-tf" onClick={() => onAddToTFClick(selectedStudies)}>
									<SchoolOutlinedIcon
										sx={{
											height: '24px',
											color: 'rsPrimary.contrastText',
										}}
									/>
								</IconButton>
							</Tooltip>
						)}
						{maven2061TeachingFolder && isTFScreenOpen && userCanEdit && (
							<Tooltip placement="top" title={t('RemoveFromTF')}>
								<IconButton
									data-cy="remove-from-tf"
									onClick={async () => onRemoveStudiesFromTF(selectedStudies)}
								>
									<RemoveCircleOutlineIcon
										sx={{
											height: '24px',
											color: 'rsPrimary.contrastText',
										}}
									/>
								</IconButton>
							</Tooltip>
						)}
						{enableDeleteStudies && (
							<>
								<VerticalDivider />
								<SelectedResourcesButton
									icon={DeleteIcon}
									iconSize="24px"
									title="Delete Studies"
									onClick={() => setOpen(true)}
								/>
							</>
						)}
					</Box>
					<Menu
						MenuListProps={{
							'aria-labelledby': 'user-button',
						}}
						anchorEl={searchCardAnchorEl}
						open={searchCardOpen}
						onClick={e => {
							// Supress Clickwheel
							e.stopPropagation();
						}}
						onClose={handleSearchCardClose}
					>
						<UserSelector
							defaultOpen
							preloadOptions
							showUnassignedOption
							customSearchIcon={<FolderSharedIcon />}
							mainLabel="readingPhysician"
							searchParams={readingPhysicianParams}
							sx={{ p: '10px' }}
							testId="user-card-selector"
							onChange={onUserSelect}
						/>
					</Menu>
					<Menu
						MenuListProps={{
							'aria-labelledby': 'download-button',
						}}
						PaperProps={{
							style: {
								transform: 'translateX(33%) translateY(100%)',
							},
						}}
						anchorEl={downloadCardAnchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						getContentAnchorEl={null}
						open={downloadCardOpen}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						onClick={e => {
							// Supress Clickwheel
							e.stopPropagation();
						}}
						onClose={handleDownloadCardClose}
					>
						<MenuItem>
							<Tooltip placement="top" title={t('download')}>
								<DownloadIcon
									sx={{
										height: '20px',
										px: '10px',
										color: 'rsPrimary.contrastText',
									}}
									onClick={e => {
										onDownloadClick(false);
									}}
								/>
							</Tooltip>
							<Tooltip placement="top" title={t('anonymous_Download')}>
								<NoAccountsIcon
									sx={{
										height: '20px',
										px: '10px',
										color: 'rsPrimary.contrastText',
									}}
									onClick={e => {
										onDownloadClick(true);
									}}
								/>
							</Tooltip>
						</MenuItem>
					</Menu>
				</Box>
			) : null}
			<AlertDialog
				description={`${t('alertDescriptionForDeleteStudiesLine1')}<br/> <br/>
					${t('alertDescriptionForDeleteStudiesLine3')}
				<br/><br/> ${t('alertDescriptionForDeleteStudiesLine2')}`}
				handleClose={() => setOpen(false)}
				handleSubmit={onDeleteClick}
				open={open}
				t={t}
				title={t('alertMessageForStudies')}
			/>
			<Toast
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				duration={duration}
				message={toastMessage}
				open={toastOpen}
				onClose={handleToastClose}
			/>
		</>
	);
};

export default SelectedStudiesToolbar;
