import React from 'react';

const DesktopIcon = ({ fill }) => (
	<svg fill="none" height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_4186_16097)">
			<path
				d="M26.25 3.75H3.75C2.3625 3.75 1.25 4.8625 1.25 6.25V21.25C1.25 22.625 2.3625 23.75 3.75 23.75H10V26.25H20V23.75H26.25C27.625 23.75 28.7375 22.625 28.7375 21.25L28.75 6.25C28.75 4.8625 27.625 3.75 26.25 3.75ZM26.25 21.25H3.75V6.25H26.25V21.25Z"
				fill={fill || '#C4C4C4'}
			/>
		</g>
		<defs>
			<clipPath id="clip0_4186_16097">
				<rect fill="white" height="30" width="30" />
			</clipPath>
		</defs>
	</svg>
);

export default DesktopIcon;
