import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import { SKIP_TRANSLATION_LIST } from '@rs-ui/helpers/constants.js';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const FilterCapsuleMenuItems = ({
	onChange,
	onKeyDown,
	filteredMenuItems,
	filterType,
	onMenuChecked,
	selectedItems,
	searchValueSet,
	t,
}) => {
	const proactEnableCustomStudyPriority = useBooleanFlagValue('proact-enable-custom-study-priority');
	if (proactEnableCustomStudyPriority) {
		SKIP_TRANSLATION_LIST.push('priority');
	}
	const getTranslatedItems = () => {
		const translatedItems = {};
		Object.keys(selectedItems).forEach(key => {
			const translatedKey = t(`codePool:${searchValueSet}.${key}`);
			translatedItems[translatedKey] = selectedItems[key];
		});

		return translatedItems;
	};

	const isChecked = item =>
		SKIP_TRANSLATION_LIST.includes(searchValueSet)
			? selectedItems[item]
			: getTranslatedItems()[t(`codePool:${searchValueSet}.${item}`)];

	return (
		<>
			<MenuItem sx={{ px: '6px' }}>
				<TextField
					InputProps={{
						disableUnderline: true,
						autoComplete: 'off',
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon sx={{ opacity: 0.6, pl: '8px' }} />
							</InputAdornment>
						),
					}}
					id="input-with-icon-textfield"
					placeholder="Search"
					variant="standard"
					onChange={onChange}
					onKeyDown={onKeyDown}
				/>
			</MenuItem>
			{_.map(filteredMenuItems, (item, index) => (
				<MenuItem
					key={index}
					sx={{
						px: '6px',
						textOverflow: 'ellipsis',
						paddingLeft: filterType === 'single-select' ? '15px' : '0px',
					}}
					onClick={event => onMenuChecked(item, event, index)}
				>
					{filterType !== 'single-select' && (
						<Checkbox checked={Boolean(isChecked(item))} id={`checkbox_${index}`} sx={{ py: '6px' }} />
					)}
					<ListItemText
						primary={
							SKIP_TRANSLATION_LIST.includes(searchValueSet)
								? item
								: t(`codePool:${searchValueSet}.${item}`)
						}
					/>
				</MenuItem>
			))}
			{filteredMenuItems.length <= 0 && (
				<MenuItem>
					<ListItemText primary="No options found" sx={{ py: '6px', opacity: 0.6 }} />
				</MenuItem>
			)}
		</>
	);
};
FilterCapsuleMenuItems.propTypes = {
	/**
	 * Callback function to handle onChange event in text field.
	 */
	onChange: PropTypes.func,

	/**
	 * Callback function for key down event on text field, it stops event propagation so that
	 * characters can be typed on text field and it does not loses focus.
	 */
	onKeyDown: PropTypes.func,

	/**
	 * List of items in Menu which appear on dropdown menu.
	 */
	filteredMenuItems: PropTypes.array,

	/**
	 * Filter type
	 */
	filterType: PropTypes.string,

	/**
	 * Callback function to handle onClick event on Checkbox or on menu which has checkbox in it.
	 */
	onMenuChecked: PropTypes.func,

	/**
	 * Object of menu items , key=name of the menu item, value=boolean.
	 * If true, then checkbox of the menu item is checked, else unchecked.
	 */
	selectedItems: PropTypes.object,
	/**
	 * Value set name
	 */
	searchValueSet: PropTypes.string,
	/**
	 * Translation function
	 */
	t: PropTypes.func,
};
export default FilterCapsuleMenuItems;
