import React from 'react';

const HeaderIcon = ({ fill }) => (
	<svg fill="none" height="25" viewBox="0 0 25 25" width="25" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_32002)">
			<path d="M5.16797 4.13281V7.14327H10.6871V19.1851H13.6976V7.14327H19.2168V4.13281H5.16797Z" fill={fill} />
		</g>
		<defs>
			<clipPath id="clip0_2852_32002">
				<rect fill={fill} height="24.0836" rx="12.0418" width="24.0836" x="0.150391" y="0.117188" />
			</clipPath>
		</defs>
	</svg>
);

export default HeaderIcon;
