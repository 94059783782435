import React from 'react';
import _ from 'lodash';
import { crmNavLinks } from './crmNavLinks';
import { useAuth, useConfig } from '@worklist-2/core/src';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

export const featureCRMNavLinks = () => {
	const prodEntities = ['po-us01', 'pre-us01'];
	const __config = useConfig();
	const metaBreezeAccount = useBooleanFlagValue('meta-breeze-account');
	const metaBreezeCase = useBooleanFlagValue('meta-breeze-case');
	const metaBreezeAdminsetting = useBooleanFlagValue('meta-breeze-adminsetting');
	const metaBreezeReport = useBooleanFlagValue('meta-breeze-report');
	const metaDocumentManager = useBooleanFlagValue('meta-document-manager');
	const metaOkrHome = useBooleanFlagValue('meta-okr-home');
	const { loggedInUser } = useAuth();
	let canReadAccount = false;
	let canReadCase = false;
	let canReadOKR = false;
	let canAccessAdminSetting = false;
	const featureCRMNavLinks = [...crmNavLinks];

	if (loggedInUser) {
		if (loggedInUser?.permission?.Account?.Read) {
			canReadAccount = true;
		}

		if (loggedInUser?.permission?.Case?.Read) {
			canReadCase = true;
		}

		if (loggedInUser?.permission?.OKR?.Read) {
			canReadOKR = true;
		}

		if (
			!!loggedInUser?.permission?.Team?.Read ||
			!!loggedInUser?.permission?.Role?.Read ||
			!!loggedInUser?.permission?.User?.Read
		) {
			canAccessAdminSetting = true;
		}
	}

	if (!metaBreezeAccount || !canReadAccount) {
		_.remove(featureCRMNavLinks, item => item.path === 'account');
	}
	if (!metaBreezeCase || !canReadCase) {
		_.remove(featureCRMNavLinks, item => item.path === 'case');
	}
	if (!metaOkrHome || !canReadOKR) {
		_.remove(featureCRMNavLinks, item => item.path === '' || item.path === '/okr/:department/:quarter');
		const userFirstPage =
			document.location.pathname.toLowerCase() === '/' || document.location.pathname.toLowerCase() === '/breeze';
		if (prodEntities.includes(__config.resource_group)) {
			if (userFirstPage && canReadAccount) {
				document.location.pathname = '/account';
			} else if (userFirstPage && canReadCase) {
				document.location.pathname = '/case/list';
			}
		} else if (userFirstPage && canReadAccount) {
			document.location.pathname = 'Breeze/account';
		} else if (userFirstPage && canReadCase) {
			document.location.pathname = 'Breeze/case/list';
		}
	}
	if (!metaBreezeAdminsetting || !canAccessAdminSetting) {
		_.remove(featureCRMNavLinks, item => item.path === 'adminSettings');
	}
	if (!metaBreezeReport) {
		_.remove(featureCRMNavLinks, item => item.path === 'stats');
	}
	if (!metaDocumentManager) {
		_.remove(featureCRMNavLinks, item => item.path === 'documentation');
	}

	return featureCRMNavLinks;
};
