import React, { createContext, useState, useContext } from 'react';
import { useRouter } from '@rs-core/hooks/useRouter';

const TabContext = createContext({
	searchData: [],
	searchTab: 'All',
	validOrganizations: [],
	internalPatients: [],
});

export const TabProvider = ({ children }) => {
	const [searchTab, setSearchTabs] = useState('All');
	const [searchData, setSearchData] = useState([]);
	const [placeholder, setPlaceholder] = useState('All');
	const [validOrganizations, setValidOrganizations] = useState([]);
	const [internalPatients, setInternalPatients] = useState([]);
	const { goTo } = useRouter();

	const updateSearchTabs = tab => {
		setSearchTabs(tab);
	};

	const updateTabVisibility = visible => {
		if (visible) goTo.searchResults();
	};

	const updatePlaceholder = param => {
		setPlaceholder(param);
	};

	return (
		<TabContext.Provider
			value={{
				searchData,
				searchTab,
				placeholder,
				validOrganizations,
				internalPatients,
				setSearchData,
				updateSearchTabs,
				updateTabVisibility,
				updatePlaceholder,
				setValidOrganizations,
				setInternalPatients,
			}}
		>
			{children}
		</TabContext.Provider>
	);
};

export const useTabContext = () => useContext(TabContext);
export default TabProvider;
