import React, { memo, useEffect, useRef, useState } from 'react';
import { Enums, eventTarget, EVENTS } from '@cornerstonejs/core';
import getDefaultRenderingEngine from '../../cornerstone/getDefaultRenderingEngine';
import { Box } from '@mui/material';
import { ORIGINAL_THUMBNAIL_SIZE } from '../../consts/dragDropRules';
import { v4 as uuid } from 'uuid';
import { getLowResImageId } from '../../utils/defineLowResPreviews';
import { EmptyThumbnail } from '../ThumbnailRenderer/EmptyThumbnail';
import createDefaultRenderingEngine from '../../cornerstone/createDefaultRenderingEngine';

const { ViewportType } = Enums;
export const thumbnailrenderingEngineId = 'thumbnailrenderingEngineId';

export const CornerstoneThumbnail = memo(({ imageIds, targetImageIdIndex, forceRender }) => {
	const [fallback, setFallback] = useState(false);
	const thumbnailViewportIdRef = useRef(null);
	const thumbnailElementRef = useRef(null);

	const createThubnailViewport = () => {
		const thumbnailViewportId = `cornerstone-thumbnail-${uuid()}`;
		thumbnailElementRef.current.classList.add('cornerstone-thumbnail');
		const viewportInput = {
			viewportId: thumbnailViewportId,
			type: ViewportType.STACK,
			element: thumbnailElementRef.current,
			defaultOptions: {
				suppressEvents: false,
			},
		};
		const renderingEngine =
			getDefaultRenderingEngine(thumbnailrenderingEngineId) ||
			createDefaultRenderingEngine(thumbnailrenderingEngineId);
		renderingEngine.enableElement(viewportInput);
		return thumbnailViewportId;
	};

	const onImageLoadError = e => {
		if (imageIds.includes(e.detail.imageId)) {
			setFallback(true);
		}
	};

	useEffect(() => {
		thumbnailViewportIdRef.current = createThubnailViewport();

		return () => {
			const renderingEngine = getDefaultRenderingEngine(thumbnailrenderingEngineId);
			renderingEngine?.disableElement(thumbnailViewportIdRef.current);
			eventTarget.removeEventListener(EVENTS.IMAGE_LOAD_ERROR, onImageLoadError);
			eventTarget.removeEventListener(EVENTS.IMAGE_LOAD_FAILED, onImageLoadError);
		};
	}, []);

	useEffect(() => {
		if (thumbnailViewportIdRef.current && imageIds?.length > 0) {
			const renderingEngine = getDefaultRenderingEngine(thumbnailrenderingEngineId);
			const targetViewport = renderingEngine.getViewport(thumbnailViewportIdRef.current);
			const setStack = async () => {
				try {
					await targetViewport.setStack(imageIds.map(id => getLowResImageId(id)));
				} catch (err) {
					console.log(`setStack error: ${err}`);
				}
			};
			setStack();
			eventTarget.addEventListener(EVENTS.IMAGE_LOAD_ERROR, onImageLoadError);
			eventTarget.addEventListener(EVENTS.IMAGE_LOAD_FAILED, onImageLoadError);

			return () => {
				eventTarget.removeEventListener(EVENTS.IMAGE_LOAD_ERROR, onImageLoadError);
				eventTarget.removeEventListener(EVENTS.IMAGE_LOAD_FAILED, onImageLoadError);
			};
		}
	}, [thumbnailViewportIdRef.current, imageIds]);

	useEffect(() => {
		if (thumbnailViewportIdRef.current && targetImageIdIndex !== null && imageIds?.length > 0) {
			setFallback(false);
			const renderingEngine = getDefaultRenderingEngine(thumbnailrenderingEngineId);
			const targetViewport = renderingEngine.getViewport(thumbnailViewportIdRef.current);
			const availableIds = targetViewport.getImageIds();
			if (availableIds?.length > 0 && forceRender && !fallback) {
				targetViewport.setImageIdIndex(targetImageIdIndex);
				targetViewport.render();
			}
		}
	}, [thumbnailViewportIdRef.current, imageIds, targetImageIdIndex, forceRender, fallback]);

	return (
		<Box
			ref={thumbnailElementRef}
			height={`${ORIGINAL_THUMBNAIL_SIZE}px`}
			sx={{
				display: 'block',
				position: 'relative',
				width: `${ORIGINAL_THUMBNAIL_SIZE}px`,
				height: `${ORIGINAL_THUMBNAIL_SIZE}px`,
			}}
			width={`${ORIGINAL_THUMBNAIL_SIZE}px`}
		>
			{fallback && (
				<Box sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 1 }}>
					<EmptyThumbnail />
				</Box>
			)}
		</Box>
	);
});
