import React from 'react';

const ChartIcon = ({ fill }) => (
	<svg fill="none" height="25" viewBox="0 0 25 25" width="25" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_31970)">
			<path
				d="M19.5865 3.20312H5.53987C4.4362 3.20312 3.5332 4.10612 3.5332 5.20979V19.2564C3.5332 20.3601 4.4362 21.2631 5.53987 21.2631H19.5865C20.6902 21.2631 21.5932 20.3601 21.5932 19.2564V5.20979C21.5932 4.10612 20.6902 3.20312 19.5865 3.20312ZM19.5865 19.2564H5.53987V5.20979H19.5865V19.2564Z"
				fill={fill}
			/>
			<path d="M9.55305 12.2344H7.54639V17.251H9.55305V12.2344Z" fill={fill} />
			<path d="M17.5799 7.21484H15.5732V17.2482H17.5799V7.21484Z" fill={fill} />
			<path d="M13.5667 14.2383H11.5601V17.2483H13.5667V14.2383Z" fill={fill} />
			<path d="M13.5667 10.2266H11.5601V12.2332H13.5667V10.2266Z" fill={fill} />
		</g>
		<defs>
			<clipPath id="clip0_2852_31970">
				<rect fill={fill} height="24.08" transform="translate(0.523438 0.191406)" width="24.08" />
			</clipPath>
		</defs>
	</svg>
);

export default ChartIcon;
