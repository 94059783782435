import {
	Chip,
	ClickAwayListener,
	InputLabel,
	List,
	ListItemButton,
	ListItemText,
	Popper,
	Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

export default function MultipleSelectChip({
	id,
	data,
	onChange,
	onEnterKeyPressed,
	fieldName,
	startArdonment,
	defaultSelected,
	styles,
	transparent,
}) {
	const [focused, setFocused] = useState(false);
	const [keyword, setKeyword] = useState('');
	const [selected, setSelected] = useState(defaultSelected || []);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const open = Boolean(anchorEl);
	const eleId = open ? id + '-simple-popper' : undefined;

	const inputID = `input-${Math.floor(Math.random() * 1000000) + 1}`;

	useEffect(() => {
		if (onChange) {
			onChange(selected);
		}
	}, [selected]);

	useEffect(() => {
		const contentEditableDiv = document.querySelector(`#${inputID}`);
		if (contentEditableDiv) {
			contentEditableDiv.focus();
			contentEditableDiv.addEventListener('keydown', detectEnterPress);

			return () => {
				contentEditableDiv.removeEventListener('keydown', detectEnterPress);
			};
		}
	}, [inputID]);

	useEffect(() => {
		const parent = document.getElementById(`${id}-parent`);
		setAnchorEl(parent);
	}, [keyword, data, focused]);

	const detectEnterPress = event => {
		if (event?.key === 'Enter' && onEnterKeyPressed) {
			event.preventDefault();
			onEnterKeyPressed();
		}
	};

	const clearSearch = () => {
		setKeyword('');
		const inputField = document.querySelector('.editable');
		if (inputField) {
			inputField.innerText = '';
		}
		const s = window.getSelection();
		const r = document.createRange();
		r.setStart(inputField, 0);
		r.setEnd(inputField, 0);
		s.removeAllRanges();
		s.addRange(r);
	};

	const options = useMemo(() => {
		return _.filter(data, function (entry) {
			return !selected.map(e => e?.key).includes(entry?.key);
		});
	}, [data, selected]);

	const filtered = useMemo(() => {
		return _.filter(options, function (entry) {
			return entry?.key?.toLowerCase().includes(keyword?.toLowerCase());
		});
	}, [options, keyword]);

	const handleListItemClick = entry => {
		clearSearch();
		setSelected(old => [...old, entry]);
	};

	const handleDelete = i => {
		setSelected(old =>
			_.filter(old, function (s, index) {
				return index !== i;
			})
		);
	};

	const handleClickAway = e => {
		try {
			const allowedEleClassList = ['clickable-area'];
			const isFocused = _.some(_.flatten(e.target.classList), value => allowedEleClassList.includes(value));
			const parent = document.getElementById(`${id}-parent`);
			setFocused(isFocused);
			setAnchorEl(isFocused ? parent : null);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<FormControl sx={{ m: 1 }}>
			{fieldName && (
				<InputLabel
					focused={true}
					shrink={true}
					sx={{
						bgcolor: '#FFF',
						zIndex: 2,
						'&.MuiInputLabel-root.Mui-focused': {
							color: 'rgba(77, 121, 234, 1) !important',
						},
					}}
				>
					{fieldName}
				</InputLabel>
			)}
			<Box
				id={`${id}-parent`}
				data-testid={`${id}-parent`}
				className="clickable-area"
				sx={{
					gap: 0.5,
					border: `1px solid ${focused && !transparent ? '#4D79EA' : 'rgba(0, 0, 0, 0.1)'}`,
					background: focused && !transparent ? 'rgba(77, 121, 234, 0.05)' : 'inherit',
					borderRadius: '10px',
					minHeight: '40px',
					width: '250px',
					display: 'flex',
					flexDirection: 'row',
					zIndex: 1,
					marginTop: '5px',

					'&:hover': {
						border: '1px solid #4D79EA',
						background: 'rgba(77, 121, 234, 0.05)',
					},
					...styles?.inputContainer,
				}}
			>
				<Box
					className="clickable-area"
					sx={{
						flexWrap: 'wrap',
						padding: '2px 5px',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}
				>
					{startArdonment}
					{selected.map(({ key }, i) => (
						<Chip
							key={`${key}-${i}`}
							label={key}
							color="info"
							onDelete={() => handleDelete(i)}
							deleteIcon={<CloseIcon data-testid="delete-icon" />}
							sx={{
								bgcolor: '#4D79EA',
								borderRadius: '5px',
								margin: '1px',
								'& span': {
									color: '#FFF !important',
								},
							}}
						/>
					))}
					<Box
						id={inputID}
						data-testid={`${id}-editable`}
						className="editable clickable-area"
						placeholder={fieldName}
						contentEditable
						onInput={e => setKeyword(e.currentTarget.textContent)}
						onFocus={() => {
							setFocused(true);
						}}
						onBlur={() => {
							setFocused(false);
						}}
						sx={{
							minWidth: _.isEmpty(selected) ? '100px' : '50px',
							flex: _.isEmpty(selected) ? 1 : 'none',
							fontFamily: 'Inter',
							fontStyle: 'normal',
							fontWeight: 400,
							fontSize: '14px',
							minHeight: '20px',
							lineHeight: '17px',
							color: 'rgba(0, 0, 0, 0.9)',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							textAlign: 'start',
							justifyContent: 'center',
							marginY: '5px',
							padding: '0px 5px',
							outlineWidth: 0,

							'&[contentEditable]:empty::before': {
								content: _.isEmpty(selected) ? '"Select an Option"' : '""',
								color: '#999',
							},
						}}
					/>
				</Box>
			</Box>
			<Popper id={eleId} open={open} anchorEl={anchorEl} sx={{ zIndex: 11 }}>
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box
						sx={{
							width: '250px',
							maxHeight: '200px',
							overflowY: 'auto',
							background: '#FFF',
							borderRadius: '10px',
							marginTop: '5px',
							border: `1px solid ${focused || !transparent ? '#4D79EA' : 'rgba(0, 0, 0, 0.1)'}`,
						}}
					>
						{_.isEmpty(filtered) ? (
							<Typography
								sx={{
									margin: 2,
									fontFamily: 'Inter',
									fontSize: '14px',
									fontWeight: 'normal',
									fontStretch: 'normal',
									lineHeight: '1',
									letterSpacing: 'normal',
									textAlign: 'center',
									color: 'rgba(0, 0, 0, 0.6)',
								}}
							>
								No Options Available
							</Typography>
						) : (
							<List>
								{_.map(filtered, function (entry, i) {
									return (
										<ListItemButton
											key={i}
											data-testid={`option-${i}`}
											className="clickable-area"
											sx={{ py: 0 }}
											onClick={() => handleListItemClick(entry)}
										>
											<ListItemText
												sx={{
													fontFamily: 'Inter',
													fontStyle: 'normal',
													fontWeight: 400,
													fontSize: '14px',
													lineHeight: '17px',
													color: 'rgba(0, 0, 0, 0.9)',
												}}
												primary={entry?.key}
											/>
										</ListItemButton>
									);
								})}
							</List>
						)}
					</Box>
				</ClickAwayListener>
			</Popper>
		</FormControl>
	);
}
