import React from 'react';

const FolderAddIcon = ({ width, height }) => (
	<svg
		fill="none"
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		width={width}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10 9.375C10 9.54076 9.93415 9.69973 9.81694 9.81694C9.69973 9.93415 9.54076 10 9.375 10H8.125V11.25C8.125 11.4158 8.05915 11.5747 7.94194 11.6919C7.82473 11.8092 7.66576 11.875 7.5 11.875C7.33424 11.875 7.17527 11.8092 7.05806 11.6919C6.94085 11.5747 6.875 11.4158 6.875 11.25V10H5.625C5.45924 10 5.30027 9.93415 5.18306 9.81694C5.06585 9.69973 5 9.54076 5 9.375C5 9.20924 5.06585 9.05027 5.18306 8.93306C5.30027 8.81585 5.45924 8.75 5.625 8.75H6.875V7.5C6.875 7.33424 6.94085 7.17527 7.05806 7.05806C7.17527 6.94085 7.33424 6.875 7.5 6.875C7.66576 6.875 7.82473 6.94085 7.94194 7.05806C8.05915 7.17527 8.125 7.33424 8.125 7.5V8.75H9.375C9.54076 8.75 9.69973 8.81585 9.81694 8.93306C9.93415 9.05027 10 9.20924 10 9.375ZM15 5V11.25C14.999 12.0785 14.6694 12.8728 14.0836 13.4586C13.4978 14.0444 12.7035 14.374 11.875 14.375H3.125C2.2965 14.374 1.50222 14.0444 0.916387 13.4586C0.330551 12.8728 0.000992411 12.0785 0 11.25L0 3.75C0.000992411 2.9215 0.330551 2.12722 0.916387 1.54139C1.50222 0.955551 2.2965 0.625992 3.125 0.625H4.705C4.99588 0.625241 5.28275 0.692841 5.54313 0.8225L7.51562 1.8125C7.60278 1.85433 7.69833 1.8757 7.795 1.875H11.875C12.7035 1.87599 13.4978 2.20555 14.0836 2.79139C14.6694 3.37722 14.999 4.1715 15 5ZM1.25 3.75V4.375H13.635C13.5062 4.01068 13.268 3.69506 12.9529 3.4713C12.6379 3.24755 12.2614 3.1266 11.875 3.125H7.795C7.50412 3.12476 7.21725 3.05716 6.95687 2.9275L4.98438 1.94062C4.89748 1.89772 4.80191 1.87527 4.705 1.875H3.125C2.62772 1.875 2.15081 2.07254 1.79917 2.42417C1.44754 2.77581 1.25 3.25272 1.25 3.75ZM13.75 11.25V5.625H1.25V11.25C1.25 11.7473 1.44754 12.2242 1.79917 12.5758C2.15081 12.9275 2.62772 13.125 3.125 13.125H11.875C12.3723 13.125 12.8492 12.9275 13.2008 12.5758C13.5525 12.2242 13.75 11.7473 13.75 11.25Z"
			fill="#C4C4C4"
		/>
	</svg>
);

export default FolderAddIcon;
