import { useRef } from 'react';

export const useLoading = () => {
	const loadingRef = useRef(false);
	const setLoading = value => {
		loadingRef.current = !!value;
	};

	const isLoading = () => {
		return !!loadingRef.current;
	};

	return {
		loading: isLoading(),
		setLoading: setLoading,
	};
};

export default useLoading;
