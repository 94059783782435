import React from 'react';

const ArrowIcon = ({ color, onClick }) => (
	<svg
		fill="none"
		height="16"
		style={{ cursor: 'pointer' }}
		viewBox="0 0 16 16"
		width="16"
		xmlns="http://www.w3.org/2000/svg"
		onClick={onClick}
	>
		<path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill={color} />
	</svg>
);

export default ArrowIcon;
