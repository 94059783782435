import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import {
	FormControl,
	FormControlLabel,
	TextField,
	Typography,
	Stack,
	FormGroup,
	Checkbox,
	Box,
	Radio,
} from '@mui/material';
import FormToolGrid from '@worklist-2/ui/src/components/FormTool/FormToolGrid';
import FormToolSubheader from '@worklist-2/ui/src/components/FormTool/FormToolSubheader';
import FormToolTextField from '@worklist-2/ui/src/components/FormTool/FormToolTextField';
import _ from 'lodash';

const FormToolCheckboxes = ({
	id = uuid(),
	required,
	title,
	description,
	options = [],
	answer = [],
	onChangeData,
	isReadOnly,
	translate,
	isCompact,
	...props
}) => {
	const titleId = `${id}-checkbox-group-title`;
	const [answerValue, setAnswerValue] = useState([]);
	const [dataReady, setDataReady] = useState(false);
	const questionTypes = [
		{
			value: 'checkbox',
			EditComponent: ({ data, item, index, secondIndex, thirdIndex, layer }) => (
				<>
					{!data.customField ? (
						<FormControlLabel
							key={`${index}-${item.key}-${secondIndex}-${data.key}`}
							checked={
								thirdIndex
									? answerValue[index]?.options[secondIndex].options[thirdIndex].checked
									: answerValue[index]?.options[secondIndex].checked
							}
							control={
								<Checkbox
									color="rsPrimary"
									name={data.key}
									onClick={() => handleChange(layer, index, secondIndex, thirdIndex)}
								/>
							}
							disabled={isReadOnly}
							label={data.description}
							sx={{
								color: 'rgba(0,0,0,1)',
								marginTop: '-9px',
								marginBottom: '-9px',
								marginLeft: thirdIndex ? '60px' : '30px',

								'.MuiFormControlLabel-label': {
									fontSize: '14px',
									color: 'rgba(0,0,0,1)',
								},
								'.MuiFormControlLabel-label.Mui-disabled': {
									color: 'rgba(0,0,0,1)',
								},
								'.MuiCheckbox-root.Mui-disabled': {
									color: 'rgba(0, 0, 0, 0.26)',
								},
							}}
							value={data.key}
						/>
					) : (
						<FormControlLabel
							key={'-' + 'other'}
							checked={
								thirdIndex
									? answerValue[index]?.options[secondIndex].options[thirdIndex].checked
									: secondIndex
									? answerValue[index]?.options[secondIndex].checked
									: answerValue[index].checked
							}
							control={
								<Checkbox
									name="other"
									onChange={() => handleChange(layer, index, secondIndex, thirdIndex)}
								/>
							}
							disabled={isReadOnly}
							label={
								<Stack direction="row" spacing={2} width="100%">
									<Typography
										sx={{
											fontSize: '14px',
											lineHeight: '16px',
											marginTop: '9px',
											color: 'rgba(0,0,0,1)',
											'.Mui-disabled': {
												color: 'rgba(0,0,0,1)',
											},
										}}
									>
										{translate ? `${translate('Other')}:` : 'Other:'}
									</Typography>
									<TextField
										defaultValue={
											thirdIndex
												? thirdIndex &&
												  answerValue[index]?.options[secondIndex]?.options[thirdIndex]?.value
												: secondIndex && answerValue[index]?.options[secondIndex]?.value
										}
										disabled={
											isReadOnly ||
											(thirdIndex
												? thirdIndex &&
												  !answerValue[index]?.options[secondIndex]?.options[thirdIndex]
														?.checked
												: secondIndex && !answerValue[index]?.options[secondIndex]?.checked)
										}
										id="standard-size-normal"
										sx={{
											width: '100%',
											'& input': {
												borderBottom: '1px solid rgba(0,0,0,0.2)',
												height: '22px',
												'-webkit-text-fill-color': 'rgba(0,0,0,1)',
											},
											'& input.Mui-disabled': {
												'-webkit-text-fill-color': 'rgba(0,0,0,0.5)',
												borderBottom: '1px solid rgba(0,0,0,0.2)',
											},
										}}
										variant="standard"
										onChange={event =>
											handleCustomInput(layer, index, secondIndex, thirdIndex, event.target.value)
										}
									/>
								</Stack>
							}
							sx={{
								color: 'rgba(0,0,0,1)',
								marginTop: '-9px',
								marginBottom: '-9px',
								marginLeft: thirdIndex ? '60px' : '30px',

								'.MuiFormControlLabel-label': {
									fontSize: '14px',
									width: '100%',
								},
								'.MuiCheckbox-root.Mui-disabled': {
									color: 'rgba(0, 0, 0, 0.26)',
								},
								'.MuiInput-root': {
									'&:before': {
										borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
									},
									'&:after': {
										borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
									},
								},
							}}
							value="other"
						/>
					)}
				</>
			),
		},
		{
			value: 'multipleChoice',
			EditComponent: ({ data, item, index, secondIndex, thirdIndex, layer }) => (
				<>
					{!data.customField ? (
						<FormControlLabel
							key={`${index}-${item.key}-${secondIndex}-${data.key}`}
							checked={
								thirdIndex
									? answerValue[index]?.options[secondIndex].options[thirdIndex].checked
									: answerValue[index]?.options[secondIndex].checked
							}
							control={
								<Radio
									color="rsPrimary"
									name={data.key}
									onClick={() => handleChange(layer, index, secondIndex, thirdIndex, 'multi')}
								/>
							}
							disabled={isReadOnly}
							label={data.description}
							sx={{
								color: 'rgba(0,0,0,1)',
								marginTop: '-9px',
								marginBottom: '-9px',
								marginLeft: thirdIndex ? '60px' : '30px',

								'.MuiFormControlLabel-label': {
									fontSize: '14px',
									color: 'rgba(0,0,0,1)',
								},
								'.MuiFormControlLabel-label.Mui-disabled': {
									color: 'rgba(0,0,0,1)',
								},
							}}
							value={data.key}
						/>
					) : (
						<FormControlLabel
							key={`${index}-${item.key}-${secondIndex}-${data.key}`}
							checked={
								thirdIndex
									? answerValue[index]?.options[secondIndex].options[thirdIndex].checked
									: answerValue[index]?.options[secondIndex].checked
							}
							control={
								<Radio
									color="rsPrimary"
									name={data.key}
									onClick={() => handleChange(layer, index, secondIndex, thirdIndex, 'multi')}
								/>
							}
							disabled={isReadOnly}
							label={
								<Stack direction="row" spacing={2} width="100%">
									<Typography
										sx={{
											fontSize: '14px',
											lineHeight: '16px',
											marginTop: '9px',
											color: 'rgba(0,0,0,1)',
											'.Mui-disabled': {
												color: 'rgba(0,0,0,1)',
											},
										}}
									>
										{translate ? `${translate('Other')}:` : 'Other:'}
									</Typography>
									<TextField
										defaultValue={
											thirdIndex
												? answerValue[index]?.options[secondIndex].options[thirdIndex].value
												: answerValue[index]?.options[secondIndex].value
										}
										disabled={
											isReadOnly ||
											(thirdIndex
												? !answerValue[index]?.options[secondIndex].options[thirdIndex].checked
												: !answerValue[index]?.options[secondIndex].checked)
										}
										id="standard-size-normal"
										sx={{
											width: '100%',
											'& input': {
												borderBottom: '1px solid rgba(0,0,0,0.2)',
												height: '22px',
												'-webkit-text-fill-color': 'rgba(0,0,0,1)',
											},
											'& input.Mui-disabled': {
												'-webkit-text-fill-color': 'rgba(0,0,0,0.5)',
												borderBottom: '1px solid rgba(0,0,0,0.2)',
											},
										}}
										variant="standard"
										onChange={event =>
											handleCustomInput(layer, index, secondIndex, thirdIndex, event.target.value)
										}
									/>
								</Stack>
							}
							sx={{
								color: 'rgba(0,0,0,1)',
								marginTop: '-9px',
								marginBottom: '-9px',
								marginLeft: thirdIndex ? '60px' : '30px',

								'.MuiFormControlLabel-label': {
									fontSize: '14px',
									width: '100%',
								},
								'.MuiInput-root': {
									'&:before': {
										borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
									},
									'&:after': {
										borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
									},
								},
							}}
							value={data.key}
						/>
					)}
				</>
			),
		},
		{
			value: 'shortAnswer',
			EditComponent: ({ data, index, secondIndex, thirdIndex, layer }) => (
				<Box sx={{ marginLeft: thirdIndex ? '75px' : '40px' }}>
					<FormToolGrid key={`Grid-${secondIndex}`} columns={1} isCompact={isCompact}>
						<Stack
							direction="row"
							spacing={2}
							sx={{
								height: isCompact ? 'fit-content' : '50px',
								alignItems: isCompact && 'center',
							}}
							width="100%"
						>
							<Typography
								style={{
									whiteSpace: 'pre-line',
									color: '#000000',
									width: '60%',
									height: isCompact ? 'fit-content' : '50px',
									overflow: 'auto',
									fontSize: '14px',
									lineHeight: isCompact ? '16px' : '24px',
								}}
							>
								{data.description}
							</Typography>
							<FormToolTextField
								defaultValue={data.value ? data.value : ''}
								isCompact={isCompact}
								placeholder={translate ? translate('Your Answer') : 'Your Answer'}
								style={{
									width: isCompact ? '50%' : '68%',
									marginTop: '5px',
								}}
								sx={{
									'& .MuiOutlinedInput-root': {
										height: isCompact ? '40px' : '56px',
										color: isReadOnly ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,1)',
									},
								}}
								onChange={e =>
									handleChange(layer, index, secondIndex, thirdIndex, 'text', e.target.value)
								}
							/>
						</Stack>
					</FormToolGrid>
				</Box>
			),
		},
	];

	useEffect(() => {
		let vals;
		if (answer.length > 0) {
			vals = answer[answer.length - 1]?.value;
		} else {
			vals = _.cloneDeep(options);
			vals.forEach(item => {
				item.checked = false;
				if (item.customField) item.value = null;
				if (item?.options?.length) {
					item.options.forEach(secondItem => {
						secondItem.checked = false;
						if (secondItem.customField) secondItem.value = null;
						if (secondItem?.options?.length) {
							secondItem.options.forEach(thirdItem => {
								thirdItem.checked = false;
								if (thirdItem.customField) thirdItem.value = null;
							});
						}
					});
				}
			});
		}
		setAnswerValue([...vals]);
		setDataReady(true);
	}, []);

	const handleCustomInput = (layer, index, secondIndex, thirdIndex, inputValue) => {
		const vals = [...answerValue];
		if (layer === 1) {
			vals[index].value = inputValue;
		}
		if (layer === 2) {
			const valsOpts = vals[index]?.options;
			valsOpts[secondIndex].value = inputValue;
		}
		if (layer === 3) {
			const valsOpts = vals[index]?.options[secondIndex]?.options;
			valsOpts[thirdIndex].value = inputValue;
		}

		setAnswerValue(vals);
		onChangeData({
			value: answerValue,
		});
	};

	const handleChange = (layer, index, secondIndex, thirdIndex, type = null, value = null) => {
		const vals = [...answerValue];
		if (layer === 1) {
			if (type === 'text') {
				vals[index].value = value;
				vals[index].checked = false;
			} else {
				if (type === 'multi') {
					vals.forEach((val, ind) => {
						if (ind !== index) {
							val.checked = false;
						}
					});
				}
				vals[index].checked = !vals[index].checked;
			}
		}
		if (layer === 2) {
			const valsOpts = vals[index]?.options;
			if (type === 'text') {
				valsOpts[secondIndex].value = value;
				valsOpts[secondIndex].checked = false;
			} else {
				if (type === 'multi') {
					valsOpts.forEach((val, ind) => {
						if (ind !== secondIndex) {
							val.checked = false;
						}
					});
				}
				valsOpts[secondIndex].checked = !valsOpts[secondIndex].checked;
			}
		}
		if (layer === 3) {
			const valsOpts = vals[index]?.options[secondIndex]?.options;
			if (type === 'text') {
				valsOpts[thirdIndex].value = value;
				valsOpts[thirdIndex].checked = false;
			} else {
				if (type === 'multi') {
					valsOpts.forEach((val, ind) => {
						if (ind !== thirdIndex) {
							val.checked = false;
						}
					});
				}
				valsOpts[thirdIndex].checked = !valsOpts[thirdIndex].checked;
			}
		}

		setAnswerValue(vals);
		onChangeData({
			value: answerValue,
		});
	};

	return (
		<FormControl sx={{ width: '100%' }}>
			<FormToolSubheader
				description={description}
				isCompact={isCompact}
				required={required}
				title={title}
				titleId={titleId}
			/>

			{dataReady && (
				<FormGroup
					disabled
					aria-labelledby={titleId}
					sx={{
						'.MuiCheckbox-root': {
							color: 'rgba(0, 0, 0, 0.2)',
						},
						'.Mui-checked': {
							color: '#4096DC',
						},
						gap: isCompact ? '5px' : 'var(--element-spacing)',
						...props?.sx,
					}}
					{...props}
				>
					{Array.isArray(answerValue)
						? answerValue.map((item, index) => (
								<>
									{typeof item === 'object' && !item.customField ? (
										<FormControlLabel
											key={`${index}-${item.key}`}
											checked={answerValue[index]?.checked}
											control={
												<Checkbox
													color="rsPrimary"
													name={item.key}
													onClick={() => handleChange(1, index, null, null)}
												/>
											}
											disabled={isReadOnly}
											label={item.description}
											sx={{
												color: 'rgba(0,0,0,1)',
												marginTop: '-9px',
												marginBottom: '-9px',

												'.MuiFormControlLabel-label': {
													fontSize: '14px',
													color: 'rgba(0,0,0,1)',
												},
												'.MuiFormControlLabel-label.Mui-disabled': {
													color: 'rgba(0,0,0,1)',
												},
												'.MuiCheckbox-root.Mui-disabled': {
													color: 'rgba(0, 0, 0, 0.26)',
												},
											}}
											value={item.key}
										/>
									) : (
										<FormControlLabel
											key={'-' + 'other'}
											checked={answerValue[index]?.checked}
											control={
												<Checkbox
													name="other"
													onChange={() => handleChange(1, index, null, null)}
												/>
											}
											disabled={isReadOnly}
											label={
												<Stack direction="row" spacing={2} width="100%">
													<Typography
														sx={{
															fontSize: '14px',
															lineHeight: '16px',
															marginTop: '9px',
															color: 'rgba(0,0,0,1)',
															'.Mui-disabled': {
																color: 'rgba(0,0,0,1)',
															},
														}}
													>
														{translate ? `${translate('Other')}:` : 'Other:'}
													</Typography>
													<TextField
														defaultValue={answerValue[index]?.value}
														disabled={isReadOnly || !answerValue[index]?.checked}
														id="standard-size-normal"
														sx={{
															width: '100%',
															'& input': {
																borderBottom: '1px solid rgba(0,0,0,0.2)',
																height: '22px',
																'-webkit-text-fill-color': 'rgba(0,0,0,1)',
															},
															'& input.Mui-disabled': {
																'-webkit-text-fill-color': 'rgba(0,0,0,0.5)',
																borderBottom: '1px solid rgba(0,0,0,0.2)',
															},
														}}
														variant="standard"
														onChange={event =>
															handleCustomInput(1, index, null, null, event.target.value)
														}
													/>
												</Stack>
											}
											sx={{
												color: 'rgba(0,0,0,1)',
												marginTop: '-9px',
												marginBottom: '-9px',

												'.MuiFormControlLabel-label': {
													fontSize: '14px',
													width: '100%',
												},
												'.MuiCheckbox-root.Mui-disabled': {
													color: 'rgba(0, 0, 0, 0.26)',
												},
												'.MuiInput-root': {
													'&:before': {
														borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
													},
													'&:after': {
														borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
													},
												},
											}}
											value="other"
										/>
									)}
									{typeof item === 'object' &&
										!!item?.options?.length &&
										!!item.checked &&
										item?.options.map((secondItem, secondIndex) => (
											<>
												{secondIndex !== 0 && (
													<>
														{questionTypes
															.find(q => q.value == secondItem.type)
															?.EditComponent({
																data: secondItem,
																item,
																index,
																secondIndex,
																thirdIndex: null,
																layer: 2,
															})}
													</>
												)}
												{!!secondItem?.options?.length &&
													(!!secondItem.checked || !!secondItem?.value?.length) &&
													secondItem?.options.map((thirdItem, thirdIndex) => (
														<>
															{thirdIndex !== 0 && (
																<>
																	{questionTypes
																		.find(q => q.value == thirdItem.type)
																		?.EditComponent({
																			data: thirdItem,
																			item,
																			index,
																			secondIndex,
																			thirdIndex,
																			layer: 3,
																		})}
																</>
															)}
														</>
													))}
											</>
										))}
								</>
						  ))
						: null}
				</FormGroup>
			)}
		</FormControl>
	);
};

export default FormToolCheckboxes;
