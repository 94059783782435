import React from 'react';
import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = ({ isOmega }) => (
	<Box
		sx={{
			height: '100%',
			width: '100%',
			flex: 1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
			boxSizing: 'border-box',
		}}
	>
		<Typography
			sx={{
				textAlign: 'center',
				fontSie: '16px',
				marginBottom: '8px',
				color: isOmega ? 'rgba(255, 255, 255, 0.60)' : 'rgba(0, 0, 0, 0.60)',
			}}
		>
			<CircularProgress
				className="progressBar"
				size={22}
				sx={{
					color: '#4D79EA',
					marginRight: '16px',
				}}
			/>
			Loading...
		</Typography>
	</Box>
);

export default Loader;
