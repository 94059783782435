// Core
import React, { useState } from 'react';
// libraries
import { Controller } from 'react-hook-form';
// Components
import { Switch, Typography, Box } from '@mui/material';
import FormNumberFieldVariant from './FormNumberFieldVariant';

const FormSignDelayVariant = ({ formHook, name, props, isEditing }) => {
	const { control } = formHook;
	const [checked, setChecked] = useState(props?.switchValue);
	const handleSwitch = e => setChecked(e);
	return (
		<>
			<Controller
				control={control}
				name={`${name}.0.valueBoolean`}
				render={({ field }) => (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<Switch
							checked={checked}
							color="info"
							disabled={!isEditing}
							inputProps={{ 'aria-label': 'controlled', 'data-testid': 'sign-delay-switch' }}
							sx={{ marginLeft: '-10px' }}
							onChange={e => {
								field.onChange(e?.target?.checked);
								handleSwitch(e?.target?.checked);
							}}
						/>
						<Typography>{props?.switchLabel}</Typography>
					</Box>
				)}
			/>
			{checked && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						marginTop: '20px',
					}}
				>
					<FormNumberFieldVariant
						formHook={formHook}
						name={`${name}.1.valueString`}
						props={{
							disabled: true,
							value: '2',
							label: props?.timeLabel,
							sx: {
								width: isEditing ? '100%' : '50px',
							},
						}}
					/>
					<Typography
						sx={{
							margin: isEditing ? '15px 0 0 20px' : '20px 0 0 -20px',
						}}
					>
						{props?.timeUnit}
					</Typography>
				</Box>
			)}
		</>
	);
};

export default FormSignDelayVariant;
