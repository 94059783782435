import React from 'react';

const AlignRightIcon = () => (
	<svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_5211_60498)">
			<path
				d="M0.666667 3.99983H15.3333C15.5101 3.99983 15.6797 3.9296 15.8047 3.80457C15.9298 3.67955 16 3.50998 16 3.33317C16 3.15636 15.9298 2.98679 15.8047 2.86177C15.6797 2.73674 15.5101 2.6665 15.3333 2.6665H0.666667C0.489856 2.6665 0.320286 2.73674 0.195262 2.86177C0.0702379 2.98679 0 3.15636 0 3.33317C0 3.50998 0.0702379 3.67955 0.195262 3.80457C0.320286 3.9296 0.489856 3.99983 0.666667 3.99983Z"
				fill="white"
			/>
			<path
				d="M15.3335 6H6.00016C5.82335 6 5.65378 6.07024 5.52876 6.19526C5.40373 6.32029 5.3335 6.48985 5.3335 6.66667C5.3335 6.84348 5.40373 7.01304 5.52876 7.13807C5.65378 7.26309 5.82335 7.33333 6.00016 7.33333H15.3335C15.5103 7.33333 15.6799 7.26309 15.8049 7.13807C15.9299 7.01304 16.0002 6.84348 16.0002 6.66667C16.0002 6.48985 15.9299 6.32029 15.8049 6.19526C15.6799 6.07024 15.5103 6 15.3335 6Z"
				fill="white"
			/>
			<path
				d="M15.3335 12.6665H6.00016C5.82335 12.6665 5.65378 12.7367 5.52876 12.8618C5.40373 12.9868 5.3335 13.1564 5.3335 13.3332C5.3335 13.51 5.40373 13.6796 5.52876 13.8046C5.65378 13.9296 5.82335 13.9998 6.00016 13.9998H15.3335C15.5103 13.9998 15.6799 13.9296 15.8049 13.8046C15.9299 13.6796 16.0002 13.51 16.0002 13.3332C16.0002 13.1564 15.9299 12.9868 15.8049 12.8618C15.6799 12.7367 15.5103 12.6665 15.3335 12.6665Z"
				fill="white"
			/>
			<path
				d="M15.3333 9.3335H0.666667C0.489856 9.3335 0.320286 9.40373 0.195262 9.52876C0.0702379 9.65378 0 9.82335 0 10.0002C0 10.177 0.0702379 10.3465 0.195262 10.4716C0.320286 10.5966 0.489856 10.6668 0.666667 10.6668H15.3333C15.5101 10.6668 15.6797 10.5966 15.8047 10.4716C15.9298 10.3465 16 10.177 16 10.0002C16 9.82335 15.9298 9.65378 15.8047 9.52876C15.6797 9.40373 15.5101 9.3335 15.3333 9.3335Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_5211_60498">
				<rect fill="white" height="16" width="16" />
			</clipPath>
		</defs>
	</svg>
);

export default AlignRightIcon;
