import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Svg } from '@worklist-2/ui/src/components/Svg';

const HamburgerBtn = ({ className, onClick }) => {
	onClick = onClick || (() => {});
	const { t } = useTranslation('sideMenu');
	return (
		<div className={classnames('nav-link-hamburger', className)} data-cy="sidebar-hamburger" onClick={onClick}>
			<Tooltip placement="right" title={t('mainMenu')}>
				<div>
					<Svg className={classnames(`nav-icon`, className)} htmlColor="#ffffff99;" name="hamburger" />
				</div>
			</Tooltip>
		</div>
	);
};

HamburgerBtn.propTypes = {
	onClick: PropTypes.func,
};

export default HamburgerBtn;
