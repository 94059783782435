import axios from 'axios';

export function getCurrentDate() {
	const today = new Date();
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, '0');
	const day = String(today.getDate()).padStart(2, '0');

	return `${year}-${month}-${day}`;
}

const getAppointments = async ({ __config }) => {
	const todayDate = getCurrentDate();
	const response = await axios.post(`${__config.data_sources.blume}Appointment/_search`, {
		patientIDs: null,
		start: todayDate,
		end: '',
		showCancelAppointments: true,
	});

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default getAppointments;
