import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

//mui
import { Typography, MenuItem, Menu, Box, TextField, InputAdornment, Chip } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import ListItemText from '@mui/material/ListItemText';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Avatar from '@mui/material/Avatar';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import ClearIcon from '@rs-ui/assets/icons/GlobalSearch/ClearIcon';

//worklist cores
import { useAuth } from '@worklist-2/core/src';
import useFhirDataLoader from '@worklist-2/core/src/hooks/useFhirDataLoader';
import { searchScopes } from '@worklist-2/core/src/context/consts/searchScopes';
import { useWorklist } from '@rs-ui/components/Worklist/Worklist/contexts/WorklistContext';
import getUserFullName, { parseName } from '@rs-core/fhir/resource/columnMapping/utils/getUserFullName';
import PractitionerMapping from '@rs-core/fhir/resource/columnMapping/PractitionerMapping';

const WorklistSelector = ({
	gridName,
	worklistsOfCurrentUser,
	currWorklistId,
	onWorklistSelected,
	onWorklistEdit,
	onAddWorklist,
	showSaveBtn,
	onSaveClicked,
}) => {
	// Auth
	const { getGuiRole, hasPermissionByOrganizationAsync, loggedInUser, authorized } = useAuth();

	// Translation
	const { t } = useTranslation('workList');

	const {
		worklists,
		setWorklists,
		searchWorklistValue,
		setSearchWorklistValue,
		selectedWorklistPractitioner,
		setSelectedWorklistPractitioner,
	} = useWorklist();

	// Feature Flag
	const crossGetPrivilegeOnDemand = useBooleanFlagValue('cross-get-privilege-on-demand');

	// Fhir loader
	const fhirDataLoaderWorklist = useFhirDataLoader({
		scope: searchScopes.worklistLayout,
	});
	const fhirDataLoaderPractitioner = useFhirDataLoader({
		scope: searchScopes.practitioner,
	});

	// State
	const [anchorWorklistSelector, setAnchorWorklistSelector] = useState(null);
	const [worklistPrivilege, setWorklistPrivilege] = useState([]);
	const [defaultSearchValue, setDefaultSearchValue] = useState(searchWorklistValue);
	const [filteredWorklistMenuItems, setFilteredWorklistMenuItems] = useState(worklistsOfCurrentUser || []);
	const [hoverState, setHoverState] = useState({});
	const selectedWorklistRef = useRef(null);
	const [menuWidth, setMenuWidth] = useState(0);
	const [menuHeight, setMenuHeight] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [lookingArray, setLookingArray] = useState([]);

	// Refs
	const searchInputRef = useRef(null);

	// Constants
	const LOOKING_USER = 'USER';
	const LOOKING_ROLE = 'ROLE';

	/**
	 *  Fetch list of worklist
	 */
	const debounceSearchValue = useCallback(
		_.debounce(nextValue => {
			if (nextValue !== searchWorklistValue) {
				setSearchWorklistValue(nextValue);
			}
		}, 1000),
		[searchWorklistValue, setIsLoading, setSearchWorklistValue]
	);

	/**
	 * handleSelectWorklist
	 * @param {*} selectedItem
	 */
	const handleSelectWorklist = (selectedItem, isEdit = false) => {
		if (selectedItem?.practitioner?.id && selectedWorklistPractitioner?.id !== selectedItem.practitioner.id) {
			fhirDataLoaderPractitioner
				.load(
					{
						summary: false,
						id: selectedItem?.practitioner?.id,
						ispractitionerroleactive: true,
					},
					true
				)
				.then(result => {
					if (result) {
						if (!!result.name && result.name[0]) {
							const fullName = getUserFullName(result.name[0]);
							setSelectedWorklistPractitioner({
								id: result.id,
								reference: `Practitioner/${result.id}`,
								display: result.name[0].text ?? fullName,
								fullName,
								organization: PractitionerMapping.organization?.getDisplay(result),
								physicianTitle: PractitionerMapping.physicianTitle?.getDisplay(result),
							});
						}
					}
					if (isEdit) {
						onWorklistEdit(selectedItem.id);
					}
				})
				.catch(console.error);
		} else {
			// Emit edit worklist
			isEdit && onWorklistEdit(selectedItem.id);
		}
		// Emit select worklist
		!isEdit && onWorklistSelected(selectedItem);

		// Close drawer
		setAnchorWorklistSelector(null);
	};

	/**
	 * Add current selected worklist
	 * @param {*} currentWorklist
	 * @param {*} checkWorklist
	 * @param {*} selectedWlId
	 * @returns
	 */
	const addCurrentSelectedWorklist = (sourceWorklist, currentWorklist, selectedWlId) => {
		// Add current worklist into search worklist if not exist
		const currentWorklistItem = currentWorklist?.find(wl => wl.id === selectedWlId);
		const combinedWorklist = [...sourceWorklist];
		if (currentWorklistItem && !combinedWorklist.find(cbWl => cbWl.id === selectedWlId)) {
			combinedWorklist.push(currentWorklistItem);
		}
		return combinedWorklist;
	};
	useEffect(() => {
		if (!selectedWorklistPractitioner) {
			setSelectedWorklistPractitioner(loggedInUser);
		}
	}, []);

	useEffect(() => {
		if (searchWorklistValue !== '') {
			setIsLoading(true);
			if (authorized && fhirDataLoaderWorklist && loggedInUser) {
				fhirDataLoaderWorklist
					.load({
						userFullName: searchWorklistValue,
						isSearchWorklist: true,
						worklistTypes: lookingArray,
						sort: 'name',
					})
					.then(result => {
						if (result && result.length > 0) {
							const fetchedWorklists = _.compact(
								_.flatten(
									_.reduce(
										result,
										(a, b) =>
											_.concat(a, [
												{
													id: b.id,
													name: b.name,
													userFullName: b.userFullName,
													columns: b.rawData.columns,
													label: b.name,
													isCurrent: b.isCurrent,
													isDefault: b.isDefault,
													practitioner: b.practitioner,
													resourceType: b.resourceType,
													active: b.active,
													role: b.role,
													organization: b.organization,
												},
											]),
										[]
									)
								)
							);
							setFilteredWorklistMenuItems(fetchedWorklists || []);

							// Set new worklist
							setWorklists(addCurrentSelectedWorklist(fetchedWorklists, worklists, currWorklistId) || []);
						} else {
							setFilteredWorklistMenuItems([]);
						}
						setIsLoading(false);
					});
			}
		} else {
			let resetWorklist = addCurrentSelectedWorklist(worklistsOfCurrentUser, worklists, currWorklistId) || [];

			// If only one looking type, filter worklist by type
			if (lookingArray?.length === 1) {
				const wlType = lookingArray[0];
				if (wlType === LOOKING_USER) {
					resetWorklist = resetWorklist.filter(wl => !wl?.role?.id);
				} else if (wlType === LOOKING_ROLE) {
					resetWorklist = resetWorklist.filter(wl => wl?.role?.id);
				}
			}
			setFilteredWorklistMenuItems(resetWorklist);
			setWorklists(resetWorklist);
			setIsLoading(false);
		}
	}, [worklistsOfCurrentUser, searchWorklistValue, setIsLoading, lookingArray]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (selectedWorklistRef?.current) {
				const bounding = selectedWorklistRef?.current.getBoundingClientRect();
				const parent = selectedWorklistRef?.current.parentElement.parentElement;

				if (bounding.top > parent.clientHeight) {
					selectedWorklistRef.current.scrollIntoView({
						behavior: 'smooth',
					});
				}
			}
		}, 1000);
		return () => {
			clearTimeout(timeout);
		};
	}, [anchorWorklistSelector]);

	useEffect(() => {
		if (!isLoading) {
			searchInputRef?.current?.focus();
		}
	}, [isLoading]);

	const permissions = useMemo(() => {
		if (crossGetPrivilegeOnDemand) {
			return [];
		}
		return getGuiRole('Home');
	}, [getGuiRole, crossGetPrivilegeOnDemand]);

	const handleWorklistSelectorClick = event => {
		event.preventDefault();
		setAnchorWorklistSelector(event.currentTarget);
	};

	const measureMenuRef = useCallback(
		node => {
			if (node !== null) {
				const resizeObserver = new ResizeObserver(() => {
					setMenuWidth(node.offsetWidth);
					setMenuHeight(node.offsetHeight);
				});
				resizeObserver.observe(node);
			}
		},
		[filteredWorklistMenuItems, anchorWorklistSelector, searchWorklistValue]
	);

	useEffect(() => {
		async function calculatePermission() {
			try {
				const worklistPrivilegeArray = await Promise.all(
					filteredWorklistMenuItems.map(async (wl, index) => {
						if (crossGetPrivilegeOnDemand) {
							const orgPermission = wl?.organization?.id
								? await hasPermissionByOrganizationAsync(
										wl?.organization?.id,
										'Home',
										'worklist.role worklist',
										'update'
								  )
								: false;

							return {
								id: wl.id,
								canEditWorklist: wl?.role?.id ? orgPermission : true,
								name: wl.name,
								canEditRoleWorklist: orgPermission,
							};
						}

						const orgPermission = permissions[wl?.organization?.id];
						if (orgPermission) {
							const perm = orgPermission?.worklist?.['role worklist']?.update ?? false;
							return {
								id: wl.id,
								name: wl.name,
								canEditWorklist: wl?.role?.id ? perm : true,
								canEditRoleWorklist: perm,
							};
						}
					})
				);
				setWorklistPrivilege(worklistPrivilegeArray);
			} catch (e) {
				console.error(e);
			}
		}

		calculatePermission();
	}, [filteredWorklistMenuItems, permissions, crossGetPrivilegeOnDemand]);

	const canSaveWorklist = useCallback(() => {
		const currentWorklist = worklists.find(wl => wl.id === currWorklistId);
		const isCurrentWorklistAUserWorklist = currentWorklist?.practitioner;
		const isCurrentWorklistARoleWorklist = currentWorklist?.role;
		const canEditRoleWorklist = worklistPrivilege?.find(
			wlPrivilege => wlPrivilege.id === currWorklistId
		)?.canEditRoleWorklist;

		return (
			isCurrentWorklistAUserWorklist?.id === loggedInUser?.id ||
			(isCurrentWorklistARoleWorklist && canEditRoleWorklist)
		);
	}, [worklists, currWorklistId, worklistPrivilege]);

	return (
		<>
			<Box
				id="gridTitle"
				sx={{
					cursor: 'pointer',
					paddingLeft: '4px',
					'&:hover': {
						backgroundColor: 'rgba(255, 255, 255, 0.04)',
					},
					display: 'flex',
					alignItems: 'center',
					borderRadius: '6px',
				}}
				onClick={handleWorklistSelectorClick}
			>
				<label htmlFor="gridTitle" style={{ cursor: 'pointer', letterSpacing: '0px' }}>
					{gridName}
				</label>
				{anchorWorklistSelector ? (
					<KeyboardArrowUpIcon sx={{ fontSize: 20 }} />
				) : (
					<KeyboardArrowDownIcon sx={{ fontSize: 20 }} />
				)}
			</Box>
			{showSaveBtn && canSaveWorklist() && (
				<Tooltip title="Save Worklist">
					<SaveIcon
						data-testid="saveGrid"
						sx={{
							visibility: onSaveClicked && showSaveBtn ? 'display' : 'hidden',
							marginRight: '-10px',
							marginTop: '2px',
							marginLeft: '5px',
							color: '#3588cb',
							fontSize: 30,
							cursor: 'pointer',
						}}
						onClick={e => {
							e.stopPropagation();
							onSaveClicked();
						}}
					/>
				</Tooltip>
			)}
			<Menu
				PaperProps={{
					style: {
						maxWidth: '1500px',
						minWidth: '450px',
						maxHeight: '700px',
						backgroundColor: '#414141',
						borderRadius: '6px',
						border: '1px solid #4b4b4b',
						borderTop: 'none',
						marginTop: '5px',
					},
					ref: measureMenuRef,
				}}
				anchorEl={anchorWorklistSelector}
				data-testId="worklist-selector-menu"
				open={Boolean(anchorWorklistSelector)}
				sx={{
					borderRadius: '6px',
					'& .MuiList-root': {
						background: '#414141',
						boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
						padding: '6px 6px 6px 6px',
					},
					'& .MuiMenuItem-root': {
						padding: '6px 6px',
						'&:hover': {
							borderRadius: '6px',
						},
						borderBottom: '1px solid #4b4b4b',
						width: 'calc(100% - 12px)',
					},
				}}
				onClose={() => {
					setAnchorWorklistSelector(null);
				}}
			>
				<TextField
					InputProps={{
						disableUnderline: true,
						startAdornment: (
							<>
								<InputAdornment position="start">
									<SearchIcon sx={{ opacity: 0.6 }} />
								</InputAdornment>
								{lookingArray?.map(item => (
									<Chip
										key={item}
										label={t(`selector.${item}`)}
										sx={{
											mr: '5px',
											backgroundColor: '#1d354a',
											color: '#42A5F5',
										}}
										tabIndex={-1}
										variant="outlined"
										onDelete={() => {
											const newLookingArray = lookingArray.filter(i => i !== item);
											setLookingArray(newLookingArray);
										}}
									/>
								))}
							</>
						),
						endAdornment: searchWorklistValue !== '' && (
							<Box
								sx={{ cursor: 'pointer' }}
								onClick={() => {
									setSearchWorklistValue('');
									setDefaultSearchValue('');
								}}
							>
								<ClearIcon />
							</Box>
						),
					}}
					disabled={isLoading}
					id="input-with-icon-textfield"
					inputRef={searchInputRef}
					placeholder="Enter worklist name, user or role"
					sx={{ width: 'calc(100% - 30px)', margin: '10px', backgroundColor: '#303030', borderRadius: '6px' }}
					value={defaultSearchValue}
					variant="outlined"
					onChange={event => {
						setDefaultSearchValue(event.target.value);
						debounceSearchValue(event.target.value);
					}}
					onKeyDown={event => event.stopPropagation()}
				/>
				{isLoading && (
					<LinearProgress
						sx={{
							height: '4px',
							backgroundColor: '#42A5F5',
							borderRadius: '5px',
						}}
					/>
				)}
				<Box
					id="box-fixed-here"
					sx={{
						position: 'fixed',
						top: `${menuHeight + 120}px`,
						display: 'flex',
						justifyContent: 'space-between',
						backgroundColor: '#414141',
						opacity: '1 !important',
						borderRadius: '6px',
						width: `${menuWidth}px`,
						cursor: 'pointer',
						marginLeft: '-7px',
						border: '1px solid #4b4b4b',
					}}
				>
					<Box
						sx={{
							padding: '10px 0px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '50%',
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
						}}
						onClick={() => {
							onAddWorklist(false);
							setAnchorWorklistSelector(null);
						}}
					>
						<AccountCircleIcon sx={{ fontSize: 25 }} />
						<Typography sx={{ fontSize: 15, marginLeft: '5px' }}>
							{t('selector.createUserWorklist')}
						</Typography>
					</Box>
					<Divider flexItem orientation="vertical" />
					<Box
						sx={{
							padding: '10px 0px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '50%',
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
						}}
						onClick={() => {
							onAddWorklist(true);
							setAnchorWorklistSelector(null);
						}}
					>
						<MedicalServicesIcon sx={{ fontSize: 25 }} />
						<Typography sx={{ fontSize: 15, marginLeft: '5px' }}>
							{t('selector.createRoleWorklist')}
						</Typography>
					</Box>
				</Box>
				<Box sx={{ display: 'flex' }}>
					<Typography
						sx={{
							margin: '5px 0px 5px 5px',
							fontSize: '12px',
							color: '#999999',
							textTransform: 'uppercase',
						}}
					>
						{t('selector.lookingFor')}
					</Typography>
				</Box>
				<Box sx={{ display: 'flex', gap: '5px', margin: '0px 0px 5px 5px' }}>
					<Box
						data-testid="worklist-selector-user-worklist"
						sx={{
							borderRadius: '16px',
							padding: '5px 10px',
							backgroundColor: '#1d354a',
							cursor: 'pointer',
							color: '#42A5F5',
							height: '15px',
							fontSize: '12px',
							textTransform: 'uppercase',
						}}
						onClick={() => {
							if (!lookingArray.includes(LOOKING_USER)) {
								setLookingArray([...lookingArray, LOOKING_USER]);
							}
						}}
					>
						{t('selector.userWorklist')}
					</Box>
					<Box
						data-testid="worklist-selector-role-worklist"
						sx={{
							borderRadius: '16px',
							padding: '5px 10px',
							backgroundColor: '#1d354a',
							cursor: 'pointer',
							color: '#42A5F5',
							height: '15px',
							fontSize: '12px',
							textTransform: 'uppercase',
						}}
						onClick={() => {
							if (!lookingArray.includes(LOOKING_ROLE)) {
								setLookingArray([...lookingArray, LOOKING_ROLE]);
							}
						}}
					>
						{t('selector.roleWorklist')}
					</Box>
				</Box>
				{filteredWorklistMenuItems.filter(wl => !wl?.role?.id).length > 0 && (
					<Box sx={{ display: 'flex' }}>
						<Typography sx={{ margin: '5px 0px 5px 5px', fontSize: '15px', textTransform: 'uppercase' }}>
							{t('selector.userWorklist')}
						</Typography>
					</Box>
				)}
				{_.map(
					filteredWorklistMenuItems?.filter(wl => !wl?.role?.id),
					(item, index) => (
						<MenuItem
							key={index}
							ref={currWorklistId === item.id ? selectedWorklistRef : null}
							sx={{
								textOverflow: 'ellipsis',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								border: '1px soild #494949',
								gap: '5px',
							}}
							onClick={() => {
								handleSelectWorklist({ ...item, key: item.id });
							}}
							onMouseEnter={() => setHoverState({ [item.id]: true })}
							onMouseLeave={() => setHoverState({ [item.id]: false })}
						>
							<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
								<CheckIcon
									data-testid={`worklist-check-user-${index}`}
									sx={{
										visibility: currWorklistId === item.id ? 'visible' : 'hidden',
										padding: '5px 5px 5px 0px',
										color: '#42A5F5',
									}}
								/>
								<Avatar
									fontSize={30}
									sx={{
										bgcolor: '#c383d9',
									}}
								>
									<AccountCircleIcon sx={{ color: 'white' }} />
								</Avatar>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-start',
										marginLeft: '10px',
									}}
								>
									<ListItemText
										primary={item.name}
										primaryTypographyProps={{ style: { letterSpacing: '0px', fontSize: '15px' } }}
									/>
									<Box sx={{ borderRadius: '10px', backgroundColor: '#545454', padding: '2px 5px' }}>
										<Typography sx={{ fontSize: '13px' }}>
											{parseName(item?.userFullName, false, 'DICOM')}
										</Typography>
									</Box>
								</Box>
							</Box>
							<Box
								sx={{
									marginRight: '20px',
									visibility:
										worklistPrivilege?.find(wlPrivilege => wlPrivilege.id === item.id)
											?.canEditWorklist && hoverState[item.id]
											? 'visible'
											: 'hidden',
								}}
							>
								<EditOutlinedIcon
									sx={{ marginLeft: '5px', marginRight: '5px' }}
									onClick={e => {
										e.stopPropagation();
										handleSelectWorklist({ ...item, key: item.id }, true);
									}}
								/>
							</Box>
						</MenuItem>
					)
				)}
				{filteredWorklistMenuItems.filter(wl => wl?.role?.id).length > 0 && (
					<Box sx={{ display: 'flex' }}>
						<Typography sx={{ margin: '5px 0px 5px 5px', fontSize: '15px', textTransform: 'uppercase' }}>
							{t('selector.roleWorklist')}
						</Typography>
					</Box>
				)}
				{_.map(
					filteredWorklistMenuItems?.filter(wl => wl?.role?.id),
					(item, index) => (
						<MenuItem
							key={index}
							ref={currWorklistId === item.id ? selectedWorklistRef : null}
							sx={{
								textOverflow: 'ellipsis',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								border: '1px soild #494949',
								gap: '5px',
							}}
							onClick={() => {
								handleSelectWorklist({ ...item, key: item.id });
							}}
							onMouseEnter={() => setHoverState({ [item.id]: true })}
							onMouseLeave={() => setHoverState({ [item.id]: false })}
						>
							<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
								<CheckIcon
									data-testid={`worklist-check-role-${index}`}
									sx={{
										visibility: currWorklistId === item.id ? 'visible' : 'hidden',
										padding: '5px 5px 5px 0px',
										color: '#42A5F5',
									}}
								/>
								<Avatar
									fontSize={30}
									sx={{
										bgcolor: '#7d7aff',
									}}
								>
									<MedicalServicesIcon sx={{ color: 'white' }} />
								</Avatar>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-start',
										marginLeft: '10px',
									}}
								>
									<ListItemText
										primary={item.name}
										primaryTypographyProps={{ style: { letterSpacing: '0px', fontSize: '15px' } }}
										sx={{ width: '100%' }}
									/>
									<Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '5px' }}>
										<Box
											sx={{
												borderRadius: '10px',
												backgroundColor: '#545454',
												padding: '2px 5px',
											}}
										>
											<Typography sx={{ fontSize: '13px' }}>
												{item?.organization?.display}
											</Typography>
										</Box>
										<Box
											sx={{
												borderRadius: '10px',
												backgroundColor: '#545454',
												padding: '2px 5px',
												marginRight: '5px',
											}}
										>
											<Typography sx={{ fontSize: '13px' }}>{item?.role?.display}</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
							<Box
								sx={{
									marginRight: '20px',
									visibility:
										worklistPrivilege?.find(wlPrivilege => wlPrivilege.id === item.id)
											?.canEditWorklist && hoverState[item.id]
											? 'visible'
											: 'hidden',
								}}
							>
								<EditOutlinedIcon
									sx={{ marginLeft: '5px', marginRight: '5px' }}
									onClick={e => {
										e.stopPropagation();
										handleSelectWorklist({ ...item, key: item.id }, true);
									}}
								/>
							</Box>
						</MenuItem>
					)
				)}
			</Menu>
		</>
	);
};

WorklistSelector.propTypes = {
	/**
	 * name of worklist
	 */
	gridName: PropTypes.string,
	/**
	 * worklist array
	 */
	worklists: PropTypes.array,
	/**
	 * current worklist id
	 */
	currWorklistId: PropTypes.number,
	/**
	 * A callback to be executed when a worklist is selected from selector menu
	 */
	onWorklistSelected: PropTypes.func,
	/**
	 * A callback to be executed when a edit button is clicked from selector menu
	 */
	onWorklistEdit: PropTypes.func,
	/**
	 * Callback when creating new worklist
	 */
	onAddWorklist: PropTypes.func,
};

export default WorklistSelector;
