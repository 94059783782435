// core
import React, { useState, useEffect } from 'react';
import { useConfig, useAppModeContext } from '@worklist-2/core/src';
import { STUDY_IMAGE_DATA } from '@worklist-2/patientPortal/src/__mocks__/mockData';
// components
import StudyPreviewImage from './StudyPreviewImage';
import StudyThumbnailImage from './StudyThumbnailImage';
import EmptyState3 from '../../assets/img/EmptyState3.png';

// libraries
import axios from 'axios';
import PropTypes from 'prop-types';
import _ from 'lodash';

const StudyImage = ({ isThumbnail, imageProps, style, setSelectedImageNumber, selectedImageIndex }) => {
	const __config = useConfig();
	const [imageUrl, setImageUrl] = useState();
	const { appMode } = useAppModeContext();
	const BaseUrl =
		appMode === 'patientPortal'
			? `${__config.data_sources.blume}Study/image/content?`
			: `${__config.data_sources.image_render}ImageRender?`;

	useEffect(() => {
		(async function fetchImageURL() {
			if (!!imageProps?.uids?.studyUid && _.has(STUDY_IMAGE_DATA, imageProps.uids.studyUid)) {
				setImageUrl(STUDY_IMAGE_DATA[imageProps.uids.studyUid]);
				if (setSelectedImageNumber && !isThumbnail) {
					setSelectedImageNumber(selectedImageIndex + 1);
				}
				return;
			}
			if (!!imageProps?.uids?.studyUid && !!imageProps?.uids?.seriesUid && !!imageProps?.uids?.instanceUid) {
				const { uids, managingOrgId, rows, columns, frame, type } = imageProps;

				const REQUEST_URL = `${BaseUrl}${_.join(
					_.compact([
						`studyUID=${uids.studyUid}`,
						`seriesUID=${uids.seriesUid}`,
						`objectUID=${uids.instanceUid}`,
						`managingOrganizationId=${managingOrgId}`,
						columns && `columns=${columns}`,
						rows && `rows=${rows}`,
						window?.center && `windowCenter=${window.center}`,
						window?.width && `windowWidth=${window.width}`,
						`isThumbnail=true`,
						`frameNumber=${frame || 1}`,
						`contentType=${type || 'image/jpeg'}`,
					]),
					'&'
				)}`;

				try {
					const image = await axios.get(REQUEST_URL, {
						responseType: 'arraybuffer',
					});
					if (image.status === 200) {
						const { Buffer } = require('buffer');
						setImageUrl(`data:image/jpeg;base64,${Buffer.from(image.data).toString('base64')}`);
						if (setSelectedImageNumber && !isThumbnail) {
							setSelectedImageNumber(selectedImageIndex + 1);
						}
					} else {
						setImageUrl(EmptyState3);
					}
				} catch (error) {
					console.error(error);
				}
			} else {
				setImageUrl(EmptyState3);
			}
		})();
	}, [isThumbnail, imageProps]);

	return isThumbnail ? (
		<StudyThumbnailImage imageUrl={imageUrl} style={{ ...style }} />
	) : (
		<StudyPreviewImage imageUrl={imageUrl} style={{ ...style }} />
	);
};

StudyImage.propTypes = {
	/**
	 * Is the image to be a isThumbnail? Defaults to true for now
	 */
	isThumbnail: PropTypes.bool,
	/**
	 * Query parameters to specify certain properties of the rendered image
	 */
	imageProps: PropTypes.shape({
		/**
		 * An object containing the study, series, and instance UIDs of the object we want to render
		 */
		uids: PropTypes.shape({
			studyUid: PropTypes.string,
			seriesUid: PropTypes.string,
			instanceUid: PropTypes.string,
		}),
		/**
		 * The ID of the managing organization to whom the image belongs
		 */
		managingOrgId: PropTypes.number,

		/**
		 * Boolean flag to show annotations. Defaults to false.
		 */
		annotations: PropTypes.bool,
		/**
		 * The window/level values
		 */
		window: PropTypes.shape({
			center: PropTypes.number,
			width: PropTypes.number,
		}),
		/**
		 * The number of rows in the image
		 */
		rows: PropTypes.number,
		/**
		 * The number of columns in the image
		 */
		columns: PropTypes.number,
		/**
		 * IF the image is part of a series, which frame number should we request?
		 */
		frame: PropTypes.number,
		/**
		 * The MIME type of the image; currently only support 'image/jpeg'
		 */
		type: PropTypes.oneOf(['image/jpeg']),
		/**
		 * Function to set the image pagination in parent
		 */
		setSelectedImageNumber: PropTypes.func,
		/**
		 * The current selected image index
		 */
		selectedImageIndex: PropTypes.number,
	}),
};

export default StudyImage;
