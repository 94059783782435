import React, { useEffect, useState, useRef } from 'react';
import { ReactMouseSelect } from 'react-mouse-select';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import axios from 'axios';

import { useConfig } from '@worklist-2/core/src';

import {
	Panel,
	DraftItem,
	InsertTextBlock,
	InsertTextBlockPlaceholder,
	InsertAttachmentBlockPlaceholder,
	SelectedPopup,
} from '..';
import { EmptyArticle } from '../../Layout';
import { useHelpCenterContext } from '../../HelpCenter/HelpCenterContext';

import styles from './MainArea.module.scss';
import './styles.css';

const MainArea = ({
	theme,
	initDraft,
	setInitDraft,
	documentType,
	activeCategory,
	activeButton,
	setActiveButton,
	setIsSectionsOpen,
	currentTextBlock,
	setCurrentTextBlock,
	setCurrentDocId,
	selectedAttachment,
	setSelectedAttachment,
	attachType,
}) => {
	const [draft, setDraft] = useState([]);
	const [activePanel, setActivePanel] = useState({ id: '', name: '' });
	const [getCurrentDraft, setGetCurrentDraft] = useState(null);
	const [draggingItem, setDraggingItem] = useState(false);
	const [dragging, setDragging] = useState(false);
	const [startX, setStartX] = useState(0);
	const [startY, setStartY] = useState(0);

	const responsiveRef = useRef(null);
	const containerRef = useRef(null);
	const selectedPopupRef = useRef(null);

	const __config = useConfig();

	const { responsive, inputWidth, inputHeight, resolution, setResolution, setWidth } = useHelpCenterContext();

	const borderSelectionContainer = document.getElementById('portal');

	useEffect(() => {
		const resizeObserver = new ResizeObserver(event => {
			const { width } = event[0].contentRect;
			if (resolution.width > width) {
				setResolution(res => ({ ...res, width }));
				setWidth(width);
			}
		});
		if (responsiveRef.current) {
			resizeObserver.observe(responsiveRef.current);
		}

		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	useEffect(() => {
		const getDraft = async () => {
			const app = theme === 'OAI' ? 'omegaai' : 'blume';
			const path = `${app}|${
				activeCategory.subCategory.SubCategoryId
					? `${activeCategory.category.DocumentCategoryId}|${activeCategory.subCategory.SubCategoryId}`
					: activeCategory.category.DocumentCategoryId
			}`;

			const res = await axios.get(`${__config.data_sources.breeze}/documentdraft?documentpath:exact=${path}`);

			const result = res.data.entry.map(item => ({
				...item.resource,
			}));

			if (result.length === 0) {
				const payload = {
					DocumentName: theme === 'Blume' ? activeCategory.category.CategoryName : 'Title',
					DocumentContent: '',
					DocumentJson: [],
					Type: documentType || 'tech',
					Path:
						(theme === 'Blume' ? 'blume|' : 'omegaai|') +
						(activeCategory.subCategory.SubCategoryId
							? `${activeCategory.category.DocumentCategoryId}|${activeCategory.subCategory.SubCategoryId}`
							: activeCategory.category.DocumentCategoryId),
				};

				const newDocument = await axios.post(`${__config.data_sources.breeze}/documentdraft`, payload);

				if (newDocument.data.DocumentDraftId) {
					result.push(newDocument.data);
				}
			}

			setDraft(result);
			if (initDraft) {
				setActivePanel(initDraft);
				setInitDraft(null);
			} else {
				setActivePanel({
					id: result[0]?.DocumentDraftId,
					name: result[0]?.DocumentName,
				});
			}
		};

		if (activeCategory.category.DocumentCategoryId) {
			getDraft();
		} else {
			setDraft([]);
		}
	}, [activeCategory]);

	useEffect(() => {
		if (draggingItem) {
			return;
		}

		const currentIndex = draft.findIndex(item => item.DocumentDraftId === activePanel.id);
		setGetCurrentDraft(draft[currentIndex]);
	}, [draft, activePanel]);

	const handleMouseDown = e => {
		e.preventDefault();
		setDragging(true);
		setStartX(e.clientX);
		setStartY(e.clientY);
	};

	const handleMouseUp = () => {
		setDragging(false);
	};

	const handleMouseMove = e => {
		if (!dragging || !responsive) return;

		const deltaX = e.clientX - startX;
		const deltaY = e.clientY - startY;

		inputWidth(resolution.width + parseInt(deltaX));
		inputHeight(resolution.height + parseInt(deltaY));

		setStartX(e.clientX);
		setStartY(e.clientY);
	};

	const handleReorder = (list, startIndex, endIndex) => {
		const result = [...list];
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const onDragEnd = result => {
		if (!result.destination) {
			setDraggingItem(null);
			return;
		}

		const items = handleReorder(getCurrentDraft.DocumentJson, result.source.index, result.destination.index);
		const newGetCurrentDraft = { ...getCurrentDraft, DocumentJson: items };
		setGetCurrentDraft(newGetCurrentDraft);

		const currentIndex = draft.findIndex(item => item.DocumentDraftId === activePanel.id);
		const newDraft = [...draft];
		newDraft[currentIndex] = newGetCurrentDraft;
		setDraft(newDraft);

		setDraggingItem(null);
	};

	useEffect(() => {
		if (getCurrentDraft?.DocumentDraftId) {
			setCurrentDocId(getCurrentDraft.DocumentDraftId);
		} else {
			setCurrentDocId(null);
		}
	}, [getCurrentDraft]);

	useEffect(() => {
		if (getCurrentDraft?.DocumentDraftId && !draggingItem) {
			let content = '';

			getCurrentDraft.DocumentJson?.forEach(item => {
				content = `${content + item.text}; `;
			});

			const payload = {
				...getCurrentDraft,
				DocumentContent: content,
			};

			const updateDocument = async () => {
				await axios.put(
					`${__config.data_sources.breeze}/documentdraft/${getCurrentDraft.DocumentDraftId}`,
					payload
				);
			};
			updateDocument();
		}
	}, [getCurrentDraft, draggingItem]);

	useEffect(() => {
		if (!selectedAttachment) {
			return;
		}

		setDraft(prev =>
			prev.map(item => {
				if (item.DocumentDraftId === activePanel.id) {
					const newDraft = [...item.DocumentJson];
					newDraft.push({
						type: selectedAttachment.type,
						path: selectedAttachment.path,
						id: `attachmentBlock-${selectedAttachment.type}-${Date.now()}`,
					});

					return {
						...item,
						DocumentJson: newDraft,
					};
				}
				return item;
			})
		);

		setSelectedAttachment(null);
	}, [selectedAttachment]);

	const [domReady, setDomReady] = useState(false);
	const [isSelectingActive, setIsSelectingActive] = useState(false);
	const [isSelecting, setIsSelecting] = useState(false);
	const [selectedIds, setSelectedIds] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);

	const handleStartSelection = () => {
		setIsSelecting(true);
		setSelectedItems([]);
	};

	const handleFinishSelection = items => {
		const newSelectedIds = items.map(item => item.getAttribute('id'));
		setSelectedIds(newSelectedIds);
	};

	const onMergeSelectedBlocks = () => {
		setDraft(prev =>
			prev.map(elem => {
				if (elem.DocumentDraftId === activePanel.id) {
					const mergedBlocks = [];

					selectedIds.forEach(selectedItem => {
						const currentItem = elem.DocumentJson.find(item => item.id === selectedItem);
						mergedBlocks.push(currentItem);
					});

					const newDocumentJson = elem.DocumentJson.filter(
						item => !selectedItems.some(selectedItem => item.id === selectedItem)
					);

					newDocumentJson.push({
						id: `textBlock-MergedBlocks-${Date.now()}`,
						type: 'MergedBlocks',
						mergedBlocks,
					});

					return {
						...elem,
						DocumentJson: newDocumentJson,
					};
				}
				return elem;
			})
		);

		setSelectedIds([]);
		setSelectedItems([]);
		setIsSelecting(false);
	};

	const onDeleteSelectedBlocks = () => {
		setDraft(prev =>
			prev.map(elem => {
				if (elem.DocumentDraftId === activePanel.id) {
					const newDocumentJson = elem.DocumentJson.filter(
						item => !selectedItems.some(selectedItem => item.id === selectedItem)
					);

					return {
						...elem,
						DocumentJson: newDocumentJson,
					};
				}
				return elem;
			})
		);
		setSelectedIds([]);
		setSelectedItems([]);
		setIsSelecting(false);
	};

	useEffect(() => {
		if (selectedIds.length > 0) {
			const arrayOfTextBlocksCount = [
				{ type: 'Header', count: 0, ids: [] },
				{ type: 'Paragraph', count: 0, ids: [] },
				{ type: 'Quote', count: 0, ids: [] },
				{ type: 'Callout', count: 0, ids: [] },
				{ type: 'Bullet List', count: 0, ids: [] },
				{ type: 'Number List', count: 0, ids: [] },
				{ type: 'Check List', count: 0, ids: [] },
				{ type: 'Table', count: 0, ids: [] },
			];

			selectedIds.forEach(elem => {
				const currentItem = getCurrentDraft?.DocumentJson?.find(item => item.id === elem);
				arrayOfTextBlocksCount.forEach(textBlock => {
					if (textBlock.type === currentItem.type) {
						return {
							...textBlock,
							count: textBlock.count++,
							ids: textBlock.ids.push(elem),
						};
					}
				});
			});

			const countsOfTextBlocks = arrayOfTextBlocksCount.map(object => object.count);
			const maxCountOfTextBlocks = Math.max(...countsOfTextBlocks);
			const resultTextBlocks = arrayOfTextBlocksCount.find(element => element.count === maxCountOfTextBlocks);

			setSelectedItems(resultTextBlocks.ids);
		}
	}, [selectedIds, getCurrentDraft]);

	useEffect(() => {
		setDomReady(true);
	}, []);

	const handleOutSideClick = event => {
		if (
			containerRef.current &&
			!containerRef.current.contains(event.target) &&
			selectedPopupRef.current &&
			!selectedPopupRef.current.contains(event.target)
		) {
			setSelectedIds([]);
			setSelectedItems([]);
			setIsSelecting(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleOutSideClick);

		return () => {
			document.removeEventListener('mousedown', handleOutSideClick);
		};
	}, []);

	return (
		<>
			<div
				ref={responsiveRef}
				className={styles.mainAreaContainer}
				onMouseMove={handleMouseMove}
				onMouseUp={handleMouseUp}
			>
				<div
					className={`${theme === 'OAI' ? styles.mainAreaOAI : styles.mainAreaBlume}`}
					style={{ width: `${resolution.width}px`, height: `${resolution.height}px` }}
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
				>
					{responsive && (
						<div>
							<div className={styles.resizeHandleWidth} onMouseDown={handleMouseDown}>
								<div className={styles.bar} />
							</div>
							<div className={styles.resizeHandleHeight} onMouseDown={handleMouseDown}>
								<div className={styles.bar} />
							</div>
						</div>
					)}

					{theme === 'OAI' ? (
						<Panel
							activeCategory={activeCategory}
							activePanel={activePanel}
							documentType={documentType}
							draft={draft}
							setActivePanel={setActivePanel}
							setDraft={setDraft}
						/>
					) : (
						''
					)}
					<div className={`${theme === 'OAI' ? styles.canvasOAI : styles.canvasBlume}`}>
						<div ref={containerRef} className="container">
							<DragDropContext
								onBeforeDragStart={ctx =>
									setDraggingItem({ draggableId: ctx.draggableId, status: ctx.source.droppableId })
								}
								onDragEnd={onDragEnd}
							>
								<Droppable droppableId="droppable">
									{provided => (
										<div ref={provided.innerRef} {...provided.droppableProps}>
											{getCurrentDraft?.DocumentJson?.map((item, index) => (
												<div key={item.id} className="mouse-select__selectable" id={item.id}>
													<DraftItem
														key={item.id}
														activePanel={activePanel}
														draggingItem={draggingItem}
														index={index}
														isSelected={selectedItems.includes(item.id)}
														isSelecting={isSelecting}
														item={item}
														setActiveButton={setActiveButton}
														setCurrentTextBlock={setCurrentTextBlock}
														setDraft={setDraft}
														setIsSectionsOpen={setIsSectionsOpen}
														setIsSelectingActive={setIsSelectingActive}
														theme={theme}
													/>
												</div>
											))}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</div>
						{currentTextBlock.type ? (
							<InsertTextBlock
								activePanel={activePanel}
								currentTextBlock={currentTextBlock}
								setCurrentTextBlock={setCurrentTextBlock}
								setDraft={setDraft}
								theme={theme}
							/>
						) : (
							''
						)}
						{activeCategory.category.DocumentCategoryId && activeButton === 'text' ? (
							<InsertTextBlockPlaceholder theme={theme} />
						) : (
							''
						)}
						{activeCategory.category.DocumentCategoryId && activeButton === 'attach' ? (
							<InsertAttachmentBlockPlaceholder attachType={attachType} theme={theme} />
						) : (
							''
						)}
						{activeCategory.category.DocumentCategoryId || draft.length > 0 ? (
							''
						) : (
							<EmptyArticle theme={theme} />
						)}
						{isSelecting && selectedItems.length > 0 ? (
							<SelectedPopup
								selectedItems={selectedItems}
								selectedPopupRef={selectedPopupRef}
								onDelete={onDeleteSelectedBlocks}
								onMerge={onMergeSelectedBlocks}
							/>
						) : (
							''
						)}
						{domReady && isSelectingActive && (
							<ReactMouseSelect
								containerRef={containerRef}
								finishSelectionCallback={handleFinishSelection}
								itemClassName="mouse-select__selectable"
								portalContainer={borderSelectionContainer}
								startSelectionCallback={handleStartSelection}
							/>
						)}
					</div>
				</div>
			</div>
			<div id="portal" />
		</>
	);
};

export default MainArea;
