import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppModeContext } from '@rs-core/context/AppModeContext';
import { useRouter } from '@rs-core/hooks/useRouter';
import { useSearchScope } from '@rs-core/context/SearchScopeContext';
import { useAuth } from '@rs-core/context/UserAuthContext';
import { useIsTablet, useIsMobile } from '@rs-core/utils/responsiveUtils';

import AutoSuggestDialogV2 from '@rs-ui/components/GlobalSearch/GlobalSearchV2/AutoSuggestDialogV2/AutoSuggestDialogV2';
import SearchResultItem, { getItemPageUrl } from '@rs-ui/components/SearchResults/SearchResultItem';
import SendDrawer from '@rs-ui/components/Worklist/WorklistGrid/SendDrawer';

import RecentlySearchedIcon from '@rs-ui/assets/icons/GlobalSearch/RecentlySearchedIcon';
import RightArrow from '@rs-ui/assets/icons/GlobalSearch/RightArrow';

import { categoriesList } from '@rs-ui/components/GlobalSearch/GlobalSearchV2/categoriesList';

import styles from './SearchDialogV2.module.scss';
import EmergencySearch from './EmergencySearch';

import EmergencySearchDrawer from '@rs-ui/components/Drawers/EmergencySearchDrawer';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const SearchDialogV2 = ({
	closeSearchDialog,
	isLoadingUserId,
	loggedInUserId,
	scope,
	onGoToUser,
	setIsRecentSearch,
	setSearchText,
	setStudyInDetailedView,
	setIsSharedType,
	select,
	setSelect,
	inputRef,
	setTabsValue,
	openArticlesSearchResult,
	data,
	handleSaveRecentlyViewed,
	showDialogHeader,
	setParentData,
	additionalSearchParams,
	dialog,
	searchText,
	isDicomDialog,
	setIsClickAwayDisabled,
}) => {
	const [searchedItems, setSearchedItems] = useState([]);
	const [recentlyViewed, setRecentlyViewed] = useState([]);
	const [categoriesListWithPermission, setCategoriesListWithPermission] = useState([]);
	const [emergencySearchOpen, setEmergencySearchOpen] = useState(false);

	const metaEmergencySearchV2 = useBooleanFlagValue('meta-emergency-search-v2');
	const proactHideMarketplace = useBooleanFlagValue('proact-hide-marketplace');
	const wonHelpLaunchNewManual = useBooleanFlagValue('won-help-launchnewmanual');

	const {
		getItemCountRecentViewed,
		getItemCountRecentSearch,
		isWorklistMode,
		isHelpCenterMode,
		setIsEmergencySearch,
	} = useAppModeContext();

	const appModeIsWorklist = isWorklistMode();
	const appModeIsHelpCenter = isHelpCenterMode();

	const isTablet = useIsTablet();
	const isMobile = useIsMobile();

	const itemCountRecentSearch = getItemCountRecentSearch();
	const itemCountRecentViewed = getItemCountRecentViewed();

	const { t } = useTranslation('Search');
	const { selectedLanguage, globalPermissionsForLoggedUser, practitionerRole } = useAuth();
	const { updateScope } = useSearchScope(useSearchScope);
	const { goTo } = useRouter();

	useEffect(() => {
		if (scope) {
			const raw = {
				searchItems: localStorage.getItem('recentSearch'),
				viewItems: localStorage.getItem('recentViewed'),
			};

			const parsed = {
				searchItems: [],
				viewItems: [],
			};

			try {
				parsed.searchItems = JSON.parse(raw.searchItems);
				parsed.viewItems = JSON.parse(raw.viewItems);
			} catch (error) {
				throw new TypeError(
					'Recently searched and viewed items could not be parsed from `localStorage`. Check their content to see whether they contain a valid JSON object!'
				);
			}

			if (raw.searchItems) {
				setSearchedItems(parsed.searchItems.filter(({ type }) => type === scope));
			}

			if (raw.viewItems) {
				setRecentlyViewed(parsed.viewItems.filter(({ viewType }) => viewType === scope));
			}
		}
	}, [scope]);

	useEffect(() => {
		const newCategoriesList = categoriesList.filter(
			item => globalPermissionsForLoggedUser[item.permissionLabel] === true
		);

		let modifiedCategoryList = proactHideMarketplace
			? newCategoriesList.filter(item => item.label !== 'Marketplace')
			: newCategoriesList;

		if (wonHelpLaunchNewManual) {
			modifiedCategoryList = modifiedCategoryList.filter(item => item.label !== 'Help');
		}

		setCategoriesListWithPermission(modifiedCategoryList);
	}, [globalPermissionsForLoggedUser]);

	const getClickHandler = item => {
		const url = getItemPageUrl(item, loggedInUserId, appModeIsWorklist || appModeIsHelpCenter, selectedLanguage);

		if ((isMobile || isTablet) && item.ResourceType === 'BlumeStudy')
			return () => {
				setStudyInDetailedView({
					study: {
						id: item.id,
					},
				});
			};
		if ((isMobile || isTablet) && item.ResourceType === 'StudySharedWithMe')
			return () => {
				setIsSharedType('withMe');
				setStudyInDetailedView({
					study: {
						id: item.id,
					},
				});
			};
		if (url || item.resourceType === 'Practitioner')
			return async () => {
				if (url) {
					goTo.any(url);
				} else {
					await onGoToUser(item);
				}
				closeSearchDialog();
			};
		return undefined;
	};

	const [isTriggeredScroll, setIsTriggeredScroll] = useState(false);

	const onRightClick = () => {
		document.getElementById('categoriesList').scrollLeft += 170;
		setIsTriggeredScroll(true);
	};

	const onLeftClick = () => {
		document.getElementById('categoriesList').scrollLeft -= 170;
		setIsTriggeredScroll(false);
	};

	const onCategoryClick = category => {
		if (!select.find(item => item.label === category.label)) {
			if (category.label === 'Dicom' || category.label === 'Marketplace' || category.label === 'Help') {
				setSearchText('');
				category.label === 'Dicom' && updateScope(category.context);
				category.label === 'Marketplace' && updateScope(category.context);
				setSelect([category]);
			} else if (select.length > 0) {
				if (select[0]?.label !== 'Dicom' && select[0]?.label !== 'Marketplace' && select[0]?.label !== 'Help') {
					setSelect(prev => [...prev, category]);
				} else {
					updateScope('searchAll');
					setSearchText('');
					setSelect([category]);
				}
			} else {
				setSelect(prev => [...prev, category]);
			}
		}
		inputRef && inputRef.current.focus();
	};

	const isReferringPhysician = practitionerRole?.some(role =>
		role.resource.extension.some(extension => extension.url.includes('isReferring') && !extension.valueBoolean)
	);

	return (
		<div className={styles.searchDialog} data-testid="search-dialog">
			{isReferringPhysician && (
				<div className={styles.top}>
					{categoriesListWithPermission?.length > 0 && (
						<>
							<span className={styles.title}>I’m looking for...</span>
							<div className={styles.categories} id="categoriesList">
								{isTriggeredScroll && (
									<div className={styles.leftArrow} data-testid="leftArrow" onClick={onLeftClick}>
										<RightArrow />
									</div>
								)}
								{categoriesListWithPermission.map(item => (
									<div
										key={item.label}
										className={`${styles.categoriesItem}`}
										data-testid={`category-item-${item.label}`}
										onClick={() => onCategoryClick(item)}
									>
										<span className={styles.categoriesItemLabel}>{item.label}</span>
									</div>
								))}
								{!isTriggeredScroll && (
									<div className={styles.rightArrow} data-testid="rightArrow" onClick={onRightClick}>
										<RightArrow />
									</div>
								)}
							</div>
						</>
					)}
				</div>
			)}
			{dialog && searchText && data?.length > 0 && !isDicomDialog ? (
				<AutoSuggestDialogV2
					additionalSearchParams={additionalSearchParams}
					closeSearchDialog={closeSearchDialog}
					data={data}
					handleSaveRecentlyViewed={handleSaveRecentlyViewed}
					isLoadingUserId={isLoadingUserId}
					loggedInUserId={loggedInUserId}
					openArticlesSearchResult={openArticlesSearchResult}
					scope={scope}
					select={select}
					setEmergencySearchOpen={setEmergencySearchOpen}
					setIsClickAwayDisabled={setIsClickAwayDisabled}
					setParentData={setParentData}
					setSearchText={setSearchText}
					setTabsValue={setTabsValue}
					showDialogHeader={showDialogHeader}
					showEmergencySearch={metaEmergencySearchV2 && globalPermissionsForLoggedUser.SearchEmergencySearch}
					value={searchText}
					onGoToUser={onGoToUser}
				/>
			) : (
				<div className={styles.bottom}>
					{metaEmergencySearchV2 && globalPermissionsForLoggedUser.SearchEmergencySearch && (
						<EmergencySearch setEmergencySearchOpen={setEmergencySearchOpen} t={t} />
					)}

					{recentlyViewed.length > 0 && (
						<div
							className={`${styles.recentlyViewed} ${
								metaEmergencySearchV2 && globalPermissionsForLoggedUser.SearchEmergencySearch
									? styles.recentlyViewedWithEmergencySearch
									: ''
							}`}
						>
							<span className={styles.recentlyViewedTitle}>{t('RECENTLY VIEWED')}</span>
							<div className={styles.recentlyViewedList}>
								{recentlyViewed.slice(0, itemCountRecentViewed).map(item => {
									const onClick = getClickHandler(item);

									return (
										<div
											key={`${item.id}_${item.resourceType}`}
											className={styles.recentlyViewedItem}
											data-cy="recent-viewed-items"
											onClick={() => {
												setIsEmergencySearch(false);
												return onClick();
											}}
										>
											<SearchResultItem
												isAccess
												showButton
												SendDrawer={SendDrawer}
												item={item}
												setIsClickAwayDisabled={setIsClickAwayDisabled}
												showProgress={
													isLoadingUserId &&
													item.resourceType === 'Practitioner' &&
													item.id === isLoadingUserId
												}
												studyBtnClassName={styles.studyBtn}
												type="search"
												onCloseDialog={closeSearchDialog}
												onGoTo={onClick}
											/>
										</div>
									);
								})}
							</div>
						</div>
					)}

					{searchedItems.length > 0 && (
						<div className={styles.recentlySearched}>
							<span className={styles.recentlySearchedTitle}>{t('RECENTLY SEARCHED')}</span>
							<div className={styles.searchedItemList}>
								{searchedItems.slice(0, itemCountRecentSearch).map(item => (
									<div
										key={item.content}
										className={styles.searchedItem}
										data-cy="recent-searched-items"
										onClick={() => {
											setSearchText(item.content);
											closeSearchDialog();
											setIsRecentSearch(true);
										}}
									>
										<RecentlySearchedIcon />
										<span>{item.content}</span>
									</div>
								))}
							</div>
						</div>
					)}
				</div>
			)}
			<Suspense fallback={null}>
				<EmergencySearchDrawer
					drawerOpen={emergencySearchOpen}
					loggedInUserId={loggedInUserId}
					setDrawerOpen={setEmergencySearchOpen}
					onCloseDialog={closeSearchDialog}
				/>
			</Suspense>
		</div>
	);
};

export default SearchDialogV2;
