// core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

const APISelectAutocomplete = ({
	formField,
	model,
	disabled,
	variant,
	helperText,
	buttonIcon,
	buttonLabel,
	onChange,
	onButtonClick,
}) => {
	const [options, setOptions] = useState(formField?.preOptions || []);
	const defaultValue = formField.mapping.split('.').reduce((p, c) => (p && p[c]) || null, model);
	const { t } = useTranslation('common');
	const freeSolo = formField?.freeSolo || false;

	const renderButtonPaper = ({ containerProps, children }) => (
		<Paper
			sx={{
				background: '#393939',
				display: 'flex',
				flexDirection: 'column',
				border: '0px',
				'.MuiAutocomplete-noOptions': {
					fontFamily: 'fontFamily',
				},
				'.MuiAutocomplete-loading': {
					fontFamily: 'fontFamily',
				},
				'.MuiAutocomplete-option': {
					fontFamily: 'fontFamily',
				},
			}}
			{...containerProps}
		>
			{onButtonClick && (
				<>
					<Button
						startIcon={buttonIcon || ''}
						sx={{
							'& .MuiButton-startIcon': {},
							justifyContent: 'left',
							height: '36px',
						}}
						onMouseDown={onButtonClick}
					>
						{buttonLabel}
					</Button>
					<Divider />
				</>
			)}
			{children}
		</Paper>
	);

	return !disabled ? (
		<Autocomplete
			PaperComponent={buttonLabel && renderButtonPaper}
			disabled={disabled}
			freeSolo={freeSolo}
			getOptionLabel={formField.getOptionLabel}
			id={formField.id ? formField.id : `form-field-${formField.name}`}
			noOptionsText={t('apiSelectAutocomplete.noOptionsText')}
			options={options}
			renderInput={params => (
				<TextField
					{...params}
					InputLabelProps={{ shrink: true }}
					disabled={disabled}
					helperText={helperText}
					label={formField.name}
					placeholder={formField?.placeholder}
				/>
			)}
			renderOption={formField?.renderOption}
			value={defaultValue}
			onChange={(event, newValue) => {
				event.stopPropagation(); // This is needed to prevent onClick in GridInput that calls onChange
				const value = newValue || formField.defaultValue || {};
				const remove =
					['name.0.suffix.0', 'suffix', 'prefix', 'name.0.prefix.0'].includes(formField.mapping) &&
					typeof value === 'object' &&
					Object.keys(value).length === 0;
				onChange(value, value, formField.mapping, remove, formField.customModelUpdate);
			}}
			onInputChange={async (event, newInputValue) => {
				if (newInputValue !== '' && formField?.onSearch) {
					const res = await formField.onSearch(newInputValue, model);
					setOptions(res);
				} else {
					const value = freeSolo
						? newInputValue || formField.defaultValue || {}
						: formField.defaultValue || {};
					const remove =
						['name.0.suffix.0', 'suffix', 'prefix', 'name.0.prefix.0'].includes(formField.mapping) &&
						typeof value === 'object' &&
						Object.keys(value).length === 0;
					onChange(value, value, formField.mapping, remove, formField.customModelUpdate);
				}
			}}
		/>
	) : (
		<TextField
			disabled
			fullWidth
			InputLabelProps={{ shrink: true }}
			defaultValue={formField.getOptionLabel ? formField.getOptionLabel(defaultValue) : defaultValue}
			id={formField.id ? formField.id : `form-field-${formField.name}`}
			label={formField.name}
			placeholder={disabled ? null : formField.placeholder}
			variant={variant || 'outlined'}
		/>
	);
};

export default APISelectAutocomplete;
