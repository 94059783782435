import React from 'react';

const HighlightingIcon = () => (
	<svg fill="none" height={16} width={16} xmlns="http://www.w3.org/2000/svg">
		<mask
			height={16}
			id="a"
			maskUnits="userSpaceOnUse"
			style={{
				maskType: 'alpha',
			}}
			width={16}
			x={0}
			y={0}
		>
			<path d="M0 0h16v16H0z" fill="#D9D9D9" />
		</mask>
		<g mask="url(#a)">
			<path
				d="M1.333 16v-2h13.334v2H1.333Zm8.202-7.904L7.8 6.363 5.173 8.99a.192.192 0 0 0-.058.141c0 .056.02.103.058.141l1.441 1.451a.192.192 0 0 0 .141.058c.056 0 .103-.02.141-.058l2.639-2.628ZM8.514 5.66l1.723 1.723 2.846-2.836a.2.2 0 0 0 .058-.147.2.2 0 0 0-.058-.147l-1.438-1.439a.2.2 0 0 0-.148-.058.2.2 0 0 0-.147.058L8.514 5.66ZM7.46 5.3 10.6 8.437l-2.99 3a1.162 1.162 0 0 1-.849.362c-.325 0-.608-.12-.849-.362l-.064-.064-.61.57h-2.59l1.895-1.885-.051-.051a1.211 1.211 0 0 1-.372-.86c-.007-.331.11-.617.351-.858l2.99-2.99Zm0 0 3.194-3.194c.24-.24.524-.361.848-.361.325 0 .608.12.85.361l1.45 1.441c.242.241.362.524.362.849 0 .325-.12.608-.361.849l-3.204 3.193L7.46 5.3Z"
				fill="#fff"
			/>
		</g>
	</svg>
);

export default HighlightingIcon;
