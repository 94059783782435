import { logDebug } from '@rs-core/index';
import { compressObjectToArrayBuffer, decompressArrayBufferToObject } from '@rs-core/utils';

const zustandShare = (key, api, { ref = 'shared-', initialize = false, wonIvBroadcastMsgCompress = false } = {}) => {
	const channelName = `${ref}-${key.toString()}`;

	const channel = new BroadcastChannel(channelName);
	let externalUpdate = false;
	let timestamp = 0;

	const cleanup = api.subscribe(
		state => state[key],
		state => {
			if (!externalUpdate) {
				timestamp = Date.now();
				const message = { timestamp, state };

				logDebug('useZustandShare', `channel.postMessage - KEY: ${key} - Start posting`, {
					timestamp: Date.now(),
				});

				if (wonIvBroadcastMsgCompress) {
					const compressedBuffer = compressObjectToArrayBuffer(message);
					logDebug('useZustandShare', `channel.postMessage - KEY: ${key} - After compression`, {
						byteLength: compressedBuffer.byteLength,
						timestamp: Date.now(),
					});
					channel.postMessage(compressedBuffer);
				} else {
					channel.postMessage(message);
				}
			}
			externalUpdate = false;
		}
	);

	channel.onmessage = evt => {
		logDebug('useZustandShare', `channel.onmessage - KEY: ${key} - Start`, { timestamp: Date.now() });
		if (evt.data === undefined) {
			const message = { timestamp, state: api.getState()[key] };

			if (wonIvBroadcastMsgCompress) {
				if (timestamp !== 0) {
					logDebug('useZustandShare', `channel.onmessage - channel.postMessage - KEY: ${key}`, {
						timestamp: Date.now(),
					});
					const compressedBuffer = compressObjectToArrayBuffer(message);
					logDebug(
						'useZustandShare',
						`channel.onmessage - channel.postMessage - KEY: ${key} - After compression`,
						{ byteLength: compressedBuffer.byteLength, timestamp: Date.now() }
					);
					channel.postMessage(compressedBuffer);
				}
			} else {
				channel.postMessage(message);
			}

			return;
		}

		let message = evt.data;
		if (wonIvBroadcastMsgCompress) {
			// decompress message after receiving
			message = decompressArrayBufferToObject(evt.data);
			logDebug('useZustandShare', `channel.onmessage - KEY: ${key} - after decompress`, {
				timestamp: Date.now(),
			});
		}

		if (message.timestamp <= timestamp) {
			return;
		}

		if (api.getState()[key] === message.state) {
			return;
		}

		externalUpdate = true;
		timestamp = message.timestamp;

		api.setState({ [key]: message.state });
	};

	const sync = () => channel.postMessage(undefined);

	const unshare = () => {
		cleanup();
		channel.close();
	};

	if (initialize) {
		sync();
	}

	return [sync, unshare];
};

export { zustandShare };
