/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
import patientActivitySchema from './schemata/patientActivitySchema';
/************************************************************************************************************************
Resource: PatientActivity
Reference: N/A
Proprietary RamSoft resource
************************************************************************************************************************/

export default class PatientActivityResource extends FhirDomainResource {
	id;
	subject;
	internalPatientID;
	practitioner;
	activityType;
	callType;
	comment;
	loginName;
	userName;

	constructor(resourceString) {
		super(resourceString, patientActivitySchema);
		this.resourceType = 'PatientActivity';
		this.populateFields();
	}
}
