import React from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import MoreIcon from '../assets/icons/MoreIcon';

const ReviewProgressPopover = ({ isOmega, isOpen, dates }) =>
	isOpen ? (
		<Box
			data-testid="reviewProgressPopoverWrapper"
			sx={{
				zIndex: 1,
				position: 'absolute',
				top: '26px',
				right: '6px',
				width: '158px',
				display: 'block',
				borderRadius: '5px',
				border: isOmega ? '1px solid rgba(255, 255, 255, 0.10)' : '1px solid rgba(196, 196, 196, 0.40)',
				background: isOmega ? '#393939' : '#FFF',
				boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.11)',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					padding: '6px 10px',
					borderBottom: '1px solid rgba(196, 196, 196, 0.40)',
				}}
			>
				<VisibilityOutlinedIcon sx={{ color: isOmega ? '#C4C4C4' : '#4D79EA' }} />
				<Typography
					sx={{
						marginLeft: '10px',
						fontFamily: 'Inter',
						fontSize: '14px',
						fontWeight: 500,
						color: isOmega ? '#FFF' : '#4D79EA',
					}}
				>
					Seen
				</Typography>
			</Box>
			<Box
				sx={{
					padding: '14px 10px 10px 14px',
				}}
			>
				<ReviewProgressItem
					isFirstItem
					date={dates?.CreatedDateTime}
					icon={MailOutlineOutlinedIcon}
					isOmega={isOmega}
					title="Invite Sent"
				/>
				<ReviewProgressItem
					date={dates.SeenDateTime}
					icon={DraftsOutlinedIcon}
					isOmega={isOmega}
					title="Invite Opened"
				/>
				<ReviewProgressItem
					date={dates.ReviewedDateTime}
					icon={RateReviewOutlinedIcon}
					isOmega={isOmega}
					title="Reviewed"
				/>
			</Box>
		</Box>
	) : null;

const ReviewProgressItem = ({ icon, title, date, isOmega, isFirstItem }) => {
	const Icon = icon;
	const iconColor = date ? '#4D79EA' : '#C4C4C4';

	return (
		<Box sx={{ position: 'relative', marginBottom: '12px', display: 'flex', alignItems: 'center' }}>
			{!isFirstItem && (
				<MoreIcon
					sx={{
						position: 'absolute',
						top: '-16px',
						left: '6px',
						color: iconColor,
						opacity: date ? '1' : '0.4',
					}}
				/>
			)}
			<Icon sx={{ fontSize: '15px', color: iconColor }} />
			<Box sx={{ marginLeft: '16px' }}>
				<Typography
					sx={{
						fontFamily: 'Inter',
						fontSize: '14px',
						fontWeight: 500,
						lineHeight: '16px',
						color: isOmega ? '#FFF' : 'rgba(0, 0, 0, 0.60)',
					}}
				>
					{title}
				</Typography>
				<Typography
					sx={{
						height: '12px',
						fontFamily: 'Inter',
						fontSize: '10px',
						lineHeight: '12px',
						color: isOmega ? 'rgba(255, 255, 255, 0.60)' : 'rgba(0, 0, 0, 0.40)',
					}}
				>
					{date ? moment(date).format('MMM D [•] h:mm A') : ''}
				</Typography>
			</Box>
		</Box>
	);
};

export default ReviewProgressPopover;
