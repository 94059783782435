import { height } from '@mui/system';
import { after } from 'lodash';
import React from 'react';
import AccordionCard from './AccordionCard';
import './AccordionContainer.css';

function AccordionContainer({
	dataSet,
	objective,
	selectedOwners,
	updateData,
	onEditIdea,
	onEditIdeaOwner,
	keyResultIndex,
	onEditIdeaStatus,
	getStyleByStatus,
	hoveredIndex,
	setHoveredIndex,
	onSetKeyResultVariable,
	ObjectiveIndex,
	moveIdeaListItem,
}) {
	return (
		<div className="accordian_container" style={{ display: dataSet.length > 0 ? '' : 'none' }}>
			{dataSet.map((data, index) => (
				<AccordionCard
					ObjectiveIndex={ObjectiveIndex}
					data={data}
					description={data?.Idea}
					getStyleByStatus={getStyleByStatus}
					index={index}
					isActive={index === hoveredIndex}
					keyResultIndex={keyResultIndex}
					moveIdeaListItem={moveIdeaListItem}
					objective={objective}
					progress={data.Progress}
					selectedOwners={selectedOwners}
					status={data.Status}
					updateData={updateData}
					userName={data.Owner?.split(' ')
						.map(n => n.charAt(0).toUpperCase())
						.join('')}
					onEditIdea={onEditIdea}
					onEditIdeaOwner={onEditIdeaOwner}
					onEditIdeaStatus={onEditIdeaStatus}
					onMouseLeave={() => setHoveredIndex(-1)}
					onMouseOver={() => {
						setHoveredIndex(index);
						onSetKeyResultVariable(objective, keyResultIndex, 'hovered', false);
					}}
					onSetKeyResultVariable={onSetKeyResultVariable}
				/>
			))}
		</div>
	);
}

export default AccordionContainer;
