import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import UserAvatar from './UserAvatar';

function AvailableAgents({ setCurrentTab }) {
	const [selectedIndex, setSelectedIndex] = useState('');

	return (
		<div>
			<div
				style={{
					height: '66vh',
				}}
			>
				<div
					style={{
						borderBottom: '1px solid #C4C4C466',
					}}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							width: '503px',
							margin: '0 auto',
							height: '67px',
						}}
					>
						<UserAvatar size={38} userName="John Doe" />
						<div
							style={{
								width: '207px',
								paddingLeft: '17px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Inter',
									fontWeight: '500',
									fontSize: '18px',
									width: '100%',
									lineHeight: '21px',
									color: '#000',
								}}
							>
								John Doe
							</Typography>
							<Typography
								style={{
									fontFamily: 'Inter',
									fontWeight: '500',
									fontSize: '16px',
									color: '#00000099',
									lineHeight: '19px',
								}}
							>
								Support Team
							</Typography>
						</div>
						<Typography
							sx={{
								width: '76px',
								backgroundColor: '#C4C4C466',
								borderRadius: '5px',
								color: '#00000066',
								fontFamily: 'Inter',
								fontWeight: '500',
								fontSize: '16px',
								textAlign: 'center',
							}}
						/>
						<div onMouseOut={() => setSelectedIndex('')} onMouseOver={() => setSelectedIndex(0)}>
							{selectedIndex === 0 ? (
								<Button
									sx={{
										color: '#4D79EA',
										border: '1px solid #4D79EA',
										borderRadius: '5px',
										fontFamily: 'Inter',
										fontWeight: '500',
										fontSize: '14px',
										marginLeft: '23px',
										height: '33px',
									}}
									variant="outlined"
								>
									CALL
								</Button>
							) : (
								<Typography
									sx={{
										width: '126px',
										borderRadius: '5px',
										color: '#00000066',
										fontFamily: 'Inter',
										fontWeight: '500',
										fontSize: '16px',
										textAlign: 'right',
									}}
								>
									Available
								</Typography>
							)}
						</div>
					</div>
				</div>
			</div>
			<Button
				sx={{
					color: '#4D79EA',
					border: '1px solid #4D79EA',
					borderRadius: '5px',
					fontFamily: 'Inter',
					fontWeight: '500',
					fontSize: '14px',
					marginLeft: '23px',
					height: '33px',
				}}
				variant="outlined"
				onClick={() => setCurrentTab('Dialpad')}
			>
				CLOSE
			</Button>
		</div>
	);
}

export default AvailableAgents;
