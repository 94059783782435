import React, { useEffect, useState } from 'react';
import { Card, CustomSwitch, Header, Knob, Title } from '../../../../../Breeze/src/views/CrmView/styles';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useNavigate } from 'react-router';
import { useCrmContext } from '../../../context/CRMContext';
import Box from '@mui/material/Box';
import RoleManagement from './RoleManagement';
import RoleAccountsTable from './RoleAccountsTable';
import TeamsHeaderCard from './TeamsHeaderCard';
import { crmSearchScopes, useAuth, useCRMDataLoader } from '@worklist-2/core/src';
import Loader from '../Loader';
import _ from 'lodash';

const AdminSettings = () => {
	const navigate = useNavigate();
	const { authorized, loggedInUser } = useAuth();
	const { toggleWidget, setRoles, isReloadUserGrid, toggleReloadUserGrid } = useCrmContext();
	const [loading, setLoading] = useState(true);
	const [teams, setTeams] = useState([]);
	const [users, setUsers] = useState([]);
	const [field, setField] = useState(null);
	const [localRoles, setLocalRoles] = useState([]);
	const [countLoading, setCountLoading] = useState(0);

	const roleDataLoader = useCRMDataLoader({ scope: crmSearchScopes.roleApi });
	const teamDataLoader = useCRMDataLoader({ scope: crmSearchScopes.teamApi });
	const userDataLoader = useCRMDataLoader({ scope: crmSearchScopes.userApi });

	useEffect(() => {
		return () => {
			toggleWidget('editRole');
			toggleWidget('newRole');
			toggleWidget('userPreview');
			toggleWidget('addUser');
		};
	}, []);

	useEffect(() => {
		if (authorized) {
			generateData();
		}
	}, [authorized]);

	useEffect(() => {
		if (isReloadUserGrid) {
			setTimeout(async () => {
				setField('');
				await filterAccount('', 'LoginName');
				toggleReloadUserGrid(false);
			}, 300);
		}
	}, [isReloadUserGrid]);

	const filterAccount = async (keywords, fieldParam = field) => {
		try {
			if (!!fieldParam) {
				const filter = async keyword => {
					let searchKey = fieldParam;
					let res = [];
					let value = {};
					if (fieldParam == 'RoleName') {
						searchKey = 'role';
					} else if (fieldParam == 'LoginName') {
						searchKey = 'username';
					}

					if (!_.isEmpty(keywords)) {
						value = {
							[searchKey]: keyword,
						};
					}

					try {
						setCountLoading(p => p + 1);
						await fetchData(userDataLoader, value).then(result => {
							res = result;
							setTimeout(() => {
								setCountLoading(p => Math.max(p - 1, 0));
							}, 0);
						});
					} catch (e) {
						setCountLoading(p => Math.max(p - 1, 0));
						console.error(e);
					}

					return res;
				};

				let results = [];
				if ((fieldParam === 'RoleName' || fieldParam === 'IsActive') && !_.isEmpty(keywords)) {
					for (const keyword of keywords) {
						const res = await filter(keyword);
						results.push(res);
					}
					// remove duplicates
					const uniqueResults = _.uniqBy(_.compact(_.flatten(results)), item => item?.UserId);
					setUsers(uniqueResults);
				} else {
					const res = await filter(keywords);
					setUsers(res);
				}
			}
			return true;
		} catch (error) {
			console.log(error);
		}
	};

	/**
	 * @info get request to breeze api
	 * @param {DataLoader} loader
	 * @param {Object} value
	 */
	const fetchData = (loader, value) => {
		return new Promise(async (resolve, reject) => {
			try {
				const fnName = 'load';
				const bodyData = undefined;
				if (!authorized) throw Error('You are unauthorized');
				const result = await loader[fnName](value, !!bodyData || undefined, bodyData);
				const newData = result;
				resolve(newData);
			} catch (error) {
				reject(error);
			}
		});
	};

	const handleRoleFilter = async keyword => {
		if (keyword) {
			const roleData = await fetchData(roleDataLoader, { name: keyword });
			setRoles(roleData);
		} else {
			await generateData();
			return true;
		}
	};

	/**
	 * @info generates the required data for admin control
	 */
	const generateData = async () => {
		try {
			if (!!loggedInUser.permission.Team?.Read) {
				const allTeams = await teamDataLoader.load({ count: 1000 });
				setTeams(allTeams);
			}
			if (!!loggedInUser.permission.Role?.Read) {
				const allRoles = await roleDataLoader.load({ count: 1000 });
				setRoles(allRoles);
				setLocalRoles(_.filter(allRoles, item => !!item.Permission));
			}
			if (!!loggedInUser.permission.User?.Read) {
				const allUsers = await userDataLoader.load({ count: 5000 });
				setUsers(allUsers);
			}

			setLoading(false);
			return true;
		} catch (error) {
			console.error(error);
		}
	};

	const updateUser = updatedUser => {
		setUsers(users.map(user => (user.UserId === updatedUser.UserId ? updatedUser : user)));
	};

	return (
		<Box>
			<Header>
				<Title>
					<ManageAccountsIcon sx={{ fontSize: 29, mr: '12px' }} />
					<span>Admin Settings</span>
				</Title>
				<CustomSwitch>
					<Knob onClick={() => navigate('/')}>
						<HomeOutlinedIcon />
					</Knob>
					<label>Admin Settings</label>
				</CustomSwitch>
			</Header>
			<Box
				sx={{
					flex: 1,
					display: 'flex',
				}}
			>
				<Box
					className="invisible-scrollbar"
					style={{
						boxShadow: 'none',
						overflow: 'hidden',
						display: 'flex',
						flexDirection: 'row',
						borderRadius: 0,
						flex: 1,
					}}
				>
					{!loading ? (
						<>
							<Box style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
								<Box style={{ display: 'flex', flexDirection: 'row' }}>
									<TeamsHeaderCard teams={teams} roles={_.cloneDeep(localRoles)} />
								</Box>

								<Box style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
									{/* Roles */}
									<Card
										style={{
											padding: '19px 0px 24px 0px',
											flex: 1.5,
											maxHeight: 'calc(100vh - 500px)',
										}}
									>
										<RoleManagement filter={handleRoleFilter} />
									</Card>

									{/* table */}
									<Card
										style={{
											padding: '19px 38px 24px 47px',
											flex: 2,
											maxHeight: 'calc(100vh - 500px)',
										}}
									>
										<RoleAccountsTable
											users={users}
											teams={teams}
											roles={_.cloneDeep(localRoles)}
											filterAccount={filterAccount}
											field={field}
											setField={setField}
											updateUser={updateUser}
											countLoading={countLoading}
										/>
									</Card>
								</Box>
							</Box>
						</>
					) : (
						<Loader />
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default AdminSettings;
