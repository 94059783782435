// types
import PropTypes from 'prop-types';

const FormFieldVariantType = (hasParent = false) => {
	const formFieldVariantType = {
		/**
		 * react-hook-form - useForm() instance
		 */
		formHook: PropTypes.object.isRequired,

		/**
		 * react-hook-form name, eg. "type.0.coding.0"
		 */
		name: PropTypes.string.isRequired,

		/**
		 * Options for select input type
		 *
		 * @param props
		 * @returns {Error}
		 */
		options: props => {
			if (props.type === 'select') {
				if (!props.options) {
					return new Error('options prop is required for "select" field type');
				}
			}
		},

		/**
		 * Props for APIAutocomplete, select input types
		 *
		 * @param props
		 * @returns {Error}
		 */
		props: props => {
			if (props.type === 'APIAutocomplete') {
				if (!props.props.isOptionEqualToValue) {
					return new Error('props.isOptionEqualToValue prop is required for "APIAutocomplete" field type');
				}

				if (!props.props.getOptionLabel) {
					return new Error('props.getOptionLabel prop is required for "APIAutocomplete" field type');
				}
			}

			if (props.type === 'select') {
				if (!props.props.SelectProps.renderValue) {
					return new Error('props.SelectProps.renderValue prop is required for "select" field type');
				}
			}
		},

		/**
		 * On search function for APIAutocomplete input type
		 *
		 * @param props
		 * @returns {Error}
		 */
		onSearch: props => {
			if (props.type === 'APIAutocomplete') {
				if (!props.onSearch) {
					return new Error('onSelect prop is required for "APIAutocomplete" field type');
				}
			}
		},
	};

	if (hasParent) {
		/**
		 * Form field type APIAutocomplete | contactInformation | googleAutocomplete | text | select
		 */
		formFieldVariantType.type = PropTypes.string.isRequired;

		/**
		 * equal to MUI Grid xs - 1-12
		 */
		formFieldVariantType.xs = PropTypes.number;

		/**
		 * If FormFieldVariant component is rendered by Form parent component then it should not have required formHook prop
		 */
		delete formFieldVariantType.formHook;
	}

	return formFieldVariantType;
};

export default FormFieldVariantType;
