import React from 'react';

const Folder = () => (
	<svg fill="none" height="93" viewBox="0 0 108 93" width="108" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_d_5211_29385)">
			<path
				clipRule="evenodd"
				d="M12 6C10.8954 6 10 6.89543 10 8V15.8203V18.0548V23.9025C10 26.6639 12.2386 28.9025 15 28.9025H93C95.7614 28.9025 98 26.6639 98 23.9025V15.8203C98 13.0589 95.7614 10.8203 93 10.8203H44.9589V9C44.9589 7.34315 43.6158 6 41.9589 6H12Z"
				fill="#4D79EA"
				fillRule="evenodd"
			/>
			<g filter="url(#filter1_d_5211_29385)">
				<rect fill="url(#paint0_linear_5211_29385)" height="63.8904" rx="5" width="88" x="10" y="14.4375" />
			</g>
		</g>
		<defs>
			<filter
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
				height="92.3279"
				id="filter0_d_5211_29385"
				width="108"
				x="0"
				y="0"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset dy="4" />
				<feGaussianBlur stdDeviation="5" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.345098 0 0 0 0 0.466667 0 0 0 0.1 0" />
				<feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_5211_29385" />
				<feBlend in="SourceGraphic" in2="effect1_dropShadow_5211_29385" mode="normal" result="shape" />
			</filter>
			<filter
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
				height="65.8904"
				id="filter1_d_5211_29385"
				width="90"
				x="9"
				y="12.4375"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset dy="-1" />
				<feGaussianBlur stdDeviation="0.5" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.345098 0 0 0 0 0.466667 0 0 0 0.1 0" />
				<feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_5211_29385" />
				<feBlend in="SourceGraphic" in2="effect1_dropShadow_5211_29385" mode="normal" result="shape" />
			</filter>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint0_linear_5211_29385"
				x1="54"
				x2="54"
				y1="14.4375"
				y2="78.3279"
			>
				<stop stopColor="#739AFF" />
				<stop offset="1" stopColor="#5887FF" />
			</linearGradient>
		</defs>
	</svg>
);

export default Folder;
