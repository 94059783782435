import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/material';

const useStyles = makeStyles()(() => ({
	top: {
		textAlign: 'center',
	},
	right: {
		textAlign: 'right',
	},
	bottom: {
		textAlign: 'center',
	},
	left: {
		textAlign: 'left',
	},
	positionalIndicator: {
		alignSelf: 'center',
		fontWeight: '600',
		margin: 0,
		padding: 0,
		whiteSpace: 'nowrap',
		fontSize: '14px',
		lineHeight: 1.1,
		letterSpacing: '0.25px',
	},
}));

export const PositionIndicator = ({ positionalIndicator }) => {
	const { classes, cx } = useStyles();

	return positionalIndicator
		? Object.keys(positionalIndicator).map(position => (
				<Box
					key={`position-indicator-${position}`}
					className={cx(classes.positionalIndicator, classes[position])}
					sx={{
						gridArea: `position-indicator-${position}`,
					}}
				>
					{positionalIndicator[position]}
				</Box>
		  ))
		: null;
};
