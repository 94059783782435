import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)({
	height: '36px',
	width: 'fit-content',
	fontWeight: 500,
	fontSize: '12px',
	lineHeight: '16px',
	letterSpacing: '1.25px',
	textTransform: 'uppercase',
	color: '#121212',
	border: '1px solid rgba(18, 18, 18, 0.1)',
	'&:hover': {
		color: '#42A5F5 ',
		backgroundColor: 'rgba(66, 165, 245, 0.06)',
		border: '1px solid rgba(66, 165, 245, 0.6)',
	},
});

const OutlineButtonWithIcon = ({ icon, text, onClick, style }) => (
	<StyledButton data-testid="StyledButton" startIcon={icon} style={style} variant="outlined" onClick={onClick}>
		{text}
	</StyledButton>
);
OutlineButtonWithIcon.propTypes = {
	text: PropTypes.string,
};

OutlineButtonWithIcon.defaultProps = {
	text: 'NEWS',
};

export default memo(OutlineButtonWithIcon);
