import styles from './DraftItem.module.scss';

export const getInputStyle = (theme, item) => {
	let resultStyle = '';

	if (theme === 'OAI') {
		resultStyle = styles.inputOAI;
	} else {
		resultStyle = styles.inputBlume;
	}

	switch (item.type) {
		case 'Header':
			resultStyle += ' ' + styles.inputHeader;
			break;
		case 'Paragraph':
			resultStyle += ' ' + styles.inputParagraph;
			break;
		case 'Quote':
			resultStyle += ' ' + styles.inputQuote;
			break;
		case 'Callout':
			resultStyle += ' ' + styles.inputCallout;
			break;
		case 'Bullet List':
			resultStyle += ' ' + styles.inputBulletList;
			break;
		case 'Number List':
			resultStyle += ' ' + styles.inputNumberList;
			break;
		case 'Check List':
			resultStyle += ' ' + styles.inputCheckList;
			break;
		default:
			resultStyle += '';
	}

	return resultStyle;
};

export const getPayload = (item, displayMode, checkListData, tableData, currentValue) => {
	if (item.type === 'MergedBlocks' && displayMode?.block?.id) {
		if (
			displayMode.block.type === 'Check List' &&
			displayMode.block?.checkList?.length > 0 &&
			displayMode.block.checkList[0].value !== '' &&
			checkListData.length > 0 &&
			checkListData[0].value !== ''
		) {
			return {
				...displayMode.block,
				checkList: checkListData,
			};
		} else if (displayMode.block.type === 'Table' && tableData?.tableText && tableData.tableText !== '') {
			return {
				...displayMode.block,
				html: tableData.tableHtml,
				text: tableData.tableText,
			};
		} else if (displayMode.block?.text) {
			return {
				...displayMode.block,
				text: currentValue,
			};
		}
	} else {
		if (
			item.type === 'Check List' &&
			item?.checkList?.length > 0 &&
			item.checkList[0].value !== '' &&
			checkListData.length > 0 &&
			checkListData[0].value !== ''
		) {
			return {
				...item,
				checkList: checkListData,
			};
		} else if (item.type === 'Table' && tableData?.tableText && tableData.tableText !== '') {
			return {
				...item,
				html: tableData.tableHtml,
				text: tableData.tableText,
			};
		} else if (item?.text) {
			return {
				...item,
				text: currentValue,
			};
		}
	}
};
