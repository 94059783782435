import React from 'react';
import _ from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import { useChatGlobalContext } from '@worklist-2/ui/src/components/Chat/ChatGlobalContext';
import { useLiveChatContext } from './LiveChatContext.jsx';
import CustomTextInput from '@worklist-2/ui/src/components/Help/CustomTextInput.jsx';
import TextChatItem from './TextChatItem';
import UserChat from './UserChat';

function TextChatTab() {
	const [searchValue, setSearchValue] = React.useState('');

	const { selectedConversation, setSelectedConversation, conversationPick, requestedConvs, isRequestQueue } =
		useLiveChatContext();
	const { state } = useChatGlobalContext();

	const filterResults = data => {
		let sortedList = null;
		const sortByNameEmail = value =>
			_.startsWith(value?.name?.toLowerCase(), searchValue.toLowerCase()) ||
			_.startsWith(value?.email?.toLowerCase(), searchValue.toLowerCase());
		if (searchValue !== '') {
			Array.isArray(data)
				? (sortedList = data.filter(value => sortByNameEmail(value)))
				: (sortedList = _.pickBy(data, value => sortByNameEmail(value)));
		} else {
			sortedList = _.orderBy(data, 'datetime', 'desc');
		}

		return sortedList;
	};

	const conversationList = Object.keys(state).length == 0 ? [] : filterResults(state);

	const requestedConversationList = requestedConvs.length == 0 ? [] : filterResults(requestedConvs);

	const renderConversationRow = (item, onClickCallBack) =>
		item ? <TextChatItem data={item} onClick={onClickCallBack} /> : null;

	return (
		<div style={{ height: '100%', overflowY: 'hidden' }}>
			<div
				style={{
					width: '100%',
					height: '75px',
					padding: '0 40px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					boxSizing: 'border-box',
				}}
			>
				<div
					style={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						padding: '0 10px 0 18px',
						border: '1px solid rgba(196, 196, 196, 0.4)',
						borderRadius: '22px',
					}}
				>
					<SearchIcon
						sx={{
							width: '18px',
							height: '18px',
							color: '#4D79EA',
							marginRight: '11px',
						}}
					/>
					<CustomTextInput
						id="test"
						inputProps={{ 'aria-label': 'Search' }}
						placeholder={searchValue === '' ? 'Search active chats' : searchValue}
						style={{
							width: '100%',
						}}
						value={searchValue}
						onChange={e => {
							setSearchValue(e.target.value);
						}}
					/>
					{selectedConversation && (
						<UserChat
							conversation={selectedConversation}
							isOpen={selectedConversation}
							style={{ height: '100vh', overflowY: 'auto' }}
							onClose={() => setSelectedConversation(null)}
						/>
					)}
				</div>
			</div>

			<div
				style={{
					position: 'relative',
					width: '100%',
					height: '100%',
					overflow: 'auto',
				}}
			>
				{isRequestQueue
					? requestedConversationList.length > 0 &&
					  requestedConversationList.map(item => renderConversationRow(item, () => conversationPick(item)))
					: state != null &&
					  conversationList != null &&
					  Object.keys(conversationList).length > 0 &&
					  Object.entries(conversationList).map(item =>
							renderConversationRow(item[1], () => setSelectedConversation(item[1]))
					  )}
			</div>
		</div>
	);
}

export default TextChatTab;
