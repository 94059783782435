import React from 'react';

const ChatIcon = ({ dataTestId }) => (
	<svg
		data-testid={dataTestId}
		fill="none"
		height="55"
		viewBox="0 0 55 55"
		width="55"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_2852_31925)">
			<path
				d="M34.3752 9.16536V25.207H11.8481L10.496 26.5591L9.16683 27.8883V9.16536H34.3752ZM36.6668 4.58203H6.87516C5.61475 4.58203 4.5835 5.61328 4.5835 6.8737V38.957L13.7502 29.7904H36.6668C37.9272 29.7904 38.9585 28.7591 38.9585 27.4987V6.8737C38.9585 5.61328 37.9272 4.58203 36.6668 4.58203ZM48.1252 13.7487H43.5418V34.3737H13.7502V38.957C13.7502 40.2174 14.7814 41.2487 16.0418 41.2487H41.2502L50.4168 50.4154V16.0404C50.4168 14.7799 49.3856 13.7487 48.1252 13.7487Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2852_31925">
				<rect fill="white" height="55" width="55" />
			</clipPath>
		</defs>
	</svg>
);

export default ChatIcon;
