/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
import imagingStudySchema from './schemata/imagingStudySchema';
/************************************************************************************************************************
Resource: ImagingStudy
Reference: https://www.hl7.org/fhir/ImagingStudy.html


ImagingStudy provides information on a DICOM imaging study, and the series and imaging objects in that study. It also provides
information on how to retrieve that information (in a native DICOM format, or in a rendered format, such as JPEG). ImagingStudy
is used to make available information about all parts of a single DICOM study. This resource provides mappings of its elements
to DICOM attributes.

DICOM attributes are identified by a 32-bit tag, presented in canonical form as two four-digit hexadecimal values within
parentheses and separated by a comma, e.g. (0008,103E).

The name and value representation (data type) of each attribute can be found in DICOM Part 6 Data Dictionary .

The use of the attributes in the context of information objects, including detailed description of use, can be found in
DICOM Part 3 Information Object Definitions

.

Attributes used in the DICOM query information models, such as "Number of Instances in Study", can be found in DICOM Part
4 Annex C . ImagingStudy provides access to significant DICOM information but will only eliminate the need for DICOM query
(e.g., QIDO-RS) in the simplest cases. The DICOM instances are not stored in the ImagingStudy resource; use of a DICOM WADO-RS
server or other storage mechanism is needed.

An ImagingStudy SHALL reference one DICOM Study, and MAY reference a subset of that Study. More than one ImagingStudy MAY
reference the same DICOM Study or different subsets of the same DICOM Study. 
************************************************************************************************************************/

export default class ImagingStudyResource extends FhirDomainResource {
	identifier = [];
	status;
	modality = [];
	subject;
	encounter;
	started;
	basedOn = [];
	referrer;
	interpreter = [];
	endpoint = [];
	numberOfSeries;
	numberOfInstances;
	procedureReference;
	procedureCode = [];
	location;
	reasonCode = [];
	reasonReference = [];
	note = [];
	description;
	series = [{}];

	constructor(resourceString) {
		super(resourceString, imagingStudySchema);
		this.resourceType = 'ImagingStudy';
		this.populateFields();
	}
}
