import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteProgress from '../../DeleteProgress';

const ExpandableRow = ({ onClick, children, isExpandable, expandableRowColSpan, expandedRowContent, sx }) => {
	const [open, setOpen] = useState(false);

	const onRowClick = e => {
		onClick(e);
		setOpen(v => !v);
	};

	return (
		<>
			<TableRow
				sx={{
					...sx,
				}}
				onClick={onRowClick}
			>
				{isExpandable ? (
					<TableCell>
						{open ? (
							<KeyboardArrowUpIcon
								aria-label="expand row"
								sx={{
									cursor: 'pointer',
									color: 'icon.primary',

									'&:hover': {
										color: 'rsPrimary.main',
									},
								}}
							/>
						) : (
							<KeyboardArrowDownIcon
								aria-label="collapse row"
								sx={{
									cursor: 'pointer',
									color: 'icon.primary',

									'&:hover': {
										color: 'rsPrimary.main',
									},
								}}
							/>
						)}
					</TableCell>
				) : null}
				{children}
			</TableRow>
			<TableRow>
				<TableCell colSpan={expandableRowColSpan} style={{ paddingBottom: 0, paddingTop: 0 }}>
					<Collapse in={open}>{expandedRowContent}</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

const GridInput = ({ formField, rows, getFormField, getGridMapping, onChange, model, isEditing, expandableRows }) => {
	const shouldRender = option => {
		if (formField.customFilter) {
			return formField.customFilter(option, model);
		}

		return true;
	};

	const isRowSelected = row => !!(row.id && model.selectedRow && row.id === model.selectedRow.id);

	return (
		<TableContainer
			sx={{
				border: '1px solid rgba(255, 255, 255, 0.06)',
				boxSizing: 'border-box',
				borderRadius: '6px',
				'& .MuiTableCell-root': {
					borderBottom: 'none',
				},
			}}
		>
			<Table>
				<TableHead>
					<TableRow
						sx={{
							'& .MuiTableCell-root': {
								color: 'rgba(255, 255, 255, 0.6)',
							},
						}}
					>
						{expandableRows ? <TableCell /> : null}
						{formField.formFields
							.filter(item => !item.hidden)
							.map((item, index) => (
								<TableCell
									key={`${item.mapping}-${index}-${item.name}`}
									sx={{
										color: '#FFFFFF99',
										...item.cellSx,
									}}
								>
									{item.disableHeader ? null : item.title || item.name}
								</TableCell>
							))}

						{isEditing && formField.onDelete && (
							<TableCell
								key="delete"
								sx={{
									color: '#FFFFFF99',
								}}
							/>
						)}
					</TableRow>
				</TableHead>

				<TableBody>
					{rows.map((row, index) =>
						shouldRender(row) ? (
							<ExpandableRow
								key={`${row.id}-${index}`}
								expandableRowColSpan={1000}
								expandedRowContent={
									<Grid item xs={12}>
										<Grid container spacing={2}>
											{formField.expandableRowFormFields?.map(item =>
												getFormField({
													...item,
													mapping: getGridMapping(index, item),
													customModelUpdate: item.customModelUpdate
														? item.customModelUpdate(index)
														: null,
												})
											)}
										</Grid>
									</Grid>
								}
								isExpandable={expandableRows}
								sx={{
									'&:hover': {
										background: 'rgba(255, 255, 255, 0.04)',
									},
									'&': {
										background: isRowSelected(row) ? 'rgba(255, 255, 255, 0.04)' : undefined,
									},
									'&:hover .onDelete': {
										visibility: 'visible',
									},
									'& .MuiInputBase-input': {
										padding: '6.5px 14px !important',
									},
									'& .MuiAutocomplete-inputRoot': {
										padding: '0 !important',
									},
									'& .MuiTextField-root': {
										marginBottom: '0',
									},
								}}
								onClick={() => onChange(row, row, 'selectedRow')}
							>
								{formField.formFields
									.filter(item => !item.hidden)
									.map(item => (
										<TableCell
											key={`${item.mapping}-${index}-${item.name}`}
											sx={{
												padding: '6px 16px',
												...item.cellSx,
											}}
										>
											{getFormField({
												...item,
												mapping: getGridMapping(index, item),
												size: 12,
												name: undefined,
											})}
										</TableCell>
									))}

								{isEditing && formField.onDelete && (
									<TableCell key="delete" sx={{ textAlign: 'right' }}>
										<Box
											className="onDelete"
											sx={{
												visibility: isRowSelected(row) ? 'visible' : 'hidden',
											}}
										>
											<DeleteProgress
												onDelete={() => formField.onDelete(row, index, onChange, model)}
											/>
										</Box>
									</TableCell>
								)}
							</ExpandableRow>
						) : null
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

GridInput.defaultProps = {
	isEditing: false,
};

export default GridInput;
