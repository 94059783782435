// Core

import React from 'react';

import PropTypes from 'prop-types';

// MUI

import { Icon, Stack } from '@mui/material';

// Custom

import EmptyIcon from '@worklist-2/ui/src/assets/icons/Chat/empty.svg';
import NoResultsIcon from '@worklist-2/ui/src/assets/icons/Chat/no-results.svg';

// Component

const ChatEmpty = ({ sx, children, searchString }) => (
	<Stack alignItems="center" justifyContent="center" sx={sx}>
		<Icon
			component={searchString ? NoResultsIcon : EmptyIcon}
			sx={{
				boxSizing: 'border-box',
				flex: 'none',
				height: '248px',
				width: '262px',
			}}
		/>

		{children}
	</Stack>
);

ChatEmpty.propTypes = {
	sx: PropTypes.object,
	children: PropTypes.node,
	searchString: PropTypes.string,
};

export default ChatEmpty;
