// core
import React, { useRef, useEffect, useState } from 'react';
// libraries
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

// TODO: consider updating the component with this (might be needed when the grid gets resizable columns) - https://www.npmjs.com/package/react-measure
// props to: https://stackoverflow.com/a/62150782

const OverflowTooltip = ({
	enableRecalc = false,
	forceSpan,
	text,
	numLines,
	tooltip,
	innerRef,
	textAlign,
	...passingProps
}) => {
	const textElementRef = useRef();
	const [isTooltipAllowed, setIsTooltipAllowed] = useState(false);

	const compareSize = () => {
		if (!textElementRef.current) return;

		const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;

		setIsTooltipAllowed(compare);
	};

	useEffect(() => {
		compareSize();
		if (enableRecalc) {
			window.addEventListener('resize', compareSize);

			return () => window.removeEventListener('resize', compareSize);
		}
	}, [enableRecalc]);

	return (
		<Tooltip
			ref={innerRef}
			disableInteractive
			PopperProps={{ style: { pointerEvents: 'none' } }}
			disableHoverListener={!isTooltipAllowed}
			title={tooltip || text}
		>
			{forceSpan ? (
				<span
					ref={textElementRef}
					data-testid="overflow-tooltip"
					style={{
						display: '-webkit-box',
						WebkitLineClamp: numLines || 1,
						WebkitBoxOrient: 'vertical',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						textAlign,
					}}
					{...passingProps}
				>
					{React.isValidElement(text) ? text : `${text}`}
				</span>
			) : (
				<div
					ref={textElementRef}
					data-testid="overflow-tooltip"
					style={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						textAlign,
					}}
					{...passingProps}
				>
					{React.isValidElement(text) ? text : `${text}`}
				</div>
			)}
		</Tooltip>
	);
};

export default OverflowTooltip;

OverflowTooltip.propTypes = {
	/**
	 * Enable recalc on window size change, defaults to false
	 */
	enableRecalc: PropTypes.bool,
	/**
	 * Will enforce span instead of div to avoid render errors
	 */
	forceSpan: PropTypes.bool,
	/**
	 * Text to render
	 */
	text: PropTypes.string.isRequired,
	/**
	 * Number of numLines to render, defaults to 1
	 */
	numLines: PropTypes.number,
	/**
	 * Tooltip to be shown, defaults to `text`
	 */
	tooltip: PropTypes.string,
	/**
	 * Reference to be passed to the tooltip component
	 */
	innerRef: PropTypes.any,
};
