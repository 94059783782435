import React, { useCallback } from 'react';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenFullscreenIcon from '@mui/icons-material/OpenInFull';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

const FullScreenIcon = ({ isFullscreen, onToggleFullscreen, hidePageHeader, sx }) => {
	const { t } = useTranslation('studyDetail');

	const _onToggleFullscreen = useCallback(() => {
		const elements = _.filter([document.getElementById('go-back')], e => Boolean);
		_.forEach(elements, el => {
			el.style.display = !isFullscreen && hidePageHeader ? 'none' : 'flex';
		});
		onToggleFullscreen(!isFullscreen);
	}, [isFullscreen]);

	const IconComponent = isFullscreen ? CloseFullscreenIcon : OpenFullscreenIcon;

	return (
		<Tooltip title={!isFullscreen ? t('Full View') : t('Normal View')}>
			<div
				style={{
					cursor: 'pointer',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					padding: '0 20px',
					width: '20px',
					...sx,
				}}
				onClick={_onToggleFullscreen}
			>
				<IconComponent
					sx={{
						fontSize: '20px',
						color: '#FFFFFF',
					}}
				/>
			</div>
		</Tooltip>
	);
};

FullScreenIcon.propTypes = {
	/**
	 * Current state of screen
	 */
	isFullscreen: PropTypes.bool,
	/**
	 * Action to perform on full screen icon toggle
	 */
	onToggleFullscreen: PropTypes.func,
	/**
	 * To hide page header in full screen mode
	 */
	hidePageHeader: PropTypes.bool,
};

export default FullScreenIcon;
