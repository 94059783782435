import { AnnotationTool } from '@cornerstonejs/tools';
import { getEnabledElement, getEnabledElementByIds } from '@cornerstonejs/core';
import {
	addAnnotation,
	getAnnotation,
	getAnnotations,
	removeAnnotation,
	getAnnotationManager,
} from '@cornerstonejs/tools/dist/esm/stateManagement';
import { isAnnotationLocked } from '@cornerstonejs/tools/dist/esm/stateManagement/annotation/annotationLocking';
import { isAnnotationVisible } from '@cornerstonejs/tools/dist/esm/stateManagement/annotation/annotationVisibility';
import { triggerAnnotationCompleted } from '@cornerstonejs/tools/dist/esm/stateManagement/annotation/helpers/state';
import { state } from '@cornerstonejs/tools/dist/esm/store';
import { Events } from '@cornerstonejs/tools/dist/esm/enums';
import { getViewportIdsWithToolToRender } from '@cornerstonejs/tools/dist/esm/utilities/viewportFilters';
import * as rectangle from '@cornerstonejs/tools/dist/esm/utilities/math/rectangle';
import { resetElementCursor, hideElementCursor } from '@cornerstonejs/tools/dist/esm/cursors/elementCursor';
import triggerAnnotationRenderForViewportIds from '@cornerstonejs/tools/dist/esm/utilities/triggerAnnotationRenderForViewportIds';
import { drawHandles as drawHandlesSvg } from '@cornerstonejs/tools/dist/esm/drawingSvg';
import { drawRect as drawRectSvg } from './drawRect';
import { renderingEngineId } from '../../contexts/ImageViewerCornerstoneContext';

class ShutterRectangleTool extends AnnotationTool {
	toolName;

	editData;

	isDrawing;

	isHandleOutsideImage;

	constructor(
		toolProps = {},
		defaultToolProps = {
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				shadow: true,
				preventHandleOutsideImage: false,
			},
		}
	) {
		super(toolProps, defaultToolProps);
	}

	addNewAnnotation = evt => {
		const eventDetail = evt.detail;
		const { currentPoints, element } = eventDetail;
		const worldPos = currentPoints.world;

		const enabledElement = getEnabledElement(element);
		const { viewport, renderingEngine } = enabledElement;

		this.isDrawing = true;

		const camera = viewport.getCamera();
		const { viewPlaneNormal, viewUp } = camera;

		const referencedImageId = this.getReferencedImageId(viewport, worldPos, viewPlaneNormal, viewUp);

		const FrameOfReferenceUID = viewport.getFrameOfReferenceUID();

		const annotation = {
			invalidated: true,
			highlighted: true,
			metadata: {
				toolName: this.getToolName(),
				viewPlaneNormal: [...viewPlaneNormal],
				viewUp: [...viewUp],
				FrameOfReferenceUID,
				referencedImageId,
			},
			data: {
				label: '',
				handles: {
					points: [[...worldPos], [...worldPos], [...worldPos], [...worldPos]],
					activeHandleIndex: null,
				},
			},
		};

		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.editData = {
			annotation,
			viewportIdsToRender,
			handleIndex: 3,
			newAnnotation: true,
			hasMoved: false,
		};
		this._activateDraw(element);

		hideElementCursor(element);

		evt.preventDefault();

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		return annotation;
	};

	isPointNearTool = (element, annotation, canvasCoords, proximity) => {
		const enabledElement = getEnabledElement(element);
		const { viewport } = enabledElement;

		const { data } = annotation;
		const { points } = data.handles;

		const canvasPoint1 = viewport.worldToCanvas(points[0]);
		const canvasPoint2 = viewport.worldToCanvas(points[3]);

		const rect = this._getRectangleImageCoordinates([canvasPoint1, canvasPoint2]);

		const point = [canvasCoords[0], canvasCoords[1]];
		const { left, top, width, height } = rect;

		const distanceToPoint = rectangle.distanceToPoint([left, top, width, height], point);

		if (distanceToPoint <= proximity) {
			return true;
		}

		return false;
	};

	toolSelectedCallback = (evt, annotation) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		annotation.highlighted = true;

		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.editData = {
			annotation,
			viewportIdsToRender,
		};

		this._activateModify(element);

		hideElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		evt.preventDefault();
	};

	handleSelectedCallback = (evt, annotation, handle) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;
		const { data } = annotation;

		annotation.highlighted = true;

		let handleIndex;

		if (!handle.worldPosition) {
			handleIndex = data.handles.points.findIndex(p => p === handle);
		}

		// Find viewports to render on drag.
		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.editData = {
			annotation,
			viewportIdsToRender,
			handleIndex,
		};
		this._activateModify(element);

		hideElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		evt.preventDefault();
	};

	_endCallback = evt => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const { annotation, viewportIdsToRender, newAnnotation } = this.editData;
		const { data } = annotation;

		data.handles.activeHandleIndex = null;

		this._deactivateModify(element);
		this._deactivateDraw(element);

		resetElementCursor(element);

		const { renderingEngine } = getEnabledElement(element);

		this.editData = null;
		this.isDrawing = false;

		if (this.isHandleOutsideImage && this.configuration.preventHandleOutsideImage) {
			removeAnnotation(annotation.annotationUID);
		}

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		const annotationAdded = getAnnotation(annotation?.annotationUID);

		if (annotationAdded && newAnnotation) {
			annotationAdded.done = true;

			triggerAnnotationCompleted(annotation);
		}
	};

	_dragCallback = evt => {
		this.isDrawing = true;

		const eventDetail = evt.detail;
		const { element, viewportId } = eventDetail;

		const { annotation, viewportIdsToRender, handleIndex } = this.editData;
		const { data } = annotation;

		const annotationAdded = getAnnotation(annotation?.annotationUID);

		if (!annotationAdded) {
			const enabledViewport = getEnabledElementByIds(viewportId, renderingEngineId);

			if (enabledViewport?.viewport) {
				const imageId = enabledViewport.viewport?.getCurrentImageId();
				const annotationManager = getAnnotationManager();
				const annotations = annotationManager.getAllAnnotations();

				annotations?.forEach(_annotation => {
					if (
						_annotation.metadata?.toolName === ShutterRectangleTool.toolName &&
						_annotation.metadata?.referencedImageId === imageId
					) {
						removeAnnotation(_annotation.annotationUID);
					}
				});
			}

			addAnnotation(annotation);
		}

		if (handleIndex === undefined) {
			// Drag mode - Moving tool, so move all points by the world points delta
			const { deltaPoints } = eventDetail;
			const worldPosDelta = deltaPoints.world;

			const { points } = data.handles;

			points.forEach(point => {
				point[0] += worldPosDelta[0];
				point[1] += worldPosDelta[1];
				point[2] += worldPosDelta[2];
			});
			annotation.invalidated = true;
		} else {
			// Moving handle.
			const { currentPoints } = eventDetail;
			const enabledElement = getEnabledElement(element);
			const { worldToCanvas, canvasToWorld } = enabledElement.viewport;
			const worldPos = currentPoints.world;

			const { points } = data.handles;

			// Move this handle.
			points[handleIndex] = [...worldPos];

			let bottomLeftCanvas;
			let bottomRightCanvas;
			let topLeftCanvas;
			let topRightCanvas;

			let bottomLeftWorld;
			let bottomRightWorld;
			let topLeftWorld;
			let topRightWorld;

			switch (handleIndex) {
				case 0:
				case 3:
					// Moving bottomLeft or topRight

					bottomLeftCanvas = worldToCanvas(points[0]);
					topRightCanvas = worldToCanvas(points[3]);

					bottomRightCanvas = [topRightCanvas[0], bottomLeftCanvas[1]];
					topLeftCanvas = [bottomLeftCanvas[0], topRightCanvas[1]];

					bottomRightWorld = canvasToWorld(bottomRightCanvas);
					topLeftWorld = canvasToWorld(topLeftCanvas);

					points[1] = bottomRightWorld;
					points[2] = topLeftWorld;

					break;
				case 1:
				case 2:
					// Moving bottomRight or topLeft
					bottomRightCanvas = worldToCanvas(points[1]);
					topLeftCanvas = worldToCanvas(points[2]);

					bottomLeftCanvas = [topLeftCanvas[0], bottomRightCanvas[1]];
					topRightCanvas = [bottomRightCanvas[0], topLeftCanvas[1]];

					bottomLeftWorld = canvasToWorld(bottomLeftCanvas);
					topRightWorld = canvasToWorld(topRightCanvas);

					points[0] = bottomLeftWorld;
					points[3] = topRightWorld;

					break;
			}
			annotation.invalidated = true;
		}

		this.editData.hasMoved = true;

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
	};

	cancel = element => {
		// If it is mid-draw or mid-modify
		if (this.isDrawing) {
			this.isDrawing = false;
			this._deactivateDraw(element);
			this._deactivateModify(element);
			resetElementCursor(element);

			const { annotation, viewportIdsToRender, newAnnotation } = this.editData;

			const { data } = annotation;

			annotation.highlighted = false;
			data.handles.activeHandleIndex = null;

			const { renderingEngine } = getEnabledElement(element);

			triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

			if (newAnnotation) {
				triggerAnnotationCompleted(annotation);
			}

			this.editData = null;
			return annotation.annotationUID;
		}
	};

	_activateDraw = element => {
		state.isInteractingWithTool = true;

		element.addEventListener(Events.MOUSE_UP, this._endCallback);
		element.addEventListener(Events.MOUSE_DRAG, this._dragCallback);
		element.addEventListener(Events.MOUSE_MOVE, this._dragCallback);
		element.addEventListener(Events.MOUSE_CLICK, this._endCallback);

		element.addEventListener(Events.TOUCH_END, this._endCallback);
		element.addEventListener(Events.TOUCH_DRAG, this._dragCallback);
		element.addEventListener(Events.TOUCH_TAP, this._endCallback);
	};

	_deactivateDraw = element => {
		state.isInteractingWithTool = false;

		element.removeEventListener(Events.MOUSE_UP, this._endCallback);
		element.removeEventListener(Events.MOUSE_DRAG, this._dragCallback);
		element.removeEventListener(Events.MOUSE_MOVE, this._dragCallback);
		element.removeEventListener(Events.MOUSE_CLICK, this._endCallback);

		element.removeEventListener(Events.TOUCH_END, this._endCallback);
		element.removeEventListener(Events.TOUCH_DRAG, this._dragCallback);
		element.removeEventListener(Events.TOUCH_TAP, this._endCallback);
	};

	_activateModify = element => {
		state.isInteractingWithTool = true;

		element.addEventListener(Events.MOUSE_UP, this._endCallback);
		element.addEventListener(Events.MOUSE_DRAG, this._dragCallback);
		element.addEventListener(Events.MOUSE_CLICK, this._endCallback);

		element.addEventListener(Events.TOUCH_END, this._endCallback);
		element.addEventListener(Events.TOUCH_DRAG, this._dragCallback);
		element.addEventListener(Events.TOUCH_TAP, this._endCallback);
	};

	_deactivateModify = element => {
		state.isInteractingWithTool = false;

		element.removeEventListener(Events.MOUSE_UP, this._endCallback);
		element.removeEventListener(Events.MOUSE_DRAG, this._dragCallback);
		element.removeEventListener(Events.MOUSE_CLICK, this._endCallback);

		element.removeEventListener(Events.TOUCH_END, this._endCallback);
		element.removeEventListener(Events.TOUCH_DRAG, this._dragCallback);
		element.removeEventListener(Events.TOUCH_TAP, this._endCallback);
	};

	renderAnnotation = (enabledElement, svgDrawingHelper) => {
		let renderStatus = false;
		const { viewport } = enabledElement;
		const { element } = viewport;

		let annotations = getAnnotations(this.getToolName(), element);

		if (!annotations?.length) {
			return renderStatus;
		}

		annotations = this.filterInteractableAnnotationsForElement(element, annotations);

		if (!annotations?.length) {
			return renderStatus;
		}

		const styleSpecifier = {
			toolGroupId: this.toolGroupId,
			toolName: this.getToolName(),
			viewportId: enabledElement.viewport.id,
		};

		for (let i = 0; i < annotations.length; i++) {
			const annotation = annotations[i];
			const { annotationUID, data } = annotation;
			const { points, activeHandleIndex } = data.handles;
			const canvasCoordinates = points.map(p => viewport.worldToCanvas(p));

			styleSpecifier.annotationUID = annotationUID;

			const { color } = this.getAnnotationStyle({
				annotation,
				styleSpecifier,
			});

			// If rendering engine has been destroyed while rendering
			if (!viewport.getRenderingEngine()) {
				console.warn('Rendering Engine has been destroyed');
				return renderStatus;
			}

			let activeHandleCanvasCoords;

			if (!isAnnotationVisible(annotationUID)) {
				continue;
			}

			if (!isAnnotationLocked(annotation) && !this.editData && activeHandleIndex !== null) {
				// Not locked or creating and hovering over handle, so render handle.
				activeHandleCanvasCoords = [canvasCoordinates[activeHandleIndex]];
			}

			if (activeHandleCanvasCoords) {
				const handleGroupUID = '0';

				drawHandlesSvg(svgDrawingHelper, annotationUID, handleGroupUID, activeHandleCanvasCoords, {
					color,
				});
			}

			const dataId = `${annotationUID}-rect`;
			const rectangleUID = '0';
			const { worldToCanvas } = enabledElement.viewport;

			drawRectSvg(
				svgDrawingHelper,
				annotationUID,
				rectangleUID,
				canvasCoordinates[0],
				canvasCoordinates[3],
				viewport.canvas,
				[worldToCanvas(points[0]), worldToCanvas(points[3])],
				dataId,
				annotation.done
			);

			renderStatus = true;
		}

		return renderStatus;
	};

	_getRectangleImageCoordinates = points => {
		const [point0, point1] = points;

		return {
			left: Math.min(point0[0], point1[0]),
			top: Math.min(point0[1], point1[1]),
			width: Math.abs(point0[0] - point1[0]),
			height: Math.abs(point0[1] - point1[1]),
		};
	};
}

ShutterRectangleTool.toolName = 'ShutterRectangleTool';
export default ShutterRectangleTool;
