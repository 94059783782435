import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import Box from '@mui/material/Box';

import ImageViewerExternal from '@worklist-2/ui/src/views/ImageViewerView3D/ImageViewerExternal/ImageViewerExternal';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useUserStore, useDrawersStore } from '@worklist-2/patientPortal/src/stores';

import MobileProcessingImageSection from '../MobileStudyDetail/MobileStudyDetailSections/MobileProcessingImageSection';

const MobileGallery = ({ study, closeGallery }) => {
	const studyUID = study?.studyThumbnail?.studyUID || null;

	const userFeedback = useUserStore(state => state.userFeedback);
	const setUserFeedbackOpen = useDrawersStore(state => state.setUserFeedbackOpen);

	useEffect(
		() => () => {
			if (!userFeedback?.isFeedbackSubmitted) {
				setUserFeedbackOpen(true);
			}
		},
		[]
	);

	return (
		<Box>
			{!studyUID ? (
				<MobileProcessingImageSection />
			) : (
				<Box
					sx={{
						width: '100%',
						height: '100%',
					}}
				>
					{createPortal(
						<Box
							data-testid="mobile-gallery-portal"
							id="mobile-gallery-portal"
							sx={{
								width: '100%',
								height: '100%',
							}}
						>
							<KeyboardArrowLeftIcon
								sx={{
									fill: 'rgba(229, 229, 229, 1)',
									position: 'fixed',
									top: '80px',
									left: '25px',
									zIndex: '11001',
								}}
								onClick={closeGallery}
							/>
							<ImageViewerExternal
								disabledKeyImages
								creatorUserId={study.creatorBlumeUserId}
								internalManagingOrganizationID={study?.managingOrganization?.id}
								isUploaded={false}
								studyInstanceUID={studyUID}
								sx={{
									width: '100%',
									height: '100%',
									borderRadius: 0,
									position: 'fixed',
									zIndex: '11000',
									top: '0',
								}}
							/>
						</Box>,
						document.body
					)}
				</Box>
			)}
		</Box>
	);
};

export default MobileGallery;
