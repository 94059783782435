import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

const ShowMoreButton = ({ onShowMoreClick, btnText }) => (
	<Typography
		sx={{
			fontWeight: '400',
			fontSize: '14px',
			lineHeight: '16px',
			mt: '20px',
		}}
	>
		<Link
			sx={{
				cursor: 'pointer',
				color: 'rsPrimary.button',
				textDecoration: 'none',
				'&:hover': {
					textDecoration: 'underline',
				},
			}}
			onClick={onShowMoreClick}
		>
			{btnText}
		</Link>
	</Typography>
);

ShowMoreButton.propTypes = {
	/**
	 * Button handler
	 */
	onShowMoreClick: PropTypes.func.isRequired,
	/**
	 * Button text
	 */
	btnText: PropTypes.string.isRequired,
};

export default ShowMoreButton;
