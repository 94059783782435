import React, { useState } from 'react';
import { Button, Drawer } from '@mui/material';
import SmsIcon from '@mui/icons-material/Sms';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { useLiveChatContext } from './LiveChatContext';
import SupportChatHeader from './SupportChatHeader';
import UserChatHeader from './UserChatHeader';
import ProfileDetails from './ProfileDetails';
import TextChatTab from './TextChatTab';
import UserChat from './UserChat';
import VoiceChatTab from './VoiceChatTab';

function LiveChat() {
	const {
		unreadConvCnt,
		isChatOpen,
		selectedConversation,
		voiceChatTab,
		setVoiceChatTab,
		isProfileOpen,
		setIsProfileOpen,
		chatLogs,
		setIsRequestQueue,
		setChatHeader,
		isTextChatQueue,
		setIsTextChatQueue,
	} = useLiveChatContext();

	return (
		<Drawer
			hideBackdrop
			anchor="right"
			open={isChatOpen}
			sx={{
				'&.MuiModal-root': {
					width: '0px',
				},
				'.MuiDrawer-paper': {
					width: '584px',
					overflow: 'hidden',
				},
			}}
		>
			{isTextChatQueue && selectedConversation ? <UserChatHeader /> : <SupportChatHeader title={voiceChatTab} />}
			<div
				data-testid="IsOpen"
				style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					flexWrap: 'nowrap',
					backgroundColor: '#4D79EA',
				}}
			>
				<ChatTabButton
					isTextChatTab
					count={unreadConvCnt}
					isActive={isTextChatQueue}
					onClick={() => {
						setVoiceChatTab('');
						setChatHeader('Support Chat');
						setIsTextChatQueue(true);
						setIsRequestQueue(false);
					}}
				/>
				<div
					style={{
						width: '1px',
						height: '46px',
						backgroundColor: 'rgba(255, 255, 255, 0.6)',
					}}
				/>
				<ChatTabButton
					isActive={!isTextChatQueue}
					isTextChatTab={false}
					onClick={() => {
						setVoiceChatTab('Dialpad');
						setChatHeader('Support Call');
						setIsTextChatQueue(false);
						setIsRequestQueue(false);
					}}
				/>
			</div>
			{isTextChatQueue ? selectedConversation ? <UserChat /> : <TextChatTab /> : <VoiceChatTab />}
			<ProfileDetails
				chatLogs={chatLogs}
				conversation={selectedConversation}
				isOpen={isProfileOpen}
				onClose={() => setIsProfileOpen(false)}
			/>
		</Drawer>
	);
}

function ChatTabButton({ isActive, isTextChatTab, count, onClick }) {
	return (
		<Button
			sx={{
				width: '100%',
				height: '57px',
				margin: '4px 26px',
				borderRadius: '11px',
				backgroundColor: isActive ? 'rgba(255, 255, 255, 0.1)' : 'none',
				border: isActive ? '1px solid #FFFFFF' : 'none',
			}}
			variant="outlined"
			onClick={onClick}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					color: '#FFFFFF',
				}}
			>
				<div
					style={{
						position: 'relative',
						width: '24px',
						height: '24px',
						marginBottom: '2px',
					}}
				>
					{isTextChatTab ? <SmsIcon /> : <CallOutlinedIcon />}
					{count > 0 && (
						<div
							style={{
								position: 'absolute',
								right: isTextChatTab ? '-20px' : '-18px',
								bottom: '0',
								width: '23px',
								height: '23px',
								backgroundColor: '#EF5D5D',
								border: '2px solid #4D79EA',
								borderRadius: '50%',
							}}
						>
							{count}
						</div>
					)}
				</div>
				<span
					style={{
						fontSize: '12px',
						fontWeight: '500',
						lineHeight: '15px',
						textTransform: 'none',
					}}
				>
					{isTextChatTab ? 'Text' : 'Voice'}
				</span>
			</div>
		</Button>
	);
}

export default LiveChat;
