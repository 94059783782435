import React from 'react';
import { Box, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import { TabPanel } from '../../../Cases/casePopOverStyles';
import Button from '@mui/material/Button';

const TABLE_HEAD = ['Application', 'License Type', 'Number of License', 'Assigned', 'Available License'];

const License = ({ data, setShowLicenseDrawer }) => (
	<TabPanel className="invisible-scrollbar">
		<div className="header">
			<div className="left">
				<div className="colored-icon" style={{ backgroundColor: 'rgba(207, 106, 185, 0.2)' }}>
					<CardTravelOutlinedIcon sx={{ fontSize: '20px', color: '#CD6ACF' }} />
				</div>
				<h2>License</h2>
			</div>
			<div className="right">
				<Button
					sx={{ backgroundColor: '#4D79EA', '&:hover': { backgroundColor: '#2727274a' } }}
					variant="contained"
					onClick={() => setShowLicenseDrawer(true)}
				>
					Edit
				</Button>
			</div>
		</div>

		<TableContainer
			style={{
				marginTop: '4px',
				height: '296px',
				border: 0,
				borderRadius: 0,
				borderTopRightRadius: '20px',
				borderTopLeftRadius: '20px',
				boxShadow: 'none',
			}}
			sx={{
				'&::-webkit-scrollbar-thumb': {
					background: '#C4C4C4 !important',
					borderRadius: '3px',
				},

				'&::-webkit-scrollbar-track': {
					background: '#FFF !important',
				},
			}}
		>
			<Table stickyHeader aria-label="customized table">
				<TableHead>
					<TableRow sx={{ height: '30px' }}>
						{TABLE_HEAD.map(title => (
							<TableCell
								align="center"
								sx={{ padding: 0, fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)', border: 0 }}
							>
								{title}
							</TableCell>
						))}
					</TableRow>
				</TableHead>

				<TableBody>
					{data && data.length > 0 ? (
						data.map((license, i) => (
							<StyledTableRow key={i}>
								<TableCell align="center" sx={{ color: 'rgba(0, 0, 0, 0.9)' }}>
									{license?.resource.applicationName || '-'}
								</TableCell>
								<TableCell align="center" sx={{ color: 'rgba(0, 0, 0, 0.9)' }}>
									{license?.resource.licenseType || '-'}
								</TableCell>
								<TableCell align="center" sx={{ color: 'rgba(0, 0, 0, 0.9)' }}>
									{license?.resource.numberOfLicense || '-'}
								</TableCell>
								<TableCell align="center" sx={{ color: 'rgba(0, 0, 0, 0.9)' }}>
									{license?.resource.assignedLicense || '-'}
								</TableCell>
								<TableCell align="center" sx={{ color: 'rgba(0, 0, 0, 0.9)' }}>
									{license?.resource.avaiableLicense || '-'}
								</TableCell>
							</StyledTableRow>
						))
					) : (
						<StyledTableRow>
							<TableCell colSpan={5}>
								<Box
									sx={{
										height: '100%',
										width: '100%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<Typography
										sx={{
											textAlign: 'center',
											fontSie: '16px',
											margin: '8px 0',
											color: 'black',
										}}
									>
										No Result
									</Typography>
								</Box>
							</TableCell>
						</StyledTableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	</TabPanel>
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&': {
		color: theme.palette.common.black,
	},
	'&:hover td': {
		backgroundColor: '#C8D6FF',
	},
	'& td': {
		height: '38px',
		padding: 0,
		alignItems: 'center',
		border: 'none',
	},
	'&:nth-of-type(odd)': {
		backgroundColor: '#F9FBFF',
	},
	'&:nth-of-type(even)': {
		backgroundColor: theme.palette.common.white,
	},
}));

export default License;
