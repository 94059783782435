// Core
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// MUI components
import AddIcon from '@mui/icons-material/Add';
import { Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

// Styles
import { ActionsWrapper, Collapse, StyledFolder } from '../Tree.style';

//Components
import TreeFolderName from '../TreeFolderName';
import { useTreeContext } from '@rs-ui/context/TreeContext';
import { ProgressDeleteButton } from '@rs-ui/views/ImageViewerView3D/components/ProgressDeleteButton/ProgressDeleteButton';
import TreePlaceholderInput from '../TreePlaceholderInput';

// Folder component
const TreeFolder = ({
	id,
	name,
	level,
	children,
	parentPath,
	isLastChild,
	handleDeleteFolder,
	canEdit,
	studies,
	handleEditName,
	folderOpen,
	parentId,
}) => {
	// Translation
	const { t } = useTranslation('teaching');

	// Context
	const { selectedFolderId, setSelectedFolderId, treeWidth, dispatch, searchText } = useTreeContext();

	// Childs list
	const [childs, setChilds] = useState([]);

	// Edit mode state
	const [isEditMode, setIsEditMode] = useState(false);

	// Folder name state
	const [folderName, setFolderName] = useState(name);

	// increase `level` recursively
	useEffect(() => {
		const nestedChilds = React.Children.map(children, item => {
			if (item && item.type === TreeFolder) {
				return React.cloneElement(item, {
					level: level + 1,
					parentPath: `${parentPath}/${name}`,
				});
			}
			return item;
		});
		setChilds(nestedChilds);
	}, [children]);

	// Set folder open state when search text is changed
	useEffect(() => {
		if (searchText) {
			dispatch({ type: 'OPEN_FOLDER', payload: { id, open: true } });
		}
	}, [searchText]);

	/**
	 * Handle sub folder creation
	 */
	const handleSubFolderCreation = () => {
		const editElement = document.querySelector('.tree__input');
		if (editElement) return;
		dispatch({ type: 'OPEN_FOLDER', payload: { id, open: true } });
		dispatch({ type: 'SHOW_ADD_FOLDER', payload: { id, name: '', level: level + 1 } });
	};

	/**
	 * Handle change name
	 * @param {string} newValue
	 * @returns {void}
	 * */
	const onChangeName = useCallback(
		newValue => {
			if (newValue && folderName !== newValue) {
				setFolderName(newValue.trim());
				handleEditName(id, newValue.trim());
			}

			setIsEditMode(false);
		},
		[folderName, setFolderName, handleEditName, id]
	);

	return (
		<StyledFolder
			className="tree__folder"
			indent={level}
			onClick={event => {
				event.stopPropagation();
			}}
		>
			{!isEditMode ? (
				<ActionsWrapper
					className="tree-actions-wrapper"
					data-testid="tree-actions-wrapper"
					indent={level}
					isSelectedFolder={id === selectedFolderId}
					treePosition={treeWidth - level * 20 - 75}
				>
					<TreeFolderName
						handleClick={(event, isHandleExpand) => {
							event.stopPropagation();
							setSelectedFolderId(id);
							if (isHandleExpand) {
								dispatch({ type: 'OPEN_FOLDER', payload: { id } });
							}
						}}
						isLastChild={isLastChild}
						isOpen={folderOpen}
						isSelectedFolder={id === selectedFolderId}
						level={level}
						name={studies && studies.length > 0 ? `${folderName} (${studies.length})` : folderName}
					/>
					{canEdit && (
						<div className="actions">
							<Tooltip title={t('Add Sub Folder')}>
								<IconButton
									data-cy="actions-add-sub"
									data-testid="actions-add-sub"
									id={`add-subfolder-${id}`}
									sx={{
										padding: '4px',
										backgroundColor: 'rgba(65, 65, 65, 1)',
										color: 'rgba(255, 255, 255, 0.6)',
									}}
									onClick={handleSubFolderCreation}
								>
									<AddIcon sx={{ fontSize: '16px' }} />
								</IconButton>
							</Tooltip>
							<Tooltip title={t('Edit')}>
								<IconButton
									data-cy="actions-edit"
									data-testid={`actions-edit-${id}`}
									sx={{
										padding: '4px',
										backgroundColor: 'rgba(65, 65, 65, 1)',
										color: 'rgba(255, 255, 255, 0.6)',
									}}
									onClick={() => setIsEditMode(true)}
								>
									<EditIcon sx={{ fontSize: '16px' }} />
								</IconButton>
							</Tooltip>
							<ProgressDeleteButton
								circularProgress={{
									size: 26,
								}}
								icon={{
									sx: {
										fontSize: '16px',
									},
								}}
								iconButton={{
									sx: {
										backgroundColor: 'rgba(65, 65, 65, 1)',
										color: 'rgba(255, 255, 255, 0.6)',
										width: '24px',
										height: '24px',
									},
								}}
								tooltipLabel={t('Delete Folder')}
								wrapper={{
									sx: {
										display: 'flex',
									},
								}}
								onDelete={() => {
									handleDeleteFolder(id);
								}}
							/>
						</div>
					)}
				</ActionsWrapper>
			) : (
				<TreePlaceholderInput
					isEdit
					defaultValue={folderName}
					folderLevel={level || 1}
					handleSubmit={onChangeName}
					id={id}
					parentId={parentId}
				/>
			)}

			<Collapse className="tree__folder--collapsible" isOpen={folderOpen}>
				{childs}
			</Collapse>
		</StyledFolder>
	);
};

TreeFolder.propTypes = {
	/**
	 * Folder id
	 */
	id: PropTypes.string,

	/**
	 * Folder name
	 */
	name: PropTypes.string,

	/**
	 * Level of the folder
	 */
	level: PropTypes.number,

	/**
	 * Children of the folder
	 */
	children: PropTypes.node,

	/**
	 * parent path
	 */
	parentPath: PropTypes.string,

	/**
	 * is last child
	 */
	isLastChild: PropTypes.bool,

	/**
	 * Handle delete folder
	 */
	handleDeleteFolder: PropTypes.func,

	/**
	 * Can edit folder
	 */
	canEdit: PropTypes.bool,

	/**
	 * Studies
	 */
	studies: PropTypes.array,

	/**
	 * Handle update folder name
	 */
	handleEditName: PropTypes.func,

	/**
	 * Folder open state
	 */
	folderOpen: PropTypes.bool,

	/**
	 * Parent id
	 */
	parentId: PropTypes.string,
};

TreeFolder.defaultProps = {
	level: 1,
	parentPath: '',
	isLastChild: false,
	children: [],
	id: '',
	name: '',
	handleDeleteFolder: () => {},
	canEdit: false,
	handleEditName: () => {},
	studies: [],
	folderOpen: false,
	parentId: null,
};

export default TreeFolder;
