/**
 * Generate the avatar name
 * @param  {String} label - Input name.
 * @return {String}
 */

const generateAvatarName = label => {
	let initials = '';

	if (label) {
		const items = label.trim().split(' ');
		items.slice(0, 2).forEach(item => {
			initials = initials + item[0];
		});
		if (items.length > 2) {
			initials = initials + '...';
		}
	}
	return initials.toUpperCase();
};

export default generateAvatarName;
