import _getHash from '@cornerstonejs/tools/dist/esm/drawingSvg/_getHash';
import setNewAttributesIfValid from '@cornerstonejs/tools/dist/esm/drawingSvg/setNewAttributesIfValid';

const drawRect = (
	svgDrawingHelper,
	annotationUID,
	rectangleUID,
	start,
	end,
	canvas,
	canvasCoordinates,
	dataId = '',
	done
) => {
	const svgns = 'http://www.w3.org/2000/svg';
	const svgNodeHash = _getHash(annotationUID, 'rect', rectangleUID);
	const existingRect = svgDrawingHelper.getSvgNode(svgNodeHash);

	const tlhc = [Math.min(start[0], end[0]), Math.min(start[1], end[1])];
	const width = Math.abs(start[0] - end[0]);
	const height = Math.abs(start[1] - end[1]);

	const rectPath = `
	M -1 -1
	h ${canvas.offsetWidth + 2}
	v ${canvas.offsetHeight + 2}
	h -${canvas.offsetWidth + 2}
	z
	M ${tlhc[0]} ${tlhc[1]}
	h ${width}
	v ${height}
	h -${width}
	z
	`;

	const attributes = {
		d: rectPath,
		fill: done ? 'black' : 'transparent',
		'fill-rule': 'evenodd',
		stroke: '#fff',
	};

	if (existingRect) {
		setNewAttributesIfValid(attributes, existingRect);

		svgDrawingHelper.setNodeTouched(svgNodeHash);
	} else {
		const svgRectElement = document.createElementNS(svgns, 'path');

		if (dataId !== '') {
			svgRectElement.setAttribute('data-id', dataId);
		}

		setNewAttributesIfValid(attributes, svgRectElement);

		svgDrawingHelper.prependNode(svgRectElement, svgNodeHash);
	}
};

export { drawRect };
