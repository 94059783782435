import {
	AngleTool,
	ArrowAnnotateTool,
	BidirectionalTool,
	EllipticalROITool,
	LengthTool,
	PlanarFreehandROITool,
	ProbeTool,
	RectangleROITool,
} from '@cornerstonejs/tools';
import CobbAngleTool from '../../cornerstoneTools/CobbAngleTool';
import CardiothoracicTool from '../../cornerstoneTools/CardiothoracicTool';
import PlumbLineTool from '../../cornerstoneTools/PlumbLineTool';
import SpineLabelingTool from '../../cornerstoneTools/SpineLabelingTool';

const measurementTools = [
	ArrowAnnotateTool.toolName,
	AngleTool.toolName,
	ProbeTool.toolName,
	RectangleROITool.toolName,
	BidirectionalTool.toolName,
	EllipticalROITool.toolName,
	PlanarFreehandROITool.toolName,
	LengthTool.toolName,
	CobbAngleTool.toolName,
	CardiothoracicTool.toolName,
	PlumbLineTool.toolName,
	SpineLabelingTool.toolName,
];

const isValidMeasurement = (measurement) => {
	if (!measurement?.cornerstone3D || !measurement?.data || !measurement?.metadata) {
		return false;
	}

	const measurementToolName = measurement.metadata.toolName;

	return measurementTools.includes(measurementToolName);
};

export default isValidMeasurement;

export { measurementTools };
