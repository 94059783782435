import axios from 'axios';
import handleApiResponse from './utils';

const getStudies = async ({ __config, patientId, internalManagingOrganizationID, studyId }) => {
	let response = await axios.get(
		`${__config.data_sources.fhir}/ImagingStudyWorklist/elk?internalPatientId=${patientId}&internalManagingOrganizationID=${internalManagingOrganizationID}`
	);
	response = handleApiResponse(response);
	const studies = response?.data?.entry ? response.data.entry.map(item => item.resource) : [];
	const currentStudyPresent = studies.find(item => item?.id === studyId);

	if (currentStudyPresent) {
		return studies;
	}
	let currentStudy = await axios.get(`${__config.data_sources.fhir}/ImagingStudy/${studyId}`);
	currentStudy = handleApiResponse(currentStudy);
	return currentStudy?.data ? [currentStudy?.data, ...studies] : studies;
};

export default getStudies;
