import { create } from 'zustand';
import { commonMiddlewares } from './middlewares';
import getStudies from '../api/getStudies';

export const useStudiesStore = create(
	commonMiddlewares((set, get) => ({
		// state
		studies: [],
		filteredStudies: [],
		isStudiesLoading: false,
		isLoaded: false,

		// setters
		setStudies: studies =>
			set(() => ({
				studies,
			})),
		setFilteredStudies: studies =>
			set(() => ({
				filteredStudies: studies,
			})),
		setIsStudiesLoading: bool =>
			set(() => ({
				isStudiesLoading: bool,
			})),

		// actions
		fetchStudies: async ({ __config }) => {
			try {
				set(() => ({
					isStudiesLoading: true,
				}));

				const response = await getStudies({ __config, status: 'completed' });

				set(() => ({
					studies: [...response],
					filteredStudies: [...response],
					isStudiesLoading: false,
					isLoaded: true,
				}));
			} catch (err) {
				console.error(err);
				throw err;
			}
		},
	}))
);
