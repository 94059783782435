import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { crmSearchScopes, useCRMDataLoader, useAuth } from '@worklist-2/core/src';
import _ from 'lodash';
import PropType from 'prop-types';
import ReactDOM from 'react-dom';
import { useCrmContext } from '../../../context/CRMContext';
import {
	AddRoleCard,
	Badge,
	LinkButton,
	RoleActionWrapper,
	RoleItem,
	SubTitle,
	Tag,
} from '../../../views/CrmView/styles';
import Loader from '../Loader';
import SwitchButton from './SwitchButton';
import { TemMembersAvatars } from './TeamCard';

const RoleManagement = forwardRef((props, ref) => {
	const [selectedRoles, setSelectedRows] = useState([]);
	const [showSearch, toggleSearch] = useState(false);
	const [keyword, setKeyword] = useState('');
	const [tags, setTags] = useState([]);
	const {
		toggleWidget,
		permissionsDefinitions,
		setRoles,
		roles,
		isRoleGridLoading,
		toggleRoleGridLoading,
		toggleReloadUserGrid,
	} = useCrmContext();
	const { loggedInUser } = useAuth();
	const [order, setOrder] = useState('asc');

	const roleDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.roleApi,
	});

	const onDragStart = event => {
		event.dataTransfer?.setData('roleData', JSON.stringify(selectedRoles));
		const container = document.getElementById('drag-ghost');
		ReactDOM.render(
			<DraggableRoleItem role={_.isEmpty(selectedRoles) ? null : selectedRoles[selectedRoles.length - 1]} />,
			container
		);
		event.dataTransfer.setDragImage(container, 0, 0);
		container.style.display = 'hidden';
		document.body.appendChild(container);
	};

	const onDragEnd = useCallback(e => {
		const container = document.getElementById('drag-ghost');
		ReactDOM.render(null, container);
		container.style.display = 'display';
		document.body.appendChild(container);
	}, []);

	useImperativeHandle(ref, () => ({
		toggleLoading: v => {
			toggleRoleGridLoading(v);
		},
	}));

	useEffect(() => {
		if (props.roles) {
			setRoles(props.roles);
		}
	}, [props.roles]);

	useEffect(() => {
		const filter = async () => {
			toggleRoleGridLoading(true);
			await props.filter(tags.join(' '));
			toggleRoleGridLoading(false);
		};
		filter();
	}, [tags]);

	const handleChecked = (e, index, checked) => {
		setSelectedRows(old => {
			const temp = [...old, index];
			if (!checked) {
				_.remove(temp, v => v.RoleId === index.RoleId);
			}
			return _.uniq(temp);
		});
	};

	const handleSearch = e => {
		try {
			e.preventDefault();
			if (!keyword) throw Error('No keyword provided');
			setTags(_.compact(keyword.split(/ |,/)));
			toggleSearch(false);
		} catch (error) {
			console.error(error.message);
		}
	};

	const onRemoveTag = useCallback(
		index => {
			try {
				const tempTags = tags;
				setTags(_.filter(tempTags, (tag, i) => i !== index));
			} catch (error) {
				console.error(error.message);
			}
		},
		[tags]
	);

	const handleRoleSort = field => {
		toggleRoleGridLoading(true);
		const isAsc = order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		roles.sort((a, b) => (isAsc ? b[field]?.localeCompare(a[field]) : a[field]?.localeCompare(b[field])));
		setTimeout(() => {
			toggleRoleGridLoading(false);
		}, 200);
	};

	const deleteSelectedRoles = () => {
		try {
			if (loggedInUser?.permission?.Role?.Delete) {
				_.map(selectedRoles, item => {
					roleDataLoader.delete({ id: item.id || item.RoleId });
					const updatedIndex = _.findIndex(roles, { RoleId: item.RoleId });
					delete roles[updatedIndex];
				});

				setSelectedRows([]);
				toggleReloadUserGrid(true);
			}
		} catch (error) {
			return error;
		}
	};

	return (
		<>
			<div id="drag-ghost" />
			<Box
				style={{
					padding: '0px 38px 0px 36px',
				}}
			>
				{!showSearch ? (
					<SubTitle>
						<span
							data-testid="role-subtitle"
							style={{ cursor: 'pointer' }}
							onClick={() => {
								toggleSearch(true);
								setKeyword('');
							}}
						>
							Roles
						</span>
						<Badge style={{ marginRight: '10px' }}>
							<span>{roles?.length || 0}</span>
						</Badge>
						{_.map(tags, (tag, i) => (
							<Tag key={i}>
								<span>{tag}</span>
								<IconButton
									sx={{ background: '#4D79EA', width: '22px', height: '22px' }}
									onClick={() => onRemoveTag(i)}
								>
									<CloseIcon sx={{ color: '#FFF' }} />
								</IconButton>
							</Tag>
						))}
					</SubTitle>
				) : (
					<form onSubmit={handleSearch}>
						<FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
							<OutlinedInput
								autoFocus
								data-testid="tags-input"
								placeholder="Search Roles"
								size="small"
								startAdornment={
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								}
								sx={{
									borderRadius: '15.5px',
									height: '33px',
								}}
								type="text"
								value={keyword}
								onChange={e => setKeyword(e.target.value)}
							/>
						</FormControl>
					</form>
				)}
			</Box>
			<Box className="invisible-scrollbar" style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
				{isRoleGridLoading ? (
					<Box
						className="invisible-scrollbar"
						sx={{
							flex: 1,
							height: '100%',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
							display: 'flex',
						}}
					>
						<Loader style={{ height: 5 }} />
					</Box>
				) : (
					<>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
								width: '100%',
							}}
						>
							<LinkButton
								sx={{
									marginRight: '47px',
									span: {
										fontFamily: 'Inter',
										fontStyle: 'normal',
										fontWeight: 500,
										fontSize: '10px',
										lineHeight: '12px',
										letterSpacing: '0.5px',
										textTransform: 'uppercase',
										color: 'rgba(0, 0, 0, 0.6)',
									},
								}}
								onClick={e => {
									handleRoleSort('RoleName');
								}}
							>
								<SwapVertIcon
									sx={{
										color: '#4D79EA',
									}}
								/>
								<span>SORT A-Z</span>
							</LinkButton>
						</Box>
						{!!loggedInUser?.permission?.Role?.Add && (
							<AddRoleCard onClick={() => toggleWidget('newRole', { data: permissionsDefinitions })}>
								<AddIcon sx={{ width: '18px', height: '18px', color: '#C4C4C4' }} />
							</AddRoleCard>
						)}
						<Box
							className="invisible-scrollbar"
							style={{
								overflow: 'hidden',
								overflowY: 'scroll',
								display: 'flex',
								flexDirection: 'column',
								flex: '1 1 330px',
							}}
						>
							{roles?.map((role, i) => (
								<RoleListItem
									key={i}
									data={role}
									draggable={selectedRoles.map(r => r.RoleId).includes(role.RoleId)}
									itemSeleted={selectedRoles.map(r => r.RoleId).includes(role.RoleId)}
									onChecked={handleChecked}
									onDragEnd={onDragEnd}
									onDragStart={onDragStart}
								/>
							))}
						</Box>
					</>
				)}
				{selectedRoles.length > 0 && (
					<RoleActionWrapper>
						<span>{`${selectedRoles.length} Selected`}</span>
						<Box className="right">
							{!!loggedInUser?.permission?.Role?.Delete && (
								<IconButton onClick={deleteSelectedRoles}>
									<DeleteOutlineOutlinedIcon sx={{ color: '#FFF' }} />
								</IconButton>
							)}
						</Box>
					</RoleActionWrapper>
				)}
			</Box>
		</>
	);
});

export default RoleManagement;

const DraggableRoleItem = ({ role }) => (
	<Box
		className="role-list-item"
		sx={{
			width: 183,
			height: 109,
			filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.11))',
			background: '#FFFFFF',
			border: '1px solid rgba(196, 196, 196, 0.5)',
			borderRadius: '10px',
			padding: 1,
			position: 'absolute',
			display: 'flex',
			flexDirection: 'column',

			span: {
				fontFamily: 'Inter',
				fontSize: '12px',
				fontWeight: 'normal',
				fontStretch: 'normal',
				fontStyle: 'italic',
				lineHeight: '1',
				letterSpacing: 'normal',
				textAlign: 'left',
				color: 'rgba(0, 0, 0, 0.6)',
				marginRight: '20px',
			},
		}}
	>
		<SubTitle sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '16px' }}>{role?.RoleName}</SubTitle>
		<span>
			{_.size(role?.Permission?.filter(({ Read, Add, Edit, Delete }) => !!Read || !!Add || !!Edit || !!Delete))}{' '}
			privileges defined
		</span>
		<Box
			sx={{
				flex: 1,
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'flex-end',
			}}
		>
			<SwitchButton checked={role?.IsActive || false} onChange={() => {}} />
			<TemMembersAvatars
				members={role?.Member?.map(({ resource }) => resource) || []}
				style={{ marginRight: '12px' }}
			/>
		</Box>
	</Box>
);

export const RoleListItem = ({ data, draggable, onDragStart, onDragEnd, onChecked, itemSeleted }) => {
	const [role, setRole] = useState({});
	const [selected, setSelected] = useState(itemSeleted);
	const [hover, setHover] = useState(false);
	const { toggleWidget, widgetData, setRoles, roles } = useCrmContext();
	const { loggedInUser } = useAuth();
	const [noMargin, setNoMargin] = useState(hover || selected || !onChecked);

	const roleDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.roleApi,
	});

	useEffect(() => {
		setRole(data);
	}, [data]);

	const viewing = useMemo(
		() => widgetData?.tag === 'editRole' && widgetData?.data?.RoleId === role?.RoleId,
		[widgetData, role]
	);

	const handleToggle = async () => {
		try {
			if (loggedInUser?.permission?.Role?.Edit) {
				const newRoleObj = { ...role, IsActive: !role?.IsActive };
				setRole(newRoleObj);
				await roleDataLoader.update(role.id || role.RoleId, newRoleObj);
				const updatedIndex = _.findIndex(roles, { RoleId: newRoleObj.RoleId });
				roles.splice(updatedIndex, 1, newRoleObj);
				setRoles(roles);
				return roles;
			}
		} catch (error) {
			return error;
		}
	};

	useEffect(() => {
		setNoMargin(hover || selected || !onChecked);
	}, [hover, selected, onChecked]);

	return (
		<Box
			data-testid="role-list-item"
			draggable={draggable}
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				marginTop: '10px',
				marginLeft: !onChecked ? '10px' : 'inherit',
				marginRight: noMargin ? '10px' : '37px',
			}}
			onDragEnd={onDragEnd}
			onDragStart={draggable && onDragStart}
			onMouseLeave={() => setHover(false)}
			onMouseOver={() => setHover(true)}
		>
			{onChecked && (hover || selected) && (
				<Box
					data-testid="checkbox-role"
					sx={{
						cursor: 'default',
						margin: '0px 11px',
					}}
					onClick={e => {
						if (onChecked) onChecked(e, role, !selected);
						setSelected(!selected);
					}}
				>
					{selected ? (
						<RadioButtonCheckedOutlinedIcon
							data-testid="checked-role"
							style={{ width: '15px', height: '15px', color: '#4D79EA' }}
						/>
					) : (
						<RadioButtonUncheckedOutlinedIcon
							data-testid="unchecked-role"
							style={{ width: '15px', height: '15px', color: '#C4C4C4' }}
						/>
					)}
				</Box>
			)}
			<RoleItem active={selected || viewing} cursor={draggable ? 'grab' : 'pointer'} noMargin={hover || selected}>
				<Box
					className="item-body"
					sx={{
						opacity: 1,
					}}
				>
					<SwitchButton checked={role?.IsActive || false} onChange={handleToggle} />
					<Box
						sx={{
							flex: 1,
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'flex-end',

							span: {
								fontFamily: 'Inter',
								fontSize: '12px',
								fontWeight: 'normal',
								fontStretch: 'normal',
								fontStyle: 'italic',
								lineHeight: '1',
								letterSpacing: 'normal',
								textAlign: 'left',
								color: 'rgba(0, 0, 0, 0.6)',
								marginRight: '20px',
							},
						}}
						onClick={() => toggleWidget('editRole', role)}
					>
						<SubTitle sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '16px', flex: 1 }}>
							{role.RoleName}
						</SubTitle>
						<span>
							{_.size(
								role?.Permission?.filter(
									({ Read, Add, Edit, Delete }) => !!Read || !!Add || !!Edit || !!Delete
								)
							)}{' '}
							privileges defined
						</span>
						<TemMembersAvatars members={role?.User || []} style={{ marginRight: '12px' }} />
						<IconButton sx={{ width: '20px', height: '20px' }}>
							<SettingsIcon sx={{ width: '14px', height: '14px', color: '#4D79EA' }} />
						</IconButton>
					</Box>
				</Box>
			</RoleItem>
		</Box>
	);
};

RoleListItem.propTypes = {
	data: PropType.object.isRequired,
};

DraggableRoleItem.propTypes = {
	roles: PropType.array.isRequired,
};
