// Common util function to log the events in the application
// Define log levels
const LOG_LEVELS = {
	DEBUG: 0,
	INFO: 1,
	WARN: 2,
	ERROR: 3,
};

// Set default loglevel on init from FF
let logLevel = null;

// Function to set log level
export const setLogLevel = level => {
	if (LOG_LEVELS.hasOwnProperty(level?.toUpperCase())) {
		logLevel = LOG_LEVELS[level.toUpperCase()];
	} else {
		console.error(`Invalid log level: ${level}`);
	}
};

const getDataLog = data => {
	if (!data) {
		return '';
	} else if (data instanceof Error) {
		return `Error: ${data.message}\nStack Trace: ${data.stack || 'Not available'}`;
	} else if (typeof data === 'object') {
		return JSON.stringify(data);
	} else {
		return data;
	}
};

export const logMessage = (level, component, message, data) => {
	if (!LOG_LEVELS.hasOwnProperty(level.toUpperCase())) {
		console.error(`Invalid log level: ${level}`);
		return;
	}

	// Check if log level threshold is greater than or equal to the log entry level
	if (logLevel > LOG_LEVELS[level.toUpperCase()]) {
		return; // Don't log if log level threshold is higher
	}

	const timeStamp = new Date().toISOString();
	const dataLog = getDataLog(data);
	const logData = `[${timeStamp}] [${level.toUpperCase()}] ${component}::${message} ${dataLog}`;

	switch (level.toUpperCase()) {
		case 'DEBUG':
			console.debug(logData);
			break;
		case 'INFO':
			console.info(logData);
			break;
		case 'WARN':
			console.warn(logData);
			break;
		case 'ERROR':
			console.error(logData);
			break;
		default:
			break;
	}
};

// Functions for different log levels
export const logDebug = (component, message, data) => {
	logMessage('DEBUG', component, message, data);
};

export const logInfo = (component, message, data) => {
	logMessage('INFO', component, message, data);
};

export const logWarn = (component, message, data) => {
	logMessage('WARN', component, message, data);
};

export const logError = (component, message, data) => {
	logMessage('ERROR', component, message, data);
};
