import React, { useState } from 'react';
import { Box, Typography, IconButton, Slide } from '@mui/material';

import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';

const ProgressBatch = ({ count }) => (
	<Box sx={{ ...STYLE.BATCH, background: '#42A5F524' }}>
		<Typography sx={{ ...STYLE.BATCH_LABEL, color: '#42A5F5', fontWeight: '500' }}>{count}%</Typography>
		<Typography sx={STYLE.BATCH_LABEL}>Uploading</Typography>
	</Box>
);

const SuccessBatch = () => (
	<Box sx={{ ...STYLE.BATCH, background: '#42F59F24' }}>
		<DownloadDoneIcon sx={{ color: '#42F59F' }} />
		<Typography sx={STYLE.BATCH_LABEL}>Uploaded</Typography>
	</Box>
);

const FailureBatch = () => (
	<Box sx={{ ...STYLE.BATCH, background: '#DB708324' }}>
		<ErrorOutlineIcon sx={{ color: '#DB7083' }} />
		<Typography sx={STYLE.BATCH_LABEL}>Failed</Typography>
	</Box>
);

const SnackBarModal = ({ fileName, inprogress, progressCount, success, failed, close }) => {
	const [visible, setVisible] = useState(true);

	const handleClose = () => {
		setVisible(false);
		setTimeout(close, 300); // Delay to allow the slide-out animation to finish
	};

	let StatusComponent;

	if (inprogress) {
		StatusComponent = <ProgressBatch count={progressCount} />;
	} else if (success) {
		StatusComponent = <SuccessBatch />;
	} else if (failed) {
		StatusComponent = <FailureBatch />;
	}

	return (
		<Slide
			mountOnEnter
			unmountOnExit
			direction={visible ? 'up' : 'right'} // Slide in from the bottom, slide out to the left
			in={visible}
		>
			<Box sx={STYLE.CONTAINER}>
				{StatusComponent}
				<Typography sx={STYLE.FILE_NAME}>{fileName}</Typography>
				{(inprogress || failed) && (
					<IconButton data-testid="close-button" sx={STYLE.CLOSE_BUTTON} onClick={handleClose}>
						<CloseIcon sx={{ color: '#FFFFFF99', fontSize: '18px' }} />
					</IconButton>
				)}
			</Box>
		</Slide>
	);
};

export default SnackBarModal;

const STYLE = {
	CONTAINER: {
		background: '#272727',
		borderRadius: '8px',
		padding: '12px 15px',
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		border: '1px solid #FFFFFF1A',
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
		marginBottom: '5px !important',
	},
	BATCH: {
		borderRadius: '50px',
		height: '24px',
		padding: '5px 8px',
		fontSize: '12px',
		fontWeight: '400',
		lineHeight: '14px',
		display: 'flex',
		alignItems: 'center',
		color: '#FFFFFF',
	},
	BATCH_LABEL: {
		fontSize: '12px',
		lineHeight: '14px',
		fontWeight: '400',
		letterSpacing: '0.25px',
		marginLeft: '4px',
	},
	FILE_NAME: {
		flexGrow: 1,
		fontSize: '14px',
		color: '#FFFFFF',
	},
	CLOSE_BUTTON: {
		color: '#FFFFFF99',
		width: '25px',
		height: '25px',
		alignSelf: 'flex-end',
	},
};
