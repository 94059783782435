// @flow

// core
import React, { useState, type Node } from 'react';
// libs
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
// Calendar localization
import { useTranslation } from 'react-i18next';
import { getDatePickerLocale, datePickerPaperProps } from '@worklist-2/ui/src/components/utils/calendarLocalization';
import moment from 'moment';

interface IFormDatePickerProps {
	// use the interface from @mui directly
	datePickerProps: {
		format: string,
		maxDate: any,
		minDate: any,
		value: any,
		onChange: (date: Date) => void,
	};
	/** The `useForm()` hook from `react-hook-form` library */
	formHook: any;
	/** Whether the input is in edit mode or not */
	isEditing: boolean;
	/**
	 * Text for the label
	 * @default 'Date'
	 */
	label: string;
	/** Name of the field, also holds the mapping to the value of the field */
	name: string;
	/** Props passed to the `<DatePicker />`  */
	inputProps: any;
	props: any;
}

const FormDatePicker = ({
	datePickerProps,
	formHook,
	inputProps,
	isEditing = false,
	label = 'Date',
	name,
	props,
}: IFormDatePickerProps): Node => {
	const { i18n } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const { control, getFieldState } = formHook;
	const { error } = getFieldState(name);

	const onClick = (event: Event) => {
		if (!isEditing) return;
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const onClose = () => {
		setAnchorEl(null);
	};

	return (
		<Controller
			control={control}
			name={name}
			render={({ field }) => (
				<LocalizationProvider adapterlocale={getDatePickerLocale(i18n)} dateAdapter={AdapterDateFns}>
					<DatePicker
						PaperProps={datePickerPaperProps}
						disabled={!isEditing}
						label={label}
						name={name}
						open={open}
						onClose={onClose}
						{...datePickerProps}
						renderInput={params => (
							<TextField
								helperText={error?.message}
								label={label}
								name={name}
								variant={isEditing ? 'outlined' : 'standard'}
								onClick={onClick}
								{...params}
								{...props}
								sx={!isEditing && sxProps.textFieldSX}
								{...inputProps}
								InputProps={{
									endAdornment: undefined,
									startAdornment: isEditing ? params.InputProps.endAdornment : undefined, // Move the calendar icon from right to left and display it only when in edit mode
									sx: sxProps.inputPropsSX,
								}}
								error={!!error?.message}
							/>
						)}
						value={field.value || null}
						onChange={date => {
							if (!date) {
								return field.onChange(null);
							}
							const formattedValue = moment(date).format(datePickerProps?.format || 'MM/DD/YYYY');
							field.onChange(formattedValue);
						}}
					/>
				</LocalizationProvider>
			)}
		/>
	);
};

export default FormDatePicker;

const sxProps = {
	textFieldSX: {
		// Hide the input outline when not editing
		'& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root': {
			fieldset: {
				border: 'none',
			},
		},
	},
	inputPropsSX: {
		paddingLeft: 0,
		'& > div': {
			height: '100%',
			width: '40px',

			'& > button': {
				padding: 0,
			},
		},
	},
};
