let aborter = null;
const searchApps = async ({ config, queryParamString, page, count }) => {
	let url = encodeURI(
		`${config.data_sources.marketplace}apps${queryParamString ?? ''}`
	);

	if (page >= 0) {
		url = `${url}&_page=${page}`;
	}

	if (count) {
		url = `${url}&_resultsperpage=${count}`;
	}

	if(aborter) aborter.abort();
	// make our request cancellable
	aborter = new AbortController();
	const signal = aborter.signal;
	// TO-DO: Temporarily using fetch until back-end CORS issue is sorted
	return await fetch(url, {signal})
		.then((result) => {
			aborter = null;
			return result.json();
		})
		.catch((e) => console.log(e));
};

export default searchApps;
