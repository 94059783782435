import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';

const StyledLink = styled(Link)({
	fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
	fontSize: '12px',
	fontWeight: '500',
	'& > :not(style) + :not(style)': {
		ml: '30px',
	},
	lineHeight: '16px',
	letterSpacing: '1.25px',
	color: '#	',
	opacity: '0.87',
	textDecoration: 'none',
	'&:focus': {
		color: '#42A5F5',
	},
	'&:hover': {
		color: '#42A5F5',
		textDecoration: 'underline',
	},
});

const HyperlinkButton = ({ id, text, link, onClick, style }) => (
	<StyledLink
		className="hyperlink"
		href={link}
		id={id}
		sx={{
			...style,
		}}
		target="_blank"
		underline="none"
		onClick={onClick}
	>
		{text}
	</StyledLink>
);

HyperlinkButton.propTypes = {
	text: PropTypes.string,
	link: PropTypes.string,
	id: PropTypes.string,
	onClick: PropTypes.func,
};

HyperlinkButton.defaultProps = {
	text: 'Text',
	link: '#',
	id: 'link',
};

export default HyperlinkButton;
