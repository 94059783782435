import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';

import { Tooltip, Box, Button, Typography, IconButton } from '@mui/material';
import {
	Bookmark,
	BookmarkBorder,
	NotificationsNone,
	Notifications,
	MapsUgcOutlined,
	DeleteOutlined,
	Lock,
	LockOpen,
	ModeEditOutlineOutlined,
} from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import { useAuth } from '@worklist-2/core/src';

import TextEditor from './TextEditor/TextEditor';

import { Comment, CommentsWrapper } from '../../../casePopOverStyles';

const useStyles = makeStyles()(() => ({
	tooltip: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#4D79EA',
		borderRadius: '5px',
	},

	arrow: {
		color: '#4D79EA',
	},
}));

const DetailComments = ({
	data,
	commentData,
	setCommentData,
	attachments,
	setAttachments,
	submitComment,
	updateComment,
	deleteComment,
	editComment,
	setEditComment,
	sendPrivate,
	setSendPrivate,
	users,
	progress,
}) => {
	const [comments, setComments] = useState();
	const commentBoxRef = useRef();
	const { loggedInUser } = useAuth();
	const getComments = (collectionComment, rootCommentId) => {
		let childComments;

		if (rootCommentId) {
			childComments = _.filter(collectionComment, {
				ParentCommentId: rootCommentId,
			});
		} else {
			childComments = _.filter(data, comment => {
				if (!comment?.ParentCommentId) {
					return comment;
				}
			});
		}

		if (childComments) {
			return _.map(childComments, item => ({
				caseId: item.CaseId,
				caseNumber: item.CaseNumber,
				user: item.LastUpdatedUserName,
				commentedOn: item.CreatedDateTime
					? `${new Date(item.CreatedDateTime).toLocaleDateString()} ${new Date(
							item.CreatedDateTime
					  ).toLocaleTimeString(navigator.language, {
							hour: '2-digit',
							minute: '2-digit',
					  })}`
					: '',
				comment: item.Description,
				comments: getComments(data, item.CommentId),
				createdUserId: item.LastUpdatedUserId ?? item.LastUpdatedUserID,
				createdDateTime: item.CreatedDateTime,
				commentId: item.CommentId,
				parentCommentId: item.ParentCommentId,
				isPrivate: item.IsPrivate,
				bookmark: item.Bookmark,
				attachments: item.Attachments,
				watcher: item.Watcher,
			}));
		}
	};

	useEffect(() => {
		setComments(getComments(data));
	}, [data]);

	useEffect(() => {
		if (editComment.split('_')[0] === 'reply' && !!commentBoxRef.current?.focus) {
			commentBoxRef.current?.focus();
		}
	}, [editComment]);

	return (
		<CommentsWrapper>
			<div className="comments">
				{comments && comments.length > 0 ? (
					comments.map(comment => (
						<CommentItem
							data={comment}
							deleteComment={deleteComment}
							editComment={editComment}
							index={comment.commentId}
							progress={progress}
							setEditComment={setEditComment}
							updateComment={updateComment}
							users={users}
						/>
					))
				) : (
					<Typography
						sx={{
							textAlign: 'center',
							fontWeight: '500',
							marginTop: '8px',
							marginBottom: '8px',
							color: 'text.primary',
						}}
					>
						No comments
					</Typography>
				)}
			</div>
			{!!loggedInUser.permission.Comment.Add && (
				<div className="comment-input-box">
					<TextEditor
						ref={commentBoxRef}
						attachments={attachments}
						commentData={commentData}
						progress={progress}
						sendPrivate={sendPrivate}
						setAttachments={setAttachments}
						setCommentData={setCommentData}
						setSendPrivate={setSendPrivate}
						submitComment={submitComment}
						sx={{
							padding: '15.3px 11.6px',
							width: '100%',
							'& legend': {
								display: 'none',
							},
							'& fieldset': {
								top: 0,
							},
						}}
						users={users}
					/>
				</div>
			)}
		</CommentsWrapper>
	);
};

export default DetailComments;

const CommentItem = ({ index, data, editComment, setEditComment, updateComment, deleteComment, users, progress }) => {
	const { loggedInUser } = useAuth();
	const user = data?.user;
	const comment = data?.comment;
	const comments = data?.comments;
	const commentedOn = data?.commentedOn;
	const isLoggedUserCreated = data?.createdUserId?.toLowerCase() === loggedInUser.id?.toLowerCase();
	const { classes } = useStyles();
	const [commentVal, setCommentVal] = useState(comment);
	const [attachmentsVal, setAttachmentsVal] = useState(data?.attachments ?? []);
	const [editDisable, setEditDisable] = useState(false);
	const replyAction = `reply_${index}`;
	const editAction = `edit_${index}`;
	const hidePrivateComment = data.isPrivate && loggedInUser.id?.toLowerCase() !== data.createdUserId?.toLowerCase();
	const { isPrivate } = data;
	const isBookmark = data.bookmark ? data.bookmark.split(',').includes(loggedInUser.id) : false;

	const getInitials = userName =>
		userName
			?.split(' ')
			.slice(0, 2)
			.map(e => e.substr(0, 1));

	useEffect(() => {
		setAttachmentsVal(data?.attachments ?? []);
	}, [editComment]);

	const onWatch = () => {
		const currentWatchers = data.watcher;
		let newWatchers = [];
		let payload = [];

		if (currentWatchers?.length > 0) {
			if (currentWatchers.find(item => item.UserId === loggedInUser.id)) {
				currentWatchers.forEach(elem => {
					if (elem.UserId !== loggedInUser.id) {
						newWatchers.push(elem);
					}
				});
			} else {
				newWatchers = [
					...currentWatchers,
					{
						UserName: loggedInUser.fullName,
						UserId: loggedInUser.id,
					},
				];
			}

			payload = [
				{
					op: 'replace',
					path: '/Watcher',
					value: newWatchers,
				},
			];
		} else {
			payload.push({
				op: 'replace',
				path: '/Watcher',
				value: [
					{
						UserName: loggedInUser.fullName,
						UserId: loggedInUser.id,
					},
				],
			});
		}

		updateComment({}, 'Watcher', {
			commentId: data.commentId,
			payload,
		});
	};

	return (
		!hidePrivateComment && (
			<Comment>
				<Box
					className="wrapper"
					sx={{
						border: editComment === replyAction ? '1px solid rgba(77, 121, 234, 0.5)' : '',
						borderRadius: '5px',
						background: editComment === replyAction ? 'rgba(77, 121, 234, 0.05)' : '',
						'&:hover, &.active': {
							background: editComment === replyAction ? 'rgba(77, 121, 234, 0.05) !important' : '',
						},
					}}
				>
					<div className="avatar">
						<span>{getInitials(user)}</span>
					</div>
					<div className="main">
						<div className="info">
							<span className="name">{user}</span>
							<span className="datetime">{commentedOn}</span>
							{isBookmark && (
								<span className="private">
									<Bookmark className="icon" sx={{ fontSize: 12 }} />
									<span className="private">BOOKMARK</span>
								</span>
							)}
							{isPrivate && (
								<span className="private">
									<Lock className="icon" sx={{ fontSize: 12 }} />
									<span className="private">PRIVATE</span>
								</span>
							)}
						</div>
						{editComment === editAction ? (
							<div>
								<TextEditor
									isEditMode
									attachments={attachmentsVal}
									commentData={commentVal}
									progress={progress}
									setAttachments={setAttachmentsVal}
									setCommentData={setCommentVal}
									sx={{
										padding: '10px 0px',
										width: '100%',
										'& legend': {
											display: 'none',
										},
										'& fieldset': {
											top: 0,
										},
									}}
									users={users}
								/>
								<Box
									display="flex"
									justifyContent="flex-end"
									sx={{
										marginTop: '8px',
									}}
								>
									<Button
										disabled={editDisable}
										sx={{
											height: '33px',
											width: '80px',
											boxShadow: 'none',
											borderColor: '#4D79EA',
											color: '#4D79EA',
											'&:hover': {
												boxShadow: 'none',
												borderColor: '#4D79EA',
											},
										}}
										variant="outlined"
										onClick={() => setEditComment('')}
									>
										CANCEL
									</Button>
									<Button
										disabled={editDisable}
										sx={{
											height: '33px',
											width: '80px',
											marginLeft: '10px',
											backgroundColor: '#4D79EA',
											borderColor: '#4D79EA',
											boxShadow: 'none',
											'&:hover': {
												backgroundColor: '#4D79EA',
												borderColor: '#4D79EA',
												boxShadow: 'none',
											},
										}}
										variant="contained"
										onClick={async () => {
											setEditDisable(true);
											data.comment = commentVal;
											data.attachments = attachmentsVal;

											updateComment(data, 'Description').then(() => {
												setEditDisable(false);
											});
										}}
									>
										SAVE
									</Button>
								</Box>
							</div>
						) : (
							<TextEditor
								readOnly
								attachments={attachmentsVal}
								commentData={comment}
								sx={{
									padding: '10px 0px',
									width: '100%',
									'& legend': {
										display: 'none',
									},
									'& fieldset': {
										top: 0,
									},
								}}
								users={users}
							/>
						)}
					</div>
					{!editDisable && isLoggedUserCreated ? (
						<div className="menu-btn">
							{!!loggedInUser.permission.Comment.Edit && (
								<Tooltip
									arrow
									classes={{
										tooltip: classes.tooltip,
										arrow: classes.arrow,
									}}
									title="Edit"
								>
									<IconButton
										className="icon-btn"
										type="button"
										onClick={() => {
											setEditComment(editAction);
										}}
									>
										<ModeEditOutlineOutlined className="icon" />
									</IconButton>
								</Tooltip>
							)}

							{!!loggedInUser.permission.Comment.Edit && (
								<Tooltip
									arrow
									classes={{
										tooltip: classes.tooltip,
										arrow: classes.arrow,
									}}
									title={isPrivate ? 'Public' : 'Make private'}
								>
									<IconButton
										className="icon-btn"
										type="button"
										onClick={async () => {
											setEditDisable(true);
											updateComment(data, 'IsPrivate').then(() => {
												setEditDisable(false);
											});
										}}
									>
										{isPrivate ? <LockOpen className="icon" /> : <Lock className="icon" />}
									</IconButton>
								</Tooltip>
							)}

							{!!loggedInUser.permission.Comment.Delete && (
								<Tooltip
									arrow
									classes={{
										tooltip: classes.tooltip,
										arrow: classes.arrow,
									}}
									title="Remove"
								>
									<IconButton
										className="icon-btn"
										type="button"
										onClick={() => {
											deleteComment(index);
										}}
									>
										<DeleteOutlined className="icon" />
									</IconButton>
								</Tooltip>
							)}
						</div>
					) : (
						<div className="menu-btn">
							{!!loggedInUser.permission.Comment.Add && (
								<Tooltip
									arrow
									classes={{
										tooltip: classes.tooltip,
										arrow: classes.arrow,
									}}
									title="Reply"
								>
									<IconButton
										className="icon-btn"
										type="button"
										onClick={() => {
											setEditComment(replyAction);
										}}
									>
										<MapsUgcOutlined className="icon" />
									</IconButton>
								</Tooltip>
							)}
							{!!loggedInUser.permission.Comment.Edit && (
								<Tooltip
									arrow
									classes={{
										tooltip: classes.tooltip,
										arrow: classes.arrow,
									}}
									title="Watch"
									onClick={onWatch}
								>
									<IconButton className="icon-btn" type="button">
										{data?.watcher?.find(item => item.UserId === loggedInUser.id) ? (
											<Notifications className="icon" />
										) : (
											<NotificationsNone className="icon" />
										)}
									</IconButton>
								</Tooltip>
							)}

							{!!loggedInUser.permission.Comment.Edit && (
								<Tooltip
									arrow
									classes={{
										tooltip: classes.tooltip,
										arrow: classes.arrow,
									}}
									title={isBookmark ? 'UnBookmark' : 'Bookmark'}
								>
									<IconButton
										className="icon-btn"
										type="button"
										onClick={() => {
											setEditDisable(true);
											updateComment(data, 'Bookmark').then(() => {
												setEditDisable(false);
											});
										}}
									>
										{isBookmark ? (
											<BookmarkBorder className="icon" />
										) : (
											<Bookmark className="icon" />
										)}
									</IconButton>
								</Tooltip>
							)}
						</div>
					)}
				</Box>
				{!_.isEmpty(comments) && (
					<Box sx={{ marginLeft: '30px' }}>
						{_.map(comments, entry => (
							<CommentItem
								data={entry}
								deleteComment={deleteComment}
								editComment={editComment}
								index={entry.commentId}
								progress={progress}
								setEditComment={setEditComment}
								updateComment={updateComment}
								users={users}
							/>
						))}
					</Box>
				)}
			</Comment>
		)
	);
};
