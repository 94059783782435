import { createTheme } from '@mui/material/styles';

//import commonTheme from './common-theme';

// Creating a palette theme allows us to reference the palettes in our final dark theme
const darkThemePalette = createTheme({
	//...commonTheme,
	palette: {
		mode: 'dark',
		primary: {
			light: '#4f4f4f',
			main: '#272727',
			dark: '#000000',
			contrastText: '#fff',
		},
		rsPrimary: {
			main: '#42A5F5',
			button: '#4096DC',
			buttonLight: '#42a5f533',
			buttonHover: '#42a5f56e',
			hover: '#28679A',
			contrastText: '#FFFFFF',
			buttonDark: '#26262633',
			buttonDarkBorder: '#FFFFFF1A',
		},
		rsSecondary: {
			main: '#121212',
			medium: '#393939',
			dark: '#272727',
		},
		rsError: {
			main: '#CF6679',
			medium: '#f44336',
		},
		text: {
			primary: 'rgba(255, 255, 255, 0.87)',
			border: 'rgba(255, 255, 255, 0.23)',
			faint: 'rgba(255, 255, 255, 0.6)',
		},
		icon: {
			primary: '#ffffff99',
			background: '#FFFFFF',
			border: 'rgba(217, 217, 217, 0.1)',
			selectedBackground: 'rgba(66, 165, 245, 0.06)',
		},
		card: {
			contactCardBackground: '#3D3D3D',
			categoryBackground: '#EAEAEA',
			organizationBackground: '#4B4B4B',
			borderGradient: 'linear-gradient(to right bottom, #0394FD, #4D4D4D)',
		},
		highlight: {
			white: '#FFFFFF',
			blue: '#74A7E5',
			darkblue: '#5487E9',
			pink: '#CE7AD0',
			yellow: '#E3B205',
			cyan: '#03DAC5',
		},
		background: {
			'dark-1': '#121212',
			'dark-2': '#272727',
			'dark-3': '#393939',
			'dark-4': '#414141',
			'dark-5': '#828282',
			'dark-6': '#3d3d3d',
			'white-transparent-1': 'rgba(255, 255, 255, .1)',
			'menu-1': '#323232',
		},
		shades: {
			'black-transparent-1': 'rgba(0, 0, 0, .1)',
			'black-transparent-2': 'rgba(0, 0, 0, .2)',
			'black-transparent-5': 'rgba(0, 0, 0, .5)',
			'black-transparent-8': 'rgba(0, 0, 0, .8)',
			'white-transparent-1': 'rgba(255, 255, 255, .1)',
			'white-transparent-2': 'rgba(255, 255, 255, .2)',
			'white-transparent-5': 'rgba(255, 255, 255, .5)',
			'white-transparent-8': 'rgba(255, 255, 255, .8)',
		},
	},
});

export const darkTheme = createTheme({
	...darkThemePalette,
	typography: {
		fontFamily: [
			'Roboto',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
	},
	components: {
		//...commonTheme.components,
		MuiInput: {
			variants: [
				{
					props: { variant: 'standard', underline: 'faint-white' },
					style: {
						'&:after': {
							borderColor: darkThemePalette.palette.background['white-transparent-5'],
						},
					},
				},
			],
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					'&.Mui-focused': {
						color: darkThemePalette.palette.rsPrimary.main,
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: darkThemePalette.palette.rsPrimary.main,
					},
					'.MuiSvgIcon-root': {
						opacity: 0.6,
					},
				},
			},
		},
		MuiListItemIcon: {
			variants: [
				{
					props: { variant: 'checklist' },
					style: {
						color: darkThemePalette.palette.rsPrimary.main,
					},
				},
			],
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					opacity: 0.87,
				},
			},
		},
		MuiSpeedDial: {
			styleOverrides: {
				fab: {
					backgroundColor: '#3588CB',
					'&.MuiFab-sizeLarge': {
						height: '70px',
						width: '70px',
					},
					'&:hover': {
						backgroundColor: '#28679A',
					},
				},
			},
		},
		MuiSpeedDialAction: {
			styleOverrides: {
				fab: {
					backgroundColor: darkThemePalette.palette.rsSecondary.medium,
					'&.MuiFab-sizeSmall': {
						height: '50px',
						width: '50px',
					},
				},
				staticTooltipLabel: {
					whiteSpace: 'nowrap',
					maxWidth: '300px',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					lineHeight: '2',
					backgroundColor: darkThemePalette.palette.rsSecondary.dark,
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					background: darkThemePalette.palette.rsSecondary.medium,
				},
			},
			variants: [
				{
					props: { variant: 'background' },
					style: {
						padding: '24px 44px',
					},
				},
				{
					props: {
						color: 'dark-4',
					},
					style: {
						backgroundColor: darkThemePalette.palette.background['dark-4'],
					},
				},
				{
					props: {
						variant: 'contact',
						color: 'white-transparent-1',
					},
					style: {
						backgroundColor: darkThemePalette.palette.background['white-transparent-1'],
						borderRadius: '10px',
						padding: '14px 16px',
					},
				},
				{
					props: { variant: 'note' },
					style: {
						background: `linear-gradient(${darkThemePalette.palette.background['dark-4']}, ${darkThemePalette.palette.background['dark-4']}) padding-box, linear-gradient(45deg, rgba(66, 165, 245, .6), rgba(6, 173, 163, .3)) border-box`,
						borderRadius: '24px',
						border: '1px solid transparent',
						padding: '24px !important',
					},
				},
			],
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					'&.Mui-checked': {
						color: '#4096DC',
					},
				},
			},
		},
		MuiCalendarPicker: {
			styleOverrides: {
				root: {
					background: '#393939',
				},
			},
		},
		MuiPickerStaticWrapper: {
			styleOverrides: {
				root: {
					background: '#393939',
				},
			},
		},
		MuiPickersDay: {
			styleOverrides: {
				root: {
					background: '#393939',

					'&.Mui-selected': {
						background: darkThemePalette.palette.rsPrimary.main,
					},
				},
			},
		},
		MuiToolbar: {
			variants: [
				{
					props: {
						variant: 'subsection',
					},
					style: {
						color: darkThemePalette.palette.text.primary,
						justifyContent: 'space-between',
						padding: '15px 0 !important',
					},
				},
				{
					props: {
						variant: 'subsection-2',
					},
					style: {
						color: darkThemePalette.palette.text.primary,
						justifyContent: 'space-between',
						padding: '10px 0 !important',
					},
				},
			],
		},
		MuiTypography: {
			variants: [
				{
					props: {
						ellipsis: true,
					},
					style: {
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					},
				},
			],
		},
	},
	zIndex: {
		tooltip: 9999,
	},
});

export default darkTheme;
