import { useMemo, useState, useEffect } from 'react';
import getDefaultRenderingEngine from '../cornerstone/getDefaultRenderingEngine';
import { Enums } from '@cornerstonejs/core';

const { STACK_NEW_IMAGE } = Enums.Events;

export const useFrames = ({ viewportId, isInitialized }) => {
	const [totalFrames, setTotalFrames] = useState(0);
	const [currentFrame, setCurrentFrame] = useState(0);

	const shouldRender = useMemo(() => totalFrames > 1 && currentFrame, [totalFrames, currentFrame]);

	useEffect(() => {
		const element = document.getElementById(viewportId);

		if (!element || !isInitialized) {
			return;
		}

		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) {
			return;
		}

		const cornerstoneViewport = renderingEngine.getViewport(viewportId);

		if (!cornerstoneViewport) {
			return;
		}

		setTotalFrames(cornerstoneViewport.getImageIds()?.length);
		setCurrentFrame(cornerstoneViewport.getCurrentImageIdIndex() + 1);

		const onStackNewImage = e => {
			setCurrentFrame(e.detail.imageIdIndex + 1);
		};

		element.addEventListener(STACK_NEW_IMAGE, onStackNewImage);

		return () => {
			element.removeEventListener(STACK_NEW_IMAGE, onStackNewImage);
		};
	}, [viewportId, isInitialized]);

	return {
		totalFrames,
		currentFrame,
		setTotalFrames,
		setCurrentFrame,
		shouldRender,
	};
};
