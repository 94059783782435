import React from 'react';
import { Stack, Box, Button, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TextEditor from '../../components/CRM/Cases/CaseDetailPopOver/DetailTabs/Comments/TextEditor/TextEditor';

export const NotifPanelContainer = props => {
	return (
		<Stack
			{...props}
			sx={{
				position: 'absolute',
				top: '40px',
				right: '45px',
				width: '381px',
				height: '546px',
				borderRadius: '20px',
				background: '#FFFFFF',
				filter: 'drop-shadow(0px 0px 20px rgba(33, 88, 119, 0.40))',
				zIndex: 60,
				alignItems: 'center',
				justifyContent: 'flex-start',
			}}
		/>
	);
};
export const GeneralNotificationContainer = props => {
	return (
		<Stack
			{...props}
			sx={{
				position: 'absolute',
				top: '40px',
				right: '45px',
				width: '10%',
				height: 'auto',
				borderRadius: '20px',
				background: '#FFFFFF',
				filter: 'drop-shadow(0px 0px 20px rgba(33, 88, 119, 0.40))',
				zIndex: 60,
				alignItems: 'center',
				justifyContent: 'flex-start',
			}}
		/>
	);
};

export const NotifPanelHeader = props => {
	return (
		<Stack
			{...props}
			direction={'row'}
			height={'47px'}
			width={'335px'}
			alignItems={'center'}
			justifyContent={'flex-start'}
		/>
	);
};

export const NotifPanelTitle = props => {
	return (
		<Typography
			{...props}
			sx={{
				marginRight: '5px',
				fontWeight: 500,
				fontSize: '16px',
				lineHeight: '19.36px',
				color: 'rgba(0, 0, 0, 0.9)',
			}}
		/>
	);
};

export const NotifCount = props => {
	return (
		<Typography
			{...props}
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				marginLeft: '5px',
				minWidth: '22px',
				height: '24px',
				borderRadius: '6px',
				background: 'rgba(196, 196, 196, 0.35)',
				color: 'rgba(0, 0, 0, 0.6)',
				fontWeight: 500,
				fontSize: '14px',
			}}
		/>
	);
};

export const NotifClearButton = props => {
	return (
		<Typography
			{...props}
			sx={{
				marginLeft: 'auto',
				color: 'rgba(77, 121, 234, 1)',
				letterSpacing: '1.5px',
				fontSize: '12px',
				fontWeight: 500,
				textTransform: 'Uppercase',
			}}
		/>
	);
};

export const NotifContainer = props => {
	return (
		<Stack
			{...props}
			spacing={'5px'}
			position={'relative'}
			sx={{
				height: '455px',
				width: '381px',
				overflowY: 'scroll',
				scrollbarWidth: 'none',
				'::-webkit-scrollbar': {
					width: 0,
					height: 0,
				},
			}}
		/>
	);
};
export const GeneralNotifContainer = props => {
	return (
		<Stack
			{...props}
			position={'relative'}
			sx={{
				// paddingTop:'10px',
				// paddingBottom:'10px',
				padding: '15px 10px 15px 10px',
				width: '100%',
				overflowY: 'scroll',
				scrollbarWidth: 'none',
				'::-webkit-scrollbar': {
					width: 0,
					height: 0,
				},
			}}
		/>
	);
};

export const Notification = props => {
	return (
		<Stack
			{...props}
			direction={'row'}
			width={'381px'}
			alignItems={'center'}
			sx={{
				background: '#F9FBFE',
				cursor: props.cursor,
				'&:hover .delete-hover-button': {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '75px',
					height: '100%',
					background: '#EF5D5D',
					transition: '300ms ease-in-out',
				},
				'&:hover .delete-icon': {
					display: 'box',
					color: '#FFF',
					fontSize: '24px',
				},
			}}
		/>
	);
};
export const GeneralNotification = props => {
	return (
		<Stack
			{...props}
			direction={'row'}
			width="100%"
			alignItems={'center'}
			sx={{
				cursor: 'pointer',
				'&:hover': {
					background: '#F9FBFE',
				},
			}}
		/>
	);
};

export const NotificationBody = props => {
	return <Box {...props} width={'300px'} marginRight={'20px'} />;
};

export const ResourceChange = props => {
	return (
		<Typography
			{...props}
			sx={{
				fontSize: '12px',
				fontWeight: 500,
				color: '#4D79EA',
				marginBottom: '2px',
			}}
		/>
	);
};

export const PassedTime = props => {
	return (
		<Typography
			{...props}
			sx={{
				marginLeft: 'auto',
				fontSize: '12px',
				fontStyle: 'italic',
				fontWeight: 400,
				color: 'rgba(0, 0, 0, 0.6)',
			}}
		/>
	);
};

export const Title = props => {
	return (
		<Typography
			{...props}
			sx={{
				fontSize: '12px',
				fontWeight: 400,
				color: 'rgba(0, 0, 0, 0.4)',
			}}
		/>
	);
};

export const Description = props => {
	return (
		<Typography
			{...props}
			sx={{
				height: '30px',
				fontSize: '12px',
				fontWeight: 500,
				color: 'rgba(0, 0, 0, 0.9)',
				lineHeight: '14.52px',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				display: '-webkit-box',
				WebkitLineClamp: '2',
				WebkitBoxOrient: 'vertical',
				maxWidth: '220px',
			}}
		/>
	);
};

export const CommentDescription = props => {
	return (
		<TextEditor
			{...props}
			readOnly={true}
			sx={{
				height: '30px',
				fontSize: '12px',
				fontWeight: 500,
				fontFamily: 'Inter',
				color: 'rgba(0, 0, 0, 0.9)',
				lineHeight: '14.52px',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				display: '-webkit-box',
				WebkitLineClamp: '2',
				WebkitBoxOrient: 'vertical',
			}}
		/>
	);
};

export const DeleteButton = props => {
	return (
		<Box
			{...props}
			sx={{
				'&.delete-hover-button': {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: 0,
					height: '100%',
					background: '#EF5D5D',
					transition: '300ms ease-in-out',
				},
			}}
		/>
	);
};

export const NotifPanelFooter = props => {
	return (
		<Typography
			{...props}
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: '47px',
				width: '335px',
				fontSize: '14px',
				fontWeight: 400,
				color: 'rgba(77, 121, 234, 1)',
			}}
		/>
	);
};

export const NoNotifications = props => {
	return <Typography {...props}>No Notifications</Typography>;
};

export const ShowSpinner = props => {
	return (
		<Stack {...props} direction={'row'} alignItems={'center'} spacing={2}>
			<CircularProgress
				sx={{
					color: '#4D79EA',
					width: '24px !important',
					height: '24px !important',
				}}
			/>
			<Typography>Loading</Typography>
		</Stack>
	);
};

export const NotifOutlinedButton = props => {
	return (
		<Button
			{...props}
			variant="outlined"
			sx={{
				maxHeight: props.maxHeight,
				width: '100%',
				height: '100%',
				padding: '6px',
				fontFamily: 'Inter',
				fontWeight: 400,
				fontSize: '16px',
				color: '#4D79EA',
				textTransform: 'unset',
				border: '1px solid #4D79EA',
				'&:hover': {
					border: '1px solid #4D79EA',
				},
			}}
		/>
	);
};

export const NotifContainedButton = props => {
	return (
		<Button
			{...props}
			variant="contained"
			sx={{
				maxHeight: props.maxHeight,
				width: '100%',
				height: '100%',
				marginLeft: props.marginLeft,
				padding: '6px',
				fontFamily: 'Inter',
				fontWeight: 400,
				fontSize: '16px',
				color: '#FFF',
				textTransform: 'unset',
				background: '#4D79EA',
				border: '1px solid transparent',
				boxShadow: 'none',
				'&:hover': {
					background: '#4D79EA',
					boxShadow: 'none',
				},
			}}
		/>
	);
};
