import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Chip, CircularProgress, Typography } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import DocumentIcon from '@worklist-2/ui/src/assets/icons/breeze/DocumentIcon.svg';
import CloseIcon from '@mui/icons-material/Close';
import { useBreezeAPI } from '@rs-core/hooks';
import { useCrmContext } from '../../../../../context/CRMContext';

const FileList = ({ organizationId, accountId, setStepCustom, data, setDbClickOrganization }) => {
	const [filteredFiles, setFilteredFiles] = useState([]);
	const [files, setFiles] = useState(null);
	const [isFetching, setIsFetching] = useState(false);
	const [activeFilter, setActiveFilter] = useState('users');
	const { fetchDocumentsUploaded, deleteDocument, downloadDocument } = useBreezeAPI();
	const { cacheKey } = useCrmContext();

	const handleFilterClick = filterKey => {
		setFilteredFiles(files[filterKey]);
		setActiveFilter(filterKey);
	};

	const handleDownloadFile = async file => {
		const response = await downloadDocument(accountId, organizationId, activeFilter, file);
		const blob = new Blob([response], { type: file.ContentType });
		const url = URL.createObjectURL(blob);

		// This is the only way to set a name to the file, altrough i dont like it
		const link = document.createElement('a');
		link.target = '_blank';
		link.href = url;
		link.download = `${file.Title}_${new Date().getTime()}`;
		link.click();

		// Release the object URL created
		URL.revokeObjectURL(url);
	};

	const handleDeleteFile = async file => {
		if (confirm('Are you sure you want to delete this file?')) {
			await deleteDocument(accountId, organizationId, activeFilter, file.id);
			setFiles(prevState => {
				const updatedFiles = { ...prevState };
				updatedFiles[activeFilter] = prevState[activeFilter].filter(f => f.id !== file.id);
				return updatedFiles;
			});
			setFilteredFiles(prevState => prevState.filter(f => f.id !== file.id));
		}
	};

	useEffect(() => {
		let shouldFetch = true;
		async function fetchFiles() {
			if (accountId && organizationId && !isFetching && shouldFetch) {
				setIsFetching(true);
				const documentFiles = await fetchDocumentsUploaded(accountId, organizationId);
				if (documentFiles) {
					setFiles(documentFiles);
					setFilteredFiles(documentFiles.users);
					setActiveFilter('users');
				}
				setIsFetching(false);
			}
		}

		fetchFiles();

		return () => {
			shouldFetch = false;
		};
	}, [accountId, organizationId, cacheKey]);

	return (
		<Box paddingX={3}>
			<Box display="flex" gap={2} marginTop={2}>
				<FileFilter
					active={activeFilter === 'users'}
					label="Users"
					onClickHandler={() => handleFilterClick('users')}
				/>
				<FileFilter
					active={activeFilter === 'orderSets'}
					label="Order Sets"
					onClickHandler={() => handleFilterClick('orderSets')}
				/>
				<FileFilter
					active={activeFilter === 'procedureCodes'}
					label="Procedure Codes"
					onClickHandler={() => handleFilterClick('procedureCodes')}
				/>
				<FileFilter
					active={activeFilter === 'insuranceCompanies'}
					label="Insurance Companies"
					onClickHandler={() => handleFilterClick('insuranceCompanies')}
				/>
				<FileFilter
					active={activeFilter === 'feeSchedules'}
					label="Fee Schedules"
					onClickHandler={() => handleFilterClick('feeSchedules')}
				/>
			</Box>
			<Box marginTop={4} padding={0}>
				<Button
					color="info"
					sx={{
						paddingY: 0,
						paddingX: 1,
						textTransform: 'capitalize',
					}}
					variant="contained"
					onClick={() => {
						setDbClickOrganization(data);
						setStepCustom('upload-from-account-fullscreen');
					}}
				>
					+ Upload
				</Button>
			</Box>
			<Box padding={3}>
				{isFetching && (
					<CircularProgress
						sx={{
							color: '#4D79EA',
							width: '24px !important',
							height: '24px !important',
						}}
					/>
				)}
				{filteredFiles && filteredFiles.length > 0 ? (
					filteredFiles.map(file => (
						<Box key={file.id} display="flex" justifyContent="space-between">
							<Box alignItems="center" display="flex" gap={2}>
								<DocumentIcon />
								<Box>
									<Typography fontWeight="500" variant="body1">
										{file.Title}
									</Typography>
									<Box display="flex">
										<Typography color="#888" fontSize={13}>
											{file.CreatedAt} • {file.FileSize} • {file.ContentType.split('/').pop()}
										</Typography>
									</Box>
								</Box>
							</Box>
							<Box
								alignItems="flex-end"
								display="flex"
								flexDirection="column"
								justifyContent="space-between"
							>
								<CloseIcon
									color="disabled"
									fontSize="small"
									sx={{
										cursor: 'pointer',
										marginRight: '-40px',
									}}
									onClick={() => handleDeleteFile(file)}
								/>
								<Button color="info" variant="outlined" onClick={() => handleDownloadFile(file)}>
									Preview
								</Button>
							</Box>
						</Box>
					))
				) : (
					<Typography variant="h6">No files found in this organization with the selected filter.</Typography>
				)}
			</Box>
		</Box>
	);
};

const FileFilter = ({ label, active, onClickHandler }) => (
	<Chip
		color={active ? 'info' : 'default'}
		icon={<ListIcon />}
		label={<Typography color={active ? '#0288d1' : 'inherit'}>{label}</Typography>}
		sx={{
			':hover': {
				background: active ? '#333' : '#7fbcde85',
				border: active ? '1px solid #7fbcde85' : '1px solid #666',
			},
			...(active ? {} : { border: '1px solid transparent' }),
		}}
		variant="outlined"
		onClick={onClickHandler}
	/>
);

FileFilter.propTypes = {
	label: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired,
	onClickHandler: PropTypes.func.isRequired,
};

FileList.propTypes = {
	organizationId: PropTypes.string.isRequired,
	accountId: PropTypes.string.isRequired,
};

export default FileList;
