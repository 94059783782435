import React from 'react';

import { Typography } from '@mui/material';

import CustomTextInput from '@worklist-2/ui/src/components/Help/CustomTextInput';

import { useHelpCenterContext } from '../../HelpCenter/HelpCenterContext';

import { deviceDimensions, devices } from './devices';

import styles from './PreviewOptions.module.scss';

function PreviewOptions({ theme }) {
	const { activeDeviceType, width, height, setResponsive, inputWidth, inputHeight } = useHelpCenterContext();

	const selectDevice = device => {
		if (device === 'Responsive') {
			setResponsive(true);
		} else {
			setResponsive(false);
			inputWidth(deviceDimensions[device][0]);
			inputHeight(deviceDimensions[device][1]);
		}
	};

	return (
		<div className={styles.PreviewOptions} data-testid="preview-options">
			<div className={styles.inputContainer}>
				<CustomTextInput
					className={styles.input}
					inputProps={{
						style: {
							color: `${theme === 'OAI' ? '#FFFFFF' : '#00000099'}`,
						},
					}}
					placeholder={1920}
					style={{
						marginLeft: '7.5%',
					}}
					value={width}
					onChange={e => inputWidth(parseInt(e.target.value ? e.target.value : 0))}
				/>
				<Typography style={{ display: 'inline-block', color: `${theme === 'OAI' ? '#FFFFFF' : '#00000099'}` }}>
					{' '}
					x{' '}
				</Typography>
				<CustomTextInput
					className={styles.input}
					inputProps={{
						style: {
							color: `${theme === 'OAI' ? '#FFFFFF' : '#00000099'}`,
						},
					}}
					placeholder={1080}
					style={{
						marginRight: '7.5%',
					}}
					value={height}
					onChange={e => inputHeight(parseInt(e.target.value ? e.target.value : 0))}
				/>
			</div>
			{devices[activeDeviceType] && (
				<div
					style={{
						borderTop: `1px solid ${theme === 'OAI' ? '#FFFFFF1A' : '#C4C4C466'}`,
						padding: '5px 0',
						fontFamily: 'Inter',
						fontSize: '14px',
					}}
				>
					{devices[activeDeviceType]?.map(item => (
						<Typography
							style={{
								marginLeft: '15px',
								cursor: 'pointer',
								color: `${theme === 'OAI' ? '#FFFFFF' : '#00000099'}`,
								padding: '5px 0',
							}}
							onClick={() => selectDevice(item)}
						>
							{item}
						</Typography>
					))}
				</div>
			)}
		</div>
	);
}

export default PreviewOptions;
