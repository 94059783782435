import axios from 'axios';

// status and cancellation reason extension should updated in this appointment object
const cancelAppointment = async ({ __config, appointment }) => {
	const response = await axios.put(`${__config.data_sources.blume}Appointment/${appointment.id}`, appointment);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default cancelAppointment;
