import { AnnotationTool } from '@cornerstonejs/tools';
import { getEnabledElement, getEnabledElementByIds } from '@cornerstonejs/core';
import {
	addAnnotation,
	getAnnotation,
	getAnnotations,
	removeAnnotation,
	getAnnotationManager,
} from '@cornerstonejs/tools/dist/esm/stateManagement/annotation/annotationState';
import { isAnnotationLocked } from '@cornerstonejs/tools/dist/esm/stateManagement/annotation/annotationLocking';
import { isAnnotationVisible } from '@cornerstonejs/tools/dist/esm/stateManagement/annotation/annotationVisibility';
import { triggerAnnotationCompleted } from '@cornerstonejs/tools/dist/esm/stateManagement/annotation/helpers/state';
import { drawHandles as drawHandlesSvg } from '@cornerstonejs/tools/dist/esm/drawingSvg';
import { state } from '@cornerstonejs/tools/dist/esm/store';
import { Events } from '@cornerstonejs/tools/dist/esm/enums';
import { getViewportIdsWithToolToRender } from '@cornerstonejs/tools/dist/esm/utilities/viewportFilters';
import { resetElementCursor, hideElementCursor } from '@cornerstonejs/tools/dist/esm/cursors/elementCursor';
import triggerAnnotationRenderForViewportIds from '@cornerstonejs/tools/dist/esm/utilities/triggerAnnotationRenderForViewportIds';
import { getCanvasCircleRadius } from '@cornerstonejs/tools/dist/esm/utilities/math/circle';
import { drawCircle as drawCircleSvg } from './drawCircle';
import { renderingEngineId } from '../../contexts/ImageViewerCornerstoneContext';

class ShutterCircleTool extends AnnotationTool {
	toolName;

	editData;

	isDrawing;

	isHandleOutsideImage = false;

	constructor(
		toolProps,
		defaultToolProps = {
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				shadow: true,
				preventHandleOutsideImage: false,
				// Radius of the circle to draw  at the center point of the circle.
				// Set this zero(0) in order not to draw the circle.
				centerPointRadius: 0,
			},
		}
	) {
		super(toolProps, defaultToolProps);
	}

	addNewAnnotation = evt => {
		const eventDetail = evt.detail;
		const { currentPoints, element } = eventDetail;
		const worldPos = currentPoints.world;

		const enabledElement = getEnabledElement(element);
		const { viewport, renderingEngine } = enabledElement;

		this.isDrawing = true;

		const camera = viewport.getCamera();
		const { viewPlaneNormal, viewUp } = camera;

		const referencedImageId = this.getReferencedImageId(viewport, worldPos, viewPlaneNormal, viewUp);

		const FrameOfReferenceUID = viewport.getFrameOfReferenceUID();

		const annotation = {
			highlighted: true,
			invalidated: true,
			metadata: {
				toolName: this.getToolName(),
				viewPlaneNormal: [...viewPlaneNormal],
				viewUp: [...viewUp],
				FrameOfReferenceUID,
				referencedImageId,
			},
			data: {
				label: '',
				handles: {
					points: [[...worldPos], [...worldPos]],
					activeHandleIndex: null,
				},
			},
		};

		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.editData = {
			annotation,
			viewportIdsToRender,
			newAnnotation: true,
			hasMoved: false,
		};
		this._activateDraw(element);

		hideElementCursor(element);

		evt.preventDefault();

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		return annotation;
	};

	isPointNearTool = (element, annotation, canvasCoords, proximity) => {
		const enabledElement = getEnabledElement(element);
		const { viewport } = enabledElement;

		const { data } = annotation;
		const { points } = data.handles;

		// For some reason Typescript doesn't understand this, so we need to be
		// more specific about the type
		const canvasCoordinates = points.map(p => viewport.worldToCanvas(p));

		const radius = getCanvasCircleRadius(canvasCoordinates);
		const radiusPoint = getCanvasCircleRadius([canvasCoordinates[0], canvasCoords]);

		if (Math.abs(radiusPoint - radius) < proximity / 2) {
			return true;
		}

		return false;
	};

	toolSelectedCallback = (evt, annotation) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		annotation.highlighted = true;

		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.editData = {
			annotation,
			viewportIdsToRender,
		};

		hideElementCursor(element);

		this._activateModify(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		evt.preventDefault();
	};

	handleSelectedCallback = (evt, annotation, handle) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;
		const { data } = annotation;

		annotation.highlighted = true;

		let handleIndex;

		if (!handle.worldPosition) {
			const { points } = data.handles;

			handleIndex = points.findIndex(p => p === handle);
		}

		// Find viewports to render on drag.
		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.editData = {
			annotation,
			viewportIdsToRender,
			handleIndex,
		};
		this._activateModify(element);

		hideElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		evt.preventDefault();
	};

	_endCallback = evt => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const { annotation, viewportIdsToRender, newAnnotation } = this.editData;
		const { data } = annotation;

		// Circle ROI tool should reset its highlight to false on mouse up (as opposed
		// to other tools that keep it highlighted until the user moves. The reason
		// is that we use top-left and bottom-right handles to define the circle,
		// and they are by definition not in the circle on mouse up.
		annotation.highlighted = false;
		data.handles.activeHandleIndex = null;

		this._deactivateModify(element);
		this._deactivateDraw(element);

		resetElementCursor(element);

		const { renderingEngine } = getEnabledElement(element);

		this.editData = null;
		this.isDrawing = false;

		if (this.isHandleOutsideImage && this.configuration.preventHandleOutsideImage) {
			removeAnnotation(annotation.annotationUID);
		}

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		const annotationAdded = getAnnotation(annotation?.annotationUID);

		if (annotationAdded && newAnnotation) {
			annotationAdded.done = true;

			triggerAnnotationCompleted(annotation);
		}
	};

	_dragDrawCallback = evt => {
		this.isDrawing = true;
		const eventDetail = evt.detail;
		const { element } = eventDetail;
		const { currentPoints, viewportId } = eventDetail;
		const currentCanvasPoints = currentPoints.canvas;
		const enabledElement = getEnabledElement(element);
		const { renderingEngine, viewport } = enabledElement;
		const { canvasToWorld } = viewport;

		const { annotation, viewportIdsToRender } = this.editData;
		const { data } = annotation;

		const annotationAdded = getAnnotation(annotation?.annotationUID);

		if (!annotationAdded) {
			const enabledViewport = getEnabledElementByIds(viewportId, renderingEngineId);

			if (enabledViewport?.viewport) {
				const imageId = enabledViewport.viewport?.getCurrentImageId();
				const annotationManager = getAnnotationManager();
				const annotations = annotationManager.getAllAnnotations();

				annotations?.forEach(_annotation => {
					if (
						_annotation.metadata?.toolName === ShutterCircleTool.toolName &&
						_annotation.metadata?.referencedImageId === imageId
					) {
						removeAnnotation(_annotation.annotationUID);
					}
				});
			}

			addAnnotation(annotation);
		}

		data.handles.points = [
			data.handles.points[0], // center stays
			canvasToWorld(currentCanvasPoints), // end point moves (changing radius)
		];

		annotation.invalidated = true;

		this.editData.hasMoved = true;

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
	};

	_dragModifyCallback = evt => {
		this.isDrawing = true;
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const { annotation, viewportIdsToRender, handleIndex } = this.editData;
		const { data } = annotation;

		if (handleIndex === undefined) {
			// Moving tool
			const { deltaPoints } = eventDetail;
			const worldPosDelta = deltaPoints.world;

			const points = data.handles.points;

			points.forEach(point => {
				point[0] += worldPosDelta[0];
				point[1] += worldPosDelta[1];
				point[2] += worldPosDelta[2];
			});
			annotation.invalidated = true;
		} else {
			this._dragHandle(evt);
			annotation.invalidated = true;
		}

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
	};

	_dragHandle = evt => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;
		const enabledElement = getEnabledElement(element);
		const { canvasToWorld, worldToCanvas } = enabledElement.viewport;

		const { annotation, handleIndex } = this.editData;
		const { data } = annotation;
		const { points } = data.handles;

		const canvasCoordinates = points.map(p => worldToCanvas(p));

		// Move current point in that direction.
		// Move other points in opposite direction.

		const { currentPoints } = eventDetail;
		const currentCanvasPoints = currentPoints.canvas;

		if (handleIndex === 0) {
			// Dragging center, move the circle ROI
			const dXCanvas = currentCanvasPoints[0] - canvasCoordinates[0][0];
			const dYCanvas = currentCanvasPoints[1] - canvasCoordinates[0][1];

			const canvasCenter = currentCanvasPoints;
			const canvasEnd = [canvasCoordinates[1][0] + dXCanvas, canvasCoordinates[1][1] + dYCanvas];

			points[0] = canvasToWorld(canvasCenter);
			points[1] = canvasToWorld(canvasEnd);
		} else {
			// Dragging end point, center stays
			points[1] = canvasToWorld(currentCanvasPoints);
		}
	};

	cancel = element => {
		// If it is mid-draw or mid-modify
		if (this.isDrawing) {
			this.isDrawing = false;
			this._deactivateDraw(element);
			this._deactivateModify(element);
			resetElementCursor(element);

			const { annotation, viewportIdsToRender, newAnnotation } = this.editData;
			const { data } = annotation;

			annotation.highlighted = false;
			data.handles.activeHandleIndex = null;

			const { renderingEngine } = getEnabledElement(element);

			triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

			if (newAnnotation) {
				triggerAnnotationCompleted(annotation);
			}

			this.editData = null;
			return annotation.annotationUID;
		}
	};

	_activateModify = element => {
		state.isInteractingWithTool = true;

		element.addEventListener(Events.MOUSE_UP, this._endCallback);
		element.addEventListener(Events.MOUSE_DRAG, this._dragModifyCallback);
		element.addEventListener(Events.MOUSE_CLICK, this._endCallback);

		element.addEventListener(Events.TOUCH_END, this._endCallback);
		element.addEventListener(Events.TOUCH_DRAG, this._dragModifyCallback);
		element.addEventListener(Events.TOUCH_TAP, this._endCallback);
	};

	_deactivateModify = element => {
		state.isInteractingWithTool = false;

		element.removeEventListener(Events.MOUSE_UP, this._endCallback);
		element.removeEventListener(Events.MOUSE_DRAG, this._dragModifyCallback);
		element.removeEventListener(Events.MOUSE_CLICK, this._endCallback);

		element.removeEventListener(Events.TOUCH_END, this._endCallback);
		element.removeEventListener(Events.TOUCH_DRAG, this._dragModifyCallback);
		element.removeEventListener(Events.TOUCH_TAP, this._endCallback);
	};

	_activateDraw = element => {
		state.isInteractingWithTool = true;

		element.addEventListener(Events.MOUSE_UP, this._endCallback);
		element.addEventListener(Events.MOUSE_DRAG, this._dragDrawCallback);
		element.addEventListener(Events.MOUSE_MOVE, this._dragDrawCallback);
		element.addEventListener(Events.MOUSE_CLICK, this._endCallback);

		element.addEventListener(Events.TOUCH_END, this._endCallback);
		element.addEventListener(Events.TOUCH_DRAG, this._dragDrawCallback);
		element.addEventListener(Events.TOUCH_TAP, this._endCallback);
	};

	_deactivateDraw = element => {
		state.isInteractingWithTool = false;

		element.removeEventListener(Events.MOUSE_UP, this._endCallback);
		element.removeEventListener(Events.MOUSE_DRAG, this._dragDrawCallback);
		element.removeEventListener(Events.MOUSE_MOVE, this._dragDrawCallback);
		element.removeEventListener(Events.MOUSE_CLICK, this._endCallback);

		element.removeEventListener(Events.TOUCH_END, this._endCallback);
		element.removeEventListener(Events.TOUCH_DRAG, this._dragDrawCallback);
		element.removeEventListener(Events.TOUCH_TAP, this._endCallback);
	};

	renderAnnotation = (enabledElement, svgDrawingHelper) => {
		let renderStatus = false;
		const { viewport } = enabledElement;
		const { element } = viewport;

		let annotations = getAnnotations(this.getToolName(), element);

		if (!annotations?.length) {
			return renderStatus;
		}

		annotations = this.filterInteractableAnnotationsForElement(element, annotations);

		if (!annotations?.length) {
			return renderStatus;
		}

		const styleSpecifier = {
			toolGroupId: this.toolGroupId,
			toolName: this.getToolName(),
			viewportId: enabledElement.viewport.id,
		};

		for (let i = 0; i < annotations.length; i++) {
			const annotation = annotations[i];
			const { annotationUID, data } = annotation;
			const { handles } = data;
			const { points, activeHandleIndex } = handles;

			styleSpecifier.annotationUID = annotationUID;

			const { color, lineWidth, lineDash } = this.getAnnotationStyle({
				annotation,
				styleSpecifier,
			});

			const canvasCoordinates = points.map(p => viewport.worldToCanvas(p));
			const center = canvasCoordinates[0];
			const radius = getCanvasCircleRadius(canvasCoordinates);

			const { centerPointRadius } = this.configuration;

			// If rendering engine has been destroyed while rendering
			if (!viewport.getRenderingEngine()) {
				console.warn('Rendering Engine has been destroyed');
				return renderStatus;
			}

			let activeHandleCanvasCoords;

			if (!isAnnotationVisible(annotationUID)) {
				continue;
			}

			if (!isAnnotationLocked(annotation) && !this.editData && activeHandleIndex !== null) {
				// Not locked or creating and hovering over handle, so render handle.
				activeHandleCanvasCoords = [canvasCoordinates[activeHandleIndex]];
			}

			if (activeHandleCanvasCoords) {
				const handleGroupUID = '0';
				drawHandlesSvg(svgDrawingHelper, annotationUID, handleGroupUID, activeHandleCanvasCoords, {
					color,
				});
			}

			const dataId = `${annotationUID}-circle`;
			const circleUID = '0';
			drawCircleSvg(
				svgDrawingHelper,
				annotationUID,
				circleUID,
				center,
				radius,
				viewport.canvas,
				dataId,
				annotation.done
			);

			// draw center point, if "centerPointRadius" configuration is valid.
			if (centerPointRadius > 0) {
				if (radius > 3 * centerPointRadius) {
					drawCircleSvg(svgDrawingHelper, annotationUID, `${circleUID}-center`, center, centerPointRadius, {
						color,
						lineDash,
						lineWidth,
					});
				}
			}

			renderStatus = true;
		}

		return renderStatus;
	};
}

ShutterCircleTool.toolName = 'ShutterCircleTool';
export default ShutterCircleTool;
