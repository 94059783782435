import React, { useState } from 'react';

const BookmarkIcon = ({ circleColor, hoverColor }) => {
	const [hover, setHover] = useState(false);

	return (
		<svg
			data-testid="BookmarkIcon"
			fill="none"
			height="18"
			viewBox="0 0 19 18"
			width="19"
			xmlns="http://www.w3.org/2000/svg"
			onMouseEnter={() => (hoverColor ? setHover(true) : '')}
			onMouseLeave={() => setHover(false)}
		>
			<g clipPath="url(#clip0_5211_39501)">
				<path
					d="M2.5 3.75H1.25V12.5C1.25 13.1875 1.8125 13.75 2.5 13.75H11.25V12.5H2.5V3.75ZM12.5 1.25H5C4.3125 1.25 3.75 1.8125 3.75 2.5V10C3.75 10.6875 4.3125 11.25 5 11.25H12.5C13.1875 11.25 13.75 10.6875 13.75 10V2.5C13.75 1.8125 13.1875 1.25 12.5 1.25ZM10.625 2.5V5.625L10 5.15625L9.375 5.625V2.5H10.625ZM12.5 10H5V2.5H8.125V8.125L10 6.71875L11.875 8.125V2.5H12.5V10Z"
					fill={`${hover ? hoverColor : '#C4C4C4'}`}
				/>
			</g>
			<circle cx="14" cy="13" fill={circleColor} r="5" />
			<path
				d="M17 13.4286H14.4286V16H13.5714V13.4286H11V12.5714H13.5714V10H14.4286V12.5714H17V13.4286Z"
				fill={`${hover ? hoverColor : '#C4C4C4'}`}
			/>
			<defs>
				<clipPath id="clip0_5211_39501">
					<rect fill="white" height="15" width="15" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default BookmarkIcon;
