import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import _ from 'lodash';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { EventEmitter, useAuth, useAppModeContext, useHelpCenter, useRouter } from '@worklist-2/core/src';
import HelpCenterCategories from './HelpCenterCategories';
import ArticlesSearchResult from './ArticlesSearchResult/ArticlesSearchResult';
import { Wrapper } from './helpCenterStyles';
import { StyledTab, StyledTabDisabled, StyledTabs } from '../CustomTabs';
import HelpSideBar from '../../../components/SideBar/HelpSideBar';
import HelpOverview from './HelpOverview';

import PopOutIcon from '../../../assets/icons/popout.svg';
import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const excludedPathsWhenUserHasNoRole = [
	'logs',
	'marketPlace',
	'myApps/biTool',
	'search/searchStudy',
	'search/searchOrganization',
	'search/searchDicom',
	'search/searchUser',
	'search/searchPatient',
	'omegaDial/sendStudy',
	'worklist/scheduler',
	'organization',
];

const recursiveFilterHelpLinks = helpLinks => {
	const newHelpLinks = helpLinks?.filter(link => !excludedPathsWhenUserHasNoRole.includes(link.path));
	newHelpLinks.forEach(link => {
		if (link.tabs) {
			link.tabs = recursiveFilterHelpLinks(link.tabs);
		}
		if (link.subNavLink) {
			link.subNavLink = recursiveFilterHelpLinks(link.subNavLink);
		}
	});
	return newHelpLinks;
};

function HelpCenter() {
	const { t } = useTranslation('helpCenter');
	const { userRoleForOrganizations } = useAuth();
	const [searchData, setSearchData] = useState([]);
	const { setSearchResults, handleQuestionChange, setCurrentSideTab, omegaAIHelpLinks } = useHelpCenter();
	const [filteredOmegaAIHelpLinks, setFilteredOmegaAIHelpLinks] = useState([]);
	const crossGetPrivilegeOnDemand = useBooleanFlagValue('cross-get-privilege-on-demand');

	const { updateAppMode } = useAppModeContext();

	useEffect(() => {
		updateAppMode('helpCenter');
		const keyword = document.getElementById('top-search')?.value;
		setSearchResults(searchData);
		handleQuestionChange(keyword);

		return () => {
			localStorage.removeItem('markdownPath');
			setCurrentSideTab(null);
		};
	}, [searchData]);

	useEffect(() => {
		let newHelpLinks;
		if (userRoleForOrganizations.length < 1 && !crossGetPrivilegeOnDemand) {
			newHelpLinks = recursiveFilterHelpLinks(omegaAIHelpLinks);
		} else {
			newHelpLinks = omegaAIHelpLinks;
		}
		setFilteredOmegaAIHelpLinks(newHelpLinks || []);
	}, [crossGetPrivilegeOnDemand ? null : userRoleForOrganizations, omegaAIHelpLinks, crossGetPrivilegeOnDemand]);

	return (
		<Wrapper>
			<span data-testid="helpPageTitle" id="pageTitle">
				<Typography
					mb={2.25}
					sx={{
						color: 'primary.contrastText',
						fontSize: '24px !important',
						fontWeight: '400',
					}}
					variant="h1"
				>
					{t('Help Center')}
				</Typography>
			</span>
			<Grid container marginTop="26px" spacing={1} wrap="nowrap">
				<HelpSideBar navLinks={filteredOmegaAIHelpLinks} />
				{/* main */}
				<Grid
					item
					xs
					sx={{
						overflow: 'hidden',
						height: 'calc(100vh - 150px)',
						borderRadius: '10px',
						display: 'flex',
						flex: 1,
					}}
				>
					<Paper
						className="organizationDetailNav"
						style={{
							marginTop: 0,
							flex: 1,
							overflowY: 'auto',
							borderRadius: '10px',
						}}
					>
						<Routes>
							{filteredOmegaAIHelpLinks.map(item => (
								<>
									{!item.path.endsWith('overview') ? (
										<>
											<Route
												key={item.path}
												exact
												element={<HelpCenterCategories category={item.path} />}
												path={item.path}
											/>
											{!!item.tabs &&
												item.tabs.map((tab, i) => (
													<Route
														key={tab.path}
														exact
														element={
															<HelpCenterCategories category={item.path} tabIndex={i} />
														}
														path={tab.path}
													/>
												))}
											{!!item.subNavLink &&
												item.subNavLink.map(link => (
													<>
														<Route
															key={link.path}
															exact
															element={
																<HelpCenterCategories
																	category={item.path}
																	subCategory={link.path}
																/>
															}
															path={link.path}
														/>
														{!!link.tabs &&
															link.tabs.map((tab, i) => (
																<Route
																	key={tab.path}
																	exact
																	element={
																		<HelpCenterCategories
																			category={item.path}
																			subCategory={link.path}
																			tabIndex={i}
																		/>
																	}
																	path={tab.path}
																/>
															))}
													</>
												))}
										</>
									) : (
										<>
											{['/', item.path].map((path, index) => (
												<Route
													key={index}
													element={<HelpOverview tabIndex={0} />}
													path={path}
												/>
											))}

											{!!item.tabs &&
												item.tabs.map((tab, i) => (
													<Route
														key={tab.path}
														exact
														element={<HelpOverview tabIndex={i} />}
														path={tab.path}
													/>
												))}
										</>
									)}
								</>
							))}

							<Route element={<ArticlesSearchResult />} path="articlesresult" />
						</Routes>
					</Paper>
				</Grid>
			</Grid>
		</Wrapper>
	);
}

export default HelpCenter;

export const HelpPopOverButton = () => {
	const { togglePopover } = useHelpCenter();
	const { goTo } = useRouter();
	const destination = sessionStorage.getItem('stuck-point');

	return (
		<IconButton
			data-testid="help-popover-btn"
			id="help-popover-btn"
			style={{
				width: 40,
				height: 40,
				position: 'relative',
				left: '100%',
				marginTop: -6,
			}}
			onClick={() => {
				EventEmitter.dispatch('update-popover-content');
				togglePopover(true);
				if (destination) {
					setTimeout(() => goTo.any(destination), 500);
				}
			}}
		>
			<PopOutIcon />
		</IconButton>
	);
};

export const CustomStyledTabs = ({ value, tabs, onClick }) => {
	const { t } = useTranslation('helpCenter');
	return (
		<Box className="styled-tabs" sx={{ bgcolor: '#2f2f2f' }}>
			<StyledTabs
				aria-label="styled-tabs"
				data-testid="HelpCenterCustomStyledTabs"
				value={value}
				onChange={onClick}
			>
				{_.map(tabs, (tab, i) =>
					value == i ? (
						<StyledTabDisabled
							key={i}
							label={typeof tab === 'object' ? t(tab.label) : t(tab)}
							style={{ zIndex: _.size(tabs) + 1 }}
						/>
					) : (
						<StyledTab
							key={i}
							label={typeof tab === 'object' ? t(tab.label) : t(tab)}
							style={{ zIndex: _.size(tabs) - i }}
							onClick={() => onClick(null, i)}
						/>
					)
				)}
			</StyledTabs>
		</Box>
	);
};

// TODO: I have no idea what's going on here, but it seems like navLinks was deleted a while ago. I'm putting in an empty array to solve the build warning
// but we need to clean this up.
export const navLinks = [];
