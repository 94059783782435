/* Generated automagically by FhirStarter - DO NOT EDIT */
const patientActivitySchema = {
	id: '__string__',
	resourceType: '__string__',
	subject: '__Reference(Patient)__',
	internalPatientID: '__string__',
	practitioner: '__Reference(Practitioner)__',
	activityType: '__string__',
	callType: '__string__',
	comment: '__string__',
	loginName: '__string__',
	userName: '__string__',
};
export default patientActivitySchema;
