/* Generated automagically by FhirStarter - DO NOT EDIT */
import { getSchema } from '../datatypes';
import { validateArgs } from './validateArgs';

const buildMetaFunc = args => {
	const { id, extension, versionId, lastUpdated, source, profile, security, tag } = args;
	const schema = getSchema('Meta');
	let valid = validateArgs(schema, args, Object.keys(args));

	return {
		id: id,
		extension: extension,
		versionId: versionId,
		lastUpdated: lastUpdated,
		source: source,
		profile: profile,
		security: security,
		tag: tag,
		__valid: valid,
	};
};

export default buildMetaFunc;
