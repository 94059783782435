import { Enums } from '@cornerstonejs/core';
import { Box, Stack, Typography } from '@mui/material';
import getDefaultRenderingEngine from '../../cornerstone/getDefaultRenderingEngine';
import CheckIcon from '@worklist-2/ui/src/assets/icons/checkmark.svg';
import { HotKeyText } from './HotKeyInputItem';
import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined';

const isSigmoidModality = modality => {
	const _modality = modality?.toUpperCase();
	return _modality == 'DX' || _modality == 'MG' || _modality == 'CR';
};

const applyVOILUTFunction = (viewportId, voiLutFunction, needRender = true) => {
	const renderingEngine = getDefaultRenderingEngine();
	if (!renderingEngine) {
		return;
	}

	const viewport = renderingEngine.getViewport(viewportId);
	if (!viewport) {
		return;
	}

	viewport.setProperties({
		VOILUTFunction: voiLutFunction,
	});

	if (needRender) {
		viewport.render();
	}
};

const toggleSigmoid = (viewportId, needRender = true) => {
	const renderingEngine = getDefaultRenderingEngine();
	if (!renderingEngine) {
		return;
	}

	const viewport = renderingEngine.getViewport(viewportId);
	if (!viewport) {
		return;
	}

	const currentVOILUTFunction = viewport.VOILUTFunction;
	if (currentVOILUTFunction !== Enums.VOILUTFunctionType.SAMPLED_SIGMOID) {
		applyVOILUTFunction(viewportId, Enums.VOILUTFunctionType.SAMPLED_SIGMOID, needRender);
	} else {
		applyVOILUTFunction(viewportId, Enums.VOILUTFunctionType.LINEAR, needRender);
	}
};

const SigmoidItem = ({ wonIvSigmoid, wonIvWlHotkey, viewportId, modality, onClose, hotkeyItem }) => {
	if (!(wonIvSigmoid && isSigmoidModality(modality))) {
		return <></>;
	}

	const renderingEngine = getDefaultRenderingEngine();

	if (!renderingEngine) {
		return <></>;
	}

	const viewport = renderingEngine.getViewport(viewportId);

	if (!viewport) {
		return <></>;
	}

	const currentVOILUTFunction = viewport?.VOILUTFunction;

	const onClickSignmoid = e => {
		e.stopPropagation();
		onClose();

		toggleSigmoid(viewportId);
	};

	return (
		<Box
			data-testid="sigmoid-button"
			as="button"
			variant="text"
			onMouseDown={e => e.stopPropagation()}
			onMouseUp={e => e.stopPropagation()}
			onClick={e => onClickSignmoid(e)}
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			bgcolor="transparent"
			border="none"
			sx={{ cursor: 'pointer' }}
		>
			<Stack direction="row" alignItems="center" gap="10px" sx={{ width: '100%' }}>
				{currentVOILUTFunction === Enums.VOILUTFunctionType.SAMPLED_SIGMOID ? (
					<CheckIcon />
				) : (
					<CheckIcon style={{ visibility: 'hidden' }} />
				)}
				<Stack
					justifyContent="space-between"
					direction="row"
					sx={{ width: '100%', alignItems: 'center', gap: '10px' }}
				>
					<Stack
						direction="row"
						sx={{
							width: '100%',
							alignItems: 'center',
							gap: '10px',
						}}
					>
						<Typography
							color="rgba(255, 255, 255, 0.87)"
							fontFamily="Roboto"
							fontSize={16}
							sx={{
								whiteSpace: 'nowrap',
								maxWidth: '115px',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
							}}
						>
							Sigmoid
						</Typography>
					</Stack>
					{wonIvWlHotkey && hotkeyItem && (
						<Stack
							color="rgba(255, 255, 255, 0.6)"
							direction="row"
							sx={{
								alignItems: 'center',
								width: '100%',
								marginLeft: '10px',
								justifyContent: 'flex-end',
							}}
						>
							<KeyboardOutlinedIcon
								sx={{
									fontSize: '24px',
								}}
							/>
							<Typography fontFamily="Roboto" fontSize={16}>
								<HotKeyText item={hotkeyItem} />
							</Typography>
						</Stack>
					)}
				</Stack>
			</Stack>
		</Box>
	);
};

export { SigmoidItem, applyVOILUTFunction, isSigmoidModality, toggleSigmoid };
