import axios from 'axios';

const getUserFacilities = async ({ __config, familyMemberHash }) => {
	const response = await axios.get(
		`${__config.data_sources.blume}User/Facility?familyMemberHash=${familyMemberHash}`
	);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default getUserFacilities;
