import LastPageIcon from '@mui/icons-material/LastPage';
import { Box, Tab, Tabs, Select, MenuItem, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useNavigate } from 'react-router';
import { InfoRow, Title, Wrapper } from '../../Cases/casePopOverStyles';
import Cases from './DetailTabs/Cases';
import Overview from './DetailTabs/Overview';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { useCrmContext } from '../../../../context/CRMContext';
import { TargetEntities } from '../../Cases/utils';
import {
	accountMapping,
	crmSearchScopes,
	useCRMDataLoader,
	useCRMSearchScope,
	useAuth,
	accountForm,
	useConfig,
} from '@worklist-2/core/src';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Organization from './DetailTabs/Organization';
import Users from './DetailTabs/Users';

export const DetailTabs = styled(Tabs)({
	'& .MuiTabs-indicator': {
		backgroundColor: '#4D79EA',
	},
});

export const DetailTab = styled(props => <Tab disableRipple {...props} />)(({ theme }) => ({
	textTransform: 'none',
	minWidth: 0,
	color: '#c4c4c4',
	[theme.breakpoints.up('sm')]: {
		minWidth: 0,
	},
	fontWeight: theme.typography.fontWeightRegular,
	marginRight: theme.spacing(1),
	fontFamily: [
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(','),
	'&:hover': {
		color: '#4D79EA',
		opacity: 1,
	},
	'&.Mui-selected': {
		color: '#4D79EA',
		fontWeight: theme.typography.fontWeightMedium,
	},
	'&.Mui-focusVisible': {
		backgroundColor: '#d1eaff',
	},
}));

const AccountDetailPopOver = ({ summaryData, fullData, setFullData }) => {
	const __config = useConfig();
	const replaceOp = 'replace';
	const numericRegex = /\d/;
	const accountDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.accountApi,
	});
	const contactDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.contactApi,
	});
	const { validateAccountForm } = useCRMSearchScope();
	const tabs = ['Overview', 'Organization', 'Users'];
	const { loggedInUser } = useAuth();
	const ref = useRef();
	const [overViewData, setOverViewData] = useState({});
	const [tabIndex, setTabIndex] = useState(0);
	const { toggleDetails, setIsRefresh } = useCrmContext();
	const [editableIndex, setEditableIndex] = useState(null);
	const navigate = useNavigate();
	const [errMsgs, setErrMsgs] = useState({});
	const [isValidForm, setIsValidForm] = useState(true);
	const [watcherData, setWatcherData] = useState([]);
	const [watching, setWatching] = useState(false);
	const [hoveredField, setHoveredField] = useState(null);
	const [loadingRow, setLoadingRow] = useState(false);
	const [data, setData] = useState({});
	const [masterOrganization, setMasterOrganization] = useState(null);
	const resourceGroup = __config.resource_group;
	const entities = resourceGroup?.includes('team')
		? [{ name: resourceGroup.replaceAll('-', ' ').toUpperCase().replace('TEAM', 'Team'), value: 'resourceGroup' }]
		: TargetEntities[resourceGroup];

	const handleTabChange = (event, newTabIndex) => {
		setTabIndex(newTabIndex);
	};

	const [contactData, setContactData] = useState([]);

	useEffect(() => {
		fetchData().catch(console.error);
	}, [summaryData]);

	useEffect(() => {
		setContactData(
			_.map(data?.RelatedContact, contact => ({
				name: contact.FullName ? contact.FullName : contact.AccountName,
				jobTitle: contact.JobTitle,
				phone: contact.CellPhone,
				email: contact.Email,
			}))
		);

		setWatcherData(data?.Watcher ?? []);
		setMasterOrganization({ id: data?.Organization?.id, name: data?.Organization?.name });
	}, [data]);

	const fetchData = async loader => {
		const fnName = 'load';
		const bodyData = undefined;
		if (!loader) {
			loader = accountDataLoader;
		}

		setLoadingRow(true);
		setData(null);

		try {
			await loader[fnName]({ id: summaryData.id }, !!bodyData || undefined, bodyData).then(result => {
				const newData = result;
				setData(newData);
				setLoadingRow(false);
			});
		} catch (e) {
			console.error(e);
			setLoadingRow(false);
		}
	};

	useEffect(() => {
		const ignoreClickOnMeElement = ref.current;
		const customButton = [
			'hoverEditButton',
			'closeEditButton',
			'contactEditButton',
			'submitNewContact',
			'closeNewContact',
		];

		const listener = event => {
			const isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
			if (
				!isClickInsideElement &&
				!event.target.id &&
				!customButton.includes(event.target.id.split('-')[0]) &&
				!customButton.includes(event.target.parentElement.id.split('-')[0]) &&
				!event.target?.getAttribute('class')?.split(' ')?.includes('MuiBackdrop-invisible') &&
				!document.activeElement.className?.split(' ')?.includes('MuiMenuItem-root')
			) {
				toggleDetails();
			}
		};
		document.addEventListener('click', listener, true);

		return () => {
			document.removeEventListener('click', listener, true);
		};
	}, []);

	const onChangeData = async (e, value) => {
		const targetId = e.target.id ? e.target.id : '';
		const targetParentId = e.target.parentElement?.id ? e.target.parentElement.id : '';

		if (targetId.split('_')[0] === 'closeEditButton' || targetParentId.split('_')[0] === 'closeEditButton') {
			const accountData = overViewData;
			if (value == 'billing_address' || value == 'shipping_address') {
				delete accountData[accountMapping[`${value}_address_line_1`]];
				delete accountData[accountMapping[`${value}_address_line_2`]];
				delete accountData[accountMapping[`${value}_city`]];
				delete accountData[accountMapping[`${value}_province`]];
				delete accountData[accountMapping[`${value}_country`]];
				delete accountData[accountMapping[`${value}_postal_code`]];
			} else {
				delete accountData[accountMapping[value]];
			}
			setOverViewData(accountData);
			setErrMsgs({});
			setEditableIndex(null);
		} else if (tabs[tabIndex].toLowerCase().includes('overview')) {
			handleOverViewTabChange(e.target.name, e.target.value);
		} else if (tabs[tabIndex].toLowerCase().includes('contacts')) {
			handleContactTabChange(value);
		}
	};

	const handleOverViewTabChange = (name, value) => {
		const accountData = overViewData;
		accountData[accountMapping[name]] = value;
		setOverViewData(accountData);
	};

	const handleContactTabChange = async value => {
		let tempData = contactData;
		if (value.Index == undefined) {
			tempData.push(value);
			const contactJson = buildContactJson(value);
			if (value.id === undefined && !!value.name) {
				await contactDataLoader.save(null, contactJson);
			}
		} else if (value.Deleted) {
			tempData = _.remove(tempData, currentObject => !currentObject.Deleted);
		}
		setContactData(tempData);
	};

	const handleSubmit = async event => {
		event.preventDefault();
		let accountJson = {};

		if (
			!loggedInUser.permission.Account.Edit ||
			!validateAccountForm(event.target, overViewData, setErrMsgs, setIsValidForm, true)
		) {
			return;
		}

		const submitField = document.activeElement.id.replace('submitButton_', '');
		const fieldName = [];
		const fieldValue = [];
		fieldName.push(accountMapping[submitField]);

		switch (document.activeElement.value) {
			case 'commonSubmit':
				accountJson = buildAccountJson(event.target, data, true, false, false, null, summaryData);
				break;
			case 'overviewSubmit':
				accountJson = buildAccountJson(event.target, data, false, true, false, null, summaryData);
				if (submitField === 'parent_account') {
					fieldName.splice(0, 1);
					fieldName.push('ParentAccount', 'ParentAccountId');
					fieldValue.push(accountJson.ParentAccount, accountJson.ParentAccountId);
				} else if (submitField === 'ownership') {
					fieldName.splice(0, 1);
					fieldName.push('Ownership', 'OwnershipId');
					fieldValue.push(accountJson.Ownership, accountJson.OwnershipId);
				} else if (submitField == 'billing_address' || submitField == 'shipping_address') {
					fieldName.splice(0, 1);
					fieldName.push(
						accountMapping[`${submitField}_address_line_1`],
						accountMapping[`${submitField}_address_line_2`],
						accountMapping[`${submitField}_city`],
						accountMapping[`${submitField}_province`],
						accountMapping[`${submitField}_country`],
						accountMapping[`${submitField}_postal_code`]
					);
					fieldValue.push(
						accountJson[accountMapping[`${submitField}_address_line_1`]],
						accountJson[accountMapping[`${submitField}_address_line_2`]],
						accountJson[accountMapping[`${submitField}_city`]],
						accountJson[accountMapping[`${submitField}_province`]],
						accountJson[accountMapping[`${submitField}_country`]],
						accountJson[accountMapping[`${submitField}_postal_code`]]
					);
				}
				break;
			case 'contactSubmit':
				accountJson = buildAccountJson(event.target, data, false, false, true);
				fieldName.splice(0, 1);
				fieldName.push('RelatedContact');
				break;
			case 'watcherSubmit':
				const tempData = [...watcherData];
				if (isWatching()) {
					_.remove(tempData, watcher => watcher.UserId === loggedInUser.id);
				} else {
					tempData.push({
						UserName: loggedInUser.display,
						UserId: loggedInUser.id,
					});
				}
				setWatcherData(tempData);
				accountJson = buildAccountJson(event.target, data, false, false, false, tempData);
				break;
		}

		if (fieldValue.length === 0) {
			fieldValue.push(accountJson[fieldName]);
		}

		const result = await accountDataLoader.patch(
			data.AccountId ? data.AccountId : data.id,
			fieldName,
			fieldValue,
			replaceOp
		);
		if (result.status == 409) {
			setErrMsgs({ clientId: 'Already exist' });
			setIsValidForm(false);
			return;
		}

		setEditableIndex(null);

		try {
			setIsRefresh(true);
			const updatedIndex = _.findIndex(fullData, { AccountId: accountJson.AccountId });
			fullData.splice(updatedIndex, 1, accountJson);
			setFullData(fullData);
			await new Promise(r => setTimeout(r, 500));
			setIsRefresh(false);
		} catch (error) {
			console.error(error);
		}
	};

	const buildAccountJson = (
		eventData,
		accountData,
		isCommonSubmit,
		isOverviewSubmit,
		isContactSubmit,
		watchers,
		summaryAccount
	) => {
		const accountJson = accountData;
		accountJson.ResourceType = 'Account';
		accountJson.Watcher = watchers ?? watcherData;

		if (isCommonSubmit) {
			accountJson.ClientId = eventData.clientId?.value?.toUpperCase() ?? accountJson.ClientId;
			accountJson.AccountName = eventData.accountName?.value ?? accountJson.AccountName;
			accountJson.Phone = eventData.phone?.value ?? accountJson.Phone;
			accountJson.ReferenceStatus = eventData.referenceStatus?.value ?? accountJson.ReferenceStatus;
			accountJson.AccountType = eventData.accountType?.value ?? accountJson.AccountType;
			accountJson.TargetEntity = eventData.targetEntity?.value ?? accountJson.TargetEntity;

			summaryAccount.ClientId = eventData.clientId?.value?.toUpperCase() ?? accountJson.ClientId;
			summaryAccount.AccountName = eventData.accountName?.value ?? accountJson.AccountName;
			summaryAccount.Phone = eventData.phone?.value ?? accountJson.Phone;
			summaryAccount.ReferenceStatus = eventData.referenceStatus?.value ?? accountJson.ReferenceStatus;
			summaryAccount.AccountType = eventData.accountType?.value ?? accountJson.AccountType;
			summaryAccount.TargetEntity = eventData.targetEntity?.value ?? accountJson.TargetEntity;
		}

		if (isOverviewSubmit) {
			//Data need handle before post
			for (const item in overViewData) {
				accountJson[item] = overViewData[item];
				summaryAccount[item] = overViewData[item];
			}
		}

		if (isContactSubmit) {
			accountJson.RelatedContact = _.map(contactData, contact => ({
				FullName: contact.name,
				JobTitle: contact.jobTitle,
				CellPhone: contact.phone,
				Email: contact.email,
			}));
		}

		return accountJson;
	};

	const buildContactJson = contactItem => ({
		FullName: contactItem.name,
		JobTitle: contactItem.jobTitle,
		CellPhone: contactItem.phone,
		Email: contactItem.email,
	});

	useEffect(() => {
		setWatching(isWatching());
	}, [watcherData]);

	const isWatching = () => _.some(watcherData, watcher => watcher.UserId === loggedInUser.id);

	const displayValue = item => {
		if (!data[item.valueName]) {
			return item.fieldName === 'referenceStatus' ? 'N/A' : '-';
		}

		return item.fieldName === 'targetEntity'
			? entities.find(targetEntity => targetEntity.value === data[item.valueName])?.name || '-'
			: data[item.valueName];
	};

	return (
		<form onSubmit={handleSubmit}>
			<Wrapper ref={ref} className="invisible-scrollbar" id="popover-wrapper">
				{!loadingRow && data ? (
					<Wrapper className="invisible-scrollbar" id="popover-wrapper">
						<div className="header">
							<div className="actions">
								<div className="left" />
								<div className="right">
									<IconButton
										id="submitButton_watcher"
										sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
										type="submit"
										value="watcherSubmit"
									>
										{watching ? <VisibilityIcon /> : <VisibilityOutlinedIcon />}
									</IconButton>
									<IconButton
										sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
										onClick={() => navigate(`/account/${data.AccountId || data.id}`)}
									>
										<CropFreeIcon />
									</IconButton>
									<IconButton sx={{ color: 'rgba(0, 0, 0, 0.6)' }} onClick={() => toggleDetails()}>
										<LastPageIcon />
									</IconButton>
								</div>
							</div>
							<Title>
								<Select
									disabled
									IconComponent=""
									defaultValue={data?.Status}
									name="status"
									sx={{
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											border: 'none',
										},
										'& .MuiOutlinedInput-notchedOutline': {
											border: 'none',
										},
										height: '20px',
										width: '40px',
										justifyContent: 'center',
									}}
								>
									<MenuItem value="busy">
										<Brightness1Icon sx={{ fontSize: 'medium', color: 'red' }} />
									</MenuItem>
									<MenuItem value="away">
										<Brightness1Icon sx={{ fontSize: 'medium', color: 'yellow' }} />
									</MenuItem>
									<MenuItem value="active">
										<Brightness1Icon
											sx={{
												fontSize: 'medium',
												color: '#3BE775',
											}}
										/>
									</MenuItem>
								</Select>
								{editableIndex === 'accountName' ? (
									<div className="row">
										<TextField
											InputProps={{
												endAdornment: editableIndex === 'accountName' && (
													<IconButton
														id="submitButton_accountName"
														sx={{
															paddingRight: 0,
														}}
														type="submit"
														value="commonSubmit"
													>
														<CheckCircleIcon
															sx={{
																pointerEvents: 'none',
																color: '#0047ff',
																fontSize: '22px',
																marginLeft: 'auto',
															}}
														/>
													</IconButton>
												),
											}}
											autoFocus={editableIndex === 'accountName'}
											defaultValue={data.AccountName}
											error={!!errMsgs.accountName}
											helperText={errMsgs.accountName}
											name="accountName"
											placeholder="Enter a name for this account"
											sx={{
												width: '375px',
												'& input': {
													marginRight: '0px',
												},
											}}
											variant="standard"
										/>
										{editableIndex === 'accountName' && (
											<IconButton
												id="closeEditButton_accountName"
												sx={{
													padding: '10px 2px 5px 2px',
													marginLeft: '2px',
												}}
												type="button"
											>
												<CloseIcon
													id="closeEditButton_accountName"
													sx={{
														color: '#0047ff',
													}}
													onClick={e => {
														setErrMsgs({});
														setEditableIndex(null);
													}}
												/>
											</IconButton>
										)}
										<span style={{ color: 'red' }}>&nbsp;*</span>
									</div>
								) : (
									<div className="row">
										<span
											style={{
												maxWidth: '80%',
												cursor: loggedInUser.permission?.Account.Edit ? 'pointer' : '',
											}}
											onClick={() => {
												if (loggedInUser.permission?.Account.Edit) {
													setEditableIndex('accountName');
												}
											}}
											onMouseEnter={() => setHoveredField('accountName')}
										>
											{data.AccountName || '-'}
										</span>
										{hoveredField === 'accountName' && !!loggedInUser.permission.Account.Edit && (
											<IconButton
												id={'closeEditButton_Edit_' + 'accountName'}
												sx={{ p: 0, marginLeft: '5px' }}
												type="button"
												onClick={e => {
													setEditableIndex('accountName');
												}}
											>
												<EditOutlinedIcon
													sx={{
														color: 'rgba(0, 0, 0, 0.6)',
														fontSize: '24px',
														marginLeft: 'auto',
													}}
												/>
											</IconButton>
										)}
										<span style={{ color: 'red' }}>&nbsp;*</span>
									</div>
								)}
							</Title>
							<InfoRow>
								{accountForm.commonTextFields.map((item, i) => (
									<Fragment key={i}>
										{editableIndex === item.fieldName ? (
											<div className="col">
												<label>
													{item.labelName}
													{item.fieldName === 'clientId' ? (
														<span style={{ color: 'red' }}>&nbsp;*</span>
													) : (
														''
													)}
												</label>
												<Box
													sx={{
														display: 'flex',
														flexDirection: 'row',
														marginTop: '2px',
														fontSize: '14px',
													}}
												>
													{' '}
													{item.fieldName === 'targetEntity' ? (
														<>
															<Select
																defaultValue={data[item.valueName]}
																id={item.fieldName}
																name={item.fieldName}
																style={{
																	border: 'none',
																	fontFamily: 'Inter',
																}}
																sx={{
																	'& legend': {
																		display: 'none',
																	},
																	'& fieldset': {
																		top: 0,
																	},
																	height: '40px',
																	width: '156px',
																	fontSize: '12px',
																	fontStretch: 'normal',
																	fontStyle: 'normal',
																	flex: '1',
																	borderRadius: 3,
																}}
															>
																{entities.map(selectItem => (
																	<MenuItem
																		key={selectItem.value}
																		value={selectItem.value}
																	>
																		{selectItem.name}
																	</MenuItem>
																))}
															</Select>
															<IconButton
																data-testid={`submitButton_${item.fieldName}`}
																id={`submitButton_${item.fieldName}`}
																sx={{
																	paddingRight: 0,
																}}
																type="submit"
																value="commonSubmit"
															>
																<CheckCircleIcon
																	sx={{
																		pointerEvents: 'none',
																		color: '#0047ff',
																		fontSize: '22px',
																		marginLeft: 'auto',
																	}}
																/>
															</IconButton>
														</>
													) : (
														<TextField
															InputProps={{
																endAdornment: (
																	<IconButton
																		data-testid={`submitButton_${item.fieldName}`}
																		id={`submitButton_${item.fieldName}`}
																		sx={{
																			paddingRight: 0,
																		}}
																		type="submit"
																		value="commonSubmit"
																	>
																		<CheckCircleIcon
																			sx={{
																				pointerEvents: 'none',
																				color: '#0047ff',
																				fontSize: '22px',
																				marginLeft: 'auto',
																			}}
																		/>
																	</IconButton>
																),
															}}
															autoFocus={editableIndex === item.fieldName}
															data-testid={item.fieldName}
															defaultValue={data[item.valueName]}
															error={!!errMsgs[item.fieldName]}
															helperText={errMsgs[item.fieldName]}
															name={item.fieldName}
															size="small"
															sx={{
																'.MuiFormHelperText-root': {
																	fontSize: '10px',
																},
																'.MuiOutlinedInput-root': {
																	paddingRight: '5px',
																	height: '35px',
																	borderRadius: 2,
																},
																height: '30px',
																'& legend': {
																	display: 'none',
																},
																'& fieldset': {
																	top: 0,
																},
																flex: '1',
																width:
																	item.fieldName === 'clientId' ? '190px' : '120px',
															}}
															variant="outlined"
															onKeyPress={e => {
																if (
																	item.fieldName === 'phone' &&
																	!numericRegex.test(e.key)
																) {
																	e.preventDefault();
																}
															}}
														/>
													)}
													<IconButton
														id={`closeEditButton_${item.fieldName}`}
														sx={{
															padding: '5px 1px 1px 1px',
															marginLeft: '1px',
														}}
														type="button"
													>
														<CloseIcon
															id={`closeEditButton_${item.fieldName}`}
															sx={{
																color: '#0047ff',
															}}
															onClick={e => {
																setErrMsgs({});
																setEditableIndex(null);
															}}
														/>
													</IconButton>
												</Box>
											</div>
										) : (
											<div className="col">
												<label>
													{item.labelName}
													{item.fieldName === 'clientId' ? (
														<span style={{ color: 'red' }}>&nbsp;*</span>
													) : (
														''
													)}
												</label>
												<Box>
													<span
														style={{
															display: 'inline-block',
															maxWidth: item.fieldName === 'clientId' ? '300px' : '125px',
															wordWrap: 'break-word',
															cursor:
																!!loggedInUser.permission?.Account.Edit &&
																!(
																	item.fieldName === 'clientId' &&
																	!!data?.Organization?.id
																)
																	? 'pointer'
																	: '',
														}}
														onClick={() => {
															if (
																!!loggedInUser.permission?.Account.Edit &&
																!(
																	item.fieldName === 'clientId' &&
																	!!data?.Organization?.id
																)
															) {
																setEditableIndex(item.fieldName);
															}
														}}
														onMouseEnter={() => setHoveredField(item.fieldName)}
													>
														{displayValue(item)}
													</span>
													{hoveredField === item.fieldName &&
														!!loggedInUser.permission.Account.Edit &&
														!(
															item.fieldName === 'clientId' && !!data?.Organization?.id
														) && (
															<IconButton
																id={`closeEditButton_Edit_${item.fieldName}`}
																sx={{ p: 0, marginLeft: '5px' }}
																type="button"
																onClick={e => {
																	setEditableIndex(item.fieldName);
																}}
															>
																<EditOutlinedIcon
																	sx={{
																		color: 'rgba(0, 0, 0, 0.6)',
																		fontSize: '16px',
																		marginLeft: 'auto',
																	}}
																/>
															</IconButton>
														)}
												</Box>
											</div>
										)}
									</Fragment>
								))}
							</InfoRow>
						</div>
						<div className="body">
							<Box sx={{ margin: '0px 36px' }}>
								<DetailTabs
									scrollButtons={false}
									value={tabIndex}
									variant="scrollable"
									onChange={handleTabChange}
								>
									{_.map(tabs, (tab, i) => {
										let isDisable = false;
										if (tab == 'Users') {
											isDisable = masterOrganization?.id == null;
										}
										return <DetailTab key={i} disabled={isDisable} label={tab} />;
									})}
								</DetailTabs>
							</Box>
							<Box style={{ flex: 1, paddingBottom: 20 }}>
								{tabs[tabIndex].toLowerCase().includes('overview') && (
									<Overview
										data={data}
										editableIndex={editableIndex}
										errorMsgs={errMsgs}
										hoveredField={hoveredField}
										isValidForm={isValidForm}
										setEditableIndex={setEditableIndex}
										setHoveredField={setHoveredField}
										onChangeData={onChangeData}
									/>
								)}
								{tabs[tabIndex].toLowerCase().includes('organization') && (
									<Organization accountData={data} fetchAccountData={fetchData} />
								)}
								{tabs[tabIndex].toLowerCase().includes('cases') && <Cases accountData={data} />}
								{tabs[tabIndex].toLowerCase().includes('users') && (
									<Users accountData={data} masterOrganization={masterOrganization} />
								)}
							</Box>
						</div>
					</Wrapper>
				) : (
					<Box
						sx={{
							height: '100%',
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							paddingBottom: '100px',
							boxSizing: 'border-box',
						}}
					>
						<Typography
							sx={{
								textAlign: 'center',
								fontSie: '16px',
								marginBottom: '8px',
								color: 'black',
							}}
						>
							<CircularProgress
								className="progressBar"
								size={22}
								sx={{
									color: '#03dac5',
									marginRight: '16px',
								}}
							/>
							Loading...
						</Typography>
					</Box>
				)}
			</Wrapper>
		</form>
	);
};

export default AccountDetailPopOver;
