import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const ActiveIndicator = ({ isActive }) => (
	<Box
		sx={{
			display: 'inline-block',
			width: '12px',
			height: '12px',
			borderRadius: '50%',
			backgroundColor: isActive ? '#4EE567' : '#FFFFFF80',
		}}
	/>
);

ActiveIndicator.defaultProps = {
	isActive: true,
};

ActiveIndicator.propTypes = {
	/**
	 * Indicate an item is active or not.
	 */
	isActive: PropTypes.bool,
};

export default ActiveIndicator;
