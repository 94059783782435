// Core

import React from 'react';

// MUI

import { Box } from '@mui/material';

// Component

const FormToolGrid = ({ columns = 2, isCompact, ...props }) => (
	<Box
		sx={{
			display: 'grid',
			gap: isCompact ? '5px' : 'var(--element-spacing)',
			gridTemplateColumns: `repeat(${columns}, 1fr)`,
			...props?.sx,
		}}
		{...props}
	/>
);

export default FormToolGrid;
