import React, { createContext, useState, useContext } from 'react';

export const UpdatePatientContext = createContext();

export const UpdatePatientContextProvider = ({ children }) => {
	const [shouldUpdate, setShouldUpdate] = useState(false);

	const triggerUpdate = () => {
		setShouldUpdate(!shouldUpdate);
	};

	return (
		<UpdatePatientContext.Provider value={{ shouldUpdate, triggerUpdate }}>
			{children}
		</UpdatePatientContext.Provider>
	);
};

export const useUpdatePatient = () => {
	const updatePatientContext = useContext(UpdatePatientContext);
	const { triggerUpdate, shouldUpdate } = updatePatientContext;

	return {
		triggerUpdate,
		shouldUpdate,
	};
};

export default UpdatePatientContextProvider;
