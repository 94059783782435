/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
import patientActivitySchema from './schemata/patientActivitySchema';
import deviceSchema from './schemata/deviceSchema';
/************************************************************************************************************************
Resource: DeviceResource
Reference: N/A
Proprietary RamSoft resource
 ************************************************************************************************************************/

export default class DeviceResource extends FhirDomainResource {
	identifier;

	constructor(resourceString) {
		super(resourceString, deviceSchema);
		this.resourceType = 'Device';
		this.populateFields();
	}
}
