import React, { useState, useCallback } from 'react';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import EditIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Check from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import SaveIcon from '@mui/icons-material/Save';
import Input from '@mui/material/Input';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

const MenuItems = ({
	optionList = [],
	selectedItemId,
	onDelete,
	onUpdate,
	onDefault,
	isDefault,
	t,
	onEdit,
	onSelect,
	onAdd,
}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [error, updateError] = useState(false);
	const [data, setData] = useState([]);
	const open = Boolean(anchorEl);
	let textInputRef = null;
	const setTextInputRef = element => {
		textInputRef = element;
	};
	const ITEM_HEIGHT = 50;
	const handleMenuClick = (event, value) => {
		event.stopPropagation();
		event.preventDefault();
		setAnchorEl(event.currentTarget);
		setData(value);
	};

	const handleClose = e => {
		setAnchorEl(null);
	};

	const editorOnClick = event => {
		event.stopPropagation();
	};

	const handleSaveClick = (event, option) => {
		if (textInputRef && textInputRef.firstChild.value !== '') {
			onUpdate(event, option.value, textInputRef.firstChild.value);
		} else {
			updateError(true);
		}

		event.stopPropagation();
	};
	const handleKeydDown = event => {
		event.stopPropagation();
		updateError(false);
	};

	const handleDelete = id => {
		setAnchorEl(null);
		onDelete(id);
	};

	const handleSetDefault = option => {
		setAnchorEl(null);
		onDefault(option.value, option.name);
	};
	const handleIsDefault = useCallback(defultValue =>
		isDefault && defultValue.value === isDefault ? (
			<Chip label={t('workListDrawer.default')} sx={{ backgroundColor: '#42A5F6' }} />
		) : (
			''
		)
	);

	const Editor = ({ option }) => {
		const ariaLabel = { 'aria-label': 'description' };
		return (
			<ListItem
				secondaryAction={
					<IconButton
						data-cy={`save_${option.name}`}
						edge="end"
						label={t('save')}
						onClick={event => handleSaveClick(event, option)}
					>
						<SaveIcon sx={{ fontSize: 'small' }} />
					</IconButton>
				}
			>
				<ListItemIcon>
					{option?.id === selectedItemId ? <Check sx={{ visibility: 'hidden' }} /> : ''}
				</ListItemIcon>
				<Input
					ref={setTextInputRef}
					autoFocus
					fullWidth
					data-cy={`input_${option.name}`}
					error={error}
					placeholder={option.name}
					type="text"
					onClick={event => editorOnClick(event)}
					onKeyDown={handleKeydDown}
				/>
			</ListItem>
		);
	};
	return (
		<List dense disablePadding aria-labelledby="nested-list-subheader" component="nav" sx={{ width: '100%' }}>
			{optionList.map((option, index) => (
				<MenuItem key={option?.id || index} sx={{ p: '0px' }}>
					{option.edit ? (
						<Editor option={option} />
					) : (
						<ListItem
							key={option?.id || index}
							sx={{
								'&:hover': {
									'.editIcon': {
										visibility: 'visible',
									},
									'.moreIcon': {
										visibility: 'visible',
									},
									'.defaultText': {
										visibility: 'hidden',
										display: 'none',
									},
								},
							}}
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
								onSelect(option);
							}}
						>
							<ListItemIcon>
								{option?.id === selectedItemId ? <Check sx={{ color: '#42A5F5' }} /> : ''}
							</ListItemIcon>
							<ListItemText
								data-cy={`${option.name}_SL`}
								primary={option.name}
								sx={{
									color: 'white',
									'& .MuiListItemText-primary': {
										textOverflow: 'ellipsis',
										overflow: 'hidden',
										width: '200px',
									},
								}}
							/>

							<IconButton
								aria-label="more"
								id="Edit"
								onClick={event => {
									event.stopPropagation();
									event.preventDefault();
									onEdit(event, option);
								}}
							>
								<EditIcon
									className="editIcon"
									data-cy={`edit_${option.name}`}
									sx={{ fontSize: 20, visibility: 'hidden' }}
								/>
							</IconButton>
							{option.value !== isDefault ? (
								<IconButton
									aria-controls={open ? option.value : undefined}
									aria-expanded={open ? 'true' : undefined}
									aria-haspopup="true"
									aria-label="more"
									id="more-button"
									onClick={event => {
										handleMenuClick(event, option);
									}}
								>
									<MoreVertIcon
										key={option.value}
										className="moreIcon"
										data-cy={`moreWLOptions_${option.name}`}
										sx={{
											fontSize: 20,
											visibility: 'hidden',
										}}
									/>
								</IconButton>
							) : (
								''
							)}

							<Menu
								key={data.value}
								MenuListProps={{
									'aria-labelledby': 'more-button',
								}}
								PaperProps={{
									style: {
										maxHeight: ITEM_HEIGHT * 3.5,
										width: '18ch',
									},
								}}
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								id={data.value}
								open={open}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								onClose={handleClose}
							>
								{onDefault ? (
									<MenuItem
										key={`${data.value}_default`}
										data-cy={`${data.name}_SetDefault`}
										sx={{ fontSize: 'small' }}
										onClick={e => {
											e.stopPropagation();
											e.preventDefault();
											handleSetDefault(data);
										}}
									>
										{t('workListDrawer.setDefault')}
									</MenuItem>
								) : null}
								{onDefault ? <Divider /> : null}
								<MenuItem
									key={`${data.value}_delete`}
									data-cy={`delete_${data.name}`}
									sx={{ fontSize: 'small' }}
									onClick={e => {
										e.stopPropagation();
										e.preventDefault();
										handleDelete(data.value);
									}}
								>
									{t('workListDrawer.delete')}
								</MenuItem>
							</Menu>

							<Stack
								className="defaultText"
								data-cy={option.isDefault && `Default_${option.name}`}
								sx={{
									color: 'white',
									visibility: 'visible',
								}}
							>
								{handleIsDefault(option)}
							</Stack>
						</ListItem>
					)}
				</MenuItem>
			))}
		</List>
	);
};

MenuItems.propTypes = {
	/**
	 * each element Name
	 */
	optionList: PropTypes.array,
	/**
	 * selected element id
	 */
	selectedItemId: PropTypes.string,
	/**
	 * Callback handler for when an item is deleted
	 */
	onDelete: PropTypes.func,
	/**
	 * Callback handler for when an item is updated
	 */
	onUpdate: PropTypes.func,
	/**
	 * Callback handler for when an item is set as default
	 */
	onDefault: PropTypes.func,
	/**
	 * Flag to tell whether or not the item is a default
	 */
	isDefault: PropTypes.string,
	/**
	 * Callback handler for when EDIT Button click
	 */
	onEdit: PropTypes.func,
	/**
	 * Callback handler for when item is select from menu
	 */
	onSelect: PropTypes.func,
};

export default React.memo(MenuItems);
