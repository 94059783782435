// core
import React, { useContext, useEffect, useState } from 'react';

// libraries
import { Controller } from 'react-hook-form';

// contexts
import FormContext from '../../../formContexts/FormContext';

// components
import Modifier from '../../../../Modifier';

const FormModifierVariant = ({ formHook, name, modifySelectedValue, TextFieldProps }) => {
	const { control } = formHook;
	const { readOnly } = useContext(FormContext);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field }) => {
				const [oldValue, setOldValue] = useState(null);

				useEffect(() => {
					setOldValue(field.value);
				}, [field.value]);

				return (
					<Modifier
						Modifiers={field.value}
						data-testid={`modifier-field-${TextFieldProps?.label}`}
						id={`modifier-field-${TextFieldProps?.label}`}
						readOnly={readOnly ?? false}
						onChange={(newValue, reason) => {
							let modifiedValue = null;
							if (modifySelectedValue) {
								modifiedValue = modifySelectedValue(newValue, reason, oldValue);
							}

							const value = modifiedValue || newValue;

							field.onChange(value);
						}}
					/>
				);
			}}
		/>
	);
};

export default FormModifierVariant;
