//

// #TODO: deprecate this and replace all usages with global `useValueSets()` hook

//

import _ from 'lodash';
/**
 * Load the value set of a given category from the FHIR API
 * @param  {FhirDataLoader} fhirDataLoader - An instance of the FhirDataLoader.
 * @param  {String} valueSetType - The valueSet category.
 * @param  {String} displayValue - Designates which field will be used as the display value.
 * @param  {Boolean} capitalizeOptions - Designates whether the display values should be capitalized or not.
 * @return {Array}
 */
const loadValueSet = async (
	fhirDataLoader,
	valueSetType,
	displayValue = 'display',
	capitalizeOptions,
	sort = ['display'],
	extraParams = {}
) => {
	let output = [];

	await fhirDataLoader
		.load({
			value: [
				{
					label: 'name',
					values: [valueSetType],
				},
			],
			sort: sort,
			count: 1000, // temporary implementation - will be removed once valueSets are loaded as meta-search
			...extraParams,
		})
		.then(loadedData => {
			if (!!loadedData && !_.isEmpty(loadedData)) {
				output = loadedData.map(elem => {
					if (Array.isArray(displayValue)) {
						const valueObject = {};

						displayValue.forEach(dv => {
							valueObject[dv] = capitalizeOptions ? elem[dv].toUpperCase() : elem[dv];
						});

						return valueObject;
					} else {
						return {
							id: elem.id,
							[displayValue]: capitalizeOptions ? elem[displayValue].toUpperCase() : elem[displayValue],
						};
					}
				});
			}
		})
		.catch(console.error);

	return output;
};

export default loadValueSet;
