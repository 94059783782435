import React from 'react';

export const useClickOutside = (ref, callback) => {
	const handleClick = e => {
		if (ref.current && !ref.current.contains(e.target) && e.target.id !== 'organization-card') {
			callback();
		}
	};
	React.useEffect(() => {
		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
	});
};
