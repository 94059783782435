// React Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Cube Libraries
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';

// Custom Chart Libraries
import Charts from './Chart/Charts';
import { useConfig } from '@worklist-2/core/src';

const renderChart = ({ chartType, resultSet, error, pivotConfig, library, columnOrder }) => {
	if (error) {
		return (
			<div
				style={{
					color: '#000',
					position: 'absolute',
					left: '50%',
					top: '50%',
					transform: 'translate(-50%, -50%)',
					fontFamily: 'Roboto',
				}}
			>
				Oops! Something went wrong.
			</div>
		);
	}

	if (!resultSet) {
		return (
			<div
				style={{
					color: '#000',
					position: 'absolute',
					left: '50%',
					top: '50%',
					transform: 'translate(-50%, -50%)',
					fontFamily: 'Roboto',
				}}
			>
				loading...
			</div>
		);
	}

	return (
		<>
			{library === 'ChartsJS' && (
				<Charts
					chartType={chartType}
					columnOrder={columnOrder}
					pivotConfig={pivotConfig}
					resultSet={resultSet}
				/>
			)}
		</>
	);
};
function ChartRender({ chartType, library, query, cubeToken, columnOrder }) {
	const __config = useConfig();
	const cubejsApi = cubejs(cubeToken, {
		apiUrl: __config.crm?.cube,
	});

	return (
		<QueryRenderer
			resetResultSetOnChange
			cubejsApi={cubejsApi}
			query={query}
			render={props =>
				renderChart({
					...props,
					chartType,
					library,
					columnOrder,
				})
			}
		/>
	);
}

ChartRender.propTypes = {
	/**
	 * Chart type
	 * @type {string}
	 */
	chartType: PropTypes.string,
	/**
	 * Chart library
	 * @type {string}
	 */
	library: PropTypes.string,
	/**
	 * Query json used for Cube
	 * @type {object}
	 */
	query: PropTypes.object,
	/**
	 * API token used for Cube
	 * @type {string}
	 */
	cubeToken: PropTypes.string,
	/**
	 * The column order of Visual Table
	 * @type {array}
	 */
	columnOrder: PropTypes.array,
};

export default ChartRender;
