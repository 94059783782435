// @flow

// core
import React, { type Node } from 'react';
// components
import Grid from '@mui/material/Grid';
import { LoaderSkeleton, type ISkeletonProps } from './LoaderSkeleton';

export const LoaderConsultingPhysician = ({ children, isLoading }: ISkeletonProps): Node =>
	isLoading ? (
		<Grid container spacing="20px">
			{[1, 2, 3, 4].map(i => (
				<Grid key={`consulting_physician_skeleton_${i}`} item sx={{ minWidth: '325px' }} xs={3}>
					<LoaderSkeleton sx={{ height: '130px' }} />
				</Grid>
			))}
		</Grid>
	) : (
		children
	);
