const circleColors = [
	'#000000',
	'#ffffff',
	'#FF0000',
	'#FF9900',
	'#FFFF2C',
	'#00FF00',
	'#00FFFF',
	'#9900FF',
	'#FF00FF',
	'#DE03DE',
	'#034DDE',
	'#03C4DE',
	'#03DE40',
	'#A5DE03',
	'#DED503',
	'#DE8603',
	'#DE0303',
	'#DE0386',
];

export default circleColors;
