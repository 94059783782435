import React, { forwardRef, useState, useImperativeHandle, useMemo, useEffect } from 'react';
import moment from 'moment';
import FormSection from '../FormSection';
import FormHeader from '../FormHeader';
import Box from '@mui/material/Box';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { UndoDialog } from '../../SideBar/PatientSideBar';
import { Svg } from '../../Svg';
import _ from 'lodash';

const FormContainer = forwardRef(
	(
		{
			className,
			buttons,
			disabled,
			formHeaderText,
			formModel,
			headerIconType,
			iconColor,
			sections,
			subHeaderText,
			variant,
			showActiveButton,
			disableActiveButton,
			hideHeader,
			disablePadding,
			undoModelUpdate,
			onFormChange,
			isEditing,
			setEditing,
			handleUpdateSignature,
			signatureData,
			needOverFlow,
			sectionMaxHeight,
			loggedInUser,
			showStatus = false,
			statusType = '',
			submitCount = 0,
		},
		ref
	) => {
		const defaultClassName = 'form';
		// Need to deep copy formModel
		const [model, setModel] = useState(_.cloneDeep(formModel));
		const [tempModel, setTempModel] = useState(_.cloneDeep(formModel));
		const [showUndo, setShowUndo] = useState(false);
		const changes = useMemo(() => {
			let count = 0;
			_.forEach(tempModel, key => {
				if (tempModel[key] !== formModel[key]) {
					count++;
				}
			});
			return count;
		}, [tempModel, formModel]);

		useImperativeHandle(
			ref,
			() => ({
				model,
				setModel,
				cancelEditing: callback => {
					setModel(_.cloneDeep(formModel));
					setShowUndo(changes > 0);
					if (callback) {
						callback(model);
					}
					setTimeout(() => setShowUndo(false), 10000);
				},
			}),
			[model]
		);

		// sections: an array of section objects
		//		each section object has two properties: header, formFields

		const updateModel = (event, value, targetMapping, remove, customModelUpdateFn) => {
			const oldModel = _.cloneDeep(model);
			const updatedModel = [_.cloneDeep(model)];
			if (targetMapping === 'note') {
				const date = moment(new Date()).toISOString();
				const valueIndex = event?.target?.id?.split('_');
				const newNote = {
					authorReference: {
						reference: `practitioner/${loggedInUser.id}`,
						display: loggedInUser.fullName,
					},
					authorString: loggedInUser.fullName,
					time: date,
					text: value,
				};

				if (!remove) {
					// Checking to see if mapping exists then update else assign
					if (
						updatedModel[0][targetMapping]?.[valueIndex[1]] &&
						updatedModel[0][targetMapping]?.[valueIndex[1]].time
					) {
						updatedModel[0][targetMapping][valueIndex[1]].text = value;
					} else {
						updatedModel[0][targetMapping][valueIndex[1]] = newNote;
					}
				} else {
					updatedModel[0][targetMapping].splice(event, 1);
				}

				// Not saving notes with no text
				updatedModel?.[0]?.[targetMapping]?.[valueIndex?.[1]]?.text === '' &&
					updatedModel[0][targetMapping].splice(valueIndex?.[1], 1);
			} else if (targetMapping == 'active') updatedModel[0].active = value;
			else {
				const path = targetMapping.split('.');

				for (let i = 0; i < path.length; i++) {
					updatedModel.push(path[i].split('.').reduce((p, c) => (p && p[c]) || [], updatedModel[i]));
				}

				if (remove) {
					delete updatedModel[updatedModel.length - 1];
				} else {
					updatedModel[updatedModel.length - 1] = value || event?.target.value;
				}

				for (let i = updatedModel.length - 1; i > 0; i--) {
					updatedModel[i - 1][path[i - 1]] = updatedModel[i];

					if (Array.isArray(updatedModel[i - 1][path[i - 1]])) {
						updatedModel[i - 1][path[i - 1]] = updatedModel[i - 1][path[i - 1]].filter(
							item => item !== undefined
						);
					}
				}

				if (customModelUpdateFn) {
					updatedModel[0] = customModelUpdateFn(updatedModel[0], value, oldModel);
				}
			}

			setTempModel(updatedModel[0]);
			setModel(updatedModel[0]);

			if (onFormChange) {
				onFormChange(updatedModel[0]);
			}
		};

		const getModel = () => model;

		const undo = () => {
			setModel(tempModel);
			setShowUndo(false);
			if (undoModelUpdate) {
				undoModelUpdate();
			}
		};

		useEffect(() => {
			setModel(_.cloneDeep(formModel));
			setTempModel(_.cloneDeep(formModel));
		}, [formModel]);

		let index = 0;
		const sectionObjects = sections
			.filter(Boolean)
			.filter(section => !(section.shouldHide && section.shouldHide(model)))
			.map(section => {
				const sectionObject = (
					<FormSection
						key={`${section.header}-section`}
						accordionStyle={section.accordionStyle}
						className={classnames(className, defaultClassName)}
						disableAccordion={section.disableAccordion}
						disabled={disabled || section.disabled}
						formFields={section.formFields}
						getModel={getModel}
						handleUpdateSignature={handleUpdateSignature}
						isEditing={isEditing}
						isExpanded={section.isExpanded}
						loggedInUser={loggedInUser}
						preRender={section.preRender}
						sectionHeader={section.header}
						sectionIndex={index}
						setEditing={setEditing}
						signatureData={signatureData}
						submitCount={submitCount}
						variant={section.disabled ? 'standard' : variant}
						onAddMore={section.onAddMore}
						onChange={updateModel}
						onRemove={section.onRemove}
					/>
				);
				index++;

				return sectionObject;
			});

		return (
			<Box
				noValidate
				autoComplete="off"
				className={classnames(className, defaultClassName, 'container')}
				component="form"
				sx={{
					backgroundColor: 'rsSecondary.medium',
					color: 'primary.contrastText',
					fontFamily: 'fontFamily',
					borderRadius: 2,
					pb: 2,
					'& .MuiTextField-root': { mb: 1 },
					'& .section': { px: disablePadding ? 0 : 8 },
					'& .section-header': {
						pt: 2,
						pb: 0,
						fontSize: 20,
						fontWeight: 500,
					},
					'& .section-divider': {
						borderBottom: 1,
						borderColor: 'primary.light',
						pt: 1,
					},
				}}
			>
				<Grid container spacing={0}>
					{/* undo change dialog */}
					{showUndo && (
						<UndoDialog>
							<Svg name="warning" style={{ width: 40, height: 40 }} viewBox="-10 -10 40 40" />
							<span>Edits have been cancelled</span>
							<a href="#" onClick={undo}>
								Undo ({changes})
							</a>
						</UndoDialog>
					)}
					{!hideHeader && (
						<>
							<FormHeader
								buttons={buttons}
								className={className}
								disableActiveButton={disableActiveButton}
								formHeaderText={formHeaderText}
								getModel={getModel}
								headerIconType={headerIconType}
								iconColor={iconColor}
								showActiveButton={showActiveButton}
								showStatus={showStatus}
								statusType={statusType}
								subHeaderText={subHeaderText}
								onActiveSwitchClick={updateModel}
							/>
							<Grid item xs={12}>
								<Box
									component="div"
									sx={{
										borderBottom: 1,
										borderColor: 'primary.light',
									}}
								/>
							</Grid>
						</>
					)}

					<Grid
						item
						style={{
							maxHeight: sectionMaxHeight,
							overflow: needOverFlow ? 'auto' : '',
						}}
						sx={{
							paddingTop: '6px',
							opacity: disabled ? '0.5' : undefined,
						}}
						xs={12}
					>
						{sectionObjects}
					</Grid>
				</Grid>
			</Box>
		);
	}
);

FormContainer.propTypes = {
	/**
	 * Array of buttons to add to header. Each button is a JS object with three properties: "type", "onClickHandler", "dividerRight"
	 */
	buttons: PropTypes.array,

	/**
	 * Set disabled prop for all text fields
	 */
	disabled: PropTypes.bool,

	/**
	 * Main header text
	 */
	formHeaderText: PropTypes.string,

	/**
	 * Object containing data for any pre-populated fields
	 */
	formModel: PropTypes.object,

	/**
	 * Type of icon to be used in the header: "account", "order", "study", "organization", "patient", "visit"
	 */
	headerIconType: PropTypes.oneOf([
		'account',
		'organization',
		'order',
		'study',
		'patient',
		'visit',
		'user',
		'insuranceInfo',
	]),

	/**
	 * MUI color to apply to header icon: "primary", "secondary", "success", "action", "disabled"
	 */
	iconColor: PropTypes.oneOf(['primary', 'secondary', 'success', 'action', 'disabled']),

	/**
	 * Array of sections in the Form. Each section is a JS Object with properties: "header", "formFields"
	 */
	sections: PropTypes.array.isRequired,

	/**
	 * Sub-header text
	 */
	subHeaderText: PropTypes.string,

	/**
	 * Show/hide fields/buttons based on page mode
	 */
	isEditing: PropTypes.bool,

	/**
	 * control section overflow based on page mode
	 */
	needOverFlow: PropTypes.bool,

	/**
	 * control section grid max height based on page mode
	 */
	sectionMaxHeight: PropTypes.string,
};

FormContainer.defaultProps = {
	isEditing: false,
	needOverFlow: true,
	sectionMaxHeight: 'calc(100vh - 245px)',
};

export default FormContainer;
