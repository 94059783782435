/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
import assigningAuthoritySchema from './schemata/assigningAuthoritySchema';
/************************************************************************************************************************
Resource: AssigningAuthorityResource
Reference: N/A
Proprietary RamSoft resource
 ************************************************************************************************************************/

export default class AssigningAuthorityResource extends FhirDomainResource {
	identifier = [];
	prefix_patientId;
	prefix_visitNumber;
	prefix_accessionNumber;

	constructor(resourceString) {
		super(resourceString, assigningAuthoritySchema);
		this.resourceType = 'AssigningAuthority';
		this.populateFields();
	}
}
