// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aQn4QJYQ3fyCmWAQmNRg{margin:15px 70px}.e6cViKibNOOP2zTKEMU9{margin:10px 50px;padding:10px;border-radius:10px;border:1px solid #42a5f5;background:rgba(66,165,245,.05)}.aQn4QJYQ3fyCmWAQmNRg h1{font-family:Roboto,serif;font-size:34px;font-style:normal;font-weight:500;line-height:normal;white-space:pre-wrap;word-wrap:break-word}.aQn4QJYQ3fyCmWAQmNRg .vo5fgGRvp2wsktirGtXO{color:#fff}.aQn4QJYQ3fyCmWAQmNRg .RE8LaSYHKJ07e7FwAEb9{color:rgba(57,57,57,.87)}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/common/MainArea/Draft/TextBlocks/HeaderBlock/HeaderBlock.module.scss"],"names":[],"mappings":"AAAA,sBACC,gBAAA,CAEA,sBACC,gBAAA,CACA,YAAA,CAEA,kBAAA,CACA,wBAAA,CACA,+BAAA,CAGD,yBACC,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CAEA,oBAAA,CACA,oBAAA,CAGD,4CACC,UAAA,CAGD,4CACC,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerBlock": "aQn4QJYQ3fyCmWAQmNRg",
	"headerBlockSelected": "e6cViKibNOOP2zTKEMU9",
	"headerOAI": "vo5fgGRvp2wsktirGtXO",
	"headerBlume": "RE8LaSYHKJ07e7FwAEb9"
};
export default ___CSS_LOADER_EXPORT___;
