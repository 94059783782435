import ADT_A08 from './ADT_A08.json';
import BAR_P01 from './BAR_P01.json';
import MFN_M02 from './MFN_M02.json';
import ORM_O01 from './ORM_O01.json';
import ORU_R01 from './ORU_R01.json';
import SIU_S12 from './SIU_S12.json';

export const HL7MapData = {
	...ADT_A08,
	...BAR_P01,
	...MFN_M02,
	...ORM_O01,
	...ORU_R01,
	...SIU_S12,
};
