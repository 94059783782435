import React from 'react';
import IdeaProgressBar from './IdeaProgressBar';

function IdeaProgressContainer({
	objective,
	idea,
	keyResultIndex,
	ideaIndex,
	onSetObjectiveVariables,
	onSetKeyResultVariable,
	onSetIdeaVariable,
	key,
}) {
	return (
		<div>
			<div
				style={{
					height: '44px',
					paddingTop: '5px',
					boxSizing: 'border-box',
				}}
			>
				<IdeaProgressBar
					key={key}
					ideaIndex={ideaIndex}
					keyResultIndex={keyResultIndex}
					objective={objective}
					percentage={idea.Progress ? idea.Progress : 0}
					setObjective={() => {}}
					status={idea.ProgressStatus || 'Status'}
					team={idea.Team || 'Team'}
					onSetKeyResultVariable={onSetKeyResultVariable}
					onSetObjectiveVariables={onSetObjectiveVariables}
					onSetIdeaVariable={onSetIdeaVariable}
					// setObjective={(objective) => setPercentageAverage(objective.KeyResults[index].Ideas, index)}
					color="#4D79EA"
				/>
			</div>
		</div>
	);
}

export default IdeaProgressContainer;
