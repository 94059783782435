// core
import React, { useState, useCallback } from 'react';
// mui
import { styled } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// mui icons
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import PanToolIcon from '@mui/icons-material/PanTool';
// libraries
import { PropTypes } from 'prop-types';
import { List as VirtualizedList } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useTranslation } from 'react-i18next';

const Tools = ({ editor, toolsList }) => {
	const [openedAccordionIndex, setOpenedAccordionIndex] = useState('');
	const { t } = useTranslation('organization');
	const onAddCodeAtCursor = useCallback(
		code => () => {
			if (!editor) return;

			const editorDoc = editor.getDoc();

			editorDoc.replaceRange(code, editorDoc.getCursor());
			editor.focus();
		},
		[editor]
	);

	const onButtonDrag = useCallback(
		code => e => {
			e.dataTransfer.setDragImage(new Image(), 0, 0);
			e.dataTransfer.setData('text', code);
		},
		[]
	);

	const onToggleAccordion = useCallback(
		header => () => {
			setOpenedAccordionIndex(prev => (header === prev ? undefined : header));
		},
		[]
	);

	const getRowHeight =
		totalCount =>
		({ index }) =>
			index === totalCount - 1
				? 54 // last has large bottom margin
				: index === 0
				? 59 // first has large both, top & bottom margins
				: 41; // others have only small bottom margin

	const renderRow =
		data =>
		({ index, style }) => {
			const isFirst = index === 0;
			const isLast = index === data.length - 1;

			const { code, label } = data[index];

			return (
				// styles are in a wrapper element to prevent `ToolButton` re-render and therefore enhance the performance
				<div key={index} style={style}>
					<ToolButton
						isFirst={isFirst}
						isLast={isLast}
						onDoubleClick={onAddCodeAtCursor(code)}
						onDragStart={onButtonDrag(code)}
					>
						{t(label)}
					</ToolButton>
				</div>
			);
		};

	return (
		<>
			{toolsList.map((toolsGroup, i) => {
				const groupList = toolsGroup.list;
				const groupListSize = groupList.length;

				return (
					<Accordion key={toolsGroup.heading} expanded={openedAccordionIndex === i}>
						<AccordionSummary onClick={onToggleAccordion(i)}>{toolsGroup.heading}</AccordionSummary>

						<AccordionDetails>
							<Typography component="div" height="100%">
								<AutoSizer
									style={{
										height: '100%',
										width: '100%',
									}}
								>
									{({ height, width }) => (
										<VirtualizedList
											height={height}
											rowCount={groupListSize}
											rowHeight={getRowHeight(groupListSize)}
											rowRenderer={renderRow(groupList)}
											width={width}
										/>
									)}
								</AutoSizer>
							</Typography>
						</AccordionDetails>
					</Accordion>
				);
			})}
		</>
	);
};

//																			   //
// ============================ Component proptypes ========================== //
//																			   //

export const PropTypesToolsList = PropTypes.arrayOf(
	PropTypes.shape({
		heading: PropTypes.string,
		list: PropTypes.arrayOf(
			PropTypes.shape({
				label: PropTypes.string,
				code: PropTypes.string,
			})
		),
	})
);

Tools.propTypes = {
	/**
	 * CodeMirror editor instance
	 */
	editor: PropTypes.object,

	/**
	 * List of scripting tools
	 */
	toolsList: PropTypesToolsList,
};

//																			   //
// ============================ Styled components ============================ //
//																			   //

const Accordion = styled(props => <MuiAccordion disableGutters square elevation={0} {...props} />)(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	position: 'relative',
	borderRadius: '10px',
	borderColor: '#4d4d4d',
	paddingTop: '48px',
	height: '0px',
	transition: 'height 150ms cubic-bezier(0.4,0,0.2,1)',
	overflow: 'hidden',

	'& > div:nth-of-type(2)': {
		'&, .MuiCollapse-wrapper, .MuiCollapse-wrapperInner, .MuiAccordion-region, .MuiAccordionDetails-root': {
			height: '100% !important',
		},
	},

	'&.Mui-expanded': {
		height: '100%',
		transition: 'height 500ms cubic-bezier(0.4,0,0.2,1)',
	},

	'&:not(:last-child)': {
		marginBottom: 5,
	},

	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled(({ children, ...props }) => (
	<MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props}>
		<Typography
			sx={{
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
			}}
		>
			{children}
		</Typography>
	</MuiAccordionSummary>
))(({ theme }) => ({
	border: '1px solid #4d4d4d',
	borderRadius: '10px',
	height: '50px',
	position: 'absolute',
	top: -1,
	left: -1,
	width: 'calc(100% + 2px)',

	'& .MuiAccordionSummary-expandIconWrapper': {
		color: 'icon.primary',
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(-90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginRight: theme.spacing(1),
		overflow: 'hidden',
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)({
	padding: 0,
	transition: 'none',
});

const ToolButton = styled(({ children, isFirst: _, isLast: __, ...props }) => (
	<Button
		draggable
		endIcon={
			<PanToolIcon
				sx={{
					color: 'icon.primary',
					transition: 'color 250ms cubic-bezier(0.4,0,0.2,1)',
				}}
			/>
		}
		variant="outlined"
		{...props}
	>
		<span
			style={{
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
			}}
		>
			{children}
		</span>
	</Button>
))(({ isFirst, isLast, theme }) => ({
	borderColor: theme.palette.text.border,
	color: theme.palette.primary.contrastText,
	borderRadius: '10px',
	display: 'flex',
	justifyContent: 'space-between',
	textTransform: 'none',
	fontSize: '14px',
	fontWeight: '400',
	position: 'relative',
	margin: '0 18px',
	width: 'calc(100% - 36px) !important',
	height: '36px !important',

	marginTop: isFirst ? '18px' : 0,
	marginBottom: isLast ? '18px' : '5px',

	'&:hover': {
		borderColor: theme.palette.primary.contrastText,

		'*': {
			color: theme.palette.primary.contrastText,
		},
	},
}));

export default Tools;
