import React from 'react';

const StudiesIcon = () => (
	<svg fill="none" height="64" viewBox="0 0 85 64" width="85" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_45976)">
			<path
				d="M72.9129 60.1613C71.3153 61.0319 69.6243 61.7188 67.8723 62.2088C59.6123 64.5436 51.0896 63.7973 42.9143 61.6674C38.477 60.5111 33.6235 58.9359 29.0037 59.1788C25.6641 59.3526 22.4581 60.6292 19.0428 60.6136C13.4233 60.5913 9.45363 57.7173 5.56855 53.736C1.59441 49.6611 1.09347 41.772 1.87717 36.269C2.7232 30.3249 5.50176 24.7083 9.63174 20.5888C11.4819 18.7396 13.5792 17.2046 15.5607 15.5181C21.543 10.4473 26.7706 3.90832 34.0176 1.2816C41.2645 -1.34513 49.313 0.455034 56.5488 3.18425C62.1148 5.28073 67.8767 8.28621 70.5974 13.8337C72.1804 17.0598 72.5455 20.8161 73.9771 24.1223C77.5683 32.4147 84.4056 36.5208 82.4931 46.6623C82.1391 48.7184 81.4913 50.7127 80.5695 52.5841C78.864 55.8993 76.0922 58.4213 72.9129 60.1613Z"
				fill="#4BAAF6"
				opacity="0.18"
			/>
			<path
				d="M46.3335 45.9271L58.0444 51.2741C58.2933 51.3875 58.577 51.3974 58.8333 51.3018C59.0896 51.2061 59.2974 51.0127 59.4114 50.7639L62.4059 44.1982C62.4635 44.0761 62.4954 43.9433 62.4994 43.8083C62.5072 43.6038 62.4543 43.4015 62.3473 43.227C62.2403 43.0526 62.0841 42.9137 61.8983 42.828L50.1874 37.481C50.0644 37.4215 49.9309 37.3873 49.7945 37.3803C49.6581 37.3732 49.5217 37.3936 49.3933 37.4401C49.2649 37.4866 49.1471 37.5583 49.0468 37.651C48.9465 37.7438 48.8657 37.8556 48.8093 37.9801L45.8125 44.5502C45.7557 44.6735 45.7238 44.8068 45.7185 44.9425C45.7133 45.0781 45.7349 45.2135 45.7821 45.3408C45.8292 45.4681 45.901 45.5848 45.9934 45.6843C46.0857 45.7837 46.1967 45.864 46.3201 45.9204L46.3335 45.9271Z"
				fill="#FBCA1B"
			/>
			<path
				d="M46.3335 45.9271L58.0444 51.2741C58.2933 51.3875 58.577 51.3974 58.8333 51.3018C59.0896 51.2061 59.2974 51.0127 59.4114 50.7639L62.4059 44.1982C62.4635 44.0761 62.4954 43.9433 62.4994 43.8083C62.5072 43.6038 62.4543 43.4015 62.3473 43.227C62.2403 43.0526 62.0841 42.9137 61.8983 42.828L50.1874 37.481C50.0644 37.4215 49.9309 37.3873 49.7945 37.3803C49.6581 37.3732 49.5217 37.3936 49.3933 37.4401C49.2649 37.4866 49.1471 37.5583 49.0468 37.651C48.9465 37.7438 48.8657 37.8556 48.8093 37.9801L45.8125 44.5502C45.7557 44.6735 45.7238 44.8068 45.7185 44.9425C45.7133 45.0781 45.7349 45.2135 45.7821 45.3408C45.8292 45.4681 45.901 45.5848 45.9934 45.6843C46.0857 45.7837 46.1967 45.864 46.3201 45.9204L46.3335 45.9271Z"
				fill="url(#paint0_linear_2852_45976)"
			/>
			<path
				d="M45.7929 45.6812L57.5038 51.0283C57.7525 51.1418 58.036 51.152 58.2922 51.0568C58.5484 50.9616 58.7565 50.7687 58.8709 50.5203L61.8654 43.9524C61.9217 43.8306 61.9535 43.6988 61.9589 43.5647C61.9665 43.3599 61.9132 43.1575 61.8058 42.983C61.6984 42.8085 61.5417 42.6698 61.3555 42.5844L49.6469 37.2374C49.3979 37.124 49.1142 37.1141 48.858 37.2097C48.6017 37.3053 48.3938 37.4988 48.2798 37.7476L45.2831 44.3155C45.1699 44.5643 45.16 44.8479 45.2556 45.1039C45.3512 45.36 45.5445 45.5677 45.7929 45.6812Z"
				fill="#FBCA1B"
			/>
			<path
				d="M62.3576 43.2411L58.7708 33.8125L49.3086 37.4908L61.4425 44.8831L62.3576 43.2411Z"
				fill="#FBCA1B"
			/>
			<path
				d="M48.7676 37.2541L52.43 45.2746L62.362 43.245L58.7753 33.8164C58.7753 33.8164 49.0347 37.116 48.7676 37.2541Z"
				fill="url(#paint1_linear_2852_45976)"
			/>
			<path
				d="M55.8427 44.6592L56.8 50.7081L45.7949 45.6819L51.116 42.4023L52.4252 45.2719L55.8427 44.6592Z"
				fill="url(#paint2_linear_2852_45976)"
			/>
			<path
				d="M54.551 10.75H33.084C31.2076 10.75 29.6865 12.2722 29.6865 14.1498V16.1705C29.6865 18.0482 31.2076 19.5704 33.084 19.5704H54.551C56.4274 19.5704 57.9485 18.0482 57.9485 16.1705V14.1498C57.9485 12.2722 56.4274 10.75 54.551 10.75Z"
				fill="white"
			/>
			<path
				d="M59.3225 20.6055H37.8555C35.9791 20.6055 34.458 22.1276 34.458 24.0053V26.026C34.458 27.9037 35.9791 29.4258 37.8555 29.4258H59.3225C61.1989 29.4258 62.72 27.9037 62.72 26.026V24.0053C62.72 22.1276 61.1989 20.6055 59.3225 20.6055Z"
				fill="white"
			/>
			<path
				d="M53.0842 30.6914H31.6172C29.7408 30.6914 28.2197 32.2136 28.2197 34.0912V36.112C28.2197 37.9896 29.7408 39.5118 31.6172 39.5118H53.0842C54.9606 39.5118 56.4817 37.9896 56.4817 36.112V34.0912C56.4817 32.2136 54.9606 30.6914 53.0842 30.6914Z"
				fill="white"
			/>
			<path
				d="M36.4595 35.1623C36.4595 35.8232 36.2636 36.4693 35.8967 37.0189C35.5297 37.5685 35.0081 37.9968 34.3979 38.2498C33.7877 38.5027 33.1162 38.5689 32.4684 38.4399C31.8205 38.311 31.2255 37.9927 30.7584 37.5253C30.2914 37.058 29.9733 36.4625 29.8444 35.8142C29.7156 35.166 29.7817 34.494 30.0345 33.8834C30.2873 33.2727 30.7153 32.7508 31.2645 32.3836C31.8137 32.0164 32.4594 31.8204 33.1199 31.8204C33.5561 31.818 33.9885 31.9017 34.3924 32.0666C34.7964 32.2314 35.1639 32.4743 35.474 32.7813C35.7841 33.0884 36.0307 33.4535 36.1998 33.8559C36.3689 34.2583 36.4572 34.6901 36.4595 35.1266V35.1623Z"
				fill="#4BAAF6"
				opacity="0.18"
			/>
			<path
				d="M33.0286 35.8651C33.5745 35.8651 34.0171 35.4222 34.0171 34.8759C34.0171 34.3296 33.5745 33.8867 33.0286 33.8867C32.4826 33.8867 32.04 34.3296 32.04 34.8759C32.04 35.4222 32.4826 35.8651 33.0286 35.8651Z"
				fill="#4BAAF6"
				opacity="0.34"
			/>
			<path
				d="M35.1821 37.7888C34.5686 38.2706 33.8059 38.5223 33.0263 38.5004C32.2468 38.4784 31.4995 38.1841 30.9141 37.6685C31.0633 37.2118 31.3561 36.8155 31.7487 36.5389C32.1414 36.2623 32.6129 36.1202 33.093 36.1337C33.573 36.1472 34.0358 36.3157 34.4123 36.614C34.7888 36.9123 35.0588 37.3244 35.1821 37.7888Z"
				fill="#4BAAF6"
				opacity="0.34"
			/>
			<path d="M53.8457 32.5508H39.7637V33.413H53.8457V32.5508Z" fill="#4BAAF6" opacity="0.18" />
			<path d="M53.8457 34.082H39.7637V34.9442H53.8457V34.082Z" fill="#4BAAF6" opacity="0.18" />
			<path d="M53.8457 35.6133H39.7637V36.4755H53.8457V35.6133Z" fill="#4BAAF6" opacity="0.18" />
			<path d="M53.8457 37.1445H39.7637V38.0067H53.8457V37.1445Z" fill="#4BAAF6" opacity="0.18" />
			<path
				d="M35.1433 57.7214C48.0296 57.7214 58.476 56.4476 58.476 54.8763C58.476 53.305 48.0296 52.0312 35.1433 52.0312C22.257 52.0312 11.8105 53.305 11.8105 54.8763C11.8105 56.4476 22.257 57.7214 35.1433 57.7214Z"
				fill="#4BAAF6"
				opacity="0.18"
			/>
			<path
				d="M20.4432 53.965C20.245 54.1165 20.0713 54.3772 20.176 54.6066C20.2806 54.8361 20.5634 54.8673 20.8038 54.874C21.7132 54.8976 22.6223 54.8169 23.5134 54.6334C23.5399 54.631 23.5649 54.62 23.5846 54.6022C23.6025 54.5794 23.6112 54.5508 23.6091 54.522C23.6751 53.9675 23.7034 53.4093 23.6937 52.851C23.2039 52.7129 22.6362 52.88 22.1976 53.105C21.6343 53.3835 20.9441 53.5773 20.4432 53.965Z"
				fill="#4BAAF6"
			/>
			<path
				d="M29.7002 53.6289C29.8983 53.7826 30.0742 54.0433 29.9696 54.2705C29.8649 54.4977 29.58 54.5334 29.3395 54.5379C28.4298 54.5605 27.5206 54.4775 26.63 54.2905C26.6035 54.2882 26.5785 54.2772 26.5587 54.2594C26.5427 54.2358 26.5349 54.2076 26.5365 54.1792C26.4682 53.6249 26.4399 53.0665 26.4519 52.5082C26.9417 52.3679 27.5094 52.5372 27.948 52.7622C28.5113 53.0496 29.1993 53.2434 29.7002 53.6289Z"
				fill="#4BAAF6"
			/>
			<path
				d="M22.3438 37.8895V53.006C22.3438 53.006 23.1853 53.3068 23.6974 53.006L25.2871 40.2712L26.4003 52.7966C26.4003 52.7966 27.2908 53.006 27.9587 52.7052L28.6267 37.7781C28.6267 37.7781 24.6815 36.8892 22.3438 37.8895Z"
				fill="#473F47"
			/>
			<path
				d="M33.8098 29.6803C33.3846 31.3891 33.3912 32.2246 32.7434 33.8621C32.5697 34.3077 32.2981 34.7978 31.8305 34.8535C31.4743 34.8959 31.147 34.6575 30.8643 34.4347C30.088 33.8153 29.3095 33.196 28.5288 32.5766C28.45 34.4254 28.5096 36.2775 28.7069 38.1174C27.6293 38.6856 26.4938 39.2671 25.276 39.2314C24.5107 39.1772 23.7634 38.9736 23.0763 38.6321C22.9093 38.5608 22.7468 38.4873 22.5843 38.4093C22.525 38.3893 22.4731 38.3521 22.4351 38.3024C22.2125 37.9548 22.3171 37.0815 22.2993 36.6693C22.2726 36.041 22.2414 35.4105 22.2102 34.78C22.1969 34.5572 22.1857 34.35 22.1702 34.1339C22.1145 33.1113 22.0499 32.0931 21.9475 31.0705C21.9653 31.2465 21.7894 31.5629 21.7249 31.7389C21.6425 32.0152 21.5601 32.2959 21.4755 32.5744C21.3848 32.8278 21.3104 33.0867 21.2529 33.3497C21.2039 33.6683 21.3575 33.9735 21.3375 34.301C21.3361 34.4379 21.3033 34.5725 21.2415 34.6946C21.1797 34.8167 21.0907 34.9229 20.9813 35.005C20.6362 35.2278 20.1842 35.0897 19.8079 34.9293C19.5976 34.8671 19.4146 34.7351 19.2892 34.555C19.2072 34.3508 19.1954 34.125 19.2558 33.9134C19.4301 32.7281 19.7385 31.5666 20.1753 30.4512C20.5516 29.4753 20.9212 28.4104 21.9564 27.9737C22.493 27.7509 23.132 27.7353 23.7064 27.7242L25.728 27.6841C26.3693 27.6388 27.0138 27.6891 27.6404 27.8333C28.462 28.0561 28.9763 28.671 29.4416 29.3506C30.0205 30.2031 30.5637 31.0809 31.0713 31.984C31.432 30.7831 31.3942 30.4734 31.8305 29.2993C32.5022 29.3534 33.166 29.4812 33.8098 29.6803Z"
				fill="white"
			/>
			<path
				d="M25.1894 34.4553C25.0776 34.6022 24.933 34.7209 24.7671 34.8018C24.6012 34.8826 24.4187 34.9235 24.2342 34.9209C23.9362 34.9319 23.639 34.9579 23.3437 34.9989C23.2623 35.0077 23.1851 35.0393 23.121 35.0903C23.075 35.1471 23.0436 35.2145 23.0297 35.2863C22.7514 36.3268 23.0053 37.4251 23.0943 38.4968C23.1032 38.5409 23.0986 38.5868 23.081 38.6282C22.914 38.5569 22.7514 38.4834 22.5889 38.4054C22.5297 38.3854 22.4777 38.3482 22.4397 38.2985C22.2171 37.9509 22.3217 37.0776 22.3039 36.6654C22.2772 36.0371 22.246 35.4066 22.2149 34.7761C22.2015 34.5533 22.1904 34.3461 22.1748 34.13C22.226 34.1167 22.2794 34.1078 22.3307 34.0988L23.7667 33.8538C24.1207 33.7914 24.6572 33.582 25.0112 33.7156C25.3652 33.8493 25.3541 34.1902 25.1894 34.4553Z"
				fill="url(#paint3_linear_2852_45976)"
			/>
			<path
				d="M23.6465 27.7178C23.9849 27.4193 24.2966 26.869 24.2343 26.4011C24.1719 25.9333 23.8313 25.4409 23.6331 24.9975C23.533 24.7747 23.4328 24.5007 23.553 24.2824C23.6249 24.1848 23.7062 24.0945 23.7957 24.0128C24.0005 23.79 24.0562 23.4647 24.192 23.1907C24.3011 22.9776 24.4617 22.7953 24.6593 22.6603C24.8568 22.5252 25.085 22.4418 25.323 22.4176C25.5616 22.4002 25.8005 22.4473 26.0147 22.5539C26.229 22.6605 26.4107 22.8227 26.5408 23.0236C26.6609 23.2385 26.7415 23.4731 26.7791 23.7165C26.9052 24.3647 26.9135 25.0303 26.8035 25.6815C26.7723 25.9073 26.6966 26.1246 26.5809 26.3209C26.5229 26.4182 26.4426 26.5003 26.3466 26.5604C26.2505 26.6205 26.1416 26.6567 26.0288 26.6663C25.9108 26.6663 25.7749 26.6395 25.6926 26.7219C25.655 26.7625 25.6316 26.8141 25.6258 26.869C25.5949 27.0277 25.6144 27.192 25.6814 27.3391C25.76 27.525 25.8167 27.7194 25.8506 27.9183C25.8952 28.13 25.9241 28.384 25.7616 28.5243C25.6597 28.5962 25.5363 28.6308 25.412 28.6224C25.0675 28.6205 24.7283 28.5377 24.4216 28.3805C24.1149 28.2234 23.8494 27.9964 23.6465 27.7178Z"
				fill="#FCBDBA"
			/>
			<path
				d="M25.8686 31.4701L27.3915 26.5664L20.3516 28.3153L24.2055 33.6846L25.8686 31.4701Z"
				fill="url(#paint4_linear_2852_45976)"
			/>
			<path
				d="M27.2709 21.9219C27.2291 21.7697 27.1477 21.6313 27.0349 21.5209C26.8773 21.3624 26.6805 21.2485 26.4646 21.191C26.2487 21.1334 26.0214 21.1343 25.8059 21.1934C25.6011 21.2513 25.4118 21.3627 25.2092 21.4318C24.8535 21.512 24.4914 21.5611 24.1272 21.5788C23.7665 21.6301 23.3814 21.7771 23.2121 22.1002C23.126 22.3252 23.0735 22.5617 23.0563 22.802C22.9361 23.4147 22.4975 24.0229 22.7223 24.6066C22.9472 25.1903 23.7999 25.6381 24.1495 26.1283C24.2385 25.9189 24.1317 25.5 24.0404 25.2906C23.9639 25.2943 23.8882 25.273 23.8248 25.2301C23.7613 25.1871 23.7136 25.1247 23.6886 25.0522C23.6208 24.9413 23.5832 24.8146 23.5795 24.6846C23.5715 24.6199 23.579 24.5542 23.6015 24.4929C23.624 24.4317 23.6607 24.3767 23.7086 24.3326C23.77 24.3044 23.8395 24.2994 23.9042 24.3187C23.9689 24.3379 24.0244 24.3801 24.0604 24.4373C24.1287 24.5552 24.1655 24.6887 24.1673 24.8249C24.6126 24.3994 24.125 23.5372 24.5257 23.0671C24.8326 23.1788 25.1474 23.2674 25.4675 23.3322C25.8061 23.362 26.1472 23.3179 26.4672 23.203C26.9191 23.0292 27.3911 22.4255 27.2709 21.9219Z"
				fill="#FC682D"
			/>
			<path
				d="M20.1664 33.2462C19.9565 33.3333 19.7752 33.4776 19.6432 33.6628C19.5669 33.7923 19.5253 33.9393 19.5226 34.0896C19.5199 34.2399 19.5561 34.3884 19.6276 34.5205C19.7019 34.6508 19.8018 34.7647 19.9211 34.8555C20.0405 34.9462 20.1769 35.0119 20.3223 35.0486C20.7408 35.1599 21.1839 35.0486 21.6047 34.9416L23.1075 34.554C23.1478 34.5477 23.1852 34.529 23.2144 34.5005C23.2275 34.4746 23.2343 34.446 23.2343 34.4169C23.2343 34.3879 23.2275 34.3593 23.2144 34.3334C23.1186 33.968 23.0563 33.5714 22.925 33.2194C22.807 32.9075 22.6667 32.9097 22.3372 32.9008C21.6158 32.8875 20.8299 32.9209 20.1664 33.2462Z"
				fill="white"
			/>
			<path
				d="M32.6325 29.8642C32.6962 29.8683 32.7597 29.8536 32.8151 29.8218C32.8549 29.792 32.887 29.753 32.9086 29.7082C33.0087 29.5347 33.0624 29.3382 33.0645 29.1379C33.0573 29.0404 33.0671 28.9424 33.0934 28.8482C33.1237 28.7867 33.1602 28.7284 33.2025 28.6744C33.3558 28.3817 33.4097 28.047 33.3561 27.7209C33.356 27.5964 33.3318 27.4731 33.2849 27.3577C33.2399 27.271 33.1769 27.1949 33.1001 27.1345C33.0233 27.0742 32.9345 27.031 32.8396 27.0079C32.6496 26.9616 32.4539 26.9428 32.2585 26.9523C32.1324 26.9411 32.0055 26.961 31.8889 27.0102C31.7197 27.0948 31.6307 27.4758 31.5973 27.6518C31.5448 27.9417 31.6011 28.2408 31.7553 28.4917C31.806 28.5613 31.8493 28.636 31.8845 28.7145C31.978 28.9685 31.7576 29.3317 31.9201 29.5522C32.0122 29.6489 32.1227 29.7263 32.245 29.7798C32.3673 29.8334 32.499 29.8621 32.6325 29.8642Z"
				fill="#FCBDBA"
			/>
			<path
				d="M25.0446 34.0007H24.1051C24.0706 34.0022 24.0361 33.9969 24.0037 33.985C23.9712 33.9732 23.9414 33.9551 23.916 33.9317C23.8905 33.9083 23.87 33.8801 23.8554 33.8488C23.8409 33.8174 23.8327 33.7835 23.8312 33.749C23.8258 33.7195 23.8258 33.6893 23.8312 33.6599L24.2765 31.6926C24.2993 31.5981 24.3523 31.5137 24.4274 31.4521C24.5026 31.3906 24.5958 31.3553 24.6929 31.3517H25.6302C25.665 31.3505 25.6997 31.3562 25.7323 31.3684C25.7649 31.3807 25.7947 31.3992 25.8202 31.423C25.8456 31.4468 25.8661 31.4754 25.8805 31.5071C25.8949 31.5389 25.9029 31.5731 25.904 31.6079C25.9085 31.636 25.9085 31.6645 25.904 31.6926L25.4587 33.6599C25.4361 33.754 25.3834 33.8382 25.3087 33.8997C25.234 33.9612 25.1413 33.9966 25.0446 34.0007Z"
				fill="#473F47"
			/>
			<path
				d="M23.7847 32.5774C23.8136 32.5309 23.8464 32.4869 23.8827 32.4459C23.9326 32.3948 24.0005 32.3652 24.0719 32.3635C24.0795 32.3628 24.0872 32.3637 24.0944 32.366C24.1017 32.3683 24.1084 32.372 24.1142 32.3769C24.1206 32.3872 24.1251 32.3985 24.1276 32.4103C24.1387 32.4749 24.1432 32.5395 24.1588 32.6019C24.1714 32.6661 24.2041 32.7247 24.2523 32.769C24.3525 32.8514 24.5462 32.7979 24.6619 32.7846L25.1517 32.7289C25.2014 32.7157 25.2534 32.7139 25.3039 32.7235C25.3544 32.7331 25.4021 32.754 25.4434 32.7846C25.5391 32.8782 25.4968 33.0363 25.4434 33.1611C25.2942 33.5309 25.0983 33.9342 24.7243 34.0746C24.279 34.2439 23.7803 33.9698 23.3105 34.0434C23.0478 34.0835 22.7561 34.1837 22.6003 33.8919C22.5547 33.8126 22.533 33.7219 22.5377 33.6306C22.5425 33.5393 22.5735 33.4513 22.627 33.3772C22.7712 33.2499 22.9382 33.1509 23.119 33.0854C23.2707 33.0054 23.4146 32.9114 23.5487 32.8046C23.6194 32.7498 23.6857 32.6894 23.7469 32.6242L23.7847 32.5774Z"
				fill="#FCBDBA"
			/>
			<path
				d="M42.1885 25.0138C42.1837 25.8938 41.8305 26.7361 41.2061 27.3559C40.5818 27.9756 39.7373 28.3224 38.8578 28.32C37.9784 28.3176 37.1357 27.9664 36.5147 27.3433C35.8937 26.7201 35.5449 25.876 35.5449 24.9959C35.5449 24.1159 35.8937 23.2718 36.5147 22.6486C37.1357 22.0255 37.9784 21.6742 38.8578 21.6719C39.7373 21.6695 40.5818 22.0163 41.2061 22.636C41.8305 23.2558 42.1837 24.0981 42.1885 24.9781V25.0138Z"
				fill="#4BAAF6"
				opacity="0.18"
			/>
			<path
				d="M38.762 25.7167C39.3079 25.7167 39.7505 25.2738 39.7505 24.7275C39.7505 24.1812 39.3079 23.7383 38.762 23.7383C38.216 23.7383 37.7734 24.1812 37.7734 24.7275C37.7734 25.2738 38.216 25.7167 38.762 25.7167Z"
				fill="#4BAAF6"
				opacity="0.34"
			/>
			<path
				d="M40.9145 27.6404C40.301 28.1222 39.5383 28.3739 38.7588 28.3519C37.9792 28.33 37.2319 28.0357 36.6465 27.5201C36.7957 27.0633 37.0885 26.6671 37.4812 26.3905C37.8738 26.1139 38.3454 25.9717 38.8254 25.9853C39.3054 25.9988 39.7682 26.1673 40.1447 26.4656C40.5212 26.7639 40.7912 27.176 40.9145 27.6404Z"
				fill="#4BAAF6"
				opacity="0.34"
			/>
			<path d="M59.5781 22.4023H45.4961V23.2646H59.5781V22.4023Z" fill="#4BAAF6" opacity="0.18" />
			<path d="M59.5781 23.9336H45.4961V24.7958H59.5781V23.9336Z" fill="#4BAAF6" opacity="0.18" />
			<path d="M59.5781 25.4648H45.4961V26.3271H59.5781V25.4648Z" fill="#4BAAF6" opacity="0.18" />
			<path d="M59.5781 26.9961H45.4961V27.8583H59.5781V26.9961Z" fill="#4BAAF6" opacity="0.18" />
			<path
				d="M37.771 15.1622C37.771 15.8232 37.5752 16.4693 37.2082 17.0189C36.8412 17.5684 36.3197 17.9968 35.7094 18.2497C35.0992 18.5027 34.4277 18.5688 33.7799 18.4399C33.1321 18.3109 32.537 17.9927 32.0699 17.5253C31.6029 17.0579 31.2848 16.4624 31.156 15.8142C31.0271 15.1659 31.0932 14.494 31.346 13.8833C31.5988 13.2727 32.0268 12.7507 32.576 12.3835C33.1252 12.0163 33.7709 11.8203 34.4314 11.8203C35.3171 11.8203 36.1666 12.1724 36.7929 12.7991C37.4192 13.4259 37.771 14.2759 37.771 15.1622Z"
				fill="#4BAAF6"
				opacity="0.18"
			/>
			<path
				d="M34.3381 15.869C34.8841 15.869 35.3267 15.4261 35.3267 14.8798C35.3267 14.3335 34.8841 13.8906 34.3381 13.8906C33.7922 13.8906 33.3496 14.3335 33.3496 14.8798C33.3496 15.4261 33.7922 15.869 34.3381 15.869Z"
				fill="#4BAAF6"
				opacity="0.34"
			/>
			<path
				d="M36.4929 17.7938C35.879 18.2759 35.1157 18.5276 34.3356 18.5052C33.5556 18.4828 32.808 18.1878 32.2227 17.6713C32.3715 17.2137 32.6644 16.8166 33.0575 16.5394C33.4506 16.2623 33.9229 16.1199 34.4036 16.1337C34.8843 16.1475 35.3476 16.3167 35.7242 16.616C36.1009 16.9152 36.3705 17.3284 36.4929 17.7938Z"
				fill="#4BAAF6"
				opacity="0.34"
			/>
			<path d="M55.1572 12.5547H41.0752V13.4169H55.1572V12.5547Z" fill="#4BAAF6" opacity="0.18" />
			<path d="M55.1572 14.0859H41.0752V14.9481H55.1572V14.0859Z" fill="#4BAAF6" opacity="0.18" />
			<path d="M55.1572 15.6133H41.0752V16.4755H55.1572V15.6133Z" fill="#4BAAF6" opacity="0.18" />
			<path d="M55.1572 17.1445H41.0752V18.0067H55.1572V17.1445Z" fill="#4BAAF6" opacity="0.18" />
			<path
				d="M56.4396 12.1525C57.1479 12.1525 57.722 11.578 57.722 10.8692C57.722 10.1605 57.1479 9.58594 56.4396 9.58594C55.7314 9.58594 55.1572 10.1605 55.1572 10.8692C55.1572 11.578 55.7314 12.1525 56.4396 12.1525Z"
				fill="#51E08A"
			/>
			<path
				d="M13.657 29.0358L10.3887 24.1343C10.3522 24.0828 10.3262 24.0246 10.3124 23.963C10.2985 23.9014 10.2971 23.8376 10.308 23.7755C10.319 23.7133 10.3422 23.6539 10.3763 23.6008C10.4104 23.5476 10.4547 23.5018 10.5067 23.4659L13.2674 21.6368C13.3188 21.6029 13.3763 21.5795 13.4366 21.5677C13.5286 21.5494 13.6239 21.5587 13.7107 21.5944C13.7974 21.63 13.8717 21.6905 13.9242 21.7683L17.1926 26.6697C17.2307 26.7279 17.2557 26.7938 17.2657 26.8627C17.2757 26.9316 17.2705 27.0018 17.2505 27.0685C17.2194 27.1735 17.1524 27.2642 17.0612 27.3247L14.3116 29.1672C14.2081 29.2364 14.0815 29.2619 13.9593 29.2381C13.8371 29.2143 13.7293 29.1432 13.6593 29.0402L13.657 29.0358Z"
				fill="#4BAAF6"
			/>
			<path
				d="M13.657 29.0358L10.3887 24.1343C10.3522 24.0828 10.3262 24.0246 10.3124 23.963C10.2985 23.9014 10.2971 23.8376 10.308 23.7755C10.319 23.7133 10.3422 23.6539 10.3763 23.6008C10.4104 23.5476 10.4547 23.5018 10.5067 23.4659L13.2674 21.6368C13.3188 21.6029 13.3763 21.5795 13.4366 21.5677C13.5286 21.5494 13.6239 21.5587 13.7107 21.5944C13.7974 21.63 13.8717 21.6905 13.9242 21.7683L17.1926 26.6697C17.2307 26.7279 17.2557 26.7938 17.2657 26.8627C17.2757 26.9316 17.2705 27.0018 17.2505 27.0685C17.2194 27.1735 17.1524 27.2642 17.0612 27.3247L14.3116 29.1672C14.2081 29.2364 14.0815 29.2619 13.9593 29.2381C13.8371 29.2143 13.7293 29.1432 13.6593 29.0402L13.657 29.0358Z"
				fill="url(#paint5_linear_2852_45976)"
			/>
			<path
				d="M13.8057 29.2639L10.5373 24.3625C10.4678 24.2578 10.4426 24.1299 10.4672 24.0067C10.4918 23.8834 10.5643 23.775 10.6687 23.7052L13.4183 21.8828C13.4696 21.8489 13.5271 21.8254 13.5875 21.8137C13.6791 21.7956 13.7741 21.8049 13.8604 21.8406C13.9468 21.8763 14.0207 21.9367 14.0729 22.0142L17.3412 26.9157C17.4108 27.0197 17.4363 27.1471 17.4121 27.2699C17.3879 27.3927 17.316 27.5009 17.2121 27.5707L14.4602 29.4043C14.4081 29.4382 14.3498 29.4615 14.2886 29.4729C14.2274 29.4842 14.1646 29.4833 14.1038 29.4703C14.043 29.4572 13.9853 29.4323 13.9342 29.3969C13.883 29.3614 13.8393 29.3163 13.8057 29.2639Z"
				fill="#4BAAF6"
			/>
			<path
				d="M17.4015 27.3038L13.5098 26.2322L13.5877 21.7942C13.6793 21.776 13.7743 21.7854 13.8606 21.8211C13.947 21.8567 14.0209 21.9171 14.073 21.9947L17.3414 26.8961C17.3822 26.9548 17.4088 27.0222 17.4193 27.0929C17.4297 27.1637 17.4236 27.2358 17.4015 27.3038Z"
				fill="url(#paint6_linear_2852_45976)"
			/>
			<path
				d="M13.5362 24.6445L10.7354 24.6512L13.8056 29.2653L14.9032 26.6185L13.5117 26.2353L13.5362 24.6445Z"
				fill="url(#paint7_linear_2852_45976)"
			/>
		</g>
		<defs>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint0_linear_2852_45976"
				x1="52.4094"
				x2="76.4223"
				y1="43.2402"
				y2="59.2394"
			>
				<stop offset="0.01" />
				<stop offset="0.08" stopOpacity="0.69" />
				<stop offset="0.21" stopOpacity="0.32" />
				<stop offset="1" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint1_linear_2852_45976"
				x1="51.5818"
				x2="71.0734"
				y1="48.6522"
				y2="7.5795"
			>
				<stop offset="0.01" />
				<stop offset="0.08" stopOpacity="0.69" />
				<stop offset="0.21" stopOpacity="0.32" />
				<stop offset="1" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint2_linear_2852_45976"
				x1="65.2498"
				x2="29.506"
				y1="34.9764"
				y2="69.4945"
			>
				<stop offset="0.01" />
				<stop offset="0.08" stopOpacity="0.69" />
				<stop offset="0.21" stopOpacity="0.32" />
				<stop offset="1" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint3_linear_2852_45976"
				x1="17.8778"
				x2="30.1452"
				y1="32.5504"
				y2="39.7927"
			>
				<stop offset="0.01" />
				<stop offset="0.08" stopOpacity="0.69" />
				<stop offset="0.21" stopOpacity="0.32" />
				<stop offset="1" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint4_linear_2852_45976"
				x1="25.2964"
				x2="23.9655"
				y1="34.5803"
				y2="27.9325"
			>
				<stop offset="0.01" stopColor="white" />
				<stop offset="1" stopColor="white" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint5_linear_2852_45976"
				x1="15.4519"
				x2="6.43021"
				y1="26.2209"
				y2="16.5091"
			>
				<stop offset="0.01" />
				<stop offset="0.08" stopOpacity="0.69" />
				<stop offset="0.21" stopOpacity="0.32" />
				<stop offset="1" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint6_linear_2852_45976"
				x1="19.6488"
				x2="9.72254"
				y1="23.9007"
				y2="27.4499"
			>
				<stop offset="0.01" stopColor="white" />
				<stop offset="1" stopColor="white" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				gradientUnits="userSpaceOnUse"
				id="paint7_linear_2852_45976"
				x1="16.9065"
				x2="3.76817"
				y1="20.7982"
				y2="39.3856"
			>
				<stop offset="0.01" />
				<stop offset="0.08" stopOpacity="0.69" />
				<stop offset="0.21" stopOpacity="0.32" />
				<stop offset="1" stopOpacity="0" />
			</linearGradient>
			<clipPath id="clip0_2852_45976">
				<rect fill="white" height="63.6475" width="84.3896" />
			</clipPath>
		</defs>
	</svg>
);

export default StudiesIcon;
