/**
 * Convert String value to Local datetime string if the value is string Date
 * @param  {String} value - the string to be converted.
 * @return {String}
 */
const convertStringToLocalDatetime = (value) => {
	let datetimeValue = new Date(value),
		hours = datetimeValue.getHours(),
		minutes = datetimeValue.getMinutes(),
		seconds = datetimeValue.getSeconds(),
		hasTimeComponent = /:/;

	// if the value is date string, will return the local Datetime string
	if (
		isNaN(value) &&
		datetimeValue != 'Invalid Date' &&
		!isNaN(datetimeValue) &&
		hasTimeComponent.test(value)
	) {
		let dateValue = datetimeValue.toLocaleDateString(),
			// check the time value, will not include the time if have no value
			timeValue =
				hours == 0 && minutes == 0 && seconds == 0
					? ''
					: ' ' + datetimeValue.toLocaleTimeString();

		return dateValue + timeValue;
	}

	// Validate input value has only time
	// if true, will return the time string with correct format
	let timeReg = /^\d{2}:\d{2}:\d{2}$/;
	if (timeReg.test(value)) {
		let [h, m, s] = value.split(':'),
			timeValue = new Date();

		timeValue.setHours(h, m, s);

		return timeValue.toLocaleTimeString();
	}

	// if the value is a number and has decimal, will return the value with 2 decimal
	if (!isNaN(parseFloat(value)) && isFinite(value) && value % 1 !== 0) {
		return parseFloat((value)).toFixed(2)
	}

	// if the value is not date string. will return the root value
	return value;
};

/**
 * Convert String valueString to Local datetime string if the value is string Date of Charts Label
 * @param  {String} keyValue - the string to be converted.
 * @return {String}
 */
export const convertStringToLocalDatetimeForLabels = (keyValue, tableColumns, t) => {
	const multipleValue = keyValue.split(',');
	let retStr = keyValue;

	if(multipleValue && multipleValue.length > 0) {
		retStr = multipleValue.map(value => {
			let datetimeValue = new Date(value),
				retValue = value;
			// if the value is date string, will return the local Datetime string
			if (
				isNaN(value) &&
				datetimeValue != 'Invalid Date' &&
				!isNaN(datetimeValue)
			) {
				return value.replace('T00:00:00.000', '');
			} else {
				// if the value is not date string. will return the field title
				retValue = t(tableColumns?.find((col) => col.key === retValue)?.shortTitle, {ns : 'cubeJS'}) || retValue;
			}

			return retValue;
		}).join(',');
	}
	return retStr;
};

/**
 * Capital each first letter in string
 * @param {*} str 
 * @returns 
 */
const capitalLetter = (str) => {
    // Split the input string into an array of words
    str = str.split(" ");

    // Iterate through each word in the array
    for (let i = 0, x = str.length; i < x; i++) {
        // Capitalize the first letter of each word and concatenate it with the rest of the word
        str[i] = str[i][0]?.toUpperCase() + str[i].substr(1);
    }

    // Join the modified array of words back into a string
    return str.join(" ");
};

export default convertStringToLocalDatetime;
