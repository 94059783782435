import React from 'react';

const AlignCenterIcon = () => (
	<svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_5211_60481)">
			<path
				d="M0.666667 3.99983H15.3333C15.5101 3.99983 15.6797 3.9296 15.8047 3.80457C15.9298 3.67955 16 3.50998 16 3.33317C16 3.15636 15.9298 2.98679 15.8047 2.86177C15.6797 2.73674 15.5101 2.6665 15.3333 2.6665H0.666667C0.489856 2.6665 0.320286 2.73674 0.195262 2.86177C0.0702379 2.98679 0 3.15636 0 3.33317C0 3.50998 0.0702379 3.67955 0.195262 3.80457C0.320286 3.9296 0.489856 3.99983 0.666667 3.99983Z"
				fill="white"
			/>
			<path
				d="M3.33317 6C3.15636 6 2.98679 6.07024 2.86177 6.19526C2.73674 6.32029 2.6665 6.48985 2.6665 6.66667C2.6665 6.84348 2.73674 7.01304 2.86177 7.13807C2.98679 7.26309 3.15636 7.33333 3.33317 7.33333H12.6665C12.8433 7.33333 13.0129 7.26309 13.1379 7.13807C13.2629 7.01304 13.3332 6.84348 13.3332 6.66667C13.3332 6.48985 13.2629 6.32029 13.1379 6.19526C13.0129 6.07024 12.8433 6 12.6665 6H3.33317Z"
				fill="white"
			/>
			<path
				d="M12.6665 12.6665H3.33317C3.15636 12.6665 2.98679 12.7367 2.86177 12.8618C2.73674 12.9868 2.6665 13.1564 2.6665 13.3332C2.6665 13.51 2.73674 13.6796 2.86177 13.8046C2.98679 13.9296 3.15636 13.9998 3.33317 13.9998H12.6665C12.8433 13.9998 13.0129 13.9296 13.1379 13.8046C13.2629 13.6796 13.3332 13.51 13.3332 13.3332C13.3332 13.1564 13.2629 12.9868 13.1379 12.8618C13.0129 12.7367 12.8433 12.6665 12.6665 12.6665Z"
				fill="white"
			/>
			<path
				d="M15.3333 9.3335H0.666667C0.489856 9.3335 0.320286 9.40373 0.195262 9.52876C0.0702379 9.65378 0 9.82335 0 10.0002C0 10.177 0.0702379 10.3465 0.195262 10.4716C0.320286 10.5966 0.489856 10.6668 0.666667 10.6668H15.3333C15.5101 10.6668 15.6797 10.5966 15.8047 10.4716C15.9298 10.3465 16 10.177 16 10.0002C16 9.82335 15.9298 9.65378 15.8047 9.52876C15.6797 9.40373 15.5101 9.3335 15.3333 9.3335Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_5211_60481">
				<rect fill="white" height="16" width="16" />
			</clipPath>
		</defs>
	</svg>
);

export default AlignCenterIcon;
