// @flow

// core
import React, { type Node } from 'react';

// libs
import { Box } from '@mui/material';
import { type ISkeletonProps, LoaderSkeleton } from './LoaderSkeleton';
import { Orders } from './Orders';

export const Visit = ({ children, isLoading }: ISkeletonProps): Node =>
	isLoading ? (
		<Box sx={SX.skeletonWrapper}>
			<LoaderSkeleton sx={SX.visitSkeleton} />
			<Orders isLoading />
		</Box>
	) : (
		children
	);

const SX = {
	skeletonWrapper: {
		width: '100%',
		maxHeight: '100%',
		rowGap: '20px',
		display: 'grid',
		flexDirection: 'column',
		padding: '50px',
		overflow: 'hidden',
	},
	visitSkeleton: {
		borderRadius: '10px',
		width: '100%',
		height: '80px',
	},
};
