/* Generated automagically by FhirStarter - but please feel free to edit this file */
const PatientActivityMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.id;
		},
	},
	subject: {
		label: 'Subject',
		options: { filter: false, sort: false },
		queryParameter: 'subject',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.subject;
		},
	},
	internalPatientID: {
		label: 'Internal Patient ID',
		options: { filter: false, sort: false },
		queryParameter: 'internalPatientID',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.internalPatientID;
		},
	},
	practitioner: {
		label: 'Practitioner',
		options: { filter: false, sort: false },
		queryParameter: 'practitioner',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.practitioner?.display;
		},
	},
	activityType: {
		label: 'Activity Type',
		options: { filter: false, sort: false },
		queryParameter: 'activityType',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.activityType;
		},
	},
	callType: {
		label: 'Call Type',
		options: { filter: false, sort: false },
		queryParameter: 'callType',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.callType;
		},
	},
	comment: {
		label: 'Comment',
		options: { filter: false, sort: false },
		queryParameter: 'comment',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.comment;
		},
	},
	loginName: {
		label: 'Login Name',
		options: { filter: false, sort: false },
		queryParameter: 'loginName',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.loginName;
		},
	},
	userName: {
		label: 'User Name',
		options: { filter: false, sort: false },
		queryParameter: 'userName',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.userName;
		},
	},
	dateTimeUpdated: {
		label: 'Date/Time Last Updated',
		options: { filter: false, sort: false },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => {
			let value = resource.meta?.lastUpdated;
			if (value != null) {
				let dateTime = new Date(value);
				return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
			}
		},
	},
};
export default PatientActivityMapping;
