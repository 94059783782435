// React Libraries
import React, { useState, useEffect } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

// Material UI Libraries
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import TableChartIcon from '@mui/icons-material/TableChart';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import List from '@mui/material/List';
import Box from '@mui/material/Box';

// Custom Component Libraries
import UpArrow from '@worklist-2/ui/src/assets/icons/up-arrow.svg';
import DragListItem from './DragListItem';

function ReportFields({ measures, fieldSearchValue }) {
	const [expanded, setExpanded] = useState([]);
	const [fieldsAfterSearch, setFieldsAfterSearch] = useState(null);

	const handleChange = (panel, state) => {
		const tempExpand = JSON.parse(JSON.stringify(expanded));
		tempExpand[panel] = !state;
		setExpanded(tempExpand);
	};

	useEffect(() => {
		if (fieldSearchValue === '') {
			setFieldsAfterSearch(measures);
			setExpanded(measures?.cubes.map(x => false));
		} else {
			const trimmedSearchValue = fieldSearchValue.trim().toLowerCase();
			const tempData = measures?.cubes
				.map(data => {
					if (data?.title.toLowerCase().includes(trimmedSearchValue)) {
						return data;
					}
					return {
						...data,
						measures: data.measures.filter(m => m.shortTitle.toLowerCase().includes(trimmedSearchValue)),
						dimensions: data.dimensions.filter(m =>
							m.shortTitle.toLowerCase().includes(trimmedSearchValue)
						),
					};
				})
				.filter(x => x.measures.length !== 0 || x.dimensions.length !== 0);
			setFieldsAfterSearch({ cubes: tempData });

			setExpanded(tempData.map(x => true));
		}
	}, [fieldSearchValue, measures]);

	return (
		<Box
			data-testid="container_click"
			sx={{
				marginTop: 1,
				overflowY: 'scroll',
				height: '85%',
			}}
		>
			{fieldsAfterSearch?.cubes.map((cubeObj, index) => (
				<Accordion
					key={index}
					expanded={expanded[index]}
					sx={{
						'&.Mui-expanded': {
							margin: 0,
						},
					}}
					onChange={() => handleChange(index, expanded[index])}
				>
					<AccordionSummary
						aria-controls="panel1d-content"
						expandIcon={<UpArrow />}
						id="panel1d-header"
						sx={{
							display: 'flex',
							alignItems: 'center',
							'&.Mui-expanded': {
								minHeight: '48px',
								'.MuiAccordionSummary-content': {
									margin: 0,
								},
							},
						}}
					>
						<TableChartIcon
							sx={{
								color: '#4D79EA',
								marginRight: '16px',
							}}
						/>
						<Typography sx={{ color: '#000' }}>
							{cubeObj.title} ({cubeObj.measures.length + cubeObj.dimensions.length})
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						sx={{
							background: '#EFF3FF',
							borderRadius: '10px',
						}}
					>
						{cubeObj.measures.length > 0 ? (
							<div data-testid="cubes_drop">
								<Droppable key={`${index}-measure`} droppableId={`${cubeObj.name}-measure`}>
									{(provided, snapshot) => (
										<div {...provided.droppableProps} ref={provided.innerRef}>
											<List sx={{ color: '#000' }}>
												Measures
												{cubeObj.measures.map((measure, i) => (
													<DragListItem
														key={i}
														index={i}
														name={cubeObj.title}
														value={measure}
													/>
												))}
											</List>
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</div>
						) : null}
						{cubeObj.dimensions.length > 0 ? (
							<Droppable key={`${index}-dimension`} droppableId={`${cubeObj.name}-dimension`}>
								{(provided, snapshot) => (
									<div {...provided.droppableProps} ref={provided.innerRef}>
										<List sx={{ color: '#000' }}>
											Dimensions
											{cubeObj.dimensions.map((dimension, i) => (
												<DragListItem
													key={i}
													index={i}
													name={cubeObj.title}
													value={dimension}
												/>
											))}
										</List>
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						) : null}
					</AccordionDetails>
				</Accordion>
			))}
		</Box>
	);
}

ReportFields.propTypes = {
	/**
	 * measures is array that holds all the fields and related items
	 */
	measures: PropTypes.object,
};

export default ReportFields;
