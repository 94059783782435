// React Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Material UI Libraries
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';

// Custom Component Libraries
import { Svg } from '@worklist-2/ui/src/components/Svg';

function ReportFilter({ sortMethod }) {
	const [filter, setFilter] = React.useState('latest');

	const handleChange = (event, value) => {
		setFilter(event.target.value);
		sortMethod(value.props.value);
	};

	return (
		<Box sx={{ width: 92 }}>
			<FormControl fullWidth>
				<Select
					MenuProps={{
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'right',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						getContentAnchorEl: null,
					}}
					sx={{
						borderRadius: '10px',
						borderColor: 'rgba(255, 255, 255, 0.1)',
						background: 'rgba(57, 57, 57, 0.5)',
						height: '54px',
						'.MuiInputBase-input > svg': {
							display: 'none',
						},
					}}
					value={filter}
					onChange={handleChange}
				>
					<MenuItem sx={{ paddingRight: '50px' }} value="latest">
						<Svg name="sorting" sx={{ marginRight: '15px', height: '20px' }} />
						Latest
					</MenuItem>
					<MenuItem sx={{ paddingRight: '50px' }} value="a-z">
						<SortByAlphaIcon sx={{ marginRight: '15px' }} /> A to Z
					</MenuItem>
					<MenuItem sx={{ paddingRight: '50px' }} value="z-a">
						<SortByAlphaIcon sx={{ marginRight: '15px' }} /> Z to A
					</MenuItem>
				</Select>
			</FormControl>
		</Box>
	);
}

ReportFilter.propTypes = {
	/**
	 * sortMethod is function to sort the report
	 */
	sortMethod: PropTypes.func,
};

export default ReportFilter;
