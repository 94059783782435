// core
import React from 'react';
// libaries
import PropTypes from 'prop-types';
// mui
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';

const ActiveSwitch = ({ isDisabled, isChecked, onChange }) => {
	const { t } = useTranslation('organization');
	return (
		<FormControlLabel
			control={
				<Switch
					checked={isChecked}
					color="info"
					disabled={isDisabled}
					sx={{
						'& > *:not(.Mui-checked) .MuiSwitch-thumb': {
							backgroundColor: '#bbb',
						},
					}}
					onChange={onChange}
				/>
			}
			label={isChecked ? t('Active') : t('Inactive')}
			labelPlacement="bottom"
			sx={{
				marginTop: '-6px',
				marginX: '0px',

				'& > .MuiFormControlLabel-label': {
					fontSize: '12px',
					color: 'text.faint',
					marginTop: '-5px',
				},
			}}
		/>
	);
};

ActiveSwitch.propTypes = {
	isDisabled: PropTypes.bool,
	isChecked: PropTypes.bool,
	onChange: PropTypes.func,
};

export default ActiveSwitch;
