import _ from 'lodash';

export const loadOmegaMarkdown = (path) => {
	try {
		const Content = require(`./${path}.mdx`);
		localStorage.setItem('markdownPath', path);
		// convert to string to enable search
		const { children } = Content?.default?.().props || { children: [] };
		const textContent = [];

		for (let child of children) {
			if (_.isObject(child) && child?.props?.children) {
				textContent.push(child.props.children);
			}
		}

		return {
			name: `${path.replaceAll('/', '_')}`,
			path: `${path}`,
			content: Content?.default?.(),
			textContent: textContent,
		};
	} catch (error) {
		console.log(error);
		return {};
	}
};
