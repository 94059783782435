import React, { createContext, useState, useContext, useReducer } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import {
	searchDFS,
	createFolder,
	sortFolderByName,
	searchFolderBySearchText,
} from '@worklist-2/ui/src/components/utils/treeUtils';
import { v4 } from 'uuid';

const defaultValue = {
	dispatch: null,
	state: [],
};

/**
 * Tree context reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
const reducer = (state, action) => {
	let newState = _cloneDeep(state);
	let node = null;
	let parentNode = null;
	if (action.payload && action.payload?.id) {
		const foundNode = searchDFS({
			data: newState,
			cond: item => item.id === action.payload.id,
		});
		parentNode = foundNode.parent;
		node = foundNode.item;
	}

	switch (action.type) {
		case 'SET_DATA':
			return sortFolderByName(action.payload);
		case 'CREATE_FOLDER':
			// state for create folder
			if (!parentNode) {
				// Root folder
				node = newState?.find(item => item.id === action.payload.id && item.isNewFolder);
			} else {
				// Child folder
				node = parentNode.files.find(item => item.id === action.payload.id && item.isNewFolder);
			}
			if (node) {
				node.name = action.payload.name;
				node.studies = action.payload.studies || [];
				node.isNewFolder = false;
			}
			return sortFolderByName(newState);
		case 'SHOW_ADD_FOLDER':
			if (!node) {
				// Root folder
				newState?.unshift(createFolder({ id: v4(), name: action.payload.name, isNewFolder: true, level: 1 }));
			} else {
				// Child folder
				node.files.unshift(
					createFolder({
						id: v4(),
						name: action.payload.name,
						isNewFolder: true,
						level: action.payload.level,
					})
				);
			}
			return newState;
		case 'DELETE_FOLDER':
			if (!parentNode) {
				// Root folder
				newState = newState?.filter(item => item.id !== action.payload.id);
			} else {
				// Child folder
				parentNode.files = parentNode.files.filter(item => item.id !== action.payload.id);
			}
			return newState;
		case 'SEARCH_FOLDER':
			return searchFolderBySearchText(newState, action.payload.searchText);
		case 'OPEN_FOLDER':
			if (node) {
				node.isOpen = action.payload.open !== undefined ? action.payload.open : !node.isOpen;
			}
			return newState;
		default:
			return state;
	}
};

export const TreeContext = createContext(defaultValue);

export const TreeContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer);
	const [selectedFolderId, setSelectedFolderId] = useState(null);
	const [selectedFolderData, setSelectedFolderData] = useState(null);
	const [treeWidth, setTreeWidth] = useState(300);
	const [errorCode, setErrorCode] = useState(0);
	const [isUpdating, setIsUpdating] = useState(false);
	const [openShareDrawer, setOpenShareDrawer] = useState(false);
	const [folderTab, setFolderTab] = useState(null); // myfolder, sharedfolder
	const [userCanEdit, setUserCanEdit] = useState(false);
	const [searchText, setSearchText] = useState('');
	return (
		<TreeContext.Provider
			value={{
				state,
				dispatch,
				selectedFolderId,
				selectedFolderData,
				treeWidth,
				errorCode,
				isUpdating,
				openShareDrawer,
				folderTab,
				searchText,
				userCanEdit,
				setSelectedFolderId,
				setTreeWidth,
				setErrorCode,
				setIsUpdating,
				setSelectedFolderData,
				setOpenShareDrawer,
				setFolderTab,
				setUserCanEdit,
				setSearchText,
			}}
		>
			{children}
		</TreeContext.Provider>
	);
};
export const useTreeContext = () => {
	const treeViewContext = useContext(TreeContext);
	const {
		state,
		dispatch,
		treeWidth,
		errorCode,
		selectedFolderId,
		selectedFolderData,
		isUpdating,
		openShareDrawer,
		folderTab,
		searchText,
		userCanEdit,
		setSelectedFolderId,
		setTreeWidth,
		setErrorCode,
		setIsUpdating,
		setSelectedFolderData,
		setOpenShareDrawer,
		setFolderTab,
		setUserCanEdit,
		setSearchText,
	} = treeViewContext;

	return {
		state,
		dispatch,
		treeWidth,
		errorCode,
		selectedFolderId,
		selectedFolderData,
		isUpdating,
		openShareDrawer,
		folderTab,
		searchText,
		userCanEdit,
		setSelectedFolderId,
		setTreeWidth,
		setErrorCode,
		setIsUpdating,
		setSelectedFolderData,
		setOpenShareDrawer,
		setFolderTab,
		setUserCanEdit,
		setSearchText,
	};
};
export default TreeContextProvider;
