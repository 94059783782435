import React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';

const AvatarGroupIcon = ({ shape, dataSet, maxIcon, sx, onClick }) => {
	const muiAvatarGroup = createTheme({
		components: {
			MuiAvatarGroup: {
				styleOverrides: {
					root: ({ ownerState: { max } }) => ({
						...[...Array(max)].reduce(
							(result, curr, index) => ({
								...result,
								[`& > .MuiAvatar-root:nth-child(${index + 1})`]: {
									zIndex: max - index,
									border: '0px',
									color: 'black',
									fontSize: shape === 'square' ? '16px' : '14px',
									fontWeight: '400px',
									borderRadius: shape === 'square' ? '10px' : '',
									height: shape === 'square' ? '98px' : '37px',
									width: shape === 'square' ? '98px' : '37px',
									backgroundColor: '#E3B269',
									cursor: 'pointer',
								},
							}),
							{}
						),
					}),
				},
			},
		},
	});
	return (
		<ThemeProvider theme={muiAvatarGroup}>
			<AvatarGroup
				className="toolbar-btn-cls"
				max={maxIcon}
				spacing={shape === 'square' ? -5 : 20}
				sx={{
					justifyContent: 'left',
					...sx,
				}}
				variant={shape}
				onClick={onClick}
			>
				{_.map(dataSet, (ds, index) => (
					<Avatar key={index} alt={ds.name} className="toolbar-btn-cls" src={ds.source} variant={shape} />
				))}
			</AvatarGroup>
		</ThemeProvider>
	);
};

AvatarGroupIcon.propTypes = {
	/**
	 * List of data set
	 */
	dataSet: PropTypes.array,
	/**
	 * shape for avatar group
	 */
	shape: PropTypes.string,
	/**
	 * max number item to be displayed in AvatarGroup
	 */
	maxIcon: PropTypes.number,
	/**
	 * Handler for when the Icon is clicked
	 */
	onClick: PropTypes.func,
};

AvatarGroupIcon.defaultProps = {
	dataSet: [],
	shape: '',
	maxIcon: 3,
};

export default AvatarGroupIcon;
