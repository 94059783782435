import React, { useState } from 'react';
import { BlumeIDWrapper, TabPanel } from '../../../casePopOverStyles';
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';
import { Card, SearchBar } from '../../../../../../views/CrmView/styles';
import Avatar from '@worklist-2/ui/src/assets/img/crmUserImg1.png';
import blumeImg from '@worklist-2/ui/src/assets/img/blume.png';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import IconButton from '@mui/material/IconButton';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CustomTextInput from '@worklist-2/ui/src/components/Help/CustomTextInput';

const BlumeID = () => {
	const [showSearch, toggleSearch] = useState(false);
	const [possibleMatch] = useState({
		name: 'Isabella Ding',
		location: 'Mississauga, Canada',
	});
	return (
		<TabPanel>
			<div className="header">
				<div className="left">
					<div className="info-icon">
						<FingerprintOutlinedIcon sx={{ fontSize: 20 }} />
					</div>
					<h2>Blume ID</h2>
				</div>
			</div>
			<div className="body">
				<BlumeIDWrapper>
					{showSearch ? (
						<>
							<img src={blumeImg} style={{ margin: '68px auto 0px auto', width: '45px' }} />
							<h3 style={{ marginTop: '10px' }}>Search</h3>
							<span>Search our database of users</span>
							<SearchBar className="search-bar">
								<SearchOutlinedIcon sx={{ m: '10px', color: '#4D79EA', marginLeft: '19px' }} />
								<CustomTextInput
									inputProps={{ 'aria-label': 'Search' }}
									placeholder="Search our database of users"
									style={{ flex: 1, padding: '1px 2px' }}
								/>
							</SearchBar>
						</>
					) : (
						<>
							<Card className="card">
								<img src={Avatar} />
								<h5>{possibleMatch.name}</h5>
								<div className="hint">
									<RoomOutlinedIcon />
									<span>{possibleMatch.location}</span>
								</div>
								<div className="hint">
									<IconButton sx={{ marginRight: '21px' }}>
										<DriveFileRenameOutlineOutlinedIcon
											sx={{ fontSize: '18px', color: '#4D79EA' }}
										/>
									</IconButton>
									<IconButton>
										<BadgeOutlinedIcon sx={{ fontSize: '18px', color: '#4D79EA' }} />
									</IconButton>
								</div>
							</Card>
							<h3>Possible Blume ID Match</h3>
							<span>Is this who you are looking for?</span>
							<div className="row">
								<button className="fill">Yes</button>
								<button
									className="outline"
									data-testid="blumeNobutton"
									onClick={() => toggleSearch(true)}
								>
									No
								</button>
							</div>
						</>
					)}
				</BlumeIDWrapper>
			</div>
		</TabPanel>
	);
};

export default BlumeID;
