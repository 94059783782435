import React, { useCallback, useEffect, useState } from 'react';

import styles from './CheckList.module.scss';

const CheckList = ({ theme, setCurrentTextBlock, setCheckListData, handleKeyDown, draftItem }) => {
	const [checkList, setCheckList] = useState([
		{ checked: false, value: '', id: `textBlock-Check List-${Date.now()}` },
	]);

	const handleKeyPress = useCallback(e => {
		if (e.keyCode === 13 && e.ctrlKey === false) {
			const newId = `textBlock-Check List-${Date.now()}`;
			setCheckList(prev => [...prev, { checked: false, value: '', id: newId }]);
			document.getElementById(newId)?.focus();
		}
		if (e.keyCode === 8 && e.target.value === '') {
			let lastElemID = '';
			setCheckList(prev => {
				const newCheckList = prev.filter(listElem => listElem.id !== e.target.id);
				lastElemID = newCheckList.at(-1).id;
				return newCheckList;
			});
			document.getElementById(lastElemID)?.focus();
		}
	}, []);

	useEffect(() => {
		document.getElementById('checkList')?.addEventListener('keydown', handleKeyPress);

		return () => {
			document.getElementById('checkList')?.addEventListener('keydown', handleKeyPress);
		};
	}, []);

	useEffect(() => {
		if (draftItem) {
			setCheckList(draftItem.checkList);
		}
	}, []);

	useEffect(() => {
		if (draftItem) {
			setCheckListData(checkList);
		} else {
			setCurrentTextBlock(prev => ({ ...prev, checkList }));
		}
	}, [checkList]);

	const onCheckedChange = item => {
		setCheckList(prev =>
			prev.map(listElem => {
				if (listElem.id === item.id) {
					return {
						...listElem,
						checked: !listElem.checked,
					};
				}
				return listElem;
			})
		);
	};

	const onValueChange = (e, item) => {
		setCheckList(prev =>
			prev.map(listElem => {
				if (listElem.id === item.id) {
					return {
						...listElem,
						value: e.target.value,
					};
				}
				return listElem;
			})
		);
	};

	return (
		<div className={styles.checkList} data-testid="checkList" id="checkList">
			{checkList.map(item => (
				<label key={item.id} className={styles.checkListItem}>
					<input
						checked={item.checked}
						className={`${styles.checkListItemCheckbox} ${
							theme === 'OAI' ? styles.checkListItemCheckboxOAI : styles.checkListItemCheckboxBlume
						}`}
						data-testid="checkListCheckbox"
						placeholder="Start typing to create list item"
						type="checkbox"
						onChange={() => onCheckedChange(item)}
					/>
					<input
						className={`${styles.checkListItemInput} ${
							theme === 'OAI' ? styles.inputOAI : styles.inputBlume
						}`}
						data-testid="checkListInput"
						id={item.id}
						type="text"
						value={item.value}
						onChange={e => onValueChange(e, item)}
						onKeyDown={e => handleKeyDown(e)}
					/>
				</label>
			))}
		</div>
	);
};

export default CheckList;
