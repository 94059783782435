import isDisplaySetReconstructable, { constructableModalities } from './isDisplaySetReconstructable';
import { checkSingleFrames } from './checkSingleFrames';
import { checkMultiFrame } from './checkMultiframe';
import { CODES } from './codes';
import { omit } from 'lodash';
import dcmjs from 'dcmjs';

const { DicomMetaDictionary } = dcmjs.data;

export const sortSeriesFrames = (instanceList, plane) => {
	let frameSetMessages = {};
	const instances = instanceList.map(i =>
		DicomMetaDictionary.naturalizeDataset(omit(i, ['isMultiframe', 'scalingModule', 'cadsr']))
	);
	const { isReconstructable, sortedInstancesByPosition } = isDisplaySetReconstructable(instances, plane);

	const firstInstance = instances[0];
	const { NumberOfFrames, Modality } = firstInstance;

	if (!constructableModalities.includes(Modality)) {
		return instanceList;
	}

	frameSetMessages = {
		...frameSetMessages,
		[isReconstructable ? CODES.RECONSTRUCTABLE : CODES.NOT_RECONSTRUCTABLE]: true,
	};

	const isMultiframe = NumberOfFrames > 1;
	// Can't reconstruct if all instances don't have the ImagePositionPatient.
	if (!isMultiframe && !instances.every(instance => instance.ImagePositionPatient)) {
		frameSetMessages = { ...frameSetMessages, [CODES.NO_POSITION_INFORMATION]: true };
	}

	frameSetMessages = {
		...frameSetMessages,
		...(isMultiframe
			? checkMultiFrame(sortedInstancesByPosition[0])
			: checkSingleFrames(sortedInstancesByPosition)),
	};

	let sortedInstances = sortedInstancesByPosition;

	if (frameSetMessages[CODES.NOT_RECONSTRUCTABLE]) {
		switch (true) {
			case frameSetMessages[CODES.NO_POSITION_INFORMATION]:
			case frameSetMessages[CODES.INCONSISTENT_ORIENTATIONS]:
				sortedInstances = sortedInstances.sort(
					(a, b) => (parseInt(a.InstanceNumber) || 0) - (parseInt(b.InstanceNumber) || 0)
				);
				break;
			default:
				console.log('No issues to handle');
				break;
		}
	}

	return instanceList.sort((a, b) => {
		const aValue = sortedInstances.findIndex(item => item.SOPInstanceUID === a['00080018']?.Value[0]);
		const bValue = sortedInstances.findIndex(item => item.SOPInstanceUID === b['00080018']?.Value[0]);
		return aValue - bValue;
	});
};
