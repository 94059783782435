export function getUniqueOrganizations(arr) {
	const uniqueOrganizations = [];
	const organizationIds = new Set();

	arr.forEach(obj => {
		const orgId = obj?.organization?.id;

		if (!organizationIds.has(orgId)) {
			organizationIds.add(orgId);
			uniqueOrganizations.push(obj?.organization);
		}
	});

	return uniqueOrganizations;
}
