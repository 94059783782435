/**
 * Adds a cache buster parameter to the given URL.
 * If the URL already contains a cache buster parameter (_dc), it will be replaced with the current timestamp.
 * If the URL does not contain a cache buster parameter, it will be appended to the URL.
 * @param {string} url - The URL to add the cache buster parameter to.
 * @returns {string} The modified URL with the cache buster parameter.
 */
export const addCacheBuster = url => {
	if (url.includes('_dc')) {
		return url.replace(/_dc=\d+/, `_dc=${Date.now()}`);
	}
	return `${url}${url.includes('?') ? '&' : '?'}_dc=${Date.now()}`;
};

/**
 * Adds a parameter to the given URL.
 *
 * @param {string} url - The URL to add the parameter to.
 * @param {string} key - The key of the parameter.
 * @param {string} value - The value of the parameter.
 * @returns {string} The modified URL with the added parameter.
 */
export const addParameter = (url, key, value) => {
	if (url.includes(`${key}=`)) {
		return url.replace(new RegExp(`${key}=[^&]+`), `${key}=${value}`);
	}
	return `${url}${url.includes('?') ? '&' : '?'}${key}=${value}`;
};

/**
 * Constructs a URL by combining a base URI, a route, and optional query parameters.
 *
 * @param {string} uri - The base URI for the URL.
 * @param {string} route - The route or path to append to the base URI.
 * @param {object} params - An object of key-value pairs representing query parameters.
 * @returns {string} The complete URL with the route and query parameters included.
 *
 * @example
 * // Constructs a URL with base URI, route, and parameters
 * const url = createUrl("https://example.com", "api/v1/resource", { param1: "value1", param2: "value2" });
 * // Output: "https://example.com/api/v1/resource?param1=value1&param2=value2"
 */
export const createUrl = (uri = '', route = '', params = {}) => {
	// Ensure uri and route are not null and trim any extraneous slashes
	uri = uri.endsWith('/') ? uri.slice(0, -1) : uri;
	route = route && route.startsWith('/') ? route.slice(1) : route;
	route = route && route.endsWith('/') ? route.slice(0, -1) : route;
	// Construct base URL by joining uri and route
	let url = uri + (route ? `/${route}` : '');

	// Add query parameters from the params object
	Object.keys(params).forEach(key => {
		url = addParameter(url, key, params[key]);
	});
	return url;
};
