import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { makeStyles } from 'tss-react/mui';
import { InputAdornment, Autocomplete, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import OverflowTooltip from '@worklist-2/ui/src/components/OverflowTooltip';
import { useAuth } from '@worklist-2/core/src';

const useStyles = makeStyles()({
	root: {},
	menuPaper: {
		maxWidth: 300,
		minWidth: 150,
		overflow: 'hidden',
		'& .MuiAutocomplete-listbox': {
			maxHeight: 260,
		},
	},
});

const SuggestInfiniteScroll = ({
	dataLoader,
	onSelectItem,
	searchParams,
	label,
	renderOptionlist,
	excludedOption = [],
	debounceDelay,
	countPerPage,
	startAdornment = <SearchIcon />,
	placeHolderText = 'Search',
}) => {
	const [optionList, setOptionList] = useState([]);
	const [selectedItem, setSelectedItem] = useState(null);
	const [itemSearchParam, setItemSearchParam] = useState({ page: 1, [searchParams.search]: null });
	const [isLoading, setIsLoading] = useState(false);
	const [disableScroll, setDisableScroll] = useState(false);

	const { loggedInUser } = useAuth();

	const { classes } = useStyles();
	const { t } = useTranslation('root');

	const onSearch = useCallback(
		_.debounce((newValue, reason) => {
			if (reason == 'input') {
				if (newValue != itemSearchParam[searchParams.search]) {
					setOptionList([]);
					setItemSearchParam(() => ({ page: 1, [searchParams.search]: newValue }));
					setDisableScroll(false);
				}
			} else if (reason === 'clear') {
				setOptionList([]);
				setItemSearchParam(() => ({ page: 1, [searchParams.search]: null }));
				setDisableScroll(false);
			}
		}, debounceDelay),
		[itemSearchParam]
	);

	const onChangeItem = (event, value, reason) => {
		setSelectedItem(value);
		if (onSelectItem) {
			onSelectItem(value);
			if (reason === 'clear' && itemSearchParam[searchParams.search] !== null) {
				setItemSearchParam({ ...itemSearchParam, [searchParams.search]: null });
			}
		}
	};

	const onLoadOptions = useCallback(
		searchParam => {
			if (searchParam[searchParams.search] !== '' && dataLoader) {
				setIsLoading(true);

				const params =
					searchParam[searchParams.search] || searchParam.email
						? { ...searchParam }
						: { page: searchParam.page };
				dataLoader
					.load({
						...params,
						sort: searchParams.sort,
						count: countPerPage,
						extraValue: searchParams.extraParam,
					})
					.then(data => {
						if (data.length > 0) {
							if (excludedOption.length > 0) {
								data = data.filter(d => !excludedOption.includes(d.id));
							}
							const processedData = data.map(d => ({ ...d }));
							setOptionList(prev => {
								if (searchParam.page > 1) {
									return [...(prev || []), ...(processedData || [])];
								}
								return [...(processedData || [])];
							});
							setDisableScroll(false);
						} else {
							setDisableScroll(true);
						}

						setIsLoading(false);
					})
					.catch(e => console.log(e));
			}
		},
		[dataLoader, searchParams.extraParam]
	);

	useEffect(() => {
		if (loggedInUser) {
			onLoadOptions(itemSearchParam);
		}
	}, [loggedInUser, itemSearchParam, onLoadOptions, searchParams.extraParam]);

	return (
		<Autocomplete
			blurOnSelect
			freeSolo
			ListboxProps={{
				onScroll: event => {
					const listboxNode = event.currentTarget;
					listboxNode.role = 'list-box';
					if (
						!disableScroll &&
						!isLoading &&
						listboxNode.scrollTop + listboxNode.clientHeight > listboxNode.scrollHeight - 1
					) {
						setItemSearchParam(prev => ({
							...prev,
							page: prev.page + 1,
						}));
					}
				},
			}}
			componentsProps={{ paper: { sx: classes.menuPaper } }}
			data-testid="suggest-infinite-scroll-single"
			defaultValue={selectedItem}
			getOptionLabel={option => option?.label ?? option?.name ?? ''}
			isOptionEqualToValue={(option, value) => option?.id === value?.id}
			loading={isLoading}
			options={optionList}
			renderInput={params => (
				<TextField
					{...params}
					InputProps={{
						...params.InputProps,
						startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment>,
					}}
					label={label}
					placeholder={t(placeHolderText)}
					sx={{
						'.MuiOutlinedInput-input': {
							padding: '3.5px 3px 3.5px 0px !important',
							width: 'auto !important',
							marginLeft: '0px',
						},
					}}
				/>
			)}
			renderOption={(props, option, { selected }) =>
				renderOptionlist ? (
					renderOptionlist(props, selected, option)
				) : (
					<li {...props}>
						<OverflowTooltip text={option.label ?? option?.name} />
					</li>
				)
			}
			value={selectedItem}
			onChange={onChangeItem} // select values from dropdown menu
			onInputChange={(event, value, reason) => {
				// when typing
				onSearch(value, reason);
			}}
		/>
	);
};

SuggestInfiniteScroll.propTypes = {
	/**
	 * Dataloader for loading options
	 */
	dataLoader: PropTypes.object.isRequired,
	/**
	 * function to be called when an item is selected to pass
	 * selected value to parent component
	 */
	onSelectItem: PropTypes.func.isRequired,
	/**
	 * Lifted up state - list of Favorite Apps
	 */
	searchParams: PropTypes.shape({
		/**
		 * search field name
		 */
		search: PropTypes.string,
		/**
		 * extra paramters such as count, _element
		 */
		extraParam: PropTypes.object,
	}).isRequired,
	/**
	 * translated label for display, like 'username' 'organization'
	 */
	label: PropTypes.string.isRequired,
	/**
	 *  debounce delay for searching
	 */
	debounceDelay: PropTypes.number,
	/**
	 * number of options to load
	 */
	countPerPage: PropTypes.number,
};

SuggestInfiniteScroll.defaultProps = {
	debounceDelay: 1000,
	countPerPage: 20,
};

export default SuggestInfiniteScroll;
