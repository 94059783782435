import React from 'react';

const ClearIcon = () => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_75527_90163)">
			<path
				d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
				fill="white"
				fillOpacity="0.6"
			/>
		</g>
		<defs>
			<clipPath id="clip0_75527_90163">
				<rect fill="white" height="24" width="24" />
			</clipPath>
		</defs>
	</svg>
);

export default ClearIcon;
