/* Generated automagically by FhirStarter - DO NOT EDIT */
const organizationSchema = {
	resourceType: 'Organization',
	identifier: ['__Identifier__'],
	active: '__boolean__',
	type: ['__CodeableConcept__'],
	name: '__string__',
	alias: ['__string__'],
	telecom: ['__ContactPoint__'],
	address: ['__Address__'],
	partOf: '__Reference(Organization)__',
	contact: [
		{
			purpose: '__CodeableConcept__',
			name: '__HumanName__',
			telecom: ['__ContactPoint__'],
			address: '__Address__',
		},
	],
	endpoint: ['__Reference(Endpoint)__'],
};
export default organizationSchema;
