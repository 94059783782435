import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LocationOn from '@mui/icons-material/LocationOn';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import TrainIcon from '@mui/icons-material/Train';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const LocationCard = ({ cardColor, sx }) => {
	const handleCheckboxClick = () => undefined;
	// TODO: Add CheckBox click for map based search
	const getChildren = () => (
		<Tabs
			centered
			TabIndicatorProps={{
				sx: {
					height: '0px',
				},
			}}
			value={valueLocation}
			onChange={handlechangeLocation}
		>
			<Tab
				icon={
					<DirectionsCarFilledIcon
						color="rsPrimary"
						sx={{
							width: '24px',
						}}
					/>
				}
				sx={tabStyle(0)}
			/>
			<Tab
				icon={
					<TrainIcon
						color="rsPrimary"
						sx={{
							width: '20px',
						}}
					/>
				}
				sx={tabStyle(1)}
			/>
			<Tab icon={<DirectionsWalkIcon color="rsPrimary" />} sx={tabStyle(2)} />

			<Tab icon={<DirectionsBikeIcon color="rsPrimary" />} sx={tabStyle(3)} />
		</Tabs>
	);

	const bodyLocation = () => (
		<div
			style={{
				width: '400px',
				paddingTop: '7px',
			}}
		>
			<TextField
				focused
				fullWidth
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<LocationOn
								sx={{
									color: '#00000080',
									paddingRight: '10px',
									fontSize: '2rem',
								}}
							/>
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position="end" variant="filled">
							<div
								display="flex"
								sx={{
									paddingRight: 0,
								}}
								onClick={handleCheckboxClick}
							>
								<CheckBoxIcon
									color="rsPrimary"
									fontSize="large"
									sx={{
										width: '55px',
										fontSize: '3.6875rem',
										paddingTop: '5px',
										cursor: 'pointer',
									}}
								/>
							</div>
						</InputAdornment>
					),
					style: {
						paddingRight: '0',
					},
				}}
				label="Starting Point"
				placeholder="Enter Starting address"
				sx={{
					input: { color: 'black' },
					'& label.Mui-focused': {
						color: '#737374',
					},
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							borderColor: '#39393999',
						},
						'&:hover fieldset': {
							borderColor: '#39393999',
						},
						'&.Mui-focused fieldset': {
							borderColor: '#39393999',
						},
						'.MuiSvgIcon-root': {
							opacity: 1,
						},
					},
				}}
			/>
		</div>
	);

	const tabStyle = index =>
		valueLocation === index
			? {
					width: '48px',
					bgcolor: '#b2d7ef',
					borderRadius: '50%',
					boxShadow: '0 0 0 0px #b2d7ef',
					minWidth: '0px',
			  }
			: {
					minWidth: '0px',
			  };
	const [valueLocation, setvalueLocation] = useState(0);
	const handlechangeLocation = (ev, newValue) => {
		setvalueLocation(newValue);
	};
	return (
		<Box
			sx={{
				position: 'absolute',
				zIndex: '9998',
				mt: '3px',
				mb: '3px',
				bgcolor: cardColor,
				width: '25.3%',
				borderRadius: '20px',
				...sx,
			}}
		>
			<Box
				sx={{
					height: '74px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{getChildren()}
			</Box>
			<Box
				sx={{
					height: '86px',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				{bodyLocation()}
			</Box>
		</Box>
	);
};

export default LocationCard;
