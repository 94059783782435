// core
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
// libraries
import PropTypes from 'prop-types';
// mui
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import styled from '@mui/system/styled';
// mui icons
import ArrowBack from '@mui/icons-material/ArrowBackIos';
import ArrowNext from '@mui/icons-material/ArrowForwardIos';

export const SnapStack = forwardRef(({ mb, mt, ...props }, ref) => {
	const buttonPrev = useRef();
	const buttonNext = useRef();
	const stackRef = useRef();

	const gap = typeof props.gap === 'number' ? props.gap * 8 : props.gap || 0;
	const [isScrollable, setIsScrollable] = useState(false);

	const onScroll = (direction) => {
		if (!stackRef.current) return;

		const childWidth = stackRef.current?.children[0]?.offsetWidth;
		const currentScrollLeft = stackRef.current?.scrollLeft || 0;
		const maxScrollLeft = stackRef.current?.scrollWidth || 0;

		stackRef.current.scrollTo(
			Math.min(
				maxScrollLeft,
				Math.max(0, currentScrollLeft + (direction === 'left' ? -0.75 : 0.75) * (childWidth + gap))
			),
			0
		);
	};

	const onScrollLeft = () => {
		onScroll('left');
	};

	const onScrollRight = () => {
		onScroll('right');
	};

	const onScrollReset = () => {
		if (stackRef.current) {
			stackRef.current.scrollLeft = 0;
		}
	};

	useImperativeHandle(ref, () => ({
		onScrollReset,
	}));

	useEffect(() => {
		const element = stackRef.current;

		if (element) {
			const wheelListener = (event) => {
				if (!event.deltaX) {
					event.preventDefault();

					onScroll(event.deltaY > 1 ? 'right' : 'left');
				}
			};

			element.addEventListener('wheel', wheelListener);

			return () => element.removeEventListener('wheel', wheelListener);
		}
	}, []);

	useEffect(() => {
		buttonPrev.current?.checkIfDisabled();
		buttonNext.current?.checkIfDisabled();
	}, [
		props.children instanceof Array ? props.children.length : props.children,
		buttonPrev.current,
		buttonNext.current,
	]);

	useEffect(() => {
		const stackElem = stackRef.current;
		const checkIfScrollable = () => {
			if (!stackElem) return;

			setIsScrollable(stackElem.scrollWidth > stackElem.clientWidth);
		};

		checkIfScrollable();

		stackElem?.addEventListener('scroll', checkIfScrollable);

		return () => stackElem?.removeEventListener('scroll', checkIfScrollable);
	}, []);

	return (
		<Box
			mb={mb}
			mt={mt}
			sx={{
				position: 'relative',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
			}}
		>
			{isScrollable && (
				<ArrowButton
					ref={buttonPrev}
					scrollDirection="left"
					stackElem={stackRef.current}
					sx={{ left: '-28px', paddingRight: '4px' }}
					onClick={onScrollLeft}
				>
					<ArrowBack sx={{ marginLeft: '4px' }} />
				</ArrowButton>
			)}

			<SnapStackContainer ref={stackRef} {...props} data-testid="snapstackcontainer" />

			{isScrollable && (
				<ArrowButton
					ref={buttonNext}
					scrollDirection="right"
					stackElem={stackRef.current}
					sx={{ right: '-28px', paddingRight: '7px' }}
					onClick={onScrollRight}
				>
					<ArrowNext sx={{ marginLeft: '1px' }} />
				</ArrowButton>
			)}
		</Box>
	);
});

const ArrowButton = forwardRef(
	({ itemCount, scrollDirection, stackElem, sx = {}, onClick: _onClick, ...props }, ref) => {
		const [isDisabled, setIsDisabled] = useState(false);

		useImperativeHandle(ref, () => ({
			checkIfDisabled: onCheckIfDisabled,
		}));

		const onCheckIfDisabled = () => {
			if (!stackElem) return;

			const currentScrollLeft = stackElem.scrollLeft || 0;
			const maxScrollLeft = stackElem.scrollWidth || 0;
			const width = stackElem.clientWidth || 0;

			setIsDisabled(
				scrollDirection === 'left' ? currentScrollLeft <= 0 : currentScrollLeft + width >= maxScrollLeft
			);
		};

		const onClick = () => {
			if (!isDisabled) _onClick?.();
		};

		useEffect(() => {
			onCheckIfDisabled();
		}, [stackElem, scrollDirection]);

		useEffect(() => {
			stackElem?.addEventListener('scroll', onCheckIfDisabled);

			return () => stackElem?.removeEventListener('scroll', onCheckIfDisabled);
		}, [stackElem]);

		return (
			<IconButton
				{...props}
				sx={{
					position: 'absolute',
					borderRadius: '50%',
					backgroundColor: '#3A3A3A !important',
					cursor: isDisabled ? 'not-allowed' : 'pointer',
					zoom: 0.9,
					zIndex: 1,
					display: isDisabled ? 'none' : undefined,

					'&:first-of-type': {
						boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)',
					},

					'&:last-of-type': {
						boxShadow: '-4px 4px 4px rgba(0, 0, 0, 0.25)',
					},

					'*': {
						color: 'text.primary',
						transition: 'color .3s ease',
					},

					'&:hover *': {
						color: 'rsPrimary.contrastText',
					},

					...sx,
				}}
				onClick={onClick}
			/>
		);
	}
);

ArrowButton.propTypes = {
	scrollDirection: PropTypes.string.isRequired,
	stackElem: PropTypes.object,
	sx: PropTypes.object,
	onClick: PropTypes.func.isRequired,
};

const SnapStackContainer = styled(Stack)({
	overflowX: 'scroll',
	scrollSnapType: 'x mandatory',
	counterReset: 'item',
	msOverflowStyle: 'none', // hides scrollbar on IE
	scrollbarWidth: 0, // hides scrollbar on Firefox
	scrollBehavior: 'smooth',

	// hides scrolllbar on webkit based browsers
	'::-webkit-scrollbar, ::-webkit-scrollbar-thumb': {
		height: 0,
		width: 0,
		background: 'transparent',
	},

	'& > *': {
		scrollSnapAlign: 'start',
		flexShrink: 0,
	},
});

SnapStack.propTypes = {
	gap: PropTypes.number.isRequired,
};
