import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { Box, Stack, FormControl, Radio } from '@mui/material';
import FormToolSubheader from '@worklist-2/ui/src/components/FormTool/FormToolSubheader';
import FormToolLabel from '@worklist-2/ui/src/components/FormTool/FormToolLabel';

const FormToolSurveyTableRow = ({ data, questionId, options = [], answer, isReadOnly, onChangeData, isCompact }) => {
	const [checked, setChecked] = React.useState();

	return (
		<>
			<Stack justifyContent="center">
				<FormToolLabel required={data.required}>{data.description}</FormToolLabel>
			</Stack>

			{options.map((optionItem, optionIndex) => (
				<Stack
					key={`${questionId}-${optionIndex}-${optionItem}`}
					sx={{
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Radio
						checked={optionItem.description === answer || checked === optionIndex}
						color="rsPrimary"
						disabled={isReadOnly}
						name={questionId}
						required={data.required}
						sx={{
							marginTop: '-9px',
							marginBottom: '-9px',
						}}
						value={optionItem.description}
						onChange={e => {
							setChecked(optionIndex);
							onChangeData(e);
						}}
					/>
				</Stack>
			))}
		</>
	);
};

// Survey Table component

const FormToolSurveyTable = ({
	id = uuid(),
	title,
	description,
	options = [],
	questions = [],
	answer = [],
	required,
	isReadOnly,
	onChangeData,
	isCompact,
}) => {
	const titleId = `${id}-survey-table-title`;
	const [selections, setSelections] = useState([]);

	useEffect(() => {
		if (answer.length > 0) {
			setSelections(answer[answer.length - 1].value);
		}
	}, [answer]);

	return (
		<FormControl>
			<FormToolSubheader
				description={description}
				isCompact={isCompact}
				required={required}
				title={title}
				titleId={titleId}
			/>

			<Box
				sx={{
					'--element-content-label-font-size': '14px',

					display: 'grid',
					gridTemplateColumns: `1fr repeat(${options.length}, max-content)`,
					gap: isCompact ? '5px' : 'var(--element-spacing) 10px',
					'.MuiRadio-root': {
						color: 'rgba(0, 0, 0, 0.2)',
					},
					'.Mui-checked': {
						color: '#4096DC',
					},
				}}
			>
				<div />

				{options.map((item, index) => (
					<FormToolLabel
						key={`${index}-${item}`}
						sx={{
							textAlign: 'center',
						}}
					>
						{item.description}
					</FormToolLabel>
				))}

				{questions.map((item, index) => (
					<FormToolSurveyTableRow
						key={`${index}-${item.description}`}
						answer={selections[index]}
						data={item}
						isCompact={isCompact}
						isReadOnly={isReadOnly}
						options={options}
						questionId={`${id}-survey-table-question-${index}`}
						onChangeData={e => onChangeData(e, index)}
					/>
				))}
			</Box>
		</FormControl>
	);
};

export default FormToolSurveyTable;
