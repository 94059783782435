import { utilities, cache } from '@cornerstonejs/core';
import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';
import getDefaultRenderingEngine from '@rs-ui/views/ImageViewerView3D/cornerstone/getDefaultRenderingEngine';
import ImagePixelAnalyser from './ImagePixelAnalyser';

const noiseLevelRatio = 0.2;
const noiseWeightRatio = 0.3;
const maxMedianKSize = 15;
const distTap = 15;
const distTaph = Math.floor(distTap / 2);

const ImageProcessStatusCode = {
	NonErr: 'NonErr',
	BlankImg: 'BlankImg',
	BinaryImg: 'BinaryImg',
	InfoPartErr: 'InfoPartErr',
	EndOfErr: 'EndOfErr',
};

function ApplyAutoWindowLevelToViewport(activeViewportId, needRender = true) {
	const renderingEngine = getDefaultRenderingEngine();
	const viewport = renderingEngine.getViewport(activeViewportId);
	if (!viewport) {
		return;
	}
	const calculateAutoWindowLevel = CalculateAutoWindowLevel(activeViewportId);

	if (isNaN(calculateAutoWindowLevel?.windowWidth) || isNaN(calculateAutoWindowLevel?.windowCenter)) {
		return;
	}

	const voiRange = utilities.windowLevel.toLowHighRange(
		calculateAutoWindowLevel?.windowWidth,
		calculateAutoWindowLevel?.windowCenter
	);
	viewport.setProperties({
		voiRange: voiRange,
	});

	if (needRender) {
		viewport.render();
	}
}

function CalculateAutoWindowLevel(activeViewportId) {
	const renderingEngine = getDefaultRenderingEngine();
	if (!renderingEngine) {
		return;
	}

	const viewport = renderingEngine.getViewport(activeViewportId);
	const imageId = viewport.getCurrentImageId();
	const imageObject = viewport.getCornerstoneImage() || cache.getCachedImageBasedOnImageURI(imageId)?.image;
	const metaData = cornerstoneWADOImageLoader.wadors.metaDataManager.get(imageId);

	if (!viewport || !imageObject || !metaData) {
		return;
	}

	let infostart, infoend;
	let winStart, winEnd;

	const modality = viewport.modality || metaData?.['00080060']?.Value?.[0];
	const bitsStored = imageObject.imageFrame.bitsStored || parseInt(metaData?.['00280101']?.Value[0]);

	// cannot auto-window a 1 bit image!
	if (bitsStored == 1) {
		return;
	}

	try {
		const imagePixelAnalyser = new ImagePixelAnalyser(imageObject, metaData);
		imagePixelAnalyser.initialize();

		// determine the map polarity:
		if (modality.toUpperCase() !== 'CR' && modality.toUpperCase() !== 'DX') {
			imagePixelAnalyser.isPositiveMap = true;
			imagePixelAnalyser.autoWinErrorLimit = 0.04;
		} else {
			imagePixelAnalyser.isPositiveMap = false;
			imagePixelAnalyser.autoWinErrorLimit = 0.02;
		}

		imagePixelAnalyser.getRegionStatis();
		if (imagePixelAnalyser.statusCode !== ImageProcessStatusCode.NonErr) {
			return;
		}

		if (imagePixelAnalyser.maxPixelIndex <= imagePixelAnalyser.minPixelIndex) {
			// for blank image
			return;
		}

		if (!imagePixelAnalyser.isPositiveMap) {
			imagePixelAnalyser.getFeatureWithEnergy();
		} else {
			imagePixelAnalyser.smoothDistribution();
			if (imagePixelAnalyser.statusCode !== ImageProcessStatusCode.NonErr) {
				return;
			}
			if (
				imagePixelAnalyser.feature[imagePixelAnalyser.maxPixelIndex] ===
				imagePixelAnalyser.feature[imagePixelAnalyser.minPixelIndex + 1]
			) {
				// for binary image:
				return {
					windowWidth:
						imagePixelAnalyser.modalityMap(
							imagePixelAnalyser.maxPixelIndex + imagePixelAnalyser.minPVIndex
						) -
						imagePixelAnalyser.modalityMap(
							imagePixelAnalyser.minPixelIndex + imagePixelAnalyser.minPVIndex
						) +
						1,
					windowCenter: imagePixelAnalyser.modalityMap(
						(imagePixelAnalyser.minPixelIndex + imagePixelAnalyser.maxPixelIndex) / 2 +
							imagePixelAnalyser.minPVIndex
					),
				};
			}
		}

		// compute the optimal window position (the effective image information):
		({ wstart: infostart, wend: infoend } = imagePixelAnalyser.getFeatureRange());

		if (imagePixelAnalyser.statusCode !== ImageProcessStatusCode.NonErr) {
			console.warn('Unable to apply auto window level. StatusCode: ' + imagePixelAnalyser.statusCode);
			return;
		}

		// compute window width and center:
		winStart = imagePixelAnalyser.modalityMap(infostart + imagePixelAnalyser.minPVIndex);
		winEnd = imagePixelAnalyser.modalityMap(infoend + imagePixelAnalyser.minPVIndex);
		return { windowWidth: Math.abs(winEnd - winStart), windowCenter: (winEnd + winStart) / 2 };
	} catch (e) {
		console.error(e);
	}
}

export { ImageProcessStatusCode, CalculateAutoWindowLevel, ApplyAutoWindowLevelToViewport };
