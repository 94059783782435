// React Libraries
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Material UI Libraries
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ListItemText from '@mui/material/ListItemText';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import Input from '@mui/material/Input';

// Custom Component Libraries
import Filter from './Filter';

const StyledListItem = styled(props => <ListItem {...props} />)(() => ({
	color: '#000',
	background: '#EFF3FF',
	border: '1px solid rgba(255, 255, 255, 0.2)',
	borderRadius: '5px',
	transition: '0.2s',
	padding: '4px 4px 4px 8px',
	cursor: 'pointer',
	'&:hover': {
		border: '1px solid #42A5F5',
	},
	svg: {
		color: '#4D79EA',
	},
	flex: '1 1 100%',
	overflow: 'hidden',
}));

const StyledListItemY = styled(props => <ListItem {...props} />)(() => ({
	color: '#4D79EA',
	background: 'transparent',
	border: '1px solid rgba(255, 255, 255, 0.2)',
	borderRadius: '5px',
	transition: '0.2s',
	padding: '4px 4px 4px 8px',
	cursor: 'pointer',
	svg: {
		color: '#4D79EA',
	},
	flex: '1 1 100%',
	overflow: 'hidden',
}));

function DropListItem({
	item,
	isYAxis,
	isXAxis,
	isFilterList,
	isMultipleList,
	updateFilters,
	removeFilter,
	edit,
	setEdit,
}) {
	const [isFilter, setFilter] = useState(false);
	const [disableInput, setDisableInput] = useState(false);
	const [inputCount, setInputCount] = useState(-1);
	const [values, setValues] = useState(item.value ?? []);
	const [currValue, setCurrValue] = useState('');

	useEffect(() => {
		setValues(item.value ?? []);
		setCurrValue('');
	}, [item]);

	const onRemoveFilter = useCallback(() => {
		removeFilter(item.name);
	}, [item, removeFilter]);

	const updateFilterValueDebounce = useCallback(
		_.debounce(newValues => {
			updateFilters(item, newValues, null);
		}, 1000),
		[item, updateFilters]
	);

	const handleKeyUp = useCallback(
		e => {
			if (e.keyCode == 13) {
				const oldValues = values;
				setValues([...oldValues, e.target.value]);
				setCurrValue('');
				if (inputCount !== -1 && oldValues.length + 1 === inputCount) {
					setDisableInput(true);
				}
			}
			updateFilterValueDebounce([...values, e.target.value]);
		},
		[values, inputCount, updateFilterValueDebounce]
	);

	const handleChange = e => {
		setCurrValue(e.target.value);
	};

	const handleDelete = useCallback(
		index => {
			const arr = [...values];
			arr.splice(index, 1);
			setValues(arr);
			setDisableInput(false);
			updateFilters(item, arr, null);
		},
		[values, item, updateFilters]
	);

	const updateFilterOperator = useCallback((operatorName, unselect, inputDisable, inputNumber, initialize) => {
		setDisableInput(inputDisable);
		setInputCount(inputNumber);
		if (!initialize) {
			setValues([]);
			setCurrValue('');
		}
		updateFilters(item, initialize ? null : [], unselect ? '' : operatorName);
	});

	return (
		<ClickAwayListener onClickAway={() => setFilter(false)}>
			<Box
				sx={{
					position: 'relative',
					width: '100%',
					marginTop: '10px',
					'&:first-of-type': {
						marginTop: '0',
					},
				}}
			>
				{isFilterList ? (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							flexBasis: '100%',
							gap: '4px',
						}}
					>
						<StyledListItem>
							<ListItemText
								sx={{
									width: '100%',
									marginRight: '10px',
									marginLeft: '10px',
								}}
							>
								<Tooltip title={item.title?.replace(`${item.fieldName} `, '')}>
									<Typography
										sx={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											textAlign: 'left',
										}}
									>
										{item.title?.replace(`${item.fieldName} `, '')}
									</Typography>
								</Tooltip>
							</ListItemText>
							{isFilterList && !edit && !isMultipleList && (
								<EditOutlinedIcon onClick={() => (isFilterList ? setEdit(true) : null)} />
							)}
							<CancelIcon
								data-testid="drop-cancel-icon"
								fontSize="small"
								onClick={() => onRemoveFilter()}
							/>
						</StyledListItem>
						{edit && (
							<StyledListItem>
								<Filter
									isFilterList={isFilterList}
									isXAxis={isXAxis}
									isYAxis={isYAxis}
									member={item}
									updateFilters={updateFilterOperator}
								/>
							</StyledListItem>
						)}
						{edit && (
							<StyledListItem>
								<FormControl>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											{values.map((chipItem, index) => (
												<Chip
													key={`inputchip-${index}`}
													label={chipItem}
													onDelete={() => handleDelete(index)}
												/>
											))}
										</Box>
										<Input
											disableUnderline
											disabled={disableInput}
											type={item.type === 'number' ? 'number' : 'text'}
											value={currValue}
											onChange={handleChange}
											onKeyUp={handleKeyUp}
										/>
									</Box>
								</FormControl>
							</StyledListItem>
						)}
					</Box>
				) : (
					<div>
						<StyledListItemY>
							<ListItemText>
								<Typography
									sx={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										display: 'block',
										textAlign: 'center',
									}}
								>
									{item.title?.replace(`${item.fieldName} `, '')}
								</Typography>
							</ListItemText>
							{isFilterList && (
								<FilterAltIcon onClick={() => (isFilterList ? setFilter(!isFilter) : null)} />
							)}
						</StyledListItemY>
					</div>
				)}
			</Box>
		</ClickAwayListener>
	);
}

DropListItem.propTypes = {
	/**
	 * index is number of filter item
	 */
	index: PropTypes.number,
	/**
	 * item is list item object that holds name and id
	 */
	item: PropTypes.object.isRequired,
	/**
	 * isYAxis is boolean value to identify yAxis filter
	 */
	isYAxis: PropTypes.bool,
	/**
	 * isXAxis is boolean value to identify isXAxis filter
	 */
	isXAxis: PropTypes.bool,
	/**
	 * isFilterList is boolean value to identify is it filter list or chart axis
	 */
	isFilterList: PropTypes.bool,
	/**
	 * idEdit is boolean that determine item should be editable or not
	 */
	idEdit: PropTypes.bool,
	/**
	 * removeFilterItem is a func to remove array item
	 */
	removeFilterItem: PropTypes.func,
};

export default DropListItem;
