import React from 'react';

const GlobalSearchIcon = () => (
	<svg fill="none" height={24} width={24} xmlns="http://www.w3.org/2000/svg">
		<mask
			height={24}
			id="a"
			maskUnits="userSpaceOnUse"
			style={{
				maskType: 'alpha',
			}}
			width={24}
			x={0}
			y={0}
		>
			<path d="M0 0h24v24H0z" fill="#D9D9D9" />
		</mask>
		<g mask="url(#a)">
			<path
				d="m19.6 21-6.3-6.3A6.096 6.096 0 0 1 9.5 16c-1.817 0-3.354-.63-4.612-1.887C3.629 12.854 3 11.317 3 9.5c0-1.817.63-3.354 1.888-4.612C6.146 3.629 7.683 3 9.5 3c1.817 0 3.354.63 4.613 1.888C15.37 6.146 16 7.683 16 9.5a6.096 6.096 0 0 1-1.3 3.8l6.3 6.3-1.4 1.4ZM9.5 14c1.25 0 2.313-.438 3.188-1.313C13.562 11.813 14 10.75 14 9.5c0-1.25-.438-2.313-1.313-3.188C11.813 5.438 10.75 5 9.5 5c-1.25 0-2.313.438-3.188 1.313S5 8.25 5 9.5c0 1.25.438 2.313 1.313 3.188C7.188 13.562 8.25 14 9.5 14Z"
				fill="#fff"
				fillOpacity={0.6}
			/>
		</g>
	</svg>
);

export default GlobalSearchIcon;
