import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { useHelpCenter } from '@worklist-2/core/src';
import React from 'react';
import HelpInput from './HelpInput';
import { BackDrop, boxStyle, header, mainBox } from './helpStyles';

const HelpModal = ({ open, onClose }) => {
	const { setView } = useHelpCenter();

	const handleClose = e => {
		e.preventDefault();
		setView('FAQs');
		localStorage.setItem('keyword', '');
		onClose();
	};

	return (
		<Modal
			BackdropComponent={() => (
				<BackDrop>
					<HelpModalContent handleClose={handleClose} />
				</BackDrop>
			)}
			open={open}
			onClose={handleClose}
		>
			<></>
		</Modal>
	);
};

export default HelpModal;

export const HelpModalContent = ({ handleClose }) => {
	const { HelpComponent, setView } = useHelpCenter();
	return (
		<Box sx={mainBox}>
			{handleClose && (
				<IconButton id="close-btn" sx={{ position: 'absolute', top: 10, right: 20 }} onClick={handleClose}>
					<CloseIcon sx={{ color: '#000' }} />
				</IconButton>
			)}
			<Box sx={boxStyle}>
				<Typography sx={header} variant="h3">
					How Can We Help?
				</Typography>
				<HelpInput />
				<HelpComponent setView={setView} />
			</Box>
		</Box>
	);
};
