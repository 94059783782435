import React from 'react';

const ClearFilterIcon = () => (
	<svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
		<path
			clipRule="evenodd"
			d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM15.6587 5.1665L16.8337 6.3415L12.1753 10.9998L16.8337 15.6582L15.6587 16.8332L11.0003 12.1748L6.34199 16.8332L5.16699 15.6582L9.82533 10.9998L5.16699 6.3415L6.34199 5.1665L11.0003 9.82484L15.6587 5.1665Z"
			fill="#4D79EA"
			fillRule="evenodd"
		/>
	</svg>
);

export default ClearFilterIcon;
