// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZbXNomeT8QVjeCHYlSy6{display:flex;flex-direction:column;justify-content:center;align-items:center;width:360px;height:195px;padding:20px 50px;margin:10px 10px 0 0;gap:10px;border-radius:10px;background:#393939}.ZbXNomeT8QVjeCHYlSy6 .JrXWj8tZHg9cgArASIXA{display:flex;justify-content:center;align-items:center}.ZbXNomeT8QVjeCHYlSy6 .JrXWj8tZHg9cgArASIXA span{color:#fff;text-align:center;font-family:Roboto,serif;font-size:18px;font-style:normal;font-weight:500;line-height:normal}.ZbXNomeT8QVjeCHYlSy6 button{width:100%;height:50px;border-radius:5px;opacity:.5;background:#42a5f5;box-shadow:0 4px 4px 0 rgba(0,0,0,.25)}.ZbXNomeT8QVjeCHYlSy6 button span{color:#fff;text-align:center;font-family:Roboto,serif;font-size:14px;font-style:normal;font-weight:500;line-height:normal;letter-spacing:1.25px;text-transform:uppercase}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/Layout/ChatBlock/ChatBlock.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA,CACA,oBAAA,CACA,QAAA,CAEA,kBAAA,CACA,kBAAA,CAEA,4CACE,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,iDACE,UAAA,CAEA,iBAAA,CACA,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CAIJ,6BACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,UAAA,CACA,kBAAA,CACA,sCAAA,CAEA,kCACE,UAAA,CACA,iBAAA,CACA,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,qBAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatBlock": "ZbXNomeT8QVjeCHYlSy6",
	"chatBlockText": "JrXWj8tZHg9cgArASIXA"
};
export default ___CSS_LOADER_EXPORT___;
