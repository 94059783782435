import React from 'react';

const AlignLeftIcon = () => (
	<svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_5211_59778)">
			<path
				d="M0.666667 3.99983H15.3333C15.5101 3.99983 15.6797 3.9296 15.8047 3.80457C15.9298 3.67955 16 3.50998 16 3.33317C16 3.15636 15.9298 2.98679 15.8047 2.86177C15.6797 2.73674 15.5101 2.6665 15.3333 2.6665H0.666667C0.489856 2.6665 0.320286 2.73674 0.195262 2.86177C0.0702379 2.98679 0 3.15636 0 3.33317C0 3.50998 0.0702379 3.67955 0.195262 3.80457C0.320286 3.9296 0.489856 3.99983 0.666667 3.99983Z"
				fill="white"
			/>
			<path
				d="M0.666667 7.33333H10C10.1768 7.33333 10.3464 7.26309 10.4714 7.13807C10.5964 7.01304 10.6667 6.84348 10.6667 6.66667C10.6667 6.48985 10.5964 6.32029 10.4714 6.19526C10.3464 6.07024 10.1768 6 10 6H0.666667C0.489856 6 0.320286 6.07024 0.195262 6.19526C0.0702379 6.32029 0 6.48985 0 6.66667C0 6.84348 0.0702379 7.01304 0.195262 7.13807C0.320286 7.26309 0.489856 7.33333 0.666667 7.33333Z"
				fill="white"
			/>
			<path
				d="M10 12.6665H0.666667C0.489856 12.6665 0.320286 12.7367 0.195262 12.8618C0.0702379 12.9868 0 13.1564 0 13.3332C0 13.51 0.0702379 13.6796 0.195262 13.8046C0.320286 13.9296 0.489856 13.9998 0.666667 13.9998H10C10.1768 13.9998 10.3464 13.9296 10.4714 13.8046C10.5964 13.6796 10.6667 13.51 10.6667 13.3332C10.6667 13.1564 10.5964 12.9868 10.4714 12.8618C10.3464 12.7367 10.1768 12.6665 10 12.6665Z"
				fill="white"
			/>
			<path
				d="M15.3333 9.3335H0.666667C0.489856 9.3335 0.320286 9.40373 0.195262 9.52876C0.0702379 9.65378 0 9.82335 0 10.0002C0 10.177 0.0702379 10.3465 0.195262 10.4716C0.320286 10.5966 0.489856 10.6668 0.666667 10.6668H15.3333C15.5101 10.6668 15.6797 10.5966 15.8047 10.4716C15.9298 10.3465 16 10.177 16 10.0002C16 9.82335 15.9298 9.65378 15.8047 9.52876C15.6797 9.40373 15.5101 9.3335 15.3333 9.3335Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_5211_59778">
				<rect fill="white" height="16" width="16" />
			</clipPath>
		</defs>
	</svg>
);

export default AlignLeftIcon;
