import React, { useState } from 'react';

import { Box, Typography } from '@mui/material';
import { DeviceHubOutlined } from '@mui/icons-material';

import createStyles from './styles';

const StationItem = ({ associatedDevice, stationData, onStationOpen, currentStation, setCurrentStation, location }) => {
	const [isHover, setIsHover] = useState(false);

	const isCurrentStation = currentStation?.id === stationData.id;

	const classes = createStyles(false, isHover, isCurrentStation);

	const stationName = stationData.distinctIdentifier;
	const stationStatus = stationData.status;

	const handleEditStation = e => {
		if (location === 'accountFullScreen') {
			onStationOpen(e, 'edit', stationData, associatedDevice);
		} else {
			onStationOpen({ station: stationData, associatedDevice, open: true, mode: 'edit' });
		}
	};

	const onStation = () => {
		if (isCurrentStation) {
			setCurrentStation({});
		} else {
			setCurrentStation(stationData);
		}
	};

	return (
		<Box
			sx={classes.stationItemBox}
			onClick={onStation}
			onMouseLeave={() => setIsHover(false)}
			onMouseOver={() => setIsHover(true)}
		>
			<Box sx={classes.stationItemBoxLeft}>
				<Box sx={classes.deviceHubBoxSmall}>
					<DeviceHubOutlined sx={classes.deviceHub} />
				</Box>
				<Box sx={classes.stationItemTitleBox}>
					<Typography sx={classes.stationItemTitle}>{stationName}</Typography>
					<Box sx={classes.deviceItemStatusBox}>
						<Box sx={classes.deviceItemStatusIndicator} />
						<Typography sx={classes.deviceItemStatusLabel}>
							{stationStatus.charAt(0).toUpperCase() + stationStatus.slice(1)}
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box sx={classes.stationItemBoxRight}>
				{isHover ? (
					<Box data-testid="edit-arrow" sx={classes.deviceItemArrow} onClick={handleEditStation}>
						<svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M0.666585 6.66536L8.77992 6.66536L5.05325 10.392L5.99992 11.332L11.3333 5.9987L5.99992 0.665364L5.05992 1.60536L8.77992 5.33203L0.666585 5.33203L0.666585 6.66536Z"
								fill="#4D79EA"
							/>
						</svg>
					</Box>
				) : (
					''
				)}
			</Box>
		</Box>
	);
};

export default StationItem;
