import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import NoSeriesPlaceholder from '../../../assets/img/no-series-placeholder.svg';
import { useTranslation } from 'react-i18next';
import { useImageViewerView3DContext } from '../contexts/ImageViewerView3DContext';
import { useImageViewerLayoutContext } from '../contexts/ImageViewerLayoutContext';

const ImageViewerEmptyViewport = ({ viewport, isExternal: isExternalProp }) => {
	const { t } = useTranslation('imageViewer');

	const { isFullScreen } = useImageViewerView3DContext();
	const { activeViewportId } = useImageViewerLayoutContext();

	const [searchParams] = useSearchParams();

	const id = viewport?.id;
	const isActive = activeViewportId === id;

	const isExternal = useMemo(() => {
		if (isExternalProp) {
			return isExternalProp;
		}
		const isBlume = searchParams.get('isBlume') === 'true';
		return isBlume;
	}, [isExternalProp, searchParams]);

	return (
		<Box
			sx={{
				flex: '1',
				position: 'relative',
				boxSizing: 'border-box',
				borderRadius: isFullScreen ? undefined : '10px',
				height: '100%',
				borderTop: '1px solid rgba(255, 255, 255, 0.06)',
				background: '#000',
				border: isActive ? '1px solid #42A5F5' : '1px solid #000',
				display: 'grid',
				placeItems: 'center',
				overflow: 'hidden',
			}}
		>
			{isExternal ? (
				<Stack alignItems="center" boxSizing="border-box">
					<NoSeriesPlaceholder height="80" />
					<Typography gutterBottom color="white" fontSize={20} mt="20px" textAlign="center" variant="h5">
						{t('viewport.No Image found with the Selected Viewcode')}
					</Typography>
					<Typography color="rgba(255, 255, 255, 0.6)" variant="body1">
						{t('viewport.Please check back later')}
					</Typography>
				</Stack>
			) : (
				''
			)}
		</Box>
	);
};

export default ImageViewerEmptyViewport;
