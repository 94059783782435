import { IconButton, Select, Typography, ClickAwayListener } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
	MenuItemOnTrack,
	MenuItemOffTrack,
	MenuItemDelayed,
	MenuItemAcrhived,
	BoxObjectiveOKR,
	TextFieldObjectiveOKR,
	WrapperStack,
	AutocompleteAssigner,
} from './OKRstyles';
import AccountCircle from '@mui/icons-material/AccountCircleOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useDrag, useDrop } from 'react-dnd';
import { crmSearchScopes, useCRMDataLoader, useAuth } from '@worklist-2/core/src';

function AccordionCard({
	index,
	data,
	description,
	objective,
	status,
	userName,
	progress,
	selectedOwners,
	onEditIdeaOwner,
	updateData,
	onEditIdea,
	onMouseOver,
	onMouseLeave,
	isActive,
	keyResultIndex,
	onEditIdeaStatus,
	getStyleByStatus,
	ObjectiveIndex,
	moveIdeaListItem,
}) {
	const [isInput, toggleInput] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState(status);
	const [value, setValue] = useState(description || '');
	const [statusBgColor, setStatusBgColor] = useState('');
	const [statusfontColor, setStatusFontColor] = useState('');
	const [isAssignerMenuOpen, setIsAssignerMenuOpen] = useState(false);
	const okrDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.okrApi,
	});

	const { loggedInUser } = useAuth();

	const handleIdeaDelete = () => {
		onEditIdea(index, '', true);
	};

	const handleStatusChange = e => {
		const _status = e.target.value;
		setSelectedStatus(_status);

		onEditIdeaStatus(index, _status);
	};

	useEffect(() => {
		if (objective?.KeyResults[keyResultIndex]?.Ideas[index]?.Idea?.length === 0) {
			handleEmptyContainerClick();
		} else {
			toggleInput(false);
		}
		const statusStyle = getStyleByStatus(data.Status);
		setStatusBgColor(statusStyle.bgColor);
		setStatusFontColor(statusStyle.fontColor);
	}, [objective, selectedStatus, data]);

	const handleEmptyContainerClick = () => {
		if (loggedInUser?.permission?.OKR?.Edit) {
			setValue(description || '');
			toggleInput(true);
		}
	};

	const handleEditIdeasUser = e => {
		const _owner = e;
		onEditIdeaOwner(index, _owner);

		if (e) {
			setIsAssignerMenuOpen(false);
		}
	};

	const submitRef = useRef();

	const handleIdeaSubmit = e => {
		e.preventDefault();
		toggleInput(false);
		const ideaVal = value.trim();
		if (ideaVal) {
			onEditIdea(index, ideaVal);
			updateData(objective, objective.OKRId);
		} else if (description) {
			setValue(description);
		} else {
			handleIdeaDelete();
		}
	};

	const handleIdeaSubmitOnBlur = e => {
		const ideaVal = value.trim();
		toggleInput(false);

		if (!description) {
			if (ideaVal) {
				handleIdeaSubmit(e);
			} else {
				handleIdeaDelete();
			}
		}
	};

	// useDrag - the list item is draggable
	const [{ isDragging }, dragSource, dragPreview] = useDrag({
		type: `key${ObjectiveIndex}idea${keyResultIndex}`,
		item: { index },
		end: async () => {
			const payload = _.cloneDeep(objective);
			const refinedObjective = {
				...objective,
				KeyResults: payload.KeyResults?.map(keyResult => ({
					...keyResult,
					hovered: undefined,
					expanded: undefined,
				})),
				hovered: undefined,
				expanded: undefined,
			};
			await okrDataLoader.update(objective.id, refinedObjective);
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});
	// useDrop - the list item is also a drop area
	const [spec, dropRef] = useDrop({
		accept: `key${ObjectiveIndex}idea${keyResultIndex}`,
		hover: (item, monitor) => {
			const dragIndex = item.index;
			const hoverIndex = index;
			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;
			// if dragging down, continue only when hover is smaller than middle Y
			if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
			// if dragging up, continue only when hover is bigger than middle Y
			if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;
			moveIdeaListItem(dragIndex, hoverIndex, ObjectiveIndex, keyResultIndex);
			item.index = hoverIndex;
		},
	});
	// Join the 2 refs together into one (both draggable and can be dropped on)
	const ref = useRef(null);
	const ideaDragDropRef = dragPreview(dropRef(ref));

	return (
		<div
			ref={ideaDragDropRef}
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				width: '100%',
				marginBottom: '5px',
				height: '40px',
				position: 'relative',
			}}
			onMouseLeave={onMouseLeave}
			onMouseOver={onMouseOver}
		>
			{!!loggedInUser?.permission?.OKR?.Edit && (
				<span
					ref={dragSource}
					style={{
						position: 'absolute',
						left: '-5px',
						top: '50%',
						transform: 'translateY(-50%)',
						zIndex: '1',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<DragIndicatorIcon
						sx={{
							width: '15px',
							height: '20px',
							color: 'grey',
							opacity: '0.5',
							'&:hover': {
								opacity: '0.8',
							},
						}}
					/>
				</span>
			)}
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					width: '80%',
					height: 'inherit',
				}}
			>
				{isInput ? (
					<form onSubmit={handleIdeaSubmit}>
						<BoxObjectiveOKR>
							<TextFieldObjectiveOKR
								autoFocus
								id="idea"
								value={value}
								onBlur={handleIdeaSubmitOnBlur}
								onChange={e => setValue(e.target.value)}
								onKeyDown={e => {
									if (e.key === 'Enter' && !e.target.value) {
										e.preventDefault();
									}
								}}
							/>
							<button ref={submitRef} style={{ display: 'none' }} type="submit" />
							{description && (
								<div className="menu-btn">
									<IconButton
										className="icon-btn"
										sx={{ padding: '8px 0px 8px 0px' }}
										onMouseDown={handleIdeaSubmit}
									>
										<CheckIcon className="icon" fontSize="25px" sx={{ color: '#4D79EA' }} />
									</IconButton>
									<IconButton className="icon-btn" sx={{ padding: '7px 2px 5px 2px' }} type="button">
										<CloseIcon
											className="icon"
											fontSize="25px"
											sx={{ color: 'rgba(0, 0, 0, 0.4)' }}
											onClick={e => {
												toggleInput(false);
												if (description) {
													setValue(description);
												} else {
													handleIdeaDelete();
												}
											}}
										/>
									</IconButton>
								</div>
							)}
						</BoxObjectiveOKR>
					</form>
				) : (
					<Typography
						ml="10px"
						sx={{
							width: '100%',
							height: 'inherit',
							overflow: 'auto',
							display: 'flex',
							alignItems: 'center',
							'&::-webkit-scrollbar-thumb': {
								background: '#C4C4C4 !important',
								borderRadius: '3px',
							},
							'&::-webkit-scrollbar-track': {
								background: '#FFF !important',
							},
						}}
						onClick={handleEmptyContainerClick}
					>
						<Typography
							sx={{
								fontSize: '14px',
								fontWeight: 400,
								fontStyle: 'Inter',
								margin: 'auto 0',
							}}
						>
							{description}
						</Typography>
					</Typography>
				)}

				{!isInput && !!loggedInUser?.permission?.OKR?.Edit && (
					<IconButton sx={{ width: '30px' }} type="button" onClick={handleEmptyContainerClick}>
						{isActive && <CreateOutlinedIcon />}
					</IconButton>
				)}
			</div>
			<div
				style={{
					background: statusBgColor,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					borderRadius: '17px',
					height: '27px',
					minWidth: '90px',
					marginLeft: '5px',
					marginRight: '15px',
				}}
			>
				<Select
					IconComponent=""
					MenuProps={{
						PaperProps: {
							sx: {
								borderRadius: '8px',
								width: '102px',
								justifyContent: 'center',
								border: '1px solid #4D79EA',
							},
						},
					}}
					class="notchedOutline"
					disabled={!loggedInUser?.permission?.OKR?.Edit}
					name="status"
					sx={{
						'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
							border: 'none',
						},
						'& .MuiOutlinedInput-notchedOutline': {
							border: 'none',
						},
						'& .MuiSelect-outlined': {
							paddingRight: '16px !important',
						},
						'& .Mui-selected': {
							backgroundColor: 'transparent',
						},
						'& .MuiSelect-icon.MuiSelect-iconOutlined.css-3qbkez-MuiSelect-icon': {
							display: 'none',
						},
						'& .MuiInputBase-input.Mui-disabled': {
							WebkitTextFillColor: statusfontColor,
						},
						justifyContent: 'center',
						fontWeight: '500',
						fontSize: '12px',
						color: statusfontColor,
					}}
					value={data.Status || ''}
					onChange={handleStatusChange}
				>
					<MenuItemOnTrack value="ON TRACK">ON TRACK</MenuItemOnTrack>
					<MenuItemOffTrack value="OFF TRACK">OFF TRACK</MenuItemOffTrack>
					<MenuItemDelayed value="DELAYED">DELAYED</MenuItemDelayed>
					<MenuItemAcrhived value="ARCHIVED">ARCHIVED</MenuItemAcrhived>
				</Select>
			</div>

			<div
				style={{
					width: '28px',
					height: '22px',
					background: data.Owner ? '#FFDBF3' : '',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					padding: data.Owner ? '5px' : '2px',
					borderRadius: '50%',
					cursor: loggedInUser?.permission?.OKR?.Edit ? 'pointer' : '',
				}}
				onClick={() => {
					if (loggedInUser?.permission?.OKR?.Edit) {
						setIsAssignerMenuOpen(!isAssignerMenuOpen);
					}
				}}
			>
				{data.Owner ? (
					<div
						style={{
							color: '#D349A4',
							fontWeight: 'bold',
							fontSize: '12px',
						}}
					>
						{data.Owner.split(' ')
							.map(n => n.charAt(0).toUpperCase())
							.join('')}
					</div>
				) : (
					<AccountCircle fontSize="large" sx={{ color: 'rgba(0, 0, 0, 0.4)' }} />
				)}
			</div>
			{isAssignerMenuOpen && (
				<ClickAwayListener onClickAway={() => setIsAssignerMenuOpen(false)}>
					<div>
						<WrapperStack>
							<AutocompleteAssigner
								freeSolo
								id="assigner"
								options={_.map(selectedOwners, user => user.username)}
								size="small"
								value={data.Owner}
								onChange={(e, val) => handleEditIdeasUser(val)}
							/>
						</WrapperStack>
					</div>
				</ClickAwayListener>
			)}
			<IconButton
				disabled={!loggedInUser?.permission?.OKR?.Delete}
				sx={{ padding: '7px 5px 5px 5px', width: '30px' }}
				type="button"
				onClick={e => {
					handleIdeaDelete();
					onMouseLeave();
				}}
			>
				{isActive && !!loggedInUser?.permission?.OKR?.Delete && <DeleteOutlineIcon />}
			</IconButton>
		</div>
	);
}

export default AccordionCard;
