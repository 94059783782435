const formUtils = {

	/**
	 * checkAndResetAnotherOptions
	 * 	clear inputted value and unselected options of unchecked answers
	 * @param {*} validatedForm 
	 * @returns 
	 */
	checkAndResetAnotherOptions : (validatedForm) => {
		const questionData = validatedForm?.formTemplate?.content;
		questionData.forEach((page) => {
			page.forEach((question) => {
				// clear selected and value of unchecked answers in case save form
				if((question?.type === 'checkbox' || question?.type === 'multipleChoice') && question?.answer?.length > 0) {
					const latestAnswer = question?.answer[question?.answer?.length - 1];
					if(latestAnswer && latestAnswer.value && latestAnswer.value.length > 0) {
						latestAnswer.value.forEach(ans => {
							if (!ans.checked) {
								ans.value = '';
							}
							if(ans.options && ans.options.length > 0) {
								formUtils.resetUncheckedAnswers(ans.options, ans.checked);
							}
						});
					}
				}
			});
		});
		return validatedForm;
	},

	/**
	 * resetUncheckedAnswers
	 *  clear selected and value of unchecked answers
	 * @param {*} answerVals list of answers
	 * @param {*} isParentChecked parent answer checked status
	 */
	resetUncheckedAnswers: (answerVals, isParentChecked) => {
		if(answerVals && answerVals.length > 0) {
			answerVals.forEach((val) => {
				if(!isParentChecked || !val.checked) {
					val.checked = false;
					val.value = '';
					if(val.options && val.options.length > 0) {
						formUtils.resetUncheckedAnswers(val.options, false);
					}
				}
			});
		}
	},

	/**
	 * Validate form data
	 * @param {*} formDataObj 
	 * @param {*} isSave true if save form
	 * @returns 
	 */
	validateForm : async (formDataObj, isSave = false) => {
		const tempFormObj = { ...formDataObj };
		const questionData = tempFormObj?.formTemplate?.content;
		let hasError = false;
		questionData.forEach((page, pageIndex) => {
			page.forEach((question, questionIndex) => {
				if (question?.required) {
					const updateAnswer = questionData[pageIndex][questionIndex];
					updateAnswer.indexErrors = [];
					// check if answer present for required fields
					if (question?.answer) {
						if (
							question?.type === 'multipleChoiceGrid' ||
							question?.type === 'checkboxGrid' ||
							question?.type === 'shortAnswer'
						) {
							let subAnswerQuestions = 0;
							question?.options?.forEach((eachQ, index) => {
								let hasAnswer = false;
								if (
									question?.answer &&
									question?.answer[question?.answer?.length - 1]?.value[index]?.length > 0
								) {
									subAnswerQuestions += 1;
									hasAnswer = true;
								}

								// Index of error items in list options
								updateAnswer.indexErrors.push(hasAnswer ? null : index);
							});
							if(question?.options?.length == subAnswerQuestions) {
								delete updateAnswer?.error;
							} else {
								updateAnswer.error = true;
							}
						} else if (!question?.answer[question?.answer?.length - 1]?.value) {
							updateAnswer.error = true;
						} else if (Array.isArray(question?.answer[question?.answer?.length - 1]?.value)) {
							// Checkbox or MultipleChoice
							const isExistedAnswer = question?.answer[question?.answer?.length - 1]?.value.find(ans => ans.checked);
							if(!isExistedAnswer) {
								updateAnswer.error = true;
							} else {
								delete updateAnswer?.error;
							}
						} else {
							delete updateAnswer?.error;
						}
					} else {
						updateAnswer.error = true;
					}
					// Mark existing error
					if(updateAnswer.error) {
						hasError = true;
					}
				}
			});
		});

		// clear selected and value of unchecked answers in case save form
		if(isSave && !hasError) {
			return formUtils.checkAndResetAnotherOptions(tempFormObj);
		}
		return tempFormObj;
	}
};

export default formUtils;
