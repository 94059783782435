/* Generated automagically by FhirStarter - but please feel free to edit this file */
import React from 'react';
import ActiveIcon from '@rs-ui/components/ActiveIcon/ActiveIcon';

const IssuerMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.id;
		},
	},
	active: {
		label: 'Active',
		filterType: 'none',
		getDisplay: resource => {
			return resource.active;
		},
		options: {
			customBodyRender: value => {
				return <ActiveIcon active={!!value.props.text} />;
			},
		},
	},
	external: {
		label: 'External',
		filterType: 'none',
		options: {
			filter: false,
			sort: true,
			setCellHeaderProps: () => ({ style: { width: '6.5%' } }),
		},
		getDisplay: resource => {
			return resource.external === true ? 'Yes' : 'No';
		},
	},
	name: {
		label: 'Issuer Name',
		filterType: 'text-search',
		searchParameter: 'name',
		options: {
			filter: true,
			sort: true,
			setCellHeaderProps: () => ({ style: { width: '11%' } }),
		},
		getDisplay: resource => {
			return resource.name;
		},
	},
	managingOrganization: {
		label: 'Managing Organization',
		options: {
			filter: true,
			sort: true,
			setCellHeaderProps: () => ({ style: { width: '21%' } }),
		},
		filterType: 'suggest',
		searchParameter: 'managingorganizationid',
		suggestScope: {
			resource: 'organization',
			label: 'name',
			extraParam: {
				ismanaging: true,
				active: true,
				_summary: 'text',
				_elements: 'name',
			},
		},
		getDisplay: resource => {
			return resource.managingOrganization?.display;
		},
	},
	issuerId: {
		label: 'Issuer ID',
		filterType: 'text-search',
		searchParameter: 'issuerid',
		options: {
			filter: true,
			sort: true,
			setCellHeaderProps: () => ({ style: { width: '23%' } }),
		},
		getDisplay: resource => {
			return resource?.identifier[0]?.value;
		},
	},
	idType: {
		label: 'Issuer ID Type',
		filterType: 'text-search',
		searchParameter: 'issueridtype',
		options: { filter: true, sort: true },
		getDisplay: resource => {
			return resource?.identifier[0]?.type?.coding[0]?.code;
		},
	},
	patientIdPrefix: {
		label: 'Patient ID Prefix',
		filterType: 'none',
		getDisplay: resource => {
			return resource?.prefix_patientId;
		},
	},
	accessionNumPrefix: {
		label: 'Accession # Prefix',
		filterType: 'none',
		getDisplay: resource => {
			return resource?.prefix_accessionNumber;
		},
	},
	visitNumPrefix: {
		label: 'Visit # Prefix',
		filterType: 'none',
		getDisplay: resource => {
			return resource?.prefix_visitNumber;
		},
	},
};

export default IssuerMapping;
