//@flow
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import { Stack, Box } from '@mui/material';
import ErrorIcon from '@worklist-2/ui/src/assets/icons/errorSymbol.svg';

interface IAlertDialogProps {
	description: string;
	handleClose: () => void;
	handleSubmit: () => void;
	isBtnDisabled?: boolean;
	open: boolean;
	primaryBtnLabel: string;
	title: string;
	t?: (key: string) => string;
	isSecondaryBtnVisible?: boolean;
	sx?: Object;
}

export const AlertDialog = ({
	description,
	handleClose,
	handleSubmit,
	isBtnDisabled,
	open,
	primaryBtnLabel,
	t,
	title,
	isSecondaryBtnVisible = true,
	sx,
}: IAlertDialogProps) => (
	<Dialog
		aria-describedby="alert-dialog-description"
		aria-labelledby="alert-dialog-title"
		open={open}
		sx={{
			'& .MuiDialog-paper': {
				background: 'linear-gradient(149.96deg, #DB7083 0.32%, rgba(102, 102, 102, 0) 67.76%)',
				borderRadius: '12px',
				maxWidth: '800px',
				...sx,
			},
		}}
		onClose={handleClose}
	>
		<Box sx={{ border: '2px solid transparent', borderRadius: '12px' }}>
			<Stack direction="row" sx={SX.dialogTitleBoxStyles}>
				<ErrorIcon />
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			</Stack>
			<DialogContent sx={{ backgroundColor: '#272727' }}>
				<DialogContentText
					dangerouslySetInnerHTML={{
						__html: description,
					}}
					id="alert-dialog-description"
				/>
			</DialogContent>
			<DialogActions sx={SX.dialogActionsStyles}>
				{isSecondaryBtnVisible ? <SecondaryButton label={t('cancel')} onClick={handleClose} /> : null}
				<PrimaryButton
					disabled={isBtnDisabled}
					label={primaryBtnLabel || t('workListDrawer.delete')}
					onClick={handleSubmit}
				/>
			</DialogActions>
		</Box>
	</Dialog>
);

const SX = {
	dialogTitleBoxStyles: {
		padding: '24px 40px 20px 16px',
		border: '0px 0px 1px 0px',
		background: '#393939',
		borderBottom: '1px solid #FFFFFF33',
		maxHeight: '20px',
		alignItems: 'center',
		justifyContent: 'flex-start',
		borderRadius: '12px 12px 0px 0px',
	},
	dialogActionsStyles: {
		borderRadius: '0px 0px 6px 8px',
		padding: '16px',
		gap: '28px',
		backgroundColor: '#272727',
	},
};
