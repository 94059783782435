import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import styled from 'styled-components';

const Container = styled(ListItem)(({ theme }) => ({
	'&:hover': {
		'.MuiListItemAvatar-root': {
			visibility: 'visible',
		},
		'.MuiButtonBase-root': {
			visibility: 'visible',
		},
	},
}));

const DraggableListItem = ({ children, item, dragId, index, iconFontSize = 16 }) => (
	<Draggable key={item.id} draggableId={dragId} index={index}>
		{(provided, snapshot) => (
			<Container ref={provided.innerRef} {...provided.draggableProps} sx={{ px: 0 }}>
				<ListItemAvatar
					{...provided.dragHandleProps}
					sx={{
						minWidth: '10px',
						minHeight: '16px',
						color: 'icon.primary',
						visibility: 'hidden',
					}}
				>
					<DragIndicatorIcon
						data-cy={`dragable_${index}`}
						sx={{
							fontSize: iconFontSize,
							paddingTop: '3px',
						}}
					/>
				</ListItemAvatar>

				{children}
			</Container>
		)}
	</Draggable>
);
DraggableListItem.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element.isRequired]),

	/**
	 * draggable item details
	 */
	item: PropTypes.array,

	/**
	 * Unique id required for draggable element
	 */
	dragId: PropTypes.string.isRequired,

	/**
	 * Index value of item
	 */
	index: PropTypes.number.isRequired,

	/**
	 * Font size of Drag Icon
	 */
	iconFontSize: PropTypes.number,
};

DraggableListItem.defaultProps = {};

export default DraggableListItem;
