import React from 'react';

const TabletIcon = ({ fill }) => (
	<svg fill="none" height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_4186_16091)">
			<path
				d="M23.125 0H5.625C3.9 0 2.5 1.4 2.5 3.125V26.875C2.5 28.6 3.9 30 5.625 30H23.125C24.85 30 26.25 28.6 26.25 26.875V3.125C26.25 1.4 24.85 0 23.125 0ZM14.375 28.75C13.3375 28.75 12.5 27.9125 12.5 26.875C12.5 25.8375 13.3375 25 14.375 25C15.4125 25 16.25 25.8375 16.25 26.875C16.25 27.9125 15.4125 28.75 14.375 28.75ZM23.75 23.75H5V3.75H23.75V23.75Z"
				fill={fill || '#C4C4C4'}
			/>
		</g>
		<defs>
			<clipPath id="clip0_4186_16091">
				<rect fill="white" height="30" width="30" />
			</clipPath>
		</defs>
	</svg>
);

export default TabletIcon;
