import React from 'react';

import { ChatIcon } from '../../assets/icons';

import styles from './ChatBlock.module.scss';

const ChatBlock = () => (
	<div className={styles.chatBlock}>
		<ChatIcon dataTestId="chat-icon" />
		<div className={styles.chatBlockText}>
			<span>Still unclear on something?</span>
		</div>
		<button>
			<span>Start Chatting</span>
		</button>
	</div>
);

export default ChatBlock;
