import React from 'react';

const FolderIcon = ({ color }) => (
	<svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_5211_16772)">
			<path
				d="M15.8333 2.50016H10.3933C10.2645 2.50101 10.1371 2.47252 10.0208 2.41683L7.39083 1.09683C7.04366 0.92395 6.66117 0.833818 6.27333 0.833496H4.16667C3.062 0.834819 2.00296 1.27423 1.22185 2.05535C0.440735 2.83646 0.00132321 3.8955 0 5.00016L0 15.0002C0.00132321 16.1048 0.440735 17.1639 1.22185 17.945C2.00296 18.7261 3.062 19.1655 4.16667 19.1668H15.8333C16.938 19.1655 17.997 18.7261 18.7782 17.945C19.5593 17.1639 19.9987 16.1048 20 15.0002V6.66683C19.9987 5.56217 19.5593 4.50313 18.7782 3.72201C17.997 2.9409 16.938 2.50149 15.8333 2.50016ZM4.16667 2.50016H6.27333C6.40222 2.49931 6.5296 2.52781 6.64583 2.5835L9.27583 3.89933C9.62266 4.07365 10.0052 4.16521 10.3933 4.16683H15.8333C16.3317 4.16764 16.8184 4.31739 17.2311 4.59684C17.6437 4.87629 17.9634 5.2727 18.1492 5.73516L1.66667 5.8285V5.00016C1.66667 4.33712 1.93006 3.70124 2.3989 3.2324C2.86774 2.76356 3.50363 2.50016 4.16667 2.50016ZM15.8333 17.5002H4.16667C3.50363 17.5002 2.86774 17.2368 2.3989 16.7679C1.93006 16.2991 1.66667 15.6632 1.66667 15.0002V7.49516L18.3333 7.401V15.0002C18.3333 15.6632 18.0699 16.2991 17.6011 16.7679C17.1323 17.2368 16.4964 17.5002 15.8333 17.5002Z"
				fill={color}
			/>
		</g>
		<defs>
			<clipPath id="clip0_5211_16772">
				<rect fill={color} height="20" width="20" />
			</clipPath>
		</defs>
	</svg>
);

export default FolderIcon;
