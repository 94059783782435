import React from 'react';
import ImportDispatcher from './ImportDispatcher';
import { useConfig, useAuth } from '@worklist-2/core/src';
import { useMsal } from '@azure/msal-react';

export function useImportDispatcher(
	studyDataLoader,
	patientDataLoader,
	accessToken,
	sessionId,
	coordinatorWorkerURL = null
) {
	const dispatcherObject = {};
	const { instance } = useMsal();
	const { updateAccessToken } = useAuth();
	const __config = useConfig();

	if (!dispatcherObject.ImportDispatcher) {
		dispatcherObject.ImportDispatcher = new ImportDispatcher(
			studyDataLoader,
			patientDataLoader,
			accessToken,
			sessionId,
			coordinatorWorkerURL,
			instance,
			__config,
			updateAccessToken
		);
		dispatcherObject.importFiles = async (nodeList, patientDataLoader, studyDataLoader, url) => {
			await dispatcherObject.ImportDispatcher.importFiles(nodeList, patientDataLoader, studyDataLoader, url);
		};
		dispatcherObject.importNonDicomFiles = async (nodeList, url, payload) => {
			await dispatcherObject.ImportDispatcher.importNonDicomFiles(nodeList, url, payload);
		};
		dispatcherObject.importBlumeDicomFiles = async (nodeList, url, payload) => {
			await dispatcherObject.ImportDispatcher.importBlumeDicomFiles(nodeList, url, payload);
		};
		dispatcherObject.stopImport = () => {
			dispatcherObject.ImportDispatcher.stopImport();
		};

		dispatcherObject.getTotalProgressBar = () => {
			return dispatcherObject.ImportDispatcher.progress;
		};

		dispatcherObject.getFilesUploadProgress = () => {
			return dispatcherObject.ImportDispatcher.uploadProgress;
		};

		dispatcherObject.getImportStatus = () => {
			return dispatcherObject.ImportDispatcher.importing;
		};

		dispatcherObject.getErrorMsg = () => {
			return dispatcherObject.ImportDispatcher.errorMessage;
		};

		dispatcherObject.getFileUploadStatus = () => {
			return dispatcherObject.ImportDispatcher.uploadStatus;
		};
		dispatcherObject.getFileUploadProgress = () => {
			return dispatcherObject.ImportDispatcher.uploadProgress;
		};
		dispatcherObject.pauseFileUpload = fileName => {
			return dispatcherObject.ImportDispatcher.pauseFileUpload(fileName);
		};
		dispatcherObject.resumeFileUpload = fileName => {
			return dispatcherObject.ImportDispatcher.resumeFileUpload(fileName);
		};
		dispatcherObject.cancelFileUpload = fileName => {
			return dispatcherObject.ImportDispatcher.cancelFileUpload(fileName);
		};
		dispatcherObject.restartFailedUpload = fileName => {
			return dispatcherObject.ImportDispatcher.restartFailedUpload(fileName);
		};
	}
	return dispatcherObject;
}
