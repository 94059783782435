import React, { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

const useLongPress = callback => {
	const [value, setValue] = useState(0);

	const [startLongPress, setStartLongPress] = useState(false);

	useEffect(() => {
		if (startLongPress) {
			setValue(100);
		} else {
			setValue(0);
		}

		let timerId;

		if (startLongPress) {
			timerId = setTimeout(callback, 1000);
		} else {
			clearTimeout(timerId);
		}

		return () => {
			clearTimeout(timerId);
		};
	}, [startLongPress]);

	const start = e => {
		e.stopPropagation();
		setStartLongPress(true);
	};

	const stop = e => {
		e.stopPropagation();
		setStartLongPress(false);
	};

	return {
		value,
		onMouseDown: start,
		onMouseUp: stop,
		onMouseLeave: stop,
		onTouchStart: start,
		onTouchEnd: stop,
	};
};

const HoldToDelete = ({
	tooltipText,
	backgroundColor,
	className,
	iconColor,
	Icon,
	height,
	width,
	iconStyles,
	btnStyles,
	progressStyles,
	backgroundStyles,
	sx = {},
	onDelete,
}) => {
	const { value, ...longPressProps } = useLongPress(onDelete);
	const { t } = useTranslation('organization');

	return (
		<Tooltip data-testid="hold-to-delete-tooltip" placement="top" title={t(tooltipText)}>
			<IconButton
				className={className}
				sx={{
					width: width || '40px',
					height: height || '40px',
					backgroundColor,
					overflow: 'hidden',
					...btnStyles,
					...sx,
				}}
				onClick={e => e.stopPropagation()}
				{...longPressProps}
			>
				<Box
					sx={{
						width: '30px',
						height: '30px',
						borderRadius: '100%',
						cursor: 'pointer',
						color: '#CF6679',
						display: 'grid',
						placeItems: 'center',
						...sx,
						'& .MuiLinearProgress-root': {
							backgroundColor: 'transparent',
						},
						'& .MuiLinearProgress-bar': { backgroundColor: '#CF6679' },
						...backgroundStyles,
					}}
				>
					<LinearProgress
						style={{
							width: '31px',
							height: '31px',
							borderRadius: '100%',
							cursor: 'pointer',
							rotate: '-90deg',
							color: '#CF6679',
							...progressStyles,
						}}
						value={value}
						variant="determinate"
					/>
					<Icon
						sx={{
							position: 'relative',
							verticalAlign: 'centre',
							width: '20px',
							height: '30px',
							top: '-30px',
							color: iconColor,
							...iconStyles,
						}}
					/>
				</Box>
			</IconButton>
		</Tooltip>
	);
};

HoldToDelete.defaultProps = {
	tooltipText: 'Hold to Delete',
	iconColor: 'white',
	backgroundColor: 'transparent',
	Icon: DeleteOutlineIcon,
	sx: {},
};

export default HoldToDelete;
