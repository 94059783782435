import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavButton } from '../SideBarButton';
import { Svg } from '@worklist-2/ui/src/components/Svg';
import Divider from '@mui/material/Divider';

const SideBarNav = ({ onClick, className, activeClassName, buttons }) => (
	<ul>
		{buttons.map(btn => (
			<React.Fragment key={btn.path}>
				{btn.path === 'teaching-folder' && <Divider sx={{ width: 'auto!important' }} />}
				<NavButton
					activeClassName={activeClassName}
					ariaLabel={`${btn.text} button`}
					className={classnames(`nav-link-icon-${btn.path}`, className)}
					icon={<Svg className={classnames(`nav-icon`, className)} htmlColor="#ffffff99;" name={btn.icon} />}
					path={btn.path}
					text={btn.text || ''}
					tooltipTxt={btn.tooltipTxt}
					onClick={onClick}
				/>
			</React.Fragment>
		))}
	</ul>
);

SideBarNav.propTypes = {
	activeClassName: PropTypes.string,
	onClick: PropTypes.func,
	className: PropTypes.string,
	buttons: PropTypes.arrayOf(
		PropTypes.shape({
			path: PropTypes.string.isRequired,
			text: PropTypes.string,
			icon: PropTypes.string,
			tooltipTxt: PropTypes.string,
		})
	).isRequired,
};

SideBarNav.defaultProps = {
	activeClassName: '',
	onClick: () => {},
};

export default SideBarNav;
