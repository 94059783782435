import React, { useState } from 'react';
import Box from '@mui/material/Box';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import Grid from '@mui/material/Grid';
import OrganizationDialog from './OrganizationDialog';

function Templates() {
	const [isOrganization, setIsOrganization] = useState(false);
	const [isExtended, setIsExtended] = useState(false);

	return (
		<Box
			sx={{
				width: isExtended ? 840 : 470,
				height: isExtended ? '94%' : 380,
				background: 'rgba(189, 211, 231, 0.17)',
				borderRadius: '15px',
				position: 'relative',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					padding: '16px 20px',
				}}
			>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Box
						sx={{
							width: '46px',
							height: '46px',
							background: 'rgba(145, 128, 247, 0.29)',
							borderRadius: '100px',
							color: '#715EE1',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<HomeRepairServiceIcon sx={{ fontSize: '20px' }} />
					</Box>
					<Typography fontSize={20} marginLeft={1.5} sx={{ color: '#000000' }}>
						Template
					</Typography>
				</Box>
				<Button
					data-testid="change-extended"
					sx={{
						minWidth: '30px',
						height: '30px',
						background: '#FFF',
						borderRadius: '100px',
						color: 'rgba(255, 255, 255, 0.6)',
					}}
					onClick={() => setIsExtended(!isExtended)}
				>
					{!isExtended ? (
						<OpenInFullIcon data-testid="open-full-icon" sx={{ fontSize: '16px', color: '#4D79EA' }} />
					) : (
						<CloseFullscreenIcon
							data-testid="close-full-icon"
							sx={{ fontSize: '16px', color: '#4D79EA' }}
						/>
					)}
				</Button>
			</Box>

			<Grid
				container
				spacing={isExtended ? 4 : 2}
				sx={{
					padding: '18px 66px 66px 66px',
					overflowY: isExtended && 'scroll',
					maxHeight: '92%',
				}}
			>
				{Array(6)
					.fill(2)
					.map(i => (
						<Grid item xs={isExtended ? 6 : 4}>
							<Box
								sx={{
									width: isExtended ? '100%' : 100,
									height: isExtended ? 290 : 100,
									background: '#F9FAFF',
									borderRadius: '10px',
									cursor: 'pointer',
									border: '1px solid transparent',
									transition: '0.2s',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									'&:hover': {
										borderColor: '#42A5F5',
										'.useBtn': {
											display: 'flex',
										},
									},
								}}
								onClick={() => setIsOrganization(true)}
							>
								<Button
									className="useBtn"
									sx={{
										width: '55px',
										height: '36px',
										background: '#3588CB',
										borderRadius: '6px',
										color: '#fff',
										display: 'none',
										'&:hover': {
											background: '#2e9df8',
										},
									}}
								>
									USE
								</Button>
							</Box>

							{isExtended && (
								<Typography
									mt={1}
									sx={{
										color: 'rgba(0, 0, 0, 0.6)',
										textTransform: 'Uppercase',
									}}
								>
									Template Name
								</Typography>
							)}
						</Grid>
					))}
			</Grid>

			{isOrganization && (
				<Box
					sx={{
						background: '#EFF3F9',
						position: 'absolute',
						left: '50%',
						top: '50%',
						transform: 'translate(-50%, -50%)',
						width: 440,
						border: '1px solid #42A5F5',
						borderRadius: '15px',
					}}
				>
					<OrganizationDialog isFullHeight setIsOrganization={setIsOrganization} />
				</Box>
			)}
		</Box>
	);
}

export default Templates;
