import React from 'react';

const InventoryIcon = () => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_4186_16109)">
			<path
				d="M20 2H4C3 2 2 2.9 2 4V7.01C2 7.73 2.43 8.35 3 8.7V20C3 21.1 4.1 22 5 22H19C19.9 22 21 21.1 21 20V8.7C21.57 8.35 22 7.73 22 7.01V4C22 2.9 21 2 20 2ZM19 20H5V9H19V20ZM20 7H4V4H20V7Z"
				fill="#C4C4C4"
			/>
			<path d="M15 12H9V14H15V12Z" fill="#C4C4C4" />
		</g>
		<defs>
			<clipPath id="clip0_4186_16109">
				<rect fill="white" height="24" width="24" />
			</clipPath>
		</defs>
	</svg>
);

export default InventoryIcon;
