import React from 'react';
import Box from '@mui/material/Box';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import FormAPIAutocompleteVariant from './FormAPIAutocompleteVariant';
import { useAppModeContext } from '@worklist-2/core/src/context/AppModeContext';
import useGoogleApi from '@rs-ui/components/FormNew/formHooks/useGoogleApi';
import searchIconAdornment from '@rs-ui/components/FormNew/formUtils/formMappingUtils';

const renderOption = (props, option) => {
	const { key, ...optionProps } = props;
	const matches = option.structured_formatting.main_text_matched_substrings || [];
	const parts = parse(
		option.structured_formatting.main_text,
		matches.map(match => [match.offset, match.offset + match.length])
	);

	return (
		<li key={key} {...optionProps}>
			<Grid container sx={{ alignItems: 'center' }}>
				<Grid item sx={{ display: 'flex', width: 44 }}>
					<LocationOnIcon sx={{ color: 'text.secondary' }} />
				</Grid>
				<Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
					{parts.map((part, index) => (
						<Box key={index} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
							{part.text}
						</Box>
					))}
					<Typography color="text.secondary" variant="body2">
						{option.structured_formatting.secondary_text}
					</Typography>
				</Grid>
			</Grid>
		</li>
	);
};

const FormGoogleLocationVariant = ({ formHook, name, props }) => {
	const { setValue } = formHook;
	const { isWorklistMode } = useAppModeContext();
	const { getSearchPlaces, getDetailedPlaceInfo } = useGoogleApi();

	const setAddressValues = selectedPlace => {
		const options = {
			shouldValidate: true,
			shouldDirty: true,
		};
		setValue('address.0.city', selectedPlace.locality, options);
		setValue('address.0.state', selectedPlace.state, options);
		setValue('address.0.postalCode', selectedPlace.postcode, options);
		setValue('address.0.country', selectedPlace.country, options);
		setValue('address.0.latitude', selectedPlace.latitude, options);
		setValue('address.0.longitude', selectedPlace.longitude, options);
	};

	const modifySelectedValue = async (value, reason, oldValue) => {
		if (value && reason === 'selectOption' && isWorklistMode()) {
			const selectedPlace = await getDetailedPlaceInfo(value);
			if (selectedPlace && Object.keys(selectedPlace).length > 0) {
				setAddressValues(selectedPlace);
				return selectedPlace?.address1;
			}
			return value.description;
		}
	};

	return (
		<FormAPIAutocompleteVariant
			TextFieldProps={{
				label: props?.label,
				required: props?.required,
				placeholder: props?.placeholder,
				...searchIconAdornment,
			}}
			formHook={formHook}
			modifySelectedValue={modifySelectedValue}
			name={name}
			props={{
				disabled: props?.disabled,
				freeSolo: true,
				getOptionLabel: option => (typeof option === 'string' ? option : option.description),
				filterOptions: x => x,
				renderOption,
			}}
			onSearch={getSearchPlaces}
		/>
	);
};

export default FormGoogleLocationVariant;
