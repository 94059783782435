import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
	LinkButton,
	OutlineWrapper,
	RoleSideWidget,
	SmallCircle,
	SubTitle,
	Title,
} from '../../../views/CrmView/styles';
import { TemMembersAvatars } from './TeamCard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LastPageIcon from '@mui/icons-material/LastPage';
import SwitchButton from './SwitchButton';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import PermissionListItem from './PermissionListItem';
import _ from 'lodash';
import { useCrmContext } from '../../../context/CRMContext';
import { crmSearchScopes, useCRMDataLoader, useAuth } from '@worklist-2/core/src';
import { TextField } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const RoleEditor = forwardRef((props, ref) => {
	const [visibility, toggleVisibility] = useState(false);
	const [role, setRole] = useState({});
	const { toggleWidget, roles, setRoles } = useCrmContext();

	useImperativeHandle(ref, () => ({
		open: data => {
			setRole(data);
			toggleVisibility(true);
		},
		close: () => {
			toggleVisibility(false);
		},
	}));

	const onClose = () => {
		toggleVisibility(false);
		toggleWidget('editRole', null);
	};

	return (
		visibility && (
			<RoleEditorWidget role={role} roles={roles} setRole={setRole} setRoles={setRoles} onClose={onClose} />
		)
	);
});

const RoleEditorWidget = ({ onClose, role, setRole, roles, setRoles }) => {
	const [active, setActive] = useState(false);
	const [isOpen, setIsOpen] = useState(true);
	const [isUpdating, setIsUpdating] = useState(false);
	const [editableIndex, setEditableIndex] = useState(null);
	const [hoveredField, setHoveredField] = useState(null);

	const [roleName, setRoleName] = useState(role.RoleName);
	const [rolePermission, setRolePermission] = useState(role.Permission);

	const [errMsgs, setErrMsgs] = useState({});
	const { loggedInUser } = useAuth();
	const roleDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.roleApi,
	});

	const [viewAll, toggleViewAll] = useState(false);
	const [modifyAll, toggleModifyAll] = useState(false);

	const groupedPermissions = useMemo(() => {
		try {
			return _.groupBy(rolePermission, permission => permission?.Category);
		} catch (error) {
			return null;
		}
	}, [rolePermission]);

	useEffect(() => {
		setActive(role?.IsActive);
	}, [role]);

	useEffect(() => {
		const ignoreClickOnMeElement = document.getElementById('popover-wrapper');
		const customButton = ['roleName', 'closeEditButton'];

		const listener = event => {
			const isClickInsideElement = ignoreClickOnMeElement?.contains(event.target);
			if (
				!isClickInsideElement &&
				!customButton.includes(event.target.id.split('_')[0]) &&
				!customButton.includes(event.target.parentElement?.id.split('_')[0])
			) {
				close();
			}
		};
		document.addEventListener('click', listener, true);

		return () => {
			document.removeEventListener('click', listener, true);
		};
	}, []);

	const close = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		if (!isOpen) {
			onClose();
		}
	}, [isOpen]);

	const handleButtonAll = mode => {
		const tempPermissions = _.cloneDeep(groupedPermissions);
		const permission = Object.keys(tempPermissions).map(key => {
			const obj = {};
			Object.keys(groupedPermissions[key][0]).forEach(index => {
				switch (mode) {
					case 'viewAll':
						if (index == 'Read' && viewAll) {
							obj[index] = true;
						} else {
							obj[index] = false;
						}
						break;
					case 'modifyAll':
						if (modifyAll) {
							obj[index] = true;
						} else {
							obj[index] = false;
						}
						break;
					default:
						obj[index] = false;
						break;
				}
			});
			obj.Category = key;
			return obj;
		});

		switch (mode) {
			case 'viewAll':
				toggleViewAll(!viewAll);
				break;
			case 'modifyAll':
				toggleModifyAll(!modifyAll);
				break;
		}
		setRolePermission(permission);
	};

	const togglePermission = ({ category, data }) => {
		const temp = _.cloneDeep(rolePermission);
		const index = temp?.findIndex(item => item.Category === category);
		temp[index] = { ...temp[index], ...data };
		setRolePermission(temp);
	};

	const save = async () => {
		try {
			if (!roleName) {
				const tempError = {};
				tempError.roleName = 'Required';
				setErrMsgs(tempError);
				return;
			}
			setIsUpdating(true);
			//Update record
			const tempRole = role;
			tempRole.RoleName = roleName;
			tempRole.Permission = rolePermission;
			tempRole.IsActive = active;
			await roleDataLoader.update(role.RoleId, tempRole);
			const updatedIndex = _.findIndex(roles, { RoleId: tempRole.RoleId });
			roles.splice(updatedIndex, 1, tempRole);
			setRoles(roles);
			setIsUpdating(false);
			setEditableIndex(null);
			onClose();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<RoleSideWidget data-testid="role-editor-widget" id="popover-wrapper" isOpen={isOpen}>
			<div className="header">
				<div className="row" onMouseEnter={() => setHoveredField(null)}>
					<TemMembersAvatars members={role?.User || []} style={{ marginLeft: '12px' }} />
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<IconButton sx={{ display: 'none' }}>
							<ContentCopyIcon />
						</IconButton>
						<IconButton onClick={() => close()}>
							<LastPageIcon />
						</IconButton>
					</Box>
				</div>
				<div className="main">
					<div className="row" style={{ justifyContent: 'normal', alignItems: 'center' }}>
						<SwitchButton
							checked={active || false}
							onChange={() => !!loggedInUser?.permission?.Role?.Edit && setActive(!active)}
						/>
						<Box sx={{ marginLeft: '17px' }}>
							{editableIndex === 'roleName' ? (
								<div className="row">
									<TextField
										autoFocus={editableIndex === 'roleName'}
										defaultValue={role.RoleName}
										disabled={!loggedInUser?.permission?.Role?.Edit}
										error={errMsgs.roleName}
										helperText={errMsgs.roleName}
										id="roleName"
										name="roleName"
										placeholder="Enter a name for this Role"
										sx={{
											width: '375px',
											'& input': {
												marginRight: '0px',
											},
										}}
										variant="standard"
										onChange={e => {
											setErrMsgs({});
											setRoleName(e.target.value);
										}}
									/>
								</div>
							) : (
								<div className="row">
									<Title
										id="roleName"
										style={{
											color: '#000',
											fontSize: '24px',
											fontWeight: '500',
											cursor: 'pointer',
										}}
										onClick={e => {
											if (loggedInUser?.permission?.Role?.Edit) {
												setHoveredField(null);
												setEditableIndex('roleName');
											}
										}}
										onMouseEnter={() => setHoveredField('roleName')}
									>
										{role.RoleName}
									</Title>
									{hoveredField === 'roleName' && !!loggedInUser?.permission?.Role?.Edit && (
										<IconButton
											data-testid="closeEditButton_Edit_roleName"
											id="closeEditButton_Edit_roleName"
											sx={{ p: 0 }}
											onClick={e => {
												setHoveredField(null);
												setEditableIndex('roleName');
											}}
										>
											<EditOutlinedIcon
												id="closeEditButton_Edit_roleName"
												sx={{
													color: 'rgba(0, 0, 0, 0.6)',
													fontSize: '18px',
													marginLeft: 'auto',
												}}
											/>
										</IconButton>
									)}
								</div>
							)}
							<span>
								{_.size(
									role?.Permission?.filter(
										({ Read, Add, Edit, Delete }) => !!Read || !!Add || !!Edit || !!Delete
									)
								)}{' '}
								privileges defined
							</span>
						</Box>
					</div>
				</div>
			</div>

			<OutlineWrapper onMouseEnter={() => setHoveredField(null)}>
				<div className="row wrapper-header">
					<SmallCircle>
						<ToggleOnOutlinedIcon sx={{ fontSize: '18px', color: '#4D79EA' }} />
					</SmallCircle>
					<SubTitle style={{ color: '#000' }}>Permissions</SubTitle>
					<LinkButton
						data-testid="reset-button"
						disabled={!loggedInUser?.permission?.Role?.Edit}
						style={{ margin: '0px 0px 0px auto' }}
						onClick={() => handleButtonAll('reset')}
					>
						<CachedOutlinedIcon sx={{ mr: '2px' }} />
						Reset All
					</LinkButton>
				</div>
				<Box
					className="invisible-scrollbar"
					sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', overflowY: 'auto' }}
				>
					<div className="row" style={{ padding: '12px 15px 8px 18px' }}>
						<LinkButton
							data-testid="view-all-button"
							disabled={!loggedInUser?.permission?.Role?.Edit}
							style={{ border: '1px solid #4D79EA', height: '25px', fontSize: '12px' }}
							onClick={() => handleButtonAll('viewAll')}
						>
							View All
						</LinkButton>
						<LinkButton
							data-testid="modify-button"
							disabled={!loggedInUser?.permission?.Role?.Edit}
							style={{ border: '1px solid #4D79EA', height: '25px', fontSize: '12px' }}
							onClick={() => handleButtonAll('modifyAll')}
						>
							Modify All
						</LinkButton>
					</div>
					{_.map(groupedPermissions, (group, i) => (
						<PermissionListItem
							key={i}
							category={i}
							permissions={group}
							togglePermission={togglePermission}
						/>
					))}
				</Box>
				<div className="row wrapper-footer">
					<LinkButton
						data-testid="close-button"
						disabled={isUpdating || !loggedInUser?.permission?.Role?.Edit}
						style={{ border: '1px solid #4D79EA', fontSize: '12px' }}
						onClick={close}
					>
						Cancel
					</LinkButton>

					<LinkButton
						data-testid="save-button"
						disabled={isUpdating || !loggedInUser?.permission?.Role?.Edit}
						style={
							loggedInUser?.permission?.Role?.Edit
								? { margin: '0px 0px 0px auto', background: '#4D79EA', color: '#FFF', width: '82px' }
								: {
										margin: '0px 0px 0px auto',
										border: '1px solid #4D79EA',
										fontSize: '12px',
										width: '82px',
								  }
						}
						onClick={save}
					>
						Save
					</LinkButton>
				</div>
			</OutlineWrapper>
		</RoleSideWidget>
	);
};

export default RoleEditor;
