/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
import workflowStepSchema from './schemata/workflowStepSchema';
/************************************************************************************************************************
Resource: WorkflowStep
Reference: N/A
Proprietary RamSoft resource
************************************************************************************************************************/

export default class WorkflowStepResource extends FhirDomainResource {
	code;
	operator;
	studyStatus;
	workflowStepName;

	constructor(resourceString) {
		super(resourceString, workflowStepSchema);
		this.resourceType = 'WorkflowStep';
		this.populateFields();
	}
}
