/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
import studySetSchema from './schemata/studySetSchema';

export default class StudySetResource extends FhirDomainResource {
	active;
	description;
	duration;
	extension = [];
	id;
	meta;
	organization;
	physicianRequired;
	procedureCode = [{}];
	studyType;

	constructor(resourceString) {
		super(resourceString, studySetSchema);
		this.resourceType = 'StudyType';
		this.populateFields();
	}
}
