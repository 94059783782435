const descriptions = [
	{ modality: 'CT', display: 'CT ABDOMEN W/O DYE' },
	{ modality: 'CT', display: 'CT ABDOMEN W/O & W/DYE' },
	{ modality: 'MR', display: 'MR SPECTROSCOPY' },
	{ modality: 'CT', display: 'CT ABDOMEN W/DYE' },
	{ modality: 'CT', display: 'CT ANGIOGRAPHY CHEST' },
	{ modality: 'US', display: 'US EXAM ABDO BACK WALL COMP' },
	{ modality: 'CT', display: 'CT ANGIOGRAPHY HEAD' },
	{ modality: 'CT', display: 'CT ANGIO LWR EXTR W/O&W/DYE' },
	{ modality: 'RF', display: 'FLUOROSCOPE EXAM EXTENSIVE' },
	{ modality: 'CT', display: 'CT ANGIOGRAPHY NECK' },
	{ modality: 'CT', display: 'CT ANGIOGRAPH PELV W/O&W/DYE' },
	{ modality: 'CT', display: 'CT THORAX W/O DYE' },
	{ modality: 'CT', display: 'CT THORAX W/O & W/DYE' },
	{ modality: 'CT', display: 'CT THORAX W/DYE' },
	{ modality: 'CT', display: 'CT NECK SPINE W/O & W/DYE' },
	{ modality: 'CT', display: 'CT NECK SPINE W/DYE' },
	{ modality: 'CT', display: 'CT NECK SPINE W/O DYE' },
	{ modality: 'CT', display: 'CT HEAD/BRAIN W/O DYE' },
	{ modality: 'CT', display: 'CT HEAD/BRAIN W/O & W/DYE' },
	{ modality: 'CT', display: 'CT ANGIO ABDOM W/O & W/DYE' },
	{ modality: 'CT', display: 'CT HEAD/BRAIN W/DYE' },
	{ modality: 'CT', display: 'CT LUMBAR SPINE W/O DYE' },
	{ modality: 'CT', display: 'CT LUMBAR SPINE W/O & W/DYE' },
	{ modality: 'CT', display: 'CT LUMBAR SPINE W/DYE' },
	{ modality: 'CT', display: 'CT MAXILLOFACIAL W/O DYE' },
	{ modality: 'CT', display: 'CT MAXILLOFACIAL W/O & W/DYE' },
	{ modality: 'CT', display: 'CT MAXILLOFACIAL W/DYE' },
	{ modality: 'CT', display: 'CT SOFT TISSUE NECK W/O DYE' },
	{ modality: 'CT', display: 'CT SFT TSUE NCK W/O & W/DYE' },
	{ modality: 'CT', display: 'CT SOFT TISSUE NECK W/DYE' },
	{ modality: 'CT', display: 'CT ORBIT/EAR/FOSSA W/O DYE' },
	{ modality: 'CT', display: 'CT ORBIT/EAR/FOSSA W/O DYE' },
	{ modality: 'CT', display: 'CT ORBIT/EAR/FOSSA W/O&W/DYE' },
	{ modality: 'CT', display: 'CT ORBIT/EAR/FOSSA W/O&W/DYE' },
	{ modality: 'CT', display: 'CT ORBIT/EAR/FOSSA W/DYE' },
	{ modality: 'CT', display: 'CT ORBIT/EAR/FOSSA W/DYE' },
	{ modality: 'CT', display: 'CT PELVIS W/O DYE' },
	{ modality: 'CT', display: 'CT PELVIS W/O & W/DYE' },
	{ modality: 'CT', display: 'CT PELVIS W/DYE' },
	{ modality: 'CT', display: '3D RENDER W/INTRP POSTPROCES' },
	{ modality: 'CT', display: 'CT CHEST SPINE W/O DYE' },
	{ modality: 'CT', display: 'CT CHEST SPINE W/O & W/DYE' },
	{ modality: 'CT', display: 'CT CHEST SPINE W/DYE' },
	{ modality: 'CT', display: 'CT MAXILLOFACIAL W/O DYE' },
	{ modality: 'CT', display: 'CT MAXILLOFACIAL W/DYE' },
	{ modality: 'CT', display: 'CT PELVIS W/O DYE' },
	{ modality: 'CT', display: 'CT BONE DENSITY AXIAL' },
	{ modality: 'CT', display: 'CT MAXILLOFACIAL W/O DYE' },
	{ modality: 'CT', display: 'CT ORBIT/EAR/FOSSA W/O&W/DYE' },
	{ modality: 'CT', display: 'CT SCAN FOR NEEDLE BIOPSY' },
	{ modality: 'CT', display: 'CT COLONOGRAPHY SCREENING' },
	{ modality: 'CT', display: 'CT COLONOGRAPHY DX' },
	{ modality: 'CT', display: 'CT COLONOGRAPHY DX W/DYE' },
	{ modality: 'CT', display: 'CAT SCAN FOLLOW-UP STUDY' },
	{ modality: 'CT', display: 'CT PERFUSION W/CONTRAST CBF' },
	{ modality: 'CT', display: 'CT MAXILLOFACIAL W/O & W/DYE' },
	{ modality: 'CT', display: 'CT ORBIT/EAR/FOSSA W/DYE' },
	{ modality: 'CT', display: 'CT ORBIT/EAR/FOSSA W/O DYE' },
	{ modality: 'CT', display: 'CT UPPER EXTREMITY W/DYE' },
	{ modality: 'CT', display: 'CT UPPER EXTREMITY W/O DYE' },
	{ modality: 'CT', display: 'CT NECK SPINE W/DYE' },
	{ modality: 'CT', display: 'CT NECK SPINE W/O DYE' },
	{ modality: 'CT', display: 'CT ABDOMEN W/O & W/DYE' },
	{ modality: 'CT', display: 'CT LUMBAR SPINE W/DYE' },
	{ modality: 'CT', display: 'CT LUMBAR SPINE W/O DYE' },
	{ modality: 'CT', display: 'CT ABD & PELVIS W/O CONTRAST' },
	{ modality: 'CT', display: 'CT ABD & PELV W/CONTRAST' },
	{ modality: 'MR', display: '3D RENDER W/INTRP POSTPROCES' },
	{ modality: 'CT', display: 'CT COLONOGRAPHY SCREENING' },
	{ modality: 'CT', display: 'CT ABD & PELV W/CONTRAST' },
	{ modality: 'CT', display: 'CT PELVIS W/DYE' },
	{ modality: 'CT', display: 'CT PELVIS W/O DYE' },
	{ modality: 'CT', display: 'CT CHEST SPINE W/DYE' },
	{ modality: 'CT', display: 'CT CHEST SPINE W/O DYE' },
	{ modality: 'CT', display: 'CT ABD & PELV 1/> REGNS' },
	{ modality: 'CT', display: 'CT PERFUSION W/CONTRAST CBF' },
	{ modality: 'CT', display: 'CT ANGIOGRAPHY HEAD' },
	{ modality: 'CT', display: 'CT ANGIOGRAPHY NECK' },
	{ modality: 'MR', display: 'MR ANGIO UPR EXTR W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI ANGIO ABDOM W ORW/O DYE' },
	{ modality: 'MR', display: 'MRI ANGIO CHEST W OR W/O DYE' },
	{ modality: 'MR', display: 'MR ANGIOGRAPHY HEAD W/DYE' },
	{ modality: 'MR', display: 'MR ANGIOGRAPHY HEAD W/O DYE' },
	{ modality: 'MR', display: 'MR ANG LWR EXT W OR W/O DYE' },
	{ modality: 'MR', display: 'MR ANGIOGRAPH NECK W/O&W/DYE' },
	{ modality: 'MR', display: 'MR ANGIOGRAPHY NECK W/O DYE' },
	{ modality: 'MR', display: 'MR ANGIO PELVIS W/O & W/DYE' },
	{ modality: 'MR', display: 'MRI UPPER EXTREMITY W/O DYE' },
	{ modality: 'MR', display: 'MRI ABDOMEN W/O DYE' },
	{ modality: 'MR', display: 'MRI ABDOMEN W/O & W/DYE' },
	{ modality: 'MR', display: 'MRI ABDOMEN W/DYE' },
	{ modality: 'MR', display: 'MAGNETIC IMAGE BONE MARROW' },
	{ modality: 'MR', display: 'MRI UPPER EXTREMITY W/O DYE' },
	{ modality: 'MR', display: 'MRI BRAIN STEM W/O DYE' },
	{ modality: 'MR', display: 'MRI BRAIN STEM W/O & W/DYE' },
	{ modality: 'MR', display: 'MRI BRAIN STEM W/DYE' },
	{ modality: 'MR', display: 'MRI LOWER EXTREMITY W/O DYE' },
	{ modality: 'MR', display: 'MRI NECK SPINE W/O & W/DYE' },
	{ modality: 'MR', display: 'MRI NECK SPINE W/DYE' },
	{ modality: 'MR', display: 'MRI CHEST W/O DYE' },
	{ modality: 'MR', display: 'MRI CHEST W/O & W/DYE' },
	{ modality: 'MR', display: 'MRI CHEST W/DYE' },
	{ modality: 'MR', display: 'MRI UPPER EXTREMITY W/O DYE' },
	{ modality: 'MR', display: 'MRI LOWER EXTREMITY W/O DYE' },
	{ modality: 'MR', display: 'MRI UPPER EXTREMITY W/O DYE' },
	{ modality: 'MR', display: 'MRI LOWER EXTREMITY W/DYE' },
	{ modality: 'MR', display: 'MRI JOINT OF LWR EXTR W/DYE' },
	{ modality: 'MR', display: 'MRI JOINT LWR EXTR W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI JNT OF LWR EXTRE W/O DYE' },
	{ modality: 'MR', display: 'MRI LUMBAR SPINE W/O & W/DYE' },
	{ modality: 'MR', display: 'MRI LUMBAR SPINE W/DYE' },
	{ modality: 'MR', display: 'MRI CHEST W/O DYE' },
	{ modality: 'MR', display: 'MRI CHEST W/O & W/DYE' },
	{ modality: 'MR', display: 'MRI ORBIT/FACE/NECK W/DYE' },
	{ modality: 'MR', display: 'MRI ORBIT/FACE/NECK W/DYE' },
	{ modality: 'MR', display: 'MRI ORBIT/FACE/NECK W/O DYE' },
	{ modality: 'MR', display: 'MRI ORBIT/FACE/NECK W/O DYE' },
	{ modality: 'MR', display: 'MRI ORBIT/FACE/NECK W/O DYE' },
	{ modality: 'MR', display: 'MRI ORBT/FAC/NCK W/O &W/DYE' },
	{ modality: 'MR', display: 'MRI ORBT/FAC/NCK W/O &W/DYE' },
	{ modality: 'MR', display: 'MRI ORBT/FAC/NCK W/O &W/DYE' },
	{ modality: 'MR', display: 'MRI JNT OF LWR EXTRE W/O DYE' },
	{ modality: 'MR', display: 'MRI JOINT LWR EXTR W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI JOINT OF LWR EXTR W/DYE' },
	{ modality: 'MR', display: 'MRI JOINT UPR EXTREM W/O DYE' },
	{ modality: 'MR', display: 'MRI JOINT UPR EXTR W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI JOINT UPR EXTREM W/DYE' },
	{ modality: 'MR', display: 'MRI JNT OF LWR EXTRE W/O DYE' },
	{ modality: 'MR', display: 'MRI JOINT LWR EXTR W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI JOINT OF LWR EXTR W/DYE' },
	{ modality: 'MR', display: 'MRI JNT OF LWR EXTRE W/O DYE' },
	{ modality: 'MR', display: 'MRI JOINT LWR EXTR W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI JOINT OF LWR EXTR W/DYE' },
	{ modality: 'MR', display: 'MRI JOINT UPR EXTREM W/O DYE' },
	{ modality: 'MR', display: 'MRI JOINT UPR EXTR W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI JOINT UPR EXTREM W/DYE' },
	{ modality: 'MR', display: 'MRI JOINT UPR EXTREM W/O DYE' },
	{ modality: 'MR', display: 'MRI JOINT UPR EXTR W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI JOINT UPR EXTREM W/DYE' },
	{ modality: 'MR', display: 'MRI PELVIS W/O DYE' },
	{ modality: 'MR', display: 'MRI PELVIS W/O & W/DYE' },
	{ modality: 'MR', display: 'MRI PELVIS W/DYE' },
	{ modality: 'MR', display: 'MRI PELVIS W/DYE' },
	{ modality: 'MR', display: 'MRI PELVIS W/O DYE' },
	{ modality: 'MR', display: 'MRI LUMBAR SPINE W/O DYE' },
	{ modality: 'MR', display: 'MRI CHEST SPINE W/O DYE' },
	{ modality: 'MR', display: 'MRI LOWER EXTREMITY W/O DYE' },
	{ modality: 'MR', display: 'MRI CHEST SPINE W/DYE' },
	{ modality: 'MR', display: 'MAGNETIC IMAGE JAW JOINT' },
	{ modality: 'MR', display: 'MAGNETIC IMAGE JAW JOINT' },
	{ modality: 'MR', display: 'MRI UPPER EXTREMITY W/DYE' },
	{ modality: 'MR', display: 'MRI JOINT UPR EXTR W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI JOINT UPR EXTREM W/DYE' },
	{ modality: 'MR', display: 'MRI UPPER EXTREMITY W/O DYE' },
	{ modality: 'MR', display: 'MRI UPPER EXTREMITY W/O DYE' },
	{ modality: 'MR', display: 'MRI JOINT UPR EXTREM W/O DYE' },
	{ modality: 'MR', display: 'MRI ANGIO ABDOM W ORW/O DYE' },
	{ modality: 'MR', display: 'MRI ANGIO CHEST W OR W/O DYE' },
	{ modality: 'MR', display: 'MR ANG LWR EXT W OR W/O DYE' },
	{ modality: 'MR', display: 'MR ANGIOGRAPHY NECK W/DYE' },
	{ modality: 'MR', display: 'MRI LWR EXTREMITY W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI JOINT OF LWR EXTR W/DYE' },
	{ modality: 'MR', display: 'MRI PELVIS W/O & W/DYE' },
	{ modality: 'MR', display: 'MRI UPPR EXTREMITY W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI JOINT UPR EXTREM W/DYE' },
	{ modality: 'MR', display: 'MR ANGIO SPINE W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI BOTH BREASTS' },
	{ modality: 'MR', display: 'MRI ONE BREAST' },
	{ modality: 'MR', display: 'BX BREAST 1ST LESION MR IMAG' },
	{ modality: 'MR', display: 'RADIOGRAPHIC PROCEDURE' },
	{ modality: 'MR', display: 'MRI BOTH BREASTS' },
	{ modality: 'MR', display: 'CARD MRI VELOC FLOW MAPPING' },
	{ modality: 'MR', display: 'BX BREAST 1ST LESION MR IMAG' },
	{ modality: 'MR', display: 'MRI BRAIN STEM W/O DYE' },
	{ modality: 'MR', display: 'MRI ONE BREAST' },
	{ modality: 'MR', display: 'MRI ABDOMEN W/O & W/DYE' },
	{ modality: 'MR', display: 'MRI UPPR EXTREMITY W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI BOTH BREASTS' },
	{ modality: 'MR', display: 'MRI ONE BREAST' },
	{ modality: 'MR', display: 'MRI LWR EXTREMITY W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI UPPER EXTREMITY W/DYE' },
	{ modality: 'MR', display: 'MRI UPPR EXTREMITY W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI LWR EXTREMITY W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI UPPER EXTREMITY W/DYE' },
	{ modality: 'MR', display: 'MRI UPPR EXTREMITY W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI LWR EXTREMITY W/O&W/DYE' },
	{ modality: 'MR', display: 'MR ANGIO SPINE W/O&W/DYE' },
	{ modality: 'CT', display: 'CT LOWER EXTREMITY W/DYE' },
	{ modality: 'CT', display: 'CT LOWER EXTREMITY W/O DYE' },
	{ modality: 'CT', display: 'CT LWR EXTREMITY W/O&W/DYE' },
	{ modality: 'CT', display: 'X-RAYS BONE LENGTH STUDIES' },
	{ modality: 'US', display: 'US EXAM ABDOM COMPLETE' },
	{ modality: 'CT', display: 'CT HRT W/O DYE W/CA TEST' },
	{ modality: 'CT', display: 'CT ORBIT/EAR/FOSSA W/DYE' },
	{ modality: 'CT', display: '3D RENDER W/INTRP POSTPROCES' },
	{ modality: 'CT', display: '3D RENDER W/INTRP POSTPROCES' },
	{ modality: 'MR', display: 'CARDIAC MRI FOR MORPH W/DYE' },
	{ modality: 'MR', display: 'CARDIAC MRI FOR MORPH' },
	{ modality: 'MR', display: 'MRI BRAIN STEM W/DYE' },
	{ modality: 'MR', display: 'MRI BRAIN STEM W/O DYE' },
	{ modality: 'MR', display: 'MRI BRAIN STEM W/O & W/DYE' },
	{ modality: 'MR', display: 'MR ANGIOGRAPH HEAD W/O&W/DYE' },
	{ modality: 'US', display: 'ECHO EXAM OF ABDOMEN' },
	{ modality: 'US', display: 'US XTR NON-VASC LMTD' },
	{ modality: 'US', display: 'US EXAM CHEST' },
	{ modality: 'US', display: 'US EXAM PELVIC COMPLETE' },
	{ modality: 'US', display: 'OB US FOLLOW-UP PER FETUS' },
	{ modality: 'US', display: 'OB US >/= 14 WKS SNGL FETUS' },
	{ modality: 'US', display: 'US GUIDE INTRAOP' },
	{ modality: 'US', display: 'OB US >/= 14 WKS ADDL FETUS' },
	{ modality: 'US', display: 'TRANSVAGINAL US OBSTETRIC' },
	{ modality: 'US', display: 'OB US < 14 WKS ADDL FETUS' },
	{ modality: 'US', display: 'OB US LIMITED FETUS(S)' },
	{ modality: 'US', display: 'TRANSVAGINAL US OBSTETRIC' },
	{ modality: 'US', display: 'US EXAM PELVIC LIMITED' },
	{ modality: 'US', display: 'US EXAM SCROTUM' },
	{ modality: 'US', display: 'TRANSVAGINAL US NON-OB' },
	{ modality: 'US', display: 'ECHO EXAM OF ABDOMEN' },
	{ modality: 'US', display: 'US EXAM PELVIC LIMITED' },
	{ modality: 'US', display: 'ECHO EXAM OF ABDOMEN' },
	{ modality: 'US', display: 'US EXAM OF HEAD AND NECK' },
	{ modality: 'US', display: 'US TRANSRECTAL' },
	{ modality: 'US', display: 'US EXAM ABDO BACK WALL LIM' },
	{ modality: 'US', display: 'PENILE VASCULAR STUDY' },
	{ modality: 'US', display: 'ECHO EXAM OF ABDOMEN' },
	{ modality: 'US', display: 'VASCULAR STUDY' },
	{ modality: 'US', display: 'VASCULAR STUDY' },
	{ modality: 'US', display: 'ECHO EXAM OF ABDOMEN' },
	{ modality: 'US', display: 'ECHO GUIDE FOR BIOPSY' },
	{ modality: 'US', display: 'US GUIDE INTRAOP' },
	{ modality: 'US', display: 'ECHO GUIDE FOR BIOPSY' },
	{ modality: 'US', display: 'US EXAM OF HEAD AND NECK' },
	{ modality: 'US', display: 'US EXAM OF HEAD AND NECK' },
	{ modality: 'US', display: 'VASCULAR STUDY' },
	{ modality: 'US', display: 'US EXAM OF HEAD AND NECK' },
	{ modality: 'XR', display: 'X-RAY EXAM OF SHOULDERS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF ANKLE' },
	{ modality: 'XR', display: 'CHEST X-RAY 1 VIEW FRONTAL' },
	{ modality: 'XR', display: 'CHEST X-RAY 2VW FRONTAL&LATL' },
	{ modality: 'XR', display: 'CHEST X-RAY 4/> VIEWS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF COLLAR BONE' },
	{ modality: 'XR', display: 'X-RAY EXAM NECK SPINE 2-3 VW' },
	{ modality: 'XR', display: 'X-RAY EXAM NECK SPINE 4/5VWS' },
	{ modality: 'XR', display: 'X-RAY EXAM NECK SPINE 2-3 VW' },
	{ modality: 'XR', display: 'X-RAY EXAM OF WRIST' },
	{ modality: 'XR', display: 'X-RAY EXAM OF ELBOW' },
	{ modality: 'XR', display: 'X-RAY EXAM OF FINGER(S)' },
	{ modality: 'XR', display: 'X-RAY EXAM OF FOOT' },
	{ modality: 'XR', display: 'X-RAY EXAM OF HEEL' },
	{ modality: 'XR', display: 'X-RAY EXAM OF HUMERUS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF HAND' },
	{ modality: 'XR', display: 'X-RAY EXAM OF KNEE 1 OR 2' },
	{ modality: 'XR', display: 'X-RAY EXAM KNEE 4 OR MORE' },
	{ modality: 'XR', display: 'X-RAY EXAM L-S SPINE 2/3 VWS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF MASTOIDS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF PELVIS' },
	{ modality: 'XR', display: 'X-RAY EXAM RIBS UNI 2 VIEWS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF SHOULDER' },
	{ modality: 'XR', display: 'X-RAY EXAM OF SHOULDER' },
	{ modality: 'XR', display: 'X-RAY EXAM OF SINUSES' },
	{ modality: 'XR', display: 'X-RAY EXAM OF SPINE 1 VIEW' },
	{ modality: 'XR', display: 'X-RAY EXAM OF FINGER(S)' },
	{ modality: 'XR', display: 'X-RAY EXAM OF LOWER LEG' },
	{ modality: 'XR', display: 'X-RAY EXAM OF JAW JOINTS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF WRIST' },
	{ modality: 'XR', display: 'X-RAY EXAM OF ABDOMEN' },
	{ modality: 'XR', display: 'X-RAY EXAM OF ABDOMEN' },
	{ modality: 'XR', display: 'X-RAY EXAM PITUITARY SADDLE' },
	{ modality: 'XR', display: 'X-RAY EXAM OF ANKLE' },
	{ modality: 'XR', display: 'X-RAYS FOR BONE AGE' },
	{ modality: 'XR', display: 'X-RAY EXAM NECK SPINE 6/>VWS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF SPINE 1 VIEW' },
	{ modality: 'XR', display: 'DXA BONE DENSITY/PERIPHERAL' },
	{ modality: 'XR', display: 'X-RAY EXAM OF ELBOW' },
	{ modality: 'XR', display: 'X-RAY EXAM OF FOREARM' },
	{ modality: 'XR', display: 'X-RAY EXAM OF FOOT' },
	{ modality: 'XR', display: 'X-RAY EXAM OF HAND' },
	{ modality: 'XR', display: 'X-RAY EXAM OF KNEE 3' },
	{ modality: 'XR', display: 'X-RAY EXAM L-S SPINE BENDING' },
	{ modality: 'XR', display: 'X-RAY EXAM L-2 SPINE 4/>VWS' },
	{ modality: 'XR', display: 'X-RAY EXAM L-S SPINE 2/3 VWS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF PELVIS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF PELVIS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF SHOULDER BLADE' },
	{ modality: 'XR', display: 'X-RAY EXAM THORAC SPINE 2VWS' },
	{ modality: 'XR', display: 'X-RAY EXAM TRUNK SPINE 2 VWS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF WRIST' },
	{ modality: 'XR', display: 'X-RAY EXAM OF SPINE 1 VIEW' },
	{ modality: 'XR', display: 'X-RAY EXAM OF PELVIS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF NECK' },
	{ modality: 'XR', display: 'X-RAY MEASUREMENT OF PELVIS' },
	{ modality: 'XR', display: 'X-RAY EXAM RIBS BIL 3 VIEWS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF SPINE 1 VIEW' },
	{ modality: 'XR', display: 'X-RAY EXAM SACRUM TAILBONE' },
	{ modality: 'XR', display: 'X-RAY EXAM OF KNEES' },
	{ modality: 'XR', display: 'X-RAYS FOR BONE AGE' },
	{ modality: 'XR', display: 'X-RAY EXAM OF EYE SOCKETS' },
	{ modality: 'XR', display: 'X-RAY EYE FOR FOREIGN BODY' },
	{ modality: 'XR', display: 'X-RAY EXAM OF JAW JOINT' },
	{ modality: 'MR', display: 'MRI UPPR EXTREMITY W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI UPPR EXTREMITY W/O&W/DYE' },
	{ modality: 'XR', display: 'X-RAY EXAM SACRUM TAILBONE' },
	{ modality: 'XR', display: 'X-RAY EXAM OF NASAL BONES' },
	{ modality: 'XR', display: 'X-RAY EXAM SI JOINTS 3/> VWS' },
	{ modality: 'XR', display: 'X-RAY NOSE TO RECTUM' },
	{ modality: 'XR', display: 'X-RAY EXAM RIBS UNI 2 VIEWS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF SINUSES' },
	{ modality: 'XR', display: 'X-RAY EXAM OF SALIVARY GLAND' },
	{ modality: 'XR', display: 'X-RAY EXAM OF EYE SOCKETS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF SPINE 1 VIEW' },
	{ modality: 'MR', display: 'MRI PELVIS W/O DYE' },
	{ modality: 'NM', display: 'HEPATOBILIARY SYSTEM IMAGING' },
	{ modality: 'NM', display: 'BONE IMAGING WHOLE BODY' },
	{ modality: 'NM', display: 'HEART INFARCT IMAGE' },
	{ modality: 'NM', display: 'CSF LEAKAGE IMAGING' },
	{ modality: 'NM', display: 'GASTROESOPHAGEAL REFLUX EXAM' },
	{ modality: 'NM', display: 'ACUTE GI BLOOD LOSS IMAGING' },
	{ modality: 'NM', display: 'LIVER AND SPLEEN IMAGING' },
	{ modality: 'NM', display: 'LIVER & SPLEEN IMAGE/FLOW' },
	{ modality: 'NM', display: 'LUNG PERFUSION DIFFERENTIAL' },
	{ modality: 'NM', display: 'LUNG VENTILAT&PERFUS IMAGING' },
	{ modality: 'NM', display: 'PARATHYROID PLANAR IMAGING' },
	{ modality: 'NM', display: 'KIDNEY IMAGING (3D)' },
	{ modality: 'NM', display: 'SERIAL SALIVARY IMAGING' },
	{ modality: 'NM', display: 'BONE IMAGING LIMITED AREA' },
	{ modality: 'NM', display: 'TESTICULAR IMAGING W/FLOW' },
	{ modality: 'NM', display: 'BONE MARROW IMAGING BODY' },
	{ modality: 'NM', display: 'GASTRIC MUCOSA IMAGING' },
	{ modality: 'NM', display: 'CARDIAC SHUNT IMAGING' },
	{ modality: 'NM', display: 'LIVER IMAGING' },
	{ modality: 'NM', display: 'LIVER IMAGING (3D)' },
	{ modality: 'NM', display: 'THYROID IMAGING W/BLOOD FLOW' },
	{ modality: 'NM', display: 'LUNG PERFUSION IMAGING' },
	{ modality: 'NM', display: 'BONE IMAGING (3D)' },
	{ modality: 'NM', display: 'HEART INFARCT IMAGE (3D)' },
	{ modality: 'NM', display: 'ESOPHAGEAL MOTILITY STUDY' },
	{ modality: 'NM', display: 'LEVEEN/SHUNT PATENCY EXAM' },
	{ modality: 'NM', display: 'BRAIN IMAGING (3D)' },
	{ modality: 'NM', display: 'LUNG VENTILATION IMAGING' },
	{ modality: 'NM', display: 'HEART INFARCT IMAGE (EF)' },
	{ modality: 'NM', display: 'GASTRIC EMPTYING STUDY' },
	{ modality: 'NM', display: 'SALIVARY GLAND FUNCTION EXAM' },
	{ modality: 'NM', display: 'HEART FIRST PASS SINGLE' },
	{ modality: 'NM', display: 'HT MUSCLE IMAGE PLANAR SING' },
	{ modality: 'NM', display: 'BRAIN IMAGING (3D)' },
	{ modality: 'MG', display: 'X-RAY EXAM BREAST SPECIMEN' },
	{ modality: 'MG', display: 'DIAGNOSTICMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'DIAGNOSTICMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'DIAGNOSTICMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'DIAGNOSTICMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'DIAGNOSTICMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'DIAGNOSTICMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'X-RAY OF MAMMARY DUCT' },
	{ modality: 'MG', display: 'SCREENINGMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'DIAGNOSTICMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'DIAGNOSTICMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'SCREENINGMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'ECHO GUIDE FOR BIOPSY' },
	{ modality: 'US', display: 'LOWER EXTREMITY STUDY' },
	{ modality: 'US', display: 'UPPER EXTREMITY STUDY' },
	{ modality: 'US', display: 'INTRACRANIAL COMPLETE STUDY' },
	{ modality: 'US', display: 'INTRACRANIAL LIMITED STUDY' },
	{ modality: 'US', display: 'US EXAM CHEST' },
	{ modality: 'MR', display: 'MR ANGIOGRAPH NECK W/O&W/DYE' },
	{ modality: 'MR', display: 'MR ANGIO UPR EXTR W/O&W/DYE' },
	{ modality: 'MR', display: 'MRI ANGIO ABDOM W ORW/O DYE' },
	{ modality: 'MG', display: 'BX BREAST PERCUT W/O IMAGE' },
	{ modality: 'MG', display: 'BX BREAST PERCUT W/O IMAGE' },
	{ modality: 'MG', display: 'DRAIN BREAST LESION ADD-ON' },
	{ modality: 'MG', display: 'DRAIN BREAST LESION ADD-ON' },
	{ modality: 'MG', display: 'ECHO GUIDE FOR BIOPSY' },
	{ modality: 'MG', display: 'ECHO GUIDE FOR BIOPSY' },
	{ modality: 'MG', display: 'DIAGNOSTICMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'DIAGNOSTICMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'PERQ DEVICE BREAST 1ST IMAG' },
	{ modality: 'MG', display: 'PERQ DEVICE BREAST 1ST IMAG' },
	{ modality: 'MG', display: 'PERQ DEVICE BREAST EA IMAG' },
	{ modality: 'MG', display: 'PERQ DEVICE BREAST EA IMAG' },
	{ modality: 'MG', display: 'PERQ DEVICE BREAST 1ST IMAG' },
	{ modality: 'US', display: 'ECHO EXAM OF HEAD' },
	{ modality: 'US', display: 'US EXAM SPINAL CANAL' },
	{ modality: 'MG', display: 'PERQ DEVICE BREAST 1ST IMAG' },
	{ modality: 'MG', display: 'X-RAY EXAM BREAST SPECIMEN' },
	{ modality: 'MG', display: 'X-RAY EXAM BREAST SPECIMEN' },
	{ modality: 'US', display: 'US EXAM ABDO BACK WALL LIM' },
	{ modality: 'US', display: 'ECHO GUIDE FOR BIOPSY' },
	{ modality: 'US', display: 'ECHO EXAM UTERUS' },
	{ modality: 'US', display: 'ECHO EXAM OF ABDOMEN' },
	{ modality: 'US', display: 'INTRACRANIAL LIMITED STUDY' },
	{ modality: 'US', display: 'ECHO GUIDE FOR BIOPSY' },
	{ modality: 'US', display: 'ECHO GUIDE FOR BIOPSY' },
	{ modality: 'US', display: 'ECHO GUIDE FOR BIOPSY' },
	{ modality: 'US', display: 'EXTREMITY STUDY' },
	{ modality: 'US', display: 'EXTREMITY STUDY' },
	{ modality: 'US', display: 'ECHO GUIDE FOR BIOPSY' },
	{ modality: 'US', display: 'MIDDLE CEREBRAL ARTERY ECHO' },
	{ modality: 'US', display: 'UMBILICAL ARTERY ECHO' },
	{ modality: 'US', display: 'OB US DETAILED ADDL FETUS' },
	{ modality: 'US', display: 'OB US DETAILED SNGL FETUS' },
	{ modality: 'US', display: 'OB US FOLLOW-UP PER FETUS' },
	{ modality: 'US', display: 'TRANSVAGINAL US OBSTETRIC' },
	{ modality: 'US', display: 'OB US DETAILED ADDL FETUS' },
	{ modality: 'US', display: 'ECHO GUIDE FOR BIOPSY' },
	{ modality: 'US', display: 'ECHO GUIDE FOR BIOPSY' },
	{ modality: 'US', display: 'OB US >/= 14 WKS ADDL FETUS' },
	{ modality: 'US', display: 'OB US < 14 WKS SINGLE FETUS' },
	{ modality: 'US', display: 'US EXAM INFANT HIPS DYNAMIC' },
	{ modality: 'US', display: 'LOWER EXTREMITY STUDY' },
	{ modality: 'US', display: 'ECHO EXAM OF FETAL HEART' },
	{ modality: 'US', display: 'US EXAM SCROTUM' },
	{ modality: 'MR', display: 'MR GUIDANCE FOR NEEDLE PLACE' },
	{ modality: 'US', display: 'EXTREMITY STUDY' },
	{ modality: 'US', display: 'EXTREMITY STUDY' },
	{ modality: 'MR', display: 'MRI PELVIS W/DYE' },
	{ modality: 'MR', display: 'MR ANGIO PELVIS W/O & W/DYE' },
	{ modality: 'MR', display: 'MRI ABDOMEN W/O DYE' },
	{ modality: 'MR', display: 'US GUIDE VASCULAR ACCESS' },
	{ modality: 'MR', display: 'MRI BRAIN STEM W/O & W/DYE' },
	{ modality: 'MR', display: 'CARD MRI W/STRESS IMG & DYE' },
	{ modality: 'MR', display: 'CARDIAC MRI W/STRESS IMG' },
	{ modality: 'MR', display: 'MRI PROCEDURE' },
	{ modality: 'NM', display: 'K FLOW/FUNCT IMAGE W/DRUG' },
	{ modality: 'NM', display: 'TUMOR IMAGING (3D)' },
	{ modality: 'NM', display: 'TUMOR IMAGING (3D)' },
	{ modality: 'NM', display: 'TUMOR IMAGING WHOLE BODY' },
	{ modality: 'NM', display: 'TUMOR IMAGING WHOLE BODY' },
	{ modality: 'NM', display: 'ABSCESS IMAGING WHOLE BODY' },
	{ modality: 'NM', display: 'ABSCESS IMAGING LTD AREA' },
	{ modality: 'NM', display: 'NUCLEAR LOCALIZATION/ABSCESS' },
	{ modality: 'NM', display: 'HEMATOPOIETIC NUCLEAR TX' },
	{ modality: 'CT', display: 'CT ANGIO ABDOMINAL ARTERIES' },
	{ modality: 'CT', display: '3D RENDER W/INTRP POSTPROCES' },
	{ modality: 'CT', display: '3D RENDER W/INTRP POSTPROCES' },
	{ modality: 'CT', display: '3D RENDER W/INTRP POSTPROCES' },
	{ modality: 'CT', display: '3D RENDER W/INTRP POSTPROCES' },
	{ modality: 'CT', display: 'CT ANGIO HRT W/3D IMAGE' },
	{ modality: 'CT', display: 'X-RAYS BONE LENGTH STUDIES' },
	{ modality: 'CT', display: 'CT HRT W/3D IMAGE CONGEN' },
	{ modality: 'CT', display: 'CT HRT W/3D IMAGE' },
	{ modality: 'CT', display: 'CT ORBIT/EAR/FOSSA W/DYE' },
	{ modality: 'CT', display: '3D RENDER W/INTRP POSTPROCES' },
	{ modality: 'CT', display: 'SET RADIATION THERAPY FIELD' },
	{ modality: 'CT', display: 'SET RADIATION THERAPY FIELD' },
	{ modality: 'CT', display: 'SET RADIATION THERAPY FIELD' },
	{ modality: 'NM/CT', display: 'TUMOR IMAGING (3D)' },
	{ modality: 'NM/CT', display: 'PARATHYRD PLANAR W/SPECT&CT' },
	{ modality: 'PT/CT', display: 'PET IMAGE W/CT SKULL-THIGH' },
	{ modality: 'PT/CT', display: 'PET IMAGE W/CT FULL BODY' },
	{ modality: 'PT/CT', display: 'PET IMAGE W/CT LMTD' },
	{ modality: 'PT/CT', display: 'PET IMAGE W/CT SKULL-THIGH' },
	{ modality: 'PT/CT', display: 'PET IMAGE W/CT FULL BODY' },
	{ modality: 'PT', display: 'BRAIN IMAGING (PET)' },
	{ modality: 'PT', display: 'HEART IMAGE (PET) SINGLE' },
	{ modality: 'PT', display: 'HEART MUSCLE IMAGING (PET)' },
	{ modality: 'PT', display: 'HEART MUSCLE IMAGING (PET)' },
	{ modality: 'XR', display: 'DXA BONE DENSITY/PERIPHERAL' },
	{ modality: 'XR', display: 'X-RAY EXAM OF FACIAL BONES' },
	{ modality: 'XR', display: 'X-RAY EXAM OF FACIAL BONES' },
	{ modality: 'XR', display: 'X-RAY EXAM OF MASTOIDS' },
	{ modality: 'XR', display: 'X-RAY EXAM SI JOINTS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF ABDOMEN' },
	{ modality: 'XR', display: 'X-RAY EXAM OF ABDOMEN' },
	{ modality: 'XR', display: 'CHEST X-RAY 1 VIEW FRONTAL' },
	{ modality: 'XR', display: 'CHEST X-RAY 1 VIEW FRONTAL' },
	{ modality: 'XR', display: 'CHEST X-RAY SPECIAL VIEWS' },
	{ modality: 'XR', display: 'CHEST X-RAY 1 VIEW FRONTAL' },
	{ modality: 'XR', display: 'X-RAY EXAM OF SPINE 1 VIEW' },
	{ modality: 'XR', display: 'X-RAY EXAM OF LEG INFANT' },
	{ modality: 'XR', display: 'X-RAY EXAM SACRUM TAILBONE' },
	{ modality: 'XR', display: 'X-RAY EXAM BREASTBONE 2/>VWS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF ARM INFANT' },
	{ modality: 'XR', display: 'X-RAY EXAM OF ABDOMEN' },
	{ modality: 'XR', display: 'X-RAY EXAM OF ABDOMEN' },
	{ modality: 'XR', display: 'X-RAY EXAM OF ABDOMEN' },
	{ modality: 'XR', display: 'X-RAY EXAM SERIES ABDOMEN' },
	{ modality: 'XR', display: 'CHEST X-RAY FRNT LAT LORDOTC' },
	{ modality: 'XR', display: 'CHEST X-RAY SPECIAL VIEWS' },
	{ modality: 'XR', display: 'CHEST X-RAY FRNT LAT OBLIQUE' },
	{ modality: 'XR', display: 'CHEST X-RAY SPECIAL VIEWS' },
	{ modality: 'XR', display: 'X-RAY EXAM UNILAT RIBS/CHEST' },
	{ modality: 'XR', display: 'X-RAY EXAM OF TOE(S)' },
	{ modality: 'XR', display: 'X-RAY EXAM OF JAW <4VIEWS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF SKULL' },
	{ modality: 'XR', display: 'X-RAY EXAM OF SKULL' },
	{ modality: 'XR', display: 'X-RAY EXAM OF ANKLE' },
	{ modality: 'XR', display: 'CHEST X-RAY SPECIAL VIEWS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF FACIAL BONES' },
	{ modality: 'XR', display: 'X-RAY STRENOCLAVIC JT 3/>VWS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF LOWER LEG' },
	{ modality: 'XR', display: 'X-RAY EXAM RIBS/CHEST4/> VWS' },
	{ modality: 'XR', display: 'X-RAY EXAM THORAC SPINE 3VWS' },
	{ modality: 'XR', display: 'X-RAY EXAM OF JAW 4/> VIEWS' },
	{ modality: 'XR', display: 'CHEST X-RAY 1 VIEW FRONTAL' },
	{ modality: 'XR', display: 'X-RAY EXAM OF SKULL' },
	{ modality: 'XR', display: 'X-RAY EXAM OF KNEE 1 OR 2' },
	{ modality: 'XR', display: 'X-RAYS BONE SURVEY INFANT' },
	{ modality: 'XR', display: 'X-RAY NOSE TO RECTUM' },
	{ modality: 'XR', display: 'X-RAY EXAM NECK SPINE 2-3 VW' },
	{ modality: 'XR', display: 'X-RAY EXAM L-2 SPINE 4/>VWS' },
	{ modality: 'XR/RF', display: 'CHEST X-RAY AND FLUOROSCOPY' },
	{ modality: 'XR/RF', display: 'CHEST X-RAY&FLUORO 4/> VIEWS' },
	{ modality: 'RF', display: 'X-RAY EXAM OF SALIVARY GLAND' },
	{ modality: 'RF', display: 'NEEDLE LOCALIZATION BY XRAY' },
	{ modality: 'RF', display: 'REPOSITION GASTROSTOMY TUBE' },
	{ modality: 'RF', display: 'CONTRAST X-RAY EXAM OF COLON' },
	{ modality: 'RF', display: 'CONTRAST X-RAY EXAM OF COLON' },
	{ modality: 'RF', display: 'FLUOROSCOPE EXAMINATION' },
	{ modality: 'RF', display: 'NEEDLE LOCALIZATION BY XRAY' },
	{ modality: 'RF', display: 'X-RAY EXAM OF SMALL BOWEL' },
	{ modality: 'RF', display: 'CONTRAST X-RAY EXAM OF COLON' },
	{ modality: 'RF', display: 'CONTRAST X-RAY EXAM OF COLON' },
	{ modality: 'RF', display: 'CONTRAST X-RAY ESOPHAGUS' },
	{ modality: 'RF', display: 'CONTRAST X-RAY ESOPHAGUS' },
	{ modality: 'RF', display: 'CONTRST X-RAY UPPR GI TRACT' },
	{ modality: 'RF', display: 'X-RAY UPPER GI&SMALL INTEST' },
	{ modality: 'RF', display: 'CONTRST X-RAY UPPR GI TRACT' },
	{ modality: 'RF', display: 'X-RAY UPPER GI DELAY W/O KUB' },
	{ modality: 'RF', display: 'CONTRAST X-RAY EXAM OF COLON' },
	{ modality: 'RF', display: 'CONTRAST X-RAY ESOPHAGUS' },
	{ modality: 'RF', display: 'X-RAY UPPER GI DELAY W/O KUB' },
	{ modality: 'RF', display: 'NEEDLE LOCALIZATION BY XRAY' },
	{ modality: 'RF', display: 'FLUOROSCOPE EXAMINATION' },
	{ modality: 'RF', display: 'FLUOROSCOPE EXAMINATION' },
	{ modality: 'RF', display: 'COMPLEX BODY SECTION X-RAYS' },
	{ modality: 'RF', display: 'FLUOROSCOPE EXAMINATION' },
	{ modality: 'RF', display: 'X-RAY EXAM OF BODY SECTION' },
	{ modality: 'RF', display: 'COMPLEX BODY SECTION X-RAY' },
	{ modality: 'RF', display: 'CONTRST X-RAY URINARY TRACT' },
	{ modality: 'RF', display: 'X-RAY EXAM OF SMALL BOWEL' },
	{ modality: 'RF', display: 'X-RAY EXAM OF SMALL BOWEL' },
	{ modality: 'NM', display: 'GATED HEART MULTIPLE' },
	{ modality: 'NM', display: 'GATED HEART PLANAR SINGLE' },
	{ modality: 'NM', display: 'HEART FIRST PASS SINGLE' },
	{ modality: 'NM', display: 'CEREBROSPINAL FLUID SCAN' },
	{ modality: 'NM', display: 'ABSCESS IMAGING LTD AREA' },
	{ modality: 'NM', display: 'NUCLEAR LOCALIZATION/ABSCESS' },
	{ modality: 'NM', display: 'BONE MARROW IMAGING LTD' },
	{ modality: 'NM', display: 'THYROID MET IMAGING BODY' },
	{ modality: 'NM', display: 'TUMOR IMAGING WHOLE BODY' },
	{ modality: 'NM', display: 'TUMOR IMAGING LIMITED AREA' },
	{ modality: 'NM', display: 'TUMOR IMAGING (3D)' },
	{ modality: 'NM', display: 'HT MUSCLE IMAGE SPECT SING' },
	{ modality: 'NM', display: 'NUCLEAR RX ORAL ADMIN' },
	{ modality: 'NM', display: 'BRAIN FLOW IMAGING ONLY' },
	{ modality: 'NM', display: 'VASCULAR FLOW IMAGING' },
	{ modality: 'NM', display: 'K FLOW/FUNCT IMAGE W/O DRUG' },
	{ modality: 'NM', display: 'VASCULAR FLOW IMAGING' },
	{ modality: 'NM', display: 'GASTRIC EMPTYING STUDY' },
	{ modality: 'NM', display: 'KIDNEY FUNCTION STUDY' },
	{ modality: 'NM', display: 'LIVER IMAGING (3D)' },
	{ modality: 'NM', display: 'ABSCESS IMAGING WHOLE BODY' },
	{ modality: 'NM', display: 'CSF SHUNT EVALUATION' },
	{ modality: 'NM', display: 'PARATHYRD PLANAR W/WO SUBTRJ' },
	{ modality: 'XA', display: 'CONTRAST EXAM ABDOMINL AORTA' },
	{ modality: 'XA', display: 'CONTRAST EXAM THORACIC AORTA' },
	{ modality: 'XA', display: 'ARTERY X-RAYS PELVIS' },
	{ modality: 'XA', display: 'VEIN X-RAY LIVER' },
	{ modality: 'XA', display: 'VEIN X-RAY LIVER W/HEMODYNAM' },
	{ modality: 'XA', display: 'VEIN X-RAY TRUNK' },
	{ modality: 'XA', display: 'ARTERY X-RAYS SPINE' },
	{ modality: 'XA', display: 'ARTERY X-RAYS CHEST' },
	{ modality: 'XA', display: 'LYMPH VESSEL X-RAY TRUNK' },
	{ modality: 'XA', display: 'LYMPH VESSEL X-RAY TRUNK' },
	{ modality: 'XA', display: 'LYMPH VESSEL X-RAY ARMS/LEGS' },
	{ modality: 'XA', display: 'LYMPH VESSEL X-RAY ARMS/LEGS' },
	{ modality: 'XA', display: 'LYMPH VESSEL X-RAY ARM/LEG' },
	{ modality: 'XA', display: 'ACCESS THORACIC LYMPH DUCT' },
	{ modality: 'XA', display: 'VEIN X-RAY LIVER W/O HEMODYN' },
	{ modality: 'XA', display: 'VEIN X-RAY LIVER W/HEMODYNAM' },
	{ modality: 'XA', display: 'ARTERY X-RAYS LUNGS' },
	{ modality: 'XA', display: 'ARTERY X-RAYS LUNG' },
	{ modality: 'XA', display: 'INS CATH REN ART 1ST BILAT' },
	{ modality: 'XA', display: 'INS CATH REN ART 1ST UNILAT' },
	{ modality: 'XA', display: 'VEIN X-RAY KIDNEYS' },
	{ modality: 'XA', display: 'VEIN X-RAY KIDNEY' },
	{ modality: 'XA', display: 'X-RAY EXAM OF SALIVARY DUCT' },
	{ modality: 'XA', display: 'VEIN X-RAY SPLEEN/LIVER' },
	{ modality: 'XA', display: 'X-RAY EXAM OF FISTULA' },
	{ modality: 'XA', display: 'VEIN X-RAY CHEST' },
	{ modality: 'XA', display: 'CONTRST X-RAY URINARY TRACT' },
	{ modality: 'XA', display: 'VEIN X-RAY ARMS/LEGS' },
	{ modality: 'XA', display: 'VEIN X-RAY ARM/LEG' },
	{ modality: 'XA', display: 'ARTERY X-RAYS ABDOMEN' },
	{ modality: 'XA', display: 'ACCESS AV DIAL GRFT FOR PROC' },
	{ modality: 'XA', display: 'ARTERY X-RAYS ARM/LEG' },
	{ modality: 'XA', display: 'DISCOGRAPHY CERV/THOR SPINE' },
	{ modality: 'XA', display: 'AV DIALYSIS SHUNT IMAGING' },
	{ modality: 'XA', display: 'ARTERY X-RAYS ARMS/LEGS' },
	{ modality: 'XA', display: 'ARTERY X-RAYS ARM/LEG' },
	{ modality: 'XA', display: 'X-RAY OF LOWER SPINE DISK' },
	{ modality: 'XA', display: 'X-RAY EXAM OF FISTULA' },
	{ modality: 'MG', display: 'BX BREAST ADD LESION STRTCTC' },
	{ modality: 'MG', display: 'BX BREAST ADD LESION STRTCTC' },
	{ modality: 'MG', display: 'BX BREAST 1ST LESION STRTCTC' },
	{ modality: 'MG', display: 'BX BREAST 1ST LESION STRTCTC' },
	{ modality: 'MG', display: 'BX BREAST ADD LESION STRTCTC' },
	{ modality: 'MG', display: 'BX BREAST ADD LESION STRTCTC' },
	{ modality: 'MG', display: 'BX BREAST 1ST LESION STRTCTC' },
	{ modality: 'MG', display: 'BX BREAST 1ST LESION STRTCTC' },
	{ modality: 'MG', display: 'DIAGNOSTICMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'DIAGNOSTICMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'COMPUTER DX MAMMOGRAM ADD-ON' },
	{ modality: 'MG', display: 'DIAGNOSTICMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'SCREENINGMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'SCREENINGMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'DIAGNOSTICMAMMOGRAPHYDIGITAL' },
	{ modality: 'MG', display: 'DIAGNOSTICMAMMOGRAPHYDIGITAL' },
	{ modality: 'XR/RF', display: 'CONTRST X-RAY UPPR GI TRACT' },
	{ modality: 'XR/RF', display: 'X-RAYUPPER GI DELAY W/KUB' },
	{ modality: 'MR', display: 'BX BREAST 1ST LESION MR IMAG' },
	{ modality: 'MR', display: 'BX BREAST 1ST LESION MR IMAG' },
	{ modality: 'MR', display: 'PERQ DEV BREAST 1ST MR GUIDE' },
	{ modality: 'MR', display: 'PERQ DEV BREAST 1ST MR GUIDE' },
	{ modality: 'MR', display: 'FUNCTIONAL BRAIN MAPPING' },
	{ modality: 'MR', display: 'MRI BRAIN STEM W/O & W/DYE' },
	{ modality: 'MR', display: 'MRI BRAIN STEM W/O & W/DYE' },
	{ modality: 'MR', display: 'MRI BRAIN STEM W/O & W/DYE' },
	{ modality: 'MR', display: 'MRI BRAIN STEM W/O & W/DYE' },
	{ modality: 'NM', display: 'BONE MARROW IMAGING MULT' },
	{ modality: 'NM', display: 'BONE IMAGING MULTIPLE AREAS' },
	{ modality: 'NM', display: 'BONE IMAGING 3 PHASE' },
	{ modality: 'NM', display: 'URETERAL REFLUX STUDY' },
	{ modality: 'NM', display: 'VEN THROMBOSIS IMAGES BILAT' },
	{ modality: 'NM', display: 'VENOUS THROMBOSIS IMAGING' },
	{ modality: 'NM', display: 'LYMPH SYSTEM IMAGING' },
	{ modality: 'NM', display: 'HEPATOBIL SYST IMAGE W/DRUG' },
	{ modality: 'NM', display: 'BRAIN IMAGE 4+ VIEWS' },
	{ modality: 'NM', display: 'BRAIN IMAGE W/FLOW 4 + VIEWS' },
	{ modality: 'NM', display: 'BRAIN IMAGE W/FLOW < 4 VIEWS' },
	{ modality: 'NM', display: 'BRAIN IMAGING (3D)' },
	{ modality: 'NM', display: 'BRAIN IMAGING (3D)' },
	{ modality: 'NM', display: 'CARDIOVASCULAR NUCLEAR EXAM' },
	{ modality: 'NM', display: 'HT MUSC IMAGE PLANAR MULT' },
	{ modality: 'NM', display: 'KIDNEY IMAGING MORPHOL' },
	{ modality: 'NM', display: 'K FLOW/FUNCT IMAGE MULTIPLE' },
	{ modality: 'NM', display: 'K FLOW/FUNCT IMAGE W/O DRUG' },
	{ modality: 'NM', display: 'LIVER AND SPLEEN IMAGING' },
	{ modality: 'NM', display: 'LYMPH SYSTEM IMAGING' },
	{ modality: 'NM', display: 'LYMPH SYSTEM IMAGING' },
	{ modality: 'NM', display: 'LUNG PERF&VENTILAT DIFERENTL' },
	{ modality: 'NM', display: 'NUCLEAR RX IV ADMIN' },
	{ modality: 'NM', display: 'APPLY INTERSTIT RADIAT COMPL' },
	{ modality: 'NM', display: 'APPLY INTERSTIT RADIAT COMPL' },
	{ modality: 'NM', display: 'SPLEEN IMAGING' },
	{ modality: 'NM', display: 'THYROID IMAGING W/BLOOD FLOW' },
	{ modality: 'NM', display: 'THYROID UPTAKE MEASUREMENT' },
	{ modality: 'NM', display: 'THYROID MET IMAGING' },
	{ modality: 'NM', display: 'THYROID UPTAKE MEASUREMENT' },
	{ modality: 'NM', display: 'THYROID MET IMAGING BODY' },
	{ modality: 'NM', display: 'TUMOR IMAGING MULT AREAS' },
	{ modality: 'NM', display: 'TUMOR IMAGING WHOLE BODY' },
	{ modality: 'NM', display: 'ABSCESS IMAGING LTD AREA' },
	{ modality: 'PT', display: 'PET IMAGE W/CT LMTD' },
	{ modality: 'PT', display: 'BRAIN IMAGING (PET)' },
	{ modality: 'PT', display: 'HEART IMAGE (PET) MULTIPLE' },
	{ modality: 'XR', display: 'DXA BONE DENSITY AXIAL' },
	{ modality: 'XR', display: 'X-RAYS BONE LENGTH STUDIES' },
	{ modality: 'XR', display: 'X-RAYS BONE SURVEY COMPLETE' },
	{ modality: 'XR', display: 'X-RAYS BONE SURVEY LIMITED' },
	{ modality: 'XR', display: 'X-RAY HEAD FOR ORTHODONTIA' },
	{ modality: 'XA', display: 'X-RAY EXAM OF FISTULA' },
	{ modality: 'RF', display: 'NEEDLE LOCALIZATION BY XRAY' },
	{ modality: 'RF', display: 'NEEDLE LOCALIZATION BY XRAY' },
	{ modality: 'RF', display: 'X-RAY FALLOPIAN TUBE' },
	{ modality: 'RF', display: 'CONTRAST X-RAY OF BRAIN' },
	{ modality: 'RF', display: 'CONTRAST X-RAY GALLBLADDER' },
	{ modality: 'RF', display: 'CONTRAST X-RAY GALLBLADDER' },
	{ modality: 'RF', display: 'X-RAY BILE DUCTS/PANCREAS' },
	{ modality: 'RF', display: 'X-RAY BILE DUCTS/PANCREAS' },
	{ modality: 'RF', display: 'X-RAYS AT SURGERY ADD-ON' },
	{ modality: 'RF', display: 'INJECTION FOR CHOLANGIOGRAM' },
	{ modality: 'RF', display: 'DISCOGRAPHY CERV/THOR SPINE' },
	{ modality: 'RF', display: 'MYELOGRAPHY NECK SPINE' },
	{ modality: 'RF', display: 'X-RAY URETHRA/BLADDER' },
	{ modality: 'RF', display: 'CONTRAST X-RAY EXAM OF COLON' },
	{ modality: 'RF', display: 'X-RAY EXAM OF SMALL BOWEL' },
	{ modality: 'RF', display: 'X-RAY BILE DUCT ENDOSCOPY' },
	{ modality: 'RF', display: 'X-RAY BILE/PANC ENDOSCOPY' },
	{ modality: 'RF', display: 'X-RAY FOR PANCREAS ENDOSCOPY' },
	{ modality: 'RF', display: 'CONTRAST X-RAY ESOPHAGUS' },
	{ modality: 'RF', display: 'X-RAY EXAM OF FISTULA' },
	{ modality: 'RF', display: 'X-RAY EXAM OF FISTULA' },
	{ modality: 'RF', display: 'X-RAY EXAM OF FISTULA' },
	{ modality: 'RF', display: 'FLUORO EXAM OF G/COLON TUBE' },
	{ modality: 'RF', display: 'X-RAY GUIDE FOR GI TUBE' },
	{ modality: 'RF', display: 'XRAY CONTROL CATHETER CHANGE' },
	{ modality: 'RF', display: 'NEEDLE LOCALIZATION BY XRAY' },
	{ modality: 'RF', display: 'X-RAY FEMALE GENITAL TRACT' },
	{ modality: 'RF', display: 'FLUOROSCOPE EXAMINATION' },
	{ modality: 'RF', display: 'CONTRST X-RAY URINARY TRACT' },
	{ modality: 'RF', display: 'CONTRST X-RAY URINARY TRACT' },
	{ modality: 'RF', display: 'NEEDLE LOCALIZATION BY XRAY' },
	{ modality: 'RF', display: 'CONTRST X-RAY URINARY TRACT' },
	{ modality: 'RF', display: 'NEEDLE LOCALIZATION BY XRAY' },
	{ modality: 'RF', display: 'FLUOROGUIDE FOR SPINE INJECT' },
	{ modality: 'RF', display: 'X-RAY OF LOWER SPINE DISK' },
	{ modality: 'RF', display: 'MYELOGRAPHY L-S SPINE' },
	{ modality: 'RF', display: 'CONTRST X-RAY URINARY TRACT' },
	{ modality: 'RF', display: 'CONTRAST X-RAY OF BRAIN' },
	{ modality: 'RF', display: 'CONTRAST X-RAY OF BRAIN' },
	{ modality: 'RF', display: 'CONTRAST X-RAY OF BRAIN' },
	{ modality: 'RF', display: 'CONTRAST X-RAY BLADDER' },
	{ modality: 'RF', display: 'CONTRST X-RAY URINARY TRACT' },
	{ modality: 'RF', display: 'NJX PX NFROSGRM &/URTRGRM' },
	{ modality: 'RF', display: 'CONTRST X-RAY URINARY TRACT' },
	{ modality: 'RF', display: 'CONTRST X-RAY URINARY TRACT' },
	{ modality: 'RF', display: 'CONTRST X-RAY URINARY TRACT' },
	{ modality: 'RF', display: 'X-RAY EXAM OF SALIVARY DUCT' },
	{ modality: 'RF', display: 'NEEDLE LOCALIZATION BY XRAY' },
	{ modality: 'RF', display: 'NEEDLE LOCALIZATION BY XRAY' },
	{ modality: 'RF', display: 'MYELOGPHY 2/> SPINE REGIONS' },
	{ modality: 'RF', display: 'X-RAY MALE GENITAL TRACT' },
	{ modality: 'RF', display: 'CINE/VID X-RAY THROAT/ESOPH' },
	{ modality: 'RF', display: 'X-RAY EXAM OF JAW JOINT' },
	{ modality: 'RF', display: 'MYELOGRAPHY THORACIC SPINE' },
	{ modality: 'RF', display: 'X-RAY URETHRA/BLADDER' },
	{ modality: 'RF', display: 'X-RAY URETHRA/BLADDER' },
	{ modality: 'RF', display: 'CONTRAST X-RAY OF WRIST' },
	{ modality: 'US', display: 'ECHO EXAM OF ABDOMEN' },
	{ modality: 'US', display: 'VASCULAR STUDY' },
	{ modality: 'US', display: 'VASCULAR STUDY' },
	{ modality: 'US', display: 'BX BREAST 1ST LESION US IMAG' },
	{ modality: 'US', display: 'BX BREAST 1ST LESION US IMAG' },
	{ modality: 'US', display: 'BX BREAST 1ST LESION US IMAG' },
	{ modality: 'US', display: 'BX BREAST 1ST LESION US IMAG' },
	{ modality: 'US', display: 'ECHO GUIDE FOR BIOPSY' },
	{ modality: 'US', display: 'US EXAM K TRANSPL W/DOPPLER' },
	{ modality: 'US', display: 'ECHO GUIDE FOR ARTERY REPAIR' },
	{ modality: 'US', display: 'US EXAM PELVIC COMPLETE' },
	{ modality: 'US', display: 'ECHOGRAP TRANS R PROS STUDY' },
	{ modality: 'US', display: 'VASCULAR STUDY' },
	{ modality: 'US', display: 'X-RAY EXAM BREAST SPECIMEN' },
	{ modality: 'US', display: 'X-RAY EXAM BREAST SPECIMEN' },
	{ modality: 'US', display: 'EXTRACRANIAL BILAT STUDY' },
	{ modality: 'US', display: 'EXTRACRANIAL UNI/LTD STUDY' },
	{ modality: 'RF', display: 'NASAL/OROGASTRIC W/TUBE PLMT' },
	{ modality: 'US', display: 'US URINE CAPACITY MEASURE' },
	{ modality: 'US', display: 'US XTR NON-VASC COMPLETE' },
	{ modality: 'US', display: 'US XTR NON-VASC LMTD' },
	{ modality: 'US', display: 'ECHO GUIDE FOR BIOPSY' },
	{ modality: 'US', display: 'PENILE VASCULAR STUDY' },
	{ modality: 'US', display: 'ECHO GUIDE FOR BIOPSY' },
	{ modality: 'XA', display: 'INJECTION FOR CHOLANGIOGRAM' },
	{ modality: 'XA', display: 'AV DIALYSIS SHUNT IMAGING' },
	{ modality: 'XA', display: 'CONTRST X-RAY URINARY TRACT' },
	{ modality: 'XA', display: 'X-RAY AORTA LEG ARTERIES' },
	{ modality: 'XA', display: 'INJECTION FOR URETER X-RAY' },
];

export default descriptions;
