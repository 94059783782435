// @flow

// core
import React, { type Node } from 'react';

// libs
import { Box } from '@mui/material';
import { type ISkeletonProps, LoaderSkeleton } from './LoaderSkeleton';

export const Study = ({ children, isLoading }: ISkeletonProps): Node =>
	isLoading ? (
		<Box sx={SX.skeletonWrapper}>
			<Box sx={SX.content}>
				<Box sx={SX.studySkeletonWrapper}>
					<LoaderSkeleton sx={SX.studyHeaderSkeleton} />
					<LoaderSkeleton sx={{ ...SX.studySkeleton, borderLeft: '5px solid #3CA3F5' }} />
					<LoaderSkeleton sx={{ ...SX.studySkeleton, borderLeft: '5px solid #7D7AFF' }} />
					<LoaderSkeleton sx={{ ...SX.studySkeleton, borderLeft: '5px solid #42F59F' }} />
					<LoaderSkeleton sx={{ ...SX.studySkeleton, borderLeft: '5px solid #EB459E' }} />
				</Box>
			</Box>
		</Box>
	) : (
		children
	);

const SX = {
	skeletonWrapper: {
		width: '100%',
		maxHeight: '96%',
		display: 'flex',
		padding: '10px 0px 10px 0px',
	},
	content: {
		width: '100%',
		maxHeight: '100%',
		rowGap: '10px',
		display: 'grid',
		flexDirection: 'column',
		overflow: 'auto',
		borderRadius: '10px',
	},
	studySkeletonWrapper: {
		display: 'grid',
	},
	studyHeaderSkeleton: {
		borderRadius: '5px 5px 0 0',
		width: '100%',
		height: '40px',
		marginBottom: '1px',
	},
	studySkeleton: {
		width: 'calc(100% - 5px)',
		height: '250px',
		marginBottom: '2px',
		borderRadius: '0px',
	},
};
