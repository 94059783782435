import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from 'react';

const Loader = props => (
	<Box
		sx={{
			height: '80vh',
			width: '100%',
			flex: 1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
			boxSizing: 'border-box',
			...props.style,
		}}
	>
		<Typography
			sx={{
				textAlign: 'center',
				fontSie: '16px',
				marginBottom: '8px',
				color: 'black',
			}}
		>
			<CircularProgress
				className="progressBar"
				size={22}
				sx={{
					color: '#03dac5',
					marginRight: '16px',
				}}
			/>
			Loading...
		</Typography>
	</Box>
);

export default Loader;
