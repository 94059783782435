import getMarkRanges from './getMarkRanges';

const getActiveTextStyle = ({ editor, attribute, defaultValue }) => {
	const markType = 'textStyle';
	const { empty } = editor.state.selection;

	if (empty) {
		const activeAttribute = (editor.state.storedMarks || editor.state.selection.$from.marks())
			.filter(mark => mark.type.name === markType)
			?.find(mark => mark.attrs?.[attribute])?.attrs?.[attribute];

		if (activeAttribute) {
			return {
				value: activeAttribute,
				isDefaultValue: false,
			};
		}

		return {
			value: defaultValue,
			isDefaultValue: true,
		};
	}

	const [markRanges, selectionRange] = getMarkRanges(editor);

	const markRangesHaveAttribute = markRanges.find(markRange => markRange.mark.attrs?.[attribute]);

	if (!markRangesHaveAttribute || selectionRange === 0) {
		return {
			value: defaultValue,
			isDefaultValue: true,
		};
	}

	const matchedRange = markRanges
		.filter(markRange => markRange.mark.type.name === markType)
		.filter(markRange => markRange.mark.attrs?.[attribute])
		.reduce((sum, markRange) => sum + markRange.to - markRange.from, 0);

	const excludedRange = markRanges
		.filter(markRange => markRange.mark.type !== markType && markRange.mark.type.excludes(markType))
		.reduce((sum, markRange) => sum + markRange.to - markRange.from, 0);

	const range = matchedRange > 0 ? matchedRange + excludedRange : matchedRange;

	const isActive = range >= selectionRange;

	if (!isActive) {
		return;
	}

	let activeAttribute;

	for (const markRange of markRanges) {
		const markAttribute = markRange.mark?.attrs?.[attribute];

		if (activeAttribute && markAttribute && activeAttribute !== markAttribute) {
			return;
		}

		if (markAttribute) {
			activeAttribute = markAttribute;
		}
	}

	if (activeAttribute) {
		return {
			value: activeAttribute,
			isDefaultValue: false,
		};
	}

	return {
		value: defaultValue,
		isDefaultValue: true,
	};
};

export default getActiveTextStyle;
