import React, { useContext } from 'react';
import { SelectionContext } from '@worklist-2/core/src/context/SelectionContext';
import { useWorklist } from '../../Worklist/contexts/WorklistContext';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';

const CheckboxCell = ({ data }) => {
	const { selectedStudies } = useContext(SelectionContext);

	const isSelected = selectedStudies?.map(s => s.id).includes(data.id) ?? false;

	return (
		<Box
			sx={{
				height: '20px',
				width: '20px',

				'&:hover': {
					'.checkbox': { display: 'flex' },
					'.flag': { display: 'none' },
				},
			}}
		>
			<RowCheckbox data={data} id={data.id} isSelected={isSelected} />

			<Box className="flag">
				{data.isCritical && !isSelected ? (
					<FlagOutlinedIcon
						fontSize="small"
						sx={{
							color: '#CF6679',
							border: '#CF6679',
							borderRadius: '6px',
							backgroundColor: 'rgba(207, 102, 121, 0.1)',
							width: '20px',
							height: '20px',
						}}
					/>
				) : null}
			</Box>
		</Box>
	);
};

const RowCheckbox = ({ id, isSelected, data }) => {
	const { toggleStudyPresence } = useContext(SelectionContext);
	const { closeWorklistWheel } = useWorklist();

	return (
		<Box
			className="checkbox"
			sx={{
				display: isSelected ? 'flex' : 'none',
			}}
		>
			<Checkbox
				checked={isSelected}
				checkedIcon={
					<Box
						sx={{
							height: '20px',
							width: '20px',
							borderRadius: '3px',
							background: '#42A5F5',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<CheckIcon
							fontSize="large"
							sx={{
								color: '#272727',
								width: '14px',
								height: '14px',
							}}
						/>
					</Box>
				}
				icon={
					<Box
						sx={{
							height: '18px',
							width: '18px',
							borderRadius: '3px',
							border: '2px solid rgba(255,255,255,.6)',
						}}
					/>
				}
				sx={{
					padding: '0 !important',
				}}
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
					// Close the worklist wheel if it's visible
					closeWorklistWheel();
					toggleStudyPresence({
						id,
						data,
					});
				}}
			/>
		</Box>
	);
};

export default CheckboxCell;
