import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

/**
 * Tooltip with custom width for navigators (template and study documents)
 */
const NavigatorTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: '200px',
	},
});

export default NavigatorTooltip;
