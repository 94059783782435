const pdfUtil = {
	/**
	 * Download pdf
	 * @param {*} instance instance of @pdftron/webviewer
	 */
	download: (instance, fileName) => {
		if (!instance) return;
		const { documentViewer, annotationManager } = instance.Core;
		annotationManager.exportAnnotations().then(xfdfString => {
			const doc = documentViewer.getDocument();
			doc.getFileData({ xfdfString }).then(data => {
				const arr = new Uint8Array(data);
				const pdfBlob = new Blob([arr], { type: 'application/pdf' });
				const winURL = window.URL || window.webkitURL;
				const blobUrl = winURL.createObjectURL(pdfBlob, { type: 'application/pdf' });
				const anchorElem = document.createElement('a');
				anchorElem.href = blobUrl;
				anchorElem.download = fileName ? fileName : 'download.pdf';
				anchorElem.click();
				anchorElem.remove();
				winURL.revokeObjectURL(blobUrl);
			});
		});
	},

	/**
	 * Print pdf
	 * @param {*} instance instance of @pdftron/webviewer
	 */
	print: instance => {
		instance?.UI?.print();
	},
};

export default pdfUtil;
