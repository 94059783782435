import React from 'react';

const QuoteIcon = ({ fill }) => (
	<svg fill="none" height="25" viewBox="0 0 25 25" width="25" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_31990)">
			<path
				d="M6.17145 17.5934H9.18191L11.1889 13.5795V7.55859H5.16797V13.5795H8.17842L6.17145 17.5934ZM14.1993 17.5934H17.2098L19.2168 13.5795V7.55859H13.1958V13.5795H16.2063L14.1993 17.5934Z"
				fill={fill}
			/>
		</g>
		<defs>
			<clipPath id="clip0_2852_31990">
				<rect fill={fill} height="24.0836" rx="12.0418" width="24.0836" x="0.150391" y="0.535156" />
			</clipPath>
		</defs>
	</svg>
);

export default QuoteIcon;
