import React, { useState, useRef, useEffect } from 'react';

import { EditorContent, useEditor } from '@tiptap/react';

import StarterKit from '@tiptap/starter-kit';
import Strike from '@tiptap/extension-strike';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Underline from '@tiptap/extension-underline';
import Color from '@tiptap/extension-color';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import BackgroundColor from '@worklist-2/worklist/src/DocumentViewerV3/features/DocumentViewer/Viewports/ViewportTiptap/ViewportTipTapEditor/plugins/BackgroundColor';

import TableSizeChanger from '../TableSizeChanger';
import TableActionPanel from '../TableActionPanel/TableActionPanel';

import { TableBorderIcon, TableSizeIcon, AddRowColumnIcon } from '../../../../../../assets/icons';

import styles from './TableConfigurator.module.scss';

const TableConfigurator = ({ theme, item, setCurrentTextBlock }) => {
	const [currentTable, setCurrentTable] = useState({ table: [{ text: '' }], rows: 1, columns: 3 });
	const [tableBorderHover, setTableBorderHover] = useState(false);
	const [tableSizeHover, setTableSizeHover] = useState(false);

	const countRowsAndColumns = tableHtml => {
		const tempElement = document.createElement('div');
		tempElement.innerHTML = tableHtml;

		const table = tempElement.querySelector('table');

		let rowCount = 0;
		let colCount = 0;

		if (table) {
			for (const row of table.rows) {
				rowCount++;

				if (colCount < row.cells.length) {
					colCount = row.cells.length;
				}
			}
		}

		setCurrentTable(prev => ({ ...prev, rows: rowCount, columns: colCount }));
	};

	useEffect(() => {
		if (item?.html) {
			countRowsAndColumns(item.html);
		}
	}, [item]);

	const tableSizeIconRef = useRef(null);
	const tableSizeChangerRef = useRef(null);

	const handleOutSideClick = event => {
		const path = event.path || (event.composedPath && event.composedPath());
		if (!path.includes(tableSizeIconRef.current) && !path.includes(tableSizeChangerRef.current)) {
			setTableSizeHover(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleOutSideClick);

		return () => {
			document.removeEventListener('mousedown', handleOutSideClick);
		};
	}, [tableSizeIconRef, tableSizeChangerRef]);

	const defaultContent = `
		<table>
			<tbody>
				<tr>
					<td><p></p></td>
					<td><p></p></td>
					<td><p></p></td>
				</tr>
			</tbody>
		</table>
	`;

	const editor = useEditor({
		extensions: [
			BackgroundColor,
			Color,
			StarterKit,
			Strike,
			Table.configure({
				resizable: false,
			}),
			TableRow,
			TableHeader,
			TableCell,
			TextAlign.configure({
				alignments: ['left', 'center', 'right'],
				types: ['paragraph'],
			}),
			TextStyle,
			Underline,
		],
		content: item?.html ? item.html : defaultContent,
		onUpdate: ({ editor: editorInstance }) => {
			const html = editorInstance.getHTML();
			const text = editorInstance.getText();
			if (text !== '') {
				setCurrentTextBlock(prev => ({
					...prev,
					tableText: text,
					tableHtml: html,
				}));
			}
		},
	});

	const onAddRow = () => {
		editor.commands.addRowAfter();

		setCurrentTable(prev => ({ ...prev, rows: prev.rows + 1 }));
	};
	const onAddColumn = () => {
		editor.commands.addColumnAfter();

		setCurrentTable(prev => ({ ...prev, columns: prev.columns + 1 }));
	};

	const iconStyles = `${styles.tableConfiguratorIconsButton} ${
		theme === 'OAI' ? styles.tableConfiguratorIconsButtonOAI : styles.tableConfiguratorIconsButtonBlume
	}`;

	return (
		<div
			className={`${styles.tableConfigurator} ${theme === 'OAI' ? styles.textOAI : styles.textBlume}`}
			data-testid="table-configurator"
		>
			<div className={styles.tableConfiguratorIcons}>
				<div
					className={iconStyles}
					data-testid="tableBorderIcon"
					onMouseLeave={() => setTableBorderHover(false)}
					onMouseOver={() => setTableBorderHover(true)}
				>
					<TableBorderIcon isHover={tableBorderHover} />
				</div>
				<div
					ref={tableSizeIconRef}
					className={iconStyles}
					data-testid="table-size-icon"
					onMouseOver={() => setTableSizeHover(true)}
				>
					<TableSizeIcon isHover={tableSizeHover} />
				</div>
				<div ref={tableSizeChangerRef} className={styles.tableSizeBox}>
					{tableSizeHover ? (
						<TableSizeChanger
							currentTable={currentTable}
							editor={editor}
							setCurrentTable={setCurrentTable}
							setTableSizeHover={setTableSizeHover}
						/>
					) : (
						''
					)}
				</div>
			</div>
			<div className={styles.main}>
				<div className={styles.editor}>
					<div className={styles.editorContent}>
						<EditorContent editor={editor} />
					</div>
					<div className={styles.addColumn} data-testid="addColumn" onClick={onAddColumn}>
						<AddRowColumnIcon />
					</div>
				</div>
				<div className={styles.addRow} data-testid="addRow" onClick={onAddRow}>
					<AddRowColumnIcon />
				</div>
			</div>

			{editor && <TableActionPanel editor={editor} />}
		</div>
	);
};

export default TableConfigurator;
