import React from 'react';

const EditTitleIcon = ({ fill }) => (
	<svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2852_32719)">
			<path
				d="M10.545 6.765L11.235 7.455L4.44 14.25H3.75V13.56L10.545 6.765ZM13.245 2.25C13.0575 2.25 12.8625 2.325 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28C15.825 4.9875 15.825 4.515 15.5325 4.2225L13.7775 2.4675C13.6275 2.3175 13.44 2.25 13.245 2.25ZM10.545 4.6425L2.25 12.9375V15.75H5.0625L13.3575 7.455L10.545 4.6425Z"
				fill={fill || 'white'}
				fillOpacity={fill ? 1 : 0.6}
			/>
		</g>
		<defs>
			<clipPath id="clip0_2852_32719">
				<rect fill="white" height="18" width="18" />
			</clipPath>
		</defs>
	</svg>
);

export default EditTitleIcon;
