import React, { useState, useMemo } from 'react';
import axios from 'axios';
import { Box, Button, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useConfig } from '@worklist-2/core/src';
import { useCrmContext } from '../../../../../context/CRMContext';
import ToggleSwitch from '../../../LiveChat/ToggleSwitch';
import CircularProgress from '@mui/material/CircularProgress';

const Features = ({ accountData, features, currentOrganizationID, canEdit, setFeatures }) => {
	const __config = useConfig();
	const { showDetails } = useCrmContext();
	const [selectedFeature, setSelectedFeature] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleTogglePermission = currentFeature => {
		const payload = {
			FeatureExtJson: features.map(feature => {
				if (feature.resource !== currentFeature) {
					return feature;
				}

				if (feature.action.includes('read')) {
					feature.action = feature.action.filter(action => action !== 'read');
				} else {
					feature.action.push('read');
				}

				return feature;
			}),
		};

		saveOrganizationFeatures(payload);
		return true;
	};

	const handleToggleSubPermission = currentFeature => {
		const tempSubFeatures = _.cloneDeep(selectedFeature);
		const tempPermissions = tempSubFeatures.permissions.map(subFeature => {
			if (subFeature.resource !== currentFeature) {
				return subFeature;
			}

			if (subFeature.action.includes('read')) {
				subFeature.action = subFeature.action.filter(action => action !== 'read');
			} else {
				subFeature.action.push('read');
			}

			return subFeature;
		});

		tempSubFeatures.permission = tempPermissions;

		const payload = {
			FeatureExtJson: features.map(feature => {
				if (feature.resource !== tempSubFeatures.resource) {
					return feature;
				}
				return tempSubFeatures;
			}),
		};

		setSelectedFeature(tempSubFeatures);
		saveOrganizationFeatures(payload);
		return true;
	};

	const toggleAll = useMemo(() => {
		if (selectedFeature) {
			return _.findIndex(selectedFeature.permissions, { action: ['read'] }) <= -1;
		}
	}, [selectedFeature]);

	const handleToggleAllSubPermission = async () => {
		setLoading(true);
		const tempSubFeatures = _.cloneDeep(selectedFeature);
		const tempPermissions = tempSubFeatures.permissions.map(subFeature => {
			if (toggleAll) {
				if (!subFeature.action.includes('read')) {
					subFeature.action.push('read');
				}
			} else {
				subFeature.action = subFeature.action.filter(action => action !== 'read');
			}

			return subFeature;
		});

		tempSubFeatures.permission = tempPermissions;

		const payload = {
			FeatureExtJson: features.map(feature => {
				if (feature.resource !== tempSubFeatures.resource) {
					return feature;
				}
				return tempSubFeatures;
			}),
		};

		setSelectedFeature(tempSubFeatures);
		saveOrganizationFeatures(payload);
		await new Promise(r => setTimeout(r, 300));
		setLoading(false);
	};

	const saveOrganizationFeatures = async payload => {
		let IsSuccess = false;
		setFeatures(payload.FeatureExtJson);

		await axios
			.put(
				`${__config.data_sources.breeze}/OrganizationOmegaAI/${accountData?.AccountId}/${currentOrganizationID}/features`,
				payload
			)
			.then(resp => {
				if (resp?.status === 200) {
					IsSuccess = true;
				}
			});

		return IsSuccess;
	};

	return (
		<Box
			sx={{
				padding: selectedFeature ? '20px 30px' : '20px 0',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			{selectedFeature ? (
				<Box
					sx={{
						boxSizing: 'border-box',
						width: '100%',
						padding: '22px 40px',
						backgroundColor: '#F9FAFF',
						borderRadius: '10px',
						boxShadow: '0px 4px 4px rgba(77, 121, 234, 0.1)',
					}}
				>
					<Box sx={{ display: 'flex' }}>
						<IconButton sx={{ padding: 0, height: '24px' }} onClick={() => setSelectedFeature(null)}>
							<ArrowBackIcon sx={{ color: '#4D79EA' }} />
						</IconButton>
						<Box sx={{ marginLeft: '18px' }}>
							<Typography
								sx={{
									fontSize: '16px',
									lineHeight: '19px',
									fontWeight: 500,
									color: 'rgba(0, 0, 0, 0.9)',
								}}
							>
								{selectedFeature.display}
							</Typography>
							<Typography
								sx={{
									fontSize: '12px',
									lineHeight: '12px',
									fontStyle: 'italic',
									color: 'rgba(0, 0, 0, 0.6)',
								}}
							>{`${
								selectedFeature.permissions.filter(item => item.action?.length > 0)?.length
							} permissions defined`}</Typography>
						</Box>
					</Box>
					<Button
						sx={{
							marginTop: '20px',
							padding: '2px 10px',
							border: '1px solid #4D79EA',
							fontSize: '12px',
							letterSpacing: '0.5px',
							color: toggleAll ? '#4D79EA' : '#C4C4C4',
						}}
						onClick={handleToggleAllSubPermission}
					>
						View All
					</Button>
					<Box
						sx={{
							marginTop: '10px',
							paddingRight: '6px',
							width: '100%',
							height: '240px',
							overflowY: 'auto',
							'&::-webkit-scrollbar-thumb': {
								background: '#C4C4C4 !important',
								borderRadius: '3px',
							},

							'&::-webkit-scrollbar-track': {
								background: '#FFF !important',
							},
						}}
					>
						{!loading ? (
							selectedFeature.permissions.map(item => (
								<Box
									key={item.display}
									sx={{
										boxSizing: 'border-box',
										width: '100%',
										marginBottom: '8px',
										padding: '16px 0 12px 14px',
										display: 'flex',
										backgroundColor: '#FFFFFF',
										borderRadius: '10px',
										border: '1px solid rgba(196, 196, 196, 0.5)',
										'&:hover': {
											backgroundColor: 'rgba(77, 121, 234, 0.06)',
											border: '1px solid #4D79EA;',
										},
									}}
								>
									<VisibilitySwitch
										canEdit={canEdit}
										data={item}
										onToggle={handleToggleSubPermission}
									/>
									<Typography
										sx={{ marginLeft: '20px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.87)' }}
									>
										{item.display}
									</Typography>
								</Box>
							))
						) : (
							<Box
								sx={{
									height: '100%',
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									flexDirection: 'column',
									paddingBottom: '100px',
									boxSizing: 'border-box',
								}}
							>
								<Typography
									sx={{
										textAlign: 'center',
										fontSie: '16px',
										marginBottom: '8px',
										color: 'black',
									}}
								>
									<CircularProgress
										className="progressBar"
										size={22}
										sx={{
											color: '#03dac5',
											marginRight: '16px',
										}}
									/>
									Loading...
								</Typography>
							</Box>
						)}
					</Box>
				</Box>
			) : (
				<Box
					sx={{
						boxSizing: 'border-box',
						maxWidth: showDetails ? '448px' : '908px',
						display: 'flex',
						flexWrap: 'wrap',
						gap: '12px',
					}}
				>
					{features.map(item => (
						<FeatureItem
							key={item.display}
							canEdit={canEdit}
							data={item}
							onClick={() => setSelectedFeature(item)}
							onToggle={handleTogglePermission}
						/>
					))}
				</Box>
			)}
		</Box>
	);
};

const FeatureItem = ({ data, onClick, onToggle, canEdit }) => {
	const [isActive, setIsActive] = useState(data.action.includes('read'));
	const [isFetch, setIsFetch] = useState(false);

	const handleSwitchClick = async permission => {
		setIsFetch(true);
		const IsSuccess = await onToggle(permission);

		if (IsSuccess) {
			setIsActive(prev => !prev);
		}
		setIsFetch(false);
	};

	return (
		<Box
			sx={{
				boxSizing: 'border-box',
				width: '218px',
				padding: '20px 20px 12px 18px',
				backgroundColor: ' #F9FAFF',
				boxShadow: '0px 4px 4px rgba(77, 121, 234, 0.1)',
				borderRadius: '10px',
				cursor: 'pointer',
			}}
			onClick={onClick}
		>
			<Box sx={{ marginBottom: '6px', display: 'flex', justifyContent: 'space-between' }}>
				<Typography
					sx={{
						fontSize: '16px',
						lineHeight: '19px',
						fontWeight: 500,
						color: 'rgba(0, 0, 0, 0.9)',
					}}
				>
					{data.display}
				</Typography>
				<SettingsIcon sx={{ fontSize: '18px', color: '#4D79EA' }} />
			</Box>
			<Typography
				sx={{
					marginBottom: '14px',
					fontSize: '12px',
					lineHeight: '12px',
					fontStyle: 'italic',
					color: 'rgba(0, 0, 0, 0.6)',
				}}
			>{`${data.permissions.filter(item => item.action?.length > 0)?.length} permissions defined`}</Typography>
			<ToggleSwitch
				disabled={!canEdit || isFetch}
				isActive={isActive}
				onClick={() => handleSwitchClick(data.resource)}
			/>
		</Box>
	);
};

const VisibilitySwitch = ({ data, onToggle, canEdit }) => {
	const [isActive, setIsActive] = useState(data.action.includes('read'));
	const handleClick = async permission => {
		const IsSuccess = await onToggle(permission);

		if (IsSuccess) {
			setIsActive(prev => !prev);
		}
	};

	return isActive ? (
		<VisibilityIcon
			disabled={!canEdit}
			sx={{ color: '#4D79EA', cursor: 'pointer' }}
			onClick={() => handleClick(data.resource)}
		/>
	) : (
		<VisibilityOffIcon
			disabled={!canEdit}
			sx={{ color: '#C4C4C4', cursor: 'pointer' }}
			onClick={() => handleClick(data.resource)}
		/>
	);
};

export default Features;
