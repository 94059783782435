import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import BlumeLogo from '@worklist-2/ui/src/assets/icons/blume_logo.svg';
import OmegaLogo from '@worklist-2/ui/src/assets/icons/Chat/omega-ai.svg';
import useLongPress from '../hooks/useLongPress';

const DocumentListItem = ({ data, title, subData, addRecentlyAccessed, isSelected, onSelected }) => {
	const details = subData.split('|');
	const isOmega = details[0] === 'omegaai';
	const navigate = useNavigate();

	const onClick = () => {
		addRecentlyAccessed(data.DocumentDraftId);
		isOmega
			? navigate('/documentation/help-center/omegaai', {
					state: {
						fileData: data,
					},
			  })
			: navigate('/documentation/help-center/blume', {
					state: {
						fileData: data,
					},
			  });
	};

	const onLongPress = () => {
		onSelected(data.DocumentDraftId);
	};

	const longPressEvent = useLongPress(onLongPress, onClick);

	const displayDetails = () => {
		if (!details[1]) {
			return '';
		}

		return details[2] ? `${details[1]} • ${details[2]}` : details[1];
	};

	return (
		<Box sx={{ cursor: 'pointer' }} {...longPressEvent}>
			<Box
				sx={{
					position: 'relative',
					width: '100%',
					height: '250px',
					borderRadius: '20px',
					objectFit: 'cover',
					backgroundColor: isOmega ? 'black' : '#F3F3F3', //temp
				}}
			>
				{isSelected && (
					<Box
						data-testid="selected-overlay"
						sx={{
							boxSizing: 'border-box',
							position: 'absolute',
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(75, 170, 246, 0.20)',
							border: '2px solid #4BAAF6',
							borderRadius: '20px',
						}}
					/>
				)}
			</Box>
			<Box sx={{ marginTop: '20px', display: 'flex' }}>
				<Box sx={{ position: 'relative', display: 'flex' }}>
					<Box
						sx={{
							boxSizing: 'border-box',
							width: '30px',
							height: '30px',
							marginRight: '24px',
							padding: isOmega ? 0 : '6px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: isOmega ? '#005675' : '#F7F9FF',
							border: '1px solid rgba(196, 196, 196, 0.40)',
							borderRadius: '50%',
						}}
					>
						{isOmega ? <OmegaLogo /> : <BlumeLogo />}
					</Box>
					<Box
						sx={{
							boxSizing: 'border-box',
							position: 'absolute',
							right: 0,
							width: '30px',
							height: '30px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: '#F9FAFC',
							border: '1px solid rgba(196, 196, 196, 0.40)',
							borderRadius: '50%',
						}}
					>
						<StickyNote2OutlinedIcon sx={{ fontSize: '15px', color: '#4D79EA' }} />
					</Box>
				</Box>
				<Box sx={{ marginLeft: '10px' }}>
					<Typography
						sx={{ fontSize: '24px', fontWeight: 500, lineHeight: '30px', color: 'rgba(0, 0, 0, 0.87)' }}
					>
						{title}
					</Typography>
					<Typography
						sx={{
							fontSize: '16px',
							fontWeight: 400,
							fontStyle: 'italic',
							color: 'rgba(0, 0, 0, 0.60)',
						}}
					>
						{displayDetails()}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default DocumentListItem;
