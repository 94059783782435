import { getSchema } from '../datatypes';
import { validateArgs } from './validateArgs';

/***************************************************** doseAndRate *****************************************************
{
  "type": "__CodeableConcept__",
  "doseRange": "__Range__",
  "doseQuantity": "__Quantity(SimpleQuantity)__",
  "rateRatio": "__Ratio__",
  "rateRange": "__Range__",
  "rateQuantity": "__Quantity(SimpleQuantity)__"
}
***********************************************************************************************************************/

const buildDosageFunc = args => {
	const {
		id,
		extension,
		sequence,
		text,
		additionalInstruction,
		patientInstruction,
		timing,
		asNeededBoolean,
		asNeededCodeableConcept,
		site,
		route,
		method,
		doseAndRate,
		maxDosePerPeriod,
		maxDosePerAdministration,
		maxDosePerLifetime,
	} = args;
	const schema = getSchema('Dosage');
	let valid = validateArgs(schema, args, Object.keys(args));

	return {
		id: id,
		extension: extension,
		sequence: sequence,
		text: text,
		additionalInstruction: additionalInstruction,
		patientInstruction: patientInstruction,
		timing: timing,
		asNeededBoolean: asNeededBoolean,
		asNeededCodeableConcept: asNeededCodeableConcept,
		site: site,
		route: route,
		method: method,
		doseAndRate: doseAndRate,
		maxDosePerPeriod: maxDosePerPeriod,
		maxDosePerAdministration: maxDosePerAdministration,
		maxDosePerLifetime: maxDosePerLifetime,
		__valid: valid,
	};
};

export default buildDosageFunc;
