import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import React, { useEffect, useState } from 'react';
import { TabPanel } from '../../../Cases/casePopOverStyles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { crmSearchScopes, useCRMDataLoader } from '@worklist-2/core/src';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.white,
		borderWidth: 0,
		color: '#666666',
		fontWeight: '400',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		borderWidth: 0,
		color: theme.palette.common.black,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&': {
		color: theme.palette.common.black,
	},
	'&:hover td': {
		backgroundColor: '#C8D6FF',
		color: theme.palette.common.black,
	},
	'& td': {
		padding: 10,
		alignItems: 'center',
		color: theme.palette.common.black,
	},
	'&:nth-of-type(odd)': {
		backgroundColor: '#F9FBFF',
	},
	'&:nth-of-type(even)': {
		backgroundColor: theme.palette.common.white,
	},
}));

const Cases = ({ accountData, fullScreen }) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const caseDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.caseApi,
	});

	useEffect(() => {
		fetchData().catch(console.error);
	}, [accountData]);

	const fetchData = async (loader, value) => {
		const fnName = 'load';
		const bodyData = undefined;
		if (!loader) {
			loader = caseDataLoader;
		}

		setLoading(true);
		setData(null);

		try {
			await loader[fnName]({ accountid: accountData.AccountId }, !!bodyData || undefined, bodyData).then(
				result => {
					const newData = result;
					setData(newData);
					setLoading(false);
				}
			);
		} catch (e) {
			console.error(e);
			setLoading(false);
		}
	};

	return !loading && accountData ? (
		<TabPanel>
			<div className="header">
				<div className="left">
					<div className="colored-icon pink-icon">
						<WorkOutlineOutlinedIcon sx={{ fontSize: 20 }} />
					</div>
					<h2>Cases</h2>
				</div>
			</div>
			<div className="body">
				<TableContainer
					sx={{
						height: fullScreen ? '300px' : 'unset',
						'&::-webkit-scrollbar-thumb': {
							background: '#C4C4C4 !important',
							borderRadius: '3px',
						},

						'&::-webkit-scrollbar-track': {
							background: '#FFF !important',
						},
					}}
				>
					<Table aria-label="customized table" stickyHeader={fullScreen}>
						<TableHead>
							<TableRow sx={fullScreen && { height: '30px' }}>
								<StyledTableCell align="center" sx={fullScreen && { padding: 0 }}>
									Case No.
								</StyledTableCell>
								<StyledTableCell align="center" sx={fullScreen && { padding: 0 }}>
									Status
								</StyledTableCell>
								<StyledTableCell align="center" sx={fullScreen && { padding: 0 }}>
									Issue Type
								</StyledTableCell>
								<StyledTableCell align="center" sx={fullScreen && { padding: 0 }}>
									Priority
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody style={{ flex: '1' }}>
							{data && data.length > 0 ? (
								data.map((row, i) => (
									<StyledTableRow key={i}>
										<StyledTableCell align="center">{row.CaseNumber || '-'}</StyledTableCell>
										<StyledTableCell align="center">{row.Status || '-'}</StyledTableCell>
										<StyledTableCell align="center">{row.Type || '-'}</StyledTableCell>
										<StyledTableCell align="center">{row.Priority || '-'}</StyledTableCell>
									</StyledTableRow>
								))
							) : (
								<StyledTableRow>
									<StyledTableCell colSpan={4}>
										<Box
											sx={{
												height: '100%',
												width: '100%',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<Typography
												sx={{
													textAlign: 'center',
													fontSie: '16px',
													marginBottom: '8px',
													color: 'black',
												}}
											>
												No Result
											</Typography>
										</Box>
									</StyledTableCell>
								</StyledTableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</TabPanel>
	) : (
		<Box
			sx={{
				height: '100%',
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				paddingBottom: '100px',
				boxSizing: 'border-box',
			}}
		>
			<Typography
				sx={{
					textAlign: 'center',
					fontSie: '16px',
					marginBottom: '8px',
					color: 'black',
				}}
			>
				<CircularProgress
					className="progressBar"
					size={22}
					sx={{
						color: '#03dac5',
						marginRight: '16px',
					}}
				/>
				Loading...
			</Typography>
		</Box>
	);
};

export default Cases;
