const invoiceSchema = {
	id: '__string__',
	resourceType: '__string__',
	status: '__string__',
	identifier: ['__Identifier__'],
	cancelledReason: '__string__',
	type: '__CodeableConcept__',
	subject: '__Reference(Patient|Group)__',
	recipient: '__Reference(Organization|Patient|RelatedPerson)__',
	date: '__dateTime__',
	participant: [
		{
			role: '__CodeableConcept__',
			actor: '__Reference(Practitioner|Organization|Patient|PractitionerRole|Device|RelatedPerson)__',
		},
	],
	issuer: '__Reference(Organization)__',
	account: '__Reference(Account)__',
	totalPriceComponent: [
		{
			type: '__code__',
			code: '__CodeableConcept__',
			factor: '__decimal__',
			amount: '__Money__',
		},
	],
	totalNet: '__Money__',
	totalGross: '__Money__',
	paymentTerms: '__markdown__',
	note: '__Annotation__',
};

export default invoiceSchema;
