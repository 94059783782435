// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oiZgel0YA_q_vptcq5WD{width:100vw;min-height:100vh;box-sizing:border-box}.oiZgel0YA_q_vptcq5WD *{box-sizing:border-box}.oiZgel0YA_q_vptcq5WD * button,.oiZgel0YA_q_vptcq5WD * input,.oiZgel0YA_q_vptcq5WD * textarea{border:none;outline:none}.b8cf33Od25sGpvRSyQLH{background:#272727}.dJrGTTqBtRk66Rj24QYg{background:#f3f3f3}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/Layout/Layout/Layout.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,gBAAA,CAEA,qBAAA,CAGF,wBACE,qBAAA,CAEA,8FAGE,WAAA,CACA,YAAA,CAIJ,sBACE,kBAAA,CAGF,sBACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "oiZgel0YA_q_vptcq5WD",
	"OAITheme": "b8cf33Od25sGpvRSyQLH",
	"BlumeTheme": "dJrGTTqBtRk66Rj24QYg"
};
export default ___CSS_LOADER_EXPORT___;
