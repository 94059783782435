import React from 'react';
import Box from '@mui/material/Box';
import MobileSharedWithSection from './MobileStudyDetailSections/MobileSharedWithSection';
import MobilePhysicianInformationSection from './MobileStudyDetailSections/MobilePhysicianInformationSection';
import MobileStudyInformationSection from './MobileStudyDetailSections/MobileStudyInformationSection';
import MobileAppointmentLocationSection from './MobileStudyDetailSections/MobileAppointmentLocationSection';
import MobileMapSection from './MobileStudyDetailSections/MobileMapSection';
import { useIsMobile } from '@worklist-2/core/src';
import FormsView from '../../../views/StudyInfoView/StudyDetailedView/FormsView';

const MobileStudyDetailScheduled = ({
	study,
	studyAdditionalInfo,
	onShareClick,
	appointmentLocation,
	appointmentDate,
}) => {
	const isMobile = useIsMobile();

	return (
		<Box data-testid="scheduledDetailMobile">
			{!isMobile && <MobileMapSection appointmentDate={appointmentDate} study={study} />}

			<Box sx={{ padding: '16px' }}>
				<MobileStudyInformationSection study={study} />
			</Box>

			{isMobile && <MobileMapSection study={study} />}

			<Box sx={{ padding: '16px' }}>
				<MobilePhysicianInformationSection study={study} />

				<MobileAppointmentLocationSection appointmentLocation={appointmentLocation} study={study} />
				{study?.patientId && <FormsView patientId={study.patientId} studyId={study.id} />}
				<MobileSharedWithSection
					study={study}
					studyAdditionalInfo={studyAdditionalInfo}
					onShareClick={onShareClick}
				/>
			</Box>
		</Box>
	);
};

export default MobileStudyDetailScheduled;
