export const clearDuplicates = (sourceArray, compareArray) => {
	const studyIDs = compareArray.map(item => item.studyID);
	return sourceArray.filter(item => !studyIDs.includes(item.studyID));
};

export const findIdenticalItems = (sourceArray, compareArray) => {
	const studyIDs = sourceArray.map(item => item.studyID);
	return compareArray.filter(item => studyIDs.includes(item.studyID));
};

export const hasAtLeastOneNonIdenticalItem = (sourceArray, compareArray) => {
	return sourceArray.some(item1 => !compareArray.some(item2 => item2.studyID === item1.studyID));
};
