// core
import React, { useState, useRef, useCallback, useEffect } from 'react';

// MUI
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

// icons
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

// libraries
import PropTypes from 'prop-types';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

import { useConfig, useAuth, searchScopes, useFhirDataLoader } from '@worklist-2/core/src';
import { defaultOptions } from '@rs-ui/views/utils/defaultPillDateOptions';

// components
import SearchableSingleSelectColumnFilter from '@worklist-2/ui/src/components/SearchableSingleSelectColumnFilter';
import SearchableMultiSelectColumnFilter from '@worklist-2/ui/src/components/SearchableMultiSelectColumnFilter';
import SearchableCheckboxMultiSelectColumnFilter from '@worklist-2/ui/src/components/SearchableCheckboxMultiSelectColumnFilter';
import Suggest from '@worklist-2/ui/src/components/Suggest';
import DatePicker from '../../../../DatePicker';
import DateTimeRangePicker from '../../../../DateTimeRangePicker/DateTimeRangePicker';

export const onMultiSelect = (preConfiguredColumnList, onChange, value, displayValue, columnName) => {
	const tempArr = preConfiguredColumnList;
	const list = _.map(tempArr, item => {
		if (item.name === columnName) {
			return {
				...item,
				filter: value ?? [],
				displayValue: displayValue ?? value ?? [],
			};
		}
		return item;
	});

	if (onChange && !_.isEqual(tempArr, list)) {
		onChange(list);
	}
};

const Filters = ({
	isLarge,
	preConfiguredColumnList,
	columnList,
	onChange,
	filters,
	height,
	placeholder = 'Search',
}) => {
	const refArray = {};
	const { t } = useTranslation('drawer');
	placeholder = t(placeholder);
	const { userRoleForOrganizations, userPrivilegesForOrganizations, userAssociatedWithOnlyOneOrg } = useAuth();

	filters &&
		_.forEach(columnList, item => {
			const matchingFilter = _.find(filters, element => element.label === item.label);
			let refValue = '';
			if (matchingFilter) {
				if (_.isArray(matchingFilter.values)) {
					refValue = matchingFilter.values[0];
				} else {
					refValue = matchingFilter;
				}
			}
			refArray[item.label] = useRef(refValue);
		});

	const [columnRefs, setColumnRefs] = useState(refArray);
	const [managingOrgId, setManagingOrgId] = useState(null);

	const crossGetPrivilegeOnDemand = useBooleanFlagValue('cross-get-privilege-on-demand');

	const organizationLoader = useFhirDataLoader({ scope: searchScopes.organization });

	useEffect(() => {
		setColumnRefs(refArray);
	}, []);

	const __config = useConfig();

	// In case the login user belongs to only one organziation, use study statuses of this organization for worklist settings
	useEffect(() => {
		// set managingOrgId
		if (crossGetPrivilegeOnDemand) {
			const managingOrgIdCheck = userAssociatedWithOnlyOneOrg
				? userPrivilegesForOrganizations?.current[0]?.organizationId
				: filters.find(f => f.searchParameter === 'internalManagingOrganizationID')?.values;
			setManagingOrgId(managingOrgIdCheck);
		} else {
			const managingOrgIdCheck =
				(userRoleForOrganizations.length === 1 && userRoleForOrganizations[0].organizationId) ||
				preConfiguredColumnList.find(i => i.name === 'managingOrganization')?.filter;
			setManagingOrgId(managingOrgIdCheck);
		}
	}, [
		crossGetPrivilegeOnDemand,
		crossGetPrivilegeOnDemand ? userAssociatedWithOnlyOneOrg : userRoleForOrganizations,
	]);

	const { data: studyStatusData } = useQuery({
		queryKey: [`studyStatus${managingOrgId}`],
		queryFn: async () => {
			if (managingOrgId) {
				const result = await axios.get(
					`${__config.data_sources.fhir}/studyStatus?organization=${managingOrgId}&_sort=statusValue`
				);
				return result.data.entry.map(e => ({ id: e.resource.id, display: e.resource.status }));
			}
		},
	});

	const { data: managingOrgIdsFromAPI } = useQuery({
		queryKey: ['fetchMasterOrganizations'],
		queryFn: async () => {
			const managingOrgs = await organizationLoader.load({
				partOfMissing: true,
				getmissingorgs: 'masters',
				isReferring: false,
				summary: true,
				count: 500,
			});
			const managingOrgIds = managingOrgs?.map(entry => entry?.id).join(',');
			return managingOrgIds;
		},
		refetchOnWindowFocus: false,
		enabled: crossGetPrivilegeOnDemand,
	});

	const managingOrgIds = crossGetPrivilegeOnDemand
		? managingOrgIdsFromAPI
		: userRoleForOrganizations.map(u => u.organizationId).join(',');

	const { data: procedureCodeData } = useQuery({
		queryKey: [`procedureCode${managingOrgIds}`],
		queryFn: async () => {
			if (managingOrgIds) {
				const result = await axios.get(
					`${__config.data_sources.fhir}/ProcedureCode?organizationId=${managingOrgIds}&page=1&_count=1000&_sort=code`
				);
				return result.data.entry.map(e => ({ code: e.resource.code, display: e.resource.display }));
			}
			return [];
		},
		refetchOnWindowFocus: false, // false so this won't get called repeatedly
	});

	const handleStringSelect = useCallback(
		(value, columnName, displayValue) => {
			const tempArr = preConfiguredColumnList;
			const list = _.map(tempArr, item => {
				if (item.name === columnName) {
					return {
						...item,
						filter: value ?? '',
						displayValue: displayValue ?? value ?? '',
					};
				}
				return item;
			});

			if (onChange && !_.isEqual(tempArr, list)) {
				onChange(list);
			}
		},
		[preConfiguredColumnList]
	);

	const pillOptions = ['Last 24 hrs', 'Today', 'Tomorrow', 'Week', 'Month', 'Last 7 days', 'Last 30 days'];
	const handleDatetimeSelect = useCallback(
		(value, columnName) => {
			const tempArr = preConfiguredColumnList;
			const list = _.map(tempArr, item => {
				let filterVal = [];
				if (item.name === columnName) {
					const itemsFlat = _.flatten(value);
					if (itemsFlat && itemsFlat.length == 2) {
						filterVal = [itemsFlat?.[0], itemsFlat?.[1]];
					} else if (pillOptions.includes(value)) {
						filterVal = value;
					}

					return {
						...item,
						filter: filterVal,
						displayValue: filterVal,
					};
				}
				return item;
			});

			if (onChange && !_.isEqual(tempArr, list)) {
				onChange(list);
			}
		},
		[preConfiguredColumnList]
	);

	const handleDateSelect = useCallback(
		(value, columnName) => {
			const tempArr = preConfiguredColumnList;
			const list = _.map(tempArr, item => {
				if (item.name === columnName) {
					return {
						...item,
						filter: value,
						displayValue: value?.toLocaleDateString(),
					};
				}
				return item;
			});

			if (onChange && !_.isEqual(tempArr, list)) {
				onChange(list);
			}
		},
		[preConfiguredColumnList]
	);

	const handleMultiSelect = useCallback(
		(value, displayValue, columnName) => {
			onMultiSelect(preConfiguredColumnList, onChange, value, displayValue, columnName);
		},
		[preConfiguredColumnList]
	);

	const filterValues = userColumn =>
		_.filter(columnList, column => column.name === userColumn.name && column.filterType !== 'none');

	const handleClearTextSearch = (fieldRef, fieldName) => {
		// clear input value
		if (fieldRef.current) fieldRef.current.value = '';

		// remove filter value
		handleStringSelect('', fieldName, '');
	};

	const calculateComponent = useCallback(
		// userColumn from preConfiguredColumnList, filter from columnList
		(userColumn, filter) => {
			let componentToReturn = null;
			let defaultDisplayValue;
			const separator = filter.filterType === 'date-range' ? ' - ' : '|';
			try {
				defaultDisplayValue = _.isArray(userColumn.displayValue)
					? userColumn.displayValue.join(separator)
					: JSON.parse(userColumn.displayValue);
			} catch {
				defaultDisplayValue = userColumn.displayValue;
			}

			switch (filter.filterType) {
				case 'multi-select':
					componentToReturn = (
						<ListItem
							key={filter.label + filter.filterType}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<SearchableMultiSelectColumnFilter
								labelAlwaysShrunk
								displayValue={
									['accountStatus', 'requeststatus'].includes(filter.valueSet) ||
									filter.label === 'Procedure Code'
										? 'code'
										: 'display'
								}
								fieldRef={columnRefs[filter.label]}
								hideIcon={false}
								label={filter.label}
								options={
									filter.valueSet == 'status' && studyStatusData?.length > 0
										? studyStatusData
										: filter.label == 'Procedure Code' && procedureCodeData?.length > 0
										? procedureCodeData
										: undefined
								}
								preSelectedValues={defaultDisplayValue}
								testId={`${filter.label}_filter`}
								valueSetType={filter.valueSet}
								width={390}
								onSelectFilter={input => {
									handleMultiSelect(input.selectedOptions, null, filter.name);
								}}
							/>
						</ListItem>
					);
					break;
				case 'checkbox-multi-select':
					componentToReturn = (
						<ListItem
							key={filter.label + filter.filterType}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<SearchableCheckboxMultiSelectColumnFilter
								labelAlwaysShrunk
								customFilterOptions={userColumn.customFilterOptions}
								fieldRef={columnRefs[filter.label]}
								hideIcon={false}
								label={filter.label}
								options={
									filter.valueSet === 'status' && studyStatusData?.length > 0
										? studyStatusData
										: undefined
								}
								placeholder={placeholder}
								preSelectedValues={defaultDisplayValue}
								testId={`${filter.label}_filter`}
								valueSetType={filter.valueSet ?? filter?.searchParameter}
								width={390}
								onSelectFilter={(input, displayValue) => {
									handleMultiSelect(input.selectedOptions, displayValue, filter.name);
								}}
							/>
						</ListItem>
					);
					break;
				case 'text-search':
					componentToReturn = (() => {
						const [currentValue, setCurrentValue] = useState('');
						const [hovered, setHovered] = useState(false);

						return (
							<ListItem
								key={filter.label + filter.filterType}
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<TextField
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="end">
												{currentValue && hovered && (
													<IconButton
														aria-label={t('Clear')}
														data-cy={`${filter.label}_clear`}
														title={t('Clear')}
														onClick={event => {
															handleClearTextSearch(
																columnRefs[filter.label],
																filter.name
															);
															setCurrentValue(
																columnRefs[filter.label]?.current?.value?.trim()
															);
														}}
													>
														<CloseIcon />
													</IconButton>
												)}
											</InputAdornment>
										),
										placeholder,
									}}
									data-cy={`${filter.label}_filter`}
									defaultValue={defaultDisplayValue}
									inputRef={columnRefs[filter.label]}
									label={filter.label}
									sx={{
										width: 390,
									}}
									onBlur={event => {
										setCurrentValue(columnRefs[filter.label]?.current?.value?.trim());
										handleStringSelect(event.target.value, filter.name);
									}}
									onMouseEnter={() => setHovered(true)}
									onMouseLeave={() => setHovered(false)}
								/>
							</ListItem>
						);
					})();
					break;
				case 'suggest':
					componentToReturn = (
						<ListItem
							key={filter.label + filter.filterType}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Suggest
								clearOnBlur
								data={
									defaultDisplayValue
										? [
												{
													[userColumn.name]: defaultDisplayValue,
												},
										  ]
										: []
								}
								fieldRef={columnRefs[filter.label]}
								listSearchScope={filter.suggestScope}
								optionId={userColumn.name}
								placeholder={placeholder}
								testId={`${filter.label}_filter`}
								text={filter.label}
								value={
									defaultDisplayValue
										? {
												[userColumn.name]: defaultDisplayValue,
										  }
										: ''
								}
								valueField="id"
								width={390}
								onSelect={(value, displayValue) => {
									handleStringSelect(value, filter.name, displayValue);
								}}
							/>
						</ListItem>
					);
					break;
				case 'single-select':
					componentToReturn = (
						<ListItem
							key={filter.label + filter.filterType}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<SearchableSingleSelectColumnFilter
								labelAlwaysShrunk
								defaultValue={defaultDisplayValue}
								fieldRef={columnRefs[filter.label]}
								hideIcon={false}
								label={filter.label}
								placeholder={placeholder}
								testId={`${filter.label}_filter`}
								valueSetType={filter.valueSet}
								width={390}
								onSelect={input => {
									handleStringSelect([input.selectedOptions], filter.name);
								}}
							/>
						</ListItem>
					);
					break;
				case 'date-range':
					componentToReturn = (
						<ListItem
							key={filter.label + filter.filterType}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<DateTimeRangePicker
								hasPillPicker
								defaultValue={userColumn.filter}
								label={filter.label}
								pillPickerOption={defaultOptions}
								testId={`${filter.name}_filter`}
								width={390}
								onChange={value => {
									handleDatetimeSelect(value, filter.name);
								}}
							/>
						</ListItem>
					);
					break;
				case 'date-time':
					componentToReturn = (
						<ListItem
							key={filter.label + filter.filterType}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<DatePicker
								defaultValue={defaultDisplayValue}
								label={filter.label}
								width={390}
								onChange={value => {
									handleDateSelect(value, filter.name);
								}}
							/>
						</ListItem>
					);
				default:
					break;
			}
			return componentToReturn;
		},
		[preConfiguredColumnList]
	);

	const mapColumnsFilter = _.map(preConfiguredColumnList, userColumn => {
		const filter = filterValues(userColumn);
		if (filter.length > 0) {
			return calculateComponent(userColumn, filter[0]);
		}
	});

	return (
		<Stack
			alignItems="stretch"
			direction="column"
			justifyContent="center"
			spacing={3}
			sx={{
				maxHeight: height || `calc(100vh - ${isLarge ? 268 : 438}px)`,
				height: height || `calc(100vh - ${isLarge ? 268 : 438}px)`,
				overflow: 'auto',
				boxSizing: 'border-box',
				padding: '8px auto',
			}}
		>
			<List
				sx={{
					height: '100%',
					overflow: 'auto',
				}}
			>
				{mapColumnsFilter}
			</List>
		</Stack>
	);
};

Filters.propTypes = {
	/**
	 * Whether the children can be taller or not (depends on whether the parent includes the username input)
	 */
	isLarge: PropTypes.bool,
	/**
	 * preConfiguredColumnList is the list of columns selected in the columns tab of columnBuilder and it's a array
	 */
	preConfiguredColumnList: PropTypes.array,
	/**
	 * List of columns
	 */
	columnList: PropTypes.array,
	/**
	 * Callback function to call when there are changes
	 */
	onChange: PropTypes.func,
	/**
	 * Array of filter objects
	 */
	filters: PropTypes.arrayOf(PropTypes.object),
	/**
	 * Height of the filter component
	 */
	height: PropTypes.number,
};

Filters.defaultProps = {
	preConfiguredColumnList: [],
};

export default Filters;
