// This is appointment status after mapping with create appointment utility
export const APPOINTMENT_STATUS = {
	PENDING: 'pending',
	SCHEDULED: 'scheduled',
	FULFILLED: 'completed',
	REQUESTED: 'requested',
	CANCELLED: 'cancelled',
	BOOKED: 'booked',
	COMPLETED: 'completed',
};

export const STUDY_STATUS = {
	ARRIVED: 'ARRIVED',
	CANCELLED: 'CANCELLED',
	COMPLETED: 'COMPLETED',
	CONFIRMED: 'CONFIRMED',
	DICTATED: 'DICTATED',
	EXAMCOMPLETED: 'EXAMCOMPLETED',
	EXAMDISCONTINUED: 'EXAMDISCONTINUED',
	IMPORT: 'IMPORT',
	INPROGRESS: 'INPROGRESS',
	NO_SHOW: 'NO SHOW',
	ON_HOLD: 'ON HOLD',
	ORDERED: 'ORDERED',
	PRIOR: 'PRIOR',
	READYFORSCAN: 'READYFORSCAN',
	REJECTED: 'REJECTED',
	SCHEDULED: 'SCHEDULED',
	SIGNED: 'SIGNED',
	STARTED: 'STARTED',
	TO_BE_AMENDED: 'TO BE AMENDED',
	TRANSCRIBED: 'TRANSCRIBED',
	UNUSED: 'UNUSED',
	VERIFIED: 'VERIFIED',
	PROCESSING: 'PROCESSING',
};

export const NOTIFICATION_TYPES = {
	STUDY_SHARED: 'STUDY_SHARED',
	APPOINTMENT_REMINDER: 'APPOINTMENT_REMINDER',
	APPOINTMENT_CONFIRMED: 'APPOINTMENT_CONFIRMED',
	APPOINTMENT_CANCELLED: 'APPOINTMENT_CANCELLED',
	APPOINTMENT_RESCHEDULED: 'APPOINTMENT_RESCHEDULED',
	APPOINTMENT_BOOKED: 'APPOINTMENT_BOOKED',
	DOCUMENT_SHARED: 'DOCUMENT_SHARED',
	ADD_CONTACT: 'ADD_CONTACT',
	USER_VERIFICATION_CODE: 'USER_VERIFICATION_CODE',
	STUDY_OPENED: 'STUDY_OPENED',
	WORKFLOW_AUTOMATION_NOTIFICATION: 'WORKFLOW_AUTOMATION_NOTIFICATION',
	INVITE: 'INVITE',
	FORM_CREATED: 'FORM_CREATED',
	OAI_PATIENT_SYNC: 'OAI_PATIENT_SYNC',
	OAI_PATIENT_DATA_SYNC: 'OAI_PATIENT_DATA_SYNC',
	OAI_PATIENT_SYNC_CLUBBED: 'OAI_PATIENT_SYNC_CLUBBED',
	NOTIFICATION_UPDATED: 'NOTIFICATION_UPDATED',
	SYNC_COMPLETED: 'SYNC_COMPLETED',
	SYNC_STUDY_UPDATED: 'SYNC_STUDY_UPDATED',
	APPOINTMENT_CREATED: 'APPOINTMENT_CREATED',
	SIGNIN_APPOINTMENT_CANCELLED: 'SIGNIN_APPOINTMENT_CANCELLED',
};

export const BANNER_SELECT_STATUS = {
	REQUESTED: 'REQUESTED',
	FUTURE_EXAM: 'FUTURE EXAM',
	COMPLETED: 'COMPLETED',
	PROCESSING: 'PROCESSING',
};

// White Label Timeout
export const SCHEDULE_APPOINTMENT_TIMEOUT = {
	minutes: 5,
	seconds: 0,
};

export const FORM_TYPES = {
	REGISTRATION_FORM: 'registration',
	CLINICAL_FORM: 'clinic',
};

// Videos are also allowed but via FF
export const ALLOWED_DOCUMENT_MIME_TYPES = [
	'application/pdf',
	'image/jpeg',
	'image/png',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const ALLOWED_DOCUMENT_VIDEO_MIME_TYPES = ['video/mp4'];

export const HIDDEN_HELP_CENTER_CATEGORIES = ['chatgpt', 'payment', 'medical-history', 'shared-history', 'chat'];

/** Hide specific articles. (Category is key and Array items are categorie's article filenames) */
export const HIDDEN_HELP_CENTER_ARTICLES_CRITERIA = {
	studies: [
		'article3',
		'article4',
		'article5',
		'article8',
		'article9',
		'article10',
		'article12',
		'article13',
		'article15',
	],
	'my-profile': ['article1', 'article2', 'article3', 'article4'],
	share: ['article2'],
	settings: ['article4'],
	'shared-history': ['article5'],
	scheduling: ['article2', 'article10'],
};

export const LANGUAGE_MAPPING = [
	{
		code: 'en',
		name: 'English',
	},
	{
		code: 'es',
		name: 'Español',
	},
	{
		code: 'hi',
		name: 'हिन्दी',
	},
	{
		code: 'fr',
		name: 'Français',
	},
];

export const COMPLETED_STUDIES_FILTER_TYPES = {
	ACCOUNT: 'account',
	TIME: 'time',
};

export const ASSET_NAMES = {
	OAI_VNA: 'OAI VNA',
	IMAGING_EMR_RIS_PACS: 'Imaging EMR / RIS PACS',
	BLUME_PATIENT_PORTAL: 'Blume Patient Portal',
	BLUME_AUTOMATED_FRONT_DESK: 'Blume Automated Front Desk',
	RADIOLOGIST_REPORTING: 'Radiologist Reporting',
	FAXING: 'Faxing',
	SMS: 'SMS',
};
