/* Generated automagically by FhirStarter - DO NOT EDIT */
const deviceSchema = {
	resourceType: 'Device',
	identifier: ['__Identifier__'],
	active: '__boolean__',
	status: '_string__',
	distinctIdentifier: '__string__',
	type: '__CodeableConcept__',
};
export default deviceSchema;
