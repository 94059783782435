import React from 'react';

const InsertTextBlockIcon = () => (
	<svg fill="none" height="22" viewBox="0 0 21 22" width="21" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0.976562 18.375V21.125H20.9864V18.375H0.976562ZM7.40831 12.6H14.5547L15.841 15.625H18.8425L12.0535 0.5H9.90954L3.12048 15.625H6.12196L7.40831 12.6ZM10.9815 3.2225L13.6542 10.125H8.30875L10.9815 3.2225Z"
			fill="#4D79EA"
		/>
	</svg>
);

export default InsertTextBlockIcon;
