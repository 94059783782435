import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { DragDropContext } from 'react-beautiful-dnd';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WorkIcon from '@mui/icons-material/Work';

import { crmSearchScopes, useAuth, useCRMDataLoader, useCRMSearchScope } from '@worklist-2/core/src';
import { useCrmContext } from '../../../../context/CRMContext';

import KanbanColumnWrapper from './KanbanColumnWrapper';
import KanbanColumn from './KanbanColumn';
import Loader from '../../Loader';

import { reorderCaseMap } from './utils';

import { CustomSwitch, Header, KanbanWrapper, Knob, Title } from '../../../../views/CrmView/styles';

const CasesKanban = () => {
	const [groupedCases, setGroupedCases] = useState({});
	const [ordered, setOrdered] = useState([]);
	const [draggableCase, setDraggableCase] = useState(null);
	const [caseToUpdate, setCaseToUpdate] = useState(null);

	const [assignedTeams, setAssignedTeams] = useState([]);

	const { authorized, loggedInUser } = useAuth();
	const { crmLoader, isFetchData, setFetchData, setCaseGlobalList } = useCRMSearchScope();
	const { showDetails, showNewDetails } = useCrmContext();

	const navigate = useNavigate();

	const caseDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.caseApi,
	});

	const userDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.userApi,
	});

	useEffect(() => {
		if (isFetchData || (crmLoader && authorized)) {
			fetchData().catch(console.error);
		}
	}, [crmLoader, authorized, isFetchData]);

	const fetchData = async () => {
		if (authorized) {
			setCaseGlobalList([]);

			try {
				await userDataLoader.load({ id: loggedInUser.id }).then(result => {
					const teams = result.Team?.map(item => item.TeamName);
					setAssignedTeams(teams || []);
				});
				const data = await caseDataLoader.load();

				getGroupedCases(data);
				setFetchData(false);
				setCaseGlobalList(data);
			} catch (e) {
				console.error(e);
			}
		}
	};

	const getGroupedCases = data => {
		const assigned = data?.filter(
			elem =>
				elem.AssignedToId !== undefined && elem.AssignedToId?.toLowerCase() === loggedInUser.id.toLowerCase()
		);

		const result = {};
		result['In Progress'] = assigned?.filter(elem => elem.Status === 'New' || elem.Status === 'In Progress');
		result.Waiting = assigned?.filter(elem => elem.Status.includes('Waiting'));
		result['On Hold'] = assigned?.filter(elem => elem.Status.includes('Bug Submitted'));
		result.Closed = assigned?.filter(
			elem => elem.Status.includes('Closed') || elem.Status === 'Resolved' || elem.Status === 'Feedback Submitted'
		);

		setGroupedCases(result);
		setOrdered(Object.keys(result));
	};

	const handleChangeStatus = DragData => {
		const { source, destination, draggableId } = DragData;

		if (!destination) {
			setDraggableCase(null);
			return;
		}

		if (source.droppableId === destination.droppableId && source.index === destination.index) {
			setDraggableCase(null);
			return;
		}

		const currentCase = groupedCases[source.droppableId].find(item => item.CaseId === draggableId);

		let status = 'New';
		switch (destination.droppableId) {
			case 'In Progress':
				status = 'In Progress';
				break;
			case 'Waiting':
				status = 'Waiting on Customer';
				break;
			case 'On Hold':
				status = 'Bug Submitted';
				break;
			case 'Closed':
				status = 'Resolved';
				break;
			default:
				break;
		}

		const updatedCase = {
			...currentCase,
			Status: status,
		};

		setCaseToUpdate({ draggableId, updatedCase });

		const newCaseMap = reorderCaseMap({
			caseMap: groupedCases,
			source,
			destination,
		});

		setGroupedCases(newCaseMap);
		setDraggableCase(null);
	};

	useEffect(() => {
		async function update() {
			try {
				if (caseToUpdate.draggableId) {
					await caseDataLoader.update(caseToUpdate.draggableId, caseToUpdate.updatedCase);
				}
			} catch (error) {
				console.error(error.message);
			}
		}

		update();
	}, [caseToUpdate]);

	return (
		<>
			<Header dataTestId="header">
				<Title>
					<WorkIcon sx={{ fontSize: 29, mr: '12px' }} />
					<span>Cases</span>
				</Title>
				<CustomSwitch>
					<Knob data-testid="home" onClick={() => navigate('/')}>
						<HomeOutlinedIcon />
					</Knob>
					<label>Cases</label>
				</CustomSwitch>
			</Header>
			<KanbanWrapper
				className="invisible-scrollbar"
				sx={{
					width: showDetails || showNewDetails ? 'calc(100vw - 640px)' : 'calc(100vw - 90px)',
					height: 'calc(100% - 170px)',
				}}
			>
				{isFetchData ? (
					<Loader />
				) : (
					<DragDropContext
						onBeforeDragStart={ctx =>
							setDraggableCase({ draggableId: ctx.draggableId, status: ctx.source.droppableId })
						}
						onDragEnd={handleChangeStatus}
					>
						<KanbanColumnWrapper>
							{ordered.map(key => (
								<KanbanColumn
									key={key}
									assignedTeams={assignedTeams}
									cases={groupedCases[key]}
									draggableCase={draggableCase}
									status={key}
								/>
							))}
						</KanbanColumnWrapper>
					</DragDropContext>
				)}
			</KanbanWrapper>
		</>
	);
};

export default CasesKanban;
