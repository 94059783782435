// @flow

// core
import React, { type Node } from 'react';
// libs
import Skeleton, { type SkeletonProps } from '@mui/material/Skeleton';

export interface ISkeletonProps extends SkeletonProps {
	/** Child components, allows component to be used as a wrapper too */
	children?: Node | Node[];
	/**
	 * Whether the data is being loaded, used together with `children` prop
	 *
	 * when `true` - shows the Skeleton
	 *
	 * when `false` - shows the children
	 */
	isLoading?: boolean;
	/** Inline styles */
	sx?: any;
}

/**
 * Customized MUI Skeleton component
 * @docs: https://mui.com/material-ui/api/skeleton/
 */
export const LoaderSkeleton = ({ children, isLoading, sx }: ISkeletonProps): Node =>
	children && !isLoading ? children : <Skeleton sx={{ width: '100%', transform: 'scale(1) !important', ...sx }} />;
