import { Enums, ArrowAnnotateTool, utilities, annotation, cursors } from '@cornerstonejs/tools';
import { getEnabledElement, triggerEvent, eventTarget } from '@cornerstonejs/core';

const { Events } = Enums;
const { triggerAnnotationRenderForViewportIds } = utilities;
const { removeAnnotation } = annotation.state;
const { resetElementCursor } = cursors.elementCursor;
class _ArrowAnnotateTool extends ArrowAnnotateTool {
	_endCallback = evt => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const { annotation, viewportIdsToRender, newAnnotation, hasMoved } = this.editData;
		const { data } = annotation;

		if (newAnnotation && !hasMoved) {
			// when user starts the drawing by click, and moving the mouse, instead
			// of click and drag
			return;
		}

		data.handles.activeHandleIndex = null;

		this._deactivateModify(element);
		this._deactivateDraw(element);
		resetElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { viewportId, renderingEngineId, renderingEngine } = enabledElement;

		if (this.isHandleOutsideImage && this.configuration.preventHandleOutsideImage) {
			removeAnnotation(annotation.annotationUID);
		}

		if (newAnnotation) {
			this.configuration.getTextCallback(text => {
				if (!text) {
					this.editData = null;
					this.isDrawing = false;
				}
				annotation.data.text = text;

				const eventType = Events.ANNOTATION_COMPLETED;

				const eventDetail = {
					annotation,
				};

				triggerEvent(eventTarget, eventType, eventDetail);

				triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
			});
		} else {
			const eventType = Events.ANNOTATION_COMPLETED;

			const eventDetail = {
				annotation,
				viewportId,
				renderingEngineId,
			};

			triggerEvent(eventTarget, eventType, eventDetail);
		}

		this.editData = null;
		this.isDrawing = false;
	};
}

export default _ArrowAnnotateTool;
