// React Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Material UI Libraries
import Grid from '@mui/material/Grid';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import Grid4x4OutlinedIcon from '@mui/icons-material/Grid4x4Outlined';
import Tooltip from '@mui/material/Tooltip';

// Chart Image
import BarChart from '../../../assets/Bar_Chart.png';
import LineChart from '../../../assets/Line_Chart.png';
import PieChart from '../../../assets/Pie-Chart.png';
import StackedChart from '../../../assets/Stacked_Chart.png';
import EmptyCard from '@worklist-2/ui/src/assets/img/empty_cards.png';
import GridChart from '@worklist-2/ui/src/assets/img/empty_grid.png';

// Worklist Core Libraries
import { useConfig } from '@worklist-2/core/src';

function ReportOptions({ setIsReport, setChartImage, setChartType }) {
	const __config = useConfig();
	const CHART_BUTTONS = [
		{
			type: 'PIE_CHART',
			icon: <DonutSmallOutlinedIcon />,
			image: PieChart,
			name: 'Pie Chart',
		},
	];

	if (__config.ff?.ROOT_STACK_CHART) {
		CHART_BUTTONS.push({
			type: 'STACK_CHART',
			icon: <StackedBarChartIcon />,
			image: StackedChart,
			name: 'Stack Graph',
		});
	}

	CHART_BUTTONS.push({
		type: 'BAR_CHART',
		icon: <BarChartIcon />,
		image: BarChart,
		name: 'Bar Graph',
	});

	CHART_BUTTONS.push({
		type: 'LINE_CHART',
		icon: <StackedLineChartIcon />,
		image: LineChart,
		name: 'Line Chart',
	});

	CHART_BUTTONS.push({
		type: 'CROP_CHART',
		icon: <CropLandscapeIcon />,
		image: EmptyCard,
		isDisable: false,
		name: 'Visual Cards',
	});

	if (__config.ff?.ROOT_GRID_CHART) {
		CHART_BUTTONS.push({
			type: 'TABLE_CHART',
			icon: <Grid4x4OutlinedIcon />,
			isDisable: false,
			image: GridChart,
			name: 'Table View',
		});
	}

	return (
		<Grid
			container
			sx={{
				width: 300,
				background: '#4D79EA',
				boxShadow: '15px 15px 20px rgba(0, 0, 0, 0.08)',
				borderRadius: '20px',
				padding: '14px',
			}}
		>
			{CHART_BUTTONS.map((chart, index) => (
				<Tooltip title={chart.name}>
					<Grid
						key={index}
						item
						data-testid={`BTN_${chart.type}`}
						sx={{
							height: 55,
							color: '#FFFFFF',
							borderRadius: '10px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							cursor: 'pointer',
							'&:hover': {
								color: '#FFF',
								background: 'rgba(66, 165, 245, 0.1)',
							},
						}}
						xs={3}
						onClick={() => {
							if (!chart.isDisable) {
								setIsReport && setIsReport(true);
								setChartType && setChartImage(chart.image);
								setChartImage && setChartType(chart.type);
							}
						}}
					>
						{chart.icon}
					</Grid>
				</Tooltip>
			))}
		</Grid>
	);
}

ReportOptions.propTypes = {
	/**
	 * setIsReport is func bool value to toggle new report popup
	 */
	setIsReport: PropTypes.func,
	/**
	 * setChartImage is func to handle chart image based on option clicked
	 */
	setChartImage: PropTypes.func,
	/**
	 * setChartType is func to handle chart type based on option clicked
	 */
	setChartType: PropTypes.func,
};

export default ReportOptions;
