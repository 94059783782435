import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import LinkIcon from '@mui/icons-material/Link';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';

import GenericDrawer from '@worklist-2/ui/src/components/Drawers/GenericDrawer';
import { useConfig, mediaMobile, useIsMobile } from '@worklist-2/core/src';

import { useToastMessageContext } from '../../../context/ToastMessageContext';
import MobileBottomDrawer from '../../MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import ShareContactNew from '../ShareContactNew';
import sendAnalyticsEvent from '../../../analytics';
import { SHARE_VIA_LINK } from '../../../analytics/eventTypes';

const StyledText = styled('p')({
	fontFamily: 'Roboto',
	fontStyle: 'normal',
	fontWeight: 500,
	fontSize: '14px',
	lineHeight: '16px',
	color: '#42A5F5',
	textTransform: 'none',
});

const StyledBoxIcon = styled(Box)({
	width: '40px',
	height: '40px',
	background: '#F3F3F3',
	borderRadius: '25px',
	alignItems: 'center',
	justifyContent: 'center',
	display: 'flex',
	margin: '0 15px 0 11px',

	[mediaMobile]: {
		marginLeft: '0px',
	},
});

const ShareOptionButtons = ({ canShare, studyId, documentId, setIsOpenShare }) => {
	const __config = useConfig();
	const { setToastMsg } = useToastMessageContext();
	const [isOpenAddContact, setIsOpenAddContact] = useState(false);

	const { t } = useTranslation('drawer');
	const isMobile = useIsMobile();

	const copylinkcolor = canShare ? '#42A5F5' : '#D3D3D3';

	const copyToClipboard = (isSafari, url) => {
		isSafari ? copy(url) : navigator.clipboard.writeText(url);
		setIsOpenShare(false);
		setToastMsg(t('Link copied successfully'));
	};

	const onClickCopy = () => {
		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

		if (studyId) {
			axios.get(`${__config.data_sources.blume}Study/${studyId}/sharetoken`).then(result => {
				if (result && result.status === 200) {
					const url = `${__config.blume_base_url}study/link/${result.data}`;
					copyToClipboard(isSafari, url);
					sendAnalyticsEvent(SHARE_VIA_LINK, { url, type: 'Study', studyId });
				}
			});
		} else if (documentId) {
			axios.get(`${__config.data_sources.blume}Study/${documentId}/sharetoken`).then(result => {
				if (result && result.status === 200) {
					const url = `${__config.blume_base_url}document/link/${result.data}`;
					copyToClipboard(isSafari, url);
					sendAnalyticsEvent(SHARE_VIA_LINK, { url, type: 'Document', documentId });
				}
			});
		}
	};

	return (
		<>
			<Button
				disabled={!canShare}
				sx={{
					width: '100%',
					justifyContent: 'left',
					paddingTop: '16px',
					paddingLeft: '27px',

					[mediaMobile]: { padding: '8px 16px' },
				}}
				onClick={onClickCopy}
			>
				<StyledBoxIcon>
					<LinkIcon sx={{ color: copylinkcolor }} />
				</StyledBoxIcon>
				<StyledText sx={{ color: copylinkcolor }}>{t('Copy the link')}</StyledText>
			</Button>
			{studyId && (
				<Button
					id="addContactBtn"
					sx={{
						width: '100%',
						justifyContent: 'left',
						paddingTop: '16px',
						paddingLeft: '27px',

						[mediaMobile]: { padding: '8px 16px' },
					}}
					onClick={() => setIsOpenAddContact(true)}
				>
					<StyledBoxIcon>
						<AddIcon sx={{ color: '#42A5F5' }} />
					</StyledBoxIcon>
					<StyledText>{t('Add new contact')}</StyledText>
				</Button>
			)}

			{isMobile ? (
				<MobileBottomDrawer
					fullHeight
					background="#ffffff"
					open={isOpenAddContact}
					onClose={() => setIsOpenAddContact(false)}
				>
					<ShareContactNew closeDrawer={() => setIsOpenAddContact(false)} isOpen={isOpenAddContact} />
				</MobileBottomDrawer>
			) : (
				<GenericDrawer
					headerFlag
					showBackBtn
					drawerColor="rsSecondary.medium"
					drawerOpen={isOpenAddContact}
					setDrawerOpen={setIsOpenAddContact}
					title="ADD NEW CONTACT"
					toggleDrawerButtonFlag={false}
					width={422}
				>
					<ShareContactNew isOpen={isOpenAddContact} />
				</GenericDrawer>
			)}
		</>
	);
};

export default ShareOptionButtons;
