const PatientLedgerMapping = {
	id: {
		label: '',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.id;
		},
	},
	case: {
		label: 'Case #',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'case',
		getDisplay: resource => {
			return resource.case;
		},
	},
	carrierId: {
		label: 'Carrier ID',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'carrierId',
		getDisplay: resource => {
			return resource.carrierId;
		},
	},
	cpt: {
		label: 'CPT',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'cpt',
		getDisplay: resource => {
			return resource.cpt;
		},
	},
	modifier: {
		label: 'Modifier',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'modifier',
		getDisplay: resource => {
			return resource.modifier;
		},
	},
	diagnosis: {
		label: 'Diagnosis',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'diagnosis',
		getDisplay: resource => {
			return resource.diagnosis;
		},
	},
	charge: {
		label: 'Charge',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'charge',
		getDisplay: resource => {
			return resource.charge;
		},
	},
	patient: {
		label: 'Patient',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'patient',
		getDisplay: resource => {
			return resource.patient;
		},
	},
	primary: {
		label: 'Primary',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'primary',
		getDisplay: resource => {
			return resource.primary;
		},
	},
	secondary: {
		label: 'Secondary',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'secondary',
		getDisplay: resource => {
			return resource.secondary;
		},
	},
	tertiary: {
		label: 'Tertiary',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'tertiary',
		getDisplay: resource => {
			return resource.tertiary;
		},
	},
	adj: {
		label: 'Adj.',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'adj',
		getDisplay: resource => {
			return resource.adj;
		},
	},
	adjReason: {
		label: 'Adj. Reason',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'adjReason',
		getDisplay: resource => {
			return resource.adjReason;
		},
	},
	status: {
		label: 'Status',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'status',
		getDisplay: resource => {
			return resource.status;
		},
	},
};
export default PatientLedgerMapping;
