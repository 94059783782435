/**
 * TODO: USE API
 * @type {[{code: string, display: string}, {code: string, display: string}, {code: string, display: string}, {code: string, display: string}, {code: string, display: string}, null, null, null, null, null, null, null]}
 */
const organizationTypes = [
	{
		code: 'PROV',
		display: 'Healthcare Provider',
	},
	{
		code: 'DEPT',
		display: 'Hospital Department',
	},
	{
		code: 'TEAM',
		display: 'Organizational team',
	},
	{
		code: 'GOVT',
		display: 'Government',
	},
	{
		code: 'INS',
		display: 'Insurance Company',
	},
	{
		code: 'PAY',
		display: 'Payer',
	},
	{
		code: 'EDU',
		display: 'Educational Institute',
	},
	{
		code: 'RELI',
		display: 'Religious Institution',
	},
	{
		code: 'CRS',
		display: 'Clinical Research Sponsor',
	},
	{
		code: 'CG',
		display: 'Community Group',
	},
	{
		code: 'BUS',
		display: 'Non-Healthcare Business or Corporation',
	},
	{
		code: 'OTHER',
		display: 'Other',
	},
];

export default organizationTypes;
