import React, { memo } from 'react';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

const ImageViewerViewportScrollbarItem = ({ isLoaded, item, renderImage, opacity = 0.4 }) => (
	<Box
		sx={{
			width: '19px',
			height: '19px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			opacity,
			boxSizing: 'border-box',
			marginBottom: '8px',
			transition: '0.1s',
		}}
		onClick={() => renderImage(item)}
	>
		<Typography
			component="div"
			id="item-id"
			sx={{
				fontWeight: '500',
				fontSize: '12px',
				lineHeight: '15px',
				color: '#FFFFFF',
				userSelect: 'none',
				position: 'relative',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			{item + 1}

			{!isLoaded && (
				<CircularProgress
					size={19}
					sx={{
						color: '#fff',
						position: 'absolute',
					}}
				/>
			)}
		</Typography>
	</Box>
);

export default memo(ImageViewerViewportScrollbarItem);
