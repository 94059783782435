import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { LinkButton, RoleSideWidget, Title } from '../../../../views/CrmView/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';

import LastPageIcon from '@mui/icons-material/LastPage';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import { TextField } from '@mui/material';
import MultipleSelectChip from '../MultipleSelectChip';
import _ from 'lodash';

const AntTabs = styled(Tabs)({
	'& .MuiTabs-indicator': {
		backgroundColor: '#4D79EA',
		height: 4,
	},
});

const AntTab = styled(props => <Tab disableRipple {...props} />)(({ theme }) => ({
	textTransform: 'none',
	minWidth: 0,
	[theme.breakpoints.up('sm')]: {
		minWidth: 0,
	},
	fontWeight: theme.typography.fontWeightRegular,
	marginRight: theme.spacing(1),
	color: '#C4C4C4',
	fontFamily: [
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(','),
	'&:hover': {
		color: '#4D79EA',
		opacity: 1,
	},
	'&.Mui-selected': {
		color: '#4D79EA',
		fontWeight: theme.typography.fontWeightMedium,
	},
	'&.Mui-focusVisible': {
		backgroundColor: '#d1eaff',
	},
}));

const AddUser = forwardRef((props, ref) => {
	const [visibility, toggleVisibility] = useState(false);
	const [roles, setRoles] = useState([]);
	const [teams, setTeams] = useState([]);

	useImperativeHandle(ref, () => ({
		open: data => {
			setRoles(data?.roles || []);
			setTeams(data?.teams || []);
			toggleVisibility(true);
		},
		close: () => {
			toggleVisibility(false);
		},
	}));

	return visibility && <AddUserWidget roles={roles} teams={teams} toggleVisibility={toggleVisibility} />;
});

const AddUserWidget = ({ toggleVisibility, roles, teams }) => {
	const [user, setUser] = useState({});
	const [currentTab, setCurrentTab] = useState(0);

	useEffect(() => {
		const ignoreClickOnMeElement = document.getElementById('popover-wrapper');
		const listener = event => {
			const isClickInsideElement = ignoreClickOnMeElement?.contains(event.target);
			if (!isClickInsideElement) {
				// toggleVisibility(false)
			}
		};
		document.addEventListener('click', listener);

		return () => {
			document.removeEventListener('click', listener);
		};
	}, []);

	const handleTabChange = (event, tabIndex) => {
		setCurrentTab(tabIndex);
	};

	return (
		<RoleSideWidget isOpen data-testid="add-user-widget" id="popover-wrapper">
			<Box className="header" sx={{ height: '120px' }}>
				<Box className="row">
					<Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
						<IconButton onClick={() => toggleVisibility(false)}>
							<LastPageIcon />
						</IconButton>
					</Box>
				</Box>
				<Box className="main row" sx={{ justifyContent: 'flex-start !important', marginBottom: '20px' }}>
					<ReplyOutlinedIcon
						sx={{ width: '30px', height: '30px', color: '#4D79EA', margin: '0px 21px 0px 31px' }}
					/>
					<Title style={{ color: 'rgba(0, 0, 0, 0.9)', fontSize: '24px', fontWeight: '500' }}>
						Invite to Breeze
					</Title>
				</Box>
			</Box>
			<Box
				sx={{
					flex: 1,
					background: '#FFF',
					zIndex: 1,
					padding: '0px 16px 25px',
				}}
			>
				<AntTabs value={currentTab} onChange={handleTabChange}>
					<AntTab data-testid="tab-1" label="Invite By Email" />
					<AntTab data-testid="tab-2" label="Invite By Link" />
				</AntTabs>
				{currentTab === 0 && <InviteUserByEmail roles={roles} teams={teams} />}
			</Box>
			<Box className="row wrapper-footer">
				<LinkButton style={{ border: '1px solid #4D79EA', fontSize: '12px' }}>Cancel</LinkButton>

				<LinkButton style={{ margin: '0px 0px 0px auto', background: '#4D79EA', color: '#FFF', width: '82px' }}>
					Save
				</LinkButton>
			</Box>
		</RoleSideWidget>
	);
};

export default AddUser;

const InviteUserByEmail = ({ roles, teams }) => {
	const roleOptions = useMemo(
		() =>
			_.map(roles, (role, index) => ({
				index,
				key: role.RoleName,
				value: role.id,
				teams: role?.Team?.map(team => team.TeamName) || [],
			})),
		[roles]
	);

	const teamOptions = useMemo(
		() =>
			// const uniqTeams = _.uniq(_.flatMap(_.concat(roleOptions.map(option => option.teams))));
			teams.map((team, index) => ({
				index,
				key: team.TeamName,
				value: team.id,
			})),
		[teams]
	);

	return (
		<Box
			sx={{
				marginTop: '30px',
				'& label': {
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: '500',
					fontSize: '12px',
					lineHeight: '15px',
					color: 'rgba(0, 0, 0, 0.6)',
				},
			}}
		>
			<form>
				<Box className="row">
					<Box sx={{ flex: 1, marginRight: '17.5px' }}>
						<label>Assign Roles</label>
						<MultipleSelectChip data={roleOptions} id="role-select" />
					</Box>
					<Box sx={{ flex: 1, marginLeft: '17.5px' }}>
						<label>Assign Teams</label>
						<MultipleSelectChip data={teamOptions} id="team-select" />
					</Box>
				</Box>
				<Box className="row" sx={{ marginTop: '20px' }}>
					<Box sx={{ flex: 1 }}>
						<label>Email Addresses</label>
						<TextField
							multiline
							helperText="Your teammates will get an email with an invite to join your team."
							placeholder="Enter emails, comma to separate"
							rows={6}
							size="small"
							sx={{ width: '100%', marginTop: '5px' }}
						/>
					</Box>
				</Box>
			</form>
		</Box>
	);
};
