import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const addContactvalidationSchema = () => {
	const { t } = useTranslation('drawer');

	const validationSchema = yup.object().shape({
		name: yup.string().required(t('Name is required')).max(100),
		email: yup.string().lowercase().required(t('Email is required')).email(t('Please enter a valid email')),
	});
	return validationSchema;
};
export default addContactvalidationSchema;
