import { useImageViewerExternalContext } from './ImageViewerExternalContext';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { metaData } from '@cornerstonejs/core';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { v4 as uuid } from 'uuid';
import ImageRender from '../components/ImageRender/ImageRender';
import { Typography } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import getDefaultRenderingEngine from '../cornerstone/getDefaultRenderingEngine';
import { ViewportType } from '@cornerstonejs/core/dist/esm/enums';
import { KEY_IMAGE, SNAPSHOT_CONFIG } from '../consts/dragDropRules';
import { findImageIdMeasurements } from '../utils/exportSnapshot';
import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';

const SeriesNavigator = () => {
	const { series, keyImages, viewport, setViewport, keyImagesDataURLRef, measurements } =
		useImageViewerExternalContext();

	const [isShownLeftIcon, setIsShownLeftIcon] = useState(false);
	const [isShownRightIcon, setIsShownRightIcon] = useState(true);

	const scrollableContainer = useRef();
	const navigatorContainer = useRef();

	const activeSeries = useMemo(() => series?.find(s => s.uniqueId === viewport?.seriesUniqueId), [series, viewport]);

	const getSeriesName = s => s?.['0008103E']?.Value?.[0];

	const onWheel = (e, smooth) => {
		if (smooth) {
			scrollableContainer.current.scrollTo({
				left: scrollableContainer.current.scrollLeft + e.deltaY,
				behavior: 'smooth',
			});
		} else {
			scrollableContainer.current.scrollLeft += e.deltaY;
		}
	};

	const checkArrows = () => {
		if (!scrollableContainer.current) {
			return;
		}

		const { scrollLeft } = scrollableContainer.current;
		const { offsetWidth } = scrollableContainer.current;
		const { scrollWidth } = scrollableContainer.current;

		if (scrollLeft > 0) {
			setIsShownLeftIcon(true);
		}

		if (scrollLeft <= 0) {
			setIsShownLeftIcon(false);
		}

		if (scrollWidth !== scrollLeft + offsetWidth) {
			setIsShownRightIcon(true);
		}

		if (scrollWidth === scrollLeft + offsetWidth) {
			setIsShownRightIcon(false);
		}
	};

	useEffect(() => {
		checkArrows();

		let myObserver;

		if (series?.length) {
			myObserver = new ResizeObserver(() => {
				checkArrows();
			});
			myObserver.observe(navigatorContainer.current);
		}

		return () => {
			myObserver && myObserver.disconnect();
		};
	}, [series?.length]);

	useEffect(() => {
		if (!keyImages?.length) {
			return;
		}

		const loadKeyImage = keyImageIndex => {
			const keyImage = keyImages[keyImageIndex];

			if (!keyImage || !keyImage.currentImageId) {
				return;
			}

			const renderingEngine = getDefaultRenderingEngine();

			if (!renderingEngine) {
				return;
			}

			const metaDataExist = metaData.get('imagePixelModule', keyImage.currentImageId);

			if (!metaDataExist) {
				return;
			}

			const id = uuid();
			const el = document.createElement('div');

			el.id = id;
			el.style.width = `512px`;
			el.style.height = `512px`;
			el.style.position = 'absolute';
			el.style.top = '0';
			el.style.left = '0';
			el.style.opacity = '0.01';
			el.style.pointerEvents = 'none';

			document.body.appendChild(el);

			renderingEngine.enableElement({
				viewportId: id,
				element: el,
				type: ViewportType.STACK,
			});

			const cornerstoneViewport = renderingEngine.getViewport(id);

			cornerstoneViewport.setStack([keyImage.currentImageId], 0).then(() => {
				setTimeout(() => {
					const canvas = el.querySelector('canvas');

					keyImagesDataURLRef.current[keyImage.currentImageId] = canvas.toDataURL('image/jpeg');

					renderingEngine.disableElement(id);

					el.remove();

					loadKeyImage(keyImageIndex + 1);
				}, 100);
			});
		};

		loadKeyImage(0);
	}, [keyImages]);

	useEffect(() => {
		const activeSeriesNavigatorItem = document.querySelector(
			`.series-navigator-item[data-uniqueId="${activeSeries?.uniqueId}"]`
		);
		activeSeriesNavigatorItem?.scrollIntoView({ block: 'center', inline: 'center' });
	}, [activeSeries]);

	if (!series?.length) {
		return null;
	}

	const onDragStart = (e, keyImage) => {
		const imageId = keyImage.currentImageId;
		const JsonData = {
			imageId,
			type: KEY_IMAGE,
			measurements: findImageIdMeasurements(imageId, measurements),
			metadata: imageId && cornerstoneWADOImageLoader.wadors.metaDataManager.get(imageId),
			config: SNAPSHOT_CONFIG,
			managingOrganizationId: keyImage?.Managingorganizationid,
			studyUid: keyImage?.StudyInstanceUID,
		};
		e.dataTransfer.setData('text/plain', JSON.stringify(JsonData));
	};

	return (
		<Box
			ref={navigatorContainer}
			sx={{
				padding: '0 60px',
				position: 'relative',
				marginBottom: '20px',
			}}
			onWheel={onWheel}
		>
			<ExpandMoreIcon
				sx={{
					position: 'absolute',
					width: '30px',
					height: '30px',
					background: '#393939',
					filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
					left: '4%',
					top: '25px',
					borderRadius: '50%',
					color: '#fff',
					rotate: '90deg',
					fontSize: '20px',
					cursor: 'pointer',
					opacity: '1',
					transition: '0.2s',
					[!isShownLeftIcon && '&']: {
						opacity: '0',
						pointerEvents: 'none',
					},
				}}
				onClick={() => onWheel({ deltaY: -200 }, true)}
			/>

			<Box
				ref={scrollableContainer}
				sx={{
					width: '100%',
					overflow: 'hidden',
					display: 'flex',
					alignItems: 'center',
					gap: '15px',
					overflowX: 'auto',
					'&::-webkit-scrollbar': {
						display: 'none',
					},
					'-ms-overflow-style': 'none',
					scrollbarWidth: 'none',
				}}
				onScroll={checkArrows}
			>
				{keyImages.map(keyImage => {
					const s = series?.find(_s => _s['0020000E']?.Value?.[0] === keyImage.SeriesID);

					if (!s) {
						return null;
					}

					const currentImageIdIndex = s.imageIds?.findIndex(i => i === keyImage.currentImageId);

					if (currentImageIdIndex === -1) {
						return;
					}

					return (
						<Box key={keyImage.id}>
							<Box
								draggable
								sx={{
									width: '100px',
									height: '80px',
									borderRadius: '8px',
									border: '2px solid rgba(255, 255, 255, 0.25)',
									cursor: 'pointer',
									boxSizing: 'border-box',
									position: 'relative',
								}}
								onClick={() => {
									setViewport({
										id: uuid(),
										keyImageId: keyImage.id,
										seriesUniqueId: s.uniqueId,
										initialInstanceIndex: currentImageIdIndex,
									});
								}}
								onDragStart={e => onDragStart(e, keyImage)}
							>
								<VpnKeyIcon
									sx={{
										color: '#42A5F5',
										position: 'absolute',
										top: '4px',
										left: '4px',
									}}
								/>

								<ImageRender
									instanceSOPUID={keyImage.ReferenceImageSOPInstanceUID}
									seriesUid={s['0020000E']?.Value?.[0]}
									studyUid={s.studyInstanceUID}
								/>
							</Box>

							<Typography
								sx={{
									color: '#fff',
									fontSize: '12px',
									paddingLeft: '8px',
								}}
							>
								{keyImage.description || getSeriesName(s)}
							</Typography>
						</Box>
					);
				})}

				{keyImages?.length ? (
					<Box
						sx={{
							minWidth: '1px',
							height: '80px',
							background: 'rgba(255, 255, 255, 0.20)',
						}}
					/>
				) : null}

				{series.map(s => (
					<Box
						key={s.uniqueId}
						className="series-navigator-item"
						data-uniqueId={s.uniqueId}
						sx={{
							width: '100px',
						}}
					>
						<Box
							sx={{
								width: '100px',
								height: '80px',
								borderRadius: '8px',
								border:
									activeSeries?.uniqueId === s.uniqueId
										? '2px solid #42A5F5'
										: '2px solid rgba(255, 255, 255, 0.25)',
								cursor: 'pointer',
								boxSizing: 'border-box',
							}}
							onClick={() => {
								setViewport({
									id: uuid(),
									seriesUniqueId: s.uniqueId,
									initialInstanceIndex: 0,
								});
							}}
						>
							<ImageRender
								instanceSOPUID={s.metadata?.[0]?.['00080018']?.Value?.[0]}
								seriesUid={s['0020000E']?.Value?.[0]}
								studyUid={s.studyInstanceUID}
							/>
						</Box>

						<Typography
							sx={{
								color: '#fff',
								fontSize: '14px',
								paddingLeft: '8px',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{getSeriesName(s) || <>&nbsp;</>}
						</Typography>
					</Box>
				))}
			</Box>

			<ExpandMoreIcon
				sx={{
					position: 'absolute',
					width: '30px',
					height: '30px',
					background: '#393939',
					filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
					right: '4%',
					top: '25px',
					borderRadius: '50%',
					color: '#fff',
					rotate: '-90deg',
					fontSize: '20px',
					cursor: 'pointer',
					opacity: '1',
					transition: '0.2s',
					[!isShownRightIcon && '&']: {
						opacity: '0',
						pointerEvents: 'none',
					},
				}}
				onClick={() => onWheel({ deltaY: +200 }, true)}
			/>
		</Box>
	);
};

export default SeriesNavigator;
