import React, { useEffect, useState } from 'react';

import styles from './TableSizeChanger.module.scss';

const validateTableGridSize = number => {
	if (Number.isNaN(number)) {
		return 3;
	}

	if (number <= 3) {
		return 3;
	}

	if (number >= 5) {
		return 5;
	}

	return number;
};
const TableSizeChanger = ({ editor, currentTable, setCurrentTable, setTableSizeHover }) => {
	const [highlight, setHighlight] = useState({ x: 0, y: 2 });
	const [tableGridSize, setTableGridSize] = useState({ rows: 3, columns: 3 });

	useEffect(() => {
		setHighlight({ x: currentTable.rows - 1, y: currentTable.columns - 1 });
		if (currentTable.rows > 3 || currentTable.columns > 3) {
			setTableGridSize({ rows: 5, columns: 5 });
		}
	}, [currentTable]);

	const onSetHighlight = ({ x, y }) => {
		const rows = x + 1;
		const columns = y + 1;

		const newValues =
			currentTable.rows > 3 || currentTable.columns > 3
				? { rows: 5, columns: 5 }
				: { rows: rows + 1, columns: columns + 1 };

		setTableGridSize({
			rows: validateTableGridSize(newValues.rows),
			columns: validateTableGridSize(newValues.columns),
		});

		setHighlight({ x, y });
	};

	const shouldHighlight = (x, y) => x <= highlight.x && y <= highlight.y;

	const insertTable = (rowIndex, columnIndex) => {
		const rowsCount = rowIndex + 1;
		const columnsCount = columnIndex + 1;

		let currentTableRows = currentTable.rows;
		let currentTableColumns = currentTable.columns;

		if (rowsCount < currentTableRows) {
			for (currentTableRows; currentTableRows > rowsCount; currentTableRows--) {
				editor.commands.deleteRow();
			}
		}

		if (columnsCount < currentTableColumns) {
			for (currentTableColumns; currentTableColumns > columnsCount; currentTableColumns--) {
				editor.commands.deleteColumn();
			}
		}

		if (rowsCount > currentTableRows) {
			for (currentTableRows; currentTableRows < rowsCount; currentTableRows++) {
				editor.commands.addRowAfter();
			}
		}

		if (columnsCount > currentTableColumns) {
			for (currentTableColumns; currentTableColumns < columnsCount; currentTableColumns++) {
				editor.commands.addColumnAfter();
			}
		}

		setCurrentTable(prev => ({ ...prev, rows: rowsCount, columns: columnsCount }));

		setTableSizeHover(false);
	};

	const onMouseLeave = () => {
		if (currentTable.rows && currentTable.columns) {
			setHighlight({ x: currentTable.rows - 1, y: currentTable.columns - 1 });
		} else {
			onSetHighlight({ x: 0, y: 2 });
		}
	};

	return (
		<div
			className={`${styles.tableSizeChanger} ${tableGridSize.rows === 4 && styles.tableSizeChangerPos4} ${
				tableGridSize.rows === 5 && styles.tableSizeChangerPos5
			} `}
			data-testid="table-size-changer"
			onMouseLeave={onMouseLeave}
		>
			<div className={styles.boxGrid}>
				{Array.from(Array(tableGridSize.rows)).map((item, rowIndex) => (
					<div
						key={rowIndex}
						className={styles.table}
						style={{ gridTemplateColumns: `repeat(${tableGridSize.columns}, 1fr)` }}
					>
						{Array.from(Array(tableGridSize.columns)).map((elem, columnIndex) => (
							<div
								key={`${rowIndex} ${columnIndex}`}
								className={`${styles.square} ${
									shouldHighlight(rowIndex, columnIndex) ? styles.squareActive : ''
								}`}
								data-testid={`table-grid-size-${rowIndex}-${columnIndex}`}
								onClick={() => insertTable(rowIndex, columnIndex)}
								onMouseEnter={() => onSetHighlight({ x: rowIndex, y: columnIndex })}
							/>
						))}
					</div>
				))}
				<div className={styles.size}>
					<span>
						{highlight.x + 1} x {highlight.y + 1}
					</span>
				</div>
			</div>
		</div>
	);
};

export default TableSizeChanger;
