import React from 'react';
import Box from '@mui/material/Box';
import studyInProgress from '@worklist-2/ui/src/assets/img/studyInProgress.png';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const MobileProcessingImageSection = () => {
	const { t } = useTranslation('studyDetail');

	return (
		<Box
			sx={{
				border: '1px solid rgba(0, 0, 0, 0.1)',
				borderRadius: '20px',
				textAlign: 'center',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				padding: '0 30px 30px 30px',
				marginBottom: '10px',
				minHeight: '500px',
			}}
		>
			<Box sx={{ marginBottom: '-30px' }}>
				<img alt="Study in Progress" src={studyInProgress} />
			</Box>

			<Typography
				sx={{
					fontSize: '16px',
					lineHeight: '20px',
					color: 'rgba(57, 57, 57, 0.87)',
					marginBottom: '10px',
				}}
			>
				{t('Not available yet...')}
			</Typography>

			<Typography
				sx={{
					fontSize: '14px',
					lineHeight: '20px',
					color: 'rgba(57, 57, 57, 0.6)',
				}}
			>
				{t('Your image will be ready soon...')}
			</Typography>
		</Box>
	);
};

export default MobileProcessingImageSection;
