// React Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Material UI Libraries
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

function VisualCards({ cards, color }) {
	return (
		<Grid
			key="crop_chart_grid"
			container
			alignItems="center"
			direction="row"
			justifyContent="center"
			spacing={2}
			sx={{
				minHeight: '100%',
				background: 'rgba(189, 211, 231, 0.17)',
				width: '100%',
				paddingRight: '5%',
				marginLeft: 'unset',
			}}
		>
			{cards.map((item, index) => (
				<Grid key={`crop_chart_grid${index}`} item>
					<Box
						sx={{
							maxWidth: cards.length > 1 ? '160px' : '250px',
							textAlign: 'center',
							margin: 'auto',
							transition: '0.2s',
						}}
					>
						<Typography fontSize={16}>{item.title}</Typography>
						<Typography
							fontSize={40}
							sx={{
								color: color || '#4D79EA',
								marginTop: '10px',
								fontWeight: 500,
							}}
						>
							{item.number}
						</Typography>
					</Box>
				</Grid>
			))}
		</Grid>
	);
}

VisualCards.propTypes = {
	/**
	 * cards is card items array of visual cards
	 */
	cards: PropTypes.array.isRequired,
	/**
	 * color is color of reports number
	 */
	color: PropTypes.string,
};

export default VisualCards;
