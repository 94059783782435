import React, { useRef, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import prettyBytes from 'pretty-bytes';
import { styled } from '@mui/material/styles';
import { Box, Button, IconButton, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LastPageIcon from '@mui/icons-material/LastPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BusinessIcon from '@mui/icons-material/Business';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import DocumentIcon from '@worklist-2/ui/src/assets/icons/breeze/DocumentIcon.svg';
import { TabPanel } from '../../../Cases/casePopOverStyles';
import { useCrmContext } from '../../../../../context/CRMContext';
import { useConfig } from '@worklist-2/core/src';
import CustomButton from './common/CustomButton';

function arrayBufferToBase64(buffer) {
	const { Buffer } = require('buffer');
	return Buffer.from(buffer).toString('base64');
}

const SECTION = {
	Users: 'users',
	'Procedure Codes': 'procedureCodes',
	'Order Sets': 'orderSets',
	'Insurance Companies': 'insuranceCompanies',
	'Fee Schedules': 'feeSchedules',
};

const UploadFiles = ({ accountData, setStep, organizationID, setOrganizationData, isOnboardingStep }) => {
	const { toggleDetails, setCacheKey } = useCrmContext();
	const __config = useConfig();

	const handleFileChange = async files => {
		const promises = [...files].map(async file => {
			const fileNameArr = file.name.split('.');
			const fileInfo = {
				Title: fileNameArr[0],
				Type: fileNameArr[fileNameArr.length - 1],
				FileName: file.name,
				ContentType: file.type,
				FileSize: prettyBytes(file.size),
				CreatedAt: new Date(file.lastModified).toLocaleDateString(),
			};

			const fileReader = new FileReader();

			fileReader.readAsArrayBuffer(file);

			fileInfo.Data = await file.arrayBuffer().then(res => arrayBufferToBase64(res));
			return fileInfo;
		});
		const fileImport = await Promise.all(promises);

		return fileImport;
	};

	const sendFiles = async (files, category) => {
		if (files.length === 0) {
			return;
		}

		const payload = await handleFileChange(files);

		return axios
			.post(
				`${__config.data_sources.breeze}/OrganizationOmegaAI/${accountData?.AccountId}/${organizationID}/upload`,
				{
					[category]: payload,
				}
			)
			.catch(error => console.error(error));
	};

	return (
		<Box
			sx={{
				boxSizing: 'border-box',
				position: 'relative',
				zIndex: '20',
				width: '538px',
				height: '100vh',
				padding: '14px 24px 0px',
				background: '#FFF',
				boxShadow: '-4px 0 20px 0 rgba(33,88,119,0.1)',
			}}
		>
			<Box sx={{ marginBottom: '24px', display: 'flex', justifyContent: 'space-between' }}>
				<IconButton sx={{ padding: 0 }} onClick={() => setStep('features')}>
					<ArrowBackIcon />
				</IconButton>
				<IconButton
					sx={{ padding: 0 }}
					onClick={() => {
						setStep(null);
						setOrganizationData(null);
						toggleDetails();
					}}
				>
					<LastPageIcon />
				</IconButton>
			</Box>
			<TabPanel style={{ margin: 0, minHeight: 'unset', maxHeight: 'unset', height: 'calc(100%  - 48px)' }}>
				<div className="header" style={{ borderBottom: 'none' }}>
					<div className="left">
						<div className="colored-icon info-icon">
							<BusinessIcon sx={{ fontSize: 20 }} />
						</div>
						<h2>Upload Files</h2>
					</div>
					<div className="right">
						<div className="colored-icon active-icon">
							<BusinessIcon sx={{ fontSize: 10 }} />
						</div>
						<span className="active-span" />
						<div className="colored-icon active-icon">
							<CardTravelIcon sx={{ fontSize: 10 }} />
						</div>
						<span className="active-span" />
						<div className="colored-icon active-icon">
							<ToggleOnOutlinedIcon sx={{ fontSize: 10 }} />
						</div>
						<span className="active-span" />
						<div className="colored-icon active-icon">
							<DeviceHubIcon sx={{ fontSize: 10 }} />
						</div>
						<span className="active-span" />
						<div className="colored-icon active-icon">
							<FolderOutlinedIcon sx={{ fontSize: 10 }} />
						</div>
					</div>
				</div>
				<div className="body">
					<Box
						sx={{
							boxSizing: 'border-box',
							width: '100%',
							padding: '8px 18px',
							background: 'rgba(77, 121, 234, 0.03)',
						}}
					>
						<Typography
							sx={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}
						>
							Upload
						</Typography>
					</Box>
					<Box sx={{ boxSizing: 'border-box', padding: '10px 14px' }}>
						{Object.keys(SECTION).map(title => (
							<UploadAccordion category={SECTION[title]} sendFiles={sendFiles} title={title} />
						))}
					</Box>
				</div>
				<Box
					sx={{
						padding: '10px 18px 30px',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					{isOnboardingStep ? (
						<>
							<CustomButton outlined onClick={() => setStep('features')}>
								go back
							</CustomButton>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<CustomButton sx={{ marginLeft: '8px' }} onClick={() => setStep('device')}>
									next
								</CustomButton>
							</Box>
						</>
					) : (
						<CustomButton
							outlined
							onClick={() => {
								setStep(null);
								toggleDetails();
								setCacheKey(new Date().getTime()); // trigger update on FileList
							}}
						>
							Save
						</CustomButton>
					)}
				</Box>
			</TabPanel>
		</Box>
	);
};

const StyledAccordion = styled(Accordion)(() => ({
	marginBottom: '10px',
	backgroundColor: 'rgba(77, 121, 234, 0.03)',
	borderRadius: '10px',
	boxShadow: 'none',
	'&::before': {
		height: 0,
	},
	'&:last-child': {
		marginBottom: '0',
	},
	'&:first-of-type': {
		borderRadius: '10px',
	},
	'&:last-of-type': {
		borderRadius: '10px',
	},
	'&.Mui-expanded': {
		margin: '0 0 10px 0',
	},
	'.MuiAccordionDetails-root': {
		padding: 0,
		margin: 0,
	},
	'.MuiAccordionSummary-content': {
		padding: 0,
		margin: 0,
		'&.Mui-expanded': {
			margin: 0,
		},
	},
	'.MuiAccordionSummary-root': {
		padding: '0 4px 0 0',
		margin: 0,
		minHeight: 'unset',
		'&.Mui-expanded': {
			minHeight: 'unset',
		},
	},
	'.MuiAccordionSummary-expandIconWrapper': {
		padding: '14px',
		color: '#4D79EA',
	},
}));

const UploadAccordion = ({ title, category, sendFiles }) => {
	const [fileList, setFileList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [uploadStatus, setUploadStatus] = useState(false);
	const inputRef = useRef(null);

	const handleFileChange = async e => {
		const files = _.values(e.target.files);
		setFileList(files);
		setIsLoading(true);
		const result = await sendFiles(files, category);
		if (result && result.status == 200) {
			setUploadStatus(true);
		} else {
			setUploadStatus(false);
		}
		setIsLoading(false);
	};

	const handleUploadClick = () => {
		inputRef.current?.click();
	};

	return (
		<StyledAccordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '16px' }} />}>
				<Typography
					sx={{
						marginLeft: '20px',
						fontFamily: 'Inter',
						fontSize: '14px',
						fontWeight: 500,
						color: 'rgba(0, 0, 0, 0.6)',
					}}
				>
					{title}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Box sx={{ boxSizing: 'border-box', padding: '0 18px 10px 18px' }}>
					<Box
						sx={{
							boxSizing: 'border-box',
							padding: '16px',
							border: '1px dashed #4D79EA',
							borderRadius: '10px',
						}}
					>
						<Button
							sx={{
								width: '100%',
								paddingTop: '12px',
								paddingBottom: '18px',
								flexDirection: 'column',
								border: '1px solid #4D79EA',
								borderRadius: '5px',
								textTransform: 'unset',
								'&:hover': { backgroundColor: 'transparent' },
							}}
							onClick={handleUploadClick}
						>
							<FileUploadIcon sx={{ fontSize: '30px', color: '#4D79EA' }} />
							<Typography
								sx={{
									fontFamily: 'Inter',
									fontSize: '14px',
									fontWeight: 600,
									color: '#4D79EA',
								}}
							>
								Upload
							</Typography>
						</Button>
						<input
							ref={inputRef}
							multiple
							accept=".csv"
							style={{ display: 'none' }}
							type="file"
							onChange={handleFileChange}
						/>
					</Box>
					{fileList.length > 0 && (
						<Box sx={{ marginTop: '24px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
							{isLoading ? (
								<HeaderFilesListItem count={fileList.length} title="upload in progress..." />
							) : (
								<>
									<HeaderFilesListItem count={fileList.length} title="upload successfully" />
									<Box
										sx={{
											margin: '0 8px',
											width: '6px',
											height: '6px',
											backgroundColor: '#D9D9D9',
											borderRadius: '50%',
										}}
									/>
									<HeaderFilesListItem
										count={fileList.length}
										title={uploadStatus ? 'succeed' : 'failed'}
									/>
								</>
							)}
						</Box>
					)}
					<Box sx={{ maxHeight: '268px', overflowY: 'auto' }}>
						{fileList.map(file => (
							<UploadItem
								key={file.lastModified}
								lastUpdated={file.lastModified}
								name={file.name}
								size={file.size}
							/>
						))}
					</Box>
				</Box>
			</AccordionDetails>
		</StyledAccordion>
	);
};

const UploadItem = ({ name, size, lastUpdated }) => (
	<Box
		sx={{
			boxSizing: 'border-box',
			position: 'relative',
			width: '100%',
			marginBottom: '4px',
			padding: '16px 18px',
			display: 'flex',
			alignItems: 'center',
			border: '1px solid rgba(196, 196, 196, 0.4)',
			borderRadius: '10px',
			'&:last-child': {
				marginBottom: 0,
			},
		}}
	>
		<DocumentIcon />
		<Box sx={{ marginLeft: '18px' }}>
			<Typography
				sx={{
					fontFamily: 'Inter',
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '15px',
					color: 'rgba(0, 0, 0, 0.8)',
				}}
			>
				{name}
			</Typography>
			<Typography
				sx={{
					fontFamily: 'Inter',
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '15px',
					color: 'rgba(0, 0, 0, 0.6)',
				}}
			>
				{`${new Date(lastUpdated).toLocaleDateString()} • ${prettyBytes(size)} • ${name.split('.').slice(-1)}`}
			</Typography>
		</Box>
		<IconButton sx={{ position: 'absolute', top: '6px', right: '10px', padding: 0 }}>
			<CloseIcon sx={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.6)' }} />
		</IconButton>
	</Box>
);

const HeaderFilesListItem = ({ count, title }) => (
	<Box sx={{ display: 'flex' }}>
		<Typography
			sx={{
				marginRight: '6px',
				fontFamily: 'Inter',
				fontWeight: '500',
				fontSize: '14px',
				lineHeight: '15px',
				color: '#4D79EA',
			}}
		>
			{`${count} item${count === 1 ? '' : 's'}`}
		</Typography>
		<Typography
			sx={{
				fontFamily: 'Inter',
				fontWeight: '300',
				fontSize: '14px',
				lineHeight: '15px',
				color: 'rgba(0, 0, 0, 0.6)',
			}}
		>
			{title}
		</Typography>
	</Box>
);

export default UploadFiles;
