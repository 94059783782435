import LastPageIcon from '@mui/icons-material/LastPage';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Menu from '@mui/material/Menu';
import { styled, Select, MenuItem, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { DetailTab, DetailTabs } from '.';
import { Row, Col, Title, Wrapper, CaseHandlerWrapper } from '../../Cases/casePopOverStyles';
import Overview from './DetailTabs/Overview';
import Organization from './DetailTabs/Organization';
import Users from './DetailTabs/Users';
import _ from 'lodash';
import { useCrmContext } from '../../../../context/CRMContext';
import {
	accountMapping,
	crmSearchScopes,
	useCRMDataLoader,
	useCRMSearchScope,
	useAuth,
	useConfig,
} from '@worklist-2/core/src';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { MemberAvatar } from '../../AdminSettings/TeamCard';
import { TargetEntities } from '../../Cases/utils';

const NewAccountPopOver = ({ editable = true }) => {
	const { loggedInUser } = useAuth();
	const __config = useConfig();
	const numericRegex = /\d/;
	const { setFetchData, validateAccountForm } = useCRMSearchScope();
	const accountDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.accountApi,
	});
	const ref = useRef();
	const [status, setStatus] = useState('active');
	const tabs = ['Overview', 'Organization', 'Users'];
	const [tabIndex, setTabIndex] = useState(0);
	const { toggleNewDetails } = useCrmContext();
	const [overViewData, setOverViewData] = useState({});
	const [contactData, setContactData] = useState([]);
	const [errMsgs, setErrMsgs] = useState({});
	const [isValidForm, setIsValidForm] = useState(true);
	const [watcherData, setWatcherData] = useState([]);
	const [watching, setWatching] = useState(false);
	const resourceGroup = __config.resource_group;
	const entities = resourceGroup?.includes('team')
		? [{ name: resourceGroup.replaceAll('-', ' ').toUpperCase().replace('TEAM', 'Team'), value: 'resourceGroup' }]
		: TargetEntities[resourceGroup];

	const handleTabChange = (event, newTabIndex) => {
		setTabIndex(newTabIndex);
	};

	const onChangeData = (e, value) => {
		if (tabs[tabIndex].toLowerCase().includes('overview')) {
			handleOverViewTabChange(e.target.name, e.target.value);
		} else if (tabs[tabIndex].toLowerCase().includes('contacts')) {
			handleContactTabChange(value);
		}
	};

	const handleOverViewTabChange = (name, value) => {
		const data = overViewData;
		data[accountMapping[name]] = value;
		setOverViewData(data);
	};

	const handleContactTabChange = value => {
		const data = contactData;
		data.push(value);
		setContactData(data);
	};

	useEffect(() => {
		const ignoreClickOnMeElement = ref.current;

		const listener = event => {
			const customButton = ['assignedmenu'];
			const isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
			if (
				!isClickInsideElement &&
				!event.target.id &&
				!customButton.includes(event.target.id.split('-')[0]) &&
				!customButton.includes(event.target.parentElement.id.split('-')[0]) &&
				!event.target?.getAttribute('class')?.split(' ')?.includes('MuiBackdrop-invisible') &&
				!document.activeElement.className?.split(' ')?.includes('MuiMenuItem-root')
			) {
				toggleNewDetails();
			}
		};
		document.addEventListener('click', listener, true);

		return () => {
			document.removeEventListener('click', listener, true);
		};
	}, []);

	const handleSubmit = async event => {
		event.preventDefault();

		if (!validateAccountForm(event.target, overViewData, setErrMsgs, setIsValidForm)) {
			return;
		}

		const accountJson = buildAccountJson(event.target);

		const result = await accountDataLoader.save(null, accountJson);
		if (result.status == 409) {
			setErrMsgs({ clientId: 'Already exist' });
			setIsValidForm(false);
			return;
		}

		setFetchData(true);

		if (document.activeElement.value === 'saveNew') {
			event.target.reset();
		} else {
			toggleNewDetails();
		}
	};

	const buildAccountJson = data => {
		const accountJson = {};

		accountJson.ResourceType = 'Account';
		accountJson.AccountName = data.accountName?.value;
		accountJson.ClientId = data.clientId?.value.toUpperCase();
		accountJson.Phone = data.phone?.value;
		accountJson.ReferenceStatus = data.referenceStatus?.value;
		accountJson.AccountType = data.accountType?.value;
		accountJson.Status = data.status?.value;
		accountJson.TargetEntity = data.targetEntity?.value;

		//Data need handle before post
		for (const item in overViewData) {
			accountJson[item] = overViewData[item];
		}

		accountJson.RelatedContact = _.map(contactData, contact => ({
			FullName: contact.name,
			JobTitle: contact.jobTitle,
			CellPhone: contact.phone,
			Email: contact.email,
		}));

		accountJson.Watcher = watcherData;

		return accountJson;
	};

	const isWatching = () => _.some(watcherData, watcher => watcher.UserId === loggedInUser.id);

	const handleWatching = () => {
		if (isWatching()) {
			setWatcherData([]);
			setWatching(false);
		} else {
			setWatcherData([{ UserName: loggedInUser.display, UserId: loggedInUser.id }]);
			setWatching(true);
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<Wrapper ref={ref} className="invisible-scrollbar" id="popover-wrapper">
				<div className="header">
					<div className="actions">
						<div className="left" />
						<div className="right">
							<IconButton sx={{ color: 'rgba(0, 0, 0, 0.6)' }} onClick={handleWatching}>
								{watching ? <VisibilityIcon /> : <VisibilityOutlinedIcon />}
							</IconButton>
							<IconButton sx={{ color: 'rgba(0, 0, 0, 0.6)' }} onClick={() => toggleNewDetails()}>
								<LastPageIcon />
							</IconButton>
						</div>
					</div>
					<Title>
						<span className="status">
							<Select
								class="notchedOutline"
								name="status"
								sx={{
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										border: 'none',
									},
									'& .MuiOutlinedInput-notchedOutline': {
										border: 'none',
									},
								}}
								value={status}
								onChange={e => {
									setStatus(e.target.value);
								}}
							>
								<MenuItem value="busy">
									<Brightness1Icon
										sx={{
											fontSize: 'medium',
											color: 'red',
										}}
									/>
								</MenuItem>
								<MenuItem value="away">
									<Brightness1Icon
										sx={{
											fontSize: 'medium',
											color: 'yellow',
										}}
									/>
								</MenuItem>
								<MenuItem value="active">
									<Brightness1Icon
										sx={{
											fontSize: 'medium',
											color: '#3BE775',
										}}
									/>
								</MenuItem>
							</Select>
						</span>
						<TextField
							error={!!errMsgs.accountName}
							helperText={errMsgs.accountName}
							name="accountName"
							placeholder="Enter a name for this account"
							sx={{
								width: '355px',
								'& input': {
									marginRight: '0px',
								},
							}}
							variant="standard"
						/>
						<span style={{ color: 'red' }}>&nbsp;*</span>
					</Title>
					<Row>
						<Col>
							<label className="detail">
								Client ID
								<span style={{ color: 'red' }}>&nbsp;*</span>
							</label>
							<div className="row">
								<TextField
									hiddenLabel
									className="without-padding"
									error={!!errMsgs.clientId}
									helperText={errMsgs.clientId}
									id="clientId"
									name="clientId"
									placeholder="Format: 6 numbers and 1 letter"
									size="small"
									sx={{
										height: '35px',
										'& legend': {
											display: 'none',
										},
										'& fieldset': {
											top: 0,
										},
										fontSize: '14px',
										flex: '1',
										width: '226px',
									}}
									variant="outlined"
								/>
							</div>
						</Col>
						<Col>
							<label className="detail">Phone</label>
							<div className="row">
								<TextField
									hiddenLabel
									className="without-padding"
									error={!!errMsgs.phone}
									helperText={errMsgs.phone}
									id="phone"
									name="phone"
									size="small"
									sx={{
										height: '35px',
										'& legend': {
											display: 'none',
										},
										'& fieldset': {
											top: 0,
										},
										fontSize: '14px',
										flex: '1',
										width: '226px',
									}}
									variant="outlined"
									onKeyPress={e => {
										if (!numericRegex.test(e.key)) {
											e.preventDefault();
										}
									}}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<label className="detail">Reference Status</label>
							<div className="row">
								<TextField
									hiddenLabel
									className="without-padding"
									id="referenceStatus"
									name="referenceStatus"
									size="small"
									sx={{
										height: '35px',
										'& legend': {
											display: 'none',
										},
										'& fieldset': {
											top: 0,
										},
										fontSize: '14px',
										flex: '1',
										width: '226px',
									}}
									variant="outlined"
								/>
							</div>
						</Col>
						<Col>
							<label className="detail">Type</label>
							<div className="row">
								<TextField
									hiddenLabel
									className="without-padding"
									id="accountType"
									name="accountType"
									size="small"
									sx={{
										height: '35px',
										'& legend': {
											display: 'none',
										},
										'& fieldset': {
											top: 0,
										},
										fontSize: '14px',
										flex: '1',
										maxWidth: '226px',
									}}
									variant="outlined"
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<label className="detail">Target Entity</label>
							<div className="row">
								<Select
									defaultValue={entities?.[0]?.value}
									id="targetEntity"
									name="targetEntity"
									style={{
										border: 'none',
										fontFamily: 'Inter',
									}}
									sx={{
										'& legend': {
											display: 'none',
										},
										'& fieldset': {
											top: 0,
										},
										height: '40px',
										width: '156px',
										fontSize: '12px',
										fontStretch: 'normal',
										fontStyle: 'normal',
										flex: '1',
										borderRadius: 3,
									}}
								>
									{entities?.map(item => (
										<MenuItem key={item.value} value={item.value}>
											{item.name}
										</MenuItem>
									))}
								</Select>
							</div>
						</Col>
						<Col />
					</Row>
				</div>
				<div className="body">
					<Box sx={{ margin: '0px 36px' }}>
						<DetailTabs
							scrollButtons={false}
							value={tabIndex}
							variant="scrollable"
							onChange={handleTabChange}
						>
							{_.map(tabs, (tab, i) => (
								<DetailTab key={i} disabled={tab !== 'Overview'} label={tab} />
							))}
						</DetailTabs>
					</Box>
					<Box style={{ flex: 1, paddingBottom: 20 }}>
						{tabs[tabIndex].toLowerCase().includes('overview') && (
							<Overview
								data={overViewData}
								editable={editable}
								errorMsgs={errMsgs}
								isValidForm={isValidForm}
								onChangeData={onChangeData}
							/>
						)}
						{tabs[tabIndex].toLowerCase().includes('organization') && (
							<Organization accountData={overViewData} />
						)}
						{tabs[tabIndex].toLowerCase().includes('users') && <Users />}
					</Box>
					<div className="footer">
						<button
							className="outline"
							data-testid="cancelButton"
							type="button"
							onClick={() => toggleNewDetails()}
						>
							CANCEL
						</button>
						<div className="right">
							<button className="outline" data-testid="saveNewButton" type="submit" value="saveNew">
								SAVE &amp; New
							</button>
							<button className="fill" data-testid="saveButton" type="submit" value="save">
								SAVE
							</button>
						</div>
					</div>
				</div>
			</Wrapper>
		</form>
	);
};

export default NewAccountPopOver;

export const StyledMenu = styled(props => (
	<Menu
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		elevation={0}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(() => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: '-40px',
		marginLeft: '170px',
		background: '#FFF',
		minWidth: 209,
		color: '#000',
		border: '1px solid #4D79EA',
	},
	ul: {
		paddingTop: '2px',
	},
}));

export const AssignCaseMenu = ({ onClose, assignedUsers, caseHandlers, onSelect }) => {
	const [keyword, setKeyword] = useState('');

	const filteredHandlers = useMemo(() => {
		if (keyword.trim().length === 0) return caseHandlers;
		const keywords = _.compact(keyword.split(/\s/g));
		return caseHandlers.filter(user => keywords.some(element => user.UserName?.toLowerCase().includes(element)));
	}, [caseHandlers, keyword]);

	return (
		<CaseHandlerWrapper>
			<div className="search-bar" id="assignedmenu-searchbar">
				<AccountCircleOutlinedIcon
					id="assignedmenu-unasigned-avatar"
					sx={{ color: 'rgba(0, 0, 0, 0.4)', fontSize: '36px' }}
				/>
				<Input
					id="assignedmenu-input"
					placeholder="Search"
					sx={{
						border: 0,
						marginLeft: '10px',
						fontSize: '14px',
						color: '#000',
					}}
					value={keyword}
					onChange={e => {
						setKeyword(e.target.value);
					}}
					onKeyPress={e => {
						if (e.key === 'Enter') {
							if (!e.target.value) onClose();
						}
					}}
				/>
			</div>
			<ul className="case-handlers" id="assignedmenu-ul">
				{filteredHandlers?.map((user, i) => (
					<li
						key={i}
						id="assignedmenu-li"
						style={{
							marginBottom: 5,
							backgroundColor: assignedUsers.map(u => u.UserId)?.includes(user.UserId)
								? '#EDF2FF'
								: 'inherit',
						}}
						onClick={() => {
							onSelect(user);
						}}
					>
						<MemberAvatar member={user} sx={{ marginLeft: 0 }} />
						<span>{user.UserName}</span>
					</li>
				))}
			</ul>
		</CaseHandlerWrapper>
	);
};
