import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { alignment, lateralityMapper, position } from '../../consts/consts';
import { Box } from '@mui/material';
import { Tags } from './components/Tags';
import { SpineLabel } from './components/SpineLabel';

const useStyles = makeStyles()(() => ({
	mgleft: {
		justifySelf: 'flex-end',
		'& *': {
			textAlign: 'right',
		},
	},
	mgcenter: {
		justifySelf: 'flex-start',
		'& *': {
			textAlign: 'left',
		},
	},
	mgright: {
		justifySelf: 'flex-start',
		'& *': {
			textAlign: 'left',
		},
	},
	horizontalleft: {
		justifySelf: 'flex-start',
		'& *': {
			textAlign: 'left',
		},
	},
	horizontalcenter: {
		justifySelf: 'center',
		margin: '0 1ch',
		'& *': {
			textAlign: 'center',
		},
	},
	horizontalright: {
		justifySelf: 'flex-end',
		'& *': {
			textAlign: 'right',
		},
	},
	verticaltop: {
		display: 'grid',
		alignItems: 'flex-start',
		alignSelf: 'flex-start',
		gridTemplateColumns: '1fr auto 1fr',
		gridArea: 'top',
	},
	verticalcenter: {
		display: 'grid',
		alignItems: 'baseline',
		alignSelf: 'center',
		gridTemplateColumns: '1fr 1fr 1fr',
		gridArea: 'center',
	},
	verticalbottom: {
		display: 'grid',
		alignItems: 'flex-end',
		alignSelf: 'flex-end',
		gridTemplateColumns: '1fr auto 1fr',
		gridArea: 'bottom',
	},
	mammography: {
		display: 'grid',
		gridAutoFlow: 'row',
		gridTemplateColumns: '1fr',
		gap: 0,
	},
	regular: {
		display: 'grid',
		gridAutoFlow: 'column',
		gap: 2,
	},
	tagsWrapper: {
		display: 'grid',
		gridAutoFlow: 'row',
		overflow: 'hidden',
	},
}));

export const SectionRenderer = ({
	sectionAlignment = 'default',
	viewport,
	verticalPosition,
	column,
	metadata,
	isCurrentStudy,
}) => {
	const { classes, cx } = useStyles();

	return (
		<Box className={cx(classes[`vertical${verticalPosition}`])}>
			{Object.keys(alignment).map(alkey => {
				if (sectionAlignment !== 'default' && alignment[alkey] === alignment.center) {
				} else {
					return (
						<Box
							key={`section-overlay-alignment-${alkey}`}
							className={cx(
								classes.tagsWrapper,
								sectionAlignment !== 'default'
									? classes[`mg${sectionAlignment}`]
									: classes[`horizontal${alignment[alkey]}`]
							)}
							id={`customOverlay|${viewport?.id}|${verticalPosition}|${alignment[alkey]}`}
							sx={{
								gridColumn:
									sectionAlignment !== 'default'
										? sectionAlignment === lateralityMapper.L
											? 3
											: 1
										: 'auto',
							}}
						>
							{alignment.center === alkey && verticalPosition === position.top && (
								<SpineLabel viewportId={viewport.id} />
							)}
							<Tags
								column={column?.[alignment[alkey]] || []}
								isCurrentStudy={isCurrentStudy}
								metadata={metadata}
								viewport={viewport}
							/>
						</Box>
					);
				}
			})}
		</Box>
	);
};
