// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FhZ2Ml3wYyoIGFu2XhCC{position:absolute;top:230px;left:380px;display:flex;flex-direction:column;justify-content:center;align-items:center;gap:10px}.mQdaYtFVjVDD5LtYTfRw{display:flex;justify-content:center;align-items:center;width:40px;height:40px;border-radius:100%;border:2px solid #272727;background:#3e3e3e;cursor:pointer;z-index:1}.RG_A_unxZaZZb6al4HhC{position:absolute;top:180px;left:490px;display:flex;flex-direction:column;justify-content:center;align-items:center;gap:10px}.xfWxa0F3rmbm85gSC6JG{display:flex;justify-content:center;align-items:center;width:40px;height:40px;border-radius:100%;border:2px solid #d9d9d9;background:#fff;cursor:pointer;z-index:1}.ZKl7VnUVeIayNAdurVlK{background:#4d79ea}.bZ6gL9E3OXYZFeTJsC3n{left:670px}.InSCcarOL138OKgsUqR1{left:780px}", "",{"version":3,"sources":["webpack://./src/components/CRM/Documentation/common/ActionButtons/ActionButtons.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,SAAA,CACA,UAAA,CAEA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,QAAA,CAEA,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CAEA,kBAAA,CACA,wBAAA,CACA,kBAAA,CACA,cAAA,CACA,SAAA,CAIJ,sBACE,iBAAA,CACA,SAAA,CACA,UAAA,CAEA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,QAAA,CAEA,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CAEA,kBAAA,CACA,wBAAA,CACA,eAAA,CACA,cAAA,CACA,SAAA,CAIJ,sBACE,kBAAA,CAGF,sBACE,UAAA,CAGF,sBACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionButtonsOAI": "FhZ2Ml3wYyoIGFu2XhCC",
	"actionButtonsOAIItem": "mQdaYtFVjVDD5LtYTfRw",
	"actionButtonsBlume": "RG_A_unxZaZZb6al4HhC",
	"actionButtonsBlumeItem": "xfWxa0F3rmbm85gSC6JG",
	"actionButtonsItemActive": "ZKl7VnUVeIayNAdurVlK",
	"actionButtonsOAIActive": "bZ6gL9E3OXYZFeTJsC3n",
	"actionButtonsBlumeActive": "InSCcarOL138OKgsUqR1"
};
export default ___CSS_LOADER_EXPORT___;
