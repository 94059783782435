/* Generated automagically by FhirStarter - DO NOT EDIT */

import { validateArgs } from './validateArgs';
import { getSchema } from '../datatypes';
import buildDosageFunc from './fhirDosageResource';
import buildMetaFunc from './fhirMetaResource';
import buildReferenceFunc from './fhirReferenceResource';
import buildExtensionFunc from './fhirExtensionResource';
import buildNarrativeFunc from './fhirNarrativeResource';

const buildAddressFunc = args => {
	const { id, extension, use, type, text, line, city, district, state, postalCode, country, period } = args;
	const schema = getSchema('Address');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		use: use,
		type: type,
		text: text,
		line: line,
		city: city,
		district: district,
		state: state,
		postalCode: postalCode,
		country: country,
		period: period,
		__valid: valid,
	};
};

const buildAnnotationFunc = args => {
	const { id, extension, authorReference, authorString, time, text } = args;
	const schema = getSchema('Annotation');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		authorReference: authorReference,
		authorString: authorString,
		time: time,
		text: text,
		__valid: valid,
	};
};

const buildAttachmentFunc = args => {
	const { id, extension, contentType, language, data, url, size, hash, title, creation } = args;
	const schema = getSchema('Attachment');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		contentType: contentType,
		language: language,
		data: data,
		url: url,
		size: size,
		hash: hash,
		title: title,
		creation: creation,
		__valid: valid,
	};
};

const buildCodeableConceptFunc = args => {
	const { id, extension, coding, text } = args;
	const schema = getSchema('CodeableConcept');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		coding: coding,
		text: text,
		__valid: valid,
	};
};

const buildCodingFunc = args => {
	const { id, extension, system, version, code, display, userSelected } = args;
	const schema = getSchema('Coding');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		system: system,
		version: version,
		code: code,
		display: display,
		userSelected: userSelected,
		__valid: valid,
	};
};

const buildContactPointFunc = args => {
	const { id, extension, system, value, use, rank, period } = args;
	const schema = getSchema('ContactPoint');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		system: system,
		value: value,
		use: use,
		rank: rank,
		period: period,
		__valid: valid,
	};
};

const buildHumanNameFunc = args => {
	const { id, extension, use, text, family, given, prefix, suffix, period } = args;
	const schema = getSchema('HumanName');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		use: use,
		text: text,
		family: family,
		given: given,
		prefix: prefix,
		suffix: suffix,
		period: period,
		__valid: valid,
	};
};

const buildIdentifierFunc = args => {
	const { id, extension, use, type, system, value, period, assigner } = args;
	const schema = getSchema('Identifier');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		use: use,
		type: type,
		system: system,
		value: value,
		period: period,
		assigner: assigner,
		__valid: valid,
	};
};

const buildMoneyFunc = args => {
	const { id, extension, value, currency } = args;
	const schema = getSchema('Money');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		value: value,
		currency: currency,
		__valid: valid,
	};
};

const buildPeriodFunc = args => {
	const { id, extension, start, end } = args;
	const schema = getSchema('Period');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		start: start,
		end: end,
		__valid: valid,
	};
};

const buildQuantityFunc = args => {
	const { id, extension, value, comparator, unit, system, code } = args;
	const schema = getSchema('Quantity');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		value: value,
		comparator: comparator,
		unit: unit,
		system: system,
		code: code,
		__valid: valid,
	};
};

const buildRangeFunc = args => {
	const { id, extension, low, high } = args;
	const schema = getSchema('Range');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		low: low,
		high: high,
		__valid: valid,
	};
};

const buildRatioFunc = args => {
	const { id, extension, numerator, denominator } = args;
	const schema = getSchema('Ratio');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		numerator: numerator,
		denominator: denominator,
		__valid: valid,
	};
};

const buildSampledDataFunc = args => {
	const { id, extension, origin, period, factor, lowerLimit, upperLimit, dimensions, data } = args;
	const schema = getSchema('SampledData');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		origin: origin,
		period: period,
		factor: factor,
		lowerLimit: lowerLimit,
		upperLimit: upperLimit,
		dimensions: dimensions,
		data: data,
		__valid: valid,
	};
};

const buildSignatureFunc = args => {
	const { id, extension, type, when, who, onBehalfOf, targetFormat, sigFormat, data } = args;
	const schema = getSchema('Signature');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		type: type,
		when: when,
		who: who,
		onBehalfOf: onBehalfOf,
		targetFormat: targetFormat,
		sigFormat: sigFormat,
		data: data,
		__valid: valid,
	};
};

const buildTimingFunc = args => {
	const {
		id,
		extension,
		event,
		repeatBoundsDuration,
		repeatBoundsRange,
		repeatBoundsPeriod,
		repeatCount,
		repeatCountMax,
		repeatDuration,
		repeatDurationMax,
		repeatDurationUnit,
		repeatFrequency,
		repeatFrequencyMax,
		repeatPeriod,
		repeatPeriodMax,
		repeatPeriodUnit,
		repeatDayOfWeek,
		repeatTimeOfDay,
		repeatWhen,
		repeatOffset,
		code,
	} = args;
	const schema = getSchema('Timing');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		event: event,
		repeat: {
			boundsDuration: repeatBoundsDuration,
			boundsRange: repeatBoundsRange,
			boundsPeriod: repeatBoundsPeriod,
			count: repeatCount,
			countMax: repeatCountMax,
			duration: repeatDuration,
			durationMax: repeatDurationMax,
			durationUnit: repeatDurationUnit,
			frequency: repeatFrequency,
			frequencyMax: repeatFrequencyMax,
			period: repeatPeriod,
			periodMax: repeatPeriodMax,
			periodUnit: repeatPeriodUnit,
			dayOfWeek: repeatDayOfWeek,
			timeOfDay: repeatTimeOfDay,
			when: repeatWhen,
			offset: repeatOffset,
		},
		code: code,
		__valid: valid,
	};
};

const buildContactDetailFunc = args => {
	const { id, extension, name, telecom } = args;
	const schema = getSchema('ContactDetail');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		name: name,
		telecom: telecom,
		__valid: valid,
	};
};

const buildContributorFunc = args => {
	const { id, extension, type, name, contact } = args;
	const schema = getSchema('Contributor');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		type: type,
		name: name,
		contact: contact,
		__valid: valid,
	};
};

/****************************************************** codeFilter ******************************************************
{
  "path": "__string__",
  "searchParam": "__string__",
  "valueSet": "__canonical(ValueSet)__",
  "code": [
    "__Coding__"
  ]
}
***********************************************************************************************************************/

/****************************************************** dateFilter ******************************************************
{
  "path": "__string__",
  "searchParam": "__string__",
  "valueDateTime": "__dateTime__",
  "valuePeriod": "__Period__",
  "valueDuration": "__Duration__"
}
***********************************************************************************************************************/

/********************************************************* sort *********************************************************
{
  "path": "__string__",
  "direction": "__code__"
}
***********************************************************************************************************************/

const buildDataRequirementFunc = args => {
	const {
		id,
		extension,
		type,
		profile,
		subjectCodeableConcept,
		subjectReference,
		mustSupport,
		codeFilter,
		dateFilter,
		limit,
		sort,
	} = args;
	const schema = getSchema('DataRequirement');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		type: type,
		profile: profile,
		subjectCodeableConcept: subjectCodeableConcept,
		subjectReference: subjectReference,
		mustSupport: mustSupport,
		codeFilter: codeFilter,
		dateFilter: dateFilter,
		limit: limit,
		sort: sort,
		__valid: valid,
	};
};

const buildExpressionFunc = args => {
	const { id, extension, description, name, language, expression, reference } = args;
	const schema = getSchema('Expression');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		description: description,
		name: name,
		language: language,
		expression: expression,
		reference: reference,
		__valid: valid,
	};
};

const buildParameterDefinitionFunc = args => {
	const { id, extension, name, use, min, max, documentation, type, profile } = args;
	const schema = getSchema('ParameterDefinition');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		name: name,
		use: use,
		min: min,
		max: max,
		documentation: documentation,
		type: type,
		profile: profile,
		__valid: valid,
	};
};

const buildRelatedArtifactFunc = args => {
	const { id, extension, type, label, display, citation, url, document, resource } = args;
	const schema = getSchema('RelatedArtifact');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		type: type,
		label: label,
		display: display,
		citation: citation,
		url: url,
		document: document,
		resource: resource,
		__valid: valid,
	};
};

const buildTriggerDefinitionFunc = args => {
	const { id, extension, type, name, timingTiming, timingReference, timingDate, timingDateTime, data, condition } =
		args;
	const schema = getSchema('TriggerDefinition');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		type: type,
		name: name,
		timingTiming: timingTiming,
		timingReference: timingReference,
		timingDate: timingDate,
		timingDateTime: timingDateTime,
		data: data,
		condition: condition,
		__valid: valid,
	};
};

const buildUsageContextFunc = args => {
	const { id, extension, code, valueCodeableConcept, valueQuantity, valueRange, valueReference } = args;
	const schema = getSchema('UsageContext');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		code: code,
		valueCodeableConcept: valueCodeableConcept,
		valueQuantity: valueQuantity,
		valueRange: valueRange,
		valueReference: valueReference,
		__valid: valid,
	};
};

const buildAgeFunc = args => {
	const { id, extension, value, comparator, unit, system, code } = args;
	const schema = getSchema('Age');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		value: value,
		comparator: comparator,
		unit: unit,
		system: system,
		code: code,
		__valid: valid,
	};
};

const buildCountFunc = args => {
	const { id, extension, value, comparator, unit, system, code } = args;
	const schema = getSchema('Count');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		value: value,
		comparator: comparator,
		unit: unit,
		system: system,
		code: code,
		__valid: valid,
	};
};

const buildDurationFunc = args => {
	const { id, extension, value, comparator, unit, system, code } = args;
	const schema = getSchema('Duration');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		value: value,
		comparator: comparator,
		unit: unit,
		system: system,
		code: code,
		__valid: valid,
	};
};

const buildDistanceFunc = args => {
	const { id, extension, value, comparator, unit, system, code } = args;
	const schema = getSchema('Distance');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		value: value,
		comparator: comparator,
		unit: unit,
		system: system,
		code: code,
		__valid: valid,
	};
};

const buildSimpleQuantityFunc = args => {
	const { id, extension, value, comparator, unit, system, code } = args;
	const schema = getSchema('SimpleQuantity');
	let valid = validateArgs(schema, args, Object.keys(args));
	return {
		id: id,
		extension: extension,
		value: value,
		comparator: comparator,
		unit: unit,
		system: system,
		code: code,
		__valid: valid,
	};
};

const buildAddress = buildAddressFunc;
const buildAnnotation = buildAnnotationFunc;
const buildAttachment = buildAttachmentFunc;
const buildCodeableConcept = buildCodeableConceptFunc;
const buildCoding = buildCodingFunc;
const buildContactPoint = buildContactPointFunc;
const buildHumanName = buildHumanNameFunc;
const buildIdentifier = buildIdentifierFunc;
const buildMoney = buildMoneyFunc;
const buildPeriod = buildPeriodFunc;
const buildQuantity = buildQuantityFunc;
const buildRange = buildRangeFunc;
const buildRatio = buildRatioFunc;
const buildSampledData = buildSampledDataFunc;
const buildSignature = buildSignatureFunc;
const buildTiming = buildTimingFunc;
const buildContactDetail = buildContactDetailFunc;
const buildContributor = buildContributorFunc;
const buildDataRequirement = buildDataRequirementFunc;
const buildExpression = buildExpressionFunc;
const buildParameterDefinition = buildParameterDefinitionFunc;
const buildRelatedArtifact = buildRelatedArtifactFunc;
const buildTriggerDefinition = buildTriggerDefinitionFunc;
const buildUsageContext = buildUsageContextFunc;
const buildAge = buildAgeFunc;
const buildCount = buildCountFunc;
const buildDuration = buildDurationFunc;
const buildDistance = buildDistanceFunc;
const buildSimpleQuantity = buildSimpleQuantityFunc;
const buildDosage = buildDosageFunc;
const buildMeta = buildMetaFunc;
const buildReference = buildReferenceFunc;
const buildExtension = buildExtensionFunc;
const buildNarrative = buildNarrativeFunc;
const dict = {
	Address: buildAddress,
	Annotation: buildAnnotation,
	Attachment: buildAttachment,
	CodeableConcept: buildCodeableConcept,
	Coding: buildCoding,
	ContactPoint: buildContactPoint,
	HumanName: buildHumanName,
	Identifier: buildIdentifier,
	Money: buildMoney,
	Period: buildPeriod,
	Quantity: buildQuantity,
	Range: buildRange,
	Ratio: buildRatio,
	SampledData: buildSampledData,
	Signature: buildSignature,
	Timing: buildTiming,
	ContactDetail: buildContactDetail,
	Contributor: buildContributor,
	DataRequirement: buildDataRequirement,
	Expression: buildExpression,
	ParameterDefinition: buildParameterDefinition,
	RelatedArtifact: buildRelatedArtifact,
	TriggerDefinition: buildTriggerDefinition,
	UsageContext: buildUsageContext,
	Age: buildAge,
	Count: buildCount,
	Duration: buildDuration,
	Distance: buildDistance,
	SimpleQuantity: buildSimpleQuantity,
	Dosage: buildDosage,
	Meta: buildMeta,
	Reference: buildReference,
	Extension: buildExtension,
	Narrative: buildNarrative,
};

export const getBuilderFunction = resource => {
	return dict[resource];
};

export const buildDataType = (resource, ...args) => {
	return dict[resource](...args);
};
