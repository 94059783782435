import getExtensionValueDateTime from '@rs-core/fhir/resource/columnMapping/utils/getExtensionValueDateTime';
import getExtensionValueCodingArrayConcat from '@rs-core/fhir/resource/columnMapping/utils/getExtensionValueCodingArrayConcat';
import getExtensionValueCoding from '@rs-core/fhir/resource/columnMapping/utils/getExtensionValueCoding';
import getExtensionValueString from '@rs-core/fhir/resource/columnMapping/utils/getExtensionValueString';

import _ from 'lodash';

const getNestedExtensionValue = (resource, fhirUrl, nestedUrl, valueType) => {
	let firstLevelExtension = _.find(resource?.extension, elem => elem.url === fhirUrl);

	if (!!firstLevelExtension) {
		switch (valueType) {
			case 'string':
				return getExtensionValueString(firstLevelExtension, nestedUrl);
			case 'coding':
				return getExtensionValueCoding(firstLevelExtension, nestedUrl);
			case 'codingArray':
				return getExtensionValueCodingArrayConcat(firstLevelExtension, nestedUrl);
			case 'dateTime':
				return getExtensionValueDateTime(firstLevelExtension, nestedUrl);
			default:
				return value.valueString;
		}
	}
};

export default getNestedExtensionValue;
