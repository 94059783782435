import fhirExtensionUrls from '@worklist-2/core/src/fhir/extension/fhirExtensionUrls';
const getUrlLabel = () => {
	return {
		practitioner: {
			getLabel: key => {
				switch (key) {
					case fhirExtensionUrls.practitioner.userTypeIsPerformingPhysician:
						return 'Performing Physician';
					case fhirExtensionUrls.practitioner.userTypeIsPerformingTechnologist:
						return 'Performing Technologist';
					case fhirExtensionUrls.practitioner.userTypeIsReadingPhysician:
						return 'Reading Physician';
					case fhirExtensionUrls.practitioner.userTypeIsReferringPhysician:
						return 'Referring Physician';
					case fhirExtensionUrls.practitioner.userTypeIsSyswideAdmin:
						return 'System wide admin';
					case fhirExtensionUrls.practitioner.userTypeIsAdmin:
						return 'Admin';
					default:
						return 'N/A';
				}
			},
		},
	};
};
export default getUrlLabel;
