class ImportQueue {
	priorityQueue = null;
	fileQueue = null;
	requireSendFiles = false;
	parent = null; // call saved to notify when certain number files are ready to import
	MAX_NUM_FILE_TO_SEND = null;
	numberOfFolderBeingRead = 0;
	arrayOfTimers = [];
	importingCancel = false;
	skipped = false;
	importingState = 'importing';

	constructor(parent) {
		this.priorityQueue = [];
		this.fileQueue = [];
		this.debugEnable =
			sessionStorage.getItem('rsff_import_debugEnable') ||
			this.debugEnable;
		this.parent = parent;
	}

	add = (newData) => {
		this.priorityQueue.push(newData);
	};

	clear = () => {
		this.priorityQueue.length = 0;
	};

	getItemIndex = (item) => {
		let index = -1;
		for (let i = 0; i < this.priorityQueue.length; i++) {
			if (this.priorityQueue[i].item === item) {
				index = i;
				break;
			}
		}
		return index;
	};

	removeItem = (item) => {
		let index = this.getItemIndex(item);
		if (index > -1) {
			this.priorityQueue.splice(index, 1);
		}
	};

	isEmpty = () => {
		return this.finishedPopulation() && this.fileQueue.length === 0;
	};

	isImportedOrSkipped = (fileName, file) => {
		return null;
	};

	resetPriorityQueue = () => {
		this.priorityQueue = [];
		for (let timers of this.arrayOfTimers) {
			if (timers) {
				timers.destroy();
			}
		}
		this.arrayOfTimers = [];
		this.numberOfFolderBeingRead = [];
	};

	populateFiles = (num, state) => {
		this.MAX_NUM_FILE_TO_SEND = num;
		this.requireSendFiles = true;
		this.importingState = state;

		if (this.priorityQueue.length > 0) {
			for (let i = 0; i < this.priorityQueue.length; i++) {
				let queueData = this.priorityQueue[i],
					dicomJson = queueData.dicomJson,
					url = queueData.url,
					item = queueData.item,
					payload = queueData.payload,
					completeStatus = queueData?.complete || false;
				if (!completeStatus && item instanceof File) {
					queueData.complete = true;
					let indexReturn = null;

					if (
						!indexReturn ||
						(importingState === 'retrying' && indexReturn)
					) {
						this.addIntoFileQueue({
							item: item,
							fileType: item.type,
							dicomJson,
							fullPath: item.path,
							url,
							payload: payload,
						});
					} else if (item instanceof FileList) {
						for (let files of item) {
							let indexReturn = null;

							if (
								!indexReturn ||
								(importingState === 'retrying' && indexReturn)
							) {
								this.addIntoFileQueue({
									item: files,
									fileType: files.type,
									dicomJson,
									fullPath: files.path,
									url,
									payload: payload,
								});
							}
						}
						this.queueData.complete = true;
					} else {
						this.populateFolder(item, dicomJson, url).then(() => {
							this.queueData.complete = true;
							if (fileQueue.length > 0) {
								this.sendFiles();
							}
						});
					}
				}
			}
		}
	};

	addIntoFileQueue = (item) => {
		console.log(
			'add file into queue - current queue length=' +
				this.fileQueue.length
		);
		this.parent.totalFileSize = this.parent.totalFileSize + item.item?.size;
		this.fileQueue.push(item);
		this.sendFiles();
	};

	finishedPopulation = () => {
		let complete = true;

		for (let i = 0; i < this.priorityQueue.length; i++) {
			let queueData = this.priorityQueue[i];
			if (!queueData.complete) {
				complete = false;
				break;
			}
		}
		console.log('finishedPopulation - ', complete);
		return complete;
	};

	sendFiles = () => {
		if (
			this.requireSendFiles &&
			(this.fileQueue.length >= this.MAX_NUM_FILE_TO_SEND ||
				this.finishedPopulation())
		) {
			this.requireSendFiles = false;
			console.log('splice start');
			let records = this.fileQueue.splice(0, this.MAX_NUM_FILE_TO_SEND);
			console.log('splice end');
			// notify dispatcher files are ready to send
			this.parent.sendFiles(records);
		}
	};

	populateFolder = (entry, dicomJson, url) => {
		let reader = entry.createReader(),
			readInterval;

		this.numberOfFolderBeingRead++;
		// Resolved when the entire directory is traversed
		return new Promise((resolve_directory) => {
			function read_entries() {
				// re-calculate interval
				let newInterval =
					fileQueue.length + this.numberOfFolderBeingRead + 100;
				if (
					(readInterval.interval > newInterval &&
						newInterval > 1000) ||
					readInterval.interval > 120000
				) {
					console.log(
						'Folder Being Read: ' +
							this.numberOfFolderBeingRead +
							' File in Queue: ' +
							this.fileQueue.length +
							' interval: ' +
							readInterval.interval / 1000 +
							's To ' +
							newInterval / 1000 +
							's'
					);
					readInterval.interval = newInterval;
				}

				// According to the FileSystem API spec, readEntries() must be called until
				// it calls the callback with an empty array.
				reader.readEntries((entries) => {
					if (!entries.length) {
						// Done iterating this particular directory
						readInterval.destroy();
						if (this.numberOfFolderBeingRead > 1) {
							this.numberOfFolderBeingRead--;
						}
						this.resolve_directory(Promise.all(iteration_attempts));
					} else {
						// Add a list of promises for each directory entry.  If the entry is itself
						// a directory, then that promise won't resolve until it is fully traversed.
						iteration_attempts.push(
							Promise.all(
								entries.map(function (item) {
									if (item.isFile) {
										item.file((file) => {
											this.addIntoFileQueue({
												item: file,
												fileType: file.type,
												dicomJson,
												fullPath: item.path,
												url,
											});
										});
									} else {
										// DO SOMETHING WITH DIRECTORIES
										return this.populateFolder(
											item,
											dicomJson,
											url
										);
									}
								})
							)
						);
					}
				});
			}

			function calcinterval() {
				let numFolder = this.numberOfFolderBeingRead,
					numFile = this.fileQueue.length,
					interval = numFolder * 20;

				if (interval < 1000) {
					interval = interval + 200;
				} else if (60000 < interval < 120000) {
					interval = numFolder * 15;
				} else if (120000 < interval < 180000) {
					interval = numFolder * 10;
				} else if (interval > 180000) {
					interval = numFolder * 0.1 + numFile;
				}
				return interval;
			}

			var iteration_attempts = [];
			readInterval = new Ext.util.TaskRunner().newTask({
				run: read_entries,
				fireOnStart: false,
				interval: calcinterval(),
				scope: this,
			});
			console.log(
				'Folder Being Read: ' +
					this.numberOfFolderBeingRead +
					' File in Queue: ' +
					this.fileQueue.length +
					' interval: ' +
					readInterval.interval / 1000
			);
			arrayOfTimers.push(readInterval);
			readInterval.start();
		});
	};

	getMoreTasks = () => {
		console.log('get more tasks from queue');
		this.requireSendFiles = true;
		this.sendFiles();
	};

	getCurrentTimeStr = () => {
		var date = new Date();

		return (
			this.leftPad(date.getHours(), 2, '0') +
			':' +
			this.leftPad(date.getMinutes(), 2, '0') +
			':' +
			this.leftPad(date.getSeconds(), 2, '0') +
			':' +
			this.leftPad(date.getMilliseconds(), 3, '0') +
			': '
		);
	};

	leftPad(string, size, character) {
		var result = String(string);
		character = character || ' ';
		while (result.length < size) {
			result = character + result;
		}
		return result;
	}
}

export default ImportQueue;
