import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const SelectedPopover = ({ count, onDelete }) =>
	count ? (
		<Box
			sx={{
				boxSizing: 'border-box',
				position: 'fixed',
				bottom: '50px',
				left: '50%',
				minWidth: '278px',
				padding: '8px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#4D79EA',
				borderRadius: '30px',
				boxShadow: '0px 30px 50px rgba(0, 0, 0, 0.21)',
				transform: 'translateX( -50%)',
			}}
		>
			<Typography
				sx={{
					width: '110px',
					fontSize: '14px',
					fontWeight: '600',
					letterSpacing: '1px',
					textAlign: 'right',
					textWrap: 'nowrap',
					textTransform: 'uppercase',
					color: '#FFF',
				}}
			>
				{`${count} selected`}
			</Typography>
			<span
				style={{
					width: '1px',
					height: '18px',
					margin: '0 16px 0 20px',
					backgroundColor: '#94B9FF',
				}}
			/>
			<IconButton data-testid="share-button" sx={{ marginRight: '14px' }}>
				<ShareIcon sx={{ color: '#FFF' }} />
			</IconButton>
			<IconButton data-testid="delete-button" onClick={onDelete}>
				<DeleteOutlinedIcon sx={{ color: '#FFF' }} />
			</IconButton>
		</Box>
	) : null;

export default SelectedPopover;
