/* eslint-disable no-shadow */
// @flow

// core
import {
	ERoutes,
	type MarketplaceAppDirectoryQuery,
	type MarketplaceDetailParams,
	type OrderDetailParams,
	type OrgDetailParams,
	type OrgQuery,
	type OrgUsersParams,
	type OrgUserDetailParams,
	type PatientDetailParams,
} from '@worklist-2/worklist/src/routes/routes';
// libs
import {
	createSearchParams,
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
	type NavigateOptions,
	type To,
} from 'react-router-dom';

// ========== Custom types ==========

/** This type is taken from `react-router-dom` and simplified to be object-only, had to be redeclared here because "Flow" can't import it correctly */
interface Location {
	/**
	 * A URL pathname, beginning with a /.
	 *
	 * @see https://github.com/remix-run/history/tree/main/docs/api-reference.md#location.pathname
	 */
	pathname: string;
	/**
	 * A URL search string, beginning with a ?.
	 *
	 * @see https://github.com/remix-run/history/tree/main/docs/api-reference.md#location.search
	 */
	search: string;
	/**
	 * A URL fragment identifier, beginning with a #.
	 *
	 * @see https://github.com/remix-run/history/tree/main/docs/api-reference.md#location.hash
	 */
	hash: string;
	/**
	 * A value of arbitrary data associated with this location.
	 *
	 * @see https://github.com/remix-run/history/tree/main/docs/api-reference.md#location.state
	 */
	state: mixed;
	/**
	 * A unique string associated with this location. May be used to safely store
	 * and retrieve data in some other storage API, like `localStorage`.
	 *
	 * Note: This value is always "default" on the initial location.
	 *
	 * @see https://github.com/remix-run/history/tree/main/docs/api-reference.md#location.key
	 */
	key: string;
}

type TParams = Record<string, string | number | boolean>;

type TQuery = Partial<Record<string, string | string[]>>;

type TRouteState = {
	/* Name of the previous page before redirect executes - used for implementing custom back buttons */
	backURL: string,
};

// ========== Types of redirect methods ==========

/* Redirect with mandatory params */
type TWithParams<Params, Query = TQuery> = (params: Params, query?: Query) => void;

/* Redirect w/o any params (queryParams can always be defined) */
type TWithoutParams<Query = TQuery> = (query?: Query) => void;

/* Redirect w/o anything */
type TVoid = () => void;

// ========== Hook types ==========

/** Structure of the navigation goTo object returned by the hook, each property is a route */
interface IGoTo {
	/** Redirects user to page with provided URL */
	any: (URL: string, options?: NavigateOptions) => void;
	/** Go back to a specific URL or the previous one the browser remembers */
	back: (options?: NavigateOptions, URL?: string) => void;
	/** Redirect to the `'/'`, called `defaultRoot` because we already have both `ROOT` and `HOME` routes */
	defaultRoot: TVoid;
	/** Redirect to any external URL w/ the option to open it in new tab */
	external: (URL: string, openInNewTab?: boolean) => void;
	home: TVoid;

	import: TVoid;

	marketplaceAppDetail: TWithParams<MarketplaceDetailParams>;
	marketplaceAppDirectory: TWithoutParams<MarketplaceAppDirectoryQuery>;

	orderDetail: TWithParams<OrderDetailParams>;

	orgAdd: TWithoutParams<OrgQuery>;
	orgDetail: TWithParams<OrgDetailParams, OrgQuery>;
	orgUsers: TWithParams<OrgUsersParams, OrgQuery>;
	orgUserDetail: TWithParams<OrgUserDetailParams, OrgQuery>;

	patientDetail: TWithParams<PatientDetailParams>;

	root: TVoid;
	searchResults: TVoid;
	userInfo: TVoid;
}

/** Return structure of the `useRouter()` hook */
interface IUseRouterReturn<Params, Query> {
	/** Browser location object */
	location: Location;
	/** Navigation object containing all valid routes */
	goTo: IGoTo;
	/** Parameters of the current route */
	params: Params;
	/** Query/Search parameters (everything after "?") of the current route */
	queryParams: Query;
	/** Refreshes the page */
	refresh: () => any;
}

/* Custom navigation hook wrapped aroung react-router `useNavigation()` */
export const useRouter = <
	Params = TParams, //
	Query = TQuery,
>(): IUseRouterReturn<Params, Query> => {
	const location: Location = useLocation();
	const params: Params = useParams<Params>();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const goTo = <
		P = Params, // URL params                       //  Record<$Keys<T>, string | number | boolean>,
		Q = Query, // URL Query search params
		S = Record<string, string>, // Route State
	>(
		URL: string, // MUST BE A VALUE FROM `ERoutes` !! Enums are needed for safe-typing this, so this is the next best thing
		params?: P,
		queryParams?: Q,
		state?: S
	): void => {
		let parsedUrl = '';

		if (typeof URL !== 'string') {
			throw new Error('PROVIDED URL IS NOT A STRING !');
		}

		// Check if any parameters were provided
		if (Object.keys(params || {}).length) {
			// Split the URL into segments by "/"
			const URLSegments = URL.split('/');

			URLSegments.forEach(segment => {
				// Check if segment is a parameter
				if (segment.includes(':')) {
					// Remove the ":" from the segment (":id" --> "id")
					const parameter = segment.replaceAll(':', '');

					// Include only parameters that were provided
					if (params[parameter]) {
						parsedUrl += `/${params[parameter]}`;
					}
				}

				// Segment is not a parameter, double-check if it's valid and append it
				// 1. Ignore invalid/empty segments (due to split by "/", the 1st segment is "", this can also ignore double // typos (eg: /detail//:id))
				// 2. Some of our URLs have "/*" at the end, ignore those segments too
				else if (segment && !segment.includes('*')) {
					parsedUrl += `/${segment}`;
				}
			});
		}

		// No parameters were provided, use the URL as-is
		else {
			// Throw an error if the URL is supposed to have parameters, but none were provided
			if (URL.includes(':')) {
				console.error(`URL ${URL} REQUIRES PARAMETERS BUT NONE WERE PROVIDED !`);
			}

			parsedUrl = String(URL);
		}

		// ===== Prepare the config & option object for `navigate()` method
		// #NOTE: If there are no query params, only `parsedUrl` is passed to navigate() instead of 2 (empty) objects
		// This makes the testing easier, devs can just use `expect(useNavigateMock.toBeCalledWith('URL'))` instead of expect(useNavigateMock.toBeCalledWith({ pathName: URL, ... }, { ... }))
		let to: To = parsedUrl;
		const navigateOptions: NavigateOptions = {};

		// If a custom state is provided, include it
		if (Object.keys(state || {}).length) {
			navigateOptions.state = ({ ...state, backURL: location.pathname }: TRouteState);
		} else {
			navigateOptions.state = ({ backURL: location.pathname }: TRouteState);
		}

		// If custom query/search parameters are provided, include them and call the `navigate()` with both configs
		if (Object.keys(queryParams || {}).length) {
			to = {
				pathname: parsedUrl,
				search: createSearchParams(queryParams || '').toString(),
			};
		}

		//
		if (Object.keys(navigateOptions).length) {
			navigate(to, navigateOptions);
		} else {
			navigate(to);
		}
	};

	/**
	 * goAny is a helper function that redirects the user to any URL - contains a backURL in the state
	 * @param {*} URL
	 * @param {*} options
	 */
	const goAny = (URL: string, options?: NavigateOptions) => {
		if (options) {
			options.state = { ...options.state, backURL: location.pathname };
			navigate(URL, options);
		} else {
			navigate(URL, { state: { backURL: location.pathname } });
		}
	};

	// Get all the queryParams from the URL and put them in an object as key-value pairs
	// $FlowIgnore
	const queryParams: Query = {};

	for (const [key, value] of searchParams) {
		// $FlowIgnore
		queryParams[key] = value;
	}

	return {
		location,
		params,
		queryParams,
		goTo: {
			any: (URL: string, options?: NavigateOptions) => goAny(URL, options),
			back: (options?: NavigateOptions, URL?: number | string = -1) =>
				options ? navigate(URL, { replace: true, ...options }) : navigate(URL),
			defaultRoot: () => goTo(ERoutes.DEFAULT_ROOT),
			external: (url, openInNewTab: boolean = true) => window.open(url, openInNewTab ? '_blank' : '_self'),

			//

			home: () => goTo(ERoutes.HOME),

			import: () => goTo(ERoutes.IMPORT),

			marketplaceAppDetail: (params: MarketplaceDetailParams) => goTo(ERoutes.MARKETPLACE_APP_DETAIL, params),
			marketplaceAppDirectory: (query?: MarketplaceAppDirectoryQuery) =>
				goTo(ERoutes.MARKETPLACE_APP_DIRECTORY, undefined, query),

			orderDetail: (params: OrderDetailParams) => goTo(ERoutes.ORDER, params),

			orgAdd: (query?: OrgQuery) => goTo(ERoutes.ORG_ADD, undefined, query),
			orgDetail: (params: OrgDetailParams, query?: OrgQuery) => goTo(ERoutes.ORG_DETAILS, params, query),
			orgUserDetail: (params: OrgUserDetailParams, query?: OrgQuery) =>
				goTo(ERoutes.ORG_USER_DETAIL, params, query),
			orgUsers: (params: OrgUsersParams, query?: OrgQuery) => goTo(ERoutes.ORG_USERS, params, query),

			patientDetail: (params: PatientDetailParams) => goTo(ERoutes.PATIENT_DETAIL_SCREEN, params),

			root: () => goTo(ERoutes.ROOT),

			searchResults: () => goTo(ERoutes.SEARCH_RESULTS),

			userInfo: () => goTo(ERoutes.USER_INFO),
		},
		refresh: () => navigate(0),
	};
};

export const setHash = (hash: string): void => {
	const url: URL = new URL(window.location);
	url.hash = hash;
	history.replaceState(null, '', url);
};
