import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { searchScopes, useAuth, useFhirDataLoader } from '@worklist-2/core/src';
import Avatar from '@worklist-2/ui/src/components/Avatar';
import Tooltip from '@mui/material/Tooltip';

function OrganizationDialog({ setIsOrganization, isFullHeight }) {
	const navigate = useNavigate();
	const { authorized } = useAuth();
	const [selectedOrganization, setSelectedOrganization] = useState(null);

	const orgDataLoader = useFhirDataLoader({
		scope: searchScopes.organization,
	});

	const [organizationData, setOrganizationData] = useState([]);

	const onClickOrg = item => {
		setSelectedOrganization(selectedOrganization === item ? null : item);
	};

	const generateInitials = label => {
		let initials = '';

		if (!label) return initials;

		const items = label.trim().split(' ');

		items.slice(0, 2).forEach(item => {
			initials += item[0];
		});

		if (items.length > 2) {
			initials += '...';
		}

		return initials.toUpperCase();
	};

	const fetchOrganizationData = async () => {
		if (authorized && orgDataLoader) {
			const extraParam = {
				active: true,
				_summary: 'text',
				_elements: 'name',
			};

			return orgDataLoader
				.load({
					extraValue: extraParam,
				})
				.then(response => {
					if (response) {
						const orgData = _.map(response, item => ({
							id: item.id,
							name: item.name,
							icon: generateInitials(item.name),
						}));

						setOrganizationData(orgData);
					}
				})
				.catch(console.error);
		}
	};

	useEffect(() => {
		fetchOrganizationData();
	}, []);

	const getOrganizationText = item => (
		<Typography
			sx={{
				marginTop: '16px',
				textAlign: 'center',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				transition: '0.2s',
				color: item.id === selectedOrganization?.id && '#fff',
				'&:hover': {
					color: '#fff',
				},
			}}
		>
			{item.name}
		</Typography>
	);

	return (
		<Box
			sx={{
				margin: '24px 60px',
				height: '100%',
			}}
		>
			<Typography fontSize={20} marginTop={1} sx={{ color: '#FFFFFF99' }}>
				Select Organization
			</Typography>

			<Grid
				container
				spacing={1}
				sx={{
					marginTop: '20px',
					height: !isFullHeight && '240px',
					overflowY: 'scroll',
					minHeight: '240px',
				}}
			>
				{organizationData &&
					organizationData.map(item => (
						<Grid key={item.id} item xs={4}>
							<Box
								sx={{
									color: '#FFFFFF99',
									'&:hover, &:focus, &:active ': {
										color: '#fff',
									},
								}}
							>
								<Box
									sx={{
										width: 70,
										height: 70,
										background: '#A09BD4',
										borderRadius: '100px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										margin: 'auto',
										borderSpacing: '6px',
										outline: '2px solid transparent',
										outlineOffset: '4px',
										outlineColor: item.id === selectedOrganization?.id && '#42A5F5',
										'&:active ': {
											outlineColor: '#42A5F5',
										},
									}}
									onClick={() => onClickOrg(item)}
								>
									<Avatar fontSize={18} label={item.name} />
								</Box>
								{item.name.length > 10 ? (
									<Tooltip title={item.name}>{getOrganizationText(item)}</Tooltip>
								) : (
									getOrganizationText(item)
								)}
							</Box>
						</Grid>
					))}
			</Grid>

			<Stack direction="row" justifyContent="end" spacing={20} sx={{ marginTop: 2 }}>
				<Button
					sx={{
						color: 'rgba(255, 255, 255, 0.6)',
						border: '1px solid rgba(255, 255, 255, 0.1)',
						'&:hover': {
							color: '#fff',
							borderColor: '#fff',
						},
					}}
					variant="outlined"
					onClick={e => {
						e.stopPropagation();
						setIsOrganization(false);
					}}
				>
					Cancel
				</Button>
				<Button
					data-testid="continue-button"
					disabled={!selectedOrganization}
					sx={{
						color: '#fff',
						background: '#42A5F5',
						'&:hover': {
							background: '#2e9df8',
						},
					}}
					variant="contained"
					onClick={() =>
						navigate('/root/new/blank', {
							state: {
								organization: { selectedOrganization },
							},
						})
					}
				>
					continue
				</Button>
			</Stack>
		</Box>
	);
}

OrganizationDialog.propTypes = {
	/**
	 * setIsOrganization is func to close the organization dialog
	 */
	setIsOrganization: PropTypes.func.isRequired,
	/**
	 * isFullHeight is boolean to define dialog is going to extend full page or not
	 */
	isFullHeight: PropTypes.bool,
};

export default OrganizationDialog;
