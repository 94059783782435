import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ConnectedTvOutlinedIcon from '@mui/icons-material/ConnectedTvOutlined';
import ScriptIcon from '@worklist-2/ui/src/assets/icons/ScriptIcon.svg';

const HeaderButtons = ({ isCodeEditorOpen, setIsCodeEditorOpen, setIsTestRun, isTestRun }) => {
	const [isHover, setIsHover] = useState(false);

	const handleSwitchToggle = () => {
		setIsCodeEditorOpen(prev => !prev);
	};

	const handleMouseOver = () => {
		if (isCodeEditorOpen) {
			setIsHover(true);
		}
	};

	const handleMouseLeave = () => {
		if (isCodeEditorOpen) {
			setIsHover(false);
		}
	};

	const handleTestButtonClick = () => {
		if (isCodeEditorOpen) {
			setIsTestRun(true);
		}
	};

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			{isTestRun ? (
				<>
					<Typography
						sx={{
							padding: '8px 6px',
							fontFamily: 'Inter',
							fontSize: '14px',
							fontWeight: '500',
							lineHeight: '14px',
							letterSpacing: '1.5px',
							textTransform: 'uppercase',
							color: '#FFF',
							backgroundColor: '#4D79EA',
							borderRadius: '5px',
						}}
					>
						Test
					</Typography>
					<span
						style={{
							width: '1px',
							height: '12px',
							margin: '0 10px',
							backgroundColor: isCodeEditorOpen ? 'rgba(255, 255, 255, 0.6)' : 'rgba(96, 96, 96, 0.1)',
						}}
					/>
					<ConnectedTvOutlinedIcon
						sx={{
							color: '#4D79EA',
						}}
					/>
				</>
			) : (
				<>
					<Box
						data-testid="testButton"
						sx={{
							padding: isHover ? '0 10px' : 'unset',
							display: 'flex',
							alignItems: 'center',
							border: isHover ? '1px solid rgba(255, 255, 255, 0.6)' : '1px solid transparent',
							borderRadius: '20px',
							cursor: isCodeEditorOpen ? 'pointer' : 'default',
						}}
						onClick={handleTestButtonClick}
						onMouseLeave={handleMouseLeave}
						onMouseOver={handleMouseOver}
					>
						<ConnectedTvOutlinedIcon
							sx={{
								paddingTop: '4px',
								paddingBottom: '2px',
								color: isCodeEditorOpen ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)',
							}}
						/>
						{isHover && (
							<Typography
								sx={{
									marginLeft: '4px',
									fontFamily: 'Inter',
									fontSize: '14px',
									fontWeight: '500',
									lineHeight: '12px',
									textTransform: 'uppercase',
									color: 'rgba(255, 255, 255, 0.6)',
								}}
							>
								Test
							</Typography>
						)}
					</Box>
					<span
						style={{
							width: '1px',
							height: '12px',
							margin: '0 10px',
							backgroundColor: isCodeEditorOpen ? 'rgba(255, 255, 255, 0.6)' : 'rgba(96, 96, 96, 0.1)',
						}}
					/>
					<Box
						sx={{
							position: 'relative',
							width: '52px',
							height: '20px',
							display: 'flex',
							justifyContent: isCodeEditorOpen ? 'flex-start' : 'flex-end',
							alignItems: 'center',
							borderRadius: '5px',
							backgroundColor: isCodeEditorOpen ? '#4D79EA' : 'rgba(0, 0, 0, 0.6)',
							cursor: 'pointer',
						}}
						onClick={handleSwitchToggle}
					>
						<Box
							sx={{
								position: 'absolute',
								top: '-2px',
								left: !isCodeEditorOpen && '0',
								right: isCodeEditorOpen && '0',
								width: '22px',
								height: '24px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: '5px',
								backgroundColor: '#FFFFFF',
								boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
							}}
						>
							<ScriptIcon />
						</Box>
						<Typography
							sx={{
								margin: '0 6px',
								fontFamily: 'Inter',
								fontSize: '14px',
								fontWeight: '500',
								lineHeight: '12px',
								color: '#FFFFFF',
							}}
						>
							{isCodeEditorOpen ? 'On' : 'Off'}
						</Typography>
					</Box>
				</>
			)}
		</Box>
	);
};

export default HeaderButtons;
