import axios from 'axios';

const getOrganizations = async ({ __config, orgIds }) => {
	let organizationIds = orgIds;
	if (Array.isArray(orgIds)) {
		organizationIds = organizationIds.filter(id => id);
		organizationIds = organizationIds.join(',');
	}
	const response = await axios.get(`${__config.data_sources.blume}Organization/?idList=${organizationIds}`, {
		headers: {
			'X-API-KEY': window.btoa(__config.patient_portal.authentication_key),
		},
	});

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default getOrganizations;
