const helpPageMapping = {
	'blume-form': 'https://help.omegaai.com/docs/Getting%20Started/Setting%20Up%20Forms',
	chat: 'https://help.omegaai.com/docs/Communication%20and%20Organization%20Tools/Using%20Chat',
	scheduler: 'https://help.omegaai.com/docs/category/scheduler',
	device: 'https://help.omegaai.com/docs/OmegaAI%20Link/OmegaAI%20Link',
	'document-viewer-v3': 'https://help.omegaai.com/docs/category/document-viewer',
	home: 'https://help.omegaai.com/docs/category/worklist',
	imageviewer3d: 'https://help.omegaai.com/docs/category/image-viewer',
	hangingProtocol: 'https://help.omegaai.com/docs/Advanced%20Topics/Mastering%20Hanging%20Protocols',
	import: 'https://help.omegaai.com/docs/Getting%20Started/import',
	log: 'https://help.omegaai.com/docs/Communication%20and%20Organization%20Tools/Logs',
	organization:
		'https://help.omegaai.com/docs/Advanced%20Topics/advanced%20-%20understanding%20organizational%20heirarchy',
	'patient-form': 'https://help.omegaai.com/docs/category/blume-patient-portal',
	protocol: 'https://help.omegaai.com/docs/Advanced%20Topics/Mastering%20Hanging%20Protocols',
	roles: 'https://help.omegaai.com/docs/Advanced%20Topics/User%20Access%20Control',
	root: 'https://help.omegaai.com/docs/category/root-business-analytics-and-reporting',
	template: 'https://help.omegaai.com/docs/Document%20Viewer/dv%20template%20management',
	'teaching-folder': 'https://help.omegaai.com/docs/Advanced%20Topics/teaching%20folder',
	userinfo: 'https://help.omegaai.com/docs/Communication%20and%20Organization%20Tools/User%20Profile',
	'workflow-automation': 'https://help.omegaai.com/docs/Workflow%20Automation/workflow%20automation',
	users: 'https://help.omegaai.com/docs/Getting%20Started/adduser',
};

export const GLOBAL_SEARCH_HELP_URL = 'https://help.omegaai.com/docs/Global%20Search/Global%20Search';

export default helpPageMapping;
