import { ReferenceLinesTool, ToolGroupManager } from '@cornerstonejs/tools';

class CustomReferenceLinesTool extends ReferenceLinesTool {
	onSetToolEnabled = () => {
		const toolGroups = ToolGroupManager.getAllToolGroups();

		toolGroups?.forEach(toolGroup => {
			const referenceLinesTool = toolGroup?._toolInstances?.[ReferenceLinesTool.toolName];

			if (referenceLinesTool) {
				referenceLinesTool?._init?.();
			}
		});
	};

	onCameraModified = () => {
		const toolGroups = ToolGroupManager.getAllToolGroups();

		toolGroups?.forEach(toolGroup => {
			const referenceLinesTool = toolGroup?._toolInstances?.[ReferenceLinesTool.toolName];

			if (referenceLinesTool) {
				referenceLinesTool?._init?.();
			}
		});
	};
}

export default CustomReferenceLinesTool;
